import template from './tnkWorkflowMatchByConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkflowMatchByConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        entityMetadata: '<',
        matchConfiguration: '<',
        previewEvaluationSource: '<',
        modFixedWidth: '<',
        tabsFieldSelectorModNarrow: '<',
        onWorkflowMatchByFieldChanged: '&',
        onWorkflowMatchQueryChanged: '&',
    },
    template,
    controller: 'WorkflowMatchByConfigurationCtrl',
});
