export enum SyncStatus {
    MET,
    NOT_MET,
    DISABLED,
}

export enum ModuleItemInterface {
    DATA_SOURCE = 'Data source',
    BACKGROUND_PROCESS = 'Background process',
    FORM = 'Form',
    TEST_BY_USER = 'Test created',
    LIVE_REPORT = 'Live report',
    EMAIL_WEBHOOK = 'Email webhook',
    COMMUNICATION_INTEGRATION = 'Communication integration',
    MANUAL_BY_USER = 'Manually by the user',
    MODULE_FLOW = 'Module flow',
    COMMUNICATION_INTEGRATION_FORM = 'Communication integration form',
    WEB_FORM = 'Web form',
    FIELDS_SETTINGS = 'Fields settings',
    LIVE_REPORT_OR_MODULE_FLOW = 'Business report or module flow',
    MODULE_REACTIVATION_PROCESS = 'Module was disabled for a long period of time - item archived during module re-activation process.',
    INPUT_SOURCE_ITEM_DELETION = 'An item in the intake source was deleted, leading to the deletion of the corresponding module item',
}

interface WorkerRunReasonConfigItem {
    /** The type of the event (updated, created, etc) */
    eventType?: string;
    /** The status of the reason - if true it's a success, false is failed. */
    success?: boolean;
    /** Is this worker run deprecated? If so, it will not fetch it in the history modal */
    isDeprecated?: boolean;

    // Module item specific:
    /** The reason of the failure is that there is already an item with the same title */
    duplicateTitle?: boolean;
    /** The interface of the module item (i.e. Live Report, Forms, etc) */
    moduleItemInterface?: ModuleItemInterface;
    /** The reason in human words. */
    description?: string;

    // Flow run specific:
    /** The subtype of the flow run (the reason of why it's triggered) */
    subType?: string;
    /**
     * Indicates whether the trigger run either because its disabled or the conditions didn't match.
     * This worker run will not be shown unless a checkbox is checked in the filters. This will also fetch the
     * placeholder worker run logic to show the trigger.
     */
    hasNotRun?: boolean;

    // Data source specific:
    /** Has the sync met or not, or the project integration is disabled */
    syncStatus?: SyncStatus;
    /** Should show the conditions of the sync? */
    showConditionsTab?: boolean;
}

export default WorkerRunReasonConfigItem;
