import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ConvertToDateFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_TO_DATE;
    override readonly sign = 'ConvertToDate';
    override readonly displayName = 'Convert to Date';
    override readonly description =
        'Will get the date value for the input. Will try to use DateFormat formats to parse it from a string, or try to get the epoch seconds/milliseconds if a number.';
    override readonly dataType = FieldType.Date;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: [FieldType.Number, FieldType.String, FieldType.Date],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
