import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import FormSettingsAddButtonLabelSection from './FormSettingsAddButtonLabelSection';
import FormSettingsClientDisplayNameLabelSection from './FormSettingsClientDisplayNameLabelSection';
import FormSettingsCollectInnerItemsAmountValidation from './FormSettingsCollectInnerItemsAmountValidation';
import FormSettingsCollectInnerItemsCollectionFormSelector from './FormSettingsCollectInnerItemsCollectionFormSelector';
import FormSettingsCommunicationIntegrationsCommands from './FormSettingsCommunicationIntegrationsCommands';
import FormSettingsEmphasisRequiredFieldsCheckbox from './FormSettingsEmphasisRequiredFieldsCheckbox';
import FormSettingsExpandableDescription from './FormSettingsExpandableDescription';
import FormSettingsFillFormButtonLabel from './FormSettingsFillFormButtonLabel';
import FormSettingsHeaderMaxLines from './FormSettingsHeaderMaxLines';
import FormSettingsHideDisplayNameCheckbox from './FormSettingsHideDisplayNameCheckbox';
import FormSettingsHomepageAccessEnabled from './FormSettingsHomepageAccessEnabled';
import FormSettingsItemHierarchyFilterSelect from './FormSettingsItemHierarchyFilterSelect';
import FormSettingsQuestionType from './FormSettingsQuestionType';
import FormSettingsSmartSearchEnabled from './FormSettingsSmartSearchEnabled';
import FormSettingsSubmitButtonLabelSection from './FormSettingsSubmitButtonLabelSection';
import FormSettingsSubtitle from './FormSettingsSubtitle';
import FormSettingsThemePicker from './FormSettingsThemePicker';
import FormSettingsTrackLocation from './FormSettingsTrackLocation';
import FormSettingsUploadFilesCanSkip from './FormSettingsUploadFilesCanSkip';
import FormSettingsUploadLogo from './FormSettingsUploadLogo';
import useEnvironmentFlags from '../../../../infrastructure/hooks/useEnvironmentFlags';
import UploadFilesDataStorageSelection from '../../../UploadFilesFormModule/components/UploadFilesDataStorageSelection';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { TnkFieldSelector } from '@tonkean/angular-to-react-components';
import { Chevron, ChevronDirection, CollapsibleContent, SavingIndicator, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { FieldDefinition, Form, FormDefinition, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { SavingState } from '@tonkean/tonkean-entities';
import { FormQuestionType, FormType } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    padding: 0 30px ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

const AdvancedSectionWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

const AdvancedSection = styled(DisableableButton)`
    color: ${Theme.colors.primary};
    border: none;
    background-color: transparent;
    font-size: ${FontSize.SMALL_12};

    & path {
        stroke: ${Theme.colors.primary};
    }
`;

const Hr = styled.hr`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
    width: 100%;
    margin-bottom: 0;
`;

const UploadFilesDataSectionWrapper = styled.div`
    .upload-file-data-storage-label {
        font-size: ${FontSize.MEDIUM_14} !important;
        font-weight: 600 !important;
        letter-spacing: 0.3px;
        font-family: 'Roboto', sans-serif;
    }
`;

const FieldSelectorWrapper = styled.div`
    margin-top: 16px;
`;

const FieldSelectorLabel = styled.div`
    font-size: ${FontSize.MEDIUM_14} !important;
    font-weight: 600 !important;
    letter-spacing: 0.3px;
    margin: 4px;
    display: flex;
    flex-direction: row;
`;

const TooltipIconWrapper = styled.div`
    margin-left: 8px;
    color: ${Theme.colors.primary};
`;

// Type to make sure T is a property of FormDefinition
type ForceKeyOfFormDefinition<T> = T extends keyof FormDefinition ? T : keyof FormDefinition;
// And to make sure the value is the same type of said T
type FormDefinitionTypedValue<T> = T extends [infer Value, ...infer Rest]
    ? [FormDefinition[ForceKeyOfFormDefinition<Value>], ...FormDefinitionTypedValue<Rest>]
    : [];

interface Props {
    formId?: string;
    groupId: string;
    workflowVersionId: string;
    form: Form;
    onFormChanged: Dispatch<SetStateAction<Form>>;
}

const FormSettings: React.FC<Props> = ({ groupId, workflowVersionId, form, onFormChanged, formId }) => {
    const customTriggerManager = useAngularService('customTriggerManager');
    const formManager = useAngularService('formManager');

    const [advancedOpen, setAdvancedOpen] = useState<boolean>(false);
    const { isEditable, setSectionLoading, editedSections } = useContext(FormBuilderContext);

    // We make sure the keys are on FormDefinition by enforcing T
    // Then with FormDefinitionTypedValue we make sure the values provided match-type with the expected type in the keys
    const getOnFormDefinitionChanged = useCallback(
        <T extends (keyof FormDefinition)[]>(section: FormBuilderSections, ...keys: T) =>
            (...values: FormDefinitionTypedValue<T>) => {
                const entries = keys.map((key, index) => [key, values[index]]);
                const updateMap = Object.fromEntries(entries);
                onFormChanged((pervForm) => ({
                    ...pervForm,
                    definition: {
                        ...pervForm.definition,
                        ...updateMap,
                    },
                }));

                setSectionLoading(section);
            },
        [onFormChanged, setSectionLoading],
    );

    const { isDraft } = useEnvironmentFlags();

    useEffect(() => {
        setAdvancedOpen(!isDraft);
    }, [formId, isDraft]);

    const onDataStorageUpdate = useMemo(() => {
        return getOnFormDefinitionChanged(FormBuilderSections.DataStorageId, 'dataStorageId');
    }, [getOnFormDefinitionChanged]);

    const onDestinationFolderUpdate = useMemo(() => {
        return getOnFormDefinitionChanged(FormBuilderSections.DestinationFolderId, 'destinationFolderId');
    }, [getOnFormDefinitionChanged]);

    const onFieldDefinitionToSaveFileIdUpdate = useMemo(() => {
        return getOnFormDefinitionChanged(
            FormBuilderSections.FieldDefinitionToSaveFileId,
            'fieldDefinitionToSaveFileId',
        );
    }, [getOnFormDefinitionChanged]);

    const onChangeFieldDefinitionToSaveFileId = useCallback(
        (fieldDefinition: FieldDefinition) => {
            if (fieldDefinition.id) {
                onFieldDefinitionToSaveFileIdUpdate(fieldDefinition.id);
            }
        },
        [onFieldDefinitionToSaveFileIdUpdate],
    );

    const onDataStorageChanged = useCallback(
        (selectedDataStorageId?: string, folderNameExpression?: TonkeanExpressionDefinition) => {
            if (!!selectedDataStorageId && selectedDataStorageId !== form.definition?.dataStorageId) {
                onDataStorageUpdate(selectedDataStorageId);
            }
            if (
                !!folderNameExpression &&
                folderNameExpression.originalExpression !== form.definition?.destinationFolderId?.originalExpression
            ) {
                onDestinationFolderUpdate(folderNameExpression);
            }
        },
        [
            onDataStorageUpdate,
            onDestinationFolderUpdate,
            form.definition.destinationFolderId,
            form.definition.dataStorageId,
        ],
    );

    // Show if thr field selector only fields that are manual field, basic fields and
    // matched entity fields that were created from custom trigger with form of type upload file
    const fieldsFilter = useCallback(
        (fields: FieldDefinition[]) => {
            return fields?.filter((field) => {
                if (!field.isMultiValueField && ['String', 'LongString'].includes(field.fieldType)) {
                    if (!field.idRelationField || !field?.linkedCustomTrigger?.id) {
                        return field;
                    } else {
                        const fieldCustomTrigger = customTriggerManager.getCachedCustomTrigger(
                            workflowVersionId,
                            field.linkedCustomTrigger.id,
                        );
                        const customTriggerFormId =
                            fieldCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition?.formId;
                        if (customTriggerFormId) {
                            const customTriggerForm = formManager.getWorkerFormFromCache(
                                workflowVersionId,
                                customTriggerFormId,
                            );
                            if (customTriggerForm?.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                                return field;
                            }
                        }
                    }
                }
            });
        },
        [customTriggerManager, formManager, workflowVersionId],
    );

    const smartSearchFlagEnabled = useFeatureFlag('tonkean_feature_smartsearch');

    return (
        <Wrapper>
            <FormSettingsSubtitle
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                form={form}
                onFormChanged={onFormChanged}
            />
            <FormSettingsExpandableDescription
                form={form}
                onFormChanged={onFormChanged}
                groupId={groupId}
                workflowVersionId={workflowVersionId}
            />
            {form.formType === FormType.UPDATE && (
                <>
                    <FormSettingsQuestionType form={form} onFormChanged={onFormChanged} />
                    {form.formQuestionType === FormQuestionType.UPLOAD_FILES && (
                        <UploadFilesDataSectionWrapper>
                            <UploadFilesDataStorageSelection
                                onDataStorageChanged={onDataStorageChanged}
                                groupId={groupId}
                                workflowVersionId={workflowVersionId}
                                currentSelectedDataStorageId={form.definition.dataStorageId}
                                currentFolderExpression={form.definition.destinationFolderId}
                                fileStorageLabel="Default File Storage"
                                destinationFolderLabel="Destination Folder (ID)"
                                form={form}
                                defaultFormDateStorageDefinition={false}
                                dataStorageIsLoading={
                                    editedSections[FormBuilderSections.DataStorageId] === SavingState.LOADING
                                }
                            />
                            <FieldSelectorWrapper>
                                <FieldSelectorLabel>
                                    Field for File ID (for uploaded file)
                                    <Tooltip content="The file ID of the uploaded file will be stored in the specified field so it can be referenced by other actions in the module flow.">
                                        <TooltipIconWrapper>
                                            <span className="fa fa-info-circle" />
                                        </TooltipIconWrapper>
                                    </Tooltip>
                                    <SavingIndicator
                                        state={editedSections[FormBuilderSections.FieldDefinitionToSaveFileId]}
                                    />
                                </FieldSelectorLabel>
                                <TnkFieldSelector
                                    key={form?.id}
                                    workflowVersionId={workflowVersionId}
                                    groupBy="groupBy"
                                    selectedFieldIdentifier={
                                        form?.definition?.fieldDefinitionToSaveFileId || 'TNK_DESCRIPTION'
                                    }
                                    itemsSource="COLUMN"
                                    onFieldSelected={onChangeFieldDefinitionToSaveFileId}
                                    placeHolder="Add a field"
                                    fieldInspectModal={false}
                                    fieldInspectAutoOpen={false}
                                    fieldsFilter={fieldsFilter}
                                    addFieldsQuickCreateOptions={false}
                                    quickCreateMatchedEntity={false}
                                    specialFieldsForFeatures={['UPLOAD_FILE_FORM_FIELD']}
                                    onlyUpdatableFieldDefinitions
                                    reloadFields
                                />
                            </FieldSelectorWrapper>
                        </UploadFilesDataSectionWrapper>
                    )}
                    <AdvancedSectionWrapper>
                        <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                            <span>
                                <AdvancedSection
                                    data-automation="form-settings-advance-settings-button"
                                    onClick={() => setAdvancedOpen((prevOpen) => !prevOpen)}
                                    disabled={!isEditable}
                                >
                                    Advanced Settings{' '}
                                    <Chevron direction={advancedOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                                </AdvancedSection>
                            </span>
                        </Tooltip>
                    </AdvancedSectionWrapper>
                    <CollapsibleContent open={advancedOpen} animateOpacity>
                        <FormSettingsItemHierarchyFilterSelect
                            form={form}
                            onFormDefinitionChanged={getOnFormDefinitionChanged(
                                FormBuilderSections.FormAvailableOn,
                                'itemsHierarchyFilter',
                            )}
                        />

                        {form.formQuestionType === FormQuestionType.COLLECT_INNER_ITEMS && (
                            <>
                                <FormSettingsCollectInnerItemsCollectionFormSelector
                                    groupId={groupId}
                                    workflowVersionId={workflowVersionId}
                                    formId={form.id}
                                    onCollectionFormSelected={getOnFormDefinitionChanged(
                                        FormBuilderSections.CollectionFormSelector,
                                        'collectionFormId',
                                    )}
                                    selectedCollectionFormId={form.definition.collectionFormId}
                                />

                                <FormSettingsCollectInnerItemsAmountValidation
                                    minimumItemsAmount={form.definition.minimumItemsAmount}
                                    onFormDefinitionChanged={getOnFormDefinitionChanged(
                                        FormBuilderSections.ItemsAmountValidation,
                                        'minimumItemsAmount',
                                    )}
                                />
                            </>
                        )}

                        {form.formQuestionType === FormQuestionType.UPLOAD_FILES && (
                            <>
                                <FormSettingsUploadFilesCanSkip
                                    canSkipUpload={form.definition.canSkipUpload}
                                    onFormDefinitionChanged={getOnFormDefinitionChanged(
                                        FormBuilderSections.CanSkipUpload,
                                        'canSkipUpload',
                                    )}
                                />
                            </>
                        )}
                    </CollapsibleContent>
                </>
            )}
            {form.formType === FormType.CREATE && (
                <>
                    <FormSettingsCommunicationIntegrationsCommands
                        form={form}
                        onFormChanged={onFormChanged}
                        workflowVersionId={workflowVersionId}
                    />
                    {smartSearchFlagEnabled && (
                        <FormSettingsSmartSearchEnabled form={form} onFormChanged={onFormChanged} />
                    )}
                    <FormSettingsHomepageAccessEnabled form={form} onFormChanged={onFormChanged} />
                    <AdvancedSectionWrapper>
                        <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                            <span>
                                <AdvancedSection
                                    data-automation="form-settings-advance-settings-button"
                                    onClick={() => setAdvancedOpen((prevOpen) => !prevOpen)}
                                    disabled={!isEditable}
                                >
                                    Advanced Settings{' '}
                                    <Chevron direction={advancedOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                                </AdvancedSection>
                            </span>
                        </Tooltip>
                    </AdvancedSectionWrapper>
                    <CollapsibleContent open={advancedOpen} animateOpacity>
                        <FormSettingsTrackLocation groupId={groupId} form={form} onFormChanged={onFormChanged} />
                    </CollapsibleContent>
                </>
            )}
            <Hr />
            <FormSettingsThemePicker
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(
                    FormBuilderSections.WebThemeDesign,
                    'primaryColor',
                    'secondaryColor',
                    'buttonsColor',
                )}
            />

            <FormSettingsEmphasisRequiredFieldsCheckbox
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(
                    FormBuilderSections.WebThemeDesign,
                    'emphasisRequiredFields',
                )}
            />

            <FormSettingsUploadLogo
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(FormBuilderSections.WebThemeDesign, 'logoUrl')}
            />
            {form.formType === FormType.UPDATE && (
                <FormSettingsFillFormButtonLabel
                    form={form}
                    onFormDefinitionChanged={getOnFormDefinitionChanged(
                        FormBuilderSections.WebThemeDesign,
                        'fillFormButtonLabel',
                    )}
                />
            )}
            {form.formQuestionType === FormQuestionType.COLLECT_INNER_ITEMS && (
                <FormSettingsHeaderMaxLines
                    form={form}
                    onFormChanged={getOnFormDefinitionChanged(FormBuilderSections.WebThemeDesign, 'headerMaxLines')}
                />
            )}
            {form.formType === FormType.UPDATE && form.formQuestionType === FormQuestionType.COLLECT_INNER_ITEMS && (
                <FormSettingsAddButtonLabelSection
                    form={form}
                    onFormDefinitionChanged={getOnFormDefinitionChanged(
                        FormBuilderSections.WebThemeDesign,
                        'addItemButtonLabel',
                    )}
                />
            )}
            <FormSettingsSubmitButtonLabelSection
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(
                    FormBuilderSections.WebThemeDesign,
                    'submitFormButtonLabel',
                )}
            />

            <FormSettingsHideDisplayNameCheckbox
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(
                    FormBuilderSections.WebThemeDesign,
                    'hideDisplayName',
                )}
            />
            <FormSettingsClientDisplayNameLabelSection
                form={form}
                onFormDefinitionChanged={getOnFormDefinitionChanged(
                    FormBuilderSections.WebThemeDesign,
                    'clientDisplayName',
                )}
            />
        </Wrapper>
    );
};

export default FormSettings;
