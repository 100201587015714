function ProductTriggersCtrl($scope, $stateParams) {
    $scope.tab = $stateParams.tab;

    $scope.data = {};

    $scope.init = function () {};

    $scope.init();
}

export default angular.module('tonkean.app').controller('ProductTriggersCtrl', ProductTriggersCtrl);
