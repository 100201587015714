import { useMemo } from 'react';

import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { ItemInterfaceWidget, TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import type { TonkeanId } from '@tonkean/tonkean-entities';

const useInitialExpressionValues = (widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>) => {
    return useMemo(() => {
        return widget.configuration?.initialExpressionValues
            ?.filter((value) => !!value.fieldDefinitionId && !!value.expression)
            .map((value) => ({
                fieldDefinitionId: value.fieldDefinitionId as TonkeanId<TonkeanType.FIELD_DEFINITION>,
                expression: value.expression as TonkeanExpressionDefinition,
            }));
    }, [widget.configuration?.initialExpressionValues]);
};

export default useInitialExpressionValues;
