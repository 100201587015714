enum ImageInputType {
    IMAGE_FROM_FIELD = 'FROM FIELD',
    IMAGE_FROM_ACTION = 'FROM ACTION',
}

export const validationToImageTypeDisplayName: Record<ImageInputType, string> = {
    [ImageInputType.IMAGE_FROM_FIELD]: 'Set image manually',
    [ImageInputType.IMAGE_FROM_ACTION]: 'From data source action',
};

export const ImageInputTypes = Object.values(ImageInputType).map((imageInputType) => ({
    value: imageInputType.valueOf(),
    label: validationToImageTypeDisplayName[imageInputType],
}));

export default ImageInputType;
