import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type AuditLogFilter from '../../../entities/AuditLogFilter';
import type AuditLogType from '../../../entities/AuditLogType';

import { useTonkeanService, useAsyncMethod } from '@tonkean/angular-hooks';
import { FilterDateRange, FilterRow, FiltersGroup, SimpleSelect, useSubmittableFilters } from '@tonkean/infrastructure';
import { PersonSelect } from '@tonkean/infrastructure';
import type { TonkeanType } from '@tonkean/tonkean-entities';

export const EMPTY_OBJECT = {};

const FiltersGrid = styled.div`
    display: grid;
    grid-template-columns: 75px auto;
    grid-gap: 14px 7px;
    align-items: baseline;
    width: 100%;
`;

interface Props {
    filters: AuditLogFilter;
    onChange: (filters: AuditLogFilter) => void;
    enterpriseComponentType: TonkeanType;
    includeActors?: boolean;
    logType?: AuditLogType;
}

const EnterpriseComponentAuditLogFilterPopover: React.FC<Props> = ({
    filters: filtersProp,
    onChange,
    enterpriseComponentType,
    includeActors = true,
    logType,
}) => {
    const auditLogService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');

    const [{ canClear, onClear, canSubmit, onSubmit }, filters, setFilters] = useSubmittableFilters<AuditLogFilter>(
        filtersProp,
        EMPTY_OBJECT,
        onChange,
    );

    // Get the available activities types
    const { data: activities, loading: activitiesLoading } = useAsyncMethod(
        auditLogService,
        'getAuditLogActivities',
        projectManager.project.id,
        logType,
    );

    // Canonize the activities response to SimpleSelect options
    const options = useMemo(() => {
        return (
            activities?.entities
                ?.filter(
                    (activity) =>
                        activity.isRelevantForAllEnterpriseComponents ||
                        activity.relevantTonkeanTypes.includes(enterpriseComponentType),
                )
                .map((activity) => ({ value: activity.name, label: activity.friendlyName })) || []
        );
    }, [activities?.entities, enterpriseComponentType]);

    // Get the relevant admins to filter on
    const { data: admins } = useTonkeanService('getTeamMembers', projectManager.project.id);

    return (
        <FiltersGroup
            clearDisabled={!canClear}
            onClear={onClear}
            submitDisabled={!canSubmit}
            onSubmit={onSubmit}
            GridTemplate={FiltersGrid}
        >
            <FilterRow label="Activity Type">
                <SimpleSelect
                    value={filters.activityType}
                    isLoading={activitiesLoading}
                    options={options}
                    onChange={(activityType) => {
                        setFilters({
                            ...filters,
                            activityType,
                        });
                    }}
                    isClearable
                    thin
                />
            </FilterRow>

            {includeActors && (
                <FilterRow label="Actor">
                    <PersonSelect
                        options={admins?.people}
                        onChange={(actorId: string) =>
                            setFilters({
                                ...filters,
                                actorId,
                            })
                        }
                        value={filters.actorId}
                        isClearable
                        thin
                    />
                </FilterRow>
            )}

            <FilterRow label="Date">
                <FilterDateRange
                    fromDate={filters.fromDate}
                    toDate={filters.toDate}
                    onFromDateChange={(fromDate) => setFilters({ ...filters, fromDate })}
                    onToDateChange={(toDate) => setFilters({ ...filters, toDate })}
                    showTimeSelect
                />
            </FilterRow>
        </FiltersGroup>
    );
};

export default EnterpriseComponentAuditLogFilterPopover;
