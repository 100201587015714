import { useCallback, useRef, useState } from 'react';

import useStillMounted from '@tonkean/tui-hooks/useStillMounted';
import type { ResolveValue } from '@tonkean/utils';
import type { KeysThatExtend } from '@tonkean/utils';

export interface LazyAsyncMethodRequestState<E, T extends (...args: any[]) => Promise<any>> {
    data?: ResolveValue<ReturnType<T>>;
    error?: E;
    loading: boolean;
    called: boolean;
    args?: Parameters<T>;
    promise?: ReturnType<T>;
}

/**
 * React hook for handling async methods in a react way. It will execute the method when the trigger is called.
 * It cannot handle more than one request every time, so if you update a param while it's loading, it will stop the
 * previous request and start the new one.
 *
 * @example
 * const GroupNameGetter = () => {
 *     const groupInfoManager = useAngularService("groupInfoManager");
 *     const [{data, error, loading, called}, getGroupById] = useLazyAsyncMethod(groupInfoManager, "getGroupById");
 *
 *     if (!called) {
 *         return (
 *             <button onClick={() => getGroupById(prompt('group id'))}>
 *                 get group name
 *             </button>
 *         );
 *     }
 *     if (loading) {
 *         return <span className="loading" />;
 *     }
 *     if (error) {
 *         return <strong className="error">{ error.message }</strong>;
 *     }
 *     return <strong>{data.name}</strong>;
 * }
 *
 * @param object - the object that contains the method.
 * @param methodKey - the key of the method in the object.
 * @returns an array, with the first value as object with `data` as the value if the method resolves, `error` as the reason if the
 * method rejects, `called` as a boolean indicating if the method has been triggered, `loading` as a boolean indicating
 * if the method is currently loading and `args` as the list of arguments passed to the method, and the second
 * value of the array is a trigger for the method which returns a promise, and it's params are the params to pass to
 * the method.
 */
function useLazyAsyncMethod<
    ERROR = any,
    OBJECT extends Record<string, any> = any,
    KEY extends KeysThatExtend<OBJECT> = any,
    METHOD extends (...args: any[]) => Promise<any> = OBJECT[KEY],
>(
    object: OBJECT,
    methodKey: KEY,
): [
    LazyAsyncMethodRequestState<ERROR, METHOD>,
    (...args: Parameters<METHOD>) => ReturnType<METHOD>,
    (args: Parameters<METHOD>, changeStateOnLoading: boolean) => ReturnType<METHOD>,
] {
    const mountedRef = useStillMounted();
    const currentPromiseRef = useRef<ReturnType<METHOD>>();

    const [state, setState] = useState<LazyAsyncMethodRequestState<ERROR, METHOD>>({
        called: false,
        loading: false,
    });

    const realTrigger = useCallback(
        (args: Parameters<METHOD>, changeStateOnLoading: boolean) => {
            // This promise will resolve or reject only if the component is still mounted, to prevent the react
            // update after unmount error.
            const promise = new Promise((resolve, reject) => {
                // Typescript doesn't understand that `OBJECT[KEY]` extends `(...args: any[]) => Promise<any>`, so
                // we need to use any. We can't extract it to a variable to not break the 'this' binding.
                (object[methodKey] as any as METHOD)(...args)
                    .then((response) => {
                        if (mountedRef.current) {
                            resolve(response);
                        }
                    })
                    .catch((error) => {
                        if (mountedRef.current) {
                            reject(error);
                        }
                    });
            }) as ReturnType<METHOD>;

            currentPromiseRef.current = promise;
            if (mountedRef.current && changeStateOnLoading) {
                setState({ loading: true, called: true, args, promise });
            }

            // We update state only if this promise is the last time this callback was triggered
            // to prevent updating a state while another request has been initiated.
            promise
                .then((data) => {
                    if (currentPromiseRef.current === promise) {
                        setState({
                            called: true,
                            loading: false,
                            args,
                            data,
                            promise,
                        });
                    }
                })
                .catch((error) => {
                    if (currentPromiseRef.current === promise) {
                        setState({
                            called: true,
                            loading: false,
                            args,
                            error,
                            promise,
                        });
                    }
                });

            return promise;
        },
        [methodKey, mountedRef, object],
    );

    const trigger = useCallback(
        (...args: Parameters<METHOD>) => {
            return realTrigger(args, true);
        },
        [realTrigger],
    );

    return [state, trigger, realTrigger];
}

export default useLazyAsyncMethod;
