import template from './tnkSearchTriggers.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSearchTriggers', {
    bindings: {
        groupId: '<',
        selectedTriggerId: '<',
        canClear: '<',
        shouldSetDefaultTrigger: '<',
        onTriggerSelected: '&',
        defaultTriggersAmount: '<',
    },
    template,
    controller: 'SearchTriggersCtrl',
});
