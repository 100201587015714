import React, { useMemo } from 'react';
import styled from 'styled-components';

import StatusChip from './components/StatusChip';
import type InitiativeRowData from '../../entities/InitiativeRowData';
import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { useStatesForWorkflowVersion } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';

interface Props extends ItemsGridCellRendererParams<InitiativeRowData['state']> {
    fontSize?: FontSize;
}

const StatusChipWrapper = styled.div`
    margin-left: 2px;
`;

const StatusCellRenderer: React.FC<Props> = ({
    value,
    context: { workflowVersion },
    fontSize = FontSize.MEDIUM_14,
}) => {
    const { getStateByLabel } = useStatesForWorkflowVersion(workflowVersion);

    const state = useMemo(() => {
        return value?.label ? getStateByLabel(value?.label) : undefined;
    }, [value, getStateByLabel]);

    return (
        <StatusChipWrapper>
            <StatusChip state={state} fontSize={fontSize} />
        </StatusChipWrapper>
    );
};

export default StatusCellRenderer;
