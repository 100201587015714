import template from './tnkExcel365CustomActionsDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkExcel365CustomActionsDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        definition: '<', // The selected action definition.
        validationObject: '<',
        onDefinitionChanged: '&',
        modTextArea: '<',
    },
    template,
    controller: 'Excel365CustomActionsDefinitionCtrl',
});
