<div
    class="worker-logic flex"
    ng-class="{
        'disabled-worker':
            !data.isParentDisabled &&
            ($root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                ? $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id).disabled
                : data.configuredLogic.node.disabled)
    }"
>
    <!-- Component Title & Content -->
    <div>
        <!-- Logic Name -->
        <div class="worker-editor-logic-name-container flex-grow relative">
            <!-- Display name non-edit-mode -->
            <span
                class="pointer"
                ng-if="!data.displayEditLogicName"
                uib-tooltip="{{ data.configuredLogic.node.displayName }}"
                tooltip-enable="data.configuredLogic.node.displayName.length > 20 && !data.isCollapsed"
                tooltip-animation="false"
                tooltip-popup-delay="500"
                tooltip-placement="bottom-left"
                tooltip-append-to-body="true"
                ng-click="toggleEditLogicDisplayName(data.configuredLogic.node.displayName)"
            >
                <span class="worker-editor-logic-name" ng-class="{ 'mod-max-width': !data.isCollapsed }">
                    {{ data.configuredLogic.node.displayName }}
                </span>
            </span>

            <!-- Display name edit-mode -->
            <div ng-if="data.displayEditLogicName" class="flex-vmiddle">
                <!-- Text input -->
                <input
                    type="text"
                    class="worker-editor-logic-name mod-input-only-line"
                    id="worker-editor-logic-name-input"
                    maxlength="100"
                    ng-model="data.editedLogicName"
                    ng-blur="updateLogicDisplayName(data.editedLogicName)"
                    ng-keydown="onLogicNameKeyDown($event)"
                />
            </div>

            <!-- Loading state when saving name-->
            <i
                class="loading-small absolute-top-left margin-left-lg-minus"
                ng-if="data.loadingSavingLogicDisplayName"
            ></i>
        </div>

        <!-- Logic Component Content -->
        <div>
            <div
                class="worker-editor-logic-container bot-side-pane-block-click-outside"
                id="logic-container-{{ data.configuredLogic.node.id }}"
                ng-class="{
                    'mod-selected': data.configuredLogic.node.id === data.syncObject.selectedLogicId,
                    'mod-unset': data.configuredLogic.node.customTriggerType == 'UNKNOWN',
                    'mod-invalid': data.invalidLogics[data.configuredLogic.node.id]
                }"
                tnk-dnd-draggable="{{::data.configuredLogic.node.id}}"
                tnk-dnd-disabled="{{
                    data.workflowVersion.workflowVersionType !== 'DRAFT' ||
                        logicBlockTypes[data.configuredLogic.node.customTriggerType].cantDrag
                }}"
                tnk-dnd-dragstart="data.isDragging = true;"
                tnk-dnd-dragend="data.isDragging = false;"
                tnk-dnd-moved="onMoved(data.items, dndData)"
                tnk-dnd-get-placeholder="getDndPlaceholder()"
            >
                <div
                    class="flex flex-col triangle-top-left"
                    ng-class="{
                        'triangle-top-left-form':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM',
                        'triangle-top-left-interface-submitted':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED',
                        'triangle-top-left-schedule':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_SCHEDULE',
                        'triangle-top-left-created':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_ITEM_CREATED' ||
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'MONITOR_TRACKS_ITEM_CREATED',
                        'triangle-top-left-field-changed':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_FIELD_CHANGED',
                        'triangle-top-left-match-condition':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTONOMOUS_MATCH_CONDITIONS' ||
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'MONITOR_TRACKS_MATCH_CONDITIONS',
                        'triangle-top-left-button-click':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'USER_INTERFACE_BUTTON_CLICK',
                        'triangle-top-left-auto-close':
                            $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                                .customTriggerSecondaryType === 'AUTO_CLOSE'
                    }"
                    ng-click="selectLogicBox()"
                >
                    <div
                        data-automation="worker-logic-action-{{ data.configuredLogic.node.displayName }}"
                        class="worker-editor-logic-container-icon flex-grow pointer"
                    >
                    
                        <!-- Top left triangle icon -->
                        <div
                            ng-switch="$root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id).customTriggerSecondaryType"
                            class="triangle-icon common-color-white"
                        >
                            <span ng-switch-when="AUTONOMOUS_ITEM_CREATED" class="fa fa-flag fa-lg"></span>
                            <span ng-switch-when="MONITOR_TRACKS_ITEM_CREATED" class="fa fa-flag fa-lg"></span>
                            <span ng-switch-when="AUTONOMOUS_SCHEDULE" class="fa fa-clock-o fa-lg"></span>
                            <span ng-switch-when="AUTONOMOUS_FIELD_CHANGED" class="fa fa-eye fa-lg"></span>
                            <div ng-switch-when="AUTONOMOUS_CREATED_FROM_FORM" class="triangle-icon-form"></div>
                            <div ng-switch-when="AUTONOMOUS_INTERFACE_SUBMITTED" class="triangle-icon-interface-submitted"></div>
                            <div ng-switch-when="AUTONOMOUS_MATCH_CONDITIONS" class="triangle-icon-filter"></div>
                            <div ng-switch-when="MONITOR_TRACKS_MATCH_CONDITIONS" class="triangle-icon-filter"></div>
                            <div ng-switch-when="USER_INTERFACE_BUTTON_CLICK" class="triangle-icon-button-click"></div>
                            <div ng-switch-when="AUTO_CLOSE" class="triangle-icon-auto-close"></div>
                        </div>

                        <div
                            ng-if="$root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id)
                            .customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED' && 
                            !$root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id).disabled"
                            class="new-intake-icon common-color-grey"
                            ng-click="goToIntakePage($root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id))"
                            data-automation="worker-logic-action-go-to-intake-page-{{ data.configuredLogic.node.displayName }}"
                        >
                            <tnk-icon src="/images/icons/new_tab.svg"></tnk-icon>
                        </div>
                    

                        <div  ng-if="data.addModuleLinkButton" class="open-new-tab-container">
                            <span class="open-new-tab" ng-click="openModuleLink()"/>
                        </div>
                        <!-- Integration icons (when we have any) -->
                        <div ng-if="data.configuredLogic.node.integrationType">
                            <div
                                class="inline-block margin-right-xs mod-big initiative-integration-icon"
                                ng-class="
                                    !data.logicProjectIntegration.iconUrl
                                        ? 'mod-' + data.configuredLogic.node.integrationType.toLowerCase()
                                        : ''
                                "
                                ng-style="{
                                    background: data.logicProjectIntegration.iconUrl
                                        ? 'url(' + data.logicProjectIntegration.iconUrl + ') no-repeat center'
                                        : null
                                }"
                            ></div>
                        </div>
                        <!-- Logic icon when we have no integration. -->
                        <div
                            ng-if="!data.configuredLogic.node.integrationType && !data.uniqueIconsForWhenFormAnswered"
                            class="logic-template-icon"
                            ng-class="logicBlockTypes[data.configuredLogic.node.customTriggerType].iconClass"
                        ></div>

                        <div
                            ng-if="!data.configuredLogic.node.integrationType && data.uniqueIconsForWhenFormAnswered"
                            class="icon-wrapper"
                        >                     
                            <div class="logic-template-icon" ng-class="data.uniqueIconsForWhenFormAnswered">
                            </div>
                        </div>
                    </div>

                    <!-- Square footer -->
                    <div
                        class="flex-vmiddle mod-justify-space-around padding-left padding-right padding-bottom-xs"
                        ng-if="
                            data.configuredLogic.node.customTriggerType !== 'UNKNOWN' &&
                            (!logicBlockTypes[data.configuredLogic.node.customTriggerType].doNotDisplayFilterEdit ||
                                !logicBlockTypes[data.configuredLogic.node.customTriggerType]
                                    .doNotDisplaySettingsEdit ||
                                !logicBlockTypes[data.configuredLogic.node.customTriggerType].doNotDisplayStatusEdit)
                        "
                        data-automation="worker-logic-inline-actions-footer-{{ data.configuredLogic.node.displayName }}"
                    >
                        <!-- Filter -->
                        <span
                            ng-click="selectLogic(data.configuredLogic, 'conditions', $event)"
                            uib-tooltip="Conditions"
                            tooltip-placement="auto bottom"
                            tooltip-append-to-body="true"
                            class="pointer svg-icon-sm svg-icon-align-text-top common-color-grey"
                            data-automation="worker-logic-change-conditions"
                            ng-class="{
                                'common-tag-full mod-thin2 svg-icon-xs common-color-white':
                                    data.configuredLogic.node.queryDefinition.query.filters.length ||
                                    data.configuredLogic.node.queryDefinition.query.queries.length
                            }"
                            ng-if="!logicBlockTypes[data.configuredLogic.node.customTriggerType].doNotDisplayFilterEdit"
                        >
                            <tnk-icon src="/images/icons/filter.svg"></tnk-icon>
                        </span>

                        <!-- Settings -->
                        <span
                            ng-click="selectLogic(data.configuredLogic, 'configuration', $event)"
                            uib-tooltip="Setup"
                            tooltip-placement="auto bottom"
                            tooltip-append-to-body="true"
                            class="pointer svg-icon-sm svg-icon-align-text-top common-color-grey"
                            ng-if="
                                !logicBlockTypes[data.configuredLogic.node.customTriggerType].doNotDisplaySettingsEdit
                            "
                        >
                            <tnk-icon src="/images/icons/cog-o.svg"></tnk-icon>
                        </span>

                        <!-- Status -->
                        <div
                            ng-click="selectLogic(data.configuredLogic, 'when-finished', $event)"
                            uib-tooltip="Change Fields"
                            tooltip-placement="auto bottom"
                            tooltip-append-to-body="true"
                            class="
                                pointer
                                worker-editor-logic-state-color-box
                                common-bold
                                text-center
                                line-height-normal
                                flex-no-shrink
                            "
                            data-automation="worker-logic-change-status"
                            ng-class="{
                                'mod-empty':
                                    !data.configuredLogic.node.stateId ||
                                    !wvm.getCachedWorkflowVersion(data.workflowVersionId).stateIdToStateMap[
                                        data.configuredLogic.node.stateId
                                    ],
                                'mod-invalid': data.invalidLogics[data.configuredLogic.node.id].whenFinishedError
                            }"
                            ng-style="{
                                'background-color': wvm.getCachedWorkflowVersion(data.workflowVersionId)
                                    .stateIdToStateMap[data.configuredLogic.node.stateId].color
                            }"
                            ng-if="
                                !logicBlockTypes[data.configuredLogic.node.customTriggerSecondaryType]
                                    .doNotDisplayStatusEdit &&
                                (!data.configuredLogic.node.customTriggerActions[0] ||
                                    data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .actionType != 'ARCHIVE_TRACK') &&
                                        (!data.configuredLogic.node.customTriggerActions[0] || data.configuredLogic.node.customTriggerType !== 'BOT_BUTTON_PRESSED' || !data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedActionType ||
                                    (data.configuredLogic.node.customTriggerType === 'BOT_BUTTON_PRESSED' && data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedActionType === 'continue-flow')) &&
                                        !(data.configuredLogic.node.customTriggerType === 'SEND_FORM_ANSWERED' && (data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedActionType === 'go-to-sequence' || data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedButtonType === 'go-to-sequence'))
                                    
                            "
                        >
                        {{ data.configuredLogic.node.updateText || data.configuredLogic.node.stateUpdaterFieldsToUpdate ? 'T' : null }}
                        </div>

                        <!-- Notification -->
                        <div
                            ng-click="selectLogic(data.configuredLogic, 'logic-notification', $event)"
                            class="pointer svg-icon-sm svg-icon-align-text-top"
                            data-automation="worker-logic-change-notification"
                            ng-class="{ 'common-color-grey': !data.configuredLogic.node.customNotificationSettings }"
                            uib-tooltip="Notification"
                            tooltip-placement="auto bottom"
                            tooltip-append-to-body="true"
                            ng-if="
                                !logicBlockTypes[data.configuredLogic.node.customTriggerType]
                                    .doNotDisplayNotificationsEdit &&
                                (!data.configuredLogic.node.customTriggerActions[0] ||
                                    data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .actionType != 'ARCHIVE_TRACK') &&
                                        (!data.configuredLogic.node.customTriggerActions[0] || data.configuredLogic.node.customTriggerType !== 'BOT_BUTTON_PRESSED' || !data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedActionType ||
                                    (data.configuredLogic.node.customTriggerType === 'BOT_BUTTON_PRESSED' && data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                                        .selectedActionType === 'continue-flow'))
                            "
                        >
                            <tnk-icon src="/images/icons/red-bell.svg"></tnk-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        ng-class="{ 'flex': !data.showWorkerLogicItemDetails }"
        ng-hide="calculateCantAddChildren()"
    >
        <!-- Line & Plus button & collapse -->
        <div
          class="show-collapse-when-hover"
          ng-class="{ 'flex': data.showWorkerLogicItemDetails }">
            <div ng-if="data.showWorkerLogicItemDetails" class="worker-editor-inner-hline mod-static mod-long"></div>
            <div ng-if="data.showWorkerLogicItemDetails" class="inner-item-details-container">
                <tnk-item-details-module-editor
                  workflow-version-id="data.workflowVersionId"
                  target-id="data.configuredLogic.node.id"
                  on-open-tab-click="(selectOutlineTab)"
                  override-details-title-placeholder = "logicBlockTypes[data.configuredLogic.node.customTriggerType].innerItemDetailsTitlePlaceholder",
                  override-details-description-placeholder = "logicBlockTypes[data.configuredLogic.node.customTriggerType].innerItemDetailsDescriptionPlaceholder"
                ></tnk-item-details-module-editor>
            </div>
            <div ng-class="{
                'flex': !data.showWorkerLogicItemDetails,
            }">
                <!-- Components outgoing line -->
                <tnk-item-details-do-line
                  ng-if="logicBlockTypes[data.configuredLogic.node.customTriggerType].showWhenDo && data.features.tonkean_show_inner_item_idd"
                  workflow-version-id="data.workflowVersionId"
                  group-target-id="data.groupId"
                  custom-trigger-target-id="data.directParent.node.id"
                  override-details-title-placeholder="logicBlockTypes[data.directParent.node.customTriggerType].innerItemDetailsTitlePlaceholder"
                  custom-trigger-work-on-action-item="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.workerItemContextType"
                ></tnk-item-details-do-line>
                <span
                  ng-class="{
                    'mod-do': logicBlockTypes[data.configuredLogic.node.customTriggerType].showWhenDo && !data.features.tonkean_show_inner_item_idd,
                    'worker-editor-inner-hline mod-static': !data.showWorkerLogicItemDetails,
                    'mod-do-inner-item': logicBlockTypes[data.configuredLogic.node.customTriggerType].showWhenDo && data.features.tonkean_show_inner_item_idd
                    }"
                ></span>

                <!-- Plus button -->
                <span class="worker-editor-add-logic-container flex-no-shrink"
                    ng-class="{
                    'mod-has-impacts':
                        data.configuredLogic.impacts && !data.showWorkerLogicItemDetails &&
                        (data.configuredLogic.impacts | filter: { node: { isHidden: false } }).length,
                    'inner-item-add-logic': data.showWorkerLogicItemDetails
                }">
                    <span class="worker-editor-add-logic-dnd-hook">
                        <span
                            class="worker-editor-add-logic mod-color dont-close-workers-sidepane-on-click"
                            data-automation="tnk-worker-logic-template-add-new-action-{{ data.configuredLogic.node.displayName }}"
                            uib-dropdown
                            uib-dropdown-toggle
                            is-open="data.createOptionsMenuIsOpen"
                            ng-if="!data.loadingCreateNewLogic"
                            ng-click="clickOnPlusButton()"
                            ng-disabled="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                        >
                            <span>+</span>

                            <!-- In order not to calculate 2 filters on every digest we use the flag of the dropdown isopen to only calculate it when the dropdown is open -->
                            <ul
                                uib-dropdown-menu
                                ng-if="data.createOptionsMenuIsOpen && data.calculatedPlusButtonDropdownOptions.length > 1"
                            >
                                <div class="title" ng-if="data.createAfterOptionsTitle">
                                    {{data.createAfterOptionsTitle}}
                                </div>
                                <li
                                    ng-repeat="option in data.calculatedPlusButtonDropdownOptions"
                                    ng-click="createNewLogic(data.configuredLogic, option.triggerParamsEnricher)"
                                    data-automation="tnk-worker-logic-template-dropdown-option-{{ option.label }}"
                                >
                                    <a>
                                        <span class="after-option-icon">
                                            <tnk-item-details-icon
                                              workflow-version-id="data.workflowVersionId"
                                              target-id="getAfterOptionTargetId(option)"
                                              size-modifier="0.4"
                                              icon-type="getIconType(option)"
                                            />
                                        </span>
                                        {{ option.label }}
                                    </a>
                                </li>
                            </ul>
                        </span>

                        <span ng-if="data.loadingCreateNewLogic" class="worker-editor-add-logic">
                            <span class="loading-small"></span>
                        </span>
                    </span>
                </span>
            </div>
            <!-- Collapse\expand button-->
            <div class="show-collapse-when-hover">
                <div class="collapse-btn-on-hover">
                    <div
                        ng-if="
                            (data.configuredLogic.impacts | filter: { node: { isHidden: false } }).length &&
                            !data.isCollapsed
                        "
                        uib-tooltip="Collapse"
                        tooltip-append-to-body="true"
                        tooltip-placement="right"
                        class="worker-editor-logic-collapse-btn pointer fa fa-arrow-circle-left common-color-light-grey"
                        ng-class="{
                            'worker-editor-logic-collapse-btn-extra':  logicBlockTypes[data.configuredLogic.node.customTriggerType].showWhenDo && data.features.tonkean_show_inner_item_idd,
                            'worker-editor-logic-collapse-btn-when-details': data.features.tonkean_show_inner_item_idd && data.showWorkerLogicItemDetails
                        }"
                        ng-click="toggleLogicCollapsed()"
                    ></div>
                </div>
            </div>
        </div>

        <!-- Children graph traversal -->
        <div class="relative"
             ng-class="{
                'inner-item-graph': data.showWorkerLogicItemDetails
            }"
        >
            <!-- Graph traversal UI -->
            <!-- When there are no children, this container becomes a dnd hook. -->
            <div
                class="worker-editor-logic-box-container"
                tnk-dnd-list="{{
                    !data.isDragging && !logicBlockTypes[data.configuredLogic.node.customTriggerType].cantMoveInto
                }}"
                tnk-dnd-list-id="{{::data.configuredLogic.node.id}}"
                tnk-dnd-drop="onDrop(dndData, dndIndex, dndListId)"
                tnk-dnd-get-marker="getDndMarker()"
                tnk-dnd-list-full-dropzone="{{
                    !data.configuredLogic.impacts || !data.configuredLogic.impacts.length ? 'true' : null
                }}"
                ng-class="{ 'mod-dnd-hook': !data.configuredLogic.impacts || !data.configuredLogic.impacts.length }"
            >
                <!-- Going through the neighbours, and displaying each node recursively -->
                <div
                    ng-repeat="innerLogic in data.configuredLogic.impacts | orderBy: 'node.index'"
                    class="flex relative worker-editor-logic-box"
                    ng-if="data.configuredLogic && !innerLogic.node.isHidden"
                    ng-class="{
                        'mod-first': $first,
                        'mod-last': $last,
                        'mod-hidden': $first && $last,
                        'mod-when': logicBlockTypes[innerLogic.node.customTriggerType].showWhenDo && !data.features.tonkean_show_inner_item_idd,
                    }"
                >
                    <!-- Graph connecting horizontal line -->
                    <div ng-if="data.features.tonkean_show_inner_item_idd && logicBlockTypes[innerLogic.node.customTriggerType].showWhenDo">
                        <tnk-Item-Details-When-Line
                            workflow-version-id="data.workflowVersionId"
                            group-target-id="data.groupId"
                            custom-trigger-target-id="selectCustomTriggerTarget(innerLogic.node)"
                            override-details-title-placeholder="logicBlockTypes[data.configuredLogic.node.customTriggerType].innerItemDetailsTitlePlaceholder"
                            monitor-inner-items="innerLogic.node.monitorInnerItems"
                            monitored-inner-item-custom-trigger="innerLogic.node.monitorInnerItemsCreatedByCustomTriggerId"
                            show-only-inner-item="innerLogic.node.customTriggerActions[0].customTriggerActionDefinition.monitorContextType === 'ACTION_ITEM'"
                        ></tnk-Item-Details-When-Line>
                    </div>
                    <div
                      ng-if="data.configuredLogic.impacts && data.configuredLogic.impacts.length && (!logicBlockTypes[innerLogic.node.customTriggerType].showWhenDo || !data.features.tonkean_show_inner_item_idd) "
                      class="worker-editor-inner-hline mod-arrow"
                    ></div>

                    <tnk-worker-logic
                        configured-logic="innerLogic"
                        sync-object="::data.syncObject"
                        invalid-logics="data.invalidLogics"
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        can-modify-bot="data.canModifyBot"
                        is-parent-disabled="data.isParentDisabled || ($root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id) ? $root.ctm.getCachedCustomTrigger(data.workflowVersionId, data.configuredLogic.node.id).disabled : data.configuredLogic.node.disabled)"
                        select-logic-callback="selectLogic(logic,logicConfigMode)"
                        create-new-logic-callback="onCreateNewLogic(params)"
                        close-side-pane-callback="closeSidePane()"
                        select-outline-tab-callback="selectOutlineTab(tabKey, additionalParams)"
                    >
                        >
                    </tnk-worker-logic>
                </div>
            </div>
        </div>
    </div>

    <!-- Collapsed children indicator -->
    <div ng-show="data.isCollapsed && data.configuredLogic.impacts.length" class="flex">
        <span class="worker-editor-inner-hline mod-static"></span>

        <div>
            <!-- Collapsed children pill -->
            <div
                ng-click="toggleLogicCollapsed()"
                class="worker-editor-logic-collapsed-indicator pointer svg-icon svg-icon-xlg common-color-white"
                ng-class="{ 'mod-warning': data.collapsedChildrenHaveError }"
            >
                <tnk-icon src="/images/icons/ellipsis-h.svg"></tnk-icon>
            </div>
            <!-- # of Actions-->
            <div ng-if="data.collapsedChildrenCount" class="common-size-xxxxs margin-top-xxs common-color-grey3">
                {{ data.collapsedChildrenCount }} Actions
            </div>
        </div>
    </div>
</div>
