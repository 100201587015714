import { ReactComponent as FlagIcon } from '../../../../images/icons/flag.svg';
import { ReactComponent as FilterIcon } from '../../../../images/icons/filter.svg';
import React from 'react';
import moment from 'moment';
import Timeline from 'tonkean-react-calendar-timeline/lib';
import { TonkeanAvatar as TrackAvatar } from '@tonkean/infrastructure';
import Utils from '@tonkean/utils';
import { Dropdown } from '@tonkean/infrastructure';

export default class GanttTimeline extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.minMaxMap = {};
        this.availableGroupBys = [];
        this.availableGroupBys.push(
            {
                displayName: 'List',
                allowExpand: (groupId) => this.getEndTimeAccess(groupId).allowExpand,
                groupIdRetriever: (initiative) => initiative.groupId,
                getRelatedInitiatives: () => this.props.initiatives,
                getRelatedGroups: () =>
                    this.props.groups.map((group) => {
                        const workflowVersion = this.props.workflowVersionManager.getPublishedVersionFromCache(
                            group.id,
                        );
                        const columnFieldDefinitions =
                            this.props.customFieldsManager.selectedColumnFieldsMap[workflowVersion.id];
                        return {
                            id: group.id,
                            title: group.name,
                            startDateOptions: columnFieldDefinitions
                                .filter((fieldDef) => fieldDef.fieldType === 'Date')
                                .map((fieldDefinition) => {
                                    return {
                                        key: `startDateOption_${group.id}_${fieldDefinition.id}`,
                                        title: fieldDefinition.name,
                                        onSelect: () =>
                                            this.setStartDateAccessForGroup(
                                                group.id,
                                                this.getAccessForFieldDefinition(fieldDefinition),
                                            ),
                                    };
                                }),
                            endDateOptions: columnFieldDefinitions
                                .filter((fieldDef) => fieldDef.fieldType === 'Date')
                                .map((fieldDefinition) => {
                                    return {
                                        key: `endDateOption_${group.id}_${fieldDefinition.id}`,
                                        title: fieldDefinition.name,
                                        onSelect: () =>
                                            this.setEndDateAccessForGroup(
                                                group.id,
                                                this.getAccessForFieldDefinition(fieldDefinition),
                                            ),
                                    };
                                }),
                        };
                    }),
            },
            {
                displayName: 'Owner',
                allowExpand: () => false,
                groupIdRetriever: (initiative) => initiative.owner && initiative.owner.id,
                getRelatedInitiatives: () =>
                    this.props.initiatives.flatMap((initiative) => [
                        ...this.getChildrenRecursively(initiative),
                        initiative,
                    ]),
                getRelatedGroups: (initiatives) => {
                    const owners = [];
                    const ownersMap = {};
                    for (const initiative of initiatives) {
                        if (initiative.owner && !ownersMap[initiative.owner.id]) {
                            ownersMap[initiative.owner.id] = {};
                            owners.push({
                                id: initiative.owner.id,
                                title: initiative.owner.name,
                                startDateOptions: [],
                                endDateOptions: [],
                            });
                        }
                    }
                    return owners;
                },
            },
        );

        const selectedGroupBy = this.availableGroupBys[0];
        this.startTimeAccessToGroupMap = {};
        this.endTimeAccessToGroupMap = {};

        this.state = {
            groups: null,
            initiatives: this.props.initiatives,
            items: null,
            daysShown: 21,
            startWithGroupId: this.props.startWithGroupId,

            selectedGroupBy,
            startDateSelectionIsOpenForGroupId: null, // Set to id of the group the start date selection is open for
            endDateSelectionIsOpenForGroupId: null, // Set to id of the group the end date selection is open for
            groupByDropdownOpen: false,
            isListFilterDropdownOpen: false,
            isHoverTipShown: false,
            hiddenGroupsMap: {},
        };

        const groupByData = this.calculateDataInGroupBy(selectedGroupBy);
        this.state.groups = groupByData.groups;
        this.state.initiatives = groupByData.initiatives;
        this.state.items = groupByData.items;

        this.selectedItem = null;
        this.editTipText = null;

        if (this.state.startWithGroupId && this.state.startWithGroupId.length) {
            // select the right group (aka hide all others)
            for (let j = 0; j < this.state.groups.length; j++) {
                if (this.state.groups[j].id !== this.state.startWithGroupId) {
                    this.state.hiddenGroupsMap[this.state.groups[j].id] = true;
                }
            }
        }

        this.lineHeight = 50;
        this.itemHeightRatio = 0.8;

        // Copied from the library because imports failed in firefox
        this.customSubHeaderLabelFormats = {
            yearShort: 'YY',
            yearLong: 'YYYY',
            monthShort: 'MM',
            monthMedium: 'MMM',
            monthLong: 'MMMM',
            dayShort: 'D',
            dayLong: 'dddd, Do',
            hourShort: 'HH',
            hourLong: 'HH:00',
            minuteShort: 'mm',
            minuteLong: 'HH:mm',

            dayMediumLong: 'ddd D',
            dayMedium: 'ddd D',
        };

        this.groupRenderer = ({ group }) => {
            // Prepare start date options
            const startDateAccess = this.getStartTimeAccess(group.id);
            const startDateOptions = [
                <li
                    key={`startDateOption_${group.id}_startTime`}
                    onClick={() => {
                        this.setStartDateSelectionIsOpenFor(null);
                        this.setStartDateAccessForGroup(group.id, this.getDefaultStartTimeAccess());
                    }}
                >
                    <a>Start Time</a>
                </li>,
            ];

            for (let i = 0; i < group.startDateOptions.length; i++) {
                const option = group.startDateOptions[i];
                startDateOptions.push(
                    <li
                        key={option.key}
                        onClick={() => {
                            this.setStartDateSelectionIsOpenFor();
                            option.onSelect();
                        }}
                    >
                        <a>{option.title}</a>
                    </li>,
                );
            }

            // Prepare end date options
            const endDateAccess = this.getEndTimeAccess(group.id);
            const endDateOptions = [
                <li
                    key={`endDateOption_${group.id}_dueDate`}
                    onClick={() => {
                        this.setEndDateSelectionIsOpenFor(null);
                        this.setEndDateAccessForGroup(group.id, this.getDefaultEndTimeAccess());
                    }}
                >
                    <a>Due Date</a>
                </li>,
            ];

            for (let i = 0; i < group.endDateOptions.length; i++) {
                const option = group.endDateOptions[i];
                endDateOptions.push(
                    <li
                        key={option.key}
                        onClick={() => {
                            this.setEndDateSelectionIsOpenFor();
                            option.onSelect();
                        }}
                    >
                        <a>{option.title}</a>
                    </li>,
                );
            }

            return (
                <div className="timeline-group">
                    <div className="common-bold common-ellipsis">{group.title}</div>
                    {/* Start date options*/}
                    <div className="dropdown open margin-top-xs padding-top-xxs">
                        <div
                            onClick={this.setStartDateSelectionIsOpenFor.bind(this, group.id)}
                            className="dropdown-toggle flex-vmiddle common-color-grey3"
                        >
                            <div className="padding-right-xs timeline-date-range-pick-label">Start</div>
                            <div className="padding-right-xs common-color-black">{startDateAccess.name}</div>
                            <span className="dropdown-chevron mod-static" />
                        </div>
                        {this.state.startDateSelectionIsOpenForGroupId === group.id && (
                            <Dropdown onClickOutside={this.setStartDateSelectionIsOpenFor.bind(this, null)}>
                                {startDateOptions}
                            </Dropdown>
                        )}
                    </div>

                    {/* End date options*/}
                    <div className="dropdown open margin-top-xs padding-top-xxs">
                        <div
                            onClick={this.setEndDateSelectionIsOpenFor.bind(this, group.id)}
                            className="dropdown-toggle flex-vmiddle common-color-grey3"
                        >
                            <div className="padding-right-xs timeline-date-range-pick-label">End</div>
                            <div className="padding-right-xs common-color-black">{endDateAccess.name}</div>
                            <span className="dropdown-chevron mod-static" />
                        </div>
                        {this.state.endDateSelectionIsOpenForGroupId === group.id && (
                            <Dropdown onClickOutside={this.setEndDateSelectionIsOpenFor.bind(this, null)}>
                                {endDateOptions}
                            </Dropdown>
                        )}
                    </div>
                </div>
            );
        };

        this.itemRenderer = ({ item, timelineContext }) => {
            if (item.isLoading) {
                return (
                    <div className="timeline-item flex-vmiddle common-bg mod-white">
                        <div className="loading margin-right" />
                        <div>Loading</div>
                    </div>
                );
            }

            const isSelected = this.selectedItem && this.selectedItem.id === item.id;
            const allowExpand = this.state.selectedGroupBy.allowExpand(item.group);

            let itemClasses = 'timeline-item flex-vmiddle relative';
            if (item.endTimeBeforeStartTime) {
                itemClasses += ' mod-end-before-start';
            }

            if (item.isEndDateMissing) {
                itemClasses += ' mod-no-end-date';
            }

            const initiative = Utils.findFirstById(this.state.initiatives, item.id);
            const dayWidth = timelineContext.timelineWidth / this.state.daysShown;
            const startTimeAccess = this.getStartTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative));
            const endTimeAccess = this.getEndTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative));
            const startDate = moment(startTimeAccess.get(initiative));
            const endDate = moment(endTimeAccess.get(initiative));
            const flags = [];
            let pastIndicatorElement = null;
            let futureIndicatorElement = null;
            let childrenContainerBox = null;
            let pastSectionWidth = 0;
            let futureSectionWidth = 0;

            if (!isSelected) {
                const dueDateFlagElement = this.createFlagElement(
                    item,
                    dayWidth,
                    endTimeAccess.id,
                    <span className="tnk-icon">
                        <FlagIcon />
                    </span>,
                    'Due date',
                    moment(initiative.dueDate),
                );
                flags.push(dueDateFlagElement);

                for (let i = 0; i < item.otherEvents.length; i++) {
                    const event = item.otherEvents[i];
                    flags.push(
                        this.createFlagElement(
                            item,
                            dayWidth,
                            event.id,
                            <div className="timeline-date-event" />,
                            event.name,
                            event.date,
                        ),
                    );
                }

                let farthestDateInPast = item.start_time;
                let farthestDateInFuture = item.end_time;
                for (let i = 0; i < item.otherEvents.length; i++) {
                    const event = item.otherEvents[i];
                    if (event.date.isBefore(farthestDateInPast)) {
                        farthestDateInPast = event.date;
                    }
                    if (event.date.isAfter(farthestDateInFuture)) {
                        farthestDateInFuture = event.date;
                    }
                }

                if (!farthestDateInPast.isSame(startDate, 'day') && !item.isEndDateMissing) {
                    pastSectionWidth =
                        startDate.endOf('day').diff(farthestDateInPast.startOf('day'), 'days') * dayWidth;
                    const pastIndicatorClasses = 'timeline-item-indicator past';
                    pastIndicatorElement = (
                        <div
                            className={pastIndicatorClasses}
                            style={{
                                width: pastSectionWidth + 14,
                            }}
                        >
                            {' '}
                            {/* +14 to connect it to the main content */}
                        </div>
                    );
                }

                if (!farthestDateInFuture.isSame(endDate, 'day') && !item.isEndDateMissing) {
                    futureSectionWidth = farthestDateInFuture.diff(endDate, 'days') * dayWidth;
                    futureIndicatorElement = (
                        <div
                            className="timeline-item-indicator future"
                            style={{
                                left: (endDate.diff(item.start_time, 'days') + 1) * dayWidth - 20, // -20 to connect it to main content
                                width: futureSectionWidth,
                            }}
                        />
                    );
                }

                if (item.isExpanded && !item.parentId) {
                    const minMoment = moment(this.minMaxMap[item.id].min);
                    const minDateDaysDiff = minMoment.diff(item.start_time, 'days');
                    const maxDateDaysDiff =
                        moment(this.minMaxMap[item.id].max).startOf('day').diff(minMoment.startOf('day'), 'days') + 1;
                    const childrenBoxPadding = 5; // arbitrary
                    const left = minDateDaysDiff * dayWidth - childrenBoxPadding;
                    const width = maxDateDaysDiff * dayWidth + childrenBoxPadding * 2; // multiply by two to make up for the padding in the negative direction.
                    const height = (this.countShownChildItemsRecursively(item.id) + 1) * this.lineHeight;

                    childrenContainerBox = (
                        <div
                            style={{
                                position: 'absolute',
                                left,
                                width,
                                top: '-5px',
                                height,
                                borderRadius: '20px',
                                background: Utils.hexToRgbaWithOpacity(item.stateColor, 0.2),
                                zIndex: -1,
                            }}
                        />
                    );
                }
            }
            let childrenCollapseElement = null;
            let childrenCountElement = null;
            if (item.childrenCount) {
                let childrenCollapseClasses = 'margin-left margin-right common-size-xxxxs fa';
                childrenCollapseClasses += item.isExpanded ? ' fa-chevron-down' : ' fa-chevron-right';
                childrenCollapseElement = (
                    <div
                        className={childrenCollapseClasses}
                        onClick={(event) => this.toggleExpandItem(event, item.id)}
                        style={{
                            color: item.stateColor,
                            width: 12, // setting fixed width, so there will be no movement in item when switching states
                        }}
                    />
                );
                childrenCountElement = (
                    <div className="common-color-light-grey margin-left-xs flex-no-shrink">{item.childrenCount}</div>
                );
            }

            let boxShadow = '';
            if (isSelected) {
                const boxShadowColor = Utils.hexToRgbaWithOpacity(item.stateColor, 0.15);
                boxShadow = `0 1px 0 0 ${boxShadowColor}, 0 -4px 6px 0 ${boxShadowColor}, 0 8px 10px 0 ${boxShadowColor}, 1px 1px 5px 0 ${boxShadowColor}`;
            }

            return (
                <div
                    className={itemClasses}
                    onClick={() => this.onItemSelected(item.id)}
                    onMouseEnter={() => this.toggleHoverTip(true)}
                    onMouseLeave={() => this.toggleHoverTip(false)}
                >
                    <div
                        className="flex-vmiddle overflow-hidden common-width-100 timeline-item-content"
                        style={{
                            marginLeft: !isSelected ? pastSectionWidth : '',
                            marginRight: !isSelected ? futureSectionWidth : '',
                            background: item.isEndDateMissing
                                ? ''
                                : Utils.hexToRgbaWithOpacity(Utils.shadeHexColor(item.stateColor, 0.9), 0.95),
                            borderColor: item.isEndDateMissing ? '' : item.stateColor,
                            boxShadow,
                        }}
                    >
                        {this.renderItemAvater(item)}
                        {allowExpand && childrenCollapseElement}
                        <div className="common-ellipsis margin-left letter-spacing-sm">{item.title}</div>
                        {this.state.selectedGroupBy.allowExpand && childrenCountElement}
                        {item.isEndDateMissing && (
                            <div className="margin-left-auto margin-right padding-left common-color-link-blue common-size-xxxxs common-ellipsis">
                                <i className="fa fa-arrows-alt margin-right-xs" />
                                Drag to add {this.getEndTimeAccess(item.group).name}
                            </div>
                        )}
                    </div>
                    {!item.isEndDateMissing && flags}
                    {childrenContainerBox}
                    {!item.endTimeBeforeStartTime && pastIndicatorElement}
                    {!item.endTimeBeforeStartTime && futureIndicatorElement}
                </div>
            );
        };

        this.toggleExpandItem = (event, itemId) => {
            event.stopPropagation();

            const parentInitiativeIndex = Utils.indexOf(this.state.initiatives, (i) => i.id === itemId);
            const parentInitiative = this.state.initiatives[parentInitiativeIndex];
            const childrenInitiatives = parentInitiative.relatedInitiatives;

            // Don't toggle the expanded state if no children are present or expand is disabled.
            if (!childrenInitiatives || !childrenInitiatives.length || !this.state.selectedGroupBy.allowExpand) {
                return;
            }

            const parentItemIndex = Utils.indexOf(this.state.items, (i) => i.id === itemId);
            const parentItem = this.state.items[parentItemIndex];
            if (!parentItem.isExpanded) {
                const items = this.state.items;
                for (const [i, child] of childrenInitiatives.entries()) {
                    items.splice(
                        parentItemIndex + 1 + i,
                        0,
                        this.createItem(child, this.state.selectedGroupBy, parentInitiative),
                    );
                }
                const initiativesToAdd = childrenInitiatives.filter(
                    (initiative) => !Utils.findFirstById(this.state.initiatives, initiative.id),
                );

                // Get inner initiatives from the server\cache
                this.props.trackHelper.loadRelatedInitiatives(parentInitiative, true).then((returnedInitiative) => {
                    const fullInitiatives = returnedInitiative.relatedInitiatives;

                    for (const fullInitiative of fullInitiatives) {
                        const foundItemIndex = Utils.indexOf(
                            this.state.items,
                            (stateItem) => stateItem.id === fullInitiative.id,
                        );
                        if (foundItemIndex !== -1) {
                            this.state.items.splice(
                                foundItemIndex,
                                1,
                                this.createItem(fullInitiative, this.state.selectedGroupBy, parentInitiative),
                            );
                        } else {
                            this.state.items.push(fullInitiative);
                        }

                        const foundInitiativeIndex = Utils.indexOf(
                            this.state.initiatives,
                            (stateInitiative) => stateInitiative.id === fullInitiative.id,
                        );
                        if (foundInitiativeIndex !== -1) {
                            this.state.initiatives.splice(foundInitiativeIndex, 1, fullInitiative);
                        } else {
                            this.state.initiatives.push(fullInitiative);
                        }
                    }

                    this.setState({ initiatives: [...this.state.initiatives] }); // react hack to force render (concat gets a new array)
                    this.setItems([...this.state.items]); // react hack to force render (concat gets a new array))
                });

                this.setState({ initiatives: [...this.state.initiatives, ...initiativesToAdd] });
                this.setItems([...items]); // react hack to force render (concat gets a new array))
            } else {
                // Set items as all the current items who are not in the children initiatives
                const itemsToRemove = this.getChildrenRecursively(parentInitiative);
                this.setItems(this.state.items.filter((item) => !Utils.findFirstById(itemsToRemove, item.id)));
            }

            parentItem.isExpanded = !parentItem.isExpanded;
        };

        this.nothing = () => {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.initiatives.length !== prevProps.initiatives.length) {
            const groupByData = this.calculateDataInGroupBy(this.state.selectedGroupBy);
            this.setState({
                groups: groupByData.groups,
                initiatives: groupByData.initiatives,
                items: groupByData.items,
            });
        }
    }

    renderItemAvater(item) {
        return (
            <div className="avatar-main img-circle timeline-avatar margin-left flex-no-shrink">
                <TrackAvatar owner={item.owner} />
            </div>
        );
    }

    createFlagElement(item, dayWidth, key, svgIconElement, name, endDate) {
        const endDateDaysDiff = endDate.diff(item.start_time, 'days');
        const widthOfFlagElement = 12;
        const endDateLeft = dayWidth * endDateDaysDiff + dayWidth / 2 - widthOfFlagElement / 2; // try to center the flag at the correct day
        return (
            <div
                key={key}
                className="svg-icon-xs common-color-black tnk-tooltip mod-absolute"
                style={{ top: 0, left: endDateLeft }}
            >
                {svgIconElement}
                <span className="tnk-tooltip-text">{name}</span>
            </div>
        );
    }

    createItem(initiative, selectedGroupBy, parentInitiative) {
        selectedGroupBy = selectedGroupBy || this.state.selectedGroupBy;
        const groupId = selectedGroupBy.groupIdRetriever(initiative);
        const startTimeAccess = this.getStartTimeAccess(groupId);
        const endTimeAccess = this.getEndTimeAccess(groupId);
        const parentEndDate = parentInitiative && endTimeAccess.get(parentInitiative);
        // If this is a mock item we need to go get it from the server and put a placholder
        if (this.isLoadingInitiative(initiative)) {
            const item = {
                id: initiative.id,
                isLoading: true,
            };
            if (parentInitiative) {
                const parentItem = Utils.findFirstById(this.state.items, parentInitiative.id);
                item.parentId = parentItem.id;
                item.start_time = parentItem.start_time; // eslint-disable-line camelcase
                item.end_time = parentEndDate; // eslint-disable-line camelcase
                item.group = parentItem.group;
            }

            return item;
        }

        let startTime;
        let endTime;
        const startDate = startTimeAccess.get(initiative);
        const endDate = endTimeAccess.get(initiative);
        if (this.selectedItem && this.selectedItem.id === initiative.id) {
            startTime = moment(startDate);
            endTime = moment(endDate || parentEndDate);
        } else {
            const minMax = this.getMinMaxDatesRecursively(
                initiative,
                parentInitiative,
                selectedGroupBy ? selectedGroupBy.allowExpand(groupId) : true,
            );
            startTime = moment(new Date(minMax.min));
            endTime = moment(new Date(minMax.max || parentEndDate));
        }
        startTime = startTime.startOf('day');
        endTime = endTime.endOf('day');
        const endTimeBeforeStartTime = endTime.isBefore(startTime);

        const otherEvents = [];

        for (let i = 0; i < initiative.fields.length; i++) {
            const field = initiative.fields[i];
            if (!field.dateValue || endTimeAccess.id === field.id) {
                continue;
            }

            otherEvents.push({
                id: field.id,
                date: moment(field.dateValue),
                name: field.fieldDefinition.name,
            });
        }

        return {
            id: initiative.id,
            parentId: parentInitiative ? parentInitiative.id : null,
            group: groupId,
            title: initiative.title,
            owner: initiative.owner,
            stateColor: initiative.stateColor,
            endTimeBeforeStartTime,
            start_time: endTimeBeforeStartTime ? endTime : startTime, // eslint-disable-line camelcase
            end_time: endTimeBeforeStartTime ? startTime : endTime, // eslint-disable-line camelcase
            childrenCount: this.getChildrenRecursively(initiative).length,
            isExpanded: false,
            isEndDateMissing: !endDate,
            otherEvents,
        };
    }

    getChildrenRecursively(initiative) {
        const children = initiative.relatedInitiatives;
        if (!children || !children.length) {
            return [];
        }
        let allChildren = children;
        for (const child of children) {
            const childChildren = this.getChildrenRecursively(child);
            if (childChildren && childChildren.length) {
                allChildren = allChildren.concat(childChildren);
            }
        }

        return allChildren;
    }

    getParentsRecursively(initiative, arrayToFill) {
        if (!arrayToFill) {
            arrayToFill = [];
        }

        if (!initiative.parent) {
            return arrayToFill;
        }

        arrayToFill.push(initiative.parent);
        return this.getParentsRecursively(initiative.parent, arrayToFill);
    }

    getMinMaxDatesRecursively(initiative, parentInitiative, includeChildren, selectedGroupBy) {
        // If there is a valid value in the map - use it
        if (this.minMaxMap[initiative.id] && this.minMaxMap[initiative.id].max && this.minMaxMap[initiative.id]) {
            return this.minMaxMap[initiative.id];
        }

        selectedGroupBy = selectedGroupBy || this.state.selectedGroupBy;

        const startDateAccess = this.getStartTimeAccess(selectedGroupBy.groupIdRetriever(initiative));
        const startDate = startDateAccess.get(initiative);
        const endDateAccess = this.getEndTimeAccess(selectedGroupBy.groupIdRetriever(initiative));
        const endDate = endDateAccess.get(initiative);

        let min = startDate || (parentInitiative && startDateAccess.get(parentInitiative));
        let max = endDate || (parentInitiative && endDateAccess.get(parentInitiative));

        const startTime = initiative.startTime || initiative.created;
        if (startTime) {
            if (startTime < min) {
                min = startTime;
            }
            if (!max || startTime > max) {
                max = startTime;
            }
        }

        if (initiative.dueDate) {
            if (initiative.dueDate < min) {
                min = initiative.dueDate;
            }
            if (!max || initiative.dueDate > max) {
                max = initiative.dueDate;
            }
        }

        if (initiative.fields) {
            for (let i = 0; i < initiative.fields.length; i++) {
                const field = initiative.fields[i];
                if (field.dateValue) {
                    if (field.dateValue < min) {
                        min = field.dateValue;
                    }
                    if (!max || field.dateValue > max) {
                        max = field.dateValue;
                    }
                }
            }
        }

        // The related initiatives might on the object might not be the ones in the state
        let children = initiative.relatedInitiatives;
        if (includeChildren && children) {
            children = children.map((child) => Utils.findFirstById(this.state.initiatives, child.id) || child);
            for (const child of children) {
                const childMinMax = this.getMinMaxDatesRecursively(child, initiative, includeChildren);
                if (childMinMax.min < min) {
                    min = childMinMax.min;
                }

                if (!max || childMinMax.max > max) {
                    max = childMinMax.max;
                }
            }
        }

        const minMax = {
            min,
            max,
        };
        this.minMaxMap[initiative.id] = minMax;

        return minMax;
    }

    countShownChildItemsRecursively(itemId) {
        let shownChildrenCount = 0;
        const initiative = Utils.findFirstById(this.state.initiatives, itemId);
        if (initiative.relatedInitiatives) {
            for (let i = 0; i < initiative.relatedInitiatives.length; i++) {
                const child = initiative.relatedInitiatives[i];
                const childInUi = Utils.findFirstById(this.state.items, child.id);
                if (childInUi && childInUi.parentId === initiative.id) {
                    // make sure the shown child is under the expected parent
                    shownChildrenCount += 1;
                    shownChildrenCount += this.countShownChildItemsRecursively(child.id);
                }
            }
        }

        return shownChildrenCount;
    }

    chooseGroupBy(n) {
        const selectedGroupBy = this.availableGroupBys[n];
        const groupByData = this.calculateDataInGroupBy(selectedGroupBy);

        this.setState({
            selectedGroupBy,
            initiatives: groupByData.initiatives,
            groups: groupByData.groups,
            groupByDropdownOpen: false,
        });
        this.setItems(groupByData.items);
    }

    calculateDataInGroupBy(selectedGroupBy) {
        // Reset initiatives
        this.minMaxMap = {}; // initiativeId to minMax dates to cover
        const relatedInitiatives = selectedGroupBy.getRelatedInitiatives();
        const newGroups = selectedGroupBy.getRelatedGroups(relatedInitiatives);
        const newItems = relatedInitiatives.map((initiative) => {
            this.getMinMaxDatesRecursively(
                initiative,
                initiative.parent,
                selectedGroupBy.allowExpand(selectedGroupBy.groupIdRetriever(initiative)),
                selectedGroupBy,
            ); // cache all initiatives minMax
            return this.createItem(initiative, selectedGroupBy);
        });

        return {
            groups: newGroups,
            initiatives: relatedInitiatives,
            items: newItems,
        };
    }

    setItems(items) {
        this.setState({
            items: items.filter((item) => {
                if (!item) {
                    return false;
                }

                return true;
            }),
        });
    }

    setStartDateSelectionIsOpenFor(groupId) {
        this.setState({ startDateSelectionIsOpenForGroupId: groupId });
    }

    setEndDateSelectionIsOpenFor(groupId) {
        this.setState({ endDateSelectionIsOpenForGroupId: groupId });
    }

    toggleGroupByDropdown() {
        this.setState({ groupByDropdownOpen: !this.state.groupByDropdownOpen });
    }

    toggleListFilterDropdown() {
        this.setState({ isListFilterDropdownOpen: !this.state.isListFilterDropdownOpen });
    }

    toggleHoverTip(state) {
        this.setState({ isHoverTipShown: state });
    }

    /**
     * Returns true if the initiative is not a full initiative from the server but an app placeholder
     * @param initiative
     * @returns {boolean}
     */
    isLoadingInitiative(initiative) {
        return !initiative.title;
    }

    onZoom(timelineContext) {
        const daysShown = moment(timelineContext.visibleTimeEnd).diff(
            moment(timelineContext.visibleTimeStart),
            'days',
            true,
        );
        this.setState({ daysShown });
        this.setItems([...this.state.items]); // force another render because this happens after the setState...
    }

    onGroupFilterChecked(event) {
        const hiddenGroupsMap = Object.assign({}, this.state.hiddenGroupsMap);
        hiddenGroupsMap[event.target.name] = !event.target.checked;
        this.setState({
            hiddenGroupsMap,
        });
    }

    renderFilterListsDropdown() {
        const groupsIls = [];
        for (let i = 0; i < this.state.groups.length; i++) {
            const group = this.state.groups[i];
            groupsIls.push(
                <li key={`filterLists-group-${group.id}`}>
                    <a>
                        <label>
                            <input
                                name={group.id}
                                type="checkbox"
                                checked={!this.state.hiddenGroupsMap[group.id]}
                                onChange={this.onGroupFilterChecked.bind(this)}
                            />
                            <span className="margin-left">{group.title}</span>
                        </label>
                    </a>
                </li>,
            );
        }

        let iconColorClass = 'common-color-light-grey2';
        for (let i = 0; i < Utils.objKeys(this.state.hiddenGroupsMap).length; i++) {
            const key = Utils.objKeys(this.state.hiddenGroupsMap)[i];
            if (this.state.hiddenGroupsMap[key]) {
                iconColorClass = 'common-color-primary';
            }
        }

        const iconClasses = `padding-right-xs svg-icon ${iconColorClass}`;

        return (
            <div className="dropdown open pointer">
                <div
                    onClick={this.toggleListFilterDropdown.bind(this)}
                    className="dropdown-toggle flex-vmiddle common-color-grey3"
                >
                    <div className={iconClasses}>
                        <span className="tnk-icon">
                            <FilterIcon />
                        </span>
                    </div>
                </div>
                {this.state.isListFilterDropdownOpen && (
                    <Dropdown onClickOutside={this.toggleListFilterDropdown.bind(this)}>{groupsIls}</Dropdown>
                )}
            </div>
        );
    }

    renderEditModeTip() {
        let classes = 'timeline-edit-mode-tip';

        if (this.selectedItem) {
            this.editTipText = 'Click outside to exit edit mode';
        } else if (this.state.isHoverTipShown) {
            this.editTipText = "Click on a track to edit it's start and end dates";
        } else {
            classes += ' mod-hidden';
        }

        return <div className={classes}>{this.editTipText}</div>;
    }

    onItemSelected(itemId) {
        const item = Utils.findFirstById(this.state.items, itemId);
        const initiative = Utils.findFirstById(this.state.initiatives, itemId);
        const startDateAccess = this.getStartTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative));
        const startDate = startDateAccess.get(initiative);
        const previousSelectedId = this.selectedItem ? this.selectedItem.id : null;
        this.selectedItem = item;

        const items = this.state.items.map((item) => {
            if (item.id === itemId) {
                return Object.assign({}, item, {
                    // eslint-disable-next-line camelcase
                    start_time: moment(startDate).startOf('day'),
                    // eslint-disable-next-line camelcase
                    end_time: moment(
                        this.getEndTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative)).get(initiative),
                    ).endOf('day'),
                });
            } else if (previousSelectedId && item.id === previousSelectedId) {
                return this.resetItemTimes(item);
            }
            return item;
        });

        this.setState({ isHoverTipShown: false });
        this.setItems(items);

        console.log(`selected ${item.title}`);
    }

    onItemDeselected() {
        console.log('item deselect');
        if (this.selectedItem) {
            this.setItems(
                this.state.items.map((item) => {
                    return item.id === this.selectedItem.id ? this.resetItemTimes(item) : item;
                }),
            );

            this.selectedItem = null;
        }
    }

    resetItemTimes(item) {
        const initiative = Utils.findFirstById(this.state.initiatives, item.id);
        const selectedGroupBy = this.state.selectedGroupBy;
        const minMax = this.getMinMaxDatesRecursively(
            initiative,
            initiative.parent,
            selectedGroupBy.allowExpand(selectedGroupBy.groupIdRetriever(initiative)),
        );
        return Object.assign({}, item, {
            // eslint-disable-next-line camelcase
            start_time: moment(minMax.min).startOf('day'),
            // eslint-disable-next-line camelcase
            end_time: moment(minMax.max).endOf('day'),
        });
    }

    onItemMove(itemId, newStartTime) {
        const item = Utils.findFirstById(this.state.items, itemId);
        const initiative = Utils.findFirstById(this.state.initiatives, itemId);
        const startDateAccess = this.getStartTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative));
        const startDate = startDateAccess.get(initiative);

        const currentEndDate = this.getEndTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative)).get(
            initiative,
        );
        const itemDurationInDays = moment(currentEndDate).endOf('day').diff(moment(startDate).startOf('day'), 'days');
        newStartTime = moment(newStartTime).startOf('day');
        const newEndDate = moment(newStartTime).add(itemDurationInDays, 'days');
        this.setInitiativeDates(itemId, newStartTime, newEndDate, item);
    }

    onItemResize(itemId, time, edge) {
        if (edge === 'left') {
            const newStartTime = moment(time).add(1, 'second'); // for some reason the time provided here is yesterday at 23:59:59
            this.setInitiativeDates(itemId, newStartTime, null);
        }
        if (edge === 'right') {
            this.setInitiativeDates(itemId, null, time);
        }
    }

    onItemDoubleClick(itemId) {
        this.props.modalUtils.openViewInitiative(itemId);
    }

    setInitiativeDates(itemId, newStartTime, newEndDate) {
        const initiative = Utils.findFirstById(this.state.initiatives, itemId);

        if (newStartTime) {
            initiative.startTime = new Date(newStartTime).getTime();
            this.props.trackHelper.updateStartTime(itemId, newStartTime, true);
        }

        if (newEndDate) {
            this.getEndTimeAccess(this.state.selectedGroupBy.groupIdRetriever(initiative)).set(initiative, newEndDate);
        }

        // Reset the minMaxMap for the item and all the item's parents so the children box and past\future indicators would update
        this.minMaxMap[itemId] = null;
        const parents = this.getParentsRecursively(initiative) || [];
        if (parents.length) {
            for (const parent of parents) {
                this.minMaxMap[parent.id] = null;
            }
            // let rootParent = Utils.findFirst(parents, parent => !parent.parent);
        }

        const itemsIdsToUpdate = parents.map((parent) => parent.id);
        itemsIdsToUpdate.push(itemId);

        this.setItems(
            this.state.items.map((item) => {
                if (itemsIdsToUpdate.includes(item.id)) {
                    let itemInitiative;
                    if (item.id === itemId) {
                        itemInitiative = initiative;
                    } else {
                        itemInitiative = Utils.findFirstById(parents, item.id);
                    }

                    const parentInitiative = item.parentId
                        ? Utils.findFirstById(this.state.initiatives, item.parentId)
                        : null;
                    const createdItem = this.createItem(itemInitiative, this.state.selectedGroupBy, parentInitiative);
                    createdItem.isExpanded = item.isExpanded;
                    return createdItem;
                }
                return item;
            }),
        );
    }

    getStartTimeAccess(groupId) {
        // If doesn't exists - set a default one for due date
        if (!this.startTimeAccessToGroupMap[groupId]) {
            this.startTimeAccessToGroupMap[groupId] = this.getDefaultStartTimeAccess();
        }

        return this.startTimeAccessToGroupMap[groupId];
    }

    getEndTimeAccess(groupId) {
        // If doesn't exists - set a default one for due date
        if (!this.endTimeAccessToGroupMap[groupId]) {
            this.endTimeAccessToGroupMap[groupId] = this.getDefaultEndTimeAccess();
        }

        return this.endTimeAccessToGroupMap[groupId];
    }

    setStartDateAccessForGroup(groupId, startTimeAccess) {
        this.startTimeAccessToGroupMap[groupId] = startTimeAccess;

        // Use props because we are resetting the view.
        // If we use state we might render a child initiative that we are not suppose to,
        // and then it is not located correctly and if the user opens the parent there would be a duplicate
        this.setItems(
            this.props.initiatives.map((initiative) => {
                if (this.state.selectedGroupBy.groupIdRetriever(initiative) === groupId) {
                    this.minMaxMap[initiative.id] = null;
                }

                return this.createItem(initiative, this.state.selectedGroupBy, initiative.parent);
            }),
        );
    }

    setEndDateAccessForGroup(groupId, endTimeAccess) {
        this.endTimeAccessToGroupMap[groupId] = endTimeAccess;

        // Use props because we are resetting the view.
        // If we use state we might render a child initiative that we are not suppose to,
        // and then it is not located correctly and if the user opens the parent there would be a duplicate
        this.setItems(
            this.props.initiatives.map((initiative) => {
                if (this.state.selectedGroupBy.groupIdRetriever(initiative) === groupId) {
                    this.minMaxMap[initiative.id] = null;
                }

                return this.createItem(initiative, this.state.selectedGroupBy, initiative.parent);
            }),
        );
    }

    getDefaultStartTimeAccess() {
        return {
            id: 'started',
            name: 'Started',
            allowExpand: true,
            get: (initiative) => initiative.startTime || initiative.created,
            set: (initiative, value) => {
                // Update in ui - make sure its a timestamp
                initiative.startTime = new Date(value).getTime();

                // update server
                return this.props.trackHelper.updateStartTime(initiative.id, value, true);
            },
        };
    }

    getDefaultEndTimeAccess() {
        return {
            id: 'due_date',
            name: 'Due Date',
            allowExpand: true,
            get: (initiative) => initiative.dueDate,
            set: (initiative, value) => {
                // Update in ui - make sure its a timestamp
                initiative.dueDate = new Date(value).getTime();

                // update server
                return this.props.trackHelper.updateDueDate(initiative.id, value, true);
            },
        };
    }

    getAccessForFieldDefinition(fieldDefinition) {
        return {
            id: fieldDefinition.id,
            name: fieldDefinition.name,
            allowExpand: true,
            get: (initiative) => {
                if (initiative.fields && initiative.fields.length) {
                    const field = Utils.findFirst(
                        initiative.fields,
                        (field) => field.fieldDefinition.id === fieldDefinition.id,
                    );
                    const value = field && (field.dateValue || field.valueDate);
                    if (value) {
                        return new Date(value);
                    }
                }

                // If not found, it will be hidden
                return null;
            },
            set: (initiative, value) => {
                const field = Utils.findFirst(
                    initiative.fields,
                    (field) => field.fieldDefinition.id === fieldDefinition.id,
                );
                field.value = value;
                field.dateValue = value;
                return this.props.trackHelper.updateInitiativeDataTile(initiative.id, field, value, field.externalId);
            },
        };
    }

    onBoundsChange(canvasTimeStart, canvasTimeEnd) {
        this.props.loadMoreInitiatives({ from: Math.floor(canvasTimeStart), to: Math.floor(canvasTimeEnd) });
    }

    render() {
        const startTime = moment().startOf('week');
        const sidebarContent = this.state.selectedGroupBy && (
            <div className="flex-vmiddle mod-justify-space common-height-full margin-top">
                <div className="dropdown open margin-top-xs padding-top-xxs margin-left">
                    <div
                        onClick={this.toggleGroupByDropdown.bind(this)}
                        className="dropdown-toggle flex-vmiddle common-color-light-grey2"
                    >
                        <div className="common-color-light-grey">Group by</div>
                        <div className="padding-left-xs common-color-black">
                            {this.state.selectedGroupBy.displayName}
                        </div>
                    </div>
                    {this.state.groupByDropdownOpen && (
                        <Dropdown onClickOutside={this.toggleGroupByDropdown.bind(this)}>
                            <li onClick={this.chooseGroupBy.bind(this, 0)}>
                                <a>List</a>
                            </li>
                            <li onClick={this.chooseGroupBy.bind(this, 1)}>
                                <a>Owner</a>
                            </li>
                        </Dropdown>
                    )}
                </div>

                {this.renderFilterListsDropdown()}
            </div>
        );

        const filteredGroups = this.state.groups.filter((group) => !this.state.hiddenGroupsMap[group.id]);
        return (
            <div className="relative">
                {this.renderEditModeTip()}
                {this.state.selectedGroupBy && (
                    <div>
                        <div className="timeline-weekstart-sunday">
                            <Timeline
                                groups={filteredGroups}
                                items={this.state.items}
                                defaultTimeStart={startTime.toDate()}
                                defaultTimeEnd={startTime.add(this.state.daysShown, 'days').toDate()}
                                stackItems="force"
                                selected={this.selectedItem ? [this.selectedItem.id] : []}
                                lineHeight={this.lineHeight}
                                itemHeightRatio={this.itemHeightRatio}
                                subHeaderLabelFormats={this.customSubHeaderLabelFormats}
                                itemRenderer={this.itemRenderer}
                                canChangeGroup={false}
                                canResize="both"
                                onCanvasClick={() => this.onItemDeselected()}
                                onBoundsChange={this.onBoundsChange.bind(this)}
                                onItemClick={this.nothing}
                                onItemMove={this.onItemMove.bind(this)}
                                onItemResize={this.onItemResize.bind(this)}
                                onItemDoubleClick={this.onItemDoubleClick.bind(this)}
                                headerLabelHeight={40}
                                minGroupHeight={100}
                                groupRenderer={this.groupRenderer}
                                sidebarContent={sidebarContent}
                                onZoom={this.onZoom.bind(this)}
                                minZoom={60 * 60 * 1000 * 24 * 7} // 7 day
                                dragSnap={60 * 60 * 1000 * 24} // 1 day
                                onItemDeselect={this.onItemDeselected.bind(this)}
                                useResizeHandle
                                canMove
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
