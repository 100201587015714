<div class="flex-vmiddle">
    <!--<strong>{{item.actor.name}}</strong>-->
    <!--<ANY ng-switch="item.type">-->
    <!--<ANY ng-switch-when="COMMIT">-->
    <!--<i class="fa fa-code common-color-grey" uib-tooltip="New commit"></i>-->
    <!--</ANY>-->
    <!--</ANY>-->
    <div class="flex-grow margin-left">
        <a ng-href="{{ item.externalUrl }}" target="_blank" class="common-color-dark-grey">{{ item.text.trim() }}</a>
        <i
            ng-if="item.description && item.description.length"
            class="common-color-grey common-size-xxxxs pointer padding-left-xs fa fa-align-left"
            uib-popover="{{ item.description }}"
            popover-is-open="data.popoverIsOpen"
            popover-trigger="mouseenter"
            popover-placement="auto right"
        ></i>
    </div>
    <span class="common-size-xxxxs common-color-light-grey hidden-xs" ng-if="item.tags && item.tags.length">
        <span ng-repeat="tag in item.tags" class="margin-right-lg">{{ tag }}{{ !$last ? ', ' : '' }}</span>
    </span>
    <i
        ng-if="item.source"
        uib-tooltip="{{ item.source.toLowerCase() }}"
        class="hidden-xs integration-group-icon margin-left-xlg margin-right-xlg flex-no-shrink mod-{{
            item.source.toLowerCase()
        }}"
    ></i>
</div>
