import { useMemo } from 'react';

import type { ContractFieldMapping, FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Truthy } from '@tonkean/utils';

export const useGetContractFieldIdToOptions = (
    allowRepeatedFields: boolean,
    entities: { id: string }[],
    fieldMappings: ContractFieldMapping[],
    isLoading: boolean,
) => {
    const idToEntity: Record<string, { id: string }> = useMemo(() => {
        if (allowRepeatedFields) {
            return {};
        }

        return entities.reduce((acc, entity) => {
            acc[entity.id] = entity;
            return acc;
        }, {});
    }, [allowRepeatedFields, entities]);

    return useMemo(() => {
        if (isLoading) return {};
        if (allowRepeatedFields) {
            return fieldMappings.reduce((acc, singleMapping) => {
                acc[singleMapping.contractFieldId] = entities;
                return acc;
            }, {});
        }

        const usedTargetIds: Set<string> = new Set(
            fieldMappings.map((fieldMapping) => fieldMapping.targetId?.toString()).filter(Truthy),
        );

        const unusedTargetIds = entities.filter((fieldDefinition) => {
            return !usedTargetIds.has(fieldDefinition.id.toString());
        });

        const contractFieldIdToOptions: Record<
            TonkeanId<TonkeanType.CONTRACT_FIELD>,
            FieldDefinition[]
        > = fieldMappings.reduce((acc, singleMapping) => {
            acc[singleMapping.contractFieldId] = singleMapping.targetId
                ? [...unusedTargetIds, idToEntity[singleMapping.targetId]]
                : unusedTargetIds;
            return acc;
        }, {});

        return contractFieldIdToOptions;
    }, [allowRepeatedFields, idToEntity, entities, fieldMappings, isLoading]);
};
