import { lateConstructController } from '@tonkean/angular-components';
import { range } from '@tonkean/utils';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

/* @ngInject */
function WorkflowVersionsListFilterCtrl($scope, $stateParams, consts, workflowVersionManager) {
    const ctrl = this;

    $scope.data = {
        filterType: ctrl.filterType,
        appendFilterDialogToBody: ctrl.appendFilterDialogToBody,
        tooltipDirection: ctrl.tooltipDirection,

        from: ctrl.from,
        to: ctrl.to,
        publisher: ctrl.publisher ? [ctrl.publisher] : [],
        sequentialIdentifier: ctrl.sequentialIdentifier,

        activityType: ctrl.activityType,
        actor: ctrl.actor ? [ctrl.actor] : [],
        fromDate: ctrl.fromDate,
        toDate: ctrl.toDate,
        subSequentialIdentifier: ctrl.subSequentialIdentifier,

        activityTypes: consts.getTriggerActivityActionTypes(),
        versions: getVersions(ctrl.workflowVersionId, ctrl.shouldLoadCommitActivities),

        onSaveFilter: ctrl.onSaveFilter,
    };

    function getVersions(workflowVersionId, shouldLoadCommitActivities) {
        const groupId = $stateParams.g;
        const workflowVersionID = workflowVersionId != undefined ? workflowVersionId : $stateParams.historyVersion;

        // When looking at draft publish or history version we will display commit versions to filter by.
        if (workflowVersionID) {
            const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionID);

            // If you are looking at old version or publish, or Draft including commit activities
            if (
                workflowVersion?.maxSubSequentialIdentifier > 0 &&
                (workflowVersion?.workflowVersionType !== WorkflowVersionType.DRAFT || shouldLoadCommitActivities)
            ) {
                // In old versions and publish the sequentialIdentifier is a version back
                // i.e. if you are looking at version 10 all the commits are 9.1, 9.2...
                // In case of Draft use the sequentialIdentifier of the last publish
                const published = workflowVersionManager.getPublishedVersionFromCache(groupId);

                const sequentialIdentifier =
                    workflowVersion.workflowVersionType !== WorkflowVersionType.DRAFT
                        ? workflowVersion.sequentialIdentifier - 1
                        : published?.sequentialIdentifier ?? 0;

                const subSequentialIdentifierOptions = range(1, workflowVersion.maxSubSequentialIdentifier + 1)
                    .map((subSequentialIdentifier) => ({
                        label: `Version ${sequentialIdentifier}.${subSequentialIdentifier}`,
                        value: `${subSequentialIdentifier}`,
                    }))
                    .reverse();

                const pendingChangesOptions = {
                    label: 'Pending changes',
                    value: '0',
                };
                return [pendingChangesOptions, ...subSequentialIdentifierOptions];
            }
        }
    }

    ctrl.$onChanges = (changesObj) => {
        if (changesObj.filterType) {
            $scope.data.filterType = changesObj.filterType.currentValue;
        }
        if (changesObj.appendFilterDialogToBody) {
            $scope.data.appendFilterDialogToBody = changesObj.appendFilterDialogToBody.currentValue;
        }
        if (changesObj.tooltipDirection) {
            $scope.data.tooltipDirection = changesObj.tooltipDirection.currentValue;
        }

        if (changesObj.from) {
            $scope.data.from = changesObj.from.currentValue;
        }
        if (changesObj.to) {
            $scope.data.to = changesObj.to.currentValue;
        }
        if (changesObj.publisher) {
            $scope.data.publisher = changesObj.publisher.currentValue ? [changesObj.publisher.currentValue] : [];
        }
        if (changesObj.sequentialIdentifier) {
            $scope.data.sequentialIdentifier = changesObj.sequentialIdentifier.currentValue;
        }

        if (changesObj.activityType) {
            $scope.data.activityType = changesObj.activityType.currentValue;
        }
        if (changesObj.actor) {
            $scope.data.actor = changesObj.actor.currentValue;
        }
        if (changesObj.fromDate) {
            $scope.data.fromDate = changesObj.fromDate.currentValue ? [changesObj.publisher.currentValue] : [];
        }
        if (changesObj.toDate) {
            $scope.data.toDate = changesObj.toDate.currentValue;
        }

        if (changesObj.onSaveFilter) {
            $scope.data.onSaveFilter = changesObj.onSaveFilter.currentValue;
        }
    };

    $scope.save = () => {
        if ($scope.data.filterType === 'versions') {
            $scope.data.onSaveFilter({
                from: $scope.data.from,
                to: $scope.data.to,
                publisher: $scope.data.publisher[0],
                sequentialIdentifier: $scope.data.sequentialIdentifier,
            });
        } else {
            $scope.data.onSaveFilter({
                activityType: $scope.data.activityType,
                actor: $scope.data.actor[0],
                fromDate: $scope.data.fromDate,
                toDate: $scope.data.toDate,
                subSequentialIdentifier: $scope.data.subSequentialIdentifier?.value,
            });
        }

        $scope.data.filterPopoverOpen = false;
    };

    $scope.clear = () => {
        if ($scope.data.filterType === 'versions') {
            $scope.data.from = undefined;
            $scope.data.to = undefined;
            $scope.data.publisher = [];
            $scope.data.sequentialIdentifier = undefined;
        } else {
            $scope.data.activityType = undefined;
            $scope.data.actor = [];
            $scope.data.fromDate = undefined;
            $scope.data.toDate = undefined;
            $scope.data.subSequentialIdentifier = undefined;
        }

        $scope.save();
    };
}

export default angular
    .module('tonkean.app')
    .controller('workflowVersionsListFilterCtrl', lateConstructController(WorkflowVersionsListFilterCtrl));
