<span class="flex-vmiddle margin-bottom-xs multi-form-selection" ng-repeat="form in data.forms">
    <!-- Form selector -->
    <tnk-form-selector
        class="single-form-select"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        selected-form-id="form.id"
        on-form-selected-parameter="($index)"
        do-not-include-form-ids-set="data.selectedFormIdsSet"
        on-form-selected="onFormSelected(form, onFormSelectedParameter)"
        automation-identifier="form-selector-open-form-drop-down"
        hide-file-upload-forms="data.hideFileUploadForms"
        hide-create-forms="data.hideCreateForms"
    ></tnk-form-selector>

    <!-- Remove form -->
    <div class="margin-left">
        <div
            class="
                svg-icon-xs
                common-color-grey
                svg-icon-hover-primary svg-icon-align-text-base
                btn-on-hover
                pointer
                flex-no-shrink
            "
            ng-click="removeForm($index)"
            uib-tooltip="Delete Form"
            tooltip-placement="auto"
            tooltip-append-to-body="true"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>
    </div>
</span>

<!-- Add form -->
<div class="flex-grow">
    <a ng-click="addForm()" ng-if="data.forms.length <= 5">+ Add form</a>
</div>
