import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SimpleNameEditorCtrl($scope) {
    const ctrl = this;
    $scope.data = {
        editMode: false,
        displayName: ctrl.displayName,
        editingDisplayName: ctrl.displayName,
        itemId: ctrl.itemId,
        isDisabled: ctrl.isDisabled || false,
        disabledTooltip: ctrl.disabledTooltip,
        isWorkflowFolderHidden: ctrl.isWorkflowFolderHidden,
        errorSavingDisplayName: null,
        loadingDisplayName: false,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.displayName) {
            $scope.data.displayName = changesObj.displayName.currentValue;
        }
    };

    /**
     * Toggles the state of the display name edit.
     */
    $scope.toggleEditMode = function ($event) {
        if ($scope.data.isDisabled || $scope.data.isWorkflowFolderHidden) {
            $event.stopPropagation();
            return;
        }

        $scope.data.editMode = !$scope.data.editMode;
    };

    $scope.updateDisplayName = function () {
        if (ctrl.onSave) {
            $scope.data.loadingDisplayName = true;
            ctrl.onSave({ itemId: $scope.data.itemId, displayName: $scope.data.editingDisplayName })
                .then(() => {
                    $scope.data.editMode = false;
                })
                .catch(() => {
                    $scope.data.errorSavingDisplayName = 'An error accord while updating solution name';
                })
                .finally(() => {
                    $scope.data.loadingDisplayName = false;
                });
        }
    };
}

export default angular.module('tonkean.app').controller('SimpleNameEditor', lateConstructController(SimpleNameEditorCtrl));
