import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ManageEnterpriseCtrl($scope, $stateParams, $state) {
    $scope.data = {
        barItems: [
            { name: 'overview', displayName: 'Overview' },
            { name: 'boards', displayName: 'Manage Boards' },
            { name: 'members', displayName: 'Manage Members' },
            { name: 'billing', displayName: 'Billing' },
        ],
        selectedBarItem: '',
    };

    $scope.init = function () {
        let startFrom = $scope.data.barItems[0];

        if ($stateParams.enterpriseTab && $stateParams.enterpriseTab.length) {
            // see if we have a tab selected
            for (let i = 0; i < $scope.data.barItems.length; i++) {
                const item = $scope.data.barItems[i];
                if (item.name === $stateParams.enterpriseTab) {
                    startFrom = item;
                    break;
                }
            }
        }

        $scope.data.selectedBarItem = startFrom;
    };

    $scope.selectBarItem = function (barItem) {
        $state.go('enterprise', { enterpriseTab: barItem.name });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageEnterpriseCtrl', lateConstructController(ManageEnterpriseCtrl));
