import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import AmazonS3PermissionType from './AmazonS3PermissionType';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

interface Bucket {
    label: string;
    value: string;
}

interface SetupProjectData {
    bucket: string | undefined;
    permissionType: AmazonS3PermissionType | undefined;
}

interface ProjectData {
    bucketsIds: string[];
    permissionType: AmazonS3PermissionType | undefined;
}

const AmazonS3CustomizeSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
    projectIntegrationData,
}) => {
    const [bucketsList, setBucketsList] = useState<Bucket[]>([]);
    const [selectedBuckets, setSelectedBuckets] = useState<Bucket[]>([]);
    const projectManager = useAngularService('projectManager');
    const [enteredBuckets, setEnteredBuckets] = useState<string | undefined>();
    const [permissionType, setPermissionType] = useState<AmazonS3PermissionType | undefined>();

    // Loading the available buckets from Amazon (auto complete options).
    useEffect(() => {
        const init = async () => {
            // Fetching all the available buckets from Amazon's API.
            const projectData = projectIntegration?.projectData?.projectDatas[0];
            const setupProjectData = projectIntegrationData as SetupProjectData;

            if (setupProjectData) {
                setPermissionType(setupProjectData.permissionType);
            } else {
                setPermissionType(projectData?.permissionType || AmazonS3PermissionType.GLOBAL);
            }

            if (setupProjectData?.bucket) {
                updateEnteredBuckets(setupProjectData.bucket);
                setPermissionType(AmazonS3PermissionType.RESTRICTED);
            } else {
                try {
                    const bucketsResults = await createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        integration.id,
                        'buckets',
                        {},
                    );

                    // Constructs the results to fits the dropdown model.
                    const constructedBuckets: Bucket[] = constructAutoCompleteOptions(bucketsResults.options);
                    if (bucketsResults.options) {
                        setBucketsList(constructedBuckets);
                    }
                    if (projectData?.bucketsIds) {
                        setSelectedBuckets(
                            constructedBuckets.filter((bucket) => projectData.bucketsIds.includes(bucket.value)),
                        );
                        updateEnteredBuckets(selectedBuckets[0]?.label);
                    }
                } catch (autoCompleteError) {
                    const errorMessage = (autoCompleteError as IHttpResponse<any>)?.data?.data?.error?.cause?.message;
                    console.log(`Error while trying to list buckets. ${errorMessage}`);

                    if (projectData?.bucketsIds) {
                        setEnteredBuckets(projectData.bucketsIds);
                        updateEnteredBuckets(projectData.bucketsIds.join(','));
                    }
                }
            }
        };
        init();
    }, [
        createProjectApis,
        integration.id,
        projectIntegration,
        projectIntegrationData,
        projectManager.project.id,
        selectedBuckets,
    ]);

    const updateEnteredBuckets = (buckets) => {
        setEnteredBuckets(buckets);

        const bucketsList: Bucket[] = buckets.split(',').map((bucket) => ({
            value: bucket.trim(),
            label: bucket.trim(),
        }));

        setSelectedBuckets(bucketsList);
    };

    const onBucketSelected = useCallback((buckets) => {
        setSelectedBuckets(buckets);
    }, []);

    const onBucketsEntered = useCallback((buckets) => {
        updateEnteredBuckets(buckets);
    }, []);

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
        }));
    };

    useEffect(() => {
        const newProjectIntegrationData: ProjectData = {
            bucketsIds: selectedBuckets.map((bucket) => bucket.value),
            permissionType,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: newProjectIntegrationData,
            },
            false,
        );
    }, [onChangeOrInitIntegration, permissionType, selectedBuckets]);

    return (
        <div className="flex flex-col">
            {permissionType === AmazonS3PermissionType.GLOBAL && (
                <div className="flex flex-col">
                    <span className="control-label margin-bottom">
                        Select buckets you would like to collect files from
                    </span>
                    <TnkSelect
                        options={bucketsList}
                        onChange={onBucketSelected}
                        value={selectedBuckets}
                        isMulti
                        isSearchable
                    />
                </div>
            )}
            {permissionType === AmazonS3PermissionType.RESTRICTED && (
                <div className="flex flex-col">
                    <span className="control-label margin-bottom">Bucket</span>
                    <input
                        type="text"
                        value={enteredBuckets}
                        className="form-control"
                        onChange={({ target }) => onBucketsEntered(target.value)}
                        autoComplete="off"
                    />
                </div>
            )}
        </div>
    );
};

export default AmazonS3CustomizeSetupComponent;
