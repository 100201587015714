import ServicenowAuthenticationComponent from './ServicenowAuthenticationComponent';
import ServicenowSetupComponent from './ServicenowSetupComponent';
import servicenowCircleIcon from '../../../../apps/tracks/images/integrations/servicenow-circle.png';
import servicenowIcon from '../../../../apps/tracks/images/servicenow.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class ServicenowIntegration extends IntegrationDefinitionBase {
    override name = 'servicenow';
    override displayName = 'ServiceNow';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Case', 'Cases'),
        new IntegrationEntity('CatalogTask', 'Catalog Tasks'),
        new IntegrationEntity('Group', 'Groups'),
        new IntegrationEntity('Incident', 'Incidents'),
        new IntegrationEntity('Request', 'Requests'),
        new IntegrationEntity('Location', 'Locations'),
        new IntegrationEntity('Note', 'Notes'),
        new IntegrationEntity('Project', 'Projects'),
        new IntegrationEntity('ProjectTask', 'Project Tasks'),
        new IntegrationEntity('RequestedItem', 'Requested Items'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('ChangeRequest', 'Change Requests'),
        new IntegrationEntity('ChangeTemplate', 'Change Templates'),
    ];
    override customizedAuthenticationComponent = ServicenowAuthenticationComponent;
    override customizedSetupComponent = ServicenowSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(servicenowCircleIcon, '130px'),
        new IntegrationIcon(servicenowIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default ServicenowIntegration;
