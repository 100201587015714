<!--if choosen an accounts integration-->
<div
    ng-if="integrationsResults['ACCOUNTS']"
    ng-controller="AdvancedSyncCtrl"
    ng-init="initializeSyncEntity('ACCOUNTS', 1)"
>
    <div class="common-size-xs padding-top-xs">
        <strong>What accounts should we track?</strong>
        <span class="common-size-xxxxs">&nbsp;(in {{ integrationsResults['ACCOUNTS'].displayName }})</span>
        <div class="common-color-dark-grey common-size-xxs">
            Ex: filter customers by stage, amount, or number of employees
        </div>
    </div>
    <hr class="margin-top" />
    <div ng-init="initAdvancedSync()">
        <div class="flex-vmiddle margin-bottom" class="margin-normal-v">
            <strong class="margin-right-xs">Use {{ integrationsResults['ACCOUNTS'].displayName }}'s:</strong>
            <tnk-entity-selector
                project-integration="integrationsResults['ACCOUNTS']"
                selected-entity="{{ valueResults['SYNC_ENTITY'] }}"
                mode="sync"
                on-entity-selected="selectEntityOption(selectedEntity)"
            ></tnk-entity-selector>
        </div>
        <tnk-custom-filters
            control="valueResults['SYNC_CONTROL']"
            items-source="EXTERNAL"
            existing-definition="valueResults['SYNC_DEFINITION']"
            project-integration="integrationsResults['ACCOUNTS']"
            from-sync="true"
            edit-mode="false"
            selected-entity-type="valueResults['SYNC_ENTITY']"
            hide-time-range-selection="true"
            show-field-inspect="true"
        ></tnk-custom-filters>
    </div>

    <hr />

    <div class="common-size-xs padding-top-xs">
        <strong>
            For each account, track the following metrics from {{ integrationsResults['ACCOUNTS'].displayName }}:
        </strong>
    </div>

    <div>
        <tnk-setup-columns
            integration-name="integrationsResults['ACCOUNTS'].integration.integrationUniqueType"
            integration-id="integrationsResults['ACCOUNTS'].id"
            columns-config="data.columns"
            entity-type="valueResults['ALL_ENTITIES_SUPPORTED'] ? valueResults['SYNC_ENTITY'] : syncSettings[valueResults['SYNC_ENTITY']].entityType"
        ></tnk-setup-columns>
    </div>
</div>

<!--if didn't choose an accounts integration-->
<div ng-if="!integrationsResults['ACCOUNTS']" class="padding-top-xs" ng-init="initiativeLable = 'Account'">
    <div class="common-size-xs margin-bottom" ng-init="addDefaultsToInitiativesResults()">
        <strong>Accounts to track:</strong>
    </div>
    <div class="common-color-dark-grey common-size-xxs margin-bottom">
        Enter few accounts names, due date and primary owner (account executive / point of contact)
    </div>
    <hr />
    <div ng-include="'../directives/manualAddTracksTemplate.template.html'"></div>
</div>

<hr />

<div class="flex-vmiddle">
    <div class="common-bold common-size-xs margin-right">Currency:</div>
    <!-- Currency (display format dropdown) -->
    <tnk-display-format-selector
        default-display-format="dollar"
        field-type-filter="'Number'"
        selected-format-api-name="valueResults['DISPLAY_FORMAT_API_NAME']"
        selected-format-prefix="valueResults['DISPLAY_FORMAT_PREFIX']"
        selected-format-postfix="valueResults['DISPLAY_FORMAT_POSTFIX']"
        on-display-format-selected="onDisplayFormatSelected(selectedDisplayFormat, skipAnalytics)"
        field-type-filter="CURRENCY"
        disable-selection="posting"
    ></tnk-display-format-selector>
</div>
