import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const SingleWorkerRunWrapper = styled.div`
    margin: 10px 30px 0;
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.HEX_CACACA};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0967821);
    border-radius: 3px;
    border-bottom: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export default SingleWorkerRunWrapper;
