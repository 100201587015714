import {
    DeleteItemsSelectionType,
    ProjectIntegrationPageMenuItemType,
    SyncItemsCreatorSelectionType,
    SyncUniqueIdentifierType,
} from '@tonkean/tonkean-entities';
import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { VIEW_TYPES } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { analyticsWrapper } from '@tonkean/analytics';
import { countQueryFilters } from '@tonkean/tonkean-utils';

function SyncTracksCtrl(
    $scope,
    $rootScope,
    $filter,
    $state,
    $q,
    tonkeanService,
    customFieldsManager,
    projectManager,
    integrations,
    utils,
    communicationIntegrationsService,
    trackHelper,
    workflowVersionManager,
    syncConfigCacheManager,
    personCache,
) {
    $scope.control = $scope.control || {};
    $scope.pm = $rootScope.pm;
    $scope.cis = communicationIntegrationsService;
    $scope.viewTypes = VIEW_TYPES;
    $scope.loadPreviewFlag = false;
    $scope.TONKEAN_ENTITY_TYPE = TONKEAN_ENTITY_TYPE;
    $scope.wvm = workflowVersionManager;

    // This parameter used to be taken from consts (Integration configuration).
    $scope.control.customDefinedView = true;

    $scope.data = {
        targetEntityId: $scope.targetEntityId,
        targetEntityType: getTonkeanEntityType($scope.targetEntityId),
        targetEntity: null,
        syncConfig: null,
        showCreatorSelection: true,
        isSupportedIntegrationType: $scope.projectIntegration.integration.integrationType === 'GOOGLESHEETS',
        isSupportedUsageMethodType:
            $scope.projectIntegration?.projectData?.projectDatas[0]?.config?.spreadsheetUsageMethodType === 'CRUD',

        selectedEntity: $scope.selectedEntity,
        selectedEntityConstsConfiguration: null,
        availableFields: [],

        hideTitleSelectionSettings: true,

        titleExpression: null,
        defaultTitleIfExpressionEmpty: null,
        uniqueIdentifierType: SyncUniqueIdentifierType.EXTERNAL_ENTITY_ID,
        syncItemsCreatorSelectionType: SyncItemsCreatorSelectionType.MODULE_DEFAULT_ACTOR,
        syncItemsCreatorConfiguration: null,
        specificCreator: [],

        deleteItemsSelectionType: DeleteItemsSelectionType.ITEM_DELETED_KEEP,

        workflowVersionId: $scope.workflowVersionId,

        shouldRemoveTheDefaultSyncDateTimeFilter: false,

        notAllowedConditionsSet: {
            Contains: true,
            NotContains: true,
        },
    };

    $scope.init = function () {
        if ($scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.WORKFLOW_VERSION) {
            $scope.data.targetEntity = workflowVersionManager.getCachedWorkflowVersion($scope.data.targetEntityId);
            $scope.data.syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.targetEntityId);
        } else if ($scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.INITIATIVE) {
            const initiative = trackHelper.getInitiativeFromCache($scope.data.targetEntityId);
            if (initiative && initiative.syncConfig) {
                $scope.data.targetEntity = initiative;
                $scope.data.syncConfig = initiative.syncConfig;
            }
        }

        // all functions were assigned
        $scope.control.loaded = true;

        // If the selected integration is different than the one in sync data, that means we're trying to initialize a new sync
        // and we should ignore the already existing sync.
        if (
            $scope.projectIntegration &&
            $scope.projectIntegration.integration.integrationType &&
            $scope.syncData &&
            $scope.syncData.integrationType &&
            $scope.projectIntegration.integration.integrationType.toLowerCase() !==
                $scope.syncData.integrationType.toLowerCase()
        ) {
            $scope.syncData = null;
        }

        $scope.data.selectedEntityConstsConfiguration = integrations.getSyncSettingsByEntity(
            $scope.projectIntegration.integration.integrationType.toLowerCase(),
            $scope.selectedEntity,
        );

        // Initializing the selected entity
        const entityTypeEvaluation = getEntityType();
        $scope.selectedEntity = entityTypeEvaluation.apiName;
        $scope.selectedEntityDisplayName = entityTypeEvaluation.displayName;

        if (!$scope.viewType || $scope.viewType === $scope.viewTypes.noView) {
            $scope.viewType = $scope.viewTypes.custom;
        }

        let filtersCount = 0;
        if ($scope.syncData && $scope.syncData.query) {
            filtersCount = countQueryFilters($scope.syncData.query);
        }

        if ($scope.syncData) {
            $scope.control.hasFilter =
                filtersCount ||
                ($scope.syncData.timeRangeFilter && $scope.syncData.timeRangeFilter.range !== 'NoRange');
        } else {
            $scope.control.hasFilter = true;
        }

        $scope.control.createViewDataForIntegrationView = getIntegrationViewData;
        $scope.control.getTitleExpression = getTitleExpression;
        $scope.control.getDefaultTitleIfExpressionEmpty = getDefaultTitleIfExpressionEmpty;

        if (
            $scope.data.syncConfig &&
            $scope.data.syncConfig.viewData &&
            $scope.data.syncConfig.viewData.titleExpression
        ) {
            $scope.data.titleExpression = $scope.data.syncConfig.viewData.titleExpression;
        }

        if (
            $scope.data.syncConfig &&
            $scope.data.syncConfig.viewData &&
            $scope.data.syncConfig.viewData.defaultTitleIfExpressionEmpty
        ) {
            $scope.data.defaultTitleIfExpressionEmpty = $scope.data.syncConfig.viewData.defaultTitleIfExpressionEmpty;
        }

        if ($scope.data.syncConfig && $scope.data.syncConfig.uniqueIdentifierType) {
            // Initialize the uniqueIdentifierType property if it was saved on the sync config. Otherwise, use the default false.
            $scope.data.uniqueIdentifierType = $scope.data.syncConfig.uniqueIdentifierType;
        }

        if ($scope.data.syncConfig && $scope.data.syncConfig.syncItemsCreatorSelectionType) {
            // Initialize the syncItemsCreatorSelectionType property if it was saved on the sync config. Otherwise, use the default value (MODULE_DEFAULT_ACTOR).
            $scope.data.syncItemsCreatorSelectionType = $scope.data.syncConfig.syncItemsCreatorSelectionType;
        }

        if ($scope.data.syncConfig && $scope.data.syncConfig.deleteItemsSelectionType) {
            // Initialize the deleteItemsSelectionType property if it was saved on the sync config. Otherwise, use the default value (DELETE_ITEMS_KEEP).
            $scope.data.deleteItemsSelectionType = $scope.data.syncConfig.deleteItemsSelectionType;
        }

        if ($scope.data.syncConfig && $scope.data.syncConfig.syncItemsCreatorConfiguration) {
            $scope.data.syncItemsCreatorConfiguration = $scope.data.syncConfig.syncItemsCreatorConfiguration;
            if ($scope.data.syncItemsCreatorSelectionType === 'SPECIFIC') {
                personCache
                    .getEntityById($scope.data.syncItemsCreatorConfiguration.personId, false, true)
                    .then((person) => {
                        $scope.data.specificCreator = [person];
                    });
            }
        }

        $scope.data.shouldRemoveTheDefaultSyncDateTimeFilter =
            integrations.getIntegrationsConfig()[$scope.projectIntegration.integrationType.toLowerCase()]
                ?.shouldRemoveTheDefaultSyncDateTimeFilter || false;

        $scope.syncValidityState = {};
    };

    /**
     * The selectedEntity changes from outside the directive and we need to reset settings once it's changed.
     * That's why we use watch to watch over value changes.
     */
    $scope.$watchCollection('selectedEntity', function () {
        $scope.data.selectedEntityConstsConfiguration = integrations.getSyncSettingsByEntity(
            $scope.projectIntegration.integration.integrationType.toLowerCase(),
            $scope.selectedEntity,
        );

        // Loading the available fields for the entity
        $scope.loadingExternal = true;

        const entityTypeEvaluation = getEntityType();

        // Update display name when entity type has changed
        $scope.selectedEntityDisplayName = entityTypeEvaluation.displayName;
        $scope.pluralEntityName =
            $scope.projectIntegration.displayName !== $scope.selectedEntityDisplayName
                ? $scope.selectedEntityDisplayName
                : `${$scope.projectIntegration.displayName} entities`;

        // if (!$scope.disableFields) {
        tonkeanService
            .getAvailableExternalFields($scope.projectIntegration.id, [entityTypeEvaluation.apiName])
            .then((result) => {
                $scope.data.availableFields = result.entitiesWithLabels;
            })
            .finally(() => {
                $scope.loadingExternal = false;
            });
        // }
    });

    /**
     * Adds an integration field to the expression of the title.
     */
    $scope.addFieldToTitleExpression = function (field) {
        if (field) {
            if (utils.isNullOrUndefined($scope.data.titleExpression)) {
                $scope.data.titleExpression = '';
            }

            $scope.data.titleExpression = `${$scope.data.titleExpression}{{${field.name}}}`;

            // Resetting the selectedAvailableField to achieve an experience of selecting a field only to be appended into the expression.
            $scope.data.selectedAvailableField = null;
        }
    };

    $scope.syncItemsCreatorSelectionTypeChange = function () {
        $scope.data.syncItemsCreatorConfiguration = {};
        $scope.data.selectedAvailableField = null;
    };

    $scope.addSpecificPerson = function () {
        $scope.data.syncItemsCreatorConfiguration.personId = $scope.data.specificCreator[0].id;
    };

    $scope.removeSpecificPerson = function () {
        $scope.data.syncItemsCreatorConfiguration.personId = null;
    };

    $scope.addFieldToCreatorCustomConfigurationExpression = function (field) {
        if (field) {
            if (!$scope.data.syncItemsCreatorConfiguration || !$scope.data.syncItemsCreatorConfiguration.expression) {
                $scope.data.syncItemsCreatorConfiguration = {};
                $scope.data.syncItemsCreatorConfiguration.expression = {};
                $scope.data.syncItemsCreatorConfiguration.expression.originalExpression = '';
                $scope.data.syncItemsCreatorConfiguration.expression.isStripHtmlDisabled = false;
            }

            $scope.data.syncItemsCreatorConfiguration.expression.originalExpression = `${$scope.data.syncItemsCreatorConfiguration.expression.originalExpression}{{${field.name}}}`;
            $scope.data.syncItemsCreatorConfiguration.expression.evaluatedExpression =
                $scope.data.syncItemsCreatorConfiguration.expression.originalExpression;

            // Resetting the selectedAvailableField to achieve an experience of selecting a field only to be appended into the expression.
            $scope.data.selectedAvailableField = null;
        }
    };

    /**
     * Toggles $scope.showCustomFilters property.
     */
    $scope.toggleShowCustomFilters = function (value) {
        $scope.control.customDefinedView = value;

        const entityTypeEvaluation = getEntityType();
        $scope.selectedEntity = entityTypeEvaluation.apiName;
        $scope.selectedEntityDisplayName = entityTypeEvaluation.displayName;
    };

    /**
     * Redirects the user to the integrations page.
     */
    $scope.goToIntegrationsPage = function () {
        $state.go('product.enterpriseComponents', {
            tab: 'data-sources',
            projectId: projectManager.project.id,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            fromName: $scope.pm.groupsMap?.[$state.params.g].name || 'Module Editor',
        });
    };

    /**
     * Redirects the user to the integrations page.
     */
    $scope.goToSingleIntegrationPage = function (projectIntegration) {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: projectIntegration.id,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            fromName: $scope.pm.groupsMap?.[$state.params.g].name || 'Module Editor',
            page: ProjectIntegrationPageMenuItemType.CONNECTIONS,
        });
    };

    $scope.prepareDataForSync = function () {
        const entityTypeEvaluation = getEntityType();

        let defaultRanges = [];
        if ($scope.data.selectedEntityConstsConfiguration) {
            defaultRanges = $scope.data.selectedEntityConstsConfiguration.defaultRanges;
        }

        analyticsWrapper.track(`Sync ${$scope.projectIntegration.integration.integrationType}`, { category: 'Sync' });

        const viewTypes = VIEW_TYPES;
        let viewData = {};
        let viewType = viewTypes.noView;

        // if we are in custom mode
        if ($scope.control.customDefinedView) {
            viewData = $scope.control.createDefinitionFromCustomFilters();
            viewType = viewTypes.custom;

            if (!$scope.control.hasFilter) {
                // if want "all" then clean filters
                viewData.query = {
                    filters: [],
                    type: 'And',
                };
            }
        } else if ($scope.control.selectedView) {
            // if we are in integration mode and have selected a preset view
            viewData = {
                Entity: entityTypeEvaluation.apiName,
                Id: $scope.control.selectedView.id,
                Name: $scope.control.selectedView.name,
            };
            viewType = viewTypes.integration;
        } else {
            // if we are in integration mode but didnt select a preset (initial)
            viewType = viewTypes.integration;
            viewData = {
                Entity: entityTypeEvaluation.apiName,
                Id: undefined,
                Name: '',
            };
        }

        return {
            defaultRanges,
            viewData,
            viewType,
            entityTypeEvaluation,
        };
    };

    $scope.control.sync = function () {
        const syncData = $scope.prepareDataForSync();
        syncData.uniqueIdentifierType = $scope.data.uniqueIdentifierType;
        syncData.syncItemsCreatorSelectionType = $scope.data.syncItemsCreatorSelectionType;

        if ($scope.projectIntegration.integration.integrationType === 'WEBHOOK') {
            syncData.deleteItemsSelectionType = DeleteItemsSelectionType.ITEM_DELETED_DELETE;
        } else {
            syncData.deleteItemsSelectionType = $scope.data.deleteItemsSelectionType;
        }

        if (syncData.syncItemsCreatorSelectionType === 'SPECIFIC' && !$scope.data.specificCreator.length) {
            $scope.data.syncItemsCreatorConfiguration.personId = $rootScope.currentUser.id;
        }

        if (syncData.syncItemsCreatorSelectionType === 'MODULE_DEFAULT_ACTOR') {
            $scope.data.syncItemsCreatorConfiguration = null;
        }

        syncData.syncItemsCreatorConfiguration = $scope.data.syncItemsCreatorConfiguration;

        if ($scope.data.titleExpression) {
            syncData.viewData.titleExpression = $scope.data.titleExpression;
        }

        if ($scope.data.defaultTitleIfExpressionEmpty) {
            syncData.viewData.defaultTitleIfExpressionEmpty = $scope.data.defaultTitleIfExpressionEmpty;
        }

        let syncPromise = $q.resolve();
        if ($scope.data.syncConfig) {
            syncPromise = syncConfigCacheManager.updateSyncConfig(
                $scope.data.syncConfig.id,
                $scope.projectIntegration.id,
                syncData.viewData,
                $scope.control.selectedView ? $scope.control.selectedView.count : null,
                syncData.viewType,
                null,
                syncData.uniqueIdentifierType,
                syncData.deleteItemsSelectionType,
                syncData.syncItemsCreatorSelectionType,
                syncData.syncItemsCreatorConfiguration,
            );
        } else {
            if ($scope.data.targetEntityId.startsWith('INIT')) {
                // If our target entity id is an initiative id, we're creating a sync config for an initiative.
                syncPromise = syncConfigCacheManager.createInitiativeSyncConfig(
                    $scope.data.targetEntityId,
                    $scope.projectIntegration.id,
                    syncData.viewData,
                    $scope.control.selectedView ? $scope.control.selectedView.count : null,
                    syncData.viewType,
                    null,
                    syncData.uniqueIdentifierType,
                    syncData.deleteItemsSelectionType,
                    syncData.syncItemsCreatorSelectionType,
                    syncData.syncItemsCreatorConfiguration,
                );
            } else if ($scope.data.targetEntityId.startsWith('WOVE')) {
                // Otherwise, our target entity id is a workflow version - we're creating a sync config for a workflow version.
                // In this case, we're creating it under the group, as we can only create or update stuff on the draft workflow
                // version as it is.
                syncPromise = syncConfigCacheManager.createGroupSyncConfig(
                    $scope.groupId,
                    $scope.projectIntegration.id,
                    syncData.viewData,
                    $scope.control.selectedView ? $scope.control.selectedView.count : null,
                    syncData.viewType,
                    null,
                    syncData.uniqueIdentifierType,
                    syncData.deleteItemsSelectionType,
                    syncData.syncItemsCreatorSelectionType,
                    syncData.syncItemsCreatorConfiguration,
                );
            }
        }

        syncPromise
            .then(() => {
                // Fetching the workflow version for the updated changes.
                return tonkeanService
                    .getWorkflowVersionById($scope.data.workflowVersionId)
                    .then((updatedWorkflowVersion) => {
                        // Caching the fetched updated workflow version.
                        workflowVersionManager.cacheWorkflowVersion(updatedWorkflowVersion);

                        // Cache sync config
                        syncConfigCacheManager.cacheSyncConfig(updatedWorkflowVersion.syncConfig);

                        // Mark the importOrSync step in the on boarding as completed.
                        $rootScope.onBoardingManager.completeStep('importOrSync');

                        if (
                            $scope.updateGroupName &&
                            $scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.GROUP
                        ) {
                            tonkeanService.updateGroupName(
                                $scope.pm.groupsMap[$scope.groupId],
                                $scope.control.selectedView.name,
                            );
                        }

                        if ($scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.INITIATIVE) {
                            // If we're syncing an initiative (we don't sync field for initiatives).
                            $scope.onClose();
                        } else {
                            // If we're syncing a workflow version.
                            if (
                                !$scope.disableFields &&
                                $scope.data.selectedFields &&
                                $scope.data.selectedFields.length &&
                                !$scope.syncData
                            ) {
                                // create the fields first
                                const definitions = [];
                                for (let i = 0; i < $scope.data.selectedFields.length; i++) {
                                    const selectedField = $scope.data.selectedFields[i];

                                    definitions.push({
                                        name: selectedField.label,
                                        ranges:
                                            syncData.defaultRanges && syncData.defaultRanges[selectedField.name]
                                                ? syncData.defaultRanges[selectedField.name]
                                                : [],
                                        projectIntegrationId: $scope.projectIntegration.id,
                                        definition: {
                                            ExternalType: syncData.entityTypeEvaluation.apiName,
                                            FieldName: selectedField.name,
                                            FieldLabel: selectedField.label,
                                            integrationType:
                                                $scope.projectIntegration.integration.integrationType.toUpperCase(),
                                        },
                                        updateable: selectedField.updateable,
                                        possibleValues: selectedField.values,
                                        targetType: 'COLUMN',
                                        fieldType: selectedField.type || 'string',
                                        type: 'EXTERNAL',
                                    });
                                }

                                customFieldsManager
                                    .createMultipleFieldDefinitions($scope.groupId, definitions, null)
                                    .finally(function () {
                                        // Mark the addField step in the on boarding as completed.
                                        $rootScope.onBoardingManager.completeStep('addField');
                                        $scope.onClose();
                                    });
                            } else {
                                $scope.onClose();
                            }
                        }

                        // Firing groupListUpdated so the list is updated.
                        $rootScope.$broadcast('groupListUpdated', { groupIds: [$scope.groupId] });
                    });
            })
            .catch(function (error) {
                $scope.data.error = error;
                $scope.posting = false;

                $scope.onError({ error });
            });
    };

    $scope.filterExternalFields = function (query) {
        if ($scope.data.selectedFields && $scope.data.selectedFields.length >= 6) {
            return [];
        }
        const result = $filter('filter')($scope.data.availableFields, { label: query });
        return $filter('orderBy')(result, 'label');
    };

    $scope.onClose = function () {
        if ($scope.onCompleted) {
            $scope.onCompleted();
        }
    };

    /**
     * Getting the entity type for the sync.
     */
    function getEntityType() {
        let apiName;
        let displayName;

        // Taking configuration from consts if it's available there. Otherwise, trying to take the configuration
        // from the selected entity we received.
        if ($scope.data.selectedEntityConstsConfiguration) {
            apiName = $scope.data.selectedEntityConstsConfiguration.pluralLabel;
            displayName = $scope.data.selectedEntityConstsConfiguration.pluralLabel;
        } else if ($scope.viewType === $scope.viewTypes.custom && $scope.syncData && $scope.syncData.entityMetadata) {
            apiName = $scope.syncData.entityMetadata.entity;
            displayName = $scope.syncData.entityMetadata.pluralLabel;
        } else if ($scope.selectedEntityMetadata) {
            apiName = $scope.selectedEntityMetadata.entity;
            displayName = $scope.selectedEntityMetadata.pluralLabel;
        } else if ($scope.viewType === $scope.viewTypes.integration && $scope.syncData && $scope.syncData['Entity']) {
            apiName = $scope.syncData['Entity'];
            displayName = $scope.syncData['Entity'];
        } else if ($scope.syncData['entity']) {
            apiName = $scope.syncData['entity'];
            displayName = $scope.syncData['entity'];
        }

        return {
            apiName,
            displayName,
        };
    }

    /**
     * Returns the view data for integration type of views.
     */
    function getIntegrationViewData(selectedView) {
        const entityTypeEvaluation = getEntityType();
        return {
            Entity: entityTypeEvaluation.apiName,
            Id: selectedView.id,
            Name: selectedView.name,
        };
    }

    /**
     * Returns the title expression field.
     */
    function getTitleExpression() {
        return $scope.data.titleExpression;
    }

    /**
     * Returns the default title if expression empty field.
     */
    function getDefaultTitleIfExpressionEmpty() {
        return $scope.data.defaultTitleIfExpressionEmpty;
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('SyncTracksCtrl', SyncTracksCtrl);
