export const SPECIAL_FIELD_KEYS = {
    title: 'TNK_TITLE',
    tags: 'TNK_TAGS',
    dueDate: 'TNK_DUE_DATE',
    ownerId: 'TNK_OWNER_ID',
    status: 'TNK_STAGE',
    statusText: 'TNK_STATUS_TEXT',
    nextGatherUpdate: 'TNK_NEXT_BOT_PING',
    itemNotificationChannel: 'TNK_ITEM_NOTIFICATION_CHANNEL',
};
