import React from 'react';
import styled from 'styled-components';

import ItemInterfaceActionsWidgetActionSelector from './ItemInterfaceActionsWidgetActionSelector';
import SolutionSiteActionSelector from './SolutionSiteActionSelector';
import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import { WidgetConfigurationToggle } from '../../CommonWidgetConfiguration';

import type { WorkflowVersion } from '@tonkean/tonkean-entities';

const TitleWrapper = styled.div`
    padding: 0 20px;
`;
const WidgetConfigurationToggleWrapper = styled.div`
    padding: 0 19px 0 19px;
`;

const ActionSelectorContainer = styled.div`
    padding: 16px 19px;
`;

interface Props {
    workflowVersion: WorkflowVersion | undefined;
}

const ActionsItemWidgetConfigurationEditorDisplay: React.FC<Props> = ({ workflowVersion }) => {
    return (
        <>
            <TitleWrapper>
                <ConfigurationSectionTitle>Actions</ConfigurationSectionTitle>
            </TitleWrapper>

            <WidgetConfigurationToggleWrapper>
                <WidgetConfigurationToggle
                    title="Open as a modal by default"
                    toolTip="When set to modal, end users will also be able to open forms in a new tab by CMD + Click on the action"
                    name="configuration.openFormsInModal"
                    dataAutomation="actions-item-widget-configuration-editor-disply-open-as-modal"
                />
            </WidgetConfigurationToggleWrapper>

            <ActionSelectorContainer>
                {workflowVersion ? (
                    <ItemInterfaceActionsWidgetActionSelector workflowVersion={workflowVersion} />
                ) : (
                    <SolutionSiteActionSelector name="configuration.actions" />
                )}
            </ActionSelectorContainer>
        </>
    );
};
export default ActionsItemWidgetConfigurationEditorDisplay;
