import template from './tnkBotFutureGathers.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotFutureGathers', {
    bindings: {
        isVisible: '<',
        filter: '<',
    },
    template,
    controller: 'BotFutureGathersCtrl',
});
