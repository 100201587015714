import React from 'react';

const WorkflowVersionLoadingHistoryLog: React.FC = () => {
    return (
        <div className="worker-audit-log-item workflow-version-loading">
            <figure>
                <div className="loading-circle loading-avatar" />
            </figure>
            <main>
                <p>
                    <span className="loading-rect loading-message" />
                </p>
                <div className="worker-audit-log-item-footer">
                    <div className="loading-rect loading-date" />
                </div>
            </main>
        </div>
    );
};

export default WorkflowVersionLoadingHistoryLog;
