<div class="change-avatar-modal">
    <div class="modal-header common-bold">Change your avatar</div>

    <div class="modal-body">
        <div>
            Select an image file:
            <input type="file" id="fileInput" custom-on-change="onFileChange" />
        </div>
        <div class="cropArea">
            <div class="text-center padding-normal" ng-show="inProgress">
                <i class="loading-large"></i>

                <h3>Uploading image...</h3>
            </div>
            <img-crop ng-hide="inProgress" image="myImage" result-image="myCroppedImage"></img-crop>
        </div>
    </div>
    <div class="modal-footer">
        <div ng-if="error" class="common-error pull-left">{{ error }}</div>
        <button type="button" class="btn btn-default" data-ng-click="cancel()">Close</button>
        <button type="button" data-ng-disabled="inProgress || !myImage" class="btn btn-primary" data-ng-click="save()">
            Save
        </button>
    </div>
</div>
