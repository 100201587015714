import type { Features } from 'angular';
import type { AngularServices } from 'angulareact';

import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';
import formAnsweredCanCreateInSameModule from '../canCreate/formAnsweredCanCreateInSameModule';
import formAnsweredCanCreateIsSequenceEnd from '../canCreate/formAnsweredCanCreateIsSequenceEnd';
import formAnsweredValidateRootParentInSequence from '../canCreate/formAnsweredValidateRootParentInSequence';
import formAnsweredShowFormTriggerParamsEnricher from '../enrichers/formAnsweredShowFormTriggerParamsEnricher';

import { ItemDetailsIconType } from '@tonkean/tonkean-entities';
import type { LogicImpact } from '@tonkean/tonkean-entities';

const showFormOtionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts: LogicImpact[],
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
    customTriggerManager: AngularServices['customTriggerManager'],
    features: Features,
) => {
    return (
        formAnsweredCanCreateInSameModule(configuredLogic, logicBlockConfigsFormInfoRetriever) &&
        formAnsweredCanCreateIsSequenceEnd(configuredLogic) &&
        formAnsweredValidateRootParentInSequence(
            configuredLogic,
            customTriggerManager,
            'AUTONOMOUS_CREATED_FROM_FORM',
            ['SEND_FORM_ANSWERED', 'ASK_FIELD_UPDATE_ANSWERED'],
        )
    );
};

const addShowFormBlockCreateAfterOption: CreateAfterOption = {
    label: 'Show a Form',
    blockDisplayName: 'Show a Form',
    triggerParamsEnricher: formAnsweredShowFormTriggerParamsEnricher,
    canCreate: showFormOtionCanCreate,
    iconType: ItemDetailsIconType.FORM,
};
export default addShowFormBlockCreateAfterOption;
