import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import MarketplaceGallerySectionByType from './MarketplaceGallerySectionByType';
import useGetMarketplaceBlueprintItemAdditionalSections from '../../../components/common/tnkWorkersBrowser/marketplace/hooks/useGetMarketplaceBlueprintItemAdditionalSections';
import useGetModuleMarketplaceItemAdditionalSections from '../../../components/common/tnkWorkersBrowser/marketplace/hooks/useGetModuleMarketplaceItemAdditionalSections';
import PageMarketplaceItemCreateModalAdditionalSections from '../../../components/common/tnkWorkersBrowser/marketplace/PageMarketplaceItemCreateModalAdditionalSections';
import { getMarketplaceItemAdditionalSections } from '../../EnterpriseComponentsModule/components/EnterpriseComponentOverview/EnterpriseComponentOverview';
import EnterpriseComponentSearch from '../../EnterpriseComponentsModule/components/EnterpriseComponentSearch';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { LoadingCircle, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { BlueprintSolutionType, MarketplaceItemType } from '@tonkean/tonkean-entities';
import type { MarketplaceItem, SolutionMarketplaceItem, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {}

const MarketplaceGallery: React.FC<Props> = () => {
    const emitToast = useToastMessage();

    const moduleAdditionalSections = useGetModuleMarketplaceItemAdditionalSections();
    const marketplaceBlueprintSections = useGetMarketplaceBlueprintItemAdditionalSections();
    const projectManager = useAngularService('projectManager');
    const { data, loading, error } = useTonkeanService('getMarketplaceItems', projectManager.project.id);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const [marketplaceItems, setMarketplaceItems] = useState<MarketplaceItem[]>([]);

    useEffect(() => {
        setMarketplaceItems(data?.entities || []);
    }, [data?.entities]);

    const filteredMarketplaceItems = useMemo(() => {
        return marketplaceItems.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [marketplaceItems, searchTerm]);

    const solutionTemplateItems: SolutionMarketplaceItem[] = useMemo(() => {
        return filteredMarketplaceItems.filter(
            (item) =>
                item.type === MarketplaceItemType.SOLUTION &&
                item.configuration.blueprintSolutionType === BlueprintSolutionType.SOLUTION_TEMPLATE,
        ) as SolutionMarketplaceItem[];
    }, [filteredMarketplaceItems]);

    const marketplaceBlueprintItems: SolutionMarketplaceItem[] = useMemo(() => {
        return filteredMarketplaceItems.filter(
            (item) =>
                item.type === MarketplaceItemType.SOLUTION &&
                (!item?.configuration.blueprintSolutionType ||
                    item?.configuration.blueprintSolutionType === BlueprintSolutionType.MARKETPLACE_BLUEPRINT),
        ) as SolutionMarketplaceItem[];
    }, [filteredMarketplaceItems]);

    const filteredModuleMarketplaceItems = useMemo(() => {
        return filteredMarketplaceItems.filter((item) => item.type === MarketplaceItemType.MODULE);
    }, [filteredMarketplaceItems]);

    const notFilteredModuleMarketplaceItems = useMemo(() => {
        return marketplaceItems.filter((item) => item.type === MarketplaceItemType.MODULE);
    }, [marketplaceItems]);

    const dataSourceMarketplaceItems = useMemo(() => {
        return filteredMarketplaceItems.filter((item) => item.type === MarketplaceItemType.DATA_SOURCE);
    }, [filteredMarketplaceItems]);

    const pageMarketplaceItems = useMemo(() => {
        return filteredMarketplaceItems.filter((item) => item.type === MarketplaceItemType.PAGE);
    }, [filteredMarketplaceItems]);

    const contractMarketplaceItems = useMemo(() => {
        return filteredMarketplaceItems.filter((item) => item.type === MarketplaceItemType.CONTRACT);
    }, [filteredMarketplaceItems]);

    const dataSourceAdditionalSections = useMemo(() => {
        return getMarketplaceItemAdditionalSections();
    }, []);

    const onGalleryItemUpdated = useCallback(
        (updatedMarketplaceItem: MarketplaceItem) => {
            setMarketplaceItems(
                marketplaceItems.map((item) => (item.id === updatedMarketplaceItem.id ? updatedMarketplaceItem : item)),
            );
        },
        [marketplaceItems, setMarketplaceItems],
    );

    const onGalleryItemDeleted = useCallback(
        (deletedItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>) => {
            setMarketplaceItems(marketplaceItems.filter((item) => item.id !== deletedItemId));
        },
        [marketplaceItems],
    );

    const notFilteredModuleItemsTemplateNames = useMemo(() => {
        return notFilteredModuleMarketplaceItems.map((moduleItem) => moduleItem.templateName);
    }, [notFilteredModuleMarketplaceItems]);

    const validateBlueprint = (item: SolutionMarketplaceItem): boolean => {
        if (!item.configuration.solutionOutline) {
            return true;
        }

        const suggestedModulesTemplateNames = item.configuration.solutionOutline.sections
            .flatMap((section) => section.suggestedModules)
            .map((suggestedModule) => suggestedModule.templateName);

        for (const templateName of suggestedModulesTemplateNames) {
            if (!notFilteredModuleItemsTemplateNames.includes(templateName)) {
                emitToast(
                    'Unable to download package due to suggested modules that have no matching templates',
                    'danger',
                );
                emitToast(`${templateName} has no corresponding template`, 'danger');
                return false;
            }
        }

        return true;
    };

    const getBlueprintRelatedTemplatesForDownload = (item: SolutionMarketplaceItem): MarketplaceItem[] => {
        if (!item.configuration.solutionOutline) {
            return [item];
        }

        const suggestedModulesTemplateNames = new Set(
            item.configuration.solutionOutline.sections
                .flatMap((section) => section.suggestedModules)
                .map((suggestedModule) => suggestedModule.templateName),
        );

        const suggestedModules = notFilteredModuleMarketplaceItems.filter((moduleItem) =>
            suggestedModulesTemplateNames.has(moduleItem.templateName),
        );

        return [item, ...suggestedModules];
    };

    if (loading) {
        return <LoadingCircle />;
    }

    if (error) {
        return <SimpleErrorStateMessage error="Error Loading Reusable Components" />;
    }

    return (
        <>
            <EnterpriseComponentSearch
                onSearchTermChange={setSearchTerm}
                searchTerm={searchTerm}
                disabled={!marketplaceItems.length}
                placeholder="Search Reusable Components"
            />

            <MarketplaceGallerySectionByType
                type={BlueprintSolutionType.MARKETPLACE_BLUEPRINT}
                marketplaceItems={marketplaceBlueprintItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                additionalSections={marketplaceBlueprintSections}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-marketplace-blueprint"
                validate={(item) => validateBlueprint(item)}
                getItemsToDownload={(item) => getBlueprintRelatedTemplatesForDownload(item)}
            />

            <MarketplaceGallerySectionByType
                type={BlueprintSolutionType.SOLUTION_TEMPLATE}
                marketplaceItems={solutionTemplateItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-internal-solution-template"
            />

            <MarketplaceGallerySectionByType
                type={MarketplaceItemType.MODULE}
                marketplaceItems={filteredModuleMarketplaceItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                additionalSections={moduleAdditionalSections}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-module"
            />

            <MarketplaceGallerySectionByType
                type={MarketplaceItemType.DATA_SOURCE}
                marketplaceItems={dataSourceMarketplaceItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                additionalSections={dataSourceAdditionalSections}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-data-source"
            />

            <MarketplaceGallerySectionByType
                type={MarketplaceItemType.PAGE}
                marketplaceItems={pageMarketplaceItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                additionalSections={PageMarketplaceItemCreateModalAdditionalSections}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-page"
            />

            <MarketplaceGallerySectionByType
                type={MarketplaceItemType.CONTRACT}
                marketplaceItems={contractMarketplaceItems}
                marketplacitemToRootEntityNameMap={data?.rootEntitiesToDisplayName}
                additionalSections={EMPTY_ARRAY}
                onItemSubmitted={onGalleryItemUpdated}
                onItemDeleted={onGalleryItemDeleted}
                dataAutomation="marketplace-gallery-item-contract"
            />
        </>
    );
};

export default MarketplaceGallery;
