import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';

import { WorkflowVersionType, type Initiative, workflowVersionTypeToDisplayName } from '@tonkean/tonkean-entities';
import type { Person } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

/**
 * Definition map of all special fields in Tonkean.
 */
export const FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP = {
    TNK_ROOT_ITEM: {
        id: 'TNK_ROOT_ITEM',
        name: 'Root Item',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Root Item',
        type: 'Boolean',
        fieldType: 'Boolean',
        displayAs: 'Boolean',
        updateable: false,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'Boolean',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            HISTORY_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative && !initiative.parent;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return (initiative && !initiative.parent).toString();
        },
    },
    TNK_TITLE: {
        id: 'TNK_TITLE',
        name: 'Title',
        entityFieldName: 'title',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Title',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            SCHEDULED_REPORTS_EMAIL: true,
            INNER_LIST_FILTER: true,
            TRIGGER_ACTIONS: true,

            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,

            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,

            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,
            ASK_PERSON_FIELD_UPDATE: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            UPLOAD_FILE_FORM_FIELD: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative ? initiative.title : null;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            return trackHelper.updateInitiativeTitle(initiative.id, value);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.title;
        },
    },
    TNK_DESCRIPTION: {
        id: 'TNK_DESCRIPTION',
        name: 'Description',
        entityFieldName: 'description',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Description',
        type: 'LongString',
        fieldType: 'LongString',
        displayAs: 'LongString',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'LongString',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            TRIGGER_ACTIONS: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,
            TONKEAN_FORMS: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            INNER_LIST_FILTER: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            FIELDS_TAB_SELECTOR: true,
            TONKEAN_EXPRESSION: true,
            ASK_PERSON_FIELD_UPDATE: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            UPLOAD_FILE_FORM_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative ? initiative.description : null;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            return trackHelper.updateInitiativeDescription(initiative.id, value);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.description;
        },
    },
    TNK_OWNER_ID: {
        id: 'TNK_OWNER_ID',
        name: 'Owner',
        entityFieldName: 'owner',

        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Owner',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TRIGGER_ACTIONS: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,
            ASK_PERSON_FIELD_UPDATE: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative?.owner ? initiative.owner.id : null;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be an owner person id.
            return trackHelper.setOwnerById(initiative, value, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.owner ? initiative.owner.id : '';
        },
    },
    TNK_OWNER_NAME: {
        id: 'TNK_OWNER_NAME',
        name: 'Owner Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Owner Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            SCHEDULED_REPORTS_EMAIL: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            TEST_WORKER_INSPECT: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative?.owner && 'name' in initiative.owner ? initiative.owner.name : null;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.owner && 'name' in initiative.owner ? initiative.owner.name : '';
        },
    },
    TNK_OWNER_EMAIL: {
        id: 'TNK_OWNER_EMAIL',
        name: 'Owner Email',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Owner Email',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,

            CREATE_ITEM_WORKER_LOGIC: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            TEST_WORKER_INSPECT: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative?.owner && 'email' in initiative.owner ? initiative.owner.email : null;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.owner && 'email' in initiative.owner ? initiative.owner.email : '';
        },
    },
    TNK_DUE_DATE: {
        id: 'TNK_DUE_DATE',
        name: 'Due Date',
        entityFieldName: 'dueDate',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Due Date',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: true,
        canUpdateFromUI: true,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            SCHEDULED_REPORTS_EMAIL: true,
            INNER_LIST_FILTER: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,

            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,

            TRIGGER_ACTIONS: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,

            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,

            ASK_PERSON_FIELD_UPDATE: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative.dueDate;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be a date (unix time or Date object).
            return trackHelper.updateDueDate(initiative.id, value, true, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.dueDate ? formatDate(new Date(initiative.dueDate).getTime(), initiative) : '';
        },
    },
    TNK_STAGE: {
        id: 'TNK_STAGE',
        name: 'Status',
        entityFieldName: 'stateText',

        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Status',
        type: 'List',
        fieldType: 'List',
        displayAs: 'List',
        evaluatedDisplayType: 'List',
        updateable: true,
        canUpdateFromUI: true,
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            SCHEDULED_REPORTS_EMAIL: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            TRIGGER_ACTIONS: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,
            MATCH_TRACK_FIELD: true,
            WORKER_UPDATE_FIELD: true,
            CREATE_TRACK_UPDATE_FIELDS: true,

            MONITOR_DESCRIPTION: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,

            ASK_PERSON_FIELD_UPDATE: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractServerValue: (initiative: Initiative, workflowVersion) => {
            // If initiative is undefined or the status is 'FUTURE' which means
            // its a default and there is no real status.
            if (!initiative || initiative.status === 'FUTURE' || !initiative.stateText) {
                return null;
            }
            return workflowVersion.stateLabelToStateMap[initiative.stateText]?.id;
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            // If initiative is undefined or the status is 'FUTURE' which means
            // its a default and there is no real status.
            if (!initiative || initiative.status === 'FUTURE' || !initiative.stateText) {
                return null;
            }

            // Extract the state text by the state text from the stateLabelToStateMap (enriched in entityHelper).
            // The same map is used later to get the full state by its label, so this is a mean to make sure we return a valid text.
            return workflowVersion.stateLabelToStateMap[initiative.stateText]?.label;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be a state label.
            // We get the state by its label (if exists) from the stateLabelToStateMap on the initiative's group.
            let state = null;
            if (value) {
                state = workflowVersion.stateLabelToStateMap[value];
            }
            return trackHelper.updateInitiativeState(initiative, state, initiative.updateText, initiative.eta, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.stateText;
        },
    },
    TNK_STATUS_TEXT: {
        id: 'TNK_STATUS_TEXT',
        name: 'Status',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Status',
        type: 'List',
        fieldType: 'List',
        displayAs: 'List',
        evaluatedDisplayType: 'List',
        doNotSupportUpdateFromExternalField: true,
        updateable: true,
        canUpdateFromUI: true,
        considerInTypes: {
            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,

            BASIC_FIELDS: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractServerValue: (initiative: Initiative, workflowVersion) => {
            // If initiative is undefined or the status is 'FUTURE' which means
            // its a default and there is no real status.
            if (!initiative || initiative.status === 'FUTURE' || !initiative.stateText) {
                return null;
            }
            return workflowVersion.stateLabelToStateMap[initiative.stateText]?.id ?? null;
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            if (!initiative?.group) {
                return null;
            }

            // Extract the state text by the state text from the stateLabelToStateMap (enriched in entityHelper).
            // The same map is used later to get the full state by its label, so this is a mean to make sure we return a valid text.
            return workflowVersion.stateLabelToStateMap[initiative.stateText].label;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be a state label.
            // We get the state by its label (if exists) from the stateLabelToStateMap on the initiative's group.
            let state = null;
            if (value) {
                state = workflowVersion.stateLabelToStateMap[value];
            }
            return trackHelper.updateInitiativeState(initiative, state, initiative.updateText, initiative.eta, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.stateText;
        },
    },
    TNK_ETA: {
        id: 'TNK_ETA',
        name: 'ETA',
        entityFieldName: 'eta',

        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'ETA',
        fieldType: 'Date',
        type: 'Date',
        displayAs: 'Date',
        updateable: true,
        canUpdateFromUI: true,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            TRIGGER_ACTIONS: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,

            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,

            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,

            ASK_PERSON_FIELD_UPDATE: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative.eta;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be a date (unix time).
            // We build the current state from the given initiative data.
            const state = { type: initiative.status, label: initiative.stateText, color: initiative.stateColor };
            return trackHelper.updateInitiativeState(initiative, state, initiative.updateText, value, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.eta ? formatDate(new Date(initiative.eta).getTime(), initiative) : '';
        },
    },
    TNK_PREVIOUS_ACTOR_NAME: {
        id: 'TNK_PREVIOUS_ACTOR_NAME',
        name: 'Previous Actor Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Previous Actor Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR_FLOW: true,
            WORKFLOW_FORMULAS: true,
        },
    },
    TNK_PREVIOUS_ACTOR_EMAIL: {
        id: 'TNK_PREVIOUS_ACTOR_EMAIL',
        name: 'Previous Actor Email',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Previous Actor Email',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR_FLOW: true,
            WORKFLOW_FORMULAS: true,
        },
    },
    TNK_PREVIOUS_REPLY_TEXT: {
        id: 'TNK_PREVIOUS_REPLY_TEXT',
        name: 'Previous Reply Text',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Previous Reply Text',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR_FLOW: true,
            WORKFLOW_FORMULAS: true,
        },
    },
    TNK_PREVIOUS_EMAIL_ATTACHMENT_IDS: {
        id: 'TNK_PREVIOUS_EMAIL_ATTACHMENT_IDS',
        name: 'Previous Reply attachment ids',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Previous Reply attachment ids',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR_FLOW: true,
            WORKFLOW_FORMULAS: true,
        },
    },
    TNK_AUTONOMOUS_TRIGGER_NAME: {
        id: 'TNK_AUTONOMOUS_TRIGGER_NAME',
        name: 'Trigger Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Trigger Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR_FLOW: true,
        },
    },
    TNK_CREATOR_NAME: {
        id: 'TNK_CREATOR_NAME',
        name: 'Creator Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Creator Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return 'name' in initiative.creator ? initiative.creator.name : '';
        },
    },
    TNK_TRACK_URL: {
        id: 'TNK_TRACK_URL',
        name: 'Track Url',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Track Url',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return `${location.protocol}//${location.host}/${initiative.project.id}/board/all?g=${initiative.group.id}&tid=${initiative.id}`;
        },
    },
    TNK_CREATE_INNER_TRACKS_URL: {
        id: 'TNK_CREATE_INNER_TRACKS_URL',
        name: 'Create Inner Tracks Url',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Create Inner Items Url',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return `${location.protocol}//${location.host}/update/${initiative.project.id}?id=${initiative.id}&inner=true`;
        },
    },
    TNK_INITIATIVE_ID: {
        id: 'TNK_INITIATIVE_ID',
        name: 'Initiative Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Initiative Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.id;
        },
    },
    TNK_REAL_INITIATIVE_ID: {
        id: 'TNK_REAL_INITIATIVE_ID',
        name: 'Real Initiative Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Real Initiative Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.id;
        },
    },
    TNK_EXTERNAL_ID: {
        id: 'TNK_EXTERNAL_ID',
        name: 'External Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'External Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.externalId ?? '';
        },
    },
    TNK_ACTION_ENTITY_ID: {
        id: 'TNK_ACTION_ENTITY_ID',
        name: 'Action Entity Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Action Entity Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
        },
    },
    TNK_PROJECT_ID: {
        id: 'TNK_PROJECT_ID',
        name: 'Project Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Project Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.project.id;
        },
    },
    TNK_GROUP_ID: {
        id: 'TNK_GROUP_ID',
        name: 'Group Id',
        isSpecialField: true,
        groupBy: 'TONKEAN',
        label: 'Group Id',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',

        considerInTypes: {
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.group.id;
        },
    },
    TNK_CREATOR_EMAIL: {
        id: 'TNK_CREATOR_EMAIL',
        name: 'Creator Email',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Creator Email',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,

            TEST_WORKER_INSPECT: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative?.creator && 'email' in initiative.creator ? initiative.creator.email : null;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return 'email' in initiative.creator ? initiative.creator.email : '';
        },
    },
    TNK_PARENT_NAME: {
        id: 'TNK_PARENT_NAME',
        name: 'Parent Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Parent Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            MATCH_TRACK_FIELD: true,
            TRIGGER_ACTIONS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.parent && 'title' in initiative.parent ? initiative.parent.title : '';
        },
    },
    TNK_PARENT_OWNER_EMAIL: {
        id: 'TNK_PARENT_OWNER_EMAIL',
        name: 'Parent Owner Email',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Parent Owner Email',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            MATCH_TRACK_FIELD: true,
            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            HISTORY_FIELDS: true,
            TRIGGER_ACTIONS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.parent &&
                'owner' in initiative.parent &&
                initiative.parent.owner &&
                'email' in initiative.parent.owner
                ? initiative.parent.owner.email
                : '';
        },
    },
    TNK_PARENT_ID: {
        id: 'TNK_PARENT_ID',
        name: 'Parent ID',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Parent ID',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            MATCH_TRACK_FIELD: true,
            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            HISTORY_FIELDS: true,
            TRIGGER_ACTIONS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.parent?.id ?? '';
        },
    },
    TNK_WORKFLOW_VERSION_TYPE: {
        id: 'TNK_WORKFLOW_VERSION_TYPE',
        name: 'Environment',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Environment',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        canUpdateFromUI: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            MATCH_TRACK_FIELD: true,
            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            HISTORY_FIELDS: true,
            TRIGGER_ACTIONS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.isDraftInitiative
                ? workflowVersionTypeToDisplayName(WorkflowVersionType.DRAFT)
                : workflowVersionTypeToDisplayName(WorkflowVersionType.PUBLISHED);
        },
    },
    TNK_UPDATE_TEXT: {
        id: 'TNK_UPDATE_TEXT',
        name: 'Update Text',
        entityFieldName: 'updateText',

        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Update Text',
        type: 'LongString',
        fieldType: 'LongString',
        displayAs: 'LongString',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'LongString',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            SCHEDULED_REPORTS_EMAIL: true,
            INNER_LIST_FILTER: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,

            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,

            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            TRIGGER_ACTIONS: true,
            TEST_WORKER_INSPECT: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            HISTORY_FIELDS: true,
            TONKEAN_FORMS: true,
            ASK_PERSON_FIELD_UPDATE: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative.updateText;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            // In this case, value should be a string.
            // We build the current state from the given initiative data.
            const state = { type: initiative.status, label: initiative.stateText, color: initiative.stateColor };
            return trackHelper.updateInitiativeState(initiative, state, value, initiative.eta, null);
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.updateText ?? '';
        },
    },
    TNK_CREATED_DATE: {
        id: 'TNK_CREATED_DATE',
        name: 'Created',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Created',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: false,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            TEST_WORKER_INSPECT: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative.created;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return formatDate(initiative.created, initiative);
        },
    },
    TNK_LAST_BOT_PING: {
        id: 'TNK_LAST_BOT_PING',
        name: 'Last Module Ping',
        entityFieldName: 'lastGatherUpdate',

        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Last Module Ping',
        updateable: false,
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MONITOR_DESCRIPTION: true,
            BASIC_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            HISTORY_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.lastGatherUpdate
                ? formatDate(new Date(initiative.lastGatherUpdate).getTime(), initiative)
                : '';
        },
    },
    TNK_NEXT_BOT_PING: {
        id: 'TNK_NEXT_BOT_PING',
        name: 'Next Module Ping',
        entityFieldName: 'nextGatherUpdate',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Next Module Ping',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: true,
        canUpdateFromUI: true,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            TRIGGER_ACTIONS: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CREATE_TRACK_UPDATE_FIELDS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative.nextGatherUpdate;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.nextGatherUpdate
                ? formatDate(new Date(initiative.nextGatherUpdate).getTime(), initiative)
                : '';
        },
    },
    TNK_ITEM_NOTIFICATION_CHANNEL: {
        id: 'TNK_ITEM_NOTIFICATION_CHANNEL',
        name: 'Item Notification',
        // entityFieldName: 'nextGatherUpdate',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Item Notification',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
        },
        extractServerValue: (initiative: Initiative, workflowVersion) => {
            return initiative?.customNotificationSettings;
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative?.customNotificationSettings?.channelName;
        },
    },
    TNK_MODIFIED_DATE: {
        id: 'TNK_MODIFIED_DATE',
        name: 'Modified',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Modified',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: false,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,

            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,

            TEST_WORKER_INSPECT: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            return initiative ? initiative.updated : null;
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return formatDate(initiative.updated, initiative);
        },
    },
    function: {
        id: 'function',
        name: 'Function',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Function',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        fromOriginalEntity: false,
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
        },
        ignoreInItemInterfaces: true,
    },
    tags: {
        id: 'tags',
        name: 'Tags',
        isSpecialField: true,
        entityFieldName: 'tags',

        groupBy: 'TONKEAN',

        label: 'Tags',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        fromOriginalEntity: false,
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            WORKER_UPDATE_FIELD: true,
            INTERFACE_SUBMITTED_INITIAL_FIELDS: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            SCHEDULED_REPORTS: true,
            INNER_LIST_FILTER: true,
            AGGREGATE_ON_COLUMN: true,
            MONITOR_DESCRIPTION: true,
            HISTORY_FIELDS: true,
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            if (!initiative.tags) {
                initiative.tags = [];
            }
            return initiative.tags;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            const tags = value || [];

            return trackHelper.updateTags(initiative.id, tags);
        },
        ignoreInItemInterfaces: true,
    },
    TNK_TAGS: {
        id: 'TNK_TAGS',
        name: 'Tags',
        isSpecialField: true,
        entityFieldName: 'tags',

        groupBy: 'TONKEAN',

        label: 'Tags',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        fromOriginalEntity: false,
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        extractServerValue: (initiative: Initiative, workflowVersion) => {
            return initiative.tags.join(',');
        },
        extractValueFromInitiative: (initiative: Initiative, workflowVersion) => {
            if (!initiative.tags) {
                initiative.tags = [];
            }
            return initiative.tags;
        },
        updateValueOnInitiative: (trackHelper, initiative: Initiative, value, workflowVersion) => {
            const tags = value || [];

            return trackHelper.updateTags(initiative.id, tags);
        },
        considerInTypes: {
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            TONKEAN_FORMS: true,
            ASK_PERSON_FIELD_UPDATE: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            BASIC_FIELDS: true,
            TRIGGER_ACTIONS: true,
            ITEM_INTERFACE_FIELDS_WIDGET: true,
            ITEM_INTERFACE_CHART_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET: true,
            ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER: true,
            ITEM_INTERFACE_UPLOAD_WIDGET: true,
            PROCESS_MAPPER_CONDITIONS_FIELDS: true,
            ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.tags.join(',');
        },
    },
    TNK_FUNCTION: {
        id: 'TNK_FUNCTION',
        name: 'Function',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Function',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: true,
        canUpdateFromUI: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_TRACK_FIELD: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.function?.['name'] ? initiative.function['name'] : '';
        },
    },
    TNK_TODAY: {
        id: 'TNK_TODAY',
        name: 'Today',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Today',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: false,
        notInitiativeRelated: true,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKFLOW_FORMULAS: true,
            AGGREGATE_ON_COLUMN: true,
            TONKEAN_EXPRESSION: true,
            FIELDS_TAB_SELECTOR: true,
            CUSTOM_TRIGGER_CONDITIONS_V1: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return Date.now().toString();
        },
    },
    TNK_NOW: {
        id: 'TNK_NOW',
        name: 'Now',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Now',
        type: 'Date',
        fieldType: 'Date',
        displayAs: 'Date',
        updateable: false,
        notInitiativeRelated: true,
        displayFormat: 'DEFAULT_DATE',
        evaluatedDisplayType: 'Date',
        considerInTypes: {
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return Date.now().toString();
        },
    },
    TNK_VIEWER_EMAIL: {
        id: 'TNK_VIEWER_EMAIL',
        name: 'Viewer email',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Viewer email',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        notInitiativeRelated: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            VIEWER_FIELDS: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return viewer.email;
        },
    },
    TNK_VIEWER_NAME: {
        id: 'TNK_VIEWER_NAME',
        name: 'Viewer name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Viewer name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        notInitiativeRelated: true,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            VIEWER_FIELDS: true,
            WORKER_INNER_ITEM_CREATION_FIELDS: true,
            FIELDS_TAB_SELECTOR: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return viewer.name;
        },
    },
    TNK_IS_EXTERNAL: {
        id: 'TNK_IS_EXTERNAL',
        name: 'Is External',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Is External',
        type: 'Boolean',
        fieldType: 'Boolean',
        displayAs: 'Boolean',
        updateable: false,
        evaluatedDisplayType: 'Boolean',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return (initiative.externalId !== undefined).toString();
        },
    },
    TNK_CREATED_BY_FORM_NAME: {
        id: 'TNK_CREATED_BY_FORM_NAME',
        name: 'Created By Form Name',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Created By Form Name',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKER_FORMS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.createdByFormName ?? '';
        },
    },
    TNK_CREATED_BY_FORM_ID: {
        id: 'TNK_CREATED_BY_FORM_ID',
        name: 'Created By Form ID',
        isSpecialField: true,
        groupBy: 'TONKEAN',

        label: 'Created By Form ID',
        type: 'String',
        fieldType: 'String',
        displayAs: 'String',
        updateable: false,
        evaluatedDisplayType: 'String',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            COLUMN_FORMULA: true,
            HISTORY_FIELDS: true,
            WORKER_FORMS: true,
            MATCH_CONDITIONAL_WIDGETS: true,
        },
        getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person): string => {
            return initiative.createdByFormId?.toString() ?? '';
        },
    },
    TNK_OTHER: {
        id: 'TNK_OTHER',
        name: 'TNK_OTHER',
        isSpecialField: true,
        groupBy: 'OTHER',
        updateable: true, // Adding flag so this fields will not be filtered out when filtering on only updateable field definitions.
        canUpdateFromUI: true,
        label: 'New Manual Field',
        iconModClass: 'mod-manual',
        considerInTypes: {
            CUSTOM_TRIGGER_CONDITIONS: true,
            FIELDS_TAB_SELECTOR: true,
            CREATE_ITEM_WORKER_LOGIC: true,
            ADDED_FIELDS_TO_BOT_MESSAGE: true,
            OTHER_ONLY: true,
            TONKEAN_EXPRESSION: true,
            TONKEAN_FORMS: true,
            TNK_OTHER: true,
            ASK_PERSON_FIELD_UPDATE: true,
        },
    },
    TNK_OTHER_MATCHED_ENTITY: {
        id: 'TNK_OTHER_MATCHED_ENTITY',
        name: 'TNK_OTHER_MATCHED_ENTITY',
        isSpecialField: true,
        groupBy: 'OTHER',
        updateable: true, // Adding flag so this fields will not be filtered out when filtering on only updateable field definitions.
        canUpdateFromUI: true,
        label: 'New Matched Entity',
        iconModClass: 'mod-manual',
        considerInTypes: {
            OTHER_MATCHED_ENTITY_ONLY: true,
        },
    },
};

export function formatDate(date: number, initiative: Initiative): string {
    const timezoneOffset = initiative.creator && 'timezone' in initiative.creator ? initiative.creator.timezone : 0;
    return dayjs(date).add(timezoneOffset, 'hours').format('MMM D, YYYY');
}

/**
 * Gets all the special fields relevant for given features.
 */
export function getSpecialFieldsForFeatures(returnAllSpecialFields, features?: string[]): any {
    const allSpecialFields: any = Utils.objValues(FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP);

    // If we are requested to return all special fields, we just return them.
    if (returnAllSpecialFields) {
        return allSpecialFields;
    }

    // Otherwise, we filter by features array.
    const featuresSet = Utils.arrayToSet(features);
    return allSpecialFields.filter(
        (specialField) => Utils.intersectSets(featuresSet, specialField.considerInTypes).length,
    );
}

/**
 * Returns the special field id to definition map.
 */
export function getFormulaSpecialFieldIdToDefinitionMap() {
    return cloneDeep(FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP);
}

/**
 * Gets the field object for given field id.
 */
export function getFullFieldObject(fieldId, workflowVersionId, selectedFieldsMap) {
    if (FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[fieldId]) {
        return FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[fieldId];
    }
    // Trying to locate field definition in columns.
    const fieldDefinition = Utils.findFirst(selectedFieldsMap[workflowVersionId], (field: any) => field.id === fieldId);

    if (fieldDefinition) {
        return fieldDefinition;
    }
}
