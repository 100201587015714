import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

// get validate source from source selector
function validateSourceSelectorDefinitions(sourceObject, validationObject) {
    const validUrlSource = sourceObject?.type === 'URL' && sourceObject?.url?.evaluatedExpression;

    const validUrlSourceUsingNewConfiguration =
        sourceObject?.fileSourceType === 'URL' && sourceObject?.url?.evaluatedExpression;

    const validDataStorageInput =
        sourceObject?.fileSourceType === 'DATA_STORAGE' && sourceObject?.extendedMatchConfiguration;

    const validStaticAssetSource =
        sourceObject?.fileSourceType === 'UPLOAD_FILE' &&
        sourceObject?.workerStaticAssetId &&
        sourceObject?.workerStaticAssetDisplayName;

    const validIntegrationSource =
        sourceObject?.type === 'Integration' &&
        sourceObject?.storageIntegrationId &&
        sourceObject?.fileId?.evaluatedExpression;

    const validMatchedEntitySource =
        sourceObject?.fileSourceType === 'MATCHED_ENTITIES' &&
        sourceObject?.matchEntityConfiguration?.matchedEntityId &&
        sourceObject?.matchEntityConfiguration?.matchedEntityId !== '';

    if (
        !validUrlSource &&
        !validUrlSourceUsingNewConfiguration &&
        !validIntegrationSource &&
        !validStaticAssetSource &&
        !validDataStorageInput &&
        !validMatchedEntitySource
    ) {
        validationObject.noSource = 'Must fill file source.';
    }
}

const StorageUploadLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.STORAGE_UPLOAD,
    title: 'File Upload',
    iconClass: 'mod-storage-upload',
    description: 'Upload file to a storage provider',
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};
        validateSourceSelectorDefinitions(definition.source, validationObject);

        if (definition?.destinationFolderIdExpression?.originalExpression === '') {
            validationObject.destinationFolder = "Destination folder can't be empty";
        }
        if (definition?.fileNameExpression?.originalExpression === '') {
            validationObject.fileName = "File name can't be empty";
        }
        if (definition?.storageProviderDestinationId === '') {
            validationObject.storage = "Storage provider can't be empty";
        }

        if (definition?.source.fileSourceType === 'MATCHED_ENTITIES') {
            if (definition?.source?.matchEntityConfiguration?.format === '') {
                validationObject.template = 'Invalid format';
            }
            if (definition?.source?.matchEntityConfiguration.columnsTemplates.length === 0) {
                validationObject.template = 'At least 1 column is required';
            } else if (
                definition?.source?.matchEntityConfiguration.columnsTemplates
                    .map((column) => column.name)
                    .some((name) => !name && name === '')
            ) {
                validationObject.template = 'All the column names must be filled';
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default StorageUploadLogicBlockConfig;
