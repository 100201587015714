import React, { useState } from 'react';

import { ReactComponent as AddIcon } from '../../../images/icons/add.svg';

import { KebabMenuButton, Menu, MenuItem } from '@tonkean/infrastructure';
import { ManageSolutionBusinessReportModal } from '@tonkean/solution-business-report';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import type { SolutionBusinessReport } from '@tonkean/tonkean-entities';

interface Props {
    workflowFolder: WorkflowFolder;

    onCreated(solutionBusinessReport: SolutionBusinessReport): void;
}

const SolutionBusinessReportPopover: React.FC<Props> = ({ workflowFolder, onCreated }) => {
    const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    return (
        <>
            <Menu
                menuItems={
                    <>
                        <MenuItem onClick={() => setCreateReportModalOpen(true)} icon={<AddIcon />}>
                            Create New Report
                        </MenuItem>
                        {/* <MenuItem onClick={() => {}} icon={<UserIcon />}>*/}
                        {/*    Solution Permissions*/}
                        {/* </MenuItem>*/}
                    </>
                }
                show={popoverOpen}
                onClose={() => setPopoverOpen(false)}
            >
                <KebabMenuButton
                    onClick={(event) => {
                        event.stopPropagation();
                        setPopoverOpen(true);
                    }}
                    flat
                    thin
                />
            </Menu>

            <ManageSolutionBusinessReportModal
                workflowFolder={workflowFolder}
                open={createReportModalOpen}
                onClose={() => setCreateReportModalOpen(false)}
                onSave={onCreated}
            />
        </>
    );
};

export default SolutionBusinessReportPopover;
