import React from 'react';
import styled from 'styled-components';

import ItemInterfaceScrollContextProvider from './ItemInterfaceScrollContextProvider';
import ItemInterfaceBuilderEmptySpot from '../../../components/ItemInterfaceBuilderEmptySpot';
import ItemInterfaceWidgetsPanel from '../../../components/ItemInterfaceWidgetsPanel';
import { ItemInterfacePermission } from '../../../entities';

import {
    Breakpoint,
    ItemInterfaceSection,
    LayoutGridElements,
    PoweredByTonkean,
    Spacer,
    useItemInterfaceContext,
    widthByLayoutGridColumns,
} from '@tonkean/infrastructure';
import type {
    Initiative,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div`
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        border-radius: 8px 8px 0px 0px;
    }
`;

const Body = styled.div<{
    $cleanMode?: boolean;
    $noBackground: boolean;
    $tabsView?: boolean;
    $nestedBackground: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ $cleanMode, $tabsView }) => {
        if ($cleanMode) {
            return '0';
        }
        if ($tabsView) {
            return '32px 32px 0';
        }
        return '0 32px';
    }};
    background-color: ${({ $noBackground, $nestedBackground }) =>
        $nestedBackground ? Theme.colors.gray_300 : $noBackground ? Theme.colors.gray : 'white'};
    flex-grow: 1;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 0 16px;
    }
`;

const GrowingSpacer = styled(Spacer)`
    flex-grow: 1;
`;

const PoweredByWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;

    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;

    ${widthByLayoutGridColumns};

    width: 100%;
    max-width: var(${LayoutGridElements.WIDTH});
`;

export interface ItemInterfaceBodyProps extends StyledComponentsSupportProps {
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    permission: ItemInterfacePermission;
    showPoweredByTonkean: boolean;
    cleanMode?: boolean;
    scrollingObjectStarted?: Event;
    tabsView?: boolean;
    isNested?: boolean;
}

const ItemInterfaceBody: React.FC<ItemInterfaceBodyProps> = ({
    initiative,
    workflowVersion,
    itemInterfaceWidgetsState,
    widgets,
    showPoweredByTonkean,
    cleanMode,
    tabsView,
    permission,
    scrollingObjectStarted,
    isNested = false,
    className,
}) => {
    const { section } = useItemInterfaceContext();
    return (
        <Wrapper data-automation="interface-body">
            <ItemInterfaceScrollContextProvider scrollingEventObject={scrollingObjectStarted}>
                <Body
                    className={className}
                    $cleanMode={cleanMode}
                    $noBackground={section === ItemInterfaceSection.MAIN}
                    $tabsView={tabsView}
                    $nestedBackground={isNested}
                >
                    {workflowVersion && itemInterfaceWidgetsState !== ItemInterfaceLoadingState.LOADING ? (
                        <>
                            {!!widgets.length && (
                                <>
                                    <ItemInterfaceWidgetsPanel
                                        itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                                        itemInterfaceWidgets={widgets}
                                        widgetProps={{
                                            initiative,
                                            workflowVersionId: workflowVersion.id,
                                            workflowVersionType: workflowVersion.workflowVersionType,
                                            permission,
                                        }}
                                        permission={permission}
                                        cleanMode={cleanMode}
                                        section={section}
                                    />
                                    {showPoweredByTonkean && !cleanMode && (
                                        <>
                                            <GrowingSpacer height={16} />
                                            <PoweredByWrapper>
                                                <PoweredByTonkean />
                                            </PoweredByWrapper>
                                        </>
                                    )}
                                </>
                            )}
                            {permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE && (
                                <>
                                    <Spacer height={32} />
                                    <ItemInterfaceBuilderEmptySpot />
                                </>
                            )}
                            {!cleanMode && <Spacer height={32} />}
                        </>
                    ) : (
                        <ItemInterfaceWidgetsPanel loading />
                    )}
                </Body>
            </ItemInterfaceScrollContextProvider>
        </Wrapper>
    );
};

export default ItemInterfaceBody;
