import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MicrosoftAdminCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        previewEvaluationSource: ctrl.previewEvaluationSource,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onPasswordMethodTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.passwordMethodExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewPasswordTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.newPasswordExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onManagerIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.managerIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMfaStateTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.mfaStateExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onGroupIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.groupIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLicensesAddedTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.licensesAddedExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLicensesRemovedTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.licensesRemovedExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDescriptionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.descriptionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUsageLocationTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.usageLocationExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPoliciesIdsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.policiesIdsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'MicrosoftAdminCustomActionsDefinitionCtrl',
        lateConstructController(MicrosoftAdminCustomActionsDefinitionCtrl),
    );
