import React, { useCallback, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import FormSettingsOptionalSubTitle from './FormSettingsOptionalSubTitle';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';
import FormSettingsSelectWrapper from './FormSettingsSelectWrapper';

import { angularToReact } from '@tonkean/angular-components';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { SavingIndicator } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import type { CreateForm } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const SearchInitiatives = angularToReact<{
    groupId: string;
    selectedInitiativeId?: string | null;
    canClear: boolean;
    shouldSetDefaultInitiative?: boolean;
    onlyRoots?: boolean;
    onlyDraftInitiatives?: boolean;
    onInitiativeSelected: (initiativeId: { id: string | null }) => void;
    defaultInitiativesAmount?: number;
}>('tnk-search-initiatives', {
    onInitiativeSelected: ['selectedSimplifiedInitiative', 'isInit'],
});

const NewTrackLocationWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;

    display: flex;
    align-items: flex-end;
`;

interface Props {
    groupId: string;
    form: CreateForm;
    onFormChanged: Dispatch<SetStateAction<CreateForm>>;
}

const FormSettingsTrackLocation: React.FC<Props> = ({ groupId, form, onFormChanged }) => {
    const { editedSections, setSectionLoading } = useContext(FormBuilderContext);

    const onChange = useCallback(
        (value: { id: string | null }) => {
            if (form && value.id !== form.definition.addUnderInitiativeId) {
                onFormChanged((prevForm) => ({
                    ...prevForm,
                    definition: { ...prevForm.definition, addUnderInitiativeId: value.id },
                }));
                setSectionLoading(FormBuilderSections.NewTrackLocation);
            }
        },
        [form, onFormChanged, setSectionLoading],
    );

    return (
        <>
            <NewTrackLocationWrapper>
                <FormSettingsSectionHeaderTitle>New Item Location</FormSettingsSectionHeaderTitle>{' '}
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.NewTrackLocation]} />
            </NewTrackLocationWrapper>
            <FormSettingsSectionHeaderSubtitle>
                To create an inner item, select the parent item under which the inner item will be stored. If no item is
                selected, the new item is created as a root item.
            </FormSettingsSectionHeaderSubtitle>
            <FormSettingsSelectWrapper fixedHeight>
                <SearchInitiatives
                    groupId={groupId}
                    selectedInitiativeId={form.definition.addUnderInitiativeId}
                    onInitiativeSelected={onChange}
                    canClear
                />
            </FormSettingsSelectWrapper>
        </>
    );
};

export default FormSettingsTrackLocation;
