import { useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-new.svg';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Spacer, useFormikArrayField } from '@tonkean/infrastructure';
import type { IntegrationRequestInterceptorParameter } from '@tonkean/tonkean-entities';
import { IntegrationRequestInterceptorOption } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import utils from '@tonkean/utils';

const SectionWrapper = styled.div`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 28px;
    align-items: center;
`;

interface IntegrationRequestInterceptorDataWithIndex extends IntegrationRequestInterceptorParameter {
    index: number;
}

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentRequestInterceptorData: React.FC<Props> = ({ expressionProps }) => {
    const {
        value: integrationRequestInterceptorParameters,
        push,
        remove,
    } = useFormikArrayField<IntegrationRequestInterceptorParameter[]>('integrationRequestInterceptorParameters');

    // split headers and query from additional integration request interceptor data.
    const [headers, queryParams] = useMemo(() => {
        return (integrationRequestInterceptorParameters || []).reduce(
            (prev, curr, index) => {
                switch (curr.addTo) {
                    case IntegrationRequestInterceptorOption.HEADER:
                        return [[...prev[0], { ...curr, index }], prev[1]];

                    case IntegrationRequestInterceptorOption.QUERY_PARAMS:
                        return [prev[0], [...prev[1], { ...curr, index }]];
                }
            },
            [[], []] as [IntegrationRequestInterceptorDataWithIndex[], IntegrationRequestInterceptorDataWithIndex[]],
        );
    }, [integrationRequestInterceptorParameters]);

    return (
        <>
            <EnterpriseComponentAuthorizationParam>Headers</EnterpriseComponentAuthorizationParam>

            <SectionWrapper>
                {headers.map((header) => (
                    <React.Fragment key={header.id}>
                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Header Key"
                            name={`integrationRequestInterceptorParameters[${header.index}].key`}
                        />

                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Header Value"
                            name={`integrationRequestInterceptorParameters[${header.index}].value`}
                        />

                        <IconButton
                            onClick={() => {
                                remove(header.index);
                            }}
                            flat
                        >
                            <TrashIcon />
                        </IconButton>
                    </React.Fragment>
                ))}
            </SectionWrapper>
            <Spacer height={12} />

            <ClickableLink
                onClick={() => {
                    push({
                        id: utils.guid(),
                        key: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
                        value: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
                        addTo: IntegrationRequestInterceptorOption.HEADER,
                    });
                }}
            >
                + Header
            </ClickableLink>
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Query Params</EnterpriseComponentAuthorizationParam>

            <SectionWrapper>
                {queryParams.map((queryParam) => (
                    <React.Fragment key={queryParam.id}>
                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Header Key"
                            name={`integrationRequestInterceptorParameters[${queryParam.index}].key`}
                        />

                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Header Value"
                            name={`integrationRequestInterceptorParameters[${queryParam.index}].value`}
                        />

                        <IconButton
                            onClick={() => {
                                remove(queryParam.index);
                            }}
                            flat
                        >
                            <TrashIcon />
                        </IconButton>
                    </React.Fragment>
                ))}
            </SectionWrapper>

            <Spacer height={12} />

            <ClickableLink
                onClick={() => {
                    push({
                        id: utils.guid(),
                        key: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
                        value: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
                        addTo: IntegrationRequestInterceptorOption.QUERY_PARAMS,
                    });
                }}
            >
                + Query Param
            </ClickableLink>
        </>
    );
};
export default EnterpriseComponentRequestInterceptorData;
