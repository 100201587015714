import { TreeNode } from './TreeNode';
import type { TreeNodeItem } from './TreeNodeItem';

/**
 * A tree node class with parent and children pointers.
 */
export class TreeBuilder {
    /**
     * Create a tree from a list of items
     */
    public static createTreeFromItemsList<T extends TreeNodeItem>(rootItem: T, items: T[]): TreeNode<T> {
        const root = new TreeNode(rootItem);

        items.map((item) => new TreeNode(item)).forEach((item) => TreeBuilder.addNodeToTree(root, item));

        return root;
    }

    /**
     * Add a single node to it's location in the tree
     */
    private static addNodeToTree<T extends TreeNodeItem>(parent: TreeNode<T>, node: TreeNode<T>): void {
        const possibleParent = parent.children.find((child) => child.isParentOf(node));
        const possibleChildren = parent.children.filter((child) => child.isChildOf(node));

        if (possibleParent) {
            TreeBuilder.addNodeToTree(possibleParent, node);
        } else if (possibleChildren.length) {
            node.pushBetween(parent, possibleChildren);
        } else {
            node.pushBetween(parent);
        }
    }
}
