import invoiceViewModalTemplate from './invoiceViewModal.template.html?angularjs';

function OrgInvoicesCtrl($scope, modalUtils, tonkeanService, projectManager) {
    /**
     * This component used to inherit the scope of the settingsCtrl when rendered from the $state.
     * Now it is rendered with `ng-transclude`, so it doesn't inherit the scope, and we must manually add
     * the values from the parent scope (`$scope.$parent.$parent`) into the `$scope`.
     */
    Object.entries($scope.$parent?.$parent).forEach(([key, value]) => {
        if (!key.startsWith('$')) {
            $scope[key] = value;
        }
    });

    $scope.pm = projectManager;
    $scope.data = {
        upcomingInvoiceId: 'Upcoming',
    };

    $scope.getInvoices = function () {
        $scope.loading = true;
        tonkeanService
            .getProjectInvoices($scope.pm.project.id)
            .then(function (data) {
                $scope.invoices = data.invoices;

                if (!$scope.invoices) {
                    $scope.invoices = [];
                }

                // Add the upcoming invoice as the first invoice (so it's first in the list).
                if ($scope.license && $scope.license.upcomingInvoice && $scope.license.upcomingInvoice.lines.length) {
                    // Maybe use $scope.license.upcomingInvoice.nextPaymentAttempt as the period dates?
                    $scope.license.upcomingInvoice.id = $scope.data.upcomingInvoiceId;
                    $scope.invoices.unshift($scope.license.upcomingInvoice);
                }
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    };

    $scope.viewInvoice = function (invoice) {
        $scope.selectedInvoice = invoice;
        modalUtils.open({
            // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
            template: invoiceViewModalTemplate,
            windowClass: 'initiative-view-modal',
            windowTopClass: 'initiative-view-modal-window',
            scope: $scope,
        });

        // Only load charge if we have the charge id.
        if (invoice.chargeId) {
            $scope.selectedInvoice.loadingCharge = true;
            // todo: get the charge obj async
            tonkeanService.getInvoiceCharge($scope.pm.project.id, invoice.chargeId).then(function (data) {
                $scope.selectedInvoice.loadingCharge = false;
                $scope.selectedInvoice.charge = data;
            });
        }
    };

    $scope.getInvoices();
}
export default angular.module('tonkean.app').controller('OrgInvoicesCtrl', OrgInvoicesCtrl);
