import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CustomTriggerSelectorCtrl($scope, customTriggerManager) {
    const ctrl = this;

    $scope.data = {
        workflowVersionId: ctrl.workflowVersionId,
        selectedCustomTriggerId: ctrl.selectedCustomTriggerId,
        parentCustomTriggerId: ctrl.parentCustomTriggerId,
        customTriggerTypes: ctrl.customTriggerTypes,
        customTriggerSecondaryTypes: ctrl.customTriggerSecondaryTypes,
        customTriggerIdToExclude: ctrl.customTriggerIdToExclude,
        emptyStateMessage: ctrl.emptyStateMessage,
        getCustomTriggerDisplayName: ctrl.getCustomTriggerDisplayName,

        selectedCustomTrigger: null,

        loadingCustomTriggers: false,
        errorLoadingCustomTriggers: false,
        customTriggers: [],
        dontAutoSelectFirstOne: ctrl.dontAutoSelectFirstOne,
        allowClear: ctrl.allowClear,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        selectSelectedTrigger();
        loadCustomTriggers();
    };

    /**
     * Occurs on changes of component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedCustomTriggerId) {
            $scope.data.selectedCustomTriggerId = changesObj.selectedCustomTriggerId.currentValue;
            selectSelectedTrigger();
        }

        if (changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = changesObj.workflowVersionId.currentValue;
            loadCustomTriggers();
        }

        if (changesObj.parentCustomTriggerId) {
            $scope.data.parentCustomTriggerId = changesObj.parentCustomTriggerId.currentValue;
            selectSelectedTrigger();
            loadCustomTriggers();
        }
    };

    /**
     * Occurs once the user selected a custom trigger.
     */
    $scope.onCustomTriggerSelected = function (customTrigger) {
        $scope.data.selectedCustomTrigger = customTrigger;

        if (ctrl.onCustomTriggerSelected) {
            ctrl.onCustomTriggerSelected({
                selectedCustomTrigger: customTrigger,
            });
        }
    };

    /**
     * Gets the display name of the trigger.
     */
    $scope.getCustomTriggerDisplayName = function (customTrigger) {
        if (customTrigger) {
            if (customTrigger.customTriggerType === 'NLP_BRANCH') {
                const directParent = customTriggerManager.getDirectParentInWorkflowVersion(
                    $scope.data.workflowVersionId,
                    customTrigger.id,
                );

                if (directParent) {
                    return `${directParent.node.displayName} - ${customTrigger.displayName}`;
                }
            }

            return customTrigger.displayName;
        }
    };

    /**
     * Loads custom triggers to display.
     */
    function loadCustomTriggers() {
        $scope.data.loadingCustomTriggers = true;
        $scope.data.errorLoadingCustomTriggers = false;

        return customTriggerManager
            .getWorkflowVersionCustomTriggersAndGraph($scope.data.workflowVersionId, false)
            .then((result) => {
                let customTriggerTypesSet = null;
                if ($scope.data.customTriggerTypes) {
                    customTriggerTypesSet = {};
                    $scope.data.customTriggerTypes.forEach(
                        (customTriggerType) => (customTriggerTypesSet[customTriggerType] = true),
                    );
                }

                $scope.data.customTriggers = result.customTriggers.filter((customTrigger) => {
                    const allowedCustomTriggerType =
                        !customTriggerTypesSet || customTriggerTypesSet[customTrigger.customTriggerType];
                    const allowedCustomTriggerSecondaryType = 
                        $scope.data.customTriggerSecondaryTypes?.includes(customTrigger.customTriggerSecondaryType);
                    const allowedCustomTriggerId = $scope.data.customTriggerIdToExclude !== customTrigger.id;
                    const allowedParent =
                        !$scope.data.parentCustomTriggerId ||
                        customTriggerManager.getDirectParentInWorkflowVersion(
                            $scope.data.workflowVersionId,
                            customTrigger.id,
                        )?.node.id === $scope.data.parentCustomTriggerId;

                    return allowedCustomTriggerType && allowedParent && allowedCustomTriggerSecondaryType && allowedCustomTriggerId;
                });

                // Selecting first one if nothing is selected.
                if (
                    !$scope.data.selectedCustomTrigger &&
                    $scope.data.customTriggers?.length &&
                    !$scope.data.dontAutoSelectFirstOne
                ) {
                    $scope.onCustomTriggerSelected($scope.data.customTriggers[0]);
                }
            })
            .catch(() => {
                $scope.data.errorLoadingCustomTriggers = true;
            })
            .finally(() => {
                $scope.data.loadingCustomTriggers = false;
            });
    }

    $scope.clearSelectedCustomTrigger = function () {
        $scope.onCustomTriggerSelected(undefined);
    };

    /**
     * Selects the selected custom trigger by id and makes sure it is not filtered out.
     */
    function selectSelectedTrigger() {
        $scope.data.selectedCustomTrigger = customTriggerManager.getCachedCustomTrigger(
            $scope.data.workflowVersionId,
            $scope.data.selectedCustomTriggerId,
        );

        if ($scope.data.selectedCustomTrigger && $scope.data.parentCustomTriggerId) {
            const directParent = customTriggerManager.getDirectParentInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.selectedCustomTrigger.id,
            );

            if (directParent?.node.id !== $scope.data.parentCustomTriggerId) {
                $scope.data.selectedCustomTrigger = null;
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('customTriggerSelectorCtrl', lateConstructController(CustomTriggerSelectorCtrl));
