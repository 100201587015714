import React, { useState } from 'react';
import type { FormEvent } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { RadioGroup } from '@tonkean/infrastructure';
import { Radio } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const OrigamiRiskComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [environment, setEnvironment] = useState<string>('');
    const [account, setAccount] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [clientName, setClientName] = useState<string | undefined>(undefined);

    const authenticateWithUserAndOrg = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                environment,
                account,
                user,
                password,
                clientName,
            };
            const environmentName = environment === 'live-' ? 'Production' : 'Staging';
            authenticate(config, {}, `${integrationConfiguration.displayName} (${environmentName})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithUserAndOrg}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-3 control-label">
                            Environment
                        </label>
                        <div className="col-sm-8">
                            <RadioGroup
                                value={environment}
                                direction="row"
                                onChange={setEnvironment}
                                size={InputSize.MEDIUM}
                            >
                                <Radio value="staging-">Staging</Radio>
                                <Radio value="live-">Production</Radio>
                            </RadioGroup>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-report" className="col-sm-3 control-label">
                            Account
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={account}
                                className="form-control"
                                id="login-api-report"
                                onChange={({ target }) => setAccount(target.value)}
                                autoComplete="off"
                                placeholder="Account"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-user" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={user}
                                className="form-control"
                                id="login-api-user"
                                onChange={({ target }) => setUser(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-password" className="col-sm-3 control-label">
                            Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="login-api-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-url" className="col-sm-3 control-label">
                            Client Name
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={clientName}
                                className="form-control"
                                id="login-api-url"
                                onChange={({ target }) => setClientName(target.value)}
                                autoComplete="off"
                                placeholder="Client Name"
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default OrigamiRiskComponent;
