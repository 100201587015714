import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ExportWorkflowFolderVersionHistoryModal from './ExportWorkflowFolderVersionHistoryModal';
import type { MarketplaceItemCreateSection } from './marketplace/components/MarketplaceItemCreateModalSection';
import useGetMarketplaceBlueprintItemAdditionalSections from './marketplace/hooks/useGetMarketplaceBlueprintItemAdditionalSections';
import MarketplaceItemCreateEditModal from './MarketplaceItemCreateEditModal';
import PublishSolutionTrigger from './PublishSolutionTrigger';
import { ReactComponent as AvatarIcon } from '../../../../images/icons/avatar.svg';
import { ReactComponent as DownloadIcon } from '../../../../images/icons/download.svg';
import { ReactComponent as HistoryIcon } from '../../../../images/icons/history.svg';
import { ReactComponent as LightningInDottedSquareIcon } from '../../../../images/icons/lightning-in-dotted-square.svg';
import { ReactComponent as LockIcon } from '../../../../images/icons/lock12.svg';
import { ReactComponent as BusinessReportIcon } from '../../../../images/icons/navigation/live-reports.svg';
import { ReactComponent as StackIcon } from '../../../../images/icons/stack.svg';
import { ReactComponent as TrashIcon } from '../../../../images/icons/trash.svg';
import BusinessReportsAccessibilityModal from '../../../modules/SolutionViewerModule/components/BusinessReportsAccessibilityModal';
import OpenManageMakersModal from '../../../modules/SolutionViewerModule/components/OpenManageMakersModal';
import OpenManagePublishersModal from '../../../modules/SolutionViewerModule/components/OpenManagePublishersModal';
import SolutionAccessModal from '../../../modules/SolutionViewerModule/components/SolutionAccessModal';

import { useFeatureFlag, useTonkeanService } from '@tonkean/angular-hooks';
import { IconSvg, KebabMenuButton, Menu, MenuItem, Tooltip } from '@tonkean/infrastructure';
import { BlueprintSolutionType, MarketplaceItemType, type WorkflowFolder } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

const MoreActionsButton = styled(KebabMenuButton)`
    margin-left: 10px;
`;

const StyledHistoryIcon = styled(HistoryIcon)`
    width: 14px !important;
    height: 14px !important;
`;

interface Props {
    workflowFolder: WorkflowFolder;
    canPublishSolution: true | string;

    onSolutionPublished(): void;

    deleteWorkflowFolder(workflowFolderId: string): void;

    openWorkflowFolderVersionsSidePane(workflowFolder: WorkflowFolder): void;
}

const WorkerGallerySolutionOptions: React.FC<Props> = ({
    canPublishSolution,
    workflowFolder,
    onSolutionPublished,
    deleteWorkflowFolder,
    openWorkflowFolderVersionsSidePane,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [makersModalOpen, setMakersModalOpen] = useState(false);
    const [accessModalOpen, setAccessModalOpen] = useState(false);
    const [businessReportsModalOpen, setBusinessReportsModalOpen] = useState(false);
    const [publishersModalOpen, setPublishersModalOpen] = useState(false);

    const isSolutionsReportsListOn = useFeatureFlag('tonkean_feature_show_solution_reports_list');
    const marketPlaceFeatureFlag = useFeatureFlag('tonkean_feature_marketplace');

    const marketplaceBlueprintAdditionalSections = useGetMarketplaceBlueprintItemAdditionalSections();
    const [additionalSections, setAdditionalSections] = useState<MarketplaceItemCreateSection[]>([]);
    const [blueprintSolutionType, setBlueprintSolutionType] = useState<BlueprintSolutionType>();

    const { data: solutionMappersResponse } = useTonkeanService('getSolutionMappers', workflowFolder.id);
    const solutionMappers = solutionMappersResponse?.solutionMappers || [];
    const projectManager = useAngularService('projectManager');
    const authenticationService = useAngularService('authenticationService');

    const canOnlyPublishersPublish = projectManager.project.metadata.allowPublishersManagement;

    const [isUserMaker, groupsCount, projectOwners] = useAngularWatch(
        () => workflowFolder.isUserOwner,
        () => workflowFolder.groupIds.length,
        () => projectManager.project.owners,
    );

    const currentUserId = authenticationService.getCurrentUser().id;
    const isProjectOwner = useMemo(() => {
        return projectOwners.some((owner) => owner.id === currentUserId);
    }, [currentUserId, projectOwners]);

    let deleteDisabledReason: false | string = false;
    if (!isUserMaker) {
        deleteDisabledReason = 'Only Makers and Board Admins can delete';
    } else if (groupsCount !== 0) {
        deleteDisabledReason = 'Please remove all the Modules before deleting a solution';
    }

    const [solutionTemplateModalOpen, setSolutionTemplateModalOpen] = useState(false);

    const [exportWorkflowFolderHistoryOpen, setExportWorkflowFolderHistoryOpen] = useState(false);

    return (
        <Wrapper>
            <PublishSolutionTrigger
                active={canPublishSolution}
                workflowFolder={workflowFolder}
                onPublished={onSolutionPublished}
            />

            <Menu
                show={menuOpen}
                onClose={() => setMenuOpen(false)}
                placement="bottom-end"
                menuItems={
                    <>
                        <MenuItem
                            disabled={!(isUserMaker || isProjectOwner)}
                            onClick={() => setMakersModalOpen(true)}
                            icon={<AvatarIcon />}
                            data-automation="worker-gallery-solution-options-manage-makers"
                        >
                            Manage Makers
                        </MenuItem>

                        {canOnlyPublishersPublish && (
                            <MenuItem
                                disabled={!isProjectOwner}
                                onClick={() => setPublishersModalOpen(true)}
                                icon={<AvatarIcon />}
                                data-automation="worker-gallery-solution-options-manage-publishers"
                            >
                                Manage Publishers
                            </MenuItem>
                        )}

                        <MenuItem disabled={!isUserMaker} onClick={() => setAccessModalOpen(true)} icon={<LockIcon />}>
                            Manage Solution Access
                        </MenuItem>

                        <MenuItem
                            onClick={() => openWorkflowFolderVersionsSidePane(workflowFolder)}
                            icon={<StyledHistoryIcon />}
                        >
                            Versions
                        </MenuItem>
                        <MenuItem
                            onClick={() => setExportWorkflowFolderHistoryOpen(true)}
                            icon={<IconSvg as={DownloadIcon} color={Theme.colors.gray_600} />}
                        >
                            Export versions files
                        </MenuItem>

                        {!isSolutionsReportsListOn && (
                            <MenuItem
                                disabled={!isUserMaker}
                                onClick={() => setBusinessReportsModalOpen(true)}
                                icon={<BusinessReportIcon />}
                                data-automation="worker-gallery-solution-options-manage-business-reports"
                            >
                                Manage Business Reports
                            </MenuItem>
                        )}

                        {marketPlaceFeatureFlag && (
                            <Tooltip
                                disabled={solutionMappers.length > 0}
                                content={"Can't create a blueprint without a solution mapper"}
                            >
                                <MenuItem
                                    disabled={!isUserMaker || !solutionMappers.length}
                                    onClick={() => {
                                        setBlueprintSolutionType(BlueprintSolutionType.MARKETPLACE_BLUEPRINT);
                                        setAdditionalSections(marketplaceBlueprintAdditionalSections);
                                        setSolutionTemplateModalOpen(true);
                                    }}
                                    icon={<StackIcon />}
                                    data-automation="worker-gallery-solution-options-create-marketplace-blueprint"
                                >
                                    Create Blueprint for Marketplace
                                </MenuItem>
                            </Tooltip>
                        )}

                        {marketPlaceFeatureFlag && (
                            <MenuItem
                                disabled={!isUserMaker}
                                onClick={() => {
                                    setBlueprintSolutionType(BlueprintSolutionType.SOLUTION_TEMPLATE);
                                    setSolutionTemplateModalOpen(true);
                                }}
                                icon={<LightningInDottedSquareIcon />}
                                data-automation="worker-gallery-solution-options-create-solution-template"
                            >
                                Create Solution Template
                            </MenuItem>
                        )}

                        <Tooltip disabled={!deleteDisabledReason} content={deleteDisabledReason}>
                            <MenuItem
                                disabled={!!deleteDisabledReason}
                                onClick={() => deleteWorkflowFolder(workflowFolder.id)}
                                icon={<TrashIcon />}
                                data-automation="worker-gallery-solution-options-delete"
                            >
                                Delete
                            </MenuItem>
                        </Tooltip>
                    </>
                }
            >
                <MoreActionsButton
                    onClick={() => setMenuOpen(true)}
                    pressed={menuOpen}
                    aria-label="Show menu"
                    dataAutomation="worker-gallery-solution-options-3-dot-button"
                    horizontal
                />
            </Menu>

            <MarketplaceItemCreateEditModal
                open={solutionTemplateModalOpen}
                onClose={() => setSolutionTemplateModalOpen(false)}
                projectId={workflowFolder.project.id}
                blueprintSolutionType={blueprintSolutionType}
                marketplaceItemType={MarketplaceItemType.SOLUTION}
                entityIdToCopy={workflowFolder.id}
                workflowFolder={workflowFolder}
                additionalSections={additionalSections}
            />

            <ExportWorkflowFolderVersionHistoryModal
                open={exportWorkflowFolderHistoryOpen}
                onClose={() => setExportWorkflowFolderHistoryOpen(false)}
                workflowFolderId={workflowFolder.id}
                defaultFileName={workflowFolder.displayName}
            />

            <OpenManageMakersModal
                workflowFolder={workflowFolder}
                open={makersModalOpen}
                onClose={() => setMakersModalOpen(false)}
            />

            <SolutionAccessModal
                workflowFolder={workflowFolder}
                open={accessModalOpen}
                onClose={() => setAccessModalOpen(false)}
            />

            {!isSolutionsReportsListOn && (
                <BusinessReportsAccessibilityModal
                    workflowFolder={workflowFolder}
                    open={businessReportsModalOpen}
                    onClose={() => setBusinessReportsModalOpen(false)}
                />
            )}

            <OpenManagePublishersModal
                workflowFolder={workflowFolder}
                open={publishersModalOpen}
                onClose={() => setPublishersModalOpen(false)}
            />
        </Wrapper>
    );
};

export default WorkerGallerySolutionOptions;
