<div class="tnk-twiliosms-custom-action-definition margin-left margin-top padding-right-xs">
    <div ng-if="data.definition.customActionKey === 'SEND_SMS'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <!-- From (Phone Number) -->
            <div class="margin-right margin-top-xs">From (Phone Number):</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter From Phone Number"
                saved-expression="data.definition.fromPhoneNumberExpression"
                on-tonkean-expression-changed="onTonkeanFromPhoneNumberExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.toNumberError" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.toNumberError }}
            </div>
        </div>

        <div class="margin-bottom">
            <!-- To (Phone Number) -->
            <div class="margin-right margin-top-xs">To (Phone Number):</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter From Phone Number"
                saved-expression="data.definition.toPhoneNumberExpression"
                on-tonkean-expression-changed="onTonkeanToPhoneNumberExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.phoneNumberError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.phoneNumberError }}
            </div>
        </div>

        <div class="margin-bottom">
            <!-- SMS Message Body -->
            <div class="margin-right margin-top-xs">Message Body:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter From Phone Number"
                saved-expression="data.definition.messageBodyExpression"
                on-tonkean-expression-changed="onTonkeanMessageBodyExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.messageBodyError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.messageBodyError }}
            </div>
        </div>
    </div>
</div>
