import { useMemo } from 'react';

import type { FieldDefinition } from '@tonkean/tonkean-entities';

function useWidgetConfigurationDeletedFields(
    allFieldsDefinitions: FieldDefinition[],
    configuredFields: string[],
    specialFieldKeysToExclude?: string[],
): string[] {
    const fieldsExistenceMap = useMemo(
        () => Object.fromEntries(allFieldsDefinitions.map((definition) => [definition.id, true])),
        [allFieldsDefinitions],
    );
    const deletedFieldsIds: string[] = useMemo(
        () => configuredFields.filter((id) => !fieldsExistenceMap[id] && !specialFieldKeysToExclude?.includes(id)),
        [configuredFields, fieldsExistenceMap, specialFieldKeysToExclude],
    );

    return deletedFieldsIds;
}

export default useWidgetConfigurationDeletedFields;
