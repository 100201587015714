import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import AddDataSourceConnectionButton from './AddDataSourceConnectionButton';
import DataSourceConnectionsList from './DataSourceConnectionsList';
import OtherDataSourceConnectionsList from './OtherDataSourceConnectionsList';
import OtherDataSourceConnectionsPermissions from './OtherDataSourceConnectionsPermissions';
import BoardAdmins from '../../../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/BoardAdmins';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { SearchBox } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { ConnectionPermission, IntegrationType } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StyledSearchBox = styled(SearchBox)`
    width: 550px;
`;

const SearchAndAddButtonWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
`;

const Header = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.XXXXLARGE_24};
    font-family: 'Lato', 'Roboto', serif;
    margin-top: 28px;
    margin-bottom: 35px;
`;

const Wrapper = styled.div`
    max-width: 1150px;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 60px;

    ${childrenStyledFocus}
`;

const StyledOtherDataSourceConnectionsPermissions = styled(OtherDataSourceConnectionsPermissions)`
    margin-top: 40px;
`;

const StyledOtherDataSourceConnectionsList = styled(OtherDataSourceConnectionsList)`
    margin-top: 16px;
`;

const DataSourceConnections: React.FC = () => {
    const project = useProject();
    const integrations = useAngularService('integrations');
    const [searchValue, setSearchValue] = useState('');

    const [authorizedPeopleIds, setAuthorizedPeopleIds] = useState<string[]>(
        project.projectIntegrationConnectionPermissionConfiguration?.authorizedPeopleIds || [],
    );
    const [connectionPermissionType, setConnectionPermissionType] = useState<ConnectionPermission | undefined>(
        project.projectIntegrationConnectionPermissionConfiguration?.nonSharedCredentialsConnectionPermission,
    );

    const {
        data: dataSourceConnections,
        error: errorDataSourceConnections,
        loading: isLoadingDataSourceConnections,
    } = useTonkeanService('getDataSourceConnections', project.id);

    // Get all integration types that have a data source connection
    const existingDataSourceConnectionTypes = useMemo(() => {
        return (
            dataSourceConnections?.entities.map(
                (dataSourceConnection) => IntegrationType[dataSourceConnection.dataSourceType],
            ) || []
        );
    }, [dataSourceConnections]);

    // Get all integration types that don't have a data source connection
    const nonExistingIntegrations = useMemo(() => {
        return integrations
            .getIntegrationsConfigList()
            .filter((integration) => !existingDataSourceConnectionTypes?.includes(integration.name));
    }, [integrations, existingDataSourceConnectionTypes]);

    const onPermissionsChange = (changedConnectionPermissionType, changedAuthorizedPeopleIds) => {
        setConnectionPermissionType(changedConnectionPermissionType);
        setAuthorizedPeopleIds(changedAuthorizedPeopleIds);

        // Update the cache
        project.projectIntegrationConnectionPermissionConfiguration = {
            nonSharedCredentialsConnectionPermission: changedConnectionPermissionType,
            authorizedPeopleIds: changedAuthorizedPeopleIds,
        };
    };

    return (
        <Wrapper>
            <Header>Connection Permissions</Header>

            {/* Search and Add a Data Source Connection */}
            <SearchAndAddButtonWrapper>
                <StyledSearchBox
                    value={searchValue}
                    onChange={({ target: { value } }) => setSearchValue(value)}
                    placeholder="Search data source type"
                />
                <AddDataSourceConnectionButton />
            </SearchAndAddButtonWrapper>

            {/* Data Source Connections List */}
            <DataSourceConnectionsList
                dataSourceConnections={dataSourceConnections?.entities || []}
                searchValue={searchValue}
                loading={isLoadingDataSourceConnections}
                fullHeight={nonExistingIntegrations?.length === 0}
            />

            {/* Other Data Sources List */}
            {nonExistingIntegrations?.length > 0 && (
                <>
                    <StyledOtherDataSourceConnectionsPermissions
                        onChange={onPermissionsChange}
                        connectionPermissionsType={connectionPermissionType}
                        authorizedPeopleIds={authorizedPeopleIds}
                    />

                    {connectionPermissionType === ConnectionPermission.SPECIFIC_MEMBERS && <BoardAdmins />}

                    <StyledOtherDataSourceConnectionsList
                        integrations={nonExistingIntegrations}
                        connectionPermissionType={connectionPermissionType}
                        authorizedPeopleIds={authorizedPeopleIds}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default DataSourceConnections;
