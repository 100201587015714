import template from './tnkCommentTextbox.template.html?angularjs';
/**
 * Created by ShaLi on 03/11/2015.
 */

function tnkCommentTextbox() {
    return {
        restrict: 'E',
        scope: {
            placeholder: '@',
            maxlength: '@',
            rows: '@',
            anonymous: '=',
            required: '=',
            postOnEnter: '=',
            entityId: '@',
            allowPartial: '@',
            onPost: '&',
            // Passed to server (if supplied). Indicates the comment is on a the given initiative id.
            // This causes the server to update the last_status_comment, so use this when commenting on statuses.
            updateInitiativeId: '<',
            singleLineMode: '<', // When passed and true, the comment textbox is rendered in a single line and different styling.
            isDisabled: '=', // Whether posting should be disabled or not.
            onTextChanged: '&', // Provides a hook for the caller for when the text is changed in the input. Keep it fast!
            initialText: '@', // An initial text supplied by the caller. It is only taken upon initialization.
        },
        template,
        controller: 'MessageCommentCtrl',
        link(scope, element) {
            element.addClass('tnk-message-textbox');
        },
    };
}

angular.module('tonkean.app').directive('tnkCommentTextbox', tnkCommentTextbox);
