import type { FieldValidationParams } from '../entities';
import { isNotRealRow, ValidationType } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';
import validationTypeToValidationError from '../utils/validationTypeToValidationError';

const getItemsGridErrors = (
    rows: InitiativeRowData[],
    validations: FieldValidation<FieldValidationParams>[],
    minAmountOfItems: number,
): string[] => {
    const notDummyAndEmptyRows = rows.filter((row) => !isNotRealRow(row));

    const hasEmptyRequiredFields = notDummyAndEmptyRows.some((row) =>
        (validations || [])
            .filter((validation) => validation.type === ValidationType.REQUIRED)
            .some((validation) => !validation.isValid(row)),
    );

    const hasInvalidDateRange = rows.some((row) =>
        (validations || [])
            .filter((validation) => validation.type === ValidationType.DATE_RANGE)
            .some((validation) => !validation.isValid(row)),
    );

    const itemsCount = notDummyAndEmptyRows.length;
    const validMinRows = minAmountOfItems ? itemsCount >= minAmountOfItems : true;
    const errors: string[] = [];

    if (!validMinRows) {
        errors.push(`You have not reached the required minimum of ${minAmountOfItems} items`);
    }

    if (hasEmptyRequiredFields) {
        errors.push(validationTypeToValidationError[ValidationType.REQUIRED]);
    }
    if (hasInvalidDateRange) {
        errors.push(validationTypeToValidationError[ValidationType.DATE_RANGE]);
    }

    return errors;
};

export default getItemsGridErrors;
