<div class="tnk-origami-risk-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">

        <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                preview-evaluation-source="data.previewEvaluationSource"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                parameters-values="data.definition.parametersValues"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Domain -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Domain (Optional):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Domain"
                    saved-expression="data.definition.domainExpression"
                    on-tonkean-expression-changed="onDomainTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Record ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Record ID (Optional):
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Only required if using a specific Domain."
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Record ID"
                    saved-expression="data.definition.recordIdExpression"
                    on-tonkean-expression-changed="onRecordIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Folder ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Folder ID (Optional):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Folder ID"
                    saved-expression="data.definition.folderIdExpression"
                    on-tonkean-expression-changed="onFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Description -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Description (Optional):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Description"
                    saved-expression="data.definition.descriptionExpression"
                    on-tonkean-expression-changed="onDescriptionTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
