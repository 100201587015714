import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import { initiativeTitle } from '@tonkean/utils';

function getWorkerRunDisplayTitle(workerRun: WorkerRun | undefined): string {
    if (!workerRun) {
        return '';
    }

    switch (workerRun.workerRunStage) {
        case WorkerRunStage.DATA_SOURCE:
            return (
                workerRun.externalActivity?.title ||
                workerRun.externalActivityTitle ||
                workerRun.externalId ||
                'Deleted item'
            );

        case WorkerRunStage.FLOW_RUN:
        case WorkerRunStage.MODULE_ITEM:
            return initiativeTitle(
                workerRun.initiative?.title || workerRun.initiativeTitle,
                workerRun.testRun,
                workerRun.initiative?.id,
            );

        default:
            return '';
    }
}

export default getWorkerRunDisplayTitle;
