import React, { useCallback, useState } from 'react';

import MatchedItemInterfaceModal from '../MatchedItemInterfaceModal';

import { TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    buttonBackground: string | undefined;
    displayText: string;
    state: string;
    disabled?: boolean;
    disabledTooltip?: string;
    openFormsInModal?: boolean;
    itemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>;
}

const OpenMatchedItemButton: React.FC<Props> = ({
    widgetId,
    initiativeId,
    className,
    projectId,
    buttonBackground,
    displayText,
    state,
    disabled,
    disabledTooltip,
    openFormsInModal,
    itemInterfaceId,
}) => {
    const defaultParams = {
        initiativeId,
        projectId,
        tid: initiativeId,
        itemInterfaceId,
        originWidget: widgetId,
    };

    const [interfaceModalOpen, setInterfaceModalOpen] = useState(false);

    const onButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (openFormsInModal && !event.ctrlKey && !event.metaKey) {
                setInterfaceModalOpen(true);

                //  Preventing default so that the regular open in new tab will not execute.
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        },
        [openFormsInModal],
    );

    return (
        <>
            <Tooltip
                dataAutomation="action-button-tooltip"
                placement="bottom"
                content={disabledTooltip}
                disabled={!disabled}
            >
                <UserThemedClickableButton
                    data-automation="action-button"
                    state={state}
                    params={defaultParams}
                    className={className}
                    background={buttonBackground}
                    disabled={disabled}
                    onClick={onButtonClick}
                    openInNewTab
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {displayText}
                    </TextEllipsis>
                </UserThemedClickableButton>
            </Tooltip>

            {openFormsInModal && (
                <MatchedItemInterfaceModal
                    open={interfaceModalOpen}
                    onClose={() => setInterfaceModalOpen(false)}
                    initiativeId={initiativeId}
                    itemInterfaceId={itemInterfaceId}
                    projectId={projectId}
                    originWidgetId={widgetId}
                />
            )}
        </>
    );
};

export default OpenMatchedItemButton;
