import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentVariablesValuesFromMarketplaceItemModal from '../../../../components/modals/enterpriseComponentVariablesValuesModal/EnterpriseComponentVariablesValuesFromMarketplaceItemModal';

import { TextEllipsis } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { MarketplaceItemSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const Item = styled(Clickable)`
    display: inline-flex;
    font-size: 9.5pt;
    color: #333333;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 6px 10px 6px 6px;
    margin-bottom: 5px;
    margin-right: 5px;
    align-items: center;
    height: 37.5px;

    &:hover {
        color: #333333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
`;

const StyledProjectIntegrationIcon = styled(ProjectIntegrationIcon)`
    margin-right: 9px;
`;

interface Props {
    marketplaceItemSummary: MarketplaceItemSummary;
    onChange?: (
        oldIdsToCreatedIdsMap: Record<string, string>,
        createdProjectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ) => void;
    onCancel?: () => void;
    initialTitle?: string;
}

const MarketplaceDataSourceTile: React.FC<Props> = ({ marketplaceItemSummary, onChange, onCancel, initialTitle }) => {
    const { title: marketplaceItemTitle, templateName, iconUrl } = marketplaceItemSummary;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [finalTitle, setFinalTitle] = useState(initialTitle ?? marketplaceItemTitle);

    const onModalClose = useCallback(
        (
            oldIdsToCreatedIdsMap: Record<string, string>,
            createdProjectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        ) => {
            setIsModalOpen(false);
            onChange?.(oldIdsToCreatedIdsMap, createdProjectIntegrationId);
            setFinalTitle(marketplaceItemTitle);
        },
        [onChange, marketplaceItemTitle],
    );

    const onModalCancel = () => {
        onCancel?.();
        setIsModalOpen(false);
    };

    const onClick = useCallback(() => {
        setIsModalOpen(true);
    }, [setIsModalOpen]);

    return (
        <>
            <Item onClick={onClick}>
                {iconUrl && <StyledProjectIntegrationIcon iconUrl={iconUrl} />}
                <TextEllipsis>{finalTitle}</TextEllipsis>
            </Item>

            <EnterpriseComponentVariablesValuesFromMarketplaceItemModal
                iconUrl={iconUrl || ''}
                isOpen={isModalOpen}
                onCancel={onModalCancel}
                onSave={onModalClose}
                templateName={templateName}
            />
        </>
    );
};

export default MarketplaceDataSourceTile;
