import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import isValidWorkflowDataStorageConfiguration from '../sharedConfigComponents/validators/isValidWorkflowDataStorageConfiguration';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const HttpUploadLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.HTTP_UPLOAD,
    title: 'HTTP Upload',
    iconClass: 'mod-http-upload',
    description: 'Call an HTTP endpoint to upload a file.',
    getLinkedFieldDefinitionsConfiguration: () => {
        return [
            {
                field: 'RESPONSE_HEADERS_DATA_CONTAINER',
                friendlyName: 'Response Headers',
            },
            {
                field: 'RESPONSE_BODY_DATA_CONTAINER',
                friendlyName: 'Response Body',
            },
            {
                field: 'RESPONSE_STATUS_CODE_DATA_CONTAINER',
                friendlyName: 'Response Status Code',
            },
        ];
    },
    validator({ definition, group, syncConfigCacheManager, workflowVersionInfoRetriever, projectManager }) {
        const validationObject: LogicBlockValidationObject = {};

        const validUrlSource = definition.source?.type === 'URL' && definition.source?.url?.evaluatedExpression;
        const validUrlSourceUsingNewConfiguration =
            definition.source?.fileSourceType === 'URL' && definition.source?.url?.evaluatedExpression;
        const validDataStorageInput =
            definition.source?.fileSourceType === 'DATA_STORAGE' && definition.source?.extendedMatchConfiguration;
        const validStaticAssetSource =
            definition.source?.fileSourceType === 'UPLOAD_FILE' &&
            definition.source?.workerStaticAssetId &&
            definition.source?.workerStaticAssetDisplayName;
        const validIntegrationSource =
            definition.source?.type === 'Integration' &&
            definition.source?.storageIntegrationId &&
            definition.source?.fileId?.evaluatedExpression;

        if (
            !validUrlSource &&
            !validUrlSourceUsingNewConfiguration &&
            !validIntegrationSource &&
            !validStaticAssetSource &&
            !validDataStorageInput
        ) {
            validationObject.noSource = 'Must fill source.';
        }

        if (!definition.url?.evaluatedExpression) {
            validationObject.noURL = 'Must enter upload URL.';
        }

        if (
            !isValidWorkflowDataStorageConfiguration(
                definition.source,
                group,
                workflowVersionInfoRetriever,
                syncConfigCacheManager,
                projectManager,
            )
        ) {
            validationObject.invalidFileChoice =
                'Data storage that operates on a different type than the intake Source can’t be matched to the Root monitored item.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default HttpUploadLogicBlockConfig;
