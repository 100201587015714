import { useCallback, useEffect, useRef } from 'react';

import { useDebouncer } from '@tonkean/infrastructure';
import type { FormManager } from '@tonkean/shared-services';
import type { Form } from '@tonkean/tonkean-entities';
import { FormType } from '@tonkean/tonkean-entities';

interface Params {
    formId: string | undefined;
    form: Form | undefined;
    updateWorkerForm: FormManager['updateWorkerForm'];
    groupId: string;
    createWorkerForm: FormManager['createWorkerForm'];
}

function useSaveForm({ formId, form, updateWorkerForm, groupId, createWorkerForm }: Params) {
    /* isInitialLoad is using a ref so mutating it does not trigger a re-render. */
    const isInitialLoadRef = useRef<boolean>(!!formId);

    useEffect(() => {
        isInitialLoadRef.current = !!formId;
    }, [formId]);

    const saveFormCallback = useCallback(() => {
        /* Loading existing form. */
        if (formId && !form && isInitialLoadRef.current) {
            return;
        }

        /* Form is loaded but the user has not changed anything. */
        if (form && isInitialLoadRef.current) {
            isInitialLoadRef.current = false;
            return;
        }

        if (!form) {
            return;
        }

        if (form.id) {
            updateWorkerForm(
                groupId,
                form.id,
                form.formType,
                form.definition,
                form.disabled,
                form.displayName,
                form.descriptionExpression || {
                    evaluatedExpression: '',
                    originalExpression: '',
                    isStripHtmlDisabled: true,
                },
                form.slackCommand || undefined,
                form.formQuestionType,
                form.collapseDescriptionByDefault,
                form.formType === FormType.CREATE ? form.smartSearchEnabled : undefined,
                form.formType === FormType.CREATE ? form.homepageEnabled : false,
                form.expandableDescriptionExpression || {
                    evaluatedExpression: '',
                    originalExpression: '',
                    isStripHtmlDisabled: true,
                },
                form.expandableDescriptionLabel,
            );
        } else {
            createWorkerForm(
                groupId,
                form.formType,
                form.definition,
                form.disabled,
                form.displayName,
                form.descriptionExpression || {
                    evaluatedExpression: '',
                    originalExpression: '',
                    isStripHtmlDisabled: true,
                },
                form.slackCommand || undefined,
                form.formQuestionType,
                form.collapseDescriptionByDefault,
                form.expandableDescriptionExpression || {
                    evaluatedExpression: '',
                    originalExpression: '',
                    isStripHtmlDisabled: true,
                },
                form.expandableDescriptionLabel,
            );
        }
    }, [createWorkerForm, form, formId, groupId, isInitialLoadRef, updateWorkerForm]);

    useDebouncer(saveFormCallback, 500);
}

export default useSaveForm;
