import React from 'react';
import styled from 'styled-components';

import { Field, SimpleSelect, TagsInput } from '@tonkean/infrastructure';
import { ContainsCriteriaType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledField = styled(Field)`
    margin-bottom: 18px;
`;

const Header = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${Theme.colors.gray_700};
    margin-bottom: 17px;
`;

const InputWrapper = styled.div`
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 2fr;
`;

const options = [
    {
        label: 'Contains',
        value: ContainsCriteriaType.CONTAINS,
    },
    {
        label: 'Not Contains',
        value: ContainsCriteriaType.NOT_CONTAINS,
    },
];

interface Props {
    name: string;
}

const StopCriteria: React.FC<Props> = ({ name }) => {
    return (
        <StyledField>
            <Header>Stop Criteria: If response body</Header>
            <InputWrapper>
                <SimpleSelect name={`${name}.type`} options={options} />
                <TagsInput name={`${name}.keywords`} placeholder="Insert keywords" />
            </InputWrapper>
        </StyledField>
    );
};

export default StopCriteria;
