import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import PermissionTypeInputs from './PermissionTypeInputs';
import BusinessReportShareModalTabs from '../../types/BusinessReportShareModalTabs';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { CopyInput } from '@tonkean/infrastructure';
import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    FormikAutosave,
    Modal,
    ModalBody,
    ModalHeader,
    ModalSize,
    Spacer,
    Tab,
    Tabs,
    useUUID,
    XCloseButton,
} from '@tonkean/infrastructure';
import { ShareIcon } from '@tonkean/svg';
import { SolutionBusinessReportAccessPermission } from '@tonkean/tonkean-entities';
import type { Person, SolutionBusinessReport, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getFieldFromYup, yupEnum } from '@tonkean/utils';

const StyledModal = styled(Modal)`
    overflow: visible;
`;
const StyledModalHeader = styled(ModalHeader)`
    justify-content: space-between;
`;
const StyledModalBody = styled(ModalBody)`
    min-height: 300px;
    overflow: visible;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ReportLink = styled.section`
    background-color: ${Theme.colors.gray_100};
    padding: 16px;
`;
const ReportLinkLabel = styled.label`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    padding: 0;
    margin: 0;
`;
const Pane = styled.section`
    padding: 16px;
`;

const permissionSchema = yup.object({
    accessType: yupEnum(SolutionBusinessReportAccessPermission).required(),
    peopleChosen: yup.array().of(yup.mixed()).required(),
});
const schema = yup.object({
    viewPermissions: permissionSchema,
    editPermissions: permissionSchema,
});
export type ShareBusinessReportFormElements = yup.InferType<typeof schema>;
export const shareBusinessReportFormFields = getFieldFromYup(schema);

interface Props {
    open: boolean;
    onClose(
        viewPermissionType: SolutionBusinessReportAccessPermission,
        viewPermissionPeople: string[],
        editPermissionType: SolutionBusinessReportAccessPermission,
        editPermissionPeople: string[],
    ): void;
    solutionBusinessReport: SolutionBusinessReport;
}

const BusinessReportShareModal: React.FC<Props> = ({ open, onClose, solutionBusinessReport }) => {
    const $state = useAngularService('$state');
    const [projectId] = useGetStateParams<[TonkeanId<TonkeanType.PROJECT>]>('projectId');
    const [tab, setTab] = useState(BusinessReportShareModalTabs.VIEW_PERMISSIONS);
    const [shouldReloadReport, setShouldReloadReport] = useState(false);
    const id = useUUID();
    const toast = useToastMessage();

    const [updateSolutionBusinessReportResponse, updateSolutionBusinessReport] =
        useLazyTonkeanService('updateSolutionBusinessReport');
    const [projectPeopleResponse, getProjectPeopleByIds] = useLazyTonkeanService('getProjectPeopleByIds');

    useEffect(() => {
        const peopleToFetch = [
            ...solutionBusinessReport.permissions.VIEW.peopleIds,
            ...solutionBusinessReport.permissions.EDIT.peopleIds,
        ];
        getProjectPeopleByIds(projectId, peopleToFetch, 0, peopleToFetch.length);
    }, [
        getProjectPeopleByIds,
        projectId,
        solutionBusinessReport.permissions.EDIT.peopleIds,
        solutionBusinessReport.permissions.VIEW.peopleIds,
    ]);

    useEffect(() => {
        if (updateSolutionBusinessReportResponse.error) {
            toast('Failed to save share preferences. Please try again', 'danger');
        }
    }, [updateSolutionBusinessReportResponse.error, toast]);

    useEffect(() => {
        if (updateSolutionBusinessReportResponse.called) {
            setShouldReloadReport(true);
        }
    }, [updateSolutionBusinessReportResponse.called]);

    const initialValues: ShareBusinessReportFormElements = useMemo(() => {
        const viewPeople: Person[] = [];
        const editPeople: Person[] = [];

        projectPeopleResponse.data?.entities?.forEach((person) => {
            if (solutionBusinessReport.permissions.VIEW.peopleIds.includes(person.id)) {
                viewPeople.push(person);
            }
            if (solutionBusinessReport.permissions.EDIT.peopleIds.includes(person.id)) {
                editPeople.push(person);
            }
        });

        return {
            viewPermissions: {
                accessType: solutionBusinessReport.permissions.VIEW.permission,
                peopleChosen: viewPeople,
            },
            editPermissions: {
                accessType: solutionBusinessReport.permissions.EDIT.permission,
                peopleChosen: editPeople,
            },
        };
    }, [
        projectPeopleResponse.data?.entities,
        solutionBusinessReport.permissions.EDIT.peopleIds,
        solutionBusinessReport.permissions.EDIT.permission,
        solutionBusinessReport.permissions.VIEW.peopleIds,
        solutionBusinessReport.permissions.VIEW.permission,
    ]);

    const shareReportURL = useMemo(() => {
        return new URL(
            $state.href('product.processContributorSolutionBusinessReport', {
                solutionBusinessReportId: solutionBusinessReport.id,
            }),
            window.location.origin,
        ).href;
    }, [$state, solutionBusinessReport.id]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                return updateSolutionBusinessReport(solutionBusinessReport.id, {
                    permissions: {
                        viewPermissionType: values.viewPermissions.accessType,
                        viewPermissionPeople: values.viewPermissions.peopleChosen.map((person: Person) => person.id),
                        editPermissionType: values.editPermissions.accessType,
                        editPermissionPeople: values.editPermissions.peopleChosen.map((person: Person) => person.id),
                    },
                });
            }}
            validationSchema={schema}
            enableReinitialize
        >
            {({ values }) => (
                <StyledModal
                    open={open}
                    onClose={() => {
                        onClose(
                            values.viewPermissions.accessType,
                            values.viewPermissions.peopleChosen.map((person) => (person as unknown as Person).id),
                            values.editPermissions.accessType,
                            values.editPermissions.peopleChosen.map((person) => (person as unknown as Person).id),
                        );
                    }}
                    size={ModalSize.SMEDIUM}
                    fixedWidth
                >
                    <StyledModalHeader $flex>
                        <TitleWrapper>
                            <ShareIcon />
                            <Spacer width={10} />
                            Share Report
                        </TitleWrapper>
                        <XCloseButton size={ButtonSize.MEDIUM} />
                    </StyledModalHeader>
                    <StyledModalBody removePadding>
                        <ReportLink>
                            <ReportLinkLabel htmlFor={id}>Report Link</ReportLinkLabel>
                            <Spacer height={8} />
                            <CopyInput value={shareReportURL} id={id} />
                        </ReportLink>
                        <Form>
                            <FormikAutosave />
                            <Tabs value={tab} onChange={setTab}>
                                <Tab label={BusinessReportShareModalTabs.VIEW_PERMISSIONS}>
                                    {BusinessReportShareModalTabs.VIEW_PERMISSIONS}
                                </Tab>
                                <Tab label={BusinessReportShareModalTabs.EDIT_PERMISSIONS}>
                                    {BusinessReportShareModalTabs.EDIT_PERMISSIONS}
                                </Tab>
                            </Tabs>
                            <AnimatedSwitch activeLabel={tab}>
                                <AnimatedSwitchItem label={BusinessReportShareModalTabs.VIEW_PERMISSIONS}>
                                    <Pane>
                                        <PermissionTypeInputs
                                            permissionTab={BusinessReportShareModalTabs.VIEW_PERMISSIONS}
                                        />
                                    </Pane>
                                </AnimatedSwitchItem>
                                <AnimatedSwitchItem label={BusinessReportShareModalTabs.EDIT_PERMISSIONS}>
                                    <Pane>
                                        <PermissionTypeInputs
                                            permissionTab={BusinessReportShareModalTabs.EDIT_PERMISSIONS}
                                        />
                                    </Pane>
                                </AnimatedSwitchItem>
                            </AnimatedSwitch>
                        </Form>
                    </StyledModalBody>
                </StyledModal>
            )}
        </Formik>
    );
};

export default BusinessReportShareModal;
