import { useContext } from 'react';

import InterfaceSubmitValidationContext, {
    useBuildInterfaceSubmitValidationContext,
} from '../../utils/InterfaceSubmitValidationContext';

import type { ValidationState } from '@tonkean/tonkean-entities';

const getSubmitValidationContext = (
    inheritValidationContext: boolean | undefined,
    onValidationChangedCallback?: (
        validation: ValidationState,
        setForceShownValidation?: (value: boolean) => void,
    ) => void,
) => {
    return inheritValidationContext
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useContext(InterfaceSubmitValidationContext)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          useBuildInterfaceSubmitValidationContext(onValidationChangedCallback);
};

export default getSubmitValidationContext;
