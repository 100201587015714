/* jshint ignore:start */

import React from 'react';
import DefaultInboxReason from './DefaultInboxReason';

export default class ExternalChangedInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;
        const reasonMetadata = item.reasonMetadata;
        if (!reasonMetadata || !reasonMetadata.externalSourceName || !reasonMetadata.externalStatus) {
            return null;
        }

        return (
            <div>
                The status of {this.renderTitleLinkElement(initiative)} was changed in{' '}
                {reasonMetadata.externalSourceName} to {reasonMetadata.externalStatus}
            </div>
        );
    }
}
/* jshint ignore:end */
