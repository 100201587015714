export enum PardotAccountType {
    SANDBOX = 'test',
    PRODUCTION = 'login',
}

export const accountTypeToDisplayName: Record<PardotAccountType, string> = {
    [PardotAccountType.SANDBOX]: 'Sandbox',
    [PardotAccountType.PRODUCTION]: 'Production',
};
export default PardotAccountType;
