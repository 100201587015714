import template from './tknDomainsInput.template.html?angularjs';

/**
 * A tags input component to handle input + validation of domain strings.
 */
export default angular.module('tonkean.app').component('tnkDomainsInput', {
    bindings: {
        updateDomains: '<',
        domains: '<',
        placeholder: '@',
    },
    template,
    controller: 'DomainsInputCtrl',
});
