<div class="copy-to-list-modal">
    <div ng-if="loading" class="text-center">
        <span class="loading-large margin-right-xlg"></span>
    </div>
    <div ng-if="!loading" class="margin-top-screen-xs">
        <!-- Modal title -->
        <div class="common-title-lg margin-bottom-md margin-top-lg">
            Copy "{{ data.customTrigger.node.displayName }}" trigger
        </div>

        <!-- Sub title -->
        <div class="flex-vmiddle-xs-block">
            <div class="margin-right margin-bottom-xs">
                Select the module you'd like to copy
                <strong>{{ data.customTrigger.node.displayName }}</strong>
                to
            </div>

            <!-- Copy into group selection -->
            <ui-select class="common-width-40-no-xs margin-bottom-xs" theme="bootstrap" ng-model="data.copyIntoGroup">
                <ui-select-match placeholder="Search worker...">
                    <span class="common-ellipsis">{{ data.copyIntoGroup.name }}</span>
                </ui-select-match>
                <ui-select-choices repeat="p in data.allAvailableGroups | filter:{name: $select.search}">
                    <div ng-bind-html="p.name | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div class="common-text-color-light-grey common-size-xxxxs">
            <div class="flex margin-top-xs margin-left-xs">
                <div class="margin-right-xs">*</div>
                <div>
                    Please note that the intake source on target Module may be different from current Module input
                    source.
                </div>
            </div>
            <div class="flex margin-top-xs margin-left-xs">
                <div class="margin-right-xs">*</div>
                <div>The trigger will be copied under the root trigger.</div>
            </div>
            <div class="flex margin-top-xs" ng-if="data.customTriggersToCopy && data.customTriggersToCopy.length > 0">
                <span class="svg-icon-xs margin-right-xs">
                    <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                </span>
                <div>Triggers that will be copied are: {{ data.customTriggersToCopy.join(', ') }}</div>
            </div>
            <div
                class="flex margin-top-xs"
                ng-if="data.fieldDefinitionsToCopy && data.fieldDefinitionsToCopy.length > 0"
            >
                <span class="svg-icon-xs margin-right-xs">
                    <tnk-icon src="/images/icons/columns.svg"></tnk-icon>
                </span>
                <div>Fields that will be copied are: {{ data.fieldDefinitionsToCopy.join(', ') }}</div>
            </div>
            <div class="flex margin-top-xs" ng-if="data.statesToCopy && data.statesToCopy.length > 0">
                <span class="svg-icon-xs margin-right-xs">
                    <tnk-icon src="/images/icons/text-input-lg.svg"></tnk-icon>
                </span>
                <div>States that will be copied are: {{ data.statesToCopy.join(', ') }}</div>
            </div>
            <div class="flex margin-top-xs" ng-if="data.formsToCopy && data.formsToCopy.length > 0">
                <span class="svg-icon-xs margin-right-xs common-color-light-grey">
                    <tnk-icon src="/images/icons/form.svg"></tnk-icon>
                </span>
                <div>Forms that will be copied are: {{ data.formsToCopy.join(', ') }}</div>
            </div>
            <div
                class="flex margin-top-xs"
                ng-if="data.projectIntegrationsToCopy && data.projectIntegrationsToCopy.length > 0"
            >
                <span class="svg-icon-xs margin-right-xs">
                    <tnk-icon src="/images/icons/plug-o.svg"></tnk-icon>
                </span>
                <div>Integrations that will be used are: {{ data.projectIntegrationsToCopy.join(', ') }}</div>
            </div>
        </div>

        <!-- Error -->
        <div class="common-size-xxs common-color-danger margin-top" ng-if="data.error">{{ data.error }}</div>

        <!-- Copy & Cancel buttons -->
        <div class="margin-top-lg flex-vmiddle mod-justify-end">
            <span ng-if="data.savingLoading" class="loading-small"></span>

            <!-- Copy button -->
            <button
                type="submit"
                ng-disabled="data.savingLoading"
                class="btn btn-primary margin-left-xs"
                ng-click="copy()"
            >
                Copy
            </button>

            <!-- Cancel button -->
            <button type="button" class="btn btn-default margin-left-xs" ng-click="close()">Cancel</button>
        </div>
    </div>
</div>
