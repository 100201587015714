import ItemInterfaceQuestionWidget from './ItemInterfaceQuestionWidget';
import QuestionWidgetConfigurationEditor from './QuestionWidgetConfigurationEditor';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const QuestionWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: ItemInterfaceQuestionWidget,
    configurationComponent: QuestionWidgetConfigurationEditor,
};

export default QuestionWidgetPackage;
