export enum WsdlOption {
    FILE = 'FILE',
    URL = 'URL',
}
export interface FileWsdl {
    type: WsdlOption.FILE;
    wsdl: Blob;
    operations: string[];
}

export interface UrlWsdl {
    type: WsdlOption.URL;
    wsdl: string;
    operations: string[];
}

type WsdlWithOperations = FileWsdl | UrlWsdl;

export default WsdlWithOperations;
