import { VIEW_TYPES } from '@tonkean/constants';

function AdvancedSyncCtrl($scope, integrations, $timeout) {
    $scope.data.advancedSyncDisplayed = false;
    $scope.viewType = VIEW_TYPES.custom;
    $scope.loadPreviewFlag = false;

    /**
     * Occurs when the advanced sync element part is first loaded in the app.
     * Initializes the sync control and the sync definition.
     */
    $scope.initAdvancedSync = function () {
        if (!$scope.valueResults['SYNC_CONTROL']) {
            $scope.valueResults['SYNC_CONTROL'] = {
                customDefinedView: true,
            };

            $scope.valueResults['PREVIEW_SYNC_CONTROL'] = {};

            const compiledViewData = $scope.calculateDefinition($scope.data.selectedList.sync.viewData);

            const integrationName =
                $scope.integrationsResults[
                    $scope.data.selectedList.sync.integrationType
                ].integration.integrationType.toUpperCase();
            // Filter out special fields that are not supported by the integration from the sync definition and open the advanced section
            // only if there were filters filtered from the definition.
            for (let i = 0; i < compiledViewData.query.filters.length; i++) {
                const filter = compiledViewData.query.filters[i];

                if (
                    !$scope.data.integrationEntitiesAndFieldsConfiguration[integrationName] ||
                    !$scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity] ||
                    !$scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
                        .fields ||
                    !$scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
                        .fields[filter.fieldName]
                ) {
                    compiledViewData.query.filters.splice(i, 1);
                    $scope.data.advancedSyncDisplayed = true;
                }
            }
        }
        //
        if (!$scope.valueResults['SYNC_VALIDITY']) {
            $scope.valueResults['SYNC_VALIDITY'] = {};
        }
    };

    /**
     * Initializes the sync entity of a sync built in list.
     */
    $scope.initializeSyncEntity = function (integrationType, selectedEntityIndex) {
        $scope.valueResults['ALL_ENTITIES_SUPPORTED'] =
            integrations.getAllEntitiesSupportedIntegrations()[
                $scope.integrationsResults[integrationType].integration.integrationType.toLowerCase()
            ];
        $scope.syncSettings =
            integrations.getSyncSettings()[
                $scope.integrationsResults[integrationType].integration.integrationType.toLowerCase()
            ];

        if (!$scope.valueResults['SYNC_ENTITY'] && $scope.syncSettings.supportedEntities) {
            $scope.valueResults['SYNC_ENTITY'] = $scope.syncSettings.supportedEntities[selectedEntityIndex];
        }

        $scope.valueResults['SYNC_CONSTS'] = $scope.syncSettings;
        $scope.valueResults['SYNC_ENTITY_TYPE'] = $scope.valueResults['SYNC_ENTITY'];
    };

    /**
     * Occurs when the user selects an entity from the entity selector.
     */
    $scope.selectEntityOption = function (selectedEntity) {
        $scope.valueResults['SYNC_ENTITY'] = selectedEntity.pluralLabel;
        $scope.valueResults['SYNC_ENTITY_METADATA'] = selectedEntity;

        // Resetting the columns so the newly selected entity type can take place.
        // angular.copy is important here. tnkSetupColumns changes the data.columns array,
        // so if we were to pass configuraitonColumns without angular.copy,
        // it would change the data, and we won't have something to recover from.
        $scope.data.columns = angular.copy($scope.data.configurationColumns);
    };

    /**
     * Occurs when the sync preview control edit button is clicked.
     */
    $scope.syncPreviewEditClicked = function () {
        // If the user clicked the edit criteria, we should open the advanced edit tab even if he didn't previously open it.
        $scope.data.advancedSyncDisplayed = true;
        $scope.previousStep();
    };

    /**
     * Updates the advanced sync object when the user changes the date range.
     */
    $scope.updateSyncData = function (keepEmptyValuedFilters) {
        // If we do not have sync, view data, sync control, or project integration configured, we shouldn't update anything.
        if (
            !$scope.data.selectedList.sync ||
            !$scope.data.selectedList.sync.viewData ||
            !$scope.valueResults['SYNC_CONTROL'] ||
            !$scope.integrationsResults[$scope.data.selectedList.sync.integrationType]
        ) {
            return;
        }

        const viewData = $scope.calculateDefinition(
            $scope.valueResults['SYNC_CONTROL'].createDefinitionFromCustomFilters(keepEmptyValuedFilters),
        );

        // Initializing a map that will hold all the values that take place in the compilation process, and their values.
        const compiledFromMap = {};

        for (let j = 0; j < viewData.query.filters.length; j++) {
            const compiledFilter = viewData.query.filters[j];

            if (compiledFilter.compiledFrom) {
                compiledFromMap[compiledFilter.compiledFrom] = $scope.valueResults[compiledFilter.compiledFrom];
            }
        }

        // Updates all the filters that have compiledFrom with the most recent value from the $scope.valueResults.
        if (
            $scope.valueResults['SYNC_CONTROL'].customFiltersDefinition &&
            $scope.valueResults['SYNC_CONTROL'].customFiltersDefinition.query &&
            $scope.valueResults['SYNC_CONTROL'].customFiltersDefinition.query.filters
        ) {
            for (let i = 0; i < $scope.valueResults['SYNC_CONTROL'].customFiltersDefinition.query.filters.length; i++) {
                const filter = $scope.valueResults['SYNC_CONTROL'].customFiltersDefinition.query.filters[i];

                if (filter.compiledFrom && compiledFromMap[filter.compiledFrom]) {
                    filter.value = compiledFromMap[filter.compiledFrom];
                }
            }
        }

        $scope.loadPreviewFlag = false;
        $timeout(function () {
            $scope.loadPreviewFlag = true;
        });
    };

    $scope.calculatePluralEntityName = function (compiledViewData) {
        const integrationName =
            $scope.integrationsResults[
                $scope.data.selectedList.sync.integrationType
            ].integration.integrationType.toUpperCase();

        // Determining the plural entity name
        let pluralEntityName;
        if (
            $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName] &&
            $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
        ) {
            if (
                $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
                    .isSpecialField
            ) {
                pluralEntityName =
                    $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
                        .underlyingPluralName;
            } else {
                const underlyingSpecialFieldName =
                    $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][compiledViewData.entity]
                        .underlyingName;
                pluralEntityName =
                    $scope.data.integrationEntitiesAndFieldsConfiguration[integrationName][underlyingSpecialFieldName]
                        .underlyingPluralName;
            }
        } else if ($scope.valueResults['SYNC_ENTITY_METADATA']) {
            pluralEntityName = $scope.valueResults['SYNC_ENTITY_METADATA'].pluralLabel;
        }

        return pluralEntityName;
    };

    /**
     * Occurs when people is added in the people selector of the single rep list (this is a type-sepcific function,
     * because for now we are not having a separate controller for each type of list).
     */
    $scope.onPeopleTagAdded = function (keepEmpyValuedFilters) {
        if ($scope.data.selectedPeople && $scope.data.selectedPeople.length) {
            $scope.valueResults['REP_OBJECT'] = $scope.data.selectedPeople[0];
        }

        $scope.prepareData();
        $scope.updateSyncData(keepEmpyValuedFilters);
    };

    /**
     * Occurs when people is removed from the people selector of the single rep list (this is a type-sepcific function,
     * because for now we are not having a separate controller for each type of list).
     */
    $scope.onPeopleTagRemoved = function (keepEmpyValuedFilters) {
        $scope.valueResults['REP_OBJECT'] = null;
        $scope.updateSyncData(keepEmpyValuedFilters);
    };

    $scope.calculateDefinition = function (viewData) {
        const compiledViewData = compileViewData(viewData);
        $scope.valueResults['SYNC_DEFINITION'] = compiledViewData;
        return compiledViewData;
    };

    $scope.data.onPreviousStep = function (stepKey) {
        if (stepKey === 'preview') {
            $scope.calculateDefinition($scope.valueResults['PREVIEW_SYNC_CONTROL'].createDefinitionFromCustomFilters());
        }
    };

    /**
     * Compiles the view data and adds the integration type property as well.
     */
    function compileViewData(viewData) {
        const compiledViewData = $scope.compileValues(viewData);
        // Adding integration type to the existing definition as it is required for the control
        compiledViewData.integrationType =
            $scope.integrationsResults[$scope.data.selectedList.sync.integrationType].integration.integrationType;

        // If we do not have a query set, that means the query is defined only by the user.
        // In such case, it is best for us to define a default match-all query to be used by all components.
        if (!compiledViewData.query) {
            compiledViewData.query = {
                type: 'And',
                filters: [],
                queries: [],
            };
        }
        return compiledViewData;
    }
}

export default angular.module('tonkean.app').controller('AdvancedSyncCtrl', AdvancedSyncCtrl);
