// Custom tab for tonkean expression with the body headers and status.
import { useMemo } from 'react';

import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import type { BaseActionParameter } from '@tonkean/tonkean-entities';
import { ActionDefinitionType } from '@tonkean/tonkean-entities';

const useCustomRequestAdditionalTab = (definitionType: ActionDefinitionType, extraFieldsIds: BaseActionParameter[]) => {
    return useMemo<TonkeanExpressionAdditionalTab>(() => {
        return {
            id: 'RESPONSE_PARAMS',
            label: 'Response Params',
            iconClass: 'mod-handle-response',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                // The fields ids are taken for the server so if we need to change remember to change from the server.
                const fieldsIds = [
                    'body',
                    ...(definitionType === ActionDefinitionType.HTTP ? ['headers', 'status'] : []),
                ];
                const constFields = fieldsIds.map((fieldId) => ({
                    name: fieldId,
                    value: fieldId,
                    label: fieldId.charAt(0).toUpperCase() + fieldId.slice(1),
                    id: fieldId,
                }));

                const extraFields = extraFieldsIds.map((field) => ({
                    name: field.id,
                    value: field.id,
                    label: field.displayName,
                    id: field.id,
                }));
                return Promise.resolve([...constFields, ...extraFields]);
            },
        };
    }, [definitionType, extraFieldsIds]);
};

export default useCustomRequestAdditionalTab;
