import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab, OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class WrapHtmlFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.WRAP_HTML;
    override readonly sign = 'WrapHtml';
    override readonly displayName = 'Wrap HTML';
    override readonly description = 'Will wrap the given expression with the given HTML elements.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.VARIABLE,
        },
        {
            displayName: 'HTML element',
            dataType: FieldType.String,
            defaultTab: FormulaPopoverTab.VARIABLE,
            isHTMLTag: true,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'HTML element attributes',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 'style="color:black"',
            defaultTab: FormulaPopoverTab.VARIABLE,
        },
        {
            displayName: 'Self Closing Tag',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
            defaultValue: true,
        },
        {
            displayName: 'Wrap with quotes',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
            defaultValue: false,
        },
    ];
}
