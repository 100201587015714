import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MailchimpTransactionalCustomActionsDefinitionCtrl($scope, customTriggerManager, modal) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.definition && !$scope.data.definition.content) {
            $scope.data.definition.content = {
                evaluatedExpression: '',
                originalExpression: '',
            };
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onTemplateNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFromEmailExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fromEmailExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFromNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fromNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSubjectExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.subjectExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientsConfigurationChanged = function (configuration, shouldSaveLogic) {
        $scope.data.definition.recipientsConfiguration = configuration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCCConfigurationChanged = function (configuration, shouldSaveLogic) {
        $scope.data.definition.ccConfiguration = configuration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onBCCConfigurationChanged = function (configuration, shouldSaveLogic) {
        $scope.data.definition.bccConfiguration = configuration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPlaceholdersChanged = function (placeholderReplacers) {
        $scope.data.definition.placeholderReplacers = placeholderReplacers;
        $scope.onDefinitionChanged(true);
    };

    $scope.onContentTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.content = {
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled: false,
        };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.openHtmlEditor = function () {
        let exampleInitiative = null;
        if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            exampleInitiative =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.configuredLogic.node.id
                ];
        }

        modal
            .openHtmlEditor(
                $scope.data.groupId,
                $scope.data.definition.content.originalExpression,
                exampleInitiative,
                false,
                $scope.data.configuredLogic.node.id,
                $scope.data.workflowVersionId,
            )
            .then((result) => {
                $scope.onContentTonkeanExpressionChanged(result.html, result.evaluatedHtml, true);
            });
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'MailchimpTransactionalCustomActionsDefinitionCtrl',
        lateConstructController(MailchimpTransactionalCustomActionsDefinitionCtrl),
    );
