import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useLazyAsyncMethod, useAsyncMethod } from '@tonkean/angular-hooks';
import {
    Checkbox,
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    Placeholder,
} from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const NoteSection = styled.div`
    font-style: normal;
    font-weight: 700;

    font-family: 'Roboto';

    color: #f0771e;

    padding-top: 8px;
    padding-bottom: 8px;
    gap: 10px;
`;

interface Props {
    group: Group;
    environment: Environment;

    onConfirm(): void;

    onCancel(): void;
}

const EnvironmentActivateConfirmationModal: React.FC<Props> = ({ group, onConfirm, onCancel, environment }) => {
    const groupManager = useAngularService('groupManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const [{ data: outdated, loading: loadingOutdated }, getGroupOutdated] = useLazyAsyncMethod(
        groupManager,
        'getGroupOutdated',
    );

    useEffect(() => {
        getGroupOutdated(
            group.id,
            environment === 'production' ? WorkflowVersionType.PUBLISHED : WorkflowVersionType.DRAFT,
        );
    }, [environment, getGroupOutdated, group.id]);

    const isArchiveRequired = useMemo(() => {
        return outdated?.isOutdated && outdated?.doesGroupHasInitiatives;
    }, [outdated]);

    const projectManager = useAngularService('projectManager');
    const maxAllowedModulesPerSolution: number | undefined = projectManager.projectData?.maxAllowedModulesPerSolution;
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const groupIdsInSolution = useMemo(() => {
        const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(projectManager.project.id, group.id);
        return (
            workflowFolderManager.projectIdToFolderIdToFolderMap[projectManager.project.id]?.[workflowFolder?.id]
                ?.groupIds || []
        );
    }, [group.id, projectManager.project.id, workflowFolderManager]);
    const { data: groupsInWorkflowFolder, loading: loadingGroupsInWorkflowFolder } = useAsyncMethod(
        groupInfoManager,
        'getGroupsByIds',
        groupIdsInSolution,
    );

    const hasReachedActiveModulesLimit = useMemo(() => {
        if (!maxAllowedModulesPerSolution) {
            return false;
        }

        const activeModules = groupsInWorkflowFolder?.filter((it) => it.workerEnabled)?.length || 0;
        return activeModules >= maxAllowedModulesPerSolution;
    }, [groupsInWorkflowFolder, maxAllowedModulesPerSolution]);

    const [isConfirmed, setIsConfirmed] = useState(false);

    const confirmLabel = isArchiveRequired ? 'Archive Items & Turn Module On' : 'Turn On';
    const cancelLabel = isArchiveRequired ? 'Contact Support' : 'Cancel';

    function onContactSupport() {
        Intercom('showNewMessage', 'Hi, I would like to turn on module without archiving items');
    }

    const isLoading = loadingOutdated || loadingGroupsInWorkflowFolder;

    return (
        <Modal onClose={onCancel} onExited={onCancel} size={ModalSize.MEDIUM} ariaRole="alertdialog" open fixedWidth>
            <ModalHeader>
                {isLoading ? <Placeholder $width="60%" /> : `Turning the ${environment} environment on`}{' '}
            </ModalHeader>
            <ModalBody>
                {isLoading ? (
                    <div>
                        <Placeholder $width="75%" />
                        <br />
                        <Placeholder $width="85%" />
                    </div>
                ) : hasReachedActiveModulesLimit ? (
                    <div>
                        This Solution reached the{' '}
                        <strong>limit of {maxAllowedModulesPerSolution} Modules live in production.</strong> To turn the
                        Module ON you would need to turn OFF one of the other Modules in this Solution or use a
                        different Solution. For more information please contact support.
                    </div>
                ) : (
                    <div>
                        Are you sure you want to turn {group.name} {environment} environment on?
                        {isArchiveRequired && (
                            <div>
                                <NoteSection>
                                    <b>Important note:</b>&nbsp;This module was not active for a long period of time{' '}
                                    {outdated?.groupOffTimeInDays !== undefined && (
                                        <>({outdated?.groupOffTimeInDays} days)</>
                                    )}
                                    . In order to re-activate you must archive all existing module items.
                                </NoteSection>
                                <Checkbox checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)}>
                                    I understand that all items will be archived.
                                </Checkbox>
                            </div>
                        )}
                    </div>
                )}
            </ModalBody>
            {isLoading ? (
                <ModalFooter>
                    <Placeholder $width="75%" />
                </ModalFooter>
            ) : (
                <ModalFooterActions
                    onCancel={() => {
                        if (isArchiveRequired) {
                            onContactSupport();
                        }
                        onCancel();
                    }}
                    cancelLabel={cancelLabel}
                    onSave={onConfirm}
                    saveLabel={confirmLabel}
                    saveDisabled={(!isConfirmed && isArchiveRequired) || hasReachedActiveModulesLimit}
                />
            )}
        </Modal>
    );
};
export default EnvironmentActivateConfirmationModal;
