import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { FilterChangedEvent } from '@ag-grid-community/core';
import {
    type CellValueChangedEvent,
    type GridApi,
    type GridReadyEvent,
    type SelectionChangedEvent,
    type CellClickedEvent,
    ModuleRegistry,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useReducer, useRef, useState } from 'react';
import styled, { css, type CSSProp } from 'styled-components';

import EditableCustomFieldCellEditor from './components/CellEditors/EditableCustomFieldCellEditor';
import CreatedDateCellRenderer from './components/CellRenderers/CreatedDateCellRenderer';
import DueDateCellRenderer from './components/CellRenderers/DueDateCellRenderer';
import OptionsCellRenderer from './components/CellRenderers/OptionsCellRenderer';
import OwnerCellRenderer from './components/CellRenderers/OwnerCellRenderer';
import StatusCellRenderer from './components/CellRenderers/StatusCellRenderer';
import stringFieldCellRenderer from './components/CellRenderers/StringFieldCellRenderer';
import TitleCellRenderer from './components/CellRenderers/TitleCellRenderer';
import URLFieldCellRenderer from './components/CellRenderers/URLFieldCellRenderer';
import CustomLoadingOverlay from './components/CustomLoadingOverlay';
import columnTypes from './configs/config/columnTypes';
import { isTempRowForAddition } from './entities';
import type InitiativeRowData from './entities/InitiativeRowData';
import type ItemGridProps from './entities/ItemGridProps';
import type ItemsGridContext from './entities/ItemsGridContext';
import useColumnsDefinition from './hooks/useColumnsDefinition';
import itemsGridStyles from './styles/itemsGridStyles';
import { ALPINE_THEME, CLICKABLE_ROW, ROW_BACKGROUND_COLOR } from './utils/customClassNames';
import getRowHeight from './utils/getRowHeight';
import onSortRowsCallback from './utils/onSortRowsCallback';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Breakpoint, useBreakpoint } from '@tonkean/infrastructure';
import { EMPTY_ARRAY } from '@tonkean/utils';

const GridWrapper = styled.div<{
    isEmphasizeText?: boolean;
    $forceGridHeight: boolean;
    $isEnlargeText?: boolean;
    backgroundColor: string;
    $hasHorizontalBorder: boolean;
    $hasVerticalBorder: boolean;
    $gridStyles: CSSProp;
}>`
    ${itemsGridStyles};
    ${({ $gridStyles }) => css`
        ${$gridStyles}
    `}
    height: 100%;
`;

const filtersStateReducer = (state: string[], action: { columnId: string; isFiltered: boolean }) => {
    if (action.isFiltered) {
        return [...state, action.columnId];
    } else {
        return state.filter((columnId) => columnId !== action.columnId);
    }
};

const EMPTY_FUNC = () => {};

const ItemsGrid: React.FC<ItemGridProps> = ({
    emptyStateText = 'No items',
    projectId,
    workflowVersion,
    searchTerm,
    groupId,
    onSelection = EMPTY_FUNC,
    userCanEdit,
    rowData,
    fieldDefinitionsToShow,
    fieldDefinitionKeyToName,
    fieldDefinitionKeyToWidth,
    requiredFields = EMPTY_ARRAY,
    showOptionsMenu,
    onRowClicked: onRowClickedProp,
    forceGridHeight = false,
    onCellMouseDown,
    validations,
    inlineMenuActions,
    loading = false,
    isEmphasizeText,
    backgroundColor,
    onDelete,
    onChange,
    fieldDefinitions,
    allowFilter,
    hasHorizontalBorder = true,
    hasVerticalBorder = false,
    fixedRowHeight,
    drilldownColumnId,
    gridStyles,
    suppressMenuHide = true,
    hasRowMarker = true,
}) => {
    const shouldEnlargeFontSize = useFeatureFlag('tonkean_feature_emphasize_fields_widget_text') || false;

    const [filteredColumnIds, filtersDispatcher] = useReducer(filtersStateReducer, []);
    const [gridApi, setGridApi] = useState<GridApi<InitiativeRowData>>();
    const $rootScope = useAngularService('$rootScope');

    // creating initially loaded ref for the building phase.
    // so adding / removing columns will not trigger the grid to re-render
    // const isInitialLoadedRef = useRef<boolean>();
    const isInitialLoadedRef = useRef<boolean>();
    if (!isInitialLoadedRef.current && !loading) {
        isInitialLoadedRef.current = true;
    }

    const gridRowHeight = getRowHeight(shouldEnlargeFontSize, isEmphasizeText, fixedRowHeight);
    const [prevRowHeight, setPrevRowHeight] = useState<number>();

    if (prevRowHeight !== gridRowHeight) {
        setPrevRowHeight(gridRowHeight);
        gridApi?.resetRowHeights();
    }

    const onGridReady = (params: GridReadyEvent<InitiativeRowData>) => {
        setGridApi(params.api);
    };

    const config = useColumnsDefinition(
        userCanEdit,
        fieldDefinitions,
        fieldDefinitionsToShow,
        fieldDefinitionKeyToName,
        groupId,
        requiredFields,
        fieldDefinitionKeyToWidth,
        fixedRowHeight,
        hasRowMarker,
        showOptionsMenu,
        validations,
        isEmphasizeText && shouldEnlargeFontSize,
        allowFilter,
        filteredColumnIds,
        drilldownColumnId,
    );

    const onCellValueChanged = useCallback(
        (event: CellValueChangedEvent<InitiativeRowData>) => {
            return onChange?.(event.data, event.colDef.field as string, event.newValue).catch((error) => {
                // Alerting user with an error
                $rootScope.$emit('alert', {
                    msg: `There was a problem: ${error.data.data.error.message}`,
                });
            });
        },
        [$rootScope, onChange],
    );

    const onSelectionChanged = (event: SelectionChangedEvent<InitiativeRowData>) => {
        onSelection(event.api.getSelectedRows());
    };

    const effectiveBreakpoint = useBreakpoint();
    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    const onRowClicked = useCallback(
        (clickEvent: CellClickedEvent) => {
            if (clickEvent.column.getColId() !== 'options') {
                onRowClickedProp?.({
                    columnId: clickEvent.column.getColId(),
                    initiativeId: clickEvent.data.initiativeId,
                });
            }
        },
        [onRowClickedProp],
    );

    const context: ItemsGridContext = useMemo(() => {
        return {
            workflowVersion,
            onDelete,
            projectId,
            inlineMenuActions,
            backgroundColor,
            onChange,
            hasHorizontalBorder,
            hasVerticalBorder,
            userCanEdit,
            drilldownColumnId,
        };
    }, [
        workflowVersion,
        onDelete,
        projectId,
        inlineMenuActions,
        backgroundColor,
        onChange,
        hasHorizontalBorder,
        hasVerticalBorder,
        userCanEdit,
        drilldownColumnId,
    ]);

    ModuleRegistry.registerModules([ClientSideRowModelModule]);

    const rowClassRules = useMemo(() => {
        return {
            [ROW_BACKGROUND_COLOR]: ({ data }) => {
                return !!data && isTempRowForAddition(data);
            },
            [CLICKABLE_ROW]: () => {
                return !!onRowClicked;
            },
        };
    }, [onRowClicked]);

    const onFilterChanged = useCallback((event: FilterChangedEvent<InitiativeRowData>) => {
        if (event.columns[0] && event.source === 'columnFilter') {
            const updatedColumn = event.columns[0];

            filtersDispatcher({
                columnId: updatedColumn.getColId(),
                isFiltered: updatedColumn.isFilterActive(),
            });
        }
    }, []);

    return (
        <GridWrapper
            role="button"
            data-automation="items-grid"
            backgroundColor={backgroundColor}
            $forceGridHeight={forceGridHeight}
            isEmphasizeText={isEmphasizeText}
            $isEnlargeText={shouldEnlargeFontSize}
            $hasHorizontalBorder={hasHorizontalBorder}
            $hasVerticalBorder={hasVerticalBorder}
            $gridStyles={gridStyles}
        >
            <AgGridReact<InitiativeRowData>
                rowData={isInitialLoadedRef.current ? rowData : null}
                rowHeight={gridRowHeight}
                className={ALPINE_THEME}
                components={{
                    statusCellRenderer: StatusCellRenderer,
                    ownerCellRenderer: OwnerCellRenderer,
                    dueDateCellRenderer: DueDateCellRenderer,
                    createdDateCellRenderer: CreatedDateCellRenderer,
                    optionsRenderer: OptionsCellRenderer,
                    stringFieldCellRenderer,
                    editableCustomFieldCellRenderer: EditableCustomFieldCellEditor,
                    titleCellRenderer: TitleCellRenderer,
                    urlFieldCellRenderer: URLFieldCellRenderer,
                }}
                quickFilterText={searchTerm}
                columnDefs={isInitialLoadedRef.current ? config : null}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                loadingOverlayComponent={CustomLoadingOverlay}
                loadingOverlayComponentParams={{
                    rowsAmount: 6,
                    shouldShowOptionsTab: showOptionsMenu,
                    columnsAmount: fieldDefinitionsToShow.length,
                }}
                overlayNoRowsTemplate={emptyStateText}
                onCellValueChanged={onCellValueChanged}
                getRowId={(params) => params.data.tableRowId}
                onSelectionChanged={onSelectionChanged}
                stopEditingWhenCellsLoseFocus={!isMobile}
                tooltipShowDelay={1000}
                postSortRows={onSortRowsCallback}
                rowClassRules={rowClassRules}
                onCellClicked={onRowClickedProp ? onRowClicked : undefined}
                onFilterChanged={onFilterChanged}
                context={context}
                onCellMouseDown={onCellMouseDown}
                suppressMenuHide={suppressMenuHide}
                singleClickEdit
                suppressRowClickSelection
                animateRows
            />
        </GridWrapper>
    );
};
export default ItemsGrid;
