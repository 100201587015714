import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import InnerItemsWidgetHeaderImmediateCreateItemAction from './InnerItemsWidgetHeaderImmediateCreateItemAction';
import OpenFormActionButton from '../../ActionsWidgetModule/components/actionButtons/OpenFormActionButton';
import type { InnerItemWidgetConfiguration } from '../../entities';
import { InnerItemWidgetConfigurationItemCreationType } from '../../entities';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    ButtonRadioGroup,
    ButtonRadioGroupButton,
    SearchBox,
    useBreakpoint,
} from '@tonkean/infrastructure';
import { CardsDisplayIcon } from '@tonkean/svg';
import { ListDisplayIcon } from '@tonkean/svg';
import {
    ItemInterfaceWidgetConfigurationDisplayMode,
    ItemInterfaceWidgetConfigurationUserAccessLevel,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type {
    Form,
    Initiative,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetParentTypes,
} from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        flex-direction: column-reverse;
    }
`;

const StyledActionButton = styled(OpenFormActionButton)`
    max-width: 350px;
    margin-right: 8px;
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;
        margin-right: 0;
    }
`;

const StyledImmediateCreateItemAction = styled(InnerItemsWidgetHeaderImmediateCreateItemAction)`
    max-width: 350px;
    margin-right: 8px;
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;
        margin-right: 0;
    }
`;

const DisplayModeIconContainer = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg,
    path {
        height: 20px;
        width: 20px;
        margin: 0;
    }
`;

const DisplayModeRadioGroupButton = styled(ButtonRadioGroup)`
    margin-right: 8px;

    label {
        min-width: 40px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const StyledButtonRadioGroupButton = styled(ButtonRadioGroupButton)`
    label {
        min-width: 50px;
        height: 30px;
    }
`;

interface Props {
    environmentIsActive?: boolean;
    initiative?: Initiative;
    searchText?: string;
    setSearchText?: (text: string) => void;
    showModuleOffIndication?: boolean;
    widget: WidgetBase<InnerItemWidgetConfiguration, WidgetParentTypes>;
    workflowVersionType?: WorkflowVersionType;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    headerBackgroundColor: string;
    onInitiativeCreated?: (initiative: Initiative) => void;
    setDisplayMode?: (displayMode: ItemInterfaceWidgetConfigurationDisplayMode) => void;
    displayMode?: ItemInterfaceWidgetConfigurationDisplayMode;
    reloadInitiativeFromServer?: () => void;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const InnerItemsItemWidgetHeaderActions: React.FC<Props> = ({
    initiative,
    searchText,
    setSearchText,
    widget,
    workflowVersionType,
    workflowVersionId,
    headerBackgroundColor,
    onInitiativeCreated,
    setDisplayMode,
    displayMode,
    reloadInitiativeFromServer,
    originatedCustomTriggerId,
}) => {
    const formManager = useAngularService('formManager');

    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const [{ data: forms }, getAllWorkerForm] = useLazyAsyncMethod(formManager, 'getAllWorkerForm');

    useEffect(() => {
        if (widget.configuration.createForm && workflowVersionId) {
            getAllWorkerForm(workflowVersionId);
        }
    }, [widget.configuration.createForm, getAllWorkerForm, workflowVersionId]);

    const createForm = useMemo<Form | undefined>(() => {
        return forms?.find((form) => form.id === widget.configuration.createForm);
    }, [forms, widget.configuration.createForm]);

    const displayCreationButton =
        widget.configuration.userAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT_AND_CREATE &&
        widget.configuration.itemCreationButtonLabel;
    const displayImmediateCreationButton =
        displayCreationButton &&
        widget.configuration.itemCreationType === InnerItemWidgetConfigurationItemCreationType.IMMEDIATE &&
        initiative?.group?.id;

    const displayCreateFormActionButton =
        displayCreationButton && !displayImmediateCreationButton && widget.configuration.createForm && createForm;

    const effectiveBreakpoint = useBreakpoint();
    const isMobile = effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;
    const enableDisplayModeSwitchButton = widget.configuration.enableDisplayModeToggle && !isMobile;

    return (
        <ContentWrapper>
            {displayImmediateCreationButton && (
                <StyledImmediateCreateItemAction
                    displayText={widget.configuration.itemCreationButtonLabel || ''}
                    projectId={widget.projectId}
                    parentInitiativeId={initiative?.id}
                    groupId={initiative.group.id}
                    workflowVersionType={workflowVersionType}
                    ownerId={initiative?.owner?.id}
                    onInitiativeCreated={onInitiativeCreated}
                    buttonBackground={headerBackgroundColor}
                    originatedCustomTriggerId={originatedCustomTriggerId}
                />
            )}
            {displayCreateFormActionButton && (
                <StyledActionButton
                    initiativeId={undefined}
                    parentInitiativeId={initiative?.id}
                    key={widget.configuration.createForm}
                    form={createForm}
                    projectId={widget.projectId}
                    buttonBackground={headerBackgroundColor}
                    params={{
                        projectId: widget.projectId,
                        formId: createForm.id,
                        initiativeId: initiative?.id,
                        tid: initiative?.id,
                        formVersionType:
                            // Only put this param if draft for nicer url
                            workflowVersionType === WorkflowVersionType.DRAFT
                                ? WorkflowVersionType.DRAFT
                                : WorkflowVersionType.PUBLISHED,
                    }}
                    displayText={
                        widget.configuration.itemCreationButtonLabel ??
                        createForm.definition?.clientDisplayName ??
                        createForm.displayName
                    }
                    disabled={
                        createForm.group &&
                        (workflowVersionType === WorkflowVersionType.DRAFT
                            ? !groupsMap[createForm.group.id].buildEnvironmentEnabled
                            : !groupsMap[createForm.group.id].workerEnabled)
                    }
                    disabledTooltip="Module is disabled"
                    onInitiativeCreated={onInitiativeCreated}
                    workflowVersionType={workflowVersionType}
                    onSubmitResolved={reloadInitiativeFromServer}
                    originatedCustomTriggerId={originatedCustomTriggerId}
                    openFormsInModal
                />
            )}
            {enableDisplayModeSwitchButton && (
                <DisplayModeRadioGroupButton size={InputSize.SMALL} onChange={setDisplayMode} value={displayMode}>
                    <StyledButtonRadioGroupButton
                        backgroundColor={headerBackgroundColor}
                        value={ItemInterfaceWidgetConfigurationDisplayMode.TABLE}
                    >
                        <DisplayModeIconContainer data-automation="inner-items-item-widget-header-actions-display-mode-table">
                            <ListDisplayIcon />
                        </DisplayModeIconContainer>
                    </StyledButtonRadioGroupButton>
                    <StyledButtonRadioGroupButton
                        backgroundColor={headerBackgroundColor}
                        value={ItemInterfaceWidgetConfigurationDisplayMode.CARDS}
                    >
                        <DisplayModeIconContainer data-automation="inner-items-item-widget-header-actions-display-mode-cards">
                            <CardsDisplayIcon />
                        </DisplayModeIconContainer>
                    </StyledButtonRadioGroupButton>
                </DisplayModeRadioGroupButton>
            )}
            {setSearchText && (
                <SearchBox
                    value={searchText}
                    onChange={({ target: { value } }) => setSearchText(value)}
                    placeholder="Search"
                    $maxWidth={isMobile ? '100%' : '200px'}
                    $height="30px"
                    $width="210px"
                    $borderRadius="18px"
                />
            )}
        </ContentWrapper>
    );
};

export default InnerItemsItemWidgetHeaderActions;
