import type { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptySolutionIcon } from '../../../../images/icons/flat-mapper.svg';
import SolutionMapperGraph from '../../SolutionMapperModule/components/SolutionMapperGraph/SolutionMapperGraph';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorStateMessage, Placeholder, SolutionMapperSelector, StateMessage, Tooltip } from '@tonkean/infrastructure';
import type { SolutionMapper } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const LoadingState = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledSolutionMapperSelector = styled(SolutionMapperSelector)`
    margin-bottom: 16px;
`;

const Description = styled.div`
    margin-bottom: 16px;
    white-space: pre-wrap;
`;

const Graph = styled.div`
    height: 200px;
`;

const EmptyState = styled(StateMessage)`
    background: ${Theme.colors.HEX_F9F9F7};
    height: 200px;
`;

const NewSolutionMapperLink = styled(Clickable)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f6;
    border: 1px dashed ${Theme.colors.gray_400};
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 238px;
    color: ${Theme.colors.primaryHighlight} !important;
`;

interface Props {
    workflowFolderId: string;
    onCreateSolutionMapperClicked: () => void;
    isEditingEnabled: boolean;
    selectedSolutionMapper: SolutionMapper | undefined;
    setSelectedSolutionMapper: Dispatch<SetStateAction<SolutionMapper | undefined>>;
}

const SolutionPageSolutionMapperField: React.FC<Props> = ({
    workflowFolderId,
    onCreateSolutionMapperClicked,
    isEditingEnabled,
    selectedSolutionMapper,
    setSelectedSolutionMapper,
}) => {
    const {
        data: solutionMappersResponse,
        loading,
        error: solutionMappersError,
    } = useTonkeanService('getSolutionMappers', workflowFolderId);
    const [{ data: graph, loading: graphLoading, error: graphError }, getSolutionMapperGraph] =
        useLazyTonkeanService('getOperationsGraph');

    useEffect(() => {
        if (solutionMappersResponse?.solutionMappers?.length && !selectedSolutionMapper) {
            setSelectedSolutionMapper(solutionMappersResponse.solutionMappers[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedSolutionMapper, solutionMappersResponse?.solutionMappers]);

    useEffect(() => {
        if (selectedSolutionMapper) {
            getSolutionMapperGraph(selectedSolutionMapper.id);
        }
    }, [getSolutionMapperGraph, selectedSolutionMapper]);

    const empty = !graph?.nodes.length && !graph?.edges.length;

    return (
        <>
            {(loading || graphLoading || !solutionMappersResponse || (!graph && selectedSolutionMapper)) &&
                !graphError &&
                !solutionMappersError && (
                    <LoadingState>
                        <Placeholder $height="20px" $width="300px" />
                        <Placeholder $height="60px" $width="300px" />
                        <Placeholder $height="150px" $width="300px" />
                    </LoadingState>
                )}

            {(solutionMappersError || graphError) && (
                <ErrorStateMessage icon={<EmptySolutionIcon />}>
                    Error occurred while loading the process mappers
                </ErrorStateMessage>
            )}

            {!graphLoading && !loading && solutionMappersResponse?.solutionMappers && (
                <>
                    {(selectedSolutionMapper || solutionMappersResponse.solutionMappers.length > 0) && graph && (
                        <>
                            <StyledSolutionMapperSelector
                                solutionMappers={solutionMappersResponse.solutionMappers || EMPTY_ARRAY}
                                onChange={(value) => {
                                    setSelectedSolutionMapper(
                                        solutionMappersResponse.solutionMappers.find(
                                            (solutionMapper) => solutionMapper.id === value,
                                        ),
                                    );
                                }}
                                value={selectedSolutionMapper?.id}
                                loading={loading}
                                data-automation="solution-page-solution-mapper-field-mapper-selector"
                            />
                            {selectedSolutionMapper?.description && (
                                <Description data-automation="solution-page-solution-mapper-field-description">
                                    {selectedSolutionMapper.description}
                                </Description>
                            )}

                            {!empty ? (
                                <Graph>
                                    <SolutionMapperGraph
                                        nodes={graph.nodes}
                                        edges={graph.edges}
                                        selectedEntity={undefined}
                                        isEditingEnabled={false}
                                        showGraphControls={false}
                                    />
                                </Graph>
                            ) : (
                                <EmptyState icon={<EmptySolutionIcon />} title="This area looks a little empty">
                                    Open the Process mapper and add your first block
                                </EmptyState>
                            )}
                        </>
                    )}

                    {!selectedSolutionMapper && solutionMappersResponse.solutionMappers.length === 0 && (
                        <Tooltip
                            content="Only Makers and Board admins can add new Process mapper"
                            disabled={isEditingEnabled}
                        >
                            <NewSolutionMapperLink
                                onClick={onCreateSolutionMapperClicked}
                                data-automation="solution-page-solution-mapper-field-add-mapper"
                                disabled={!isEditingEnabled}
                            >
                                + Create a New Process Map
                            </NewSolutionMapperLink>
                        </Tooltip>
                    )}
                </>
            )}
        </>
    );
};

export default SolutionPageSolutionMapperField;
