<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group">
                <label for="teamconnect-subdomain" class="col-sm-3 control-label">URL</label>

                <div class="col-sm-9">
                    <div class="flex-vmiddle">
                        <div>https://</div>
                        <div class="flex-grow teamconnect-subdomain">
                            <input
                                type="text"
                                ng-model="data.subdomain"
                                class="form-control"
                                id="teamconnect-subdomain"
                                placeholder="Sub-domain"
                                required
                            />
                        </div>
                        <div>.teamconnect.com/</div>
                        <div class="flex-grow">
                            <input
                                type="text"
                                ng-model="data.version"
                                class="form-control"
                                id="teamconnect-version"
                                placeholder="Version"
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- TeamConnect Login Credentials-->
            <div ng-if="!data.oauth">
                <div class="form-group">
                    <label for="teamconnect-username" class="col-sm-3 control-label">Username</label>

                    <div class="col-sm-9">
                        <input
                            type="text"
                            ng-model="data.username"
                            class="form-control"
                            id="teamconnect-username"
                            autocomplete="off"
                            placeholder="Username"
                            required
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="teamconnect-password" class="col-sm-3 control-label">Password</label>

                    <div class="col-sm-9">
                        <input
                            type="password"
                            ng-model="data.password"
                            class="form-control"
                            id="teamconnect-password"
                            autocomplete="off"
                            placeholder="Password"
                            required
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
        type="button"
        ng-click="connect()"
    >
        OK
    </button>
</div>
