import React, { useMemo } from 'react';

import { RegexPart } from './RegexPart';
import { RegexTooltipZone } from './RegexTooltipZone';
import { RegexViewerBlock } from './RegexViewerBlock';
import { RegexExpressionPart } from '../entities/RegexExpressionPart';
import { RegexParser } from '../entities/RegexParser/RegexParser';
import { TreeBuilder } from '../entities/TreeBuilder';
import { TreeNode } from '../entities/TreeNode';

interface Props {
    evaluatedRegex: string;
    unevaluatedRegex: string;
    loading: boolean;
    error: { index?: number; error: string } | undefined;
    selectedGroup: { match: number; group: number | undefined } | undefined;
}

const RegexExpressionViewer: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    loading,
    evaluatedRegex,
    unevaluatedRegex,
    error,
    selectedGroup,
}) => {
    const rootPart = useMemo(() => {
        // Create the root tree node (the entire regex string)
        const rootPart = new RegexExpressionPart(evaluatedRegex, 0, evaluatedRegex.length);

        if (error) {
            // If it's an error, it will create a tree with only
            // one child - the error message.
            const treeRoot = new TreeNode(rootPart);

            if (error.index || error.index === 0) {
                // Create a fake part to contain the error message
                const errorPart = new RegexExpressionPart(
                    evaluatedRegex.substring(error.index, error.index + 1),
                    error.index,
                    error.index + 1,
                    true,
                );
                treeRoot.children.push(new TreeNode(errorPart));
            }
            return treeRoot;
        } else {
            // generate parts from the regex
            const parts = RegexParser.parseRegex(evaluatedRegex);

            // Create the tree for each of the parts in the regex
            return TreeBuilder.createTreeFromItemsList(rootPart, parts);
        }
    }, [evaluatedRegex, error]);

    return (
        <RegexViewerBlock
            input={children}
            title="Regular expression (pattern)"
            evaluated={evaluatedRegex}
            unevaluated={unevaluatedRegex}
            loading={loading}
            className="regex-expression-viewer"
            showRegexTutorialLink
        >
            <RegexTooltipZone>
                {(setTooltip) => (
                    <>
                        <RegexPart part={rootPart} selectedPart={selectedGroup} setTooltip={setTooltip} rootPart />
                        {error?.error && <strong className="margin-top-xs common-error block">{error.error}</strong>}
                    </>
                )}
            </RegexTooltipZone>
        </RegexViewerBlock>
    );
};

export { RegexExpressionViewer };
