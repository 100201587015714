import Markdown from 'markdown-to-jsx';
import React, { useEffect } from 'react';
import { useTypewriter } from 'react-simple-typewriter';

import { useFeatureFlag } from '@tonkean/angular-hooks';

interface Props {
    text: string;
    onTypingDone?: (isDone: boolean) => void;
    skipAnimation?: boolean;
}

const AITypewriter: React.FC<Props> = ({ text, onTypingDone, skipAnimation }) => {
    const configTypeSpeed = useFeatureFlag<number>('tonkean_feature_typewriter_typing_speed');
    const typeSpeed = configTypeSpeed || 20;

    const [typingText, { isDone }] = useTypewriter({ words: [text], typeSpeed });
    useEffect(() => {
        if (skipAnimation) {
            onTypingDone?.(true);
        } else {
            onTypingDone?.(isDone);
        }
    }, [isDone, onTypingDone, skipAnimation]);

    return <Markdown>{skipAnimation ? text : typingText}</Markdown>;
};
export default AITypewriter;
