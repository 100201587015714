import { useAngularService, useAngularWatch } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import SecondaryNavbar from './components/SecondaryNavbar';

import { EnvironmentIndicator, TextEllipsis } from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ModuleNavigator = styled.div`
    display: flex;
    align-items: center;
`;

const ModuleName = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
    margin-right: 32px;
`;

interface Props {
    currentEnvironment: Environment;
    onEnvironmentChange: () => void;
    environmentIsActive: boolean;
    group: Group;
}

const DrilldownHeaderNavigation: React.FC<Props> = ({
    currentEnvironment,
    onEnvironmentChange,
    environmentIsActive,
    group,
}) => {
    const navigationService = useAngularService('navigationService');
    const [isDrilldownMode] = useAngularWatch(() => navigationService.isDrilldownMode);

    const groupPermissions = useAngularService('groupPermissions');
    const hasPermissions = groupPermissions.hasPermissionsToEditWorker(group);
    const disabledEnvironments = {
        production: false,
        build: !hasPermissions ? 'Build environment is only available to module makers' : false,
    };

    if (isDrilldownMode) {
        return (
            <SecondaryNavbar isUppermost={isDrilldownMode}>
                <ModuleNavigator>
                    <ModuleName>
                        <TextEllipsis numberOfLines={1} tooltipLimitWidth={500} tooltip>
                            {group.name}
                        </TextEllipsis>
                    </ModuleName>
                </ModuleNavigator>

                <EnvironmentIndicator
                    currentEnvironment={currentEnvironment}
                    onChanges={onEnvironmentChange}
                    environmentIsActive={environmentIsActive}
                    disabledEnvironments={disabledEnvironments}
                />
            </SecondaryNavbar>
        );
    } else {
        return <></>;
    }
};

export default DrilldownHeaderNavigation;
