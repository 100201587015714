import type { ConstantActionConfig } from './projectIntegrationActionConstantParamIdToConfig';
import projectIntegrationActionConstantParamsIdToConfig from './projectIntegrationActionConstantParamIdToConfig';
import { ProjectIntegrationPaginatedActionConstantParams } from './projectIntegrationPaginatedActionConstantParams';

import { ActionDefinitionType, ActionParameterType } from '@tonkean/tonkean-entities';

const projectIntegrationPaginatedActionConstantParamIdToConfig: Record<
    ProjectIntegrationPaginatedActionConstantParams,
    ConstantActionConfig
> = {
    ...projectIntegrationActionConstantParamsIdToConfig,

    [ProjectIntegrationPaginatedActionConstantParams.IS_FIRST_PAGE]: {
        id: ProjectIntegrationPaginatedActionConstantParams.IS_FIRST_PAGE,
        displayName: 'Is First Page',
        emptyValueTooltip: 'Is it the first page',
        placeholder: '',
        description: 'Boolean Field (True, False). True if Page Number equals to 1',
        definitionTypes: [ActionDefinitionType.HTTP],
        editableInTest: true,
        parameterType: ActionParameterType.BOOLEAN,
        defaultOptions: [],
        excludeInActionTypes: [],
    },
    [ProjectIntegrationPaginatedActionConstantParams.PAGE_NUMBER]: {
        id: ProjectIntegrationPaginatedActionConstantParams.PAGE_NUMBER,
        displayName: 'Page Number',
        emptyValueTooltip: 'The page number starts with 1',
        placeholder: '',
        description: 'Numeric Fields. Starts with 1',
        definitionTypes: [ActionDefinitionType.HTTP],
        editableInTest: true,
        parameterType: ActionParameterType.NUMBER_TONKEAN_EXPRESSION,
        defaultOptions: [],
        excludeInActionTypes: [],
    },
    [ProjectIntegrationPaginatedActionConstantParams.LAST_COLLECT]: {
        id: ProjectIntegrationPaginatedActionConstantParams.LAST_COLLECT,
        displayName: 'Last Collect',
        emptyValueTooltip: 'The Last collect time',
        placeholder: '',
        description:
            'Date Field. Sends as numeric value, auto assigned with the last collect success time (ex. 891781261000)',
        definitionTypes: [ActionDefinitionType.HTTP],
        editableInTest: true,
        parameterType: ActionParameterType.DATE,
        defaultOptions: [],
        excludeInActionTypes: [],
    },
    [ProjectIntegrationPaginatedActionConstantParams.IS_INITIAL_COLLECT]: {
        id: ProjectIntegrationPaginatedActionConstantParams.IS_INITIAL_COLLECT,
        displayName: 'Is Initial Collect',
        emptyValueTooltip: 'Whether that is the initial collect',
        placeholder: '',
        description: 'Boolean Field (True, False). True if this collect is the initial one',
        definitionTypes: [ActionDefinitionType.HTTP],
        editableInTest: true,
        parameterType: ActionParameterType.BOOLEAN,
        defaultOptions: [],
        excludeInActionTypes: [],
    },
};

export default projectIntegrationPaginatedActionConstantParamIdToConfig;
