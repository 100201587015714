import { ReactComponent as TonkeanLogoIcon } from '../../../../../images/icons/tonkean-logo.svg';
import React from 'react';
import { daysDiffShortFilter } from '@tonkean/shared-services';
import { capitalizeFilter } from '@tonkean/shared-services';

export default class DefaultHistoryReason extends React.Component {
    constructor(props) {
        super(props);
    }

    /**
     * Basic implementation for the title element, can be overridden.
     * @returns {*}
     */
    getTitle() {
        return (
            <div>
                <span className="common-bold">{this.props.person.name}</span>
                <span> was pinged about {this.renderTitleLink()}.</span>
            </div>
        );
    }

    /**
     * Basic implementation for the footer element, can be overridden.
     * @returns {*}
     */
    getFooter() {
        const dueDate = this.props.initiative.dueDate;
        if (dueDate) {
            const now = new Date();
            if (dueDate > now) {
                return (
                    <div className="common-color-light-grey2 common-size-xxxxs margin-top-md">
                        Due date is {daysDiffShortFilter(dueDate)}
                    </div>
                );
            } else {
                return (
                    <div className="common-color-danger common-size-xxxxs margin-top-md">
                        Due date was {daysDiffShortFilter(dueDate)}
                    </div>
                );
            }
        }

        return null;
    }

    renderTitleLink() {
        const id = `bot-history-item-title-${this.props.item.id}-tnk-bot-history`;
        const context = this.props.initiative.parent
            ? this.props.initiative.parent.title
            : this.props.initiative.group.name;
        return (
            <span>
                <a
                    id={id}
                    onClick={this.props.goToTrack.bind(this, this.props.initiative.id)}
                    onMouseEnter={() =>
                        this.props.toggleTrackStatusSummaryPopover(true, this.props.statusPopoverTimeout)
                    }
                >
                    {this.props.initiative.title}
                </a>{' '}
                ({context})
            </span>
        );
    }

    /**
     * The main render function
     */
    render() {
        let askerName = 'someone';
        if (this.props.item.askerPerson) {
            if (this.props.item.askerPerson.id === this.props.as.currentUser.id) {
                askerName = 'you';
            } else {
                askerName = this.props.item.askerPerson.name;
            }
        }

        return (
            <div className="flex flex-grow">
                {/* Module Icon*/}
                <div className="svg-icon-xlg mod-static">
                    <span className="tnk-icon">
                        <TonkeanLogoIcon />
                    </span>
                </div>

                <div className="flex flex-col flex-grow">
                    <div className="common-size-xxxxs common-color-grey">
                        {this.props.item.messageSend
                            ? ` via ${capitalizeFilter(this.props.item.messageSend.sendBy)}`
                            : null}
                    </div>
                    {this.getTitle()}
                    {this.props.item.askerPerson ? <div>Ping was requested by {askerName}.</div> : null}

                    <div className="flex-grow" />

                    {this.getFooter()}
                </div>
            </div>
        );
    }
}
