import template from './tnkPeopleNamesAutocomplete.template.html?angularjs';

/**
 * A text input with people names autocomplete capabilities.
 */
function tnkPeopleNamesAutocomplete() {
    return {
        restrict: 'E',
        scope: {
            model: '=',
            isRequired: '=',
            placeholder: '@',
            classes: '@',
            // Attributes used for query options.
            excludeMe: '=',
            group: '=',
            options: '=',
        },
        template,
        controller: [
            '$scope',
            'tonkeanService',
            function ($scope, tonkeanService) {
                $scope.data = {
                    userSelected: false,
                    noResults: false,
                };

                $scope.searchPeople = function (query) {
                    $scope.loadingPeople = true;

                    // Mark the user as not selected (since the user has just typed something new).
                    $scope.data.userSelected = false;

                    // Set a limit to the results.
                    const limit = 8;

                    // Set the custom options for the query.
                    const options = $scope.options || {};
                    options.excludeMe = $scope.excludeMe;
                    options.includeTemporary = $scope.$root.pm.allowTempUsers;
                    options.group = $scope.group;

                    // Run the query against the server.
                    return tonkeanService
                        .getTeamMembers($scope.$root.pm.project.id, options, query, limit)
                        .then(function (data) {
                            let results = [];

                            // If nothing was found, let typeahead know about this.
                            if (!data || !data.people || !data.people.length) {
                                $scope.data.noResults = true;
                            } else {
                                results = data.people;
                            }

                            return results;
                        })
                        .catch(function (error) {
                            return [{ error }];
                        })
                        .finally(function () {
                            $scope.loadingPeople = false;
                        });
                };

                $scope.selectUser = function (user) {
                    $scope.data.userSelected = true;

                    if (user && user.name) {
                        $scope.model = user.name;
                    }
                };
            },
        ],
    };
}
export default angular.module('tonkean.app').directive('tnkPeopleNamesAutocomplete', tnkPeopleNamesAutocomplete);
