import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';

import { Checkbox, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HideDisplayNameCheckbox = styled(Checkbox)`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
`;
const HideDisplayNameLabel = styled.div`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    form: Form;
    onFormDefinitionChanged: (expandByDefault: boolean) => void;
}

const FormSettingsHideDisplayNameCheckbox: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    return (
        <div>
            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <HideDisplayNameCheckbox
                    checked={form.definition.hideDisplayName}
                    onChange={({ target: { checked } }) => onFormDefinitionChanged(checked)}
                    disabled={!isEditable}
                >
                    <HideDisplayNameLabel>Hide Display Name</HideDisplayNameLabel>
                </HideDisplayNameCheckbox>
            </Tooltip>
            <FormSettingsSectionHeaderSubtitle>* Supported in web forms only</FormSettingsSectionHeaderSubtitle>
        </div>
    );
};

export default FormSettingsHideDisplayNameCheckbox;
