<div class="tnk-docusign-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'ANALYZE_DOCUMENT' || data.definition.customActionKey === 'ANALYZE_INVOICE'" class="margin-bottom-xlg">
        <div class="btn-group margin-bottom">
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': data.definition.useStorageProvider }"
                uib-btn-radio="true"
                ng-model="data.definition.useStorageProvider"
                ng-click="onDefinitionChanged(true)"
            >
                Storage Provider
            </label>
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': !data.definition.useStorageProvider }"
                uib-btn-radio="false"
                ng-click="onDefinitionChanged(true)"
                ng-model="data.definition.useStorageProvider"
            >
                S3 Object
            </label>
        </div>

        <tnk-worker-file-selection
            ng-if="data.definition.useStorageProvider"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            info-message="'Maximum document size is 5MB'"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>
        <div ng-if="!data.definition.useStorageProvider">
            <div class="margin-top">
                <div class="margin-bottom-xs">Bucket</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="S3 object Bucket"
                    saved-expression="data.definition.s3ObjectBucketExpression"
                    on-tonkean-expression-changed="
                        onS3ObjectBucketTonkeanExpressionChanged(expression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>

            <div class="margin-top">
                <div class="margin-bottom-xs">Name</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="S3 object Name"
                    saved-expression="data.definition.s3ObjectNameExpression"
                    on-tonkean-expression-changed="onS3ObjectNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <div class="margin-top">
                <div class="margin-bottom-xs">Version</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="S3 object Version"
                    saved-expression="data.definition.s3ObjectVersionExpression"
                    on-tonkean-expression-changed="
                        onS3ObjectVersionTonkeanExpressionChanged(expression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
