function SyncConfigManager($q, $rootScope, tonkeanService, workflowVersionManager, syncConfigCacheManager, workflowVersionDataSourceManager) {
    const _this = this;

    _this.cacheSyncConfig = function (syncConfig) {
        syncConfigCacheManager.cacheSyncConfig(syncConfig);
    }

    _this.getSyncConfig = function (workflowVersionId) {
        return syncConfigCacheManager.getSyncConfig(workflowVersionId)
    }

    _this.createInitiativeSyncConfig = function (
        initiativeId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return syncConfigCacheManager.createInitiativeSyncConfig(
            initiativeId,
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        );
    }

    _this.createGroupSyncConfig = function (
        groupId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return syncConfigCacheManager.createGroupSyncConfig(
            groupId,
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        )
    }

    _this.updateSyncConfig = function (
        syncConfigId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return syncConfigCacheManager.updateSyncConfig(
            syncConfigId,
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,           
        )
    }

    _this.workflowVersionHasDatasourceOfType = function (workflowVersionId, integrationType, entity) {
        return syncConfigCacheManager.workflowVersionHasDatasourceOfType(workflowVersionId, integrationType, entity)
    }

    /**
     * Removes a group sync config from the cache and from the server.
     */
    _this.removeGroupSyncConfig = function (groupId, syncConfigId) {
        // Deleting the sync config in server.
        return tonkeanService.deleteSyncConfig(syncConfigId)
          .then((response) => {
          const draftWorkflowVersion = workflowVersionManager.getDraftVersionFromCache(groupId);
          const draftWorkflowVersionId = draftWorkflowVersion.id;

          // Deleting the sync config in cache.
          syncConfigCacheManager.workflowVersionIdToSyncConfigMap[draftWorkflowVersionId] = null;
          draftWorkflowVersion.syncConfig = undefined;

            workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
            return Promise.all([
                workflowVersionManager.getFromServerAndCacheWorkflowVersion(draftWorkflowVersionId),
                workflowVersionDataSourceManager.refetchWorkflowVersionItemDetails(draftWorkflowVersionId),
            ]).then(() => {
                // We broadcast a change, so components relying on this information can take action.
                // Doing broadcast-watch is frowned upon, but the alternative is a very ugly spaghetti
                // code between components in order to have them updated. It's a compromise that makes
                // code more readable in this particular case.
                $rootScope.$broadcast('groupSyncConfigRemoved', {
                    groupId,
                    workflowVersionId: draftWorkflowVersionId,
                });
                return $q.resolve(response);
            });
        });
    };

    _this.createOrUpdateGroupSyncConfig = function (
        groupId,
        workflowVersionId,
        syncConfigId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        let promise;
        if (syncConfigId) {
            promise = _this.updateSyncConfig(
                syncConfigId,
                projectIntegrationId,
                viewData,
                viewCount,
                viewType,
                previewMode,
                uniqueIdentifierType,
                deleteItemsSelectionType,
                syncItemsCreatorSelectionType,
                syncItemsCreatorConfiguration,
            );
        } else {
            promise = _this.createGroupSyncConfig(
                groupId,
                projectIntegrationId,
                viewData,
                viewCount,
                viewType,
                previewMode,
                uniqueIdentifierType,
                deleteItemsSelectionType,
                syncItemsCreatorSelectionType,
                syncItemsCreatorConfiguration,
            );
        }

        return promise
            .then(() =>
                Promise.all([
                    workflowVersionManager.getFromServerAndCacheWorkflowVersion(workflowVersionId),
                    workflowVersionDataSourceManager.refetchWorkflowVersionItemDetails(workflowVersionId),
                ]),
            )
            .then(([updatedWorkflowVersion]) => {
                updatedWorkflowVersion.syncConfig.workflowVersion = updatedWorkflowVersion;
                syncConfigCacheManager.cacheSyncConfig(updatedWorkflowVersion.syncConfig);
            });
    };
}

export default angular.module('tonkean.app').service('syncConfigManager', SyncConfigManager);
