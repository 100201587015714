import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import AddContractFieldsModal from './AddContractFieldsModal';
import type { ContractFieldWithIsSelected } from './ContractFieldWithIsSelected';
import CustomItemMatchFieldsMetadataTableMenuList from './CustomItemMatchFieldsMetadataTableMenuList';
import NestedFieldListItem from './NestedFieldListItem';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Chevron,
    ChevronDirection,
    Separator,
    Spacer,
    Tooltip,
    useProject,
    useToggle,
    ProjectIntegrationIcon,
} from '@tonkean/infrastructure';
import {
    ContractFieldType,
    FormDefinitionType,
    MatchItemFieldDefinition,
    type FieldDefinition,
    type ProjectIntegration,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const MatchItemContainer = styled.div`
    padding: 5px;
    display: grid;
    align-items: center;
    gap: 5px;
    grid-template-columns: 1fr 18fr 1fr;
`;

const FieldsContainer = styled.div`
    padding: 8px;
    display: flex;
    justify-content: space-between;
`;

const AddFieldContainer = styled.div`
    margin-right: 9px;
`;

const AddFieldClickableContainer = styled.div`
    display: flex;
`;

const StyledClickable = styled(Clickable)`
    color: #3fa7b8; // this color is not in the palette and needs to be consistent with the rest of the links in the module editor

    &:hover {
        text-decoration: underline;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: ${Theme.colors.white};
    border: 1px solid #e9ecf1; // this color is not in the palette and needs to be consistent with the rest of the links in the module editor
    box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    fieldDefinition: FieldDefinition<MatchItemFieldDefinition>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    onFieldSelected: (
        groupId: TonkeanId<TonkeanType.GROUP>,
        targetType: string,
        type: FormDefinitionType,
        projectIntegration: ProjectIntegration,
        createMode: boolean,
        duplicateMode: boolean,
        definition: FieldDefinition,
        reloadFields: (fieldDefinition: FieldDefinition, forceReload: boolean) => void,
        deleteCallback: () => void | undefined,
        openInStep: boolean,
        quickCreateForExternal: boolean,
        startWithDataSource: boolean,
        selectedEntity: any,
        isWorkerMode: boolean,
        overrideFormulaOperator: boolean,
        overrideFieldIsHidden: boolean,
        manualValue: any,
        idOnlyMode: boolean,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        allowOnlyFieldDefinitionDataTypes: boolean,
        isForMatchedItem: boolean,
    ) => void;

    reloadFields: (fieldDefinition: FieldDefinition, forceReload: boolean) => void;
    addFieldByRelatedEntity: (
        projectIntegration: ProjectIntegration,
        fieldDefinitionId: FieldDefinition['id'],
        isForMatchItem: boolean,
    ) => void;
    allFieldDefinitions: Record<FieldDefinition['id'], FieldDefinition<MatchItemFieldDefinition>> | undefined;
    editNestedFields: () => void;
    applyFieldDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    applyTriggerDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    deleteFieldDefinition: (fieldDefinition: FieldDefinition<unknown>) => void;
    fieldFilterByName?: string;
}

const CustomItemMatchFieldsMetadataTable: React.FC<Props> = ({
    fieldDefinition,
    groupId,
    workflowVersionId,
    onFieldSelected,
    reloadFields,
    addFieldByRelatedEntity,
    allFieldDefinitions,
    applyFieldDependenciesFilter,
    applyTriggerDependenciesFilter,
    deleteFieldDefinition,
    fieldFilterByName = '',
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const customFieldsManager = useAngularService('customFieldsManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const { id: projectId } = useProject();

    const [isContractAddFieldsModalOpen, toggleIsContractAddFieldsModalOpen] = useToggle(false);

    const [projectIntegrationGroupName, setProjectIntegrationGroupName] = useState<string | undefined>();
    useEffect(() => {
        if (fieldDefinition?.projectIntegration?.representativeGroupId) {
            groupInfoManager.getGroupById(fieldDefinition.projectIntegration.representativeGroupId).then((group) => {
                setProjectIntegrationGroupName(group.name);
            });
        }
    }, [fieldDefinition.projectIntegration?.representativeGroupId, groupInfoManager]);

    const reloadFieldsCallback = useCallback(
        (fieldDefinition: FieldDefinition, forceReload: boolean) => {
            reloadFields(fieldDefinition, forceReload);
        },
        [reloadFields],
    );
    const contractId = fieldDefinition?.definition?.matchConfiguration?.contractId;

    const [{ data: contractOutputFields }, getContractOutputFields] = useLazyTonkeanService('getContractFields');

    useEffect(() => {
        if (contractId) {
            getContractOutputFields(projectId, contractId, ContractFieldType.OUTPUT);
        }
    }, [contractId, getContractOutputFields, projectId]);

    // The fields extracted from the matched item
    const nestedFields = useMemo<FieldDefinition[]>(() => {
        if (allFieldDefinitions) {
            return Object.values(allFieldDefinitions).filter(
                (field) =>
                    field?.definition?.matchConfiguration?.idRelationFieldDefinitionId === fieldDefinition.id &&
                    field.name.toLocaleLowerCase().includes(fieldFilterByName.toLocaleLowerCase()),
            );
        }
        return [];
    }, [allFieldDefinitions, fieldDefinition.id, fieldFilterByName]);

    const contractFieldsState = useMemo(() => {
        return (contractOutputFields?.entities || []).map((contractField) => {
            const linkedFieldDefinition =
                nestedFields &&
                Object.values(nestedFields).find((fide) => fide.secondaryId === contractField.contractFieldId);

            return {
                isSelected: !!linkedFieldDefinition,
                disabled: !!linkedFieldDefinition,
                displayName: contractField.displayName,
                id: contractField.contractFieldId,
            };
        });
    }, [contractOutputFields?.entities, nestedFields]);

    const onSubmitContractFieldsModal = useCallback(
        async (fieldsToAdd: ContractFieldWithIsSelected[]) => {
            const promises = fieldsToAdd.map(async (createdField) => {
                const createdFieldDefinition = await customFieldsManager.createFieldDefinition(
                    'COLUMN',
                    `${fieldDefinition.name} - ${createdField.displayName}`,
                    undefined,
                    'EXTERNAL',
                    null,
                    {
                        ExternalType: fieldDefinition.definition.ExternalType,
                        FieldLabel: createdField.displayName,
                        FieldName: createdField.id,
                        matchConfiguration: {
                            matchOption: 'SPECIFIC_ITEM',
                            performOnWorkerItem: false,
                            idRelationFieldDefinitionId: fieldDefinition.id,
                            isForMatchingItem: true,
                            matchedItemSourceGroupId:
                                fieldDefinition.definition.matchConfiguration.matchedItemSourceGroupId,
                            matchedItemSourceWorkflowVersionId:
                                fieldDefinition.definition.matchConfiguration.matchedItemSourceWorkflowVersionId,
                            contractId,
                        },
                    },
                    fieldDefinition.projectIntegration.id,
                    groupId,
                    'String',
                    null,
                    undefined,
                    null,
                    null,
                    null,
                    false,
                    false,
                    true,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    null,
                    createdField.id,
                    undefined,
                    undefined,
                    null,
                    undefined,
                );

                reloadFields(createdFieldDefinition, true);
            });

            return await Promise.all(promises);
        },
        [
            contractId,
            customFieldsManager,
            fieldDefinition?.definition?.ExternalType,
            fieldDefinition?.definition?.matchConfiguration?.matchedItemSourceGroupId,
            fieldDefinition?.definition?.matchConfiguration?.matchedItemSourceWorkflowVersionId,
            fieldDefinition?.id,
            fieldDefinition?.name,
            fieldDefinition?.projectIntegration?.id,
            groupId,
            reloadFields,
        ],
    );

    const isContractNotReady =
        !!contractId && !fieldDefinition?.definition?.matchConfiguration?.matchedItemSourceGroupId;

    const onFieldSelectedCallback = useCallback(
        (fieldDefinitionSelected: FieldDefinition<MatchItemFieldDefinition>) => {
            onFieldSelected(
                groupId,
                fieldDefinitionSelected.targetType,
                fieldDefinitionSelected.type,
                fieldDefinitionSelected.projectIntegration,
                false,
                false,
                fieldDefinitionSelected,
                reloadFieldsCallback,
                () => {},
                false,
                false,
                false,
                undefined,
                false,
                false,
                false,
                undefined,
                false,
                workflowVersionId,
                false,
                fieldDefinitionSelected?.definition?.matchConfiguration?.isForMatchingItem &&
                    fieldDefinitionSelected.idRelationField,
            );
        },
        [groupId, onFieldSelected, reloadFieldsCallback, workflowVersionId],
    );

    // Nested Field list is the list of fields used from the matched entity
    const [nestedFieldsOpen, toggleNestedFieldsOpen] = useToggle(false);
    const enableAddFields = !fieldDefinition.definition.matchConfiguration.contractId;
    return (
        <Container>
            <MatchItemContainer>
                <ProjectIntegrationIcon
                    integrationType={fieldDefinition.projectIntegration?.integration?.integrationType}
                    width={16}
                    iconUrl={fieldDefinition.projectIntegration?.iconUrl}
                />
                <TitleContainer data-automation="custom-item-match-fields-metadata-table-title">
                    <b>{projectIntegrationGroupName || fieldDefinition?.projectIntegration?.representativeGroupId}</b>
                    {fieldDefinition?.name}
                </TitleContainer>
                <CustomItemMatchFieldsMetadataTableMenuList
                    menuIsOpen={menuIsOpen}
                    setMenuIsOpen={setMenuIsOpen}
                    fieldDefinition={fieldDefinition}
                    onFieldSelectedCallback={onFieldSelectedCallback}
                    applyFieldDependenciesFilter={applyFieldDependenciesFilter}
                    applyTriggerDependenciesFilter={applyTriggerDependenciesFilter}
                    deleteFieldDefinition={deleteFieldDefinition}
                />
            </MatchItemContainer>
            <Separator />
            <FieldsContainer>
                <Tooltip disabled={!isContractNotReady} content="You must configure module">
                    <Clickable onClick={toggleNestedFieldsOpen} disabled={isContractNotReady}>
                        <AddFieldClickableContainer data-automation="group-fields-metadata-open-matched-item-list">
                            <b>Fields ({nestedFields.length})</b>
                            <Spacer height={1} width={8} />
                            <Chevron direction={nestedFieldsOpen ? ChevronDirection.DOWN : ChevronDirection.RIGHT} />
                        </AddFieldClickableContainer>
                    </Clickable>
                </Tooltip>

                {contractId && !!contractFieldsState && (
                    <ClickableLink onClick={toggleIsContractAddFieldsModalOpen}>+ Add Field (Contract)</ClickableLink>
                )}

                {isContractAddFieldsModalOpen && (
                    <AddContractFieldsModal
                        initialFieldsState={contractFieldsState}
                        onSubmit={onSubmitContractFieldsModal}
                        onClose={toggleIsContractAddFieldsModalOpen}
                        open={isContractAddFieldsModalOpen}
                    />
                )}
                {enableAddFields && (
                    <AddFieldContainer>
                        <StyledClickable
                            data-automation={`custom-item-match-fields-metadata-table-add-field-${
                                projectIntegrationGroupName ||
                                fieldDefinition?.projectIntegration?.representativeGroupId
                            }`}
                            onClick={() => {
                                addFieldByRelatedEntity(fieldDefinition.projectIntegration, fieldDefinition.id, true);
                            }}
                        >
                            + Add Field
                        </StyledClickable>
                    </AddFieldContainer>
                )}
            </FieldsContainer>
            {nestedFieldsOpen && (
                <>
                    {nestedFields.map((field, index) => {
                        return (
                            <div key={`container_${field.id}`}>
                                <Separator key={`separator_${field.id}`} />
                                <NestedFieldListItem
                                    key={field.id}
                                    fieldDefinition={field}
                                    onFieldSelected={onFieldSelectedCallback}
                                    applyFieldDependenciesFilter={applyFieldDependenciesFilter}
                                    applyTriggerDependenciesFilter={applyTriggerDependenciesFilter}
                                    index={index}
                                    enableEditFields={enableAddFields}
                                />
                            </div>
                        );
                    })}
                </>
            )}
        </Container>
    );
};
export default CustomItemMatchFieldsMetadataTable;
