import InnerItemsItemWidget from './components/InnerItemsItemWidget';
import InnerItemsItemWidgetConfigurationEditor from './components/InnerItemsItemWidgetConfigurationEditor';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';
import type { InnerItemWidgetConfiguration } from '../entities';

const InnerItemsWidgetPackage: ItemInterfaceWidgetPackage<InnerItemWidgetConfiguration> = {
    displayComponent: InnerItemsItemWidget,
    configurationComponent: InnerItemsItemWidgetConfigurationEditor,
};

export default InnerItemsWidgetPackage;
