import React from 'react';
import styled from 'styled-components';

import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const CreatedDate = styled.div`
    color: ${Theme.colors.gray_700};
`;

const CreatedDateCellRenderer: React.FC<ItemsGridCellRendererParams> = ({ value }) => {
    return <CreatedDate>{value ? utils.formatDate(value, false, true, true, true) : ''}</CreatedDate>;
};

export default CreatedDateCellRenderer;
