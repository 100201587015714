import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MicrosoftTeamsAppTeamSelectionCtrl($scope, tonkeanService, projectManager, oauthHandler) {
    const ctrl = this;

    $scope.data = {
        connecting: false,
        error: null,
        isEdit: false,
        integration: ctrl.integration,
        projectIntegration: ctrl.projectIntegration,
        projectData: ctrl.projectIntegration?.projectData?.projectDatas[0] || {},
        teamsOptions: [],
        teamsOptionsLoading: false,
        checkIfBotIsConnectedToTeamLoading: false,
        connectBotToTeamAndTryAgain: false,
        selectedTeam: null,
        errorMessage: null,
    };

    $scope.init = function () {
        console.log($scope.data.projectData);
        ctrl.onApplicationConnectedToTeam({ projectData: $scope.data.projectData, isDisabled: true });
        $scope.loadAllTeams();
    };

    $scope.loadAllTeams = function () {
        $scope.data.teamsOptionsLoading = true;
        $scope.data.errorMessage = null;
        tonkeanService
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'teams')
            .then((data) => {
                $scope.data.teamsOptions = data.options;
            })
            .catch((error) => {
                $scope.data.errorMessage = "Seems like we're having trouble with fetching the Teams list.";
            })
            .finally(() => {
                $scope.data.teamsOptionsLoading = false;
            });
    };

    $scope.onTeamClicked = function (team) {
        $scope.data.selectedTeam = team;
        $scope.data.checkIfBotIsConnectedToTeamLoading = true;
        checkIfBotConnectedToTeamAndCreateProjectIntegration();
    };

    $scope.connect = () => {
        // The oAuthHandler doesn't actually do oAuth, it just send the user to the Microsoft Teams marketplace,
        // so he can install Tonkean. So we always will go to the .then call here.
        // After sending the user there, we change the button to "I did it".
        // Once he clicks that, we get the project and check if it's connected. If it is, connection is successful.
        oauthHandler.microsoftTeams();
    };

    function checkIfBotConnectedToTeamAndCreateProjectIntegration() {
        $scope.data.checkIfBotIsConnectedToTeamLoading = true;
        return tonkeanService
            .isBotConnectedToTeam(
                $scope.data.selectedTeam.value,
                $scope.data.integration.id,
                $scope.data.projectIntegration?.id,
                projectManager.project.id,
            )
            .then((response) => {
                $scope.data.connectBotToTeamAndTryAgain = false;
                $scope.data.checkIfBotIsConnectedToTeamLoading = false;
                $scope.data.projectData = response.projectData;
                return ctrl.onApplicationConnectedToTeam({ projectData: $scope.data.projectData, isDisabled: false });
            })
            .finally(() => {
                $scope.data.checkIfBotIsConnectedToTeamLoading = false;
            });
    }

    $scope.checkIfBotConnectedToSelectedTeam = function () {
        checkIfBotConnectedToTeamAndCreateProjectIntegration().catch(() => {
            $scope.data.connectBotToTeamAndTryAgain = true;
            ctrl.onApplicationConnectedToTeam({ projectData: $scope.data.projectData, isDisabled: true });
        });
    };

    $scope.changeTeam = function () {
        $scope.data.selectedTeam = null;
        $scope.data.connectBotToTeamAndTryAgain = false;
    };

    $scope.init();
}

angular
    .module('tonkean.shared')
    .controller('MicrosoftTeamsAppTeamSelectionCtrl', lateConstructController(MicrosoftTeamsAppTeamSelectionCtrl));
