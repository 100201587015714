import template from './tnkWorkerHistory.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerHistory', {
    bindings: {
        filter: '<',
        groupId: '@',
        workflowVersionId: '@',
        closeSidePane: '<',
    },
    template,
    controller: 'workerHistoryCtrl',
});
