import { reactToAngular } from 'angulareact';

import ContractSidePaneTab from './ContractSidePaneTab';

export default angular
    .module('tonkean.app')
    .component(
        'tnkContractSidePaneTab',
        reactToAngular(ContractSidePaneTab, ['groupId', 'workflowVersionId', 'workflowVersionType']),
    );
