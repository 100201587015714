/**
 * Returns the relation id, if its a field definition in a related field definition, it will return the related field
 * definition id.
 *
 * @param fieldDefinition - the field definition definition.
 * @returns the relation id.
 */
function getFieldDefinitionHistoryRelationId(fieldDefinition): string | undefined {
    // Field is the intake source match entity
    if (fieldDefinition.type === 'ROOT') {
        return fieldDefinition.projectIntegrationId;
    }

    if (fieldDefinition.linkedCustomTrigger?.id) {
        // Auto generated matched entity in a custom trigger (Ex: folder created in drive).
        return fieldDefinition.linkedCustomTrigger.id;
    }

    // Field is matched entity
    if (fieldDefinition.idRelationField) {
        return fieldDefinition.id;
    }

    if (fieldDefinition.definition?.matchConfiguration?.creatingCustomTriggerId) {
        return fieldDefinition.definition?.matchConfiguration?.creatingCustomTriggerId;
    }

    // Field is inside a matched entity
    const parentMatchedEntityId = fieldDefinition.definition?.matchConfiguration?.idRelationFieldDefinitionId;
    const parentCustomTriggerMatchedEntityId = fieldDefinition.definition?.matchConfiguration?.creatingCustomTriggerId;
    const parentDataSourceMatchedEntityId = fieldDefinition.projectIntegration?.id;
    return parentMatchedEntityId || parentCustomTriggerMatchedEntityId || parentDataSourceMatchedEntityId;
}

export default getFieldDefinitionHistoryRelationId;
