import React from 'react';

import DetailedItemsWidgetItemCell from './DetailedItemsWidgetItemCell';
import { Wrapper } from './DetailedItemsWidgetItemsListStyle';
import type { DetailedItemsWidgetItemInfo } from '../entities/DetailedItemsWidgetItemInfo';
import useItemsVisibleValidMap from '../hooks/useItemsVisibleValidMap';

import type {
    ActionInfo,
    TonkeanId,
    TonkeanType,
    ValidationState,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export interface DetailedItemsWidgetItemsListProps {
    itemsInfo: DetailedItemsWidgetItemInfo[];
    selectedItemId?: TonkeanId<TonkeanType.INITIATIVE>;
    setSelectedItemId: (itemId: TonkeanId<TonkeanType.INITIATIVE>) => void;
    selectionColor: Color;
    inlineMenuActions: ActionInfo[];
    onDelete: (deletedItemId: TonkeanId<TonkeanType.INITIATIVE>) => void;
    viewOnly: boolean;
    workflowVersionType: WorkflowVersionType;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    visitedItemInterfacesValidation?: Record<string, ValidationState>;
    forceShowValidation?: boolean;
}

const DetailedItemsWidgetItemsList: React.FC<DetailedItemsWidgetItemsListProps> = ({
    itemsInfo,
    selectedItemId,
    setSelectedItemId,
    selectionColor,
    inlineMenuActions,
    onDelete,
    viewOnly,
    workflowVersionType,
    projectId,
    visitedItemInterfacesValidation,
    forceShowValidation,
}) => {
    const itemsVisibleValidMap = useItemsVisibleValidMap(
        itemsInfo,
        visitedItemInterfacesValidation,
        forceShowValidation,
    );

    if (!itemsInfo.length) {
        return null;
    }

    return (
        <Wrapper>
            {itemsInfo.map((itemInfo, itemIndex) => (
                <DetailedItemsWidgetItemCell
                    key={itemInfo.id}
                    selectedItemId={selectedItemId}
                    itemInfo={itemInfo}
                    setSelectedItemId={setSelectedItemId}
                    selectionColor={itemsVisibleValidMap[itemInfo.id] ? selectionColor : Theme.colors.error}
                    inlineMenuActions={inlineMenuActions}
                    onDelete={onDelete}
                    projectId={projectId}
                    workflowVersionType={workflowVersionType}
                    viewOnly={viewOnly}
                    isItemValid={itemsVisibleValidMap[itemInfo.id]}
                />
            ))}
        </Wrapper>
    );
};

export default DetailedItemsWidgetItemsList;
