import { reactToAngular } from 'angulareact';

import WorkflowFolderCategorySelector from './WorkflowFolderCategorySelector';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkflowFolderCategorySelector',
        reactToAngular(WorkflowFolderCategorySelector, [
            'workflowFolderId',
            'workflowFolderCategoryId',
            'onCategorySelected',
            'autoSelectFirst',
        ]),
    );
