import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import type FieldChartTooltipData from './FieldChartTooltipData';
import FieldChartTooltipType from './FieldChartTooltipType';
import PopoverTooltipElement from './PopoverTooltipElement';
import useGetFieldGraphValues from './useGetFieldGraphValues';

import {
    ChartDisplayType,
    ErrorMessage,
    FieldChartOptions,
    LineChart,
    useLastDefinedValue,
} from '@tonkean/infrastructure';
import type { FieldInstance, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const LineChartContainer = styled.div``;

interface Props {
    field: FieldInstance;
    fieldName: string;
    chartInModal?: boolean;
    showStatusOverlay: boolean;
    rangeFrom: Date;
    rangeTo: Date;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    configuredChartType?: ChartDisplayType;
}

const FieldChart: React.FC<Props> = ({
    field,
    fieldName,
    chartInModal,
    showStatusOverlay,
    rangeFrom,
    rangeTo,
    initiativeId,
    configuredChartType = ChartDisplayType.STEP,
}) => {
    const [tooltipLeft, setTooltipLeft] = useState(0);
    const [tooltipTop, setTooltipTop] = useState(0);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [forceShowTooltip, setForceShowTooltip] = useState<boolean>(false);
    const [tooltipContent, setTooltipContent] = useState<{ lines: FieldChartTooltipData[] }[] | undefined>();
    const [tooltipTitle, setTooltipTitle] = useState<string>('');
    const [tooltipType, setTooltipType] = useState<FieldChartTooltipType>(FieldChartTooltipType.VALUE_CHANGE);

    const [graphData, activityArr, error, loading, tooltipData] = useGetFieldGraphValues(
        field,
        fieldName,
        rangeFrom.getTime(),
        rangeTo.getTime(),
        showStatusOverlay,
        configuredChartType,
        initiativeId,
    );

    const chartGraphData = useLastDefinedValue<React.ComponentProps<typeof LineChart>['data'] | undefined>(graphData);

    const tooltipCallbacks = useMemo(() => {
        return {
            label(tooltipItem) {
                const formattedDate = dayjs(tooltipItem.parsed.x).format('MMM DD, YYYY');
                setTooltipTitle(
                    formattedDate === dayjs(rangeTo).format('MMM DD, YYYY') ? 'Current Value' : formattedDate,
                );

                if (activityArr && Object.keys(activityArr).includes(formattedDate)) {
                    // it's an activity
                    const item = activityArr[formattedDate]?.[tooltipItem.dataset.backgroundColor];

                    if (item?.[0]) {
                        setTooltipType(FieldChartTooltipType.STATUS_CHANGE);
                        return {
                            id: item[0].id,
                            type: FieldChartTooltipType.STATUS_CHANGE,
                            label: item[0].metadata?.newState?.stateText,
                            color: item[0].metadata?.newState?.stateColor,
                            actor: item[0]?.actor,
                            realActivityDate: dayjs(item[0]?.created).format('HH:mm'),
                        };
                    }
                }
                setTooltipType(FieldChartTooltipType.VALUE_CHANGE);
                if (tooltipData?.[formattedDate]?.length > 1) {
                    return {
                        id: tooltipItem.dataIndex,
                        type: FieldChartTooltipType.VALUE_CHANGE,
                        label: tooltipItem.formattedValue,
                        color: tooltipItem.dataset.borderColor,
                        date: dayjs(rangeTo).format('MMM DD, YYYY') === formattedDate ? 'Current value' : formattedDate,
                        currentDateChanges: tooltipData?.[formattedDate],
                    };
                }
                return {
                    id: tooltipItem.dataIndex,
                    type: FieldChartTooltipType.VALUE_CHANGE,
                    label: tooltipItem.formattedValue,
                    color: tooltipItem.dataset.borderColor,
                    date: dayjs(rangeTo).format('MMM DD, YYYY') === formattedDate ? 'Current value' : formattedDate,
                };
            },
        };
    }, [activityArr, rangeTo, tooltipData]);

    const reRenderTooltip = useCallback(() => {
        if (!showTooltip) {
            setShowTooltip(true);
        }
    }, [showTooltip]);

    const chartOptions = useMemo<React.ComponentProps<typeof LineChart>['options']>(() => {
        return FieldChartOptions(
            (context) => {
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    if (!forceShowTooltip) {
                        setShowTooltip(false);
                    }
                    return;
                }
                setForceShowTooltip(true);
                const positionY = context.chart.canvas.offsetTop;
                const positionX = context.chart.canvas.offsetLeft;
                // position the tooltips
                // offsets are provided to position the tooltip correctly and not cover the graph points
                let tooltipPositionX = positionX + context.tooltip.caretX + 20;
                let tooltipPositionY = positionY + context.tooltip.caretY + 90;

                if (chartInModal) {
                    tooltipPositionY = tooltipPositionY + 35;
                    tooltipPositionX = tooltipPositionX - 5;
                }

                // if the tooltip position is different we need to re-render the tooltip element
                // the reason we do it is because when moving mouse on the line the tooltip is not set to false and the positioning doesn't change
                if (tooltipPositionX !== tooltipLeft || tooltipPositionY !== tooltipTop) {
                    setShowTooltip(false);
                }

                setTooltipLeft(tooltipPositionX);
                setTooltipTop(tooltipPositionY);

                setTooltipContent(context.tooltip.body);
                reRenderTooltip();
            },
            tooltipCallbacks,
            rangeFrom,
            rangeTo,
        );
    }, [
        chartInModal,
        forceShowTooltip,
        rangeFrom,
        rangeTo,
        reRenderTooltip,
        tooltipCallbacks,
        tooltipLeft,
        tooltipTop,
    ]);

    const closeTooltip = useCallback(() => {
        setShowTooltip(false);
        setForceShowTooltip(false);
    }, []);

    if (error) {
        return <ErrorMessage> failed evaluating field values</ErrorMessage>;
    }

    return (
        <LineChartContainer onMouseLeave={closeTooltip}>
            {!loading && <LineChart data={chartGraphData} options={chartOptions} chartType={configuredChartType} />}
            {showTooltip && (
                <PopoverTooltipElement
                    showTooltip={showTooltip}
                    closeTooltip={closeTooltip}
                    tooltipContent={tooltipContent}
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    tooltipTitle={tooltipTitle}
                    placement="bottom-end"
                    tooltipAbove={
                        configuredChartType === ChartDisplayType.BAR &&
                        tooltipType === FieldChartTooltipType.VALUE_CHANGE
                    }
                />
            )}
        </LineChartContainer>
    );
};
export default FieldChart;
