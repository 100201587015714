import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SecondaryTypeSidePaneCtrl($rootScope, $scope, projectManager, consts, utils) {
    const ctrl = this;
    $scope.pm = projectManager;
    const logicBlockTypes = consts.getLogicBlockTypes();

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        customTrigger: ctrl.customTrigger,
        allowChangeType: ctrl.allowChangeType,
        isMonitorTrack: ctrl.isMonitorTrack,
        isInnerAutonomous: ctrl.isInnerAutonomous,
        secondaryTypeToLogicConfigurationMapping: utils.createMapFromArray(
            utils.findAllInObj(
                logicBlockTypes,
                (blockType) => blockType.type === 'AUTONOMOUS' || blockType.type === 'MONITOR_TRACKS',
            ),
            'secondaryType',
        ),
    };

    /**
     * Occurs on changes to the component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.customTrigger && changesObj.customTrigger.currentValue) {
            // Loading custom trigger from cache.
            $scope.data.customTrigger = changesObj.customTrigger.currentValue;
            $scope.data.selectedLogicTypeConfiguration =
                $scope.data.secondaryTypeToLogicConfigurationMapping[
                    $scope.data.customTrigger.customTriggerSecondaryType
                ];
        }

        if (changesObj.isInnerAutonomous) {
            $scope.data.isInnerAutonomous = ctrl.isInnerAutonomous;
        }
    };

    $scope.changeAutonomousType = function (selectedType) {
        ctrl.onTypeChanged({ selectedType });
    };
}

export default angular
    .module('tonkean.app')
    .controller('SecondaryTypeSidePaneCtrl', lateConstructController(SecondaryTypeSidePaneCtrl));
