import React from 'react';
import { TonkeanAvatar as TrackAvatar } from '@tonkean/infrastructure';

const TrackFieldUserIcon = ({ user, toggleSetUserPopover, isCreatorOnlyValid }) => {
    return (
        <div className="relative tnk-tooltip mod-top">
            <div
                onClick={isCreatorOnlyValid ? toggleSetUserPopover : () => {}}
                className={`track-leader-avatar ${
                    isCreatorOnlyValid ? 'pointer svg-icon-hover-black' : ''
                } mod-border avatar-main img-circle avatar common-color-grey`}
            >
                <TrackAvatar owner={user} />
            </div>
            {isCreatorOnlyValid ? (
                <span className="tnk-tooltip-text track-col-external-tooltip">{user ? user.email : 'Add people'}</span>
            ) : (
                <span className="tnk-tooltip-text track-col-external-tooltip">
                    Only item creator can update this field
                </span>
            )}
        </div>
    );
};

/**
 * A components that renders the track list user icon
 */
export default TrackFieldUserIcon;
