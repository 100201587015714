import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Checkbox, useToggle } from '@tonkean/infrastructure';

const CheckBoxesWrapper = styled.div`
    display: inline-flex;
    gap: 12px;
`;

interface Props {
    onFilterChanged(isManageableShown: boolean, isViewShown: boolean, isInaccessibleShown: boolean): void;
}

const PersonRoleFilters: React.FC<Props> = ({ onFilterChanged }) => {
    const [isManageableShown, toggleIsManageableShown] = useToggle(true);
    const [isViewShown, toggleIsViewShown] = useToggle(true);
    const [isInaccessibleShown, toggleIsInaccessibleShown] = useToggle(false);

    useEffect(() => {
        onFilterChanged(isManageableShown, isViewShown, isInaccessibleShown);
    }, [onFilterChanged, isManageableShown, isViewShown, isInaccessibleShown]);

    return (
        <>
            <CheckBoxesWrapper>
                <Checkbox checked={isManageableShown} name="Manage" onChange={() => toggleIsManageableShown()}>
                    Manage
                </Checkbox>

                <Checkbox checked={isViewShown} name="View" onChange={() => toggleIsViewShown()}>
                    View
                </Checkbox>

                <Checkbox
                    checked={isInaccessibleShown}
                    name="Inaccessible"
                    onChange={() => toggleIsInaccessibleShown()}
                >
                    Inaccessible
                </Checkbox>
            </CheckBoxesWrapper>
        </>
    );
};

export default PersonRoleFilters;
