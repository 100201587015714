/**
 * Represents the integration storage provider settings.
 */
class StorageProviderSettings {
    constructor(
        /**
         * Whether the downloading method is implemented for the integration.
         */
        public supportsDownloading: boolean = false,

        /**
         * Whether the uploading method is implemented for the integration.
         */
        public supportsUploading: boolean = false,
    ) {}
}

export default StorageProviderSettings;
