import TrackFieldUser from './TrackFieldUser';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkTrackFieldUser',
        reactToAngular(TrackFieldUser, ['field', 'viewOnlyMode', 'anonymous'], ['personCache']),
    );
