import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as DataSourceSvg } from '../../images/data-source.svg';
import { ReactComponent as ManualSvg } from '../../images/solution-mapper-manual.svg';
import { ReactComponent as MoneySvg } from '../../images/solution-mapper-money.svg';
import { ReactComponent as TimeSvg } from '../../images/solution-mapper-time.svg';
import { ReactComponent as UserSvg } from '../../images/user.svg';
import operationConfigurationSectionConfig from '../entities/operationConfigurationSectionConfig';
import OperationConfigurationSections from '../entities/OperationConfigurationSections';
import isFilledAutomatedPart from '../utils/isFilledAutomatedPart';
import isFilledManualPart from '../utils/isFilledManualPart';

import type { OperationEdge, OperationNode } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const dataTypeToTextColor = {
    tools: operationConfigurationSectionConfig[OperationConfigurationSections.APPS].color,
    manual: operationConfigurationSectionConfig[OperationConfigurationSections.MANUAL].color,
    time: operationConfigurationSectionConfig[OperationConfigurationSections.COSTS].color,
    money: operationConfigurationSectionConfig[OperationConfigurationSections.COSTS].color,
    teams: '#6A6A6A',
} as const;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
`;
const DataItem = styled.div`
    margin: 0 25px;
    display: flex;
    align-items: center;
`;
const Data = styled.div<{ type: keyof typeof dataTypeToTextColor }>`
    margin-left: 8px;
    font-weight: normal;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 14px;
    color: ${({ type }) => dataTypeToTextColor[type]};
`;
const ToolsIcon = styled(DataSourceSvg)`
    height: 17px;
    width: 17px;

    path {
        fill: ${dataTypeToTextColor.tools};
    }
`;
const ManualIcon = styled(ManualSvg)`
    height: 17px;
    width: 17px;
`;
const TeamsIcon = styled(UserSvg)`
    height: 16px;
    width: 16px;
`;
const TimeIcon = styled(TimeSvg)`
    height: 14px;
    width: 14px;
`;
const MoneyIcon = styled(MoneySvg)`
    height: 17px;
    width: 17px;
`;

type DataTypeToValue = Record<keyof typeof dataTypeToTextColor, number | string>;

interface Props {
    nodes: OperationNode[];
    edges: OperationEdge[];
}

const SolutionMapperStats: React.FC<Props> = ({ nodes, edges }) => {
    const { tools, manual, teams, time, money } = useMemo<DataTypeToValue>(() => {
        const configurations = [...nodes, ...edges].map((entity) => entity.operationConfiguration);

        const toolsSet = new Set(configurations.flatMap((configuration) => configuration.apps));

        const filledManualParts = configurations
            .flatMap((configuration) => configuration.manualParts)
            .filter(isFilledManualPart);

        const manualCount = filledManualParts.length;
        const automatedCount = configurations
            .flatMap((configuration) => configuration.automatedParts)
            .filter(isFilledAutomatedPart).length;
        const total = manualCount + automatedCount;

        const teamsSet = new Set(filledManualParts.map((manualPart) => manualPart.department?.trim()).filter(Boolean));

        const durationDays = configurations.reduce((prev, curr) => prev + (curr.estimatedTime || 0), 0);
        const durationMonths = durationDays / 30;

        const price = configurations.reduce((prev, curr) => prev + (curr.estimatedCost || 0), 0);

        return {
            tools: toolsSet.size,
            manual: Math.round((manualCount * 100) / total || 0),
            teams: teamsSet.size,
            time: durationMonths > 2 ? `${Math.round(durationMonths)} months` : `${durationDays} days`,
            money: utils.numberWithCommas(price),
        };
    }, [edges, nodes]);

    return (
        <Wrapper>
            <DataItem>
                <ToolsIcon />
                <Data type="tools">{tools} Tools</Data>
            </DataItem>
            <DataItem>
                <ManualIcon />
                <Data type="manual">{manual}% is manual</Data>
            </DataItem>
            <DataItem>
                <TeamsIcon />
                <Data type="teams">{teams} teams</Data>
            </DataItem>
            <DataItem>
                <TimeIcon />
                <Data type="time">{time}</Data>
            </DataItem>
            <DataItem>
                <MoneyIcon />
                <Data type="money">{money}</Data>
            </DataItem>
        </Wrapper>
    );
};

export default SolutionMapperStats;
