import type { TypedDocumentNode } from '@urql/core';
import type { OptimisticMutationConfig } from '@urql/exchange-graphcache';
import type { IntrospectionQuery } from 'graphql';
import { Kind } from 'graphql';
import type { SelectionNode, SelectionSetNode } from 'graphql/language/ast';
import type { IntrospectionObjectType } from 'graphql/utilities/getIntrospectionQuery';

import { mockMutationField } from './addMockMutationForCacheUpdateToSchema';
import { getNonNullIntrospectionType } from './getTonkeanGraphqlCacheQueryResolversConfig';
import type {
    UpdateGraphqlCachedEntityVariables,
    UpdateGraphqlCachedEntityVariablesWithEntityName,
} from './updateGraphqlCachedEntity';

function getGraphqlCacheOptimisticConfigurationToUpdateEntityInCache(
    schema: IntrospectionQuery,
): OptimisticMutationConfig {
    const findObjectInSchema = (typeName: string) => {
        const currentType = schema.__schema.types.find(
            (type): type is IntrospectionObjectType => type.kind === 'OBJECT' && type.name === typeName,
        );
        if (!currentType) {
            throw new Error(`Not found type of ${typeName} in schema`);
        }
        return currentType;
    };

    const createFragment = (
        updateInformation: UpdateGraphqlCachedEntityVariablesWithEntityName<any>,
    ): TypedDocumentNode => {
        const createSelectionSet = (object: Record<string, any>, typeName: string): SelectionSetNode => {
            const currentType = findObjectInSchema(typeName);

            const selections = Object.entries(object).map(([key, value]): SelectionNode => {
                const fieldNodeWithoutSelectionSet = {
                    kind: Kind.FIELD,
                    name: { kind: Kind.NAME, value: key },
                } as const;

                const currentField = currentType.fields.find((field) => field.name === key);
                if (!currentField) {
                    throw new Error(`Not found field named ${key} in type ${currentType.name}`);
                }
                if (currentField.args.length) {
                    throw new Error(
                        `The field named ${key} in type ${currentType.name} has arguments, and therefor we can't generate fragment. Either pass fragment manually, or don't pass the field.`,
                    );
                }
                const currentFieldType = getNonNullIntrospectionType(currentField.type);

                if (currentFieldType.kind === 'LIST') {
                    if (!Array.isArray(value)) {
                        throw new TypeError(
                            `Field ${key} in type ${
                                currentType.name
                            } specified as an array in schema, but received ${typeof value}`,
                        );
                    }
                    const arrayFieldType = getNonNullIntrospectionType(currentFieldType.ofType);

                    if (arrayFieldType.kind === 'OBJECT') {
                        const arrayFieldTypeFull = findObjectInSchema(arrayFieldType.name);

                        const firstElement = value[0];
                        // If there is no elements in the array, we still need an object, otherwise it will be an
                        // invalid graphql, so we will create an object with one of the fields of the type as key.
                        const mockElement = { [arrayFieldTypeFull.fields[0]!.name]: null };

                        return {
                            ...fieldNodeWithoutSelectionSet,
                            selectionSet: createSelectionSet(firstElement ?? mockElement, arrayFieldType.name),
                        };
                    } else {
                        return fieldNodeWithoutSelectionSet;
                    }
                } else if (currentFieldType.kind === 'OBJECT') {
                    if (typeof value !== 'object') {
                        throw new TypeError(
                            `Field ${key} in type ${
                                currentType.name
                            } specified in schema as object but received ${typeof value}`,
                        );
                    }

                    return {
                        ...fieldNodeWithoutSelectionSet,
                        selectionSet: createSelectionSet(value, currentFieldType.name),
                    };
                } else {
                    return fieldNodeWithoutSelectionSet;
                }
            });

            return {
                kind: Kind.SELECTION_SET,
                selections,
            };
        };

        return {
            kind: Kind.DOCUMENT,
            definitions: [
                {
                    kind: Kind.FRAGMENT_DEFINITION,
                    name: { kind: Kind.NAME, value: '_' },
                    typeCondition: {
                        kind: Kind.NAMED_TYPE,
                        name: { kind: Kind.NAME, value: updateInformation.entityName },
                    },
                    selectionSet: createSelectionSet(updateInformation.object, updateInformation.entityName),
                },
            ],
        };
    };

    return {
        [mockMutationField.name]: (args, cache, info) => {
            const updateInformation = info.variables as unknown as UpdateGraphqlCachedEntityVariables<any>;

            const fragment = updateInformation.fragment ?? createFragment(updateInformation);
            cache.writeFragment(fragment, updateInformation.object);

            return [];
        },
    };
}

export default getGraphqlCacheOptimisticConfigurationToUpdateEntityInCache;
