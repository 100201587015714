import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';

import useLazyAsyncMethod, { type LazyAsyncMethodRequestState } from './asyncMethod/useLazyAsyncMethod';

import type { TonkeanService } from '@tonkean/shared-services';
import type { KeysThatExtend } from '@tonkean/utils';

/**
 * React hook for using the Tonkean service in a react way. It will execute the method when the trigger is called.
 * It cannot handle more than one request every time, so if you update a param while it's loading, it will stop the
 * previous request and start the new one.
 *
 * @example
 * const CustomTriggerNameGetter = () => {
 *     const [{data, error, loading, called}, callCustomTrigger] = useLazyTonkeanService('getCustomTrigger');
 *
 *     if (!called) {
 *         return <button onClick={() => callCustomTrigger(prompt('custom trigger id'))}>
 *             get custom trigger name
 *         </button>;
 *     }
 *     if (loading) {
 *         return <span className="loading" />;
 *     }
 *     if (error) {
 *         return <strong className="error">{ error.message }</strong>;
 *     }
 *     return <strong>{data.name}</strong>;
 * }
 *
 * @param methodName - the tonkean service method name to use
 * @returns an array, with the first value as object with `data` as the value if the method resolves, `error` as the reason if the
 * method rejects, `called` as a boolean indicating if the method has been triggered, `loading` as a boolean indicating
 * if the method is currently loading and `args` as the list of arguments passed to the method, and the second
 * value of the array is a trigger for the method which returns a promise, and it's params are the params to pass to
 * the method.
 */

function useLazyTonkeanService<E = any, T extends KeysThatExtend<TonkeanService> = any>(
    methodName: T,
): [
    LazyAsyncMethodRequestState<IHttpResponse<E>, TonkeanService[T]>,
    (...args: Parameters<TonkeanService[T]>) => ReturnType<TonkeanService[T]>,
    (args: Parameters<TonkeanService[T]>, changeStateOnLoading: boolean) => ReturnType<TonkeanService[T]>,
] {
    const tonkeanService = useAngularService('tonkeanService');

    return useLazyAsyncMethod(tonkeanService, methodName);
}

export default useLazyTonkeanService;
