<div class="monitor-tracks-configuration-logic">
    <!-- secondary type badge -->
    <tnk-secondary-type-side-pane
        group-id="data.groupId"
        workflow-version-id="data.workflowVersionId"
        custom-trigger="data.configuredLogic.node"
        is-monitor-track="true"
        is-inner-autonomous="false"
        on-type-changed="onMonitorItemTypeChanged(selectedType)"
    ></tnk-secondary-type-side-pane>

    <!-- Settings title -->
    <div
        ng-hide="pm.groupsMap[data.groupId].workerEnabled && !data.configuredLogic.disabled"
        class="autonomous-trigger-title common-bold common-size-xxxs padding-normal-xs margin-bottom margin-top"
    >
        Settings
    </div>

    <!-- On condition configuration -->
    <div ng-if="data.selectedMonitorItemsConditionType === 'ON_CONDITION'">
        <div class="margin-left-md margin-top">
            <!-- Conditions Title -->
            <div class="margin-bottom-xs">
                <!-- Action quantifier options dropdown -->
                <span uib-dropdown class="margin-left-xs">
                    <a uib-dropdown-toggle>
                        {{ data.selectedActionQuantifierOption.label }}
                        <span class="dropdown-chevron mod-static"></span>
                    </a>
                    <ul uib-dropdown-menu class="dropdown-menu">
                        <li ng-repeat="actionQuantifierOption in data.actionQuantifierOptions">
                            <a ng-click="onActionQuantifierOptionSelected(actionQuantifierOption)">
                                {{ actionQuantifierOption.label }}
                            </a>
                        </li>
                    </ul>
                </span>

                <!-- Of the action items title -->
                <span>of the action items:</span>
            </div>

            <!-- Conditions -->
            <tnk-custom-filters
                class="margin-right"
                display-integration-indication="true"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                hide-query-on-single-condition="false"
                hide-close-button="true"
                control="data.control"
                items-source="COLUMN"
                existing-definition="data.configuredLogic.node.queryDefinition"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                logic-id="data.configuredLogic.node.id"
                preview-evaluation-source="data.previewEvaluationSource"
                evaluate-preview="true"
                edit-mode="data.editMode"
                small-mod="true"
                hide-time-range-selection="true"
                bold-title="true"
                on-filters-change="onActionsChanged(true)"
                filters-change-only-after-init="true"
                special-fields-for-features="['CUSTOM_TRIGGER_CONDITIONS']"
                reload-fields-on-new-field-definition-creation="'true'"
            ></tnk-custom-filters>
        </div>
    </div>

    <div ng-if="data.allowSelectOnWhichItemToContinueTheFlow">
        <hr class="common-separator margin-bottom-md margin-top-md" />

        <!-- Continue the flow on what title -->
        <div class="margin-bottom common-bold">Continue the flow on:</div>

        <!-- Triggered action item -->
        <tnk-radio-button
            radio-id="triggered-action-item-{{ data.workflowVersionId }}"
            class="margin-top-xs"
            radio-name="run-on-context-selection-{{ data.workflowVersionId }}"
            radio-label="{{ 'Each action item' }}"
            ng-if="data.selectedActionQuantifierOption.id === 'any'"
            radio-value="TRIGGERED_ACTION_ITEM"
            model="data.selectedRunOnContext"
            is-required="true"
            on-click="onRunOnContextSelected(onClickParam)"
            on-click-param="('TRIGGERED_ACTION_ITEM')"
        ></tnk-radio-button>

        <!-- Parent item -->
        <tnk-radio-button
            radio-id="parent-item-{{ data.workflowVersionId }}"
            class="margin-top-xs"
            radio-name="run-on-context-selection-{{ data.workflowVersionId }}"
            radio-label="{{ 'Parent item' }}"
            radio-value="PARENT_ITEM"
            model="data.selectedRunOnContext"
            is-required="true"
            on-click="onRunOnContextSelected(onClickParam)"
            on-click-param="('PARENT_ITEM')"
        ></tnk-radio-button>

        <!-- Parent item selection -->
        <div ng-if="data.selectedRunOnContext === 'PARENT_ITEM'">
            <!-- Parent selection -->
            <ui-select
                theme="bootstrap"
                ng-change="onRunOnContextParentSelect(data.selectedRunOnContextParent)"
                ng-model="data.selectedRunOnContextParent"
            >
                <ui-select-match placeholder="Select parent...">
                    {{ data.selectedRunOnContextParent.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="parentItemOption in data.parentItemOptions | filter:$select.search">
                    <div ng-bind-html="parentItemOption.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <!-- Error finding parent -->
            <div class="common-size-xxxs common-color-danger" ng-if="data.runOnContextParentError">
                {{ data.runOnContextParentError }}
            </div>
        </div>
    </div>
</div>
