import React from 'react';
import styled from 'styled-components';

import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field, Placeholder } from '@tonkean/infrastructure';
import { useCacheFillForTonkeanExpression } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const SectionItem = styled(Field)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};

    tnk-tonkean-expression {
        width: 100%;
    }
`;

const MarginedPlaceholder = styled(Placeholder)`
    margin-bottom: 5px;
`;

/* This is the expression loader under the expression which is active during the expression evaluation*/
const ExpressionLoader: React.FC = () => (
    <>
        <MarginedPlaceholder $width="100%" $height="25px" $borderRadius="8px" />
        <MarginedPlaceholder $width="16%" $borderRadius="4px" />
    </>
);

interface Props extends DataAutomationSupportProps {
    label?: string;
    placeholder: string;
    name: string;
}

const ItemInterfaceWidgetUrlExpression: React.FC<Props> = ({ label, placeholder, name, dataAutomation }) => {
    const {
        itemInterface: { group, workflowVersionType, workflowVersionId },
        initiative,
    } = useItemInterfaceContext();
    const { loading } = useCacheFillForTonkeanExpression(group.id, workflowVersionType);

    return (
        <SectionItem label={label} light>
            <FormikTonkeanExpression
                placeholder={placeholder}
                name={name}
                groupId={group.id}
                workflowVersionId={workflowVersionId}
                overrideExampleInitiative={initiative?.id}
                dataAutomation={dataAutomation}
                includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                hideEditorButton
            />
        </SectionItem>
    );
};

export default ItemInterfaceWidgetUrlExpression;
