import { useMemo } from 'react';

import { useInitiativeValuesFromExpressionsQueries } from '@tonkean/infrastructure';
import { useTDLMatchConditions } from '@tonkean/tonkean-data-layer';
import {
    type InterfaceCTASavedAction,
    type MatchConditionExpression,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Initiative, ItemInterface, ItemInterfaceWidget } from '@tonkean/tonkean-entities';

enum ActionStateType {
    HIDDEN = 'hidden',
    DISABLED = 'disabled',
}

function attachActionStateTypeAsPrefixToId(parentEntityWithActionid: string, actionStateType: ActionStateType) {
    return `${actionStateType}-${parentEntityWithActionid}`;
}

function stripActionStateTypePrefixFromId(actionId: string) {
    const firstDashIndex = actionId.indexOf('-');
    const actionStateType = actionId.slice(0, Math.max(0, firstDashIndex));
    const parentEntityWithActionId = actionId.slice(Math.max(0, firstDashIndex + 1));

    return {
        actionStateType: actionStateType as ActionStateType,
        parentEntityWithActionId: parentEntityWithActionId,
    };
}
function getIdForStatelessCommand(actionId: string, actionParentEntityId: string, actionStateType: ActionStateType) {
    return attachActionStateTypeAsPrefixToId(`${actionParentEntityId}-${actionId}`, actionStateType);
}

function actionsToHiddenConditionsExpressions(
    actions: InterfaceCTASavedAction[] | undefined,
    actionsParentEntityId: string,
): MatchConditionExpression[] {
    return (actions || [])
        .filter((action) => action.buttonStates?.hidden?.active && action.buttonStates?.hidden?.conditions)
        .map((action) => {
            return {
                entityId: getIdForStatelessCommand(action.id, actionsParentEntityId, ActionStateType.HIDDEN),
                queryDefinition: action.buttonStates!.hidden!.conditions!,
            };
        });
}

function actionsToDisabledConditionsExpressions(
    actions: InterfaceCTASavedAction[] | undefined,
    actionsParentEntityId: string,
): MatchConditionExpression[] {
    return (actions || [])
        .filter((action) => action.buttonStates?.disabled?.active && action.buttonStates?.disabled?.conditions)
        .map((action) => {
            return {
                entityId: getIdForStatelessCommand(action.id, actionsParentEntityId, ActionStateType.DISABLED),
                queryDefinition: action.buttonStates!.disabled!.conditions!,
            };
        });
}

interface UseItemInterfaceActionsStateProps {
    initiative: Initiative | undefined;
    itemInterface: ItemInterface | undefined;
    widgets: ItemInterfaceWidget[];
}

interface UseItemInterfaceActionsStateResult {
    loadingActionsLiveDisplay: boolean;
    actionsDisabledLiveDisplay: Record<InterfaceCTASavedAction['id'], boolean>;
    actionsHiddenLiveDisplay: Record<InterfaceCTASavedAction['id'], boolean>;
}

function useItemInterfaceActionsState({
    initiative,
    itemInterface,
    widgets,
}: UseItemInterfaceActionsStateProps): UseItemInterfaceActionsStateResult {
    const widgetsWithActions = useMemo(() => {
        return widgets.filter((widget) => widget.configuration.actions && widget.configuration.actions.length > 0);
    }, [widgets]);

    const widgetsActionsHiddenConditionsExpressions = useMemo(() => {
        return widgetsWithActions.reduce((acc: MatchConditionExpression[], widget) => {
            const widgetConditionsExpressions = actionsToHiddenConditionsExpressions(
                widget.configuration.actions,
                widget.id,
            );
            return [...acc, ...widgetConditionsExpressions];
        }, []);
    }, [widgetsWithActions]);

    const widgetsActionsDisabledConditionsExpressions = useMemo(() => {
        return widgetsWithActions.reduce((acc: MatchConditionExpression[], widget) => {
            const widgetConditionsExpressions = actionsToDisabledConditionsExpressions(
                widget.configuration.actions,
                widget.id,
            );
            return [...acc, ...widgetConditionsExpressions];
        }, []);
    }, [widgetsWithActions]);

    const interfaceActionsHiddenConditionsExpressions = useMemo(() => {
        if (!itemInterface?.configuration?.actions) {
            return [];
        }

        return actionsToHiddenConditionsExpressions(itemInterface.configuration.actions, itemInterface.id);
    }, [itemInterface?.configuration?.actions, itemInterface?.id]);

    const interfaceActionsDisabledConditionsExpressions = useMemo(() => {
        if (!itemInterface?.configuration?.actions) {
            return [];
        }

        return actionsToDisabledConditionsExpressions(itemInterface.configuration.actions, itemInterface.id);
    }, [itemInterface?.configuration?.actions, itemInterface?.id]);

    const allExpressions = useMemo(() => {
        return [
            ...widgetsActionsHiddenConditionsExpressions,
            ...widgetsActionsDisabledConditionsExpressions,
            ...interfaceActionsHiddenConditionsExpressions,
            ...interfaceActionsDisabledConditionsExpressions,
        ];
    }, [
        widgetsActionsHiddenConditionsExpressions,
        widgetsActionsDisabledConditionsExpressions,
        interfaceActionsHiddenConditionsExpressions,
        interfaceActionsDisabledConditionsExpressions,
    ]);

    const allFieldsFromExpressions = useInitiativeValuesFromExpressionsQueries(initiative, allExpressions);

    const { data: actionsMatchedConditionsResponse, isLoading: loadingActionsMatchedConditions } =
        useTDLMatchConditions({
            fieldValues: allFieldsFromExpressions,
            matchInput: allExpressions,
            workflowVersionType: initiative?.isDraftInitiative
                ? WorkflowVersionType.DRAFT
                : WorkflowVersionType.PUBLISHED,
        });

    return useMemo(() => {
        const actionsDisabledLiveDisplay: Record<string, boolean> = {};
        const actionsHiddenLiveDisplay: Record<string, boolean> = {};

        if (!actionsMatchedConditionsResponse) {
            return {
                actionsDisabledLiveDisplay,
                actionsHiddenLiveDisplay,
                loadingActionsLiveDisplay: allExpressions.length > 0,
            };
        }

        for (const [expressionId, expressionResult] of Object.entries(actionsMatchedConditionsResponse)) {
            const { actionStateType, parentEntityWithActionId } = stripActionStateTypePrefixFromId(expressionId);

            switch (actionStateType) {
                case ActionStateType.DISABLED:
                    actionsDisabledLiveDisplay[parentEntityWithActionId] = expressionResult.matched;
                    break;
                case ActionStateType.HIDDEN:
                    actionsHiddenLiveDisplay[parentEntityWithActionId] = expressionResult.matched;
                    break;
            }
        }

        return {
            actionsDisabledLiveDisplay,
            actionsHiddenLiveDisplay,
            loadingActionsLiveDisplay: loadingActionsMatchedConditions,
        };
    }, [actionsMatchedConditionsResponse, allExpressions.length, loadingActionsMatchedConditions]);
}

export default useItemInterfaceActionsState;
