export const ON_BOARDING = {
    // States not shown to the user. Used mainly for analytics.
    hiddenSteps: {
        addBot: true,
        addIntegration: true,
        signup: true,
        importOrSync: true,
        addField: true,
        createTrack: true,
        assignOwner: true,
        invite: true,
        firstListCreated: true,
        firstNoGatherUpdate: true,
    },
    steps: {
        viewEducationTracks: {
            index: 1,
        },
        viewEducationData: {
            index: 2,
        },
        viewEducationBot: {
            index: 3,
        },
    },
};

export function getOnBoardingSteps() {
    return structuredClone(ON_BOARDING.steps);
}

export function getOnBoardingHiddenSteps() {
    return structuredClone(ON_BOARDING.hiddenSteps);
}
