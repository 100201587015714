/**
 * Returns users to map from tonkean user to the external users
 *
 * @param createProjectApis - angularJs service
 * @param comparePropertyKey {string} - the remote item name
 * @param projectIntegrationId {string} - project integration id
 * @param projectId {string} - project id
 * @param projectIntegrationType {string} = the type of the integration - e.g. jira
 * @returns {Promise<{users: T[], mappedUsersMap: *, mappedCount: number, totalCount: number}>} - object with mapped users map and a list of users to map
 */
export function getUsersToMap(
    createProjectApis,
    comparePropertyKey,
    projectIntegrationId,
    projectId,
    projectIntegrationType = '',
) {
    return Promise.all([
        createProjectApis.getProjectIntegrationUnMappedUsers(projectIntegrationId, projectIntegrationType),
        createProjectApis.getProjectIntegrationUserMapping(projectId, projectIntegrationId),
    ]).then(([unmapped, mapped]) => {
        const users = unmapped.users.map((user) => ({ ...user, people: [] }));

        mapped.users.forEach((mappedUser) => {
            const unmappedUser = users.find(
                (unmappedUser) => unmappedUser[comparePropertyKey] === mappedUser.comparePropertyKey,
            );

            if (unmappedUser) {
                unmappedUser.email = mappedUser.email;
                unmappedUser.saved = true;
            } else {
                users.push({ ...mappedUser, people: [], saved: true });
            }
        });

        return generateDataObject(users);
    });
}

/**
 * Match external users with tonkean users, based on email, actorName and the comparePropertyKey.
 * It will mutate the user object.
 *
 * @param requestPager - angularJs service
 * @param tonkeanService - angularJs service
 * @param projectManager - angularJs service
 * @param users {Object[]} - array with the remote users
 * @param comparePropertyKey {string} - the remote item name
 * @returns {Promise<void>} - a promise that when resolves marks the end of loading
 */
export function tryToMatchExisting(requestPager, tonkeanService, projectManager, users, comparePropertyKey) {
    return requestPager
        .getAllPages(
            tonkeanService.getTeamMembers,
            [projectManager.project.id, null, null],
            (data) => data.people,
            (data) => data.total,
        )
        .then((people) => {
            people?.forEach((person) => {
                let autoSuggested = false;
                users.forEach((user) => {
                    const personEmailIsUserEmail = person.email === user.email || person.emails?.includes(user.email);

                    const personNameIsUsercomparePropertyKey = person.name && person.name === user.comparePropertyKey;
                    const personNameIsUserName = person.name && person.name === user.actorName;
                    const personcomparePropertyKeyIsUsercomparePropertyKey =
                        person[comparePropertyKey] && person[comparePropertyKey] === user.comparePropertyKey;
                    const canAutoSuggest =
                        !autoSuggested &&
                        (personNameIsUsercomparePropertyKey ||
                            personNameIsUserName ||
                            personcomparePropertyKeyIsUsercomparePropertyKey);

                    if (personEmailIsUserEmail || canAutoSuggest) {
                        user.people = [person];
                        user.autoSuggestion = true;

                        // To prevent auto-suggesting the same tonkean user to multiple users, we store a variable
                        // indicating whether this user has been already suggested.
                        autoSuggested = false;
                    }
                });
            });
        });
}

/**
 * Generates a map with user's email as key and the user itself as a value, counts the users,
 * and counts the mapped users.
 *
 * @param users {T[]} - list of users
 * @returns {{users: T[], mappedUsersMap: any, mappedCount: number, totalCount: number}}
 */
export function generateDataObject(users) {
    const mappedUsersMap = Object.fromEntries(users.map((user) => [user.email, user]));
    const totalCount = users.length;
    const mappedCount = users.reduce((sum, user) => {
        if (user.saved) {
            return sum + 1;
        }
        return sum;
    }, 0);

    return {
        users,
        mappedUsersMap,
        totalCount,
        mappedCount,
    };
}
