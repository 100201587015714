import { useFormikContext } from 'formik';
import type React from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { FORMIK_AUTOSAVE_DEBOUNCE_MS_DEFAULT } from './FormikAutosaveConsts';

import { debouncer } from '@tonkean/utils';

interface Props {
    debounceMs?: number;
}

const FormikAutosave: React.FC<Props> = ({ debounceMs = FORMIK_AUTOSAVE_DEBOUNCE_MS_DEFAULT }) => {
    const debounce = useMemo(() => {
        return debouncer(debounceMs);
    }, [debounceMs]);

    const formik = useFormikContext();

    const submitFormRef = useRef(formik.submitForm);
    submitFormRef.current = formik.submitForm;

    const initialValueRef = useRef(formik.initialValues);
    initialValueRef.current = formik.initialValues;

    const lastAutoSubmittedValueRef = useRef(formik.values);

    useEffect(() => {
        // We want to enforce using eslint having formik values in the deps array so we included it in the condition.
        if (formik.values !== lastAutoSubmittedValueRef.current && formik.values !== initialValueRef.current) {
            debounce(() => {
                // We put this if to override previous debounces.
                if (!formik.isSubmitting) {
                    submitFormRef.current();
                    lastAutoSubmittedValueRef.current = formik.values;
                }
            });
        }
    }, [debounce, formik.isSubmitting, formik.values]);

    return null;
};

export default FormikAutosave;
