import template from './tnkMultipleInput.template.html?angularjs';

function tnkMultipleInput() {
    return {
        restrict: 'E',
        scope: {
            tags: '<',
            onTagsChanged: '<',
            disabled: '<',
        },
        template,
        controller: 'MultipleInputCtrl',
        controllerAs: '$ctrl',
    };
}

angular.module('tonkean.app').directive('tnkMultipleInput', tnkMultipleInput);
