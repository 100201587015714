import React from 'react';
import styled from 'styled-components';

import PeopleDirectoryRecipientsPreviewModal from '../PeopleDirectoryRecipientsPreview/PeopleDirectoryRecipientsPreviewModal';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Radio, RadioGroup, Spacer, useToggle } from '@tonkean/infrastructure';
import type { CustomTrigger, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { SendToGroupOfPeopleAs, SendToGroupOfPeopleAsDisplayName } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Card = styled.div`
    background-color: ${Theme.colors.gray_100};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    width: 100%;
    padding: 12px;
`;

const ChooseDirectoryTitle = styled.h4`
    padding: 0;
    margin: 0;
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Legend = styled.legend`
    padding: 0;
    margin: 0;
    border: none;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const Error = styled.p`
    margin: 0;
    padding: 0;
    color: ${Theme.colors.error};
    font-size: ${FontSize.SMALL_12};
`;

const PreviewRecipientsButton = styled(Clickable)`
    margin-top: 16px;
    color: ${Theme.colors.primary};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;

    &:hover,
    &:focus {
        color: ${Theme.colors.primary};
    }
`;

const FieldSet = styled.fieldset`
    margin-right: -12px;
`;

interface Props {
    peopleDirectoriesNameExpression: TonkeanExpressionDefinition;

    updatePeopleDirectoriesNameExpression(peopleDirectoriesNameExpression: TonkeanExpressionDefinition): void;

    sendToGroupOfPeopleAs: SendToGroupOfPeopleAs;

    updateSendToGroupOfPeopleAs(sendToGroupOfPeopleAs: SendToGroupOfPeopleAs): void;

    previewEvaluationSource: any;
    validationObject?: {
        peopleDirectoriesLength?: string;
    };

    customTrigger: CustomTrigger;
}

const PeopleDirectoryPicker: React.FC<Props> = ({
    peopleDirectoriesNameExpression,
    updatePeopleDirectoriesNameExpression,
    sendToGroupOfPeopleAs,
    previewEvaluationSource,
    updateSendToGroupOfPeopleAs,
    validationObject,
    customTrigger,
}) => {
    const [groupId] = useGetStateParams('g');
    const [previewRecipientsModalOpen, togglePreviewRecipientsModalOpen, setPreviewRecipientsModalOpen] =
        useToggle(false);

    return (
        <>
            <Spacer height={16} />
            <Card>
                <ChooseDirectoryTitle>Choose Business Groups</ChooseDirectoryTitle>
                <Spacer height={10} />
                {validationObject?.peopleDirectoriesLength && (
                    <Error>{validationObject?.peopleDirectoriesLength}</Error>
                )}
                <TonkeanExpression
                    groupId={groupId}
                    workflowVersionId={customTrigger.workflowVersion.id}
                    logicId={customTrigger.id}
                    savedOriginalExpression={peopleDirectoriesNameExpression.originalExpression}
                    savedEvaluatedExpression={peopleDirectoriesNameExpression.evaluatedExpression}
                    hidePreview={false}
                    onTonkeanExpressionChanged={(_, __, expression) => {
                        if (expression) {
                            updatePeopleDirectoriesNameExpression(expression);
                        }
                    }}
                    previewEvaluationSource={previewEvaluationSource}
                    placeholder="Name or expression, comma separated"
                    showPeopleDirectories
                />
                <Spacer height={18} />
                <FieldSet>
                    <Legend>Send message using:</Legend>
                    <Spacer height={10} />
                    <RadioGroup
                        value={sendToGroupOfPeopleAs}
                        onChange={(value: SendToGroupOfPeopleAs) => updateSendToGroupOfPeopleAs(value)}
                        size={InputSize.SMALL}
                        direction="column"
                    >
                        <Radio value={SendToGroupOfPeopleAs.PEOPLE_GROUP}>
                            {SendToGroupOfPeopleAsDisplayName.PEOPLE_GROUP}
                        </Radio>
                        <Radio value={SendToGroupOfPeopleAs.EACH_INDIVIDUAL}>
                            {SendToGroupOfPeopleAsDisplayName.EACH_INDIVIDUAL}
                        </Radio>
                    </RadioGroup>
                </FieldSet>
                <PreviewRecipientsButton onClick={togglePreviewRecipientsModalOpen}>
                    Preview Recipients
                </PreviewRecipientsButton>
            </Card>
            {previewRecipientsModalOpen && (
                <PeopleDirectoryRecipientsPreviewModal
                    onClose={() => setPreviewRecipientsModalOpen(false)}
                    evaluatedExpression={peopleDirectoriesNameExpression.evaluatedExpression}
                    customTrigger={customTrigger}
                    sendToGroupOfPeopleAs={sendToGroupOfPeopleAs}
                />
            )}
        </>
    );
};

export default PeopleDirectoryPicker;
