import React, { useMemo } from 'react';
import styled from 'styled-components';

import ChildEntitiesParameterSection from './ChildEntitiesParameterSection';
import SimpleActionParameterSection from './SimpleActionParameterSection';

import { useTimeoutService } from '@tonkean/infrastructure';
import type {
    ActionParameterExpression,
    BaseActionParameter,
    BasicQueryDefinition,
    ProjectIntegrationAction,
} from '@tonkean/tonkean-entities';
import { ActionParameterType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const IsRequiredErrorMessage = styled.div`
    color: ${Theme.colors.error};
    margin-bottom: 10px;
`;

const ParameterHeader = styled.div`
    margin-bottom: 10px;
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
`;

const NoParametersNotificationText = styled.div`
    padding: 10px;
    font-size: ${FontSize.SMALL_12};
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
`;
interface Props {
    /**
     * The group id this action is in.
     */
    groupId: string;

    /**
     * The version of the group this action is in.
     */
    workflowVersionId: string;

    /**
     * The preview evaluation source whether it is example item or worker run logic id
     */
    previewEvaluationSource: any;

    /**
     * The custom trigger id this action is defined in.
     */
    customTriggerId: string;

    /**
     * The definition of the current custom trigger.
     */
    definition: any;

    /**
     * The project integration action we base the configuration on.
     */
    projectIntegrationAction: ProjectIntegrationAction;

    /**
     * A callback to be called when ever the definition of the custom trigger is changed.
     * @param definition is the new definition we modified.
     * @param shouldSaveLogic whether we should save the logic or not when a change in definition has happened.
     */
    onDefinitionChange(definition: unknown, shouldSaveLogic: boolean): void;

    isEnableKeyValueParameters: boolean;

    doesHaveFieldListParams: boolean;

    validationLogicErrorMessage?: string;
    validationLogicErrorMessageChildEntity?: string;
    predefinedParameters: (BaseActionParameter & ActionParameterExpression)[];
}

const SimpleActionParameters: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    previewEvaluationSource,
    customTriggerId,
    definition,
    projectIntegrationAction,
    onDefinitionChange,
    isEnableKeyValueParameters,
    doesHaveFieldListParams,
    validationLogicErrorMessage,
    validationLogicErrorMessageChildEntity,
    predefinedParameters,
}) => {
    const childParametersKeys: string[] = useMemo(() => {
        return projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions.map(
            (childEntitiesDefinition) => childEntitiesDefinition.entityKey,
        );
    }, [projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions]);

    const childEntityKeyToParameters: Record<string, (BaseActionParameter & ActionParameterExpression)[]> =
        useMemo(() => {
            const childEntitiesParameterDefinitions =
                projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions;

            if (childEntitiesParameterDefinitions) {
                return childEntitiesParameterDefinitions.reduce(
                    (record, childDefinition) => {
                        let parameters: (BaseActionParameter & ActionParameterExpression)[] =
                            (childDefinition.parameters?.filter(
                                (parameter) => parameter.parameterType === ActionParameterType.TONKEAN_EXPRESSION,
                            ) as (BaseActionParameter & ActionParameterExpression)[]) ||
                            ([] as (BaseActionParameter & ActionParameterExpression)[]);

                        const projectIntegrationEntityId = childDefinition.entitiesToRunOn[0];

                        if (projectIntegrationEntityId) {
                            parameters = [
                                ...parameters,
                                ...predefinedParameters.filter(
                                    (parameter) => parameter.projectIntegrationEntityId === projectIntegrationEntityId,
                                ),
                            ];
                        }

                        record[childDefinition.entityKey] = parameters;
                        return record;
                    },
                    {} as Record<string, (BaseActionParameter & ActionParameterExpression)[]>,
                );
            }

            return {};
        }, [predefinedParameters, projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions]);

    const hasChildEntitiesEnabled = projectIntegrationAction.parametersDefinition.isChildEntitiesEnabled;

    const $timeout = useTimeoutService();

    const saveParameterExpressionValue = (
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean,
        actionParameter: BaseActionParameter,
    ) => {
        const newDefinition = {
            ...definition,
            parametersValues: {
                ...definition?.parametersValues,
                [actionParameter.id]: {
                    originalExpression,
                    evaluatedExpression,
                },
            },
        };

        $timeout(() => onDefinitionChange(newDefinition, shouldSaveLogic));
    };

    const saveChildParameterExpressionValue = (
        key: string,
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean,
        actionParameter: BaseActionParameter,
    ) => {
        const newDefinition = {
            ...definition,
            childParametersValues: {
                ...definition?.childParametersValues,
                [key]: {
                    ...definition?.childParametersValues?.[key],
                    [actionParameter.id]: {
                        originalExpression,
                        evaluatedExpression,
                    },
                },
            },
        };

        $timeout(() => onDefinitionChange(newDefinition, shouldSaveLogic));
    };

    const saveChildEntitiesFilters = (key: string, filter: BasicQueryDefinition) => {
        const newDefinition = {
            ...definition,
            innerItemsFilter: { ...definition.innerItemsFilters, [key]: { ...filter } },
        };

        $timeout(() => onDefinitionChange(newDefinition, true));
    };

    const simpleActionPredefinedParameters = useMemo(() => {
        return predefinedParameters.filter(
            (parameter) =>
                !parameter.projectIntegrationEntityId || // Simple Action Parameters do not contain projectIntegrationEntityId
                parameter.projectIntegrationEntityId ===
                    projectIntegrationAction.parametersDefinition.entitiesToRunOn?.[0], // Custom params should be included only if their projectIntegrationEntityId matches the entitiesToRunOn of the projectIntegrationAction
        );
    }, [predefinedParameters, projectIntegrationAction.parametersDefinition.entitiesToRunOn]);

    return (
        <>
            {simpleActionPredefinedParameters.length > 0 && <ParameterHeader>Parameters:</ParameterHeader>}

            {validationLogicErrorMessage && (
                <IsRequiredErrorMessage>{validationLogicErrorMessage}</IsRequiredErrorMessage>
            )}

            {simpleActionPredefinedParameters.map((singleActionParameter) => (
                <div key={singleActionParameter.id} data-automation="simple-action-parameter">
                    <SimpleActionParameterSection
                        singleActionParameter={singleActionParameter}
                        saveParameterExpressionValue={saveParameterExpressionValue}
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        customTriggerId={customTriggerId}
                        parametersValues={definition.parametersValues}
                        previewEvaluationSource={previewEvaluationSource}
                    />
                </div>
            ))}
            {hasChildEntitiesEnabled && (
                <>
                    {childParametersKeys.map((key) => {
                        const childParametersExist =
                            childEntityKeyToParameters[key]?.length && childEntityKeyToParameters[key]?.length > 0;

                        return (
                            childParametersExist && (
                                <div key={`${key}child_parameters`}>
                                    <ChildEntitiesParameterSection
                                        childEntityKey={key}
                                        childEntityParameters={childEntityKeyToParameters[key]!}
                                        saveChildParameterExpressionValue={saveChildParameterExpressionValue}
                                        groupId={groupId}
                                        workflowVersionId={workflowVersionId}
                                        customTriggerId={customTriggerId}
                                        innerItemsFilters={definition.innerItemsFilter?.[key]}
                                        previewEvaluationSource={previewEvaluationSource}
                                        saveChildEntitiesFilters={saveChildEntitiesFilters}
                                        childParametersValues={definition.childParametersValues?.[key]}
                                        validationLogicErrorMessageChildEntity={validationLogicErrorMessageChildEntity}
                                    />
                                </div>
                            )
                        );
                    })}
                </>
            )}

            {!predefinedParameters.length && !isEnableKeyValueParameters && !doesHaveFieldListParams && (
                <NoParametersNotificationText>
                    Notice that this action does not require any params.
                </NoParametersNotificationText>
            )}
        </>
    );
};

export default SimpleActionParameters;
