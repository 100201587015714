import React, { useMemo } from 'react';
import styled from 'styled-components';

import NlpBranchExpendableContent from './NlpBranchExpendableContent';
import { NlpActionStatusText } from './SingleWorkerRunActionInspectNlpStatus';
import TableChevronCell from './TableChevronCell';
import { ReactComponent as ModelIcon } from '../../../../../../images/icons/model.svg';

import { Table } from '@tonkean/infrastructure';
import type { TrainingSetModelSummary } from '@tonkean/tonkean-entities';
import type { TrainingSetNLPBranchResult } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { TableSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const Expendable = styled.div`
    background-color: ${Theme.colors.gray_100};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledModelIcon = styled(ModelIcon)`
    margin-right: 8px;
`;

interface Props {
    modelSummariesOfBranch: TrainingSetModelSummary[];
    branchResult?: TrainingSetNLPBranchResult;
}

const TrainingSetAnyOfTheRestExpendable: React.FC<Props> = ({ modelSummariesOfBranch, branchResult }) => {
    const modelIdToSummary = useMemo(() => {
        return utils.createMapFromArray(modelSummariesOfBranch, 'id');
    }, [modelSummariesOfBranch]);

    const rows = useMemo(() => {
        return (
            branchResult?.modelResults.map((modelResult) => {
                return {
                    chevron: <></>,
                    models: (
                        <Wrapper>
                            <StyledModelIcon />
                            {modelIdToSummary[modelResult.trainingSetModelId]?.displayName}
                        </Wrapper>
                    ),
                    accuracy: (
                        <Wrapper>
                            <NlpActionStatusText space>Score: </NlpActionStatusText>
                            <NlpActionStatusText success>
                                {(modelResult.resultEnrichment?.matchInformation?.score || 0) * 100}%
                            </NlpActionStatusText>
                        </Wrapper>
                    ),
                    expendable: modelResult.resultEnrichment?.matchInformation ? (
                        <NlpBranchExpendableContent matchedResult={modelResult.resultEnrichment.matchInformation} />
                    ) : undefined,
                };
            }) || []
        );
    }, [branchResult?.modelResults, modelIdToSummary]);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'chevron' as const,
                Cell: TableChevronCell,
            },
            {
                Header: `Match models (${branchResult?.modelResults.length || 0})`,
                accessor: 'models' as const,
                width: '40%',
            },
            {
                Header: 'Matching accuracy',
                accessor: 'accuracy' as const,
                width: '60%',
            },
        ],
        [branchResult?.modelResults.length],
    );

    return (
        <Expendable>
            <Table
                columns={columns}
                data={rows}
                showCellBorder={false}
                size={TableSize.SMALL}
                indentationLevel={2}
                expendableRows
            />
        </Expendable>
    );
};

export default TrainingSetAnyOfTheRestExpendable;
