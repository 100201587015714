import template from './tnkIntegrationSelection.template.html?angularjs';

/**
 * An integration selection component. Displays connected, disabled and not connected integrations.
 * Also displays an "other..." search control.
 */
export default angular.module('tonkean.app').component('tnkIntegrationSelection', {
    bindings: {
        currentIntegration: '<',
        integrationState: '<',
        integrationsSettings: '<',
        openModalForConfiguration: '<', // If true, will open a modal for configuration instead of setting it inline.
        notConnectedNativeSources: '<', // All sources which are native to Tonkean (excluding webhooks) and not connected. We load webhook sources internally in this component.
        resetOtherSearchBox: '<', // A flag passed from parents that tells the component it should reset the "other" search box.
        selectProvider: '&', // Expects 1 parameter: projectIntegration.
        onIntegrationSaved: '&', // Expects 1 parameter: overrideState.
        showImportCsv: '<', // When true, a button for importing from csv will appear first.
        disableImportIntegrations: '<', // When false, external integrations will appear
        onImportCsvClicked: '&', // A function that will be called if the user clicks on "Import from A CSV file".
        // Fires when currentIntegration changes. Expects 1 parameter: currentIntegration.
        // Notice that we don't deep watch the current integration, so the function only fires once the reference changes.
        // This lets users get updated if currentIntegration is reset to null or changes to another integration.
        onCurrentIntegrationChanged: '&',
        onSelectedBrokenIntegration: '&', // Called before moving to the integrations page when selected a broken integration
        configuredInGroupId: '@', // If given, that means integration is configured in a certain workflow version
        configuredInWorkflowVersionId: '@', // If given, that means integration is configured in a certain workflow version
        displayOnlyNotConnectedNativeSourcesInSearch: '<', // If true, will only display the not connected source in search. Otherwise, will display all native integration sources.
        openNewInModal: '<', // If true, will open the add integration in modal
        onlyIntegrationsOfTypes: '<', // Optional array of project integrations types that will be shown, if doesnt exist will show all
        hideAddButton: '<', // If true will hide the option to add a datasource
        // Some components use this component in order to create fields, take actions based on the integration's entities.
        // If an integration has no entities (for example SendGrid) we want to hide it in order to prevent possible bug.
        hideIntegrationsWithNoEntities: '<',
    },
    template,
    controller: 'IntegrationSelectionCtrl',
});
