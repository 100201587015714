import { lateConstructController } from '@tonkean/angular-components';
import { InitiativeStatus } from "@tonkean/tonkean-entities";

/* @ngInject */
function postStatusUpdateLogicConfigurationCtrl(
    $rootScope,
    $scope,
    projectManager,
    customTriggerManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,

        invalidLogics: ctrl.invalidLogics,

        // Logic state
        stateConfiguration: {
            updateToStatus: null,
            shouldSendNotificationOnStatusUpdate: false,
            externalStatusValue: null,
            statusUpdaterPersonSelectionConfiguration: {},
            existingStatusUpdaterPersonSelectionConfiguration: {},

            whenFinishedUpdateText: null,
            evaluatedWhenFinishedUpdateText: null,
            applyStatusUpdateToInnerItems: false,
            stateUpdaterFieldsToUpdate: null
        },
        viewOnlyMode: ctrl.viewOnlyMode,
        workingOn: ctrl.workingOn,
        showFieldsToUpdate: ctrl.configuredLogic.node.customTriggerType !== 'MANUAL_FIELD_UPDATE' &&
          ctrl.configuredLogic.node.customTriggerSecondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED',
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            onConfiguredLogicChange();
        }
        if (changesObj.viewOnlyMode) {
            $scope.data.viewOnlyMode = changesObj.viewOnlyMode.currentValue;
        }
        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
    };

    /**
     * Updates the state id of the custom trigger.
     */
    $scope.logicUpdateToStateUpdated = function (stateConfiguration) {
        ctrl.onPostStatusChanged({
            shouldSaveLogic: true,
            stateConfiguration,
        });
    };

    /**
     * Occurs on tonkean expression changed.
     */
    $scope.onTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        expressionIdentifier,
        shouldSaveLogic,
    ) {
        $scope.data.stateConfiguration.whenFinishedUpdateText = originalExpression;
        $scope.data.stateConfiguration.evaluatedWhenFinishedUpdateText = evaluatedExpression;

        if (shouldSaveLogic) {
            $scope.logicUpdateToStateUpdated($scope.data.stateConfiguration);
        }
    };

    // Clear the update status.
    $scope.clearSelectedStatus = function () {
        $scope.data.stateConfiguration.whenFinishedUpdateText = '';
        $scope.data.stateConfiguration.evaluatedWhenFinishedUpdateText = '';
        $scope.data.stateConfiguration.updateToStatus = null;
        $scope.data.stateConfiguration.applyStatusUpdateToInnerItems = false;
        $scope.data.stateConfiguration.stateUpdaterFieldsToUpdate = null;
        $scope.logicUpdateToStateUpdated($scope.data.stateConfiguration);
    };

    $scope.onStatusUpdaterPersonSelectionConfigurationChanged = function (
        personSelectionConfiguration,
        shouldSaveLogic,
    ) {
        $scope.data.stateConfiguration.statusUpdaterPersonSelectionConfiguration = personSelectionConfiguration;

        if (shouldSaveLogic) {
            $scope.logicUpdateToStateUpdated($scope.data.stateConfiguration);
        }
    };

    $scope.onConfiguredFieldsChanged = function(configuredFields, shouldSaveLogic) {
        $scope.data.stateConfiguration.stateUpdaterFieldsToUpdate = configuredFields;

        if (shouldSaveLogic) {
            $scope.logicUpdateToStateUpdated($scope.data.stateConfiguration);
        }
    }

    /** @type {{item: State}} */
    $scope.groupByType = function (item) {
      return item.type === InitiativeStatus.INTAKE ? "Intake" : "Triage And Coordination"
    }

    function onConfiguredLogicChange() {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            $scope.data.workflowVersionId,
            ctrl.configuredLogic.node.id,
        );
        const stateConfiguration = {};
        stateConfiguration.updateToStatus = workflowVersionManager.getCachedWorkflowVersion(
            $scope.data.workflowVersionId,
        ).stateIdToStateMap[customTrigger.stateId];
        stateConfiguration.applyStatusUpdateToInnerItems = !!customTrigger.stateUpdaterInnerItemsStateId;
        stateConfiguration.stateUpdaterFieldsToUpdate = customTrigger.stateUpdaterFieldsToUpdate;
        stateConfiguration.whenFinishedUpdateText = customTrigger.updateText;
        stateConfiguration.evaluatedWhenFinishedUpdateText = customTrigger.evaluatedUpdateText;
        stateConfiguration.externalStatusValue = customTrigger.externalStatusValue;
        stateConfiguration.shouldSendNotificationOnStatusUpdate = !customTrigger.stateUpdateSkipNotification;
        stateConfiguration.existingStatusUpdaterPersonSelectionConfiguration = {
            initiativeOwner: customTrigger.stateUpdaterIsOwner,
            previousActor: customTrigger.stateUpdaterIsPreviousActor,
            specificPeopleIds: customTrigger.stateUpdaterPersonId ? [customTrigger.stateUpdaterPersonId] : [],
            personEmailFieldDefinitionId: customTrigger.stateUpdaterFieldDefinitionId,
            personEmailExpressionDefinition: customTrigger.stateUpdaterExpressionDefinition,
            useNone: true, // Use none as default.
            failIfNoValue: customTrigger.failIfNoValue,
            isTonkeanRootUser: customTrigger.stateUpdaterIsTonkean,
        };
        $scope.data.stateConfiguration = stateConfiguration;
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'postStatusUpdateLogicConfigurationCtrl',
        lateConstructController(postStatusUpdateLogicConfigurationCtrl),
    );
