import React, { useEffect, useState } from 'react';

import EmbedWidgetDisplay from './EmbedWidgetDisplay';
import { useSolutionSiteContext } from '../../../hooks';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type EmbedWidgetConfiguration from '../EmbedWidgetConfiguration';
import { useEmbedWidgetContentExpression } from '../useEmbedWidgetContentExpression';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const SolutionSitePageEmbedWidget: React.FC<SolutionSitePageWidgetProps<EmbedWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');

    const { solutionSite } = useSolutionSiteContext();

    const expressionsToLoad = useEmbedWidgetContentExpression(widget.configuration.embed);

    const [
        { data: expressions, loading: loadingExpressions, error: urlExpressionError },
        getEvaluatedExpressionForProject,
    ] = useLazyTonkeanService('getEvaluatedExpressionForProject');

    const [embedContent, setEmbedContent] = useState<string | undefined>();
    useEffect(() => {
        const expression = expressionsToLoad[0];
        if (expression) {
            getEvaluatedExpressionForProject(
                solutionSite.projectId,
                entityVersionType || WorkflowVersionType.PUBLISHED,
                [
                    {
                        key: utils.guid(),
                        expression: expression?.evaluatedExpression,
                    },
                ],
            ).then((data) => {
                if (data?.evaluatedExpressions) {
                    const expressionValues = Object.values(data?.evaluatedExpressions);
                    setEmbedContent(expressionValues?.[0]);
                }
            });
        }
    }, [entityVersionType, expressionsToLoad, getEvaluatedExpressionForProject, solutionSite.projectId]);

    return (
        <EmbedWidgetDisplay
            permission={permission}
            embedType={widget.configuration.embed.type}
            loadingExpressions={loadingExpressions}
            height={widget.configuration?.embed.height}
            embedContent={embedContent}
            contentConfigured={!!expressionsToLoad[0]?.originalExpression}
            hideWidgetBackground={widget.configuration.hideWidgetBackground}
        />
    );
};

export default SolutionSitePageEmbedWidget;
