<div>
    <!-- Monitor thread message title and content -->
    <div class="margin-top-lg flex">
        <div
            class="flex-grow margin-right"
            uib-tooltip="Can't monitor replies if replying to a thread, try monitoring the original message."
            tooltip-append-to-body="true"
            tooltip-enable="data.channelOrPersonSelectionConfiguration.targetType === 'thread'"
        >
            <span class="common-title-xxs margin-right">Monitor thread messages</span>
            <i ng-if="data.monitorRepliesIsLoading" class="loading"></i>
            <p class="common-size-xxs common-color-grey3">
                For each new reply on this thread, you will be able to perform additional actions.
            </p>
        </div>

        <!-- Monitor thread messages toggle -->
        <tnk-toggle
            is-active="data.monitorRepliesOnOriginalMessage.isActive"
            size="sm"
            toggle-disabled="data.monitorRepliesIsLoading"
            on-toggle-click="onMonitorRepliesChanged(isActive)"
        ></tnk-toggle>
    </div>

    <!-- Storage for attachments select dropdown -->
    <div ng-if="data.monitorRepliesOnOriginalMessage.isActive">
        <div>Where to store the replies attachments?</div>
        <ui-select
            on-select="attachmentStorageSelected()"
            class="margin-bottom-xs"
            theme="bootstrap"
            ng-model="data.selectedStorage"
        >
            <ui-select-match placeholder="Select data storage">{{ data.selectedStorage.displayName }}</ui-select-match>
            <ui-select-choices repeat="storage in data.storageOptions | filter:$select.search">
                <div ng-bind-html="storage.displayName | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
    </div>
</div>
