import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import type FieldDateRangeValidation from '../entities/FieldDateRangeValidation';
import type { FieldDateRangeValidationParams } from '../entities/FieldDateRangeValidation';

import {
    DatepickerInput,
    Field,
    H4,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    withModal,
} from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const FieldsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`;

const isDateRangeValid = (min: Date | undefined, max: Date | undefined) => {
    const minDate = min ? new Date(min) : undefined;
    const maxDate = max ? new Date(max) : undefined;

    return minDate !== undefined && maxDate !== undefined ? maxDate >= minDate : true;
};

const dateRangeValidationModalSchema = Yup.object({
    min: Yup.date(),
    max: Yup.date(),
});

const StyledDatepickerInput = styled(DatepickerInput)`
    width: 100%;
`;

interface Props {
    onSubmit(params: FieldDateRangeValidationParams);
    initialValues: FieldDateRangeValidation | undefined;
}

const LineItemsDateRangeValidationModal: React.FC<Props> = ({ onSubmit, initialValues }) => {
    const DEFAULT_MIN_DATE = 'January 1, 1900';
    const DEFAULT_MAX_DATE = 'December 31, 2100';

    const params = {
        min: new Date(DEFAULT_MIN_DATE),
        max: new Date(DEFAULT_MAX_DATE),
    };

    return (
        <Formik
            onSubmit={(values) => {
                onSubmit({ min: values.params.min, max: values.params.max });
            }}
            validationSchema={dateRangeValidationModalSchema}
            initialValues={!!initialValues ? initialValues : { params }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <ModalForm>
                        <ModalHeader>Set Date Range</ModalHeader>
                        <ModalBody>
                            <FieldsWrapper>
                                <Field
                                    label={
                                        <H4 $color={Theme.colors.gray_700} $bold>
                                            Select Starting Date
                                        </H4>
                                    }
                                >
                                    <StyledDatepickerInput
                                        name="params.min"
                                        maxDate={values?.params.max}
                                        placeholderText="mm/dd/yyyy"
                                    />
                                </Field>

                                <Field
                                    label={
                                        <H4 $color={Theme.colors.gray_700} $bold>
                                            Select End Date
                                        </H4>
                                    }
                                >
                                    <StyledDatepickerInput
                                        name="params.max"
                                        minDate={values?.params.min}
                                        placeholderText="mm/dd/yyyy"
                                    />
                                </Field>
                            </FieldsWrapper>
                        </ModalBody>

                        <ModalFooterActions
                            saveButtonProps={{ highlighted: true }}
                            saveDisabled={!isDateRangeValid(values.params.min, values.params.max)}
                            error={
                                !isDateRangeValid(values.params.min, values.params.max)
                                    ? 'Starting date must be earlier than end date'
                                    : undefined
                            }
                            cancelButtonProps={{ highlighted: true, outlined: true, cancel: false }}
                            border
                        />
                    </ModalForm>
                );
            }}
        </Formik>
    );
};

export default withModal(LineItemsDateRangeValidationModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
});
