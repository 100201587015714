import { useAngularService } from 'angulareact';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import useGetLoadedFieldDefinitionById from '../../hooks/useGetLoadedFieldDefinitionById';

import { useAsyncMethod, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { UpdateField } from '@tonkean/fields';
import { LoadingCircle, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};

    line-height: 40px; // This is so that when the input loads in there wont be a UI jump
`;

const StyledUpdateField = styled(UpdateField)`
    min-width: 85px;
`;

interface Props {
    workflowVersion: WorkflowVersion;
    fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
    associatedInitiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    editableFieldLabel: string | undefined;
}

const SearchWidgetResultItemEditableField: React.FC<Props> = ({
    workflowVersion,
    fieldDefinitionId,
    associatedInitiativeId,
    editableFieldLabel,
}) => {
    const customFieldManager = useAngularService('customFieldsManager');
    const {
        data: fieldDefinition,
        loading: loadingFieldDefinition,
        error: errorFieldDefinition,
    } = useAsyncMethod(customFieldManager, 'getFieldDefinitionById', workflowVersion.id, fieldDefinitionId);

    const trackHelper = useAngularService('trackHelper');
    const {
        data: associatedInitiative,
        loading: loadingInitiative,
        error: errorInitiative,
    } = useAsyncMethod(trackHelper, 'getInitiativeById', associatedInitiativeId);

    const [{ loading: loadingUpdateField, error: errorUpdateField }, updateFieldValueNew] =
        useLazyTonkeanService('updateFieldValueNew');

    const $timeout = useAngularService('$timeout');
    const initiativeCache = useAngularService('initiativeCache');

    const getLoadedFieldDefinitionById = useGetLoadedFieldDefinitionById(workflowVersion?.id);

    const onValueSave = useCallback(
        async (newValue: string) => {
            const newInitiative = await updateFieldValueNew(
                workflowVersion.id,
                associatedInitiativeId,
                fieldDefinitionId,
                newValue,
                false,
                '',
            );

            $timeout(() => {
                initiativeCache.removeInitiativeFromCache(newInitiative.id);
                trackHelper.cacheTrack(newInitiative);
                trackHelper.getRelatedInitiativesCount(newInitiative.project.id, [newInitiative.id]);
            });
        },
        [
            $timeout,
            associatedInitiativeId,
            fieldDefinitionId,
            initiativeCache,
            trackHelper,
            updateFieldValueNew,
            workflowVersion.id,
        ],
    );

    const loading = loadingInitiative || loadingUpdateField || loadingFieldDefinition;
    const error = errorInitiative || errorUpdateField || errorFieldDefinition;

    return (
        <Container>
            <Label>{editableFieldLabel}</Label>
            {associatedInitiative && fieldDefinition && (
                <StyledUpdateField
                    workflowVersion={workflowVersion}
                    fieldDefinition={fieldDefinition}
                    onValueSave={onValueSave}
                    projectId={workflowVersion.project.id}
                    groupId={workflowVersion.groupId}
                    initiative={associatedInitiative}
                    fieldsAreBeingEdited={false}
                    showEmptyFieldWhenNoInitiative={false}
                    isSubmittingForm={false}
                    getLoadedFieldDefinitionById={getLoadedFieldDefinitionById}
                    revealByDefault
                />
            )}
            {loading && <LoadingCircle thin />}
            {error && <SimpleErrorStateMessage error={error} showSmallError />}
        </Container>
    );
};

export default SearchWidgetResultItemEditableField;
