import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import type { SuggestedSectionConfig } from './SuggestedSectionConfig';
import SuggestedSectionConfigEditor from './SuggestedSectionConfigEditor';
import createMarketplaceItemTemplateNameFromDisplayName from '../../../../getTemplateNameFromModuleName';
import { useSolutionMarketplaceItemCreateModal } from '../../../MarketplaceItemCreateModalContext';

import { Field, Input, useFormikArrayField, useFormikField } from '@tonkean/infrastructure';
import type { IntegrationType, ProcessParticipantSummary } from '@tonkean/tonkean-entities';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalSolutionSectionsSection: React.FC = () => {
    const { existingMarketplaceItem, groups, workflowFolderCategories, workflowFolderCategoryProcessParticipants } =
        useSolutionMarketplaceItemCreateModal();
    const { value: configSections, setValue: setConfigurationSections } = useFormikField<SuggestedSectionConfig[]>(
        'configuration.solutionOutline.sections',
    );

    const { value: currentSections, remove: removeSection } = useFormikArrayField<SuggestedSectionConfig[]>(
        `configuration.solutionOutline.sections`,
    );

    const sections: SuggestedSectionConfig[] = useMemo(() => {
        let value: SuggestedSectionConfig[] = [];
        if (existingMarketplaceItem) {
            value =
                existingMarketplaceItem.configuration.solutionOutline?.sections.map((section) => ({
                    ...section,
                    outcomeDefinition: section.outcomeDefinition ?? null,
                })) || [];
        } else if (workflowFolderCategories && groups) {
            value = workflowFolderCategories.map((category) => {
                const processParticipants =
                    workflowFolderCategoryProcessParticipants?.find(
                        (entity) => entity.workflowFolderCategoryId === category.id,
                    )?.processParticipants || [];

                const templateProcessParticipants: ProcessParticipantSummary[] = processParticipants.map(
                    (participant) => ({
                        roleInProcess: participant.roleInProcess,
                        systemBeingUsed: participant.systemBeingUsed,
                        index: participant.index,
                    }),
                );

                const categoryModules = groups.filter((group) => group.workflowFolderCategoryId === category.id);

                const suggestedModules = categoryModules
                    .map((module) => ({
                        label: module.name,
                        templateName: createMarketplaceItemTemplateNameFromDisplayName(module.name),
                        suggestedDataSources:
                            module.originalGroupProjectIntegrations?.map((projectIntegration) => ({
                                integrationType: projectIntegration.integrationType as IntegrationType,
                                publicMarketplaceTemplateName: projectIntegration.publicMarketplaceTemplateName,
                                publicMarketplaceVersion: projectIntegration.publicMarketplaceVersion,
                            })) || [],
                        isEmbedded: false,
                    }))
                    .sort((groupA, groupB) => (groupA.label > groupB.label ? 1 : -1));

                return {
                    title: category.displayName,
                    description: category.description,
                    outcomeDefinition: category.outcomeDefinition || [],
                    suggestedModules,
                    processParticipants: templateProcessParticipants,
                };
            });
        }

        return value;
    }, [existingMarketplaceItem, groups, workflowFolderCategories, workflowFolderCategoryProcessParticipants]);

    // Set initial values for sections for formik
    useEffect(() => {
        if (!configSections) {
            setConfigurationSections(sections);
        }
    }, [configSections, sections, setConfigurationSections]);

    return (
        <>
            <StyledField
                label="Solution Outline Title"
                informationTooltip="The title for the suggested sections and modules in the blueprint page"
            >
                <Input
                    name="configuration.solutionOutline.title"
                    data-automation="marketplace-item-create-modal-solution-outline-title"
                />
            </StyledField>
            {currentSections?.map((section, index) => (
                <SuggestedSectionConfigEditor
                    key={index}
                    section={section}
                    sectionIndex={index}
                    onRemove={() => removeSection(index)}
                    isLast={currentSections.length === 1}
                />
            ))}
        </>
    );
};

export default MarketplaceItemCreateModalSolutionSectionsSection;
