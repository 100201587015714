import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

function useToastMessage(timeout?: number) {
    const $rootScope = useAngularService('$rootScope');
    const $timeout = useAngularService('$timeout');

    const emit = useCallback(
        (message: string, type: 'danger' | 'warning' | 'success' | 'info' = 'danger') => {
            $timeout(() => {
                $rootScope.$emit('alert', {
                    type,
                    msg: message,
                    ...(timeout ? { timeout } : {}),
                });
            });
        },
        [$rootScope, $timeout, timeout],
    );

    return emit;
}

export default useToastMessage;
