import { analyticsWrapper } from '@tonkean/analytics';
import { RATING_TITLES } from '@tonkean/constants';

function ActivityPopupCtrl(
    $scope,
    $rootScope,
    $localStorage,
    $timeout,
    $q,
    projectManager,
    tonkeanService,
    requestSimpleDebouncer,
    authenticationService,
    utils,
    modal,
    communicationIntegrationsService,
    requestThrottler,
) {
    $scope.activityTypes = [
        'NEW_INITIATIVE',
        'INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED',
        'NEW_COMMENT',
        'NEW_ACTIVITY_COMMENT',
        'INITIATIVE_DESCRIPTION_CHANGED',
    ];

    let projectIdLoaded;
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.modal = modal;
    $scope.cis = communicationIntegrationsService;
    $scope.activities = [];
    $scope.popover = {
        isOpen: false,
    };

    $scope.data = {
        activityFirstTimePopoverAlreadyRead: false,
        filter: {
            simplifiedInitiative: null,
        },
    };

    $scope.ratingTitles = RATING_TITLES;

    if (!$localStorage.lastActivityWatch) {
        $localStorage.lastActivityWatch = {};
    }

    $rootScope.$on('newActivityUpdate', function () {
        $scope.loadActivity();
    });

    $rootScope.$on('newIntegrationAdded', function () {
        $scope.loadActivity();
    });

    $rootScope.$on('$stateChangeStart', function () {
        $scope.popover.isOpen = false;
    });

    // When user is hovering an item(if the side pane is open) it will filter the feed by the initiative.
    $scope.$watch('pm.currentlyViewedSimplifiedInitiative', () => {
        const currentlyViewedSimplifiedInitiative = $scope.pm.currentlyViewedSimplifiedInitiative;
        // Only filter if initiative has changed
        if (
            $scope.isVisible &&
            currentlyViewedSimplifiedInitiative &&
            (!$scope.data.filter.simplifiedInitiative ||
                $scope.data.filter.simplifiedInitiative.id !== currentlyViewedSimplifiedInitiative.id)
        ) {
            $scope.onFilterByInitiative(currentlyViewedSimplifiedInitiative, true);
        }
    });

    $scope.$watch('isVisible', function () {
        $scope.data.communicationIntegration = $scope.cis.getFirstThatSupports($scope.cis.features.notifications);

        onIsOpenChanged();
    });

    function onIsOpenChanged() {
        // In sidepane mode we rely on isVisible while in mobile we rely on isOpen
        const isOpen = $scope.popover.isOpen || $scope.isVisible;
        if (isOpen && !$scope.loadingActivity) {
            const xMinAgo = new Date();
            xMinAgo.setMinutes(xMinAgo.getMinutes() - 2);

            $scope.openedOnce = true;

            let shouldReloadChangedInitiative = false;
            // clear filter on initiative on open.
            if ($scope.data.filter.simplifiedInitiative && $scope.data.filter.simplifiedInitiative.id) {
                $scope.data.filter.simplifiedInitiative = {};
                shouldReloadChangedInitiative = true;
            }

            analyticsWrapper.track('Open', { category: 'Activity popup' });

            if (!$scope.lastLoad || xMinAgo >= $scope.lastLoad || shouldReloadChangedInitiative) {
                $scope.loadActivity();
            }
        }
    }

    $scope.$watch('popover.isOpen', function () {
        onIsOpenChanged();
    });

    /**
     * Init function to run as soon as the controller is up and running.
     */
    $scope.init = function () {
        $scope.data.activityFirstTimePopoverAlreadyRead =
            $scope.as.currentUser.metadata.activityFirstTimePopoverAlreadyRead;

        // Updating the metadata that the activity first time popover was already read.
        $scope.as.currentUser.metadata.activityFirstTimePopoverAlreadyRead = true;
        // Clearing the last activity watch time, in case available, to avoid updating the last activity watch time
        // on the server with many projects.
        $scope.as.currentUser.metadata.lastActivityWatch = {};
        tonkeanService.updateProfileMetadata($scope.as.currentUser.metadata);

        // Updating the variable the UI uses in delay so the tip will stay open for a while.
        $timeout(() => {
            $scope.data.activityFirstTimePopoverAlreadyRead = true;
        }, 5000);
    };

    $scope.loadActivity = function () {
        if ($scope.pm.project && $scope.pm.getProjectToken()) {
            projectIdLoaded = $scope.pm.project.id;
            $scope.loadingActivity = true;
            $scope.lastLoad = new Date();
            const initiativeIdToFilter = $scope.data.filter.simplifiedInitiative
                ? $scope.data.filter.simplifiedInitiative.id
                : null;
            // tonkeanService.getProjectActivity($scope.pm.project.id, 20, $scope.activityTypes, null, null, initiativeIdToFilter)
            requestSimpleDebouncer
                .debounce(
                    'loadActivity-popup',
                    tonkeanService.getProjectActivity,
                    $scope.pm.project.id,
                    20,
                    $scope.activityTypes,
                    null,
                    null,
                    initiativeIdToFilter,
                    null,
                    null,
                    null,
                    false,
                    null,
                )
                .then(function (data) {
                    if (data && data.activity) {
                        $scope.activities = utils.aggregateActivityItems(data.activity);

                        const today = new Date();
                        today.setHours(0);
                        today.setMinutes(0);
                        const yesterday = new Date(today);
                        yesterday.setDate(yesterday.getDate() - 1);

                        const todayActivity = [];
                        const yesterdayActivity = [];
                        const olderActivity = [];

                        angular.forEach($scope.activities, function (item) {
                            if (item.created > today.getTime()) {
                                todayActivity.push(item);
                            } else if (item.created > yesterday.getTime()) {
                                yesterdayActivity.push(item);
                            } else {
                                olderActivity.push(item);
                            }
                        });

                        $scope.todayActivity = todayActivity;
                        $scope.yesterdayActivity = yesterdayActivity;
                        $scope.olderActivity = olderActivity;
                    }
                })
                .catch(function (error) {
                    $scope.error = error;
                    // todo: show error
                })
                .finally(function () {
                    $scope.loadingActivity = false;
                });
        }
    };

    $scope.handleClick = function () {
        $scope.popover.isOpen = false;
    };

    /**
     * Filter for selected initiative.
     */
    $scope.onFilterByInitiative = function (selectedSimplifiedInitiative, isInit) {
        if (
            !$scope.data.filter.simplifiedInitiative ||
            $scope.data.filter.simplifiedInitiative.id !== selectedSimplifiedInitiative.id
        ) {
            $scope.data.filter.simplifiedInitiative = selectedSimplifiedInitiative;
            requestThrottler.do('filterActivityFeed', 200, () => {
                $scope.loadActivity();
                return $q.resolve($scope.data.filter);
            });

            if (!isInit) {
                $scope.data.initiativePopoverIsOpen = false;
            }
        }
    };

    $scope.clearSelectedInitiative = function () {
        $scope.data.filter.simplifiedInitiative = {};
    };

    $scope.init();
}

angular.module('tonkean.app').controller('ActivityPopupCtrl', ActivityPopupCtrl);
