import React from 'react';
import styled from 'styled-components';

import type FieldChartWidgetConfiguration from './FieldChartWidgetConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';

import {
    ButtonRadioGroup,
    ButtonRadioGroupButton,
    ChartDataType,
    ChartDisplayType,
    Spacer,
} from '@tonkean/infrastructure';
import { LineIcon } from '@tonkean/svg';
import { BarIcon } from '@tonkean/svg';
import { StepLineIcon } from '@tonkean/svg';
import { PieIcon } from '@tonkean/svg';
import { DoughnutIcon } from '@tonkean/svg';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    align-content: center;
`;

const ButtonLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const ButtonLabel = styled.div`
    font-weight: 700;
    line-height: 16px;
    font-size: ${FontSize.SMALL_12};
`;

const ChartTypeContainer = styled.div`
    svg,
    path {
        height: 16px;
        width: 16px;
    }
`;

const ChartTypeButtonGroup = styled(ButtonRadioGroup)`
    width: 100%;
`;
const ToggleWrapper = styled.div`
    font-weight: 400;
    padding: 0px 16px 16px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    margin-bottom: 16px;
`;
const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    margin: 12px 0px;
`;
const Description = styled.span`
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
`;

interface Props {
    configuration: FieldChartWidgetConfiguration;
}

const FieldChartDataTypeEditorControllers: React.FC<Props> = ({ configuration }) => {
    return (
        <ToggleWrapper>
            <ConfigurationSectionTitle>Chart</ConfigurationSectionTitle>

            <Label>Data Type</Label>
            <ChartTypeButtonGroup size={InputSize.LARGE} name="configuration.chartDataType">
                <ButtonRadioGroupButton
                    dataAutomation="item-builder-widget-configuration-header-chart-data-type-categorical"
                    value={ChartDataType.CATEGORICAL}
                    highlighted
                    outlined
                >
                    <Container>
                        <ButtonLabelContainer>
                            <ButtonLabel>Categorical</ButtonLabel>
                            <Description>Compare groups of values</Description>
                        </ButtonLabelContainer>
                    </Container>
                </ButtonRadioGroupButton>
                <ButtonRadioGroupButton
                    dataAutomation="item-builder-widget-configuration-header-chart-data-type-historical"
                    value={ChartDataType.HISTORICAL}
                    highlighted
                    outlined
                >
                    <Container>
                        <ButtonLabelContainer>
                            <ButtonLabel>Historical</ButtonLabel>
                            <Description>Compare values over time</Description>
                        </ButtonLabelContainer>
                    </Container>
                </ButtonRadioGroupButton>
            </ChartTypeButtonGroup>

            <Label>Display</Label>
            {configuration.chartDataType === ChartDataType.HISTORICAL && (
                <ChartTypeButtonGroup size={InputSize.MEDIUM} name="configuration.chartHistoricalDisplayType">
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-historical-display-type-line"
                        value={ChartDisplayType.LINE}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <LineIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Line</ButtonLabel>
                    </ButtonRadioGroupButton>
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-historical-display-type-bar"
                        value={ChartDisplayType.BAR}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <BarIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Bar</ButtonLabel>
                    </ButtonRadioGroupButton>
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-historical-display-type-step"
                        value={ChartDisplayType.STEP}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <StepLineIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Step</ButtonLabel>
                    </ButtonRadioGroupButton>
                </ChartTypeButtonGroup>
            )}
            {configuration.chartDataType === ChartDataType.CATEGORICAL && (
                <ChartTypeButtonGroup size={InputSize.MEDIUM} name="configuration.chartCategoricalDisplayType">
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-categorical-display-type-pie"
                        value={ChartDisplayType.PIE}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <PieIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Pie</ButtonLabel>
                    </ButtonRadioGroupButton>
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-categorical-display-type-bars"
                        value={ChartDisplayType.BAR}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <BarIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Bar</ButtonLabel>
                    </ButtonRadioGroupButton>
                    <ButtonRadioGroupButton
                        dataAutomation="item-builder-chart-widget-configuration-categorical-display-type-doughnut"
                        value={ChartDisplayType.DOUGHNUT}
                        highlighted
                        outlined
                    >
                        <Spacer height={5} />
                        <ChartTypeContainer>
                            <DoughnutIcon />
                        </ChartTypeContainer>
                        <ButtonLabel>Doughnut</ButtonLabel>
                    </ButtonRadioGroupButton>
                </ChartTypeButtonGroup>
            )}
        </ToggleWrapper>
    );
};

export default FieldChartDataTypeEditorControllers;
