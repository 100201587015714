/**
 * Created by ShaLi on 26/01/2016.
 */

function PeopleSettingsCtrl(
    $scope,
    $rootScope,
    $state,
    tonkeanService,
    utils,
    inviteManager,
    projectManager,
    authenticationService,
) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;

    if (!$scope.pm.isOwner) {
        $state.go('product.enterpriseComponents', { tab: 'communication-sources' });
        return;
    }

    $scope.lps = $rootScope.lps;
    $scope.disableInProgress = {};
    $scope.inviteInProgress = {};
    $scope.sortField = 'NAME';
    $scope.sortDesc = false;
    $scope.currentPage = 1;
    $scope.pageSize = 10;
    $scope.people = [];
    $scope.owners = [];
    $scope.supportUsers = [];
    $scope.loadingPeople = false;
    $scope.peopleSearch = '';
    $scope.projectDomains = $scope.pm.project.domains;
    $scope.defaultActor = $scope.pm.project.defaultActor || $scope.as.currentUser;
    $scope.loadingSavingDefaultActor = false;
    $scope.errorLoadingSavingDefaultActor = false;

    $scope.data = {};
    $scope.init = function () {
        $scope.getTeamMembersPage();
        $scope.data.allowOwnersNotInTeam = $scope.pm.project.metadata
            ? !$scope.pm.project.metadata.blockOwnersNotInTeam
            : true;
        $scope.data.allowViewOnlyAccess = $scope.pm.project.metadata
            ? $scope.pm.project.metadata.allowViewOnlyAccess
            : false;

        $scope.data.allowPublishersManagement = $scope.pm.project.metadata.allowPublishersManagement;

        $scope.data.isOnlyAdminAllowToCreateSolution = $scope.pm.project.isOnlyAdminAllowToCreateSolution || false;
    };

    $scope.$watch('pm.isOwner', function () {
        if (!$scope.pm.isOwner) {
            $state.go('product');
        }
    });

    $scope.$watch('peopleSearch', function () {
        $scope.currentPage = 1;
        $scope.searchTotal = 0;
        $scope.people = [];
        $scope.getTeamMembersPage();
    });

    $scope.$watchGroup(['currentPage', 'sortField', 'sortDesc'], function () {
        $scope.getTeamMembersPage();
    });

    /**
     * Occurs on default actor selected.
     */
    $scope.onDefaultActorSelected = function (person) {
        if (person !== null) {
            $scope.defaultActor = person;
            $scope.loadingSavingDefaultActor = true;
            $scope.errorLoadingSavingDefaultActor = false;
            tonkeanService
                .updateProjectDefaultActor($scope.pm.project.id, person.id)
                .then((projectManager.project.defaultActor = person))
                .catch(() => {
                    $scope.errorLoadingSavingDefaultActor = true;
                })
                .finally(() => {
                    $scope.loadingSavingDefaultActor = false;
                });
        }
    };

    $scope.sort = function (sortBy) {
        if ($scope.sortField === sortBy) {
            $scope.sortDesc = !$scope.sortDesc;
        } else {
            $scope.sortField = sortBy;
        }
    };

    $scope.getTeamMembersPage = function () {
        const pageIndex = $scope.currentPage - 1;
        const skip = $scope.pageSize + (pageIndex - 1) * $scope.pageSize;
        if (!$scope.loadingPeople && pageIndex !== -1 && (skip === 0 || skip < $scope.totalPeople)) {
            $scope.loadingPeople = true;
            const q = $scope.peopleSearch;
            const options = {
                includeDisabled: $scope.data.includeDisabled,
                sortField: $scope.sortField,
                sortDesc: $scope.sortDesc,
            };
            tonkeanService
                .getTeamMembers($scope.pm.project.id, options, q, $scope.pageSize, skip)
                .then(function (data) {
                    if (q === $scope.peopleSearch) {
                        $scope.people = data.people;
                    }
                    if (!q) {
                        $scope.totalPeople = data.total;
                    }
                    $scope.searchTotal = data.total;
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loadingPeople = false;
                    if (q !== $scope.peopleSearch) {
                        $scope.getTeamMembersPage();
                    }
                });
        }
    };

    $scope.invitePerson = function (person) {
        if (!$scope.inviteInProgress[person.id]) {
            $scope.inviteInProgress[person.id] = true;
            inviteManager
                .sendInvites($scope.pm.project.id, [person], null, null, null, true)
                .catch(function (error) {
                    $scope.$emit(error);
                })
                .finally(function () {
                    $scope.inviteInProgress[person.id] = false;
                });
        }
    };

    $scope.toggleDisablePerson = function (person) {
        if (!$scope.disableInProgress[person.id]) {
            $scope.disableInProgress[person.id] = true;
            const value = !person.isDisabledInProject;
            tonkeanService
                .disablePersonInProject($scope.pm.project.id, person.id, value)
                .then(function () {
                    person.isDisabledInProject = value;
                    $scope.getTeamMembersPage();
                })
                .catch(function (error) {
                    $scope.$emit('alert', error);
                })
                .finally(function () {
                    $scope.disableInProgress[person.id] = false;
                });
        }
    };

    $scope.isOwner = function (person) {
        if ($scope.pm.project && $scope.pm.project.owners) {
            for (let i = 0; i < $scope.pm.project.owners.length; i++) {
                const owner = $scope.pm.project.owners[i];
                if (owner.id === person.id) {
                    return true;
                }
            }
        }
        return false;
    };

    $scope.editOwners = function () {
        $scope.owners = angular.copy($scope.pm.project.owners);
        $scope.supportUsers = $scope.owners.filter((person) => person.systemUtilized);
        $scope.owners = $scope.owners.filter((person) => !person.systemUtilized);
        $scope.showEditOwners = true;
    };

    $scope.addOwner = function (person) {
        $scope.owners = angular.copy($scope.pm.project.owners);
        $scope.owners.push(person);
        $scope.supportUsers = $scope.owners.filter((person) => person.systemUtilized);
        $scope.owners = $scope.owners.filter((person) => !person.systemUtilized);
        $scope.saveOwners();
    };

    $scope.saveOwners = function (force) {
        $scope.owners = $scope.owners.concat($scope.supportUsers);
        if ($scope.ownersForm.$valid && !$scope.savingOwners) {
            const saveAnyway = force || utils.findFirstById($scope.owners, $scope.as.currentUser.id);
            if (saveAnyway) {
                $scope.savingOwners = true;
                $scope.pm
                    .saveOwners($scope.owners)
                    .then(function () {
                        $scope.showEditOwners = false;
                        $scope.$emit('alert', {
                            msg: 'Saved!',
                            type: 'success',
                        });
                    })
                    .catch(function (error) {
                        $scope.$emit('alert', error);
                    })
                    .finally(function () {
                        $scope.savingOwners = false;
                    });
            } else {
                $scope.mboxData = {
                    title: 'Are you sure?',
                    body: 'You are about to remove yourself as an owner of this team.',
                    okLabel: 'Remove myself',
                    cancelLabel: 'Take me back',
                };

                $rootScope.modal
                    .openMessageBox({
                        scope: $scope,
                        size: 'sm',
                        windowClass: 'mod-danger',
                    })
                    .result.then(function () {
                        $scope.saveOwners(true);
                    });
            }
        }
    };

    $scope.updateProjectDomains = function (domains) {
        tonkeanService.updateProjectDomains($scope.pm.project.id, domains);
    };

    $scope.updateAllowOwnersNotInTeam = function () {
        const project = $scope.pm.project;
        const metadata = project.metadata || {};
        metadata.blockOwnersNotInTeam = !$scope.data.allowOwnersNotInTeam;
        tonkeanService
            .updateProjectMetadataJson(project.id, metadata)
            .then(function (data) {
                // Update PM object
                angular.extend(project, data);
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            });
    };

    /**
     * Updates the metadata of the project with the option of whether allowing management
     * of solution publishers in the solutions of the current project
     */
    $scope.updateAllowPublishersManagement = function () {
        const project = $scope.pm.project;
        const metadata = project.metadata || {};
        metadata.allowPublishersManagement = $scope.data.allowPublishersManagement;

        tonkeanService
            .updateProjectMetadataJson(project.id, metadata)
            .then(function (data) {
                // Update PM object
                $scope.$emit('alert', {
                    msg: 'Solution Publish setting was updated successfully!',
                    type: 'success',
                });
                angular.extend(project, data);
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            });
    };

    $scope.updateAllowViewOnlyAccess = function () {
        const project = $scope.pm.project;
        const metadata = project.metadata || {};
        metadata.allowViewOnlyAccess = $scope.data.allowViewOnlyAccess;
        tonkeanService
            .updateProjectMetadataJson(project.id, metadata)
            .then(function (data) {
                // Update PM object
                angular.extend(project, data);
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            });
    };

    /**
     * Updates the metadata of the project with the option of whether allowing only admins
     * to create Solutions.
     */
    $scope.updateAllowBoardAdminsToCreateSolutions = function () {
        const project = $scope.pm.project;
        const isAllowed = $scope.data.isOnlyAdminAllowToCreateSolution;

        tonkeanService
            .updateProjectIsAllowedOnlyAdminsToCreateSolution(project.id, isAllowed)
            .then(function (data) {
                $scope.$emit('alert', {
                    msg: 'Solution Create setting was updated successfully!',
                    type: 'success',
                });

                project.isOnlyAdminAllowToCreateSolution = isAllowed;
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('PeopleSettingsCtrl', PeopleSettingsCtrl);
