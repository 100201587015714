import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import PredefinedConnectionCrudModal from './PredefinedConnectionCrudModal';
import PredefinedConnectionsTableView from './PredefinedConnectionsTableView';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { Checkbox, SavingIndicator, SearchBox, useToggle } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const SearchAndAddButtonWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
`;

const CheckboxWrapper = styled.span`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const Wrapper = styled.div`
    max-width: 1150px;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-top: 20px;

    ${childrenStyledFocus}
`;

const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 10px;
`;

const AddConnectionButton = styled(Button)`
    margin-left: 15px;
`;

interface Props {
    setCurrentDataSourceConnection: React.Dispatch<React.SetStateAction<DataSourceConnection>>;
}

const PredefinedConnections: React.FC<Props> = ({ setCurrentDataSourceConnection }) => {
    const [dataSourceConnectionId] = useGetStateParams<[string]>('dataSourceConnectionId');
    const [searchValue, setSearchValue] = useState('');
    const [isCrudModalOpen, setIsCrudModalOpen] = useState(false);
    const project = useProject();

    const [{ data: dataSourceConnection, loading: isLoadingDataSourceConnection }, getDataSourceConnectionById] =
        useLazyTonkeanService('getDataSourceConnectionById');

    const [isOnlyPredefinedConnections, toggleIsOnlyPredefinedConnections, setIsOnlyPredefinedConnections] =
        useToggle(false);

    useEffect(() => {
        if (dataSourceConnection?.allowOnlySharedCredentials) {
            setIsOnlyPredefinedConnections(true);
        } else {
            setIsOnlyPredefinedConnections(false);
        }
    }, [dataSourceConnection?.allowOnlySharedCredentials, setIsOnlyPredefinedConnections]);

    useEffect(() => {
        if (dataSourceConnection) {
            setCurrentDataSourceConnection(dataSourceConnection);
        }
    }, [dataSourceConnection, setCurrentDataSourceConnection]);

    // callback to request the latest predefined connections.
    const onPredefinedConnectionUpdated = useCallback(() => {
        getDataSourceConnectionById(project.id, dataSourceConnectionId);
    }, [getDataSourceConnectionById, project.id, dataSourceConnectionId]);

    // Initialize data source connections
    useEffect(() => {
        getDataSourceConnectionById(project.id, dataSourceConnectionId);
    }, [getDataSourceConnectionById, project.id, dataSourceConnectionId]);

    const [{ error, loading }, updateDataSourceConnection] = useLazyTonkeanService('updateDataSourceConnection');

    // On toggle the Checkbox isOnlyPredefinedConnections.
    const onToggleIsOnlyPredefinedConnections = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setIsOnlyPredefinedConnections(event.target.checked);

            if (dataSourceConnection) {
                await updateDataSourceConnection(
                    project.id,
                    dataSourceConnection.id,
                    event.target.checked,
                    dataSourceConnection.enabled,
                    dataSourceConnection.connectionPermission,
                    dataSourceConnection.authorizedEntitiesIds,
                );
            }
        } catch {
            toggleIsOnlyPredefinedConnections();
        }
    };

    return (
        <Wrapper>
            <CheckboxWrapper>
                <span>
                    <StyledCheckbox
                        checked={isOnlyPredefinedConnections}
                        onChange={onToggleIsOnlyPredefinedConnections}
                        disabled={loading || isLoadingDataSourceConnection}
                        boldLabel
                    >
                        Only allow predefined connections
                    </StyledCheckbox>

                    <SavingIndicator loading={loading} error={error} />
                </span>
            </CheckboxWrapper>

            <SearchAndAddButtonWrapper>
                <SearchBox
                    value={searchValue}
                    onChange={({ target: { value } }) => setSearchValue(value)}
                    placeholder="Search predefined connections"
                />

                <AddConnectionButton
                    data-automation="predefined-connections-add-predefined-connection"
                    onClick={() => setIsCrudModalOpen(true)}
                    size={ButtonSize.SMALL}
                    outlined
                >
                    + Add Predefined Connection
                </AddConnectionButton>
            </SearchAndAddButtonWrapper>

            <PredefinedConnectionsTableView
                isLoadingDataSourceConnection={isLoadingDataSourceConnection}
                dataSourceConnection={dataSourceConnection}
                filterByTextValue={searchValue}
                onPredefinedConnectionUpdated={onPredefinedConnectionUpdated}
            />

            {dataSourceConnection && isCrudModalOpen && (
                <PredefinedConnectionCrudModal
                    isOpen={isCrudModalOpen}
                    onClose={() => setIsCrudModalOpen(false)}
                    onPredefinedConnectionUpdated={onPredefinedConnectionUpdated}
                    dataSourceConnection={dataSourceConnection}
                    integrationType={dataSourceConnection.dataSourceType}
                    isCreate
                />
            )}
        </Wrapper>
    );
};

export default PredefinedConnections;
