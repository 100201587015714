import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { LogicConfigurationTitle } from '@tonkean/logic-configuration';
import type { LogicConfigurationProps } from '@tonkean/logic-configuration';
import { TrashIcon as TrashNewIcon } from '@tonkean/svg';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const LineItemTitle = styled(LogicConfigurationTitle)`
    font-size: ${FontSize.MEDIUM_14};
`;

const LineItemContainer = styled.div`
    background: ${Theme.colors.HEX_F2F2F2};
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid ${Theme.current.palette.general.border};
`;

const LineItem = styled.div`
    margin-top: 10px;
`;

const RequiredSign = styled.span`
    color: ${Theme.colors.error};
    margin-left: 3px;
`;

const RemoveLineItem = styled.span`
    float: right;
    margin-top: -6px;
`;

const RequiredAsterisk: React.FC = () => (
    <Tooltip content="Required Field">
        <RequiredSign>*</RequiredSign>
    </Tooltip>
);

const AddLineItemButton = styled(ClickableLink)`
    margin-top: 20px;
`;

interface LineItemObject {
    item: string;
    quantity: string;
    amount: string;
    rate: string;
    description: string;
}

const NetsuiteAddPurchaseOrderCustomActionConfiguration: React.FC<LogicConfigurationProps> = ({
    groupId,
    workflowVersionId,
    definition,
    previewEvaluationSource,
    configuredLogic,
    onDefinitionChanged,
}) => {
    const lineItem: LineItemObject = useMemo(
        () => ({ item: '', quantity: '', amount: '', rate: '', description: '' }),
        [],
    );

    if (!definition.items) {
        definition.items = [lineItem];
    }

    const addLineItem = useCallback(() => {
        const itemsArray: LineItemObject[] = [...definition.items, lineItem];
        definition.items = itemsArray;
        onDefinitionChanged(true);
    }, [definition, lineItem, onDefinitionChanged]);

    const removeLineItem = useCallback(
        (itemIndex) => {
            definition.items.splice(itemIndex, 1);
            onDefinitionChanged(true);
        },
        [definition, onDefinitionChanged],
    );

    const onEntityChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.entityExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onTransactionDateChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.trandateExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onCurrencyChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.currencyExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onFormChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.customFormExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onLocationChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.locationExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onEmailChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.emailExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onMemoChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.memoExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onLineItemIdChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].itemExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemQuantityChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].quantityExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemAmountChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].amountExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemRateChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].rateExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemDescriptionChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].descriptionExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    return (
        <>
            <LogicConfigurationTitle>
                Vendor ID
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.entityExpression?.originalExpression}
                savedEvaluatedExpression={definition.entityExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onEntityChanged}
                placeholder="Vendor ID"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>
                Date
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.trandateExpression?.originalExpression}
                savedEvaluatedExpression={definition.trandateExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onTransactionDateChanged}
                placeholder="Transaction Date"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>
                Currency ID
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.currencyExpression?.originalExpression}
                savedEvaluatedExpression={definition.currencyExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onCurrencyChanged}
                placeholder="Currency"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>
                Form ID
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.customFormExpression?.originalExpression}
                savedEvaluatedExpression={definition.customFormExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onFormChanged}
                placeholder="Form ID"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>
                Location ID
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.locationExpression?.originalExpression}
                savedEvaluatedExpression={definition.locationExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onLocationChanged}
                placeholder="Location ID"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>Employee Email</LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.emailExpression?.originalExpression}
                savedEvaluatedExpression={definition.emailExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onEmailChanged}
                placeholder="Email"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>Memo</LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.memoExpression?.originalExpression}
                savedEvaluatedExpression={definition.memoExpression?.evaluatedExpression}
                onTonkeanExpressionChanged={onMemoChanged}
                placeholder="Memo"
                textAreaNumberOfRows={2}
                showFormulasTab
                modTextArea
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <hr />

            <LogicConfigurationTitle size="large">Line Items</LogicConfigurationTitle>
            {definition.items.map((item, itemIndex) => (
                <LineItemContainer key={itemIndex}>
                    <LineItem>
                        <LineItemTitle>
                            Item ID
                            <RequiredAsterisk />
                            <RemoveLineItem>
                                <IconButton onClick={() => removeLineItem(itemIndex)} flat>
                                    <TrashNewIcon />
                                </IconButton>
                            </RemoveLineItem>
                        </LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].itemExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].itemExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemIdChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Line Item ID"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>
                            Quantity
                            <RequiredAsterisk />
                        </LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].quantityExpression?.originalExpression}
                            savedEvaluatedExpression={
                                definition.items[itemIndex].quantityExpression?.evaluatedExpression
                            }
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemQuantityChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Quantity"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>
                            Amount
                            <RequiredAsterisk />
                        </LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].amountExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].amountExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemAmountChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Amount"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Rate</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].rateExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].rateExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemRateChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Rate"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Description</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={
                                definition.items[itemIndex].descriptionExpression?.originalExpression
                            }
                            savedEvaluatedExpression={
                                definition.items[itemIndex].descriptionExpression?.evaluatedExpression
                            }
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemDescriptionChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Description"
                            textAreaNumberOfRows={2}
                            showFormulasTab
                            modTextArea
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>
                </LineItemContainer>
            ))}
            <AddLineItemButton onClick={() => addLineItem()}>+ Add Line Item</AddLineItemButton>
        </>
    );
};

export default NetsuiteAddPurchaseOrderCustomActionConfiguration;
