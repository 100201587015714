<div
    class="display-table initiatives-page"
    ng-if="
        (!pm.project.isLimitedLicense ||
            pm.freeMembershipGroupId === groupId ||
            data.stateFilter ||
            filter !== 'all') &&
        !(!pm.groups || !pm.groups.length || !(pm.groups | filter: { dashboardHidden: false }).length)
    "
>
    <!-- Header -->
    <div
        class="initiatives-header"
        id="initiatives-header"
        ng-class="{ 'mod-full': !showProjectFilter }"
        ng-hide="!pm.groups || !pm.groups.length || !(pm.groups | filter: { dashboardHidden: false }).length"
    >
        <div
            class="initiatives-header-inner margin-left flex-vmiddle mod-justify-end-xs flex-xs-wrap"
            ng-class="{ 'mod-show-inner-project-filter': showProjectInnerFilter }"
        >
            <!-- Collapse panel -->
            <div class="hidden-xs margin-right">
                <div
                    class="btn btn-no-border btn-sm"
                    ng-click="$root.showProjectFilter = !$root.showProjectFilter; data.isNavToggleTooltipOpen = false;"
                    uib-tooltip="{{ $root.showProjectFilter ? 'Collapse menu' : 'Expand menu' }}"
                    tooltip-is-open="data.isNavToggleTooltipOpen"
                    tooltip-placement="bottom-left"
                >
                    <div ng-if="$root.showProjectFilter" class="svg-icon">
                        <tnk-icon src="/images/icons/arrow-left-double.svg"></tnk-icon>
                    </div>

                    <div ng-if="!$root.showProjectFilter" class="svg-icon">
                        <tnk-icon src="/images/icons/arrow-right-double.svg"></tnk-icon>
                    </div>
                </div>
            </div>

            <!-- Group/Function/Filter title -->
            <div
                class="initiatives-list-title capital-first-letter show-edit-when-hover flex-no-shrink margin-right"
                ng-class="{ 'mod-fixed-on-mobile': !filterVisiblity.showInnerBar }"
            >
                <div ng-switch="filter" ng-hide="data.editTitle">
                    <!-- My Tracks -->
                    <span ng-switch-when="my">Only items I own</span>

                    <!-- All filter -->
                    <span ng-switch-when="all" class="dropdown" uib-dropdown on-toggle="toggled(open)">
                        <a
                            href
                            class="dropdown-toggle common-link-no-style cursor-default common-color-black flex-vmiddle"
                            uib-dropdown-toggle
                            ng-click="($root.isMobile && pm.groups.length > 7) && modalUtils.openManageGroupsModal()"
                            ng-disabled="$root.isMobile && pm.groups.length > 7"
                            uib-tooltip="{{ pm.groupsMap[groupId].name }}"
                            tooltip-enable="{{ utils.hasEllipsis('.live-report-display-name') }}"
                            tooltip-append-to-body="true"
                        >
                            <span
                                ng-if="!data.stateFilter && groupId && pm.groupsMap[groupId]"
                                class="live-report-display-name fs-censored common-ellipsis"
                                data-automation="initiatives-template-{{ pm.groupsMap[groupId].name }}"
                            >
                                {{ pm.groupsMap[groupId].name }}
                            </span>
                            <span ng-if="data.stateFilter">"{{ data.stateFilter }}" items</span>
                            <span
                                class="caret visible-xs-inline margin-left-xs pointer"
                                ng-if="pm.groups.length > 1"
                            ></span>
                        </a>
                        <ul
                            uib-dropdown-menu
                            class="dropdown-menu mod-padding-sm-v"
                            role="menu"
                            ng-if="$root.isMobile && pm.groups.length > 1"
                        >
                            <li
                                ng-repeat="group in (pm.groups | orderBy:['!isStarred','created'] | limitTo: 10)"
                                class="common-size-xs"
                                ng-if="group.id !== groupId"
                            >
                                <a class="flex-vmiddle" ng-click="navigateToList(group.id)">
                                    <i
                                        class="
                                            absolute-left
                                            margin-left-xs
                                            flex-no-shrink
                                            common-color-yellow
                                            svg-icon-xs svg-icon-align-text-base
                                        "
                                        ng-if="group.isStarred"
                                    >
                                        <tnk-icon src="/images/icons/star.svg"></tnk-icon>
                                    </i>
                                    <span class="flex-grow">{{ group.name }}</span>
                                    <i
                                        class="
                                            flex-no-shrink
                                            project-filter-item-lock
                                            svg-icon-xs svg-icon-align-text-base
                                        "
                                        ng-if="group.visibilityType && group.visibilityType === 'PRIVATE'"
                                    >
                                        <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                                    </i>
                                </a>
                            </li>
                            <li ng-if="pm.groups.length > 10" class="divider"></li>
                            <li ng-if="pm.groups.length > 10">
                                <a ng-click="modalUtils.openManageGroupsModal()">View all...</a>
                            </li>
                        </ul>
                    </span>

                    <!-- Late tracks -->
                    <span ng-switch-when="Late">Late items</span>

                    <!-- Owner filter -->
                    <span ng-switch-when="owner">{{ data.ownerName || 'Owner' }}</span>

                    <!-- Default filter -->
                    <span ng-switch-default class="margin-xs-left">{{ filter }}</span>
                </div>

                <!-- Function: edit name -->
                <form
                    ng-submit="updateFunctionName(data.tempTitle)"
                    name="initiative-update-title-form"
                    id="initiative-update-title-form"
                    class="flex-vmiddle"
                    ng-show="data.editTitle"
                >
                    <input
                        type="text"
                        class="form-control margin-right-xs"
                        ng-model="data.tempTitle"
                        id="input-edit-title"
                    />
                    <button type="button" class="btn btn-default margin-right-xxs" ng-click="data.editTitle = false;">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary" ng-disabled="data.loadingUpdateTitle">
                        <span class="flex-vmiddle">
                            Save
                            <span class="margin-left-xxs">
                                <i class="loading-small" ng-show="data.loadingUpdateTitle"></i>
                            </span>
                        </span>
                    </button>
                </form>
            </div>

            <!-- Items in the header that is not the title  -->
            <div class="header-action-buttons flex-vmiddle flex-grow">
                <!-- Items in the header except Module live -->
                <div class="flex-grow flex-vmiddle">
                    <!-- Group privacy and visibility -->
                    <div
                        ng-if="
                            !data.func &&
                            groupId &&
                            filterByGroup &&
                            !data.stateFilter &&
                            pm.groupsMap[groupId] &&
                            pm.groupsMap[groupId].members &&
                            pm.groupsMap[groupId].members.length
                        "
                        class="flex-vmiddle common-size-xxxxs margin-right"
                        uib-tooltip="Visible to {{ utils.joinObjArray(pm.groupsMap[groupId].members, 'name', ', ') }}"
                        tooltip-placement="bottom"
                        tooltip-append-to-body="true"
                    >
                        <span class="svg-icon-sm common-color-grey svg-icon-vmiddle margin-right-xs">
                            <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                        </span>
                    </div>

                    <!-- Public group icon -->
                    <span
                        ng-if="
                            !data.func &&
                            groupId &&
                            filterByGroup &&
                            !data.stateFilter &&
                            pm.groupsMap[groupId] &&
                            (!pm.groupsMap[groupId].members || !pm.groupsMap[groupId].members.length)
                        "
                        class="svg-icon-sm svg-icon-vmiddle common-color-grey margin-right-lg"
                        uib-tooltip="Everyone at {{ pm.project.name }} can view {{
                            pm.groupsMap[groupId].writePermissionType === 'EVERYONE' ? 'and edit ' : ''
                        }} this business report"
                        tooltip-placement="bottom auto"
                    >
                        <tnk-icon src="/images/icons/public.svg"></tnk-icon>
                    </span>

                    <!-- Key Metrics-->
                    <div
                        class="btn btn-sm hidden-xs btn-no-border"
                        ng-if="
                            !data.func &&
                            groupId &&
                            filterByGroup &&
                            !data.stateFilter &&
                            pm.groupsMap[groupId] &&
                            filter !== 'Archive' &&
                            data.view[pageKey] !== 'cal'
                        "
                        ng-click="toggleKeyMetrics($event)"
                        ng-class="{ active: !data.hideKeyMetrics }"
                        uib-tooltip="{{ data.hideKeyMetrics ? 'Show Key Metrics' : 'Hide Key Metrics' }}"
                        tooltip-placement="bottom"
                    >
                        <span
                            class="svg-icon-xsm"
                            ng-class="!data.hideKeyMetrics ? 'common-color-black' : 'common-color-grey'"
                        >
                            <tnk-icon src="/images/icons/bars-spaced.svg"></tnk-icon>
                        </span>
                    </div>

                    <!-- Separator-->
                    <div class="flex-vmiddle padding-none margin-left margin-right">
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!-- Who's viewing the list -->
                    <tnk-avatars-bar
                        ng-if="viewingUsers || shouldDisplayViewingUsersBar()"
                        avatars="viewingUsers"
                        avatars-desktop-limit="2"
                        display-more-limit="10"
                        show-all-in-display-more="false"
                        show-more-avatars-link="true"
                        should-display-view-time="true"
                        group-id="groupId"
                    ></tnk-avatars-bar>

                    <!-- Invite to tonkean -->
                    <span
                        class="common-color-dark-grey common-size-xxxs hidden-xs hidden-sm"
                        ng-if="groupId && filterByGroup && !data.stateFilter && as.currentUser && pm.project && !pm.viewOnlyMode && pm.groupsMap[groupId].canUserWrite && pm.project.systemUsersSource==='MANUAL'"
                    >
                        <a
                            class="common-link-no-style margin-left margin-right-lg"
                            ng-click="openInviteModal()"
                            analytics-on
                            analytics-category="Invite"
                            analytics-event="Open"
                            analytics-label="ListNav"
                        >
                            Invite
                        </a>
                    </span>

                    <!-- List options -->
                    <div
                        class="dropbox relative"
                        data-automation="tnk-group-fields-metadata-table-template-list"
                        ng-if="filter !== 'Archive' && (filterByGroup || data.func) && !data.stateFilter"
                        uib-dropdown
                        on-toggle="data.toggleActionsMenu = open; data.settingsTooltipIsOpen = false"
                    >
                        <!-- List options button -->
                        <div
                            class="svg-icon-mdl svg-icon-hover-primary pointer"
                            ng-if="groupId && pm.groups.length"
                            uib-dropdown-toggle
                            uib-tooltip="Module is turned off"
                            tooltip-append-to-body="true"
                            tooltip-enable="data.isDraft ? !pm.groupsMap[groupId].buildEnvironmentEnabled : !pm.groupsMap[groupId].workerEnabled"
                            ng-disabled="!pm.groupsMap[groupId].canUserWrite || data.isDraft ? !pm.groupsMap[groupId].buildEnvironmentEnabled : !pm.groupsMap[groupId].workerEnabled"
                        >
                            <div class="flex-vmiddle">
                                <div
                                    class="svg-icon-md initiatives-list-settings-icon"
                                    data-automation="initiative-list-settings-button"
                                >
                                    <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                </div>
                            </div>
                        </div>
                        <!-- List options menu -->
                        <ul
                            uib-dropdown-menu
                            class="actions-menu dropdown-menu mod-wide dropdown-menu-left"
                            role="menu"
                            ng-if="data.toggleActionsMenu"
                        >
                            <!-- Star -->
                            <li
                                data-automation="initiatives-remove-add-to-favorite-button"
                                ng-if="filterByGroup"
                                ng-click="toggleStar()"
                            >
                                <i
                                    class="svg-icon-sm margin-right common-color-yellow"
                                    ng-if="pm.groupsMap[groupId].isStarred"
                                >
                                    <tnk-icon src="/images/icons/star.svg"></tnk-icon>
                                </i>
                                <i
                                    class="svg-icon-sm margin-right star-icon-empty"
                                    ng-if="!pm.groupsMap[groupId].isStarred"
                                >
                                    <tnk-icon src="/images/icons/star-not-selected.svg"></tnk-icon>
                                </i>

                                {{ pm.groupsMap[groupId].isStarred ? 'Remove from' : 'Add to' }} my favorites
                            </li>

                            <li class="divider" ng-if="filterByGroup"></li>

                            <!-- Manage columns -->
                            <li
                                ng-if="filterByGroup && data.isCurrentUserGroupOwner"
                                data-automation="initiatives-open-manage-field-button"
                                ng-click="openManageFields(groupId, workflowVersionId, $event)"
                                ng-class="{ 'common-disabled': data.isFieldsStillLoadingFromCache }"
                            >
                                <span class="margin-right svg-icon-sm">
                                    <tnk-icon src="/images/icons/columns.svg"></tnk-icon>
                                </span>
                                Manage Fields
                                <span class="margin-left-xxxs margin-left-xs">
                                    <i class="loading-xsmall" ng-show="data.isFieldsStillLoadingFromCache"></i>
                                </span>
                            </li>

                            <!-- Schedule Reports -->
                            <li
                                class="flex"
                                ng-click="openReportScheduling($event)"
                                ng-class="{ 'common-disabled': data.isDraft }"
                                uib-tooltip="Only available in Production environment"
                                tooltip-enable="data.isDraft"
                            >
                                <span class="margin-right svg-icon-sm common-color-grey">
                                    <tnk-icon src="/images/icons/report.svg"></tnk-icon>
                                </span>
                                <span class="margin-right-xs">Report scheduling</span>
                                <span class="common-color-grey">via email</span>
                            </li>

                            <li class="divider"></li>

                            <!-- Email notifications -->
                            <li
                                ng-if="filterByGroup"
                                class="flex-vmiddle"
                                ng-class="{ 'common-disabled': data.isDraft }"
                                uib-tooltip="Only available in Production environment"
                                tooltip-enable="data.isDraft"
                                ng-click="openEmailNotificationsSettings($event)"
                            >
                                <div class="flex-grow">
                                    <span class="svg-icon-sm svg-icon-align-text-top margin-right">
                                        <tnk-icon src="/images/icons/envelope.svg"></tnk-icon>
                                    </span>
                                    Email notifications (to me)
                                </div>
                                <div class="flex-no-shrink">
                                    <tnk-toggle
                                        size="xs"
                                        is-active="as.currentUser.projectContexts[pm.project.id].shouldSendActivityDigestEmail !== 'OFF' && pm.activityDigestSubscribedGroups[groupId]"
                                        toggle-disabled="true"
                                        uib-tooltip="{{(as.currentUser.projectContexts[pm.project.id].shouldSendActivityDigestEmail !== 'OFF' && pm.activityDigestSubscribedGroups[groupId]) ? 'ON' : 'OFF'}}"
                                    ></tnk-toggle>
                                </div>
                            </li>
                            <!-- Communication integration channel -->
                            <li
                                ng-if="filterByGroup && (gp.currentUserIsOwnerMap[groupId] || pm.isOwner) &&
                                cis.doesUserHaveCommunicationIntegration(as.currentUser, pm.project.id)"
                                class="flex-vmiddle"
                                ng-class="{ 'common-disabled': data.isDraft }"
                                uib-tooltip="Only available in Production environment"
                                tooltip-enable="data.isDraft"
                                ng-init="data.menuCommunicationIntegration = pm.project.defaultCommunicationProjectIntegration"
                            >
                                <div class="flex-grow flex mod-align-start">
                                    <span
                                        class="
                                            initiative-integration-icon
                                            mod-normal
                                            flex-no-shrink
                                            mod-dropdown
                                            inline-block
                                            margin-right
                                        "
                                        ng-class="
                                            data.menuCommunicationIntegration
                                                ? 'mod-' +
                                                  data.menuCommunicationIntegration.integration.integrationType.toLowerCase()
                                                : 'mod-slack'
                                        "
                                    ></span>
                                    <span
                                        uib-tooltip="{{::getMenuCommunicationIntegrationTooltipLabel(data.menuCommunicationIntegration)}}"
                                        tooltip-enable="!data.isDraft"
                                    >
                                        {{::getMenuCommunicationIntegrationLabel(data.menuCommunicationIntegration)}}
                                    </span>
                                </div>
                                <div class="flex-no-shrink">
                                    <tnk-toggle
                                        size="xs"
                                        is-active="isCommunicationChannelConnected()"
                                        uib-tooltip="{{ isCommunicationChannelConnected() ? 'ON' : 'OFF' }}"
                                        toggle-disabled="true"
                                    ></tnk-toggle>
                                </div>
                            </li>

                            <li
                                ng-if="filterByGroup"
                                class="divider"
                                ng-class="{
                                    'visible-xs-block':
                                        !pm.isUserLicensed ||
                                        !pm.project.metadata ||
                                        !pm.project.metadata.allowViewOnlyAccess
                                }"
                            ></li>

                            <!-- Share view only link -->
                            <li
                                ng-if="
                                    filterByGroup &&
                                    pm.isUserLicensed &&
                                    pm.project.metadata &&
                                    pm.project.metadata.allowViewOnlyAccess
                                "
                                ng-click="modalUtils.openShareListModal(groupId)"
                            >
                                <span class="svg-icon-sm svg-icon-align-text-top margin-right">
                                    <tnk-icon src="/images/icons/share.svg"></tnk-icon>
                                </span>
                                Share
                            </li>
                            <!-- Create email endpoint -->
                            <li
                                ng-if="
                                    filterByGroup &&
                                    pm.isUserLicensed &&
                                    (pm.isOwner ||
                                        gp.currentUserIsOwnerMap[groupId] ||
                                        pm.groupsMap[groupId].emailEndpoint)
                                "
                                ng-click="openEmailEndpointModal($event)"
                                ng-class="{ 'common-disabled': data.isDraft }"
                                uib-tooltip="Only available in Production environment"
                                tooltip-enable="data.isDraft"
                            >
                                <span class="svg-icon-sm svg-icon-align-text-top margin-right">
                                    <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                                </span>
                                Add items by Email
                            </li>
                            <!-- Invite (only in mobile and small screens) -->
                            <li
                                class="flex common-color-black visible-xs-block visible-sm-block"
                                ng-click="openInviteModal()"
                            >
                                <i class="svg-icon-sm margin-right-xs common-color-grey">
                                    <tnk-icon src="/images/icons/plus-o.svg"></tnk-icon>
                                </i>
                                <span>Invite people</span>
                            </li>
                            <!-- Create list (only in mobile) -->
                            <li
                                ng-if="filterByGroup"
                                class="flex common-color-black visible-xs-block"
                                ng-click="modalUtils.openSetupGroupModal(null, true)"
                            >
                                <i class="fa fa-plus margin-right common-color-grey"></i>
                                Create new list
                            </li>
                            <!-- Function actions -->
                            <!-- Edit function name -->
                            <li
                                ng-if="data.func"
                                class="block"
                                ng-click="data.editTitle = true; data.tempTitle = data.func.name"
                                onclick="document.getElementById('input-edit-title').focus();"
                            >
                                <span class="margin-right svg-icon-sm common-color-grey">
                                    <tnk-icon src="/images/icons/pencil.svg"></tnk-icon>
                                </span>
                                Edit function name
                            </li>
                            <!-- Change function owner -->
                            <li ng-if="data.func" class="block" ng-click="data.editOwner = !data.editOwner">
                                <span class="svg-icon-sm svg-icon-align-text-top margin-right common-color-grey">
                                    <tnk-icon src="/images/icons/profile-o.svg"></tnk-icon>
                                </span>
                                Change owner
                            </li>
                            <li class="divider" ng-if="data.func"></li>
                            <!-- Delete function -->
                            <li ng-if="data.func" class="block" ng-click="deleteFunction(data.func)">
                                <span class="svg-icon-sm svg-icon-align-text-top margin-right">
                                    <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                                </span>
                                Delete function
                            </li>
                        </ul>
                    </div>

                    <!--Create Item by form-->
                    <div ng-if="data.groupCreateForms.length > 1 && !data.loadingForm" uib-dropdown>
                        <div
                            class="
                                common-size-xxxs
                                btn
                                inner-filter-status
                                margin-left-lg
                                padding-normal-xxs
                                create-form-dropdown-btn
                            "
                            data-automation="initiative-click-on-open-form-from-the-live-report"
                            uib-dropdown-toggle
                        >
                            <span class="margin-right-s margin-left common-color-white margin-right-no-xs">
                                + Create
                            </span>
                            <i class="margin-right-xs fa fa-chevron-down common-size-xxxxxs common-color-white"></i>
                        </div>

                        <ul class="dropdown-no-padding inner-filter-drop-down" uib-dropdown-menu>
                            <!-- Only Done -->
                            <li
                                ng-repeat="form in data.groupCreateForms"
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                data-automation="initiative-create-form-name-option"
                                ng-click="openModalForm(form.id, groupId)"
                            >
                                <span class="flex-grow common-color-light-grey2">
                                    {{ form.displayName ? form.displayName : 'Unnamed Form' }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        ng-if="data.groupCreateForms.length == 1 && !data.loadingForm && filter !== 'my'"
                        ng-disabled="data.isDraft ? !pm.groupsMap[groupId].buildEnvironmentEnabled : !pm.groupsMap[groupId].workerEnabled"
                        ng-click="(data.isDraft ? pm.groupsMap[groupId].buildEnvironmentEnabled : pm.groupsMap[groupId].workerEnabled) && openModalForm(data.groupCreateForms[0].id, groupId)"
                        class="
                            common-size-xxxs
                            btn
                            inner-filter-status
                            margin-left-lg
                            padding-normal-xxs
                            create-form-dropdown-btn
                        "
                        data-automation="initiative-click-on-single-form-from-the-live-report"
                    >
                        <span class="margin-right-s margin-left common-color-white margin-right-no-xs">
                            +
                            {{
                                data.groupCreateForms[0].displayName ? data.groupCreateForms[0].displayName : 'New form'
                            }}
                        </span>
                    </div>

                    <!-- Module Studio -->
                    <a
                        class="module-studio-navigation-button"
                        ng-if="groupId && filterByGroup && !data.stateFilter && data.showModuleStudioButton"
                        data-automation="initiative-move-to-module-editor-button"
                        ui-sref="product.workerEditor({g: groupId, isFromList: true, env: $stateParams.env || 'PUBLISHED'})"
                    >
                        <span class="tnk-icon module-studio-navigation-icon">
                            <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                        </span>
                        <span
                            data-automation="initiative-template-module-studio-button"
                            class="module-studio-navigation-label"
                        >
                            Module Studio
                        </span>
                    </a>

                    <!-- Space -->
                    <div class="flex-grow hidden-xs"></div>

                    <!-- Seperator-->
                    <div
                        class="flex-vmiddle padding-none visible-xs-block margin-right-xs"
                        ng-if="!data.func && groupId && filterByGroup && !data.stateFilter && pm.groupsMap[groupId]"
                    >
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!-- Function: owner name + avatar -->
                    <div
                        class="flex-no-shrink margin-left flex-vmiddle avatar-size-xxs"
                        ng-if="data.func && data.func.defaultOwner && !data.editOwner"
                    >
                        <tnk-avatar
                            user="data.func.defaultOwner"
                            class="margin-right"
                            default-on-empty="true"
                        ></tnk-avatar>
                        <span>{{ data.func.defaultOwner.firstName }}</span>
                    </div>

                    <!-- Function: set owner -->
                    <div
                        ng-if="data.func && data.editOwner"
                        class="
                            margin-top-xs margin-left
                            show-edit-when-hover-visibility
                            initiative-function-default-owner
                            margin-bottom-xs
                        "
                    >
                        <div class="flex-vmiddle avatar-size-xxs">
                            <!--<span class="margin-right">Owner:</span>-->
                            <div>
                                <form name="set-owner">
                                    <div class="flex mod-align-start initiative-function-add-people">
                                        <tnk-deprecated-people-selector
                                            class="flex-grow margin-none"
                                            no-validation="true"
                                            style="width: 200px"
                                            autofucsed
                                            selected-people="data.selectedPeople"
                                            no-custom="!pm.allowTempUsers"
                                            max-tags="1"
                                            placeholder="Enter owner's name {{ pm.allowTempUsers ? 'or email' : '' }}"
                                            show-intro="true"
                                        ></tnk-deprecated-people-selector>
                                        <button
                                            class="btn btn-default margin-left-xs"
                                            type="submit"
                                            ng-click="addPeopleToFunction(data.func)"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    ng-if="pm.isOwner || gp.currentUserIsOwnerMap[groupId]"
                    class="margin-right-xs margin-left-lg flex-no-shrink"
                >
                    <tnk-environment-indicator
                        environment-is-active="data.isDraft ? pm.groupsMap[groupId].buildEnvironmentEnabled : pm.groupsMap[groupId].workerEnabled"
                        current-environment="data.isDraft ? 'build' : 'production'"
                        on-changes="(onEnvironmentToggleClick)"
                        show-active-status-indicator="true"
                        off-module-indication="true"
                    ></tnk-environment-indicator>
                </div>
            </div>
        </div>

        <!-- Draft Builder Line -->
        <div ng-if="data.isDraft" class="initiatives-header-builder-line common-builder-line"></div>
    </div>

    <!--List metrics-->
    <div
        class="initiatives-list-metrics"
        id="initiatives-list-metrics"
        ng-class="{
            'mod-full':
                !showProjectFilter ||
                !pm.groups ||
                !pm.groups.length ||
                !(pm.groups | filter: { dashboardHidden: false }).length
        }"
        ng-if="!data.stateFilter && filter === 'all' && !data.hideKeyMetrics"
    >
        <tnk-metrics-bar
            type="listView"
            field-definition-target-type="GLOBAL"
            view-only="!pm.groupsMap[groupId].canUserWrite || (data.isDraft ? !pm.groupsMap[groupId].buildEnvironmentEnabled : !pm.groupsMap[groupId].workerEnabled)"
            is-draft="data.isDraft"
            group-id="groupId"
            workflow-version-id="{{ workflowVersionId }}"
            fields-map="pm.groupsMap[groupId].globalFieldsMap"
            fields-map-draft="pm.groupsMap[groupId].globalFieldsDraftMap"
            target="pm.groupsMap[groupId]"
            toggle-key-metrics="toggleKeyMetrics()"
            on-user-add-metric="openCreateNewFieldModal(createType)"
            on-user-edit-metric-settings="openEditFieldModal(fieldDefinition)"
            on-user-fix-integration="openFixIntegrationModal(fieldDefinition)"
            on-user-apply-tracks-filter="applyFilterFromDefinition(queryDefinition)"
            environment="data.environment"
        ></tnk-metrics-bar>
    </div>

    <!-- List container -->
    <div
        class="initiatives-content"
        id="initiatives-content"
        ng-class="{
            'mod-key-metrics': !data.stateFilter && filter === 'all' && !data.hideKeyMetrics,
            'mod-triggers-side-pane': data.isManageTriggersOpen,
            'mod-full':
                !showProjectFilter ||
                !pm.groups ||
                !pm.groups.length ||
                !(pm.groups | filter: { dashboardHidden: false }).length
        }"
    >
        <div class="initiatives-content-inner" id="initiatives-content-inner">
            <div>
                <!--week view-->
                <div ng-if="filter !== 'Archive'" class="margin-left" ng-show="data.showWeekView">
                    <div
                        class="relative hidden-xs padding-bottom margin-top initiative-week-view relative"
                        ng-show="data.showWeekView"
                    >
                        <div class="initiative-week-btn mod-left" ng-click="loadOtherWeek(-7)">
                            <span class="common-circle"><i class="fa fa-angle-left"></i></span>
                        </div>
                        <tnk-track-week-view
                            items="data.allInitiatives"
                            start-date="data.startDate"
                            group-id="filterByGroup ? groupId : null"
                            is-draft="data.isDraft"
                            func-id="data.func ? data.func.id : null"
                            include-links="true"
                            owner-id="filter === 'my' ? as.currentUser.id : null"
                            state-text="data.stateFilter"
                            tag="data.tag ? data.tag : null"
                            ng-if="!data.loadingWeek"
                            full-mode="true"
                        ></tnk-track-week-view>
                        <div class="initiative-week-btn mod-right" ng-click="loadOtherWeek(7)">
                            <span class="common-circle"><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>

                <!-- List Filters bar-->
                <div
                    class="initiatives-list-filter-bar"
                    id="initiatives-list-filter-bar"
                    ng-if="groupId && filterByGroup && !data.stateFilter"
                >
                    <div class="flex-vmiddle common-color-dark-grey common-size-xxxxs padding-top-xs padding-bottom-xs">
                        <!-- Week view -->
                        <div
                            class="
                                btn btn-sm
                                hidden-xs
                                btn-no-border
                                flex-vmiddle
                                common-size-xxxxs common-bg
                                btn-bold-on-active-container
                            "
                            ng-if="filter !== 'Archive' && data.view[pageKey] !== 'cal'"
                            ng-click="toggleWeekView()"
                            ng-class="{ active: data.showWeekView }"
                        >
                            <span
                                class="bold-on-active"
                                ng-class="data.showWeekView ? 'common-color-black' : 'common-color-grey'"
                                title="Week view"
                            >
                                <span class="svg-icon-xs margin-right-xs svg-icon-align-text-top">
                                    <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                                </span>
                                Week View
                            </span>
                        </div>

                        <div
                            class="common-separator-v hidden-xs"
                            ng-if="filter !== 'Archive' && data.view[pageKey] !== 'cal'"
                        ></div>

                        <!-- Timeline view -->
                        <div
                            class="
                                btn btn-sm
                                hidden-xs
                                btn-no-border
                                flex-vmiddle
                                common-size-xxxxs common-bg
                                btn-bold-on-active-container
                            "
                            ng-if="filter !== 'Archive' && pm.projectData.isTimelineAvailable"
                            ui-sref="product.calendar({tab: 'timeline', g: groupId})"
                        >
                            <span class="bold-on-active" title="Week view">
                                <span class="svg-icon-xs margin-right-xs svg-icon-align-text-top">
                                    <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                                </span>
                                Timeline
                            </span>
                        </div>

                        <div
                            class="common-separator-v hidden-xs"
                            ng-if="filter !== 'Archive' && pm.projectData.isTimelineAvailable"
                        ></div>

                        <!-- Open filter bar options button -->
                        <div
                            class="flex-vmiddle btn btn-sm btn-no-border common-size-xxxxs"
                            data-automation="initiatives-template-open-filter-bar-button"
                            analytics-on="click"
                            analytics-category="Filter"
                            analytics-event="Open Filter Bar"
                            ng-class="{ active: data.initiativesFilterDefinitionBarOpened }"
                            ng-click="toggleInitiativesFilterDefinitionBarOpened()"
                        >
                            <span
                                class="common-color-grey"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.structuredFilterOn ||
                                        data.toggleDone ||
                                        data.initiativesFilterDefinitionBarOpened
                                }"
                            >
                                Filter
                            </span>
                        </div>

                        <div class="common-separator-v"></div>

                        <!-- Search list-->
                        <div class="flex-vmiddle margin-left initiatives-inner-search-list-box">
                            <span class="svg-icon-sm">
                                <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                            </span>
                            <input
                                class="form-control common-no-border common-no-outline common-size-xxxxs"
                                data-automation="initiative-search-report-items-line"
                                placeholder="Search report items"
                                ng-change="applyFilter()"
                                ng-model="data.innerListSearchText"
                            />
                        </div>

                        <!-- Loading circle for filtering and OrderBy -->
                        <div
                            ng-style="{ visibility: ((data.loading || data.loadingOrderBy) && 'visible') || 'hidden' }"
                            class="inner-filter-loading-circle-container common-valign-middle"
                        >
                            <i
                                ng-if="data.loading || data.loadingOrderBy"
                                class="loading-small margin-right-xs block"
                                data-automation="initiatives-template-loading-icon"
                            ></i>
                        </div>

                        <!-- Clear filter-->
                        <span
                            ng-if="data.initiativesSearchOn"
                            ng-click="clearInitiativesSearch()"
                            class="svg-icon-xs common-color-grey pointer"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </span>

                        <!-- Group Saved Filters -->
                        <div
                            class="flex-vmiddle"
                            ng-if="
                                pm.groupsMap[groupId].metadata.savedFilters &&
                                pm.groupsMap[groupId].metadata.savedFilters &&
                                pm.groupsMap[groupId].metadata.savedFilters.length
                            "
                        >
                            <div class="common-separator-v"></div>
                            <a
                                ng-repeat="filter in pm.groupsMap[groupId].metadata.savedFilters"
                                ui-sref="product.board({filter: 'all', st: null, g: groupId, listFilter: filter.url, isGroupListFilter: true})"
                                data-automation="initiatives-template-saved-filter"
                                class="
                                    common-link-no-style common-tag-full
                                    mod-grey-tag mod-thin
                                    margin-right-xs
                                    pointer
                                "
                            >
                                <span
                                    ng-class="{
                                        'common-color-success2':
                                            data.currentSavedFilter && filter.url === data.currentSavedFilter.url
                                    }"
                                >
                                    <i class="fa fa-star"></i>
                                    <span>{{ filter.name }}</span>
                                </span>
                            </a>
                        </div>

                        <!-- My Saved Filters -->
                        <div
                            class="flex-vmiddle"
                            ng-if="as.currentUser.metadata.savedFilters && as.currentUser.metadata.savedFilters[groupId] && as.currentUser.metadata.savedFilters[groupId].length"
                        >
                            <div class="common-separator-v"></div>
                            <a
                                ng-repeat="filter in as.currentUser.metadata.savedFilters[groupId]"
                                data-automation="initiatives-template-my-saved-filter"
                                ui-sref="product.board({filter: 'all', st: null, g: groupId, listFilter: filter.url, isGroupListFilter: false})"
                                class="
                                    common-link-no-style common-tag-full
                                    mod-grey-tag mod-thin
                                    margin-right-xs
                                    pointer
                                "
                            >
                                <span
                                    ng-class="{
                                        'common-color-primary':
                                            data.currentSavedFilter && filter.url === data.currentSavedFilter.url
                                    }"
                                >
                                    <i class="fa fa-star"></i>
                                    <span>{{ filter.name }}</span>
                                </span>
                            </a>
                        </div>

                        <div class="flex-grow"></div>
                    </div>
                </div>

                <!-- Triangle indicating filter bar is opened -->
                <div class="inner-filter-separator" ng-if="data.initiativesFilterDefinitionBarOpened"></div>
                <hr class="margin-none" ng-if="groupId && filterByGroup && !data.stateFilter" />

                <!-- Inner filter -->
                <div
                    ng-if="data.initiativesFilterDefinitionBarOpened"
                    class="flex-vmiddle margin-top margin-bottom padding-left"
                >
                    <!-- Status -->
                    <div
                        uib-dropdown
                        class="margin-right-xlg inner-filter-status common-size-xxxxs"
                        is-open="data.initiativesFilterDefinitionBarDropdownOptionState['STATUS']"
                    >
                        <div
                            class="flex-vmiddle common-color-grey pointer"
                            ng-click="closeInitiativesFilterPopover()"
                            uib-dropdown-toggle
                        >
                            <span
                                class="margin-right-xs"
                                data-automation="initiatives-template-status-filter-button"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.initiativesFilterData.selectedItemsCountMap['STATUS']
                                }"
                            >
                                Status
                            </span>
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                        <ul class="dropdown-no-padding inner-filter-drop-down" uib-dropdown-menu>
                            <!-- Only Done -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleAllItemsFilter($event, 'STATUS'); toggleToggleDone($event, false)"
                            >
                                <span
                                    class="flex-grow common-color-light-grey2"
                                    ng-class="{
                                        'common-color-black':
                                            !data.initiativesFilterData.selectedItemsCountMap['STATUS'] &&
                                            !data.toggleDone,
                                        pointer: data.initiativesFilterData.selectedItemsCountMap['STATUS'] > 0
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Tags"
                                    analytics-label="All tags"
                                >
                                    Active statuses
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="
                                        !data.initiativesFilterData.selectedItemsCountMap['STATUS'] && !data.toggleDone
                                    "
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>
                            <!-- All statuses -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleAllItemsFilter($event, 'STATUS'); toggleToggleDone($event, true)"
                            >
                                <span
                                    class="flex-grow common-color-light-grey2"
                                    ng-class="{
                                        'common-color-black':
                                            !data.initiativesFilterData.selectedItemsCountMap['STATUS'] &&
                                            data.toggleDone,
                                        pointer: data.initiativesFilterData.selectedItemsCountMap['STATUS'] > 0
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Tags"
                                    analytics-label="All tags"
                                >
                                    All statuses
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="
                                        !data.initiativesFilterData.selectedItemsCountMap['STATUS'] && data.toggleDone
                                    "
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>

                            <li ng-if="pm.projectData.stats && pm.projectData.stats.length" class="divider"></li>

                            <!-- Each status -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleSelectedFilteredItem($event, 'STATUS', state.stateText)"
                                ng-repeat="state in pm.projectData.stats"
                                ng-if="data.groupStatesLabelsSet[state.stateText]"
                            >
                                <span
                                    class="flex-grow common-color-light-grey2"
                                    ng-class="{
                                        'common-color-black':
                                            data.initiativesFilterData.selectedItemsMaps['STATUS'][state.stateText]
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Statuses"
                                    analytics-label="{{ state.stateText }}"
                                >
                                    <i class="fa fa-circle margin-right-xs" ng-style="{ color: state.stateColor }"></i>
                                    <span>{{ state.stateText }}</span>
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="data.initiativesFilterData.selectedItemsMaps['STATUS'][state.stateText]"
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <!-- Tags -->
                    <div
                        uib-dropdown
                        class="margin-right-xlg inner-filter-tags common-size-xxxxs"
                        ng-if="pm.tags && pm.tags.length"
                        is-open="data.initiativesFilterDefinitionBarDropdownOptionState['TAG']"
                    >
                        <div
                            class="flex-vmiddle common-color-grey pointer"
                            ng-click="closeInitiativesFilterPopover()"
                            uib-dropdown-toggle
                        >
                            <span
                                class="margin-right-xs"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.initiativesFilterData.selectedItemsCountMap['TAG']
                                }"
                            >
                                Tags
                            </span>
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                        <ul class="dropdown-no-padding inner-filter-drop-down" uib-dropdown-menu>
                            <!-- All tags -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleAllItemsFilter($event, 'TAG')"
                            >
                                <span
                                    class="flex-grow common-color-grey"
                                    ng-class="{
                                        'common-color-black': !data.initiativesFilterData.selectedItemsCountMap['TAG'],
                                        pointer: data.initiativesFilterData.selectedItemsCountMap['TAG'] > 0
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Tags"
                                    analytics-label="All tags"
                                >
                                    All tags
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="!data.initiativesFilterData.selectedItemsCountMap['TAG']"
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>

                            <!-- Each tag -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleSelectedFilteredItem($event, 'TAG', tag.name)"
                                ng-repeat="tag in pm.tags"
                            >
                                <span
                                    class="flex-grow common-color-grey"
                                    ng-class="{
                                        'common-color-black':
                                            data.initiativesFilterData.selectedItemsMaps['TAG'][tag.name]
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Tags"
                                    analytics-label="{{ tag.name }}"
                                >
                                    {{ tag.name }}
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="data.initiativesFilterData.selectedItemsMaps['TAG'][tag.name]"
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <!-- Owner -->
                    <div
                        uib-dropdown
                        class="margin-right-xlg inner-filter-owner common-size-xxxxs"
                        is-open="data.initiativesFilterDefinitionBarDropdownOptionState['OWNER']"
                    >
                        <div
                            class="flex-vmiddle common-color-grey pointer"
                            uib-dropdown-toggle
                            ng-click="closeInitiativesFilterPopover()"
                        >
                            <span
                                class="margin-right-xs"
                                data-automation="initiatives-template-owner-filter-button"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.initiativesFilterData.selectedItemsCountMap['OWNER']
                                }"
                            >
                                Owner
                            </span>
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                        <div
                            class="padding-normal-sm inner-filter-owner-option"
                            ng-click="initiativesFilterOwnerSelection($event)"
                            uib-dropdown-menu
                        >
                            <tnk-deprecated-people-selector
                                class="flex-grow margin-none"
                                no-validation="true"
                                only-owners-in-group="pm.groupsMap[groupId]"
                                selected-people="data.initiativesFilterData.filteredOwners"
                                no-custom="true"
                                on-tag-added="onInitiativesFilterOwnerAddedOrRemoved()"
                                on-tag-removed="onInitiativesFilterOwnerAddedOrRemoved()"
                            ></tnk-deprecated-people-selector>
                        </div>
                    </div>

                    <!-- Creator -->
                    <div
                        uib-dropdown
                        class="margin-right-xlg inner-filter-owner common-size-xxxxs"
                        is-open="data.initiativesFilterDefinitionBarDropdownOptionState['CREATOR']"
                    >
                        <div
                            class="flex-vmiddle common-color-grey pointer"
                            uib-dropdown-toggle
                            ng-click="closeInitiativesFilterPopover()"
                        >
                            <span
                                class="margin-right-xs"
                                data-automation="initiatives-template-creator-filter-button"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.initiativesFilterData.selectedItemsCountMap['CREATOR']
                                }"
                            >
                                Creator
                            </span>
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                        <div
                            class="padding-normal-sm inner-filter-owner-option"
                            ng-click="initiativesFilterCreatorSelection($event)"
                            uib-dropdown-menu
                        >
                            <tnk-deprecated-people-selector
                                class="flex-grow margin-none"
                                on-tag-added="onInitiativesFilterCreatorAddedOrRemoved()"
                                on-tag-removed="onInitiativesFilterCreatorAddedOrRemoved()"
                            ></tnk-deprecated-people-selector>
                        </div>
                    </div>

                    <!-- Functions -->
                    <div
                        uib-dropdown
                        class="margin-right-xlg inner-filter-functions common-size-xxxxs"
                        ng-if="pm.functions && pm.functions.length"
                        is-open="data.initiativesFilterDefinitionBarDropdownOptionState['FUNCTION']"
                    >
                        <div
                            class="flex-vmiddle common-color-grey pointer"
                            ng-click="closeInitiativesFilterPopover()"
                            uib-dropdown-toggle
                        >
                            <span
                                class="margin-right-xs"
                                ng-class="{
                                    'common-color-black common-bold':
                                        data.initiativesFilterData.selectedItemsCountMap['FUNCTION']
                                }"
                            >
                                Functions
                            </span>
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                        <ul class="dropdown-no-padding inner-filter-drop-down" uib-dropdown-menu>
                            <!-- All functions -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleAllItemsFilter($event, 'FUNCTION')"
                            >
                                <span
                                    class="flex-grow common-color-grey"
                                    ng-class="{
                                        'common-color-black':
                                            !data.initiativesFilterData.selectedItemsCountMap['FUNCTION'],
                                        pointer: data.initiativesFilterData.selectedItemsCountMap['FUNCTION'] > 0
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Functions"
                                    analytics-label="All functions"
                                >
                                    All functions
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="!data.initiativesFilterData.selectedItemsCountMap['FUNCTION']"
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>

                            <!-- Each function -->
                            <li
                                class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                ng-click="toggleSelectedFilteredItem($event, 'FUNCTION', function.name)"
                                ng-repeat="function in pm.functions"
                            >
                                <span
                                    class="flex-grow common-color-grey"
                                    ng-class="{
                                        'common-color-black':
                                            data.initiativesFilterData.selectedItemsMaps['FUNCTION'][function.name]
                                    }"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Tags"
                                    analytics-label="{{ function.name }}"
                                >
                                    {{ function.name }}
                                </span>
                                <span
                                    class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                    ng-if="data.initiativesFilterData.selectedItemsMaps['FUNCTION'][function.name]"
                                >
                                    <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <!-- Advanced -->
                    <div
                        class="
                            initiatives-filter-popover-parent
                            margin-right-lg
                            inner-filter-advanced
                            common-size-xxxxs
                        "
                    >
                        <div
                            uib-popover-template="'./initiativesFilterPopoverTemplate.template.html'"
                            popover-is-open="initiativesFilterPopoverState.isOpen"
                            popover-placement="bottom-centre auto"
                            popover-trigger="none"
                        >
                            <div
                                class="margin-right-xs common-color-grey pointer"
                                data-automation="initiatives-template-advanced-filter-button"
                                ng-class="{
                                    'common-color-black common-bold':
                                        initiativesFilterControl && initiativesFilterControl.anyNonEmptyConditions
                                            ? initiativesFilterControl.anyNonEmptyConditions()
                                            : anyNonEmptyConditionsInQuery(data.existingDefinition.query)
                                }"
                                analytics-on="click"
                                analytics-category="Filter"
                                analytics-event="Filter by Conditions"
                                ng-click="toggleInitiativesFilterPopoverOpenState()"
                            >
                                Advanced
                            </div>
                        </div>
                    </div>

                    <!-- Clear button -->
                    <button
                        type="button"
                        class="
                            margin-right-lg
                            inner-filter-clear-btn
                            pointer
                            common-bold common-no-outline common-size-xxxxs
                        "
                        data-automation="initiatives-template-clear"
                        ng-if="data.structuredFilterOn || data.toggleDone"
                        ng-click="clearStructuredFilter()"
                    >
                        Clear
                    </button>

                    <!-- Create  key metric-->
                    <div class="inner-filter-create-bubble" ng-if="data.structuredFilterOn && data.isDraft">
                        Create
                        <a ng-click="openKeyMetricDefinitionModalFromFilters()" class="common-link-blue">Key Metric</a>
                        <span class="common-color-grey2">&nbsp;from this filter</span>
                    </div>

                    <!-- Save filter-->
                    <div class="inner-filter-create-bubble margin-left" data-automation="initiatives-template-save-filter" ng-if="data.structuredFilterOn">
                        <!-- Save-->
                        <a
                            ng-click="data.showSaveFilterName = true;"
                            class="common-link-blue"
                            ng-hide="data.showSaveFilterName"
                            ng-if="!data.currentSavedFilter"
                        >
                            <i class="fa fa-star margin-right-xs"></i>
                            Save this Filter
                        </a>
                        <div class="flex-vmiddle" ng-if="data.showSaveFilterName">
                            <input
                                class="form-control common-size-xxxxs common-input-only-line"
                                data-automation="initiatives-template-filter-name"
                                placeholder="Enter filter name..."
                                ng-model="data.saveNewFilterName"
                                autofocus
                            />
                            <a ng-click="saveFilterToMyFilters()" class="btn btn-sm btn-primary-black" data-automation="initiatives-template-save">Save</a>
                            <a
                                ng-if="data.isCurrentUserGroupOwner"
                                ng-click="saveFilterToGroupFilters()"
                                class="btn btn-sm btn-primary-black margin-left-xs"
                                data-automation="initiatives-template-filter-save-for-everyone"
                            >
                                Save For Everyone
                            </a>
                            <i
                                class="fa fa-close margin-left-xs padding-normal-xs pointer"
                                ng-click="data.showSaveFilterName = false;"
                            ></i>
                        </div>

                        <!-- Delete -->
                        <a
                            ng-click="removeSavedFilter(data.currentSavedFilter)"
                            class="common-link-blue"
                            ng-if="data.currentSavedFilter"
                        >
                            <i class="fa fa-star-o margin-right-xs"></i>
                            Delete filter
                        </a>

                        <!-- Loading -->
                        <i class="loading-small" ng-if="data.removingGroupSavedFilter"></i>
                    </div>

                    <!-- Make Default -->
                    <div
                        ng-if="data.currentSavedFilter && data.isGroupListFilter && data.structuredFilterOn"
                        class="inner-filter-create-bubble margin-left"
                        data-automation="initiatives-template-make-default"
                    >
                        <a ng-click="toggleFilterIsDefault(data.currentSavedFilter)" class="common-link-blue">
                            <i class="fa fa-check margin-right-xs"></i>
                            {{
                                pm.groupsMap[groupId].metadata.defaultSavedFilterUrl === data.currentSavedFilter.url
                                    ? 'Remove as'
                                    : 'Make'
                            }}
                            default
                        </a>
                    </div>
                </div>

                <hr ng-if="data.initiativesFilterDefinitionBarOpened" class="margin-none" />

                <!--Loading-->
                <tnk-initiatives-placeholder-container
                    ng-if="
                        data.showloading ||
                        (!data.startManually &&
                            filter === 'all' &&
                            !data.stateFilter &&
                            !data.dateRange &&
                            !data.showList)
                    "
                    class-name="data.showloading || data.loading ? 'absolute'  : ''"
                    loading="data.showloading || data.loading"
                ></tnk-initiatives-placeholder-container>

                <!--Track view-->
                <div
                    ng-if="
                        !data.loading ||
                        (data.initiatives && data.initiatives.length) ||
                        !data.invisibleGroupIdToInitiativesMapIsEmpty
                    "
                    id="tracks-list"
                >
                    <div class="tracks-editor" ng-if="data.startManually || data.showList">
                        <div
                            ng-repeat="group in filteredGroups = (pm.groups | filter:filterGroup)"
                            ng-init="filteredItems = (data.initiatives | filter:{status: !data.stateFilter ? '!DONE' : '', groupId: group.id})"
                        >
                            <div
                                class="tracks-group common-width-full"
                                ng-class="{
                                    'mod-seperator': filteredGroups.length > 1 && !$last,
                                    'display-table': filteredGroups.length > 1
                                }"
                                ng-if="
                                    filteredItems.length > 0 ||
                                    (filter === 'all' && !data.stateFilter) ||
                                    data.dateRange
                                "
                            >
                                <div
                                    class="relative margin-top margin-left margin-right margin-bottom-none"
                                    ng-if="
                                        filteredGroups.length > 1 ||
                                        filter !== 'all' ||
                                        data.stateFilter ||
                                        data.dateRange
                                    "
                                    ng-class="{ pointer: filteredGroups.length > 1 }"
                                    ng-click="group.collapse = !group.collapse"
                                >
                                    <div class="flex-vmiddle flex-xs-wrap margin-top-xlg-no-xs">
                                        <!-- Filter mode - Collapse arrows -->
                                        <div
                                            class="
                                                fa fa-chevron-right
                                                common-size-xxxxxs
                                                margin-right
                                                filtered-groups-collapse-arrow
                                            "
                                            ng-if="group.collapse"
                                        ></div>
                                        <div
                                            class="
                                                fa fa-chevron-down
                                                common-size-xxxxxs
                                                margin-right
                                                filtered-groups-collapse-arrow
                                                margin-bottom-xxs
                                            "
                                            ng-if="!group.collapse"
                                        ></div>

                                        <!-- Filter mode - Group name -->
                                        <div class="common-bold common-size-xs margin-right-xs pointer" style="display: flex; align-items: center;">
                                            {{ group.name }} <tnk-off-module-indication show="data.isDraft ? !group.buildEnvironmentEnabled: !group.workerEnabled" style="display: flex; margin-left: 5px;"></tnk-off-module-indication>
                                        </div>

                                        <!-- Filter mode - Lock icon if needed -->
                                        <div
                                            class="
                                                common-color-grey
                                                svg-icon svg-icon-vmiddle
                                                relative
                                                margin-bottom-xxs
                                            "
                                            uib-tooltip="{{ group.visibleToSummary }}"
                                            ng-if="group.members && group.members.length"
                                        >
                                            <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                                        </div>
                                    </div>
                                </div>
                                <hr
                                    class="margin-bottom-none margin-top"
                                    ng-if="!groupId || !filterByGroup || data.stateFilter"
                                />
                                <div
                                    ng-hide="
                                        (filteredGroups.length > 1 && group.collapse) ||
                                        group.isFieldsStillLoadingFromCache
                                    "
                                >
                                    <div class="relative">
                                        <tnk-tracks-editor
                                            scroller-element-id="project-board"
                                            list-parent-element-id="tracks-list"
                                            tracks="data.initiatives"
                                            default-func="data.func"
                                            default-tag="data.tag"
                                            filter="{groupId: group.id}"
                                            editor-id="{{ 'filter' + $index }}"
                                            lazy-load-tracks="!(filteredGroups.length > 1 || filter !== 'all' || data.stateFilter || data.dateRange)"
                                            only-group="group.id"
                                            default-me="filter === 'my'"
                                            loading="data.loading"
                                            allow-dnd-on-root="filter === 'all' && !data.stateFilter && !data.initiativesFilterOn"
                                            show-archived="filter === 'Archive'"
                                            show-add-on-empty="true"
                                            hide-add-new-force="filter !== 'all' || data.stateFilter || data.initiativesFilterOn"
                                            hide-column-quick-create-force="filter !== 'all' || data.stateFilter || data.initiativesFilterOn"
                                            custom-fields="cfm.selectedColumnFieldsMap[getWorkflowVersionIdByGroupId(group.id)]"
                                            group-id="group.id"
                                            workflow-version-id="getWorkflowVersionIdByGroupId(group.id)"
                                            search-initiatives-query="searchInitiativesQueryObject"
                                            load-next-initiatives-page-callback="loadNextTracksPage()"
                                            reload-all-initiatives-pages="loadAllInitiativesPages(softUpdate)"
                                            has-more-initiatives="data.hasMoreInitiatives"
                                            is-tag-or-func="filter !== 'my' && filter !== 'all' && filter !== 'Archive'"
                                            view-only-mode="!pm.groupsMap[group.id].canUserWrite || (data.isDraft ? !pm.groupsMap[group.id].buildEnvironmentEnabled : !pm.groupsMap[group.id].workerEnabled)"
                                            filter-by-field="initiativesFilterFieldSelection(fieldDefinition)"
                                            save-order-preference="true"
                                            on-order-by="setOrderBy(field, fieldType, orderType, forceLoad, groupId)"
                                            show-actions-column="true"
                                            default-item-interface-id="filter === 'my' ? data.defaultInterfaceByWorkflowVersion[getWorkflowVersionIdByGroupId(group.id)] : data.defaultItemInterfaceId"
                                            show-done="data.toggleDone"
                                            loading-related-initiatives="data.loadingRelatedInitiatives"
                                        ></tnk-tracks-editor>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Initiatives from groups the user can't see (i.e invisibleGroupIdToInitiativesMap) -->
                        <div
                            ng-repeat="(groupId, invisibleInitiatives) in data.invisibleGroupIdToInitiativesMap"
                            ng-if="!data.invisibleGroupIdToInitiativesMapIsEmpty"
                        >
                            <div class="common-title-sm margin-normal-sm">Other ({{ pm.groupsMap[groupId].name }})</div>

                            <tnk-tracks-editor
                                group-id="groupId"
                                workflow-version-id="workflowVersionId"
                                tracks="invisibleInitiatives"
                                default-func="data.func"
                                default-tag="data.tag"
                                filter="{status: !data.stateFilter ? '!DONE' : '', groupId: group.id}"
                                editor-id="invisibleGroupsBucket"
                                loading="data.loading"
                                allow-dnd-on-root="filter === 'all' && !data.stateFilter"
                                hide-add-new-force="true"
                                custom-fields="data.invisibleGroupIdToFieldDefinitionsMap[groupId]"
                                is-tag-or-func="filter !== 'my' && filter !== 'all' && filter !== 'Archive'"
                                show-actions-column="true"
                            ></tnk-tracks-editor>
                        </div>

                        <!-- Plus sign to add new item -->
                        <div
                            ng-if="filter !== 'all' && filter !== 'my' && filter !== 'Late'"
                            class="tracks-item-new flex-vmiddle"
                        >
                            <i
                                class="
                                    tracks-item-new-plus-icon
                                    margin-right
                                    flex-no-shrink
                                    mod-center
                                    flex-vmiddle
                                    mod-center
                                "
                                ng-class="{ 'mod-not-inner': !innerMode }"
                                ng-show="!subItemMode"
                            >
                                <span>+</span>
                            </i>

                            <tnk-search-auto-complete
                                class="flex-grow tracks-item-new-input"
                                title="TOP MATCHES"
                                placeholder="{{
                                    data.func
                                        ? 'Search item to set \'' + data.func.name + '\' as its owner'
                                        : 'Add exisiting item to \'' + filter + '\''
                                }}"
                                is-open="data.attachData.isOpen"
                                is-focused="data.attachData.isFocused"
                                results="data.attachData.results"
                                on-select="addItemToFilter($item, $event)"
                            ></tnk-search-auto-complete>
                        </div>
                    </div>
                    <div
                        class="padding-normal common-size-xxs common-color-grey flex-vmiddle"
                        ng-show="
                            (filter !== 'all' || data.dateRange || data.initiativesFilterOn || data.stateFilter) &&
                            (!data.initiatives || !data.initiatives.length) &&
                            data.invisibleGroupIdToInitiativesMapIsEmpty
                        "
                    >
                        <!-- No Matches-->
                        <i class="fa fa-search fa-2x common-color-primary margin-right"></i>
                        <div>
                            <div class="common-section-title-sm" data-automation="initiatives-template-no-matches">No matches found for what you searched</div>
                            <div class="common-subtitle-inner">Try searching again. Sorry!</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Locked list for when the project is limited -->
<div
    ng-if="
        pm.project.isLimitedLicense &&
        pm.freeMembershipGroupId !== groupId &&
        (!data.stateFilter || filter !== 'all') &&
        !(!pm.groups || !pm.groups.length || !(pm.groups | filter: { dashboardHidden: false }).length)
    "
>
    <!-- Header -->
    <div class="initiatives-header">
        <div
            class="initiatives-header-inner margin-left flex-vmiddle mod-justify-end-xs flex-xs-wrap"
            ng-class="{ 'mod-show-inner-project-filter': showProjectInnerFilter }"
        >
            <div
                class="initiatives-list-title capital-first-letter show-edit-when-hover flex-no-shrink margin-right"
                ng-class="{ 'mod-fixed-on-mobile': !filterVisiblity.showInnerBar }"
            >
                <span class="dropdown" uib-dropdown on-toggle="toggled(open)">
                    <a
                        href
                        class="
                            dropdown-toggle
                            common-link-no-style
                            cursor-default
                            common-color-black
                            flex-vmiddle
                            padding-left
                        "
                        uib-dropdown-toggle
                        ng-click="($root.isMobile && pm.groups.length > 7) && modalUtils.openManageGroupsModal()"
                        ng-disabled="$root.isMobile && pm.groups.length > 7"
                        uib-tooltip="{{ pm.groupsMap[groupId].name }}"
                        tooltip-class="initiatives-list-title-tooltip"
                        tooltip-append-to-body="true"
                    >
                        <span
                            ng-if="!data.stateFilter && groupId && pm.groupsMap[groupId]"
                            class="fs-censored common-ellipsis"
                        >
                            {{ pm.groupsMap[groupId].name }}
                        </span>
                        <span ng-if="data.stateFilter">"{{ data.stateFilter }}" items</span>
                        <span ng-if="!groupId || !pm.groups.length">High level items</span>
                        <span
                            class="caret visible-xs-inline margin-left-xs pointer"
                            ng-if="pm.groups.length > 1"
                        ></span>
                    </a>
                    <ul
                        uib-dropdown-menu
                        class="dropdown-menu mod-padding-sm-v"
                        role="menu"
                        ng-if="$root.isMobile && pm.groups.length > 1"
                    >
                        <li
                            ng-repeat="group in (pm.groups | orderBy:['!isStarred','created'] | limitTo: 10)"
                            class="common-size-xs"
                            ng-if="group.id !== groupId"
                        >
                            <a class="flex-vmiddle" ng-click="navigateToList(group.id)">
                                <i
                                    class="
                                        absolute-left
                                        margin-left-xs
                                        flex-no-shrink
                                        common-color-yellow
                                        svg-icon-xs svg-icon-align-text-base
                                    "
                                    ng-if="group.isStarred"
                                >
                                    <tnk-icon src="/images/icons/star.svg"></tnk-icon>
                                </i>
                                <span class="flex-grow">{{ group.name }}</span>
                                <i
                                    class="flex-no-shrink project-filter-item-lock svg-icon-xs svg-icon-align-text-base"
                                    ng-if="group.visibilityType && group.visibilityType === 'PRIVATE'"
                                >
                                    <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                                </i>
                            </a>
                        </li>
                        <li ng-if="pm.groups.length > 10" class="divider"></li>
                        <li ng-if="pm.groups.length > 10">
                            <a ng-click="modalUtils.openManageGroupsModal()">View all...</a>
                        </li>
                    </ul>
                </span>
            </div>

            <div class="flex-grow flex-vmiddle"></div>

            <!-- Bot toggle button -->
            <div class="flex-vmiddle mod-justify-end">
                <div
                    class="flex-vmiddle margin-xs-right-md"
                    ng-if="wvm.getCachedWorkflowVersion(workflowVersionId).shouldSendGatherUpdates"
                >
                    <div class="svg-icon svg-icon-lg mod-static relative">
                        <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                    </div>
                    <tnk-bot-toggle
                        is-bot-active="wvm.getCachedWorkflowVersion(workflowVersionId).shouldSendGatherUpdates"
                        group-id="groupId"
                        workflow-version-id="{{ workflowVersionId }}"
                        toggle-disabled="!pm.groupsMap[groupId].canUserWrite"
                        uib-popover-template="'./botOffWhenOwnersPopoverTemplate.template.html'"
                        popover-placement="bottom-right"
                        popover-is-open="!as.currentUser.metadata.forceHideBotOffWhenOwnersPopover && openBotOffWhenOwnersPopover && !wvm.getCachedWorkflowVersion(workflowVersionId).shouldSendGatherUpdates"
                        popover-class="bot-off-when-owners-popover"
                        popover-trigger="none"
                    ></tnk-bot-toggle>
                </div>
            </div>
        </div>
    </div>
    <!-- Content -->
    <div class="flex flex-col margin-top-xlg">
        <div class="flex-grow flex flex-col mod-center text-center common-size-xs padding-normal-xlg">
            <i class="svg-icon">
                <tnk-icon src="/images/icons/two-empty-screens.svg"></tnk-icon>
            </i>
            <div class="common-title-md margin-top-lg">Upgrade to access all your Lists</div>
            <div class="margin-top-md common-size-xxs common-color-dark-grey">
                Multiple Lists require a paid subscription.
                <br />
                <span>Upgrade to restore access to the Lists you created</span>
                <br class="visible-xs-block" />
                <span>&nbsp;during your free trial.</span>
            </div>
            <div class="flex-no-shrink margin-top-md">
                <button
                    type="button"
                    class="btn btn-primary btn-lg"
                    ui-sref="product.settings.license({tab: 'upgrade'})"
                >
                    Upgrade Plan
                </button>
            </div>
            <hr class="initiatives-locked-list-seperator" />
            <div class="common-size-xxs common-color-dark-grey">
                <div ng-if="!pm.project.metadata.freeMembershipSelectedGroup" class="margin-xs-bottom-xlg">
                    <a href="" class="common-link-blue" ng-click="setFreeMembershipGroup()">
                        Change my free List to this one
                    </a>
                    <br />
                    <span class="margin-top-xs">* This can only be done once.</span>
                </div>
                <div ng-if="pm.project.metadata.freeMembershipSelectedGroup" class="margin-xs-bottom-xlg">
                    <div>You have already set your free List</div>
                    <a ng-click="modal.openFeedback()" class="margin-top-xs">Contact us</a>
                    if you have any questions
                </div>
            </div>
        </div>
    </div>
</div>

<tnk-system-interface-modal open="true"></tnk-system-interface-modal>

<!-- Empty state -->
<tnk-initiatives-empty-state
    ng-if="!pm.groups || !pm.groups.length || !(pm.groups | filter: { dashboardHidden: false }).length"
></tnk-initiatives-empty-state>
