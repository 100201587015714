import React from 'react';
import styled from 'styled-components';

import type { SearchWidgetCardsDensityConfiguration } from './SearchWidgetCardsDensityConfigurations';

import { IconSvg } from '@tonkean/infrastructure';
import { ImagePlaceHolderIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

const ImageContainer = styled.div<{ $width: number; $height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ $width }) => $width}px;
    max-width: ${({ $width }) => $width}px;
    min-width: ${({ $width }) => $width}px;
    border-radius: 4px;
    height: ${({ $height }) => $height}px;
    background: ${Theme.colors.gray_300};
`;

interface Props {
    cardsDensityConfiguration: SearchWidgetCardsDensityConfiguration;
}

const SearchWidgetLoadingImage: React.FC<Props> = ({ cardsDensityConfiguration }) => {
    return (
        <ImageContainer $width={cardsDensityConfiguration.image.width} $height={cardsDensityConfiguration.image.height}>
            <IconSvg as={ImagePlaceHolderIcon} />
        </ImageContainer>
    );
};

export default SearchWidgetLoadingImage;
