import { createPluginFactory } from '@udecode/plate';
import React from 'react';

import { ELEMENT_TNK_VARIABLE } from './coreEditorConsts';
import CoreEditorTonkeanExpressionToolbarButton from './CoreEditorTonkeanExpressionToolbarButton';
import CoreEditorTonkeanVariablePlateUi from './CoreEditorTonkeanVariablePlateUi';
import type { CoreEditorVariableElement } from './coreEditorVariableElement';
import EvaluatedTonkeanVariable from './EvaluatedTonkeanVariable';

import type { CoreEditorPluginSerializeElementProps, HTMLEditorPlatePlugin } from '@tonkean/editor';
import { createCoreEditorPlatePlugin } from '@tonkean/editor';

const createTonkeanVariablePlugin = createPluginFactory({
    key: ELEMENT_TNK_VARIABLE,
    isElement: true,
    isInline: true,
    isVoid: false,
});

const coreEditorTonkeanVariablePlugin = createCoreEditorPlatePlugin<HTMLEditorPlatePlugin>({
    platePlugin: createTonkeanVariablePlugin(),
    plateUI: {
        [ELEMENT_TNK_VARIABLE]: {
            isVoidElement: true,
            component: CoreEditorTonkeanVariablePlateUi,
        },
    },
    serializeHtml: {
        [ELEMENT_TNK_VARIABLE]: {
            isElement: true,
            serialize: ({
                children,
                element,
                additionalAttributes,
                serializeConfiguration,
            }: CoreEditorPluginSerializeElementProps<CoreEditorVariableElement>) => {
                const elementStyleParams = {
                    ...element.children[0],
                    text: undefined,
                };
                return (
                    <EvaluatedTonkeanVariable
                        expression={element.expression}
                        elementStyleParams={elementStyleParams}
                        serializeConfiguration={serializeConfiguration}
                    />
                );
            },
        },
    },
    toolbarComponents: [<CoreEditorTonkeanExpressionToolbarButton key={ELEMENT_TNK_VARIABLE} />],
});

export default coreEditorTonkeanVariablePlugin;
