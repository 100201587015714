/**
 * Created by ShaLi on 5/13/15.
 */

function percentage(numberExFilter) {
    return function (input, fractionSize, round) {
        fractionSize = fractionSize >= 0 ? fractionSize : 1;
        let number = numberExFilter(input * 100, fractionSize);
        let prefix = '';
        if (round) {
            const over = number % 10;
            if (over > 0) {
                number = number - over;
                if (number === 0) {
                    number = 10;
                    prefix = '< ';
                } else {
                    prefix = '> ';
                }
            }
        }
        return `${prefix + number}%`;
    };
}
angular.module('tonkean.app').filter('percentage', percentage);
export { percentage as PercentageFilter };
