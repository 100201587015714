<div class="tnk-google-cloud-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'REMOVE_USER_FROM_GROUP'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Group ID
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Group ID"
                    saved-expression="data.definition.groupIdExpression"
                    on-tonkean-expression-changed="onGroupIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

</div>
