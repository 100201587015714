import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import EnterpriseComponentPageLayoutLoading from './EnterpriseComponentPageLayoutLoading';
import EnterpriseComponentPageLayoutView from './EnterpriseComponentPageLayoutView';
import type { EnterpriseComponentContentPageConfiguration } from '../../entities/EnterpriseComponentContentPageConfiguration';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../entities/EnterpriseComponentExplorerListConfiguration';
import type { EnterpriseComponentPageMenuConfiguration } from '../../entities/EnterpriseComponentPageMenuConfiguration';
import type EnterpriseComponentOverviewStepDefinition from '../../entities/overview/EnterpriseComponentOverviewStepDefinition';
import useOverviewResult from '../../hooks/useOverviewResult';
import EnterpriseComponentPageLayoutContext from '../../utils/EnterpriseComponentPageLayoutContext';
import getComponentAndPermissionByIds from '../../utils/getComponentAndPermissionsById';
import { getEnterpriseComponentTabKey } from '../../utils/getEnterpriseComponentsPageConfiguration';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { BreadcrumbsZone } from '@tonkean/infrastructure';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';
import type {
    EnterpriseComponent,
    EnterpriseComponentId,
    EnterpriseComponentType,
    Person,
    PersonPermissionRoleType,
} from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    /**
     * Mapping between the enterprise component ids to their data
     */
    enterpriseComponentsByIds: Record<EnterpriseComponentId, C>;
    /**
     * Configuration for the menu section of the layout
     */
    menuConfiguration: EnterpriseComponentPageMenuConfiguration<T, C>;
    /**
     * Current enterprise component type
     */
    enterpriseComponentType: T;
    /**
     * Configuration for the enterprise components explorer.
     * Can either be sections configuration or an array of enterprise component ids.
     * In the second case there will be no sections
     */
    enterpriseComponentExplorerItems: EnterpriseComponentExplorerListSectionConfiguration[] | string[];
    /**
     * Title for the explorer section
     */
    explorerTitle: string;
    /**
     * Callback to be run when the enterprise component selection was changed
     */
    onEnterpriseComponentSelected?(newEnterpriseComponentId: string): void;
    /**
     * Callback to be run when the selected enterprise component was updated
     */
    onEnterpriseComponentUpdated(updatedEnterpriseComponent: C): void;
    /**
     * Callback to call when the user clicks on the Delete button in the menu.
     */
    onDelete?: (enterpriseComponentId: string) => void;
    /**
     * A list of ids for the enterprise components the user can delete.
     * If onDelete is provided but enterpriseComponentIdsUserCanDelete is null, all enterprise components
     * will have the Delete button enabled.
     */
    enterpriseComponentIdsUserCanDelete?: string[];
    /**
     * The enterprise component id that is currently loading. Will display a loading indicator when not null.
     */
    loadingEnterpriseComponentId?: string;
    /**
     * The name of the URL state of the page.
     */
    pageStateName: string;
    /**
     * A function to get the overview steps definitions, different for each enterprise component type
     */
    getOverviewStepDefinitions: (
        result: ConvertEnterpriseComponentTypeToOverviewResult<T>,
    ) => (EnterpriseComponentOverviewStepDefinition | undefined)[];
}

const EnterpriseComponentPageLayout = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponentsByIds,
    menuConfiguration,
    enterpriseComponentType,
    enterpriseComponentExplorerItems,
    explorerTitle,
    onEnterpriseComponentSelected,
    onEnterpriseComponentUpdated,
    onDelete,
    enterpriseComponentIdsUserCanDelete,
    loadingEnterpriseComponentId,
    getOverviewStepDefinitions,
    pageStateName,
}: Props<T, C>) => {
    const [currentEnterpriseComponentId] = useGetStateParams('enterpriseComponentId');
    const currentEnterpriseComponent = enterpriseComponentsByIds[currentEnterpriseComponentId];
    const projectManager = useAngularService('projectManager');
    const [projectId, projectOwnersWithSupportUser] = useAngularWatch(
        () => projectManager.project.id,
        () => projectManager.project.owners,
    );

    const [overviewResult, updateOverview] = useOverviewResult(
        projectId,
        currentEnterpriseComponentId,
        enterpriseComponentType,
    );

    const projectOwners = useMemo(
        () => projectOwnersWithSupportUser.filter((owner: Person) => !owner.systemUtilized),
        [projectOwnersWithSupportUser],
    );

    // Getting all the enterpriseComponentIds, in a way that will create a new item only when the ids have changed
    const enterpriseComponentIdsString = useMemo(
        () => Object.keys(enterpriseComponentsByIds).sort().join(','),
        [enterpriseComponentsByIds],
    );

    const [{ data: enterpriseComponentsAccessData }, getEnterpriseComponentsAccess] = useLazyTonkeanService(
        'getEnterpriseComponentsPersonPermissionsRole',
    );

    // Updating the current enterprise component and calling the parent callback to notify the change whenever the id changes
    useEffect(() => {
        onEnterpriseComponentSelected?.(currentEnterpriseComponentId);
    }, [currentEnterpriseComponentId, enterpriseComponentsByIds, onEnterpriseComponentSelected]);

    // Updating the enterprise component access whenever the the ids of the enterprise components change
    useEffect(() => {
        if (enterpriseComponentIdsString === '') return;

        getEnterpriseComponentsAccess(enterpriseComponentIdsString.split(','), enterpriseComponentType, projectId);
    }, [getEnterpriseComponentsAccess, enterpriseComponentIdsString, enterpriseComponentType, projectId]);

    const currentEnterpriseComponentAccessRole = useMemo(() => {
        return enterpriseComponentsAccessData?.enterpriseComponentPersonAccessType[currentEnterpriseComponentId];
    }, [enterpriseComponentsAccessData?.enterpriseComponentPersonAccessType, currentEnterpriseComponentId]);

    const menuItemIdConfigurationMap: Record<string, EnterpriseComponentContentPageConfiguration<T, C>> = useMemo(
        () => getComponentAndPermissionByIds(menuConfiguration),
        [menuConfiguration],
    );

    const contextValue = useMemo(
        () => ({
            onDelete,
            enterpriseComponentIdsUserCanDelete,
            enterpriseComponentsByIds,
            enterpriseComponentExplorerItems,
            loadingEnterpriseComponentId,
            enterpriseComponent: currentEnterpriseComponent as EnterpriseComponent,
            currentEnterpriseComponentAccessType: currentEnterpriseComponentAccessRole as PersonPermissionRoleType,
            getOverviewStepDefinitions,
            enterpriseComponentName: explorerTitle.toLowerCase(),
        }),
        [
            currentEnterpriseComponent,
            currentEnterpriseComponentAccessRole,
            enterpriseComponentExplorerItems,
            enterpriseComponentIdsUserCanDelete,
            enterpriseComponentsByIds,
            explorerTitle,
            getOverviewStepDefinitions,
            loadingEnterpriseComponentId,
            onDelete,
        ],
    );

    const breadcrumb = useMemo(
        () => ({
            id: 'enterprise-component-page-layout',
            displayName: enterpriseComponentTypeDisplayName[enterpriseComponentType].plural,
            clickable: {
                state: 'product.enterpriseComponents',
                params: { tab: getEnterpriseComponentTabKey(enterpriseComponentType), enterpriseComponentId: null },
            },
            capitalize: true,
        }),
        [enterpriseComponentType],
    );

    return enterpriseComponentsAccessData && currentEnterpriseComponent ? (
        <EnterpriseComponentPageLayoutContext.Provider value={contextValue}>
            <BreadcrumbsZone append={breadcrumb}>
                <EnterpriseComponentPageLayoutView
                    enterpriseComponentType={enterpriseComponentType}
                    allAccessData={enterpriseComponentsAccessData}
                    projectId={projectId}
                    projectOwners={projectOwners}
                    menuConfiguration={menuConfiguration}
                    setCurrentEnterpriseComponent={onEnterpriseComponentUpdated}
                    menuItemIdConfigurationMap={menuItemIdConfigurationMap}
                    explorerTitle={explorerTitle}
                    enterpriseComponentOverviewResult={overviewResult}
                    updateOverview={updateOverview}
                    pageStateName={pageStateName}
                />
            </BreadcrumbsZone>
        </EnterpriseComponentPageLayoutContext.Provider>
    ) : (
        <EnterpriseComponentPageLayoutLoading />
    );
};

export default EnterpriseComponentPageLayout;
