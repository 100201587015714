import type { EntityRef, FieldDefinition, Group, Person } from '@tonkean/tonkean-entities';
import type { AngularServices } from 'angulareact';
import { FieldDefinitionTargetType } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

export class CurrentUserService {
    public static $inject: (string & keyof AngularServices)[] = ['authenticationService'];
    constructor(
        protected authenticationService: AngularServices['authenticationService'],
        protected groupPermissions: AngularServices['groupPermissions'],
    ) {}

    /**
     * Returns true if a person was not previously invited to Tonkean, false otherwise.
     * @param person - the person to test if previously invited or not.
     * @returns {*|boolean}
     */
    public isPersonUninvited = (person) => {
        // Check if the person was not invited and that the person is not the current user.
        return (
            (!person || !person.inviteSent) && (!person || person.id !== this.authenticationService.getCurrentUser().id)
        );
    };

    /**
     * Gets whether current user has permissions to edit field of initiative.
     *
     * This function does not work for special fields.
     *
     * @param {import('@tonkean/tonkean-entities').FieldDefinition | undefined} fieldDefinition
     * @param {import('@tonkean/tonkean-entities').Person | undefined} initiativeOwner
     * @param {import('@tonkean/tonkean-entities').Group | undefined} group
     * @param {import('@tonkean/tonkean-entities').Person | undefined} initiativeCreator
     */
    public doesCurrentUserHavePermissionToEdit(
        fieldDefinition: FieldDefinition,
        initiativeOwner: Person | EntityRef<Person> | undefined,
        group: Group | EntityRef<Group> | undefined,
        initiativeCreator: Person | EntityRef<Person> | undefined,
    ) {
        const currentUserId = this.authenticationService.getCurrentUser().id;
        const updateFieldPermissions = fieldDefinition.updateFieldPermissions;

        const listOwnersAllowed = Utils.findFirst(updateFieldPermissions, (element) => element === 'LIST_OWNER');
        const trackOwnersAllowed = Utils.findFirst(updateFieldPermissions, (element) => element === 'TRACK_OWNERS');
        const collaboratorsAllowed = Utils.findFirst(updateFieldPermissions, (element) => element === 'COLLABORATORS');
        const trackCreatorAllowed = Utils.findFirst(updateFieldPermissions, (element) => element === 'TRACK_CREATOR');

        const currentUserIsCollaborator = group && this.groupPermissions.isCurrentUserGroupCollaborator(group as Group);

        const areListOwnerAllowedAndCurrentUserIsOne =
            listOwnersAllowed &&
            (group as Group)?.owners &&
            Utils.existsInArray((group as Group).owners, (listOwner) => listOwner.id === currentUserId);
        const areTrackOwnerAllowedAndCurrentUserIsOne =
            trackOwnersAllowed && initiativeOwner && initiativeOwner.id === currentUserId;
        const areCollaboratorsAllowedAndUserIsOne = collaboratorsAllowed && currentUserIsCollaborator;
        const areTrackCreatorAllowedAndCurrentUserIsOne =
            trackCreatorAllowed && initiativeCreator && initiativeCreator.id === currentUserId;

        return (
            areTrackOwnerAllowedAndCurrentUserIsOne ||
            areListOwnerAllowedAndCurrentUserIsOne ||
            areCollaboratorsAllowedAndUserIsOne ||
            areTrackCreatorAllowedAndCurrentUserIsOne ||
            fieldDefinition?.targetType === FieldDefinitionTargetType.GLOBAL
        );
    }
}

angular.module('tonkean.app').service('currentUserService', CurrentUserService);
