<tags-input
    data-ng-disabled="loadingExternal"
    class="margin-top tnk-selector common-no-outline"
    ng-model="data.selectedFields"
    display-property="label"
    key-property="name"
    text="data.latestFieldSearch"
    placeholder="Add field to track.."
    add-from-autocomplete-only="true"
    maxTags="6"
    max-tags="6"
    on-tag-added="fieldAdded($tag)"
    on-tag-removed="fieldRemoved($tag)"
    ng-class="{ 'mod-max': data.selectedFields.length >= 6 }"
    allow-leftover-text="false"
>
    <auto-complete
        source="filterExternalFields($query)"
        min-length="0"
        load-on-focus="true"
        load-on-empty="true"
        max-results-to-show="250"
    ></auto-complete>
    <i class="loading mod-tags-input" ng-show="loadingTags"></i>
</tags-input>
