<div class="channel-checker-selector">
    <div class="input-group">
        <div class="relative">
            <!-- Channel name input -->
            <input
                type="text"
                class="form-control padding-left-lg padding-right-lg"
                data-automation="private-channel-selector-template-channel-name-input"
                id="private-group"
                ng-model="data.privateChannelName"
                maxlength="250"
                required
                ng-change="onPrivateChannelNameChanged()"
                placeholder="Enter channel name"
                autocomplete="off"
            />

            <!-- Loading -->
            <span class="form-control-feedback common-z-index-forward" ng-show="data.loadingPrivateSlackChannel">
                <span class="loading-small common-size-xs"></span>
            </span>

            <!-- # Hashtag sign -->
            <span
                class="fa fa-hashtag common-size-xxs common-color-light-grey form-control-feedback"
                style="left: -5px; top: -1px; z-index: 4"
            ></span>

            <!-- V sign for validated private slack channel -->
            <span
                class="fa fa-check common-size-xxs common-color-light-grey form-control-feedback common-z-index-forward"
                ng-show="!data.loadingPrivateSlackChannel && data.validatedPrivateChannel"
            ></span>
        </div>

        <!-- Check button -->
        <span ng-click="validateChannel()" class="input-group-btn">
            <button
                type="button"
                class="btn btn-default common-color-primary"
                data-automation="private-channel-selector-template-check-button"
                data-ng-disabled="loadingPrivateGroup || !data.privateChannelName"
            >
                Check
            </button>
        </span>
    </div>

    <!-- Error finding a channel -->
    <div class="margin-top-xs common-size-xxs" ng-if="!data.loadingPrivateSlackChannel && data.privateChannelNotFound">
        <div class="common-bold">Couldn't reach #{{ data.privateChannelName }}.</div>
        <div ng-if="data.communicationIntegration.integrationType === 'SLACK'">
            If the channel exists and you have access to it, invite @{{ botName || 'tonkean' }} by typing the following
            in Slack:
            <span class="common-color-danger">
                /invite @{{ botName || 'tonkean' }} {{ data.wrongPrivateChannelName }}
            </span>
        </div>
    </div>

    <!-- Server error -->
    <div
        class="alert alert-danger margin-top padding-normal-sm common-size-xxs"
        ng-if="!data.loadingPrivateSlackChannel && data.errorLoadingPrivateSlackChannel"
    >
        <p>
            Oops, Something went wrong... Please try again in a little while.
            <br />
            <span ng-if="data.errorLoadingPrivateSlackChannel">
                Please make sure you invited Tonkean App to your private channel.
            </span>
        </p>
    </div>
</div>
