import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as InnerItemsCube } from '../../../../../../images/icons/inner-item-cube.svg';
import { ReactComponent as ItemDetailsCube } from '../../../../../../images/icons/item-details-cube.svg';
import { INNER_ITEM_DETAILS_NAME_DEFAULT, ITEM_DETAILS_NAME_DEFAULT } from '../../../itemDetailsSharedConsts';
import { useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery } from '../../ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';

import { Tooltip } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import Utils from '@tonkean/utils';

const Container = styled.div`
    width: 85px;
    margin-top: 50px;
    position: absolute;
    margin-left: 10px;
`;

const DoLineWrapper = styled.span`
    background: #d8d8d8;
    height: 2px;

    &:after {
        content: '';
        background: ${Theme.colors.gray_700};
        height: 16px;
        width: 16px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        top: 4px;
        margin-top: -9px;
        margin-left: 36px;
    }
`;

const DoLineLabel = styled.div`
    width: 45px;
    padding: 4px;
    background: ${Theme.colors.gray_700};
    color: ${Theme.colors.white};
    font-size: 8pt;
    border-radius: 3px;
    margin-top: -9px;
    position: absolute;
    z-index: 1;
`;

const DoLineLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const BoldTooltipText = styled.span`
    font-weight: 500;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    groupTargetId: TonkeanId<TonkeanType.GROUP>;
    customTriggerTargetId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    overrideDetailsTitlePlaceholder?: string;
    customTriggerWorkOnActionItem: string;
}

const ItemDetailsDoLine: React.FC<Props> = ({
    workflowVersionId,
    groupTargetId,
    customTriggerTargetId,
    overrideDetailsTitlePlaceholder,
    customTriggerWorkOnActionItem,
}) => {
    const targetIsGroup = useMemo<boolean>(() => {
        return !(customTriggerTargetId && customTriggerWorkOnActionItem === 'TRIGGERED_ACTION_ITEM');
    }, [customTriggerTargetId, customTriggerWorkOnActionItem]);

    const [{ data, fetching }] = useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery({
        variables: {
            targetId: targetIsGroup ? groupTargetId : customTriggerTargetId!,
            workflowVersionId,
        },
        suspend: false,
    });

    const label = useMemo(() => {
        const itemDetailsLabel = data?.itemDetailsByTargetIdAndWorkflowVersionId?.name;
        const hasLabel = !Utils.isNullOrEmpty(itemDetailsLabel);
        return hasLabel
            ? itemDetailsLabel
            : targetIsGroup
              ? ITEM_DETAILS_NAME_DEFAULT
              : overrideDetailsTitlePlaceholder ?? INNER_ITEM_DETAILS_NAME_DEFAULT;
    }, [data?.itemDetailsByTargetIdAndWorkflowVersionId?.name, overrideDetailsTitlePlaceholder, targetIsGroup]);

    return (
        <Container>
            <DoLineWrapper>
                <Tooltip
                    disabled={fetching}
                    placement="bottom"
                    size={TooltipSize.SMALL}
                    color={TooltipColor.DARK}
                    content={
                        <>
                            Acting on <BoldTooltipText>{label}</BoldTooltipText>
                        </>
                    }
                >
                    <DoLineLabel>
                        <DoLineLabelContainer>
                            {targetIsGroup ? <ItemDetailsCube /> : <InnerItemsCube />}
                            Do
                        </DoLineLabelContainer>
                    </DoLineLabel>
                </Tooltip>
            </DoLineWrapper>
        </Container>
    );
};

export default ItemDetailsDoLine;
