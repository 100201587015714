import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationCreateEntityModal from './ProjectIntegrationCreateEntityModal';
import ProjectIntegrationEntitiesLists from './ProjectIntegrationEntitiesLists';
import { ReactComponent as EmptyStateIcon } from '../../../../../images/icons/empty-state-actions.svg';
import useNativeEntitiesFromConsts from '../../hooks/useNativeEntitiesFromConsts';
import ProjectIntegrationPageContext from '../ProjectIntegrationPageContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    EmptyStateFilters,
    H1,
    Input,
    Paragraph,
    Placeholder,
    PlaceholderGrid,
    PlaceholderGridDirection,
    SimpleErrorStateMessage,
    StateMessage,
} from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { range } from '@tonkean/utils';

const StyledH1 = styled(H1)`
    margin-bottom: 10px;
`;

const Description = styled(Paragraph)`
    margin: 10px 0 24px;
    line-height: 20px;
`;

const EntitiesPlaceholderWrapper = styled(PlaceholderGrid)`
    margin-top: 26px;
`;

const SearchAndCreateButtonWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    max-width: 750px;
    width: 100%;
    margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
    margin-left: 10px;
`;

const ProjectIntegrationEntitiesPage: React.FC = () => {
    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);

    // Whether the create entity modal is open.
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Text query to filter entity by name.
    const [searchQuery, setSearchQuery] = useState<string>('');

    const isCreateEntityEnabled = useFeatureFlag('tonkean_feature_enable_create_project_integration_entities');

    const [{ data: entitiesResponse, loading: isLoadingEntities, error }, getProjectIntegrationEntitySummaries] =
        useLazyTonkeanService('getProjectIntegrationEntitySummaries');

    // On load get all summaries from server
    useEffect(() => {
        if (currentProjectIntegration?.id) {
            getProjectIntegrationEntitySummaries(currentProjectIntegration.id);
        }
    }, [currentProjectIntegration?.id, getProjectIntegrationEntitySummaries]);

    const nativeEntities = useNativeEntitiesFromConsts(currentProjectIntegration?.integrationType);

    const entitiesSummary = useMemo(() => {
        const nativeSummary = nativeEntities.map((entity) => ({
            displayName: entity.displayName,
            id: entity.displayName,
            isClickable: false,
        }));

        const customSummary =
            entitiesResponse?.entities.map((entity) => ({
                displayName: entity.displayName,
                id: entity.id,
                isClickable: !entity.isImported,
            })) || [];

        return [...nativeSummary, ...customSummary];
    }, [entitiesResponse?.entities, nativeEntities]);

    const filteredEntities = useMemo(() => {
        return (
            entitiesSummary.filter((entity) => {
                const trimmedLowerCaseQuery = searchQuery.toLowerCase().trim();
                return (
                    entity.id.toLowerCase().trim().includes(trimmedLowerCaseQuery) ||
                    entity.displayName.toLowerCase().trim().includes(trimmedLowerCaseQuery)
                );
            }) || []
        );
    }, [entitiesSummary, searchQuery]);

    return (
        <div>
            <StyledH1>Entities</StyledH1>

            <Description $light>
                <div>
                    Entities are objects that contain data source records. Most data sources contain various entities,
                    depending on the application type. Some common examples include Employee, Opportunity, and Post.
                </div>
                <div>
                    While some data sources contain predefined entities, you can create additional entities for your
                    data source to collect the relevant data you want to use in your solutions.
                </div>
            </Description>

            <SearchAndCreateButtonWrapper>
                <Input
                    placeholder="Search for entity"
                    value={searchQuery}
                    onChange={({ target: { value } }) => setSearchQuery(value)}
                />

                {isCreateEntityEnabled && (
                    <StyledButton
                        onClick={() => setIsOpen(true)}
                        data-automation="project-integration-Entities-page-create-new-entity"
                    >
                        Create New
                    </StyledButton>
                )}
            </SearchAndCreateButtonWrapper>

            {currentProjectIntegration?.id && (
                <ProjectIntegrationEntitiesLists
                    entities={filteredEntities}
                    projectIntegrationId={currentProjectIntegration.id}
                />
            )}

            {isLoadingEntities && (
                <EntitiesPlaceholderWrapper direction={PlaceholderGridDirection.ROWS}>
                    {range(5).map((i) => (
                        <Placeholder key={i} $height="41px" $width="750px" />
                    ))}
                </EntitiesPlaceholderWrapper>
            )}

            {isCreateEntityEnabled && filteredEntities.length === 0 && !isLoadingEntities && (
                <StateMessage title="No entities were created yet" icon={<EmptyStateIcon className="tnk-icon" />}>
                    Click on the ‘Create New’ to create a new entity
                </StateMessage>
            )}

            {!entitiesSummary && !isLoadingEntities && (
                <EmptyStateFilters
                    icon={
                        <span className="tnk-icon">
                            <EmptyStateIcon />
                        </span>
                    }
                    title={`No entities that match "${searchQuery}"`}
                    clearFilters={() => setSearchQuery('')}
                />
            )}

            {error && <SimpleErrorStateMessage error={error} />}

            {isOpen && (
                <ProjectIntegrationCreateEntityModal
                    isOpen={isOpen}
                    projectIntegrationId={(currentProjectIntegration as ProjectIntegration).id}
                    closeModal={() => setIsOpen(false)}
                />
            )}
        </div>
    );
};

export default ProjectIntegrationEntitiesPage;
