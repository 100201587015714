import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

const Dynamics365FOAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [instanceUri, setInstanceUri] = useState<string>('');

    const authenticateWithOAuth = async () => {
        try {
            if (instanceUri.indexOf('https://') !== 0 && instanceUri.indexOf('http://') !== 0) {
                setInstanceUri(`https://${instanceUri}`);
            }

            const clientId = environment.integrationKeysMap.dynamics;
            const redirectUri = environment.integrationKeysMap.dynamicsUri;

            const code = await oauthHandler.dynamics(clientId, instanceUri, redirectUri);

            authenticate({ code, instanceUri, redirectUri });
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="api-token-authentication">
                <div className="flex flex-col">
                    <div className="form-group">
                        <label htmlFor="instance_url" className="col-md-4 control-label">
                            Dynamics 365 instance
                        </label>
                        <div className="col-sm-7">
                            <input
                                value={instanceUri}
                                className="form-control"
                                onChange={({ target }) => setInstanceUri(target.value)}
                                id="instance_url"
                                autoComplete="off"
                                placeholder="Instance URL"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex mod-center margin-top-20">
                        <button
                            className="btn btn-secondary btn-lg"
                            onClick={authenticateWithOAuth}
                            disabled={!instanceUri}
                        >
                            Authenticate with {integrationConfiguration.displayName}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dynamics365FOAuthenticationComponent;
