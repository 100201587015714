<span class="flex margin-bottom-xs flex-col" ng-repeat="field in data.fields">
    <!-- Field to filter on -->
    <span
        class="filter-min-width margin-xs-bottom-xs flex-grow flex-shrink flex-vmiddle"
        ng-class="{ 'margin-bottom-xs': smallMod }"
    >
        <span class="filter-min-width margin-xs-bottom-xs flex-grow flex-shrink overflow-hidden">
            <tnk-field-selector
                items-source="COLUMN"
                selected-field="field"
                selected-field-identifier="$index"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                include-global-fields="data.includeGlobalFields"
                do-not-include-field-ids-set="data.doNotIncludeFieldIdsSet"
                group-by="groupBy"
                display-integration-indication="true"
                on-field-selected="onFieldOptionSelected(selectedField, selectedFieldIdentifier)"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                auto-create-column-fields="true"
                add-fields-quick-create-options="true"
                place-holder="Search data field"
                special-fields-for-features="data.specialFieldsForFeatures"
                append-to-body="true"
            ></tnk-field-selector>
        </span>

        <span ng-if="data.actions.length" class="margin-left" uib-dropdown>
            <div
                class="
                    svg-icon-smd
                    common-color-grey
                    svg-icon-hover-primary svg-icon-align-text-base
                    btn-on-hover
                    pointer
                    flex-no-shrink
                "
                ng-click="toggleMenu($index)"
                uib-dropdown-toggle=""
            >
                <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
            </div>

            <ul class="dropdown-menu-right" uib-dropdown-menu>
                <li ng-repeat="action in data.actions">
                    <a ng-click="action.action(field); onFieldsChange();" class="padding-left-none">
                        <span
                            ng-if="action.name === 'shortFieldAction'"
                            ng-include="'../tnkLogicWorkerComponent/logicConfigurationComponents/tnkPersonInquiryLogicConfiguration/templates/isShortFieldAction.template.html'"
                        ></span>
                    </a>
                </li>

                <li ng-if="$index > 0">
                    <a ng-click="moveFieldUp($index)" class="padding-left-none">
                        <span class="flex-vmiddle padding-left">
                            <span>
                                <span class="svg-icon-xs common-color-grey margin-right-xs">
                                    <tnk-icon src="/images/icons/arrow-up.svg"></tnk-icon>
                                </span>
                            </span>
                            <span class="flex-grow">Move Up</span>
                        </span>
                    </a>
                </li>

                <li ng-if="$index < data.fields.length - 1">
                    <a ng-click="moveFieldDown($index)" class="padding-left-none">
                        <span class="flex-vmiddle padding-left">
                            <span>
                                <span class="svg-icon-xs common-color-grey margin-right-xs">
                                    <tnk-icon src="/images/icons/arrow-down.svg"></tnk-icon>
                                </span>
                            </span>
                            <span class="flex-grow">Move Down</span>
                        </span>
                    </a>
                </li>

                <li>
                    <a ng-click="removeField($index)" class="padding-left-none">
                        <span class="flex-vmiddle padding-left">
                            <span>
                                <span class="svg-icon-xs common-color-grey margin-right-xs">
                                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                </span>
                            </span>
                            <span class="flex-grow">Delete</span>
                        </span>
                    </a>
                </li>
            </ul>
        </span>

        <!-- If no custom actions - Remove field -->
        <div ng-if="!data.actions || !data.actions.length" class="margin-left">
            <div
                class="
                    svg-icon-xs
                    common-color-grey
                    svg-icon-hover-primary svg-icon-align-text-base
                    btn-on-hover
                    pointer
                    flex-no-shrink
                "
                ng-click="removeField($index)"
                uib-tooltip="Delete Field"
                tooltip-placement="auto"
                tooltip-append-to-body="true"
            >
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </div>
        </div>
    </span>
    <div ng-hide="!field.fieldLabel" class="common-size-xxs common-color-grey3 margin-top-6">
        <tnk-icon src="/images/icons/Label.svg" class="margin-right-xxs"></tnk-icon>
        Labeled as: {{ field.fieldLabel }}
    </div>
</span>

<!-- Add field -->
<div class="margin-top-xs flex-vmiddle">
    <div class="flex-grow">
        <a ng-click="addField()">+ Add field</a>
    </div>
    <a ng-click="$root.$broadcast('selectWorkflowOutlineTab', { tabKey: 'fields' });" class="margin-right-lg">
        Manage fields
    </a>
</div>
