import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const FormSettingsLabel = styled.label<{ disabled?: boolean }>`
    font-weight: unset;
    letter-spacing: unset;
    display: block;

    &[disabled] {
        background-color: ${Theme.colors.brightestGray};
    }
`;

export default FormSettingsLabel;
