import type { Project } from '@tonkean/tonkean-entities';

/**
 * Safely gets the current user's project context or returns null if it can't retrieve it.
 * @param currentUser - the current user for which we want to get the project context (if null, null is returned).
 * @param project - the current project (if null, null is returned).
 */
export function getCurrentUserProjectContext(currentUser: any, project: Project) {
    let userProjectContext = null;
    if (project?.id && currentUser?.projectContexts?.[project.id]) {
        userProjectContext = currentUser.projectContexts[project.id];
    }

    return userProjectContext;
}
