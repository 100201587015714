import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ProcessBuilderAIChat from './ProcessBuilderAIChat';
import ProcessBuilderAnswerQuestionsAIConversationView from './ProcessBuilderAnswerQuestionsAIConversationView';
import ProcessBuilderQuestionMessage from './ProcessBuilderQuestionMessage';

import { useProject } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import type { ProcessBuilderMissingQuestion } from '@tonkean/tonkean-entities';

const ChatMessage = styled.div`
    margin-left: 18px;
`;

const StyledSmartConversationResponseText = styled(SmartConversationResponseText)`
    display: flex;
    align-items: start;
    margin-bottom: 20px;
`;

const MessagesList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

interface Props {
    questions: ProcessBuilderMissingQuestion[];
    onQuestionsAnswerCompleted: (userReplies: string[]) => void;
}

const ProcessBuilderQuestionsPopup: React.FC<Props> = ({ questions, onQuestionsAnswerCompleted }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [userReplies, setUserReplies] = useState<string[]>([]);

    const { id: projectId } = useProject();

    const shownQuestions = useMemo(() => {
        return questions?.filter((element, index) => index <= currentQuestionIndex) || [];
    }, [questions, currentQuestionIndex]);

    const onFeedbackReceived = useCallback(
        (continueToNextQuestion: boolean, userReply: string) => {
            if (continueToNextQuestion) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setUserReplies((current) => [...current, userReply]);
            }
        },
        [currentQuestionIndex],
    );

    useEffect(() => {
        if (currentQuestionIndex === questions.length) {
            onQuestionsAnswerCompleted(userReplies);
        }
    }, [currentQuestionIndex, questions, onQuestionsAnswerCompleted, userReplies]);

    return questions.length ? (
        <ProcessBuilderAIChat>
            <MessagesList>
                {shownQuestions.map((question, index) => (
                    <ChatMessage key={question.questionId}>
                        <StyledSmartConversationResponseText>
                            <ProcessBuilderQuestionMessage isFirstMessage={index === 0} text={question.questionText} />
                        </StyledSmartConversationResponseText>

                        <ProcessBuilderAnswerQuestionsAIConversationView
                            projectId={projectId}
                            questionId={question.questionId}
                            onAnswerOnMissingQuestionFeedbackReceived={onFeedbackReceived}
                        />
                    </ChatMessage>
                ))}

                {(currentQuestionIndex === questions.length || !shownQuestions.length) && (
                    <ChatMessage>
                        <ProcessBuilderQuestionMessage
                            text={
                                'Thank you for providing the missing details.\n' +
                                'We will now optimize the process and provide a detailed process mapping'
                            }
                        />
                    </ChatMessage>
                )}
            </MessagesList>
        </ProcessBuilderAIChat>
    ) : (
        <></>
    );
};

export default ProcessBuilderQuestionsPopup;
