/**
 * Created by ShaLi on 8/6/15.
 */

function GlobalAlertsCtrl($scope, $rootScope, $log, $timeout, errorFilter) {
    let timer;
    const defaultType = 'danger';

    const typeToTimeout = { danger: 10_000, warning: 10_000, success: 3000, info: 10_000 };

    const defaults = {
        type: defaultType,
        msg: '',
        timeout: typeToTimeout[defaultType],
    };

    $scope.alertIcons = {
        danger: 'fa-exclamation-circle',
        warning: 'fa-exclamation-triangle',
        success: 'fa-check',
        info: 'fa-info-circle',
    };

    $scope.alerts = [];

    $rootScope.comingSoon = function () {
        $scope.addAlert({
            type: 'info',
            msg: 'Coming soon...',
            timeout: typeToTimeout['info'],
        });
    };

    $rootScope.$on('alert', function (e, data) {
        $scope.addAlert(data);
    });

    $scope.addAlert = function (alert) {
        // Support for string input
        if (angular.isString(alert)) {
            alert = { msg: alert };
        }

        // Support for error object (http error)
        if (angular.isObject(alert) && alert.status) {
            alert = { msg: errorFilter(alert) };
        }

        // Applying defaults
        const timeout = alert.timeout || typeToTimeout[alert.type];
        const modifiedAlert = { ...defaults, ...alert, timeout };

        // Verifying alert type
        if (!$scope.alertIcons[alert.type]) {
            $log.error('Invalid alert type: %s', modifiedAlert.type);
            modifiedAlert.type = defaults.type;
        }
        $scope.alerts.push(modifiedAlert);
    };

    $scope.closeAlert = function (index) {
        const alert = $scope.alerts[index];
        if (alert) {
            alert.closed = true;
        }
        if (timer) {
            $timeout.cancel(timer);
        }
        timer = $timeout(cleanClosed, 400); // This time should be synced with the time in the CSS.
    };

    function cleanClosed() {
        $scope.alerts = $scope.alerts.filter(function (alert) {
            return !alert.closed;
        });
        timer = null;
    }
}
angular.module('tonkean.shared').controller('GlobalAlertsCtrl', GlobalAlertsCtrl);
