import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';

import { useAsyncMethod, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { CategoricalFieldInstanceChart } from '@tonkean/fields';
import { ChartDisplayType } from '@tonkean/infrastructure';
import type { Group, ItemInterfaceWidget, WorkflowVersion } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

interface Props {
    widgetId: ItemInterfaceWidget['id'];
    configuration: SolutionSiteFieldChartWidgetConfiguration;
    entityVersionType: WorkflowVersionType;
    selectedGroup: Group;
}

const SolutionSiteCategoricalAggregateFieldChartWidget: React.FC<Props> = ({
    widgetId,
    configuration,
    entityVersionType,
    selectedGroup,
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const [{ data: categoricalFieldChartResults, loading }, getCategoricalSolutionSiteFieldChartWidgetData] =
        useLazyTonkeanService('getCategoricalSolutionSiteFieldChartWidgetData');

    const workflowVersionId = useMemo<WorkflowVersion['id']>(() => {
        return entityVersionType === WorkflowVersionType.DRAFT
            ? selectedGroup.draftWorkflowVersionId
            : selectedGroup.publishedWorkflowVersionId;
    }, [entityVersionType, selectedGroup.draftWorkflowVersionId, selectedGroup.publishedWorkflowVersionId]);

    const {
        data: workflowVersion,
        loading: loadingWorkflowVersion,
        error: workflowVersionError,
    } = useAsyncMethod(workflowVersionManager, 'getCachedWorkflowVersionOrGetFromServer', workflowVersionId);

    useEffect(() => {
        getCategoricalSolutionSiteFieldChartWidgetData(entityVersionType, widgetId);
    }, [
        getCategoricalSolutionSiteFieldChartWidgetData,
        entityVersionType,
        widgetId,
        configuration.groupId,
        configuration.filters,
    ]);

    return (
        <CategoricalFieldInstanceChart
            workflowVersion={workflowVersion}
            fieldType={configuration.definition?.fieldType}
            fieldId={configuration.definition?.aggregatedDefinitionId}
            chartDisplayType={configuration.chartCategoricalDisplayType || ChartDisplayType.DOUGHNUT}
            optionallyEvaluatedResults={categoricalFieldChartResults?.data}
            loading={loading || loadingWorkflowVersion}
        />
    );
};

export default SolutionSiteCategoricalAggregateFieldChartWidget;
