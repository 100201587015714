import type { AngularServices } from 'angulareact';
import {
    type BackgroundProcessStatus,
    type BackgroundProcessType,
    type BackgroundProcessesFetchResults,
    type Group,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersion,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

/**
 * A service to help with groups operations
 */
export class GroupInfoManager {
    constructor(
        private $rootScope: AngularServices['$rootScope'],
        private $q: AngularServices['$q'],
        private projectManager: AngularServices['projectManager'],
        private tonkeanService: AngularServices['tonkeanService'],
        private workflowVersionManager: AngularServices['workflowVersionManager'],
        private syncConfigCacheManager: AngularServices['syncConfigCacheManager'],
        private customFieldsManager: AngularServices['customFieldsManager'],
        private liveReportHelper: AngularServices['liveReportHelper'],
        private groupPermissions: AngularServices['groupPermissions'],
    ) {
        this.$rootScope.$watchCollection('pm.groups', (groups: Group[]) => {
            if (groups) {
                for (const group of groups) {
                    this.groupPermissions.calculateOwnersForGroup(group);
                }
            }
        });
    }

    private groupsRelatedInfoWasFetchedFor = new Set<TonkeanId<TonkeanType.GROUP>>();

    getGroups = (forceUpdate?: boolean): Promise<Group[]> => {
        const tenMinutes = 10 * 60 * 1000;

        return this.projectManager.getGroups(forceUpdate, tenMinutes);
    };

    getGroupsByIds = (
        groupIds: TonkeanId<TonkeanType.GROUP>[],
        forceUpdate?: boolean,
        shouldGetRelatedInfo?: boolean,
        fetchOnlyVersionType?: WorkflowVersionType,
    ): Promise<Group[]> => {
        const tenMinutes = 10 * 60 * 1000;
        return this.projectManager.getGroupsByIds(groupIds, forceUpdate, tenMinutes).then((fetchedGroups) => {
            if (shouldGetRelatedInfo) {
                return this.getGroupsRelatedInfo(fetchedGroups, forceUpdate, true, fetchOnlyVersionType);
            }

            return fetchedGroups;
        });
    };

    getGroupsFull = (forceUpdate?: boolean): Promise<Group[]> => {
        const tenMinutes = 10 * 60 * 1000;

        return this.projectManager.getGroups(forceUpdate, tenMinutes).then((fetchedGroups) => {
            return this.getGroupsRelatedInfo(fetchedGroups, forceUpdate);
        });
    };

    getGroup = (
        groupId: TonkeanId<TonkeanType.GROUP>,
        forceUpdate?: boolean,
        fetchFieldDefinitions?: boolean,
    ): Promise<Group> => {
        const tenMinutes = 10 * 60 * 1000;

        return this.projectManager.getGroup(groupId, forceUpdate, tenMinutes).then((fetchedGroup) => {
            return this.getGroupsRelatedInfo(
                [fetchedGroup].filter((group) => group !== undefined),
                forceUpdate,
                fetchFieldDefinitions,
            ).then(() => fetchedGroup);
        });
    };

    getGroupNameAndProjectIntegrationId = (
        groupId: TonkeanId<TonkeanType.GROUP>,
        forceUpdate?: boolean,
        fetchFieldDefinitions?: boolean,
    ): Promise<Group> => {
        const tenMinutes = 10 * 60 * 1000;
        if (
            this.$rootScope?.features?.[this.projectManager.project.id]
                ?.tonkean_feature_remove_redundant_call_to_group_related_info_in_tonkean_expression
        ) {
            return this.projectManager.getGroup(groupId, forceUpdate, tenMinutes);
        } else {
            return this.projectManager.getGroup(groupId, forceUpdate, tenMinutes).then((fetchedGroup) => {
                return this.getGroupsRelatedInfo(
                    [fetchedGroup].filter((group) => group !== undefined),
                    forceUpdate,
                    fetchFieldDefinitions,
                ).then(() => fetchedGroup);
            });
        }
    };

    getGroupName = (groupId: TonkeanId<TonkeanType.GROUP>, forceUpdate?: boolean) => {
        const tenMinutes = 10 * 60 * 1000;

        return this.projectManager.getGroup(groupId, forceUpdate, tenMinutes).then((fetchedGroup) => {
            return fetchedGroup.name;
        });
    };

    /**
     * Gets a group by the given group id.
     */
    getGroupById = (groupId: TonkeanId<TonkeanType.GROUP>, forceServer?: boolean): Promise<Group> => {
        if (forceServer || !this.projectManager.groupsMap[groupId]) {
            return this.tonkeanService.getGroupById(groupId).then((group) => {
                // Since this request gets the full group, there's no problem to delete missing fields.
                this.projectManager.updateGroup(group, true);

                return this.$q.resolve(group);
            });
        } else {
            return this.$q.resolve(this.projectManager.groupsMap[groupId]);
        }
    };

    /**
     * Gets a group by the given group id from cache.
     */
    getGroupByIdFromCache = (groupId: TonkeanId<TonkeanType.GROUP>): Group | undefined => {
        return this.projectManager.groupsMap[groupId];
    };

    getBackgroundProcessesByWorkflowVersionType = (
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType,
        backgroundProcessType: BackgroundProcessType,
        backgroundProcessStatuses: BackgroundProcessStatus[],
        skip: number,
        limit: number,
    ): Promise<BackgroundProcessesFetchResults> => {
        return this.tonkeanService
            .getBackgroundProcessesByWorkflowVersionType(
                groupId,
                workflowVersionType,
                null,
                backgroundProcessType,
                backgroundProcessStatuses,
                skip,
                limit,
            )
            .catch(() => {
                return this.$q.reject();
            });
    };

    cacheGroupRelatedInfo = (workflowVersion: WorkflowVersion, fieldDefinitions) => {
        // Cache the version itself.
        this.workflowVersionManager.cacheWorkflowVersion(workflowVersion);

        this.liveReportHelper.initializeCache(workflowVersion.workflowVersionType, workflowVersion.groupId);

        // Cache the field definitions of the version.
        // Its important to call the cache method even if we dont have any fields to cache as it initializes the objects
        this.customFieldsManager.cacheFieldDefinitionsForWorkflowVersion(
            workflowVersion.id,
            fieldDefinitions || [],
            undefined,
            workflowVersion.groupId,
        );

        // Cache the sync config of the version.
        this.syncConfigCacheManager.cacheSyncConfig(workflowVersion.syncConfig);
    };

    private getGroupsRelatedInfo = (
        fetchedGroups: Group[],
        forceUpdate,
        fetchFieldDefinitions = true,
        fetchOnlyVersionType?: WorkflowVersionType,
    ) => {
        // Getting only the groups that we haven't fetched the version for just yet.
        const missingDraftVersionsGroupIds: TonkeanId<TonkeanType.GROUP>[] = [];
        const missingDraftWorkflowVersionIds: TonkeanId<TonkeanType.WORKFLOW_VERSION>[] = [];
        const missingPublishedVersionsGroupIds: TonkeanId<TonkeanType.GROUP>[] = [];
        const missingPublishedWorkflowVersionIds: TonkeanId<TonkeanType.WORKFLOW_VERSION>[] = [];

        fetchedGroups.forEach((fetchedGroup) => {
            // Draft version
            if (fetchOnlyVersionType === undefined || fetchOnlyVersionType === WorkflowVersionType.DRAFT) {
                if (
                    forceUpdate ||
                    !this.workflowVersionManager.hasWorkflowVersion(fetchedGroup.draftWorkflowVersionId)
                ) {
                    missingDraftVersionsGroupIds.push(fetchedGroup.id);
                }
                if (forceUpdate || !this.groupsRelatedInfoWasFetchedFor.has(fetchedGroup.id)) {
                    missingDraftWorkflowVersionIds.push(fetchedGroup.draftWorkflowVersionId);
                }
            }

            // Published version
            if (fetchOnlyVersionType === undefined || fetchOnlyVersionType === WorkflowVersionType.PUBLISHED) {
                if (
                    forceUpdate ||
                    !this.workflowVersionManager.hasWorkflowVersion(fetchedGroup.publishedWorkflowVersionId)
                ) {
                    missingPublishedVersionsGroupIds.push(fetchedGroup.id);
                }
                if (forceUpdate || !this.groupsRelatedInfoWasFetchedFor.has(fetchedGroup.id)) {
                    missingPublishedWorkflowVersionIds.push(fetchedGroup.publishedWorkflowVersionId);
                }
            }
        });

        // Fetch draft versions
        const fetchDraftVersionsPromise: Promise<{ entities: [] }> = missingDraftVersionsGroupIds.length
            ? this.tonkeanService.getWorkflowVersionOfType(
                  this.projectManager.project.id,
                  'DRAFT',
                  missingDraftVersionsGroupIds,
                  0,
                  missingDraftVersionsGroupIds.length,
              )
            : this.$q.resolve({ entities: [] });

        // Fetch published versions
        const fetchPublishedVersionsPromise = missingPublishedVersionsGroupIds.length
            ? this.tonkeanService.getWorkflowVersionOfType(
                  this.projectManager.project.id,
                  'PUBLISHED',
                  missingPublishedVersionsGroupIds,
                  0,
                  missingPublishedVersionsGroupIds.length,
              )
            : this.$q.resolve({ entities: [] });

        // Fetch all field definitions for missing workflow versions by type
        let draftFieldDefinitionsPromise = this.$q.resolve({});
        if (missingDraftWorkflowVersionIds.length && fetchFieldDefinitions) {
            draftFieldDefinitionsPromise = this.customFieldsManager.getFieldDefinitionsByWorkflowVersions(
                this.projectManager.project.id,
                missingDraftWorkflowVersionIds,
            );

            fetchedGroups.forEach((group) => this.groupsRelatedInfoWasFetchedFor.add(group.id));
        }

        let publishedFieldDefinitionsPromise = this.$q.resolve({});
        if (missingPublishedWorkflowVersionIds.length && fetchFieldDefinitions) {
            publishedFieldDefinitionsPromise = this.customFieldsManager.getFieldDefinitionsByWorkflowVersions(
                this.projectManager.project.id,
                missingPublishedWorkflowVersionIds,
            );

            fetchedGroups.forEach((group) => this.groupsRelatedInfoWasFetchedFor.add(group.id));
        }

        return this.$q
            .all([
                fetchDraftVersionsPromise,
                fetchPublishedVersionsPromise,
                draftFieldDefinitionsPromise,
                publishedFieldDefinitionsPromise,
            ])
            .then((resultsArray) => {
                const draftVersionsResponse = resultsArray[0] as { entities: WorkflowVersion[] };
                const publishedVersionsResponse = resultsArray[1] as { entities: WorkflowVersion[] };
                const workflowVersionIdToFieldsMap = { ...resultsArray[2], ...resultsArray[3] };
                const groupIds = fetchedGroups.map((group) => group.id);

                return this.liveReportHelper
                    .loadLiveReportFieldDefinitionsOfGroups(this.projectManager.project.id, groupIds)
                    .then(() => {
                        [...draftVersionsResponse!.entities, ...publishedVersionsResponse!.entities].forEach(
                            (version) => this.cacheGroupRelatedInfo(version, workflowVersionIdToFieldsMap[version.id]),
                        );

                        return fetchedGroups;
                    });
            });
    };
}

angular.module('tonkean.app').service('groupInfoManager', GroupInfoManager);
