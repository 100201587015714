import React from 'react';
import styled from 'styled-components';

import type { BaseProjectIntegrationActionManagerProps } from '../../../../../../../components/state.product.projectIntegrationActionManager/baseProjectIntegrationActionManagerProps';
import type { AdditionalSidePaneConfiguration } from '../../../../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationActionCRUDPage from '../ProjectIntegrationActionCRUDPage/ProjectIntegrationActionCRUDPage';

const Wrapper = styled.div`
    padding-top: 30px;
    padding-left: 24px;
`;

const ProjectIntegrationActionRequestTab: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationActionManagerProps>
> = ({ setProjectIntegrationAction, projectIntegrationAction, projectIntegration }) => {
    return (
        <Wrapper>
            <ProjectIntegrationActionCRUDPage
                setProjectIntegrationAction={setProjectIntegrationAction}
                projectIntegrationAction={projectIntegrationAction}
                projectIntegration={projectIntegration}
            />
        </Wrapper>
    );
};

export default ProjectIntegrationActionRequestTab;
