import type { MouseEvent } from 'react';
import React, { useMemo, useState } from 'react';

import type { RegexBaseItem } from '../entities/RegexBaseItem';
import { RegexExpressionPart } from '../entities/RegexExpressionPart';
import { RegexMatchedGroupPart } from '../entities/RegexMatchedGroupPart';
import type { TreeNode } from '../entities/TreeNode';

import { classNames } from '@tonkean/utils';

interface Props {
    rootPart: boolean;
    part: TreeNode<RegexBaseItem>;
    selectedPart: { match: number; group: number | undefined } | undefined;
    setTooltip: (id: string | number, label: { label: string; subLabel?: string } | false) => void;
}

/**
 * A react component for showing a tree node of regex parts
 */
export const RegexPart: React.FC<Props> = React.memo(function RegexPart({ rootPart, part, selectedPart, setTooltip }) {
    const [localHover, setLocalHover] = useState(false);

    // Break down this regex part to a flat array of RegexTreeNodeItems and the strings between them
    const subParts = useMemo(() => {
        const subParts: (TreeNode<RegexBaseItem> | string)[] = [];

        let currentIndexInsidePart = 0;
        part.children.forEach((subPart) => {
            const textBefore = part.item.value.substring(
                currentIndexInsidePart,
                subPart.item.startIndex - part.item.startIndex,
            );
            if (textBefore) {
                subParts.push(textBefore);
            }

            currentIndexInsidePart = subPart.item.endIndex - part.item.startIndex;
            subParts.push(subPart);
        });

        // Get the strings that are after the last child, or
        // if there are no children, get the entire string
        const textAfter = part.item.value.slice(Math.max(0, currentIndexInsidePart));
        if (textAfter) {
            subParts.push(textAfter);
        }

        return subParts;
    }, [part]);

    // Check if the current part is being hovered
    const isSelectedPartByMatchId =
        !(part.item instanceof RegexMatchedGroupPart) || part.item.matchIndex === selectedPart?.match;
    const isSelectedPartByGroupId = selectedPart?.group === part.item.groupIndex;
    const isSelected = selectedPart && isSelectedPartByMatchId && isSelectedPartByGroupId;

    const isHovered = localHover || isSelected;

    const setHover = (event: MouseEvent<HTMLElement>) => {
        if (!rootPart && part.item.index) {
            setTooltip(part.item.index, part.item.getTooltipLabel());
            setLocalHover(() => true);
            event.stopPropagation();
        }
    };
    const setUnHover = (event: MouseEvent<HTMLElement>) => {
        if (!rootPart && part.item.index) {
            setLocalHover(() => false);
            setTooltip(part.item.index, false);
            event.stopPropagation();
        }
    };

    return (
        <span
            style={{ backgroundColor: part.item.color }}
            className={classNames({
                'regex-part': true,
                'regex-part-root': rootPart,
                'mod-hover': isHovered,
                'mod-error': part.item instanceof RegexExpressionPart && part.item.hasError,
            })}
            onMouseOver={setHover}
            onMouseOut={setUnHover}
        >
            {subParts.map((subPart, index) =>
                typeof subPart === 'string' ? (
                    subPart
                ) : (
                    <RegexPart
                        key={subPart.item.index || `c${index}`}
                        rootPart={false}
                        part={subPart}
                        selectedPart={selectedPart}
                        setTooltip={setTooltip}
                    />
                ),
            )}
        </span>
    );
});
