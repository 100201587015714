function ManageColumnsModalCtrl(
    $rootScope,
    $scope,
    $uibModalInstance,
    customFieldsManager,
    groupInfoManager,
    groupId,
    workflowVersionId,
    workflowVersionManager,
) {
    $scope.cfm = customFieldsManager;
    $scope.pm = $rootScope.pm;
    $scope.groupId = groupId;
    $scope.group = $scope.pm.groupsMap[$scope.groupId];
    $scope.workflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

    $scope.data = {
        workflowVersionId,
        saveMangeColumns: false,
        columnsCtrlObject: {},
    };

    $scope.init = function () {
        $scope.data.columnsCtrlObject.onSaveChangesErrorOccured = $scope.onSaveChangesErrorOccured;
    };

    $scope.selectedItem = null;

    /**
     * Saves the inner list selected fields as the group selected fields.
     */
    $scope.saveChanges = function () {
        Promise.all([$scope.data.columnsCtrlObject.saveChanges()]).then(() => $uibModalInstance.close());
    };

    $scope.onSaveChangesErrorOccured = function (err) {
        $scope.errorSavingFields = err;
    };

    /**
     * Once the create\edit modal is closed, we update the collections so that the manage columns ui is updated.
     */
    $scope.onCreateDefModalClosed = function (field, fromUpdate) {
        if (fromUpdate === false) {
            $scope.$dismiss();
        }

        $scope.loading = true;
        // Get latest fields.
        groupInfoManager.getGroup($scope.groupId, true).then(function () {
            $scope.loading = false;
        });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageColumnsModalCtrl', ManageColumnsModalCtrl);
