<div class="setup-group-preview common-close-btn-container clearfix">
    <div class="common-close-btn svg-icon-sm common-color-white" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>
    <div class="flex flex-col common-height-full margin-auto setup-group-preview-content relative">
        <!--Content-->
        <div class="flex-basis-33 visible-xs">
            <h2>Preview example</h2>
        </div>

        <!--screen shot-->
        <!--<div class="story common-bg mod-black flex-basis-60 relative">-->
        <div class="story relative flex-grow">
            <div class="mac-placeholder">
                <div class="mac-placeholder-content">
                    <div class="relative common-width-full common-height-full flex flex-col mod-center">
                        <!-- List Name -->
                        <div
                            class="user-select-none cursor-default story-title"
                            ng-class="{ 'hidden-xs': state.currentFrame.hideTitleOnMobile }"
                        >
                            {{ listState.currentList.title }}
                        </div>

                        <!-- new list header-->
                        <div
                            class="user-select-none cursor-default story-frame-fake-header flex-vmiddle hidden-xs"
                            ng-class="{ 'mod-iphone': state.currentFrameIndex === 1 || state.currentFrameIndex === 2 }"
                        >
                            <img src="/images/onBoarding/icon-automation.png" height="100%" />
                            <span>Tonkean</span>
                        </div>

                        <!-- Current Story Frame -->
                        <img
                            class="user-select-none cursor-default story-frame hidden-xs"
                            ng-src="{{ state.currentFrame.image }}"
                            width="100%"
                            ng-click="pauseResume()"
                        />
                        <img
                            class="user-select-none cursor-default story-frame visible-xs-inline-block"
                            ng-src="{{ state.currentFrame.mobileImage }}"
                            width="100%"
                            ng-click="pauseResume()"
                        />
                    </div>
                </div>
            </div>

            <!-- Controls -->
            <div class="magic-box">
                <div class="controls flex-vmiddle mod-center">
                    <div class="flex flex-col">
                        <!-- Frame Indicators -->
                        <div class="flex mod-center flex-basis-20">
                            <div
                                ng-repeat="frame in frames"
                                class="indicator"
                                ng-class="{
                                    seen: state.currentFrameIndex > $index,
                                    active: $index === state.currentFrameIndex,
                                    reset: state.resetting
                                }"
                                ng-click="frameOnClick($index)"
                                tooltip-enable="{{ !isMobile }}"
                                uib-tooltip-template="'./storyIndicatorThumbnailTemplate.template.html'"
                            ></div>
                        </div>
                        <div class="flex flex-basis-80 margin-top-md">
                            <div class="controls-icon">
                                <i ng-if="state.storyStarted === false" ng-click="startStory()">
                                    <tnk-icon src="/images/icons/play.svg"></tnk-icon>
                                </i>
                                <i
                                    ng-if="state.storyStarted === true && state.storyPaused === false"
                                    ng-click="pause()"
                                >
                                    <tnk-icon src="/images/icons/pause.svg"></tnk-icon>
                                </i>
                                <i
                                    ng-if="state.storyStarted === true && state.storyPaused === true"
                                    ng-click="resume()"
                                >
                                    <tnk-icon src="/images/icons/play.svg"></tnk-icon>
                                </i>
                            </div>
                            <span class="controls-title common-bold margin-left user-select-none cursor-default">
                                {{ state.currentFrame.title }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
