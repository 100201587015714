import { useAngularService } from 'angulareact';
import React from 'react';

import useGetModuleMarketplaceItemAdditionalSections from './marketplace/hooks/useGetModuleMarketplaceItemAdditionalSections';
import MarketplaceItemCreateEditModal from './MarketplaceItemCreateEditModal';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    groupName: string;
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    open: boolean;
    onClose: () => void;
}

const ModuleMarketplaceItemCreateModal: React.FC<Props> = ({
    projectId,
    groupId,
    groupName,
    workflowFolderId,
    open,
    onClose,
}) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const additionalSections = useGetModuleMarketplaceItemAdditionalSections();

    const { data: workflowFolder } = useAsyncMethod(
        workflowFolderManager,
        'getWorkflowFolder',
        projectId,
        workflowFolderId,
    );

    if (!workflowFolder) {
        return null;
    }

    return (
        <MarketplaceItemCreateEditModal
            open={open}
            onClose={onClose}
            projectId={workflowFolder.project.id}
            entityIdToCopy={groupId}
            entityNameToCopy={groupName}
            marketplaceItemType={MarketplaceItemType.MODULE}
            workflowFolder={workflowFolder}
            additionalSections={additionalSections}
        />
    );
};

export default ModuleMarketplaceItemCreateModal;
