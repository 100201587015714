import template from './tnkMonitorTracksLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkMonitorTracksLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        control: '<',
        onActionsChanged: '&',
    },
    template,
    controller: 'MonitorTracksLogicConfigurationCtrl',
});
