import template from './tnkPricingOverview.template.html?angularjs';

function tnkPricingOverview() {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        template,
        controller: [
            '$scope',
            '$rootScope',
            '$location',
            '$stateParams',
            '$timeout',
            '$state',
            'tonkeanService',
            'projectManager',
            function (
                $scope,
                $rootScope,
                $location,
                $stateParams,
                $timeout,
                $state,
                tonkeanService,
                projectManager,
            ) {
                $scope.pm = projectManager;
                $scope.lps = $rootScope.lps;
                $scope.data = {
                    tiersToPlans: {},
                };

                $scope.populatePlans = function (couponCode) {
                    $scope.data.loading = true;
                    return tonkeanService.getPlans($scope.pm.project.id, couponCode).then(function (data) {
                        // Reset the all plans array before updating it (so we won't have any duplicates).
                        $scope.data.allPlans = [];

                        for (let i = 0; i < data.plans.length; i++) {
                            const p = data.plans[i];
                            p.quantity = 1;
                            p.amountPerSeat = p.amountPerSeat / 100;
                            p.amountPerSeatPerMonth = p.amountPerSeatPerMonth / 100;

                            $scope.data.allPlans.push(p);

                            if (!$scope.data.tiersToPlans[p.tier]) {
                                $scope.data.tiersToPlans[p.tier] = {};
                            }
                            $scope.data.tiersToPlans[p.tier][p.interval.toUpperCase()] = p;
                        }

                        $scope.data.loading = false;
                    });
                };

                $scope.askForMeeting = function () {
                    $timeout(function () {
                        if (window.Intercom) {
                            // eslint-disable-next-line new-cap
                            window.Intercom('showNewMessage', 'Hi, I would like to upgrade my account');
                            $timeout(function () {
                                const btn = document['intercom-messenger-frame'].document.querySelectorAll(
                                    '.intercom-composer-send-button',
                                );
                                if (btn && btn.length) {
                                    btn[0].click();
                                }
                            }, 2000);
                        }
                    });
                    $scope.data.askedForMeeting = true;
                };

                $scope.populatePlans();
            },
        ],
    };
}

export default angular.module('tonkean.app').directive('tnkPricingOverview', tnkPricingOverview);
