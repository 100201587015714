import { useAngularService } from 'angulareact';
import React from 'react';

import { ReactComponent as MailIcon } from '../../../../../../images/integrations/mail.svg';

import { CommunicationIntegrationIcon } from '@tonkean/infrastructure';
import type { PeopleDirectory, ProjectIntegration } from '@tonkean/tonkean-entities';
import { PeopleDirectorySourcePreferences } from '@tonkean/tonkean-entities';

interface Props {
    peopleDirectory: PeopleDirectory;
}

const PeopleDirectorySelectedCommunicationIntegration: React.FC<Props> = ({ peopleDirectory }) => {
    const projectManager = useAngularService('projectManager');

    const mailIcon = (
        <>
            <MailIcon /> Email
        </>
    );

    const preferredCommunicationIntegration = peopleDirectory.communicationIntegrationsPreferences[0];
    if (!preferredCommunicationIntegration) {
        return mailIcon;
    }

    let communicationIntegration: ProjectIntegration | undefined;
    switch (preferredCommunicationIntegration) {
        case PeopleDirectorySourcePreferences.MAIL: {
            return mailIcon;
        }
        case PeopleDirectorySourcePreferences.DEFAULT: {
            communicationIntegration = projectManager.project.defaultCommunicationProjectIntegration;
            break;
        }
        default: {
            communicationIntegration = projectManager.project.communicationIntegrations.find(
                (commIntegration) => commIntegration.id === preferredCommunicationIntegration,
            );
        }
    }

    if (!communicationIntegration) {
        return mailIcon;
    }

    const name = ` ${communicationIntegration.displayName} (${
        communicationIntegration.integration.integrationUserDisplayName || communicationIntegration.displayNameFull
    })`;

    return (
        <>
            <CommunicationIntegrationIcon integrationType={communicationIntegration.integration.integrationType} />{' '}
            {name}
        </>
    );
};

export default PeopleDirectorySelectedCommunicationIntegration;
