import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const StyledSecondaryNavbar = styled.nav<{ isUppermost: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: ${(props) => (props.isUppermost ? 0 : '54px')};
    height: 50px;
    left: 0;
    right: 0;
    // We want it to be above the project-board content which has z-index 1
    z-index: 4;
    background: white;
    padding: 0 18px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
`;

export default StyledSecondaryNavbar;
