function KbPopoverCtrl($scope, $rootScope, $sce, $timeout, $location, modalUtils) {
    $scope.modalUtils = modalUtils;

    const originalBotSidePaneState = !!$rootScope.showBotSidePaneCollapsed;

    $scope.popover = {
        isOpen: false,
    };

    $scope.botLivePopoverState = {
        isOpen: false,
        isSticky: false,
    };

    $scope.onEducationPopoverOpenKb = function () {
        if (!$scope.popover.isOpen) {
            $scope.toggleSidebar();
        }
    };

    $scope.toggleSidebar = function () {
        $scope.popover.isOpen = !$scope.popover.isOpen;

        if ($scope.popover.isOpen) {
            // KB popover open. force bot side pane collapse
            $rootScope.showBotSidePaneCollapsed = true;
        } else {
            // KB popover closed. return to original state
            $location.hash('');
            $rootScope.showBotSidePaneCollapsed = originalBotSidePaneState;
        }
    };

    $scope.selectArticle = function (article) {
        $scope.popover.currentArticle = article;
        if (article) {
            $location.hash(`kbid=${article.id}`);
        } else {
            $location.hash('');
        }
    };

    $scope.load = function () {
        let baseUrl = 'https://help.tonkean.com/';
        if ($scope.startInCat && $scope.startInCat.length) {
            baseUrl += `category/${$scope.startInCat}`;
        }
        $scope.popover.iframeUrl = $sce.trustAsResourceUrl(`${baseUrl}?inapp`);
        if ($location.hash().includes('kbid=') && !$scope.popover.isOpen) {
            openArticleFromHash($location.hash());
            $scope.toggleSidebar();
        }
    };

    $scope.handleClick = function () {
        $scope.popover.isOpen = false;
    };

    $scope.load();

    $scope.$watchCollection(
        function () {
            return $location.hash();
        },
        function () {
            // reaction
            const hash = $location.hash();
            if (hash && hash.includes('kbid=')) {
                openArticleFromHash(hash);
                if (!$scope.popover.isOpen) {
                    $scope.toggleSidebar();
                }
            } else if (hash && hash.includes('kbopen')) {
                $timeout(function () {
                    if (!$scope.popover.isOpen) {
                        $scope.toggleSidebar();
                    }
                });
            }
        },
    );

    function openArticleFromHash(kbid) {
        $timeout(function () {
            const article = kbid.slice(5);
            $scope.popover.iframeUrl = $sce.trustAsResourceUrl(`https://help.tonkean.com/article/${article}?inapp`);
        });
    }
}

export default angular.module('tonkean.app').controller('KbPopoverCtrl', KbPopoverCtrl);
