<!-- Title -->
<div class="common-bold margin-bottom-xs">SQL Field</div>

<!-- Query input -->
<div class="flex-grow">
    <!-- Input -->
    <textarea
        ng-model="data.sql"
        ng-change="onSqlQueryChanged()"
        class="form-control common-resize-y-only margin-bottom"
        autocomplete="off"
        placeholder="Enter field SQL query"
        required
    ></textarea>

    <!-- Add title to query -->
    <div
        class="flex mod-align-baseline common-subtitle-inner common-size-xxs margin-top-xs"
        ng-if="data.targetType === 'COLUMN'"
    >
        <i class="fa fa-info-circle margin-right-xs"></i>
        <span>
            You can use the keyword&nbsp;
            <a ng-click="addKeywordToSQL('[title]')" class="common-color-primary">[title]</a>
            &nbsp;to query by the track name.
        </span>
    </div>

    <!-- One row and one column warning -->
    <div class="flex mod-align-baseline common-subtitle-inner common-size-xxs margin-top-xs">
        <i class="fa fa-info-circle margin-right-xs"></i>
        <span>
            Make sure to return one row and one column!&nbsp;
            <i class="common-size-xxxxs"><a ng-click="data.viewSqlExamples = !data.viewSqlExamples">[Examples]</a></i>
        </span>
    </div>
</div>

<!-- Examples show -->
<div ng-if="data.viewSqlExamples" class="margin-top">
    <span class="flex-no-shrink flex-self-start margin-right initiative-form-label margin-top-xs">Example SQL:</span>

    <div class="common-subtitle-inner margin-top-xs">
        <pre class="common-size-xxxxs">
SELECT t.{{ data.fieldDefinitionName.toLowerCase().replace(' ', '_') }} FROM {{
                data.projectIntegration.integration.integrationUserDisplayName || 'schema_name'
            }}.table_name t WHERE t.name=[title]</pre
        >
    </div>
    <div class="common-subtitle-inner margin-top-xs">
        <pre
            class="common-size-xxxxs"
        >SELECT COUNT(*) FROM {{data.projectIntegration.integration.integrationUserDisplayName || 'schema_name'}}.inner_table i<br/>WHERE i.id in (SELECT t.id FROM {{data.projectIntegration.integration.integrationUserDisplayName || 'schema_name'}}.table_name t WHERE t.name=[title])</pre>
    </div>
</div>
