import type { TElement } from '@udecode/plate';
import React from 'react';

import type {
    TonkeanId,
    TonkeanType,
    WorkflowFolderCategory,
    WorkflowFolderCategoryProcessParticipants,
} from '@tonkean/tonkean-entities';
import type { ModuleMarketplaceItem } from '@tonkean/tonkean-entities';

export interface WorkflowFolderCategoriesContextType {
    workflowFolderCategories: WorkflowFolderCategory[];
    selectedCategoryId?: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>;
    setSelectedCategoryId: (id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>) => void;
    createWorkflowFolderCategory: (
        displayName: string,
        description: string,
        outcomeDefinition: TElement[],
    ) => Promise<WorkflowFolderCategory>;
    updateWorkflowFolderCategory: (
        id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        displayName: string,
        description: string,
        outcomeDefinition: TElement[],
    ) => Promise<void>;
    swapWorkflowFolderCategoryIndices: (firstIndex: number, secondIndex: number) => Promise<void>;
    reloadWorkflowFolderCategories: () => Promise<void>;
    loading: boolean;
    workflowFolderCategoryProcessParticipants: WorkflowFolderCategoryProcessParticipants[];
    workflowFolderCategorySuggestedModules: Record<
        TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        ModuleMarketplaceItem[]
    >;
}

export const WorkflowFolderCategoriesContext = React.createContext<WorkflowFolderCategoriesContextType | undefined>(
    undefined,
);
