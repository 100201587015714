import React, { useState } from 'react';

import RegexLoading from './RegexLoading';
import { RegexViewerBlockSideButtons } from './RegexViewerBlockSideButtons';

interface Props {
    input?: React.ReactElement | React.ReactNode;
    title: string;
    evaluated: string | boolean;
    unevaluated?: string;
    loading?: boolean;
    showRegexTutorialLink?: boolean;
    className?: string;
}

const RegexViewerBlock: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    input,
    loading,
    title,
    evaluated,
    unevaluated,
    showRegexTutorialLink = false,
    className = '',
}) => {
    const [showEvaluated, setShowEvaluated] = useState(true);

    return (
        <div className={`rounded-box padding-md margin-bottom-md ${className}`}>
            <h4 className="margin-bottom padding-bottom margin-top-none flex">
                {title}
                <RegexViewerBlockSideButtons
                    showRegexTutorialLink={showRegexTutorialLink}
                    showEvaluationToggleButton={!!evaluated && !!unevaluated}
                    active={showEvaluated}
                    onToggle={setShowEvaluated}
                />
            </h4>
            {input}
            {evaluated && (
                <>
                    {showEvaluated ? <>{loading ? <RegexLoading /> : <> {children} </>}</> : <span>{unevaluated}</span>}
                </>
            )}
        </div>
    );
};

export { RegexViewerBlock };
