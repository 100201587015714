import React from 'react';
import styled, { css } from 'styled-components';

import ConstBlock from './ConstBlock';
import TreeBlock from './TreeBlock';
import VariableBlock from './VariableBlock';

import type { ServerFormulaExpressionNode } from '@tonkean/tonkean-entities';

const ExpressionBlockWrapper = styled.span<{ showFocus: boolean }>`
    outline: none;
    ${({ showFocus }) =>
        showFocus &&
        css`
            box-shadow: 0 0 0 2px #afeafb;
            border-radius: 5px;
        `};
`;

interface Props {
    expression: ServerFormulaExpressionNode;
    showFocus?: boolean;
    children?: React.ReactNode;
}

const TonkeanExpressionBlock: React.FC<Props> = ({ expression, showFocus, children }) => {
    return (
        <ExpressionBlockWrapper showFocus={!!showFocus} contentEditable={false}>
            {expression['@type'] === 'const' && <ConstBlock expression={expression}>{children}</ConstBlock>}
            {expression['@type'] === 'variable' && <VariableBlock expression={expression}>{children}</VariableBlock>}
            {expression['@type'] === 'tree' && <TreeBlock expression={expression}>{children}</TreeBlock>}
        </ExpressionBlockWrapper>
    );
};

export default TonkeanExpressionBlock;
