import React, { useCallback, useMemo, useState } from 'react';

import LineItemsDateRangeValidationModal from './LineItemsDateRangeValidationModal';
import LineItemsInitialValues from './LineItemsInitialValues';
import Section from '../../../components/Section';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import { WidgetInlineActionsSelector } from '../../CommonWidgetConfiguration';
import type { FieldsWidgetMenuItem } from '../../FieldsWidgetModule';
import LineItemsWidgetConfigurationEditorDisplay from '../../InnerItemsWidgetModule/components/configurationEditorDisplays/LineItemsWidgetConfigurationEditorDisplay';
import type { FieldDateRangeValidationParams } from '../entities/FieldDateRangeValidation';
import FieldDateRangeValidation from '../entities/FieldDateRangeValidation';
import type InitialFieldDefinitionValueExpression from '../entities/InitialFieldDefinitionValueExpression';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';
import useFieldsAdditionalMenuItems from '../hooks/useFieldsAdditionalMenuItems';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useFormikField } from '@tonkean/infrastructure';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

const LineItemsWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<LineItemsWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const [editedDateRangeValidationKeyAndName, setEditedDateRangeValidationKeyAndName] = useState<
        [FieldDefinitionKey, string] | false
    >(false);

    const { initiative, itemInterface } = useItemInterfaceContext();

    const { setValue: setDateRangeValidations, value: dateRangeValidations } = useFormikField<
        FieldDateRangeValidation[] | undefined
    >('configuration.dateRangeValidations');

    const { setValue: setInitialExpressionValues, value: initialExpressionValues } = useFormikField<
        InitialFieldDefinitionValueExpression[] | undefined
    >('configuration.initialExpressionValues');

    const editableDateRangeValidationField = useMemo(() => {
        const dateRangeValidationInitialValue = dateRangeValidations?.find(
            (dateRangeValidation) => dateRangeValidation.fieldDefinitionKey === editedDateRangeValidationKeyAndName[0],
        );

        if (dateRangeValidationInitialValue) {
            const min = dateRangeValidationInitialValue.params.min || undefined;
            const max = dateRangeValidationInitialValue.params.max || undefined;

            return new FieldDateRangeValidation(dateRangeValidationInitialValue.fieldDefinitionKey, {
                min,
                max,
            });
        }

        return dateRangeValidationInitialValue;
    }, [dateRangeValidations, editedDateRangeValidationKeyAndName]);

    const fieldsAdditionalMenuItems: FieldsWidgetMenuItem[] = useFieldsAdditionalMenuItems(
        dateRangeValidations,
        setEditedDateRangeValidationKeyAndName,
    );

    const onDateRangeValidationSubmit = useCallback(
        ({ min, max }: FieldDateRangeValidationParams) => {
            if (!editedDateRangeValidationKeyAndName) {
                // Shouldn't happen because only when the modal is open we call this callback
                return;
            }

            setDateRangeValidations((prevDateRangeValidations) => {
                const filteredPrevDateRangeValidations = (prevDateRangeValidations || []).filter(
                    (rangeValidation) => rangeValidation.fieldDefinitionKey !== editedDateRangeValidationKeyAndName[0],
                );

                // If min and max are empty, we delete the range validation
                if (min || max) {
                    const fieldDefinitionKey = editedDateRangeValidationKeyAndName[0];
                    return [
                        ...filteredPrevDateRangeValidations,
                        new FieldDateRangeValidation(fieldDefinitionKey, { min, max }),
                    ];
                }

                return filteredPrevDateRangeValidations;
            });

            setEditedDateRangeValidationKeyAndName(false);
        },
        [editedDateRangeValidationKeyAndName, setDateRangeValidations],
    );

    const { data: fieldDefinitions, loading: isLoadingFieldDefinitions } = useTonkeanService(
        'getFieldDefinitionsByWorkflowVersionType',
        workflowVersion.groupId,
        workflowVersion.workflowVersionType,
        workflowVersion.id,
    );

    return (
        <>
            <LineItemsWidgetConfigurationEditorDisplay
                initiative={initiative}
                configuration={configuration}
                workflowVersionId={workflowVersion.id}
                groupId={workflowVersion.groupId}
                fieldsAdditionalOptions={fieldsAdditionalMenuItems}
                fieldDefinitions={fieldDefinitions?.entities}
                supportFieldWidth
            />

            <Section>
                <WidgetInlineActionsSelector workflowVersionId={itemInterface.workflowVersionId} />
            </Section>

            <Section>
                <LineItemsInitialValues
                    workflowVersionId={workflowVersion.id}
                    groupId={workflowVersion.groupId}
                    fieldDefinitions={fieldDefinitions?.entities}
                    isLoadingFieldDefinitions={isLoadingFieldDefinitions}
                />
            </Section>

            <LineItemsDateRangeValidationModal
                onSubmit={onDateRangeValidationSubmit}
                onClose={() => setEditedDateRangeValidationKeyAndName(false)}
                open={!!editedDateRangeValidationKeyAndName}
                initialValues={editableDateRangeValidationField}
            />
        </>
    );
};

export default LineItemsWidgetConfigurationEditor;
