import { getOperators } from '@tonkean/forumla-operators';
import { fieldTypeToFieldDisplayName } from '@tonkean/tonkean-entities';
import { OperatorFamily } from '@tonkean/tonkean-entities';
import { htmlDecode } from '@tonkean/utils';
import { getParticipatingVariablesForFormula, getVariablesUsedInFormulaExpression } from '@tonkean/tonkean-utils';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function FormulaExpressionEditorCtrl(
    $scope,
    $timeout,
    customFieldsManager,
    tonkeanService,
    requestThrottler,
    projectManager,
    utils,
) {
    const ctrl = this;

    let lastText = '';
    let lastCursorIndex = 0;

    $scope.data = {
        projectId: projectManager.project.id,
        projectIntegration: ctrl.projectIntegration,
        targetType: ctrl.targetType,
        fieldDefinitionType: ctrl.fieldDefinitionType,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        variableEntityName: ctrl.variableEntityName,
        additionalTabs: ctrl.additionalTabs,
        customTrigger: ctrl.customTrigger,
        formulaExpression: ctrl.formulaExpression || '',
        formulaData: ctrl.formulaData,
        evaluatedFormulaExpression: ctrl.evaluatedFormulaExpression || undefined,
        formulaExpressionTree: ctrl.formulaExpressionTree || undefined,
        exampleInitiative: ctrl.exampleInitiative || false,
        aggregationOnColumnFeatureName: ctrl.aggregationOnColumnFeatureName,
        columnFormulaFeatureName: ctrl.columnFormulaFeatureName,
        variables: [],
        variableIdToVariableMap: {},
        variableNameToVariableMap: {},

        availableFunctions: getOperators({ withAggregationOperands: ctrl.targetType === 'GLOBAL' }),
        filteredAvailableFunctions: getOperators({ withAggregationOperands: ctrl.targetType === 'GLOBAL' }),

        guid: utils.guid(),
        treeViewMod: true,

        expressionPreId: `expressionPreId-${utils.guid()}`,

        onInnerTrackAggregationSelected: ctrl.onInnerTrackAggregationSelected,
        preElement: undefined,

        fieldDefinition: ctrl.fieldDefinition,
    };

    // List of function signs, sorted from the longest to the shortest
    const allFunctionOperatorsSigns = getOperators({ withAggregationOperands: true, withDeprecatedOperands: true })
        .filter((operator) => operator.family === OperatorFamily.FUNCTION)
        .map((operator) => operator.sign)
        .sort((a, b) => b.length - a.length);
    // order is important! the first ones are the stronger
    const coloringRegexs = [
        { regex: '(\\".*?\\")', style: 'color: green;' }, // quotes
        { regex: '({.*?})', style: 'color: #396394;' }, // params
        {
            regex: `(${allFunctionOperatorsSigns.join('|')})`,
            style: 'color: #794E8A;',
        }, // functions
        { regex: '(AND|OR|\\+|\\-|\\*|\\/|\\=|\\<|\\>|(!=))', style: 'color: darkred;' }, // and/or/+/- etc
    ];

    /**
     * Modal initialization function.
     */
    ctrl.$onInit = function () {
        setVariableNameToVariableMap();
    };

    /**
     * Occurs when component bindings are changed.
     */
    ctrl.$onChanges = function (changesObj) {
        handleFormulaExpressionChange(changesObj);
        handleTreeViewModChange(changesObj);

        if (changesObj.exampleInitiative) {
            $scope.data.exampleInitiative = ctrl.exampleInitiative || false;
        }

        if (changesObj.formulaExpressionTree) {
            $scope.data.formulaExpressionTree = ctrl.formulaExpressionTree;
        }

        if (changesObj.onInnerTrackAggregationSelected) {
            $scope.data.onInnerTrackAggregationSelected = ctrl.onInnerTrackAggregationSelected;
        }

        if (changesObj.targetType) {
            $scope.data.targetType = ctrl.targetType;
            $scope.data.availableFunctions = getOperators({ withAggregationOperands: ctrl.targetType === 'GLOBAL' });
            $scope.data.filteredAvailableFunctions = getOperators({
                withAggregationOperands: ctrl.targetType === 'GLOBAL',
            });
        }
    };

    /**
     * Validates that the field definition is valid.
     */
    $scope.validateFormulaExpression = (finallyCallback, fromFormulaBuilder = false, valid, dataType) => {
        if ($scope.data.treeViewMod && !fromFormulaBuilder) {
            // This cannot be triggered from outside the formula builder in treeViewMod
            return;
        }

        $scope.data.validatingAdvancedFormula = true;
        $scope.data.validationResult = null;

        // Validating not empty formula.
        if (!$scope.data.formulaExpression?.length) {
            $scope.data.validatingAdvancedFormula = false;
            finallyCallback?.();
            return;
        }

        // If from formula builder it should not validate on the server as it has it's own validation, and should
        // skip ambiguousVariableNameUsed validation because it generates an evaluated formula by itself.
        if (fromFormulaBuilder) {
            $scope.data.validatingAdvancedFormula = false;
            $scope.data.validationResult = {
                validFormulaExpression: valid,
                errorMessage: undefined,
                returnedFieldType: dataType,
            };
            finallyCallback();

            return;
        }

        // Validating there aren't any ambiguous column names used.
        const ambiguousVariableNameUsed = getAmbiguousVariableNameUsed($scope.data.formulaExpression);
        if (ambiguousVariableNameUsed) {
            $scope.data.validationResult = {
                errorMessage: `There is more than one variable with the name ${ambiguousVariableNameUsed}.`,
            };
            $scope.data.validatingAdvancedFormula = false;
            return;
        }

        // Validating in the server.
        tonkeanService
            .validateFormulaExpression(
                projectManager.project.id,
                replaceVariableNameToVariableIdInExpression($scope.data.formulaExpression),
                $scope.data.targetType === 'COLUMN' ? 'TNK_COLUMN_FORMULA' : 'TNK_COLUMN_AGGREGATE',
                $scope.data.groupId,
                null,
                null,
                null,
                $scope.data.workflowVersionId,
            )
            .then((data) => {
                $scope.data.validationResult = data;
            })
            .catch(() => {
                $scope.data.validationResult = {
                    errorMessage: 'There was an error trying to validate expression.',
                };
            })
            .finally(() => {
                $scope.data.validatingAdvancedFormula = false;
                finallyCallback?.();
            });
    };

    $scope.onKeydown = function ($event) {
        if ($event.keyCode === 13) {
            // enter
            $event.preventDefault();
            return false;
        }
    };

    /**
     * Triggered on change in the pre tag.
     *
     * @param force {boolean} - if false, it will run onFormulaExpressionChanged only if the lastText not match the
     * text in the pre tag
     * @param isInit {boolean=} - it it triggered as part of the initiation
     */
    $scope.onExpressionPreChanged = function () {
        requestThrottler.do('formulaPreExpressionChanged', 200, () => {
            $scope.saveCursorPosition();
            const el = getPreElement();

            const newText = el?.textContent.trim();
            const valueChanged = !$scope.data.treeViewMod && newText !== lastText;

            if (valueChanged) {
                onFormulaExpressionChanged(
                    valueChanged ? newText : undefined,
                    valueChanged ? replaceVariableNameToVariableIdInExpression(newText) : undefined,
                    false,
                    undefined,
                    undefined,
                    false,
                );

                colorFormulaExpression($scope.data.formulaExpression);
            }
        });
    };

    /**
     * Triggered once the formula expression is changed in the formula builder.
     *
     * @param originalFormula {string} - the new readable formula string.
     * @param evaluatedFormula {string} - the new formula string with ids in it.
     * @param valid {boolean=} - is the formula valid (ignored if fromFormulaBuilder is not true).
     * @param dataType {string=} - the return type of the formula (ignored if fromFormulaBuilder is not true).
     */
    $scope.onFormulaBuilderChanged = (originalFormula, evaluatedFormula, valid, dataType, rootNode) => {
        // This event is triggered from react, so to make in run in a digest cycle I'm wrapping it with timeout
        $timeout(() => {
            onFormulaExpressionChanged(originalFormula, evaluatedFormula, true, valid, dataType, false, rootNode);
        });
    };

    /** * Function triggered when formula data changed
     *
     * @param formulaData - the formula data itself
     * @param isInit - parameter indicating if it is the first initiallize
     */
    $scope.onFormulaDataChange = (formulaData, isInit) => {
        ctrl.onFormulaDataChange(formulaData, isInit);
    };

    /**
     * Emits validation result of the current formula expression
     *
     * @param valid {boolean=} - is the formula valid (ignored if fromFormulaBuilder is not true).
     * @param dataType {string=} - the return type of the formula (ignored if fromFormulaBuilder is not true).
     */
    $scope.emitValidationResult = (valid, dataType) => {
        $timeout(() => {
            $scope.validateFormulaExpression(() => emitFormulaExpressionChanged(false), true, valid, dataType);
        });
    };

    /**
     * Adds a variable to the expression.
     */
    $scope.addVariableToExpression = function (variable) {
        const formula =
            $scope.data.targetType === 'GLOBAL' && variable.isColumnFieldDefinition
                ? `ColumnSum({${variable.name}})`
                : `{${variable.name}}`;

        addToExpression(formula);
    };

    /**
     * Adds a formula to the expression.
     */
    $scope.addFormulaToExpression = function (operator) {
        if (operator) {
            addToExpression(operator.toString());
        }
    };

    /**
     * Returns a string with the data type of the supplied operator.
     * Used in group-by in the ui select
     *
     * @param operator {object} - operator to get it's data type.
     */
    $scope.operatorStringDataType = function (operator) {
        if (typeof operator.dataType === 'string') {
            return fieldTypeToFieldDisplayName[operator.dataType];
        }
        return 'Dynamic';
    };

    /**
     * Updates the filteredAvailableFunctions list by filtering the operators in availableFunctions
     * by display name and sign.
     *
     * @param searchTerm {string} - the search term to filter by.
     */
    $scope.filterOperators = (searchTerm) => {
        const searchTermLowerCased = searchTerm.toLowerCase();

        $scope.data.filteredAvailableFunctions = $scope.data.availableFunctions.filter(
            (formulaOperator) =>
                formulaOperator.displayName.toLowerCase().includes(searchTermLowerCased) ||
                formulaOperator.sign.toLowerCase().includes(searchTermLowerCased),
        );
    };

    /**
     * Encode `<` and `>`
     *
     * @param str {string} - string to encode
     * @returns {string} - encoded string
     */
    function encodeHtmlTags(str) {
        return str.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
    }

    function addToExpression(variable) {
        const inputEl = getPreElement();
        if (inputEl) {
            const startPosition = lastCursorIndex;
            const endPosition = startPosition;
            const text = inputEl.textContent;

            const newFormulaValue =
                text.slice(0, Math.max(0, startPosition)) + variable + text.slice(Math.max(0, endPosition));
            onFormulaExpressionChanged(newFormulaValue, replaceVariableNameToVariableIdInExpression(newFormulaValue));

            lastCursorIndex += variable.length;
        } else {
            const originalFormula = $scope.data.formulaExpression + variable;
            onFormulaExpressionChanged(originalFormula, replaceVariableNameToVariableIdInExpression(originalFormula));
        }

        colorFormulaExpression($scope.data.formulaExpression);
    }

    /**
     * Occurs once the formula expression is changed.
     *
     * @param originalFormula {string=} - the new readable formula string.
     * @param evaluatedFormula {string=} - the new formula string with ids in it.
     * @param fromFormulaBuilder {boolean=} - has the callback been triggered from the formula builder.
     * @param valid {boolean=} - is the formula valid (ignored if fromFormulaBuilder is not true).
     * @param dataType {string=} - the return type of the formula (ignored if fromFormulaBuilder is not true).
     * @param isInit {boolean=} - it it triggered as part of the initiation
     */
    function onFormulaExpressionChanged(
        originalFormula = $scope.data.formulaExpression,
        evaluatedFormula = $scope.data.evaluatedFormulaExpression,
        fromFormulaBuilder = false,
        valid,
        dataType,
        isInit = false,
        rootNode,
    ) {
        $scope.data.formulaExpression = originalFormula.trim();
        $scope.data.evaluatedFormulaExpression = evaluatedFormula.trim();
        $scope.data.expressionNode = rootNode;

        if (!isInit) {
            // It is not being updated, it should be removed so it won't be used again by the formula builder
            $scope.data.formulaExpressionTree = undefined;
        }

        lastText = $scope.data.formulaExpression;

        $scope.validateFormulaExpression(
            () => emitFormulaExpressionChanged(isInit),
            fromFormulaBuilder,
            valid,
            dataType,
        );
    }

    /**
     * Calls the given callback for when expression is changed.
     */
    function emitFormulaExpressionChanged(isInit) {
        const variablesUsedInExpression = getVariablesUsedInFormulaExpression(
            $scope.data.formulaExpression,
            $scope.data.variableNameToVariableMap,
        );

        ctrl.onFormulaExpressionChanged?.({
            isInit,
            variablesUsedInExpression,
            evaluatedFormulaExpression: $scope.data.evaluatedFormulaExpression,
            validationResult: $scope.data.validationResult,
            originalFormulaExpression: $scope.data.formulaExpression,
            expressionNode: $scope.data.expressionNode,
        });
    }

    /**
     * Gets the first variable that its name can be ambiguous (meaning there is more than one variable with the same name).
     */
    function getAmbiguousVariableNameUsed(expression) {
        /* jshint loopfunc:true */
        for (let i = 0; i < expression.length; i++) {
            const currentChar = expression[i];

            // Field names will be encapsulated in {} brackets
            if (currentChar === '{') {
                const startingIndex = i + 1;
                const closingIndex = expression.indexOf('}', i); // The closing bracket of the opening bracket we found
                const fieldName = expression.substr(startingIndex, closingIndex - startingIndex);

                const fields = $scope.data.variables.filter((fieldDef) => fieldDef.name === fieldName);

                if (fields && fields.length > 1) {
                    return fieldName;
                }
            }
        }
    }

    /**
     * Replaces the use of variable names in the expression with their id, so that the variables map
     * in the server can be truly unique.
     * If there are two fields with the same name, it will take the first one it finds.
     */
    function replaceVariableNameToVariableIdInExpression(expression) {
        let finalExpression = '';

        // Generate latest map
        setVariableNameToVariableMap();

        /* jshint loopfunc:true */
        for (let i = 0; i < expression.length; i++) {
            const currentChar = expression[i];

            // Field names will be encapsulated in {} brackets
            if (currentChar === '{') {
                const startingIndex = i + 1;
                const closingIndex = expression.indexOf('}', i); // The closing bracket of the opening bracket we found
                const variableName = expression.substr(startingIndex, closingIndex - startingIndex);

                if ($scope.data.variableNameToVariableMap[variableName]) {
                    // If what's within the brackets is an existing variable, we replace it with the variable's id

                    finalExpression += '{';
                    finalExpression += $scope.data.variableNameToVariableMap[variableName].id;
                    finalExpression += '}';
                    i = closingIndex;
                } else {
                    // Otherwise, we do nothing, and just append current char to final expression string.
                    finalExpression += currentChar;
                }
            } else {
                finalExpression += currentChar;
            }
        }

        return finalExpression;
    }

    let lastColoredExpression;
    function colorFormulaExpression() {
        const expression = $scope.data.formulaExpression;

        if (lastColoredExpression === expression) {
            // if we already colored that expression, no need to do it again and it will might jump the cursor
            // we might got called from echo from the parent control etc so can ignore
            return;
        }

        const el = getPreElement();
        if (el) {
            // save that we are working on this expression
            lastColoredExpression = expression;

            let htmlExpression = encodeHtmlTags(expression);
            for (const rule of coloringRegexs) {
                // we don't want to match within existing spans
                const regexString = `(?:<span.*?<\\/span>)|${rule.regex}`;

                htmlExpression = htmlExpression.replace(new RegExp(regexString, 'g'), function (match, capture) {
                    return capture ? `<span style="${rule.style}">${capture}</span>` : match;
                });
            }

            // update element
            el.innerHTML = htmlExpression;

            // set back the cursor position
            if (lastCursorIndex > -1 && el.childNodes && el.childNodes.length) {
                // If element is not the active (focused) element, the cursor should not be moved
                if (el !== document.activeElement) {
                    return;
                }

                const range = document.createRange();
                const sel = window.getSelection();

                // find the new position
                const nodeInfo = findNodeByCharIndex(el.childNodes, lastCursorIndex);

                if (nodeInfo) {
                    const node = nodeInfo.node;

                    range.setStart(node, nodeInfo.index);
                    range.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        }
    }

    // looking for the node that has our last cur position
    function findNodeByCharIndex(nodes, index) {
        let count = 0;
        for (let node of nodes) {
            let innerLength = 0;
            if (node.textContent) {
                innerLength = node.textContent.length;
            } else if (node.nodeType === Node.TEXT_NODE) {
                innerLength = node.textContent.length;
            }

            if (count + innerLength >= index) {
                const pos = innerLength - (count + innerLength - index);
                if (node.childNodes && node.childNodes.length) {
                    // set range only works on TEXT elements, so if it's a span/other tag we need to get the text in it
                    node = node.childNodes[0];
                }
                return { node, index: pos };
            }

            count += innerLength;
        }
        return null;
    }

    // from https://codepen.io/neoux/pen/OVzMor
    // getting the position of the cur from the text
    $scope.saveCursorPosition = function () {
        const element = getPreElement();
        let caretOffset = 0;
        const doc = element.ownerDocument || element.document;
        const win = doc.defaultView || doc.parentWindow;
        let sel;
        if (typeof win.getSelection !== 'undefined') {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                const range = win.getSelection().getRangeAt(0);
                const preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        } else if ((sel = doc.selection) && sel.type !== 'Control') {
            const textRange = sel.createRange();
            const preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint('EndToEnd', textRange);
            caretOffset = preCaretTextRange.text.length;
        }

        lastCursorIndex = caretOffset;
        return lastCursorIndex;
    };

    /**
     * Generate a variable name to variable map from the cache
     */
    function setVariableNameToVariableMap() {
        const variablesObject = getParticipatingVariablesForFormula(
            customFieldsManager.selectedGlobalFieldsMap[ctrl.workflowVersionId],
            customFieldsManager.selectedColumnFieldsMap[ctrl.workflowVersionId],
            ctrl.fieldDefinitionType === 'TNK_COLUMN_AGGREGATE'
                ? $scope.data.aggregationOnColumnFeatureName
                : $scope.data.columnFormulaFeatureName,
        );

        $scope.data.variables = variablesObject.variables;
        $scope.data.variableIdToVariableMap = variablesObject.variableIdToVariableMap;
        $scope.data.variableNameToVariableMap = variablesObject.variableNameToVariableMap;
    }

    /**
     * Will update formulaExpression and evaluatedFormulaExpression, emit an onFormulaExpressionChanged and update the
     * pre element content if the new expression in the changesObj are different from the currently stored.
     *
     * @param changesObj {angular.IOnChangesObject} - angular's $onChanges change object.
     */
    function handleFormulaExpressionChange(changesObj) {
        const unescapedOriginalFormulaExpression =
            changesObj.formulaExpression && htmlDecode(changesObj.formulaExpression.currentValue);
        const unescapedEvaluatedFormulaExpression =
            changesObj.evaluatedFormulaExpression && htmlDecode(changesObj.evaluatedFormulaExpression.currentValue);

        const formulaExpressionChanged =
            changesObj.formulaExpression && $scope.data.formulaExpression !== unescapedOriginalFormulaExpression;
        const evaluatedFormulaExpressionChanged =
            changesObj.evaluatedFormulaExpression &&
            $scope.data.evaluatedFormulaExpression !== unescapedEvaluatedFormulaExpression;

        if (formulaExpressionChanged || evaluatedFormulaExpressionChanged) {
            $scope.data.formulaExpression = unescapedOriginalFormulaExpression;
            $scope.data.evaluatedFormulaExpression =
                unescapedEvaluatedFormulaExpression ||
                replaceVariableNameToVariableIdInExpression(unescapedOriginalFormulaExpression);

            onFormulaExpressionChanged(undefined, undefined, undefined, undefined, undefined, true);

            if (!$scope.data.treeViewMod) {
                $timeout(() => {
                    colorFormulaExpression();
                });
            }
        }
    }

    /**
     * Will update tree view mod, emit an onExpressionPreChanged if pre is visible and remove the pre
     * element from cache if it's not shown.
     *
     * @param changesObj {angular.IOnChangesObject} - angular's $onChanges change object.
     */
    function handleTreeViewModChange(changesObj) {
        if (changesObj.treeViewMod) {
            $scope.data.treeViewMod = changesObj.treeViewMod.currentValue;

            if ($scope.data.treeViewMod) {
                // pre element is being removed from the dom and the reference should be removed from the cache
                $scope.data.preElement = undefined;
            } else {
                // Using a timeout to run it when the pre tag is already added to the dom, so when
                // saveCursorPosition will be triggered, it will find the pre tag.
                $timeout(() => {
                    colorFormulaExpression();
                });
            }
        }
    }

    /**
     * Returns an HTMLPreElement, and caches the result.
     *
     * @returns {HTMLPreElement} the pre element
     */
    function getPreElement() {
        if (!$scope.data.preElement) {
            $scope.data.preElement = document.getElementById($scope.data.expressionPreId);
        }

        return $scope.data.preElement;
    }
}

angular
    .module('tonkean.app')
    .controller('FormulaExpressionEditorCtrl', lateConstructController(FormulaExpressionEditorCtrl));
