import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ProjectIntegrationEntityLeftPageGenericHeader from './common/ProjectIntegrationEntityLeftPageGenericHeader';
import ProjectIntegrationEntityHeaderComponent from './ProjectIntegrationEntityHeaderComponent';
import ProjectIntegrationEntitySchemaEditMetadata from './ProjectIntegrationEntitySchema/ProjectIntegrationEntitySchemaEditMetadata';
import { ReactComponent as ArrowRightLgIcon } from '../../../../images/icons/arrow-right.svg';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import SplitPageTemplate from '../../../infrastructure/pageTemplates/SplitPageTemplate';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle } from '@tonkean/infrastructure';
import type { FieldMetadata } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const EmptyStateText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_600};
    font-weight: 500;
    margin-top: 10px;
`;

const ErrorLoadingFieldsText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
`;

const LoadingFieldsText = styled.div`
    font-size: ${FontSize.SMALL_12};
`;

const MetadataRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    height: 46px;
    &:hover {
        background-color: ${Theme.colors.tabsHover};
    }
`;

const RightArrow = styled.span`
    .tnk-icon {
        svg {
            padding-top: 4px;

            width: 18px;
            height: 15px;
        }
    }
`;

const EditButton = styled(Clickable)<{ selected?: boolean }>`
    margin-left: auto;
    margin-right: 10px;
    color: #2f84dc;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;

    &:focus,
    &:hover {
        text-decoration: underline;
        color: ${Theme.colors.primaryHighlight};
    }

    ${({ selected }) =>
        selected &&
        css`
            color: ${Theme.colors.primaryHighlight};
        `};

    ${styledFocus}
`;

const FieldMetadataDisplayName = styled.span``;

const FieldMetadataName = styled.span`
    font-weight: 500;
    margin-right: 5px;
`;

const FieldTitle = styled.span`
    color: ${Theme.colors.gray_600};
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 20px;
`;

const LoadingIndicationContainer = styled.div`
    display: flex;
    align-items: center;
`;
const StyledLoadingCircle = styled(LoadingCircle)`
    margin-right: 5px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 9px;

    display: flex;
    align-items: center;
`;

interface Props extends AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps> {}

const ProjectIntegrationEntitySchemaPage: React.FC<Props> = ({
    projectIntegration,
    entity,
    entitiesSummary,
    sidePaneTabName,
    tabKeyToOnExit,
    ...props
}) => {
    const tabHeader = sidePaneTabName || 'Unknown Tab';
    const {
        data: fieldMetadata,
        loading: fieldMetadataLoading,
        error: fieldMetadataError,
    } = useTonkeanService(
        'getEntityFieldMetadata',
        entity.id,
        projectIntegration.id,
        projectIntegration.integrationUniqueType,
    );

    const [fieldMetadatas, setFieldMetadatas] = useState<FieldMetadata[] | undefined>(fieldMetadata?.entities);
    const [selectedFieldMetadata, setSelectedFieldMetadata] = useState<FieldMetadata | undefined>(undefined);
    const shouldShowEmptyState = !fieldMetadataLoading && !fieldMetadataError && !fieldMetadata?.entities.length;

    useEffect(() => {
        setFieldMetadatas(fieldMetadata?.entities || []);
    }, [fieldMetadata?.entities]);

    const onChange = (fieldMetadataToSet: FieldMetadata) => {
        if (!fieldMetadatas) {
            return;
        }

        setFieldMetadatas(
            fieldMetadatas.map((field) => {
                if (field.name === fieldMetadataToSet.name) {
                    return fieldMetadataToSet;
                }

                return field;
            }),
        );
    };

    // Pick first field metadata
    useEffect(() => {
        if (selectedFieldMetadata) {
            return;
        }

        if (!fieldMetadata?.entities) {
            return;
        }

        setSelectedFieldMetadata(fieldMetadata.entities[0]);
    }, [fieldMetadata?.entities, selectedFieldMetadata]);

    return (
        <SplitPageTemplate
            breadcrumbs={
                <ProjectIntegrationEntityHeaderComponent
                    entity={entity}
                    projectIntegration={projectIntegration}
                    entitiesSummary={entitiesSummary}
                    defaultMargin={false}
                    tabKeyToOnExit={tabKeyToOnExit}
                    {...props}
                />
            }
            actions={<></>}
            leftStickyItems={<></>}
            rightBody={
                <>
                    {selectedFieldMetadata && (
                        <ProjectIntegrationEntitySchemaEditMetadata
                            entity={entity}
                            fieldMetadataToEdit={selectedFieldMetadata}
                            onChange={onChange}
                        />
                    )}
                </>
            }
            name={tabHeader}
            title={
                <ProjectIntegrationEntityLeftPageGenericHeader
                    title={tabHeader}
                    description={
                        <>
                            <div> The data source schema is the entity structure as received from the application.</div>
                            <div>
                                You can edit the metadata for select fields, including the label, type, field value, to
                                improve clarity for process contributors.
                            </div>
                        </>
                    }
                />
            }
        >
            <div>
                <FieldTitle>Entity Fields</FieldTitle>
                {fieldMetadatas?.map((field) => (
                    <MetadataRow key={field.name}>
                        <FieldMetadataName>{field.name}</FieldMetadataName>{' '}
                        <FieldMetadataDisplayName>
                            {field.isLabelOverridable ? field.overridableLabel : field.label}
                        </FieldMetadataDisplayName>
                        <EditButton
                            onClick={() => {
                                setSelectedFieldMetadata(field);
                            }}
                        >
                            Edit Metadata
                            <RightArrow>
                                <span className="tnk-icon">
                                    <ArrowRightLgIcon />
                                </span>
                            </RightArrow>
                        </EditButton>
                    </MetadataRow>
                ))}

                {shouldShowEmptyState && <EmptyStateText>No Field Metadata Information Available</EmptyStateText>}

                {fieldMetadataLoading && (
                    <LoadingIndicationContainer>
                        <StyledLoadingCircle />
                        <LoadingFieldsText>Loading fields metadata...</LoadingFieldsText>
                    </LoadingIndicationContainer>
                )}

                {fieldMetadataError && <ErrorLoadingFieldsText>Error loading fields to tag...</ErrorLoadingFieldsText>}
            </div>
        </SplitPageTemplate>
    );
};

export default ProjectIntegrationEntitySchemaPage;
