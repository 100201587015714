import React from 'react';

import LandingPageSelector from './LandingPageSelector';
import { useSolutionMarketplaceItemCreateModal } from '../../../../MarketplaceItemCreateModalContext';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const MarketplaceItemCreateModalLandingPageSection: React.FC = () => {
    const { existingMarketplaceItem, workflowFolder } = useSolutionMarketplaceItemCreateModal();
    const workflowFolderId = workflowFolder?.id || existingMarketplaceItem?.rootEntityId;
    if (workflowFolderId?.startsWith('WOFO')) {
        return <LandingPageSelector workflowFolderId={workflowFolderId as TonkeanId<TonkeanType.WORKFLOW_FOLDER>} />;
    } else {
        return <></>;
    }
};

export default MarketplaceItemCreateModalLandingPageSection;
