import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import hasActiveSolutionBusinessReportGroupFilters from './hasActiveSolutionBusinessReportGroupFilters';
import SolutionBusinessReportSingleSavedFilter from './SolutionBusinessReportSingleSavedFilter';
import { INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS } from './SolutionReportConsts';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ModifiableListReturnType } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';
import { Field } from '@tonkean/infrastructure';
import { LoadingCircle } from '@tonkean/infrastructure';
import { ADD_DIRECTION } from '@tonkean/infrastructure';
import type { SolutionBusinessReportGroupFilters, SolutionBusinessReportSavedFilter } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledSavePane = styled.div`
    display: flex;
    align-items: center;
`;

const StyledSaveButton = styled(Button)`
    margin-left: 5px;
`;

const StyledResetButton = styled(Button)`
    margin-right: 5px;
`;

interface Props {
    solutionBusinessReportId: string;
    groupId: string;
    definition: SolutionBusinessReportGroupFilters;
    savedFiltersModifiableList: ModifiableListReturnType<SolutionBusinessReportSavedFilter>;
    onDefinitionChange: (filters: SolutionBusinessReportGroupFilters) => void;
}
const SolutionBusinessReportSavedFilters: React.FC<Props> = ({
    solutionBusinessReportId,
    groupId,
    definition,
    savedFiltersModifiableList,
    onDefinitionChange,
}) => {
    const hasActiveFilters = hasActiveSolutionBusinessReportGroupFilters(definition, false);

    const [innerSavedFilters, { onCreate: addSavedFilter, onDelete: deleteSavedFilter }] = savedFiltersModifiableList;

    // Instead of saving a state and making sure it is updated at all times, we shallow compare the definition to see
    // if its the selected filter
    const selectedSavedFilter = useMemo(() => {
        if (!innerSavedFilters || !definition) {
            return undefined;
        }

        return innerSavedFilters.find((filter) => filter.definition === definition);
    }, [definition, innerSavedFilters]);

    const [saving, setSaving] = useState(false);
    const [filterName, setFilterName] = useState('');

    const [{ loading: loadingSaveFilter }, createSolutionBusinessReportFilter] = useLazyTonkeanService(
        'createSolutionBusinessReportFilter',
    );

    const hasSavedFilters = !!innerSavedFilters?.length;

    return (
        <>
            {innerSavedFilters.map((filter) => (
                <SolutionBusinessReportSingleSavedFilter
                    key={filter.id}
                    solutionBusinessReportId={solutionBusinessReportId}
                    filter={filter}
                    isSelectedFilter={selectedSavedFilter?.id !== filter.id}
                    onDefinitionChange={onDefinitionChange}
                    onDelete={deleteSavedFilter}
                />
            ))}
            {hasActiveFilters && (
                <>
                    {hasSavedFilters && <span className="common-separator-v" />}
                    <StyledResetButton
                        onClick={() => onDefinitionChange(INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS)}
                        size={ButtonSize.SMALL}
                        outlined
                    >
                        Reset
                    </StyledResetButton>

                    {!saving && (
                        <Button onClick={() => setSaving(true)} size={ButtonSize.SMALL}>
                            Save New Filter
                        </Button>
                    )}

                    {loadingSaveFilter && <LoadingCircle />}

                    {saving && !loadingSaveFilter && (
                        <StyledSavePane>
                            <Field>
                                <Input
                                    size={InputSize.MEDIUM}
                                    placeholder="Filter Name..."
                                    value={filterName}
                                    onChange={(event) => setFilterName(event.target.value)}
                                    autoFocus
                                />
                            </Field>
                            <StyledSaveButton
                                type="submit"
                                disabled={!filterName?.length}
                                size={ButtonSize.SMALL}
                                onClick={() => {
                                    createSolutionBusinessReportFilter(
                                        solutionBusinessReportId,
                                        groupId,
                                        filterName,
                                        definition,
                                    ).then((newFilter) => {
                                        setSaving(false);
                                        addSavedFilter(newFilter, ADD_DIRECTION.END);
                                        setFilterName('');
                                        onDefinitionChange(newFilter.definition);
                                    });
                                }}
                            >
                                Save
                            </StyledSaveButton>
                        </StyledSavePane>
                    )}
                </>
            )}
        </>
    );
};

export default SolutionBusinessReportSavedFilters;
