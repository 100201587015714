import type { IDirectiveFactory } from 'angular';

/**
 * Like ng-bind-html but if the html is faulty will just display it as text
 */
const bindUserHtml: IDirectiveFactory = ($sce, $parse, $compile) => {
    return {
        restrict: 'A',
        compile: (tElement, tAttrs) => {
            /* this code is copied from ng-bind-html */
            const ngBindHtmlGetter = $parse(tAttrs.bindUserHtml);
            const ngBindHtmlWatch = $parse(tAttrs.bindUserHtml, (val) => {
                // Unwrap the value to compare the actual inner safe value, not the wrapper object.
                return $sce.valueOf(val);
            });
            $compile.$$addBindingClass(tElement);

            return (scope, element, attr) => {
                $compile.$$addBindingInfo(element, attr.bindUserHtml);

                scope.$watch(ngBindHtmlWatch, () => {
                    // The watched value is the unwrapped value. To avoid re-escaping, use the direct getter.
                    const value = ngBindHtmlGetter(scope);

                    /* this try catch is our addition */
                    try {
                        const trustedHtml = $sce.getTrustedHtml(value);
                        element.html(trustedHtml || '');
                    } catch {
                        if (element[0]) {
                            element[0].innerText = value;
                        }
                    }
                });
            };
        },
    };
};
export default angular.module('tonkean.app').directive('bindUserHtml', bindUserHtml);
