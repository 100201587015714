import template from './tnkNlpBranchConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkNlpBranchConfiguration', {
    bindings: {
        branchId: '<',
        definition: '<',
        validationObject: '<',
        textAnalysisType: '<',
        selectedTrainingSetId: '<',
        groupId: '<',

        onBranchDefinitionChanged: '&',
        trainingSetModelsToExclude: '<',
        onBranchNameChanged: '<',
    },
    template,
    controller: 'NlpBranchConfigurationCtrl',
});
