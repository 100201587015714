export const EMPTY_AND_REQUIRED_CLASS_NAME = 'ag-empty-and-required-cell';
export const ERRORED_ROW_CLASS_NAME = 'ag-row-with-error';
export const REQUIRED_HEADER_CLASS_NAME = 'required-header';
export const EMPHASIZED_HEADER_CLASS_NAME = 'emphasized-header';
export const FILLED_FILTER_ICON_CLASS_NAME = 'filled-filter-icon';
export const ROW_BACKGROUND_COLOR = 'row-background-color';
export const HEADER_CELL_RESIZE = 'ag-header-cell-resize';
export const HEADER_CELL = 'ag-header-cell';
export const HEADER_ROW = 'ag-header-row';
export const HEADER = 'ag-header';
export const ALPINE_THEME = 'ag-theme-alpine';
export const ASC_ICON = 'ag-icon-asc';
export const DESC_ICON = 'ag-icon-desc';
export const HEADER_MENU_BUTTON = 'ag-header-cell-menu-button';
export const ROW = 'ag-row';
export const FILTER_ICON = 'ag-filter-icon';
export const CELL = 'ag-cell';
export const ROOT_WRAPPER = 'ag-root-wrapper';
export const CLICKABLE_ROW = 'row-clickable';
