import { useAngularService } from 'angulareact';
import type { Variants } from 'framer-motion';
import { motion, useReducedMotion } from 'framer-motion';
import React, { useContext, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled, { css } from 'styled-components';

import ItemWidgetBody from './ItemWidgetBody';
import ItemWidgetHeader from './ItemWidgetHeader';
import ItemWidgetSidepaneTrigger from './ItemWidgetSidepaneTrigger';
import getInterfaceWidgetElementId from '../../components/getItemInterfaceElementId';
import InterfaceQuickNavigationContext from '../../components/InterfaceQuickNavigationContext';
import ItemInterfacePermission from '../../entities/ItemInterfacePermission';
import ItemWidgetContext from '../../entities/ItemWidgetContext';
import { getIsInWidgetBuilder, shouldHideWidget } from '../utils';
import itemInterfaceErrorCss from '../utils/itemInterfaceErrorCss';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { Breakpoint, ItemInterfaceSection } from '@tonkean/infrastructure';
import { ConditionalWidgetIcon, ErrorIcon } from '@tonkean/svg';
import { getWidgetDisplayColumns, InterfaceQuickNavigationMode } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

type ContainerProps = {
    $hide: boolean;
    $disableMinHeight: boolean;
    $isHoverAllowed: boolean;
    $disableMaxHeight?: boolean;
    $displayColumns: number;
    overflow?: React.CSSProperties['overflow'];
    $showAsQuestionWidget?: boolean;
    itemWidgetBodyMinHeight?: number;
    itemWidgetBodyMaxHeight?: number;
};

const NO_ICON: string = 'no icon';

const getBodyWrapperAnimationState = (isWidgetEdited: boolean, stayHighlighted: boolean) => {
    if (isWidgetEdited) {
        return stayHighlighted ? 'highlight' : 'highlightPulse';
    }
    return 'normal';
};
const BODY_WRAPPER_OUTLINE_HIGHLIGHT = `1px solid ${Theme.colors.primaryHighlight}`;
const BODY_WRAPPER_OUTLINE_NORMAL = `0px solid ${Theme.colors.gray_300}`;
const BODY_WRAPPER_BOX_SHADOW_HIGHLIGHT = '0 1px 6px rgba(47, 132, 220, 0.62)';
const BODY_WRAPPER_ANIMATION_VARIANTS: Variants = {
    highlight: {
        outline: BODY_WRAPPER_OUTLINE_HIGHLIGHT,
        boxShadow: BODY_WRAPPER_BOX_SHADOW_HIGHLIGHT,
        borderRadius: '6px',
        transition: {
            duration: 0.2,
            easings: 'ease',
        },
    },
    highlightPulse: {
        outline: [BODY_WRAPPER_OUTLINE_NORMAL, BODY_WRAPPER_OUTLINE_HIGHLIGHT, BODY_WRAPPER_OUTLINE_NORMAL],
        boxShadow: [
            '0 1px 6px rgba(47, 132, 220, 0)',
            BODY_WRAPPER_BOX_SHADOW_HIGHLIGHT,
            '0 1px 6px rgba(47, 132, 220, 0)',
        ],
        borderRadius: '6px',
        transition: {
            duration: 1.5,
            easings: 'easeOut',
        },
    },
    normal: {
        borderRadius: '6px',
        outline: BODY_WRAPPER_OUTLINE_NORMAL,
    },
};

const Container = styled(motion.div)<ContainerProps>`
    // We do flex column so that the body component can stretch as needed and respect the max-height on its parent
    display: ${({ $hide }) => ($hide ? 'none' : 'flex')};
    flex-direction: column;
    position: relative;

    ${({ $showAsQuestionWidget }) =>
        $showAsQuestionWidget &&
        css`
            margin-bottom: auto;
        `};

    ${({ $disableMinHeight }) =>
        !$disableMinHeight &&
        css`
            min-height: 360px;
        `};

    ${({ $disableMaxHeight }) =>
        !$disableMaxHeight &&
        css`
            max-height: 560px;
        `};

    grid-column: ${({ $displayColumns }) => `span ${$displayColumns}`};

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-column: span 1;
        width: 80vw;
        margin: auto;
    }

    ${({ $isHoverAllowed }) =>
        $isHoverAllowed &&
        css`
            .item-widget-content {
                transition: box-shadow 200ms ease;
            }

            &:hover {
                .item-widget-content {
                    border-color: rgba(47, 132, 220, 0.4);
                    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 6px;
                }
            }
        `} // Using "overflow: auto" in the rich text widget messes with the angular expression picker.
            // so we must provide an escape hatch.
            // set the default to "visible" because when it auto it removes the box shadow
    overflow-x: ${({ overflow = 'visible' }) => overflow};
`;

const ErrorContainer = styled.div`
    ${itemInterfaceErrorCss};
    flex-grow: 1;
    margin: auto;
`;

const NoInitiativeIndication = styled.div`
    ${itemInterfaceErrorCss};
    flex-grow: 1;
    margin: auto;
    gap: 20px;
`;

const DisplayConditionsIndicatorContainer = styled.div`
    margin-top: 5px;
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    display: flex;
    align-items: center;
`;

const StyledConditionalWidgetIcon = styled(ConditionalWidgetIcon)`
    path {
        stroke: ${Theme.colors.gray_600};
    }

    margin-right: 4px;
`;

export enum ItemWidgetSidepaneTriggerZone {
    FULL_WIDGET = 'FULL_WIDGET',
    HEADER = 'HEADER',
}

const ItemWidgetFooter = styled.div`
    border-top: 1px solid ${Theme.colors.gray_300};
    background-color: ${Theme.colors.gray_100};
    border-radius: 0 0 4px 4px;
`;

const BodyAndFooterWrapper = styled(motion.div)`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
`;

const CoreEditorSerializeContainer = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    color: ${Theme.colors.gray_600};
`;

const ItemWidgetErrorContent = () => {
    return (
        <ErrorContainer>
            <ErrorIcon />
            Oops, an error occurred displaying the widget
        </ErrorContainer>
    );
};

const ItemWidgetHeaderDescriptionErrorContent = () => {
    return (
        <ErrorContainer>
            <ErrorIcon />
            There is an issue with displaying the description. It might arise if the text was copied from an external
            source. To resolve this, ensure that the description is typed directly into the provided text box to avoid
            any potential formatting or encoding errors.
        </ErrorContainer>
    );
};

export const ItemWidgetError: React.FC = () => {
    return (
        <Container $hide={false} $disableMinHeight={false} $isHoverAllowed={false} $displayColumns={2}>
            <ErrorContainer>
                <ItemWidgetErrorContent />
            </ErrorContainer>
        </Container>
    );
};

const defaultInitiativeIsRequiredToPreviewWidgetComponent = (
    <NoInitiativeIndication>
        <ErrorIcon />
        Item is required to preview this widget.
    </NoInitiativeIndication>
);

interface Props extends StyledComponentsSupportProps {
    /**
     * If undefined will use default behavior. So when overriding use either true or false, not undefined.
     */
    hideOverride?: boolean;
    sidepaneTriggerZone?: ItemWidgetSidepaneTriggerZone;
    headerTitle?: string | JSX.Element;
    headerActions?: JSX.Element;
    headerSavingIndicator?: JSX.Element;
    subHeader?: string | JSX.Element;
    disableMinHeight?: boolean;
    disableMaxHeight?: boolean;
    noPaddingBody?: boolean;
    noBorderBody?: boolean;
    noBackgroundBody?: boolean;
    growBody?: boolean;
    dashedBorderBody?: boolean;
    sidepaneDisabled?: boolean;
    footer?: JSX.Element | false;
    enableHorizontalScrolling?: boolean;
    showIcon?: boolean;
    permission: ItemInterfacePermission;
    showAsQuestionWidget?: boolean;
    itemWidgetBodyHeight?: number;
    itemWidgetBodyMinHeight?: number;
    itemWidgetBodyMaxHeight?: number;
    // When True, the body will be hidden and empty state will be displayed.
    showInitiativeIsRequiredToPreviewWidget?: boolean;
    initiativeIsRequiredToPreviewWidgetComponent?: React.ReactNode;

    /**
     * Option to let widget override the `overflow` property of the container.
     * Main usage is for the rich text widget, where the angular expression picker is messed up
     * when using `overflow: auto`.
     */
    containerOverflowOverride?: React.CSSProperties['overflow'];
    pluggableActions?: React.ReactNode;
}

const ItemWidget: React.FC<React.PropsWithChildren<Props>> = ({
    hideOverride,
    sidepaneTriggerZone = ItemWidgetSidepaneTriggerZone.FULL_WIDGET,
    headerTitle: headerProp,
    headerActions,
    headerSavingIndicator,
    subHeader,
    children,
    className,
    disableMinHeight = false,
    disableMaxHeight = false,
    noPaddingBody = false,
    noBorderBody = false,
    noBackgroundBody = false,
    growBody = false,
    dashedBorderBody = false,
    sidepaneDisabled: sidepaneDisabledProp,
    footer,
    enableHorizontalScrolling = false,
    showIcon = true,
    permission,
    containerOverflowOverride,
    showAsQuestionWidget,
    itemWidgetBodyHeight,
    itemWidgetBodyMinHeight,
    itemWidgetBodyMaxHeight,
    showInitiativeIsRequiredToPreviewWidget = false,
    initiativeIsRequiredToPreviewWidgetComponent = defaultInitiativeIsRequiredToPreviewWidgetComponent,
    pluggableActions,
}) => {
    const widgetContext = useContext(ItemWidgetContext);
    const [editedWidgetId] = useGetStateParams('widgetId');
    const {
        selectedWidgetId: quickNavvedWidgetId,
        setSelectedWidgetId: setQuickNavvedWidgetId,
        quickNavigationMode,
        tabIdByWidgetId,
        tabs,
    } = useContext(InterfaceQuickNavigationContext);
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    const [isWidgetActiveOrInHover, setIsWidgetActiveOrInHover] = useState(false);

    const handleActiveOrHoverState = () => {
        setIsWidgetActiveOrInHover(true);
    };

    const handleNotActiveState = () => {
        setIsWidgetActiveOrInHover(false);
    };

    const headerTitle = headerProp ?? widgetContext?.widget.displayName.trim();
    const description = widgetContext?.widget.displayDescription;
    const tooltipDescription = description?.flatMap((element) => element.children.map((node) => node.text)).join('\n');
    const showDisplayDescriptionAsTooltip = widgetContext?.widget.showDisplayDescriptionAsTooltip;
    const sidepaneDisabled =
        widgetContext?.section === ItemInterfaceSection.PANEL || sidepaneDisabledProp || !widgetContext?.isEditable;

    const entireItemClickable =
        widgetContext?.section === ItemInterfaceSection.MAIN &&
        !sidepaneDisabled &&
        sidepaneTriggerZone === ItemWidgetSidepaneTriggerZone.FULL_WIDGET;

    const layoutDeps = useMemo(
        () => [widgetContext?.widget.index, getWidgetDisplayColumns(widgetContext?.widget)],
        [widgetContext?.widget],
    );

    const reduceMotion = useReducedMotion();

    const canEditInBuilder = useMemo(() => {
        return isInBuilder && widgetContext?.section === ItemInterfaceSection.MAIN;
    }, [isInBuilder, widgetContext?.section]);

    // if we are in the builder we want to be able to move between widgets to see configurations
    const headersAreClickable =
        canEditInBuilder || (!sidepaneDisabled && sidepaneTriggerZone === ItemWidgetSidepaneTriggerZone.HEADER);

    const elementId = widgetContext?.widget && getInterfaceWidgetElementId(widgetContext.widget.id);

    const displayConditionsEnabled = useMemo(() => {
        return !!(canEditInBuilder && widgetContext?.widget?.configuration?.enableDisplayConditions);
    }, [canEditInBuilder, widgetContext?.widget?.configuration?.enableDisplayConditions]);

    const shouldBeDashed = useMemo(() => {
        return displayConditionsEnabled || (canEditInBuilder && dashedBorderBody);
    }, [displayConditionsEnabled, dashedBorderBody, canEditInBuilder]);

    const noBorderBodyOverride = useMemo(() => {
        return (!canEditInBuilder && noBackgroundBody) || noBorderBody;
    }, [canEditInBuilder, noBackgroundBody, noBorderBody]);
    const widgetIcon = widgetContext?.widget?.configuration?.['icon'];

    const widgetHasIcon = widgetIcon?.name !== NO_ICON;

    const isWidgetEdited = editedWidgetId === widgetContext?.widget.id;
    const isWidgetQuickNavved = quickNavvedWidgetId === widgetContext?.widget.id;
    const stayHighlighted = permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;
    const bodyWrapperAnimationState = getBodyWrapperAnimationState(
        isWidgetEdited || isWidgetQuickNavved,
        stayHighlighted,
    );

    const tabIdOfCurrentWidget = widgetContext?.widget && tabIdByWidgetId?.[widgetContext?.widget?.id];
    const hideWidget = shouldHideWidget(
        hideOverride,
        isInBuilder,
        quickNavigationMode,
        tabIdOfCurrentWidget,
        quickNavvedWidgetId,
    );

    return (
        <Container
            id={elementId}
            data-automation="item-widget"
            data-automation-label={widgetContext?.widget.type}
            data-automation-width={getWidgetDisplayColumns(widgetContext?.widget)}
            className={className}
            $hide={hideWidget}
            $disableMinHeight={disableMinHeight}
            $disableMaxHeight={disableMaxHeight}
            $isHoverAllowed={!sidepaneDisabled}
            $displayColumns={getWidgetDisplayColumns(widgetContext?.widget)}
            layout={!reduceMotion && permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE}
            layoutDependency={layoutDeps}
            overflow={containerOverflowOverride}
            $showAsQuestionWidget={showAsQuestionWidget}
            onMouseEnter={handleActiveOrHoverState}
            onMouseLeave={handleNotActiveState}
        >
            {entireItemClickable && <ItemWidgetSidepaneTrigger />}
            {(headerTitle || headerActions) && (
                <ItemWidgetHeader
                    isClickable={headersAreClickable}
                    descriptionTooltip={showDisplayDescriptionAsTooltip ? tooltipDescription : undefined}
                    inert={canEditInBuilder ? '' : null}
                    title={headerTitle}
                    wrapTitle={widgetContext?.widget?.configuration?.wrapDisplayName}
                    actions={headerActions}
                    widget={widgetContext?.widget}
                    showIcon={widgetHasIcon && showIcon}
                    savingIndicator={headerSavingIndicator}
                    widgetIcon={widgetIcon}
                    pluggableActions={!canEditInBuilder && pluggableActions}
                    isWidgetActiveOrInHover={isWidgetActiveOrInHover}
                />
            )}
            {subHeader && (
                <ItemWidgetHeader
                    isClickable={headersAreClickable}
                    inert={canEditInBuilder ? '' : null}
                    title={subHeader}
                    widget={widgetContext?.widget}
                    showIcon={false}
                    shouldRemoveTopPadding
                />
            )}
            <ErrorBoundary FallbackComponent={ItemWidgetHeaderDescriptionErrorContent}>
                <CoreEditorSerializeContainer data-automation="widget-description">
                    {description && description.length > 0 && !showDisplayDescriptionAsTooltip && (
                        <CoreEditorSerialize plugins={HTMLEditorFullPlatePluginsList} value={description} />
                    )}
                </CoreEditorSerializeContainer>
            </ErrorBoundary>

            <BodyAndFooterWrapper
                className="item-widget-content"
                variants={BODY_WRAPPER_ANIMATION_VARIANTS}
                animate={
                    quickNavigationMode === InterfaceQuickNavigationMode.STANDARD || isInBuilder
                        ? bodyWrapperAnimationState
                        : undefined
                }
                onAnimationComplete={() => {
                    // If we dont want it to stay highlighted we should reset the selected id
                    if (!stayHighlighted) {
                        if (isWidgetEdited) {
                            $state.go('.', { widgetId: undefined }, { location: false, inherit: true, notify: false });
                        }

                        if (isWidgetQuickNavved) {
                            setQuickNavvedWidgetId?.(undefined);
                        }
                    }
                }}
            >
                <ErrorBoundary FallbackComponent={ItemWidgetErrorContent}>
                    <ItemWidgetBody
                        data-automation="item-widget-body"
                        inert={entireItemClickable ? '' : null}
                        $noPadding={noPaddingBody}
                        $noBorderBody={noBorderBodyOverride}
                        $noBackgroundBody={noBackgroundBody}
                        $enableHorizontalScrolling={enableHorizontalScrolling}
                        $dashedBorderBody={shouldBeDashed}
                        $itemWidgetBodyHeight={itemWidgetBodyHeight}
                        $itemWidgetBodyMinHeight={itemWidgetBodyMinHeight}
                        $itemWidgetBodyMaxHeight={itemWidgetBodyMaxHeight}
                        $noBottomBorderRadius={!!footer}
                        $growBody={growBody}
                    >
                        {showInitiativeIsRequiredToPreviewWidget
                            ? initiativeIsRequiredToPreviewWidgetComponent
                            : children}
                    </ItemWidgetBody>
                </ErrorBoundary>

                {footer && <ItemWidgetFooter inert={canEditInBuilder ? '' : null}>{footer}</ItemWidgetFooter>}
            </BodyAndFooterWrapper>
            {displayConditionsEnabled && (
                <DisplayConditionsIndicatorContainer>
                    <StyledConditionalWidgetIcon />
                    <i>This widget is displayed conditionally. See the display conditions in the configuration.</i>
                </DisplayConditionsIndicatorContainer>
            )}
        </Container>
    );
};

export default ItemWidget;
