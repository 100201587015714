import { SlackAuthenticationType } from '@tonkean/tonkean-entities';
import { analyticsWrapper } from '@tonkean/analytics';

function LoginAddBotCtrl(
    $scope,
    $state,
    consts,
    createProjectApis,
    oauthHandler,
    communicationIntegrationsService,
    environment,
    projectManager,
) {
    $scope.data = {};

    $scope.init = function () {
        $scope.loading = true;
        $scope.pm.getProjects().then(function () {
            if ($scope.pm.project && communicationIntegrationsService.anyIntegrationsConnected()) {
                $scope.close();
            } else {
                $scope.loading = false;
            }
        });
    };

    $scope.init();

    $scope.connectSlack = function () {
        $scope.creating = true;
        const slackOauth2Enabled = true; // TODO (ofirshomron): remove or change to true after migration: https://tonkean.atlassian.net/browse/TNKN-15537

        oauthHandler
            .slack(
                environment.integrationKeysMap.slack,
                slackOauth2Enabled ? '' : consts.getSlackLoginUserScopes(),
                slackOauth2Enabled ? '' : consts.getSlackLoginUserScopes(),
                slackOauth2Enabled,
            )
            .then(function (code) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    'slack',
                    {
                        code,
                        redirectUri: environment.integrationKeysMap.slackApp,
                        authType: SlackAuthenticationType.OAUTH2,
                    },
                    undefined,
                    undefined,
                );
            })
            .then(function (integrationObj) {
                analyticsWrapper.track('Add Slack', { category: 'Edit Project', label: 'Connect' });
                window.Intercom('trackEvent', 'AddBot');

                return createProjectApis
                    .createProjectIntegration($scope.pm.project, integrationObj)
                    .then(function (data) {
                        return $scope.pm.selectProject(data).then(function () {
                            // $state.go('product', {projectId: $scope.pm.project.id});
                            // $state.go('quickSetup');
                            $scope.close();
                        });
                    });
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.creating = false;
            });
    };

    /**
     * Happens when the user either connects Slack or chooses to skip.
     */
    $scope.close = function () {
        $state.go('loginColumn');
    };
}

export default angular.module('tonkean.app').controller('LoginAddBotCtrl', LoginAddBotCtrl);
