<div
    class="common-size-xxs show-edit-when-hover-visibility"
    ng-show="item.metadata && (item.metadata.newUpdateText || item.metadata.newEta)"
>
    <!--<hr class="margin-normal-sm-v hidden-xs"/>-->
    <button
        class="btn btn-link activity-comment-delete btn-on-hover"
        ng-if="pm.isOwner ||item.actor.id === as.currentUser.id"
        ng-disabled="deleting"
        ng-click="deleteItem(item.id,true)"
    >
        <i ng-hide="deleting" class="fa fa-trash-o"></i>
        <i ng-show="deleting" class="loading"></i>
    </button>
    <p class="common-break-long" ng-show="item.metadata.newUpdateText" light-markdown="item.metadata.newUpdateText"></p>

    <!--<hr ng-if="item.metadata.newTicketUrl" class="margin-normal-sm-v"/>-->
    <div class="common-subtitle-inner common-size-xxs">
        <!--<span class="common-tag-full pointer mod-grey margin-right " ng-if="item.metadata.newState.stateText"-->
        <!--ng-style="{'background-color':item.metadata.newState.stateColor}">-->
        <!--{{item.metadata.newState.stateText }}-->
        <!--</span>-->
        <span ng-show="item.metadata.newEta" class="common-size-xxxxs">ETA: {{ item.metadata.newEta | daysDiff }}</span>
    </div>
</div>
