import React from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';
import { MicrosoftTeamsAppSelection } from '../components';

const MicrosoftTeamsAppCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    onChangeOrInitIntegration,
    projectIntegration,
}) => {
    return (
        <div className="flex flex-col">
            <MicrosoftTeamsAppSelection
                integration={integration}
                projectIntegration={projectIntegration}
                onApplicationConnectedToTeam={(projectData, isDisabled = true) =>
                    onChangeOrInitIntegration({ projectIntegrationData: projectData }, isDisabled)
                }
            />
        </div>
    );
};

export default MicrosoftTeamsAppCustomizeSetupComponent;
