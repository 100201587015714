import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import getIntegrationByType from '../../../../infrastructure/utils/getIntegrationByType';
import { toSavingState } from '../../../../infrastructure/utils/toSavingState';
import PeoplePermissionsLabel from '../PeoplePermission/PeoplePermissionsLabel';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Chevron,
    ChevronDirection,
    SavingIndicator,
    Separator,
    Toggle,
    useCreateDialog,
} from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ListItem = styled(Clickable)`
    display: grid;
    grid-template-columns: 280px auto 117px 22px;
    align-items: center;

    height: 50px;
    padding-left: 14px;

    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

const IntegrationIcon = styled(ProjectIntegrationIcon)`
    margin-right: 6px;
`;

const IntegrationName = styled.div`
    display: flex;
    align-items: center;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
`;

const StyledToggle = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    dataSourceConnection: DataSourceConnection;
}

const DataSourceConnectionListItem: React.FC<Props> = ({ dataSourceConnection }) => {
    const project = useProject();
    const utils = useAngularService('utils');

    const { confirm } = useCreateDialog();

    const [enabled, setEnabled] = useState(dataSourceConnection.enabled);

    const [
        {
            loading: updateDataSourceEnabledStateLoading,
            error: updateDataSourceEnabledStateError,
            called: updateDataSourceEnabledStateCalled,
        },
        updateDataSourceConnection,
    ] = useLazyTonkeanService('updateDataSourceConnection');

    // Get the integration info (we need the display name for presentation)
    const integration = useMemo(
        () => getIntegrationByType(dataSourceConnection.dataSourceType),
        [dataSourceConnection],
    );

    // Calculate the saving indicator state
    const savingState = toSavingState(
        updateDataSourceEnabledStateLoading,
        updateDataSourceEnabledStateError,
        updateDataSourceEnabledStateCalled,
    );

    const onEnabledToggle = useCallback(
        async ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>) => {
            if (integration) {
                const isConfirmed = await confirm(
                    `Turn ${checked ? 'on' : 'off'} ${integration.displayName} rule`,
                    `Are you sure you want to turn the ${integration.displayName} rule ${
                        checked ? 'on' : 'off'
                    }? This will only affect new connections.`,
                    {
                        okLabel: 'Confirm',
                        cancelLabel: 'Cancel',
                    },
                );

                if (isConfirmed) {
                    setEnabled(checked);
                    await updateDataSourceConnection(
                        project.id,
                        dataSourceConnection.id,
                        dataSourceConnection.allowOnlySharedCredentials,
                        checked,
                        dataSourceConnection?.connectionPermission,
                        dataSourceConnection?.authorizedEntitiesIds,
                    );
                }
            }
        },
        [
            integration,
            confirm,
            setEnabled,
            updateDataSourceConnection,
            project.id,
            dataSourceConnection.id,
            dataSourceConnection.allowOnlySharedCredentials,
            dataSourceConnection?.connectionPermission,
            dataSourceConnection?.authorizedEntitiesIds,
        ],
    );

    const authorizedPeopleIds = Object.keys(
        utils.arrayToSet(
            (dataSourceConnection.authorizedEntitiesIds || []).concat(project?.owners.map((owner) => owner.id) || []),
        ),
    );

    return (
        <>
            <ListItem
                data-automation="data-source-connection-list-item"
                state="product.dataSourceConnection"
                params={{ dataSourceConnectionId: dataSourceConnection.id }}
                options={{ inherit: true }}
            >
                <IntegrationName>
                    <IntegrationIcon width={14} integrationType={dataSourceConnection.dataSourceType} />
                    {integration?.displayName}
                </IntegrationName>

                <PeoplePermissionsLabel
                    connectionPermission={dataSourceConnection.connectionPermission}
                    authorizedPeopleIds={authorizedPeopleIds}
                    noOneLabel="No one except board admins"
                />

                <StyledToggle>
                    <Toggle
                        checked={enabled}
                        size={InputSize.MEDIUM}
                        onClick={(event) => event.stopPropagation()}
                        onChange={onEnabledToggle}
                        showInnerLabel
                    />
                    <SavingIndicator state={savingState} />
                </StyledToggle>

                <div>
                    <Chevron direction={ChevronDirection.RIGHT} />
                </div>
            </ListItem>

            <Separator color={Theme.colors.gray_300} />
        </>
    );
};

export default DataSourceConnectionListItem;
