import { useAngularService } from 'angulareact';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { CustomTriggerType } from '@tonkean/tonkean-entities';

function useGetItemInterfaceWidgetsData(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
    const formManager = useAngularService('formManager');
    const {
        data: forms,
        loading: loadingForms,
        error: errorForms,
    } = useAsyncMethod(formManager, 'getAllWorkerForm', workflowVersionId);

    const customTriggerManager = useAngularService('customTriggerManager');
    const {
        data: customTriggers,
        loading: loadingCustomTriggers,
        error: errorCustomTriggers,
    } = useAsyncMethod(
        customTriggerManager,
        'getCustomTriggersOfTypeInWorkflowVersion',
        workflowVersionId,
        CustomTriggerType.AUTONOMOUS,
    );

    const {
        data: interfacesObj,
        loading: loadingInterfaces,
        error: errorInterfaces,
    } = useTonkeanService('getItemInterfacesByWorkflowVersionId', workflowVersionId);
    const interfaces = interfacesObj?.entities;
    return {
        forms,
        customTriggers,
        interfaces,
        loadingForms,
        loadingCustomTriggers,
        loadingInterfaces,
        errorForms,
        errorCustomTriggers,
        errorInterfaces,
    };
}

export default useGetItemInterfaceWidgetsData;
