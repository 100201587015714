<div class="initiatives-header" ng-class="{ 'mod-show-inner-project-filter': showProjectInnerFilter }">
    <div class="flex flex-vmiddle padding-normal-sm">
        <div class="common-title-md margin-left-lg relative margin-right-xlg margin-top-none margin-bottom-none">
            <span>Archive</span>
        </div>
        <div class="common-color-grey margin-right">For module:</div>
        <div class="margin-right-xlg">
            <div uib-dropdown class="flex-no-shrink" ng-if="data.groupId">
                <span uib-dropdown-toggle class="pointer">
                    {{ pm.groupsMap[data.groupId].name }}
                    <i class="chevron-bottom margin-left"></i>
                </span>

                <ul uib-dropdown-menu class="dropdown-menu overflow-y-auto" style="max-height: 200px">
                    <li ng-repeat="group in pm.groups" ng-if="!group.isExample">
                        <a ui-sref="{gid: group.id}">{{ group.name }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Environment selector-->
        <div ng-if="pm.isOwner || gm.currentUserIsOwnerMap[data.groupId]">
            <tnk-environment-indicator
                dont-show-is-active-indicator="true"
                current-environment="data.isDraft ? 'build' : 'production'"
                on-changes="(onEnvironmentToggleClick)"
            ></tnk-environment-indicator>
        </div>

        <div>
            <form ng-submit="init()">
                <!-- Search list-->
                <div class="flex-vmiddle margin-left initiatives-inner-search-list-box">
                    <span class="svg-icon-sm margin-right">
                        <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                    </span>
                    <input
                        class="
                            form-control
                            flex-no-shrink
                            common-input-only-line common-no-outline
                            margin-right-xs
                            common-size-xxxxs
                        "
                        placeholder="Search..."
                        ng-model="data.search"
                    />
                    <button
                        type="submit"
                        class="btn btn-primary margin-right-xs btn-sm"
                        ng-if="data.search && data.search.length"
                    >
                        Apply
                    </button>
                    <button
                        class="btn btn-default btn-sm"
                        ng-click="data.search = ''; init();"
                        ng-if="data.search && data.search.length"
                    >
                        Clear
                    </button>
                </div>
            </form>
        </div>
    </div>
    <hr class="margin-none" />
</div>

<div class="initiatives-content">
    <div
        class="common-page-load padding-top-xlg"
        ng-show="data.loading && (!data.allInitiatives || !data.allInitiatives.length)"
    >
        <i class="loading-full-circle"></i>
    </div>

    <div
        class="tracks-editor margin-top-lg"
        ng-if="!data.loading || (data.allInitiatives && data.allInitiatives.length)"
    >
        <div
            ng-if="!data.allInitiatives || !data.allInitiatives.length"
            class="padding-normal common-color-grey text-center"
        >
            <div class="text-center">
                <i class="fa fa-archive margin-normal"></i>
                {{ data.search && data.search.length ? 'No results found' : 'Archive is empty.' }}
                <br />
            </div>
        </div>

        <div ng-if="data.allInitiatives && data.allInitiatives.length">
            <tnk-tracks-editor
                tracks="data.allInitiatives"
                editor-id="archive"
                default-me="true"
                loading="data.loading"
                allow-dnd-on-root="false"
                show-add-on-empty="false"
                show-archived="true"
                hide-add-new-force="true"
                group-id="data.groupId"
                workflow-version-id="null"
                custom-fields="null"
            ></tnk-tracks-editor>

            <div class="margin-top" ng-if="data.hasMoreEntities">
                <a ng-click="loadInitiatives(true);" ng-if="!data.loadingMore">Load more...</a>
                <span class="loading-small" ng-if="data.loadingMore"></span>
            </div>
        </div>
    </div>
</div>

<tnk-system-interface-modal />
