import React from 'react';
import { daysDiffInSentenceFilter } from '@tonkean/shared-services';
import DefaultInboxReason from './DefaultInboxReason';

export default class DateInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;
        const fieldName = this.props.fieldName;
        const fieldLabel = this.props.fieldLabel;

        if (!item.reasonMetadata || !item.reasonMetadata[fieldName]) {
            return null;
        }

        const fieldValue = item.reasonMetadata[fieldName];
        const fieldValueElement = (
            <span className="common-bold tnk-tooltip mod-top">
                {daysDiffInSentenceFilter(fieldValue)}
                <span className="tnk-tooltip-text">{new Date(fieldValue).toLocaleString()}</span>
            </span>
        );
        // If in future
        if (fieldValue >= new Date()) {
            return (
                <div>
                    {this.renderTitleLinkElement(initiative)}'s {fieldLabel} is {fieldValueElement}, any new updates?
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        {this.renderTitleLinkElement(initiative)}'s {fieldLabel} has passed{' '}
                        <span className="common-color-danger">{fieldValueElement}</span>
                    </div>
                    <div>What's the latest status?</div>
                </div>
            );
        }
    }
}
