import type CreateAfterOption from '../../../entities/CreateAfterOption';
import { RunOnTypes } from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType, TonkeanActionType } from '@tonkean/tonkean-entities';

const addCreateItemInAnotherModuleOptionCanCreate: CreateAfterOptionCanCreate = () => {
    return true;
};

const addCreateItemInAnotherModuleParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.TONKEAN_ACTION;
    params.actionType = TonkeanActionType.CREATE_TRACK;
    params.displayName = addCreateItemInAnotherModuleOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
};

const addCreateItemInAnotherModuleOption: CreateAfterOption = {
    label: 'Send to another module',

    runOnType: RunOnTypes.INNER_ITEM,
    blockDisplayName: 'Send to another module',
    triggerParamsEnricher: addCreateItemInAnotherModuleParamsEnricher,
    canCreate: addCreateItemInAnotherModuleOptionCanCreate,
};

export default addCreateItemInAnotherModuleOption;
