import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class JSLTFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.JSLT;
    override readonly sign = 'JSLT';
    override readonly displayName = 'JSON Transformation Language';
    override readonly description = 'Will returned a transformed JSON content based on the JSLT provided.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'JSLT',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Input Json',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Pair',
            pluralName: 'Pairs',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 0,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: [FieldType.String, FieldType.Number, FieldType.Boolean, FieldType.Date],
                    displayName: `key ${repeatIndex}`,
                    explanation: 'The key of the pair.',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: [FieldType.String, FieldType.Number, FieldType.Boolean, FieldType.Date],
                    displayName: `value ${repeatIndex}`,
                    explanation: 'The value of the pair.',
                },
            ],
        },
    ];
}
