import { anyNonEmptyConditionsInQuery, FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP } from '@tonkean/tonkean-utils';
import { getFieldConditionToApiDefinitionMap, MATCH_CONDITION_TAGS_SEPARATOR } from '@tonkean/constants';

function CustomFiltersCtrl($scope, $timeout, projectManager, customFieldsManager, utils, integrations, apiConsts) {
    const ctrl = this;

    const fieldConditionToApiDefinitionMap = getFieldConditionToApiDefinitionMap();

    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.apiConsts = apiConsts;
    $scope.utils = utils;
    $scope.createDateSettings = integrations.getCreateDateSettings();

    $scope.integrationEntitiesAndFieldsConfiguration = apiConsts.getIntegrationEntitiesAndFieldsConfiguration();

    /**
     * $scope.data holds inner implementation details of the directive, and $scope.customFiltersDefinition holds shared data
     * with the calling controller.
     */
    $scope.data = {
        // The possible sources of the items.
        itemsSourceTypes: {
            external: 'EXTERNAL',
            column: 'COLUMN',
            custom: 'CUSTOM',
        },
        reloadFields: false,
        noFields: true,

        listPreviewQuery: null,
        reloadListPreview: false,
        listPreviewSoftLoading: false,
        listPreviewDefinitions: [],

        fieldDefinitionMap: {},

        uniqueIdentifier: utils.guid(),

        groupNameBeingEditId: null,
        filterInFocusId: null,
        groupId: $scope.groupId,

        projectIntegrationDescriptionClass: null,
        excludedTabSelectorSpecialFields: $scope.excludedTabSelectorSpecialFields,
        includeTabSelectorSpecialFieldsForFeatures: $scope.includeTabSelectorSpecialFieldsForFeatures,
        allowContains: $scope.allowContains,
        useExpressionsForValue: ctrl.useExpressionsForValue,
    };

    /**
     * Controller's initialization function.
     */
    $scope.init = function () {
        updateFieldDefinitionMap();
        initializeConsts();
        $scope.data.searchAllFields = this.searchAllFields;
        // Setting the items source
        if (!$scope.itemsSource) {
            $scope.itemsSource = $scope.data.itemsSourceTypes.external;
        }

        // Initialize control with functions
        $scope.control.customFiltersDefinition = {};
        $scope.control.createDefinitionFromCustomFilters = createDefinitionFromCustomFilters;
        $scope.control.reloadFromExistingDefinition = createCustomFiltersFromDefinition;
        $scope.control.loadConditionFields = loadConditionFields;
        $scope.control.clearFiltersAndQueries = clearFiltersAndQueries;
        $scope.control.anyNonEmptyConditions = anyNonEmptyConditions;

        // Initialize the custom filters definition
        $scope.control.customFiltersDefinition.selectedQueryCondition = $scope.data.queryTypes[0]; // Defaults to All
        $scope.control.customFiltersDefinition.enableTimeRange = false;

        let defaultFilter = {
            condition: 'Equals',
        };

        if ($scope.initializeQueryWithDefaultCreateDateCondition) {
            defaultFilter = {
                field: {
                    name: 'TNK_NEW_CREATED_DATE',
                    label: 'Created Date',
                    type: 'Date',
                },
                condition: 'Past',
                value: '2',
                secondValue: 'Days',
            };
        }

        $scope.control.customFiltersDefinition.query = {
            uiOpen: true,
            type: $scope.data.queryTypeToDisplayName['And'],
            name: null,
            selectedQueryCondition: $scope.data.queryTypeToDisplayName['And'],
            filters: [defaultFilter],
        };

        loadItemsSource();

        // We call the user-provided function when our initialization has ended.
        if ($scope.onInitializationFinished) {
            $scope.onInitializationFinished();
        }
    };

    /**
     * The selectedEntityType changes from outside the directive and we need to reload fields once it's changed.
     * That's why we use watch to watch over value changes and reload the fields.
     */
    $scope.$watchCollection('selectedEntityType', function () {
        if ($scope.itemsSource !== $scope.data.itemsSourceTypes.external) {
            return;
        }

        loadItemsSource();

        loadConditionFields();
    });

    /**
     * Occurs once a field has been selected in a filter.
     * @param selectedField The selected field.
     * @param selectedFieldIdentifier The filter the field was selected in.
     */
    $scope.onFieldOptionSelected = function (selectedField, selectedFieldIdentifier) {
        const filter = selectedFieldIdentifier;

        if (filter.field && filter.field.type !== selectedField.type) {
            filter.resetConditionFlag = false;
            $timeout(function () {
                filter.resetConditionFlag = true;
            });
        }

        filter.field = selectedField;

        $scope.invokeOnFilterChange(filter, false);
    };

    $scope.onGroupNameEdit = function (groupId) {
        if ($scope.viewOnly) {
            return;
        }

        $scope.data.groupNameBeingEditId = groupId;
    };

    $scope.onFilterFocus = function (filter) {
        if ($scope.data.filterInFocusId) {
            $scope.data.filterInFocusId = null;
            return;
        }

        if (!filter) {
            $scope.data.filterInFocusId = null;
            return;
        }

        if (!filter.uiId) {
            filter.uiId = utils.guid();
        }

        $scope.data.filterInFocusId = filter.uiId;
    };

    /**
     * Occurs when a condition is selected for the filter.
     * @param selectedCondition The selected condition
     * @param param The param we passed to the component.
     */
    $scope.onConditionSelection = function (selectedCondition, param, isInit) {
        const filter = param;

        filter.value = null;
        filter.secondValue = null;
        filter.expression = null;
        filter.condition = selectedCondition.apiName;
        filter.conditionDisplayName = selectedCondition.displayName;

        if ($scope.invokeChangeOnConditionSelection) {
            $scope.invokeOnFilterChange(filter, isInit);
        } else {
            setFilterUiProperties(filter);
        }
    };

    /**
     * Occurs when a value of filter has been changed.
     * @param valueObject The value object containing the changed value.
     * @param param The param we passed to the component.
     */
    $scope.onValueChanged = function (valueObject, param, isInit, doNotSaveChanges) {
        const filter = param;

        filter.value = valueObject.value;

        $scope.invokeOnFilterChange(filter, isInit, doNotSaveChanges);
    };

    /**
     * Occurs when a second value of filter has been changed.
     * @param valueObject The value object containing the changed value.
     * @param param The param we passed to the component.
     */
    $scope.onSecondValueChanged = function (valueObject, param, doNotSaveChanges) {
        const filter = param;

        filter.secondValue = valueObject.value;

        $scope.invokeOnFilterChange(filter, false, doNotSaveChanges);
    };

    $scope.onExpressionChanged = function (expression, param, shouldSaveLogic) {
        const filter = param;

        filter.expression = expression;

        $scope.invokeOnFilterChange(filter, false, !shouldSaveLogic);
    };

    $scope.onFilterSearchChange = function (param, doNotSaveChanges) {
        const customFilterSearch = $scope.control.customFiltersDefinition.query.filters.find(
            (filter) => filter.queryAllFields,
        );
        customFilterSearch.value = param;
        $scope.invokeOnFilterChange(customFilterSearch, false, doNotSaveChanges);
    };

    /**
     * Occurs once the field options are loaded.
     * @param fieldOptions Loaded field options.
     */
    $scope.onFieldOptionsLoaded = function (fieldOptions) {
        $scope.data.noFields = !fieldOptions || !fieldOptions.length;
    };

    /**
     * Adds custom fields to integration's available fields.
     */
    $scope.addCustomFields = function (fieldOptions, projectIntegration, externalType) {
        const fieldsToAdd = [];

        if (
            projectIntegration &&
            projectIntegration.integration &&
            projectIntegration.integration.integrationUniqueType.toLowerCase() === 'salesforce' &&
            externalType.toLowerCase() === 'task'
        ) {
            fieldsToAdd.push(
                {
                    name: 'What-Name',
                    label: 'Account Name',
                    type: 'string',
                },
                {
                    name: 'What-Name',
                    label: 'Opportunity Name',
                    type: 'string',
                },
                {
                    name: 'What-Name',
                    label: 'Case Name',
                    type: 'string',
                },
            );
        }

        if ($scope.additionalFields) {
            return [...fieldsToAdd, ...$scope.additionalFields];
        }

        return fieldsToAdd;
    };

    /**
     * Removing the filter from the selected filters.
     * @param filters Filters array.
     * @param index Remove at index.
     */
    $scope.removeFieldFilter = function (filters, index) {
        filters.splice(index, 1);
        $scope.data.filterInFocusId = null;

        if (filters.length === 0) {
            filters.push(
                setFilterUiProperties({
                    condition: 'Equals',
                }),
            );
        }

        $scope.invokeOnFilterChange();
    };

    /**
     * Adds a new empty aggregate field condition.
     * @param filters The filters array.
     */
    $scope.addNewAggregateCondition = function (filters) {
        const uiId = utils.guid();

        filters.push(
            setFilterUiProperties({
                uiId,
                condition: 'Equals',
            }),
        );

        $scope.data.filterInFocusId = uiId;
    };

    /**
     * Adds a new empty inner query.
     */
    $scope.addNewAggregateQuery = function (base) {
        if (!base.queries) {
            base.queries = [];
        }

        base.queries.push({
            uiId: utils.guid(),
            uiOpen: true,
            selectedQueryCondition: $scope.data.queryTypeToDisplayName['And'],
            filters: [
                setFilterUiProperties({
                    condition: 'Equals',
                }),
            ],
        });
    };

    /**
     * Occurs once you toggle the enable time range.
     */
    $scope.onEnabledTimeRangeToggled = function () {
        if ($scope.control.customFiltersDefinition.enableTimeRange) {
            $scope.control.customFiltersDefinition.selectedTimeRangeOption = $scope.data.timeRangesOptions[0];
        }

        $scope.invokeOnFilterChange();
    };

    /**
     * Occurs once you select a time range option.
     */
    $scope.onTimeRangeOptionSelected = function () {
        $scope.invokeOnFilterChange();
    };

    $scope.closeFullscreen = function () {
        if ($scope.isFullscreen) {
            $scope.isFullscreen = false;
            $scope.smallMod = true;
            $scope.appendToBody = true;
            $scope.hideCloseButton = true;
            $scope.showFieldInspect = false;
            $scope.showFieldInspectModal = !!$scope.projectIntegration;

            // bringing it back to it's place
            $scope.ctrlRootElement.append($scope.customTriggersElement);
        }
    };

    /**
     * Clears the defined filters and queries.
     */
    function clearFiltersAndQueries(addEmptyFilter) {
        if (!$scope.control.customFiltersDefinition || !$scope.control.customFiltersDefinition.query) {
            return;
        }

        // Default query condition
        $scope.control.customFiltersDefinition.query.selectedQueryCondition = $scope.data.queryTypeToDisplayName['And'];

        // Empty filters
        if (
            $scope.control.customFiltersDefinition.query.filters &&
            $scope.control.customFiltersDefinition.query.filters.length
        ) {
            $scope.control.customFiltersDefinition.query.filters.splice(
                0,
                $scope.control.customFiltersDefinition.query.filters.length,
            );
        }

        // If requested, adding an empty filter to the custom definition.
        if (addEmptyFilter) {
            if (!$scope.control.customFiltersDefinition.query.filters) {
                $scope.control.customFiltersDefinition.query.filters = [];
            }

            $scope.control.customFiltersDefinition.query.filters.push({
                condition: 'Equals',
            });
        }

        // Empty queries
        if (
            $scope.control.customFiltersDefinition.query.queries &&
            $scope.control.customFiltersDefinition.query.queries.length
        ) {
            $scope.control.customFiltersDefinition.query.queries.splice(
                0,
                $scope.control.customFiltersDefinition.query.queries.length,
            );
        }
    }

    /**
     * Returns true if there are any non empty conditions. False otherwise.
     */
    function anyNonEmptyConditions() {
        if (!$scope.control.customFiltersDefinition) {
            return false;
        }

        // Recursively checking if the query has any non empty conditions in it.
        return anyNonEmptyConditionsInQuery($scope.control.customFiltersDefinition.query);
    }

    /**
     * Reload the fields.
     */
    function loadConditionFields() {
        $scope.data.reloadFields = false;

        $timeout(function () {
            $scope.data.reloadFields = true;
        });
    }

    // region: Create custom filters from definition

    /**
     * Creates the inner model custom filters from API definition.
     * @param givenDefinition If exists, will load the custom filters from this given definition, otherwise, will load from $scope.existingDefinition.
     */
    function createCustomFiltersFromDefinition(givenDefinition) {
        let definition = $scope.existingDefinition || {};

        if (givenDefinition) {
            definition = givenDefinition;
        }

        if (!$scope.control.customFiltersDefinition) {
            $scope.control.customFiltersDefinition = {};
        }

        if (!definition.query) {
            definition.query = {
                filters: [],
                type: 'And',
            };
        }

        switch ($scope.itemsSource) {
            case $scope.data.itemsSourceTypes.external:
                createCustomFiltersFromDefinitionForExternal(definition);
                break;

            case $scope.data.itemsSourceTypes.column:
                createCustomFiltersFromDefinitionForColumn(definition);
                break;

            case $scope.data.itemsSourceTypes.custom:
                if (definition) {
                    createCustomFiltersFromDefinitionForColumn(definition);
                } else {
                    const uiId = utils.guid();

                    $scope.control.customFiltersDefinition = {
                        entity: $scope.selectedEntityType,
                        query: {
                            uiId: utils.guid(),
                            uiOpen: true,
                            selectedQueryCondition: $scope.data.queryTypeToDisplayName['And'],
                            filters: [
                                setFilterUiProperties({
                                    uiId,
                                    condition: 'Equals',
                                }),
                            ],
                        },
                    };

                    $scope.data.filterInFocusId = uiId;
                }

                break;
        }

        $scope.control.customFiltersDefinition.query.uiOpen = true;

        // refreshing angular
        $scope.data.forceRefresh = true;
        $timeout(() => ($scope.data.forceRefresh = false));
    }

    /**
     * Once a filter has been changed, we call the user's given function.
     */
    $scope.invokeOnFilterChange = function (filter, isInit, shouldNotSaveLogic) {
        if (filter && !filter.queryAllFields) {
            setFilterUiProperties(filter);
        }

        // If onlyAfterInit flag is on, take initialization into consideration.
        const onlyAfterInit = !$scope.filtersChangeOnlyAfterInit || !isInit;

        if (onlyAfterInit && $scope.onFiltersChange) {
            $scope.onFiltersChange({ shouldNotSaveLogic });
        }

        if (onlyAfterInit && $scope.onFiltersChangeDefinition) {
            $scope.onFiltersChangeDefinition(createDefinitionFromCustomFilters());
        }
    };

    /**
     * Creates the definition from custom filters for the EXTERNAL items source.
     */
    function createCustomFiltersFromDefinitionForExternal(definition) {
        if (!$scope.selectedEntityType) {
            return;
        }

        const { entityType, pluralLabel } = integrations.getSyncSettingsByEntity(
            $scope.projectIntegration.integration.integrationType,
            $scope.selectedEntityType,
        ) || { entityType: $scope.selectedEntityType, pluralLabel: $scope.selectedEntityType };

        const selectedEntityNames = new Set(
            [entityType, pluralLabel].filter(Boolean).map((name) => name.toUpperCase()),
        );
        const entityInDefinitionNames = [
            definition.entity,
            definition.entityMetadata?.displayName,
            definition.entityMetadata?.entity,
            definition.entityMetadata?.pluralLabel,
        ]
            .filter(Boolean)
            .map((name) => name.toUpperCase());

        // Only if the selected entity matches the definition entity, we want to load the filters from definition.
        const selectedEntityMatchesEntityInDefinition = entityInDefinitionNames.some((entityInDefinitionName) => {
            return selectedEntityNames.has(entityInDefinitionName);
        });

        if (selectedEntityMatchesEntityInDefinition) {
            const definitionIntegrationType = definition.integrationUniqueType || definition.integrationType;
            const specialEntity = apiConsts.getConfigurationByIntegrationAndEntity(
                definitionIntegrationType,
                definition.entity,
            );

            if (specialEntity) {
                $scope.control.customFiltersDefinition.entity = specialEntity.underlyingName;
            } else {
                $scope.control.customFiltersDefinition.entity = definition.entity;
            }

            if (!$scope.selectedEntityType) {
                $scope.selectedEntityType = definition.entity;
            }
            $scope.control.customFiltersDefinition.selectedQueryCondition =
                $scope.data.queryTypeToDisplayName[definition.query.type];

            if (!$scope.control.customFiltersDefinition.query) {
                $scope.control.customFiltersDefinition.query = {};
            }

            initFilters(definition.query, $scope.control.customFiltersDefinition.query, specialEntity);

            if ($scope.control.customFiltersDefinition.query.filters.length === 0) {
                const uiId = utils.guid();
                if ($scope.data.searchAllFields) {
                    $scope.control.customFiltersDefinition.query.filters.push({
                        queryAllFields: true,
                        type: 'String',
                        condition: 'Match',
                        value: '',
                    });
                }

                $scope.control.customFiltersDefinition.query.filters.push(
                    setFilterUiProperties({
                        uiId,
                        condition: 'Equals',
                    }),
                );
                $scope.data.filterInFocusId = uiId;
            }

            if (!definition.timeRangeFilter || definition.timeRangeFilter.range === $scope.data.noRangeApiName) {
                $scope.control.customFiltersDefinition.enableTimeRange = false;
            } else {
                $scope.control.customFiltersDefinition.enableTimeRange = true;
                $scope.control.customFiltersDefinition.selectedTimeRangeOption =
                    $scope.data.timeRangeToDisplayName[definition.timeRangeFilter.range];
            }
        } else {
            const uiId = utils.guid();

            $scope.control.customFiltersDefinition = {
                entity: $scope.selectedEntityType,
                query: {
                    uiId: utils.guid(),
                    uiOpen: true,
                    selectedQueryCondition: $scope.data.queryTypeToDisplayName['And'],
                    filters: [
                        setFilterUiProperties({
                            uiId,
                            condition: 'Equals',
                        }),
                    ],
                },
            };

            $scope.data.filterInFocusId = uiId;
        }
    }

    /**
     * Creates the definition from custom filters for the COLUMN items source.
     */
    function createCustomFiltersFromDefinitionForColumn(definition) {
        $scope.control.customFiltersDefinition.selectedQueryCondition =
            $scope.data.queryTypeToDisplayName[definition.query.type];
        $scope.control.customFiltersDefinition.query = {};
        $scope.control.customFiltersDefinition.enableTimeRange = false;

        initFilters(definition.query, $scope.control.customFiltersDefinition.query);

        if ($scope.control.customFiltersDefinition.query.filters.length === 0) {
            const uiId = utils.guid();
            $scope.control.customFiltersDefinition.query.filters.push(
                setFilterUiProperties({
                    uiId,
                    condition: 'Equals',
                }),
            );
            $scope.data.filterInFocusId = uiId;
        }
    }

    /**
     * Recursive function that build the filters locally for the ui
     */
    function initFilters(definitionQuery, query, specialEntity) {
        query.name = definitionQuery.name;

        if (!query.uiId) {
            query.uiId = utils.guid();
        }

        if (!query.filters) {
            query.filters = [];
        } else {
            query.filters.splice(0, query.filters.length);
        }

        query.selectedQueryCondition = $scope.data.queryTypeToDisplayName[definitionQuery.type];
        for (let j = 0; j < definitionQuery.filters.length; j++) {
            const definitionFilter = definitionQuery.filters[j];
            const customFilter = {};

            if (specialEntity && specialEntity.fields && specialEntity.fields[definitionFilter.fieldName]) {
                const specialField = specialEntity.fields[definitionFilter.fieldName];

                definitionFilter.id = specialField.id;
                definitionFilter.fieldName = specialField.fieldName;
                definitionFilter.fieldLabel = specialField.fieldLabel;
                definitionFilter.type = specialField.fieldType;
                definitionFilter.values = specialField.values;
                definitionFilter.possibleValuesMap = specialField.possibleValuesMap;
                definitionFilter.fromOriginalEntity = specialField.fromOriginalEntity;
            }

            customFilter.field = {
                id: definitionFilter.id || definitionFilter.fieldName,
                name: definitionFilter.fieldName,
                label: definitionFilter.fieldLabel,
                type: definitionFilter.type,
                values: definitionFilter.values,
                possibleValuesMap: definitionFilter.possibleValuesMap,
                fromOriginalEntity: definitionFilter.fromOriginalEntity,
                compareTimeframe: definitionFilter.compareTimeframe,
            };

            // Only add isTriggerTemplateData if we have template data.
            if (definitionFilter.isTriggerTemplateData) {
                customFilter.field.isTriggerTemplateData = definitionFilter.isTriggerTemplateData;
            }

            copyPropertiesFromSourceToDest(definitionFilter, customFilter);

            customFilter.condition = definitionFilter.condition;
            customFilter.value = definitionFilter.value;
            customFilter.expression = definitionFilter.expression;
            customFilter.secondValue = definitionFilter.secondValue;
            customFilter.id = definitionFilter.id;
            setFilterUiProperties(customFilter);

            query.filters.push(customFilter);
        }

        if (definitionQuery.queries && definitionQuery.queries.length) {
            query.queries = [];
            for (let k = 0; k < definitionQuery.queries.length; k++) {
                const innerDefinitionQuery = definitionQuery.queries[k];
                const innerQuery = {};
                initFilters(innerDefinitionQuery, innerQuery);
                query.queries.push(innerQuery);
            }
        }
    }

    // endregion

    // region: Create definition from custom filters

    /**
     * Creates the API definition out of the given custom filters.
     */
    function createDefinitionFromCustomFilters(keepEmptyValuedFilters = $scope.keepEmptyValuedFilters) {
        let definition;
        switch ($scope.itemsSource) {
            case $scope.data.itemsSourceTypes.external:
                // As "entity" prefer using the selectedEntityConstsConfiguration if available.
                definition = {
                    entity: $scope.data.selectedEntityConstsConfiguration
                        ? $scope.data.selectedEntityConstsConfiguration.entityType
                        : $scope.selectedEntityType,
                    integrationType: $scope.projectIntegration.integration.integrationType,
                    integrationUniqueType: $scope.projectIntegration.integration.integrationUniqueType,
                    query: {
                        type: $scope.data.queryTypeToApiName[
                            $scope.control.customFiltersDefinition.query.selectedQueryCondition
                        ],
                    },
                };

                createDefinitionFiltersFromCustomFilters(
                    $scope.control.customFiltersDefinition.query,
                    definition.query,
                    keepEmptyValuedFilters,
                );

                const createTimeFieldName =
                    $scope.createDateSettings[$scope.projectIntegration.integration.integrationType.toLowerCase()];

                if ($scope.control.customFiltersDefinition.enableTimeRange && createTimeFieldName) {
                    definition.timeRangeFilter = {
                        range: $scope.data.timeRangeToApiName[
                            $scope.control.customFiltersDefinition.selectedTimeRangeOption
                        ],
                        timeFieldName: createTimeFieldName,
                    };
                } else {
                    definition.timeRangeFilter = {
                        range: $scope.data.noRangeApiName,
                        timeFieldName: createTimeFieldName || '',
                    };
                }

                return definition;

            case $scope.data.itemsSourceTypes.custom:
            case $scope.data.itemsSourceTypes.column:
                definition = {
                    query: {
                        type: $scope.data.queryTypeToApiName[
                            $scope.control.customFiltersDefinition.query.selectedQueryCondition
                        ],
                    },
                };

                createDefinitionFiltersFromCustomFilters(
                    $scope.control.customFiltersDefinition.query,
                    definition.query,
                    keepEmptyValuedFilters,
                );

                return definition;
        }
    }

    /**
     * Creates the definition filters out of custom filters.
     */
    function createDefinitionFiltersFromCustomFilters(customFiltersDefinition, query, keepEmptyValuedFilters) {
        query.filters = [];
        query.name = customFiltersDefinition.name;
        const containsQueryAllFields = $scope.control.customFiltersDefinition.query.filters.find(
            (filter) => filter.queryAllFields,
        );

        if ($scope.data.searchAllFields && !containsQueryAllFields) {
            customFiltersDefinition.filters.push({
                queryAllFields: true,
                type: 'String',
                condition: 'Match',
                value: '',
            });
        }

        const relevantFilters = customFiltersDefinition.filters.filter((filter) => {
            if (!keepEmptyValuedFilters && !fieldConditionToApiDefinitionMap[filter.condition].noValueRequired) {
                if (
                    (Number.isNaN(filter.value) || utils.isNullOrUndefined(filter.value)) &&
                    (utils.isNullOrUndefined(filter.expression) ||
                        utils.isNullOrUndefined(filter.expression.evaluatedExpression))
                ) {
                    return false;
                }

                // Note this is the correct wait to check real equality to NaN in javascript.
                if (filter.value && typeof filter.value === 'number' && Number.isNaN(filter.value)) {
                    return false;
                }
            }

            return true;
        });

        for (const currentFilter of relevantFilters) {
            if (currentFilter.field && !currentFilter.queryAllFields) {
                let convertedFieldName;

                // This is a surprisingly ugly hack. Since special fields do not apply to the convention of having the id and name properties of their definition
                // the same, and use the name property as a display name (for example, id: TNK_STAGE, but name: Status), we cannot take the name first
                // and then fallback to the id, as it will break the conditions in the case of special fields.
                // Therefore, we do a check - if it's a special field (by checking the id, we only take id),
                // otherwise, we take the name first and then fallback to the id.
                if (currentFilter.field?.id && FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[currentFilter.field?.id]) {
                    convertedFieldName = currentFilter.field?.id;
                } else {
                    convertedFieldName = currentFilter.field.name ? currentFilter.field.name : currentFilter.field.id;
                }

                const convertedFilter = {
                    fieldName: convertedFieldName,
                    fieldLabel: currentFilter.field.label,
                    type: currentFilter.field.fieldType ?? currentFilter.field.type,
                    values: currentFilter.field.values,
                    possibleValuesMap: currentFilter.field.possibleValuesMap,
                    fromOriginalEntity: currentFilter.field.fromOriginalEntity,
                    condition: currentFilter.condition,
                    expression: currentFilter.expression,
                    value: currentFilter.value instanceof Date ? currentFilter.value.getTime() : currentFilter.value,
                    secondValue:
                        currentFilter.secondValue instanceof Date
                            ? currentFilter.secondValue.getTime()
                            : currentFilter.secondValue,
                    compareTimeframe: currentFilter.field.compareTimeframe,
                    id: currentFilter.id || currentFilter.uiId || utils.guid(),

                    key: currentFilter.field.key,
                    projectIntegrationId: currentFilter.field.projectIntegrationId,
                    externalType: currentFilter.field.externalType,
                    source: currentFilter.field.source,
                };

                copyPropertiesFromSourceToDest(currentFilter, convertedFilter, [
                    'fieldName',
                    'fieldLabel',
                    'type',
                    'values',
                    'condition',
                    'value',
                    'expression',
                ]);

                if (
                    keepEmptyValuedFilters ||
                    (convertedFilter.value !== '' && convertedFilter.fieldName && convertedFilter.fieldLabel)
                ) {
                    query.filters.push(convertedFilter);
                }
            } else if ($scope.data.searchAllFields && currentFilter.queryAllFields) {
                currentFilter.condition = 'Match';
                query.filters.push(currentFilter);
            }
        }

        if (customFiltersDefinition.queries) {
            query.queries = [];
            for (let i = 0; i < customFiltersDefinition.queries.length; i++) {
                const customQuery = customFiltersDefinition.queries[i];
                const innerQuery = { type: $scope.data.queryTypeToApiName[customQuery.selectedQueryCondition] };

                createDefinitionFiltersFromCustomFilters(customQuery, innerQuery, keepEmptyValuedFilters);

                if (keepEmptyValuedFilters || innerQuery.filters.length) {
                    query.queries.push(innerQuery);
                }
            }
        }
    }

    // endregion

    /**
     * If $scope.copyPropertiesFromDefinition is true, will copy properties from sourceFilter filter to the destFilter.
     * Otherwise, will not copy anything extra.
     */
    function copyPropertiesFromSourceToDest(sourceFilter, destFilter, excludedProperties) {
        // Only copying properties if user requested it.
        if (!$scope.copyPropertiesFromDefinition) {
            return;
        }

        const excludedPropertySet = {};

        if (excludedProperties) {
            for (const excludedProperty of excludedProperties) {
                excludedPropertySet[excludedProperty] = true;
            }
        }

        // Copies all properties from current filter to the custom filter definition.
        for (const property in sourceFilter) {
            if (sourceFilter.hasOwnProperty(property) && !excludedPropertySet[property]) {
                destFilter[property] = sourceFilter[property];
            }
        }
    }

    /**
     * If needed, initializes the entity from the fallback entity type.
     */
    function evaluateFallbackEntityType() {
        // If we have the integration in the ApiConsts configuration, BUT
        // the integration does not have the $scope.selectedEntityType in the configuration map, AND
        // the entity is a special entity ($scope.isSpecialEntity = true), AND
        // $scope.fallbackEntityType is defined, AND
        // the configuration map has it,
        // we will use the fallback entity as the selected entity.
        if (
            apiConsts.getConfigurationByIntegration($scope.projectIntegration.integration.integrationUniqueType) &&
            !apiConsts.getConfigurationByIntegrationAndEntity(
                $scope.projectIntegration.integration.integrationUniqueType,
                $scope.selectedEntityType,
            ) &&
            $scope.isSpecialEntity &&
            $scope.fallbackEntityType &&
            apiConsts.getConfigurationByIntegrationAndEntity(
                $scope.projectIntegration.integration.integrationUniqueType,
                $scope.fallbackEntityType,
            )
        ) {
            $scope.selectedEntityType = $scope.fallbackEntityType;
        }
    }

    function loadItemsSource() {
        // Initializing the correct items source
        switch ($scope.itemsSource) {
            case $scope.data.itemsSourceTypes.external:
                $scope.data.selectedEntityConstsConfiguration = integrations.getSyncSettingsByEntity(
                    $scope.projectIntegration.integration.integrationType,
                    $scope.selectedEntityType,
                );
                evaluateFallbackEntityType();
                createCustomFiltersFromDefinition();
                break;

            case $scope.data.itemsSourceTypes.column:
                if ($scope.existingDefinition) {
                    createCustomFiltersFromDefinition();
                }
                break;

            case $scope.data.itemsSourceTypes.custom:
                if ($scope.existingDefinition) {
                    createCustomFiltersFromDefinition();
                }

                break;
        }
    }

    /**
     * Initializes all needed consts for the filter query definition.
     */
    function initializeConsts() {
        // The logical operator between all filters, for aggregate count field
        $scope.data.queryTypes = ['All', 'At least one'];
        $scope.data.queryTypeToApiName = {
            All: 'And',
            'At least one': 'Or',
        };
        $scope.data.queryTypeToDisplayName = {
            And: 'All',
            Or: 'At least one',
        };

        /**
         * The time range for the field
         */
        $scope.data.noRangeApiName = 'NoRange';
        $scope.data.timeRangesOptions = [
            'Last day',
            'Last 7 days',
            'Last 30 days',
            'Last 2 months',
            'Last 6 months',
            'Last year',
        ];
        $scope.data.timeRangeToApiName = {
            'Last day': 'LastDay',
            'Last 7 days': 'Last7Days',
            'Last 30 days': 'Last30Days',
            'Last 2 months': 'Last2Months',
            'Last 6 months': 'Last6Months',
            'Last year': 'LastYear',
        };
        $scope.data.timeRangeToDisplayName = {
            LastDay: 'Last day',
            Last7Days: 'Last 7 days',
            Last30Days: 'Last 30 days',
            Last2Months: 'Last 2 months',
            Last6Months: 'Last 6 months',
            LastYear: 'Last year',
        };
        $scope.data.projectIntegrationDescriptionClass =
            $scope.projectIntegration?.integration?.integrationType?.toLowerCase();
    }

    function setFilterUiProperties(filter) {
        if (filter.condition === 'Match' || filter.condition === 'NotMatch') {
            const updatedFilterValue = (filter.value || '').replaceAll(MATCH_CONDITION_TAGS_SEPARATOR, ' ');
            const matchType = filter.secondValue ? `(${utils.capitalize(filter.secondValue.toLowerCase())})` : '';

            filter.uiDescription = `${filter.field.label} ${filter.conditionDisplayName} ${matchType} ${updatedFilterValue}`;
        } else {
            const uiDescriptionValue = filter.expression
                ? filter.expression.originalExpression || ''
                : `${filter.value || ''} ${filter.secondValue || ''}`;

            filter.uiDescription = filter.field?.label
                ? `${filter.field.label} ${filter.conditionDisplayName} ${uiDescriptionValue}`
                : 'Set New Condition';
        }

        filter.sourceClass = filter.field?.source?.toLowerCase();

        return filter;
    }

    function updateFieldDefinitionMap() {
        if (!$scope.workflowVersionId) {
            return;
        }
        $scope.data.loadingFields = true;
        return customFieldsManager
            .getFieldDefinitions($scope.workflowVersionId)
            .then((fields) => {
                $scope.data.fieldDefinitionMap = utils.createMapFromArray(fields, 'id');
            })
            .finally(() => ($scope.data.loadingFields = false));
    }

    $scope.$watch(
        () => $scope.workflowVersionId && customFieldsManager.selectedFieldsMap[$scope.workflowVersionId],
        updateFieldDefinitionMap,
    );

    $scope.init();
}

angular.module('tonkean.app').controller('CustomFiltersCtrl', CustomFiltersCtrl);
