import { reactToAngular } from '@tonkean/angular-components';
import { EnvironmentIndicator } from '@tonkean/infrastructure';

export default angular
    .module('tonkean.app')
    .component(
        'tnkEnvironmentIndicator',
        reactToAngular(EnvironmentIndicator, [
            'environmentIsActive',
            'currentEnvironment',
            'onChanges',
            'showActiveStatusIndicator',
            'offModuleIndication',
        ]),
    );
