function EditWorkerFormModalCtrl(
    $rootScope,
    $scope,
    $uibModalInstance,
    utils,
    tonkeanService,
    groupId,
    formId,
    formTypeToCreate,
    callBackFormCreated,
    workflowVersionId,
    viewMode,
) {
    $scope.data = {
        formId,
        groupId,
        workflowVersionId,
        formTypeToCreate,
        callBackFormCreated,
        viewMode,
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Occurs once the form created.
     */
    $scope.onFormCreated = function (form) {
        // Letting the user know.
        if ($scope.data.callBackFormCreated) {
            $scope.data.callBackFormCreated({ form });
        }

        // Closing the modal.
        $uibModalInstance.close();
    };
}

export default angular.module('tonkean.app').controller('EditWorkerFormModalCtrl', EditWorkerFormModalCtrl);
