import React from 'react';

import { useIsEnvironmentActive } from '../../../hooks';
import useFieldsValuesUpdater from '../../../hooks/useFieldsValuesUpdater';
import type { ItemWidgetProps } from '../../../WidgetModule';
import InnerItemsAgGridWidgetDisplay from '../../InnerItemsWidgetModule/components/InnerItemsAgGridWidgetDisplay';
import InnerItemsItemWidgetDisplay from '../../InnerItemsWidgetModule/components/InnerItemsItemWidgetDisplay';
import type ModuleItemsWidgetConfiguration from '../entities/ModuleItemsWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';

const ModuleItemsWidget: React.FC<ItemWidgetProps<ModuleItemsWidgetConfiguration>> = ({
    initiative,
    widget,
    permission,
}) => {
    const { project, itemInterface, workflowVersion } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);
    const groupId = workflowVersion?.groupId;
    const isEnvironmentActive = useIsEnvironmentActive(groupId, initiative);
    const updateFieldsValuesManager = useFieldsValuesUpdater();

    const showItemsTableWithLineItems = !!useFeatureFlag('tonkean_feature_workspace_app_inner_items_ag_grid');

    return showItemsTableWithLineItems ? (
        <InnerItemsAgGridWidgetDisplay
            workflowVersionType={workflowVersion?.workflowVersionType}
            workflowVersionId={workflowVersion?.id}
            groupId={groupId}
            widget={widget}
            permission={permission}
            environmentIsActive={isEnvironmentActive}
            headerBackgroundColor={themeConfiguration.headerBackgroundColor}
            showModuleOffIndication
        />
    ) : (
        <InnerItemsItemWidgetDisplay
            workflowVersionType={workflowVersion?.workflowVersionType}
            parentInitiative={undefined}
            workflowVersionId={workflowVersion?.id}
            groupId={groupId}
            widget={widget}
            permission={permission}
            environmentIsActive={isEnvironmentActive}
            headerBackgroundColor={themeConfiguration.headerBackgroundColor}
            updateFieldsValuesManager={updateFieldsValuesManager}
            showModuleOffIndication
        />
    );
};

export default ModuleItemsWidget;
