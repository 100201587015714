import GoogleSheetUsageMethod from './GoogleSheetUsageMethod';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkGoogleSheetUsageMethod',
        reactToAngular(GoogleSheetUsageMethod, [
            'onSelect',
            'selectedUsageMethod',
            'isEdit',
            'isCrudDisabled',
            'isAppendDisabled',
        ]),
    );
