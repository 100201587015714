import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyImage } from '../../../../../../../images/icons/big-upload-image.svg';

import { DragAndDropFiles, Paragraph, useFormikField } from '@tonkean/infrastructure';
import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ErrorText = styled(Paragraph)`
    margin-top: 5px;
`;

interface Props {
    maximumFiles?: number;
    formikFieldName?: string;
    touchedErrorWorkAround?: boolean; // Whether touch indication doesnt work with Formik, we use work around.
    dataAutomation?: string;
}

const MarketPlaceItemCreateModalUploadImagesSection: React.FC<Props> = ({
    maximumFiles,
    formikFieldName,
    touchedErrorWorkAround,
    dataAutomation,
}) => {
    const {
        value: existingFiles,
        error,
        touched,
        setValue: setExistingFiles,
        setError,
    } = useFormikField<TonkeanUploadedFile[] | undefined>(formikFieldName || 'images');

    useEffect(() => {
        touchedErrorWorkAround && setError(undefined);
    }, [setError, touchedErrorWorkAround]);

    return (
        <>
            <DragAndDropFiles
                acceptedFileTypes={['image/jpeg', 'image/png']}
                mainText="Drag image here to upload"
                dragAndDropLogo={<EmptyImage />}
                existingFiles={existingFiles || []}
                setExistingFiles={setExistingFiles}
                setErrorText={setError}
                maxFileSizeMB={5}
                limitationsDescriptionText="The maximum file size is 5mb and the supported file types are JPG, JPEG and PNG"
                maximumFiles={maximumFiles}
                dataAutomation={`${dataAutomation}-marketplace-item-upload-image`}
                isImageUpload
            />

            {error && (touched || touchedErrorWorkAround) && <ErrorText $color={Theme.colors.error}>{error}</ErrorText>}
        </>
    );
};

export default MarketPlaceItemCreateModalUploadImagesSection;
