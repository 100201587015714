import DOMPurify from 'dompurify';
import React, { useLayoutEffect, useState } from 'react';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

interface Props {
    unsafeHtml: string;
    onPurifyComplete?(safeHtml: string, removed: any[]): void;
}

const SafeHtml: React.FC<Props> = ({ unsafeHtml, onPurifyComplete: onPurifyCompleteProp }) => {
    const [safeHtml, setSafeHtml] = useState('');

    const onPurifyComplete = useConstantRefCallback(onPurifyCompleteProp);
    useLayoutEffect(() => {
        const purified = DOMPurify.sanitize(unsafeHtml, {
            RETURN_DOM_FRAGMENT: false,
            RETURN_DOM: false,
            ADD_ATTR: ['target'],
        });
        setSafeHtml(purified);
        onPurifyComplete(purified, DOMPurify.removed);
    }, [onPurifyComplete, unsafeHtml]);

    return <div dangerouslySetInnerHTML={{ __html: safeHtml }} />;
};

export default SafeHtml;
