import workerRunReasonConfig from '../entities/workerRunReasonConfig';

import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import { text } from '@tonkean/utils';

/**
 * Get the description of the worker run to show near the icon in the worker runs table in the history modal.
 *
 * @param workerRun - the worker run.
 * @param projectIntegration - the project integration (if the worker run stage is data source).
 */
function getWorkerRunFullDescription(workerRun: WorkerRun | undefined, projectIntegration?: any): string {
    if (!workerRun) {
        return '';
    }

    switch (workerRun.workerRunStage) {
        case WorkerRunStage.FLOW_RUN: {
            const customTriggerNames = workerRun.customTriggerNames?.join(', ');
            const customTriggerNamesFromReasonData = workerRun.workerRunReasonData?.customTriggers
                ?.map((customTrigger) => customTrigger.customTriggerName)
                .join(', ');

            const subType = workerRunReasonConfig[workerRun.workerRunReason].subType;

            return `${customTriggerNames || customTriggerNamesFromReasonData || subType}`;
        }

        case WorkerRunStage.DATA_SOURCE: {
            const externalType =
                workerRun.externalType ||
                workerRun.externalActivity?.externalType ||
                workerRun.externalActivityExternalType;
            const prefix = `${
                workerRun.projectIntegrationDisplayName || projectIntegration?.displayName || 'Unknown'
            } ${externalType} - `;
            const actionType = workerRunReasonConfig[workerRun.workerRunReason]?.eventType;

            return text`${prefix}${actionType}`;
        }

        case WorkerRunStage.MODULE_ITEM: {
            return workerRunReasonConfig[workerRun.workerRunReason]?.eventType || 'Update / Create';
        }
    }
}

export default getWorkerRunFullDescription;
