import { DaysHoursDiffFilter } from '@tonkean/shared-services';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';

/**
 * Gets the subtitle of the project integration summary item.
 */
function getIntegrationPageBrowsedItemSubtitle(projectIntegrationSummary: ProjectIntegrationSummary): string {
    if (projectIntegrationSummary.lastCollect) {
        return `Updated ${DaysHoursDiffFilter(projectIntegrationSummary.lastCollect)}`;
    } else {
        return ``;
    }
}

export default getIntegrationPageBrowsedItemSubtitle;
