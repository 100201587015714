import changeAvatarModalTemplate from './changeAvatarModal/changeAvatarModal.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';
/**
 * Created by sagieliyahu on 07/10/15.
 */

function ManageProfileCtrl(
    $scope,
    $rootScope,
    modalUtils,
    tonkeanService,
    authenticationService,
    communicationIntegrationsService,
    currentUserHelper,
) {
    $scope.loading = false;
    $scope.error = null;

    $scope.cis = communicationIntegrationsService;

    $scope.data = {
        currentUser: authenticationService.currentUser,
        name: authenticationService.currentUser.name,
        email: authenticationService.currentUser.email,
        title: authenticationService.currentUser.title,
        phone: authenticationService.currentUser.metadata
            ? authenticationService.currentUser.metadata.phoneNumber
            : null,
        preferEmail: authenticationService.currentUser.preferEmail,
        preferImportantNotificationsAsUser: authenticationService.currentUser.preferImportantNotificationsAsUser,

        selectedPreferences: null,
    };

    $scope.init = function () {};

    /**
     * Occurs once preferences are selected.
     */
    $scope.onSelectedPreferencesChanged = function (selectedPreferences) {
        $scope.data.selectedPreferences = selectedPreferences;
    };

    $scope.saveChanges = function () {
        if (!$scope.data.name) {
            return;
        }
        const timezone = Math.floor(new Date().getTimezoneOffset() / 60) * -1;
        if ($scope.data.selectedPreferences && $scope.data.selectedPreferences.includes('NO_ALL')) {
            analyticsWrapper.track('Unsubscribe', { category: 'Account Page' });
        }

        if ($scope.data.phone && $scope.data.phone.length) {
            if (!authenticationService.currentUser.metadata) {
                authenticationService.currentUser.metadata = {};
            }
            authenticationService.currentUser.metadata.phoneNumber = $scope.data.phone;
        }

        // Saving the previous message preferences so we can rollback afterwards.
        const previousMessagePreferences = angular.copy(authenticationService.currentUser.messagePreferences);

        if ($scope.data.selectedPreferences) {
            // Updating current user with the preferences.
            currentUserHelper.updateCurrentUserMessagePreferences($scope.data.selectedPreferences);
        }

        tonkeanService
            .updateProfileInfo(
                $scope.data.name,
                $scope.data.title,
                authenticationService.currentUser.messagePreferences,
                timezone,
                authenticationService.currentUser.metadata,
                $scope.data.preferEmail,
                $scope.data.preferImportantNotificationsAsUser,
            )
            .then(function (user) {
                angular.extend(authenticationService.currentUser, user);

                $rootScope.$broadcast('currentUserChanged');
                $scope.$emit('alert', { msg: 'Profile changes saved.', type: 'success' });
            })
            .catch(function () {
                if (previousMessagePreferences) {
                    // Doing rollback for the message preferences of the user.
                    currentUserHelper.updateCurrentUserMessagePreferences(previousMessagePreferences);
                }

                $scope.$emit('alert', 'Could not save changes.');
            });
    };

    $scope.changeAvatar = function () {
        modalUtils.open({
            scope: $scope,
            backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
            template: changeAvatarModalTemplate,
            controller: 'ChangeAvatarModalCtrl',
        });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageProfileCtrl', ManageProfileCtrl);
