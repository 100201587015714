import React, { useMemo } from 'react';

import useProjectIntegrationActionWarningModal from '../../../components/state.product.projectIntegrationActionManager/useProjectIntegrationActionWarningModal';

import type { BreadCrumbsOption, BreadcrumbsSettings } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';

const useProjectIntegrationActionBreadCrumbsSettings = (
    projectIntegration: ProjectIntegration,
    currentProjectIntegrationAction: ProjectIntegrationAction,
    otherPossibleActions: BreadCrumbsOption[],
    $state,
    shouldOpenWarningBeforeNavigate,
) => {
    const openWarning = useProjectIntegrationActionWarningModal();

    return useMemo(() => {
        const projectIntegrationName: BreadcrumbsSettings = {
            displayName: projectIntegration.displayName,
            id: projectIntegration.id,
            prefixIcon: (
                <ProjectIntegrationIcon
                    width={14}
                    projectIntegrationId={projectIntegration.id}
                    integrationType={projectIntegration.integration.integrationType}
                />
            ),
        };

        const actionsTab: BreadcrumbsSettings = {
            displayName: 'Custom Actions',
            id: 'actions',
            clickable: {
                onClick: async () => {
                    let confirmed = true;
                    if (shouldOpenWarningBeforeNavigate) {
                        confirmed = await openWarning('Remove unsaved changes');
                    }

                    if (confirmed) {
                        $state.go('product.projectIntegrationPage', {
                            enterpriseComponentId: projectIntegration.id,
                            page: ProjectIntegrationPageMenuItemType.ACTIONS,
                        });
                    }
                },
            },
        };

        const currentProjectIntegrationActionSettings: BreadcrumbsSettings = {
            displayName: currentProjectIntegrationAction.displayName,
            id: currentProjectIntegrationAction.id,
            otherOptionsSettings: {
                otherOptions: otherPossibleActions,
                onOtherOptionClicked: async (id) => {
                    let confirmed = true;
                    if (shouldOpenWarningBeforeNavigate) {
                        confirmed = await openWarning('Remove unsaved changes');
                    }

                    if (confirmed) {
                        $state.go(
                            'product.projectIntegrationActionManager',
                            { projectIntegrationActionId: id },
                            { inherit: true },
                        );
                    }
                },
            },
        };

        return [projectIntegrationName, actionsTab, currentProjectIntegrationActionSettings];
    }, [
        projectIntegration.displayName,
        projectIntegration.id,
        projectIntegration.integration.integrationType,
        currentProjectIntegrationAction.displayName,
        currentProjectIntegrationAction.id,
        otherPossibleActions,
        shouldOpenWarningBeforeNavigate,
        openWarning,
        $state,
    ]);
};

export default useProjectIntegrationActionBreadCrumbsSettings;
