import React from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react/dist/components/editable';

import ExpressionEditorElementWrapper from './ExpressionEditorElementWrapper';
import ExpressionEditorVariableTag from './ExpressionEditorVariableTag';
import type VariableElement from '../../entities/VariableElement';
import createVariableElement from '../../utils/createVariableElement';

interface Props extends RenderElementProps {
    element: VariableElement;
}

const ExpressionEditorVariableElement: React.FC<React.PropsWithChildren<Props>> = ({
    attributes,
    children,
    element,
}) => {
    const editor = useSlateStatic();
    const onChange = (newVariableContent: string) => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.setNodes(editor, createVariableElement(newVariableContent), { at: path });
    };

    return (
        <ExpressionEditorElementWrapper editorChildren={children} attributes={attributes}>
            <ExpressionEditorVariableTag
                variableContent={element.variableContent}
                elementToDelete={element}
                onChange={onChange}
            />
        </ExpressionEditorElementWrapper>
    );
};

export default ExpressionEditorVariableElement;
