import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

/**
 * @deprecated
 * Should use ConvertToString
 */
export default class ToStringFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.TO_STRING;
    override readonly sign = 'ToString';
    override readonly displayName = 'To String';
    override readonly description = 'Will get the string value of any input.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: undefined,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];

    override readonly deprecated = true;
}
