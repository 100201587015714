import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useState } from 'react';

import WorkflowVersionHistory from './WorkflowVersionHistory';
import WorkflowVersionsList from './WorkflowVersionsList';

import type { WorkflowVersion } from '@tonkean/tonkean-entities';

interface Props {
    groupId: string;
    draftVersionChanges: number;
    productionWorkflowVersionId: string;
    onTestWorkflowClicked: (error, invalid: string) => void;
    onRevertWorkflowVersionClicked: () => void;
    groupByToggleDefault?: boolean;
}

const WorkflowVersionsHistoryTab: React.FC<Props> = ({
    groupId,
    draftVersionChanges,
    productionWorkflowVersionId,
    onTestWorkflowClicked,
    onRevertWorkflowVersionClicked,
    groupByToggleDefault,
}) => {
    const [workflowVersionsCache, setWorkflowVersionsCache] = useState<WorkflowVersion[]>([]);

    const $timeout = useAngularService('$timeout');
    const $state = useAngularService('$state');

    const [historyVersion] = useAngularWatch(() => {
        return $state.params.historyVersion as string | undefined;
    });

    const updateSelectedWorkflowVersion = (
        workflowVersionId: string | undefined,
        changeOnlyHistoryVersion: boolean,
    ) => {
        $timeout(() => {
            if (changeOnlyHistoryVersion) {
                $state.go('.', { historyVersion: workflowVersionId }, { location: 'replace' });
            } else {
                $state.go(
                    'product.workerEditor',
                    { env: workflowVersionId, historyVersion: workflowVersionId },
                    { location: 'replace' },
                );
            }
        });
    };

    return (
        <div className="workflow-versions-history-tab">
            {historyVersion ? (
                <WorkflowVersionHistory
                    onUnselectWorkflowVersion={() => updateSelectedWorkflowVersion(undefined, false)}
                    groupId={groupId}
                    workflowVersionId={historyVersion}
                    workflowVersionsCache={workflowVersionsCache}
                    groupByToggleDefault={groupByToggleDefault}
                />
            ) : (
                <WorkflowVersionsList
                    groupId={groupId}
                    onWorkflowVersionSelected={updateSelectedWorkflowVersion}
                    draftVersionChanges={draftVersionChanges}
                    productionWorkflowVersionId={productionWorkflowVersionId}
                    setWorkflowVersionsCache={setWorkflowVersionsCache}
                    onTestWorkflowClicked={onTestWorkflowClicked}
                    onRevertWorkflowVersionClicked={onRevertWorkflowVersionClicked}
                />
            )}
        </div>
    );
};

export default WorkflowVersionsHistoryTab;
