import template from './tnkSelectIntegrationInstanceReact.template.html?angularjs';

/**
 * A wrapping for tnkSelectIntegrationInstance .
 */
export const tnkSelectIntegrationInstanceReact = angular
    .module('tonkean.app')
    .component('tnkSelectIntegrationInstanceReact', {
        bindings: {
            integrationType: '<',
            selectedProjectIntegration: '<',
            marketplaceTemplateName: '<',
            onIntegrationSelected: '<',
        },
        template,
        controller: SelectIntegrationInstanceReactCtrl,
    });

function SelectIntegrationInstanceReactCtrl($scope, projectManager) {
    const ctrl = this;

    $scope.data = {
        projectIntegrationOptions: [],
        integrationType: undefined,
        marketplaceTemplateName: undefined,
        selectedProjectIntegration: undefined,
        onIntegrationSelected: undefined,
        integrationState: {},
    };
    const getRelevantIntegrations = () => {
        if ($scope.data.marketplaceTemplateName) {
            const marketplaceItems = projectManager.project.integrations.filter(
                (integ) => integ.publicMarketplaceTemplateName === $scope.data.marketplaceTemplateName,
            );
            if (!!marketplaceItems.length) {
                return marketplaceItems;
            }
        }

        return projectManager.project.integrations.filter(
            (integ) => integ.integrationType === $scope.data.integrationType && !integ.representativeGroupId,
        );
    };

    ctrl.$onChanges = function (changes) {
        if (changes.integrationType) {
            $scope.data.integrationType = ctrl.integrationType;
            $scope.data.projectIntegrationOptions = getRelevantIntegrations();
        }

        if (changes.marketplaceTemplateName) {
            $scope.data.marketplaceTemplateName = ctrl.marketplaceTemplateName;
            $scope.data.projectIntegrationOptions = getRelevantIntegrations();
        }
        if (changes.selectedProjectIntegration) {
            $scope.data.selectedProjectIntegration = ctrl.selectedProjectIntegration;
        }
        if (changes.onIntegrationSelected) {
            $scope.data.onIntegrationSelected = ctrl.onIntegrationSelected;
        }
    };

    $scope.onIntegrationSelected = (selectedIntegration) => {
        $scope.data.selectedProjectIntegration = selectedIntegration;
        $scope.data.onIntegrationSelected?.(selectedIntegration);
    };
}

export const SelectIntegrationInstanceReactCtrlController = angular
    .module('tonkean.app')
    .controller('SelectIntegrationInstanceReactCtrl', SelectIntegrationInstanceReactCtrl);
