<div>
    <form name="addFunctionForm">
        <div style="min-width: 220px" class="">
            <div ng-if="pm && pm.functions && pm.functions.length" class="margin-bottom-xs">
                <div class="flex mod-align-start">
                    <div
                        class="
                            common-color-black common-bold common-size-xxs common-width-25
                            flex-no-shrink
                            margin-top-xxs
                            padding-top-xs
                        "
                    >
                        Function:
                    </div>
                    <tnk-tags-input
                        tags="popover.functionToAdd"
                        class="flex-grow common-width-75 people-selector people-selector-border"
                        latest-tag-search="popover.latestFunc"
                        selected-item-template="function-selected-item"
                        is-func="true"
                        load-on-empty="true"
                        max-tags="1"
                        on-tag-removed="onTagRemoved(tag)"
                        no-custom="false"
                        auto-complete-template="function-autocomplete-item"
                        placeholder="Type team/function name..."
                    ></tnk-tags-input>
                </div>
                <div
                    class="text-right margin-bottom common-size-xxxxxs common-color-grey"
                    ng-hide="popover.selectedPeople.length"
                    ng-if="popover.functionToAdd.length && popover.functionToAdd[0].defaultOwner"
                >
                    <i class="margin-left-sm">Function's default: {{ popover.functionToAdd[0].defaultOwner.name }}</i>
                </div>
            </div>
            <div class="flex mod-align-start">
                <div
                    class="
                        common-color-black common-bold common-size-xxs common-width-25
                        flex-no-shrink
                        margin-top-xxs
                        padding-top-xs
                    "
                >
                    Owner:
                </div>
                <tnk-deprecated-people-selector
                    class="flex-grow common-width-75 people-selector-border"
                    no-validation="false"
                    selected-people="popover.selectedPeople"
                    no-custom="pm.project.metadata && pm.project.metadata.blockOwnersNotInTeam || disableInvite"
                    max-tags="1"
                    group="group"
                    show-intro="lps.isPricingVersion(lps.pricingVersions.V1)"
                    max-tag-chars="22"
                    include-external="true"
                ></tnk-deprecated-people-selector>
            </div>
            <div
                class="flex mod-justify-end margin-top-xs"
                ng-if="popover.selectedPeople.length && popover.selectedPeople[0].id !== as.currentUser.id &&
                    (!initiative.owner || (popover.selectedPeople[0].id !== initiative.owner.id)) &&
                    !disableInvite &&
                    (pm.project.isEnterprise || (lps.isV2AndUserLicensed(popover.selectedPeople[0])) || (lps.isPricingVersion(lps.pricingVersions.V1) && !currentUserService.isPersonUninvited(popover.selectedPeople[0])))"
            >
                <div class="common-width-75">
                    <textarea
                        ng-model="data.customMessage"
                        class="
                            form-control
                            common-width-100
                            owner-custom-message-input
                            common-resize-none common-size-xxs
                        "
                        placeholder="{{
                            'Write a note. It will be sent to ' +
                                popover.selectedPeople[0].name +
                                ' with a notification about this item assignment.'
                        }}"
                        autofocus="true"
                    ></textarea>
                </div>
            </div>
        </div>

        <!-- Assign seat section-->
        <div
            ng-if="popover.selectedPeople.length && lps.isV2AndUserNotLicensed(popover.selectedPeople[0])"
            ng-hide="popover.posting"
            class="common-bg mod-light-blue rounded-box padding-normal-sm margin-top"
        >
            <div class="flex common-size-xxxxs">
                <span class="svg-icon margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/email-send.svg"></tnk-icon>
                </span>
                <div>
                    <!-- Free plan -->
                    <div ng-if="pm.project.isLimitedLicense">
                        <span class="capital-first-letter">
                            {{
                                popover.selectedPeople[0].firstName ||
                                    popover.selectedPeople[0].email.split('@')[0] ||
                                    'They'
                            }}
                            will be invited to Tonkean.
                            <a ng-click="openInviteModal()">Add a note</a>
                        </span>
                    </div>
                    <!-- Paid -->
                    <div ng-if="!pm.project.isLimitedLicense">
                        <span class="capital-first-letter" ng-if="!pm.isBuyer && !pm.isOwner">
                            {{
                                popover.selectedPeople[0].firstName ||
                                    popover.selectedPeople[0].email.split('@')[0] ||
                                    'They'
                            }}
                            will be invited to Tonkean and we'll ask
                            {{ pm.project.license.buyer.name || pm.project.creator.name }} to add them as a member.
                            <a ng-click="openInviteModal()">Add a note</a>
                        </span>
                        <span ng-if="pm.isBuyer || pm.isOwner">
                            {{
                                popover.selectedPeople[0].firstName ||
                                    popover.selectedPeople[0].email.split('@')[0] ||
                                    'They'
                            }}
                            will be invited to Tonkean and added as a member.
                            <a ng-click="openInviteModal()">Add a note</a>
                            &nbsp;
                            <span ng-if="!pm.isEnterprise">
                                |&nbsp;
                                <a ng-click="popover.showNewUserDetails = !popover.showNewUserDetails">More details</a>
                            </span>
                        </span>
                    </div>
                    <!-- Details (for buyer in payed) -->
                    <div ng-if="popover.showNewUserDetails" class="common-color-dark-grey margin-top-xs">
                        If there is an open seat it will be taken, otherwise
                        <span ng-if="pm.isBuyer || pm.isOwner">
                            a new seat will be added according to your
                            <a ui-sref="product.settings.license({tab: 'members'})" target="_blank">subscription</a>
                            .
                        </span>
                        <span ng-if="!pm.isBuyer && !pm.isOwner">
                            they'll get temporary access and we'll ask
                            {{ pm.project.license.buyer.name || pm.project.creator.name }} to approve them.
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add a note bubble for pricing V2 -->
        <div
            ng-if="
                lps.isV2TrialNotLicensed() &&
                popover.selectedPeople.length &&
                currentUserService.isPersonUninvited(popover.selectedPeople[0])
            "
            class="common-bg mod-light-blue rounded-box padding-normal-sm margin-top"
        >
            <div class="flex common-size-xxxxs">
                <span class="svg-icon margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/email-send.svg"></tnk-icon>
                </span>
                <div>
                    <div class="capital-first-letter">
                        {{
                            popover.selectedPeople[0].firstName ||
                                popover.selectedPeople[0].email.split('@')[0] ||
                                'They'
                        }}
                        will be invited to Tonkean and added as a member.
                        <a ng-click="openInviteModal()">Add a note</a>
                    </div>
                </div>
            </div>
        </div>

        <hr class="common-separator margin-top margin-bottom" />

        <div class="flex-vmiddle margin-top" style="min-width: 290px">
            <div class="margin-right flex-no-shrink" ng-if="initiative.status !== 'DONE'">
                <div ng-if="popover.selectedPeople.length === 0" class="common-color-grey common-size-xxxxs" data-automation="add-function-popover-template-assign-to-me">
                    <div><a ng-click="assignToMe($event)">Assign to me</a></div>
                </div>
            </div>
            <div class="flex-grow"></div>
            <button class="btn btn-default flex-no-shrink" ng-click="close()">Cancel</button>
            <button
                type="submit"
                class="btn btn-primary margin-left-xs flex-no-shrink"
                data-automation="add-function-popover-template-submit"
                ng-disabled="popover.posting"
                ng-click="save()"
            >
                Save
            </button>
        </div>
    </form>
</div>
