import React from 'react';
import styled from 'styled-components';

import { AITypewriter } from '@tonkean/ai-builder';
import { IconSvg } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { SmartSearchIcon } from '@tonkean/svg';
import type { SmartConversationMessage, SmartSearchAnalyzeEntityCategoryResponse } from '@tonkean/tonkean-entities';

const Container = styled.div`
    display: flex;
    gap: 10px;
`;
const ResponseIcon = styled(IconSvg)`
    flex-shrink: 0;
    margin-top: 4px;
`;

interface Props {
    message: SmartConversationMessage<SmartSearchAnalyzeEntityCategoryResponse>;
    onAnimationEnd: () => void;
}

const SmartSearchAnalyzeEntityCategoryView: React.FC<Props> = ({ message, onAnimationEnd }) => {
    return (
        <Container>
            <ResponseIcon as={SmartSearchIcon} size={18} />
            <SmartConversationResponseText>
                {message.response.answerText && (
                    <AITypewriter
                        skipAnimation={!message.showAnimation}
                        text={message.response.answerText}
                        onTypingDone={(isDone) => {
                            if (isDone) {
                                onAnimationEnd();
                            }
                        }}
                    />
                )}
            </SmartConversationResponseText>
        </Container>
    );
};

export default SmartSearchAnalyzeEntityCategoryView;
