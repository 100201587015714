import { useMemo } from 'react';

import type { ContractGroupSummary } from '../entities';

import type { Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const useGetContractGroupSelector = (
    groups: Group[],
    contractId: TonkeanId<TonkeanType.CONTRACT> | undefined,
    workflowVersionType: WorkflowVersionType,
): ContractGroupSummary[] => {
    return useMemo(() => {
        if (!contractId) {
            return EMPTY_ARRAY;
        }

        return groups
            .filter((group: Group) =>
                workflowVersionType === WorkflowVersionType.DRAFT
                    ? group.draftContractId === contractId
                    : group.publishedContractId === contractId,
            )
            .map(
                (group: Group) =>
                    ({
                        displayName: group.name,
                        id: group.id,
                    }) as ContractGroupSummary,
            );
    }, [contractId, groups, workflowVersionType]);
};

export default useGetContractGroupSelector;
