import { lateConstructController } from '@tonkean/angular-components';
import { getDefaultTonkeanQuery } from '@tonkean/tonkean-utils';

/* @ngInject */
function MatchOptionSelectionCtrl(
    $scope,
    projectManager,
    customTriggerManager,
    utils,
    syncConfigCacheManager,
    workflowVersionManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetWorkflowVersionId: ctrl.targetWorkflowVersionId || ctrl.workflowVersionId,
        logicId: ctrl.logicId,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        modFixedWidth: ctrl.modFixedWidth,
        viewOnly: ctrl.viewOnly,
        verticalMod: ctrl.verticalMod,
        entityMetadata: ctrl.entityMetadata,
        validationObject: ctrl.validationObject,
        projectIntegration: ctrl.projectIntegration,
        savedMatchOptionSelection: ctrl.savedMatchOptionSelection,
        showAdvanced: ctrl.showAdvanced,
        defaultSelectedOption: ctrl.defaultSelectedOption,
        onlyShowDefaultSelectedOption: ctrl.onlyShowDefaultSelectedOption,
        orderByTonkeanField: ctrl.orderByTonkeanField,
        customQueryControl: {},
        customQueryDefinition: getQueryDefinition(),
        allowedConditionsSet: {
            Equals: true,
            BasicEquals: true,
            NotEquals: true,
            BasicNotEquals: true,
            LessThan: true,
            GreaterThan: true,
            Match: true,
            NotMatch: true,
            Between: true,
            Before: true,
            After: true,
            Past: true,
            Next: true,
            InThis: true,
            OlderThan: true,
            FurtherThan: true,
            BeforeNow: true,
            AfterNow: true,
            Contains: true,
            NotContains: true,
            IsEmpty: true,
            IsNotEmpty: true,
        },
        orderByField:
            ctrl.savedMatchOptionSelection && ctrl.savedMatchOptionSelection.orderByField
                ? ctrl.savedMatchOptionSelection.orderByField
                : null,
        orderByFieldTypes: [
            {
                apiName: 'String',
                displayName: 'Textual',
            },
            {
                apiName: 'Number',
                displayName: 'Number',
            },
            {
                apiName: 'Date',
                displayName: 'Date',
            },
        ],

        matchOptions: {
            specificItem: {
                id: 'specificItem',
                apiName: 'SPECIFIC_ITEM',
            },
            matchFieldRules: {
                id: 'matchFieldRules',
                apiName: 'MATCH_FIELD_RULES',
            },
            tonkeanSmartMatching: {
                id: 'tonkeanSmartMatching',
                apiName: 'TONKEAN_SMART_MATCHING',
            },
            matchFieldCustomQuery: {
                id: 'matchFieldCustomQuery',
                apiName: 'MATCH_FIELD_CUSTOM_QUERY',
            },
        },

        // Order types
        orderTypes: [
            {
                apiName: 'ASC',
                displayName: 'Ascending',
            },
            {
                apiName: 'DESC',
                displayName: 'Descending',
            },
        ],

        selectedMatchOption: null,
        performOnWorkerItem: null,
        creatingCustomTriggerId: null,
        idRelationFieldDefinitionId: null,
        entityMatchField: null,
        workerItemMatchFieldDefinition: null,
        workerItemMatchSpecialField: null,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        tabsFieldSelectorModFixedWidth: ctrl.tabsFieldSelectorModFixedWidth,
        isWorkflowVersionPublished: $scope.wvm.isPublishedVersion(ctrl.workflowVersionId),

        notAllowedConditionsSet: {
            Contains: true,
            NotContains: true,
        },
    };

    function isLogicNodeMatchingAsRelated(node) {
        // Correct logic type
        return (
            node.customTriggerType === 'PERFORM_INTEGRATION_ACTION' &&
            node.customTriggerActions &&
            node.customTriggerActions.length &&
            node.customTriggerActions[0].customTriggerActionDefinition.performedActionType === 'CREATE_NEW_ITEM' &&
            // Same entity
            node.customTriggerActions[0].customTriggerActionDefinition.performOnEntity &&
            node.customTriggerActions[0].customTriggerActionDefinition.performOnEntity.entity ===
                $scope.data.entityMetadata.entity &&
            // Same integration
            node.customTriggerActions[0].customTriggerActionDefinition.projectIntegrationId ===
                $scope.data.projectIntegration.id
        );
    }

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        $scope.data.selectedMatchOption = $scope.data.defaultSelectedOption
            ? $scope.data.matchOptions[$scope.data.defaultSelectedOption]
            : $scope.data.matchOptions.specificItem;
        $scope.data.orderBySelectedOrder = $scope.data.orderTypes[0];
        $scope.data.selectedOrderByFieldType = $scope.data.orderByFieldTypes[0];

        if ($scope.data.savedMatchOptionSelection) {
            initializeEditMode();
            $scope.onMatchOptionSelectionChanged(false);
        } else {
            // Check if match option integration is not the same as the sync one, then try to find a matching logic
            const syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);
            if (
                !syncConfig ||
                !syncConfig.viewData ||
                syncConfig.viewData.integrationUniqueType !== $scope.data.projectIntegration.integrationUniqueType ||
                syncConfig.viewData.entity !== $scope.data.entityMetadata.entity
            ) {
                let matchingNodeId = null;
                // Having a logic id indicates that the selection of match is from a trigger, so only when we have
                // it we will try to connect the match to a custom trigger
                if ($scope.data.logicId) {
                    const matchingParent = customTriggerManager.getFirstParentByPredicateInWorkflowVersion(
                        $scope.data.workflowVersionId,
                        $scope.data.logicId,
                        (parent) => isLogicNodeMatchingAsRelated(parent.node),
                    );
                    if (matchingParent) {
                        matchingNodeId = matchingParent.node.id;
                    }

                    // If we didn't find a matching parent
                    // Try to find a matching logic anywhere in the tree
                    if (!matchingNodeId) {
                        const matchingLogicData = customTriggerManager.findLogicDataInWorkflowVersionGraph(
                            $scope.data.workflowVersionId,
                            isLogicNodeMatchingAsRelated,
                        );
                        if (matchingLogicData) {
                            matchingNodeId = matchingLogicData.logic.node.id;
                        }
                    }
                }

                if (matchingNodeId) {
                    $scope.data.selectedMatchOption = $scope.data.matchOptions.specificItem;
                    $scope.data.performOnWorkerItem = false;
                    $scope.data.creatingCustomTriggerId = matchingNodeId;
                    $scope.data.idRelationFieldDefinitionId = null;
                    $scope.data.savedMatchOptionSelection = $scope.onMatchOptionSelectionChanged(true);
                }
            }
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
        if (changes.entityMetadata) {
            $scope.data.entityMetadata = ctrl.entityMetadata;
        }
        if (changes.targetWorkflowVersionId) {
            $scope.data.targetWorkflowVersionId = ctrl.targetWorkflowVersionId;
        }
        if (changes.workflowVersionId) {
            $scope.data.targetWorkflowVersionId = ctrl.targetWorkflowVersionId || ctrl.workflowVersionId;
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.data.isWorkflowVersionPublished = $scope.wvm.isPublishedVersion(ctrl.workflowVersionId);
        }
        if (changes.savedMatchOptionSelection && !changes.savedMatchOptionSelection.isFirstChange()) {
            $scope.data.savedMatchOptionSelection = ctrl.savedMatchOptionSelection;
            initializeEditMode();
            $scope.data.customQueryDefinition = getQueryDefinition();
            $scope.data.customQueryControl?.reloadFromExistingDefinition?.($scope.data.customQueryDefinition);
        }
    };

    /**
     * Occurs once an option is the radio button is selected.
     */
    $scope.onMatchOptionSelected = function (selectedMatchOptionId) {
        $scope.data.selectedMatchOption = $scope.data.matchOptions[selectedMatchOptionId];
        $scope.onMatchOptionSelectionChanged(true);
    };

    /**
     * Occurs on change of custom query.
     */
    $scope.onFilterChange = function (shouldNotSaveLogic) {
        $scope.onMatchOptionSelectionChanged(!shouldNotSaveLogic);
    };

    /**
     * Occurs on selection of field to order by.
     */
    $scope.onOrderByFieldSelected = function (selectedField, dontSaveChanges) {
        $scope.data.orderByField = selectedField?.name ? selectedField : null;
        $scope.onMatchOptionSelectionChanged(!dontSaveChanges);
    };

    /**
     * Occurs once a order by field type is selected.
     */
    $scope.onOrderByFieldTypeSelected = function (selectedOrderByFieldType) {
        $scope.data.selectedOrderByFieldType = selectedOrderByFieldType;
        $scope.onMatchOptionSelectionChanged(true);
    };

    /**
     * Occurs once workflow item selection is done.
     */
    $scope.onWorkflowItemSelectionChanged = function (
        performOnWorkerItem,
        creatingCustomTriggerId,
        idRelationFieldDefinitionId,
        shouldSaveLogic,
    ) {
        if (performOnWorkerItem || creatingCustomTriggerId || idRelationFieldDefinitionId) {
            if (shouldSaveLogic) {
                $scope.data.selectedMatchOption = $scope.data.matchOptions.specificItem;
            }

            $scope.data.performOnWorkerItem = performOnWorkerItem;
            $scope.data.creatingCustomTriggerId = creatingCustomTriggerId;
            $scope.data.idRelationFieldDefinitionId = idRelationFieldDefinitionId;
            $scope.onMatchOptionSelectionChanged(shouldSaveLogic);
        }
    };

    /**
     * Occurs on change of match conditions.
     */
    $scope.onWorkflowMatchByFieldChanged = function (
        entityMatchField,
        workerItemMatchFieldDefinition,
        workerItemMatchSpecialField,
        shouldSaveLogic,
    ) {
        if (entityMatchField && (workerItemMatchFieldDefinition || workerItemMatchSpecialField)) {
            if (shouldSaveLogic) {
                $scope.data.selectedMatchOption = $scope.data.matchOptions.matchFieldRules;
            }

            $scope.data.entityMatchField = entityMatchField;
            $scope.data.workerItemMatchFieldDefinition = workerItemMatchFieldDefinition;
            $scope.data.workerItemMatchSpecialField = workerItemMatchSpecialField;
            $scope.onMatchOptionSelectionChanged(shouldSaveLogic);
        }
    };

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderBySelectedOrderSelected = function (selectedOrder) {
        $scope.data.orderBySelectedOrder = selectedOrder;
        $scope.onMatchOptionSelectionChanged(true);
    };

    /**
     * Occurs once the match option selection is changed.
     */
    $scope.onMatchOptionSelectionChanged = function (shouldSave) {
        let selectionObject = {};

        if ($scope.data.customQueryControl && $scope.data.customQueryControl.createDefinitionFromCustomFilters) {
            selectionObject.customQuery = $scope.data.customQueryControl.createDefinitionFromCustomFilters();
            selectionObject.customQuery.entity =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata.entity
                    : $scope.data.entityMetadata;
            selectionObject.customQuery.entityMetadata =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata
                    : { entity: $scope.data.entityMetadata };
        }

        // We don't change the matching recurrence yet from the UI, so it's the same as we got from the server
        selectionObject.matchingRecurrence = $scope.data.savedMatchOptionSelection?.matchingRecurrence;

        if ($scope.data.orderByField) {
            selectionObject.orderByField = $scope.data.orderByField;
        }

        if ($scope.data.orderBySelectedOrder) {
            selectionObject.orderByOrder = $scope.data.orderBySelectedOrder.apiName;
        }

        if ($scope.data.selectedOrderByFieldType) {
            selectionObject.orderByFieldType = $scope.data.selectedOrderByFieldType.apiName;
        }

        if ($scope.data.selectedMatchOption) {
            switch ($scope.data.selectedMatchOption.id) {
                case $scope.data.matchOptions.specificItem.id:
                case $scope.data.matchOptions.tonkeanSmartMatching.id:
                    selectionObject = {
                        matchOptionApiName: $scope.data.matchOptions[$scope.data.selectedMatchOption.id].apiName,
                        performOnWorkerItem: $scope.data.performOnWorkerItem,
                        creatingCustomTriggerId: $scope.data.creatingCustomTriggerId,
                        idRelationFieldDefinitionId: $scope.data.idRelationFieldDefinitionId,
                        validMatchSelection: !!(
                            $scope.data.performOnWorkerItem ||
                            $scope.data.creatingCustomTriggerId ||
                            $scope.data.idRelationFieldDefinitionId
                        ),
                    };
                    break;

                case $scope.data.matchOptions.matchFieldRules.id:
                    selectionObject = {
                        matchOptionApiName: $scope.data.matchOptions[$scope.data.selectedMatchOption.id].apiName,
                        entityMatchField: $scope.data.entityMatchField,
                        workerItemMatchFieldDefinition: $scope.data.workerItemMatchFieldDefinition,
                        workerItemMatchSpecialField: $scope.data.workerItemMatchSpecialField,
                        validMatchSelection: !!(
                            $scope.data.entityMatchField &&
                            ($scope.data.workerItemMatchFieldDefinition || $scope.data.workerItemMatchSpecialField)
                        ),
                    };
                    $scope.data.showAdvanced = true;
                    break;

                case $scope.data.matchOptions.matchFieldCustomQuery.id:
                    selectionObject.matchOptionApiName =
                        $scope.data.matchOptions[$scope.data.selectedMatchOption.id].apiName;
                    selectionObject.validMatchSelection = true;

                    if ($scope.data.logicId) {
                        selectionObject.creatingCustomTriggerId = $scope.data.logicId;
                    }
                    break;
            }
        }

        if (ctrl.onMatchOptionSelectionChanged) {
            ctrl.onMatchOptionSelectionChanged({
                selectionObject,
                shouldSave,
            });
        }

        return selectionObject;
    };

    function getQueryDefinition() {
        return ctrl.savedMatchOptionSelection && ctrl.savedMatchOptionSelection.customQuery
            ? ctrl.savedMatchOptionSelection.customQuery
            : {
                  query: getDefaultTonkeanQuery(),
              };
    }

    /**
     * Initializes the edit mode for the component.
     */
    function initializeEditMode() {
        const apiNameToMatchOptionMap = {};
        for (const key in $scope.data.matchOptions) {
            if ($scope.data.matchOptions.hasOwnProperty(key)) {
                const matchOption = $scope.data.matchOptions[key];
                apiNameToMatchOptionMap[matchOption.apiName] = matchOption;
            }
        }

        if ($scope.data.savedMatchOptionSelection.matchOption) {
            $scope.data.selectedMatchOption =
                apiNameToMatchOptionMap[$scope.data.savedMatchOptionSelection.matchOption];

            if (
                $scope.data.savedMatchOptionSelection.matchOption === 'MATCH_FIELD_CUSTOM_QUERY' ||
                $scope.data.savedMatchOptionSelection.matchOption === 'MATCH_FIELD_RULES'
            ) {
                $scope.data.showAdvanced = true;
            }

            if ($scope.data.savedMatchOptionSelection.matchOption === 'TONKEAN_SMART_MATCHING') {
                $scope.data.selectedMatchOption = apiNameToMatchOptionMap['SPECIFIC_ITEM'];
                $scope.data.savedMatchOptionSelection.matchOption = 'SPECIFIC_ITEM';
                $scope.data.savedMatchOptionSelection.performOnWorkerItem = true;
            }
        }

        $scope.data.orderByField = $scope.data.savedMatchOptionSelection.orderByField;
        const apiNameToOrderObjectMap = utils.createMapFromArray($scope.data.orderTypes, 'apiName');
        $scope.data.orderBySelectedOrder = apiNameToOrderObjectMap[$scope.data.savedMatchOptionSelection.orderByOrder];

        const apiNameToOrderByFieldTypeMap = utils.createMapFromArray($scope.data.orderByFieldTypes, 'apiName');
        $scope.data.selectedOrderByFieldType =
            apiNameToOrderByFieldTypeMap[$scope.data.savedMatchOptionSelection.orderByFieldType];
    }
}

export default angular.module('tonkean.app').controller('MatchOptionSelectionCtrl', lateConstructController(MatchOptionSelectionCtrl));
