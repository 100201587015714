import SharePointAuthenticationComponent from './SharePointAuthenticationComponent';
import SharePointCustomizedSetupComponent from './SharePointCustomizedSetupComponent';
import sharepointCircleImage from '../../../../apps/tracks/images/integrations/sharepoint-circle.svg';
import sharepointLogoImage from '../../../../apps/tracks/images/sharepoint-logo.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class SharePointIntegration extends IntegrationDefinitionBase {
    override name = 'sharepoint';
    override displayName = 'SharePoint Online';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override entities = [
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('File', 'Files'),
        new IntegrationEntity('List', 'Lists'),
        new IntegrationEntity('ListItem', 'List Items'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(sharepointCircleImage, '130px'),
        new IntegrationIcon(sharepointLogoImage, '130px'),
    );

    override customizedSetupComponent = SharePointCustomizedSetupComponent;
    override customizedAuthenticationComponent = SharePointAuthenticationComponent;

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Folder: true, File: true, List: true, ListItem: true },
        isOnlyDataRetention: {},
        daysDefault: { free: 7 },
    };
}

export default SharePointIntegration;
