import { useField } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';

import ValidateActionHandleType from './common/ValidateActionHandleType';
import ProjectIntegrationEntityHandleResponseAdditionalFieldsView from './ProjectIntegrationEntityHandleResponseAdditionalFieldsView';
import ProjectIntegrationEntityHandleResponseFetcherSelector from './ProjectIntegrationEntityHandleResponseFetcherSelector';
import ProjectIntegrationEntityLiveStreamWebhookFilter from './ProjectIntegrationEntityLiveStream/ProjectIntegrationEntityLiveStreamWebhookFilter';
import HandleResponseActionType from './ProjectIntegrationEntitySchema/HandleResponseActionType';
import ProjectIntegrationHandleResponseDynamicFields from './ProjectIntegrationHandleResponseDynamicFields';
import { ReactComponent as PencilIcon } from '../../../../images/icons/edit-pencil.svg';
import projectIntegrationPaginatedActionConstantParamIdToConfig from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParamIdToConfig';
import { ProjectIntegrationPaginatedActionConstantParams } from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParams';
import projectIntegrationEntityCollectPageInputState from '../states/projectIntegrationEntityCollectPageInputState';

import { useToastMessage } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { Field, H4, LoadingCircle, Paragraph, SimpleSelect, useCreateDialog } from '@tonkean/infrastructure';
import { EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import type {
    ProjectIntegration,
    ProjectIntegrationEntityResponseHandlingDefinitionBase,
    ResponseHandlingValidationStatus,
    TonkeanExpressionDefinition,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const CustomLabel = styled(H4)`
    color: ${Theme.colors.gray_800};
`;

const ExplanationParagraph = styled(Paragraph)`
    margin-top: 4px;
`;

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const EntityWrapper = styled.div<{ wideButton: boolean }>`
    display: grid;
    grid-template-columns: auto 75px;
    ${({ wideButton }) =>
        wideButton &&
        css`
            grid-template-columns: auto 113px;
        `};
    align-items: center;
    grid-gap: 8px;
`;

interface Props {
    entityIdFormikName?: string;
    responseHandlingDefinitionFormikNamePrefix: string;
    parametersFormikNamePrefix?: string;
    projectIntegrationEntity: ProjectIntegrationEntity;
    expressionProps?: FormikExpressionProps;
    isLoading?: boolean;
    validateActionHandleType: ValidateActionHandleType;
    validationId: string | undefined;
    labelPrefix: string;
    projectIntegrationEntities: { id: string; displayName: string }[];
    projectIntegration: ProjectIntegration;
    showFetchEntity: boolean;
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[];
    handleResponseActionTypes: HandleResponseActionType;
}

const ProjectIntegrationEntitySingleHandleResponseView: React.FC<Props> = ({
    projectIntegrationEntity,
    entityIdFormikName,
    parametersFormikNamePrefix,
    responseHandlingDefinitionFormikNamePrefix,
    expressionProps,
    isLoading = false,
    validateActionHandleType,
    validationId,
    labelPrefix,
    projectIntegrationEntities,
    projectIntegration,
    showFetchEntity,
    entitiesSummary,
    handleResponseActionTypes,
}) => {
    const emitToastMessage = useToastMessage();
    const { confirm } = useCreateDialog();

    const [, { value: entityDefinition }] = useField<
        ProjectIntegrationEntityResponseHandlingDefinitionBase<EntityResponseHandlingDefinitionType>
    >(responseHandlingDefinitionFormikNamePrefix);

    const [, { error: entityError }] = useField<TonkeanExpressionDefinition>(
        `${responseHandlingDefinitionFormikNamePrefix}.entity`,
    );

    const [, { value: validationStatus }, validationStatusHelper] = useField<ResponseHandlingValidationStatus>(
        `${responseHandlingDefinitionFormikNamePrefix}.validationStatus`,
    );

    const [{ loading: loadingValidateActionHandleResponseObject }, validateActionHandleResponse] =
        useLazyTonkeanService(
            validateActionHandleType === ValidateActionHandleType.PROJECT_INTEGRATION_TEST_RUN_ID
                ? 'validateActionHandleResponse'
                : 'validateWebhookPayloadHandleResponse',
        );

    // Tab for the validated tabs.
    const additionalFieldsTab = useMemo<TonkeanExpressionAdditionalTab>(
        () => ({
            id: 'CUSTOM_PAYLOAD_FIELDS',
            label: 'Response Fields',
            iconClass: 'mod-flow',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                return Promise.resolve(
                    validationStatus?.outputFields.map((param) => ({
                        name: param.id,
                        value: param.id,
                        label: param.displayName,
                        id: param.id,
                    })) || [],
                );
            },
        }),

        [validationStatus?.outputFields],
    );

    // Tab for the paginated tabs.
    const enterpriseComponentFields = useMemo<TonkeanExpressionAdditionalTab>(
        () => ({
            id: 'ENTERPRISE_COMPONENT_FIELDS',
            label: 'Enterprise Component Fields',
            iconClass: 'mod-columns',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                return Promise.resolve(
                    Object.entries(projectIntegrationPaginatedActionConstantParamIdToConfig)
                        // Filter out the non pagination params
                        .filter(([paramId]) => {
                            const paginationParams: string[] = Object.values(
                                ProjectIntegrationPaginatedActionConstantParams,
                            );
                            return paginationParams.includes(paramId);
                        })
                        .map(([paramId, paramConfig]) => ({
                            name: paramId,
                            value: paramId,
                            label: paramConfig.displayName,
                            id: paramId,
                        })),
                );
            },
        }),
        [],
    );

    // On Validate / Edit entity field.
    const onToggleValidState = useCallback(async () => {
        try {
            if (validationStatus?.isValid) {
                const toggleToEdit = await confirm(
                    `Are you sure you want to edit the extracted entity?`,
                    `This will delete all the configured extracted fields, and may affect Response Handling configuration`,
                    { cancelLabel: 'Cancel', okLabel: 'OK', warning: true },
                );
                if (toggleToEdit) {
                    validationStatusHelper.setValue({ isValid: false, outputFields: [] });
                }
            } else {
                // Validate that we have id to work with
                if (!validationId) {
                    emitToastMessage(
                        `Cant validate entity without ${
                            validateActionHandleType === ValidateActionHandleType.WEBHOOK_PAYLOAD_ID
                                ? 'Webhook payload'
                                : 'Test run'
                        }`,
                        'danger',
                    );
                    return;
                }

                const isValidResponse = await validateActionHandleResponse(
                    projectIntegrationEntity.projectIntegrationId,
                    validationId,
                    entityDefinition,
                );

                if (!isValidResponse.isValid) {
                    emitToastMessage('The Entity must return as list', 'danger');
                    validationStatusHelper.setValue({ outputFields: [], isValid: false });
                    return;
                } else {
                    validationStatusHelper.setValue({ outputFields: isValidResponse.outputFields, isValid: true });
                }
            }
        } catch {
            emitToastMessage('Error occurred please try again', 'danger');
        }
    }, [
        validationStatus?.isValid,
        confirm,
        validationStatusHelper,
        validationId,
        validateActionHandleResponse,
        projectIntegrationEntity.projectIntegrationId,
        entityDefinition,
        emitToastMessage,
        validateActionHandleType,
    ]);

    const [, setJsonPickerState] = useRecoilState(projectIntegrationEntityCollectPageInputState);

    useEffect(() => {
        setJsonPickerState((currentState) => {
            return {
                ...currentState,
                isJsonPickerDisabled: validationStatus?.isValid,
            };
        });
    }, [setJsonPickerState, validationStatus?.isValid]);

    const projectIntegrationEntityOptions = useMemo(() => {
        return projectIntegrationEntities
            .map((entitySummary) => ({
                label: entitySummary.displayName,
                value: entitySummary.id,
            }))
            .filter((entityOption) => entityOption.value !== projectIntegrationEntity.id);
    }, [projectIntegrationEntities, projectIntegrationEntity.id]);

    return (
        <>
            {isLoading && <>Loading...</>}

            {!isLoading && (
                <>
                    {responseHandlingDefinitionFormikNamePrefix && !!projectIntegrationEntities.length && (
                        <StyledField label={<CustomLabel>Select Existing Entity</CustomLabel>}>
                            <SimpleSelect name={entityIdFormikName} options={projectIntegrationEntityOptions} />
                        </StyledField>
                    )}
                    <StyledField
                        error={entityError ? 'Invalid Entities' : undefined}
                        label={
                            <CustomLabel>
                                {`${labelPrefix}`}
                                {loadingValidateActionHandleResponseObject && (
                                    <LoadingCircle className="margin-left-xs" />
                                )}
                                <ExplanationParagraph $light $small>
                                    Extract Entities action will return an array of entities extracted from the response
                                    body.
                                </ExplanationParagraph>
                            </CustomLabel>
                        }
                    >
                        <EntityWrapper wideButton={!validationStatus?.isValid}>
                            <FormikTonkeanExpression
                                disabled={validationStatus?.isValid || loadingValidateActionHandleResponseObject}
                                placeholder="Extract entity from the response body"
                                name={`${responseHandlingDefinitionFormikNamePrefix}.entity`}
                                {...expressionProps}
                            />

                            <Button
                                onClick={onToggleValidState}
                                size={ButtonSize.MEDIUM}
                                disabled={loadingValidateActionHandleResponseObject}
                                flex
                                outlined
                            >
                                {validationStatus?.isValid ? (
                                    <>
                                        <PencilIcon /> Edit
                                    </>
                                ) : (
                                    <>Extract Entities</>
                                )}
                            </Button>
                        </EntityWrapper>
                    </StyledField>

                    <StyledField label={<CustomLabel>Flatten Path (Optional)</CustomLabel>}>
                        <FormikTonkeanExpression
                            disabled={validationStatus?.isValid || loadingValidateActionHandleResponseObject}
                            placeholder="Entity flatten path (Optional)"
                            name={`${responseHandlingDefinitionFormikNamePrefix}.entityFlatteningPath`}
                            {...expressionProps}
                        />
                    </StyledField>
                    {showFetchEntity && (
                        <StyledField label={<CustomLabel>Fetch entity</CustomLabel>}>
                            <ProjectIntegrationEntityLiveStreamWebhookFilter
                                namePrefix={responseHandlingDefinitionFormikNamePrefix}
                            />
                        </StyledField>
                    )}

                    {showFetchEntity &&
                        entityDefinition.responseHandlingType ===
                            EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES_IDS && (
                            <ProjectIntegrationEntityHandleResponseFetcherSelector
                                responseHandlingDefinitionFormikNamePrefix={responseHandlingDefinitionFormikNamePrefix}
                                entityIdFormikName={entityIdFormikName || ''}
                                parametersFormikNamePrefix={parametersFormikNamePrefix || ''}
                                projectIntegration={projectIntegration}
                                projectIntegrationEntityProp={projectIntegrationEntity}
                                entitiesSummary={entitiesSummary}
                                expressionProps={{
                                    ...expressionProps,
                                    additionalTabs: [
                                        ...(expressionProps?.additionalTabs || []),
                                        additionalFieldsTab,
                                        enterpriseComponentFields,
                                    ],
                                    defaultTabId: 'CUSTOM_PAYLOAD_FIELDS',
                                }}
                            />
                        )}

                    {validationStatus?.isValid && (
                        <ProjectIntegrationEntityHandleResponseAdditionalFieldsView
                            namePrefix={`${responseHandlingDefinitionFormikNamePrefix}`}
                            expressionProps={{
                                ...expressionProps,
                                additionalTabs: [...(expressionProps?.additionalTabs || []), additionalFieldsTab],
                                defaultTabId: 'CUSTOM_PAYLOAD_FIELDS',
                            }}
                            labelPrefix={labelPrefix}
                            responseHandlingDefinitionType={entityDefinition.responseHandlingType}
                        />
                    )}
                    {HandleResponseActionType.ENTITY_FETCHER === handleResponseActionTypes && (
                        <ProjectIntegrationHandleResponseDynamicFields
                            name={`${responseHandlingDefinitionFormikNamePrefix}.dynamicParameters`}
                            expressionTabs={expressionProps?.additionalTabs || []}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ProjectIntegrationEntitySingleHandleResponseView;
