import ProjectIntegrationViewDataList from './tnkProjectIntegrationViewDataPreviewList';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationViewDataList',
        reactToAngular(ProjectIntegrationViewDataList, [
            'entityPluralLabel',
            'projectIntegration',
            'viewData',
            'viewType',
            'fullScreen',
            'selectedEntity',
            'onDataChanged',
            'editAllowed',
        ]),
    );
