import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import NewTrainingSetModal from './NewTrainingSetModal';
import { ReactComponent as TrainingSetEmptyStateIcon } from '../../../../../../images/icons/training-set-empty-state.svg';
import EnterpriseComponentTabHeader from '../../../../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentTabHeader';
import EnterpriseComponentSearch from '../../../../EnterpriseComponentsModule/components/EnterpriseComponentSearch';
import getTrainingSetAsTableRow from '../utils/getTrainingSetAsTableRow';
import getTrainingSetAsTableRowWithState from '../utils/getTrainingSetAsTableRowWithState';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { KnowledgeBase } from '@tonkean/constants';
import { Modal, ModalSize, SimpleErrorStateMessage, StateMessage, Table } from '@tonkean/infrastructure';
import type { PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const Wrapper = styled.div`
    margin-top: 30px;
`;

const SearchRow = styled.div`
    display: flex;
`;

const Tr = styled.tr`
    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

interface Props {}

const TrainingSetsPage: React.FC<Props> = ({}) => {
    const projectManager = useAngularService('projectManager');
    const {
        data: trainingSetsGallery,
        error,
        loading,
    } = useTonkeanService('getTrainingSetsGallery', projectManager.project.id);
    const [showTrainingSetsTypes, setShowTrainingSetsTypes] = useState<Record<PersonPermissionRoleType, boolean>>({
        ADMIN: true,
        MAKER: true,
        NOT_AUTHORIZED: false,
    });
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [newTrainingSetModalOpen, setNewTrainingSetModalOpen] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: `Training Sets${
                    trainingSetsGallery?.entities.length ? ` (${trainingSetsGallery?.entities.length})` : ''
                }`,
                accessor: 'name' as const,
                width: '20%',
            },
            {
                Header: 'Last Update',
                accessor: 'updated' as const,
                width: '20%',
            },
            {
                Header: 'Allowed Solutions',
                accessor: 'solutions' as const,
                width: '20%',
            },
            {
                Header: 'Status',
                accessor: 'status' as const,
                width: '20%',
            },
            {
                Header: 'Access Type',
                accessor: 'access' as const,
                width: '20%',
            },
        ],
        [trainingSetsGallery],
    );

    const filteredTrainingSets = useMemo(() => {
        if (!trainingSetsGallery) {
            return [];
        }

        const trainingSetsToShow = trainingSetsGallery.entities.filter((trainingSet) => {
            return showTrainingSetsTypes[trainingSet.accessType];
        });

        if (searchTerm) {
            return trainingSetsToShow.filter((set) => set.displayName.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        return trainingSetsToShow;
    }, [searchTerm, showTrainingSetsTypes, trainingSetsGallery]);

    const rows = useMemo(() => {
        return filteredTrainingSets.map((set) => {
            return getTrainingSetAsTableRow(set, true);
        });
    }, [filteredTrainingSets]);

    const placeHolderRows = useMemo(() => {
        return range(3).map(() => getTrainingSetAsTableRowWithState());
    }, []);

    const empty = !loading && !error && rows.length === 0;
    return (
        <Wrapper>
            <EnterpriseComponentTabHeader
                headerText="Training Sets"
                subHeaderText={
                    'Training sets are a basic machine learning feature that allows users to identify or extract text from\n' +
                    "                documents and use that text in their module's logic. "
                }
                knowledgeBaseUrl={KnowledgeBase.pages.trainingSetsPage || ''}
            />
            <SearchRow>
                <EnterpriseComponentSearch
                    onSearchTermChange={setSearchTerm}
                    searchTerm={searchTerm}
                    showAuthorizationTypes={showTrainingSetsTypes}
                    onShowAuthorizationTypesChange={setShowTrainingSetsTypes}
                    authorizationFiltersPrefix="Show sets that you can:"
                    disabled={(!loading && trainingSetsGallery?.entities.length === 0) || !!error}
                    placeholder="Search Training Sets"
                />
                <Button onClick={() => setNewTrainingSetModalOpen(true)}>+ New Training Set</Button>
            </SearchRow>

            <Modal
                onClose={() => setNewTrainingSetModalOpen(false)}
                size={ModalSize.SMEDIUM}
                open={newTrainingSetModalOpen}
                fixedWidth
            >
                <NewTrainingSetModal projectId={projectManager.project.id} />
            </Modal>

            {loading && <Table columns={columns} data={placeHolderRows} />}
            {error && <SimpleErrorStateMessage error="Error Loading Training Sets" />}
            {empty && (
                <StateMessage icon={<TrainingSetEmptyStateIcon />} title="No Training Sets were created yet">
                    Click on the '+ New Training Set' button above
                </StateMessage>
            )}
            {!loading && !error && rows.length > 0 && <Table columns={columns} data={rows} tableRowComponent={Tr} />}
        </Wrapper>
    );
};

export default TrainingSetsPage;
