import React, { useMemo } from 'react';
import * as Yup from 'yup';

import type { MarketplaceItemCreateSection } from '../components/MarketplaceItemCreateModalSection';
import MarketplaceItemCreateModalLandingPageSection from '../components/sections/solution/LandingPageSection/MarketplaceItemCreateModalLandingPageSection';
import MarketplaceItemCreateModalSolutionContentSection from '../components/sections/solution/MarketplaceItemCreateModalSolutionContentSection';
import MarketplaceItemCreateModalSolutionSectionsSection from '../components/sections/solution/MarketplaceItemCreateModalSolutionSectionsSection';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { MarketplaceItemKeyMetricTrendType } from '@tonkean/tonkean-entities';
import { yupEnum } from '@tonkean/utils';

const useGetMarketplaceBlueprintItemAdditionalSections = () => {
    const requireImageUpload = useFeatureFlag('tonkean_marketplace_item_must_upload_images');

    const additionalSections: MarketplaceItemCreateSection[] = useMemo(() => {
        return [
            {
                title: 'Content',
                contentComponent: <MarketplaceItemCreateModalSolutionContentSection />,
                configurationInitialValues: {
                    keyMetricsConfig: {
                        keyMetrics: [
                            {
                                id: 0,
                                trendType: MarketplaceItemKeyMetricTrendType.GREEN_UP,
                                metric: '',
                                subTitle: '',
                            },
                        ],
                    },
                },
                configurationValidationSchema: {
                    keyMetricsConfig: Yup.object({})
                        .shape({
                            keyMetrics: Yup.array().of(
                                Yup.object({
                                    id: Yup.number(),
                                    metric: Yup.string().required('You must set a metric'),
                                    trendType: yupEnum(MarketplaceItemKeyMetricTrendType).required(),
                                    subTitle: Yup.string().required('You must set a sub title'),
                                }).required(),
                            ),
                        })
                        .required(),
                },
            },
            {
                title: 'Solution Sections',
                contentComponent: <MarketplaceItemCreateModalSolutionSectionsSection />,
                configurationInitialValues: {
                    solutionOutline: {
                        title: '',
                    },
                },
                configurationValidationSchema: {
                    solutionOutline: Yup.object({
                        title: Yup.string().required('Must fill a solution outline title'),
                        sections: Yup.array(
                            Yup.object({
                                suggestedModules: Yup.array(
                                    Yup.object({
                                        label: Yup.string().required('Must fill a name'),
                                    }),
                                ),
                                image: Yup.array().when([], {
                                    is: () => requireImageUpload,
                                    then: Yup.array().length(1, 'Must upload 1 image').required('Must upload 1 image'),
                                    otherwise: Yup.array().notRequired(),
                                }),
                            }),
                        ),
                    }).required(),
                },
                validationSchema: {
                    images: Yup.array().when([], {
                        is: () => requireImageUpload,
                        then: Yup.array()
                            .min(1, 'Must upload at least 1 image')
                            .required('Must upload at least 1 image'),
                        otherwise: Yup.array().notRequired(),
                    }),
                },
            },
            {
                title: 'Landing Page',
                contentComponent: <MarketplaceItemCreateModalLandingPageSection />,
            },
        ];
    }, [requireImageUpload]);

    return additionalSections;
};

export default useGetMarketplaceBlueprintItemAdditionalSections;
