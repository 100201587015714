import React from 'react';
import styled from 'styled-components';

import { NLPBranchName, StyledNlpActionsIcon } from './getTrainingSetNlpActionBranchAsRow';
import NlpBranchExpendableContent from './NlpBranchExpendableContent';
import SingleWorkerRunActionInspectNlpStatus from './SingleWorkerRunActionInspectNlpStatus';

import type { ManualNlpBranchResult } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

function getManualNlpActionBranchAsRow(
    branchImpact: any,
    logicBlockTypes: any,
    branchResult?: ManualNlpBranchResult,
    activeBranchesWorkerRunLogics?: any[],
) {
    const logicBlockType = branchImpact.node.customTriggerType && logicBlockTypes[branchImpact.node.customTriggerType];
    const isActive =
        activeBranchesWorkerRunLogics?.some(
            (workerRunLogic) => workerRunLogic.customTriggerId === branchImpact.node.id,
        ) || false;

    return {
        expendableBorder: true,
        branch: (
            <Wrapper>
                <StyledNlpActionsIcon logicBlockType={logicBlockType} />
                <NLPBranchName dark={isActive}>{branchImpact.node.displayName}</NLPBranchName>
            </Wrapper>
        ),
        status: (
            <Wrapper>
                <SingleWorkerRunActionInspectNlpStatus
                    matchInformation={branchResult?.matchedResult}
                    failureReason={branchResult?.failureReason}
                    active={isActive}
                    branchType={branchImpact.node.customTriggerType}
                    activationExplainText="Activated when the input text doesn't match any other branch"
                />
            </Wrapper>
        ),
        expendable: branchResult?.matchedResult ? (
            <NlpBranchExpendableContent matchedResult={branchResult.matchedResult} />
        ) : undefined,
        highlighted: isActive,
    };
}

export default getManualNlpActionBranchAsRow;
