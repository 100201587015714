import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { SimpleSelect } from '@tonkean/infrastructure';
import type { HttpRequestQueryParam } from '@tonkean/tonkean-entities';
import { HttpQueryParamConditionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const StyledSimpleSelect = styled(SimpleSelect)`
    margin-top: 1px;
`;

const StyledIconButton = styled(IconButton)`
    margin-top: 3px;
`;

const QueryParamOptionTypeToText: Record<HttpQueryParamConditionType, string> = {
    [HttpQueryParamConditionType.ONLY_FIRST_PAGE]: 'Only First Page',
    [HttpQueryParamConditionType.ALWAYS]: 'Any Page',
    [HttpQueryParamConditionType.AFTER_FIRST_PAGE]: 'After First Page',
};

interface Props {
    name: string;
    remove(): void;
    showQueryParamType: boolean;
    expressionProps?: FormikExpressionProps;
}

const SingleQueryParamComponent: React.FC<Props> = ({ name, remove, showQueryParamType, expressionProps }) => {
    const [queryParam, , queryParamHelper] = useField<HttpRequestQueryParam>(name);

    const options = Object.entries(QueryParamOptionTypeToText).map(([key, value]) => ({ value: key, label: value }));

    return (
        <>
            <TonkeanExpression
                {...expressionProps}
                placeholder="Query Param Name"
                savedOriginalExpression={queryParam.value.name.originalExpression}
                savedEvaluatedExpression={queryParam.value.name.evaluatedExpression}
                onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                    queryParamHelper.setValue({
                        ...queryParam.value,
                        name: {
                            ...queryParam.value.name,
                            originalExpression,
                            evaluatedExpression,
                        },
                    });
                }}
            />

            <TonkeanExpression
                placeholder="Query Param Value"
                {...expressionProps}
                savedOriginalExpression={queryParam.value.value.originalExpression}
                savedEvaluatedExpression={queryParam.value.value.evaluatedExpression}
                onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                    queryParamHelper.setValue({
                        ...queryParam.value,
                        value: {
                            ...queryParam.value.name,
                            originalExpression,
                            evaluatedExpression,
                        },
                    });
                }}
            />

            {showQueryParamType && <StyledSimpleSelect options={options} name={`${name}.conditionType`} />}

            <StyledIconButton onClick={remove} flat>
                <TrashIcon />
            </StyledIconButton>
        </>
    );
};

export default SingleQueryParamComponent;
