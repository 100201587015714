<div>
    <div class="margin-top">
        <div class="common-size-s common-bold margin-top-xs margin-bottom-md">File Destination</div>
        <div class="margin-bottom-md">
            <div class="common-title-xxs margin-bottom-xs">Storage Source</div>
            <tnk-project-integration-selector
                group-id="{{ data.groupId }}"
                selected-project-integration-id="{{ data.storageProviderDestinationId }}"
                allow-default-option="false"
                filter-on-integration-type="data.filterByIntegrations"
                on-project-integration-selected="onDestinationProjectIntegrationSelected(selectedProjectIntegration)"
                is-for-upload="true"
            ></tnk-project-integration-selector>

            <div
                ng-if="data.invalidLogics[data.configuredLogic.node.id].storage"
                class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.node.id].storage }}
            </div>

            <div class="common-title-xxs margin-bottom-xs margin-top-xs">Destination Folder</div>
            <tnk-tonkean-expression
                placeholder="Destination folder"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.destinationFolderIdExpression.originalExpression"
                saved-evaluated-expression="data.destinationFolderIdExpression.evaluatedExpression"
                expression-unique-identifier="destinationFolder"
                on-tonkean-expression-changed="
                    onTonkeanExpressionFileDestinationChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div
                ng-if="data.invalidLogics[data.configuredLogic.node.id].destinationFolder"
                class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.node.id].destinationFolder }}
            </div>

            <div class="common-title-xxs margin-bottom-xs margin-top-xs">New File Name</div>
            <tnk-tonkean-expression
                placeholder="File Name"
                class="margin-bottom-20"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.fileNameExpression.originalExpression"
                saved-evaluated-expression="data.fileNameExpression.evaluatedExpression"
                expression-unique-identifier="fileName"
                on-tonkean-expression-changed="
                    onTonkeanExpressionFileNameChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div
                ng-if="data.invalidLogics[data.configuredLogic.node.id].fileName"
                class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.node.id].fileName }}
            </div>
        </div>

        <div class="common-title-xxs margin-bottom-xs margin-top-xs">File To Upload</div>
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.source.fileSourceType }}"
            storage-integration-id="{{ data.source.storageIntegrationId }}"
            storage-integration-action-id="{{ data.source.storageIntegrationActionId }}"
            url-expression="data.source.url"
            should-display-delete-sign="false"
            preview-evaluation-source="data.previewEvaluationSource"
            extended-match-configuration="data.source.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.source.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.source.workerStaticAssetDisplayName }}"
            parameters-values="data.source.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    matchEntityConfiguration,
                    onStorageProjectIntegrationSelected
                )
            "
            should-add-matched-entity-option="true"
            match-entity-configuration="data.source.matchEntityConfiguration"
            is-vertical-radio-boxes="true"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        ></tnk-worker-file-selection>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].template"
            class="common-color-danger margin-bottom-xs"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].template }}
        </div>
    </div>
</div>
