import { Formik } from 'formik';
import type { FormikProps } from 'formik/dist/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import ProjectIntegrationEntityLeftPageGenericHeader from './common/ProjectIntegrationEntityLeftPageGenericHeader';
import ValidateActionHandleType from './common/ValidateActionHandleType';
import ProjectIntegrationEntityContinuousCollectRightLayout from './ProjectIntegrationEntityContinuousCollectComponents/ProjectIntegrationEntityContinuousCollectRightLayout';
import ProjectIntegrationEntityContinuousCollectTestModal from './ProjectIntegrationEntityContinuousCollectComponents/ProjectIntegrationEntityContinuousCollectTestModal';
import ProjectIntegrationEntityFetcherHeader from './ProjectIntegrationEntityFetcherHeader';
import ProjectIntegrationEntityHeaderComponent from './ProjectIntegrationEntityHeaderComponent';
import type HandleResponseActionType from './ProjectIntegrationEntitySchema/HandleResponseActionType';
import projectIntegrationEntitySplitPageSchema, {
    defaultSingleHandleResponseObject,
} from './projectIntegrationEntitySplitPageFormikSchema';
import ProjectIntegrationHandleResponse from './ProjectIntegrationHandleResponse';
import { ReactComponent as SpaceShip } from '../../../../images/icons/spaceship-small.svg';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import SplitPageTemplate from '../../../infrastructure/pageTemplates/SplitPageTemplate';
import useEnterpriseComponentVariablesExpressionTab from '../../EnterpriseComponentsModule/hooks/useEnterpriseComponentVariablesExpressionTab';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import defaultCustomPaginatedHttpActionDefinition from '../../HttpRequestConfiguration/entities/defaultCustomPaginatedHttpActionDefinition';
import HttpRequestConfiguration from '../../HttpRequestConfiguration/HttpRequestConfiguration';
import useCustomRequestAdditionalTab from '../../HttpRequestConfiguration/utils/useCustomRequestAdditionalTab';
import ProjectIntegrationActionConstantParams from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';
import projectIntegrationActionConstantParamsIdToConfig from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';
import type { ConstantActionConfig } from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';
import ProjectIntegrationActionParametersView from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionCRUDPage/ProjectIntegrationActionParametersView';
import projectIntegrationPaginatedActionConstantParamIdToConfig from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParamIdToConfig';
import JsonPickerOutputType from '../entities/JsonPickerOutputType';
import projectIntegrationEntityCollectPageInputState from '../states/projectIntegrationEntityCollectPageInputState';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    Modal,
    SimpleErrorStateMessage,
    Tab,
    Tabs,
    Tooltip,
    useCreateDialog,
} from '@tonkean/infrastructure';
import { ActionDefinitionType, ActionParameterType } from '@tonkean/tonkean-entities';
import type {
    BaseActionParameter,
    HttpRequestDefinition,
    ProjectIntegrationAction,
    ProjectIntegrationActionResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const LeftPageWrapper = styled.div`
    margin-right: 33px;
`;

const InvalidDot = styled.span`
    margin-left: 6px;
    height: 4px;
    width: 4px;
    background-color: ${Theme.colors.error};
    border-radius: 2px;
`;

const TestButton = styled(Button)`
    margin-right: 8px;
`;

const TabWrapper = styled.div`
    margin-top: 20px;
`;

const ParametersViewContainer = styled.div`
    margin-top: 10px;
`;

enum ConfigurationTypeTab {
    REQUEST = 'REQUEST',
    HANDLE_RESPONSE = 'HANDLE_RESPONSE',
}

interface Props extends AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps> {
    httpRequestConfigurationHeaderText: string;
    requestUrlHeader?: string;
    additionalActionParameters?: BaseActionParameter[];
    isPaginatedRequest?: boolean;
    projectIntegrationActionId: string;
    labelPrefix?: string;
    showFetchEntity?: boolean;
    description: ReactNode;
    enableSubEntitiesCollectOption?: boolean;
    useEntityFetcherBreadcrumbs?: boolean;
    shouldShowPredefinedParameters?: boolean;
    isModuleParams?: boolean;
    handleResponseActionType: HandleResponseActionType;
}

const ProjectIntegrationEntityGenericSplitPageWithRequest: React.FC<Props> = ({
    entity,
    projectIntegration,
    entitiesSummary,
    httpRequestConfigurationHeaderText,
    additionalActionParameters,
    sidePaneTabName,
    requestUrlHeader,
    labelPrefix = 'Entity',
    isPaginatedRequest = true,
    projectIntegrationActionId,
    setOnExitTab,
    tabKeyToOnExit,
    showFetchEntity = false,
    description,
    enableSubEntitiesCollectOption,
    useEntityFetcherBreadcrumbs,
    shouldShowPredefinedParameters = false,
    isModuleParams = true,
    handleResponseActionType,
    ...props
}) => {
    const [selectedTab, setSelectedTab] = useState<ConfigurationTypeTab>(ConfigurationTypeTab.REQUEST);
    const [isTestModalOpen, setIsTestModalOpen] = useState<boolean>(false);
    const [projectIntegrationActionState, setProjectIntegrationActionState] = useState<
        ProjectIntegrationAction<ProjectIntegrationActionResponseHandlingDefinition> | undefined
    >(undefined);

    const formRef = useRef<FormikProps<any>>(null);

    // Getting the action configuration from the server.
    const {
        loading: loadingProjectIntegrationAction,
        data: projectIntegrationAction,
        error: errorProjectIntegrationAction,
    } = useTonkeanService('getProjectIntegrationActionById', projectIntegration.id, projectIntegrationActionId);

    // Base url for the project integration.
    const { data: projectIntegrationBaseUrl } = useTonkeanService('getIntegrationApiBaseUrl', projectIntegration.id);

    const [{ loading: isUpdateActionLoading, error: updateActionError }, updateProjectIntegrationAction] =
        useLazyTonkeanService('updateProjectIntegrationAction');

    useEffect(() => {
        setProjectIntegrationActionState(projectIntegrationAction);
    }, [projectIntegrationAction]);

    const { confirm } = useCreateDialog();

    // Getting all the test runs for the viewing entity.
    const [{ data: testRunsResponse, loading }, getProjectIntegrationActionTestRuns] = useLazyTonkeanService(
        'getProjectIntegrationActionTestRuns',
    );

    // Call back to reload the test runs.
    const reloadTestRuns = useCallback(() => {
        return getProjectIntegrationActionTestRuns(entity.projectIntegrationId, projectIntegrationActionId);
    }, [projectIntegrationActionId, entity.projectIntegrationId, getProjectIntegrationActionTestRuns]);

    const [{ selectedTestRunIds }, setJsonPickerInputState] = useRecoilState(
        projectIntegrationEntityCollectPageInputState,
    );

    // Change the inputtempConstantParams state according to the selected tab.
    useEffect(() => {
        switch (selectedTab) {
            case ConfigurationTypeTab.REQUEST:
                setJsonPickerInputState((currentState) => {
                    return { ...currentState, jsonPickerOutputType: JsonPickerOutputType.JSON_PATH };
                });
                break;
            case ConfigurationTypeTab.HANDLE_RESPONSE:
                setJsonPickerInputState((currentState) => {
                    return { ...currentState, jsonPickerOutputType: JsonPickerOutputType.JSON_TRAVERSE };
                });
                break;
        }
    }, [selectedTab, setJsonPickerInputState]);

    const onCloseModal = useCallback(async () => {
        await reloadTestRuns();
        setIsTestModalOpen(false);
    }, [reloadTestRuns]);

    // Init the test Runs list
    useEffect(() => {
        reloadTestRuns();
    }, [reloadTestRuns]);

    const hasErrorInRequestTab = !!formRef?.current?.errors['definition'];

    const hasErrorInHandleResponseTab = !!formRef?.current?.errors['handleResponse'];

    const isTestModalReady = projectIntegrationActionState && projectIntegrationBaseUrl;
    const tabHeader = sidePaneTabName || 'Unknown Tab';

    const shouldShowHandleResponseTab = !!testRunsResponse?.entities?.length;

    const enterpriseComponentVariablesTab = useEnterpriseComponentVariablesExpressionTab(
        entity.projectId,
        projectIntegration.id,
        projectIntegration.id,
    );

    const expressionProps = useMemo(
        () => ({
            additionalTabs: [enterpriseComponentVariablesTab],
            hideEditorButton: true,
            globalExpressionOnly: true,
            doNotEvaluatePreview: true,
        }),
        [enterpriseComponentVariablesTab],
    );

    const constantParameters: ConstantActionConfig[] = useMemo(() => {
        const tempConstantParams = Object.values(
            isPaginatedRequest
                ? projectIntegrationPaginatedActionConstantParamIdToConfig
                : projectIntegrationActionConstantParamsIdToConfig,
        ).filter(
            (param) =>
                projectIntegrationActionState &&
                !param.excludeInActionTypes.includes(projectIntegrationActionState.actionType),
        );

        if (
            projectIntegrationActionState?.actionDefinition.definitionType === ActionDefinitionType.HTTP &&
            projectIntegrationActionState?.actionDefinition.shouldIncludeFileDownloading
        ) {
            tempConstantParams.push(
                ProjectIntegrationActionConstantParams.file_stream,
                ProjectIntegrationActionConstantParams.tonkean_file_name,
                ProjectIntegrationActionConstantParams.tonkean_mime_type,
            );
        }

        return tempConstantParams;
    }, [isPaginatedRequest, projectIntegrationActionState]);

    const projectIntegrationActionAdditionalParameters = useMemo<BaseActionParameter[]>(
        () => projectIntegrationActionState?.parametersDefinition?.parameters || [],
        [projectIntegrationActionState?.parametersDefinition?.parameters],
    );

    const allAdditionalActionParameters = useMemo<BaseActionParameter[]>(() => {
        return [...projectIntegrationActionAdditionalParameters, ...(additionalActionParameters || [])];
    }, [additionalActionParameters, projectIntegrationActionAdditionalParameters]);

    const fieldListParameters = useMemo(() => {
        if (!projectIntegrationActionState) {
            return [];
        }

        return (
            projectIntegrationActionState.parametersDefinition?.parameters.filter(
                (parameter) => parameter.parameterType === ActionParameterType.FIELD_LIST,
            ) || []
        );
    }, [projectIntegrationActionState]);

    // Set the onExitTab callback for a tab based on its dirty state.
    useEffect(() => {
        if (setOnExitTab) {
            setOnExitTab(async () => {
                return formRef?.current?.dirty
                    ? await confirm('You have unsaved changes', 'Are you sure you want to discard those changes?', {
                          warning: true,
                          okLabel: 'Yes',
                      })
                    : true;
            });
        }
    }, [formRef?.current?.dirty, confirm, setOnExitTab]);

    const actionsResponseFieldTab = useCustomRequestAdditionalTab(
        projectIntegrationAction?.actionDefinition?.definitionType || ActionDefinitionType.HTTP,
        allAdditionalActionParameters,
    );

    const additionalTabs = useMemo(() => {
        return [actionsResponseFieldTab, enterpriseComponentVariablesTab];
    }, [actionsResponseFieldTab, enterpriseComponentVariablesTab]);

    const nextPageExpressionProps = useMemo(
        () => ({
            additionalTabs,
            hideEditorButton: true,
            globalExpressionOnly: true,
            doNotEvaluatePreview: true,
        }),
        [additionalTabs],
    );

    const initialValues = useMemo(() => {
        return projectIntegrationActionState
            ? {
                  definition: projectIntegrationActionState.actionDefinition,
                  handleResponse: {
                      ...defaultSingleHandleResponseObject,
                      ...projectIntegrationActionState.responseHandlingDefinition,
                  },
              }
            : {
                  definition: defaultCustomPaginatedHttpActionDefinition,
                  handleResponse: { ...defaultSingleHandleResponseObject },
              };
    }, [projectIntegrationActionState]);

    return (
        <SplitPageTemplate
            breadcrumbs={
                useEntityFetcherBreadcrumbs ? (
                    projectIntegrationAction && (
                        <ProjectIntegrationEntityFetcherHeader
                            entity={entity}
                            projectIntegration={projectIntegration}
                            entitiesSummary={entitiesSummary}
                            defaultMargin={false}
                            tabKeyToOnExit={tabKeyToOnExit}
                            projectIntegrationAction={projectIntegrationAction}
                            {...props}
                        />
                    )
                ) : (
                    <ProjectIntegrationEntityHeaderComponent
                        entity={entity}
                        projectIntegration={projectIntegration}
                        entitiesSummary={entitiesSummary}
                        defaultMargin={false}
                        tabKeyToOnExit={tabKeyToOnExit}
                        {...props}
                    />
                )
            }
            actions={
                <>
                    <TestButton
                        disabled={!isTestModalReady}
                        size={ButtonSize.MEDIUM}
                        onClick={() => {
                            setIsTestModalOpen(true);
                        }}
                        highlighted
                        flex
                    >
                        <SpaceShip />
                        Test
                    </TestButton>

                    <Button
                        disabled={!projectIntegrationActionState || isUpdateActionLoading}
                        size={ButtonSize.MEDIUM}
                        onClick={() => {
                            if (formRef.current) {
                                formRef.current?.submitForm();
                            }
                        }}
                    >
                        Save
                    </Button>
                </>
            }
            leftStickyItems={
                <Tabs value={selectedTab} onChange={setSelectedTab}>
                    <Tab label={ConfigurationTypeTab.REQUEST}>
                        Request Configuration
                        {hasErrorInRequestTab && <InvalidDot />}
                    </Tab>
                    <Tooltip
                        disabled={shouldShowHandleResponseTab}
                        content="Run test is mandatory before configuring Response Handling"
                    >
                        <Tab disabled={!shouldShowHandleResponseTab} label={ConfigurationTypeTab.HANDLE_RESPONSE}>
                            Response Handling
                            {hasErrorInHandleResponseTab && <InvalidDot />}
                        </Tab>
                    </Tooltip>
                </Tabs>
            }
            rightBody={
                <ProjectIntegrationEntityContinuousCollectRightLayout
                    testRuns={testRunsResponse?.entities}
                    isLoadingTestRuns={loading}
                    entity={entity}
                />
            }
            name={tabHeader}
            title={
                <ProjectIntegrationEntityLeftPageGenericHeader
                    title={tabHeader}
                    description={description}
                    error={updateActionError}
                    isLoading={isUpdateActionLoading}
                />
            }
        >
            <LeftPageWrapper>
                <Formik
                    innerRef={formRef}
                    onSubmit={async (values, { resetForm }) => {
                        await updateProjectIntegrationAction(
                            projectIntegrationActionId,
                            projectIntegrationActionState?.displayName,
                            projectIntegrationActionState?.description,
                            values.definition,
                            projectIntegrationActionState?.parametersDefinition,
                            values.handleResponse,
                        );
                        resetForm({ values });
                    }}
                    validationSchema={projectIntegrationEntitySplitPageSchema}
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {({ errors, values, setFieldValue }) => {
                        return (
                            <>
                                <AnimatedSwitch activeLabel={selectedTab}>
                                    <AnimatedSwitchItem label={ConfigurationTypeTab.REQUEST}>
                                        <TabWrapper>
                                            {!errorProjectIntegrationAction && (
                                                <>
                                                    <HttpRequestConfiguration
                                                        isLoading={loadingProjectIntegrationAction}
                                                        errors={errors}
                                                        namePrefix="definition"
                                                        urlHeader={requestUrlHeader}
                                                        expressionProps={expressionProps}
                                                        includePaging={isPaginatedRequest}
                                                        additionalActionParameters={allAdditionalActionParameters}
                                                        hideEnvUrlPrefix={
                                                            (initialValues.definition as HttpRequestDefinition)
                                                                .wasUrlConvertedToEnvUrlAsParameterFormat
                                                        }
                                                        actionType={projectIntegrationActionState?.actionType}
                                                        fieldListParameters={fieldListParameters}
                                                        shouldIncludeFileDownloading={
                                                            (initialValues.definition as HttpRequestDefinition)
                                                                .shouldIncludeFileDownloading
                                                        }
                                                        constantParameters={constantParameters}
                                                    />

                                                    {projectIntegrationActionState &&
                                                        shouldShowPredefinedParameters && (
                                                            <ParametersViewContainer>
                                                                <ProjectIntegrationActionParametersView
                                                                    projectIntegration={projectIntegration}
                                                                    parametersDefinition={
                                                                        projectIntegrationActionState.parametersDefinition
                                                                    }
                                                                    projectIntegrationAction={
                                                                        projectIntegrationActionState
                                                                    }
                                                                    onChange={(parametersDefinition) => {
                                                                        setProjectIntegrationActionState(
                                                                            (currentActionState) => {
                                                                                if (currentActionState) {
                                                                                    return {
                                                                                        ...currentActionState,
                                                                                        parametersDefinition,
                                                                                        actionDefinition:
                                                                                            values.definition,
                                                                                        responseHandlingDefinition:
                                                                                            values.handleResponse,
                                                                                    };
                                                                                }
                                                                            },
                                                                        );
                                                                    }}
                                                                    constantParameters={constantParameters}
                                                                    fieldListParameters={fieldListParameters}
                                                                    shouldShowHeaderButtons={false}
                                                                    isModuleParams={isModuleParams}
                                                                />
                                                            </ParametersViewContainer>
                                                        )}
                                                </>
                                            )}
                                            {errorProjectIntegrationAction && (
                                                <SimpleErrorStateMessage error={errorProjectIntegrationAction} />
                                            )}
                                        </TabWrapper>
                                    </AnimatedSwitchItem>
                                    <AnimatedSwitchItem label={ConfigurationTypeTab.HANDLE_RESPONSE}>
                                        <TabWrapper>
                                            <ProjectIntegrationHandleResponse
                                                isLoading={loadingProjectIntegrationAction}
                                                expressionProps={nextPageExpressionProps}
                                                labelPrefix={labelPrefix}
                                                validationId={selectedTestRunIds[0]}
                                                showFetchEntity={showFetchEntity}
                                                validateActionHandleType={
                                                    ValidateActionHandleType.PROJECT_INTEGRATION_TEST_RUN_ID
                                                }
                                                namePrefix="handleResponse"
                                                projectIntegration={projectIntegration}
                                                projectIntegrationEntity={entity}
                                                entitiesSummary={entitiesSummary}
                                                enableSubEntitiesCollectOption={enableSubEntitiesCollectOption}
                                                handleResponseActionType={handleResponseActionType}
                                            />
                                        </TabWrapper>
                                    </AnimatedSwitchItem>
                                </AnimatedSwitch>
                                {isTestModalReady && (
                                    <Modal size={441} onClose={onCloseModal} open={isTestModalOpen} fixedWidth>
                                        <ProjectIntegrationEntityContinuousCollectTestModal
                                            onClose={onCloseModal}
                                            projectIntegrationId={projectIntegration.id}
                                            projectIntegrationAction={projectIntegrationActionState}
                                            testActionDefinition={values.definition}
                                            testResponseHandlingDefinition={values.handleResponse}
                                            apiBaseUrl={projectIntegrationBaseUrl.apiBaseUrl}
                                            incomingWebhookUrl={projectIntegrationBaseUrl.incomingWebhookUrl}
                                            shouldHidePaginatedTest={!isPaginatedRequest}
                                            additionalActionParameters={additionalActionParameters}
                                        />
                                    </Modal>
                                )}
                            </>
                        );
                    }}
                </Formik>
            </LeftPageWrapper>
        </SplitPageTemplate>
    );
};

export default ProjectIntegrationEntityGenericSplitPageWithRequest;
