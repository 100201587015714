import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import SolutionSiteCategoricalAggregateFieldChartWidget from './SolutionSiteCategoricalAggregateFieldChartWidget';
import SolutionSiteCategoricalGroupByGlobalFieldFieldChartWidget from './SolutionSiteCategoricalGroupByGlobalFieldFieldChartWidget';
import SolutionSiteFieldChartHeader from './SolutionSiteFieldChartHeader';
import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';
import SolutionSiteHistoricalGlobalFieldFieldChartWidget from './SolutionSiteHistoricalGlobalFieldFieldChartWidget';
import { useSolutionSiteContext } from '../../hooks';
import { ItemWidget } from '../../WidgetModule';
import type { SolutionSitePageWidgetProps } from '../../WidgetModule';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { ChartDataType } from '@tonkean/infrastructure';
import type { FieldDefinition, Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SolutionSiteFieldChartWidget: React.FC<
    SolutionSitePageWidgetProps<SolutionSiteFieldChartWidgetConfiguration>
> = ({ permission, widget }) => {
    const projectManager = useAngularService('projectManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const { solutionSite } = useSolutionSiteContext();
    const [isWorkerEnabled, setIsWorkerEnabled] = useState(true);

    const [entityVersionTypeParam] = useGetStateParams<[WorkflowVersionType]>('env');
    const entityVersionType = entityVersionTypeParam || WorkflowVersionType.PUBLISHED;

    const selectedGroup = useMemo<Group | undefined>(() => {
        return widget.configuration.groupId && projectManager.groupsMap[widget.configuration.groupId];
    }, [widget.configuration.groupId, projectManager.groupsMap]);

    const { data: globalFieldDefinitions, loading: loadingGlobalFieldDefinitions } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const configuredFieldDefinition = useMemo<FieldDefinition | undefined>(() => {
        const selectedFields =
            widget.configuration.chartDataType === ChartDataType.CATEGORICAL
                ? widget.configuration.categoricalFields
                : widget.configuration.fields;
        return globalFieldDefinitions?.entities?.find((fieldDefinition) =>
            Boolean(selectedFields?.[fieldDefinition.id]),
        );
    }, [
        globalFieldDefinitions?.entities,
        widget.configuration.categoricalFields,
        widget.configuration.chartDataType,
        widget.configuration.fields,
    ]);

    const getGroupIdFromWorkflowVersion = useCallback(
        async (workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) => {
            return (
                workflowVersionManager.workflowVersionIdToWorkflowVersionMap[workflowVersionId] ||
                (await workflowVersionManager.getFromServerAndCacheWorkflowVersion(workflowVersionId))
            ).groupId;
        },
        [workflowVersionManager],
    );

    const checkIfWorkerEnabled = useCallback(
        async (groupId: TonkeanId<TonkeanType.GROUP>) => {
            const group =
                groupInfoManager.getGroupByIdFromCache(groupId) || (await groupInfoManager.getGroupById(groupId));

            setIsWorkerEnabled(
                entityVersionType === WorkflowVersionType.DRAFT ? group.buildEnvironmentEnabled : group.workerEnabled,
            );
        },
        [entityVersionType, groupInfoManager],
    );

    useEffect(() => {
        if (widget.configuration.groupId && widget.configuration.chartDataType === ChartDataType.CATEGORICAL) {
            checkIfWorkerEnabled(widget.configuration.groupId);
        } else {
            if (configuredFieldDefinition) {
                getGroupIdFromWorkflowVersion(configuredFieldDefinition?.workflowVersionId).then((groupId) => {
                    checkIfWorkerEnabled(groupId);
                });
            }
        }
    }, [
        widget.configuration.groupId,
        entityVersionType,
        configuredFieldDefinition,
        workflowVersionManager.workflowVersionIdToWorkflowVersionMap,
        widget.configuration.chartDataType,
        checkIfWorkerEnabled,
        getGroupIdFromWorkflowVersion,
    ]);

    return (
        <ItemWidget
            permission={permission}
            headerTitle={
                <SolutionSiteFieldChartHeader environmentIsActive={isWorkerEnabled} title={widget.displayName} />
            }
        >
            {widget.configuration.chartDataType === ChartDataType.CATEGORICAL ? (
                widget.configuration.definition && selectedGroup ? (
                    <SolutionSiteCategoricalAggregateFieldChartWidget
                        widgetId={widget.id}
                        configuration={widget.configuration}
                        entityVersionType={entityVersionType}
                        selectedGroup={selectedGroup}
                    />
                ) : (
                    <SolutionSiteCategoricalGroupByGlobalFieldFieldChartWidget
                        solutionSite={solutionSite}
                        configuration={widget.configuration}
                        entityVersionType={entityVersionType}
                    />
                )
            ) : (
                <SolutionSiteHistoricalGlobalFieldFieldChartWidget
                    solutionSite={solutionSite}
                    configuration={widget.configuration}
                    entityVersionType={entityVersionType}
                />
            )}
        </ItemWidget>
    );
};

export default SolutionSiteFieldChartWidget;
