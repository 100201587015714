import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function NlpProcessorLogicConfigurationCtrl(
    $scope,
    projectManager,
    utils,
    tonkeanUtils,
    authenticationService,
    customTriggerManager,
    consts,
) {
    const ctrl = this;
    const logicBlockTypes = consts.getLogicBlockTypes();
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.tonkeanUtils = tonkeanUtils;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,

        inputExpression: null,
        evaluatedInputExpression: null,
        textAnalysisType: 'MANUAL',
        selectedTrainingSetId: null,
        useDefaultBranch: false,
        useAnyOfTheRestBranch: false,
        branches: [],
        alreadyUsedModelIds: [],

        hasPlaceholderBranches: false,
        branchesLoading: false,

        nlpMatchTypes: {
            ALL_MATCHING_BRANCHES: {
                key: 'ALL_MATCHING_BRANCHES',
                label: 'All matching Branches',
            },
            SINGLE_HIGHEST_MATCHING_BRANCH: {
                key: 'SINGLE_HIGHEST_MATCHING_BRANCH',
                label: 'Best matched Branch',
            },
        },

        selectedMatchType: null,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.addBranch = function () {
        if (ctrl.createNewLogicCallback) {
            ctrl.createNewLogicCallback({
                params: {
                    parentNode: $scope.data.configuredLogic,
                    disabled: projectManager.groupsMap[$scope.data.groupId].workerEnabled,
                    enricher: consts.getAddNlpBranchBlockCreateAfterOption().triggerParamsEnricher,
                },
            });
        }
    };

    $scope.onBranchDefinitionChanged = function (branch, definition) {
        const foundBranch = utils.findFirst(
            $scope.data.branches,
            (branchToUpdate) => branchToUpdate.customTriggerId === branch.customTriggerId,
        );
        if (foundBranch) {
            foundBranch.definition = definition;
            $scope.onActionsChanged(true, true);
        }
    };

    $scope.onBranchNameChanged = function (branchId, modelName, manuallyChangedName) {
        const foundBranch = $scope.data.branches.find((branchToUpdate) => branchToUpdate.customTriggerId === branchId);
        const foundBranchCustomTrigger = $scope.data.configuredLogic.impacts.find(
            (impact) => impact.node.id === branchId,
        );

        if (manuallyChangedName && foundBranchCustomTrigger) {
            foundBranchCustomTrigger.node.manuallyChangedName = manuallyChangedName;
        }

        // we want to update the branch name to be the same as the model name just if the user did not update it manually.
        if (
            foundBranch &&
            foundBranchCustomTrigger &&
            !foundBranchCustomTrigger.node.manuallyChangedName &&
            modelName
        ) {
            foundBranch.displayName = modelName;
            $scope.onActionsChanged(true, true);
        }
    };

    $scope.onInputExpressionExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.inputExpression = originalExpression;
        $scope.data.evaluatedInputExpression = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onMatchTypeChanged = function (matchTypeKey) {
        $scope.data.selectedMatchType = $scope.data.nlpMatchTypes[matchTypeKey];
        $scope.onActionsChanged(true);
    };

    $scope.onUseDefaultBranchChanged = function (turnedOn, branchTypes) {
        branchTypes.forEach((branchType) => {
            if (branchType === logicBlockTypes.NLP_BRANCH_DEFAULT.type) {
                $scope.data.useDefaultBranch = turnedOn;
                $scope.data.useDefaultBranchIsLoading = true;
            } else {
                $scope.data.useAnyOfTheRestBranch = turnedOn;
                $scope.data.useAnyOfTheRestBranchIsLoading = true;
            }
        });

        toggleDefaultBranchTriggerHiddenAndDisabled(!turnedOn, branchTypes).finally(() => {
            branchTypes.forEach((branchType) => {
                if (branchType === logicBlockTypes.NLP_BRANCH_DEFAULT.type) {
                    $scope.data.useDefaultBranchIsLoading = false;
                } else {
                    $scope.data.useAnyOfTheRestBranchIsLoading = false;
                }
            });
        });
    };

    function getBranchTriggerByBranchType() {
        return Object.fromEntries(
            $scope.data.configuredLogic.impacts?.map((impact) => [impact.node.customTriggerType, impact]),
        );
    }

    function toggleDefaultBranchTriggerHiddenAndDisabled(turningOff, branchTypes) {
        const branchTriggerByType = getBranchTriggerByBranchType();
        const triggersToUpdate = branchTypes.map((branchType) => branchTriggerByType[branchType]).filter(Boolean);
        const triggersTypesToCreate = branchTypes.filter(
            (branchType) => !turningOff && !branchTriggerByType[branchType],
        );

        return Promise.all([
            ...triggersToUpdate
                .filter((triggerToUpdate) => triggerToUpdate.node.isHidden !== turningOff)
                .map((triggerToUpdate) =>
                    customTriggerManager.updateCustomTriggerIsHidden(
                        $scope.data.groupId,
                        triggerToUpdate.node.id,
                        turningOff,
                    ),
                ),
            ,
            triggersTypesToCreate.length
                ? $scope.onActionsChanged(true, false, triggersTypesToCreate)
                : Promise.resolve(),
        ]);
    }

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic, saveBranches, branchTypesToCreate = []) {
        if (ctrl.onActionsChanged) {
            const logicType = $scope.data.configuredLogic.node.customTriggerType;
            const logicConfigurationObject = {
                childrenCustomTriggersToCreate: [],
                actions: [
                    {
                        type: logicType,
                        definition: {
                            inputExpression: $scope.data.inputExpression,
                            evaluatedInputExpression: $scope.data.evaluatedInputExpression,
                            nlpMatchType: $scope.data.selectedMatchType ? $scope.data.selectedMatchType.key : null,
                            textAnalysisType: $scope.data.textAnalysisType,
                            trainingSetId: $scope.data.selectedTrainingSetId,
                        },
                    },
                ],
            };

            // Branches
            $scope.data.hasPlaceholderBranches = false;

            let loadingBranches;
            if (saveBranches) {
                loadingBranches = true;
                const childCustomTriggers = $scope.data.branches.map((branch) => {
                    let customTrigger = branch;
                    if (branch.customTriggerId) {
                        customTrigger = customTriggerManager.getCachedCustomTrigger(
                            $scope.data.workflowVersionId,
                            branch.customTriggerId,
                        );
                    }

                    customTrigger.displayName = branch.displayName;
                    customTrigger.type = logicBlockTypes.NLP_BRANCH.type;
                    customTrigger.customTriggerActions = [
                        {
                            type: logicBlockTypes.NLP_BRANCH.type,
                            definition: branch.definition,
                            customTriggerActionType: logicBlockTypes.NLP_BRANCH.type,
                            customTriggerActionDefinition: branch.definition,
                        },
                    ];

                    return customTrigger;
                });

                logicConfigurationObject.childrenCustomTriggersToCreate = childCustomTriggers.filter(
                    (trigger) => !trigger.id,
                );
                logicConfigurationObject.childrenCustomTriggersToUpdate = childCustomTriggers.filter(
                    (trigger) => trigger.id,
                );

                $scope.data.hasPlaceholderBranches =
                    logicConfigurationObject.childrenCustomTriggersToCreate &&
                    logicConfigurationObject.childrenCustomTriggersToCreate.length;
            }

            logicConfigurationObject.childrenCustomTriggersToCreate.push(
                ...branchTypesToCreate.map((branchType) => {
                    return {
                        displayName: logicBlockTypes[branchType].displayName,
                        type: branchType,
                    };
                }),
            );

            return ctrl.onActionsChanged({ definition: logicConfigurationObject, shouldSaveLogic }).then(() => {
                if (loadingBranches) {
                    loadingBranches = false;
                    initializeEditMode();
                    $scope.onActionsChanged(false);
                }
            });
        }
    };

    /**
     * Occurs on selection of radio button value.
     */
    $scope.selectTextAnalysisType = function (selectedType) {
        $scope.data.textAnalysisType = selectedType;

        if (selectedType === 'MANUAL') {
            $scope.data.selectedTrainingSetId = null;
            $scope.onUseDefaultBranchChanged(false, [logicBlockTypes.NLP_BRANCH_ANY_OF_THE_REST.type]);
        }

        if (selectedType === 'TRAINING_SET') {
            $scope.onUseDefaultBranchChanged(true, [
                logicBlockTypes.NLP_BRANCH_DEFAULT.type,
                logicBlockTypes.NLP_BRANCH_ANY_OF_THE_REST.type,
            ]);
        }
    };

    /**
     * Occurs on selection of a training set
     */
    $scope.onTrainingSetSelected = function (selectedTrainingSetId) {
        $scope.data.selectedTrainingSetId = selectedTrainingSetId;
        $scope.onActionsChanged(true);
    };

    function initializeEditMode() {
        let customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            $scope.data.configuredLogic.node.customTriggerType,
        );
        customTriggerAction = customTriggerAction ? customTriggerAction : { customTriggerActionDefinition: {} };

        // input expression
        const definition = customTriggerAction.customTriggerActionDefinition;
        $scope.data.inputExpression = definition.inputExpression;
        $scope.data.evaluatedInputExpression = definition.evaluatedInputExpression;

        // Match type option
        $scope.data.selectedMatchType = definition.nlpMatchType
            ? $scope.data.nlpMatchTypes[definition.nlpMatchType]
            : $scope.data.nlpMatchTypes.ALL_MATCHING_BRANCHES;

        // Whether the NLP is manual or by Training Set
        $scope.data.textAnalysisType = definition.textAnalysisType || $scope.data.textAnalysisType;

        // Selected Training Set
        $scope.data.selectedTrainingSetId = definition.trainingSetId;

        // Branches
        if ($scope.data.configuredLogic.impacts) {
            // Monitor replies
            $scope.data.useDefaultBranch = !!$scope.data.configuredLogic.impacts.filter(
                (impact) =>
                    impact.node.customTriggerType === logicBlockTypes.NLP_BRANCH_DEFAULT.type && !impact.node.isHidden,
            ).length;

            $scope.data.useAnyOfTheRestBranch = !!$scope.data.configuredLogic.impacts.filter(
                (impact) =>
                    impact.node.customTriggerType === logicBlockTypes.NLP_BRANCH_ANY_OF_THE_REST.type &&
                    !impact.node.isHidden,
            ).length;

            $scope.data.branches = $scope.data.configuredLogic.impacts
                .filter((impact) => impact.node.customTriggerType === logicBlockTypes.NLP_BRANCH.type)
                .map((impact) => {
                    const branchTriggerAction = getFirstCustomTriggerAction(
                        impact.node.customTriggerActions,
                        impact.node.customTriggerType,
                    );
                    return {
                        customTriggerId: impact.node.id,
                        displayName: impact.node.displayName,
                        definition: branchTriggerAction && branchTriggerAction.customTriggerActionDefinition,
                    };
                });

            $scope.data.alreadyUsedModelIds = $scope.data.configuredLogic.impacts
                .map((impact) => impact.node)
                .filter((child) => child.customTriggerType === logicBlockTypes.NLP_BRANCH.type)
                .filter((branchChild) => branchChild?.customTriggerActions?.[0]?.customTriggerActionDefinition)
                .map(
                    (branchChild) =>
                        branchChild.customTriggerActions[0].customTriggerActionDefinition.trainingSetModelId,
                );
        } else {
            $scope.data.branches = [];
            $scope.addBranch();

            $scope.data.useDefaultBranch = false;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('NlpProcessorLogicConfigurationCtrl', lateConstructController(NlpProcessorLogicConfigurationCtrl));
