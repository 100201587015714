import template from './tnkSimpleNameEditor.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSimpleNameEditor', {
    bindings: {
        displayName: '<',
        itemId: '<',
        isDisabled: '<',
        disabledTooltip: '<',
        isWorkflowFolderHidden: '<',
        onSave: '&',
    },
    template,
    controller: 'SimpleNameEditor',
});
