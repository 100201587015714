import React, { useCallback, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import FormSettingsOptionalSubTitle from './FormSettingsOptionalSubTitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { SavingIndicator, useUUID } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import type { Form, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    margin-top: 40px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 12px;

    display: flex;
    align-items: flex-end;
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    form: Form;
    onFormChanged: Dispatch<SetStateAction<Form>>;
}

const FormSettingsSubtitle: React.FC<Props> = ({ groupId, workflowVersionId, form, onFormChanged }) => {
    const { editedSections, setSectionLoading, isEditable } = useContext(FormBuilderContext);

    const onChange = useCallback(
        (originalExpression: string, evaluatedExpression: string, expression?: TonkeanExpressionDefinition) => {
            if (
                originalExpression !== form?.descriptionExpression?.originalExpression ||
                evaluatedExpression !== form?.descriptionExpression?.evaluatedExpression
            ) {
                onFormChanged((prevFormValues) => ({
                    ...prevFormValues,
                    descriptionExpression: {
                        originalExpression,
                        evaluatedExpression,
                        isStripHtmlDisabled: expression?.isStripHtmlDisabled ?? false,
                    },
                }));
                setSectionLoading(FormBuilderSections.Subtitle);
            }
        },
        [form, onFormChanged, setSectionLoading],
    );

    const id = useUUID();
    const key = form.id ?? id;

    return (
        <Wrapper>
            <TitleWrapper>
                <FormSettingsSectionHeaderTitle>Form Subtitle</FormSettingsSectionHeaderTitle>{' '}
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.Subtitle]} />
            </TitleWrapper>
            <TonkeanExpression
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                savedOriginalExpression={form?.descriptionExpression?.originalExpression || ''}
                savedEvaluatedExpression={form?.descriptionExpression?.evaluatedExpression || ''}
                disabled={!isEditable}
                onTonkeanExpressionChanged={onChange}
                key={key}
                includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />
        </Wrapper>
    );
};

export default FormSettingsSubtitle;
