import LogicalOperatorBase from './LogicalOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const EqualsTypeMatchingSymbol = Symbol();

class EqualsOperator extends LogicalOperatorBase {
    override readonly key = OperatorKey.EQUALS;
    override readonly sign = '=';
    override readonly displayName = 'Equals';
    override readonly description = 'Will return true if the left hand column is equal to the right hand column.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: undefined,
            typeMatchingSymbol: EqualsTypeMatchingSymbol,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: undefined,
            typeMatchingSymbol: EqualsTypeMatchingSymbol,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}

export default EqualsOperator;
