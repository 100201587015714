import { useAngularService } from 'angulareact';
import React, { useContext, useMemo, useState } from 'react';
import type { Element } from 'slate';
import styled, { css } from 'styled-components';

import ExpressionEditorTagWrapper from './ExpressionEditorTagWrapper';
import ExpressionEditorVariablePopover from './ExpressionEditorVariablePopover';
import ExpressionEditorContext from '../../entities/ExpressionEditorContext';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { Popover } from '@tonkean/infrastructure';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';

const VariableTag = styled(ExpressionEditorTagWrapper)<{ bordered: boolean; dashed: boolean }>`
    background: #e2ebf5;

    ${({ bordered }) =>
        bordered &&
        css`
            border: 1px solid #d8d8d8;
        `};

    ${({ dashed }) =>
        dashed &&
        css`
            border: 1px dashed #3fa7b8;
        `};
`;

const Icon = styled.div`
    display: inline-block;
    vertical-align: text-bottom;
    height: 14px;
    width: 12px;
    background-size: contain !important;
    margin-right: 4px;
`;

const ProjectIntegrationIconStyled = styled(ProjectIntegrationIcon).attrs({ height: 14, width: 12 })`
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 4px;
`;

const Name = styled.div`
    display: inline;
    vertical-align: baseline;
`;

interface Props {
    variableContent: string;
    insideFormula?: boolean;
    elementToDelete?: Element;

    onChange(variableContent: string): void;
}

const ExpressionEditorVariableTag: React.FC<Props> = ({
    variableContent,
    insideFormula = false,
    elementToDelete,
    onChange,
}) => {
    const [open, setOpen] = useState(false);

    const { translateVariableLabel, getVariableIconClassName, getProjectIntegrationTypeAndId } =
        useContext(ExpressionEditorContext);

    const [variableId, ...pipeSections] = useMemo(() => {
        return variableContent.split('|').map((item) => item.trim()) as [string, ...string[]];
    }, [variableContent]);

    const { customTriggerId, groupId, workflowVersionId } = useContext(ExpressionEditorContext);
    const showDefaultValue = useFeatureFlag('tonkean_feature_show_default_value') as boolean;

    const customFieldsManager = useAngularService('customFieldsManager');
    const fieldDefinition = useMemo(() => {
        if (
            !variableId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION]) ||
            !workflowVersionId
        ) {
            return;
        }

        return customFieldsManager.getFieldDefinitionFromCachesById(workflowVersionId, variableId);
    }, [customFieldsManager, variableId, workflowVersionId]);

    const pipes = useMemo(() => {
        const pipeEntries = pipeSections.map((pipeString) => {
            const [key, value] = pipeString.split(':').map((item) => item.trim());
            return [key, value] as const;
        });

        return Object.fromEntries(pipeEntries);
    }, [pipeSections]);

    const name = useMemo(() => {
        return translateVariableLabel(variableId);
    }, [translateVariableLabel, variableId]);

    const iconClass = useMemo(() => {
        return getVariableIconClassName?.(variableId, name);
    }, [getVariableIconClassName, name, variableId]);

    const projectIntegrationData = useMemo(() => {
        return getProjectIntegrationTypeAndId?.(variableId);
    }, [getProjectIntegrationTypeAndId, variableId]);

    const updatePipes = (partialPipes: Partial<typeof pipes>) => {
        const newPipes: Record<any, any> = {
            ...pipes,
            ...partialPipes,
        };

        const newPipeStrings = Object.entries(newPipes)
            .filter(([, pipeValue]) => pipeValue !== undefined)
            .map(([pipeName, pipeValue]) => `${pipeName}:${pipeValue}`);
        const newVariableParts = [variableId, ...newPipeStrings];
        const newVariableString = newVariableParts.join('|');
        onChange(newVariableString);
    };

    return (
        <Popover
            show={open}
            restoreFocusWhenClosed={false}
            content={
                <ExpressionEditorVariablePopover
                    customTriggerId={customTriggerId}
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    showDefaultValue={showDefaultValue}
                    fieldDefinition={fieldDefinition}
                    defaultValue={pipes.D}
                    flowItemParent={pipes.P}
                    onDefaultValueChange={(defaultValue) => {
                        updatePipes({ D: defaultValue });
                    }}
                    onFlowItemParentChange={(flowItemParent) => {
                        updatePipes({ P: flowItemParent });
                    }}
                />
            }
            onClose={() => {
                setOpen(false);
            }}
        >
            <VariableTag
                dashed={!!pipes.D || !!pipes.P}
                bordered={insideFormula}
                thin={insideFormula}
                active={!open && !insideFormula}
                onClick={() =>
                    setOpen(
                        (customTriggerId !== undefined && customTriggerId !== '') ||
                            showDefaultValue ||
                            (fieldDefinition !== undefined && fieldDefinition != null),
                    )
                }
                elementToDelete={elementToDelete}
            >
                {projectIntegrationData?.integrationType ? (
                    <ProjectIntegrationIconStyled
                        projectIntegrationId={projectIntegrationData.projectIntegrationId}
                        integrationType={projectIntegrationData.integrationType}
                    />
                ) : (
                    iconClass && <Icon className={iconClass} />
                )}

                <Name>{name}</Name>
            </VariableTag>
        </Popover>
    );
};

export default ExpressionEditorVariableTag;
