import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useFetchProjectManager = () => {
    const projectManager = useAngularService('projectManager');

    const getGroupsMap = useCallback(
        (groupIds: TonkeanId<TonkeanType.GROUP>[]) => {
            return groupIds.map((groupId) => projectManager.groupsMap[groupId]);
        },
        [projectManager.groupsMap],
    );

    return { getGroupsMap };
};

export default useFetchProjectManager;
