import { useCallback, useContext, useMemo } from 'react';

import InterfaceSubmitValidationContext from '../../utils/InterfaceSubmitValidationContext';
import type QuestionWidgetConfiguration from '../QuestionWidget/QuestionWidgetConfiguration';
import type RichTextEditorInputWidgetConfiguration from '../RichTextEditorInputWidget/RichTextEditorInputWidgetConfiguration';

import { Validation, type Widget, type WidgetBase } from '@tonkean/tonkean-entities';

const useWidgetsValidationLogic = (
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration> | Widget<QuestionWidgetConfiguration>,
) => {
    const { touchedWidgetsIds, setTouchedWidgetId, forceShowValidation, validationResult } = useContext(
        InterfaceSubmitValidationContext,
    );

    const markAsTouched = useCallback(() => {
        setTouchedWidgetId(widget.id);
    }, [widget.id, setTouchedWidgetId]);

    const userHasInteracted = useMemo(() => {
        return forceShowValidation || touchedWidgetsIds.includes(widget.id);
    }, [forceShowValidation, touchedWidgetsIds, widget.id]);

    // Is any of the validation results are not valid
    const optionalFailedValidationResult = validationResult[widget.id]
        ? validationResult[widget.id]?.find((currentValidation) => currentValidation.validation === Validation.INVALID)
        : undefined;

    const validationFailed = optionalFailedValidationResult
        ? optionalFailedValidationResult?.validation === Validation.INVALID
        : false;

    const hasValidationError = validationFailed && userHasInteracted;

    return { hasValidationError, markAsTouched, optionalFailedValidationResult };
};

export default useWidgetsValidationLogic;
