import template from './formPageState.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkFormPageState', {
    bindings: {
        form: '<',
        project: '<',
        loadForm: '<',
        addUnderInitiative: '<',
        collectionForm: '<',
        group: '<',
        itemInterfaceId: '<',
    },
    template,
    controller: 'FormPageStateCtrl',
});
