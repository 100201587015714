function ActivityDigestEmailSettingsCtrl(
    $scope,
    $uibModalInstance,
    $timeout,
    $q,
    tonkeanService,
    utils,
    projectManager,
    authenticationService,
    communicationIntegrationsService,
    groupInfoManager,
) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.cis = communicationIntegrationsService;

    groupInfoManager.getGroups();

    $scope.data = {
        recurrenceType: 'None',
        loading: false,
        error: null,

        loadingSubscribedGroups: false,
        errorLoadingSubscribedGroups: null,
        subscribedGroups: [],
        subscribedGroupsSet: {},

        loadingGroupSubscriptionMap: {},
        errorGroupSubscriptionMap: {},
    };

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        const projectContext = projectManager.getProjectContext();

        if (
            projectContext.shouldSendActivityDigestEmail === 'ON' ||
            (projectContext.shouldSendActivityDigestEmail === 'SEND_IF_NO_SLACK' &&
                !$scope.cis.anyIntegrationSupports($scope.cis.features.notifications))
        ) {
            $scope.data.recurrenceType = projectContext.activityDigestEmailRecurrenceType;
        }

        $scope.reloadSubscribedGroups();
    };

    /**
     * Reloads the subscribed groups.
     */
    $scope.reloadSubscribedGroups = function () {
        $scope.data.loadingSubscribedGroups = true;
        $scope.data.errorLoadingSubscribedGroups = null;

        tonkeanService
            .getActivityDigestSubscribedGroups(projectManager.project.id, authenticationService.currentUser.id)
            .then((data) => {
                $scope.data.subscribedGroups = data.subscribedGroups;
                $scope.data.subscribedGroupsSet = utils.arrayToSet($scope.data.subscribedGroups);
            })
            .catch(() => {
                $scope.data.errorLoadingSubscribedGroups = 'Error trying to get activity preferences information.';
            })
            .finally(() => {
                $scope.data.loadingSubscribedGroups = false;
            });
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Occurs once you select a type of recurrence for the activity digest email.
     */
    $scope.onRecurrenceTypeSelected = function (onClickParam) {
        $scope.data.recurrenceType = onClickParam;
        $scope.data.loading = true;
        $scope.data.error = null;

        let shouldSendActivityDigestEmail = true;
        if ($scope.data.recurrenceType === 'None') {
            shouldSendActivityDigestEmail = false;
        }

        // Updating the person saved in cache, so it would be updated without a refresh.
        const projectContext = projectManager.getProjectContext();
        projectContext.shouldSendActivityDigestEmail = shouldSendActivityDigestEmail ? 'ON' : 'OFF';
        projectContext.activityDigestEmailRecurrenceType = $scope.data.recurrenceType;

        tonkeanService
            .updateActivityDigestEmailPreferences(
                projectManager.project.id,
                authenticationService.currentUser.id,
                shouldSendActivityDigestEmail ? 'ON' : 'OFF',
                shouldSendActivityDigestEmail ? $scope.data.recurrenceType : null,
            )
            .then(showSaved)
            .catch(() => {
                $scope.data.error = 'There was an error trying to update activity notifications settings.';
            })
            .finally(() => {
                $scope.data.loading = false;
            });
    };

    /**
     * Toggle the subscription of the user to the group.
     */
    $scope.toggleSubscribeToGroup = function (group) {
        $scope.data.loadingGroupSubscriptionMap[group.id] = true;
        $scope.data.errorGroupSubscriptionMap[group.id] = null;

        let subscriptionChangePromise = $q.resolve();
        if ($scope.data.subscribedGroupsSet[group.id]) {
            subscriptionChangePromise = tonkeanService.activityDigestUnsubscribeFromGroup(group.id);
        } else {
            subscriptionChangePromise = tonkeanService.activityDigestSubscribeGroup(group.id);
        }

        $scope.data.subscribedGroupsSet[group.id] = !$scope.data.subscribedGroupsSet[group.id];

        subscriptionChangePromise
            .then(showSaved)
            .catch(() => {
                $scope.data.errorGroupSubscriptionMap[group.id] =
                    'There was an error trying to update activity notifications settings.';
            })
            .finally(() => {
                $scope.data.loadingGroupSubscriptionMap[group.id] = false;
                if ($scope.pm.activityDigestSubscribedGroups) {
                    $scope.pm.activityDigestSubscribedGroups[group.id] = $scope.data.subscribedGroupsSet[group.id];
                }
            });
    };

    function showSaved() {
        $scope.data.saved = true;
        $timeout(function () {
            $scope.data.saved = false;
        }, 2000);
    }

    $scope.init();
}

angular.module('tonkean.app').controller('ActivityDigestEmailSettingsCtrl', ActivityDigestEmailSettingsCtrl);
