import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ThreadReplyCtrl($scope, $q, utils, customTriggerManager, consts, integrations, projectManager) {
    const ctrl = this;
    const logicBlockTypes = consts.getLogicBlockTypes();

    $scope.data = {
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        definition: ctrl.definition,
        storageOptions: [],
        monitorRepliesOnOriginalMessage: ctrl.monitorRepliesOnOriginalMessage,
        selectedStorage: {},
        groupId: ctrl.groupId,
    };

    ctrl.$onInit = function () {
        // Setting monitor thread reply toggle
        if ($scope.data.configuredLogic.impacts) {
            // Monitor replies
            isMonitorThread();
        }

        const supportsUploadingIntegration = Object.keys(integrations.getSupportedUploadingStorageIntegrations());

        $scope.data.storageOptions = projectManager.project.integrations
            .filter((integration) => supportsUploadingIntegration.includes(integration.integrationType))
            .map((integration) => {
                return {
                    projectIntegrationId: integration.id,
                    displayName: integration.displayName,
                };
            });

        $scope.data.selectedStorage = utils.findFirst(
            $scope.data.storageOptions,
            (storageOption) =>
                storageOption.projectIntegrationId === $scope.data.definition.attachmentsStorageProjectIntegrationId,
        );
    };

    /**
     * When user checks\unchecks the monitor replies checkbox
     */
    $scope.onMonitorRepliesChanged = function (newState) {
        $scope.data.monitorRepliesIsLoading = true;
        $scope.data.monitorRepliesOnOriginalMessage.isActive = newState;

        let promise = $q.resolve();
        promise = toggleMonitorRepliesTriggerIsDisabled(!newState, true);

        promise.finally(() => {
            $scope.data.monitorRepliesIsLoading = false;
        });
    };

    function toggleMonitorRepliesTriggerIsDisabled(newValue, createIfMissing) {
        let promise = $q.resolve();
        const monitorRepliesTrigger =
            $scope.data.configuredLogic.impacts &&
            $scope.data.configuredLogic.impacts.find(
                (impact) => impact.node.customTriggerType === logicBlockTypes.BOT_THREAD_REPLY.type,
            );
        // turning toggle off/on disable / enable the action
        if (monitorRepliesTrigger) {
            promise = customTriggerManager.updateCustomTriggerDisableMode(
                $scope.data.groupId,
                monitorRepliesTrigger.node.id,
                newValue,
            );
        } else if (createIfMissing) {
            const logicConfigurationObject = {
                actions: [],
            };

            if (!logicConfigurationObject.childrenCustomTriggersToCreate) {
                logicConfigurationObject.childrenCustomTriggersToCreate = [];
            }
            logicConfigurationObject.childrenCustomTriggersToCreate.push({
                displayName: logicBlockTypes.BOT_THREAD_REPLY.displayName,
                type: logicBlockTypes.BOT_THREAD_REPLY.type,
            });

            promise = ctrl.onToggled({
                shouldSaveLogic: true,
                childrenCustomTriggersToCreate: logicConfigurationObject.childrenCustomTriggersToCreate,
            });
        }
        return promise;
    }

    $scope.attachmentStorageSelected = function () {
        $scope.data.definition.attachmentsStorageProjectIntegrationId =
            $scope.data.selectedStorage.projectIntegrationId;
        ctrl.onToggled({ shouldSaveLogic: true, createMonitorReplyTrigger: false });
    };

    function isMonitorThread() {
        $scope.data.monitorRepliesOnOriginalMessage.isActive = !!$scope.data.configuredLogic.impacts.filter(
            (impact) =>
                impact.node.customTriggerType === logicBlockTypes.BOT_THREAD_REPLY.type && !impact.node.disabled,
        ).length;
    }
}

export default angular.module('tonkean.app').controller('ThreadReplyCtrl', lateConstructController(ThreadReplyCtrl));
