import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import LineItemSummaryFooter from './LineItemSummaryFooter';
import WidgetRequiredIndication from '../../../components/WidgetRequiredIndication';
import type { ItemInterfacePermission } from '../../../entities';
import { useIsEnvironmentActive } from '../../../hooks';
import InterfaceSubmitValidationContext from '../../../utils/InterfaceSubmitValidationContext';
import { ItemWidget, ItemWidgetHeaderTitle } from '../../../WidgetModule';
import { ItemsGridWithValidation } from '../../CommonWidgetComponents';
import InnerItemsItemWidgetHeaderActions from '../../InnerItemsWidgetModule/components/InnerItemsItemWidgetHeaderActions';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';
import useFieldDefinitionKeyToSettings from '../hooks/useFieldDefinitionKeyToSettings';
import useFieldDefinitions from '../hooks/useFieldDefinitions';
import useFieldsToShow from '../hooks/useFieldsToShow';
import useGridDataIsLoading from '../hooks/useGridDataIsLoading';
import useHandleCreateWrapper from '../hooks/useHandleCreateWrapper';
import useHandleUpdateWrapper from '../hooks/useHandleUpdateWrapper';
import useInitialExpressionValues from '../hooks/useInitialExpressionValues';
import useItemsGridCrudHandlers from '../hooks/useItemsGridCrudHandlers';
import useItemsValuesForCalculations from '../hooks/useItemsValuesForCalculations';
import useLineItemPermission from '../hooks/useLineItemPermission';
import useReloadInitiatives from '../hooks/useReloadInitiatives';
import useRequiredFields from '../hooks/useRequiredFields';
import useValidations from '../hooks/useValidations';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    NoItemEmptyState,
    useItemInterfaceContext,
    useDebouncedState,
    useFastReload,
    useInitiatives,
    useIsMobile,
    DataNotUpToDateIndicator,
} from '@tonkean/infrastructure';
import { useItemsGridRowsManager } from '@tonkean/items-grid';
import type {
    ActionInfo,
    FieldDefinitionKey,
    Group,
    Initiative,
    ItemInterfaceHeaderThemeConfiguration,
    ItemInterfaceWidget,
    Project,
    TonkeanId,
    WorkflowVersion,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { ItemInterfaceWidgetConfigurationDisplayMode } from '@tonkean/tonkean-entities';

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

interface Props {
    permission: ItemInterfacePermission;
    groupId: Group['id'] | undefined;
    initiative: Initiative | undefined;
    initiativeInitialLoading: boolean;
    projectId: Project['id'];
    widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>;
    theme: ItemInterfaceHeaderThemeConfiguration;
    workflowVersion: WorkflowVersion | undefined;
    inlineMenuActions?: ActionInfo[];
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const LineItemWidgetDisplay: React.FC<Props> = ({
    permission,
    projectId,
    initiative,
    initiativeInitialLoading,
    widget,
    theme,
    groupId,
    workflowVersion,
    inlineMenuActions,
    originatedCustomTriggerId,
}) => {
    const enableInterfaceWidgetNoItemEmptyState = useFeatureFlag(
        'tonkean_enable_interface_widgets_no_item_empty_state',
    );
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState(debouncedSearchTerm, setDebouncedSearchTerm);
    const isEnvironmentActive = useIsEnvironmentActive(groupId, initiative);
    const isMobile = useIsMobile();

    const {
        reloadInitiativeFromServer,
        itemInterfaceFieldDefinitions: allFieldDefinitions,
        loadingItemInterfaceFieldDefinitions: fieldDefinitionsLoading,
        updateWidgetsInnerItems,
    } = useItemInterfaceContext();

    const [interval, fastInterval] = useFastReload(10_000, 1000);

    const [searchInitiatives, { initiatives, loading, hasMorePages, isFetched, executionDate, totalInitiatives }] =
        useInitiatives(50, interval, projectId);

    useEffect(() => {
        updateWidgetsInnerItems(widget.id, initiatives);
    }, [initiatives, widget.id, updateWidgetsInnerItems]);

    const { onValidationChanged } = useContext(InterfaceSubmitValidationContext);

    const { userCanAddLineItems, userCanEditLineItems } = useLineItemPermission(
        widget,
        groupId,
        workflowVersion?.workflowVersionType,
    );

    // Show the footer only if there is at least 1 valid aggregation (maybe the field is deleted / hidden)
    const shouldShowFooter = !!widget.configuration.aggregations
        ?.map((agg) => agg.fieldDefinitionKey)
        .filter((key) => widget.configuration.fields[key]?.isShown).length;

    const reloadInitiatives = useReloadInitiatives(widget, projectId, groupId, initiative, searchInitiatives);

    useEffect(() => {
        reloadInitiatives();
    }, [reloadInitiatives]);

    const fieldsToShow = useFieldsToShow(widget);

    const fieldDefinitions = useFieldDefinitions(allFieldDefinitions, fieldsToShow);

    const { fieldDefinitionKeyToLabel, fieldDefinitionKeyToWidth } = useFieldDefinitionKeyToSettings(widget);

    const requiredFields = useRequiredFields(widget);

    const minimumItemsRequired = widget.configuration.minMaxRequirementConfiguration?.minimum ?? 0;

    const validations = useValidations(widget, userCanEditLineItems, requiredFields);

    const initialExpressionValues = useInitialExpressionValues(widget);

    const { handleUpdate, handleCreate, handleDelete } = useItemsGridCrudHandlers(
        projectId,
        groupId,
        initiative?.id,
        workflowVersion,
        originatedCustomTriggerId,
        initialExpressionValues,
    );

    const handleUpdateWrapper = useHandleUpdateWrapper(handleUpdate, fastInterval);
    const handleCreateWrapper = useHandleCreateWrapper(handleCreate, fastInterval);

    const onValidationResultChanged = useCallback(
        (errors: string[]) => {
            onValidationChanged(errors, widget.id);
        },
        [onValidationChanged, widget.id],
    );

    const { items, onUpdate, onDelete, isInitialized } = useItemsGridRowsManager(
        initiatives,
        fieldDefinitions,
        isFetched && !loading?.initial,
        executionDate ?? Date.now(),
        userCanAddLineItems,
        minimumItemsRequired ?? 0,
        widget.configuration.minMaxRequirementConfiguration?.maximum ?? 50,
        validations,
        onValidationResultChanged,
        handleUpdateWrapper,
        handleCreateWrapper,
        handleDelete,
    );
    const itemValuesForCalculations: Record<FieldDefinitionKey, string | number | Date | undefined>[] =
        useItemsValuesForCalculations(items, widget);

    const gridDataIsLoading = useGridDataIsLoading(loading?.initial, isFetched, fieldDefinitionsLoading, isInitialized);

    return (
        <>
            <ItemWidget
                permission={permission}
                itemWidgetBodyMinHeight={200}
                itemWidgetBodyMaxHeight={450}
                headerTitle={
                    <TitleContainer>
                        <ItemWidgetHeaderTitle>{widget?.displayName ?? 'Line Items '}</ItemWidgetHeaderTitle>
                        {minimumItemsRequired > 0 && (
                            <WidgetRequiredIndication type={widget.configuration.requiredIndicatorType} />
                        )}
                        <DataNotUpToDateIndicator show={isEnvironmentActive !== undefined && !isEnvironmentActive} />
                    </TitleContainer>
                }
                headerActions={
                    <>
                        {!isMobile && (
                            <InnerItemsItemWidgetHeaderActions
                                searchText={searchTerm}
                                setSearchText={setSearchTerm}
                                widget={widget}
                                workflowVersionId={workflowVersion?.id}
                                headerBackgroundColor={theme.headerBackgroundColor}
                                displayMode={ItemInterfaceWidgetConfigurationDisplayMode.TABLE}
                                initiative={initiative}
                                workflowVersionType={workflowVersion?.workflowVersionType}
                                originatedCustomTriggerId={originatedCustomTriggerId}
                            />
                        )}
                    </>
                }
                subHeader={
                    isMobile ? (
                        <InnerItemsItemWidgetHeaderActions
                            searchText={searchTerm}
                            setSearchText={setSearchTerm}
                            widget={widget}
                            workflowVersionId={workflowVersion?.id}
                            headerBackgroundColor={theme.headerBackgroundColor}
                            initiative={initiative}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            originatedCustomTriggerId={originatedCustomTriggerId}
                        />
                    ) : undefined
                }
                footer={
                    shouldShowFooter && (
                        <LineItemSummaryFooter
                            widget={widget}
                            fieldDefinitions={allFieldDefinitions}
                            parentInitiativeId={initiative?.id}
                            itemsValues={itemValuesForCalculations}
                            hasMoreInitiatives={hasMorePages}
                            totalInitiatives={totalInitiatives}
                            isLoaded={isFetched}
                            onUpdateParentInitiative={reloadInitiativeFromServer}
                        />
                    )
                }
                noPaddingBody
                disableMaxHeight
            >
                {!initiative && !initiativeInitialLoading && enableInterfaceWidgetNoItemEmptyState ? (
                    <NoItemEmptyState />
                ) : (
                    <ItemsGridWithValidation
                        widgetId={widget.id}
                        rowData={items}
                        parentItemId={initiative?.id}
                        searchTerm={debouncedSearchTerm}
                        groupId={initiative?.group?.id}
                        workflowVersion={workflowVersion}
                        onChange={onUpdate}
                        projectId={projectId}
                        loading={gridDataIsLoading}
                        userCanEdit={userCanEditLineItems}
                        fieldDefinitionsToShow={fieldsToShow}
                        fieldDefinitionKeyToName={fieldDefinitionKeyToLabel}
                        fieldDefinitionKeyToWidth={fieldDefinitionKeyToWidth}
                        onDelete={onDelete}
                        requiredFields={requiredFields}
                        fieldDefinitions={fieldDefinitions}
                        validations={validations}
                        inlineMenuActions={inlineMenuActions}
                        isEmphasizeText={!!widget.configuration.emphasizeText}
                        backgroundColor={theme.headerBackgroundColor}
                        allowFilter={widget.configuration.displayAdvancedFilters}
                        forceGridHeight
                        showOptionsMenu
                    />
                )}
            </ItemWidget>
        </>
    );
};

export default LineItemWidgetDisplay;
