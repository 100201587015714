<div class="outline-filter-wrapper margin-left-xs">
    <div class="flex-vmiddle common-color-dark-grey common-size-xxxxs margin-left-xxs-minus margin-top-xs">
        <!-- Open filter bar options button -->
        <div
            class="flex-vmiddle btn btn-sm btn-no-border common-size-xxxxs margin-left-xs"
            ng-class="{ active: data.filterMenuOpened }"
            ng-click="toggleFieldsFilterDefinitionBarOpened()"
        >
            <span
                class="common-color-grey"
                ng-class="{ 'common-color-black common-bold': !!data.selectedFieldDefinitionForDependencies }"
            >
                Filter
            </span>
        </div>

        <div class="common-separator-v margin-left-v-separator"></div>

        <!-- Search list-->
        <div class="flex-vmiddle margin-left initiatives-inner-search-list-box">
            <span class="svg-icon-sm">
                <tnk-icon src="/images/icons/search.svg"></tnk-icon>
            </span>
            <input
                data-automation="tnk-module-editor-field-filter-input"
                class="form-control common-no-border common-no-outline common-size-xxxxs"
                placeholder="Search {{ data.entityName }}..."
                ng-change="applyFilters()"
                ng-model="data.fieldFilterText"
            />
        </div>
    </div>

    <div ng-if="data.filterMenuOpened" class="inner-filter-separator-fields"></div>

    <hr ng-if="data.filterMenuOpened" class="margin-top-xs margin-bottom-xs" />

    <!-- Filter sub menu -->
    <div ng-if="data.filterMenuOpened" class="flex-vmiddle">
        <!-- Dependencies -->
        <div
            uib-dropdown
            auto-close="disabled"
            is-open="data.showDependenciesDropdown"
            class="margin-right-xlg inner-filter-owner common-size-xxxxs"
        >
            <div class="flex-vmiddle common-color-grey pointer margin-right-xs" uib-dropdown-toggle>
                <span ng-class="{ 'common-color-black common-bold': !!data.selectedFieldDefinitionForDependencies }">
                    Dependent Fields
                </span>
                <i class="fa fa-chevron-down common-size-xxxxxs margin-left-xs"></i>
                <i class="loading-small margin-left-xs" ng-if="data.loadingDependenciesFilterBar"></i>
            </div>
            <div uib-dropdown-menu>
                <div class="common-color-dark-grey common-size-xxs padding-normal-sm flex-vmiddle flex-grow">
                    <ui-select
                        ng-model="data.selectedFieldDefinitionForDependencies"
                        ng-change="selectField()"
                        theme="bootstrap"
                        class="ui-select-min-width"
                    >
                        <ui-select-match placeholder="Select Field">
                            {{ data.selectedFieldDefinitionForDependencies.name }}
                        </ui-select-match>

                        <ui-select-choices
                            repeat="field in data.fieldDefinitionForDependenciesOptions | filter : {name:$select.search}"
                        >
                            <div ng-bind-html="field.name | escapeHtml | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>

                    <span
                        ng-click="clearSelectedFieldDependency()"
                        class="btn btn-no-border svg-icon svg-icon-vmiddle common-color-light-grey margin-right-xs"
                        uib-tooltip="Clear"
                    >
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </span>
                </div>
            </div>
        </div>

        <!-- Clear button -->
        <div>
            <button
                type="button"
                class="inner-filter-clear-btn pointer common-no-outline common-size-xxxxxs"
                ng-if="!!data.selectedFieldDefinitionForDependencies || !!data.fieldFilterByName"
                ng-click="clearFilters()"
            >
                Clear
            </button>
        </div>
    </div>
</div>
