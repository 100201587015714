import React, { useMemo } from 'react';

import { ItemWidgetContext } from '../entities';

import type { ItemInterfaceSection } from '@tonkean/infrastructure';
import type { ItemInterfaceWidget } from '@tonkean/tonkean-entities';

interface Props {
    widget: ItemInterfaceWidget;
    editableWidgets: boolean;
    section: ItemInterfaceSection;
}

const ItemWidgetContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
    widget,
    editableWidgets,
    children,
    section,
}) => {
    const contextValue = useMemo(
        () =>
            ({
                widget,
                isEditable: editableWidgets,
                sidepaneClickableProps: {
                    state: '.',
                    params: {
                        widgetId: widget.id,
                        currentTab: undefined,
                    },
                    options: { location: 'replace' },
                },
                section,
            }) as const,
        [editableWidgets, section, widget],
    );

    return <ItemWidgetContext.Provider value={contextValue}>{children}</ItemWidgetContext.Provider>;
};

export default ItemWidgetContextProvider;
