import { useCallback } from 'react';

import useQueryClientAdapter from '../utils/reactQuery/useQueryClientAdapter';
import type { UniqueQueryKeyType } from '../utils/reactQuery/useTonkeanQuery';

import type { WorkflowVersion } from '@tonkean/tonkean-entities';

const useWorkflowVersionCacheSynchronizer = () => {
    const queryClientAdapter = useQueryClientAdapter();

    return useCallback(
        (
            queryUniqueKey: string,
            propsUniqueKeysArray: UniqueQueryKeyType[],
            workflowVersion: WorkflowVersion | undefined,
        ) => {
            if (!workflowVersion) return undefined;

            queryClientAdapter.setCachedData({
                queryUniqueKey: 'getWorkflowVersionById',
                propsUniqueKeysArray: [workflowVersion.id],
                updatedData: workflowVersion,
                ignoreKey: {
                    queryUniqueKey,
                    propsUniqueKeysArray,
                },
            });

            queryClientAdapter.setCachedDataByPartialPropsKeys<WorkflowVersion>({
                queryUniqueKey: 'getWorkflowVersionByInitiativeId',
                propsUniqueKeysArray: [workflowVersion.project.id],
                filterFn: (cachedWorkflowVersion: WorkflowVersion) => cachedWorkflowVersion.id === workflowVersion.id,
                updatedData: workflowVersion,
                ignoreKey: {
                    queryUniqueKey,
                    propsUniqueKeysArray,
                },
            });
        },
        [queryClientAdapter],
    );
};

export default useWorkflowVersionCacheSynchronizer;
