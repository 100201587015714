/* jshint ignore:start */

import React from 'react';
import DefaultInboxReason from './DefaultInboxReason';

export default class ManualInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;
        let askerName = 'Someone';
        if (item.askerPerson) {
            if (item.askerPerson.id === this.props.as.currentUser.id) {
                askerName = 'You';
            } else {
                askerName = item.askerPerson.name;
            }
        }
        return (
            <div>
                <div>
                    {askerName} asked me to remind you about {this.renderTitleLinkElement(initiative)}.
                </div>
                <div>What's the latest status?</div>
            </div>
        );
    }
}
/* jshint ignore:end */
