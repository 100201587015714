import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import SingleWorkerRunFlowRunInnerFlowRuns from './SingleWorkerRunFlowRunInnerFlowRuns';
import SingleWorkerRunFlowRunLogicStatus from './SingleWorkerRunFlowRunLogicStatus';
import HistoryContext from '../../../../entities/HistoryContext';
import getWorkerRunLogicStatus from '../../../../utils/getWorkerRunLogicStatus';
import type LogicType from '../../LogicType';
import SingleWorkerRunActionInspect from '../../SingleWorkerRunActionInspect/SingleWorkerRunActionInspect';

import { Breakpoint, Chevron, ChevronDirection, Modal, useBreakpoint } from '@tonkean/infrastructure';
import { type WorkerRun, WorkerRunLogicStatus } from '@tonkean/tonkean-entities';
import { CustomTriggerType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const innerFlowCustomTriggers: CustomTriggerType[] = [
    CustomTriggerType.BOT_THREAD_REPLY,
    CustomTriggerType.SYNC_INNER_MATCHED_ENTITY,
    CustomTriggerType.MANUAL_NEXT_STEPS,
    CustomTriggerType.BOT_BUTTON_PRESSED,
    CustomTriggerType.DELAY,
    CustomTriggerType.SEND_FORM_ANSWERED,
];

const innerFlowAcceptableLogicStatus: WorkerRunLogicStatus[] = [
    WorkerRunLogicStatus.MONITORING,
    WorkerRunLogicStatus.PENDING,
    WorkerRunLogicStatus.WASNT_ACTIVATED,
    WorkerRunLogicStatus.SUCCESS,
];

const HideableChevron = styled(Chevron)<{ visible: boolean }>`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    margin-right: 8px;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
`;

const ActionIcon = styled.div<{ $fillIcon: boolean }>`
    display: flex;
    align-items: center;
    width: 14px;
    height: 14px;
    background-size: unset;
    flex-shrink: 0;
    margin-right: 8px;
    color: ${Theme.colors.gray_500};

    svg {
        width: 14px;
        height: 14px;
        ${({ $fillIcon }) => ($fillIcon ? colorSvg(Theme.colors.gray_500) : '')}
    }
`;

const LogicBlockType = styled.span`
    color: ${Theme.current.palette.TUI.notSelectedText};
    margin-right: 4px;
`;

const FlowRunTableValue = styled.div`
    display: flex;
    align-items: center;
    line-height: normal;
    overflow-wrap: break-word;
`;

const breakpointToModalSize: Record<Breakpoint, number> = {
    [Breakpoint.MINIATURE_0]: 1300,
    [Breakpoint.XSMALL_640]: 1300,
    [Breakpoint.MID_XSMALL_768]: 1300,
    [Breakpoint.SMALL_1366]: 1300,
    [Breakpoint.MEDIUM_1920]: 1500,
    [Breakpoint.LARGE_2560]: 1600,
};

interface Props {
    logic: any;
    logicInfo: any;
    workerRun: WorkerRun;
    depth: number;
    displayedChildren?: any[];
    isActivatedOrContainActivatedLogic: boolean;
    isOpen: boolean;
    icon: React.ReactNode;
    logicType: LogicType;
    isInlineAction?: boolean;
    displayName: string;
    isParentActionCompleted?: boolean;
    fillIcon?: boolean;
}

const SingleWorkerRunFlowRunTableLogicRow: React.FC<React.PropsWithChildren<Props>> = ({
    logic,
    logicInfo,
    workerRun,
    depth,
    displayedChildren,
    isActivatedOrContainActivatedLogic,
    isOpen,
    icon,
    children,
    logicType,
    isInlineAction,
    displayName,
    isParentActionCompleted,
    fillIcon = true,
}) => {
    const { state, updateState, getState } = useContext(HistoryContext);

    const effectiveBreakpoint = useBreakpoint();

    const hideInspect = () => {
        updateState({ inspect: false, workerRunLogicId: undefined, tab: undefined });
    };

    const inspectState = useMemo(() => {
        return getState({
            inspect: true,
            workerRunLogicId: logicInfo?.id,
            tab: undefined,
        });
    }, [getState, logicInfo?.id]);

    const showInspect = () => {
        updateState({
            inspect: true,
            workerRunLogicId: logicInfo?.id,
            tab: undefined,
        });
    };

    const logicStatus = useMemo(
        () =>
            getWorkerRunLogicStatus(
                logic,
                logicInfo,
                isActivatedOrContainActivatedLogic,
                isParentActionCompleted || false,
                isInlineAction || false,
                workerRun.workerRunReason,
            ),
        [
            isActivatedOrContainActivatedLogic,
            isInlineAction,
            isParentActionCompleted,
            logic,
            logicInfo,
            workerRun.workerRunReason,
        ],
    );

    const shouldShowInnerFlowRuns = useMemo(() => {
        return (
            innerFlowCustomTriggers.includes(logic.node.customTriggerType) &&
            !workerRun.customTriggerIds?.includes(logic.node.id) &&
            innerFlowAcceptableLogicStatus.includes(logicStatus) &&
            logicStatus !== 'WASNT_ACTIVATED'
        );
    }, [logic.node.customTriggerType, logic.node.id, logicStatus, workerRun.customTriggerIds]);

    return (
        <>
            <FlowRunTableValue style={{ marginLeft: 22 * depth }}>
                <HideableChevron
                    visible={!!displayedChildren && displayedChildren.length > 0}
                    direction={isOpen ? ChevronDirection.DOWN : ChevronDirection.RIGHT}
                />
                <ActionIcon $fillIcon={fillIcon}>{icon}</ActionIcon>
                {logicType && <LogicBlockType>{logicType}:</LogicBlockType>}
                <Description>{children}</Description>
            </FlowRunTableValue>

            <FlowRunTableValue data-automation={`single-worker-run-logic-${displayName.trim()}`}>
                <SingleWorkerRunFlowRunLogicStatus
                    logicStatus={logicStatus}
                    logicInfo={logicInfo}
                    onClick={(e) => {
                        showInspect();
                        e.stopPropagation();
                    }}
                    inspectState={inspectState}
                />
            </FlowRunTableValue>

            <FlowRunTableValue>
                {shouldShowInnerFlowRuns && <SingleWorkerRunFlowRunInnerFlowRuns workerRun={workerRun} logic={logic} />}
            </FlowRunTableValue>

            {logicInfo && (
                <Modal
                    open={state.workerRunLogicId === logicInfo?.id}
                    onClose={hideInspect}
                    size={breakpointToModalSize[effectiveBreakpoint]}
                    height="700px"
                    fixedWidth
                >
                    <SingleWorkerRunActionInspect
                        logic={logic}
                        logicInfo={logicInfo}
                        workerRun={workerRun}
                        logicType={logicType}
                        displayName={displayName}
                        icon={isInlineAction && icon}
                    />
                </Modal>
            )}
        </>
    );
};

export default SingleWorkerRunFlowRunTableLogicRow;
