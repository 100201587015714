import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

/**
 * Gets project integrations for project and group.
 */
export function getRelevantProjectIntegrations(
    project,
    group,
    filterOnIntegrationType,
    filterOutSystemUtilized = false,
) {
    const relevantProjectIntegrationsMap: Record<string, ProjectIntegration> = {};

    // Adding project integrations of the project (those are integrations the current user created or has access to).
    for (let i = 0; i < project.integrations.length; i++) {
        const projectIntegration = project.integrations[i];
        relevantProjectIntegrationsMap[projectIntegration.id] = projectIntegration;
    }

    // Adding group project integrations as well.
    if (group) {
        for (let i = 0; i < group.groupProjectIntegrations.length; i++) {
            const groupProjectIntegration = group.groupProjectIntegrations[i];
            relevantProjectIntegrationsMap[groupProjectIntegration.id] = groupProjectIntegration;
        }
    }

    // Gathering all relevant project integrations.
    let relevantProjectIntegrations = Utils.objValues(relevantProjectIntegrationsMap);

    // Filtering on integration type.
    if (filterOnIntegrationType) {
        relevantProjectIntegrations = relevantProjectIntegrations.filter(
            (projectIntegration) =>
                projectIntegration &&
                projectIntegration.integration &&
                filterOnIntegrationType.includes(projectIntegration.integration.integrationType),
        );
    }

    if (filterOutSystemUtilized) {
        relevantProjectIntegrations = relevantProjectIntegrations.filter(
            (projectIntegration) => !projectIntegration.systemUtilized,
        );
    }

    // At last, our filtered relevantProjectIntegrations are the available project integrations.
    return relevantProjectIntegrations;
}
