import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function filteredWorkerHistoryCtrl(
    $scope,
    projectManager,
    customTriggerManager,
    requestThrottler,
    $q,
    $state,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.ctm = customTriggerManager;

    $scope.data = {
        hideWorkerSettings: ctrl.hideWorkerSettings,
        workflowVersionId: ctrl.workflowVersionId,
        groupId: ctrl.groupId,
        closeSidePane: ctrl.closeSidePane,

        isShowingFuture: false,
        ownerPopoverIsOpen: false,
        initiativePopoverIsOpen: false,
        triggerPopoverIsOpen: false,

        isCurrentListSelected: false,

        isWorkerValid: false,

        currentGroup: null,
        filter: {},
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        if ($scope.data.currentGroup) {
            $scope.setGroupFilter($scope.data.currentGroup, true);
        } else {
            setFirstGroup();
        }
    };

    // When user is in a group it will filter the feed by the group.
    $scope.$watch('pm.currentlyViewedGroupId', () => {
        const newCurrentGroup = $scope.pm.groupsMap[$scope.pm.currentlyViewedGroupId];

        if (newCurrentGroup) {
            $scope.setGroupFilter(newCurrentGroup, true);
        } else {
            setFirstGroup();
        }
        $scope.data.currentGroup = newCurrentGroup;
    });

    // When user is hovering an item(if the side pane is open) it will filter the feed by the initiative.
    $scope.$watch('pm.currentlyViewedSimplifiedCustomTrigger', () => {
        const currentlyViewedSimplifiedCustomTrigger = $scope.pm.currentlyViewedSimplifiedCustomTrigger;
        // Only filter if trigger has changed
        if (
            $scope.data.isVisible &&
            $scope.data.filter.group &&
            currentlyViewedSimplifiedCustomTrigger &&
            (!$scope.data.filter.simplifiedTrigger ||
                $scope.data.filter.simplifiedTrigger.id !== currentlyViewedSimplifiedCustomTrigger.id)
        ) {
            $scope.setFilter(
                $scope.data.filter.group,
                $scope.data.filter.simplifiedInitiative,
                currentlyViewedSimplifiedCustomTrigger,
            );
        }
    });

    // When user is hovering an item(if the side pane is open) it will filter the feed by the initiative.
    $scope.$watch('pm.currentlyViewedSimplifiedInitiative', () => {
        const currentlyViewedSimplifiedInitiative = $scope.pm.currentlyViewedSimplifiedInitiative;
        // Only filter if initiative has changed
        if (
            $scope.data.isVisible &&
            $scope.data.filter.group &&
            currentlyViewedSimplifiedInitiative &&
            (!$scope.data.filter.simplifiedInitiative ||
                $scope.data.filter.simplifiedInitiative.id !== currentlyViewedSimplifiedInitiative.id)
        ) {
            $scope.setFilter(
                $scope.data.filter.group,
                currentlyViewedSimplifiedInitiative,
                $scope.data.filter.simplifiedTrigger,
            );
        }
    });

    // Sets the first available group as filter.
    function setFirstGroup() {
        if (projectManager.groups && projectManager.groups.length) {
            const firstGroup = projectManager.groups[0];
            $scope.setGroupFilter(firstGroup, false);
        }
    }

    /**
     * Set selected filter for the worker feed by group,initiative,trigger.
     */
    $scope.setFilter = function (group, initiative, trigger) {
        requestThrottler.do('filterWorkerFeed', 200, () => {
            $scope.data.filter = {
                group,
                simplifiedInitiative: initiative,
                simplifiedTrigger: trigger,
            };
            return $q.resolve($scope.data.filter);
        });
    };

    /**
     * Close other filters if they are open.
     */
    $scope.openGroupFilter = function () {
        $scope.data.initiativePopoverIsOpen = false;
        $scope.data.triggerPopoverIsOpen = false;
    };

    /**
     * Filter for selected group and clear other filters.
     */
    $scope.setGroupFilter = function (group, currentListSelected) {
        $scope.data.isCurrentListSelected = currentListSelected;
        $scope.setFilter(group, null, null);
    };

    /**
     * Filter for selected initiative and keep old filters.
     */
    $scope.onFilterByInitiative = function (selectedSimplifiedInitiative, isInit) {
        if (
            !$scope.data.filter.simplifiedInitiative ||
            $scope.data.filter.simplifiedInitiative.id !== selectedSimplifiedInitiative.id
        ) {
            $scope.setFilter(
                $scope.data.filter.group,
                selectedSimplifiedInitiative,
                $scope.data.filter.simplifiedTrigger,
            );
            if (!isInit) {
                $scope.data.initiativePopoverIsOpen = false;
            }
        }
    };

    /**
     * Filter for selected trigger and keep old filters.
     */
    $scope.onFilterByTrigger = function (selectedSimplifiedTrigger, isInit) {
        if (
            !$scope.data.filter.simplifiedTrigger ||
            $scope.data.filter.simplifiedTrigger.id !== selectedSimplifiedTrigger.id
        ) {
            $scope.setFilter(
                $scope.data.filter.group,
                $scope.data.filter.simplifiedInitiative,
                selectedSimplifiedTrigger,
            );
            if (!isInit) {
                $scope.data.triggerPopoverIsOpen = false;
            }
        }
    };

    $scope.openWorkerRunsModal = function () {
        $state.go('product.workers', { tab: 'history', g: $scope.data.groupId });
        $scope.data.closeSidePane?.();
    };

    $scope.validateLogics = function () {
        if (!$scope.data.currentGroup && !$scope.data.filter.group) {
            return $q.resolve();
        }

        return customTriggerManager
            .validateCustomTriggers($scope.data.workflowVersionId, $scope.data.currentGroup || $scope.data.filter.group)
            .then(() => {
                $scope.data.isWorkerValid = true;
                return $q.resolve();
            })
            .catch(() => {
                $scope.data.isWorkerValid = false;

                return $q.reject();
            });
    };
}

export default angular
    .module('tonkean.app')
    .controller('filteredWorkerHistoryCtrl', lateConstructController(filteredWorkerHistoryCtrl));
