<div class="margin-left margin-right margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <div class="common-title-xxxs margin-bottom-xs">
            Subject:
            <span class="common-color-danger">*</span>
        </div>
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The email subject..."
            saved-expression="data.definition.subjectExpression"
            expression-unique-identifier="MailchimpTransactionalSubject"
            on-tonkean-expression-changed="onSubjectExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <div class="common-title-xxxs margin-bottom-xs">
            From Email:
            <span class="common-color-danger">*</span>
        </div>
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The sender email..."
            saved-expression="data.definition.fromEmailExpression"
            expression-unique-identifier="MailchimpTransactionalFromEmail"
            on-tonkean-expression-changed="onFromEmailExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <div class="common-title-xxxs margin-bottom-xs">From Name:</div>
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The sender name..."
            saved-expression="data.definition.fromNameExpression"
            expression-unique-identifier="MailchimpTransactionalFromName"
            on-tonkean-expression-changed="onFromNameExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <div ng-if="data.definition.customActionKey === 'SEND_MESSAGE_USING_TEMPLATE'">
            <div class="common-title-xxxs margin-bottom-xs">
                Template Name:
                <span class="common-color-danger">*</span>
            </div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="The template name to use..."
                saved-expression="data.definition.templateNameExpression"
                expression-unique-identifier="MailchimpTransactionalTemplateName"
                on-tonkean-expression-changed="onTemplateNameExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <tnk-send-email-recipients
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            identifier="mailchimp"
            configured-logic="data.configuredLogic"
            preview-evaluation-source="data.previewEvaluationSource"
            validation-object="data.validationObject"
            recipients-configuration="data.definition.recipientsConfiguration"
            cc-configuration="data.definition.ccConfiguration"
            bcc-configuration="data.definition.bccConfiguration"
            on-recipients-configuration-changed="onRecipientsConfigurationChanged(configuration, shouldSaveLogic)"
            on-c-c-configuration-changed="onCCConfigurationChanged(configuration, shouldSaveLogic)"
            on-b-c-c-configuration-changed="onBCCConfigurationChanged(configuration, shouldSaveLogic)"
        ></tnk-send-email-recipients>

        <!-- Send Message Using Template -->
        <div ng-if="data.definition.customActionKey === 'SEND_MESSAGE_USING_TEMPLATE'">
            <tnk-placeholder-replacer
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                initial-value="data.definition.placeholderReplacers"
                on-change="(onPlaceholdersChanged)"
            ></tnk-placeholder-replacer>
        </div>
        <div ng-if="data.definition.customActionKey === 'SEND_MESSAGE'">
            <div class="btn-group margin-bottom">
                <label
                    class="btn btn-default"
                    ng-class="{ 'btn-primary': !data.definition.sendAsPlainText }"
                    uib-btn-radio="false"
                    ng-model="data.definition.sendAsPlainText"
                    ng-click="onDefinitionChanged(true)"
                >
                    HTML
                </label>
                <label
                    class="btn btn-default"
                    ng-class="{ 'btn-primary': data.definition.sendAsPlainText }"
                    uib-btn-radio="true"
                    ng-click="onDefinitionChanged(true)"
                    ng-model="data.definition.sendAsPlainText"
                >
                    Plain-Text
                </label>
            </div>

            <!-- Title -->
            <div class="margin-bottom">Content of the email:</div>

            <!-- Plain test Input -->
            <div ng-if="data.definition.sendAsPlainText">
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Type the text to include in the email"
                    saved-original-expression="data.definition.content.originalExpression"
                    saved-evaluated-expression="data.definition.content.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onContentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                    mod-text-area="true"
                ></tnk-tonkean-expression>
            </div>

            <!-- HTML Editor-->
            <div ng-if="!data.definition.sendAsPlainText">
                <button class="btn btn-primary btn-slim" ng-click="openHtmlEditor()">Open Editor</button>
            </div>
        </div>
    </div>
</div>
