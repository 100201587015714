import type { ReactElement } from 'react';
import React from 'react';

import { ReactComponent as DataSourceIcon } from '../../../../images/icons/history/data-source.svg';
import { ReactComponent as FlowRunIcon } from '../../../../images/icons/history/flow-run.svg';
import { ReactComponent as ModuleItemIcon } from '../../../../images/icons/history/module-item.svg';

import { WorkerRunStage } from '@tonkean/tonkean-entities';

const workerRunStageConfig: Record<WorkerRunStage, { singleName: string; pluralName: string; icon: ReactElement }> = {
    [WorkerRunStage.DATA_SOURCE]: { icon: <DataSourceIcon />, pluralName: 'Data Sources', singleName: 'Data Source' },
    [WorkerRunStage.MODULE_ITEM]: { icon: <ModuleItemIcon />, pluralName: 'Module Items', singleName: 'Module Item' },
    [WorkerRunStage.FLOW_RUN]: { icon: <FlowRunIcon />, pluralName: 'Flow Runs', singleName: 'Flow Run' },
};

export default workerRunStageConfig;
