<div
    ng-if="
        (data.formId || data.isInFormBuilder) &&
        (data.formType === 'WEBFORM' ||
            data.formType === 'PREVIOUS_PLATFORM' ||
            (data.sendToType === 'PEOPLE_DIRECTORY' &&
                ['COLLECT_INNER_ITEMS', 'UPLOAD_FILES'].includes(data.form.formQuestionType)))
    "
>
    <div ng-if="data.formType === 'PREVIOUS_PLATFORM'" class="margin-bottom-xs">Web Form Preview:</div>
    <div
        class="
            box-with-shadow
            padding-normal
            box-with-shadow
            common-border-primary
            margin-top-none
            tnk-preview-form
            common-break-long
        "
    >
        <div>
            <!-- Title -->
            <h4
                ng-if="!!data.form.displayName && !data.form.definition.hideDisplayName"
                data-automation="preview-form-in-web-title-text"
            >
                {{ data.form.definition.clientDisplayName || data.form.displayName }}
            </h4>
            <!-- Description/Subtitle -->
            <div
                class="form-subtitle"
                bind-user-html="data.evaluatedDescription"
                data-automation="preview-form-in-web-description-text"
            ></div>

            <!-- Expandable Description -->
            <tnk-expandable-form-description
                ng-if="!!data.form"
                expandable-description="data.evaluatedExpandableDescription"
                form="data.form"
            ></tnk-expandable-form-description>

            <hr class="margin-normal-sm-v" />

            <span class="flex mod-justify-center" ng-if="data.loadingFields">
                <i class="loading-small"></i>
            </span>
            <div ng-if="!data.loadingFields">
                <!-- Update Fields Preview -->
                <div
                    class="margin-bottom-md"
                    ng-if="data.form && (!data.form.formQuestionType || data.form.formQuestionType === 'UPDATE_FIELDS')"
                    ng-repeat="fieldDefinition in data.wrappedFields"
                >
                    <!--Tonkean field-->
                    <div>
                        <span class="flex-no-shrink margin-right">
                            <span
                                ng-class="{'emphasis-required-field': fieldDefinition.isRequired &&  data.form.definition.emphasisRequiredFields}"
                                data-automation="preview-form-web-fields"
                                ng-if="fieldDefinition.displayName && fieldDefinition.displayName.length"
                            >
                                {{ fieldDefinition.displayName }}<span ng-if="fieldDefinition.isRequired && data.form.definition.emphasisRequiredFields" class="emphasis-required-field"> (Required)</span>:

                                <span ng-if="fieldDefinition.isRequired" class="required-field">*</span>
                            </span>
                            <span
                                ng-class="{'emphasis-required-field': fieldDefinition.isRequired &&  data.form.definition.emphasisRequiredFields}"
                                data-automation="preview-form-web-fields-{{ fieldDefinition.fieldDefinition.name }}"
                                ng-if="!fieldDefinition.displayName || !fieldDefinition.displayName.length"
                            >
                                {{
                                    fieldDefinition.fieldDefinition.fieldLabel || fieldDefinition.fieldDefinition.name
                                }}<span ng-if="fieldDefinition.isRequired && data.form.definition.emphasisRequiredFields" class="emphasis-required-field"> (Required)</span>:
                                <span ng-if="fieldDefinition.isRequired" class="required-field">*</span>
                            </span>
                        </span>
                        <input class="form-control read-only-input" readonly />
                    </div>
                </div>

                <!-- Collect Inner Items Preview -->
                <div ng-if="data.form && data.form.formQuestionType === 'COLLECT_INNER_ITEMS'">
                    <!-- Form Question -->
                    <div
                        bind-user-html="data.form.definition.formQuestion"
                        class="margin-bottom-md"
                        data-automation="preview-form-in-web-form-question"
                        ng-if="data.form.definition.formQuestion"
                    ></div>

                    <!-- Inner Items Editor Preview -->
                    <div class="overflow-scroll-horizontal overflow-y-hidden">
                        <tnk-tracks-editor
                            tracks="[]"
                            minimize-title-header="true"
                            class="pointer-events-none block"
                            editor-id="collect-items-update-form"
                            hide-column-quick-create-force="true"
                            show-add-on-empty="true"
                            group-id="data.form.group.id"
                            hide-title="data.fieldsIds.indexOf('TNK_TITLE') === -1"
                            workflow-version-id="data.workflowVersionId"
                            display-fields-list="data.fieldsIds"
                            ignore-column-visibility="true"
                            hide-bulk-selection="true"
                            add-item-label="{{ data.form.definition.addItemButtonLabel }}"
                            header-max-lines="data.form.definition.headerMaxLines"
                            only-group="data.form.group.id"
                        ></tnk-tracks-editor>
                    </div>
                </div>

                <!-- Upload Files Preview -->
                <div ng-if="data.form && data.form.formQuestionType === 'UPLOAD_FILES'">
                    <!-- files uploader preview -->
                    <div class="overflow-x-hidden overflow-y-hidden flex mod-center">
                        <tnk-upload-files-form is-in-preview="true"></tnk-upload-files-form>
                    </div>
                </div>
            </div>
            <!-- Submit form button-->
            <div
                class="common-disabled inline-block"
                data-automation="preview-form-in-web-submit-form"
                tooltip-enable="{{ utils.hasEllipsis('.tnk-preview-form .submit-button') }}"
                uib-tooltip="{{ data.form.definition.submitFormButtonLabel || 'Submit Form' }}"
            >
                <button
                    class="btn btn-primary margin-top margin-right btn-disabled submit-button common-ellipsis"
                    readonly
                >
                    {{ data.form.definition.submitFormButtonLabel || 'Submit Form' }}
                </button>
            </div>
        </div>
    </div>
</div>

<div ng-if="data.formType === 'PREVIOUS_PLATFORM'" class="margin-bottom-md"></div>

<div
    ng-if="
        (data.formId || data.isInFormBuilder) &&
        (data.formType === 'DIALOG' || data.formType === 'PREVIOUS_PLATFORM') &&
        !(
            data.sendToType === 'PEOPLE_DIRECTORY' &&
            ['COLLECT_INNER_ITEMS', 'UPLOAD_FILES'].includes(data.form.formQuestionType)
        )
    "
>
    <div ng-if="data.formType === 'PREVIOUS_PLATFORM'" class="margin-bottom-xs">Slack Dialog Preview:</div>
    <div
        class="
            box-with-shadow
            padding-normal
            box-with-shadow
            common-border-darkgray
            margin-top-none
            tnk-preview-form
            common-break-long
        "
    >
        <div ng-if="!data.form.formQuestionType || data.form.formQuestionType === 'UPDATE_FIELDS'">
            <div>
                <div class="flex-vmiddle flex-space-between margin-bottom-xs">
                    <div class="flex-vmiddle">
                        <span
                            class="svg-icon-lg margin-bottom-xs svg-icon mod-static margin-right common-color-blue-dark"
                        >
                            <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                        </span>

                        <!-- Title -->
                        <h4 ng-if="!!data.form.displayName" data-automation="preview-form-in-slack-title-name">
                            {{ data.form.definition.clientDisplayName || data.form.displayName }}
                        </h4>
                    </div>

                    <span class="flex flex-justify-end-no-xs common-color-grey-soft">
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </span>
                </div>

                <!-- Description -->
                <p ng-if="!!data.form.description" data-automation="preview-form-in-slack-description-name">
                    {{ data.form.description }}
                </p>

                <!-- Fill Fields -->
                <div ng-repeat="fieldDefinition in data.wrappedFields" class="margin-bottom-md">
                    <!--Tonkean field-->
                    <div>
                        <span class="flex-no-shrink margin-right" data-automation="preview-form-in-slack-field-name">
                            <span ng-if="fieldDefinition.displayName && fieldDefinition.displayName.length">
                                <strong>{{ fieldDefinition.displayName }}</strong>
                                (optional)
                            </span>
                            <span ng-if="!fieldDefinition.displayName || !fieldDefinition.displayName.length">
                                <strong>
                                    {{
                                        fieldDefinition.fieldDefinition.fieldLabel ||
                                            fieldDefinition.fieldDefinition.name
                                    }}
                                </strong>
                                (optional)
                            </span>
                        </span>
                        <input class="form-control read-only-input" readonly />
                    </div>
                </div>
            </div>

            <div class="flex-vmiddle mod-justify-end">
                <!-- submit form button-->
                <div class="margin-left">
                    <div class="common-disabled inline-block">
                        <button
                            class="btn margin-top margin-right btn-disabled common-border-darkgray"
                            readonly
                            data-automation="preview-form-in-slack-cancel-button-name"
                        >
                            Cancel
                        </button>
                    </div>

                    <!-- submit form button-->
                    <div
                        class="common-disabled inline-block"
                        tooltip-enable="{{ utils.hasEllipsis('.tnk-preview-form .slack-button') }}"
                        uib-tooltip="{{ data.slackSubmitFormButtonLabel }}"
                    >
                        <button
                            class="btn margin-top margin-right btn-disabled slack-button common-ellipsis"
                            readonly
                            data-automation="preview-form-in-slack-submit-button-name"
                        >
                            {{ data.slackSubmitFormButtonLabel }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="common-color-grey"
            data-automation="preview-form-in-slack-not-supported-error"
            ng-if="
                !data.form.formQuestionType ||
                ['COLLECT_INNER_ITEMS', 'UPLOAD_FILES'].includes(data.form.formQuestionType)
            "
        >
            This Form Type is Not Supported in Slack
        </div>
    </div>
</div>

<!-- Warning that will be displayed when the user entered custom submission button text which contains spaces. (Slack doesn't allow spaces in submission button). -->
<div class="margin-top" ng-if="data.formType === 'DIALOG' && data.shouldDisplaySlackSubmitFormButtonWarning">
    <span class="common-size-xxs common-color-warning" data-automation="preview-form-in-slack-submit-button-error">
        Dialog ‘submit’ button must contain a single word. Text after space is ignored.
    </span>
</div>
