import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { REMINDERS } from '@tonkean/constants';
import { SimpleSelect } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { DaysDiffNumberFilter, DaysHoursDiffFilter } from '@tonkean/shared-services';
import type { Initiative } from '@tonkean/tonkean-entities';
import { calculateNextReminderHour } from '@tonkean/tonkean-utils';
import Utils from '@tonkean/utils';

const NoAccess = styled.div`
    width: 100%;
`;

const NOW_REMINDER_NUM = -1;
const CUSTOM_REMINDER_NUM = -2;

interface Props {
    initiative: Initiative;
    value: number | undefined;
    viewOnly: boolean;
    forceCloseDropdown?: boolean;
    hideCustom?: boolean;
    manualGatherUpdate?: { date?: number };
    dontUpdateServer?: boolean;
    noTooltip?: boolean;
    onSave?: () => void;
    onCustom?: (initiative: Initiative) => void;
    onSetReminder?: (value: number) => void;
    provideOwnerName?: () => string;
    customLabel?: string;
    bold?: boolean;
}

const NextGatherUpdateSelector: React.FC<Props> = ({
    initiative,
    viewOnly,
    forceCloseDropdown,
    hideCustom,
    manualGatherUpdate,
    dontUpdateServer = true,
    noTooltip,
    onSave,
    onCustom,
    onSetReminder,
    provideOwnerName,
    value,
    bold = false,
}) => {
    const modal = useAngularService('modal');
    const trackHelper = useAngularService('trackHelper');
    const licensePermissionsService = useAngularService('licensePermissionsService');

    const reminders = REMINDERS;

    const setReminder = (reminderValue) => {
        if (reminderValue === NOW_REMINDER_NUM) {
            askForUpdateNow();
            return;
        }
        if (reminderValue === CUSTOM_REMINDER_NUM) {
            onCustomClicked();
            return;
        }
        const reminder = reminders.find((elem) => elem.days === reminderValue);
        const hour = calculateNextReminderHour(reminder!);
        if (manualGatherUpdate) {
            manualGatherUpdate.date = Utils.getTimeDiffFromNow(reminder?.days as number, hour!);
        }

        if (onSetReminder) {
            onSetReminder(Utils.getTimeDiffFromNow(reminder?.days as number, hour!));
        }

        if (!dontUpdateServer) {
            trackHelper.updateNextReminder(initiative, reminder?.days as number, hour!).then(function (data) {
                initiative.nextGatherUpdate = data.nextGatherUpdate;

                if (onSave) {
                    onSave();
                }
            });
        }
    };

    const askForUpdateNow = () => {
        let ownerName = initiative?.owner?.['name'];

        if (provideOwnerName) {
            ownerName = provideOwnerName();
        }

        modal.openAskForUpdateModal(initiative, ownerName);
    };

    const onCustomClicked = () => {
        if (onCustom) {
            onCustom(initiative);
        } else {
            modal.openInitiativeSettingsModal(initiative, true, null, null);
        }
    };

    const isEditable = licensePermissionsService.canPersonUpdate(initiative.owner);

    const options = useMemo(() => {
        const reminderToDisplay = reminders.map((item) => ({
            value: item.days,
            label: item.label,
        }));
        if (!hideCustom) {
            reminderToDisplay.push({ label: 'Custom', value: CUSTOM_REMINDER_NUM });
        }
        reminderToDisplay.unshift({ label: 'Now', value: NOW_REMINDER_NUM });
        return reminderToDisplay;
    }, [hideCustom, reminders]);

    const tooltipContent = useMemo(() => {
        return manualGatherUpdate?.date
            ? `Next reminder: ${DaysHoursDiffFilter(manualGatherUpdate.date)}`
            : `Next reminder: ${DaysHoursDiffFilter(value)}`;
    }, [value, manualGatherUpdate]);

    const isTooltipDisabled = noTooltip || (!value && !manualGatherUpdate?.date);

    const placeholder = useMemo(() => {
        const nextGatherUpdate = value || manualGatherUpdate?.date;
        if (nextGatherUpdate) {
            const daysDiff = DaysDiffNumberFilter(nextGatherUpdate);
            const currentReminder = reminders.find((elem) => elem.days === daysDiff);
            if (currentReminder) {
                return currentReminder.label;
            } else {
                return `${daysDiff} days`;
            }
        }
        return 'None';
    }, [value, manualGatherUpdate?.date, reminders]);

    return isEditable ? (
        <Tooltip content={tooltipContent} disabled={isTooltipDisabled}>
            <div>
                <div>
                    <SimpleSelect
                        placeholder={placeholder}
                        value={value}
                        options={options}
                        onChange={setReminder}
                        readOnly={viewOnly}
                        isDisabled={viewOnly}
                        bold={bold}
                    />
                </div>
            </div>
        </Tooltip>
    ) : (
        <NoAccess>Not a Member</NoAccess>
    );
};

export default NextGatherUpdateSelector;
