function ManageFieldsModalCtrl(
    $rootScope,
    $scope,
    customFieldsManager,
    groupInfoManager,
    groupId,
    workflowVersionId,
    onlyColumnTypeFields,
    solutionBusinessReportId,
    environment,
) {
    $scope.cfm = customFieldsManager;
    $scope.pm = $rootScope.pm;

    $scope.data = {
        saveMangeColumns: false,
        columnsCtrlObject: {},
        keyMetricsCtrlObject: {},
        groupId,
        workflowVersionId,
        onlyColumnTypeFields,
        solutionBusinessReportId,
        environment,
        group: $scope.pm.groupsMap[groupId],

        saveOnChange: true,
    };

    $scope.init = function () {
        $scope.data.columnsCtrlObject.onSaveChangesErrorOccurred = $scope.onSaveChangesErrorOccurred;
        $scope.data.keyMetricsCtrlObject.onSaveChangesErrorOccurred = $scope.onSaveChangesErrorOccurred;
        $scope.data.columnsCtrlObject.onSaveChangesErrorOccurred = $scope.onSaveChangesErrorOccurred;
    };

    $scope.onSaveChangesErrorOccurred = function (err) {
        $scope.data.errorSavingFields = err;
    };

    /**
     * Once the create\edit modal is closed, we update the collections so that the manage columns ui is updated.
     */
    $scope.onCreateDefModalClosed = function (field, fromUpdate) {
        if (fromUpdate === false) {
            $scope.$dismiss();
        }

        $scope.data.loading = true;
        // Get latest fields.
        groupInfoManager.getGroup($scope.data.groupId, true, solutionBusinessReportId).then(() => {
            $scope.data.loading = false;
        });
    };

    $scope.init();
}

angular.module('tonkean.app').controller('ManageFieldsModalCtrl', ManageFieldsModalCtrl);
