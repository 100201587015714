import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CircleIcon } from '../../../../../images/icons/circle.svg';
import { ReactComponent as ErrorWarningIcon } from '../../../../../images/icons/error-warning.svg';
import { ReactComponent as RedErrorIcon } from '../../../../../images/icons/red-error.svg';
import { ReactComponent as SuccessOutlineIcon } from '../../../../../images/icons/success-outline.svg';
import type EnterpriseComponentOverviewStepDefinition from '../../entities/overview/EnterpriseComponentOverviewStepDefinition';

import { Placeholder } from '@tonkean/infrastructure';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const Step = styled(Clickable)<{ $descriptionExists: boolean; $border: boolean }>`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'icon title actions'
        '_ description actions';
    grid-gap: 4px 10px;
    height: 70px;
    padding: 0 12px;
    align-content: center;
    align-items: center;

    &:hover {
        background-color: ${Theme.colors.gray_200};
    }

    ${({ $border }) =>
        $border &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}
`;

const StepTitle = styled.div<{ isValid: boolean }>`
    grid-area: title;
    font-size: ${FontSize.MEDIUM_14};
    color: ${({ isValid }) => (isValid ? Theme.colors.gray_700 : Theme.colors.gray_800)};
`;

const StatusIcon = styled.svg`
    grid-area: icon;
    width: 12px;
    height: 12px;
`;

const StepDescription = styled.div`
    grid-area: description;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const StepActions = styled.div`
    grid-area: actions;
`;

const TitlePlaceHolder = styled(Placeholder)`
    grid-area: title;
`;

const IconPlaceHolder = styled(Placeholder)`
    grid-area: icon;
`;

const StyledCircleIcon = styled(CircleIcon)`
    ${colorSvg(Theme.colors.primary)};
`;

const stepStatusToIcon: Record<
    EnterpriseComponentOverviewStepStatus,
    React.FunctionComponent<React.SVGAttributes<SVGElement>>
> = {
    [EnterpriseComponentOverviewStepStatus.VALID]: SuccessOutlineIcon,
    [EnterpriseComponentOverviewStepStatus.WARNING]: ErrorWarningIcon,
    [EnterpriseComponentOverviewStepStatus.ERROR]: RedErrorIcon,
    [EnterpriseComponentOverviewStepStatus.EMPTY]: StyledCircleIcon,
};

interface Props {
    definition?: EnterpriseComponentOverviewStepDefinition;
    loading: boolean;
    isLastStep: boolean;
}

const EnterpriseComponentOverviewStep: React.FC<Props> = ({ definition, loading, isLastStep }) => {
    return (
        <>
            {!loading && definition ? (
                <Step
                    $descriptionExists={!!definition.description}
                    $border={!isLastStep}
                    state="."
                    params={{ page: definition.page }}
                >
                    <StatusIcon as={stepStatusToIcon[definition.status]} />

                    <StepTitle isValid={definition.status === EnterpriseComponentOverviewStepStatus.VALID}>
                        {definition.title}
                    </StepTitle>

                    {definition.description && (
                        <StepDescription data-automation="enterprise-component-over-view-step-description">
                            {definition.description}{' '}
                        </StepDescription>
                    )}

                    {definition.actions && <StepActions>{definition.actions}</StepActions>}
                </Step>
            ) : (
                <Step $descriptionExists={false} $border={!isLastStep}>
                    <IconPlaceHolder $width="14px" $circle />
                    <TitlePlaceHolder $width="250px" $height="12px" />
                </Step>
            )}
        </>
    );
};

export default EnterpriseComponentOverviewStep;
