import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

export interface DatadogAuthenticationProps extends CustomizedAuthenticationComponentProps {}

const DatadogAuthenticationComponent: React.FC<DatadogAuthenticationProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [site, setSite] = useState<string>('datadoghq.com');
    const [apiKey, setApiKey] = useState<string>('');
    const [appKey, setAppKey] = useState<string>('');

    const authenticateWithApiAndAppKeys = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                api_site: site,
                api_key: apiKey,
                app_key: appKey,
            };
            authenticate(config, {}, integrationConfiguration.displayName);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithApiAndAppKeys}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="login-api-site" className="col-sm-3 control-label">
                            API SITE
                        </label>
                        <div className="col-sm-8">
                            <div className="flex-vmiddle">
                                <div>https://api.</div>
                                <div className="flex-grow">
                                    <input
                                        type="text"
                                        value={site}
                                        className="form-control"
                                        id="login-api-site"
                                        onChange={({ target }) => setSite(target.value)}
                                        autoComplete="off"
                                        placeholder="API SITE"
                                        required
                                    />
                                </div>
                            </div>
                            <span className="common-size-xxxs common-color-grey">
                                The regional site for a Datadog customer. (e.g., datadoghq.com or datadoghq.eu)
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-key" className="col-sm-3 control-label">
                            API Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={apiKey}
                                className="form-control"
                                id="login-api-key"
                                onChange={({ target }) => setApiKey(target.value)}
                                autoComplete="off"
                                placeholder="API Key"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Get your API key{' '}
                                <a href="https://app.datadoghq.com/account/settings#api" target="_blank">
                                    here
                                </a>
                                . Only Admin users can create API keys.
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-app-key" className="col-sm-3 control-label">
                            Application Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={appKey}
                                className="form-control"
                                onChange={({ target }) => setAppKey(target.value)}
                                id="login-app-key"
                                autoComplete="off"
                                placeholder="Application Key"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Get your Application key{' '}
                                <a href="https://app.datadoghq.com/access/application-keys" target="_blank">
                                    here
                                </a>
                                .
                            </span>
                        </div>
                    </div>

                    <div className="flex mod-center common-subtitle-inner common-size-xxs margin-bottom-xs">
                        <i className="fa fa-info-circle margin-right-xs" />
                        <span>
                            After completing the setup, a new webhook will be added to DataDog{' '}
                            <a href="https://app.datadoghq.com/account/settings#integrations/webhooks" target="_blank">
                                here
                            </a>
                            .<br />
                            New Events that contain @webhook-[webhook name], will be collected.
                        </span>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DatadogAuthenticationComponent;
