function SyncPreviewModalCtrl(
    $scope,
    consts,
    newControl,
    customDefinedView,
    pluralEntityType,
    projectIntegration,
    viewData,
    viewType,
    getAllIntegrationViews,
    hideBotInPreview,
    isBotActiveObject,
    allowSave,
    saveFunction,
    closeFunction,
    titleExpression,
    defaultTitleIfExpressionEmpty,
    singleEntityType,
) {
    $scope.newControl = newControl;
    $scope.customDefinedView = customDefinedView;
    $scope.pluralEntityType = pluralEntityType;
    $scope.projectIntegration = projectIntegration;
    $scope.viewData = viewData;
    $scope.viewType = viewType;
    $scope.titleExpression = titleExpression;
    $scope.defaultTitleIfExpressionEmpty = defaultTitleIfExpressionEmpty;
    $scope.isBotActiveObject = isBotActiveObject;
    $scope.hideBotInPreview = hideBotInPreview;
    $scope.allowSave = allowSave;
    $scope.saveFunction = saveFunction;
    $scope.syncItemsLimitation = consts.getSyncItemsLimitation();
    $scope.closeFunction = closeFunction;
    $scope.singleEntityType = singleEntityType;

    $scope.data = {
        loadPreviewFlag: false,
    };

    $scope.init = function () {
        if (!customDefinedView) {
            $scope.integrationViews = getAllIntegrationViews();
        }
    };

    $scope.cancel = function () {
        if ($scope.closeFunction) {
            $scope.closeFunction();
        }

        $scope.$dismiss();
    };

    $scope.save = function () {
        $scope.saveFunction();
        $scope.$dismiss();
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('SyncPreviewModalCtrl', SyncPreviewModalCtrl);
