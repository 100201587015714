import { initiativeTitle } from '@tonkean/utils';
import lateConstructController from '../../utils/lateConstructController';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

/* @ngInject */
function SearchInitiativesCtrl(
    $scope,
    $q,
    tonkeanService,
    projectManager,
    requestThrottler,
    trackHelper,
    utils,
    customTriggerManager,
    workflowVersionManager,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        canClear: ctrl.canClear,
        shouldSetDefaultInitiative: ctrl.shouldSetDefaultInitiative,
        defaultInitiativesAmount: ctrl.defaultInitiativesAmount,
        onlyRoots: ctrl.onlyRoots,
        onlyDraftInitiatives: ctrl.onlyDraftInitiatives,
        simplifiedInitiatives: [],
        selectedSimplifiedInitiative: null,
        appendToBody: ctrl.appendToBody ?? false,
    };

    $scope.utils = utils;

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        init();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
            init();
        }
        if (changesObj.selectedInitiativeId) {
            $scope.data.selectedInitiativeId = changesObj.selectedInitiativeId.currentValue;
            init();
        }
        if (changesObj.appendToBody) {
            $scope.data.appendToBody = changesObj.appendToBody.currentValue;
        }

        if (changesObj.onlyDraftInitiatives) {
            $scope.data.onlyDraftInitiatives = ctrl.onlyDraftInitiatives;
        }
    };

    /**
     * Occurs once an initiative is selected.
     */
    $scope.onInitiativeSelected = function (selectedSimplifiedInitiative, isInit) {
        $scope.data.selectedSimplifiedInitiative = selectedSimplifiedInitiative;

        if (ctrl.onInitiativeSelected) {
            ctrl.onInitiativeSelected({
                selectedSimplifiedInitiative: selectedSimplifiedInitiative
                    ? selectedSimplifiedInitiative
                    : { id: null },
                isInit,
            });
        }
    };

    /**
     * Search initiatives in server using request throttler.
     */
    $scope.searchInitiativesThrottled = function (searchText) {
        requestThrottler.do(
            'loadInitiatives',
            $scope.data.defaultInitiativesAmount ? $scope.data.defaultInitiativesAmount : 350,
            () => searchInitiatives(searchText),
            (initiatives) => {
                // Map into simplified initiatives, because ui-select tries to do deep equals which results in a circular reference and an exception.
                $scope.data.simplifiedInitiatives = initiatives.map((initiative) => {
                    return {
                        id: initiative.id,
                        title: initiative.title,
                        isDraftInitiative: initiative.isDraftInitiative,
                        displayTitle: initiativeTitle(initiative.title, initiative.isDraftInitiative, initiative.id),
                    };
                });
            },
        );
    };

    /**
     * Clear selected initiative.
     */
    $scope.clearInitiative = function () {
        $scope.onInitiativeSelected(null);
    };

    /**
     *  Stop propagation, so the side pane will not be closed.
     */
    $scope.stopPropagation = function (event) {
        event.stopPropagation();
    };

    /**
     * Searches for one initiative to set him as the default.
     */
    async function getDefaultInitiativeExampleEntity() {
        const workflowVersion = await workflowVersionManager.getGroupWorkflowVersion(
            $scope.data.groupId,
            $scope.data.onlyDraftInitiatives ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
        );
        // Taking the id either from the regular cache or the local storage one in case the regular wasnt initialized yet
        const exampleItemIdInCache =
            customTriggerManager.workflowVersionIdToExampleItemsMap?.[workflowVersion.id]?.root ||
            customTriggerManager.getWorkflowExampleItemIdFromCache(workflowVersion.id);
        if (exampleItemIdInCache) {
            return {
                entities: [
                    {
                        id: exampleItemIdInCache,
                        isDraftInitiative: $scope.data.onlyDraftInitiatives,
                    },
                ],
            };
        }

        const data = await tonkeanService.searchInitiatives(projectManager.project.id, {
            isArchived: false,
            groupId: $scope.data.groupId,
            limit: 1,
            isDraftInitiatives: $scope.data.onlyDraftInitiatives === true ? true : undefined,
            isRootInitiative: true, // It makes sense the default item would be a root item
        });

        return data;
    }

    /**
     * Searches for initiatives using search text.
     */
    function searchInitiatives(searchText) {
        $scope.data.loadingInitiatives = true;
        $scope.data.errorLoadingInitiatives = false;

        return tonkeanService
            .searchInitiatives(projectManager.project.id, {
                isArchived: false,
                groupId: $scope.data.groupId,
                limit: 5,
                isDraftInitiatives: $scope.data.onlyDraftInitiatives === true ? true : undefined,
                isRootInitiative: $scope.data.onlyRoots === true ? true : undefined,
                searchString: searchText,
            })
            .then((data) => {
                return $q.resolve(data.entities);
            })
            .catch(() => {
                $scope.data.errorLoadingInitiatives = 'Error loading items.';
            })
            .finally(() => {
                $scope.data.loadingInitiatives = false;
            });
    }

    /**
     * Set selected initiative on init.
     */
    function init() {
        if (ctrl.selectedInitiativeId) {
            trackHelper.getInitiativeById(ctrl.selectedInitiativeId).then(function (data) {
                const selectedSimplifiedInitiative = {
                    id: data.id,
                    title: data.title,
                    isDraftInitiative: data.isDraftInitiative,
                    displayTitle: initiativeTitle(data.title, data.isDraftInitiative, data.id),
                };
                $scope.onInitiativeSelected(selectedSimplifiedInitiative, true);
            });
        } else if ($scope.data.shouldSetDefaultInitiative) {
            // Get default item example entity
            getDefaultInitiativeExampleEntity().then(function (data) {
                if (data && data.entities && data.entities.length) {
                    const entity = data.entities[0];
                    const selectedSimplifiedInitiative = {
                        id: entity.id,
                        title: entity.title,
                        isDraftInitiative: entity.isDraftInitiative,
                        displayTitle: initiativeTitle(entity.title, entity.isDraftInitiative, entity.id),
                    };
                    $scope.onInitiativeSelected(selectedSimplifiedInitiative, true);
                }
            });
        }
    }
}

angular.module('tonkean.app').controller('SearchInitiativesCtrl', lateConstructController(SearchInitiativesCtrl));
