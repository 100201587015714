<div class="view-project-integration-data">
    <!-- View data title -->

    <div class="view-data-title-section">
        <div class="view-data-title">View Data</div>

        <tnk-project-integration-collect-status
            project-integration="data.projectIntegration"
            selected-entity="data.previewItems.selectedEntity"
            on-collect-completed="(onCollectComplete)"
        ></tnk-project-integration-collect-status>
    </div>

    <div class="explore-entities-title">Explore Entities</div>
    <div class="explore-entities-description">Select an entity to view all available data source records.</div>

    <tnk-entity-selector
        project-integration="data.projectIntegration"
        force-fetch-supported-entities-from-server="true"
        mode="sync"
        on-entity-selected="loadData(selectedEntity)"
    ></tnk-entity-selector>

    <div class="flex-vmiddle items-title-container">
        <div ng-if="data.previewItems.selectedEntity" class="items-title margin-right-md" data-automation="tnk-view-project-integration-data-template-header">
            {{ data.previewItems.entityType }} Records
        </div>

        <button
            ng-if="data.projectIntegration.integration.integrationType === 'WEBHOOK'"
            data-automation="single-integration-view-add-item-button"
            class="btn btn-primary btn-sm"
            ng-click="openEditCustomWebhookItemModal()"
        >
            + Add Item
        </button>
        <tnk-view-data-add-record
            data-automation="tnk-view-project-integration-data-template-add-record"
            ng-if="data.projectIntegration.integration.integrationType === 'NO_CODE_DATA_SOURCE'"
            entity-name="data.previewItems.selectedEntity.label"
            project-integration-entity-id="data.previewItems.selectedEntity.entity"
            project-integration-id="data.projectIntegration.id"
            project-integration-unique-type="data.projectIntegration.integrationUniqueType"
            on-submit="(refreshData)"
        ></tnk-view-data-add-record>
    </div>

    <div
        class="items-table padding-normal rounded-box"
        ng-class="{ fullscreen: data.fullScreen }"
        data-automation="view-project-integration-data-table"
        ng-if="data.previewItems.selectedEntity"
    >
        <button class="toggle-fullscreen" aria-label="Toggle fullscreen" ng-click="toggleFullscreen()">
            <span ng-if="!data.fullScreen" class="svg-icon-sm svg-icon-vmiddle pointer">
                <tnk-icon src="/images/icons/fullscreen.svg"></tnk-icon>
            </span>

            <span ng-if="data.fullScreen" class="svg-icon-sm svg-icon-vmiddle pointer">
                <tnk-icon src="/images/icons/exit-fullscreen.svg"></tnk-icon>
            </span>
        </button>
        <div class="flex-vmiddle margin-bottom-lg">
            <!-- All -->
            <button
                class="text-left btn btn-default common-no-outline margin-right-xs padding-right-xs padding-left-xs"
                ng-click="toggleFilter(false)"
                ng-class="!data.isFiltered ? 'selected-option' : 'not-selected-option'"
            >
                All {{ data.previewItems.entityType }}
            </button>

            <!-- Custom filter -->
            <button
                class="text-left btn btn-default common-no-outline margin-right-xs padding-right-xs padding-left-xs"
                ng-click="toggleFilter(true)"
                ng-class="data.isFiltered ? 'selected-option' : 'not-selected-option'"
            >
                Custom Filter
            </button>
        </div>

        <!-- Sync preview component -->
        <tnk-live-sync
            control="data.previewItems.control"
            project-integration="data.projectIntegration"
            copy-properties-from-definition="true"
            sync-validity-state="data.previewItems.validityState"
            selected-entity-plural-name="data.previewItems.entityType"
            custom-defined-view="true"
            existing-definition="data.previewItems.definition"
            selected-entity-type="data.previewItems.entityType"
            view-type="data.viewTypes.custom"
            hide-manage-triggers-modal="true"
            hide-information="true"
            override-activate-bot-object="data.overrideActivateBotObject"
            hide-filters="!data.isFiltered"
            full-screen="data.fullScreen"
            selected-entity="data.previewItems.selectedEntity.entity"
            on-data-changed="(refreshData)"
        ></tnk-live-sync>
    </div>
</div>
