import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function workerHistoryCtrl(
    $scope,
    projectManager,
    initiativeCache,
    workerRunsHistoryHelper,
    modalUtils,
    timeUtils,
    utils,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.itemMap = initiativeCache.getInitiativesCache();

    $scope.data = {
        loading: false,
        hardLoading: false, // If need to use the big spinner forcibly.
        filter: ctrl.filter,
        groupId: ctrl.groupId,
        closeSidePane: ctrl.closeSidePane,
        items: [],
        historySections: [
            {
                label: 'Today',
                predicate: (itemTime) => timeUtils.isToday(itemTime),
                items: [],
            },
            {
                label: 'Yesterday',
                predicate: (itemTime) => timeUtils.isYesterday(itemTime),
                items: [],
            },
            {
                label: 'Last 7 days',
                predicate: (itemTime) => timeUtils.isInPastSevenDays(itemTime),
                items: [],
            },

            // Default
            {
                label: 'Older',
                predicate: () => true,
                items: [],
            },
        ],
        finishedOnInit: false,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.filter) {
            $scope.data.filter = changesObj.filter.currentValue;
            $scope.data.workerRuns = [];
            if ($scope.data.finishedOnInit) {
                $scope.loadHistory(true);
            }
        }
        if (changesObj.closeSidePane) {
            $scope.data.closeSidePane = changesObj.closeSidePane.currentValue;
        }
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        $scope.init();
        $scope.data.finishedOnInit = true;
    };

    /**
     * Load worker run history by the selected filters.
     */
    $scope.loadHistory = function (hardLoad) {
        $scope.data.loading = true;
        $scope.data.hardLoading = hardLoad;

        if (hardLoad) {
            $scope.data.items = [];
        }

        const filter = $scope.data.filter || {};

        const groupId = filter.group ? filter.group.id : null;
        const initiativeId = filter.simplifiedInitiative ? filter.simplifiedInitiative.id : null;
        const triggerId = filter.simplifiedTrigger ? filter.simplifiedTrigger.id : null;

        const after = null; // for now we never update the list on new items (because of polling)

        let before = null;
        if ($scope.data.items && $scope.data.items.length) {
            before = $scope.data.items[$scope.data.items.length - 1].created;
        }

        fetchWorkerRunPage(groupId, initiativeId, triggerId, 0, false, after, before, 15);
    };

    // region UiActions

    // UI functions needed.
    $scope.workerHistoryUiActions = {};

    /**
     * Initialize for the ctrl.
     */
    $scope.init = function () {
        $scope.initUiActions();
    };

    /**
     * Initialize UI functions needed.
     */
    $scope.initUiActions = function () {
        $scope.workerHistoryUiActions = {
            goToTrack() {
                $scope.$apply($scope.goToTrack.apply(null, arguments));
            },
            filterTrigger() {
                $scope.$apply($scope.filterTrigger.apply(null, arguments));
            },
            filterInitiative() {
                $scope.$apply($scope.filterInitiative.apply(null, arguments));
            },
        };
    };

    /**
     * Open the requested initiative view.
     */
    $scope.goToTrack = function (initiativeId) {
        modalUtils.openViewInitiative(initiativeId);
    };

    /**
     * Filter the worker history by the requested trigger.
     */
    $scope.filterTrigger = function (trigger) {
        $scope.pm.currentlyViewedSimplifiedCustomTrigger = { id: trigger.id, title: trigger.displayName };
    };

    /**
     * Filter the worker history by the requested initiative.
     */
    $scope.filterInitiative = function (intiative) {
        $scope.pm.currentlyViewedSimplifiedInitiative = { id: intiative.id, title: intiative.title };
    };

    // endregion UiActions

    /**
     * Fetches a page of worker runs.
     */
    function fetchWorkerRunPage(groupId, initiativeId, triggerId, skip, doNotDisplayLoading, from, to, pageSize) {
        workerRunsHistoryHelper
            .loadWorkerRunPage(groupId, initiativeId, triggerId, skip, doNotDisplayLoading, from, to, true, pageSize)
            .then((data) => {
                $scope.data.hasMore = data.hasMore;
                $scope.data.workerRuns = $scope.data.workerRuns.concat(data.entities);
                const items = data.entities;

                // Hold 1 array of all the items, and add or replace old items with the new items.
                for (const item of items) {
                    utils.addOrReplaceById($scope.data.items, item);
                }

                // Filtering out test runs.
                $scope.data.items = $scope.data.items.filter((item) => {
                    return item.workerRunReason !== 'TEST_RUN';
                });

                // Empty sections from stale data.
                for (let i = 0; i < $scope.data.historySections.length; i++) {
                    const section = $scope.data.historySections[i];
                    section.items = [];
                }

                // Fill sections with fresh data.
                for (let i = 0; i < $scope.data.items.length; i++) {
                    const item = $scope.data.items[i];
                    for (let j = 0; j < $scope.data.historySections.length; j++) {
                        const section = $scope.data.historySections[j];
                        if (section.predicate(item.created)) {
                            section.items.push(item);
                            break;
                        }
                    }
                }
            })
            .catch(() => {
                $scope.data.errorLoadingWorkerRuns = 'Error trying to load worker run history.';
            })
            .finally(() => {
                if (!doNotDisplayLoading) {
                    $scope.data.loading = false;
                    $scope.data.hardLoading = false;
                }

                $scope.data.loadingFirst = false;
            });
    }
}

export default angular.module('tonkean.app').controller('workerHistoryCtrl', lateConstructController(workerHistoryCtrl));
