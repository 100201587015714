<div class="recurrence-time-selection">
    <!-- Frequency type drop down -->
    <div class="flex-vmiddle margin-bottom-md">
        <ui-select
            class="common-width-auto"
            data-automation="recurrence-time-selection-add-frequency-row"
            ng-model="data.selectedFrequency"
            theme="bootstrap"
            ng-change="onRecurrenceTimeSelectionChanged()"
        >
            <ui-select-match placeholder="Enter frequency...">
                {{ data.frequencyOptionToDisplayNameMap[data.selectedFrequency] }}
            </ui-select-match>
            <ui-select-choices repeat="p in data.frequenciesOptions | filter:$select.search">
                <div
                    ng-bind-html="data.frequencyOptionToDisplayNameMap[p] | highlight: $select.search"
                    data-automation="recurrence-time-selection-template-select-frequency"
                ></div>
            </ui-select-choices>
        </ui-select>
    </div>
    <div ng-if="data.validationObject.recurrencePeriodType" class="common-color-danger margin-bottom-xs margin-top-xs">
        {{ data.validationObject.recurrencePeriodType }}
    </div>

    <!-- Do not run on saturday & sunday -->
    <div class="margin-top-md">
        <tnk-checkbox
            label="'Do not run on weekends'"
            value="data.doNotRunOnWeekends"
            on-toggle="onRecurrenceTimeSelectionChanged(value)"
        ></tnk-checkbox>
    </div>

    <div class="common-color-light-grey common-size-xxxxs margin-bottom-md">
        In a case where recurrence time is on a weekend, it will run on the next work day.
    </div>

    <!-- Frequency type -->
    <div ng-switch="data.selectedFrequency">
        <!-- Every X minutes -->
        <div ng-switch-when="EveryXMinutes">
            <div class="flex-vmiddle-no-xs">
                <div class="common-bold margin-right margin-xs-top">Minutes:</div>
                <input
                    type="number"
                    class="form-control"
                    ng-model="data.selectedEveryXMinutes"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    data-automation="recurrence-time-selection-add-minus-frequency"
                />
            </div>
            <i
                class="common-size-xxxxs margin-top-xs"
                ng-class="'common-color-light-grey'"
                ng-if="!data.validationObject.invalidEveryXMinutes"
            >
                * Must be {{data.minEveryXMinutesInterval}} or greater
            </i>
            <div
                ng-if="data.validationObject.invalidEveryXMinutes"
                class="common-color-danger margin-bottom-xs margin-top-xs"
            >
                {{ data.validationObject.invalidEveryXMinutes }}
            </div>
        </div>

        <!-- Every X Hours -->
        <div ng-switch-when="EveryXHours">
            <div class="flex-vmiddle-no-xs">
                <div class="common-bold margin-right margin-xs-top">Hours:</div>

                <input
                    type="number"
                    class="form-control"
                    ng-model="data.selectedEveryXHours"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                />
            </div>
            <i
                class="common-size-xxxxs margin-top-xs"
                ng-class="'common-color-light-grey'"
                ng-if="!data.validationObject.invalidEveryXHours"
            >
                * Must be greater than 0
            </i>
            <div
                ng-if="data.validationObject.invalidEveryXHours"
                class="common-color-danger margin-bottom-xs margin-top-xs"
            >
                {{ data.validationObject.invalidEveryXHours }}
            </div>
        </div>

        <!-- Daily -->
        <div ng-switch-when="Daily">
            <div class="flex-vmiddle-no-xs margin-bottom-xs margin-top-xs">
                <span class="common-bold margin-right">Choose time:</span>

                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-model="data.selectedHourInDay"
                    theme="bootstrap"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                >
                    <ui-select-match>
                        {{ $select.selected.label }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item.value as item in data.hoursInDay | filter:$select.search"
                    >
                        <div ng-bind-html="item.label | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
                <span class="common-bold margin-left margin-right">:</span>
                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedMinuteOfHourOfDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item in data.minutesOfHourOfDay | filter:$select.search"
                    >
                        <div ng-bind-html="item | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <div ng-hide="data.hideTimezoneIfNotConfigured && !data.existingRecurrenceTimeSelection.timezone">
                <div class="common-bold margin-bottom-xs margin-top-xs">Pick time zone:</div>

                <ui-select
                    class="common-width-auto"
                    ng-model="data.selectedTimezone"
                    theme="bootstrap"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                >
                    <ui-select-match placeholder="Select time zone...">
                        {{ data.selectedTimezone }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.timezones | filter:$select.search">
                        <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- Weekly -->
        <div ng-switch-when="Weekly">
            <div class="common-bold margin-right margin-bottom-xs margin-xs-top">Choose days:</div>

            <!-- Days Selection -->
            <div class="btn-group">
                <button
                    type="button"
                    ng-repeat="(dayInWeekName, dayInWeekSelection) in data.selectedDaysInWeek"
                    class="btn btn-default common-no-outline"
                    ng-class="{ 'btn-primary': dayInWeekSelection.selected }"
                    ng-model="ignore"
                    ng-click="selectDayInWeek(dayInWeekSelection)"
                >
                    {{ dayInWeekName }}
                </button>
            </div>

            <!-- TimeSpan Selection -->
            <div class="flex-vmiddle-no-xs margin-bottom-xs margin-top-xs">
                <span class="common-bold margin-right">Choose time:</span>

                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedHourInDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected.label }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item.value as item in data.hoursInDay | filter:$select.search"
                    >
                        <div ng-bind-html="item.label | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
                <span class="common-bold margin-left margin-right">:</span>
                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedMinuteOfHourOfDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item in data.minutesOfHourOfDay | filter:$select.search"
                    >
                        <div ng-bind-html="item | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <div ng-hide="data.hideTimezoneIfNotConfigured && !data.existingRecurrenceTimeSelection.timezone">
                <div class="common-bold margin-bottom-xs margin-top-xs">Pick time zone:</div>

                <ui-select
                    class="common-width-auto"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedTimezone"
                    theme="bootstrap"
                >
                    <ui-select-match placeholder="Select time zone...">
                        {{ data.selectedTimezone }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.timezones | filter:$select.search">
                        <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- Monthly -->
        <div ng-switch-when="Monthly">
            <!-- Month selection -->
            <div class="common-bold margin-right margin-bottom-xs margin-xs-top">Choose months:</div>

            <!-- Months Selection -->
            <div class="btn-group margin-bottom">
                <button
                    type="button"
                    ng-repeat="(monthInYearName, monthInYearSelection) in data.selectedMonthsInYear"
                    ng-if="$index < 6"
                    class="btn btn-default common-no-outline"
                    ng-class="{ 'btn-primary': monthInYearSelection.selected }"
                    ng-model="ignore"
                    ng-click="selectMonthInYear(monthInYearSelection)"
                >
                    {{ monthInYearName }}
                </button>
            </div>
            <div class="btn-group margin-bottom-xs">
                <button
                    type="button"
                    ng-repeat="(monthInYearName, monthInYearSelection) in data.selectedMonthsInYear"
                    ng-if="$index >= 6"
                    class="btn btn-default common-no-outline"
                    ng-class="{ 'btn-primary': monthInYearSelection.selected }"
                    ng-model="ignore"
                    ng-click="selectMonthInYear(monthInYearSelection)"
                >
                    {{ monthInYearName }}
                </button>
            </div>

            <!-- Day Selection -->
            <div class="flex-vmiddle-no-xs margin-top">
                <div class="common-bold margin-right margin-bottom-xs margin-xs-top">Choose day:</div>

                <select
                    class="form-control common-width-auto margin-bottom-xs"
                    ng-options="day for day in data.daysInMonth"
                    ng-model="data.selectedDayInMonth"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                ></select>
            </div>

            <!-- TimeSpan Selection -->
            <div class="flex-vmiddle-no-xs margin-bottom-xs margin-top-xs">
                <span class="common-bold margin-right">Choose time:</span>

                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedHourInDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected.label }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item.value as item in data.hoursInDay | filter:$select.search"
                    >
                        <div ng-bind-html="item.label | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
                <span class="common-bold margin-left margin-right">:</span>
                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedMinuteOfHourOfDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item in data.minutesOfHourOfDay | filter:$select.search"
                    >
                        <div ng-bind-html="item | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <div ng-hide="data.hideTimezoneIfNotConfigured && !data.existingRecurrenceTimeSelection.timezone">
                <div class="common-bold margin-bottom-xs margin-top-xs">Pick time zone:</div>

                <ui-select
                    class="common-width-auto"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedTimezone"
                    theme="bootstrap"
                >
                    <ui-select-match placeholder="Select time zone...">
                        {{ data.selectedTimezone }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.timezones | filter:$select.search">
                        <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- Quarterly -->
        <div ng-switch-when="Quarterly">
            <!-- TimeSpan Selection -->
            <div class="flex-vmiddle-no-xs margin-bottom-xs margin-top-xs">
                <span class="common-bold margin-right">Choose time:</span>

                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedHourInDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected.label }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item.value as item in data.hoursInDay | filter:$select.search"
                    >
                        <div ng-bind-html="item.label | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
                <span class="common-bold margin-left margin-right">:</span>
                <ui-select
                    class="common-width-auto flex-basis-0 flex-grow"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedMinuteOfHourOfDay"
                    theme="bootstrap"
                >
                    <ui-select-match>
                        {{ $select.selected }}
                    </ui-select-match>
                    <ui-select-choices
                        class="common-width-auto mod-match-select-width"
                        repeat="item in data.minutesOfHourOfDay | filter:$select.search"
                    >
                        <div ng-bind-html="item | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <div ng-hide="data.hideTimezoneIfNotConfigured && !data.existingRecurrenceTimeSelection.timezone">
                <div class="common-bold margin-bottom-xs margin-top-xs">Pick time zone:</div>

                <ui-select
                    class="common-width-auto"
                    ng-change="onRecurrenceTimeSelectionChanged()"
                    ng-model="data.selectedTimezone"
                    theme="bootstrap"
                >
                    <ui-select-match placeholder="Select time zone...">
                        {{ data.selectedTimezone }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.timezones | filter:$select.search">
                        <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <div class="common-color-light-grey common-size-xxxxs">Will run on the 1st of Jan, Apr, Jul and Oct</div>
        </div>
    </div>
</div>
