function RunOnExistingItemModalCtrl($scope, $uibModalInstance, trackHelper, tonkeanService, initiative) {
    $scope.trackHelper = trackHelper;

    $scope.data = {
        initiative,

        loadingRunAgain: false,
        errorLoadingRunAgain: false,
        shouldReRunTriggers: false,
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    $scope.runAgain = function () {
        $scope.data.loadingRunAgain = true;
        $scope.data.errorLoadingRunAgain = false;

        tonkeanService
            .runOnExistingItemAgain(
                $scope.data.initiative.group.id,
                [$scope.data.initiative.id],
                $scope.data.shouldReRunTriggers,
            )
            .then(() => {
                $scope.cancel();
            })
            .catch(() => {
                $scope.data.errorLoadingRunAgain = true;
            })
            .finally(() => {
                $scope.data.loadingRunAgain = false;
            });
    };
}

export default angular.module('tonkean.app').controller('RunOnExistingItemModalCtrl', RunOnExistingItemModalCtrl);
