import React, { useMemo } from 'react';
import styled from 'styled-components';

import EmptySolution from './GridComponents/EmptySolution';
import GridHeaders from './GridConfiguration/GridHeaders';
import MetricsRow from './MetricsRow/MetricsRow';
import RuntimePageSolutionName from '../../RuntimePageTable/RuntimePageSolutionName';

import {
    CollapsibleContent,
    ZebraTable,
    ZebraTableHeader,
    ZebraTableHeaderCell,
    ZebraTableHeaderRow,
} from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

interface Props {
    folderName: string;
    folderId: string;
    modules: Group[];
    filterModulesTerm: string;
    environment: Environment;
    collapsed: boolean;
    onToggle: (folderId: string) => void;
}

const headers = Object.keys(GridHeaders);

const SolutionMetricsGrid: React.FC<Props> = ({
    folderName,
    modules,
    environment,
    filterModulesTerm,
    collapsed,
    onToggle,
    folderId,
}) => {
    // Filter the modules by a given search term
    const filteredModules = useMemo(
        () =>
            modules.filter(
                (module) => !filterModulesTerm || module.name.toLowerCase().includes(filterModulesTerm.toLowerCase()),
            ),
        [filterModulesTerm, modules],
    );

    return (
        <>
            <Wrapper>
                <RuntimePageSolutionName onToggle={() => onToggle(folderId)} collapsed={collapsed}>
                    {folderName}
                </RuntimePageSolutionName>
                <CollapsibleContent open={!collapsed} animateOpacity>
                    <ZebraTable>
                        <ZebraTableHeader>
                            <ZebraTableHeaderRow>
                                {headers.map(
                                    (headerKey) =>
                                        GridHeaders[headerKey].isVisible() && (
                                            <ZebraTableHeaderCell
                                                key={headerKey}
                                                width={GridHeaders[headerKey].fixedWidth ? 150 : undefined}
                                            >
                                                {GridHeaders[headerKey].title(filteredModules, modules)}
                                            </ZebraTableHeaderCell>
                                        ),
                                )}
                            </ZebraTableHeaderRow>
                        </ZebraTableHeader>

                        <tbody>
                            {!modules.length && <EmptySolution />}
                            {filteredModules
                                .filter((module) => module.isVisibleToUser)
                                .map((module) => (
                                    <MetricsRow
                                        key={module.id}
                                        module={module}
                                        environment={environment}
                                        folderId={folderId}
                                    />
                                ))}
                        </tbody>
                    </ZebraTable>
                </CollapsibleContent>
            </Wrapper>
        </>
    );
};

export default SolutionMetricsGrid;
