import template from './integrationAggregateField.template.html?angularjs';

/**
 * Definition of an integration aggregation field.
 */
export default angular.module('tonkean.app').component('tnkIntegrationAggregateField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        workflowVersionId: '@',
        targetType: '<',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        isWorkerMode: '<',
        aggregatedEntity: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',

        onChange: '<',
    },
    template,
    controller: 'IntegrationAggregateFieldCtrl',
});
