import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionEntityOutputTypeSelect from '../ProjectIntegrationCreateAction/components/ActionEntityOutputTypeSelect';
import type ActionOutput from '../ProjectIntegrationCreateAction/entities/ActionOutput';

import { useAsyncMethod, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { ErrorMessage, Modal, ModalBody, ModalFooterActions, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import type { ProjectIntegration, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { ActionType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { getSmallestAvailableName } from '@tonkean/utils';

const StyledModalHeader = styled(ModalHeader)`
    display: flex;
    align-items: center;
`;

const Label = styled.div`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    margin-bottom: 14px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-top: 8px;
`;

interface Props {
    projectIntegrationAction: ProjectIntegrationAction;
    projectIntegrationActions: ProjectIntegrationAction[];
    projectIntegration: ProjectIntegration;
    isOpen: boolean;
    onClose: () => void;
}

const DuplicateProjectIntegrationAction: React.FC<Props> = ({
    projectIntegrationAction,
    projectIntegrationActions,
    projectIntegration,
    isOpen,
    onClose,
}) => {
    const integrationMetadataManager = useAngularService('integrationMetadataManager');
    const emitToastMessage = useToastMessage();

    const [actionOutput, setActionOutput] = useState<ActionOutput | undefined>();

    const { data: supportedEntitiesDict } = useAsyncMethod(
        integrationMetadataManager,
        'getIntegrationEntitiesAsDict',
        projectIntegrationAction.projectIntegrationId,
    );

    const ifEntityWasRemoved = useMemo(() => {
        const previousActionEntity = projectIntegrationAction.parametersDefinition?.entitiesToRunOn?.[0];
        return previousActionEntity && previousActionEntity !== actionOutput?.entity?.entity;
    }, [actionOutput, projectIntegrationAction]);

    const customActionTypesToExclude = [ActionType.UPLOAD, ActionType.DOWNLOAD, ActionType.STREAM_IMAGE];

    // Init the default select boxes to the current action's configuration
    useEffect(() => {
        const entityName = projectIntegrationAction.parametersDefinition.entitiesToRunOn?.[0] || '';

        if (entityName) {
            if (supportedEntitiesDict) {
                setActionOutput({
                    type: projectIntegrationAction.actionType,
                    entity: supportedEntitiesDict[entityName],
                });
            }
        } else {
            setActionOutput({
                type: projectIntegrationAction.actionType,
            });
        }
    }, [supportedEntitiesDict, projectIntegrationAction]);

    const $state = useAngularService('$state');

    const [
        { loading: loadingCreateProjectIntegrationAction, error: errorCreateProjectIntegrationAction },
        createProjectIntegrationAction,
    ] = useLazyTonkeanService('createProjectIntegrationAction');

    const projectIntegrationActionNames: string[] = useMemo(() => {
        return projectIntegrationActions.map((action) => action.displayName) || [];
    }, [projectIntegrationActions]);

    const onSubmit = async () => {
        if (actionOutput) {
            const entitiesToRunOn = actionOutput.entity ? [actionOutput.entity.entity] : [];
            const { id } = await createProjectIntegrationAction(
                projectIntegrationAction.projectIntegrationId,
                getSmallestAvailableName(projectIntegrationAction.displayName, [
                    ...projectIntegrationActionNames,
                    projectIntegrationAction.displayName,
                ]),
                projectIntegrationAction.description,
                projectIntegrationAction.actionDefinition,
                {
                    ...projectIntegrationAction.parametersDefinition,
                    entitiesToRunOn,
                },
                actionOutput.type,
                projectIntegrationAction.responseHandlingDefinition,
            );

            $state.go('product.projectIntegrationActionManager', {
                tab: 'general',
                projectIntegrationActionId: id,
                projectIntegrationId: projectIntegrationAction.projectIntegrationId,
            });

            onClose();
        } else {
            emitToastMessage('There was an error trying to duplicate the action');
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} size={ModalSize.SMALL} fixedWidth>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    return onSubmit();
                }}
            >
                <StyledModalHeader $border>Duplicate Action</StyledModalHeader>

                <ModalBody>
                    <Label>The action will be:</Label>
                    {actionOutput?.type && (
                        <ActionEntityOutputTypeSelect
                            projectIntegration={projectIntegration}
                            defaultActionType={actionOutput?.type}
                            defaultEntity={actionOutput?.entity}
                            onChange={(changedActionOutput) => setActionOutput(changedActionOutput)}
                            customActionTypesToExclude={customActionTypesToExclude}
                            shouldNotChooseDefaultEntity
                        />
                    )}

                    {ifEntityWasRemoved && (
                        <StyledErrorMessage warn>
                            Removing the entity will delete entity related fields configured in the duplicated action
                        </StyledErrorMessage>
                    )}
                </ModalBody>

                <ModalFooterActions
                    onCancel={onClose}
                    saveLabel="Duplicate"
                    loading={loadingCreateProjectIntegrationAction}
                    error={errorCreateProjectIntegrationAction}
                    saveDisabled={!actionOutput?.entity && actionOutput?.type !== ActionType.CUSTOM}
                />
            </form>
        </Modal>
    );
};

export default DuplicateProjectIntegrationAction;
