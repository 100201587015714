/**
 * Will trigger the given action when the target or any of the children are clicked (capture phase).
 * Clicks in js occur from the bottom up, so checks the event target element and all the element's parents until reached target (or not).
 *
 * enable-click-on-target-if (String) - If provided will trigger action if the original element has this class.
 * dont-click-target-if (String) - If provided will NOT trigger action if any of the elements in the hierarchy have this class.
 */
function clickOnTargetOnly($parse) {
    return {
        restrict: 'A',
        link($scope, elem, attr) {
            const callback = $parse(attr.clickOnTargetOnly);
            const enableClickOnTargetIf = attr.enableClickOnTargetIf;
            const dontClickTargetIf = attr.dontClickTargetIf;

            elem[0].addEventListener(
                'click',
                function (event) {
                    if (enableClickOnTargetIf && !elem[0].classList.contains(enableClickOnTargetIf)) {
                        return;
                    }

                    if (parentIsNotTarget(elem[0], event.target, dontClickTargetIf)) {
                        // Because the callback came from $parse we need to call it in this weird way.
                        callback($scope, { event });
                    }
                },
                true,
            );
        },
    };

    function parentIsNotTarget(element, target, dontClickTargetIf) {
        if (!target || !element) {
            return false;
        }

        if (target.classList && target.classList.contains(dontClickTargetIf)) {
            return false;
        }

        if (element === target) {
            return true;
        }

        return parentIsNotTarget(element, target.parentElement, dontClickTargetIf);
    }
}
export default angular.module('tonkean.app').directive('clickOnTargetOnly', clickOnTargetOnly);
