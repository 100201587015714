import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SpecificGroupFieldsMetadataTableCtrl(
    $scope,
    projectManager,
    customTriggerManager,
    utils,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.ctm = customTriggerManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.utils = utils;

    $scope.data = {
        onFieldSelected: ctrl.onFieldSelected,
        applyTriggerDependenciesFilter: ctrl.applyTriggerDependenciesFilter,
        fieldEditorHasPendingChanges: ctrl.fieldEditorHasPendingChanges,
        onTabSelected: ctrl.onTabSelected,
        reloadFields: ctrl.reloadFields,
        cantEditInProductionEnvironmentMessage: ctrl.cantEditInProductionEnvironmentMessage,
        fieldFilterByName: ctrl.fieldFilterByName,
        selectedFieldDefinitionForDependencies: ctrl.selectedFieldDefinitionForDependencies,
        filteredCustomFields: ctrl.filteredCustomFields,
        dropdownFieldOpened: ctrl.dropdownFieldOpened,
        columns: ctrl.columns,
        showYourFields: ctrl.showYourFields,
        showFields: ctrl.showFields,
        showHideFields: ctrl.showHideFields,
        canModifyBot: ctrl.canModifyBot,
        isDraft: ctrl.isDraft,
        groupId: ctrl.groupId,
        workflowVersion: ctrl.workflowVersion,
        workflowVersionId: ctrl.workflowVersion.id,
        applyFieldDependenciesFilter: ctrl.applyFieldDependenciesFilter,
        fieldDefinitionDependenciesCounter: ctrl.fieldDefinitionDependenciesCounter,
        triggerDependenciesCounter: ctrl.triggerDependenciesCounter,
        loadDependenciesDropdownList: ctrl.loadDependenciesDropdownList,
        fieldType: ctrl.fieldType,
        openDuplicateFieldModal: ctrl.openDuplicateFieldModal,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.showYourFields) {
            $scope.data.showYourFields = changesObj.showYourFields.currentValue;
        }

        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
        }

        if (changesObj.workflowVersion) {
            $scope.data.workflowVersion = changesObj.workflowVersion.currentValue;
        }

        if (changesObj.columns) {
            $scope.data.columns = changesObj.columns.currentValue;
        }

        if (changesObj.filteredCustomFields) {
            $scope.data.filteredCustomFields = changesObj.filteredCustomFields.currentValue;
        }

        if (changesObj.fieldDefinitionDependenciesCounter) {
            $scope.data.fieldDefinitionDependenciesCounter = changesObj.fieldDefinitionDependenciesCounter.currentValue;
        }

        if (changesObj.triggerDependenciesCounter) {
            $scope.data.triggerDependenciesCounter = changesObj.triggerDependenciesCounter.currentValue;
        }
    };

    $scope.onHideFieldsClick = function () {
        $scope.data?.showHideFields($scope.data.showFields);
    };

    $scope.getFieldsGroupTitleName = function () {
        if ($scope.data?.fieldType?.toLowerCase() === 'aggregate') {
            return 'Aggregation fields';
        }

        return 'Manual fields';
    };
}

export default angular
    .module('tonkean.app')
    .controller('SpecificGroupFieldsMetadataTableCtrl', lateConstructController(SpecificGroupFieldsMetadataTableCtrl));
