import OrigamiRiskAuthenticationComponent from './OrigamiRiskAuthenticationComponent';
import OrigamiRiskCustomizeSetupComponent from './OrigamiRiskCustomizeSetupComponent';
import origamiRiskCircleIcon from '../../../../apps/tracks/images/integrations/origamirisk-circle.png';
import origamiRiskIcon from '../../../../apps/tracks/images/origamirisk.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class OrigamiRiskIntegration extends IntegrationDefinitionBase {
    override name = 'origamirisk';
    override displayName = 'Origami Risk';
    override description = `We'll collect data from the Location domains by default.`;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [new IntegrationEntity('Location', 'Locations')];
    override hasDynamicEntities = true;

    override dataSourceSectionType = DataSourcesSectionType.DATABASE;
    override customizedAuthenticationComponent = OrigamiRiskAuthenticationComponent;
    override customizedSetupComponent = OrigamiRiskCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(origamiRiskCircleIcon, '130px'),
        new IntegrationIcon(origamiRiskIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { Location: true },
        isOnlyDataRetention: {},
    };
}

export default OrigamiRiskIntegration;
