import React from 'react';

import CustomInterfaceView from './CustomInterfaceView';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { ItemInterfaceSection } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const CustomInterfaceViewState: React.FC = () => {
    const [itemInterfaceId, initiativeId, originWidget, projectId, cleanMode] = useGetStateParams<
        [
            TonkeanId<TonkeanType.ITEM_INTERFACE>,
            TonkeanId<TonkeanType.INITIATIVE>,
            TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
            TonkeanId<TonkeanType.PROJECT>,
            boolean,
        ]
    >('itemInterfaceId', 'initiativeId', 'originWidget', 'projectId', 'cleanMode');

    return (
        <CustomInterfaceView
            itemInterfaceId={itemInterfaceId}
            initiativeId={initiativeId}
            originWidget={originWidget}
            projectId={projectId}
            cleanMode={cleanMode}
            showPanel={!cleanMode}
            section={ItemInterfaceSection.MAIN}
            showReturnToHomepageButton
        />
    );
};

export default CustomInterfaceViewState;
