import React from 'react';
import styled from 'styled-components';

import { InputWrapper } from './InputWrapper';
import { Label } from './Label';
import { WidgetConfigurationSelector } from '../../../CommonWidgetConfiguration';
import { InnerItemWidgetConfigurationItemCreationType } from '../../../entities';

import { Field, Input } from '@tonkean/infrastructure';
import type { ItemInterfaceWidgetOptionsConfiguration } from '@tonkean/tonkean-entities';

const StyledInput = styled(Input)`
    margin-top: 8px;
    width: 100%;
`;

interface Props {
    supportImmediateItemCreation?: boolean;
}

const itemCreationTypeOptions: ItemInterfaceWidgetOptionsConfiguration[] = [
    { value: InnerItemWidgetConfigurationItemCreationType.MODAL, label: 'Modal' },
    { value: InnerItemWidgetConfigurationItemCreationType.IMMEDIATE, label: 'Inside widget' },
];

const ItemCreationButtonCreateForm: React.FC<Props> = ({ supportImmediateItemCreation = false }) => {
    return (
        <>
            <Field inlineError>
                <InputWrapper>
                    <Label>Item creation button "Create" form</Label>
                    <StyledInput
                        title='Item creation button Create" form'
                        name="configuration.itemCreationButtonLabel"
                        autoComplete="off"
                        data-automation="item-builder-widget-configuration-header-edit-title"
                    />
                </InputWrapper>
            </Field>
            {supportImmediateItemCreation && (
                <WidgetConfigurationSelector
                    dataAutomation="inner-items-widget-configuration-creation-type"
                    title="Creation method"
                    name="configuration.itemCreationType"
                    options={itemCreationTypeOptions}
                />
            )}
        </>
    );
};

export default ItemCreationButtonCreateForm;
