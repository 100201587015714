import styled from 'styled-components';

import { Breakpoint, LayoutGridElements } from '@tonkean/infrastructure';

const ItemInterfaceWrapper = styled.div`
    // CSS to set widget panel sizes
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    @media screen and (min-width: ${Breakpoint.MINIATURE_0}px) and (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }
    @media screen and (min-width: ${Breakpoint.XSMALL_640}px) and (max-width: ${Breakpoint.MEDIUM_1920}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export default ItemInterfaceWrapper;
