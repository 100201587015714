import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';

import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    onAnimationEnd?: () => void;
    skipAnimation?: boolean;
}

const AppearFromTopAnimation: React.FC<PropsWithChildren<Props>> = ({
    className,
    children,
    onAnimationEnd,
    skipAnimation,
    dataAutomation,
}) => {
    const [animationFinished, setAnimationFinished] = useState(false);

    useEffect(() => {
        if (skipAnimation) {
            setAnimationFinished(true);
            onAnimationEnd?.();
        }
    }, [skipAnimation, setAnimationFinished, onAnimationEnd]);

    if (skipAnimation) {
        return <div className={className}>{children}</div>;
    }

    return (
        <motion.div
            data-automation={`${dataAutomation ?? 'appear-from-top-animation'}-${animationFinished ? 'finished' : 'animating'}`}
            className={className}
            initial={{
                height: 0,
                opacity: 0,
            }}
            animate={{
                height: 'auto',
                opacity: 1,
                transition: {
                    delay: 0.2,
                    height: {
                        duration: 0.4,
                    },
                    opacity: {
                        duration: 0.25,
                        delay: 0.35,
                    },
                },
            }}
            onAnimationStart={() => setAnimationFinished(false)}
            onAnimationComplete={() => {
                setAnimationFinished(true);
                onAnimationEnd?.();
            }}
        >
            {children}
        </motion.div>
    );
};

export default AppearFromTopAnimation;
