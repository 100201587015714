import React from 'react';
import styled from 'styled-components';

import { Breakpoint, Placeholder } from '@tonkean/infrastructure';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const NodePlaceholder = styled(Placeholder)`
    max-width: min(350px, 100%);
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        min-height: 40px;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const EdgePlaceholder = styled(Placeholder)`
    max-width: min(350px, 100%);
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        min-height: 5px;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

interface Props {}

const ProcessMapperLoadingSkeleton: React.FC<Props> = ({}) => {
    return (
        <Container>
            <NodePlaceholder key="node_placeholder_1" $width="160px" $height="130px" />
            <EdgePlaceholder key="edge_placeholder" $width="30px" $height="5px" />
            <NodePlaceholder key="node_placeholder_2" $width="160px" $height="130px" />
        </Container>
    );
};

export default ProcessMapperLoadingSkeleton;
