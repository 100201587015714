import React, { useContext } from 'react';
import type { StylesConfig } from 'react-select';
import styled from 'styled-components';

import FormSettingsOptionalSubTitle from './FormSettingsOptionalSubTitle';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';
import FormSettingsSelectWrapper from './FormSettingsSelectWrapper';

import { SavingIndicator, SimpleSelect } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { UpdateForm } from '@tonkean/tonkean-entities';
import { ItemsHierarchyFilter, ItemsHierarchyFilterToLabels } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ItemsHierarchyFilterWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;

    display: flex;
    align-items: flex-end;
`;

const Wrapper = styled.div`
    height: 84px;
`;

interface Option {
    value: ItemsHierarchyFilter;
    label: string;
}

const options: Option[] = [
    {
        value: ItemsHierarchyFilter.GROUP_ITEMS,
        label: ItemsHierarchyFilterToLabels[ItemsHierarchyFilter.GROUP_ITEMS],
    },
    {
        value: ItemsHierarchyFilter.INNER_ITEMS,
        label: ItemsHierarchyFilterToLabels[ItemsHierarchyFilter.INNER_ITEMS],
    },
    {
        value: ItemsHierarchyFilter.ROOT_ITEMS,
        label: ItemsHierarchyFilterToLabels[ItemsHierarchyFilter.ROOT_ITEMS],
    },
    {
        value: ItemsHierarchyFilter.SINGLE_ITEM,
        label: ItemsHierarchyFilterToLabels[ItemsHierarchyFilter.SINGLE_ITEM],
    },
];

interface Props {
    form: UpdateForm;
    onFormDefinitionChanged: (value: ItemsHierarchyFilter) => void;
}

const FormSettingsItemHierarchyFilterSelect: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { editedSections } = useContext(FormBuilderContext);

    const styles: StylesConfig<Option> = {
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 2,
        }),
    };

    return (
        <Wrapper>
            <ItemsHierarchyFilterWrapper>
                <FormSettingsSectionHeaderTitle>Form Available On</FormSettingsSectionHeaderTitle>{' '}
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.FormAvailableOn]} />
            </ItemsHierarchyFilterWrapper>
            <FormSettingsSectionHeaderSubtitle>
                Choose which level of items will be available in the update form.
            </FormSettingsSectionHeaderSubtitle>
            <FormSettingsSelectWrapper>
                <SimpleSelect
                    value={form.definition.itemsHierarchyFilter}
                    options={options}
                    onChange={(value: ItemsHierarchyFilter) => onFormDefinitionChanged(value)}
                    placeholder="Search Item"
                    styles={styles}
                    thin
                />
            </FormSettingsSelectWrapper>
        </Wrapper>
    );
};

export default FormSettingsItemHierarchyFilterSelect;
