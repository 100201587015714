<div class="bot-tab-pane mod-history">
    <!-- Header -->
    <div class="bot-pane-header">
        <!--Title-->
        <div class="common-title-xs">Tonkean Module Feed</div>

        <div class="common-color-grey3 common-size-xxxs margin-right-lg">
            View the actions tonkean module did for you.
        </div>
    </div>

    <div class="padding-normal-h">
        <tnk-filtered-worker-history close-side-pane="data.closeSidePane"></tnk-filtered-worker-history>
    </div>
</div>
