import dayjs from 'dayjs';

const FieldChartOptions: any = (customTooltip, tooltipCallbacks, rangeFrom, rangeTo) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        animation: true,
        plugins: {
            tooltip: {
                enabled: false,
                intersect: false,
                external: customTooltip,
                callbacks: tooltipCallbacks,
            },

            legend: {
                display: false,
                position: 'top' as const,
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 4,
                top: 0,
                bottom: 0,
            },
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                beginAtZero: true,
            },
            x: {
                ticks: {
                    maxRotation: 90,
                    minRotation: 0,
                    callback: (value) => dayjs(value).format('MMM DD, YYYY'),
                },
                type: 'linear' as const,
                min: dayjs(rangeFrom).toDate().setHours(0, 0, 0, 0),
                max: dayjs(rangeTo).toDate(),
                offset: true,
            },
        },
        tension: 0.2,
    };
};

export default FieldChartOptions;
