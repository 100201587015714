<div class="tnk-sendgrid-custom-actions margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>
    <div ng-if="data.definition.customActionKey === 'SEND_EMAIL'">
        <!-- Email to (address and name) -->

        <tnk-send-email-recipients
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            identifier="sendgrid"
            configured-logic="data.configuredLogic"
            preview-evaluation-source="data.previewEvaluationSource"
            validation-object="data.validationObject"
            recipients-configuration="data.definition.recipientsConfiguration"
            cc-configuration="data.definition.ccConfiguration"
            bcc-configuration="data.definition.bccConfiguration"
            to-recipients-array="data.definition.toRecipientsArray"
            cc-recipients-array="data.definition.ccRecipientsArray"
            bcc-recipients-array="data.definition.bccRecipientsArray"
            on-recipients-configuration-changed="
                onRecipientsConfigurationChanged(configuration, shouldSaveLogic, array)
            "
            on-c-c-configuration-changed="onCCConfigurationChanged(configuration, shouldSaveLogic, array)"
            on-b-c-c-configuration-changed="onBCCConfigurationChanged(configuration, shouldSaveLogic, array)"
        ></tnk-send-email-recipients>

        <!-- separator -->
        <hr />

        <!-- Subject -->
        <div class="margin-bottom-xs">From email address to use:</div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="From email to send as"
            saved-original-expression="data.definition.fromEmail"
            saved-evaluated-expression="data.definition.fromEmailEvaluatedText"
            expression-unique-identifier="fromEmailAddress"
            on-tonkean-expression-changed="
                onFromEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <!-- Validation error -->
        <div ng-if="data.validationObject.noFromAddress" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noFromAddress }}
        </div>

        <!-- separator -->
        <hr />

        <!-- Subject -->
        <div class="margin-bottom-xs">
            From name to use:
            <i class="common-color-grey common-size-xxxxs">(optional)</i>
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="From name to include in the email (optional)"
            saved-original-expression="data.definition.fromName"
            saved-evaluated-expression="data.definition.fromNameEvaluatedText"
            expression-unique-identifier="fromName"
            on-tonkean-expression-changed="
                onFromNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <!-- separator -->
        <hr />

        <!-- Subject -->
        <div class="margin-bottom-xs">Subject of the email:</div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Type the subject to include in the email"
            saved-original-expression="data.definition.emailSubject"
            saved-evaluated-expression="data.definition.emailSubjectEvaluatedText"
            expression-unique-identifier="subject"
            on-tonkean-expression-changed="
                onSubjectTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <!-- Validation error -->
        <div ng-if="data.validationObject.noSubject" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noSubject }}
        </div>

        <!-- separator -->
        <hr />

        <div class="btn-group margin-bottom">
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': !data.definition.sendAsPlainText }"
                uib-btn-radio="false"
                ng-model="data.definition.sendAsPlainText"
                ng-click="onDefinitionChanged(true)"
            >
                HTML
            </label>
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': data.definition.sendAsPlainText }"
                uib-btn-radio="true"
                ng-click="onDefinitionChanged(true)"
                ng-model="data.definition.sendAsPlainText"
            >
                Plain-Text
            </label>
        </div>

        <!-- Title -->
        <div class="margin-bottom">Content of the email:</div>

        <!-- Plain test Input -->
        <div ng-if="data.definition.sendAsPlainText">
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the html to include in the email"
                saved-original-expression="data.definition.content"
                saved-evaluated-expression="data.definition.contentEvaluatedText"
                on-tonkean-expression-changed="
                    onContentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
                mod-text-area="true"
            ></tnk-tonkean-expression>
        </div>

        <!-- HTML Editor-->
        <div ng-if="!data.definition.sendAsPlainText">
            <button class="btn btn-primary btn-slim" ng-click="openHtmlEditor()">Open Editor</button>
        </div>

        <!-- separator -->
        <hr />

        <!-- Reply to -->
        <div>
            <!-- Reply to title-->
            <div>
                Reply-To:
                <i class="common-color-grey common-size-xxxxs">(optional)</i>
            </div>

            <!-- Reply to sub title-->
            <div class="common-size-xxxxs gray common-color-grey margin-bottom-xs">
                Reply-to is enabled only when monitored thread messages is off
            </div>

            <!-- Input -->
            <!-- Show when monitored thread reply is off -->
            <tnk-tonkean-expression
                ng-show="!data.monitorRepliesOnOriginalMessage.isActive"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Reply-To address"
                saved-original-expression="data.definition.replyTo"
                saved-evaluated-expression="data.definition.replyToEvaluatedText"
                expression-unique-identifier="replyTo"
                on-tonkean-expression-changed="
                    onReplyToTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <!-- Show when monitored thread reply is on -->
            <div ng-show="data.monitorRepliesOnOriginalMessage.isActive" class="reply-to-disabled-textbox">
                {{ data.definition.replyToEvaluatedText }}
            </div>

            <hr />
        </div>

        <!-- Add unsubscribe link-->
        <div>
            <label class="common-normal-weight margin-top-lg">
                <input
                    type="checkbox"
                    ng-model="data.definition.addUnsubscribeLink"
                    ng-change="onAddUnsubscribeLinkChanged()"
                />
                <span>Add an unsubscribe link to your email</span>
            </label>
        </div>

        <hr />

        <!-- Attachments -->
        <tnk-send-email-attachments
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            configured-logic-id="data.configuredLogic.node.id"
            preview-evaluation-source="data.previewEvaluationSource"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            existing-attachments-configuration="data.definition.attachments"
            on-attachments-changed="onAttachmentsChanged(attachments, shouldSaveLogic)"
        ></tnk-send-email-attachments>

        <!-- separator -->
        <hr />

        <!-- Monitor email replies -->
        <tnk-thread-reply
            on-toggled="onDefinitionChanged(shouldSaveLogic, childrenCustomTriggersToCreate)"
            workflow-version-id="{{ data.workflowVersionId }}"
            configured-logic="data.configuredLogic"
            definition="data.definition"
            monitor-replies-on-original-message="data.monitorRepliesOnOriginalMessage"
            group-id="data.groupId"
        ></tnk-thread-reply>
    </div>
</div>
