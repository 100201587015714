import SolutionBusinessReportPopover from './SolutionBusinessReportPopover';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkSolutionBusinessReportPopover',
        reactToAngular(SolutionBusinessReportPopover, ['workflowFolder', 'onCreated']),
    );
