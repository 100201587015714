/**
 * A directive that handles ESC key presses of the user and calls the given callback.
 * The ESC also doesn't allow the event to bubble any forward, guaranteeing it doesn't allow modals to also close.
 */
function tnkEscClose($parse, $document, $timeout) {
    return {
        restrict: 'A',
        scope: {
            callback: '&tnkEscClose',
        },
        link(scope, element, attrs) {
            // Save the callback supplied to us.
            const callbackName = attrs.tnkEscClose;

            const onDocumentKeyDown = (event) => {
                if (
                    (event.code === 'Escape' || event.keyCode === 27) && // Call the callback if it was defined.
                    callbackName
                ) {
                    // If there's a callback, don't allow any other listeners to do something.
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    // Call the callback function with a timeout, so it runs in the next digest (we're currently in js and not in a digest loop).
                    $timeout(() => {
                        scope.callback({ event });
                    });
                }
            };

            // Get the document element.
            const doc = $document[0];
            // Register to the documents keydown so we can catch the esc pressed.
            // Passing "true" as the 3rd parameter causes our event to jump in the capture phase, causing it to happen before all other listeners.
            // This is needed if we want to cancel others listening to keydown and ESC.
            doc.addEventListener('keydown', onDocumentKeyDown, true);
            // On destroy of this directive remove the keydown listener.
            // We also pass "true" in the 3rd parameter when removing the listener.
            scope.$on('$destroy', () => doc.removeEventListener('keydown', onDocumentKeyDown, true));
        },
    };
}

export default angular.module('tonkean.app').directive('tnkEscClose', tnkEscClose);
