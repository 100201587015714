import React from 'react';
import styled, { css } from 'styled-components';

import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { isUrlValid } from '@tonkean/utils';

const StyledClickableLink = styled(ClickableLink)<{ isLink: boolean }>`
    ${({ isLink }) =>
        !isLink &&
        css`
            color: black;

            &:hover {
                color: black;
            }
        `}
`;

const StringFieldCellRenderer: React.FC<ItemsGridCellRendererParams> = ({ value }) => {
    if (value && isUrlValid(value)) {
        return (
            <StyledClickableLink
                href={value}
                onClickCapture={(e) => {
                    e.stopPropagation();
                }}
                isLink
                openInNewTab
            >
                {value}
            </StyledClickableLink>
        );
    } else {
        return <>{value}</>;
    }
};

export default StringFieldCellRenderer;
