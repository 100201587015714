import { css } from 'styled-components';

import { ALPINE_THEME, HEADER, HEADER_CELL, HEADER_CELL_RESIZE, HEADER_ROW } from '@tonkean/items-grid';
import { Theme } from '@tonkean/tui-theme';

const itemsTableGridStyles = css`
    .${ALPINE_THEME} {
        --ag-row-hover-color: ${Theme.colors.gray_100};
        --ag-header-background-color: var(${Theme.colors.gray_100}, #fcfcfb);
        --ag-header-foreground-color: ${Theme.colors.gray_600};
        --ag-header-row-font-weight: 300;

        .${HEADER} {
            background-color: white;
            border-bottom: 1px solid var(${Theme.colors.gray_300}, #e9ecf1);
            box-shadow: 1px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        .${HEADER_ROW} {
            font-weight: 500;
        }

        .${HEADER_CELL}:hover {
            .ag-header-cell-resize {
                visibility: visible;
            }
        }

        .${HEADER_CELL_RESIZE} {
            visibility: hidden;
        }
    }
`;

export default itemsTableGridStyles;
