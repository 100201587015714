import React from 'react';

import { StateMessage } from '../StateMessages';

import { NoItemsEmptyIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    description?: string;
}
const NoItemEmptyState: React.FC<Props> = ({ description }) => (
    <StateMessage
        data-automation="no-item-view-empty-state"
        titleColor={Theme.colors.gray_700}
        icon={<NoItemsEmptyIcon />}
        title="No item available"
        secondaryColor={Theme.colors.gray_600}
    >
        {description}
    </StateMessage>
);

export default NoItemEmptyState;
