import template from './tnkPreviewForm.template.html?angularjs';
import { lateConstructController } from '@tonkean/angular-components';

export const tnkPreviewFormCtrlComponent = angular.module('tonkean.app').component('tnkPreviewFormCtrl', {
    bindings: {
        formId: '<',
        form: '<',
        formType: '<',
        isInFormBuilder: '<',
        groupId: '<',
        workflowVersionId: '<',
        sendToType: '<',
    },
    template,
    controller: 'tnkPreviewFormCtrl',
});

/* @ngInject */
const tnkPreviewFormCtrl = function (
    $scope,
    customFieldsManager,
    utils,
    tonkeanService,
    customTriggerManager,
    projectManager,
) {
    const ctrl = this;
    $scope.utils = utils;

    $scope.data = {
        formId: ctrl.formId,
        form: ctrl.form,
        formType: ctrl.formType,
        slackSubmitFormButtonLabel: 'Submit',
        shouldDisplaySlackSubmitFormButtonWarning: false,
        fieldsIds: ctrl.form?.definition.fields.map((formField) => formField.fieldDefinitionIdentifier),
        isInFormBuilder: ctrl.isInFormBuilder,
        groupId: ctrl.groupId,

        loadingFields: false,

        evaluatedDescription: '',
        evaluatedExpandableDescription: '',
        workflowVersionId: ctrl.workflowVersionId,
        sendToType: ctrl.sendToType,
    };

    ctrl.$onInit = function () {
        $scope.loadWrappedFormFields();
        $scope.loadDescriptions();
        setSlackSubmitFormButtonLabel();
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.form && changesObj.form.currentValue) {
            $scope.data.form = changesObj.form.currentValue;
            $scope.data.fieldsIds = changesObj.form.currentValue.definition.fields.map(
                (formField) => formField.fieldDefinitionIdentifier,
            );
            $scope.loadWrappedFormFields();
            $scope.loadDescriptions();
            setSlackSubmitFormButtonLabel();
        }

        if (changesObj.formType && changesObj.formType.currentValue) {
            $scope.data.formType = changesObj.formType.currentValue;
        }

        if (changesObj.sendToType?.currentValue) {
            $scope.data.sendToType = changesObj.sendToType.currentValue;
        }
    };

    $scope.loadWrappedFormFields = async () => {
        $scope.data.loadingFields = true;
        try {
            const fieldsIdsToGet = $scope.data.form?.definition.fields.map((field) => field.id);
            let wrappedFields = [];
            if (fieldsIdsToGet?.length > 0 && $scope.data.form?.workflowVersion) {
                // Make sure fields are in cache
                await customFieldsManager.getFieldDefinitions($scope.data.form.workflowVersion.id, false);

                // Get the fields from the cache mapped correctly
                wrappedFields = $scope.data.form.definition.fields.map((formField) => {
                    const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
                        $scope.data.form.workflowVersion.id,
                        formField.fieldDefinitionIdentifier,
                    );
                    return {
                        fieldDefinition,
                        defaultValue: formField.defaultValue,
                        isRequired: formField.isRequired,
                        displayName: formField.displayName,
                    };
                });
            }
            $scope.data.wrappedFields = wrappedFields;
        } finally {
            $scope.data.loadingFields = false;
        }
    };

    $scope.loadDescriptions = async () => {
        if (
            $scope.data.form?.descriptionExpression?.evaluatedExpression ||
            $scope.data.form?.expandableDescriptionExpression?.evaluatedExpression
        ) {
            const expressions = [
                $scope.data.form.descriptionExpression?.evaluatedExpression && {
                    key: 'description',
                    expression: $scope.data.form.descriptionExpression.evaluatedExpression,
                },
                $scope.data.form?.expandableDescriptionExpression?.evaluatedExpression && {
                    key: 'expandableDescriptionExpression',
                    expression: $scope.data.form.expandableDescriptionExpression.evaluatedExpression,
                },
            ].filter(Boolean);

            let data;
            const initiativeId =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]?.root;
            if (initiativeId) {
                data = await tonkeanService.getEvaluatedExpressionsForInitiative(
                    projectManager.project.id,
                    initiativeId,
                    expressions,
                    true,
                );
            } else if ($scope.data.groupId) {
                data = await tonkeanService.getEvaluatedExpressions($scope.data.groupId, expressions);
            }

            if (data?.evaluatedExpressions?.description) {
                $scope.data.evaluatedDescription = data.evaluatedExpressions.description;
            }
            if (data?.evaluatedExpressions?.expandableDescriptionExpression) {
                $scope.data.evaluatedExpandableDescription = data.evaluatedExpressions.expandableDescriptionExpression;
            }
        }
    };

    function setSlackSubmitFormButtonLabel() {
        const submitFormButtonLabel = $scope.data.form?.definition.submitFormButtonLabel;
        if (submitFormButtonLabel) {
            // If the label exists, we take the first word (split it by empty space).
            // If there are no empty spaces, the text will remain the same.
            $scope.data.slackSubmitFormButtonLabel = submitFormButtonLabel.split(' ')[0];
            // If the text contains spaces we would like to display a warning.
            if (submitFormButtonLabel.includes(' ')) {
                $scope.data.shouldDisplaySlackSubmitFormButtonWarning = true;
            }
        } else if ($scope.data.form?.definition) {
            // If we have a form but deleted the form submit button label
            $scope.data.slackSubmitFormButtonLabel = 'Submit';
        }
    }
};

export const tnkPreviewFormCtrlControl = angular
    .module('tonkean.app')
    .controller('tnkPreviewFormCtrl', lateConstructController(tnkPreviewFormCtrl));
