const extractHeaderFromArray = (headers: Record<string, string[]>): Record<string, string> => {
    if (headers) {
        const entries = Object.entries(headers).map(([headerKey, headerValueArray]) => {
            return [headerKey, headerValueArray.join(', ')];
        });

        return Object.fromEntries(entries);
    } else {
        return {};
    }
};

export default extractHeaderFromArray;
