import React from 'react';
import styled from 'styled-components';

import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import WidgetRequiredIndication from '../../components/WidgetRequiredIndication';
import { ItemWidgetHeaderTitle } from '../../WidgetModule';

import { TextEllipsis } from '@tonkean/infrastructure';
import type { WidgetBase } from '@tonkean/tonkean-entities';

const RichTextEditorHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

interface Props {
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
}

const RichTextEditorHeaderTitle: React.FC<Props> = ({ widget }) => {
    return (
        <RichTextEditorHeader data-automation="rich-text-editor-header-title">
            <ItemWidgetHeaderTitle>
                {widget.configuration?.wrapDisplayName ? (
                    widget.displayName
                ) : (
                    <TextEllipsis numberOfLines={1}>{widget.displayName}</TextEllipsis>
                )}
            </ItemWidgetHeaderTitle>
            {widget.configuration.required && (
                <WidgetRequiredIndication type={widget.configuration.requiredIndicatorType} />
            )}
        </RichTextEditorHeader>
    );
};

export default RichTextEditorHeaderTitle;
