<div class="tnk-import-action-config-form-curl">
    <!-- Description -->
    <div class="margin-bottom-md margin-top-md">
        Use this to import an existing cURL request easily.
        <div>* cURL is a command-line tool for transferring data specified with URL syntax.</div>
    </div>

    <!-- Raw Text -->
    <div>
        <label>Raw Text</label>
        <textarea
            class="import-text-input block padding-normal-sm common-resize-none"
            ng-model="data.importRawText"
            ng-change="previewCurlParsing()"
            placeholder="curl -X POST http://www.yourwebsite.com/login/ -d 'username=yourusername&password=yourpassword'"
        ></textarea>
    </div>

    <!-- Preview -->
    <div class="margin-top-md">
        <label>Preview</label>
        <textarea disabled class="block common-resize-none import-preview-input">{{ data.previewText }}</textarea>
    </div>
</div>
