<!-- Data source -->
<div
    class="input-source-config-panel"
    ng-if="pm.groupsMap[data.groupId].workerType === data.workerTypes.FULL_TIME.key || data.configureWorker"
>
    <div
        ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'INTEGRATION'"
        class="common-title-sm margin-bottom"
    >
        Intake Source - Data Source
    </div>

    <div
        ng-if="
            wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'UNKNOWN' &&
            (!wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled || data.editScheduled)
        "
        class="common-title-sm margin-bottom"
    >
        Configure Intake Source
    </div>

    <div
        ng-if="
            wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'MANUAL' ||
            (wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled && !data.editScheduled)
        "
    >
        <div
            ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'MANUAL'"
            class="common-title-sm margin-bottom"
        >
            Intake Source - Manual
        </div>

        <div
            ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled"
            class="common-title-sm margin-bottom"
        >
            Intake Source - Scheduled
        </div>
    </div>

    <!-- Description -->
    <div class="margin-bottom-lg">
        Configure the intake source to feed your module with items you wish your module to monitor
    </div>

    <!-- Simple worker (not scheduled) -->
    <div ng-if="!wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled || data.editScheduled">
        <div ng-if="data.invalidLogics['DATASOURCE'].noDataSource" class="margin-bottom common-color-danger">
            {{ data.invalidLogics['DATASOURCE'].noDataSource }}
        </div>

        <!-- If the data source (project integration) is inaccessible, means the workflow folder (=solution)
         does not have permissions -->
        <div
            ng-show="
                (scm.getSyncConfig(data.workflowVersionId).projectIntegration.id &&
                    data.invalidLogics['DATASOURCE'].inAccessibleProjectIntegration) ||
                data.workflowFolderProjectIntegrationsAccess.inAccessibleProjectIntegrations.includes(
                    scm.getSyncConfig(data.workflowVersionId).projectIntegration.id
                )
            "
            class="margin-bottom"
        >
            <div class="info-box margin-normal-sm flex">
                <tnk-icon class="margin-right-xs margin-top-xxxs" src="/images/icons/lock.svg"></tnk-icon>
                <span>
                    This data source is inaccessible by modules under this solution (require data source admin).
                    <a
                        target="_blank"
                        class="flex-vmiddle text-right"
                        ng-click="goToProjectIntegrationPageWithReturnTo()"
                    >
                        Edit accessibility
                    </a>
                </span>
            </div>
        </div>

        <div ng-if="data.loading" class="loading-large"></div>

        <div ng-if="!data.loading">
            <!-- Manual Selected -->
            <div ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'MANUAL'">
                <div class="margin-bottom-lg">
                    <span>
                        <a ng-click="goBack()" class="btn btn-default btn-sm" data-automation="tnk-data-source-worker-component-template-edit-source-type">Edit Source Type</a>
                    </span>
                </div>

                <div class="info-box">
                    <div class="common-color-dark-grey">
                        <h4 class="mod-no-top">How to create items manually</h4>
                        <div>All of the following ways are available with this Module:</div>
                        <ul>
                            <li class="margin-bottom">
                                Create using custom
                                <strong>Create Forms</strong>
                                via Slack / Web (
                                <a ng-click="selectOutlineTab('forms')">Configure</a>
                                )
                            </li>
                            <li class="margin-bottom">
                                Create using the
                                <a
                                    ui-sref="product.board({g: data.groupId, filter:'all', st: null, env: $stateParams.env})"
                                    target="_blank"
                                >
                                    Business Report
                                </a>
                                interface
                            </li>
                            <li class="margin-bottom">
                                Another Tonkean module can create items here, using the "Tonkean Data Action"
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Integration Selected (Collapsed) -->
            <div
                ng-if="
                    wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType === 'INTEGRATION' &&
                    !data.editMode
                "
            >
                <!-- Collpased Integration view-->
                <div class="margin-top common-size-xs">
                    <div class="flex mod-justify-space">
                        <span class="common-size-xs common-bold">Your Module Intake Source</span>
                        <span>
                            <a ng-click="setEditMode()">Edit</a>
                        </span>
                    </div>

                    <div class="flex-vmiddle margin-top">
                        <!-- Integration Icon (for when there's an integration) -->
                        <span>
                            <i
                                class="inline-block margin-right-xs mod-normal initiative-integration-icon"
                                ng-class="
                                    !scm.getSyncConfig(data.workflowVersionId).projectIntegration.iconUrl
                                        ? 'mod-' +
                                          scm
                                              .getSyncConfig(data.workflowVersionId)
                                              .projectIntegration.integrationType.toLowerCase()
                                        : ''
                                "
                                ng-style="{
                                    background: scm.getSyncConfig(data.workflowVersionId).projectIntegration.iconUrl
                                        ? 'url(' +
                                          scm.getSyncConfig(data.workflowVersionId).projectIntegration.iconUrl +
                                          ') no-repeat center'
                                        : null
                                }"
                            ></i>
                        </span>
                        <span class="flex-grow padding-left-xs">
                            <!-- Integration Name-->
                            <span>
                                {{
                                    scm.getSyncConfig(data.workflowVersionId).viewData.integrationType !== 'WEBHOOK'
                                        ? (scm.getSyncConfig(data.workflowVersionId).projectIntegration.displayName
                                              ? scm.getSyncConfig(data.workflowVersionId).projectIntegration.displayName
                                              : scm.getSyncConfig(data.workflowVersionId).viewData.integrationType) +
                                          ' - '
                                        : ''
                                }}
                            </span>
                            <span
                                ng-if="
                                    (scm.getSyncConfig(data.workflowVersionId).viewData.query.filtersCount &&
                                        scm.getSyncConfig(data.workflowVersionId).viewData.query.filtersCount > 0) ||
                                    (scm.getSyncConfig(data.workflowVersionId).viewData.timeRangeFilter &&
                                        scm.getSyncConfig(data.workflowVersionId).viewData.timeRangeFilter.range !==
                                            'NoRange')
                                "
                            >
                                Filtered
                            </span>
                            <span
                                ng-if="
                                    !scm.getSyncConfig(data.workflowVersionId).viewData.query.filtersCount &&
                                    (!scm.getSyncConfig(data.workflowVersionId).viewData.timeRangeFilter ||
                                        scm.getSyncConfig(data.workflowVersionId).viewData.timeRangeFilter.range ===
                                            'NoRange')
                                "
                            >
                                All
                            </span>
                            {{
                                scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.pluralLabel ||
                                    scm.getSyncConfig(data.workflowVersionId).viewData['Entity'] ||
                                    utils.pluralize(scm.getSyncConfig(data.workflowVersionId).viewData['entity'])
                            }}
                        </span>
                    </div>
                </div>

                <div ng-if="scm.getSyncConfig(data.workflowVersionId).projectIntegration.integrationType === 'EMAILWEBHOOK'" class="margin-top-6">
                    <div class="common-size-xxs common-bold">Send an email to this address</div>
                    <tnk-copy-input value="scm.getSyncConfig(data.workflowVersionId).projectIntegration.projectData.projectDatas[0].titleFieldPath" />
                </div>

                <hr ng-if="scm.getSyncConfig(data.workflowVersionId).viewData" />
                <!-- Owners-->
                <div class="margin-bottom-lg" ng-if="scm.getSyncConfig(data.workflowVersionId).viewData">
                    <div class="common-size-xs flex-vmiddle">
                        <span class="flex-grow common-bold">Coordination Fields Settings</span>
                        <span>
                            <a ng-click="setCoordinationFieldSettings()">
                                {{ data.editSyncSettings ? 'Close' : 'Edit' }}
                            </a>
                        </span>
                    </div>

                    <div ng-show="!data.editSyncSettings" class="margin-top">
                        <p ng-if="scm.getSyncConfig(data.workflowVersionId).viewData.titleExpression">
                            <strong class="common-color-black common-size-14">Custom Title:</strong>
                            <br />
                            {{ scm.getSyncConfig(data.workflowVersionId).viewData.titleExpression }}
                        </p>
                    </div>

                    <div ng-show="data.editSyncSettings">
                        <tnk-import-tracks
                            group-id="data.groupId"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            view-type="scm.getSyncConfig(data.workflowVersionId).viewType"
                            sync-data="scm.getSyncConfig(data.workflowVersionId).viewData"
                            connected-integration-unique-type="scm.getSyncConfig(data.workflowVersionId).projectIntegration.integration.integrationUniqueType"
                            only-sync="true"
                            only-import="false"
                            section-visibility="{ titleSelection: true, uniqueIdentifierType: true, creator: true, deleteItemsSelectionType: true }"
                            hide-section-headers="true"
                            hide-manual-import-option="true"
                            hide-time-range-selection="false"
                            show-inner-back="true"
                            no-container-padding="true"
                            hide-remove-btn="true"
                            btn-label="Preview Items & Save"
                            on-complete-callback="onSyncComplete()"
                            set-should-reload-fields="ctrl.setShouldReloadFields"
                        ></tnk-import-tracks>
                    </div>
                </div>
            </div>

            <!-- Edit Mode-->
            <div ng-if="data.editMode">
                <!-- Manual option -->
                <div class="margin-bottom" ng-if="!data.targetDataSourceForEdit && !data.selectedIntegration">
                    <button
                        class="
                            btn btn-default
                            margin-right-xs
                            common-size-xxs
                            margin-bottom-xs
                            integration-selection-button
                        "
                        ng-click="chooseManualDataSource()"
                        data-automation="tnk-data-source-worker-component-template-manual-button"
                    >
                        <i class="margin-right svg-icon">
                            <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                        </i>
                        <div>Manual (via Forms, Business Report, or another module)</div>
                    </button>
                </div>
                <!-- Scheduled option -->
                <div class="margin-bottom" ng-if="!data.targetDataSourceForEdit && !data.selectedIntegration">
                    <button
                        class="
                            btn btn-default
                            margin-right-xs
                            common-size-xxs
                            margin-bottom-xs
                            integration-selection-button
                        "
                        ng-click="onWorkerTypeOptionSelection(true)"
                        data-automation="tnk-data-source-worker-component-template-scheduled-button"
                    >
                        <i class="margin-right svg-icon">
                            <tnk-icon src="/images/icons/clock.svg"></tnk-icon>
                        </i>
                        <div>Scheduled (Add item every X time)</div>
                    </button>
                </div>

                <!-- Integration option -->
                <div ng-if="!data.targetDataSourceForEdit || data.targetDataSourceForEdit === 'INTEGRATION'">
                    <tnk-import-tracks
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        on-complete-callback="onComponentClosed()"
                        on-delete-callback="onIntegrationSelected(null)"
                        on-saved-callback="hideManualImportOption = true"
                        on-integration-selected-callback="onIntegrationSelected(projectIntegration)"
                        view-type="scm.getSyncConfig(data.workflowVersionId).viewType"
                        sync-data="scm.getSyncConfig(data.workflowVersionId).viewData"
                        connected-integration-unique-type="scm.getSyncConfig(data.workflowVersionId).projectIntegration.integration.integrationUniqueType"
                        only-sync="true"
                        only-import="false"
                        show-inner-back="true"
                        section-visibility="{sync: true, items: true }"
                        hide-section-headers="true"
                        hide-bot-in-preview="true"
                        hide-manual-import-option="true"
                        hide-time-range-selection="false"
                        btn-label="Preview Items & Save"
                        disable-fields="true"
                        dismiss="onIntegrationSelected(null)"
                        mod-small="true"
                        no-container-padding="true"
                        reload-fields="reloadFields()"
                    ></tnk-import-tracks>
                </div>
            </div>
        </div>
    </div>

    <!-- Scheduled worker -->
    <div ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled && !data.editScheduled">
        <div class="margin-bottom-lg flex-vmiddle">
            <span>
                <a ng-click="setEditScheduled()" class="btn btn-default btn-sm">Edit Source Type</a>
            </span>

            <div class="flex-grow"></div>

            <!-- Loading state -->
            <div ng-if="data.sendingRunWorkerNow">
                <i class="loading-small margin-right-xs"></i>
            </div>
        </div>

        <!-- Separator -->
        <hr />

        <div class="common-title-xs margin-bottom-lg">When do you want the module to run?</div>

        <!-- Recurrence time selection -->
        <tnk-recurrence-time-selection
            existing-recurrence-time-selection="wvm.getCachedWorkflowVersion(data.workflowVersionId)"
            on-recurrence-time-selection-changed="onRecurrenceTimeSelectionChanged(recurrenceTimeSelection)"
            hide-timezone-if-not-configured="wvm.getCachedWorkflowVersion(data.workflowVersionId).workflowVersionType !== 'DRAFT'"
            validation-object="data.invalidLogics['DATASOURCE']"
            min-every-x-minutes-interval="pm.project.features.tonkean_feature_minimum_scheduled_input_source_interval_minutes || 30"
        ></tnk-recurrence-time-selection>

        <!-- Title -->
        <div class="margin-bottom-xs margin-top-xs">
            <div class="common-title-xxs">Scheduled Item Name</div>
            <span class="common-size-xxs common-color-grey3">
                When the scheduled time arrive a new item will be created with the following title.
            </span>
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Type item name"
            saved-original-expression="data.scheduledTrackName"
            saved-evaluated-expression="data.evaluatedScheduledTrackName"
            global-expression-only="true"
            on-tonkean-expression-changed="
                onScheduledTrackNameExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <div ng-if="data.invalidLogics['DATASOURCE'].noFrequency" class="margin-bottom common-color-danger">
            {{ data.invalidLogics['DATASOURCE'].noFrequency }}
        </div>

        <!-- Loading state -->
        <div class="flex-vmiddle margin-top" ng-if="data.savingRecurrenceTimeSelection">
            <i class="loading-small margin-right-xs"></i>
            <span>Saving...</span>
        </div>

        <!-- Error state -->
        <div
            class="common-color-danger common-size-xxxs margin-top"
            ng-if="!data.savingRecurrenceTimeSelection && data.errorSavingRecurrenceTimeSelection"
        >
            {{ data.errorSavingRecurrenceTimeSelection }}
        </div>

        <!-- Error state -->
        <div
            class="common-color-danger common-size-xxxs"
            ng-if="!data.sendingRunWorkerNow && data.errorSendingRunWorkerNow"
        >
            {{ data.errorSendingRunWorkerNow }}
        </div>
    </div>
</div>
