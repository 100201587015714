import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class ArrayDiffFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.ARRAY_DIFF;
    override readonly sign = 'ArrayDiff';
    override readonly displayName = 'Array Diff';
    override readonly description =
        'Will return items from the first array (base array) that are missing from the second array (match array) as a comma-separated array string.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Base Array',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Match Array',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Separator',
            dataType: FieldType.String,
            defaultValue: ',',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
    ];
}
