import { angularToReact } from '@tonkean/angular-components';
import type { Person } from '@tonkean/tonkean-entities';

interface Props {
    avatars: Person[];
    avatarsDesktopLimit?: number;
    displayMoreLimit?: number;
    showAllInDisplayMore?: boolean;
    showMoreAvatarsLink?: boolean;
    shouldDisplayViewTime?: boolean;
    separatedAvatars?: boolean;
    size?: 'small' | 'medium' | 'large' | 'extra-large';
}

const TonkeanAvatarsBar = angularToReact<Props>('tnk-avatars-bar');

export default TonkeanAvatarsBar;
