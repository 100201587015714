import { DeprecatedDate } from '@tonkean/utils';
import { analyticsWrapper } from '@tonkean/analytics';
import { REMINDERS } from '@tonkean/constants';
import {
    calculateNextReminderHour,
    initiativeSyncedWithExternalSource,
    getInitiativeOriginalGroupStates,
} from '@tonkean/tonkean-utils';

function FunctionCardCtrl(
    $scope,
    $rootScope,
    $q,
    $timeout,
    $filter,
    timeUtils,
    trackHelper,
    integrations,
    tonkeanService,
    utils,
    modal,
    requestThrottler,
    workflowVersionManager,
) {
    const DATE_SUGGESTION_REQUEST_ID = 'functionCardDateSuggestion';

    $scope.selectedPeople = [];
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;
    $scope.wvm = workflowVersionManager;
    $scope.trackHelper = $rootScope.trackHelper;
    $scope.utils = utils;
    $scope.initiative = $scope.trackHelper.getInitiativeFromCache($scope.initiative.id) || $scope.initiative;

    $scope.minDate = new Date();
    $scope.toggles = {};
    $scope.etaDays = {};
    $scope.funcDescription = {};
    $scope.funcState = $scope.initialState || {};
    $scope.toggles.change = $scope.changeToggle;
    $scope.responseData = null;

    const workflowVersion = $scope.initiative.isDraftInitiative
        ? workflowVersionManager.getDraftVersionFromCache($scope.initiative.group.id)
        : workflowVersionManager.getPublishedVersionFromCache($scope.initiative.group.id);
    $scope.data = {
        mode: 'update',
        tempText: null,
        tempNextReminder: {
            date: null, // The date displayed to the user (don't change the name beacuse tnkSetReminderPopover depends on it)
            suggestedDate: null, // From the server, suggested from the temp text
        },
        showUpdateText: false,
        selectedFollowUpStepIndex: -1,
        followUpLoading: false,
        followUpSteps: [],
        errorLoadingAvailableExternalStatuses: false,
        externalStatuses: [],
        originalGroupStates: getInitiativeOriginalGroupStates(
            $scope.pm.groupsMap[$scope.initiative.group.id],
            workflowVersion,
            $scope.pm.groupsMap[$scope.currentGroupId],
            workflowVersionManager.getPublishedVersionFromCache($scope.currentGroupId),
        ),
        workflowVersion,
        initiativeExternalActivity: null,
        selectedExternalStatus: null,
        updatingExternalStatus: false,
        errorUpdatingExternalStatus: false,
        customNextReminderIsOpen: false,
        customReminder: null,
        didUserSetReminderManually: false,

        atMentioned: [],
        atMentionedInEdit: false,
        atMentionedNextOwner: null,
        atMentionedOwnerIndex: -1,
        // we don't want to do anything on click
        atMentionedTrackOverrideClick: () => {},

        savingAutomaticallyUpdateExternalSettings: false,
        errorSavingAutomaticallyUpdateExternalSettings: false,
    };

    $scope.possibleFollowUpSteps = [
        {
            // At mention in update text -> should I create a follow-up item
            shouldIncludeStep: (initiative) => {
                if (initiative.status === 'DONE') {
                    return false;
                }

                // make sure the mentions are still in the update text (if the user removed the at mention string it will still be here)
                const foundAtMentions = [];
                for (let i = 0; i < $scope.data.atMentioned.length; i++) {
                    const mention = $scope.data.atMentioned[i];
                    if ($scope.initiative.updateText.includes(mention.atMentionName)) {
                        foundAtMentions.push(mention);
                    }
                }
                $scope.data.atMentioned = foundAtMentions;
                return $scope.data.atMentioned && $scope.data.atMentioned.length > 0;
            },
            questionHtml: null, // Will be set once initialized so we wouldn't calculate it each digest.
            getQuestionHtml: () => {
                return [
                    `${'<div>' + '<span class="at-mention">'}${utils.joinNames(
                        $scope.data.atMentioned.map((mention) => mention.atMentionName),
                        0,
                    )}</span> ` +
                        `Will be notified.` +
                        `</div>` +
                        `<div>Need to follow up with anything else?</div>`,
                ];
            },
            answerTemplate: 'createInnerTracks',
            onLoad: () => {
                $scope.setNextAtMentionedOwner();
                $scope.data.showUpdateText = true;
            },
        },
        {
            // Done -> Update external status?
            shouldIncludeStep: shouldIncludeUpdateExternalStatusStep,
            questionHtml: null,
            getQuestionHtml: () => [
                `Need to change the <strong>${$scope.initiative.externalSourceFriendly}</strong> status as well?`,
            ],
            answerTemplate: 'externalStatus',
            onLoad: externalStatusStepOnLoad,
        },
        {
            // Ping still relevant? -> move ping?
            shouldIncludeStep: (initiative) => {
                const now = DeprecatedDate.nowAsDate();
                const pingInNext24h =
                    initiative.nextGatherUpdate && initiative.nextGatherUpdate < now.setDate(now.getDate() + 1);
                const isReasonManual = initiative.nextGatherUpdateReason === 'MANUAL';
                const isUserOwner = initiative.owner && initiative.owner.id === $scope.as.currentUser.id;
                return !$scope.data.didUserSetReminderManually && isReasonManual && isUserOwner && pingInNext24h;
            },
            questionHtml: null,
            getQuestionHtml: () => {
                const asker =
                    $scope.initiative.nextGatherUpdateUpdater.id === $scope.as.currentUser.id
                        ? 'You'
                        : $scope.initiative.nextGatherUpdateUpdater.firstName;
                const day = timeUtils.daysDiffString($scope.initiative.nextGatherUpdate).toLowerCase();
                const time = $filter('date')($scope.initiative.nextGatherUpdate, 'shortTime');
                return [`${asker} asked me to ping you about this ${day} at ${time}.`, 'Do you still want me to?'];
            },
            answerTemplate: 'stillRelevant',
        },
    ];

    $scope.reminders = REMINDERS;

    /**
     * Initialization function for the controller.
     */
    $scope.init = function () {
        $scope.data.tempNextReminder.date = $scope.initiative.nextGatherUpdate;
        $scope.data.didUserSetReminderManually = false;

        // If skipUpdateTextStep = true, we skip to the follow-up step, and skip the update status.
        if ($scope.skipUpdateTextStep) {
            setFollowUpSteps();
            $scope.nextFollowUpStep();
            $scope.data.mode = 'follow-up';
        }
    };

    $scope.$watch('initiative.created', function () {
        if ($scope.initiative) {
            if (!$scope.initialState) {
                if ($scope.initiative.status === 'FUTURE') {
                    $scope.funcState = workflowVersionManager.getCachedWorkflowVersion(
                        $scope.data.workflowVersion.id,
                    )?.states[0];
                } else {
                    $scope.funcState.label = $scope.initiative.stateText;
                    $scope.funcState.color = $scope.initiative.stateColor;
                    $scope.funcState.type = $scope.initiative.status;
                }
            }

            if (
                (!$scope.initialState || $scope.initialState.label === $scope.initiative.stateText) &&
                $scope.initiative.updateText &&
                $scope.initiative.updateText.length &&
                $scope.initiative.updater &&
                $scope.initiative.updater.id === $scope.as.currentUser.id
            ) {
                // We use timeout here because if we don't mentio throws an exception.
                // What happens is the ngModel is binded before the mentio menu is loaded, which causes a one time exception
                // each load of the modal.
                $timeout(() => ($scope.data.tempText = $scope.initiative.updateText));
            }

            $scope.data.tempNextReminder.date = $scope.initiative.nextGatherUpdate;
        }
    });

    $scope.$watch('initiative.nextGatherUpdate', function () {
        $scope.data.tempNextReminder.date = $scope.initiative.nextGatherUpdate;
    });

    $scope.$watch('initiative.etaDays', function () {
        if ($scope.initiative && $scope.initiative.etaDays > -1) {
            const date = new Date();
            date.setDate(new Date().getDate() + $scope.initiative.etaDays);
            $scope.etaDays.date = date;
        }
    });

    $scope.$watch('data.customReminder', function () {
        if ($scope.data.customReminder) {
            const now = new Date();
            // in order to calculate correctly set the time to right now(new date sets the time to 00:00 and it fucks up calculations)
            $scope.data.customReminder.setHours(now.getHours());
            const daysDiff = timeUtils.daysDiff($scope.data.customReminder, now);
            let hours = 10;
            // if today
            if (daysDiff === 0) {
                hours = calculateNextReminderHour({ days: daysDiff });
            }
            $scope.setReminder(daysDiff, hours);
        }
    });

    $scope.onOwnerAdded = function () {
        $scope.addPeopleToFunction();
    };

    $scope.addPeopleToFunction = function () {
        const selectedPeople = $scope.selectedPeople;
        const initiative = $scope.initiative;
        trackHelper
            .setOwner(initiative, selectedPeople, $scope.isInSharedMode, $scope.solutionBusinessReportId)
            .then($scope.updateInitiativeData);
    };

    $scope.updateItemStatus = function (text, etaText, state, clearToggle) {
        let eta = null;
        if (etaText && etaText >= 0) {
            const date = new Date();
            date.setDate(new Date().getDate() + etaText);
            eta = date.getTime();
        }

        analyticsWrapper.track('Update status', { category: 'Update modal' });
        $scope.postingUpdate = true;
        let atMentionIds = [];
        if ($scope.data.atMentioned) {
            atMentionIds = $scope.data.atMentioned.map((mention) => mention.id);
        }
        const previousGatherUpdate = $scope.initiative.nextGatherUpdate;
        trackHelper
            .updateInitiativeState(
                $scope.initiative,
                state,
                text,
                eta,
                atMentionIds,
                undefined,
                $scope.isInSharedMode,
                $scope.solutionBusinessReportId,
            )
            .then((data) => {
                let promise = $q.resolve();
                if (isTempDateSuggestedOrDefault() && $scope.data.tempText && $scope.data.tempText !== '') {
                    requestThrottler.cancel(DATE_SUGGESTION_REQUEST_ID);
                    promise = tonkeanService.getDateSuggestion($scope.data.tempText).then((data) => {
                        if (data.date && data.date > DeprecatedDate.nowAsDate()) {
                            $scope.data.tempNextReminder.date = data.date;
                        }
                    });
                }
                return promise
                    .then(() => {
                        // If the user updated the next gather update, go to server and make that update
                        if ($scope.data.tempNextReminder.date === previousGatherUpdate) {
                            return $q.resolve(data);
                        }

                        return trackHelper.updateNextReminderTime($scope.initiative, $scope.data.tempNextReminder.date);
                    })
                    .then(
                        (nextReminderData) => ($scope.initiative.nextGatherUpdate = nextReminderData.nextGatherUpdate),
                    )
                    .then(() => {
                        return $q.resolve(data);
                    });
            })
            .then(function (data) {
                $scope.responseData = data;
                $scope.postingUpdate = false;

                if (clearToggle) {
                    $scope.toggles[clearToggle] = null;
                }
                $scope.data.tempText = null;

                // If user saved settings of updating in external system, we update the status there as well,
                // and not having him go through picking again.
                if (
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings &&
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings.automaticallyUpdateExternalStatus &&
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                        $scope.initiative.externalSource + $scope.initiative.stateText
                    ]
                ) {
                    trackHelper
                        .updateInitiativeExternalStatus(
                            $scope.initiative.id,
                            $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                                $scope.initiative.externalSource + $scope.initiative.stateText
                            ].statusId,
                        )
                        .then(() => {
                            $scope.$emit('alert', {
                                msg: `Successfully updated status in ${$scope.initiative.externalSourceFriendly}!`,
                                type: 'success',
                            });
                        })
                        .catch(() => {
                            $scope.$emit('alert', {
                                msg: 'There was an error trying to update external status in external system.',
                                type: 'danger',
                            });
                        });
                }

                setFollowUpSteps();
                $scope.nextFollowUpStep();
                if ($scope.data.followUpSteps && $scope.data.followUpSteps.length) {
                    $scope.data.mode = 'follow-up';
                }
            });
    };

    $scope.openSettingsModal = function (initiative, showSetReminder) {
        $scope.initiativeClicked = initiative;
        modal.openInitiativeSettingsModal(initiative, showSetReminder, $scope.data.tempNextReminder);
    };

    $scope.setFuncState = function (state) {
        $scope.funcState = state;
    };

    $scope.toggleState = function (key, id) {
        // console.log($scope.toggles[key]);
        if ($scope.toggles[key] === id) {
            $scope.toggles[key] = null;
        } else {
            $scope.toggles[key] = id;
        }
        // console.log($scope.toggles[key]);
    };

    $scope.inviteTempUsers = function () {
        analyticsWrapper.track('Invite temp users', { category: 'Update modal' });
        const tempUsers = [];
        const usedUsersIds = {};
        angular.forEach($scope.initiative.functions, function (func) {
            if (func.owner && func.owner.isTemporary && !usedUsersIds[func.owner.id]) {
                tempUsers.push({ name: func.owner.name, tempPersonId: func.owner.id });
                usedUsersIds[func.owner.id] = true;
            }
        });

        if (tempUsers.length) {
            modal.openInvite(tempUsers);
        }
    };

    $scope.updateInitiativeData = function (data, dontLoadActivity) {
        $scope.toggles = {};
        $scope.data.followUpSteps = [];
        $scope.data.atMentioned = [];
        $scope.data.mode = 'update';
        $scope.data.didUserSetReminderManually = false;
        if ($scope.updateInitiative) {
            $scope.updateInitiative({ data, dontLoadActivity });
        }
    };

    $scope.onSetReminderManually = function () {
        $scope.data.didUserSetReminderManually = true;
    };

    $scope.onSaveManualReminder = function () {
        if ($scope.fullPageMode) {
            $scope.data.nextReminderSaved = true;
            $timeout(function () {
                $scope.data.nextReminderSaved = false;
            }, 1500);
        }
    };

    $scope.onDateChange = function () {
        $scope.toggles.dateOpened = false;
        const now = new Date();

        if ($scope.etaDays.date) {
            const date = new Date($scope.etaDays.date);
            date.setHours(now.getHours() + 1);
            $scope.initiative.etaDays = timeUtils.daysDiff(date);
        } else {
            $scope.initiative.etaDays = 0;
        }
    };

    $scope.onTextChange = function () {
        // If the user hasn't set the date manually
        if (isTempDateSuggestedOrDefault() && $scope.data.tempText && $scope.data.tempText !== '') {
            requestThrottler.do(
                DATE_SUGGESTION_REQUEST_ID,
                500,
                () => tonkeanService.getDateSuggestion($scope.data.tempText),
                (data) => {
                    if (isTempDateSuggestedOrDefault()) {
                        // Reset to default if there is no suggested date
                        if (!data.date) {
                            $scope.data.tempNextReminder.date = $scope.initiative.nextGatherUpdate;
                        } else if (data.date > DeprecatedDate.nowAsDate()) {
                            $scope.data.tempNextReminder.date = data.date;
                        }

                        $scope.data.tempNextReminder.suggestedDate = data.date;
                    }
                },
            );
        }
    };

    $scope.onTextKeyDown = function ($event) {
        if (
            !$rootScope.isMobile &&
            ($event.code === 'Enter' || $event.keyCode === 13) &&
            ($event.ctrlKey || $event.metaKey)
        ) {
            $event.preventDefault();
            $scope.updateItemStatus($scope.data.tempText, $scope.initiative.etaDays, $scope.funcState, 'updateFocus');
        }
    };

    $scope.loadNextInnerItemsPage = function () {
        // before first page request hasMoreInnerItemsToLoad is undefined, but
        // we still want to get more pages. this makes sure that hasMoreInnerItems is
        // actually false and not falsy
        if ($scope.initiative.hasMoreInnerItemsToLoad === false) {
            return;
        }

        $scope.initiative.loadingRelated = true;
        const pageSize = $scope.pm.projectData.initiativesInnerItemsPageSize;

        return trackHelper
            .loadRelatedInitiatives($scope.initiative, false, $scope.initiative.relatedInitiatives.length, pageSize, [])
            .then(() => {
                const itemIdsToCountRelated = $scope.initiative.relatedInitiatives.map(({ id }) => id);
                trackHelper.getRelatedInitiativesCount($scope.initiative.project.id, itemIdsToCountRelated);
                $scope.initiative.loadingRelated = false;
            });
    };

    function isTempDateSuggestedOrDefault() {
        return (
            $scope.data.tempNextReminder.date === $scope.data.tempNextReminder.suggestedDate ||
            $scope.data.tempNextReminder.date === $scope.initiative.nextGatherUpdate
        );
    }

    /**
     * Initializes the follow-up steps.
     */
    function setFollowUpSteps() {
        $scope.data.followUpSteps = $scope.possibleFollowUpSteps.filter((step) =>
            step.shouldIncludeStep($scope.initiative),
        );
        $scope.data.selectedFollowUpStepIndex = -1;

        for (let i = 0; i < $scope.data.followUpSteps.length; i++) {
            const step = $scope.data.followUpSteps[i];
            step.questionHtml = step.getQuestionHtml();
        }
    }

    /**
     * Continues to the next follow-up step.
     */
    $scope.nextFollowUpStep = function () {
        $scope.data.selectedFollowUpStepIndex++;
        // keep going until last step
        if ($scope.data.selectedFollowUpStepIndex < $scope.data.followUpSteps.length) {
            if ($scope.data.followUpSteps[$scope.data.selectedFollowUpStepIndex].onLoad) {
                $scope.data.followUpSteps[$scope.data.selectedFollowUpStepIndex].onLoad();
            }
        } else {
            // close
            $scope.updateInitiativeData($scope.responseData);
        }
    };

    // region: Follow-up steps logic
    $scope.onReminderClick = function (reminder) {
        const hours = calculateNextReminderHour(reminder);
        $scope.setReminder(reminder.days, hours);
    };

    $scope.setReminder = function (days, hours) {
        $scope.data.followUpLoading = true;
        trackHelper
            .updateNextReminder($scope.initiative, days, hours)
            .then(() => {
                $scope.nextFollowUpStep();
                $scope.data.followUpLoading = false;
            })
            .catch(() => {
                $scope.$emit('alert', 'Failed to update reminder.');
                $scope.data.followUpLoading = false;
            });
    };

    $scope.setOwnerAndContinue = function (person) {
        trackHelper.setOwner($scope.initiative, [person], $scope.isInSharedMode, $scope.solutionBusinessReportId);
        $scope.nextFollowUpStep();
    };

    // /**
    //  * Should include follow up step after warn/on-hold status update.
    //  */
    // function shouldIncludeFollowUpOnWarnStep(initiative) {
    //     return initiative.status === 'WARN' || initiative.status === 'ON_HOLD';
    // }

    /**
     * Opens the modal for changing the external status update settings.
     */
    $scope.openChangeExternalStatusSettingsModal = function () {
        modal.openChangeExternalStatusSettingsModal($scope.initiative);
    };

    /**
     * Toggles automatically update external status settings.
     */
    $scope.updateAutomaticallyUpdateExternalStatus = function () {
        $scope.data.savingAutomaticallyUpdateExternalSettings = true;
        $scope.data.errorSavingAutomaticallyUpdateExternalSettings = false;

        tonkeanService
            .updateProfileMetadata($scope.as.currentUser.metadata)
            .then(() => {})
            .catch(() => {
                $scope.data.loadingSavingAutomaticallyUpdateExternalSettings = true;

                $scope.$emit('alert', {
                    msg: 'There was an error trying to save status update settings.',
                    type: 'danger',
                });
            })
            .finally(() => {
                $scope.data.savingAutomaticallyUpdateExternalSettings = false;
            });
    };

    /**
     * Selects the external status.
     * @param externalStatus
     */
    $scope.selectExternalStatus = function (externalStatus) {
        $scope.data.selectedExternalStatus = externalStatus;
    };

    /**
     * We should include update external status step only if it's an update to DONE status, initiative has external source & type, and we can find its project integration,
     * and it supports external status update, and user didn't choose to remember his choice.
     */
    function shouldIncludeUpdateExternalStatusStep(initiative) {
        if (initiative.status !== 'DONE') {
            return false;
        }
        if (!initiativeSyncedWithExternalSource(initiative)) {
            return false;
        }
        if (
            $scope.as.currentUser.metadata.externalStatusUpdateSettings &&
            $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                $scope.initiative.externalSource + $scope.initiative.stateText
            ]
        ) {
            return false;
        }
        if (
            $scope.as.currentUser.metadata.externalStatusUpdateSettings &&
            $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain &&
            $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain[
                $scope.initiative.externalSource + $scope.initiative.stateText
            ]
        ) {
            return false;
        }
        if (!integrations.initiativeSupportsExternalStatusChange(initiative)) {
            return false;
        }

        return true;
    }

    /**
     * Occurs once the update external status is loaded.
     */
    function externalStatusStepOnLoad() {
        $scope.data.showUpdateText = true;

        if (
            $scope.as.currentUser.metadata.externalStatusUpdateSettings &&
            !(
                $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain &&
                $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain[
                    $scope.initiative.externalSource + $scope.initiative.stateText
                ]
            ) &&
            $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                $scope.initiative.externalSource + $scope.initiative.stateText
            ]
        ) {
            $scope.data.selectedExternalStatus =
                $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                    $scope.initiative.externalSource + $scope.initiative.stateText
                ];
        }

        $scope.loadExternalStatuses();
    }

    /**
     * Loads available external statuses for an initiative.
     */
    $scope.loadExternalStatuses = function () {
        $scope.data.followUpLoading = true;
        $scope.data.errorLoadingAvailableExternalStatuses = false;

        return tonkeanService
            .getInitiativeAvailableExternalStatuses($scope.initiative.id)
            .then((data) => {
                $scope.data.externalStatuses = data.availableStatuses;
                $scope.data.initiativeExternalActivity = data.externalActivity;
            })
            .catch(() => {
                $scope.data.errorLoadingAvailableExternalStatuses = true;

                // $scope.$emit('alert', {
                //     msg: 'There was an error trying to get available statuses from external system.',
                //     type: 'danger'
                // });
            })
            .finally(() => {
                $scope.data.followUpLoading = false;
            });
    };

    /**
     * Updates the external status in an external system.
     */
    $scope.updateInitiativeExternalStatus = function (initiativeId) {
        // Updating the metadata for remember my choice
        if (!$scope.as.currentUser.metadata.externalStatusUpdateSettings) {
            $scope.as.currentUser.metadata.externalStatusUpdateSettings = {};
        }

        $scope.as.currentUser.metadata.externalStatusUpdateSettings.automaticallyUpdateExternalStatus = true;
        $scope.as.currentUser.metadata.externalStatusUpdateSettings[
            $scope.initiative.externalSource + $scope.initiative.stateText
        ] = $scope.data.selectedExternalStatus;
        $scope.data.updatingExternalStatus = true;
        $scope.data.errorUpdatingExternalStatus = false;

        // Updating the status in the external system
        $q.all([
            tonkeanService.updateProfileMetadata($scope.as.currentUser.metadata),
            trackHelper.updateInitiativeExternalStatus(initiativeId, $scope.data.selectedExternalStatus.statusId),
        ])
            .then(() => {
                $scope.$emit('alert', {
                    msg: `Successfully updated status in ${$scope.initiative.externalSourceFriendly}!`,
                    type: 'success',
                });

                $scope.nextFollowUpStep();
            })
            .catch(() => {
                $scope.data.errorUpdatingExternalStatus = true;

                $scope.$emit('alert', {
                    msg: 'There was an error trying to update external status in external system.',
                    type: 'danger',
                });
            })
            .finally(() => {
                $scope.data.updatingExternalStatus = false;
            });
    };

    /**
     * Saving don't ask again preference.
     */
    $scope.doNotAskAgain = function () {
        if (!$scope.as.currentUser.metadata.externalStatusUpdateSettings) {
            $scope.as.currentUser.metadata.externalStatusUpdateSettings = {};
        }
        if (!$scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain) {
            $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain = {};
        }

        $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain[
            $scope.initiative.externalSource + $scope.initiative.stateText
        ] = true;
        $scope.data.savingDontAskAgain = true;
        $scope.data.errorSavingDontAskAgain = false;

        tonkeanService
            .updateProfileMetadata($scope.as.currentUser.metadata)
            .then(() => {
                $scope.nextFollowUpStep();
            })
            .catch(() => {
                $scope.data.errorSavingDontAskAgain = true;

                $scope.$emit('alert', {
                    msg: 'There was an error trying to save status update settings.',
                    type: 'danger',
                });
            })
            .finally(() => {
                $scope.data.savingDontAskAgain = false;
            });
    };

    $scope.onInnerTrackInputFocus = function (event, ownerName) {
        if (!event.target.value || event.target.value === '') {
            event.target.value = `${utils.createAtMentionString(ownerName)} `;

            // Make sure the caret moves to the end of the @mention
            const selectionTarget = event.target.value.length; // cache real value
            $timeout(() => {
                event.target.selectionStart = selectionTarget;
                event.target.selectionEnd = selectionTarget;
            });
        }
    };

    $scope.startTrackEdit = function () {
        $scope.data.atMentionedInEdit = true;
        $timeout(() => {
            const element = document.querySelector('#theText-bot-atMention');
            element.focus();
        });
    };
    $scope.setNextAtMentionedOwner = function () {
        $scope.data.atMentionedOwnerIndex++;
        $scope.data.atMentionedNextOwner =
            $scope.data.atMentionedOwnerIndex < $scope.data.atMentioned.length
                ? $scope.data.atMentioned[$scope.data.atMentionedOwnerIndex]
                : null;
    };

    $scope.recalculateNextReminderAndContinue = function () {
        trackHelper.updateNextReminderTime($scope.initiative, null, true);
        $scope.nextFollowUpStep();
    };

    // endregion: Follow-up steps logic

    $scope.init();
}

angular.module('tonkean.app').controller('FunctionCardCtrl', FunctionCardCtrl);
