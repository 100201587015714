<div class="padding-normal">
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-dropbox"></div>
            <h4 class="flex-grow">Set Up Dropbox</h4>
        </div>
    </div>
    <tnk-dropbox-selector
        project-integration="projectIntegration"
        on-project-integration-created="onDropboxFolderSelected(projectIntegration)"
        on-cancel="onCancel()"
    ></tnk-dropbox-selector>
</div>
