import { Formik } from 'formik';
import React, { useCallback } from 'react';

import FormulaAIConversationView from './FormulaAIConversationView';

import { AIPromptInput } from '@tonkean/ai-builder';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Field, ModalBody, ModalSize, useCloseCallback, withModal } from '@tonkean/infrastructure';
import { useSmartConversation } from '@tonkean/smart-conversation';
import type {
    FormulaAIMessage,
    SmartConversationReplyMethodParams,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onUseFormulaClick: (formula: string) => void;
}

const FormulaAIBuilderModal: React.FC<Props> = ({ projectId, onUseFormulaClick }) => {
    const onClose = useCloseCallback();
    const onUseFormula = useCallback(
        (formula: string) => {
            onUseFormulaClick(formula);
            onClose();
        },
        [onClose, onUseFormulaClick],
    );

    const [{ loading, error, called }, generateFormula] = useLazyTonkeanService('generateFormula');

    const replyMethod = useCallback(
        ({ projectId: innerProjectId, userReply, conversationId }: SmartConversationReplyMethodParams) => {
            return generateFormula(innerProjectId, userReply, conversationId);
        },
        [generateFormula],
    );

    const smartConversationManager = useSmartConversation<FormulaAIMessage>(projectId, replyMethod, loading, error);

    return (
        <ModalBody>
            <Formik
                initialValues={{ userPrompt: '' }}
                onSubmit={({ userPrompt }) => {
                    smartConversationManager.callReplyMethod(userPrompt, '', true, true);
                }}
            >
                {({ submitForm }) => (
                    <Field>
                        <AIPromptInput inputName="userPrompt" onResubmitButtonClick={submitForm} />
                    </Field>
                )}
            </Formik>

            <FormulaAIConversationView
                onUseFormulaClick={onUseFormula}
                smartConversationManager={smartConversationManager}
                called={called}
            />
        </ModalBody>
    );
};

export default withModal(FormulaAIBuilderModal, { size: ModalSize.MEDIUM, fixedWidth: true });
