<tags-input
    class="tnk-selector"
    ng-model="$ctrl.tags"
    on-tag-added="$ctrl.onTagAddedFunc($tag)"
    on-tag-removed="$ctrl.onTagRemovedFunc($tag)"
    template="multiple-input-selected-item"
    replace-spaces-with-dashes="false"
    placeholder="Add value"
    add-on-enter="true"
    add-on-blur="false"
    add-on-comma="true"
    add-on-paste="false"
    add-on-space="false"
    add-from-autocomplete-only="false"
    ng-disabled="$ctrl.disabled"
></tags-input>

<script type="text/ng-template" id="multiple-input-selected-item">
    <span>{{$getDisplayText()}}</span>
    <a class="common-link-no-style" ng-click="$removeTag()">×</a>
</script>
