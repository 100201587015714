import styled from 'styled-components';

import { Checkbox, SearchBox } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HistoryFieldsTableFilters = styled.div<{ paddingTop?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    padding: ${({ paddingTop = false }) => (paddingTop ? '20px 0' : '0 0 20px')};
`;

export const HistoryFieldsTableFiltersCheckbox = styled(Checkbox)`
    margin-right: 12px;
`;

export const HistoryFieldsTableFiltersHeader = styled.div<{ big?: boolean }>`
    font-size: ${({ big = false }) => (big ? FontSize.MEDIUM_14 : FontSize.SMALL_12)};
    margin-right: ${({ big = false }) => (big ? '20px' : '12px')};
    color: ${Theme.colors.gray_700};
`;

export const HistoryFieldsTableFiltersSearchBox = styled(SearchBox)`
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: auto;
`;

export default HistoryFieldsTableFilters;
