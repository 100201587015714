import { reactToAngular } from 'angulareact';

import ModuleMarketplaceItemCreateModal from './ModuleMarketplaceItemCreateModal';

export default angular
    .module('tonkean.app')
    .component(
        'tnkModuleMarketplaceItemCreateModal',
        reactToAngular(ModuleMarketplaceItemCreateModal, [
            'projectId',
            'groupId',
            'groupName',
            'workflowFolderId',
            'open',
            'onClose',
        ]),
    );
