<div class="padding-normal common-close-btn-container">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>
    <tnk-initiative-settings
        initiative="initiative"
        inner-css="mod-vertical"
        on-saved="$dismiss()"
        show-set-reminder="showSetReminder"
        on-reminder-set="onReminderSet()"
        manual-gather-update="manualGatherUpdate"
    ></tnk-initiative-settings>
</div>
