import React, { useCallback } from 'react';
import styled from 'styled-components';

import { StyledField, StyledPreCreatedParameter, StyledTonkeanExpression } from './SuccessCriteriaSharedStyles';
import { ReactComponent as InfoIcon } from '../../../../../../../../../../images/icons/info10px.svg';

import { Field, Tooltip } from '@tonkean/infrastructure';
import { ActionParameterType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type {
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionUploadFileResponseHandleDefinition,
} from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 6px;
`;

interface Props {
    projectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionUploadFileResponseHandleDefinition>;
    projectIntegration: ProjectIntegration;
    groupId: string;
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<ProjectIntegrationAction<ProjectIntegrationActionUploadFileResponseHandleDefinition>>
    >;
    actionsCustomFieldTab: TonkeanExpressionAdditionalTab[];
}

const SuccessCriteriaUpload: React.FC<Props> = ({
    projectIntegrationAction,
    projectIntegration,
    setProjectIntegrationAction,
    groupId,
    actionsCustomFieldTab,
}) => {
    const onChangeIdField = useCallback(
        (originalExpression: string, evaluatedExpression: string) => {
            setProjectIntegrationAction((currentAction) => {
                // Combine the old responseHandlingDefinition ( to keep the on failure handle)
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.UPLOAD_FILE_INFORMATION,
                        ...currentAction.responseHandlingDefinition,

                        uploadFileId: {
                            originalExpression,
                            evaluatedExpression,
                            isStripHtmlDisabled: true,
                            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                        },
                        uploadFileName: currentAction.responseHandlingDefinition?.uploadFileName || undefined,
                        originalFileName: currentAction.responseHandlingDefinition?.originalFileName || undefined,
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    const onChangeFileNameField = useCallback(
        (originalExpression: string, evaluatedExpression: string) => {
            setProjectIntegrationAction((currentAction) => {
                // Combine the old responseHandlingDefinition ( to keep the on failure handle)
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.UPLOAD_FILE_INFORMATION,
                        ...currentAction.responseHandlingDefinition,

                        uploadFileName: {
                            originalExpression,
                            evaluatedExpression,
                            isStripHtmlDisabled: true,
                            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                        },
                        uploadFileId: currentAction.responseHandlingDefinition?.uploadFileId || undefined,
                        originalFileName: currentAction.responseHandlingDefinition?.originalFileName || undefined,
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    const onChangeFileOriginalField = useCallback(
        (originalExpression: string, evaluatedExpression: string) => {
            setProjectIntegrationAction((currentAction) => {
                // Combine the old responseHandlingDefinition ( to keep the on failure handle)
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.UPLOAD_FILE_INFORMATION,
                        ...currentAction.responseHandlingDefinition,

                        originalFileName: {
                            originalExpression,
                            evaluatedExpression,
                            isStripHtmlDisabled: true,
                            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                        },
                        uploadFileName: currentAction.responseHandlingDefinition?.uploadFileName || undefined,
                        uploadFileId: currentAction.responseHandlingDefinition?.uploadFileId || undefined,
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    return (
        <>
            <Field
                label={
                    <StyledField isFirst>
                        File ID
                        <Tooltip content="You can use Json Path formula to indicate the location of the field value">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledField>
                }
                error={
                    !projectIntegrationAction.responseHandlingDefinition?.uploadFileId?.originalExpression
                        ? "Value can't be empty"
                        : undefined
                }
            >
                <StyledTonkeanExpression
                    groupId={groupId}
                    workflowVersionId=""
                    onTonkeanExpressionChanged={onChangeIdField}
                    placeholder="Extract value from the response body/headers"
                    additionalTabs={actionsCustomFieldTab}
                    savedOriginalExpression={
                        projectIntegrationAction.responseHandlingDefinition?.uploadFileId?.originalExpression || ''
                    }
                    savedEvaluatedExpression={
                        projectIntegrationAction.responseHandlingDefinition?.uploadFileId?.evaluatedExpression || ''
                    }
                    globalExpressionOnly
                />
            </Field>

            <Field
                label={
                    <StyledPreCreatedParameter>
                        uploaded file name
                        <Tooltip content="You can use Json Path formula to indicate the location of the field value">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledPreCreatedParameter>
                }
                error={
                    !projectIntegrationAction.responseHandlingDefinition?.uploadFileName?.originalExpression
                        ? "Value can't be empty"
                        : undefined
                }
            >
                <StyledTonkeanExpression
                    groupId={groupId}
                    workflowVersionId=""
                    onTonkeanExpressionChanged={onChangeFileNameField}
                    placeholder="Extract value from the response body/headers"
                    additionalTabs={actionsCustomFieldTab}
                    savedOriginalExpression={
                        projectIntegrationAction.responseHandlingDefinition?.uploadFileName?.originalExpression || ''
                    }
                    savedEvaluatedExpression={
                        projectIntegrationAction.responseHandlingDefinition?.uploadFileName?.evaluatedExpression || ''
                    }
                    globalExpressionOnly
                />
            </Field>

            <Field
                label={
                    <StyledPreCreatedParameter>
                        original field name
                        <Tooltip content="You can use Json Path formula to indicate the location of the field value">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledPreCreatedParameter>
                }
                error={
                    !projectIntegrationAction.responseHandlingDefinition?.originalFileName?.originalExpression
                        ? "Value can't be empty"
                        : undefined
                }
            >
                <StyledTonkeanExpression
                    groupId={groupId}
                    workflowVersionId=""
                    onTonkeanExpressionChanged={onChangeFileOriginalField}
                    placeholder="Extract value from the response body/headers"
                    additionalTabs={actionsCustomFieldTab}
                    savedOriginalExpression={
                        projectIntegrationAction.responseHandlingDefinition?.originalFileName?.originalExpression || ''
                    }
                    savedEvaluatedExpression={
                        projectIntegrationAction.responseHandlingDefinition?.originalFileName?.evaluatedExpression || ''
                    }
                    globalExpressionOnly
                />
            </Field>
        </>
    );
};

export default SuccessCriteriaUpload;
