import React from 'react';
import styled from 'styled-components';

import { ReactComponent as UserIcon } from '../../../../../images/icons/user.svg';
import TonkeanAvatarsBar from '../../../ProjectIntegrationPageModule/components/EnterpriseComponentSolutionsAccess/TonkeanAvatarsBar';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import { ConnectionPermission } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledPermissionsLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledPermissionsLabelText = styled.div`
    color: ${Theme.colors.gray_700};
    margin-left: 6px;
`;

const UserAvatar = styled.span`
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    position: relative;
`;

interface Props {
    connectionPermission: ConnectionPermission;
    authorizedPeopleIds?: string[];
    noOneLabel?: string;
}

const PeoplePermissionsLabel: React.FC<Props> = ({ connectionPermission, authorizedPeopleIds, noOneLabel }) => {
    const project = useProject();

    const {
        data: authorizedPeople,
        error,
        loading,
    } = useTonkeanService('getAuthorizablesByIds', project.id, authorizedPeopleIds || []);

    switch (connectionPermission) {
        case ConnectionPermission.NO_ONE:
            return (
                <StyledPermissionsLabel>
                    <UserAvatar>
                        <UserIcon />
                    </UserAvatar>
                    <StyledPermissionsLabelText>{noOneLabel || 'No one'}</StyledPermissionsLabelText>
                </StyledPermissionsLabel>
            );
        case ConnectionPermission.ALL_MEMBERS:
            return (
                <StyledPermissionsLabel>
                    <UserAvatar>
                        <UserIcon />
                    </UserAvatar>
                    <StyledPermissionsLabelText>All Users</StyledPermissionsLabelText>
                </StyledPermissionsLabel>
            );
        case ConnectionPermission.SPECIFIC_MEMBERS:
            return (
                <TonkeanAvatarsBar
                    avatars={authorizedPeople || []}
                    avatarsDesktopLimit={5}
                    displayMoreLimit={10}
                    shouldDisplayViewTime={false}
                    showAllInDisplayMore={false}
                    showMoreAvatarsLink
                    separatedAvatars
                />
            );
    }
    return <></>;
};

export default PeoplePermissionsLabel;
