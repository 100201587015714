import { reactToAngular } from 'angulareact';

import CreateInitiativeByContractActionDefinition from './CreateInitiativeByContractActionDefinition';

export default angular
    .module('tonkean.app')
    .component(
        'tnkCreateInitiativeByContractActionDefinition',
        reactToAngular(CreateInitiativeByContractActionDefinition, [
            'contractId',
            'workflowVersionId',
            'projectId',
            'onChange',
            'contractFieldToExpression',
        ]),
    );
