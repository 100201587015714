import React from 'react';
import styled from 'styled-components';

import { AITypewriter } from '@tonkean/ai-builder';
import { H4, IconSvg } from '@tonkean/infrastructure';
import { OpenIcon } from '@tonkean/svg';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const EntityBox = styled.div`
    display: block;
    gap: 20px;
    font-weight: 300;
    padding: 10px;
    border-left: 2px solid ${Theme.colors.promotion};
    background: #f9fbfe;
`;

const EntityTypeLine = styled(Clickable)`
    display: flex;
    align-items: center;
    gap: 14px;
`;

const EntityActionLine = styled.div`
    margin-top: 10px;
`;

const IsRelevantText = styled.span`
    margin-right: 5px;
`;

interface Props {
    externalLink: string;
    icon: React.ReactNode;
    title: string;
    subTitle: string;
    isAnalyzing: boolean;
    isReAnalyzeEnabled: boolean;
    onClickAnalyze(): void;
}

const SmartSearchLookupEntityBoxView: React.FC<Props> = ({
    externalLink,
    icon,
    subTitle,
    title,
    isReAnalyzeEnabled,
    isAnalyzing,
    onClickAnalyze,
}) => {
    return (
        <EntityBox>
            <EntityTypeLine href={externalLink} openInNewTab>
                {icon}

                <H4 $color={Theme.colors.gray_700} $thin>
                    {title}
                </H4>

                {subTitle && (
                    <H4 $color={Theme.colors.gray_600} $thin>
                        {subTitle}
                    </H4>
                )}

                {externalLink && <IconSvg as={OpenIcon} size={14} />}
            </EntityTypeLine>

            <div>
                {isReAnalyzeEnabled && (
                    <EntityActionLine>
                        <IsRelevantText>Relevant?</IsRelevantText>
                        <ClickableLink onClick={onClickAnalyze}>Run analysis</ClickableLink>
                    </EntityActionLine>
                )}

                {isAnalyzing && (
                    <EntityActionLine>
                        <AITypewriter text="Analyzing..." />
                    </EntityActionLine>
                )}
            </div>
        </EntityBox>
    );
};

export default SmartSearchLookupEntityBoxView;
