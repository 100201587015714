import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';

import FormulaNode from './FormulaNode';
import FormulaNodePopoverTrigger from './FormulaNodePopoverTrigger';
import FormulaOperatorArrayOperandsWrapper from './FormulaOperatorArrayOperandsWrapper';
import FormulaOperatorWrapper from './FormulaOperatorWrapper';
import FormulaContext from '../../entities/FormulaContext';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';
import { ReactComponent as FormulaBuilderSpecificCollapseIcon } from '../../images/formula-builder-specific-collapse.svg';
import { ReactComponent as FormulaBuilderSpecificExpandIcon } from '../../images/formula-builder-specific-expand.svg';

import { useCreateDialog } from '@tonkean/infrastructure';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { FormulaTreeTreeNode } from '@tonkean/tonkean-entities';
import { createEmptyFormulaNode } from '@tonkean/tonkean-entities';

const SpecificEditorButton = styled.button`
    display: inline-block;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    margin: 2px 6px 2px -14px;
    vertical-align: bottom;
    outline: none;
`;

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: FormulaTreeTreeNode;
    /** Function that will be triggered when the operand changes */
    onOperandChanged: (newOperand: formulaTreeNode, oldOperand: formulaTreeNode) => void;
}

const FormulaOperandFunction: React.FC<Props> = ({
    depth,
    canDelete,
    node,
    disabled,
    onOperandChanged,
    onNodeChanged: emitOnNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    const { confirm } = useCreateDialog();

    const { specificEditor, setSpecificEditor, formulaChangedOutsideSpecificEditorCallback } =
        useContext(FormulaContext);

    const canRemoveFromArray =
        node.operands.length - node.operator.fieldsMetadata.arrayTupleSize >= node.operator.fieldsMetadata.minOperands;

    const onNodeAdded = useCallback(
        (addAtIndex: number = 0) => {
            const fields = node.operator.getFieldsList(
                node.operands.length + node.operator.fieldsMetadata.arrayTupleSize,
            );
            const newFields: formulaTreeNode[] = Array.from({
                length: node.operator.fieldsMetadata.arrayTupleSize,
            }).map((_, fieldIndex) => createEmptyFormulaNode(fields[addAtIndex + fieldIndex]!));

            const newOperandsList = [...node.operands];
            newOperandsList.splice(addAtIndex, 0, ...newFields);

            // Clone all other operands because their field has changed
            const clonedNewOperandsList = newOperandsList.map((operand, index) => {
                if (newFields.includes(operand)) {
                    return operand;
                }
                return operand.clone(fields[index]!);
            });

            emitOnNodeChanged(new FormulaTreeTreeNode(node.operator, clonedNewOperandsList, node.field), node);
        },
        [node, emitOnNodeChanged],
    );

    const onOperandDeleted = useCallback(
        async (operandToDelete: formulaTreeNode) => {
            // If can't delete, don't. :)
            if (!canRemoveFromArray) {
                return;
            }

            // Can delete only tuples
            if (operandToDelete.field.metadata.fieldDefinitionType !== FieldDefinitionType.ARRAY) {
                return;
            }

            const tupleIndex = operandToDelete.field.metadata.inArrayIndex || 0;
            const fieldsToDelete = node.operands.filter(
                (operand) =>
                    operand.field.metadata.fieldDefinitionType === FieldDefinitionType.ARRAY &&
                    operand.field.metadata.inArrayIndex === tupleIndex,
            );

            const emptyTuple = fieldsToDelete.every((operand) => operand.type === FormulaTreeNodeType.EMPTY);

            if (fieldsToDelete.length !== 1 && !emptyTuple) {
                // Show modal to confirm the user intent
                const tupleName = node.operator.fieldsMetadata.arrayField?.displayName.toLowerCase() || '';
                const canDelete = await confirm(
                    `Do you want to delete the entire ${tupleName}?`,
                    `It will delete all ${fieldsToDelete.length} fields.`,
                    { cancelLabel: 'Cancel', okLabel: 'Delete' },
                );

                if (!canDelete) {
                    return;
                }
            }

            const fieldsAfterDeleting = node.operands.filter((operand) => !fieldsToDelete.includes(operand));
            const fields = node.operator.getFieldsList(fieldsAfterDeleting.length);

            // Clone all other operands because their field has changed
            const clonedNewOperandsList = fieldsAfterDeleting.map((operand, index) => {
                return operand.clone(fields[index]!);
            });

            emitOnNodeChanged(new FormulaTreeTreeNode(node.operator, clonedNewOperandsList, node.field), node);
        },
        [canRemoveFromArray, node, emitOnNodeChanged, confirm],
    );

    const updateSpecificEditor = useCallback(() => {
        setSpecificEditor({
            onChanges: (operands: formulaTreeNode[]) => {
                emitOnNodeChanged(new FormulaTreeTreeNode(node.operator, operands, node.field, node.id), node);
            },
            treeNode: node,
        });
    }, [node, emitOnNodeChanged, setSpecificEditor]);

    const nodeId = node.id;
    const specialEditorActive = specificEditor?.treeNode.id === nodeId;

    useEffect(() => {
        // If not active no need to regenerate the onChanges and update the tree node reference.
        if (!specialEditorActive) {
            return;
        }

        updateSpecificEditor();
    }, [specialEditorActive, updateSpecificEditor]);

    useEffect(() => {
        return () => {
            setSpecificEditor((specificEditor) => {
                if (specificEditor?.treeNode.id === nodeId) {
                    return undefined;
                }
                return specificEditor;
            });
        };
    }, [nodeId, setSpecificEditor]);

    const onNodeChanged = useCallback(
        (newNode: formulaTreeNode, oldNode: formulaTreeNode) => {
            const shouldCloseSpecificEditor =
                specialEditorActive &&
                oldNode.type === FormulaTreeNodeType.TREE &&
                (newNode.type !== oldNode.type || newNode.operator !== oldNode.operator);
            if (shouldCloseSpecificEditor) {
                setSpecificEditor(undefined);
            }

            emitOnNodeChanged(newNode, oldNode);
        },
        [emitOnNodeChanged, specialEditorActive, setSpecificEditor],
    );

    const toggleSpecificEditor = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        // Specific editor is open, so should be closed
        if (specialEditorActive) {
            setSpecificEditor(undefined);
            return;
        }

        formulaChangedOutsideSpecificEditorCallback();
        updateSpecificEditor();
    };

    const lastArrayItem = node.operands.length - node.operator.fieldsMetadata.fieldsAfterArray;

    const operandsBeforeArray = node.operands.slice(0, node.operator.fieldsMetadata.fieldsBeforeArray);
    const operandsInArray = node.operands.slice(node.operator.fieldsMetadata.fieldsBeforeArray, lastArrayItem);
    const operandsAfterArray = node.operands.slice(lastArrayItem, node.operands.length);

    const formulaOperatorWrapperProps = {
        depth: depth + 1,
        fieldsMetadata: node.operator.fieldsMetadata,
        disabled,
        onAdd: onNodeAdded,
    };
    const formulaOperatorProps = {
        depth: depth + 2,
        additionalTabs,
        customTrigger,
        projectIntegration,
        disabled,
        onNodeChanged: onOperandChanged,
        onNodeDeleted: onOperandDeleted,
    };

    return (
        <>
            <FormulaNodePopoverTrigger
                prefix={
                    node.operator.specificEditor && (
                        <SpecificEditorButton onClick={toggleSpecificEditor} type="button">
                            {specialEditorActive ? (
                                <span className="tnk-icon">
                                    <FormulaBuilderSpecificCollapseIcon />
                                </span>
                            ) : (
                                <span className="tnk-icon">
                                    <FormulaBuilderSpecificExpandIcon />
                                </span>
                            )}
                        </SpecificEditorButton>
                    )
                }
                content={`${node.operator.sign} (`}
                depth={depth}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                projectIntegration={projectIntegration}
                canDelete={canDelete}
                disabled={disabled}
                node={node}
                onNodeChanged={onNodeChanged}
                onNodeDeleted={onNodeDeleted}
                right
            />

            {operandsBeforeArray.map((operand) => (
                <FormulaOperatorWrapper key={operand.id} operand={operand} {...formulaOperatorWrapperProps}>
                    <FormulaNode node={operand} canDelete={false} {...formulaOperatorProps} />
                </FormulaOperatorWrapper>
            ))}

            {node.operator.fieldsMetadata.arrayField && (
                <FormulaOperatorArrayOperandsWrapper
                    depth={depth + 1}
                    fieldsMetadata={node.operator.fieldsMetadata}
                    disabled={disabled}
                    onAdd={onNodeAdded}
                >
                    {operandsInArray.length
                        ? operandsInArray.map((operand) => (
                              <FormulaOperatorWrapper
                                  key={operand.id}
                                  operand={operand}
                                  {...formulaOperatorWrapperProps}
                              >
                                  <FormulaNode
                                      node={operand}
                                      canDelete={canRemoveFromArray}
                                      {...formulaOperatorProps}
                                  />
                              </FormulaOperatorWrapper>
                          ))
                        : undefined}
                </FormulaOperatorArrayOperandsWrapper>
            )}

            {operandsAfterArray.map((operand) => (
                <FormulaOperatorWrapper key={operand.id} operand={operand} {...formulaOperatorWrapperProps}>
                    <FormulaNode node={operand} canDelete={false} {...formulaOperatorProps} />
                </FormulaOperatorWrapper>
            ))}

            <FormulaNodePopoverTrigger
                content=")"
                depth={depth}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={canDelete}
                disabled={disabled}
                node={node}
                onNodeChanged={emitOnNodeChanged}
                onNodeDeleted={onNodeDeleted}
                left
            />
        </>
    );
};

export default FormulaOperandFunction;
