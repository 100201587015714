import React from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import MultiEntitySelectorEntityLabel from '../../components/MultiEntitySelector/components/MultiEntitySelectorEntityLabel';
import MultiEntitySelector from '../../components/MultiEntitySelector/MultiEntitySelector';
import type MultiEntitySelectorCustomComponentProps from '../../components/MultiEntitySelector/types/MultiEntitySelectorCustomComponentProps';
import useGetItemInterfaceWidgetsData from '../hooks/useGetItemInterfaceWidgetsData';
import useWidgetInlineActionsOptions from '../hooks/useWidgetInlineActionsOptions';

import { IconSvg, TextEllipsis } from '@tonkean/infrastructure';
import { SettingsIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { InterfaceCTAActionOption, InterfaceCTASavedAction } from '@tonkean/tonkean-entities';
import { InterfaceCTAActionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ACTIONS_FORMIK_NAME = 'configuration.actions';

const IconAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}

const WidgetInlineActionsSelector: React.FC<Props> = ({ workflowVersionId }) => {
    const {
        forms,
        interfaces,
        customTriggers,
        loadingForms,
        loadingCustomTriggers,
        loadingInterfaces,
        errorForms,
        errorCustomTriggers,
        errorInterfaces,
    } = useGetItemInterfaceWidgetsData(workflowVersionId);

    const actionOptions: InterfaceCTAActionOption[] = useWidgetInlineActionsOptions(forms, interfaces, customTriggers);

    return (
        <>
            <ConfigurationSectionTitle>Inline Actions</ConfigurationSectionTitle>
            <MultiEntitySelector<InterfaceCTASavedAction>
                name={ACTIONS_FORMIK_NAME}
                loading={loadingForms || loadingCustomTriggers || loadingInterfaces}
                error={errorForms || errorCustomTriggers || errorInterfaces}
                entityTypeLabel="action"
                entitiesOptions={actionOptions}
                customComponents={{
                    entityLabelComponent: WidgetInlineMenuActionSelectorEntityDescription,
                }}
            />
        </>
    );
};

const WidgetInlineMenuActionSelectorEntityDescription: React.FC<
    MultiEntitySelectorCustomComponentProps<InterfaceCTASavedAction>
> = ({ entityOption, savedEntity }) => {
    switch (savedEntity.type) {
        case InterfaceCTAActionType.DELETE_ITEM:
            return (
                <MultiEntitySelectorEntityLabel>
                    <TextEllipsis numberOfLines={1} tooltipPlacement="bottom" tooltip>
                        {savedEntity.label || entityOption.savedEntityTemplate.defaultLabel}
                    </TextEllipsis>
                    <IconAndTextWrapper>
                        <IconSvg
                            as={SettingsIcon}
                            size={16}
                            color={Theme.colors.gray_600}
                            style={{ paddingRight: '5px' }}
                        />{' '}
                        System Action
                    </IconAndTextWrapper>
                </MultiEntitySelectorEntityLabel>
            );
        default:
            return (
                <MultiEntitySelectorEntityLabel style={{ paddingTop: '11px', paddingBottom: '11px' }}>
                    <TextEllipsis numberOfLines={1} tooltipPlacement="bottom" tooltip>
                        {savedEntity.label || entityOption.savedEntityTemplate.defaultLabel}
                    </TextEllipsis>
                </MultiEntitySelectorEntityLabel>
            );
    }
};

export default WidgetInlineActionsSelector;
