import React from 'react';
import styled, { css } from 'styled-components';

import type { CollaborationAction } from '../entities';
import { CloseIcon } from '../icons';

import { Popover, type PopperChildrenRefProps, useProject } from '@tonkean/infrastructure';
import type { ProjectThemeConfiguration } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const CollaborationActionsPopoverWrapper = styled.div<{ $showGradient: boolean | undefined; $gradientColor: string }>`
    border-radius: 8px;
    background: ${({ $showGradient, $gradientColor }) =>
        $showGradient
            ? `linear-gradient(165deg, ${$gradientColor} -190%, rgba(255, 255, 255, 0) 51.68%),
                ${Theme.colors.white};`
            : `${Theme.colors.white}`};
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
`;

const Title = styled.div<{ $iconFill?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 16px;
    font-size: ${FontSize.MEDIUM_14};
    gap: 8px;

    ${({ $iconFill }) =>
        $iconFill &&
        css`
            ${colorSvg($iconFill)};
        `};
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 16px;
    height: 16px;
    transform: rotate(-0.188deg);
    cursor: pointer;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const NavigationButtonWrapper = styled.div`
    height: 38px;
`;

export interface CollaborationActionsPopoverProps {
    collaborationAction: CollaborationAction;
    showPopover: boolean;
    primaryColor?: string;
    closePopover: () => void;
}

const CollaborationActionsPopover: React.FC<
    React.PropsWithChildren<CollaborationActionsPopoverProps & PopperChildrenRefProps>
> = ({ children: navigationButton, collaborationAction, showPopover, closePopover, primaryColor }) => {
    const project = useProject();
    const projectThemeConfiguration: ProjectThemeConfiguration = project.themeConfiguration;
    const themeColorToDisplay = primaryColor ? primaryColor : projectThemeConfiguration.primaryColor;

    return (
        <Popover
            show={showPopover}
            onClose={closePopover}
            content={
                <CollaborationActionsPopoverWrapper
                    data-automation={`collaboration-actions-popover${collaborationAction.actionType}`}
                    $showGradient={collaborationAction.showGradientBackground}
                    $gradientColor={themeColorToDisplay}
                >
                    <TitleWrapper>
                        <Title $iconFill={collaborationAction.iconFill}>
                            {collaborationAction.icon}
                            {collaborationAction.popupTitle ?? collaborationAction.title}
                        </Title>
                        <StyledCloseIcon
                            data-automation={`collaboration-actions-popover-closing-button${collaborationAction.actionType}`}
                            onClick={closePopover}
                        />
                    </TitleWrapper>
                    <Content>{collaborationAction.popupContent}</Content>
                </CollaborationActionsPopoverWrapper>
            }
            placement="bottom-end"
            width="500px"
            showArrow={false}
            noPadding
        >
            <NavigationButtonWrapper>{navigationButton}</NavigationButtonWrapper>
        </Popover>
    );
};

export default CollaborationActionsPopover;
