import React, { useContext, useEffect, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { StylesConfig } from 'react-select';
import styled from 'styled-components';

import FormSettingsOptionalSubTitle from './FormSettingsOptionalSubTitle';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';
import FormBuilderPreviewType from '../../entities/FormBuilderPreviewType';
import { SLACK_COMMAND_ERROR } from '../../utils/formServerErrors';
import FormBuilderSelectIcon from '../FormBuilderSelect/FormBuilderSelectIcon';
import FormBuilderSlackCommandInput from '../FormBuilderSlackCommandInput';

import { FormBuilderContext } from '@tonkean/infrastructure';
import { InformationTooltip, SavingIndicator, SimpleSelect, Tooltip } from '@tonkean/infrastructure';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import type { CreateForm } from '@tonkean/tonkean-entities';

const TitleWrapper = styled.div`
    margin-top: 14px;
    display: flex;
    align-items: center;
`;

const SelectWrapper = styled.div`
    width: 34%;
    min-width: 34%;
    margin-right: 8px;
`;

const CommandSelectWrapper = styled.div`
    display: flex;
    margin-top: 8px;
`;

const InformationTooltipStyled = styled(InformationTooltip)`
    margin-left: 2px;
`;

interface Props {
    form: CreateForm;
    onFormChanged: Dispatch<SetStateAction<CreateForm>>;
    workflowVersionId: string;
}

const options: SimpleSelectSingleOption<string>[] = [
    { value: 'Slack', label: 'Slack', icon: <FormBuilderSelectIcon label={FormBuilderPreviewType.Slack} /> },
];

const selectStyles: StylesConfig<SimpleSelectSingleOption<string>> = { control: () => ({ height: '28px' }) };

const FormSettingsCommunicationIntegrationsCommands: React.FC<Props> = ({ form, onFormChanged }) => {
    const { editedSections, setSectionLoading, isEditable, currentError } = useContext(FormBuilderContext);

    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [slackCommand, setSlackCommand] = useState<string | undefined>(form.slackCommand);

    const isValid = useMemo<boolean>(() => {
        return currentError !== SLACK_COMMAND_ERROR;
    }, [currentError]);

    useEffect(() => {
        setSlackCommand(form.slackCommand);
        setIsTouched(false);
    }, [form.id, form.slackCommand]);

    const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSlackCommand(value);
        setIsTouched(true);
    };

    useEffect(() => {
        if (isTouched && slackCommand !== form.slackCommand) {
            onFormChanged((prevForm) => ({ ...prevForm, slackCommand }));
            setSectionLoading(FormBuilderSections.CommunicationIntegrationCommands);
        }
    }, [form.slackCommand, isTouched, onFormChanged, setSectionLoading, slackCommand]);

    return (
        <>
            <TitleWrapper>
                <FormSettingsSectionHeaderTitle>Form Communication Integration Commands</FormSettingsSectionHeaderTitle>{' '}
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>{' '}
                <InformationTooltipStyled textAlignLeft>
                    For testing command in build environment add “build” param when running the command.
                    <br /> Example: /tonk {'{form name}'} build
                </InformationTooltipStyled>
                <SavingIndicator state={editedSections[FormBuilderSections.CommunicationIntegrationCommands]} />
            </TitleWrapper>
            <FormSettingsSectionHeaderSubtitle>
                Define a command name that will open the form within the integration.
            </FormSettingsSectionHeaderSubtitle>

            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <CommandSelectWrapper>
                    <SelectWrapper>
                        <SimpleSelect
                            value="Slack"
                            options={options}
                            onChange={() => {} /** TODO: Empty function because the onChange is not optional */}
                            isSearchable={false} /* TODO: Make searchable with more options */
                            styles={selectStyles}
                            isDisabled={!isEditable}
                            thin
                        />
                    </SelectWrapper>
                    <FormBuilderSlackCommandInput
                        valid={isValid}
                        value={slackCommand}
                        disabled={!isEditable}
                        onChange={onChange}
                    />
                </CommandSelectWrapper>
            </Tooltip>
        </>
    );
};

export default FormSettingsCommunicationIntegrationsCommands;
