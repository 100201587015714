import template from './tnkSendEmailRecipients.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSendEmailRecipients', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        identifier: '@',
        configuredLogic: '<',
        validationObject: '<',
        previewEvaluationSource: '<',

        recipientsConfiguration: '<',
        bccConfiguration: '<',
        ccConfiguration: '<',

        toRecipientsArray: '<', // DEPRECATED: this used to be the first version of the recipients component dont use this.
        ccRecipientsArray: '<', // DEPRECATED: this used to be the first version of the recipients component dont use this.
        bccRecipientsArray: '<', // DEPRECATED: this used to be the first version of the recipients component dont use this.

        onRecipientsConfigurationChanged: '&',
        onCCConfigurationChanged: '&',
        onBCCConfigurationChanged: '&',
    },
    template,
    controller: 'SendEmailRecipientsCtrl',
});
