function MigrateToNewPricingModalCtrl($scope, authenticationService, tonkeanService, projectManager, modalUtils) {
    $scope.modalUtils = modalUtils;

    $scope.data = {};

    $scope.getMigrationData = function () {
        $scope.data.loading = true;
        tonkeanService.moveToPricingV2(projectManager.project.id, true).then(function (migrateData) {
            $scope.data.migrateData = migrateData;
            $scope.data.currentPlan = migrateData.oldPlan;
            $scope.data.currentPlan.seats = projectManager.project.license.seats;
            $scope.data.newPlan = migrateData.newPlan;

            $scope.data.loading = false;
        });
    };

    $scope.moveToNewPlan = function () {
        $scope.data.loading = true;
        tonkeanService.moveToPricingV2(projectManager.project.id).then(function () {
            window.location.reload();
        });
    };

    $scope.getMigrationData();
}

export default angular.module('tonkean.app').controller('MigrateToNewPricingModalCtrl', MigrateToNewPricingModalCtrl);
