import { useContext, useEffect } from 'react';

import useGetItemInterfaceFieldDefinitionSummary from './useGetItemInterfaceFieldDefinitionSummary';

import { ItemInterfaceFieldDefinitionSummaryContext } from '@tonkean/infrastructure';
import type {
    FieldDefinition,
    Initiative,
    ItemInterfaceWidget,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

/**
 * Build the field definitions summaries for this item interface and register them to the summaries context.
 * Components using these field definitions should consume the context to get them.
 *
 * This registration is helpful for handling included (embed) interfaces and how they are dynamically loaded.
 */
export const useRegisterItemInterfaceFieldDefinitionSummaryOnContext = (
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
    widgets: ItemInterfaceWidget[],
    itemInterfaceFieldDefinitions: FieldDefinition<unknown>[],
    initiative: Initiative | undefined,
    widgetsInnerItems: { [widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>]: Initiative[] },
) => {
    const currentItemInterfaceFieldDefinitionSummary = useGetItemInterfaceFieldDefinitionSummary(
        initiative,
        widgets,
        itemInterfaceFieldDefinitions,
        widgetsInnerItems,
    );

    const summariesContext = useContext(ItemInterfaceFieldDefinitionSummaryContext);
    const registerSummariesByInterfaceId = summariesContext?.registerSummariesByInterfaceId;
    useEffect(() => {
        registerSummariesByInterfaceId?.(itemInterfaceId, currentItemInterfaceFieldDefinitionSummary);
    }, [currentItemInterfaceFieldDefinitionSummary, itemInterfaceId, registerSummariesByInterfaceId]);
};
