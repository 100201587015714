<div class="common-size-xs padding-top-xs">
    <strong>What website do you want to track?</strong>
</div>
<hr class="margin-top" />

<!-- Google view select -->
<div
    class="row common-size-xs flex-vmiddle"
    ng-init="getAutoComplete(integrationsResults['ANALYTICS'].integration.id, 'views')"
>
    <strong class="col-sm-3 text-right">Google view:</strong>
    <div class="col-sm-9 flex">
        <ui-select
            theme="bootstrap"
            required
            class="common-width-auto flex-basis-50 inline-block"
            ng-model="valueResults['VIEW']"
            ng-disabled="loadingAutoCompleteOptions['views']"
        >
            <ui-select-match placeholder="Choose a view...">{{ $select.selected.displayName }}</ui-select-match>
            <ui-select-choices repeat="op in data.autoCompleteOptions['views'] | filter:$select.search">
                <div ng-bind-html="op.displayName | escapeHtml | highlight: $select.search"></div>
                <div
                    class="common-size-xxxxs common-color-grey"
                    ng-bind-html="op.description | highlight: $select.search"
                ></div>
                <!--<div ng-bind-html="(op.displayName + ' (' + op.description + ')') | escapeHtml | highlight: $select.search"></div>-->
            </ui-select-choices>
        </ui-select>
    </div>
</div>

<!-- Selected google view description -->
<div class="row">
    <span class="col-sm-3"></span>
    <span
        ng-if="valueResults['VIEW'] && valueResults['VIEW'].description"
        class="col-sm-9 common-size-xxxxs common-color-dark-grey margin-top-xs"
    >
        {{ valueResults['VIEW'].description }}
    </span>
</div>

<!-- Time interval -->
<div class="row common-size-xs flex-vmiddle margin-top" ng-init="valueResults['DAYS_COUNT'] = 7">
    <strong class="col-sm-3 text-right">Time interval:</strong>
    <div class="col-sm-9 flex-vmiddle">
        <input
            type="number"
            required
            ng-model="valueResults['DAYS_COUNT']"
            class="form-control common-width-12 common-input-only-line text-center padding-left-xs"
        />
        &nbsp;days
    </div>
</div>

<!-- Segment -->
<div ng-if="valueResults['VIEW'] && valueResults['VIEW'].value">
    <div class="row common-size-xs flex-vmiddle margin-top">
        <strong class="col-sm-3 text-right">Segment by:</strong>
        <div
            class="col-sm-9 flex-vmiddle"
            ng-init="getAutoComplete(integrationsResults['ANALYTICS'].integration.id, 'metricsAndDimensions', {
                            'accountId': valueResults['VIEW'].accountId,
                            'webPropertyId': valueResults['VIEW'].webPropertyId,
                            'viewId': valueResults['VIEW'].value
                        })"
        >
            <ui-select
                theme="bootstrap"
                required
                class="common-width-auto flex-basis-50 inline-block margin-right"
                ng-model="valueResults['DIMENSION']"
                ng-change="getAutoComplete(integrationsResults['ANALYTICS'].integration.id, 'values', {
                            'timespan': valueResults['DAYS_COUNT'],
                            'dimension': valueResults['DIMENSION'].value,
                            'viewId': valueResults['VIEW'].value
                        }, 'DIMENSION_INITIATIVES')"
                ng-disabled="!data.autoCompleteOptions['metricsAndDimensions'] || loadingAutoCompleteOptions['metricsAndDimensions']"
            >
                <ui-select-match placeholder="Choose a view...">
                    {{ $select.selected.displayName + ' (' + $select.selected.value + ')' }}
                </ui-select-match>
                <ui-select-choices
                    repeat="op in data.autoCompleteOptions['metricsAndDimensions'] | filter: { type: 'dimension' }  | filter:$select.search"
                >
                    <div ng-bind-html="op.displayName | escapeHtml | highlight: $select.search"></div>
                    <div
                        class="common-size-xxxxs common-color-grey"
                        ng-bind-html="op.value | highlight: $select.search"
                    ></div>
                    <!--<div ng-bind-html="(op.displayName + ' (' + op.description + ')') | escapeHtml | highlight: $select.search"></div>-->
                </ui-select-choices>
            </ui-select>
            <span
                class="loading-small"
                ng-show="loadingAutoCompleteOptions['metricsAndDimensions'] || loadingAutoCompleteOptions['values']"
            ></span>
        </div>
    </div>
    <div class="row common-size-xxxxs common-color-grey margin-top">
        <span class="col-sm-3"></span>
        <span class="col-sm-9">
            <span class="padding-left-xxs">Example: Campaign name, Source / Medium etc</span>
        </span>
    </div>
    <!-- Auto completion error -->
    <div ng-if="data.autoCompleteError" class="row common-size-xxs common-color-danger margin-top-md">
        <span class="col-sm-3"></span>
        <span class="col-sm-9">
            <span class="padding-left-xxs">{{ data.autoCompleteError }}</span>
        </span>
    </div>
    <div class="visibility-hidden">
        <input
            ng-model="valueResults['DIMENSION_INITIATIVES']"
            name="initiative-placeholder"
            required
            style="width: 0; height: 0"
        />
    </div>
</div>
