<a
    class="kb-popover pointer kb-popover-button btn btn-slim btn-no-border common-color-black"
    ng-class="{ active: popover.isOpen }"
    ng-href="{{ $root.knowledgeBase.baseUrl }}"
    target="_blank"
>
    <i ng-if="showIcon" class="fa fa-graduation-cap margin-right-xs inline-block"></i>
    Learn
</a>
<div
    class="kb-popover-sidebar"
    ng-if="popover.isOpen"
    id="kbPopoverSidebar"
    click-outside="toggleSidebar()"
    outside-if-not="kb-popover"
    prevent-scroll-bubbling
>
    <iframe frameborder="0" ng-src="{{ popover.iframeUrl }}"></iframe>
</div>
