import React from 'react';

/**
 * A components that renders the track list item options.
 */
export default class TrackFieldPicklist extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const options = this.props.options;
        const selected = this.props.selected;
        // Classes.
        let className = 'text-center tracks-col-info2';
        className += !selected || !selected.length ? ' visible-on-hover' : '';
        className += this.props.collectItemsMode ? ' mod-force-visible' : '';

        // Make the selection label to be in a maximum fixed size
        const labelLength = 20;
        const selectedLabel =
            selected.join(', ').length > labelLength
                ? `${selected.join(', ').slice(0, Math.max(0, labelLength - 3))}...`
                : selected.join(', ');

        return (
            <div className={className} ref={this.props.divRef}>
                <span className="tnk-tooltip mod-top">
                    {(!options || !options.length) && this.props.dropdownSource !== 'SEARCH' && (
                        // No Values
                        <React.Fragment>
                            <i className="fa fa-list fa-lg common-color-light-grey opacity-50" />
                            <span className="tnk-tooltip-text">No Values</span>
                        </React.Fragment>
                    )}
                    {((!!options && options.length > 0) || this.props.dropdownSource === 'SEARCH') &&
                        (!selected || selected.length === 0) && (
                            // No Selected Option
                            <React.Fragment>
                                <i
                                    className="fa fa-list fa-lg common-color-light-grey opacity-50"
                                    onClick={this.props.onIconClick?.bind(this)}
                                />
                                <span className="tnk-tooltip-text">Select Option</span>
                            </React.Fragment>
                        )}
                    {((!!options && options.length > 0) || this.props.dropdownSource === 'SEARCH') &&
                        !!selected &&
                        selected.length > 0 && (
                            // There is a selected option already
                            <span className="tnk-tooltip mod-top pointer" onClick={this.props.onIconClick?.bind(this)}>
                                {selectedLabel}
                                <span className="tnk-tooltip-text">{selected.join(', ')}</span>
                            </span>
                        )}
                </span>
            </div>
        );
    }
}
