import template from './tnkFormFieldsSelect.template.html?angularjs';

/**
 * Component description goes here
 */
export default angular.module('tonkean.app').component('tnkFormFieldsSelect', {
    bindings: {
        titleLabel: '<', // The title of the fields selection section
        fields: '<', // The array of the fields
        groupId: '<', // The relevant group id for the fields selection
        workflowVersionId: '<', // The relevant workflow version id
        isDraggable: '<', // Whether the chosen fields are draggable
        onAdd: '&', // A callback function when a field is added
        onRemove: '&', // A callback function when a field is removed
        onEdit: '&', // A callback function when a field is edited (line inline toggle for example)
        onMove: '&', // A callback function when a field is moved to different place
        onReplace: '&', // A callback function when a field definition is replaced bu another
        addButtonLabel: '<', // The label for adding a field button
        filterFieldsOptions: '&', // A filter function to the fields
        sortFields: '&', // A sort function for fields (only when isDraggable is false)
        fixedFields: '<', // Fields to add that cannot be removed
        defaultFields: '<', //  Fields to add as default (can be removed)
        isAliasEnabled: '<', // Whether adding a display name to a field is enabled
        isInlineEnabled: '<', // Whether the inline option is visible
        sortOptionsBy: '&', // A sorting function to sort the fields options in field selector
        displayNameCharacterLimit: '<', // The limit number of the display name characters.
        formQuestionType: '<', // The form question type.
        formType: '<',
        onFieldsChange: '<',
    },
    template,
    controller: 'FormFieldsSelectCtrl',
});
