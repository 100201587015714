import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CreateMarketplaceDataSourceItemInfo from './CreateMarketplaceDataSourceItemInfo';
import EnterpriseComponentOverviewStep from './EnterpriseComponentOverviewStep';
import EnterpriseComponentOverviewTemplateStepActions from './EnterpriseComponentOverviewTemplateStepActions';
import MarketplaceItemCreateModalDataSourceContentSection from './MarketplaceItemCreateModalDataSourceContentSection';
import MarketplaceItemCreateModalDataSourceInstructionsSection from './MarketplaceItemDataSourceTabs/MarketplaceItemCreateModalDataSourceInstructionsSection';
import MarketplaceItemCreateModalDataSourceSubscriptionSection from './MarketplaceItemDataSourceTabs/MarketplaceItemCreateModalDataSourceSubscriptionSection';
import UpdateMarketplaceDataSourceItemInfo from './UpdateMarketplaceDataSourceItemInfo';
import type { MarketplaceItemCreateSection } from '../../../../components/common/tnkWorkersBrowser/marketplace/components/MarketplaceItemCreateModalSection';
import MarketplaceItemCreateEditModal from '../../../../components/common/tnkWorkersBrowser/MarketplaceItemCreateEditModal';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import type { EnterpriseComponentMenuItemPageContentProps } from '../../entities/EnterpriseComponentMenuItemPageContentProps';
import type EnterpriseComponentOverviewStepDefinition from '../../entities/overview/EnterpriseComponentOverviewStepDefinition';
import getGeneralEnterpriseComponentOverviewStepDefinitions from '../../entities/overview/getGeneralEnterpriseComponentOverviewStepDefinitions';
import useEnterpriseComponentContext from '../../hooks/useEnterpriseComponentContext';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Placeholder, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import {
    enterpriseComponentTypeDisplayName,
    IntegrationType,
    MarketplaceItemType,
    ProjectIntegrationPageMenuItemType,
} from '@tonkean/tonkean-entities';
import type { EnterpriseComponent, EnterpriseComponentType, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';
import { range } from '@tonkean/utils';

export const getMarketplaceItemAdditionalSections = (
    projectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
) => {
    const marketplaceItemContentSection: MarketplaceItemCreateSection = {
        title: 'Content',
        contentComponent: <MarketplaceItemCreateModalDataSourceContentSection />,
    };

    const marketplaceItemInstructionSection: MarketplaceItemCreateSection = {
        title: 'Instructions',
        contentComponent: <MarketplaceItemCreateModalDataSourceInstructionsSection />,
    };

    const marketplaceItemSubscriptionSection: MarketplaceItemCreateSection = {
        title: 'Subscription',
        contentComponent: <MarketplaceItemCreateModalDataSourceSubscriptionSection />,
    };

    const marketplaceItemDataSourceSection: MarketplaceItemCreateSection = {
        title: 'Data Source',
        contentComponent: projectIntegrationId ? (
            <CreateMarketplaceDataSourceItemInfo projectIntegrationId={projectIntegrationId} />
        ) : (
            <UpdateMarketplaceDataSourceItemInfo />
        ),
        noPadding: true,
    };

    return [
        marketplaceItemContentSection,
        marketplaceItemInstructionSection,
        marketplaceItemSubscriptionSection,
        marketplaceItemDataSourceSection,
    ];
};

const EnterpriseComponentOverview = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    projectId,
    enterpriseComponentType,
    enterpriseComponent,
    updateOverview,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    const {
        data: overviewResult,
        error: isError,
        loading,
    } = useTonkeanService('getEnterpriseComponentOverview', projectId, enterpriseComponent.id, enterpriseComponentType);

    useEffect(() => {
        if (overviewResult) {
            updateOverview(() => overviewResult as ConvertEnterpriseComponentTypeToOverviewResult<T>);
        }
    }, [overviewResult, updateOverview]);

    const authenticationService = useAngularService('authenticationService');
    const projectManager = useAngularService('projectManager');
    const $state = useAngularService('$state');

    const { getOverviewStepDefinitions } = useEnterpriseComponentContext();

    const [isCreateNewTemplateModalOpen, setIsCreateNewTemplateModalOpen] = useState(false);

    const onClickCreateNewTemplate = useCallback(() => {
        setIsCreateNewTemplateModalOpen(true);
    }, [setIsCreateNewTemplateModalOpen]);

    const marketplaceFeatureFlag = useFeatureFlag('tonkean_feature_marketplace');
    const isNoCodeIntegration = enterpriseComponent.integrationType === IntegrationType.NO_CODE_DATA_SOURCE;

    const getTemplateStepDefinitions = useCallback(() => {
        if (enterpriseComponent.publicMarketplaceItemTemplateName) {
            return [
                {
                    title: `Connected To '${enterpriseComponent.publicMarketplaceItemTemplateName}'`,
                    description: `Current Version: ${
                        enterpriseComponent.publicMarketplaceItemTemplateVersion ?? 'N/A'
                    }`,
                    page: ProjectIntegrationPageMenuItemType.OVERVIEW,
                    status: EnterpriseComponentOverviewStepStatus.VALID,
                },
            ];
        } else {
            if (!marketplaceFeatureFlag || !isNoCodeIntegration) {
                return [];
            }

            return [
                {
                    title: 'Create a template',
                    description:
                        'Create and publish a template from this data source, enabling other makers to leverage it and create their own template.',
                    page: ProjectIntegrationPageMenuItemType.OVERVIEW,
                    status: EnterpriseComponentOverviewStepStatus.EMPTY,
                    actions: (
                        <EnterpriseComponentOverviewTemplateStepActions
                            onClickCreateTemplate={onClickCreateNewTemplate}
                        />
                    ),
                },
            ];
        }
    }, [
        marketplaceFeatureFlag,
        isNoCodeIntegration,
        enterpriseComponent.publicMarketplaceItemTemplateName,
        enterpriseComponent.publicMarketplaceItemTemplateVersion,
        onClickCreateNewTemplate,
    ]);

    const stepDefinitions = useMemo(() => {
        if (!overviewResult) {
            return [] as EnterpriseComponentOverviewStepDefinition[];
        }

        return [
            ...getTemplateStepDefinitions(),
            ...getGeneralEnterpriseComponentOverviewStepDefinitions(
                overviewResult,
                enterpriseComponentTypeDisplayName[enterpriseComponentType].singular,
                projectManager,
                authenticationService,
            ),
            ...getOverviewStepDefinitions(overviewResult),
        ].filter(Boolean) as EnterpriseComponentOverviewStepDefinition[];
    }, [
        authenticationService,
        enterpriseComponentType,
        getOverviewStepDefinitions,
        overviewResult,
        projectManager,
        getTemplateStepDefinitions,
    ]);

    const errorsCount = useMemo(() => {
        return stepDefinitions.filter((definition) => definition.status === EnterpriseComponentOverviewStepStatus.ERROR)
            .length;
    }, [stepDefinitions]);

    const uncompletedStepsCount = useMemo(() => {
        return stepDefinitions.filter(
            (definition) => definition.status === EnterpriseComponentOverviewStepStatus.WARNING,
        ).length;
    }, [stepDefinitions]);

    const errorsDescription = errorsCount > 0 && `${errorsCount} errors`;
    const warningDescription = uncompletedStepsCount > 0 && `${uncompletedStepsCount} uncompleted steps`;
    const warningAndErrorsDescription = [errorsDescription, warningDescription].filter(Boolean).join(' and ');

    const enterpriseComponentName = enterpriseComponentTypeDisplayName[enterpriseComponentType].singular;
    const notReadyDescription = `This ${enterpriseComponentName} has ${warningAndErrorsDescription}`;
    const readyDescription = `This ${enterpriseComponentName} is ready for use in your board.`;
    const description = errorsDescription || warningDescription ? notReadyDescription : readyDescription;

    const onCreateNewTemplateModalClose = useCallback(() => {
        setIsCreateNewTemplateModalOpen(false);
    }, [setIsCreateNewTemplateModalOpen]);

    const marketplaceItemAdditionalSections = useMemo(() => {
        return getMarketplaceItemAdditionalSections(
            enterpriseComponent.id as TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        );
    }, [enterpriseComponent]);

    return (
        <>
            <EnterpriseComponentPageTemplate
                name="Overview"
                description={
                    isError ? undefined : <>{loading ? <Placeholder $width="250px" $height="12px" /> : description}</>
                }
            >
                {!isError && !loading && (
                    <>
                        {stepDefinitions.map((definition, index) => (
                            <EnterpriseComponentOverviewStep
                                key={`${definition.title}${definition.page}`}
                                definition={definition}
                                loading={false}
                                isLastStep={index === stepDefinitions.length - 1}
                            />
                        ))}
                    </>
                )}

                {isError && <SimpleErrorStateMessage error={isError} />}

                {loading &&
                    range(5).map((index) => (
                        <EnterpriseComponentOverviewStep
                            key={index}
                            isLastStep={index === stepDefinitions.length - 1}
                            loading
                        />
                    ))}
            </EnterpriseComponentPageTemplate>

            <MarketplaceItemCreateEditModal
                open={isCreateNewTemplateModalOpen}
                onSubmit={onCreateNewTemplateModalClose}
                onClose={() => setIsCreateNewTemplateModalOpen(false)}
                projectId={projectId}
                marketplaceItemType={MarketplaceItemType.DATA_SOURCE}
                entityIdToCopy={enterpriseComponent.id}
                entityNameToCopy={enterpriseComponent.displayName}
                additionalSections={marketplaceItemAdditionalSections}
            />
        </>
    );
};

export default EnterpriseComponentOverview;
