import template from './tnkColorOptionsPicker.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkColorOptionsPicker', {
    bindings: {
        selectedColor: '<', // Required, Initial state of the selected color.
        colorOptions: '<', // Required, Key\value pairs of color name to color hex value. (Object)
        isDisabled: '<',
        forceClose: '<',

        onColorSelected: '&',
    },
    transclude: true,
    template,
    controller: 'ColorOptionsPicker',
});
