/* eslint-disable no-nested-ternary */
import type PopperJS from '@popperjs/core';
import React from 'react';
import styled, { css } from 'styled-components';

import type FieldChartTooltipData from './FieldChartTooltipData';
import FieldChartTooltipType from './FieldChartTooltipType';

import { Breakpoint, Popover, Separator } from '@tonkean/infrastructure';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const TooltipElement = styled.div<{ $tooltipAbove: boolean }>`
    position: absolute;
    border-radius: 5px;
    padding: 0 15px;
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0 1px 4px ${Theme.colors.gray_500};
    background: ${Theme.colors.white};
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    ${({ $tooltipAbove }) =>
        $tooltipAbove &&
        css`
            bottom: 10px;
            transform: translateX(-50%);
        `}
`;

const PopoverOpeningPosition = styled.div<{ $positionLeft: number; $positionTop: number }>`
    position: absolute;
    left: ${({ $positionLeft }) => $positionLeft}px;
    top: ${({ $positionTop }) => $positionTop}px;
`;

const TooltipContentContainer = styled.div`
    min-width: 80px;
`;

const TooltipActorContainer = styled.div`
    min-width: 300px;
`;

const TooltipLabelColor = styled.div<{ $color?: Color }>`
    background: ${({ $color }) => $color ?? Theme.colors.white};
    border-width: 2px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
`;

const TooltipLabel = styled.div`
    display: flex;
    align-items: baseline;
    padding: 5px 0;
`;

const TooltipUpdateStatusLabelContainer = styled.div`
    min-width: 200px;
    white-space: nowrap;
`;

const TooltipLabelContent = styled.span`
    font-weight: 700;
`;

const TooltipTextContainer = styled.span`
    color: ${Theme.colors.gray_500};
    font-weight: 400;
`;

const ChangeStatusDate = styled.div`
    color: ${Theme.colors.gray_500};
`;

const StatusName = styled.div<{ $color: Color }>`
    display: inline;
    color: ${({ $color }) => $color};
    font-weight: 600;
    width: 30px;
`;

const UserAvatar = styled.span`
    width: 16px;
    height: 16px;
    position: relative;
    flex-shrink: 0;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        display: none;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 9px 0;
`;

const TooltipTitle = styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-top: 8px;
`;

const TooltipValueChangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
`;

const TooltipHistoryOfValuesChangedContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding-bottom: 8px;
    min-width: 200px;
    width: 100%;
`;

const TooltipValueChangeLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TooltipValuesListContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: baseline;
    width: 100%;
`;

const TooltipValuesListWrapper = styled.div`
    width: 100%;
    padding-top: 8px;
    &:not(:last-of-type) {
        padding-bottom: 8px;
    }
    &:not(:first-of-type) {
        border-top: 1px solid ${Theme.colors.gray_300};
    }
`;

interface Props {
    showTooltip: boolean;
    closeTooltip(): void;
    tooltipContent: { lines: FieldChartTooltipData[] }[] | undefined;
    tooltipLeft: number;
    tooltipTop: number;
    tooltipTitle: string;
    placement: PopperJS.Placement;
    tooltipAbove: boolean;
}

const PopoverTooltipElement: React.FC<Props> = ({
    showTooltip,
    closeTooltip,
    tooltipContent,
    tooltipLeft,
    tooltipTop,
    tooltipTitle,
    placement,
    tooltipAbove,
}) => {
    return (
        <Popover
            showArrow={false}
            show={showTooltip}
            onClose={closeTooltip}
            placement={placement}
            content={
                <TooltipElement $tooltipAbove={tooltipAbove}>
                    <TooltipTitle>{tooltipTitle}</TooltipTitle>
                    {tooltipContent?.map((content) => {
                        const fieldChartTooltipRow = content.lines[0];
                        if (!fieldChartTooltipRow) {
                            return <></>;
                        }
                        return (
                            <TooltipContentContainer key={`tooltipContentContainer${fieldChartTooltipRow.id}`}>
                                {fieldChartTooltipRow.type === FieldChartTooltipType.STATUS_CHANGE ? (
                                    <TooltipActorContainer>
                                        <Container>
                                            <UserAvatar>
                                                <TonkeanAvatar
                                                    key={`tooltipActivityOwner${fieldChartTooltipRow.id}`}
                                                    owner={fieldChartTooltipRow.actor}
                                                />
                                            </UserAvatar>
                                            <TooltipUpdateStatusLabelContainer>
                                                <TooltipLabelContent
                                                    key={`tooltipLabelActorName${fieldChartTooltipRow.id}`}
                                                >
                                                    {fieldChartTooltipRow.actor?.name}{' '}
                                                </TooltipLabelContent>
                                                <TooltipTextContainer>
                                                    updated the status to
                                                    <StatusName $color={fieldChartTooltipRow.color}>
                                                        {' '}
                                                        {fieldChartTooltipRow.label}
                                                    </StatusName>
                                                </TooltipTextContainer>
                                                <ChangeStatusDate>
                                                    at {fieldChartTooltipRow.realActivityDate}
                                                </ChangeStatusDate>
                                            </TooltipUpdateStatusLabelContainer>
                                        </Container>
                                    </TooltipActorContainer>
                                ) : !!fieldChartTooltipRow && fieldChartTooltipRow?.currentDateChanges ? (
                                    <TooltipHistoryOfValuesChangedContainer>
                                        <TooltipValuesListContainer
                                            key={`tooltipValuesListWrapper${fieldChartTooltipRow.id}`}
                                        >
                                            {fieldChartTooltipRow?.currentDateChanges.map((change, index) => {
                                                return (
                                                    <TooltipValuesListWrapper
                                                        key={`tooltipValuesListWrapper${change.time}${index}`}
                                                    >
                                                        <TooltipLabel key={`tooltipLabel${change.time}${index}`}>
                                                            <TooltipValueChangeLabelWrapper>
                                                                <TooltipLabelColor
                                                                    $color={fieldChartTooltipRow.color}
                                                                    key={`tooltipLabelColorBox${change.time}${index}`}
                                                                />
                                                                <TooltipLabelContent
                                                                    key={`TooltipLabelContent${change.time}${index}`}
                                                                >
                                                                    {change.value}{' '}
                                                                    <TooltipTextContainer>
                                                                        updated at {change.time}
                                                                    </TooltipTextContainer>
                                                                </TooltipLabelContent>
                                                            </TooltipValueChangeLabelWrapper>
                                                        </TooltipLabel>
                                                    </TooltipValuesListWrapper>
                                                );
                                            })}
                                        </TooltipValuesListContainer>
                                    </TooltipHistoryOfValuesChangedContainer>
                                ) : (
                                    <TooltipLabel key={`tooltipLabel${fieldChartTooltipRow.id}`}>
                                        <TooltipValueChangeContainer>
                                            <TooltipValueChangeLabelWrapper>
                                                <TooltipLabelColor
                                                    $color={fieldChartTooltipRow.color}
                                                    key={`tooltipLabelColorBox${fieldChartTooltipRow.id}`}
                                                />
                                                <TooltipLabelContent
                                                    key={`TooltipLabelContent${fieldChartTooltipRow.id}`}
                                                >
                                                    {fieldChartTooltipRow.label}
                                                </TooltipLabelContent>
                                            </TooltipValueChangeLabelWrapper>
                                        </TooltipValueChangeContainer>
                                    </TooltipLabel>
                                )}
                                {tooltipContent.length > 1 && <Separator />}
                            </TooltipContentContainer>
                        );
                    })}
                </TooltipElement>
            }
            noPadding
        >
            <PopoverOpeningPosition $positionLeft={tooltipLeft} $positionTop={tooltipTop} />
        </Popover>
    );
};
export default PopoverTooltipElement;
