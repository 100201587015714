import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as PublishVersionTriggerIcon } from '../../../../images/icons/publish-version-trigger.svg';
import { ReactComponent as TooltipTriggerIcon } from '../../../../images/icons/tooltip-trigger.svg';

import { Modal, Tooltip } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';

const PublishSolution = React.lazy(() => import('../../../modules/PublishSolutionModule/PublishSolution'));

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    position: relative;
`;

const TooltipTrigger = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

interface Props {
    active: true | string;
    workflowFolder: WorkflowFolder;
    onPublished(): void;
}

const PublishSolutionTrigger: React.FC<Props> = ({ active, workflowFolder, onPublished }) => {
    const [open, setOpen] = useState(false);

    const disabled = active !== true;

    return (
        <Wrapper>
            <React.Suspense fallback={<div className="loading margin-right" />}>
                <Modal open={open} onClose={() => setOpen(false)} size={1400} fixedWidth>
                    <PublishSolution workflowFolder={workflowFolder} onPublished={onPublished} />
                </Modal>
            </React.Suspense>

            <ButtonWrapper>
                <Button
                    data-automation="publish-solution-trigger-publish-button"
                    disabled={disabled}
                    onClick={() => setOpen(true)}
                    shape={ButtonShape.RECTANGULAR}
                    flex
                    publishRelated
                >
                    <span className="tnk-icon">
                        <PublishVersionTriggerIcon />
                    </span>
                    Publish Solution
                </Button>

                {disabled && (
                    <Tooltip content={active}>
                        <TooltipTrigger
                            data-automation="publish-solution-trigger-tooltip-on-disabled-publish-button"
                            className="svg-fix-size"
                        >
                            <span className="tnk-icon">
                                <TooltipTriggerIcon />
                            </span>
                        </TooltipTrigger>
                    </Tooltip>
                )}
            </ButtonWrapper>
        </Wrapper>
    );
};

export default PublishSolutionTrigger;
