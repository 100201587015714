<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>

    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <!-- Harvest API Key -->
            <div class="form-group">
                <label for="login-api-cid" class="col-sm-4 control-label">Harvest API Key</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.harvestApiKey"
                        class="form-control"
                        id="login-api-cid"
                        autocomplete="off"
                        placeholder="Harvest API Key"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <!--            &lt;!&ndash; Job board API Key &ndash;&gt;-->
            <!--            <div class="form-group">-->
            <!--                <label for="login-api-cid" class="col-sm-4 control-label">Job Board API Key</label>-->

            <!--                <div class="col-sm-8">-->
            <!--                    <input type="text" ng-model="data.jobBoardApiKey" class="form-control" id="login-api-cid"-->
            <!--                           autocomplete="off" placeholder="Job Board API Key" required>-->
            <!--                </div>-->
            <!--                <div class="common-subtitle-inner"><i></i></div>-->
            <!--            </div>-->

            <div class="margin-bottom-lg info-box common-size-xxs">
                <p class="common-color-dark-grey">Setup instructions:</p>
                <div class="common-color-dark-grey">
                    Go to
                    <a target="_blank" href="https://app2.greenhouse.io/configure/dev_center/credentials">
                        Visit the Greenhouse Dev Center
                    </a>
                    <br />
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>

        <div>
            <!-- Loading -->
            <div class="flex-vmiddle mod-justify-center" ng-if="data.loadingExistingIncomingWebhooks">
                <span class="loading-small margin-right-xs"></span>
                <span>Loading existing greenhouse webhooks endpoint...</span>
            </div>

            <!-- Error -->
            <div
                class="flex-vmiddle mod-justify-center common-color-danger common-size-xxxxs"
                ng-if="data.errorLoadingExistingIncomingWebhooks"
            >
                There was an error trying to load existing greenhouse webhooks endpoint.
            </div>

            <!-- Create endpoint button -->
            <div
                class="flex-vmiddle mod-justify-center"
                ng-if="
                    !data.incomingWebhookUrl &&
                    !data.loadingExistingIncomingWebhooks &&
                    !data.errorLoadingExistingIncomingWebhooks
                "
            >
                <div class="btn btn-primary" ng-click="createGreenhouseIncomingWebhook()">
                    Create Greenhouse Webhooks Endpoint
                </div>
            </div>

            <!-- Existing endpoint -->
            <div class="flex-vmiddle mod-justify-center common-bold" ng-if="data.incomingWebhookUrl">
                Greenhouse Webhooks Endpoint
            </div>

            <!-- Endpoint -->
            <div class="flex-vmiddle mod-justify-center" ng-if="data.incomingWebhookUrl">
                {{ data.incomingWebhookUrl }}
            </div>

            <!-- Supported webhooks -->
            <div class="common-size-xxxs margin-top-md info-box">
                <div class="common-color-dark-grey common-bold">Currently supported webhooks:</div>
                <div class="common-color-light-grey">
                    <ol class="margin-bottom-none">
                        <li class="margin-bottom-xs margin-top-xs">Candidate has been hired</li>
                        <li class="margin-bottom-xs">Candidate has submitted application</li>
                        <li class="margin-bottom-xs">Candidate has changed state</li>
                        <li class="margin-bottom-xs">Candidate prospect updated</li>
                        <li class="margin-bottom-xs">Prospect created</li>
                        <li class="margin-bottom-xs">Application updated</li>
                        <li class="margin-bottom-xs">Job created</li>
                        <li class="margin-bottom-xs">Job updated</li>
                    </ol>
                </div>
            </div>

            <!-- Setup instructions -->
            <div class="common-size-xxxs margin-top-md info-box">
                <div class="common-color-dark-grey common-bold">Setup instructions:</div>
                <div class="common-color-light-grey">
                    <ol>
                        <li class="margin-bottom-xs margin-top-xs">
                            Log in to your Greenhouse account and go to Settings > Dev Center*
                            <br />
                            * You must have developer permissions to access Dev Center
                        </li>
                        <li class="margin-bottom-xs">Go to Web Hooks > Web Hooks</li>
                        <li class="margin-bottom-xs">
                            Select the event* (When) Tonkean supports.
                            <br />
                            * You can create multiple webhooks with different events
                        </li>
                        <li class="margin-bottom-xs">Paste the webhook url from above in the 'Endpoint URL' field.</li>
                        <li class="margin-bottom-xs">Type the Secret key if required</li>
                        <li class="margin-bottom-xs">Click on the 'Create Web hook' button.</li>
                    </ol>

                    <div>For more information please contact our support team.</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
