import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import FormulaInnerRoundedBox from './FormulaInnerRoundedBox';
import FormulaContext from '../../entities/FormulaContext';
import HighlightedNodesSource from '../../entities/HighlightedNodesSource';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';
import FormulaTreeNodePopoverTabs from '../FormulaTreeNodePopoverTabs/FormulaTreeNodePopoverTabs';

import { InformationTooltip, Menu, MenuItem, Popover } from '@tonkean/infrastructure';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import { FormulaTreeEmptyNode, FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { createEmptyFormulaNode } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { classNames } from '@tonkean/utils';

function getMenuOptions(
    node: formulaTreeNode,
    disableWrap,
    disableClear,
    disableDelete,
    onReplaceSelected: () => void,
    onWrapSelected: () => void,
    onClearSelected: () => void,
    onResetToDefaultSelected: () => void,
    onDeleteSelected: () => void,
): { trigger(): void; icon: string; name: string }[] {
    const replaceOption = { trigger: onReplaceSelected, name: 'Edit value', icon: 'replace' };
    const wrapOption = { trigger: onWrapSelected, name: 'Wrap with formula', icon: 'wrap' };
    const clearOption = { trigger: onClearSelected, name: 'Clear value', icon: 'clear' };
    const resetToDefaultOption = {
        trigger: onResetToDefaultSelected,
        name: 'Reset to default',
        icon: 'revert',
    };
    const deleteOption = { trigger: onDeleteSelected, name: 'Delete parameter', icon: 'trash' };

    const disableResetToDefaultOption =
        node.type !== FormulaTreeNodeType.CONST || node.field.defaultValue?.toString() === node.value;

    return [
        replaceOption,
        !disableWrap && wrapOption,
        !disableClear && clearOption,
        !disableResetToDefaultOption && resetToDefaultOption,
        !disableDelete && deleteOption,
    ].filter(Boolean) as any;
}

// We are using inline button to allow the FormulaInnerRoundButton be a
const InlineButton = styled.button.attrs({ type: 'button' })`
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    text-align: left;

    &:focus {
        font-weight: bold;
        outline: none;
    }
`;

const MenuHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${FontSize.MSMALL_13};
    line-height: 16px;
    padding: 5px 10px;
    font-weight: bold;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const MenuIcon = styled.div`
    display: inline-block;
    background: center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    width: 15px;
    height: 15px;

    &.replace {
        background-image: url('/images/icons/formula-builder-replace.svg');
    }

    &.wrap {
        background-image: url('/images/icons/formula-builder-wrap.svg');
    }

    &.clear {
        background-image: url('/images/icons/formula-builder-clear.svg');
    }

    &.revert {
        background-image: url('/images/icons/formula-builder-revert.svg');
    }

    &.trash {
        background-image: url('/images/icons/formula-builder-trash.svg');
    }
`;

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: formulaTreeNode;
    /** Used by styled components */
    className?: string;
    /** unlike content, this prop allows JSX and should be rarely used as it will "break" the memorization */
    prefix?: React.ReactNode;
    disableWrap?: boolean;
    content?: string;
    left?: boolean;
    right?: boolean;
}

const FormulaNodePopoverTrigger: React.FC<Props> = ({
    content,
    className,
    depth,
    left,
    right,
    canDelete,
    disabled,
    node,
    prefix,
    disableWrap,
    onNodeChanged: emitOnNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    const {
        highlightedNodes,
        setHighlightedNodes,
        specificEditor,
        setSpecificEditor,
        workflowVersionId,
        onInnerTrackAggregationSelected,
        formulaChangedOutsideSpecificEditorCallback,
    } = useContext(FormulaContext);

    const isHighlighted = useMemo(() => highlightedNodes?.nodes?.includes(node), [highlightedNodes, node]);
    const isFaded = !isHighlighted && !!highlightedNodes;
    const nodeIsEmpty = node.type === FormulaTreeNodeType.EMPTY;

    const markAsActive = () => {
        // Only a tree node can be set active on hover (to show it's wrapping elements)
        if (!disabled && node.type === FormulaTreeNodeType.TREE) {
            setHighlightedNodes({ source: HighlightedNodesSource.HOVER, nodes: [node] });
        }
    };
    const unmarkAsActive = () => {
        if (highlightedNodes?.source === HighlightedNodesSource.HOVER && highlightedNodes.nodes?.[0] === node) {
            setHighlightedNodes(undefined);
        }
    };

    const [popoverShown, setPopoverShown] = useState(false);
    const [popoverInWrapMode, setPopoverInWrapMode] = useState(false);
    const [menuShown, setMenuShown] = useState(false);

    const triggerRef = useRef<HTMLDivElement>(null);

    const onNodeChanged = useCallback(
        (newNode: formulaTreeNode, oldNode: formulaTreeNode) => {
            if (newNode.type === FormulaTreeNodeType.TREE && newNode.operator.specificEditor && !specificEditor) {
                setSpecificEditor({
                    treeNode: newNode,
                    // Will be changed in the useEffect in FormulaOperatorFunction
                    onChanges: () => {},
                });
            }

            emitOnNodeChanged(newNode, oldNode);
            formulaChangedOutsideSpecificEditorCallback?.();
        },
        [specificEditor, emitOnNodeChanged, formulaChangedOutsideSpecificEditorCallback, setSpecificEditor],
    );

    const menuOptions = useMemo(() => {
        return getMenuOptions(
            node,
            disableWrap ?? nodeIsEmpty,
            nodeIsEmpty,
            !canDelete || nodeIsEmpty,
            () => {
                setPopoverShown(true);
                setPopoverInWrapMode(false);
            },
            () => {
                setPopoverShown(true);
                setPopoverInWrapMode(true);
            },
            () => {
                onNodeChanged(new FormulaTreeEmptyNode(node.field), node);
            },
            () => {
                onNodeChanged(createEmptyFormulaNode(node.field), node);
            },
            () => {
                onNodeDeleted(node);
            },
        );
    }, [canDelete, disableWrap, node, nodeIsEmpty, onNodeChanged, onNodeDeleted]);

    const togglePopover = (event: React.MouseEvent) => {
        event.stopPropagation();

        if (!disabled) {
            if (menuOptions.length === 1) {
                menuOptions[0]!.trigger();
            } else {
                setMenuShown(true);
            }
        }
    };

    return (
        <>
            <Popover
                className="formula-tree-popover"
                content={
                    <FormulaTreeNodePopoverTabs
                        workflowVersionId={workflowVersionId}
                        node={node}
                        depth={depth}
                        additionalTabs={additionalTabs}
                        customTrigger={customTrigger}
                        projectIntegration={projectIntegration}
                        isWrapMode={popoverInWrapMode}
                        deleteInPopover={canDelete && nodeIsEmpty}
                        closePopover={() => setPopoverShown(false)}
                        onNodeChanged={onNodeChanged}
                        onNodeDeleted={onNodeDeleted}
                        onInnerTrackAggregationSelected={onInnerTrackAggregationSelected}
                    />
                }
                show={popoverShown && !disabled}
                onClose={() => setPopoverShown(false)}
                nodeRef={triggerRef}
                placement="bottom"
                noPadding
            />

            <Menu
                show={menuShown && !disabled}
                menuItems={
                    <>
                        <MenuHeader>
                            {node.toNodeDisplayName()}
                            {node.type === FormulaTreeNodeType.TREE && (
                                <InformationTooltip>{node.operator.description}</InformationTooltip>
                            )}
                        </MenuHeader>

                        {menuOptions.map((menuOption) => (
                            <MenuItem
                                key={menuOption.name}
                                icon={<MenuIcon className={menuOption.icon} />}
                                onClick={() => {
                                    menuOption.trigger();
                                    setMenuShown(false);
                                }}
                            >
                                {menuOption.name}
                            </MenuItem>
                        ))}
                    </>
                }
                onClose={() => setMenuShown(false)}
                nodeRef={triggerRef}
                initiallyFocusOnWrapper // So that the info tooltip wont pop open as soon as user clicks the header
            />

            <FormulaInnerRoundedBox
                className={classNames(className, 'formula-tree-popover-trigger')}
                onClick={togglePopover}
                onMouseEnter={markAsActive}
                onMouseLeave={unmarkAsActive}
                left={left}
                right={right}
                depth={depth}
                hasError={isHighlighted && highlightedNodes?.source === HighlightedNodesSource.ERROR}
                faded={isFaded || disabled}
                nodeType={node.type}
                pointer={!disabled}
                ref={triggerRef}
            >
                {prefix}

                <InlineButton onClick={togglePopover} disabled={disabled} data-automation="formula-field-inline-button">
                    {content}
                </InlineButton>
            </FormulaInnerRoundedBox>
        </>
    );
};

export default FormulaNodePopoverTrigger;
