import React, { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import DeleteStatusModal from './DeleteStatusModal';
import StateTypeSelect from './StateTypeSelect';
import { ReactComponent as HamburgerIcon } from '../../../../../images/icons/hamburger.svg';
import { ReactComponent as XButton } from '../../../../../images/icons/x.svg';
import StatusListDataContext from '../StatusListDataContext';

import { PopoverHexColorPicker, Input, Tooltip, useFormikField } from '@tonkean/infrastructure';
import type { InitiativeStatus, State } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const StyledStateTypeSelect = styled(StateTypeSelect)`
    min-width: 90px;
`;

export const StatusRow = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
    min-width: 24px;
`;

interface Props {
    index: number;
    name: string;
    formikId: string;
    typesToInclude?: Set<InitiativeStatus>;
    onDelete: () => Promise<void>;
    forceDisableUpdateType?: boolean;
    forceDisableDelete?: boolean;
    deleteTooltip?: string;
    updateTypeTooltip?: string;
    isErrored: boolean;
    hideTypeSelection?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const DraggableStateRow: React.FC<Props> = ({
    index,
    name,
    formikId,
    onDelete,
    forceDisableDelete,
    deleteTooltip,
    updateTypeTooltip,
    isErrored,
    forceDisableUpdateType,
    typesToInclude,
    hideTypeSelection,
    inputRef,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { projectId, groupId, viewOnly } = useContext(StatusListDataContext);

    const { value } = useFormikField<State>(name);

    return (
        <Draggable draggableId={formikId} index={index}>
            {(provided) => (
                <div ref={provided.innerRef}>
                    <div {...provided.draggableProps}>
                        <StatusRow data-automation="draggable-state-row">
                            <StyledIconButton
                                {...provided.dragHandleProps}
                                dataAutomation="status-row-inner-drag"
                                disabled={viewOnly}
                                iconSize={14}
                                flat
                            >
                                <HamburgerIcon />
                            </StyledIconButton>

                            <PopoverHexColorPicker
                                dataAutomation="status-row-inner-color-picker"
                                name={`${name}.color`}
                                disabled={viewOnly}
                                flat
                            />

                            <Input
                                ref={inputRef}
                                data-automation="status-row-inner-label"
                                name={`${name}.label`}
                                disabled={viewOnly}
                                isError={isErrored}
                            />

                            {!hideTypeSelection && (
                                <Tooltip content={updateTypeTooltip} disabled={!updateTypeTooltip}>
                                    <StyledStateTypeSelect
                                        dataAutomation="status-row-inner-select-type"
                                        name={`${name}.type`}
                                        typesToInclude={typesToInclude}
                                        isDisabled={viewOnly || forceDisableUpdateType}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip content={deleteTooltip} disabled={!deleteTooltip}>
                                <StyledIconButton
                                    dataAutomation="status-row-inner-delete-status"
                                    disabled={viewOnly || forceDisableDelete}
                                    onClick={async () => {
                                        if (value.id === 'NEW') {
                                            await onDelete();
                                        } else {
                                            setIsModalOpen(true);
                                        }
                                    }}
                                    flat
                                >
                                    <XButton />
                                </StyledIconButton>
                            </Tooltip>

                            {projectId && groupId && (
                                <DeleteStatusModal
                                    onDelete={onDelete}
                                    statusId={value.id}
                                    statusName={value.label}
                                    projectId={projectId}
                                    groupId={groupId}
                                    open={isModalOpen}
                                    onClose={() => setIsModalOpen(false)}
                                />
                            )}
                        </StatusRow>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default DraggableStateRow;
