import { useAngularService } from 'angulareact';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionInspectConditions from './ActionInspectConditions';
import LogicWorkerComponent from '../../../../components/common/tnkLogicWorkerComponent/LogicWorkerComponent';
import HistoryContext from '../../entities/HistoryContext';
import LogicType from '../SingleWorkerRun/LogicType';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { AnimatedSwitch, AnimatedSwitchItem, BlockedElement, Tab, Tabs } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, WorkerRun } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledTabsContainer = styled(Tabs)`
    position: sticky;
    z-index: 1001; // needs to be above the blocked-element z-index
    top: 0;
    background: ${Theme.colors.basicBackground};
    margin-left: 1px;
    padding: 10px 15px 0;
`;

const StyledTab = styled(Tab)`
    text-transform: capitalize;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ContentContainer = styled.div<{ width: number }>`
    flex-grow: 1;
    width: ${({ width }) => width}px;
`;

const TabContentWrapper = styled.div`
    padding: 10px 15px 15px;
`;

const BlockedElementStyled = styled(BlockedElement)`
    height: 100%;
`;

interface InspectTab {
    type: string;
    component: JSX.Element | undefined;
}

interface Props extends StyledComponentsSupportProps {
    workflowVersionId: string;
    configuredLogic: any;
    workerRun: WorkerRun;
    logicType: LogicType;
    width: number;
}

const ActionInspectSidePane: React.FC<Props> = ({
    workflowVersionId,
    configuredLogic,
    workerRun,
    logicType,
    width,
}) => {
    const customFieldsManager = useAngularService('customFieldsManager');
    const { loading: fieldDefinitionsLoading } = useAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
        workflowVersionId as TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        false,
    );

    const { state } = useContext(HistoryContext);

    const workerRunLogic = useMemo(() => {
        return workerRun?.workerRunLogics?.find((logic) => logic.id === state.workerRunLogicId);
    }, [workerRun, state.workerRunLogicId]);

    let configMode: string = 'configuration';
    if (logicType === LogicType.INLINE_ACTION) {
        const customTriggerActionType = workerRunLogic?.workerRunLogicActions?.[0]?.customTriggerActionType;
        if (customTriggerActionType === 'POST_ACTION_STATE_UPDATE') {
            configMode = 'when-finished';
        } else if (customTriggerActionType === 'POST_ACTION_SEND_NOTIFICATION') {
            configMode = 'logic-notification';
        }
    }

    const tabs: InspectTab[] = [
        {
            type: 'Configuration',
            component: (
                <BlockedElementStyled noMessage>
                    <TabContentWrapper>
                        <LogicWorkerComponent
                            groupId={state.groupId}
                            workflowVersionId={workflowVersionId}
                            includeSpaceFillerForWarningMessage={false}
                            configuredLogic={configuredLogic}
                            logicConfigMode={configMode}
                            previewEvaluationSource={{
                                workerRunLogic: state.workerRunLogicId,
                                workerRunId: workerRun.id,
                                workerRunStartTime: workerRun.startTime,
                            }}
                            noHeader
                            viewOnly
                        />
                    </TabContentWrapper>
                </BlockedElementStyled>
            ),
        },
    ];
    if (logicType === LogicType.ACTION) {
        tabs.push({
            type: 'Conditions',
            component: workerRunLogic && (
                <TabContentWrapper>
                    <ActionInspectConditions
                        workflowVersionId={workflowVersionId}
                        configuredLogic={configuredLogic}
                        workerRunLogic={workerRunLogic}
                    />
                </TabContentWrapper>
            ),
        });
    }

    const defaultTab: InspectTab = tabs[0]!;
    const [selectedTabType, setSelectedTabType] = useState(defaultTab.type);
    const selectedTab: InspectTab = tabs.find((tab) => tab.type === selectedTabType) || defaultTab;

    return (
        <Container>
            <StyledTabsContainer
                value={selectedTabType}
                onChange={(type) => setSelectedTabType(type)}
                height="52px"
                showBackground={false}
            >
                {tabs.map((tab) => (
                    <StyledTab
                        dataAutomation="single-worker-run-action-inspect-tab-side-pane"
                        key={tab.type}
                        label={tab.type}
                    >
                        {tab.type}
                    </StyledTab>
                ))}
            </StyledTabsContainer>
            {!fieldDefinitionsLoading && (
                <ContentContainer width={width}>
                    <div className="overflow-hidden flex-grow common-height-full">
                        <AnimatedSwitch activeLabel={selectedTab.type}>
                            <AnimatedSwitchItem
                                label={selectedTab.type}
                                className="common-height-full"
                                key={selectedTab.type}
                            >
                                {selectedTab.component}
                            </AnimatedSwitchItem>
                        </AnimatedSwitch>
                    </div>
                </ContentContainer>
            )}

            {fieldDefinitionsLoading && (
                <div className="flex-vmiddle mod-justify-center">
                    <i className="loading-large" />
                </div>
            )}
        </Container>
    );
};

export default ActionInspectSidePane;
