import { FieldArray, useField } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ValidateActionHandleType from './common/ValidateActionHandleType';
import type HandleResponseActionType from './ProjectIntegrationEntitySchema/HandleResponseActionType';
import ProjectIntegrationEntitySingleHandleResponseView from './ProjectIntegrationEntitySingleHandleResponseView';
import { defaultHandleResponseSubEntities } from './projectIntegrationEntitySplitPageFormikSchema';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { H3, H4, Paragraph } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const Header = styled(H4)`
    color: ${Theme.colors.gray_800};
    margin-bottom: 8px;
`;

const Description = styled(Paragraph)`
    margin-bottom: 16px;
`;

const RelatedEntitiesWrapper = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    margin-top: 21px;
`;

const FirstSingleHandleResponse = styled(ProjectIntegrationEntitySingleHandleResponseView)`
    margin-bottom: 31px;
`;

const SingleHandleResponse = styled.div`
    margin-bottom: 21px;
    background-color: ${Theme.colors.gray_900};
    padding: 24px 28px 80px 24px;
`;

const SingleHandleResponseHeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const SingleHandleResponseHeader = styled(H3)``;

const StyledClickableLink = styled(ClickableLink)`
    padding-top: 12px;
`;

interface Props {
    projectIntegrationEntity: ProjectIntegrationEntity;
    labelPrefix: string;
    expressionProps?: FormikExpressionProps;
    validationId: string | undefined;
    showFetchEntity: boolean;
    isLoading?: boolean;
    namePrefix: string;
    validateActionHandleType: ValidateActionHandleType;
    projectIntegration: ProjectIntegration;
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[];
    enableSubEntitiesCollectOption?: boolean;
    handleResponseActionType: HandleResponseActionType;
}

const ProjectIntegrationHandleResponse: React.FC<Props> = ({
    projectIntegrationEntity,
    labelPrefix,
    expressionProps,
    validationId,
    showFetchEntity,
    isLoading,
    namePrefix,
    validateActionHandleType,
    projectIntegration,
    entitiesSummary,
    enableSubEntitiesCollectOption,
    handleResponseActionType,
}) => {
    const {
        data: entitiesResponse,
        loading: isLoadingEntitiesisLoadingEntities,
        error,
    } = useTonkeanService('getProjectIntegrationEntitySummaries', projectIntegrationEntity.projectIntegrationId);

    const subEntitiesFormikName = `${namePrefix}.subEntities`;
    const [{ value: relatedEntitiesHandleResponseArray }, , { setValue: setRelatedEntitiesHandleResponseArray }] =
        useField(subEntitiesFormikName);

    const descriptionText = useMemo(() => {
        switch (validateActionHandleType) {
            case ValidateActionHandleType.PROJECT_INTEGRATION_TEST_RUN_ID:
                return 'Select the entity you want to collect from the test response.';

            case ValidateActionHandleType.WEBHOOK_PAYLOAD_ID:
                return 'For each payload we received we extract the entities. You can extract the values from the entity results.';

            default:
                return 'Unknown Validation Type';
        }
    }, [validateActionHandleType]);

    const projectIntegrationEntities: { id: string; displayName: string }[] = useMemo(() => {
        return (
            entitiesResponse?.entities.map((entity) => ({
                displayName: entity.displayName,
                id: entity.id,
            })) || []
        );
    }, [entitiesResponse?.entities]);

    return (
        <>
            <Header>When Request Succeeds</Header>
            <Description>{descriptionText}</Description>
            <FirstSingleHandleResponse
                projectIntegrationEntity={projectIntegrationEntity}
                isLoading={isLoading}
                expressionProps={expressionProps}
                responseHandlingDefinitionFormikNamePrefix={namePrefix}
                entityIdFormikName={`${namePrefix}.entityId`}
                parametersFormikNamePrefix={`${namePrefix}.parametersMap`}
                validateActionHandleType={validateActionHandleType}
                labelPrefix={labelPrefix}
                validationId={validationId}
                projectIntegrationEntities={[]}
                projectIntegration={projectIntegration}
                showFetchEntity={showFetchEntity}
                entitiesSummary={[]}
                handleResponseActionTypes={handleResponseActionType}
            />
            <RelatedEntitiesWrapper>
                <FieldArray name={subEntitiesFormikName}>
                    {({ remove }) => {
                        return relatedEntitiesHandleResponseArray?.map((relatedEntityHandleResponse, index) => (
                            <SingleHandleResponse key={`${subEntitiesFormikName}[${index}]`}>
                                <SingleHandleResponseHeaderRow>
                                    <SingleHandleResponseHeader>Entity Information</SingleHandleResponseHeader>
                                    <StyledClickableLink onClick={() => remove(index)}>
                                        remove entity
                                    </StyledClickableLink>
                                </SingleHandleResponseHeaderRow>
                                <ProjectIntegrationEntitySingleHandleResponseView
                                    projectIntegrationEntity={projectIntegrationEntity}
                                    isLoading={isLoading || isLoadingEntitiesisLoadingEntities}
                                    expressionProps={expressionProps}
                                    entityIdFormikName={`${subEntitiesFormikName}[${index}].entityId`}
                                    responseHandlingDefinitionFormikNamePrefix={`${subEntitiesFormikName}[${index}].responseHandlingDefinition`}
                                    parametersFormikNamePrefix={`${subEntitiesFormikName}[${index}].parametersMap`}
                                    validateActionHandleType={validateActionHandleType}
                                    labelPrefix={labelPrefix}
                                    validationId={validationId}
                                    projectIntegrationEntities={projectIntegrationEntities}
                                    projectIntegration={projectIntegration}
                                    showFetchEntity={showFetchEntity}
                                    entitiesSummary={entitiesSummary}
                                    handleResponseActionTypes={handleResponseActionType}
                                />
                            </SingleHandleResponse>
                        ));
                    }}
                </FieldArray>
            </RelatedEntitiesWrapper>
            {enableSubEntitiesCollectOption && (
                <StyledClickableLink
                    onClick={() =>
                        setRelatedEntitiesHandleResponseArray([
                            ...relatedEntitiesHandleResponseArray,
                            defaultHandleResponseSubEntities,
                        ])
                    }
                >
                    + Collect another entity
                </StyledClickableLink>
            )}
        </>
    );
};

export default ProjectIntegrationHandleResponse;
