import * as React from 'react';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Spacer } from '@tonkean/infrastructure';

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentCertificationTwoWayTLS: React.FC<Props> = ({ expressionProps }) => {
    return (
        <>
            <EnterpriseComponentAuthorizationParam>Private Key:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression
                name="certificationDetails.privateKey"
                {...expressionProps}
                placeholder="Base 64 of the content of the unencrypted private key file to use for signing HTTP requests"
            />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Certificate:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression
                name="certificationDetails.certificate"
                {...expressionProps}
                placeholder="Base 64 of the content of the server certificate file to use for signing HTTP requests"
            />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>CA Certificate:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression
                name="certificationDetails.caCertificate"
                {...expressionProps}
                placeholder="Base 64 of the content of the CA root certificate file to use for signing HTTP requests"
            />
        </>
    );
};

export default EnterpriseComponentCertificationTwoWayTLS;
