import { useAngularService } from 'angulareact';
import { useCallback, useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { FieldDefinition, FieldType, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FieldDefinitionTargetType } from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import utils from '@tonkean/utils';

interface useGetWorkflowVersionFieldsAndBasicFieldsData {
    allFieldDefinitions: FieldDefinition[];
    loading: boolean;
}

function useGetWorkflowVersionFieldsAndBasicFields(
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    specialFieldsForFeaturesKey: string[],
    specialFieldKeysToExclude?: string[],
    fieldTypeFilter?: FieldType,
    shouldFilterForAggregation?: boolean,
    isSolutionSite?: boolean,
): useGetWorkflowVersionFieldsAndBasicFieldsData {
    const customFieldsManager = useAngularService('customFieldsManager');
    const { data: customFieldDefinitions, loading } = useAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
        workflowVersionId,
    );

    const filterFieldForAggregation = useCallback(
        (fieldDefinition: FieldDefinition) => {
            const isGlobal = fieldDefinition.targetType === FieldDefinitionTargetType.GLOBAL;
            const fieldDefinitionDef = fieldDefinition.definition as any;
            const isAggregation =
                fieldDefinitionDef?.aggregationType === 'Terms' &&
                (fieldDefinitionDef?.formulaType === 'STRUCTURED' || fieldDefinition.type === 'AGGREGATE_QUERY');
            const isInnerItemFieldAggregation = fieldDefinitionDef?.innerTracksAggregationDefinition;
            if (isGlobal) {
                // if the field is a global field -> return only if it is aggregation field
                return isAggregation;
            } else if (isSolutionSite) {
                // if we are in a solution site and the field is an inner item aggregation - do not return field.
                return !isInnerItemFieldAggregation;
            }
            return true;
        },
        [isSolutionSite],
    );

    const allFieldDefinitions = useMemo(() => {
        let specialFieldDefinitions = getSpecialFieldsForFeatures(false, specialFieldsForFeaturesKey);
        if (specialFieldKeysToExclude?.length) {
            const filterKeysMap = utils.arrayToSet(specialFieldKeysToExclude);
            specialFieldDefinitions = specialFieldDefinitions.filter(
                (fieldDefinition) => !filterKeysMap[fieldDefinition.id],
            );
        }

        if (customFieldDefinitions) {
            let fieldDefinitions = [...specialFieldDefinitions, ...customFieldDefinitions];
            if (fieldTypeFilter) {
                fieldDefinitions = fieldDefinitions.filter((fieldDef) => fieldDef.fieldType === fieldTypeFilter);
            } else if (shouldFilterForAggregation) {
                fieldDefinitions = fieldDefinitions.filter((fieldDef) => filterFieldForAggregation(fieldDef));
            }
            return fieldDefinitions;
        } else if (fieldTypeFilter) {
            specialFieldDefinitions = specialFieldDefinitions.filter(
                (fieldDef) => fieldDef.fieldType === fieldTypeFilter,
            );
        } else if (shouldFilterForAggregation) {
            specialFieldDefinitions = specialFieldDefinitions.filter((fieldDef) => filterFieldForAggregation(fieldDef));
        }

        return specialFieldDefinitions;
    }, [
        customFieldDefinitions,
        fieldTypeFilter,
        filterFieldForAggregation,
        shouldFilterForAggregation,
        specialFieldKeysToExclude,
        specialFieldsForFeaturesKey,
    ]);

    return { allFieldDefinitions, loading };
}

export default useGetWorkflowVersionFieldsAndBasicFields;
