import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class HMAC_SHA256EncryptionFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.HMAC_SHA256_ENCRYPTION;
    override readonly sign = 'HMAC_SHA256Encryption';
    override readonly displayName = 'HMAC SHA256 Encryption';
    override readonly description = `Encrypt data using secret key`;
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Data',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Secret Key',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Encoding Type',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            defaultValue: 'None',
            presetValues: ['None', 'Base16', 'Base64'],
        },
    ];
}
