import { CurlParser } from '@tonkean/utils';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ImportActionConfigFromCurlCtrl($scope, utils) {
    const ctrl = this;

    $scope.data = {
        previewText: '',
        importRawText: '',
        errors: [],
    };

    $scope.previewCurlParsing = function () {
        $scope.data.errors = [];
        ctrl.onError({ errors: [] });

        if ($scope.data.importRawText) {
            const parsedCurl = CurlParser.parse($scope.data.importRawText.trim());
            $scope.data.previewText = JSON.stringify(parsedCurl, null, 2);

            if (!utils.isEmpty(parsedCurl) && $scope.data.previewText.length > 0) {
                ctrl.onSuccessfulParse({ parsedCurl });
            } else {
                $scope.data.errors.push('Could not parse the given text, the format is unexpected');
                ctrl.onError({ errors: $scope.data.errors });
            }
        } else {
            $scope.data.previewText = '';
            ctrl.onSuccessfulParse({});
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('ImportActionConfigFromCurlCtrl', lateConstructController(ImportActionConfigFromCurlCtrl));
