import { useCallback } from 'react';

import { TonkeanId, TonkeanType, Validation, type ValidationState } from '@tonkean/tonkean-entities';

const useSetItemValidation = (
    viewOnly: boolean,
    currentlySelectedItemId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    visitedItemInterfacesValidation: Record<TonkeanId<TonkeanType.INITIATIVE>, ValidationState>,
    setDetailedItemsValidation,
) => {
    return useCallback(
        (validation: ValidationState) => {
            if (viewOnly) {
                return;
            }

            const visitedItem = currentlySelectedItemId && !!visitedItemInterfacesValidation[currentlySelectedItemId];

            if (visitedItem) {
                // Enforce true validation when navigating back to an item that was already visited
                setDetailedItemsValidation(currentlySelectedItemId, {
                    ...validation,
                    visibleValidation: validation.trueValidation,
                });

                if (visitedItemInterfacesValidation[currentlySelectedItemId]?.trueValidation === Validation.INVALID) {
                    validation.setForceShowValidation?.(true);
                }
            } else {
                setDetailedItemsValidation(currentlySelectedItemId, validation);
            }
        },
        [currentlySelectedItemId, setDetailedItemsValidation, viewOnly, visitedItemInterfacesValidation],
    );
};

export default useSetItemValidation;
