import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import SQLDatabaseIntegrationConfigurationContext from './SQLDatabaseIntegrationConfigurationContext';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

import { Chevron, ChevronDirection } from '@tonkean/infrastructure';
import { PencilNewIcon } from '@tonkean/svg';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const QueryTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const QueryInput = styled.input`
    font-size: 14px;
    outline: none;
    box-shadow: none;
    border: none;
    flex-grow: 1;
    margin-right: 10px;
`;

const QueryNameDisplay = styled.div`
    font-size: 14px;
    margin-right: 6px;
`;

const PencilIconWrapper = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
`;

const Error = styled.span`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.error};
    padding-left: 6px;
    margin-right: 5px;
    white-space: pre;
`;

const ExpandButton = styled.button`
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;

    ${styledFocus}
`;

const SQLDatabaseQueryConfigurationTitleSection: React.FC<{
    queryConfiguration: SQLDatabaseQueryConfiguration;
    index: number;
}> = ({ index, queryConfiguration: { queryNameInEditMode, queryName, expanded, nameValidationError } }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const { toggleEditMode, changeQueryName, changeExpanded } = useContext(SQLDatabaseIntegrationConfigurationContext);

    /**
     * Once user starts editing the title, we focus on the title input.
     */
    useEffect(() => {
        if (queryNameInEditMode) {
            inputRef?.current?.focus();
        }
    }, [queryNameInEditMode]);

    return (
        <QueryTitleContainer>
            {queryNameInEditMode && (
                <QueryInput
                    ref={inputRef}
                    value={queryName}
                    onChange={({ target }) => changeQueryName(target.value, index)}
                    onBlur={() => toggleEditMode(false, index)}
                    placeholder="Query Entity"
                    maxLength={100}
                />
            )}

            {!queryNameInEditMode && (
                <div className="flex-vmiddle flex-grow">
                    <QueryNameDisplay>{queryName}</QueryNameDisplay>

                    {expanded && (
                        <PencilIconWrapper className="svg-icon-sm" onClick={() => toggleEditMode(true, index)}>
                            <span className="tnk-icon">
                                <PencilNewIcon />
                            </span>
                        </PencilIconWrapper>
                    )}
                </div>
            )}

            {nameValidationError && <Error>{nameValidationError}</Error>}

            <ExpandButton onClick={() => changeExpanded(false, index)}>
                <Chevron direction={expanded ? ChevronDirection.DOWN : ChevronDirection.UP} className="pointer" />
            </ExpandButton>
        </QueryTitleContainer>
    );
};

export default SQLDatabaseQueryConfigurationTitleSection;
