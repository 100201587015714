import { analyticsWrapper } from '@tonkean/analytics';

function ImportTableCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $timeout,
    $q,
    projectManager,
    tonkeanService,
    utils,
    customFieldsManager,
    trackHelper,
    inviteManager,
    workflowVersionManager,
) {
    $scope.pm = $rootScope.pm;

    $scope.data = {
        result: {
            include: {},
            ownersMap: {},
            avoidDups: 'ignore',
        },
        owners: [],
        fieldMapping: [],
        failedTracks: [],
        failedTracksCount: 0,
        filteredFields: $scope.displayFieldsList?.length
            ? $scope.table[0].filter((headerField) => $scope.displayFieldsList.includes(headerField))
            : $scope.table[0],
    };

    $scope.import = async function () {
        $scope.error = '';
        analyticsWrapper.track('Import CSV', { category: 'Import' });

        if ($scope.data.result.trackName && $scope.data.result.trackName.length) {
            if ($scope.table.length < 2) {
                $scope.error = 'No data to import.';
                return;
            }

            $scope.data.result.trackNameIndex = $scope.table[0].indexOf($scope.data.result.trackName);
            $scope.data.result.ownerIndex = $scope.table[0].indexOf($scope.data.result.owner);
            $scope.data.result.dueDateIndex = $scope.table[0].indexOf($scope.data.result.dueDate);

            if ($scope.data.result.ownerIndex > -1 && $scope.data.stage !== 'importing') {
                await extractOwners();
            }

            if ($scope.overrideImport && $scope.onOverrideImport) {
                // means the host of this directive wish to handle import by itself
                return $scope.onOverrideImport({ result: $scope.data.result });
            }

            $scope.data.stage = 'importing';

            $scope.data.progress = 0;
            $scope.data.state = 'Creating fields...';

            createFields($scope.table[0], function () {
                updateProgress(0.1);
                $scope.data.createdCount = 0;
                $scope.data.state = 'Importing tracks...';
                const groupId = $scope.groupId || projectManager.project.defaultGroup.id;
                const exsitingTitles = $scope.data.result.avoidDups ? getExistingTitlesMap(groupId, true) : {};

                createTracks($scope.table, 1, 100, groupId, exsitingTitles, function () {
                    $scope.data.stage = 'completed';
                    $rootScope.$broadcast('newActivityUpdate');
                    $rootScope.$broadcast('groupListUpdated', { groupIds: [groupId], shouldReloadLiveReport: true });
                });
            });
        }
    };

    $scope.matchOwners = function () {
        $scope.data.stage = 'importing';
        $scope.import();
    };

    $scope.isOptionEmpty = function (value) {
        return !utils.isNullOrEmpty(value);
    };

    /**
     * Checks whether there are fields which we're able to import on top of the title, owner and due date
     * @returns true if there are more fields to show
     */
    $scope.isAdditionalFieldsPresent = function () {
        return $scope.data.filteredFields.some(
            (field) =>
                field !== $scope.data.result.trackName &&
                field !== $scope.data.result.owner &&
                field !== $scope.data.result.dueDate,
        );
    };

    function createFields(columns, doneCallback) {
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId);

        const fieldDefinitionsToCreate = [];
        const nameMap = {};

        for (let i = 0; i < columns.length; i++) {
            updateProgress(i / columns.length);
            const column = columns[i];

            if (
                column &&
                $scope.data.result.include[column] &&
                column !== $scope.data.result.trackName &&
                column !== $scope.data.result.owner &&
                column !== $scope.data.result.dueDate
            ) {
                // check if the field exist in this group's fields.
                let existingField;
                if (workflowVersion && workflowVersion.workflowVersionFieldDefinitions) {
                    for (let j = 0; j < workflowVersion.workflowVersionFieldDefinitions.length; j++) {
                        const dataField = customFieldsManager.getFieldDefinitionFromCachesById(
                            workflowVersion.id,
                            workflowVersion.workflowVersionFieldDefinitions[j]?.fieldDefinition.id,
                        );
                        if (dataField?.name.toLowerCase() === column.toLowerCase()) {
                            existingField = dataField;
                            break;
                        }
                    }
                }

                nameMap[column] = i;

                if (existingField) {
                    // Take existing field
                    mapField(existingField, column, i);
                } else {
                    // Create the field
                    fieldDefinitionsToCreate.push({
                        name: column,
                        type: 'MANUAL',
                        updateable: true,
                        ranges: [],
                    });
                }
            }
        }

        let createMultiDefinitionsPromise = $q.resolve();

        // Only calling the create multi definitions api if we have any definitions to create
        if (fieldDefinitionsToCreate && fieldDefinitionsToCreate.length) {
            createMultiDefinitionsPromise = customFieldsManager.createMultipleFieldDefinitions(
                $scope.groupId,
                fieldDefinitionsToCreate,
                null,
            );
        }

        createMultiDefinitionsPromise.then(function (data) {
            if (data) {
                // creationResults
                for (let j = 0; j < data.creationResults.length; j++) {
                    const res = data.creationResults[j];
                    mapField(res.definition, res.name, nameMap[res.name]);
                }
            }

            // Mark the addField step in the on boarding as completed.
            $rootScope.onBoardingManager.completeStep('addField');

            updateProgress(1);
            customFieldsManager.getFieldDefinitions($scope.workflowVersionId);
            if (doneCallback) {
                doneCallback();
            }
        });
    }

    function mapField(field, column, index, dontSelect) {
        const groupId = $scope.groupId || projectManager.project.defaultGroup.id;

        $scope.data.fieldMapping.push({ column, fieldIndex: index, field });
        if (field.id && groupId && !dontSelect) {
            // Auto check the newly created field as a selected header. updateSelectedFields will update the server with this data.
            customFieldsManager.updateFieldIsHidden(field.id, false, $stateParams.formVersionType, $scope.groupId);
        }
    }

    function updateProgress(num) {
        $timeout(function () {
            $scope.data.progress = (num * 100).toFixed(0);
        });
    }

    function createTracks(rows, start, count, groupId, exsitingTitles, doneCallback) {
        let to = start + count;
        if (to > rows.length) {
            to = rows.length;
        }

        const initiatives = [];

        for (let rowId = start; rowId < to; rowId++) {
            const row = angular.copy(rows[rowId]);

            const trackName = row[$scope.data.result.trackNameIndex];
            if (
                trackName &&
                trackName.length &&
                (!exsitingTitles[trackName.toLowerCase().trim()] || $scope.data.result.avoidDups === 'update')
            ) {
                const newInitiative = { title: trackName };
                newInitiative.groupId = groupId;

                if (exsitingTitles[trackName.toLowerCase().trim()] && $scope.data.result.avoidDups === 'update') {
                    // if there is already a track with that title use it's id so we know to update
                    const original = exsitingTitles[trackName.toLowerCase().trim()];
                    newInitiative.initiativeId = original.id || original.initiativeId;
                    newInitiative.dueDate = original.dueDate;
                    newInitiative.owner = original.owner && original.owner.id ? original.owner.id : original.owner;
                }

                // try to get duedata
                if ($scope.data.result.dueDateIndex > -1) {
                    try {
                        const date = Date.parse(row[$scope.data.result.dueDateIndex]);
                        if (date) {
                            newInitiative.dueDate = date.getTime();
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                // try to get owner
                if ($scope.data.result.ownerIndex > -1) {
                    try {
                        const ownerObj = $scope.data.result.ownersMap[row[$scope.data.result.ownerIndex]];
                        if (ownerObj && ownerObj.people && ownerObj.people.length && ownerObj.people[0]) {
                            newInitiative.owner = ownerObj.people[0].id;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                const dataFields = [];
                for (let j = 0; j < $scope.data.fieldMapping.length; j++) {
                    const field = $scope.data.fieldMapping[j];
                    dataFields.push({
                        fieldDefinitionId: field.field.id,
                        value: row[field.fieldIndex],
                    });
                }

                if (dataFields.length) {
                    newInitiative.fields = dataFields;
                }

                if ($scope.data.result.avoidDups) {
                    // Save the title to the map
                    exsitingTitles[trackName.toLowerCase().trim()] = newInitiative;
                }

                if ($scope.createInEditMode) {
                    newInitiative.inEditMode = true;
                }

                if ($scope.formId) {
                    newInitiative.createdByFormId = $scope.formId;
                }

                if ($scope.parentId) {
                    newInitiative.parentId = $scope.parentId;
                    newInitiative.parentAlreadyExists = true;
                }

                if ($stateParams.formVersionType.toLowerCase() === 'DRAFT'.toLowerCase()) {
                    newInitiative.isDraftInitiative = true;
                }

                initiatives.push(newInitiative);
            }
        }

        if (!initiatives.length) {
            // we might ignored all of them, call the next batch
            handleCreateMultipleResponse(rows, to, count, groupId, exsitingTitles, doneCallback);
        } else {
            tonkeanService
                .createMultipleInitiatives(groupId, initiatives)
                .then(function (data) {
                    if (data && data.entities) {
                        // save count of successful
                        $scope.data.createdCount += data.entities.length;
                    }
                    handleCreateMultipleResponse(rows, to, count, groupId, exsitingTitles, doneCallback);
                })
                .catch(function (error) {
                    $scope.data.stage = 'completed';
                    $scope.data.failedTracksCount = rows.length - start;
                    $scope.data.createInitiativesError = error?.data?.error?.cause?.message;
                });
        }
    }

    function handleCreateMultipleResponse(rows, to, count, groupId, exsitingTitles, doneCallback) {
        updateProgress(to / rows.length);

        if (to < rows.length) {
            // call the next bulk
            createTracks(rows, to, count, groupId, exsitingTitles, doneCallback);
        } else {
            // done!
            if (doneCallback) {
                doneCallback();
            }
        }
    }

    async function extractOwners() {
        for (let i = 1; i < $scope.table.length; i++) {
            const row = $scope.table[i];
            const owner = row[$scope.data.result.ownerIndex];
            if (owner && owner.length) {
                $scope.data.result.ownersMap[owner] = { people: [] };
                await fillPersonIfExist(owner);
            }
        }
    }

    async function fillPersonIfExist(owner) {
        const people = await tonkeanService.getTeamMembers($scope.pm.project.id, { group: $scope.groupId }, owner, 1);
        if (people && people[0]) {
            $scope.data.result.ownersMap[owner].people.length = 0;
            $scope.data.result.ownersMap[owner].people.push(people[0]);
        }
    }

    /**
     * Returns a map of all existing titles of root tracks in a group
     * @param groupId
     * @returns {{}}
     */
    function getExistingTitlesMap(groupId, toLower) {
        const ids = trackHelper.getTracksByGroupIdFromCache(groupId);
        const map = {};
        for (const id in ids) {
            if (ids.hasOwnProperty(id)) {
                const track = trackHelper.getInitiativeFromCache(id);
                if (track && !track.isArchived && !track.archived && !track.deleted) {
                    let title = track.title.trim();
                    if (toLower) {
                        title = title.toLowerCase();
                    }
                    map[title] = track;
                }
            }
        }

        return map;
    }

    $scope.bulkSelect = function (all) {
        if (!all) {
            $scope.data.result.include = {};
        } else {
            angular.forEach($scope.table[0], function (item) {
                $scope.data.result.include[item] = true;
            });
        }
    };
}

export default angular.module('tonkean.app').controller('ImportTableCtrl', ImportTableCtrl);
