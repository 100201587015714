import React from 'react';

import getWorkerRunFullDescription from '../../../utils/getWorkerRunFullDescription';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection, TextEllipsis } from '@tonkean/infrastructure';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason, WorkerRunStage } from '@tonkean/tonkean-entities';

interface Props {
    workerRun: WorkerRun;
    numberOfLines: number;
}

const WorkerRunsTableItemDescription: React.FC<Props> = ({ workerRun, numberOfLines }) => {
    const shouldHaveProjectIntegration = workerRun.workerRunStage === WorkerRunStage.DATA_SOURCE;
    const { projectIntegration } = useGetProjectIntegrationById(
        shouldHaveProjectIntegration ? workerRun.projectIntegrationId : undefined,
    );

    const workerRunFullDescription = getWorkerRunFullDescription(workerRun, projectIntegration);

    return (
        <>
            {!shouldHaveProjectIntegration || projectIntegration ? (
                <TextEllipsis numberOfLines={numberOfLines} fullWords={false} tooltip>
                    {workerRunFullDescription}
                    {workerRun.workerRunReason === WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE &&
                        ` - ${workerRun.resumingText}`}
                </TextEllipsis>
            ) : (
                <PlaceholderGrid direction={PlaceholderGridDirection.ROWS}>
                    <Placeholder $width="90%" />
                    <Placeholder $width="50%" />
                </PlaceholderGrid>
            )}
        </>
    );
};

export default WorkerRunsTableItemDescription;
