import FunctionOperatorBase from './FunctionOperatorBase';

import { logicBlockTypes } from '@tonkean/logic-block-configs';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const outgoingWebhookType = logicBlockTypes.OUTGOING_WEBHOOK.type;
const performIntegrationActionType = logicBlockTypes.PERFORM_INTEGRATION_ACTION.type;

export default class FileToBase64Function extends FunctionOperatorBase {
    override readonly key = OperatorKey.FILE_TO_BASE64;
    override readonly sign = 'FileToBase64';
    override readonly displayName = 'File to Base64';
    override readonly description = 'Will get the file from a Storage Provider and encode it to Base64.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'StorageProviderId',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'FileId',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];

    override isAllowed({ customTrigger }): boolean {
        if (!customTrigger) {
            return false;
        }

        const isCustomDataSourceCustomHttpAction =
            customTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition?.performedActionType ===
                'GENERIC_INTEGRATION_ACTION' &&
            customTrigger.customTriggerActions[0].customTriggerActionDefinition.integrationType === 'WEBHOOK';

        // Whether the user is using an action over outgoing webhook integration.
        const isCustomHttpAction = customTrigger.customTriggerType === outgoingWebhookType;

        // If the custom trigger,action, is related to a project integration.
        const isProjectIntegrationAction = customTrigger.customTriggerType === performIntegrationActionType;

        return isCustomDataSourceCustomHttpAction || isCustomHttpAction || isProjectIntegrationAction;
    }
}
