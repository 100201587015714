import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function HttpUploadLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        onActionsChanged: ctrl.onActionsChanged,
        previewEvaluationSource: ctrl.previewEvaluationSource,
    };

    $scope.onDefinitionChanged = function (definition, shouldSaveLogic) {
        const actionDefinition = {
            actions: [
                {
                    type: 'HTTP_UPLOAD',
                    definition,
                },
            ],
        };

        ctrl.onActionsChanged?.({ definition: actionDefinition, shouldSaveLogic });
    };
}

export default angular
    .module('tonkean.app')
    .controller('HttpUploadLogicConfigurationCtrl', lateConstructController(HttpUploadLogicConfigurationCtrl));
