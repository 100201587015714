import { lateConstructController } from '@tonkean/angular-components';
import { getDisplayFormatByAdditionalName } from '@tonkean/constants';
import { getAdditionalNameToFieldTypeApiNameMap } from '@tonkean/constants';
import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP, convertMatchSelectionToServerObject, getMatchConfigurationAccordingToSourceIntegration } from '@tonkean/tonkean-utils';

/* @ngInject */
function IntegrationExternalFieldCtrl(
    $rootScope,
    $scope,
    $timeout,
    integrations,
    utils,
    tonkeanUtils,
    customTriggerManager,
    customFieldsManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;
    $scope.ctm = customTriggerManager;
    $scope.cfm = customFieldsManager;
    $scope.formulaSpecialFieldIdToDefinitionMap = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        idOnlyMode: ctrl.idOnlyMode,
        workflowVersionId: ctrl.workflowVersionId,

        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,
        isCustomEntitiesFlagOn:
            $rootScope?.features?.currentProject?.tonkean_feature_enable_project_integration_entities,

        integrationSupportsAllEntities: false,

        integrationSupportsChangingType:
            integrations.getIntegrationSupportsChangingFieldType()[
                ctrl.projectIntegration.integration.integrationType.toLowerCase()
            ],
        additionalNameToFieldTypeApiNameMap: getAdditionalNameToFieldTypeApiNameMap(),

        matchConfigurationSelectionObject: null,
        matchDefaultSelectedOption:
            ctrl.idOnlyMode || ctrl.existingFieldDefinition?.idRelationField ? 'matchFieldCustomQuery' : 'specificItem',

        /** @type string | null */
        selectedEntity: null,
        /** @type string | null */
        selectedEntityPlural: null,
        /** @type object | null */
        selectedEntityMetadata: null,
        selectedField: null,
        selectedFieldType: null,
        newlySelectedField: false,

        picklistValues: [],

        displayAsApiNameToLabel: {
            String: 'Text',
            Number: 'Number',
            Date: 'Date',
            Boolean: 'Boolean',
            List: 'Dropdown',
        },

        isWorkflowVersionPublished: false,

        show: true,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        initExternalField();
        $scope.data.integrationSupportsAllEntities = shouldSupportAllEntities();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.fieldDefinitionNameEdited) {
            $scope.data.fieldDefinitionNameEdited = ctrl.fieldDefinitionNameEdited;
        }

        if (changesObj.projectIntegration) {
            $scope.data.projectIntegration = changesObj.projectIntegration.currentValue;

            $scope.data.integrationSupportsAllEntities = shouldSupportAllEntities();

            // Change the entity options
            $scope.data.integrationFieldsOptions =
                integrations.getFieldOptions()[$scope.data.projectIntegration.integration.integrationType.toLowerCase()];

            $scope.data.selectedEntity = null;
            $scope.data.selectedEntityMetadata = null;
            $scope.data.selectedField = null;
            $scope.data.newlySelectedField = false;

            // After we reset the selected entity and field we should call to definitionChanged to update about the
            // Resetting of these fields
            if (changesObj.projectIntegration.isFirstChange()) {
                definitionChanged(false);
            } else {
                definitionChanged(true);
            }
        }

        if (changesObj.selectedEntity) {
            $scope.onEntitySelected(ctrl.selectedEntity);
        }

        const changedEnvironment = changesObj.workflowVersionId && !changesObj.workflowVersionId.isFirstChange();
        if (changedEnvironment) {
            $scope.data.existingFieldDefinition = changesObj.existingFieldDefinition.currentValue;
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            initExternalField(false);
        }
    };

    /**
     * Gets the object that defines the real entity id.
     */
    function getExternalEntityIdField(entity, projectIntegration) {
        return {
            source: projectIntegration.integration.integrationUniqueType,
            entity,
            name: 'TNK_REAL_ENTITY_ID',
            label: 'Data Source Entity Id',
            type: 'String',
            projectIntegrationId: projectIntegration.id,
        };
    }

    /**
     * Function that gets the entity name.
     *
     * @param entity {string | {entity: string} | undefined} - the entity.
     * @returns {string | undefined} the entity name.
     */
    function getEntityName(entity) {
        if (!entity) {
            return undefined;
        }

        if (typeof entity === 'object') {
            return entity.entity;
        }

        return entity;
    }

    /**
     * Update selectedEntity, selectedEntityPlural, selectedEntityMetadata.
     *
     * @param selectedEntity {string | object} - the newly selected entity. It can be a string or a metadata object.
     * @returns {void}
     */
    function updateSelectedEntity(selectedEntity) {
        const entityName = getEntityName(selectedEntity);

        const { entityType, pluralLabel } = integrations.getSyncSettingsByEntity(
            ctrl.projectIntegration.integration.integrationType,
            entityName,
        ) || { entityType: entityName };

        $scope.data.selectedEntity = entityType;
        $scope.data.selectedEntityPlural = pluralLabel;
        $scope.data.selectedEntityMetadata = utils.isObject(selectedEntity) ? selectedEntity : null;
    }

    /**
     * Selects an entity for field.
     * Sets the external type to the given option.
     * Sets the external type, which causes the select to be enabled.
     * Loads external fields for auto completion.
     *
     * @param selectedEntity {string | object} - the newly selected entity. It can be a string or a metadata object.
     */
    $scope.onEntitySelected = function (selectedEntity) {
        // If it's the same entity, we shouldn't do any change.
        if ($scope.data.selectedEntity?.toUpperCase() === getEntityName(selectedEntity)?.toUpperCase()) {
            return;
        }

        updateSelectedEntity(selectedEntity);

        $scope.data.integrationSupportsChangingType =
            integrations.getIntegrationSupportsChangingFieldType()[
                ctrl.projectIntegration.integration.integrationType.toLowerCase()
            ];

        const syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);
        const matchConfiguration = getMatchConfigurationAccordingToSourceIntegration(
            syncConfig?.viewData,
            selectedEntity,
        );

        if ($scope.data.idOnlyMode || $scope.data.existingFieldDefinition?.idRelationField) {
            // if it's id only (mapping for an entitiy) we always want to use the custom query
            $scope.data.matchConfigurationSelectionObject = {
                id: 'matchFieldCustomQuery',
                apiName: 'MATCH_FIELD_CUSTOM_QUERY',
            };
            $scope.data.matchDefaultSelectedOption = 'matchFieldCustomQuery';
        } else if (
            matchConfiguration?.matchOptionApiName === 'SPECIFIC_ITEM' ||
            matchConfiguration?.matchOptionApiName === 'TONKEAN_SMART_MATCHING'
        ) {
            $scope.data.matchConfigurationSelectionObject = matchConfiguration;
            $scope.data.matchDefaultSelectedOption = 'specificItem';
        }

        // Resetting the selected field and available fields.
        $scope.data.selectedField = null;
        $scope.data.selectedFieldType = null;

        if (
            ($scope.data.createMode && $scope.data.idOnlyMode) ||
            $scope.data.existingFieldDefinition?.idRelationField
        ) {
            $scope.data.selectedField = getExternalEntityIdField(getExternalType(), $scope.data.projectIntegration);
        }

        $scope.data.show = false;

        $timeout(() => {
            $scope.data.show = true;
        });

        $scope.autoGenerateName();
        definitionChanged(true);
    };

    /**
     * Occurs once a field has been selected.
     */
    $scope.onFieldSelected = function (selectedField) {
        const previousSelectedFieldName = $scope.data.selectedField?.name;

        $scope.data.selectedField = selectedField;
        $scope.data.selectedFieldType = null;
        $scope.data.newlySelectedField = true;

        // Changing the name to the selected field label.
        $scope.autoGenerateName();
        definitionChanged(selectedField?.name !== previousSelectedFieldName);
    };

    /**
     * Rename the field with an auto generated name if it has not been edited manually
     */
    $scope.autoGenerateName = () => {
        if (ctrl.onFieldDefinitionNameChange && !$scope.data.fieldDefinitionNameEdited && $scope.data.selectedField) {
            let name = $scope.data.selectedField.label;
            if ($scope.data.idOnlyMode) {
                // adding the integration and entity name
                const entityName = $scope.data.selectedEntityMetadata?.label || $scope.data.selectedEntity || '';
                const showEntityName = entityName !== $scope.data.projectIntegration.displayName;
                name = `${$scope.data.projectIntegration.displayName} ${showEntityName ? `${entityName} ` : ''}${name}`;
            }
            ctrl.onFieldDefinitionNameChange({ fieldDefinitionName: name });
        }
    };

    /**
     * Occurs once the match option selection object is changed.
     */
    $scope.onMatchOptionSelectionChanged = function (selectionObject, shouldSave) {
        if (selectionObject && selectionObject.validMatchSelection) {
            $scope.data.matchConfigurationSelectionObject = selectionObject;
            definitionChanged(shouldSave);
        }
    };

    $scope.selectFieldType = function (fieldType) {
        $scope.data.selectedFieldType = fieldType;
        definitionChanged(true);
    };

    $scope.onPicklistValuesChanged = function (values) {
        $scope.data.picklistValues = values;
        definitionChanged(true);
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged(hadChanges = false, initializationMode = false) {
        const validDefinition = !!(
            $scope.data.selectedField &&
            $scope.data.selectedField.name &&
            $scope.data.selectedField.label
        );

        let possibleValues = [];
        if ($scope.data.selectedFieldType === 'List') {
            possibleValues = $scope.data.picklistValues;
        } else {
            possibleValues = $scope.data.selectedField?.values || [];
        }

        const definitionObject = {
            fieldConfigurationSummaryTitle: $scope.data.selectedField ? $scope.data.selectedField.label : null,
            fieldConfigurationSummarySubTitle: null,
            validDefinition,

            definition: {
                FieldName: $scope.data.selectedField ? $scope.data.selectedField.name : null,
                FieldLabel: $scope.data.selectedField ? $scope.data.selectedField.label : null,
                ExternalType: getExternalType(),
                entityMetadata: $scope.data.selectedEntityMetadata,
                fieldMetadata: $scope.data.selectedField,
                matchConfiguration: $scope.data.matchConfigurationSelectionObject
                    ? convertMatchSelectionToServerObject($scope.data.matchConfigurationSelectionObject)
                    : null,
            },

            possibleValues,
        };

        // Tell the server to override the field metadata regarding the possible values, and instead to take them from the params
        if ($scope.data.picklistValues.length) {
            definitionObject.forceUseDefinitionMetadata = true;
        }

        let fieldType;
        if ($scope.data.integrationSupportsChangingType) {
            fieldType =
                $scope.data.selectedFieldType ||
                ($scope.data.selectedField && $scope.data.selectedField.type) ||
                ($scope.data.existingFieldDefinition && $scope.data.existingFieldDefinition.fieldType);
            $scope.data.selectedFieldType = fieldType;
        } else {
            fieldType = $scope.data.selectedField && $scope.data.selectedField.type;
        }

        if ($scope.data.integrationSupportsChangingType) {
            definitionObject.fieldType = fieldType
                ? $scope.data.additionalNameToFieldTypeApiNameMap[fieldType.toLowerCase()]
                : null;
        }

        // Only not in initialization mode we send these configuration properties.
        if (
            (!initializationMode && $scope.data.selectedField && $scope.data.newlySelectedField) ||
            $scope.data.integrationSupportsChangingType
        ) {
            definitionObject.updateable = $scope.data.selectedField && $scope.data.selectedField.updateable;
            definitionObject.displayAs = definitionObject.fieldType;
            definitionObject.evaluatedDisplayType = definitionObject.fieldType;

            const foundDisplayFormat = fieldType
                ? getDisplayFormatByAdditionalName(fieldType.toLowerCase())
                : null;
            if (foundDisplayFormat) {
                definitionObject.displayFormat = foundDisplayFormat.apiName;
                definitionObject.displayFormatPrefix = foundDisplayFormat.prefix;
                definitionObject.displayFormatPostfix = foundDisplayFormat.postfix;
            }
        }

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
            });
        }

        if (hadChanges) {
            ctrl.onChange();
        }
    }

    /**
     * Returns the external type used for the field definition.
     */
    function getExternalType() {
        if ($scope.data.selectedEntityMetadata?.entity) {
            return $scope.data.selectedEntityMetadata.entity;
        } else if ($scope.data.selectedEntityMetadata?.pluralLabel) {
            return $scope.data.selectedEntityMetadata.pluralLabel;
        } else if ($scope.data.selectedEntity) {
            return $scope.data.selectedEntity;
        } else {
            return null;
        }
    }

    /**
     * Initializes elastic edit mode.
     */
    function initializeEditMode() {
        // Entity
        if ($scope.data.existingFieldDefinition.definition.entityMetadata) {
            updateSelectedEntity($scope.data.existingFieldDefinition.definition.entityMetadata);
        } else {
            const selectedEntity = {
                entity: $scope.data.existingFieldDefinition.definition['ExternalType'],
            };
            updateSelectedEntity(selectedEntity);
        }

        // Field
        if ($scope.data.existingFieldDefinition.definition.fieldMetadata) {
            $scope.data.selectedField = $scope.data.existingFieldDefinition.definition.fieldMetadata;
        } else {
            $scope.data.selectedField = {
                name: $scope.data.existingFieldDefinition.definition['FieldName'],
                label: $scope.data.existingFieldDefinition.definition['FieldLabel'],
            };
        }

        // Match option
        $scope.data.matchConfigurationSelectionObject = $scope.data.existingFieldDefinition.definition
            .matchConfiguration
            ? tonkeanUtils.convertMatchSelectionToClientObject(
                  $scope.data.existingFieldDefinition.definition.matchConfiguration,
              )
            : null;
    }

    function initExternalField(isInit = true) {
        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        if (ctrl.selectedEntity) {
            $scope.onEntitySelected(ctrl.selectedEntity);
        }

        $scope.data.isWorkflowVersionPublished = $scope.wvm.isPublishedVersion($scope.data.workflowVersionId);

        if (
            $scope.data.idOnlyMode ||
            $scope.data.existingFieldDefinition?.idRelationField ||
            $scope.data.isWorkflowVersionPublished
        ) {
            $scope.data.showMatchingSettings = true;
        }

        $scope.data.integrationFieldsOptions =
            integrations.getFieldOptions()[$scope.data.projectIntegration.integration.integrationType.toLowerCase()];

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged(false, isInit);
    }

    function shouldSupportAllEntities() {
        return (
            integrations.getAllEntitiesSupportedIntegrations()[
                ctrl.projectIntegration.integration.integrationType.toLowerCase()
            ] || $scope.data.isCustomEntitiesFlagOn
        );
    }
}

export default angular
    .module('tonkean.app')
    .controller('IntegrationExternalFieldCtrl', lateConstructController(IntegrationExternalFieldCtrl));
