import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';

import NavigationMenuCurrentPage from './NavigationMenuCurrentPage';
import NavigationMenuSwitch from './NavigationMenuSwitch';
import useIsInEmptyTrial from '../../hooks/useIsInEmptyTrial';
import { getPageByState } from '../../utils/navigationPagesConfiguration';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const NavigationTab = styled.div<{ $showGradient?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: skewX(20deg);
    width: 290px;
    height: 50px;
    padding: 2px 0 2px 16px;

    & > * {
        transform: skewX(-20deg);
    }

    ${({ $showGradient }) =>
        $showGradient &&
        css`
            background: linear-gradient(270deg, rgba(95, 92, 228, 0.06) 3.63%, rgba(95, 92, 228, 0) 100%);
        `}
`;

const CompanyLogo = styled.img`
    height: 24px;
`;

interface Props {
    transparentMode: boolean | undefined;
    showGradient?: boolean;
    logoUrl: string | undefined;
    projectUrlSlug: string;
    isSystemUser: boolean;
}

const NavigationMenu: React.FC<Props> = ({ showGradient, transparentMode, logoUrl, projectUrlSlug, isSystemUser }) => {
    const $state = useAngularService('$state');

    const [stateName] = useAngularWatch(() => $state.current.name);

    const currentPage = useMemo(() => {
        return getPageByState(stateName);
    }, [stateName]);
    const [hideNavigationInHeader, hideNavigationCurrentPage] = useGetStateParams(
        'hideNavigationInHeader',
        'hideNavigationCurrentPage',
    );
    const isInEmptyTrial = useIsInEmptyTrial();

    const containerNodeRef = useRef<HTMLDivElement>(null);

    return (
        <NavigationTab ref={containerNodeRef} $showGradient={showGradient}>
            {!hideNavigationInHeader && !isInEmptyTrial && (
                <NavigationMenuSwitch
                    currentPage={currentPage}
                    transparentMode={transparentMode}
                    alignToNodeRef={containerNodeRef}
                    isSystemUser={isSystemUser}
                />
            )}
            {hideNavigationCurrentPage ? (
                logoUrl && (
                    <Clickable state="homepageView" params={{ projectUrlSlug }}>
                        <CompanyLogo src={logoUrl} alt="Company logo" />
                    </Clickable>
                )
            ) : (
                <NavigationMenuCurrentPage currentPage={currentPage} />
            )}
        </NavigationTab>
    );
};

export default NavigationMenu;
