import React from 'react';
import styled from 'styled-components';

import { Field, Paragraph, Toggle } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledField = styled(Field)`
    display: flex;
    justify-content: space-between;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const SolutionPublisherToggle: React.FC = () => {
    return (
        <Wrapper>
            <StyledField label="Should publish solution after install?" showLabelInline>
                <Toggle
                    name="configuration.shouldBePublished"
                    onClick={(e) => e.stopPropagation()}
                    size={InputSize.SMALL}
                    dataAutomation="should-publish-after-install"
                />
            </StyledField>
            <StyledField label="* Should include predefined external activities?" showLabelInline>
                <Toggle
                    name="configuration.includeExternalActivities"
                    onClick={(e) => e.stopPropagation()}
                    size={InputSize.SMALL}
                />
            </StyledField>
            <Paragraph $small $light $thin>
                * Make sure to preform "Update Source" after changing the configuration.
            </Paragraph>
        </Wrapper>
    );
};

export default SolutionPublisherToggle;
