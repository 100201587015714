import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import BoardAdmins from './BoardAdmins';
import EnterpriseComponentPageTemplate from '../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import type { EnterpriseComponentMenuItemPageContentProps } from '../../../entities/EnterpriseComponentMenuItemPageContentProps';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import type { EnterpriseComponent, EnterpriseComponentType, Person } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';
import { getStateError } from '@tonkean/utils';

const PeopleSelectorWrapper = styled.div`
    width: 546px;
`;

const EnterpriseComponentPageAdmins = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponent,
    enterpriseComponentType,
    updateOverview,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    const projectManager = useAngularService('projectManager');
    const [supportUsers, setSupportUsers] = useState<Person[]>([]);
    const [enterpriseComponentAdmins, setEnterpriseComponentAdmins] = useState<Person[]>([]);

    const [, updateEnterpriseComponentAdmins] = useLazyTonkeanService('updateEnterpriseComponentAdmins');

    const { data: enterpriseComponentAdminsResult, error: errorLoadingEnterpriseComponentAdmins } = useTonkeanService(
        'getEnterpriseComponentAdmins',
        enterpriseComponent.id,
        enterpriseComponentType,
        projectManager.project.id,
    );

    const updateEnterpriseComponentAdminsIfUserHasPermissions = useCallback(() => {
        if (enterpriseComponent.id && enterpriseComponentAdmins) {
            updateOverview(() => {
                return {
                    adminIds: enterpriseComponentAdmins.map((admin) => admin.id),
                    adminsStepStatus: enterpriseComponentAdmins.length
                        ? EnterpriseComponentOverviewStepStatus.VALID
                        : EnterpriseComponentOverviewStepStatus.WARNING,
                } as Partial<ConvertEnterpriseComponentTypeToOverviewResult<T>>;
            });

            updateEnterpriseComponentAdmins(
                enterpriseComponent.id,
                enterpriseComponentType,
                projectManager.project.id,
                [...enterpriseComponentAdmins, ...supportUsers],
            );
        }
    }, [
        enterpriseComponent.id,
        enterpriseComponentAdmins,
        updateOverview,
        updateEnterpriseComponentAdmins,
        enterpriseComponentType,
        projectManager.project.id,
        supportUsers,
    ]);

    useEffect(() => {
        if (enterpriseComponentAdminsResult) {
            setEnterpriseComponentAdmins(enterpriseComponentAdminsResult.filter((admin) => !admin.systemUtilized));
            setSupportUsers(enterpriseComponentAdminsResult.filter((admin) => admin.systemUtilized));
        }
    }, [enterpriseComponentAdminsResult]);

    const enterpriseComponentTypeName =
        enterpriseComponentTypeDisplayName[enterpriseComponentType].singular.toLocaleLowerCase();
    return (
        <EnterpriseComponentPageTemplate
            name="Manage IT Admins"
            description={`Assign users as admins for the ${enterpriseComponentTypeName}. Admins are authorized to view data and configure the ${enterpriseComponentTypeName}.`}
        >
            <div className="flex-vmiddle">
                <PeopleSelectorWrapper>
                    <DeprecatedPeopleSelector
                        selectedPeople={enterpriseComponentAdmins}
                        disabled={false}
                        onTagAdded={updateEnterpriseComponentAdminsIfUserHasPermissions}
                        onTagRemoved={updateEnterpriseComponentAdminsIfUserHasPermissions}
                        noCustom
                        doNotSearchOnlyOwnersInGroup
                        includeGroups
                    />
                </PeopleSelectorWrapper>
                {errorLoadingEnterpriseComponentAdmins && (
                    <span className="common-error margin-left-lg">
                        {getStateError(errorLoadingEnterpriseComponentAdmins, { isNetworkError: false })}
                    </span>
                )}
            </div>

            <BoardAdmins />
        </EnterpriseComponentPageTemplate>
    );
};
export default EnterpriseComponentPageAdmins;
