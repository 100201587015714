import { REGULAR_INITIATIVES_PAGE_SIZE } from '@tonkean/constants';

function InitiativesCalendarCtrl(
    $rootScope,
    $scope,
    $state,
    $localStorage,
    timeUtils,
    $anchorScroll,
    modalUtils,
    initiativeManager,
    initiativeCache,
    tonkeanService,
    groupInfoManager,
    utils,
) {
    $scope.pm = $rootScope.pm;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.timeUtils = timeUtils;
    $scope.$state = $state;

    $scope.dueFilterOptions = [
        { label: 'Today', days: 1 },
        { label: 'Next 7 days', days: 7 },
        {
            label: 'Next 30 days',
            days: 30,
        },
    ];

    $scope.data = {
        weeks: [],
        initiatives: [],
        timelineInitiatives: [],
        timelineInitiativesMap: {},
        initiativesByGroup: {},
        selectedDay: {},
        loading: true,
        loadingGroups: true,
        initiativesWithDueDateCount: 0,
        trackTodayPopoverPlacement: 'top',
        onlyGroupId: null,
        startDayOptions: [
            {
                name: 'Sat',
                index: 6,
            },
            {
                name: 'Sun',
                index: 0,
            },
            {
                name: 'Mon',
                index: 1,
            },
        ],
        weekStartDay: { name: 'Mon' }, // d
    };

    // kind of a hack, but we want to make the groupId the same as the selected group for the day popover in mobile
    $scope.$watch('data.onlyGroupId', function (newVal) {
        $scope.groupId = newVal;
    });

    $scope.$on('newActivityUpdate', function () {
        $scope.loadInitiatives();
    });

    $scope.init = function () {
        $anchorScroll();
        $scope.data.startDate = utils.getLatestWeekDay();
        if (
            $rootScope.as.currentUser &&
            $rootScope.as.currentUser.metadata &&
            $rootScope.as.currentUser.metadata.weekStartDay
        ) {
            $scope.data.weekStartDay = $rootScope.as.currentUser.metadata.weekStartDay;
            $scope.data.startDate = utils.getLatestWeekDay(null, $scope.data.weekStartDay.index);
        }

        $scope.data.lastMonthDayLoaded = new Date($scope.data.startDate);

        $scope.loadMonth(new Date(), true);

        $rootScope.pageTitle = $scope.filter;

        groupInfoManager.getGroupsFull().then(() => ($scope.data.loadingGroups = false));
    };

    $scope.selectWeekStartDay = function (day) {
        if ($rootScope.as.currentUser) {
            $scope.data.weekStartDay = day;
            $scope.data.startDate = utils.getLatestWeekDay(null, $scope.data.weekStartDay.index);
            $scope.loadMonth(new Date(), true);

            const metadata = $rootScope.as.currentUser.metadata || {};
            metadata.weekStartDay = day;
            tonkeanService.updateProfileMetadata(metadata).catch(() => {
                $scope.$emit('alert', 'Error occurred while saving start day of week.');
            });
        }
    };

    $scope.loadMonth = function (d, firstTime) {
        const startDate = new Date(d);

        const monthFirst = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

        // var weekBefore = new Date(monthFirst.setDate(monthFirst.getDate()-7));

        const weeks = [];

        let date = new Date(monthFirst);

        for (let i = 0; i < 5; i++) {
            weeks.push({ startDate: new Date(utils.getLatestWeekDay(date, $scope.data.startDate.getDay())) });

            date = new Date(date.setDate(date.getDate() + 7));
        }

        $scope.data.currentMonth = new Date(monthFirst);
        $scope.data.currentStartDate = new Date(monthFirst.setDate(monthFirst.getDate() - 11));
        const lastDate = new Date(weeks[weeks.length - 1].startDate);
        $scope.data.currentEndDate = new Date(lastDate.setDate(lastDate.getDate() + 11));

        // console.log($scope.data.currentEndDate);
        $scope.data.dateRange = {
            from: $scope.data.currentStartDate.getTime(),
            to: $scope.data.currentEndDate.getTime(),
        };

        $scope.data.weeks = weeks;

        $scope.loadInitiatives(firstTime);
    };

    $scope.loadInitiatives = function (firstTime) {
        if (firstTime) {
            $scope.data.loading = true;
        } else {
            $scope.data.loadingInner = true;
        }

        return initiativeManager
            .searchInitiatives(
                $scope.pm.project.id,
                null,
                $scope.data.dateRange,
                null,
                null,
                null,
                null,
                null,
                0,
                REGULAR_INITIATIVES_PAGE_SIZE,
            )
            .then(function (data) {
                if ($scope.pm.projectData.isTimelineAvailable) {
                    const tree = $scope.data.timelineInitiatives.flat();
                    for (let i = 0; i < data.initiatives.length; i++) {
                        const initiative = data.initiatives[i];
                        const initiativeToAdd =
                            initiative.parentInitiatives && initiative.parentInitiatives.length
                                ? initiative.parentInitiatives[0]
                                : initiative;

                        if ($scope.data.timelineInitiativesMap[initiativeToAdd.id]) {
                            continue;
                        }

                        tree.push(initiativeToAdd);
                        $scope.data.timelineInitiativesMap[initiativeToAdd.id] = true;
                    }

                    $scope.data.timelineInitiatives = tree;
                }

                if (data && data.data) {
                    $scope.data.initiativesWithDueDateCount += data.data.entities.length;
                }
                $scope.data.loading = false;
                $scope.data.loadingInner = false;
            });
    };

    $scope.loadMoreInitiativesForTimeline = function ({ from, to }) {
        $scope.data.dateRange = {
            from,
            to,
        };

        return $scope.loadInitiatives();
    };

    $scope.openCalendarSubscriptionModal = function (groupId) {
        modalUtils.openCalendarSubscriptionModal(groupId);
    };

    $scope.$watch('data.initiativesWithDueDateCount', function () {
        if ($localStorage.showWeekView === undefined) {
            $scope.data.showWeekView = $scope.data.initiativesWithDueDateCount > 0;
        }
    });

    $scope.init();
}
export default angular.module('tonkean.app').controller('InitiativesCalendarCtrl', InitiativesCalendarCtrl);
