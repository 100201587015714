import template from './tnkInlineTextbox.template.html?angularjs';

function tnkInlineTextbox() {
    return {
        restrict: 'E',
        scope: {
            placeholder: '@',
            maxlength: '@',
            rows: '@',
            onLeave: '&',
            submit: '&',
            message: '=ngModel',
            required: '=',
            disabled: '@',
        },
        template,
        controller: 'InlineTextboxCtrl',
        link(scope, element) {
            element.addClass('tnk-inline-textbox');
        },
    };
}

export default angular.module('tonkean.app').directive('tnkInlineTextbox', tnkInlineTextbox);
