<!-- Modal title -->
<div class="common-title modal-header flex-vmiddle flex-wrap">
    <img
        ng-if="data.webhookSource.imgUrl"
        src="{{ data.webhookSource.imgUrl }}"
        class="initiative-integration-icon flex-no-shrink mod-normal margin-right"
    />
    <span class="flex-grow margin-right-xs">
        {{ data.incomingWebhook.displayName ? data.incomingWebhook.displayName : 'New Custom Data Source' }}
        <span ng-if="data.incomingWebhook.displayName" class="common-color-grey common-size-xxs">(Custom Source)</span>
    </span>
    <span class="flex-no-shrink common-size-xxs">
        <a ng-href="{{::$root.knowledgeBase.pages.webhooks}}" target="_blank">Need help?</a>
    </span>
</div>

<!-- Modal body -->
<div class="modal-body">
    <!-- Info and documentation -->
    <div class="padding-normal-h" ng-if="data.webhookSource && !data.editMode">
        <div class="common-color-dark-grey">
            <p>{{ data.webhookSource.name }} requires setup as a custom source, here's what you need to do:</p>
            <div class="padding-normal-sm-v">
                <div class="flex margin-bottom-lg">
                    <div class="common-circle mod-primary-border">1</div>
                    <div class="margin-left">
                        <div><strong>Upload your current data</strong></div>
                        <div>Export {{ data.webhookSource.name }} data to a CSV file and upload it to Tonkean.</div>
                    </div>
                </div>
                <div class="flex">
                    <div class="common-circle mod-primary-border">2</div>
                    <div class="margin-left">
                        <div><strong>Setup continuous data integration (can be done later)</strong></div>
                        <div>
                            Use a direct Webhook to keep your data continuously up-to-date (
                            <a ng-href="{{::$root.knowledgeBase.pages.webhooks}}" target="_blank">Learn more</a>
                            ).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Webhook definition form -->
    <form
        name="data.definitionForm"
        ng-submit="createOrEditWebHook()"
        ng-if="!data.loadingEditMode && !data.loadingDeleteWebhook"
        novalidate
    >
        <!-- Tab selection -->
        <div class="inner-tabs-bar flex" ng-if="data.editMode" ng-hide="data.sourceType == 'csv'">
            <div
                ng-class="{ 'mod-selected': !data.selectedTab || data.selectedTab === 'config' }"
                ng-click="data.selectedTab = 'config'; data.sourceType = 'webhook'"
            >
                Incoming Configuration
            </div>

            <div
                ng-class="{ 'mod-selected': data.selectedTab === 'outgoingConfiguration' }"
                ng-click="data.selectedTab = 'outgoingConfiguration'"
            >
                Outgoing Configuration
            </div>

            <div ng-class="{ 'mod-selected': data.selectedTab === 'dataLog' }" ng-click="data.selectedTab = 'dataLog'">
                Data log
            </div>

            <div
                ng-class="{ 'mod-selected': data.selectedTab === 'security' }"
                ng-click="data.selectedTab = 'security'"
            >
                Security
            </div>

            <div
                ng-if="data.isEntitiesFeatureFlagOn"
                ng-class="{ 'mod-selected': data.selectedTab === 'variables' }"
                ng-click="data.selectedTab = 'variables'"
            >
                Parameter Values
            </div>

            <!--Upload initial data-->
            <a ng-click="openImportWebhookDataModal()" class="common-size-xxs margin-right-xs">
                Upload data file (CSV)
            </a>
        </div>

        <!-- Settings Tab -->
        <div ng-show="!data.selectedTab || data.selectedTab === 'config'">
            <!-- Webhook URL (in edit mode) -->
            <div ng-if="data.editMode" ng-init="data.inputWebhookUrl = data.incomingWebhook.url">
                <div class="text-center rounded-box mod-blue-light padding-normal-sm margin-bottom-lg">
                    <div ng-show="data.showWebhookUrl && !data.showWebhookReceivedJSON">
                        <div>
                            <div class="common-size-xs common-bold">Webhook URL:</div>
                            <div class="common-color-grey common-size-xxxxs margin-top-xs">
                                Copy this url and call using HTTP POST with a JSON object or array of JSON objects.
                            </div>
                            <!-- Input (readonly in angular) with copy button -->
                            <div class="input-group common-width-90-no-xs margin-auto-right-left">
                                <input
                                    type="text"
                                    id="input-webhook-url-edit-mode"
                                    class="form-control inline-block text-center common-width-full"
                                    ng-model="data.inputWebhookUrl"
                                    ng-change="data.inputWebhookUrl = data.incomingWebhook.url"
                                />
                                <span class="input-group-btn">
                                    <button
                                        type="button"
                                        class="btn btn-default"
                                        data-automation="modal-template-copy-webhook-url"
                                        ng-click="copyUrlToClipboard('input-webhook-url-edit-mode')"
                                    >
                                        Copy
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        ng-hide="data.showWebhookUrl"
                        class="pointer text-left common-color-primary"
                        data-automation="modal-template-show-webhook-url"
                        ng-click="data.showWebhookUrl = true"
                    >
                        Show Webhook URL to send data to
                        <i class="fa fa-chevron-right margin-left"></i>
                    </div>
                </div>
            </div>

            <div class="padding-normal-h">
                <!-- Wrong mapping message -->
                <div
                    class="common-color-danger padding-normal-sm margin-top common-size-xxs"
                    ng-if="data.editMode && data.incomingWebhook.firstItemError && !data.hideWebhookFirstItemError"
                >
                    <strong>{{ data.incomingWebhook.firstItemError }}</strong>
                    <br />
                    <div class="flex">You can fix the mapping below.</div>
                </div>

                <!-- Integration display name field -->
                <div class="row margin-bottom-lg margin-top-lg" ng-hide="data.webhookSource">
                    <!-- Title -->
                    <div class="col-sm-3 common-size-xxs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                        Integration display name:
                    </div>
                    <!-- Input and explanation -->
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control"
                            data-automation="modal-enter-custom-data-source-name"
                            ng-model="data.incomingWebhook.displayName"
                            placeholder="Enter name"
                            required
                        />
                        <!--<div class="common-size-xxxxs margin-top-xxs common-color-grey">-->
                        <!--Choose a display name for the incoming webhook.-->
                        <!--</div>-->
                    </div>
                </div>

                <!-- Choose data source type -->
                <div class="row margin-bottom-lg margin-top-lg" ng-if="!data.webhookSource && !data.editMode">
                    <!-- Title -->
                    <div class="col-sm-3 common-size-xxs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                        How would you send the data?
                    </div>
                    <!-- Input and explanation -->
                    <div class="col-sm-8">
                        <tnk-radio-button
                            radio-id="data-type-manual"
                            radio-label="Manual"
                            radio-value="manual"
                            model="data.sourceType"
                            on-click="data.sourceType = 'manual'"
                            is-required="true"
                        ></tnk-radio-button>
                        <tnk-radio-button
                            radio-id="data-type-webhook"
                            radio-label="External Data Source"
                            radio-value="webhook"
                            model="data.sourceType"
                            on-click="data.sourceType = 'webhook'"
                            is-required="true"
                        ></tnk-radio-button>
                        <tnk-radio-button
                            radio-id="data-type-csv"
                            radio-label="Upload data files (CSV)"
                            radio-value="csv"
                            model="data.sourceType"
                            on-click="data.sourceType = 'csv'"
                            is-required="true"
                        ></tnk-radio-button>
                    </div>
                </div>

                <div ng-if="data.editMode">
                    <!-- ID Field path -->
                    <div class="margin-bottom-lg">
                        <div class="row margin-bottom-xs">
                            <!-- Title -->
                            <div
                                class="
                                    col-sm-3
                                    common-size-xxs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Unique Identifier field:</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-8">
                                <div class="flex-vmiddle margin-bottom-xs">
                                    <tnk-field-selector
                                        class="flex-grow margin-right-xxs"
                                        selected-field="data.incomingWebhook.definition.idFieldPath"
                                        selected-field-identifier="'idFieldPath'"
                                        items-source="EXTERNAL"
                                        project-integration="data.projectIntegration"
                                        external-type="data.projectIntegration.integration.integrationUniqueType"
                                        on-field-selected="
                                            onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                        "
                                        place-holder="Select field..."
                                        reload-fields="data.reloadFieldSelectorOptions"
                                        field-inspect-modal="true"
                                        auto-match-on-options="true"
                                        is-required="true"
                                        field-inspect-filter="isTonkeanSpecialField"
                                        fields-filter="filterBlackListedFields"
                                    ></tnk-field-selector>
                                    <!-- Add button -->
                                    <div tooltip-append-to-body="true" uib-tooltip="Add additional id mapping">
                                        <div
                                            class="dropdown-toggle pointer btn btn-no-border"
                                            ng-click="addAdditionalFieldMapping('ID')"
                                        >
                                            <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['ID']"
                                    class="margin-bottom-xs"
                                >
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xs"
                                            selected-field="item"
                                            selected-field-identifier="'ID.' + $index"
                                            items-source="EXTERNAL"
                                            project-integration="data.projectIntegration"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('ID',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="common-size-xxxxs margin-top-xxs common-color-grey">
                                    This field will be used as a unique identifier of the sent entity. If an entity with
                                    the same id already exists in Tonkean, it will update it.
                                </div>

                                <tnk-checkbox
                                    uib-tooltip="If checked will concatenate all provided id fields for the id value. Else, will default to additional ids if no value found, by order provided."
                                    label="'Concat additional ids'"
                                    value="data.incomingWebhook.definition.concatAdditionalIds"
                                ></tnk-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                        </div>
                    </div>

                    <!-- Title Field path -->
                    <div class="margin-bottom-lg">
                        <div class="row margin-bottom-xs">
                            <!-- Title -->
                            <div
                                class="
                                    col-sm-3
                                    common-size-xxs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Item Title field path:</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-8">
                                <div class="flex-vmiddle margin-bottom-xs">
                                    <tnk-field-selector
                                        class="flex-grow margin-right-xxs"
                                        selected-field="data.incomingWebhook.definition.titleFieldPath"
                                        selected-field-identifier="'titleFieldPath'"
                                        project-integration="data.projectIntegration"
                                        items-source="EXTERNAL"
                                        external-type="data.projectIntegration.integration.integrationUniqueType"
                                        on-field-selected="
                                            onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                        "
                                        place-holder="Select field..."
                                        reload-fields="data.reloadFieldSelectorOptions"
                                        field-inspect-modal="true"
                                        auto-match-on-options="true"
                                        field-inspect-filter="isTonkeanSpecialField"
                                        fields-filter="filterBlackListedFields"
                                        is-required="true"
                                    ></tnk-field-selector>
                                    <!-- Add button -->
                                    <div tooltip-append-to-body="true" uib-tooltip="Add additional title mapping">
                                        <div
                                            class="dropdown-toggle pointer btn btn-no-border"
                                            ng-click="addAdditionalFieldMapping('TITLE')"
                                        >
                                            <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['TITLE']"
                                    class="margin-bottom-xs"
                                >
                                    <!-- Input and explanation -->
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'TITLE.' + $index"
                                            items-source="EXTERNAL"
                                            project-integration="data.projectIntegration"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('TITLE',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="common-size-xxxxs margin-top-xxs common-color-grey">
                                    This field will be used as the main name/title of the entity.
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- updatableExternalActivities check box -->
                    <tnk-checkbox
                        label="'Received entities fields are updatable'"
                        value="data.projectIntegration.updatableExternalActivities"
                    ></tnk-checkbox>

                    <!-- JSON field settings title -->
                    <div class="row margin-bottom-lg common-size-xxs margin-top-lg" ng-if="data.editMode">
                        <div class="col-sm-3 padding-right-none">
                            <a ng-click="data.displayEditModeSettings = !data.displayEditModeSettings">
                                Edit all field settings
                                <i
                                    class="margin-left-xs fa"
                                    ng-class="data.displayEditModeSettings ? 'fa-caret-down' : 'fa-caret-right'"
                                ></i>
                            </a>
                        </div>
                        <div class="col-sm-8"></div>
                    </div>

                    <!-- JSON field settings -->
                    <div ng-show="data.displayEditModeSettings">
                        <!-- Pick off a Child Key field -->
                        <div class="row margin-bottom-lg">
                            <!-- Title and explanation -->
                            <div
                                class="
                                    col-sm-3
                                    common-size-xxs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">
                                    Pick off a Child Key:
                                    <i
                                        class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                        uib-tooltip="Click for more info"
                                        ng-click="$scope.infoStates.pickOffChildKey = !$scope.infoStates.pickOffChildKey"
                                    ></i>
                                </div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-8">
                                <i class="col-loading" ng-if="data.loadingPickOffChildKey"></i>
                                <div class="input-group relative">
                                    <input
                                        type="text"
                                        class="form-control"
                                        ng-model="data.incomingWebhook.definition.pickOffChildKey.name"
                                        ng-change="data.incomingWebhook.definition.pickOffChildKey.label = data.incomingWebhook.definition.pickOffChildKey.name"
                                        placeholder="Select field..."
                                        ng-disabled="data.loadingPickOffChildKey"
                                        ng-keydown="onPickOffChildKeyKeyDown($event)"
                                    />
                                    <span class="input-group-btn">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            ng-disabled="data.loadingPickOffChildKey"
                                            ng-click="setPickOffChildKey(data.incomingWebhook.definition.pickOffChildKey.name)"
                                        >
                                            Set
                                        </button>
                                    </span>
                                </div>
                                <div
                                    class="common-size-xxxxs margin-top-xxs common-color-grey"
                                    ng-if="$scope.infoStates.pickOffChildKey"
                                >
                                    By default, Tonkean will use the entire payload of the webhook. If this field is
                                    specified, Tonkean will only grab the object sent to the webhook. For example, given
                                    {"contact": {"name": "Mike"}}, specify
                                    <strong>contact</strong>
                                    to only trigger on {"name": "Mike"}. Traverse more deeply nested children by using
                                    dot-separated syntax.
                                </div>
                            </div>
                        </div>

                        <!-- Owner-Email Field path -->
                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <!-- Title and subtitle -->
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="common-bold">
                                        Owner-Email field:
                                        <i
                                            class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                            uib-tooltip="Click for more info"
                                            ng-click="$scope.infoStates.ownerEmailFieldPath = !$scope.infoStates.ownerEmailFieldPath"
                                        ></i>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="data.incomingWebhook.definition.ownerEmailFieldPath"
                                            selected-field-identifier="'ownerEmailFieldPath'"
                                            items-source="EXTERNAL"
                                            project-integration="data.projectIntegration"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                            "
                                            allow-clear-selection="true"
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                        ></tnk-field-selector>
                                        <!-- Add button -->
                                        <div uib-tooltip="Add additional Owner-Email mapping">
                                            <div
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalFieldMapping('OWNER_EMAIL')"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="common-size-xxxxs margin-top-xxs common-color-grey"
                                        ng-if="$scope.infoStates.ownerEmailFieldPath"
                                    >
                                        This field will be used to match owners for sync.
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['OWNER_EMAIL']"
                                class="row margin-bottom-xs"
                            >
                                <div class="col-sm-3"></div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'OWNER_EMAIL.' + $index"
                                            items-source="EXTERNAL"
                                            project-integration="data.projectIntegration"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('OWNER_EMAIL',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Owner-Name Field path -->
                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <!-- Title and subtitle -->
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="common-bold">
                                        Owner-Name field:
                                        <i
                                            class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                            uib-tooltip="Click for more info"
                                            ng-click="$scope.infoStates.ownerNameFieldPath = !$scope.infoStates.ownerNameFieldPath"
                                        ></i>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="data.incomingWebhook.definition.ownerNameFieldPath"
                                            selected-field-identifier="'ownerNameFieldPath'"
                                            items-source="EXTERNAL"
                                            project-integration="data.projectIntegration"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                            "
                                            allow-clear-selection="true"
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                        ></tnk-field-selector>
                                        <!-- Add button -->
                                        <div uib-tooltip="Add additional Owner-Name mapping">
                                            <div
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalFieldMapping('OWNER_NAME')"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="common-size-xxxxs margin-top-xxs common-color-grey"
                                        ng-if="$scope.infoStates.ownerNameFieldPath"
                                    >
                                        This field holds the name of the owner.
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['OWNER_NAME']"
                                class="row margin-bottom-xs"
                            >
                                <div class="col-sm-3"></div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'OWNER_NAME.' + $index"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('OWNER_NAME',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Parent-Name Field path -->
                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <!-- Title and subtitle -->
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="common-bold">
                                        Parent-Name field:
                                        <i
                                            class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                            uib-tooltip="Click for more info"
                                            ng-click="$scope.infoStates.parentNameFieldPath = !$scope.infoStates.parentNameFieldPath"
                                        ></i>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="data.incomingWebhook.definition.parentNameFieldPath"
                                            selected-field-identifier="'parentNameFieldPath'"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                            "
                                            allow-clear-selection="true"
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                        ></tnk-field-selector>
                                        <!-- Add button -->
                                        <div uib-tooltip="Add additional Parent-Name mapping">
                                            <div
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalFieldMapping('PARENT_NAME')"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="common-size-xxxxs margin-top-xxs common-color-grey"
                                        ng-if="$scope.infoStates.parentNameFieldPath"
                                    >
                                        This field holds the parent name of the item.
                                    </div>
                                </div>
                            </div>
                            <div
                                ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['PARENT_NAME']"
                                class="row margin-bottom-xs"
                            >
                                <div class="col-sm-3"></div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'PARENT_NAME.' + $index"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('PARENT_NAME',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Description Field path -->
                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <!-- Title and subtitle -->
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="common-bold">
                                        Description field:
                                        <i
                                            class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                            uib-tooltip="Click for more info"
                                            ng-click="$scope.infoStates.descriptionFieldPath = !$scope.infoStates.descriptionFieldPath"
                                        ></i>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="data.incomingWebhook.definition.descriptionFieldPath"
                                            selected-field-identifier="'descriptionFieldPath'"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                            "
                                            allow-clear-selection="true"
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                        ></tnk-field-selector>
                                        <!-- Add button -->
                                        <div uib-tooltip="Add additional Description mapping">
                                            <div
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalFieldMapping('DESCRIPTION')"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="common-size-xxxxs margin-top-xxs common-color-grey"
                                        ng-if="$scope.infoStates.descriptionFieldPath"
                                    >
                                        This field holds the description of the entity.
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['DESCRIPTION']"
                                class="row margin-bottom-xs"
                            >
                                <div class="col-sm-3"></div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'DESCRIPTION.' + $index"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('DESCRIPTION',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- External url Field path -->
                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <!-- Title and subtitle -->
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="common-bold">
                                        Url field:
                                        <i
                                            class="fa fa-info-circle common-color-grey margin-left-xxs pointer"
                                            uib-tooltip="Click for more info"
                                            ng-click="$scope.infoStates.urlFieldPath = !$scope.infoStates.urlFieldPath"
                                        ></i>
                                    </div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="data.incomingWebhook.definition.urlFieldPath"
                                            selected-field-identifier="'urlFieldPath'"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                            "
                                            allow-clear-selection="true"
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                        ></tnk-field-selector>
                                        <!-- Add button -->
                                        <div uib-tooltip="Add additional url mapping">
                                            <div
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalFieldMapping('URL')"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="common-size-xxxxs margin-top-xxs common-color-grey"
                                        ng-if="$scope.infoStates.urlFieldPath"
                                    >
                                        This field holds a url link to the incoming entity in the external system.
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-repeat="item in data.incomingWebhook.definition.additionalFieldMappings['URL']"
                                class="row margin-bottom-xs"
                            >
                                <div class="col-sm-3"></div>
                                <!-- Input and explanation -->
                                <div class="col-sm-8">
                                    <div class="flex-vmiddle">
                                        <tnk-field-selector
                                            class="flex-grow margin-right-xxs"
                                            selected-field="item"
                                            selected-field-identifier="'URL.' + $index"
                                            project-integration="data.projectIntegration"
                                            items-source="EXTERNAL"
                                            external-type="data.projectIntegration.integration.integrationUniqueType"
                                            on-field-selected="
                                                onAdditionalFieldMappingChanged(selectedField, selectedFieldIdentifier)
                                            "
                                            place-holder="Select field..."
                                            reload-fields="data.reloadFieldSelectorOptions"
                                            field-inspect-modal="true"
                                            auto-match-on-options="true"
                                            is-required="true"
                                        ></tnk-field-selector>
                                        <!-- Remove button -->
                                        <button
                                            type="button"
                                            class="btn btn-no-border common-no-outline flex-no-shrink"
                                            ng-click="removeAdditionalFieldMapping('URL',$index)"
                                        >
                                            <span class="svg-icon-xs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="margin-bottom-lg">
                            <div class="row margin-bottom-xs">
                                <div
                                    class="
                                        col-sm-3
                                        common-size-xxs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <span class="common-bold">Additional Fields:</span>
                                </div>

                                <div class="col-sm-8 flex flex-col">
                                    <div
                                        class="flex-vmiddle margin-bottom-xs"
                                        ng-repeat="jsonPathDefinition in data.incomingWebhook.definition.enrichedFieldsForPickOff track by $index"
                                    >
                                        <div class="col-lg-12 flex row">
                                            <input
                                                type="text"
                                                class="form-control margin-right"
                                                ng-model="jsonPathDefinition.fieldName"
                                                placeholder="Field Name"
                                            />

                                            <input
                                                type="text"
                                                class="form-control"
                                                ng-model="jsonPathDefinition.jsonPath"
                                                placeholder="JSON Path Expression"
                                            />
                                        </div>

                                        <div>
                                            <!-- Remove button -->
                                            <button
                                                ng-if="!$first"
                                                type="button"
                                                ng-click="removeAdditionalJsonPath($index)"
                                                class="btn btn-no-border common-no-outline flex-no-shrink"
                                            >
                                                <span class="svg-icon-xs common-color-grey">
                                                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                                </span>
                                            </button>

                                            <div
                                                ng-if="$first"
                                                class="dropdown-toggle pointer btn btn-no-border"
                                                ng-click="addAdditionalJsonPath()"
                                            >
                                                <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Data log tab -->
        <div ng-if="data.selectedTab === 'dataLog'">
            <div class="flex-vmiddle common-size-xxs padding-bottom">
                <div class="flex-grow">
                    Last update:
                    <span
                        ng-if="
                            webhookEntities &&
                            webhookEntities.length &&
                            webhookEntities[0] &&
                            webhookEntities[0].updated
                        "
                    >
                        {{ webhookEntities[0].updated | date: 'short' }}
                    </span>
                </div>
                <div class="text-right padding-normal-sm-h">
                    <a ng-click="loadWebhookEntities()">
                        <i class="fa fa-refresh margin-right-xs"></i>
                        Refresh
                    </a>
                </div>
            </div>
            <div
                class="
                    flex-vmiddle
                    common-border common-bg
                    mod-light-grey
                    padding-left padding-bottom-xs padding-top-xs
                "
            >
                <!-- header -->
                <div class="common-width-20">ID</div>
                <div class="flex-grow">Title</div>
                <div class="common-width-20">Last updated</div>
                <div class="common-width-20"></div>
            </div>
            <div class="import-review-box" ng-init="loadWebhookEntities()">
                <div class="text-center" ng-if="data.loadingWebhookEntities"><i class="loading-small"></i></div>
                <div
                    class="text-center common-size-xxxxs common-color-grey padding-normal"
                    ng-if="!data.loadingWebhookEntities && (!data.webhookEntities || !data.webhookEntities.length)"
                >
                    No data yet.
                </div>
                <div
                    class="list-item flex-vmiddle padding-left padding-bottom-xs padding-top-xs pointer"
                    ng-repeat="item in webhookEntities = (data.webhookEntities | orderBy:'-updated')"
                    ng-class="{ 'mod-selected': data.webhookDataLogItemId === item.id }"
                    ng-click="loadWebhookDataLogJson(item); data.webhookDataLogItemId = item.id"
                >
                    <span class="common-size-xxxxs common-width-20 margin-right common-ellipsis padding-normal-sm-v">
                        {{ item.id }}
                    </span>
                    <span class="flex-grow common-size-xxxxs padding-normal-sm-v">{{ item.name }}</span>
                    <span class="common-size-xxxxs common-width-20 padding-normal-sm-v">
                        {{ item.updated ? (item.updated | date: 'short') : '' }}
                    </span>
                    <span class="common-size-xxxxs common-width-20 text-right flex-no-shrink">View Json</span>
                </div>
            </div>
            <div ng-show="data.webhookDataLogJSON">
                <textarea
                    class="form-control common-resize-y-only margin-top-xs"
                    readonly="readonly"
                    rows="10"
                    prevent-scroll-bubbling
                    >{{ data.webhookDataLogJSON }}</textarea
                >
                <div class="common-color-grey margin-top">
                    <a
                        href="#"
                        class="common-link-no-color common-size-xxxxs"
                        ng-click="data.webhookDataLogJSON = null; data.webhookDataLogItemId = null;"
                    >
                        Close
                    </a>
                </div>
            </div>
        </div>

        <!-- outgoingConfiguration tab -->
        <div ng-if="data.selectedTab === 'outgoingConfiguration'">
            <div class="common-size-xxs rounded-box padding-normal-sm">
                <a
                    ng-href="{{::$root.knowledgeBase.pages.webhooks}}"
                    target="_blank"
                    class="pull-right common-size-xxxxs"
                >
                    See Documentation
                </a>
                <div class="common-bold"></div>
                <div>Define the outgoing configuration to use custom data source actions in your module.</div>
            </div>

            <div class="margin-top-md">
                <div class="common-title-xxs margin-bottom-xs">Base Url:</div>
                <input class="form-control" type="text" ng-model="data.baseUrl" />
            </div>

            <!-- Headers -->
            <div class="margin-top-md">
                <div class="flex-vmiddle common-title-xxs margin-bottom-xs">
                    <div>Authorization:</div>

                    <ui-select
                        class="common-width-33 margin-left"
                        theme="bootstrap"
                        ng-change="onAuthorizationTypeSelected(data.authorizationTypeSelected)"
                        ng-model="data.authorizationTypeSelected"
                    >
                        <ui-select-match placeholder="Authorization Type">
                            {{ data.authorizationTypeSelected }}
                        </ui-select-match>
                        <ui-select-choices
                            repeat="authorizationTypeOption in data.authorizationTypeOptions | filter:$select.search"
                            ng-click="stopPropagation($event)"
                        >
                            <div class="flex-vmiddle">
                                <span>{{ authorizationTypeOption }}</span>
                            </div>
                        </ui-select-choices>
                    </ui-select>
                </div>

                <div ng-if="data.authorizationTypeSelected !== 'No Authorization'">
                    <!-- Headers -->
                    <div
                        class="
                            common-bg
                            mod-light-grey
                            margin-top-md
                            flex
                            row
                            common-row-no-gutters common-border-dark-softer
                        "
                        ng-repeat="headerRow in data.headersArray"
                    >
                        <!-- Header name input -->
                        <div class="flex flex-col common-width-full">
                            <span class="padding-none margin-left-md margin-bottom-lg">
                                <div class="flex mod-justify-space margin-bottom">
                                    <span class="common-color-dark-grey margin-top-md">Header Name:</span>
                                    <!-- Remove button -->
                                    <div ng-if="data.headersArray.length > 0">
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-no-border"
                                            ng-click="removeHeader($index)"
                                        >
                                            <i class="svg-icon-xxs common-color-grey">
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </i>
                                        </button>
                                    </div>
                                </div>

                                <div class="margin-top-xxs margin-right-md">
                                    <input
                                        data-ng-disabled="headerRow.serverEncrypted"
                                        class="form-control"
                                        type="text"
                                        ng-model="headerRow.name"
                                    />
                                </div>
                            </span>

                            <!-- Header value input -->
                            <span class="margin-bottom-md common-border-top-dark-softer margin-right-md margin-left-md">
                                <span
                                    class="
                                        margin-top-md
                                        common-color-dark-grey
                                        margin-bottom
                                        flex-vmiddle
                                        mod-justify-space
                                    "
                                >
                                    <div>
                                        <span>Header Value:</span>
                                    </div>
                                </span>

                                <div class="margin-top-xxs">
                                    <input
                                        ng-change="httpHeaderValueChanged(headerRow)"
                                        data-ng-disabled="headerRow.serverEncrypted"
                                        class="form-control"
                                        type="text"
                                        ng-model="headerRow.value"
                                    />
                                </div>

                                <div class="flex-vmiddle margin-top">
                                    <div class="common-size-xxxxs common-color-dark-grey">Encrypt Value:</div>
                                    <tnk-toggle
                                        class="margin-left-xs"
                                        size="xs"
                                        is-active="headerRow.isEncrypted"
                                        uib-tooltip="{{ headerRow.isEncrypted ? 'ON' : 'OFF' }}"
                                        on-toggle-click="headerRow.isEncrypted = !headerRow.isEncrypted"
                                        toggle-disabled="headerRow.serverEncrypted"
                                    ></tnk-toggle>
                                    <a class="margin-left-md common-size-xxxxs" ng-click="clear(headerRow)">Clear</a>
                                </div>
                            </span>
                        </div>

                        <!-- Separator -->
                        <hr />
                    </div>

                    <!-- Add button -->
                    <div class="margin-top-xs">
                        <a ng-click="addHeader(data.headersArray)">+ Add header</a>
                    </div>
                </div>
            </div>
        </div>

        <div ng-if="data.selectedTab === 'security'">
            <!--   Setup Instructions    -->
            <div class="margin-bottom-lg info-box common-size-xxs">
                <p class="common-color-dark-grey common-bold">
                    Configure encrypted fields to censor a custom data source's sensitive data.
                    <br />
                    <br />
                    How to define encrypted fields:
                </p>
                <div class="common-color-dark-grey">
                    <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                        <li>
                            <div class="padding-left-xxs">
                                Start with sample data, use an existing one or send a sample webhook item.
                            </div>
                        </li>
                        <!-- Webhook URL (in edit mode) -->
                        <div ng-if="data.editMode" ng-init="data.inputWebhookUrl = data.incomingWebhook.url">
                            <div class="text-center rounded-box mod-blue-light padding-normal-sm margin-bottom-lg">
                                <div ng-show="data.showWebhookUrl && !data.showWebhookReceivedJSON">
                                    <div>
                                        <div class="common-size-xs common-bold">Webhook URL:</div>
                                        <div class="common-color-grey common-size-xxxxs margin-top-xs">
                                            Copy this url and call using HTTP POST with a JSON object or array of JSON
                                            objects.
                                        </div>
                                        <!-- Input (readonly in angular) with copy button -->
                                        <div class="input-group common-width-90-no-xs margin-auto-right-left">
                                            <input
                                                type="text"
                                                id="input-webhook-url-edit-mode-security"
                                                class="form-control inline-block text-center common-width-full"
                                                ng-model="data.inputWebhookUrl"
                                                ng-change="data.inputWebhookUrl = data.incomingWebhook.url"
                                            />
                                            <span class="input-group-btn">
                                                <button
                                                    type="button"
                                                    class="btn btn-default"
                                                    ng-click="copyUrlToClipboard('input-webhook-url-edit-mode-security')"
                                                >
                                                    Copy
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Open JSON data -->
                                <div
                                    class="margin-top common-size-xxxxs common-color-grey"
                                    ng-class="{ 'pull-right margin-none': !data.showWebhookUrl }"
                                >
                                    <a
                                        href="#"
                                        ng-click="data.showWebhookReceivedJSON = !data.showWebhookReceivedJSON;"
                                    >
                                        See the JSON we got
                                    </a>
                                    <!-- Refresh button -->
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-no-border common-color-dark-grey webhook-refresh-button"
                                        ng-click="refreshEditMode()"
                                        ng-disabled="data.loadingWebhookRefresh"
                                        uib-tooltip="Refresh JSON"
                                        tooltip-placement="bottom auto"
                                    >
                                        <i class="loading-small" ng-if="data.loadingWebhookRefresh"></i>
                                        <i class="fa fa-refresh" ng-if="!data.loadingWebhookRefresh"></i>
                                    </button>
                                </div>
                                <div
                                    ng-hide="data.showWebhookUrl"
                                    class="pointer text-left common-color-primary"
                                    data-automation="modal-template-show-webhook-url"
                                    ng-click="data.showWebhookUrl = true"
                                >
                                    Show Webhook URL to send data to
                                    <i class="fa fa-chevron-right margin-left"></i>
                                </div>
                            </div>
                        </div>

                        <li>
                            <div class="padding-left-xxs">Choose the fields you wish to encrypt below.</div>
                        </li>
                    </ul>
                </div>

                <p class="common-color-dark-grey common-bold">How to use encrypted fields:</p>
                <div class="common-color-dark-grey">
                    <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                        <li>
                            <div class="padding-left-xxs">
                                Removing an encrypted field from the list will only work for future incoming webhook
                                object.
                            </div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">
                                Revealing the encrypted field real value (decryption) will only work by using the
                                "decrypt" formula inside custom http actions.
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Encryption Key -->
            <div class="padding-left-lg padding-right-lg">
                <div class="common-title-xxs margin-right">
                    Encryption key:

                    <!-- Encryption key length -->
                    <span class="common-size-xxxxs common-color-grey3">
                        (
                        <span
                            ng-class="{
                                'common-error':
                                    data.incomingWebhook.definition.encryptionKeyDummy &&
                                    data.incomingWebhook.definition.encryptionKeyDummy.length < 32
                            }"
                        >
                            {{ data.incomingWebhook.definition.encryptionKeyDummy.length || 0 }}
                        </span>
                        /{{ data.encryptionKeyLength }})
                    </span>
                </div>
                <div class="common-size-xxs common-color-grey3">
                    An encryption key is typically a random string generated specifically to scramble and unscramble
                    data.
                    <br />
                    Note: This key needs to be {{ data.encryptionKeyLength }} chars long.
                </div>
                <div class="flex-vmiddle margin-top-xs">
                    <!-- Encryption key generator button -->
                    <button
                        ng-if="!data.encryptionKeyLocked"
                        class="btn btn-secondary margin-right-xxs"
                        ng-click="generateEncryptionKey()"
                        type="button"
                    >
                        Generate
                    </button>

                    <input
                        type="input"
                        class="form-control"
                        ng-model="data.incomingWebhook.definition.encryptionKeyDummy"
                        ng-disabled="data.encryptionKeyLocked"
                        maxlength="32"
                        placeholder="or insert manually"
                        pattern="^[a-zA-Z0-9.!@?#$%&:\x22';()*\+,\/;\-=[\\\]\^_{|}<>~` ]+$"
                    />
                </div>
                <div ng-if="data.encryptionKeyValidationError" class="common-error">
                    {{ data.encryptionKeyValidationError }}
                </div>
            </div>

            <hr />

            <!-- Encrypted field selection -->
            <div class="padding-left-lg padding-right-lg margin-top-md margin-bottom-lg">
                <!-- Title -->
                <div class="common-title-xxs margin-right">Fields to encrypt:</div>

                <div class="margin-top flex-grow">
                    <div
                        ng-repeat="item in data.incomingWebhook.definition.encryptedFieldsPaths"
                        class="margin-bottom-xs"
                    >
                        <div ng-if="!data.loadingWebhookRefresh" class="flex-vmiddle">
                            <tnk-field-selector
                                class="flex-grow margin-right-xs"
                                selected-field="item"
                                selected-field-identifier="$index"
                                items-source="EXTERNAL"
                                project-integration="data.projectIntegration"
                                external-type="data.projectIntegration.integration.integrationUniqueType"
                                on-field-selected="onEncryptedFieldSelected(selectedField, selectedFieldIdentifier)"
                                place-holder="Select field..."
                                reload-fields="data.reloadFieldSelectorOptions"
                                field-inspect-modal="true"
                                allow-clear-selection="true"
                            ></tnk-field-selector>
                            <div class="flex-vmiddle">
                                <!-- Add button -->
                                <div ng-if="$index === 0" uib-tooltip="Add field to encrypt">
                                    <div
                                        class="dropdown-toggle pointer btn btn-no-border"
                                        ng-click="addFieldToEncrypt()"
                                    >
                                        <i class="fa fa-plus-circle common-no-outline common-color-primary"></i>
                                    </div>
                                </div>
                                <!-- Remove button -->
                                <button
                                    ng-if="$index > 0"
                                    type="button"
                                    class="btn btn-no-border common-no-outline flex-no-shrink"
                                    ng-click="removeFieldToEncrypt($index)"
                                >
                                    <span class="svg-icon-xs common-color-grey">
                                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ng-if="data.editMode" ng-hide="data.selectedTab != 'variables'">
            <tnk-enterprise-component-variable
                enterprise-component-id="data.projectIntegration.id"
                related-entity-id="data.projectIntegration.id"
                on-save-ref="(data.saveEnterpriseComponentVariableRef)"
            ></tnk-enterprise-component-variable>
        </div>

        <!-- Buttons -->
        <div class="margin-bottom flex-vmiddle margin-top-xlg mod-justify-end">
            <!-- Loading -->
            <i class="loading-small margin-right flex-no-shrink" ng-if="data.loadingWebhook"></i>

            <!-- Cancel button (when no in edit mode) -->
            <button
                type="button"
                ng-if="!data.editMode"
                class="btn btn-default margin-right-xs flex-no-shrink"
                analytics-on
                analytics-category="Webhooks"
                analytics-event="Exit Window Incoming Webhook Create Mode"
                analytics-label="{{ data.incomingWebhook.displayName }}"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- Create button (appears if there's no integration yet) -->
            <button
                type="submit"
                ng-disabled="data.loadingWebhook"
                ng-if="!data.projectIntegration"
                class="btn btn-primary flex-no-shrink"
                data-automation="modal-create-custom-data-source"
                analytics-on
                analytics-category="Webhooks"
                analytics-event="Create Incoming Webhook"
                analytics-label="{{ data.incomingWebhook.displayName }}"
            >
                Create
            </button>

            <!-- Cancel button (only in edit mode) -->
            <button
                type="button"
                ng-if="data.editMode"
                class="btn btn-default margin-right-xs flex-no-shrink"
                ng-class="{ 'btn-primary': !data.editMode && !data.afterCreationMode && !data.webhookReceivedItems }"
                analytics-on
                analytics-category="Webhooks"
                analytics-event="Exit Window Incoming Webhook Edit Mode"
                analytics-label="{{ data.incomingWebhook.displayName }}"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- Save button (appears if there's an integration to save changes for) -->
            <button
                type="submit"
                ng-if="data.editMode && data.projectIntegration"
                class="btn btn-primary flex-no-shrink"
                data-automation="modal-template-save-button"
                analytics-on
                analytics-category="Webhooks"
                analytics-event="Edit Incoming Webhook"
                analytics-label="{{ data.incomingWebhook.displayName }}"
            >
                Save
            </button>
        </div>

        <!-- Error -->
        <div class="common-color-danger" ng-if="data.errorMessage">
            Error trying to create a new integration - {{ data.errorMessage }}.
        </div>
    </form>

    <!-- Loading edit mode -->
    <div class="flex-vmiddle mod-center margin-top-lg margin-bottom-lg" ng-if="data.loadingEditMode">
        <i class="loading-medium"></i>
        <span class="common-bold common-size-xs margin-left">Loading configuration...</span>
    </div>

    <!-- Deleting webhook -->
    <div class="flex-vmiddle mod-center margin-top-lg margin-bottom-lg" ng-if="data.loadingDeleteWebhook">
        <i class="loading-medium"></i>
        <span class="common-bold common-size-xs margin-left">Deleting configuration...</span>
    </div>
</div>
