import React from 'react';
import styled from 'styled-components';

import { PreviewFindWordsHighlightType } from '@tonkean/tonkean-entities';
import type { PreviewFindWordsSnippetLine } from '@tonkean/tonkean-entities';
import type { PreviewFindWordsSnippetLinePart } from '@tonkean/tonkean-entities';

const HighlightedText = styled.span<{ backgroundColor?: string }>`
    font-weight: 500;
    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const LinePartText = styled.span`
    overflow-wrap: break-word;
`;

function getTextHighlightBackground(linePart: PreviewFindWordsSnippetLinePart): string | undefined {
    const highlightTypes = linePart.highlightTypes;

    if (!highlightTypes) {
        return;
    }

    if (
        highlightTypes.includes(PreviewFindWordsHighlightType.BEFORE) &&
        highlightTypes.includes(PreviewFindWordsHighlightType.EXPECTED_VALUE)
    ) {
        return '#eeb5ee';
    } else if (
        highlightTypes.includes(PreviewFindWordsHighlightType.AFTER) &&
        highlightTypes.includes(PreviewFindWordsHighlightType.EXPECTED_VALUE)
    ) {
        return '#fcd6dc';
    } else if (highlightTypes.includes(PreviewFindWordsHighlightType.BEFORE) && highlightTypes.length === 1) {
        return '#d0d0ff';
    } else if (
        (highlightTypes.includes(PreviewFindWordsHighlightType.AFTER) && highlightTypes.length === 1) ||
        highlightTypes.includes(PreviewFindWordsHighlightType.NLP_POSITIVE_EXAMPLE)
    ) {
        return '#c6f7b7';
    } else if (
        (highlightTypes.includes(PreviewFindWordsHighlightType.EXPECTED_VALUE) && highlightTypes.length === 1) ||
        highlightTypes.includes(PreviewFindWordsHighlightType.NLP_NEGATIVE_EXAMPLE)
    ) {
        return '#ffb27a';
    } else if (highlightTypes.includes(PreviewFindWordsHighlightType.ARBITRARY_ANCHOR)) {
        return '#e8cfff';
    } else {
        return;
    }
}

interface Props {
    line: PreviewFindWordsSnippetLine;
}

const SingleSnippetLineComponent: React.FC<Props> = ({ line }) => {
    return (
        <>
            {line.snippetLineParts.map((linePart) => (
                <React.Fragment key={linePart.snippetLinePartIndex}>
                    {!linePart.highlightTypes?.length && <LinePartText>{linePart.text}</LinePartText>}
                    {linePart.highlightTypes?.length && (
                        <HighlightedText backgroundColor={getTextHighlightBackground(linePart)}>
                            {linePart.text}
                        </HighlightedText>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default SingleSnippetLineComponent;
