import { reactToAngular } from 'angulareact';

import TnkFormDatePickerInput from './tnkFormDatePickerInput';

angular
    .module('tonkean.app')
    .component(
        'tnkFormDatePickerInput',
        reactToAngular(TnkFormDatePickerInput, [
            'className',
            'children',
            'onChange',
            'selected',
            'isLoading',
            'disabled',
            'displayFormat',
            'fieldDefinitionId',
            'minDate',
        ]),
    );
