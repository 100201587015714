/**
 * Tonkean entity type enum.
 */
export const TONKEAN_ENTITY_TYPE = {
    PROJECT: 'PROJECT',
    ACTIVITY: 'ACTIVITY',
    INITIATIVE_LINK: 'INITIATIVE_LINK',
    FIELD_DEFINITION: 'FIELD_DEFINITION',
    FIELD: 'FIELD',
    INITIATIVE: 'INITIATIVE',
    GROUP: 'GROUP',
    PERSON: 'PERSON',
    PROJECT_INTEGRATION: 'PROJECT_INTEGRATION',
    CUSTOM_TRIGGER: 'CUSTOM_TRIGGER',
    WORKER_RUN: 'WORKER_RUN',
    WORKFLOW_VERSION: 'WORKFLOW_VERSION',
    SYNC_CONFIG: 'SYNC_CONFIG',
    USER_GROUP: 'USER_GROUP',
    SCIM_GROUP: 'SCIM_GROUP',
};
