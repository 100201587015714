<div class="padding-normal">
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-googledrive"></div>
            <h4 class="flex-grow">Set Up Google Drive</h4>
        </div>
    </div>
    <tnk-google-drive-selector
        project-integration="projectIntegration"
        integration="integration"
        on-project-integration-created="onGoogleDriveFolderSelected(projectIntegration)"
        on-cancel="onCancel()"
    ></tnk-google-drive-selector>
</div>
