import { useAngularService, useAngularWatch } from 'angulareact';
import { useCallback, useMemo } from 'react';

import type {
    SingleFieldChanged,
    SingleFieldConfiguration,
    SingleFieldConfigurationWithDefinition,
    WidgetFieldPackage,
} from '../CommonWidgetConfiguration';

import { type UpdateFieldsValuesManager, useGroupGlobalFields } from '@tonkean/infrastructure';
import { FieldDefinitionTargetType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, Initiative, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import utils from '@tonkean/utils';

function useItemFieldWidget(
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    specialFieldsForFeatureType: string,
    updateFieldsValuesManager: UpdateFieldsValuesManager,
    workflowVersion?: WorkflowVersion,
    initiative?: Initiative,
    allFieldDefinitions?: FieldDefinition<unknown>[],
    fields?: Record<FieldDefinitionKey, SingleFieldConfiguration>,
    selectedField?: SingleFieldConfigurationWithDefinition,
): [(changes: SingleFieldChanged[]) => Promise<void>, WidgetFieldPackage[]] {
    const trackHelper = useAngularService('trackHelper');
    const projectManager = useAngularService('projectManager');

    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const globalFields = useGroupGlobalFields({
        groupId: initiative?.group.id,
        workflowVersionType: workflowVersion?.workflowVersionType ?? WorkflowVersionType.DRAFT,
    });

    const configuredFields = useMemo<WidgetFieldPackage[]>(() => {
        if (!workflowVersion) {
            return [];
        }

        const allFieldDefinitionsAndSpecialFields = [
            ...getSpecialFieldsForFeatures(false, [specialFieldsForFeatureType]),
            ...(allFieldDefinitions || []),
        ];

        return allFieldDefinitionsAndSpecialFields
            ?.filter(
                (fieldDefinition) =>
                    Boolean(fields?.[fieldDefinition.id]) ||
                    (selectedField && selectedField.key === fieldDefinition.id),
            )
            .map((fieldDefinition) => {
                return {
                    groupId: workflowVersion.groupId,
                    fieldDefinition,
                    initiative,
                    workflowVersion,
                    environmentIsActive:
                        workflowVersion.workflowVersionType === WorkflowVersionType.PUBLISHED
                            ? !!groupsMap?.[workflowVersion?.groupId]?.workerEnabled
                            : !!groupsMap?.[workflowVersion?.groupId]?.buildEnvironmentEnabled,
                };
            });
    }, [
        workflowVersion,
        specialFieldsForFeatureType,
        allFieldDefinitions,
        fields,
        selectedField,
        initiative,
        groupsMap,
    ]);

    const onChange = useCallback(
        (changes: SingleFieldChanged[]) => {
            const initiativeIdToChanges = utils.groupBy(changes, (change) => change.package.initiative?.id || '');

            const promises = Object.entries(initiativeIdToChanges).map(([initiativeId, groupedChanges]) => {
                const globalFieldChanges: SingleFieldChanged[] = [];
                const initiativeFieldChanges: SingleFieldChanged[] = [];

                groupedChanges?.forEach((change) => {
                    if (change.package.fieldDefinition.targetType === FieldDefinitionTargetType.GLOBAL) {
                        globalFieldChanges.push(change);
                    } else {
                        initiativeFieldChanges.push(change);
                    }
                });

                const newInitiativeValuesEntries = initiativeFieldChanges.map((change) => [
                    change.package.fieldDefinition.id,
                    change.newValue,
                ]);

                const globalFieldPromises = globalFieldChanges.map((globalChange) => {
                    const globalField = globalFields[globalChange.package.fieldDefinition.id];
                    if (!globalField?.id) {
                        return Promise.resolve();
                    }

                    return updateFieldsValuesManager.updateGlobalField(globalField.id, globalChange.newValue);
                });

                return Promise.all([
                    updateFieldsValuesManager.updateInitiativeFields(
                        workflowVersionId,
                        initiativeId as TonkeanId<TonkeanType.INITIATIVE>,
                        Object.fromEntries(newInitiativeValuesEntries || []),
                    ),
                    ...globalFieldPromises,
                ]).then(([newInitiative]) => {
                    trackHelper.forceReloadInitiativeFromServer(newInitiative.id);
                });
            });
            return Promise.all(promises).then(() => {});
        },
        [globalFields, trackHelper, updateFieldsValuesManager, workflowVersionId],
    );

    return [onChange, configuredFields];
}

export default useItemFieldWidget;
