import FormulaTreeNodeBase from './FormulaTreeNodeBase';
import { FieldDefinitionType } from '../FieldDefinitionType';
import type FormulaField from '../FormulaField';
import { FormulaTreeNodeType } from '../FormulaTreeNodeType';
import type { ServerFormulaExpressionNode } from '../ServerFormulaExpressionNode';
import EmptyFieldValidationError from '../validationErrors/EmptyFieldValidationError';

export class FormulaTreeEmptyNode extends FormulaTreeNodeBase {
    public declare type: FormulaTreeNodeType.EMPTY;

    constructor(
        public override field: FormulaField,
        id?: number,
    ) {
        super(FormulaTreeNodeType.EMPTY, field, id);

        // If field is in array, its validation will be handled in the tree node.
        if (field.metadata.fieldDefinitionType === FieldDefinitionType.SINGLE) {
            this.setError(new EmptyFieldValidationError());
        }
    }

    public override toString() {
        return '';
    }

    public override toDisplayString() {
        return this.field.displayName;
    }

    public override getServerFormulaNode(): ServerFormulaExpressionNode | undefined {
        return undefined;
    }

    protected override getType() {
        return undefined;
    }

    public override clone(field: FormulaField) {
        return new FormulaTreeEmptyNode(field, this.id);
    }
}
