// Inspiration: https://github.com/angular-ui/ui-select/issues/258#issuecomment-193139572

/** *
 * This directive enables making ui-select required in a form. Simply using ng-required doesn't work with ui-select.
 * To use this directive simply add the ui-select-required directive to your ui-select to make it required.
 * It should also support a ui-select with multiselect thought it wasn't thoroughly checked.
 */
function uiSelectRequired() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attr, ctrl) {
            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
                // Check if the user has set a value to our attribute.
                if (attr.uiSelectRequired) {
                    const isRequired = scope.$eval(attr.uiSelectRequired);
                    if (isRequired === false) {
                        return true;
                    }
                }

                if (angular.isArray(modelValue)) {
                    // Treatment for multiselect - when the user can choose an array of options.
                    return modelValue.length > 0;
                } else if (angular.isArray(viewValue)) {
                    // Treatment for multiselect - when the user can choose an array of options.
                    return viewValue.length > 0;
                } else if ((modelValue && modelValue.name) || (viewValue && viewValue.name)) {
                    // Treatment for regular ui-select.
                    return true;
                } else if (
                    !scope.$root.utils.isNullOrNoProperties(modelValue) ||
                    !scope.$root.utils.isNullOrNoProperties(viewValue)
                ) {
                    // If any real properties are set, it should be allowed.
                    return true;
                }

                // If we got here, the ui-select is empty. Return false.
                return false;
            };
        },
    };
}
export default angular.module('tonkean.app').directive('uiSelectRequired', uiSelectRequired);
