import template from './tnkChannelOrPersonSelector.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkChannelOrPersonSelector', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        logicId: '<',
        savedChannelOrPersonSelection: '<',
        showReplyInFlowItemThread: '<', // Show option to reply on the root monitored item's thread
        showReplyInResumeThread: '<', // Show option to reply on the thread from previous block
        hideFailIfNoValue: '<', // When true, Fail if no value checkbox will be hidden.
        validationObject: '<',
        configuredLogic: '<',
        showSendTo: '<',

        doNotEvaluatePreview: '<', // When false, will evaluate the expression for preview.
        hidePreview: '<', // weather to show preview by default
        overrideExampleInitiative: '<', // an example initiative for the preview.
        previewEvaluationSource: '<',

        uniqueIdentifier: '@',

        onChannelOrPersonSelectionChanged: '&',
        onCommunicationProjectIntegrationChanged: '&',
        communicationProjectIntegrationSelector: '<', // Show communication project integration selector

        automationIdentifier: '@',
    },
    template,
    controller: 'ChannelOrPersonSelectorCtrl',
});
