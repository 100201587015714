import WorkfrontAuthenticationComponent from './WorkfrontAuthenticationComponent';
import workfrontCircleIcon from '../../../../apps/tracks/images/integrations/workfront-circle.png';
import workfrontIcon from '../../../../apps/tracks/images/workfront.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class WorkfrontIntegration extends IntegrationDefinitionBase {
    override name = 'workfront';
    override displayName = 'workfront';
    override description = 'We will only collect projects and tasks and users items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Portfolio', 'Portfolios'),
        new IntegrationEntity('Project', 'Projects'),
        new IntegrationEntity('Task', 'Tasks'),
        new IntegrationEntity('User', 'Users'),
    ];
    override customizedAuthenticationComponent = WorkfrontAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(workfrontCircleIcon, '130px'),
        new IntegrationIcon(workfrontIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Portfolio: true, Project: true, Task: true, User: true },
        isOnlyDataRetention: {},
    };
}

export default WorkfrontIntegration;
