import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import type { FormikValues } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import {
    Field,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    SimpleSelect,
} from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { ConnectionPermission, IntegrationType } from '@tonkean/tonkean-entities';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { yupEnum } from '@tonkean/utils';

interface Props {
    isOpen: boolean;
    onDismiss(): void;
    onCreate(dataSourceConnection: DataSourceConnection): void;
    existingDataSourceConnectionTypes?: IntegrationType[];
}

const CreateDataSourceConnectionModal: React.FC<Props> = ({
    onDismiss,
    onCreate,
    isOpen,
    existingDataSourceConnectionTypes,
}) => {
    const integrations = useAngularService('integrations');
    const authenticationService = useAngularService('authenticationService');
    const [, createDataSourceConnection] = useLazyTonkeanService('createDataSourceConnection');
    const project = useProject();

    const [integrationType, setIntegrationType] = useState<IntegrationType>();

    const integrationTypes = integrations
        .getIntegrationsConfigList()
        .filter((integration: any) =>
            integration.name
                ? !existingDataSourceConnectionTypes?.includes(IntegrationType[integration.name.toUpperCase()])
                : false,
        )
        .map((integration) => ({
            value: integration.name,
            label: integration.displayName,
            icon: <ProjectIntegrationIcon integrationType={integration.name} />,
        }));

    // Schema validation for formik.
    const createDataSourceConnectionSchema = useMemo(() => {
        return Yup.object({
            integrationType: yupEnum(IntegrationType, 'invalid integration').required('Choose integration type'),
        }).required();
    }, []);

    // Formik submit callback.
    const onSubmit = async (values: FormikValues) => {
        const dataSourceConnectionId = (await createDataSourceConnection(project.id, values.integrationType, false)).id;

        const createdDataSourceConnection: DataSourceConnection = {
            id: dataSourceConnectionId,
            dataSourceType: values.integrationType,
            projectId: project.id,
            sharedCredentials: [],
            allowOnlySharedCredentials: false,
            enabled: false,
            creator: authenticationService.getCurrentUser()?.id,
            updated: Date.now(),
            created: Date.now(),
            authorizedEntitiesIds: [],
            connectionPermission: ConnectionPermission.ALL_MEMBERS,
        };

        onCreate(createdDataSourceConnection);
    };

    return (
        <Formik validationSchema={createDataSourceConnectionSchema} initialValues={{}} onSubmit={onSubmit}>
            {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
                <Modal size={ModalSize.SMALL} onClose={onDismiss} open={isOpen} fixedWidth>
                    <ModalHeader>Select Data Source Type</ModalHeader>

                    <ModalForm>
                        <ModalBody>
                            <Field
                                error={
                                    errors.integrationType && touched.integrationType
                                        ? (errors.integrationType as string)
                                        : undefined
                                }
                                label="Data source type:"
                            >
                                <SimpleSelect
                                    placeholder="Search data source type..."
                                    options={integrationTypes}
                                    name="integrationType"
                                    onChange={setIntegrationType}
                                />
                            </Field>
                        </ModalBody>

                        <ModalFooterActions saveDisabled={!integrationType} />
                    </ModalForm>
                </Modal>
            )}
        </Formik>
    );
};

export default CreateDataSourceConnectionModal;
