import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import SolutionManagingModalHeader from './SolutionManagingModalHeader';
import { ReactComponent as AvatarIcon } from '../../../../images/icons/avatar.svg';

import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import {
    CloseButton,
    ErrorMessage,
    LoadingCircle,
    ModalBody,
    ModalFooter,
    useCloseCallback,
} from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';

const SaveButton = styled(Button)`
    margin-left: 8px;
`;

const MakersModalBody = styled(ModalBody)`
    overflow: visible;
`;

const MakersErrorBox = styled(ErrorMessage)`
    margin-top: 10px;
`;

const Notice = styled.p`
    margin: 20px 0 0;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

interface Props {
    workflowFolder: WorkflowFolder;
    initiallyFocusedRef: React.Ref<HTMLButtonElement>;
}

const ManageMakersModalContent: React.FC<Props> = ({ workflowFolder, initiallyFocusedRef }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const [owners] = useAngularWatch(() => workflowFolder.owners);

    // Create a copy for the makers so it wouldn't change the angular UI while we edit
    const makersCopy = useMemo(() => owners.filter((person) => !person.systemUtilized), [owners]);

    const onClose = useCloseCallback();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();

    const onSave = useCallback(() => {
        setLoading(true);
        if (makersCopy.length == 0) {
            setError('At least one solution maker is required.');
            setLoading(false);
        } else {
            const supportUser = workflowFolder.owners.filter((person) => person.systemUtilized);
            workflowFolderManager
                .setWorkflowFolderMakers(workflowFolder.project.id, workflowFolder.id, makersCopy.concat(supportUser))
                .then(() => onClose())
                .catch((error) => {
                    setError(error.data.error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [
        makersCopy,
        onClose,
        workflowFolder.id,
        workflowFolder.owners,
        workflowFolder.project.id,
        workflowFolderManager,
    ]);

    return (
        <>
            <MakersModalBody>
                <SolutionManagingModalHeader workflowFolder={workflowFolder} icon={<AvatarIcon />}>
                    Manage Solution Makers
                </SolutionManagingModalHeader>

                <DeprecatedPeopleSelector
                    selectedPeople={makersCopy}
                    disabled={false}
                    onTagAdded={($tag) => console.log(`tag added ${$tag}`)}
                    onTagRemoved={($tag) => console.log(`tag removed ${$tag}`)}
                    noCustom
                    doNotSearchOnlyOwnersInGroup
                />
                {error && <MakersErrorBox>{error}</MakersErrorBox>}

                {/* {hasPrivateGroups && (*/}
                <Notice>
                    Notice for private modules: Adding a maker to the solution will add the maker as a member of the
                    private module. Removing maker will not remove the maker as a member of the private module.
                </Notice>
                {/* )}*/}
            </MakersModalBody>
            <ModalFooter align="right">
                {loading && <LoadingCircle className="margin-right-xs" />}
                <CloseButton
                    disabled={loading}
                    onClick={() => onClose()}
                    ref={initiallyFocusedRef}
                    data-automation="manage-makers-modal-content-cancel-button"
                    outlined
                >
                    Cancel
                </CloseButton>

                <SaveButton
                    onClick={onSave}
                    disabled={loading}
                    data-automation="manage-makers-modal-content-save-button"
                >
                    Save
                </SaveButton>
            </ModalFooter>
        </>
    );
};
export default ManageMakersModalContent;
