import {lateConstructController} from '@tonkean/angular-components';
import {WorkflowVersionType} from '@tonkean/tonkean-entities';

/* @ngInject */
function CreateTrackActionDefinitionCtrl(
    $scope,
    $q,
    $state,
    utils,
    tonkeanUtils,
    trackHelper,
    projectManager,
    customFieldsManager,
    groupManager,
    workflowVersionManager,
    workflowFolderManager,
    modalUtils,
    tonkeanService,
    $rootScope,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.cfm = customFieldsManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        workflowVersionType: ctrl.workflowVersionType,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        groupOptionIds: ctrl.groupOptionIds,

        editParentOpen: false, // Is the edit parent in edit mode
        editParentLoading: false,
        parent: null,

        fieldsToUpdate: [{}],
        existingCreatorPersonSelectionConfiguration: {},

        editGroupLoading: false,
        selectedGroup:
            ctrl.definition && ctrl.definition.groupId ? projectManager.groupsMap[ctrl.definition.groupId] : null,
        loadingGroupOptions: false,
        enableGroupOptionsChange: true,
        enableContractOptionsChange: true,
        groupOptions: [],

        fields: [
            {
                key: 'title',
                label: 'Title',
                isRequired: true,
            },
            {
                key: 'description',
                label: 'Description',
            },
            {
                key: 'dueDate',
                label: 'Due Date',
            },
        ],
        workflowFolder: {},
        tags: [],
        contractSummaries: [],
        loadingContractSummaries: false,
        isModuleContractsFeatureFlagOn: $rootScope.features.currentProject.tonkean_feature_enable_module_contract,
    };

    ctrl.$onInit = function () {
        $scope.data.workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
            projectManager.project.id,
            $scope.data.groupId,
        );

        if ($scope.data.isModuleContractsFeatureFlagOn) {
            $scope.data.loadingContractSummaries = true;
            tonkeanService.getContractSummaries($scope.data.groupId)
                .then((response) => {
                    $scope.data.contractSummaries = response.entities;
                })
                .finally(() => {
                    $scope.data.loadingContractSummaries = false;
                })
        }

        setGroupOptions();

        if ($scope.data.definition.groupId) {
            $scope.data.selectedGroup = projectManager.groupsMap[$scope.data.definition.groupId];
        }

        loadRelatedMatchedItemFields();
        setEnableContractOptionsChange();
        loadParent($scope.data.definition.parentId);

        initializeEditMode();

        $scope.data.canUserCreateModuleInSolution =
            projectManager.isOwner ||
            workflowFolderManager.projectIdToFolderIdToFolderMap[projectId]?.[$scope.data.workflowFolder.id]
                ?.isUserOwner;
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    function setGroupOptions() {
        $scope.data.groupOptions = $scope.data.groupOptionIds
            .map((groupId) => {
                return projectManager.groupsMap[groupId];
            })
            .filter((group) => group && !group.isExample && group.id !== $scope.data.groupId)
            .filter((group) => isMatchingContract(group));
    }

    function isMatchingContract(group) {
        if (!$scope.data.isModuleContractsFeatureFlagOn || !$scope.data.workflowVersionType || !$scope.data.definition.contractId) {
            return true;
        }

        if ($scope.data.workflowVersionType === WorkflowVersionType.DRAFT) {
            return group.draftContractId === $scope.data.definition.contractId;
        }

        if ($scope.data.workflowVersionType === WorkflowVersionType.PUBLISHED) {
            return group.publishedContractId === $scope.data.definition.contractId;
        }
    }

    function loadRelatedMatchedItemFields() {
        const fieldsRelatedToMatchedItemField = $scope.cfm.selectedColumnFieldsMap[
            $scope.data.workflowVersionId
            ]?.filter(
            (fieldDefinition) =>
                fieldDefinition.definition?.matchConfiguration?.creatingCustomTriggerId ===
                $scope.data.configuredLogic.node.id && (!fieldDefinition?.linkedCustomTrigger),
        );

        if (fieldsRelatedToMatchedItemField.length > 0) {
            $scope.data.enableGroupOptionsChange = false;
        }
    }

    function setEnableContractOptionsChange() {
        const fieldsRelatedToMatchedItemField = $scope.cfm.selectedColumnFieldsMap[
            $scope.data.workflowVersionId
            ]?.filter(
            (fieldDefinition) =>
              fieldDefinition?.linkedCustomTrigger?.id === $scope.data.configuredLogic.node.id
        );

        if (fieldsRelatedToMatchedItemField.length > 0) {
            $scope.data.enableContractOptionsChange = false;
        }
    }

    $scope.onFieldExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic, fieldKey) {
        $scope.data.definition[fieldKey] = originalExpression;
        $scope.data.definition[`evaluated${utils.capitalize(fieldKey)}`] = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onContractSelectedChange = function (contractId) {
        $scope.data.definition.contractId = contractId;
        $scope.data.definition.contractFieldToExpression = new Map();
        $scope.clearSelectedGroup()
        setGroupOptions();
        $scope.onDefinitionChanged(true);

    }

    $scope.onContractFieldToExpressionChange = function (contractFieldToExpression) {
        $scope.data.definition.contractFieldToExpression = contractFieldToExpression
        $scope.onDefinitionChanged(true);
    }

    /**
     * Occurs on changes in configured fields.
     */
    $scope.onConfiguredFieldsChanged = function (configuredFields, shouldSaveLogic) {
        $scope.data.fieldsToUpdate = configuredFields;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onGroupSelected = function () {
        $scope.data.definition.groupId = $scope.data.selectedGroup ? $scope.data.selectedGroup.id : undefined;

        // Reset target parent because its in different group
        $scope.data.definition.parentId = null;
        $scope.data.editParentOpen = false;
        $scope.data.editParentLoading = false;
        $scope.data.parent = null;
        $scope.onConfiguredFieldsChanged([{}], true);

        $scope.onDefinitionChanged(true);
    };

    $scope.clearSelectedGroup = function () {
        $scope.data.selectedGroup = undefined;
        $scope.onGroupSelected();
    };

    $scope.onTagExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic, tag) {
        tag.value = originalExpression;
        tag.evaluatedValue = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Choose who will be the updater of the field
     */
    $scope.onCreatorPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.creatorPersonSelectionConfiguration = personSelectionConfiguration;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addEmptyTag = function () {
        $scope.data.tags.push({});
    };

    $scope.createNewModuleLink = function () {
        const solutionId = $scope.data.workflowFolder.id;

        if (!$scope.data.canUserCreateModuleInSolution) {
            return Promise.resolve();
        }

        const workflowFolderCategoryId = projectManager.groupsMap[$scope.data.groupId].workflowFolderCategoryId;

        return modalUtils
            .openChooseWorkerTypeModal(solutionId, workflowFolderCategoryId)
            .then((group) =>
                $state.go('product.workerEditor', {
                    g: group.id,
                    fromStateParams: $state.params,
                    fromState: $state.current.name,
                    fromName: projectManager.groupsMap[$scope.data.groupId]?.name || 'module editor',
                }),
            )
            .catch(() => {
            });
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        $scope.data.definition.tags = $scope.data.tags.filter((tag) => tag.evaluatedValue);
        $scope.data.definition.fieldsToUpdate = $scope.data.fieldsToUpdate;
        $scope.data.definition.onBehalfOfPersonDefinition = tonkeanUtils.buildPersonSelectionConfiguration(
            $scope.data.creatorPersonSelectionConfiguration,
        );

        ctrl.onDefinitionChanged({shouldSaveLogic});
    };

    function initializeEditMode() {
        if ($scope.data.definition.tags && $scope.data.definition.tags.length) {
            $scope.data.tags = $scope.data.definition.tags;
        } else {
            $scope.data.tags = [];
            $scope.addEmptyTag();
        }

        $scope.data.fieldsToUpdate = $scope.data.definition.fieldsToUpdate || [{}];

        $scope.data.existingCreatorPersonSelectionConfiguration =
            $scope.data.definition.onBehalfOfPersonDefinition || {};
    }

    function loadParent(parentId) {
        if (!parentId) {
            $scope.data.parent = null;
            return $q.resolve();
        }

        return trackHelper
            .getInitiativeById(parentId)
            .then((parent) => {
                $scope.data.parent = parent;
            })
            .catch(() => {
                $scope.data.parent = null;
                $scope.data.editParentOpen = true;
            })
            .finally(() => {
                $scope.data.editParentLoading = false;
            });
    }
}

export default angular
    .module('tonkean.app')
    .controller('CreateTrackActionDefinitionCtrl', lateConstructController(CreateTrackActionDefinitionCtrl));
