import { useAngularService } from 'angulareact';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { ItemInterfaceSequenceBody } from './components/ItemInterfaceSequenceBody';
import useActiveValidationsErrors from './hooks/useActiveValidationsErrors';
import useBackNavigation from './hooks/useBackNavigation';
import useFetchSequenceStepData from './hooks/useFetchSequenceStepData';
import useNextNavigation from './hooks/useNextNavigation';
import useSetThankYouPageData from './hooks/useSetThankYouPageData';
import useUpdateStepWithNextInitiative from './hooks/useUpdateStepWithNextInitiative';
import IntakeWaitForConditinsPage from './IntakeWaitForConditinsPage';
import {
    ItemInterfaceSequenceHeaderTitles,
    ItemInterfaceSequenceHeaderWrapper,
} from './ItemInterfaceSequenceSharedComponents';
import GeneralInterfaceWrapper from '../../../components/GeneralInterfaceWrapper';
import InterfaceButton from '../../../components/InterfaceButton';
import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import ItemInterfaceScrollContextProvider from '../../../SystemInterface/components/ItemInterface/ItemInterfaceScrollContextProvider';
import useActivateOnLoadActions from '../../../SystemInterface/hooks/useActivateOnLoadActions';
import InterfaceSubmitValidationContext, {
    useBuildInterfaceSubmitValidationContext,
} from '../../../utils/InterfaceSubmitValidationContext';
import useUpdateWidgetsToValidate from '../../../widgets/hooks/useUpdateWidgetsToValidate';
import IntakeThankYouPage from '../IntakeThankYouPage/IntakeThankYouPage';

import {
    Breakpoint,
    FieldError,
    LoadingCircle,
    NotFoundPage,
    useIsDataRestricted,
    useItemInterfaceContext,
    useUserPermissionsForInitiativeInGroup,
} from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import {
    type FetchResults,
    type FullProject,
    type Initiative,
    InterfaceQuickNavigationMode,
    type ItemInterface,
    ItemInterfaceLoadingState,
    ItemInterfaceThemeTextAlignment,
    type ProjectThemeConfiguration,
    type SequenceState,
    type TonkeanId,
    type TonkeanType,
    useItemInterfaceHeaderThemeConfiguration,
    Validation,
    type WidgetBase,
    type WidgetConfiguration,
    type WidgetParentTypes,
    type WorkflowVersion,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { ButtonStyle } from '@tonkean/tui-theme/styles';
import { EMPTY_ARRAY } from '@tonkean/utils';

function Loading() {
    return (
        <div className="common-height-full flex mod-align-center mod-center mod-justify-center">
            <i className="loading-large" />
        </div>
    );
}

// Important - this class list is from the tnkPeopleSelector.template.html component <tag-input/> angular component
// It is enabling scroll inside this component
const CLASS_NAMES_TO_ENABLE_SCROLLING = ['dropdown', 'suggestion-list'];

const InterfaceWrapper = styled.div`
    border-radius: 16px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        border-radius: 16px 16px 0px 0px;
        padding: 8px;
        box-shadow: none;
    }
`;

const ActionsFooter = styled.div<{ showBackButton?: boolean }>`
    position: relative;
    display: flex;
    justify-content: ${({ showBackButton }) => (showBackButton ? 'space-between' : 'flex-end')};
    align-items: center;
    flex: 1;
    gap: 10px;
    background-color: ${Theme.colors.basicBackground};
    padding: 16px 32px;
    border-radius: 0 0 16px 16px;
`;

const SubmitButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledFieldError = styled(FieldError)`
    margin-top: 0;
`;

interface Props {
    onNextClicked?: (data: any) => void;
    onBackClicked?: () => void;
    onSwitchedToNextInterfaceInternally?: (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void;
    onSwitchedToPrevInterfaceInternally?: (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void;
    itemInterface: ItemInterface;
    workflowVersion: WorkflowVersion;
    project: FullProject;
    widgets: FetchResults<WidgetBase<WidgetConfiguration, WidgetParentTypes>>;
    showBackButton?: boolean;
    nextWorkerRunId?: TonkeanId<TonkeanType.WORKER_RUN>;
    formModalRef?: HTMLDivElement | null;
    buttonColor?: string;
    setCurrentSequenceState: (updatedSequenceState: SequenceState) => void;
}

const ItemInterfaceInSequence: React.FC<Props> = ({
    workflowVersion,
    itemInterface,
    project,
    widgets,
    onNextClicked,
    onBackClicked,
    onSwitchedToNextInterfaceInternally,
    onSwitchedToPrevInterfaceInternally,
    showBackButton,
    nextWorkerRunId,
    formModalRef,
    buttonColor,
}) => {
    const projectManager = useAngularService('projectManager');
    const projectThemeConfiguration: ProjectThemeConfiguration = projectManager.project.themeConfiguration;

    const [scrollingObjectStarted, setScrollingObjectStarted] = useState<Event | undefined>();
    const lastKnownScrollPosition = useRef<number>(0);
    const scheduledAnimationFrame = useRef<boolean>(false);
    const [interfaceLoadingActive, setInterfaceLoadingActive] = useState<boolean>(true);
    const [submitLoadingActive, setSubmitLoadingActive] = useState<boolean>(false);
    const [backLoadingActive, setbackLoadingActive] = useState<boolean>(false);

    const {
        initiative,
        widgetsToDisplay,
        itemInterfaceContextDataError,
        updateFieldsValuesManager,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        intakeWorkflowVersion,
        intakeInitiative,
        currentSequenceState,
        setCurrentSequenceState,
        tabs,
    } = useItemInterfaceContext();

    const initiativeData = initiative as Initiative;

    const [showWaitForConditionsToMatch, setShowWaitForConditionsToMatch] = useState<boolean>(false);

    const [previousSequenceState, setPreviousSequenceState] = useState<SequenceState[]>([]);

    const [showThankYouPage, setShowThankYouPage] = useState<boolean>(false);

    const [isWaitingForNextInitiative, setIsWaitingForNextInitiative] = useState<boolean>(false);

    const elementToScrollTo = formModalRef || window;
    const scrollToTop = useCallback(() => {
        elementToScrollTo?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [elementToScrollTo]);

    useLayoutEffect(() => {
        scrollToTop();
        setbackLoadingActive(false);
        setInterfaceLoadingActive(false);
    }, [scrollToTop]);

    const scrollHandler = useCallback(
        (e: Event) => {
            const isScrollInDropdown: number = CLASS_NAMES_TO_ENABLE_SCROLLING.findIndex((className) =>
                e?.target?.['className']?.includes(className),
            );
            if (isScrollInDropdown > -1) {
                return;
            }
            lastKnownScrollPosition.current = window.scrollY;
            if (!scheduledAnimationFrame.current) {
                window.requestAnimationFrame(function () {
                    setScrollingObjectStarted(e);
                    scheduledAnimationFrame.current = false;
                });

                scheduledAnimationFrame.current = true;
            }
        },
        [setScrollingObjectStarted, lastKnownScrollPosition],
    );

    useEffect(() => {
        elementToScrollTo?.addEventListener('scroll', scrollHandler, true);

        return () => {
            elementToScrollTo?.removeEventListener('scroll', scrollHandler, true);
        };
    }, [elementToScrollTo, scrollHandler]);

    useActivateOnLoadActions(initiative?.id, itemInterface, reloadInitiativeFromServer, applyFastReloadInterval);

    const {
        handleNextInterface,
        getStepItemInterfaceById,
        getStepInterfaceWidgets,
        fetchSequenceStepLoading,
        fetchSequenceStepError,
    } = useFetchSequenceStepData(
        workflowVersion.id,
        intakeWorkflowVersion!.id,
        initiativeData.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
        intakeInitiative!.id,
        nextWorkerRunId,
    );

    const updateSequenceStep = useCallback(
        (
            stepItemInterface: ItemInterface,
            stepCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
            stepWidgets: WidgetBase<WidgetConfiguration, WidgetParentTypes>[] | undefined,
            isStepLastInSequence: boolean,
            nextInSequence: boolean,
            stepInitiative?: Initiative,
            stepWorkflowVersion?: WorkflowVersion,
            evaluatedIntakeSequenceButtonLabel?: string,
        ) => {
            if (nextInSequence && currentSequenceState !== undefined) {
                setPreviousSequenceState([...previousSequenceState, currentSequenceState]);
            }

            setCurrentSequenceState?.({
                itemInterface: stepItemInterface,
                customTriggerId: stepCustomTriggerId,
                widgets: stepWidgets,
                isLastInSequence: isStepLastInSequence,
                initiative: stepInitiative || intakeInitiative,
                workflowVersion: stepWorkflowVersion || intakeWorkflowVersion,
                evaluatedIntakeSequenceButtonLabel,
            });
        },
        [
            setPreviousSequenceState,
            previousSequenceState,
            setCurrentSequenceState,
            currentSequenceState,
            intakeWorkflowVersion,
            intakeInitiative,
        ],
    );

    const { onNextClickedHandle, actionDefinition, sequenceSubmissionResponse } = useNextNavigation(
        initiative as Initiative,
        intakeInitiative!.id,
        intakeInitiative!.group.id,
        scrollToTop,
        onSwitchedToNextInterfaceInternally,
        onNextClicked,
        currentSequenceState!,
        updateSequenceStep,
        handleNextInterface,
        getStepInterfaceWidgets,
        getStepItemInterfaceById,
        setSubmitLoadingActive,
        setShowThankYouPage,
        setShowWaitForConditionsToMatch,
        setIsWaitingForNextInitiative,
    );

    const onBackClickedHandle = useBackNavigation(
        previousSequenceState,
        scrollToTop,
        updateSequenceStep,
        getStepInterfaceWidgets,
        setbackLoadingActive,
        backLoadingActive,
        onBackClicked,
        onSwitchedToPrevInterfaceInternally,
    );

    useUpdateStepWithNextInitiative(
        isWaitingForNextInitiative,
        setIsWaitingForNextInitiative,
        project.id,
        intakeInitiative!.id,
        intakeInitiative!.group.id,
        workflowVersion.workflowVersionType!,
        actionDefinition,
        sequenceSubmissionResponse,
        setSubmitLoadingActive,
        setCurrentSequenceState,
    );

    const thankYouPageData = useSetThankYouPageData(actionDefinition, sequenceSubmissionResponse, initiativeData.title);

    const { canUserEditItem, canUserViewItem } = useUserPermissionsForInitiativeInGroup(
        initiative?.isArchived,
        initiative?.isDraftInitiative,
        itemInterface?.group,
    );

    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(currentSequenceState!.itemInterface, project);
    const showHeader = !!(
        themeConfiguration.title?.originalExpression.trim() || themeConfiguration.subtitle?.originalExpression.trim()
    );

    const interfaceSubmitValidationContext = useBuildInterfaceSubmitValidationContext();
    const setWidgetsIdsToValidate = interfaceSubmitValidationContext.setWidgetsIdsToValidate;

    useUpdateWidgetsToValidate(setWidgetsIdsToValidate, widgetsToDisplay);

    const activeValidationErrors = useActiveValidationsErrors(interfaceSubmitValidationContext);

    const submitButtonDisabled = useMemo(() => {
        return (
            submitLoadingActive ||
            activeValidationErrors ||
            backLoadingActive ||
            updateFieldsValuesManager.inActiveFieldsValuesUpdate ||
            fetchSequenceStepLoading
        );
    }, [
        activeValidationErrors,
        backLoadingActive,
        updateFieldsValuesManager.inActiveFieldsValuesUpdate,
        submitLoadingActive,
        fetchSequenceStepLoading,
    ]);

    const showNotFoundPage = useIsDataRestricted([...fetchSequenceStepError, itemInterfaceContextDataError?.status]);

    // Disabled tabs view in sequence due to bugs with validation.
    const tabsView = false;

    if (interfaceLoadingActive) {
        return <LoadingTonkean />;
    }

    if (!canUserViewItem || showNotFoundPage) {
        return (
            <React.Suspense fallback={<Loading />}>
                <NotFoundPage text="Oops, we can't find that page" dataAutomation="interface-not-found" />
            </React.Suspense>
        );
    }

    const permission = canUserEditItem
        ? ItemInterfacePermission.USER_CAN_EDIT_ITEM
        : ItemInterfacePermission.USER_CANT_EDIT_ITEM;

    const themeColorToDisplay = buttonColor ? buttonColor : projectThemeConfiguration.primaryColor;
    const buttonLabel = currentSequenceState?.evaluatedIntakeSequenceButtonLabel
        ? currentSequenceState.evaluatedIntakeSequenceButtonLabel
        : currentSequenceState!.isLastInSequence
          ? 'Finish'
          : 'Next';

    const isBackButtonDisplayed = showBackButton || previousSequenceState?.length > 0;
    return (
        <motion.div
            key={currentSequenceState!.itemInterface.id}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.8,
                delay: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
            }}
        >
            <InterfaceSubmitValidationContext.Provider value={interfaceSubmitValidationContext}>
                <InterfaceWrapper>
                    {!showThankYouPage && !showWaitForConditionsToMatch && (
                        <ItemInterfaceScrollContextProvider scrollingEventObject={scrollingObjectStarted}>
                            <GeneralInterfaceWrapper
                                quickNavigationMode={InterfaceQuickNavigationMode.STANDARD} // Tabs mode is blocked in sequence interface
                                tabs={tabs}
                            >
                                {showHeader && (
                                    <ItemInterfaceSequenceHeaderWrapper
                                        data-automation="item-interface-view-header"
                                        $tabsView={tabsView}
                                    >
                                        <ItemInterfaceSequenceHeaderTitles
                                            initiative={initiative}
                                            widgets={widgets.entities}
                                            textAlignment={ItemInterfaceThemeTextAlignment.LEFT}
                                            textColor={Theme.colors.gray_800}
                                            tabsView={tabsView}
                                            isBold
                                        />
                                    </ItemInterfaceSequenceHeaderWrapper>
                                )}
                                <ItemInterfaceSequenceBody
                                    widgets={widgetsToDisplay ?? EMPTY_ARRAY}
                                    initiative={initiative}
                                    workflowVersion={workflowVersion}
                                    permission={permission}
                                    showPoweredByTonkean={false}
                                    itemInterfaceWidgetsState={ItemInterfaceLoadingState.IDLE}
                                    showHeader={showHeader}
                                />
                                <ActionsFooter showBackButton={isBackButtonDisplayed}>
                                    {isBackButtonDisplayed && (
                                        <InterfaceButton
                                            onClick={() => {
                                                interfaceSubmitValidationContext.setForceShowValidation(false);

                                                return onBackClickedHandle();
                                            }}
                                            disabled={backLoadingActive || submitLoadingActive}
                                            dataAutomation="sequence-back-button"
                                            buttonColor={buttonColor}
                                            invertButtonColors
                                        >
                                            Back
                                            {backLoadingActive && <LoadingCircle />}
                                        </InterfaceButton>
                                    )}

                                    <SubmitButtonWrapper>
                                        {activeValidationErrors && (
                                            <StyledFieldError
                                                $textAlign="right"
                                                data-automation="item-interface-in-sequence-styled-field-error"
                                                $inlineError
                                            >
                                                One or more fields are not valid.
                                            </StyledFieldError>
                                        )}

                                        <InterfaceButton
                                            dataAutomation="sequence-next-button"
                                            onClick={() => {
                                                const isValid =
                                                    interfaceSubmitValidationContext.validation !== Validation.INVALID;
                                                if (isValid) {
                                                    interfaceSubmitValidationContext.setForceShowValidation(false);

                                                    return onNextClickedHandle();
                                                } else {
                                                    interfaceSubmitValidationContext.setForceShowValidation(true);
                                                }
                                            }}
                                            disabled={submitButtonDisabled}
                                            buttonColor={buttonColor}
                                        >
                                            {(submitLoadingActive ||
                                                updateFieldsValuesManager.inActiveFieldsValuesUpdate) && (
                                                <LoadingCircle
                                                    color={
                                                        submitButtonDisabled
                                                            ? Theme.current.palette.TUI.button[ButtonStyle.FILLED]
                                                                  .textDisabledColor
                                                            : undefined
                                                    }
                                                />
                                            )}
                                            {buttonLabel}
                                        </InterfaceButton>
                                    </SubmitButtonWrapper>
                                </ActionsFooter>
                            </GeneralInterfaceWrapper>
                        </ItemInterfaceScrollContextProvider>
                    )}
                    {showThankYouPage && (
                        <IntakeThankYouPage thankYouPageData={thankYouPageData} color={themeColorToDisplay} />
                    )}
                    {showWaitForConditionsToMatch && (
                        <IntakeWaitForConditinsPage
                            evaluatedWaitConditionsMessage={sequenceSubmissionResponse?.evaluatedWaitConditionsMessage}
                            color={themeColorToDisplay}
                        />
                    )}
                </InterfaceWrapper>
            </InterfaceSubmitValidationContext.Provider>
        </motion.div>
    );
};

export default ItemInterfaceInSequence;
