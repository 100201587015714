import ProjectIntegrationCollectStatus from './ProjectIntegrationCollectStatus';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationCollectStatus',
        reactToAngular(ProjectIntegrationCollectStatus, ['projectIntegration', 'selectedEntity', 'onCollectCompleted']),
    );
