<div class="picklist-value-editor margin-top flex flex-space-between common-width-100">
    <div class="picklist-options">
        <!-- Title -->
        <div class="flex flex-space-between flex-center margin-bottom common-size-xxs margin-top-md">
            <div>
                <span class="svg-icon-sm margin-right-xs">
                    <tnk-icon src="/images/icons/chevron-circle.svg"></tnk-icon>
                </span>
                <span ng-if="!data.disableEdit">
                    <span>Create options for selection</span>
                    <span uib-tooltip="Use comma delimited text to copy and paste a list of values">
                        <i class="fa fa-info-circle"></i>
                    </span>
                </span>
                <span ng-if="data.disableEdit">Options for selection</span>
            </div>
            <div ng-if="!data.disableEdit" class="common-size-xxs">
                <a class="common-color-link-blue" ng-click="clearAllPicklistValues()">Clear All</a>
            </div>
        </div>

        <!-- Value picking drag and drop -->
        <div class="common-draggable-list margin-bottom-lg" tnk-dnd-list>
            <!-- Single value -->
            <div
                ng-repeat="picklistValue in data.picklistValues"
                class="flex-vmiddle common-draggable-list-item mod-no-padding"
                tnk-dnd-draggable="{{::picklistValue.value}}}"
                tnk-dnd-no-dragover="true"
                tnk-dnd-moved="onOptionMoved($index, dndIndex)"
                tnk-dnd-relative-to="field-definition-modal"
            >
                <div
                    ng-if="!data.disableEdit"
                    class="svg-icon svg-icon-align-text-base common-color-border-light drag margin-right"
                    style="cursor: move"
                >
                    <tnk-icon src="/images/icons/3lines-h.svg"></tnk-icon>
                </div>

                <!-- Picklist value input -->
                <input
                    data-automation="pick-list-value-editor-option-input"
                    id="picklist-value-{{ $index }}"
                    type="text"
                    ng-disabled="data.disableEdit"
                    ng-required="$first && data.required"
                    ng-model="picklistValue.value"
                    ng-keydown="onPicklistValueKeyDown($event, $index)"
                    ng-change="changeToPicklistValue(picklistValue.value, '{{ escape(picklistValue.value) }}', $index)"
                    ng-paste="onPicklistValuePaste($event, $index)"
                    placeholder="Option {{ $index + 1 }}"
                    autocomplete="off"
                    class="form-control common-no-border mod-short margin-right"
                />
                <div class="flex-grow"></div>

                <!-- Remove button -->
                <div
                    data-automation="pick-list-value-editor-remove-option"
                    ng-if="!data.disableEdit"
                    ng-click="removePicklistValue(picklistValue.value, $index)"
                    class="
                        svg-icon-xs
                        common-color-grey
                        svg-icon-hover-primary
                        margin-left-lg
                        svg-icon-align-text-base
                        btn-on-hover
                        pointer
                    "
                >
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </div>
            </div>
        </div>

        <!-- Add button -->
        <div ng-if="!data.disableEdit" class="common-size-xxs">
            <a
                data-automation="pick-list-value-editor-add-option-for-selection"
                class="common-color-link-blue"
                ng-click="addPicklistValue()"
            >
                + Add
            </a>
        </div>

        <!-- Change warning message part -->
        <div ng-if="data.anyExistingValuesChanged" class="margin-top-xs common-color-danger common-size-xxs">
            Changes to existing picklist values (
            <strong>{{ data.existingValuesChanged }}</strong>
            ) will not change any existing fields.
        </div>
    </div>

    <div ng-if="data.enableImport">
        <a
            class="btn btn-secondary import-picklist-options-button"
            uib-popover-template="'./importDropdownOptionsPopoverTemplate.template.html'"
            popover-is-open="data.isOpen"
            popover-placement="right"
            ng-click="data.isOpen = !data.isOpen"
        >
            Import
        </a>
    </div>
</div>
