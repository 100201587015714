<!-- Single mode + Button mode - Enabled or new integration -->
<div
    ng-init="singleIntegration = integrations[0]"
    ng-hide="inlineSetupScreen"
    ng-if="!state[integrations[0].name].integrations[0] || !state[integrations[0].name].integrations[0].disabled"
    data-ng-disabled="saving"
    tnk-require="{{
        $root.pm.projectData.allowIntegrationsInTrial
            ? 'free'
            : singleIntegration && integrationRequireMap[singleIntegration.name]
            ? integrationRequireMap[singleIntegration.name]
            : defaultLicenseTierToAllow
    }}"
    ng-click="singleIntegration.login ? singleIntegration.login(singleIntegration) : setUp(singleIntegration)"
    class="common-size-xxs flex-justify-space-around integration-option"
    ng-class="{
        'selected-integration-active':
            currentIntegration && selectedProjectIntegration && currentIntegration.id === selectedProjectIntegration.id
    }"
>
    <div ng-if="!webhookSource" class="flex-inline mod-vmiddle">
        <i
            class="initiative-integration-icon mod-normal margin-right"
            ng-class="!singleIntegration.iconUrl ? 'mod-' + singleIntegration.name : ''"
            ng-style="{
                background: singleIntegration.iconUrl ? 'url(' + singleIntegration.iconUrl + ') no-repeat center' : null
            }"
        ></i>
        <span class="common-ellipsis">
            {{ buttonPrefix || ''
            }}{{
                singleIntegration.displayName +
                    (state[integrations[0].name].integrations[0].integration.isNativeIntegration &&
                    !state[integrations[0].name].integrations[0].integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + state[integrations[0].name].integrations[0].creator.firstName + ')'
                        : '')
            }}{{ buttonPostfix || '' }}
        </span>
        &nbsp;
        <i class="loading-small margin-left" ng-show="saving"></i>
    </div>
    <div ng-if="webhookSource" class="overflow-hidden">
        <img
            ng-if="webhookSource.imgUrl"
            src="{{ webhookSource.imgUrl }}"
            class="initiative-integration-icon mod-normal mod-circle margin-right flex-no-shrink"
        />
        <span class="common-ellipsis">{{ buttonPrefix || '' }}{{ webhookSource.name }}</span>
        <i class="margin-left-xs common-color-grey common-ellipsis common-size-xxxxs">(Custom Source)</i>
        <i class="loading-small margin-left" ng-show="saving"></i>
    </div>
</div>

<!-- Single mode + Button mode - Existing disabled integration -->
<div
    ng-init="singleIntegration = integrations[0]; projectIntegration = state[integrations[0].name].integrations[0]"
    ng-hide="inlineSetupScreen"
    ng-if="state[integrations[0].name].integrations[0].disabled === true"
    data-ng-disabled="saving"
    tnk-require="{{
        singleIntegration && integrationRequireMap[singleIntegration.name]
            ? integrationRequireMap[singleIntegration.name]
            : defaultLicenseTierToAllow
    }}"
    ng-click="singleIntegration.login ? singleIntegration.login(singleIntegration) : setUp(singleIntegration)"
    class="common-size-xxs"
>
    <div class="flex-inline mod-vmiddle">
        <i class="fa fa-exclamation common-color-danger margin-right-xs"></i>
        <i
            class="initiative-integration-icon mod-normal margin-right"
            ng-class="!projectIntegration.iconUrl ? 'mod-' + singleIntegration.name : ''"
            ng-style="{
                background: projectIntegration.iconUrl
                    ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                    : null
            }"
        ></i>
        <span class="common-ellipsis">
            {{ buttonPrefix || ''
            }}{{
                projectIntegration.displayName +
                    (projectIntegration.integration.isNativeIntegration &&
                    !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + projectIntegration.creator.firstName + ')'
                        : '')
            }}
        </span>
        <i class="loading-small margin-left" ng-show="saving"></i>
    </div>
</div>
