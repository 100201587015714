/* jshint ignore:start */

import React from 'react';
import DefaultInboxReason from './DefaultInboxReason';

export default class FieldRangeChangedInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;

        if (
            !item.reasonMetadata ||
            !item.reasonMetadata.fieldRangesChanges ||
            !item.reasonMetadata.fieldRangesChanges.length
        ) {
            return null;
        }

        const changes = [];
        for (let i = 0; i < item.reasonMetadata.fieldRangesChanges.length; i++) {
            const fieldRangeChange = item.reasonMetadata.fieldRangesChanges[i];
            changes.push(
                <li key={i}>
                    value of column "{fieldRangeChange.fieldDefName}" was changed
                    {fieldRangeChange.oldValue ? ` from ${fieldRangeChange.oldValue}` : null} to{' '}
                    {fieldRangeChange.newValue}
                </li>,
            );
        }

        return (
            <div>
                <div>The following changes occurred on {this.renderTitleLinkElement(initiative)}</div>
                <ul>{changes}</ul>
                <div>Would you like to take any follow-up actions?</div>
            </div>
        );
    }
}
/* jshint ignore:end */
