import React, { useMemo } from 'react';
import styled from 'styled-components';

import type UserMenuSection from '../../entities/UserMenuSection';
import useUserPagesConfiguration from '../../hooks/useUserPagesConfiguration';

import { Separator } from '@tonkean/infrastructure';

const UserMenuContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    isSystemUser: boolean;
}

const UserMenuContent: React.FC<Props> = ({ isSystemUser }) => {
    const { settingsConfiguration } = useUserPagesConfiguration();

    const userMenuSections = useMemo(() => {
        return Object.keys(settingsConfiguration) as UserMenuSection[];
    }, [settingsConfiguration]);

    const sectionsToShow = useMemo(() => {
        return userMenuSections.filter((section) => settingsConfiguration[section].some((config) => config.isShown));
    }, [settingsConfiguration, userMenuSections]);

    return (
        <>
            {sectionsToShow.map((section, index) => {
                const settingsComponents = settingsConfiguration[section]
                    .filter((setting) => setting.isShown && (isSystemUser || setting.accessibleToAllUsers))
                    .map((setting) => <setting.component key={`${section}-${setting.name}`} setting={setting} />);

                if (!settingsComponents.length) {
                    return <></>;
                }

                return (
                    <UserMenuContentWrapper key={section}>
                        {settingsComponents}

                        {index < sectionsToShow.length - 1 && <Separator $margin="4px" />}
                    </UserMenuContentWrapper>
                );
            })}
        </>
    );
};

export default UserMenuContent;
