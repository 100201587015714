import React from 'react';

import type CommentsWidgetConfiguration from './CommentsWidgetConfiguration';
import { ItemInterfacePermission } from '../../../../entities';
import { ItemWidget, type ItemWidgetProps } from '../../../../WidgetModule';

import { Comments } from '@tonkean/collaboration';
import { useItemInterfaceContext } from '@tonkean/infrastructure';

const CommentsWidget: React.FC<ItemWidgetProps<CommentsWidgetConfiguration>> = ({ initiative, widget, permission }) => {
    const canEditComments = [
        ItemInterfacePermission.USER_CAN_EDIT_ITEM,
        ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM,
    ].includes(permission);

    const { initiativeInitialLoading } = useItemInterfaceContext();

    return (
        <ItemWidget permission={permission} noPaddingBody>
            <Comments
                initiative={initiative}
                initiativeInitialLoading={initiativeInitialLoading}
                canEditComments={canEditComments}
                includeIntake={widget.configuration.includeIntakeActivities ?? false}
                filterOnOtherTargets={!widget.configuration.saveActivitesAsPublic}
                disableReplyToComment={widget.configuration.disableReplyToComment}
                orderByAscending={widget.configuration.orderByAscending}
            />
        </ItemWidget>
    );
};

export default CommentsWidget;
