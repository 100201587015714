<!-- Track status summary popover -->
<div id="{{ popoverIdPrefix + data.editorId }}" ng-class="data.classes" tnk-dnd-undraggable>
    <!-- safari append() calculations gets messed up when div is empty -->
    <span>&nbsp;</span>
    <!-- Click outside makes sure the popover is reset when clicking outside -->
    <div
        ng-if="data.itemId"
        click-outside="reset()"
        outside-if-not="user-search"
        class="absolute-center-v"
        tnk-esc-close="reset()"
    >
        <div
            class="track-item-func-height"
            uib-popover-template="'status-popover-template'"
            popover-is-open="data.isOpen"
            popover-placement="{{ data.placement || 'auto left' }}"
            popover-trigger="none"
            popover-class="status-popover-template"
        ></div>
    </div>
</div>

<script type="text/ng-template" id="status-popover-template">
    <tnk-status-popover
        initiative-id="data.initiativeId"
        is-comment-in-edit="data.isLocked"
        is-view-only="viewOnlyMode"
        on-close="reset()"
        workflow-version-id="data.workflowVersionId"
    ></tnk-status-popover>
</script>
