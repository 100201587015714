import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';

/**
 * Gets the tonkean entity type, based on the given id.
 */
export const getTonkeanEntityType = function (id: string) {
    if (!id) {
        return null;
    }

    // Project
    if (id.indexOf('PROJ') === 0) {
        return TONKEAN_ENTITY_TYPE.PROJECT;
    }

    // Activity
    if (id.indexOf('ACTI') === 0) {
        return TONKEAN_ENTITY_TYPE.ACTIVITY;
    }

    // Initiative link
    if (id.indexOf('INLI') === 0) {
        return TONKEAN_ENTITY_TYPE.INITIATIVE_LINK;
    }

    // Field definition
    if (id.indexOf('FIDE') === 0) {
        return TONKEAN_ENTITY_TYPE.FIELD_DEFINITION;
    }

    // Field
    if (id.indexOf('FILD') === 0) {
        return TONKEAN_ENTITY_TYPE.FIELD;
    }

    // Initiative
    if (id.indexOf('INIT') === 0) {
        return TONKEAN_ENTITY_TYPE.INITIATIVE;
    }

    // Group
    if (id.indexOf('GRUP') === 0) {
        return TONKEAN_ENTITY_TYPE.GROUP;
    }

    // Person
    if (id.indexOf('PRSN') === 0) {
        return TONKEAN_ENTITY_TYPE.PERSON;
    }

    // Project integration
    if (id.indexOf('PRIN') === 0) {
        return TONKEAN_ENTITY_TYPE.PROJECT_INTEGRATION;
    }

    // Workflow version
    if (id.indexOf('WOVE') === 0) {
        return TONKEAN_ENTITY_TYPE.WORKFLOW_VERSION;
    }

    if (id.indexOf('SCGR') === 0) {
        return TONKEAN_ENTITY_TYPE.USER_GROUP;
    }

    return null;
};

/**
 * Returns true if given entity is an initiative entity. Returns false otherwise.
 */
export const isInitiativeEntity = function (entity) {
    if (!entity?.id) {
        return false;
    }

    return getTonkeanEntityType(entity.id) === TONKEAN_ENTITY_TYPE.INITIATIVE;
};

/**
 * Returns true if given entity is a field definition entity. Returns false otherwise.
 */
export const isFieldDefinitionEntity = function (entity) {
    if (!entity?.id) {
        return false;
    }

    return getTonkeanEntityType(entity.id) === TONKEAN_ENTITY_TYPE.FIELD_DEFINITION;
};

/**
 * Returns true if given entity is an initiative link entity. Returns false otherwise.
 */
export const isInitiativeLinkEntity = function (entity) {
    if (!entity?.id) {
        return false;
    }

    return getTonkeanEntityType(entity.id) === TONKEAN_ENTITY_TYPE.INITIATIVE_LINK;
};
