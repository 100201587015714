import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkerRunLogicCtrl($scope, $attrs, $localStorage, consts, modal, customTriggerManager) {
    const ctrl = this;
    $scope.logicBlockTypes = consts.getLogicBlockTypes();
    $scope.localStorage = $localStorage;
    $scope.data = {
        logic: ctrl.logic,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        selectedRunLogicsMap: ctrl.selectedRunLogicsMap,
        showInspect: ctrl.showInspect,
        onInspect: $attrs.onInspect ? ctrl.onInspect : null,
        forceExpandMap: ctrl.forceExpandMap,
        workerRun: ctrl.workerRun,

        collapsed: ctrl.collapsed,
        state: null,
        runLogicInstance: null,
        friendlyError: '',
        workingOnLabel: '',
        workingOnContextType: 'ORIGINAL_ITEM',

        // Map with the logic node id as the key and boolean indicating if the logic was activated as the value
        activatedInnerLogicMap: {},
        hasActivatedChildren: false,

        states: {
            MONITORING: {
                label: 'MONITORING',
                classes: 'common-color-yellow',
                iconClass: 'mod-monitor-tracks',
            },
            WASNT_ACTIVATED: {
                label: "WASN'T ACTIVATED",
                classes: 'common-color-grey',
            },
            CONDITION_NOT_MET: {
                label: 'CONDITION NOT MET',
                classes: 'common-color-danger',
            },
            HAS_ERROR: {
                label: 'HAS ERROR',
                classes: 'common-color-danger',
            },
            PENDING: {
                label: 'PENDING',
                classes: 'common-color-yellow',
            },
            SUCCESS: {
                label: 'SUCCESS',
                classes: 'common-color-success',
            },
            DISABLED: {
                label: 'DISABLED',
                classes: 'common-color-grey',
            },
        },
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.logic) {
            $scope.data.logic = changesObj.logic.currentValue;
            const firstMonitorParentData = customTriggerManager.getWorkflowVersionFirstMonitorParentData(
                $scope.data.workflowVersionId,
                $scope.data.logic.node,
            );
            if (firstMonitorParentData && firstMonitorParentData.monitorActionDefinition.workerItemContextType) {
                $scope.data.workingOnContextType = firstMonitorParentData.monitorActionDefinition.workerItemContextType;
            }

            $scope.data.workingOnLabel = customTriggerManager.getWorkingOnLabelInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.logic.node,
            );
            updateInnerLogicMap();
        }

        if (changesObj.workerRun) {
            $scope.data.workerRun = changesObj.workerRun.currentValue;
        }

        if (changesObj.forceExpandMap) {
            $scope.data.forceExpandMap = changesObj.forceExpandMap.currentValue;
            if ($scope.data.logic && $scope.data.forceExpandMap) {
                // Take the wildcard value into account
                if ($scope.data.forceExpandMap.hasOwnProperty('*')) {
                    $scope.data.collapsed = !$scope.data.forceExpandMap['*'];
                } else if ($scope.data.forceExpandMap[$scope.data.logic.node.id]) {
                    // If doesnt exist look at the value for specific logic
                    $scope.data.collapsed = false;
                }
            }
        }

        if (changesObj.selectedRunLogicsMap) {
            $scope.data.selectedRunLogicsMap = changesObj.selectedRunLogicsMap.currentValue;
            $scope.data.runLogicInstance = $scope.data.selectedRunLogicsMap
                ? $scope.data.selectedRunLogicsMap[$scope.data.logic.node.id]
                : null;
            $scope.data.friendlyError = '';
            if ($scope.data.logic.node.customTriggerType === 'MONITOR_TRACKS') {
                $scope.data.state = $scope.data.states.MONITORING;
            } else if (!$scope.data.runLogicInstance) {
                $scope.data.state = $scope.data.states.WASNT_ACTIVATED;
            } else if ($scope.data.runLogicInstance.disabled) {
                $scope.data.state = $scope.data.states.DISABLED;
            } else if (!$scope.data.runLogicInstance.conditionMet) {
                $scope.data.state = $scope.data.states.CONDITION_NOT_MET;
            } else if ($scope.data.runLogicInstance.hasError) {
                $scope.data.state = $scope.data.states.HAS_ERROR;
                if (
                    $scope.data.runLogicInstance.workerRunLogicActions &&
                    $scope.data.runLogicInstance.workerRunLogicActions.length
                ) {
                    $scope.data.friendlyError =
                        $scope.data.runLogicInstance.workerRunLogicActions[0].friendlyErrorMessage;
                } else if ($scope.data.runLogicInstance.friendlyErrorMessage) {
                    $scope.data.friendlyError = $scope.data.runLogicInstance.friendlyErrorMessage;
                }
            } else if (!$scope.data.runLogicInstance.completed) {
                $scope.data.state = $scope.data.states.PENDING;
            } else {
                $scope.data.state = $scope.data.states.SUCCESS;
            }
            updateInnerLogicMap();
        }
    };

    $scope.onInspectErrorClicked = function () {
        modal.openQuestionConfirmModal({
            controller: 'QuestionConfirmModalCtrl',
            windowClass: 'mod-primary',
            resolve: {
                questionConfirmModalData() {
                    return {
                        title: 'Inspect Error',
                        body: $scope.data.friendlyError ? $scope.data.friendlyError : 'Error occurred ',
                        okLabel: 'Ok',
                        cancelLabel: 'Cancel',
                    };
                },
            },
        });
    };

    $scope.onInspectRequestClicked = function (initiative) {
        $scope.data.onInspect({
            logicBlock: $scope.data.logic,
            runLogicInfo: $scope.data.runLogicInstance,
            initiative,
        });
    };

    function updateInnerLogicMap() {
        $scope.data.activatedInnerLogicMap = {};
        $scope.data.hasActivatedChildren = false;
        $scope.data.logic.impacts.forEach((innerLogic) => {
            const activated = !!$scope.data.selectedRunLogicsMap[innerLogic.node.id];
            if (activated) {
                $scope.data.hasActivatedChildren = true;
            }
            $scope.data.activatedInnerLogicMap[innerLogic.node.id] = activated;
        });
    }
}

export default angular.module('tonkean.app').controller('WorkerRunLogicCtrl', lateConstructController(WorkerRunLogicCtrl));
