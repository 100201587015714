import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Container = styled(Clickable)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 78px;
`;

const Header = styled.span`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
    margin-bottom: 6px;
`;

const Content = styled.span<{ underline?: boolean }>`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    flex-grow: 1;
    justify-self: center;
    text-transform: capitalize;
    ${({ underline = false }) =>
        underline &&
        css`
            text-decoration: underline;
        `};
`;

interface Props {
    header: string;
    value: ReactNode;
    onClick?(): void;
    disabled?: boolean;
    underline?: boolean;
    loading?: boolean;
}

const SolutionReportListItemMetric: React.FC<Props> = ({
    header,
    value,
    onClick: onClickProps,
    disabled = false,
    underline = false,
    loading = false,
}) => {
    const onClick = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            onClickProps?.();
        },
        [onClickProps],
    );
    return (
        <Container onClick={loading ? undefined : onClick} disabled={disabled}>
            <Header>{header}</Header>
            <Content underline={underline}>{loading ? <Placeholder $width="16px" $height="17px" /> : value}</Content>
        </Container>
    );
};

export default SolutionReportListItemMetric;
