import styled from 'styled-components';

import searchImageUrl from '../../../../../images/icons/history/search.svg?url';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

/**
 * @deprecated use SearchBox from TUI.
 */
const SearchBox = styled.input.attrs({ type: 'search', placeholder: 'Search for a Form' })`
    background: ${Theme.colors.basicBackground};
    border: none;
    height: 36px;
    padding-left: 31px;
    width: 100%;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    background: url(${searchImageUrl}) center left no-repeat;
    background-position-x: 12px;
    background-position-y: center;

    ::placeholder {
        color: ${Theme.colors.gray_500};
    }

    ${styledFocus}
`;

export default SearchBox;
