import { useEffect, useMemo, useState } from 'react';

import type { LineItemAggregation } from '../../entities';
import type CalculationResult from '../entities/CalculationResult';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { usePrevious } from '@tonkean/infrastructure';
import type { FieldDefinition, Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

type LineItemAggregationWithOutputAggregationResult = [
    LineItemAggregation & {
        outputFieldDefinitionId: FieldDefinition['id'];
    },
    number,
];

const useAutomaticUpdateOfManualFields = (
    initiativeId: Initiative['id'] | undefined,
    results: CalculationResult[],
    isReady: boolean,
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    onUpdate?: (initiative: Initiative) => void,
) => {
    const emit = useToastMessage();

    const [cache, setCache] = useState<CalculationResult[]>([]);

    const prevResults = usePrevious(cache);

    useEffect(() => {
        // Updating the results from the outside component to the inner component to be able to use "usePrevious"
        setCache(results);
    }, [results]);

    const [, updateMultipleFieldsValues] = useLazyTonkeanService('updateMultipleFieldsValues');

    const prevResultsCache: { [id: FieldDefinitionKey]: number | string | Date } = useMemo(() => {
        const entries = (prevResults || []).map(
            ([aggregation, result]) => [aggregation.fieldDefinitionKey, result] as const,
        );

        return Object.fromEntries(entries);
    }, [prevResults]);

    useEffect(() => {
        if (initiativeId && isReady) {
            cache
                // Filtering only the fields that has outputFieldDefinitionId.
                .filter(
                    (result): result is LineItemAggregationWithOutputAggregationResult =>
                        !!result[0].outputFieldDefinitionId,
                )
                // Filtering only the fields that their value has changed.
                .filter(([aggregation, result]) => prevResultsCache[aggregation.fieldDefinitionKey] !== result)
                .map(async ([aggregation, result]) => {
                    try {
                        const initiative = await updateMultipleFieldsValues(
                            workflowVersionId,
                            initiativeId,
                            {
                                [aggregation.outputFieldDefinitionId]: result?.toString(),
                            },
                            true,
                        );

                        onUpdate?.(initiative);
                    } catch {
                        emit(`Unable to save response of calculation of ${aggregation.title}`);

                        return undefined;
                    } finally {
                        console.log(`Caching ${aggregation.fieldDefinitionKey} - ${result}`);
                    }
                });
        }
    }, [emit, initiativeId, prevResultsCache, cache, isReady, updateMultipleFieldsValues, workflowVersionId, onUpdate]);
};

export default useAutomaticUpdateOfManualFields;
