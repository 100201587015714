import { useMemo } from 'react';

import type { BaseDataSourceConnectionConfigurationProps } from './baseDataSourceConnectionConfigurationProps';
import DataSourceConnectionAccessibilityTab from './DataSourceConnectionAccessibilityTab';
import DataSourceConnectionConnectionsTab from './DataSourceConnectionConnectionsTab';
import DataSourceConnectionPageHeader from './DataSourceConnectionPageHeader';
import { DataSourceConnectionTab } from './DataSourceConnectionTab';
import type {
    SidePaneBlockConfiguration,
    SidePaneConfiguration,
} from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { IntegrationManager } from '@tonkean/integrations';
import { IntegrationType } from '@tonkean/tonkean-entities';

const useDataSourceConnectionConfiguration = (integrationType: IntegrationType | undefined) => {
    return useMemo(() => {
        const settingBlock: SidePaneBlockConfiguration<BaseDataSourceConnectionConfigurationProps> = {
            displayName: 'Settings',
            key: 'settings',
            subTabsConfigurations: [
                {
                    displayName: 'Accessibility',
                    key: DataSourceConnectionTab.ACCESSIBILITY,
                    component: DataSourceConnectionAccessibilityTab,
                },
                {
                    displayName: 'Connections',
                    key: DataSourceConnectionTab.CONNECTION,
                    component: DataSourceConnectionConnectionsTab,
                    hide:
                        integrationType === IntegrationType.WEBHOOK ||
                        (integrationType && !IntegrationManager.getIntegrationByName(integrationType)),
                },
            ],
        };

        return {
            sidePaneBlockConfiguration: [settingBlock],
            headerComponent: DataSourceConnectionPageHeader,
        } as SidePaneConfiguration<BaseDataSourceConnectionConfigurationProps>;
    }, [integrationType]);
};

export default useDataSourceConnectionConfiguration;
