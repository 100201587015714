import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType } from '@tonkean/tonkean-entities';

const formAnsweredShowInterfaceTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.SEND_FORM_ANSWERED;
    params.displayName = 'Show an Interface';
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerActions = [
        {
            type: CustomTriggerType.SEND_FORM_ANSWERED,
            customTriggerActionDefinition: {
                replyOnOriginalMessage: true,
                selectedButtonType: 'open-custom-interface-in-sequence',
            },
        },
    ];
};

export default formAnsweredShowInterfaceTriggerParamsEnricher;
