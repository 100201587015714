import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EditBlueIcon } from '../../../../images/icons/edit-pencil.svg';
import { ReactComponent as FieldValueIcon } from '../../../../images/icons/field-value.svg';

import { Paragraph } from '@tonkean/infrastructure';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const FieldWrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const FieldName = styled(Paragraph)`
    margin-bottom: 5px;
`;

const FieldSubText = styled(Paragraph)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledFieldValueIcon = styled(FieldValueIcon)`
    margin-right: 5px;
`;

const EditButton = styled(IconButton)`
    border-radius: 5px;
`;

const HighlightedText = styled(Paragraph)`
    margin-right: 10px;
`;

const EditHighlightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export interface Field {
    name: string;
    itemValue?: string;
    highlightMark?: React.ReactNode;
    isEditable: boolean;
}

interface FieldRowProps {
    field: Field;
    onClickEditField: () => void;
}
const FieldMapperFieldRow: React.FC<FieldRowProps> = ({
    field: { name, itemValue, highlightMark, isEditable = true },
    onClickEditField,
}) => {
    return (
        <FieldWrapper>
            <div>
                <FieldName $color={Theme.colors.gray_800}>{name}</FieldName>
                {itemValue && (
                    <FieldSubText $color={Theme.colors.gray_600} $small>
                        <StyledFieldValueIcon />
                        {itemValue}
                    </FieldSubText>
                )}
            </div>
            <EditHighlightWrapper>
                {highlightMark && <HighlightedText $color={Theme.colors.primary}>{highlightMark}</HighlightedText>}
                {isEditable && (
                    <EditButton
                        iconColor={Theme.colors.primary}
                        onClick={onClickEditField}
                        dataAutomation="field-mapper-row-edit-icon"
                    >
                        <EditBlueIcon />
                    </EditButton>
                )}
            </EditHighlightWrapper>
        </FieldWrapper>
    );
};

export default FieldMapperFieldRow;
