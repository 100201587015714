import type { EntitySelectorProps } from './EntitySelectorProps';

import { angularToReact } from '@tonkean/angular-components';

const TnkEntitySelector = angularToReact<EntitySelectorProps>('tnk-entity-selector', {
    onEntitySelected: ['selectedEntity', 'isInit'],
    shouldDisableChoice: ['option'],
});

export default TnkEntitySelector;
