import template from './tnkImportTable.template.html?angularjs';

function tnkImportTable() {
    return {
        restrict: 'E',
        scope: {
            table: '=',
            groupId: '=',
            workflowVersionId: '@',
            overrideImport: '=',
            handleDuplicatesDisabled: '<',
            preventDuplicatesUpdate: '<', // When set to true, the options to "update" tracks found as duplicates in the csv will not appear.
            displayFieldsList: '=',
            onCancel: '&',
            onDone: '&',
            onOverrideImport: '&',
            parentId: '=',
            createInEditMode: '=',
            formId: '=',
        },
        template,
        controller: 'ImportTableCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkImportTable', tnkImportTable);
