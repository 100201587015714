<tnk-assign-value-for-inner-item-field
    group-id="{{ data.groupId }}"
    workflow-version-id="{{ data.workflowVersionId }}"
    initiative="initiative"
    on-inner-tracks-template-config-changed="
        onInnerTracksTemplateConfigChangedUpdateInitiative(index, initiative, shouldSaveLogic)
    "
    owner-configuration="initiative.personDefinition"
    on-owner-changed="onOwnerChanged(index, personSelectionConfiguration, shouldSaveLogic)"
    on-remove-item="removeInitiative($index)"
    show-remove-item="data.shouldShowRemoveButton"
    index="$index"
    click-outside="outsideClicked($index)"
    outside-if-not="manual-items-template-config"
    configured-logic="data.configuredLogic"
    is-dynamic="data.isDynamic"
></tnk-assign-value-for-inner-item-field>
