function AddLinkedColumnModalCtrl(
    $scope,
    $uibModalInstance,
    projectManager,
    workflowVersionId,
    utils,
    customFieldsManager,
    groupInfoManager,
    workflowVersionManager,
) {
    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;

    $scope.data = {
        workflowVersionId,

        workflowVersions: null,
        fieldDefinitions: null,

        selectedWorkflowVersionToLinkFrom: null,
        selectedFieldDefinitionToLink: null,

        savingFieldDefinitionOnWorkflowVersion: false,
        errorSavingFieldDefinitionOnWorkflowVersion: false,
    };

    /**
     * Initializes the modal.
     */
    $scope.init = function () {
        // Initializing the possible workflow versions and selecting a first one.
        $scope.data.workflowVersions = utils
            .objValues(workflowVersionManager.workflowVersionIdToWorkflowVersionMap)
            .filter((workflowVersion) => {
                // We are filtering current workflow version from possible workflow versions.
                if (workflowVersion.id === workflowVersionId) {
                    return false;
                }

                // We are filtering out workflow versions that do not have fields.
                if (
                    !customFieldsManager.selectedFieldsMap[workflowVersion.id] ||
                    !customFieldsManager.selectedFieldsMap[workflowVersion.id].length
                ) {
                    return false;
                }

                // Gathering all the field definitions that were created in the workflow version.
                const fieldThatWereCreatedInTheWorkflowVersion = [];
                for (let i = 0; i < customFieldsManager.selectedFieldsMap[workflowVersion.id].length; i++) {
                    const currentField = customFieldsManager.selectedFieldsMap[workflowVersion.id][i];
                    if (currentField.workflowVersionId === workflowVersion.id) {
                        fieldThatWereCreatedInTheWorkflowVersion.push(currentField);
                    }
                }

                // If there are none, we filter it out.
                if (!fieldThatWereCreatedInTheWorkflowVersion.length) {
                    return false;
                }

                // We only want published workflow versions.
                if (workflowVersion.workflowVersionType !== 'PUBLISHED') {
                    return false;
                }

                return true;
            });

        if ($scope.data.workflowVersions && $scope.data.workflowVersions.length) {
            $scope.data.selectedWorkflowVersionToLinkFrom = $scope.data.workflowVersions[0];
            initializeFieldDefinitionsToChooseFrom();
        }
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Occurs once the selected workflow version is changed.
     */
    $scope.onSelectedWorkflowVersionChanged = function () {
        initializeFieldDefinitionsToChooseFrom();
    };

    /**
     * Occurs once the user wants to link a field definition to a workflow version.
     */
    $scope.linkField = function () {
        $scope.data.savingFieldDefinitionOnWorkflowVersion = true;
        $scope.data.errorSavingFieldDefinitionOnWorkflowVersion = false;

        customFieldsManager
            .addFieldDefinitionToWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.selectedWorkflowVersionToLinkFrom.id,
                $scope.data.selectedFieldDefinitionToLink.id,
            )
            .then(() => {
                groupInfoManager.getGroups(true);
                $uibModalInstance.close();
            })
            .catch(() => {
                $scope.data.errorSavingFieldDefinitionOnWorkflowVersion = true;
            })
            .finally(() => {
                $scope.data.savingFieldDefinitionOnWorkflowVersion = false;
            });
    };

    /**
     * Initializes the field definitions we should choose from.
     */
    function initializeFieldDefinitionsToChooseFrom() {
        // Constructing a set of already existing field definitions.
        const existingFieldDefinitionsSet = {};
        for (let i = 0; i < customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId].length; i++) {
            const existingFieldDefinition = customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId][i];
            existingFieldDefinitionsSet[existingFieldDefinition.id] = true;
        }

        // Initializing the field definitions array according to already selected fields.
        $scope.data.fieldDefinitions = customFieldsManager.selectedFieldsMap[
            $scope.data.selectedWorkflowVersionToLinkFrom.id
        ].filter((fieldDefinition) => !existingFieldDefinitionsSet[fieldDefinition.id]);
        if ($scope.data.fieldDefinitions && $scope.data.fieldDefinitions.length) {
            $scope.data.selectedFieldDefinitionToLink = $scope.data.fieldDefinitions[0];
        } else {
            $scope.data.selectedFieldDefinitionToLink = null;
        }
    }

    $scope.init();
}

angular.module('tonkean.app').controller('AddLinkedColumnModalCtrl', AddLinkedColumnModalCtrl);
