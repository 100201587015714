import React, { useEffect, useMemo } from 'react';

import {
    ExtendableRowColoredText,
    ExtendableRowText,
    HeaderWrapper,
    InnerContentWrapper,
} from './HandleResponseSharedCriteriaStyle';
import SuccessCriteriaCreate from './SuccessCriteriaViews/SuccessCriteriaCreate';
import SuccessCriteriaCustom from './SuccessCriteriaViews/SuccessCriteriaCustom';
import SuccessCriteriaUpload from './SuccessCriteriaViews/SuccessCriteriaUpload';
import SuccessCriteriaValueAssignment from './SuccessCriteriaViews/SuccessCriteriaValueAssignment';
import useCustomRequestAdditionalTab from '../../../../../../../HttpRequestConfiguration/utils/useCustomRequestAdditionalTab';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { ExtendableRow, GroupedExtendableRows } from '@tonkean/infrastructure';
import { ActionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type {
    EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition,
    IntegrationSupportedEntity,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
    ProjectIntegrationActionUploadFileResponseHandleDefinition,
    TonkeanExpressionDefinition,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    setProjectIntegrationAction: React.Dispatch<React.SetStateAction<ProjectIntegrationAction>>;
    entity: IntegrationSupportedEntity | undefined;
    viewingTestResponseId?: string;
}

const SuccessCriteria: React.FC<Props> = ({
    projectIntegration,
    projectIntegrationAction,
    setProjectIntegrationAction,
    viewingTestResponseId,
    entity,
}) => {
    const [groupId] = useGetStateParams('projectId');

    const actionsCustomFieldTab = useCustomRequestAdditionalTab(
        projectIntegrationAction.actionDefinition.definitionType,
        [],
    );

    // List of all the expression definition
    // when one of them changes we requesting the preview foreach definition.
    const listOfAllUsedExpressionsDefinitions: TonkeanExpressionDefinition[] = useMemo(() => {
        if (
            projectIntegrationAction?.responseHandlingDefinition?.responseHandlingType ===
            EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS
        ) {
            return (
                projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields.map(
                    (outputLinkedField) => outputLinkedField.value,
                ) || []
            );
        } else {
            return [];
        }
    }, [projectIntegrationAction.responseHandlingDefinition]);

    // When the list of the used expression changed we requesting the server to evaluate the preview for tonkean expression
    const [{ data: evaluateExpressionToEvaluatedPreview }, evaluateHandleResponseTestRunExpressions] =
        useLazyTonkeanService('evaluateHandleResponseTestRunExpressions');

    // When the listOfAllUsedExpressionsDefinitions we update evaluate all the previews again
    useEffect(() => {
        if (viewingTestResponseId) {
            evaluateHandleResponseTestRunExpressions(
                projectIntegrationAction.projectIntegrationId,
                viewingTestResponseId,
                listOfAllUsedExpressionsDefinitions,
            );
        }
    }, [
        evaluateHandleResponseTestRunExpressions,
        listOfAllUsedExpressionsDefinitions,
        projectIntegrationAction.projectIntegrationId,
        viewingTestResponseId,
    ]);

    // Indicated the success header text.
    const headerText = useMemo(() => {
        if (projectIntegrationAction.actionType === ActionType.UPDATE) {
            return 'Log success and continue the flow run';
        } else if (projectIntegrationAction.actionType === ActionType.OAUTH2_REFRESH_AUTHENTICATION) {
            return 'Update parameter values';
        } else {
            return 'Create a matched entity, log success and continue the flow run';
        }
    }, [projectIntegrationAction.actionType]);

    // Indicates Whether we allow the user to handle the success response and create new fields.
    const shouldEnableToHandleSuccess = projectIntegrationAction.actionType !== ActionType.UPDATE;

    return (
        <GroupedExtendableRows>
            <ExtendableRow
                header={
                    <HeaderWrapper isNonExtendable>
                        <ExtendableRowColoredText color={Theme.colors.success}>
                            Success Criteria:
                        </ExtendableRowColoredText>
                        <ExtendableRowText>Unless there is no failure (default)</ExtendableRowText>
                    </HeaderWrapper>
                }
            />

            <ExtendableRow
                header={
                    <HeaderWrapper isNonExtendable={!shouldEnableToHandleSuccess}>
                        <ExtendableRowColoredText color={Theme.colors.gray_700}>Handle: </ExtendableRowColoredText>
                        <ExtendableRowText>{headerText}</ExtendableRowText>
                    </HeaderWrapper>
                }
                isOpenByDefault
            >
                {shouldEnableToHandleSuccess && (
                    <InnerContentWrapper>
                        {(projectIntegrationAction.actionType === ActionType.CREATE ||
                            projectIntegrationAction.actionType === ActionType.CREATE_OR_UPDATE) && (
                            <SuccessCriteriaCreate
                                setProjectIntegrationAction={setProjectIntegrationAction}
                                projectIntegrationAction={
                                    projectIntegrationAction as ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>
                                }
                                projectIntegration={projectIntegration}
                                actionsCustomFieldTab={[actionsCustomFieldTab]}
                                evaluateExpressionToEvaluatedPreview={evaluateExpressionToEvaluatedPreview}
                                groupId={groupId}
                                hasExampleRequest={!!viewingTestResponseId}
                                entity={entity}
                            />
                        )}

                        {projectIntegrationAction.actionType === ActionType.CUSTOM && (
                            <SuccessCriteriaCustom
                                setProjectIntegrationAction={setProjectIntegrationAction}
                                projectIntegrationAction={
                                    projectIntegrationAction as ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>
                                }
                                groupId={groupId}
                                actionsCustomFieldTab={[actionsCustomFieldTab]}
                                evaluateExpressionToEvaluatedPreview={evaluateExpressionToEvaluatedPreview}
                            />
                        )}

                        {projectIntegrationAction.actionType === ActionType.UPLOAD && (
                            <SuccessCriteriaUpload
                                projectIntegration={projectIntegration}
                                projectIntegrationAction={
                                    projectIntegrationAction as ProjectIntegrationAction<ProjectIntegrationActionUploadFileResponseHandleDefinition>
                                }
                                groupId={groupId}
                                actionsCustomFieldTab={[actionsCustomFieldTab]}
                                setProjectIntegrationAction={setProjectIntegrationAction}
                            />
                        )}

                        {projectIntegrationAction.actionType === ActionType.OAUTH2_REFRESH_AUTHENTICATION && (
                            <SuccessCriteriaValueAssignment
                                projectIntegration={projectIntegration}
                                projectIntegrationAction={
                                    projectIntegrationAction as ProjectIntegrationAction<EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition>
                                }
                                setProjectIntegrationAction={setProjectIntegrationAction}
                            />
                        )}
                    </InnerContentWrapper>
                )}
            </ExtendableRow>
        </GroupedExtendableRows>
    );
};

export default SuccessCriteria;
