import { useAngularService, useAngularWatch } from 'angulareact';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as WarningIcon } from '../../../../images/icons/history/warning.svg';
import VersionComment, { VersionCommentAvatarSize } from '../../../infrastructure/components/VersionComment';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import {
    HighlightableText,
    KebabMenuButton,
    Menu,
    MenuItem,
    ModalSize,
    Tooltip,
    useCreateDialog,
    useToggle,
} from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { WorkflowVersion } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import utils, { colorSvg } from '@tonkean/utils';

const StyledKebabMenuItem = styled(KebabMenuButton)`
    margin-left: auto;
    width: 30px;
`;

const StyledMenuItem = styled(MenuItem)`
    font-size: ${FontSize.SMALL_12};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    line-height: 20px;
`;

const StyledParagraph = styled.p`
    margin-bottom: 20px;
`;

const PropContainer = styled.div`
    display: flex;
`;
const PropTitle = styled.strong`
    margin-right: 5px;
`;

const StyledWarningIcon = styled(WarningIcon)`
    width: 20px;
    height: 20px;
    margin-right: 10px;

    ${colorSvg(Theme.colors.gray_500)};
`;

const DivProduction = styled.div`
    margin-bottom: 4px;
`;

const StyledSection = styled.section<{ isOldPublish: boolean; isSubVersion: boolean; showAsDrillDown: boolean }>`
    padding: 10px;

    > header {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        align-items: center;
        line-height: 14px;
    }

    > main {
        display: flex;
        margin-top: ${({ showAsDrillDown }) => (showAsDrillDown ? '15px' : '4px')};
    }

    span.workflow-version-number {
        color: #9ca6b2;
        margin-right: 4px;
    }

    span.workflow-version-date {
        color: #5b636c;
        margin-left: 2px;
        flex-grow: 1;
    }

    .workflow-versions-comment {
        font-size: 12px;
        color: #838a92;
        margin: 0 0 0 7px;
        align-self: center;

        > strong {
            font-weight: 500;
            color: #34393e;
        }
    }

    ${({ isOldPublish }) =>
        isOldPublish &&
        css`
            box-shadow:
                0 -1px 0 #cdd2d8,
                inset 4px 0 0 #605ce4;
        `}

    ${({ isSubVersion }) =>
        isSubVersion &&
        css`
            padding: 8px 8px 8px 36px;
            background: #f8f8f8;
        `}
`;

const StyledInProduction = styled.span`
    background: #605ce4;
    color: #fff;
    border-radius: 10px;
    padding: 2px 6px 1px 6px;
    margin-right: 5px;
    font-size: 10px;
    width: 88px;
`;

const StyledCount = styled.span`
    color: #9ca6b2;
    margin-left: 3px;
    font-size: 10px;
    float: right;

    > span {
        color: #5b636c;
        background: #e9ecf1;
        display: inline-block;
        height: 17px;
        min-width: 17px;
        line-height: 17px;
        text-align: center;
        border-radius: 100%;
        margin-left: 2px;
        padding: 0 1px;
        font-weight: 500;
    }
`;

const StyledVersionComment = styled(VersionComment)<{ showAvatar: boolean }>`
    ${({ showAvatar }) =>
        showAvatar &&
        css`
            padding-right: 20px;
        `}
`;

interface Props {
    workflowVersion: WorkflowVersion;
    headerPrepend?: React.ReactNode;
    searchTerm?: string;
    showAsDrillDown?: boolean;
}

const WorkflowVersionInfo: React.FC<Props> = ({
    workflowVersion,
    headerPrepend,
    searchTerm,
    showAsDrillDown = false,
}) => {
    const {
        creator,
        comment,
        sequentialIdentifier,
        subSequentialIdentifier,
        created,
        workflowVersionType,
        isPublishReady,
        publishReadyTime,
        publishApprover,
        groupId,
        changesCount,
    } = workflowVersion;

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const $state = useAngularService('$state');
    const groupManager = useAngularService('groupManager');
    const [, revertDraftWorkflowVersion] = useLazyAsyncMethod(groupManager, 'revertDraftWorkflowVersion');

    const { confirm } = useCreateDialog();

    const formattedCreationDate = dayjs(created).format('MMM Do YYYY');

    const publishDate = isPublishReady && publishReadyTime ? publishReadyTime : created;
    const publisher = isPublishReady && publishApprover ? publishApprover : creator;

    const [open, toggleOpen] = useToggle(false);

    const [draftWorkflowVersionChanges] = useAngularWatch(() => {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId)?.id;

        if (!draftWorkflowVersionId) {
            console.error(`Could not load draftWorkflowVersionId for groupId : ${groupId}`);
            return 0;
        }

        return workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersionId] ?? 0;
    });

    const isOldPublish =
        subSequentialIdentifier < 0 &&
        workflowVersionType !== WorkflowVersionType.PUBLISHED &&
        workflowVersionType !== 'DRAFT';

    const getDate = (date) => {
        return utils.formatDate(date, true, true, true, true, 'hh:mm A', 'M/D/YY');
    };

    const openRestoreVersion = useCallback(() => {
        return confirm(
            <Header>
                <StyledWarningIcon /> Restore Version V{sequentialIdentifier}
                {subSequentialIdentifier > 0 && `.${subSequentialIdentifier}`}
            </Header>,
            <Container>
                {draftWorkflowVersionChanges > 0 && (
                    <>
                        <StyledParagraph>
                            You have <strong>{draftWorkflowVersionChanges} pending changes</strong> in your build
                            environment.
                        </StyledParagraph>
                        <StyledParagraph>
                            This action will dismiss the pending changes and then restore the selected snapshot of your
                            module by creating a new version in the build environment.
                        </StyledParagraph>
                    </>
                )}
                {draftWorkflowVersionChanges === 0 && (
                    <StyledParagraph>
                        This action restores the selected snapshot of your module by creating a new version in the build
                        environment.
                    </StyledParagraph>
                )}

                <PropContainer>
                    <PropTitle>Module Version:</PropTitle>
                    <div>
                        V{sequentialIdentifier}
                        {subSequentialIdentifier > 0 && `.${subSequentialIdentifier}`}
                    </div>
                </PropContainer>

                <PropContainer>
                    <PropTitle>Creation Date:</PropTitle>
                    <div>{formattedCreationDate}</div>
                </PropContainer>

                <PropContainer>
                    <PropTitle>Created By:</PropTitle>
                    <div>{creator.name}</div>
                </PropContainer>
            </Container>,
            {
                warning: true,
                size: ModalSize.SMEDIUM,
                okLabel: 'Restore',
                onOk: () => {
                    return revertDraftWorkflowVersion(groupId, workflowVersion.id).then(() => {
                        $state.go('product.workerEditor', { env: 'DRAFT', historyVersion: '' });
                    });
                },
            },
        );
    }, [
        confirm,
        sequentialIdentifier,
        subSequentialIdentifier,
        draftWorkflowVersionChanges,
        formattedCreationDate,
        creator.name,
        revertDraftWorkflowVersion,
        groupId,
        workflowVersion.id,
        $state,
    ]);

    return (
        <StyledSection
            data-automation={`workflow-version-info-V${sequentialIdentifier > 0 ? sequentialIdentifier : '0'}${subSequentialIdentifier > 0 ? `.${subSequentialIdentifier}` : ''}`}
            className="workflow-version-info"
            isOldPublish={!showAsDrillDown && (workflowVersionType === WorkflowVersionType.PUBLISHED || isOldPublish)}
            isSubVersion={!showAsDrillDown && subSequentialIdentifier > 0}
            showAsDrillDown={showAsDrillDown}
        >
            {workflowVersionType === WorkflowVersionType.PUBLISHED && (
                <DivProduction>
                    <Tooltip content={`Was published to production on ${getDate(publishDate)}`} placement="bottom">
                        <StyledInProduction>IN PRODUCTION</StyledInProduction>
                    </Tooltip>
                    {!showAsDrillDown && (
                        <StyledCount>
                            <span>{changesCount}</span>
                        </StyledCount>
                    )}
                </DivProduction>
            )}
            <header>
                {headerPrepend}
                {workflowVersionType === WorkflowVersionType.PUBLISHED && (
                    <span className="workflow-version-number">V{sequentialIdentifier}</span>
                )}
                {subSequentialIdentifier > 0 && (
                    <span className="workflow-version-number">
                        V{`${sequentialIdentifier > 0 ? sequentialIdentifier : '0'}.${subSequentialIdentifier}`}
                    </span>
                )}
                {isOldPublish && (
                    <Tooltip content={`Was published to production on ${getDate(publishDate)}`} placement="bottom">
                        <span className="workflow-version-number">{`V${sequentialIdentifier}`}</span>
                    </Tooltip>
                )}
                <span className="workflow-version-date">{utils.formatDate(publishDate, true, true, true, true)}</span>
                {showAsDrillDown && workflowVersionType != 'DRAFT' && (
                    <Menu
                        show={open}
                        onClose={toggleOpen}
                        placement="left-start"
                        menuItems={
                            <StyledMenuItem onClick={() => openRestoreVersion()}>Restore Version</StyledMenuItem>
                        }
                    >
                        <StyledKebabMenuItem onClick={() => toggleOpen()} flat thin />
                    </Menu>
                )}
                {!showAsDrillDown &&
                    changesCount !== undefined &&
                    workflowVersionType !== WorkflowVersionType.PUBLISHED && (
                        <StyledCount>
                            <span>{changesCount}</span>
                        </StyledCount>
                    )}
            </header>
            <main>
                <StyledVersionComment
                    avatarSize={VersionCommentAvatarSize.BIG}
                    user={publisher}
                    showAvatar={showAsDrillDown}
                >
                    <HighlightableText text={comment || 'Initial workflow publish'} highlightText={searchTerm} inline />
                </StyledVersionComment>
            </main>
        </StyledSection>
    );
};

export default WorkflowVersionInfo;
