<form class="common-width-100" name="fieldDefinitionConfigurationForm" ng-submit="createOrUpdateFieldDefinition()">
    <!-- Matched Item Page -->
    <div ng-if="data.isForMatchedItem">
        <tnk-field-definition-match-item-settings-modal
          group-id="data.groupId"
          field-definition="data.existingFieldDefinition"
          workflow-version="data.workflowVersion"
          reload-fields="(afterCreateOrUpdateCallback)"
          on-modal-esc-close="(onModalEscClose)"
          on-cancel="(cancel)"
          on-delete="
                (!fieldNotPublished &&
                    !data.createMode &&
                    data.originalFieldDefinitionType !== 'MATCH_TONKEAN_ITEM' &&
                    deleteFieldDefinition)
            "
          on-duplicate="
                (!fieldNotPublished &&
                    !data.createMode &&
                    data.originalFieldDefinitionType !== 'MATCH_TONKEAN_ITEM' &&
                    !data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem &&
                    openDuplicateMetricModal)
            "
        />
    </div>

    <div
        ng-if="!data.matchedEntityRelationsLoading && !data.isForMatchedItem"
        class="field-definition-configuration-modal mod-stretch common-close-btn-container"
        ng-class="{ 'new-navigation-design': $root.features.currentProject.tonkean_feature_show_new_topbar_navigation }"
    >
        <tnk-field-definition-configuration-modal-sub-header
            class="field-definition-configuration-sub-header mod-justify-space"
            header-title="data.subHeaderTitle"
            has-pending-changes="data.didChange && !data.duplicateOrCreateMode"
            on-modal-esc-close="(onModalEscClose)"
            on-cancel="(cancel)"
            workflow-version-type="data.workflowVersion.workflowVersionType"
            on-delete="
                (!fieldNotPublished &&
                    !data.createMode &&
                    data.originalFieldDefinitionType !== 'MATCH_TONKEAN_ITEM' &&
                    deleteFieldDefinition)
            "
            on-duplicate="
                (!fieldNotPublished &&
                    !data.createMode &&
                    data.originalFieldDefinitionType !== 'MATCH_TONKEAN_ITEM' &&
                    !data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem &&
                    openDuplicateMetricModal)
            "
            save-disabled="!data.previewRequestParameters.validDefinition || data.errorDefiningFieldDefinitionName || data.creatingOrUpdatingFieldDefinition || data.integrationConfigurationStillInProgress || !data.didChange"
            ng-attr-title=""
        ></tnk-field-definition-configuration-modal-sub-header>



        <!-- Left pane (configuration) -->
        <div class="left-pane mod-full-screen-modal" ng-if="showField">
            <!-- Field Not Published -->
            <div ng-if="fieldNotPublished" class="alert alert-warning field-not-published">
                Field was not published, and cannot be shown.
            </div>

            <!-- Content -->
            <div class="left-pane-inner-content" ng-if="!fieldNotPublished">
                <!-- Title section -->
                <div class="margin-bottom-lg">
                    <!-- Matching Entity Subtitle -->
                    <div ng-if="data.idOnlyMode" class="flex mod-align-start margin-bottom-lg common-color-grey">
                        To set a new matched data source entity first configure a matching field that will define how
                        they match.
                    </div>

                    <!-- Subtitle -->
                    <div ng-if="data.projectIntegration" class="flex flex-col mod-align-start margin-bottom-md">
                        <!-- Actual Subtitle -->
                        <div class="flex-vmiddle margin-right-lg">
                            <i
                                class="initiative-integration-icon mod-circle mod-normal mod-dropdown inline-block"
                                ng-class="
                                    !data.projectIntegration.iconUrl
                                        ? 'mod-' + data.projectIntegration.integration.integrationType.toLowerCase()
                                        : ''
                                "
                                ng-style="{
                                    background: data.projectIntegration.iconUrl
                                        ? 'url(' + data.projectIntegration.iconUrl + ') no-repeat center'
                                        : null
                                }"
                            ></i>
                            <span class="common-title-xxs mod-light mod-grey">
                                {{ data.projectIntegration.displayNameFull }}
                            </span>
                        </div>

                        <!-- Broken integration message -->
                        <div
                            ng-if="
                                data.existingFieldDefinition.isBrokenIntegration ||
                                (data.projectIntegration && !data.projectIntegration.valid)
                            "
                        >
                            <div
                                ng-init="displayName = integrations.getAllIntegrationsDisplayName()[getIntegrationType().toLowerCase()]"
                                class="info-box margin-normal-sm flex"
                            >
                                <i class="fa fa-exclamation-triangle common-color-danger margin-right"></i>
                                <span>
                                    The selected {{ data.projectIntegration.displayName }} requires a fix. Go to
                                    <a
                                        ng-click="goToProjectIntegrationPageWithReturnTo(data.projectIntegrationPageMenuItemType.CONNECTIONS)"
                                    >
                                        Manage data source
                                    </a>
                                    to fix it.
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- Title & Check sign -->
                    <div class="flex-vmiddle flex-xs-wrap" ng-if="!data.openedFromCustomTriggerId">
                        <!-- Title -->
                        <div class="common-size-xxs margin-right-xs margin-bottom-xs">Name:</div>

                        <!-- Name input Matching Field-->
                        <input
                            ng-if="data.idOnlyMode"
                            type="text"
                            class="form-control margin-right field-definition-name-input margin-bottom-xs"
                            data-automation="field-definition-name-input"
                            ng-readonly="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                            id="editMatchingFieldNameInput"
                            ng-model="data.previewRequestParameters.name"
                            ng-change="onFieldDefinitionNameEdited()"
                            ng-keydown="onFieldNameInputKeyDown($event)"
                            placeholder="matching field name"
                            autocomplete="off"
                            required
                            autofocus
                        />

                        <!-- Name input Field or Global Field -->
                        <input
                            ng-if="!data.idOnlyMode"
                            type="text"
                            class="form-control margin-right field-definition-name-input margin-bottom-xs"
                            data-automation="field-definition-name-input"
                            ng-readonly="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                            id="editFieldNameInput"
                            ng-model="data.previewRequestParameters.name"
                            ng-change="onFieldDefinitionNameEdited()"
                            ng-keydown="onFieldNameInputKeyDown($event)"
                            placeholder="What data do you wish to see?"
                            autocomplete="off"
                            required
                            autofocus
                        />
                    </div>
                </div>
                <!-- Data type upper separator -->
                <hr
                    class="margin-none"
                    ng-if="
                        !data.stepsState['dataTypeConfiguration'] &&
                        !data.skipStepsMap['dataTypeConfiguration'] &&
                        !data.startWithDataSource &&
                        data.createMode
                    "
                    ng-hide="data.quickCreateForExternal"
                />

                <!-- Data type title -->
                <div
                    class="padding-normal step-header"
                    ng-click="(!data.createMode || data.visitedStepsState['dataTypeConfiguration']) && toggleStep('dataTypeConfiguration')"
                    ng-class="{
                        pointer: !data.createMode || data.visitedStepsState['dataTypeConfiguration'],
                        'mod-hover': !data.createMode
                    }"
                    ng-if="
                        !data.stepsState['dataTypeConfiguration'] &&
                        !data.skipStepsMap['dataTypeConfiguration'] &&
                        !data.startWithDataSource &&
                        data.createMode
                    "
                    ng-hide="data.quickCreateForExternal || data.idOnlyMode"
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                            data-automation="field-definition-configuration-modal-data-type-section"
                        >
                            Data Type
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['dataTypeConfiguration']"
                            data-automation="field-definition-configuration-modal-check-icon-data-type-section"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="common-color-grey step-edit-annotation">Edit</div>
                    </div>

                    <!-- Subtitle -->
                    <div class="common-size-xxxs">
                        {{
                            data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'
                                ? data.selectedDataTypeObject.displayName.column
                                : data.selectedDataTypeObject.displayName.global
                        }}
                    </div>
                </div>

                <!-- Data type configuration-->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-dataTypeConfiguration"
                    ng-if="
                        data.stepsState['dataTypeConfiguration'] &&
                        !data.skipStepsMap['dataTypeConfiguration'] &&
                        !data.startWithDataSource &&
                        data.createMode
                    "
                    ng-hide="data.quickCreateForExternal || data.idOnlyMode"
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg"
                        ng-click="data.visitedStepsState['dataTypeConfiguration'] && toggleStep('dataTypeConfiguration')"
                        ng-class="{ pointer: data.visitedStepsState['dataTypeConfiguration'] }"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light">
                            Where should the data for this
                            {{
                                data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'
                                    ? 'field'
                                    : 'global field'
                            }}
                            come from?
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['dataTypeConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <!-- Data types options -->
                    <div>
                        <!-- All data sources -->
                        <div
                            class="
                                common-hover
                                rounded-box
                                mod-no-border
                                padding-normal-sm
                                flex-vmiddle
                                pointer
                                margin-right-xs margin-bottom-xs
                                common-no-outline
                            "
                            ng-class="{
                                'data-source-selected-option': data.selectedDataTypeObject.id === dataSourceType
                            }"
                            ng-click="selectDataSource(dataSourceObject, true)"
                            data-automation="field-definition-configuration-modal-data-source"
                            ng-if="
                                dataSourceObject.targetTypes[data.previewRequestParameters.fieldDefinitionTargetType] &&
                                isAllowedFieldDefinitionDataType(dataSourceType)
                            "
                            ng-repeat="(dataSourceType, dataSourceObject) in data.fieldDefinitionDataTypes"
                        >
                            <i
                                class="margin-right-lg fa"
                                ng-class="
                                    data.selectedDataTypeObject.id === dataSourceType
                                        ? 'fa-solid fa-circle common-color-primary'
                                        : 'fa-regular fa-circle common-color-grey'
                                "
                            ></i>
                            <i class="svg-icon-nav svg-icon-align-text-top margin-right-lg" ng-switch="dataSourceType">
                                <span ng-switch-when="dataSource">
                                    <tnk-icon src="/images/icons/plug-o.svg"></tnk-icon>
                                </span>
                                <span ng-switch-when="formula">
                                    <tnk-icon src="/images/icons/calculator.svg"></tnk-icon>
                                </span>
                                <span ng-switch-default>
                                    <tnk-icon src="/images/icons/text-input.svg"></tnk-icon>
                                </span>
                            </i>
                            <div>
                                <div>{{ dataSourceObject.name }}</div>
                                <div class="common-color-grey">
                                    {{
                                        data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'
                                            ? dataSourceObject.displayName.column
                                            : dataSourceObject.displayName.global
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Next button -->
                    <button
                        class="btn btn-primary margin-top-lg"
                        data-automation="field-definition-configuration-modal-next-button"
                        type="button"
                        ng-disabled="!data.selectedDataTypeObject"
                        ng-click="proceedFromDataTypeSelectionStep()"
                    >
                        Next
                    </button>
                </div>

                <!-- Integration configuration upper separator -->
                <hr
                    class="margin-none"
                    ng-if="
                        !data.skipStepsMap['integrationConfiguration'] && !data.stepsState['integrationConfiguration']
                    "
                />

                <!-- Integration configuration title -->
                <div
                    class="padding-normal step-header"
                    ng-click="(!data.createMode || data.visitedStepsState['integrationConfiguration']) && toggleStep('integrationConfiguration')"
                    ng-class="{
                        pointer: !data.createMode || data.visitedStepsState['integrationConfiguration'],
                        'mod-hover': !data.createMode
                    }"
                    ng-if="
                        !data.stepsState['integrationConfiguration'] &&
                        !data.skipStepsMap['integrationConfiguration'] &&
                        data.createMode
                    "
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                        >
                            Data Source
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['integrationConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="common-color-grey step-edit-annotation">Edit</div>
                    </div>

                    <!-- Subtitle -->
                    <div class="flex-vmiddle margin-right-lg">
                        <i
                            class="initiative-integration-icon mod-circle mod-sm mod-dropdown inline-block"
                            ng-class="
                                !data.projectIntegration.iconUrl
                                    ? 'mod-' + data.projectIntegration.integration.integrationType.toLowerCase()
                                    : ''
                            "
                            ng-style="{
                                background: data.projectIntegration.iconUrl
                                    ? 'url(' + data.projectIntegration.iconUrl + ') no-repeat center'
                                    : null
                            }"
                        ></i>
                        <span class="common-size-xxxs">{{ data.projectIntegration.displayNameFull }}</span>
                    </div>
                </div>

                <!-- Integration configuration -->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-integrationConfiguration"
                    ng-if="
                        data.stepsState['integrationConfiguration'] &&
                        !data.skipStepsMaponDefinitionChange['integrationConfiguration']
                    "
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg"
                        ng-click="data.visitedStepsState['integrationConfiguration'] && toggleStep('integrationConfiguration')"
                        ng-class="{ pointer: data.visitedStepsState['integrationConfiguration'] }"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light">Select Data Source</div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['integrationConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <div style="height: 400px; overflow: auto">
                        <input
                            type="text"
                            style="width: 95%"
                            class="form-control margin-bottom-lg ng-pristine ng-empty ng-touched"
                            ng-model="data.dataSourceFilter"
                            placeholder="Search for a data source.."
                        />
                        <tnk-project-integrations-view
                            on-click-connected-data-source="(selectProvider)"
                            view-type="'LIST'"
                            filter-by-text="data.dataSourceFilter"
                            filter-integrations-with-no-entities="true"
                            is-list-filter-by-solution-access="true"
                            should-block-on-click-of-inaccessible="true"
                        ></tnk-project-integrations-view>
                    </div>
                </div>

                <!-- Field definition upper separator -->
                <hr
                    class="margin-none"
                    ng-if="
                        !data.stepsState['fieldDefinitionConfiguration'] &&
                        !data.skipStepsMap['fieldDefinitionConfiguration'] &&
                        !data.integrationConfigurationStillInProgress
                    "
                />

                <!-- Field definition configuration title -->
                <div
                    class="padding-normal step-header"
                    ng-class="{
                        pointer: !data.createMode || data.visitedStepsState['fieldDefinitionConfiguration'],
                        'mod-hover': !data.createMode
                    }"
                    ng-click="(!data.createMode || data.visitedStepsState['fieldDefinitionConfiguration']) && toggleStep('fieldDefinitionConfiguration')"
                    ng-if="
                        !data.stepsState['fieldDefinitionConfiguration'] &&
                        !data.skipStepsMap['fieldDefinitionConfiguration'] &&
                        !data.integrationConfigurationStillInProgress
                    "
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                            data-automation="field-definition-configuration-modal-data-settings-section"
                        >
                            Data Settings
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['fieldDefinitionConfiguration']"
                            data-automation="field-definition-configuration-modal-check-icon-data-setting-section"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="common-color-grey step-edit-annotation">Edit</div>
                    </div>
                    <!-- Subtitle -->
                    <div class="common-size-xxxs">
                        {{ data.fieldConfigurationSummarySubTitle || data.fieldConfigurationSummaryTitle }}
                    </div>
                </div>

                <!-- Field definition configuration -->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-fieldDefinitionConfiguration"
                    ng-show="
                        data.stepsState['fieldDefinitionConfiguration'] &&
                        !data.skipStepsMap['fieldDefinitionConfiguration'] &&
                        !data.integrationConfigurationStillInProgress
                    "
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg flex-xs-wrap"
                        ng-click="data.visitedStepsState['fieldDefinitionConfiguration'] && toggleStep('fieldDefinitionConfiguration')"
                        ng-class="{ pointer: data.visitedStepsState['fieldDefinitionConfiguration'] }"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light flex-no-shrink margin-right-lg flex-grow">
                            {{ data.fieldConfigurationSummaryTitle || 'Data Settings' }}
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['fieldDefinitionTypeConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <div style="min-height: 150px">
                        <!-- Field definition configuration -->
                        <div ng-switch="data.previewRequestParameters.fieldDefinitionType">
                            <!-- External -->
                            <tnk-integration-external-field
                                ng-if="!!data.projectIntegration"
                                ng-switch-when="EXTERNAL"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                selected-entity="data.selectedEntity"
                                id-only-mode="data.idOnlyMode"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                                on-change="markAsChanged()"
                            ></tnk-integration-external-field>

                            <tnk-match-tonkean-item-field
                                existing-field-definition="data.existingFieldDefinition"
                                group-id="data.groupId"
                                ng-switch-when="MATCH_TONKEAN_ITEM"
                                on-change="markAsChanged()"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                opened-from-custom-trigger-id="data.openedFromCustomTriggerId"
                                target-workflow-version-id="data.matchedEntityFromWorkflowVersionId"
                                workflow-version-id="data.workflowVersionId"
                                view-only="!data.createMode"
                            ></tnk-match-tonkean-item-field>

                            <!-- Aggregate query field -->
                            <tnk-integration-aggregate-field
                                ng-switch-when="AGGREGATE_QUERY"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                is-worker-mode="data.isWorkerMode"
                                aggregated-entity="data.selectedEntity"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                                on-change="(markAsChanged)"
                            ></tnk-integration-aggregate-field>

                            <!-- Manual field -->
                            <tnk-manual-field
                                ng-switch-when="MANUAL"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                manual-value="{{ data.manualValue }}"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                                on-change="markAsChanged()"
                            ></tnk-manual-field>

                            <!-- Aggregate on column field -->
                            <tnk-aggregate-on-column-field
                                ng-switch-when="TNK_COLUMN_AGGREGATE"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                aggregation-on-column-feature-name="AGGREGATE_ON_COLUMN"
                                column-formula-feature-name="COLUMN_FORMULA"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                                should-show-checkbox-for-inner-items-hirerachy="false"
                                on-change="(markAsChanged)"
                            ></tnk-aggregate-on-column-field>

                            <!-- Column formula -->
                            <tnk-column-formula-field
                                ng-switch-when="TNK_COLUMN_FORMULA"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                override-formula-operator="data.overrideFormulaOperator"
                                aggregation-on-column-feature-name="AGGREGATE_ON_COLUMN"
                                column-formula-feature-name="COLUMN_FORMULA"
                                formula-expression-tree="data.formulaExpressionTree"
                                existing-field-definition="data.existingFieldDefinition"
                                on-select-inner-track-aggregation="(onSelectInnerTrackAggregation)"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                                on-change="markAsChanged()"
                                workflow-version-type="data.workflowVersion.workflowVersionType"
                            ></tnk-column-formula-field>

                            <!-- SQL field -->
                            <tnk-integration-sql-field
                                ng-switch-when="SQL"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                            ></tnk-integration-sql-field>

                            <!-- Google field -->
                            <tnk-integration-google-field
                                ng-switch-when="GOOGLE"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                            ></tnk-integration-google-field>

                            <!-- Jira count field -->
                            <tnk-integration-jira-count-field
                                ng-switch-when="JIRA_COUNT_FILTER"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                            ></tnk-integration-jira-count-field>

                            <!-- Smartsheet field -->
                            <tnk-integration-smartsheet-field
                                ng-switch-when="SMARTSHEET"
                                project-integration="data.projectIntegration"
                                group-id="data.groupId"
                                target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                                create-mode="data.createMode"
                                duplicate-mode="data.duplicateMode"
                                field-definition-name="data.previewRequestParameters.name"
                                field-definition-name-edited="data.fieldDefinitionNameEdited"
                                existing-field-definition="data.existingFieldDefinition"
                                on-definition-change="
                                    onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
                                "
                                on-field-definition-name-change="onFieldDefinitionNameChange(fieldDefinitionName)"
                            ></tnk-integration-smartsheet-field>
                        </div>
                    </div>
                    <div>
                        <!-- Preview link -->
                        <a
                            class="common-link-blue block common-bold margin-top hidden-xs"
                            ng-click="reloadPreview(false)"
                            ng-if="
                                data.manualPreviewFieldDefinitionTypes[
                                    data.previewRequestParameters.fieldDefinitionType
                                ]
                            "
                        >
                            Preview
                        </a>

                        <!-- Next button -->
                        <button
                            class="btn btn-primary margin-top-lg"
                            data-automation="field-definition-configuration-modal-next-button"
                            type="button"
                            ng-click="proceedFromFieldDefinitionConfiguration()"
                            ng-disabled="!data.previewRequestParameters.validDefinition"
                        >
                            Next
                        </button>
                    </div>
                </div>

                <!-- Display configuration separator -->
                <hr
                    class="margin-none"
                    ng-if="!data.stepsState['displayConfiguration'] && !data.skipStepsMap['displayConfiguration']"
                    ng-hide="data.quickCreateForExternal"
                />

                <!-- Display configuration title -->
                <div
                    class="padding-normal step-header"
                    ng-hide="data.quickCreateForExternal"
                    ng-click="(!data.createMode || data.visitedStepsState['displayConfiguration']) && toggleStep('displayConfiguration')"
                    ng-class="{
                        pointer: !data.createMode || data.visitedStepsState['displayConfiguration'],
                        'mod-hover': !data.createMode
                    }"
                    ng-if="!data.stepsState['displayConfiguration'] && !data.skipStepsMap['displayConfiguration']"
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                            data-automation="field-definition-configuration-modal-display-data-section"
                        >
                            Display Settings
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['displayConfiguration']"
                            data-automation="field-definition-configuration-modal-check-icon-display-settings-section"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="common-color-grey step-edit-annotation">Edit</div>
                    </div>

                    <!-- Subtitle -->
                    <div
                        ng-if="data.previewRequestParameters.fieldDefinitionType !== 'MANUAL'"
                        class="common-size-xxxs"
                    >
                        {{ data.displaySummary }}
                    </div>
                </div>

                <!-- Display configuration -->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-displayConfiguration"
                    ng-if="
                        data.stepsState['displayConfiguration'] &&
                        !data.skipStepsMap['displayConfiguration'] &&
                        data.previewRequestParameters.definition.aggregationType !== 'Terms'
                    "
                    ng-hide="data.quickCreateForExternal"
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg"
                        ng-click="data.visitedStepsState['displayConfiguration'] && toggleStep('displayConfiguration')"
                        ng-class="{ pointer: data.visitedStepsState['displayConfiguration'] }"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light">Display Settings</div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['displayConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <!-- Display configuration component -->
                    <tnk-display-configuration
                        field-definition-type="data.previewRequestParameters.fieldDefinitionType"
                        field-type="data.previewRequestParameters.fieldType"
                        display-as="data.previewRequestParameters.displayAs"
                        number-field-decimal-places="data.previewRequestParameters.numberFieldDecimalPlaces"
                        evaluated-display-type="data.previewRequestParameters.evaluatedDisplayType"
                        display-format="data.previewRequestParameters.displayFormat"
                        display-format-prefix="data.previewRequestParameters.displayFormatPrefix"
                        display-format-postfix="data.previewRequestParameters.displayFormatPostfix"
                        compare-timeframe="data.previewRequestParameters.compareTimeframe"
                        is-increment-negative="data.previewRequestParameters.isIncrementNegative"
                        decimal-places="data.previewRequestParameters.decimalPlaces"
                        include-trailing-zeros="data.previewRequestParameters.includeTrailingZeros"
                        is-multi-value-field="data.previewRequestParameters.isMultiValueField"
                        output-multi-value-separator="data.previewRequestParameters.outputMultiValueSeparator"
                        target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                        on-display-configuration-change="
                            onDisplayConfigurationChange(displayConfigurationObject, didDataChange)
                        "
                        project-integration-id="data.projectIntegration.id"
                        field-label="data.previewRequestParameters.fieldLabel"
                        is-from-matched-items="data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem"
                    ></tnk-display-configuration>

                    <!-- Next button -->
                    <button
                        class="btn btn-primary margin-top-lg"
                        data-automation="field-definition-configuration-modal-next-button"
                        type="button"
                        ng-click="moveFromToStep('displayConfiguration', 'rangesConfiguration'); setStepVisited('rangesConfiguration')"
                    >
                        Next
                    </button>
                </div>

                <!-- Ranges configuration separator -->
                <hr
                    class="margin-none"
                    ng-if="!data.stepsState['rangesConfiguration'] && !data.skipStepsMap['rangesConfiguration']"
                    ng-hide="data.quickCreateForExternal"
                />

                <!-- Ranges configuration title -->
                <div
                    ng-if="!data.stepsState['rangesConfiguration'] && !data.skipStepsMap['rangesConfiguration']"
                    ng-hide="data.quickCreateForExternal"
                    ng-click="onRangesConfigurationStepHeaderClick()"
                    ng-class="{
                        pointer:
                            !data.createMode ||
                            data.visitedStepsState['rangesConfiguration'] ||
                            data.previewRequestParameters.validDefinition,
                        'mod-hover': !data.createMode
                    }"
                    class="step-header padding-normal"
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                            data-automation="field-definition-configuration-modal-display-conditions-section"
                        >
                            Display Conditions & Follow-Ups
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['rangesConfiguration']"
                            data-automation="field-definition-configuration-modal-check-icon-display-condition-section"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="step-edit-annotation">Edit</div>
                    </div>
                    <!-- Subtitle -->
                    <div class="common-size-xxxs" ng-if="!data.createMode">
                        {{ data.previewRequestParameters.ranges.length || 0 }} conditions
                    </div>
                </div>

                <!-- Ranges configuration -->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-rangesConfiguration"
                    ng-hide="data.quickCreateForExternal"
                    ng-if="data.stepsState['rangesConfiguration']"
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg pointer"
                        ng-click="toggleStep('rangesConfiguration')"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light">Display Conditions & Follow-Ups</div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['rangesConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <!-- Ranges configuration component -->
                    <tnk-ranges-configuration
                        project-integration="data.projectIntegration"
                        target-caption="{{
                            data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'
                                ? 'Column'
                                : 'Key metric'
                        }}"
                        ranges="data.previewRequestParameters.ranges"
                        is-important="data.previewRequestParameters.isImportant"
                        field-type="data.previewRequestParameters.fieldType"
                        field-target-type="data.previewRequestParameters.fieldDefinitionTargetType"
                        possible-values="data.previewRequestParameters.possibleValues"
                        on-ranges-change="onRangesChange(ranges, isImportant, didChange)"
                    ></tnk-ranges-configuration>

                    <!-- Next button -->
                    <button
                        class="btn btn-primary margin-top-lg"
                        data-automation="field-definition-configuration-modal-next-button"
                        type="button"
                        ng-click="proceedFromRangeConfigurationStep()"
                    >
                        Next
                    </button>
                </div>

                <!-- Additional Information separator -->
                <hr
                    class="margin-none"
                    ng-if="
                        !data.stepsState['additionalInformationConfiguration'] &&
                        !data.skipStepsMap['additionalInformationConfiguration']
                    "
                    ng-hide="data.quickCreateForExternal"
                />

                <!-- Additional Information title -->
                <div
                    ng-if="
                        !data.stepsState['additionalInformationConfiguration'] &&
                        !data.skipStepsMap['additionalInformationConfiguration']
                    "
                    ng-hide="data.quickCreateForExternal"
                    ng-click="onAdditionalInformationConfigurationStepHeaderClick()"
                    ng-class="{
                        pointer:
                            !data.createMode ||
                            data.visitedStepsState['additionalInformationConfiguration'] ||
                            data.previewRequestParameters.validDefinition,
                        'mod-hover': !data.createMode
                    }"
                    class="step-header padding-normal"
                >
                    <div class="flex-vmiddle mod-justify-space">
                        <!-- Title -->
                        <div
                            class="common-title-xxs step-outer-title"
                            ng-class="{ 'mod-light mod-grey': data.createMode, 'common-bold': !data.createMode }"
                            data-automation="field-definition-configuration-modal-additional-information-section"
                        >
                            Additional Information
                        </div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['additionalInformationConfiguration']"
                            data-automation="field-definition-configuration-modal-check-icon-additional-information-section"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                        <!-- Edit icon -->
                        <div ng-if="!data.createMode" class="step-edit-annotation">Edit</div>
                    </div>
                </div>

                <!-- Additional Information configuration -->
                <div
                    ng-class="{
                        pointer:
                            data.workflowVersion.workflowVersionType !== 'DRAFT' && !data.disabledEnvironments.build
                    }"
                    class="rounded-white-square padding-normal"
                    id="section-additionalInformationConfiguration"
                    ng-hide="data.quickCreateForExternal"
                    ng-if="data.stepsState['additionalInformationConfiguration']"
                    blocked-element="{{ data.workflowVersion.workflowVersionType !== 'DRAFT' }}"
                    data-blocked-message-production
                    ng-click="changeEnvironmentToBuildFromDisabledElement()"
                >
                    <!-- Title & Check sign -->
                    <div
                        class="flex-vmiddle mod-justify-space margin-bottom-lg pointer"
                        ng-click="toggleStep('additionalInformationConfiguration')"
                    >
                        <!-- Title -->
                        <div class="common-title-xxs mod-light">Additional Information</div>

                        <!-- Check icon -->
                        <div
                            class="svg-icon-xs common-color-success svg-icon-vmiddle"
                            ng-if="data.createMode && data.visitedStepsState['additionalInformationConfiguration']"
                        >
                            <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr class="margin-top-none margin-bottom" />

                    <!-- Description -->
                    <div class="flex-vmiddle flex-xs-wrap">
                        <!-- Title -->
                        <div class="common-size-xxs margin-right-xs margin-bottom-xs">
                            {{
                                data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'
                                    ? 'Field'
                                    : 'Global Field '
                            }}
                            description:
                        </div>

                        <!-- Name input -->
                        <textarea
                            class="
                                form-control
                                tnk-message-textbox-textarea
                                common-resize-y-only
                                margin-bottom-xs
                                common-width-75
                            "
                            id="editFieldDescriptionInput"
                            maxlength="1000"
                            ng-required="required"
                            autofocus
                            ng-model="data.previewRequestParameters.description"
                            ng-change="markAsChanged()"
                        ></textarea>
                    </div>

                    <!-- Field Group Name -->
                    <div class="flex-vmiddle flex-xs-wrap">
                        <!-- Title -->
                        <div class="flex-no-shrink common-size-xxs margin-right-16 margin-bottom-xs">Field group:</div>

                        <!-- Group Name Input -->
                        <input
                            class="form-control margin-bottom-xs margin-left-lg common-width-75"
                            ng-model="data.previewRequestParameters.fieldGroupName"
                            ng-change="markAsChanged()"
                            placeholder="Enter field group name"
                            uib-typeahead="item for item in searchGroupName($viewValue) | limitTo:6"
                            typeahead-append-to-body="appendToBody"
                            typeahead-no-results="noResults"
                        />
                    </div>

                    <!-- Update Field Permissions -->
                    <div
                        class="flex flex-xs-wrap flex-direction-column"
                        ng-if="
                            data.originalFieldDefinitionType === 'MANUAL' ||
                            data.previewRequestParameters.fieldDefinitionType === 'MANUAL'
                        "
                    >
                        <!-- Title -->
                        <div class="common-size-xxs margin-top margin-right-xs margin-bottom-xs">Who can update (in addition to Tonkean system updates):
                        </div>

                        <!-- Selection -->
                        <div class="common-normal-weight margin-top-xs margin-left-lg">
                            <!-- Only admins -->
                            <div class="margin-top-xs">
                                <tnk-radio-button
                                    radio-id="only-admins-{{ data.workflowVersionId }}"
                                    radio-name="permissions-selection-{{ data.workflowVersionId }}"
                                    radio-value="LIST_OWNER"
                                    model="data.updateFieldPermissionsType"
                                    on-click="onUpdateFieldPermissionsTypeSelected(onClickParam)"
                                    on-click-param="('LIST_OWNER')"
                                    is-required="true"
                                >
                                    <div>Only admins</div>
                                </tnk-radio-button>
                            </div>

                            <!-- Only item owners -->
                            <div class="margin-top-xs">
                                <tnk-radio-button
                                    radio-id="only-track-owners-{{ data.workflowVersionId }}"
                                    radio-name="permissions-selection-{{ data.workflowVersionId }}"
                                    radio-value="TRACK_OWNERS"
                                    model="data.updateFieldPermissionsType"
                                    on-click="onUpdateFieldPermissionsTypeSelected(onClickParam)"
                                    on-click-param="('TRACK_OWNERS')"
                                    is-required="true"
                                >
                                    <div>Only item owners</div>
                                </tnk-radio-button>
                            </div>

                            <!-- Match -->
                            <div class="margin-top-xs">
                                <tnk-radio-button
                                    radio-id="any-collaborator-{{ data.workflowVersionId }}"
                                    radio-name="permissions-selection-{{ data.workflowVersionId }}"
                                    radio-value="ANY_COLLABORATOR"
                                    model="data.updateFieldPermissionsType"
                                    on-click="onUpdateFieldPermissionsTypeSelected(onClickParam)"
                                    on-click-param="('ANY_COLLABORATOR')"
                                    is-required="true"
                                >
                                    <div>Any collaborator</div>
                                </tnk-radio-button>
                            </div>

                            <!-- Only item creators -->
                            <div class="margin-top-xs">
                                <tnk-radio-button
                                    radio-id="only-track-creator-{{ data.workflowVersionId }}"
                                    radio-name="permissions-selection-{{ data.workflowVersionId }}"
                                    radio-value="TRACK_CREATOR"
                                    model="data.updateFieldPermissionsType"
                                    on-click="onUpdateFieldPermissionsTypeSelected(onClickParam)"
                                    on-click-param="('TRACK_CREATOR')"
                                    is-required="true"
                                >
                                    <div>Only item creator</div>
                                </tnk-radio-button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Separator -->
                <hr class="margin-none" />

                <!-- Delete error -->
                <div class="common-color-danger common-size-xxxs margin-top" ng-if="data.errorDeletingFieldDefinition">
                    {{ data.errorDeletingFieldDefinition }}
                </div>

                <!-- Error message -->
                <div class="common-size-xxxs common-color-danger margin-top-xs">
                    {{ data.errorCreatingOrUpdatingFieldDefinition }}
                </div>
            </div>
        </div>

        <!-- Right pane -->
        <div
            class="flex-grow right-pane hidden-xs"
            ng-class="{ 'mod-dark-white-background': !data.createMode }"
            ng-if="showField"
        >
            <!-- Preview List -->
            <tnk-preview-list-columns
                ng-if="data.previewRequestParameters.fieldDefinitionTargetType === 'COLUMN'"
                class="preview-list-columns"
                ng-class="{ 'mod-dark-white': !data.createMode }"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                existing-field-definition-id="data.existingFieldDefinition.id"
                definitions-to-preview="data.allDefinitionsToPreview"
                highlighted-field-definitions="data.highlightedFieldDefinitions"
                global-fields-to-preview="data.globalFieldsToPreview"
                highlighted-definition-id="{{ data.previewRequestParameters.definitionId }}"
                reload-preview="data.reloadPreview"
                soft-loading="data.softLoading"
                is-broken-integration="data.existingFieldDefinition.isBrokenIntegration || (data.projectIntegration && !data.projectIntegration.valid)"
            ></tnk-preview-list-columns>

            <!-- Preview Key Metric -->
            <div
                class="key-metric-preview-container"
                ng-if="data.previewRequestParameters.fieldDefinitionTargetType === 'GLOBAL'"
            >
                <tnk-preview-key-metrics
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    definitions-to-preview="data.allDefinitionsToPreview"
                    initiative-definitions-to-preview="data.initiativeDefinitionsToPreview"
                    highlighted-definition-id="{{ data.previewRequestParameters.definitionId }}"
                    show-tracks-loading="data.previewRequestParameters.fieldDefinitionType === 'TNK_COLUMN_AGGREGATE'"
                    reload-preview="data.reloadPreview"
                    soft-loading="data.softLoading"
                    is-broken-integration="data.existingFieldDefinition.isBrokenIntegration || (data.projectIntegration && !data.projectIntegration.valid)"
                ></tnk-preview-key-metrics>
            </div>
        </div>
        </div>

    <div class="flex mod-justify-center" ng-if="data.matchedEntityRelationsLoading">
        <span class="loading-large"></span>
    </div>
</form>
