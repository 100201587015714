import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType } from '@tonkean/tonkean-entities';

const formAnsweredEndSequenceTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.SEND_FORM_ANSWERED;
    params.displayName = 'End Sequence';
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerActions = [
        {
            type: CustomTriggerType.SEND_FORM_ANSWERED,
            customTriggerActionDefinition: {
                replyOnOriginalMessage: true,
                selectedButtonType: 'continue-flow',
            },
        },
    ];
};

export default formAnsweredEndSequenceTriggerParamsEnricher;
