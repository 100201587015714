import FunctionOperatorBase from './FunctionOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import type { FormulaAllowedParams } from '@tonkean/tonkean-entities';

export default class GenerateSequenceIdFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.GENERATE_SEQUENCE_ID;
    override readonly sign = 'GenerateSequenceId';
    override readonly displayName = 'Generate Sequence Id';
    override readonly description = 'Will generate a sequence id for the specific item.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Scope',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];

    override isAllowed(params: FormulaAllowedParams): boolean {
        return !params.customTrigger;
    }
}
