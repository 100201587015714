import AdobeSignAuthenticationComponent from './AdobeSignAuthenticationComponent';
import adobeSignIcon from '../../../../apps/tracks/images/adobesign.png';
import adobeSignCircleIcon from '../../../../apps/tracks/images/integrations/adobesign-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class AdobeSignIntegration extends IntegrationDefinitionBase {
    override name = 'adobesign';
    override displayName = 'Adobe Sign';
    override description = 'We will only collect templates and agreements items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Template', 'Templates'),
        new IntegrationEntity('Agreement', 'Agreements'),
    ];
    override customizedAuthenticationComponent = AdobeSignAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(adobeSignCircleIcon, '130px'),
        new IntegrationIcon(adobeSignIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Template: true, Agreement: true },
        isOnlyDataRetention: {},
    };
}

export default AdobeSignIntegration;
