import { useAngularService, useAngularWatch } from 'angulareact';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { SearchInitiativesQuery, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

function useInitiativeNavigation(
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    setInitiativeId: React.Dispatch<React.SetStateAction<TonkeanId<TonkeanType.INITIATIVE> | undefined>>,
) {
    const projectManager = useAngularService('projectManager');
    const $location = useAngularService('$location');

    const [project] = useAngularWatch(() => projectManager.project);
    const [urlInitiativeId] = useAngularWatch(() => $location.search().tid);
    const [encodedSearchInitiativesQuery] = useAngularWatch(() => $location.search().searchInitiativesQuery);

    const [firstInitiativeId, setFirstInitiativeId] = useState<TonkeanId<TonkeanType.INITIATIVE>>();
    const [position, setPosition] = useState<number>(0);

    useEffect(() => {
        setInitiativeId?.(urlInitiativeId);
    }, [urlInitiativeId, setInitiativeId]);

    // Update the first initiative ID to fetch the initial position
    useEffect(() => {
        if (!!(initiativeId || urlInitiativeId) && !firstInitiativeId) {
            setFirstInitiativeId(initiativeId || urlInitiativeId);
        }
    }, [initiativeId, urlInitiativeId, firstInitiativeId]);

    const searchInitiativesQuery = useMemo(() => {
        if (encodedSearchInitiativesQuery) {
            const decodedQuery = JSON.parse(decodeURIComponent(atob(decodeURI(encodedSearchInitiativesQuery))));

            // Check that the given string is of the expected interface
            if (decodedQuery !== undefined && typeof decodedQuery === 'object') {
                return decodedQuery as SearchInitiativesQuery;
            } else {
                return undefined;
            }
        }

        return undefined;
    }, [encodedSearchInitiativesQuery]);

    const [{ data: searchPositionResponse }, getInitiativePositionInList] =
        useLazyTonkeanService('getInitiativePositionInList');

    useEffect(() => {
        if (firstInitiativeId) {
            getInitiativePositionInList(project.id, firstInitiativeId, searchInitiativesQuery);
        }
    }, [firstInitiativeId, project.id, searchInitiativesQuery, getInitiativePositionInList]);

    // Set position from fetched data
    useEffect(() => {
        if (searchPositionResponse) {
            setPosition(searchPositionResponse.position + 1);
        }
    }, [searchPositionResponse]);

    const navigateToNextInitiativeId = useCallback(() => {
        if (position === searchPositionResponse?.totalHits - 1) {
            return;
        }

        const nextInitiative = searchPositionResponse.items?.[position + 1].id;
        $location.search('tid', nextInitiative);
        setInitiativeId(nextInitiative);
        setPosition(position + 1);
    }, [position, searchPositionResponse?.totalHits, searchPositionResponse?.items, $location, setInitiativeId]);

    const navigateToPreviousInitiativeId = useCallback(() => {
        const previousInitiative = searchPositionResponse.items?.[position - 1].id;
        $location.search('tid', previousInitiative);
        setInitiativeId(previousInitiative);
    }, [position, searchPositionResponse?.items, $location, setInitiativeId]);

    // If initiative ID is changed to other than next/prev (e.g. back button), find the position again from fetched data
    useEffect(() => {
        if (initiativeId && searchPositionResponse) {
            const initiativeIndex = searchPositionResponse?.items.findIndex(
                (initiative) => initiative.id === initiativeId,
            );

            setPosition(initiativeIndex);
        }
    }, [initiativeId, searchPositionResponse]);

    // Reset when initiative id is empty (for example, when modal closes)
    useEffect(() => {
        if (!(initiativeId || urlInitiativeId)) {
            setInitiativeId(undefined);
            setFirstInitiativeId(undefined);
            setPosition(-1);
        }
    }, [initiativeId, urlInitiativeId, setInitiativeId]);

    useEffect(() => {
        if (encodedSearchInitiativesQuery === undefined) {
            setPosition(-1);
        }
    }, [encodedSearchInitiativesQuery]);

    return useMemo(() => {
        return {
            navigateToNextInitiativeId,
            navigateToPreviousInitiativeId,
            items: searchPositionResponse?.items,
            currentPosition: (position || 0) + 1,
            total: searchPositionResponse?.totalHits,
        };
    }, [
        navigateToNextInitiativeId,
        navigateToPreviousInitiativeId,
        position,
        searchPositionResponse?.items,
        searchPositionResponse?.totalHits,
    ]);
}

export default useInitiativeNavigation;
