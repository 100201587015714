/**
 * Helps querying the communication integrations we handle in the system for features, etc.
 */
function CommunicationIntegrationsService(
    projectManager,
    utils,
) {
    /**
     * PLEASE NOTE: All of the functions here should be treated as if they will be called from within a digest loop,
     * so they should be super lightweight and not run any complicated calculations.
     */

    const _this = this;
    let integrationTypes;
    let integrationTypesLowerCase;
    let integrationTypesDisplayNames;

    _this.features = {
        channels: 'channels',
        notifications: 'notifications',
        multiDirectMessage: 'multiDirectMessage',
        privateChannels: 'privateChannels',
    };

    const featuresMap = {
        SLACK: {
            displayName: 'Slack',
            shortName: 'Slack',
            channels: true,
            notifications: true,
            multiDirectMessage: true,
            privateChannels: true,
        },
        MICROSOFT_TEAMS: {
            displayName: 'Microsoft Teams',
            shortName: 'MS Teams',
            channels: true,
            notifications: true,
            multiDirectMessage: true,
            privateChannels: false,
        },
        GOOGLECHAT: {
            displayName: 'Google Chat',
            shortName: 'GChat',
            channels: true,
            notifications: true,
            multiDirectMessage: true,
            privateChannels: false,
        },
    };

    _this.integrations = {
        SLACK: {
            channelLabel: 'channel',
            privateChannelErrorAction: null,
        },
        MICROSOFT_TEAMS: {
            channelLabel: 'channel',
        },
        GOOGLECHAT: {
            channelLabel: 'room',
        },
    };

    /**
     * Checks if there's any communication integration connected, that supports the given feature name.
     * @returns {boolean}
     */
    _this.anyIntegrationSupports = function (featureName) {
        return !!_this.getFirstThatSupports(featureName);
    };

    /**
     * Returns a string with the integration names to be used in a sentence,
     * e.g Slack, Slack and Teams, etc.
     */
    _this.getIntegrationsNames = function () {
        if (!_this.anyIntegrationsConnected()) {
            return null;
        }

        let name = '';

        // Since we don't have more than 2 communication integrations today, this for loop is very fast.
        for (let i = 0; i < projectManager.project.communicationIntegrations.length; i++) {
            const communicationIntegration = projectManager.project.communicationIntegrations[i];

            name += communicationIntegration.displayName;

            if (i > 0 && i < projectManager.project.communicationIntegrations.length - 2) {
                name += ', ';
            } else if (i === projectManager.project.communicationIntegrations.length - 2) {
                name += ' and ';
            }
        }

        return name;
    };

    /**
     * Returns an array of the communication integrations types upper-cased.
     * @param toLowerCase - if true, all types are lower-cased before returned.
     */
    _this.getIntegrationTypes = function (toLowerCase) {
        // We only run utils.objKeys once when we're first called.
        // Then we save it locally and re-use it since it's not something that can change in run-time.
        if (!integrationTypes || !integrationTypes.length) {
            integrationTypes = utils.objKeys(featuresMap);
        }

        if (toLowerCase) {
            // We only run the map function once when we're first called.
            // Then we save it locally and re-use it since it's not something that can change in run-time.
            if (!integrationTypesLowerCase || !integrationTypesLowerCase.length) {
                integrationTypesLowerCase = integrationTypes.map((type) => type.toLowerCase());
            }
            return integrationTypesLowerCase;
        }

        return integrationTypes;
    };

    /**
     * Returns array with the possible communication integration's display names.
     * @returns {*}
     */
    _this.getIntegrationTypesShortNames = function () {
        // We only run utils.objKeys once when we're first called.
        // Then we save it locally and re-use it since it's not something that can change in run-time.
        if (!integrationTypesDisplayNames || !integrationTypesDisplayNames.length) {
            integrationTypesDisplayNames = utils.objValues(featuresMap).map((feature) => feature.shortName);
        }

        return integrationTypesDisplayNames;
    };

    /**
     * Returns the first communication integration connected, or null if nothing is connected.
     * @returns {*}
     */
    _this.getFirstIntegration = function () {
        if (!_this.anyIntegrationsConnected()) {
            return null;
        }

        return projectManager.project.communicationIntegrations[0];
    };

    /**
     * Returns communication integration by id, or undefined if nothing is matched.
     * @returns {*}
     */
    _this.getIntegrationById = function (integrationId) {
        return projectManager.project.communicationIntegrations.find((integration) => integration.id === integrationId);
    };

    /**
     * Returns the first communication integration found that support the given feature, or null if none found.
     */
    _this.getFirstThatSupports = function (featureName) {
        if (!featureName || !_this.features[featureName] || !_this.anyIntegrationsConnected()) {
            return null;
        }

        // Since we don't have more than 2 communication integrations today, this for loop is very fast.
        for (let i = 0; i < projectManager.project.communicationIntegrations.length; i++) {
            const communicationIntegration = projectManager.project.communicationIntegrations[i];

            if (supportsFeature(communicationIntegration.integrationType, featureName)) {
                return communicationIntegration;
            }
        }

        return null;
    };

    /**
     * Runs getFirstThatSupports and returns it's name + the channel label, ready to use in a sentence.
     * Returns "Slack channel" if can't find the requested feature.
     * @returns {string}
     */
    _this.getFirstThatSupportsLabel = function (featureName) {
        const firstThatSupports = _this.getFirstThatSupports(featureName);

        if (!firstThatSupports) {
            return 'Slack channel';
        }

        return `${firstThatSupports.displayName} ${_this.integrations[firstThatSupports.integrationType].channelLabel}`;
    };

    /**
     * Checks if any communication integration is connected.
     * @returns {boolean}
     */
    _this.anyIntegrationsConnected = function () {
        if (
            !projectManager.project ||
            !projectManager.project.communicationIntegrations ||
            projectManager.project.communicationIntegrations.length === 0
        ) {
            return false;
        }

        return true;
    };

    /**
     * Checks if the given communication integration name is connected to the project.
     * @param integrationName - The integration name to test. Case in-sensitive.
     * @returns {boolean}
     */
    _this.isIntegrationConnected = function (integrationName) {
        if (!integrationName || !_this.anyIntegrationsConnected()) {
            return false;
        }

        const integrationNameUpper = integrationName.toUpperCase();

        for (let i = 0; i < projectManager.project.communicationIntegrations.length; i++) {
            if (
                projectManager.project.communicationIntegrations[i].integration.integrationType ===
                    integrationNameUpper ||
                projectManager.project.communicationIntegrations[i].integration.integrationUniqueType ===
                    integrationNameUpper
            ) {
                return true;
            }
        }

        return false;
    };

    /**
     * Calculates the number of connected communication integrations.
     * @returns {number}
     */
    _this.getNumberOfConnectedIntegration = function () {
        let numberOfCommunicationIntegrations = 0;

        for (let i = 0; i < projectManager.project.communicationIntegrations.length; i++) {
            if (
                _this.integrations[
                    projectManager.project.communicationIntegrations[i].integration.integrationUniqueType
                ]
            ) {
                numberOfCommunicationIntegrations += 1;
            }
        }

        return numberOfCommunicationIntegrations;
    };

    /**
     * Returns true if this user has a communication integration id.
     * @param user
     * @returns {*}
     */
    _this.doesUserHaveCommunicationIntegration = function (user, projectId) {
        if (!user) {
            return false;
        }

        const projectContext = user.projectContext || (user.projectContexts ? user.projectContexts[projectId] : null);

        return projectContext && projectContext.slackUserId;
    };

    /**
     * Gets the communication integration name for the user.
     * If the user has no bot id (slack or teams) or the user prefers email returns email. Otherwise returns the connected integration name
     * @param user
     * @returns {*}
     */
    _this.getPreferredCommunicationIntegrationNameForUser = function (user) {
        if (!_this.doesUserHaveCommunicationIntegration(user) || user.preferEmail) {
            return 'email';
        }

        return _this.getIntegrationsNames();
    };

    _this.supportsFeature = function (integrationName, featureName) {
        if (!integrationName) {
            return false;
        }
        return supportsFeature(integrationName, featureName);
    };

    /**
     * Checks whether the given integration name supports the given feature name, according to the features map.
     * @returns {boolean}
     */
    function supportsFeature(integrationName, featureName) {
        return featuresMap[integrationName] && featuresMap[integrationName][featureName];
    }

    /**
     * Gets the default communication integration defined in solution.
     * @returns {CommunicationIntegration}
     */
    _this.getDefaultCommunicationIntegration = function () {
        return projectManager.project.defaultCommunicationProjectIntegration;
    };
}

angular.module('tonkean.app').service('communicationIntegrationsService', CommunicationIntegrationsService);
