import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY> | undefined;
    onCategorySelected: (id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>) => void;
    autoSelectFirst?: boolean;
}

const WorkflowFolderCategorySelector: React.FC<Props> = ({
    workflowFolderId,
    workflowFolderCategoryId,
    onCategorySelected,
    autoSelectFirst,
}) => {
    const [projectId] = useGetStateParams<[TonkeanId<TonkeanType.PROJECT>]>('projectId');

    const [innerCategoryId, setInnerCategoryId] = useState(workflowFolderCategoryId);

    const { data: categoriesObject, loading } = useTonkeanService(
        'getWorkflowFolderCategories',
        projectId,
        workflowFolderId,
    );

    // When the category list is change (when changing the workflow folder) we setting the category to first category
    useEffect(() => {
        if (!autoSelectFirst) {
            return;
        }

        const firstCategory = categoriesObject?.workflowFolderCategories?.[0];

        if (firstCategory?.id) {
            onCategorySelected(firstCategory?.id);
            setInnerCategoryId(firstCategory?.id);
        }
    }, [autoSelectFirst, categoriesObject?.workflowFolderCategories, onCategorySelected]);

    const options: SimpleSelectSingleOption<TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>>[] = useMemo(() => {
        return (
            categoriesObject?.workflowFolderCategories.map((category) => ({
                value: category.id,
                label: category.displayName,
            })) || []
        );
    }, [categoriesObject?.workflowFolderCategories]);

    const onChange = useCallback(
        (id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>) => {
            onCategorySelected(id);
            setInnerCategoryId(id);
        },
        [onCategorySelected],
    );

    return (
        <SimpleSelect
            value={innerCategoryId}
            options={options}
            onChange={onChange}
            isLoading={loading}
            placeholder="Select Section"
        />
    );
};

export default WorkflowFolderCategorySelector;
