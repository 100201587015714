import { analyticsWrapper } from '@tonkean/analytics';
import { getDefaultTonkeanQuery } from '@tonkean/tonkean-utils';
import { tabKeyToWorkerRunTabType } from '../../modules/HistoryModule/entities/WorkerRunLogicInspectTabToComponent';
import { pathParamToWorkerRunStage } from '@tonkean/tonkean-entities';
import { GetItemDetailsForItemDetailsModuleEditorDocument } from '../../modules/ItemDetailsModule/components/ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';
import '../modals/HistoryModal/HistoryModal';
import workerEditorPreviewInitiativesModalTemplate from './workerEditorPreviewInitiativesModal.template.html?angularjs';
import { WORKER_TYPES } from '@tonkean/constants';

const MODULE_EDITOR_STATE_NAME = 'product.workerEditor';

function WorkerEditorCtrl(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $q,
    $timeout,
    utils,
    groupManager,
    groupInfoManager,
    groupPermissions,
    projectManager,
    consts,
    customTriggerManager,
    tonkeanService,
    tonkeanUtils,
    modalUtils,
    modal,
    customFieldsManager,
    trackHelper,
    initiativeManager,
    workflowVersionManager,
    syncConfigCacheManager,
    communicationIntegrationsService,
    workflowFolderManager,
    navigationService,
) {
    let scrollContainer = null;
    const logicConfigModes = consts.getLogicConfigModes();
    $scope.logicBlockTypes = consts.getLogicBlockTypes();

    /** @type {import("@urql/core").Client} */
    const urql = $scope.urql;

    $scope.pm = projectManager;
    $scope.gm = groupPermissions;
    $scope.ctm = customTriggerManager;
    $scope.tonkeanUtils = tonkeanUtils;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.cis = communicationIntegrationsService;
    $scope.$state = $state;
    $scope.utils = utils;
    $scope.ns = navigationService;
    $scope.pathParamToWorkerRunStage = pathParamToWorkerRunStage;
    $scope.tabKeyToWorkerRunTabType = tabKeyToWorkerRunTabType;

    $scope.data = {
        projectId: $stateParams.projectId,
        groupId: $stateParams.g,
        openSyncConfiguration: $stateParams.s,
        isFromList: $stateParams.isFromList, // If true, was brought to this page from the list.
        workerTypes: WORKER_TYPES,

        sideBySideDiffDisplayed: false,

        loadingCustomTriggers: true,

        reloadOutlineConfiguration: false,

        group: null,
        workflowVersion: null,
        workflowVersionId: null,
        workflowVersionType: null,

        hasPermissionsToEditWorker: false,

        validationStates: {
            INVALID: 'INVALID',
            PENDING: 'PENDING',
            VALID: 'VALID',
        },

        // Logic components
        selectedLogic: null, // Do not pass a selected logic object down the tree.
        selectedLogicSyncObject: {},
        syncObject: {
            selectedLogicId: null,
        },
        invalidLogics: {},

        // Toggling worker enabled state
        savingWorkerEnabled: false,
        errorSavingWorkerEnabled: null,

        // Edit worker name
        workerNameEditMode: false,
        editingWorkerNameValue: projectManager.groupsMap[$stateParams.g].name,
        loadingSavingWorkerName: false,
        errorSavingWorkerName: null,

        // Right (data) side-pane.
        selectedSidePaneType: null,
        sidePaneTypes: {
            dataSource: 'dataSource',
            bot: 'bot',
            logic: 'logic',
            dashboard: 'dashboard',
            trigger: 'trigger',
        },

        // Left (workflow outline) sidebar.
        isOutlineSidePaneOpen: !!$stateParams.t,
        selectedOutlineTabKey: $stateParams.t ?? null,
        selectedOutlineFormId: $stateParams.selectedOutlineFormId || null,
        selectedOutlineFormType: $stateParams.selectedOutlineFormId || null,
        formDeletedCallback: null,
        selectedFieldProps: $stateParams.selectedFieldProps || null,
        isDrilldownMode: navigationService.isDrilldownMode || false,
        itemInterfaceId: null,
        processMapperId: null,

        logicConfigMode: logicConfigModes.selectType,
        galleryConfigMode: logicConfigModes.selectType,
        showBackToMarkForPublishButton: false,
        startValidating: false,
        dontClosePreviousTabOnFieldCreation: false,
        zoom: 1,
        fieldEditorHasPendingChanges: false,
        workflowFolderProjectIntegrationsAccess: {
            accessibleProjectIntegrations: [],
            inAccessibleProjectIntegrations: [],
            workflowFolderId: null,
        },
        scheduledDetails: null,
        showGroupDescription: false,
        features: projectManager.project.features,
    };

    /**
     * Initialization function of the controller.
     */
    $scope.init = function () {
        const groupId = $stateParams.g;
        groupInfoManager.getGroup(groupId).then((group) => {
            const goToSolutionsPage = () => {
                $state.go('product.workers', {}, { location: 'replace' });
            };

            // Check if group id is valid / you have write access / are creator or admin (if it's public).
            if (!group) {
                goToSolutionsPage();
                return;
            }

            // Getting the workflow folder id the current group is contained in.
            const containingWorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                projectManager.project.id,
                group.id,
            );

            if (!containingWorkflowFolder.isVisible) {
                goToSolutionsPage();
                return;
            }

            // Set page title to show module
            $rootScope.pageTitle = group.name;

            // Currently, taking the published workflow version as the workflow version of the group.
            $scope.data.group = group;

            $scope.data.hasPermissionsToEditWorker = groupPermissions.hasPermissionsToEditWorker(group);

            let workflowVersionPromise = $q.resolve();

            // If no env specified, will default to draft unless user has no edit permissions
            const requestedVersion = $stateParams.env?.toUpperCase();
            const shouldViewDraftVersion = !requestedVersion || requestedVersion === 'DRAFT';
            if (shouldViewDraftVersion) {
                if (!$scope.data.hasPermissionsToEditWorker) {
                    $state.go(MODULE_EDITOR_STATE_NAME, { env: 'PUBLISHED' }, { location: 'replace' });
                    return;
                }
                $scope.data.workflowVersion = workflowVersionManager.getDraftVersionFromCache(group.id);
                $scope.data.workflowVersionId = $scope.data.workflowVersion.id;
                $scope.data.workflowVersionType = 'DRAFT';

                workflowVersionManager
                    .initializeChangesCounter($scope.data.workflowVersionId, $scope.data.groupId)
                    .then(() => {});
            } else if (requestedVersion === 'PUBLISHED') {
                $scope.data.workflowVersion = workflowVersionManager.getPublishedVersionFromCache(group.id);
                $scope.data.workflowVersionId = $scope.data.workflowVersion.id;
                $scope.data.workflowVersionType = 'PUBLISHED';
            } else {
                const workflowVersionId = $stateParams.env;
                workflowVersionPromise = workflowVersionManager
                    .getCachedWorkflowVersionOrGetFromServer(workflowVersionId)
                    .then((workflowVersion) =>
                        customFieldsManager.getFieldDefinitions(workflowVersionId, true).then((fieldDefinitions) => {
                            $scope.data.workflowVersion = workflowVersion;
                            $scope.data.workflowVersionId = $scope.data.workflowVersion.id;
                            $scope.data.workflowVersionType = workflowVersion.workflowVersionType;

                            groupInfoManager.cacheGroupRelatedInfo(workflowVersion, fieldDefinitions);
                        }),
                    );
            }

            // We fetch the item details here so it would be part of the global loading of the page. We dont use the response,
            // but it's being added to the cache.
            /** @type {import("../../modules/ItemDetailsModule/components/ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql").GetItemDetailsForItemDetailsModuleEditorQueryVariables} */
            const queryVariables = { workflowVersionId: $scope.data.workflowVersionId };
            const urqlPromise = urql.query(GetItemDetailsForItemDetailsModuleEditorDocument, queryVariables).toPromise();

            Promise.all([urqlPromise, workflowVersionPromise]).then(() => {
                // First load the workflow version and then move to the selected logic
                initializeWorkerEditor().then(() => {
                    if ($stateParams.l || $stateParams.spt) {
                        selectLogicByUrlState($stateParams);
                    }

                    if ($stateParams.field) {
                        const fValue = $stateParams.field.toUpperCase();
                        const isCreate = fValue === 'COLUMN' || fValue === 'GLOBAL';
                        let existingFieldDefinition = null;
                        if (!isCreate) {
                            existingFieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
                                $scope.data.workflowVersionId,
                                $stateParams.field,
                            );
                        }
                        $scope.onOutlineFieldSelected(
                            $scope.data.groupId,
                            isCreate ? fValue : existingFieldDefinition.targetType,
                            existingFieldDefinition?.type,
                            existingFieldDefinition?.projectIntegration,
                            isCreate,
                            null,
                            existingFieldDefinition,
                            null,
                            null,
                            null,
                            null,
                            $stateParams.fieldCreateStartWithDataSource?.toLowerCase() || null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            existingFieldDefinition?.idRelationField,
                            $scope.data.workflowVersionId,
                            null,
                            null,
                        );
                    }
                });

                if ($scope.data.openSyncConfiguration) {
                    $scope.selectDataSourceComponent();
                }

                $scope.onRecurrenceTimeSelectionChanged();
            });

            // Fetching the project integrations summary which is also contains the
            // current workflow folder (=solution) project integrations permissions.
            tonkeanService
                .getProjectIntegrationsSummaries(projectManager.project.id, true, containingWorkflowFolder.id)
                .then(({ workflowFolderAccess }) => {
                    $scope.data.workflowFolderProjectIntegrationsAccess = workflowFolderAccess;
                });
        });
    };

    $scope.onEnvironmentChange = function (environment) {
        const workflowVersionType = environment === 'build' ? 'DRAFT' : 'PUBLISHED';
        $scope.onWorkflowVersionSelected(null, workflowVersionType);
    };

    /**
     * Sets the state of the side pane by the given state that represents the url params
     * @param newState - url params or an object that mocks it
     * @param logic - if the logic to set in the sidepane is known in advanced setting this will improve performance
     */
    function selectLogicByUrlState(newState, logic) {
        // Check if there was a state change
        if (
            newState.spt === $scope.data.sidePaneType &&
            (!newState.l ||
                (newState.lcm === $scope.data.logicConfigMode &&
                    newState.l === $scope.data.selectedLogic?.node?.id &&
                    newState.openCompare === $stateParams.openCompare))
        ) {
            return;
        }

        if (!$scope.data.workflowVersionId) {
            return;
        }

        const logicId = newState.l;
        let selectedLogic = logic;
        // Make sure the side pane is not saving and then move to the correct state
        return $scope.selectSidePaneType(newState.spt).then(() => {
            // Set the config params so the ui will change
            $scope.data.sidePaneType = newState.spt;
            $scope.data.logicConfigMode = newState.lcm;

            // If a logic wasn't provided we need to go find it
            if (!selectedLogic) {
                // If and id was provided and its not the same as currently selected go find it in the graph
                if (logicId && logicId !== $scope.data.syncObject.selectedLogicId) {
                    const node = customTriggerManager.findLogicDataInGraphById($scope.data.workflowVersionId, logicId);
                    if (node) {
                        selectedLogic = node.logic;
                    } else {
                        // Invalid logic id, Reset state
                        selectLogicByUrlState({});
                    }
                } else {
                    // No need to find logic in the graph
                    selectedLogic = $scope.data.selectedLogic;
                }
            }

            $scope.data.selectedLogic = selectedLogic;
            $scope.data.syncObject.selectedLogicId = $scope.data.selectedLogic?.node?.id;
            $scope.data.sideBySideDiffDisplayed = !!newState.openCompare;

            if ($scope.data.selectedLogic) {
                // Scroll to the selected logic. If we didn't get a logic (but a dashboard, check-ins, etc) supply an override id.
                scrollToSelectedLogic(
                    $scope.data.selectedLogic,
                    $scope.data.sidePaneType && $scope.data.sidePaneType !== $scope.data.sidePaneTypes.logic
                        ? `logic-container-${$scope.data.sidePaneType}`
                        : null,
                );
            }

            if ($state.current.name.startsWith(MODULE_EDITOR_STATE_NAME)) {
                // Set the $state params so the url will update
                $state.go('.', newState, { location: 'replace' });
            }
        });
    }

    $scope.$watchGroup(
        ['$state.params.l', '$state.params.spt', '$state.params.lcm', '$state.params.openCompare', '$state.params.t'],
        (newValue, oldValue) => {
            if (
                newValue[0] !== oldValue[0] ||
                newValue[1] !== oldValue[1] ||
                newValue[2] !== oldValue[2] ||
                newValue[3] !== oldValue[3]
            ) {
                selectLogicByUrlState($state.params);
            }
            if (newValue[4] !== oldValue[4]) {
                updateOutlineTabState(newValue[4], newValue[4] !== undefined);
            }
        },
    );

    $scope.$watchGroup(['$state.params.t', '$state.params.selectedOutlineFormId'], (newValue, oldValue) => {
        const [newTab, selectedFormId] = newValue;
        if (newTab === 'forms' && !!selectedFormId) {
            $scope.data.selectedOutlineFormId = selectedFormId;
        }
        return;
    });

    $scope.$watchGroup(
        ['$state.params.t', '$state.params.itemInterfaceId', '$state.params.createNew'],
        (newValue, oldValue) => {
            const [newTab, newItemInterfaceId] = newValue;
            const [oldTab] = oldValue;

            if (newTab !== 'interfaces' && oldTab === 'interfaces') {
                $state.go('.', { itemInterfaceId: undefined }, { location: 'replace' });
                navigationService.exitDrilldownMode();
            }

            $scope.data.itemInterfaceId = newItemInterfaceId ? newItemInterfaceId : null;

            if (newTab === 'interfaces') {
                if (newItemInterfaceId) {
                    navigationService.enterDrilldownMode();
                } else {
                    navigationService.exitDrilldownMode();
                }
            }
        },
    );

    $scope.$watchGroup(
        ['$state.params.t', '$state.params.processMapperId', '$state.params.createNew'],
        (newValue, oldValue) => {
            const [newTab, newProcessMapperId] = newValue;
            const [oldTab] = oldValue;

            if (newTab !== 'process-mappers' && oldTab === 'process-mappers') {
                $state.go('.', { processMapperId: undefined, nodeId: undefined }, { location: 'replace' });
                navigationService.exitDrilldownMode();
            }

            $scope.data.processMapperId = newProcessMapperId ? newProcessMapperId : null;

            if (newTab === 'process-mappers') {
                if (newProcessMapperId) {
                    navigationService.enterDrilldownMode();
                } else {
                    navigationService.exitDrilldownMode();
                }
            }
        },
    );

    $scope.$watchGroup(['$state.params.t', '$state.params.syncProjectIntegrationId'], (newValue, oldValue) => {
        const [newTab, newProjectIntegrationId] = newValue;
        const [oldTab] = oldValue;

        if (newTab !== 'item-details' && oldTab === 'item-details') {
            $state.go('.', { syncProjectIntegrationId: undefined }, { location: 'replace' });
            navigationService.exitDrilldownMode();
        }

        $scope.data.syncProjectIntegrationId = newProjectIntegrationId ? newProjectIntegrationId : null;

        if (newTab === 'item-details') {
            if (newProjectIntegrationId) {
                navigationService.enterDrilldownMode();
            } else {
                navigationService.exitDrilldownMode();
            }
        }
    });

    // open sidepan request
    $rootScope.$on('openEditorRightPane', function (event, key) {
        $scope.selectComponent(key, null);
    });

    $scope.onRevertWorkflowVersionClicked = function () {
        $scope.reloadOutlineConfiguration();
    };

    $scope.onTestWorkflowClicked = function (invalidLogics, validationState) {
        $scope.data.startValidating = true;
        $scope.data.invalidLogics = invalidLogics;
        $scope.data.validationState = validationState;

        if ($scope.data.validationState === $scope.data.validationStates.VALID) {
            return $q.resolve();
        } else {
            return $q.reject();
        }
    };

    /**
     * Initializes the worker editor, after we have a workflow version id set.
     */
    function initializeWorkerEditor() {
        // Try to get our scroll container. We later scroll to elements.
        const scrollContainerRaw = document.querySelector('#worker-editor-scroll-container');
        if (scrollContainerRaw) {
            scrollContainer = angular.element(scrollContainerRaw);
        }

        const loadCustomTriggersPromise = $scope.loadLogicComponents();

        // Setting the activity manager to only cache items from current $scope.data.group.
        if ($scope.data.groupId) {
            projectManager.currentlyViewedGroupId = $scope.data.groupId;
        }

        $scope.data.fieldDefinitions = customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId]
            ? angular.copy(
                  customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId].filter(
                      (fieldDefinition) => !fieldDefinition.systemUtilized,
                  ),
              )
            : null;
        const loadExampleItemPromise = loadExampleItem();

        // Loading both fields and custom triggers.
        return $q.all([loadCustomTriggersPromise, loadExampleItemPromise]);
    }

    /**
     * Occurs when someone asks to open an outline tab
     */
    $rootScope.$on('selectWorkflowOutlineTab', (e, params) => {
        if (params && params.tabKey) {
            $scope.selectOutlineTab(params.tabKey);
        }
    });

    $rootScope.$on('reloadModuleEditorRequest', () => {
        window.location.reload();
    });

    $scope.onOutlineIsOpenChanged = function (isOpen) {
        $scope.data.isOutlineSidePaneOpen = isOpen;

        if (!isOpen) {
            updateOutlineTabState(undefined, isOpen);
        }
    };

    /**
     * Loads the custom triggers of a workflow version.
     */
    $scope.loadLogicComponents = function () {
        const draftWorkflowVersionsId = workflowVersionManager.getDraftVersionFromCache($scope.data.groupId).id;
        const cachedDraftTriggers =
            customTriggerManager.hasWorkflowVersionCustomTriggersAndGraph(draftWorkflowVersionsId);
        const publishedWorkflowVersionId = workflowVersionManager.getPublishedVersionFromCache($scope.data.groupId).id;
        const cachedPublishedTriggers =
            customTriggerManager.hasWorkflowVersionCustomTriggersAndGraph(publishedWorkflowVersionId);

        const viewedWorkflowVersion = $scope.data.workflowVersion;
        const shouldGetCurrentVersion =
            viewedWorkflowVersion &&
            viewedWorkflowVersion.id !== draftWorkflowVersionsId &&
            viewedWorkflowVersion.id !== publishedWorkflowVersionId &&
            !customTriggerManager.hasWorkflowVersionCustomTriggersAndGraph(viewedWorkflowVersion.id);

        $scope.data.loadingCustomTriggers = !cachedDraftTriggers || !cachedPublishedTriggers;
        $scope.data.errorLoadingCustomTriggers = null;

        return $q
            .all([
                // Fetch draft triggers
                !cachedDraftTriggers
                    ? customTriggerManager.getWorkflowVersionCustomTriggersAndGraph(draftWorkflowVersionsId)
                    : $q.resolve(),

                // Fetch published triggers
                !cachedPublishedTriggers
                    ? customTriggerManager.getWorkflowVersionCustomTriggersAndGraph(publishedWorkflowVersionId)
                    : $q.resolve(),

                // Fetch currently viewed triggers
                shouldGetCurrentVersion
                    ? customTriggerManager.getWorkflowVersionCustomTriggersAndGraph(viewedWorkflowVersion.id)
                    : $q.resolve(),
            ])
            .catch(() => {
                $scope.data.errorLoadingCustomTriggers =
                    'There was an error trying to get custom triggers for this list.';
            })
            .finally(() => {
                $scope.data.loadingCustomTriggers = false;
            });
    };

    /**
     * Method for opening privateGroupModal, always use this method when opening the modal.
     */
    $scope.openPrivateGroupModal = function (group) {
        analyticsWrapper.track('Open worker group settings', { category: 'Worker editor' });

        return modalUtils.openPrivateGroupModal(projectManager.groupsMap[group.id], null, true);
    };

    /**
     * Toggles the state of the worker name edit.
     */
    $scope.toggleWorkerNameEditMode = function () {
        $scope.data.workerNameEditMode = !$scope.data.workerNameEditMode;
    };

    /**
     * Occurs on selection of workflow version.
     */
    $scope.onWorkflowVersionSelected = function (workflowVersionId, workflowVersionType) {
        if ($scope.data.fieldEditorHasPendingChanges) {
            return openChangeEnvironmentWithPendingChangesModal();
        }

        $state.go(MODULE_EDITOR_STATE_NAME, {
            env: workflowVersionType,
            selectedFieldProps: $scope.data.selectedFieldProps,
            selectedOutlineFormId: $scope.data.selectedOutlineFormId,
            selectedOutlineFormType: $scope.data.selectedOutlineFormType,
        });
    };

    /**
     * Change the workflow version
     * @param workflowVersionType - A given workflow version type
     */
    $scope.onWorkflowVersionTypeSelected = function (workflowVersionType) {
        if (workflowVersionType === 'build' || workflowVersionType === 'DRAFT') {
            return $scope.onWorkflowVersionSelected(undefined, 'DRAFT');
        }

        $scope.onWorkflowVersionSelected(undefined, 'PUBLISHED');
    };

    /**
     * Updates the name of the worker.
     */
    $scope.updateWorkerName = function (workerName) {
        if (!workerName || !workerName.length || projectManager.groupsMap[$scope.data.groupId].name === workerName) {
            return;
        }

        $scope.data.loadingSavingWorkerName = true;
        $scope.data.errorSavingWorkerName = null;

        groupManager
            .updateGroupName($scope.data.groupId, workerName)
            .catch(() => {
                $scope.data.errorSavingWorkerName = 'There was an error trying to update module name.';
            })
            .finally(() => {
                $scope.data.loadingSavingWorkerName = false;
                $scope.toggleWorkerNameEditMode();
            });
    };

    /**
     * Open preview items modal
     */
    $scope.previewInitiatives = function () {
        $timeout(() => {
            initiativeManager
                .searchInitiatives(
                    $scope.pm.project.id,
                    null,
                    null,
                    null,
                    $scope.data.groupId,
                    null,
                    null,
                    null,
                    0,
                    100,
                    null,
                    null,
                    null,
                    true,
                    $scope.data.workflowVersionType === 'DRAFT',
                )
                .then((data) => {
                    $scope.data.initiatives = data.initiatives;
                    trackHelper.getRelatedInitiativesCount(
                        $scope.pm.project.id,
                        data.initiatives.map(({ id }) => id),
                    );
                    const columnFields = customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId];
                    $scope.data.previewFieldDefinitions = columnFields.map((fd) => fd.id);
                })
                .finally(() => {
                    modalUtils.open(
                        {
                            template: workerEditorPreviewInitiativesModalTemplate,
                            scope: $scope,
                            animation: false,
                            windowClass: 'fullscreen-modal',
                        },
                        'previewInitiatives',
                    );
                });
        });
    };

    /**
     * Adds the auto check ins component to the worker.
     */
    $scope.addAutoCheckInsComponent = function () {
        workflowVersionManager.toggleShouldSendGatherUpdates($scope.data.groupId, true);
        $scope.selectSidePaneType($scope.data.sidePaneTypes.bot);
    };

    /**
     * Adds the dashboard component to the worker.
     */
    $scope.addLiveDashboard = function () {
        workflowVersionManager.updateWorkflowVersionDashboardHidden($scope.data.groupId, false);
        $scope.selectSidePaneType($scope.data.sidePaneTypes.dashboard);
    };

    /**
     * Selects the data source component.
     */
    $scope.selectDataSourceComponent = function () {
        $timeout(() => {
            $scope.selectComponent($scope.data.sidePaneTypes.dataSource, null);
        });
    };

    /**
     * Selects the auto check in component.
     */
    $scope.selectAutoCheckInsComponent = function () {
        $scope.selectComponent($scope.data.sidePaneTypes.bot, null);
    };

    /**
     * Toggle the live report active state
     */
    $scope.toggleLiveReportActiveState = function (groupId, isOpen) {
        analyticsWrapper.track('Toggle worker live report', {
            category: 'Dashboard worker component',
            label: isOpen,
        });
        workflowVersionManager.updateWorkflowVersionDashboardHidden(groupId, isOpen);
    };

    /**
     * Selects the live dashboard component.
     */
    $scope.selectLiveDashboardComponent = function () {
        $scope.selectComponent($scope.data.sidePaneTypes.dashboard, null);
    };

    /**
     * Selects a logic component.
     */
    $scope.selectLogicComponent = function (logic, logicConfigMode) {
        $scope.selectComponent($scope.data.sidePaneTypes.logic, logic, logicConfigMode);
    };

    /**
     * Occurs once the user clicks on publish workflow version.
     */
    $scope.onReadyToPublishWorkflowClicked = function (componentsAreValidated) {
        if (!componentsAreValidated) {
            $scope.data.startValidating = true;
        }
    };

    $scope.onBackToMarkForPublishClicked = function () {
        $scope.closeSidePane();
        $scope.$broadcast('backToMarkForPublish');
    };

    /**
     * Passed to the workerOutline component and allows it to select a logic node.
     */
    $scope.selectComponentFromOutline = function (node) {
        let type = logicConfigModes.selectType;
        if (node?.node?.customTriggerType !== 'UNKNOWN') {
            type = logicConfigModes.configuration;
        }
        $scope.selectLogicComponent(node, type);
    };

    /**
     * Selects the component and scrolls to it.
     */
    $scope.selectComponent = function (sidePaneType, logic, logicConfigMode, openCompare) {
        const newState = {
            l: logic?.node?.id,
            spt: sidePaneType,
            lcm: logicConfigMode,
            openCompare,
            t: null,
            selectedOutlineFormId: null,
            targetId: null,
        };

        $scope.data.showBackToMarkForPublishButton = false;
        selectLogicByUrlState(newState, logic);
    };

    $scope.onSideBySideDiffSelected = function (openCompare) {
        $scope.data.sideBySideDiffDisplayed = !$scope.data.sideBySideDiffDisplayed;
        $scope.selectComponent(
            $scope.data.sidePaneTypes.logic,
            $scope.data.selectedLogic,
            $scope.data.logicConfigMode,
            openCompare,
        );
    };

    $scope.onSuccessfulPublish = function () {
        const workflowVersionId = workflowVersionManager.getPublishedVersionFromCache($scope.data.groupId).id;

        $scope.onWorkflowVersionSelected(workflowVersionId, 'PUBLISHED');
    };

    $scope.showButtonBackToMarkForPublish = () => {
        $scope.data.showBackToMarkForPublishButton = true;
    };

    $scope.onFieldsGroupExpanded = function (key) {
        $scope.selectOutlineTab('fields');

        $timeout(() => $rootScope.$broadcast('expandFieldGroup', key));
    };

    /**
     * Opens the outline side pane.
     *
     * @param tabKey {string | undefined}
     * @param additionalParams {object | undefined=}
     */
    $scope.selectOutlineTab = function (tabKey, additionalParams) {
        $timeout(() => {
            $scope.data.syncObject.selectedLogicId = null;
            updateOutlineTabState(tabKey, true, additionalParams);
        });
    };

    /**
     * Callback triggered by on-tab-selected param in worker-outline.
     */
    $scope.onOutlineTabSelected = function (tab) {
        const prevTab = $scope.data.selectedOutlineTabKey;
        $scope.data.selectedFieldProps = null;
        $scope.data.fieldEditorHasPendingChanges = false;
        updateOutlineTabState(tab?.key, true);

        if (prevTab !== tab?.key) {
            navigationService.exitDrilldownMode();
        }
    };

    /**
     * Update the selected worker outline tab and url.
     *
     * @param tabKey {string | undefined} - the tab key to open, if false will not change the tab.
     * @param isOutlineOpen {boolean} -  If false, it will close the side pane.
     * @param additionalParams {object | undefined=} - additional params to add to the redirect.
     */
    function updateOutlineTabState(tabKey, isOutlineOpen, additionalParams) {
        // For consistency, we always want to have the previous tab selected even if the outline pane is closed
        const selectedOutlineTabKey = tabKey || $scope.data.selectedOutlineTabKey;

        const inModuleEditor = $state.current.name.startsWith(MODULE_EDITOR_STATE_NAME);
        const hasChangedTab = $scope.data.selectedOutlineTabKey !== selectedOutlineTabKey;

        if (inModuleEditor && (hasChangedTab || additionalParams)) {
            $state.go(
                '.',
                { t: isOutlineOpen ? selectedOutlineTabKey : undefined, ...additionalParams },
                { location: 'replace' },
            );
        }

        $scope.data.isOutlineSidePaneOpen = isOutlineOpen;
        $scope.data.selectedOutlineTabKey = selectedOutlineTabKey;
    }

    /**
     * Checks if can add modify the bot, based on the workflow version. If not, it will
     * open a dialog suggesting to move to build environment.
     *
     * @param forceDialog {boolean} - if true, it will show the dialog and return false without checking the version type.
     * @returns {boolean} - true if can modify the custom trigger based on the current workflow version
     */
    $scope.canModifyBot = (forceDialog = false) => {
        if (forceDialog || $scope.data.workflowVersionType !== 'DRAFT') {
            $scope.mboxData = {
                title: 'Editing in a published version is disabled',
                html: `You are currently in a published version of your module.<br />If you want to modify it, you should go to the 
                build environment.<br /><br />Do you want to change mode?`,
                isWarn: false,
                cancelLabel: 'Stay in production',
                closeIcon: true,
                isReversedButtons: true,
            };

            if ($scope.data.hasPermissionsToEditWorker) {
                $scope.mboxData.okLabel = 'Move to build environment';
                $scope.mboxData.html = `You are currently in a published version of your module.<br />If you want to modify it, you should go to the 
                build environment.<br /><br />Do you want to change mode?`;
            } else {
                $scope.mboxData.html = `You are currently in a published version of your module.<br />Build Environment is only available to Module makers.`;
            }

            modal
                .openMessageBox({
                    scope: $scope,
                    size: 'md',
                    windowClass: 'mod-danger',
                })
                .result.then(() => {
                    $scope.onWorkflowVersionSelected(
                        workflowVersionManager.getDraftVersionFromCache($scope.data.groupId).id,
                        'DRAFT',
                    );
                });

            return false;
        }
        return true;
    };

    /**
     * Adding a trigger is a complex operation requiring multiple calls to the server and to methods in this component to add elements to the UI.
     */
    $scope.$on('addNewAutonomousTriggerToWorkerEditor', (event, selectedTypeConfig) =>
        $scope.addNewAutonomousTrigger(selectedTypeConfig),
    );
    /**
     * Adds a new autonomous trigger.
     * @param selectedTypeConfig: {LogicBlockConfigDefinition}
     */
    $scope.addNewAutonomousTrigger = function (selectedTypeConfig) {
        if (!$scope.canModifyBot()) {
            return;
        }

        const params = {
            isScheduled: selectedTypeConfig?.secondaryType === 'AUTONOMOUS_SCHEDULE',
            parentNode: customTriggerManager.workflowVersionIdToCustomTriggersGraph[$scope.data.workflowVersionId],
        };
        $scope.createNewLogic(params, selectedTypeConfig);
    };

    /**
     * Returns an impact list filtering out non-special children. Special children are children that appear in
     * the `createAfterOptions`, and logic block types with `specialChild` as true,  that are enabled in the
     * WorkerLogic sidebar
     *
     * @param parentNode - the custom trigger node
     * @returns list of special children
     */
    $scope.getSpecialChildren = (parentNode) => {
        const typeConfig = $scope.logicBlockTypes[parentNode.node.customTriggerType];
        const specialChildrenTypes = new Set([
            // Locate types with `specialChild` as true
            ...Object.values(consts.getLogicBlockTypes())
                .filter(({ specialChild }) => specialChild)
                .map(({ type }) => type),
            // Locate optional children in `createAfterOptions` with a `customTriggerType`
            ...(typeConfig
                ?.createAfterOptions?.(projectManager.project.features)
                ?.map((createAfter) => {
                    const obj = {};
                    createAfter.triggerParamsEnricher?.(obj);
                    return obj.customTriggerType;
                })
                .filter(Boolean) || []),
        ]);

        const specialChildren = parentNode.impacts?.filter(({ node }) =>
            specialChildrenTypes.has(node.customTriggerType),
        );

        return specialChildren || [];
    };

    $scope.removeSpecialChildren = function (parentNode) {
        const specialChildren = $scope.getSpecialChildren(parentNode);
        parentNode.impacts = parentNode.impacts?.flatMap((impact) =>
            specialChildren.includes(impact) ? impact.impacts : [impact],
        );

        // Making the children of the special children impacts to be the children of the reset logic.
        const childrenOfCreatedCustomTriggerIds = parentNode.impacts.map(
            (childOfCreatedCustomTrigger) => childOfCreatedCustomTrigger.node.id,
        );
        const specialChildrenCustomTriggerIds = specialChildren.map((specialChild) => specialChild.node.id);
        return customTriggerManager.moveAndRemoveCustomTrigger(
            $scope.data.groupId,
            parentNode.node.id,
            childrenOfCreatedCustomTriggerIds,
            null,
            specialChildrenCustomTriggerIds,
        );
    };

    $scope.createMultipleLogicComponents = function (parentNode, definitionsArray, replaceInPlace) {
        $scope.data.loadingCreatingCustomTrigger = true;
        $scope.data.errorCreatingCustomTrigger = null;
        definitionsArray = definitionsArray.map((definition) => {
            definition.addToGraphUnderParentId = parentNode.node.id;
            return definition;
        });

        return customTriggerManager
            .createMultipleCustomTriggersInWorkflowVersion($scope.data.groupId, definitionsArray)
            .then((data) => {
                const createdCustomTriggers = data.creationResults.map((result) => result.createdCustomTrigger);
                // If replace in place - save previous impacts, remove them, then match new impacts as parents to old impacts
                const previousImpacts = parentNode.impacts;
                if (replaceInPlace) {
                    parentNode.impacts = [];
                }

                const creationPromises = [];
                for (const [i, createdCustomTrigger] of createdCustomTriggers.entries()) {
                    creationPromises.push(
                        createNewUILogic(parentNode, createdCustomTrigger, null, null).then((createdLogic) => {
                            if (replaceInPlace) {
                                // Match new impacts as new impacts parents
                                if (!createdLogic.impacts) {
                                    createdLogic.impacts = [];
                                }
                                createdLogic.impacts.push(previousImpacts[i]);
                            }

                            return $q.resolve();
                        }),
                    );
                }

                return $q.all(creationPromises).then(() => $q.resolve(createdCustomTriggers));
            })
            .catch(() => {
                $scope.data.errorPostingCustomTrigger = 'There was an error trying to save custom trigger.';
            })
            .finally(() => {
                $scope.data.loadingCustomTrigger = false;
            });
    };

    /**
     * Enriches the query definition by given evaluatedCustomTriggerType.
     */
    function enrichQueryDefinition(
        parentCustomTriggerType,
        evaluatedCustomTriggerType,
        queryDefinition,
        isImmediately,
    ) {
        switch (evaluatedCustomTriggerType) {
            case 'MONITOR_TRACKS': {
                if (
                    parentCustomTriggerType !== 'SYNC_INNER_MATCHED_ENTITY' &&
                    parentCustomTriggerType !== 'SEND_FORM_ANSWERED' &&
                    !isImmediately
                ) {
                    queryDefinition.query.filters = [
                        tonkeanUtils.generateStatusFilter(
                            projectManager.groupsMap,
                            customFieldsManager.selectedColumnFieldsMap,
                            customFieldsManager.selectedGlobalFieldsMap,
                            $scope.data.groupId,
                            'DONE',
                            $scope.data.workflowVersionId,
                            workflowVersionManager.workflowVersionIdToWorkflowVersionMap,
                            workflowVersionManager,
                        ),
                    ];
                }

                break;
            }

            default:
                break;
        }

        return queryDefinition;
    }

    /**
     * Adds the custom trigger to UI graph and saves new graph in server.
     */
    function addLogicToUIAndGraph(
        parentNode,
        selectWithConfigMode,
        createdCustomTrigger,
        doNotUpdateServer,
        childrenOfCreatedCustomTrigger,
    ) {
        // Create new logic in UI model.
        const selectConfigMode = selectWithConfigMode || logicConfigModes.selectType;
        return createNewUILogic(parentNode, createdCustomTrigger, selectConfigMode, childrenOfCreatedCustomTrigger);
    }

    /**
     * Creating a new logic.
     * Because the amount of parameters got big and needed to be put in many places (because of angular bindings), we only get one object with all the params on it.
     * Available params:
     * parentNode, displayName, customTriggerType, selectWithConfigMode
     */
    $scope.createNewLogic = function (params, customTriggerSecondaryType) {
        if (!$scope.canModifyBot()) {
            return Promise.resolve();
        }

        $scope.data.loadingCreatingCustomTrigger = true;
        $scope.data.errorCreatingCustomTrigger = null;

        if (params.enricher) {
            params.enricher(params, customTriggerManager, projectManager.project.features);
        }

        let runOneTimeOnly = false;
        let evaluatedCustomTriggerType = params.customTriggerType;
        if (
            projectManager.groupsMap[$scope.data.groupId].workerType === $scope.data.workerTypes.FULL_TIME.key &&
            params.parentNode.node ===
                $scope.ctm.workflowVersionIdToCustomTriggersGraph[$scope.data.workflowVersionId].node
        ) {
            evaluatedCustomTriggerType = 'AUTONOMOUS';
            runOneTimeOnly = customTriggerSecondaryType.secondaryType === 'AUTONOMOUS_ITEM_CREATED';
            params.selectWithConfigMode = logicConfigModes.configuration;
        }

        analyticsWrapper.track('Create logic', { category: 'Worker editor', label: evaluatedCustomTriggerType });

        let queryDefinition = {
            query: getDefaultTonkeanQuery(),
        };

        let evaluatedDisplayName;
        if (customTriggerSecondaryType) {
            evaluatedDisplayName = customTriggerSecondaryType.title;
        } else {
            evaluatedDisplayName = utils.isNullOrEmpty(params.displayName) ? 'Action Block' : params.displayName;
        }

        if(customTriggerSecondaryType?.secondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED') {
            var states = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).states
            var defaultIntakeState = states.find(state => state.type === 'INTAKE')
            if (defaultIntakeState && !params.stateId) {
                params.stateId = defaultIntakeState.id
            }
        }

        // Enrich the query definition.
        queryDefinition = enrichQueryDefinition(
            params?.parentNode?.node?.customTriggerType,
            evaluatedCustomTriggerType,
            queryDefinition,
            params?.customTriggerActions?.[0]?.customTriggerActionDefinition?.monitorItemsConditionType ===
                'IMMEDIATELY',
        );

        const pingOwner =
            evaluatedCustomTriggerType === 'AUTONOMOUS' ||
            evaluatedCustomTriggerType === 'GATHER_UPDATE' ||
            evaluatedCustomTriggerType === 'PERSON_INQUIRY';

        return customTriggerManager
            .createCustomTriggerInWorkflowVersion(
                $scope.data.groupId,
                evaluatedDisplayName,
                'Description',
                queryDefinition,
                pingOwner,
                null,
                null,
                null,
                params.customTriggerActions,
                null,
                evaluatedCustomTriggerType ? evaluatedCustomTriggerType : null,
                evaluatedCustomTriggerType === 'AUTONOMOUS',
                params.parentNode.node ? params.parentNode.node.id : null,
                params.disabled,
                params.stateId,
                params.updateText,
                params.evaluatedUpdateText,
                runOneTimeOnly,
                customTriggerSecondaryType?.secondaryType
                    ? customTriggerSecondaryType.secondaryType
                    : params.customTriggerSecondaryType,
            )
            .then((createdCustomTrigger) => {
                return addLogicToUIAndGraph(params.parentNode, params.selectWithConfigMode, createdCustomTrigger, true)
                    .then(() => $q.resolve(createdCustomTrigger));
            })
            .catch((error) => {
                if (error.status === 409) {
                    // On conflict
                    modalUtils.openRefreshModuleModal();
                }
            })
            .finally(() => {
                $scope.data.loadingCustomTrigger = false;
            });
    };

    /**
     * Duplicates a logic in the graph (the source logic will be the parent of duplicated logic).
     */
    $scope.duplicateLogic = function (logicToDuplicate) {
        const foundLogicData = customTriggerManager.findLogicDataInGraphById(
            $scope.data.workflowVersionId,
            logicToDuplicate.node.id,
        );

        if (
            !utils.isNullOrUndefined(foundLogicData.logic) &&
            !utils.isNullOrUndefined(foundLogicData.parent) &&
            !utils.isNullOrUndefined(foundLogicData.indexInParent)
        ) {
            analyticsWrapper.track('Duplicate logic', { category: 'Worker editor' });

            return customTriggerManager
                .duplicateCustomTriggerInWorkflowVersion($scope.data.groupId, foundLogicData.logic.node.id)
                .then((data) => {
                    const createdCustomTrigger = data.createdCustomTrigger;
                    const childrenCustomTriggers = data.childrenCustomTriggers;

                    return addLogicToUIAndGraph(
                        foundLogicData.parent,
                        createdCustomTrigger.customTriggerType === 'UNKNOWN' ? 'selectType' : 'configuration',
                        createdCustomTrigger,
                        true,
                        childrenCustomTriggers,
                    ).then(() => $q.resolve(createdCustomTrigger));
                })
                .catch(() => {
                    $scope.data.errorPostingCustomTrigger = 'There was an error trying to save custom trigger.';
                })
                .finally(() => {
                    $scope.data.loadingCustomTrigger = false;
                });
        }
    };

    /**
     * Creates a new logic in the UI model.
     */
    function createNewUILogic(parentNode, customTrigger, selectConfigMode, childrenOfCreatedCustomTrigger) {
        // Setting index to created node.
        let maxIndex = 0;
        if (parentNode.impacts && parentNode.impacts.length) {
            for (let i = 0; i < parentNode.impacts.length; i++) {
                const impacted = parentNode.impacts[i];

                if (impacted.node.index > maxIndex) {
                    maxIndex = impacted.node.index;
                }
            }
        }
        customTrigger.index = maxIndex;

        // Creating logic and selecting it.
        const createdLogic = {
            node: customTrigger,
            impacts: [],
        };

        // Assigning it to its parent.
        if (!parentNode.impacts) {
            parentNode.impacts = [];
        }
        parentNode.impacts.push(createdLogic);

        const moveCustomTrigger = $q.resolve();

        return moveCustomTrigger
            .then((ignored) => {
                // Adding the children.
                if (childrenOfCreatedCustomTrigger && childrenOfCreatedCustomTrigger.length) {
                    for (const childCustomTrigger of childrenOfCreatedCustomTrigger) {
                        const childLogic = {
                            node: childCustomTrigger,
                            impacts: [],
                        };

                        createdLogic.impacts.push(childLogic);
                    }
                }

                if (selectConfigMode) {
                    $scope.selectLogicComponent(createdLogic, selectConfigMode);
                }

                return createdLogic;
            })
            .catch((error) => {
                if (error.status === 409) {
                    modalUtils.openRefreshModuleModal();
                }
            });
    }

    /**
     * Scrolls to to given logic in the graph.
     * @param logic - the logic to scroll to.
     * @param overrideId - a specific id to scroll to (logic will be ignored). Useful to scroll to data-source block, etc.
     */
    function scrollToSelectedLogic(logic, overrideId) {
        // Make sure we have a valid logic and a valid container.
        if (!scrollContainer) {
            return;
        }
        if (utils.isNullOrEmpty(overrideId) && (!logic || !logic.node)) {
            return;
        }

        const scroll = () => {
            // Get the id from the override id if we don't have a logic.
            const elementId = !utils.isNullOrEmpty(overrideId) ? overrideId : `logic-container-${logic.node.id}`;
            // Get the logic container element.
            const element = document.getElementById(elementId);

            // If we found that element, we can try to scroll to it.
            if (element) {
                // Get the bounding rect to the element we are going to scroll to.
                const boundingRect = element.getBoundingClientRect();

                // The left scroll position is: the bounding rect left (relative to viewport) + the left scroll we have on container - 30px padding fix - half of the width of the container (to center it).
                const left =
                    boundingRect.left + scrollContainer[0].scrollLeft - 30 - scrollContainer[0].clientWidth / 2;
                // The top scroll position is: the bounding rect top (relative to viewport) + the top scroll we have on container - half of the height of the container (to center it).
                const top = boundingRect.top + scrollContainer[0].scrollTop - scrollContainer[0].clientHeight / 2;

                // Scroll using the duScroll (angular scroll) library. It adds functions to the angular elements.
                // The function in the end is easeOutQuad (from: https://gist.github.com/gre/1650294).
                scrollContainer
                    .scrollTo(left, top, 500, (t) => t * (2 - t))
                    .catch(() => {
                        // For some reason scrollTo always throws exception of "undefined" since upgrading to angular 1.8
                        // Swallow the error
                    });
            }
            return !!element;
        };

        // We need to retry the scroll because we it was not added to the DOM yet.
        const retryScroll = (retriesLeft) => {
            if (!scroll() && retriesLeft) {
                $timeout(() => retryScroll(retriesLeft - 1), 200);
            }
        };

        retryScroll(10);
    }

    /**
     * Selects the type of the side pane content.
     */
    $scope.selectSidePaneType = function (sidePaneType) {
        if (!sidePaneType) {
            $scope.$broadcast('closeSidePaneEvent');
        }
        const validationPromise = getSelectSidePaneValidationPromise();

        validationPromise
            .then(() => {
                $scope.data.selectedSidePaneType = sidePaneType;
                $scope.data.logicSaveStateObject = null;

                if ($scope.data.startValidating) {
                    $scope.validateLogics();
                }
            })
            .finally(() => {
                if ($scope.data.selectedLogicSyncObject.setBigLoadingCircle) {
                    $scope.data.selectedLogicSyncObject.setBigLoadingCircle(false);
                }
            });

        return validationPromise;
    };

    function getSelectSidePaneValidationPromise() {
        // No Validation if its not on a logic tab or the worker is disabled, or there is no save state
        const saveState = $scope.data.logicSaveStateObject;
        if ($scope.data.selectedSidePaneType !== $scope.data.sidePaneTypes.logic || !saveState) {
            return $q.resolve();
        }

        // If the logic is in a save operation
        const existingSavePromise =
            $scope.data.selectedLogicSyncObject.getExistingSavePromise() || $scope.data.existingConfirmSaveModal;
        if (existingSavePromise) {
            // Wait for the save operation to complete and then continue.
            $scope.data.selectedLogicSyncObject.setBigLoadingCircle(true);
            return existingSavePromise;
        }

        return $q.resolve();
    }

    /**
     * Closes the side pane.
     */
    $scope.closeSidePane = function () {
        $scope.selectComponent(null, null, null);
    };

    /**
     * Occurs when something is saved on the logic.
     * Handles changes on every "tab" of settings for the logic
     * @param saveFunction - if there was no save, use this function to save
     */
    $scope.onActionsChanged = function (saveFunction) {
        $scope.data.logicSaveStateObject = {
            saveFunction,
        };

        $scope.data.validationState = $scope.data.validationStates.PENDING;

        if ($scope.data.startValidating && !$scope.data.hideValidation) {
            $scope.validateLogics();
        }
    };

    /**
     * Zooms the graph
     * @param amount
     */
    $scope.zoomGraph = function (amount) {
        if (amount) {
            $scope.data.zoom = $scope.data.zoom + amount;
        } else {
            // reset
            $scope.data.zoom = 1;
        }

        const el = document.querySelector('#worker-graph');
        if (el) {
            el.style['transform'] = `scale(${$scope.data.zoom})`;
            el.style['transformOrigin'] = '0% 0% 0px';
        }
    };

    $scope.reloadOutlineConfiguration = function () {
        $scope.data.reloadOutlineConfiguration = true;
        $timeout(() => {
            $scope.data.reloadOutlineConfiguration = false;
        });
    };

    $scope.onHideValidationChanged = function (value) {
        $scope.data.hideValidation = value;
        if ($scope.data.hideValidation) {
            $scope.data.invalidLogics = {};
        } else {
            $scope.validateLogics();
        }
        return $q.resolve();
    };

    $scope.onValidationRequested = function () {
        $scope.data.startValidating = true;
        $scope.validateLogics();
    };

    $scope.validateLogics = function () {
        $scope.data.hideValidation = false;

        return customTriggerManager
            .validateCustomTriggers(
                $scope.data.workflowVersionId,
                $scope.pm.groupsMap[$scope.data.groupId],
                $scope.pm.project,
            )
            .then(() => {
                // Valid.
                $scope.data.invalidLogics = {};
                $scope.data.validationState = $scope.data.validationStates.VALID;
                $scope.data.startValidating = false;

                return $q.resolve();
            })
            .catch((error) => {
                // Invalid.
                $scope.data.invalidLogics = error;
                $scope.data.validationState = $scope.data.validationStates.INVALID;

                return $q.reject();
            });
    };

    $scope.toggleBotWorkerComponent = function () {
        analyticsWrapper.track('Toggle worker auto check-ins', {
            category: 'Worker editor',
            label: !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId)
                .shouldSendGatherUpdates,
        });
        return workflowVersionManager.toggleShouldSendGatherUpdates(
            $scope.data.groupId,
            !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).shouldSendGatherUpdates,
        );
    };

    $scope.toggleDashboardWorkerComponent = function () {
        analyticsWrapper.track('Toggle worker live report', {
            category: 'Worker editor',
            label: !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).dashboardHidden,
        });
        return workflowVersionManager.updateWorkflowVersionDashboardHidden(
            $scope.data.groupId,
            !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).dashboardHidden,
        );
    };

    $scope.learnClicked = function () {
        analyticsWrapper.track('Learn more', { category: 'Worker editor' });
    };

    /**
     * Decide if to move to list or to workers gallery.
     * Need a special method because we might come here from a link, or run history.
     */
    $scope.goBack = function () {
        $state.go('product.workers');
    };

    /**
     * Get default item example entity
     */
    async function loadExampleItem() {
        if (customTriggerManager.workflowVersionIdToExampleItemsMap?.[$scope.data.workflowVersionId]?.root) {
            return;
        }

        const exampleItemIdFromStorage = customTriggerManager.getWorkflowExampleItemIdFromCache($scope.data.workflowVersionId);

        let exampleInitiativeId;
        if (exampleItemIdFromStorage) {
            try {
                const initiativeFromServer = await trackHelper.getInitiativeById(exampleItemIdFromStorage, true)
                if (initiativeFromServer) {
                    exampleInitiativeId = initiativeFromServer.id
                }
            } catch(error) {
                // If for any reason we cant get this initiative from the server, assume its no longer available and its id should be removed from the example items storage
                customTriggerManager.removeWorkflowExampleItemIdFromCache($scope.data.workflowVersionId)
            }
        }

        if (!exampleInitiativeId) {
            const isDraftInitiatives = $scope.data.workflowVersionId
                ? workflowVersionManager.isDraftVersion($scope.data.workflowVersionId)
                : false;

            const searchData = await tonkeanService
                .searchInitiatives(projectManager.project.id, {
                    isArchived: false,
                    groupId: $scope.data.groupId,
                    limit: 1,
                    isRootInitiative: true,
                    isDraftInitiatives,
                });
            exampleInitiativeId = searchData?.entities?.[0]?.id;
        }

        if (exampleInitiativeId) {
            return customTriggerManager.buildWorkflowVersionExampleItems($scope.data.workflowVersionId, exampleInitiativeId);
        }
    }

    $scope.onOutlineFormSelected = (formId, formType) => {
        $scope.data.selectedOutlineFormId = formId;
        $scope.data.selectedOutlineFormType = formType;

        if (formId || formType) {
            navigationService.enterDrilldownMode();
        }
    };

    $scope.onFormUnselected = () => {
        $timeout(() => {
            $state.go('.', { selectedOutlineFormId: undefined }, { location: 'replace' });
            $scope.data.selectedOutlineFormId = null;
            $scope.data.selectedOutlineFormType = null;
            navigationService.exitDrilldownMode();
        });
    };

    $rootScope.$on('editForm', function (event, args) {
        $scope.data.selectedOutlineFormId = args.formId;
        $scope.data.selectedOutlineFormType = args.formType;
        $scope.data.selectedOutlineTabKey = 'forms';
        navigationService.enterDrilldownMode();
    });

    $rootScope.$on('formCreated', (event, args) => {
        $scope.data.selectedOutlineFormId = args.id;
        navigationService.enterDrilldownMode();
    });

    $scope.onOutlineFieldSelected = (
        groupId,
        targetType,
        fieldDefinitionType,
        projectIntegration,
        createMode,
        duplicateMode,
        existingFieldDefinition,
        afterCreateOrUpdateCallback,
        deleteCallback,
        openInStep,
        quickCreateForExternal,
        startWithDataSource,
        selectedEntity,
        isWorkerMode,
        overrideFormulaOperator,
        overrideFieldIsHidden,
        manualValue,
        idOnlyMode,
        workflowVersionId,
        allowOnlyFieldDefinitionDataTypes,
        dontClosePreviousTabOnFieldCreation,
        isForMatchedItem,
    ) => {
        $scope.openFieldModal({
            groupId,
            targetType,
            fieldDefinitionType,
            projectIntegration,
            createMode,
            duplicateMode,
            existingFieldDefinition,
            afterCreateOrUpdateCallback,
            deleteCallback,
            openInStep,
            quickCreateForExternal,
            startWithDataSource,
            selectedEntity,
            isWorkerMode,
            overrideFormulaOperator,
            overrideFieldIsHidden,
            manualValue,
            idOnlyMode,
            workflowVersionId,
            allowOnlyFieldDefinitionDataTypes,
            dontClosePreviousTabOnFieldCreation,
            isForMatchedItem,
        });
    };

    /**
     * Opens field modal
     * Uses FieldDefinitionConfigurationModalParams
     */
    $scope.openFieldModal = ({
        groupId,
        targetType,
        fieldDefinitionType,
        projectIntegration,
        createMode,
        duplicateMode,
        existingFieldDefinition,
        afterCreateOrUpdateCallback,
        deleteCallback,
        openInStep,
        quickCreateForExternal,
        startWithDataSource,
        selectedEntity,
        isWorkerMode,
        overrideFormulaOperator,
        overrideFieldIsHidden,
        manualValue,
        idOnlyMode,
        workflowVersionId,
        matchedEntityFromWorkflowVersionId,
        allowOnlyFieldDefinitionDataTypes,
        dontClosePreviousTabOnFieldCreation,
        isSystemUtilized,
        openedFromCustomTriggerId,
        secondaryId,
        isForMatchedItem,
    }) => {
        $scope.data.dontClosePreviousTabOnFieldCreation = dontClosePreviousTabOnFieldCreation;
        const sameDatasource =
            startWithDataSource && $scope.data.selectedFieldProps?.startWithDataSource === startWithDataSource;
        $scope.data.selectedFieldProps = {
            groupId,
            targetType,
            fieldDefinitionType,
            projectIntegration,
            createMode,
            duplicateMode,
            existingFieldDefinition,
            afterCreateOrUpdateCallback,
            deleteCallback,
            openInStep,
            quickCreateForExternal,
            startWithDataSource: sameDatasource ? undefined : startWithDataSource,
            selectedEntity,
            isWorkerMode,
            overrideFormulaOperator,
            overrideFieldIsHidden,
            manualValue,
            idOnlyMode,
            workflowVersionId,
            matchedEntityFromWorkflowVersionId,
            allowOnlyFieldDefinitionDataTypes,
            isSystemUtilized,
            openedFromCustomTriggerId,
            secondaryId,
            isForMatchedItem,
        };

        // if its the same datasource we want to make sure a re-initialize occurs so we re-set the param
        if (sameDatasource) {
            $timeout(() => {
                $scope.data.selectedFieldProps = {
                    ...$scope.data.selectedFieldProps,
                    startWithDataSource,
                };
            });
        }

        $scope.data.fieldEditorHasPendingChanges = false;
        if (!$scope.data.dontClosePreviousTabOnFieldCreation) {
            $scope.data.selectedOutlineTabKey = 'fields';
        }

        // Make sure the url reflects the current view state
        $state.go('product.workerEditor', { field: existingFieldDefinition?.id || targetType });

        navigationService.enterDrilldownMode();
    };

    $rootScope.$on('createNewField', (_, args) => {
        $scope.onOutlineFieldSelected(...args);
    });

    $scope.onOutlineFieldUnselected = () => {
        $timeout(() => {
            $scope.data.selectedFieldProps = null;
            $scope.data.fieldEditorHasPendingChanges = false;
            if (!$scope.data.dontClosePreviousTabOnFieldCreation) {
                navigationService.exitDrilldownMode();
            }
            $scope.data.dontClosePreviousTabOnFieldCreation = false;
            $state.go('product.workerEditor', { field: null });
        });
    };

    $scope.isWorkflowVersionActive = () => {
        if ($scope.data.workflowVersionType === 'DRAFT') {
            return $scope.data.group?.buildEnvironmentEnabled ?? false;
        }

        return $scope.data.group?.workerEnabled ?? false;
    };

    $scope.onFieldEditorHasPendingChangesChanged = function (hasPendingChanges) {
        $scope.data.fieldEditorHasPendingChanges = hasPendingChanges;
    };

    /**
     * Navigate to a state while keeping the current state into for the return to button
     * @param stateName
     */
    $scope.goToCommunicationSourcesWithReturnTo = function () {
        $state.go('product.enterpriseComponents', {
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            fromName: $scope.data.group.name,
            tab: 'communication-sources',
        });
    };

    function openChangeEnvironmentWithPendingChangesModal() {
        $scope.mboxData = {
            title: 'Moving from Build Environment to Production',
            html: `You have some unsaved changes.<br />Are you sure you want to discard and move to Production Environment? `,
            isWarn: false,
            cancelLabel: 'Stay in Build',
            closeIcon: false,
            okLabel: 'Discard and move to Production',
            isReversedButtons: true,
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                $scope.data.fieldEditorHasPendingChanges = false;
                $scope.onWorkflowVersionSelected(undefined, 'production');
            });
    }

    $scope.toggleGroupDescription = (open) => {
        $timeout(() => {
            $scope.data.showGroupDescription = !!open;
        });
    };

    $scope.onRecurrenceTimeSelectionChanged = function () {
        const workflowVersion = $scope.wvm.getCachedWorkflowVersion($scope.data.workflowVersionId);

        if (workflowVersion.isScheduled) {
            switch (workflowVersion.recurrencePeriodType) {
                case 'EveryXMinutes':
                    $scope.data.scheduledDetails = `Runs every ${workflowVersion.everyXMinutes} minutes`;
                    break;
                case 'EveryXHours':
                    $scope.data.scheduledDetails = `Runs every ${workflowVersion.everyXHours} hour${
                        workflowVersion.everyXHours > 1 ? 's' : ''
                    }`;
                    break;
                case 'Daily':
                    const ampm = workflowVersion.recurrenceHour >= 12 ? 'PM' : 'AM';
                    const hour = $scope.convert24HourTimeTo12HourTime(workflowVersion.recurrenceHour);
                    $scope.data.scheduledDetails = `Runs daily at ${hour} ${ampm}`;
                    break;
                default:
                    $scope.data.scheduledDetails = `Runs ${workflowVersion.recurrencePeriodType}`;
                    break;
            }
        }
    };

    $scope.onDiscardPendingChangesModalOpened = function () {
        $scope.data.isOutlineSidePaneOpen = false;
    };

    /**
     * Converting a 24h clock hour value to a 12h clock hour value
     * @param hour
     * @returns {number|*}
     */
    $scope.convert24HourTimeTo12HourTime = function (hour) {
        if (hour === 0) {
            return 12;
        }

        if (hour > 12) {
            return hour - 12;
        }

        return hour;
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('WorkerEditorCtrl', WorkerEditorCtrl);
