import { createContext } from 'react';

import type { Group, Project } from '@tonkean/tonkean-entities';

export interface StatusListDataContextProps {
    projectId: Project['id'] | undefined;
    groupId: Group['id'] | undefined;
    viewOnly: boolean | undefined;
    loading: boolean | undefined;
}

const StatusListDataContext = createContext<StatusListDataContextProps>({
    projectId: undefined,
    groupId: undefined,
    viewOnly: undefined,
    loading: undefined,
});

export default StatusListDataContext;
