function MoreAvatarsCtrl(
    $scope,
    $uibModalInstance,
    avatars,
    anonymousUsersPatters,
    modPalette,
    anonymousUserName,
    utils,
    shouldDisplayViewTime,
) {
    $scope.utils = utils;

    $scope.data = {
        anonymousUsersPatters,
        modPalette,
        anonymousUserName,
        avatars,
        shouldDisplayViewTime,
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

export default angular.module('tonkean.app').controller('MoreAvatarsCtrl', MoreAvatarsCtrl);
