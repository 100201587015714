import template from './tnkManageTriggers.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkManageTriggers', {
    bindings: {
        groupId: '<',
        hideBotToggle: '<',
        hideCloseBtn: '<',
        modSlim: '<',
        viewOnly: '<',
        onClose: '&',
    },
    template,
    controller: 'ManageTriggersCtrl',
});
