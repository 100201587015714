import template from './tnkWorkerLogic.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerLogic', {
    bindings: {
        // The sync object holds properties that populate through the tree: selectedLogicId.
        // We use a single object so no change will trigger full tree on change cycle.
        // Angular will stop us after 10 levels are reached so this is our solution.
        syncObject: '<',
        configuredLogic: '<',
        invalidLogics: '<',
        groupId: '<',
        workflowVersionId: '@',
        isParentDisabled: '<',
        canModifyBot: '<',
        closeSidePaneCallback: '&',
        createNewLogicCallback: '&',
        selectLogicCallback: '&',
        selectOutlineTabCallback: '&',
    },
    template,
    controller: 'WorkerLogicCtrl',
});
