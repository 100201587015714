<div class="padding-right view-function-popover-content" style="width: 300px; max-width: 300px">
    <div class="flex-vmiddle flex-wrap flex-self-end">
        <div class="flex-grow">
            <div
                class="hidden-sub common-size-xxxxs common-color-grey text-left"
                ng-if="itemMap[item.trackId].parentInitiatives && itemMap[item.trackId].parentInitiatives.length"
            >
                <span
                    ng-repeat="p in itemMap[item.trackId].parentInitiatives"
                    ng-if="p.title || trackHelper.getInitiativeFromCache(p.id).title"
                >
                    <a
                        ui-sref="product.interface.group({groupId: itemMap[item.trackId].group.id, initiativeId: item.id})"
                        class="common-color-grey margin-right-xs"
                    >
                        {{ p.title || trackHelper.getInitiativeFromCache(p.id).title }}
                    </a>
                    <i class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
                </span>
            </div>
            <div>
                <strong class="common-bold">{{ itemMap[item.trackId].title }}</strong>
            </div>
        </div>
        <div
            class="margin-none flex-no-shrink common-tag-full mod-grey common-size-xxxxs common-status-tag"
            ng-style="{ 'background-color': itemMap[item.trackId].stateColor ? itemMap[item.trackId].stateColor : '' }"
        >
            {{ itemMap[item.trackId].stateText ? itemMap[item.trackId].stateText : 'Not started' }}
        </div>
    </div>
    <hr
        class="margin-normal-sm-v"
        ng-if="
            itemMap[item.trackId].updater ||
            itemMap[item.trackId].function ||
            itemMap[item.trackId].eta ||
            itemMap[item.trackId].updateText
        "
    />
    <div class="flex-grow flex flex-vmiddle flex-wrap">
        <div
            class="flex-grow margin-right flex-vmiddle common-size-xxxxs"
            ng-if="itemMap[item.trackId].updater || itemMap[item.trackId].function"
        >
            <div class="track-leader-avatar mod-border avatar-main img-circle avatar">
                <img
                    class="avatar-img img-circle"
                    ng-if="itemMap[item.trackId].updater && itemMap[item.trackId].updater.avatarUri"
                    ng-src="{{ itemMap[item.trackId].updater.avatarUri }}"
                />

                <span
                    ng-if="!itemMap[item.trackId].updater || !itemMap[item.trackId].updater.avatarUri"
                    class="avatar-img img-circle svg-icon-lg"
                >
                    <tnk-icon src="../../../../../apps/tracks/images/icons/avatar.svg"></tnk-icon>
                </span>
            </div>
            <span class="margin-left-xs common-color-dark-grey" ng-if="itemMap[item.trackId].function">
                {{ itemMap[item.trackId].function.name }}
            </span>
            <span class="margin-left-xs common-color-dark-grey">
                {{ itemMap[item.trackId].function && itemMap[item.trackId].updater ? ' > ' : '' }}
            </span>
            <span class="margin-left-xs common-color-dark-grey" ng-if="itemMap[item.trackId].updater">
                {{ itemMap[item.trackId].updater.name }}
            </span>
        </div>
        <div class="common-color-grey common-size-xxxxs" ng-show="itemMap[item.trackId].stateUpdated">
            <span>{{ itemMap[item.trackId].stateUpdated | timeAgo }}</span>
            <span ng-if="itemMap[item.trackId].updatedViaBot" class="common-size-xxxxs">
                <span class="tag-sample mod-spaced mod-small-font common-bold">VIA BOT</span>
            </span>
        </div>
    </div>

    <div ng-if="itemMap[item.trackId].updateText && itemMap[item.trackId].updateText.length">
        <div class="padding-normal-sm flex pointer margin-left">
            <i class="fa fa-quote-left common-color-blue-light margin-right"></i>

            <div class="flex-grow common-text-wrap">
                <div light-markdown="itemMap[item.trackId].updateText | limitLength : 150"></div>
            </div>
        </div>
    </div>
    <div
        class="common-color-blue-dark common-size-xxxxs margin-top flex-no-shrink relative"
        ng-if="itemMap[item.trackId].eta"
    >
        ETA: {{ itemMap[item.trackId].eta | daysDiff }}
    </div>
    <hr
        class="margin-normal-sm-v"
        ng-if="
            (itemMap[item.trackId].fields && itemMap[item.trackId].fields.length) ||
            (itemMap[item.trackId].description && itemMap[item.trackId].description.length)
        "
    />
    <!--sync details-->
    <div ng-if="itemMap[item.trackId].externalId" class="common-size-xxxxs common-color-dark-grey margin-top-xs">
        <span>Synced with&nbsp;</span>
        <!-- if there is an external link show as link-->
        <a ng-if="itemMap[item.trackId].externalUrl" ng-href="{{ itemMap[item.trackId].externalUrl }}" target="_blank">
            <span>
                {{ itemMap[item.trackId].externalSourceFriendly || itemMap[item.trackId].externalSource | capitalize }}
            </span>
        </a>
        <!-- if no link just show source name-->
        <span ng-if="!itemMap[item.trackId].externalUrl">
            {{ itemMap[item.trackId].externalSourceFriendly || itemMap[item.trackId].externalSource | capitalize }}
        </span>
        <hr class="margin-normal-sm-v" />
    </div>
    <!-- Data fields -->
    <div
        ng-repeat="field in (itemMap[item.trackId].fields | limitTo:10)"
        ng-if="
            (field.fieldDefinition.type.toLowerCase() === 'manual' || field.isAssociated) &&
            field.value &&
            !field.fieldDefinition.systemUtilized
        "
        class="common-size-xxs margin-top-xs common-ellipsis"
    >
        <strong>{{ field.fieldDefinition.name }}:&nbsp;</strong>
        <span ng-style="field.style">{{ field.displayValue || field.value }}</span>
        <span
            class="margin-left-xs common-size-xxxxs pointer"
            ng-if="field.change > 0 || field.change < 0"
            ng-class="{ 'common-color-danger': field.negative, 'common-color-success': field.positive }"
        >
            <i class="fa fa-caret-{{ field.trend }}"></i>
        </span>
    </div>
    <!-- Description -->
    <div class="initiative-description-container margin-top-lg show-edit-when-hover-visibility relative">
        <!-- edit btn -->
        <div
            ng-if="pm.isUserLicensed && !popover.editDesc && !viewOnlyMode"
            ng-click="popover.editDesc = true"
            ng-class="{
                'btn-on-hover absolute-top-right':
                    itemMap[item.trackId].description && itemMap[item.trackId].description.length
            }"
            class="inner-btn common-z-index-1 common-size-xxxxxs pointer common-color-link-blue margin-top-minus-xxs"
        >
            <div class="flex-vmiddle">
                <div class="svg-icon-xxxs svg-icon-vmiddle margin-right-xxs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/pencil-o.svg"></tnk-icon>
                </div>
                <div>
                    {{
                        itemMap[item.trackId].description && itemMap[item.trackId].description.length
                            ? 'EDIT'
                            : 'Add
                    description...'
                    }}
                </div>
            </div>
        </div>

        <!-- desc -->
        <div
            class="relative initiative-description-box mod-sm initiative-growable"
            ng-if="itemMap[item.trackId].description && itemMap[item.trackId].description.length"
            ng-hide="popover.editDesc"
            ng-class="{ 'mod-no-max': popover.expandDesc }"
        >
            <div class="common-color-dark-grey common-size-xxs clearfix initiative-description-content">
                <div class="flex-grow" light-markdown="itemMap[item.trackId].description"></div>
            </div>
            <div
                class="initiative-growable-hook mod-sm show-edit-when-hover-visibility inner-btn"
                ng-click="popover.expandDesc = true"
                ng-hide="popover.expandDesc"
            >
                <div class="text-right edit-button" ng-if="itemMap[item.trackId].description.length > 100">
                    <span class="common-size-xxxxxs common-color-primary">Show all...</span>
                </div>
            </div>
        </div>

        <!-- Edit description -->
        <div ng-if="popover.editDesc" ng-init="popover.description = itemMap[item.trackId].description">
            <textarea
                class="
                    flex-grow
                    form-control
                    initiative-description-inline-textbox
                    common-no-outline common-resize-none
                    mod-used
                "
                id="initiative-description-txt"
                ng-model="popover.description"
                placeholder="Description"
                autofocus
                maxlength="2000"
                prevent-scroll-bubbling
            ></textarea>
            <div class="flex-vmiddle margin-top-xs">
                <button
                    class="btn inner-btn btn-xs btn-default margin-right-xs"
                    ng-click="popover.editDesc = false; popover.description = ''"
                >
                    Cancel
                </button>
                <button class="btn inner-btn btn-xs btn-primary" ng-click="updateDescription(popover.description)">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
