import { angularToReact } from '@tonkean/angular-components';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

export interface TonkeanExpressionProps {
    groupId?: string;
    logicId?: string;
    workflowVersionId?: string;
    workflowFolderId?: string;
    entityVersionType?: string;
    savedOriginalExpression?: string;
    savedEvaluatedExpression?: string;
    overrideExampleInitiative?: string;
    onTonkeanExpressionChanged(
        originalExpression: string,
        evaluatedExpression: string,
        expression?: TonkeanExpressionDefinition,
        shouldSaveLogic?: boolean,
    ): void;
    showFormulasTab?: boolean;
    doNotEvaluatePreview?: boolean;
    saveOnKeyUp?: boolean;
    disabled?: boolean;
    disabledReason?: string;
    placeholder?: string;
    hidePreview?: boolean;
    globalExpressionOnly?: boolean;
    additionalTabs?: TonkeanExpressionAdditionalTab[];
    customPreview?: string;
    modTextArea?: boolean;
    showPeopleDirectories?: boolean;
    textAreaNumberOfRows?: number;
    previewEvaluationSource?: any;
    prefix?: string;
    hideEditorButton?: boolean;
    defaultTabId?: string;
    excludedTabSelectorSpecialFields?: string[];
    includeTabSelectorSpecialFieldsForFeatures?: string[];
    automationIdentifierExpressionValue?: string;
    automationIdentifierExpressionAddField?: string;
    shouldDeleteVariable?: (string) => boolean;
    expressionUniqueIdentifier?: string;
    fieldsToFilter?: string[];
}

const TonkeanExpression = angularToReact<TonkeanExpressionProps>('tnk-tonkean-expression', {
    onTonkeanExpressionChanged: ['originalExpression', 'evaluatedExpression', 'expression', 'shouldSaveLogic'],
});

export default TonkeanExpression;
