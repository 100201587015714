<div class="titled-input">
    <span class="titled-label" ng-show="value.length" style="">{{ defaultValue.toUpperCase() }}</span>
    <input
        type="text"
        name="{{ inputName }}"
        class="form-control-transparent titled-input-field"
        ng-class="value.length ? 'titled-input-filled' : 'titled-input-empty'"
        ng-model="value"
        ng-disabled="inputDisabled"
        ng-attr-autofocus="{{ focus }}"
        ng-attr-required="{{ isRequired }}"
        placeholder="{{ defaultValue }}"
        ng-required="{{ isRequired }}"
        tnk-phone-input="{{ isPhone }}"
    />
    <span class="titled-label-required">(required)</span>
</div>
