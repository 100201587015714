<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="signIn()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group">
                <label for="jira-url" class="col-sm-2 control-label">URL</label>

                <div class="col-sm-10">
                    <div class="flex-vmiddle">
                        <!-- Protocol label -->
                        <div>https://</div>

                        <!-- Subdomain Input -->
                        <div class="flex-grow margin-right-xxs">
                            <input
                                type="text"
                                ng-model="data.subDomain"
                                class="form-control"
                                id="jira-url"
                                placeholder="Your sub-domain"
                                required
                            />
                        </div>

                        <!-- Jira url suffix -->
                        .{{ data.jiraDomain }}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-username" class="col-sm-2 control-label">Email</label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        ng-model="data.user"
                        class="form-control"
                        id="login-username"
                        placeholder="me@example.com"
                        required
                    />
                </div>
            </div>
            <div class="form-group">
                <label for="login-password" class="col-sm-2 control-label">API Token</label>

                <div class="col-sm-10">
                    <input
                        type="password"
                        ng-model="data.password"
                        class="form-control"
                        id="login-password"
                        autocomplete="new-password"
                        placeholder="Password"
                        required
                    />
                    <div class="common-subtitle-inner">
                        <i>
                            To generate API Token, go
                            <a href="https://id.atlassian.com/manage/api-tokens" target="_blank">here</a>
                        </i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>

        <h4>Which Jira projects are relevant to this team?</h4>
        <hr />
        <div ng-show="selectedProjects.length">
            <!--<h4>Selected Projects:</h4>-->
            <div ng-repeat="selected in selectedProjects">
                <div class="panel panel-default">
                    <div class="panel-heading flex-vmiddle">
                        <h4 class="flex-grow">
                            {{ selected.project.displayName }}
                            <i class="fa fa-check common-color-secondary margin-left"></i>
                        </h4>
                        <button
                            type="button"
                            class="btn btn-default btn-sm pull-right"
                            analytics-on
                            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                            analytics-event="Delete jira proj"
                            analytics-label="{{ currentIntegration.name }}"
                            ng-click="removeProject($index)"
                        >
                            Remove
                        </button>
                    </div>
                    <div class="panel-body">
                        <div ng-hide="selected.projectComponents.length">
                            All issues in this project will be analyzed.
                        </div>
                        <div class="flex-vmiddle" ng-show="selected.projectComponents.length">
                            <div class="margin-right">
                                Components
                                <div class="common-subtitle-inner">(Optional)</div>
                            </div>
                            <div class="flex-grow">
                                <tags-input
                                    ng-model="selected.components"
                                    display-property="displayName"
                                    placeholder="Specify specific components"
                                    key-property="value"
                                >
                                    <auto-complete
                                        source="selected.projectComponents|filter:{displayName:$query}"
                                        min-length="0"
                                        load-on-focus="true"
                                        max-results-to-show="32"
                                    ></auto-complete>
                                </tags-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-vmiddle" ng-hide="filteredProjects.length === 0">
            <div class="flex-grow margin-right">
                <ui-select
                    theme="bootstrap"
                    ng-model="temp.project"
                    ng-disabled="addingProject||filteredProjects.length===0"
                >
                    <ui-select-match placeholder="Select a Project">{{ $select.selected.displayName }}</ui-select-match>
                    <ui-select-choices repeat="p in filteredProjects | filter:$select.search">
                        <div ng-bind-html="p.displayName | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <div ng-if="projectIntegration" class="margin-top-md">
            <div class="panel panel-default">
                <div class="panel-heading flex-vmiddle">
                    <h4 class="flex-grow">
                        Jira users mapping
                        <small ng-if="data.usersToMap.totalCount !== 0" class="common-size-xs common-color-grey">
                            (mapped {{ data.usersToMap.mappedCount }} out of {{ data.usersToMap.totalCount }})
                        </small>
                    </h4>
                    <button
                        type="button"
                        class="btn btn-default btn-sm pull-right"
                        ng-click="modalUtils.openMapIntegrationUsersModal(projectIntegration, 'externalId', data.usersToMap, onUsersMapped)"
                    >
                        Edit
                    </button>
                </div>
                <div class="panel-body">
                    Map Jira users with Tonkean users in order to use human coordination capabilities.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="common-color-danger pull-left" ng-show="okClicked && selectedProjects.length === 0">
        You must select at least one project
    </div>
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
