import type { DataFields, Resolver } from '@urql/exchange-graphcache';

import type { TonkeanGraphqlCacheQueryResolversConfig } from './getTonkeanGraphqlCacheQueryResolversConfig';

/**
 * This tells urql which queries are "get entity by id", which allows for cache lookup before sending an http request.
 * For example, we know that `trainingSet(id: "TRSE1")` query returns training set of id "TRSE1", so we can look at
 * the cache instead of sending an http request. But `trainingSetByProjectId(projectId: "PROJ1")` can't be resolved
 * without sending an http request, so it won't have a cache resolver.
 */
function getTonkeanGraphqlCacheQueryResolvers(
    queryResolversConfig: TonkeanGraphqlCacheQueryResolversConfig[],
): Record<string, Resolver> {
    const queryResolverEntries: [string, Resolver][] = queryResolversConfig.map(({ queryName, argName, typeName }) => {
        const resolveSingleId = (idArg: string | Record<string, string>) => {
            const ids = typeof idArg === 'string' ? { id: idArg } : idArg;
            return {
                __typename: typeName,
                ...ids,
            };
        };

        const resolver: Resolver<
            DataFields,
            Record<string, string | Record<string, string> | string[] | Record<string, string>[]>
        > = (_, args) => {
            const arg = args[argName]!;

            if (Array.isArray(arg)) {
                return arg.map(resolveSingleId);
            } else {
                return resolveSingleId(arg);
            }
        };

        return [queryName, resolver];
    });

    return Object.fromEntries(queryResolverEntries);
}

export default getTonkeanGraphqlCacheQueryResolvers;
