import React from 'react';

import DisableContent from './DisableContent';
import useEnvironmentFlags from '../hooks/useEnvironmentFlags';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

/**
 * Creates an overlay on the content in production mode.
 * Make sure to add in reactToAngular root - $state.
 * Doesn't trigger a render on url change for now - needs to be implemented.
 *
 * @example
 * <DisableOnProduction><input value="6" /></DisableOnProduction>
 */
const DisableOnProduction: React.FC<React.PropsWithChildren<StyledComponentsSupportProps>> = ({
    className,
    children,
}) => {
    const { isDraft } = useEnvironmentFlags();

    return (
        <DisableContent className={className} disable={!isDraft}>
            {children}
        </DisableContent>
    );
};

export default DisableOnProduction;
