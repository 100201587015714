import { useCallback, useEffect, useState } from 'react';

import type { LazyAsyncMethodRequestState } from './useLazyAsyncMethod';
import useLazyAsyncMethod from './useLazyAsyncMethod';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import type { DeferredPromise, KeysThatExtend, ResolveValue } from '@tonkean/utils';
import { getDeferredPromise } from '@tonkean/utils';

export type AsyncMethodRequestState<
    ERROR = any,
    OBJECT extends Record<string, any> = any,
    KEY extends KeysThatExtend<OBJECT> = any,
> = Omit<LazyAsyncMethodRequestState<ERROR, OBJECT[KEY]>, 'called' | 'args' | 'promise'> & {
    args: Parameters<OBJECT[KEY]>;
    promise: Promise<ReturnType<OBJECT[KEY]>>;
    reader(): ResolveValue<ReturnType<OBJECT[KEY]>>;
    rerun(boolean?): void;
};

/**
 * React hook for handling async methods in a react way. It will trigger the method immediately on component load,
 * and will change automatically when one of the params changes.
 *
 * @example
 * const GroupName = ({groupId}) => {
 *     const groupInfoManager = useAngularService("groupInfoManager");
 *     const {data, error, loading} = useAsyncMethod(groupInfoManager, "getGroupById", groupId);
 *
 *     if (loading) {
 *         return <span className="loading" />;
 *     }
 *     if (error) {
 *         return <strong className="error">{ error.message }</strong>;
 *     }
 *     return <strong>{data.name}</strong>;
 * }
 *
 * @param object - the object that contains the method.
 * @param methodKey - the key of the method in the object.
 * @param args - the arguments to pass to the service
 * @returns an object with the value if the method resolves as data, the reason if the method rejects as error
 * and a boolean indicating if the method is currently loading as loading.
 */
function useAsyncMethod<
    ERROR = any,
    OBJECT extends Record<string, any> = any,
    KEY extends KeysThatExtend<OBJECT> = any,
>(object: OBJECT, methodKey: KEY, ...args: Parameters<OBJECT[KEY]>): AsyncMethodRequestState<ERROR, OBJECT, KEY> {
    const [state, , realTrigger] = useLazyAsyncMethod(object, methodKey);
    const [deferredPromise, setDeferredPromise] = useState<DeferredPromise<ReturnType<OBJECT[KEY]>>>(() =>
        getDeferredPromise(),
    );

    const run = useCallback(
        (updateState: boolean = true) => {
            const deferredPromiseToUse = deferredPromise.isPending() ? deferredPromise : getDeferredPromise();
            setDeferredPromise(deferredPromiseToUse);

            deferredPromiseToUse.convert(realTrigger(args, updateState));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [realTrigger, ...args],
    );

    useEffect(() => {
        // Timeout is used to send just one request and not two due to strict mode (same hack is used in react query)
        const timeout = setTimeout(() => {
            run();
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [run]);

    const rerun = useConstantRefCallback((changeStateOnLoading: boolean = false) => run(changeStateOnLoading));

    const reader = useCallback((): ResolveValue<ReturnType<OBJECT[KEY]>> => {
        if (state.loading || !state.called) {
            throw deferredPromise.promise;
        }
        if (state.error) {
            throw state.error;
        }

        return state.data as ResolveValue<ReturnType<OBJECT[KEY]>>;
    }, [state, deferredPromise]);

    return {
        ...state,
        promise: deferredPromise.promise,
        args,
        reader,
        rerun,
        loading: state.loading || !state.called,
    };
}

export default useAsyncMethod;
