import { useAngularService, useAngularWatch } from 'angulareact';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TextEllipsis } from '@tonkean/infrastructure';
import { ReturnIcon } from '@tonkean/svg';
import { XIcon } from '@tonkean/svg';
import StateLink from '@tonkean/tui-basic/StateLink';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

const FloatingButtonWrapper = styled(motion.div)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 50vw;
    top: 80vh;
    position: absolute;
    color: white;
    line-height: 36px;
    // We want it to be above the history modal
    z-index: 1051;
    max-width: 300px;
    width: max-content;
    transform: translate(-50%, 0);
`;

const ReturnIconWrapper = styled(ReturnIcon)`
    margin-right: 10px;
    display: flex;
    align-items: center;
    min-width: 16px;
    min-height: 16px;
`;

const Dismiss = styled.button`
    display: flex;
    height: 36px;
    background: ${Theme.current.palette.navigation.floatingReturnTo.background};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-style: none;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    padding: 12px 14px 12px 10px;

    &:hover {
        text-decoration: none;
        background: ${Theme.current.palette.navigation.floatingReturnTo.backgroundHover};
    }

    svg path {
        fill: white;
    }

    ${styledFocus}
`;

const FloatingButton = styled(StateLink)`
    display: flex;
    align-items: center;
    height: 36px;
    background: ${Theme.current.palette.navigation.floatingReturnTo.background};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    color: white;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    padding: 10px 10px 10px 20px;
    border-right: 1px solid ${Theme.current.palette.navigation.floatingReturnTo.innerBorder};

    &:hover {
        text-decoration: none;
        color: white;
        background: ${Theme.current.palette.navigation.floatingReturnTo.backgroundHover};
    }

    ${styledFocus}
`;

const FloatingReturnToButton: React.FC = () => {
    const $state = useAngularService('$state');
    const [stateParams] = useAngularWatch(() => $state.params);
    const [isShown, setIsShown] = useState<boolean>(stateParams.fromState && stateParams.fromStateParams);

    const stateParamsWithoutFloatingParams = useMemo(() => {
        const { fromState, toState, fromName, fromStateParams, ...rest } = stateParams;
        return rest;
    }, [stateParams]);

    useEffect(() => {
        setIsShown(stateParams.fromState && stateParams.fromName);
    }, [stateParams]);

    return (
        <>
            {isShown && (
                <FloatingButtonWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <FloatingButton state={stateParams.fromState} params={stateParams.fromStateParams}>
                        <ReturnIconWrapper />
                        <TextEllipsis tooltipLimitWidth={200} numberOfLines={1} tooltip>
                            Return to {stateParams.fromName}
                        </TextEllipsis>
                    </FloatingButton>
                    <Dismiss
                        onClick={() => {
                            setIsShown(false);
                            $state.go('.', stateParamsWithoutFloatingParams, {
                                notify: false,
                                inherit: false,
                            });
                        }}
                    >
                        <XIcon />
                    </Dismiss>
                </FloatingButtonWrapper>
            )}
        </>
    );
};

export default FloatingReturnToButton;
