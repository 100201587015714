import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import StatusSections from './components/StatusSections';
import DisableOnProduction from '../../../infrastructure/components/DisableOnProduction';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { H4, SimpleErrorStateMessage, Spacer } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { Group, State, WorkflowVersion } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Header = styled(H4)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 8px;
`;

interface Props {
    workflowVersionId: WorkflowVersion['id'];
    groupId: Group['id'];
    viewOnly: boolean;
}

const StatusesWorkerOutline: React.FC<Props> = ({ workflowVersionId, groupId, viewOnly }) => {
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const { id: projectId } = useProject();

    const [{ error }, updateWorkflowVersionStates] = useLazyTonkeanService('updateWorkflowVersionStates');

    const onSubmit = async (statuses: State[]) => {
        if (viewOnly) {
            return Promise.resolve();
        }

        const fixedStatuses = statuses
            .map((state) => ({ ...state, id: state.id === 'NEW' ? undefined : state.id }))
            .filter(({ label }) => label?.trim() !== '');

        await updateWorkflowVersionStates(groupId, fixedStatuses, false);
        workflowVersionManager.incrementDraftCounter(groupId);

        // To get The cache
        groupInfoManager.getGroup(groupId, true);
    };

    return (
        <DisableOnProduction>
            <Spacer height={16} />

            <Header $color={Theme.colors.gray_700}>
                A status signifies key stages in your business process. During the information collection phase, intake
                statuses don't trigger business logic by default. Triage and coordination statuses, used after request
                submission, activate triggers. Drag statuses between groups to change their type.
                {error && <SimpleErrorStateMessage error={error} showSmallError />}
            </Header>

            <StatusSections
                workflowVersionId={workflowVersionId}
                groupId={groupId}
                projectId={projectId}
                onChange={onSubmit}
                viewOnly={viewOnly}
            />
        </DisableOnProduction>
    );
};

export default StatusesWorkerOutline;
