import React from 'react';
import styled from 'styled-components';

import thirdPartyIconsByCategory from './ThirdPartyIconsByCategory.json';
import { getIconComponent } from './WidgetIconUtils';

import { LogicIcon } from '@tonkean/infrastructure';
import { useActualColorToDisplay } from '@tonkean/infrastructure';
import type { LogicBlockConfigDefinition } from '@tonkean/logic-block-configs';
import { NoIcon, TonkeanIcon } from '@tonkean/svg';
import { PresetItemInterfaceWidgetType } from '@tonkean/tonkean-entities';
import type { CustomTriggerType, Icon, IntegrationType, LogicBlockConfigType } from '@tonkean/tonkean-entities';
import { type Color, Theme } from '@tonkean/tui-theme';
import { classNames, colorSvg } from '@tonkean/utils';

const TONKEAN_ICON_NAME = 'tonkean';

const StyledLogicIcon = styled(LogicIcon)`
    margin-right: 3px;
`;

const IconWrapper = styled.div<{ $size: number; $color: Color | undefined }>`
    svg {
        background-size: contain !important;
        background: no-repeat;
        height: ${({ $size }) => $size}px;
        width: ${({ $size }) => $size}px;
        display: flex;
        ${({ $color }) => $color && colorSvg($color)}
    }
`;

const StyledNoIcon = styled(NoIcon)<{ width?: number; height?: number }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border: 1px solid ${Theme.colors.gray_400};
`;

export const NO_ICON: string = 'no icon';

// component that receives an icon and display the correct icon from the system accordingly
interface Props {
    icon?: Icon;
    size?: number;
    dontShowEmptyIcon?: boolean;
    integrationTypesForIcon?: {
        integrationTypes: IntegrationType[];
        hasFieldsWithoutIcon: boolean;
    };
    showMatchedEntityIcon?: boolean | undefined;
    customTriggerTypes?: CustomTriggerType[];
    logicBlocks: Record<LogicBlockConfigType, LogicBlockConfigDefinition>;
}

const TonkeanIconDisplayInner: React.FC<Props> = ({
    icon,
    size = 20,
    dontShowEmptyIcon = false,
    integrationTypesForIcon,
    showMatchedEntityIcon,
    customTriggerTypes,
    logicBlocks,
}) => {
    const colorOverride = useActualColorToDisplay(icon?.iconColor, icon?.customColorId, Theme.colors.gray_700);

    // If no icon was received it will display a no-icon display in the icon picker
    // If it is in the widget header and not in the icon picker, it will not display anything
    if (!icon || icon?.name === NO_ICON) {
        return dontShowEmptyIcon ? (
            <></>
        ) : (
            <IconWrapper $size={size} $color={undefined}>
                <StyledNoIcon width={size} height={size} />
            </IconWrapper>
        );
    } else if (icon.name === TONKEAN_ICON_NAME) {
        return (
            <span className="tnk-icon">
                <TonkeanIcon
                    style={{
                        height: `${size}px`,
                        width: `${size}px`,
                        fill: colorOverride,
                        stroke: colorOverride,
                    }}
                    key={`${icon.name} component`}
                />
            </span>
        );
    } else if (icon?.name === PresetItemInterfaceWidgetType.MATCHED_ENTITY) {
        if (!showMatchedEntityIcon) {
            return dontShowEmptyIcon ? (
                <></>
            ) : (
                <IconWrapper $size={size} $color={undefined}>
                    <StyledNoIcon width={size} height={size} />
                </IconWrapper>
            );
        }
        if (integrationTypesForIcon?.integrationTypes && integrationTypesForIcon?.integrationTypes?.length > 0) {
            return (
                <>
                    {integrationTypesForIcon?.integrationTypes.map((integrationType) => (
                        <StyledLogicIcon
                            key={integrationType}
                            integrationType={integrationType}
                            logicBlockType={undefined}
                            projectIntegrationId={undefined}
                            size={size}
                            disableTooltip
                        />
                    ))}
                </>
            );
        } else {
            return (
                <StyledLogicIcon
                    key={customTriggerTypes?.[0] || 'customTriggerType'}
                    integrationType={undefined}
                    logicBlockType={logicBlocks[customTriggerTypes?.[0] || 0]}
                    projectIntegrationId={undefined}
                    size={size}
                    disableTooltip
                />
            );
        }
    } else if (icon.integrationName || icon.integrationId) {
        return (
            <LogicIcon
                integrationType={icon.integrationName}
                logicBlockType={icon.blockType}
                projectIntegrationId={icon.integrationId}
                size={size}
                key={icon.integrationName}
                iconUrl={icon.iconUrl}
            />
        );
    } else if (getIconComponent(icon)) {
        const IconComponent = getIconComponent(icon);
        return (
            <IconWrapper $size={size} $color={colorOverride}>
                <IconComponent />
            </IconWrapper>
        );
    } else if (icon.isFullClassName) {
        return <div className={icon.iconClass} key={icon.name} style={{ fontSize: size }} />;
    } else {
        icon.iconColor = colorOverride;
        const iconsByCategories = Object.values(thirdPartyIconsByCategory);
        const iconClassName = iconsByCategories
            .flatMap((item) => item.icons)
            .find((iconName) => iconName === icon.name);

        if (iconClassName) {
            const firstClassName = icon.iconClass ? `fa-${icon.iconClass}` : 'fa-solid';
            return (
                <div
                    className={classNames(firstClassName, `fa-${icon.name}`)}
                    key={icon.name}
                    style={{ fontSize: size }}
                />
            );
        }
    }
    return <StyledNoIcon width={size} height={size} />;
};

export default TonkeanIconDisplayInner;
