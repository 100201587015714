import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsInput from './FormSettingsInput';
import FormSettingsLabel from './FormSettingsLabel';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';

import { FormBuilderContext } from '@tonkean/infrastructure';
import { Tooltip, useUUID } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export const SubmitButtonLabel = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    form: Form;
    onFormDefinitionChanged: (submitFormButtonLabel: string | undefined) => void;
}

const FormSettingsSubmitButtonLabelSection: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    const id = useUUID();

    return (
        <>
            <FormSettingsLabel htmlFor={id}>
                <SubmitButtonLabel>Edit Submit Button Label</SubmitButtonLabel>
                <FormSettingsSectionHeaderSubtitle>
                    * Slack dialogs support only one word.
                </FormSettingsSectionHeaderSubtitle>
            </FormSettingsLabel>
            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <FormSettingsInput
                    data-automation="form-settings-submit-button-label-section-submit-form"
                    placeholder="Submit Form"
                    onChange={({ target: { value } }) => onFormDefinitionChanged(value)}
                    value={form.definition.submitFormButtonLabel ?? ''}
                    id={id}
                    disabled={!isEditable}
                    withMarginTop={false}
                    halfWidth
                />
            </Tooltip>
        </>
    );
};

export default FormSettingsSubmitButtonLabelSection;
