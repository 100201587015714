import { useEffect, useRef } from 'react';

/**
 * A function to retain previous value of given parameter.
 * The function uses a `ref` so it does not trigger re-renders.
 *
 * @param value The value to keep track of
 * @param ignoreFalsy whether to skip falsy values
 */
function usePrevious<T>(value: T, ignoreFalsy: boolean = false) {
    const ref = useRef<T>();

    useEffect(() => {
        if (ignoreFalsy && !value) {
            return;
        }

        ref.current = value;
    }, [ignoreFalsy, value]);

    return ref.current;
}

export default usePrevious;
