<div class="test-bot-modal common-close-btn-container">
    <div>
        <div class="common-close-btn margin-normal-sm" ng-click="$dismiss()">
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <div class="common-title-sm margin-bottom-xs">
            Send yourself
            {{
                data.communicationIntegration
                    ? 'a ' + data.communicationIntegration.displayName + '
            message'
                    : 'an Email'
            }}
            preview of the
            <br />
            Tonkean Module message
        </div>
        <div>
            Use this to
            <span class="common-bold">send yourself a test</span>
            of how Tonkean BOT's reach-
        </div>
        <div>out will look like to a item owner.</div>

        <div class="margin-top-xlg padding-top">
            <div class="flex">
                <div class="flex-grow flex flex-col margin-right-lg" ng-init="data.donePreparingTest">
                    <input
                        type="text"
                        ng-model="data.testItemName"
                        ng-maxlength="140"
                        placeholder="Item name"
                        required
                        class="form-control test-bot-input common-width-full-xs margin-bottom-xxs"
                    />
                    <div class="common-color-light-grey2 common-size-xxxxs margin-bottom-md margin-left-xxs">
                        ex: a customer name, project name or initiative
                    </div>
                    <tnk-preview-bot-bubble-ctrl
                        class="visible-xs-block"
                        text="data.testItemName"
                        placeholder="Your item name will appear here"
                        preview-type="question"
                        is-email="!cis.anyIntegrationsConnected()"
                        user="as.currentUser"
                    ></tnk-preview-bot-bubble-ctrl>
                    <div class="flex-grow"></div>
                    <button
                        class="test-bot-send-button btn btn-primary common-no-outline margin-bottom-lg"
                        ng-disabled="data.preparingTest"
                        ng-click="sendButtonClicked()"
                    >
                        <span ng-if="!data.preparingTest && !data.donePreparingTest">Send me a preview</span>
                        <div
                            ng-if="data.preparingTest || data.donePreparingTest"
                            class="flex-vmiddle"
                            ng-class="{ 'fade-out-up': data.donePreparingTest }"
                        >
                            <i class="loading-small common-color-white margin-right-xs"></i>
                            <div>Sending...</div>
                        </div>
                        <div ng-if="data.donePreparingTest" class="flex-vmiddle fade-in-up">
                            <i class="margin-right-xs">
                                <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                            </i>
                            <div>Sent!</div>
                        </div>
                    </button>
                </div>

                <tnk-preview-bot-bubble-ctrl
                    class="hidden-xs"
                    text="data.testItemName"
                    placeholder="Your item name will appear here"
                    preview-type="question"
                    is-email="!cis.anyIntegrationsConnected()"
                    user="as.currentUser"
                ></tnk-preview-bot-bubble-ctrl>
            </div>
        </div>
    </div>
</div>
