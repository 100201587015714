import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class GreedyHtmlPathFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.GREEDY_HTML_PATH;
    override readonly sign = 'GreedyHtmlPath';
    override readonly displayName = 'Greedy HTML Path';
    override readonly description = 'Will return the elements of given DOM traversal or CSS selectors from html.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Html',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'DOM traversal or CSS selectors',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
