import React from 'react';
import styled from 'styled-components';

import { TextEllipsis } from '@tonkean/infrastructure';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';
import { initiativeTitle } from '@tonkean/utils';

const LinkValue = styled(StateLink)`
    text-decoration: underline;
    color: ${Theme.colors.gray_800};
`;

interface Props {
    initiativeId?: string;
    title?: string;
    groupId: string;
    testRun: boolean;
}

const SingleWorkerRunModuleItemInitiativeLink: React.FC<Props> = ({ initiativeId, title, groupId, testRun }) => {
    const titleToDisplay = initiativeTitle(title, testRun, initiativeId);

    return (
        <LinkValue
            state="product.interface.group"
            params={{
                initiativeId,
                groupId,
            }}
            openInNewTab
        >
            <TextEllipsis numberOfLines={2} tooltip>
                {titleToDisplay}
            </TextEllipsis>
        </LinkValue>
    );
};

export default SingleWorkerRunModuleItemInitiativeLink;
