/* jshint ignore:start */
// this is because jshint doesnt know the keyword const (when in dist host)

import { TimeUtils } from '@tonkean/shared-services';

function TimeDiffShortDiff(timeUtils) {
    const timeFrames = [
        {
            diffFunc: timeUtils.daysDiff,
            label: ' d',
        },
        {
            diffFunc: timeUtils.hoursDiff,
            label: ' hr',
        },
        {
            diffFunc: timeUtils.minutesDiff,
            label: ' min',
        },
    ];

    return function (time, isAbsolute) {
        for (const frame of timeFrames) {
            let diff = frame.diffFunc(time);
            if (diff) {
                if (isAbsolute) {
                    diff = Math.abs(diff);
                }
                return diff + frame.label;
            }
        }

        return 'Now';
    };
}
export default angular.module('tonkean.app').filter('timeDiffShortDiff', TimeDiffShortDiff);
const timeDiffShortDiffInstance = new TimeDiffShortDiff(TimeUtils);
export { timeDiffShortDiffInstance as timeDiffShortDiffFilter };

/* jshint ignore:end */
