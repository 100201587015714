import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ConvertToBooleanFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_TO_BOOLEAN;
    override readonly sign = 'ConvertToBoolean';
    override readonly displayName = 'Convert to Boolean';
    override readonly description =
        'Will get the boolean value of the input. Can accept the strings "true" or "false" or the numbers 1 or 0.';
    override readonly dataType = FieldType.Boolean;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: [FieldType.Boolean, FieldType.Number, FieldType.String],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
