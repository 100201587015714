import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Checkbox, Field, FormikAutosave, H4, Input, TagsInput } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { FieldMetadata } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    background-color: ${Theme.colors.gray_100};
`;

const ContentWrapper = styled.div`
    padding: 6px 16px 16px 16px;
    white-space: pre;
    overflow-y: scroll;
    height: 100%;
`;

const MetadataInput = styled(Input)`
    width: 500px;
`;

const FieldWrapper = styled.div`
    align-items: flex-end;
    display: grid;
    grid-auto-flow: row;
`;

const MetadataField = styled(Field)`
    margin-top: 24px;
`;

const MetadataOverridableField = styled(Field)`
    margin-top: 2px;
`;

const CheckBoxWrapper = styled(Checkbox)`
    margin-top: 10px;
`;

const HeaderWrapper = styled.div`
    padding: 20px 16px 5px 16px;
`;

const HeaderText = styled(H4)`
    margin: 4px 0 8px 0;
`;

const FieldMetadataSchema = Yup.object({
    fieldName: Yup.string().required(),
    fieldLabel: Yup.string().required(),
    isLabelOverridable: Yup.boolean().required(),
    fieldLabelOverridable: Yup.string().when('isLabelOverridable', {
        is: true,
        then: Yup.string().required('You must fill field label overridable'),
    }),
    fieldType: Yup.string().required(),
    isTypeOverridable: Yup.boolean().required(),
    overridableType: Yup.string().when('isTypeOverridable', {
        is: true,
        then: Yup.string().required('You must fill field type overridable'),
    }),
    isUpdateable: Yup.boolean().required(),
    values: Yup.array(Yup.string()).required(),
    isValuesOverridable: Yup.boolean().required(),
    overridableValues: Yup.array(Yup.string()).required(),
});

interface Props {
    entity: ProjectIntegrationEntity;
    fieldMetadataToEdit: FieldMetadata;
    onChange: (value) => void;
}

const ProjectIntegrationEntitySchemaEditMetadata: React.FC<Props> = ({ entity, fieldMetadataToEdit, onChange }) => {
    const [{ error: updateFieldMetadataError, loading: updateFieldMetadataLoading }, updateFieldMetadataCommand] =
        useLazyTonkeanService('updateFieldMetadataCommand');

    return (
        <Wrapper>
            <HeaderWrapper>
                <HeaderText>Edit Metadata</HeaderText>
            </HeaderWrapper>

            <ContentWrapper>
                <Formik
                    initialValues={{
                        fieldName: fieldMetadataToEdit.name,
                        fieldLabel: fieldMetadataToEdit.label,
                        fieldLabelOverridable: fieldMetadataToEdit.overridableLabel || '',
                        isLabelOverridable: fieldMetadataToEdit.isLabelOverridable,
                        fieldType: fieldMetadataToEdit.type || 'Text',
                        overridableType: fieldMetadataToEdit.overridableType || '',
                        isTypeOverridable: fieldMetadataToEdit.isTypeOverridable,
                        values: fieldMetadataToEdit.values || [],
                        isUpdateable: fieldMetadataToEdit.updateable,
                        overridableValues: fieldMetadataToEdit.overridableValues || [],
                        isValuesOverridable: fieldMetadataToEdit.isValuesOverridable,
                        idLookup: fieldMetadataToEdit.idLookup || false,
                    }}
                    validationSchema={FieldMetadataSchema}
                    onSubmit={(values) => {
                        onChange({
                            ...fieldMetadataToEdit,
                            overridableLabel: values.fieldLabelOverridable,
                            isLabelOverridable: values.isLabelOverridable,
                            isTypeOverridable: values.isTypeOverridable,
                            overridableType: values.overridableType,
                            idLookup: values.idLookup,
                            overridableValues: fieldMetadataToEdit.overridableValues,
                            isValuesOverridable: fieldMetadataToEdit.isValuesOverridable,
                        });

                        return updateFieldMetadataCommand(entity.id, {
                            ...fieldMetadataToEdit,
                            overridableLabel: values.fieldLabelOverridable,
                            isLabelOverridable: values.isLabelOverridable,
                            isTypeOverridable: values.isTypeOverridable,
                            overridableType: values.overridableType,
                            idLookup: values.idLookup,
                            overridableValues: fieldMetadataToEdit.overridableValues,
                            isValuesOverridable: fieldMetadataToEdit.isValuesOverridable,
                        });
                    }}
                    enableReinitialize
                >
                    <FieldWrapper>
                        <MetadataField label="ID">
                            <MetadataInput size={InputSize.MEDIUM} name="fieldName" disabled />
                        </MetadataField>

                        <MetadataField label="Label">
                            <MetadataInput size={InputSize.MEDIUM} name="fieldLabel" disabled />
                        </MetadataField>

                        <Field>
                            <CheckBoxWrapper name="isLabelOverridable">Use override value</CheckBoxWrapper>
                        </Field>

                        <MetadataOverridableField>
                            <MetadataInput size={InputSize.MEDIUM} name="fieldLabelOverridable" />
                        </MetadataOverridableField>

                        <MetadataField label="Type">
                            <MetadataInput size={InputSize.MEDIUM} name="fieldType" disabled />
                        </MetadataField>

                        <Field>
                            <CheckBoxWrapper name="isTypeOverridable">Use override value</CheckBoxWrapper>
                        </Field>

                        <MetadataOverridableField>
                            <MetadataInput size={InputSize.MEDIUM} name="overridableType" />
                        </MetadataOverridableField>

                        <MetadataField label="Values">
                            <TagsInput name="values" isDisabled thin />
                        </MetadataField>

                        <Field>
                            <CheckBoxWrapper name="isValuesOverridable">Use override value</CheckBoxWrapper>
                        </Field>

                        <MetadataOverridableField>
                            <TagsInput name="overridableValues" thin />
                        </MetadataOverridableField>

                        <MetadataField>
                            <CheckBoxWrapper name="isUpdateable">Updatable</CheckBoxWrapper>
                        </MetadataField>

                        <MetadataField>
                            <CheckBoxWrapper name="idLookup">Is Unique Id Field?</CheckBoxWrapper>
                        </MetadataField>

                        <FormikAutosave />
                    </FieldWrapper>
                </Formik>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ProjectIntegrationEntitySchemaEditMetadata;
