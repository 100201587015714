import { FormQuestionType, PersonInquirySendTo } from '@tonkean/tonkean-entities';

const formQuestionTypeMustMatchFormType = (validationObject, definition, form) => {
    if (
        [FormQuestionType.COLLECT_INNER_ITEMS, FormQuestionType.UPLOAD_FILES].includes(form?.formQuestionType) &&
        definition.formType === 'DIALOG' &&
        definition.sendToType === PersonInquirySendTo.CUSTOM
    ) {
        validationObject.formTypeError = `It's not possible to choose Slack Dialog with a form which ${form?.formQuestionType
            .toLowerCase()
            .replaceAll('_', ' ')}`;
    }
};

export default formQuestionTypeMustMatchFormType;
