<div class="gather-update-logic-configuration">
    <!-- Owner selection title -->
    <div class="common-title-xxs margin-bottom">Who should Tonkean module Ask?</div>
    <!-- Owner selection -->
    <tnk-person-selection-configuration
        class="block margin-bottom-lg"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        support-quick-create="true"
        hide-fail-if-no-value="true"
        show-send-by="true"
        preview-evaluation-source="data.previewEvaluationSource"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        configured-logic="data.configuredLogic"
        saved-person-selection-configuration="data.existingPersonSelectionConfiguration"
        on-person-selection-configuration-changed="
            onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
    ></tnk-person-selection-configuration>

    <!-- Title -->
    <div class="margin-bottom-xs common-title-xxs">
        <span ng-if="data.personSelectionConfiguration.forceEmail">Email body:</span>
        <span ng-if="!data.personSelectionConfiguration.forceEmail">Text message:</span>
        <a
            class="inline-block common-italic margin-left"
            uib-tooltip="You can use markdown format to add styling to the message"
            target="_blank"
            ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
        >
            <i class="fa fa-info-circle"></i>
        </a>
    </div>

    <!-- Input -->
    <tnk-tonkean-expression
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        logic-id="{{ data.configuredLogic.node.id }}"
        placeholder="Type a custom message the Module will use... (optional)"
        preview-evaluation-source="data.previewEvaluationSource"
        saved-original-expression="data.botMessageText"
        saved-evaluated-expression="data.evaluatedBotMessageText"
        on-tonkean-expression-changed="
            onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
        "
    ></tnk-tonkean-expression>

    <!-- Separator -->
    <hr />

    <!-- Preview -->
    <div class="margin-top-lg">
        <!-- Title Message Preview-->
        <div
            ng-if="!data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            class="margin-bottom common-bold"
        >
            Message Preview:
        </div>

        <!-- Title Email Preview -->
        <div
            ng-if="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            class="margin-bottom common-bold"
        >
            Email Preview:
        </div>

        <!-- Preview -->
        <tnk-preview-bot-bubble-ctrl
            text="data.botMessageText"
            post-text="tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm)"
            post-text-evaluated-expression="'{{ TNK_TITLE }}'"
            preview-type="text"
            is-email="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            user="as.currentUser"
            bot-buttons="data.previewButtons"
            no-triangle="true"
            logic-id="data.configuredLogic.node.id"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            text-evaluated-expression="data.evaluatedBotMessageText"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-preview-bot-bubble-ctrl>
    </div>
</div>
