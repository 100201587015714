import { reactToAngular } from '../../utils';

import { TnkSelect } from '@tonkean/infrastructure';

angular.module('tonkean.app').component(
    'tnkSelect',
    reactToAngular(TnkSelect, [
        'value',
        'options',
        'onChange',
        'isMulti',
        'isError',
        'isSearchable',
        'placeholder',
        'noOptionsMessage',
        'className',
        'menuIsOpen',
        'defaultMenuIsOpen',
        'isDisabled',
        'name',
        'closeMenuOnSelect',
        'defaultValue',
        'theme',
        'styles',
        'isClearable',
        'isCreatable',
        'isAsync',
        'styles',
        'thin',
        'readOnly',
        'isLoading',
        // Getting all of the props for the typing is reliant on the generic props of the component, its not worth trying to fix this for angular so we just ignore the typescript error
        // @ts-ignore
        'loadOptions',
    ]),
);
