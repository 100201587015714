import template from './../setupGroup.template.html?angularjs';

function tnkSetupGroup() {
    return {
        restrict: 'E',
        scope: {
            showGroupSettings: '=',
            workersTemplateMode: '=',
            isModal: '=',
            isInline: '=',
            isListGallery: '=',
        },
        template,
        controller: 'SetupGroupCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkSetupGroup', tnkSetupGroup);
