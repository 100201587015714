import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import SolutionManagingModalHeader from './SolutionManagingModalHeader';
import { ReactComponent as LockIcon } from '../../../../images/icons/lock12.svg';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import {
    Field,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalSize,
    Radio,
    RadioGroup,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const SolutionAccessSchema = Yup.object({
    isHiddenFromNonSolutionCollaborator: Yup.boolean().required(),
});

interface Props {
    workflowFolder: WorkflowFolder;
}

const SolutionAccessModal: React.FC<Props> = ({ workflowFolder }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const [{ error }, updateWorkflowFolderAccess] = useLazyAsyncMethod(
        workflowFolderManager,
        'updateWorkflowFolderAccess',
    );

    const close = useCloseCallback();

    return (
        <Formik
            initialValues={{ isHiddenFromNonSolutionCollaborator: workflowFolder.isHiddenFromNonSolutionCollaborator }}
            validationSchema={SolutionAccessSchema}
            onSubmit={(solutionAccess) => {
                return updateWorkflowFolderAccess(workflowFolder.project.id, workflowFolder.id, solutionAccess).then(
                    () => close(),
                );
            }}
        >
            <ModalForm>
                <ModalBody>
                    <SolutionManagingModalHeader workflowFolder={workflowFolder} icon={<LockIcon />}>
                        Manage Solution Access
                    </SolutionManagingModalHeader>
                    <Field label="Only visible for:">
                        <RadioGroup size={InputSize.MEDIUM} name="isHiddenFromNonSolutionCollaborator">
                            <Radio value>Makers and publishers</Radio>
                            <Radio value={false}>All board members</Radio>
                        </RadioGroup>
                    </Field>
                </ModalBody>

                <ModalFooterActions error={error} />
            </ModalForm>
        </Formik>
    );
};

export default withModal(SolutionAccessModal, { size: ModalSize.MEDIUM, fixedWidth: true });
