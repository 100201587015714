import React from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection } from '@tonkean/infrastructure';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    margin-left: 30px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
`;

const ChevronWrapper = styled(DisableableButton)`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${Theme.colors.gray_300};
    margin-right: 8px;
    padding: 0;

    border: none;
    cursor: pointer;

    display: inline-grid;
    place-items: center;

    .tnk-icon {
        display: inline-flex;
    }

    ${styledFocus}
`;

const GrayText = styled.span`
    color: ${Theme.colors.gray_600};
    margin-right: 0.8ch;
`;

const SolutionName = styled.span`
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_800};
`;

interface Props {
    collapsed?: boolean;
    onToggle: () => void;
}

const RuntimePageSolutionName: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    children,
    collapsed = false,
    onToggle,
}) => {
    return (
        <Wrapper>
            <ChevronWrapper onClick={onToggle}>
                <Chevron direction={collapsed ? ChevronDirection.RIGHT : ChevronDirection.DOWN} />
            </ChevronWrapper>
            <GrayText>Solution: </GrayText>
            <SolutionName>{children}</SolutionName>
        </Wrapper>
    );
};

export default RuntimePageSolutionName;
