import { useMemo } from 'react';

import {
    type FieldDefinition,
    type FieldDefinitionKey,
    TonkeanType,
    tonkeanTypeToIdentifyingPrefixMap,
} from '@tonkean/tonkean-entities';

const useFieldDefinitions = (allFieldDefinitions: FieldDefinition[], fieldsToShow: FieldDefinitionKey[]) => {
    return useMemo(() => {
        return allFieldDefinitions.filter(
            (field) =>
                fieldsToShow.includes(field.id) &&
                field.id.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION]),
        );
    }, [allFieldDefinitions, fieldsToShow]);
};

export default useFieldDefinitions;
