<div class="padding-bottom">
    <tnk-match-option-selection
        default-selected-option="matchFieldCustomQuery"
        group-id="{{ data.groupId }}"
        logic-id="data.openedFromCustomTriggerId"
        mod-fixed-width="true"
        on-match-option-selection-changed="onMatchOptionSelectionChanged(selectionObject, shouldSave)"
        only-show-default-selected-option="true"
        order-by-tonkean-field="true"
        saved-match-option-selection="data.existingFieldDefinition.definition.matchConfiguration"
        show-advanced="true"
        tabs-field-selector-mod-fixed-width="true"
        tabs-field-selector-mod-narrow="false"
        target-workflow-version-id="{{ data.targetWorkflowVersionId }}"
        vertical-mod="false"
        view-only="data.viewOnly"
        workflow-version-id="{{ data.workflowVersionId }}"
    ></tnk-match-option-selection>
</div>
