import { bindThis } from '@tonkean/utils';

class NavigationService {
    /**
     * Indicates whether we are in a drilldown mode in fields or forms configuration.
     * We have to save this in a singleton field because we need to access it from React, and publish changes from Angular.
     * We could do that using state params, but we still don't have all the necessary state params to detect the right
     * places for the drilldown mode.
     */
    public isDrilldownMode: boolean = false;

    @bindThis
    /**
     * Toggles to true the drilldown mode
     */
    public enterDrilldownMode() {
        this.isDrilldownMode = true;
    }

    @bindThis
    /**
     *  Toggles to false the drilldown mode
     */
    public exitDrilldownMode() {
        this.isDrilldownMode = false;
    }
}
angular.module('tonkean.app').service('navigationService', NavigationService);
