import template from './tnkEditIntegrationButton.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkEditIntegrationButton', {
    bindings: {
        projectIntegration: '<',
        onProjectIntegrationUpdated: '&',
    },
    template,
    controller: 'EditIntegrationButtonCtrl',
});
