import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import FieldsWidget from './FieldsWidget';
import useSolutionSiteContext from '../../../hooks/useSolutionSiteContext';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type { SingleFieldChanged, WidgetFieldPackage } from '../../CommonWidgetConfiguration/FieldSelector';
import type FieldsItemWidgetConfiguration from '../FieldsItemWidgetConfiguration';

import { useFeatureFlag, useGetStateParams, useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useFetchManager, usePrevious } from '@tonkean/infrastructure';
import type { Group, WorkflowVersion } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const SolutionSiteFieldsWidget: React.FC<SolutionSitePageWidgetProps<FieldsItemWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    const { solutionSite, addGroupsToPoll } = useSolutionSiteContext();

    const [entityVersionTypeParam] = useGetStateParams<[WorkflowVersionType]>('env');
    const entityVersionType = entityVersionTypeParam || WorkflowVersionType.PUBLISHED;

    const { data: globalFieldDefinitions, loading: loadingGlobalFieldDefinitions } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const filteredFields = useMemo(
        () =>
            globalFieldDefinitions?.entities?.filter((fieldDefinition) =>
                Boolean(widget.configuration?.fields?.[fieldDefinition.id]),
            ),
        [globalFieldDefinitions?.entities, widget.configuration?.fields],
    );
    const workflowVersionIds = useMemo(
        () => filteredFields?.map((field) => field.workflowVersionId) ?? [],
        [filteredFields],
    );

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const [{ data: workflowVersions, loading: loadingWorkflowVersions }, getFromServerAndCacheWorkflowVersions] =
        useLazyAsyncMethod(workflowVersionManager, 'getFromServerAndCacheWorkflowVersions');

    useEffect(() => {
        // Calling this with an empty array will cause a flicker of loading because it will load with an empty workflowVersionIds, then load again with the filled one.
        if (workflowVersionIds.length) {
            getFromServerAndCacheWorkflowVersions(widget.projectId, workflowVersionIds);
        }
    }, [getFromServerAndCacheWorkflowVersions, widget.projectId, workflowVersionIds]);

    const useCentralizedGroupsPollingEnabled = useFeatureFlag(
        'tonkean_feature_centralize_groups_polling_in_solution_site',
    );

    /* eslint-disable react-hooks/rules-of-hooks */
    if (useCentralizedGroupsPollingEnabled) {
        useEffect(() => {
            if (workflowVersions?.length) {
                const groupIds = workflowVersions.map((workflowVersion) => workflowVersion.groupId);
                entityVersionType === WorkflowVersionType.PUBLISHED
                    ? addGroupsToPoll.published(groupIds)
                    : addGroupsToPoll.draft(groupIds);
            }
        }, [workflowVersions, entityVersionType, addGroupsToPoll]);
    } else {
        const groupInfoManager = useAngularService('groupInfoManager');

        const [[getGroups, cancelGroupFetcher]] = useFetchManager(groupInfoManager, 'getGroupsByIds', {
            autoReloadInterval: 10_000,
            isSingle: false,
            getItems: (items) => items,
            compareItems: (first: Group, second: Group) => first.id !== second.id,
        });

        useEffect(() => {
            if (workflowVersions?.length) {
                getGroups(
                    workflowVersions.map((workflowVersion) => workflowVersion.groupId),
                    true,
                    true,
                    entityVersionType,
                );
            }

            return cancelGroupFetcher;
        }, [cancelGroupFetcher, getGroups, workflowVersions, entityVersionType]);
    }

    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    // Using previous workflow version because `useTonkeanService` clears the data when refetching.
    const prevWorkflowVersions = usePrevious(workflowVersions, true);

    const configuredFields = useMemo<WidgetFieldPackage[]>(() => {
        if (!workflowVersions && !prevWorkflowVersions) {
            return [];
        }

        return (
            globalFieldDefinitions?.entities
                ?.filter((fieldDefinition) => Boolean(widget.configuration?.fields?.[fieldDefinition.id]))
                .map((fieldDefinition) => {
                    const workflowVersion = workflowVersionManager.workflowVersionIdToWorkflowVersionMap[
                        fieldDefinition.workflowVersionId
                    ] as WorkflowVersion;

                    return {
                        groupId: workflowVersion?.groupId,
                        fieldDefinition,
                        initiative: undefined,
                        workflowVersion,
                        environmentIsActive:
                            workflowVersion &&
                            (workflowVersion.workflowVersionType === WorkflowVersionType.PUBLISHED
                                ? groupsMap[workflowVersion.groupId]?.workerEnabled
                                : groupsMap[workflowVersion.groupId]?.buildEnvironmentEnabled),
                    };
                }) ?? []
        );
    }, [
        workflowVersions,
        prevWorkflowVersions,
        globalFieldDefinitions?.entities,
        widget.configuration?.fields,
        workflowVersionManager.workflowVersionIdToWorkflowVersionMap,
        groupsMap,
    ]);

    const [, updateFieldValue] = useLazyTonkeanService('updateFieldValue');

    const globalFields = useMemo(() => {
        return Object.assign(
            {},
            ...Object.values(groupsMap).map((groupMap: any) => {
                if (entityVersionType === WorkflowVersionType.PUBLISHED) {
                    return groupMap.globalFieldsMap;
                }
                return groupMap.globalFieldsDraftMap;
            }),
        );
    }, [groupsMap, entityVersionType]);

    const onChange = useMemo(
        () => async (changes: SingleFieldChanged[]) => {
            const promises = changes.map((change) => {
                return updateFieldValue(globalFields[change.package.fieldDefinition.id].id, change.newValue, undefined);
            });

            await Promise.all(promises);
        },
        [globalFields, updateFieldValue],
    );

    const loading = loadingGlobalFieldDefinitions || loadingWorkflowVersions;

    return (
        <FieldsWidget
            onChange={onChange}
            fields={configuredFields}
            showEmptyFieldWhenNoInitiative={false}
            loading={loading}
            widget={widget}
            permission={permission}
            displayNotUpToDateWarning
            showModuleOffIndication
        />
    );
};

export default React.memo(SolutionSiteFieldsWidget);
