import React from 'react';

import SeparatorWidgetView from './SeparatorWidgetView';
import type { ItemWidgetProps } from '../../../WidgetModule';
import type SeparatorWidgetConfiguration from '../SeparatorWidgetConfiguration';

const SeparatorWidget: React.FC<ItemWidgetProps<SeparatorWidgetConfiguration>> = ({ widget, permission }) => {
    return <SeparatorWidgetView widget={widget} permission={permission} />;
};

export default SeparatorWidget;
