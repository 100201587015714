import ItemDetailsModuleEditor from './ItemDetailsModuleEditor';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemDetailsModuleEditor',
        reactToAngular(ItemDetailsModuleEditor, [
            'workflowVersionId',
            'targetId',
            'onOpenTabClick',
            'onViewItemsClicked',
            'hasValidationError',
            'overrideDetailsTitlePlaceholder',
            'overrideDetailsDescriptionPlaceholder',
        ]),
    );
