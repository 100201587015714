<div class="validate-modal">
    <div class="common-title">Validate Your Account</div>
    <div class="common-subtitle padding-normal">
        To preform this action you must activate your account by validating your email address.
    </div>
    <a class="btn btn-secondary btn-lg" ng-click="as.validateAccount()">
        <i class="fa fa-exclamation-triangle"></i>
        Validate Now
        <i ng-show="as.loadingValidate" class="loading btn-loading-white"></i>
    </a>
</div>
