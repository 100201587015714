import dayjs from 'dayjs';

function getErrorMessagesFromWorkerRunLogic(logic): string[] {
    const workerRunLogicActionMessages: string[] | undefined = logic?.workerRunLogicActions
        ?.flatMap((workerRunLogicAction) => workerRunLogicAction.executionOutput?.logMessages || [])
        .map((log) => {
            const date = dayjs(log.timestamp).format('MMM D, YYYY hh:mm:ss A');
            return `${date} - ${log.logger} - ${log.message}`;
        });

    if (workerRunLogicActionMessages?.length) {
        return workerRunLogicActionMessages;
    }

    const friendlyErrorMessage: string | undefined = logic?.friendlyErrorMessage;
    if (friendlyErrorMessage) {
        return [friendlyErrorMessage];
    }

    return [];
}

export default getErrorMessagesFromWorkerRunLogic;
