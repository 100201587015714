import { CustomTriggerType } from '@tonkean/tonkean-entities';

const CustomTriggerMonitorTypes = [
    CustomTriggerType.MONITOR_TRACKS,
    CustomTriggerType.BOT_THREAD_REPLY,
    CustomTriggerType.INNER_AUTONOMOUS,
    CustomTriggerType.BOT_BUTTON_PRESSED,
    CustomTriggerType.SEND_FORM_ANSWERED,
];

export default CustomTriggerMonitorTypes;
