import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useIsInSharedReport } from '@tonkean/angular-hooks';
import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import type { Group, Initiative, SolutionBusinessReport } from '@tonkean/tonkean-entities';

interface Params {
    solutionReport?: SolutionBusinessReport;
    group: Group | undefined;
    initiative: Initiative | undefined;
}

function useItemInterfaceBreadcrumbs({ solutionReport, group, initiative }: Params) {
    const [boardFilter] = useGetStateParams<[string | undefined]>('filter');
    const $state = useAngularService('$state');
    const isInSharedReport = useIsInSharedReport();

    return useMemo<BreadcrumbsSettings[]>(() => {
        if (initiative?.id === undefined || group?.id === undefined) {
            return [];
        }

        const showYourItems = boardFilter === 'my';

        const yourItemsBreadcrumbs: BreadcrumbsSettings[] = [
            {
                id: 'yourItems',
                displayName: 'Your Items',
                clickable: {
                    state: '.',
                    params: { tid: undefined },
                },
            },
        ];

        let solutionReportBreadcrumbs: BreadcrumbsSettings[] = [];
        let groupBreadcrumb: BreadcrumbsSettings[];

        if ($state.current.name === 'solution-site' || $state.current.name === 'product.solutionSiteBuilderPreview') {
            groupBreadcrumb = [
                {
                    id: $state.current.name,
                    displayName: group.name,
                    clickable: {
                        state: $state.current.name,
                    },
                },
            ];
        } else if (solutionReport) {
            solutionReportBreadcrumbs = [
                {
                    id: 'solutionReport',
                    displayName: solutionReport.displayName,
                    clickable: {
                        state: isInSharedReport
                            ? 'product.processContributorSolutionBusinessReport'
                            : 'product.solution-business-report',
                        params: { solutionBusinessReportId: solutionReport.id },
                    },
                },
            ];

            groupBreadcrumb = [
                {
                    id: 'group',
                    displayName: group.name,
                    clickable: {
                        state: isInSharedReport
                            ? 'product.processContributorSolutionBusinessReport'
                            : 'product.solution-business-report',
                        params: {
                            solutionBusinessReportId: solutionReport.id,
                        },
                    },
                },
            ];
        } else {
            groupBreadcrumb = [
                {
                    id: 'group',
                    displayName: group.name,
                    clickable: {
                        state: 'product.board',
                        params: {
                            filter: 'all',
                            g: group.id,
                        },
                    },
                },
            ];
        }
        return [
            solutionReportBreadcrumbs,
            showYourItems ? yourItemsBreadcrumbs : [],
            groupBreadcrumb,
            [
                {
                    id: 'item',
                    displayName: initiative.title,
                },
            ],
        ].flat();
    }, [
        $state,
        boardFilter,
        group?.id,
        group?.name,
        initiative?.id,
        initiative?.title,
        isInSharedReport,
        solutionReport,
    ]);
}

export default useItemInterfaceBreadcrumbs;
