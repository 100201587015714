import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP } from './formulaSpecialFieldIdToDefinitionMap';

import Utils from '@tonkean/utils';

/**
 * Gets the variables participating in a formula, by the given target type and field definition type.
 *
 * @param globalFieldDefinitions {Object[]} - List of global field definitions
 * @param columnFieldDefinitions {Object[]} - List of column field definitions
 * @param featureName {string} - feature name
 */
export function getParticipatingVariablesForFormula(globalFieldDefinitions, columnFieldDefinitions, featureName) {
    const variablesSet = new Set<Record<any, any>>();

    // Global field definitions variables.
    for (let i = 0; i < globalFieldDefinitions?.length; i++) {
        const globalFieldDefinition = globalFieldDefinitions[i];

        // It is important we mark isColumnFieldDefinition = false, so we can distinguish this field definition is a key metric and not a column.
        globalFieldDefinition.isColumnFieldDefinition = false;
        globalFieldDefinition.isSpecialField = false;

        variablesSet.add(globalFieldDefinition);
    }

    // Column field definitions variables.
    for (let i = 0; i < columnFieldDefinitions?.length; i++) {
        const fieldDefinition = columnFieldDefinitions[i];

        // It is important we mark isColumnFieldDefinition = true, so we can distinguish this field definition is a column and not a key metric.
        fieldDefinition.isColumnFieldDefinition = true;
        fieldDefinition.isSpecialField = false;

        variablesSet.add(fieldDefinition);
    }

    // Special fields variables.
    const formulaSpecialFieldIdToDefinitionMap = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP;
    for (const key in formulaSpecialFieldIdToDefinitionMap) {
        if (
            formulaSpecialFieldIdToDefinitionMap.hasOwnProperty(key) &&
            formulaSpecialFieldIdToDefinitionMap[key].considerInTypes[featureName]
        ) {
            const specialField = formulaSpecialFieldIdToDefinitionMap[key];

            variablesSet.add({
                id: key,
                name: specialField.name,
                fieldType: specialField.fieldType,
                displayAs: specialField.displayAs,
                displayFormat: specialField.displayFormat,
                evaluatedDisplayType: specialField.evaluatedDisplayType,
                isColumnFieldDefinition: true,
                isSpecialField: true,
            });
        }
    }

    const variables = [...variablesSet];

    return {
        variables,
        variableIdToVariableMap: Utils.createMapFromArray(variables, 'id'),
        variableNameToVariableMap: Utils.createMapFromArray(variables, 'name'),
        variableLabelToVariableMap: Utils.createMapFromArray(variables, 'label'),
    };
}
