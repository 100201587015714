<tnk-react-root-fixed-portal></tnk-react-root-fixed-portal>
<div class="common-page-load" ng-show="loading">
    <i class="loading-full-circle mod-center-screen"></i>
</div>
<div
    class="view-only-page project-board"
    ng-class="{ 'mod-no-nav': !$root.showHeader }"
    ng-if="!loading && data.group"
    id="project-board"
>
    <!-- View only message bubble -->
    <div class="visible-xs view-only-msg-bubble" ng-if="$root.showHeader">
        This is a
        <strong class="common-underline">limited and read only</strong>
        mode of this list.
        <br />
        Sign in to get full access or create your own.
    </div>
    <div class="initiatives-header mod-full" id="initiatives-header" ng-class="{ 'mod-top-zero': !$root.showHeader }">
        <div class="initiatives-header-inner margin-left flex-vmiddle mod-justify-end-xs flex-xs-wrap margin-top-none">
            <!-- title -->
            <div
                class="initiatives-list-title capital-first-letter show-edit-when-hover flex-no-shrink margin-right"
                ng-class="{ 'common-ellipsis': !$root.showHeader }"
            >
                {{ data.group.name }}
            </div>
            <!-- Items in the header that isnt the title  -->
            <div class="flex-vmiddle flex-grow">
                <!-- items in the header except bot live -->
                <div class="flex-grow flex-vmiddle flex-row-reverse-xs mod-justify-end-xs">
                    <!-- Seperator-->
                    <div
                        class="flex-vmiddle padding-none hidden-xs"
                        ng-if="
                            !data.func &&
                            groupId &&
                            pm.groupsMap[groupId] &&
                            pm.groupsMap[groupId].members &&
                            pm.groupsMap[groupId].members.length
                        "
                    >
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!-- Group privacy and visibility -->
                    <div class="flex-vmiddle common-size-xxxxs pointer margin-right margin-left flex-no-shrink">
                        <span class="svg-icon-sm common-color-primary svg-icon-vmiddle margin-right-xs">
                            <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                        </span>
                        <span>
                            View only
                            <span class="visible-lg-inline">&nbsp;access</span>
                        </span>
                    </div>

                    <!-- Seperator-->
                    <div class="flex-vmiddle padding-none hidden-xs">
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!-- Week view -->
                    <div
                        class="btn btn-sm margin-right hidden-xs btn-no-border flex-vmiddle common-size-xxxxs"
                        ng-click="data.showWeekView = !data.showWeekView"
                        ng-class="data.showWeekView ? 'common-color-primary' : 'common-color-grey'"
                    >
                        <span class="svg-icon svg-icon-vmiddle margin-right-xs">
                            <tnk-icon src="/images/icons/week.svg"></tnk-icon>
                        </span>
                        <span class="common-color-dark-grey">Week view</span>
                    </div>

                    <!-- Space -->
                    <div class="flex-grow hidden-xs"></div>

                    <!-- Seperator-->
                    <div class="flex-vmiddle padding-none hidden-xs margin-left-xs">
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!-- Columns -->
                    <div
                        class="btn btn-sm hidden-xs btn-no-border flex-vmiddle common-size-xxxs common-disabled"
                        uib-tooltip="Add Column"
                        tooltip-placement="bottom"
                    >
                        <span class="common-color-dark-grey">
                            Columns ({{ cfm.selectedColumnFieldsMap[data.workflowVersionId].length || 0 }})
                        </span>
                    </div>

                    <!-- Seperator-->
                    <div class="flex-vmiddle padding-none hidden-xs">
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>

                    <!--Triggers-->
                    <button
                        class="btn btn-sm hidden-xs hidden-sm btn-no-border flex-vmiddle common-size-xxxs"
                        ng-if="data.group.id"
                        uib-btn-checkbox
                        ng-model="data.isManageTriggersOpen"
                    >
                        <span class="common-color-dark-grey">Automation Triggers</span>
                    </button>

                    <!-- Seperator-->
                    <div class="flex-vmiddle padding-none hidden-xs margin-right-xs">
                        <div class="initiatives-header-separator">&nbsp;</div>
                    </div>
                </div>

                <!--Bot active-->
                <div ng-mouseenter="data.showBotState = true" ng-mouseleave="data.showBotState = false">
                    <div class="flex-vmiddle">
                        <div class="svg-icon svg-icon-lg mod-static relative">
                            <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                        </div>
                        <div class="tnk-toggle-state-button mod-md common-bold toggle-active">
                            <div class="white-circle"></div>
                            <div class="button-text">MODULE ON</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--List metrics-->
    <div
        class="initiatives-list-metrics mod-full"
        id="initiatives-list-metrics"
        ng-class="{ 'mod-no-nav': !$root.showHeader }"
        ng-if="
            cfm.selectedGlobalFieldsMap[data.workflowVersionId] &&
            cfm.selectedGlobalFieldsMap[data.workflowVersionId].length &&
            data.group.globalFields &&
            data.group.globalFields.length
        "
    >
        <tnk-metrics-bar
            type="listView"
            field-definition-target-type="GLOBAL"
            group-id="data.group.id"
            workflow-version-id="{{ data.workflowVersionId }}"
            fields-map="data.group.globalFieldsMap"
            fields-map-draft="data.group.globalFieldsDraftMap"
            view-only="true"
            target="data.group"
        ></tnk-metrics-bar>
    </div>

    <!-- List container -->
    <div
        class="initiatives-content"
        id="initiatives-content"
        ng-class="{
            'mod-key-metrics':
                cfm.selectedGlobalFieldsMap[data.workflowVersionId] &&
                cfm.selectedGlobalFieldsMap[data.workflowVersionId].length &&
                data.group.globalFields &&
                data.group.globalFields.length,
            'mod-no-nav': !$root.showHeader
        }"
    >
        <div class="initiatives-content-inner" id="initiatives-content-inner">
            <div ng-show="data.loadingInitiatives">
                <div class="common-page-load padding-top-xlg">
                    <i class="loading-full-circle"></i>
                </div>
            </div>

            <div ng-if="!data.loadingInitiatives">
                <!--week view-->
                <div class="margin-left" ng-show="data.showWeekView">
                    <div
                        class="relative hidden-xs padding-bottom margin-top initiative-week-view relative"
                        ng-show="data.showWeekView"
                    >
                        <div class="initiative-week-btn mod-left" ng-click="loadOtherWeek(-7)">
                            <span class="common-circle"><i class="fa fa-angle-left"></i></span>
                        </div>
                        <tnk-track-week-view
                            items="data.allInitiatives"
                            start-date="data.startDate"
                            ng-if="!data.loadingWeek"
                            include-links="true"
                            full-mode="true"
                        ></tnk-track-week-view>
                        <div class="initiative-week-btn mod-right" ng-click="loadOtherWeek(7)">
                            <span class="common-circle"><i class="fa fa-angle-right"></i></span>
                        </div>
                    </div>
                </div>

                <!-- List Filters bar-->
                <div class="initiatives-list-filter-bar mod-full" id="initiatives-list-filter-bar">
                    <div class="flex-vmiddle common-color-dark-grey common-size-xxxxs padding-top-xs padding-bottom-xs">
                        <!-- Search list-->
                        <div class="flex-vmiddle margin-left initiatives-inner-search-list-box">
                            <span class="svg-icon-sm">
                                <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                            </span>
                            <input
                                class="form-control common-no-border common-no-outline common-size-xxxxs"
                                placeholder="Search list items..."
                                ng-model="data.innerListSearchText"
                            />
                        </div>

                        <div class="common-separator-v hidden"></div>

                        <!-- All/Only done-->
                        <div uib-dropdown class="margin-right-xlg hidden inner-filter-single-option">
                            <div
                                class="flex-vmiddle common-color-grey pointer"
                                ng-click="closeInitiativesFilterPopover()"
                                uib-dropdown-toggle
                            >
                                <span class="margin-right-xs">{{ data.toggleDone ? 'Active & Done' : 'Active' }}</span>
                                <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                            </div>
                            <ul class="padding-none inner-filter-drop-down" uib-dropdown-menu>
                                <li
                                    class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                    ng-click="data.toggleDone = false"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by Status"
                                    analytics-label="Active"
                                >
                                    <span
                                        class="flex-grow common-color-grey"
                                        ng-class="{ 'common-color-black': !data.toggleDone }"
                                    >
                                        Active
                                    </span>
                                    <span
                                        class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                        ng-if="!data.toggleDone"
                                    >
                                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                    </span>
                                </li>
                                <li
                                    class="inner-filter-menu-item padding-normal-sm flex-vmiddle pointer"
                                    ng-click="data.toggleDone = true"
                                    analytics-on="click"
                                    analytics-category="Filter"
                                    analytics-event="Filter by State"
                                    analytics-label="Done"
                                >
                                    <span
                                        class="flex-grow common-color-grey"
                                        ng-class="{ 'common-color-black': data.toggleDone }"
                                    >
                                        Active & Done
                                    </span>
                                    <span
                                        class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-black"
                                        ng-if="data.toggleDone"
                                    >
                                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div
                            class="common-separator-v"
                            ng-if="data.innerListSearchText && data.innerListSearchText.length"
                        ></div>

                        <!-- Clear filter-->
                        <span
                            ng-if="data.innerListSearchText && data.innerListSearchText.length"
                            ng-click="data.innerListSearchText = ''"
                            class="
                                common-tag-full
                                mod-big-radius
                                pointer
                                flex-vmiddle
                                common-size-xxxxs
                                padding-right
                                flex-no-shrink
                            "
                        >
                            <span class="svg-icon common-color-white">
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </span>
                            <span>Clear</span>
                        </span>

                        <div class="flex-grow"></div>
                    </div>
                </div>

                <!--Track view-->
                <div ng-if="data.initiatives && data.initiatives.length" id="tracks-list">
                    <div class="tracks-editor">
                        <div>
                            <div class="tracks-group common-width-full">
                                <div>
                                    <div class="relative">
                                        <tnk-tracks-editor
                                            tracks="data.initiatives"
                                            filter="{status: (data.toggleDone ? '' : '!DONE'), title: data.innerListSearchText}"
                                            editor-id="data.group.id"
                                            lazy-load-tracks="true"
                                            only-group="data.group.id"
                                            on-submit="loadInitiatives(softUpdate)"
                                            loading="data.loadingInitiatives"
                                            allow-dnd-on-root="false"
                                            show-archived="false"
                                            show-add-on-empty="false"
                                            view-only-mode="true"
                                            anonymous="true"
                                            custom-fields="cfm.selectedColumnFieldsMap[data.workflowVersionId]"
                                            group-id="data.group.id"
                                            workflow-version-id="wvm.getPublishedVersionFromCache(data.group.id).id"
                                            hide-title="false"
                                        ></tnk-tracks-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
