function ShareListCtrl($scope, tonkeanService, utils, projectManager, authenticationService, groupInfoManager, groupPermissions, groupId) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.gm = groupPermissions;

    $scope.groupId = groupId;
    $scope.data = {
        publicToken: null,
        publicTokenUrl: null,
    };

    $scope.init = function () {
        // if we allow link then it's a group, so checking if there is already a link
        if (projectManager.groupsMap[groupId] && projectManager.groupsMap[groupId].sharedAccessToken) {
            $scope.data.publicToken = projectManager.groupsMap[groupId].sharedAccessToken;
            $scope.data.publicTokenUrl = projectManager.groupsMap[groupId].sharedAccessUrl;
        }
    };

    /**
     * Shares the entity with selected people.
     */
    $scope.createShareableLink = function (internal) {
        if (!$scope.data.publicToken && (!$scope.creatingLink || internal)) {
            if (projectManager.groupsMap[groupId] && !projectManager.groupsMap[groupId].canAccessWithToken) {
                if (!groupPermissions.currentUserIsOwnerMap[groupId]) {
                    return;
                }

                $scope.creatingLink = true;
                // first allow access with token
                tonkeanService.updateGroupAllowShare(projectManager.groupsMap[groupId], true).then(function () {
                    projectManager.groupsMap[groupId].canAccessWithToken = true;
                    $scope.createShareableLink(true);
                });
                return;
            }

            $scope.creatingLink = true;
            tonkeanService.createGuestAccessToken(groupId).then(function (data) {
                // Update group in cache with the new token values
                const cachedGroup = projectManager.groupsMap[groupId];
                cachedGroup.sharedAccessToken = data.token;
                cachedGroup.sharedAccessUrl = data.url;
                projectManager.updateGroup(cachedGroup);

                $scope.data.publicToken = data.token;
                $scope.data.publicTokenUrl = data.url;
                $scope.creatingLink = false;
            });
        }
    };
    /**
     * revoke token
     */
    $scope.revokeShareableLink = function () {
        tonkeanService.revokeGuestAccessToken(groupId).then(function () {
            $scope.data.publicToken = null;
            $scope.data.publicTokenUrl = null;
            groupInfoManager.getGroups(true);
        });
    };

    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    $scope.init();

    // Focus the message input element when the document finishes loading.
    angular.element(document).ready(function () {
        // Auto focus the message input.
        const messageInput = document.getElementById(`message-${$scope.entityId}`);
        if (messageInput) {
            messageInput.focus();
        }
    });
}

export default angular.module('tonkean.app').controller('ShareListCtrl', ShareListCtrl);
