import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle, TextEllipsis } from '@tonkean/infrastructure';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

enum KeywordSentiment {
    POSITIVE = 'Positive',
    NEGATIVE = 'Negative',
}

const ErrorText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
    margin-top: 10px;
`;

const EmptyEnrichmentFields = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    margin-top: 5px;
`;

const StyledDetailTitle = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 2px;
`;

const StyledDetailValue = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    margin-top: 6px;
`;

const StyledModelKeyword = styled.span`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

const EnrichmentFieldName = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    font-weight: 500;
`;

const EnrichmentFieldValue = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_700};
`;

const TrainingSetEditor = styled.div`
    float: right;
    margin-top: 12px;
`;

const TrainingExampleWrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    height: 40px;
    width: 310px;
`;

const TrainingExampleKeyword = styled.span`
    color: ${Theme.colors.gray_700};
    font-weight: 500;
    width: 200px;
    font-size: ${FontSize.SMALL_12};
    float: left;
    margin-left: 12px;
    margin-top: 10px;
`;

const TrainingExampleKeywordSentiment = styled.span<{ isPositive: boolean }>`
    color: ${({ isPositive }) => (isPositive ? Theme.colors.success : Theme.colors.error)};
    float: right;
    margin-right: 12px;
    margin-top: 10px;
`;

const StyledEnrichedField = styled.div`
    padding: 5px 12px;

    border: 1px solid ${Theme.colors.gray_300};

    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 3px;
        border-left: 2px solid ${Theme.colors.gray_500};
        border-radius: 4px;
    }
`;

interface Props {
    selectedTrainingSetId: string;
    selectedTrainingSetModelId: string;
    groupId: string;
}

const TrainingSetNlpModelDetails: React.FC<Props> = ({
    selectedTrainingSetId,
    selectedTrainingSetModelId,
    groupId,
}) => {
    const $state = useAngularService('$state');
    const projectManager = useAngularService('projectManager');

    const {
        data: trainingSetModelResponse,
        error,
        loading,
    } = useTonkeanService('getTrainingSetModel', selectedTrainingSetModelId);

    const trainingSetModelFields = useMemo(() => {
        if (!trainingSetModelResponse?.modelFields) {
            return {};
        }

        return utils.createMapFromArray(trainingSetModelResponse.modelFields || [], 'id');
    }, [trainingSetModelResponse?.modelFields]);

    const activeFieldsToEnrich = useMemo(() => {
        return trainingSetModelResponse?.model?.configuration?.enrichmentFields.filter(
            (enrichmentField) => trainingSetModelFields?.[enrichmentField.trainingSetFieldId]?.isActive || false,
        );
    }, [trainingSetModelFields, trainingSetModelResponse?.model?.configuration?.enrichmentFields]);

    const trainingExamplesCounter = trainingSetModelResponse?.model?.configuration?.trainingExamples?.length || 0;

    return (
        <>
            {loading && <LoadingCircle />}
            {error && <ErrorText>Error while trying to load the training set nlp branch details</ErrorText>}
            {trainingSetModelResponse?.model && (
                <>
                    <TrainingSetEditor>
                        <ClickableLink
                            state="product.trainingSetPage"
                            params={{
                                page: ProjectIntegrationPageMenuItemType.EXTRACTED_FIELDS,
                                enterpriseComponentId: selectedTrainingSetId,
                                fromState: $state.current.name,
                                fromStateParams: $state.params,
                                fromName: projectManager.groupsMap[groupId]?.name || 'Module Editor',
                            }}
                            underline={LinkUnderline.ALWAYS}
                        >
                            Open Training Set Editor
                        </ClickableLink>
                    </TrainingSetEditor>

                    <StyledDetailTitle>Description</StyledDetailTitle>
                    <StyledDetailValue>
                        {trainingSetModelResponse.model.description || 'No Description'}
                    </StyledDetailValue>

                    <StyledDetailTitle> Matching method</StyledDetailTitle>
                    <StyledDetailValue>
                        {trainingSetModelResponse.model?.configuration?.nlpMatchingType?.toLowerCase() || 'Simple'}
                    </StyledDetailValue>

                    <StyledDetailTitle>Keywords</StyledDetailTitle>
                    <div>
                        {trainingSetModelResponse.model?.configuration?.trainingExamples?.map((trainingExample, i) => (
                            <StyledModelKeyword key={trainingExample.keyword}>
                                {trainingExample.keyword} {i < trainingExamplesCounter - 1 ? ',' : ''}
                            </StyledModelKeyword>
                        ))}
                    </div>

                    <StyledDetailTitle>Training examples</StyledDetailTitle>
                    {trainingSetModelResponse.model?.configuration?.trainingExamples?.map((trainingExample) => (
                        <TrainingExampleWrapper key={trainingExample.keyword}>
                            <TrainingExampleKeyword>
                                <TextEllipsis numberOfLines={1} tooltip>
                                    {trainingExample.keyword}
                                </TextEllipsis>
                            </TrainingExampleKeyword>
                            <TrainingExampleKeywordSentiment
                                isPositive={trainingExample.keywordSentiment === 'POSITIVE'}
                            >
                                {KeywordSentiment[trainingExample.keywordSentiment]}
                            </TrainingExampleKeywordSentiment>
                        </TrainingExampleWrapper>
                    ))}

                    <StyledDetailTitle>Fields To Enrich</StyledDetailTitle>
                    <div>
                        {activeFieldsToEnrich?.length === 0 && (
                            <EmptyEnrichmentFields> No active enrichment fields exists</EmptyEnrichmentFields>
                        )}
                        {activeFieldsToEnrich?.map((enrichmentField) => (
                            <StyledEnrichedField key={enrichmentField.trainingSetFieldId}>
                                <EnrichmentFieldName>
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {trainingSetModelFields?.[enrichmentField.trainingSetFieldId]?.fieldName}{' '}
                                    </TextEllipsis>
                                </EnrichmentFieldName>
                                <EnrichmentFieldValue>
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {enrichmentField.value || '{Value was not assigned}'}
                                    </TextEllipsis>
                                </EnrichmentFieldValue>
                            </StyledEnrichedField>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default TrainingSetNlpModelDetails;
