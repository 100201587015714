import type { AngularServices } from 'angulareact';

import getFieldDefinitionHistoryRelationId from './getFieldDefinitionHistoryRelationId';
import getHistoryFieldIntegrationId from './getHistoryFieldIntegrationId';
import getHistoryFieldIntegrationType from './getHistoryFieldIntegrationType';
import type HistoryField from '../entities/HistoryField';

import type { FieldDefinition } from '@tonkean/tonkean-entities';

/**
 * Convert field definitions to a TableFields.
 *
 * @param fieldDefinitions - field definitions list.
 * @param initialValueFunc - a function that gets the fieldDefinition as a param and returns the initial value of it.
 * @param finalValueFunc - a function that gets the fieldDefinition as a param and returns the final value of it.
 * @param couldHaveChanged - does the action preformed could have changed the values? (for example, if it's a custom trigger, it can't).
 */
function convertFieldDefinitionsToHistoryTableFields(
    fieldDefinitions: FieldDefinition<any>[],
    initialValueFunc: (fieldDefinition: FieldDefinition) => string | undefined,
    finalValueFunc: (fieldDefinition: FieldDefinition) => string | undefined,
    couldHaveChanged: boolean,
    workflowVersionId: string | undefined,
    customTriggerManager: AngularServices['customFieldsManager'],
): HistoryField[] {
    return fieldDefinitions.map((field) => {
        const isGlobalField = field.targetType === 'GLOBAL';

        const initialValue = initialValueFunc(field);
        const finalValue = finalValueFunc(field);
        return {
            isGlobalField,
            relationFieldId: getFieldDefinitionHistoryRelationId(field),
            id: field.id,
            displayName: field.name,
            type: isGlobalField ? 'GLOBAL' : field.type,
            integrationType: getHistoryFieldIntegrationType(field, workflowVersionId, customTriggerManager),
            isSpecialField: field.isSpecialField,
            isMatchedEntity:
                field.idRelationField ||
                field.type === 'ROOT' ||
                field?.definition?.matchConfiguration?.performOnWorkerItem ||
                field?.linkedCustomTrigger ||
                field?.definition?.matchConfiguration?.creatingCustomTriggerId,
            isFormulaField: field.type === 'TNK_COLUMN_FORMULA' || field.type === 'TNK_COLUMN_AGGREGATE',
            isSystemUtilized: field.systemUtilized,
            projectIntegrationId: getHistoryFieldIntegrationId(field, workflowVersionId, customTriggerManager),
            fieldDefinition: field,
            value: {
                initialValue,
                finalValue,
                hasChanged: couldHaveChanged ? initialValue !== finalValue : false,
                isEmpty: couldHaveChanged ? !initialValue || !finalValue : !initialValue,
            },
        };
    });
}

export default convertFieldDefinitionsToHistoryTableFields;
