import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function ApprovalCycleLogicConfigurationCtrl(
    $scope,
    $q,
    authenticationService,
    communicationIntegrationsService,
    projectManager,
) {
    const ctrl = this;

    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        text: 'Hey {Reviewer}, have you had a chance to look at {Item}?',
        evaluatedText: 'Hey {Reviewer}, have you had a chance to look at {{TNK_TITLE}}?',

        reviewers: [{}],
        customText: null,
        isUrgent: false,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    /**
     * Adds a new reviewer to the list.
     */
    $scope.addNewReviewer = function () {
        $scope.data.reviewers.push({});
    };

    /**
     * Sets a selection of a reviewer.
     */
    $scope.ownerSelected = function (selectedPerson, index) {
        $scope.data.reviewers[index] = selectedPerson;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.customText = originalExpression;
        $scope.data.evaluatedCustomText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Removes a selection of a reviewer.
     */
    $scope.ownerRemoved = function (index) {
        $scope.data.reviewers[index] = {};
        $scope.onActionsChanged(true);
    };

    /**
     * Removes a reviewer.
     */
    $scope.removeReviewer = function (index) {
        $scope.data.reviewers.splice(index, 1);
        $scope.onActionsChanged(true);
    };

    $scope.onIsUrgentChanged = function (value) {
        $scope.data.isUrgent = value;
        $scope.onActionsChanged(true);
        return $q.resolve();
    };

    /**
     * Occurs once the definition of the action is changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                actions: [
                    {
                        type: 'APPROVAL_CYCLE',
                        definition: {
                            reviewerPersonIds: $scope.data.reviewers
                                .filter((reviewer) => reviewer && reviewer.id)
                                .map((reviewer) => reviewer.id),
                            customText: $scope.data.customText,
                            evaluatedCustomText: $scope.data.evaluatedCustomText,
                            isUrgent: $scope.data.isUrgent,
                        },
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'APPROVAL_CYCLE',
        );

        if (customTriggerAction) {
            $scope.data.reviewers = [];

            const definition = customTriggerAction.customTriggerActionDefinition;
            for (let i = 0; i < definition.reviewerPersonIds.length; i++) {
                const reviewerPersonId = definition.reviewerPersonIds[i];

                $scope.data.reviewers.push({
                    id: reviewerPersonId,
                });
            }

            if (!$scope.data.reviewers.length) {
                $scope.data.reviewers.push({});
            }

            $scope.data.customText = definition.customText;

            $scope.data.evaluatedCustomText = definition.evaluatedCustomText;

            $scope.data.isUrgent = definition.isUrgent;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('ApprovalCycleLogicConfigurationCtrl', lateConstructController(ApprovalCycleLogicConfigurationCtrl));
