import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import type SQLDatabaseEntityIdType from '../entities/SQLDatabaseEntityIdType';
import type SQLDatabaseIntegrationConfiguration from '../entities/SQLDatabaseIntegrationConfiguration';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';
import getEmptySQLDatabaseQueryConfiguration from '../utils/getEmptySQLDatabaseQueryConfiguration';
import isSQLDatabaseQueryConfigurationValid from '../utils/isSQLDatabaseQueryConfigurationValid';
import validateSQLDatabasePaginationQuery from '../utils/validateSQLDatabasePaginationQuery';

import { useCreateDialog } from '@tonkean/infrastructure';

function useEditSQLDatabaseQueryConfigurations(
    projectIntegration: any,
    onChange: (projectData: Record<string, any>, overrideDisplayName?: string) => void,
    deleteConfirmationMessage: React.ReactElement,
) {
    const counterRef = useRef(1);
    const { confirm } = useCreateDialog();

    const [queryConfigurations, setQueryConfigurations] = useState<SQLDatabaseQueryConfiguration[]>([]);
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        const projectData: SQLDatabaseIntegrationConfiguration = projectIntegration?.projectData?.projectDatas[0];

        if (projectData?.queries?.length) {
            projectData.queries.forEach((queryConfiguration) => {
                // Setting a query id if needed.
                if (!queryConfiguration.queryId) {
                    counterRef.current = counterRef.current + 1;
                    queryConfiguration.queryId = counterRef.current;
                } else {
                    // We want the next added queries to take a query id that does not already exist.
                    if (counterRef.current < queryConfiguration.queryId) {
                        counterRef.current = queryConfiguration.queryId;
                    }
                }

                // We start with not expanded.
                queryConfiguration.expanded = false;
                queryConfiguration.existingQueryConfiguration = true;
                queryConfiguration.queryTested = true;
            });

            setQueryConfigurations(projectData.queries);
            setIsValid(
                projectData.queries.every((currentConfiguration) =>
                    isSQLDatabaseQueryConfigurationValid(currentConfiguration),
                ),
            );
        } else {
            // We start with an empty expanded configuration if we haven't got entities just yet.
            counterRef.current = counterRef.current + 1;
            const emptySQLConfiguration = getEmptySQLDatabaseQueryConfiguration(counterRef.current);
            emptySQLConfiguration.expanded = true;
            setQueryConfigurations([emptySQLConfiguration]);
            setIsValid(false);
        }
    }, [projectIntegration]);

    const changeSQLQueryConfiguration = (
        propertyName: keyof SQLDatabaseQueryConfiguration,
        newValue: any,
        index: number,
    ) => {
        if (!queryConfigurations[index]) {
            throw new Error(`no index number ${index} in queryConfigurations`);
        }

        const clonedConfigurations = [...queryConfigurations];

        clonedConfigurations[index] = {
            ...clonedConfigurations[index]!,
            [propertyName]: newValue,
        };

        setQueryConfigurations(clonedConfigurations);
        setIsValid(
            clonedConfigurations.every((currentConfiguration) =>
                isSQLDatabaseQueryConfigurationValid(currentConfiguration),
            ),
        );
    };

    const toggleEditMode = (inEditMode: boolean, index: number) => {
        changeSQLQueryConfiguration('queryNameInEditMode', inEditMode, index);
    };

    const changeQueryName = (newValue: string, index: number) => {
        const clonedQueries = queryConfigurations.map((currentConfiguration, currentIndex) => {
            // Gathering all the names of other query entities.
            const otherQueriesNames = new Set(
                queryConfigurations
                    .map((innerConfig, innerIndex) => {
                        if (innerIndex === currentIndex) {
                            // We don't want current element.
                            return undefined;
                        } else if (innerIndex === index) {
                            // If it's changed element, we want the new value.
                            return newValue;
                        } else {
                            // Otherwise, we just take the query name.
                            return innerConfig.queryName;
                        }
                    })
                    .filter((innerConfig) => innerConfig),
            );

            return {
                ...currentConfiguration,
                queryName: currentIndex === index ? newValue : currentConfiguration.queryName,
                nameValidationError: otherQueriesNames.has(
                    currentIndex === index ? newValue : currentConfiguration.queryName,
                )
                    ? 'Query entity name already exists!'
                    : undefined,
            };
        });

        setQueryConfigurations(clonedQueries);
        setIsValid(
            clonedQueries.every((currentConfiguration) => isSQLDatabaseQueryConfigurationValid(currentConfiguration)),
        );
    };

    const changeExpanded = (newValue: boolean, index: number) => {
        changeSQLQueryConfiguration('expanded', newValue, index);
    };

    const changeQuery = (newValue: any, index: number) => {
        const clonedConfigurations = [...queryConfigurations];

        const oldConfiguration = clonedConfigurations[index];
        if (!oldConfiguration) {
            throw new Error(`No configuration with index ${index}`);
        }

        clonedConfigurations[index] = {
            ...oldConfiguration,
            query: newValue,
            queryTested: false,
            pagingValidationError: validateSQLDatabasePaginationQuery(
                oldConfiguration.paginationEnabled,
                newValue,
                oldConfiguration.pageSize,
            ),
        };

        setQueryConfigurations(clonedConfigurations);
        setIsValid(
            clonedConfigurations.every((currentConfiguration) =>
                isSQLDatabaseQueryConfigurationValid(currentConfiguration),
            ),
        );
    };

    const changeEntityIdType = (newEntityIdType: SQLDatabaseEntityIdType, index: number) => {
        changeSQLQueryConfiguration('entityIdType', newEntityIdType, index);
    };

    const changeIdentifierColumn = (newIdentifierColumn: string, index: number) => {
        changeSQLQueryConfiguration('identifierColumn', newIdentifierColumn, index);
    };

    const changePaginationEnabled = (newPaginationEnabled: boolean, index: number, newPageSize?: number) => {
        const clonedConfigurations = [...queryConfigurations];
        const oldConfiguration = clonedConfigurations[index];
        if (!oldConfiguration) {
            throw new Error(`No index ${index} in queryConfigurations`);
        }

        const pagingValidationError = validateSQLDatabasePaginationQuery(
            newPaginationEnabled,
            oldConfiguration.query,
            newPageSize ?? oldConfiguration.pageSize,
        );

        clonedConfigurations[index] = {
            ...oldConfiguration,
            paginationEnabled: newPaginationEnabled,
            pageSize: newPageSize ?? oldConfiguration.pageSize,
            pagingValidationError,
        };

        setQueryConfigurations(clonedConfigurations);
        setIsValid(
            clonedConfigurations.every((currentConfiguration) =>
                isSQLDatabaseQueryConfigurationValid(currentConfiguration),
            ),
        );
    };

    const changePagesAmount = (newPagesAmount: string, index: number) => {
        changeSQLQueryConfiguration('pagesAmount', newPagesAmount, index);
    };

    const changePageSize = (newPageSize: number, index: number) => {
        changePaginationEnabled(true, index, newPageSize);
    };

    const changeCustomTimestampsEnabled = (newCustomTimestampsEnabled: boolean, index: number) => {
        changeSQLQueryConfiguration('customTimestampsEnabled', newCustomTimestampsEnabled, index);
    };

    const changeCreatedColumn = (newCreatedColumn: string, index: number) => {
        changeSQLQueryConfiguration('createdColumn', newCreatedColumn, index);
    };

    const changeUpdatedColumn = (newUpdatedColumn: string, index: number) => {
        changeSQLQueryConfiguration('updatedColumn', newUpdatedColumn, index);
    };

    const changeQueryTested = (newQueryTestedValue: boolean, index: number) => {
        changeSQLQueryConfiguration('queryTested', newQueryTestedValue, index);
    };

    const removeQuery = async (currentIndex: number) => {
        let canDelete = true;

        const sqlDatabaseQueryConfiguration = queryConfigurations[currentIndex];
        if (sqlDatabaseQueryConfiguration?.existingQueryConfiguration) {
            canDelete = await confirm(
                `Do you want to delete ${sqlDatabaseQueryConfiguration.queryName}?`,
                deleteConfirmationMessage,
                {
                    cancelLabel: 'Cancel',
                    okLabel: 'Delete',
                },
            );
        }

        if (canDelete) {
            // Filtering out deleted query.
            let clonedConfigurations = queryConfigurations.filter(
                (_sqlQueryConfiguration, index) => index !== currentIndex,
            );

            // If we have an empty array, we add an empty query to it.
            if (!clonedConfigurations.length) {
                counterRef.current = counterRef.current + 1;
                clonedConfigurations.push(getEmptySQLDatabaseQueryConfiguration(counterRef.current));
            }

            // Calculating the correct nameValidationError for all queries.
            clonedConfigurations = clonedConfigurations.map((currentConfiguration, clonedIndex) => {
                // Gathering all the names of other query entities.
                const otherQueriesNames = new Set(
                    clonedConfigurations
                        .map((innerConfig, innerIndex) => {
                            if (innerIndex === clonedIndex) {
                                // We don't want current element.
                                return undefined;
                            } else {
                                // Otherwise, we just take the query name.
                                return innerConfig.queryName;
                            }
                        })
                        .filter((innerConfig) => innerConfig),
                );

                return {
                    ...currentConfiguration,
                    queryName: currentConfiguration.queryName,
                    nameValidationError: otherQueriesNames.has(currentConfiguration.queryName)
                        ? 'Query entity name already exists!'
                        : undefined,
                };
            });

            setQueryConfigurations(clonedConfigurations);
            setIsValid(
                clonedConfigurations.every((currentConfiguration) =>
                    isSQLDatabaseQueryConfigurationValid(currentConfiguration),
                ),
            );
        }
    };

    const addQuery = () => {
        counterRef.current = counterRef.current + 1;
        const addedQuery = getEmptySQLDatabaseQueryConfiguration(counterRef.current);
        addedQuery.expanded = true;
        setQueryConfigurations([...queryConfigurations, addedQuery]);
        setIsValid(false);
    };

    useEffect(() => {
        onChange({ queries: queryConfigurations });
    }, [onChange, queryConfigurations]);

    return {
        queryConfigurations,
        toggleEditMode,
        changeQueryName,
        changeExpanded,
        changeQuery,
        changeEntityIdType,
        changeIdentifierColumn,
        changePaginationEnabled,
        changePagesAmount,
        changePageSize,
        changeCustomTimestampsEnabled,
        changeCreatedColumn,
        changeUpdatedColumn,
        changeQueryTested,
        removeQuery,
        addQuery,
        isValid,
    };
}

export default useEditSQLDatabaseQueryConfigurations;
