import { useCallback } from 'react';

import type InitiativeRowData from '../entities/InitiativeRowData';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import {
    type Initiative,
    tonkeanTypeToIdentifyingPrefixMap,
    type WorkflowVersion,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { SpecialFieldsKey } from '@tonkean/tonkean-entities';

const useUpdateField = (workflowVersion?: WorkflowVersion) => {
    const project = useProject();

    const [, updateInitiativeState] = useLazyTonkeanService('updateInitiativeStatus');

    const [, updateInitiativeOwner] = useLazyTonkeanService('updateInitiativeOwner');

    const [, updateMultipleFieldsValues] = useLazyTonkeanService('updateMultipleFieldsValues');

    const [, updateInitiativeDueDate] = useLazyTonkeanService('updateInitiativeDueDate');

    return useCallback(
        (
            id: Initiative['id'],
            updatedRow: InitiativeRowData,
            updatedCell: string,
            updatedValue: any,
        ): Promise<Initiative | undefined> => {
            // Note, never use the event.data.id because it may be the dummy id
            if (!workflowVersion) {
                return Promise.resolve(undefined);
            }

            switch (updatedCell) {
                case 'state':
                    return updateInitiativeState(id, updatedRow['state']?.label || '', project, workflowVersion);
                case 'title':
                    return updateMultipleFieldsValues(workflowVersion.id, id, {
                        [SpecialFieldsKey.TITLE]: updatedRow.title || '',
                    });
                case 'owner':
                    return updateInitiativeOwner(id, updatedRow['owner']?.id || null, '', null, false);
                case 'dueDate':
                    return updateInitiativeDueDate(id, updatedValue ? new Date(updatedValue).getTime() : null, null);
                default:
                    if (updatedCell.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION])) {
                        return updateMultipleFieldsValues(workflowVersion.id, id, {
                            [updatedCell]: updatedValue,
                        });
                    }
                    console.error(`Trying to update unknown field - [${updatedCell}]`);
                    return Promise.reject('Field was not recognized');
            }
        },
        [
            project,
            updateInitiativeDueDate,
            updateInitiativeOwner,
            updateInitiativeState,
            updateMultipleFieldsValues,
            workflowVersion,
        ],
    );
};

export default useUpdateField;
