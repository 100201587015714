import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import type { BaseEditor, BaseElement, Descendant, Element as SlateElement } from 'slate';
import { createEditor } from 'slate';
import type { HistoryEditor } from 'slate-history';
import { withHistory } from 'slate-history';
import type { ReactEditor } from 'slate-react';
import { Editable, Slate, withReact } from 'slate-react';
import type { RenderElementProps } from 'slate-react/dist/components/editable';

import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
    interface CustomTypes {
        Editor: CustomEditor;
        Element: BaseElement & { type: string };
    }
}

interface EditorPluginConfiguration<T extends string = string> {
    component: React.ComponentType<Omit<RenderElementProps, 'element'> & { element: { type: T } }>;
    isInline: boolean;
    isVoid: boolean;
}

type EditorPlugins<T extends Record<string, any>> = { [K in keyof T]: EditorPluginConfiguration<K & string> };

export function createPlugins<T extends Record<string, any>>(pluginsObject: EditorPlugins<T>) {
    return pluginsObject;
}

interface BaseProps<ValueType, OnChangeType extends SlateElement[] | ValueType> {
    value: ValueType;

    onChange(value: OnChangeType): void;

    plugins?: EditorPlugins<Record<string, any>>;
}

interface ConvertValueToNode<ValueType> {
    convertValueToNodes(value: ValueType): SlateElement[];
}

interface ConvertNodeToValue<OnChangeType> {
    convertNodesToValue(nodes: SlateElement[]): OnChangeType;
}

// If ValueType is Node[], the convertValueToNodes prop is optional.
type ConvertValueToNodeProps<ValueType> = ValueType extends SlateElement[]
    ? Partial<ConvertValueToNode<ValueType>>
    : ConvertValueToNode<ValueType>;
// If OnChangeType is Node[], the convertNodesToValue prop is optional.
type ConvertNodeToValueProps<OnChangeType> = OnChangeType extends SlateElement[]
    ? Partial<ConvertNodeToValue<OnChangeType>>
    : ConvertNodeToValue<OnChangeType>;

type Props<ValueType, OnChangeType extends SlateElement[] | ValueType> = BaseProps<ValueType, OnChangeType> &
    ConvertValueToNodeProps<ValueType> &
    ConvertNodeToValueProps<OnChangeType> &
    Omit<React.TextareaHTMLAttributes<HTMLDivElement>, 'onChange' | 'value'>;

const OldCoreEditor = <ValueType = Descendant[], OnChangeType extends SlateElement[] | ValueType = SlateElement[]>(
    {
        value: valueProp,
        onChange: onChangeProps,
        plugins,
        // `convertValueToNodes` is only optional when `ValueType` is BaseNode[].
        convertValueToNodes = ((value: Descendant[]) => value) as any,
        // `convertNodesToValue` is only optional when `OnChangeType` is BaseNode[].
        convertNodesToValue = ((nodes: Descendant[]) => nodes) as any,
        onBlur: onBlurProp,
        onFocus: onFocusProp,
        ...props
    }: Props<ValueType, OnChangeType>,
    ref: React.ForwardedRef<ReactEditor | null>,
): React.ReactElement => {
    const getPlugin = useCallback(
        (element: SlateElement) => {
            if (!plugins) {
                return;
            }

            return Object.entries(plugins).find(([pluginName]) => pluginName === element['type'])?.[1];
        },
        [plugins],
    );

    const editor = useMemo(() => {
        const newEditor = withHistory(withReact(createEditor()));

        // This is needed because we will override the reference so we need to store the reference to the original methods.
        const { isInline, isVoid } = newEditor;

        newEditor.isInline = (element) => {
            const plugin = getPlugin(element);
            return plugin ? plugin.isInline : isInline(element);
        };

        newEditor.isVoid = (element) => {
            const plugin = getPlugin(element);
            return plugin ? plugin.isVoid : isVoid(element);
        };

        return newEditor;
    }, [getPlugin]);

    const convertValue = useCallback(
        (newValue: ValueType) => {
            // A bug in slate requires you to have at lease one node, so if the value we get is an empty array we will
            // use an empty node as value.
            const emptyNodes: SlateElement[] = [
                {
                    type: 'paragraph',
                    children: [{ text: '' }],
                },
            ];

            const nodes = convertValueToNodes(newValue);
            return {
                source: newValue,
                nodes: nodes.length ? nodes : emptyNodes,
            };
        },
        [convertValueToNodes],
    );

    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState<{ source?: ValueType; nodes: SlateElement[] }>(() => convertValue(valueProp));

    useEffect(() => {
        // A bug in slate disallows changing the value while the user is focused - it throws point not found error.
        if (focused) {
            return;
        }

        setValue((currentValue) => {
            if (currentValue.source === valueProp) {
                return currentValue;
            }

            return convertValue(valueProp);
        });
    }, [convertValue, focused, valueProp]);

    const onChange = (nodes: SlateElement[]) => {
        const emittedValue = convertNodesToValue(nodes);

        setValue({ nodes, source: emittedValue as ValueType });
        onChangeProps(emittedValue as OnChangeType);
    };

    const renderElement = useCallback(
        (renderElementProps: RenderElementProps) => {
            const plugin = getPlugin(renderElementProps.element);

            if (plugin) {
                return <plugin.component {...renderElementProps} />;
            }

            return <div {...renderElementProps.attributes}>{renderElementProps.children}</div>;
        },
        [getPlugin],
    );

    const onFocus = useCompositeEventCallback(() => {
        setFocused(true);
    }, onFocusProp);
    const onBlur = useCompositeEventCallback(() => {
        setFocused(false);
    }, onBlurProp);

    useImperativeHandle(ref, () => editor, [editor]);

    return (
        <Slate editor={editor} value={value.nodes} onChange={onChange}>
            <Editable renderElement={renderElement} onFocus={onFocus} onBlur={onBlur} {...props} />
        </Slate>
    );
};

type ComponentType = <ValueType = SlateElement[], OnChangeType extends SlateElement[] | ValueType = SlateElement[]>(
    props: Props<ValueType, OnChangeType> & React.RefAttributes<ReactEditor>,
) => React.ReactElement;

export default React.forwardRef(OldCoreEditor) as ComponentType;
