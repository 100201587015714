import React from 'react';

import SearchWidgetInnerStateContainer from './SearchWidgetInnerStateContainer';

import { H3, IconSvg, Paragraph } from '@tonkean/infrastructure';
import { SearchLargeIcon } from '@tonkean/svg';

interface Props {
    searchText: string;
}

const SearchWidgetNoResultsState: React.FC<Props> = ({ searchText }) => {
    return (
        <SearchWidgetInnerStateContainer data-automation="search-widget-no-results-state">
            <IconSvg as={SearchLargeIcon} size={114} />

            <H3>No result for '{searchText}'</H3>
            <Paragraph $light>Try to refine your search</Paragraph>
        </SearchWidgetInnerStateContainer>
    );
};

export default SearchWidgetNoResultsState;
