import { css } from 'styled-components';

import { Breakpoint } from '@tonkean/infrastructure';

const ActionButtonSharedSizingCss = css`
    max-width: min(350px, 100%);
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        min-height: 40px;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default ActionButtonSharedSizingCss;
