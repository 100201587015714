export enum OneDriveDriveType {
    ROOT = 'ROOT',
    SHARED = 'SHARED',
}

interface OneDrive365ProjectDatas {
    url: string;
    pathToFolder: { id: string; name: string }[];
    externalDriveId?: string;
    driveType?: OneDriveDriveType;
}

export default OneDrive365ProjectDatas;
