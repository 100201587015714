import React from 'react';
import styled from 'styled-components';

import { Field, Input, useFormikField } from '@tonkean/infrastructure';
import type { FieldValue } from '@tonkean/tonkean-entities';

const InputField = styled(Field)`
    margin-bottom: 20px;
`;

interface Props {
    name: string;
    isEdit: boolean;
}

const ViewDataAddRecordRow: React.FC<Props> = ({ name, isEdit }) => {
    const {
        value: { fieldLabel, isMandatory },
    } = useFormikField<FieldValue>(name);
    return (
        <Field label={fieldLabel} showOptional={!isMandatory}>
            <InputField>
                <Input
                    placeholder="Insert Value"
                    name={`${name}.value`}
                    data-automation={`view-data-add-record-row-value-${fieldLabel}`}
                    disabled={isMandatory && isEdit}
                />
            </InputField>
        </Field>
    );
};

export default ViewDataAddRecordRow;
