import openUpgradeModalTemplate from './../components/modals/openUpgradeModal/openUpgradeModal.template.html?angularjs';
import openOrgOverviewModalTemplate
    from './../components/modals/openOrgOverviewModal/openOrgOverviewModal.template.html?angularjs';
import trialExpiredModalTemplate
    from './../components/modals/trialExpiredModal/trialExpiredModal.template.html?angularjs';
import migrateToNewPricingModalTemplate
    from './../components/modals/migrateToNewPricing/migrateToNewPricingModal.template.html?angularjs';
import onBoardingModalTemplate from './../components/modals/onBoardingModal/onBoardingModal.template.html?angularjs';
import onBoardingContinuousModalTemplate
    from './../components/modals/onBoardingContinuousModal/onBoardingContinuousModal.template.html?angularjs';
import updateInitiativeStateModalTemplate
    from './../components/modals/updateInitiativeStateModal/updateInitiativeStateModal.template.html?angularjs';
import setupGroupPreviewModalTemplate
    from './../components/modals/setupGroupPreviewModal/setupGroupPreviewModal.template.html?angularjs';
import manageGroupsModalTemplate
    from './../components/modals/manageGroupsModal/manageGroupsModal.template.html?angularjs';
import privateGroupModalTemplate
    from './../components/modals/privateGroupModal/privateGroupModal.template.html?angularjs';
import moveToTrackModalTemplate from './../components/modals/moveToTrackModal/moveToTrackModal.template.html?angularjs';
import syncTracksModalTemplate from './../components/modals/syncTracksModal/syncTracksModal.template.html?angularjs';
import importTracksModalTemplate
    from './../components/modals/importTracksModal/importTracksModal.template.html?angularjs';
import testBotModalTemplate from './../components/modals/testBotModal/testBotModal.template.html?angularjs';
import workflowVersionActionModalTemplate
    from '../components/modals/workflowVersionActionModal/workflowVersionActionModal.template.html?angularjs';
import botGatherExampleModalTemplate
    from './../components/modals/botGatherExampleModal/botGatherExampleModal.template.html?angularjs';
import exampleHighlightsModalTemplate
    from './../components/modals/exampleHighlightsModal/exampleHighlightsModal.template.html?angularjs';
import quickNavModalTemplate from './../components/modals/quickNavModal/quickNavModal.template.html?angularjs';
import addIntegrationModalTemplate
    from './../components/modals/addIntegrationModal/addIntegrationModal.template.html?angularjs';
import importActionConfigModalTemplate
    from './../components/modals/importActionConfigModal/importActionConfigModal.template.html?angularjs';
import entitiesExpirationConfigurationModalTemplate
    from './../components/modals/entitiesExpirationConfiguration/entitiesExpirationConfigurationModal.template.html?angularjs';
import editCustomWebhookItemModalTemplate
    from './../components/modals/editCustomWebhookItemModal/editCustomWebhookItemModal.template.html?angularjs';
import googleSheetSelectorModalTemplate
    from './../components/modals/googleSheetSelectorModal/googleSheetSelectorModal.template.html?angularjs';
import manageColumnsModalTemplate
    from './../components/modals/manageColumnsModal/manageColumnsModal.template.html?angularjs';
import educationModalTemplate from './../components/modals/educationModal/educationModal.template.html?angularjs';
import syncPreviewModalTemplate from './../components/modals/syncPreviewModal/syncPreviewModal.template.html?angularjs';
import deleteFieldDefinitionModalTemplate
    from './../components/modals/deleteFieldDefinitionModal/deleteFieldDefinitionModal.template.html?angularjs';
import shareModalTemplate from './../components/modals/shareModal/shareModal.template.html?angularjs';
import shareListModalTemplate from './../components/modals/shareListModal/shareListModal.template.html?angularjs';
import emailEndpointModalTemplate
    from './../components/modals/emailEndpointModal/emailEndpointModal.template.html?angularjs';
import calendarSubscriptionModalTemplate
    from './../components/modals/calendarSubscriptionModal/calendarSubscriptionModal.template.html?angularjs';
import manageScheduledReportsModalTemplate
    from './../components/modals/manageScheduledReportsModal/manageScheduledReportsModal.template.html?angularjs';
import triggersGalleryModalTemplate
    from './../components/modals/triggersGalleryModal/triggersGalleryModal.template.html?angularjs';
import addScheduledReportModalTemplate
    from './../components/modals/addScheduledReportModal/addScheduledReportModal.template.html?angularjs';
import testWorkflowModalTemplate
    from './../components/modals/testWorkflowModal/testWorkflowModal.template.html?angularjs';
import revertDraftVersionModalTemplate
    from './../components/modals/revertDraftVersionModal/revertDraftVersionModal.template.html?angularjs';
import workflowVersionChangesModalTemplate
    from './../components/modals/workflowVersionChangesModal/workflowVersionChangesModal.template.html?angularjs';
import workflowVersionChangeDetailsModalTemplate
    from './../components/modals/workflowVersionChangeDetailsModal/workflowVersionChangeDetailsModal.template.html?angularjs';
import copyToListModalTemplate from './../components/modals/copyToListModal/copyToListModal.template.html?angularjs';
import fieldDefinitionConfigurationModalTemplate
    from './../components/modals/fieldDefinitionConfigurationModal/fieldDefinitionConfigurationModal.template.html?angularjs';
import connectFieldManuallyModalTemplate
    from './../components/modals/connectFieldManuallyModal/connectFieldManuallyModal.template.html?angularjs';
import moreAvatarsModalTemplate from './../components/modals/moreAvatarsModal/moreAvatarsModal.template.html?angularjs';
import mapIntegrationUsersModalTemplate
    from './../components/modals/mapIntegrationUsersModal/mapIntegrationUsersModal.template.html?angularjs';
import turnGatherUpdatesOffModalTemplate
    from './../components/modals/turnGatherUpdatesOffModal/turnGatherUpdatesOffModal.template.html?angularjs';
import chooseWorkerTypeModalTemplate
    from './../components/modals/chooseWorkerTypeModal/chooseWorkerTypeModal.template.html?angularjs';
import createWorkflowFolderModalTemplate
    from './../components/modals/createWorkflowFolderModal/createWorkflowFolderModal.template.html?angularjs';
import changeGroupsWorkflowFolderModalTemplate
    from './../components/modals/changeGroupsWorkflowFolderModal/changeGroupsWorkflowFolderModal.template.html?angularjs';
import httpRequestPreviewModalTemplate
    from './../components/modals/httpRequestPreviewModal/httpRequestPreviewModal.template.html?angularjs';
import editWorkerFormModalTemplate
    from './../components/modals/editWorkerFormModal/editWorkerFormModal.template.html?angularjs';
import type { ui } from 'angular';
import { TrackActions } from '@tonkean/flux';
import type { AngularServices } from 'angulareact';
import SetupGroupModalNewTemplate
    from '../../app/components/modals/setupGroupModalNew/setupGroupModalNew.template.html?angularjs';
import SetupGroupModalTemplate
    from '../../app/components/modals/setupGroupModal/setupGroupModal.template.html?angularjs';
import createNewProjectIntegrationTemplate
    from '../components/modals/createNewProjectIntegrationModal/createNewProjectIntegrationModal.template.html?angularjs';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { analyticsWrapper } from '@tonkean/analytics';

export class ModalUtils {
    /* @ngInject */

    private viewInitiativeModal;
    private workerRunsModal;

    constructor(
        private $rootScope: AngularServices['$rootScope'],
        private $q: AngularServices['$q'],
        private $location: AngularServices['$location'],
        private modal: AngularServices['Modal'],
        private customFieldsManager: AngularServices['customFieldsManager'],
        private projectManager: AngularServices['projectManager'],
    ) {
        this.$rootScope.$on('$stateChangeStart', () => this.dismissViewInitiative());

        this.$rootScope.$watchCollection(
            () => {
                return this.$location.search();
            },
            () => {
                if (!this.$location.search().tid) {
                    this.dismissViewInitiative();
                }
                if (!this.$location.search().wr) {
                    this.dismissViewWorkerRun();
                }
            },
        );
    }

    /**
     * Open a modal.
     *
     * @param modalProperties - the modal settings.
     * @param key - the modal key.
     * @returns the return type of the function passed in openFunc param.
     */
    public open(
        modalProperties: ui.bootstrap.IModalSettings,
        key: string | undefined,
    ): ui.bootstrap.IModalInstanceService | undefined {
        return this.modal.open(modalProperties, key);
    }

    public openUpgradeModal() {
        if (!this.modal.isModalOpen('upgrade')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    // for now, using both settingsCtrl and UpgradeCtrl, cause the upgrade rely on init that happens at the settings page,
                    // need to make this more separate
                    template: openUpgradeModalTemplate,
                    controller: 'SettingsCtrl',
                    windowClass: 'fullscreen-modal',
                    animation: false,
                },
                'upgrade',
            );

            return modal;
        }
    }

    public openOrgOverviewModal() {
        if (!this.modal.isModalOpen('orgOverview')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    // for now, using both settingsCtrl and OrgOverviewCtrl, cause the upgrade rely on init that happens at the settings page,
                    // need to make this more separate
                    template: openOrgOverviewModalTemplate,
                    controller: 'SettingsCtrl',
                    windowClass: 'fullscreen-modal',
                    animation: false,
                },
                'upgrade',
            );

            return modal;
        }
    }

    public openRequiresUpgrade(tierText) {
        if (!this.modal.isModalOpen('requiresUpgrade')) {
            let tierName = '';
            if (tierText) {
                tierName = `to a <strong>${tierText}</strong> `;
            }

            let bodyText = 'This feature is not available in your current plan.<br/>';
            const isLicensed = !!this.projectManager.project.licensed;
            if (this.projectManager.project.isExpired) {
                bodyText += ` Since your trial ended, you will need to upgrade ${tierName}in order to use it.`;
            } else {
                bodyText += `<p>You will need to upgrade ${tierName} in order to use it.</p>`;
            }

            const modal = this.modal.openQuestionConfirmModal(
                {
                    windowClass: 'mod-primary',
                    resolve: {
                        questionConfirmModalData() {
                            return {
                                title: 'Upgrade Your Account',
                                body: bodyText,
                                okLabel: isLicensed ? 'Change current plan' : 'View Plans',
                                cancelLabel: 'Cancel',
                            };
                        },
                    },
                },
                'requiresUpgrade',
            );

            modal?.result.then(() => {
                // okLabel clicked.
                // if (isLicensed) {
                // If the project is already payed for - open the org overview modal so he can change the plan type.
                this.openOrgOverviewModal();
                // } else {
                //     // The project is not yet payed for, open the upgrade modal.
                //     this.openUpgradeModal();
                // }
            });

            return modal;
        }
    }

    public openTrialExpiredModal() {
        if (!this.modal.isModalOpen('trialExpiredModal')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    keyboard: false,
                    template: trialExpiredModalTemplate,
                    controller: 'TrialExpiredModalCtrl',
                },
                'trialExpiredModal',
            );
        }
    }

    public openMigratePricingModal() {
        if (!this.modal.isModalOpen('migratePricingModal')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    keyboard: false,
                    template: migrateToNewPricingModalTemplate,
                    controller: 'MigrateToNewPricingModalCtrl',
                    windowClass: 'fullscreen-modal',
                },
                'migratePricingModal',
            );
        }
    }

    public openOnBoarding(selectedUsecase, dontAnimate) {
        if (!this.modal.isModalOpen('onboarding')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: onBoardingModalTemplate,
                    controller: 'OnBoardingModalCtrl',
                    animation: !dontAnimate,
                    size: 'md',
                    resolve: {
                        selectedUsecase() {
                            return selectedUsecase;
                        },
                    },
                },
                'onboarding',
            );
        }
    }

    public openContinuousOnBoardingModal(onDismissedCallback) {
        if (!this.modal.isModalOpen('continuousOnBoarding')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: onBoardingContinuousModalTemplate,
                    controller: 'OnBoardingContinuousModalCtrl',
                    windowClass: 'fullscreen-modal',
                    animation: false,
                    resolve: {
                        onDismissed() {
                            return onDismissedCallback;
                        },
                    },
                },
                'continuousOnBoarding',
            );

            return modal;
        }
    }

    public openViewInitiative(id) {
        this.$location.search('tid', id);
    }

    public openUpdateInitiativeStateModal(initiativeId) {
        if (!this.modal.isModalOpen('setupGroup')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: updateInitiativeStateModalTemplate,
                    controller: 'UpdateInitiativeStateModalCtrl',
                    windowClass: 'fullscreen-modal',
                    animation: false,
                    resolve: {
                        initiativeId() {
                            return initiativeId;
                        },
                    },
                },
                'updateInitiativeState',
            );

            return modal;
        }
    }

    public openSetupGroupModal(
        duplicateGroup,
        showGroupSettings,
        workersTemplateMode,
        isInline,
        emptyOnly,
        workflowFolderId,
        workflowFolderCategoryId,
        marketplaceItemSummary,
    ) {
        // Use the feature flag configuration to decide whether to use the old or new setupGroup modal.
        const template = this.projectManager.projectData.shouldUseTheNewSetupGroupModal
            ? SetupGroupModalNewTemplate
            : SetupGroupModalTemplate;
        const controller = this.projectManager.projectData.shouldUseTheNewSetupGroupModal
            ? 'SetupGroupModalNewCtrl'
            : 'SetupGroupModalCtrl';

        if (!this.modal.isModalOpen('setupGroup')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template,
                    controller,
                    windowClass: 'fullscreen-modal mod-no-padding',
                    animation: false,
                    resolve: {
                        showGroupSettings() {
                            return showGroupSettings;
                        },
                        duplicateGroup() {
                            return duplicateGroup;
                        },
                        workersTemplateMode() {
                            return workersTemplateMode;
                        },
                        isInline() {
                            return isInline;
                        },
                        emptyOnly() {
                            return emptyOnly;
                        },
                        workflowFolderId() {
                            return workflowFolderId;
                        },
                        workflowFolderCategoryId() {
                            return workflowFolderCategoryId;
                        },
                        marketplaceItemSummary() {
                            return marketplaceItemSummary;
                        },
                    },
                },
                'setupGroup',
            );

            return modal;
        }
    }

    public openSetupGroupPreviewModal(selectedList, requestedList, builtInLists) {
        if (!this.modal.isModalOpen('setupGroupPreview')) {
            const modal = this.open(
                {
                    // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: setupGroupPreviewModalTemplate,
                    controller: 'SetupGroupPreviewModalCtrl',
                    windowClass: 'setup-group-modal-black',
                    size: 'md2',
                    animation: false,
                    resolve: {
                        selectedList() {
                            return selectedList;
                        },
                        requestedList() {
                            return requestedList;
                        },
                        builtInLists() {
                            return builtInLists;
                        },
                    },
                },
                'setupGroupPreview',
            );

            return modal;
        }
    }

    public openManageGroupsModal(requestedTab) {
        if (!this.modal.isModalOpen('manageGroups')) {
            this.open(
                {
                    // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: manageGroupsModalTemplate,
                    controller: 'ManageGroupsModalCtrl',
                    windowClass: 'mod-primary',
                    animation: false,
                    resolve: {
                        requestedTab() {
                            return requestedTab;
                        },
                    },
                },
                'manageGroups',
            );
        }
    }

    public openPrivateGroupModal(group, tab, dontRedirect, hideListName) {
        if (!this.modal.isModalOpen('privateGroup')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: privateGroupModalTemplate,
                    controller: 'PrivateGroupModalCtrl',
                    windowClass: 'fullscreen-modal',
                    animation: false,
                    resolve: {
                        group() {
                            return group;
                        },
                        tab() {
                            return tab;
                        },
                        dontRedirect() {
                            return dontRedirect;
                        },
                        hideListName() {
                            return hideListName;
                        },
                    },
                },
                'privateGroup',
            );
        }
    }

    public openMoveToTrackModal(initiative) {
        if (!this.modal.isModalOpen('moveToTrack')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: moveToTrackModalTemplate,
                    controller: 'MoveToTrackModalCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        initiative() {
                            return initiative;
                        },
                    },
                },
                'moveToTrack',
            );
        }
    }

    public openMoveGroupModal(item, allowLinking) {
        return this.modal.openMoveGroupModal(item, allowLinking);
    }

    /**
     * Opens the sync settings modal.
     */
    public openSyncTracksModal(groupId, workflowVersionId, targetEntityId) {
        if (!this.modal.isModalOpen('syncSettings')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: syncTracksModalTemplate,
                    controller: 'SyncTracksModalCtrl',
                    size: 'md2',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                        targetEntityId() {
                            return targetEntityId;
                        },
                    },
                },
                'syncTracksModal ',
            );
        }
    }

    public openImportTracks(
        func: (() => void) | null,
        parent: any,
        groupId: string,
        workflowVersionId: string,
        callback?: () => void,
        dontAnimate?: boolean,
        formId?: string,
        fieldsNames?: string[],
        createInEditMode = false,
        disableImportFromIntegrations = false,
    ) {
        if (!this.modal.isModalOpen('importTracks')) {
            const importTracksSettings = {
                func,
                parentInitiative: parent,
                groupId,
                onCompleteCallback: callback,
                createInEditMode,
                formId,
                workflowVersionId,
                fieldsNames,
                disableImportFromIntegrations,
            };

            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    // templateUrl: 'components/importTracksModal/importTracksModal.html',
                    template: importTracksModalTemplate,
                    controller: 'ImportTracksModalCtrl',
                    animation: !dontAnimate,
                    size: 'md2',
                    windowClass: 'mod-primary',
                    resolve: {
                        importTracksSettings() {
                            return importTracksSettings;
                        },
                    },
                },
                'importTracks',
            );
        }
    }

    public openInitiativeSettingsModal(initiative, showSetReminder, manualGatherUpdate, onReminderSet) {
        return this.modal.openInitiativeSettingsModal(
            initiative,
            showSetReminder,
            manualGatherUpdate,
            onReminderSet
        );
    }

    public openTestBotModal() {
        if (!this.modal.isModalOpen('testBot')) {
            analyticsWrapper.track('Open modal', { category: 'Test Bot' });

            const modal = {
                // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: testBotModalTemplate,
                controller: 'TestBotCtrl',
                windowClass: 'mod-primary',
            };

            return this.open(modal, 'testBot');
        }
    }

    public openWorkflowVersionActionModal(
        groupId: string,
        onSuccessfulAction?: () => void,
        action: string = 'markReadyToPublish',
        onReadyToPublishCompareClicked?: () => void,
    ) {
        if (!this.modal.isModalOpen('publishWorkflowVersionModal')) {
            analyticsWrapper.track('Open modal', { category: 'Ready to Publish Workflow Version' });

            const modal = {
                backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: workflowVersionActionModalTemplate,
                controller: 'workflowVersionActionModalCtrl',
                size: 'md2',
                windowClass: 'vertical-middle-modal overflow-hidden-modal',
                resolve: {
                    groupId() {
                        return groupId;
                    },
                    entityVersionId() {
                        return undefined;
                    },
                    solutionSitePageId() {
                        return undefined;
                    },
                    onSuccessfulAction() {
                        return onSuccessfulAction;
                    },
                    action() {
                        return action;
                    },
                    onReadyToPublishCompareClicked() {
                        return onReadyToPublishCompareClicked;
                    },
                    targetTypeId() {
                        return undefined;
                    },
                    useTotalCommitted() {
                        return true;
                    },
                },
            };

            return this.open(modal, 'workflowVersionActionModal');
        }
    }

    public openSolutionSitePageVersionActionModal(
        solutionSitePageId?: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
        entityVersionId?: string,
        onSuccessfulAction?: () => void,
        action: string = 'markSolutionSitePageReadyToPublish',
        onReadyToPublishCompareClicked?: () => void,
    ) {
        if (solutionSitePageId) {
            analyticsWrapper.track('Open modal', { category: 'Ready to Publish Entity Version' });

            const modal = {
                backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: workflowVersionActionModalTemplate,
                controller: 'workflowVersionActionModalCtrl',
                size: 'md2',
                windowClass: 'vertical-middle-modal overflow-hidden-modal',
                resolve: {
                    groupId() {
                        return undefined;
                    },
                    entityVersionId() {
                        return entityVersionId;
                    },
                    solutionSitePageId() {
                        return solutionSitePageId;
                    },
                    onSuccessfulAction() {
                        return onSuccessfulAction;
                    },
                    action() {
                        return action;
                    },
                    onReadyToPublishCompareClicked() {
                        return onReadyToPublishCompareClicked;
                    },
                    targetTypeId() {
                        return solutionSitePageId;
                    },
                    useTotalCommitted() {
                        return true;
                    },
                },
            };

            return this.open(modal, 'solutionSitePageActionModal');
        }
    }

    public openBotGatherExampleModal(hideManageTriggersModal) {
        if (!this.modal.isModalOpen('botGatherExample')) {
            analyticsWrapper.track('Open modal', { category: 'Bot Gather Example' });

            const modal = {
                // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: botGatherExampleModalTemplate,
                controller: 'BotGatherExampleCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    hideManageTriggersModal() {
                        return hideManageTriggersModal;
                    },
                },
            };

            return this.open(modal, 'botGatherExample');
        }
    }

    public openExampleHighlightsModal() {
        if (!this.modal.isModalOpen('exampleHighlights')) {
            analyticsWrapper.track('Open modal', { category: 'Example Highlights' });

            const modal = {
                // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: exampleHighlightsModalTemplate,
                controller: 'ExampleHighlightsCtrl',
                windowClass: 'modal-fit-content-no-xs',
            };

            this.open(modal, 'exampleHighlights');
        }
    }

    public openQuickNavModal(dontAnimate) {
        if (!this.modal.isModalOpen('quickNav')) {
            analyticsWrapper.track('Open modal', { category: 'Quick Nav' });

            const modal = {
                // backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: quickNavModalTemplate,
                controller: 'QuickNavModalCtrl',
                windowClass: 'modal-fit-content-no-xs margin-top-xxlg',
                animation: !dontAnimate,
            };

            this.open(modal, 'quickNav');
        }
    }

    public openAddIntegration(workflowVersionId, groupId, callback, mode, filter) {
        if (!this.modal.isModalOpen('addIntegration')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: addIntegrationModalTemplate,
                    controller: 'AddIntegrationCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                        onCompleteCallback() {
                            return callback;
                        },
                        mode() {
                            return mode;
                        },
                        filter() {
                            return filter;
                        },
                    },
                },
                'addIntegration',
            );
        }
    }

    public openImportActionConfig(actionType) {
        return this.open(
            {
                backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: importActionConfigModalTemplate,
                controller: 'ImportActionConfigCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    actionType: () => actionType,
                },
            },
            'importActionConfig',
        );
    }

    public openEntitiesConfigurationExpirationModal(projectIntegrationId, integrationName) {
        return this.open(
            {
                backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                template: entitiesExpirationConfigurationModalTemplate,
                controller: 'EntitiesExpirationConfigurationModalCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    projectIntegrationId() {
                        return projectIntegrationId;
                    },
                    integrationName() {
                        return integrationName;
                    },
                },
            },
            'entitiesConfigurationExpiration',
        );
    }

    public openEditCustomWebhookItemModal(projectIntegration, existingItem) {
        if (!this.modal.isModalOpen('editCustomWebhookItem')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: editCustomWebhookItemModalTemplate,
                    controller: 'EditCustomWebhookItemModal',
                    size: 'md',
                    animation: false,
                    resolve: {
                        projectIntegration() {
                            return projectIntegration;
                        },
                        existingItem() {
                            return existingItem;
                        },
                    },
                },
                'editCustomWebhookItem',
            );
        }
    }

    public openGoogleSheetSelectorModal(onProjectIntegrationCreated) {
        if (!this.modal.isModalOpen('googleSheetSelector')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: googleSheetSelectorModalTemplate,
                    controller: 'GoogleSheetSelectorModalCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        onProjectIntegrationCreated() {
                            return onProjectIntegrationCreated;
                        },
                    },
                },
                'googleSheetSelector',
            );
        }
    }

    public openManageColumnsModal(groupId, workflowVersionId) {
        if (!this.modal.isModalOpen('manageColumns')) {
            return this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: manageColumnsModalTemplate,
                    controller: 'ManageColumnsModalCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                    },
                },
                'manageColumns',
            );
        }
    }

    public openEducationItemModal(educationItem, group, workflowVersionId) {
        if (!this.modal.isModalOpen('educationItem')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: educationModalTemplate,
                    controller: 'EducationModalCtrl',
                    size: 'md2',
                    resolve: {
                        educationItem() {
                            return educationItem;
                        },
                        group() {
                            return group;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                    },
                },
                'educationItem',
            );
        }
    }

    public openSyncPreviewModal(
        newControl,
        customDefinedView,
        pluralEntityType,
        singleEntityType,
        projectIntegration,
        viewData,
        viewType,
        getAllIntegrationViews,
        isBotActiveObject,
        hideBotInPreview,
        allowSave,
        saveFunction,
        closeFunction,
        titleExpression,
        defaultTitleIfExpressionEmpty,
    ) {
        if (!this.modal.isModalOpen('syncPreview')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: syncPreviewModalTemplate,
                    controller: 'SyncPreviewModalCtrl',
                    size: 'md3',
                    windowClass: 'mod-primary',
                    resolve: {
                        newControl() {
                            return newControl;
                        },
                        customDefinedView() {
                            return customDefinedView;
                        },
                        pluralEntityType() {
                            return pluralEntityType;
                        },
                        projectIntegration() {
                            return projectIntegration;
                        },
                        viewData() {
                            return viewData;
                        },
                        viewType() {
                            return viewType;
                        },
                        titleExpression() {
                            return titleExpression;
                        },
                        defaultTitleIfExpressionEmpty() {
                            return defaultTitleIfExpressionEmpty;
                        },
                        getAllIntegrationViews() {
                            return getAllIntegrationViews;
                        },
                        hideBotInPreview() {
                            return hideBotInPreview;
                        },
                        isBotActiveObject() {
                            return isBotActiveObject;
                        },
                        allowSave() {
                            return allowSave;
                        },
                        saveFunction() {
                            return saveFunction;
                        },
                        closeFunction() {
                            return closeFunction;
                        },
                        singleEntityType() {
                            return singleEntityType;
                        },
                    },
                },
                'syncPreview',
            );
        }
    }

    public openDeleteFieldDefinitionModal(
        fieldDefinitionId,
        fieldDefinitionName,
        isGlobalFieldDefinition,
        globalFieldId,
        groupId,
        workflowVersionId,
        groupInfoManager,
    ) {
        if (!this.modal.isModalOpen('deleteFieldDefinitionModal')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: deleteFieldDefinitionModalTemplate,
                    controller: 'DeleteFieldDefinitionModalCtrl',
                    size: 'md2',
                    windowClass: 'mod-primary',
                    resolve: {
                        fieldDefinitionId() {
                            return fieldDefinitionId;
                        },
                        fieldDefinitionName() {
                            return fieldDefinitionName;
                        },
                        isGlobalFieldDefinition() {
                            return isGlobalFieldDefinition;
                        },
                        globalFieldId() {
                            return globalFieldId;
                        },
                        groupId() {
                            return groupId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                    },
                },
                'deleteFieldDefinitionModal',
            );

            return modal?.result.then((deletedSuccessfully) => {
                return groupInfoManager.getGroup(groupId, true).then(() => {
                    TrackActions.fieldDefinitionsUpdated();

                    return this.$q.resolve(deletedSuccessfully);
                });
            });
        }
    }

    public openShareModal(entityId, entityName) {
        if (!this.modal.isModalOpen('shareModal')) {
            this.open(
                {
                    template: shareModalTemplate,
                    controller: 'ShareCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        entityId() {
                            return entityId;
                        },
                        entityName() {
                            return entityName;
                        },
                    },
                },
                'shareModal',
            );
        }
    }

    public openShareListModal(groupId) {
        if (!this.modal.isModalOpen('shareListModal')) {
            this.open(
                {
                    template: shareListModalTemplate,
                    controller: 'ShareListCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                    },
                },
                'shareListModal',
            );
        }
    }

    public openEmailEndpointModal(groupId) {
        if (!this.modal.isModalOpen('emailEndpointModal')) {
            this.open(
                {
                    template: emailEndpointModalTemplate,
                    controller: 'EmailEndpointCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                    },
                },
                'emailEndpointModal',
            );
        }
    }

    public openCalendarSubscriptionModal(groupId) {
        if (!this.modal.isModalOpen('calendarSubscriptionModal')) {
            this.open(
                {
                    template: calendarSubscriptionModalTemplate,
                    controller: 'CalendarSubscriptionCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                    },
                },
                'calendarSubscriptionModal',
            );
        }
    }

    public openManageScheduledReportsModal(groupId) {
        if (!this.modal.isModalOpen('manageScheduledReportsModal')) {
            this.open(
                {
                    template: manageScheduledReportsModalTemplate,
                    controller: 'ManageScheduledReportsCtrl',
                    windowClass: 'manage-scheduled-reports-modal',
                    size: 'md2',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                    },
                },
                'manageScheduledReportsModal',
            );
        }
    }

    public openTriggersGalleryModal(fullscreenMode) {
        if (!this.modal.isModalOpen('triggersGalleryModal')) {
            this.open(
                {
                    template: triggersGalleryModalTemplate,
                    controller: 'triggersGalleryModalCtrl',
                    animation: false,
                    windowClass: fullscreenMode ? 'fullscreen-modal mod-full-background' : '',
                    size: 'md2',
                },
                'customTriggerModal',
            );
        }
    }

    public openAddScheduledReportModal(createMode, onClosedCallback, createOrUpdateCallback, editedReport, groupId) {
        if (!this.modal.isModalOpen('addScheduledReportModal')) {
            this.open(
                {
                    template: addScheduledReportModalTemplate,
                    controller: 'AddScheduledReportCtrl',
                    windowClass: 'add-scheduled-report-modal',
                    size: 'md2',
                    resolve: {
                        createMode() {
                            return createMode;
                        },
                        onClosedCallback() {
                            return onClosedCallback;
                        },
                        createOrUpdateCallback() {
                            return createOrUpdateCallback;
                        },
                        editedReport() {
                            return editedReport;
                        },
                        groupId() {
                            return groupId;
                        },
                    },
                },
                'addScheduledReportModal',
            );
        }
    }

    public openTestWorkflowModal(
        groupId,
        selectedInitiativeId,
        openResultInModal,
        runOnCustomTriggerId,
        workflowVersionId,
    ) {
        if (!this.modal.isModalOpen('testWorkflowModal')) {
            const modal = this.open(
                {
                    template: testWorkflowModalTemplate,
                    controller: 'TestWorkflowModalCtrl',
                    windowClass: 'test-workflow-modal',
                    size: 'md2',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        selectedInitiativeId() {
                            return selectedInitiativeId;
                        },
                        openResultInModal() {
                            return openResultInModal;
                        },
                        runOnCustomTriggerId() {
                            return runOnCustomTriggerId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                    },
                },
                'testWorkflowModal',
            );

            return modal?.result;
        }
    }

    public openRevertDraftVersionModal(groupId) {
        if (!this.modal.isModalOpen('revertDraftVersionModal')) {
            const modal = this.open(
                {
                    template: revertDraftVersionModalTemplate,
                    controller: 'RevertDraftVersionCtrl',
                    size: 'md2',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        solutionSitePageId() {
                            return undefined;
                        },
                        entityVersionId() {
                            return undefined;
                        },
                        subSequentialIdentifier() {
                            return undefined;
                        },
                    },
                },
                'revertDraftVersionModal',
            );

            return modal?.result;
        }
    }

    public openRevertDraftSolutionSitePageVersionModal(solutionSitePageId, entityVersionId, subSequentialIdentifier) {
        if (!this.modal.isModalOpen('revertDraftSolutionSitePageVersionModal')) {
            const modal = this.open(
                {
                    template: revertDraftVersionModalTemplate,
                    controller: 'RevertDraftVersionCtrl',
                    size: 'md2',
                    resolve: {
                        groupId() {
                            return undefined;
                        },
                        solutionSitePageId() {
                            return solutionSitePageId;
                        },
                        entityVersionId() {
                            return entityVersionId;
                        },
                        subSequentialIdentifier() {
                            return subSequentialIdentifier;
                        },
                    },
                },
                'revertDraftSolutionSitePageVersionModal',
            );

            return modal?.result;
        }
    }

    public openWorkflowVersionChangesModal(
        groupId,
        workflowVersionId,
        modalTitleContent,
        modalSubtitleContent,
        targetTypeId,
        groupByToggleDefault,
        hideGroupByToggle,
        entityVersionId,
        subSequentialIdentifier,
    ) {
        if (!this.modal.isModalOpen('workflowVersionChangesModal')) {
            const modal = this.open(
                {
                    template: workflowVersionChangesModalTemplate,
                    controller: 'WorkflowVersionChangesModalCtrl',
                    size: 'md2',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                        modalTitleContent() {
                            return modalTitleContent;
                        },
                        modalSubtitleContent() {
                            return modalSubtitleContent;
                        },
                        targetTypeId() {
                            return targetTypeId;
                        },
                        groupByToggleDefault() {
                            return groupByToggleDefault;
                        },
                        hideGroupByToggle() {
                            return hideGroupByToggle;
                        },
                        entityVersionId() {
                            return entityVersionId;
                        },
                        subSequentialIdentifier() {
                            return subSequentialIdentifier;
                        },
                    },
                },
                'workflowVersionChangesModal',
            );

            return modal?.result;
        }
    }

    public openWorkflowVersionChangeDetailsModal(changes, activity) {
        if (!this.modal.isModalOpen('workflowVersionChangeDetailsModal')) {
            const modal = this.open(
                {
                    template: workflowVersionChangeDetailsModalTemplate,
                    controller: 'WorkflowVersionChangeDetailsModalCtrl',
                    windowClass: 'vertical-middle-modal overflow-hidden-modal',
                    resolve: {
                        changes() {
                            return changes;
                        },
                        activity() {
                            return activity;
                        },
                    },
                },
                'workflowVersionChangeDetailsModal',
            );

            return modal?.result;
        }
    }

    public openCopyToListModal(customTrigger, groupId, includeCurrentGroup) {
        if (!this.modal.isModalOpen('copyToListModal')) {
            this.open(
                {
                    template: copyToListModalTemplate,
                    controller: 'CopyToListCtrl',
                    windowClass: 'copy-to-list-modal',
                    size: 'md2',
                    resolve: {
                        customTrigger() {
                            return customTrigger;
                        },
                        groupId() {
                            return groupId;
                        },
                        includeCurrentGroup() {
                            return includeCurrentGroup;
                        },
                    },
                },
                'copyToListModal',
            );
        }
    }

    public openFieldDefinitionConfigurationModal(
        groupId,
        targetType,
        fieldDefinitionType,
        projectIntegration,
        createMode,
        duplicateMode,
        existingFieldDefinition,
        afterCreateOrUpdateCallback,
        deleteCallback,
        openInStep,
        quickCreateForExternal,
        startWithDataSource,
        selectedEntity,
        isWorkerMode,
        overrideFormulaOperator,
        overrideFieldIsHidden,
        manualValue,
        idOnlyMode,
        workflowVersionId,
        allowOnlyFieldDefinitionDataTypes,
    ) {
        if (!this.modal.isModalOpen('fieldDefinitionConfiguration')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    // backdropClass: 'modal-backdrop-not-transparent',
                    template: fieldDefinitionConfigurationModalTemplate,
                    controller: 'FieldDefinitionConfigurationCtrl',
                    windowClass: 'fullscreen-modal mod-full-background field-definition-configuration-modal-container',
                    animation: false,
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        targetType() {
                            return targetType;
                        },
                        fieldDefinitionType() {
                            return fieldDefinitionType;
                        },
                        projectIntegration() {
                            return projectIntegration;
                        },
                        createMode() {
                            return createMode;
                        },
                        duplicateMode() {
                            return duplicateMode;
                        },
                        existingFieldDefinition() {
                            return existingFieldDefinition;
                        },
                        afterCreateOrUpdateCallback() {
                            return afterCreateOrUpdateCallback;
                        },
                        deleteCallback() {
                            return deleteCallback;
                        },
                        openInStep() {
                            return openInStep;
                        },
                        quickCreateForExternal() {
                            return quickCreateForExternal;
                        },
                        startWithDataSource() {
                            return startWithDataSource;
                        },
                        selectedEntity() {
                            return selectedEntity;
                        },
                        isWorkerMode() {
                            return isWorkerMode;
                        },
                        overrideFormulaOperator() {
                            return overrideFormulaOperator;
                        },
                        overrideFieldIsHidden() {
                            return overrideFieldIsHidden;
                        },
                        manualValue() {
                            return manualValue;
                        },
                        idOnlyMode() {
                            return idOnlyMode;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                        allowOnlyFieldDefinitionDataTypes() {
                            return allowOnlyFieldDefinitionDataTypes;
                        },
                    },
                },
                'fieldDefinitionConfiguration',
            );

            return modal?.result.then((updatedFieldDefinition) => {
                if (updatedFieldDefinition) {
                    this.customFieldsManager.updateFieldDefinitionInCaches(workflowVersionId, updatedFieldDefinition);
                }

                return this.$q.resolve(updatedFieldDefinition);
            });
        }
    }

    public openConnectFieldManuallyModal(field, groupId, initiative) {
        if (!this.modal.isModalOpen('connectFieldManually')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: connectFieldManuallyModalTemplate,
                    controller: 'ConnectFieldManuallyCtrl',
                    windowClass: 'mod-primary',
                    resolve: {
                        field() {
                            return field;
                        },
                        groupId() {
                            return groupId;
                        },
                        initiative() {
                            return initiative;
                        },
                    },
                },
                'connectFieldManually',
            );
        }
    }

    public openAskForUpdateModal(initiative, userName) {
        return this.modal.openAskForUpdateModal(initiative, userName);
    }

    public openMoreUsersModal(avatars, anonymousUsersPatters, modPalette, anonymousUserName, shouldDisplayViewTime) {
        if (!this.modal.isModalOpen('openMoreUsersModal')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: moreAvatarsModalTemplate,
                    controller: 'MoreAvatarsCtrl',
                    resolve: {
                        avatars: () => avatars,
                        anonymousUsersPatters: () => anonymousUsersPatters,
                        modPalette: () => modPalette,
                        anonymousUserName: () => anonymousUserName,
                        shouldDisplayViewTime: () => shouldDisplayViewTime,
                    },
                },
                'openMoreUsersModal',
            );
        }
    }

    public openMapIntegrationUsersModal(
        projectIntegration,
        comparePropertyKey = 'actorIdentifyingName',
        usersToMap,
        onUsersMapped,
    ) {
        if (!this.modal.isModalOpen('mapIntegrationUsersModal')) {
            this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: mapIntegrationUsersModalTemplate,
                    controller: 'MapIntegrationUsersModalCtrl',
                    resolve: {
                        projectIntegration() {
                            return projectIntegration;
                        },
                        comparePropertyKey() {
                            return comparePropertyKey;
                        },
                        usersToMap() {
                            return usersToMap;
                        },
                        onUsersMapped() {
                            return onUsersMapped;
                        },
                    },
                },
                'mapIntegrationUsersModal',
            );
        }
    }

    public openCreateNewProjectIntegrationModal(setUp, integration, integrationType) {
        this.open(
            {
                backdrop: 'static',
                template: createNewProjectIntegrationTemplate,
                controller: 'CreateNewProjectIntegrationCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    integration() {
                        return integration;
                    },

                    setUp() {
                        return setUp;
                    },

                    integrationType() {
                        return integrationType;
                    },
                },
            },
            'createNewProjectIntegrationModal',
        );
    }

    public openTurnGatherUpdatesOffModal() {
        if (!this.modal.isModalOpen('turnGatherUpdatesOffModal')) {
            return this.open(
                {
                    template: turnGatherUpdatesOffModalTemplate,
                    controller: 'TurnGatherUpdatesOffCtrl',
                    windowClass: 'mod-primary',
                    resolve: {},
                },
                'turnGatherUpdatesOffModal',
            );
        }
    }

    public openChooseWorkerTypeModal(
        folderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        workflowFolderCategoryId?: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
    ) {
        if (!this.modal.isModalOpen('chooseWorkerTypeModal')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: chooseWorkerTypeModalTemplate,
                    controller: 'ChooseWorkerTypeCtrl',
                    resolve: {
                        folderId() {
                            return folderId;
                        },
                        workflowFolderCategoryId() {
                            return workflowFolderCategoryId;
                        },
                    },
                },
                'chooseWorkerTypeModal',
            );

            return modal?.result;
        }
    }

    public openCreateWorkflowFolderModal(projectId) {
        if (!this.modal.isModalOpen('createWorkflowFolderModal')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: createWorkflowFolderModalTemplate,
                    controller: 'CreateWorkflowFolderCtrl',
                    resolve: {
                        projectId() {
                            return projectId;
                        },
                    },
                },
                'createWorkflowFolderModal',
            );

            return modal?.result;
        }
    }

    public changeGroupsWorkflowFolderModal(groupId, originWorkflowFolderId) {
        if (!this.modal.isModalOpen('changeGroupsWorkflowFolderModal')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: changeGroupsWorkflowFolderModalTemplate,
                    controller: 'ChangeGroupsWorkflowFolderCtrl',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        originWorkflowFolderId() {
                            return originWorkflowFolderId;
                        },
                    },
                },
                'changeGroupsWorkflowFolderModal',
            );

            return modal?.result;
        }
    }

    public openHttpRequestPreviewModal(groupId, options, exampleInitiative) {
        if (!this.modal.isModalOpen('httpRequestPreview')) {
            const modal = this.open(
                {
                    backdrop: 'static',
                    template: httpRequestPreviewModalTemplate,
                    controller: 'HttpRequestPreviewModalCtrl',
                    size: 'lg',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        options() {
                            return options;
                        },
                        exampleInitiative() {
                            return exampleInitiative;
                        },
                    },
                },
                'httpRequestPreview',
            );
            return modal?.result;
        }
    }

    /**
     * Will open an edit form modal
     * @param groupId - needed for create form
     * @param formId - needed for update form
     * @returns {*}
     */
    public openEditWorkerFormModal(
        groupId,
        formId,
        formTypeToCreate,
        callBackFormCreated,
        workflowVersionId,
        viewMode,
    ) {
        if (!this.modal.isModalOpen('openEditWorkerFormModal')) {
            const modal = this.open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: editWorkerFormModalTemplate,
                    controller: 'EditWorkerFormModalCtrl',
                    resolve: {
                        groupId() {
                            return groupId;
                        },
                        formId() {
                            return formId;
                        },
                        formTypeToCreate() {
                            return formTypeToCreate;
                        },
                        callBackFormCreated() {
                            return callBackFormCreated;
                        },
                        workflowVersionId() {
                            return workflowVersionId;
                        },
                        viewMode() {
                            return viewMode;
                        },
                    },
                },
                'openEditWorkerFormModal',
            );

            return modal?.result;
        }
    }

    public openRefreshModuleModal(message?: string) {
        return this.modal.alert('Whoops!', {
            html: message
                ? undefined
                : "<div>Looks like your module is out of date, we found new changes that are missing in your current view.</div><div class='margin-top-md'>Don't worry, we can refresh the module for you!</div>",
            body: message,
            isWarn: false,
            okLabel: 'Refresh Module',
            backdrop: 'static',
            key: 'openRefreshModule',
        })?.finally(() => {
            this.$rootScope.$broadcast('reloadModuleEditorRequest');
        });
    }

    public openChat(message, autoSend) {
        window['Intercom']('showNewMessage', message); // eslint-disable-line new-cap

        if (autoSend && message && message.length) {
            setTimeout(() => {
                const frames = document.getElementsByName('intercom-messenger-frame');
                if (frames && frames.length) {
                    const iframeContent = frames[0]!['contentWindow'];
                    const sendBtn = iframeContent.document.querySelectorAll('.intercom-composer-send-button')[0];
                    if (sendBtn) {
                        sendBtn.click();
                    }
                }
            }, 1000);
        }
    }

    private dismissViewInitiative() {
        // clear view initiatilve modal if open
        if (this.modal.isModalOpen('viewInitiative') && this.viewInitiativeModal) {
            this.viewInitiativeModal.dismiss();
        }
    }

    private dismissViewWorkerRun() {
        // clear view worker run modal if open
        if (this.modal.isModalOpen('workerRunsModal') && this.workerRunsModal) {
            this.workerRunsModal.dismiss();
        }
    }
}

export default angular.module('tonkean.app').service('modalUtils', ModalUtils);
