export function TwilioChatModalCtrl(
    $scope,
    tonkeanService,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.data = {
        workspaceId: '',
        isManagingServiceWebhooks: true,
        isManagingWorkspaceWebhooks: true,
        incomingWebhookUrl: '',

        webhookUrlLoading: false,
    };
    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }
        $scope.integration = state.integration;
        $scope.data.projectIntegration = settings.projectIntegration;
        $scope.selected = state.selected || {};
        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        if ($scope.data.loginModal.$valid) {
            $scope.error = null;
            $scope.loading = true;

            const createIntegrationPromise = createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        api_sid: $scope.data.sid,
                        api_secret: $scope.data.secret,
                        shouldValidateSignature: $scope.data.shouldValidateSignature,
                        authToken: $scope.data.authToken,
                    },
                    integrations.getNewIntegrationUniqueType(authenticationService.currentUser.id, 'TWILIOCHAT'),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                });

            if ($scope.data.projectIntegration) {
                createIntegrationPromise.then(() => $scope.ok());
            } else {
                return createIntegrationPromise
                    .then(() => {
                        return createProjectApis.createProjectIntegration(projectManager.project, $scope.integration);
                    })
                    .then((updatedProject) => {
                        $scope.data.projectIntegration = utils.findFirst(
                            updatedProject.integrations,
                            (prin) => prin.integration.id === $scope.integration.id,
                        );
                    })
                    .then(() => {
                        return createProjectApis.getAutoCompleteOptions(
                            projectManager.project.id,
                            $scope.integration.id,
                            'services',
                        );
                    })
                    .catch(function (error) {
                        $scope.error = error;
                    })
                    .then(function (data) {
                        $scope.selections = data.options.slice(0, 100).map(function (service) {
                            return { service };
                        });
                        $scope.loading = false;

                        $scope.services = data.options;
                        updateServicesLists();
                    })
                    .then(() => {
                        return tryGetAssociatedIncomingWebhook();
                    });
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }

        const existingProjectIntegrationIndex = utils.indexOf(
            projectManager.project.integrations,
            (prin) => prin.id === $scope.data.projectIntegration.id,
        );

        if (existingProjectIntegrationIndex > -1) {
            projectManager.project.integrations[existingProjectIntegrationIndex].displayName =
                $scope.data.projectIntegration.displayName;
        } else {
            projectManager.project.integrations.push($scope.data.projectIntegration);
        }

        const s = {
            services: $scope.services,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            service: selection.service.value,
                            workspace: $scope.data.workspaceId,
                            isManagingWorkspaceWebhooks: $scope.data.isManagingWorkspaceWebhooks,
                            isManagingServiceWebhooks: $scope.data.isManagingServiceWebhooks,
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    /**
     * Copies the url in the given element id to clipboard.
     * @param elementId - the element id in which the webhook url is.
     */
    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.onServiceSelected = function (service) {
        if (service && service.displayName) {
            const selection = { service, selected: true };
            $scope.selections.push(selection);
            updateServicesLists();
            $scope.onSelectionChange(selection);
            $scope.temp.service = null;
        }
    };

    /* Refresh the repositories selection for each combo-box.
     */
    function updateServicesLists() {
        if ($scope.services) {
            const boardInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.service.displayName;
                }),
            );
            $scope.otherServices = $scope.services.filter(function (r) {
                return !boardInSelection.has(r.displayName);
            });
        }
    }

    $scope.createWebhookEndpoint = function () {
        $scope.data.webhookUrlLoading = true;
        tonkeanService
            .createTwilioIncomingWebhook(projectManager.project.id, $scope.data.projectIntegration.id)
            .then((data) => {
                if (data.incomingWebhook) {
                    $scope.data.incomingWebhookUrl = data.incomingWebhook.url;
                }
            })
            .finally(() => ($scope.data.webhookUrlLoading = false));
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryGetAssociatedIncomingWebhook() {
        $scope.data.webhookUrlLoading = true;
        return tonkeanService
            .getIncomingWebhookByProjectIntegrationId(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                true,
                'INTEGRATION',
            )
            .then((data) => {
                if (data.incomingWebhook) {
                    $scope.data.incomingWebhookUrl = data.incomingWebhook.url;
                }
            })
            .finally(() => ($scope.data.webhookUrlLoading = false));
    }

    function tryLoadFromEdit() {
        if (
            state &&
            state.integrations &&
            !$scope.integration &&
            state.integrations[0] &&
            state.integrations[0].integration
        ) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.projectIntegration = state.integrations[0];
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                const projectData = state.integrations[0].projectData.projectDatas[0];
                $scope.data.workspaceId = projectData.workspace;
                $scope.data.isManagingServiceWebhooks = projectData.isManagingServiceWebhooks;
                $scope.data.isManagingWorkspaceWebhooks = projectData.isManagingWorkspaceWebhooks;
            }

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'services')
                .then(function (data) {
                    $scope.services = data.options;
                    $scope.selections = data.options
                        .map(function (service) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (projectData.service === service.value) {
                                            return {
                                                service,
                                                selected: true,
                                            };
                                        }
                                    }
                                    // in case no selected board found
                                    return {
                                        service,
                                        selected: false,
                                    };
                                }
                            }
                        })
                        .filter(angular.identity);
                    $scope.data.loginModal.$valid = true;
                    updateServicesLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });

            tryGetAssociatedIncomingWebhook();
        }
    }
}

export default angular.module('tonkean.shared').controller('TwilioChatModalCtrl', TwilioChatModalCtrl);
