import template from './tnkMultipleFieldToUpdateEditors.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkMultipleFieldToUpdateEditors', {
    bindings: {
        fieldSelectorGroupId: '@',
        fieldSelectorWorkflowVersionId: '@',
        expressionGroupId: '@',
        expressionWorkflowVersionId: '@',
        isPreselectedField: '@',
        preselectedFieldLabel: '@',
        preselectedFieldId: '@',
        configuredFields: '<',
        reloadFields: '<',
        specialFieldsForFeatures: '<',
        extraButtonText: '@',
        logicId: '@',
        extraButtonTrigger: '&',
        isExtraButtonRed: '<',
        isExtraButtonVisible: '<',
        validationObject: '<',
        previewEvaluationSource: '<',
        validationKeyPrefix: '<',
        onConfiguredFieldsChanged: '&',
        globalExpressionOnly: '<',
        allowDeleteOfLastField: '<',
    },
    template,
    controller: 'MultipleFieldToUpdateEditorsCtrl',
});
