import SpringCMAuthenticationComponent from './SpringCMAuthenticationComponent';
import springcmCircleImage from '../../../../apps/tracks/images/integrations/springcm-circle.png';
import springcmImage from '../../../../apps/tracks/images/springcm.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class SpringCMIntegration extends IntegrationDefinitionBase {
    override name = 'springcm';
    override displayName = 'SpringCM';
    override description = 'We will only collect documents and workflows and folders items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, false);
    override entities = [
        new IntegrationEntity('Document', 'Documents'),
        new IntegrationEntity('DocumentGenerationConfiguration', 'Document Generation Configurations'),
        new IntegrationEntity('Folder', 'Folders'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(springcmCircleImage, '130px'),
        new IntegrationIcon(springcmImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Document: true, DocumentGenerationConfiguration: true, Folder: true },
        isOnlyDataRetention: { Document: true, DocumentGenerationConfiguration: true, Folder: true },
    };

    override customizedAuthenticationComponent = SpringCMAuthenticationComponent;
}

export default SpringCMIntegration;
