import type { ICellEditorReactComp } from '@ag-grid-community/react';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import type { StylesConfig } from 'react-select';
import styled from 'styled-components';

import type ItemsGridCellEditorParams from '../../entities/ItemsGridCellEditorParams';
import useDropdownOptions from '../../hooks/useDropdownOptions';

import { UpdateFieldAsyncSearchDropdownSelectorWrapper } from '@tonkean/fields';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

const StyledSimpleSelect = styled(SimpleSelect)<{ width: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => `${width}px`};
`;

const StyledUpdateFieldAsyncSearchDropdownSelectorWrapper = styled(UpdateFieldAsyncSearchDropdownSelectorWrapper)<{
    width: number;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => `${width}px`};
`;

interface Props extends ItemsGridCellEditorParams {
    fieldDefinition: FieldDefinition;
    groupId: TonkeanId<TonkeanType.GROUP>;
}

const EditableCustomFieldCellEditor: React.ForwardRefRenderFunction<ICellEditorReactComp, Props> = (
    { value, fieldDefinition, context: { workflowVersion }, data, column, groupId },
    ref,
) => {
    const dropdownValues = useMemo(() => {
        if (Array.isArray(value)) {
            return value;
        } else {
            return value?.split(fieldDefinition?.outputMultiValueSeparator || ',');
        }
    }, [fieldDefinition?.outputMultiValueSeparator, value]);

    const [updatedValue, setUpdatedValue] = useState<string | string[] | undefined>(dropdownValues);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return updatedValue;
            },
        };
    });

    const selectStyles: StylesConfig<SimpleSelectSingleOption<string>> = {
        control: () => ({
            width: '100%',
        }),
    };

    const options = useDropdownOptions(
        workflowVersion?.workflowVersionType,
        data,
        groupId,
        fieldDefinition.inputMultiValueSeparator,
        fieldDefinition.possibleValues,
        fieldDefinition.dropdownOptionsFromFieldDefinitionId,
    );

    if (fieldDefinition.fieldType === FieldType.List && fieldDefinition.dropdownSource === 'SEARCH') {
        return (
            <StyledUpdateFieldAsyncSearchDropdownSelectorWrapper
                width={column.getActualWidth()}
                fieldDefinitionId={fieldDefinition.id}
                initiativeId={data.initiativeId}
                onChange={(changeValue) => {
                    setUpdatedValue(changeValue);
                }}
                value={!Array.isArray(updatedValue) ? updatedValue : undefined}
                isMultiValueList={fieldDefinition.isMultiValueField}
                multiValueList={Array.isArray(updatedValue) ? updatedValue : undefined}
                isCreatable={fieldDefinition.allowAddDropdownOptions}
                readOnly={false}
                styles={selectStyles}
                workflowVersionType={workflowVersion?.workflowVersionType}
                defaultMenuIsOpen
                autoFocus
            />
        );
    } else if (fieldDefinition.fieldType === FieldType.List) {
        return (
            <StyledSimpleSelect
                width={column.getActualWidth()}
                value={updatedValue}
                options={options}
                onChange={(changeValue) => {
                    setUpdatedValue(changeValue);
                }}
                isMulti={fieldDefinition.isMultiValueField}
                isCreatable={fieldDefinition.allowAddDropdownOptions}
                styles={selectStyles}
                isClearable
                defaultMenuIsOpen
                autoFocus
            />
        );
    }

    return <>{value}</>;
};

export default forwardRef(EditableCustomFieldCellEditor);
