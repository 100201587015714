import { useAngularService } from 'angulareact';
import { useCallback, useEffect } from 'react';

import useCreateWorkerForm from './useCreateWorkerForm';
import generateUniqueFormName from '../utils/generateUniqueFormName';

import type { Form, FormType } from '@tonkean/tonkean-entities';

interface Params {
    form: Form | undefined;
    workflowVersionId: string;
    groupId?: string;
    onFormSelected: (formId: string, formType: FormType) => void;
}

function useDuplicateWorkerFormCallback({ form, workflowVersionId, groupId, onFormSelected }: Params) {
    const formManager = useAngularService('formManager');
    const $rootScope = useAngularService('$rootScope');
    const forms = formManager.workflowVersionIdToFormIdToFormMap[workflowVersionId];
    const { createWorkerForm, createWorkerFormData: data, createWorkerFormLoading: loading } = useCreateWorkerForm();

    useEffect(() => {
        if (data) {
            onFormSelected(data.id, data.formType);
        }
    }, [data, onFormSelected]);

    const duplicateWorkerForm = useCallback(
        (newSlackCommand?: string) => {
            if (!forms || !form || !groupId || !form.descriptionExpression) {
                return;
            }

            const newFormName = generateUniqueFormName(Object.values(forms), [], form.displayName);

            if (newFormName.length > 100) {
                $rootScope.$emit('alert', {
                    msg: 'Duplicated form’s name is too long. Please rename it',
                    type: 'danger',
                });

                return;
            }

            createWorkerForm(
                groupId,
                form.formType,
                form.definition,
                form.disabled,
                newFormName,
                form.descriptionExpression,
                newSlackCommand,
                form.formQuestionType,
                form.collapseDescriptionByDefault,
                form.expandableDescriptionExpression,
                form.expandableDescriptionLabel,
            );
        },
        [$rootScope, createWorkerForm, form, forms, groupId],
    );

    return [duplicateWorkerForm, loading] as const;
}

export default useDuplicateWorkerFormCallback;
