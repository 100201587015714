import OutlookAuthenticationComponent from './OutlookAuthenticationComponent';
import OutlookCustomizeSetupComponent from './OutlookSetupComponent';
import outlookCircleIcon from '../../../../apps/tracks/images/integrations/outlook-circle.png';
import outlookIcon from '../../../../apps/tracks/images/outlook.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class OutlookIntegration extends IntegrationDefinitionBase {
    override name = 'outlook';
    override displayName = 'Outlook';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Attachment', 'Attachments'),
        new IntegrationEntity('AttachedEmail', 'Attached Emails'),
        new IntegrationEntity('Calendar', 'Calendars'),
        new IntegrationEntity('Event', 'Events'),
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('Message', 'Messages'),
    ];
    override customizedAuthenticationComponent = OutlookAuthenticationComponent;
    override customizedSetupComponent = OutlookCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(outlookCircleIcon, '130px'),
        new IntegrationIcon(outlookIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default OutlookIntegration;
