import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import type { StylesConfig } from 'react-select';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { SimpleSelect, tnkSelectHoverOrFocus } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ADD_ITEM_INTERFACE = '-1';

interface Option {
    value: string;
    label: string;
}

interface Props {
    name?: string;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    onItemInterfaceSelected?: (value?: TonkeanId<TonkeanType.ITEM_INTERFACE>) => void;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    selectedItemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    allowClear?: boolean;
    disabled?: boolean;
    excludeIds?: TonkeanId<TonkeanType.ITEM_INTERFACE>[];
}

const ItemInterfaceSelector: React.FC<Props> = ({
    name,
    workflowVersionId,
    selectedItemInterfaceId,
    onItemInterfaceSelected,
    groupId,
    allowClear = false,
    disabled = false,
    excludeIds,
}) => {
    const $state = useAngularService('$state');

    const {
        data,
        loading: itemInterfacesLoadingFromServer,
        error,
    } = useTonkeanService('getItemInterfacesByWorkflowVersionId', workflowVersionId);

    const options = useMemo(() => {
        if (!data?.entities || data?.entities?.length === 0) {
            return [{ value: ADD_ITEM_INTERFACE, label: '+ Add Item Interface' }];
        }

        return data?.entities
            .filter((summary) => !excludeIds?.includes(summary.id))
            .map((summary) => ({
                value: summary.id.toString(),
                label: summary.displayName,
            }));
    }, [data?.entities, excludeIds]);

    const selectStyles: StylesConfig<Option> = {
        option: (provided, state) => ({
            ...provided,
            [tnkSelectHoverOrFocus(state.isFocused)]: {
                color:
                    state.isSelected || options?.length === 1
                        ? Theme.current.palette.colorPicker.HEX_FFFFFF
                        : Theme.colors.gray_800,
                background:
                    state.isSelected || options?.length === 1 ? Theme.colors.primaryHighlight : Theme.colors.gray_200,
            },
            color:
                state.isSelected || options?.length === 1
                    ? Theme.current.palette.colorPicker.HEX_FFFFFF
                    : Theme.colors.gray_700,
            background:
                state.isSelected || options?.length === 1
                    ? Theme.colors.primaryHighlight
                    : Theme.colors.basicBackground,
        }),
    };
    const stateLinkParams = useMemo(
        () => ({
            t: 'interfaces',
            env: 'DRAFT',
            g: groupId,
            createNew: true,
        }),
        [groupId],
    );

    const onChange = useCallback(
        (val: string) => {
            if (val === ADD_ITEM_INTERFACE) {
                $state.go('product.workerEditor', stateLinkParams);
            } else {
                onItemInterfaceSelected?.(val as TonkeanId<TonkeanType.ITEM_INTERFACE>);
            }
        },
        [$state, onItemInterfaceSelected, stateLinkParams],
    );

    return (
        <SimpleSelect
            name={name}
            placeholder="Select an interface"
            isCreatable={false}
            options={options}
            isLoading={itemInterfacesLoadingFromServer}
            getOptionValue={(option) => option.value}
            value={selectedItemInterfaceId}
            onChange={onChange}
            styles={selectStyles}
            isClearable={allowClear}
            readOnly={itemInterfacesLoadingFromServer}
            isDisabled={disabled}
        />
    );
};

export default ItemInterfaceSelector;
