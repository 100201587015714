import template from './importTracks.template.html?angularjs';

function tnkImportTracks() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            title: '@',
            btnLabel: '@',
            showBack: '=',
            showInnerBack: '=',
            onlyImport: '=',
            onlySync: '=',
            syncData: '=',

            // An object representing which sections to show, if no object provided show all sections
            // available sections: title, sync, items, fields, uniqueIdentifierType, deleteItemsSelectionType
            sectionVisibility: '<',
            hideSectionHeaders: '<',
            noContainerPadding: '<',
            hideRemoveBtn: '<',
            hideBotInPreview: '<',
            hideTimeRangeSelection: '<',
            disableFields: '<',
            modSmall: '<',
            connectedIntegrationUniqueType: '=',
            func: '=',
            parentInitiative: '=',
            groupId: '=',
            workflowVersionId: '@',
            updateGroupName: '=',
            hideManualImportOption: '=',
            viewType: '=',
            onCompleteCallback: '&',
            onDeleteCallback: '&',
            onSavedCallback: '&',
            onIntegrationSelectedCallback: '&',
            dismiss: '&',
            createInEditMode: '=',
            formId: '=',
            displayFieldsList: '=',
            disableImportFromIntegrations: '=',
            reloadFields: '&',
            setShouldReloadFields: '<',
        },
        template,
        controller: 'ImportTracksCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkImportTracks', tnkImportTracks);
