<div class="common-close-btn" ng-click="$dismiss()"><tnk-icon src="/images/icons/x.svg"></tnk-icon></div>

<div
    class="
        common-size-xxs common-color-grey
        flex-vmiddle
        padding-top padding-left-lg padding-bottom padding-right-lg
        common-bg
        mod-light-blue
    "
>
    <span class="flex-grow">Preview of the first 100 rows:</span>
    <span>
        (For a full view
        <a ui-sref="product.board({g: data.groupId, filter:'all', st: null, env: data.workflowVersionType})">
            open the Business Report
        </a>
        )
    </span>
</div>

<div class="padding-normal-sm">
    <div class="display-table common-width-full">
        <tnk-tracks-editor
            tracks="data.initiatives"
            editor-id="manual-worker-input"
            hide-header="false"
            quick-creation-mode="false"
            allow-dnd-on-root="false"
            hide-add-new-force="true"
            hide-column-quick-create-force="true"
            loading="data.loadingInitiatives"
            custom-fields="data.previewFieldDefinitions"
            ignore-column-visibility="true"
            display-fields-list="data.previewFieldDefinitions"
            only-group="data.groupId"
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            view-items-mode="true"
        ></tnk-tracks-editor>
    </div>
</div>

<tnk-system-interface-modal />
