import React from 'react';

import GenericDatasourceInputSourceSelection from './dataSource/GenericDatasourceInputSourceSelection';
import type { InputSourceContentComponentProps } from '../InputSourceContentComponentProps';

import { IntegrationType } from '@tonkean/tonkean-entities';

const CHAT_INTEGRATION_TYPES = [IntegrationType.SLACK_APP, IntegrationType.MICROSOFTTEAMSAPP];

const ChatInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = (props) => {
    return <GenericDatasourceInputSourceSelection filterByIntegrationType={CHAT_INTEGRATION_TYPES} {...props} />;
};

export default ChatInputSourceSelectionContent;
