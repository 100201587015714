import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const InformationRow = styled.div<{ itemsCount: number }>`
    margin: 30px 0 10px;
    display: grid;
    grid-template-columns: repeat(${({ itemsCount }) => itemsCount - 1}, auto) 1fr;
    grid-gap: 50px;

    &:not(:first-child) {
        margin-top: 40px;
    }
`;

export const SingleWorkerRunInformationRowContext = React.createContext<() => () => void>({} as any);

const SingleWorkerRunInformationRow: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [itemsCount, setItemsCount] = useState(0);

    const addItem = useCallback(() => {
        setItemsCount((currentItemCount) => currentItemCount + 1);

        return () => {
            setItemsCount((currentItemCount) => currentItemCount - 1);
        };
    }, []);

    return (
        <SingleWorkerRunInformationRowContext.Provider value={addItem}>
            <InformationRow itemsCount={itemsCount}>{children}</InformationRow>
        </SingleWorkerRunInformationRowContext.Provider>
    );
};

export default SingleWorkerRunInformationRow;
