import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import type SinglePlaceholderReplacer from './SinglePlaceholderReplacer';
import type { SinglePlaceholderReplacerWithKey } from './SinglePlaceholderReplacer';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Input, LinkLikeButton } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const DeleteButtonWrapper = styled.div`
    text-align: right;
    margin-top: 5px;
`;

const StyledInput = styled(Input)`
    height: 30px;
    margin-bottom: 5px;
`;

const PlaceholderWrapper = styled.div`
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const EMPTY_PLACEHOLDER: SinglePlaceholderReplacer = {
    name: '',
    content: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
};

interface Props {
    groupId: string;
    workflowVersionId: string;
    initialValue: SinglePlaceholderReplacer[];

    onChange(placeholderReplacers: SinglePlaceholderReplacer[]): void;
}

const PlaceholderReplacer: React.FC<Props> = ({
    initialValue = [EMPTY_PLACEHOLDER],
    onChange,
    groupId,
    workflowVersionId,
}) => {
    const counterRef = useRef(0);

    const [placeholderReplacers, setPlaceholderReplacers] = useState<SinglePlaceholderReplacerWithKey[]>(() => {
        return initialValue.map((singlePlaceholderReplacer) => {
            counterRef.current += 1;
            return { ...singlePlaceholderReplacer, key: counterRef.current };
        });
    });

    const updatePlaceholders = (newPlaceholderReplacers: SinglePlaceholderReplacerWithKey[]) => {
        setPlaceholderReplacers(newPlaceholderReplacers);
        onChange(newPlaceholderReplacers);
    };

    const addNew = () => {
        counterRef.current += 1;
        updatePlaceholders([
            ...placeholderReplacers,
            {
                ...EMPTY_PLACEHOLDER,
                key: counterRef.current,
            },
        ]);
    };

    const nameChanged = (currentPlaceholderReplacer: SinglePlaceholderReplacerWithKey, newName: string) => {
        updatePlaceholders(
            placeholderReplacers.map((singlePlaceholderReplacer) => {
                if (singlePlaceholderReplacer !== currentPlaceholderReplacer) {
                    return singlePlaceholderReplacer;
                }

                return { ...singlePlaceholderReplacer, name: newName };
            }),
        );
    };

    const contentChanged = (
        currentPlaceholderReplacer: SinglePlaceholderReplacerWithKey,
        originalExpression: string,
        evaluatedExpression: string,
    ) => {
        updatePlaceholders(
            placeholderReplacers.map((singlePlaceholderReplacer) => {
                if (singlePlaceholderReplacer !== currentPlaceholderReplacer) {
                    return singlePlaceholderReplacer;
                }

                return {
                    ...singlePlaceholderReplacer,
                    content: {
                        evaluatedExpression,
                        originalExpression,
                        isStripHtmlDisabled: singlePlaceholderReplacer.content.isStripHtmlDisabled,
                    },
                };
            }),
        );
    };

    const remove = (currentPlaceholderReplacer: SinglePlaceholderReplacerWithKey) => {
        updatePlaceholders(
            placeholderReplacers.filter((singlePlaceholderReplacer) => {
                return singlePlaceholderReplacer !== currentPlaceholderReplacer;
            }),
        );
    };

    return (
        <div>
            <div className="common-title-xxxs margin-bottom-xs">Replace Placeholders:</div>

            {placeholderReplacers.map((singlePlaceholderReplacer) => (
                <PlaceholderWrapper key={singlePlaceholderReplacer.key}>
                    <StyledInput
                        value={singlePlaceholderReplacer.name}
                        onChange={(e) => nameChanged(singlePlaceholderReplacer, e.target.value)}
                    />
                    <TonkeanExpression
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        savedOriginalExpression={singlePlaceholderReplacer.content.originalExpression}
                        savedEvaluatedExpression={singlePlaceholderReplacer.content.evaluatedExpression}
                        onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) =>
                            contentChanged(singlePlaceholderReplacer, originalExpression, evaluatedExpression)
                        }
                    />
                    <DeleteButtonWrapper>
                        <LinkLikeButton onClick={() => remove(singlePlaceholderReplacer)}>Delete</LinkLikeButton>
                    </DeleteButtonWrapper>
                </PlaceholderWrapper>
            ))}

            <Button onClick={addNew} size={ButtonSize.SMALL} outlined>
                + Add
            </Button>
        </div>
    );
};

export default PlaceholderReplacer;
