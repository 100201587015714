import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TriggersGalleryCtrl(
    $scope,
    authenticationService,
) {
    // TODO: revert this before deploy!
    const webSiteUrl = 'https://tonkean.com';
    // let webSiteUrl = 'http://localhost:9191';

    /**
     * Posts a message to the iFrame's window parent, using the cross-origin postMessage interface.
     */
    $scope.postMessageToContainer = function (triggerId, builtInListId) {
        // Prepare the data string - add our own prefix, so the web app can recognize us.
        // If this prefix is not sent to the web app, it ignores the message.
        let dataString;

        if (builtInListId) {
            // We have a built in list id, we want to send the user to the lists page in the web site, pointing to this list.
            dataString = `tnk-app-view-list ${builtInListId}`;
        } else {
            if (authenticationService.isUserAuthenticated()) {
                // User IS authenticated, tell the web site to redirect to the trigger create page.
                dataString = `tnk-app-open-trigger ${triggerId}`;
            } else {
                // User is not authenticated.
                // Tell the web app to open the authentication modal.
                dataString = 'tnk-app-open-modal';
            }
        }

        // If we are in an iFrame, window.parent is the one to post a message to.
        // If it's null we let it throw an exception so we can know about it (shouldn't cause the app to crash, just fail the message sending).
        window.parent.postMessage(dataString, webSiteUrl);
    };

    /**
     * Posts a message to the iFrame's window parent of our current height so it can resize the iframe.
     */
    $scope.postHeightToContainer = function () {
        const height = document.documentElement.scrollHeight;
        window.parent.postMessage(`tnk-update-gallery-height ${height}px`, webSiteUrl);
    };
}

export default angular.module('tonkean.app').controller('TriggersGalleryCtrl', lateConstructController(TriggersGalleryCtrl));
