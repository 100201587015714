import { css } from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const itemInterfaceErrorCss = css`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.MEDIUM_14};
    padding: 24px;
    text-align: center;

    svg {
        path:first-child {
            fill: ${Theme.colors.gray_500};
        }

        height: 40px;
        width: 40px;
    }
`;

export default itemInterfaceErrorCss;
