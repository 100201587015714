import { angularToReact } from '@tonkean/angular-components';
import type { BasicQueryDefinition, ProjectIntegration } from '@tonkean/tonkean-entities';

export interface CustomFilterFieldMetadata {
    name: string;
    label: string;
    type: string;
}

export interface CustomFiltersProps {
    control: any;
    itemsSource: 'EXTERNAL' | 'COLUMN' | 'CUSTOM';
    groupId?: string;
    workflowVersionId?: string;
    hideTimeRangeSelection: boolean;
    hideCloseButton: boolean;
    editMode: boolean;
    onFiltersChangeDefinition: (definition: BasicQueryDefinition) => void;
    specialFieldsForFeatures?: string[];
    projectIntegration?: ProjectIntegration;
    selectedEntityType?: string;
    existingDefinition?: BasicQueryDefinition;
    viewOnly?: boolean;
    displayIntegrationIndication?: boolean;
    forceExpand?: boolean;
    searchAllFields?: boolean;
    boldTitle?: boolean;
    smallMod?: boolean;
    evaluatePreview?: boolean;
    filtersResultMap?: Record<string, boolean>;
    additionalFields?: CustomFilterFieldMetadata[];
    useExpressionsForValue?: boolean;
    verticalMod?: boolean;
    excludedTabSelectorSpecialFields?: string[];
    includeTabSelectorSpecialFieldsForFeatures?: string[];
    showFieldInspect?: boolean;
    showFieldInspectModal?: boolean;
    fromSync?: boolean;
    initializeQueryWithDefaultCreateDateCondition?: boolean;
    notAllowedConditionsSet?: Record<string, boolean>;
    keepEmptyValuedFilters?: boolean;
    filtersChangeOnlyAfterInit?: boolean;
    errorObject?: CustomFiltersErrorObject;
    invokeChangeOnConditionSelection?: boolean;
    allowContains?: boolean;
    includeViewerFields?: boolean;
}

type CustomFiltersErrorObject = {
    filters?: { value: string }[];
    queries?: CustomFiltersErrorObject[];
};

const CustomFilters = angularToReact<CustomFiltersProps>('tnk-custom-filters', {
    onFiltersChangeDefinition: ['definition'],
});

export default CustomFilters;
