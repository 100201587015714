import { type FullProject, type Person, type TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

function AuthenticationService($location, $localStorage, $rootScope, $injector, $log, $window, environment) {
    const _this = this;
    this.currentUser = $rootScope.currentUser = $localStorage.currentUser;
    this.projectToken = { token: '' };

    this.getCurrentUser = () => {
        if (!this.currentUser) {
            throw 'No current user found';
        }
        return this.currentUser;
    };

    this.getCurrentUserSafe = () => {
        return this.currentUser;
    };

    // These are properties we do not want to cache for the user.
    const uncachedProperties = ['enterprise'];

    /**
     * the URL to redirect to when authentication is complete.
     * for continuation from login to originally requested page.
     * @param url - this url is saved as a redirect url if supplied. If not, the current location's url is used.
     */
    this.setAuthenticationCompleteRedirectUrl = function (url: string | undefined) {
        $localStorage.authenticationCompleteRedirectUrl = url || $location.url();
    };

    /**
     * Complete authentication of given user by saving it, broadcasting 'currentUserChanged' event and
     * navigate either to board state (by default) or explicitly set URL (to return to originally requested action).
     *
     * @param user the user to set
     * @param accessToken the access token of the authentication to set
     * @param noNavigation true - don't navigate from the current state.
     */
    this.finishAuthentication = function (
        user: Person,
        accessToken: string,
        isNewUser?: boolean,
        noNavigation?: boolean,
    ) {
        user.accessToken = accessToken;

        if (isNewUser) {
            console.log('user created');
            $rootScope.$broadcast('newUserCreated', user.id);
        }

        this.updateCurrentUser(user);

        if (this.isUserAuthenticated()) {
            this.handleAuthenticationComplete(noNavigation);
        } else {
            $log.warn('Ignoring the current user since it is not authenticated.');
            this.logout();
        }
    };

    /**
     * Broadcast 'currentUserChanged' event and navigate either to board state (by default) or explicitly set URL (to return to originally requested action).
     *
     * @param noNavigation true - don't navigate from the current state.
     */
    this.handleAuthenticationComplete = function (noNavigation?: boolean, redirectAfterAuth?: boolean) {
        $rootScope.$broadcast('currentUserChanged');
        if (!noNavigation) {
            const authenticationCompleteRedirectUrl = !!redirectAfterAuth
                ? redirectAfterAuth
                : $localStorage.authenticationCompleteRedirectUrl;
            delete $localStorage.authenticationCompleteRedirectUrl;
            if (authenticationCompleteRedirectUrl) {
                $location.url(authenticationCompleteRedirectUrl);
            } else {
                const $state = $injector.get('$state');
                $state.go('product', {}, { notify: true, location: 'replace' });
            }
        }
    };

    /**
     * Is the currently set user is authenticated: currect user exists and has access token.
     */
    this.isUserAuthenticated = function () {
        return _this.currentUser && _this.currentUser.accessToken;
    };

    /**
     * Validate the current partial user.
     */
    this.validateAccount = function () {
        if (!_this.currentUser || !_this.currentUser.isPartial) {
            $log.error('Validating an account only applies on a partial user.');
            return;
        }

        const email = $window.prompt('Enter email to test to');

        if (email && !_this.loadingValidate) {
            $localStorage.LastLoginWithEmailAddress = email;
            _this.setAuthenticationCompleteRedirectUrl();
            $log.info('Validating email: %s', email);
            _this.loadingValidate = true;
            const tonkeanService = $injector.get('tonkeanService');
            tonkeanService
                .sendLoginEmail(email)
                .then(function () {
                    $injector.get('$state').go('loginSent');
                })
                .finally(function () {
                    _this.loadingValidate = false;
                });
        }
    };

    /**
     * Update the current user.
     */
    this.updateCurrentUser = function (user: Person) {
        if (_this.currentUser && !_this.currentUser.isGuest) {
            const extendedUser = angular.extend(_this.currentUser, user);

            // Go over the properties we don't cache.
            for (const uncachedProperty of uncachedProperties) {
                // If the new user doesn't have this property but the extended user does, delete this property from the extended one.
                if (!user.hasOwnProperty(uncachedProperty) && extendedUser.hasOwnProperty(uncachedProperty)) {
                    delete extendedUser[uncachedProperty];
                }
            }

            // Update the user as the new extended user.
            user = extendedUser;
        }

        _this.currentUser = $localStorage.currentUser = $rootScope.currentUser = user;
        $rootScope.$broadcast('currentUserChanged');
    };

    /**
     * Set current user with a fake "guest" user..
     * We don't save this user to the local storage and we don't extend the current user.
     */
    this.setGuestUser = function (token: string) {
        // create fake guest user
        const guestUser = {
            name: 'Guest',
            viewOnlyToken: token,
            isGuest: true,
        };

        _this.currentUser = $rootScope.currentUser = guestUser;

        $rootScope.$broadcast('currentUserChanged');
    };

    /**
     * Logout the current user.
     */
    this.logout = function (alreadyLoggedOut?: boolean) {
        if (!alreadyLoggedOut) {
            const tonkeanService = $injector.get('tonkeanService');
            tonkeanService.logout();
        }
        $rootScope.pm.cleanSelectedProject();
        delete $rootScope.currentUser;
        delete $localStorage.currentUser;
        delete $localStorage.lastProjectId;
        delete _this.currentUser;
        delete _this.projectToken;
        $rootScope.$broadcast('currentUserChanged');
        $injector.get('$state').go('login');
    };

    /**
     * Returns a boolean value indicating whether the user has a communication integration in the give project
     * @param user
     * @param projectId
     * @returns {boolean}
     */
    this.isUserInCommunicationIntegration = function (user: Person, projectId: TonkeanId<TonkeanType.PROJECT>) {
        if (!user || !user.projectContexts || !projectId || !user.projectContexts[projectId]) {
            return false;
        }

        // slackUserId is used for all communication integration.
        return !!user.projectContexts[projectId].slackUserId;
    };

    this.COMMUNICATION_INTEGRATION_TYPES = {
        EMAIL: 'EMAIL',
        COMMUNICATION: 'COMMUNICATION',
    };

    /**
     * Get the preferred communication method of the user.
     * @param user
     * @param project
     * @returns {*} one of the COMMUNICATION_INTEGRATION_TYPES or null if the input is faulty.
     */
    this.getUserCommunicationPreference = function (user: Person, project: FullProject) {
        // Check for faulty input.
        if (!user || !project) {
            return null;
        }

        if (user.preferEmail || !project.communicationIntegrations || !project.communicationIntegrations.length) {
            return _this.COMMUNICATION_INTEGRATION_TYPES.EMAIL;
        }

        if (this.isUserInCommunicationIntegration(user, project.id)) {
            return _this.COMMUNICATION_INTEGRATION_TYPES.COMMUNICATION;
        } else {
            // Defaults to email
            return _this.COMMUNICATION_INTEGRATION_TYPES.EMAIL;
        }
    };

    /**
     *  @type Class
     */
    this.updateProjectToken = function (projectId: TonkeanId<TonkeanType.PROJECT>, projectToken: string) {
        this.projectId = projectId;
        this.projectToken = projectToken;

        $rootScope.$broadcast('currentProjectTokenChanged');
    };

    this.getProjectToken = function () {
        return this.projectToken?.token;
    };
}
angular.module('tonkean.app').service('authenticationService', AuthenticationService);
