import React from 'react';
import styled, { css } from 'styled-components';

import EmptyFormDescription from './EmptyFormDescription';
import FormDescription from './FormDescription';
import FormsListLoading from './FormsListLoading';

import type { Form } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const FormSectionHeader = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: bold;
    margin: 16px 10px 6px 10px;
    color: ${Theme.colors.gray_800};
`;

const FormSectionSubHeader = styled.div`
    font-size: ${FontSize.XSMALL_10};
    font-style: italic;
    color: ${Theme.colors.gray_600};
    margin: 0px 10px 16px 10px;
`;

const FormRow = styled.button<{ $isFirst: boolean; $isSelected: boolean }>`
    display: flex;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid ${Theme.colors.gray_300};

    ${({ $isFirst }) =>
        $isFirst &&
        css`
            border-top: 1px solid ${Theme.colors.gray_300};
        `};

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background-color: ${Theme.colors.gray_300};
        `};

    &:hover {
        background-color: ${Theme.colors.gray_300};
    }

    ${styledFocus}
`;

const EmpyFormRow = styled.div`
    display: flex;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    border-top: 1px solid ${Theme.colors.gray_300};
`;

interface Props {
    forms: Form[];
    searchTerm: string;
    header: string;
    subHeader: string;
    editForm: (form: Form) => void;
    isLoading: boolean;
    selectedFormId: string;
}

const FormsList: React.FC<Props> = ({ forms, searchTerm, header, subHeader, editForm, isLoading, selectedFormId }) => {
    if (isLoading) {
        return (
            <>
                <FormSectionHeader>{header}</FormSectionHeader>

                <FormSectionSubHeader>{subHeader}</FormSectionSubHeader>

                <FormsListLoading />
            </>
        );
    }

    if (forms.length === 0) {
        return (
            <>
                <FormSectionHeader>{header}</FormSectionHeader>

                <FormSectionSubHeader>{subHeader}</FormSectionSubHeader>

                <EmpyFormRow>
                    <EmptyFormDescription />
                </EmpyFormRow>
            </>
        );
    }

    return (
        <>
            <FormSectionHeader>{header}</FormSectionHeader>

            <FormSectionSubHeader>{subHeader}</FormSectionSubHeader>

            {forms.map((form, index) => (
                <FormRow
                    key={form.id}
                    onClick={() => editForm(form)}
                    $isFirst={index === 0}
                    $isSelected={selectedFormId === form.id}
                >
                    <FormDescription form={form} searchTerm={searchTerm} />
                </FormRow>
            ))}
        </>
    );
};

export default FormsList;
