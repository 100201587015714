import React, { useMemo } from 'react';

import SolutionSitePageIncludeInterfaceWidgetDisplay from './SolutionSitePageIncludeInterfaceWidgetDisplay';
import ItemInterfaceWrapper from '../../../components/ItemInterfaceWrapper';
import useItemInterfaceContextDataBuilder from '../../../hooks/useItemInterfaceContextDataBuilder';
import useSolutionSiteContext from '../../../hooks/useSolutionSiteContext';
import { ItemWidget, type SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type { SolutionSitePageIncludeInterfaceWidgetConfiguration } from '../SolutionSitePageIncludeInterfaceWidgetConfiguration';

import { ItemInterfaceContext } from '@tonkean/infrastructure';
import { useTDLEmbedInterfaceByWidgetId, useTDLWorkflowVersionById } from '@tonkean/tonkean-data-layer';

const SolutionSitePageIncludeInterfaceWidget: React.FC<
    SolutionSitePageWidgetProps<SolutionSitePageIncludeInterfaceWidgetConfiguration>
> = ({ permission, widget }) => {
    const { workflowVersionType } = useSolutionSiteContext();

    const configurationSet = useMemo(() => {
        return (
            !!widget.configuration.matchingContractId &&
            !!widget.configuration.matchingGroupId &&
            !!widget.configuration.matchingContractFieldId
        );
    }, [
        widget.configuration.matchingContractFieldId,
        widget.configuration.matchingContractId,
        widget.configuration.matchingGroupId,
    ]);

    const emptyText = useMemo(() => {
        if (configurationSet) return undefined;

        return {
            emptyTitle: 'Set Configuration',
            emptyMessage: 'Please select a contract, group and interface to show',
        };
    }, [configurationSet]);

    const {
        data: embedInterface,
        isLoading: embedInterfaceIsLoading,
        isError: embedInterfaceIsError,
    } = useTDLEmbedInterfaceByWidgetId(
        widget.projectId,
        widget.configuration.matchingGroupId,
        widget.configuration.matchingContractFieldId,
        workflowVersionType,
    );

    const { data: workflowVersion } = useTDLWorkflowVersionById(embedInterface?.itemInterface.workflowVersionId);

    const itemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative: undefined,
        initiativeInitialLoading: false,
        itemInterface: embedInterface?.itemInterface,
        section: undefined,
        workflowVersion,
        widgetsToDisplay: embedInterface?.widgets ?? [],
        allInterfaceWidgets: embedInterface?.widgets ?? [],
        applyFastReloadInterval: undefined,
    });

    return (
        <ItemWidget
            permission={permission}
            containerOverflowOverride="visible"
            disableMinHeight
            disableMaxHeight
            noBorderBody
            noPaddingBody
        >
            <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
                <ItemInterfaceWrapper>
                    <SolutionSitePageIncludeInterfaceWidgetDisplay
                        embedInterfaceWidgets={embedInterface?.widgets}
                        workflowVersion={workflowVersion}
                        embedInterfaceIsLoading={embedInterfaceIsLoading}
                        embedInterfaceIsError={embedInterfaceIsError}
                        configurationSet={configurationSet}
                        emptyTitle={emptyText?.emptyTitle}
                        emptyMessage={emptyText?.emptyMessage}
                    />
                </ItemInterfaceWrapper>
            </ItemInterfaceContext.Provider>
        </ItemWidget>
    );
};

export default SolutionSitePageIncludeInterfaceWidget;
