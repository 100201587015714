import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SmartSearchSuggestActionCategoryView from './SmartSearchSuggestActionCategoryView';

import { AITypewriter } from '@tonkean/ai-builder';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { IconSvg } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { SmartSearchIcon } from '@tonkean/svg';
import type { SmartConversationMessage, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { SmartSearchPerformActionCategoryResponse } from '@tonkean/tonkean-entities';

const ResponseContainer = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'gutter text'
        'gutter buttons';
    column-gap: 10px;
`;

const ResponseIcon = styled(IconSvg)`
    grid-area: gutter;
    margin-top: 4px;
`;

const ResponseTextContainer = styled.div`
    grid-area: text;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    message: SmartConversationMessage<SmartSearchPerformActionCategoryResponse>;
    userQuestion: string;
    onAnimationEnd: () => void;
    setLoading: (value: boolean) => void;
}

const SmartSearchPerformActionCategoryView: React.FC<Props> = ({
    projectId,
    message,
    userQuestion,
    onAnimationEnd,
    setLoading,
}) => {
    const [isInitialTextEnded, setIsInitialTextEnded] = useState(false);

    const { data: suggestedActionResponse, loading } = useTonkeanService(
        'smartSearchSuggestAction',
        projectId,
        message.conversationId,
        userQuestion,
    );

    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading]);

    return (
        <ResponseContainer>
            <ResponseIcon as={SmartSearchIcon} />
            <ResponseTextContainer>
                <SmartConversationResponseText>
                    <AITypewriter
                        skipAnimation={!message.showAnimation}
                        text={`Searching actions to help with - "${message.response.subject}"...`}
                        onTypingDone={() => setIsInitialTextEnded(true)}
                    />
                </SmartConversationResponseText>

                {isInitialTextEnded && (
                    <>
                        {suggestedActionResponse?.foundAction && (
                            <SmartSearchSuggestActionCategoryView
                                skipAnimation={!message.showAnimation}
                                projectId={projectId}
                                suggestedAction={suggestedActionResponse}
                                onAnimationEnd={onAnimationEnd}
                                conversationId={message.conversationId}
                            />
                        )}
                        {suggestedActionResponse && !suggestedActionResponse.foundAction && (
                            <SmartConversationResponseText>
                                <AITypewriter
                                    skipAnimation={!message.showAnimation}
                                    text="Could not find possible action"
                                    onTypingDone={onAnimationEnd}
                                />
                            </SmartConversationResponseText>
                        )}
                    </>
                )}
            </ResponseTextContainer>
        </ResponseContainer>
    );
};

export default SmartSearchPerformActionCategoryView;
