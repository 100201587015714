import ItemDetailsDoLine from './ItemDetailsDoLine';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemDetailsDoLine',
        reactToAngular(ItemDetailsDoLine, [
            'workflowVersionId',
            'groupTargetId',
            'customTriggerTargetId',
            'overrideDetailsTitlePlaceholder',
            'customTriggerWorkOnActionItem',
        ]),
    );
