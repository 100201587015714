import { useCallback, useMemo } from 'react';

import type { WidgetInterface } from '../widgetsFactory';
import ItemInterfaceWidgetHandler from '../widgetsFactory/ItemInterfaceWidgetHandler';

import { useGetFieldValueFromInitiative } from '@tonkean/infrastructure';
import type {
    FieldDefinition,
    FieldDefinitionSummary,
    Initiative,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

const allSpecialFields = getSpecialFieldsForFeatures(true);

const useGetItemInterfaceFieldDefinitionSummary = (
    initiative: Initiative | undefined,
    widgets: ItemInterfaceWidget[],
    itemInterfaceFieldDefinitions: FieldDefinition[],
    widgetsInnerItems: Record<TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>, Initiative[]>,
) => {
    const getFieldValueFromInitiative = useGetFieldValueFromInitiative();

    const enrichFieldWithDisplayMetadata = useCallback(
        (field: FieldDefinitionSummary) => {
            const fieldDefinition = itemInterfaceFieldDefinitions.find(
                (fieldDefinition) => fieldDefinition.id === field.fieldDefinitionId,
            );
            const specialField = allSpecialFields.find((specialField) => specialField.id === field.fieldDefinitionId);
            field.definedDisplayFormat = fieldDefinition?.displayFormat;
            field.evaluatedDisplayType = fieldDefinition?.fieldType.toString();
            field.name = field.name ?? (fieldDefinition?.fieldLabel || fieldDefinition?.name) ?? specialField?.name;
        },
        [itemInterfaceFieldDefinitions],
    );

    const addFieldsFromRootInitiativeField = useCallback(
        (
            handler: WidgetInterface | undefined,
            interfaceFieldsSummary: FieldDefinitionSummary[],
            widget: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>,
        ) => {
            const fieldsInWidget = handler?.getFieldsSummaryFromConfiguration(widget);
            fieldsInWidget?.forEach((field) => {
                enrichFieldWithDisplayMetadata(field);
                const fieldValue = getFieldValueFromInitiative(initiative, field);
                interfaceFieldsSummary.push({
                    fieldDefinitionId: field.fieldDefinitionId,
                    name: field.name,
                    value: field.value ?? fieldValue ?? '',
                    label: field.label,
                    index: field.index,
                });
            });
        },
        [initiative, getFieldValueFromInitiative, enrichFieldWithDisplayMetadata],
    );

    const addFieldsFromInnerInitiativeField = useCallback(
        (
            handler: WidgetInterface | undefined,
            interfaceFieldsSummary: FieldDefinitionSummary[],
            widget: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>,
        ) => {
            const innerItemsFieldsSummaryForWidget = handler?.getFieldsSummaryFromConfiguration(widget);

            const firstFieldInWidget = innerItemsFieldsSummaryForWidget?.slice().sort((a, b) => a.index - b.index)[0];

            const currentWidgetInnerItems = widgetsInnerItems[widget.id];

            currentWidgetInnerItems?.forEach((initiative) => {
                // The first field in the widget value is used as part fo the field title, to enable the user to recognise the field and it's initiative
                const firstFieldInWidgetValue = getFieldValueFromInitiative(initiative, firstFieldInWidget) ?? 'N/A';

                innerItemsFieldsSummaryForWidget?.forEach((field) => {
                    enrichFieldWithDisplayMetadata(field);

                    interfaceFieldsSummary.push({
                        fieldDefinitionId: `${field.fieldDefinitionId}-${initiative?.id}`,
                        name: field.name,
                        value: getFieldValueFromInitiative(initiative, field),
                        label: `${firstFieldInWidgetValue} - ${field.name}`,
                        index: field.index,
                    });
                });
            });
        },
        [getFieldValueFromInitiative, enrichFieldWithDisplayMetadata, widgetsInnerItems],
    );

    return useMemo(() => {
        const interfaceFieldsSummary: FieldDefinitionSummary[] = [];
        widgets?.forEach((widget) => {
            const handler = ItemInterfaceWidgetHandler(widget);
            if (handler) {
                if (!handler.containsInnerItems) {
                    addFieldsFromRootInitiativeField(handler, interfaceFieldsSummary, widget);
                } else if (widgetsInnerItems) {
                    addFieldsFromInnerInitiativeField(handler, interfaceFieldsSummary, widget);
                }
            }
        });
        return interfaceFieldsSummary;
    }, [widgets, widgetsInnerItems, addFieldsFromInnerInitiativeField, addFieldsFromRootInitiativeField]);
};

export default useGetItemInterfaceFieldDefinitionSummary;
