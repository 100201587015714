import React, { useMemo } from 'react';
import styled from 'styled-components';

import getErrorMessagesFromWorkerRunLogic from '../../../entities/getErrorMessagesFromWorkerRunLogic';
import workerRunReasonConfig from '../../../entities/workerRunReasonConfig';
import getWorkerRunFullDescription from '../../../utils/getWorkerRunFullDescription';
import SingleWorkerRunInformationItem from '../SingleWorkerRunInformationItem';
import SingleWorkerRunInformationRow from '../SingleWorkerRunInformationRow';
import SingleWorkerRunWorkflowVersionInformationItem from '../SingleWorkerRunWorkflowVersionInformationItem';

import { ErrorMessage, TextEllipsis } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';
import { initiativeTitle } from '@tonkean/utils';

const Error = styled(ErrorMessage)`
    margin: 20px 0;
`;

const SubType = styled.strong`
    display: block;
    font-weight: 500;
`;

const Underline = styled.span`
    text-decoration: underline;
`;

const ProcessArrow = styled.span`
    margin-left: 3px;
    margin-right: 3px;
`;

const LinkValue = styled(StateLink)`
    text-decoration: underline;
    color: ${Theme.colors.gray_800};
`;

interface Props {
    /** If undefined, it means that it's loading the initial load */
    workerRun?: WorkerRun;
    inspectWorkerRun(): void;
}

const SingleWorkerRunFlowRunItemDetails: React.FC<Props> = ({ workerRun, inspectWorkerRun }) => {
    const errors = useMemo(() => {
        return workerRun?.workerRunLogics?.flatMap(getErrorMessagesFromWorkerRunLogic).sort() || [];
    }, [workerRun]);
    const warnings = useMemo(() => {
        return (workerRun?.workerRunLogics?.map((logic) => logic.warningMessage).filter(Boolean) as string[]) || [];
    }, [workerRun?.workerRunLogics]);

    const title =
        workerRun && initiativeTitle(workerRun.initiative?.title, workerRun.testRun, workerRun.initiative?.id);
    const workerRunFullDescription = getWorkerRunFullDescription(workerRun);
    const workerRunSubType = workerRun && workerRunReasonConfig[workerRun.workerRunReason].subType;

    return (
        <>
            {errors.map((error) => (
                <Error key={error}>{error}</Error>
            ))}
            {warnings.map((warning, i) => (
                <Error key={warning + i} warn>
                    {warning}
                </Error>
            ))}

            <SingleWorkerRunInformationRow>
                <SingleWorkerRunInformationItem
                    title="What triggered this run?"
                    value={
                        workerRun && (
                            <TextEllipsis numberOfLines={2} tooltip>
                                {workerRunSubType !== workerRunFullDescription && (
                                    <SubType>
                                        {workerRun.backgroundProcessName && (
                                            <>
                                                <Underline>{workerRun.backgroundProcessName}</Underline>
                                                <ProcessArrow>{' > '}</ProcessArrow>
                                            </>
                                        )}
                                        {workerRunSubType}
                                    </SubType>
                                )}
                                {workerRunFullDescription}
                                {workerRun.workerRunReason === WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE &&
                                    ` - ${workerRun.resumingText}`}
                            </TextEllipsis>
                        )
                    }
                    placeholderCount={2}
                />

                <SingleWorkerRunInformationItem
                    title="Involved Item"
                    value={
                        workerRun && (
                            <LinkValue
                                data-automation="flow-run-item-details-item"
                                state="product.workerEditor.history.workerRunStage.inspect"
                                onClick={inspectWorkerRun}
                                dontChangeState
                            >
                                <TextEllipsis numberOfLines={2} tooltip>
                                    {title}
                                </TextEllipsis>
                            </LinkValue>
                        )
                    }
                    placeholderCount={2}
                />

                <SingleWorkerRunWorkflowVersionInformationItem workerRun={workerRun} />
            </SingleWorkerRunInformationRow>
        </>
    );
};

export default SingleWorkerRunFlowRunItemDetails;
