<div id="manage-columns-modal" class="manage-columns-modal common-close-btn-container container" ng-hide="hideSelf">
    <!-- Exit button -->
    <span data-automation="close-fields-modal-button" class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>
    <div>
        <div>
            <div class="common-title-sm flex-vmiddle">Manage Fields (Columns)</div>
            <div class="common-color-dark-grey common-size-xxxxs margin-bottom margin-top-xs">
                Hidden fields will not be visible in the Live Report as columns, but can still be used as fields by the
                Module.
            </div>

            <tnk-manage-fields
                target-type="COLUMN"
                on-close="$close()"
                create-fields-as-visible="true"
                control-object="data.columnsCtrlObject"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                save-on-change="data.saveOnChange"
                solution-business-report-id="data.solutionBusinessReportId"
                environment="data.environment"
            ></tnk-manage-fields>
        </div>

        <div ng-if="data.loading" class="text-center margin-top-xxlg margin-bottom-xxlg">
            <i class="loading-medium"></i>
        </div>
        <div><span></span></div>
        <div ng-if="!data.onlyColumnTypeFields" class="margin-top-lg">
            <div class="common-title-sm flex-vmiddle">Manage Global Fields (Key Metrics)</div>
            <div class="common-color-dark-grey common-size-xxxxs margin-bottom margin-top-xs">
                Hidden global fields will not be visible in the Business Report as key metrics, but can still be used as
                global fields by the Module.
            </div>

            <tnk-manage-fields
                target-type="GLOBAL"
                on-close="$close()"
                control-object="data.keyMetricsCtrlObject"
                create-fields-as-visible="true"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                save-on-change="data.saveOnChange"
            ></tnk-manage-fields>
        </div>
    </div>
    <!-- Footer -->
    <div class="flex padding-top-lg">
        <!-- Buttons -->
        <div class="flex-vmiddle">
            <!-- Cancel -->
            <button
                type="button"
                data-automation="manage-fields-cancel-button"
                class="btn btn-default margin-right-xs"
                ng-click="$dismiss()"
            >
                Close
            </button>
        </div>
    </div>

    <!-- Errors -->
    <div ng-if="data.errorSavingFields" class="padding-top common-size-xxs common-color-danger">
        {{ data.errorSavingFields }}
    </div>
</div>
