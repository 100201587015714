import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MessagePreferencesCtrl(
    $scope,
    $q,
    modalUtils,
    modal,
    authenticationService,
    tonkeanService,
) {
    const ctrl = this;
    $scope.modal = modal;

    $scope.data = {
        messageId: ctrl.messageId,
        workingOnCurrentUser: ctrl.workingOnCurrentUser,
        excludeOptionKey: ctrl.excludeOptionKey,

        preferenceOptionConfigurations: {
            NO_ALL: {
                id: 'NO_ALL',
                label: "I don't want to get any emails from Tonkean.",
            },
            NO_GATHER_UPDATE: {
                id: 'NO_GATHER_UPDATE',
                label: 'A.I. Module Follow ups',
            },
            NO_DIGEST_READY_MESSAGE: {
                id: 'NO_DIGEST_READY_MESSAGE',
                label: 'Morning alert',
                description:
                    'Notifications of new Tracks assigned to you, reminders about updating Tracks and Automation Trigger notifications',
            },
            NO_SCHEDULED_REPORT: {
                id: 'NO_SCHEDULED_REPORT',
                label: 'Scheduled reports',
            },
        },
        preferenceOptionConfigurationsArray: [],

        currentMessagePreferences: null,
        preferenceOptionToIsCheckedMap: {},

        loadingPreferences: false,
        errorLoadingPreferences: false,
    };

    /**
     * Initializes the component.
     */
    $scope.init = function () {
        // Getting the preference options.
        initializePreferenceOptions();

        if ($scope.data.workingOnCurrentUser || authenticationService.currentUser) {
            // Copying preferences from current user, as we are requested to work on current user.
            $scope.data.currentMessagePreferences =
                angular.copy(authenticationService.currentUser.messagePreferences) || [];

            // Update the active options.
            updatePreferenceOptionsMap();
        } else {
            // Loading the message preferences from service, as we do not have a current user to take them from.
            $scope.data.loadingPreferences = true;
            $scope.data.errorLoadingPreferences = false;

            tonkeanService
                .getMessagePreferences($scope.data.messageId)
                .then((data) => {
                    $scope.data.currentMessagePreferences = data.messagePreferences;

                    // Update the active options.
                    updatePreferenceOptionsMap();
                })
                .catch(() => {
                    $scope.data.errorLoadingPreferences = true;
                })
                .finally(function () {
                    $scope.data.loadingPreferences = false;
                });
        }
    };

    /**
     * Occurs once preference option is selected.
     */
    $scope.onOptionToggled = function (preferenceOption) {
        // The user of should confirm here is critical.
        // Since we are in a function that acts after check box component toggle, the value we have in hand
        // is still not updated, therefore we toggle it ourselves after the confirmation promise.
        // But, if we really do fire the confirmation promise, a digest loop is fired before we continue,
        // and we have the updated value after the confirmation promise and so we don't have to toggle.
        let shouldConfirm = false;
        let confirmationPromise = $q.resolve();

        if (
            preferenceOption.id === 'NO_GATHER_UPDATE' &&
            $scope.data.preferenceOptionToIsCheckedMap[preferenceOption.id]
        ) {
            shouldConfirm = true;
            confirmationPromise = modalUtils.openTurnGatherUpdatesOffModal().result.catch(() => {
                $scope.data.preferenceOptionToIsCheckedMap[preferenceOption.id] = true;
                return $q.reject();
            });
        }

        return confirmationPromise.then(() => {
            // Toggle is checked first.
            if (!shouldConfirm) {
                $scope.data.preferenceOptionToIsCheckedMap[preferenceOption.id] =
                    !$scope.data.preferenceOptionToIsCheckedMap[preferenceOption.id];
            }

            // Getting the selected preferences.
            const selectedPreferences = getSelectedPreferencesArray();

            // Letting the user of the directive know about the change.
            if (ctrl.onSelectedPreferencesChanged) {
                ctrl.onSelectedPreferencesChanged({
                    selectedPreferences: selectedPreferences || [],
                });
            }

            return $q.resolve();
        });
    };

    /**
     * Gets the previously selected preferences.
     */
    function getSelectedPreferencesArray() {
        const selectedPreferences = [];

        for (const preferenceOptionId in $scope.data.preferenceOptionToIsCheckedMap) {
            if ($scope.data.preferenceOptionToIsCheckedMap.hasOwnProperty(preferenceOptionId)) {
                const isChecked = $scope.data.preferenceOptionToIsCheckedMap[preferenceOptionId];

                if (preferenceOptionId === 'NO_ALL') {
                    if (isChecked) {
                        selectedPreferences.push('NO_ALL');
                    }
                } else if (preferenceOptionId !== 'NO_ALL' && !isChecked) {
                    selectedPreferences.push(preferenceOptionId);
                }
            }
        }

        return selectedPreferences;
    }

    /**
     * Updates the isActive according to whether option is selected.
     */
    function updatePreferenceOptionsMap() {
        for (let i = 0; i < $scope.data.currentMessagePreferences.length; i++) {
            const preferenceOptionKey = $scope.data.currentMessagePreferences[i];

            // If an option is in the preferences, it should not be checked.
            $scope.data.preferenceOptionToIsCheckedMap[preferenceOptionKey] = preferenceOptionKey === 'NO_ALL';
        }
    }

    /**
     * Initializes the displayed preference options.
     */
    function initializePreferenceOptions() {
        // Initializes the options array.
        for (const key in $scope.data.preferenceOptionConfigurations) {
            if (
                $scope.data.preferenceOptionConfigurations.hasOwnProperty(key) &&
                (!$scope.data.excludeOptionKey || key !== $scope.data.excludeOptionKey)
            ) {
                // Add to array.
                $scope.data.preferenceOptionConfigurationsArray.push($scope.data.preferenceOptionConfigurations[key]);

                // Add to map.
                // By default, nothing in the preferences, meaning it is checked - you didn't choose to unsubscribe from something.
                $scope.data.preferenceOptionToIsCheckedMap[key] = key !== 'NO_ALL';
            }
        }
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('MessagePreferencesCtrl', lateConstructController(MessagePreferencesCtrl));
