<!-- tnk-track-list-item is a wrapper to the TrackListItem React component. -->
<div
    class="list-group-item tracks-item-row tracks-item-row-marker-{{::editorId}}"
    id="item-row-{{::item.id}}{{::editorId}}"
    ng-click="itemClicked($event, item)"
    ng-dblclick="itemDoubleClicked($event)"
    ng-class="{ 'mod-quick-create': quickCreationMode }"
>
    <!-- React directive for the list item -->
    <tnk-track-list-item
        display-fields-list="displayFieldsList"
        ignore-column-visibility="ignoreColumnVisibility"
        hide-context-menu="hideContextMenu"
        form-view-in-initiative="formViewInInitiative"
        disable-go-to-track="disableGoToTrack"
        hide-history="hideHistory"
        ng-class="{ 'track-being-moved': trackHelper.isTheInitiativeBeingMoved(item.id) }"
        ng-if="!hideRow['item-row-' + item.id + editorId] || !lazyLoadTracks || data.draggingItem === item.id"
        id="::item.id"
        data="::trackListItemData"
        ui-actions="::trackListItemUiActions"
        created-in-form-id="createdInFormId"
        collect-items-mode="collectItemsMode"
        view-items-mode="viewItemsMode"
        field-validation-map="fieldValidationMap[item.id]"
        on-track-removed="(onTrackRemoved)"
        dragging="data.dragging"
        disable-owner-invite="disableOwnerInvite"
        hide-title="data.hideTitle"
        title-form-label="data.titleDisplayName"
        worker-forms="data.workerForms"
        filter-visible-field-definitions="(filterVisibleFieldDefinitions)"
        show-actions-column="data.showActionsColumn"
        show-done="showDone || data.showDone"
        enable-adding-items="data.enableAddingItems"
        enable-inner-items-toggle="data.enableInnerItemsToggle"
        on-manual-field-update="(onManualFieldUpdate)"
        numerate-items="data.numerateItems"
        loading-related-initiatives="data.loadingRelatedInitiatives"
    ></tnk-track-list-item>
</div>

<ul
    ng-if="
        !data.bulkSelecting &&
        itemMap[item.trackId].expended &&
        !itemMap[item.trackId].isArchived &&
        !formViewInInitiative
    "
    id="tracks-ul-{{::item.id}}{{::editorId}}-INNER"
    class="tracks-drag-drop-list common-ul-no-style tracks-ul-side-border"
    tnk-dnd-list
    tnk-dnd-list-id="{{::item.trackId}}"
    tnk-dnd-drop="onDrop(dndData, dndIndex, dndListId)"
    tnk-dnd-get-marker="getDndMarker()"
>
    <li
        ng-repeat="item in items = (itemMap[item.trackId].relatedInitiatives | orderBy:data.orderBy.filter) track by item.id"
        class="track-sub-item flex-vmiddle"
        ng-if="!itemMap[item.trackId].deleted && !itemMap[item.id].isArchived"
        id="tracks-li-{{::item.id}}{{::editorId}}"
        tnk-dnd-draggable="{{::item.id}}"
        tnk-dnd-dragstart="onDragStart(dndData)"
        tnk-dnd-dragend="onDragEnd()"
        tnk-dnd-moved="onMoved(data.items, dndData)"
        tnk-dnd-drop-into="onDrop(dndData, 0, dndListId, true)"
        tnk-dnd-disabled="{{
            data.bulkSelecting ||
                !allowDndOnRoot ||
                data.orderBy.field !== 'index' ||
                !item.created ||
                viewOnlyMode ||
                trackHelper.isTheInitiativeBeingMoved(item.id)
        }}"
        tnk-dnd-get-placeholder="getDndPlaceholder(dndData)"
    >
        <div ng-include="'./trackEditorItem.template.html'" class="flex-grow"></div>
    </li>
</ul>

<ul
    class="common-ul-no-style create-placeholder-inner"
    ng-class="{
        'mod-no-padding':
            !itemMap[item.trackId].relatedInitiatives ||
            !itemMap[item.trackId].relatedInitiatives.length ||
            underItemId === item.id
    }"
>
    <li id="create-placeholder-{{::item.id}}{{::editorId}}"></li>
</ul>

<ul
    class="tracks-drag-drop-list common-ul-no-style tracks-ul-side-border"
    ng-if="!data.bulkSelecting && itemMap[item.trackId].expended && !itemMap[item.trackId].isArchived"
>
    <li ng-if="item.hasMoreInnerItemsToLoad" class="track-sub-item flex-vmiddle">
        <div class="flex-grow">
            <div class="list-group-item tracks-item-row">
                <div
                    class="
                        tracks-item-box tracks-item-box-border-container
                        flex-vmiddle
                        tracks-items-flex-basis tracks-load-more-inner-items-row
                    "
                >
                    <span class="margin-right-xs common-color-grey common-size-xxxs">
                        Displaying 1-{{ item.relatedInitiatives.length }} of {{ item.innerItemsCount }}
                    </span>
                    <button
                        class="load-more-button"
                        ng-if="!item.loadingRelated"
                        ng-click="loadNextInnerItemsPage(item)"
                    >
                        Load More
                    </button>
                    <i ng-if="item.loadingRelated" style="display: inline-block" class="loading-small"></i>
                </div>
            </div>
        </div>
    </li>
</ul>
