import { useAngularService } from 'angulareact';
import { useCallback, useMemo } from 'react';

import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { type FieldInstance, FieldType, type Initiative } from '@tonkean/tonkean-entities';

const tryParseNumber = (value: unknown): number | undefined => {
    if (value === null || value === undefined) {
        return undefined;
    } else {
        const parsed = Number.parseFloat(value?.toString());
        return Number.isNaN(parsed) ? undefined : parsed;
    }
};

const useConstructInitiativeToInitiativeRowData = (fieldDefinitions: FieldDefinition[]) => {
    const fieldDisplay = useAngularService('fieldDisplay');

    const fieldDefinitionsMap = useMemo(() => {
        return Object.fromEntries(fieldDefinitions.map((fieldDefinition) => [fieldDefinition.id, fieldDefinition]));
    }, [fieldDefinitions]);

    const extractValueFromField = useCallback(
        (field: FieldInstance) => {
            const fieldDefinition = fieldDefinitionsMap[field.fieldDefinition.id] || field.fieldDefinition;

            switch (fieldDefinition.fieldType) {
                case FieldType.Number:
                    return field.numberValue || tryParseNumber(field.value);
                case FieldType.Date:
                    return fieldDisplay.evaluateFieldDisplayValue(
                        FieldType.Date,
                        fieldDefinition.displayFormat,
                        field.value,
                        field.dateValue,
                        field.formattedValue,
                    );
                default: {
                    return field.value;
                }
            }
        },
        [fieldDefinitionsMap, fieldDisplay],
    );

    return useCallback(
        (initiative: Initiative) => {
            return {
                initiativeId: initiative.id,
                title: initiative.title,
                state: {
                    type: initiative.status,
                    color: initiative.stateColor,
                    label: initiative.stateText,
                },
                dueDate: initiative.dueDate ? new Date(initiative.dueDate) : undefined,
                owner: initiative.owner,
                created: new Date(initiative.created),

                ...Object.fromEntries(
                    (initiative.fields || [])?.map((field) => {
                        return [field.fieldDefinition.id, extractValueFromField(field)];
                    }),
                ),
            };
        },
        [extractValueFromField],
    );
};

export default useConstructInitiativeToInitiativeRowData;
