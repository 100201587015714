import { PersonPermissionRoleType, ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function IntegrationSelectionCtrl(
    $scope,
    $rootScope,
    $state,
    $timeout,
    integrations,
    modalUtils,
    communicationIntegrationsService,
    utils,
    projectManager,
    tonkeanService,
) {
    const ctrl = this;
    $scope.pm = $rootScope.pm;
    $scope.cis = communicationIntegrationsService;

    $scope.data = {
        // Bindings.
        currentIntegration: ctrl.currentIntegration,
        integrationState: ctrl.integrationState,
        integrationsSettings: ctrl.integrationsSettings,
        notConnectedNativeSources: ctrl.notConnectedNativeSources,
        showImportCsv: ctrl.showImportCsv,
        disableImportIntegrations: ctrl.disableImportIntegrations,
        openModalForConfiguration: ctrl.openModalForConfiguration,
        configuredInGroupId: ctrl.configuredInGroupId,
        configuredInWorkflowVersionId: ctrl.configuredInWorkflowVersionId,
        displayOnlyNotConnectedNativeSourcesInSearch: ctrl.displayOnlyNotConnectedNativeSourcesInSearch,
        openNewInModal: ctrl.openNewInModal,
        hideAddButton: ctrl.hideAddButton,

        // Inner properties.
        nativeIntegrationsSources: integrations.getAllIntegrationTypes(),
        integrationRequestInFocus: false,
        searchIntegrationNameText: null,
        projectIntegrationToStateMap: {},
        authorizedProjectIntegrationsSummaries: [],

        // Array of entities with no entities at all (SendGrid for example)
        integrationsWithNoEntities: [],
    };

    $scope.selectProvider = function (projectIntegrationSummary) {
        const projectIntegration = $scope.pm.project.integrations.find(
            (integration) => integration.id === projectIntegrationSummary.id,
        );

        if (projectIntegration.valid) {
            ctrl.selectProvider({ projectIntegration });
        } else {
            $state.go('product.projectIntegrationPage', {
                enterpriseComponentId: projectIntegration?.id,
                fromState: 'product.workerEditor',
                fromStateParams: $state.params,
                fromName: projectManager.groupsMap[$state.params.g]?.name || 'Module Editor',
                page: ProjectIntegrationPageMenuItemType.CONNECTIONS,
            });
            // Let the UI update to the new state before annoncing that the borken broken integration was selected.
            // This will make sure closing modals and such are done after the ui has changed
            $timeout(() => ctrl.onSelectedBrokenIntegration());
        }
    };
    $scope.onIntegrationSaved = function (overrideState) {
        // Recalculate the projectIntegrationToStateMap since it might need an update.
        initializeStateObjectForAllProjectIntegrations();
        ctrl.onIntegrationSaved({ overrideState });
    };
    $scope.onImportCsvClicked = function () {
        ctrl.onImportCsvClicked();
    };

    /**
     * Opens the add new integration modal.
     */
    $scope.openAddNewIntegrationModal = function () {
        modalUtils.openAddIntegration(null, null, null, 'all');
    };

    /**
     * We don't deep watch the current integration, so the function only fires once the reference changes.
     * This is done for performance considerations (since deep watching is heavy).
     * This lets users get updated if currentIntegration is reset to null or changes to another integration.
     */
    $scope.$watch('data.currentIntegration', function () {
        ctrl.onCurrentIntegrationChanged({ currentIntegration: $scope.data.currentIntegration });
    });

    /**
     * We don't deep watch the current integration, so the function only fires once the reference changes.
     * This is done for performance considerations (since deep watching is heavy).
     * This keeps the ctrl.integrationState reference from outside update once the $scope.data.integrationState internally changes (by tnk-integration-group).
     */
    $scope.$watch('data.integrationState', function () {
        $scope.data.integrationState = ctrl.integrationState;
    });

    ctrl.$onInit = async function () {
        // If requested to hide integrations with no entities
        if (ctrl.hideIntegrationsWithNoEntities) {
            const fieldOptions = integrations.getFieldOptions();
            const allEntitiesSupportedIntegrations = integrations.getAllEntitiesSupportedIntegrations();
            $scope.data.integrationsWithNoEntities = Object.keys(fieldOptions).filter(
                (integrationType) =>
                    fieldOptions[integrationType]?.length === 0 && !allEntitiesSupportedIntegrations[integrationType],
            );
        }

        const summariesResponse = await tonkeanService.getProjectIntegrationsSummaries(
            projectManager.project.id,
            false,
        );
        $scope.data.authorizedProjectIntegrationsSummaries = summariesResponse.entities.filter(
            (projectIntegrationSummary) =>
                projectIntegrationSummary.accessType !== PersonPermissionRoleType.NOT_AUTHORIZED,
        );

        initializeStateObjectForAllProjectIntegrations();
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        // Keep the current integration reference updated.
        if (changes.currentIntegration) {
            $scope.data.currentIntegration = ctrl.currentIntegration;
        }
        // Also keep the integrations settings reference updated.
        if (changes.integrationsSettings) {
            $scope.data.integrationsSettings = ctrl.integrationsSettings;
        }
        // And also notConnectedNativeSources.
        if (changes.notConnectedNativeSources) {
            $scope.data.notConnectedNativeSources = ctrl.notConnectedNativeSources;
        }
        // If resetOtherSearchBox was set to true, reset the "other" search box.
        if (changes.resetOtherSearchBox && changes.resetOtherSearchBox.currentValue) {
            clearOtherSearchBox();
        }
    };

    /**
     * Supplied to tnkIntegrationGroup to be called upon cancellation.
     */
    $scope.onIntegrationCanceled = function () {
        initializeStateObjectForAllProjectIntegrations();
        $scope.data.currentIntegration = null;
        clearOtherSearchBox();
    };

    /**
     * Reloading the integration and initializing the data needed for the refresh to apply.
     */
    $scope.reloadIntegrations = function () {
        $scope.data.reloadingIntegrations = true;

        $scope.pm.getProjectData(true).then(() => {
            initializeStateObjectForAllProjectIntegrations();
            $scope.data.reloadingIntegrations = false;
        });
    };

    $scope.filterByIntegrationType = function (projectIntegration) {
        if (ctrl.onlyIntegrationsOfTypes) {
            const targetType = projectIntegration.integration.integrationType;
            return utils.anyMatch(ctrl.onlyIntegrationsOfTypes, (type) => targetType === type);
        } else if (ctrl.hideIntegrationsWithNoEntities) {
            return !$scope.data.integrationsWithNoEntities.includes(
                projectIntegration.integration.integrationType.toLowerCase(),
            );
        } else {
            return true;
        }
    };

    $scope.projectIntegrationTooltip = function (projectIntegration) {
        if (!projectIntegration) {
            return;
        }

        if (!projectIntegration.valid) {
            return `${projectIntegration.displayName} data source is broken. Click the button to fix it in the Data Sources page.`;
        } else if (utils.hasEllipsis(`#initiative-project-integration-${projectIntegration.id}`)) {
            return projectIntegration.displayName;
        }
    };

    /**
     * Initializes the state object for tnkIntegrationsGroup directive to use.
     */
    function initializeStateObjectForAllProjectIntegrations() {
        createProjectIntegrationToStateMap($scope.pm.project.integrations);
    }

    /**
     * Create the project integration to state map from given project integrations.
     */
    function createProjectIntegrationToStateMap(projectIntegrations) {
        if (projectIntegrations) {
            for (const projectIntegration of projectIntegrations) {
                if (!$scope.data.projectIntegrationToStateMap[projectIntegration.id]) {
                    $scope.data.projectIntegrationToStateMap[projectIntegration.id] = {
                        state: {},
                    };

                    $scope.data.projectIntegrationToStateMap[projectIntegration.id].state[
                        projectIntegration.integration.integrationType.toLowerCase()
                    ] = {
                        integrations: [projectIntegration],
                    };
                } else {
                    $scope.data.projectIntegrationToStateMap[projectIntegration.id].state[
                        projectIntegration.integration.integrationType.toLowerCase()
                    ].integrations[0].displayName = projectIntegration.displayName;
                }
            }
        }
    }

    /**
     * Clears the "other" search box.
     */
    function clearOtherSearchBox() {
        $scope.data.searchIntegrationNameText = '';
    }
}
export default angular.module('tonkean.app').controller('IntegrationSelectionCtrl', lateConstructController(IntegrationSelectionCtrl));
