<a
    class="pointer common-link-no-style block relative track-week-item"
    popover-append-to-body="true"
    ng-click="as.currentUser && as.currentUser.isUser ? modalUtils.openViewInitiative(item.trackId) : null"
    uib-popover-template="'../../../../../../packages/angular-components/src/components/tnkViewFunctionPopover/viewFunctionPopoverTemplate.template.html'"
    popover-trigger="mouseenter"
    popover-placement="auto {{ trackTodayPopoverPlacement || 'bottom' }}"
    ng-style="{ 'border-left-color': itemMap[item.trackId].stateColor }"
>
    <div class="track-week-item-bg" ng-style="{ background: itemMap[item.trackId].stateColor }"></div>
    <span draggable="true" class="flex-vmiddle" ng-class="{ 'mod-eta': data.isEtaOnDay }">
        <div class="flex-grow">
            <!-- TITLE-->
            <div
                class="flex-grow common-bold common-ellipsis"
                ng-class="
                    itemMap[item.trackId].status === 'DONE' ? 'common-color-grey common-italic' : 'common-color-black'
                "
            >
                {{ itemMap[item.trackId].title }}
            </div>

            <!-- Upper line-->
            <div class="flex-vmiddle common-size-xxxxxs">
                <!-- DUE DATE icon -->
                <span
                    ng-if="data.isDueDateOnDay"
                    class="flex-vmiddle common-color-grey3 svg-icon-xxxs svg-icon-vmiddle margin-right-xs"
                >
                    <tnk-icon src="/images/icons/flag.svg"></tnk-icon>
                </span>
                <!-- ETA icon -->
                <span
                    ng-if="data.isEtaOnDay && !data.isDueDateOnDay"
                    class="flex-vmiddle common-color-grey3 svg-icon-xxxs svg-icon-vmiddle margin-right-xs"
                >
                    <tnk-icon src="/images/icons/clock.svg"></tnk-icon>
                </span>

                <ng-pluralize
                    class="common-ellipsis flex-grow"
                    count="data.thingsOnDay.length"
                    when="{
                        '1': '{{ data.thingsOnDay[0] }}',
                        '2': '{{ data.thingsOnDay[0] }} + {{ data.thingsOnDay[1] }}',
                        'other': '{{ data.thingsOnDay[0] }} + {{ data.thingsOnDay.length - 1 }} more'}"
                ></ng-pluralize>
            </div>
        </div>
    </span>
</a>
