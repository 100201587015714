import React from 'react';

import {
    ReactComponent as ActionsIcon,
    ReactComponent as HandleResponseIcon,
} from '../../images/formula-builder-popover-tab-presets.svg';

import { ColumnsIcon } from '@tonkean/svg';
import { BarsIcon } from '@tonkean/svg';
import { PlugIcon } from '@tonkean/svg';
import { UnionIcon } from '@tonkean/svg';
import { FlowIcon } from '@tonkean/svg';
import { CalculatorIcon } from '@tonkean/svg';
import { EnterpriseComponentVariableIcon } from '@tonkean/svg';
import { UserGroupIcon as PeopleDirectoryIcon } from '@tonkean/svg';
import { TrelloIcon } from '@tonkean/svg';

interface Props {
    iconClass: string;
}

const AdditionalTabIcon: React.FC<Props> = ({ iconClass }) => {
    switch (iconClass?.toLowerCase()) {
        case 'mod-columns':
            return <ColumnsIcon />;
        case 'mod-bars':
            return <BarsIcon />;
        case 'mod-plug':
            return <PlugIcon />;
        case 'mod-flow':
            return <FlowIcon />;
        case 'mod-calculator':
            return <CalculatorIcon />;
        case 'mod-actions':
            return <ActionsIcon />;
        case 'mod-trello':
            return <TrelloIcon />;
        case 'mod-handle-response':
            return <HandleResponseIcon />;
        case 'mod-people-directory':
            return <PeopleDirectoryIcon />;
        case 'mod-union':
            return <UnionIcon />;
        case 'mod-enterprise-component-variable':
            return <EnterpriseComponentVariableIcon />;
        default:
            return <></>;
    }
};

export default AdditionalTabIcon;
