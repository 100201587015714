import type { Environment } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessibilitySummary } from '@tonkean/tonkean-entities';

interface BaseSolutionReport {
    id: string;
    displayName: string;
    actions: string[];
    groupNames: string[];
    environments: Environment[];
}

export interface BusinessReportProperties extends BaseSolutionReport {
    type: SolutionReportTypes.BUSINESS;
    permissions: BusinessReportAccessibilitySummary;
}

interface SolutionReportProperties extends BaseSolutionReport {
    type: SolutionReportTypes.SOLUTION;
}

export type SolutionReport = BusinessReportProperties | SolutionReportProperties;

export enum SolutionReportTypes {
    BUSINESS = 'business',
    SOLUTION = 'solution',
}
