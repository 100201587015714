import React from 'react';
import styled from 'styled-components';

import type { EntityRetentionSummary } from './useDataRetentionConfiguration';
import { ReactComponent as TooltipIcon } from '../../../../../images/icons/history/search-tooltip.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-new.svg';

import { TnkEntitySelector } from '@tonkean/angular-to-react-components';
import { Input, LoadingCircle, Toggle, Tooltip } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const TableColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const EntityLifespanInput = styled(Input)`
    width: 60px;
`;

const DataLifeSpanColumn = styled(TableColumn)`
    gap: 8px;
`;

const TableText = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.XSMALL_10};
`;

interface Props {
    projectIntegration: ProjectIntegration;
    entityDataRetentionSummary: EntityRetentionSummary;
    configuredEntitiesIds: string[];
    ignoreEntities: string[];
    showActiveRecordsColumn: boolean;

    onChange(entity: EntityRetentionSummary): void;
    deleteEntity(): void;
}

const EntityRetentionConfigurationRow: React.FC<Props> = ({
    entityDataRetentionSummary,
    projectIntegration,
    configuredEntitiesIds,
    showActiveRecordsColumn,
    ignoreEntities,
    onChange,
    deleteEntity,
}) => {
    // For entity selector component - should enable to choose specific entity
    const shouldDisableChoice = (id: string) => {
        return configuredEntitiesIds.includes(id);
    };

    return (
        <>
            {/* Entity Display Name */}
            <TableColumn>
                <TnkEntitySelector
                    projectIntegration={projectIntegration}
                    selectedEntity={entityDataRetentionSummary.id}
                    shouldDisableChoice={(selectedEntity) => {
                        return shouldDisableChoice(selectedEntity.entity);
                    }}
                    mode="sync"
                    onEntitySelected={(entity: { entity: string }) => {
                        onChange({ ...entityDataRetentionSummary, id: entity.entity });
                    }}
                    ignoreEntities={ignoreEntities}
                    forceFetchSupportedEntitiesFromServer
                    shouldNotChooseDefaultEntity
                />
            </TableColumn>

            {/* Is Collect Enabled */}
            <TableColumn>
                <Toggle
                    size={InputSize.MEDIUM}
                    checked={entityDataRetentionSummary.collectStatus.isEnabled || false}
                    labelAfter={
                        <TableText>{entityDataRetentionSummary.collectStatus.isEnabled ? 'On' : 'Off'}</TableText>
                    }
                    onChange={(event) => {
                        onChange({
                            ...entityDataRetentionSummary,
                            collectStatus: {
                                ...entityDataRetentionSummary.collectStatus,
                                isEnabled: event.target.checked,
                            },
                        });
                    }}
                    disabled={
                        entityDataRetentionSummary.collectStatus.isLoading ||
                        !entityDataRetentionSummary.collectStatus.isEditable
                    }
                />
                {entityDataRetentionSummary.collectStatus.tooltip && (
                    <Tooltip content={entityDataRetentionSummary.collectStatus.tooltip}>
                        <TooltipIcon />
                    </Tooltip>
                )}

                {entityDataRetentionSummary.collectStatus.isLoading && <LoadingCircle centered />}
            </TableColumn>

            {/* Retention Column */}
            <DataLifeSpanColumn>
                {/* Is Indefinite */}
                <Toggle
                    checked={entityDataRetentionSummary.retention.isIndefinite.value || false}
                    size={InputSize.MEDIUM}
                    labelAfter={<TableText>Indefinite</TableText>}
                    disabled={!entityDataRetentionSummary.retention.isIndefinite.isEditable}
                    onChange={(event) => {
                        if (event.target.checked) {
                            onChange({
                                ...entityDataRetentionSummary,
                                retention: {
                                    ...entityDataRetentionSummary.retention,
                                    isIndefinite: { ...entityDataRetentionSummary.retention.isIndefinite, value: true },
                                    expirationDays: {
                                        ...entityDataRetentionSummary.retention.expirationDays,
                                        value: null,
                                    },
                                },
                            });
                        } else {
                            onChange({
                                ...entityDataRetentionSummary,
                                retention: {
                                    ...entityDataRetentionSummary.retention,
                                    isIndefinite: { ...entityDataRetentionSummary.retention.isIndefinite, value: null },
                                    expirationDays: {
                                        ...entityDataRetentionSummary.retention.expirationDays,
                                        value: 365,
                                    },
                                },
                            });
                        }
                    }}
                />

                {/* Expiration Days*/}
                {!entityDataRetentionSummary.retention.isIndefinite.value && (
                    <>
                        <EntityLifespanInput
                            type="number"
                            min={1}
                            max={1095}
                            value={entityDataRetentionSummary.retention.expirationDays.value || 0}
                            disabled={!entityDataRetentionSummary.retention.expirationDays.isEditable}
                            onChange={(event) => {
                                onChange({
                                    ...entityDataRetentionSummary,
                                    retention: {
                                        ...entityDataRetentionSummary.retention,
                                        expirationDays: {
                                            ...entityDataRetentionSummary.retention.expirationDays,
                                            value: Number(event.target.value || '365'),
                                        },
                                    },
                                });
                            }}
                        />
                        <TableText>Days</TableText>
                    </>
                )}

                {entityDataRetentionSummary.retention.tooltip && (
                    <Tooltip content={entityDataRetentionSummary.retention.tooltip}>
                        <TooltipIcon />
                    </Tooltip>
                )}
            </DataLifeSpanColumn>

            {/* Active Records */}
            {showActiveRecordsColumn && (
                <TableColumn>
                    {entityDataRetentionSummary.activeRecords}
                    {entityDataRetentionSummary.activeRecords === undefined && <LoadingCircle centered />}
                </TableColumn>
            )}

            <TableColumn>
                {entityDataRetentionSummary.isDeletable && (
                    <IconButton onClick={deleteEntity} flat>
                        <TrashIcon />
                    </IconButton>
                )}
            </TableColumn>
        </>
    );
};

export default EntityRetentionConfigurationRow;
