import template from './tnkOcrConversionLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkOcrConversionLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        importActionConfig: '@',
    },
    template,
    controller: 'OcrConversionLogicConfigurationCtrl',
});
