import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function SubmitFormButtonCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        allErrorsNotRestrictingErrors: ctrl.allErrorsNotRestrictingErrors,
        label: ctrl.label,
        primaryColor: ctrl.primaryColor,
        secondaryColor: ctrl.secondaryColor,
        buttonsColor: ctrl.buttonsColor,
        formFinished: ctrl.formFinished,
        disableSubmit: ctrl.disabled,

        savingFormAnswer: false,
        savingIgnoring: false,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.formFinished) {
            $scope.data.formFinished = ctrl.formFinished;
        }

        if (changesObj.disabled) {
            $scope.data.disableSubmit = ctrl.disabled;
        }

        if (changesObj.allErrorsNotRestrictingErrors) {
            $scope.data.allErrorsNotRestrictingErrors = ctrl.allErrorsNotRestrictingErrors;
        }

        if (changesObj.label) {
            $scope.data.label = ctrl.label;
        }
    };

    $scope.submitForm = function (ignoreNonRestrictingErrors) {
        $scope.data.savingFormAnswer = true;
        $scope.data.savingIgnoring = ignoreNonRestrictingErrors;

        // Call parent callback
        ctrl.onSubmit({ ignoreNonRestrictingErrors })
            .catch((error) => {
                if (!error?.expectedError) {
                    return Promise.reject(error);
                }
            })
            .finally(() => ($scope.data.savingFormAnswer = false));
    };
}

angular.module('tonkean.app').controller('SubmitFormButtonCtrl', lateConstructController(SubmitFormButtonCtrl));
