import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ColumnAvgFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.COLUMN_AVG;
    override readonly sign = 'ColumnAvg';
    override readonly displayName = 'Column Average';
    override readonly description = 'Aggregates a column and calculates its avg.';
    override readonly dataType = FieldType.Number;

    override readonly aggregationOnly = true;

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input Column',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
