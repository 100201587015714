import { atom } from 'recoil';

import type OriginatedWorkerRunParams from '../entities/OriginatedWorkerRunParams';

const originatedWorkerRunState = atom<OriginatedWorkerRunParams | undefined>({
    key: 'originatedWorkerRunState',
    default: undefined,
});

export default originatedWorkerRunState;
