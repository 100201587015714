import React, { useCallback } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import TonkeanIconPicker from './TonkeanIconPicker';

import type { CustomColor } from '@tonkean/infrastructure';
import {
    PopoverHexColorPickerWithList,
    useActualColorToDisplay,
    useFilterOutHiddenColors,
} from '@tonkean/infrastructure';
import type { Icon, IconsByCategory } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const IconPickerWithColorWrapper = styled.div`
    display: flex;
    flex-direction: inherit;
    align-items: center;
    gap: 4px;
`;

const StyledHexColorPickerWithList = styled(PopoverHexColorPickerWithList)`
    padding: 14px 8px;
    min-height: 20px;
`;

export interface IconPickerWithColorProps extends DataAutomationSupportProps {
    icon: Icon | undefined;
    onChangeIcon: (icon: Icon) => void;
    SelectedIconComponent: ReactElement;
    showLabel?: boolean;
    customColors: CustomColor[];
    removeWrappingMargin?: boolean;
    disabled?: boolean;
    predefinedIcons: IconsByCategory[];
}

const IconPickerWithColor: React.FC<IconPickerWithColorProps> = ({
    icon,
    onChangeIcon,
    SelectedIconComponent,
    showLabel = true,
    customColors,
    removeWrappingMargin,
    disabled,
    predefinedIcons,
}) => {
    const actualWidgetColor = useActualColorToDisplay(icon?.iconColor, icon?.customColorId, Theme.colors.gray_700);

    const filteredHiddenColors = useFilterOutHiddenColors(customColors);

    const onSelectColor = useCallback(
        (color: string, customColorId?: string) => {
            if (icon) {
                onChangeIcon({ ...icon, iconColor: color, customColorId });
            }
        },
        [icon, onChangeIcon],
    );

    const onSelectIcon = useCallback(
        (newIcon: Icon) => {
            onChangeIcon({
                ...newIcon,
                iconColor: actualWidgetColor,
                customColorId: icon?.customColorId,
            });
        },
        [onChangeIcon, actualWidgetColor, icon?.customColorId],
    );

    return (
        <IconPickerWithColorWrapper>
            <StyledHexColorPickerWithList
                onChange={onSelectColor}
                selectedColor={actualWidgetColor}
                customColors={filteredHiddenColors}
                selectedCustomColorId={icon?.customColorId}
                disabled={disabled}
                dataAutomation="icon-color-picker"
                noBackgroundColor
                showBorder
            />
            <TonkeanIconPicker
                color={actualWidgetColor}
                icon={icon}
                onChangeIcon={onSelectIcon}
                SelectedIconComponent={SelectedIconComponent}
                showLabel={showLabel}
                addMargin={removeWrappingMargin ? false : undefined}
                disabled={disabled}
                predefinedIcons={predefinedIcons}
            />
        </IconPickerWithColorWrapper>
    );
};

export default IconPickerWithColor;
