import { useCallback, useEffect, useState } from 'react';

import type { FormBuilderSections } from '@tonkean/tonkean-entities';
import type { FormBuilderEditedSectionsState } from '@tonkean/tonkean-entities';
import { SavingState } from '@tonkean/tonkean-entities';
import { FormBuilderEditedSectionsInitialState } from '@tonkean/tonkean-entities';

function useEditedSections(formId: string | undefined) {
    const [editedSections, setEditedSections] = useState<FormBuilderEditedSectionsState>({
        ...FormBuilderEditedSectionsInitialState,
    });

    const setLoadingToSaved = useCallback(() => {
        setEditedSections((prevEditedSectionsState) => {
            const newEditedSectionsState = { ...FormBuilderEditedSectionsInitialState };
            Object.entries(prevEditedSectionsState).forEach(([section, state]) => {
                newEditedSectionsState[section] = state === SavingState.LOADING ? SavingState.SAVED : state;
            });

            return newEditedSectionsState;
        });
    }, []);

    const setLoadingToError = useCallback(() => {
        setEditedSections((prevEditedSectionsState) => {
            const newEditedSectionsState = { ...FormBuilderEditedSectionsInitialState };
            Object.entries(prevEditedSectionsState).forEach(([section, state]) => {
                newEditedSectionsState[section] = state === SavingState.LOADING ? SavingState.ERROR : state;
            });

            return newEditedSectionsState;
        });
    }, []);

    const setSectionLoading = useCallback((section: FormBuilderSections) => {
        setEditedSections((prevEditedSections) => ({
            ...prevEditedSections,
            [section]: SavingState.LOADING,
        }));
    }, []);

    useEffect(() => {
        setEditedSections({ ...FormBuilderEditedSectionsInitialState });
    }, [formId]);

    return {
        editedSections,
        setLoadingToSaved,
        setLoadingToError,
        setSectionLoading,
    };
}

export default useEditedSections;
