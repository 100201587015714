import React from 'react';
import styled from 'styled-components';

import WorkerRunTableListLoading from './WorkerRunTableListLoading';

import { Placeholder, SearchBox } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.current.palette.history.workerRunsList;

const NamePlaceHolder = styled(Placeholder)`
    margin: 0 10px;
`;

const InformationHeaderPlaceHolder = styled(Placeholder)`
    margin: 0 0 6px;
`;

const Header = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.borderColor};
    padding: 0 20px;
    flex-grow: 0;
    flex-shrink: 0;
`;

const Content = styled.div`
    padding: 0 20px 20px;
`;

const InformationRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`;

const TableSearch = styled(SearchBox)`
    max-width: 350px;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${Theme.current.palette.general.border};
    border-radius: 3px;
`;

const TableHeaderColumns = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr 1fr;
    grid-gap: 20px;
    height: 30px;
    border-bottom: 1px solid ${Theme.current.palette.general.border};

    > &:first-child {
        margin-left: 20px;
    }
`;

const SingleWorkerRunLoading: React.FC = () => {
    return (
        <>
            <Header>
                <Placeholder $width="20px" $circle />
                <NamePlaceHolder $height={FontSize.LARGE_16} $width="100px" />
                <Placeholder $height={FontSize.SMALL_12} $width="100px" />
            </Header>
            <Content>
                <InformationRow>
                    <InformationHeaderPlaceHolder $height={FontSize.SMALL_12} $width="50px" />
                    <Placeholder $height={FontSize.SMALL_12} $width="150px" />
                </InformationRow>

                <TableHeader>
                    <Placeholder $height={FontSize.MEDIUM_14} $width="150px" />
                    <TableSearch disabled />
                </TableHeader>

                <Table>
                    <TableHeaderColumns>
                        <InformationHeaderPlaceHolder $height={FontSize.SMALL_12} $width="50px" />
                        <InformationHeaderPlaceHolder $height={FontSize.SMALL_12} $width="50px" />
                    </TableHeaderColumns>
                    <WorkerRunTableListLoading />
                </Table>
            </Content>
        </>
    );
};

export default SingleWorkerRunLoading;
