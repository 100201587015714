import template from './tnkPostStatusUpdateLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkPostStatusUpdateLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        viewOnlyMode: '<',
        workingOn: '<',
        onPostStatusChanged: '&',
    },
    template,
    controller: 'postStatusUpdateLogicConfigurationCtrl',
});
