<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
            <div class="form-horizontal" ng-hide="connecting || integration">
                <div>
                    <div class="margin-bottom-lg info-box common-size-xxs">
                        <!--<i class="fa fa-info-circle margin-right"></i>-->
                        <p class="common-color-dark-grey">Setup instructions:</p>
                        <div class="common-color-dark-grey">
                            1. Go to into your GitLab site and click your avatar in the top right of the app.
                            <br />
                            2. Click on "Settings".
                            <br />
                            3. On the left side pane choose "Access Tokens".
                            <br />
                            4. Give the token a name like "Tonkean". (
                            <strong>Expires at</strong>
                            is optional and is subject to your choice.)
                            <br />
                            5. Choose the required scopes
                            <strong>api</strong>
                            and
                            <strong>read_user</strong>
                            . 6. Copy the newly created access token to this windows.
                            <br />
                            7. Enter the your GitLab servers URL (e.g. https://gitlab.com/ ,
                            https://gitlab.your-company.com/).
                            <br />
                            8. To finish please click save.
                            <br />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="login-uri" class="col-sm-3 control-label">GitLab URL</label>
                        <div class="col-sm-9">
                            <input
                                type="text"
                                ng-model="data.appUri"
                                class="form-control"
                                id="login-uri"
                                placeholder="URL used to get to your GitLab homepage"
                                autocomplete="off"
                                required
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="login-api-key" class="col-sm-3 control-label">Access Token</label>

                        <div class="col-sm-9">
                            <input
                                type="text"
                                ng-model="data.key"
                                class="form-control"
                                id="login-api-key"
                                autocomplete="off"
                                placeholder="Access Token"
                                required
                            />
                        </div>
                        <div class="common-subtitle-inner"><i></i></div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-7"></div>
                        <div class="col-sm-3 text-right">
                            <button
                                ng-click="connect()"
                                analytics-on
                                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                analytics-event="Authenticate"
                                analytics-label="{{ currentIntegration.name }}"
                                class="btn btn-primary"
                            >
                                Connect
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>
            <div ng-show="integration && !connecting">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change User
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>
                <h4>Which projects are relevant to this team?</h4>
                <hr />
                <span>Suggested Projects:</span>

                <div class="margin-normal-v">
                    <div ng-repeat="selection in selections" class="new-product-git-repo">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    ng-model="selection.selected"
                                    ng-required="(selections|filter:{selected:true}:true).length===0"
                                />
                                <strong>{{ selection.project.displayName }}</strong>
                            </label>

                            <div class="common-subtitle-inner margin-normal-h">
                                <span>{{ selection.project.description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <span>Add Other Projects:</span>

                <div class="flex-vmiddle margin-normal-sm-v">
                    <div class="flex-grow margin-right">
                        <ui-select theme="bootstrap" ng-model="temp.project" ng-disabled="!otherProjects">
                            <ui-select-match placeholder="Add another project">
                                {{ $select.selected.displayName }}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="project in otherProjects | filter:$select.search"
                                refresh="addProject($select.search)"
                                refresh-delay="0"
                            >
                                <div ng-bind-html="project.displayName | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <button
                        type="button"
                        ng-click="onProjectSelected(temp.project)"
                        ng-disabled="validatingRepo"
                        class="btn btn-secondary"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="common-color-danger pull-left" ng-show="data.integrationForm.$submitted">
                You should select at least one project
            </div>
            <button
                class="btn btn-default"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="ok()"
            >
                OK
            </button>
        </div>
    </div>
</form>
