import evisortIcon from '../../../../apps/tracks/images/evisort.png';
import evisortCircleIcon from '../../../../apps/tracks/images/integrations/evisort-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class EvisortIntegration extends IntegrationDefinitionBase {
    override name = 'evisort';
    override displayName = 'evisort';
    override description = 'We will only collect documents items.';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override storageProviderSettings = new StorageProviderSettings(true, true);
    override entities = [new IntegrationEntity('Document', 'Documents')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(evisortCircleIcon, '130px'),
        new IntegrationIcon(evisortIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Document: true },
        isOnlyDataRetention: {},
    };
}

export default EvisortIntegration;
