import SQLDatabaseEntityIdType from '../entities/SQLDatabaseEntityIdType';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

function isSQLDatabaseQueryConfigurationValid(queryConfiguration: SQLDatabaseQueryConfiguration) {
    return (
        queryConfiguration.queryName &&
        queryConfiguration.query &&
        (queryConfiguration.entityIdType !== SQLDatabaseEntityIdType.CUSTOM || queryConfiguration.identifierColumn) &&
        (!queryConfiguration.customTimestampsEnabled ||
            queryConfiguration.createdColumn ||
            queryConfiguration.updatedColumn) &&
        !queryConfiguration.nameValidationError &&
        !queryConfiguration.pagingValidationError &&
        queryConfiguration.queryTested
    );
}

export default isSQLDatabaseQueryConfigurationValid;
