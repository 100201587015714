import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BoxCustomActionsDefinitionCtrl($scope, integrations, integrationsConsts) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['BOX']['FILE'],
        entityName: '',
        possibleItemTypes: ['Folder', 'File'],
        possibleAccessTypes: ['User', 'Group'],
        possibleAccessLevels: ['Open', 'Company', 'Collaborators'],
        possibleAccessRoles: [
            'Editor',
            'Viewer',
            'Previewer',
            'Uploader',
            'Previewer Uploader',
            'Viewer Uploader',
            'Co-owner',
        ],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        switch ($scope.data.definition.customActionKey) {
            case 'COPY_FOLDER':
            case 'MOVE_FOLDER':
                $scope.data.entityName = integrationsConsts.getEntitiesMetadata()['BOX']['FOLDER']['displayName'];
                break;
            case 'COPY_FILE':
            case 'MOVE_FILE':
                $scope.data.entityName = integrationsConsts.getEntitiesMetadata()['BOX']['FILE']['displayName'];
                break;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onItemNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.itemNameExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAccessTypeExpressionChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAccessEmailAddressExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.accessEmailAddressExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAccessIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.accessIdExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onExpiresAtExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.expiresAtExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onOnBehalfOfUserIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.onBehalfOfUserIdExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAccessRoleExpressionChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onItemTypeExpressionChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onItemIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.itemIdExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('BoxCustomActionsDefinitionCtrl', lateConstructController(BoxCustomActionsDefinitionCtrl));
