import React from 'react';
import { components } from 'react-select';
import type { OptionProps } from 'react-select';
import type { GroupBase } from 'react-select';

import Status from '../../CellRenderers/components/StatusChip';

import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import type { State } from '@tonkean/tonkean-entities';

export type SelectStatusOption = SimpleSelectSingleOption<any> & State;

export const StatusOption: React.FC<{
    selectProps: OptionProps<SelectStatusOption>;
    component: React.ComponentType<React.PropsWithChildren<{}>>;
}> = ({ selectProps, component: Component }) => {
    const status = selectProps.data;

    return (
        <Component {...selectProps}>
            <Status state={status} />
        </Component>
    );
};

export const Option: React.FC<OptionProps<SelectStatusOption, false, GroupBase<SelectStatusOption>>> = (props) => {
    return <StatusOption selectProps={props} component={components.Option} />;
};

export const SingleValue: React.FC<OptionProps<SelectStatusOption, false, GroupBase<SelectStatusOption>>> = (props) => {
    return <StatusOption selectProps={props} component={components.SingleValue} />;
};

export const MultiValueLabel: React.FC<OptionProps<SelectStatusOption, false, GroupBase<SelectStatusOption>>> = (
    props,
) => {
    return <StatusOption selectProps={props} component={components.MultiValueLabel} />;
};
