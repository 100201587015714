import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousItemCreatedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_ITEM_CREATED,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-created',
    title: 'Item Is Added',
    description: 'When Item Is Added.',
    longDescription: 'This trigger will run when a new item enters the flow, either synced or manually created.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [addNewLogicOption],
    definition: {},
};

export default AutonomousItemCreatedLogicBlockConfig;
