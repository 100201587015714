import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import ProjectIntegrationPageExplorerContent from './ProjectIntegrationPageExplorerContent';
import type ProjectIntegrationPageExplorerSection from '../../../ProjectIntegrationPageModule/entities/ProjectIntegrationPageExplorerSection';
import getDataSourceLoadingSections from '../../../ProjectIntegrationPageModule/utils/getDataSourceLoadingSections';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ProjectIntegrationWorkflowFolderAccess } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    filterByText: string | undefined;
    projectIntegrationsSummaries: ProjectIntegrationSummary[];
    onClickDataSource: (projectIntegration: ProjectIntegrationSummary) => void;
    isLoading: boolean;
    projectIntegrationWorkflowFolderAccess?: ProjectIntegrationWorkflowFolderAccess;
    isFilterBySolutionAccess?: boolean;
    filterIntegrationsWithNoEntities?: boolean;
    projectIntegrationSections: ProjectIntegrationPageExplorerSection[];
}

const ProjectIntegrationsListView: React.FC<Props> = ({
    filterByText,
    projectIntegrationsSummaries,
    onClickDataSource,
    isLoading,
    projectIntegrationWorkflowFolderAccess,
    isFilterBySolutionAccess = false,
    filterIntegrationsWithNoEntities,
    projectIntegrationSections,
}) => {
    // Saving project id for ease of use.
    const projectManager = useAngularService('projectManager');
    const projectId = useMemo(() => projectManager.project.id, [projectManager]);

    const [
        {
            data: enterpriseComponentsCurrentPersonPermissionRole,
            error: errorLoadingEnterpriseComponentsCurrentPersonPermissionRole,
            loading: loadingEnterpriseComponentsCurrentPersonPermissionRole,
        },
        getEnterpriseComponentAccess,
    ] = useLazyTonkeanService('getEnterpriseComponentsPersonPermissionsRole');

    useEffect(() => {
        if (projectIntegrationsSummaries.length)
            getEnterpriseComponentAccess(
                projectIntegrationsSummaries.map((projectIntegration) => projectIntegration?.id),
                TonkeanType.PROJECT_INTEGRATION,
                projectId,
            );
    }, [getEnterpriseComponentAccess, projectId, projectIntegrationsSummaries]);

    return !loadingEnterpriseComponentsCurrentPersonPermissionRole ? (
        <ProjectIntegrationPageExplorerContent
            onItemSelected={(id) => {
                const projectIntegration = projectIntegrationsSummaries.find((item) => item.id === id);
                if (projectIntegration) {
                    onClickDataSource(projectIntegration);
                }
            }}
            searchText={filterByText}
            projectIntegrationSections={
                isLoading ? getDataSourceLoadingSections(3, [3, 3, 3]) : projectIntegrationSections
            }
            isFilterBySolutionAccess={isFilterBySolutionAccess}
            projectIntegrationWorkflowFolderAccess={projectIntegrationWorkflowFolderAccess}
            enterpriseComponentsCurrentPersonPermissionRole={enterpriseComponentsCurrentPersonPermissionRole}
            filterIntegrationsWithNoEntities={filterIntegrationsWithNoEntities}
        />
    ) : null;
};

export default ProjectIntegrationsListView;
