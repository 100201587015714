import { FullScreenIcon } from '@tonkean/svg';
import { CalendarOldIcon as CalendarIcon } from '@tonkean/svg';
import React from 'react';
import Utils from '@tonkean/utils';
import { TrackActions } from '@tonkean/flux';
import TrackFieldUser from './TrackFieldUser';
import TrackFieldPicklist from './TrackFieldPicklist';
import { Popover } from '@tonkean/infrastructure';
import { angularToReact } from '@tonkean/angular-components';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { classNames } from '@tonkean/utils';
import { DatepickerInput } from '@tonkean/infrastructure';
import { FieldType, FieldDefinitionDateFormatToDisplayMapper } from '@tonkean/tonkean-entities';
import { DEFAULT_COLUMN_WIDTH, FIELD_DEFINITION_FIELD_TYPES } from '@tonkean/constants';

const EditDropdownOptionsPopover = angularToReact('tnk-edit-dropdown-options-popover');
const DEFAULT_DISPLAY_FORMAT = 'DEFAULT_DATE';

/**
 * A components that renders the track list item field.
 */
export default class TrackField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { manualListFieldPopoverOpen: false };
        this.manualListFieldPopoverRef = React.createRef();
    }

    shouldComponentUpdate(nextProps) {
        if (!Utils.shallowEqualIgnoreRef(this.validFields, nextProps.validFields)) {
            return true;
        }
        if (!Utils.shallowEqual(this.props.fieldDefinition, nextProps.fieldDefinition)) {
            return true;
        }
        // If the edit mode has changed from false to this field def's id - render.
        if (
            this.props.editFieldMode !== nextProps.editFieldMode &&
            nextProps.editFieldMode === nextProps.fieldDefinition.id
        ) {
            return true;
        }
        // If the edit mode has changed and this field was previously the one in edit mode - render.
        if (
            this.props.editFieldMode !== nextProps.editFieldMode &&
            this.props.editFieldMode === this.props.fieldDefinition.id
        ) {
            return true;
        }

        return false;
    }

    // region: Helper functions

    /**
     * A helper function used for getting fields from defIdToValidFieldsMap safely (won't cause an exception and always returns an object).
     * @param nullIfEmpty - if true, returns null if the first field is not found. Otherwise an empty object is returned.
     */
    getFirstFieldFromValidMap(nullIfEmpty) {
        const validFields = this.getMultiFieldsFromValidMap();

        if (validFields.length) {
            return validFields[0];
        }

        return nullIfEmpty ? null : {};
    }

    /**
     * A helper function used for getting an array of multiple fields from defIdToValidFieldsMap safely (won't cause an exception and always returns an array).
     */
    getMultiFieldsFromValidMap() {
        return this.props.validFields || [];
    }

    isFieldInEditMode() {
        return (
            this.props.editFieldMode &&
            this.props.editFieldMode === this.props.fieldDefinition.id &&
            this.props.fieldDefinition.canUpdateFromUI
        );
    }

    // endregion: Helper functions

    // region: UI Actions

    startFieldEditMode() {
        TrackActions.toggleTrackFieldEdit(
            this.props.track.id,
            this.props.editorId,
            this.props.fieldDefinition.id,
            true,
        );
    }

    updateFieldByEvent(e) {
        this.updateField(e.target.value);
    }

    updateField(value) {
        this.props?.onManualFieldUpdate?.(value);
        this.props.uiActions.updateFieldValue(
            this.props.track.id,
            this.getFirstFieldFromValidMap(true),
            this.props.fieldDefinition,
            value,
        );
        TrackActions.toggleTrackFieldEdit(
            this.props.track.id,
            this.props.editorId,
            this.props.fieldDefinition.id,
            false,
        );
    }

    onFieldKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            // Handle enter.
            e.preventDefault();
            this.updateFieldByEvent(e);
        } else if (e.key === 'Escape' || e.keyCode === 27) {
            // Handle escape or shift+tab.
            e.preventDefault();
            TrackActions.toggleTrackFieldEdit(
                this.props.track.id,
                this.props.editorId,
                this.props.fieldDefinition.id,
                false,
            );
        } else if ((e.key === 'Tab' || e.keyCode === 9) && !e.shiftKey) {
            // Handle tab (without shift).
            // e.preventDefault();
        } else if ((e.key === 'Tab' || e.keyCode === 9) && e.shiftKey) {
            // Handle tab (without shift).
            // e.preventDefault();
        } else if (e.key === 'ArrowUp' || e.keyCode === 38) {
            // Handle arrow up.
            // e.preventDefault();
        } else if (e.code === 'ArrowDown' || e.keyCode === 40) {
            // Handle arrow down.
            // e.preventDefault();
        }
    }

    openFieldGraphModal(fieldDef, e) {
        // We don't want the field itself to be clicked and enter edit mode when clicking on the graph button.
        e.stopPropagation();
        this.props.fieldOpenOrExtend(fieldDef, true);
    }

    // endregion: UI Actions

    getFieldElement(
        validField,
        fieldDef,
        track,
        realTrack,
        isMultiple,
        appendToParentClassNameAction,
        setParentClickEvent,
        fieldStyle,
    ) {
        let field = (
            <div className="common-no-outline fs-censored flex-grow" style={{ minWidth: '25px', minHeight: '17px' }} />
        ); // Default field (for empty fields).
        const fieldDefTypes = FIELD_DEFINITION_FIELD_TYPES;
        const isFieldInEdit = this.isFieldInEditMode();
        let viewHistory = false;
        const minLengthForViewHistory = 20;

        const trackCreatorAllowed = Utils.findFirst(
            fieldDef.updateFieldPermissions,
            (element) => element === 'TRACK_CREATOR',
        );

        const currentUserId = this.props.services.as.currentUser.id;
        const isCurrentUserTrackCreator = track.creator && track.creator.id === currentUserId;

        const doesCurrentUserHavePermissionToEdit = this.props.currentUserService.doesCurrentUserHavePermissionToEdit(
            fieldDef,
            track.owner,
            this.props.projectManager.groupsMap[this.props.groupId],
            track.creator,
        );

        if (
            (fieldDef.type === 'MANUAL' || (!isMultiple && fieldDef.updateable)) &&
            doesCurrentUserHavePermissionToEdit &&
            !fieldDef?.idRelationField
        ) {
            if (fieldDef.fieldType === FieldType.List || fieldDef.displayAs === FieldType.List) {
                field = this.getManualListFieldElement(validField, fieldDef, setParentClickEvent);
            } else if (fieldDef.displayAs === FieldType.Date) {
                field = this.getManualDateFieldElement(validField, setParentClickEvent, fieldDef);
            } else if (this.props.restrictNumberInputs && fieldDef.displayAs === FieldType.Number) {
                viewHistory = true;
                field = this.getManualDefaultFieldElement(
                    validField,
                    isFieldInEdit && doesCurrentUserHavePermissionToEdit,
                    appendToParentClassNameAction,
                    setParentClickEvent,
                    'number',
                );
            } else if (fieldDef.displayAs === fieldDefTypes.string) {
                if (fieldDef.displayFormat === 'USER') {
                    field = this.getManualUserFieldElement(
                        validField,
                        fieldDef,
                        !trackCreatorAllowed || isCurrentUserTrackCreator,
                        setParentClickEvent,
                        doesCurrentUserHavePermissionToEdit,
                    );
                } else {
                    viewHistory =
                        validField &&
                        validField.displayValue &&
                        validField.displayValue.length > minLengthForViewHistory;
                    field = this.getManualDefaultFieldElement(
                        validField,
                        isFieldInEdit && doesCurrentUserHavePermissionToEdit,
                        appendToParentClassNameAction,
                        setParentClickEvent,
                    );
                }
            } else {
                viewHistory = true;
                field = this.getManualDefaultFieldElement(
                    validField,
                    isFieldInEdit,
                    appendToParentClassNameAction,
                    setParentClickEvent,
                );
            }
        } else {
            // External
            if (
                fieldDef.type === 'MANUAL' &&
                fieldDef.displayAs === fieldDefTypes.string &&
                fieldDef.displayFormat === 'USER'
            ) {
                field = this.getManualUserFieldElement(
                    validField,
                    fieldDef,
                    !trackCreatorAllowed || isCurrentUserTrackCreator,
                    setParentClickEvent,
                    doesCurrentUserHavePermissionToEdit,
                );
            } else if (trackCreatorAllowed && !isCurrentUserTrackCreator && !doesCurrentUserHavePermissionToEdit) {
                viewHistory =
                    fieldDef.displayAs === fieldDefTypes.number ||
                    (fieldDef.displayAs === fieldDefTypes.string &&
                        validField &&
                        validField.displayValue &&
                        validField.displayValue.length > minLengthForViewHistory);
                field = this.getExternalSingleFieldElement(
                    validField,
                    fieldDef,
                    track,
                    fieldStyle,
                    true,
                    'Only item creator can update this field',
                );
            } else {
                viewHistory =
                    fieldDef.displayAs === fieldDefTypes.number ||
                    (fieldDef.displayAs === fieldDefTypes.string &&
                        validField &&
                        validField.displayValue &&
                        validField.displayValue.length > minLengthForViewHistory);
                field = this.getExternalSingleFieldElement(validField, fieldDef, track, fieldStyle);
            }
        }

        // For certain field types, we show a view history button (that opens a modal).
        // A valid (not empty field) must also have an id. The valid field might be an empty object.
        if (viewHistory && !(validField && validField.hasUrlLink) && !this.props.hideHistory) {
            let graphButtonClasses = 'tracks-item-field-graph-icon';
            let isDisabled = false;
            let tooltipText = 'View More';

            // In case the field is empty.
            if (!validField || !validField.id) {
                // Center the button when not manual (for manuals we have an input border).
                if (fieldDef.type !== 'MANUAL') {
                    graphButtonClasses += ' mod-centered';
                }

                // In case the field instance was never set (it doesn't even exist as a non valid field), disable the graph button.
                if (
                    !(
                        realTrack.defIdToFieldsMap &&
                        realTrack.defIdToFieldsMap[fieldDef.id] &&
                        realTrack.defIdToFieldsMap[fieldDef.id][0]
                    )
                ) {
                    isDisabled = true;
                    tooltipText = 'No Value';
                }
            }

            // In case the field is list move the button up a bit.
            if (fieldDef.fieldType === fieldDefTypes.list) {
                graphButtonClasses += ' mod-list';
            }

            // let fieldContentClassName = 'custom-field-content';
            // if (showGraphButton) {
            //     fieldContentClassName += ' mod-show-graph';
            // }

            field = (
                <React.Fragment>
                    <div className={graphButtonClasses}>
                        <div
                            disabled={isDisabled}
                            onClick={this.openFieldGraphModal.bind(this, fieldDef)}
                            className="btn btn-xs btn-primary-black common-z-index-1 padding-normal-xxs common-no-outline tnk-tooltip mod-top cursor-pointer"
                            tnk-dnd-undraggable="true"
                        >
                            <i className="svg-icon-xxs common-color-white svg-icon-vmiddle ">
                                <span className="tnk-icon">
                                    <FullScreenIcon />
                                </span>
                            </i>
                            <span className="tnk-tooltip-text">{tooltipText}</span>
                        </div>
                    </div>
                    {field}
                </React.Fragment>
            );
        }

        return field;
    }

    getManualDateFieldElement(validField, setParentClickEvent, fieldDef) {
        const updateOpenStateCallback = (open) => this.setState({ manualDateFieldPopoverOpen: open });
        const onClose = () => {
            updateOpenStateCallback(false);
        };

        setParentClickEvent(() => updateOpenStateCallback(true));
        const fieldDisplayDetails =
            FieldDefinitionDateFormatToDisplayMapper[fieldDef?.displayFormat || DEFAULT_DISPLAY_FORMAT] ||
            FieldDefinitionDateFormatToDisplayMapper[DEFAULT_DISPLAY_FORMAT];
        return (
            <div
                className={classNames(
                    'common-no-outline pointer flex-grow',
                    !validField.displayValue && !this.props.manualDateFieldPopoverOpen && ' visible-on-hover',
                    this.props.collectItemsMode && 'mod-force-visible',
                )}
            >
                <Popover
                    content={
                        <DatepickerInput
                            selected={validField.dateValue}
                            onChange={(date) => {
                                this.updateField(date?.toISOString());
                            }}
                            onCalendarClose={onClose}
                            dateFormat={fieldDisplayDetails?.format}
                            showTimeSelectOnly={!fieldDisplayDetails?.includeDate}
                            showTimeSelect={fieldDisplayDetails?.includeTime}
                            timeFormat={fieldDisplayDetails?.timeFormat}
                            usePortal={false}
                            startOpen
                        />
                    }
                    show={this.state.manualDateFieldPopoverOpen && !this.props.viewOnlyMode}
                    onClose={onClose}
                >
                    <div
                        className="relative"
                        onClick={() => {
                            if (!this.props.viewOnlyMode) {
                                updateOpenStateCallback(!this.state.manualDateFieldPopoverOpen);
                            }
                        }}
                    >
                        {!validField.displayValue && (
                            <i className="svg-icon-track svg-icon-hover-black common-color-light-grey">
                                <span className="tnk-icon">
                                    <CalendarIcon />
                                </span>
                            </i>
                        )}
                        {validField.displayValue}
                    </div>
                </Popover>
            </div>
        );
    }

    getDropdownOptionsPopoverContainerId(fieldDefId) {
        return Utils.getReactAngularContainerId(
            'set-dropdown-options',
            this.props.track.id,
            fieldDefId,
            this.props.editorId,
        );
    }

    /**
     * Calculate the possible values from the field definition and the field value
     * @param fieldDef - The given field definition to extract the options from
     * @returns {[]} - An array of string which are the options you may choose in the dropdown
     */
    calculatePossibleValuesForDropdown(fieldDef) {
        if (
            fieldDef.dropdownSource === 'FROM_FIELD' &&
            (this.props.track.fields?.length ||
                this.props.projectManager.groupsMap[this.props.groupId].globalFields?.length ||
                this.props.projectManager.groupsMap[this.props.groupId].globalFieldsDraft?.length)
        ) {
            // get the global fields of the workflow version type.
            const group = this.props.projectManager.groupsMap[this.props.groupId];
            const workflowVersionTypeGlobalFields = this.props.realTrack.isDraftInitiative
                ? group.globalFieldsDraft
                : group.globalFields;

            // If it's from a field values, dynamically calculate the values
            const fromFieldInstances = [...(this.props.track.fields ?? []), ...(workflowVersionTypeGlobalFields ?? [])];

            const fromFieldInstance = fromFieldInstances.find(
                (field) => field.fieldDefinition.id === fieldDef.dropdownOptionsFromFieldDefinitionId,
            );

            const valuesFromFieldInstance =
                fromFieldInstance?.multiValues ||
                fromFieldInstance?.values ||
                fromFieldInstance?.value?.split(fieldDef?.inputMultiValueSeparator || ',') ||
                [];

            if (fieldDef.possibleValues) {
                return [...valuesFromFieldInstance, ...fieldDef.possibleValues];
            }

            return valuesFromFieldInstance;
        } else if (
            fieldDef.dropdownSource === 'MANUAL' ||
            fieldDef.dropdownSource === 'SYNC_FIELD' ||
            (!fieldDef.dropdownSource && fieldDef.type === 'COLUMN')
        ) {
            // If it's from field options or manual, it should already be calculated
            return fieldDef.possibleValues;
        } else if (fieldDef.fieldType === 'List' && fieldDef.type === 'EXTERNAL') {
            // If it's external field we can extract it from the metadata
            return fieldDef.definition?.fieldMetadata?.values;
        } else {
            return [];
        }
    }

    /**
     * Calculate the selected values from the field definition and the field value
     * @param field - The given field to extract the selected values from
     * @param fieldDef - The given definition to extract whether the field is multivalued or not
     * @returns {[]} -  An array which holds the values which are already selected in this dropdown
     */
    calculateSelectedValuesForDropdown(field, fieldDef) {
        // Cast manually the selected value into an array by the given configuration
        let selectedValues = [];

        if (fieldDef.isMultiValueField) {
            selectedValues = field.formattedMultiValues || [];
            // If it's not an array, split the string by the separator and make it an array
            if (!Array.isArray(field.formattedMultiValues) && !!field.formattedMultiValues) {
                selectedValues = field.formattedMultiValues.split(fieldDef.outputMultiValueSeparator);
            }
        } else {
            // In it's a single value, push it to an array of one value
            selectedValues = field.displayValue ? [field.displayValue] : [];
        }

        return selectedValues;
    }

    getManualListFieldElement(validField, fieldDef, setParentClickEvent) {
        const possibleValues = this.calculatePossibleValuesForDropdown(fieldDef);
        const selectedValues = this.calculateSelectedValuesForDropdown(validField, fieldDef);

        const createUpdateOpenStateCallback = (manualListFieldPopoverOpen) => () =>
            this.setState({ manualListFieldPopoverOpen });

        setParentClickEvent(() => createUpdateOpenStateCallback(true)());

        return (
            <>
                <Popover
                    content={
                        <EditDropdownOptionsPopover
                            dropdownConfig={{
                                possibleValues,
                                selectedValues,
                                isCreatable: fieldDef.allowAddDropdownOptions,
                                isMultiValueField: fieldDef.isMultiValueField,
                                onSave: this.updateField.bind(this),
                            }}
                            onClose={createUpdateOpenStateCallback(false)}
                            initiativeId={this.props.track.id}
                            fieldDefinitionId={fieldDef.id}
                            dropdownSource={fieldDef.dropdownSource}
                        />
                    }
                    placement="bottom"
                    show={this.state.manualListFieldPopoverOpen && !this.props.viewOnlyMode}
                    onClose={createUpdateOpenStateCallback(false)}
                    nodeRef={this.manualListFieldPopoverRef}
                />
                <TrackFieldPicklist
                    options={possibleValues}
                    selected={selectedValues}
                    onIconClick={this.props.viewOnlyMode ? undefined : createUpdateOpenStateCallback(true)}
                    divRef={this.manualListFieldPopoverRef}
                    collectItemsMode={this.props.collectItemsMode}
                    dropdownSource={fieldDef.dropdownSource}
                />
            </>
        );
    }

    getManualUserFieldElement(
        validField,
        fieldDef,
        isCreatorOnlyValid,
        setParentClickEvent,
        doesCurrentUserHavePermissionToEdit,
    ) {
        return (
            <TrackFieldUser
                field={validField}
                track={this.props.track}
                editorId={this.props.editorId}
                hasCustomBackground={!!validField.reactStyle && fieldDef.isImportant}
                uiActions={this.props.uiActions}
                realTrack={this.props.realTrack}
                fieldDef={fieldDef}
                personCache={this.props.personCache}
                viewOnlyMode={this.props.viewOnlyMode}
                anonymous={this.props.anonymous}
                updateField={this.updateField.bind(this)}
                isCreatorOnlyValid={isCreatorOnlyValid || doesCurrentUserHavePermissionToEdit}
                setParentClickEvent={setParentClickEvent}
            />
        );
    }

    getManualDefaultFieldElement(
        validField,
        isFieldInEdit,
        appendToParentClassNameAction,
        setParentClickEvent,
        type = 'text',
    ) {
        let fieldClasses = 'common-no-outline fs-censored common-ellipsis';
        fieldClasses += !validField.change ? ' flex-grow' : '';
        appendToParentClassNameAction(' track-item-field-input');
        appendToParentClassNameAction(isFieldInEdit ? ' mod-edit' : ' mod-hover');

        if (this.props.collectItemsMode) {
            appendToParentClassNameAction(' mod-hover-force');
        }

        if (!isFieldInEdit || this.props.viewOnlyMode) {
            // Field NOT in edit mode.
            setParentClickEvent(this.startFieldEditMode.bind(this));
            return (
                <div className={fieldClasses} style={{ minWidth: '25px', minHeight: '17px' }}>
                    {validField.formattedValue ? validField.formattedValue : validField.displayValue}
                </div>
            );
        } else {
            // Field IN edit mode.
            let defaultValue = validField.formattedValue ? validField.formattedValue : validField.displayValue;
            if (type === 'number') {
                defaultValue = validField.value;
            }
            return (
                <input
                    className="form-control"
                    data-automation="track-field-write-filed-value"
                    defaultValue={defaultValue}
                    onFocus={Utils.moveCaretToEnd.bind(this)}
                    onBlur={this.updateFieldByEvent.bind(this)}
                    onKeyDown={this.onFieldKeyDown.bind(this)}
                    type={type}
                    autoFocus
                />
            );
        }
    }

    getExternalSingleFieldElement(validField, fieldDef, track, fieldStyle, forceTooltip, tooltipText) {
        const fieldValue = validField.displayValue;
        const displayValue = fieldValue;
        let fieldClasses = 'fs-censored relative flex-grow padding-right-xs cursor-default';
        let widthToCharRatio = 5;

        // For some reason url's needs a bigger divider
        if (validField.hasUrlLink) {
            widthToCharRatio = 7;
        }
        const maxChars = fieldStyle.maxWidth / widthToCharRatio;
        const showTooltip = (displayValue && displayValue.length > maxChars) || forceTooltip;
        if (showTooltip) {
            fieldClasses += ' tnk-tooltip mod-top';
        }

        if ((!Utils.isNullOrEmpty(displayValue) || tooltipText) && !(validField.reactStyle && fieldDef.isImportant)) {
            fieldClasses += ' custom-field-white-hover mod-padded';
        }

        return (
            <div className={fieldClasses}>
                <div
                    tnk-dnd-prevent-default-drag="true"
                    className="common-ellipsis cursor-text"
                    data-automation="track-field-custom-field"
                >
                    {displayValue}
                </div>
                {showTooltip ? (
                    <span className="tnk-tooltip-text track-col-external-tooltip">
                        {forceTooltip ? tooltipText : fieldValue}
                    </span>
                ) : (
                    ''
                )}
            </div>
        );
    }

    render() {
        // Get the real track (in case this is a linked item)
        const track = this.props.track;
        const realTrack = this.props.realTrack;
        const fieldDef = this.props.fieldDefinition;
        const validField = this.getFirstFieldFromValidMap();
        const isMultiple = realTrack.multipleFieldsMap && realTrack.multipleFieldsMap[fieldDef.id];

        // Classes.
        let className = 'common-size-xxxxs tracks-col-custom-field text-center flex mod-justify-center relative';
        let linkIcon = null;
        let trendIcon = null;
        let clickEvent = null;
        const appendToClassNameAction = (text) => (className += text); // we must have an append action because strings are immutable
        const setClickEvent = (value) => (clickEvent = value);

        // Style.
        let style = this.getMultiFieldsFromValidMap().length < 2 ? validField.reactStyle || {} : {};
        if (
            this.props.services.as.currentUser &&
            this.props.services.as.currentUser.metadata &&
            this.props.services.as.currentUser.metadata.columnWidthOverrides &&
            this.props.services.as.currentUser.metadata.columnWidthOverrides[this.props.groupId]
        ) {
            const columnConfig =
                this.props.services.as.currentUser.metadata.columnWidthOverrides[this.props.groupId][fieldDef.id];
            if (columnConfig) {
                // if the style object already exist it might be readonly so make a new one
                const newStyle = {};
                Utils.flatCopyObjectProperties(style, newStyle);
                style = newStyle;
                style.width = columnConfig;
                style.maxWidth = columnConfig;
            }
        }

        if (!style.maxWidth) {
            style.maxWidth = DEFAULT_COLUMN_WIDTH;
        }

        // Inner elements (linkIcon and trendIcon).
        if (!isMultiple) {
            // link icon - if the field has a url link.
            if (validField.hasUrlLink) {
                linkIcon = (
                    <a href={validField.value} target="_blank" className="flex mod-align-center">
                        <i className="fa fa-external-link-square margin-right-xs" />
                    </a>
                );

                appendToClassNameAction(' mod-left');
            }

            // trend icon - if there's a change in the data.
            if (validField.change > 0 || validField.change < 0) {
                let trendIconClasses = 'margin-right-xs common-size-xxxxxs pointer';
                trendIconClasses += validField.negative ? ' common-color-danger' : '';
                trendIconClasses += validField.positive ? ' common-color-success' : '';

                trendIcon = (
                    <span className={trendIconClasses}>
                        <i className={`fa fa-caret-${validField.trend}`} />
                    </span>
                );
            }
        }

        const field = this.getFieldElement(
            validField,
            fieldDef,
            track,
            realTrack,
            isMultiple,
            appendToClassNameAction,
            setClickEvent,
            style,
        );

        return (
            <div
                className={className}
                data-automation={`track-field-click-field-column-${fieldDef.name}-${track.title}`}
                style={style}
                onClick={clickEvent}
                onKeyPress={(e) => {
                    if (this.props.collectItemsMode) {
                        clickEvent?.(e);
                    }
                }}
                tabIndex={!this.props.collectItemsMode ? -1 : 0}
            >
                {this.props.validationMessage?.length && (
                    <Tooltip content={this.props.validationMessage.map((error) => error.errorMessage).join(', ')}>
                        <div
                            className={classNames(
                                'track-field-validation-indicator',
                                this.props.validationMessage.every((error) => !error.restrictSubmission) &&
                                    'mod-warning',
                            )}
                        />
                    </Tooltip>
                )}
                {linkIcon}
                {trendIcon}
                {field}
            </div>
        );
    }
}
