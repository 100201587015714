/**
 * Get integration type (external entity type) of the field.
 *
 * @param field - the field definition.
 * @returns the integration type.
 */
import type { AngularServices } from 'angulareact';

import type { FieldDefinition } from '@tonkean/tonkean-entities';

function getHistoryFieldIntegrationId(
    field: FieldDefinition<any>,
    workflowVersionId: string | undefined,
    customTriggerManager: AngularServices['customTriggerManager'],
): string | undefined {
    // If it's a related entity field, it will be manual, but we should show the integration id of the
    // matched external entity.

    if (field.definition?.matchConfiguration?.isForMatchingItem) {
        return field.projectIntegration.id;
    }

    if (field.linkedCustomTrigger) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            workflowVersionId,
            field.linkedCustomTrigger.id,
        );
        return customTrigger?.projectIntegrationIds?.[0] || field?.projectIntegration?.id;
    }

    return field.projectIntegration?.id;
}

export default getHistoryFieldIntegrationId;
