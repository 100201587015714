import React, { useMemo } from 'react';
import styled from 'styled-components';

import FieldsContainer from './FieldsContainer';
import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration/SingleFieldConfiguration';
import type { FieldsWidgetDisplayType } from '../FieldsItemWidgetConfiguration';

import { Placeholder } from '@tonkean/infrastructure';

interface Props {
    fields?: Record<string, SingleFieldConfiguration>;
    singleField?: boolean;
    fieldDisplay: FieldsWidgetDisplayType;
}

const WidgetItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    gap: 8px;
`;

const FieldLoadingSkeleton = () => {
    return (
        <WidgetItemWrapper>
            <Placeholder $width="25%" $height="14px" />
            <Placeholder $width="100%" $height="34px" />
        </WidgetItemWrapper>
    );
};

const FieldsWidgetLoadingSkeleton: React.FC<Props> = ({ fields, singleField, fieldDisplay }) => {
    const numberOfFields: number = useMemo(() => {
        if (fields) {
            return Object.values(fields).filter((field) => field.isShown).length;
        }

        return singleField ? 1 : 3;
    }, [fields, singleField]);
    const arrayOfRows = Array.from({ length: numberOfFields }).fill(0);
    return (
        <FieldsContainer fieldDisplay={fieldDisplay}>
            {arrayOfRows.map((row, index) => (
                <FieldLoadingSkeleton key={index} />
            ))}
        </FieldsContainer>
    );
};

export default FieldsWidgetLoadingSkeleton;
