import TrackInnerItemsCounts from './TrackInnerItemsCounts';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkTrackInnerItemsCounts',
        reactToAngular(TrackInnerItemsCounts, ['realTrack', 'hideCount', 'onClick']),
    );
