import { getStateError } from '@tonkean/utils';

function DeleteFieldDefinitionModalCtrl(
    $scope,
    projectManager,
    fieldDefinitionId,
    fieldDefinitionName,
    isGlobalFieldDefinition,
    globalFieldId,
    groupId,
    workflowVersionId,
    $q,
    $uibModalInstance,
    customFieldsManager,
    tonkeanService,
) {
    $scope.data = {
        fieldDefinitionId,
        fieldDefinitionName,
        isGlobalFieldDefinition,
        globalFieldId,
        groupId,
        workflowVersionId,

        deletingFieldDefinition: false,
        errorDeletingFieldDefinition: null,
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close(false);
    };

    /**
     * Deletes the field definition.
     */
    $scope.deleteFieldDefinition = function () {
        $scope.data.deletingFieldDefinition = true;
        $scope.data.errorDeletingFieldDefinition = null;

        let deletePromise;

        if ($scope.data.isGlobalFieldDefinition) {
            // If it's a global field definition, we need to delete the global field as well.
            deletePromise = tonkeanService
                .getImpactedByFieldDefinitions($scope.data.workflowVersionId, [$scope.data.fieldDefinitionId])
                .then((data) => {
                    if (data.impactedByFieldDefinitionIds && data.impactedByFieldDefinitionIds.length) {
                        // If this definition impacts other definitions, we must return.
                        $scope.data.errorDeletingFieldDefinition = `Other fields definitions or triggers are dependent on ${$scope.data.fieldDefinitionName}. You should first delete the dependencies and then delete this field.`;
                        return $q.resolve();
                    }

                    return customFieldsManager.deleteField(
                        $scope.data.workflowVersionId,
                        $scope.data.groupId,
                        $scope.data.fieldDefinitionId,
                        $scope.data.globalFieldId,
                    );
                });
        } else {
            // Otherwise, we just delete the field definition.
            deletePromise = tonkeanService.deleteFieldDefinition($scope.data.fieldDefinitionId);
        }

        // If everything completed successfully, we close the modal.
        return deletePromise
            .then(() => {
                $uibModalInstance.close(true);
            })
            .catch((error) => {
                $scope.data.errorDeletingFieldDefinition = getStateError(error);
            })
            .finally(() => {
                $scope.data.deletingFieldDefinition = false;
            });
    };
}

export default angular.module('tonkean.app').controller('DeleteFieldDefinitionModalCtrl', DeleteFieldDefinitionModalCtrl);
