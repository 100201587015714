import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const FormSettingsOptionalSubTitle = styled.span`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    margin-right: 3px;
`;

export default FormSettingsOptionalSubTitle;
