import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { LoadingCircle } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    TonkeanExpressionDefinition,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { ActionType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ParametersContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 20px;
`;

const ParameterName = styled.div`
    font-size: ${FontSize.MSMALL_13};
    font-weight: bold;
    margin-right: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 0;
`;

const ParameterValue = styled.div`
    width: 100%;
`;

const ErrorText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
    margin-top: 10px;
`;

interface Props {
    storageProjectIntegration: ProjectIntegration;
    onStorageIntegrationDynamicParametersChanged: (storageIntegration) => void;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId?: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    customTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    parametersValues: Record<string, TonkeanExpressionDefinition>;
    storageProjectIntegrationActionId: string;
}

const NoCodeProjectIntegrationDownloadPredefined: React.FC<Props> = ({
    storageProjectIntegration,
    onStorageIntegrationDynamicParametersChanged,
    groupId,
    workflowVersionId,
    customTriggerId,
    parametersValues,
    storageProjectIntegrationActionId,
}) => {
    const [
        { data: downloadCustomActions, loading: storageIntegrationActionLoading, error: storageIntegrationActionError },
        getProjectIntegrationActions,
    ] = useLazyTonkeanService('getProjectIntegrationActions');

    useEffect(() => {
        getProjectIntegrationActions(storageProjectIntegration.id, [ActionType.DOWNLOAD]).then((response) => {
            return response;
        });
    }, [getProjectIntegrationActions, storageProjectIntegration.id]);

    const [dynamicFieldsMap, setDynamicFieldsMap] = useState(parametersValues || {});

    const customAction = useMemo(() => {
        return downloadCustomActions?.entities.find((action) => action.id === storageProjectIntegrationActionId);
    }, [downloadCustomActions?.entities, storageProjectIntegrationActionId]);

    return (
        <>
            {/* Handle all loading */}
            {storageIntegrationActionLoading && <LoadingCircle />}

            {/* Handle all errors */}
            {storageIntegrationActionError && <ErrorText>Error loading upload action parameters</ErrorText>}

            {customAction?.parametersDefinition?.parameters?.map((parameter) => (
                <div key={parameter.id}>
                    <ParametersContainer>
                        <ParameterName>{parameter.displayName}:</ParameterName>
                        <ParameterValue>
                            <TonkeanExpression
                                groupId={groupId}
                                logicId={customTriggerId}
                                workflowVersionId={workflowVersionId}
                                savedOriginalExpression={dynamicFieldsMap[parameter.id]?.originalExpression || ''}
                                savedEvaluatedExpression={dynamicFieldsMap[parameter.id]?.evaluatedExpression || ''}
                                onTonkeanExpressionChanged={(original, evaluated, expression) => {
                                    if (expression) {
                                        setDynamicFieldsMap({
                                            ...dynamicFieldsMap,
                                            [parameter.id]: expression,
                                        });

                                        onStorageIntegrationDynamicParametersChanged({
                                            ...dynamicFieldsMap,
                                            [parameter.id]: expression,
                                        });
                                    }
                                }}
                                doNotEvaluatePreview
                            />
                        </ParameterValue>
                    </ParametersContainer>
                </div>
            ))}
        </>
    );
};

export default NoCodeProjectIntegrationDownloadPredefined;
