import type { AngularServices } from 'angulareact';
import { DISPLAY_FORMATS, getApiNameToDisplayFormatMap } from '@tonkean/constants';
import { FIELD_TYPES } from '@tonkean/constants';
import type { valueOf } from '@tonkean/utils';

class FieldDisplay {
    public static $inject: (string & keyof AngularServices)[] = ['$filter'];
    
    protected constructor(
        protected $filter: AngularServices['$filter']
    ) {}

    /**
     * Evaluates the display value of the given field parameters.
     */
    evaluateFieldDisplayValue(
        evaluatedDisplayType: string,
        definedDisplayFormat: keyof typeof DISPLAY_FORMATS,
        value: string | number,
        dateValue: Date | number | string,
        formattedValue: string,
    ) {
        if (!value) {
            return null;
        }

        // If evaluatedDisplayType exists (it's added by us in enrichFieldDefinition) we should continue processing the field's displayValue.
        if (evaluatedDisplayType) {
            switch (evaluatedDisplayType) {
                case 'Number':
                    return formattedValue || this.$filter('number')(value) || value;

                case 'Date':
                    let displayFormat: null | valueOf<typeof DISPLAY_FORMATS> = null;
                    if (getApiNameToDisplayFormatMap()[definedDisplayFormat]) {
                        displayFormat = getApiNameToDisplayFormatMap()[definedDisplayFormat]!;
                    }

                    if (
                        !displayFormat ||
                        displayFormat.fieldType !== FIELD_TYPES.date ||
                        ('dateFormat' in displayFormat && displayFormat.dateFormat)
                    ) {
                        // If we don't have a display format, or we have a dateFormat display format,
                        // we will try to case date filter over the value to make it like the format.
                        const dateFilterFormat =
                            displayFormat && 'dateFormat' in displayFormat && displayFormat.dateFormat ? displayFormat.dateFormat : 'mediumDate';
                        const valueDate = value ? new Date(value) : null;

                        // If we were able to convert the value into a Date object that is not an Invalid Date object,
                        // we should use that converted object as a value. Otherwise, we should use the raw value as a value.
                        let actualValue: string | number | Date = value;
                        if (valueDate instanceof Date && !isNaN(valueDate.valueOf())) {
                            actualValue = valueDate;
                        }

                        if (this.$filter('date')(dateValue || actualValue, dateFilterFormat)) {
                            return this.$filter('date')(dateValue || actualValue, dateFilterFormat);
                        }
                    } else {
                        // Otherwise, it means we have a display format that is not a dateFormat format,
                        // so we do the special handling for it.
                        switch (displayFormat.apiName) {
                            case 'DATE_DIFF_FROM_TODAY':
                                const now = new Date();

                                let valueDate;
                                if (dateValue) {
                                    valueDate = !isNaN(dateValue as number)
                                        ? new Date(Number.parseInt(dateValue as string))
                                        : new Date(dateValue);
                                } else {
                                    valueDate = !isNaN(value as number) ? new Date(Number.parseInt(value as string)) : new Date(value);
                                }

                                return Math.abs(valueDate.getDate() - now.getDate());
                        }
                    }
                    break;
                default:
                    return value;
            }
        }

        // If we got here, we couldn't determine a good display value. Just return the plain field value.
        return formattedValue || value;
    }
}

angular.module('tonkean.app').service('fieldDisplay', FieldDisplay);
