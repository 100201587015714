import template from './channelCheckerSelector.template.html?angularjs';

/**
 * Component to select communication integration's channels.
 */
export default angular.module('tonkean.app').component('tnkChannelCheckerSelector', {
    bindings: {
        selectedChannel: '<', // The selected channel reference.
        integration: '<',

        onPrivateChannelSelected: '&',
        channelType: '<',
    },
    template,
    controller: 'ChannelCheckerSelectorCtrl',
});
