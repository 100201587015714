import InboxItemDispatcher from '../dispatchers/InboxItemDispatcher';

/**
 * A map for action type names.
 */
export const InboxItemActionTypes = {
    TOGGLE_LOADING: 'TOGGLE_LOADING',
    TOGGLE_ASK_LATER_DROPDOWN: 'TOGGLE_ASK_LATER_DROPDOWN',
};

/**
 * Our flux actions.
 */
export const InboxItemActions = {
    toggleLoading(id, isTrue) {
        InboxItemDispatcher.dispatch({
            type: InboxItemActionTypes.TOGGLE_LOADING,
            id,
            isTrue,
        });
    },
    toggleAskLaterDropdown(id, isTrue) {
        InboxItemDispatcher.dispatch({
            type: InboxItemActionTypes.TOGGLE_ASK_LATER_DROPDOWN,
            id,
            isTrue,
        });
    },
};

export default InboxItemActions;
