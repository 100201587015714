import { useMemo } from 'react';

import type { DetailedItemsWidgetItemInfo } from '../entities/DetailedItemsWidgetItemInfo';
import type NewAddedInitiativeInfo from '../entities/NewAddedInitiativeInfo';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Initiative } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const getInitiativeFieldValue = (
    initiative: LoadedInitiativeInfo,
    fieldDefinitionKey: FieldDefinitionKey,
): string | undefined => {
    switch (fieldDefinitionKey) {
        case SpecialFieldsKey.TITLE:
            return initiative.title;
        case SpecialFieldsKey.STATUS:
            return initiative.stateText;
        case SpecialFieldsKey.OWNER:
            return initiative?.owner?.['name'];
        case SpecialFieldsKey.DUE_DATE:
            return initiative.dueDate
                ? utils.formatDate(new Date(initiative.dueDate), false, true, true, true)
                : undefined;
        case SpecialFieldsKey.CREATED_DATE:
            return utils.formatDate(new Date(initiative.created), false, true, true, true);
        default:
            return initiative.defIdToFieldsMap?.[fieldDefinitionKey]?.[0]?.formattedValue;
    }
};

export type LoadedInitiativeInfo = Pick<Initiative, 'id' | 'title' | 'stateText' | 'owner' | 'dueDate' | 'created'> & {
    defIdToFieldsMap?: Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, { formattedValue?: string }[]>;
};

const getDetailedItemInfoFromLoadedInitiative = (
    initiative: LoadedInitiativeInfo,
    fieldsToShowKeys: FieldDefinitionKey[],
): DetailedItemsWidgetItemInfo => {
    const itemInfoFields = fieldsToShowKeys.map((fieldKey) => {
        return {
            key: fieldKey,
            value: getInitiativeFieldValue(initiative, fieldKey) || '',
        };
    });

    return {
        id: initiative.id,
        fields: itemInfoFields,
    };
};

export interface useDetailedItemsInfoProps {
    loadedInitiatives: LoadedInitiativeInfo[];
    newAddedInitiatives: NewAddedInitiativeInfo[];
    fieldsToShowKeys: FieldDefinitionKey[];
    deletedInitiativeIds: Set<TonkeanId<TonkeanType.INITIATIVE>>;
}

const useDetailedItemsInfo = ({
    loadedInitiatives,
    newAddedInitiatives,
    fieldsToShowKeys,
    deletedInitiativeIds,
}: useDetailedItemsInfoProps): DetailedItemsWidgetItemInfo[] => {
    const loadedInitiativeIdToInitiative = useMemo(() => {
        return loadedInitiatives.reduce(
            (acc, initiative) => {
                acc[initiative.id] = initiative;
                return acc;
            },
            {} as Record<LoadedInitiativeInfo['id'], LoadedInitiativeInfo>,
        );
    }, [loadedInitiatives]);

    return useMemo(() => {
        // New added initiatives appear first. Use loaded info from server if already loaded or basic id/title otherwise
        const detailedItemsInfo = newAddedInitiatives.map((initiative) => {
            const loadedInitiative = loadedInitiativeIdToInitiative[initiative.id];
            if (loadedInitiative) {
                return getDetailedItemInfoFromLoadedInitiative(loadedInitiative, fieldsToShowKeys);
            } else {
                return {
                    id: initiative.id,
                    fields: [
                        {
                            key: SpecialFieldsKey.TITLE,
                            value: initiative.title,
                        },
                    ],
                };
            }
        });

        const newAddedInitiativesIds = new Set(newAddedInitiatives.map((newInitiative) => newInitiative.id));

        loadedInitiatives.forEach((initiative) => {
            if (!newAddedInitiativesIds.has(initiative.id)) {
                detailedItemsInfo.push(getDetailedItemInfoFromLoadedInitiative(initiative, fieldsToShowKeys));
            }
        });

        return detailedItemsInfo.filter((initiative) => !deletedInitiativeIds.has(initiative.id));
    }, [
        newAddedInitiatives,
        loadedInitiatives,
        loadedInitiativeIdToInitiative,
        fieldsToShowKeys,
        deletedInitiativeIds,
    ]);
};

export default useDetailedItemsInfo;
