import React from 'react';
import styled from 'styled-components';

import { ChipStatusOption, InformationTooltip, StatusChip } from '@tonkean/infrastructure';
import type { TextAnalysisMatchInformation } from '@tonkean/tonkean-entities';
import {
    CustomTriggerType,
    FailedNlpMatchingReason,
    FailedNlpMatchingReasonToDisplayName,
} from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';

const StatusContainer = styled.div`
    display: flex;
    margin-left: 6px;
`;

export const NlpActionStatusText = styled.div<{ success?: boolean; space?: boolean }>`
    color: ${({ success }) => (success ? Theme.colors.success : Theme.colors.gray_500)};
    font-size: ${FontSize.SMALL_12};
    margin-right: ${({ space }) => (space ? 4 : 0)}px;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-left: 6px;
`;

interface Props {
    matchInformation?: TextAnalysisMatchInformation;
    failureReason?: FailedNlpMatchingReason;
    active: boolean;
    branchType: CustomTriggerType;
    activationExplainText: string;
}

const SingleWorkerRunActionInspectNlpStatus: React.FC<Props> = ({
    matchInformation,
    failureReason,
    active,
    branchType,
    activationExplainText,
}) => {
    if (matchInformation?.matched || active) {
        return (
            <>
                {active && <StatusChip status={ChipStatusOption.SUCCESS}>ACTIVATED</StatusChip>}
                {(branchType === CustomTriggerType.NLP_BRANCH_DEFAULT ||
                    branchType === CustomTriggerType.NLP_BRANCH_ANY_OF_THE_REST) && (
                    <StyledInformationTooltip iconSize={InformationIconSize.SMALL} placement="top">
                        {activationExplainText}
                    </StyledInformationTooltip>
                )}
                {matchInformation && (
                    <StatusContainer>
                        <NlpActionStatusText success space>
                            Match:
                        </NlpActionStatusText>
                        <NlpActionStatusText space>Accuracy score: </NlpActionStatusText>
                        <NlpActionStatusText success>{Math.round(matchInformation.score * 100)}</NlpActionStatusText>
                    </StatusContainer>
                )}
            </>
        );
    }

    if (failureReason) {
        return (
            <>
                <NlpActionStatusText>{FailedNlpMatchingReasonToDisplayName[failureReason]}</NlpActionStatusText>
                {failureReason === FailedNlpMatchingReason.ARBITRARY_ANCHORS && (
                    <StyledInformationTooltip iconSize={InformationIconSize.SMALL} placement="top">
                        Please review the "Advanced" Section, in the NLP model definition.
                    </StyledInformationTooltip>
                )}
            </>
        );
    }

    if (
        branchType === CustomTriggerType.NLP_BRANCH_DEFAULT ||
        branchType === CustomTriggerType.NLP_BRANCH_ANY_OF_THE_REST
    ) {
        return (
            <>
                <NlpActionStatusText>Wasn't activated</NlpActionStatusText>
                <StyledInformationTooltip iconSize={InformationIconSize.SMALL} placement="top">
                    {activationExplainText}
                </StyledInformationTooltip>
            </>
        );
    }

    return <></>;
};

export default SingleWorkerRunActionInspectNlpStatus;
