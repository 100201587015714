import React, { useMemo } from 'react';

import useCreateEdge from '../../hooks/useCreateEdge';
import useMoveNode from '../../hooks/useMoveNode';

import { useGetStateParams } from '@tonkean/angular-hooks';
import type { GetViewTransform, SelectedEntity } from '@tonkean/solution-mapper-graph';
import SolutionMapperGraphView from '@tonkean/solution-mapper-graph';
import type {
    OperationEdge,
    OperationEntityType,
    OperationNode,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

interface Props {
    isEditingEnabled: boolean;
    nodes: OperationNode[];
    edges: OperationEdge[];
    setNodes?: React.Dispatch<React.SetStateAction<OperationNode[]>>;
    setEdges?: React.Dispatch<React.SetStateAction<OperationEdge[]>>;
    selectedEntity: SelectedEntity | undefined;
    onSelectedEntity?: (type: OperationEntityType, id: string | undefined) => void;
    showGraphControls?: boolean;
    setGetViewTransform?: React.Dispatch<React.SetStateAction<GetViewTransform>>;
}

const SolutionMapperGraph: React.FC<Props> = ({
    isEditingEnabled,
    nodes,
    edges,
    setNodes,
    setEdges,
    selectedEntity,
    onSelectedEntity,
    showGraphControls = true,
    setGetViewTransform,
}) => {
    const [workflowFolderId, projectId, solutionMapperId] = useGetStateParams<
        [TonkeanId<TonkeanType.WORKFLOW_FOLDER>, TonkeanId<TonkeanType.PROJECT>, TonkeanId<TonkeanType.SOLUTION_MAPPER>]
    >('workflowFolderId', 'projectId', 'solutionMapperId');

    const moveNode = useMoveNode(setNodes);
    const [loadingEdges, createEdge] = useCreateEdge(projectId, workflowFolderId, solutionMapperId, setEdges);

    const updatedLoadingEdges = useMemo(() => {
        return loadingEdges.map((loadingEdge) => ({
            ...loadingEdge,
            type: 'loadingEdge',
            handleText: <tspan y={-25}>Creating...</tspan>,
        }));
    }, [loadingEdges]);

    return (
        <SolutionMapperGraphView
            isEditingEnabled={isEditingEnabled}
            nodes={nodes}
            edges={edges}
            loadingEdges={updatedLoadingEdges}
            selectedEntity={selectedEntity}
            onSelectedEntity={onSelectedEntity}
            showGraphControls={showGraphControls}
            createEdge={createEdge}
            moveNode={moveNode}
            setGetViewTransform={setGetViewTransform}
            showConfigurationIcons
        />
    );
};

export default SolutionMapperGraph;
