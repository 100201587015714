import template from './initiativeSettings.template.html?angularjs';

function tnkInitiativeSettings() {
    return {
        restrict: 'E',
        scope: {
            initiative: '=',
            innerCss: '@',
            showSetReminder: '=',
            onReminderSet: '&',
            onSaved: '&',
            manualGatherUpdate: '=',
        },
        controller: 'InitiativeSettingsCtrl',
        template,
    };
}
angular.module('tonkean.app').directive('tnkInitiativeSettings', tnkInitiativeSettings);
