import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    FormikAutosave,
    H1,
    Input,
    Paragraph,
    SavingIndicator,
    Textarea,
    Toggle,
} from '@tonkean/infrastructure';
import { IntegrationType } from '@tonkean/tonkean-entities';

const StyledField = styled(Field)`
    margin-top: 30px;
`;

const BodyWrapper = styled(Form)`
    max-width: 500px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 10px;

    display: flex;
    align-items: center;
`;

const ProjectIntegrationEntityGeneralPage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ projectIntegration, entity, setEntity, entitiesSummary }) => {
    const [{ loading, error }, updateProjectIntegrationEntity] = useLazyTonkeanService(
        'updateProjectIntegrationEntity',
    );

    // On Update general info for entity
    const onSubmit = async (
        displayName: string,
        description: string | undefined,
        isCollectEnabled: boolean,
        isAddRecordsEnabled: boolean,
        isLiveStreamEnabled: boolean,
        turnOffSingleCollect: boolean,
    ) => {
        // Constructing the new webhook definition with the new isEnabled.
        const newEntityWebhookDefinition = { ...entity.entityWebhookDefinition, isEnabled: isLiveStreamEnabled };

        setEntity(
            await updateProjectIntegrationEntity(
                projectIntegration.id,
                entity.id,
                displayName,
                description,
                isCollectEnabled,
                isAddRecordsEnabled,
                turnOffSingleCollect,
                newEntityWebhookDefinition,
            ),
        );
    };

    // Callback to get if the entity name is unique.
    const isNameUnique = useCallback(
        (newName: string | undefined) => {
            return !entitiesSummary
                .filter((entitySummary) => entitySummary.id !== entity.id)
                .map((entitySummary) => entitySummary.displayName)
                .some((name) => name.trim() === newName?.trim() || '');
        },
        [entitiesSummary, entity.id],
    );

    const ProjectIntegrationEntitySchema = useMemo(() => {
        return Yup.object({
            displayName: Yup.string()
                .required('Entity Name is required')
                .max(255, "Entity name can't be longer than 255 characters")
                .test('uniqueName', 'Entity name already exists', isNameUnique),
            description: Yup.string(),
            isCollectEnabled: Yup.boolean().required(),
            isAddRecordsEnabled: Yup.boolean().required(),
            turnOffSingleCollect: Yup.boolean().required(),
            isLiveStreamEnabled: Yup.boolean().required(),
        });
    }, [isNameUnique]);

    return (
        <>
            <TitleWrapper>
                <H1 style={{ marginRight: '8px' }}>General</H1>
                <SavingIndicator loading={loading} error={error} />
            </TitleWrapper>

            <Paragraph $light>
                Edit general entity information and toggle the entity collect and webhook options.
            </Paragraph>

            <Formik
                initialValues={{
                    displayName: entity.displayName,
                    description: entity.description || '',
                    isCollectEnabled: entity.collectDefinition?.isEnabled || false,
                    isAddRecordsEnabled: entity.collectDefinition?.isAddRecordsEnabled || false,
                    turnOffSingleCollect: entity.collectDefinition?.turnOffSingleCollect || false,
                    isLiveStreamEnabled: !!entity.entityWebhookDefinition?.isEnabled,
                }}
                onSubmit={async (values) => {
                    await onSubmit(
                        values.displayName,
                        values.description,
                        values.isCollectEnabled,
                        values.isAddRecordsEnabled,
                        values.isLiveStreamEnabled,
                        values.turnOffSingleCollect,
                    );
                }}
                validationSchema={ProjectIntegrationEntitySchema}
            >
                <BodyWrapper>
                    <StyledField label="Display Name">
                        <Input placeholder="Insert Name" name="displayName" />
                    </StyledField>

                    <StyledField label="Description" showOptional>
                        <Textarea minRows={3} placeholder="Insert Description" name="description" />
                    </StyledField>

                    <StyledField label="Should Enable Collect? ">
                        <Toggle name="isCollectEnabled" />
                    </StyledField>
                    <StyledField label="Should Enable Webhooks? ">
                        <Toggle name="isLiveStreamEnabled" />
                    </StyledField>
                    {projectIntegration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE && (
                        <StyledField label="Should Enable Add Records Manually? ">
                            <Toggle name="isAddRecordsEnabled" />
                        </StyledField>
                    )}

                    {projectIntegration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE && (
                        <StyledField label="Should Turn Off Single Collect? ">
                            <Toggle name="turnOffSingleCollect" />
                        </StyledField>
                    )}
                    <FormikAutosave />
                </BodyWrapper>
            </Formik>
        </>
    );
};

export default ProjectIntegrationEntityGeneralPage;
