import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as GenericLogo } from '../../../../../../images/icons/generic-logo.svg';
import EnterpriseComponentPageTemplate from '../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import type { EnterpriseComponentMenuItemPageContentProps } from '../../../entities/EnterpriseComponentMenuItemPageContentProps';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    DragAndDropImagesModal,
    Field,
    FormikAutosave,
    Input,
    SavingIndicator,
    Textarea,
} from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType, TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName, PersonPermissionRoleType, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const MAX_INPUT_WIDTH = 490;

const GenericLogoIcon = styled(GenericLogo)`
    margin-bottom: 1px;
    margin-right: 5px;
`;

const BigProjectIntegrationItemIcon = styled.div<{ url?: string }>`
    ${({ url }) => css`
        width: 120px;
        height: 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 120px;
        background-image: url(${url});
        border: 1px solid ${Theme.current.palette.general.border};
        margin-bottom: 15px;
    `}
`;

const FormSizedInput = styled(Input)`
    width: ${MAX_INPUT_WIDTH}px;
    display: block;
`;

const FormSizedTextarea = styled(Textarea)`
    width: ${MAX_INPUT_WIDTH}px;
    display: block;
`;

const IconLabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const InputContainer = styled(Field)`
    margin-top: 30px;
`;

const ImportIconButton = styled(Button)`
    display: block;
`;

const ValidationNameSchema = Yup.object({ name: Yup.string().required('Must fill name') });
const ValidationDescriptionSchema = Yup.object({ description: Yup.string() });

const EnterpriseComponentPageInformationPage = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponent,
    enterpriseComponentType,
    projectId,
    setCurrentEnterpriseComponent,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    // Update description.
    const [
        { error: errorUpdatingDescription, loading: loadingUpdatingDescription },
        updateEnterpriseComponentDescription,
    ] = useLazyTonkeanService('updateEnterpriseComponentDescription');

    // Update display name.
    const [
        { error: errorUpdatingDisplayName, loading: loadingUpdatingDisplayName },
        updateEnterpriseComponentDisplayName,
    ] = useLazyTonkeanService('updateEnterpriseComponentDisplayName');

    const updateDisplayName = useCallback(
        (updatedDisplayName: string) => {
            if (updatedDisplayName?.length && enterpriseComponent) {
                return updateEnterpriseComponentDisplayName(projectId, enterpriseComponent.id, updatedDisplayName).then(
                    () => {
                        setCurrentEnterpriseComponent?.({
                            ...enterpriseComponent,
                            displayName: updatedDisplayName || enterpriseComponent.displayName,
                        });
                    },
                );
            }
        },
        [enterpriseComponent, projectId, setCurrentEnterpriseComponent, updateEnterpriseComponentDisplayName],
    );

    const updateDescription = useCallback(
        (updatedDescription: string) => {
            return updateEnterpriseComponentDescription(projectId, enterpriseComponent.id, updatedDescription).then(
                () => {
                    setCurrentEnterpriseComponent?.({
                        ...enterpriseComponent,
                        description: updatedDescription || enterpriseComponent.description,
                    });
                },
            );
        },
        [enterpriseComponent, projectId, setCurrentEnterpriseComponent, updateEnterpriseComponentDescription],
    );

    // Update icon.
    const [{ error: errorEnterpriseComponentIcon }, updateEnterpriseComponentIcon] = useLazyTonkeanService(
        'updateEnterpriseComponentIcon',
    );

    const updateIcon = useCallback(
        (updatedIconBuffer: Blob): Promise<string> => {
            if (updatedIconBuffer && enterpriseComponent) {
                return updateEnterpriseComponentIcon(projectId, enterpriseComponent.id, updatedIconBuffer).then(
                    (iconUrl) => {
                        setCurrentEnterpriseComponent?.({
                            ...enterpriseComponent,
                            iconUrl: iconUrl || enterpriseComponent.iconUrl,
                        });
                        return iconUrl;
                    },
                );
            }
            return Promise.resolve('');
        },
        [enterpriseComponent, projectId, setCurrentEnterpriseComponent, updateEnterpriseComponentIcon],
    );

    const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState<boolean>(false);

    const onSaveImage = async (files: [TonkeanUploadedFile]) => {
        if (files?.length === 1) {
            try {
                const newIconFile = files[0];
                if (!!newIconFile?.blob) {
                    await updateIcon(newIconFile.blob);
                }
            } finally {
                setIsUploadImageModalOpen(false);
            }
        }
    };

    const integrationType =
        enterpriseComponentType === TonkeanType.PROJECT_INTEGRATION && enterpriseComponent.integrationType;

    const canUserUpdateEnterpriseComponent = enterpriseComponent.accessType === PersonPermissionRoleType.ADMIN;

    return (
        <EnterpriseComponentPageTemplate
            name="Information"
            description={`Edit general information for the ${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}.`}
        >
            <Formik
                validationSchema={ValidationNameSchema}
                initialValues={{ name: enterpriseComponent.displayName }}
                onSubmit={(values) => updateDisplayName(values.name)}
            >
                <Field
                    label={
                        <>
                            Display name
                            <SavingIndicator loading={loadingUpdatingDisplayName} error={errorUpdatingDisplayName} />
                        </>
                    }
                    touchedOnlyErrors={false}
                    error={errorUpdatingDisplayName ? 'There was an error trying to update display name...' : undefined}
                >
                    <FormikAutosave />
                    <FormSizedInput
                        type="text"
                        autoComplete="off"
                        placeholder="Display name"
                        name="name"
                        disabled={!canUserUpdateEnterpriseComponent}
                    />
                </Field>
            </Formik>

            <Formik
                validationSchema={ValidationDescriptionSchema}
                initialValues={{ description: enterpriseComponent.description || '' }}
                onSubmit={(values) => updateDescription(values.description)}
            >
                <InputContainer
                    label={
                        <>
                            Description
                            <SavingIndicator loading={loadingUpdatingDescription} error={errorUpdatingDescription} />
                        </>
                    }
                    touchedOnlyErrors={false}
                    error={errorUpdatingDescription ? 'There was an error trying to update description...' : undefined}
                >
                    <FormikAutosave />
                    <FormSizedTextarea
                        minRows={4}
                        autoComplete="off"
                        placeholder="Description"
                        name="description"
                        disabled={!canUserUpdateEnterpriseComponent}
                    />
                </InputContainer>
            </Formik>

            {enterpriseComponent.isAllowUpdateIcon && (
                <InputContainer
                    error={errorEnterpriseComponentIcon ? 'There was an error trying to update icon...' : undefined}
                    touchedOnlyErrors={false}
                    label={
                        <IconLabelWrapper>
                            <GenericLogoIcon />
                            Data source icon
                        </IconLabelWrapper>
                    }
                >
                    {enterpriseComponent?.iconUrl && (
                        <BigProjectIntegrationItemIcon url={enterpriseComponent?.iconUrl} />
                    )}
                    <ImportIconButton
                        onClick={() => {
                            setIsUploadImageModalOpen(true);
                        }}
                        disabled={!canUserUpdateEnterpriseComponent}
                        outlined
                    >
                        {enterpriseComponent?.iconUrl ? 'Update icon image' : 'Upload icon image'}
                    </ImportIconButton>
                </InputContainer>
            )}

            <DragAndDropImagesModal
                isUploadImageModalOpen={isUploadImageModalOpen}
                onSave={onSaveImage}
                onCancel={() => setIsUploadImageModalOpen(false)}
            />
        </EnterpriseComponentPageTemplate>
    );
};

export default EnterpriseComponentPageInformationPage;
