import React from 'react';
import styled from 'styled-components';

import { Separator, Toggle } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Title = styled.div`
    font-weight: 700;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
`;

const TableTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
    margin-top: 12px;
`;

const Entities = styled.div`
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
`;

const EntityRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${FontSize.SMALL_12};
    font-weight: 700;
    color: ${Theme.colors.gray_700};
    padding: 8px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

interface Props {
    entityTypes: string[];
    entityTypeToInboundStatus: Record<string, boolean>;
    onChange: (params: Record<string, boolean>) => void;
}

const IntegrationEntitiesInboundConfiguration: React.FC<Props> = ({
    entityTypes,
    entityTypeToInboundStatus,
    onChange,
}) => {
    return (
        <>
            <Separator $margin="20px" />
            <Title>Select the data source entities you would like to collect and monitor:</Title>
            <TableTitle>
                <div>Entity</div>
                <div>Collect & Monitor</div>
            </TableTitle>
            <Entities>
                {entityTypes.map((entityType) => (
                    <EntityRow key={entityType}>
                        <div>{entityType}</div>
                        <Toggle
                            onChange={({ target: { checked } }) =>
                                onChange({
                                    ...entityTypeToInboundStatus,
                                    [entityType]: checked,
                                })
                            }
                            checked={entityTypeToInboundStatus[entityType]}
                            size={InputSize.MEDIUM}
                        />
                    </EntityRow>
                ))}
            </Entities>
        </>
    );
};

export default IntegrationEntitiesInboundConfiguration;
