import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';

import FormBuilderEditableTitle from './FormBuilderEditableTitle';
import { ReactComponent as CreateFormIcon } from '../../../../images/icons/create-form.svg';
import { ReactComponent as UpdateFormIcon } from '../../../../images/icons/update-form.svg';

import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FormType } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.colors;

const DisplayNameBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    grid-area: header;

    border-bottom: 1px solid ${theme.gray_300};
`;

const DisplayNameWrapper = styled.span`
    width: 100%;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FormTypeDisplayName = styled.span`
    font-size: 20px;
    line-height: 23px;
    color: ${theme.gray_500};
    min-width: fit-content;
    margin-right: 4px;
`;

const FormTypeIcon = styled.div`
    margin-right: 10px;
    width: 24px;
    height: 24px;

    svg {
        height: 24px;
        width: 24px;
    }
`;

interface Props {
    formType: FormType | undefined;
    form: Form | undefined;
    onFormChanged: Dispatch<SetStateAction<Form>>;
    workflowVersionId: string;
}

const FormBuilderDisplayNameBar: React.FC<Props> = ({ formType, form, onFormChanged, workflowVersionId }) => {
    const { setSectionLoading } = useContext(FormBuilderContext);

    const onChange = useCallback(
        (value) => {
            onFormChanged((prevForm) => ({ ...prevForm, displayName: value }));
            setSectionLoading(FormBuilderSections.DisplayName);
        },
        [onFormChanged, setSectionLoading],
    );

    const displayName = useMemo(() => {
        if (form) {
            return `${formType === FormType.CREATE ? 'Create' : 'Update'} Form:`;
        } else {
            return 'Unpublished Form';
        }
    }, [form, formType]);

    return (
        <DisplayNameBarWrapper>
            <DisplayNameWrapper>
                <FormTypeIcon>
                    {(!form || form.formType === FormType.CREATE) && (
                        <span className="tnk-icon">
                            <CreateFormIcon />
                        </span>
                    )}
                    {form?.formType === FormType.UPDATE && (
                        <span className="tnk-icon">
                            <UpdateFormIcon />
                        </span>
                    )}
                </FormTypeIcon>
                <FormTypeDisplayName data-automation="form-builder-display-name-bar-form-type">
                    {displayName}
                </FormTypeDisplayName>

                {form && (
                    <FormBuilderEditableTitle
                        value={form.displayName}
                        onChange={onChange}
                        workflowVersionId={workflowVersionId}
                        formId={form.id}
                    />
                )}
            </DisplayNameWrapper>
        </DisplayNameBarWrapper>
    );
};

export default FormBuilderDisplayNameBar;
