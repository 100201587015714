<div class="external-statuses-buttons-container">
    <!-- Error loading statuses -->
    <div
        ng-if="data.errorLoadingAvailableExternalStatuses && !data.loadingAvailableExternalStatuses"
        class="common-color-danger common-size-xxs"
    >
        There was an error trying to get external statuses.
        <a ng-click="loadExternalStatuses()">Reload</a>
    </div>

    <!-- External statuses buttons -->
    <div
        ng-if="!data.loadingAvailableExternalStatuses && !data.errorLoadingAvailableExternalStatuses"
        class="flex-vmiddle flex-wrap margin-bottom-xlg"
    >
        <div
            ng-repeat="externalStatus in data.externalStatuses"
            class="margin-right-xs margin-bottom-xs"
            ng-click="selectExternalStatus(externalStatus)"
        >
            <button
                class="btn btn-default common-btn-active-no-style"
                ng-class="{
                    'external-status-button-selected': data.selectedExternalStatus.statusId === externalStatus.statusId
                }"
            >
                {{ externalStatus.displayName }}
            </button>
        </div>
    </div>

    <!-- Remember my choice -->
    <div class="flex-vmiddle">
        <!-- Finish button -->
        <button
            class="btn btn-primary margin-right-xs"
            ng-click="updateInitiativeExternalStatus(initiative.id)"
            ng-if="data.selectedExternalStatus"
        >
            Update in {{ initiative.externalSourceFriendly }}
        </button>

        <!-- Skip -->
        <a
            ng-click="nextFollowUpStep()"
            ng-if="!data.selectedExternalStatus"
            class="common-color-link-blue common-bold"
            ng-class="{ 'margin-left': data.selectedExternalStatus }"
        >
            No, thanks!
        </a>

        <!-- Separator -->
        <div class="flex-grow"></div>

        <!-- Don't ask me again -->
        <a class="flex-no-shrink common-color-grey" ng-click="doNotAskAgain()">Don't ask me again</a>
    </div>

    <!-- Loading and error states -->
    <div class="margin-top">
        <!-- Loading state for update external status -->
        <div
            ng-if="
                (data.updatingExternalStatus || data.savingDontAskAgain) &&
                !(data.errorUpdatingExternalStatus || data.errorSavingDontAskAgain)
            "
            class="flex-vmiddle"
        >
            <i class="loading-small margin-right-xs"></i>
            <span>Saving...</span>
        </div>

        <!-- Error state for update external status -->
        <div
            ng-if="
                !(data.updatingExternalStatus || data.savingDontAskAgain) &&
                (data.errorUpdatingExternalStatus || data.errorSavingDontAskAgain)
            "
            class="common-color-danger"
        >
            There was an error trying to save changes.
        </div>
    </div>
</div>
