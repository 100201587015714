<div uib-dropdown>
    <button
        type="button"
        class="btn btn-default common-no-outline dropdown-toggle"
        uib-dropdown-toggle
        ng-disabled="data.disableSelection"
    >
        <span data-automation="display-format-selector-open-format-drop-down" class="flex-vmiddle">
            <span class="flex-grow text-left margin-right">{{ data.selectedDisplayFormat.label }}</span>
            <span class="dropdown-chevron flex-no-shrink"></span>
        </span>
    </button>
    <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
        <li
            data-automation="display-format-selector-option"
            ng-repeat="displayFormat in data.displayFormats"
            ng-click="onDisplayFormatSelected(displayFormat, null, true)"
        >
            <a>{{ displayFormat.label }}</a>
        </li>
    </ul>
</div>
