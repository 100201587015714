import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function EnterpriseBillingCtrl(
    $scope,
    environment,
    $timeout,
    $log,
    $q,
    tonkeanService,
    utils,
    authenticationService,
) {
    let stripe;

    $scope.data = {
        enterprise: authenticationService.currentUser.enterprise,
        enterpriseId: authenticationService.currentUser.enterprise.id,
        accountHolderType: 'company',
        toggles: {},
        loadingState: {},
    };

    $scope.init = function () {
        $scope.loading = true;

        loadStripe();

        // $scope.getStats();

        $scope
            .loadLicence()
            .then($scope.loadInvoices)
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    };

    $scope.loadLicence = function () {
        return tonkeanService.getProjectLicense($scope.data.enterpriseId).then(function (data) {
            $scope.license = data.license;

            return $q.resolve($scope.license);
        });
    };

    $scope.loadInvoices = function () {
        return tonkeanService.getProjectInvoices($scope.data.enterpriseId).then(function (data) {
            $scope.invoices = data.invoices;

            if (!$scope.invoices) {
                $scope.invoices = [];
            }

            // Add the upcoming invoice as the first invoice (so it's first in the list).
            if ($scope.license && $scope.license.upcomingInvoice && $scope.license.upcomingInvoice.lines.length) {
                // Maybe use $scope.license.upcomingInvoice.nextPaymentAttempt as the period dates?
                $scope.license.upcomingInvoice.id = $scope.data.upcomingInvoiceId;

                $scope.license.upcomingInvoice.upcoming = true;

                // build periods
                $scope.license.upcomingInvoice.periods = {};
                for (let i = 0; i < $scope.license.upcomingInvoice.lines.length; i++) {
                    const line = $scope.license.upcomingInvoice.lines[i];
                    const token = getSimpleDate(line.periodStart) + getSimpleDate(line.periodEnd);
                    if (!$scope.license.upcomingInvoice.periods[token]) {
                        $scope.license.upcomingInvoice.periods[token] = {
                            periodStart: line.periodStart,
                            periodEnd: line.periodEnd,
                            lines: [],
                        };
                    }

                    $scope.license.upcomingInvoice.periods[token].lines.push(line);
                }

                $scope.invoices.unshift($scope.license.upcomingInvoice);
            }

            return $q.resolve($scope.invoices);
        });
    };

    $scope.setupAch = function () {
        if (
            $scope.data.acceptTerms &&
            $scope.loadingStripeComplete &&
            $scope.data.routingNumber &&
            $scope.data.accountNumber &&
            $scope.data.accountHolder
        ) {
            const info = {
                country: 'US',
                currency: 'usd',
            };

            info['routing_number'] = $scope.data.routingNumber.toString();
            info['account_number'] = $scope.data.accountNumber.toString();
            info['account_holder_name'] = $scope.data.accountHolder;
            info['account_holder_type'] = $scope.data.accountHolderType;

            $scope.savingBillingInfo = true;

            submitStripeBankAccount(info)
                .then(function (token) {
                    return tonkeanService.addEnterpriseBankAccount($scope.data.enterpriseId, token.id);
                })
                .then(function () {
                    $scope.closeSetupAch();
                    $scope.$emit('alert', { msg: 'Submitted successfully.', type: 'success' });
                    $scope.init();
                })
                .catch(function (error) {
                    $scope.billingFormError = error;
                })
                .finally(function () {
                    $scope.savingBillingInfo = false;
                });
        }
    };

    $scope.getStats = function (showLoading) {
        if (showLoading) {
            $scope.loadingStats = true;
        }

        return tonkeanService
            .getWorkerRunsStats($scope.data.enterpriseId, null, false, true, false, false, true)
            .then(function (data) {
                $scope.data.stats = data.billingReportedUsage;

                return $q.resolve($scope.data.stats);
            })
            .finally(function () {
                $scope.loadingStats = false;
            });
    };

    $scope.closeSetupAch = function () {
        $scope.data.showAch = false;
        $scope.data.routingNumber = null;
        $scope.data.accountNumber = null;
    };

    $scope.init();

    function getSimpleDate(value) {
        const date = new Date(value);
        const mm = date.getMonth() + 1; // getMonth() is zero-based
        const dd = date.getDate();

        return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('');
    }

    function loadStripe() {
        // If the Stripe script is not already loaded, we load it while showing the user a loading circle.
        // This shouldn't happen if the user doesn't load this page specifically (using a url) because Stripe
        // is already loaded in the prior upper organization page (product.license state).
        /* jshint newcap: false */
        if (typeof Stripe === 'undefined' || Stripe === undefined || Stripe === null) {
            utils.loadScript('https://js.stripe.com/v3/', function () {
                stripe = Stripe(environment.apps.stripeAppId);
                // addStripeElements(stripe);
                $scope.loadingStripeComplete = true;

                $log.info('Stripe loaded');
            });
        } else {
            stripe = Stripe(environment.apps.stripeAppId);
            // addStripeElements(stripe);
            $scope.loadingStripeComplete = true;
        }
    }

    function submitStripeBankAccount(info) {
        return $q(function (resolve, reject) {
            stripe.createToken('bank_account', info).then(function (result) {
                // If we got an error, show an error message to the user.
                if (result.error) {
                    reject(result.error.message);
                } else {
                    // Otherwise, we succeeded! Send the token down the flow.
                    resolve(result.token);
                }
            });
        });
    }
}

export default angular.module('tonkean.app').controller('EnterpriseBillingCtrl', lateConstructController(EnterpriseBillingCtrl));
