import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const MultiEntitySelectorEntityDescription = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: 10px;
`;

export default MultiEntitySelectorEntityDescription;
