import type { valueOf } from './typescript/valueOf';

type CompareFunc<T> = (currentValue: T) => boolean;
type CompareFunctionsObject<T extends Record<string, any>> = { [KEY in keyof T]: CompareFunc<T[KEY]> };

/**
 * Generate a compare function based on the initial value. If the given value is an array, it will run this method over
 * each item in the array. If the given value is an object with id, it will compare the ids. It it's a date, it will
 * compare timestamps. If it's a string, number, boolean or anything else, it will compare by soft compare.
 *
 * @param initialValue - the initial value.
 * @returns a compare function that will match best the type of value based on the given initial value.
 */
function getCompareFunc<T>(initialValue: T): CompareFunc<T> {
    // If it's an array, compare lengths and then use getCompareFunc to make sure all items in the initial value are in
    // the current value.
    if (Array.isArray(initialValue)) {
        return (currentValue: T & any[]) => {
            if (currentValue?.length !== initialValue.length) {
                return false;
            }

            return initialValue.every((initialItem) => {
                const compareFunc = getCompareFunc(initialItem);
                return currentValue?.some((currentItem) => compareFunc(currentItem));
            });
        };
    }

    // If it's an object and the initial object has an id, compare by the id.
    if (initialValue && typeof initialValue === 'object' && 'id' in initialValue) {
        return (currentValue: T) => currentValue?.['id'] === initialValue['id'];
    }

    // If it's a date, compare the timestamps. If you don't care about the time, use setHours when updating the filter
    // object. This is a best practice regardless of this function, as it will also make sure that you get the entire
    // date based on the user's timestamp.
    if (initialValue instanceof Date) {
        const timestamp = initialValue.getTime();

        return (currentValue: T & Date) => currentValue?.getTime() === timestamp;
    }

    // If it's something else, compare it regularly (if it's string or number they are compared by value, if it's an
    // object or a symbol it's compared by reference).
    // We intentionally use weak compare, so "1" will match 1, and "" will match undefined.
    // eslint-disable-next-line eqeqeq
    return (currentValue: T) => currentValue == initialValue;
}

/**
 * Helper function to manage filters. It helps check if had changed and count the changes. It allows to provide a
 * compare function to compare values, but it can generate a "smart" compare function based on the type of the
 * initial filters.
 *
 * @example
 * const initialFilters = {
 *     users: [{id: "abc", name: "john"}],
 *     fromDate: new Date(),
 *     toDate: undefined,
 * };
 *
 * const ourFiltersHelper = filtersHelper(
 *     initialFilters,
 *     {
 *         fromDate(current, initial) {
 *             return current.setHours(0, 0, 0, 0) === initial.setHours(0, 0, 0, 0) });
 *         },
 *     },
 * );
 *
 * const changesCount = ourFiltersHelper.changesCount(
 *     {
 *         users: [{ id: "abc", name: "other name" }],
 *         fromDate: new Date(Date.now() + 1000),
 *         toDate: null,
 *     },
 * );
 * // changesCount will be 0, because in the array, the id of the user matches the id of the user in the initial
 * filters, the to date is null and it weakly compares to undefined, and the fromDate ignores the time and uses only
 * the day because of the provided object in compareFunctionsObject.
 *
 * @param initialFilters - the initial filters object.
 * @param compareFunctionsObject - an optional compare object. The key is the same as in the initial filters, and the
 * value is a predicate that accepts the current value and the initial value, and returns true if it's the same,
 * otherwise false. You don't need to provide all, if some or all are missing they will be auto generated.
 * @returns an object with a functions to check if had changes, and to count changes, both accept the current filters
 * object.
 */
function filtersHelper<T extends {}>(
    initialFilters: T,
    compareFunctionsObject: Partial<CompareFunctionsObject<T>> = {},
) {
    const initialFilterEntries = Object.entries(initialFilters) as [keyof T, valueOf<T>][];

    // For each key in the initial filters, check if it has a compare function fromm the compareFunctionsObject prop,
    // and if not, use getCompareFunc to generate one.
    const compareFunctionsToUse = Object.fromEntries(
        initialFilterEntries.map(([key, value]) => {
            const compareFunction = compareFunctionsObject[key] ?? getCompareFunc(value);
            return [key, compareFunction];
        }),
    ) as CompareFunctionsObject<T>;

    // This predicate will return the result of the compare function based on the given current filters.
    const predicate = (currentFilters: T) => {
        return (key: keyof T): boolean | number => {
            const compareFunction = compareFunctionsToUse[key] ?? getCompareFunc(initialFilters[key]);
            return !compareFunction(currentFilters[key]);
        };
    };

    const initialKeys = new Set(initialFilterEntries.map(([key]) => key));
    const getCompareKeys = (currentFilters: T) => {
        return [...new Set([...initialKeys, ...Object.keys(currentFilters)])];
    };

    return {
        /**
         * Check if some of the filters had changed.
         *
         * @param currentFilters - the current filters object.
         * @returns true if some changes, false if not.
         */
        hadChanged(currentFilters: T): boolean {
            return getCompareKeys(currentFilters).some(predicate(currentFilters));
        },
        /**
         * Count the changed filters.
         * If all you need is to check if the filters had changes, use `hadChanged`, because unlike `changesCount`, it
         * will stop the loop after the first changed item and won't go over all filters, so it's more preformat.
         *
         * @param currentFilters - the current filters object.
         * @returns the amount of changed filters.
         */
        changesCount(currentFilters: T): number {
            return getCompareKeys(currentFilters).filter(predicate(currentFilters)).length;
        },
    };
}

export default filtersHelper;
