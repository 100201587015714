import template from './tnkSubmitFormButton.template.html?angularjs';

angular.module('tonkean.app').component('tnkSubmitFormButton', {
    bindings: {
        label: '<',
        primaryColor: '<',
        secondaryColor: '<',
        buttonsColor: '<',
        formFinished: '<',
        allErrorsNotRestrictingErrors: '<',
        disabled: '<', // Whether to enable submitting the form

        onSubmit: '&', // Callback to the form submit
    },
    template,
    controller: 'SubmitFormButtonCtrl',
});
