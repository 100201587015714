import template from './statusPopoverWrapper.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkStatusPopoverWrapper', {
    bindings: {
        editorId: '<', // The editor id that this wrapper is inside of.
        containerIdPrefix: '<', // The prefix for the container id of the popover (format is: prefix-initiativeId-editorId
        emptyContainerId: '<', // The container id for the empty state

        viewOnlyMode: '<', // Is the editor in viewOnlyMode
        classes: '@',
        placement: '<',

        itemId: '<', // The initiative id who's data to display, changing this will result in a change in popover.
        workflowVersionId: '<',
        initiativeIdRetriever: '&', // In case the item id is no an initiative id use this to match itemId to initiativeId.
    },
    template,
    controller: 'StatusPopoverWrapperCtrl',
});
