import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { LoadingCircle, Toggle, Tooltip, useToggle } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div<{ disabled: boolean }>`
    ${({ disabled }) => disabled && 'opacity: 0.4;'}
`;

const Title = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    font-weight: 500;
    margin-bottom: 9px;
    line-height: 1.1;
    letter-spacing: 0.3px;
`;

const Description = styled.span`
    font-size: ${FontSize.MSMALL_13};
    color: ${Theme.colors.gray_500};
    font-weight: 300;
    white-space: pre-wrap;
`;

const SettingLabel = styled.label`
    font-size: ${FontSize.MSMALL_13};
    line-height: 1.1;
    font-weight: 300;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 6px;
`;

const ToggleWrapper = styled.div`
    display: flex;
`;

const CenteredLoader = styled(LoadingCircle)`
    margin: auto;
`;

interface Props {
    description: string;
    isSupportUserPermitted: boolean;
    onToggle: (newValue: boolean) => Promise<any>;
    loading?: boolean;
    disabled?: boolean;
    disabledTooltip?: string;
}

const SupportUserSettingsToggle: React.FC<Props> = ({
    description,
    isSupportUserPermitted,
    onToggle: onChange,
    loading = false,
    disabled = false,
    disabledTooltip = '',
}) => {
    const [accessGranted, toggleAccessGranted] = useToggle(isSupportUserPermitted);

    const onToggle = useCallback(() => {
        // Calling on change and only if it succeed we can safely update the toggle view
        onChange(!accessGranted).then(toggleAccessGranted);
    }, [accessGranted, onChange, toggleAccessGranted]);

    useEffect(() => {
        if (isSupportUserPermitted !== accessGranted) {
            toggleAccessGranted();
        }
        // we only want to run the effect when the `isSupportUserPermitted` is changed from outside.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSupportUserPermitted]);

    return (
        <Wrapper disabled={disabled}>
            <Title>Support Access</Title>
            <Description>{description}</Description>
            <SettingLabel>
                Tonkean Support Remote Access
                <Tooltip content={disabledTooltip} disabled={!disabled}>
                    <ToggleWrapper>
                        {loading && <CenteredLoader />}

                        <Toggle
                            checked={accessGranted}
                            size={InputSize.MEDIUM}
                            onChange={onToggle}
                            disabled={disabled}
                            showInnerLabel
                        />
                    </ToggleWrapper>
                </Tooltip>
            </SettingLabel>
        </Wrapper>
    );
};

export default SupportUserSettingsToggle;
