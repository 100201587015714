function WorkflowVersionChangesModalCtrl(
    $scope,
    $q,
    $timeout,
    $uibModalInstance,
    tonkeanService,
    customTriggerManager,
    groupManager,
    projectManager,
    workflowVersionManager,
    customFieldsManager,
    groupId,
    workflowVersionId,
    modalTitleContent,
    modalSubtitleContent,
    targetTypeId,
    groupByToggleDefault,
    hideGroupByToggle,
    entityVersionId,
    subSequentialIdentifier,
) {
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        groupId,
        workflowVersionId,
        modalTitleContent,
        modalSubtitleContent,

        activityActorSelectionPopoverOpen: false,
        selectedActorPeopleToFilterOn: [],
        workerAuditLogsControlObject: {},

        onlyActivityTypes: workflowVersionManager.getWorkflowRelevantActivityTypes(),
        targetTypeId,
        groupByToggleDefault,
        hideGroupByToggle,
        entityVersionId,
        subSequentialIdentifier,
    };

    /**
     * Initialization function for the modal.
     */
    $scope.init = function () {};

    $scope.init();

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Occurs once the person added.
     */
    $scope.onActorPersonAdded = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    /**
     * Opens the activity actor person selection popover.
     */
    $scope.openActivityActorPersonSelection = function () {
        $scope.data.activityActorSelectionPopoverOpen = true;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-container'));

        ngContainer.append(ngPopoverElement);

        // This is important to make the popover show right after the first click, and not have to double click the link for it to show.
        // Our theory here is that appending the element of the popover requires a digest loop to be rendered, and by calling timeout
        // we trigger such digest loop and the popover gets displayed.
        $timeout(() => {});
    };

    /**
     * Closes the popover for the actor selection.
     */
    $scope.resetActivityActorSelectionPopover = function () {
        $scope.data.activityActorSelectionPopoverOpen = false;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-parent'));

        ngContainer.append(ngPopoverElement);
    };

    /**
     * Occurs once the person removed.
     */
    $scope.onActorPersonRemoved = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };
}

export default angular.module('tonkean.app').controller('WorkflowVersionChangesModalCtrl', WorkflowVersionChangesModalCtrl);
