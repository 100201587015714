import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionHttpRequestView from './ProjectIntegrationActionHttpRequestView';
import ProjectIntegrationActionSqlRequestView from './ProjectIntegrationActionSqlRequestView';
import ProjectIntegrationActionSqlResponseView from './ProjectIntegrationActionSqlResponseView';
import ProjectIntegrationActionTestEntityParamsView from './ProjectIntegrationActionTestEntityParamsView';
import ProjectIntegrationActionTestParamsView from './ProjectIntegrationActionTestParamsView';
import TestPageHttpResponseView, { SubHeader } from './TestPageHttpResponseView';
import { keyValueName } from '../../../../../../utils/keyValueName';
import { ProjectIntegrationActionConstantParams } from '../../../projectIntegrationActionConstantParamIdToConfig';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ModalBody, ModalHeader, XCloseButton } from '@tonkean/infrastructure';
import type {
    BaseActionParameter,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionTestRunHTTPResponse,
} from '@tonkean/tonkean-entities';
import {
    ActionDefinitionType,
    HttpMethodType,
    ProjectIntegrationActionDefinitionType,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY, getStateError } from '@tonkean/utils';

const ModalTitle = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    font-weight: 400;
    display: inline-flex;
    line-height: 28px;
    vertical-align: center;
    width: 100%;
    align-items: center;
`;

const LeftSection = styled(ModalBody)`
    border-right: 1px solid ${Theme.current.palette.mainColors.gray_400};
    padding-top: 0;
`;

const RightSection = styled(ModalBody)`
    padding-top: 0;
`;

const CloseButton = styled(XCloseButton)`
    margin-left: auto;
`;

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow: auto;
    height: 100%;
`;

const ProjectIntegrationActionTestPageHeader = styled(ModalHeader)`
    height: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
`;

const Divider = styled.hr`
    width: 100%;
    margin: 20px 0;
`;

const RunTestButton = styled(Button)`
    margin-top: 30px;
`;

const LoadingIndicator = styled.i`
    margin-left: 15px;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
    font-size: ${FontSize.XSMALL_10};
    display: inline;
    margin-left: 15px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    onCancel: () => void;
    paramIdToParamInputValueRecord: Record<string, string>;
    setParamIdToParamInputValueRecord: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    constantParameters: BaseActionParameter[];
}

const ProjectIntegrationActionTestPage: React.FC<Props> = ({
    projectIntegrationAction,
    projectIntegration,
    onCancel,
    paramIdToParamInputValueRecord,
    setParamIdToParamInputValueRecord,
    constantParameters,
}) => {
    const { data: projectIntegrationActionParams } = useTonkeanService(
        'extractProjectIntegrationActionParams',
        projectIntegration.id,
        projectIntegrationAction.actionDefinition,
        projectIntegrationAction.parametersDefinition.entitiesToRunOn || EMPTY_ARRAY,
    );

    const {
        data: evaluateProjectIntegrationTestActionResponse,
        loading: isLoadingEvaluateProjectIntegrationTestActionResponse,
        error: errorEvaluateProjectIntegrationTestAction,
    } = useTonkeanService(
        'evaluateProjectIntegrationTestAction',
        projectIntegration.id,
        projectIntegrationAction.actionDefinition,
        paramIdToParamInputValueRecord,
        projectIntegrationAction.parametersDefinition.parameters,
    );

    // Getting the project integration api url
    const { data: apiBaseUrlObject } = useTonkeanService('getIntegrationApiBaseUrl', projectIntegration.id);

    // Adding all the values for the constant params in the action
    useEffect(() => {
        if (apiBaseUrlObject) {
            setParamIdToParamInputValueRecord((prev) => {
                const envUrlParam: Record<string, string> = {
                    [ProjectIntegrationActionConstantParams.ENV_URL]: apiBaseUrlObject.apiBaseUrl,
                    [ProjectIntegrationActionConstantParams.WEBHOOK_URL]: apiBaseUrlObject.incomingWebhookUrl,
                };
                return { ...prev, ...envUrlParam };
            });
        }
    }, [apiBaseUrlObject, setParamIdToParamInputValueRecord]);

    // When projectIntegrationActionParams changes, we extract all of it parameters (=new ids) and construct a dictionary between the new ids to an empty string.
    // Server must get all the keys... without them he wont evaluate properly.
    useEffect(() => {
        if (projectIntegrationActionParams) {
            const allParameters = [
                ...(projectIntegrationAction.parametersDefinition?.parameters || []),
                ...projectIntegrationActionParams.entityParameters,
            ];

            const entriesOfNewParams: [string, string][] = allParameters.map((param) => {
                return [param.id, ''];
            });

            const paramIdToEmptyValue = Object.fromEntries(entriesOfNewParams);
            setParamIdToParamInputValueRecord((oldValues) => ({ ...paramIdToEmptyValue, ...oldValues }));
        }
    }, [
        projectIntegrationAction.parametersDefinition?.parameters,
        projectIntegrationActionParams,
        setParamIdToParamInputValueRecord,
    ]);

    const [
        {
            data: projectIntegrationActionTestResponse,
            loading: isRunningProjectIntegrationActionTest,
            error: projectIntegrationActionTestError,
        },
        runProjectIntegrationActionTest,
    ] = useLazyTonkeanService('runProjectIntegrationActionTest');

    // Indicates whether we have parameters or not.
    const shouldShowDivider = !!projectIntegrationAction?.parametersDefinition?.entitiesToRunOn.length;

    // Indicates the response error.
    // Error text will exist when unexpected error is thrown in our server or when the response contains error and no response data.
    const errorText: string | undefined = useMemo(() => {
        if (
            projectIntegrationActionTestResponse?.type === ProjectIntegrationActionDefinitionType.SQL &&
            projectIntegrationActionTestResponse.error
        ) {
            return projectIntegrationActionTestResponse.error;
        } else if (projectIntegrationActionTestError) {
            return getStateError(projectIntegrationActionTestError);
        } else if (
            projectIntegrationActionTestResponse?.type === ProjectIntegrationActionDefinitionType.HTTP &&
            projectIntegrationActionTestResponse?.error &&
            !projectIntegrationActionTestResponse?.responseCode
        ) {
            return projectIntegrationActionTestResponse?.error;
        }
    }, [projectIntegrationActionTestResponse, projectIntegrationActionTestError]);

    const allActionParameters = useMemo<BaseActionParameter[]>(() => {
        const params = projectIntegrationAction.parametersDefinition?.parameters || [];
        if (
            projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP &&
            projectIntegrationAction.actionDefinition.methodType === HttpMethodType.POST &&
            projectIntegrationAction.actionDefinition.enableKeyValueParameters
        ) {
            const keyValueParam = {
                displayName: keyValueName,
                id: keyValueName,
                parameterType: 'TONKEAN_EXPRESSION',
            } as BaseActionParameter;
            return [...params, keyValueParam];
        }
        return params;
    }, [projectIntegrationAction]);

    return (
        <>
            <ProjectIntegrationActionTestPageHeader $shadow>
                <ModalTitle>
                    Test Action
                    <CloseButton
                        size={ButtonSize.MEDIUM}
                        onClick={onCancel}
                        data-automation="project-integration-action-test-page-close-button"
                    />
                </ModalTitle>
            </ProjectIntegrationActionTestPageHeader>

            <ContentWrapper>
                <LeftSection>
                    <SubHeader>Action Params</SubHeader>

                    <ProjectIntegrationActionTestEntityParamsView
                        onChange={setParamIdToParamInputValueRecord}
                        entityParams={projectIntegrationActionParams?.entityParameters || []}
                        parameterIdToParameterValueRecord={paramIdToParamInputValueRecord}
                        projectIntegration={projectIntegration}
                    />

                    {shouldShowDivider && <Divider />}

                    <ProjectIntegrationActionTestParamsView
                        onChange={setParamIdToParamInputValueRecord}
                        actionParams={allActionParameters}
                        constantParameters={constantParameters}
                        parameterIdToParameterValueRecord={paramIdToParamInputValueRecord}
                    />
                </LeftSection>

                <RightSection>
                    <SubHeader>
                        Send Request
                        <ErrorText>
                            {errorEvaluateProjectIntegrationTestAction && (
                                <>
                                    Unable to show preview, Reason -{' '}
                                    {getStateError(errorEvaluateProjectIntegrationTestAction)}
                                </>
                            )}
                        </ErrorText>
                    </SubHeader>

                    {projectIntegrationAction.actionDefinition?.definitionType === ActionDefinitionType.HTTP && (
                        <ProjectIntegrationActionHttpRequestView
                            previewEvaluatedResponse={evaluateProjectIntegrationTestActionResponse}
                            isLoading={isLoadingEvaluateProjectIntegrationTestActionResponse}
                            actionDefinition={projectIntegrationAction.actionDefinition}
                        />
                    )}

                    {projectIntegrationAction.actionDefinition?.definitionType === ActionDefinitionType.SQL && (
                        <ProjectIntegrationActionSqlRequestView
                            evaluatedRequest={evaluateProjectIntegrationTestActionResponse?.query}
                        />
                    )}

                    <RunTestButton
                        data-automation="project-integration-action-test-page-run-test-button"
                        onClick={() =>
                            runProjectIntegrationActionTest(
                                projectIntegration.id,
                                projectIntegrationAction?.id || '',
                                projectIntegrationAction,
                                paramIdToParamInputValueRecord,
                            )
                        }
                    >
                        Run Test
                    </RunTestButton>

                    {isRunningProjectIntegrationActionTest && <LoadingIndicator className="loading-small" />}

                    {errorText && <ErrorText>{errorText}</ErrorText>}

                    {projectIntegrationAction.actionDefinition?.definitionType === ActionDefinitionType.HTTP && (
                        <TestPageHttpResponseView
                            isTestRunning={isRunningProjectIntegrationActionTest}
                            response={
                                projectIntegrationActionTestResponse as ProjectIntegrationActionTestRunHTTPResponse
                            }
                        />
                    )}

                    {projectIntegrationAction.actionDefinition?.definitionType === ActionDefinitionType.SQL && (
                        <ProjectIntegrationActionSqlResponseView
                            response={projectIntegrationActionTestResponse?.body}
                        />
                    )}
                </RightSection>
            </ContentWrapper>
        </>
    );
};

export default ProjectIntegrationActionTestPage;
