import InboxItemActions from '../actions/InboxItemActions';

function BotHistoryHelper(tonkeanService, trackHelper, utils) {
    const _this = this;

    _this.itemMap = {};

    _this.loadBotHistory = function (projectId, groupId, ownerId, searchText, before, after, limit) {
        return tonkeanService
            .getGatherHistory(projectId, groupId, ownerId, searchText, before, after, limit)
            .then((data) => {
                // Cache newly retrieved initiatives
                const initiatives = utils
                    .objToArray(data.relatedEntities)
                    .filter((kvp) => kvp.key.slice(0, 4) === 'INIT')
                    .map((kvp) => kvp.value);
                trackHelper.cacheItemList(initiatives);

                for (let i = 0; i < data.entities.length; i++) {
                    const item = data.entities[i];
                    _this.itemMap[item.id] = item;
                }

                return data;
            });
    };

    _this.loadMyInbox = function (projectId, before, after, limit) {
        return tonkeanService.getInbox(projectId, before, after, limit).then((data) => {
            // Cache newly retrieved initiatives
            trackHelper.cacheItemList(data.entities);

            for (let i = 0; i < data.inbox.length; i++) {
                const item = data.inbox[i];
                _this.itemMap[item.id] = item;
            }

            return data;
        });
    };

    _this.getItem = function (id) {
        return _this.itemMap[id];
    };

    _this.setItem = function (item) {
        _this.itemMap[item.id] = item;
    };

    _this.reloadItem = function (id) {
        InboxItemActions.toggleLoading(id, true);
        tonkeanService
            .getGatherHistoryById(id)
            .then((gatherHistory) => {
                _this.setItem(gatherHistory);
            })
            .finally(() => {
                InboxItemActions.toggleLoading(id, false);
            });
    };
}

export default angular.module('tonkean.app').service('botHistoryHelper', BotHistoryHelper);
