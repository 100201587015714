import template from './tnkVideoCarousel.template.html?angularjs';

/**
 * Component description goes here
 */
export default angular.module('tonkean.app').component('tnkVideoCarousel', {
    bindings: {
        videos: '<',
    },
    template,
    controller: 'VideoCarouselCtrl',
});
