import type { IController, IOnChangesObject } from 'angular';

interface Scope {
    tags: string[];
    onTagsChanged(tags: string[]): void;
    disabled: boolean;
}

class MultipleInputCtrl implements IController {
    /* @ngInject */

    public declare tags: { text: string }[];
    public declare disabled: boolean;

    constructor(public $scope: Scope) {
        this.tags = this.tags || [];
        this.disabled = this.disabled || false;
    }

    $onInit() {
        this.setTags(this.$scope.tags || []);
        this.disabled = this.$scope.disabled;
    }

    $onChanges(onChangesObj: IOnChangesObject): void {
        if (onChangesObj?.tags) {
            this.setTags(onChangesObj.tags.currentValue || []);
        }
    }

    onTagAddedFunc() {
        this.onTagsChanged();
    }

    onTagRemovedFunc() {
        this.onTagsChanged();
    }

    private onTagsChanged() {
        this.$scope.onTagsChanged?.(this.tags.map(({ text }) => text));
    }

    private setTags(tags: string[] = []) {
        this.tags = tags.map((text) => ({ text }));
    }
}

angular.module('tonkean.app').controller('MultipleInputCtrl', MultipleInputCtrl);
