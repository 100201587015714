<div class="data-retention-configuration" ng-cloak>
    <div class="margin-bottom-lg">
        <!-- Empty Message -->
        <div class="common-title- mod-light margin-bottom-lg" ng-show="::data.isConfigurationIsEmpty">
            <p>Data collection configuration is empty, no data is being currently collected.</p>
        </div>

        <!-- Title -->
        <div class="flex-vmiddle title">
            <div class="margin-right common-width-25">Entity</div>

            <div class="common-width-25">Data Lifespan</div>

            <div class="common-width-25" ng-if="data.showShouldCollect">Should collect</div>

            <div class="active-entities-column" ng-show="data.showEntitiesCount">Current Active Records</div>
        </div>

        <!-- Loader Icon -->
        <div ng-show="data.isInitializeLoading" class="flex">
            <div class="flex-grow-one">
                <div class="data-retention-row-placeholder"></div>
                <div class="data-retention-row-placeholder"></div>
                <div class="data-retention-row-placeholder" ng-if="data.showShouldCollect"></div>
                <div class="data-retention-row-placeholder"></div>
            </div>
        </div>

        <!-- Table Values -->
        <div
            ng-show="!data.isInitializeLoading"
            class="flex-vmiddle data-retention-entity-row"
            ng-repeat="entity in data.integrationConfiguredEntities"
            ng-init="lockedEntity = !(data.dataRetentionSettings.canEditAll || data.dataRetentionSettings.editableEntities[entity.id])"
        >
            <div class="margin-right common-width-25">
                <tnk-entity-selector
                    should-not-choose-default-entity="true"
                    project-integration="data.projectIntegration"
                    selected-entity="{{ entity.id }}"
                    on-entity-selected="onEntitySelected(selectedEntity, $index)"
                    force-fetch-supported-entities-from-server="true"
                    ignore-entities="data.dataRetentionSettings.ignoreEntities"
                    should-disable-choice="isEntityAlreadySelected(option)"
                ></tnk-entity-selector>
            </div>

            <div class="flex-vmiddle common-width-25">
                <div
                    class="flex-vmiddle"
                    uib-tooltip="The data retention for this entity cannot be modified"
                    tooltip-enable="lockedEntity"
                >
                    <div class="flex-vmiddle common-color-dark-grey margin-right-lg">
                        <tnk-toggle
                            size="xs"
                            class="margin-right"
                            is-active="entity.isIndefinite || lockedEntity"
                            toggle-disabled="lockedEntity"
                            validation-promise="onDataRetentionTypeToggle(entity.id)"
                        ></tnk-toggle>

                        <span ng-class="{ 'opacity-30': !(entity.isIndefinite || lockedEntity) }">Indefinite</span>
                    </div>

                    <div
                        class="flex-vmiddle common-color-dark-grey"
                        ng-class="{ 'disabled-data-retention-input': entity.isIndefinite || lockedEntity }"
                    >
                        <input
                            type="number"
                            min="0"
                            max="1095"
                            ng-disabled="entity.isIndefinite || lockedEntity"
                            oninput="this.value = Math.abs(this.value)"
                            class="margin-right days-back-input"
                            ng-model="entity.expirationDays"
                            autofocus
                        />

                        Days
                    </div>
                </div>
            </div>

            <div class="flex-vmiddle common-width-25" ng-if="data.showShouldCollect">
                <div
                    class="flex-vmiddle"
                    uib-tooltip="The data retention for this entity cannot be modified"
                    tooltip-enable="lockedEntity"
                >
                    <tnk-toggle
                        size="xs"
                        class="margin-right"
                        is-active="data.nativeEntitiesInboundConfiguration[entity.displayName].collectConfiguration.isCollectEnabled
                         && data.nativeEntitiesInboundConfiguration[entity.displayName].livestreamConfiguration.isLivestreamEnabled"
                        toggle-disabled="lockedEntity"
                        on-toggle-click="onEntityInboundConfigurationToggle(entity.displayName, isActive)"
                    ></tnk-toggle>
                </div>
            </div>

            <div class="active-entities-column" ng-show="data.showEntitiesCount">
                {{
                    data.integrationEntityDocumentsCount.get(entity.id)
                        ? data.integrationEntityDocumentsCount.get(entity.id)
                        : 0
                }}
            </div>

            <!-- Remove row button -->
            <span class="padding-left-lg padding-right-none">
                <button
                    type="button"
                    class="btn btn-sm btn-no-border padding-normal-xxs margin-left-xs"
                    ng-hide="
                        entity.id &&
                        !(
                            data.dataRetentionSettings.canAddOrRemoveAll ||
                            data.dataRetentionSettings.removableEntities[entity.id]
                        )
                    "
                    ng-click="removeEntity($index)"
                >
                    <i class="svg-icon-sm">
                        <tnk-icon src="/images/icons/trash.svg"></tnk-icon>
                    </i>
                </button>
            </span>
        </div>

        <!-- Add entity button -->
        <a
            class="inline-block margin-top"
            ng-click="addEntity()"
            ng-if="data.dataRetentionSettings.isSupportsDataRetention && data.dataRetentionSettings.canAddOrRemoveAll"
        >
            + Add Entity
        </a>

        <div class="alert alert-danger margin-top" ng-show="!data.isInitializeLoading && data.errorMessage">
            {{ data.errorMessage }}
        </div>
    </div>

    <!-- Modal footer -->
    <div>
        <button
            class="btn btn-primary flex mod-align-center"
            ng-disabled="(selections|filter:{loadingBranches:true}:true).length || data.savingDataRetention || data.isInitializeLoading"
            type="button"
            ng-click="saveConfiguration()"
        >
            Save
            <i class="loading-small margin-left-xs" ng-if="data.savingDataRetention"></i>
        </button>
    </div>
</div>
