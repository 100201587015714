import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import addFormBlockCreateOptionDefaults from '../addFormBlockCreateOptionDefaults';
import formAnsweredCanCreate from '../canCreate/formAnsweredCanCreate';
import formAnsweredTriggerParamsEnricher from '../enrichers/formAnsweredTriggerParamsEnricher';

const formAnsweredForIntegrationCanCreate: CreateAfterOptionCanCreate = (childImpacts, configuredLogic) => {
    return (
        formAnsweredCanCreate(childImpacts) &&
        configuredLogic.node?.customTriggerActions?.length &&
        // Is intercom action and of the right sub-type of action
        configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition &&
        configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.integrationType === 'INTERCOM' &&
        configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.performedActionDefinition &&
        configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.performedActionDefinition
            .customActionKey === 'ADD_FORM_TO_CONVERSATION'
    );
};

const addFormAnsweredForIntegrationActionCreateAfterOption = {
    label: 'Data Source Form Answered',
    blockDisplayName: addFormBlockCreateOptionDefaults.blockDisplayName,
    triggerParamsEnricher: formAnsweredTriggerParamsEnricher,
    canCreate: formAnsweredForIntegrationCanCreate,
};

export default addFormAnsweredForIntegrationActionCreateAfterOption;
