import { useMemo } from 'react';
import type * as React from 'react';

import type FieldDateRangeValidation from '../entities/FieldDateRangeValidation';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { type FieldDefinitionKey, FieldType } from '@tonkean/tonkean-entities';

const useFieldsAdditionalMenuItems = (
    dateRangeValidations: FieldDateRangeValidation[] | undefined,
    setEditedDateRangeValidationKeyAndName: React.Dispatch<React.SetStateAction<[FieldDefinitionKey, string] | false>>,
) => {
    return useMemo(() => {
        return [
            {
                dataAutomation: 'fields-widget-config-field-row-aggregation',
                key: 'fields-widget-config-field-row-date-range',
                title: (fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>) => {
                    const fieldDefinitionDateRangeValidation = dateRangeValidations?.find(
                        (dateRangeValidation) => dateRangeValidation.fieldDefinitionKey === fieldDefinitionId,
                    );
                    return fieldDefinitionDateRangeValidation ? 'Edit date range' : 'Set date range';
                },
                isDisabled: (fieldType) => {
                    return fieldType !== FieldType.Date;
                },

                onClick: (fieldKey, fieldName) => {
                    setEditedDateRangeValidationKeyAndName([fieldKey, fieldName]);
                },
            },
        ];
    }, [dateRangeValidations, setEditedDateRangeValidationKeyAndName]);
};

export default useFieldsAdditionalMenuItems;
