<div ng-if="!data.loading" class="relative tracks-editor-ctrl" id="tracks-editor-{{ editorId }}">
    <div class="tracks-editor-content" id="tracks-editor-content-{{ editorId }}">
        <div class="tracks-editor-content-inner relative">
            <!-- Resize indicator - its fixed and will be place dynamically  -->
            <div id="{{::editorId}}-resize-indicator" class="tracks-editor-resize-indicator">
                <div
                    class="resize-box"
                    ng-if="data.resize.columnId"
                    ng-style="{ left: data.resize.xPos + 'px', top: data.resize.yPos + 'px' }"
                >
                    <div class="resize-line"></div>
                </div>
            </div>

            <div
                ng-if="
                    !viewOnlyMode &&
                    !parentItem &&
                    !showArchived &&
                    !hideColumnQuickCreateForce &&
                    data.hasPermissionsToEditWorker
                "
                class="tracks-header-plus-separator"
            ></div>

            <!-- Fields headers - created with a stub track. This structure need to be updated when trackEditorItem is. -->
            <div
                class="tracks-headers-container flex-vmiddle show-edit-when-hover-visibility"
                id="tracks-headers-container-{{ editorId }}"
                ng-class="{ 'expand-title': !data.minimizeTitleHeader, 'tracks-headers-sticky': data.stickyHeader }"
                ng-if="
                    (!showArchived && (!parentItem || (data.items && data.items.length)) && !hideHeader) ||
                    data.showHeader
                "
            >
                <!-- Title column header -->
                <!-- 56px is the total size of all items that are before the title - checkbox, bullet and tree dots -->
                <div ng-if="hideTitle && !formViewInInitiative" style="width: 56px"></div>
                <div
                    class="
                        tracks-col-text tracks-header
                        mod-order
                        flex-vmiddle
                        margin-left-lg
                        clearfix
                        relative
                        tracks-title-custom
                    "
                    ng-class="{
                        'mod-in-order': data.orderBy.field === 'TNK_TITLE',
                        'flex-grow': !collectItemsMode,
                        'limit-col-text-width-header': collectItemsMode,
                        'col-text-header-without-bullet-options': formViewInInitiative
                    }"
                    ng-if="!hideTitle"
                >
                    <div
                        class="padding-normal-h flex-vmiddle relative show-edit-when-hover"
                        ng-class="{ 'flex-grow': !collectItemsMode }"
                    >
                        <!-- Bulk selection -->
                        <span
                            class="pointer absolute-left padding-none margin-none common-color-grey-softer"
                            ng-if="
                                !viewOnlyMode &&
                                !onlyUpdateExistingItems &&
                                !hideBulkSelection &&
                                data.items &&
                                data.items.length > 1
                            "
                            ng-click="!data.bulkSelecting ? startBulkSelection() : finishBulkSelection()"
                            uib-tooltip="Bulk select items"
                            tooltip-placement="bottom-left"
                            style="left: -10px; top: 1px"
                        >
                            <i
                                class="fa fa-square-o common-size-s"
                                ng-class="{ 'fa-square common-color-primary': data.bulkSelecting }"
                            ></i>
                        </span>

                        <span>
                            <!-- Title header label -->
                            <tnk-text-ellipsis
                                data-automation="track-editor-items-column"
                                number-of-lines="headerMaxLines || 1"
                                tooltip="!$root.isMobile"
                                children="getDisplayNameForTitleField()"
                            ></tnk-text-ellipsis>
                        </span>

                        <!-- Sort by title -->
                        <i
                            class="order-btn svg-icon-vmiddle margin-left-xs"
                            ng-class="{ 'mod-asc': data.orderBy.orderType === 'ASC' }"
                            ng-click="setOrderBy('TNK_TITLE', 'String')"
                        >
                            <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                        </i>
                    </div>

                    <!-- Clear ordering button -->
                    <div
                        class="pointer common-tag-full mod-big-radius mod-thin common-normal-weight margin-right"
                        ng-class="
                            data.loadingSortedOrder
                                ? 'mod-grey-tag common-color-grey'
                                : 'mod-primary-tag common-color-primary'
                        "
                        ng-if="data.orderBy.field && data.orderBy.field !== 'index'"
                        ng-click="!data.loadingSortedOrder && clearOrderBy()"
                    >
                        Clear sort
                        <i class="svg-icon-xxxs">
                            <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
                        </i>
                    </div>
                </div>

                <!-- Tags column header -->
                <div
                    class="tracks-col-info2 tracks-header text-left flex-vmiddle mod-order"
                    ng-if="(createdInFormId && !displayFieldsList) || displayFieldsList.includes('TNK_TAGS')"
                    ng-class="{ 'mod-in-order': data.orderBy.field === 'TNK_TAGS' }"
                    uib-dropdown
                >
                    <!-- Tags column title -->
                    <span
                        class="flex-grow"
                        uib-dropdown-toggle
                        ng-class="{ pointer: !disableFieldHeaderDropdown }"
                        ng-disabled="disableFieldHeaderDropdown"
                    >
                        <tnk-text-ellipsis
                            number-of-lines="headerMaxLines || 1"
                            tooltip="!$root.isMobile"
                            children="data.tagsDisplayName"
                        ></tnk-text-ellipsis>
                    </span>

                    <!-- Dropdown menu-->
                    <ul uib-dropdown-menu ng-if="!viewOnlyMode" class="tracks-header-dropdown">
                        <li ng-click="setOrderBy('TNK_TAGS','List')">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">
                                    Sort
                                    <span class="common-size-xxxxxs">
                                        ({{
                                            data.orderBy.field === 'tagsDisplayName'
                                                ? data.orderBy.orderType === 'DESC'
                                                    ? 'asc'
                                                    : 'desc'
                                                : data.defaultOrderType.title
                                        }}.)
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li
                            ng-if="filterByField"
                            ng-click="filterByField({fieldDefinition: {id: 'TNK_TAGS', name: 'Tags', fieldType: 'List'}})"
                        >
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Filter</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Update Forms Actions -->
                <div
                    class="tracks-col-info4 tracks-header text-left flex-vmiddle mod-order"
                    ng-if="data.showActionsColumn && data.workerForms.length"
                >
                    Actions
                </div>

                <!-- Due date column header -->
                <div
                    data-automation="track-editor-due-date-column"
                    class="tracks-col-info2 tracks-header text-left flex-vmiddle mod-order"
                    ng-if="!displayFieldsList || displayFieldsList.includes('TNK_DUE_DATE')"
                    ng-class="{ 'mod-in-order': data.orderBy.field === 'TNK_DUE_DATE' }"
                    uib-dropdown
                >
                    <!-- Due date column title -->
                    <span
                        class="flex-grow"
                        uib-dropdown-toggle
                        ng-class="{ pointer: !disableFieldHeaderDropdown }"
                        ng-disabled="disableFieldHeaderDropdown"
                    >
                        <tnk-text-ellipsis
                            number-of-lines="headerMaxLines || 1"
                            tooltip="!$root.isMobile"
                            children="data.dueDateDisplayName"
                        ></tnk-text-ellipsis>
                    </span>

                    <!-- Dropdown menu-->
                    <ul uib-dropdown-menu ng-if="!viewOnlyMode" class="tracks-header-dropdown">
                        <li ng-click="setOrderBy('TNK_DUE_DATE','Date')">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">
                                    Sort
                                    <span class="common-size-xxxxxs">
                                        ({{
                                            data.orderBy.field === 'TNK_DUE_DATE'
                                                ? data.orderBy.orderType === 'DESC'
                                                    ? 'asc'
                                                    : 'desc'
                                                : data.defaultOrderType.title
                                        }}.)
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li
                            ng-if="filterByField"
                            ng-click="filterByField({fieldDefinition: {id: 'TNK_DUE_DATE', name: 'Due Date', fieldType: 'Date'}})"
                        >
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Filter</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Sort by icon -->
                    <i
                        class="margin-left-xs order-btn svg-icon-vmiddle"
                        ng-class="{ 'mod-asc': data.orderBy.orderType === 'ASC' }"
                        ng-click="setOrderBy('TNK_DUE_DATE', 'Date')"
                        ng-hide="data.loadingSortedOrder"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                    </i>

                    <!-- Loading circle for sort -->
                    <i
                        class="loading-small margin-left-xs"
                        ng-if="data.loadingSortedOrder && data.orderBy.field === 'dueDate'"
                    ></i>
                </div>

                <!-- Status column header -->
                <div
                    data-automation="track-editor-status-column"
                    ng-if="!quickCreationMode && (!displayFieldsList || displayFieldsList.includes('TNK_STAGE'))"
                    class="tracks-col-status tracks-header flex-vmiddle mod-order"
                    ng-class="{ 'mod-in-order': data.orderBy.field === 'TNK_STAGE' }"
                    uib-dropdown
                >
                    <!-- Status column title -->
                    <span
                        class="margin-right"
                        uib-dropdown-toggle
                        ng-class="{ pointer: !disableFieldHeaderDropdown }"
                        ng-disabled="disableFieldHeaderDropdown"
                    >
                        <tnk-text-ellipsis
                            number-of-lines="headerMaxLines || 1"
                            tooltip="!$root.isMobile"
                            children="data.statusDisplayName"
                        ></tnk-text-ellipsis>
                    </span>

                    <!-- Dropdown menu-->
                    <ul uib-dropdown-menu ng-if="!viewOnlyMode" class="tracks-header-dropdown">
                        <li ng-click="setOrderBy('TNK_STAGE', 'String')">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">
                                    Sort
                                    <span class="common-size-xxxxxs">
                                        ({{
                                            data.orderBy.field === 'TNK_STAGE'
                                                ? data.orderBy.orderType === 'DESC'
                                                    ? 'asc'
                                                    : 'desc'
                                                : data.defaultOrderType.title
                                        }}.)
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li ng-if="filterByField" ng-click="filterByField({fieldDefinition: 'STATUS'})">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Filter</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Sort by icon -->
                    <i
                        class="margin-left-xs order-btn svg-icon-vmiddle"
                        ng-class="{ 'mod-asc': data.orderBy.orderType === 'ASC' }"
                        ng-click="setOrderBy('TNK_STAGE', 'String')"
                        ng-hide="data.loadingSortedOrder"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                    </i>

                    <!-- Loading circle for sort -->
                    <i
                        class="loading-small margin-left"
                        ng-if="data.loadingSortedOrder && data.orderBy.field === 'stateText'"
                    ></i>
                </div>

                <!-- Owner column header -->
                <div
                    data-automation="track-editor-owner-column"
                    class="tracks-col-who tracks-header flex-vmiddle mod-order"
                    ng-if="!displayFieldsList || displayFieldsList.includes('TNK_OWNER_ID')"
                    ng-class="{ 'mod-in-order': data.orderBy.field === 'TNK_OWNER_NAME' }"
                    uib-dropdown
                >
                    <!-- Owner column title -->
                    <span
                        uib-dropdown-toggle
                        ng-class="{ pointer: !disableFieldHeaderDropdown }"
                        ng-disabled="disableFieldHeaderDropdown"
                    >
                        <tnk-text-ellipsis
                            number-of-lines="headerMaxLines || 1"
                            tooltip="!$root.isMobile"
                            children="data.ownerDisplayName"
                        ></tnk-text-ellipsis>
                    </span>

                    <!-- Dropdown menu-->
                    <ul uib-dropdown-menu ng-if="!viewOnlyMode" class="tracks-header-dropdown">
                        <li ng-click="setOrderBy('TNK_OWNER_NAME', 'String')">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">
                                    Sort
                                    <span class="common-size-xxxxxs">
                                        ({{
                                            data.orderBy.field === 'TNK_OWNER_NAME'
                                                ? data.orderBy.orderType === 'DESC'
                                                    ? 'asc'
                                                    : 'desc'
                                                : data.defaultOrderType.title
                                        }}.)
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li ng-if="filterByField" ng-click="filterByField({fieldDefinition: 'OWNER'})">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Filter</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Sort by icon -->
                    <i
                        class="margin-left-xs order-btn svg-icon-vmiddle"
                        ng-class="{ 'mod-asc': data.orderBy.orderType === 'ASC' }"
                        ng-click="setOrderBy('TNK_OWNER_NAME', 'String')"
                        ng-hide="data.loadingSortedOrder"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                    </i>

                    <!-- Loading circle for sort -->
                    <i
                        class="loading-small margin-left"
                        ng-if="data.loadingSortedOrder && data.orderBy.field === 'owner.name'"
                    ></i>
                </div>

                <!-- Custom data fields headers -->
                <div
                    data-automation="track-editor-{{fieldDef.name}}-column"
                    class="
                        tracks-col-custom-field tracks-header
                        mod-order
                        flex-vmiddle
                        mod-justify-center
                        common-hover-visible-container
                    "
                    ng-repeat="fieldDef in calculateVisibleColumns() track by fieldDef.id"
                    ng-if="!displayFieldsList || displayFieldsList.includes(fieldDef.id)"
                    id="tracksHeader{{ fieldDef.id }}"
                    ng-class="{ 'mod-in-order': data.orderBy.field === fieldDef.id }"
                    ng-style="{width: as.currentUser.metadata.columnWidthOverrides[groupId][fieldDef.id] ? as.currentUser.metadata.columnWidthOverrides[groupId][fieldDef.id] + 'px' : defaultColumnWidth +'px'}"
                    uib-dropdown
                >
                    <div
                        class="tracks-col-perimeter"
                        ng-if="!$first"
                        ng-class="{
                            'common-hover-visible': !data.resize.columnId,
                            hidden: data.resize.columnId && data.resize.columnId !== fieldDef.id,
                            'mod-left': !$first,
                            'absolute-left': $first
                        }"
                    ></div>
                    <i
                        class="fa fa-exclamation pointer margin-right-xxs common-size-xxxxs common-color-danger pointer"
                        ng-if="
                            fieldDef.isBrokenIntegration ||
                            (fieldDef.projectIntegration && fieldDef.projectIntegration.valid != undefined && !fieldDef.projectIntegration.valid)
                        "
                        ng-click="$root.$broadcast('createNewField', [onlyGroup, fieldDef.targetType, fieldDef.type, fieldDef.projectIntegration, false, false, fieldDef, null, null, null, null, null, null, null, null, null, null, null, workflowVersionId])"
                    ></i>
                    <!-- Integration icon if view-only and has integration-->
                    <i
                        ng-if="viewOnlyMode && fieldDef.integrationType"
                        uib-tooltip="Data from {{ fieldDef.integrationType.toLowerCase() }}"
                        class="initiative-integration-icon margin-right-xs mod-column-header flex-no-shrink"
                        ng-class="'mod-' + fieldDef.integrationType.toLowerCase() "
                    ></i>

                    <!-- Column name -->
                    <span
                        class="margin-right-xxs flex-vmiddle"
                        uib-dropdown-toggle
                        ng-disabled="disableFieldHeaderDropdown"
                        ng-click="setFieldDescriptionPopoverIsOpen(fieldDef.id, !!data.currentFieldDescriptionPopoverIsOpen, 'left', true)"
                        ng-class="{
                            'tracks-field-description-outside': data.currentFieldDescriptionPopoverIsOpen,
                            pointer: !disableFieldHeaderDropdown,
                            'tracks-col-custom-name': !disableFieldHeaderDropdown
                        }"
                        click-outside="setFieldDescriptionPopoverIsOpen(fieldDefId, false, null, true)"
                        outside-activators="tracks-field-description-outside"
                        outside-if-not="tracks-col-custom-name,tracks-description-tooltip"
                    >
                        <!-- Linked field definition icon -->
                        <i
                            class="svg-icon common-fill-grey-text margin-right-xs"
                            ng-if="fieldDef.workflowVersionId && fieldDef.workflowVersionId !== workflowVersionId"
                        >
                            <tnk-icon src="../../../../../apps/tracks/images/icons/link.svg"></tnk-icon>
                        </i>

                        <!-- Integration icon if not view-only and native integration-->
                        <i
                            ng-if="!viewOnlyMode && fieldDef.definition.matchConfiguration.isForMatchingItem"
                            uib-tooltip="Matched item"
                            class="initiative-integration-icon mod-column-header margin-right-xs flex-no-shrink"
                            ng-class="mod - match"
                            ng-style="{
                                background: 'white url(' + fieldDef.projectIntegration.iconUrl + ') no-repeat center'
                            }"
                        ></i>

                        <!-- Integration icon if not view-only and native integration-->
                        <i
                            ng-if="
                                !viewOnlyMode &&
                                fieldDef.definition &&
                                fieldDef.definition.integrationType &&
                                fieldDef.projectIntegration &&
                                !fieldDef.definition.matchConfiguration.isForMatchingItem
                            "
                            uib-tooltip="{{ fieldDef.projectIntegration.displayName | capitalize }}"
                            class="initiative-integration-icon mod-column-header margin-right-xs flex-no-shrink"
                            ng-class="
                                !fieldDef.projectIntegration.iconUrl
                                    ? 'mod-' + fieldDef.definition.integrationType.toLowerCase()
                                    : null
                            "
                            ng-style="{
                                background: fieldDef.projectIntegration.iconUrl
                                    ? 'white url(' + fieldDef.projectIntegration.iconUrl + ') no-repeat center'
                                    : null
                            }"
                        ></i>

                        <span
                            uib-tooltip-html="fieldDef.description"
                            tooltip-placement="{{ data.currentFieldDescriptionPopoverPlacement }}"
                            tooltip-append-to-body="true"
                            tooltip-enable="{{ !$root.isMobile && fieldDef.description.length > 0 }}"
                            tooltip-trigger="none"
                            tooltip-is-open="data.currentFieldDescriptionPopoverIsOpen === fieldDef.id"
                            tooltip-class="tooltip-cursor-enable"
                            ng-mouseenter="setFieldDescriptionPopoverIsOpen(fieldDef.id, true)"
                            ng-mouseleave="setFieldDescriptionPopoverIsOpen(fieldDef.id, false)"
                        >
                            <tnk-text-ellipsis
                                number-of-lines="headerMaxLines || 1"
                                tooltip="!$root.isMobile"
                                children="getDisplayNameOfFieldDefinition(fieldDef)"
                            ></tnk-text-ellipsis>
                        </span>
                    </span>

                    <!-- Dropdown menu-->
                    <ul uib-dropdown-menu ng-if="!viewOnlyMode" class="tracks-header-dropdown">
                        <!-- Sort -->
                        <li ng-click="setOrderBy(fieldDef.id, fieldDef.evaluatedDisplayType)">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">
                                    Sort
                                    <span class="common-size-xxxxxs">
                                        ({{
                                            data.orderBy.field === fieldDef.id
                                                ? data.orderBy.orderType === 'DESC'
                                                    ? 'asc'
                                                    : 'desc'
                                                : data.defaultOrderType.title
                                        }}.)
                                    </span>
                                </span>
                            </a>
                        </li>

                        <!-- Filter -->
                        <li ng-if="filterByField" ng-click="filterByField({fieldDefinition: fieldDef})">
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Filter</span>
                            </a>
                        </li>

                        <!-- Move left -->
                        <li
                            ng-if="!$first && data.hasPermissionsToEditWorker"
                            ng-click="moveFieldLeft($event, fieldDef.id)"
                            ng-class="{ 'common-disabled': !data.isDraft }"
                            uib-tooltip="Only available in Build environment"
                            tooltip-placement="auto left"
                            tooltip-enable="!data.isDraft"
                        >
                            <a class="common-color-light-grey">
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/arrow-left.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Move left</span>
                            </a>
                        </li>

                        <!-- Move right -->
                        <li
                            ng-if="!$last && data.hasPermissionsToEditWorker"
                            ng-click="moveFieldRight($event, fieldDef.id)"
                            ng-class="{ 'common-disabled': !data.isDraft }"
                            uib-tooltip="Only available in Build environment"
                            tooltip-placement="auto left"
                            tooltip-enable="!data.isDraft"
                        >
                            <a class="common-color-light-grey">
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/arrow-right.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Move right</span>
                            </a>
                        </li>

                        <!-- Unlink field -->
                        <li
                            ng-click="unlinkFieldDefinition(fieldDef.id)"
                            ng-if="fieldDef.workflowVersionId !== workflowVersionId"
                        >
                            <a class="common-color-light-grey">
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/link.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Unlink</span>
                            </a>
                        </li>

                        <!-- Separator -->
                        <li class="divider"></li>

                        <!-- Repopulate field definition -->
                        <li ng-click="repopulateField(fieldDef)">
                            <a>
                                <span class="svg-icon-sm margin-right"><i class="refresh-icon fa fa-refresh"></i></span>
                                <span class="common-color-light-grey2">Refresh field</span>
                            </a>
                        </li>

                        <!-- Separator -->
                        <li
                            class="divider"
                            ng-if="
                                !parentItem &&
                                data.hasPermissionsToEditWorker &&
                                fieldDef.workflowVersionId === workflowVersionId
                            "
                        ></li>

                        <!-- Hide -->
                        <li
                            ng-if="!parentItem && data.hasPermissionsToEditWorker"
                            ng-click="hideColumn($event, fieldDef.id)"
                            ng-class="{ 'common-disabled': !data.isDraft }"
                            uib-tooltip="Only available in Build environment"
                            tooltip-placement="auto left"
                            tooltip-enable="!data.isDraft"
                        >
                            <!-- Not showing "Hide" when there's a parent item (when in initiative view) cause it will hide the field from the whole list -->
                            <a>
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/eye-crossed-o.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Hide</span>
                            </a>
                        </li>
                    </ul>

                    <!-- Order by button -->
                    <i
                        class="order-btn svg-icon-vmiddle pointer"
                        ng-class="{ 'mod-asc': data.orderBy.orderType === 'ASC' && data.orderBy.field === fieldDef.id }"
                        ng-click="setOrderBy(fieldDef.id, fieldDef.evaluatedDisplayType)"
                        ng-hide="data.loadingSortedOrder"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/sort.svg"></tnk-icon>
                    </i>

                    <!-- Loading circle for sort -->
                    <i
                        class="loading-small margin-left"
                        ng-if="data.loadingSortedOrder && data.orderBy.field === fieldDef.id"
                    ></i>

                    <!-- Quick Create field-->
                    <div
                        ng-if="
                            !viewOnlyMode && !parentItem && !showArchived && !hideColumnQuickCreateForce && data.isDraft
                        "
                        class="absolute-below common-z-index-1 flex mod-justify-center"
                    >
                        <button
                            ng-if="!fieldDef.isConfigured"
                            type="button"
                            class="btn btn-primary tracks-header-configure-btn flex-vmiddle"
                            analytics-on
                            analytics-category="Configure"
                            analytics-event="Configure button clicked"
                            ng-click="openConfigureManualFieldModal(fieldDef)"
                        >
                            <i class="svg-icon-xs svg-icon-vmiddle common-color-white margin-right-xxs">
                                <tnk-icon src="../../../../../apps/tracks/images/icons/settings.svg"></tnk-icon>
                            </i>
                            <div class="common-size-xxxxs line-height-normal">Configure</div>
                        </button>
                    </div>

                    <div
                        class="track-col-resize-separator common-hover-visible"
                        ng-mousedown="onResizeColumnMouseDown($event, fieldDef)"
                        ng-class="{ hidden: data.resize.columnId }"
                    ></div>

                    <div
                        class="tracks-col-perimeter mod-right"
                        ng-class="{
                            'common-hover-visible': !data.resize.columnId,
                            hidden: data.resize.columnId && data.resize.columnId !== fieldDef.id
                        }"
                    ></div>
                </div>

                <!-- Create fields headers -->
                <div
                    class="tracks-header-plus"
                    ng-if="
                        !viewOnlyMode &&
                        !parentItem &&
                        !showArchived &&
                        !hideColumnQuickCreateForce &&
                        data.hasPermissionsToEditWorker &&
                        (isFieldsExists() || data.isDraft)
                    "
                >
                    <i
                        class="fa fa-plus-circle pointer common-color-primary"
                        data-automation="tnk-tracks-editor-template-click-add-new-manual-field"
                        uib-popover-template="'./popovers/quickColumnPopoverTemplate.template.html'"
                        popover-is-open="data.quickColumn.isOpen"
                        popover-placement="bottom-right"
                        popover-class="popover-no-arrow tracks-quick-column-popover"
                        popover-trigger="outsideClick"
                        popover-animation="false"
                        popover-append-to-body="true"
                    ></i>
                </div>
                <div
                    class="tracks-header-plus-placeholder"
                    ng-if="!isFieldsExists() && !data.isDraft && !hideColumnQuickCreateForce"
                ></div>
            </div>

            <ul
                class="list-group tracks-items-list tracks-drag-drop-list common-ul-no-style"
                ng-if="!showArchived"
                id="tracks-ul-root{{ editorId }}"
                tnk-dnd-list
                tnk-dnd-drop="onDrop(dndData, dndIndex)"
                tnk-dnd-get-marker="getDndMarker()"
            >
                <li
                    ng-repeat="item in items = (data.items | filter:filter | orderBy:data.orderBy.filter) track by item.id"
                    ng-if="!itemMap[item.id].deleted && !itemMap[item.id].isArchived"
                    ng-class="{ 'non-editable-item': !item.inEditMode && data.doNotAllowEditPublishedInitiatives }"
                    id="tracks-li-{{::item.id}}{{::editorId}}"
                    tnk-dnd-draggable="{{::item.id}}"
                    tnk-dnd-dragstart="onDragStart(dndData)"
                    tnk-dnd-dragend="onDragEnd()"
                    tnk-dnd-moved="onMoved(data.items, dndData)"
                    tnk-dnd-drop-into="onDrop(dndData, 0, dndListId, true)"
                    tnk-dnd-disabled="{{
                        data.bulkSelecting ||
                            !allowDndOnRoot ||
                            data.orderBy.field !== 'index' ||
                            !item.created ||
                            (!item.inEditMode && data.doNotAllowEditPublishedInitiatives) ||
                            viewOnlyMode ||
                            trackHelper.isTheInitiativeBeingMoved(item.id)
                    }}"
                    tnk-dnd-get-placeholder="getDndPlaceholder(dndData)"
                    loading-related-initiatives="{{loadingRelatedInitiatives}}"
                >
                    <div ng-include="'./trackEditorItem.template.html'"></div>
                </li>

                <!-- Show "No tracks found" when filtering on tag or function and we filtered out items (probably because of done toggle) -->
                <div
                    ng-if="isTagOrFunc && tracks.length > 0 && items.length === 0"
                    class="margin-normal common-color-grey"
                >
                    No tracks found...
                </div>
            </ul>

            <!-- Initiatives list to display when ONLY ARCHIVED items are requested (e.g from the archived.html page) -->
            <ul class="list-group tracks-items-list tracks-drag-drop-list common-ul-no-style" ng-if="showArchived">
                <li
                    ng-repeat="item in items = (data.items | filter:filter ) track by item.id"
                    ng-if="itemMap[item.id].isArchived && !itemMap[item.id].deleted"
                    id="tracks-li-{{ item.id }}{{ editorId }}-archived"
                >
                    <div ng-include="'./trackEditorItemArchived.template.html'"></div>
                </li>
            </ul>

            <!--Bulk operation bar-->
            <div class="tracks-editor-bulk-operations" ng-if="data.bulkSelecting">
                {{bulkSelectedTrue = (data.bulkSelectItems | objToArray | filter:{value: true});""}}
                <div class="flex-vmiddle">
                    <div>
                        <span class="margin-right">Bulk select:</span>
                        <a ng-click="bulkSelectAll()">All</a>
                        <span class="margin-normal-sm-h">|</span>
                        <a ng-click="bulkSelectNone()">None</a>
                    </div>
                    <button
                        class="btn btn-primary btn-sm margin-left-lg margin-right-xs"
                        ng-click="bulkRefresh()"
                        data-ng-disabled="bulkSelectedTrue.length < 1"
                    >
                        Refresh&nbsp;({{ bulkSelectedTrue.length }})
                    </button>
                    <button
                        class="btn btn-primary btn-sm margin-left-lg margin-right-xs"
                        ng-click="bulkArchive(!showArchived)"
                        data-ng-disabled="bulkSelectedTrue.length < 1"
                    >
                        {{ showArchived ? 'Un-Archive' : 'Archive' }}&nbsp;({{ bulkSelectedTrue.length }})
                    </button>
                    <button
                        class="btn btn-danger btn-sm"
                        ng-click="bulkDelete()"
                        ng-if="showArchived"
                        data-ng-disabled="bulkSelectedTrue.length < 1"
                    >
                        Delete&nbsp;({{ bulkSelectedTrue.length }})
                    </button>
                    <button class="btn btn-default btn-sm margin-left-lg" ng-click="finishBulkSelection()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Showing loading while we still have more initiatives that we didn't receive... -->
    <div ng-if="hasMoreInitiatives" class="flex-vmiddle margin-top-md common-size-xxxs common-color-grey">
        <i class="loading-small margin-right-xs"></i>
        Loading more...
    </div>

    <!-- New item text box -->
    <div
        id="create-placeholder-base-{{ editorId }}"
        ng-show="!hasMoreInitiatives && !viewOnlyMode"
        class="tracks-item-new-box"
        ng-class="{
            'mod-focused':
                (data.newItemText && data.newItemText.length) ||
                data.dueDate.dueDate ||
                data.newItemFocus ||
                subItemMode
        }"
        ng-if="!hideAddNewForce && ((!viewOnlyMode && !onlyUpdateExistingItems) || parentItem) && !collectItemsMode"
        click-outside="unFocusNewItem()"
        outside-activators="mod-focused"
        outside-if-not="tracks-item-new-input, uib-typeahead-match"
    >
        <form
            name="tracks-item-create-form-{{ editorId }}"
            id="tracks-item-create-form-{{ editorId }}"
            ng-submit="submit()"
        >
            <div>
                <div
                    class="tracks-item-new flex-vmiddle relative tracks-item-new-underline-container"
                    ng-class="{ 'mod-sub-item': subItemMode }"
                    uib-popover-template="'pasteTipPopoverTemplate.html'"
                    popover-is-open="pasteTip.isOpen"
                    popover-placement="bottom"
                    popover-trigger="none"
                >
                    <i
                        class="
                            fa fa-long-arrow-right
                            track-placeholder
                            common-color-primary
                            flex-no-shrink
                            visibility-hidden
                        "
                        ng-show="innerMode && (!subItemMode || (subItem && uiParent[subItem.id]))"
                    ></i>
                    <i
                        class="fa fa-long-arrow-right common-color-primary margin-right flex-no-shrink"
                        ng-show="subItemMode"
                    ></i>
                    <i
                        class="tracks-item-new-plus-icon margin-right flex-no-shrink mod-center flex-vmiddle mod-center"
                        data-automation="track-editor-add-one-initiative-plus-button"
                        ng-click="setFocusOnMainTextbox()"
                        ng-class="{ 'mod-not-inner': !innerMode }"
                        ng-show="!subItemMode"
                    >
                        <span>+</span>
                    </i>
                    <div class="flex-grow flex-vmiddle">
                        <input
                            type="text"
                            class="flex-grow track-item-tabbable tracks-item-new-input"
                            ng-class="{ 'hide-typeahead': disableNewItemTypeahead }"
                            data-automation="track-editor-template-add-button-label"
                            ng-model="data.newItemText"
                            ng-change="onNewTextChange()"
                            ng-paste="onPaste($event)"
                            ng-keydown="onKeyDown($event)"
                            placeholder="{{
                                !data.newItemFocus
                                    ? !utils.isNullOrEmpty(addItemLabel)
                                        ? addItemLabel
                                        : subItemMode
                                        ? 'Add inner item'
                                        : 'Add item'
                                    : ''
                            }}"
                            ng-focus="onNewItemFocus()"
                            ng-blur="data.newItemFocus = false"
                            mentio
                            id="theText-{{ editorId }}"
                        />
                        <div ng-if="data.tags.length" class="flex-no-shrink visible-on-focus">
                            <div class="track-item-func flex-vmiddle">
                                <span class="initiative-tag margin-left" ng-repeat="tag in data.tags">
                                    {{ tag.name || tag }}
                                    <i
                                        class="fa fa-close margin-left-xs pointer"
                                        ng-click="removeItem(data.tags, tag)"
                                    ></i>
                                </span>
                            </div>
                        </div>

                        <div ng-repeat="p in data.atMentioned" class="flex-no-shrink visible-on-focus">
                            <div
                                class="track-item-func track-remove-function-container flex-vmiddle pointer"
                                ng-click="removeItem(data.atMentioned, p)"
                            >
                                <tnk-avatar
                                    user="p"
                                    name="p.name"
                                    uib-tooltip="{{ p.name }}"
                                    mod-secondary="p.email ? false : true"
                                    class="track-leader-avatar mod-border"
                                ></tnk-avatar>
                                <!--<span class="visible-lg margin-left common-size-xxxxs">{{func.stateText || 'Not started'}}</span>-->
                                <i class="fa fa-close track-remove-function"></i>
                            </div>
                        </div>
                        <!-- X button-->
                        <button
                            type="button"
                            class="tracks-item-new-btn mod-cancel visible-on-focus margin-bottom-xs visible-xs"
                            ng-click="hideCreate()"
                        >
                            <i class="fa fa-close common-color-grey margin-top"></i>
                        </button>
                    </div>
                </div>
            </div>

            <tnk-people-at-mention
                text-id="{{ 'theText-' + editorId }}"
                result-list="data.atMentioned"
                include-functions="true"
                opened="onAtMentionOpen(open)"
            ></tnk-people-at-mention>
            <!--<tnk-functions-at-mention text-id="theText" result-list="data.atMentioned"-->
            <!--opened="data.mentionFuncOpen"></tnk-functions-at-mention>-->
            <tnk-tags-at-mention
                text-id="{{ 'theText-' + editorId }}"
                result-list="data.tags"
                opened="onAtMentionOpen(open)"
            ></tnk-tags-at-mention>
        </form>
    </div>

    <div
        class="common-color-grey common-size-xxxxxs margin-top margin-left-lg padding-left-lg"
        ng-class="{
            visible: data.newItemFocus && data.items && data.items.length > 1,
            'visibility-hidden': !(data.newItemFocus && data.items && data.items.length > 1),
            'margin-bottom-20': (data.items | filter: filter | orderBy: data.orderBy.filter).length === 0
        }"
        ng-if="!hideAddNewForce && !viewOnlyMode && !isMobile && !noSubitems"
    >
        <span>
            <i class="fa fa-lightbulb-o common-color-level1"></i>
            Tip:
            <a
                id="tab-in"
                ng-mousedown="onTabInLinkMousedown()"
                onclick=""
                analytics-on
                analytics-category="Tracks Editor"
                analytics-event="Tab from Tip"
            >
                Tab in
            </a>
            , just like in a bullet list, to add sub-items.
        </span>
    </div>

    <script type="text/ng-template" id="pasteTipPopoverTemplate.html">
        <div class="">
            <div ng-if="pasteTip.lines.length" class="flex-grow">Do you want to create {{pasteTip.lines.length}} items? </div>
            <span ng-if="pasteTip.lines.length && pasteTip.duplicatedCount" class="common-size-xxxxs common-color-light-grey2">({{pasteTip.duplicatedCount}} other lines already exist and will be ignored)</span>
            <span ng-if="!pasteTip.lines.length && pasteTip.duplicatedCount" class="flex-grow">All {{pasteTip.duplicatedCount}} lines already exist!</span>
            <div ng-if="pasteTip.lines.length" class="text-right">
                <a class="margin-normal-sm-h" ng-click="pasteMultiple()"><strong>Yes</strong></a>
                <a ng-click="pasteTip.isOpen = false">No</a>
            </div>
        </div>
    </script>

    <!-- Track summary popover -->
    <div id="popover-track-summary-{{ editorId }}" class="absolute-right" tnk-dnd-undraggable>
        <!-- safari append() calculations gets messed up when div is empty -->
        <span>&nbsp;</span>
        <!-- Click outside makes sure the popover is reset when clicking outside -->
        <div
            ng-if="popovers.trackSummary"
            click-outside="resetPopover('popover-track-summary'+ {{ editorId }}, 'trackSummary')"
            outside-if-not="view-function-popover-content inner-btn"
            class="common-color-dark-grey absolute-center-v margin-left-md-minus"
        >
            <tnk-view-function-popover
                initiative="itemMap[popoversData.trackSummary]"
                is-open="popovers.trackSummary"
                placement="auto right"
                view-only-mode="viewOnlyMode"
                solution-business-report-id="data.solutionBusinessReportId"
            ></tnk-view-function-popover>
        </div>
    </div>
</div>
