import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function FormPageStateCtrl($scope, $stateParams) {
    const ctrl = this;

    $scope.data = {
        fieldDefinitionId: $stateParams.fieldId,
        onlyInnerItems: $stateParams.inner,
        projectId: $stateParams.projectId,
        formId: $stateParams.formId?.startsWith('FORM') ? $stateParams.formId : '',
        hierarchy: $stateParams.hr,
        viewMode: $stateParams.viewMode,
        workerRunLogicActionType: $stateParams.workerRunLogicActionType,
        workerRunId: $stateParams.workerRunId,
        sessionId: $stateParams.sessionId,
        customTriggerId: $stateParams.customTriggerId,
        initiativeId: $stateParams.initiativeId,
        form: ctrl.form,
        project: ctrl.project,
        loadForm: ctrl.loadForm,
        addUnderInitiative: ctrl.addUnderInitiative,
        collectionForm: ctrl.collectionForm,
        group: ctrl.group,
        formVersionType: $stateParams.formVersionType?.toLowerCase(),
        itemInterfaceId: $stateParams.formId?.startsWith('ITIN') ? $stateParams.formId : '',
        workflowVersionId: $stateParams.workflowVersionId,
    };
}

export default angular
    .module('tonkean.app')
    .controller('FormPageStateCtrl', lateConstructController(FormPageStateCtrl));
