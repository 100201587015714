import { countQueryFilters } from '@tonkean/tonkean-utils';
import { getParticipatingVariablesForFormula } from '@tonkean/tonkean-utils';
import {
    getVariablesUsedInFormulaExpression,
    getTonkeanQueryParticipatingFieldNames,
    getDefaultTonkeanQuery,
    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP,
} from '@tonkean/tonkean-utils';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function AggregateOnColumnFieldCtrl(
    $scope,
    customFieldsManager,
    utils,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;
    $scope.cfm = customFieldsManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        isWorkflowVersionPublished: $scope.wvm.isPublishedVersion(ctrl.workflowVersionId),
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        noAdvance: ctrl.noAdvance,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        aggregationOnColumnFeatureName: ctrl.aggregationOnColumnFeatureName,
        columnFormulaFeatureName: ctrl.columnFormulaFeatureName,
        shouldShowCheckboxForInnerItemsHirerachy: ctrl.shouldShowCheckboxForInnerItemsHirerachy || false,

        aggregationType: { id: 'Count', displayName: 'Count' },
        aggregatedFieldDefinition: null,
        definitionDoesNotExist: false,
        aggregationTypes: [
            { id: 'Count', displayName: 'Count' },
            { id: 'Avg', displayName: 'Avg' },
            { id: 'Sum', displayName: 'Sum' },
            { id: 'Max', displayName: 'Max' },
            { id: 'Min', displayName: 'Min' },
            { id: 'First', displayName: 'First' },
            { id: 'Reduce', displayName: 'Reduce' },
            { id: 'Terms', displayName: 'Grouped by' },
        ],
        reduceFunctions: ['Concat'],
        concatenationSeparator: '',
        reduceFunction: null,
        groupByFunctions: [
            {
                id: 'Count',
                apiName: 'Count',
                displayName: 'Count',
            },
            {
                id: 'Sum',
                apiName: 'Sum',
                displayName: 'Sum',
            },
            {
                id: 'Avg',
                apiName: 'Avg',
                displayName: 'Average',
            },
        ],
        groupByFunction: null,

        shouldRunRecursivelyOnParents: false,
        advancedFormulaSelected: false,
        formulaExpression: '',
        evaluatedFormulaExpression: '',
        validationResult: null,

        // Order types
        orderTypes: [
            {
                apiName: 'ASC',
                displayName: 'Ascending',
            },
            {
                apiName: 'DESC',
                displayName: 'Descending',
            },
        ],

        variables: [],
        variableIdToVariableMap: {},
        variableNameToVariableMap: {},

        customFiltersControl: {},

        shouldDisplayFilterConditions: false,

        onFormulaSelected: ctrl.onFormulaSelected,

        notAllowedConditionsSet: {
            Contains: true,
            NotContains: true,
        },
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.data.apiNameToGroupByFunctionMap = utils.createMapFromArray($scope.data.groupByFunctions, 'apiName');
        const variablesObject = getParticipatingVariablesForFormula(
            customFieldsManager.selectedGlobalFieldsMap[$scope.data.workflowVersionId],
            customFieldsManager.selectedColumnFieldsMap[ctrl.workflowVersionId],
            'AGGREGATE_ON_COLUMN',
        );

        $scope.data.variables = variablesObject.variables;
        $scope.data.variableIdToVariableMap = variablesObject.variableIdToVariableMap;
        $scope.data.variableNameToVariableMap = variablesObject.variableNameToVariableMap;

        $scope.data.orderBySelectedOrder = $scope.data.orderTypes[0];

        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged(false);
    };

    /**
     * Occurs when changes are made to component bindings.
     * @param changesObj {angular.IOnChangesObject}
     */
    ctrl.$onChanges = function (changesObj) {
        let shouldReininitateEditMode = false;
        let isPartOfEnvironmentChange = false;

        if (changesObj.createMode) {
            $scope.data.createMode = changesObj.createMode.currentValue;
        }
        if (changesObj.duplicateMode) {
            $scope.data.duplicateMode = changesObj.duplicateMode.currentValue;
        }
        if (changesObj.projectIntegration) {
            $scope.data.projectIntegration = changesObj.projectIntegration.currentValue;
        }
        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
        }
        if (changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = changesObj.workflowVersionId.currentValue;
            $scope.data.isWorkflowVersionPublished = $scope.wvm.isPublishedVersion(ctrl.workflowVersionId);
            if (!changesObj.workflowVersionId.isFirstChange()) {
                shouldReininitateEditMode = true;
                isPartOfEnvironmentChange = true;
            }
        }
        if (changesObj.targetType) {
            $scope.data.targetType = changesObj.targetType.currentValue;
        }
        if (changesObj.noAdvance) {
            $scope.data.noAdvance = changesObj.noAdvance.currentValue;
            if (!changesObj.noAdvance.isFirstChange()) {
                shouldReininitateEditMode = true;
            }
        }
        if (changesObj.existingFieldDefinition) {
            $scope.data.existingFieldDefinition = changesObj.existingFieldDefinition.currentValue;
            if (!changesObj.existingFieldDefinition.isFirstChange()) {
                shouldReininitateEditMode = true;
            }
        }
        if (changesObj.fieldDefinitionName) {
            $scope.data.fieldDefinitionName = changesObj.fieldDefinitionName.currentValue;
        }
        if (changesObj.fieldDefinitionNameEdited) {
            $scope.data.fieldDefinitionNameEdited = changesObj.fieldDefinitionNameEdited.currentValue;
        }
        if (changesObj.aggregationOnColumnFeatureName) {
            $scope.data.aggregationOnColumnFeatureName = changesObj.aggregationOnColumnFeatureName.currentValue;
        }
        if (changesObj.columnFormulaFeatureName) {
            $scope.data.columnFormulaFeatureName = changesObj.columnFormulaFeatureName.currentValue;
        }
        if (changesObj.onFormulaSelected) {
            $scope.data.onFormulaSelected = changesObj.onFormulaSelected.currentValue;
        }

        if (shouldReininitateEditMode) {
            initializeEditMode();
            if (isPartOfEnvironmentChange) {
                $scope.data.customFiltersControl?.reloadFromExistingDefinition?.(
                    $scope.data.existingFieldDefinition.definition.aggregateQueryDefinition,
                );
            }
        }
    };

    /**
     * Toggles the formula definition type.
     */
    $scope.toggleFormulaDefinitionType = function () {
        $scope.data.advancedFormulaSelected = !$scope.data.advancedFormulaSelected;
        definitionChanged(true);
    };

    /**
     * Occurs once the aggregation type is changed.
     */
    $scope.onAggregationTypeChanged = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once the custom filters are changed.
     */
    $scope.onCustomFiltersChanged = function (shouldNotSaveLogic) {
        definitionChanged(!shouldNotSaveLogic);
    };

    /**
     * Occurs once the custom filters are changed.
     */
    $scope.onRunRecursivelyOnInnerItemsChanged = function (value) {
        $scope.data.shouldRunRecursivelyOnParents = value;
        definitionChanged(true);
    };

    /**
     * Once an aggregated column is selected.
     */
    $scope.onSelectedAggColumn = function (selectedField, dontSaveChanges) {
        $scope.data.aggregatedFieldDefinition = selectedField;
        definitionChanged(!dontSaveChanges);
    };

    /**
     * Occurs once the formula expression is changed.
     */
    $scope.onFormulaExpressionChanged = function (
        evaluatedFormulaExpression,
        validationResult,
        originalFormulaExpression,
        variablesUsedInExpression,
        isInit,
    ) {
        $scope.data.evaluatedFormulaExpression = evaluatedFormulaExpression;
        $scope.data.validationResult = validationResult;
        $scope.data.formulaExpression = originalFormulaExpression;
        $scope.data.variablesUsedInExpression = variablesUsedInExpression;

        const anyColumnDefinitionsUsed = utils.findFirst(
            $scope.data.variablesUsedInExpression,
            (variable) => variable.isColumnFieldDefinition,
        );
        $scope.data.shouldDisplayFilterConditions = !!anyColumnDefinitionsUsed;

        definitionChanged(!isInit);
    };

    /**
     * Initialized the component for edit mode.
     */
    function initializeEditMode() {
        $scope.data.advancedFormulaSelected =
            !$scope.data.noAdvance && $scope.data.existingFieldDefinition.definition.formulaType === 'EXPRESSION';

        if ($scope.data.advancedFormulaSelected) {
            $scope.data.formulaExpression = $scope.data.existingFieldDefinition.definition.originalFormulaExpression;
            $scope.data.evaluatedFormulaExpression = $scope.data.existingFieldDefinition.definition.formulaExpression;
        } else {
            if ($scope.data.existingFieldDefinition.definition.aggregationType) {
                $scope.data.aggregationType = $scope.data.aggregationTypes.find(
                    (aggregationType) =>
                        aggregationType.id === $scope.data.existingFieldDefinition.definition.aggregationType,
                );
            }

            if ($scope.data.existingFieldDefinition.definition.aggregatedDefinitionId) {
                const existingFieldDefinition =
                    utils.findFirst(
                        customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                        (fieldDefinition) =>
                            fieldDefinition.id ===
                            $scope.data.existingFieldDefinition.definition.aggregatedDefinitionId,
                    ) ||
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[
                        $scope.data.existingFieldDefinition.definition.aggregatedDefinitionId
                    ] ||
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[
                        $scope.data.existingFieldDefinition.definition.aggregatedDefinitionName
                    ];

                if (existingFieldDefinition) {
                    $scope.data.aggregatedFieldDefinition = {
                        name: existingFieldDefinition.id,
                        label: existingFieldDefinition.name,
                        projectIntegration: existingFieldDefinition.projectIntegration
                            ? { id: existingFieldDefinition.projectIntegration.id }
                            : null,
                    };
                } else {
                    $scope.data.definitionDoesNotExist = true;
                }
            }
        }

        if ($scope.data.existingFieldDefinition.definition.orderByFieldDefinitionId) {
            const orderByFieldDefinition = utils.findFirst(
                customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                (fieldDefinition) =>
                    fieldDefinition.id === $scope.data.existingFieldDefinition.definition.orderByFieldDefinitionId,
            );
            $scope.data.orderByFieldDefinition = orderByFieldDefinition;
        }

        if ($scope.data.existingFieldDefinition.definition) {
            $scope.data.reduceFunction =
                $scope.data.existingFieldDefinition.definition.aggregateQueryDefinition?.reduceAggregationDefinition?.reduceFunction;
            $scope.data.concatenationSeparator =
                $scope.data.existingFieldDefinition.definition.aggregateQueryDefinition?.reduceAggregationDefinition?.reduceFunctionDefinition?.concatenationSeparator;
            $scope.data.shouldRunRecursivelyOnParents =
                $scope.data.existingFieldDefinition.definition.shouldRunRecursivelyOnParents;
        }

        $scope.data.groupByAggregateField =
            $scope.data.existingFieldDefinition?.definition?.aggregateQueryDefinition?.groupByAggregationDefinition?.aggregateByField;
        $scope.data.groupByFunction =
            $scope.data.apiNameToGroupByFunctionMap[
                $scope.data.existingFieldDefinition.definition?.aggregateQueryDefinition?.groupByAggregationDefinition
                    ?.groupByFunction || 'Count'
            ];

        const apiNameToOrderObjectMap = utils.createMapFromArray($scope.data.orderTypes, 'apiName');
        $scope.data.orderBySelectedOrder =
            apiNameToOrderObjectMap[$scope.data.existingFieldDefinition.definition.orderByOrder];
    }

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderBySelectedOrderSelected = function (selectedOrder) {
        $scope.data.orderBySelectedOrder = selectedOrder;
        definitionChanged(true);
    };

    $scope.shouldAggregationTypeBeShown = function (value) {
        return !($scope.data.targetType === 'GLOBAL' && value === 'Reduce');
    };

    /**
     * Occurs once an order by field definition is selected.
     */
    $scope.onFieldOptionSelected = function (selectedField, dontSaveChanges) {
        $scope.data.orderByFieldDefinition = selectedField;
        definitionChanged(!dontSaveChanges);
    };

    /**
     * Retrieves all the field definitions participating in aggregate on column definition.
     */
    function getParticipatingDefinitionObjects(definition) {
        let allParticipatingDefinitionIds = [];

        // Getting the field definition ids participating in the query.
        if (definition.aggregateQueryDefinition.query) {
            allParticipatingDefinitionIds = allParticipatingDefinitionIds.concat(
                getTonkeanQueryParticipatingFieldNames(definition.aggregateQueryDefinition.query),
            );
        }

        // Getting the field definition ids for each type of definition.
        switch (definition.formulaType) {
            case 'STRUCTURED':
                if (definition.aggregatedDefinitionId) {
                    allParticipatingDefinitionIds.push(definition.aggregatedDefinitionId);
                }
                break;

            case 'EXPRESSION':
                allParticipatingDefinitionIds = allParticipatingDefinitionIds.concat(
                    getVariablesUsedInFormulaExpression(definition.formulaExpression),
                );
                break;
        }

        if (!allParticipatingDefinitionIds || !allParticipatingDefinitionIds.length) {
            return [];
        } else {
            return utils
                .getUniqueValues(allParticipatingDefinitionIds)
                .map((definitionId) => $scope.data.variableIdToVariableMap[definitionId]);
        }
    }

    /**
     * Gets any additional field definitions we might need to preview.
     */
    function getAdditionalFieldDefinitionsToPreview(definition) {
        const participatingDefinitions = getParticipatingDefinitionObjects(definition);
        if (!participatingDefinitions || !participatingDefinitions.length) {
            return {};
        }

        const initiativeDefinitionsToPreview = participatingDefinitions
            .filter((definitionObject) => definitionObject.isColumnFieldDefinition)
            .map((definitionObject) => {
                return {
                    definitionId: definitionObject.id,
                    name: definitionObject.name,
                    fieldDefinitionTargetType: 'COLUMN',
                    validDefinition: true,
                    // If it's a special field, it's an initiative field. Otherwise, it's an existing field definition.
                    previewDefinitionType: definitionObject.isSpecialField
                        ? 'INITIATIVE_FIELD'
                        : 'EXISTING_FIELD_DEFINITION',
                };
            });

        const additionalGlobalFieldsToPreview = participatingDefinitions
            .filter((definitionObject) => !definitionObject.isColumnFieldDefinition)
            .map((definitionObject) => {
                return {
                    definitionId: definitionObject.id,
                    name: definitionObject.name,
                    fieldDefinitionTargetType: 'GLOBAL',
                    validDefinition: true,
                    previewDefinitionType: 'EXISTING_FIELD_DEFINITION',
                };
            });

        return {
            initiativeDefinitionsToPreview,
            additionalGlobalFieldsToPreview,
        };
    }

    /**
     * Occurs on the change of the concatenation separator.
     */
    $scope.onConcatenationSeparatorChanged = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once a reduce function is selected.
     */
    $scope.reduceFunctionSelected = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once a groupBy function is selected.
     */
    $scope.groupByFunctionSelected = function () {
        definitionChanged(true);
    };

    /**
     * Selecting a field in the search input box for group by aggregate field.
     */
    $scope.selectGroupByAggregateField = function (aggregatedField, dontSaveChanges) {
        const previousAggregatedField = $scope.data.groupByAggregateField;

        $scope.data.groupByAggregateField = aggregatedField;

        definitionChanged(previousAggregatedField !== aggregatedField && !dontSaveChanges);
    };

    function validateDefinition(definition, evaluatedAggregatedFieldDefinition) {
        if (
            definition.aggregationType.id === 'Reduce' &&
            !definition.aggregateQueryDefinition.reduceAggregationDefinition?.reduceFunction
        ) {
            return false;
        }

        if (
            definition.aggregationType === 'Terms' &&
            definition.groupByAggregationDefinition &&
            definition.groupByAggregationDefinition.groupByFunction !== 'Count' &&
            !definition.groupByAggregationDefinition.aggregateByField
        ) {
            return false;
        }

        return (
            $scope.data.aggregationType.id === 'Count' ||
            $scope.data.aggregationType.id === 'Reduce' ||
            evaluatedAggregatedFieldDefinition
        );
    }

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged(hadChanges = false) {
        let fieldConfigurationSummaryTitle = '';
        let fieldConfigurationSummarySubTitle = '';
        let evaluatedAggregatedFieldDefinition = null;
        let definition = {};
        let validDefinition = false;

        // Constructing query
        let query = {
            query: getDefaultTonkeanQuery(),
        };
        if ($scope.data.customFiltersControl && $scope.data.customFiltersControl.createDefinitionFromCustomFilters) {
            query = $scope.data.customFiltersControl.createDefinitionFromCustomFilters();
        }

        // Constructing subtitle
        if (query) {
            const filtersCount = countQueryFilters(query.query);
            if (filtersCount) {
                fieldConfigurationSummarySubTitle += `${filtersCount} conditions`;
            }
        }

        if ($scope.data.advancedFormulaSelected) {
            validDefinition =
                $scope.data.formulaExpression &&
                (!$scope.data.validationResult || $scope.data.validationResult.validFormulaExpression);
            fieldConfigurationSummaryTitle = 'Aggregation Formula';

            definition = {
                formulaType: 'EXPRESSION',
                formulaExpression: $scope.data.evaluatedFormulaExpression,
                originalFormulaExpression: $scope.data.formulaExpression,
                aggregateQueryDefinition: query,
                groupId: $scope.data.groupId,
            };
        } else {
            // Constructing summary title
            if ($scope.data.aggregatedFieldDefinition) {
                evaluatedAggregatedFieldDefinition =
                    utils.findFirst(
                        customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                        (fieldDefinition) => fieldDefinition.id === $scope.data.aggregatedFieldDefinition.name,
                    ) ||
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[$scope.data.aggregatedFieldDefinition.id] ||
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[$scope.data.aggregatedFieldDefinition.name];
            }

            fieldConfigurationSummaryTitle = $scope.data.aggregationType.displayName;
            if (evaluatedAggregatedFieldDefinition) {
                fieldConfigurationSummaryTitle += ` of ${evaluatedAggregatedFieldDefinition.name}`;
            } else {
                fieldConfigurationSummaryTitle += ' of Items';
            }

            if ($scope.data.reduceFunction) {
                query.reduceAggregationDefinition = {
                    reduceFunction: $scope.data.reduceFunction,
                    reduceFunctionDefinition: {
                        concatenationSeparator: $scope.data.concatenationSeparator || undefined,
                    },
                };
            }

            if ($scope.data.groupByFunction) {
                query.groupByAggregationDefinition = {
                    groupByFunction: $scope.data.groupByFunction.apiName,
                    aggregateByField: $scope.data.groupByAggregateField,
                };
            }

            definition = {
                formulaType: 'STRUCTURED',
                aggregationType: $scope.data.aggregationType.id,
                aggregatedDefinitionId: evaluatedAggregatedFieldDefinition
                    ? evaluatedAggregatedFieldDefinition.id
                    : null,
                aggregatedDefinitionName: evaluatedAggregatedFieldDefinition
                    ? evaluatedAggregatedFieldDefinition.name
                    : null,
                aggregateQueryDefinition: query,
                shouldRunRecursivelyOnParents: $scope.data.shouldRunRecursivelyOnParents,
                groupId: $scope.data.groupId,
            };

            validDefinition = validateDefinition(definition, evaluatedAggregatedFieldDefinition);
        }

        // Gathering the additional global fields and initiative field definitions we need to preview.
        const additionalFieldDefinitionsToPreview = getAdditionalFieldDefinitionsToPreview(definition);

        let fieldType;

        if ($scope.data.reduceFunction === 'Concat' || $scope.data.aggregationType.id === 'Terms') {
            fieldType = 'String';
        } else if (
            $scope.data.aggregatedFieldDefinition &&
            $scope.data.variableIdToVariableMap[$scope.data.aggregatedFieldDefinition.name]
        ) {
            switch (
                $scope.data.variableIdToVariableMap[$scope.data.aggregatedFieldDefinition.name].evaluatedDisplayType
            ) {
                case 'Date':
                    fieldType = 'Date';
                    break;

                default:
                    fieldType = 'Number';
                    break;
            }
        } else {
            fieldType = 'Number';
        }

        if ($scope.data.orderByFieldDefinition) {
            definition.orderByFieldDefinitionId = $scope.data.orderByFieldDefinition.id;
        }
        if ($scope.data.orderBySelectedOrder) {
            definition.orderByOrder = $scope.data.orderBySelectedOrder.apiName;
        }

        const skipStepsMap = {};
        skipStepsMap['displayConfiguration'] = $scope.data.aggregationType.id === 'Terms';

        const definitionObject = {
            fieldConfigurationSummaryTitle,
            fieldConfigurationSummarySubTitle,
            definition,
            validDefinition: !!validDefinition,
            updateable: false,
            fieldType,
            evaluatedDisplayType: fieldType,
            displayAs: fieldType,
            projectIntegrationId:
                evaluatedAggregatedFieldDefinition && evaluatedAggregatedFieldDefinition.projectIntegration
                    ? evaluatedAggregatedFieldDefinition.projectIntegration.id
                    : null,
            additionalDefinitionsToPreview: additionalFieldDefinitionsToPreview.additionalGlobalFieldsToPreview,
            initiativeDefinitionsToPreview: additionalFieldDefinitionsToPreview.initiativeDefinitionsToPreview,
            skipStepsMap,
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
            });
        }

        if (hadChanges && ctrl.onChange) {
            ctrl.onChange();
        }
    }
}

angular
    .module('tonkean.app')
    .controller('AggregateOnColumnFieldCtrl', lateConstructController(AggregateOnColumnFieldCtrl));
