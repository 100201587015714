import type { SQLRequestDefinition } from '@tonkean/tonkean-entities';
import { ActionDefinitionType } from '@tonkean/tonkean-entities';

const defaultCustomSQLActionDefinition: SQLRequestDefinition = {
    query: {
        originalExpression: '',
        evaluatedExpression: '',
        isStripHtmlDisabled: false,
    },
    definitionType: ActionDefinitionType.SQL,
};

export default defaultCustomSQLActionDefinition;
