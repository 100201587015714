import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionHeader from './ProjectIntegrationActionHeader';

import { ModalHeader, XCloseButton } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const IntegrationCustomActionHeader = styled(ModalHeader)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    z-index: 1;
`;

const HeaderSectionItem = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
`;

interface Props {
    onCancel: () => void;
    projectIntegration: ProjectIntegration;
    projectIntegrationActionNames: string[];
    setIsNameValid: (value: boolean) => void;
    defaultName?: string;
    setName: Dispatch<SetStateAction<string>>;
}

const ProjectIntegrationActionCreateModalHeader: React.FC<Props> = ({
    onCancel,
    projectIntegration,
    projectIntegrationActionNames,
    setIsNameValid,
    defaultName = '',
    setName,
}) => {
    return (
        <IntegrationCustomActionHeader $border>
            <HeaderSectionItem>
                <ProjectIntegrationActionHeader
                    defaultName={defaultName}
                    onChange={(changedName) => {
                        setName(changedName);
                        setIsNameValid(true);
                    }}
                    onChangeNameError={() => {
                        setIsNameValid(false);
                    }}
                    projectIntegration={projectIntegration}
                    projectIntegrationActionNames={projectIntegrationActionNames}
                />
            </HeaderSectionItem>
            <XCloseButton size={ButtonSize.MEDIUM} onClick={() => onCancel()} />
        </IntegrationCustomActionHeader>
    );
};

export default ProjectIntegrationActionCreateModalHeader;
