import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as UnionIcon } from '../../../../../images/icons/union.svg';
import type HistoryField from '../../entities/HistoryField';
import type HistoryTableGroup from '../../utils/HistoryTableGroup';

import { FieldTypeIcon } from '@tonkean/fields';
import {
    Chevron,
    ChevronDirection,
    CollapsibleContent,
    HighlightableText,
    TextEllipsis,
} from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { FieldType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize, Theme } from '@tonkean/tui-theme';

const ContentColumn = styled.div`
    // To prevent grid item grow
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
`;

const ContentName = styled(ContentColumn)`
    display: flex;
    align-items: center;
    margin-left: 42px;
`;

const FinalValue = styled(ContentColumn)<{ changed?: boolean }>`
    ${({ changed }) =>
        changed &&
        css`
            color: ${Theme.colors.gray_800};
            font-weight: 500;
        `};
`;

const ContentRow = styled.div<{ changed?: boolean }>`
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    min-height: 30px;
    padding: 10px 20px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    border-bottom: 1px solid ${Theme.colors.gray_300};

    ${({ changed }) =>
        changed &&
        css`
            background: ${Theme.colors.HEX_FFF8EE};
        `};
`;

const GroupNameToggle = styled.button`
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    background: rgba(252, 252, 252);
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    transition: all 0.3s ease-in-out;
    min-height: 30px;
    padding: 10px 20px;
    color: ${Theme.colors.gray_700};
    border-bottom: 1px solid ${Theme.colors.gray_300};

    &:hover {
        background: rgba(248, 248, 248, 1);
    }

    ${styledFocus}
`;

const GroupIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
`;

const StyledChevron = styled(Chevron)`
    margin-right: 10px;
`;

const ProjectIntegrationIconStyled = styled(ProjectIntegrationIcon).attrs({ width: 14 })`
    margin-right: 10px;
    flex-shrink: 0;
`;

const FieldTypeIconStyled = styled(FieldTypeIcon)`
    margin-right: 10px;
    flex-shrink: 0;
`;

const UnionIconStyled = styled(UnionIcon)`
    margin-right: 10px;
    width: 14px;
    flex-shrink: 0;
`;

interface Props {
    fieldsGroup: HistoryTableGroup;
    unFilteredFieldsGroups: HistoryTableGroup[];
    toggleGroupOpenState: (fieldsGroupId: string, isOpen: boolean) => void;
    isOpen?: boolean;
    freeTextFilter: string;
    couldHaveChanged: boolean;
}

const HistoryFieldsTableFieldsGroup: React.FC<Props> = ({
    unFilteredFieldsGroups,
    fieldsGroup,
    toggleGroupOpenState,
    isOpen = true,
    freeTextFilter,
    couldHaveChanged,
}) => {
    const groupId = fieldsGroup.id;

    const unFilteredFieldCount = useMemo(
        () => unFilteredFieldsGroups.find((tableGroup) => tableGroup.id === groupId)?.fields.length,
        [groupId, unFilteredFieldsGroups],
    );

    const fieldDisplay = useAngularService('fieldDisplay');

    const isMatchedItemFieldsGroup = fieldsGroup.fields.find(
        (field) => field.fieldDefinition?.definition?.matchConfiguration?.isForMatchingItem,
    );

    useEffect(() => {
        toggleGroupOpenState(groupId, true);
    }, [freeTextFilter, groupId, toggleGroupOpenState]);

    const getInitialFieldValue = (field: HistoryField) => {
        const initialValue = field.value.initialValue;
        if (initialValue) {
            if (field.fieldDefinition?.fieldType === FieldType.Date) {
                return fieldDisplay.evaluateFieldDisplayValue(
                    FieldType.Date,
                    field.fieldDefinition.displayFormat,
                    initialValue,
                    initialValue,
                    initialValue,
                );
            }

            return initialValue;
        }

        // If the field could not have changed, we can use the final value, if it's being passed by mistake instead of passing it as the
        // initial value.
        const finalValue = field.value.finalValue;
        if (!couldHaveChanged && finalValue) {
            if (field.fieldDefinition?.fieldType === FieldType.Date) {
                return fieldDisplay.evaluateFieldDisplayValue(
                    FieldType.Date,
                    field.fieldDefinition.displayFormat,
                    finalValue,
                    finalValue,
                    finalValue,
                );
            }

            return finalValue;
        }

        return '-';
    };

    const getFinalValue = useCallback(
        (fieldChange: HistoryField) => {
            if (!fieldChange?.value?.finalValue) {
                return '-';
            }

            if (fieldChange.fieldDefinition?.fieldType === FieldType.Date) {
                return fieldDisplay.evaluateFieldDisplayValue(
                    FieldType.Date,
                    fieldChange.fieldDefinition.displayFormat,
                    fieldChange.value.finalValue,
                    fieldChange.value.finalValue,
                    fieldChange.value.finalValue,
                );
            }

            return fieldChange.value.finalValue;
        },
        [fieldDisplay],
    );

    return (
        <div>
            <GroupNameToggle
                data-automation="history-fields-table-fields-group"
                data-automation-display-name={fieldsGroup.displayName}
                onClick={() => toggleGroupOpenState(fieldsGroup.id, !isOpen)}
            >
                <GroupIcons>
                    <StyledChevron direction={isOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />

                    {isMatchedItemFieldsGroup && <UnionIconStyled />}

                    {fieldsGroup.integrationType && !isMatchedItemFieldsGroup && (
                        <ProjectIntegrationIconStyled
                            projectIntegrationId={fieldsGroup.projectIntegrationId}
                            integrationType={fieldsGroup.integrationType}
                        />
                    )}

                    {!fieldsGroup.integrationType && !isMatchedItemFieldsGroup && (
                        <FieldTypeIconStyled fieldType={fieldsGroup.type.toUpperCase()} size="sm" />
                    )}
                </GroupIcons>

                <span>
                    {fieldsGroup.displayName} ({fieldsGroup.fields.length}/{unFilteredFieldCount})
                </span>
            </GroupNameToggle>

            <CollapsibleContent open={isOpen}>
                {fieldsGroup.fields.map((fieldChange) => (
                    <ContentRow key={fieldChange.id} changed={fieldChange.value.hasChanged}>
                        <ContentName>
                            {fieldChange.fieldDefinition?.definition?.matchConfiguration?.isForMatchingItem && (
                                <UnionIconStyled />
                            )}

                            {!fieldChange.fieldDefinition?.definition?.matchConfiguration?.isForMatchingItem &&
                                fieldsGroup.integrationType &&
                                fieldChange.integrationType && (
                                    <ProjectIntegrationIconStyled
                                        projectIntegrationId={fieldChange.projectIntegrationId}
                                        integrationType={fieldChange.integrationType}
                                        width={14}
                                    />
                                )}

                            {fieldsGroup.type === 'Manual' && (
                                <FieldTypeIconStyled fieldType={fieldChange.type || ''} size="sm" />
                            )}

                            <span>
                                <HighlightableText
                                    data-automation={`history-fields-table-fields-group-field-name-${fieldChange.displayName}`}
                                    text={fieldChange.displayName || '-'}
                                    highlightText={freeTextFilter}
                                />
                            </span>
                        </ContentName>

                        <ContentColumn>
                            <TextEllipsis disabled={!!freeTextFilter} numberOfLines={3} showMore>
                                <HighlightableText
                                    data-automation={`history-fields-table-fields-group-before-action-${fieldChange.displayName}`}
                                    text={getInitialFieldValue(fieldChange)}
                                    highlightText={freeTextFilter}
                                />
                            </TextEllipsis>
                        </ContentColumn>

                        {couldHaveChanged && (
                            <FinalValue changed={fieldChange.value.hasChanged}>
                                <TextEllipsis disabled={!!freeTextFilter} numberOfLines={3} showMore>
                                    <HighlightableText
                                        text={getFinalValue(fieldChange)}
                                        data-automation={`history-fields-table-fields-group-field-after-action-${fieldChange.displayName}`}
                                        highlightText={freeTextFilter}
                                    />
                                </TextEllipsis>
                            </FinalValue>
                        )}
                    </ContentRow>
                ))}
            </CollapsibleContent>
        </div>
    );
};

export default HistoryFieldsTableFieldsGroup;
