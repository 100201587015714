import { FormQuestionType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function BotPostMessageActionConfigurationCtrl(
    $rootScope,
    $scope,
    $q,
    tonkeanUtils,
    modal,
    customTriggerManager,
    communicationIntegrationsService,
    formManager,
    consts,
    utils,
    syncConfigCacheManager,
    workflowVersionManager,
) {
    const ctrl = this;
    const logicBlockTypes = consts.getLogicBlockTypes();

    $scope.cis = communicationIntegrationsService;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        customTriggerActionType: ctrl.customTriggerActionType,
        onFormSelectedOpenConfirmation: ctrl.onFormSelectedOpenConfirmation,
        openFieldModal: ctrl.openFieldModal,

        isUrlLink: false,
        shouldEncodeUrl: true,
        urlLink: '',
        evaluatedUrlLink: '',
        redirectDelay: 5,
        redirectToNewTab: true,
        inputVisible: true,

        webformResponseMessage: null,
        evaluatedWebformResponseMessage: null,

        webformResponseMessageSubtitle: '',
        evaluatedWebformResponseMessageSubtitle: '',

        customResponseMessage: null,

        customInterfaceLabel: null,

        waitingConditionMessage: null,
        evaluatedWaitingConditionMessage: null,
        isOneTime: false,
        sendResponseToChannel: false,
        replyOnOriginalMessage: true,
        waitFormConditions: { query: { type: 'And', filters: [], test: 'value' } },
        selectedReplyTo: 'original',

        previewMessageText: '',
        previewMessageButtons: [],
        previewReplyText: '',
        previewReplyEvaluateText: '',
        showWebFormResponse: true,

        updateOriginalMessageDisabled: false,
        parentFormType: null,
        parentIsChannel: false,
        parentForceEmail: false,
        parentAskForInfoType: null,
        selectedActionType: 'continue-flow',
        formId: null,
        form: null,
        editFormId: null,
        formType: 'PREVIOUS_PLATFORM',
        formLocation: 'NEW',
        borderViewType: 'MULTIPLE_FORMS',
        // isUnderIntercomFlow param indicates whether the trigger is under intercom flow so we can
        // hide advanced features like Form Preview and Custom Response Message which aren't relevant to intercom.
        isUnderIntercomFlow: false,
        showPreviousFormPlatformRadioOption: false,

        postFormFieldsToUpdate: [{}],
        formMatchedEntityFieldId: null,
        changedActionTypeIsLoading: false,
        control: {},
        waitFormType: 'IMMEDIATELY',
        disableStorageSelection: false,

        itemInterfaceId: null,
        enableChoosingGoToSequence: false,
    };

    ctrl.$onInit = function () {
        initializeEditMode();

        if (
            utils.isNullOrEmpty($scope.data.webformResponseMessage) &&
            utils.isNullOrEmpty($scope.data.evaluatedWebformResponseMessage)
        ) {
            const defaultWebformResponseMessage =
                $scope.data.customTriggerActionType === 'ASK_FIELD_UPDATE_ANSWERED'
                    ? 'Thank you for filling out the information!'
                    : 'Thank you for your submission!';

            $scope.data.webformResponseMessage = defaultWebformResponseMessage;
            $scope.data.evaluatedWebformResponseMessage = defaultWebformResponseMessage;
        }

        $scope.onActionsChanged(false);

        $rootScope.$on('formUpdate', (event, form) => {
            if ($scope.data.formId === form?.id) {
                $scope.data.form = form;
            }
        });

        $scope.data.enableChoosingItemInterfaceInSequence = false;
        
    };

    /**
     * Occurs once the filters for the wait form changed.
     */
    $scope.onFilterChange = function (shouldNotSaveLogic) {
        if ($scope.data.control && $scope.data.control.createDefinitionFromCustomFilters) {
            $scope.data.waitFormConditions = $scope.data.control.createDefinitionFromCustomFilters();
        }

        $scope.onActionsChanged(!shouldNotSaveLogic);
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            const logicData = customTriggerManager.findLogicDataInGraphById(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node.id,
            );
            if (logicData) {
                const parent = logicData.parent;
                const parentAction = tonkeanUtils.getFirstCustomTriggerActionOfTypes(parent.node.customTriggerActions, [
                    'PERSON_INQUIRY',
                    'SEND_FORM',
                    'BOT_BUTTON_PRESSED',
                    'ASK_FIELD_UPDATE',
                    'SEND_FORM_ANSWERED',
                ]);

                const parentActionWithCommunicationIntegrationLogic =
                    customTriggerManager.getFirstParentOfTypesWorkflowVersion(
                        $scope.data.workflowVersionId,
                        $scope.data.configuredLogic.node.id,
                        ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                        (parentLogic) => {
                            const parentLogicAction = tonkeanUtils.getFirstCustomTriggerActionOfTypes(
                                parentLogic.node.customTriggerActions,
                                ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                            );

                            return !!parentLogicAction.customTriggerActionDefinition.communicationProjectIntegrationId;
                        },
                    );

                let parentActionWithCommunicationIntegration;
                let parentCommunicationIntegration;
                if (parentActionWithCommunicationIntegrationLogic) {
                    parentActionWithCommunicationIntegration = tonkeanUtils.getFirstCustomTriggerActionOfTypes(
                        parentActionWithCommunicationIntegrationLogic.node.customTriggerActions,
                        ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                    );

                    parentCommunicationIntegration = $scope.cis.getIntegrationById(
                        parentActionWithCommunicationIntegration.customTriggerActionDefinition
                            .communicationProjectIntegrationId,
                    );
                }

                let parentActionReplyToRootItemLogic = customTriggerManager.getDirectParentInWorkflowVersion(
                    $scope.data.workflowVersionId,
                    $scope.data.configuredLogic.node.id,
                    (parentLogic) => {
                        const parentLogicAction = tonkeanUtils.getFirstCustomTriggerActionOfTypes(
                            parentLogic.node.customTriggerActions,
                            ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                        );

                        return !!parentLogicAction
                            ? parentLogicAction.customTriggerActionDefinition.replyToRootMonitoredItem
                            : false;
                    },
                );

                if (!parentActionReplyToRootItemLogic && !parentActionWithCommunicationIntegrationLogic) {
                    parentActionReplyToRootItemLogic = customTriggerManager.getFirstParentOfTypesWorkflowVersion(
                        $scope.data.workflowVersionId,
                        $scope.data.configuredLogic.node.id,
                        ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                        (parentLogic) => {
                            const parentLogicAction = tonkeanUtils.getFirstCustomTriggerActionOfTypes(
                                parentLogic.node.customTriggerActions,
                                ['PERSON_INQUIRY', 'SEND_FORM', 'ASK_FIELD_UPDATE'],
                            );

                            return !!parentLogicAction
                                ? parentLogicAction.customTriggerActionDefinition.replyToRootMonitoredItem
                                : false;
                        },
                    );
                }

                const hasSlackMessagesAsDataSource = syncConfigCacheManager.workflowVersionHasDatasourceOfType(
                    $scope.data.workflowVersionId,
                    'SLACK_APP',
                    'Message',
                );

                const isAutonomousWhenFormFilledParent =
                    parentAction?.forceEmail === undefined &&
                    parentActionReplyToRootItemLogic === undefined &&
                    parentActionWithCommunicationIntegration === undefined;

                const isReplyInThreadAndModuleHadSlackAppSynced =
                    parentActionReplyToRootItemLogic && hasSlackMessagesAsDataSource;

                const isParentCommunicationIntegrationOfTypeSlack =
                    !parentActionWithCommunicationIntegration?.customTriggerActionDefinition.forceEmail &&
                    parentCommunicationIntegration?.integrationType === 'SLACK';

                if (parentAction) {
                    $scope.data.parentForceEmail =
                        parentAction.forceEmail ||
                        (!isReplyInThreadAndModuleHadSlackAppSynced &&
                            !isParentCommunicationIntegrationOfTypeSlack &&
                            !isAutonomousWhenFormFilledParent);

                    $scope.data.previewMessageText = parentAction.customTriggerActionDefinition.text;
                    $scope.data.previewMessageEvaluateText = parentAction.customTriggerActionDefinition.evaluatedText;
                    $scope.data.color = parentAction.customTriggerActionDefinition.color;
                    $scope.data.parentIsChannel =
                        parentAction.customTriggerActionDefinition.notificationSettings ||
                        parentAction.customTriggerActionDefinition.sendResponseToChannel;
                    $scope.data.parentSendAsPlainText = parentAction.customTriggerActionDefinition.sendAsPlainText;
                }

                if ($scope.data.customTriggerActionType === 'ASK_FIELD_UPDATE_ANSWERED') {
                    const directParent = customTriggerManager.getDirectParentInWorkflowVersion(
                        $scope.data.workflowVersionId,
                        $scope.data.configuredLogic.node.id,
                    );

                    if (directParent?.node?.customTriggerActions[0]?.customTriggerActionDefinition?.forceEmail) {
                        $scope.data.parentAskForInfoType = 'WEBFORM';
                    } else {
                        if (parentCommunicationIntegration?.integrationType === 'MICROSOFT_TEAMS') {
                            $scope.data.parentFormType = 'WEBFORM';
                        }
                    }
                }

                if ($scope.data.customTriggerActionType === 'SEND_FORM_ANSWERED') {
                    $scope.data.previewMessageButtons = [
                        {
                            text:
                                parentAction &&
                                parentAction.customTriggerActionDefinition &&
                                parentAction.customTriggerActionDefinition.evaluatedFillFormButtonLabel
                                    ? parentAction.customTriggerActionDefinition.evaluatedFillFormButtonLabel
                                    : 'Fill Form',
                        },
                    ];

                    const directParent = customTriggerManager.getDirectParentInWorkflowVersion(
                        $scope.data.workflowVersionId,
                        $scope.data.configuredLogic.node.id,
                    );
                    const directParentCustomTriggerType = directParent?.node?.customTriggerType;

                    if (
                        directParent &&
                        (directParentCustomTriggerType === 'SEND_FORM' ||
                            directParentCustomTriggerType === 'SEND_FORM_ANSWERED')
                    ) {
                        // Extracting the parent form type in order to know whether we should display the 'Webform response message',
                        $scope.data.parentFormType =
                            directParent.node.customTriggerActions[0].customTriggerActionDefinition.formType;
                    } else if (
                        directParent &&
                        directParentCustomTriggerType === 'AUTONOMOUS' &&
                        directParent.node.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM'
                    ) {
                        // If the direct parent is AUTONOMOUS_CREATED_FROM_FORM we would like to disable the 'update original message' option
                        // because there are ways which user can fill the from and it's not through a message. Which means, there is no original message to update.
                        $scope.data.updateOriginalMessageDisabled = true;
                        $scope.data.selectedReplyTo = 'person';
                        $scope.data.replyOnOriginalMessage = false;

                        // If the parent trigger is "Form filled", we cant be sure if the form was a slack form or a web form
                        // so we give the option to configure the response.
                        $scope.data.parentFormType = 'WEBFORM';
                    }
                } else {
                    $scope.data.previewMessageButtons = parent.impacts
                        .filter((impact) => impact.node.customTriggerType === logicBlockTypes.BOT_BUTTON_PRESSED.type)
                        .map((impact) => {
                            return {
                                text: impact.node.displayName,
                            };
                        });
                }
            }

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
    };

    $scope.onFormSelectedOpenConfirmationWrap = function (form) {
        const isFormFromOtherGroup = !formManager.getWorkerFormFromCache($scope.data.workflowVersionId, form.id);
        if (isFormFromOtherGroup && $scope.data.configuredLogic.impacts.length) {
            $scope.$emit('alert', {
                msg: "Can't select form from other modules if it has child logic blocks",
                type: 'warning',
            });
            return false;
        } else if ($scope.data.onFormSelectedOpenConfirmation) {
            return $scope.data.onFormSelectedOpenConfirmation(form);
        } else {
            return $q.resolve();
        }
    };

    $scope.onConfiguredPostFormFieldsChanged = (configuredFields, shouldSave) => {
        $scope.data.postFormFieldsToUpdate = configuredFields;
        $scope.onActionsChanged(shouldSave);
    };

    /**
     * Opens edit form modal.
     */
    $scope.editForm = function (formId, formType) {
        $rootScope.$broadcast('editForm', { formId, formType });
    };

    $scope.onShowWebFormResponseToggled = function (isActive) {
        $scope.data.showWebFormResponse = isActive;
        $scope.onActionsChanged(true);
    };

    /**
     * Opens a confirmation modal in case we need to remove monitor items trigger
     */
    $scope.onFormSelectedOpenConfirmation = function (form) {
        let confirmationPromise = $q.resolve();

        if (
            $scope.data.form?.formQuestionType === 'COLLECT_INNER_ITEMS' &&
            form.formQuestionType !== 'COLLECT_INNER_ITEMS' &&
            hasAnyMonitorItemsImpacts()
        ) {
            confirmationPromise = modal.openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-warning',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Change form type',
                            body: 'Please remove all Monitor Items action blocks before changing form.',
                            cancelLabel: 'Close',
                        };
                    },
                },
            }).result;
        }

        return confirmationPromise;
    };

    /**
     * Occurs when the form type radio button selection is changed.
     * @param formType
     */
    $scope.selectFormType = function (formType) {
        $scope.data.formType = formType;
        $scope.onActionsChanged(true);
    };

    $scope.selectWaitFormType = function (onClickParam) {
        $scope.data.waitFormType = onClickParam;
        $scope.onActionsChanged(true);
    };

    $scope.selectFormLocation = function (formLocation) {
        $scope.data.formLocation = formLocation;
        $scope.onActionsChanged(true);
    };

    $scope.selectBorderViewType = function (borderViewType) {
        $scope.data.borderViewType = borderViewType;
        $scope.onActionsChanged(true);
    };

    $scope.selectReplyTo = function (typeName) {
        $scope.data.selectedReplyTo = typeName;

        switch (typeName) {
            case 'person': {
                $scope.data.sendResponseToChannel = false;
                $scope.data.replyOnOriginalMessage = false;

                break;
            }
            case 'channel': {
                $scope.data.sendResponseToChannel = true;
                $scope.data.replyOnOriginalMessage = false;

                break;
            }
            case 'original': {
                $scope.data.sendResponseToChannel = false;
                $scope.data.replyOnOriginalMessage = true;

                break;
            }
            // No default
        }

        $scope.onActionsChanged(true);
    };

    $scope.selectActionType = function (typeName) {
        $scope.data.changedActionTypeIsLoading = true;
        let promise = $q.resolve();

        if (typeName === 'continue-flow' && $scope.doesNotHaveMonitorItemsChildren()) {
            $scope.data.selectedActionType = typeName;
            delete $scope.data.urlLink;
            delete $scope.data.formId;
            delete $scope.data.itemInterfaceId;
            $scope.data.isUrlLink = false;
            $scope.onActionsChanged(true);
            promise = onSelectActionType(true, false);
        } else if (
            typeName === 'open-custom-interface-in-sequence' &&
            $scope.doesLogicHaveNoChildren(['SEND_FORM_ANSWERED'])
        ) {
            $scope.data.selectedActionType = typeName;
            delete $scope.data.urlLink;
            delete $scope.data.formId;
            $scope.data.isUrlLink = false;
            $scope.onActionsChanged(true);
            promise = onSelectActionType(false, false);
        } else if (typeName === 'open-custom-interface' && $scope.doesLogicHaveNoChildren()) {
            $scope.data.selectedActionType = typeName;
            delete $scope.data.urlLink;
            delete $scope.data.formId;
            $scope.data.isUrlLink = false;
            $scope.onActionsChanged(true);
            promise = onSelectActionType(false, false);
        } else if (typeName === 'open-link' && $scope.doesLogicHaveNoChildren()) {
            $scope.data.selectedActionType = typeName;
            delete $scope.data.formId;
            delete $scope.data.itemInterfaceId;
            $scope.onActionsChanged(true);
            promise = onSelectActionType(true, false);
        } else if (typeName === 'open-form' && $scope.doesLogicHaveNoChildren(['SEND_FORM_ANSWERED'])) {
            $scope.data.selectedActionType = typeName;
            delete $scope.data.urlLink;
            delete $scope.data.itemInterfaceId;
            $scope.data.isUrlLink = false;
            $scope.onActionsChanged(true);
            promise = onSelectActionType(false, true);
        }

        promise.finally(() => {
            $scope.data.changedActionTypeIsLoading = false;
        });
    };

    $scope.redirectToggle = function (value) {
        if (value) {
            $scope.selectActionType('open-link');
        } else {
            $scope.selectActionType('continue-flow');
        }
    };

    $scope.openInNewTabToggle = function (value) {
        $scope.data.redirectToNewTab = value;
        $scope.onActionsChanged(true);
    };

    function onSelectActionType(disableWhenFormAnswered, createActionIfMissing) {
        let promise = $q.resolve();
        const formAnsweredTriggers =
            $scope.data.configuredLogic.impacts &&
            $scope.data.configuredLogic.impacts.filter(
                (impact) => impact.node.customTriggerType === logicBlockTypes.SEND_FORM_ANSWERED.type,
            );
        // If actions exists hide/show all the 'when form answered' actions
        if (formAnsweredTriggers && formAnsweredTriggers.length > 0) {
            formAnsweredTriggers.forEach((formAnsweredTrigger) => {
                if (formAnsweredTrigger) {
                    promise = customTriggerManager.updateCustomTriggerDisableMode(
                        $scope.data.groupId,
                        formAnsweredTrigger.node.id,
                        disableWhenFormAnswered,
                    );
                }
            });
        } else if (createActionIfMissing) {
            // if the 'when form answered' action doesn't exist -create it
            const logicConfigurationObject = {
                actions: [],
            };

            if (!logicConfigurationObject.childrenCustomTriggersToCreate) {
                logicConfigurationObject.childrenCustomTriggersToCreate = [];
            }

            logicConfigurationObject.childrenCustomTriggersToCreate.push({
                displayName: 'When Form Answered',
                type: logicBlockTypes.SEND_FORM_ANSWERED.type,
                customTriggerActions: [
                    {
                        type: logicBlockTypes.SEND_FORM_ANSWERED.type,
                        customTriggerActionDefinition: {
                            replyOnOriginalMessage: true,
                        },
                    },
                ],
            });

            promise = $scope.onActionsChanged(
                {
                    shouldSaveLogic: true,
                },
                logicConfigurationObject.childrenCustomTriggersToCreate,
            );
        }
        return promise;
    }

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.customResponseMessage = originalExpression;
        $scope.data.evaluatedCustomResponseMessage = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once custom interface label changed.
     */
    $scope.onCustomInterfaceLabelTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.customInterfaceLabel = originalExpression;
        $scope.data.evaluatedCustomInterfaceLabel = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onEvaluatedWaitingConditionMessageChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.waitingConditionMessage = originalExpression;
        $scope.data.evaluatedWaitingConditionMessage = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onWebformResponseMessageTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.webformResponseMessage = originalExpression;
        $scope.data.evaluatedWebformResponseMessage = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onWebformResponseMessageSubtitleTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.webformResponseMessageSubtitle = originalExpression;
        $scope.data.evaluatedWebformResponseMessageSubtitle = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onLinkTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.urlLink = originalExpression;
        $scope.data.evaluatedUrlLink = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onRedirectDelayChanged = function (redirectDelay, inputVisible) {
        $scope.data.inputVisible = inputVisible;

        if (!inputVisible) {
            redirectDelay = 0;
        } else if ((!redirectDelay) || (redirectDelay == 0)) {
            redirectDelay = 5;
        }

        $scope.data.redirectDelay = redirectDelay;
        $scope.onActionsChanged(true);
    };

    $scope.doesLogicHaveNoChildren = function (excludeCustomTriggerTypes) {
        // Can't have children if link.
        if (
            $scope.data.configuredLogic.impacts &&
            $scope.data.configuredLogic.impacts.filter(
                (singleImpact) =>
                    !excludeCustomTriggerTypes ||
                    !excludeCustomTriggerTypes.includes(singleImpact.node.customTriggerType),
            ).length
        ) {
            $scope.$emit('alert', {
                msg: "Can't turn Logic into a Link if it has child logic blocks",
                type: 'warning',
            });
            return false;
        }
        return true;
    };

    $scope.doesNotHaveMonitorItemsChildren = function () {
        if ($scope.data.configuredLogic.impacts && $scope.data.configuredLogic.impacts.length) {
            const hasMonitorItemsImpact = $scope.data.configuredLogic.impacts.some(
                (singleImpact) => singleImpact.node.customTriggerType === 'MONITOR_TRACKS',
            );

            if (hasMonitorItemsImpact) {
                $scope.$emit('alert', {
                    msg: 'Can\'t turn Logic into a "Continue a flow" if it has monitor items logic blocks',
                    type: 'warning',
                });

                return false;
            }
        }

        return true;
    };

    $scope.onFormMatchedEntityCreatedOrUpdated = function (field) {
        $scope.data.formMatchedEntityFieldId = field?.id;
        $scope.onActionsChanged(true);
    };

    $scope.onActionsChanged = function (shouldSaveLogic, childrenToCreate) {
        if (ctrl.onActionsChanged) {
            const defaultMessage =
                $scope.data.sendResponseToChannel || $scope.data.replyOnOriginalMessage
                    ? `"${$scope.data.configuredLogic.node.displayName}" Clicked by {Person Name}`
                    : '\uD83D\uDC4C';
            let message = defaultMessage;
            let evaluatedMessage = defaultMessage;
            if ($scope.data.customResponseMessage) {
                message = $scope.data.customResponseMessage;
                evaluatedMessage = $scope.data.evaluatedCustomResponseMessage;

                if (
                    $scope.data.sendResponseToChannel ||
                    ($scope.data.replyOnOriginalMessage && $scope.data.customTriggerActionType !== 'SEND_FORM_ANSWERED')
                ) {
                    message = `"${message}" | ${defaultMessage}`;
                    evaluatedMessage = `"${evaluatedMessage}" | ${defaultMessage}`;
                }
            }

            $scope.data.previewReplyText = message;
            $scope.data.previewReplyEvaluateText = evaluatedMessage;

            const actionDefinition = {
                actions: [
                    {
                        type: $scope.data.customTriggerActionType,
                        definition: {
                            webformResponseMessage: $scope.data.webformResponseMessage,
                            evaluatedWebformResponseMessage: $scope.data.evaluatedWebformResponseMessage,
                            webformResponseMessageSubtitle: $scope.data.webformResponseMessageSubtitle,
                            evaluatedWebformResponseMessageSubtitle:
                                $scope.data.evaluatedWebformResponseMessageSubtitle,
                            evaluatedWaitingConditionMessage: $scope.data.evaluatedWaitingConditionMessage,
                            waitingConditionMessage: $scope.data.waitingConditionMessage,
                            customResponseMessage: $scope.data.customResponseMessage,
                            evaluatedCustomResponseMessage: $scope.data.evaluatedCustomResponseMessage,
                            showWebFormResponse: $scope.data.showWebFormResponse,
                            sendResponseToChannel: $scope.data.sendResponseToChannel,
                            isOneTime: $scope.data.isOneTime,
                            replyOnOriginalMessage: $scope.data.replyOnOriginalMessage,
                            // the $scope.data.selectedActionType === 'open-link' is for backward compatibility.
                            urlLink:
                                $scope.data.isUrlLink || $scope.data.selectedActionType === 'open-link'
                                    ? $scope.data.urlLink
                                    : null,
                            evaluatedUrlLink:
                                $scope.data.isUrlLink || $scope.data.selectedActionType === 'open-link'
                                    ? $scope.data.evaluatedUrlLink
                                    : null,
                            redirectDelay: $scope.data.redirectDelay,
                            redirectToNewTab: $scope.data.redirectToNewTab,
                            dontEncodeUrl: !$scope.data.shouldEncodeUrl,
                            selectedActionType: $scope.data.selectedActionType,
                            formId: $scope.data.formId,
                            formType: $scope.data.formType,
                            formLocation: $scope.data.formLocation,
                            postFormFieldsToUpdate: $scope.data.postFormFieldsToUpdate,
                            borderViewType: $scope.data.borderViewType,
                            formMatchedEntityFieldId: $scope.data.formMatchedEntityFieldId,
                            waitFormConditions: $scope.data.waitFormConditions,
                            waitFormType: $scope.data.waitFormType,

                            itemInterfaceId: $scope.data.itemInterfaceId,
                            isCustomInterfaceLink:
                                $scope.data.itemInterfaceId &&
                                $scope.data.selectedActionType === 'open-custom-interface',
                            isCustomInterfaceInSequence:
                                $scope.data.itemInterfaceId &&
                                $scope.data.selectedActionType === 'open-custom-interface-in-sequence',
                            customInterfaceLabel: $scope.data.customInterfaceLabel ?? 'View form submission',
                            evaluatedCustomInterfaceLabel:
                                $scope.data.evaluatedCustomInterfaceLabel ?? 'View form submission',
                        },
                    },
                ],
            };

            if ($scope.data.form && $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                actionDefinition.actions[0].definition.dataStorageId = $scope.data.dataStorageId;
                actionDefinition.actions[0].definition.folderIdExpression = $scope.data.folderIdExpression;
            }

            if (childrenToCreate) {
                actionDefinition.childrenCustomTriggersToCreate = childrenToCreate;
            }

            return ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Edit mode initialization.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            $scope.data.customTriggerActionType,
        );

        if (customTriggerAction) {
            // UI parameters
            $scope.data.isUnderIntercomFlow = isSendFormAnsweredTriggerUnderIntercomFlow();
            // Definition
            const definition = customTriggerAction.customTriggerActionDefinition;

            // The custom message that been sent back to the user once he filled the form.
            $scope.data.customResponseMessage = definition.customResponseMessage;
            $scope.data.evaluatedCustomResponseMessage = definition.evaluatedCustomResponseMessage;

            // custom interface
            $scope.data.customInterfaceLabel = definition.customInterfaceLabel;
            $scope.data.evaluatedCustomInterfaceLabel = definition.evaluatedCustomInterfaceLabel;

            $scope.data.itemInterfaceId = definition.itemInterfaceId;

            // Message that we display the user once he filled the form.
            // If the message is not present, we use the custom response message as default value (backward compatibility)
            $scope.data.webformResponseMessage = definition.webformResponseMessage || definition.customResponseMessage;
            $scope.data.evaluatedWebformResponseMessage =
                definition.evaluatedWebformResponseMessage || definition.evaluatedCustomResponseMessage;
            $scope.data.showWebFormResponse = utils.isNullOrUndefined(definition.showWebFormResponse)
                ? true
                : definition.showWebFormResponse;

            $scope.data.webformResponseMessageSubtitle = definition.webformResponseMessageSubtitle;
            $scope.data.evaluatedWebformResponseMessageSubtitle = definition.evaluatedWebformResponseMessageSubtitle;

            $scope.data.waitingConditionMessage = definition.waitingConditionMessage;
            $scope.data.evaluatedWaitingConditionMessage = definition.evaluatedWaitingConditionMessage;
            $scope.data.sendResponseToChannel = definition.sendResponseToChannel;
            $scope.data.isOneTime = definition.isOneTime;
            $scope.data.replyOnOriginalMessage = definition.replyOnOriginalMessage;
            $scope.data.waitFormConditions = definition.waitFormConditions || { query: { type: 'And', filters: [] } };
            $scope.data.waitFormType = definition.waitFormType || 'IMMEDIATELY';
            if ($scope.data.sendResponseToChannel) {
                $scope.data.selectedReplyTo = 'channel';
            } else if ($scope.data.replyOnOriginalMessage && !$scope.data.updateOriginalMessageDisabled) {
                $scope.data.selectedReplyTo = 'original';
            } else {
                $scope.data.replyOnOriginalMessage = false;
                $scope.data.selectedReplyTo = 'person';
            }

            $scope.data.showPreviousFormPlatformRadioOption =
                $scope.data.configuredLogic.node.customTriggerType === logicBlockTypes.SEND_FORM_ANSWERED.type;

            $scope.data.formType = definition.formType;
            $scope.data.formMatchedEntityFieldId = definition.formMatchedEntityFieldId;
            if ($scope.data.showPreviousFormPlatformRadioOption && !$scope.data.formType) {
                $scope.data.formType = 'PREVIOUS_PLATFORM';
            } else if ($scope.data.configuredLogic.node.customTriggerType === logicBlockTypes.BOT_BUTTON_PRESSED.type) {
                $scope.data.formType = definition.formType || 'WEBFORM';
            }

            $scope.data.formLocation = definition.formLocation || 'NEW';
            $scope.data.postFormFieldsToUpdate = definition.postFormFieldsToUpdate;
            $scope.data.borderViewType = definition.borderViewType || 'MULTIPLE_FORMS';

            $scope.data.urlLink = definition.urlLink;
            $scope.data.redirectDelay = definition.redirectDelay !== undefined ? definition.redirectDelay : $scope.data.redirectDelay;
            $scope.data.inputVisible = $scope.data.redirectDelay != 0;
            $scope.data.redirectToNewTab = definition.redirectToNewTab ? definition.redirectToNewTab : $scope.data.redirectToNewTab;
            $scope.data.evaluatedUrlLink = definition.evaluatedUrlLink;
            $scope.data.isUrlLink = !!$scope.data.urlLink;
            $scope.data.shouldEncodeUrl = !definition.dontEncodeUrl;

            if (definition.selectedButtonType || definition.selectedActionType) {
                $scope.data.selectedActionType = definition.selectedActionType
                    ? definition.selectedActionType
                    : definition.selectedButtonType;
                // This part is for past compatibility, because definition.selectedButtonType & definition.selectedActionType is a new property.
            } else {
                $scope.data.selectedActionType = $scope.data.isUrlLink ? 'open-link' : 'continue-flow';
            }

            // Form preview
            $scope.data.formId = definition.formId;

            const formOptional = formManager.getWorkerFormFromCache($scope.data.workflowVersionId, $scope.data.formId);

            // Try fetch form from cache, if not exist, fetch it from the workflowFolder Forms
            if (formOptional) {
                $scope.data.form = formOptional;
                if ($scope.data.form && $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                    $scope.data.dataStorageId = customTriggerAction.customTriggerActionDefinition.dataStorageId;
                    $scope.data.folderIdExpression =
                        customTriggerAction.customTriggerActionDefinition.folderIdExpression;
                    if ($scope.data.dataStorageId) {
                        $scope.data.disableStorageSelection = true;
                    }
                }
            } else {
                // Checking if the $scope.data.workflowVersion is DRAFT or PUBLISHED
                const draftWorkflowVersion = workflowVersionManager.getDraftVersionFromCache($scope.data.groupId);
                const workflowVersionType =
                    draftWorkflowVersion.id === $scope.data.workflowVersionId ? 'DRAFT' : 'PUBLISHED';

                if ($scope.data.formId) {
                    formManager.getFormByWorkflowVersionType($scope.data.formId, workflowVersionType).then((form) => {
                        $scope.data.form = form;
                        if ($scope.data.form && $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                            $scope.data.dataStorageId = customTriggerAction.customTriggerActionDefinition.dataStorageId;
                            $scope.data.folderIdExpression =
                                customTriggerAction.customTriggerActionDefinition.folderIdExpression;
                            if ($scope.data.dataStorageId) {
                                $scope.data.disableStorageSelection = true;
                            }
                        }
                    });
                }
            }
        }
    }

    /**
     * Occurs once a form is selected from the dropdown.
     */
    $scope.onFormSelected = function (form) {
        $scope.data.form = form;
        $scope.data.formId = form.id;

        if ($scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
            // upload files form is accessible nly through web form
            $scope.data.formType = 'WEBFORM';
        }

        return $scope.onActionsChanged(true);
    };

    $scope.onDataStorageChanged = function (selectedDataStorage, folderIdExpression) {
        $scope.data.dataStorageId = selectedDataStorage;
        $scope.data.folderIdExpression = folderIdExpression;
        if ($scope.data.dataStorageId) {
            $scope.data.disableStorageSelection = true;
        }
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once a custom interface is selected from the dropdown.
     */
    $scope.onItemInterfaceSelected = function (itemInterfaceId) {
        $scope.data.itemInterfaceId = itemInterfaceId;

        return $scope.onActionsChanged(true);
    };

    /**
     * If form is of type upload and form has default definition -
     * save the storage in custom trigger on side pane closes
     */
    $scope.$on('closeSidePaneEvent', function (e) {
        if (
            $scope.data.form &&
            $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES &&
            !$scope.data.dataStorageId
        ) {
            $scope.data.dataStorageId = $scope.data.form.definition.dataStorageId;
            $scope.data.folderIdExpression = $scope.data.form.definition.destinationFolderId;
            $scope.data.disableStorageSelection = true;
            $scope.onActionsChanged(true);
        }
    });

    /**
     * Checking whether the trigger's parents are type of 'SEND_FORM_ANSWERED' or 'ADD_FORM_TO_CONVERSATION'.
     * The purpose of this function is find out whether this form is taking part of Intercom flow.
     */
    function isSendFormAnsweredTriggerUnderIntercomFlow() {
        // Fetching 'When Form Answered' trigger's logic parents.
        const logicParents = customTriggerManager.getWorkflowVersionLogicParents(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.node.id,
        );
        for (const customTrigger of logicParents) {
            // If no type, it is root
            if (customTrigger.node.customTriggerType && customTrigger.node.customTriggerType !== 'SEND_FORM_ANSWERED') {
                if (customTrigger.node.integrationType !== 'INTERCOM') {
                    return false;
                } else {
                    // Trigger is not 'Send Form Answered' type, might be 'Add Form To Conversation' type.
                    return (
                        customTrigger &&
                        customTrigger.node &&
                        customTrigger.node.customTriggerActions &&
                        customTrigger.node.customTriggerActions[0] &&
                        customTrigger.node.customTriggerActions[0].customTriggerActionDefinition &&
                        customTrigger.node.customTriggerActions[0].customTriggerActionDefinition
                            .performedActionDefinition &&
                        customTrigger.node.customTriggerActions[0].customTriggerActionDefinition
                            .performedActionDefinition.customActionKey === 'ADD_FORM_TO_CONVERSATION'
                    );
                }
            }
        }

        return true;
    }

    function hasAnyMonitorItemsImpacts() {
        return $scope.data.configuredLogic.impacts.some(
            (singleImpact) => singleImpact.node.customTriggerType === 'MONITOR_TRACKS',
        );
    }
}

export default angular
    .module('tonkean.app')
    .controller('BotPostMessageActionConfigurationCtrl', lateConstructController(BotPostMessageActionConfigurationCtrl));
