import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionTypeDropdownOptions from '../config/ActionTypeDropdownOptions';
import actionTypeToPresentTense from '../config/ActionTypePresentTense';
import type ActionOutput from '../entities/ActionOutput';

import { TnkEntitySelector } from '@tonkean/angular-to-react-components';
import { SimpleSelect } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { IntegrationSupportedEntity, ProjectIntegration } from '@tonkean/tonkean-entities';
import { ActionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const ActionTypeSelect = styled(SimpleSelect)`
    width: 190px;
    margin-bottom: 16px;
`;

const ReadOnlyLabel = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    padding: 6px 8px 6px 8px;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
    background: ${Theme.current.palette.actionOutputType.readOnlyLabel.background};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
`;

const ProjectIntegrationIconWrapper = styled(ProjectIntegrationIcon)`
    margin-right: 6px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    onChange?: (actionOutput: ActionOutput) => void;
    disabled?: boolean;
    defaultActionType?: ActionType;
    defaultEntity?: IntegrationSupportedEntity;
    isEditable?: boolean;
    canChooseEntity?: boolean;
    customActionTypesToExclude?: ActionType[];
    shouldNotChooseDefaultEntity?: boolean;
    shouldDisableChoice?: boolean;
}

const ActionEntityOutputTypeSelect: React.FC<Props> = ({
    projectIntegration,
    onChange,
    disabled = false,
    defaultActionType = ActionType.CREATE,
    defaultEntity,
    isEditable = true,
    canChooseEntity = true,
    customActionTypesToExclude = [],
    shouldNotChooseDefaultEntity = false,
    shouldDisableChoice = false,
}) => {
    const [selectedEntity, setSelectedEntity] = useState<IntegrationSupportedEntity | undefined>(defaultEntity);
    const [selectedActionType, setSelectedActionType] = useState<ActionType>(defaultActionType);

    const options = useMemo(() => {
        return ActionTypeDropdownOptions.filter((type) => !customActionTypesToExclude.includes(type.value));
    }, [customActionTypesToExclude]);

    return (
        <>
            {!isEditable && (
                <ReadOnlyLabel>
                    <ProjectIntegrationIconWrapper
                        iconUrl={projectIntegration.iconUrl}
                        integrationType={projectIntegration.integration.integrationType.toLowerCase()}
                        width={20}
                    />
                    {actionTypeToPresentTense[selectedActionType]} {defaultEntity?.pluralLabel || ''}
                </ReadOnlyLabel>
            )}
            {isEditable && (
                <>
                    <ActionTypeSelect
                        value={selectedActionType}
                        options={options}
                        onChange={(type) => {
                            setSelectedActionType(type);
                            onChange?.({ entity: selectedEntity, type });
                        }}
                        readOnly={disabled}
                        thin
                    />
                    {canChooseEntity && (
                        <div>
                            <TnkEntitySelector
                                projectIntegration={projectIntegration}
                                selectedEntity={(selectedEntity as any)?.entity}
                                selectedEntityDisplayName={selectedEntity?.entity}
                                mode="sync"
                                onEntitySelected={(entity) => {
                                    setSelectedEntity(entity);
                                    onChange?.({ entity, type: selectedActionType });
                                }}
                                disabled={shouldDisableChoice}
                                shouldNotChooseDefaultEntity={shouldNotChooseDefaultEntity}
                                useReactSelect
                                allowClear
                                forceFetchSupportedEntitiesFromServer
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ActionEntityOutputTypeSelect;
