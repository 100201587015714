import template from './tnkAvatarsBar.template.html?angularjs';

/**
 * This component shows a bar of round photos of given avatars
 * @avatars - An array of avatars which has to contain:
 *            Name - The name of the avatar (default Guest)
 *            isLink - whether the avatar should be displayed as link on hover (default false)
 *            isAnonymousUser - whether it's a guest or registered user (default Guest)
 *            opacity - The opacity for the avatar image (default 1)
 * @avatarsDesktopLimit - Limit of avatars to show on the bar (default 3)
 * @groupId - A given group ID
 * @displayMoreLimit - Limit of avatars to show in the "+X more" section (default 10)
 * @showMoreTemplatePath - Path to template of the "+X more" section
 * @avatarHoverTemplatePath - Path to template which will shown on avatar hover
 * @showAllInDisplayMore - Whether to show all avatars in the "+X more" section (default false)
 * @showMoreAvatarsLink - Whether to show a link to all avatars list in the "+X more" section (default true)
 * @moreAvatarsModalPath - Path to template which will shown the list of all avatars
 */
export default angular.module('tonkean.app').component('tnkAvatarsBar', {
    bindings: {
        avatars: '<',
        groupId: '<',
        avatarsDesktopLimit: '<',
        displayMoreLimit: '<',
        showMoreTemplatePath: '<',
        avatarHoverTemplatePath: '<',
        showAllInDisplayMore: '<',
        showMoreAvatarsink: '<',
        shouldDisplayViewTime: '<',
        size: '<',
        separatedAvatars: '<',
    },
    template,
    controller: 'AvatarsBarCtrl',
});
