import React, { useContext } from 'react';
import styled from 'styled-components';

import WorkerRunsFiltersLabel from './WorkerRunsFiltersLabel';
import WorkerRunsFiltersPopoverSearchTriggers from './WorkerRunsFiltersPopoverSearchTriggers';
import WorkerRunsFiltersPopoverWorkflowVersion from './WorkerRunsFiltersPopoverWorkflowVersion';
import BackgroundProcessSelector from '../../../../../BackgroundProcessSelectorModule/BackgroundProcessSelector';
import HistoryContext from '../../../../entities/HistoryContext';
import initialFilters from '../../../../entities/initialFilters';
import type { ErrorsFilter } from '../../../../entities/WorkerRunsFilter';
import type WorkerRunsFilter from '../../../../entities/WorkerRunsFilter';

import { Checkbox, FilterRow, FiltersGroup, SimpleSelect, useSubmittableFilters } from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { filtersHelper } from '@tonkean/utils';

export const workerRunsFiltersHelper = filtersHelper(initialFilters.filters);

const hasErrorOptions: {
    label: string;
    value: ErrorsFilter;
}[] = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Yes',
        value: 'onlyErrors',
    },
    {
        label: 'No',
        value: 'noErrors',
    },
];

const ShowNotRunCheckboxLabel = styled.span`
    font-weight: normal;
`;

interface Props {
    filters: WorkerRunsFilter;

    onChange(newFilter: WorkerRunsFilter): void;
}

const WorkerRunsFiltersPopover: React.FC<Props> = ({ filters: previousFilters, onChange }) => {
    const { state } = useContext(HistoryContext);

    const [{ canClear, canSubmit, onClear, onSubmit }, filters, setFilters] = useSubmittableFilters(
        previousFilters,
        initialFilters.filters,
        onChange,
    );

    return (
        <FiltersGroup
            clearDataAutomation="history-filters-clear-filters-button"
            clearDisabled={!canClear}
            onClear={onClear}
            submitDataAutomation="history-filters-apply-button"
            submitDisabled={!canSubmit}
            onSubmit={onSubmit}
        >
            <WorkerRunsFiltersLabel label="Trigger">
                <WorkerRunsFiltersPopoverSearchTriggers
                    sequentialIdentifier={filters.version}
                    value={filters.trigger}
                    onChange={(trigger) => setFilters({ ...filters, trigger })}
                />
            </WorkerRunsFiltersLabel>

            <WorkerRunsFiltersLabel label="Background Process">
                <BackgroundProcessSelector
                    groupId={state.groupId}
                    workflowVersionType={
                        state.environment === 'production' ? WorkflowVersionType.PUBLISHED : WorkflowVersionType.DRAFT
                    }
                    onItemSelected={(backgroundProcess) => setFilters({ ...filters, backgroundProcess })}
                    preselectedItem={filters.backgroundProcess}
                />
            </WorkerRunsFiltersLabel>

            {state.environment === 'production' && (
                <WorkerRunsFiltersLabel label="Version">
                    <WorkerRunsFiltersPopoverWorkflowVersion
                        value={filters.version}
                        onChange={(version) => setFilters({ ...filters, version })}
                    />
                </WorkerRunsFiltersLabel>
            )}

            <WorkerRunsFiltersLabel label="Has errors">
                <SimpleSelect
                    options={hasErrorOptions}
                    value={filters.errors}
                    onChange={(errors) => errors && setFilters({ ...filters, errors })}
                    thin
                />
            </WorkerRunsFiltersLabel>

            <FilterRow>
                <Checkbox
                    changeLabelColor={false}
                    checked={filters.showHasNotRun}
                    onChange={(event) => setFilters({ ...filters, showHasNotRun: event.target.checked })}
                >
                    <ShowNotRunCheckboxLabel>Include trigger not met or disabled</ShowNotRunCheckboxLabel>
                </Checkbox>
            </FilterRow>
        </FiltersGroup>
    );
};

export default WorkerRunsFiltersPopover;
