<!--Important - this components class is being used to enable scroll inside this element list, in ItemInterfaceView.tsx --->
<tags-input
    ng-model="selectedPeople"
    display-property="email"
    key-property="email"
    class="item-interface-style-override people-selector tnk-selector common-width-100"
    on-tag-added="onTagAddedInner($tag)"
    on-tag-removed="onTagRemovedInner($tag)"
    on-tag-removing="onTagRemovingInner($tag)"
    ng-disabled="disabled"
    replace-spaces-with-dashes="false"
    max-tags="{{ maxTags }}"
    text="text"
    min-tags="{{ minTags }}"
    ng-class="{
        'mod-max': maxTags > 0 && selectedPeople.length >= maxTags,
        'hide-tags-background-when-disabled': hideTagsBackgroundWhenDisabled
    }"
    placeholder="{{
        hidePlaceholderWhenDisabled && disabled
            ? ' '
            : placeholder || 'Search a user ' + (includeGroups ? 'or group name' : '') + (!noCustom ? ' or email' : '')
    }}"
    addFromAutocompleteOnly="noCustom"
    template="./peopleSelectedItem.template.html"
    data-automation="people-selector"
    include-external="true"
>
    <auto-complete
        source="searchPeople($query)"
        min-length="0"
        load-on-focus="true"
        load-on-empty="true"
        template="./peopleAutocompleteItem.template.html"
    ></auto-complete>
    <i class="loading-small mod-tags-input" ng-show="loadingPeople"></i>
</tags-input>
<div
    ng-if="showIntro && uninvitedPeople && uninvitedPeople.length"
    class="common-size-xxxxs common-color-dark-grey margin-top-xs margin-left-xxs"
>
    <span class="common-text-wrap">
        <ng-pluralize
            count="uninvitedPeople.length"
            when="{'1': '{{ uninvitedPeople[0].firstName }} is ', '2': '{{ uninvitedPeople[0].firstName }} and {{
                uninvitedPeople[1].firstName
            }} are ', 'other': '{} people are '}"
            uib-tooltip="{{ utils.joinObjArray(uninvitedPeople, 'name', ', ') }}"
            tooltip-placement="auto bottom"
            tooltip-enable="uninvitedPeople.length > 2"
        ></ng-pluralize>
        {{ introText || 'new to Tonkean.' }}&nbsp;
    </span>
    <a class="common-size-xxxxs common-one-liner" href="" ng-click="openInviteModal()">
        {{ introButtonText || 'Personalize their Invite?' }}
    </a>
</div>
