import React from 'react';

import { FrontDoorEntitySettingsRowDivider } from './FrontDoorEntitySettingsRowDivider';

import { Placeholder } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

interface Props {}

const rowsCount = 3;
const ProjectIntegrationFrontDoorEntitiesLoadingState: React.FC<Props> = ({}) => {
    return (
        <>
            {range(rowsCount).map((value) => (
                <>
                    <Placeholder $width="100%" $height="33px" />
                    <Placeholder $width="36px" $height="18px" />
                    <Placeholder $width="22px" $height="22px" $borderRadius="3px" />
                    <Placeholder $width="22px" $height="22px" $borderRadius="3px" />
                    <div />
                    {value !== rowsCount - 1 && <FrontDoorEntitySettingsRowDivider />}
                </>
            ))}
        </>
    );
};

export default ProjectIntegrationFrontDoorEntitiesLoadingState;
