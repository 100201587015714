import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType, TonkeanActionType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const TonkeanActionLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.TONKEAN_ACTION,
    title: 'Module Actions',
    iconClass: 'mod-actions',
    description: 'Various actions in Tonkean',
    dontSaveOnSelect: true,
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    cantChangeType: (features, workingOnContext) => {
        if (workingOnContext === 'TRIGGERED_ACTION_ITEM' && !features?.tonkean_allow_inner_items_old_flow) {
            return true;
        }
        return false;
    },
    cantAddChildren: (customTrigger, features, workingOnContext) => {
        if (!customTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition?.actionType) {
            // if we don't have an action type configured = cant add children
            return true;
        } else if (
            customTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition?.actionType ===
            TonkeanActionType.ARCHIVE_TRACK
        ) {
            // archive action type can never add children
            return true;
        } else if (features.tonkean_allow_inner_items_old_flow) {
            // if we have action type configured and we can allow old flow allow adding children
            return false;
        } else if (workingOnContext === 'TRIGGERED_ACTION_ITEM') {
            // if we are in inner items context don't allow children
            return true;
        } else {
            return false;
        }
    },
    validator: ({ definition, customTrigger, customTriggerManager }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (
            definition.actionType === TonkeanActionType.CREATE_TRACK &&
            !definition.actionDefinition.groupId &&
            !definition.actionDefinition.parentId
        ) {
            validationObject.noGroup = "Can't create Item in the same module as Root item";
        }

        if (definition.actionType === null) {
            validationObject.noAction = 'No action type selected.';
        }

        if (
            definition.actionType === TonkeanActionType.CREATE_TRACK &&
            utils.isNullOrEmpty(definition.actionDefinition.title)
        ) {
            validationObject.noTitle = 'Must fill a title';
        }

        if (
            definition.actionType === TonkeanActionType.ADD_COMMENT &&
            utils.isNullOrEmpty(definition.actionDefinition.comment)
        ) {
            validationObject.noComment = 'Must fill a comment';
        }

        if (definition.actionType === TonkeanActionType.ARCHIVE_TRACK) {
            const logicData = customTriggerManager.findLogicDataInGraphById(
                customTrigger.workflowVersion.id,
                customTrigger.id,
            );

            if (logicData && logicData.logic.impacts.length > 0) {
                validationObject.hasChildren =
                    'To use "Archive item" it must be the last action in the flow. Remove the subsequent actions.';
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    enricher: (customTrigger, definition) => {
        if (definition.actionType) {
            customTrigger.cantAddChildren = false;

            if (definition.actionType === TonkeanActionType.ARCHIVE_TRACK) {
                customTrigger.cantAddChildren = true;
            }
        }
    },
};

export default TonkeanActionLogicBlockConfig;
