<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ data.integrationName }}"></div>
        <h4 class="flex-grow">Configure Data Collection</h4>
    </div>
</div>

<div class="modal-body entities-expiration-modal">
    <!-- Security Info Message -->
    <div ng-show="!data.isInitializeLoading" class="margin-bottom common-size-xxs">
        <span>
            Tonkean will hold the entity data that has not being updated, only for the time span configured for each
            entity.
        </span>
    </div>

    <!-- Loader Icon -->
    <div ng-show="data.isInitializeLoading" class="flex mod-center">
        <i class="loading-large"></i>
    </div>

    <div ng-show="!data.isInitializeLoading" class="margin-bottom-lg">
        <div class="common-title- mod-light margin-bottom-lg" ng-show="::data.isConfigurationIsEmpty">
            <p>Data collection configuration is empty, no data is being currently collected.</p>
        </div>

        <div
            class="flex-vmiddle margin-bottom row"
            ng-repeat="entity in data.integrationConfiguredEntities"
            ng-init="lockedEntity = !entity.id || !(data.dataRetentionSettings.canEditAll || data.dataRetentionSettings.editableEntities[entity.id])"
        >
            <div class="col-sm-4 margin-left-xxs margin-right">
                <tnk-entity-selector
                    should-not-choose-default-entity="true"
                    project-integration="data.projectIntegration"
                    selected-entity="{{ entity.id }}"
                    on-entity-selected="onEntitySelected(selectedEntity, $index)"
                    force-fetch-supported-entities-from-server="true"
                    ignore-entities="data.dataRetentionSettings.ignoreEntities"
                    should-disable-choice="isEntityAlreadySelected(option)"
                ></tnk-entity-selector>
            </div>

            <div class="flex-vmiddle col-sm-8">
                <!-- Displayed When Not In Editing Mode -->
                <div ng-hide="data.entityIdToEditableStateMap[entity.id]" class="flex-vmiddle">
                    <span class="days-back-text" ng-hide="entity.isIndefinite" ng-if="!lockedEntity">
                        {{ entity.expirationDays }} days back
                    </span>

                    <tnk-toggle
                        ng-show="entity.isIndefinite || lockedEntity"
                        is-active="true"
                        toggle-disabled="true"
                        class="is-indefinite-toggle"
                        on-label="('Indefinite')"
                        size="sm-long"
                    ></tnk-toggle>

                    <!-- Edit button (pencil) -->
                    <i
                        class="edit-button"
                        ng-click="editEntityExpirationDays(entity)"
                        ng-class="{ 'disable-edit-mode': lockedEntity }"
                    >
                        <i class="fa fa-pencil margin-left"></i>
                    </i>
                </div>

                <!-- Displayed When In Editing Mode -->
                <div ng-show="data.entityIdToEditableStateMap[entity.id]" class="flex-vmiddle row margin-none">
                    <input
                        type="number"
                        min="0"
                        max="1095"
                        oninput="this.value = Math.abs(this.value)"
                        class="form-control input mod-input block margin-right days-back-input"
                        ng-model="data.entityIdToEditableStateMap[entity.id].expirationDays"
                        ng-hide="data.entityIdToEditableStateMap[entity.id].isIndefinite"
                        autofocus
                    />

                    <tnk-toggle
                        is-active="data.entityIdToEditableStateMap[entity.id].isIndefinite"
                        class="margin-right-lg is-indefinite-toggle"
                        on-label="('Indefinite')"
                        off-label="Days"
                        validation-promise="openConfirmationModalAndToggleIsIndefinite(entity)"
                        size="sm-long"
                    ></tnk-toggle>

                    <button class="btn btn-primary btn-sm" ng-click="saveEntityExpirationDays($index, entity)">
                        Save
                    </button>

                    <button class="btn btn-cancel btn-sm" ng-click="cancelEditMode(entity)">Cancel</button>
                </div>

                <!-- Remove row button -->
                <span class="padding-left-none padding-right-none">
                    <button
                        type="button"
                        class="btn btn-sm btn-no-border padding-normal-xxs margin-left-xs"
                        popover-enable="entity.id && !(data.dataRetentionSettings.canAddOrRemoveAll || data.dataRetentionSettings.removableEntities[entity.id])"
                        uib-popover-template="'../../common/tnkPopovers/tnkNoDataRetentionPermissionsPopover/noDataRetentionPermissionsPopoverTemplate.template.html'"
                        popover-placement="{{ placement || 'right' }}"
                        popover-class="common-width-60"
                        popover-trigger="mouseenter"
                        ng-click="removeEntity($index)"
                        ng-class="{
                            'disable-remove-button':
                                entity.id &&
                                !(
                                    data.dataRetentionSettings.canAddOrRemoveAll ||
                                    data.dataRetentionSettings.removableEntities[entity.id]
                                )
                        }"
                    >
                        <i
                            class="svg-icon-xxs"
                            ng-class="
                                entity.id &&
                                !(
                                    data.dataRetentionSettings.canAddOrRemoveAll ||
                                    data.dataRetentionSettings.removableEntities[entity.id]
                                )
                                    ? 'common-color-grey'
                                    : 'common-color-primary-black'
                            "
                        >
                            <span class="tnk-icon" ng-include="'images/icons/x.svg'" src="images/icons/x.svg">
                                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill="#34393E"
                                        d="M5.5,4.08578644 L2.26923077,0.855017207 L0.855017207,2.26923077 L4.08578644,5.5 L0.91701334,8.6687731 L2.3312269,10.0829867 L5.5,6.91421356 L8.78105434,10.1952679 L10.1952679,8.78105434 L6.91421356,5.5 L10.1449828,2.26923077 L8.73076923,0.855017207 L5.5,4.08578644 Z"
                                    ></path>
                                </svg>
                            </span>
                        </i>
                    </button>
                </span>
            </div>
        </div>

        <!-- Add entity button -->
        <a
            ng-click="addEntity()"
            ng-if="data.dataRetentionSettings.isSupportsDataRetention && data.dataRetentionSettings.canAddOrRemoveAll"
        >
            + Add Entity
        </a>

        <div class="alert alert-danger margin-top" ng-show="data.errorMessage">{{ data.errorMessage }}</div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer">
        <button class="btn btn-default" type="button" ng-click="onCancel()">Discard</button>
        <button
            class="btn btn-primary"
            ng-disabled="(selections|filter:{loadingBranches:true}:true).length"
            type="button"
            ng-click="saveModalConfiguration()"
        >
            Save
        </button>
    </div>
</div>
