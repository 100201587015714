import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationFrontDoorEntitySettingRow from './ProjectIntegrationFrontDoorEntitySettingRow';
import ProjectIntegrationFrontDoorEntitySettingRowColumns from './ProjectIntegrationFrontDoorEntitySettingRowColumns';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ADD_DIRECTION, FormikAutosave, FormikHelpers, useModifiableList } from '@tonkean/infrastructure';
import type { ProjectIntegration, ProjectIntegrationSmartSearchEntityConfig } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

const AddEntityRow = styled.div`
    display: flex;
    gap: 10px;
`;

interface Props {
    initialEntities: ProjectIntegrationSmartSearchEntityConfig[];
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationFrontDoorEntities: React.FC<Props> = ({ initialEntities, projectIntegration }) => {
    const [entities, { onCreate, onUpdate, onDelete }] =
        useModifiableList<ProjectIntegrationSmartSearchEntityConfig>(initialEntities);

    const [{ loading: createLoading, error: createError }, createProjectIntegrationSmartSearchAccessEntityConfig] =
        useLazyTonkeanService('createProjectIntegrationSmartSearchAccessEntityConfig');

    const [showAddEntityRow, setShowAddEntityRow] = useState(false);

    const shouldDisableChoice = useCallback(
        (externalType: string) => {
            return entities.some((it) => it.externalType === externalType);
        },
        [entities],
    );

    return (
        <>
            {entities.map((entity) => (
                <ProjectIntegrationFrontDoorEntitySettingRow
                    key={entity.id}
                    projectIntegration={projectIntegration}
                    entity={entity}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    shouldDisableChoice={shouldDisableChoice}
                />
            ))}

            {showAddEntityRow && (
                <Formik
                    initialValues={{ externalType: undefined }}
                    onSubmit={({ externalType }, formikHelpers) => {
                        if (!externalType) {
                            return;
                        }
                        return createProjectIntegrationSmartSearchAccessEntityConfig(
                            projectIntegration.projectId,
                            projectIntegration.id,
                            externalType,
                            false,
                            undefined,
                        ).then((result) => {
                            onCreate(result, ADD_DIRECTION.END);
                            setShowAddEntityRow(false);
                            formikHelpers.resetForm();
                        });
                    }}
                >
                    <FormikHelpers>
                        <FormikAutosave />
                        <ProjectIntegrationFrontDoorEntitySettingRowColumns
                            projectIntegration={projectIntegration}
                            onOpenConditions={() => {}}
                            onDelete={() => setShowAddEntityRow(false)}
                            shouldDisableChoice={shouldDisableChoice}
                            loading={createLoading}
                            error={createError}
                            createEntityMode
                        />
                    </FormikHelpers>
                </Formik>
            )}
            <AddEntityRow>
                <Button
                    data-automation="front-door-access-settings-add-row-entity"
                    onClick={() => setShowAddEntityRow(true)}
                    disabled={createLoading}
                    highlighted
                >
                    + Add Entity
                </Button>
            </AddEntityRow>
        </>
    );
};

export default ProjectIntegrationFrontDoorEntities;
