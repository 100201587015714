import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useState } from 'react';

import type SequenceSubmissionResponse from '../entities/SequenceSubmissionResponse';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { usePollingWithConditions } from '@tonkean/infrastructure';
import { useTDLWorkflowVersionByInitiativeId } from '@tonkean/tonkean-data-layer';
import type {
    FetchInitiativeFieldsResponse,
    FieldDefinitionIdToFetch,
    Initiative,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { SequenceState } from '@tonkean/tonkean-entities';

const useUpdateStepWithNextInitiative = (
    isWaitingForNextInitiative: boolean,
    setIsWaitingForNextInitiative: (isWaiting: boolean) => void,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    intakeInitiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    groupId: TonkeanId<TonkeanType.GROUP>,
    workflowVersionType: WorkflowVersionType,
    actionDefinition: any,
    sequenceSubmissionResponse: SequenceSubmissionResponse | undefined,
    setSubmitLoadingActive: (isLoading: boolean) => void,
    setCurrentSequenceState?: (sequenceState: SequenceState) => void,
) => {
    const [, fetchInitiativeFields] = useLazyTonkeanService('fetchInitiativeFields');

    const [{ data: itemInterfaceData, loading: loadingItemInterface }, getItemInterfaceDataForDisplay] =
        useLazyTonkeanService('getItemInterfaceDataForDisplay');

    const [nextInitiativeId, setNextInitiativeId] = useState<TonkeanId<TonkeanType.INITIATIVE> | undefined>(undefined);

    const { data: workflowVersionFromTDL, isLoading: workflowVersionFromTDLLoading } =
        useTDLWorkflowVersionByInitiativeId(projectId, nextInitiativeId);

    const projectManager = useAngularService('projectManager');

    const handleConditionalPolling = usePollingWithConditions();

    const getNextStepItemCreated = useCallback(
        (fieldsData: FetchInitiativeFieldsResponse) => {
            if (fieldsData?.initiatives) {
                const matchedItemFieldValue = fieldsData.initiatives.find(
                    (init) => init.initiativeId === intakeInitiativeId,
                )?.customFields?.[actionDefinition?.matchedInterfaceFieldDefinitionId]?.value;

                if (!!matchedItemFieldValue) {
                    setNextInitiativeId(matchedItemFieldValue as TonkeanId<TonkeanType.INITIATIVE>);
                }
            }
        },
        [actionDefinition, intakeInitiativeId],
    );

    const updateNextStepData = useCallback(
        (fieldsData: FetchInitiativeFieldsResponse) => {
            getNextStepItemCreated(fieldsData);
        },
        [getNextStepItemCreated],
    );

    const updateNextSequence = useCallback(
        async (workflowVersionFromTDL: WorkflowVersion) => {
            const interfaceDataResult = await getItemInterfaceDataForDisplay(
                projectId,
                nextInitiativeId!,
                actionDefinition?.itemInterfaceId,
                undefined,
                workflowVersionFromTDL.id,
                workflowVersionFromTDL.workflowVersionType,
            );

            projectManager.addGroup(interfaceDataResult.itemInterface?.group);
            setCurrentSequenceState?.({
                itemInterface: interfaceDataResult.itemInterface,
                customTriggerId: sequenceSubmissionResponse!.formAnsweredCustomTrigger.id,
                widgets: interfaceDataResult.widgets.entities,
                isLastInSequence: false,
                initiative: { id: nextInitiativeId } as Initiative,
                workflowVersion: workflowVersionFromTDL,
                evaluatedIntakeSequenceButtonLabel: sequenceSubmissionResponse!.evaluatedIntakeSequenceButtonLabel,
            });
            setSubmitLoadingActive(false);
        },
        [
            getItemInterfaceDataForDisplay,
            projectId,
            nextInitiativeId,
            actionDefinition?.itemInterfaceId,
            sequenceSubmissionResponse,
            setSubmitLoadingActive,
            setCurrentSequenceState,
            projectManager,
        ],
    );

    useEffect(() => {
        if (
            workflowVersionFromTDL &&
            !loadingItemInterface &&
            itemInterfaceData?.itemInterface.id !== actionDefinition?.itemInterfaceId
        ) {
            updateNextSequence(workflowVersionFromTDL);
        }
    }, [workflowVersionFromTDL, updateNextSequence, loadingItemInterface, itemInterfaceData, actionDefinition]);

    const updateNextStepDataIfItemWasCreated = useCallback(() => {
        const fieldDefinitionIdsToFetch: FieldDefinitionIdToFetch[] = [
            {
                fieldDefinitionId: actionDefinition?.matchedInterfaceFieldDefinitionId,
                groupId,
                workflowVersionType,
            },
        ];
        handleConditionalPolling(
            1000,
            (data) => {
                return getNextStepItemCreated(data) !== undefined;
            },
            () => fetchInitiativeFields(projectId, [intakeInitiativeId], [], fieldDefinitionIdsToFetch),
            (condition, data) => {
                setIsWaitingForNextInitiative(false);
                updateNextStepData(data);
            },
        );
    }, [
        actionDefinition,
        projectId,
        fetchInitiativeFields,
        intakeInitiativeId,
        groupId,
        workflowVersionType,
        getNextStepItemCreated,
        setIsWaitingForNextInitiative,
        updateNextStepData,
        handleConditionalPolling,
    ]);

    useEffect(() => {
        // If the next step is in another module, we need to wait for the next initiative to be created
        // We need to perform polling until the next initiative is created and then fetch it and it's workflow version
        if (isWaitingForNextInitiative) {
            updateNextStepDataIfItemWasCreated();
        }
    }, [isWaitingForNextInitiative, updateNextStepDataIfItemWasCreated]);
};

export default useUpdateStepWithNextInitiative;
