import React from 'react';

import { Placeholder, SimpleSelect } from '@tonkean/infrastructure';
import { HttpMethodType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const options = Object.keys(HttpMethodType).map((key) => ({ value: key, label: key }));

interface Props {
    name: string;
    isLoading: boolean;
}

const RequestTypeComponent: React.FC<Props> = ({ name, isLoading }) => {
    return (
        <>
            {isLoading && <Placeholder $height="36px" $width="auto" />}

            {!isLoading && (
                <SimpleSelect
                    styles={{
                        control: () => ({
                            height: '36px',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderRight: 'none',
                            backgroundColor: Theme.colors.gray_200,
                        }),
                    }}
                    name={name}
                    options={options}
                    size={InputSize.MEDIUM}
                />
            )}
        </>
    );
};
export default RequestTypeComponent;
