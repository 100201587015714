<div class="padding-normal-xs common-color-dark-grey common-size-xxs">
    <h4 class="mod-no-top">{{ tip.title }}</h4>

    <div class="common-break-long margin-normal-sm-v" light-markdown="tip.text"></div>
    <div class="margin-top-lg flex-vmiddle">
        <div class="common-size-xxxxxs common-color-grey flex-grow">
            Seen this before?
            <a ng-click="doneAll()">Opt out of all tips</a>
            .
        </div>
        <button class="btn btn-primary btn-sm" data-automation="tip-popover-template-button" ng-click="tipDone()">{{ tip.btn || 'Got it!' }}</button>
    </div>
</div>
