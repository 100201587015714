export function MicrosoftTeamsModalCtrl(
    $scope,
    $rootScope,
    $q,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    tonkeanService,
    environment,
    projectManager,
) {
    $scope.data = {
        connecting: false,
        error: null,
        isEdit: false,
        redirectUri: null,
        scope: 'https%3A%2F%2Fgraph.microsoft.com%2FTeam.ReadBasic.All https%3A%2F%2Fgraph.microsoft.com%2FUser.Read',
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state?.[0];
        $scope.projectIntegration = settings.projectIntegration;

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        if (state?.[0]?.projectData?.projectDatas?.[0]?.teamId) {
            $scope.data.isEdit = true;
            $scope.uniqueIntegrationId = $scope.integration.integrationUniqueType;
        }

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        // The oAuthHandler doesn't actually do oAuth, it just send the user to the Microsoft Teams marketplace,
        // so he can install Tonkean. So we always will go to the .then call here.
        // After sending the user there, we change the button to "I did it".
        // Once he clicks that, we get the project and check if it's connected. If it is, connection is successful.
        oauthHandler.microsoftTeams().then(() => {
            // The Microsoft Teams tab was opened, just change the button to "I did it".
            $scope.connectionTabOpened = true;
        });
    };

    $scope.authorize = async function () {
        $scope.data.connecting = true;
        $scope.data.error = null;
        const redirectUri = environment.integrationKeysMap.microsoftUri;

        try {
            const code = await oauthHandler.microsoft(
                $scope.data.scope,
                redirectUri,
                true,
            );
            const config = {
                code,
                redirectUri,
            };
            $scope.data.createMicrosoftTeamUserIntegration = true;
            const integObj = await createProjectApis.createIntegration(
                projectManager.project.id,
                'MICROSOFT_TEAMS_USER',
                config,
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'MICROSOFT_TEAMS_USER',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            );
            $scope.data.createMicrosoftTeamUserIntegration = false;
            $scope.integration = integObj;
        } catch (error) {
            $scope.error = error;
        } finally {
            $scope.connecting = false;
            $scope.loadAllTeams();

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.loadAllTeams = function () {
        $scope.data.teamsOptionsLoading = true;
        createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'teams', null)
            .then((data) => {
                $scope.data.teamsOptions = data.options;
            })
            .finally(() => {
                $scope.data.teamsOptionsLoading = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.data.isEdit = false;
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.onTeamClicked = function (team) {
        $scope.data.selectedTeam = team;
        $scope.data.checkIfBotIsConnectedToTeamLoading = true;
        checkIfBotConnectedToTeamAndCreateProjectIntegration();
    };

    function checkIfBotConnectedToTeamAndCreateProjectIntegration() {
        $scope.data.checkIfBotIsConnectedToTeamLoading = true;
        return tonkeanService
            .isBotConnectedToTeam(
                $scope.data.selectedTeam.value,
                $scope.integration.id,
                $scope.projectIntegration?.id,
                projectManager.project.id,
            )
            .then((response) => {
                $scope.data.connectBotToTeamAndTryAgain = false;
                return createTeamsIntegration(response.secret).then((integrationObj) => {
                    $scope.integration = integrationObj;
                    return $scope.ok(response.projectData);
                });
            })
            .finally(() => {
                $scope.data.checkIfBotIsConnectedToTeamLoading = false;
            });
    }

    $scope.checkIfBotConnectedToSelectedTeam = function () {
        checkIfBotConnectedToTeamAndCreateProjectIntegration().catch(() => {
            $scope.data.connectBotToTeamAndTryAgain = true;
        });
    };

    $scope.changeTeam = function () {
        $scope.data.selectedTeam = null;
        $scope.data.connectBotToTeamAndTryAgain = false;
        $scope.connectionTabOpened = false;
    };

    function createTeamsIntegration(secret) {
        return createProjectApis.createIntegration(
            projectManager.project.id,
            'MICROSOFT_TEAMS',
            {
                aaGroupId: $scope.data.selectedTeam.value,
                secret,
            },
            $scope.uniqueIntegrationId,
            undefined,
        );
    }

    $scope.ok = function (projectData) {
        if (!$scope.data.integrationForm.$valid) {
            return $q.resolve();
        }

        // If the integration is of type MICROSOFT_TEAMS_USER, we dont want to save it.
        const settings =
            $scope.integration.integrationType === 'MICROSOFT_TEAMS'
                ? {
                      integration: $scope.integration,
                      integrations: [{ integration: $scope.integration, projectData }],
                  }
                : {};

        if ($scope.onIntegrationClosed) {
            return $scope.onIntegrationClosed(settings);
        }
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}
export default angular.module('tonkean.shared').controller('MicrosoftTeamsModalCtrl', MicrosoftTeamsModalCtrl);
