<div class="manual-items-template-config">
    <div ng-repeat="initiative in data.initiatives" class="flex-vmiddle mod-align-start margin-bottom">
        <!-- For each initiative in initiative creation -->
        <div
            class="svg-icon-smd svg-icon-hover-primary pointer margin-top"
            uib-popover-template="'./manualItemsFieldsToUpdatePopoverContent.template.html'"
            popover-placement="bottom-left"
            popover-class="manual-item-popover"
            popover-is-open="data.isOpen[$index]"
        >
            <div class="relative">
                <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>

                <span class="add-due-date-time-bubble" ng-if="initiative.actualFieldsCount > 0">
                    {{ initiative.actualFieldsCount }}
                </span>
            </div>
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
            class="flex-left flex-grow"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="{{ data.configuredLogic.node.displayName }}"
            saved-original-expression="initiative.expression.originalExpression"
            saved-evaluated-expression="initiative.expression.evaluatedExpression"
            expression-unique-identifier="manualItems{{ $index }}"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic, initiative)
            "
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>
    <!-- Add new initiative -->
    <div class="common-size-xxs">
        <a ng-click="addEmptyInitiative()">+ Add</a>
    </div>

    <!-- Apply on previously created tracks -->
    <div class="flex-vmiddle margin-top common-size-xxs" ng-hide="data.hideApplyOnPreviouslyCreatedTracks">
        <input
            type="checkbox"
            ng-change="onInnerTracksTemplateConfigChanged()"
            ng-model="data.applySettingsOnPreviouslyCreatedTracks"
            id="applySettingsOnPreviouslyCreatedTracks"
            class="margin-top-none"
        />
        <span class="margin-left-xs pointer" ng-click="toggleApplySettingsOnPreviouslyCreatedTracks()">
            Apply to all previously created Items in this Module.
        </span>
    </div>
</div>
