<div class="tnk-salesforce-custom-actions-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'CONVERT_LEAD'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">
                Account ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, a new account will be created."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Account ID"
                saved-original-expression="data.definition.accountIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.accountIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onAccountIdTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">Converted Status:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Convert State ('Closed - Converted')"
                saved-original-expression="data.definition.convertState"
                saved-evaluated-expression="data.definition.evaluatedConvertState"
                on-tonkean-expression-changed="
                    onConvertStateExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.convertState" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.convertState }}
            </div>

            <tnk-checkbox
                class="margin-right-xs"
                label="'Create Opportunity'"
                value="data.definition.createOpportunity"
                on-toggle="onCreateOpportunityToggled(value)"
            ></tnk-checkbox>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'MERGE_RECORDS'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">Record To Merge:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Record to merge ID"
                saved-original-expression="data.definition.recordToMerge"
                saved-evaluated-expression="data.definition.evaluatedRecordToMerge"
                on-tonkean-expression-changed="
                    onRecordToMergeExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.recordToMerge" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.recordToMerge }}
            </div>
        </div>

        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">Object Name:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Record type (Lead, Account, etc.)"
                saved-original-expression="data.definition.recordType"
                saved-evaluated-expression="data.definition.evaluatedRecordType"
                on-tonkean-expression-changed="
                    onRecordTypeExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.recordType" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.recordType }}
            </div>
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'UPLOAD_DOCUMENT' ||
            data.definition.customActionKey === 'UPDATE_DOCUMENT'
        "
        class="margin-bottom-xlg"
    >
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Comment/Reason for Change -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Comment:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Comment"
                saved-original-expression="data.definition.documentCommentExpression.originalExpression"
                saved-evaluated-expression="data.definition.documentCommentExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onDocumentCommentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- New Document Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New Document Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Document name"
                saved-original-expression="data.definition.newDocumentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
