import React, { useMemo } from 'react';

import EnterpriseComponentPageMenuSection from './EnterpriseComponentPageMenuSection';
import type { EnterpriseComponentPageMenuSectionConfiguration } from '../../../entities/EnterpriseComponentPageMenuConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import { isMenuItemVisible } from '../../../utils/IsMenuItemVisible';

import { SideMenuContainer } from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    sections: EnterpriseComponentPageMenuSectionConfiguration<T, C>[];
    enterpriseComponentOverviewResult: ConvertEnterpriseComponentTypeToOverviewResult<T> | undefined;
}

const EnterpriseComponentPageMenu = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    sections,
    enterpriseComponentOverviewResult,
}: Props<T, C>) => {
    const { currentEnterpriseComponentAccessType } = useEnterpriseComponentContext();
    const visibleSections = useMemo(
        () =>
            sections.filter((section) =>
                section.menuItems.some((item) => isMenuItemVisible(item, currentEnterpriseComponentAccessType)),
            ),
        [sections, currentEnterpriseComponentAccessType],
    );

    const enterpriseComponentOverviewStepKeys = useMemo(() => {
        return sections
            .flatMap((section) => section.menuItems)
            .filter((menuItem) => menuItem.stepStatusKey)
            .map((menuItem) => menuItem.stepStatusKey!);
    }, [sections]);

    return (
        <SideMenuContainer data-automation="enterprise-component-page-menu-container">
            {visibleSections.map((section) => (
                <EnterpriseComponentPageMenuSection
                    key={section.id}
                    section={section}
                    enterpriseComponentOverviewResult={enterpriseComponentOverviewResult}
                    enterpriseComponentOverviewStepKeys={enterpriseComponentOverviewStepKeys}
                />
            ))}
        </SideMenuContainer>
    );
};

export default EnterpriseComponentPageMenu;
