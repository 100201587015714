import { useAngularService } from 'angulareact';
import React from 'react';

import ConnectNewDatasourceInputSource from './ConnectNewDatasourceInputSource';
import ViewExistingDatasourceInputSource from './ViewExistingDatasourceInputSource';
import WorkflowVersionInputSourceType from '../../../../entities/WorkflowVersionInputSourceType';
import type { InputSourceContentComponentProps } from '../../InputSourceContentComponentProps';
import { SelectInputSourceTitleContainer } from '../sharedComponents';

import { H4, Tooltip } from '@tonkean/infrastructure';
import type { IntegrationType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

interface Props extends InputSourceContentComponentProps {
    filterByIntegrationType?: IntegrationType[];
}

const GenericDatasourceInputSourceSelection: React.FC<Props> = ({
    workflowVersion,
    setSelectedInputSourceType,
    filterByIntegrationType,
}) => {
    const $timeout = useAngularService('$timeout');
    const $state = useAngularService('$state');
    const typeChangeDisabled = !!workflowVersion.syncConfig;
    return (
        <>
            <SelectInputSourceTitleContainer>
                <H4 $bold>Select Data Source</H4>

                <Tooltip
                    content="Must remove existing connection to change intake method"
                    disabled={!typeChangeDisabled}
                >
                    <ClickableLink
                        onClick={() => {
                            $timeout(() => {
                                $state.go(
                                    '.',
                                    { projectIntegration: undefined, syncProjectIntegrationId: undefined },
                                    { notify: false },
                                );
                            });
                            return setSelectedInputSourceType(WorkflowVersionInputSourceType.MANUAL);
                        }}
                        disabled={typeChangeDisabled}
                    >
                        Change Intake Method
                    </ClickableLink>
                </Tooltip>
            </SelectInputSourceTitleContainer>

            {workflowVersion.syncConfig ? (
                <ViewExistingDatasourceInputSource
                    syncConfig={workflowVersion.syncConfig}
                    workflowVersion={workflowVersion}
                    groupId={workflowVersion.groupId}
                />
            ) : (
                <ConnectNewDatasourceInputSource
                    workflowVersion={workflowVersion}
                    filterByIntegrationType={filterByIntegrationType}
                />
            )}
        </>
    );
};

export default GenericDatasourceInputSourceSelection;
