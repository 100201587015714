import template from './matchTonkeanItemField.template.html?angularjs';

/**
 */
export default angular.module('tonkean.app').component('tnkMatchTonkeanItemField', {
    bindings: {
        groupId: '<',
        workflowVersionId: '<',
        viewOnly: '<',
        targetWorkflowVersionId: '<',
        openedFromCustomTriggerId: '<',
        existingFieldDefinition: '<',
        onDefinitionChange: '&',
        onChange: '&',
    },
    template,
    controller: 'MatchTonkeanItemFieldCtrl',
});
