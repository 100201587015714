<div class="manual-next-steps-logic-configuration">
    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noTracks" class="common-color-danger margin-bottom-xs">
        {{ data.invalidLogics[data.configuredLogic.node.id].noTracks }}
    </div>

    <div
        ng-if="data.invalidLogics[data.configuredLogic.node.id].invalidTrack"
        class="common-color-danger margin-bottom-xs"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].invalidTrack }}
    </div>

    <!-- Fields selection title -->
    <div class="margin-bottom-xs">
        <div class="common-size-xxs">Manual:</div>

        <tnk-manual-items-template-config
            existing-inner-tracks-template="data.innerTracksTemplate"
            on-inner-tracks-template-config-changed="
                onInnerTracksTemplateConfigChanged(innerTracksTemplate, shouldSaveLogic)
            "
            should-show-remove-button="data.shouldShowRemoveButton"
            configured-logic="data.configuredLogic"
            hide-apply-on-previously-created-tracks="true"
            new-track-placeholder="Action Item"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-manual-items-template-config>
    </div>

    <div class="margin-bottom-xs">
        <div class="common-size-xxs margin-bottom-xs">
            Dynamic:
            <i
                class="fa fa-info-circle margin-left-xs"
                uib-tooltip="Dynamic action items support multiple items in the same expression separated by a comma.
            All these action items will have the same fields configuration."
            ></i>
        </div>
        <tnk-manual-items-template-config
            existing-inner-tracks-template="data.dynamicInnerTracksTemplate"
            on-inner-tracks-template-config-changed="
                onDynamicInnerTracksTemplateConfigChanged(innerTracksTemplate, shouldSaveLogic)
            "
            should-show-remove-button="data.shouldShowRemoveButton"
            configured-logic="data.configuredLogic"
            hide-apply-on-previously-created-tracks="true"
            new-track-placeholder="Action Item"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            is-dynamic="true"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-manual-items-template-config>
    </div>
    <!-- Separator -->
    <hr />

    <!-- Fields selection title -->
    <div>
        <div class="common-title-xxs">Creator</div>
        <span class="common-size-xxs common-color-grey3">Choose who would be the creator of the inner items</span>
    </div>

    <!-- Selecting notification method -->
    <tnk-person-selection-configuration
        class="block padding-top"
        support-quick-create="true"
        radio-id-prefix="'manual-items-creator-' + data.workflowVersionId"
        allow-none="true"
        none-option-label="'Default actor (' + pm.groupsMap[data.groupId].defaultActor.name + ')'"
        max-people="1"
        hide-fail-if-no-value="true"
        configured-logic="data.configuredLogic"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        saved-person-selection-configuration="data.existingManualItemsCreatorPersonSelectionConfiguration"
        on-person-selection-configuration-changed="
            onManualItemsCreatorPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        unique-identifier="creator"
        preview-evaluation-source="data.previewEvaluationSource"
    ></tnk-person-selection-configuration>

    <!-- Separator -->
    <hr />

    <!-- Notification Settings -->
    <div>
        <div class="common-title-xxs">Notification Settings</div>
        <span class="common-size-xxs common-color-grey3">
            Define whether item update notification should be sent (only in production environment)
        </span>
    </div>

    <div class="margin-top" ng-if="!data.cantControlNotification">
        <label>
            <input type="checkbox" ng-model="data.shouldSendNotification" ng-change="onActionsChanged(true)" />
            <span class="common-normal-weight">Send notifications</span>
        </label>
    </div>

    <!-- Separator -->
    <hr />

    <!-- Preview -->
    <div class="margin-top-lg">
        <!-- Title -->
        <div class="margin-bottom common-bold">Message Preview:</div>

        <!-- Preview -->
        <tnk-preview-bot-bubble-ctrl
            text="'Hi, ' + as.currentUser.name + ' has set you the owner of '"
            post-text="tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm)"
            post-text-evaluated-expression="data.evaluatedPostText"
            preview-type="text"
            is-email="!cis.anyIntegrationsConnected()"
            user="pm.groupsMap[data.groupId].creator"
            no-triangle="true"
            auto-width="true"
            logic-id="data.configuredLogic.node.id"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            bot-buttons="data.previewButtons"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-preview-bot-bubble-ctrl>
    </div>
</div>
