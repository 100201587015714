<div
    class="activity-popup pointer activity-popup-button"
    id="activity-popup-button"
    ng-if="!inline"
    ng-class="{ 'mod-active': popover.isOpen }"
    ng-click="popover.isOpen = !popover.isOpen"
>
    <i class="svg-icon tab-icon svg-icon-align-text-base common-color-dark-grey">
        <tnk-icon src="../../../../../apps/tracks/images/icons/bell.svg"></tnk-icon>
    </i>
    <!-- <span class="activity-popup-button-notification common-size-xxxxs" ng-if="newItemsCount > 0">
        {{ newItemsCount }}
    </span> -->
</div>
<div
    class="activity-sidebar text-left"
    ng-if="popover.isOpen || inline"
    id="activitySidebar"
    click-outside="popover.isOpen = false"
    outside-if-not="select-choices-row, activity-popup-button"
    ng-class="!inline ? 'mod-popup' : '' + (!borderless ? ' mod-border' : '') + ' ' + customClass"
>
    <div
        class="
            common-color-dark-grey
            padding-normal
            margin-bottom-lg margin-xs-top
            activity-list-title
            popover-primary-bg-container
            mod-bottom
            flex-vmiddle
        "
    >
        <!-- Title -->
        <div class="common-title-xs">Notifications</div>

        <!-- Activity digest email settings open button -->
        <button
            class="btn btn-no-border btn-default common-no-outline"
            uib-popover-template="'./activityPopupFirstTimePopoverTemplate.template.html'"
            popover-is-open="!data.activityFirstTimePopoverAlreadyRead"
            popover-placement="bottom-right"
            popover-trigger="none"
            ng-click="modal.openActivityDigestEmailSettingsModal()"
        >
            <span class="svg-icon common-color-dark">
                <tnk-icon src="../../../../../apps/tracks/images/icons/cog-o.svg"></tnk-icon>
            </span>
        </button>

        <!-- Notification settings button -->
        <button
            class="btn btn-no-border btn-default common-no-outline"
            ng-click="popover.showSettings = !popover.showSettings"
            ng-if="pm.isOwner && data.communicationIntegration"
        >
            <span
                class="initiative-integration-icon mod-normal mod-dropdown inline-block margin-none"
                ng-class="
                    data.communicationIntegration
                        ? 'mod-' + data.communicationIntegration.integration.integrationType.toLowerCase()
                        : 'mod-slack'
                "
            ></span>
        </button>
        <div class="flex-grow"></div>
        <div ng-click="popover.isOpen = false" class="visible-xs-block padding-normal margin-right">
            <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
        </div>
    </div>
    <!--<hr class="margin-none"/>-->
    <div class="activity-inner-alert" ng-if="pm.isOwner" ng-show="popover.showSettings">
        <tnk-notification-settings></tnk-notification-settings>
    </div>

    <div class="margin-top margin-right pull-right" ng-if="loadingActivity">
        <i class="loading-medium"></i>
    </div>

    <!-- Filters -->
    <div class="margin-right-md margin-left-md">
        <div class="flex-vmiddle">
            <div class="flex-grow"></div>

            <!-- Filter By Initiative -->
            <div class="flex-no-shrink dropdown" uib-dropdown>
                <a
                    class="flex-vmiddle common-link-no-style common-title-xxxs"
                    uib-popover-template="'./filterByInitiativePopover.template.html'"
                    popover-placement="bottom-right"
                    popover-is-open="data.initiativePopoverIsOpen"
                >
                    <span class="margin-right-xxs">
                        {{
                            data.filter.simplifiedInitiative.title
                                ? data.filter.simplifiedInitiative.title
                                : 'All Items'
                        }}
                    </span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>
            </div>
        </div>

        <!-- Separator -->
        <hr class="margin-top-none" />
    </div>

    <div
        ng-show="!activities.length"
        class="margin-top-lg padding-top-xs letter-spacing-normal common-font-smooth relative"
    >
        <div class="text-center margin-bottom-xlg">
            <span class="svg-icon">
                <tnk-icon src="../../../../../apps/tracks/images/icons/happy-bell.svg"></tnk-icon>
            </span>

            <div ng-if="!data.filter.simplifiedInitiative.id" class="common-color-light-grey margin-top-md">
                You don't have any Notifications yet
            </div>
            <div ng-if="data.filter.simplifiedInitiative.id" class="common-color-light-grey margin-top-md">
                <div>no notifications for this track yet.</div>
                <div>
                    <a class="bot-side-pane-block-click-outside" ng-click="clearSelectedInitiative()">Show all items</a>
                </div>
            </div>
        </div>
        <div
            ng-if="!data.filter.simplifiedInitiative.id"
            class="common-color-dark-grey margin-top-xlg margin-left-lg padding-left"
        >
            <div class="margin-bottom">The type of notifications you'll see here:</div>

            <div class="flex-vmiddle margin-top">
                <span class="activity-check-circle common-color-white margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/check.svg"></tnk-icon>
                </span>
                <div class="margin-left-xxs">New items added</div>
            </div>

            <div class="flex-vmiddle margin-top">
                <span class="activity-check-circle common-color-white margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/check.svg"></tnk-icon>
                </span>
                <div class="margin-left-xxs">New Status updates from owners</div>
            </div>

            <div class="flex-vmiddle margin-top">
                <span class="activity-check-circle common-color-white margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/check.svg"></tnk-icon>
                </span>
                <div class="margin-left-xxs">Comments on updates</div>
            </div>
        </div>
    </div>
    <div ng-show="activities.length" class="clearfix margin-top">
        <div ng-if="todayActivity && todayActivity.length">
            <div class="common-bold common-color-dark-grey padding-normal-sm margin-left">TODAY</div>
            <hr class="margin-none" />
            <div class="activity-popup-inner-list">
                <tnk-activity-item
                    ng-repeat="activity in todayActivity"
                    item="activity"
                    notification="true"
                    custom-css="activity-popup-inner-list-item"
                    is-new="false"
                    ng-click="handleClick()"
                ></tnk-activity-item>
            </div>
        </div>
        <div ng-if="yesterdayActivity && yesterdayActivity.length">
            <div class="common-bold common-color-dark-grey padding-normal-sm margin-left">YESTERDAY</div>
            <hr class="margin-none" />
            <div class="activity-popup-inner-list">
                <tnk-activity-item
                    ng-repeat="activity in yesterdayActivity"
                    item="activity"
                    notification="true"
                    custom-css="activity-popup-inner-list-item"
                    is-new="false"
                    ng-click="handleClick()"
                ></tnk-activity-item>
            </div>
        </div>
        <div ng-if="olderActivity && olderActivity.length">
            <div ng-if="(todayActivity && todayActivity.length) || (yesterdayActivity && yesterdayActivity.length)">
                <div class="common-bold common-color-dark-grey padding-normal-sm margin-left">OLDER</div>
            </div>
            <hr class="margin-none" />
            <div class="activity-popup-inner-list">
                <tnk-activity-item
                    ng-repeat="activity in olderActivity"
                    item="activity"
                    notification="true"
                    custom-css="activity-popup-inner-list-item"
                    is-new="false"
                    ng-click="handleClick()"
                ></tnk-activity-item>
            </div>
        </div>
        <!--<div ng-if="activities.length > 5" class="common-color-dark-grey common-size-xxs margin-normal-sm-v">-->
        <!--<a ui-sref="product.home" ng-click="handleClick()">View all activity...</a>-->
        <!--</div>-->
    </div>
</div>
