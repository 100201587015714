import { useAngularService } from 'angulareact';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';

import type { CustomFiltersProps } from './TnkCustomFilters';
import CustomFilters from './TnkCustomFilters';

import { getFieldConditionToApiDefinitionMap } from '@tonkean/constants';
import type { BasicQueryDefinition, FieldFilter } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const fieldConditionsConfiguration = getFieldConditionToApiDefinitionMap();

const emptyFilterMessage =
    "All filters must have a value configured, use 'Is Empty' condition if empty value is expected";
const FieldQueryValidation = Yup.object({
    filters: Yup.array(
        Yup.object()
            .when({
                is: (filter: FieldFilter) => {
                    return fieldConditionsConfiguration[filter.condition].requiresSecondValue;
                },
                then: Yup.object({
                    secondValue: Yup.mixed().required('Both values must be filled for this filter'),
                }),
            })
            .when({
                is: (filter: FieldFilter) => {
                    return !fieldConditionsConfiguration[filter.condition].noValueRequired;
                },
                then: Yup.object({
                    value: Yup.mixed()
                        // Custom filters might put NaN as empty value and .required() doesnt treat that as empty so we have a special case
                        .notOneOf([Number.NaN], emptyFilterMessage)
                        .required(emptyFilterMessage),
                }),
            }),
    ),
    // Recursive typing
    queries: Yup.array().of(Yup.lazy(() => FieldQueryValidation.default(undefined)) as any),
});
export const TnkCustomFiltersValidationSchema = {
    query: FieldQueryValidation,
};

export interface FormikTnkCustomFilters
    extends Omit<CustomFiltersProps, 'onFiltersChangeDefinition' | 'existingDefinition' | 'control'> {}

interface Props extends FormikTnkCustomFilters, StyledComponentsSupportProps {
    name: string;
}

const FormikTnkCustomFilters: React.FC<Props> = ({ name, className, ...props }) => {
    const controlObjectRef = useRef({});
    const [, { value, error }, definitionHelpers] = useField<BasicQueryDefinition | undefined>(name);
    const $timeout = useAngularService('$timeout');
    const { validateForm } = useFormikContext();
    return (
        <div className={className}>
            <CustomFilters
                {...props}
                existingDefinition={value}
                control={controlObjectRef.current}
                onFiltersChangeDefinition={(definition) => {
                    if (JSON.stringify(definition) !== JSON.stringify(value)) {
                        definitionHelpers.setValue(definition);

                        // Validate the form after timeout to make sure weve validated on the updated values.
                        // Will cause incorrect validation errors when changing the conditions if not done.
                        $timeout(() => {
                            validateForm();
                        });
                    }
                }}
                errorObject={(error as any)?.query}
                keepEmptyValuedFilters
                filtersChangeOnlyAfterInit
                invokeChangeOnConditionSelection
            />
        </div>
    );
};

export default FormikTnkCustomFilters;
