import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';

import StatusSection from './StatusSection';
import type StateSectionsFormikValues from '../entities/StateSectionsFormikValues';
import type { StateData } from '../entities/StateSectionsFormikValues';
import type { StatusListDataContextProps } from '../StatusListDataContext';
import StatusListDataContext from '../StatusListDataContext';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { ErrorMessage, FormikAutosave, FormikHelpers } from '@tonkean/infrastructure';
import { useStatesForWorkflowVersion } from '@tonkean/infrastructure';
import {
    InitiativeStatus,
    type Group,
    type Project,
    type State,
    type WorkflowVersion,
} from '@tonkean/tonkean-entities';

const StyledErrorMessage = styled(ErrorMessage)`
    margin: 8px 8px 0 8px;
`;

const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const IntakeTypes = new Set([InitiativeStatus.INTAKE]);
const TriageAndCoordinationTypes = new Set([
    InitiativeStatus.ACTIVE,
    InitiativeStatus.DONE,
    InitiativeStatus.WARN,
    InitiativeStatus.ON_HOLD,
    InitiativeStatus.ARCHIVE,
]);

const StatusSections: React.FC<{
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersionId: WorkflowVersion['id'];
    onChange: (states: State[]) => Promise<void>;
    viewOnly: boolean;
}> = ({ workflowVersionId, projectId, groupId, onChange, viewOnly }) => {
    const { loading, data: workflowVersion, error } = useTonkeanService('getWorkflowVersionById', workflowVersionId);
    const emitToastMessage = useToastMessage(2000);

    const { states } = useStatesForWorkflowVersion(workflowVersion);

    const initialValues: StateSectionsFormikValues = useMemo(() => {
        return {
            triageAndCoordinationStatuses: states
                .filter(({ type }) => TriageAndCoordinationTypes.has(type))
                .map((state) => ({
                    formikId: state.id,
                    state,
                })),

            intakeStatuses: states
                .filter(({ type }) => IntakeTypes.has(type))
                .map((state) => ({
                    formikId: state.id,
                    state,
                })),
        };
    }, [states]);

    const contextDate: StatusListDataContextProps = useMemo(() => {
        return {
            projectId,
            groupId,
            viewOnly,
            loading,
        };
    }, [groupId, projectId, viewOnly, loading]);

    if (error) {
        return <ErrorMessage>Error View Statuses</ErrorMessage>;
    }

    return (
        <Formik<StateSectionsFormikValues>
            onSubmit={(values) => {
                const allStatuses = [...values.intakeStatuses, ...values.triageAndCoordinationStatuses];
                const uniqueNamesAmount = new Set(allStatuses.map(({ state: { label } }) => label)).size;

                if (allStatuses.length === uniqueNamesAmount) {
                    return onChange(allStatuses.map(({ state }) => state));
                } else {
                    return Promise.resolve();
                }
            }}
            initialValues={initialValues}
            enableReinitialize
        >
            {({ setFieldValue, getFieldProps, values }) => {
                const allData = [...values.intakeStatuses, ...values.triageAndCoordinationStatuses];

                const hasDuplicateName = allData.length !== new Set(allData.map(({ state: { label } }) => label)).size;

                return (
                    <FormikHelpers>
                        <Form>
                            {hasDuplicateName && (
                                <StyledErrorMessage data-automation="duplicate-name-error">
                                    Duplicate name detected
                                </StyledErrorMessage>
                            )}
                            <hr />

                            <FormikAutosave />
                            <DragDropContext
                                onDragEnd={({ destination, source }) => {
                                    // dropped outside the list
                                    if (!destination) {
                                        return undefined;
                                    }

                                    // dropped on the same place
                                    if (
                                        destination.droppableId === source.droppableId &&
                                        destination.index === source.index
                                    ) {
                                        return undefined;
                                    }

                                    const isDroppedOnSameDropId = destination.droppableId === source.droppableId;

                                    if (isDroppedOnSameDropId) {
                                        // reorder states in the same list
                                        const reorderStates = [
                                            ...getFieldProps<StateData[]>(destination.droppableId).value,
                                        ];

                                        const [removedStore] = reorderStates.splice(source.index, 1);

                                        if (removedStore) {
                                            reorderStates.splice(destination.index, 0, removedStore);
                                            setFieldValue(destination.droppableId, reorderStates);
                                        }
                                    } else {
                                        // move state to another list (from intake to triage and vice versa) and change state type accordingly
                                        const originalDropStatuses = [
                                            ...getFieldProps<StateData[]>(source.droppableId).value,
                                        ];

                                        const destinationDropStatuses = [
                                            ...getFieldProps<StateData[]>(destination.droppableId).value,
                                        ];

                                        // check if the state was the only 'Done' status in the list
                                        const isTheOnlyDoneState =
                                            [...originalDropStatuses, ...destinationDropStatuses].filter(
                                                ({ state }) => state.type === InitiativeStatus.DONE,
                                            ).length === 1 &&
                                            originalDropStatuses[source.index]?.state.type === InitiativeStatus.DONE;

                                        if (isTheOnlyDoneState) {
                                            emitToastMessage("You must have at least one 'Done' status", 'danger');
                                            return;
                                        }

                                        const isTheOnlyIntakeState =
                                            [...originalDropStatuses, ...destinationDropStatuses].filter(
                                                ({ state: { type, id } }) => type === InitiativeStatus.INTAKE,
                                            ).length === 1 &&
                                            originalDropStatuses[source.index]?.state.type === InitiativeStatus.INTAKE;

                                        if (isTheOnlyIntakeState) {
                                            emitToastMessage("You must have at least one 'Intake' status", 'danger');
                                            return;
                                        }

                                        const [removedStore] = originalDropStatuses.splice(source.index, 1);

                                        if (removedStore) {
                                            destinationDropStatuses.splice(destination.index, 0, removedStore);

                                            // change the state type
                                            destinationDropStatuses[destination.index]!.state.type =
                                                destinationDropStatuses[destination.index]!.state.type ===
                                                InitiativeStatus.INTAKE
                                                    ? InitiativeStatus.ACTIVE
                                                    : InitiativeStatus.INTAKE;

                                            setFieldValue(destination.droppableId, destinationDropStatuses);
                                            setFieldValue(source.droppableId, originalDropStatuses);
                                        }
                                    }
                                }}
                            >
                                <StatusListDataContext.Provider value={contextDate}>
                                    <SectionsWrapper>
                                        <StatusSection
                                            name="intakeStatuses"
                                            title="Intake"
                                            defaultStatusType={InitiativeStatus.INTAKE}
                                            hideTypeSelection
                                        />

                                        <StatusSection
                                            name="triageAndCoordinationStatuses"
                                            title="Triage & Coordination"
                                            typesToInclude={TriageAndCoordinationTypes}
                                            defaultStatusType={InitiativeStatus.ACTIVE}
                                        />
                                    </SectionsWrapper>
                                </StatusListDataContext.Provider>
                            </DragDropContext>
                        </Form>
                    </FormikHelpers>
                );
            }}
        </Formik>
    );
};

export default StatusSections;
