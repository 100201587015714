import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: ${Theme.colors.gray_200};
    padding: 24px;
    overflow: hidden;
`;

const VerticalContainer = styled.div<{ gap: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => `${gap}px`};
`;

const HorizontalContainer = styled.div<{ gap: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ gap }) => `${gap}px`};
`;

const Rectangle = styled.div<{ width: string; height: string }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: ${Theme.colors.gray_300};
    border-radius: 3px;
`;

enum RectangleSize {
    SMALL = '19px',
    MEDIUM = '24px',
    LARGE = '40px',
    XLARGE = '135px',
}

const VerticalSmallAndALargeRectangles = ({
    largeRectSize = RectangleSize.LARGE,
}: {
    largeRectSize?: RectangleSize.LARGE | RectangleSize.XLARGE;
}) => (
    <VerticalContainer gap={9}>
        <Rectangle width="80%" height={RectangleSize.SMALL} />
        <Rectangle width="100%" height={largeRectSize} />
    </VerticalContainer>
);

const DetailedItemsWidgetDrillDownLoading = () => {
    return (
        <Wrapper>
            <VerticalContainer gap={24}>
                <Rectangle width="80%" height={RectangleSize.MEDIUM} />
                <VerticalContainer gap={36}>
                    {Array.from({ length: 2 }).map((_, i) => (
                        <VerticalSmallAndALargeRectangles key={i} />
                    ))}
                    <HorizontalContainer gap={36}>
                        {Array.from({ length: 2 }).map((_, i) => (
                            <VerticalSmallAndALargeRectangles key={i} />
                        ))}
                    </HorizontalContainer>
                    <VerticalSmallAndALargeRectangles key="xlarge" largeRectSize={RectangleSize.XLARGE} />
                    <HorizontalContainer gap={36}>
                        {Array.from({ length: 2 }).map((_, i) => (
                            <VerticalSmallAndALargeRectangles key={i} />
                        ))}
                    </HorizontalContainer>
                </VerticalContainer>
            </VerticalContainer>
        </Wrapper>
    );
};

export default DetailedItemsWidgetDrillDownLoading;
