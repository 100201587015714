export function WunderlistModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.lists = state.lists;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateListLists();
        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        const promise = oauthHandler
            .wunderlist(environment.integrationKeysMap.wunderlist, environment.integrationKeysMap.wunderlistUri)
            .then(function (code) {
                return { code };
            });

        promise
            .then(function (config) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'WUNDERLIST',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (data) {
                $scope.integration = data;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'lists',
                );
            })
            .then(function (data) {
                $scope.selections = data.options.slice(0, 3).map(function (list) {
                    return { list };
                });
                $scope.lists = data.options;
                updateListLists();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.lists = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.$watch('temp.list', function () {
        $scope.onListSelected($scope.temp.list);
    });

    $scope.onListSelected = function (list) {
        if (list && list.displayName) {
            const selection = { list, selected: true };
            $scope.selections.push(selection);
            updateListLists();
            $scope.onSelectionChange(selection);
            $scope.temp.list = null;
        }
    };

    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateListLists() {
        if ($scope.lists) {
            const listInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.list.displayName;
                }),
            );
            $scope.otherLists = $scope.lists.filter(function (r) {
                return !listInSelection.has(r.displayName);
            });
        }
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            lists: $scope.lists,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = [
            {
                integration: $scope.integration,
                projectData: {
                    lists: $scope.selections
                        .map(function (selection) {
                            if (selection.selected) {
                                return selection.list.value;
                            }
                        })
                        .filter(angular.identity),
                },
            },
        ];

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'lists')
                .then(function (data) {
                    $scope.lists = data.options;
                    $scope.selections = data.options
                        .map(function (list) {
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (
                                        let i = 0;
                                        i < originalIntegration.projectData.projectDatas[0].lists.length;
                                        i += 1
                                    ) {
                                        const currentList = state.integrations[0].projectData.projectDatas[0].lists[i];

                                        if (currentList === list.value) {
                                            return {
                                                list,
                                                selected: true,
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);

                    updateListLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}
export default angular.module('tonkean.shared').controller('WunderlistModalCtrl', WunderlistModalCtrl);
