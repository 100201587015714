import React from 'react';
import styled from 'styled-components';

import SolutionSiteBuilderHeaderLoading from './SolutionSiteBuilderHeaderLoading';
import SolutionSiteInterfaceHeaderColorBanner from './SolutionSiteInterfaceHeaderColorBanner';
import { SolutionSitePageCanvasContent, SolutionSitePageWidgetsWrapper } from './SolutionSitePageInterfaceView';
import { SOLUTION_SITE_INTERFACE_MENU_WIDTH } from './SolutionSiteSidePane';
import SolutionSiteSidePaneLoading from './SolutionSiteSidePaneLoading';

import { Breakpoint, useBreakpoint } from '@tonkean/infrastructure';
import { NavigationTopBarLoading } from '@tonkean/navigation';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    background-color: ${Theme.colors.gray};
`;

const SolutionSitePageWidgetsWrapperMockGrid = styled(SolutionSitePageWidgetsWrapper)`
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 32px;
`;

const WidgetPlaceholder = styled.div`
    height: 100%;
    border-radius: 6px;
    border: 1px solid ${Theme.colors.gray_300};
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);
`;

const WidgetWidePlaceholder = styled(WidgetPlaceholder)`
    grid-column: span 2;
`;

const LoadingGrid = styled.div`
    flex-grow: 1;
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    grid-template-areas:
        'solution-site-banner solution-site-banner'
        'solution-site-sidepane solution-site-header'
        'solution-site-sidepane solution-site-body';
    grid-template-columns: ${SOLUTION_SITE_INTERFACE_MENU_WIDTH}px 1fr;
`;

const SolutionSiteBannerLoading = styled.div`
    grid-area: solution-site-banner;
`;

interface Props {}

const SolutionSiteLoadingSkeleton: React.FC<Props> = ({}) => {
    const effectiveBreakpoint = useBreakpoint();
    return (
        <Wrapper>
            <LoadingGrid>
                <SolutionSiteBannerLoading>
                    <SolutionSiteInterfaceHeaderColorBanner $color="#676767" />
                    <NavigationTopBarLoading />
                </SolutionSiteBannerLoading>
                <SolutionSiteBuilderHeaderLoading />
                <SolutionSiteSidePaneLoading />

                <SolutionSitePageCanvasContent>
                    <SolutionSitePageWidgetsWrapperMockGrid>
                        <WidgetWidePlaceholder />

                        {effectiveBreakpoint < Breakpoint.MID_XSMALL_768 ? (
                            <WidgetWidePlaceholder />
                        ) : (
                            <>
                                <WidgetPlaceholder />
                                <WidgetPlaceholder />
                            </>
                        )}
                    </SolutionSitePageWidgetsWrapperMockGrid>
                </SolutionSitePageCanvasContent>
            </LoadingGrid>
        </Wrapper>
    );
};

export default SolutionSiteLoadingSkeleton;
