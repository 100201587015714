function ProductExpiredCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $state,
    projectManager,
    licenseManager,
    tonkeanService,
    authenticationService,
) {
    $scope.projectId = $stateParams.projectId;
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.licenseManager = licenseManager;

    console.log(`Expired page: ${$scope.projectId}`);

    projectManager.selectProject($scope.projectId).then(function () {
        if (projectManager.project && !projectManager.project.isExpired) {
            if (projectManager.isUserLicensed) {
                $state.go('product', { projectId: $scope.projectId }, { location: 'replace' });
            } else if (projectManager.isOwner && projectManager.isEnterprise) {
                licenseManager.goToBoard();
            }
        }
    });

    $scope.data = {};

    $scope.setLimitedState = function () {
        $scope.data.loading = true;

        licenseManager.setLimitedState().finally(() => {
            $state.go(
                'product.settings.license',
                { projectId: $scope.projectId, tab: 'members' },
                {
                    location: 'replace',
                    reload: true,
                },
            );
        });
    };
}
export default angular.module('tonkean.app').controller('ProductExpiredCtrl', ProductExpiredCtrl);
