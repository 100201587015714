import generateUniqueFormName from './generateUniqueFormName';
import {
    formBuilderConfigurationCreateFormInitialValue,
    formBuilderConfigurationUpdateFormInitialValue,
} from '../entities/formBuilderConfigurationInitialValue';

import type { FormManager } from '@tonkean/shared-services';
import type { FormDefinition, ProjectThemeConfiguration } from '@tonkean/tonkean-entities';
import { FormType } from '@tonkean/tonkean-entities';

/**
 * A function to get initial value for the state in form builder.
 *
 * @param formId the form id
 * @param formType the form type
 * @param formManager
 * @param workflowVersionId
 * @param projectThemeConfiguration project theme to use for default theme
 */
function getInitialForm(
    formId: string | undefined,
    formType: FormType,
    formManager: FormManager,
    workflowVersionId: string,
    projectThemeConfiguration: ProjectThemeConfiguration,
) {
    if (formId) {
        return undefined;
    }

    const forms = formManager.workflowVersionIdToFormIdToFormMap[workflowVersionId];
    const uniqueFormName = generateUniqueFormName(forms ? Object.values(forms) : [], []);

    // Theme is taken in the server but we set them here too in order to not have a flickering of values before we get the updated theme
    const themeDefaults: Partial<FormDefinition> = {
        primaryColor: '#34393e',
        secondaryColor: projectThemeConfiguration.primaryColor,
        buttonsColor: projectThemeConfiguration.primaryColor,
        logoUrl: projectThemeConfiguration.logoUrl,
    };

    const initialValues =
        formType === FormType.CREATE
            ? formBuilderConfigurationCreateFormInitialValue
            : formBuilderConfigurationUpdateFormInitialValue;
    return {
        ...initialValues,
        displayName: uniqueFormName,
        definition: {
            ...initialValues.definition,
            ...themeDefaults,
        },
    };
}

export default getInitialForm;
