import template from './tnkGallery.template.html?angularjs';
import { lateConstructController } from '@tonkean/angular-components';

export const tnkGallery = angular.module('tonkean.app').component('tnkGallery', {
    bindings: {
        images: '<',
    },
    template,
    controller: 'tnkGalleryCtrl',
});

/* @ngInject */
var tnkGalleryCtrl = function tnkGalleryCtrl($scope, $rootScope) {
    const ctrl = this;
    $scope.isMobile = $rootScope.isMobile;
    $scope.images = ctrl.images;
    $scope.data = {
        imgIndex: 0,
        arrowVisibility: {
            previous: false,
            next: true,
        },
    };

    $scope.moveToIndex = function (index) {
        $scope.data.imgIndex = index;
        updateArrowVisibility();
    };

    $scope.moveToIndex(0);

    $scope.nextImage = function () {
        // If we are not showing the last image, increment the index.
        if ($scope.data.imgIndex + 1 < $scope.images.length) {
            $scope.moveToIndex($scope.data.imgIndex + 1);
        } else {
            // We are now showing the last image - the next one is the first one.
            $scope.moveToIndex(0);
        }

        updateArrowVisibility();
    };
    $scope.previousImage = function () {
        if ($scope.data.imgIndex - 1 < 0) {
            $scope.moveToIndex(0);
        } else {
            $scope.moveToIndex($scope.data.imgIndex - 1);
        }
    };

    function updateArrowVisibility() {
        if ($scope.data.imgIndex === 0) {
            $scope.data.arrowVisibility.previous = false;
            $scope.data.arrowVisibility.next = true;
        } else if ($scope.data.imgIndex === $scope.images.length - 1) {
            $scope.data.arrowVisibility.previous = true;
            $scope.data.arrowVisibility.next = false;
        } else {
            $scope.data.arrowVisibility.previous = true;
            $scope.data.arrowVisibility.next = true;
        }
    }
};

export const tnkGalleryCtrlController = angular
    .module('tonkean.app')
    .controller('tnkGalleryCtrl', lateConstructController(tnkGalleryCtrl));
