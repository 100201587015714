function ProjectIntegrationManager() {
    const _this = this;

    const integrationToSearchByQuery = {
        jira: {
            searchByEntity: 'user',
            searchByFieldName: 'emailAddress',
        },
    };

    /**
     * This function gets the relevant parameters to filter the users by, in order to get those with no email address and map them.
     * We have to provide the integration type, e.g. 'jira' so we'll know which entities to filter and by which field key
     * @param integrationType
     * @returns {{}|*}
     */
    _this.getUnmappedUserFilterParamsByIntegrationType = function (integrationType) {
        if (Object.keys(integrationToSearchByQuery).includes(integrationType.toLowerCase())) {
            return integrationToSearchByQuery[integrationType];
        } else {
            return {};
        }
    };

    /**
     * Finds the given updated project integration in the given project.integrations and updates several of its properties.
     * Please note that it does not currently update all properties, and take in consideration that other properties
     * will need to be added to here if wanting them to be updated.
     * @param project The project object to update.
     * @param updatedProjectIntegration - the updated project integration to take the updated data from.
     */
    _this.updateCachedProjectIntegration = function (project, updatedProjectIntegration) {
        if (project.integrations && updatedProjectIntegration) {
            const projectIntegrationIndex = project.integrations?.findIndex(
                (projectIntegration) => projectIntegration.id === updatedProjectIntegration.id,
            );

            project.integrations[projectIntegrationIndex] = updatedProjectIntegration;
        }
    };
}

angular.module('tonkean.app').service('projectIntegrationManager', ProjectIntegrationManager);
