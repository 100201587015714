function GoogleSheetSelectorModalCtrl($scope, onProjectIntegrationCreated) {
    $scope.cancel = function () {
        $scope.$dismiss();
    };

    $scope.onGoogleSheetSelected = function (projectIntegration, fieldDefinitionsToCreate) {
        if (onProjectIntegrationCreated) {
            onProjectIntegrationCreated(projectIntegration, fieldDefinitionsToCreate);
            $scope.$dismiss();
        }
    };
}

export default angular.module('tonkean.app').controller('GoogleSheetSelectorModalCtrl', GoogleSheetSelectorModalCtrl);
