import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function GoogleSlidesCustomActionsDefinitionCtrl($scope, $filter, projectManager) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition,
        validationObject: ctrl.validationObject,
        loading: false,
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'CREATE_PRESENTATION_FROM_TEMPLATE':
                if (!$scope.data.definition.replacementActions) {
                    $scope.data.definition.replacementActions = [];
                }
                break;
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onTemplateIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewPresentationNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.newPresentationNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationFolderIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSlidesNumbersTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.slidesNumbersExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /* Replacement Actions */

    $scope.addReplacementAction = function () {
        $scope.data.definition.replacementActions.push({
            placeholderTextExpression: {},
            newTextExpression: {},
            isMatchCase: false,
        });
    };

    $scope.removeReplacementAction = function (index) {
        $scope.data.definition.replacementActions.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onMatchCaseToggled = function (index, value) {
        $scope.data.definition.replacementActions[index].isMatchCase = value;
        $scope.onDefinitionChanged(true);
    };

    $scope.onPlaceholderTextTonkeanExpressionChanged = function (index, expression, shouldSaveLogic) {
        $scope.data.definition.replacementActions[index].placeholderTextExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.oneNewTextTonkeanExpressionChanged = function (index, expression, shouldSaveLogic) {
        $scope.data.definition.replacementActions[index].newTextExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'GoogleSlidesCustomActionsDefinitionCtrl',
        lateConstructController(GoogleSlidesCustomActionsDefinitionCtrl),
    );
