import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EyeIcon } from '../../../../../images/icons/Invisble-Eye.svg';

import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 14px;
    color: ${Theme.colors.gray_300};
    font-size: ${FontSize.SMALL_12};
`;

const ExploreTrainingSetLink = styled(StateLink)`
    color: ${Theme.colors.primary};
    text-decoration: underline;
`;

const StyledEyeIcon = styled(EyeIcon)`
    margin-right: 5px;
`;

const OrText = styled.span`
    margin-left: 5px;
    margin-right: 5px;
    color: ${Theme.colors.gray_500};
`;

interface Props {
    trainingSetId: string | undefined;
    groupId: string;
}

const ExploreTrainingSet: React.FC<Props> = ({ trainingSetId, groupId }) => {
    const $state = useAngularService('$state');
    const projectManager = useAngularService('projectManager');

    return (
        <Wrapper>
            <StyledEyeIcon />
            {trainingSetId && (
                <>
                    <ExploreTrainingSetLink
                        state="product.trainingSetPage"
                        params={{
                            page: ProjectIntegrationPageMenuItemType.EXTRACTED_FIELDS,
                            enterpriseComponentId: trainingSetId,
                            fromState: $state.current.name,
                            fromStateParams: $state.params,
                            fromName: projectManager.groupsMap[groupId]?.name || 'Module Editor',
                        }}
                    >
                        Explore this Training Set
                    </ExploreTrainingSetLink>{' '}
                    <OrText>or</OrText>{' '}
                </>
            )}
            <ExploreTrainingSetLink state="product.enterpriseComponents" params={{ tab: 'training-sets' }}>
                Go to all Training Sets
            </ExploreTrainingSetLink>
        </Wrapper>
    );
};

export default ExploreTrainingSet;
