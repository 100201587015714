import React from 'react';

import type { EnterpriseComponentMenuItemPageContentProps } from '../../../entities/EnterpriseComponentMenuItemPageContentProps';
import EnterpriseComponentAuditLogMainPage from '../../AuditLogPage/components/EnterpriseComponentPageAuditLog/components/EnterpriseComponentAuditLogMainPage';
import AuditLogType from '../../AuditLogPage/entities/AuditLogType';

import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';

const EnterpriseComponentPageSystemLog = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponent,
    enterpriseComponentType,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    return (
        <EnterpriseComponentAuditLogMainPage
            enterpriseComponent={enterpriseComponent}
            enterpriseComponentType={enterpriseComponentType}
            logType={AuditLogType.SYSTEM}
            description="View recent entity collections and any related errors."
        />
    );
};

export default EnterpriseComponentPageSystemLog;
