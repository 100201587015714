import { useMemo } from 'react';

import { useTDLServerConnectity } from '../../tonkeanServerConnectivity';

import { NetworkService } from '@tonkean/network-service';

const useTonkeanNetworkService = () => {
    const accessToken = useTDLServerConnectity((state) => state.accessToken);
    const projectToken = useTDLServerConnectity((state) => state.projectToken);
    const tonkeanServerUrl = useTDLServerConnectity((state) => state.tonkeanServerUrl);

    return useMemo(() => {
        return new NetworkService({
            baseUrl: tonkeanServerUrl,
            authorizationToken: accessToken,
            projectToken,
        });
    }, [accessToken, projectToken, tonkeanServerUrl]);
};

export default useTonkeanNetworkService;
