<div class="show-edit-when-hover-visibility">
    <div class="flex-grow margin-left flex-self-start common-text-wrap">
        <!--<i ng-if="item.source" uib-tooltip="{{item.source.toLowerCase()}}"-->
        <!--class="integration-group-icon mod-work-item margin-right-xs flex-no-shrink mod-{{item.source.toLowerCase()}}"></i>-->

        <!--<div class="activity-comment-avatar avatar-main img-circle common-z-index-forward margin-none">-->
        <!--<img class="avatar-img img-circle" ng-src="{{item.actor && item.actor.avatarUri ? item.actor.avatarUri : 'images/avatar.png'}}">-->
        <!--</div>-->
        <div>
            <a ng-href="{{ item.externalUrl }}" target="_blank" class="common-color-dark-grey">
                {{ item.text.trim() }}
            </a>
        </div>
    </div>
</div>
