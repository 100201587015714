import { useAngularService } from 'angulareact';
import React from 'react';

import FieldChartWidgetWorkflowVersionFieldSelector from './FieldChartWidgetWorkflowVersionFieldSelector';
import type SolutionSiteFieldChartWidgetConfiguration from '../SolutionSiteFieldChartWidgetConfiguration';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { LoadingCircle } from '@tonkean/infrastructure';
import type { Group } from '@tonkean/tonkean-entities';

interface Props {
    configuration: SolutionSiteFieldChartWidgetConfiguration;
    selectedGroup: Group;
}

const SolutionSiteCategoricalGroupSelectedFieldSelector: React.FC<Props> = ({ configuration, selectedGroup }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const {
        data: workflowVersion,
        loading: loadingWorkflowVersion,
        error: workflowVersionError,
    } = useAsyncMethod(
        workflowVersionManager,
        'getCachedWorkflowVersionOrGetFromServer',
        selectedGroup.draftWorkflowVersionId,
    );

    if (loadingWorkflowVersion) {
        return <LoadingCircle centered />;
    }

    return (
        <>
            {workflowVersion && (
                <FieldChartWidgetWorkflowVersionFieldSelector
                    configuration={configuration}
                    workflowVersion={workflowVersion}
                    isSolutionSite
                />
            )}
        </>
    );
};
export default SolutionSiteCategoricalGroupSelectedFieldSelector;
