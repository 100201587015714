<div class="mark-modal common-close-btn-container">
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <div class="flex-vmiddle margin-bottom-lg">
        <div class="common-size-l common-bold flex-grow">Share {{ entityName || '' }}</div>
    </div>

    <div class="text-center" ng-show="sharing">
        <i class="loading-large"></i>

        <h3>Sending...</h3>
    </div>

    <form ng-hide="sharing" name="forms.shareFrom" ng-submit="submit()" novalidate>
        <div
            class="form-group flex"
            ng-if="pm.project.communicationIntegrations && pm.project.communicationIntegrations.length"
        >
            <span ng-click="data.shareToType = 'People'" class="margin-right pointer">
                <input
                    type="radio"
                    class="form-inline"
                    name="type"
                    ng-model="data.shareToType"
                    required
                    value="People"
                />
                People
            </span>
            <span ng-click="data.shareToType = 'Channel'" class="pointer">
                <input
                    type="radio"
                    class="form-inline"
                    name="type"
                    ng-model="data.shareToType"
                    required
                    value="Channel"
                />
                Channel
            </span>
        </div>

        <ui-select
            ng-if="data.shareToType === 'Channel'"
            class="flex-grow"
            theme="bootstrap"
            ng-model="data.shareToChannel"
            ng-disabled="loadingCommunicationChannels"
            on-select="selectPublicChannel($item)"
        >
            <ui-select-match placeholder="Choose a channel...">{{ $select.selected.displayName }}</ui-select-match>
            <ui-select-choices repeat="channel in suggestedCommunicationChannels | filter:$select.search">
                <div ng-bind-html="channel.displayName | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>

        <tnk-deprecated-people-selector
            ng-if="data.shareToType === 'People'"
            selected-people="selectedPeople"
            no-custom="false"
            max-tags="10"
            exclude-me="true"
            no-validation="false"
        ></tnk-deprecated-people-selector>

        <uib-alert
            type="danger"
            class="margin-normal-sm-v common-size-xxs"
            ng-show="sharingError"
            close="sharingError=null"
        >
            <div>{{ sharingError | error }}</div>
        </uib-alert>

        <div class="margin-top flex-grow relative message-text-area">
            <label for="message-{{ entityId }}" class="sr-only">Message</label>
            <textarea
                class="form-control common-no-outline absolute-fill-view"
                rows="4"
                id="message-{{ entityId }}"
                placeholder="Write your message..."
                maxlength="450"
                ng-disabled="sharing"
                ng-required="required"
                ng-model="data.shareMessageText"
            ></textarea>
        </div>
        <div
            class="common-color-warning flex common-size-xxxxs margin-top"
            ng-if="data.shareToType === 'People' && selectedPeople.length"
        >
            <div class="margin-right"><i class="fa fa-exclamation-triangle"></i></div>
            <div>
                <span>
                    NOTE: By sharing this with specific people, you also invite & grant them access to
                    <strong>{{ pm.project.name }}</strong>
                    .
                </span>
                <span ng-if="pm.groupsMap[entityId] && pm.groupsMap[entityId].visibilityType === 'PRIVATE'">
                    <br />
                    Since this is a private list, they will be added as collaborators to this list.
                </span>
            </div>
        </div>
        <div class="mark-modal-divider"></div>
        <div class="clearfix">
            <button type="submit" class="btn btn-primary mark-modal-btn">Share</button>
            <button class="btn btn-default mark-modal-btn" ng-click="$dismiss()">Cancel</button>
            <span class="text-danger" ng-if="error">{{ error | error }}</span>
        </div>
    </form>
</div>
