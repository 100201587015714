<div class="import-tracks-modal relative common-close-btn-container">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Title -->
    <div class="common-title-inner mod-no-top">Add New Data Source</div>

    <hr />

    <!-- Search Input -->
    <form
        class="padding-left padding-right flex-vmiddle margin-bottom-lg"
        ng-hide="onlyFilter && onlyFilter.length"
        autocomplete="off"
    >
        <span class="margin-right-xs svg-icon-sm">
            <tnk-icon src="/images/icons/search.svg"></tnk-icon>
        </span>
        <input
            type="search"
            class="form-control inline-block common-input-only-line common-width-50-no-xs"
            placeholder="Search data source"
            data-automation="add-integration-modal-search-integration"
            ng-model="data.searchIntegrationNameText"
            autofocus
        />
    </form>

    <div class="add-integration-list">
        <span ng-if="!data.loadingWebhooksSources && !data.loadingMarketplaceSources">
            <!-- Marketplace Data Sources -->
            <tnk-marketplace-data-source-tile
                marketplace-item-summary="marketplaceSource"
                ng-if="!onlyFilter || !onlyFilter.length"
                ng-repeat="marketplaceSource in filteredMarketplaceSources = (data.marketplaceSources | filter:data.searchIntegrationNameText | limitTo:data.marketplaceSourcesLimit)"
                on-change="(closeModal)"
                on-cancel="(onIntegrationCanceled)"
                data-automation="integration-group-marketplace-source-option"
            ></tnk-marketplace-data-source-tile>

            <!-- Data Sources -->
            <tnk-integration-group
                sources="[source.name]"
                ng-repeat="source in filteredSources = (data.sources | filter:searchSourcesFilter | filter:filterIntegrationsToHide ) | orderBy: 'isDisabled'"
                ng-class="{ 'connected-data-source': source.isConnected }"
                ng-model="ignored"
                display-mode="button"
                state="data.integrationState"
                open-modal-for-configuration="true"
                display-new-prefix="false"
                current-integration="data.currentIntegration"
                integration-changed="onClose()"
                integration-configuration-canceled="onIntegrationCanceled()"
                configured-in-group-id="groupId"
                configured-in-workflow-version-id="workflowVersionId"
                ng-disabled-tooltip=" {{ source.isDisabled ? source.disabledMessage : undefined }}"
                ng-disabled="source.isDisabled"
            ></tnk-integration-group>

            <!-- Webhooks -->
            <tnk-integration-group
                sources="['webhook']"
                ng-if="!onlyFilter || !onlyFilter.length"
                ng-repeat="webhookSource in filteredWebhookSources = (data.webhooksSources | filter:data.searchIntegrationNameText | limitTo:data.webhookSourcesLimit)"
                ng-model="ignored"
                display-mode="button"
                webhook-source="webhookSource"
                state="data.integrationState"
                open-modal-for-configuration="true"
                display-new-prefix="false"
                current-integration="data.currentIntegration"
                integration-changed="onClose()"
                integration-configuration-canceled="onIntegrationCanceled()"
                configured-in-group-id="groupId"
                configured-in-workflow-version-id="workflowVersionId"
                ng-disabled-tooltip=" {{ getNgDisabledTooltipTextOfDisabledWebhook() }}"
                ng-disabled="data.isWebhookCreationDisabled"
            ></tnk-integration-group>

            <!-- Custom Data Source -->
            <tnk-integration-group
                sources="['webhook']"
                ng-if="ifCustomDataSourceDisplayed()"
                ng-model="ignored"
                display-mode="button"
                state="data.integrationState"
                open-modal-for-configuration="true"
                display-new-prefix="false"
                current-integration="data.currentIntegration"
                integration-changed="onClose()"
                integration-configuration-canceled="onIntegrationCanceled()"
                configured-in-group-id="groupId"
                configured-in-workflow-version-id="workflowVersionId"
                ng-disabled-tooltip=" {{ getNgDisabledTooltipTextOfDisabledWebhook() }}"
                ng-disabled="data.isWebhookCreationDisabled"
            ></tnk-integration-group>
        </span>

        <!-- Still loading webhooks -->
        <div
            ng-if="data.loadingWebhooksSources || data.loadingMarketplaceSources"
            class="margin-top margin-bottom common-size-xxs flex mod-align-center mod-center"
        >
            Loading custom data sources
            <i class="loading-small margin-normal-sm-h" data-automation="loading-circle"></i>
        </div>

        <!-- Load More -->
        <div
            ng-if="
                !data.loadingWebhooksSources &&
                !data.loadingMarketplaceSources &&
                (data.webhookSourcesLimit < data.webhooksSources.length ||
                    data.marketplaceSourceslimit < data.marketplaceSources.length) &&
                (!data.searchIntegrationNameText || !data.searchIntegrationNameText.length)
            "
            class="margin-top margin-bottom common-size-xxs"
        >
            <a ng-click="loadMore()">Load more...</a>
        </div>
    </div>

    <!-- Loading Save -->
    <div class="text-center" ng-show="posting">
        <i class="loading-large"></i>

        <h3>Saving...</h3>
    </div>
</div>
