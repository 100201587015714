<div class="worker-target-storage-selection common-border mod-radius common-bg mod-grey5 padding-normal-sm">
    <!-- Target Storage selection title -->
    <div class="margin-bottom-md flex-vmiddle mod-justify-space">
        <!-- Title -->
        <div class="common-size-xxs common-bold">
            Target Storage Provider
            <i
                ng-if="data.infoMessage"
                class="fa fa-info-circle common-color-primary margin-left-xs"
                uib-tooltip="{{ data.infoMessage }}"
            ></i>
        </div>

        <!-- Delete icon -->
        <button
            ng-if="data.shouldDisplayDeleteSign"
            class="svg-icon-xs btn btn-no-style common-color-light-grey"
            ng-click="deleteSignClicked()"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </button>
    </div>

    <!-- Data Storage Configuration -->
    <div>
        <!-- Data Storage Selection -->
        <div class="margin-bottom-xs common-bold common-size-xxxs">Data Storage:</div>

        <!-- Storage provider -->
        <tnk-project-integration-selector
            selected-project-integration-id="{{ data.storageIntegrationId }}"
            filter-on-integration-type="data.storageIntegrationTypes"
            selector-label-placeholder="Select data storage"
            allow-default-option="false"
            on-project-integration-selected="onProjectIntegrationSelected(selectedProjectIntegration)"
        ></tnk-project-integration-selector>
    </div>

    <!-- New Document Name -->
    <div class="margin-top">
        <div class="margin-bottom-xs">
            New Document Name:
            <i
                class="fa fa-info-circle common-color-primary margin-left-xs"
                uib-tooltip="If empty, the original name will be used."
            ></i>
        </div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.customTriggerId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Document name..."
            saved-original-expression="data.newDocumentName.originalExpression"
            saved-evaluated-expression="data.newDocumentName.evaluatedExpression"
            on-tonkean-expression-changed="
                onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>
</div>
