import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    gap: 16px;

    &:not(:last-child) {
        border-bottom: 1px solid ${Theme.colors.gray_300};
    }
`;

export default Section;
