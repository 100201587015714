import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import { GoogleAuthenticationComponent } from '../components';

import { DataSourcesSectionType } from '@tonkean/tonkean-entities';
import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class GoogleSheetsIntegration extends IntegrationDefinitionBase {
    override name = 'googlesheets';
    override displayName = 'Google Sheets';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override showEntitiesInboundConfiguration = false;
    override entities = [new IntegrationEntity('Row', 'Rows')];

    override customizedAuthenticationComponent = GoogleAuthenticationComponent;

    override shouldRemoveTheDefaultSyncDateTimeFilter = true;
    override dataSourceSectionType = DataSourcesSectionType.SPREADSHEET;

    override dataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Row: true },
        isOnlyDataRetention: { Row: true },
    };

    override supportsSharedCredentials = true;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon('', '130px'),
        new IntegrationIcon('', '130px'),
    );
}
