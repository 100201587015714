import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class WeekNumFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.WEEK_NUM;
    override readonly sign = 'WeekNum';
    override readonly displayName = 'Week Number';
    override readonly description = 'Will get the week number.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'First Week day',
            explanation: 'The first day of the week. 1 = Sunday, 2 = Monday...',
            dataType: FieldType.Number,
            defaultValue: 2,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Min Days In First Week',
            explanation: 'How many minimal days required in the first week of the year.',
            dataType: FieldType.Number,
            defaultValue: 1,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        timeZoneField(),
    ];
}
