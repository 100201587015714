import { useMemo } from 'react';

import type { InnerItemWidgetConfiguration } from '../../entities';

import type { FieldDefinitionKey, WidgetBase } from '@tonkean/tonkean-entities';

interface UseFieldDefinitionKeyToSettingsResult {
    fieldDefinitionKeyToLabel: Record<FieldDefinitionKey, string | undefined>;
    fieldDefinitionKeyToWidth: Record<FieldDefinitionKey, number | undefined>;
}

const useFieldDefinitionKeyToSettings = (
    widget: WidgetBase<InnerItemWidgetConfiguration>,
): UseFieldDefinitionKeyToSettingsResult => {
    return useMemo(() => {
        const fieldDefinitionKeyToLabel: Record<FieldDefinitionKey, string | undefined> = {};
        const fieldDefinitionKeyToWidth: Record<FieldDefinitionKey, number | undefined> = {};

        Object.entries(widget.configuration.fields).forEach(([key, { label, width }]) => {
            fieldDefinitionKeyToLabel[key] = label;
            fieldDefinitionKeyToWidth[key] = width;
        });

        return { fieldDefinitionKeyToLabel, fieldDefinitionKeyToWidth };
    }, [widget.configuration.fields]);
};

export default useFieldDefinitionKeyToSettings;
