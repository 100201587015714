<div class="common-close-btn-container">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>
    <div class="field-view-modal padding-normal">
        <div class="common-title-inner mod-no-top flex-vmiddle">
            <div class="flex-grow">Upload {{ data.incomingWebhook.displayName }} Initial Data</div>
            <div class="common-size-xxxxs margin-right-xlg">
                <strong>Help?</strong>
                <a
                    ng-href="{{::$root.knowledgeBase.pages.webhooks}}"
                    target="_blank"
                    class="common-link-no-color common-underline"
                >
                    See Docs
                </a>
                or
                <a onclick="Intercom('showNewMessage');" class="common-link-no-color common-underline">Contact us</a>
            </div>
        </div>
        <hr />
        <div
            ng-show="!data.table || data.table.length < 2"
            class="import-webhook-data-modal-content text-center padding-normal"
        >
            <p class="common-size-xs">
                Export your data from "{{ data.incomingWebhook.displayName }}" to a csv file and upload it here:
            </p>
            <div class="text-center common-size-xxxs">
                <a
                    ng-href="{{::$root.knowledgeBase.pages.customDataSourceWithCsvUpload}}"
                    target="_blank"
                >
                    How to export {{ data.incomingWebhook.displayName }} data to a CSV file
                    <i class="fa fa-external-link margin-left-xs"></i>
                </a>
            </div>
            <!--<div>-->
            <!--Select a CSV file: <input type="file" id="fileInput" class="margin-top-lg"-->
            <!--custom-on-change="onFileChange"-->
            <!--accept=".csv, text/csv"/></div>-->
            <label class="btn btn-primary btn-lg inline-block margin-top-xlg margin-bottom btn-text-left">
                <span class="flex-vmiddle">
                    <input
                        class="hidden"
                        type="file"
                        id="fileInput"
                        custom-on-change="onFileChange"
                        accept=".csv, text/csv"
                    />
                    <i class="fa fa-upload margin-right-xs"></i>
                    Upload CSV
                </span>
            </label>
            <div
                class="alert alert-warning common-size-xxxxs margin-top-lg"
                ng-if="data.table && data.table.length < 2"
            >
                This file is empty. It needs to have at least two rows (first row for the column headers and at least
                one row of values).
            </div>
        </div>

        <div class="margin-top" ng-if="data.table && data.table.length > 1" ng-hide="loading">
            <div ng-hide="data.stage">
                <strong>Found {{ data.table.length - 1 }} items, with {{ data.table[0].length }} columns</strong>
                (
                <a ng-click="clearFile()">Change file</a>
                )
                <hr />
            </div>
            <!-- when there is no metadata yet-->
            <div ng-if="data.stage === 'initial'">
                <p>
                    <strong>Set up the basic mapping for those items:</strong>
                </p>
                <div class="flex-vmiddle margin-bottom text-right">
                    <div class="margin-right common-width-25">Item Title:</div>
                    <div class="flex-grow relative" ng-click="openFieldInspectModal('selectedTitleColumn')">
                        <ui-select
                            class="relative field-selector-ui-select"
                            theme="bootstrap"
                            on-select="
                                !data.selectedIdColumn ? (data.selectedIdColumn = data.selectedTitleColumn) : null
                            "
                            ng-model="data.selectedTitleColumn"
                        >
                            <ui-select-match placeholder="{{ data.placeHolder || 'Select a column' }}">
                                <span ng-class="{ 'common-color-grey': !data.selectedTitleColumn }">
                                    {{ data.selectedTitleColumn || 'Select a column' }}
                                </span>
                            </ui-select-match>
                            <ui-select-choices
                                group-by="'{{ data.groupBy }}'"
                                repeat="p in (data.table[0] | filter:$select.search) track by $index"
                            >
                                <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                        <!-- Field inspector button. -->
                        <span class="svg-icon-nav-sm field-selector-inspect-icon pointer">
                            <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                        </span>
                    </div>
                </div>

                <div class="flex-vmiddle text-right">
                    <div class="margin-right common-width-25">
                        <div>Unique Identifier:</div>
                        <div class="common-size-xxxxxs common-color-grey">Can be the same as Title</div>
                    </div>
                    <div class="flex-grow relative" ng-click="openFieldInspectModal('selectedIdColumn')">
                        <ui-select
                            class="relative field-selector-ui-select"
                            theme="bootstrap"
                            ng-model="data.selectedIdColumn"
                        >
                            <ui-select-match placeholder="{{ data.placeHolder || 'Select a column' }}">
                                <span ng-class="{ 'common-color-grey': !data.selectedIdColumn }">
                                    {{ data.selectedIdColumn || 'Select a column' }}
                                </span>
                            </ui-select-match>
                            <ui-select-choices
                                group-by="'{{ data.groupBy }}'"
                                repeat="p in (data.table[0] | filter:$select.search) track by $index"
                            >
                                <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                        <!-- Field inspector button. -->
                        <span class="svg-icon-nav-sm field-selector-inspect-icon pointer">
                            <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                        </span>
                    </div>
                </div>
                <hr />
                <div class="flex-grow common-color-danger">{{ error }}</div>
                <div class="flex-vmiddle">
                    <div class="flex-grow"></div>
                    <div ng-show="data.initialSetupLoading" class="margin-right"><i class="loading-small"></i></div>
                    <div>
                        <button
                            type="submit"
                            class="btn btn-primary margin-right"
                            data-ng-disabled="!data.selectedIdColumn || !data.selectedTitleColumn || data.initialSetupLoading"
                            ng-click="initialImport()"
                        >
                            Import
                        </button>
                    </div>
                </div>
            </div>

            <!-- Object mapping -->
            <form name="importTableForm" ng-if="!data.stage || data.stage === 'setup'">
                <h4 class="margin-top-xs margin-bottom">Object mapping:</h4>
                <p class="common-size-xxxxs">
                    Choose the columns from your CSV to map into the objects of "{{
                        data.incomingWebhook.displayName
                    }}".
                    <br />
                    Note:
                    <strong>{{ data.incomingWebhook.definition.idFieldPath.label }}</strong>
                    and
                    <strong>{{ data.incomingWebhook.definition.titleFieldPath.label }}</strong>
                    must be selected.
                </p>

                <div class="import-webhook-mapping-box">
                    <!--headers-->
                    <div class="flex-vmiddle common-bold common-border-bottom common-bg mod-light-grey">
                        <div class="flex-basis-40 padding-normal-sm common-border-right common-color-dark-grey">
                            CSV Column
                        </div>
                        <div class="flex-grow padding-normal-sm common-color-dark-grey">Object Field</div>
                    </div>

                    <!--mapping-->
                    <div
                        class="flex-vmiddle common-border-bottom"
                        ng-repeat="col in data.table[0]"
                        ng-class="{
                            'common-bg mod-blue-light':
                                data.mapping['Column-' + $index] && data.mapping['Column-' + $index].name
                        }"
                    >
                        <div class="flex-basis-40 flex-no-shrink padding-normal-sm common-border-right">
                            <div>
                                {{ col }}
                                <i
                                    class="margin-left fa fa-check-circle"
                                    ng-if="data.mapping['Column-' + $index] && data.mapping['Column-' + $index].name"
                                ></i>
                            </div>
                            <div class="common-size-xxxxxs common-color-grey common-italic">
                                EX: {{ data.table[1][$index] }}
                            </div>
                        </div>
                        <div
                            class="flex-grow padding-right"
                            ng-init="colId = 'Column-' + $index; selectedColField = { name: col, label: col }"
                        >
                            <tnk-field-selector
                                selected-field="selectedColField"
                                selected-field-identifier="colId"
                                items-source="EXTERNAL"
                                project-integration="data.projectIntegration"
                                external-type="data.projectIntegration.integration.integrationUniqueType"
                                on-field-selected="onFieldOptionSelected(selectedField, selectedFieldIdentifier)"
                                allow-custom-input="true"
                                allow-clear-selection="true"
                                place-holder="Select field"
                                clear-selected-if-no-match-on-options="true"
                                field-inspect-modal="true"
                                auto-match-on-options="true"
                            ></tnk-field-selector>
                        </div>
                    </div>
                </div>

                <hr />
                <div class="flex-vmiddle">
                    <div class="flex-grow common-color-danger">{{ error }}</div>
                    <div>
                        <button type="submit" class="btn btn-primary margin-right" ng-click="import()">Import</button>
                    </div>
                </div>
            </form>
            <div ng-if="data.stage === 'importing'" class="text-center">
                <div>
                    <i class="loading"></i>
                    <span class="margin-left-xs">{{ data.state || 'Importing' }}</span>
                </div>
                <div class="progress">
                    <div
                        class="progress-bar"
                        ng-if="data.progress > 0"
                        role="progressbar"
                        aria-valuenow="{{ data.progress }}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        ng-style="{ width: data.progress + '%' }"
                    >
                        {{ data.progress }}%
                    </div>
                </div>
            </div>
            <div ng-if="data.stage === 'completed'" class="text-center">
                <h3 class="common-title-md mod-light">Import Completed</h3>

                <div ng-if="failedTracks && failedTracks.length">
                    <p>Unfortunately, the following items could not be imported:</p>
                    <ul>
                        <li ng-repeat="row in failedTracks">{{ row.join(', ') }}</li>
                    </ul>
                </div>
                <hr />
                <button class="btn btn-primary" ng-click="$close()">Complete</button>
            </div>
        </div>
        <div class="text-center" ng-if="loading">
            <i class="loading"></i>
        </div>
    </div>
</div>
