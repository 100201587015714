<div ng-show="!data.duplicateOrCreateMode && !data.showSmartsheetFieldSetup" class="clearfix">
    <div class="common-bold padding-bottom">
        Live data settings:
        <a ng-click="toggleShowSmartsheetFieldSetup()">Change</a>
    </div>
    <div class="common-size-xxxxs common-color-grey pull-right text-right">
        {{ data.sheet.displayName }}
        <br />
        {{ data.sheet.description }}
    </div>
    <div>
        Filter: {{ data.filter.displayName }}
        <br />
        Column: "{{ data.column.displayName }}"
    </div>
</div>
<div ng-if="data.duplicateOrCreateMode || data.showSmartsheetFieldSetup" ng-switch="data.smartsheetStep">
    <!--Views setup-->
    <div ng-switch-when="selectSheet">
        <div class="flex-vmiddle">
            <div class="flex-grow"><b>Select a Sheet:</b></div>
            <div>
                <input class="form-control common-size-xxxxs" placeholder="Search..." ng-model="data.filterByText" />
            </div>
        </div>
        <div class="integration-instance-selection-box margin-top">
            <div ng-if="loadingExternal" class="text-center margin-top-lg"><i class="loading-medium"></i></div>
            <div
                ng-click="onSheetSelected(selection)"
                ng-repeat="selection in (data.availableSheets | filter:{displayName: data.filterByText})"
                class="list-item btn-default pointer common-size-xxs"
            >
                <strong>{{ selection.displayName }}</strong>
                <div class="common-size-xxxxs common-color-grey">
                    <span>{{ selection.description }}</span>
                </div>
            </div>
        </div>
    </div>
    <!--Metrics and dimensions setup-->
    <div ng-switch-when="selectFields">
        Selected View:
        <b>{{ data.sheet.displayName }}</b>
        -
        <a ng-click="loadExternalSheets(true); data.smartsheetStep= 'selectSheet';">Change sheet</a>
        <hr />
        <div class="flex-vmiddle margin-top flex-xs-wrap">
            <div class="flex-grow margin-bottom-xs">
                <div class="common-size-xxs margin-bottom flex-vmiddle">
                    <span>Value (Column):</span>
                    <i class="loading-small create-field-selection-loading margin-left" ng-if="loadingExternal"></i>
                </div>
                <ui-select
                    required
                    theme="bootstrap"
                    ng-model="data.column"
                    ng-change="onColumnSelected()"
                    ng-disabled="!data.availableColumns"
                >
                    <ui-select-match placeholder="Select a column">{{ $select.selected.displayName }}</ui-select-match>
                    <ui-select-choices repeat="p in data.availableColumns | filter:$select.search">
                        <div ng-bind-html="p.displayName | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>
        <div class="flex-grow margin-bottom-xs margin-top" ng-if="data.targetType !== 'GLOBAL'">
            <div class="common-size-xxs margin-bottom flex-vmiddle">
                <span>Match Item title to column:</span>
                <i
                    class="fa fa-info-circle margin-left common-color-grey"
                    uib-tooltip="The column in SmartSheets that will contain your track name (for ex: Customer name, Initiative name, Campaign name etc)"
                ></i>
                <i class="loading-small create-field-selection-loading margin-left" ng-if="loadingExternal"></i>
            </div>
            <ui-select
                required
                theme="bootstrap"
                ng-model="data.filter"
                ng-change="onFilterSelected()"
                ng-disabled="!data.availableColumns"
            >
                <ui-select-match placeholder="Select a filter column">
                    {{ $select.selected.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="p in data.availableColumns | filter:$select.search">
                    <div ng-bind-html="p.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div class="flex-grow margin-bottom-xs margin-top" ng-if="data.targetType === 'GLOBAL'">
            <div class="common-size-xxs margin-bottom">
                Choose a row number:
                <i
                    class="fa fa-info-circle margin-left common-color-grey"
                    uib-tooltip="The row in SmartSheets that will contain the value"
                ></i>
            </div>

            <input
                type="number"
                pattern="\d*"
                min="1"
                class="form-control"
                id="rowNumber"
                ng-model="data.rowNumber"
                ng-change="onRowNumberChanged()"
                required
            />
        </div>
    </div>
</div>

<hr />
