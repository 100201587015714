import { STATUSES } from '@tonkean/constants';
/**
 * Created by ShaLi on 9/24/15.
 */

function ActivityItemCtrl($scope, $rootScope, $state, tonkeanService, modal) {
    $scope.pm = $rootScope.pm;
    $scope.cfm = $rootScope.cfm;
    $scope.as = $rootScope.as;
    $scope.modal = modal;

    $scope.statuses = STATUSES;

    $scope.data = {
        commentsLimit: 3,
    };

    $scope.types = {
        question: {
            icon: 'fa-question-circle',
            handleClick(item) {
                $state.go('question', {
                    projectId: $scope.pm.project.id,
                    inquiry: item.reference1.id,
                });
            },
        },
        PROBLEM_STATUS_CHANGED: {
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference1.id,
                });
            },
        },
        NEW_PROBLEM: {
            icon: 'fa-exclamation-circle',
            hideIfMe: true,
            shouldShowContent(item) {
                return item.reference1.id !== item.target.id;
            },
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference1.id,
                });
            },
        },
        NEW_POSITIVE_PROBLEM: {
            icon: 'fa-thumbs-o-up',
            hideIfMe: true,
            shouldShowContent(item) {
                return item.reference1.id !== item.target.id;
            },
            handleClick(item) {
                $state.go('product.quests.details', {
                    projectId: item.reference1.project.id,
                    inquiryId: item.reference1.inquiry.id,
                });
            },
        },
        INSIGHT_PLUS_ONE: {
            icon: 'fa-thumbs-o-up',
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference1.problem.id,
                });
            },
        },
        INSIGHT_INDICATION: {
            icon: 'fa-thumbs-o-up',
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference1.problem.id,
                });
            },
        },
        PROBLEM_INDICATION: {
            icon: 'fa-exclamation-triangle',
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference2.problem.id,
                });
            },
        },
        NEW_INSIGHT: {
            icon: 'fa-check-circle',
            handleClick(item) {
                $state.go('product.analyze.problems.details', {
                    projectId: $scope.pm.project.id,
                    problemId: item.reference1.problem.id,
                });
            },
        },
        PROJECT_READY: {
            icon: 'fa-flag-checkered',
            title: 'Team preparation completed',
        },
        NEW_COMMENT: {
            allowComments: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference2.id);
            },
        },
        NEW_ACTIVITY_COMMENT: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference3.id);
            },
        },
        INITIATIVE_CREATED: {
            shouldShowContent: () => false,
        },
        INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED: {
            allowComments: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
            isLightMode(item) {
                return !item.metadata || (!item.metadata.newUpdateText && !item.metadata.newEta);
            },
            shouldShowContent(item) {
                return item.metadata && (item.metadata.newUpdateText || item.metadata.newEta);
            },
        },
        INITIATIVE_FUNCTION_ADDED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_FUNCTION_REMOVED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_DESCRIPTION_CHANGED: {
            lightMode: true,
            shouldShowContent() {
                return false;
            },
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_TAGS_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_LEADER_CHANGED: {
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        NEW_INITIATIVE: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_TITLE_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_DUE_DATE_CHANGED: {
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_STATUS_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_FUNCTION_OWNER_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_MANUAL_FIELD_VALUE_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        INITIATIVE_FIELD_RANGE_CHANGED: {
            lightMode: true,
            handleClick(item) {
                modal.openViewInitiative(item.reference1.id);
            },
        },
        BOT_GATHER: {
            customIconTemplate: true,
            shouldShowContent: () => false,
        },
        BOT_WORKER_RUN: {
            customIconTemplate: true,
            shouldShowContent: () => false,
        },
        COMMIT: {
            lightMode: true,
        },
        ISSUE: {
            lightMode: false,
        },
        EMPTY: {
            customIconTemplate: true,
        },
    };

    $scope.isAggregated = $scope.item.aggregatedItems && $scope.item.aggregatedItems.length;
    $scope.aggregationCount = $scope.item.aggregatedItems ? $scope.item.aggregatedItems.length + 1 : 1;

    $scope.deleteItem = function (id, hideOnSuccess, item) {
        if (!$scope.deleting) {
            $scope.deleting = true;

            $scope.questionConfirmModalData = {
                title: 'Delete',
                body: 'Are you sure you want to delete this?',
                okLabel: 'Delete',
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // ok
                    tonkeanService
                        .deleteItem(id)
                        .then(function () {
                            $scope.hideItem = hideOnSuccess;
                            if (item) {
                                item.deleted = true;
                            }
                            if ($scope.onDelete) {
                                $scope.onDelete();
                            }
                        })
                        .catch(function (error) {
                            $scope.deleteError = error;
                        })
                        .finally(function () {
                            $scope.deleting = false;
                        });
                })
                .catch(function () {
                    // cancel
                    $scope.deleting = false;
                });
        }
    };

    $scope.handleClick = function (item) {
        if ($scope.notification && item && $scope.types[item.type] && $scope.types[item.type].handleClick) {
            $scope.types[item.type].handleClick(item);

            if ($scope.onClick) {
                $scope.onClick(item);
            }
        }
    };

    $scope.addCommentToItem = function (comment) {
        if (comment) {
            if (!$scope.item.comments) {
                $scope.item.comments = [];
            }

            $scope.item.comments.unshift(comment);
        }
    };
}
angular.module('tonkean.app').controller('ActivityItemCtrl', ActivityItemCtrl);
