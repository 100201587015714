import GoogleAuthenticationComponent from './GoogleAuthenticationComponent';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkGoogleAuthentication',
        reactToAngular(GoogleAuthenticationComponent, [
            'authenticate',
            'googleIntegrationType',
            'initialGoogleAuthenticationType',
            'initialOauthPermissionType',
            'disableOtherGoogleAuthenticationTypes',
        ]),
    );
