<form name="onboardingForm" novalidate>
    <div class="onboarding-modal clearfix flex flex-col" ng-class="{ 'mod-start monkey-bg': !data.selectedUsecase }">
        <div class="onboarding-start-question text-center" ng-if="!data.selectedUsecase">
            <p>Welcome {{as.currentUser.name || ''}}! Let's get you quickly set up.</p>

            <br />
            <h4 class="margin-normal-v">What do you want to track?</h4>

            <div class="margin-normal-v">
                <button
                    ng-repeat="(id, usecase) in data.usecases"
                    class="btn btn-primary btn-lg btn-block"
                    ng-click="data.selectedUsecase = id"
                >
                    {{ usecase.btn }}
                </button>
            </div>
            <a class="flex-no-shrink common-color-grey common-size-xxxxxs" ng-click="close(false)">
                I'll do it manually →
            </a>
        </div>

        <div ng-if="data.selectedUsecase">
            <div
                class="onboarding-close-btn margin-normal-sm pointer padding-normal-sm common-color-grey"
                ng-click="close(false)"
            >
                <i class="fa fa-close"></i>
            </div>
            <div class="text-center margin-top-lg">
                <h4>Let's get those {{ data.usecases[data.selectedUsecase].caption }}!</h4>
                <span ng-if="creating" class="loading-small margin-left"></span>
            </div>
            <div class="clearfix common-size-xxs onboarding-tips">
                <div
                    class="onboarding-tips-btn"
                    analytics-category="Onboarding"
                    analytics-label="Import"
                    analytics-event="Start path"
                    ng-click="import()"
                >
                    <!--<img src="/images/onBoarding/start-samples.png" alt="Pre built examples"/>-->

                    <div class="onboarding-tips-btn-image mod-border padding-normal">
                        <!--<br/>-->
                        <div class="margin-normal-sm">
                            <img
                                src="/images/onBoarding/import-icon-salesforce.png"
                                class="inline-block margin-bottom-xs margin-right-xs"
                                alt="Import from Salesforce"
                                style="height: 29px"
                            />
                            <img
                                src="/images/onBoarding/import-icon-excel.png"
                                class="inline-block margin-bottom-xs margin-right"
                                alt="Import from excel"
                                style="height: 29px"
                            />
                            <img
                                src="/images/onBoarding/import-icon-notes.png"
                                alt="Import from notes"
                                class="inline-block margin-bottom-xs"
                                style="height: 29px"
                            />
                        </div>
                        <div class="margin-normal-sm">
                            <img
                                src="/images/onBoarding/import-icon-jira.png"
                                class="inline-block margin-bottom-xs margin-right"
                                alt="Import from JIRA"
                                style="height: 17px"
                            />
                            <img
                                src="/images/onBoarding/import-icon-trello.png"
                                alt="Import from Trello"
                                class="inline-block"
                                style="height: 17px"
                            />
                        </div>
                    </div>
                    <!--<img class="onboarding-tips-btn-image" src="/images/onBoarding/start-blank.png" alt="Blank slate"/>-->

                    <div class="onboarding-tips-btn-title">
                        Import {{ data.usecases[data.selectedUsecase].caption }}
                    </div>
                    <p class="common-size-xxxxs">Connect to your existing tools</p>
                </div>
                <div
                    class="onboarding-tips-btn"
                    analytics-category="Onboarding"
                    analytics-label="Blank slate"
                    analytics-event="Start path"
                    ng-click="createSampleTracks()"
                >
                    <div class="onboarding-tips-btn-image mod-samples">
                        <img src="/images/onBoarding/start-samples2.png" alt="Blank slate" />
                    </div>

                    <div class="onboarding-tips-btn-title">Enter manually</div>
                    <p class="common-size-xxxxs">Start with few examples</p>
                </div>
            </div>
        </div>
    </div>
</form>
