import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SmartsheetWorkspaceCustomActionDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        onDefinitionChanged: ctrl.onDefinitionChanged,
        configuredLogic: ctrl.configuredLogic,
        loading: true,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = false;
            }
        }
        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.destinationSheetId = originalExpression;
        $scope.data.definition.evaluatedDestinationSheetId = evaluatedExpression;
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'SmartsheetWorkspaceCustomActionDefinitionCtrl',
        lateConstructController(SmartsheetWorkspaceCustomActionDefinitionCtrl),
    );
