import Utils from '@tonkean/utils';

/**
 * Gets the variables that are used in a formula expression.
 * If given a variableIdToVariableMap, will use the map to return variable objects from the variable ids.
 * Otherwise, will just return the variable ids.
 */
export function getVariablesUsedInFormulaExpression(formulaExpression: string, variableIdToVariableMap) {
    if (!formulaExpression?.length) {
        return [];
    }

    const variables: string[] = [];

    /* jshint loopfunc:true */
    for (let i = 0; i < formulaExpression.length; i++) {
        const currentChar = formulaExpression[i];

        // Field names will be encapsulated in {} brackets
        if (currentChar === '{') {
            const startingIndex = i + 1;
            const closingIndex = formulaExpression.indexOf('}', i); // The closing bracket of the opening bracket we found
            const fieldName = formulaExpression.substr(startingIndex, closingIndex - startingIndex);

            variables.push(fieldName);
        }
    }

    const uniqueVariables = variables ? Utils.getUniqueValues(variables) : [];

    if (!uniqueVariables.length) {
        return [];
    }
    return uniqueVariables.map((uniqueVariableName) =>
        variableIdToVariableMap ? variableIdToVariableMap[uniqueVariableName] : uniqueVariableName,
    );
}
