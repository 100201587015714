import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import type TestItemConfiguration from '../entities/TestItemConfiguration';
import SnippetComponent from '../SnippetModule/SnippetComponent';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Input, LoadingCircle, useDebouncedState } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { InitiativeSelector } from '@tonkean/initiative-selector';
import { TrashFormIcon } from '@tonkean/svg';
import type { PreviewFindWordsSnippet } from '@tonkean/tonkean-entities';
import type { TextExtractionFieldInformation } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Divider = styled.hr`
    margin: 20px 0 20px 0;
`;

const FormTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: bold;
    margin-bottom: 10px;
`;

const FormInputContainer = styled.div<{ includeMargin?: boolean }>`
    flex-grow: 1;
    width: 50%;

    ${({ includeMargin }) =>
        includeMargin &&
        css`
            margin-right: 20px;
        `}
`;

const TrashIcon = styled.button<{ isLastItem?: boolean }>`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    border: none;
    margin-left: 10px;
    background: transparent;

    svg {
        width: 16px;
        height: 16px;

        ${({ isLastItem }) =>
            isLastItem &&
            css`
                opacity: 0.4;
            `}
    }
`;

const FormContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-shrink: 0;

    ${childrenStyledFocus}
`;

const SnippetRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const SnippetTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
`;

const Snippet = styled.div`
    position: relative;
    padding: 5px;
    background: #f7f7f9;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
    min-height: 70px;
`;

const MatchingResult = styled.div<{ matching: boolean }>`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};

    ${({ matching }) => css`
        color: ${matching ? '#4CAF50' : '#F0611E'};
    `};
`;

const LoadingCircleContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 15px 0 0;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
`;

const ExpectedValueInput = styled(Input)`
    height: 28px;
`;

interface Props {
    testItemConfiguration: TestItemConfiguration;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    fieldDefinitionId?: string;
    textExtractionFieldInformation: TextExtractionFieldInformation;
    onPreviewFullTextClicked: (initiativeId?: string, expectedValue?: string) => void;
    onChange: (testItemConfiguration: TestItemConfiguration) => void;
    onDelete: () => void;
    isLastItem: boolean;
}

const FindWordsEditorTestItem: React.FC<Props> = ({
    testItemConfiguration,
    groupId,
    workflowVersionId,
    fieldDefinitionId,
    textExtractionFieldInformation,
    onPreviewFullTextClicked,
    onChange,
    onDelete,
    isLastItem,
}) => {
    const [snippets, setSnippets] = useState<PreviewFindWordsSnippet[]>([]);
    const [findWordsMatched, setFindWordsMatched] = useState<boolean | undefined>(undefined);

    const [internalExpectedValue, setInternalExpectedValue] = useDebouncedState(
        testItemConfiguration.expectedValue || '',
        (debouncedValue) => {
            onChange({
                ...testItemConfiguration,
                expectedValue: debouncedValue,
            });
        },
    );

    const [{ data: previewFindWordsResponse, error, loading }, previewFindWords] =
        useLazyTonkeanService('previewFindWords');

    useEffect(() => {
        if (testItemConfiguration.initiativeId && fieldDefinitionId) {
            previewFindWords(
                testItemConfiguration.initiativeId,
                fieldDefinitionId,
                testItemConfiguration.expectedValue,
                textExtractionFieldInformation,
                5,
            );
        }
    }, [previewFindWords, testItemConfiguration, fieldDefinitionId, textExtractionFieldInformation]);

    useEffect(() => {
        if (previewFindWordsResponse?.snippets && !error && !loading) {
            setSnippets(previewFindWordsResponse.snippets);
            setFindWordsMatched(previewFindWordsResponse.findWordsMatched);
        }
    }, [previewFindWordsResponse, error, loading]);

    return (
        <>
            <FormContainer>
                <FormInputContainer includeMargin>
                    <FormTitle>Item to test</FormTitle>

                    <InitiativeSelector
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        preselectedItem={testItemConfiguration.initiativeId}
                        onItemSelected={(newSelectedInitiativeId: TonkeanId<TonkeanType.INITIATIVE>) => {
                            onChange({
                                ...testItemConfiguration,
                                initiativeId: newSelectedInitiativeId,
                            });
                        }}
                    />
                </FormInputContainer>

                <FormInputContainer>
                    <FormTitle>Expected value</FormTitle>

                    <ExpectedValueInput
                        value={internalExpectedValue}
                        onChange={({ target }) => setInternalExpectedValue(target.value)}
                        placeholder="Expected value"
                    />
                </FormInputContainer>
                <Tooltip placement="top" content="Can't delete last item" disabled={!isLastItem}>
                    <TrashIcon isLastItem={isLastItem} disabled={isLastItem} type="button" onClick={onDelete}>
                        <TrashFormIcon />
                    </TrashIcon>
                </Tooltip>
            </FormContainer>

            <SnippetRow>
                <SnippetTitle>Snippet</SnippetTitle>

                <Button
                    size={ButtonSize.SMALL}
                    onClick={() =>
                        onPreviewFullTextClicked(
                            testItemConfiguration.initiativeId,
                            testItemConfiguration.expectedValue,
                        )
                    }
                    type="button"
                    outlined
                >
                    Preview Full Text
                </Button>
            </SnippetRow>

            <Snippet>
                <SnippetComponent snippets={snippets} />
                <LoadingCircleContainer>{loading && <LoadingCircle />}</LoadingCircleContainer>
                {error && <ErrorText>Error calculating snippet...</ErrorText>}
            </Snippet>

            <SnippetRow>
                <SnippetTitle>Extracted Value</SnippetTitle>
            </SnippetRow>

            <Snippet>
                {!loading && !error && previewFindWordsResponse?.findWordsPreviewFieldResult}
                <LoadingCircleContainer>{loading && <LoadingCircle />}</LoadingCircleContainer>
                {error && <ErrorText>Error calculating snippet...</ErrorText>}
            </Snippet>

            {findWordsMatched !== undefined && (
                <MatchingResult matching={findWordsMatched}>
                    {findWordsMatched ? 'FindWords Matched!' : 'FindWords Not Matched!'}
                </MatchingResult>
            )}

            <Divider />
        </>
    );
};

export default FindWordsEditorTestItem;
