import channelOrPersonSelectorValidator from './channelOrPersonSelectorValidator';
import isValidWorkflowDataStorageConfiguration from './isValidWorkflowDataStorageConfiguration';

import { PersonInquirySendTo } from '@tonkean/tonkean-entities';

const botQuestionAndSendNotificationValidator = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    group,
    projectManager,
) => {
    if (!definition.text?.length) {
        validationObject.text = 'Text field must be filled.';
    }

    if (definition.forceEmail && !definition.emailSubject?.length) {
        validationObject.emailSubject = 'Email subject must be filled.';
    }

    if (definition.attachments?.length) {
        const invalidIndex = definition.attachments
            .map((singleAttachment) => singleAttachment.fileSourceConfiguration)
            .findIndex(
                (singleSource) =>
                    !isValidWorkflowDataStorageConfiguration(
                        singleSource,
                        group,
                        workflowVersionInfoRetriever,
                        syncConfigCacheManager,
                        projectManager,
                    ),
            );

        if (invalidIndex !== -1) {
            validationObject.invalidAttachmentIndex = invalidIndex;
            validationObject.invalidAttachmentMessage =
                'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
        }
    }

    switch (definition.sendToType) {
        case PersonInquirySendTo.PEOPLE_DIRECTORY: {
            break;
        }
        case PersonInquirySendTo.CUSTOM:
        default: {
            channelOrPersonSelectorValidator(validationObject, definition, true);
            break;
        }
    }
};

export default botQuestionAndSendNotificationValidator;
