import { Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import ProjectIntegrationActionEditPredefinedParameterModal from './ProjectIntegrationActionEditPredefinedParameterModal';
import { ReactComponent as EditIcon } from '../../../../../../../../images/icons/edit-pencil.svg';
import { ReactComponent as ModalXIcon } from '../../../../../../../../images/icons/modal-x.svg';
import EditableTitle from '../../../../../../../components/common/EditableTitle/EditableTitle';

import { ActionParameterType } from '@tonkean/tonkean-entities';
import type { ActionParameterExpression, BaseActionParameter } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { classNames } from '@tonkean/utils';

const Row = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #cdd2d8;
    padding-bottom: 12px;
    padding-top: 12px;
`;

const InnerRow = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const PredefinedParam = styled(EditableTitle)`
    width: 500px;
`;

const ParamName = styled.div<{ colored?: boolean }>`
    ${({ colored }) =>
        colored &&
        css`
            color: ${Theme.current.palette.colorPicker.HEX_605CE4};
        `};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
`;

const RemoveParamButton = styled.button`
    margin-left: 8px;

    svg {
        height: 10px;
        width: 10px;
    }
`;

const PredefinedActionParameterNameSchema = Yup.object({
    displayName: Yup.string()
        .required('Action parameter must have a name')
        .max(350, "Action parameter name can't be longer than 350 characters"),
});

type PredefinedActionParameterNameSchemaType = Yup.InferType<typeof PredefinedActionParameterNameSchema>;

interface Props {
    parameter: BaseActionParameter;
    onRemove: (paramIdToRemove: string) => void;
    onUpdate: (updatedParameter: BaseActionParameter) => void;
    isModuleParams?: boolean;
}

const ProjectIntegrationActionPredefinedParameter: React.FC<Props> = ({
    parameter,
    onRemove,
    onUpdate,
    isModuleParams = true,
}) => {
    const [editParamModalOpen, setEditParamModalOpen] = useState<boolean>(false);

    const initialValues = useMemo(() => {
        return {
            displayName: parameter.displayName,
        };
    }, [parameter.displayName]);

    const onSubmit = useCallback(
        (values: PredefinedActionParameterNameSchemaType) => {
            const updatedParam: BaseActionParameter = {
                ...parameter,
                displayName: values.displayName,
            } as ActionParameterExpression;

            onUpdate(updatedParam);
        },
        [onUpdate, parameter],
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={PredefinedActionParameterNameSchema}
            enableReinitialize
        >
            <div>
                {parameter.parameterType === ActionParameterType.TONKEAN_EXPRESSION && (
                    <Row key={parameter.id}>
                        {isModuleParams && (
                            <InnerRow>
                                <ParamName
                                    className="common-break-long"
                                    data-automation="project-integration-action-predefined-parameter-name"
                                >
                                    {parameter.displayName}
                                </ParamName>
                            </InnerRow>
                        )}

                        {!isModuleParams && (
                            <PredefinedParam
                                name="displayName"
                                onChange={(display) => {
                                    const updatedParam: BaseActionParameter = {
                                        ...parameter,
                                        displayName: display,
                                    } as ActionParameterExpression;

                                    onUpdate(updatedParam);
                                }}
                                maxLength={500}
                                fontSize={FontSize.LARGE_16}
                            />
                        )}

                        <InnerRow>
                            {isModuleParams && (
                                <IconButton
                                    iconColor={Theme.colors.primary}
                                    onClick={() => setEditParamModalOpen(true)}
                                    data-automation="project-integration-action-predefined-parameter-edit-button"
                                    flat
                                >
                                    <EditIcon />
                                </IconButton>
                            )}

                            <RemoveParamButton
                                type="button"
                                className={classNames('inline-button mod-outline')}
                                onClick={() => onRemove(parameter.id)}
                            >
                                <span className="tnk-icon">
                                    <ModalXIcon />
                                </span>
                            </RemoveParamButton>
                        </InnerRow>
                    </Row>
                )}

                <ProjectIntegrationActionEditPredefinedParameterModal
                    open={editParamModalOpen}
                    onClose={() => setEditParamModalOpen(false)}
                    onUpdate={onUpdate}
                    parameter={parameter as ActionParameterExpression}
                />
            </div>
        </Formik>
    );
};

export default ProjectIntegrationActionPredefinedParameter;
