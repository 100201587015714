import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import InterfaceSubmitValidationContext from '../../utils/InterfaceSubmitValidationContext';

import { FieldError, useOnClickOutside } from '@tonkean/infrastructure';
import { ItemsGrid } from '@tonkean/items-grid';
import type { ItemGridProps } from '@tonkean/items-grid';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const ItemsGridWrapper = styled.div`
    height: 100%;
`;

interface Props extends ItemGridProps {
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
}

const ItemsGridWithValidation: React.FC<Props> = ({ widgetId, ...props }) => {
    const [focused, setFocused] = useState<boolean>(false);
    const { forceShowValidation, validationResult, onValidationChanged } = useContext(InterfaceSubmitValidationContext);
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

    const ref = useOnClickOutside(
        () => {
            if (focused) {
                setShowValidationErrors(true);
            }
        },
        true,
        false,
    );

    const onCellMouseDown = useCallback(() => {
        setFocused(true);
    }, []);

    const onValidationChangedCallback = useCallback(
        (errors: string[]) => {
            onValidationChanged(errors, widgetId);
        },
        [onValidationChanged, widgetId],
    );

    const errorMessages: string[] =
        validationResult[widgetId]
            ?.map((result) => result.errorMessage)
            ?.filter((errorMessage): errorMessage is string => !!errorMessage) || [];

    return (
        <>
            <ItemsGridWrapper ref={ref}>
                <ItemsGrid
                    onCellMouseDown={onCellMouseDown}
                    onValidationChanged={onValidationChangedCallback}
                    {...props}
                />
            </ItemsGridWrapper>

            {(showValidationErrors || forceShowValidation) && errorMessages.length > 0 && (
                <FieldError $inlineError={false} $textAlign="right" data-automation="line-items-widget-error-message">
                    {errorMessages.map((errorMessage, index) => (
                        <div key={index}>{errorMessage}</div>
                    ))}
                </FieldError>
            )}
        </>
    );
};

export default ItemsGridWithValidation;
