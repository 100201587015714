import template from './tnkSendEmailLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSendEmailLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        overrideTitle: '@',
        overrideActionType: '@',
        onActionsChanged: '&',
    },
    template,
    controller: 'SendEmailLogicConfigurationCtrl',
});
