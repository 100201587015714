import { lateConstructController } from '@tonkean/angular-components';
import { getRelevantProjectIntegrations } from '@tonkean/tonkean-utils';

/* @ngInject */
const WorkerTargetStorageSelectionCtrl = function ($scope, integrations, utils, projectManager) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        customTriggerId: ctrl.customTriggerId,
        shouldDisplayDeleteSign: ctrl.shouldDisplayDeleteSign,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        infoMessage: ctrl.infoMessage,
        storageIntegrationId: ctrl.storageIntegrationId,
        newDocumentName: ctrl.newDocumentName || {
            originalExpression: null,
            evaluatedExpression: null,
        },
        storageIntegrationTypes: Object.keys(integrations.getSupportedUploadingStorageIntegrations()),
        storageIntegration: null,
    };

    /**
     * Initialization function.
     */
    ctrl.$onInit = function () {
        const relevantProjectIntegrations = getRelevantProjectIntegrations(
            projectManager.project,
            projectManager.groupsMap[$scope.data.groupId],
            $scope.data.storageIntegrationTypes,
        );
        $scope.data.storageIntegration = utils.findFirst(
            relevantProjectIntegrations,
            (projectIntegration) => projectIntegration.id === $scope.data.storageIntegrationId,
        );
    };

    /**
     * Occurs on the selection of a project integration.
     */
    $scope.onProjectIntegrationSelected = function (storageIntegration) {
        $scope.data.storageIntegration = storageIntegration;
        $scope.data.storageIntegrationId = storageIntegration.id;
        $scope.onWorkerTargetStorageSelectionChanged(true);
    };

    $scope.onNewDocumentNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.newDocumentName = { originalExpression, evaluatedExpression };
        $scope.onWorkerTargetStorageSelectionChanged(shouldSaveLogic);
    };

    /**
     * Occurs on a click on the delete sign.
     */
    $scope.deleteSignClicked = function () {
        if (ctrl.onDeleteSignClicked) {
            ctrl.onDeleteSignClicked({});
        }
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (shouldSaveLogic) {
        if (ctrl.onWorkerTargetStorageSelectionChanged) {
            ctrl.onWorkerTargetStorageSelectionChanged({
                storageIntegrationId: $scope.data.storageIntegrationId,
                newDocumentName: $scope.data.newDocumentName,
                shouldSaveLogic,
            });
        }
    };
};

export default angular
    .module('tonkean.app')
    .controller('WorkerTargetStorageSelectionCtrl', lateConstructController(WorkerTargetStorageSelectionCtrl));
