import React, { useMemo } from 'react';

import { DragAndDropFiles, useFormikField } from '@tonkean/infrastructure';
import { EmptyImage } from '@tonkean/svg';
import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

interface Props {}

const CreateMarketplaceItemFromJsonFile: React.FC<Props> = ({}) => {
    const {
        value: file,
        error,
        touched,
        setValue: setFile,
        setError,
    } = useFormikField<TonkeanUploadedFile[] | undefined>('marketplaceItemAsJsonFile');

    const acceptedFileTypes = useMemo(() => {
        return ['application/json'];
    }, []);

    return (
        <DragAndDropFiles
            acceptedFileTypes={acceptedFileTypes}
            mainText="Drag json here to upload"
            dragAndDropLogo={<EmptyImage />}
            existingFiles={file ?? EMPTY_ARRAY}
            setExistingFiles={setFile}
            setErrorText={setError}
            maxFileSizeMB={50}
            limitationsDescriptionText="The maximum file size is 50mb and the supported file type is JSON"
            maximumFiles={1}
            isImageUpload={false}
        />
    );
};

export default CreateMarketplaceItemFromJsonFile;
