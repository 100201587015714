import { useMemo } from 'react';

import useCurrentlyAuthenticatedUser from './useCurrentlyAuthenticatedUser';
import useProject from './useProject';

import { SCIMTonkeanRole } from '@tonkean/tonkean-entities';

/**
 * A hook that returns the roles of the current user in the current project.
 */
function useCurrentUserRole() {
    const { owners } = useProject();

    const currentUser = useCurrentlyAuthenticatedUser();

    const isAdmin = useMemo(() => {
        return owners.some((owner) => owner.id === currentUser.id);
    }, [currentUser.id, owners]);

    const isUserProcessContributor = useMemo(
        () => currentUser.projectContext?.calculatedTonkeanRoles.includes(SCIMTonkeanRole.PROCESS_CONTRIBUTOR) ?? false,
        [currentUser.projectContext?.calculatedTonkeanRoles],
    );
    const isUserSystemUser = useMemo(
        () => currentUser.projectContext?.calculatedTonkeanRoles.includes(SCIMTonkeanRole.SYSTEM_USER) ?? false,
        [currentUser.projectContext?.calculatedTonkeanRoles],
    );
    const highestRole = useMemo(() => {
        if (isUserSystemUser) {
            return SCIMTonkeanRole.SYSTEM_USER;
        } else if (isUserProcessContributor) {
            return SCIMTonkeanRole.PROCESS_CONTRIBUTOR;
        }

        return SCIMTonkeanRole.NO_ACCESS;
    }, [isUserProcessContributor, isUserSystemUser]);

    return {
        noAccess: !isUserProcessContributor && !isUserSystemUser,
        processContributor: isUserProcessContributor,
        systemUser: isUserSystemUser,
        highestRole,
        userId: currentUser.id,
        isAdmin,
    };
}

export default useCurrentUserRole;
