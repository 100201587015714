import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const FrontDoorActionLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.FRONT_DOOR_ACTION,
    displayName: 'Front-Door',
    title: 'Front-Door',
    iconClass: 'mod-front-door-action',
    description: 'TBD', // TODO: add description
};

export default FrontDoorActionLogicBlockConfig;
