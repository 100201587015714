import { NavigablePageType } from '../entities/BaseNavigablePage';
import type InternalNavigablePage from '../entities/InternalNavigablePage';
import NavigablePageKey from '../entities/NavigablePageKey';

import { DataSourceIcon } from '@tonkean/svg';
import { LiveReportIcon } from '@tonkean/svg';
import { OpsModuleIcon } from '@tonkean/svg';
import { TonkeanIcon } from '@tonkean/svg';
import { HomeIcon } from '@tonkean/svg';
import { RuntimeIcon } from '@tonkean/svg';
import { BoardPermissionsIcon } from '@tonkean/svg';
import {
    BarChart10Icon,
    ClockRefreshIcon,
    CloudBlank01Icon,
    CubeOutlineIcon,
    DataIcon,
    FileSearch02Icon,
    Lightning01Icon,
    MessageChatCircleIcon,
    Users01Icon,
} from '@tonkean/svg';

export const homepageNavigablePage: InternalNavigablePage = {
    id: NavigablePageKey.HOMEPAGE,
    type: NavigablePageType.INTERNAL,
    name: 'Homepage',
    state: 'homepageView',
    icon: TonkeanIcon,
    menuIcon: HomeIcon,
    isShownInNavigationMenu: (features) => features.tonkean_feature_homepage,
    openInNewPage: false,
    accessibleToAllUsers: true,
    secondaryStates: [],
};

const internalPages: InternalNavigablePage[] = [
    homepageNavigablePage,
    {
        id: NavigablePageKey.OPS_MODULES,
        type: NavigablePageType.INTERNAL,
        name: 'Solutions Studio',
        state: 'product.workers',
        icon: OpsModuleIcon,
        menuIcon: Lightning01Icon,
        isShownInNavigationMenu: () => true,
        openInNewPage: false,
        accessibleToAllUsers: false,
        secondaryStates: [
            'product.workerEditor',
            'product.solutionMapper',
            'product.solution',
            'product.itemInterfaceBuilder',
            'product.itemInterfaceBuilderPreview',
            'product.solution-site-builder',
            'product.processBuilder',
        ],
    },
    {
        id: NavigablePageKey.ENTERPRISE_COMPONENTS,
        type: NavigablePageType.INTERNAL,
        name: 'Enterprise Components',
        state: 'product.enterpriseComponents',
        params: { tab: 'data-sources' },
        icon: DataSourceIcon,
        menuIcon: DataIcon,
        isShownInNavigationMenu: () => true,
        openInNewPage: false,
        accessibleToAllUsers: false,
        secondaryStates: [
            'product.projectIntegrationPage',
            'product.peopleDirectoryPage',
            'product.trainingSetPage',
            'product.projectIntegrationActionManager',
            'product.projectIntegrationPageEntity',
            'product.projectIntegrationPageEntityFetcher',
            'product.marketplaceItemView',
            'product.createMarketplaceItemForNativeIntegration',
            'product.createEntityFromMarketplaceItemJson',
        ],
        innerPages: [
            {
                id: NavigablePageKey.ENTERPRISE_COMPONENTS_DATA_SOURCES,
                type: NavigablePageType.INTERNAL,
                name: 'Data Sources',
                state: 'product.enterpriseComponents',
                params: { tab: 'data-sources' },
                icon: CloudBlank01Icon,
                menuIcon: CloudBlank01Icon,
                isShownInNavigationMenu: () => true,
                openInNewPage: false,
                accessibleToAllUsers: false,
                secondaryStates: [],
            },
            {
                id: NavigablePageKey.ENTERPRISE_COMPONENTS_COMMUNICATION_TOOLS,
                type: NavigablePageType.INTERNAL,
                name: 'Communication Tools',
                state: 'product.enterpriseComponents',
                params: { tab: 'communication-sources' },
                icon: MessageChatCircleIcon,
                menuIcon: MessageChatCircleIcon,
                isShownInNavigationMenu: () => true,
                openInNewPage: false,
                accessibleToAllUsers: false,
                secondaryStates: [],
            },
            {
                id: NavigablePageKey.ENTERPRISE_COMPONENTS_BUSINESS_GROUPS,
                type: NavigablePageType.INTERNAL,
                name: 'Business Groups',
                state: 'product.enterpriseComponents',
                params: { tab: 'business-groups' },
                icon: Users01Icon,
                menuIcon: Users01Icon,
                isShownInNavigationMenu: () => true,
                openInNewPage: false,
                accessibleToAllUsers: false,
                secondaryStates: [],
            },
            {
                id: NavigablePageKey.ENTERPRISE_COMPONENTS_TRAINING_SETS,
                type: NavigablePageType.INTERNAL,
                name: 'Training Sets',
                state: 'product.enterpriseComponents',
                params: { tab: 'training-sets' },
                icon: FileSearch02Icon,
                menuIcon: FileSearch02Icon,
                isShownInNavigationMenu: () => true,
                openInNewPage: false,
                accessibleToAllUsers: false,
                secondaryStates: [],
            },
            {
                id: NavigablePageKey.ENTERPRISE_COMPONENTS_REUSABLE_COMPONENTS,
                type: NavigablePageType.INTERNAL,
                name: 'Reusable Components',
                state: 'product.enterpriseComponents',
                params: { tab: 'reusable-components' },
                icon: CubeOutlineIcon,
                menuIcon: CubeOutlineIcon,
                isShownInNavigationMenu: (features) => features.tonkean_feature_marketplace,
                openInNewPage: false,
                accessibleToAllUsers: false,
                secondaryStates: [],
            },
            {
                id: NavigablePageKey.RUNTIME,
                type: NavigablePageType.INTERNAL,
                name: 'Runtime',
                state: 'product.runtime',
                params: {
                    tab: 'background-processes',
                    environment: 'production',
                    g: null,
                    listType: null,
                    defaultFilterSearchTerm: null,
                },
                icon: RuntimeIcon,
                menuIcon: ClockRefreshIcon,
                isShownInNavigationMenu: () => true,
                openInNewPage: false,
                accessibleToAllUsers: false,
            },
        ],
    },
    {
        id: NavigablePageKey.LIVE_REPORT,
        type: NavigablePageType.INTERNAL,
        name: 'Business Reports',
        state: 'product.board',
        params: { filter: 'all', g: null, st: null },
        icon: LiveReportIcon,
        menuIcon: BarChart10Icon,
        isShownInNavigationMenu: () => true,
        openInNewPage: false,
        accessibleToAllUsers: false,
        secondaryStates: [
            'product.board',
            'product.calendar',
            'product.archive',
            'product.solution-business-report',
            'product.processContributorSolutionBusinessReport',
            'product.interface',
        ],
    },
    {
        id: NavigablePageKey.BOARD_SETTINGS,
        type: NavigablePageType.INTERNAL,
        name: 'Board Settings',
        state: 'product.settings.tab',
        icon: BoardPermissionsIcon,
        menuIcon: BoardPermissionsIcon,
        isShownInNavigationMenu: () => false,
        openInNewPage: false,
        accessibleToAllUsers: false,
        secondaryStates: [
            'product.settings',
            'product.settings.license',
            'product.settings.profile',
            'product.settings.board',
            'product.settings.dataSources',
            'product.dataSourcesSingle',
        ],
    },
];

export default internalPages;
