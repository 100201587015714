import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ReactComponent as RefreshIcon } from '../../../../../../images/icons/history/refresh.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const StyledRefreshIcon = styled(RefreshIcon)`
    path {
        fill: ${Theme.current.palette.collect.collectNowButton.fill};
    }
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const UpdateNowSection = styled.div`
    display: flex;
`;

interface Props {
    projectIntegrationId: string;
    onCollectNowRequestStarted?: () => void;
    disabled?: boolean;
}

const ProjectIntegrationCollectStatusCollectNow: React.FC<Props> = ({
    projectIntegrationId,
    onCollectNowRequestStarted = () => {},
    disabled = false,
}) => {
    const [{ error, loading }, collectProjectIntegrationNow] = useLazyTonkeanService('collectProjectIntegrationNow');

    const onCollectNowClick = useCallback(async () => {
        onCollectNowRequestStarted();
        await collectProjectIntegrationNow(projectIntegrationId);
    }, [onCollectNowRequestStarted, collectProjectIntegrationNow, projectIntegrationId]);

    return (
        <UpdateNowSection>
            <StyledButton
                data-automation="tnk-project-integration-collect-status-stopped-collect-button"
                onClick={onCollectNowClick}
                size={ButtonSize.SMALL}
                disabled={loading || disabled}
                outlined
            >
                <StyledRefreshIcon /> Collect Manually
            </StyledButton>
            {error && <ErrorMessage>{getStateError(error)}</ErrorMessage>}
        </UpdateNowSection>
    );
};

export default ProjectIntegrationCollectStatusCollectNow;
