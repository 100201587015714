<div ng-controller="SetupIntegrationTypeCtrl">
    <!-- Import from CSV -->
    <div
        ng-init="data.settingUpGoogleSheets = false; data.settingUpNewGoogleSheet = false; data.settingUpExcelSheets = false; data.settingUpNewExcelSheet = false;"
    >
        <div
            ng-show="
                (!csvData.table || csvData.changeFile) && !data.settingUpGoogleSheets && !data.settingUpExcelSheets
            "
        >
            <div class="common-size-s">
                Choose the type of spreadsheet that represents the list of
                <span class="common-bold-title">{{ data.selectedList.shortTitle }}</span>
                :
            </div>
            <div class="common-width-50-no-xs margin-top-lg">
                <!-- Select CSV file -->
                <label class="btn btn-secondary btn-lg common-width-90 block margin-bottom btn-text-left">
                    <span class="flex-vmiddle">
                        <input
                            class="hidden"
                            type="file"
                            id="fileInput"
                            custom-on-change="onFileChange"
                            accept=".csv, text/csv"
                        />
                        <span class="svg-icon-md margin-right-xs">
                            <tnk-icon src="/images/icons/csv-logo.svg"></tnk-icon>
                        </span>
                        Upload a csv file
                    </span>
                </label>

                <!--&lt;!&ndash; Separator &ndash;&gt;-->
                <!--<div class="flex-vmiddle common-color-grey margin-top margin-bottom">-->
                <!--<div class="flex-grow">-->
                <!--<hr class="margin-none"/>-->
                <!--</div>-->
                <!--<i class="common-size-xxxxs margin-right margin-left">Or</i>-->
                <!--<div class="flex-grow">-->
                <!--<hr class="margin-none"/>-->
                <!--</div>-->
                <!--</div>-->

                <!-- Google sheet selection -->

                <button
                    class="btn btn-secondary btn-lg common-width-90 block margin-bottom btn-text-left"
                    ng-click="data.settingUpGoogleSheets = true"
                >
                    <!--ng-click="modalUtils.openGoogleSheetSelectorModal(onGoogleSheetProjectIntegrationSelected)">-->
                    <span class="flex-vmiddle">
                        <span class="svg-icon-md margin-right-xs">
                            <tnk-icon src="/images/icons/googlesheets-logo.svg"></tnk-icon>
                        </span>
                        Sync with Google Sheets
                    </span>
                </button>

                <button
                    class="btn btn-secondary btn-lg common-width-90 block margin-bottom btn-text-left"
                    ng-click="data.settingUpExcelSheets = true"
                >
                    <span class="flex-vmiddle">
                        <span class="svg-icon-md margin-right-xs">
                            <tnk-icon src="/images/icons/excel-logo.svg"></tnk-icon>
                        </span>
                        <span>Sync with Excel Online</span>
                    </span>
                </button>
            </div>
        </div>
        <div
            ng-hide="
                (!csvData.table || csvData.changeFile) && !data.settingUpGoogleSheets && !data.settingUpExcelSheets
            "
        >
            <div ng-if="data.settingUpGoogleSheets" ng-hide="data.settingUpExcelSheets">
                <!-- choose from existing-->
                <div
                    ng-hide="
                        !googlSheetsIntegrations ||
                        !googlSheetsIntegrations.length ||
                        data.settingUpNewGoogleSheet ||
                        data.settingUpNewExcelSheet
                    "
                >
                    <p>Choose from already connected Google Sheets:</p>
                    <!-- Connected and enabled integrations -->
                    <div
                        ng-repeat="projectIntegration in googlSheetsIntegrations = (pm.project.integrations | filter:{integration: {integrationType: 'googlesheets'}})"
                        ng-if="projectIntegration.disabled === false"
                        class="
                            btn btn-default
                            margin-right-xs
                            common-size-xxs
                            margin-bottom-xs
                            integration-selection-button
                        "
                        ng-click="selectProvider(projectIntegration)"
                    >
                        <i
                            class="initiative-integration-icon mod-normal margin-right"
                            ng-class="
                                !projectIntegration.iconUrl
                                    ? 'mod-' + projectIntegration.integration.integrationType.toLowerCase()
                                    : ''
                            "
                            ng-style="{
                                background: projectIntegration.iconUrl
                                    ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                                    : null
                            }"
                        ></i>
                        {{ projectIntegration.displayName }}
                    </div>

                    <div class="margin-top-lg">
                        <p class="common-color-grey">OR</p>
                        <a class="btn btn-secondary" ng-click="data.settingUpNewGoogleSheet = true">
                            Connect to another Google Sheet...
                        </a>
                    </div>
                </div>

                <div
                    ng-show="
                        !googlSheetsIntegrations || !googlSheetsIntegrations.length || data.settingUpNewGoogleSheet
                    "
                    class="margin-top-minus"
                >
                    <tnk-google-sheet-selector
                        on-project-integration-created="
                            onGoogleSheetProjectIntegrationSelected(projectIntegration, fieldDefinitionsToCreate)
                        "
                        display-column-selection="true"
                        on-cancel="data.settingUpGoogleSheets = false; data.settingUpNewGoogleSheet = false"
                    ></tnk-google-sheet-selector>
                </div>
            </div>

            <div ng-if="data.settingUpExcelSheets" ng-hide="data.settingUpGoogleSheets">
                <!-- choose from existing-->
                <div
                    ng-hide="
                        !excelSheetsIntegrations ||
                        !excelSheetsIntegrations.length ||
                        data.settingUpNewExcelSheet ||
                        data.settingUpNewGoogleSheet
                    "
                >
                    <p>Choose from already connected Excel Online Spreadsheets:</p>
                    <!-- Connected and enabled integrations -->
                    <div
                        ng-repeat="projectIntegration in excelSheetsIntegrations = (pm.project.integrations | filter:{integration: {integrationType: 'excel365'}})"
                        ng-if="projectIntegration.disabled === false"
                        class="
                            btn btn-default
                            margin-right-xs
                            common-size-xxs
                            margin-bottom-xs
                            integration-selection-button
                        "
                        ng-click="selectProvider(projectIntegration)"
                    >
                        <i
                            class="initiative-integration-icon mod-normal margin-right"
                            ng-class="
                                !projectIntegration.iconUrl
                                    ? 'mod-' + projectIntegration.integration.integrationType.toLowerCase()
                                    : ''
                            "
                            ng-style="{
                                background: projectIntegration.iconUrl
                                    ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                                    : null
                            }"
                        ></i>
                        {{ projectIntegration.displayName }}
                    </div>

                    <div class="margin-top-lg">
                        <p class="common-color-grey">OR</p>
                        <a class="btn btn-secondary" ng-click="data.settingUpNewExcelSheet = true">
                            Connect to another Excel Online Spreadsheet...
                        </a>
                    </div>
                </div>

                <div
                    ng-show="!excelSheetsIntegrations || !excelSheetsIntegrations.length || data.settingUpNewExcelSheet"
                    class="margin-top-minus"
                >
                    <tnk-excel-sheet-selector
                        on-project-integration-created="
                            onExcelSheetProjectIntegrationSelected(projectIntegration, fieldDefinitionsToCreate)
                        "
                        display-column-selection="true"
                        on-cancel="data.settingUpExcelSheets = false; data.settingUpNewExcelSheet = false"
                    ></tnk-excel-sheet-selector>
                </div>
            </div>
        </div>
        <div ng-if="!csvData.loadingFile && csvData.table">
            <tnk-import-table
                table="csvData.table"
                on-cancel="clearFile()"
                override-import="true"
                on-override-import="handleCsvResult(result)"
                prevent-duplicates-update="true"
            ></tnk-import-table>
        </div>
    </div>
</div>
