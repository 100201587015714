import styled from 'styled-components';

import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Cell = styled.div<{ $isSelected: boolean; $selectionColor: Color }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 17px 16px;

    &:not(:first-child) {
        border-top: 1px solid ${Theme.colors.gray_200};
    }

    border-right: 1px solid ${Theme.colors.gray_200};
    background: ${({ $isSelected, $selectionColor }) =>
        $isSelected
            ? `linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), ${$selectionColor}`
            : Theme.colors.white};

    box-shadow: ${({ $isSelected, $selectionColor }) =>
        $isSelected ? `5px 0px 0px 0px ${$selectionColor} inset` : undefined};
`;

export const CellContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: baseline;
`;
