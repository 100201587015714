<div class="nlp-branch-logic-configuration" data-automation="nlp-branch-logic-configuration">
    <div
        ng-if="data.invalidLogics[data.configuredLogic.node.id].noInputExpression"
        class="margin-top common-color-danger"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].noInputExpression }}
    </div>

    <div ng-if="data.textAnalysisType !== 'TRAINING_SET'" class="margin-bottom-xs">Define keywords:</div>

    <div>
        <tnk-nlp-branch-configuration
            definition="data.definition"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            text-analysis-type="data.textAnalysisType"
            selected-training-set-id="data.parentNlpProcessorTrainingSetId"
            on-branch-definition-changed="onActionsChanged(definition, shouldSaveLogic)"
            training-set-models-to-exclude="data.alreadyUsedModelIds"
            on-branch-name-changed="(onTrainingSetModelSelected)"
            group-id="data.groupId"
        ></tnk-nlp-branch-configuration>
    </div>

    <!-- Training examples title -->
    <div ng-if="data.textAnalysisType !== 'TRAINING_SET'" class="margin-top-lg margin-bottom-xs">
        Training examples:
    </div>

    <form
        ng-if="data.textAnalysisType !== 'TRAINING_SET'"
        name="trainingExampleForm"
        ng-submit="addNewTrainingExample()"
    >
        <!-- Current training example input -->
        <input
            type="text"
            class="form-control"
            ng-model="data.currentTrainExampleToAdd"
            maxlength="255"
            placeholder="Enter an example sentence..."
            data-automation="tnk-nlp-branch-logic-configuration-template-training-example-sentence"
        />

        <div class="flex-vmiddle margin-top margin-bottom-lg">
            <!-- Loading/error section -->
            <div class="flex-grow">
                <!-- Loading -->
                <div ng-if="data.loadingAddNewTrainingExample" class="flex-vmiddle">
                    <!-- Icon -->
                    <i class="loading-small margin-right-xs"></i>

                    <!-- Text -->
                    <div>Adding example...</div>
                </div>

                <!-- Error -->
                <div ng-if="data.errorLoadingAddNewTrainingExample" class="common-color-danger">
                    Failed adding example...
                </div>
            </div>

            <div uib-dropdown class="margin-right flex-no-shrink">
                <!-- Sentiment dropdown toggle -->
                <div uib-dropdown-toggle class="pointer">
                    <div class="flex-vmiddle">
                        <!-- Current sentiment selection -->
                        <div
                            class="margin-right-xs"
                            ng-class="{
                                'positive-example': data.currentTrainExampleSentiment === 'POSITIVE',
                                'negative-example': data.currentTrainExampleSentiment === 'NEGATIVE'
                            }"
                        >
                            {{ data.currentTrainExampleSentiment === 'POSITIVE' ? 'Positive' : 'Negative' }} example
                        </div>

                        <!-- Dropdown chevron -->
                        <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                    </div>
                </div>

                <!-- Sentiment dropdown options -->
                <ul uib-dropdown-menu>
                    <li
                        class="sentiment-dropdown-option pointer negative-example"
                        ng-click="selectCurrentTrainingExampleSentiment('NEGATIVE')"
                    >
                        Negative
                    </li>
                    <li
                        class="sentiment-dropdown-option pointer positive-example"
                        ng-click="selectCurrentTrainingExampleSentiment('POSITIVE')"
                    >
                        Positive
                    </li>
                </ul>
            </div>

            <!-- Add new trained example -->
            <button
                class="btn btn-primary flex-no-shrink common-no-outline"
                data-automation="tnk-nlp-branch-logic-configuration-template-add-new-example"
                type="submit"
                ng-disabled="!data.currentTrainExampleToAdd || !data.currentTrainExampleToAdd.length || data.trainedKeywordsSet[data.currentTrainExampleToAdd] || data.loadingAddNewTrainingExample"
            >
                Add
            </button>
        </div>
    </form>

    <!-- Error examples -->
    <div ng-if="data.errorLoadingTrainedKeywords" class="common-color-danger">
        There was an error loading trained keywords.
    </div>

    <!-- Training examples box - items state -->
    <div
        class="training-examples-box margin-bottom"
        ng-if="
            !data.loadingTrainedKeywords &&
            !data.errorLoadingTrainedKeywords &&
            data.trainedKeywords &&
            data.trainedKeywords.length &&
            data.textAnalysisType !== 'TRAINING_SET'
        "
    >
        <!-- For each training example -->
        <div
            ng-repeat="trainedKeyword in data.trainedKeywords"
            class="flex-vmiddle trained-example-box"
            uib-tooltip="This keyword was added by {{
                trainedKeyword.createdManually ? trainedKeyword.creator.name : 'a module'
            }}."
            tooltip-placement="top"
        >
            <!-- Keyword -->
            <div class="flex-grow trained-keyword-text" data-automation="tnk-nlp-branch-logic-configuration-template-trained-keyword-text-{{ trainedKeyword.keyword }}">{{ trainedKeyword.keyword }}</div>

            <!-- Sentiment indication -->
            <div
                class="margin-right"
                data-automation="tnk-nlp-branch-logic-configuration-template-sentiment-indication-{{ trainedKeyword.keyword }}"
                ng-class="{
                    'positive-example': trainedKeyword.keywordSentiment === 'POSITIVE',
                    'negative-example': trainedKeyword.keywordSentiment === 'NEGATIVE'
                }"
            >
                {{ trainedKeyword.keywordSentiment === 'POSITIVE' ? 'Positive' : 'Negative' }}
            </div>

            <!-- Delete icon -->
            <i
                class="fa fa-trash-o delete-trained-keyword-icon"
                data-automation="tnk-nlp-branch-logic-configuration-template-delete-trained-keyword-{{ trainedKeyword.keyword }}"
                ng-click="removeTrainedKeyword($index)"
                ng-class="{ 'mod-enabled': !data.loadingDeleteKeyword, 'mod-disabled': data.loadingDeleteKeyword }"
                ng-if="!data.loadingDeleteKeyword || data.loadingDeleteKeywordIndex !== $index"
            ></i>

            <!-- Loading -->
            <i class="loading-small" ng-if="data.loadingDeleteKeyword && data.loadingDeleteKeywordIndex === $index"></i>
        </div>
    </div>

    <!-- Training examples box - loading state -->
    <div class="training-examples-box margin-bottom" ng-if="data.errorLoadingTrainedKeywords">
        <!-- For each training example -->
        <div ng-repeat="ignored in [1, 2]" class="flex-vmiddle trained-example-box">
            <div class="trained-keyword-singular-loading-placeholder"></div>
        </div>
    </div>

    <!-- Publish warning -->
    <div ng-if="data.anyKeywordsAddedByWorker">* Note: only manually added keywords will be published.</div>
</div>
