import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

import HistoryContext from '../../entities/HistoryContext';
import workerRunReasonConfig from '../../entities/workerRunReasonConfig';
import type WorkerRunReasonConfigItem from '../../entities/WorkerRunReasonConfigItem';
import workerRunStageConfig from '../../entities/workerRunStageConfig';
import SingleWorkerRunModuleItemSourceWorkerRunLink from '../SingleWorkerRun/SingleWorkerRunModuleItem/SingleWorkerRunModuleItemSourceWorkerRunLink';

import { LogicIcon } from '@tonkean/infrastructure';
import { createWorkerRunInfo } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason, WorkerRunStage } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const Event = styled.div<{ last?: boolean }>`
    padding-bottom: 50px;
    position: relative;

    ::before {
        background: #eaecf2;
        width: 2px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 24px;
        pointer-events: none;
        ${({ last }) =>
            last
                ? css`
                      height: 25px;
                  `
                : css`
                      bottom: 0;
                  `};
        content: '';
    }
`;

const EventIcon = styled.div<{ disabled?: boolean }>`
    margin-left: 10px;
    height: 30px;
    width: 30px;
    border: 2px solid #eaecf2;
    background: #fff;
    position: relative;
    z-index: 2;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        height: 16px;
        width: 14px;
        display: block;
        opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
    }
`;

const EventLinkContainer = styled(Clickable)<{
    disabled: boolean;
    multiple: boolean;
    selected: boolean;
}>`
    display: flex;
    align-items: ${({ multiple }) => (multiple ? 'flex-start' : 'center')};
    width: 100%;

    &,
    &:hover {
        text-decoration: none;
    }

    ${({ multiple, selected, disabled }) => {
        const colorOnHover = !multiple && !disabled;

        if (selected || colorOnHover) {
            const selector = selected ? '&' : '&:hover';
            return css`
                ${selector} {
                    ${EventIcon} {
                        border: 2px solid #2f84dc;
                    }
                }
            `;
        }

        return '';
    }}
`;

const EventDetails = styled.div<{ $bold: boolean; $selected: boolean; $withIcon?: boolean }>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: ${({ $withIcon }) => ($withIcon ? 14 : 54)}px;
    color: ${({ $selected }) => ($selected ? `#2F84DC` : Theme.colors.gray_800)};
    font-weight: ${({ $bold }) => ($bold ? 500 : 'normal')};
`;

const EventTitle = styled.div<{ selected?: boolean }>`
    font-size: 12px;
    line-height: 15px;
    font-weight: ${({ selected }) => (selected ? 500 : 'normal')};
    color: ${Theme.colors.gray_700};
`;

const CreatedBy = styled.div`
    margin-top: 6px;
`;

interface Props {
    onClick?: () => void;
    targetWorkerRun?: WorkerRun;
    isLast?: boolean;
    isDisabled?: boolean;
    showTitle?: boolean;
    workerRunStage: WorkerRunStage;
    isMultiple?: boolean;
    title?: React.ReactNode;
    hovered?: boolean;
}

const ChainOfEventsSingleEvent: React.FC<React.PropsWithChildren<Props>> = ({
    onClick,
    children,
    targetWorkerRun,
    isLast = false,
    isDisabled = false,
    showTitle = true,
    workerRunStage,
    isMultiple = false,
    title = isMultiple
        ? workerRunStageConfig[workerRunStage].pluralName
        : workerRunStageConfig[workerRunStage].singleName,
    hovered = false,
}) => {
    const { state, getState } = useContext(HistoryContext);

    const selected = !isDisabled && workerRunStage === state.workerRunStage;
    const isClickable = !selected && !isMultiple && !isDisabled;

    const getStateResult = useMemo(() => {
        if (targetWorkerRun?.id && targetWorkerRun?.startTime && workerRunStage && isClickable) {
            return getState({
                workerRunInfo: createWorkerRunInfo(targetWorkerRun.id, targetWorkerRun.startTime),
                workerRunStage,
            });
        }
    }, [getState, isClickable, targetWorkerRun?.id, targetWorkerRun?.startTime, workerRunStage]);

    const config: WorkerRunReasonConfigItem | undefined =
        targetWorkerRun && workerRunReasonConfig[targetWorkerRun.workerRunReason];

    return (
        <Event last={isLast}>
            <EventLinkContainer
                state={isClickable && getStateResult?.name}
                params={isClickable && getStateResult?.params}
                onClick={isClickable && onClick}
                multiple={isMultiple}
                selected={selected || hovered}
                disabled={isDisabled}
                dontChangeState
            >
                <EventIcon disabled={isDisabled}>
                    {targetWorkerRun &&
                    workerRunStage === WorkerRunStage.DATA_SOURCE &&
                    targetWorkerRun.externalActivity?.integrationType ? (
                        <LogicIcon integrationType={targetWorkerRun.externalActivity.integrationType} />
                    ) : (
                        <>{workerRunStageConfig[workerRunStage].icon}</>
                    )}
                </EventIcon>

                <EventDetails $bold={!isMultiple && selected} $selected={selected} $withIcon>
                    {showTitle && <EventTitle selected={selected}>{title}</EventTitle>}
                    {children}
                </EventDetails>
            </EventLinkContainer>
            {targetWorkerRun?.workerRunReason === WorkerRunReason.INITIATIVE_CREATED_FROM_LOGIC &&
                targetWorkerRun?.workerRunReasonData?.workerRunReasonSourceWorkerRun && (
                    <CreatedBy>
                        <EventDetails $bold={false} $selected={false}>
                            <EventTitle selected={false}>{config?.description}:</EventTitle>
                            <SingleWorkerRunModuleItemSourceWorkerRunLink
                                workerRunReasonSourceWorkerRun={
                                    targetWorkerRun.workerRunReasonData.workerRunReasonSourceWorkerRun
                                }
                            />
                        </EventDetails>
                    </CreatedBy>
                )}
        </Event>
    );
};

export default ChainOfEventsSingleEvent;
