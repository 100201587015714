import type React from 'react';

import { ReactComponent as DevIcon } from '../../images/dev.svg';
import { ReactComponent as FinanceIcon } from '../../images/finance.svg';
import { ReactComponent as ITIcon } from '../../images/HR.svg';
import { ReactComponent as HRIcon } from '../../images/IT.svg';
import { ReactComponent as LegalIcon } from '../../images/legal.svg';
import { ReactComponent as MarketingIcon } from '../../images/marketing.svg';
import { ReactComponent as SalesIcon } from '../../images/sales.svg';

import { OperationNodeType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const operationNodeTypeConfig: Record<OperationNodeType, { color: string; icon: React.ComponentType; name: string }> = {
    [OperationNodeType.MARKETING]: { name: 'Marketing', color: Theme.colors.warning, icon: MarketingIcon },
    [OperationNodeType.SALES]: { name: 'Sales', color: Theme.colors.primaryActive, icon: SalesIcon },
    [OperationNodeType.LEGAL]: { name: 'Legal', color: Theme.colors.promotion, icon: LegalIcon },
    [OperationNodeType.FINANCE]: { name: 'Finance', color: Theme.colors.success, icon: FinanceIcon },
    [OperationNodeType.PROCUREMENT]: { name: 'Procurement', color: Theme.colors.success, icon: FinanceIcon },
    [OperationNodeType.HR]: { name: 'HR', color: '#FF9B05', icon: ITIcon },
    [OperationNodeType.IT]: { name: 'IT', color: '#0B446A', icon: HRIcon },
    [OperationNodeType.DEV]: { name: 'DEV', color: '#B94289', icon: DevIcon },
    [OperationNodeType.CUSTOMER_SUCCESS]: {
        name: 'CustomerSuccess',
        color: Theme.colors.primaryActive,
        icon: SalesIcon,
    },
    [OperationNodeType.EXTERNAL]: { name: 'External', color: Theme.colors.primaryActive, icon: SalesIcon },
    [OperationNodeType.INTERNAL]: { name: 'Internal', color: Theme.colors.primaryActive, icon: SalesIcon },
    [OperationNodeType.OTHER]: { name: 'Other', color: Theme.colors.primaryActive, icon: SalesIcon },
};

export default operationNodeTypeConfig;
