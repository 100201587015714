import ManualFieldCreateOrUpdateValidator from '../actionsValidators/ManualFieldCreateOrUpdateValidator';
import ManualFieldUpdateValidator from '../actionsValidators/ManualFieldUpdateValidator';
import ManualFieldValidatorUtils from '../actionsValidators/ManualFieldValidatorUtils';
import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';
import addFormAnsweredForIntegrationActionCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addFormAnsweredForIntegrationActionCreateAfterOption';
import {
    validateAdobesignCustomActions,
    validateAmazonS3CustomActions,
    validateAsanaCustomActions,
    validateBoxCustomActions,
    validateCloudConvertCustomActions,
    validateCoupaCustomActions,
    validateCustomHttpActionFields,
    validateDocusignCustomActions,
    validateDropboxCustomActions,
    validateEvisortCustomActions,
    validateFrontappCustomActions,
    validateGmailCustomActions,
    validateGoodTimeCustomActions,
    validateGoogleCloudStorageCustomActions,
    validateGoogleDriveCustomActions,
    validateGoogleSheetsCustomActions,
    validateGoogleSlidesCustomActions,
    validateIntercomCustomActions,
    validateLeverCustomActions,
    validateNetsuiteCustomActions,
    validateSalesforceCustomActions,
    validateSendGridCustomActions,
    validateSharePointCustomActions,
    validateSlackAppCustomActions,
    validateSpringCMCustomActions,
    validateTrelloCustomActions,
    validateTwilioChatCustomActions,
    validateTwilioSMSCustomActions,
    validateUiPathCustomActions,
    validateZendeskCustomActions,
} from '../sharedConfigComponents/validators/integrations/integrationsValidations';
import outgoingWebhookValidator from '../sharedConfigComponents/validators/outgoingWebhookValidator';

import type { ActionParameterExpression, FullProject, Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { ActionParameterType, IntegrationActionType, LogicBlockConfigType } from '@tonkean/tonkean-entities';

const PerformIntegrationActionLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.PERFORM_INTEGRATION_ACTION,
    title: 'Create/Update External Item',
    iconClass: 'mod-action',
    description: '',
    dontSaveOnSelect: true,
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    createAfterOptions: () => [addFormAnsweredForIntegrationActionCreateAfterOption, addNewLogicOption],
    getLinkedFieldDefinitionsConfiguration: (integrationsConsts, logic) => {
        if (logic?.customTriggerActions?.length) {
            const firstAction = logic.customTriggerActions[0];

            if (
                firstAction.customTriggerActionDefinition?.performedActionType &&
                firstAction.customTriggerActionDefinition.integrationType
            ) {
                const integrationConfiguration =
                    integrationsConsts.getActionsByIntegrationsMap()[
                        firstAction.customTriggerActionDefinition.integrationType
                    ];

                switch (firstAction.customTriggerActionDefinition.performedActionType) {
                    case 'CREATE_NEW_ITEM':
                        if (
                            integrationConfiguration.createNewItem &&
                            firstAction.customTriggerActionDefinition.performOnEntity?.entity
                        ) {
                            const entity = firstAction.customTriggerActionDefinition.performOnEntity.entity;
                            if (integrationConfiguration.createNewItem[entity]?.linkedFieldDefinitionsConfiguration) {
                                return integrationConfiguration.createNewItem[entity]
                                    .linkedFieldDefinitionsConfiguration;
                            } else if (
                                firstAction.customTriggerActionDefinition.performedActionDefinition.entityMetadata
                            ) {
                                return (
                                    firstAction.customTriggerActionDefinition.performedActionDefinition.entityMetadata
                                        .linkedFieldDefinitionsConfiguration || []
                                );
                            }
                        }
                        break;

                    case 'CREATE_OR_UPDATE_ITEM':
                        if (
                            integrationConfiguration.createOrUpdateItem &&
                            firstAction.customTriggerActionDefinition.performOnEntity?.entity
                        ) {
                            return (
                                integrationConfiguration.createOrUpdateItem[
                                    firstAction.customTriggerActionDefinition.performOnEntity.entity
                                ].linkedFieldDefinitionsConfiguration || []
                            );
                        }
                        break;

                    case 'CUSTOM_ACTIONS':
                        if (
                            firstAction.customTriggerActionDefinition.performedActionDefinition?.customActionKey &&
                            integrationConfiguration.customActions
                        ) {
                            const customActionConfiguration =
                                integrationConfiguration.customActions[
                                    firstAction.customTriggerActionDefinition.performedActionDefinition.customActionKey
                                ];
                            if (customActionConfiguration) {
                                return customActionConfiguration.linkedFieldDefinitionsConfiguration || [];
                            }
                        }
                        break;
                }
            }
        }

        return [];
    },
    validatorAsync: async (params, featureFlags) => {
        const {
            definition,
            integrationsConsts,
            group,
            syncConfigCacheManager,
            workflowVersionInfoRetriever,
            formInfoRetriever,
            project,
            projectManager,
            tonkeanService,
        } = params;
        const validationObject: LogicBlockValidationObject = {};

        switch (definition.performedActionType) {
            case 'CREATE_NEW_ITEM': {
                ManualFieldValidatorUtils.integrationFieldCreateValidator(definition, validationObject);

                break;
            }
            case 'CREATE_OR_UPDATE_ITEM': {
                ManualFieldCreateOrUpdateValidator.validateAction(definition, validationObject, projectManager);

                break;
            }
            case 'MANUAL_FIELD_UPDATE': {
                ManualFieldUpdateValidator.validateAction(definition, validationObject, projectManager);

                break;
            }
            default:
                if (definition.performedActionDefinition.isCustomHttpAction) {
                    validateCustomHttpActionFields(validationObject, definition, integrationsConsts);
                } else {
                    switch (definition.performedActionType) {
                        case 'CUSTOM_ACTIONS': {
                            switch (definition.integrationType) {
                                case 'INTERCOM': {
                                    validateIntercomCustomActions(
                                        validationObject,
                                        definition,
                                        integrationsConsts,
                                        group,
                                        formInfoRetriever,
                                    );

                                    break;
                                }
                                case 'FRONTAPP': {
                                    validateFrontappCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'ZENDESK': {
                                    validateZendeskCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'GOOGLEDRIVE': {
                                    validateGoogleDriveCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        syncConfigCacheManager,
                                        group,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'GOOGLECLOUDSTORAGE': {
                                    validateGoogleCloudStorageCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        syncConfigCacheManager,
                                        group,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'GOOGLESHEETS': {
                                    validateGoogleSheetsCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        syncConfigCacheManager,
                                        group,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'DROPBOX': {
                                    validateDropboxCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'TWILIOCHAT': {
                                    validateTwilioChatCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'GMAIL': {
                                    validateGmailCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'SENDGRID': {
                                    validateSendGridCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'TRELLO': {
                                    validateTrelloCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'SLACK_APP': {
                                    validateSlackAppCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'ASANA': {
                                    validateAsanaCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'DOCUSIGN': {
                                    validateDocusignCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'TWILIOSMS': {
                                    validateTwilioSMSCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'SALESFORCE': {
                                    validateSalesforceCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'SPRINGCM': {
                                    validateSpringCMCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'GOODTIME': {
                                    validateGoodTimeCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'SHAREPOINT': {
                                    validateSharePointCustomActions(validationObject, definition);

                                    break;
                                }
                                case 'AMAZONS3': {
                                    validateAmazonS3CustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'BOX': {
                                    validateBoxCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );

                                    break;
                                }
                                case 'LEVER': {
                                    validateLeverCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                    );

                                    break;
                                }
                                case 'UIPATH': {
                                    validateUiPathCustomActions(validationObject, definition);
                                    break;
                                }
                                case 'EVISORT': {
                                    validateEvisortCustomActions(validationObject, definition);
                                    break;
                                }
                                case 'GOOGLESLIDES': {
                                    validateGoogleSlidesCustomActions(validationObject, definition);
                                    break;
                                }
                                case 'CLOUDCONVERT': {
                                    validateCloudConvertCustomActions(validationObject, definition);
                                    break;
                                }
                                case 'ADOBESIGN': {
                                    validateAdobesignCustomActions(
                                        validationObject,
                                        definition,
                                        workflowVersionInfoRetriever,
                                        group,
                                        syncConfigCacheManager,
                                        projectManager,
                                    );
                                    break;
                                }
                                case 'COUPA': {
                                    validateCoupaCustomActions(validationObject, definition);
                                    break;
                                }
                                case 'NETSUITE': {
                                    validateNetsuiteCustomActions(validationObject, definition);
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }

                            break;
                        }
                        case 'GENERIC_INTEGRATION_ACTION': {
                            const outgoingWebhookParams = {
                                ...params,
                                definition: definition.performedActionDefinition,
                            };
                            return outgoingWebhookValidator(outgoingWebhookParams);
                        }
                        case IntegrationActionType.PROJECT_INTEGRATION_ACTION: {
                            // As of now we do not validate any of the data entered in the action it self.
                            // (We still did not implement the mandatory fields logic)

                            const projectIntegrationAction = await tonkeanService.getProjectIntegrationActionById(
                                definition.projectIntegrationId,
                                definition.performedActionDefinition.projectIntegrationActionId,
                            );

                            const entityToRunOn = projectIntegrationAction.parametersDefinition?.entitiesToRunOn[0];

                            let parameters: ActionParameterExpression[] = [];
                            let requiredPredefinedParameters: ActionParameterExpression[] = [];

                            if (entityToRunOn) {
                                parameters = await tonkeanService.getProjectIntegrationActionParameters(
                                    definition.projectIntegrationId,
                                    definition.performedActionDefinition.projectIntegrationActionId,
                                );

                                requiredPredefinedParameters = parameters
                                    .filter(
                                        (parameter) =>
                                            !parameter.projectIntegrationEntityId || // Simple Action Parameters do not contain projectIntegrationEntityId
                                            parameter.projectIntegrationEntityId === entityToRunOn, // Custom params should be included only if their projectIntegrationEntityId matches the entitiesToRunOn of the projectIntegrationAction,
                                    )
                                    .filter((parameter) => parameter.isRequired);
                            } else {
                                requiredPredefinedParameters = projectIntegrationAction.parametersDefinition.parameters
                                    .filter(
                                        (parameter) =>
                                            parameter.parameterType === ActionParameterType.TONKEAN_EXPRESSION,
                                    )
                                    .filter(
                                        (parameter: ActionParameterExpression) => parameter.isRequired,
                                    ) as ActionParameterExpression[];
                            }

                            for (const parameter of requiredPredefinedParameters) {
                                if (
                                    !definition.performedActionDefinition.parametersValues[parameter.id] ||
                                    definition.performedActionDefinition.parametersValues[parameter.id]
                                        .originalExpression === ''
                                ) {
                                    validationObject.requiredFields = 'Must fill all required fields.';
                                    break;
                                }
                            }

                            if (
                                projectIntegrationAction.parametersDefinition.isChildEntitiesEnabled &&
                                !!definition.performedActionDefinition.childParametersValues &&
                                projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions
                            ) {
                                const requiredFieldsIdsPerKey: Record<string, string[]> = {};
                                projectIntegrationAction.parametersDefinition.childEntitiesParameterDefinitions.forEach(
                                    (childEntity) => {
                                        let childEntityCustomFieldParameters: ActionParameterExpression[] = [];

                                        if (parameters.length > 0) {
                                            const childEntityToRunOn = childEntity.entitiesToRunOn[0];

                                            if (childEntityToRunOn) {
                                                childEntityCustomFieldParameters = parameters.filter(
                                                    (parameter) =>
                                                        parameter.projectIntegrationEntityId === childEntityToRunOn,
                                                );
                                            }
                                        }

                                        const requiredFieldsIds: string[] = [];
                                        [...childEntity.parameters, ...childEntityCustomFieldParameters].forEach(
                                            (parameter: ActionParameterExpression) => {
                                                if (parameter.isRequired) {
                                                    requiredFieldsIds.push(parameter.id);
                                                }
                                            },
                                        );

                                        requiredFieldsIdsPerKey[childEntity.entityKey] = requiredFieldsIds;
                                    },
                                );

                                for (const key of Object.keys(
                                    definition.performedActionDefinition.childParametersValues,
                                )) {
                                    const keyRequiredParameters = requiredFieldsIdsPerKey[key];
                                    if (keyRequiredParameters) {
                                        for (const parameterId of keyRequiredParameters) {
                                            if (
                                                !definition.performedActionDefinition.childParametersValues[key][
                                                    parameterId
                                                ] ||
                                                definition.performedActionDefinition.childParametersValues[key][
                                                    parameterId
                                                ].originalExpression === ''
                                            ) {
                                                validationObject.childParametersRequiredFields =
                                                    'Must fill all required fields.';
                                                break;
                                            }
                                        }
                                        if (validationObject.childParametersRequiredFields) {
                                            break;
                                        }
                                    }
                                }
                            }
                            break;
                        }
                        default: {
                            validationObject.noExternalAction = 'Must select action.';
                        }
                    }
                }
        }

        // Validate that action runs on the same type as the sync, and if not that there is a matching
        if (
            definition.performedActionType === 'MANUAL_FIELD_UPDATE' ||
            definition.performedActionType === 'CUSTOM_ACTIONS' ||
            definition.performedActionType === 'PROJECT_INTEGRATION_ACTION'
        ) {
            const isNotGmail = definition.integrationType !== 'GMAIL';
            const isReplyToThread =
                !definition.performedActionDefinition ||
                (definition.performedActionDefinition.customActionKey === 'SEND_EMAIL' &&
                    definition.performedActionDefinition.isReplyToThread);

            if (isNotGmail || isReplyToThread) {
                const actionProjectIntegration = projectManager.getProjectIntegrationById(
                    definition.projectIntegrationId,
                );

                const draftVersionFromCache = workflowVersionInfoRetriever.getDraftVersionFromCache(group.id);

                const storageProviderIntegrationId = definition.performedActionDefinition?.source?.storageIntegrationId;

                const storageProjectIntegration = await getStorageProjectIntegration(
                    group,
                    storageProviderIntegrationId,
                    project,
                    projectManager,
                );

                const isDifferentIntegrationThanSync =
                    definition.integrationType === 'NO_CODE_DATA_SOURCE'
                        ? checkStorageProviderWithSyncIntegration(
                              storageProjectIntegration,
                              syncConfigCacheManager,
                              draftVersionFromCache?.id,
                          )
                        : draftVersionFromCache?.dataSourceType !== 'INTEGRATION' ||
                          syncConfigCacheManager.getSyncConfig(draftVersionFromCache.id).viewData.integrationType !==
                              definition.integrationType ||
                          (actionProjectIntegration &&
                              actionProjectIntegration.integrationUniqueType !==
                                  syncConfigCacheManager.getSyncConfig(draftVersionFromCache.id).projectIntegration
                                      .integrationUniqueType) ||
                          (definition.performOnEntity?.entity &&
                              syncConfigCacheManager.getSyncConfig(draftVersionFromCache.id).viewData.entity &&
                              syncConfigCacheManager
                                  .getSyncConfig(draftVersionFromCache.id)
                                  .viewData.entity.toLowerCase() !== definition.performOnEntity.entity.toLowerCase());

                let matchConfiguration = definition.matchConfiguration;
                if (!isNotGmail) {
                    matchConfiguration = definition.performedActionDefinition.replyToEmailMatchConfiguration;
                } else if (definition.extendedMatchConfiguration) {
                    matchConfiguration =
                        definition.extendedMatchConfiguration.extendedMatchConfigurationType === 'WORKFLOW'
                            ? definition.extendedMatchConfiguration.matchConfiguration
                            : {};
                }

                const runOnRootMonitoredItem = !matchConfiguration || matchConfiguration.performOnWorkerItem;
                if (isDifferentIntegrationThanSync && runOnRootMonitoredItem) {
                    validationObject.cantBeRootMatch =
                        "Actions that operate on a different type than the Data Source can't be matched to the Root Monitored Item";
                }
            }
        }

        // Validate whether the integration is valid or not.
        if (definition.projectIntegrationId) {
            const projectIntegration =
                group?.projectIntegrationIdDict?.[definition.projectIntegrationId] ||
                project?.projectIntegrationIdDict?.[definition.projectIntegrationId] ||
                projectManager.getProjectIntegrationById(definition.projectIntegrationId);

            if (!projectIntegration) {
                validationObject.integrationIsBroken = `Data source has been deleted.`;
            } else if (!projectIntegration.valid) {
                validationObject.integrationIsBroken = `Data source "${projectIntegration.displayName}" is broken, please go to data sources and fix it before running the module.`;
            } else if (projectIntegration.disabled) {
                validationObject.integrationIsBroken = `Data source "${projectIntegration.displayName}" has been disabled.`;
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

function checkStorageProviderWithSyncIntegration(
    storageProjectIntegration,
    syncConfigCacheManager,
    draftVersionFromCacheId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
) {
    if (!storageProjectIntegration) {
        return false;
    }

    return (
        storageProjectIntegration?.integrationUniqueType !==
            syncConfigCacheManager.getSyncConfig(draftVersionFromCacheId)?.projectIntegration.integrationUniqueType &&
        storageProjectIntegration?.integrationType !==
            syncConfigCacheManager.getSyncConfig(draftVersionFromCacheId)?.projectIntegration.integrationType
    );
}

async function getStorageProjectIntegration(
    group: Group,
    storageProviderIntegrationId,
    project: FullProject,
    projectManager,
) {
    if (!storageProviderIntegrationId) {
        return undefined;
    }

    let storageProjectIntegration =
        group?.projectIntegrationIdDict?.[storageProviderIntegrationId] ||
        project?.projectIntegrationIdDict?.[storageProviderIntegrationId] ||
        projectManager.getProjectIntegrationById(storageProviderIntegrationId);

    if (!storageProjectIntegration) {
        storageProjectIntegration =
            await projectManager.getProjectIntegrationByIdWithServerFallback(storageProviderIntegrationId);
    }

    return storageProjectIntegration;
}

export default PerformIntegrationActionLogicBlockConfig;
