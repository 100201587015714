import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as FlowRunIcon } from '../../../../../images/icons/history/flow-run.svg';
import HistoryContext from '../../entities/HistoryContext';
import getWorkerRunFullDescription from '../../utils/getWorkerRunFullDescription';

import { TextEllipsis } from '@tonkean/infrastructure';
import { createWorkerRunInfo, getWorkerRunInfo } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const FlowRunEventLink = styled(Clickable)<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 12px;
    text-decoration: ${({ selected }) => (selected ? 'none' : 'underline')};
    color: ${({ selected }) => (selected ? '#2F84DC' : Theme.colors.gray_700)};
    margin-top: 15px;
    margin-right: 30px;

    &:hover,
    &:active,
    &:focus {
        color: #2f84dc;
    }
`;

const EventLinksListIcon = styled.div<{ selected?: boolean }>`
    display: flex;
    margin-right: 5px;
    flex-shrink: 0;

    ${({ selected }) => selected && colorSvg('#2F84DC')};
`;

interface Props {
    workerRun: WorkerRun;
    onHover: (isHovering: boolean) => void;
    onSelect: () => void;
}

const ChainOfEventsSingleFlowRunEvent: React.FC<React.PropsWithChildren<Props>> = ({
    workerRun,
    onHover,
    onSelect,
    children,
}) => {
    const { state, getState } = useContext(HistoryContext);
    const getStateResult = useMemo(
        () =>
            getState({
                workerRunInfo: createWorkerRunInfo(workerRun.id, workerRun.startTime),
                workerRunStage: workerRun.workerRunStage,
            }),
        [getState, workerRun.id, workerRun.startTime, workerRun.workerRunStage],
    );

    const isSelected = getWorkerRunInfo(state.workerRunInfo)?.id === workerRun.id;

    return (
        <FlowRunEventLink
            state={!isSelected && getStateResult.name}
            params={!isSelected && getStateResult.params}
            onClick={!isSelected && onSelect}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            selected={isSelected}
            dontChangeState
        >
            <EventLinksListIcon selected={isSelected}>
                <FlowRunIcon />
            </EventLinksListIcon>
            <TextEllipsis tooltip>
                {getWorkerRunFullDescription(workerRun)}
                {workerRun.workerRunReason === WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE &&
                    ` - ${workerRun.resumingText}`}
            </TextEllipsis>
        </FlowRunEventLink>
    );
};

export default ChainOfEventsSingleFlowRunEvent;
