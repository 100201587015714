import React, { useContext } from 'react';

import type { WidgetPackage } from '../WidgetModule/entities/ItemWidgetPackage';
import type { WidgetsPackageMap } from '../widgets/itemInterfaceWidgetsPackageMap';

import type {
    IconsByCategory,
    IncludedWidgetsSummaryByInterface,
    ItemInterface,
    ItemInterfaceLoadingState,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

type InferredWidgetPackageTypes<T> =
    T extends WidgetPackage<infer CONFIG, infer PARENT, infer WIDGET, infer WIDGET_PROPS>
        ? [CONFIG, PARENT, WIDGET, WIDGET_PROPS]
        : never;

/**
 * Context for the editing of item interface
 */
export interface ItemInterfaceEditorContextData<
    PACKAGE extends WidgetPackage,
    PARAMS extends InferredWidgetPackageTypes<PACKAGE> = InferredWidgetPackageTypes<PACKAGE>,
    PARENT extends PARAMS[1] = PARAMS[1],
    WIDGET extends PARAMS[2] = PARAMS[2],
> {
    updateItemInterface(itemInterface: ItemInterface): void;

    itemInterfaceState: ItemInterfaceLoadingState;

    itemInterfaceWidgets: WIDGET[];
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;

    includedWidgetsSummaryByInterfaceId?: IncludedWidgetsSummaryByInterface;

    widgetsPackageMap: WidgetsPackageMap[PARENT];

    createItemInterfaceWidget(itemInterfaceWidget: WIDGET): Promise<WIDGET>;

    duplicateItemInterfaceWidget(itemInterfaceWidget: WIDGET): Promise<WIDGET>;

    updateItemInterfaceWidget(itemInterfaceWidget: WIDGET, forceUpdateOnServerResult?: boolean): Promise<WIDGET>;

    updateItemInterfaceLogo(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>, logo?: Blob): Promise<void>;

    deleteItemInterfaceWidget(itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>): Promise<void>;

    deleteItemInterfaceLogo(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>): Promise<void>;

    swapItemInterfaceWidgetIndices(firstWidget: WIDGET, secondWidget: WIDGET): Promise<void>;

    reloadItemInterfaceWidgets(): void;

    selectedItemInterfaceWidget?: WIDGET;

    setItemInterfaceDefault(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>, isDefault: boolean): void;

    loadingUpdateItemInterfaceDefault?: boolean;

    predefinedIcons: IconsByCategory[];
}

const ItemInterfaceEditorContext = React.createContext<any>(undefined);
export const ItemInterfaceEditorContextProvider = ItemInterfaceEditorContext.Provider;

export default function useItemInterfaceEditorContext<T extends WidgetPackage>() {
    const contextValue = useContext(ItemInterfaceEditorContext) as ItemInterfaceEditorContextData<T>;

    if (!contextValue) {
        throw new Error('component must be wrapped with ItemInterfaceEditorContextProvider');
    }

    return contextValue;
}
