import { useMemo } from 'react';

import type { InterfaceSubmitValidationContextProps } from '../../../../utils/InterfaceSubmitValidationContext';

import { Validation } from '@tonkean/tonkean-entities';

const useActiveValidationsErrors = (interfaceSubmitValidationContext: InterfaceSubmitValidationContextProps) => {
    return useMemo(() => {
        return (
            interfaceSubmitValidationContext.forceShowValidation &&
            interfaceSubmitValidationContext.validation === Validation.INVALID
        );
    }, [interfaceSubmitValidationContext.forceShowValidation, interfaceSubmitValidationContext.validation]);
};

export default useActiveValidationsErrors;
