<div class="tnk-netsuite-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            info-message="'Maximum document size is 9MB'"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">SuiteScript ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="SuiteScript ID"
                saved-expression="data.definition.suiteScriptIdExpression"
                on-tonkean-expression-changed="onSuiteScriptIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.netsuiteScriptIdError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.netsuiteScriptIdError }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">SuiteScript Deployment Version:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="SuiteScript Deployment Version"
                saved-expression="data.definition.suiteScriptDeployVersionExpression"
                on-tonkean-expression-changed="
                    onSuiteScriptDeployVersionTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.netsuiteScriptDeployVersionError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.netsuiteScriptDeployVersionError }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                File Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="File name must include the extension."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File Name"
                saved-expression="data.definition.fileNameExpression"
                on-tonkean-expression-changed="onFileNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.netsuiteFileNameError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.netsuiteFileNameError }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">Target Folder ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Folder ID"
                saved-expression="data.definition.targetFolderIdExpression"
                on-tonkean-expression-changed="onTargetFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.netsuiteFolderIdError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.netsuiteFolderIdError }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">Description:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Description"
                saved-expression="data.definition.fileDescriptionExpression"
                on-tonkean-expression-changed="onFileDescriptionTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.netsuiteDescriptionError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.netsuiteDescriptionError }}
        </div>

        <!-- Action instructions -->
        <div class="common-size-xxxs margin-top-md info-box">
            <div class="common-color-dark-grey common-bold">Action instructions:</div>
            <div class="common-color-light-grey">
                <ol class="padding-left-md">
                    <li class="margin-bottom-xs margin-top-xs">
                        Make sure the SuiteScript feature is enabled.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                Select Setup > Company > Enable Features and click on the Suite Cloud tab.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Locate the Client SuiteScript option and check the box.
                            </li>
                        </ol>
                    </li>
                    <li class="margin-bottom-xs">
                        Copy and save the script below as a JavaScript file (".js")
                        <br />
                        <div>
                            <pre class="common-size-xxxxs" style="white-space: pre-wrap; height: 200px">
								<code id="input-script-upload" title="Script">
/**
* @NApiVersion 2.x
* @NScriptType Restlet
* @NModuleScope Public
*/

var file,
	log,
	query,
	record;

define( [ 'N/file', 'N/log', 'N/query', 'N/record' ], main );

function main( fileModule, logModule, queryModule, recordModule ) {
	file = fileModule;
	log = logModule;
	query = queryModule;
	record = recordModule;
    return {
        post: postProcess
    }
}

function postProcess( request ) {
	if ( ( typeof request.function == 'undefined' ) || ( request.function == '' ) ) {
		return { 'error': 'No function was specified.' }
	}
	switch ( request.function ) {
		case 'fileCreate':
			return fileCreate( request )
			break;
		case 'folderCreate':
			return folderCreate( request )
			break;
		default:
			var response = { 'error': 'Unsupported Function' }
			return response;
	}
}

function fileCreate( request ) {
	// Validate the request...
	if ( typeof request.name == 'undefined' ) {
		return { 'error': 'No name was specified.' }
	}
	if ( typeof request.fileType == 'undefined' ) {
		return { 'error': 'No fileType was specified.' }
	}
	if ( typeof request.contents == 'undefined' ) {
		return { 'error': 'No content was specified.' }
	}
	if ( typeof request.description == 'undefined' ) {
		return { 'error': 'No description was specified.' }
	}
	if ( typeof request.encoding == 'undefined' ) {
		return { 'error': 'No encoding was specified.' }
	}
	if ( typeof request.folderID == 'undefined' ) {
		return { 'error': 'No folderID was specified.' }
	}
	if ( typeof request.isOnline == 'undefined' ) {
		return { 'error': 'No isOnline was specified.' }
	}

	// Load the file.
	try {
		var fileObj = file.create(
			{
				name: request.name,
				fileType: request.fileType,
				contents: request.contents,
				description: request.description,
				encoding: request.encoding,
				folder: request.folderID,
				isOnline: request.isOnline
    		}
		);

		// Save the file and get its ID.
		var fileID = fileObj.save();

		// Load the file.
		fileObj = file.load( { id: fileID } );

		// Create the response.
		var response = {};
		response['info'] = fileObj;
		response['content'] = fileObj.getContents();
		return response;
	} catch (e) {
		return { 'error': e }
	}
}

function folderCreate( request ) {
	// If no folder name was specified...
	if ( typeof request.name == 'undefined' ) {
		return { 'error': 'No name was specified.' }
	}

	// Create the folder record.
	var objRecord = record.create(
		{
			type: record.Type.FOLDER,
			isDynamic: true
		}
	);

	// Set the folder name.
	objRecord.setValue( { fieldId: 'name', value: request.name } );

	// If this is a subfolder...
	if ( typeof request.parent !== 'undefined' ) {
		objRecord.setValue( { fieldId: 'parent', value: request.parent } );
	}

	// Save the record.
	var folderId = objRecord.save();

	// Get the record.
	result = record.load( { type: record.Type.FOLDER, id: folderId, isDynamic: false } );
	return result;
}
                                </code>
							</pre>
                            <button
                                class="btn btn-secondary btn-slim"
                                ng-click="copyToClipboard('input-script-upload')"
                                type="button"
                            >
                                Copy
                            </button>
                        </div>
                    </li>
                    <li class="margin-bottom-xs margin-top-xs">
                        Upload the script file to NetSuites.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                In the NetSuite UI, go to Documents > File > SuiteScripts.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                In the left pane, select the SuiteScripts folder and click Add File.
                            </li>
                        </ol>
                    </li>
                    <li class="margin-bottom-xs margin-top-xs">
                        Deploy the script and grab the script ID.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                In the NetSuite UI, go to Customization > Scripting > Scripts > New.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Choose the uploaded script file and create the script record.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Enter a name, click Save and deploy the script.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Choose the 'Released' Status, select the Roles and Employees audience and click Save.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                You can then copy the script ID and deployment version from the generated URL.
                            </li>
                        </ol>
                    </li>
                </ol>
                <div>For more information please contact our support team.</div>
            </div>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ATTACH_FILE'" class="margin-bottom-xlg">

        <div class="margin-top">
            <div class="margin-bottom-xs">SuiteScript ID:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="SuiteScript ID"
                    saved-expression="data.definition.suiteScriptIdExpression"
                    on-tonkean-expression-changed="onSuiteScriptIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">SuiteScript Deployment Version:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="SuiteScript Deployment Version"
                    saved-expression="data.definition.suiteScriptDeployVersionExpression"
                    on-tonkean-expression-changed="
                    onSuiteScriptDeployVersionTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">File ID:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="File ID"
                    saved-expression="data.definition.fileIdExpression"
                    on-tonkean-expression-changed="onFileIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                Transaction Type:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="The table's name. For example: 'purchaseorder'"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Transaction Type"
                    saved-expression="data.definition.transactionTypeExpression"
                    on-tonkean-expression-changed="onTransactionTypeTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Action instructions -->
        <div class="common-size-xxxs margin-top-md info-box">
            <div class="common-color-dark-grey common-bold">Action instructions:</div>
            <div class="common-color-light-grey">
                <ol class="padding-left-md">
                    <li class="margin-bottom-xs margin-top-xs">
                        Make sure the SuiteScript feature is enabled.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                Select Setup > Company > Enable Features and click on the Suite Cloud tab.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Locate the Client SuiteScript option and check the box.
                            </li>
                        </ol>
                    </li>
                    <li class="margin-bottom-xs">
                        Copy and save the script below as a JavaScript file (".js")
                        <br />
                        <div>
                            <pre class="common-size-xxxxs" style="white-space: pre-wrap; height: 200px">
								<code id="input-script-attach" title="Script">
/**
* @NApiVersion 2.x
* @NScriptType Restlet
* @NModuleScope Public
*/

var file,
	log,
	query,
	record;

define( [ 'N/file', 'N/log', 'N/query', 'N/record'], main );

function main( fileModule, logModule, queryModule, recordModule ) {
	file = fileModule;
	log = logModule;
	query = queryModule;
	record = recordModule;
    return {
        post: attachRecord
    }
}

function attachRecord(request)
{

	var response = record.attach({
	    record: {
	        type: 'file',
	        id: request.fileID
	    },
	    to: {
	        type: request.recordType,
	        id: request.recordID
	    }
	});

	return response;
}
                                </code>
							</pre>
                            <button
                                    class="btn btn-secondary btn-slim"
                                    ng-click="copyToClipboard('input-script-attach')"
                                    type="button"
                            >
                                Copy
                            </button>
                        </div>
                    </li>
                    <li class="margin-bottom-xs margin-top-xs">
                        Upload the script file to NetSuites.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                In the NetSuite UI, go to Documents > File > SuiteScripts.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                In the left pane, select the SuiteScripts folder and click Add File.
                            </li>
                        </ol>
                    </li>
                    <li class="margin-bottom-xs margin-top-xs">
                        Deploy the script and grab the script ID.
                        <ol class="padding-left-md" type="a">
                            <li class="margin-bottom-xs margin-top-xs">
                                In the NetSuite UI, go to Customization > Scripting > Scripts > New.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Choose the uploaded script file and create the script record.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Enter a name, click Save and deploy the script.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                Choose the 'Released' Status, select the Roles and Employees audience and click Save.
                            </li>
                            <li class="margin-bottom-xs margin-top-xs">
                                You can then copy the script ID and deployment version from the generated URL.
                            </li>
                        </ol>
                    </li>
                </ol>
                <div>For more information please contact our support team.</div>
            </div>
        </div>
    </div>
</div>
