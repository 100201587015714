import React, { useContext } from 'react';
import styled from 'styled-components';

import SQLDatabaseExplanationSubtitle from './SQLDatabaseExplanationSubtitle';
import SQLDatabaseFormSizedInput from './SQLDatabaseFormSizedInput';
import SQLDatabaseInnerMiddleSectionTitle from './SQLDatabaseInnerMiddleSectionTitle';
import SQLDatabaseIntegrationConfigurationContext from './SQLDatabaseIntegrationConfigurationContext';
import SQLDatabaseItalicText from './SQLDatabaseItalicText';
import SQLDatabaseMultiMiddleSectionTitle from './SQLDatabaseMultiMiddleSectionTitle';
import SQLDatabaseSectionTitle from './SQLDatabaseSectionTitle';
import SQLDatabaseToggledSectionTitle from './SQLDatabaseToggledSectionTitle';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

import { Toggle } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const PaginationError = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.error};
    margin-top: 6px;
    white-space: pre;
`;

const SQLDatabaseQueryConfigurationPaginationSection: React.FC<{
    queryConfiguration: SQLDatabaseQueryConfiguration;
    index: number;
}> = ({ queryConfiguration, index }) => {
    const { changePaginationEnabled, changePagesAmount, changePageSize } = useContext(
        SQLDatabaseIntegrationConfigurationContext,
    );

    return (
        <>
            <SQLDatabaseMultiMiddleSectionTitle>
                <SQLDatabaseToggledSectionTitle>Enable Pagination</SQLDatabaseToggledSectionTitle>
                <Toggle
                    checked={queryConfiguration.paginationEnabled}
                    onChange={() => changePaginationEnabled(!queryConfiguration.paginationEnabled, index)}
                    size={InputSize.MEDIUM}
                />
            </SQLDatabaseMultiMiddleSectionTitle>

            {queryConfiguration.paginationEnabled && (
                <>
                    <SQLDatabaseExplanationSubtitle>
                        In order to incorporate pagination in your query, use{' '}
                        <SQLDatabaseItalicText>&#123;&#123;Offset&#125;&#125;</SQLDatabaseItalicText> and&nbsp;
                        <SQLDatabaseItalicText>&#123;&#123;Limit&#125;&#125;</SQLDatabaseItalicText> placeholders.
                    </SQLDatabaseExplanationSubtitle>

                    <SQLDatabaseSectionTitle>Amount of pages</SQLDatabaseSectionTitle>

                    <SQLDatabaseFormSizedInput
                        type="number"
                        min="1"
                        max="1000"
                        value={queryConfiguration.pagesAmount}
                        className="form-control"
                        onChange={({ target }) => changePagesAmount(target.value, index)}
                        autoComplete="off"
                        placeholder="Amount of pages"
                        required
                    />

                    <SQLDatabaseInnerMiddleSectionTitle>Page size</SQLDatabaseInnerMiddleSectionTitle>

                    <SQLDatabaseFormSizedInput
                        type="number"
                        min={1}
                        max={1000}
                        value={queryConfiguration.pageSize}
                        className="form-control"
                        onChange={({ target }) => changePageSize(target.valueAsNumber, index)}
                        autoComplete="off"
                        placeholder="Page size"
                        required
                    />
                </>
            )}

            {queryConfiguration.pagingValidationError && (
                <PaginationError>{queryConfiguration.pagingValidationError}</PaginationError>
            )}
        </>
    );
};

export default SQLDatabaseQueryConfigurationPaginationSection;
