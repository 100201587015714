import * as Yup from 'yup';
import type { RequiredObjectSchema, TypeOfShape } from 'yup/lib/object';

import defaultTonkeanExpression from '../../FormBuilder/entities/DefaultTonkeanExpression';

import {
    EntityResponseHandlingDefinitionType,
    ProjectIntegrationActionResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { yupEnum, yupTonkeanExpression } from '@tonkean/utils';

export const singleHandleResponseSchema = Yup.object({}).required().when(getSchema) as ReturnType<typeof getSchema>;

const subEntitySchema = Yup.object().shape({
    entityId: Yup.string().required('Must select an entity'),
    responseHandlingDefinition: singleHandleResponseSchema,
});

const baseResponseHandlingDefinitionSchema = Yup.object().shape({
    entity: yupTonkeanExpression(),
    entityFlatteningPath: yupTonkeanExpression(),
    validationStatus: Yup.object({
        outputFields: Yup.array().required(),
        isValid: Yup.boolean(),
    }).required(),
    id: Yup.object().when(['validationStatus'], {
        is: (validationStatus) => validationStatus.isValid,
        then: yupTonkeanExpression('Id is required'),
        otherwise: yupTonkeanExpression(),
    }),
    subEntities: Yup.array().of(subEntitySchema).required(),
    responseHandlingType: yupEnum(EntityResponseHandlingDefinitionType).required(),
});

const externalActivitiesIdsResponseHandlingDefinitionSchema = Yup.object({}).shape({
    fetcherActionId: Yup.string(),
});

const externalActivitiesResponseHandlingDefinitionSchema = Yup.object({}).shape({
    createdDate: yupTonkeanExpression().required(),
    updatedDate: yupTonkeanExpression().required(),
    displayName: yupTonkeanExpression().required(),
    entityUrl: yupTonkeanExpression().required(),
    dynamicParams: Yup.array().of(Yup.object()),
});

function getSchema(
    definition: ProjectIntegrationActionResponseHandlingDefinition,
    schema: RequiredObjectSchema<{}, Record<string, any>, TypeOfShape<{}>>,
) {
    if (!definition) {
        return baseResponseHandlingDefinitionSchema.concat(externalActivitiesResponseHandlingDefinitionSchema);
    }

    switch (definition.responseHandlingType) {
        case EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES: {
            return baseResponseHandlingDefinitionSchema.concat(externalActivitiesResponseHandlingDefinitionSchema);
        }
        case EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES_IDS: {
            return baseResponseHandlingDefinitionSchema.concat(externalActivitiesIdsResponseHandlingDefinitionSchema);
        }
        default: {
            return baseResponseHandlingDefinitionSchema;
        }
    }
}

const projectIntegrationEntitySplitPageSchema = Yup.object({}).shape({
    definition: Yup.mixed(),
    handleResponse: singleHandleResponseSchema,
});

export const defaultSingleHandleResponseObject = {
    entity: { ...defaultTonkeanExpression },
    id: { ...defaultTonkeanExpression },
    displayName: { ...defaultTonkeanExpression },
    updatedDate: { ...defaultTonkeanExpression },
    createdDate: { ...defaultTonkeanExpression },
    entityUrl: { ...defaultTonkeanExpression },
    validationStatus: {
        outputFields: [],
        isValid: false,
    },
    responseHandlingType: EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES,
    subEntities: [],
    dynamicParams: [],
};

export const defaultHandleResponseSubEntities = {
    responseHandlingDefinition: defaultSingleHandleResponseObject,
};

export default projectIntegrationEntitySplitPageSchema;
