import React from 'react';

import EnterpriseComponentPageTemplate from '../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';

const EnterpriseComponentNotAuthorizedForPageContent: React.FC = () => {
    return (
        <EnterpriseComponentPageTemplate name="Unauthorized" description="You are not authorized to view this page" />
    );
};

export default EnterpriseComponentNotAuthorizedForPageContent;
