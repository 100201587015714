import React from 'react';

import ConfigurationSectionTitle from '../../../../components/ConfigurationSectionTitle';
import type { InnerItemWidgetConfiguration } from '../../../entities';
import type { FieldsWidgetMenuItem } from '../../../FieldsWidgetModule';
import { useGroupSelectorOptions } from '../../hooks';
import FilterAndSortItems from '../configurationEditorSettingsComponents/FilterAndSortItems';
import ItemCreationButtonCreateForm from '../configurationEditorSettingsComponents/ItemCreationButtonCreateForm';
import ItemInterfaceSelectorContainer from '../configurationEditorSettingsComponents/ItemInterfaceSelectorContainer';
import SelectCreateForm from '../configurationEditorSettingsComponents/SelectCreateForm';
import { SelectorTitle } from '../configurationEditorSettingsComponents/SelectorTitle';
import StyledWidgetConfigurationToggle from '../configurationEditorSettingsComponents/StyledWidgetConfigurationToggle';
import ItemsWidgetConfigurationEditorFieldSelector from '../ItemsWidgetConfigurationEditorFieldSelector';
import ItemsWidgetConfigurationEditorInterfaceSelector from '../ItemsWidgetConfigurationEditorInterfaceSelector';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    configuration: InnerItemWidgetConfiguration;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    fieldsAdditionalOptions?: FieldsWidgetMenuItem[];
    fieldDefinitions: FieldDefinition[] | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
}

const SolutionSiteItemsTableWidgetConfigurationEditorDisplay: React.FC<Props> = ({
    configuration,
    groupId,
    fieldsAdditionalOptions,
    fieldDefinitions,
    workflowVersionId,
}) => {
    const groupSelectorOptions = useGroupSelectorOptions();

    return (
        <>
            <ItemInterfaceSelectorContainer>
                <ConfigurationSectionTitle>Items</ConfigurationSectionTitle>

                <SelectorTitle>Module</SelectorTitle>
                <SimpleSelect name="configuration.groupId" options={groupSelectorOptions} />

                {groupId && workflowVersionId && (
                    <>
                        <ItemCreationButtonCreateForm />

                        <SelectCreateForm
                            itemCreationType={configuration.itemCreationType}
                            workflowVersionId={workflowVersionId}
                            groupId={groupId}
                        />

                        <ItemsWidgetConfigurationEditorInterfaceSelector
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                        />

                        <FilterAndSortItems groupId={groupId} workflowVersionId={workflowVersionId} />

                        <StyledWidgetConfigurationToggle
                            title="Match inner items"
                            toolTip="Inner items that match the filter conditions will also be displayed as results"
                            name="configuration.includeInnerItemsFromAllHierarchies"
                        />

                        <StyledWidgetConfigurationToggle
                            title="Allow expanding items"
                            toolTip="Items that match the filter conditions, will also contain any nested inner items when viewed as a table"
                            name="configuration.enableInnerItemsToggle"
                        />

                        <StyledWidgetConfigurationToggle
                            title="Display advanced filters"
                            toolTip="Display advanced filters option at the top of the widget"
                            name="configuration.displayAdvancedFilters"
                        />
                    </>
                )}
            </ItemInterfaceSelectorContainer>

            {workflowVersionId && (
                <ItemsWidgetConfigurationEditorFieldSelector
                    configuration={configuration}
                    fieldDefinitions={fieldDefinitions}
                    initiative={undefined}
                    workflowVersionId={workflowVersionId}
                    fieldsAdditionalOptions={fieldsAdditionalOptions}
                    titleFieldUnlocked={false}
                    fixedOrderForSpecialFields
                />
            )}
        </>
    );
};

export default SolutionSiteItemsTableWidgetConfigurationEditorDisplay;
