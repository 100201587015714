import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class SplitToPartsFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.SPLIT_TO_PARTS;
    override readonly sign = 'SplitToParts';
    override displayName = 'Split Text to Parts';
    override description =
        'Will split the given text to parts based on max/min character count. Will split by paragraph and sentence.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Maximum Characters',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Minimum Characters',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 100,
            defaultTab: FormulaPopoverTab.CONST,
        },
    ];
}
