import { getStateError } from '@tonkean/utils';

function LoginTokenCtrl($scope, $state, $stateParams, tonkeanService, authenticationService, $log, utils) {
    $scope.token = $stateParams.token;
    $scope.create = $stateParams.create;
    $scope.access = $stateParams.access;
    $scope.referralCode = $stateParams.referralCode;
    $scope.loading = false;
    $scope.error = null;
    $scope.loadingResend = false;
    $scope.data = {
        name: null,
    };

    $scope.init = function () {
        tonkeanService.sendMessageClick($scope.token, $scope.create ? 0 : 1);
        $scope.login();
    };

    $scope.login = function () {
        $scope.error = null;
        $scope.loading = true;

        if ($scope.access) {
            loginWithAccessToken();
        } else {
            loginWithEmail();
        }
    };

    $scope.setName = function () {
        tonkeanService
            .updateProfileInfo($scope.data.name)
            .then(function (user) {
                authenticationService.updateCurrentUser(user);
                authenticationService.handleAuthenticationComplete();
            })
            .catch(function () {
                $scope.$emit('alert', 'Could not save user name.');
            });
    };

    $scope.resendEmail = function () {
        $scope.loadingResend = true;
        tonkeanService
            .resendLoginEmail($scope.token, $scope.referralCode)
            .finally(() => ($scope.loadingResend = false));
    };

    $scope.init();

    function loginWithAccessToken() {
        tonkeanService
            .getCurrentUser($scope.token)
            .then(function (userData) {
                userData.isAdminLoginAs = true;
                authenticationService.finishAuthentication(userData, $scope.token);
            })
            .catch(function (error) {
                $scope.error = error;
                $scope.loading = false;
            });
    }

    function loginWithEmail() {
        tonkeanService
            .loginEmail($scope.token, $scope.data.name, $scope.referralCode)
            .then(function (data) {
                authenticationService.finishAuthentication(data.user, data.accessToken, data.isNewUser, true);
                return data.user;
            })
            .then(function (user) {
                // $log.info('Creating organization for user...');
                // After login with email we check if there's no organization.
                const workEmails = user.emails
                    .map(function (email) {
                        return utils.getCompanyEmailDomain(email);
                    })
                    .filter(angular.identity);

                const emailDomain = workEmails.length === 1 ? workEmails[0] : null;
                // if (utils.isEmpty(user.organizations) && emailDomain) {
                //     tonkeanService.createOrganization(emailDomain)
                //         .then(function (org) {
                //             $log.info('Organization created', org);
                //             user.organizations = user.organizations || [];
                //             user.organizations.push(org);
                //         });
                // }
                console.log(emailDomain);
                return emailDomain;
            })
            .then(function () {
                $scope.pm.getProjects(true).then(function () {
                    // The user has no projects - create brand new user flow.
                    if (!$scope.pm.projects || !$scope.pm.projects.length) {
                        $state.go('loginCreate', null, { location: 'replace' });
                    } else {
                        authenticationService.handleAuthenticationComplete();
                    }
                });
            })
            .catch(function (error) {
                $scope.loading = false;
                if (error.status === 400 || error.status === 410) {
                    if (!$scope.create && error.data.error.message.indexOf("'name'") === 0) {
                        $log.warn('Name parameter missing error, show create UI...');
                        $scope.create = true;
                    } else {
                        $scope.error = error.status;
                    }
                } else {
                    $scope.error = getStateError(error);
                }
            });
    }
}

export default angular.module('tonkean.app').controller('LoginTokenCtrl', LoginTokenCtrl);
