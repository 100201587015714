import { useAngularService, useAngularWatch } from 'angulareact';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyAsyncMethod, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { CoreEditorSerialize, HTMLEditor, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import {
    CharCount,
    Field,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalHeaderActionButtons,
    ModalSize,
    Paragraph,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import type { Group, WorkflowVersion } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const MAX_LENGTH = 255;

const schema = Yup.object({
    name: Yup.string().required().max(MAX_LENGTH, ''),
    description: Yup.array(),
});

const Body = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
const StyledCharCount = styled(CharCount)`
    position: absolute;
    top: 100%;
    right: 0;
`;

interface Props {
    group: Group;
    workflowVersion: WorkflowVersion;
}

const ModuleInfoModal: React.FC<Props> = ({ group, workflowVersion }) => {
    const groupManager = useAngularService('groupManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const [{ error: updateGroupNameError }, updateGroupName] = useLazyAsyncMethod(groupManager, 'updateGroupName');
    const [{ error: updateWorkflowVersionDescriptionError }, updateWorkflowVersionDescription] = useLazyTonkeanService(
        'updateWorkflowVersionDescription',
    );
    const close = useCloseCallback();

    const [groupName, description, workflowVersionType] = useAngularWatch(
        () => group.name,
        () => workflowVersion.htmlDescription,
        () => workflowVersion.workflowVersionType,
    );
    const canEditDescription = workflowVersionType === WorkflowVersionType.DRAFT;

    return (
        <Formik
            initialValues={{ name: groupName, description }}
            validationSchema={schema}
            onSubmit={(values) => {
                const promises: Promise<void>[] = [];

                if (values.name !== groupName) {
                    promises.push(updateGroupName(group.id, values.name));
                }
                if (values.description !== description && canEditDescription) {
                    promises.push(
                        updateWorkflowVersionDescription(group.id, values.description).then(() => {
                            workflowVersionManager.incrementWorkflowVersionCounter(group.draftWorkflowVersionId);
                            workflowVersionManager.updateWorkflowVersion({
                                id: group.draftWorkflowVersionId,
                                htmlDescription: values.description,
                            });
                        }),
                    );
                }

                return Promise.all(promises).then(() => close());
            }}
        >
            {({ values, setFieldValue }) => (
                <ModalForm>
                    <ModalHeader>
                        Module Info
                        <ModalHeaderActionButtons />
                    </ModalHeader>
                    <Body>
                        <Field label="Module name">
                            <Input name="name" />
                            <StyledCharCount currentCount={values.name.length} maxCount={MAX_LENGTH} />
                        </Field>
                        <Field label="Description">
                            {canEditDescription ? (
                                <HTMLEditor
                                    placeholder="Describe your module..."
                                    initialValue={values.description}
                                    onChange={(newValue) => setFieldValue('description', newValue, true)}
                                />
                            ) : (
                                <>
                                    <CoreEditorSerialize
                                        plugins={HTMLEditorFullPlatePluginsList}
                                        value={values.description}
                                    />
                                    {!values.description.length && (
                                        <Paragraph $small $light>
                                            go to build environment to edit
                                        </Paragraph>
                                    )}
                                </>
                            )}
                        </Field>
                    </Body>
                    <ModalFooterActions error={updateGroupNameError || updateWorkflowVersionDescriptionError} />
                </ModalForm>
            )}
        </Formik>
    );
};

export default withModal(ModuleInfoModal, { size: ModalSize.MEDIUM, fixedWidth: true });
