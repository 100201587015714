import { useAngularService } from 'angulareact';
import type { FormEvent } from 'react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import AdobeSignAuthenticationType, { authTypeToDisplayName } from './AdobeSignAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const CenteredLinkButton = styled.div`
    text-align: center;
`;

const AdobeSignAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [shard, setShard] = useState<string | undefined>();
    const [accessToken, setAccessToken] = useState<string | undefined>();
    const [authenticationType, setAuthenticationType] = useState<AdobeSignAuthenticationType>(
        AdobeSignAuthenticationType.OAUTH,
    );

    const authenticateWithAdobeSign = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            let config;

            if (authenticationType === AdobeSignAuthenticationType.OAUTH) {
                const clientId = environment.integrationKeysMap.adobesignKey;
                const redirectUri = environment.integrationKeysMap.adobesignUri;
                const state = oauthHandler.publicGetState();

                const scopes = encodeURIComponent(
                    'agreement_read agreement_write library_read library_write user_login user_read webhook_read webhook_retention webhook_write workflow_read',
                );

                const url = `https://secure.${shard}.adobesign.com/public/oauth/v2?response_type=code&scope=${scopes}&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}`;

                const code = await oauthHandler.startOAuth(url, state);

                config = {
                    code,
                    redirectUri,
                    shard,
                    authenticationType,
                };
            } else {
                config = {
                    shard,
                    authenticationType,
                    accessToken,
                };
            }

            authenticate(config, {}, `${integrationConfiguration.displayName} (${shard})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <CenteredAuthTypeTitle>
                <h5>{authTypeToDisplayName[authenticationType]} Authentication</h5>
            </CenteredAuthTypeTitle>
            <div className="flex flex-col api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-3 control-label margin-top">Environment</label>
                    <div className="col-sm-7 flex-vmiddle">
                        <span>https://[subdomain].</span>
                        <input
                            type="text"
                            value={shard}
                            className="form-control margin-bottom-xs"
                            onChange={({ target }) => setShard(target.value)}
                            autoComplete="off"
                            placeholder="na[#]"
                            required
                        />
                        <span>.adobesign.com/</span>
                    </div>
                </div>

                {authenticationType === AdobeSignAuthenticationType.ACCESS_TOKEN && (
                    <div className="form-group">
                        <label className="col-sm-3 control-label margin-top-xs">Access Token</label>
                        <div className="col-sm-9">
                            <input
                                value={accessToken}
                                className="form-control"
                                onChange={({ target }) => setAccessToken(target.value)}
                                autoComplete="off"
                                placeholder="Access Token"
                                required
                            />
                        </div>
                    </div>
                )}

                <div className="flex mod-center margin-top-20">
                    <button
                        className=" btn btn-secondary btn-lg"
                        onClick={authenticateWithAdobeSign}
                        disabled={
                            !shard || (authenticationType === AdobeSignAuthenticationType.ACCESS_TOKEN && !accessToken)
                        }
                    >
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>

                <div>
                    <CenteredLinkButton className="form-group margin-top-20">
                        <a
                            onClick={() =>
                                setAuthenticationType(
                                    authenticationType === AdobeSignAuthenticationType.OAUTH
                                        ? AdobeSignAuthenticationType.ACCESS_TOKEN
                                        : AdobeSignAuthenticationType.OAUTH,
                                )
                            }
                        >
                            Use&nbsp;
                            {authenticationType === AdobeSignAuthenticationType.OAUTH
                                ? authTypeToDisplayName[AdobeSignAuthenticationType.ACCESS_TOKEN]
                                : authTypeToDisplayName[AdobeSignAuthenticationType.OAUTH]}
                            &nbsp;Authentication
                        </a>
                    </CenteredLinkButton>
                </div>
            </div>
        </div>
    );
};

export default AdobeSignAuthenticationComponent;
