import { analyticsWrapper } from '@tonkean/analytics';
import { DeprecatedDate } from '@tonkean/utils';
import { SCIMTonkeanRole, isUserInRole, isUserOnlyInRole } from '@tonkean/tonkean-entities';

/**
 * Handles the state for all the project data.
 */
function ProjectManager(
    $rootScope,
    $localStorage,
    $q,
    $state,
    tonkeanService,
    $timeout,
    $location,
    $log,
    authenticationService,
    utils,
    projectIntegrationCache,
) {
    const _this = this;
    /**
     * The promises for all requests that are made in this manager.
     * @type {{}}
     */
    const promises = {};

    /**
     * Used to avoid multiple parallel calls to the server to bring the same project.
     */
    const selectProjectPromises = {};

    /**
     * The name of fields that are not project specific.
     * This is used to clean up when switching projects.
     * @type {string[]}
     */
    const nonProjectFields = new Set(['projects', 'loading', 'error', 'loadTime']);

    /**
     * The name of fields that were fetched.
     * This is used to clean up when switching projects.
     * @type {string[]}
     */
    const dataFields = [];

    /**
     * The time an object will be taken from cache.
     * @type {number}
     */
    const cacheTime = 10_000; // 10 seconds

    /**
     * The loading state for all fields.
     * @type {{}}
     */
    _this.loading = {};

    /**
     * The error object for all fields.
     * @type {{}}
     */
    _this.error = {};

    /**
     * The time a property was loaded.
     * @type {{}}
     */
    _this.loadTime = {};

    /**
     * Map between group id and the users viewing it.
     * @type {{}}
     */
    _this.groupIdToViewingUsersMap = {};

    /**
     * Which group user is currently viewing in the UI.
     */
    _this.currentlyViewedGroupId = null;

    /**
     * Which initiative user is currently viewing(hovering) in the UI.
     */
    _this.currentlyViewedSimplifiedInitiative = null;

    /**
     * Which trigger user is currently selected in the UI.
     */
    _this.currentlyViewedSimplifiedCustomTrigger = null;

    /**
     * Holds the allowed business reports identifiers the user can access.
     */
    _this.allowedBusinessReportIdsSet = null;

    _this.groupsMap = {};
    _this.groups = [];
    _this.interval = null;
    _this.tokenData = {
        getTokenPromise: null,
        getTokenPromiseProject: null,
        getTokenPromiseLastCall: null,
    };

    /** A flag that marks that there were integrations that are in collect at the moment. */
    let isAnyIntegrationCollecting = false;
    /** A promise used for areIntegrationsCollecting timeout. */
    let integrationsCollectingPromise = null;

    // Map between group id to its collecting state.
    const groupIdToCollectingStateMap = {};
    // Map between group id and the promise that is used for checking if integrations are still collecting for this group.
    const groupIntegrationsCollectingPromiseMap = {};

    // Initializing an empty features map
    $rootScope.features = $rootScope.features || {};

    /**
     * Initialization function for the project.
     */
    _this.init = function () {
        $rootScope.$on('$locationChangeSuccess', function () {
            const routesToRedirectToLastProject = ['/'];
            if (
                $localStorage.currentUser &&
                $localStorage.lastProjectId &&
                routesToRedirectToLastProject.includes($location.url())
            ) {
                if ($localStorage.lastProjectUrlSlug && $localStorage.lastProjectHomepageEnabled) {
                    $state.go('homepageView', { projectUrlSlug: $localStorage.lastProjectUrlSlug });
                } else {
                    $state.go('product.workers', { projectId: $localStorage.lastProjectId });
                }
            }

            _this.interval = setInterval(function () {
                if (_this.project) {
                    updateProjectToken(_this.project);
                }
            }, 5_400_000); // 90 minutes
        });
        return _this;
    };

    /**
     * Gets the projects list.
     * @param clear whether to clear the cached data.
     * @param forceUpdate whether to refresh the cached data.
     * @param shouldNotSelectProject Indicates whether we would like project to be selected after fetched or not.
     * @return {*} a promise with the list of projects.
     */
    _this.getProjects = function (clear, forceUpdate, shouldNotSelectProject) {
        // fetches all projects used for caching
        return goFetch('projects', tonkeanService.getProjects, [0, 52, null], clear, forceUpdate, function (projects) {
            if (!shouldNotSelectProject && _this.project) {
                // Updating project instance
                _this.selectProject(_this.project);
            }
            return projects;
        });
    };

    _this.cleanSelectedProject = function () {
        setProjectObj(undefined);
    };

    _this.getProjectsPaged = function (limit, skip, query) {
        let retrievedProjects = [];
        // fetches all projects used for caching
        return goFetch('projects', tonkeanService.getProjects, [skip, limit, query], false, true, function (projects) {
            retrievedProjects = projects;

            const existingProjects = _this['projects'] || [];
            projects.forEach((project) => {
                let foundIndex = -1;
                for (const [i, existingProject] of existingProjects.entries()) {
                    if (existingProject.id === project.id) {
                        foundIndex = i;
                        break;
                    }
                }
                if (foundIndex > -1) {
                    existingProjects.splice(foundIndex, 1, project);
                } else {
                    existingProjects.push(project);
                }
            });

            if (_this.project) {
                // Updating project instance
                selectProjectFromList(_this.project.id);
            }

            return existingProjects;
        }).then(() => {
            return retrievedProjects;
        });
    };

    /**
     * Gets the enterprise projects list.
     */
    _this.getEnterpriseProjects = function (enterpriseId) {
        return tonkeanService.getEnterpriseProjects(enterpriseId);
    };

    /**
     * Gets the relevant project context for the current user.
     */
    _this.getProjectContext = function () {
        return authenticationService.currentUser.projectContexts[_this.project.id];
    };

    /**
     * Selects a project in the manager.
     * @param project Project id string or a project object.
     * @return {*} a promise with the project object.
     */
    _this.selectProject = function (project) {
        const projectId = angular.isObject(project) ? project.id : project;
        if (angular.isString(projectId) && projectId) {
            let alreadySelected = false;

            if (_this.project) {
                if (_this.project.id !== projectId) {
                    _this.cleanProjectState();
                } else {
                    alreadySelected = true;
                }
            }

            if (!alreadySelected) {
                if (angular.isObject(project)) {
                    setProjectObj(project);
                } else {
                    // Dummy object. will be replaced by real one after we get all projects.
                    setProjectObj({ id: projectId, integrations: [], creator: {}, isDummy: true });
                }
            }

            return updateProjectToken(projectId).then(() => {
                tonkeanService.notifyProjectAccess(projectId);

                if (!selectProjectPromises[projectId]) {
                    selectProjectPromises[projectId] = getProjectByIdInner(projectId);
                }

                return selectProjectPromises[projectId]
                    .then(() => {
                        return selectProjectFromList(projectId);
                    })
                    .finally(() => delete selectProjectPromises[projectId]);
            });
        } else {
            return $q.reject(404);
        }
    };

    /**
     * Get project token for current project
     */
    _this.getProjectToken = function () {
        return authenticationService.getProjectToken();
    };

    function isDataStale(timeToCheck) {
        if (!timeToCheck) {
            return true;
        }
        const currentTime = DeprecatedDate.nowAsDate();
        const timeElapsed = currentTime - timeToCheck;
        const fiveMinutesInMilliseconds = 5 * 60 * 1000;
        return timeElapsed > fiveMinutesInMilliseconds;
    }

    function updateProjectToken(projectId) {
        if (
            !_this.tokenData?.getTokenPromise ||
            projectId !== _this.tokenData?.getTokenPromiseProject ||
            isDataStale(_this.tokenData?.getTokenPromiseLastCall)
        ) {
            _this.tokenData = {};
            _this.tokenData.getTokenPromiseProject = projectId;
            _this.tokenData.getTokenPromiseLastCall = DeprecatedDate.nowAsDate();
            _this.tokenData.getTokenPromise = tonkeanService
                .getProjectToken(projectId)
                .then((projectToken) => {
                    _this.projectToken = authenticationService.updateProjectToken(projectId, projectToken);
                    _this.projectToken = projectToken;
                })
                .catch((error) => {
                    _this.tokenData = {
                        getTokenPromise: null,
                        getTokenPromiseProject: null,
                        getTokenPromiseLastCall: null,
                    };

                    if ([403, 404].includes(error.status)) {
                        $state.go('noaccesspage');
                    }
                });
        }
        return _this.tokenData?.getTokenPromise;
    }

    /**
     * Gets a single project by id.
     */
    function getProjectByIdInner(projectId) {
        return tonkeanService.getProjectById(projectId).then((data) => {
            if (!_this.projects) {
                _this.projects = [];
            }

            _this.projects.push(data.project);

            if (_this.project) {
                // Updating project instance
                selectProjectFromList(_this.project.id);
            }
        });
    }

    /**
     * Fetches the risks array.
     * @return {*} Promise.
     */
    _this.getTempUsers = function (forceUpdate) {
        return goFetch(
            'tempUsers',
            tonkeanService.getTempUsers,
            [_this.project.id],
            false,
            forceUpdate,
            function (data) {
                if (data.people) {
                    angular.forEach(data.people, function (p) {
                        p.email = '';
                        p.tempPersonId = p.id;
                    });
                }
                return data.people;
            },
        );
    };

    /**
     * Fetches the risks array.
     * @return {*} Promise.
     */
    _this.getTags = function (forceUpdate) {
        return goFetch('tags', tonkeanService.searchTopics, [_this.project.id], false, forceUpdate, function (data) {
            return data.tags;
        });
    };

    /**
     * Updates the group to viewing users map.
     */
    _this.updateGroupIdToViewingUsersMap = function (groupId, personIdToLastViewTimeMap) {
        if (!personIdToLastViewTimeMap) {
            return;
        }

        if (!_this.groupIdToViewingUsersMap) {
            _this.groupIdToViewingUsersMap = {};
        }

        _this.groupIdToViewingUsersMap[groupId] = personIdToLastViewTimeMap;
    };

    /**
     * Fetches the activity digest subscribed groups array.
     */
    _this.getActivityDigestSubscribedGroups = function (forceUpdate) {
        return goFetch(
            'activityDigestSubscribedGroups',
            tonkeanService.getActivityDigestSubscribedGroups,
            [_this.project.id, authenticationService.currentUser.id],
            false,
            forceUpdate,
            function (data) {
                const activityDigestSubscribedGroups = {};
                if (data && data.subscribedGroups) {
                    for (let i = 0; i < data.subscribedGroups.length; i++) {
                        activityDigestSubscribedGroups[data.subscribedGroups[i]] = true;
                    }
                }
                return activityDigestSubscribedGroups;
            },
        );
    };

    /**
     * Fetches the risks array.
     * @return {*} Promise.
     */
    _this.getFunctions = function (forceUpdate) {
        return goFetch(
            'functions',
            tonkeanService.searchFunctions,
            [_this.project.id],
            false,
            forceUpdate,
            function (data) {
                const map = {};
                angular.forEach(data.entities, function (func) {
                    map[func.name] = func;
                });
                _this.functionMap = map;
                return data.entities;
            },
        );
    };

    /**
     * Loads the business reports accessible for the user for the project.
     */
    _this.loadBusinessReports = function (forceUpdate) {
        if (!_this.allowedBusinessReportIdsSet || forceUpdate) {
            return tonkeanService.getPersonBusinessReports(_this.project.id).then((businessReportsResponse) => {
                const allowedBusinessReportIdsSet = {};
                if (businessReportsResponse?.entities?.length) {
                    businessReportsResponse?.entities.forEach((businessReport) => {
                        allowedBusinessReportIdsSet[businessReport.groupId] = true;
                    });
                }

                _this.allowedBusinessReportIdsSet = allowedBusinessReportIdsSet;
            });
        } else {
            return $q.resolve();
        }
    };

    _this.getGroupsMap = function (forceUpdate, overrideCacheInvalidationTimeMilliseconds) {
        return _this.getGroups(forceUpdate, overrideCacheInvalidationTimeMilliseconds).then(() => _this.groupsMap);
    };

    /**
     * Fetches the groups array.
     * @return {*} Promise.
     */
    _this.getGroups = function (forceUpdate, overrideCacheInvalidationTimeMilliseconds) {
        return _this.loadBusinessReports(forceUpdate).then(() => {
            return goFetch(
                'groups',
                tonkeanService.getGroups,
                [_this.project.id],
                false,
                forceUpdate,
                function (data) {
                    return cacheGroups(data.entities, forceUpdate, true);
                },
                overrideCacheInvalidationTimeMilliseconds,
            );
        });
    };

    /**
     * Fetches the groups array by the group ids.
     * @return {*} Promise.
     */
    _this.getGroupsByIds = function (groupIds, forceUpdate, overrideCacheInvalidationTimeMilliseconds) {
        return _this.loadBusinessReports(forceUpdate).then(() => {
            return goFetch(
                'groups',
                tonkeanService.getGroupsByIds,
                [_this.project.id, groupIds],
                false,
                forceUpdate,
                function (data) {
                    cacheGroups(data.groups, forceUpdate, false);
                    return data.groups.map((group) => _this.groupsMap[group.id]);
                },
                overrideCacheInvalidationTimeMilliseconds,
            );
        });
    };

    _this.getGroup = function (groupId, forceUpdate, overrideCacheInvalidationTimeMilliseconds) {
        if (_this.groupsMap[groupId]) {
            return $q.resolve(_this.groupsMap[groupId]);
        }

        const businessReportsPromise = _this.loadBusinessReports(forceUpdate);
        const groupPromise = tonkeanService.getGroupById(groupId);

        return $q
            .all([businessReportsPromise, groupPromise])
            .then(() =>
                goFetch(
                    'groups',
                    () => groupPromise,
                    [],
                    false,
                    forceUpdate,
                    function (group) {
                        return cacheGroups([group], forceUpdate, false);
                    },
                    overrideCacheInvalidationTimeMilliseconds,
                ),
            )
            .then(() => _this.groupsMap[groupId]);
    };

    /**
     * Adds the given new group to the different group caches of the project manager.
     * @param newGroup - the new group to add to the caches.
     */
    _this.addGroup = function (newGroup) {
        // Only do something if we've go t a valid group.
        if (newGroup && newGroup.id) {
            const groupsMap = _this.groupsMap || {};
            const groupsArray = _this.groups || [];

            // Update the groups map.
            groupsMap[newGroup.id] = newGroup;

            // Update the groups array.
            const existingGroup = utils.findFirstById(groupsArray, newGroup.id);
            if (existingGroup) {
                // New group already exists. Update it.
                utils.copyEntityFields(newGroup, existingGroup);
            } else {
                // New group doesn't exist. Add it.
                groupsArray.push(newGroup);
            }

            // Update the actual references.
            _this.groupsMap = groupsMap;
            _this.groups = groupsArray;
        }
    };

    /**
     * Updated the given group in the different caches by copying it's properties.
     * @param updatedGroup - an updated group to find and update in our caches.
     * @param deleteMissingFields - when true, missing fields in the updated group will be deleted from the cached group.
     */
    _this.updateGroup = function (updatedGroup, deleteMissingFields) {
        const map = _this.groupsMap || {};
        const groups = _this.groups || [];

        // Update the groups map (without replacing the reference).
        if (map[updatedGroup.id]) {
            utils.copyEntityFields(updatedGroup, map[updatedGroup.id], null, null, deleteMissingFields);
        } else {
            map[updatedGroup.id] = updatedGroup;
        }

        // Update the groups array.
        const originalGroup = utils.findFirst(groups, (group) => group.id === updatedGroup.id);
        if (originalGroup) {
            // If we found the group in the groups array.
            utils.copyEntityFields(updatedGroup, originalGroup, null, null, deleteMissingFields);
        } else {
            // If we didn't find the group in the groups array, add it to the array.
            groups.push(updatedGroup);

            // Order the groups array by created date ascending - if we added a new group to the array.
            groups.sort(function (groupA, groupB) {
                // We want to order the groups in an ascending order.
                // This mean, groups with smaller created unix time should go first.
                // So if groupA.created is smaller than groupB.created, a negative value will be returned,
                // reflecting this to the sort function.
                return groupA.created - groupB.created;
            });
        }

        // Update the references (in case they were null in the beginning).
        _this.groupsMap = map;
        _this.groups = groups;
    };

    /**
     * Deletes a group from the different group caches.
     * @param groupId - The is of the group to delete..
     */
    _this.deleteGroupFromCaches = function (groupId) {
        // Delete the group from the groups map if found.
        if (_this.groupsMap && _this.groupsMap[groupId]) {
            delete _this.groupsMap[groupId];
        }

        // Delete the group from the groups array.
        if (_this.groups && _this.groups.length) {
            // Find the group's index in the groups array.
            const groupIndex = utils.indexOf(_this.groups, (group) => group.id === groupId);

            // If found, remove from array.
            if (groupIndex > -1) {
                _this.groups.splice(groupIndex, 1);
            }
        }
    };

    /**
     * Updates the given groupId's group with the given metadata.
     */
    _this.updateGroupMetadata = function (groupId, metadata) {
        // Get the group we need.
        const group = _this.groupsMap[groupId];

        // Replace the metadata with the new one.
        group.metadata = metadata;

        // Update our group in our local caches.
        _this.updateGroup(group);

        // Update the server.
        return tonkeanService.updateGroupMetadata(groupId, metadata);
    };

    /**
     * Checks if the group exists in the groups map cache safely.
     * @param groupId - the group id to check for existence.
     * @returns {boolean}
     */
    _this.groupExists = function (groupId) {
        // If we didn't get a group id, no point in continuing.
        // Make sure the groups map and group array exist. Only then check for the group id existence.
        if (groupId && _this.groupsMap && _this.groups) {
            return !!_this.groupsMap[groupId];
        }

        return false;
    };

    /**
     * Sets the given group id as the free membership group in the project's metadata.
     */
    _this.setFreeMembershipGroup = function (groupId) {
        // We make sure freeMembershipSelectedGroup is not already set cause the user is only allowed to set it once.
        if (_this.project.isLimitedLicense && !_this.project.metadata.freeMembershipSelectedGroup) {
            _this.freeMembershipGroupId = groupId;

            // Make sure the metadata exists.
            if (!_this.project.metadata) {
                _this.project.metadata = {};
            }
            _this.project.metadata.freeMembershipSelectedGroup = groupId;

            // Update the server with the fresh metadata.
            tonkeanService.updateProjectMetadataJson(_this.project.id, _this.project.metadata);
        }
    };

    /**
     * Updates the current project's name with the give new name.
     * @param newProjectName - the new project name to set to the current project. Ignored if null or empty.
     * @returns {*|Promise<T>} a promise.
     */
    _this.changeProjectName = function (newProjectName) {
        if (_this.project && !utils.isNullOrEmpty(newProjectName) && newProjectName !== _this.project.name) {
            return tonkeanService
                .updateProjectMetadata(_this.project.id, newProjectName, _this.project.emailDomain)
                .then(function (data) {
                    // Update the cached project's name with the updated one.
                    if (data && data.name) {
                        _this.project.name = data.name;
                    }

                    return $q.resolve(_this.project.name);
                });
        }

        return $q.resolve(_this.project.name);
    };

    /**
     * Fetches the projectData obj.
     * @return {*} Promise.
     */
    _this.getProjectData = function (forceUpdate, dontUseCache) {
        return goFetch(
            'projectData',
            tonkeanService.getProjectById,
            [_this.project.id, forceUpdate, dontUseCache],
            false,
            forceUpdate,
            function (data) {
                setProjectObj(data.project);

                if (data.stats) {
                    // Save the map.
                    _this.statsMap = {};
                    data.stats.forEach((stat) => {
                        _this.statsMap[stat.stateText] = stat;
                    });
                }

                return data;
            },
        );
    };

    _this.approveLicenseRequest = function (projectId, personId) {
        return tonkeanService.updatePersonLicense(projectId, personId, true, true);
    };

    _this.answerQuestionBySecret = function (secret, answerIndex) {
        return tonkeanService.postAnswerBySecret(secret, answerIndex).then(onPostAnswer);
    };

    _this.answerQuestion = function (questionId, answerId) {
        return tonkeanService.postAnswerById(questionId, answerId).then(onPostAnswer);
    };

    /**
     * Updates the sendApproved state for a project.
     * @return {*}
     */
    _this.saveOwners = function (owners) {
        return goFetch('owners', tonkeanService.saveOwners, [_this.project.id, owners], true, true, function () {
            _this.project.owners = owners;
            _this.isOwner = owners && utils.findFirstById(owners, authenticationService.currentUser.id);
        });
    };

    /**
     * Increments or decrements the onlyMineInitiativesCount counter.
     */
    _this.updateOnlyMineInitiativesCount = function (isIncrement) {
        if (!_this.projectData) {
            return;
        }

        const value = isIncrement ? 1 : -1;

        if (_this.projectData.onlyMineInitiativesCount) {
            _this.projectData.onlyMineInitiativesCount += value;
        } else {
            _this.projectData.onlyMineInitiativesCount = isIncrement ? 1 : 0;
        }
    };

    /**
     * Recalculates the isFullUserPreview property according to current time.
     * The isFullUserPreview property is true if the user is in full user preview mode and false otherwise.
     * The isFullUserPreview state is time sensitive, this is why its calculation is exposed here so other services can ask for an update.
     */
    _this.recalculateIsFullUserPreview = function () {
        return recalculateIsFullUserPreview();
    };

    /**
     * Recalculates all license and pricing related properties that we set on the projectManager
     * @param newProject - the new project to take data from.
     */
    _this.recalculateProjectLicenseProperties = function (newProject) {
        recalculateProjectLicenseProperties(newProject);
    };

    /**
     * Set the given project in readonly mode.
     */
    _this.setViewOnlyMode = function (proj) {
        _this.viewOnlyMode = true;
        _this.project = proj;
    };

    /**
     * Since recalculateIsFullUserPreview can get called very early, we can't rely on the public recalculateIsFullUserPreview to be initialized.
     * This function acts as a private inner function, and _this.recalculateIsFullUserPreview acts as a public encapsulation of it, exposing it
     * to other services.
     */
    function recalculateIsFullUserPreview() {
        let userProjectContext = null;
        if (
            _this.project &&
            authenticationService &&
            authenticationService.currentUser &&
            authenticationService.currentUser.projectContexts &&
            authenticationService.currentUser.projectContexts[_this.project.id]
        ) {
            userProjectContext = authenticationService.currentUser.projectContexts[_this.project.id];
        }

        _this.isFullUserPreview =
            userProjectContext &&
            userProjectContext.isInPreview &&
            !userProjectContext.isLicensed &&
            userProjectContext.previewExpirationDate &&
            DeprecatedDate.nowAsDate() < userProjectContext.previewExpirationDate;

        // isUserLicensed is dependant on isFullUserPreview. We should recalculate it too.
        recalculateIsUserLicensed();

        return _this.isFullUserPreview;
    }

    /**
     * Recalculates isUserLicensed according to the current relevant project states.
     */
    function recalculateIsUserLicensed() {
        // No point of checking for license if there's no project.
        if (_this.project) {
            let userProjectContext = null;
            if (
                _this.project &&
                authenticationService &&
                authenticationService.currentUser &&
                authenticationService.currentUser.projectContexts &&
                authenticationService.currentUser.projectContexts[_this.project.id]
            ) {
                userProjectContext = authenticationService.currentUser.projectContexts[_this.project.id];
            }

            _this.isUserLicensed =
                (userProjectContext && userProjectContext.isLicensed) ||
                _this.project.isInTrial ||
                _this.isFullUserPreview;

            return _this.isUserLicensed;
        }
    }

    /**
     * Recalculates all license and pricing related properties that we set on the projectManager
     * @param newProj - the new project to take data from.
     */
    function recalculateProjectLicenseProperties(newProj) {
        if (newProj && _this.project) {
            _this.isOwner = newProj.owners && utils.findFirstById(newProj.owners, authenticationService.currentUser.id);
            _this.isBuyer =
                newProj.license &&
                newProj.license.buyer &&
                newProj.license.buyer.id === authenticationService.currentUser.id;
            _this.isCreator = newProj.creator.id === authenticationService.currentUser.id;
            _this.masterOwner = newProj.isLicensed ? newProj.license.buyer : newProj.creator;

            _this.isUserLicensed = recalculateIsUserLicensed();

            _this.isFree = !_this.project.expirationDate && !newProj.license && !newProj.isEnterprise;
            _this.isInTrial = _this.project.isInTrial;
            _this.isLicensed = _this.project.licensed;
            _this.isExpired = _this.project.isExpired;
            _this.isEnterprise = _this.project.isEnterprise;
            _this.isFullUserPreview = recalculateIsFullUserPreview();
            _this.isLimitedLicense = _this.project.isLimitedLicense;
        }
    }

    /**
     * Returns a promise that returns true if integrations of given group id is still in collection phase.
     * The promise will return false otherwise.
     */
    _this.areGroupIntegrationsCollecting = function (groupId) {
        // Initializing the state in the map, if it doesn't exist yet.
        if (utils.isNullOrUndefined(groupIdToCollectingStateMap[groupId])) {
            groupIdToCollectingStateMap[groupId] = true;
        }
        if (utils.isNullOrUndefined(groupIntegrationsCollectingPromiseMap[groupId])) {
            groupIntegrationsCollectingPromiseMap[groupId] = null;
        }

        if (groupIdToCollectingStateMap[groupId]) {
            return _this.getGroups(true).then(function () {
                return $q.resolve(areGroupIntegrationsCollectingInner(groupId));
            });
        } else {
            return $q.resolve(areGroupIntegrationsCollectingInner(groupId));
        }
    };

    /**
     * Inner logic of the areGroupIntegrationsCollecting function.
     */
    function areGroupIntegrationsCollectingInner(groupId) {
        if (!_this.groupsMap || !_this.groupsMap[groupId] || !_this.groupsMap[groupId].groupProjectIntegrations) {
            return false;
        }

        let foundIntegrationCollecting = false;

        // Go over all integrations, and check if there are any that are not yet collected and are valid.
        for (let i = 0; i < _this.groupsMap[groupId].groupProjectIntegrations.length; i++) {
            const integration = _this.groupsMap[groupId].groupProjectIntegrations[i];

            if (integration.valid && !integration.disabled && !integration.lastCollect) {
                // We found a valid integration in first collect - mark as found and stop iteration.
                foundIntegrationCollecting = true;
                break;
            }
        }

        if (foundIntegrationCollecting) {
            // If we found any integration in first collect.
            // Mark global boolean as true, and poll again after timeout.

            groupIdToCollectingStateMap[groupId] = true;

            // Poll again - try to cancel the current timeout, and push a new interval (10 seconds).
            if (groupIntegrationsCollectingPromiseMap[groupId]) {
                $timeout.cancel(groupIntegrationsCollectingPromiseMap[groupId]);
            }
            groupIntegrationsCollectingPromiseMap[groupId] = $timeout(
                () => _this.areGroupIntegrationsCollecting.apply(null, [groupId]),
                5000,
            );
        } else {
            // Otherwise, we didn't find any integration in first collect.
            // If we did find one the last time we checked - this means it finished collecting, so we need to notify everybody.

            if (groupIdToCollectingStateMap[groupId]) {
                $rootScope.$broadcast('integrationsFinishedFirstCollect');
            }

            groupIdToCollectingStateMap[groupId] = false;
        }

        return groupIdToCollectingStateMap[groupId];
    }

    /**
     * Checks if there are integrations in the project which are in first collection (were never collected before) and return true or false accordingly.
     * If it finds integrations that are valid and were not yet collected, starts polling the server for updates.
     * When an integration is finally collected, the function yields a broadcast.
     * @returns {boolean}
     */
    _this.areIntegrationsCollecting = function () {
        // If last time isAnyIntegrationCollecting was true, we should ask the server if anything changed.
        if (isAnyIntegrationCollecting) {
            return _this.getProjectData(true).then(function () {
                return $q.resolve(areIntegrationsCollectingInner());
            });
        } else {
            return $q.resolve(areIntegrationsCollectingInner());
        }
    };

    /**
     * Gets a project integration from project by given id.
     * Deprecated. Don't use this method for new things. Newer usages should use projectIntegrationCache directly
     */
    _this.getProjectIntegrationById = function (projectIntegrationId) {
        return projectIntegrationCache.getFromCache(projectIntegrationId);
    };

    _this.getProjectIntegrationByIdWithServerFallback = function (projectIntegrationId) {
        if (projectIntegrationCache.getFromCache(projectIntegrationId)) {
            return $q.resolve(projectIntegrationCache.getFromCache(projectIntegrationId));
        } else {
            return tonkeanService.getProjectIntegrationById(projectIntegrationId);
        }
    };

    function areIntegrationsCollectingInner() {
        let foundIntegrationCollecting = false;

        // Only do something if we have a project and integrations.
        if (_this.project && _this.project.integrations && _this.project.integrations.length) {
            // Go over all integrations, and check if there are any that are not yet collected and are valid.
            for (let i = 0; i < _this.project.integrations.length; i++) {
                const integration = _this.project.integrations[i];

                if (!integration.lastCollect && integration.valid && !integration.disabled) {
                    // We found a valid integration in first collect - mark as found and stop iteration.
                    foundIntegrationCollecting = true;
                    break;
                }
            }
        }

        // If we found any integration in first collect.
        if (foundIntegrationCollecting) {
            // Mark global boolean as true, and poll again after timeout.
            isAnyIntegrationCollecting = true;

            // Poll again - try to cancel the current timeout, and push a new interval (10 seconds).
            if (integrationsCollectingPromise) {
                $timeout.cancel(integrationsCollectingPromise);
            }
            integrationsCollectingPromise = $timeout(_this.areIntegrationsCollecting, 5000);
        } else {
            // We didn't find any integration in first collect.
            // If we did find one the last time we checked - this means it finished collecting, so we need to notify everybody.
            if (isAnyIntegrationCollecting) {
                $rootScope.$broadcast('integrationsFinishedFirstCollect');
            }

            isAnyIntegrationCollecting = false;
        }

        return isAnyIntegrationCollecting;
    }

    // region Private Methods

    /**
     * Updates the relevate caches after an answer was posted.
     * @param data The server response
     * @return {*} The data
     */
    function onPostAnswer(data) {
        const inquiry = data.inquiries[0];
        _this.selectProject(inquiry.project);
        _this.inquiries = _this.inquiries || [];
        _this.inquiriesMap = _this.inquiriesMap || {};
        _this.questionActivityItems = _this.questionActivityItems || [];

        utils.addOrReplace(_this.inquiries, inquiry, function (inq) {
            return inq.id === inquiry.id;
        });
        _this.inquiriesMap[inquiry.id] = inquiry;
        if (inquiry.questions && !inquiry.insight) {
            const activityItem = {
                type: 'question',
                reference1: inquiry,
                actor: authenticationService.currentUser,
            };
            utils.addOrReplace(_this.questionActivityItems, activityItem, function (a) {
                return a.reference1.id === inquiry.id;
            });
        }

        if (data.answerCount) {
            _this.answerCount = data.answerCount;
        }

        return inquiry;
    }

    /**
     * Updates the project status fields.
     * @param status
     */
    function updateProjectStatus(status) {
        if (status) {
            _this.projectStatus = status;
            _this.collectingOrAnalyzing = status === 'COLLECTING' || status === 'ANALYZING';
            if (!_this.collectingOrAnalyzing) {
                _this.progress = 1;
            }
        }
    }

    /**
     * Selects a project by it's object;
     * @param newProj
     */
    function setProjectObj(newProj) {
        if (newProj?.isDummy) {
            $log.log('Using Dummy project');
        } else if (_this.project?.isDummy) {
            $log.log('Not using dummy project anymore.');
        }

        const currentProject = _this.project;
        _this.project = newProj;
        // Set last project in storage, unless its dummy
        if (!newProj || !newProj.isDummy) {
            $localStorage.lastProjectId = newProj?.id;
            $localStorage.lastProjectUrlSlug = newProj?.urlSlug;
            $localStorage.lastProjectHomepageEnabled = newProj?.features?.tonkean_feature_homepage;
        }

        $rootScope.$broadcast('projectSelected');

        // Get the latest project and its feature flags so it will be accessible from anywhere
        $rootScope.features.currentProject = newProj?.features || {};

        if (!currentProject || !newProj || currentProject.id !== newProj.id || currentProject.isDummy) {
            $rootScope.$broadcast('currentProjectChanged');
        }

        if (newProj) {
            updateProjectStatus(newProj.status);

            if (authenticationService?.currentUser?.projectContexts?.[_this.project.id]) {
                authenticationService.currentUser.projectContext =
                    authenticationService.currentUser.projectContexts[_this.project.id];

                _this.setUserRoleOnProjectInAnalytics(authenticationService.currentUser.projectContext);
            }

            recalculateProjectLicenseProperties(newProj);

            _this.hasInvalidIntegration = newProj.integrations.some(function (integ) {
                return !integ.valid;
            });
            _this.allowTempUsers = !newProj.communicationIntegrations || !newProj.communicationIntegrations.length;
            projectIntegrationCache.cacheEntities(newProj.integrations);

            _this.setProjectInfoOnAnalyticsContext(newProj);
        }
    }

    _this.setUserRoleOnProjectInAnalytics = function (projectContext) {
        analyticsWrapper.setGlobalContext({
            isSystemUser: isUserInRole(projectContext, SCIMTonkeanRole.SYSTEM_USER),
            isProcessContributorOnly: isUserOnlyInRole(projectContext, SCIMTonkeanRole.PROCESS_CONTRIBUTOR),
            isGuestUserOnly: isUserOnlyInRole(projectContext, SCIMTonkeanRole.GUEST_USER),
        });
    };

    _this.setProjectInfoOnAnalyticsContext = function (project) {
        analyticsWrapper.setGlobalContext({
            projectId: project?.id,
            projectName: project?.name,
        });
    };

    /**
     * Selects a project from the project list and updates the local storage.
     * @param projectId The project id.
     * @return {*} the project or a failed promise.
     */
    function selectProjectFromList(projectId) {
        if (projectId && _this.projects) {
            let newProj = null;
            angular.forEach(_this.projects, function (p) {
                if (p.id === projectId) {
                    newProj = p;
                }
            });
            setProjectObj(newProj);
            return newProj ? newProj : $q.reject(404);
        }
    }

    /**
     * Removes all data related to the selected project.
     */
    _this.cleanProjectState = function () {
        _this.loading = {};
        _this.error = {};
        _this.loadTime = {};

        for (const prop in _this) {
            if (_this.hasOwnProperty(prop) && !angular.isFunction(_this[prop]) && !nonProjectFields.has(prop)) {
                delete _this[prop];
            }
        }
    };

    function cacheGroups(groupsToCache, forceUpdate, isFullUpdate) {
        const map = { ..._this.groupsMap };
        const groups = [...(_this.groups || [])];

        // Helper map to collect groups ids returned from the server.
        const serverIdsMap = {};
        const newGroups = [];

        // Clear the default group id before setting it again in the forEach.
        _this.groupDefaultId = null;

        // Go over the groups from the server.
        angular.forEach(groupsToCache, function (group) {
            // If this group is being deleted, ignore and skip it.
            if (!utils.isNullOrUndefined(group.deleteStartTime)) {
                return;
            }

            // Add this id to the helper map.
            serverIdsMap[group.id] = true;

            // Update the groups map. If exists, don't replace reference.
            if (map[group.id]) {
                // We tell the copyEntityFields function to delete missing properties if the data came from the server (forceUpdate).
                // This means, that we are deleting properties from cached groups that didn't return from the server now.
                // This is needed because an integration or sync could have been removed and needs to be deleted.
                utils.copyEntityFields(group, map[group.id], null, false, forceUpdate);
            } else {
                map[group.id] = group;
            }

            let groupExisted = false;

            // Check if this group already exists in pm.groups.
            for (const group_ of groups) {
                // If we find it - replace it with the fresh one from the server.
                if (group_.id === group.id) {
                    utils.copyEntityFields(group, group_);
                    // groups[i] = g;
                    groupExisted = true;
                    break;
                }
            }

            // If this is a new group, mark to add it. We don't push it now because that will cause the next iteration to loop on this group for no reason.
            if (!groupExisted) {
                newGroups.push(group);
            }
        });

        let i;

        // Now add the new groups.
        for (i = 0; i < newGroups.length; i++) {
            groups.push(newGroups[i]);
        }

        if (isFullUpdate) {
            // Now go over the groups array, and delete any group not returned from the server. To not mess up indexes, we start from the top.
            for (i = groups.length - 1; i >= 0; i--) {
                if (!serverIdsMap[groups[i].id]) {
                    delete map[groups[i].id];
                    groups.splice(i, 1);
                }
            }
        }

        // Order the groups array by created date ascending.
        groups.sort(function (groupA, groupB) {
            // We want to order the groups in an ascending order.
            // This mean, groups with smaller created unix time should go first.
            // So if groupA.created is smaller than groupB.created, a negative value will be returned,
            // reflecting this to the sort function.
            return groupA.created - groupB.created;
        });

        // Now that the array is sorted, the oldest group (the first one) should be set as the default one.
        if (groups.length) {
            const defaultGroup = utils.findFirst(
                groups,
                (group) => !group.dashboardHidden && _this.allowedBusinessReportIdsSet?.[group.id],
            );
            _this.groupDefaultId = defaultGroup ? defaultGroup.id : null;
        }

        // In case of free membership user, set the one free group of the project (the one's he's allowed to use).
        if (_this.project && _this.project.isLimitedLicense) {
            // Make sure the metadata exists.
            if (!_this.project.metadata) {
                _this.project.metadata = {};
            }

            if (_this.project.metadata.freeMembershipSelectedGroup) {
                // We first try to take the freeMembershipGroupId from the metadata.
                // It will be there if the user has overwritten the settings by selecting a specific group.
                _this.freeMembershipGroupId = _this.project.metadata.freeMembershipSelectedGroup;
            } else {
                _this.freeMembershipGroupId = _this.groupDefaultId;
            }
        }

        let hasLiveReports = false;
        // check if there are any live reports
        for (i = 0; i < groups.length; i++) {
            if (groups[i] && !groups[i].dashboardHidden) {
                hasLiveReports = true;
                break;
            }
        }
        _this.hasLiveReports = hasLiveReports;

        _this.groupsMap = map;
        _this.groups = groups;

        return _this.groups;
    }

    _this.cacheGroups = cacheGroups;

    /**
     * Handle the fetching, loading, error state for all fields
     * @param {String} propName The field name in the manager
     * @param {Function} func The function to be used to  fetch the data. Must return a promise.
     * @param {[]} [args] The args for the function.
     * @param {Boolean} [clear] whether to delete the cached data before fetching.
     * @param {Boolean} [forceUpdate] When true, got and fetch the data anyway (without deleting the old data).
     * @param {Function} [mapper] a function that gets the data from server and returns that data that should be used.
     * @param overrideCacheInvalidationTimeMilliseconds If given, will override the cache invalidation time defined for the entire project manager service.
     * @return {*} a promise.
     */
    function goFetch(propName, func, args, clear, forceUpdate, mapper, overrideCacheInvalidationTimeMilliseconds) {
        if (authenticationService.currentUser && authenticationService.currentUser.isGuest) {
            // if view only, means it's a fake obj, so we will return empty
            if (!_this[propName]) {
                _this[propName] = {};
            }
            _this[propName] = angular.isFunction(mapper) ? mapper(_this[propName]) : _this[propName];
            return $q.resolve(_this[propName]);
        }

        if (clear) {
            delete _this[propName];
        }

        if (!dataFields.includes(propName)) {
            dataFields.push(propName);
        }

        const cacheIsOld = _this.loadTime[propName]
            ? DeprecatedDate.nowAsDate() - _this.loadTime[propName] >
              (overrideCacheInvalidationTimeMilliseconds || cacheTime)
            : false;

        if ((!_this.loading[propName] && (!_this[propName] || forceUpdate || cacheIsOld)) || !promises[propName]) {
            _this.loading[propName] = true;
            delete _this.error[propName];
            const innerPromise = (promises[propName] = func.apply(null, args).then(function (data) {
                _this[propName] = angular.isFunction(mapper) ? mapper(data) : data;
                _this.loadTime[propName] = new Date();
                return _this[propName];
            }));
            innerPromise
                .catch(function (error) {
                    _this.error[propName] = error;
                })
                .finally(function () {
                    _this.loading[propName] = false;
                });
        }

        return promises[propName];
    }

    _this.isSelectedProjectInTrial = function () {
        return _this.isProjectInTrial(_this.project);
    };

    _this.isProjectInTrial = function (project) {
        if (!project) {
            return true;
        }

        return project.isInTrial && !project.licensed && !project.isEnterprise;
    };

    _this.isProjectLicensedAndNotEnterprise = function (project) {
        if (!project) {
            return false;
        }

        return project.licensed && !project.isEnterprise;
    };

    // endregion
}

angular.module('tonkean.app').service('projectManager', ProjectManager);
