import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ConcatFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_CONCAT;
    override readonly sign = 'Concat';
    override readonly displayName = 'Concatenate';
    override readonly description = 'Will concatenate two or more fields text value.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Value',
            pluralName: 'values',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                dataType: FieldType.String,
                displayName: `value ${repeatIndex}`,
            }),
        },
    ];
}
