import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CreditCardCtrl($scope, environment, $log, $q, utils) {
    const ctrl = this;
    let stripe;

    $scope.card = {};

    ctrl.$onInit = function () {
        // If the Stripe script is not already loaded, we load it while showing the user a loading circle.
        // This shouldn't happen if the user doesn't load this page specifically (using a url) because Stripe
        // is already loaded in the prior upper organization page (product.license state).
        /* jshint newcap: false */
        if (typeof Stripe === 'undefined' || Stripe === undefined || Stripe === null) {
            utils.loadScript('https://js.stripe.com/v3/', function () {
                stripe = Stripe(environment.apps.stripeAppId);
                addStripeElements(stripe);
                ctrl.loadingStripeComplete();

                $log.info('Stripe loaded');
            });
        } else {
            stripe = Stripe(environment.apps.stripeAppId);
            addStripeElements(stripe);
            ctrl.loadingStripeComplete();
        }

        // Add out submit function to the given sync object.
        ctrl.syncObject.submit = submitCard;
    };

    function addStripeElements(stripeObject) {
        const elements = stripeObject.elements();

        // Create Stripe's card element.
        // We hide the postal code because we collect it separately.
        // We add some styling to the card element looks the same as all of our form controls.
        $scope.card = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: '14px',

                    '::placeholder': {
                        color: '#999',
                    },
                },
            },
        });

        // Mount Stripe's card element into our container.
        $scope.card.mount('#frmCard');
    }

    function submitCard() {
        return $q(function (resolve, reject) {
            stripe.createToken($scope.card, ctrl.cardExtraData).then(function (result) {
                // If we got an error, show an error message to the user.
                if (result.error) {
                    reject(result.error.message);
                } else {
                    // Otherwise, we succeeded! Send the token down the flow.
                    resolve(result.token);
                }
            });
        });
    }
}
export default angular.module('tonkean.app').controller('CreditCardCtrl', lateConstructController(CreditCardCtrl));
