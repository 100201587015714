import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import SolutionRowView from './SolutionRowView';
import TableLoaderView from './TableLoaderView';
import type UpdateOverviewProp from '../../../EnterpriseComponentsModule/utils/UpdateOverviewProp';

import {
    LIMIT_PARAM,
    SimpleErrorStateMessage,
    SKIP_PARAM,
    useFetchManager,
    useModifiableList,
} from '@tonkean/infrastructure';
import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';

const TableWrapper = styled.div<{ currentHeight?: number }>`
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    max-width: 100%;
`;

const TableHeader = styled.div`
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    color: ${Theme.current.palette.mainColors.gray_600};
    border-bottom: 1px solid ${Theme.current.palette.mainColors.gray_400};
    padding-bottom: 10px;
`;

const TableHeaderMakers = styled(TableHeader)`
    padding-left: 5px;
`;

const ErrorContainer = styled.div`
    grid-column: 1 / -1;
    margin: 10px;
`;

const LoadMoreButton = styled(Button)`
    width: 120px;
    margin-top: 30px;
`;

const NoSolutionsRow = styled.div`
    margin-top: 10px;
    font-size: 12px;
`;

interface Props {
    enterpriseComponentId: string;
    enterpriseComponentType: EnterpriseComponentType;
    onError: (errText: string) => void;
    updateOverview: UpdateOverviewProp<any>;
    filterValue: string;
}

const SolutionsTable: React.FC<Props> = ({
    enterpriseComponentId,
    enterpriseComponentType,
    onError,
    updateOverview,
    filterValue,
}) => {
    const projectManager = useAngularService('projectManager');
    const tonkeanService = useAngularService('tonkeanService');

    const [
        [getEnterpriseComponentAllWorkflowFoldersAccess, cancelFetcher],
        {
            error: errorEnterpriseComponentWorkflowFolderAccessList,
            loading: isLoadingEnterpriseComponentWorkflowFolderAccessList,
            data: enterpriseComponentWorkflowFolderAccessList,
            loadNextPage,
            hasMorePages,
            isFetched,
        },
    ] = useFetchManager(tonkeanService, 'getEnterpriseComponentAllWorkflowFoldersAccess', {
        compareItems(item1, item2) {
            return item1.workflowFolder.id === item2.workflowFolder.id;
        },
        limit: 40,
    });

    // On load project integration we want to request the solutions.
    useEffect(() => {
        if (enterpriseComponentId) {
            getEnterpriseComponentAllWorkflowFoldersAccess(
                enterpriseComponentId,
                enterpriseComponentType,
                projectManager.project.id,
                SKIP_PARAM,
                LIMIT_PARAM,
            );
            return cancelFetcher;
        }
    }, [
        cancelFetcher,
        getEnterpriseComponentAllWorkflowFoldersAccess,
        projectManager,
        enterpriseComponentId,
        enterpriseComponentType,
    ]);

    const workflowFolderAccessList = useMemo(() => {
        return enterpriseComponentWorkflowFolderAccessList.map((solution) => ({
            id: solution.workflowFolder.id,
            displayName: solution.workflowFolder.displayName,
            projectId: solution.workflowFolder.project.id,
            accessAllowed: solution.accessAllowed,
        }));
    }, [enterpriseComponentWorkflowFolderAccessList]);

    const [workflowFolderModifiableList, { onUpdate: onAllowAccessChange }] =
        useModifiableList(workflowFolderAccessList);

    useEffect(() => {
        if (workflowFolderModifiableList && isFetched) {
            updateOverview(() => {
                const allowedWorkflowFolders = workflowFolderModifiableList.filter(
                    (workflowFolder) => workflowFolder.accessAllowed,
                );

                return {
                    allowedSolutionsCount: allowedWorkflowFolders.length,
                    allowedSolutionsStepStatus: allowedWorkflowFolders.length
                        ? EnterpriseComponentOverviewStepStatus.VALID
                        : EnterpriseComponentOverviewStepStatus.WARNING,
                };
            });
        }
    }, [
        isFetched,
        isLoadingEnterpriseComponentWorkflowFolderAccessList.any,
        updateOverview,
        workflowFolderModifiableList,
    ]);

    // Memo for filtering the solutions list by the filter input box.
    const filteredSolutions = useMemo(() => {
        if (!filterValue) {
            return workflowFolderModifiableList;
        } else {
            return workflowFolderModifiableList.filter((enterpriseComponentWorkflowFolderAccess) => {
                return enterpriseComponentWorkflowFolderAccess.displayName
                    .toLowerCase()
                    .includes(filterValue.toLowerCase());
            });
        }
    }, [filterValue, workflowFolderModifiableList]);

    // Filtered solution count for display.
    const filteredSolutionsCount = filteredSolutions?.length || 0;

    return (
        <>
            <TableWrapper>
                <TableHeader>Solutions ({filteredSolutionsCount})</TableHeader>
                <TableHeaderMakers>Solution Makers</TableHeaderMakers>
                <TableHeader>Allow Access</TableHeader>

                {filteredSolutions.map((solutionsAccess) => (
                    <SolutionRowView
                        key={solutionsAccess.id}
                        enterpriseComponentWorkflowFolderAccess={solutionsAccess}
                        enterpriseComponentId={enterpriseComponentId}
                        enterpriseComponentType={enterpriseComponentType}
                        onError={onError}
                        onAllowAccessChange={onAllowAccessChange}
                    />
                ))}

                {filterValue && filteredSolutions.length === 0 && (
                    <NoSolutionsRow>No solutions matched the filter...</NoSolutionsRow>
                )}

                {errorEnterpriseComponentWorkflowFolderAccessList && (
                    <ErrorContainer>
                        <SimpleErrorStateMessage
                            error={errorEnterpriseComponentWorkflowFolderAccessList}
                            showSmallError={!!enterpriseComponentWorkflowFolderAccessList.length}
                        />
                    </ErrorContainer>
                )}

                {isLoadingEnterpriseComponentWorkflowFolderAccessList.any && <TableLoaderView />}
            </TableWrapper>

            {hasMorePages && <LoadMoreButton onClick={loadNextPage}>Load more</LoadMoreButton>}
        </>
    );
};

export default SolutionsTable;
