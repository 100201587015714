import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import FormsList from './FormsList';
import FormsOutlineHeader from './FormsOutlineHeader';
import useEnvironmentFlags from '../../../../infrastructure/hooks/useEnvironmentFlags';

import { FormType } from '@tonkean/tonkean-entities';
import type { Form } from '@tonkean/tonkean-entities';

// Uses legacy colors and no outline to act exactly as all the other alerts in the outline
const ProductionAlert = styled.button`
    width: 100%;
    padding: 5px;
    margin: 16px 10px;
    outline: none;
    background-color: #fcf8e3;
    color: #8a6d3b;
    border: 1px solid #faebcc;
    border-radius: 4px;
    text-align: left;
`;

interface Props {
    formIdToFormMap: Record<string, Form>;
    switchToDraftDialog: (forceDialog: boolean) => void;
    createNewForm: (formType: FormType) => void;
    editForm: (form: Form) => void;
    isLoading: boolean;
    selectedFormId: string;
}

const FormsWorkerOutline: React.FC<Props> = ({
    formIdToFormMap,
    switchToDraftDialog,
    createNewForm,
    editForm,
    isLoading,
    selectedFormId: editFormId,
}) => {
    const { isDraft } = useEnvironmentFlags();

    const [searchTerm, setSearchTerm] = useState('');

    const { createForms, updateForms } = useMemo(() => {
        const formsCreate: Form[] = [];
        const formsUpdate: Form[] = [];

        Object.values(formIdToFormMap || {}).forEach((form) => {
            if (!form.displayName.toLowerCase().includes(searchTerm.toLowerCase())) {
                return;
            }

            if (form.formType === FormType.CREATE) {
                formsCreate.push(form);
            }
            if (form.formType === FormType.UPDATE) {
                formsUpdate.push(form);
            }
        });

        return { createForms: formsCreate, updateForms: formsUpdate };
    }, [formIdToFormMap, searchTerm]);

    return (
        <>
            {!isDraft && (
                <div className="flex">
                    <ProductionAlert onClick={() => switchToDraftDialog(true)}>
                        Cannot be edited in a published version.
                    </ProductionAlert>
                </div>
            )}

            <FormsOutlineHeader searchTerm={searchTerm} onChange={setSearchTerm} createNewForm={createNewForm} />

            <FormsList
                forms={createForms}
                searchTerm={searchTerm}
                header="Create Forms"
                subHeader="Create forms can be filled from the tonk slack command or the business report"
                editForm={editForm}
                isLoading={isLoading}
                selectedFormId={editFormId}
            />

            <FormsList
                forms={updateForms}
                searchTerm={searchTerm}
                header="Update Forms"
                subHeader="Update forms can be sent using the send form action"
                editForm={editForm}
                isLoading={isLoading}
                selectedFormId={editFormId}
            />
        </>
    );
};

export default FormsWorkerOutline;
