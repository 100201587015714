import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const FormSettingsSectionHeaderSubtitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    font-style: italic;
    line-height: 1.6;
`;

export default FormSettingsSectionHeaderSubtitle;
