import React, { useMemo } from 'react';
import styled from 'styled-components';

import CommentableCard from './CommentableCard';
import CommentView from './CommentView';

import type {
    Activity,
    NewActivityData,
    Person,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const CommentTitle = styled.span`
    color: #34393e;
    font-weight: 500;
`;

const Via = styled.span`
    color: #9ca6b2;
    font-style: italic;
    font-weight: 500;
`;

interface CommentCardProps {
    comment: Activity;
    currentUser: Person;
    defaultShowAddCommentForm?: boolean;
    threadMaxHeight?: number | undefined;
    onCommentAdded: (newActivity: NewActivityData) => void;
    onCommentDelete: (commentId: string, targetId?: string) => void;
    canUserEditItem: boolean;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType: WorkflowVersionType;
    defaultCommentText?: string;
    disableReplyToComment?: boolean;
}

const CommentCard: React.FC<CommentCardProps> = ({
    comment,
    currentUser,
    onCommentDelete,
    initiativeId,
    workflowVersionType,
    defaultCommentText,
    disableReplyToComment,
    ...commentableCardProps
}) => {
    const commenterIsCurrentUser = comment.actor.id === currentUser.id;
    const userName = commenterIsCurrentUser && !comment.reference1.onBehalfOfFullName ? 'You' : comment.actor.name;
    let eventText: string | JSX.Element;
    if (comment.reference1.onBehalfOfFullName) {
        eventText = (
            <>
                <CommentTitle>{comment.reference1.onBehalfOfFullName} </CommentTitle>
                <Via>(via {userName})</Via>
            </>
        );
    } else {
        eventText = `${userName} commented`;
    }
    const timestamp = comment.reference1.created;
    const sortedComments = useMemo(() => {
        return comment?.comments?.sort((first, second) => first.created - second.created);
    }, [comment.comments]);

    return (
        <CommentableCard
            {...commentableCardProps}
            targetId={comment.id}
            comments={sortedComments}
            currentUser={currentUser}
            onCommentDelete={onCommentDelete}
            initiativeId={initiativeId}
            workflowVersionType={workflowVersionType}
            dataAutomation="comment-card"
            commentTimestamp={timestamp}
            disableReplyToComment={disableReplyToComment}
        >
            <CommentView
                commentId={comment.id}
                commentText={comment.reference1.text}
                created={comment.created}
                actor={comment.actor}
                eventText={eventText}
                onCommentDelete={commenterIsCurrentUser ? onCommentDelete : undefined}
                onBehalfOfFullName={comment.reference1.onBehalfOfFullName}
            />
        </CommentableCard>
    );
};

export default CommentCard;
