import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function SendEmailLogicConfigurationCtrl(
    $scope,
    tonkeanUtils,
    communicationIntegrationsService,
    authenticationService,
    projectManager,
) {
    const ctrl = this;
    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.tonkeanUtils = tonkeanUtils;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        overrideTitle: ctrl.overrideTitle,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        personSelectionConfiguration: null,
        existingPersonSelectionConfiguration: null,
        externalEmailAddresses: [],
        emailText: null,
        emailSubject: null,
        externalEmails: [],
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {};

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    /**
     * Occurs once the person selection configuration is changed.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.personSelectionConfiguration = personSelectionConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                fieldDefinitionsToCreate: [],
                actions: [],
            };

            const action = tonkeanUtils.enrichPersonActionDefinition(
                'SEND_EMAIL',
                actionDefinition,
                $scope.data.personSelectionConfiguration,
                $scope.data.emailText,
            );

            action.definition.subject = $scope.data.emailSubject;
            action.definition.receipientSpecificEmails = $scope.data.externalEmails;

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Edit mode initialization.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'SEND_EMAIL',
        );

        if (customTriggerAction) {
            $scope.data.emailText = customTriggerAction.customTriggerActionDefinition.text;
            $scope.data.emailSubject = customTriggerAction.customTriggerActionDefinition.subject;
            $scope.data.externalEmails = customTriggerAction.customTriggerActionDefinition.receipientSpecificEmails
                ? customTriggerAction.customTriggerActionDefinition.receipientSpecificEmails
                : [];
            $scope.data.existingPersonSelectionConfiguration = customTriggerAction.customTriggerActionDefinition;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('SendEmailLogicConfigurationCtrl', lateConstructController(SendEmailLogicConfigurationCtrl));
