import type { FormEvent } from 'react';
import React, { useState } from 'react';

import AmazonS3PermissionType, {
    permissionInfoTypeToDisplayName,
    permissionTypeToDisplayName,
} from './AmazonS3PermissionType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { InputSize } from '@tonkean/tui-theme/sizes';

const AmazonS3AuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [region, setRegion] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');
    const [bucket, setBucket] = useState<string | undefined>();
    const [permissionType, setPermissionType] = useState<AmazonS3PermissionType>(AmazonS3PermissionType.GLOBAL);

    const authenticateWithClientIdAndSecret = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                api_region: region,
                api_client: clientId,
                api_secret: clientSecret,
                permissionType,
                bucket,
            };
            authenticate(config, { bucket, permissionType }, `${integrationConfiguration.displayName} (${region})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithClientIdAndSecret}>
                <div className="flex api-token-authentication">
                    {integrationConfiguration.name === 'amazons3' && (
                        <div className="form-group">
                            <label htmlFor="login-api-permission" className="col-sm-3 control-label">
                                Permission Type
                            </label>
                            <div className="col-sm-8">
                                <RadioGroup
                                    value={permissionType}
                                    direction="column"
                                    onChange={setPermissionType}
                                    size={InputSize.MEDIUM}
                                >
                                    <Radio value={AmazonS3PermissionType.GLOBAL}>
                                        {permissionTypeToDisplayName[AmazonS3PermissionType.GLOBAL]}
                                        <Tooltip
                                            content={permissionInfoTypeToDisplayName[AmazonS3PermissionType.GLOBAL]}
                                        >
                                            <i className="margin-left flex-no-shrink fa fa-info-circle" />
                                        </Tooltip>
                                    </Radio>
                                    <Radio value={AmazonS3PermissionType.RESTRICTED}>
                                        {permissionTypeToDisplayName[AmazonS3PermissionType.RESTRICTED]}
                                        <Tooltip
                                            content={permissionInfoTypeToDisplayName[AmazonS3PermissionType.RESTRICTED]}
                                        >
                                            <i className="margin-left flex-no-shrink fa fa-info-circle" />
                                        </Tooltip>
                                    </Radio>
                                </RadioGroup>
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="login-api-region" className="col-sm-3 control-label">
                            Region
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={region}
                                className="form-control"
                                id="login-api-region"
                                onChange={({ target }) => setRegion(target.value)}
                                autoComplete="off"
                                placeholder="Region"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-client" className="col-sm-3 control-label">
                            Access Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={clientId}
                                className="form-control"
                                id="login-api-client"
                                onChange={({ target }) => setClientId(target.value)}
                                autoComplete="off"
                                placeholder="Access Key"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-secret" className="col-sm-3 control-label">
                            Secret Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={clientSecret}
                                className="form-control"
                                onChange={({ target }) => setClientSecret(target.value)}
                                id="login-api-secret"
                                autoComplete="off"
                                placeholder="Secret Key"
                                required
                            />
                        </div>
                    </div>
                    {permissionType === AmazonS3PermissionType.RESTRICTED && (
                        <div className="form-group">
                            <label htmlFor="login-api-bucket" className="col-sm-3 control-label">
                                Bucket Name
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    value={bucket}
                                    className="form-control"
                                    onChange={({ target }) => setBucket(target.value)}
                                    id="login-api-bucket"
                                    autoComplete="off"
                                    placeholder="Bucket Name"
                                    required
                                />
                            </div>
                        </div>
                    )}

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AmazonS3AuthenticationComponent;
