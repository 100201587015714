export function GmailModalCtrl(
    $scope,
    $rootScope,
    consts,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    projectManager,
    environment,
) {
    $scope.temp = {};

    $scope.data = {
        isUsingGCPServiceAccount: projectManager.project.features.tonkean_feature_gcp_service_account_enabled,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        // Extract Send Email flag
        const projectDatas = settings?.projectIntegration?.projectData?.projectDatas;
        const relevantEntryWithEmails = projectDatas?.find((entry) => entry.hasOwnProperty('sendEmails'));

        if (relevantEntryWithEmails) {
            $scope.data.sendEmails = relevantEntryWithEmails.sendEmails;
        } else {
            $scope.data.sendEmails = false;
        }

        tryLoadFromEdit();
    };

    async function createIntegrationUsingOAuth() {
        const redirectUri = environment.integrationKeysMap.googleUri;
        const code = await oauthHandler.google(
            environment.integrationKeysMap.google,
            consts.getGmailScopes(),
            redirectUri,
            true,
        );
        const config = { code, redirectUri, environment: 'PUBLIC' };
        return createProjectApis.createIntegration(
            projectManager.project.id,
            $scope.currentIntegration.name,
            config,
            integrations.getIntegrationUniqueType(
                authenticationService.currentUser.id,
                'GMAIL',
                $scope.otherIntegrationOwner,
            ),
            undefined,
        );
    }

    $scope.authorize = async function (config) {
        $scope.connecting = true;
        $scope.error = null;

        try {
            if ($scope.data.isUsingGCPServiceAccount) {
                $scope.integration = await createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'GMAIL',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            } else {
                $scope.integration = await createIntegrationUsingOAuth();
            }
        } catch (error) {
            $scope.error = error;
        } finally {
            const s = {
                selected: $scope.selected,
                integration: $scope.integration,
            };

            s.integrations = [
                {
                    integration: $scope.integration,
                    projectData: {
                        sendEmails: $scope.data.sendEmails,
                    },
                },
            ];
            $scope.connecting = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const dataSource = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        dataSource.integrations = [
            {
                integration: $scope.integration,
                projectData: {
                    sendEmails: $scope.data.sendEmails,
                },
            },
        ];

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(dataSource);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });

            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}
export default angular.module('tonkean.shared').controller('GmailModalCtrl', GmailModalCtrl);
