import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const AdminPermissionWarning = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.warning};
    margin-bottom: 10px;
`;

const InsertDomain = styled.span`
    font-weight: bold;
    flex-shrink: 0;
`;

const DomainInput = styled.div`
    display: flex;
    align-items: center;
`;

const GoogleGroupSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    projectIntegration,
    createProjectApis,
    onChangeOrInitIntegration,
}) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [domain, setCurrentDomain] = useState<string>('');
    const [shouldDisplayLoader, setShouldDisplayLoader] = useState<boolean>(false);
    const [isConnectionSucceed, setIsConnectionSucceed] = useState<boolean>(false);
    const projectManager = useAngularService('projectManager');

    // On every change of the project integration, we initialize current domain froms project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setCurrentDomain(projectData.domain);
        }
    }, [projectIntegration]);

    // On every change of the integration, we reset the state if the integration id was changed.
    useEffect(() => {
        // If the user changed the integration credentials (logged in to a different user) -
        // it means that the current project data is irrelevant and we should reset the component state.
        if (integration?.id !== projectIntegration?.integration?.id) {
            setCurrentDomain('');
        }
    }, [integration, projectIntegration]);

    const onDomainChanged = useCallback((domainEvent) => {
        setCurrentDomain(domainEvent.target.value);
    }, []);

    const selectDomain = async () => {
        const projectData = {
            domain,
        };

        try {
            setShouldDisplayLoader(true);
            const groupDomains = await createProjectApis.getAutoCompleteOptions(
                projectManager.project.id,
                integration.id,
                'domains',
                projectData,
            );

            if (groupDomains.options.length === 0) {
                setError('No Google Groups found for the selected domain');
            } else {
                setIsConnectionSucceed(true);
                onChangeOrInitIntegration(
                    {
                        projectIntegrationData: projectData,
                        projectIntegrationDisplayName: `Google Groups (${domain})`,
                    },
                    false,
                );
            }
        } catch (error_) {
            const errorMessage = (error_ as IHttpResponse<any>).data?.error?.cause?.message;
            setError(`Error occurred while trying to connect the integration. ${errorMessage || ''}`);
        } finally {
            setShouldDisplayLoader(false);
        }
    };

    return (
        <>
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}
            <AdminPermissionWarning>
                * The user you’re connecting the data source with must have admin permissions.
            </AdminPermissionWarning>
            <DomainInput>
                <Tooltip content="The domain of your organization in google, Consult with your IT if not sure what is the domain.">
                    <i className="margin-right flex-no-shrink fa fa-info-circle" />
                </Tooltip>
                <InsertDomain>Insert a Domain:</InsertDomain>
                <input
                    type="text"
                    className="flex-shrink flex-grow margin-right margin-left form-control common-size-xxs"
                    placeholder="ex: someDomain.xyz"
                    onChange={onDomainChanged}
                    value={domain}
                />
                <Button
                    className="flex-no-shrink"
                    size={ButtonSize.BIG}
                    disabled={!domain || shouldDisplayLoader}
                    onClick={selectDomain}
                >
                    {shouldDisplayLoader && <span className="loading" />}
                    {!shouldDisplayLoader && <span> Connect this domain</span>}
                </Button>
                {isConnectionSucceed && (
                    <i className="margin-left margin-right flex-no-shrink fa fa-check common-color-primary" />
                )}
            </DomainInput>
        </>
    );
};

export default GoogleGroupSetupComponent;
