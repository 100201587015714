import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MarketoCustomActionsDefinitionCtrl($scope, createProjectApis, projectManager) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        projectIntegration: ctrl.projectIntegration,
        validationObject: ctrl.validationObject,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        definition: ctrl.definition,
        possibleFileFormats: ['CSV', 'TSV', 'SSV'],
        possibleObjectSource: ['staticListName', 'staticListId', 'smartListName', 'smartListId'],
        possibleFields: [],
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'CREATE_EXPORT_JOB':
                if (!$scope.data.definition.fields) {
                    $scope.data.definition.fields = [];
                }
                if ($scope.data.projectIntegration) {
                    $scope.getFields();
                }
                break;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onSourceObjectIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sourceObjectIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCreatedAtTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.createdAtExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUpdatedAtTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.updatedAtExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addField = function () {
        $scope.data.definition.fields.push({});
    };

    $scope.removeField = function (index) {
        $scope.data.definition.fields.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onFieldNameChanged = function (index, selectedItem, shouldSaveLogic) {
        if (selectedItem && selectedItem.displayName) {
            $scope.data.definition.fields[index].fieldObject = selectedItem;
        }
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewColumnNameTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.fields[index].newColumnName = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Get Lead Fields
     */
    $scope.getFields = function () {
        createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.projectIntegration.integration.id, 'fields')
            .then((leadFields) => ($scope.data.possibleFields = leadFields.options))
            .catch(() => {
                $scope.data.loadingFieldsError = 'Unable to load available Lead Fields';
            });
    };
}

export default angular
    .module('tonkean.app')
    .controller('MarketoCustomActionsDefinitionCtrl', lateConstructController(MarketoCustomActionsDefinitionCtrl));
