import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { TnkFormSelector as FormSelector } from '@tonkean/angular-to-react-components';
import { Field, Radio, RadioGroup, SavingIndicator, Spacer } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const Hr = styled.hr`
    width: 100%;
    margin-bottom: 10px;
`;
const Note = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
`;

enum FormCollectionOption {
    NEW_COLLECTION = 'NEW_COLLECTION',
    EXISTING_COLLECTION = 'EXISTING_COLLECTION',
}
interface Props {
    workflowVersionId: string;
    groupId: string;
    formId: string; // this is the form id that is being edited
    onCollectionFormSelected: (collectionFormId: string | undefined) => void;
    selectedCollectionFormId?: string;
}

const FormSettingsCollectInnerItemsCollectionFormSelector: React.FC<Props> = ({
    workflowVersionId,
    groupId,
    formId,
    onCollectionFormSelected,
    selectedCollectionFormId,
}) => {
    const { editedSections } = useContext(FormBuilderContext);

    const [selectedCollectionOption, setSelectedCollectionOption] = useState(
        selectedCollectionFormId ? FormCollectionOption.EXISTING_COLLECTION : FormCollectionOption.NEW_COLLECTION,
    );
    const onCollectionOptionChange = (value: FormCollectionOption) => {
        setSelectedCollectionOption(value);
        if (value === FormCollectionOption.NEW_COLLECTION) {
            onCollectionFormSelected(undefined);
        }
    };
    return (
        <>
            <Hr />
            <Field
                label={
                    <>
                        <FormSettingsSectionHeaderTitle className="flex-vmiddle">
                            Answers Collection
                            <SavingIndicator state={editedSections[FormBuilderSections.CollectionFormSelector]} />
                        </FormSettingsSectionHeaderTitle>
                        <Spacer width={6} />
                        <FormSettingsSectionHeaderSubtitle className="common-normal-weight">
                            Choose the collection that will be used as the source of answers
                        </FormSettingsSectionHeaderSubtitle>
                    </>
                }
            >
                <RadioGroup value={selectedCollectionOption} onChange={onCollectionOptionChange} direction="row">
                    <Radio value={FormCollectionOption.NEW_COLLECTION}>Create new collection</Radio>
                    <Radio value={FormCollectionOption.EXISTING_COLLECTION}>Use existing collection</Radio>
                </RadioGroup>

                {selectedCollectionOption === FormCollectionOption.EXISTING_COLLECTION && (
                    <>
                        <Spacer height={8} />
                        <FormSelector
                            workflowVersionId={workflowVersionId}
                            groupId={groupId}
                            selectedFormId={selectedCollectionFormId}
                            onFormSelected={(collectionForm) => onCollectionFormSelected(collectionForm?.id)}
                            doNotIncludeFormIdsSet={{ [formId]: true }}
                            formQuestionTypes={[FormQuestionType.COLLECT_INNER_ITEMS]}
                            hideCreateForms
                            clearable
                        />
                        <Spacer height={6} />
                        <Note>If left blank will revert to Create new collection</Note>
                    </>
                )}
            </Field>
        </>
    );
};

export default FormSettingsCollectInnerItemsCollectionFormSelector;
