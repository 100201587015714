import React, { useMemo } from 'react';

import { FilterRow } from '@tonkean/infrastructure';

interface Props {
    label: string;
}

const WorkerRunsFiltersLabel: React.FC<React.PropsWithChildren<Props>> = ({ label, children }) => {
    const dataAutomation = useMemo(() => {
        const lowerLabel = label.toLowerCase().replaceAll(/\s/g, '-');
        return `${lowerLabel}-filter-label`;
    }, [label]);

    return (
        <FilterRow label={label} dataAutomation={dataAutomation}>
            {children}
        </FilterRow>
    );
};

export default WorkerRunsFiltersLabel;
