<div class="revert-draft-version-modal">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold mod-no-top">Discard build environment changes</h1>

    <!-- Explanation -->
    <div class="margin-bottom-xlg" ng-if="data.groupId">
        The changes below will be discarded and your module will be restored to the last saved version.
        <br />
        Please note this is an irreversible step.
    </div>
    <div class="margin-bottom-xlg" ng-if="data.solutionSitePageId">
        The changes below will be discarded and your solution site page will be restored to the last saved version.
        <br />
        Please note this is an irreversible step.
    </div>

    <div>
        <tnk-worker-audit-logs
            group-id="data.groupId"
            open-activity-actor-person-selection-callback="openActivityActorPersonSelection"
            selected-actor-people-to-filter-on="data.selectedActorPeopleToFilterOn"
            page-size="5"
            from-date="data.changesFromDate"
            only-activity-types="data.onlyActivityTypes"
            workflow-version-id="wvm.getDraftVersionFromCache(data.groupId).id"
            control-object="data.workerAuditLogsControlObject"
            append-filter-dialog-to-body="true"
            group-by-toggle-default="true"
            on-close-modal="(cancel)"
            target-type-id="data.solutionSitePageId"
            hide-group-by-toggle="data.solutionSitePageId ? true : undefined"
            entity-version-id="data.entityVersionId"
            sub-sequential-identifier="data.subSequentialIdentifier"
        ></tnk-worker-audit-logs>
    </div>

    <!-- Activity actor selection popover -->
    <div id="activity-actor-selection-popover-parent">
        <div id="activity-actor-selection-popover" tnk-dnd-undraggable>
            <div
                click-outside="resetActivityActorSelectionPopover()"
                outside-if-not="activity-actor-selection-popover, activity-actor-selection-popover-container, tag-item, autocomplete, track-leader-avatar, track-owner-name"
            >
                <div
                    uib-popover-template="'./activityActorSelectionPopover.template.html'"
                    popover-is-open="data.activityActorSelectionPopoverOpen"
                    popover-placement="bottom"
                ></div>
            </div>
        </div>
    </div>

    <!-- Form for reverting -->
    <form name="revertForm" ng-submit="revertDraftWorkflowVersion()">
        <!-- Revert button -->
        <div class="flex-vmiddle mod-justify-end margin-top-lg">
            <!-- Error -->
            <span
                class="common-color-danger margin-right"
                ng-if="data.errorRevertingDraftWorkflowVersion && data.groupId"
            >
                There was an error trying to revert draft workflow version.
            </span>
            <span
                class="common-color-danger margin-right"
                ng-if="data.errorRevertingDraftWorkflowVersion && data.solutionSitePageId"
            >
                There was an error trying to revert solution site page version.
            </span>

            <!-- Loading -->
            <span class="loading-small margin-right" ng-if="data.revertingDraftWorkflowVersion"></span>

            <!-- Revert button -->
            <button data-automation="revert-draft-version-modal-discard-button" type="submit" class="btn btn-danger">Discard Changes</button>
        </div>
    </form>
</div>
