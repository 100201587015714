import WorkdayReportsAuthenticationComponent from './WorkdayReportsAuthenticationComponent';
import workdayCircleImage from '../../../../apps/tracks/images/integrations/workday-circle.png';
import workdayImage from '../../../../apps/tracks/images/workday.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class WorkdayReportsIntegration extends IntegrationDefinitionBase {
    override name = 'workdayreports';
    override displayName = 'Workday Reports';
    override description = "Using only Workday's Custom  Reports";
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;

    override entities = [new IntegrationEntity('Record', 'Records')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(workdayCircleImage, '130px'),
        new IntegrationIcon(workdayImage, '130px'),
    );

    override customizedAuthenticationComponent = WorkdayReportsAuthenticationComponent;
}

export default WorkdayReportsIntegration;
