<div class="draft-changes-modal">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold mod-no-top">{{ data.modalTitleContent || 'Version Changes' }}</h1>

    <!-- Explanation -->
    <div ng-if="data.modalSubtitleContent">{{ data.modalSubtitleContent }}</div>

    <div class="margin-top-xlg">
        <tnk-worker-audit-logs
            group-id="data.groupId"
            open-activity-actor-person-selection-callback="openActivityActorPersonSelection"
            selected-actor-people-to-filter-on="data.selectedActorPeopleToFilterOn"
            page-size="5"
            workflow-version-id="data.workflowVersionId"
            only-activity-types="data.onlyActivityTypes"
            control-object="data.workerAuditLogsControlObject"
            on-close-modal="(cancel)"
            group-by-toggle-default="true"
            target-type-id="data.targetTypeId"
            group-by-toggle-default="data.groupByToggleDefault"
            hide-group-by-toggle="data.hideGroupByToggle"
            entity-version-id="data.entityVersionId"
            sub-sequential-identifier="data.subSequentialIdentifier"
        ></tnk-worker-audit-logs>
    </div>

    <!-- Activity actor selection popover -->
    <div id="activity-actor-selection-popover-parent">
        <div id="activity-actor-selection-popover" tnk-dnd-undraggable>
            <div
                click-outside="resetActivityActorSelectionPopover()"
                outside-if-not="activity-actor-selection-popover, activity-actor-selection-popover-container, tag-item, autocomplete, track-leader-avatar, track-owner-name"
            >
                <div
                    uib-popover-template="'./activityActorSelectionPopover.template.html'"
                    popover-is-open="data.activityActorSelectionPopoverOpen"
                    popover-placement="bottom"
                ></div>
            </div>
        </div>
    </div>
</div>
