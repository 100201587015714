<div class="margin-left margin-right margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <div ng-if="data.definition.customActionKey === 'ADD_MEMBER_TO_AUDIENCE'">
            <div class="margin-bottom-xs">Email Address:</div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="The member's email address"
                saved-expression="data.definition.emailAddressExpression"
                expression-unique-identifier="MailchimpMarketingEmailAddress"
                on-tonkean-expression-changed="onEmailAddressExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div class="margin-bottom-xs">
                Subscription Status:
                <i
                    class="fa fa-info-circle margin-left-xs"
                    uib-tooltip="Possible values: subscribed, unsubscribed, cleaned, pending, or transactional"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="The type of subscription"
                saved-expression="data.definition.subscriptionStatusExpression"
                expression-unique-identifier="MailchimpMarketingSubscriptionStatus"
                on-tonkean-expression-changed="onSubscriptionStatusExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
