import React from 'react';

import { lazyReactToAngular } from '@tonkean/angular-components';
import { LoadingCircle } from '@tonkean/infrastructure';

angular.module('tonkean.app').component(
    'tnkFieldValidationConfigurationModal',
    lazyReactToAngular(
        () => import('./FieldValidationConfigurationModal'),
        ['fieldValidation', 'onChange', 'onClose', 'open', 'displayName', 'groupId', 'workflowVersionId'],
        <LoadingCircle />,
    ),
);
