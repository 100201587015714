import React from 'react';
import styled from 'styled-components';

import PreviewMatchedEntityItems from './PreviewMatchedEntityItems';

import { Modal, ModalBody, ModalFooter, ModalHeader, ModalSize, XCloseButton } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 12px;
    top: 12px;
`;
const ModalSubtitle = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: 12px;
    margin-top: 6px;
`;

const ModalTitle = styled.div`
    font-size: 24px;
`;

interface Props {
    workflowVersionId: string;
    matchedEntityId: string;
    initiativeId?: string;

    open: boolean;

    onClose(): void;
}

const PreviewMatchedEntityItemsModal: React.FC<Props> = ({
    open,
    workflowVersionId,
    matchedEntityId,
    initiativeId,
    onClose,
}) => {
    return (
        <Modal open={open} onClose={onClose} size={ModalSize.LARGE} fixedWidth>
            <ModalHeader>
                <ModalTitle>Iterate Related Items Preview</ModalTitle>
                <ModalCloseButton size={ButtonSize.BIG} onClick={onClose} />
                <ModalSubtitle>Preview of the data source items that will be created as action items.</ModalSubtitle>
            </ModalHeader>

            <ModalBody>
                <PreviewMatchedEntityItems
                    workflowVersionId={workflowVersionId}
                    matchedEntityId={matchedEntityId}
                    initiativeId={initiativeId}
                />
            </ModalBody>

            <ModalFooter align="right" border>
                <Button onClick={onClose} cancel>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PreviewMatchedEntityItemsModal;
