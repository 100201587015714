import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import DocumentPreviewPageSelector from './DocumentPreviewPageSelector';
import DocumentPreviewPositionedElement from './DocumentPreviewPositionedElement';
import getPageHeight from '../utils/getPageHeight';
import getPageWidth from '../utils/getPageWidth';

import { OcrOutputType } from '@tonkean/tonkean-entities';
import type { OcrOutput } from '@tonkean/tonkean-entities';
import type { OcrPositionedElement } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { getIndexedCollection } from '@tonkean/utils';

const PageWrapper = styled.div<{ width: number; height: number }>`
    position: relative;
    background: #f7f7f9;
    border: 1px solid #eeeeee;
    border-radius: 4px;

    ${({ width }) => css`
        width: ${width}px;
    `};

    ${({ height }) => css`
        height: ${height}px;
    `};
`;

interface Props extends StyledComponentsSupportProps {
    page?: number;
    ocrOutput?: OcrOutput;
    ocrOutputType: OcrOutputType;
}

const DocumentPreviewStructured: React.FC<Props> = ({ className, ocrOutput, ocrOutputType, page }) => {
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>(0);

    useEffect(() => {
        if (ocrOutput?.pages?.[0]) {
            setSelectedPageNumber(0);
        }
    }, [ocrOutput]);

    const selectedPage = ocrOutput?.pages?.[page ?? selectedPageNumber];

    const displayedElements = useMemo<{ index: number; original: OcrPositionedElement }[]>(() => {
        if (selectedPage) {
            switch (ocrOutputType) {
                case OcrOutputType.WORDS:
                    return getIndexedCollection(
                        selectedPage.blocks
                            .flatMap((block) => block.paragraphs)
                            .flatMap((paragraph) => paragraph.words),
                    );

                case OcrOutputType.PARAGRAPHS:
                    return getIndexedCollection(selectedPage.blocks.flatMap((block) => block.paragraphs));

                case OcrOutputType.BLOCKS:
                    return getIndexedCollection(selectedPage.blocks);
            }
        }

        return [];
    }, [ocrOutputType, selectedPage]);

    return (
        <>
            {page === undefined && (
                <DocumentPreviewPageSelector
                    ocrOutput={ocrOutput}
                    selectedPageIndex={selectedPageNumber || 0}
                    onPageSelected={(pageNumber) => setSelectedPageNumber(pageNumber)}
                />
            )}

            {selectedPage && (
                <PageWrapper
                    width={getPageWidth(selectedPage)}
                    height={getPageHeight(selectedPage)}
                    className={className}
                >
                    {displayedElements.map((indexedDisplayedElement) => (
                        <DocumentPreviewPositionedElement
                            key={indexedDisplayedElement.index}
                            page={selectedPage}
                            displayedElement={indexedDisplayedElement.original}
                        />
                    ))}
                </PageWrapper>
            )}
        </>
    );
};

export default React.memo(DocumentPreviewStructured);
