import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BotFutureGathersCtrl(
    $scope,
    $q,
    $timeout,
    projectManager,
    tonkeanService,
    trackHelper,
    modalUtils,
    timeUtils,
    utils,
) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        filter: ctrl.filter || {},

        hasItems: false,
        loading: false,
        hardLoading: false, // If need to use the big spinner forcibly.
        sections: [
            {
                id: 'today',
                label: 'Est. for TODAY',
                predicate: (itemTime) => timeUtils.isToday(itemTime),
                items: [],
            },
            {
                id: 'tomorrow',
                label: 'Est. for TOMORROW',
                predicate: (itemTime) => timeUtils.isTomorrow(itemTime),
                items: [],
            },
            {
                id: 'nextweek',
                label: 'Est. for NEXT 7 DAYS',
                predicate: (itemTime) => timeUtils.isInNextSevenDays(itemTime),
                items: [],
            },

            // Default
            {
                id: 'later',
                label: 'Later',
                predicate: () => true,
                items: [],
            },
        ],
    };

    $scope.uiActions = {};

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.filter) {
            $scope.data.filter = changesObj.filter.currentValue;
            $scope.loadItems(true);
        } else if (changesObj.isVisible && changesObj.isVisible.currentValue) {
            $scope.loadItems();
        }
    };

    $scope.init = function () {
        $scope.initUiActions();
    };

    $scope.initUiActions = function () {
        $scope.uiActions = {
            goToTrack() {
                $scope.$apply($scope.goToTrack.apply(null, arguments));
            },

            loadItems() {
                const args = arguments;
                $timeout(() => $scope.loadItems.apply(null, args));
            },
        };
    };

    $scope.goToTrack = function (initiativeId) {
        // otherwise open in popup
        modalUtils.openViewInitiative(initiativeId);
    };

    $scope.loadItems = function (hardLoad) {
        $scope.data.loading = true;
        $scope.data.hardLoading = hardLoad;

        const filter = $scope.data.filter;
        const groupId = filter.group && filter.group.id;
        const ownerId = filter.owner && filter.owner.id;

        return tonkeanService
            .getNextGathers($scope.pm.project.id, groupId, ownerId)
            .then((data) => {
                const cachedItems = trackHelper.cacheItemList(data.entities);

                // Empty sections from stale data.
                for (let i = 0; i < $scope.data.sections.length; i++) {
                    const section = $scope.data.sections[i];
                    section.items = [];
                }

                $scope.data.hasItems = false;

                // Fill sections with fresh data.
                for (const item of cachedItems) {
                    for (let j = 0; j < $scope.data.sections.length; j++) {
                        const section = $scope.data.sections[j];
                        if (section.predicate(item.nextGatherUpdate)) {
                            $scope.data.hasItems = true;
                            section.items.push(item);
                            break;
                        }
                    }
                }

                // Group the received items to groups by owner
                for (let i = 0; i < $scope.data.sections.length; i++) {
                    const section = $scope.data.sections[i];
                    const groupedItems = utils.groupBy(section.items, (item) => item.owner && item.owner.id);
                    section.items = utils.objToArray(groupedItems);
                }
            })
            .finally(() => {
                $scope.data.loading = false;
                $scope.data.hardLoading = false;
            });
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('BotFutureGathersCtrl', lateConstructController(BotFutureGathersCtrl));
