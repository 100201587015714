function extractQueryParams(url: string): Record<string, string> {
    if (!url) {
        return {};
    }
    const queryParamsDict: Record<string, string> = {};

    const regex: any = '([?&])([^=]+)=([^&]+)';

    const regExpMatchArrays: RegExpMatchArray[] = [...url.matchAll(regex)];
    regExpMatchArrays.forEach((regExpMatchArray: RegExpMatchArray) => {
        if (regExpMatchArray.length > 3) {
            queryParamsDict[regExpMatchArray[2]!] = regExpMatchArray[3]!;
        }
    });

    return queryParamsDict;
}

export default extractQueryParams;
