import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import type { FieldDefinitionSummary, Initiative } from '@tonkean/tonkean-entities';

const useGetFieldValueFromInitiative = () => {
    const fieldDisplay = useAngularService('fieldDisplay');

    const getFieldValueFromInitiative = useCallback(
        (initiative: Initiative | undefined, field?: FieldDefinitionSummary) => {
            if (!initiative || !field) {
                return '';
            }

            if (field && initiative?.defIdToFieldsMap?.[field.fieldDefinitionId]) {
                const fieldValues = initiative?.defIdToFieldsMap?.[field.fieldDefinitionId][0];
                return fieldDisplay.evaluateFieldDisplayValue(
                    field?.evaluatedDisplayType,
                    field?.definedDisplayFormat,
                    fieldValues?.value,
                    fieldValues?.dateValue,
                    fieldValues?.formattedValue,
                );
            }

            switch (field.fieldDefinitionId) {
                case 'TNK_TITLE': {
                    return initiative?.title;
                }
                case 'TNK_DESCRIPTION': {
                    return initiative?.description;
                }
                case 'TNK_UPDATE_TEXT': {
                    return initiative?.updateText;
                }
                case 'TNK_DUE_DATE': {
                    return dayjs(initiative?.dueDate).format('MMM DD, YYYY');
                }
                case 'TNK_ETA': {
                    return dayjs(initiative?.eta).format('MMM DD, YYYY');
                }
                case 'TNK_STAGE': {
                    return initiative?.stateText;
                }
                case 'TNK_OWNER_ID': {
                    return initiative?.owner?.['email'];
                }
                case 'TNK_OWNER_EMAIL': {
                    return initiative?.owner?.['email'];
                }
                case 'TNK_OWNER_NAME': {
                    return initiative?.owner?.['name'];
                }
                default: {
                    return '';
                }
            }
        },
        [fieldDisplay],
    );

    return getFieldValueFromInitiative;
};

export default useGetFieldValueFromInitiative;
