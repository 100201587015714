import { useCallback, useMemo } from 'react';

import useEnterpriseComponentContext from './useEnterpriseComponentContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type {
    EnterpriseComponentVariable,
    EnterpriseComponentVariableValueType,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

const useSubmitEnterpriseComponentVariable = (
    enterpriseComponentVariableId: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE> | undefined,
): [
    { loading: boolean; error?: ErrorResponseType },
    (
        displayName: string,
        description: string,
        isRequired: boolean,
        isEncrypted: boolean,
        valueType: EnterpriseComponentVariableValueType,
    ) => Promise<EnterpriseComponentVariable>,
] => {
    const { id: projectId } = useProject();

    const {
        enterpriseComponent: { id: enterpriseComponentId },
    } = useEnterpriseComponentContext();

    const [{ error: createError, loading: createLoading }, createEnterpriseComponentVariable] = useLazyTonkeanService(
        'createEnterpriseComponentVariable',
    );

    const [{ error: updateError, loading: editLoading }, updateEnterpriseComponentVariable] = useLazyTonkeanService(
        'updateEnterpriseComponentVariable',
    );

    const submitEnterpriseComponentVariable = useCallback(
        (
            displayName: string,
            description: string,
            isRequired: boolean,
            isEncrypted: boolean,
            valueType: EnterpriseComponentVariableValueType,
        ) => {
            if (enterpriseComponentVariableId) {
                return updateEnterpriseComponentVariable(
                    enterpriseComponentVariableId,
                    projectId,
                    enterpriseComponentId,
                    displayName,
                    description,
                    isRequired,
                    isEncrypted,
                    valueType,
                );
            } else {
                return createEnterpriseComponentVariable(
                    projectId,
                    enterpriseComponentId,
                    displayName,
                    description,
                    isRequired,
                    isEncrypted,
                    valueType,
                );
            }
        },
        [
            createEnterpriseComponentVariable,
            enterpriseComponentId,
            enterpriseComponentVariableId,
            projectId,
            updateEnterpriseComponentVariable,
        ],
    );

    return useMemo(() => {
        return [
            { loading: createLoading || editLoading, error: createError || updateError },
            submitEnterpriseComponentVariable,
        ];
    }, [createError, createLoading, editLoading, submitEnterpriseComponentVariable, updateError]);
};

export default useSubmitEnterpriseComponentVariable;
