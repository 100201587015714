import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo } from 'react';

import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { CategoricalFieldInstanceChart } from '@tonkean/fields';
import { ChartDisplayType } from '@tonkean/infrastructure';
import type { FieldDefinition, SolutionSite } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

interface Props {
    solutionSite: SolutionSite;
    configuration: SolutionSiteFieldChartWidgetConfiguration;
    entityVersionType: WorkflowVersionType;
}

const SolutionSiteGropuByGlobalFieldFieldChartWidget: React.FC<Props> = ({
    solutionSite,
    configuration,
    entityVersionType,
}) => {
    const projectManager = useAngularService('projectManager');

    const { data: globalFieldDefinitions } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const filteredFields = useMemo<FieldDefinition[] | undefined>(() => {
        return globalFieldDefinitions?.entities?.filter((fieldDefinition) =>
            Boolean(configuration.categoricalFields?.[fieldDefinition.id]),
        );
    }, [globalFieldDefinitions?.entities, configuration.categoricalFields]);

    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const globalFields = useMemo(() => {
        return Object.assign(
            {},
            ...Object.values(groupsMap).map((groupMap: any) => {
                if (entityVersionType === WorkflowVersionType.PUBLISHED) {
                    return groupMap.globalFieldsMap;
                }
                return groupMap.globalFieldsDraftMap;
            }),
        );
    }, [groupsMap, entityVersionType]);

    const fieldInstance = useMemo(() => {
        if (globalFields && filteredFields?.[0]) {
            return globalFields[filteredFields[0].id];
        }
    }, [filteredFields, globalFields]);

    return (
        <CategoricalFieldInstanceChart
            fieldInstance={fieldInstance}
            fieldType={configuration.definition?.fieldType}
            fieldId={configuration.definition?.aggregatedDefinitionId}
            chartDisplayType={configuration.chartCategoricalDisplayType || ChartDisplayType.DOUGHNUT}
        />
    );
};

export default SolutionSiteGropuByGlobalFieldFieldChartWidget;
