import React from 'react';

import ConfigurationSectionTitle from '../../../../components/ConfigurationSectionTitle';
import type { InnerItemWidgetConfiguration } from '../../../entities';
import type { FieldsWidgetMenuItem } from '../../../FieldsWidgetModule';
import FilterAndSortItems from '../configurationEditorSettingsComponents/FilterAndSortItems';
import ItemCreationButtonCreateForm from '../configurationEditorSettingsComponents/ItemCreationButtonCreateForm';
import ItemInterfaceSelectorContainer from '../configurationEditorSettingsComponents/ItemInterfaceSelectorContainer';
import MinMaxRequirements from '../configurationEditorSettingsComponents/MinMaxRequirements';
import SelectCreateForm from '../configurationEditorSettingsComponents/SelectCreateForm';
import StyledWidgetConfigurationToggle from '../configurationEditorSettingsComponents/StyledWidgetConfigurationToggle';
import ItemsWidgetConfigurationEditorFieldSelector from '../ItemsWidgetConfigurationEditorFieldSelector';
import ItemsWidgetConfigurationEditorInterfaceSelector from '../ItemsWidgetConfigurationEditorInterfaceSelector';

import type { FieldDefinition, Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    configuration: InnerItemWidgetConfiguration;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    initiative: Initiative | undefined;
    fieldsAdditionalOptions?: FieldsWidgetMenuItem[];
    fieldDefinitions: FieldDefinition[] | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
}

const DetailedItemsWidgetConfigurationEditorDisplay: React.FC<Props> = ({
    configuration,
    groupId,
    initiative,
    fieldsAdditionalOptions,
    fieldDefinitions,
    workflowVersionId,
}) => {
    const minimumItemsRequired = configuration.minMaxRequirementConfiguration?.minimum ?? 0;

    return (
        <>
            <ItemInterfaceSelectorContainer>
                <ConfigurationSectionTitle>Items</ConfigurationSectionTitle>
                {groupId && workflowVersionId && (
                    <>
                        <ItemCreationButtonCreateForm supportImmediateItemCreation />

                        <SelectCreateForm
                            itemCreationType={configuration.itemCreationType}
                            workflowVersionId={workflowVersionId}
                            groupId={groupId}
                        />

                        <ItemsWidgetConfigurationEditorInterfaceSelector
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                        />

                        <MinMaxRequirements
                            minimumItemsRequired={minimumItemsRequired}
                            minMaxRequirementsEntityName="line items"
                        />

                        <FilterAndSortItems groupId={groupId} workflowVersionId={workflowVersionId} />

                        <StyledWidgetConfigurationToggle title="Emphasize Text" name="configuration.emphasizeText" />
                    </>
                )}
            </ItemInterfaceSelectorContainer>

            {workflowVersionId && (
                <ItemsWidgetConfigurationEditorFieldSelector
                    configuration={configuration}
                    fieldDefinitions={fieldDefinitions}
                    initiative={initiative}
                    workflowVersionId={workflowVersionId}
                    fieldsAdditionalOptions={fieldsAdditionalOptions}
                    supportCardsViewFields
                    fixedOrderForSpecialFields
                    titleFieldUnlocked
                    supportRequiredFields
                    supportAggregations
                />
            )}
        </>
    );
};

export default DetailedItemsWidgetConfigurationEditorDisplay;
