import styled, { css } from 'styled-components';

import { SearchWidgetCardsDensity } from '../SearchWidgetConfiguration';

import { Theme } from '@tonkean/tui-theme';

const SearchWidgetItemsList = styled.div<{ $itemsGap: number; $cardsDensity: SearchWidgetCardsDensity }>`
    display: flex;
    flex-direction: column;
    gap: ${({ $itemsGap }) => $itemsGap}px;
    ${({ $cardsDensity }) =>
        $cardsDensity === SearchWidgetCardsDensity.COMPACT &&
        css`
            height: fit-content;
            border: 1px solid ${Theme.colors.gray_300};
            border-radius: 8px;
        `}

    overflow: auto;
`;
export default SearchWidgetItemsList;
