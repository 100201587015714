import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { TnkSelect } from '@tonkean/infrastructure';
import type { Category } from '@tonkean/tonkean-entities';

type SelectorCategoryValues = { value: string; label: string }[];

interface Props {
    categories: Category[] | undefined;
    onChange: (categories: Category[]) => void;
}

const TemplateCategoriesSelector: React.FC<Props> = ({ categories, onChange }) => {
    const tonkeanService = useAngularService('tonkeanService');
    const { data: categoryData, loading: categoryLoading } = useAsyncMethod(tonkeanService, 'getAllTemplateCategories');
    const categoryOptions: SelectorCategoryValues = useMemo(() => {
        return categoryData?.categories.map((singleCategory) => ({
            value: singleCategory.id,
            label: singleCategory.title,
        }));
    }, [categoryData]);

    const selectedCategories = useMemo(() => {
        if (!categories) {
            return [];
        }

        return categories.map((singleCategory) => ({
            value: singleCategory.id,
            label: singleCategory.title,
        }));
    }, [categories]);

    const onChangeInternal = (value: SelectorCategoryValues) => {
        const categoriesValues = value?.map((item) => ({ id: item.value, title: item.label }));
        onChange(categoriesValues || []);
    };
    return (
        <TnkSelect
            placeholder="Select"
            options={categoryOptions}
            isLoading={categoryLoading}
            value={selectedCategories}
            onChange={onChangeInternal}
            isMulti
            thin
        />
    );
};

export default TemplateCategoriesSelector;
