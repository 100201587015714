import dayjs from 'dayjs';

import type WorkerRunDateRangeFilter from './WorkerRunDateRangeFilter';
import type WorkerRunsFilter from './WorkerRunsFilter';
import type WorkerRunStagesFilter from './WorkerRunStagesFilter';

import { WorkerRunStage } from '@tonkean/tonkean-entities';

const initialFilters: {
    stages: WorkerRunStagesFilter;
    dateRange(): WorkerRunDateRangeFilter;
    filters: WorkerRunsFilter;
} = {
    stages: {
        [WorkerRunStage.FLOW_RUN]: true,
        [WorkerRunStage.MODULE_ITEM]: false,
        [WorkerRunStage.DATA_SOURCE]: false,
    },
    dateRange: () => ({
        dateFrom: dayjs().subtract(1, 'week').set('minutes', 0).toDate(),
        dateTo: undefined,
    }),
    filters: {
        errors: 'all',
        showHasNotRun: false,
        trigger: undefined,
        version: undefined,
        backgroundProcess: undefined,
    },
};

export default initialFilters;
