import template from './tnkSearchInitiatives.template.html?angularjs';

angular.module('tonkean.app').component('tnkSearchInitiatives', {
    bindings: {
        groupId: '<',
        selectedInitiativeId: '<',
        canClear: '<',
        shouldSetDefaultInitiative: '<',
        onlyRoots: '<',
        onlyDraftInitiatives: '<',
        onInitiativeSelected: '&',
        defaultInitiativesAmount: '<',
    },
    template,
    controller: 'SearchInitiativesCtrl',
});
