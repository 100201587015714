import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import CreateOrEditItemInterfaceModal from './CreateOrEditItemInterfaceModal';
import InterfacesList from './InterfacesList';
import InterfacesWorkerOutlineEmptyState from './InterfacesWorkerOutlineEmptyState';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { ErrorMessage, LoadingCircle, Message as RawMessage, Separator } from '@tonkean/infrastructure';
import { useItemInterfaceCache } from '@tonkean/shared-services';
import type { ItemInterface, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const CreateBar = styled.div<{ $justifyContent: React.CSSProperties['justifyContent'] }>`
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: ${({ $justifyContent }) => $justifyContent};
    height: 45px;
`;

const Message = styled(RawMessage)`
    width: 90%;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    workflowVersionType: WorkflowVersionType;
}

const InterfacesWorkerOutline: React.FC<Props> = ({ workflowVersionId, workflowVersionType }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const customTriggerManager = useAngularService('customTriggerManager');
    const $state = useAngularService('$state');
    const $rootScope = useAngularService('$rootScope');
    const emit = useToastMessage();

    const [projectId, groupId, itemInterfaceId, createNewInterface] = useGetStateParams<
        [TonkeanId<TonkeanType.PROJECT>, TonkeanId<TonkeanType.GROUP>, TonkeanId<TonkeanType.ITEM_INTERFACE>, boolean]
    >('projectId', 'g', 'itemInterfaceId', 'createNew');

    const workflowFolder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectId, groupId);
    }, [groupId, projectId, workflowFolderManager]);

    const {
        getItemInterfaces,
        setItemInterfaces,
        onItemInterfaceDeleted,
        onItemInterfaceCreated,
        onItemInterfaceUpdated,
        setItemInterfaceDefault,
        setLoading,
        getLoading,
    } = useItemInterfaceCache();

    const {
        data,
        loading: itemInterfacesLoadingFromServer,
        error,
    } = useTonkeanService('getItemInterfacesSummaryByGroupId', groupId, workflowVersionType);
    const [showCreateItemInterfaceModal, setShowCreateItemInterfaceModal] = useState(!!createNewInterface);

    useEffect(() => {
        if (createNewInterface) {
            setShowCreateItemInterfaceModal(true);
        }
    }, [createNewInterface]);

    useEffect(() => {
        if (data?.entities) {
            setItemInterfaces(workflowVersionId, data?.entities);
        }
    }, [data?.entities, setItemInterfaces, workflowVersionId]);
    useEffect(() => {
        setLoading(
            workflowVersionId,
            itemInterfacesLoadingFromServer ? ItemInterfaceLoadingState.LOADING : ItemInterfaceLoadingState.IDLE,
        );
    }, [itemInterfacesLoadingFromServer, setLoading, workflowVersionId]);

    const onInterfaceCreated = useCallback(
        (newItemInterface: ItemInterface | undefined, itemInterfaceWasCreated?: boolean) => {
            onItemInterfaceCreated(newItemInterface);
            setShowCreateItemInterfaceModal(false);

            if (itemInterfaceWasCreated) {
                $state.go('.', { itemInterfaceId: newItemInterface?.id }, { location: 'replace' });
                $rootScope.$emit('onSidePaneClose');
            } else {
                $state.go('.', { createNew: undefined }, { location: 'replace' });
            }
        },
        [$rootScope, $state, onItemInterfaceCreated],
    );

    const [, updateDefaultItemInterface] = useLazyTonkeanService('updateDefaultItemInterface');
    const setAsDefaultItemInterface = useCallback(
        (itemInterfaceIdToUpdate: TonkeanId<TonkeanType.ITEM_INTERFACE>, isDefault: boolean) => {
            updateDefaultItemInterface(itemInterfaceIdToUpdate, groupId, isDefault)
                .then(() => {
                    setItemInterfaceDefault(workflowVersionId, itemInterfaceIdToUpdate, isDefault);
                })
                .catch(() => {
                    emit('There was an error updating the default custom interface', 'danger');
                });
        },
        [updateDefaultItemInterface, groupId, setItemInterfaceDefault, workflowVersionId, emit],
    );

    const [exampleItemId] = useAngularWatch(
        () => customTriggerManager.workflowVersionIdToExampleItemsMap[workflowVersionId]?.root,
    );

    const itemInterfaces = getItemInterfaces(workflowVersionId);

    const showLoading =
        getLoading(workflowVersionId) === ItemInterfaceLoadingState.LOADING && itemInterfaces.length === 0;
    const showEmptyState =
        getLoading(workflowVersionId) === ItemInterfaceLoadingState.IDLE && itemInterfaces.length === 0;

    const isDraft = workflowVersionType === WorkflowVersionType.DRAFT;

    if (!(getLoading(workflowVersionId) === ItemInterfaceLoadingState.LOADING) && error) {
        return <ErrorMessage>{error ? getStateError(error) : ''}</ErrorMessage>;
    }

    return (
        <div data-automation="interfaces-worker-outline">
            <ErrorBoundary fallback={<ErrorMessage>{error ? getStateError(error) : ''}</ErrorMessage>}>
                {showEmptyState ? (
                    <InterfacesWorkerOutlineEmptyState
                        onOpenNewInterfaceModal={isDraft ? () => setShowCreateItemInterfaceModal(true) : undefined}
                    />
                ) : (
                    <>
                        <CreateBar $justifyContent={isDraft ? 'flex-end' : 'center'}>
                            {isDraft ? (
                                <Button
                                    size={ButtonSize.SMALL}
                                    onClick={() => setShowCreateItemInterfaceModal(true)}
                                    data-automation="interface-worker-outline-add-new-interface-button"
                                    highlighted
                                >
                                    New Interface
                                </Button>
                            ) : (
                                <Message>Cannot be edited in production</Message>
                            )}
                        </CreateBar>
                        <Separator />
                        {showLoading ? (
                            <LoadingCircle large centered />
                        ) : (
                            workflowFolder && (
                                <InterfacesList
                                    itemInterfaces={itemInterfaces}
                                    workflowFolder={workflowFolder}
                                    onItemInterfaceCreated={onInterfaceCreated}
                                    onItemInterfaceUpdated={onItemInterfaceUpdated}
                                    onItemInterfaceDeleted={onItemInterfaceDeleted}
                                    groupId={groupId}
                                    workflowVersionType={workflowVersionType}
                                    exampleItemId={exampleItemId}
                                    setAsDefaultItemInterface={setAsDefaultItemInterface}
                                    itemInterfaceId={itemInterfaceId}
                                />
                            )
                        )}
                    </>
                )}
                {workflowFolder && (
                    <CreateOrEditItemInterfaceModal
                        open={showCreateItemInterfaceModal}
                        onClose={onInterfaceCreated}
                        workflowFolder={workflowFolder}
                        groupId={groupId}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
};

export default InterfacesWorkerOutline;
