<div ng-if="!data.displayInterface" class="fill-form margin-top-md">
    <div ng-if="singleFormConfiguration.loading">
        <div class="loading-wrapper">
            <tnk-loading-tonkean></tnk-loading-tonkean>
        </div>
    </div>
    <div
        class="single-form single-form-mobile"
        ng-repeat="singleFormConfiguration in data.formsConfigurations"
        ng-if="!data.finishedSequence"
    >
        <div
            class="container-md mod-no-xs common-z-index-forward fill-form-container"
            blocked-element="{{ data.viewMode === 'preview' }}"
            ng-class="{
                'expand-hovered': data.expandHovered,
                'wait-form-mode': data.loadingWaitForm
            }"
            data-ng-attr-style="{{ 'border-color: ' + data.selectedSecondaryColor + ' !important' }}"
        >
            <div ng-if="!singleFormConfiguration.loading">
                <!-- Form filling is still in progress -->
                <div
                    class="common-break-long form-wrapper"
                    ng-if="!areAllFormFinished() && !data.loadingWaitForm"
                    data-automation="fill-form-wrapper"
                >
                    <!-- Title -->
                    <div ng-if="!singleFormConfiguration.form.definition.hideDisplayName" class="form-title">
                        <span
                            class="common-title-sm form-title"
                            data-automation="fill-form-name"
                            data-automation-label="{{ data.selectedPrimaryColor }}"
                        >
                            {{
                                singleFormConfiguration.form.definition.clientDisplayName ||
                                    singleFormConfiguration.form.displayName
                            }}
                        </span>
                    </div>

                    <!-- Description/Subtitle -->
                    <div
                        class="form-subtitle"
                        bind-user-html="singleFormConfiguration.evaluatedDescription"
                        data-automation="fill-form-subtitle"
                    ></div>

                    <!-- Expandable Description -->
                    <tnk-expandable-form-description
                        ng-if="!!singleFormConfiguration.form"
                        expandable-description="singleFormConfiguration.evaluatedExpandableDescription"
                        form="singleFormConfiguration.form"
                        data-automation="fill-form-form-description-label"
                    ></tnk-expandable-form-description>

                    <!-- Selected inner item to add the initiative under -->
                    <div class="common-color-grey" ng-if="singleFormConfiguration.selectedAddUnderInitiative">
                        The new item will be added under the following item:
                        <a ng-click="openTrackModalView($index)">
                            {{ singleFormConfiguration.selectedAddUnderInitiative.title }}
                        </a>
                        <hr class="margin-normal-sm-v" />
                    </div>

                    <!-- Forms -->
                    <div class="margin-top-lg">
                        <!-- Collect Inner Items Form -->
                        <tnk-collect-inner-items-form
                            ng-if="
                                singleFormConfiguration.form &&
                                singleFormConfiguration.form.formQuestionType === 'COLLECT_INNER_ITEMS'
                            "
                            group-id="data.groupId"
                            workflow-version-type="data.formVersionType"
                            workflow-version-id="data.workflowVersionId"
                            form-config="singleFormConfiguration.form"
                            collection-form-config="singleFormConfiguration.collectionForm"
                            session-id="data.sessionId"
                            custom-trigger-id="singleFormConfiguration.customTriggerId"
                            initiative-id="singleFormConfiguration.initiativeId"
                            worker-run-id="singleFormConfiguration.workerRunId"
                            form-finished="singleFormConfiguration.formFinished"
                            primary-color="data.selectedPrimaryColor"
                            secondary-color="data.selectedSecondaryColor"
                            buttons-color="data.selectedButtonsColor"
                            on-submit="onSubmit($index, fields, ignoreNonRestrictingErrors)"
                            on-error="onError(errorMessage)"
                            items-validation-map="data.itemsValidationMap[$index]"
                            all-errors-not-restricting-errors="data.allErrorsNotRestrictingErrors[$index]"
                            solution-business-report-id="data.solutionBusinessReportId"
                            show-back="data.showBackButton"
                            on-back-clicked="getPreviousState()"
                        ></tnk-collect-inner-items-form>

                        <!-- Update Fields Form -->
                        <tnk-update-fields-form
                            ng-if="
                                singleFormConfiguration.form &&
                                (!singleFormConfiguration.form.formQuestionType ||
                                    singleFormConfiguration.form.formQuestionType === 'UPDATE_FIELDS')
                            "
                            group-id="singleFormConfiguration.form.group.id"
                            fields="singleFormConfiguration.form.definition.fields"
                            workflow-version-id="singleFormConfiguration.form.workflowVersion.id"
                            worker-run-id="singleFormConfiguration.workerRunId"
                            form-config="singleFormConfiguration.form"
                            prepopulated-values="singleFormConfiguration.prepopulatedValues"
                            custom-trigger-id="singleFormConfiguration.customTriggerId"
                            form-finished="singleFormConfiguration.formFinished"
                            session-id="data.sessionId"
                            on-submit="onSubmit($index, fields, ignoreNonRestrictingErrors)"
                            on-error="onError(errorMessage)"
                            primary-color="data.selectedPrimaryColor"
                            secondary-color="data.selectedSecondaryColor"
                            buttons-color="data.selectedButtonsColor"
                            initiative-id="singleFormConfiguration.initiativeId"
                            validation-map="data.fieldsValidationMap[$index]"
                            all-errors-not-restricting-errors="data.allErrorsNotRestrictingErrors[$index]"
                            workflow-version-type="data.formVersionType"
                            group="data.group"
                            is-previous="($index + 1) < data.formsConfigurations.length"
                            show-back="data.showBackButton"
                            on-back-clicked="getPreviousState()"
                        ></tnk-update-fields-form>

                        <tnk-upload-files-form
                            ng-if="
                                singleFormConfiguration.form &&
                                singleFormConfiguration.form.formQuestionType === 'UPLOAD_FILES'
                            "
                            form="singleFormConfiguration.form"
                            primary-color="data.selectedPrimaryColor"
                            secondary-color="data.selectedSecondaryColor"
                            buttons-color="data.selectedButtonsColor"
                            form-finished="singleFormConfiguration.formFinished"
                            can-skip="singleFormConfiguration.form.definition.canSkipUpload"
                            initiative-id="singleFormConfiguration.initiativeId"
                            custom-trigger-id="singleFormConfiguration.customTriggerId"
                            workflow-version-type="data.formVersionType"
                            on-submit="(onSubmit)"
                            submitted-files="singleFormConfiguration.submittedFiles"
                            worker-run-id="singleFormConfiguration.workerRunId"
                            form-index="$index"
                            show-back="data.showBackButton"
                            on-back-clicked="(getPreviousState)"
                        ></tnk-upload-files-form>
                    </div>

                    <!-- Errors -->
                    <div class="error-text" ng-if="singleFormConfiguration.errorLoadingFormConfig">
                        {{ singleFormConfiguration.errorLoadingFormConfig }}
                    </div>

                    <div
                        class="alert alert-warning margin-top"
                        ng-if="data.noFormMatchEntityDetails[singleFormConfiguration.form.id]"
                    >
                        Could not find a match between the item's values and the items in Module '{{
                            data.noFormMatchEntityDetails[singleFormConfiguration.form.id].moduleName
                        }}'
                    </div>
                </div>
            </div>
            <!-- If there is no form answer definition to current form, put a border bottom. -->
            <!-- It's on a separate div from the form itself because there is an ng-if on the form's div that checks if -->
            <!-- the form has a response message and if not, the form's div is not shown. So we had to use another div for the "Artificial" border bottom. -->
        </div>
    </div>
    <!-- Wait Form Or Interface -->
    <div
        ng-if="!data.finishedSequence && data.loadingWaitForm"
        class="text-center margin-top-wrapper big-box-wrapper fill-form-container"
        data-automation="tnk-fill-form-wait-page"
    >
        <tnk-branded-loading color="data.selectedSecondaryColor"></tnk-branded-loading>
        <div data-automation="tnk-fill-form-wait-message-title" class="wait-message-title">
            {{ data.evaluatedWaitConditionsMessage || 'Waiting for an operation to complete...' }}
        </div>
        <div data-automation="tnk-fill-form-wait-message-sub-title" class="wait-message-sub-title">
            Please wait for the operation to complete. Actions related to your request are being processed and once it
            is complete you can continue to the next step.
        </div>
    </div>
    <!-- Form finished -->
    <div
        ng-if="
            data.finishedSequence &&
            !data.loadingWaitForm &&
            (summaryTrigger.showWebFormResponse || summaryTrigger.showDefaultResponseMessage)
        "
        class="text-center margin-top-wrapper big-box-wrapper fill-form-container"
    >
        <tnk-checkmark color="data.selectedButtonsColor" is-big="true"></tnk-checkmark>
        <div
            ng-if="
                (utils.isNullOrEmpty(summaryTrigger.evaluatedWebformResponseMessage) &&
                    utils.isNullOrEmpty(summaryTrigger.evaluatedCustomResponseMessage)) ||
                summaryTrigger.showDefaultResponseMessage
            "
        >
            <div class="thank-you-message" data-automation="fill-form-web-response-message">
                Thank you for your submission!
            </div>
        </div>
        <p
            data-automation="fill-form-web-response-message"
            ng-if="
                (!utils.isNullOrEmpty(summaryTrigger.evaluatedWebformResponseMessage) ||
                    !utils.isNullOrEmpty(summaryTrigger.evaluatedCustomResponseMessage)) &&
                !summaryTrigger.showDefaultResponseMessage
            "
            ng-bind-html="
            summaryTrigger.evaluatedWebformResponseMessage
                            || summaryTrigger.evaluatedCustomResponseMessage
                        "
            class="thank-you-message"
        ></p>
        <p
            data-automation="fill-form-web-response-message-subtitle"
            ng-if="(!utils.isNullOrEmpty(summaryTrigger.evaluatedWebformResponseMessageSubtitle))"
            ng-bind-html="summaryTrigger.evaluatedWebformResponseMessageSubtitle"
            class="thank-you-message-subtitle"
        ></p>

        <p ng-if="!summaryTrigger.redirectToUrl">
            <a
                ng-if="summaryTrigger.evaluatedUrlLink && summaryTrigger.evaluatedCustomInterfaceLabel"
                data-automation="fill-form-interface-link-button"
                ng-href="{{ summaryTrigger.evaluatedUrlLink }}"
                target="{{ summaryTrigger.redirectToNewTab ? '_blank' : '_self' }}"
                ng-class="btn btn-secondary margin-right summary-interface-button"
                ng-style="{
                'color': data.selectedButtonsColor ,
                'border-color': data.selectedButtonsColor,
            }"
                ng-click="linkClicked()"
            >
                {{ summaryTrigger.evaluatedCustomInterfaceLabel }}
            </a>
        </p>

        <p
            ng-if="summaryTrigger.redirectToUrl && summaryTrigger.evaluatedUrlLink && summaryTrigger.evaluatedUrlLabel"
            data-automation="fill-form-redirect-to-url-messages"
            class="redirect-url-messages"
        >
            <span ng-if="data.redirectUrlCounter > 0">Redirecting in {{ data.redirectUrlCounter }} seconds to</span>
            <a
                class="redirect-url"
                ng-if="!summaryTrigger.isCustomInterfaceLink"
                data-automation="fill-form-redirect-url-messages-expected-url"
                ng-href="{{ summaryTrigger.evaluatedUrlLink }}"
                target="{{ summaryTrigger.redirectToNewTab ? '_blank' : '_self' }}"
                ng-style="{
                    'color': data.selectedButtonsColor
                }"
                ng-click="linkClicked()"
                >
                {{ (summaryTrigger.evaluatedCustomInterfaceLabel === undefined || summaryTrigger.evaluatedCustomInterfaceLabel == "") ? 
                    summaryTrigger.evaluatedUrlLabel : summaryTrigger.evaluatedCustomInterfaceLabel}}
            </a>
        </p>
        <a
            ng-if="summaryTrigger.isCustomInterfaceLink"
            class="btn redirect-custom-interface"
            data-automation="fill-form-redirect-url-messages-expected-url"
            ng-href="{{ summaryTrigger.evaluatedUrlLink }}"
            target="{{ summaryTrigger.redirectToNewTab ? '_blank' : '_self' }}"
            type="button"
            ng-style="{
                'color': data.selectedButtonsColor,
                'border-color': data.selectedButtonsColor,
            }"
            ng-click="linkClicked()"
            >
            {{ (summaryTrigger.evaluatedCustomInterfaceLabel === undefined || summaryTrigger.evaluatedCustomInterfaceLabel == "") ? 
                summaryTrigger.evaluatedUrlLabel : summaryTrigger.evaluatedCustomInterfaceLabel}}
        </a>
    </div>
</div>

<div ng-if="data.displayInterface" class="margin-top-wrapper min-height-wrapper">
    <div ng-if="data.intakeLoader" class="text-center margin-top-wrapper big-box-wrapper loading-container">
        <tnk-branded-loading color="data.selectedPrimaryColor"></tnk-branded-loading>
    </div>

    <tnk-item-interface-in-sequence-with-context
        ng-if="!data.intakeLoader"
        on-next-clicked="(handlePostFormSubmit)"
        on-back-clicked="(getPreviousState)"
        on-switched-to-next-interface-internally="(onSwitchedFromOneInterfaceToAnother)"
        on-switched-to-prev-interface-internally="(onSwitchedFromOneInterfaceToAnother)"
        custom-trigger-id="data.currentCustomTriggerId"
        item-interface="data.interfaceData"
        workflow-version="data.workflowVersionData"
        widgets="data.widgetsData"
        initiative="data.initiativeData"
        project="data.projectData"
        show-back-button="data.showBackButton"
        next-worker-run-id="data.nextWorkerRunId"
        is-last-in-sequence="data.isLastInSequence"
        form-modal-ref="data.formModalRef"
        button-color="data.selectedButtonsColor"
    ></tnk-item-interface-in-sequence-with-context>
</div>
