import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const ConcurComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [geolocation, setGeolocation] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [clientId, setClientId] = useState<string | undefined>(undefined);
    const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

    const authenticateWithCredentials = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                geolocation,
                username,
                password,
                clientId,
                clientSecret,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${username})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithCredentials}>
                <div className="flex api-token-authentication">
                    <div className="margin-bottom-lg info-box common-size-xxs">
                        <div className="flex common-color-dark-grey">
                            <i className="fa fa-info-circle margin-right-xs margin-top-xxs" />
                            <span>
                                In order to get the proper credentials, you'll need to register an application with SAP
                                Concur. You can do this by contacting your Partner Enablement Manager or Partner Account
                                Manager.
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-geolocation" className="col-sm-3 control-label">
                            Data Center
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={geolocation}
                                className="form-control"
                                id="api-geolocation"
                                onChange={({ target }) => setGeolocation(target.value)}
                                autoComplete="off"
                                placeholder="Data Center"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Your data center location & environment. For example:
                                <br />
                                Production - 'us'
                                <br />
                                Implementation - 'us-impl'
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-username" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="api-username"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="api-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-3 control-label">
                            Client ID
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={clientId}
                                className="form-control"
                                id="api-client-id"
                                onChange={({ target }) => setClientId(target.value)}
                                autoComplete="off"
                                placeholder="Client ID"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Client Secret
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={clientSecret}
                                className="form-control"
                                onChange={({ target }) => setClientSecret(target.value)}
                                id="api-client-secret"
                                autoComplete="off"
                                placeholder="Client Secret"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ConcurComponent;
