import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import type { SharedStatusSelectProps } from './StaticStatusSelect';
import StaticStatusSelect from './StaticStatusSelect';

import type { State } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

interface StatusProps {
    workflowVersionId: string;
}

type Props = StatusProps & SharedStatusSelectProps;

const StatusSelect: React.FC<Props> = ({ workflowVersionId, ...props }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const states = useMemo<State[]>(() => {
        return (
            workflowVersionManager
                .getCachedWorkflowVersion(workflowVersionId)
                ?.states?.filter((state) => state.label?.length) || EMPTY_ARRAY
        );
    }, [workflowVersionId, workflowVersionManager]);

    return <StaticStatusSelect {...props} states={states} className="status-select" />;
};

export default StatusSelect;
