import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Cell, CellContent } from './DetailedItemsWidgetItemsListStyle';
import type { DetailedItemsWidgetItemInfo } from '../entities/DetailedItemsWidgetItemInfo';

import { KebabMenuButton, Menu } from '@tonkean/infrastructure';
import { ItemWidgetsInlineActionMenuItem } from '@tonkean/items-grid';
import {
    type ActionInfo,
    InterfaceCTAActionType,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme, FontSize } from '@tonkean/tui-theme';

const MainField = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    user-select: none;
`;

const SecondaryField = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    font-weight: 400;
    user-select: none;
`;
const ErrorChip = styled.div`
    display: flex;
    padding: 3px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: ${Theme.colors.error};
    color: white;
    text-align: center;
    font-size: ${FontSize.XXSMALL_8};
    font-weight: 900;
    text-transform: uppercase;
    height: 15px;
    line-height: normal;
`;

const FieldWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export interface DetailedItemsWidgetItemCellProps {
    itemInfo: DetailedItemsWidgetItemInfo;
    selectedItemId?: TonkeanId<TonkeanType.INITIATIVE>;
    setSelectedItemId: (itemId: TonkeanId<TonkeanType.INITIATIVE>) => void;
    selectionColor: Color;
    inlineMenuActions: ActionInfo[];
    onDelete: (deletedItemId: TonkeanId<TonkeanType.INITIATIVE>) => void;
    viewOnly: boolean;
    workflowVersionType: WorkflowVersionType;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    isItemValid?: boolean;
}

const DetailedItemsWidgetItemCell: React.FC<DetailedItemsWidgetItemCellProps> = ({
    itemInfo,
    selectedItemId,
    setSelectedItemId,
    selectionColor,
    inlineMenuActions,
    onDelete,
    viewOnly,
    workflowVersionType,
    projectId,
    isItemValid,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    const shouldShowOptionMenu = (inlineMenuActions && inlineMenuActions.length > 0) || menuIsOpen;

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setMenuIsOpen(true);
    };

    const setSelectedItemIdCallback = useCallback(() => {
        if (itemInfo.id !== selectedItemId) {
            setSelectedItemId(itemInfo.id);
        }
    }, [itemInfo.id, selectedItemId, setSelectedItemId]);

    return (
        <Cell
            $isSelected={itemInfo.id === selectedItemId}
            $selectionColor={selectionColor}
            onClick={setSelectedItemIdCallback}
            data-automation="detailed-items-widget-item-list-cell"
            role="button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => {
                if (!menuIsOpen) {
                    setIsHovered(false);
                }
            }}
        >
            <CellContent>
                {itemInfo.fields.map((field, index) => {
                    const Field = index === 0 ? MainField : SecondaryField;

                    return (
                        <Field key={field.key} data-automation="detailed-items-widget-item-list-field">
                            <FieldWrapper>
                                {field.value || '-'} {!isItemValid && index === 0 && <ErrorChip>Errors</ErrorChip>}
                            </FieldWrapper>
                        </Field>
                    );
                })}
            </CellContent>
            {shouldShowOptionMenu && isHovered && (
                <Menu
                    menuItems={
                        <>
                            {inlineMenuActions?.map((action) => (
                                <ItemWidgetsInlineActionMenuItem
                                    key={action.id}
                                    actionId={action.id}
                                    displayName={action.displayName}
                                    initiativeId={itemInfo.id}
                                    actionType={InterfaceCTAActionType[action.type]}
                                    projectId={projectId}
                                    workflowVersionType={workflowVersionType}
                                    deleteCallback={() => onDelete(itemInfo.id)}
                                    disabled={viewOnly}
                                />
                            ))}
                        </>
                    }
                    onClose={() => setMenuIsOpen(false)}
                    placement="bottom-end"
                    show={menuIsOpen}
                >
                    <KebabMenuButton
                        onClick={handleMenuClick}
                        data-automation={`detailed-items-widget-item-list-menu-${itemInfo.id}`}
                    />
                </Menu>
            )}
        </Cell>
    );
};

export default DetailedItemsWidgetItemCell;
