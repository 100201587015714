<div class="tnk-edit-group">
    <!-- Group name -->
    <div class="row" ng-if="!hideListName">
        <div class="col-sm-2 common-size-xs padding-right-none margin-top-xs margin-bottom-xs">Module Name:</div>
        <div class="col-sm-10 padding-left-lg-no-xs">
            <input
                type="text"
                class="form-control common-width-50-no-xs"
                id="list-name"
                ng-model="data.name"
                maxlength="255"
                placeholder="Module name..."
                required
            />
        </div>
    </div>

    <!-- Separator -->
    <hr class="common-separator mod-margin-xlg" ng-if="!hideListName" />

    <!-- Solution Selector -->
    <div ng-if="shouldShowWorkflowFolderSelection()" class="row">
        <div class="col-sm-2 common-size-xs padding-right-none margin-top-xs margin-bottom-xs">Solution:</div>
        <div class="col-sm-10 padding-left-lg-no-xs">
            <tnk-workflow-folder-selector
                is-required="true"
                only-of-maker="as.currentUser.id"
                group-id="data.group.sourceGroupId"
                on-folder-selected="onWorkflowFolderSelected(folder)"
            ></tnk-workflow-folder-selector>
        </div>
    </div>

    <hr
        class="common-separator mod-margin-xlg"
        ng-if="shouldShowWorkflowFolderSelection() && shouldShowOutlineSectionSelection()"
    />

    <!-- Outline Section Selector -->
    <div ng-if="shouldShowOutlineSectionSelection()" class="row">
        <div class="col-sm-2 common-size-xs padding-right-none margin-top-xs margin-bottom-xs">Outline:</div>
        <div class="col-sm-10 padding-left-lg-no-xs">
            <tnk-workflow-folder-category-selector
                workflow-folder-category-id="data.workflowFolderCategoryId"
                workflow-folder-id="data.workflowFolderId"
                on-category-selected="(onWorkflowFolderCategorySelected)"
                auto-select-first="true"
            ></tnk-workflow-folder-category-selector>
        </div>
    </div>

    <hr class="common-separator mod-margin-xlg" ng-if="group.isDuplicatedList || !data.isEdit" />

    <!-- Group permissions settings -->
    <div class="margin-top-lg" ng-if="!data.group.isDefaultGroup">
        <div class="margin-bottom flex flex-wrap">
            <div class="common-size-xs margin-bottom-xs margin-right-lg">Permissions:</div>
            <div>
                <div class="margin-top-xxs">
                    <tnk-radio-button
                        radio-id="permission-public"
                        radio-name="permission-public"
                        radio-value="public"
                        model="data.type"
                        on-click="selectType(onClickParam)"
                        on-click-param="(groupType.public)"
                        is-required="true"
                    >
                        <span>Everyone in {{ pm.project.name }} can</span>
                        <div class="margin-left-xxs inline-block" uib-dropdown uib-dropdown-toggle>
                            <span class="common-color-link-blue">
                                {{ data.selectedGroupPermissionsType.displayName }}
                            </span>
                            <ul uib-dropdown-menu>
                                <li
                                    ng-repeat="type in groupWritePermissionTypes"
                                    ng-click="data.selectedGroupPermissionsType = type"
                                >
                                    <a>{{ type.displayName }}</a>
                                </li>
                            </ul>
                            <span class="dropdown-chevron mod-static"></span>
                        </div>
                    </tnk-radio-button>
                    <div
                        ng-if="
                            data.type === 'public' && data.selectedGroupPermissionsType.apiName === 'SPECIFIC_PEOPLE'
                        "
                        class="common-normal-weight common-size-xxs margin-left-lg"
                    >
                        <div class="margin-top margin-bottom-xs">Only you and these people can edit:</div>
                        <tnk-deprecated-people-selector
                            selected-people="data.writePermissionMembers"
                            exclude-me="true"
                            show-intro="true"
                        ></tnk-deprecated-people-selector>
                    </div>
                </div>
                <div class="margin-top label-margin-bottom-none">
                    <tnk-radio-button
                        radio-id="permission-private"
                        radio-name="permission-private"
                        radio-label="Private"
                        radio-value="private"
                        model="data.type"
                        on-click="selectType(onClickParam)"
                        on-click-param="(groupType.private)"
                        is-required="true"
                        class="inline-block"
                    ></tnk-radio-button>
                    <span
                        ng-click="selectType(groupType.private)"
                        class="pointer common-color-light-grey2 common-size-xxs padding-left-xs"
                    >
                        Only you and collaborators you choose to invite
                    </span>
                </div>
                <div ng-show="data.type === groupType.private" class="margin-top-xs padding-left-lg">
                    <!-- Group members -->
                    <div class="common-size-xxs">Add collaborators:</div>
                    <div class="common-width-75-no-xs margin-top-xs">
                        <tnk-deprecated-people-selector
                            no-custom="pm.project.metadata && pm.project.metadata.blockOwnersNotInTeam"
                            selected-people="data.members"
                            exclude-me="true"
                            show-intro="true"
                            on-tag-added="onPeopleSelectionChanged()"
                            on-tag-removed="onPeopleSelectionChanged()"
                        ></tnk-deprecated-people-selector>
                    </div>
                    <!-- Allow setting owners that are not collaborators -->
                    <label class="common-thin margin-top-xs">
                        <input type="checkbox" ng-model="data.allowOwnersNotFromList" />
                        <span class="margin-left-xs">Allow setting owners that are not collaborators</span>
                        <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="Enabling this option will allow setting owners who are not collaborators in this module. These owners will only see the Items they own and not the module itself."
                        ></i>
                    </label>
                </div>

                <!-- Allow share a link -->
                <div
                    class="margin-top-lg"
                    ng-if="pm.isUserLicensed && pm.project.metadata && pm.project.metadata.allowViewOnlyAccess"
                >
                    <label class="common-thin margin-top-xs">
                        <input type="checkbox" ng-model="data.canAccessWithToken" />
                        <span class="margin-left-xs">Allow creating a public, shareable link to this list</span>
                        <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="Enabling this option will allow members of this group to share a link of this group that will give view access to anyone with the link, even without signing up to Tonkean."
                        ></i>
                    </label>
                </div>
            </div>
        </div>

        <div
            class="margin-bottom-lg flex margin-top-lg"
            ng-if="
                ($root.features[pm.project.id].tonkean_feature_show_module_admins || data.group.notVisibleToMakers) &&
                !data.group.isDuplicatedList
            "
        >
            <div class="common-size-xs margin-right-lg">Admins:</div>

            <div class="margin-left-lg padding-left-md flex-grow">
                <!-- Edit Mode -->
                <div ng-if="data.showEditOwners" class="margin-bottom-xs">
                    <tnk-deprecated-people-selector
                        selected-people="data.owners"
                        disabled="data.ownersIsLoading"
                        group="data.group"
                        no-custom="true"
                        on-tag-adding="onOwnerTagAdding($tag)"
                        on-tag-removing="onOwnerTagRemoving($tag)"
                        do-not-search-only-owners-in-group="true"
                    ></tnk-deprecated-people-selector>

                    <div class="flex-vmiddle margin-top-xs">
                        <button
                            type="button"
                            class="btn btn-default margin-right"
                            ng-click="data.showEditOwners = false"
                        >
                            Cancel
                        </button>

                        <button type="button" class="btn btn-primary" ng-click="saveOwners()">
                            Save Admins
                            <i ng-if="data.ownersIsLoading" class="loading-small"></i>
                        </button>
                    </div>
                </div>

                <!-- View Mode -->
                <div ng-if="!data.showEditOwners" class="inline-block">
                    <div
                        class="common-tag-full mod-padding-sm pull-left margin-right margin-bottom-xs"
                        ng-repeat="owner in data.owners"
                    >
                        <tnk-user user="owner" class="mod-one-line margin-right"></tnk-user>
                    </div>
                    <button
                        class="btn btn-default pull-left settings-edit-owners"
                        ng-show="gm.currentUserIsOwnerMap[group.id]"
                        ng-click="data.showEditOwners = true"
                    >
                        <i class="fa fa-pencil"></i>
                        Edit Admins
                    </button>
                </div>

                <div class="common-size-xxxxs common-color-light-grey">
                    Admins have permissions to edit the settings of the module.
                </div>
            </div>
        </div>
    </div>

    <!-- Separator -->
    <hr class="common-separator mod-margin-xlg" />

    <div>
        <tnk-group-support-user-permissions
            group-type="data.type"
            on-toggle="(onSupportAccessToggle)"
        ></tnk-group-support-user-permissions>
    </div>

    <hr class="common-separator mod-margin-xlg" />

    <!-- Default actor selection -->
    <div class="row">
        <!-- Title -->
        <div class="col-sm-2 common-size-xs padding-right-none margin-top-xs margin-bottom-xs">Actor:</div>

        <!-- Single people selector section -->
        <div class="col-sm-10 padding-left-lg-no-xs">
            <!-- Single people selector -->
            <tnk-single-person-selector
                on-person-selected="onDefaultActorSelected(person)"
                on-person-removed="onDefaultActorSelected(null)"
                selected-person-id="data.defaultActor.id"
            ></tnk-single-person-selector>

            <!-- Default actor selection explanation -->
            <div class="common-size-xxxxs common-color-light-grey setting-explanation">
                Selected person will be set the default actor for Tonkean actions.
            </div>
        </div>
    </div>

    <!-- Separator -->
    <hr class="common-separator mod-margin-xlg" />

    <!-- Time before deleting archived items -->
    <div class="row archive-setting padding-left-lg-no-xs">
        <span>Save archived items for</span>
        <div class="padding-left-md">
            <input
                type="number"
                min="1"
                max="{{ getMaxArchivePeriod() }}"
                class="input mod-input form-control"
                ng-model="data.deleteArchivedAfterDays"
                ng-change="checkArchiveDaysValue()"
            />
        </div>
        <div uib-dropdown uib-dropdown-toggle class="pointer margin-left-md inline-block">
            <span class="common-color-link-blue">
                {{ data.selectedArchivePeriodType.displayName }}
            </span>
            <ul uib-dropdown-menu>
                <li 
                    ng-repeat="period in archivePeriodOptions"
                    ng-click="data.selectedArchivePeriodType = period">
                    <a>{{ period.displayName }}</a>
                </li>
            </ul>
            <span class="dropdown-chevron mod-static"></span>
        </div>

    </div>
    <div class="common-size-xxxxs setting-explanation"
        ng-class="{ 'common-color-light-grey': data.isValidArchiveDays , 'common-color-warning': !data.isValidArchiveDays }">
        The maximum archive time is {{ $root.features[pm.project.id].tonkean_allow_extended_archive_period ? extendedArchivePeriodInYears : shortArchivePeriodInYears }} years.
    </div>

    <!-- Separator -->
    <hr class="common-separator mod-margin-xlg" />

    <!-- Notifications settings -->
    <div class="margin-bottom-lg">
        <div class="common-size-xs padding-right-none margin-bottom-xs margin-right-lg-no-xs">Notifications:</div>
        <!-- Choose communication channel-->
        <div class="communication-source-selector" ng-if="data.type === groupType.public">
            <tnk-communication-project-integration-selector
                email-option="false"
                selected-communication-project-integration="data.communicationIntegration"
                on-communication-project-integration-changed="
                    communicationSourceSelected(selectedCommunicationProjectIntegration)
                "
                disallow-manage-communication-sources="true"
            ></tnk-communication-project-integration-selector>
        </div>
        <div ng-if="data.communicationIntegration">
            <!-- Private group -->
            <div ng-if="data.type === groupType.private" class="flex flex-wrap">
                <div class="margin-top-xxs">
                    <div>
                        <tnk-radio-button
                            radio-id="notificationType0"
                            radio-name="notificationType0"
                            radio-label="{{
                                'Slackbot (Notifications will only go to ' + data.group.creator.name + ')'
                            }}"
                            radio-value="{{ groupNotifications.none }}"
                            model="data.notificationType"
                            on-click="selectPrivateChannelType(onClickParam)"
                            on-click-param="(groupNotifications.none)"
                            is-required="true"
                        ></tnk-radio-button>
                    </div>
                    <div>
                        <tnk-radio-button
                            radio-id="notificationType1"
                            radio-name="notificationType1"
                            radio-label="Direct message to {{
                                cis.getFirstThatSupports(cis.features.multiDirectMessage) &&
                                data.members &&
                                data.members.length
                                    ? data.members.length + 1 + ' people'
                                    : 'you'
                            }}"
                            radio-value="{{ groupNotifications.direct }}"
                            model="data.notificationType"
                            on-click="selectPrivateChannelType(onClickParam)"
                            on-click-param="(groupNotifications.direct)"
                            is-required="true"
                            ng-if="
                                !data.members ||
                                data.members.length <= 7 ||
                                !cis.getFirstThatSupports(cis.features.multiDirectMessage)
                            "
                        ></tnk-radio-button>
                        <!-- Message for when are more than 8 members for direct channel (in Slack) -->
                        <div
                            ng-if="
                                cis.getFirstThatSupports(cis.features.multiDirectMessage) &&
                                data.members &&
                                data.members.length > 7
                            "
                            class="flex-vmiddle common-color-grey common-size-xxs"
                        >
                            <span class="tnk-radio-fake-circle"></span>
                            <strong class="margin-right-xs">Direct message</strong>
                            <span>Group DM can include up to 8 people (including you).</span>
                        </div>
                    </div>
                    <div class="margin-top" ng-if="::cis.anyIntegrationSupports(cis.features.privateChannels)">
                        <tnk-radio-button
                            radio-id="notificationType2"
                            radio-name="notificationType2"
                            radio-label="Private {{ cis.getFirstThatSupportsLabel(cis.features.channels) }}"
                            radio-value="{{ groupNotifications.private }}"
                            model="data.notificationType"
                            on-click="selectPrivateChannelType(onClickParam)"
                            on-click-param="(groupNotifications.private)"
                            is-required="true"
                        ></tnk-radio-button>
                    </div>

                    <div
                        ng-if="data.notificationType === groupNotifications.private"
                        class="padding-left-lg margin-top-xs"
                    >
                        <div class="input-group">
                            <div class="relative">
                                <input
                                    type="text"
                                    class="form-control padding-left-lg padding-right-lg"
                                    id="private-group"
                                    ng-model="data.privateChannelName"
                                    maxlength="250"
                                    required
                                    placeholder="{{ cis.getFirstThatSupportsLabel(cis.features.channels) }} name"
                                    ng-keyup="onPrivateGroupKeyPress($event)"
                                    autocomplete="off"
                                />
                                <span
                                    class="form-control-feedback common-z-index-forward"
                                    ng-show="loadingPrivateGroup"
                                >
                                    <span class="loading-small common-size-xs"></span>
                                </span>
                                <span
                                    class="fa fa-hashtag common-size-xxs common-color-light-grey form-control-feedback"
                                    style="left: -5px; top: -1px; z-index: 4"
                                ></span>
                                <span
                                    class="
                                        fa fa-check
                                        common-size-xxs common-color-light-grey
                                        form-control-feedback
                                        common-z-index-forward
                                    "
                                    ng-show="!loadingPrivateGroup && validatedPrivateChannel"
                                ></span>
                            </div>
                            <span ng-click="validateChannel()" class="input-group-btn">
                                <button
                                    type="button"
                                    class="btn btn-default common-color-primary"
                                    data-ng-disabled="loadingPrivateGroup || !data.privateChannelName"
                                >
                                    Check
                                </button>
                            </span>
                        </div>
                        <div class="margin-top-xs common-size-xxs" ng-if="failedFindingPrivateChannel">
                            <div class="common-bold">Couldn't reach #{{ data.wrongPrivateChannelName }}.</div>
                            <div ng-if="data.communicationIntegration.integrationType === 'SLACK'">
                                If the channel exists and you have access to it, invite @{{ botName || 'tonkean' }} by
                                typing the following in Slack:
                                <span class="common-color-danger">
                                    /invite @{{ botName || 'tonkean' }} {{ data.wrongPrivateChannelName }}
                                </span>
                            </div>
                        </div>
                        <div
                            class="alert alert-danger margin-top padding-normal-sm common-size-xxs"
                            ng-if="serverError"
                        >
                            <p>
                                Oops, Something went wrong... Please try again in a little while.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Public group - choose communication integration channel -->
            <div ng-if="data.type === groupType.public && !data.group.isDefaultGroup" class="flex flex-wrap">
                <div class="common-width-full-xs">
                    <tnk-channel-selector
                        classes="common-width-full"
                        selected-channel="data.newChannel"
                        reload-channels="data.reloadChannels"
                        enable-clear-channel="true"
                        on-channels-loaded="autoselectPublicChannel(channels)"
                        on-channel-selected="selectPublicChannel(channel)"
                        selected-integration="data.communicationIntegration"
                    ></tnk-channel-selector>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap flex-col">
            <!-- Notifications on error -->
            <label class="margin-top-lg">
                <input type="checkbox" ng-model="data.allowWorkerErrorAlerts" />
                <span class="common-normal-weight">Alert on Module errors?</span>
            </label>

            <!-- Smart Search -->
            <label class="margin-top-lg" ng-if="data.shouldShowSmartSearch">
                <input type="checkbox" ng-model="data.smartSearchEnabled" />
                <span class="common-normal-weight">Enable In Front Door </span>
            </label>
        </div>
    </div>

    <hr
        class="common-separator mod-margin-xlg"
        ng-if="data.communicationIntegration && cis.doesUserHaveCommunicationIntegration(as.currentUser, pm.project.id)"
    />
</div>
