import React from 'react';
import styled from 'styled-components';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Checkbox, Field, Placeholder, Tooltip } from '@tonkean/infrastructure';
import type { BaseActionParameter } from '@tonkean/tonkean-entities';

const CheckboxesWrapper = styled.div`
    display: grid;
    grid-row-gap: 15px;
    justify-items: start;
`;

interface Props {
    name: string;
    isLoading: boolean;
    fieldListParameters: BaseActionParameter[];
    shouldShowKeyValueOption: boolean;
}

const AdvancedOptions: React.FC<Props> = ({ name, isLoading, fieldListParameters, shouldShowKeyValueOption }) => {
    const shouldShowFileDownloadingOptionCheckbox = useFeatureFlag(
        'tonkean_feature_show_file_download_option_on_custom_action',
    );

    return (
        <Field>
            <CheckboxesWrapper>
                {isLoading && (
                    <>
                        <Placeholder $width="180px" />
                        <Placeholder $width="165px" />
                        <Placeholder $width="130px" />
                        <Placeholder $width="172px" />
                        <Placeholder $width="180px" />
                        <Placeholder $width="195px" />
                    </>
                )}

                {!isLoading && (
                    <>
                        <Checkbox
                            changeLabelColor={false}
                            boldLabel={false}
                            name={`${name}.ignoreUntrustedCertificates`}
                        >
                            Ignore untrusted certificates
                        </Checkbox>
                        <Checkbox changeLabelColor={false} boldLabel={false} name={`${name}.disableAutoCharset`}>
                            Disable auto charset
                        </Checkbox>
                        <Checkbox changeLabelColor={false} boldLabel={false} name={`${name}.disableFollowRedirects`}>
                            Disable follow redirects
                        </Checkbox>
                        <Checkbox changeLabelColor={false} boldLabel={false} name={`${name}.keepTrailingForwardSlash`}>
                            Keep trailing forward slash
                        </Checkbox>
                        <Checkbox
                            changeLabelColor={false}
                            boldLabel={false}
                            name={`${name}.removeKeysWithEmptyValueFromBody`}
                        >
                            Remove keys with empty values
                        </Checkbox>

                        {shouldShowFileDownloadingOptionCheckbox && (
                            <Checkbox
                                changeLabelColor={false}
                                boldLabel={false}
                                name={`${name}.shouldIncludeFileDownloading`}
                            >
                                Support file download
                            </Checkbox>
                        )}

                        {shouldShowKeyValueOption && (
                            <Tooltip
                                disabled={fieldListParameters.length === 0}
                                content="Unable to use dynamic key-value parameters when using field list parameters"
                            >
                                <Checkbox
                                    changeLabelColor={false}
                                    boldLabel={false}
                                    name={`${name}.enableKeyValueParameters`}
                                    disabled={fieldListParameters.length > 0}
                                >
                                    Enable dynamic key-value parameters
                                </Checkbox>
                            </Tooltip>
                        )}
                    </>
                )}
            </CheckboxesWrapper>
        </Field>
    );
};

export default AdvancedOptions;
