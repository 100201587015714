import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class JsonPathFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.JSON_TRAVERSE;
    override readonly sign = 'JsonTraverse';
    override readonly displayName = 'Json Traverse';
    override readonly description = `Flat all the arrays in the object by the filed value (the filed values should be separated by '.' (Example: 'organizations.files'))`;
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Path',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
