import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';

import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';

const useProjectIntegrationEntityBreadCrumbsConfiguration = (
    projectIntegration: ProjectIntegration,
    entity: ProjectIntegrationEntity,
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[],
    onExit?: (destinationEntityId: string) => Promise<boolean>,
) => {
    const $state = useAngularService('$state');

    // Navigation to entity single page view.
    const navigateToCreatedEntity = useCallback(
        async (entityId: string) => {
            if (!onExit || (await onExit(entityId))) {
                $state.go(
                    'product.projectIntegrationPageEntity',
                    {
                        entityId,
                    },
                    { inherit: true },
                );
            }
        },
        [$state, onExit],
    );

    // Memo for the breadCrumbs settings.
    return useMemo(() => {
        // Project Integration bread crumb.
        const projectIntegrationBreadCrumb: BreadcrumbsSettings = {
            displayName: projectIntegration.displayName,
            id: projectIntegration.displayName,
            prefixIcon: (
                <ProjectIntegrationIcon
                    width={14}
                    iconUrl={projectIntegration.iconUrl}
                    integrationType={projectIntegration.integration.integrationType}
                />
            ),
        };

        // Entities Page bread crumb.
        const entitiesPageBreadCrumb: BreadcrumbsSettings = {
            displayName: 'Entities',
            id: 'Entities',
        };

        // Single entity with other entities options bread crumb.
        const entitiesBreadCrumb: BreadcrumbsSettings = {
            displayName: entity.displayName,
            id: entity.id,
            otherOptionsSettings: {
                otherOptions: entitiesSummary
                    .filter((entitySummary) => !entitySummary.isImported)
                    .filter((entitySummary) => entitySummary.id !== entity.id),
                onOtherOptionClicked: navigateToCreatedEntity,
            },
        };

        return [projectIntegrationBreadCrumb, entitiesPageBreadCrumb, entitiesBreadCrumb];
    }, [
        projectIntegration.displayName,
        projectIntegration.iconUrl,
        projectIntegration.integration.integrationType,
        entity.displayName,
        entity.id,
        entitiesSummary,
        navigateToCreatedEntity,
    ]);
};

export default useProjectIntegrationEntityBreadCrumbsConfiguration;
