import WorkdayAuthenticationComponent from './WorkdayAuthenticationComponent';
import WorkdayCustomizeSetupComponent from './WorkdayCustomizeSetupComponent';
import workdayCircleIcon from '../../../../apps/tracks/images/integrations/workday-circle.png';
import workdayIcon from '../../../../apps/tracks/images/workday.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class WorkdayIntegration extends IntegrationDefinitionBase {
    override name = 'workday';
    override displayName = 'Workday';
    override description =
        'We are not collecting any data. In order to collect the workers data, please contact our support team.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'createdate';
    override supportsMultipleIntegrationPerUser = true;
    override entities = [new IntegrationEntity('Worker', 'Workers'), new IntegrationEntity('Record', 'Records')];
    override customizedAuthenticationComponent = WorkdayAuthenticationComponent;
    override customizedSetupComponent = WorkdayCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(workdayCircleIcon, '130px'),
        new IntegrationIcon(workdayIcon, '110px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Worker: true, Record: true },
        isOnlyDataRetention: {},
    };
}

export default WorkdayIntegration;
