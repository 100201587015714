import type { ColDef } from '@ag-grid-community/core';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { type FieldDefinitionKey, SpecialFieldsKey } from '@tonkean/tonkean-entities';

const getCreateDateDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[SpecialFieldsKey.CREATED_DATE] || 'Created Date',
        field: 'created',
        type: 'resizableColumn',
        cellRenderer: 'createdDateCellRenderer',
        editable: false,
        filter: enableFilter && 'agDateColumnFilter',
        equals: (date1, date2) => {
            if (date1 === undefined && date2 === undefined) {
                return true;
            }

            return new Date(date1).getTime() === new Date(date2).getTime();
        },
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(SpecialFieldsKey.CREATED_DATE)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
    } as ColDef<InitiativeRowData>;
};

export default getCreateDateDefinition;
