import { useMemo } from 'react';

import type { IncludeInterfaceWidgetConfiguration } from '../widgets/IncludeInterfaceWidgetModule/IncludeInterfaceWidgetConfiguration';

import type {
    InterfaceWidgetTabSummary,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetParentTypes,
} from '@tonkean/tonkean-entities';
import { type InterfaceTab, ItemInterfaceWidgetType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const addWidgetToTabs = (
    tabs: InterfaceTab[],
    widget: Pick<WidgetBase, 'id' | 'configuration' | 'displayName'>,
    parentEntityId: TonkeanId<WidgetParentTypes>,
) => {
    if (widget.configuration.quickNav) {
        tabs.push({
            key: widget.id,
            label: widget.configuration.quickNavLabel || widget.displayName,
            icon: widget.configuration.quickNavIcon || widget.configuration.icon,
            widgets: [],
        });
    }

    if (tabs.length === 0) {
        tabs.push({
            key: `${parentEntityId}-GENERAL`,
            label: 'General',
            icon: undefined,
            widgets: [],
        });
    }

    tabs[tabs.length - 1]?.widgets.push(widget.id);
};

export type UseInterfaceTabsWidget = Pick<
    WidgetBase,
    'id' | 'displayName' | 'type' | 'parentEntityId' | 'configuration'
>;

export const useInterfaceTabs = (
    widgets: UseInterfaceTabsWidget[],
    includedWidgetsSummaryByInterfaceId:
        | Record<TonkeanId<TonkeanType.ITEM_INTERFACE>, InterfaceWidgetTabSummary[]>
        | undefined,
): InterfaceTab[] => {
    return useMemo(() => {
        if (!widgets[0]) {
            return EMPTY_ARRAY;
        }

        const parentEntityId = widgets[0].parentEntityId;
        const tabs: InterfaceTab[] = [];
        widgets.forEach((widget, index) => {
            if (widget.type === ItemInterfaceWidgetType.INCLUDE_INTERFACE) {
                if (widget.configuration.quickNav) {
                    addWidgetToTabs(tabs, widget, parentEntityId);
                }
                const matchingItemInterfaceId = (widget.configuration as IncludeInterfaceWidgetConfiguration)
                    .matchingItemInterfaceId;
                if (matchingItemInterfaceId) {
                    includedWidgetsSummaryByInterfaceId?.[matchingItemInterfaceId]?.forEach(
                        (includedWidget, includedIndex) => {
                            addWidgetToTabs(
                                tabs,
                                { ...includedWidget, configuration: { ...includedWidget } },
                                parentEntityId,
                            );
                        },
                    );
                }
            } else {
                addWidgetToTabs(tabs, widget, parentEntityId);
            }
        });
        return tabs;
    }, [widgets, includedWidgetsSummaryByInterfaceId]);
};
