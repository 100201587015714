import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DuplicateNewIcon } from '../../../../images/icons/duplicate-new.svg';
import { ReactComponent as WasntActivatedIcon } from '../../../../images/icons/history/wasnt-activated.svg';
import { ReactComponent as TrashNewIcon } from '../../../../images/icons/trash-new.svg';

import { Tooltip, useEscapeCallback } from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Button } from '@tonkean/tui-buttons/Button';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Header = styled.div`
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;

    a,
    button {
        ${styledFocus}
    }
`;
const Title = styled.div`
    font-weight: 400;
    font-size: ${FontSize.XXLARGE_20};
    color: ${Theme.colors.gray_800};
    margin: 0 32px 0 40px;
    line-height: 54px;
`;
const PendingChanges = styled.div`
    position: relative;
    height: 14px;
    font-family: Roboto;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.warning};
    display: flex;
    align-self: center;
    margin-top: 5px;
`;
const PendingChangesIcon = styled.div`
    position: relative;
    width: 0px;
    background: ${Theme.colors.warning};

    path,
    circle[stroke] {
        stroke: ${Theme.colors.warning};
    }
    circle[fill] {
        fill: ${Theme.colors.warning};
    }
`;
const Buttons = styled.div`
    margin-right: 8px;
`;
const Cancel = styled(Button)`
    margin-right: 8px;
`;
const Message = styled.div`
    margin-left: 16px;
    align-self: center;
`;

const FlatIconButton = styled(IconButton)`
    margin-right: 12px;
`;

interface Props {
    headerTitle: string;
    hasPendingChanges: boolean;
    onDelete?: () => void;
    onDuplicate?: () => void;
    onCancel?: () => void;
    onModalEscClose: () => void;
    workflowVersionType: WorkflowVersionType;
    saveDisabled: boolean;
}

const FieldDefinitionConfigurationModalSubHeader: React.FC<Props> = ({
    headerTitle,
    hasPendingChanges,
    onDelete,
    onCancel,
    onDuplicate,
    onModalEscClose,
    workflowVersionType,
    saveDisabled,
}) => {
    useEscapeCallback(onModalEscClose);

    return (
        <Header>
            <Title>{headerTitle} Settings</Title>
            {hasPendingChanges && (
                <PendingChanges>
                    <PendingChangesIcon>
                        <span className="tnk-icon">
                            <WasntActivatedIcon />
                        </span>
                    </PendingChangesIcon>
                    <Message data-automation="field-definition-configuration-modal-dub-header-pending-changes-indication">
                        Pending Changes{' '}
                    </Message>
                </PendingChanges>
            )}
            <div className="flex-grow" />
            {workflowVersionType === WorkflowVersionType.DRAFT && (
                <>
                    {onDuplicate && (
                        <Tooltip content="Duplicate Field">
                            <FlatIconButton
                                data-automation="field-definition-configuration-modal-sub-header-duplicate-field-button"
                                onClick={onDuplicate}
                            >
                                <span className="tnk-icon">
                                    <DuplicateNewIcon />
                                </span>
                            </FlatIconButton>
                        </Tooltip>
                    )}
                    {onDelete && (
                        <Tooltip content="Delete Field">
                            <FlatIconButton
                                data-automation="field-definition-configuration-modal-sub-header-delete-field-button"
                                onClick={onDelete}
                            >
                                <span className="tnk-icon">
                                    <TrashNewIcon />
                                </span>
                            </FlatIconButton>
                        </Tooltip>
                    )}
                </>
            )}
            <Buttons>
                {onCancel && (
                    <Cancel
                        type="button"
                        onClick={onCancel}
                        data-automation="field-definition-configuration-modal-sub-header-cancel-button"
                        cancel
                    >
                        {workflowVersionType === WorkflowVersionType.DRAFT ? 'Cancel' : 'Close'}
                    </Cancel>
                )}
                {workflowVersionType === WorkflowVersionType.DRAFT && (
                    <Button
                        type="submit"
                        data-automation="field-definition-configuration-modal-sub-header-submit-button"
                        disabled={saveDisabled}
                    >
                        Save
                    </Button>
                )}
            </Buttons>
        </Header>
    );
};

export default FieldDefinitionConfigurationModalSubHeader;
