import React from 'react';
import styled from 'styled-components';

import IntakeStatusProgressBarCompletionStatusIcon from './IntakeStatusProgressBarCompletionStatusIcon';
import type { IntakeProgressStatusInfo } from '../entities/IntakeProgressStatusInfo';
import { IntakeProgressStatusCompletionStatus } from '../entities/IntakeProgressStatusInfo';

import { TextEllipsis } from '@tonkean/infrastructure';
import type { Color } from '@tonkean/tui-theme';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ProgressBarContainer = styled.div<{ $isVertical?: boolean }>`
    display: flex;
    flex-direction: ${({ $isVertical }) => ($isVertical ? 'column' : 'row')};
    width: ${({ $isVertical }) => ($isVertical ? '100%' : 'auto')};
    flex-wrap: wrap;
    align-content: flex-start;
`;

const SingleStatusInfoContainer = styled.div<{ $isVertical?: boolean }>`
    display: flex;
    justify-content: ${({ $isVertical }) => ($isVertical ? 'flex-start' : 'center')};
    align-items: center;
    flex-direction: ${({ $isVertical }) => ($isVertical ? 'row' : 'column')};
    gap: ${({ $isVertical }) => ($isVertical ? '22px' : '8px')};
`;

enum ColoredPartForStep {
    FULLY_COLORED = 1,
    NOT_COLORED = 0,
    PARTIALLY_COLORED = 0.5,
}

const calcColoredBarStatusPadding = (totalSteps: number, isVertical?: boolean) => {
    return isVertical ? 20 + Math.max(0, (4 - totalSteps) * 10) : 42 + Math.max(0, (4 - totalSteps) * 20);
};

const ColoredBar = styled.div<{
    $isVertical?: boolean;
    $coloredPart: ColoredPartForStep;
    $statusBarColor: Color;
    $isFirst: boolean;
    $isLast: boolean;
    $totalSteps: number;
}>`
    width: ${({ $isVertical }) => ($isVertical ? '20px' : '100%')};
    height: ${({ $isVertical }) => ($isVertical ? '100%' : '20px')};
    align-items: center;
    justify-content: center;
    display: flex;

    background: ${({ $coloredPart, $statusBarColor, $isVertical, $isFirst, $isLast }) => {
        const gradientStopValue =
            // because first and last element don't have even padding on their sides, we need to treat them differently
            $isFirst && $coloredPart === ColoredPartForStep.PARTIALLY_COLORED
                ? '20px'
                : $isLast && $coloredPart === ColoredPartForStep.PARTIALLY_COLORED
                  ? `calc(100% - 20px)`
                  : `${$coloredPart * 100}%`;

        return `linear-gradient(to ${
            $isVertical ? 'bottom' : 'right'
        }, ${$statusBarColor} 0%, ${$statusBarColor} ${gradientStopValue}, rgba(255, 255, 255, 0.80) ${gradientStopValue}), ${$statusBarColor}`;
    }};

    padding: ${({ $isVertical, $totalSteps }) => {
        const paddingVal = calcColoredBarStatusPadding($totalSteps, $isVertical);
        return $isVertical ? `${paddingVal}px 0` : `0 ${paddingVal}px`;
    }};

    ${({ $isFirst, $isLast, $isVertical }) => {
        if ($isFirst && $isLast) {
            return `
                border-radius: 10px;
                padding: ${$isVertical ? '30px 0px' : '0px 30px'};
            `;
        } else if ($isFirst) {
            return `
                border-radius: ${$isVertical ? '10px 10px 0px 0px' : '10px 0px 0px 10px'};
                padding-top: ${$isVertical ? '10px' : '0px'};
                padding-left: ${$isVertical ? '0px' : '10px'};
            `;
        } else if ($isLast) {
            return `
                border-radius: ${$isVertical ? '0px 0px 10px 10px' : '0px 10px 10px 0px'};
                padding-bottom: ${$isVertical ? '10px' : '0px'};
                padding-right: ${$isVertical ? '0px' : '10px'};
            `;
        }
    }}
`;

const TitleContainer = styled.div<{
    $isFirst: boolean;
    $isLast: boolean;
    $isVertical?: boolean;
    $completeColor: Color;
    $isFutureStep: boolean;
    $totalSteps: number;
}>`
    display: flex;
    justify-content: ${({ $isVertical }) => ($isVertical ? 'start' : 'center')};
    max-width: ${({ $isVertical }) => (!$isVertical ? 'calc(3vw * 2)' : undefined)};
    color: ${({ $isVertical, $completeColor, $isFutureStep }) =>
        !$isFutureStep ? $completeColor : $isVertical ? Theme.colors.gray_800 : Theme.colors.gray_600};
    font-size: ${({ $isVertical }) => ($isVertical ? FontSize.SMALL_12 : FontSize.XSMALL_10)};
    font-weight: 500;
    text-shadow: ${({ $isFutureStep }) => (!$isFutureStep ? '0 0 0 rgba(0, 0, 0, 0.3)' : undefined)};

    // First and last elements have uneven padding on their sides, so we need to recenter the title accordingly
    ${({ $isFirst, $isLast, $isVertical, $totalSteps }) => {
        const statusPadding = calcColoredBarStatusPadding($totalSteps, $isVertical);
        const edgeTitleFixPadding = -(statusPadding - 10);

        if ($isFirst && $isLast) {
            return `
                margin: 0;
            `;
        } else if ($isFirst && !$isVertical) {
            return `
                margin-left: ${edgeTitleFixPadding}px;
            `;
        } else if ($isLast && !$isVertical) {
            return `
                margin-right: ${edgeTitleFixPadding}px;
            `;
        } else if ($isFirst && $isVertical) {
            return `
                margin-top: ${edgeTitleFixPadding}px;
            `;
        } else if ($isLast && $isVertical) {
            return `
                margin-bottom: ${edgeTitleFixPadding}px;
            `;
        }
    }}
`;

function getColoredPartForStep(
    completionStatus: IntakeProgressStatusCompletionStatus,
    isLast: boolean,
): ColoredPartForStep {
    switch (completionStatus) {
        case IntakeProgressStatusCompletionStatus.CURRENT:
            return isLast ? ColoredPartForStep.FULLY_COLORED : ColoredPartForStep.PARTIALLY_COLORED;
        case IntakeProgressStatusCompletionStatus.COMPLETE:
            return ColoredPartForStep.FULLY_COLORED;
        case IntakeProgressStatusCompletionStatus.SKIPPED:
            return ColoredPartForStep.FULLY_COLORED;
        case IntakeProgressStatusCompletionStatus.FUTURE:
            return ColoredPartForStep.NOT_COLORED;
    }
}

export interface IntakeStatusProgressBarProps {
    statusBarColor?: Color;
    intakeStatusesInfo: IntakeProgressStatusInfo[];
    isVertical?: boolean;
}

const IntakeStatusProgressBar: React.FC<IntakeStatusProgressBarProps> = ({
    statusBarColor,
    intakeStatusesInfo,
    isVertical,
}) => {
    const color = statusBarColor ?? Theme.colors.primary;
    return (
        <ProgressBarContainer $isVertical={isVertical}>
            {intakeStatusesInfo.map((statusInfo, index) => (
                <SingleStatusInfoContainer
                    key={index}
                    $isVertical={isVertical}
                    data-automation="intake-status-progress-single-status"
                >
                    <ColoredBar
                        $isVertical={isVertical}
                        $statusBarColor={color}
                        $coloredPart={getColoredPartForStep(
                            statusInfo.completionStatus,
                            index === intakeStatusesInfo.length - 1,
                        )}
                        $isFirst={index === 0}
                        $isLast={index === intakeStatusesInfo.length - 1}
                        $totalSteps={intakeStatusesInfo.length}
                    >
                        <IntakeStatusProgressBarCompletionStatusIcon
                            completionStatus={statusInfo.completionStatus}
                            isLast={index === intakeStatusesInfo.length - 1}
                            color={color}
                        />
                    </ColoredBar>

                    <TitleContainer
                        $completeColor={color}
                        $isFutureStep={statusInfo.completionStatus === IntakeProgressStatusCompletionStatus.FUTURE}
                        $isVertical={isVertical}
                        $isFirst={index === 0}
                        $isLast={index === intakeStatusesInfo.length - 1}
                        $totalSteps={intakeStatusesInfo.length}
                    >
                        <TextEllipsis
                            numberOfLines={1}
                            tooltipContentOverride={statusInfo.title}
                            tooltipPlacement="bottom"
                            tooltip
                        >
                            {statusInfo.title}
                        </TextEllipsis>
                    </TitleContainer>
                </SingleStatusInfoContainer>
            ))}
        </ProgressBarContainer>
    );
};

export default IntakeStatusProgressBar;
