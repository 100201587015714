import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TooltipIcon } from '../../../../../../../images/icons/tooltip2.svg';

import { Tooltip } from '@tonkean/infrastructure';
import type { Color } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { isBetween } from '@tonkean/utils';

enum StatusCodeTypes {
    INFORMATIONAL_RESPONSES = 'Informational',
    SUCCESSFUL = 'Success',
    REDIRECTS = 'Redirect',
    CLIENT_ERRORS = 'Client Error',
    SERVER_ERRORS = 'Server Error',
}

const StatusCodeText = styled.div<{ color: string }>`
    font-size: ${FontSize.XSMALL_10};
    font-weight: 700;

    background-color: ${(prop) => prop.color};
    color: white;
    padding: 2px 6px;
    border-radius: 100px;
`;

const Wrapper = styled.div`
    display: inline-flex;
    gap: 4px;
    align-items: center;
`;

interface Props {
    statusCode: number;
    hideIndicator?: boolean;
}

const ProjectIntegrationActionTestResponseStatusCode: React.FC<Props> = ({ statusCode, hideIndicator = false }) => {
    const statusCodeTypeToColorRecord: Record<StatusCodeTypes, Color> = {
        [StatusCodeTypes.INFORMATIONAL_RESPONSES]: Theme.colors.gray_500,
        [StatusCodeTypes.SUCCESSFUL]: Theme.colors.success,
        [StatusCodeTypes.REDIRECTS]: Theme.colors.primaryHighlight,
        [StatusCodeTypes.CLIENT_ERRORS]: Theme.colors.error,
        [StatusCodeTypes.SERVER_ERRORS]: Theme.colors.warning,
    };

    const statusCodeTypeToTooltipText: Record<StatusCodeTypes, string> = {
        [StatusCodeTypes.INFORMATIONAL_RESPONSES]:
            'This interim response indicates that everything so far is OK and that the client should continue the request, or ignore the response if the request is already finished.',
        [StatusCodeTypes.SUCCESSFUL]: 'The request has succeeded.',
        [StatusCodeTypes.REDIRECTS]:
            'The request has more than one possible response. The user-agent or user should choose one of them.',
        [StatusCodeTypes.CLIENT_ERRORS]:
            'The server could not understand the request due to invalid syntax or unauthenticated.',
        [StatusCodeTypes.SERVER_ERRORS]: "The server has encountered a situation it doesn't know how to handle.",
    };

    const statusType = useMemo(() => {
        const isSuccess = isBetween(200, 299, statusCode);
        const isRedirect = isBetween(300, 399, statusCode);
        const isClientError = isBetween(400, 499, statusCode);
        const isServerError = isBetween(500, 599, statusCode);

        if (isSuccess) {
            return StatusCodeTypes.SUCCESSFUL;
        } else if (isRedirect) {
            return StatusCodeTypes.REDIRECTS;
        } else if (isClientError) {
            return StatusCodeTypes.CLIENT_ERRORS;
        } else if (isServerError) {
            return StatusCodeTypes.SERVER_ERRORS;
        } else {
            return StatusCodeTypes.INFORMATIONAL_RESPONSES;
        }
    }, [statusCode]);

    return (
        <Wrapper>
            <StatusCodeText color={statusCodeTypeToColorRecord[statusType]}>
                {statusCode} {statusType}
            </StatusCodeText>
            {!hideIndicator && (
                <Tooltip limitWidth={30} content={statusCodeTypeToTooltipText[statusType]}>
                    <TooltipIcon />
                </Tooltip>
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationActionTestResponseStatusCode;
