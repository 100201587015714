import { useMemo } from 'react';

/**
 * useJsonParse Hook
 *
 * This hook takes an object or a JSON string as input and returns a formatted JSON string or an object.
 * If the resulting JSON string exceeds a maximum length, it truncates the string and sets a flag to indicate truncation.
 *
 * @param object - The object or JSON string to be parsed and formatted.
 * @param maxOutputLength - The maximum length the output json should be. If the output exceeds this length, it will be truncated.
 * @param returnObjectOnParseError - If true, the original object will be returned if an error occurs, and it is a string, during JSON parsing.
 * @returns An object containing the formatted JSON string and a boolean indicating whether the data was truncated.
 */
const useJsonStringify = (object: any, maxOutputLength?: number, returnObjectOnParseError?: boolean) => {
    return useMemo(() => {
        if (object.toString().length > 500_000) {
            return {
                isTruncated: true,
                json: `JSON object is too large (${object
                    .toString()
                    .length.toLocaleString()} characters) to be processed and displayed.`,
            };
        }
        try {
            let result: string = '';
            if (typeof object === 'string') {
                const jsonObject = JSON.parse(object);
                result = JSON.stringify(jsonObject, null, 4);
            } else if (typeof object === 'object') {
                result = JSON.stringify(object, null, 4);
            } else {
                return object;
            }

            return getTruncatedResult(result, maxOutputLength);
        } catch {
            return returnObjectOnParseError && typeof object === 'string'
                ? getTruncatedResult(object.toString(), maxOutputLength)
                : object;
        }
    }, [object, maxOutputLength, returnObjectOnParseError]);
};

const getTruncatedResult = (stringToTruncate: string, maxOutputLength?: number) => {
    if (maxOutputLength && stringToTruncate.length > maxOutputLength) {
        return {
            json: `${stringToTruncate.slice(0, maxOutputLength)}...`,
            isTruncated: true,
        };
    }

    return {
        json: stringToTruncate,
        isTruncated: false,
    };
};

export default useJsonStringify;
