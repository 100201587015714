function AutoCompleteExternalItemCtrl($scope, $rootScope, tonkeanService) {
    $scope.pm = $rootScope.pm;
    $scope.results = [];
    $scope.popover = {};

    if ($scope.externalItem && $scope.externalItem.id) {
        $scope.inputText = $scope.externalItem.text;
    }

    $scope.search = function (term) {
        if ($scope.pm.project && term && term.length > 1) {
            $scope.searching = true;
            $scope.results = [];
            return tonkeanService
                .searchExternalItems(
                    $scope.pm.project.id,
                    $scope.groupId,
                    $scope.externalSource,
                    $scope.externalType,
                    null,
                    term,
                    5,
                    $scope.dontFixType,
                )
                .then(function (data) {
                    if (data && data.entities) {
                        $scope.results = data.entities;
                    }
                    return $scope.results;
                })
                .catch(function (error) {
                    $scope.error = error;
                    // todo: show error
                })
                .finally(function () {
                    $scope.searching = false;
                });
        }
    };

    $scope.onSelect = function ($item) {
        $scope.externalItem = $item;
    };

    /**
     * (1) Once blurred out of the external item selection input, will check if the current input string equals to the selected item title string.
     *     It's used to prevent a situation where you choose an item, then change the title to something else, but still able to click Save.
     * (2) As well, this function will auto select the item for you, if results returned only one result, and the input string equals to that
     *     single result's title. This is done for the convenient of the user.
     */
    $scope.validateSelection = function () {
        // If searchTerm isn't a string, it means the user already selected something from the drop-down list.
        if (angular.isString($scope.searchTerm)) {
            // externalItem can be an empty object (and that means no selection), that's why we condition over externalItem.title as well.
            const externalItemExists = $scope.externalItem && $scope.externalItem.title;

            // (1)
            if (externalItemExists && $scope.externalItem.title !== $scope.searchTerm) {
                $scope.externalItem = null;
            }

            // (2)
            if (
                !externalItemExists &&
                $scope.results &&
                $scope.results.length === 1 &&
                $scope.results[0].title === $scope.searchTerm
            ) {
                $scope.externalItem = $scope.results[0];
            }
        }
    };

    $scope.getDisplayText = function ($item) {
        if (!$item) {
            return;
        }

        let result = $item.text;
        if ($item.parentName) {
            result += ` (${$item.parentName})`;
        }

        return result;
    };
}

export default angular.module('tonkean.app').controller('AutoCompleteExternalItemCtrl', AutoCompleteExternalItemCtrl);
