export function TrelloModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.orgs = state.orgs;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateOrganizationLists();
        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        const promise = oauthHandler
            .trello(environment.integrationKeysMap.trello, environment.integrationKeysMap.trelloUri)
            .then(function (code) {
                return { code };
            });

        promise
            .then(function (config) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'TRELLO',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (data) {
                $scope.integration = data;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'orgs',
                );
            })
            .then(function (data) {
                $scope.selections = data.options.slice(0, 3).map(function (org) {
                    return { org };
                });
                $scope.orgs = data.options;
                updateOrganizationLists();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.orgs = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.$watch('temp.org', function () {
        $scope.onOrganizationSelected($scope.temp.org);
    });

    $scope.onOrganizationSelected = function (org) {
        if (org && org.displayName) {
            const selection = { org, selected: true };
            $scope.selections.push(selection);
            updateOrganizationLists();
            $scope.onSelectionChange(selection);
            $scope.temp.org = null;
        }
    };

    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateOrganizationLists() {
        if ($scope.orgs) {
            const orgInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.org.displayName;
                }),
            );
            $scope.otherOrganizations = $scope.orgs.filter(function (r) {
                return !orgInSelection.has(r.displayName);
            });
        }
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            orgs: $scope.orgs,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            organization: selection.org.value,
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
        // $uibModalInstance.close(s);
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (
            state &&
            state.integrations &&
            !$scope.integration &&
            state.integrations[0] &&
            state.integrations[0].integration
        ) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.projectIntegration = state.integrations[0];
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'orgs')
                .then(function (data) {
                    $scope.orgs = data.options;
                    $scope.selections = data.options
                        .map(function (org) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (projectData.organization === org.value) {
                                            return {
                                                org,
                                                selected: true,
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);
                    updateOrganizationLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }

    // $scope.init();
}
export default angular.module('tonkean.shared').controller('TrelloModalCtrl', TrelloModalCtrl);
