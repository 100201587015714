import React, { useMemo } from 'react';
import styled from 'styled-components';

import { QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE } from './QuestionWidget';
import type QuestionWidgetConfiguration from './QuestionWidgetConfiguration';
import QuestionWidgetListMinMaxConfigurationEditor from './QuestionWidgetListMinMaxConfigurationEditor';
import widgetGroupedFieldsUtils from './WidgetGroupedFieldsUtils';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import Section from '../../components/Section';
import WidgetRequiredIndicationConfiguration from '../../components/WidgetRequiredIndicationConfiguration';
import type { FieldsSelectorByFieldTypeGroupSummary } from '../CommonWidgetConfiguration';
import { WidgetConfigurationSingleFieldSelector } from '../CommonWidgetConfiguration';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../hooks';

import {
    CopyLinkButton,
    Field,
    InformationTooltip,
    Input,
    LoadingCircle,
    SimpleSelect,
    Toggle,
    useItemInterfaceContext,
} from '@tonkean/infrastructure';
import { DuplicateIcon as DuplicateIconSVG } from '@tonkean/svg';
import { FieldType, type WorkflowVersion } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InformationIconSize, InputSize } from '@tonkean/tui-theme/sizes';
import {
    RegexValidationType,
    validationToDisplayName,
    ValidationTypeToErrorMessage,
    ValidationTypeToRegexMap,
} from '@tonkean/utils';

interface Props {
    configuration: QuestionWidgetConfiguration;
    workflowVersion: WorkflowVersion;
}

const ValidationOptions = Object.values(RegexValidationType).map((validationType) => ({
    value: validationType.valueOf(),
    label: validationToDisplayName[validationType],
}));

const CustomRegexInput = styled(Field)`
    margin-top: 10px;
`;

const StyledRegexExplantion = styled(Input)`
    width: 320px;
`;

const StyledCopyButton = styled(CopyLinkButton)`
    width: 20px;
`;

const RegexExplanationDiv = styled.div`
    display: inline-flex;
`;

const StyledField = styled(Field)`
    justify-content: space-between;
`;

const SubTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: ${Theme.colors.gray_700};
    margin-top: 12px;
    margin-bottom: 8px;
`;

const QuestionWidgetWorkflowVersionFieldSelector: React.FC<Props> = ({ configuration, workflowVersion }) => {
    const { initiative } = useItemInterfaceContext();

    const groups = useMemo<FieldsSelectorByFieldTypeGroupSummary[]>(() => {
        return widgetGroupedFieldsUtils(configuration);
    }, [configuration]);

    const regexValidationExplanation = useMemo(() => {
        if (!configuration.regexValidationType) {
            return '';
        }

        return ValidationTypeToRegexMap[configuration.regexValidationType];
    }, [configuration.regexValidationType]);

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(
        workflowVersion.id,
        ['TONKEAN_FORMS'],
        undefined,
        undefined,
        true,
        false,
    );

    const filteredAllFieldDefinitions = useMemo(() => {
        return allFieldDefinitions.filter(
            (fieldDefinition) =>
                fieldDefinition.updateable && fieldDefinition.canUpdateFromUI && !fieldDefinition.idRelationField,
        );
    }, [allFieldDefinitions]);

    const selectedFieldDefinition = useMemo(() => {
        if (!filteredAllFieldDefinitions) {
            return undefined;
        }

        return filteredAllFieldDefinitions.find(
            (fieldDefinition) => fieldDefinition.id === configuration.selectedField?.key,
        );
    }, [configuration.selectedField, filteredAllFieldDefinitions]);

    if (loading || !allFieldDefinitions) {
        return <LoadingCircle large centered />;
    }

    return (
        <Section>
            <ConfigurationSectionTitle>Input</ConfigurationSectionTitle>
            <Field label="Field for input" light>
                <WidgetConfigurationSingleFieldSelector
                    allFieldDefinitions={filteredAllFieldDefinitions}
                    groups={groups}
                    workflowVersion={workflowVersion}
                    workflowVersionType={workflowVersion.workflowVersionType}
                    exampleItem={initiative}
                />
            </Field>
            {selectedFieldDefinition?.fieldType === FieldType.List &&
                !selectedFieldDefinition?.allowAddDropdownOptions && (
                    <StyledField
                        label="Display options as a list"
                        informationTooltip="Supports up to 20 items, otherwise it will be displayed as a dropdown"
                        light
                        inlineError
                        showLabelInline
                    >
                        <Toggle
                            dataAutomation="widget-configuration-toggle-question-widget-configuration-display-as-list"
                            size={InputSize.SMALL}
                            name="configuration.listConfiguration.displayAsList"
                        />
                    </StyledField>
                )}

            <Field label="Placeholder label" light inlineError>
                <Input
                    title="altText"
                    name="configuration.placeholderText"
                    autoComplete="off"
                    data-automation="widget-configuration-placeholder-text"
                />
            </Field>

            <StyledField label="Is required?" light inlineError showLabelInline>
                <Toggle
                    dataAutomation="widget-configuration-is-required"
                    size={InputSize.SMALL}
                    name="configuration.required"
                />
            </StyledField>

            {configuration.required && (
                <>
                    <Field
                        label="Required Message (Optional)"
                        informationTooltip="Replace the default text that is shown when a required field is missing"
                        light
                        inlineError
                    >
                        <Input
                            name="configuration.requiredMessage"
                            data-automation="widget-configuration-required-label"
                            placeholder={QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE}
                        />
                    </Field>

                    <WidgetRequiredIndicationConfiguration name="configuration.requiredIndicatorType" />
                </>
            )}
            {selectedFieldDefinition?.fieldType === FieldType.List &&
                selectedFieldDefinition.isMultiValueField &&
                configuration.listConfiguration?.displayAsList &&
                selectedFieldDefinition.possibleValues?.length && (
                    <QuestionWidgetListMinMaxConfigurationEditor
                        listConfiguration={configuration.listConfiguration}
                        possibleValuesSize={selectedFieldDefinition.possibleValues.length}
                    />
                )}

            <Field
                label="Field Validation"
                informationTooltip="Regular expressions are used to compare values with text patterns. It's highly recommended to use one of our presets or GPT to generate a custom pattern that you need"
                dataAutomation="widget-configuration-field-validation"
                light
                inlineError
            >
                <SimpleSelect name="configuration.regexValidationType" options={ValidationOptions} isClearable thin />
            </Field>

            {configuration?.regexValidationType &&
                configuration.regexValidationType !== RegexValidationType.CUSTOM_VALIDATION && (
                    <CustomRegexInput label="Validation Regex" inlineError>
                        <RegexExplanationDiv>
                            <StyledRegexExplantion value={regexValidationExplanation} disabled />
                            <StyledCopyButton
                                link={regexValidationExplanation}
                                tooltip="Copy expression"
                                icon={DuplicateIconSVG}
                            />
                        </RegexExplanationDiv>
                    </CustomRegexInput>
                )}

            {configuration.regexValidationType === RegexValidationType.CUSTOM_VALIDATION && (
                <>
                    <CustomRegexInput label="Regular Expression" inlineError>
                        <Input
                            title="altText"
                            name="configuration.customRegex"
                            autoComplete="off"
                            data-automation="widget-configuration-regular-expression"
                        />
                    </CustomRegexInput>
                </>
            )}

            {configuration.regexValidationType && (
                <>
                    <SubTitleWrapper>
                        Field Validation Message
                        <InformationTooltip iconSize={InformationIconSize.SMALL}>
                            Replace the default text that is shown when a field validation failed
                        </InformationTooltip>
                    </SubTitleWrapper>
                    <Field inlineError>
                        <Input
                            name="configuration.customRegexErrorMessage"
                            data-automation="widget-configuration-custom-regex-error-message"
                            placeholder={
                                configuration.regexValidationType
                                    ? ValidationTypeToErrorMessage?.[configuration.regexValidationType]
                                    : 'Field validation message'
                            }
                        />
                    </Field>
                </>
            )}
        </Section>
    );
};
export default QuestionWidgetWorkflowVersionFieldSelector;
