import template from './tnkModuleIntegrationsPreview.template.html?angularjs';

/**
 * A Preview of all the module's integrations
 */
export default angular.module('tonkean.app').component('tnkModuleIntegrationsPreview', {
    bindings: {
        worker: '<',
        size: '<',
        hideCircle: '<',
    },
    template,
    controller: 'ModuleIntegrationsPreviewCtrl',
});
