/**
 * Filter pair by the second value list.
 *
 * @example
 * const pairs = [
 *    ["pair 1", [1, 2, 3, 4, 5]],
 *    ["pair 2", [10, 11, 12, 13, 14]],
 *    ["pair 3", [1, 3, 5, 7, 9]],
 * ];
 * filterPairValues(pairs, (item) => ((item % 2) === 0));
 * // [ ["pair 1", [2, 4]], ["pair 2", [10, 12, 14]] ]
 *
 * @param pairsList - list of pairs to filter.
 * @param filter - a function that receives an item from the second value of the pair and
 * should returns false to filter out the item.
 * @returns filtered pair.
 */
function filterPairValues<T, Z>(pairsList: [T, Z[]][], filter: (value: Z) => boolean): [T, Z[]][] {
    return (
        pairsList
            .map(([dataType, items]) => [dataType, items.filter(filter)] as [T, Z[]])
            // Filter out data types with no related items
            .filter(([, items]) => items.length)
    );
}

export default filterPairValues;
