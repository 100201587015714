import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';

const useProjectIntegrationActionBreadCrumbsSettings = (
    currentDataSourceConnection: DataSourceConnection,
    otherPossibleDataSourceConnections: DataSourceConnection[],
) => {
    const integrations = useAngularService('integrations');
    const $state = useAngularService('$state');

    return useMemo(() => {
        const dataSourceConnectionsTab: BreadcrumbsSettings = {
            displayName: 'Data Source Connections',
            id: 'dataSourceConnections',
            clickable: {
                onClick: () => {
                    $state.go('product.settings.dataSources');
                },
            },
        };

        // Constructing the other possible project integration actions the user can switch to.
        const otherPossibilities = otherPossibleDataSourceConnections
            .filter((dataSourceConnection) => dataSourceConnection.id !== currentDataSourceConnection.id)
            .map((dataSourceConnection) => ({
                id: dataSourceConnection.id,
                displayName:
                    dataSourceConnection.dataSourceType === IntegrationType.WEBHOOK
                        ? 'Custom'
                        : integrations.getIntegrationDisplayNameByIntegrationType(
                              dataSourceConnection.dataSourceType.toLowerCase(),
                          ),
            }));

        const currentDataSourceConnectionsSettings: BreadcrumbsSettings = {
            displayName:
                currentDataSourceConnection.dataSourceType === IntegrationType.WEBHOOK
                    ? 'Custom'
                    : integrations.getIntegrationDisplayNameByIntegrationType(
                          currentDataSourceConnection.dataSourceType.toLowerCase(),
                      ),
            id: currentDataSourceConnection.id,
            prefixIcon: (
                <ProjectIntegrationIcon width={14} integrationType={currentDataSourceConnection.dataSourceType} />
            ),
            otherOptionsSettings: {
                otherOptions: otherPossibilities,
                onOtherOptionClicked: (id) => {
                    $state.go('product.dataSourceConnection', { dataSourceConnectionId: id }, { inherit: true });
                },
            },
        };

        return [dataSourceConnectionsTab, currentDataSourceConnectionsSettings];
    }, [
        integrations,
        currentDataSourceConnection.dataSourceType,
        currentDataSourceConnection.id,
        otherPossibleDataSourceConnections,
        $state,
    ]);
};

export default useProjectIntegrationActionBreadCrumbsSettings;
