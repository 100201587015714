import React from 'react';

import SingleWorkerRunFlowRunItemDetails from './SingleWorkerRunFlowRunItemDetails';
import SingleWorkerRunFlowRunTable from './SingleWorkerRunFlowRunTable/SingleWorkerRunFlowRunTable';
import { ReactComponent as FlowRunIcon } from '../../../../../../images/icons/history/flow-run.svg';
import getWorkerRunFullDescription from '../../../utils/getWorkerRunFullDescription';
import SingleWorkerRunContent from '../SingleWorkerRunContent';

import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';

interface Props {
    /** If undefined, it means that it's the initial load of the worker run, and not just a reload. */
    workerRun?: WorkerRun;
    manuallyReloading: boolean;
    autoReloading: boolean;
    reload(): void;
    inspectWorkerRun(): void;
}

const SingleWorkerRunFlowRun: React.FC<Props> = ({ workerRun, manuallyReloading, reload, inspectWorkerRun }) => {
    return (
        <SingleWorkerRunContent
            startTime={workerRun?.startTime}
            icon={
                <span className="tnk-icon">
                    <FlowRunIcon />
                </span>
            }
            title={workerRun && getWorkerRunFullDescription(workerRun)}
            workerRunStage={WorkerRunStage.FLOW_RUN}
            scrollable
        >
            <div>
                <SingleWorkerRunFlowRunItemDetails workerRun={workerRun} inspectWorkerRun={inspectWorkerRun} />
                <SingleWorkerRunFlowRunTable reload={reload} workerRun={!manuallyReloading ? workerRun : undefined} />
            </div>
        </SingleWorkerRunContent>
    );
};

export default SingleWorkerRunFlowRun;
