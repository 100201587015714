import React from 'react';
import styled from 'styled-components';

import TaggingOption from './TaggingOption';
import type { TaggableEntitiesItemsProps } from '../../../entities';

import { TonkeanAvatar } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const UserAvatar = styled.span`
    position: relative;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 50%;
    margin-right: 6px;

    .avatar-initials {
        width: 32px;
        height: 32px;
        position: relative;
    }
`;

const UserTitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
`;

const UserTagging: React.FC<TaggableEntitiesItemsProps> = ({ entry, search, highlightedDisplay, index, focused }) => {
    return (
        <TaggingOption data-automation="mention-input-person-option" $focused={focused}>
            <UserAvatar>
                <TonkeanAvatar owner={entry} />
            </UserAvatar>
            <UserTitle>{entry.display}</UserTitle>
        </TaggingOption>
    );
};

export default UserTagging;
