import lateConstructController from '../../utils/lateConstructController';
import { getFieldRangeAppliedStyle, getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function PreviewKeyMetricsCtrl(
    $scope,
    $q,
    tonkeanService,
    projectManager,
    customFieldsManager,
    requestThrottler,
    utils,
    fieldDisplay,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        definitionsToPreview: ctrl.definitionsToPreview,
        initiativeDefinitionsToPreview: ctrl.initiativeDefinitionsToPreview,
        highlightedDefinitionId: ctrl.highlightedDefinitionId,
        hidePreviewTitle: ctrl.hidePreviewTitle,
        softLoading: ctrl.softLoading,
        isBrokenIntegration: ctrl.isBrokenIntegration,
        showTracksLoading: ctrl.showTracksLoading,

        formulaSpecialFieldIdToDefinitionMap: getFormulaSpecialFieldIdToDefinitionMap(),
        fieldDefinitionIdToFieldDefinitionMap: utils.createMapFromArray(
            customFieldsManager.selectedFieldsMap[ctrl.workflowVersionId],
            'id',
        ),
        definitionsToPreviewMap: utils.createMapFromArray(ctrl.definitionsToPreview, 'definitionId'),

        highlightedDefinition: null,
        queriedInitiatives: [],

        previewListTextSearch: null,

        loadingAggregatedInitiativesSearch: false,

        loading: false,
        error: null,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        initializeHighlightedDefinition();

        loadPreviewData(ctrl.softLoading);
    };

    /**
     * Check if there was change between the current and previous value of definitionsToPreview
     */
    function didDefinitionToPreviewChanged(changes) {
        const { currentValue, previousValue } = changes.definitionsToPreview;

        if (currentValue?.length !== previousValue?.length) {
            return true;
        }

        if (currentValue?.length === 0) {
            return false;
        }

        return currentValue?.some((singleCurrentValue, index) => {
            const singlePreviousValue = previousValue[index];
            return (
                singleCurrentValue?.definitionId !== singlePreviousValue?.definitionId ||
                singleCurrentValue?.name !== singlePreviousValue?.name ||
                singleCurrentValue?.previewDefinitionType !== singlePreviousValue?.previewDefinitionType ||
                singleCurrentValue?.fieldDefinitionTargetType !== singlePreviousValue?.fieldDefinitionTargetType ||
                singleCurrentValue?.validDefinition !== singlePreviousValue?.validDefinition
            );
        });
    }

    /**
     * Occurs on changes to component bindings.
     */
    ctrl.$onChanges = function (changes) {
        let shouldReload = false;
        if (changes.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            shouldReload = true;
        }

        if (shouldReload || (changes.reloadPreview && changes.reloadPreview.currentValue)) {
            $scope.reloadPreview(ctrl.softLoading);
        }

        if (changes.definitionsToPreview && didDefinitionToPreviewChanged(changes)) {
            $scope.data.definitionsToPreview = ctrl.definitionsToPreview;
            $scope.data.definitionsToPreviewMap = utils.createMapFromArray(
                $scope.data.definitionsToPreview,
                'definitionId',
            );

            initializeHighlightedDefinition();
            $scope.reloadPreview(ctrl.softLoading);
        }

        if (changes.initiativeDefinitionsToPreview) {
            $scope.data.initiativeDefinitionsToPreview = ctrl.initiativeDefinitionsToPreview;
        }

        if (changes.highlightedDefinitionId) {
            $scope.data.highlightedDefinitionId = ctrl.highlightedDefinitionId;

            initializeHighlightedDefinition();
        }
    };

    /**
     * Reloads the preview data.
     */
    $scope.reloadPreview = function () {
        loadPreviewData(ctrl.softLoading);
    };

    /**
     * Initializes the highlighted definition.
     */
    function initializeHighlightedDefinition() {
        // Initializing highlighted definition.
        $scope.data.highlightedDefinition = $scope.data.definitionsToPreviewMap[$scope.data.highlightedDefinitionId];
    }

    /**
     * Executes the preview request for the list.
     */
    function getPreviewKeyMetricsPromise() {
        // Going through the definitions we want to preview, and if their definition is not valid yet, we mark them as do not calculate preview.
        const definitionsToPreview = [];
        for (let i = 0; i < $scope.data.definitionsToPreview.length; i++) {
            const definition = $scope.data.definitionsToPreview[i];

            if (definition) {
                definition.doNotCalculatePreviewForDefinition = !definition.validDefinition;
                definitionsToPreview.push(definition);
            }
        }

        if (!definitionsToPreview.length) {
            // If we don't have any definitions to preview, we mock the data.

            const globalDefinitionIdToValueMap = {};

            for (let i = 0; i < $scope.data.definitionsToPreview.length; i++) {
                globalDefinitionIdToValueMap[$scope.data.definitionsToPreview[i].definitionId] = {};
            }

            return $q.resolve({
                globalDefinitionIdToValueMap,
            });
        } else {
            return tonkeanService.previewListFieldDefinitions($scope.data.workflowVersionId, definitionsToPreview);
        }
    }

    /**
     * Handles the data retrieved by the preview API request.
     */
    function handlePreviewData(data) {
        $scope.data.globalDefinitionIdToValueMap = data.globalDefinitionIdToValueMap;

        // Evaluating range and display value
        for (const key in $scope.data.globalDefinitionIdToValueMap) {
            if ($scope.data.globalDefinitionIdToValueMap.hasOwnProperty(key)) {
                const valueObject = $scope.data.globalDefinitionIdToValueMap[key];
                const definition = $scope.data.definitionsToPreviewMap[key];

                valueObject.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                    definition.evaluatedDisplayType,
                    definition.displayFormat,
                    valueObject.value,
                    valueObject.dateValue,
                    valueObject.formattedValue,
                );

                if (valueObject.range) {
                    const appliedStyle = getFieldRangeAppliedStyle(definition.isImportant, valueObject.range.color);
                    valueObject.style = appliedStyle.style;
                }
            }
        }

        return $q.resolve(data);
    }

    /**
     * Handles the error block of the preview API request.
     */
    function handlePreviewError() {
        $scope.data.error = 'There was an error trying to preview key metric.';
    }

    /**
     * Handles the finally block of the preview API request.
     */
    function handlePreviewFinalCompletion() {
        $scope.data.loading = false;
        $scope.data.softLoading = false;
    }

    /**
     * Loads the preview data from API.
     */
    function loadPreviewData(softLoading) {
        $scope.data.loading = true;
        $scope.data.softLoading = softLoading;
        $scope.data.error = null;

        requestThrottler.do(
            'previewKeyMetrics',
            300,
            () => getPreviewKeyMetricsPromise(),
            (data) => handlePreviewData(data),
            (error) => handlePreviewError(error),
            () => handlePreviewFinalCompletion(),
        );
    }
}
angular.module('tonkean.app').controller('PreviewKeyMetricsCtrl', lateConstructController(PreviewKeyMetricsCtrl));
