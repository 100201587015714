import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BotHistoryCtrl(
    $scope,
    projectManager,
    initiativeCache,
    botHistoryHelper,
    modalUtils,
    timeUtils,
    utils,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.itemMap = initiativeCache.getInitiativesCache();

    $scope.data = {
        loading: false,
        hardLoading: false, // If need to use the big spinner forcibly.
        filter: ctrl.filter,
        totalCount: 0,
        items: [],
        popovers: {
            trackStatusSummary: null,
        },
        historySections: [
            {
                label: 'Today',
                predicate: (itemTime) => timeUtils.isToday(itemTime),
                items: [],
            },
            {
                label: 'Yesterday',
                predicate: (itemTime) => timeUtils.isYesterday(itemTime),
                items: [],
            },
            {
                label: 'Last 7 days',
                predicate: (itemTime) => timeUtils.isInPastSevenDays(itemTime),
                items: [],
            },

            // Default
            {
                label: 'Older',
                predicate: () => true,
                items: [],
            },
        ],
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.filter) {
            $scope.data.filter = changesObj.filter.currentValue;
            $scope.loadHistory(true);
        }
    };

    $scope.loadHistory = function (hardLoad) {
        $scope.data.loading = true;
        $scope.data.hardLoading = hardLoad;

        if (hardLoad) {
            $scope.data.items = [];
        }

        const filter = $scope.data.filter || {};
        const groupId = filter.group ? filter.group.id : null;
        const ownerId = filter.owner ? filter.owner.id : null;

        const after = null; // for now we never update the list on new items (because of polling)

        let before = null;
        if ($scope.data.items && $scope.data.items.length) {
            before = $scope.data.items[$scope.data.items.length - 1].created;
        }

        return botHistoryHelper
            .loadBotHistory($scope.pm.project.id, groupId, ownerId, filter.searchText, before, after)
            .then((data) => {
                const items = data.entities;
                $scope.data.totalCount = data.totalCount;

                // Hold 1 array of all the items, and add or replace old items with the new items.
                for (const item of items) {
                    utils.addOrReplaceById($scope.data.items, item);
                }

                // Empty sections from stale data.
                for (let i = 0; i < $scope.data.historySections.length; i++) {
                    const section = $scope.data.historySections[i];
                    section.items = [];
                }

                // Fill sections with fresh data.
                for (let i = 0; i < $scope.data.items.length; i++) {
                    const item = $scope.data.items[i];
                    for (let j = 0; j < $scope.data.historySections.length; j++) {
                        const section = $scope.data.historySections[j];
                        if (section.predicate(item.created)) {
                            section.items.push(item);
                            break;
                        }
                    }
                }
            })
            .finally(() => {
                $scope.data.loading = false;
                $scope.data.hardLoading = false;
            });
    };

    $scope.getInitiativeIdFromItemId = function (itemId) {
        const item = utils.findFirstById($scope.data.items, itemId);
        if (item) {
            return item.initiative.id;
        }

        return null;
    };

    // region UiActions

    // UI functions needed by the tnkTrackListItem.
    $scope.botHistoryUiActions = {};

    $scope.init = function () {
        $scope.initUiActions();
    };

    $scope.initUiActions = function () {
        $scope.botHistoryUiActions = {
            goToTrack() {
                $scope.$apply($scope.goToTrack.apply(null, arguments));
            },
            toggleTrackStatusSummaryPopover() {
                $scope.$apply($scope.toggleTrackStatusSummaryPopover.apply(null, arguments));
            },
        };
    };

    $scope.goToTrack = function (initiativeId) {
        // otherwise open in popup
        modalUtils.openViewInitiative(initiativeId);
    };

    $scope.toggleTrackStatusSummaryPopover = function (itemId) {
        $scope.data.popovers.trackStatusSummary = itemId;
    };

    // endregion UiActions

    $scope.init();
}
export default angular.module('tonkean.app').controller('BotHistoryCtrl', lateConstructController(BotHistoryCtrl));
