import type { TonkeanService } from '@tonkean/shared-services';

/**
 * Gets the autonomous triggers that we need to create after using extraction (if any are needed).
 */
async function getExtractionTriggersToCreate(
    workflowVersionId: string,
    tonkeanService: TonkeanService,
    customTriggerManager,
    configuredLogic: any,
    trainingSetId: string,
): Promise<Record<string, any>[]> {
    // First we check if we already have any children.
    // If so, we do nothing.
    const anyChildren: boolean = customTriggerManager.anyLogicChildren(configuredLogic, () => true) || false;

    if (anyChildren) {
        return [];
    }

    return Promise.all([
        // Fetch training set (for its name).
        tonkeanService.getTrainingSetById(trainingSetId),

        // Fetch training set fields (for their names and identifiers).
        tonkeanService.getTrainingSetFields(trainingSetId),

        // Fetch linked field definitions (for the link between a training set field id and its field definition id).
        tonkeanService.getCustomTriggerLinkedFieldDefinitions(workflowVersionId, configuredLogic.node.id),
    ]).then((results) => {
        const trainingSet = results[0];
        const trainingSetFields = results[1]?.trainingSetFields || [];
        const trainingSetFieldIdToFieldDefinitionIdMap = results[2]?.linkedFieldTypeToFieldDefinitionIdMap || {};

        // If at least one of the extracted fields isn't empty, it's a win.
        const successfulExtractionTrigger = {
            displayName: 'Successful Extraction',
            type: 'INNER_AUTONOMOUS',
            customTriggerSecondaryType: 'AUTONOMOUS_MATCH_CONDITIONS',
            queryDefinition: {
                query: {
                    type: 'Or',
                    filters: trainingSetFields.map((trainingSetField) => ({
                        fromOriginalEntity: true,
                        type: 'LongString',
                        fieldName: trainingSetFieldIdToFieldDefinitionIdMap[trainingSetField.id],
                        fieldLabel: `${trainingSetField.fieldName} (${trainingSet.displayName})`,
                        condition: 'IsNotEmpty',
                    })),
                },
            },
        };

        // If all of the extracted fields are empty, it's a failure.
        const failedExtractionTrigger = {
            displayName: 'Failed Extraction',
            type: 'INNER_AUTONOMOUS',
            customTriggerSecondaryType: 'AUTONOMOUS_MATCH_CONDITIONS',
            queryDefinition: {
                query: {
                    type: 'And',
                    filters: trainingSetFields.map((trainingSetField) => ({
                        fromOriginalEntity: true,
                        type: 'LongString',
                        fieldName: trainingSetFieldIdToFieldDefinitionIdMap[trainingSetField.id],
                        fieldLabel: `${trainingSetField.fieldName} (${trainingSet.displayName})`,
                        condition: 'IsEmpty',
                    })),
                },
            },
        };

        return [successfulExtractionTrigger, failedExtractionTrigger];
    });
}

export default getExtractionTriggersToCreate;
