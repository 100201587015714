import React, { useContext, useEffect } from 'react';

import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import RichTextEditorInputWidgetViewBody from './RichTextEditorInputWidgetViewBody';
import { ItemInterfacePermission, ItemWidgetContext } from '../../entities';
import { useItemFieldWidget } from '../hooks';

import type { HTMLEditorPlugin } from '@tonkean/editor';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, WidgetBase } from '@tonkean/tonkean-entities';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface Props extends DataAutomationSupportProps {
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
    permission: ItemInterfacePermission;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    editorPlugins: HTMLEditorPlugin[];
    isEditMode: boolean;
    setIsEditMode: (isShowEdit: boolean) => void;
    setIsEmptyState: (isShowEdit: boolean) => void;
}

const RichTextEditorInputWidgetView: React.FC<Props> = ({
    widget,
    permission,
    workflowVersionId,
    editorPlugins,
    isEditMode,
    setIsEditMode,
    setIsEmptyState,
}) => {
    const widgetContext = useContext(ItemWidgetContext);
    const isBuilderMode = widgetContext?.isEditable && permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;
    const { initiative, workflowVersion, itemInterfaceFieldDefinitions, updateFieldsValuesManager } =
        useItemInterfaceContext();

    const [onChange, configuredFields] = useItemFieldWidget(
        workflowVersionId,
        'ITEM_INTERFACE_FIELDS_WIDGET',
        updateFieldsValuesManager,
        workflowVersion,
        initiative,
        itemInterfaceFieldDefinitions,
        undefined,
        widget.configuration.selectedField,
    );

    const isItemExists = !!initiative && !!workflowVersion && !!configuredFields?.[0];

    useEffect(() => {
        setIsEmptyState(!isItemExists);
    }, [isItemExists, setIsEmptyState]);

    return (
        <RichTextEditorInputWidgetViewBody
            isBuilderMode={isBuilderMode}
            widget={widget}
            editorPlugins={editorPlugins}
            initiative={initiative}
            workflowVersion={workflowVersion}
            configuredFields={configuredFields}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onChange={onChange}
        />
    );
};

export default React.memo(RichTextEditorInputWidgetView);
