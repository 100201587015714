import WorkerHistoryItemDispatcher from '../dispatchers/WorkerHistoryItemDispatcher';

/**
 * A map for action type names.
 */
export const WorkerHistoryItemActionTypes = {
    TOGGLE_ACTIONS: 'TOGGLE_ACTIONS',
};

/**
 * Our flux actions.
 */
export const WorkerHistoryItemActions = {
    // Toggle history item actions.
    toggleActions(id, isTrue) {
        WorkerHistoryItemDispatcher.dispatch({
            type: WorkerHistoryItemActionTypes.TOGGLE_ACTIONS,
            id,
            isTrue,
        });
    },
};

export default WorkerHistoryItemActions;
