<div class="create-solution-modal common-close-btn-container" ng-init="init()">
    <div class="padding-normal margin-bottom-lg">
        <!-- Close button -->
        <span class="common-close-btn svg-icon" ng-click="cancel()">
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </span>

        <!-- Title -->
        <div class="common-size-l common-bold margin-bottom">Create New Solution</div>

        <div class="margin-top-xs sub-title common-size-15">
            Let’s create new Solution that will contain multiple Modules
        </div>

        <!-- Solution name input -->
        <div class="margin-bottom-xlg margin-top-xlg">
            <div class="common-size-15 margin-bottom input-title">Give your Solution a Name:</div>
            <div class="flex-vmiddle">
                <input
                    class="form-control common-input-only-line flex-grow solution-name-font"
                    data-automation="create-workflow-folder-solution-input-name"
                    ng-model="data.workflowFolderName"
                    placeholder="Solution name..."
                    autofocus
                    required
                />

                <i class="fa fa-pencil common-color-blue margin-left-minus"></i>
            </div>

            <div class="margin-top-20 flex-vmiddle" ng-if="shouldAllowUserToAddSandBox">
                <tnk-react-checkbox
                    on-change="(onIsSandboxChanged)"
                    disabled="isSandboxDisabled()"
                    checked="isSandbox"
                ></tnk-react-checkbox>
                <span class="margin-left-xxs input-title">Sandbox Solution?</span>

                <i
                    class="fa fa-info-circle margin-left-xs"
                    uib-tooltip="Create a sandbox environment to test your modules in a secure manner. Sandbox environment can not be used for production i.e modules can not be published"
                ></i>
            </div>
        </div>
    </div>

    <hr class="margin-none" />

    <div class="padding-normal">
        <!-- Save button and error -->
        <div class="flex mod-justify-end mod-align-center">
            <div class="flex mod-justify-end">
                <button ng-click="cancel()" class="btn round-button btn-round-white margin-right">Cancel</button>

                <button
                    class="btn round-button btn-blue"
                    ng-click="save()"
                    data-automation="create-workflow-folder-modal-submit-new-solution"
                    ng-disabled="!data.workflowFolderName || !data.workflowFolderName.length"
                >
                    Create
                    <i ng-if="data.loading" class="loading-small"></i>
                </button>
            </div>

            <div ng-if="data.error">{{ data.error }}</div>
        </div>
    </div>
</div>
