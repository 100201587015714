import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type { FieldsSelectorByWorkflowVersionIdGroupSummary } from './FieldsSelectorByWorkflowVersionIdGroupSummary';
import type { SingleFieldConfiguration } from './SingleFieldConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import FieldsWidgetAddField from '../FieldsWidgetModule/components/FieldsWidgetAddField';
import FieldsWidgetConfigFieldRow from '../FieldsWidgetModule/components/FieldsWidgetConfigFieldRow';
import type FieldsWidgetMenuItem from '../FieldsWidgetModule/components/FieldsWidgetMenuItem';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Checkbox, Message, MessageType, SearchBox, useFormikField, useSearch } from '@tonkean/infrastructure';
import { getFieldInitialWidth } from '@tonkean/items-grid';
import type {
    FieldDefinition,
    FieldDefinitionKey,
    Initiative,
    SpecialFieldsKey,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const DeletedFieldsMessage = styled(Message)`
    margin-bottom: 16px;
`;

const DeletedFieldsMessageText = styled.div`
    margin-bottom: 8px;
`;

const StyledSearch = styled(SearchBox)`
    margin-bottom: 25px;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 17px;
`;

const CheckboxLabel = styled.span`
    margin-left: 8px;
`;

const Wrapper = styled.div`
    padding: 0 20px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const FieldsSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const MaxFieldsRemark = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_600};
    margin-bottom: 24px;
`;
/**
 * Special fields have a fixed position in the tracks editor.
 * This mapping is used to fix the special fields in the configuration menu of the items widget.
 */
const specialFieldsIndex: Record<FieldDefinition['id'], number> = {
    TNK_TITLE: -5,
    TNK_DUE_DATE: -4,
    TNK_STAGE: -3,
    TNK_OWNER_ID: -2,
    TNK_TAGS: -1,
};

const comparer = (field: FieldDefinition, search: string) =>
    field.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());

interface Props {
    allFieldDefinitions: FieldDefinition[];
    deletedFieldsIds: string[];
    workflowVersion: WorkflowVersion | undefined;
    workflowVersionType?: WorkflowVersionType;
    groups: FieldsSelectorByWorkflowVersionIdGroupSummary[] | undefined;
    exampleItem?: Initiative | undefined;
    enableSearch?: boolean;
    fieldConfigurationPath?: string;
    fixedOrderForSpecialFields: boolean;
    shouldToggleFilters?: boolean;
    onFieldChanged?: (value: Record<FieldDefinitionKey, SingleFieldConfiguration>) => void;
    forceRemoveDeletedFields?: boolean;
    configurationTitle?: string;
    maxAmountOfFields?: number;
    hideAllFieldsCheckbox?: boolean;
    disabledFields?: string[];
    disableEditFields?: string[];
    disableOrderFields?: string[];
    fieldsAdditionalOptions?: FieldsWidgetMenuItem[];
    supportRequiredFields?: boolean;
    supportFieldWidth?: boolean;
}

const WidgetConfigurationFieldSelector: React.FC<Props> = ({
    allFieldDefinitions,
    deletedFieldsIds,
    workflowVersion,
    workflowVersionType,
    groups,
    exampleItem,
    configurationTitle,
    maxAmountOfFields,
    enableSearch = true,
    fieldConfigurationPath = 'configuration.fields',
    fixedOrderForSpecialFields,
    onFieldChanged,
    forceRemoveDeletedFields = false,
    shouldToggleFilters,
    hideAllFieldsCheckbox = false,
    disabledFields,
    disableEditFields,
    disableOrderFields,
    fieldsAdditionalOptions,
    supportRequiredFields = false,
    supportFieldWidth = false,
}) => {
    const { value: fields, setValue: setConfigValue } =
        useFormikField<Record<FieldDefinitionKey, SingleFieldConfiguration>>(fieldConfigurationPath);

    const updateConfigValue = useCallback(
        (configValue: Record<FieldDefinitionKey, SingleFieldConfiguration>) => {
            setConfigValue(configValue);
            if (onFieldChanged) {
                onFieldChanged(configValue);
            }
        },
        [onFieldChanged, setConfigValue],
    );

    const onRemoveDeletedFields = useCallback(
        (fieldIds?: string[]) => {
            const configValue = { ...fields };
            const fieldsToDelete = fieldIds || deletedFieldsIds;
            fieldsToDelete?.forEach?.((id) => delete configValue[id]);
            updateConfigValue(configValue);
        },
        [deletedFieldsIds, fields, updateConfigValue],
    );

    const updateFieldConfiguration = useCallback(
        (fieldConfiguration: SingleFieldConfiguration | undefined, checked: boolean) => {
            if (fieldConfiguration) {
                fieldConfiguration.isShown = checked;
            }
        },
        [],
    );

    const setCheckedFields = useCallback(
        (fieldId: string, checked: boolean) => {
            if (fields) {
                const configValue: Record<FieldDefinitionKey, SingleFieldConfiguration> = { ...fields };
                updateFieldConfiguration(configValue[fieldId], checked);
                updateConfigValue(configValue);
            }
        },
        [fields, updateConfigValue, updateFieldConfiguration],
    );

    const fieldsExistenceMap = useMemo(
        () => Object.fromEntries(allFieldDefinitions.map((definition) => [definition.id, definition])),
        [allFieldDefinitions],
    );

    const changeFieldWidth = useCallback(
        (widthCalc: (width: number) => number) =>
            (fieldId: TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}` | SpecialFieldsKey) => {
                if (fields) {
                    const configValue: Record<FieldDefinitionKey, SingleFieldConfiguration> = { ...fields };
                    const fieldToUpdate = configValue[fieldId];
                    if (fieldToUpdate) {
                        const fieldDefinition = fieldsExistenceMap[fieldId];
                        fieldToUpdate.width = widthCalc(
                            fieldToUpdate.width || getFieldInitialWidth(fieldId, fieldDefinition),
                        );
                    }
                    updateConfigValue(configValue);
                }
            },
        [fields, updateConfigValue, fieldsExistenceMap],
    );

    const increaseFieldWidth = useCallback(() => changeFieldWidth((width: number) => width + 4), [changeFieldWidth]);

    const decreaseFieldWidth = useCallback(() => changeFieldWidth((width: number) => width - 4), [changeFieldWidth]);

    const configuredFields: FieldDefinition[] = useMemo(() => {
        return allFieldDefinitions?.filter((fieldDefinition) => fields?.[fieldDefinition?.id]);
    }, [allFieldDefinitions, fields]);

    const {
        searchTerm,
        setSearchTerm,
        debouncedFilteredItems: fieldsToShow,
    } = useSearch<FieldDefinition>(configuredFields, comparer);

    const allFieldsSelected = useMemo(
        () => configuredFields.every((field) => fields[field.id]?.isShown),
        [configuredFields, fields],
    );

    const anyFieldSelected = useMemo(
        () => configuredFields.some((field) => fields[field.id]?.isShown),
        [configuredFields, fields],
    );

    const toggleAll = useCallback(
        ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            const configValue = { ...fields };
            const fieldsArray: string[] = Object.keys(configValue);
            fieldsArray.forEach((fieldId) => {
                updateFieldConfiguration(configValue[fieldId], checked);
            });
            updateConfigValue(configValue);
        },
        [fields, updateConfigValue, updateFieldConfiguration],
    );

    const unselectedFieldsDefinitions = useMemo(
        () =>
            allFieldDefinitions?.filter((item) => {
                return !item.ignoreInItemInterfaces && !fields?.[item?.id] && fieldsExistenceMap?.[item?.id];
            }),
        [allFieldDefinitions, fields, fieldsExistenceMap],
    );

    const sortedFieldDefinitions = useMemo(() => {
        return fieldsToShow.sort((itemA, itemB) => {
            let itemAIndex: number;
            let itemBIndex: number;

            if (fixedOrderForSpecialFields) {
                itemAIndex = specialFieldsIndex[itemA.id] ?? Number(fields[itemA.id]?.index) ?? 0;
                itemBIndex = specialFieldsIndex[itemB.id] ?? Number(fields[itemB.id]?.index) ?? 0;
            } else {
                itemAIndex = Number(fields[itemA.id]?.index) ?? 0;
                itemBIndex = Number(fields[itemB.id]?.index) ?? 0;
            }

            return itemAIndex - itemBIndex;
        });
    }, [fieldsToShow, fixedOrderForSpecialFields, fields]);

    const fieldsIndex = useMemo(() => {
        if (fixedOrderForSpecialFields) {
            const sortedFieldDefinitionsWithoutSpecial = sortedFieldDefinitions.filter(
                (fieldDefinition) => !specialFieldsIndex[fieldDefinition.id],
            );

            const sortedFieldsSize = sortedFieldDefinitionsWithoutSpecial
                ? sortedFieldDefinitionsWithoutSpecial.length - 1
                : 0;
            return {
                min: sortedFieldDefinitionsWithoutSpecial[0]?.id,
                max: sortedFieldDefinitionsWithoutSpecial[sortedFieldsSize]?.id,
            };
        } else {
            const sortedFieldsSize = sortedFieldDefinitions ? sortedFieldDefinitions.length - 1 : 0;
            return {
                min: sortedFieldDefinitions[0]?.id,
                max: sortedFieldDefinitions[sortedFieldsSize]?.id,
            };
        }
    }, [fixedOrderForSpecialFields, sortedFieldDefinitions]);

    const numberOfFieldsConfiguredToShow = useMemo(() => {
        if (!fields || Object.keys(fields).length === 0) {
            return 0;
        } else {
            return Object.values(fields)?.filter((field) => field.isShown).length;
        }
    }, [fields]);

    const addFieldToConfiguration = useCallback(
        (field: FieldDefinition) => {
            if (field?.id && fields) {
                const configValue: Record<FieldDefinitionKey, SingleFieldConfiguration> = { ...fields };
                const isFieldShown = !(maxAmountOfFields && maxAmountOfFields <= numberOfFieldsConfiguredToShow);
                const newIndex =
                    fieldsIndex?.max && fields[fieldsIndex?.max]?.index
                        ? Number(fields[fieldsIndex.max]?.index) + 1
                        : Object.keys(fields).length;
                configValue[field?.id] = {
                    isShown: isFieldShown,
                    fullWidth: false,
                    index: newIndex?.toString(),
                    hideColorsAndTrends: true,
                };
                updateConfigValue(configValue);
            }
        },
        [fields, fieldsIndex.max, maxAmountOfFields, numberOfFieldsConfiguredToShow, updateConfigValue],
    );

    const updateFieldIndex = useCallback(
        (fieldId: string, index: number) => {
            if (fields) {
                const configValue: Record<FieldDefinitionKey, SingleFieldConfiguration> = { ...fields };
                const fieldToUpdate = configValue[fieldId];
                const fieldToUpdateLocationInFieldDefinition = sortedFieldDefinitions.findIndex(
                    (field) => field.id === fieldId,
                );
                if (fieldToUpdate && fieldToUpdateLocationInFieldDefinition > -1) {
                    // direction of index change
                    const direction = Number(fieldToUpdate.index) < index ? 1 : -1;
                    const originalIndex = fieldToUpdate.index;
                    fieldToUpdate.index = index.toString();
                    const fieldIdToSwapWith =
                        sortedFieldDefinitions[fieldToUpdateLocationInFieldDefinition + direction]?.id;

                    if (fieldIdToSwapWith) {
                        const fieldToUpdateOriginalIndex = configValue[fieldIdToSwapWith];
                        if (fieldToUpdateOriginalIndex) {
                            fieldToUpdateOriginalIndex.index = originalIndex;
                        }
                    }
                }

                updateConfigValue(configValue);
            }
        },
        [fields, updateConfigValue, sortedFieldDefinitions],
    );

    // Using actual lists length since it might take a little time after the search term has changed,
    // and that caused "all fields" checkbox to disappear before the fields were filtered
    const showAllFieldsCheckbox = fieldsToShow.length === configuredFields.length && !hideAllFieldsCheckbox;

    const showAddAnotherField: boolean = allFieldDefinitions && !!unselectedFieldsDefinitions.length;

    const fieldWidthFF = useFeatureFlag('tonkean_feature_line_items_column_width');

    const shouldAddFieldsCheckedAutomatically = maxAmountOfFields
        ? maxAmountOfFields > numberOfFieldsConfiguredToShow
        : true;

    return (
        <Wrapper>
            <TitleWrapper>
                <FieldsSectionTitle>{configurationTitle || 'Fields'}</FieldsSectionTitle>
            </TitleWrapper>
            {enableSearch && (
                <StyledSearch
                    data-automaton="widget-configuration-field-selector-search-input"
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                />
            )}
            {showAllFieldsCheckbox && (
                <StyledCheckbox
                    dataAutomation="show-hide-all-fields"
                    boldLabel={false}
                    checked={allFieldsSelected}
                    onChange={toggleAll}
                    indeterminate={!allFieldsSelected && anyFieldSelected}
                    highlighted
                    labelFillSpace
                >
                    <CheckboxLabel>Show / Hide all fields</CheckboxLabel>
                </StyledCheckbox>
            )}
            {!!deletedFieldsIds?.length && (
                <DeletedFieldsMessage type={MessageType.WARNING}>
                    <DeletedFieldsMessageText>
                        {deletedFieldsIds.length} fields are no longer available in the associated module.
                    </DeletedFieldsMessageText>
                    <Button
                        data-automation="widget-configuration-field-selector-remove-all-fields"
                        onClick={() => onRemoveDeletedFields()}
                        size={ButtonSize.MEDIUM}
                        warningOutlined
                    >
                        Remove Deleted Fields
                    </Button>
                </DeletedFieldsMessage>
            )}

            {maxAmountOfFields === numberOfFieldsConfiguredToShow && (
                <MaxFieldsRemark>You can select to show up to 6 fields in the cards display</MaxFieldsRemark>
            )}

            {sortedFieldDefinitions.map((fieldDefinition) => {
                let fieldIndex: number = 0;
                if (fixedOrderForSpecialFields) {
                    fieldIndex =
                        specialFieldsIndex[fieldDefinition.id] ?? Number(fields[fieldDefinition.id]?.index) ?? 0;
                } else {
                    fieldIndex = Number(fields[fieldDefinition.id]?.index) ?? 0;
                }

                // A negative index means the fields is a special field and thus has a fixed index
                const maxFieldIndex =
                    fieldIndex < 0 ? fieldIndex : Number(fields[fieldsIndex?.max || fieldDefinition.id]?.index);

                // A negative index means the fields is a special field and thus has a fixed index
                const minFieldIndex =
                    fieldIndex < 0 ? fieldIndex : Number(fields[fieldsIndex?.min || fieldDefinition.id]?.index);

                const isFieldDisabled =
                    !!(
                        maxAmountOfFields &&
                        maxAmountOfFields <= numberOfFieldsConfiguredToShow &&
                        !fields[fieldDefinition.id]?.isShown
                    ) || disabledFields?.includes(fieldDefinition.id);

                const disableEditingField = !!disableEditFields?.includes(fieldDefinition.id);
                const disableChangeOrder = !!disableOrderFields?.includes(fieldDefinition.id);
                const fieldWidthConfig =
                    supportFieldWidth && fieldWidthFF
                        ? { increaseFieldWidth: increaseFieldWidth(), decreaseFieldWidth: decreaseFieldWidth() }
                        : {};

                return (
                    <FieldsWidgetConfigFieldRow
                        key={fieldDefinition.id}
                        formikName={`${fieldConfigurationPath}.[${fieldDefinition.id}]`}
                        fieldDefinition={fieldDefinition}
                        exampleItem={exampleItem}
                        workflowVersion={workflowVersion}
                        workflowVersionType={workflowVersionType}
                        isShown={fields[fieldDefinition.id]?.isShown}
                        setIsFieldShown={setCheckedFields}
                        onRemoveDeletedFields={onRemoveDeletedFields}
                        maxFieldIndex={maxFieldIndex}
                        minFieldIndex={minFieldIndex}
                        updateFieldIndex={updateFieldIndex}
                        fieldIndex={fieldIndex}
                        disabledCheckbox={isFieldDisabled}
                        shouldToggleFilters={shouldToggleFilters}
                        disableEditingField={disableEditingField}
                        disableChangeOrder={disableChangeOrder}
                        additionalMenuItems={fieldsAdditionalOptions}
                        supportRequiredFields={supportRequiredFields}
                        fieldWidthConfig={fieldWidthConfig}
                    />
                );
            })}
            {showAddAnotherField && !(forceRemoveDeletedFields && deletedFieldsIds.length > 0) && (
                <FieldsWidgetAddField
                    addFieldToConfiguration={addFieldToConfiguration}
                    groups={groups}
                    fieldDefinitions={unselectedFieldsDefinitions}
                    isChecked={shouldAddFieldsCheckedAutomatically}
                />
            )}
        </Wrapper>
    );
};
export default React.memo(WidgetConfigurationFieldSelector);
