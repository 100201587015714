<ul
    class="list-group sub-items-list drag-drop-list mod-small"
    dnd-list
    dnd-drop="onDrop(day, item, index)"
    dnd-allowed-types="['dateItem']"
    dnd-disable-if="!as.currentUser || !as.currentUser.isUser"
    ng-class="{ 'mod-empty': !day.items.length }"
>
    <li
        dnd-draggable="item.id"
        dnd-type="'dateItem'"
        dnd-effect-allowed="move"
        dnd-disable-if="!itemMap[id].group.canUserWrite || (!itemMap[item.trackId].dueDate || !timeUtils.isSameDay(day.date, itemMap[item.trackId].dueDate))"
        class="block"
        draggable="{{
            itemMap[id].eta && timeUtils.getDateToken(itemMap[id].eta) === day.dateToken ? 'false' : 'true'
        }}"
        ng-repeat="(id,item) in day.items"
        ng-if="
            (!groupId || itemMap[id].group.id === groupId) &&
            (isDraft ? item.isDraftInitiative : !item.isDraftInitiative) &&
            !itemMap[id].isArchived &&
            !itemMap[item.id].deleted &&
            (!stateText || itemMap[id].stateText === stateText) &&
            (!funcId || (itemMap[id].function && itemMap[id].function.id === funcId)) &&
            (!ownerId || (itemMap[id].owner && itemMap[id].owner.id === ownerId)) &&
            (!tag || (itemMap[id].tags && itemMap[id].tags.indexOf(tag) > -1))
        "
    >
        <tnk-track-week-view-item
            item="item"
            day="day"
            track-today-popover-placement="data.trackTodayPopoverPlacement"
        ></tnk-track-week-view-item>
    </li>
</ul>
