interface OneDriveProjectDatas {
    pathToFolder: { id: string; name: string }[];
    externalDriveId?: string;
    driveType?: OneDriveDriveType;
}

export default OneDriveProjectDatas;

export enum OneDriveDriveType {
    ROOT = 'ROOT',
    SHARED = 'SHARED',
}
