import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const RequiredSign = styled.span`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.error};
`;

const SQLDatabaseRequiredAsterisk: React.FC = () => {
    return (
        <Tooltip content="Required Field">
            <RequiredSign>*</RequiredSign>
        </Tooltip>
    );
};

export default SQLDatabaseRequiredAsterisk;
