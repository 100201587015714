<div class="margin-left" ng-if="!localStorage.hideWasntActivated || data.state !== data.states.WASNT_ACTIVATED">
    <div
        class="flex-vmiddle padding-normal-xs common-border-bottom common-selectable-grey pointer"
        ng-click="data.collapsed = !data.collapsed"
    >
        <span class="margin-right capital-first-letter">{{ data.logic.node.displayName }}</span>

        <span
            ng-if="data.logic.node.customTriggerType"
            class="margin-right common-italic common-color-light-grey common-size-xxxxs"
        >
            ({{ logicBlockTypes[data.logic.node.customTriggerType].title }})
        </span>

        <span class="flex-grow"></span>

        <!-- Has Error but no message provided -->
        <div
            class="common-color-danger common-size-xxxxs margin-right-xs"
            ng-if="data.showInspect && !data.friendlyError && data.state.label === 'HAS ERROR'"
        >
            Error message not provided
        </div>

        <!-- Has Error with message-->
        <button
            ng-if="data.showInspect && data.friendlyError"
            class="btn btn-primary mod-inverse btn-xs margin-right common-size-xxxxs"
            ng-click="onInspectErrorClicked()"
        >
            Inspect Error
        </button>

        <!-- Inspect button -->
        <button
            class="btn btn-primary mod-inverse btn-xs margin-right common-size-xxxxs"
            ng-if="
                data.runLogicInstance ||
                (data.logic && data.logic.node && data.logic.node.customTriggerType === 'AUTONOMOUS')
            "
            ng-click="onInspectRequestClicked(data.workerRun.initiative)"
        >
            Inspect
        </button>

        <span ng-if="data.state" class="margin-right-xs common-size-xxxxs flex" ng-class="data.state.classes">
            <div ng-if="data.state.iconClass" class="logic-template-icon mod-xxs" ng-class="data.state.iconClass"></div>
            <span>{{ data.state.label }}</span>
        </span>

        <!-- Collapsed indicator-->
        <span
            ng-if="
                data.logic.impacts &&
                data.logic.impacts.length &&
                (!localStorage.hideWasntActivated || data.hasActivatedChildren)
            "
            class="margin-right-xs"
            ng-class="data.collapsed ? 'chevron-right' : 'chevron-bottom'"
        ></span>
    </div>
    <ul class="">
        <li
            ng-repeat="innerLogic in data.logic.impacts | orderBy:'-startTime'"
            ng-if="
                !data.collapsed && (!localStorage.hideWasntActivated || data.activatedInnerLogicMap[innerLogic.node.id])
            "
        >
            <tnk-worker-run-logic
                logic="innerLogic"
                worker-run="data.workerRun"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                force-expand-map="data.forceExpandMap"
                selected-run-logics-map="data.selectedRunLogicsMap"
                collapsed="true"
                show-inspect="data.showInspect"
                on-inspect="
                    data.onInspect({ logicBlock: logicBlock, runLogicInfo: runLogicInfo, initiative: initiative })
                "
            ></tnk-worker-run-logic>
        </li>
    </ul>

    <!--<ul ng-if="data.workingOnContextType === 'TRIGGERED_ACTION_ITEM'">-->
    <!--<li ng-repeat="relatedInitiative in data.runLogicInstance.initiative.relatedInitiatives">-->
    <!--<div class="flex padding-normal-xs common-border-bottom common-selectable-grey pointer">-->
    <!--<span class="flex-grow">{{relatedInitiative.title}}</span>-->
    <!--<button class="btn btn-primary mod-inverse btn-xs margin-right common-size-xxxxs"-->
    <!--ng-click="onInspectRequestClicked(relatedInitiative)">-->
    <!--Inspect-->
    <!--</button>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ul>-->
</div>
