<div class="common-size-xxs">
    <!-- Selecting entity to count -->
    <div class="margin-bottom-xs">
        <!-- Title -->
        <span ng-if="data.isWorkerMode" class="common-bold margin-right-xs">
            {{
                data.targetType !== 'GLOBAL'
                    ? 'For each ' +
                      data.projectIntegration.displayName +
                      ' ' +
                      scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity +
                      ', aggregate '
                    : 'Aggregate '
            }}
        </span>
        <span class="common-bold margin-right common-break-long">
            <span ng-if="!data.isWorkerMode">
                {{ data.targetType !== 'GLOBAL' ? 'For each item, aggregate ' : 'Aggregate ' }}
            </span>
            {{ data.projectIntegration.displayName }} items from:
        </span>

        <!-- Entity selector for only Count aggregation type -->
        <tnk-entity-selector
            class="entity-selector-no-min-width inline-block"
            project-integration="data.projectIntegration"
            selected-entity="{{ data.aggregatedEntity.entity || data.aggregatedEntity }}"
            ng-class="{ 'selector-value-selected': data.aggregatedEntity }"
            selected-entity-display-name="{{ data.aggregatedEntity.pluralLabel }}"
            mode="fields"
            on-entity-selected="selectAggregateEntityOption(selectedEntity, isInit)"
            is-required="true"
        ></tnk-entity-selector>
    </div>

    <!-- Selecting entity to match track title to -->
    <div class="flex flex-vmiddle flex-wrap" ng-if="data.aggregatedEntity && data.targetType !== 'GLOBAL'">
        <div class="flex-vmiddle margin-bottom-xs">
            <!-- Choosing match type -->
            <span ng-if="data.isWorkerMode" class="common-bold flex-no-shrink margin-right">
                {{
                    data.duplicateOrCreateMode
                        ? 'Where the ' +
                          data.projectIntegration.displayName +
                          ' ' +
                          scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity
                        : data.projectIntegration.displayName +
                          ' ' +
                          scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity
                }}
            </span>
            <span ng-if="!data.isWorkerMode" class="common-bold flex-no-shrink margin-right">
                {{ data.duplicateOrCreateMode ? 'Where the item' : 'Item' }}
            </span>

            <!-- the field we would match with -->
            <tnk-field-selector
                ng-class="{ 'selector-value-selected': data.fieldToMatch }"
                items-source="COLUMN"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                special-fields-for-features="['MATCH_TRACK_FIELD']"
                selected-field="data.fieldToMatch"
                display-integration-indication="true"
                on-field-selected="onTrackMatchFieldSelected(selectedField, dontSaveChanges)"
            ></tnk-field-selector>
        </div>

        <div class="flex-vmiddle margin-bottom-xs">
            <span ng-if="data.isWorkerMode" class="common-bold margin-right">
                <span class="margin-left">matches</span>
                the
                {{
                    data.projectIntegration.displayName + ' ' + data.aggregatedEntity.label
                        ? data.aggregatedEntity.label
                        : data.aggregatedEntity
                }}
                field:
            </span>
            <span ng-if="!data.isWorkerMode" class="common-bold margin-right">
                <span class="margin-left">matches</span>
                the field:
            </span>

            <!-- Selecting the field the title should match to -->
            <tnk-field-selector
                selected-field="data.matchTrackField"
                ng-class="{ 'selector-value-selected': data.matchTrackField }"
                items-source="EXTERNAL"
                project-integration="data.projectIntegration"
                external-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                on-field-selected="onFieldOptionSelected(selectedField, dontSaveChanges)"
                is-required="true"
                place-holder="Select a field"
                field-inspect-modal="true"
                field-inspect-auto-open="false"
                workflow-version-id="{{ data.workflowVersionId }}"
            ></tnk-field-selector>
        </div>
    </div>

    <div
        ng-if="data.aggregatedEntity && (data.matchTrackField || data.targetType === 'GLOBAL')"
        class="flex-vmiddle flex-xs-wrap"
    >
        <strong class="margin-right margin-bottom-xs flex-no-shrink">And display the</strong>

        <!-- Aggregation type selection -->
        <ui-select
            data-automation="integration-aggregate-field-open-aggregation-type-drop-down"
            class="margin-right"
            ng-model="data.aggregationType"
            ng-change="aggregationTypeSelected()"
            theme="bootstrap"
        >
            <ui-select-match placeholder="Aggregation type">{{ data.aggregationType.displayName }}</ui-select-match>
            <ui-select-choices repeat="aggregationType in data.aggregationTypes | filter:$select.search ">
                <div
                    data-automation="integration-aggregate-field-aggregation-type-option"
                    ng-bind-html="aggregationType.displayName | highlight: $select.search"
                ></div>
            </ui-select-choices>
        </ui-select>

        <!-- Requires field -->
        <div class="flex-vmiddle margin-bottom-xs" ng-if="data.aggregationType && data.aggregationType.requiresField">
            <span class="margin-right">of the field:</span>

            <!-- Selecting the field the title should match to -->
            <tnk-field-selector
                class="margin-right"
                selected-field="data.aggregatedField"
                ng-class="{ 'selector-value-selected': data.aggregatedField }"
                items-source="EXTERNAL"
                project-integration="data.projectIntegration"
                external-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                on-field-selected="selectAggregatedField(selectedField, dontSaveChanges)"
                is-required="true"
                place-holder="Select a field"
                field-inspect-modal="true"
                field-inspect-auto-open="false"
                workflow-version-id="{{ data.workflowVersionId }}"
            ></tnk-field-selector>
        </div>

        <!-- Count -->
        <strong
            class="margin-right margin-bottom-xs"
            ng-if="data.aggregationType && data.aggregationType.id === 'Count'"
        >
            of matched {{ data.aggregatedEntity.pluralLabel || 'items' }}
        </strong>

        <!-- Formula sentence - 2 secondary aggregations -->
        <div
            class="margin-right margin-bottom-xs flex-vmiddle"
            ng-if="
                data.aggregationType &&
                data.aggregationType.requiresSecondaryAggregation &&
                data.aggregationType.id !== 'Formula'
            "
        >
            <span class="flex-no-shrink margin-right-xs common-bold">of</span>
            <input
                class="form-control common-input-only-line common-width-30 margin-right-xs"
                type="text"
                ng-model="data.secondaryAggregations[0].fieldTitle"
                ng-change="secondaryAggregationFieldTitleChanged()"
                placeholder="Enter..."
            />
            <span class="flex-no-shrink margin-right-xs common-bold">
                {{ data.aggregatedEntity.pluralLabel || 'items' }} {{ data.aggregationType.connectionLabel }}
            </span>
            <input
                class="form-control common-input-only-line common-width-30 margin-right-xs"
                type="text"
                ng-model="data.secondaryAggregations[1].fieldTitle"
                ng-change="secondaryAggregationFieldTitleChanged()"
            />
            <span class="common-bold">{{ data.aggregatedEntity.pluralLabel || 'items' }}</span>
        </div>

        <!-- Formula sentence - more than two secondary aggregations -->
        <div
            class="margin-right margin-bottom-xs flex-vmiddle"
            ng-if="
                data.aggregationType &&
                data.aggregationType.requiresSecondaryAggregation &&
                data.aggregationType.id === 'Formula'
            "
        >
            <span class="margin-right">-</span>

            <!-- Formula expression input -->
            <input
                class="form-control margin-right-xs"
                type="text"
                ng-model="data.formulaExpression"
                ng-change="formulaExpressionChanged(data.formulaExpression)"
            />

            <!-- Loading -->
            <i class="loading-small" ng-if="data.validatingFormulaExpression"></i>
        </div>
    </div>

    <!-- Reduce function selection -->
    <div class="margin-top" ng-if="data.aggregationType.id === 'Reduce'">
        <div class="flex-vmiddle">
            <!-- Title -->
            <div class="margin-right common-bold">Reduce function:</div>

            <!-- Reduce function selection -->
            <ui-select
                class="margin-right"
                ng-model="data.reduceFunction"
                ng-change="reduceFunctionSelected()"
                theme="bootstrap"
            >
                <ui-select-match placeholder="Reduce function">{{ data.reduceFunction.displayName }}</ui-select-match>
                <ui-select-choices repeat="reduceFunction in data.reduceFunctions | filter:$select.search ">
                    <div ng-bind-html="reduceFunction.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Concat configuration -->
        <div ng-if="data.reduceFunction.apiName === 'Concat'" class="flex-vmiddle margin-top">
            <!-- Title -->
            <div class="margin-right common-bold flex-no-shrink">Separator (defaults to a comma):</div>

            <!-- Separator selection -->
            <input
                type="text"
                class="form-control"
                ng-model="data.concatenationSeparator"
                maxlength="255"
                placeholder="Enter concatenation separator..."
                ng-change="onConcatenationSeparatorChanged()"
                data-automation="integration-aggregate-field-separator"
            />
        </div>
    </div>

    <!-- Grouped by function selection -->
    <div class="margin-top" ng-if="data.aggregationType.id === 'Terms'">
        <div class="flex-vmiddle">
            <!-- Title -->
            <div class="margin-right common-bold">Show the</div>

            <!-- group by function selection -->
            <ui-select
                class="margin-right"
                ng-model="data.groupByFunction"
                ng-change="groupByFunctionSelected()"
                theme="bootstrap"
            >
                <ui-select-match placeholder="Group by function">
                    {{ data.groupByFunction.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="groupByFunction in data.groupByFunctions | filter:$select.search ">
                    <div ng-bind-html="groupByFunction.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <div ng-if="data.groupByFunction.apiName === 'Count'" class="flex-vmiddle">
                <div class="margin-right common-bold flex-no-shrink">of items per group</div>
            </div>

            <div ng-if="data.groupByFunction && data.groupByFunction.apiName !== 'Count'" class="flex-vmiddle">
                <!-- Title -->
                <div class="margin-right common-bold flex-no-shrink">of</div>

                <tnk-field-selector
                    class="margin-right"
                    selected-field="data.groupByAggregateField"
                    ng-class="{ 'selector-value-selected': data.groupByAggregateField }"
                    items-source="EXTERNAL"
                    project-integration="data.projectIntegration"
                    external-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                    on-field-selected="selectGroupByAggregateField(selectedField, dontSaveChanges)"
                    is-required="true"
                    place-holder="Select a field"
                    field-inspect-modal="true"
                    field-inspect-auto-open="false"
                    workflow-version-id="{{ data.workflowVersionId }}"
                ></tnk-field-selector>

                <div class="margin-right common-bold flex-no-shrink">per group</div>
            </div>
        </div>
    </div>

    <!-- Error -->
    <div
        class="margin-top-xs common-color-danger common-size-xxxs"
        ng-if="!data.validatingFormulaExpression && data.validationResult && data.validationResult.errorMessage"
    >
        {{ data.validationResult.errorMessage }}
    </div>

    <!-- Formula documentation link -->
    <div class="common-size-xxxs common-color-grey margin-top" ng-if="data.aggregationType.id === 'Formula'">
        <span>See all options and functions in</span>
        <a ng-href="{{::$root.knowledgeBase.pages.formulas}}" target="_blank">the formulas documentation.</a>
    </div>

    <!-- Selecting condition for the whole query -->
    <!-- Separator -->
    <hr
        ng-if="
            (!data.aggregationType.requiresSecondaryAggregation || data.anyNotHiddenSecondaryAggregations) &&
            data.aggregationType &&
            ((data.matchTrackField && data.matchTrackField.name) ||
                (data.targetType === 'GLOBAL' && (data.aggregatedField || data.aggregatedEntity)))
        "
    />

    <!-- Requires secondary aggregations -->
    <div
        ng-show="data.aggregationType.requiresSecondaryAggregation"
        ng-repeat="secondaryAggregation in data.secondaryAggregations | filter:{'hidden' : false}"
        class="margin-top"
    >
        <!-- Title -->
        <div class="margin-bottom common-bold common-size-xxs common-underline">
            {{ secondaryAggregation.fieldTitle }}
            {{ data.aggregatedEntity.pluralLabel || data.aggregatedEntity || 'items' }}
        </div>

        <!-- Aggregation selection -->
        <div class="common-bold flex-vmiddle margin-bottom">
            <!-- Aggregation type selection -->
            <ui-select
                class="margin-right"
                ng-model="secondaryAggregation.aggregationType"
                ng-change="onSecondaryAggregationTypeSelected(secondaryAggregation)"
                theme="bootstrap"
            >
                <ui-select-match placeholder="Aggregation type">
                    {{ secondaryAggregation.aggregationType.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="aggregationType in data.singleAggregationTypes | filter:$select.search ">
                    <div ng-bind-html="aggregationType.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <!-- Does not require fields -->
            <div ng-if="!secondaryAggregation.aggregationType.requiresField">
                {{ data.aggregatedEntity.pluralLabel || data.aggregatedEntity || 'items' }} who meet the conditions:
            </div>

            <!-- Requires field -->
            <div ng-if="secondaryAggregation.aggregationType.requiresField" class="flex-vmiddle">
                <!-- Of-->
                <span class="margin-right">of</span>

                <!-- Selecting the field the title should match to -->
                <tnk-field-selector
                    class="margin-right"
                    selected-field="secondaryAggregation.aggregatedField"
                    selected-field-identifier="secondaryAggregation"
                    ng-class="{ 'selector-value-selected': secondaryAggregation.aggregatedField }"
                    project-integration="data.projectIntegration"
                    items-source="EXTERNAL"
                    external-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                    on-field-selected="
                        selectSecondaryAggregationAggregatedField(
                            selectedField,
                            selectedFieldIdentifier,
                            dontSaveChanges
                        )
                    "
                    is-required="true"
                    place-holder="Select a field"
                    field-inspect-modal="true"
                    field-inspect-auto-open="false"
                    workflow-version-id="{{ data.workflowVersionId }}"
                ></tnk-field-selector>

                <span>
                    For {{ data.aggregatedEntity.pluralLabel || data.aggregatedEntity || 'items' }} who meet the
                    conditions:
                </span>
            </div>
        </div>

        <!-- If we do not want to show conditions on the start -->
        <div ng-if="!secondaryAggregation.showConditions">
            <a ng-click="displaySecondaryAggregationConditions(secondaryAggregation)">+ Add filter</a>
        </div>
        <!-- Displaying conditions-->
        <div ng-show="secondaryAggregation.showConditions">
            <tnk-custom-filters
                control="secondaryAggregation.customFiltersControl"
                items-source="EXTERNAL"
                existing-definition="data.createMode ? null : secondaryAggregation"
                project-integration="data.projectIntegration"
                selected-entity-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                edit-mode="false"
                hide-time-range-selection="true"
                on-filters-change="onSecondaryAggregationCustomFiltersChanged()"
                show-field-inspect="true"
            ></tnk-custom-filters>
        </div>

        <!-- Separator -->
        <hr ng-if="!$last" />
    </div>

    <!-- Not requires secondary aggregations -->
    <div
        ng-if="
            !data.aggregationType.requiresSecondaryAggregation &&
            data.aggregationType &&
            ((data.matchTrackField && data.matchTrackField.name) ||
                (data.targetType === 'GLOBAL' && (data.aggregatedField || data.aggregatedEntity)))
        "
    >
        <!-- Title for aggregation types that do not require secondary aggregation -->
        <div class="common-bold">
            Only include {{ data.aggregatedEntity.pluralLabel || data.aggregatedEntity || 'items' }} where:
        </div>

        <!-- If we do not want to show conditions on the start -->
        <div ng-if="!data.showConditions">
            <a ng-click="displayConditions()">+ Add filter</a>
        </div>
        <!-- Displaying conditions-->
        <div ng-show="data.showConditions">
            <tnk-custom-filters
                control="data.customFiltersControl"
                items-source="EXTERNAL"
                existing-definition="data.createMode ? null : data.existingFieldDefinition.definition"
                project-integration="data.projectIntegration"
                selected-entity-type="data.aggregatedEntity.pluralLabel || data.aggregatedEntity"
                edit-mode="false"
                hide-time-range-selection="true"
                on-filters-change="onCustomFiltersChanged(shouldNotSaveLogic)"
                show-field-inspect="true"
                force-expand="data.isWorkflowVersionPublished"
                not-allowed-conditions-set="data.notAllowedConditionsSet"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                use-expressions-for-value="data.useExpressionsForValue"
            ></tnk-custom-filters>
        </div>

        <hr />

        <div ng-if="data.aggregationType.id === 'Reduce' || data.aggregationType.id === 'First'">
            <tnk-order-by-field
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                project-integration="data.projectIntegration"
                entity-metadata="data.aggregatedEntity"
                on-sort-by-field-changed="onSortByFieldChanged(sortByFieldObject, shouldSave)"
                saved-match-option-selection="data.existingFieldDefinition.definition.orderByFieldConfiguration"
            ></tnk-order-by-field>
        </div>
    </div>
</div>
