<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Upload File From Url -->
        <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE_FROM_URL'">
            <!-- Url -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Url of File:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The url to upload..."
                    saved-expression="data.definition.urlExpression"
                    expression-unique-identifier="AmazonS3FileUrl"
                    on-tonkean-expression-changed="onUrlExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div ng-if="data.validationObject.noUrl" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noUrl }}
                </div>
            </div>

            <!-- Bucket -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Bucket Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The bucket to create the file in..."
                    saved-expression="data.definition.destinationFolderIdExpresssion"
                    expression-unique-identifier="AmazonS3Bucket"
                    on-tonkean-expression-changed="onDestinationFolderExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- New File Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">New File Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The new file name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.fileNameExpresssion"
                    expression-unique-identifier="AmazonS3FileName"
                    on-tonkean-expression-changed="onFileNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    ng-if="data.validationObject.noFileName"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noFileName }}
                </div>
            </div>
        </div>

        <!-- Upload File From Storage Provider or Url -->
        <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
            <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                preview-evaluation-source="data.previewEvaluationSource"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                parameters-values="data.definition.parametersValues"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                on-worker-file-selection-changed="
                    onWorkerFileSelectionChanged(
                        fileSourceType,
                        storageIntegrationId,
                        extendedMatchConfiguration,
                        urlExpression,
                        workerStaticAssetId,
                        workerStaticAssetDisplayName,
                        parametersValues,
                        shouldSaveLogic,
                        storageIntegrationActionId
                    )
                "
            ></tnk-worker-file-selection>

            <div
                ng-if="data.validationObject.invalidFileChoice"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.invalidFileChoice }}
            </div>

            <!-- Bucket -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Bucket Name:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Required in case not using a restricted (bucket) connection."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The bucket to create the file in..."
                    saved-expression="data.definition.destinationFolderIdExpresssion"
                    expression-unique-identifier="AmazonS3Bucket"
                    saved-original-expression="data.definition.bucketNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.bucketNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onBucketNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- New Document Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    New Document Name:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original name will be used."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Document name"
                    saved-original-expression="data.definition.newFileNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.newFileNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onNewFileNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Generating a signed URL -->
        <div ng-if="data.definition.customActionKey === 'GENERATE_SIGNED_URL'" class="margin-bottom-xlg">

            <!-- Bucket -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Bucket Name:
                    <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="Required in case not using a restricted (bucket) connection."
                    ></i>
                </div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.destinationFolderIdExpresssion"
                        expression-unique-identifier="AmazonS3Bucket"
                        saved-original-expression="data.definition.bucketNameExpression.originalExpression"
                        saved-evaluated-expression="data.definition.bucketNameExpression.evaluatedExpression"
                        on-tonkean-expression-changed="
                        onBucketNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- Object Key -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    File Key:
                </div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="File Key"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.fileKeyExpression"
                        on-tonkean-expression-changed="onFileKeyExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Expiration Duration -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Expires In (minutes):
                </div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Expires In"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.expirationDurationExpression"
                        on-tonkean-expression-changed="onExpirationDurationExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
