import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import CreateDataSourceConnectionModal from './CreateDataSourceConnectionModal';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const AddConnectionButton = styled(Button)`
    margin-left: 15px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-left: 8px;
`;

const AddDataSourceConnectionButton: React.FC = () => {
    const project = useProject();
    const $state = useAngularService('$state');

    const [isCreateConnectionModalOpen, setIsCreateConnectionModalOpen] = useState(false);

    const {
        data: dataSourceConnections,
        error: errorDataSourceConnections,
        loading: isLoadingDataSourceConnections,
    } = useTonkeanService('getDataSourceConnections', project.id);

    // Get all integration types that have a data source connection
    const existingDataSourceConnectionTypes = useMemo(() => {
        return (
            dataSourceConnections?.entities.map(
                (dataSourceConnection) => IntegrationType[dataSourceConnection.dataSourceType],
            ) || []
        );
    }, [dataSourceConnections]);

    const onDataSourceConnectionCreated = (dataSourceConnection) => {
        setIsCreateConnectionModalOpen(false);
        $state.go(
            'product.dataSourceConnection',
            { dataSourceConnectionId: dataSourceConnection.id },
            { inherit: true },
        );
    };

    return (
        <>
            <AddConnectionButton
                data-automation="add-data-source-connection-button-add-rule"
                onClick={() => setIsCreateConnectionModalOpen(true)}
                size={ButtonSize.SMALL}
                disabled={isLoadingDataSourceConnections || !!errorDataSourceConnections}
                outlined
            >
                + Add Rule
            </AddConnectionButton>

            {!!errorDataSourceConnections && (
                <StyledErrorMessage>There was an error getting the available integrations</StyledErrorMessage>
            )}

            {isCreateConnectionModalOpen && (
                <CreateDataSourceConnectionModal
                    existingDataSourceConnectionTypes={existingDataSourceConnectionTypes}
                    isOpen={isCreateConnectionModalOpen}
                    onDismiss={() => {
                        setIsCreateConnectionModalOpen(false);
                    }}
                    onCreate={onDataSourceConnectionCreated}
                />
            )}
        </>
    );
};

export default AddDataSourceConnectionButton;
