import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class IsEmptyFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.IS_EMPTY;
    override readonly sign = 'IsEmpty';
    override readonly displayName = 'Is Empty';
    override readonly description = 'Checks if the field has a value';
    override readonly dataType = FieldType.Boolean;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input String',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
