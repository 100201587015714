import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import type { FormikProps } from 'formik/dist/types';
import React from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import type { RequiredDateSchema } from 'yup/lib/date';

import type WorkerRunDateRangeFilter from '../../../entities/WorkerRunDateRangeFilter';

import type { DatepickerWidth } from '@tonkean/infrastructure';
import { FilterDateRange, FormikAutosave } from '@tonkean/infrastructure';

const DateRangeWrapper = styled(Form)<{ $alignLeft?: boolean }>`
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;

    ${({ $alignLeft }) =>
        $alignLeft &&
        css`
            justify-content: left;
        `};
`;

const ValidationSchema = Yup.object({
    dateFrom: Yup.date().when(
        'dateTo',
        (dateTo: Date | undefined, schema: RequiredDateSchema<Date | undefined, Record<string, any>>) =>
            schema
                .required('Start date is required')
                .nullable()
                .max(dayjs(dateTo || undefined).toDate(), 'Invalid range')
                .min(
                    dayjs(dateTo || undefined)
                        .subtract(6, 'month')
                        .set('minutes', 0)
                        .toDate(),
                    'Maximum range is 6 months',
                ),
    ),
    dateTo: Yup.date().nullable().default(undefined),
});

interface Props {
    initialRange: WorkerRunDateRangeFilter;
    onChange: (workerRunDateRangeFilter: WorkerRunDateRangeFilter) => void;
    innerRef: React.RefObject<FormikProps<any>>;
    width?: DatepickerWidth;
    alignLeft?: boolean;
}

const WorkerRunsFilterDateRange: React.FC<Props> = ({ onChange, initialRange, innerRef, width, alignLeft = false }) => {
    const $state = useAngularService('$state');

    return (
        <>
            <Formik
                validationSchema={ValidationSchema}
                initialValues={initialRange}
                innerRef={innerRef}
                onSubmit={(values) => {
                    onChange(values);
                    $state.go(
                        '.',
                        {
                            ...$state.params,
                            from: values.dateFrom ? dayjs(values.dateFrom).unix().toString() : undefined,
                            to: values.dateTo ? dayjs(values.dateTo).unix().toString() : undefined,
                        },
                        { reload: false, notify: false },
                    );
                    return Promise.resolve();
                }}
            >
                <>
                    <FormikAutosave />

                    <DateRangeWrapper $alignLeft={alignLeft}>
                        <FilterDateRange
                            fromName="dateFrom"
                            toName="dateTo"
                            toPlaceholder="Now"
                            width={width}
                            showTimeSelect
                            horizontalLayout
                        />
                    </DateRangeWrapper>
                </>
            </Formik>
        </>
    );
};

export default WorkerRunsFilterDateRange;
