import externalPages from '../config/externalNavigationPagesConfiguration';
import internalPages from '../config/internalNavigationPagesConfiguration';
import type NavigablePage from '../entities/NavigablePage';

const navigationPagesConfiguration: NavigablePage[] = [...internalPages, ...externalPages];

export default navigationPagesConfiguration;

const isInsideState = (parentState: string | undefined, childState: string) => {
    return parentState && (parentState === childState || childState.startsWith(`${parentState}.`));
};

export const getPageByState = (state: string | undefined): NavigablePage | undefined => {
    if (state) {
        return navigationPagesConfiguration.find(
            (page) =>
                isInsideState(page.state, state) ||
                page.secondaryStates?.some((secondaryState) => isInsideState(secondaryState, state)),
        );
    }
};
