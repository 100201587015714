import React from 'react';

import type { EntitySelectorProps } from './EntitySelectorProps';
import TnkEntitySelector from './TnkEntitySelector';

import { useInnerField } from '@tonkean/infrastructure';

interface Props extends Omit<EntitySelectorProps, 'selectedEntity' | 'onEntitySelected' | 'useReactSelect' | 'mode'> {
    onSelected?: (entityMetadata: any) => void;
}

const FormikEntitySelector: React.FC<Props> = ({ name, onSelected, ...props }) => {
    const [fieldProps, hasError, formikFieldHelpers] = useInnerField({
        name,
        type: 'select',
        multiple: false,
    });
    return (
        <TnkEntitySelector
            {...props}
            selectedEntity={fieldProps.value}
            onEntitySelected={(value) => {
                formikFieldHelpers?.setValue(value.entity);
                onSelected?.(value);
            }}
            mode="sync"
            showAsError={hasError}
            useReactSelect
        />
    );
};

export default FormikEntitySelector;
