import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SendNotificationLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {};

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (actionDefinition, shouldSaveLogic) {
        return ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'SendNotificationLogicConfigurationCtrl',
        lateConstructController(SendNotificationLogicConfigurationCtrl),
    );
