import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationCollectGenericCollectTimeLabel from './ProjectIntegrationCollectStatusGenericCollectTimeLabel';
import ProjectIntegrationCollectStatusLastCollectFailedUpdate from './ProjectIntegrationCollectStatusLastCollectFailedUpdate';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_500};
    margin-right: 4px;
`;
const LabelWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

interface Props {
    updated: number | null;
    lastUpdatedTime: Date;
    error?: string;
}

const ProjectIntegrationCollectStatusLastCollectTime: React.FC<Props> = ({
    updated,
    lastUpdatedTime,
    error = null,
}) => {
    return (
        <LabelWrapper>
            <Label data-automation="project-integration-collect-status-last-collect-time">
                <ProjectIntegrationCollectGenericCollectTimeLabel
                    dateTime={updated}
                    lastUpdatedTime={lastUpdatedTime}
                    label="Last collect time"
                />
            </Label>
            {error ? <ProjectIntegrationCollectStatusLastCollectFailedUpdate error={error} /> : <></>}
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusLastCollectTime;
