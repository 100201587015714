import { useAngularService } from 'angulareact';
import { useCallback, useState } from 'react';

const useLazyFetch = () => {
    const authenticationService = useAngularService('authenticationService');
    const environment = useAngularService('environment');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const accessToken = authenticationService.getCurrentUser().accessToken;
    const projectAccessToken = authenticationService.getProjectToken();
    const fetchData = useCallback(
        async (path, method = 'POST', body: any = null) => {
            setLoading(true);
            try {
                const fetchOptions = {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: '',
                };

                if (projectAccessToken) {
                    fetchOptions.headers['Authorization-Project'] = `Bearer ${projectAccessToken}`;
                }
                if (body) {
                    fetchOptions.body = JSON.stringify(body);
                }

                const response: any = await fetch(environment.apiUrl + path, fetchOptions);
                if (!response.ok) {
                    throw new Error(`Error fetching data: ${response.statusText}`);
                }
                setData(response);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        },
        [accessToken, projectAccessToken, environment],
    );

    return { data, error, loading, fetchData };
};

export default useLazyFetch;
