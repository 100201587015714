import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import FieldChart from './FieldChart';
import type { FieldChartIntervalOption } from './FieldChartIntervalOption';
import IntervalSelector from './IntervalSelector';

import { ChartDisplayType, LineChart } from '@tonkean/infrastructure';
import type { FieldInstance, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const IntervalSelectorContainer = styled.div`
    display: block;
    height: 30px;
`;

const FieldNameContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FieldColorBox = styled.span`
    background: ${Theme.colors.primary};
    border-width: 2px;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
`;

const FieldName = styled.span`
    color: ${Theme.colors.gray_500};
`;

const intervalOptions: FieldChartIntervalOption[] = [
    {
        label: '14 days',
        value: 14,
    },
    {
        label: '30 days',
        value: 30,
    },
    {
        label: '90 days',
        value: 90,
    },
    {
        label: '180 days',
        value: 180,
    },
    {
        label: '365 days',
        value: 365,
    },
];

interface Props {
    fieldInstance: FieldInstance | undefined;
    chartInModal?: boolean;
    showStatusOverlay?: boolean;
    fieldName: string;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    configuredChartType?: ChartDisplayType;
}
const rangeTo = new Date();

const HistoricalFieldChartComponent: React.FC<Props> = ({
    fieldInstance,
    chartInModal,
    showStatusOverlay = false,
    fieldName,
    initiativeId,
    configuredChartType = ChartDisplayType.STEP,
}) => {
    const [selectedInterval, setSelectedInterval] = useState<FieldChartIntervalOption>({
        label: '30 days',
        value: 30,
    });
    const rangeFrom = useMemo(() => {
        const from = new Date(rangeTo);
        from.setDate(from.getDate() - selectedInterval.value);
        return from;
    }, [selectedInterval]);

    return (
        <>
            {chartInModal ? (
                <IntervalSelectorContainer>
                    <IntervalSelector
                        selectedInterval={selectedInterval}
                        onIntervalChanged={setSelectedInterval}
                        intervalOptions={intervalOptions}
                    />
                </IntervalSelectorContainer>
            ) : (
                <TopContainer>
                    <FieldNameContainer>
                        <FieldColorBox />
                        <FieldWrapper>
                            <FieldName>{fieldName}</FieldName>
                        </FieldWrapper>
                    </FieldNameContainer>

                    <IntervalSelectorContainer>
                        <IntervalSelector
                            selectedInterval={selectedInterval}
                            onIntervalChanged={setSelectedInterval}
                            intervalOptions={intervalOptions}
                        />
                    </IntervalSelectorContainer>
                </TopContainer>
            )}
            {fieldInstance ? (
                <FieldChart
                    field={fieldInstance}
                    fieldName={fieldName}
                    chartInModal={chartInModal}
                    showStatusOverlay={showStatusOverlay}
                    rangeFrom={rangeFrom}
                    rangeTo={rangeTo}
                    initiativeId={initiativeId}
                    configuredChartType={configuredChartType}
                />
            ) : (
                <LineChart data={undefined} chartType={configuredChartType} />
            )}
        </>
    );
};

export default HistoricalFieldChartComponent;
