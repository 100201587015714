import { lighten } from 'polished';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import SearchItemImage from './SearchItemImage';
import SearchItemStreamImage from './SearchItemStreamImage';
import SearchWidgetCardsDensityConfigurations from './SearchWidgetCardsDensityConfigurations';
import SearchWidgetResultItemEditableField from './SearchWidgetResultItemEditableField';
import ImageInputType from '../../entities/ImageInputType';
import type {
    SearchWidgetEditableResultFieldConfiguration,
    SearchWidgetImagePlaceholderMode,
} from '../SearchWidgetConfiguration';
import { SearchWidgetCardsDensity } from '../SearchWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { Breakpoint, Checkbox, H3, LoadingCircle, Paragraph, TextEllipsis } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { SearchWidgetResponseItem } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import { ButtonShape } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { Color } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

/**
 * The click zone for this element spans the entire size of the element.
 * Other elements can specify being not a part of it by being higher in z-index.
 * This solves the problem where texts should be selectedable while the rest of the element should be clickable
 */
const CLICK_ZONE_ZINDEX = 1;
const ABOVE_CLICK_ZONE_ZINDEX = CLICK_ZONE_ZINDEX + 1;
const ClickZone = styled(Clickable)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: ${CLICK_ZONE_ZINDEX};
`;
export const SearchWidgetResultItemBox = styled.div<{
    $selected: boolean;
    $selectedColor: Color;
    $gap: number;
    $cardsDensity: SearchWidgetCardsDensity;
}>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background: ${Theme.colors.basicBackground};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    ${({ $selected, $selectedColor }) =>
        $selected &&
        css`
            border-color: ${$selectedColor};
        `};

    ${({ $cardsDensity }) =>
        $cardsDensity === SearchWidgetCardsDensity.SPACED
            ? css`
                  border-radius: 8px;
              `
            : css`
                  &:first-of-type {
                      border-top-left-radius: 8px;
                      border-top-right-radius: 8px;
                  }

                  &:last-of-type {
                      border-bottom-left-radius: 8px;
                      border-bottom-right-radius: 8px;
                  }
              `};

    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        flex-direction: column;
    }

    transition: 150ms ease-out;
    transition-property: border-color, box-shadow;

    &:hover {
        border: 1px solid
            ${({ $selected, $selectedColor }) => ($selected ? lighten(0.05, $selectedColor) : Theme.colors.gray_400)};
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    }
`;

const TextContainer = styled.div<{
    $textsGap: number;
    $cardsDensity: SearchWidgetCardsDensity;
}>`
    z-index: ${ABOVE_CLICK_ZONE_ZINDEX};
    flex-grow: 1;

    display: flex;
    ${({ $cardsDensity }) =>
        $cardsDensity === SearchWidgetCardsDensity.SPACED
            ? css`
                  flex-direction: column;
              `
            : css`
                  flex-direction: row;
                  align-items: center;
              `};

    gap: ${({ $textsGap }) => $textsGap}px;
    height: 100%;
    width: 100%;
`;

const TitleAndDescription = styled.div`
    flex-grow: 1;
`;

const TitleLine = styled.div`
    display: flex;
    align-items: baseline;
    gap: 5px;

    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        flex-direction: column-reverse;
    }
`;

const Tag = styled.div`
    padding: 3px 8px;
    border-radius: 4px;
    background: #e49b37;
    font-size: ${FontSize.XSMALL_10};
    line-height: 13px;
    color: ${Theme.colors.white};
`;

const LastLine = styled.div<{ $cardsDensity: SearchWidgetCardsDensity }>`
    display: flex;
    align-items: center;

    ${({ $cardsDensity }) =>
        $cardsDensity === SearchWidgetCardsDensity.COMPACT &&
        css`
            flex-direction: column;
            align-items: end;
        `}

    gap: 10px;
`;

interface Props {
    item: SearchWidgetResponseItem;
    editableResultFieldConfiguration: SearchWidgetEditableResultFieldConfiguration | undefined;
    customColor: Color;
    selected: boolean;
    associatedInitiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    isMobile: boolean;
    cardsDensity: SearchWidgetCardsDensity;
    imagePlaceholderMode: SearchWidgetImagePlaceholderMode | undefined;
    imageInputType: ImageInputType | undefined;
    searchEntityProjectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION> | undefined;
    imageStreamSelectedActionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    changeItemSelectedState: (searchItem: SearchWidgetResponseItem, value: boolean) => Promise<unknown>;
}

const SearchWidgetResultItem: React.FC<Props> = ({
    item,
    editableResultFieldConfiguration,
    customColor,
    selected,
    associatedInitiativeId,
    isMobile,
    cardsDensity,
    imagePlaceholderMode,
    imageInputType,
    searchEntityProjectIntegrationId,
    imageStreamSelectedActionId,
    projectId,
    changeItemSelectedState,
}) => {
    const { workflowVersion } = useItemInterfaceContext();

    const [selectLoading, setSelectLoading] = useState(false);
    const onSelectItemChange = (value: boolean) => {
        setSelectLoading(true);
        changeItemSelectedState(item, value).finally(() => setSelectLoading(false));
    };

    const cardsDensityConfiguration = SearchWidgetCardsDensityConfigurations[cardsDensity];
    const enableStreamImageFeature = useFeatureFlag('tonkean_enable_stream_service_features');

    return (
        <SearchWidgetResultItemBox
            $selected={selected}
            $selectedColor={customColor}
            $gap={cardsDensityConfiguration.gap}
            $cardsDensity={cardsDensity}
        >
            <ClickZone onClick={!selectLoading ? () => onSelectItemChange(!selected) : undefined} />
            {!isMobile && (
                <Checkbox
                    onChange={() => onSelectItemChange(!selected)}
                    checked={selected}
                    size={InputSize.LARGE}
                    shape={ButtonShape.ROUND}
                    customColor={customColor}
                    loading={selectLoading}
                />
            )}

            {enableStreamImageFeature && imageInputType === ImageInputType.IMAGE_FROM_ACTION ? (
                <SearchItemStreamImage
                    entity={item}
                    imagePlaceholderMode={imagePlaceholderMode}
                    cardsDensityConfiguration={cardsDensityConfiguration}
                    searchEntityProjectIntegrationId={searchEntityProjectIntegrationId}
                    imageStreamSelectedActionId={imageStreamSelectedActionId}
                    projectId={projectId}
                />
            ) : (
                <SearchItemImage
                    entity={item}
                    imagePlaceholderMode={imagePlaceholderMode}
                    cardsDensityConfiguration={cardsDensityConfiguration}
                />
            )}

            <TextContainer $textsGap={cardsDensityConfiguration.textsGap} $cardsDensity={cardsDensity}>
                <TitleAndDescription>
                    <TitleLine>
                        <H3 $bold>
                            <TextEllipsis numberOfLines={cardsDensityConfiguration.maxTextLines} tooltip>
                                {item.title}
                            </TextEllipsis>
                        </H3>
                        {item.tagText && <Tag>{item.tagText}</Tag>}
                    </TitleLine>
                    {item.description && (
                        <Paragraph $light>
                            <TextEllipsis numberOfLines={cardsDensityConfiguration.maxTextLines} tooltip>
                                {item.description}
                            </TextEllipsis>
                        </Paragraph>
                    )}
                </TitleAndDescription>
                <LastLine $cardsDensity={cardsDensity}>
                    {item.secondaryProperty && <H3 $bold>{item.secondaryProperty}</H3>}
                    {workflowVersion &&
                        selected &&
                        editableResultFieldConfiguration?.fieldDefinitionId &&
                        associatedInitiativeId && (
                            <SearchWidgetResultItemEditableField
                                workflowVersion={workflowVersion}
                                fieldDefinitionId={editableResultFieldConfiguration.fieldDefinitionId}
                                associatedInitiativeId={associatedInitiativeId}
                                editableFieldLabel={editableResultFieldConfiguration.label}
                            />
                        )}
                    <div className="flex-grow" />
                    {isMobile && (
                        <>
                            {selectLoading && <LoadingCircle color={customColor} />}
                            <UserThemedClickableButton
                                background={selected ? customColor : Theme.colors.gray_100}
                                onClick={() => onSelectItemChange(!selected)}
                            >
                                {selected ? 'Deselect' : 'Select'} Item
                            </UserThemedClickableButton>
                        </>
                    )}
                </LastLine>
            </TextContainer>
        </SearchWidgetResultItemBox>
    );
};

export default SearchWidgetResultItem;
