<span ng-switch="fieldType.toUpperCase()" class="{{ size ? 'svg-icon-' + size : 'svg-icon-sm' }}">
    <span ng-switch-when="NUMBER">
        <tnk-icon src="../../../../svg/src/icons/hashtag.svg"></tnk-icon>
    </span>
    <span ng-switch-when="DATE">
        <tnk-icon src="../../../../svg/src/icons/calendar-new.svg"></tnk-icon>
    </span>
    <span ng-switch-when="LIST">
        <tnk-icon src="../../../../svg/src/icons/chevron-circle.svg"></tnk-icon>
    </span>
    <span ng-switch-when="FORMULA">
        <tnk-icon src="../../../../svg/src/icons/calculator.svg"></tnk-icon>
    </span>
    <span ng-switch-when="PEOPLE_DIRECTORY">
        <tnk-icon src="../../../../svg/src/icons/user-group.svg"></tnk-icon>
    </span>
    <span ng-switch-default>
        <tnk-icon src="../../../../svg/src/icons/text.svg"></tnk-icon>
    </span>
</span>
