<div class="margin-left padding-left-xlg">
    <div class="flex-vmiddle flex-xs-wrap">
        <button
            ng-if="!$first && !$last"
            ng-repeat="reminder in reminders"
            ng-click="onReminderClick(reminder)"
            class="btn btn-default margin-right common-no-outline margin-top"
        >
            {{ reminder.label }}
        </button>
        <div
            uib-datepicker-popup
            popup-placement="bottom-right"
            ng-model="data.customReminder"
            is-open="data.customNextReminderIsOpen"
            datepicker-options="{minDate:minDate, showWeeks:false, initDate:minDate}"
        >
            <button
                ng-click="data.customNextReminderIsOpen = !customNextReminderIsOpen"
                class="btn btn-default common-no-outline margin-top-md relative"
            >
                Pick date
            </button>
        </div>
    </div>
    <a
        ng-click="nextFollowUpStep()"
        class="inline-block common-bold common-color-link-blue margin-top-md margin-bottom padding-bottom-lg"
    >
        I don't know
    </a>
</div>
