import React from 'react';
import styled from 'styled-components';

import { BrandedLoading } from '@tonkean/infrastructure';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    text-align: center;
    margin: 56px 0;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${Theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
`;

const WaitMessgeTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: ${Theme.colors.gray_800};
    margin-top: 32px;
`;

const WaitMessgeSubTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: ${Theme.colors.gray_800};
    margin-top: 32px;
    max-width: 50%;
`;

interface IntakeWaitForConditinsPageProps {
    color: Color;
    evaluatedWaitConditionsMessage?: string;
}

const IntakeWaitForConditinsPage: React.FC<IntakeWaitForConditinsPageProps> = ({
    color,
    evaluatedWaitConditionsMessage = 'Waiting for an operation to complete...',
}) => {
    return (
        <Wrapper data-automation="tnk-fill-form-wait-page">
            <BrandedLoading color={color} />
            <WaitMessgeTitle data-automation="tnk-fill-form-wait-message-title">
                {evaluatedWaitConditionsMessage}
            </WaitMessgeTitle>
            <WaitMessgeSubTitle data-automation="tnk-fill-form-wait-message-sub-title">
                Please wait for the operation to complete. Actions related to your request are being processed and once
                it is complete you can continue to the next step.
            </WaitMessgeSubTitle>
        </Wrapper>
    );
};

export default IntakeWaitForConditinsPage;
