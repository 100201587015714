import goodTimeIcon from '../../../../apps/tracks/images/goodtime.png';
import goodTimeCircleIcon from '../../../../apps/tracks/images/integrations/goodtime-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class GoodTimeIntegration extends IntegrationDefinitionBase {
    override name = 'goodtime';
    override displayName = 'GoodTime';
    override description = 'We will collect Contacts, Candidates, Applications, Interviews, Jobs data.';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [
        new IntegrationEntity('Contact', 'Contacts'),
        new IntegrationEntity('ContactTag', 'ContactTags'),
        new IntegrationEntity('Tag', 'Tags'),
        new IntegrationEntity('TagType', 'TagTypes'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(goodTimeCircleIcon, '130px'),
        new IntegrationIcon(goodTimeIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            Contact: true,
            ContactTag: true,
            Tag: true,
            TagType: true,
        },
        isOnlyDataRetention: {},
    };
}

export default GoodTimeIntegration;
