import React, { useEffect, useState } from 'react';

import type { ImageWidgetDisplayBaseProps } from './ImageWidgetDisplayBase';
import { ImageWrapper, StyledImage } from './ImageWidgetDisplayBase';
import ImageWidgetLoadingSkeleton from './ImageWidgetLoadingSkeleton';
import { ItemWidget } from '../../../WidgetModule';

import { StateMessage } from '@tonkean/infrastructure';
import { EmptyImage } from '@tonkean/svg';

interface Props extends ImageWidgetDisplayBaseProps {}

const ImageWidgetDisplay: React.FC<Props> = ({
    isUrlExpressionValid,
    loadingExpressions,
    permission,
    altText,
    url,
    originalExpression,
}) => {
    const [imageError, setImageError] = useState<boolean>(false);

    const urlIsInvalid = !isUrlExpressionValid || imageError;

    useEffect(() => {
        setImageError(false);
    }, [url]);

    const borderlessWidgetView = loadingExpressions || !urlIsInvalid;
    return (
        <ItemWidget
            permission={permission}
            noPaddingBody={borderlessWidgetView}
            noBorderBody={borderlessWidgetView}
            noBackgroundBody={borderlessWidgetView}
            disableMaxHeight
            disableMinHeight
        >
            {loadingExpressions && !url ? (
                <ImageWidgetLoadingSkeleton />
            ) : (
                <ImageWrapper>
                    {!urlIsInvalid && url && originalExpression && (
                        <StyledImage src={url} alt={altText} title={altText} onError={() => setImageError(true)} />
                    )}
                    {((!loadingExpressions && urlIsInvalid) || !originalExpression) && (
                        <StateMessage icon={<EmptyImage />} title="Image content not found">
                            The source URL configured for this content might be invalid
                        </StateMessage>
                    )}
                </ImageWrapper>
            )}
        </ItemWidget>
    );
};

export default ImageWidgetDisplay;
