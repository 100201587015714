import React from 'react';
import styled, { css } from 'styled-components';

import type { PeopleDirectoryRecipientsPreviewProps } from './PeopleDirectoryRecipientsPreviewProps';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoriesView } from '../../../utils/PeopleDirectoryViewContext';

import { EnvironmentIndicator, InformationTooltip, Placeholder } from '@tonkean/infrastructure';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';

const EnvironmentMessage = styled.div<{ $gap?: boolean }>`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    display: flex;
    align-items: center;
    white-space: pre;

    ${({ $gap = true }) =>
        $gap &&
        css`
            gap: 8px;
        `};
`;
const AggregativeRecipientsMessage = styled.span`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    display: inline-flex;
`;

type Props = Pick<PeopleDirectoryRecipientsPreviewProps, 'currentEnvironment' | 'setCurrentEnvironment' | 'loading'>;

const Message: React.FC<{ message: string; peopleDirectoriesLength: number; totalIndividuals: number }> = ({
    message,
    peopleDirectoriesLength,
    totalIndividuals,
}) => {
    return (
        <span>
            {message}&nbsp;
            <AggregativeRecipientsMessage>
                {peopleDirectoriesLength} business groups containing total of {totalIndividuals} unique{' '}
                {totalIndividuals === 1 ? 'recipient' : 'recipients'}
            </AggregativeRecipientsMessage>
        </span>
    );
};

const Preview: React.FC<Props> = ({ currentEnvironment, setCurrentEnvironment, loading }) => {
    const { peopleDirectories, totalIndividuals, sendToGroupOfPeopleAs } = usePeopleDirectoriesView();

    return (
        <EnvironmentMessage>
            In
            {currentEnvironment && setCurrentEnvironment && (
                <EnvironmentIndicator
                    onChanges={setCurrentEnvironment}
                    placeholder={loading ? 'loading' : ''}
                    currentEnvironment={loading ? undefined : currentEnvironment}
                    isLoading={loading}
                />
            )}
            {loading ? (
                <Placeholder $width="400px" />
            ) : (
                <>
                    <Message
                        message="environment, message will be sent to:"
                        peopleDirectoriesLength={Object.values(peopleDirectories).length}
                        totalIndividuals={totalIndividuals}
                    />
                    <InformationTooltip iconSize={InformationIconSize.SMALL}>
                        Recipients list is created dynamically during run time and might differ from the list presented
                        below
                    </InformationTooltip>
                </>
            )}
        </EnvironmentMessage>
    );
};

const History: React.FC<Props> = () => {
    const { peopleDirectories, totalIndividuals, sendToGroupOfPeopleAs } = usePeopleDirectoriesView();

    return (
        <EnvironmentMessage $gap={false}>
            <Message
                message="Message was sent to:"
                peopleDirectoriesLength={Object.values(peopleDirectories).length}
                totalIndividuals={totalIndividuals}
            />
        </EnvironmentMessage>
    );
};

const PeopleDirectoryRecipientsSummaryMessage: React.FC<Props> = (props) => {
    const { mode } = usePeopleDirectoriesView();

    return mode === PeopleDirectoryViewMode.PREVIEW ? <Preview {...props} /> : <History {...props} />;
};

export default PeopleDirectoryRecipientsSummaryMessage;
