import {getColumnsAndStatesOptionsForDefinitions, getTonkeanQueryParticipatingFilters} from '@tonkean/tonkean-utils';

/**
 * A utils class for handling Tonkean server objects.
 * This util class uses the main Tonkean managers and can be used by other managers or controllers.
 */
function TonkeanUtils(utils) {
    const _this = this;

    /**
     * A regex expression that returns the parts of the expression:
     * A string, a field and a formula
     */
    const getAnExpressionPartRegex = /<#.+?#>|{{.+?}}|(?:^|(?!}}|#>)).+?(?:(?={{|<#)|$)/gm;

    _this.enrichPersonActionDefinition = function (
        type,
        actionDefinition,
        personSelectionConfiguration,
        botMessageText,
        evaluatedBotMessageText,
    ) {
        const parsedPersonSelectionConfig = _this.buildPersonSelectionConfiguration(personSelectionConfiguration);

        const action = {
            type,
            definition: {
                text: botMessageText,
                evaluatedText: evaluatedBotMessageText,
                initiativeOwner: parsedPersonSelectionConfig.initiativeOwner,
                previousActor: parsedPersonSelectionConfig.previousActor,
                specificPeopleIds: parsedPersonSelectionConfig.specificPeopleIds,
                personEmailExpressionDefinition: parsedPersonSelectionConfig.personEmailExpressionDefinition,
                useNone: parsedPersonSelectionConfig.useNone,
                forceEmail: parsedPersonSelectionConfig.forceEmail,
                sendAsGroupMessage: parsedPersonSelectionConfig.sendAsGroupMessage,
                sendTo: parsedPersonSelectionConfig.sendTo,
                selectedEmailProviderProjectIntegrationId:
                parsedPersonSelectionConfig.selectedEmailProviderProjectIntegrationId,
            },
        };

        actionDefinition.actions.push(action);

        return action;
    };

    _this.buildPersonSelectionConfiguration = function (personSelectionConfiguration, specificPeopleIdsPropertyName) {
        const finalSpecificPeopleIdsPropertyName = specificPeopleIdsPropertyName || 'specificPeopleIds';

        const data = {
            personEmailExpressionDefinition: null,
            onBehalfOfExpressionDefinition: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
            forceEmail: null,
            sendAsGroupMessage: null,
            sendTo: null,
            selectedEmailProviderProjectIntegrationId: null,
            isTonkeanRootUser: false,
        };

        if (personSelectionConfiguration) {
            if (
                personSelectionConfiguration.personSelectionType === 'fromField' &&
                personSelectionConfiguration.fromFieldExpressionDefinition
            ) {
                data.personEmailExpressionDefinition = personSelectionConfiguration.fromFieldExpressionDefinition;
            }

            if (personSelectionConfiguration.onBehalfOfExpressionDefinition) {
                data.onBehalfOfExpressionDefinition = personSelectionConfiguration.onBehalfOfExpressionDefinition
            }

            data[finalSpecificPeopleIdsPropertyName] = [];
            if (
                personSelectionConfiguration.personSelectionType === 'specificPeople' &&
                personSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < personSelectionConfiguration.selectedPeopleIds.length; i++) {
                    data[finalSpecificPeopleIdsPropertyName].push(personSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = personSelectionConfiguration.personSelectionType === 'initiativeOwner';
            data.previousActor = personSelectionConfiguration.personSelectionType === 'previousActor';
            data.useNone = personSelectionConfiguration.personSelectionType === 'none';
            data.failIfNoValue = personSelectionConfiguration.failIfNoValue;
            data.forceEmail = personSelectionConfiguration.forceEmail;
            data.sendAsGroupMessage = personSelectionConfiguration.sendAsGroupMessage;
            data.sendTo = personSelectionConfiguration.sendTo;
            data.selectedEmailProviderProjectIntegrationId =
                personSelectionConfiguration.selectedEmailProviderProjectIntegrationId;
            data.isTonkeanRootUser = personSelectionConfiguration.personSelectionType === 'isTonkeanRootUser';
        }

        return data;
    };

    /**
     * Returns whether the given channelOrPersonSelectionConfiguration is valid.
     */
    _this.isChannelOrPersonSelectionConfigurationValid = function (channelOrPersonSelectionConfiguration) {
        // We must have a target type.
        if (!channelOrPersonSelectionConfiguration || !channelOrPersonSelectionConfiguration.targetType) {
            return false;
        }

        switch (channelOrPersonSelectionConfiguration.targetType) {
            // If reply on thread, there is nothing to check.
            case 'thread':
                return true;

            // Validating channel notification.
            case 'channel':
                // Must have a channel type.
                if (
                    !channelOrPersonSelectionConfiguration.notificationSettings ||
                    !channelOrPersonSelectionConfiguration.notificationSettings.channelType
                ) {
                    return false;
                }

                switch (channelOrPersonSelectionConfiguration.notificationSettings.channelType) {
                    // Public channel.
                    case 'PUBLIC_CHANNEL':
                        // Must have channel name and channel id.
                        if (
                            !channelOrPersonSelectionConfiguration.notificationSettings.channelName ||
                            !channelOrPersonSelectionConfiguration.notificationSettings.channelId
                        ) {
                            return false;
                        }
                        break;

                    // Private channel.
                    case 'PRIVATE_CHANNEL':
                        //  Must have channel name.
                        if (!channelOrPersonSelectionConfiguration.notificationSettings.channelName) {
                            return false;
                        }
                        break;

                    // If not a private or public channel, it's invalid!
                    default:
                        return false;
                }
                break;

            // Validating person notification.
            case 'person':
                // We must have a person selection type.
                if (!channelOrPersonSelectionConfiguration.personSelectionType) {
                    return false;
                }

                if (channelOrPersonSelectionConfiguration.personSelectionType === 'specificPeople') {
                    // If it's a specific people notification, we must have specific people ids defined!
                    if (
                        !channelOrPersonSelectionConfiguration.selectedPeopleIds ||
                        !channelOrPersonSelectionConfiguration.selectedPeopleIds.length
                    ) {
                        return false;
                    }
                } else if (channelOrPersonSelectionConfiguration.personSelectionType === 'fromField') {
                    // If it's notification to a value from integration field, we must have a field definition!
                    if (!channelOrPersonSelectionConfiguration.fromFieldExpressionDefinition) {
                        return false;
                    }
                } else if (
                    channelOrPersonSelectionConfiguration.personSelectionType !== 'initiativeOwner' &&
                    channelOrPersonSelectionConfiguration.personSelectionType !== 'previousActor'
                ) {
                    // If the person selection type is not out of the 4 types mentioned above, it's not valid!
                    return false;
                }
                break;

            // If it's not a channel or a person, it's invalid!
            default:
                return false;
        }

        return true;
    };

    /**
     * Gets the server object for custom notification settings + field definitions to create.
     */
    _this.getCustomNotificationSettingsServerObject = function (
        botMessageText,
        evaluatedBotMessageText,
        channelOrPersonSelectionConfiguration,
        notificationAdditionalFields,
        hideInitiativePageLink,
        communicationProjectIntegrationId,
    ) {
        if (!channelOrPersonSelectionConfiguration) {
            return null;
        }

        const serverObject = {
            notificationSettings: channelOrPersonSelectionConfiguration.notificationSettings,
            text: botMessageText,
            evaluatedText: evaluatedBotMessageText,
            forceEmail: channelOrPersonSelectionConfiguration.forceEmail,
            fields: [],
            hideInitiativePageLink,
            communicationProjectIntegrationId,
        };

        // Notification additional fields.
        if (notificationAdditionalFields && notificationAdditionalFields.length) {
            serverObject.fields = notificationAdditionalFields
                .map((existingField) => {
                    if (existingField.isSpecialField) {
                        return {
                            specialFieldId: existingField.id,
                        };
                    }
                    return {
                        completeField: existingField,
                        fieldDefinitionId: existingField.id,
                    };
                })
                .filter((loadedField) => loadedField);
        }

        if (channelOrPersonSelectionConfiguration.targetType === 'person') {
            let personEmailExpressionDefinition = null;
            if (channelOrPersonSelectionConfiguration.personSelectionType === 'fromField') {
                personEmailExpressionDefinition = channelOrPersonSelectionConfiguration.fromFieldExpressionDefinition;
            }

            const inquiredPeopleIds = [];
            if (
                channelOrPersonSelectionConfiguration.personSelectionType === 'specificPeople' &&
                channelOrPersonSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < channelOrPersonSelectionConfiguration.selectedPeopleIds.length; i++) {
                    inquiredPeopleIds.push(channelOrPersonSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            serverObject.initiativeOwner =
                channelOrPersonSelectionConfiguration.personSelectionType === 'initiativeOwner';
            serverObject.previousActor = channelOrPersonSelectionConfiguration.personSelectionType === 'previousActor';
            serverObject.specificPeopleIds = inquiredPeopleIds;
            serverObject.personEmailExpressionDefinition = personEmailExpressionDefinition;
            serverObject.sendTo = channelOrPersonSelectionConfiguration.sendTo;
            serverObject.selectedEmailProviderProjectIntegrationId =
                channelOrPersonSelectionConfiguration.selectedEmailProviderProjectIntegrationId;
        }

        if (channelOrPersonSelectionConfiguration.targetType === 'thread') {
            serverObject.replyToPreviousMessage = true;
        }

        return serverObject;
    };

    _this.getEntityNameAndSourceName = function (projectManager, groupId, workflowVersionManager, syncConfigCacheManager) {
        let entityAndSource;

        const syncConfig = syncConfigCacheManager.getSyncConfig(
            workflowVersionManager.getPublishedVersionFromCache(groupId).id,
        );
        if (syncConfig && syncConfig.viewData) {
            const entity = syncConfig.viewData.Entity || syncConfig.viewData.entity;
            entityAndSource = `${syncConfig.projectIntegration.displayName} ${entity}`;
        } else {
            entityAndSource = 'Item';
        }

        return entityAndSource;
    };

    _this.getItemDisplayNameForBotPreview = function (
        projectManager,
        groupId,
        workflowVersionManager,
        syncConfigCacheManager,
    ) {
        const entityAndSource = _this.getEntityNameAndSourceName(
            projectManager,
            groupId,
            workflowVersionManager,
            syncConfigCacheManager,
        );

        return `{${entityAndSource}'s title}`; // eslint-disable-line no-useless-escape
    };

    /**
     * Gets the first action of given type in action array.
     */
    _this.getFirstCustomTriggerActionOfTypes = function (customTriggerActions, customTriggerActionTypes) {
        if (
            customTriggerActionTypes &&
            customTriggerActionTypes.length &&
            customTriggerActions &&
            customTriggerActions.length
        ) {
            const customTriggerActionTypesSet = utils.arrayToSet(customTriggerActionTypes);

            for (const action of customTriggerActions) {
                if (customTriggerActionTypesSet[action.customTriggerActionType]) {
                    return action;
                }
            }
        }

        return null;
    };

    /**
     * Generates a filter object for filtering over a status.
     */
    _this.generateStatusFilter = function (
        groupIdToGroupMap,
        selectedColumnFieldsMap,
        selectedGlobalFieldsMap,
        groupId,
        autoSelectType,
        workflowVersionId,
        workflowVersionIdToWorkflowVersionMap,
        workflowVersionManager,
    ) {
        const data = getColumnsAndStatesOptionsForDefinitions(
            groupId,
            false,
            selectedColumnFieldsMap,
            selectedGlobalFieldsMap,
            groupIdToGroupMap,
            false,
            workflowVersionId,
            workflowVersionIdToWorkflowVersionMap,
            workflowVersionManager,
        );
        let value = null;

        if (autoSelectType) {
            let stateTypeIndex = 0;
            for (let i = 0; i < data.statesFull.length; i++) {
                const st = data.statesFull[i];
                if (st.type === autoSelectType) {
                    stateTypeIndex = i;
                    break;
                }
            }
            value = data.statesFull[stateTypeIndex].label;
        }

        return {
            compareTimeframe: 1,
            condition: 'Equals',
            fieldLabel: 'Status',
            fieldName: 'TNK_STAGE',
            fromOriginalEntity: true,
            id: utils.guid(),
            isSpecialField: false,
            matchType: null,
            secondValue: null,
            type: 'List',
            value,
            values: data.states,
        };
    };

    /**
     * Generate field definition for an external field that need to be created.
     */
    _this.generateExternalFieldDefinitionToCreate = function (completeField) {
        const fieldToCreate = {
            definition: {
                FieldName: completeField.name,
                FieldLabel: completeField.label,
                ExternalType: completeField.externalType,
            },
            type: 'EXTERNAL',
            name: completeField.label,
            projectIntegrationId: completeField.projectIntegrationId,
            key: completeField.key,
        };

        return fieldToCreate;
    };

    _this.createFieldDefinitionKeyToFieldDefinitionMap = function (
        createMultipleFieldDefinitionsData,
        alreadyCreatedFilterKeys,
    ) {
        const createdFieldDefinitionKeyToFieldDefinitionMap = {};
        for (let i = 0; i < createMultipleFieldDefinitionsData.creationResults.length; i++) {
            const creationResult = createMultipleFieldDefinitionsData.creationResults[i];
            createdFieldDefinitionKeyToFieldDefinitionMap[creationResult.key] = creationResult.definition;
            alreadyCreatedFilterKeys[creationResult.key] = true;
        }
        return createdFieldDefinitionKeyToFieldDefinitionMap;
    };

    _this.replaceFiltersAndActionsWithCreatedFieldDefinitions = function (
        createMultipleFieldDefinitionsData,
        alreadyCreatedFilterKeys,
        queryDefinition,
        actionDefinitionObject,
    ) {
        // Creating a map between created field definition key and its definition object.
        const createdFieldDefinitionKeyToFieldDefinitionMap = _this.createFieldDefinitionKeyToFieldDefinitionMap(
            createMultipleFieldDefinitionsData,
            alreadyCreatedFilterKeys,
        );

        // Handling created field definitions for actions.
        if (actionDefinitionObject && actionDefinitionObject.actions && actionDefinitionObject.actions.length) {
            // Going through the manual field update actions, and converting the temp id to the created field definition id.
            const manualFieldUpdateActions = actionDefinitionObject.actions.filter(
                (action) => action.type === 'MANUAL_FIELD_UPDATE',
            );
            for (const manualFieldUpdateAction of manualFieldUpdateActions) {
                for (const key in createdFieldDefinitionKeyToFieldDefinitionMap) {
                    if (createdFieldDefinitionKeyToFieldDefinitionMap.hasOwnProperty(key)) {
                        const createdDefinition = createdFieldDefinitionKeyToFieldDefinitionMap[key];
                        manualFieldUpdateAction.definition.fieldIdToValueMap[createdDefinition.id] =
                            manualFieldUpdateAction.definition.fieldIdToValueMap[key];
                        delete manualFieldUpdateAction.definition.fieldIdToValueMap[key];
                    }
                }
            }

            // Going through the ask person field update actions, and putting the created field definition id.
            const askFieldUpdateActions = actionDefinitionObject.actions.filter(
                (action) => action.type === 'ASK_FIELD_UPDATE',
            );
            for (const askFieldUpdateAction of askFieldUpdateActions) {
                for (const key in createdFieldDefinitionKeyToFieldDefinitionMap) {
                    if (createdFieldDefinitionKeyToFieldDefinitionMap.hasOwnProperty(key)) {
                        const createdDefinition = createdFieldDefinitionKeyToFieldDefinitionMap[key];
                        askFieldUpdateAction.definition.fieldDefinitionId = createdDefinition.id;
                    }
                }
            }

            // Going through the perform integration actions, and putting the created field definition id.
            const performIntegrationActions = actionDefinitionObject.actions.filter(
                (action) => action.type === 'PERFORM_INTEGRATION_ACTION',
            );
            for (const performIntegrationAction of performIntegrationActions) {
                const fieldDefinitionIdToValueMap =
                    performIntegrationAction.definition.performedActionDefinition.fieldDefinitionIdToValueMap;

                for (const key in fieldDefinitionIdToValueMap) {
                    if (
                        fieldDefinitionIdToValueMap.hasOwnProperty(key) &&
                        createdFieldDefinitionKeyToFieldDefinitionMap[key]
                    ) {
                        const createdDefinition = createdFieldDefinitionKeyToFieldDefinitionMap[key];
                        fieldDefinitionIdToValueMap[createdDefinition.id] = fieldDefinitionIdToValueMap[key];
                        delete fieldDefinitionIdToValueMap[key];
                    }
                }
            }
        }

        replaceFiltersWithCreatedFieldDefinitions(queryDefinition.query, createdFieldDefinitionKeyToFieldDefinitionMap);
        if (actionDefinitionObject && actionDefinitionObject.actions) {
            replaceActionsFieldsWithCreatedFieldDefinitions(
                actionDefinitionObject.actions,
                createdFieldDefinitionKeyToFieldDefinitionMap,
            );
        }
    };

    /**
     * Generate fields for create for each of the common filters in the control.
     */
    _this.generateRelevantFiltersFieldsForCreate = function (control) {
        const queryDefinition = control.createDefinitionFromCustomFilters();

        // Taking only the filters that require field definition creation.
        const relevantFilters = getTonkeanQueryParticipatingFilters(queryDefinition.query)
            .filter((filter) => filter.swapWithCreatedFieldDefinition);

        return relevantFilters.map((fieldToCreate) => {
            return {
                definition: {
                    FieldName: fieldToCreate.fieldName,
                    FieldLabel: fieldToCreate.fieldLabel,
                    ExternalType: fieldToCreate.externalType,
                },
                type: 'EXTERNAL',
                name: fieldToCreate.fieldLabel,
                projectIntegrationId: fieldToCreate.projectIntegrationId,
                key: fieldToCreate.key,
            };
        });
    };

    /**
     * Replaces the filters in the query with created field definitions.
     */
    function replaceFiltersWithCreatedFieldDefinitions(query, createFieldDefinitionMap) {
        if (query.filters && query.filters.length) {
            for (let i = 0; i < query.filters.length; i++) {
                const filter = query.filters[i];

                if (filter.swapWithCreatedFieldDefinition && createFieldDefinitionMap[filter.key]) {
                    const field = createFieldDefinitionMap[filter.key];

                    filter.fieldName = field.id;
                    delete filter.projectIntegrationId;
                    delete filter.externalType;
                    delete filter.key;
                    delete filter.swapWithCreatedFieldDefinition;

                    // Some filter fields we swap with might not have a type. This is mandatory for things to work properly.
                    if (utils.isNullOrUndefined(filter.type)) {
                        filter.type = field.fieldType;
                    }
                }
            }
        }

        if (query.queries && query.queries.length) {
            for (let i = 0; i < query.queries.length; i++) {
                const innerQuery = query.queries[i];

                replaceFiltersWithCreatedFieldDefinitions(innerQuery);
            }
        }
    }

    /**
     * Replaces the fields in the actions with created field definitions.
     */
    function replaceActionsFieldsWithCreatedFieldDefinitions(actions, createFieldDefinitionMap) {
        if (actions.length) {
            for (const action of actions) {
                if (
                    action.definition &&
                    action.definition.performedActionDefinition &&
                    action.definition.performedActionDefinition.fieldsForCreation
                ) {
                    // Swipe perform action create fields
                    const fields = action.definition.performedActionDefinition.fieldsForCreation;
                    const fieldsForCreationArr = utils.objToArray(fields);
                    for (const field of fieldsForCreationArr) {
                        if (
                            field.value &&
                            field.value.valueInputConfiguration &&
                            field.value.valueInputConfiguration.completeField &&
                            field.value.valueInputConfiguration.completeField.swapWithCreatedFieldDefinition &&
                            field.value.valueInputConfiguration.definition
                        ) {
                            const completeField = field.value.valueInputConfiguration.completeField;
                            const newField = createFieldDefinitionMap[completeField.key];
                            field.value.valueInputConfiguration.definition.id = newField.id;
                            field.value.valueInputConfiguration.definition.source = completeField.source;
                            clearCompleteField(completeField, createFieldDefinitionMap);
                        }
                    }
                } else if (
                    action.definition &&
                    action.definition.performedActionDefinition &&
                    action.definition.performedActionDefinition.fieldDefinitionIdToValueMap
                ) {
                    // Swipe perform action update fields
                    const fields = action.definition.performedActionDefinition.fieldDefinitionIdToValueMap;

                    const fieldsForCreationArr = utils.objToArray(fields);
                    for (const field of fieldsForCreationArr) {
                        if (
                            field.value &&
                            field.value.completeField &&
                            field.value.completeField.swapWithCreatedFieldDefinition
                        ) {
                            const completeField = field.value.completeField;
                            const newField = createFieldDefinitionMap[completeField.key];
                            field.value.definition.id = newField.id;
                            field.value.definition.source = completeField.source;
                            clearCompleteField(completeField, createFieldDefinitionMap);
                        }
                    }
                } else if (action.definition && action.definition.fieldIdToValueMap) {
                    // Swipe update fields
                    const fields = action.definition.fieldIdToValueMap;
                    const fieldsForCreationArr = utils.objToArray(fields);
                    for (const field of fieldsForCreationArr) {
                        if (
                            field.value &&
                            field.value.completeField &&
                            field.value.completeField.swapWithCreatedFieldDefinition
                        ) {
                            const completeField = field.value.completeField;
                            const newField = createFieldDefinitionMap[completeField.key];
                            field.value.fieldDefinitionId = newField.id;
                            clearCompleteField(completeField, createFieldDefinitionMap);
                        }
                    }
                } else if (action.definition && action.definition.fields) {
                    // Swipe Person Inquiry fields and notifications
                    const fields = action.definition.fields;
                    const fieldsForCreationArr = utils.objToArray(fields);
                    for (const field of fieldsForCreationArr) {
                        if (
                            field.value &&
                            field.value.completeField &&
                            field.value.completeField.swapWithCreatedFieldDefinition
                        ) {
                            const completeField = field.value.completeField;
                            const newField = createFieldDefinitionMap[completeField.key];
                            field.value.fieldDefinitionId = newField.id;
                            clearCompleteField(completeField, createFieldDefinitionMap);
                        }
                    }
                }
            }
        }
    }

    /**
     * If the field should be swipe clear it.
     */
    function clearCompleteField(completeField, createFieldDefinitionMap) {
        if (completeField.swapWithCreatedFieldDefinition && createFieldDefinitionMap[completeField.key]) {
            completeField.fieldName = createFieldDefinitionMap[completeField.key].id;
            completeField.id = createFieldDefinitionMap[completeField.key].id;
            delete completeField.projectIntegrationId;
            delete completeField.externalType;
            delete completeField.key;
            delete completeField.swapWithCreatedFieldDefinition;
        }
    }

    /**
     * Gets the used variables in the formula expression.
     */
    _this.getFormulaExpressionUsedVariables = function (formulaExpression) {
        const variables = [];

        /* jshint loopfunc:true */
        for (let i = 0; i < formulaExpression.length; i++) {
            const currentChar = formulaExpression[i];

            // Variable names will be encapsulated in {} brackets
            if (currentChar === '{') {
                const startingIndex = i + 1;
                const closingIndex = formulaExpression.indexOf('}', i); // The closing bracket of the opening bracket we found
                const variableName = formulaExpression.substr(startingIndex, closingIndex - startingIndex);

                variables.push(variableName);
            }
        }

        return variables;
    };

    _this.convertMatchSelectionToClientObject = function (matchConfigurationSelection) {
        return {
            matchOptionApiName: matchConfigurationSelection.matchOption,
            performOnWorkerItem: matchConfigurationSelection.performOnWorkerItem,
            creatingCustomTriggerId: matchConfigurationSelection.creatingCustomTriggerId,
            idRelationFieldDefinitionId: matchConfigurationSelection.idRelationFieldDefinitionId,
            entityMatchField: matchConfigurationSelection.entityMatchField,
            workerItemMatchFieldDefinition: matchConfigurationSelection.workerItemMatchFieldDefinition,
            workerItemMatchSpecialField: matchConfigurationSelection.workerItemMatchSpecialField,
            customQuery: matchConfigurationSelection.customQuery,
            isForMatchingItem: matchConfigurationSelection.isForMatchingItem,
            matchedItemSourceGroupId: matchConfigurationSelection.matchedItemSourceGroupId,
            matchedItemSourceWorkflowVersionId: matchConfigurationSelection.matchedItemSourceWorkflowVersionId,
            matchingRecurrence: matchConfigurationSelection.matchingRecurrence,
        };
    };

    /**
     * Create a Tonkean Expression object
     */
    _this.createTonkeanExpression = function (
        originalExpression,
        evaluatedExpression = originalExpression,
        isStripHtmlDisabled = false,
    ) {
        return {
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled,
        };
    };

    /**
     * Gets the input type selection for field editor.
     */
    _this.getInputTypeSelectionForFieldEditor = function (speciallyHandledField) {
        if (speciallyHandledField) {
            return 'MANUAL';
        }
        return 'EXPRESSION';
    };

    /**
     * Gets whether given field is a specially handled field for field editor.
     */
    _this.getSpeciallyHandledFieldForFieldEditor = function (field) {
        return (
            !utils.isNullOrUndefined(field) &&
            (field.id === 'TNK_OWNER_ID' ||
                field.id === 'TNK_STAGE' ||
                field.type === 'Date' ||
                field.fieldType === 'Date' ||
                field.type === 'date' ||
                field.fieldType === 'date' ||
                field.displayFormat === 'USER')
        );
    };

    /**
     * Gets the entity metadata for the file entity.
     */
    _this.getFileEntityMetadata = function () {
        return {
            displayName: 'File',
            entity: 'File',
            pluralLabel: 'Files',
        };
    };

    /**
     * Whether this project integration custom actions can run on entity.
     */
    _this.canProjectIntegrationCustomActionRunOnEntity = function (projectIntegration) {
        return projectIntegration.integrationType !== 'WEBHOOK';
    };

    /**
     * Converts a Tonkean Expression to a formula.
     * For example: `{{Owner}} is <# 2020 - {birthYear} #>` will return `Concat({Owner}, " is ", 2020 - {birthYear})}`
     *
     * @param {string} expression
     * @returns {string} formula
     */
    _this.convertExpressionToFormula = function (expression) {
        if (!expression || !expression.length) {
            return '""';
        }

        const expressionParts = expression.match(getAnExpressionPartRegex);

        const formulaParts = expressionParts.map((part) => {
            if (part.startsWith('{{') && part.endsWith('}}')) {
                // A field
                return `{${removeWrappingChars(part)}}`;
            } else if (part.startsWith('<#') && part.endsWith('#>')) {
                // A formula
                return removeWrappingChars(part);
            } else if (/^\d+\.?\d*$/g.test(part)) {
                // A number
                return part;
            }
            // A string
            // Adding a slash before quotes and slash at the end of the string
            const escapedString = part.replace(/(|\\)"/g, '$1\\"').replace(/\\$/g, '\\\\');
            return `"${escapedString}"`;
        });

        if (formulaParts.length === 1) {
            return formulaParts[0];
        }
        const concatArguments = formulaParts.join(', ');
        return `Concat(${concatArguments})`;
    };

    /**
     * Remove the wrapping chars of a field or a formula (the `{{` and `}}`,
     * and `<#` and `#>`.
     * @param {string} str
     * @returns {string}
     */
    function removeWrappingChars(str) {
        return str.substr(2, str.length - 4);
    }
}

export default angular.module('tonkean.app').service('tonkeanUtils', TonkeanUtils);
