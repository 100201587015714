import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { DeprecatedDate } from '@tonkean/utils';

/**
 * Holds the cache of initiatives in the system.
 */
function InitiativeCache(utils, projectManager, entityInitiativeHelper) {
    const _this = this;

    // Single item time to leave threshold in milliseconds.
    const singleItemTtl = 60_000;

    // Initiatives cache.
    let cacheItemMap = {};
    let cacheTtl = {};

    // Caches for calendar uses.
    let tracksByDate = {};
    let tracksByGroupId = {};

    // Owner cache.
    let cacheHasOwner = {};

    /**
     * Clears all the caches of the track helper.
     */
    _this.clearCaches = function () {
        // Initiatives cache.
        cacheItemMap = {};
        cacheTtl = {};

        // Caches for calendar uses.
        tracksByDate = {};
        tracksByGroupId = {};

        // Owner cache.
        cacheHasOwner = {};
    };

    /**
     * Gets the cache of initiatives.
     */
    _this.getInitiativesCache = function () {
        return cacheItemMap;
    };

    /**
     * Returns true if given initiativeId is in our cache. False otherwise.
     */
    _this.cacheHasInitiative = function (initiativeId) {
        return !!cacheItemMap[initiativeId];
    };

    /**
     * Returns the initiative with id of initiativeId from the cache, if such exists.
     */
    _this.getInitiativeFromCache = function (initiativeId) {
        return cacheItemMap[initiativeId];
    };

    /**
     * Returns initiatives that were updated between given from and to.
     */
    this.getInitiativesUpdatedFromAndToTimestamps = function (from, to) {
        const updatedInitiatives = [];

        for (const key in cacheItemMap) {
            if (cacheItemMap.hasOwnProperty(key)) {
                const initiative = cacheItemMap[key];

                if (initiative && initiative.updated && initiative.updated >= from && initiative.updated <= to) {
                    updatedInitiatives.push(initiative);
                }
            }
        }

        return updatedInitiatives;
    };

    /**
     * Returns the initiatives with given ids of initiativeIds from the cache, if they exist.
     */
    _this.getInitiativesFromCache = function (initiativeIds) {
        if (!initiativeIds || !initiativeIds.length) {
            return null;
        }

        return initiativeIds
            .filter((initiativeId) => _this.cacheHasInitiative(initiativeId))
            .map((initiativeId) => _this.getInitiativeFromCache(initiativeId));
    };

    /**
     * Gets the cache of tracks by date (for calendars usages).
     */
    _this.getTracksByDateFromCache = function (dateToken) {
        if (!tracksByDate[dateToken]) {
            tracksByDate[dateToken] = {};
        }

        return tracksByDate[dateToken];
    };

    /**
     * Gets the cache of tracks by group.
     */
    _this.getTracksByGroupIdFromCache = function (groupId) {
        if (!tracksByGroupId[groupId]) {
            tracksByGroupId[groupId] = {};
        }

        return tracksByGroupId[groupId];
    };

    /**
     * Removes given initiative id from the cache.
     */
    _this.removeInitiativeFromCache = function (initiativeId) {
        if (!initiativeId || !_this.cacheHasInitiative(initiativeId)) {
            return;
        }

        delete cacheItemMap[initiativeId];
        delete cacheTtl[initiativeId];
    };

    /**
     * Caches the given item (initiative or initiative link).
     *
     * The reason for adding the considerCacheOptions, and not accepting an object like { optionA: true, optionB: true, ...},
     * is because we would like the user to know the model without going into the logic of the function,
     * and we would also like to be backwards compatible and keeping it simple - most function calls didn't even specify if
     * they'd like any special caching options, so instead of requiring them to pass options now, we keep the logic the same as
     * it was before for them.
     *
     * @param itemToCache Item (initiative or initiative link) to cache.
     * @param dontMarkTtl If true, will not mark cached item with a TTL. If false, will set a TTL to the item being cached.
     * @param considerCacheOptions If true, will consider the booleans cacheRelatedInitiatives, cacheParentInitiatives, cacheParent as caching options.
     * @param cacheRelatedInitiatives If true, and considerCacheOptions is true, will cache the related initiatives as well as the item itself. If false, and considerCacheOptions is true, will cache the related initiatives as well as the item itself.
     * @param cacheParentInitiatives If true, and considerCacheOptions is true, will cache the parent initiatives as well as the item itself. If false, and considerCacheOptions is true, will cache the parent initiatives as well as the item itself.
     * @param cacheParent If true, and considerCacheOptions is true, will cache the parent as well as the item itself. If false, and considerCacheOptions is true, will cache the parent initiative as well as the item itself.
     */
    _this.cacheItem = function (
        itemToCache,
        dontMarkTtl,
        considerCacheOptions,
        cacheRelatedInitiatives,
        cacheParentInitiatives,
        cacheParent,
        deleteMissingFields,
    ) {
        if (!itemToCache) {
            return itemToCache;
        }

        // We only cache initiatives or initiative links!
        if (!isInitiativeOrInitiativeLink(itemToCache.id)) {
            return itemToCache;
        }

        const cached = cacheItemMap[itemToCache.id];
        if (!cached) {
            // If itemToCache isn't cached yet, we just insert it into the cache.
            cacheItemMap[itemToCache.id] = itemToCache;
        } else {
            // Otherwise, we update the cache with its properties, using the cache options
            // the user might have given.
            let excludeFromCopy = null;
            // If the only change reason is DATA change, we do not cache the index!
            const shouldNotCacheIndexProperty =
                itemToCache &&
                itemToCache.changeTypes &&
                itemToCache.changeTypes.length === 1 &&
                itemToCache.changeTypes[0] === 'DATA';

            if (!considerCacheOptions) {
                // If considerCacheOptions = false, we exclude related initiatives, parent initiatives and the parent from the copy.
                excludeFromCopy = {
                    relatedInitiatives: true,
                    parentInitiatives: true,
                    parent: true,
                    index: shouldNotCacheIndexProperty,
                };
            } else {
                // Otherwise (considerCacheOptions = true), we exclude related and parents according to given options.
                excludeFromCopy = {
                    relatedInitiatives: !cacheRelatedInitiatives,
                    parentInitiatives: !cacheParentInitiatives,
                    parent: !cacheParent,
                    index: shouldNotCacheIndexProperty,
                };
            }

            utils.copyEntityFields(itemToCache, cached, excludeFromCopy, false, deleteMissingFields);
        }

        if (!dontMarkTtl && itemToCache && itemToCache.id) {
            cacheTtl[itemToCache.id] = new Date();
        }

        // Replace the initiative's group with a group from the groups cache in the pm.
        if (itemToCache.group && itemToCache.group.id) {
            // If the pm somehow doesn't have this group in cache, add it.
            if (!projectManager.groupExists(itemToCache.group.id)) {
                projectManager.addGroup(itemToCache.group);
            }

            itemToCache.group = projectManager.groupsMap[itemToCache.group.id];
        }

        // Enriching the freshly cached itemToCache
        cacheItemMap[itemToCache.id] = entityInitiativeHelper.enrichEntity(cacheItemMap[itemToCache.id]);
        _this.cacheDueDate(itemToCache);

        cacheByGroup(itemToCache);

        // Update the group has owners cache
        if (itemToCache.group && itemToCache.owner && !cacheHasOwner[itemToCache.group.id]) {
            cacheHasOwner[itemToCache.group.id] = true;
        }

        return cacheItemMap[itemToCache.id];
    };

    /**
     * Caches the given items (initiatives or initiative links or a mix between the two).
     *
     * The reason for adding the considerCacheOptions, and not accepting an object like { optionA: true, optionB: true, ...},
     * is because we would like the user to know the model without going into the logic of the function,
     * and we would also like to be backwards compatible and keeping it simple - most function calls didn't even specify if
     * they'd like any special caching options, so instead of requiring them to pass options now, we keep the logic the same as
     * it was before for them.
     *
     * @param items Items (initiative or initiative links) to cache.
     * @param dontMarkTtl If true, will not mark cached items with a TTL. If false, will set a TTL to each item being cached.
     * @param considerCacheOptions If true, will consider the booleans cacheRelatedInitiatives, cacheParentInitiatives, cacheParent as caching options.
     * @param cacheRelatedInitiatives If true, and considerCacheOptions is true, will cache the related initiatives as well as the item itself. If false, and considerCacheOptions is true, will cache the related initiatives as well as the item itself.
     * @param cacheParentInitiatives If true, and considerCacheOptions is true, will cache the parent initiatives as well as the item itself. If false, and considerCacheOptions is true, will cache the parent initiatives as well as the item itself.
     * @param cacheParent If true, and considerCacheOptions is true, will cache the parent as well as the item itself. If false, and considerCacheOptions is true, will cache the parent initiative as well as the item itself.
     */
    _this.cacheItems = function (
        items,
        dontMarkTtl,
        considerCacheOptions,
        cacheRelatedInitiatives,
        cacheParentInitiatives,
        cacheParent,
        deleteMissingFields,
    ) {
        if (!items || !items.length) {
            return items;
        }

        for (let i = 0; i < items.length; i++) {
            if (items[i] && isInitiativeOrInitiativeLink(items[i].id)) {
                items[i] = _this.cacheItem(
                    items[i],
                    dontMarkTtl,
                    considerCacheOptions,
                    cacheRelatedInitiatives,
                    cacheParentInitiatives,
                    cacheParent,
                    deleteMissingFields,
                );
            }
        }

        return items;
    };

    /**
     * Removes this item's eta from the dates cache.
     */
    _this.removeEtaCache = function (item) {
        if (item.eta) {
            const oldDateToken = new Date(item.eta).toDateString();
            if (tracksByDate[oldDateToken] && tracksByDate[oldDateToken][item.id]) {
                delete tracksByDate[oldDateToken][item.id];
            }
        }
    };

    /**
     * Removes the field id of the given item from the dates cache, if it is a date field.
     */
    _this.removeFieldDateCache = function (initiativeOrLink, fieldId) {
        if (!initiativeOrLink || !fieldId) {
            return;
        }

        const realInitiative = _this.getRealTrack(initiativeOrLink);
        // If we don't have an initiative or any fields, we have nothing to do.
        if (!realInitiative || !realInitiative.fields || !realInitiative.fields.length) {
            return;
        }

        const relevantField = utils.findFirst(
            realInitiative.fields,
            (field) => field.id === fieldId && field.valueDate,
        );
        if (relevantField) {
            const oldDateToken = new Date(relevantField.valueDate).toDateString();

            // Remove the initiative from the cache.
            if (tracksByDate[oldDateToken] && tracksByDate[oldDateToken][realInitiative.id]) {
                delete tracksByDate[oldDateToken][realInitiative.id];
            }
        }
    };

    /**
     * Removes this item's due date from the dates cache.
     * Call this function before changing the due date of the initiative, since it relies on the old due date
     * to find and remove the initiative/link from the cache.
     */
    _this.removeDueDateCache = function (initiativeOrLink) {
        if (!initiativeOrLink) {
            return;
        }

        const realInitiative = _this.getRealTrack(initiativeOrLink);
        // No point in going further if there's no initiative or dueDate.
        if (!realInitiative || !realInitiative.dueDate) {
            return;
        }

        const oldDateToken = new Date(realInitiative.dueDate).toDateString();

        // Remove the initiative from the cache.
        if (tracksByDate[oldDateToken] && tracksByDate[oldDateToken][realInitiative.id]) {
            delete tracksByDate[oldDateToken][realInitiative.id];
        }
    };

    /**
     * Caches initiative in the dates cache. (Primarily used for week view presentation).
     */
    _this.cacheDueDate = function (initiativeOrLink) {
        const realInitiative = _this.getRealTrack(initiativeOrLink);
        if (!realInitiative) {
            return;
        }

        cacheDueDateInner(initiativeOrLink, realInitiative.dueDate);
        cacheDueDateInner(initiativeOrLink, realInitiative.eta);

        // cache all external dates
        if (realInitiative.fields && realInitiative.fields.length) {
            for (let i = 0; i < realInitiative.fields.length; i++) {
                const field = realInitiative.fields[i];
                if (field && field.valueDate) {
                    cacheDueDateInner(initiativeOrLink, field.valueDate);
                }
            }
        }
    };

    /**
     * Returns true if we should invalidate the item in cache. False otherwise.
     */
    _this.shouldInvalidate = function (initiativeId) {
        if (!cacheTtl[initiativeId]) {
            return true;
        }

        const now = DeprecatedDate.nowAsDate();
        const cacheTime = cacheTtl[initiativeId].getTime();
        const diff = now - cacheTime;

        return diff >= singleItemTtl;
    };

    /**
     * From given entity which is an initiative or an initiative link, returns the underlying initiative entity (if it's a link, will return initiativeOrLink.initiative).
     */
    _this.getRealTrack = function (initiative) {
        if (!initiative) {
            return;
        }

        return _this.getInitiativeFromCache(initiative.id);
    };

    /**
     * Inner logic of the cache due date.
     */
    function cacheDueDateInner(initiativeOrLink, date) {
        if (!initiativeOrLink || !date) {
            return;
        }

        const dateToken = new Date(date).toDateString();

        if (!tracksByDate[dateToken]) {
            tracksByDate[dateToken] = {};
        }

        // Caching as the entity itself (whether it's initiative or a link).
        tracksByDate[dateToken][initiativeOrLink.id] = initiativeOrLink;
    }

    /**
     * Caches initiative in the initiative by group cache.
     */
    function cacheByGroup(initiative) {
        const groupId = initiative.group ? initiative.group.id : 'public';

        if (!tracksByGroupId[groupId]) {
            tracksByGroupId[groupId] = {};
        }

        tracksByGroupId[groupId][initiative.id] = initiative;
    }

    /**
     * Returns true if given entityId is either an initiative id or initiative link id. Returns false otherwise.
     */
    function isInitiativeOrInitiativeLink(entityId) {
        const entityType = getTonkeanEntityType(entityId);

        return entityType === TONKEAN_ENTITY_TYPE.INITIATIVE || entityType === TONKEAN_ENTITY_TYPE.INITIATIVE_LINK;
    }
}
angular.module('tonkean.app').service('initiativeCache', InitiativeCache);
