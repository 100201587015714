import React from 'react';

import {
    marketplaceItemKeyMetricTrendTypeToColor,
    type MarketplaceItemKeyMetricTrendType,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

interface Props {
    onTrendClicked: (trendType: MarketplaceItemKeyMetricTrendType) => void;
    children: React.ReactNode;
    trendType: MarketplaceItemKeyMetricTrendType;
}

const KeyMetricTrendButton: React.FC<Props> = ({ children, onTrendClicked, trendType }) => {
    return (
        <IconButton
            onClick={() => onTrendClicked(trendType)}
            iconColor={marketplaceItemKeyMetricTrendTypeToColor[trendType]}
            flat
        >
            {children}
        </IconButton>
    );
};

export default KeyMetricTrendButton;
