import AutoCloseLongDescription from './AutoCloseLongDescription';
import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import validateNoEmptyConditions from '../sharedConfigComponents/validators/validateNoEmptyConditions';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';
import { states } from '@tonkean/tonkean-entities';
import { getProjectStates } from '@tonkean/tonkean-utils';

const AutoCloseLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: 'AUTO_CLOSE',
    title: 'Item is Auto-Closed',
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-auto-close',
    description: 'Auto close old items',
    longDescription: undefined,
    longDescriptionComponent: AutoCloseLongDescription,
    showWhenDo: false,
    cantChangeType: () => true,
    cantCopyToOtherModule: true,
    cantDuplicate: true,
    cantDelete: true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: false,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [],
    definition: {},
    validator: ({ customTrigger, group, workflowVersionInfoRetriever, project }) => {
        const validationObject: LogicBlockValidationObject = {};
        let hasError = false;
        if (!customTrigger.stateId?.length) {
            hasError = true;
        } else {
            const workflowVersion = workflowVersionInfoRetriever.getDraftVersionFromCache(group.id);
            if (workflowVersion) {
                const moduleStates = getProjectStates(workflowVersion, project);
                const foundState = moduleStates.find(
                    (state) => state.id === customTrigger.stateId && state.type === states.DONE.type,
                );
                hasError = !foundState;
            }
        }
        if (hasError) {
            validationObject.noAutoCloseState = 'Auto Closed must have a status selected of type Done';
            validationObject.whenFinishedError = true;
        }

        validateNoEmptyConditions('Auto close', customTrigger, validationObject);

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutoCloseLogicBlockConfig;
