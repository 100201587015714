import * as React from 'react';

import type WorkerRunDateRangeFilter from './WorkerRunDateRangeFilter';
import type { StateParams } from '../hooks/useHistoryStateManager';

const HistoryContext = React.createContext<{
    environmentIsActive: boolean;
    state: StateParams;
    getState(newState: Partial<StateParams>): { name: string; params: any };
    updateState(newState: Partial<StateParams>, showInBrowserHistory?: boolean): void;
    setRangeFilter: React.Dispatch<React.SetStateAction<WorkerRunDateRangeFilter>>;
    rangeFilter: WorkerRunDateRangeFilter;
}>([] as any);

export default HistoryContext;
