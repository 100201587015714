import importWebhookDataModalTemplate from './../../../../app/components/modals/importWebhookDataModal/importWebhookDataModal.template.html?angularjs';
import React from 'react';
import { analyticsWrapper } from '@tonkean/analytics';

export function WebhookModalCtrl(
    $rootScope,
    $scope,
    $timeout,
    $uibModal,
    $http,
    $state,
    utils,
    entityHelper,
    projectManager,
    modalUtils,
    tonkeanService,
    projectIntegrationManager,
) {
    $scope.modalUtils = modalUtils;
    $scope.infoStates = {}; // Will hold the show/hide state of the info buttons.

    $scope.data = {
        // Webhook definition details
        incomingWebhook: {
            displayName: null,
            definition: {
                pickOffChildKey: null,
                idFieldPath: null,
                titleFieldPath: null,
                ownerEmailFieldPath: null,
                ownerNameFieldPath: null,
                parentNameFieldPath: null,
                descriptionFieldPath: null,
                tagsFieldPath: null,
                urlFieldPath: null,
                additionalFieldMappings: {
                    ID: [],
                    TITLE: [],
                    PARENT_NAME: [],
                    OWNER_EMAIL: [],
                    OWNER_NAME: [],
                    DESCRIPTION: [],
                    TAGS: [],
                    URL: [],
                },
                enrichedFieldsForPickOff: [{ fieldName: null, jsonPath: null }],
            },
        },
        projectIntegration: null,
        reloadFieldSelectorOptions: false,

        // Form
        definitionForm: null,

        // API
        errorMessage: null,
        loadingWebhook: null,
        loadingEditMode: null,
        loadingDeleteWebhook: null,
        loadingPickOffChildKey: null,
        loadingWebhookRefresh: null,

        // Polling
        webhookReceivedItems: false,
        getIncomingWebhookStatusTimeout: false,
        getIncomingWebhookStatusTimeLimit: null,

        // Mode
        editMode: false,
        postingFeedback: false,
        displayAdvancedConfiguration: false,
        displayEditModeSettings: false,
        displayReceivedEntityJson: false,
        showWebhookReceivedJSON: false,
        hideDeleteButton: false,
        hideWebhookFirstItemError: false,
        encryptionKeyLocked: false,
        encryptionKeyValidationError: null,
        encryptionKeyLength: 32,

        // State
        state: null,

        // type
        sourceType: 'manual',
        headersArray: [
            {
                name: '',
                value: '',
                isEncrypted: false,
            },
        ],
        authorizationTypeOptions: ['No Authorization', 'Other'],
        authorizationTypeSelected: null,
        baseUrl: null,

        blackListedKeys: [
            'created',
            'updated',
            'secondaryId',
            'TNK_REAL_ENTITY_ID',
            'TNK_NEW_CREATED_DATE',
            'TNK_NEW_MODIFIED_DATE',
        ],

        isEntitiesFeatureFlagOn: $rootScope.features.currentProject.tonkean_feature_enable_project_integration_entities,
        saveEnterpriseComponentVariableRef: React.createRef(),
    };

    let getIncomingWebhookStatusPromise;

    $scope.addHeader = function () {
        $scope.data.headersArray.push({
            name: '',
            value: '',
            isEncrypted: false,
        });
    };

    $scope.filterBlackListedFields = function (fields) {
        return fields.filter((fieldDefinition) => !$scope.data.blackListedKeys.includes(fieldDefinition.name));
    };

    $scope.isTonkeanSpecialField = function (field) {
        return !$scope.data.blackListedKeys.includes(field.name);
    };

    $scope.removeHeader = function (index) {
        $scope.data.headersArray.splice(index, 1);
    };

    $scope.addAdditionalJsonPath = function () {
        if (!$scope.data.incomingWebhook.definition.enrichedFieldsForPickOff) {
            $scope.data.incomingWebhook.definition.enrichedFieldsForPickOff = [];
        }
        $scope.data.incomingWebhook.definition.enrichedFieldsForPickOff.push({ fieldName: null, jsonPath: null });
    };

    $scope.removeAdditionalJsonPath = function (index) {
        $scope.data.incomingWebhook.definition.enrichedFieldsForPickOff.splice(index, 1);
    };

    $scope.onAuthorizationTypeSelected = function (selectedAuthType) {
        if (selectedAuthType === $scope.data.authorizationTypeOptions[0]) {
            $scope.data.headersArray = [];
        }

        if (selectedAuthType === $scope.data.authorizationTypeOptions[1]) {
            $scope.data.headersArray = [
                {
                    name: '',
                    value: '',
                },
            ];
        }
    };

    /**
     * Init function.
     * @param settings
     */
    $scope.init = function (settings) {
        $scope.data.sourceType = settings.webhookSource ? 'webhook' : 'manual';
        $scope.data.authorizationTypeSelected = $scope.data.authorizationTypeOptions[0];
        let state;
        if (settings.state) {
            state = angular.copy(settings.state);
        } else if (settings.originalState) {
            state = angular.copy(settings.originalState);
        } else {
            state = {};
        }

        if (state.integrations && state.integrations[0]) {
            // Edit mode.
            initializeEditMode(state);
        } else {
            // Create mode.
            // Add your code here...
        }

        if (settings.webhookSource) {
            $scope.data.webhookSource = settings.webhookSource;
            $scope.data.incomingWebhook.displayName = $scope.data.webhookSource.name;
        }

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.data.hideDeleteButton = settings.hideDeleteButton;
    };

    /**
     * Exists the modal.
     */
    $scope.cancel = function () {
        // Always stop the polling when closing the modal.
        cancelPolling();

        if ($scope.integrationConfigurationCanceled) {
            $scope.integrationConfigurationCanceled();
        }
        $scope.$dismiss();
    };

    /**
     * Polling server to see if we have any indexed items from the webhook.
     */
    $scope.pollWebhookEntities = function () {
        if ($scope.data.webhookReceivedItems) {
            initializeEditMode($scope.data.projectIntegration);
        } else {
            // Polling the server to see if we have any items in the incoming webhook
            $scope.data.webhookReceivedItems = false;
            $scope.data.getIncomingWebhookStatusTimeout = false;
            $scope.data.getIncomingWebhookStatusTimeLimit = Date.now() + 30 * 1000;

            getIncomingWebhookStatus($scope.data.projectIntegration);
        }
    };

    /**
     * Fired when the user selects a new field option for one of the path fields.
     * @param selectedField - the new field option selected.
     * @param selectedFieldIdentifier - the field name in the incoming webhook's definition.
     */
    $scope.onFieldOptionSelected = function (selectedField, selectedFieldIdentifier) {
        // Update our reference according to the selectedFieldIdentifier we got (which we set when using the component).
        if ($scope.data.incomingWebhook && $scope.data.incomingWebhook.definition && selectedFieldIdentifier) {
            $scope.data.incomingWebhook.definition[selectedFieldIdentifier] = selectedField;
        }
    };

    /**
     * Sets the "pick off a child key" JSON mapping setting for the current incoming webhook.
     * @param pickOffChildKeyString
     */
    $scope.setPickOffChildKey = function (pickOffChildKeyString) {
        if ($scope.data.incomingWebhook) {
            $scope.data.loadingPickOffChildKey = true;
            tonkeanService
                .setIncomingWebhookPickOffChildKey($scope.data.incomingWebhook.id, pickOffChildKeyString)
                .then(function () {
                    reloadFieldSelectorsOptions();
                })
                .catch(function (error) {
                    handleApiError(error);
                })
                .finally(function () {
                    $scope.data.loadingPickOffChildKey = false;
                });
        }
    };

    /**
     * Occurs when a key is down in the "pick off child key" input.
     * @param $event - the keyDown even.
     */
    $scope.onPickOffChildKeyKeyDown = function ($event) {
        // If enter happened was clicked.
        if ($event.code === 'Enter' || $event.keyCode === 13) {
            // Prevent default (we don't want the form to submit itself).
            $event.preventDefault();
            // Do the save action.
            $scope.setPickOffChildKey($scope.data.incomingWebhook.definition.pickOffChildKey.name);
        }
    };

    /**
     * Posts a requests for help to us.
     */
    $scope.askForHelp = function () {
        if (!$scope.data.postingFeedback) {
            $scope.data.postingFeedback = true;
            tonkeanService
                .postFeedback(
                    'Setup meeting request',
                    `${$scope.data.incomingWebhook.displayName} webhook help requested`,
                )
                .then(function () {
                    $scope.$emit('alert', {
                        msg: 'Thanks! Our team will contact you soon.',
                        type: 'success',
                    });
                    $scope.data.postingFeedback = false;
                })
                .finally(function () {
                    $scope.data.postingFeedback = false;
                });
        }
    };

    /**
     * Open the import webhook data modal
     */
    $scope.openImportWebhookDataModal = function () {
        modalUtils
            .open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: importWebhookDataModalTemplate,
                    controller: 'ImportWebhookDataModalCtrl',
                    size: 'md2',
                    animation: false,
                    resolve: {
                        projectIntegration() {
                            return $scope.data.projectIntegration;
                        },
                        incomingWebhook() {
                            return $scope.data.incomingWebhook;
                        },
                    },
                },
                'importWebhookDataModal',
            )
            .result.then(function () {
                $scope.data.selectedTab = 'dataLog';
                $scope.data.sourceType = 'webhook';
                // reload
                initializeEditMode($scope.data.projectIntegration);
            });
    };

    /**
     * Copies the url in the given element id to clipboard.
     * @param elementId - the element id in which the webhook url is.
     */
    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    /**
     * Creates the new webhook (which also creates an integration and a project integration.
     */
    $scope.createOrEditWebHook = function () {
        if (!$scope.data.definitionForm.$valid) {
            return;
        }

        if ($scope.data.editMode) {
            updateWebhook();
        } else {
            createWebhook();
        }
    };

    $scope.addAdditionalFieldMapping = function (externalActivityFieldType) {
        if (!$scope.data.incomingWebhook.definition.additionalFieldMappings) {
            $scope.data.incomingWebhook.definition.additionalFieldMappings = {};
        }

        if (!$scope.data.incomingWebhook.definition.additionalFieldMappings[externalActivityFieldType]) {
            $scope.data.incomingWebhook.definition.additionalFieldMappings[externalActivityFieldType] = [];
        }

        const newElement = {
            id: utils.guid(),
            name: '',
            label: '',
        };
        $scope.data.incomingWebhook.definition.additionalFieldMappings[externalActivityFieldType].push(newElement);
    };

    /**
     * Remove additional field mapping by index.
     */
    $scope.removeAdditionalFieldMapping = function (field, index) {
        $scope.data.incomingWebhook.definition.additionalFieldMappings[field].splice(index, 1);
    };

    /**
     * Change the name of the additional field when it is updated in the ui.
     * splittedIdentifier[0] represent the field mapping type
     * splittedIdentifier[1] represent the index in the field mapping values arr
     */
    $scope.onAdditionalFieldMappingChanged = function (selectedField, selectedFieldIdentifier) {
        const splittedIdentifier = selectedFieldIdentifier.split('.');
        if (
            $scope.data.incomingWebhook.definition.additionalFieldMappings[splittedIdentifier[0]] &&
            $scope.data.incomingWebhook.definition.additionalFieldMappings[splittedIdentifier[0]][splittedIdentifier[1]]
        ) {
            $scope.data.incomingWebhook.definition.additionalFieldMappings[splittedIdentifier[0]][
                splittedIdentifier[1]
            ].name = selectedField.name;
        }
    };

    $scope.onEncryptedFieldSelected = function (selectedField, selectedFieldIdentifier) {
        if ($scope.data.incomingWebhook.definition.encryptedFieldsPaths[selectedFieldIdentifier]) {
            $scope.data.incomingWebhook.definition.encryptedFieldsPaths[selectedFieldIdentifier].name =
                selectedField.name;
        }
    };

    $scope.addFieldToEncrypt = function () {
        if (!$scope.data.incomingWebhook.definition.encryptedFieldsPaths) {
            $scope.data.incomingWebhook.definition.encryptedFieldsPaths = [];
        }

        const newElement = {
            id: utils.guid(),
            name: '',
            label: '',
        };

        $scope.data.incomingWebhook.definition.encryptedFieldsPaths.push(newElement);
    };

    $scope.removeFieldToEncrypt = function (index) {
        $scope.data.incomingWebhook.definition.encryptedFieldsPaths.splice(index, 1);
    };

    /**
     * load webhook entities
     */
    $scope.loadWebhookEntities = function () {
        $scope.data.loadingWebhookEntities = true;
        $scope.data.webhookEntities = null;
        return tonkeanService
            .importInitiatives(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.projectIntegration.integration.integrationUniqueType,
                null,
                projectManager.groupsMap[projectManager.groupDefaultId].publishedWorkflowVersionId,
                true,
            )
            .then(function (data) {
                $scope.data.webhookEntities = data.entities;
                $scope.data.loadingWebhookEntities = false;
                $scope.data.webhookEntitiesLastUpdate = new Date();
            })
            .catch(function (error) {
                $scope.data.error = error;
                $scope.data.loadingWebhookEntities = false;
            });
    };

    /**
     * load single webhook item json
     */
    $scope.loadWebhookDataLogJson = function (item) {
        if (!$scope.data.webhookDataLogJSONLoading) {
            $scope.data.webhookDataLogJSON = ' ';
            $scope.data.webhookDataLogJSONLoading = true;
            tonkeanService
                .getExternalActivityById(projectManager.project.id, $scope.data.projectIntegration.id, item.id)
                .then(function (data) {
                    $scope.data.webhookDataLogJSON = JSON.stringify(data.originalEntity, null, '\t');
                })
                .finally(function () {
                    $scope.data.webhookDataLogJSONLoading = false;
                });
        }
    };

    /**
     * Softly refreshes the incoming webhook's parameters (only the ones who won't possibly override the user's inputs).
     */
    $scope.refreshEditMode = function () {
        // This function is only allowed to run in edit mode (which guarantees us an incoming webhook exists).
        if ($scope.data.editMode && $scope.data.incomingWebhook) {
            $scope.data.loadingWebhookRefresh = true;

            reloadFieldSelectorsOptions();
            tonkeanService
                .getIncomingWebhookByProjectIntegrationId(projectManager.project.id, $scope.data.projectIntegration.id)
                .then(function (data) {
                    // Update the project integration with the fresh one we got.
                    $scope.data.projectIntegration = data.projectIntegration;

                    // Only update incoming webhook properties that won't override the user's changes (that might have been set but not yet saved).
                    const incomingWebhook = parseIncomingWebhookForClient(data.incomingWebhook);
                    $scope.data.incomingWebhook.firstItemReceived = incomingWebhook.firstItemReceived;
                    $scope.data.incomingWebhook.firstItemReceivedSuccessfully =
                        incomingWebhook.firstItemReceivedSuccessfully;
                    $scope.data.incomingWebhook.firstItemError = incomingWebhook.firstItemError;
                })
                .catch(function (error) {
                    handleApiError(error);
                })
                .finally(function () {
                    $scope.data.loadingWebhookRefresh = false;
                });
        }
    };

    $scope.saveManuallyPastedJson = function (text) {
        $scope.data.errorManualJson = null;
        if (!$scope.data.sendingManualJson) {
            $scope.data.sendingManualJson = true;
            try {
                // validate json
                const json = JSON.parse(text);
                if (!json) {
                    $scope.data.errorManualJson = 'Invalid JSON.';
                    $scope.data.sendingManualJson = false;
                    return;
                }

                $http.post($scope.data.incomingWebhook.url, json).finally(function () {
                    // sending the json and trying to re-load
                    $scope.pollWebhookEntities();
                    $scope.data.sendingManualJson = false;
                });
            } catch {
                $scope.data.errorManualJson = 'Invalid JSON.';
                $scope.data.sendingManualJson = false;
            }
        }
    };

    $scope.generateEncryptionKey = function () {
        const secret = new Uint8Array(24);
        window.crypto.getRandomValues(secret);
        $scope.data.incomingWebhook.definition.encryptionKeyDummy = btoa(String.fromCharCode.apply(null, secret));
    };

    /**
     * Polling the server to see if there are any indexed items in the incoming webhook we just selected.
     */
    function getIncomingWebhookStatus(projectIntegration) {
        return tonkeanService
            .getIncomingWebhookByProjectIntegrationId(projectManager.project.id, projectIntegration.id)
            .then(function (data) {
                // If a first item was received by the server (the server holds metadata).
                $scope.data.webhookReceivedItems = data && data.incomingWebhook;

                // If an item was received.
                if ($scope.data.webhookReceivedItems) {
                    $scope.data.incomingWebhook = parseIncomingWebhookForClient(data.incomingWebhook);
                    $scope.data.projectIntegration = data.projectIntegration;
                    // We received items. Go forward to edit mode.
                    setEditMode();

                    updateCachedProjectIntegration($scope.data.projectIntegration);

                    // We didn't have any errors - we got an item with valid JSON mapping.
                    if (!data.incomingWebhook.firstItemError) {
                        $scope.data.projectIntegration.disabled = false;
                    }
                }
            })
            .finally(function () {
                if (!$scope.data.webhookReceivedItems) {
                    // Try to cancel the current timeout (if it exists) before setting a new timeout.
                    if (getIncomingWebhookStatusPromise) {
                        $timeout.cancel(getIncomingWebhookStatusPromise);
                    }

                    // Only call the timeout if we haven't reached the time limit.
                    const now = Date.now();
                    if (now < $scope.data.getIncomingWebhookStatusTimeLimit) {
                        getIncomingWebhookStatusPromise = $timeout(
                            getIncomingWebhookStatus,
                            5000,
                            false,
                            projectIntegration,
                        );
                    } else {
                        $scope.data.getIncomingWebhookStatusTimeout = true;
                    }
                }
            });
    }

    /**
     * Notifies about a new integration added or updated.
     */
    function notifyIntegrationCreatedOrUpdated() {
        const data = {
            createdProjectIntegration: $scope.data.projectIntegration,
            integration: $scope.data.projectIntegration,
            integrations: [
                {
                    integration: $scope.data.projectIntegration,
                },
            ],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(data);
        }
    }

    /**
     * Creates a new webhook.
     */
    function createWebhook() {
        analyticsWrapper.track('Creating a webhook', { category: 'Webhook' });
        window.Intercom('trackEvent', 'Create Webhook');

        $scope.data.errorMessage = null;
        $scope.data.loadingWebhook = true;

        // Get the webhook's icon url (if a source was selected).
        const webhookIconUrl =
            $scope.data.webhookSource && $scope.data.webhookSource.imgUrl ? $scope.data.webhookSource.imgUrl : null;

        $scope.data.incomingWebhook = parseIncomingWebhookForServer($scope.data.incomingWebhook);

        if ($scope.data.sourceType === 'manual') {
            $scope.data.incomingWebhook.definition = {
                idFieldPath: 'id',
                titleFieldPath: 'id',
            };
        }

        return tonkeanService
            .createIncomingWebhook(
                projectManager.project.id,
                $scope.data.incomingWebhook.displayName,
                $scope.data.incomingWebhook.definition,
                webhookIconUrl,
            )
            .then(function (data) {
                $scope.data.incomingWebhook = parseIncomingWebhookForClient(data.incomingWebhook);
                $scope.data.projectIntegration = data.projectIntegration;
                $scope.data.displayEditModeSettings = false;

                // Adding the newly created project integration to the project's integrations
                projectManager.project.integrations.push(data.projectIntegration);
                entityHelper.enrichEntity(projectManager.project);

                if ($scope.data.sourceType === 'manual') {
                    $state.go(
                        'product.enterpriseComponents',
                        {
                            tab: 'data-sources',
                            projectId: projectManager.project.id,
                            prin: $scope.data.projectIntegration.id,
                        },
                        { reload: true },
                    );
                    notifyIntegrationCreatedOrUpdated();
                } else {
                    // Polling to see if we received any item from the webhook
                    $scope.pollWebhookEntities();
                }
            })
            .catch(function (error) {
                handleApiError(error);
            })
            .finally(function () {
                $scope.data.loadingWebhook = false;
            });
    }

    function isWebhookValid() {
        if (
            $scope.data.incomingWebhook.definition?.encryptionKeyDummy?.length > 0 &&
            $scope.data.incomingWebhook.definition?.encryptionKeyDummy?.length !== $scope.data.encryptionKeyLength
        ) {
            $scope.data.encryptionKeyValidationError = `Encryption key must have ${$scope.data.encryptionKeyLength} chars`;
            return false;
        }
        return true;
    }

    /**
     * Updates the existing webhook.
     */
    function updateWebhook() {
        if (!isWebhookValid()) {
            return;
        }

        analyticsWrapper.track('Updating a webhook', { category: 'Webhook' });

        $scope.data.loadingWebhook = true;
        $scope.data.errorMessage = null;
        $scope.data.incomingWebhook.firstItemError = null;

        // Create a copy of the webhook so we don't make the UI ugly while parsing and saving.
        let incomingWebhookCopy = angular.copy($scope.data.incomingWebhook);
        incomingWebhookCopy.definition.additionalFieldMappings = angular.copy(
            $scope.data.incomingWebhook.definition.additionalFieldMappings,
        );
        incomingWebhookCopy = parseIncomingWebhookForServer(incomingWebhookCopy);

        incomingWebhookCopy.definition.outgoingConfiguration = {
            baseUrl: $scope.data.baseUrl,
            authorizationType: $scope.data.authorizationTypeSelected,
            headersArray: $scope.data.headersArray,
        };

        incomingWebhookCopy.definition.enrichedFieldsForPickOff =
            incomingWebhookCopy.definition.enrichedFieldsForPickOff.filter(
                (mapping) => !utils.isNullOrEmpty(mapping.fieldName) && !utils.isNullOrEmpty(mapping.jsonPath),
            );

        incomingWebhookCopy.definition.encryptedFieldsPaths = angular
            .copy($scope.data.incomingWebhook.definition.encryptedFieldsPaths)
            .filter((encryptedField) => !utils.isNullOrEmpty(encryptedField.name))
            .map((encryptedField) => encryptedField.name);

        const saveParametersPromise = $scope.data.saveEnterpriseComponentVariableRef.current?.() || Promise.resolve();

        const saveWebhookConfigurationPromise = tonkeanService.updateIncomingWebhook(
            incomingWebhookCopy.id,
            incomingWebhookCopy.displayName,
            incomingWebhookCopy.definition,
            $scope.data.projectIntegration.updatableExternalActivities,
        );

        return Promise.all([saveWebhookConfigurationPromise, saveParametersPromise])
            .then(function ([webhookData]) {
                $scope.data.incomingWebhook = parseIncomingWebhookForClient(webhookData.incomingWebhook);
                $scope.data.projectIntegration = webhookData.projectIntegration;
                $scope.data.editMode = true;

                updateCachedProjectIntegration($scope.data.projectIntegration);

                notifyIntegrationCreatedOrUpdated();
            })
            .catch(function (error) {
                handleApiError(error);
            })
            .finally(function () {
                $scope.data.loadingWebhook = false;
            });
    }

    $scope.clear = function (headerRow) {
        headerRow.value = '';
        headerRow.isEncrypted = false;
        headerRow.serverEncrypted = false;
    };

    $scope.httpHeaderValueChanged = function (headerRow) {
        headerRow.isDirty = true;
    };

    /**
     * Initializes the edit mode if needed.
     */
    function initializeEditMode(state) {
        $scope.data.loadingEditMode = true;
        const projectIntegration = state.integrations && state.integrations.length ? state.integrations[0] : state;

        tonkeanService
            .getIncomingWebhookByProjectIntegrationId(projectManager.project.id, projectIntegration.id)
            .then(function (data) {
                $scope.data.incomingWebhook = parseIncomingWebhookForClient(data.incomingWebhook);
                $scope.data.projectIntegration = data.projectIntegration;

                if ($scope.data.incomingWebhook) {
                    setEditMode();
                    updateCachedProjectIntegration($scope.data.projectIntegration);
                } else {
                    $scope.pollWebhookEntities();
                }

                // If there are no JSON path configuration, we creates an empty one.
                if (!$scope.data.incomingWebhook.definition?.enrichedFieldsForPickOff?.length) {
                    $scope.data.incomingWebhook.definition.enrichedFieldsForPickOff = [{ fieldName: '', jsonPath: '' }];
                }

                if (data?.incomingWebhook?.definition?.outgoingConfiguration) {
                    const outgoingConfiguration = data.incomingWebhook.definition.outgoingConfiguration;
                    $scope.data.authorizationTypeSelected = outgoingConfiguration.authorizationType;
                    $scope.data.headersArray = outgoingConfiguration.headersArray.map((httpHeader) => {
                        return {
                            ...httpHeader,
                            serverEncrypted: httpHeader.isEncrypted,
                            fetchedFromServer: true,
                            isDirty: false,
                        };
                    });
                    $scope.data.baseUrl = outgoingConfiguration.baseUrl;
                }

                if ($scope.data?.incomingWebhook?.definition?.encryptionKeyDummy) {
                    $scope.data.encryptionKeyLocked = true;
                }
            })
            .catch(function (error) {
                handleApiError(error);
            })
            .finally(function () {
                $scope.data.loadingEditMode = false;
            });
    }

    /**
     * Finds the given updated project integration in the pm.integrations and updates its display name and disabled mode.
     * @param updatedProjectIntegration - the updated project integration to take the updated data from.
     */
    function updateCachedProjectIntegration(updatedProjectIntegration) {
        projectIntegrationManager.updateCachedProjectIntegration(projectManager.project, updatedProjectIntegration);
    }

    /**
     * Sets edit mode as true, and marks all the other needed flags.
     */
    function setEditMode() {
        $scope.data.sourceType = 'webhook';
        $scope.data.editMode = true;
    }

    /**
     * Creates a new field option object.
     * @param value - the value of the field option.
     */
    function createFieldOption(value) {
        return { name: value, label: value };
    }

    /**
     * Converts all the webhook definition properties to strings (they can be field option objects).
     * In case of additional field mappings copy the inner elemnt of the array
     * @param webhook - the webhook containing a definition that contains the properties to parse.
     */
    function parseIncomingWebhookForServer(webhook) {
        if (webhook && webhook.definition) {
            const definition = webhook.definition;
            for (const property in definition) {
                if (property === 'additionalFieldMappings') {
                    if (webhook.definition[property]) {
                        for (const fieldPath in webhook.definition[property]) {
                            if (webhook.definition[property].hasOwnProperty(fieldPath)) {
                                const valuesArr = [];
                                // Copy each of the definition field mappings element by element, their name or label
                                for (let j = 0; j < webhook.definition[property][fieldPath].length; j++) {
                                    const value =
                                        webhook.definition[property][fieldPath][j].name ||
                                        webhook.definition[property][fieldPath][j].label;
                                    if (value) {
                                        valuesArr.push(value);
                                    }
                                }

                                if (valuesArr.length) {
                                    webhook.definition[property][fieldPath] = valuesArr;
                                } else {
                                    //  If the arr is empty delete it to clear the data you send to the server
                                    delete webhook.definition[property][fieldPath];
                                }
                            }
                        }
                    }
                    break;
                }
                // If the property is an object and not a string, convert it.
                if (definition.hasOwnProperty(property) && definition[property] instanceof Object) {
                    definition[property] = definition[property].name || definition[property].label;
                }
            }
        }

        return webhook;
    }

    /**
     * Converts all the webhook definition properties to field option objects so they are ready to be used by tnkFieldSelector.
     * Also sets the data.showWebhookFirstItemError parameter according to the id and title fields status.
     * @param webhook - the webhook containing a definition that contains the properties to parse.
     */
    function parseIncomingWebhookForClient(webhook) {
        if (webhook) {
            // If we the incoming webhook had an error with parsing the first item and idFieldPath and titleFieldPath were never configured,
            // we should not display the error message. The user should get a chance to configure them first.
            if (
                webhook.firstItemError &&
                webhook.definition &&
                !webhook.definition.idFieldPath &&
                !webhook.definition.titleFieldPath
            ) {
                $scope.data.hideWebhookFirstItemError = true;
            } else {
                $scope.data.hideWebhookFirstItemError = false;
            }

            // Parse all the incoming webhook's definition properties.
            if (webhook.definition) {
                for (const property in webhook.definition) {
                    if (property === 'additionalFieldMappings') {
                        if (webhook.definition[property]) {
                            for (const fieldPath in webhook.definition[property]) {
                                if (webhook.definition[property].hasOwnProperty(fieldPath)) {
                                    for (let j = 0; j < webhook.definition[property][fieldPath].length; j++) {
                                        webhook.definition[property][fieldPath][j] = createFieldOption(
                                            webhook.definition[property][fieldPath][j],
                                        );
                                    }
                                }
                            }
                        }
                        break;
                    }

                    const skipTheseFields = ['outgoingConfiguration', 'encryptionKeyDummy', 'concatAdditionalIds'];
                    // If the property is not an object use its value.
                    if (
                        webhook.definition.hasOwnProperty(property) &&
                        !(property instanceof Object) &&
                        !skipTheseFields.find((s) => s === property)
                    ) {
                        webhook.definition[property] = createFieldOption(webhook.definition[property]);
                    }
                }
            }

            if (!webhook?.definition?.encryptedFieldsPaths?.name?.length) {
                webhook.definition.encryptedFieldsPaths = [
                    {
                        id: utils.guid(),
                        name: '',
                        label: '',
                    },
                ];
            } else {
                webhook.definition.encryptedFieldsPaths = webhook.definition.encryptedFieldsPaths.name.map(
                    (encryptedFieldPath) => {
                        return {
                            id: utils.guid(),
                            name: encryptedFieldPath,
                            label: '',
                        };
                    },
                );
            }
        }

        return webhook;
    }

    /**
     * Tells the field selector it's time to reload its field options.
     */
    function reloadFieldSelectorsOptions() {
        // We should first set to false and then to true, so our tnkFieldSelectors recognize a change.
        // We set to true using the $timeout service, so it happens in the next digest. Otherwise our toggle is meaningless.
        $scope.data.reloadFieldSelectorOptions = false;
        $timeout(function () {
            $scope.data.reloadFieldSelectorOptions = true;
        });
    }

    /**
     * Cancels the polling and sets the polling time limit to the past.
     */
    function cancelPolling() {
        $scope.data.getIncomingWebhookStatusTimeLimit = Date.now() - 1000;
        if (getIncomingWebhookStatusPromise) {
            $timeout.cancel(getIncomingWebhookStatusPromise);
        }
    }

    /**
     * Handles an error response from an API call and writes it to the UI.
     * @param error - the error response from the API call.
     */
    function handleApiError(error) {
        $scope.data.errorMessage = error;

        if (error && error.data && error.data.error && error.data.error.message) {
            $scope.data.errorMessage = error.data.error.message;
        }
    }
}

export default angular.module('tonkean.shared').controller('WebhookModalCtrl', WebhookModalCtrl);
