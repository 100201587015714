import React from 'react';

import EnterpriseComponentAuditLogMainPage from './EnterpriseComponentAuditLogMainPage';
import type { EnterpriseComponentMenuItemPageContentProps } from '../../../../../entities/EnterpriseComponentMenuItemPageContentProps';
import AuditLogType, { getUserActionAuditLogDescription } from '../../../entities/AuditLogType';

import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';

const EnterpriseComponentPageAuditLog = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponent,
    enterpriseComponentType,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    return (
        <EnterpriseComponentAuditLogMainPage
            enterpriseComponent={enterpriseComponent}
            enterpriseComponentType={enterpriseComponentType}
            logType={AuditLogType.USER_ACTION}
            description={getUserActionAuditLogDescription(enterpriseComponentType)}
        />
    );
};

export default EnterpriseComponentPageAuditLog;
