import React, { useCallback } from 'react';
import styled from 'styled-components';

import { toSavingState } from '../../../../infrastructure/utils/toSavingState';
import PeoplePermissionsPicker from '../PeoplePermission/PeoplePermissionsPicker';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { SavingIndicator } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { ConnectionPermission } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledLabel = styled.div`
    color: ${Theme.colors.gray_500};
    margin-bottom: 16px;
    margin-top: 40px;
`;

const StyledPermissionsWrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
`;

const StyledPeoplePermissionsPicker = styled(PeoplePermissionsPicker)`
    width: 400px;
`;

interface Props {
    onChange: (connectionPermissionType: ConnectionPermission, authorizedPeopleIds: string[]) => void;
    connectionPermissionsType?: ConnectionPermission;
    authorizedPeopleIds?: string[];
}

const OtherDataSourceConnectionsPermissions: React.FC<Props> = ({
    onChange,
    connectionPermissionsType,
    authorizedPeopleIds = [],
}) => {
    const project = useProject();
    const [
        {
            error: errorUpdateProjectDataSourceConnectionsSettings,
            loading: loadingUpdateProjectDataSourceConnectionsSettings,
            called: calledUpdateProjectDataSourceConnectionsSettings,
        },
        updateProjectDataSourceConnectionsSettings,
    ] = useLazyTonkeanService('updateProjectDataSourceConnectionsSettings');

    const { data: authorizables } = useTonkeanService('getAuthorizablesByIds', project.id, authorizedPeopleIds);

    const onPermissionsChanged = useCallback(
        async (changedConnectionPermission, changedAuthorizedUsers) => {
            const usersIds = changedAuthorizedUsers.map((person) => person.id);
            const updateProjectResponse = await updateProjectDataSourceConnectionsSettings(
                project.id,
                changedConnectionPermission,
                usersIds,
            );

            // Only if we succeed we update the main component of the change
            if (updateProjectResponse.status === 200) {
                onChange(changedConnectionPermission, usersIds);
            }
        },
        [updateProjectDataSourceConnectionsSettings, onChange, project.id],
    );

    const savingState = toSavingState(
        loadingUpdateProjectDataSourceConnectionsSettings,
        errorUpdateProjectDataSourceConnectionsSettings,
        calledUpdateProjectDataSourceConnectionsSettings,
    );

    return (
        <>
            <StyledLabel>Who can connect all other supported data source types</StyledLabel>

            <StyledPermissionsWrapper>
                {authorizables && (
                    <StyledPeoplePermissionsPicker
                        connectionPermission={connectionPermissionsType}
                        authorizedPeople={authorizables}
                        onChange={onPermissionsChanged}
                        noOneLabel="No one except board admins"
                    />
                )}

                <SavingIndicator state={savingState} />
            </StyledPermissionsWrapper>
        </>
    );
};

export default OtherDataSourceConnectionsPermissions;
