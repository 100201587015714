<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Create Import Task Using Upload File From Storage Provider or Url -->
        <div ng-if="data.definition.customActionKey === 'CREATE_IMPORT_FILE_TASK'" class="margin-bottom-xlg">
            <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                parameters-values="data.definition.parametersValues"
                on-worker-file-selection-changed="
                    onWorkerFileSelectionChanged(
                        fileSourceType,
                        storageIntegrationId,
                        extendedMatchConfiguration,
                        urlExpression,
                        workerStaticAssetId,
                        workerStaticAssetDisplayName,
                        parametersValues,
                        shouldSaveLogic,
                        storageIntegrationActionId
                    )
                "
            ></tnk-worker-file-selection>

            <div
                ng-if="data.validationObject.invalidFileChoice"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.invalidFileChoice }}
            </div>

            <div>
                <tnk-checkbox
                        class="margin-top-xs"
                        label="'Encode file using Base64'"
                        value="data.definition.base64Encoded"
                        on-toggle-async="onDefinitionChanged(true)"
                ></tnk-checkbox>
            </div>
        </div>

        <!-- Create Merge Task From Other Import Tasks -->
        <div
            ng-if="
                data.definition.customActionKey === 'CREATE_MERGE_TASK' ||
                data.definition.customActionKey === 'CREATE_EXPORT_URL_TASK'
            "
            class="margin-bottom-xlg"
        >
            <!-- Task IDs -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Input Task IDs:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="The ID of the import tasks to merge separated by comma."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Task IDs separated by comma"
                    saved-expression="data.definition.cloudConvertTaskIdsExpression"
                    expression-unique-identifier="CloudConvertTaskIds"
                    on-tonkean-expression-changed="onCloudConvertTaskIdsExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>
        <div class="margin-top-6 common-error" ng-if="data.validationObject.cloudConvertTaskIdsError">
            {{ data.validationObject.cloudConvertTaskIdsError }}
        </div>

        <!-- Create Merge Task From Other Import Tasks -->
        <div ng-if="data.definition.customActionKey === 'CREATE_MERGE_TASK'" class="margin-bottom-xlg">
            <!-- Output Format -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Output Format:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="The target format to convert to. For now, only PDF is supported."
                    ></i>
                </div>

                <input
                    type="text"
                    class="form-control"
                    ng-model="data.definition.cloudConvertOutputFormat"
                    ng-change="onDefinitionChanged(true)"
                    readonly
                />
            </div>

            <!-- New Document Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    File Name:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Choose a filename (including extension) for the output file (optional)."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="File Name"
                    saved-expression="data.definition.cloudConvertFileNameExpression"
                    expression-unique-identifier="CloudConvertFileName"
                    on-tonkean-expression-changed="onCloudConvertFileNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Timeout in seconds -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    Timeout:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Timeout in seconds after the task will be cancelled (optional)."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Timeout in seconds"
                    saved-expression="data.definition.cloudConvertTimeoutExpression"
                    expression-unique-identifier="CloudConvertTimeout"
                    on-tonkean-expression-changed="onCloudConvertTimeoutExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Create Export URL Task From Other Tasks -->
        <div ng-if="data.definition.customActionKey === 'CREATE_EXPORT_URL_TASK'" class="margin-bottom-xlg">
            <!-- Archive Multiple Files -->
            <div class="margin-top">
                <tnk-checkbox
                    class="margin-top-xs"
                    label="'Archive Multiple Files'"
                    value="data.definition.cloudConvertArchiveMultipleFiles"
                    on-toggle-async="onDefinitionChanged(true)"
                ></tnk-checkbox>
            </div>
        </div>
    </div>
</div>
