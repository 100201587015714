export class FieldChartWidgetConstants {
    static readonly TERMS = 'Terms';
    static readonly STRUCTURED = 'STRUCTURED';
    static readonly AGGREGATE_QUERY = 'AGGREGATE_QUERY';
    static readonly NO_VALUE_LABEL = 'No value';
    static readonly COLORS: string[] = [
        '#F3722C',
        '#90BE6D',
        '#F94144',
        '#F9C74F',
        '#43AA8B',
        '#F8961E',
        '#577590',
        '#82377C',
        '#A05B06',
        '#89B5D2',
        '#AE2E30',
        '#4B1414',
        '#739857',
        '#49220D',
        '#682C63',
        '#135599',
        '#B25878',
        '#9C3694',
        '#2F84DC',
        '#D5DCA8',
        '#5EE2F4',
        '#7A7A7A',
        '#0AD2D2',
        '#817FC8',
    ];
}
