import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useState } from 'react';
import type { FormEvent } from 'react';

import DocuSignAccountType from './DocuSignAccountType';
import type { DocuSignProjectIntegrationData } from './DocuSignProjectIntegrationData';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Radio } from '@tonkean/infrastructure';
import { RadioGroup } from '@tonkean/infrastructure';
import { Field } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const DocuSignAuthenticationComponent: React.FC<
    CustomizedAuthenticationComponentProps<DocuSignProjectIntegrationData>
> = ({ authenticate, reportError, integrationConfiguration, projectIntegrationData }) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [accountType, setAccountType] = useState<string>(
        projectIntegrationData?.accountType || DocuSignAccountType.SANDBOX,
    );
    const [internalProjectIntegrationData, setInternalProjectIntegrationData] =
        useState<DocuSignProjectIntegrationData>(projectIntegrationData || { selectedAccount: undefined, accountType });
    useEffect(() => {
        setInternalProjectIntegrationData((prev) => {
            return { ...prev, accountType };
        });
    }, [accountType]);
    const accountTypes = {
        [DocuSignAccountType.SANDBOX]: {
            authSubdomain: 'account-d',
            apiSubDomain: 'demo',
            displayName: 'Sandbox',
        },
        [DocuSignAccountType.PRODUCTION]: {
            authSubdomain: 'account',
            apiSubDomain: 'www',
            displayName: 'Production',
        },
    };

    const authenticateWithOAuth = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            const clientId = environment.integrationKeysMap.docusignKey;
            const redirectUri = environment.integrationKeysMap.docusignUri;
            const state = oauthHandler.publicGetState();

            const url = `https://${accountTypes[accountType].authSubdomain}.docusign.com/oauth/auth?response_type=code&scope=signature%20extended%20impersonation&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}`;

            const code = await oauthHandler.startOAuth(url, state);

            const config = {
                code,
                redirectUri,
                authSubdomain: accountTypes[accountType].authSubdomain,
                apiSubdomain: accountTypes[accountType].apiSubDomain,
            };

            authenticate(
                config,
                internalProjectIntegrationData,
                `${integrationConfiguration.displayName} (${accountTypes[accountType].displayName})`,
            );
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col api-token-authentication">
                <div className="flex mod-center">
                    <Field label="Account Type:">
                        <RadioGroup
                            value={accountType}
                            direction="column"
                            onChange={setAccountType}
                            size={InputSize.MEDIUM}
                        >
                            <Radio value={DocuSignAccountType.SANDBOX}>
                                {accountTypes[DocuSignAccountType.SANDBOX].displayName}
                            </Radio>
                            <Radio value={DocuSignAccountType.PRODUCTION}>
                                {accountTypes[DocuSignAccountType.PRODUCTION].displayName}
                            </Radio>
                        </RadioGroup>
                    </Field>
                </div>

                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={authenticateWithOAuth}>
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocuSignAuthenticationComponent;
