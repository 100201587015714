/**
 * Debounces requests so only the first one is executed, but all receive the response.
 */
function RequestSimpleDebouncer($q, $log) {
    const _this = this;

    /**
     * A map of requestId to an array of promises. These promises will all get a response when the request is done.
     */
    const promiseMap = {};

    /**
     * A map of requestId to boolean, marking active debouncing in progress.
     */
    const activeDebounceMap = {};

    /**
     * Debounces the given serverAction so only the first request is executed while many requests are fired at once.
     * All requests will get the same response when the first one returns.
     * @param requestId - a unique id so that the debounce function can identify other identical requests.
     * @param serverAction - the function to run debounced.
     * @param actionArgs - arguments for the server action. Notice that you can pass any number of parameters to this function.
     * @returns {*}
     */
    _this.debounce = function (requestId, serverAction, ...actionArgs) {
        if (activeDebounceMap[requestId]) {
            $log.debug(`RequestSimpleDebouncer: Debouncing - ${requestId}`);

            // If the request id is already in progress, create a promise using $q.defer.
            const deferred = $q.defer();
            // Save it, so we can resolve/reject it when we're done.
            if (!promiseMap[requestId]) {
                promiseMap[requestId] = [];
            }
            promiseMap[requestId].push(deferred);
            // Return the deferred promise to the called so he will later get our response.
            return deferred.promise;
        } else {
            // This is a new request id.
            activeDebounceMap[requestId] = true;

            return serverAction(...actionArgs)
                .then((data) => {
                    // On success, also resolve all waiting promises.
                    doOnAllWaitingPromises(requestId, (promise) => promise.resolve(data));
                    // Also resolve the current promise with the data.
                    return $q.resolve(data);
                })
                .catch((error) => {
                    // On failure, also reject all waiting promises.
                    doOnAllWaitingPromises(requestId, (promise) => promise.reject(error));
                    // Also reject the current promise with the error data.
                    return $q.reject(error);
                })
                .finally(() => {
                    // Finally, clear the active flag and waiting promises.
                    activeDebounceMap[requestId] = false;
                    if (promiseMap[requestId]) {
                        delete promiseMap[requestId];
                    }
                });
        }
    };

    /**
     * Goes over all waiting promises of the given request id safely, and runs the given action.
     */
    function doOnAllWaitingPromises(requestId, action) {
        if (promiseMap[requestId]) {
            for (let i = 0; i < promiseMap[requestId].length; i++) {
                $log.debug(`RequestSimpleDebouncer: Responding to ${requestId} promise that was saved.`);
                action(promiseMap[requestId][i]);
            }
        }
    }
}

angular.module('tonkean.app').service('requestSimpleDebouncer', RequestSimpleDebouncer);
