<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group">
                <label for="login-api-sid" class="col-sm-4 control-label">API Key SID</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.sid"
                        class="form-control"
                        id="login-api-sid"
                        autocomplete="off"
                        placeholder="API SID"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>
            <div class="form-group">
                <label for="login-api-secret" class="col-sm-4 control-label">API Key Secret</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.secret"
                        class="form-control"
                        id="login-api-secret"
                        autocomplete="off"
                        placeholder="API Secret"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <div class="form-group flex mod-align-center">
                <label for="login-validate-signature" class="col-sm-4 control-label">Validate Signature?</label>

                <div class="col-sm-8">
                    <input
                        type="checkbox"
                        ng-model="data.shouldValidateSignature"
                        class="margin-none"
                        id="login-validate-signature"
                    />
                </div>
            </div>

            <div class="form-group" ng-if="data.shouldValidateSignature">
                <label for="login-auth-token" class="col-sm-4 control-label">Auth Token</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.authToken"
                        class="form-control"
                        id="login-auth-token"
                        autocomplete="off"
                        placeholder="Auth token"
                        required
                    />
                </div>

                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <div class="margin-bottom-lg info-box common-size-xxs">
                <p class="common-color-dark-grey">Setup instructions:</p>
                <div class="common-color-dark-grey">
                    Go to
                    <a target="_blank" href="https://www.twilio.com/console/runtime/api-keys/create">
                        Where to create an API key?
                    </a>
                    <br />
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <form novalidate>
        <div ng-if="selections" ng-form="data.integrationForm">
            <div ng-show="integration && !loading">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <h4>Which Twilio Chat - Services are relevant?</h4>
            <hr />
            <div class="margin-normal-v">
                <div ng-repeat="selection in selections" class="new-product-git-repo">
                    <div class="checkbox">
                        <label>
                            <input
                                type="checkbox"
                                ng-model="selection.selected"
                                ng-required="(selections|filter:{selected:true}:true).length===0"
                                ng-change="onServiceSelected(selection)"
                            />
                            <strong>{{ selection.service.displayName }}</strong>
                        </label>

                        <div class="common-subtitle-inner margin-normal-h">
                            <span>{{ selection.service.description }}</span>
                            <span ng-hide="selection.showEdit || !selection.selected">
                                <span ng-show="selection.service.description">&nbsp;|&nbsp;</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div>
                    <label>Workspace ID:</label>
                    <input ng-model="data.workspaceId" class="form-control" />
                </div>

                <!-- Webhook Management -->
                <div class="margin-top">
                    <div class="common-title-xs margin-bottom">Webhook Managements</div>
                    <div class="margin-bottom">
                        <input type="checkbox" ng-model="data.isManagingServiceWebhooks" />
                        <label class="margin-none">Override my Service webhooks</label>
                        <div class="common-color-light-grey common-size-xxxs">
                            This includes only the Post-Event webhooks of the selected chat service
                        </div>
                    </div>
                    <div class="margin-bottom">
                        <input type="checkbox" ng-model="data.isManagingWorkspaceWebhooks" />
                        <label>Override my Workspace Events</label>
                    </div>

                    <div ng-if="!data.isManagingWorkspaceWebhooks || !data.isManagingServiceWebhooks">
                        <div>
                            You can manage the Twilio Events on your own and keep us up to date at the following
                            endpoint:
                        </div>

                        <div class="alert alert-info" ng-if="!data.webhookUrlLoading">
                            <!-- Webhook exists-->
                            <div ng-if="data.incomingWebhookUrl">
                                <div class="btn-group input-group flex-vmiddle">
                                    <input
                                        id="twilio-webhook-url"
                                        ng-model="data.incomingWebhookUrl"
                                        class="form-control"
                                        readonly
                                    />

                                    <span class="input-group-btn display-table">
                                        <button
                                            ng-click="copyUrlToClipboard('twilio-webhook-url')"
                                            class="btn btn-default"
                                        >
                                            Copy
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <!-- Webhook doesnt exists-->
                            <div ng-if="!data.incomingWebhookUrl">
                                <div class="padding-normal-xs">
                                    <button class="btn btn-default center-block" ng-click="createWebhookEndpoint()">
                                        Create Endpoint
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="loading-medium" ng-if="data.webhookUrlLoading"></div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="common-size-xxxs">
        <div class="common-bold">Important Notes:</div>
        <div class="common-color-light-grey">
            <ol>
                <li class="margin-bottom-xs">
                    In order for us to stay up to date on your Twilio data we will
                    <strong>OVERRIDE</strong>
                    any existing webhooks you may have on the selected Programmable Chat service. We would also
                    <strong>OVERRIDE</strong>
                    the TaskRouter Events callback endpoint for the provided workspace
                </li>
                <li class="margin-bottom-xs">
                    If you are using Twilio Flex, make sure that in the studio any block that is "Send to Flex" is
                    sending these attributes:
                    <pre contenteditable="false" class="text-left">{{
                        '"serviceSid": "\{\{trigger.message.InstanceSid\}\}",\n"channelSid": "\{\{trigger.message.ChannelSid\}\}"'
                    }}</pre>
                </li>
            </ol>

            <div>For more information please contact our support team.</div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
