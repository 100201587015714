import React, { useCallback, useMemo } from 'react';

import SupportUserSettingsToggle from './SupportUserSettingsToggle';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';

const description =
    'By enabling remote support access, you allow the Tonkean Support team admin access to ' +
    'this board. Tonkean Support only accesses boards to address support needs and logs all actions.';

const BoardSupportUserAccess: React.FC = () => {
    const [projectId] = useGetStateParams('projectId');
    const { data: supportUserDisabledResponse, loading: loadingSupportUserAccess } = useTonkeanService(
        'isSupportUserDisabled',
        projectId,
    );
    const [{ data: supportUserDisabledUpdateResponse, loading: loadingUpdateSupportUser }, changeSupportUserDisabled] =
        useLazyTonkeanService('changeSupportUserDisabled');

    const updateBoardSettings = useCallback(
        // shouldDisable for support user is the opposite of isSupportUserPermitted
        (newIsSupportUserPermitted: boolean) => changeSupportUserDisabled(projectId, !newIsSupportUserPermitted),
        [projectId, changeSupportUserDisabled],
    );

    const response = useMemo(
        () => supportUserDisabledUpdateResponse || supportUserDisabledResponse,
        [supportUserDisabledResponse, supportUserDisabledUpdateResponse],
    );

    return response ? (
        <SupportUserSettingsToggle
            description={description}
            isSupportUserPermitted={!response.isSupportUserDisabled}
            onToggle={updateBoardSettings}
            loading={loadingSupportUserAccess || loadingUpdateSupportUser}
        />
    ) : null;
};

export default BoardSupportUserAccess;
