import { lateConstructController } from '@tonkean/angular-components';
import { ActionType } from '@tonkean/tonkean-entities';
import { getRelevantProjectIntegrations } from '@tonkean/tonkean-utils';

/* @ngInject */
const WorkerFileSelectionCtrl = function (
    $scope,
    integrations,
    utils,
    tonkeanUtils,
    projectManager,
    tonkeanService,
    customTriggerManager,
    $rootScope,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.matchedEntitiesFormatsOptions = [{ value: 'CSV', label: 'CSV' }];

    $scope.ctm = customTriggerManager;
    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        customTriggerId: ctrl.customTriggerId,
        shouldDisplayDeleteSign: ctrl.shouldDisplayDeleteSign,
        infoMessage: ctrl.infoMessage,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        fileSourceType: ctrl.fileSourceType || 'DATA_STORAGE',
        storageIntegrationId: ctrl.storageIntegrationId,
        storageIntegrationActionId: ctrl.storageIntegrationActionId,
        parametersValues: ctrl.parametersValues || {},
        extendedMatchConfiguration: ctrl.extendedMatchConfiguration || {
            extendedMatchConfigurationType: 'WORKFLOW',
            matchConfiguration: {
                creatingCustomTriggerId: null,
                idRelationFieldDefinitionId: null,
                matchOption: 'SPECIFIC_ITEM',
                performOnWorkerItem: true,
            },
        },
        urlExpression: ctrl.urlExpression || {
            originalExpression: null,
            evaluatedExpression: null,
        },
        workerStaticAssetId: ctrl.workerStaticAssetId,
        workerStaticAssetDisplayName: ctrl.workerStaticAssetDisplayName,

        fileEntityMetadata: tonkeanUtils.getFileEntityMetadata(),
        storageIntegrationTypes: integrations.getSupportedDownloadingStorageIntegrations(),

        storageIntegration: null,
        fileBeingUploadedName: null,
        uploadingAsset: false,
        errorUploadingAsset: false,

        attachmentIndex: ctrl.attachmentIndex,

        matchedEntitiesFormats: $scope.matchedEntitiesFormatsOptions,
        selectedFormatObject: $scope.matchedEntitiesFormatsOptions[0],

        shouldAddMatchedEntityOption: ctrl.shouldAddMatchedEntityOption,
        matchEntityConfiguration: ctrl.matchEntityConfiguration || {
            matchedEntityId: '',
            format: 'CSV',
            columnsTemplates: [],
            isUnlimitedRows: false,
            rowsLimit: Math.min($scope.pm.projectData.maxAllowedExportDataSize, 10),
        },

        isVerticalRadioBoxes: ctrl.isVerticalRadioBoxes || false,
        validationObject: ctrl.validationObject || {},

        maximumDefaultExportDataLimit: $scope.pm.projectData.maxAllowedExportDataSize,

        allowedUnlimitedExportData:
            $rootScope.features[projectManager.project.id].tonkean_feature_allow_unlimited_export_data,
        actionTypesToIncludeInSelector: [ActionType.DOWNLOAD, ]
    };

    /**
     * Initialization function.
     */
    ctrl.$onInit = function () {
        const relevantProjectIntegrations = getRelevantProjectIntegrations(
            projectManager.project,
            projectManager.groupsMap[$scope.data.groupId],
            $scope.data.storageIntegrationTypes,
        );
        $scope.data.storageIntegration = utils.findFirst(
            relevantProjectIntegrations,
            (projectIntegration) => projectIntegration.id === $scope.data.storageIntegrationId,
        );
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.validationObject) {
            $scope.data.validationObject = changesObj.validationObject.currentValue;
        }
    };

    /**
     * On changing format in matched entities option
     */
    $scope.onFormatChanged = function (selectedFormat) {
        $scope.data.matchEntityConfiguration.format = selectedFormat.value;
        $scope.data.selectedFormatObject = selectedFormat;
    };

    /**
     * On pick matched entity option
     */
    $scope.onMatchedEntitySelected = function (selectedMatchedEntity) {
        $scope.data.matchEntityConfiguration.matchedEntityId = selectedMatchedEntity.id;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Occurs on selection of radio button value.
     */
    $scope.selectFileSource = function (selectedFileSource) {
        $scope.data.fileSourceType = selectedFileSource;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Occurs on the selection of a project integration.
     */
    $scope.onProjectIntegrationSelected = function (storageIntegration) {
        $scope.data.storageIntegration = storageIntegration;
        $scope.data.storageIntegrationId = storageIntegration.id;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Occurs on the selection of a project integration.
     */
    $scope.onProjectIntegrationActionSelected = function (storageIntegrationAction) {
        $scope.data.storageIntegrationActionId = storageIntegrationAction;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Occurs on the change of the extended match configuration.
     */
    $scope.onExtendedMatchConfigurationChanged = function (extendedMatchConfiguration, shouldSaveLogic) {
        $scope.data.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onWorkerFileSelectionChanged(shouldSaveLogic);
    };

    /**
     * Occurs on the change of file url expression.
     */
    $scope.onFileDownloadUrlTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.urlExpression = { originalExpression, evaluatedExpression };
        $scope.onWorkerFileSelectionChanged(shouldSaveLogic);
    };

    /**
     * Occurs on a click on the delete sign.
     */
    $scope.deleteSignClicked = function () {
        if (ctrl.onDeleteSignClicked) {
            ctrl.onDeleteSignClicked({});
        }
    };

    /**
     * Occurs on the selection of a file.
     */
    $scope.onFileChange = function (event) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onloadend = (event) => {
            $scope.$apply(() => {
                $scope.data.fileBeingUploadedName = file.name;
                uploadWorkerStaticAsset(event.target.result, file.name, file.type);
            });
        };
        reader.readAsArrayBuffer(file);
    };

    /**
     * Add new destination column.
     */
    $scope.addDestinationMatchedEntityParam = function () {
        $scope.data.matchEntityConfiguration.columnsTemplates.push({
            name: '',
            value: {
                originalExpression: '',
                evaluatedExpression: '',
            },
        });
    };

    /**
     * Occurs on a change of an expression of a value.
     */
    $scope.onTonkeanExpressionValueChanged = function (expression, expressionIdentifier, shouldSaveLogic) {
        $scope.data.matchEntityConfiguration.columnsTemplates[expressionIdentifier].value = expression;
        $scope.onWorkerFileSelectionChanged(shouldSaveLogic);
    };

    $scope.onStorageConfigurationDynamicParametersChanged = function (storageConfigurationParameters) {
        $scope.data.parametersValues = storageConfigurationParameters;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Uploads a worker static asset.
     */
    function uploadWorkerStaticAsset(fileBytes, fileName, contentType) {
        $scope.data.uploadingAsset = true;
        $scope.data.errorUploadingAsset = false;

        tonkeanService
            .uploadWorkerStaticAsset($scope.data.groupId, fileBytes, fileName, $scope.data.customTriggerId, contentType)
            .then((data) => {
                $scope.data.workerStaticAssetId = data.createdWorkerStaticAssetId;
                $scope.data.workerStaticAssetDisplayName = fileName;
                $scope.onWorkerFileSelectionChanged(true);
            })
            .catch(() => {
                $scope.data.errorUploadingAsset = true;
            })
            .finally(() => {
                $scope.data.uploadingAsset = false;
            });
    }

    /**
     * Removes the asset from the trigger.
     */
    $scope.removeAsset = function () {
        $scope.data.workerStaticAssetId = null;
        $scope.data.workerStaticAssetDisplayName = null;
        $scope.onWorkerFileSelectionChanged(true);
    };

    /**
     * Occurs once the worker file selection object is changed.
     */
    $scope.onWorkerFileSelectionChanged = function (shouldSaveLogic) {
        if (ctrl.onWorkerFileSelectionChanged) {
            ctrl.onWorkerFileSelectionChanged({
                fileSourceType: $scope.data.fileSourceType,
                parametersValues: $scope.data.parametersValues,
                storageIntegrationId:
                    $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationId : undefined,
                extendedMatchConfiguration:
                    $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.extendedMatchConfiguration : undefined,
                urlExpression: $scope.data.fileSourceType === 'URL' ? $scope.data.urlExpression : undefined,
                workerStaticAssetId:
                    $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetId : undefined,
                workerStaticAssetDisplayName:
                    $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetDisplayName : undefined,
                shouldSaveLogic,
                storageIntegrationActionId:
                    $scope.data.fileSourceType === 'DATA_STORAGE' && $scope.data.storageIntegrationActionId !== ''
                        ? $scope.data.storageIntegrationActionId
                        : undefined,
                matchEntityConfiguration:
                    $scope.data.fileSourceType === 'MATCHED_ENTITIES'
                        ? $scope.data.matchEntityConfiguration
                        : undefined,
            });
        }
    };

    $scope.removeColumn = (index) => {
        $scope.data.matchEntityConfiguration.columnsTemplates.splice(index, 1);
        $scope.onWorkerFileSelectionChanged(true);
    };

    $scope.onChangeLimitNumber = () => {
        if (!$scope.data.matchEntityConfiguration.rowsLimit) {
            $scope.data.matchEntityConfiguration.rowsLimit = 0;
        }
        if ($scope.data.matchEntityConfiguration.rowsLimit < 0) {
            $scope.data.matchEntityConfiguration.rowsLimit = 0;
        } else if (
            !$scope.data.allowedUnlimitedExportData &&
            $scope.data.matchEntityConfiguration.rowsLimit >= $scope.data.maximumDefaultExportDataLimit
        ) {
            $scope.data.matchEntityConfiguration.rowsLimit = $scope.data.maximumDefaultExportDataLimit;
        }
        $scope.onWorkerFileSelectionChanged(true);
    };

    $scope.onChangeDelimiter = () => {
        if (!$scope.data.matchEntityConfiguration.delimiter) {
            $scope.data.matchEntityConfiguration.delimiter = ',';
        }
        $scope.onWorkerFileSelectionChanged(true);
    };

    $scope.onToggleIsUnlimitedRows = () => {
        if (!$scope.data.matchEntityConfiguration.isUnlimitedRows) {
            $scope.data.matchEntityConfiguration.rowsLimit = 0;
        }
        $scope.onWorkerFileSelectionChanged(true);
    };
};

export default angular.module('tonkean.app').controller('WorkerFileSelectionCtrl', lateConstructController(WorkerFileSelectionCtrl));
