import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { BoxContainer, IntegrationBox, IntegrationNameContainer } from './ProjectIntegrationBoxCommons';

import { TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';

const CenteredProjectIntegrationIcon = styled(ProjectIntegrationIcon)`
    margin: auto;
`;

interface Props {
    integrationType: string;
    onClick: (projectIntegrationType: string) => void;
}

const ThinProjectIntegrationBox: React.FC<Props> = ({ integrationType, onClick }) => {
    const consts = useAngularService('consts');
    const integrations = useAngularService('integrations');

    // On non project Integration we have other ways to display name and display icon.
    const nonProjectIntegrationActions: string[] = useMemo(() => {
        const outgoingWebhookType = consts.getLogicBlockTypes().OUTGOING_WEBHOOK.type;
        const httpUploadType = consts.getLogicBlockTypes().HTTP_UPLOAD.type;
        const storageUploadType = consts.getLogicBlockTypes().STORAGE_UPLOAD.type;
        return [outgoingWebhookType, httpUploadType, storageUploadType];
    }, [consts]);

    // On non project integration actions we have other way to display icon.
    const classOfSpecialIntegration = useMemo(() => {
        if (
            nonProjectIntegrationActions.some(
                (specialIntegration) => specialIntegration.toLowerCase() === integrationType.toLowerCase(),
            )
        ) {
            return consts.getLogicBlockTypes()[integrationType].iconClass;
        }
        return undefined;
    }, [consts, integrationType, nonProjectIntegrationActions]);

    // Memo of the project integration display name.
    const displayName = useMemo(() => {
        if (nonProjectIntegrationActions.includes(integrationType)) {
            return consts.getLogicBlockTypes()[integrationType].title;
        }
        return integrations.getIntegrationsConfig()[integrationType.toLowerCase()]?.displayName || integrationType;
    }, [consts, integrations, integrationType, nonProjectIntegrationActions]);

    return (
        <BoxContainer>
            <Tooltip content={displayName}>
                <IntegrationBox onClick={() => onClick(integrationType)}>
                    <CenteredProjectIntegrationIcon
                        className={classOfSpecialIntegration}
                        integrationType={integrationType}
                        width={25}
                    />
                </IntegrationBox>
            </Tooltip>

            <IntegrationNameContainer>
                <TextEllipsis numberOfLines={1} tooltip>
                    {displayName}
                </TextEllipsis>
            </IntegrationNameContainer>
        </BoxContainer>
    );
};

export default ThinProjectIntegrationBox;
