/**
 * Returns true if the module sync config is the same as our action's project integration.
 * For example, if our module is synced on google sheet but we try to Upload File to google drive
 * and choose in Match Configuration the 'Root Monitored Item' we should throw an error.
 * User can't use 'Root Monitored Item' for an action that requires File entity
 * when in the flow there is Sheet Row entity.
 */
const isValidSyncConfigTypeLikeInput = (
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectIntegration,
) => {
    return (
        workflowVersionInfoRetriever.getDraftVersionFromCache(group.id).dataSourceType === 'INTEGRATION' &&
        projectIntegration &&
        projectIntegration.integrationUniqueType ===
            syncConfigCacheManager.getSyncConfig(workflowVersionInfoRetriever.getDraftVersionFromCache(group.id).id)
                .viewData.integrationUniqueType
    );
};

export default isValidSyncConfigTypeLikeInput;
