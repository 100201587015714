import NextCloudAuthenticationComponent from './NextCloudAuthenticationComponent';
import NextCloudSetupComponent from './NextCloudSetupComponent';
import nextCloudCircleIcon from '../../../../apps/tracks/images/integrations/nextcloud-circle.png';
import nextCloudIcon from '../../../../apps/tracks/images/nextcloud.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class NextCloudIntegration extends IntegrationDefinitionBase {
    override name = 'nextcloud';
    override displayName = 'NextCloud';
    override description = 'We will only collect files and folders items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [new IntegrationEntity('File', 'Files'), new IntegrationEntity('Folder', 'Folders')];
    override customizedAuthenticationComponent = NextCloudAuthenticationComponent;
    override customizedSetupComponent = NextCloudSetupComponent;
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(nextCloudCircleIcon, '130px'),
        new IntegrationIcon(nextCloudIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { File: true, Folder: true },
        isOnlyDataRetention: {},
    };
}

export default NextCloudIntegration;
