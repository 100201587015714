import { useMemo } from 'react';

import type { InnerItemWidgetConfiguration } from '../../entities';

import type { BasicQueryDefinition, FieldQuery, WidgetBase } from '@tonkean/tonkean-entities';

const useCalculatedFiltersQueryDefinition = (
    endUserFiltersQueryDefinition: BasicQueryDefinition,
    widget: WidgetBase<InnerItemWidgetConfiguration>,
): BasicQueryDefinition | undefined => {
    return useMemo<BasicQueryDefinition | undefined>(() => {
        if (endUserFiltersQueryDefinition.query.filters.length > 0 || widget.configuration.displayAdvancedFilters) {
            const endUserFiltersCopy = { ...endUserFiltersQueryDefinition };
            const endUserFiltersQueryCopy = { ...endUserFiltersCopy.query };
            if (endUserFiltersQueryCopy.filters.length > 0) {
                endUserFiltersQueryCopy.filters = endUserFiltersCopy.query.filters.map((filter) => {
                    const clonedFilter = { ...filter };
                    // TNK_OWNER_ID is indexed as 'ownerId'
                    if (clonedFilter.fieldName === 'TNK_OWNER_ID') {
                        clonedFilter.fieldName = 'ownerId';
                    }
                    return clonedFilter;
                });
            }
            if (widget.configuration.filters) {
                const queriesCopy: FieldQuery[] = [];
                endUserFiltersQueryCopy.queries.forEach((queryToCopy) => {
                    queriesCopy.push({ ...queryToCopy });
                });
                queriesCopy.push(widget.configuration.filters.query);
                endUserFiltersQueryCopy.queries = queriesCopy;
            }

            endUserFiltersCopy.query = endUserFiltersQueryCopy;
            return endUserFiltersCopy;
        }
        return widget.configuration.filters;
    }, [endUserFiltersQueryDefinition, widget.configuration.displayAdvancedFilters, widget.configuration.filters]);
};

export default useCalculatedFiltersQueryDefinition;
