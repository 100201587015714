import template from './tnkIntercomIdTypePicker.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkIntercomIdTypePicker', {
    bindings: {
        onIdTypeSelected: '&',
        selectedIdType: '<',
    },
    template,
    controller: 'tnkIntercomIdTypePickerCtrl',
});
