import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { SimpleSelect, Spacer } from '@tonkean/infrastructure';
import { OAuth2ClientAuthenticationLocationToDisplayName } from '@tonkean/tonkean-entities';

const StyledSimpleSelect = styled(SimpleSelect)`
    width: 250px;
`;

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentAuthorizationOAuth2AuthorizationCode: React.FC<Props> = ({ expressionProps }) => {
    // All Supported client authentication locations.
    const options = useMemo(() => {
        return Object.entries(OAuth2ClientAuthenticationLocationToDisplayName).map(([location, displayName]) => ({
            value: location,
            label: displayName,
        }));
    }, []);
    return (
        <>
            <EnterpriseComponentAuthorizationParam>Auth URL:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="authUrl" {...expressionProps} placeholder="Auth URL" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Access Token Url:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="accessTokenUrl" {...expressionProps} placeholder="Access Token Url" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Client Id:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="clientId" {...expressionProps} placeholder="Client Id" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Client Secret:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="clientSecret" {...expressionProps} placeholder="Client Secret" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Scope:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="scope" {...expressionProps} placeholder="Scope" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Client Authentication</EnterpriseComponentAuthorizationParam>
            <StyledSimpleSelect name="clientAuthenticationLocation" options={options} />
        </>
    );
};

export default EnterpriseComponentAuthorizationOAuth2AuthorizationCode;
