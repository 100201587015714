import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';
import { dateUnitPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class DateDiffFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.DATE_DIFF;
    override readonly dataType = FieldType.Number;
    override readonly sign = 'DateDiff';
    override readonly displayName = 'Date Diff';
    override readonly description =
        'Calculates the number of minutes, hours, days, months, or years between two dates.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Start date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'End date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Time Unit',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: dateUnitPreset,
        },
        timeZoneField(),
        {
            displayName: 'Return absolute number',
            explanation: 'The non-negative value of a number without regard to its sign',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
            defaultValue: true,
        },
    ];
}
