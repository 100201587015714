import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function ZendeskCustomActionsDefinitionCtrl(
    $scope,
    $q,
    projectManager,
    tonkeanService,
    utils,
    integrations,
    integrationsConsts,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        validationObject: ctrl.validationObject,
        onDefinitionChanged: ctrl.onDefinitionChanged,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        configuredLogic: ctrl.configuredLogic,
        hasAdminRights:
            ctrl.projectIntegration.integration.additionalData &&
            ctrl.projectIntegration.integration.additionalData.isAdmin,
        authors: [],

        authorSelectionConfiguration: null,
        existingAuthorSelectionConfiguration: null,

        tagsObjects: [],
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata().ZENDESK.ATTACHMENT,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = true;

                $scope
                    .refreshAuthors()
                    .then(initializeEditMode)
                    .then($scope.onActionsChanged)
                    .finally(() => {
                        $scope.data.loading = false;
                    });
            }
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.refreshAuthors = function (q) {
        return tonkeanService
            .getIntegrationExampleEntities($scope.data.projectIntegration.id, 'User', q && q.length ? q : null)
            .then((data) => {
                if (data.exampleEntities && data.exampleEntities.length) {
                    $scope.data.authors = data.exampleEntities;
                }
            });
    };

    $scope.addTagObject = function () {
        $scope.data.tagsObjects.push({
            text: '',
            evaluatedText: '',
        });
    };

    $scope.removeTagObject = function (index) {
        $scope.data.tagsObjects.splice(index, 1);

        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.comment = originalExpression;
        $scope.data.definition.evaluatedCommentExpression = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of the expression.
     */
    $scope.onTonkeanExpressionChangedForTags = function (
        originalExpression,
        evaluatedExpression,
        index,
        shouldSaveLogic,
    ) {
        $scope.data.tagsObjects[index].text = originalExpression;
        $scope.data.tagsObjects[index].evaluatedText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onPerformingAuthorSelected = function (authorSelectionConfiguration, shouldSaveLogic) {
        $scope.data.authorSelectionConfiguration = authorSelectionConfiguration;

        const data = {
            personEmailExpressionDefinition: null,
            personIds: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
        };

        if (authorSelectionConfiguration) {
            if (authorSelectionConfiguration.personSelectionType === 'fromField') {
                data.personEmailExpressionDefinition = authorSelectionConfiguration.fromFieldExpressionDefinition;
            }

            data.personIds = [];
            if (
                authorSelectionConfiguration.personSelectionType === 'specificPeople' &&
                authorSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < authorSelectionConfiguration.selectedPeopleIds.length; i++) {
                    data.personIds.push(authorSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = authorSelectionConfiguration.personSelectionType === 'initiativeOwner';
            data.previousActor = authorSelectionConfiguration.personSelectionType === 'previousActor';
            data.useNone = authorSelectionConfiguration.personSelectionType === 'none';
            data.failIfNoValue = authorSelectionConfiguration.failIfNoValue;
        }

        $scope.data.definition.author = data;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onActionsChanged = function (shouldSave) {
        if (ctrl.onDefinitionChanged) {
            switch ($scope.data.definition.customActionKey) {
                case 'CREATE_COMMENT_ON_TICKET':
                    break;

                case 'ADD_TAG_ON_TICKET':
                case 'REMOVE_TAG_FROM_TICKET':
                    if (!$scope.data.tagsObjects) {
                        $scope.data.tagsObjects = [];
                    }

                    $scope.data.definition.tags = $scope.data.tagsObjects.map((tagToAdd) => {
                        return {
                            text: tagToAdd.text && tagToAdd.text.replaceAll(' ', '_'),
                            evaluatedText: tagToAdd.evaluatedText && tagToAdd.evaluatedText.replaceAll(' ', '_'),
                        };
                    });

                    break;
            }

            ctrl.onDefinitionChanged({ shouldSave });
        }
    };

    /**
     * Occurs once the is public property is changed.
     */
    $scope.onIsPublicToggled = function () {
        $scope.onActionsChanged(true);
    };

    /**
     * When changing the file selection
     */
    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onActionsChanged(true);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderExpression = expression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Followers
     */
    $scope.onFollowersExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.followersExpression = expression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        switch ($scope.data.definition.customActionKey) {
            case 'CREATE_COMMENT_ON_TICKET':
                break;

            case 'ADD_TAG_ON_TICKET':
            case 'REMOVE_TAG_FROM_TICKET':
                if (!$scope.data.definition.tags) {
                    $scope.data.definition.tags = [];
                }

                $scope.data.tagsObjects = $scope.data.definition.tags.map((tag) => {
                    return {
                        text: tag.text,
                        evaluatedText: tag.evaluatedText,
                    };
                });

                break;
        }

        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (customTriggerAction && customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author) {
            $scope.data.existingAuthorSelectionConfiguration = {
                personEmailFieldDefinitionId:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author
                        .personEmailFieldDefinitionId,
                personEmailExpressionDefinition:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author
                        .personEmailExpressionDefinition,
                specificPeopleIds:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.personIds,
                initiativeOwner:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.initiativeOwner,
                previousActor:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.previousActor,
                useNone: false,
                failIfNoValue:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.failIfNoValue,
            };

            if (
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.personIds &&
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.personIds.length > 0
            ) {
                const itemId =
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.author.personIds[0];
                $scope.data.selectedAuthor = utils.findFirstById($scope.data.authors, itemId);
                if (!$scope.data.selectedAuthor) {
                    return tonkeanService
                        .getExternalActivityById(projectManager.project.id, $scope.data.projectIntegration.id, itemId)
                        .then((data) => {
                            $scope.data.authors.push(data);
                            $scope.data.selectedAuthor = utils.findFirstById($scope.data.authors, itemId);
                        });
                }
            }
        }
        return $q.resolve();
    }
}

export default angular
    .module('tonkean.app')
    .controller('ZendeskCustomActionsDefinitionCtrl', lateConstructController(ZendeskCustomActionsDefinitionCtrl));
