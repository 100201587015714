export const WORKER_TYPES = {
    UNINITIALIZED: {
        key: 'UNINITIALIZED',
        label: 'Uninitialized Worker',
    },
    FULL_TIME: {
        key: 'FULL_TIME',
        label: 'Coordination Module',
        graphLabel: 'Run flow on:',
        typeLabel: 'Always monitoring',
        subtext: 'Always monitoring',
        description:
            'Always on, capable of advance workflows like monitoring information, maintaining live reports and fully coordinating people.',
    },
    ON_TRIGGER: {
        key: 'ON_TRIGGER',
        label: 'Scheduled Bot',
        graphLabel: 'On Trigger',
        typeLabel: 'Single Trigger',
        subtext: 'Scheduled',
        description: 'Perfect for recurring simple workflows and processes.',
    },
    SCHEDULED: {
        key: 'SCHEDULED',
        label: 'Scheduled Bot',
        graphLabel: 'On Schedule',
        typeLabel: 'On Schedule',
        description: 'Perfect for recurring workflows and processes.',
        disabled: true,
    },
};
