import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const ManualUpdateOwnerLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.MANUAL_OWNER_UPDATE,
    title: 'Assign Owner',
    iconClass: 'mod-update-owner',
    description: 'Assign action to an owner',
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition.fieldsToUpdate?.length) {
            validationObject.noOwner = 'Must select an owner.';
        } else {
            for (let i = 0; i < definition.fieldsToUpdate.length; i++) {
                const fieldToUpdate = definition.fieldsToUpdate[i];

                if (fieldToUpdate.fieldId !== 'TNK_OWNER_ID') {
                    validationObject.noOwner = 'Must select an owner.';
                    break;
                } else {
                    if (!fieldToUpdate.updateToEmptyValue) {
                        if (fieldToUpdate.inputTypeSelection === 'MANUAL' && !fieldToUpdate.ownerValue?.ownerId) {
                            validationObject.noOwner = 'Must select an owner.';
                            break;
                        } else if (
                            fieldToUpdate.inputTypeSelection === 'EXPRESSION' &&
                            !fieldToUpdate.expressionValue?.evaluatedExpression
                        ) {
                            validationObject.noOwner = 'Must select an owner.';
                            break;
                        }
                    }
                }
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    dataAutomation: 'assign-owner',
};

export default ManualUpdateOwnerLogicBlockConfig;
