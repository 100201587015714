import template from './tnkAddCommentActionDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkAddCommentActionDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        definition: '<', // The selected action definition.
        validationObject: '<',
        previewEvaluationSource: '<',
        onDefinitionChanged: '&',
    },
    template,
    controller: 'AddCommentActionDefinitionCtrl',
});
