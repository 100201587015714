import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';
import EnterpriseComponentAuthorizationSelector from './EnterpriseComponentAuthorizationSelector';
import EnterpriseComponentCertificationPage from './EnterpriseComponentCertificationPage';
import RelevantAuthorizationComponent from './RelevantAuthorizationComponent';
import useEnterpriseComponentVariablesExpressionTab from '../../hooks/useEnterpriseComponentVariablesExpressionTab';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Spacer } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const StyledEnterpriseComponentAuthorizationSelector = styled(EnterpriseComponentAuthorizationSelector)`
    width: 160px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
}

const EnterpriseComponentAuthorizationConfiguration: React.FC<Props> = ({ projectIntegration }) => {
    // Enterprise component variable tab.
    const enterpriseComponentVariablesExpressionTab = useEnterpriseComponentVariablesExpressionTab(
        projectIntegration.projectId,
        projectIntegration.id,
        projectIntegration.id,
    );

    // All the additional default fields for expressions
    const additionalExpressionProps: Partial<TonkeanExpressionProps> = useMemo(() => {
        return {
            globalExpressionOnly: true,
            doNotEvaluatePreview: true,
            hideEditorButton: true,
            additionalTabs: [enterpriseComponentVariablesExpressionTab],
        };
    }, [enterpriseComponentVariablesExpressionTab]);

    return (
        <>
            <EnterpriseComponentAuthorizationParam>Base URL:</EnterpriseComponentAuthorizationParam>

            <FormikTonkeanExpression {...additionalExpressionProps} name="baseUrl" placeholder="Base URL" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Authentication Type:</EnterpriseComponentAuthorizationParam>
            <StyledEnterpriseComponentAuthorizationSelector />

            <Spacer height={12} />

            <RelevantAuthorizationComponent
                expressionProps={additionalExpressionProps}
                projectIntegration={projectIntegration}
            />

            <Spacer height={12} />

            <EnterpriseComponentCertificationPage
                projectIntegration={projectIntegration}
                expressionProps={additionalExpressionProps}
            />
        </>
    );
};

export default EnterpriseComponentAuthorizationConfiguration;
