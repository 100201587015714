/* eslint-disable import/order */

import React from 'react';
import styled from 'styled-components';
import { Breakpoint, ItemInterfaceSection, useItemInterfaceContext, useProject } from '@tonkean/infrastructure';
import {
    type Initiative,
    type InterfaceCTASavedAction,
    type ItemInterface,
    type ItemInterfaceLoadingState,
    type ItemInterfaceWidget,
    type ItemInterfaceWidgetConfiguration,
    useItemInterfaceHeaderThemeConfiguration,
    type ValidationState,
    type WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { ItemInterfacePermission } from '../../../entities';
import ItemInterfaceHeader, { type ItemInterfaceHeaderProps } from './ItemInterfaceHeader';
import { GeneralInterfaceWrapper } from '../../../components';
import type { InterfaceLiveDisplay } from './InterfaceLiveDisplay';
import ItemInterfaceScrollContextProvider from './ItemInterfaceScrollContextProvider';
import { useIsTabsViewItemInterface } from './useIsTabsViewItemInterface';
import getSubmitValidationContext from '../../utils/getSubmitValidationContext';
import useUpdateWidgetsToValidate from '../../../widgets/hooks/useUpdateWidgetsToValidate';
import useScrollHandler from '../../hooks/useScrollHandler';
import Utils from '@tonkean/utils';
import { PanelHeaderCircles } from '@tonkean/svg';
import InterfaceHeaderCallToActionMenu from './InterfaceHeaderCallToActionMenu';
import ItemInterfaceBody from './ItemInterfaceBody';
import InterfaceSubmitValidationContext from '../../../utils/InterfaceSubmitValidationContext';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        border-radius: 8px 8px 0 0;
    }
    min-height: 100%;
    position: relative;
`;

const HeaderWrapper = styled.div<{ $color: [number, number, number] }>`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    background: ${({ $color }) => `
        linear-gradient(
          206deg,
          rgba(${$color.join(',')},0.2) 0%,
          rgba(${$color.join(',')},0) 47%
        ),
        #fff
      `};

    position: sticky;
    top: 0;
    z-index: 1;
`;

const StyledPanelHeaderCircles = styled(PanelHeaderCircles)<{ $color: [number, number, number] }>`
    position: absolute;

    circle {
        stroke: ${({ $color }) => `
            rgba(${$color.join(',')},0.7);
        `};
    }

    width: 100%;
`;

export interface ItemInterfaceViewProps {
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    permission: ItemInterfacePermission;
    showPoweredByTonkean: boolean;
    CustomHeaderComponent?: React.FC<ItemInterfaceHeaderProps>;
    onValidationChangedCallback?: (
        validation: ValidationState,
        setForceShownValidation?: (value: boolean) => void,
    ) => void;
    inheritValidationContext?: boolean;
    itemActions?: InterfaceCTASavedAction[];
    itemInterface: ItemInterface;

    refreshItem?(): void;

    liveDisplay: InterfaceLiveDisplay | undefined;
}

const PanelItemInterfaceView: React.FC<ItemInterfaceViewProps> = ({
    initiative,
    workflowVersion,
    itemInterfaceWidgetsState,
    widgets,
    showPoweredByTonkean,
    permission,
    CustomHeaderComponent,
    onValidationChangedCallback,
    inheritValidationContext,
    itemActions,
    itemInterface,
    refreshItem,
    liveDisplay,
}) => {
    const project = useProject();
    const tabsView = useIsTabsViewItemInterface(itemInterface);
    const headerThemeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    const interfaceSubmitValidationContext = getSubmitValidationContext(
        inheritValidationContext,
        onValidationChangedCallback,
    );

    const setWidgetsIdsToValidate = interfaceSubmitValidationContext.setWidgetsIdsToValidate;

    useUpdateWidgetsToValidate(setWidgetsIdsToValidate, widgets);

    const scrollingObjectStarted = useScrollHandler();

    const HeaderCompononent = CustomHeaderComponent ?? ItemInterfaceHeader;

    const showActionAsButton =
        itemInterface.configuration?.showActionAsButton !== undefined
            ? itemInterface.configuration?.showActionAsButton
            : true;

    const headerColorRgb = Utils.hexToRgb(headerThemeConfiguration.headerBackgroundColor) || [0, 0, 0];

    const { tabs } = useItemInterfaceContext();

    return (
        <Wrapper data-automation="panel-item-interface">
            <ItemInterfaceScrollContextProvider scrollingEventObject={scrollingObjectStarted}>
                <InterfaceSubmitValidationContext.Provider value={interfaceSubmitValidationContext}>
                    <GeneralInterfaceWrapper
                        quickNavigationMode={itemInterface.configuration?.quickNavigationMode}
                        tabs={tabs}
                    >
                        <HeaderWrapper $color={headerColorRgb}>
                            <StyledPanelHeaderCircles $color={headerColorRgb} />

                            <HeaderCompononent
                                initiative={initiative}
                                permission={permission}
                                widgets={widgets}
                                section={ItemInterfaceSection.PANEL}
                                tabsView={tabsView}
                                actionButton={
                                    itemActions && (
                                        <InterfaceHeaderCallToActionMenu
                                            data-automation="panel-item-interface-header-actions"
                                            initiative={initiative}
                                            actions={itemActions}
                                            workflowVersionType={itemInterface.workflowVersionType}
                                            primaryColor={headerThemeConfiguration.headerBackgroundColor}
                                            itemInterface={itemInterface}
                                            workflowVersion={workflowVersion}
                                            projectId={itemInterface.projectId}
                                            permission={permission}
                                            reloadInitiativeFromServer={refreshItem}
                                            liveDisplay={liveDisplay}
                                            showActionAsButton={showActionAsButton}
                                        />
                                    )
                                }
                            />
                        </HeaderWrapper>

                        <ItemInterfaceBody
                            itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                            widgets={widgets}
                            initiative={initiative}
                            workflowVersion={workflowVersion}
                            permission={permission}
                            showPoweredByTonkean={showPoweredByTonkean}
                        />
                    </GeneralInterfaceWrapper>
                </InterfaceSubmitValidationContext.Provider>
            </ItemInterfaceScrollContextProvider>
        </Wrapper>
    );
};

export default PanelItemInterfaceView;
