import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class IsNumberFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.IS_NUMBER;
    override readonly sign = 'IsNumber';
    override readonly displayName = 'Is Number';
    override readonly description = 'Checks if the value is a number';
    override readonly dataType = FieldType.Boolean;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
