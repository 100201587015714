import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import { useSolutionSiteContext } from '../../../hooks';

import type { Group } from '@tonkean/tonkean-entities';

const useGroupSelectorOptions = (): { label: string; value: string }[] => {
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { solutionSite } = useSolutionSiteContext();

    const workflowFolder =
        workflowFolderManager.projectIdToFolderIdToFolderMap[solutionSite.projectId]?.[solutionSite.workflowFolderId];

    return useMemo(() => {
        if (!workflowFolder) {
            console.error(`Unable to find workflow folder [${solutionSite.workflowFolderId}] in cache`);
            return [];
        }
        return workflowFolder.groupIds
            .map((groupId) => projectManager.groupsMap[groupId])
            .filter(Boolean)
            .map((group: Group) => ({ label: group.name, value: group.id }));
    }, [projectManager.groupsMap, solutionSite.workflowFolderId, workflowFolder]);
};

export default useGroupSelectorOptions;
