import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';

function ConnectSpecificIntegrationCtrl($scope, $state) {
    const ctrl = this;

    $scope.data = {
        marketplaceItem: undefined,
        integrationType: undefined,
        sources: [],
        onIntegrationCreated: undefined,
        integrationState: {},
        currentIntegration: {},
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.marketplaceItem) {
            $scope.data.marketplaceItem = ctrl.marketplaceItem;
        }
        if (changesObj.integrationType) {
            $scope.data.integrationType = ctrl.integrationType;
            // For some reason the sources are lowercase
            $scope.data.sources = [$scope.data.integrationType.toLowerCase()];
        }
        if (changesObj.onIntegrationCreated) {
            $scope.data.onIntegrationCreated = ctrl.onIntegrationCreated;
        }
    };
    $scope.onIntegrationChanged = (changedProjectIntegration) => {
        $scope.data.onIntegrationCreated?.(changedProjectIntegration.projectIntegration);
    };

    $scope.onIntegrationCancel = () => {};
    $scope.onIntegrationUpdate = (oldIdsToCreatedIdsMap, createdProjectIntegrationId) => {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: createdProjectIntegrationId,
            page: ProjectIntegrationPageMenuItemType.OVERVIEW,
        });
    };
}

export default angular.module('tonkean.app').controller('ConnectSpecificIntegrationCtrl', ConnectSpecificIntegrationCtrl);
/* jshint ignore:start */
