import { useAngularService } from 'angulareact';
import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field, SimpleSelect } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegration, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
`;

const ParameterValue = styled.div`
    margin-top: 3px;
`;

const ParameterName = styled.div`
    width: 100px;
    font-weight: bold;
    font-size: ${FontSize.MEDIUM_14};
`;

const StyledField = styled(Field)`
    flex-grow: 1;
`;

interface Props {
    responseHandlingDefinitionFormikNamePrefix: string;
    projectIntegration: ProjectIntegration;
    entityIdFormikName: string;
    parametersFormikNamePrefix: string;
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[];
    expressionProps: FormikExpressionProps;
    projectIntegrationEntityProp: ProjectIntegrationEntity;
}

const ProjectIntegrationEntityHandleResponseFetcherSelector: React.FC<Props> = ({
    responseHandlingDefinitionFormikNamePrefix,
    projectIntegration,
    entityIdFormikName,
    parametersFormikNamePrefix,
    entitiesSummary,
    expressionProps,
    projectIntegrationEntityProp,
}) => {
    const [, { value: currentFetcherActionId }, { setValue: setCurrentFetcherActionId }] = useField<string>(
        `${responseHandlingDefinitionFormikNamePrefix}.fetcherActionId`,
    );
    const [, { value: currentFetcherParameters }, { setValue: setCurrentFetcherParameters }] =
        useField<Record<string, TonkeanExpressionDefinition>>(parametersFormikNamePrefix);
    const [, { value: optionalEntityIdFromFormik }] = useField<string>(entityIdFormikName);

    // creating a state for the projectIntegrationEntity due to the need to pass setEntity in the props of the fetcher page component
    const [projectIntegrationEntity, setProjectIntegrationEntity] = useState<ProjectIntegrationEntity | undefined>();

    const [
        {
            data: projectIntegrationActions,
            loading: loadingProjectIntegrationActions,
            error: errorProjectIntegrationActions,
        },
        getProjectIntegrationFetchingActionsByEntityId,
    ] = useLazyTonkeanService('getProjectIntegrationFetchingActionsByEntityId');

    const selectedProjectIntegrationFetcherAction = useMemo(() => {
        return projectIntegrationActions?.entities.find((action) => action.id === currentFetcherActionId);
    }, [currentFetcherActionId, projectIntegrationActions?.entities]);

    const [
        { data: entityResponse, loading: loadingProjectIntegrationEntity, error: errorProjectIntegrationEntity },
        getProjectIntegrationEntityById,
    ] = useLazyTonkeanService('getProjectIntegrationEntityById');

    const $state = useAngularService('$state');

    // If we have entity id from the formik we take it, otherwise this is the fetcher of the root entity
    const entityId = optionalEntityIdFromFormik ?? projectIntegrationEntityProp.id;

    useEffect(() => {
        if (!entityId) {
            return;
        }

        getProjectIntegrationFetchingActionsByEntityId(projectIntegration.id, entityId);
        getProjectIntegrationEntityById(projectIntegration.id, entityId);
    }, [
        getProjectIntegrationFetchingActionsByEntityId,
        getProjectIntegrationEntityById,
        projectIntegration.id,
        entityId,
    ]);

    // Set default action fetching id
    useEffect(() => {
        if (!currentFetcherActionId) {
            setCurrentFetcherActionId(projectIntegrationEntityProp.entityFetcherDefinition.fetcherActionId);
        }

        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectIntegrationEntityProp.entityFetcherDefinition.fetcherActionId]);

    useEffect(() => {
        if (entityResponse) {
            setProjectIntegrationEntity(entityResponse);
        }
    }, [entityResponse]);

    const fetcherActionOptions = useMemo(() => {
        return (
            projectIntegrationActions?.entities?.map((action) => ({
                label: action.displayName,
                value: action.id,
            })) || []
        );
    }, [projectIntegrationActions?.entities]);

    return (
        <div>
            <Wrapper>
                <StyledField>
                    <SimpleSelect
                        name={`${responseHandlingDefinitionFormikNamePrefix}.fetcherActionId`}
                        options={fetcherActionOptions}
                        isLoading={loadingProjectIntegrationActions || loadingProjectIntegrationEntity}
                        isError={!!errorProjectIntegrationEntity || !!errorProjectIntegrationActions}
                    />
                </StyledField>
                <Button
                    onClick={() => {
                        $state.go('product.projectIntegrationPageEntityFetcher', {
                            enterpriseComponentId: projectIntegration.id,
                            entityId,
                            fetcherActionId: currentFetcherActionId,
                            props: {
                                projectIntegration,
                                entity: projectIntegrationEntity,
                                setEntity: setProjectIntegrationEntity,
                                entitiesSummary,
                            },
                        });
                    }}
                    size={ButtonSize.MEDIUM}
                    disabled={!entityId}
                    flex
                    outlined
                >
                    Configure
                </Button>
            </Wrapper>

            {selectedProjectIntegrationFetcherAction?.parametersDefinition?.parameters.map((parameter) => (
                <div key={parameter.id}>
                    <ParameterName> {parameter.displayName} </ParameterName>

                    <ParameterValue>
                        <TonkeanExpression
                            {...expressionProps}
                            onTonkeanExpressionChanged={(original, evaluated, expression) => {
                                if (expression) {
                                    setCurrentFetcherParameters({
                                        ...currentFetcherParameters,
                                        [parameter.id]: expression,
                                    });
                                }
                            }}
                            placeholder="Insert parameter value"
                            savedOriginalExpression={currentFetcherParameters?.[parameter.id]?.originalExpression || ''}
                            savedEvaluatedExpression={
                                currentFetcherParameters?.[parameter.id]?.evaluatedExpression || ''
                            }
                            doNotEvaluatePreview
                            globalExpressionOnly
                            hideEditorButton
                        />
                    </ParameterValue>
                </div>
            ))}
        </div>
    );
};
export default ProjectIntegrationEntityHandleResponseFetcherSelector;
