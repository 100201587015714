import { right } from '@popperjs/core';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TooltipIcon } from '../../../../../../images/icons/tooltip2.svg';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { H4, Separator, Textarea, Tooltip } from '@tonkean/infrastructure';
import type { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';
import { MessageSendBy } from '@tonkean/tonkean-entities';

const Header = styled(H4)`
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
`;

const Value = styled.div``;

const AttachmentContainer = styled.div`
    margin-top: 10px;
`;

const TooltipIconWrapper = styled(TooltipIcon)`
    margin-left: 5px;
`;

interface EmailSendDetails {
    type: MessageSendBy.EMAIL;
    subject: string;
    contentPlain: string;
    contentHtml: string;
    to: {
        address: string;
        name: string;
    }[];
    attachments?: {
        fileName: string;
        contentMimeType: string;
        length: number;
    }[];
}

const SingleWorkerRunActionInspectSendNotification: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.SEND_NOTIFICATION>
> = ({ finalDetails }) => {
    if (finalDetails.type === MessageSendBy.EMAIL) {
        const emailDetails = finalDetails as EmailSendDetails;
        const attachments = emailDetails.attachments || [];
        return (
            <>
                <Header $bold $underline>
                    Subject
                </Header>
                <Value>{emailDetails.subject}</Value>
                <Header $bold $underline>
                    Content Plain
                </Header>
                <Value>{emailDetails.contentPlain}</Value>
                <Header $bold $underline>
                    Content HTML
                </Header>
                <Value>
                    <Textarea minRows={2} maxRows={10} value={emailDetails.contentHtml} disabled />
                </Value>
                <Tooltip
                    placement={right}
                    content="If the notification was sent to a group of emails, other recipients may have also received the notification."
                >
                    <Header $bold $underline>
                        Recipients
                        <TooltipIconWrapper />
                    </Header>
                </Tooltip>
                {emailDetails.to.map((item) => (
                    <Value key={item.address}>{`${item.name} <${item.address}>`}</Value>
                ))}
                {attachments.length > 0 ? (
                    attachments.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <AttachmentContainer key={index}>
                            <Separator />
                            <Header $bold $underline>
                                Attachment {index + 1}
                            </Header>
                            <Value>File name: {item.fileName}</Value>
                            <Value>Content type: {item.contentMimeType}</Value>
                            <Value>File length: {item.length} bytes</Value>
                        </AttachmentContainer>
                    ))
                ) : (
                    <></>
                )}
            </>
        );
    }
    return <></>;
};

export default SingleWorkerRunActionInspectSendNotification;
