function ShareCtrl(
    $scope,
    $log,
    $uibModalInstance,
    tonkeanService,
    utils,
    projectManager,
    authenticationService,
    communicationIntegrationsService,
    entityId,
    entityName,
) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.cis = communicationIntegrationsService;
    $scope.entityId = entityId;
    $scope.entityName = entityName;
    $scope.forms = {};

    $scope.selectedPeople = [];
    $scope.data = {
        shareToType: 'People',
        shareMessageText: null,
    };

    /**
     * Initialization function for the share modal.
     */
    $scope.init = function () {
        $scope.loadAutoCompleteChannels();
    };

    /**
     * Occurs once a user selects a channel from the suggested channels list.
     */
    $scope.selectPublicChannel = function (item) {
        $scope.data.channel = {
            channelId: item.value,
            channelName: item.displayName,
        };
    };

    /**
     * Loads communication channels of project to auto complete with.
     */
    $scope.loadAutoCompleteChannels = function () {
        const communicationIntegration = $scope.cis.getFirstThatSupports($scope.cis.features.channels);
        // Only if we have communication integration that support channels connected, we will load the channels.
        if (communicationIntegration) {
            $scope.loadingCommunicationChannels = true;

            tonkeanService
                .getCommunicationChannels(
                    projectManager.project.id,
                    communicationIntegration.integration.id,
                    communicationIntegration.projectData.serviceUrl,
                    communicationIntegration.projectData.teamId,
                )
                .then(function (data) {
                    $scope.suggestedCommunicationChannels = data.options;
                    $scope.loadingCommunicationChannels = false;

                    // Auto select the main public channel for the user.
                    autoselectPublicChannel($scope.suggestedCommunicationChannels);
                });
        }
    };

    /**
     * Sends the share request to server.
     */
    $scope.submit = function () {
        if (!$scope.sharing && $scope.forms.shareFrom.$valid) {
            switch ($scope.data.shareToType) {
                case 'People':
                    shareWithPeople();
                    break;
                case 'Channel':
                    shareWithCommunicationChannels();
                    break;
                default:
                    $log.error('Share to type ', $scope.data.shareToType, ' is not supported.');
            }
        }
    };

    /**
     * Shares the entity with selected people.
     */
    function shareWithPeople() {
        const people = $scope.selectedPeople.filter(function (p) {
            return p.isValid || p.id;
        });

        const ignoredPeople = $scope.selectedPeople.length - people.length;
        if (ignoredPeople) {
            $log.warn('Ignoring', ignoredPeople, 'people because they are not valid');
        }

        if (people.length) {
            $scope.sharing = true;
            tonkeanService
                .shareItem(
                    $scope.entityId,
                    people,
                    null,
                    tonkeanService.shareTypes.multipleDirectMessage,
                    $scope.data.shareMessageText,
                )
                .then(function (data) {
                    $scope.$emit('alert', { msg: 'Shared!', type: 'success' });
                    $uibModalInstance.close(data);
                })
                .catch(function (error) {
                    $scope.sharingError = error;
                    $scope.sharing = false;
                    if (error.data && error.data.error && error.data.error.data && error.data.error.data.emails) {
                        const unauthorizedPeople = $scope.selectedPeople
                            .filter(function (p) {
                                return error.data.error.data.emails.includes(p.email);
                            })
                            .map(function (p) {
                                return p.name || p.email;
                            });

                        if (error.status === 403) {
                            // Forbidden - means the people don't have access to the item.
                            if (unauthorizedPeople && unauthorizedPeople.length) {
                                $scope.sharingError = "The following people don't have access to this track: ";
                            } else {
                                $scope.sharingError =
                                    "You can't share this private track with the selected destination.";
                            }
                        } else {
                            $scope.sharingError = "You can't share this with ";
                        }

                        let unauthorizedPeopleString = '';
                        if (unauthorizedPeople && unauthorizedPeople.length) {
                            const lastPerson = unauthorizedPeople.pop();
                            unauthorizedPeopleString = unauthorizedPeople.join(',');

                            if (unauthorizedPeople.length) {
                                unauthorizedPeopleString += ' and ';
                            }

                            unauthorizedPeopleString += lastPerson;
                        }

                        $scope.sharingError += unauthorizedPeopleString;
                    }
                });
        } else {
            $scope.sharingError = 'You must choose at least one person to share with.';
        }
    }

    /**
     * Shares the entity with selected communication channels.
     */
    function shareWithCommunicationChannels() {
        if ($scope.data.shareToChannel) {
            $scope.sharing = true;
            tonkeanService
                .shareItem(
                    $scope.entityId,
                    null,
                    $scope.data.shareToChannel.displayName,
                    tonkeanService.shareTypes.channel,
                    $scope.data.shareMessageText,
                )
                .then(function (data) {
                    $scope.$emit('alert', { msg: 'Shared!', type: 'success' });
                    $uibModalInstance.close(data);
                })
                .catch(function (error) {
                    $scope.sharingError = error;
                    $scope.sharing = false;
                });
        } else {
            $scope.sharingError = 'You must choose a channel to share with.';
        }
    }

    /**
     * Auto selects the defined communication channel for the user.
     * @param channels The channels to select from.
     */
    function autoselectPublicChannel(channels) {
        // Auto select the main channel only if we have one and if the user didn't select a channel by himself.
        if ($scope.pm.project.notificationSettings) {
            // Search for the channel in the channel list.
            for (const channel of channels) {
                // If found, auto select the channel.
                if (channel.value === $scope.pm.project.notificationSettings.channelId) {
                    $scope.data.shareToChannel = channel;
                    break;
                }
            }
        }
    }

    $scope.init();

    // Focus the message input element when the document finishes loading.
    angular.element(document).ready(function () {
        // Auto focus the message input.
        const messageInput = document.getElementById(`message-${$scope.entityId}`);
        if (messageInput) {
            messageInput.focus();
        }
    });
}

export default angular.module('tonkean.app').controller('ShareCtrl', ShareCtrl);
