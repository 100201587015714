import template from './customFilters.template.html?angularjs';

/**
 * This is a directive used to define custom filters on all kind of objects. For example, count field definition, or syncing views...
 * definedFilters is an object to hold the chosen filters in the directive's model.
 * existingDefinition is an object to hold the filters as they exist today in the server.
 */
function tnkCustomFilters() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            // Passing the control of the calling controller, in which a function syncUsingCustomFilters will be defined, to save
            // the defined custom filters as an API sync definition.
            control: '=',
            // Can be out of two values: 'EXTERNAL', 'COLUMN'. If EXTERNAL is chosen, filtered fields will be from an external integration.
            // If COLUMN is chosen, filtered fields will be from Tonkean columns.
            itemsSource: '@',
            existingDefinition: '=', // Passes the current defined definition, if such exists.

            groupId: '=', // The group id we're in.
            workflowVersionId: '=', // The workflow version id we're in.
            targetWorkflowVersionId: '=', // The workflow version id of the LEFT side.
            logicId: '=', // The logic id we're in.
            evaluatePreview: '=', // whether to evaluate worker field preview.

            considerAllGroups: '=', // If true, will consider all groups of the project as data source for fields and not just the given group at groupId parameter.
            projectIntegration: '=', // The project integration we're defining filters for.
            selectedEntityType: '=', // The currently chosen entity user wants to sync items of. For example, HubSpot's Company.
            isSpecialEntity: '=', // Indicates whether the given selected entity type is a special kind of entity (For example, TNK_BUCKET).
            // If given selected entity type is special entity (isSpecialEntity = true), and we can't find the selectedEntityType in our maps,
            // we will try and take the fallbackEntityType as the synced entity.
            // This should be used if you want to be generic about the entity you sync, but not all integrations have the special entity type you use.
            fallbackEntityType: '=',

            fromSync: '=', // If true, that means the directive is used from sync.
            hideTimeRangeSelection: '=', // If true, will not display the time range selection in directive.
            editMode: '=', // If true, we are editing previously chosen filters. Otherwise, we create new custom filters.
            smallMod: '<', // If true, this component should use its small container compatible ui mod: '<', // If true will wrap the conditions in ui blocks
            verticalMod: '<', // Changing the custom filters layout to be vertical aligned
            hideIfNoMetadata: '=', // If true, will hide the whole control if there's no metadata for the selectedEntityType.
            copyPropertiesFromDefinition: '=', // If true, will copy the properties from definition to custom filters and vice versa.
            onInitializationFinished: '&', // Function to be called once the initialization of the custom filters directive is done.
            onFiltersChange: '&', // Function to be called every time the filters collection or filter data changes.
            onFiltersChangeDefinition: '<', // Function to be called every time the filters collection or filter data changes that returns the definition.
            filtersChangeOnlyAfterInit: '<', // If true, will only call onFiltersChange after initialization is over

            initializeQueryWithDefaultCreateDateCondition: '<',
            displayIntegrationIndication: '<', // If true, will display an icon for fields from integrations (only those who define the source property. at the moment, it's only for the quick-create of integration fields).

            boldTitle: '=', // Whether the "these conditions" title should be bold
            showFieldInspect: '<', // If true, we enable tnkFieldInspect on the inner tnkFieldSelector.
            showFieldInspectModal: '<', // If true, we enable tnkFieldInspect on the inner tnkFieldSelector in MODAL mode.
            inspectEntityType: '<', // optional : if exist set the inspect to use this entity.

            viewOnly: '<', // If true, the user can't edit anything.
            // Only relevant to COLUMN items source.
            // Only special fields that are in this feature list will be included.
            specialFieldsForFeatures: '<',

            hideCloseButton: '<', // If true will hide the close button
            hideQueryOnSingleCondition: '<', // If true in case of only one condition the qury type(title) will be hidden
            reloadFieldsOnNewFieldDefinitionCreation: '<', // If true, will reload field options if a new field definition creation event is raised.

            previewEvaluationSource: '<',
            useExpressionsForValue: '<', // Indicates whether we should use tonkean expressions for values instead of plain values.
            modFixedWidth: '<', // Setting it true will prevent the expression field input from expending.
            tabsFieldSelectorModNarrow: '<', // Making the width of the tnkTabsFieldSelector narrower.

            tabsFieldSelectorModFixedWidth: '<', // Making the width of the tnkTabsFieldSelector fixed.
            allowedConditionsSet: '<',
            notAllowedConditionsSet: '<',
            forceExpand: '<', // if true, show expanded custom filters
            searchAllFields: '<', // if true, show search all fields input
            filtersResultMap: '<',
            additionalFields: '<', // Fields to use as the field options in the field selector
            excludedTabSelectorSpecialFields: '<', // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
            includeTabSelectorSpecialFieldsForFeatures: '<', // Use 'considerInTypes' to add fields under this category to the special fields tab selector
            keepEmptyValuedFilters: '<', // if true notify changes with incomplete filters (no values). If false will remove them when notifying
            errorObject: '<', // Will display errors in correct place (inner query) based on this object
            invokeChangeOnConditionSelection: '<', // If true will invoke change methods after condition selection regardless if valid or not
            allowContains: '<', // allow using contains operator
            includeViewerFields: '<', // If true will include viewer fields in field selection (left side)
        },
        template,
        controller: 'CustomFiltersCtrl',
        link(scope, element) {
            // saving the original elements so can be used for the Full screen mode
            scope.ctrlRootElement = element[0];
            scope.customTriggersElement = element[0].querySelectorAll('.custom-filters')[0];
        },
    };
}

angular.module('tonkean.app').directive('tnkCustomFilters', tnkCustomFilters);
