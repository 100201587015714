<div ng-hide="data.currentIntegration">
    <!-- Title -->
    <span>Authorized data sources:</span>

    <!-- Refresh button -->
    <a ng-if="data.showRefreshBtn" class="margin-left-lg common-size-xxxxs" ng-click="reloadIntegrations()">
        <i class="fa fa-refresh margin-right-xs"></i>
        Refresh
    </a>

    <!-- Loading indication for refreshing integrations -->
    <span ng-if="data.reloadingIntegrations" class="loading-small margin-left"></span>
</div>
<div class="integration-selection margin-top">
    <!-- CSV Import -->
    <div
        class="btn btn-default margin-right-xs common-size-xxs margin-bottom-xs integration-selection-button"
        ng-if="data.showImportCsv"
        ng-hide="data.currentIntegration"
        ng-click="onImportCsvClicked()"
    >
        <i class="fa fa-file-excel-o margin-right common-size-s padding-normal-xs"></i>
        A CSV file
    </div>

    <!-- Connected and enabled integrations -->
    <div
        ng-repeat="projectIntegration in data.authorizedProjectIntegrationsSummaries| filter:filterByIntegrationType"
        data-automation="integration-selection-data-source"
        ng-hide="data.currentIntegration || data.disableImportIntegrations"
        ng-if="data.integrationsSettings[projectIntegration.integration.integrationType.toLowerCase()]"
        class="
            btn btn-default
            margin-right-xs
            common-size-xxs
            margin-bottom-xs
            integration-selection-button
            long-name-limit
        "
        ng-click="selectProvider(projectIntegration)"
        uib-tooltip="{{ projectIntegrationTooltip(projectIntegration) }}"
        tooltip-placement="auto bottom"
    >
        <!-- Integration Icon -->
        <i
            class="initiative-integration-icon mod-normal margin-right"
            ng-class="
                !projectIntegration.iconUrl ? 'mod-' + projectIntegration.integration.integrationType.toLowerCase() : ''
            "
            ng-style="{
                background: projectIntegration.iconUrl
                    ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                    : null
            }"
        ></i>

        <!-- Integration Name -->
        <span id="initiative-project-integration-{{ projectIntegration.id }}" class="initiative-integration-label">
            {{
                projectIntegration.displayName +
                    (projectIntegration.integration.isNativeIntegration &&
                    !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + projectIntegration.creator.firstName + ')'
                        : '')
            }}
        </span>

        <!-- Fix Indication -->
        <i
            ng-if="::!projectIntegration.valid"
            class="fa fa-exclamation margin-left-xs common-size-xxs common-color-danger"
        ></i>
    </div>

    <!-- Connected and disabled integrations -->
    <tnk-integration-group
        ng-repeat="projectIntegration in pm.project.integrations"
        ng-if="
            projectIntegration.disabled === true &&
            data.integrationsSettings[projectIntegration.integration.integrationType.toLowerCase()]
        "
        ng-hide="
            (data.currentIntegration &&
                data.currentIntegration.name !== projectIntegration.integration.integrationType.toLowerCase()) ||
            data.disableImportIntegrations
        "
        sources="[projectIntegration.integration.integrationType.toLowerCase()]"
        class="inline-block"
        ng-model="ignored"
        state="data.projectIntegrationToStateMap[projectIntegration.id].state"
        open-modal-for-configuration="data.openModalForConfiguration"
        current-integration="data.currentIntegration"
        display-mode="button"
        hide-delete-button="true"
        integration-changed="onIntegrationSaved(data.projectIntegrationToStateMap[projectIntegration.id].state)"
        integration-configuration-canceled="onIntegrationCanceled()"
        configured-in-group-id="data.configuredInGroupId"
        configured-in-workflow-version-id="data.configuredInWorkflowVersionId"
    ></tnk-integration-group>
</div>
<div class="margin-top" ng-hide="data.currentIntegration || data.hideAddButton">
    <a
        class="btn btn-primary"
        ng-click="data.showRefreshBtn = true"
        ng-if="!data.openNewInModal"
        ui-sref="product.enterpriseComponents({enterpriseComponentId: 'new', tab: 'data-sources'})"
        target="_blank"
    >
        <i class="fa fa-plus-circle margin-right-xs"></i>
        Add a Data Source
    </a>
    <a
        class="btn btn-primary"
        ng-click="openAddNewIntegrationModal()"
        ng-if="data.openNewInModal"
        data-automation="integration-selection-add-data-source"
    >
        <i class="fa fa-plus-circle margin-right-xs"></i>
        Add a Data Source
    </a>
</div>
