const storageProviderValidator = (validationObject, definition, form) => {
    if (form && form.formType === 'UPDATE' && form.formQuestionType === 'UPLOAD_FILES') {
        if (!definition?.folderIdExpression || definition?.folderIdExpression?.originalExpression === '') {
            validationObject.destinationFolderIdError = 'Must have destination folder id';
        }

        if (!definition.dataStorageId) {
            validationObject.dataProviderIdError = 'Must select Data Storage';
        }
    }
};

export default storageProviderValidator;
