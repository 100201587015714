import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import useIsInEmptyTrial from './useIsInEmptyTrial';
import CurrentProjectMenuItem from '../components/User/CustomUserMenuItem/CurrentProjectNameMenuItem';
import EnterpriseUserMenuItem from '../components/User/CustomUserMenuItem/EnterpriseManagementMenuItem';
import UserMenuItem from '../components/User/UserMenuItem';
import UserMenuSection from '../entities/UserMenuSection';
import type UserMenuSectionConfig from '../entities/UserMenuSectionConfig';

import { useIsInSharedReport } from '@tonkean/angular-hooks';
import { KnowledgeBase } from '@tonkean/constants';
import { PlusOIcon as PlusIcon } from '@tonkean/svg';
import { LightningIcon as TriggerIcon } from '@tonkean/svg';
import { CogIcon } from '@tonkean/svg';
import { ProfileIcon } from '@tonkean/svg';
import { HistorySearchIcon as SearchIcon } from '@tonkean/svg';
import { QuestionMarkIcon } from '@tonkean/svg';
import { SignOutIcon } from '@tonkean/svg';
import { UserIcon } from '@tonkean/svg';
import { ReportIcon } from '@tonkean/svg';
import { LightBulbIcon } from '@tonkean/svg';

function useUserPagesConfiguration() {
    const authenticationService = useAngularService('authenticationService');
    const modal = useAngularService('modal');
    const projectManager = useAngularService('projectManager');
    const licensePermissionsService = useAngularService('licensePermissionsService');
    const isInSharedReport = useIsInSharedReport();
    const isInEmptyTrial = useIsInEmptyTrial();

    const settingsConfiguration = useMemo<Record<UserMenuSection, UserMenuSectionConfig[]>>(() => {
        return {
            [UserMenuSection.MY_ITEMS]: [
                {
                    name: 'Invite People',
                    icon: PlusIcon,
                    onClick: () => modal.openInvite(null, null, null, null, null),
                    isShown:
                        projectManager.isUserLicensed &&
                        !projectManager.viewOnlyMode &&
                        !isInEmptyTrial &&
                        projectManager?.project?.systemUsersSource === 'MANUAL' &&
                        !isInSharedReport,
                    accessibleToAllUsers: false,
                    component: UserMenuItem,
                },
                {
                    name: 'My Items Box',
                    icon: TriggerIcon,
                    state: 'updateState',
                    isShown: !isInEmptyTrial && !isInSharedReport,
                    accessibleToAllUsers: false,
                    component: UserMenuItem,
                },
            ],
            [UserMenuSection.ADMINISTRATION]: [
                {
                    name: 'Board Settings',
                    icon: CogIcon,
                    state: 'product.settings.board',
                    isShown: !isInEmptyTrial && !isInSharedReport,
                    accessibleToAllUsers: false,
                    component: UserMenuItem,
                },
                {
                    name: 'Profile',
                    icon: ProfileIcon,
                    state: 'product.settings.profile',
                    isShown: !isInEmptyTrial && !isInSharedReport,
                    accessibleToAllUsers: false,
                    component: UserMenuItem,
                },
            ],
            [UserMenuSection.BOARD]: [
                {
                    name: projectManager.project.name,
                    icon: ReportIcon,
                    state: 'product',
                    params: { projectId: projectManager?.project?.id },
                    isShown: !isInEmptyTrial && !isInSharedReport,
                    accessibleToAllUsers: true,
                    component: CurrentProjectMenuItem,
                },
                {
                    name: 'Browse All Boards',
                    icon: SearchIcon,
                    state: 'products',
                    isShown: Boolean(
                        !isInEmptyTrial && authenticationService?.getCurrentUserSafe()?.isUser && !isInSharedReport,
                    ),
                    accessibleToAllUsers: true,
                    component: UserMenuItem,
                },
            ],
            [UserMenuSection.SIGN_OUT]: [
                {
                    name: 'Feedback & Help',
                    icon: QuestionMarkIcon,
                    onClick: () => {
                        Intercom('showNewMessage');
                    },
                    isShown: true,
                    accessibleToAllUsers: true,
                    component: UserMenuItem,
                },
                {
                    name: 'Docs & Knowledge Base',
                    icon: LightBulbIcon,
                    onClick: () => {
                        window.open(KnowledgeBase.baseUrl);
                    },
                    isShown: true,
                    accessibleToAllUsers: false,
                    component: UserMenuItem,
                },
                {
                    name: 'Sign Out',
                    icon: SignOutIcon,
                    onClick: () => {
                        authenticationService.logout();
                    },
                    section: UserMenuSection.ADMINISTRATION,
                    isShown: true,
                    accessibleToAllUsers: true,
                    component: UserMenuItem,
                },
            ],
            [UserMenuSection.ENTERPRISE]: [
                {
                    name: `${authenticationService.getCurrentUser().enterprise?.name || ''} Administration`,
                    icon: UserIcon,
                    state: 'enterprise',
                    isShown: licensePermissionsService.isEnterpriseUser(),
                    accessibleToAllUsers: false,
                    component: EnterpriseUserMenuItem,
                },
            ],
        };
    }, [
        authenticationService,
        isInEmptyTrial,
        isInSharedReport,
        licensePermissionsService,
        modal,
        projectManager.isUserLicensed,
        projectManager.project?.id,
        projectManager.project?.name,
        projectManager.project?.systemUsersSource,
        projectManager.viewOnlyMode,
    ]);

    return { settingsConfiguration };
}

export default useUserPagesConfiguration;
