import type { RequestThrottler } from '@tonkean/shared-services';

class ExternalEntitySelectorCtrl implements angular.IController {
    /* @ngInject */

    public declare projectIntegrationId: string;
    public declare externalType: string;
    public declare externalTypePluralName: string;
    public declare onlyUpdateable: boolean;
    public declare selectedEntity?: any;
    public declare onEntitySelected?: (entity?: Record<string, any>) => void;

    public query: string = '';
    public items: any[];
    public loading = false;
    public error = false;

    constructor(public tonkeanService, public requestThrottler: RequestThrottler) {
        this.externalTypePluralName = 'items';
    }

    $onInit() {
        this.items = [];
        this.addItems();
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        // onChanges runs before onInit. To prevent double fetching, if the items list is undefined, it means that
        // onInit was not called yet and onChanges should be ignored.
        if (!this.items) {
            return;
        }

        // If the external type, project integration id or `only updateable` changes, we should re-fetch the items
        if (onChangesObj?.externalType || onChangesObj?.projectIntegrationId || onChangesObj?.onlyUpdateable) {
            this.fetchExampleEntities().then((items) => {
                this.items = items || [];

                // If the selected entity is not included in the newly fetched items, we should unset it
                if (this.selectedEntity && this.items.every(({ id }) => id !== this.selectedEntity.id)) {
                    this.selectedEntity = undefined;
                    this.emitChanges();
                }
            });
        }
    }

    /**
     * Throttled function that updates the query term and fetches new items.
     *
     * @param query - the new query term to search by.
     */
    public onQueryChanged(query: string) {
        const trimmedQuery = query.trim();

        if (this.query === trimmedQuery) {
            return;
        }

        this.query = trimmedQuery;

        this.requestThrottler.do('fetchEntitiesInExternalEntitySelector', 200, () => this.addItems());
    }

    /**
     * Update the selected item and emit it.
     *
     * @param item - the new selected item.
     */
    public onItemSelected(item) {
        this.selectedEntity = item;
        this.emitChanges();
    }

    /**
     * Emit change event.
     */
    private emitChanges() {
        this.onEntitySelected?.(this.selectedEntity);
    }

    /**
     * Fetch items and append them to the items list.
     * It will not delete existing items, unless the fetchExampleEntities returned a newer version of them.
     */
    private async addItems() {
        // Fetch new items
        const newItems = (await this.fetchExampleEntities()) || [];

        // Remove existing items that were now fetched to have only the newest version of the items
        const unchangedItems = this.items.filter((existingItem) =>
            newItems.every((newItem) => newItem.id !== existingItem.id),
        );

        this.items = [...unchangedItems, ...newItems];
    }

    /**
     * Fetch items based on query.
     *
     * @returns Promise with list of items, or undefined if an error occurred.
     */
    private fetchExampleEntities(): Promise<any[]> {
        this.error = false;
        this.loading = true;

        const query = this.query?.length > 1 ? this.query : undefined;

        return this.tonkeanService
            .getIntegrationExampleEntities(this.projectIntegrationId, this.externalType, query, 20, this.onlyUpdateable)
            .then(({ exampleEntities }) => exampleEntities)
            .catch(() => (this.error = true))
            .finally(() => (this.loading = false));
    }
}

export default angular.module('tonkean.app').controller('ExternalEntitySelectorCtrl', ExternalEntitySelectorCtrl);
