import { useAngularService } from 'angulareact';
import React from 'react';

interface Props {
    customTriggerType: string;
    customTriggerSecondaryType: string;
}

const LongDescriptionSelector: React.FC<Props> = ({ customTriggerType, customTriggerSecondaryType }) => {
    const consts = useAngularService('consts');
    const logicBlockTypesMap = consts.getLogicBlockTypesMap();
    const config = logicBlockTypesMap[customTriggerType]?.[customTriggerSecondaryType];
    const Component = config?.longDescriptionComponent;
    if (Component) {
        return <Component />;
    } else {
        return null;
    }
};

export default LongDescriptionSelector;
