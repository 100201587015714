import { reactToAngular } from 'angulareact';

import { ImportInnerItemsFromCSV } from '@tonkean/infrastructure';

angular
    .module('tonkean.app')
    .component(
        'tnkImportInnerItemsFromCsvModal',
        reactToAngular(ImportInnerItemsFromCSV, [
            'formName',
            'fieldIdsToFieldNames',
            'onMappingsCreated',
            'onExited',
            'disabled',
        ]),
    );
