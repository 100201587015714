import { useAngularService } from 'angulareact';
import React, { type CSSProperties, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import InterfaceQuickNav from '../../../components/InterfaceQuickNavigation/InterfaceQuickNav';

import {
    Breakpoint,
    DataNotUpToDateIndicator,
    H2,
    H3,
    LayoutGridElements,
    Placeholder,
    TextEllipsis,
    useInitiativeExpressionServerEvaluation,
    useItemInterfaceContext,
    useLastDefinedValue,
    widthByLayoutGridColumns,
} from '@tonkean/infrastructure';
import {
    type Initiative,
    ItemInterfaceThemeTextAlignment,
    useItemInterfaceHeaderThemeConfiguration,
    type WidgetBase,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const InterfaceAlignmentToAlignItemsCssValue: Record<ItemInterfaceThemeTextAlignment, CSSProperties['alignItems']> = {
    [ItemInterfaceThemeTextAlignment.LEFT]: 'flex-start',
    [ItemInterfaceThemeTextAlignment.CENTER]: 'center',
    [ItemInterfaceThemeTextAlignment.RIGHT]: 'flex-end',
};

type HeaderProps = {
    $backgroundColor?: string;
    $alignTextTo?: ItemInterfaceThemeTextAlignment;
    $tabsView?: boolean;
};

const Header = styled.header<HeaderProps>`
    display: flex;
    justify-content: center;

    font-size: ${FontSize.SMALL_12};

    ${({ $backgroundColor }) =>
        $backgroundColor &&
        css`
            border-top: 8px solid ${$backgroundColor};
        `};

    ${({ $tabsView }) =>
        $tabsView &&
        css`
            background: ${Theme.colors.basicBackground};
            padding-bottom: 0 !important;
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `};

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        border-top: none;
        padding: 8px;
    }
`;

const HeaderContent = styled.div`
    ${widthByLayoutGridColumns};

    gap: 14px;

    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;
    ${LayoutGridElements.CONTAINER_WIDTH}: 100%;
`;

const TextEllipsisStyled = styled(TextEllipsis)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const InterfaceTitle = styled(H2)<{ isBold?: boolean; $tabsView?: boolean }>`
    font-size: ${FontSize.XXXXXLARGE_28};
    font-weight: ${(isBold) => (isBold ? 700 : 400)};
    padding-left: 11px;
    ${({ $tabsView }) =>
        $tabsView &&
        css`
            font-weight: 500;
        `};

    line-height: 32px;
`;

const InterfaceSubTitle = styled(H3)`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.XLARGE_18};
    line-height: 20px;
    padding-left: 11px;
`;

const TitleTextWrapper = styled.div<{ $alignTextTo?: ItemInterfaceThemeTextAlignment }>`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: ${({ $alignTextTo = ItemInterfaceThemeTextAlignment.CENTER }) =>
        InterfaceAlignmentToAlignItemsCssValue[$alignTextTo]};
    margin-bottom: 8px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`;

const ActionButtonWrapper = styled.div`
    z-index: 2;
`;
export interface Props extends StyledComponentsSupportProps {
    initiative: Initiative | undefined;
    widgets: WidgetBase[];
    textAlignment?: ItemInterfaceThemeTextAlignment;
    textColor?: string;
    isBold?: boolean;
    onHasTitlesChanged?: (value: boolean) => void;
    tabsView: boolean;
    actionButton?: React.ReactNode;
}

const ItemInterfaceHeaderTitles: React.FC<Props> = ({
    initiative,
    widgets,
    textAlignment,
    textColor,
    isBold,
    onHasTitlesChanged,
    tabsView,
    className,
    actionButton: ActionButton,
}) => {
    const { itemInterface, project, workflowVersion } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);
    const {
        values: [title, subtitle],
        loading: loadingTitles,
    } = useInitiativeExpressionServerEvaluation([themeConfiguration.title, themeConfiguration.subtitle], initiative);

    const groupInfoManager = useAngularService('groupInfoManager');
    const lastTitle = useLastDefinedValue<string | undefined>(title);
    const lastSubTitle = useLastDefinedValue<string | undefined>(subtitle);

    const [isWorkerEnabled, setIsWorkerEnabled] = useState(true);

    const checkIfWorkerEnabled = useCallback(async () => {
        if (workflowVersion?.groupId) {
            const group =
                groupInfoManager.getGroupByIdFromCache(workflowVersion.groupId) ||
                (await groupInfoManager.getGroupById(workflowVersion.groupId));

            setIsWorkerEnabled(
                workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT
                    ? group.buildEnvironmentEnabled
                    : group.workerEnabled,
            );
        }
    }, [groupInfoManager, workflowVersion?.groupId, workflowVersion?.workflowVersionType]);

    useEffect(() => {
        checkIfWorkerEnabled();
    }, [checkIfWorkerEnabled]);

    // We dont want to show the loading state when we already have a previous value (looks smoother)
    // BUT if the new titles were changed to empty we want to show the empty string and not the previous state
    const finalTitle = title === undefined ? lastTitle : title;
    const finalSubtitle = subtitle === undefined ? lastSubTitle : subtitle;
    const showLoadingTitle = !finalTitle && loadingTitles;
    const showLoadingSubtitle = !finalSubtitle && loadingTitles;

    // This is to communicate to parent component if this component is showing the titles lines.
    // If we are loading, we are showing the placeholder, if we are showing a value it will have no height if the value is empty
    const hasTitle = showLoadingTitle ? true : Boolean(finalTitle);
    const hasSubtitle = showLoadingSubtitle ? true : Boolean(finalSubtitle);
    const hasTitles = hasTitle || hasSubtitle;

    useEffect(() => {
        onHasTitlesChanged?.(hasTitles);
    }, [hasTitles, onHasTitlesChanged]);

    const finalTextAlignment = textAlignment || themeConfiguration.textAlignment;

    return (
        <Header className={className} $tabsView={tabsView}>
            <HeaderContent
                data-automation="item-interface-view-header-content"
                data-automation-label={themeConfiguration.textAlignment}
            >
                <TitleWrapper>
                    <TitleTextWrapper $alignTextTo={finalTextAlignment}>
                        {hasTitle && (
                            <InterfaceTitle
                                $color={textColor || themeConfiguration.textColor || Theme.colors.gray_800}
                                data-automation="item-interface-view-header-title"
                                isBold={isBold}
                                $tabsView={tabsView}
                            >
                                {showLoadingTitle ? (
                                    <Placeholder $width="300px" $borderRadius="8px" $height="28px" />
                                ) : (
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {finalTitle}
                                    </TextEllipsis>
                                )}
                            </InterfaceTitle>
                        )}
                        {hasSubtitle && (
                            <InterfaceSubTitle
                                $color={textColor || themeConfiguration.textColor}
                                data-automation="item-interface-view-header-sub-title"
                            >
                                {showLoadingSubtitle ? (
                                    <Placeholder $width="200px" $borderRadius="4px" $height="20px" />
                                ) : (
                                    <TextEllipsisStyled tooltip>
                                        <DataNotUpToDateIndicator show={!isWorkerEnabled} />
                                        {finalSubtitle}
                                    </TextEllipsisStyled>
                                )}
                            </InterfaceSubTitle>
                        )}
                    </TitleTextWrapper>

                    <ActionButtonWrapper>{ActionButton}</ActionButtonWrapper>
                </TitleWrapper>

                <InterfaceQuickNav
                    quickNavigationMode={itemInterface.configuration?.quickNavigationMode}
                    widgets={widgets}
                    themeColor={themeConfiguration.headerBackgroundColor}
                    tabsViewEnabled={tabsView}
                    alignment={finalTextAlignment}
                />
            </HeaderContent>
        </Header>
    );
};

export default ItemInterfaceHeaderTitles;
