<div class="margin-left margin-top">
    <div ng-if="data.isActionOnChannel">
        <!-- Service ID -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Service ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a service ID the target channel is in..."
                saved-expression="data.definition.serviceIdExpression"
                on-tonkean-expression-changed="onServiceIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noService" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noService }}
            </div>
        </div>

        <!-- Channel ID -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Channel ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a channel ID to send the message to..."
                saved-expression="data.definition.channelIdExpression"
                on-tonkean-expression-changed="onChannelIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noChannel" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noChannel }}
            </div>
        </div>
    </div>

    <div ng-if="data.isActionOnTask">
        <!-- Workspace ID -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Workspace ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a workspace ID the target task is in..."
                saved-expression="data.definition.workspaceIdExpression"
                on-tonkean-expression-changed="onWorkspaceIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noWorkspace" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noWorkspace }}
            </div>
        </div>

        <!-- Task ID -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Task ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a task ID to send the message to..."
                saved-expression="data.definition.taskIdExpression"
                on-tonkean-expression-changed="onTaskIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noTask" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noTask }}
            </div>
        </div>
    </div>

    <hr />

    <!-- Reply to Channel -->
    <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'REPLY_TO_CHANNEL'">
        <!-- Message -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Message:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the message to send"
                saved-expression="data.definition.messageExpression"
                on-tonkean-expression-changed="onMessageTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- From -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">From:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type from..."
                saved-expression="data.definition.fromExpression"
                on-tonkean-expression-changed="onFromTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Attributes -->
        <div class="margin-bottom">
            <div class="margin-bottom-xs">Attributes JSON:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="A custom JSON object"
                saved-expression="data.definition.attributesExpression"
                on-tonkean-expression-changed="onAttributesTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Add\Update Channel Attributes-->
    <div
        class="delay-configuration-logic"
        ng-if="
            data.definition.customActionKey === 'ADD_OR_UPDATE_CHANNEL_ATTRIBUTES' ||
            data.definition.customActionKey === 'ADD_OR_UPDATE_TASK_ATTRIBUTES'
        "
    >
        <div
            ng-repeat="attribute in data.attributesToUpdate"
            class="
                margin-bottom
                twilio-attribute-to-update-container
                common-border-dark-softer
                padding-normal-xs
                margin-right
            "
        >
            <div>Property</div>
            <tnk-tonkean-expression
                class="margin-bottom-xs"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Attribute Name"
                saved-expression="attribute.nameExpression"
                on-tonkean-expression-changed="
                    onAttributeNameTonkeanExpressionChanged(expression, shouldSaveLogic, attribute)
                "
            ></tnk-tonkean-expression>

            <div>Value</div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Attribute Value"
                saved-expression="attribute.valueExpression"
                on-tonkean-expression-changed="
                    onAttributeValueTonkeanExpressionChanged(expression, shouldSaveLogic, attribute)
                "
            ></tnk-tonkean-expression>
        </div>

        <a ng-click="addEmptyAttributeToUpdate()">+ Add Attribute</a>
    </div>
</div>
