<div>
    <form name="addUserForm">
        <div class="min-width">
            <div class="flex mod-align-start">
                <div
                    class="
                        common-color-black common-bold common-size-xxs common-width-25
                        flex-no-shrink
                        margin-top-xxs
                        padding-top-xs
                    "
                >
                    People:
                </div>
                <tnk-deprecated-people-selector
                    class="flex-grow common-width-75 people-selector-border"
                    selected-people="data.selectedPeople"
                    no-custom="false"
                    max-tags="{{data.maxTags}}"
                    no-validation="false"
                    max-tag-chars="22"
                    on-tag-added="updateSelected()"
                    placeholder="{{::data.placeholder || 'Search for a person'}}"
                    include-external="true"
                ></tnk-deprecated-people-selector>
            </div>
        </div>

        <hr class="common-separator margin-top margin-bottom" />

        <div class="flex-vmiddle margin-top" style="min-width: 290px">
            <div class="flex-grow"></div>
            <button class="btn btn-default flex-no-shrink" ng-click="close()">Cancel</button>
            <button
                type="submit"
                class="btn btn-primary margin-left-xs flex-no-shrink"
                ng-disabled="popover.posting"
                ng-click="save()"
            >
                Save
            </button>
        </div>
    </form>
</div>
