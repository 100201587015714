import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import type { Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

export interface EntityToSolutionMapping {
    mapping: { [workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>]: string[] };
    workflowFolders: { [workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>]: WorkflowFolder };
    groups: { [groupId: TonkeanId<TonkeanType.GROUP>]: Group };
}

/**
 * Fetches and returns all the modules and solutions dependent on a project integration action.
 */
function useProjectIntegrationActionToSolutionAndGroupMapping(
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    entityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>,
): EntityToSolutionMapping {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const project = useProject();

    const { data: groups } = useTonkeanService(
        'getProjectIntegrationActionDependentGroups',
        projectIntegrationId,
        entityId,
    );

    // Create a map of id to the group data
    const groupsMap = useMemo(() => {
        return Object.fromEntries(groups?.map((group) => [group.id, group]) || []);
    }, [groups]);

    // Create a map of id to the workflow folder data
    const workflowFolders = useMemo(
        () =>
            Object.fromEntries(
                groups
                    ?.map((group) => {
                        const workflowFolder: WorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                            project.id,
                            group.id,
                        );
                        return [workflowFolder.id, workflowFolder];
                    })
                    .filter(([workflowFolderId]) => !!workflowFolderId) || [],
            ),
        [workflowFolderManager, project.id, groups],
    );

    // Fill the mapping between project integration action, solution and module
    const projectIntegrationActionToSolutionAndGroup = useMemo(
        () =>
            groups
                ?.map((group): [TonkeanId<TonkeanType.WORKFLOW_FOLDER>, TonkeanId<TonkeanType.GROUP>] => {
                    const workflowFolder: WorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                        project.id,
                        group.id,
                    );
                    return [workflowFolder?.id, group.id];
                })
                .filter(([workflowFolderId]) => !!workflowFolderId)
                .reduce((map, [workflowFolderId, groupId]) => {
                    if (!map[workflowFolderId]) {
                        map[workflowFolderId] = [groupId];
                    } else {
                        map[workflowFolderId] = [...map[workflowFolderId], groupId];
                    }
                    return map;
                }, {}) || {},
        [workflowFolderManager, project.id, groups],
    );

    return {
        mapping: projectIntegrationActionToSolutionAndGroup,
        workflowFolders,
        groups: groupsMap,
    };
}

export default useProjectIntegrationActionToSolutionAndGroupMapping;
