import type { FieldDefinitionExtended, Initiative, WorkflowVersion } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

export interface InitiativeFieldValue {
    value: number | string | undefined;
    formattedValue: string | undefined;
    change: string | undefined;
    id: string | undefined;
    appliedRangeNumber: number | undefined;
}

/**
 * Gets the field value in the current initiative as string. not requesting from server, only gets the current value
 * @param initiative - the initiative to extract the value from
 * @param fieldDefinition - the field defintion id or the name of the basic field
 * @param workflowVersion - the workflow version to use when extracting the data from the initiative
 *
 * return the field value in a string.
 */
function getInitiativeFieldValue(
    initiative: Initiative,
    fieldDefinition: FieldDefinitionExtended,
    workflowVersion: WorkflowVersion,
    getDisplayValueOnly: boolean = false,
): InitiativeFieldValue | undefined {
    const field = initiative.defIdToValidFieldsMap?.[fieldDefinition.id]?.[0];

    if (!getDisplayValueOnly && fieldDefinition.extractServerValue) {
        return {
            value: fieldDefinition.extractServerValue(initiative, workflowVersion),
            formattedValue: '',
            change: field?.change,
            id: field?.id,
            appliedRangeNumber: field?.appliedRangeNumber,
        };
    } else if (fieldDefinition.extractValueFromInitiative) {
        return {
            value: fieldDefinition.extractValueFromInitiative(initiative, workflowVersion),
            formattedValue: '',
            change: field?.change,
            id: field?.id,
            appliedRangeNumber: field?.appliedRangeNumber,
        };
    }

    if (field) {
        switch (fieldDefinition.fieldType) {
            case FieldType.Date:
                return {
                    value: field.dateValue || field.value,
                    formattedValue: field.formattedValue,
                    change: field?.change,
                    id: field?.id,
                    appliedRangeNumber: field?.appliedRangeNumber,
                };
            case FieldType.Number:
                return {
                    value: field.numberValue || field.value,
                    formattedValue: field.formattedValue,
                    change: field?.change,
                    id: field?.id,
                    appliedRangeNumber: field?.appliedRangeNumber,
                };
            default:
                return {
                    value: field.value,
                    formattedValue: field.formattedValue,
                    change: field?.change,
                    id: field?.id,
                    appliedRangeNumber: field?.appliedRangeNumber,
                };
        }
    }
}

export default getInitiativeFieldValue;
