import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const UkgComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [baseUrl, setBaseUrl] = useState<string>();
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [userAccessKey, setUserAccessKey] = useState<string>();
    const [clientAccessKey, setClientAccessKey] = useState<string>();

    const authenticateWithUserAndOrg = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            const subdomain = getBaseUrlSubdomain(baseUrl);

            const config = {
                baseUrl,
                username,
                password,
                userAccessKey,
                clientAccessKey,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${subdomain})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    const getBaseUrlSubdomain = (url) => {
        const domain = new URL(url);
        return domain.hostname.split('.')[0];
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithUserAndOrg}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="loginBaseUrl" className="col-sm-5 control-label">
                            Web Service Base URL
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={baseUrl}
                                className="form-control"
                                id="loginBaseUrl"
                                onChange={({ target }) => setBaseUrl(target.value)}
                                autoComplete="off"
                                placeholder="Base URL"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                The base service URL will be similar to{' '}
                                <span className="common-bold common-color-dark-grey">https://servicet.ultipro.com</span>
                                , but will vary by customer datacenter and production/test environments.
                                <br />
                                More info can be found{' '}
                                <a href="https://connect.ultipro.com/web-service-accounts" target="_blank">
                                    here
                                </a>
                                .
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="loginUsername" className="col-sm-5 control-label">
                            Web Service Account Username
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="loginUsername"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="loginPassword" className="col-sm-5 control-label">
                            Web Service Account Password
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="loginPassword"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="loginUserAccessKey" className="col-sm-5 control-label">
                            User Access (API) Key
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={userAccessKey}
                                className="form-control"
                                id="loginUserAccessKey"
                                onChange={({ target }) => setUserAccessKey(target.value)}
                                autoComplete="off"
                                placeholder="User Access key"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="loginClientAccessKey" className="col-sm-5 control-label">
                            Customer Access (API) Key
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={clientAccessKey}
                                className="form-control"
                                id="loginClientAccessKey"
                                onChange={({ target }) => setClientAccessKey(target.value)}
                                autoComplete="off"
                                placeholder="Customer Access Key"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UkgComponent;
