<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>

    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <!-- Client ID -->
            <div class="form-group">
                <label for="login-api-cid" class="col-sm-4 control-label">Service Account Key Json:</label>

                <div class="col-sm-8">
                    <textarea
                        ng-model="data.credentialsJson"
                        class="form-control"
                        id="login-api-cid"
                        autocomplete="off"
                        placeholder="Credentials Json"
                        required
                    ></textarea>
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <!-- Project Number -->
            <div class="form-group">
                <label for="login-api-cid" class="col-sm-4 control-label">Project Number:</label>

                <div class="col-sm-8">
                    <input
                        ng-model="data.projectNumber"
                        class="form-control"
                        autocomplete="off"
                        placeholder="Project Number"
                        required
                        type="text"
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <div class="margin-bottom-lg info-box common-size-xxs">
                <!-- Title -->
                <p class="common-color-dark-grey common-underline">Setup instructions:</p>

                <!-- Instructions -->
                <div class="common-color-dark-grey">
                    <ul>
                        <li class="margin-bottom">
                            You should create a
                            <a href="https://console.cloud.google.com/iam-admin/serviceaccounts" target="_blank">
                                service account
                            </a>
                            dedicated to Tonkean, and create a JSON key file for it.
                            <br />
                            Copy the key file content and paste it into the
                            <strong>"Service Account Key Json:"</strong>
                            text area.
                        </li>
                        <li>
                            The project number can be found in the Google Cloud Platform
                            <a href="https://console.developers.google.com/iam-admin/settings" target="_blank">
                                IAM & Admin
                            </a>
                            dashboard under the settings tab.
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        ng-disabled="!data.credentialsJson || !data.projectNumber"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
