import { reactToAngular } from '@tonkean/angular-components';
import { TagsExpression } from '@tonkean/expression-editor';

angular
    .module('tonkean.app')
    .component(
        'tnkTagsExpression',
        reactToAngular(TagsExpression, [
            'setControlObject',
            'onExpressionUpdated',
            'getVariableIconClassName',
            'getProjectIntegrationTypeAndId',
            'expression',
            'groupId',
            'workflowVersionId',
            'customTriggerId',
            'translateVariableLabel',
            'debounceOnChange',
            'placeholder',
            'prefix',
            'className',
            'automationIdentifierExpressionValue',
            'additionalTabs',
        ]),
    );
