import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import BusinessReportAccessibilityEditButton from './BusinessReportAccessibilityEditButton';
import SolutionManagingModalHeader from './SolutionManagingModalHeader';
import { ReactComponent as BusinessReportIcon } from '../../../../images/icons/navigation/live-reports.svg';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ModalBody, ModalSize, Placeholder, Table, withModal } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessibilitySummary } from '@tonkean/tonkean-entities';
import { BusinessReportAccessType } from '@tonkean/tonkean-entities';
import { GroupAccessibilityType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const TableCell = styled.div`
    color: ${Theme.colors.gray_800};
`;

interface Props {
    workflowFolder: WorkflowFolder;
}

const BusinessReportsAccessibilityModal: React.FC<Props> = ({ workflowFolder }) => {
    const {
        data: businessReportsResponse,
        error: errorLoadingBusinessReports,
        loading: loadingBusinessReports,
    } = useTonkeanService('getWorkflowFolderBusinessReports', workflowFolder.id);

    const [businessReports, setBusinessReports] = useState<BusinessReportAccessibilitySummary[]>([]);

    useEffect(() => {
        if (businessReportsResponse?.entities?.length) {
            setBusinessReports(businessReportsResponse?.entities);
        }
    }, [businessReportsResponse]);

    const columns = useMemo(
        () => [
            {
                Header: `Business Reports${
                    businessReportsResponse?.entities.length ? ` (${businessReportsResponse?.entities.length})` : ''
                }`,
                accessor: 'name' as const,
                width: '50%',
            },
            {
                Header: 'Who Can View',
                accessor: 'whoCanView' as const,
                width: '50%',
            },
            {
                Header: 'Edit',
                accessor: 'edit' as const,
            },
        ],
        [businessReportsResponse],
    );

    const placeHolderRows = useMemo(() => {
        return range(3).map(() => {
            return {
                name: <Placeholder $width="100%" />,
                whoCanView: <Placeholder $width="100%" />,
                edit: <Placeholder $width="100%" />,
            };
        });
    }, []);

    const businessReportRows = useMemo(() => {
        if (businessReports.length) {
            return businessReports.map((businessReport) => {
                return {
                    name: <TableCell>{businessReport.groupName}</TableCell>,
                    whoCanView: (
                        <TableCell>
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PRIVATE &&
                                'Private (Based on module settings)'}
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC &&
                                businessReport.businessReportAccessibilityType === BusinessReportAccessType.EVERYONE &&
                                'Everyone'}
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC &&
                                businessReport.businessReportAccessibilityType ===
                                    BusinessReportAccessType.ONLY_MAKERS &&
                                'Only Makers'}
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC &&
                                businessReport.businessReportAccessibilityType ===
                                    BusinessReportAccessType.ONLY_SOLUTION_COLLABORATORS &&
                                'Only Makers & Publishers'}
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC &&
                                businessReport.businessReportAccessibilityType ===
                                    BusinessReportAccessType.SOLUTION_COLLABORATORS_AND_SPECIFIC_PEOPLE &&
                                'Makers, Publishers & Specific People'}
                            {businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC &&
                                businessReport.businessReportAccessibilityType ===
                                    BusinessReportAccessType.SPECIFIC_PEOPLE &&
                                'Specific People'}
                        </TableCell>
                    ),
                    edit:
                        businessReport.groupAccessibilityType === GroupAccessibilityType.PUBLIC ? (
                            <BusinessReportAccessibilityEditButton
                                businessReport={businessReport}
                                onEdit={(newBusinessReportAccessibilityType, specificPeople) => {
                                    setBusinessReports(
                                        businessReports.map((currentReport) => {
                                            if (currentReport.groupId === businessReport.groupId) {
                                                return {
                                                    ...currentReport,
                                                    businessReportAccessibilityType: newBusinessReportAccessibilityType,
                                                    specificPeopleWithAccess: specificPeople,
                                                };
                                            } else {
                                                return currentReport;
                                            }
                                        }),
                                    );
                                }}
                            />
                        ) : (
                            <></>
                        ),
                };
            });
        } else {
            return [];
        }
    }, [businessReports]);

    return (
        <div>
            <ModalBody>
                <SolutionManagingModalHeader workflowFolder={workflowFolder} icon={<BusinessReportIcon />}>
                    Manage permissions
                </SolutionManagingModalHeader>

                {loadingBusinessReports && <Table columns={columns} data={placeHolderRows} />}
                {errorLoadingBusinessReports && 'Error loading business reports...'}

                {!!businessReports.length && <Table columns={columns} data={businessReportRows} />}
            </ModalBody>
        </div>
    );
};

export default withModal(BusinessReportsAccessibilityModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
});
