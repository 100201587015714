import React, { useCallback } from 'react';

import useGetLoadedFieldDefinitionById from '../../widgets/hooks/useGetLoadedFieldDefinitionById';

import { useToastMessage } from '@tonkean/angular-hooks';
import { UpdateField } from '@tonkean/fields';
import type { FieldDefinition, FieldFilter, Group, Project, State, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

interface Props {
    fieldFilter: FieldFilter | undefined;
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersion: WorkflowVersion;
    fieldDefinition: FieldDefinition;
    onFilterChanged: (newVal: any) => void;
    label: string;
    onChangeFormattedLabel?: (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => void;
}

const WidgetItemsStatusFilter: React.FC<Props> = ({
    fieldFilter,
    projectId,
    groupId,
    workflowVersion,
    fieldDefinition,
    onFilterChanged,
    label,
    onChangeFormattedLabel,
}) => {
    const emitToastMessage = useToastMessage();
    const getLoadedFieldDefinitionById = useGetLoadedFieldDefinitionById(workflowVersion?.id);

    const onValueChanged = useCallback(
        (newVal: State['id']) => {
            const status: State | undefined = workflowVersion?.states?.find((state) => state.id === newVal);
            if (status) {
                onFilterChanged(status.label);
                onChangeFormattedLabel?.(fieldDefinition.id, status.label);
            } else {
                emitToastMessage(`Failed filtering field "${label}"`, 'danger');
            }
        },
        [workflowVersion?.states, onFilterChanged, onChangeFormattedLabel, fieldDefinition.id, emitToastMessage, label],
    );

    return (
        <UpdateField
            fieldDefinition={fieldDefinition}
            onValueSave={onValueChanged}
            projectId={projectId}
            groupId={groupId}
            initiative={undefined}
            workflowVersion={workflowVersion}
            viewOnly={false}
            fieldsAreBeingEdited={false}
            isSubmittingForm={false}
            overrideValue={fieldFilter?.value}
            getLoadedFieldDefinitionById={getLoadedFieldDefinitionById}
            showEmptyFieldWhenNoInitiative
            hideColorsAndTrends
            revealByDefault
            shouldOverrideValue
            isInPopover
            isFilterInput
        />
    );
};

export default WidgetItemsStatusFilter;
