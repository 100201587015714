import template from './tnkImportActionConfigFromCurl.template.html?angularjs';

/**
 * Component description goes here
 */
export default angular.module('tonkean.app').component('tnkImportActionConfigFromCurl', {
    bindings: {
        onSuccessfulParse: '&',
        onError: '&',
    },
    template,
    controller: 'ImportActionConfigFromCurlCtrl',
});
