import React from 'react';
import styled from 'styled-components';

import { InformationTooltip, SimpleSelect } from '@tonkean/infrastructure';
import type { ItemInterfaceWidgetOptionsConfiguration } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';

type TitlePosition = 'top' | 'left';

const Wrapper = styled.div<{ titlePosition: TitlePosition }>`
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ titlePosition }) => (titlePosition === 'top' ? 'column' : 'row')};
    align-items: ${({ titlePosition }) => (titlePosition === 'top' ? 'start' : 'center')};
`;

const LabelTooltipWrap = styled.div`
    display: flex;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    margin-right: 10px;
`;

const SelectorWrapper = styled.div<{ titlePosition: TitlePosition }>`
    width: ${({ titlePosition }) => (titlePosition === 'top' ? '100%' : undefined)};
    margin-top: ${({ titlePosition }) => (titlePosition === 'top' ? '8px' : '0px')};
`;

interface Props {
    /**
     * The title for the toggle
     */
    title: string;

    /**
     * The title position
     */
    titlePosition?: TitlePosition;

    /**
     * The tooltip text
     */
    toolTip?: string;
    /**
     * Formik field name
     */
    name: string;

    selectorSize?: InputComponentSizes;

    /**
     * Whether the drop down selector is searchable
     */
    isSearchable?: boolean;

    dataAutomation?: string;

    options: ItemInterfaceWidgetOptionsConfiguration[];
}

const WidgetConfigurationSelector: React.FC<Props> = ({
    title,
    titlePosition = 'top',
    toolTip,
    name,
    selectorSize,
    isSearchable,
    dataAutomation,
    options,
}) => {
    return (
        <Wrapper titlePosition={titlePosition}>
            <LabelTooltipWrap>
                <Label>{title}</Label>
                {toolTip && <InformationTooltip>{toolTip}</InformationTooltip>}
            </LabelTooltipWrap>
            <SelectorWrapper titlePosition={titlePosition}>
                <SimpleSelect
                    dataAutomation={`widget-configuration-select-${dataAutomation}`}
                    options={options}
                    name={name}
                    size={selectorSize}
                    isSearchable={isSearchable}
                    thin
                />
            </SelectorWrapper>
        </Wrapper>
    );
};

export default React.memo(WidgetConfigurationSelector);
