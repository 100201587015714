import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import getProjectIntegrationActionFetcherAsTableRow from './getProjectIntegrationActionFetcherAsTableRow';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import EnterpriseComponentSearch from '../../EnterpriseComponentsModule/components/EnterpriseComponentSearch';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationActionCreateModal from '../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationCreateAction/components/ProjectIntegrationActionCreateModal';
import getTrainingSetAsTableRowWithState from '../../TrainingSetsModule/modules/TrainingSetsModule/utils/getTrainingSetAsTableRowWithState';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { H1, Paragraph, Table, useModifiableList } from '@tonkean/infrastructure';
import type { IntegrationSupportedEntity } from '@tonkean/tonkean-entities';
import { ActionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const SearchRow = styled.div`
    margin-top: 16px;
    display: flex;
    width: 1040px;
`;

const StyledTable = styled(Table)`
    margin-top: 20px;
    width: 1000px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 10px;

    display: flex;
    align-items: center;
`;

const Tr = styled.tr`
    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

const ProjectIntegrationEntityEntityFetcherPage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const $state = useAngularService('$state');

    const {
        data: projectIntegrationActionFetcher,
        loading: projectIntegrationActionFetcherLoading,
        error: projectIntegrationActionFetcherError,
    } = useTonkeanService(
        'getProjectIntegrationFetchingActionsByEntityId',
        props.entity.projectIntegrationId,
        props.entity.id,
    );

    const [fetcherActions, { onDelete }] = useModifiableList(projectIntegrationActionFetcher?.entities);

    // We make so getProjectIntegrationActions won't call every react render
    const projectIntegrationActionTypeToFetch = useMemo(() => {
        return [ActionType.FETCHING];
    }, []);

    const { data: projectIntegrationActionsResponse, loading: loadingProjectIntegrationActions } = useTonkeanService(
        'getProjectIntegrationActions',
        props.entity.projectIntegrationId,
        projectIntegrationActionTypeToFetch,
    );

    const projectIntegrationActionNames: string[] = useMemo(() => {
        return projectIntegrationActionsResponse?.entities?.map((action) => action.displayName) || [];
    }, [projectIntegrationActionsResponse]);

    const placeHolderRows = useMemo(() => {
        return range(3).map(() => getTrainingSetAsTableRowWithState());
    }, []);

    const entitiesRows = useMemo(() => {
        return fetcherActions
            ?.filter((fetcherAction) => {
                if (searchTerm) {
                    return fetcherAction.displayName.toLowerCase().includes(searchTerm.toLowerCase());
                }

                return true;
            })
            .map((fetcherAction) => {
                return getProjectIntegrationActionFetcherAsTableRow(
                    fetcherAction,
                    props.entity,
                    props,
                    props.entity.entityFetcherDefinition.fetcherActionId,
                    onDelete,
                );
            });
    }, [fetcherActions, onDelete, props, searchTerm]);

    const onCloseModal = () => {
        setIsCreateModalOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: `Name (${entitiesRows?.length || ''})`,
                accessor: 'name' as const,
                width: '92%',
            },
            {
                Header: 'Actions',
                accessor: 'actions' as const,
                width: '8%',
            },
        ],
        [entitiesRows?.length],
    );

    const defaultEntity: IntegrationSupportedEntity = useMemo(() => {
        return {
            entity: props.entity.displayName,
            label: props.entity.displayName,
            pluralLabel: props.entity.displayName,
            integrationViewSupported: true,
        };
    }, [props.entity.displayName]);

    return (
        <>
            <TitleWrapper>
                <H1 style={{ marginRight: '8px' }}>Entity Fetcher</H1>
            </TitleWrapper>
            <Paragraph $light>
                Entity fetching is the mechanism by which Tonkean sends an API request to an application to get or
                update a single data source record for an entity.
            </Paragraph>
            <SearchRow>
                <EnterpriseComponentSearch
                    onSearchTermChange={setSearchTerm}
                    searchTerm={searchTerm}
                    disabled={
                        (!projectIntegrationActionFetcherLoading &&
                            projectIntegrationActionFetcher?.entities?.length === 0) ||
                        !!projectIntegrationActionFetcherError
                    }
                    placeholder="Search Entity Fetcher"
                />
                <Button onClick={() => setIsCreateModalOpen(true)}>+ New Entity Fetcher</Button>
            </SearchRow>
            <ProjectIntegrationActionCreateModal
                isOpen={isCreateModalOpen}
                onCancel={onCloseModal}
                onCreate={(id) => {
                    onCloseModal();
                    $state.go('product.projectIntegrationPageEntityFetcher', {
                        enterpriseComponentId: props.projectIntegration.id,
                        entityId: props.entity.id,
                        fetcherActionId: id,
                        props,
                    });
                }}
                projectIntegration={props.projectIntegration}
                projectIntegrationActionNames={projectIntegrationActionNames}
                customActionTypesToExclude={[
                    ActionType.UPDATE,
                    ActionType.CREATE,
                    ActionType.CREATE_OR_UPDATE,
                    ActionType.CREATE,
                    ActionType.UPLOAD,
                    ActionType.DOWNLOAD,
                    ActionType.CUSTOM,
                    ActionType.STREAM_IMAGE,
                ]}
                defaultActionType={ActionType.FETCHING}
                dependentProjectIntegrationEntityIds={[props.entity.id]}
                defaultEntity={defaultEntity}
                canChooseEntity={false}
                isPostAction={false}
            />

            {projectIntegrationActionFetcherLoading && (
                <StyledTable columns={columns} data={placeHolderRows} tableRowComponent={Tr} growTable={false} />
            )}
            {entitiesRows && !projectIntegrationActionFetcherLoading && (
                <StyledTable columns={columns} data={entitiesRows} tableRowComponent={Tr} growTable={false} />
            )}
        </>
    );
};

export default ProjectIntegrationEntityEntityFetcherPage;
