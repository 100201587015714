import template from './dropdownSearch.template.html?angularjs';

/**
 * Component to display a searchable dropdown.
 */
angular.module('tonkean.app').component('tnkDropdownSearch', {
    bindings: {
        items: '<', // The dropdown items.
        selectedItem: '<', // The selected item in the dropdown.
        enableClear: '<', // If true will enable to clear the selection.
        allowCustomInput: '<', // If true, allows the user to type in custom values as fields.
        closedPlaceholder: '@', // The placeholder to display when dropdown is closed, but nothing is selected yet.
        selectedLabel: '@', // The label to display when dropdown is closed and there's a selected item. It will be clickable and open the dropdown.
        onItemSelected: '&', // The function to be called once an item is selected.
        placeholder: '@', // The placeholder text for the search input box.
        labelClasses: '@', // The classes to add to the selected label div elements.
        itemsDisplayNameProperty: '@',
        hideDropdownChevron: '@',
        doNotSaveSelectedItem: '@',
        viewOnly: '<',
    },
    template,
    controller: 'DropdownSearchCtrl',
});
