<div class="aggregate-on-column-field">
    <div ng-if="data.aggregationType === 'Count' || !data.definitionDoesNotExist">
        <tnk-formula-builder-inner-aggregation-mock
            ng-if="!!data.noAdvance"
            class="block margin-bottom-lg"
            workflow-version-id="data.workflowVersionId"
            on-formula-changed="(data.onFormulaSelected)"
        ></tnk-formula-builder-inner-aggregation-mock>

        <!-- Basic vs. Advanced selection -->
        <div class="flex-vmiddle common-size-xxs margin-bottom-lg" ng-if="!data.noAdvance">
            <!-- Basic -->
            <div
                class="formula-type-selection-option margin-right pointer"
                ng-class="{
                    'common-bold mod-selected': !data.advancedFormulaSelected,
                    'common-color-grey': data.advancedFormulaSelected
                }"
                ng-click="toggleFormulaDefinitionType()"
            >
                Basic
            </div>

            <!-- Advanced -->
            <div
                class="formula-type-selection-option pointer"
                ng-class="{
                    'common-bold mod-selected': data.advancedFormulaSelected,
                    'common-color-grey': !data.advancedFormulaSelected
                }"
                ng-click="toggleFormulaDefinitionType()"
            >
                Advanced
            </div>

            <div class="flex-grow text-right">
                <a ng-href="{{::$root.knowledgeBase.pages.formulas}}" target="_blank">
                    See Documentation
                    <i class="fa fa-question-circle"></i>
                </a>
            </div>
        </div>

        <!-- Advanced definition -->
        <div ng-if="data.advancedFormulaSelected">
            <!-- Explanation -->
            <div class="margin-bottom-xs">You can write more expressive mathematical formulas here.</div>
            <div class="margin-bottom-lg">
                Use {GlobalFieldName} to insert the value of a specific global field, or ColumnAvg({FieldName}) to
                aggregate over a field.
            </div>

            <!-- Expression definition -->
            <tnk-formula-expression-editor
                target-type="GLOBAL"
                field-definition-type="TNK_COLUMN_AGGREGATE"
                variable-entity-name="Variable"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                formula-expression="data.formulaExpression"
                evaluated-formula-expression="data.evaluatedFormulaExpression"
                aggregation-on-column-feature-name="{{ data.aggregationOnColumnFeatureName }}"
                column-formula-feature-name="{{ data.columnFormulaFeatureName }}"
                on-formula-expression-changed="
                    onFormulaExpressionChanged(
                        evaluatedFormulaExpression,
                        validationResult,
                        originalFormulaExpression,
                        variablesUsedInExpression,
                        isInit
                    )
                "
                field-definition="data.existingFieldDefinition"
            ></tnk-formula-expression-editor>

            <!-- Examples -->
            <div class="flex margin-top-lg">
                <div class="common-underline margin-right flex-no-shrink">For example:</div>
                <div class="flex-grow">
                    {GlobalFieldA} + {GlobalFieldB}
                    <br />
                    {GlobalFieldC} + Max( ColumnAvg{FieldB} , ColumnMax{FieldC} )
                    <div class="common-size-xxxs common-color-grey margin-top">
                        See all options and functions in
                        <a ng-href="{{::$root.knowledgeBase.pages.formulas}}" target="_blank">
                            the formulas documentation.
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div ng-if="data.shouldShowCheckboxForInnerItemsHirerachy" class="margin-bottom">
            <tnk-checkbox
                label="'Include inner items from all hierarchy levels'"
                value="data.shouldRunRecursivelyOnParents"
                on-toggle-async="onRunRecursivelyOnInnerItemsChanged(value)"
            ></tnk-checkbox>
        </div>

        <!-- Basic - Entity & Agg field selection -->
        <div class="flex flex-vmiddle flex-xs-wrap" ng-if="!data.advancedFormulaSelected">
            <!-- Sentence start -->
            <span class="margin-right common-bold margin-bottom">
                I want Tonkean to show me the {{ data.aggregationType.id === 'Terms' ? 'count of items' : '' }}
            </span>

            <!-- Aggregation type selection -->
            <ui-select
                data-automation="aggregate-on-column-field-template"
                class="margin-right common-width-auto margin-bottom"
                ng-model="data.aggregationType"
                ng-change="onAggregationTypeChanged()"
            >
                <ui-select-match placeholder="Aggregation type">{{ data.aggregationType.displayName }}</ui-select-match>
                <ui-select-choices repeat="aggregationType in data.aggregationTypes | filter:$select.search ">
                    <div
                        data-automation="integration-aggregate-field-aggregation-type-option"
                        ng-bind-html="aggregationType.displayName | highlight: $select.search"
                    ></div>
                </ui-select-choices>
            </ui-select>

            <!-- Sentence of word-->
            <span class="margin-right-xs margin-bottom">
                {{ data.aggregationType.id !== 'Terms' ? 'of' : '' }} the field:
            </span>

            <!-- Tracks in case it's a count aggregation -->
            <div class="common-bold margin-left-xs margin-bottom" ng-if="data.aggregationType.id === 'Count'">
                Items
            </div>

            <!-- Field to filter on -->
            <tnk-field-selector
                ng-if="data.aggregationType.id !== 'Count'"
                class="margin-right-xs filter-min-width margin-bottom"
                selected-field="data.aggregatedFieldDefinition"
                items-source="COLUMN"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                on-field-selected="onSelectedAggColumn(selectedField, dontSaveChanges)"
                group-by="groupBy"
                place-holder="Select field"
                
            ></tnk-field-selector>
        </div>

        <!-- Reduce function selection -->
        <div class="margin-top" ng-if="data.aggregationType.id === 'Reduce'">
            <div class="flex-vmiddle">
                <!-- Title -->
                <div class="margin-right common-bold">Reduce function:</div>

                <!-- Reduce function selection -->
                <ui-select
                    class="margin-right"
                    ng-model="data.reduceFunction"
                    ng-change="reduceFunctionSelected()"
                    theme="bootstrap"
                >
                    <ui-select-match placeholder="Reduce function">
                        {{ data.reduceFunction }}
                    </ui-select-match>
                    <ui-select-choices repeat="reduceFunction in data.reduceFunctions | filter:$select.search ">
                        <div ng-bind-html="reduceFunction | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <!-- Concat configuration -->
            <div ng-if="data.reduceFunction === 'Concat'" class="flex-vmiddle margin-top">
                <!-- Title -->
                <div class="margin-right common-bold flex-no-shrink">Separator (defaults to a comma):</div>

                <!-- Separator selection -->
                <input
                    type="text"
                    class="form-control"
                    ng-model="data.concatenationSeparator"
                    maxlength="255"
                    placeholder="Enter concatenation separator..."
                    ng-change="onConcatenationSeparatorChanged()"
                />
            </div>
        </div>

        <!-- Grouped by function selection -->
        <div class="margin-top" ng-if="data.aggregationType.id === 'Terms'">
            <div class="flex-vmiddle">
                <!-- Title -->
                <div class="margin-right common-bold">Show the</div>

                <!-- group by function selection -->
                <ui-select
                    class="margin-right"
                    ng-model="data.groupByFunction"
                    ng-change="groupByFunctionSelected()"
                    theme="bootstrap"
                >
                    <ui-select-match placeholder="Group by function">
                        {{ data.groupByFunction.displayName }}
                    </ui-select-match>
                    <ui-select-choices repeat="groupByFunction in data.groupByFunctions | filter:$select.search ">
                        <div ng-bind-html="groupByFunction.displayName | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>

                <div ng-if="data.groupByFunction.apiName === 'Count'" class="flex-vmiddle">
                    <div class="margin-right common-bold flex-no-shrink">of items per group</div>
                </div>

                <div ng-if="data.groupByFunction && data.groupByFunction.apiName !== 'Count'" class="flex-vmiddle">
                    <!-- Title -->
                    <div class="margin-right common-bold flex-no-shrink">of</div>

                    <tnk-field-selector
                        class="margin-right"
                        selected-field="data.groupByAggregateField"
                        ng-class="{ 'selector-value-selected': data.groupByAggregateField }"
                        items-source="COLUMN"
                        project-integration="data.projectIntegration"
                        on-field-selected="selectGroupByAggregateField(selectedField, dontSaveChanges)"
                        is-required="true"
                        place-holder="Select a field"
                        field-inspect-modal="true"
                        field-inspect-auto-open="false"
                        workflow-version-id="{{ data.workflowVersionId }}"
                    ></tnk-field-selector>

                    <div class="margin-right common-bold flex-no-shrink">per group</div>
                </div>
            </div>
        </div>

        <!-- Order by -->
        <div class="flex-vmiddle margin-bottom margin-top" ng-if="data.aggregationType.id === 'First'">
            <!-- Order by title -->
            <div class="margin-right common-bold">Order by:</div>

            <!-- Order by field selection -->
            <tnk-field-selector
                class="filter-min-width"
                selected-field="data.orderByFieldDefinition"
                items-source="COLUMN"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                display-integration-indication="true"
                add-fields-quick-create-options="true"
                group-by="groupBy"
                auto-create-column-fields="true"
                special-fields-for-features="['']"
                place-holder="Search data field"
                on-field-selected="onFieldOptionSelected(selectedField, dontSaveChanges)"
            ></tnk-field-selector>
        </div>

        <!-- Order by order -->
        <div class="flex-vmiddle" ng-if="data.aggregationType.id === 'First'">
            <!-- Order by order title -->
            <div class="margin-right common-bold">Order:</div>

            <!-- Order by order selection -->
            <ui-select
                class="margin-right"
                ng-model="data.orderBySelectedOrder"
                ng-change="onOrderBySelectedOrderSelected(data.orderBySelectedOrder)"
                theme="bootstrap"
            >
                <ui-select-match placeholder="Order type...">
                    {{ data.orderBySelectedOrder.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="orderType in data.orderTypes | filter:$select.search ">
                    <div ng-bind-html="orderType.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Column conditions -->
        <div ng-if="!data.advancedFormulaSelected || data.shouldDisplayFilterConditions">
            <!-- Separator -->
            <hr class="margin-bottom-lg margin-top" />

            <!-- Title -->
            <div class="margin-top-lg margin-bottom common-bold">Only items that match these conditions -</div>

            <!-- Conditions definition -->
            <tnk-custom-filters
                control="data.customFiltersControl"
                items-source="COLUMN"
                existing-definition="data.existingFieldDefinition.definition.aggregateQueryDefinition"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                hide-time-range-selection="true"
                edit-mode="!data.duplicateOrCreateMode"
                on-filters-change="onCustomFiltersChanged(shouldNotSaveLogic)"
                special-fields-for-features="['AGGREGATE_ON_COLUMN']"
                force-expand="data.isWorkflowVersionPublished"
                not-allowed-conditions-set="data.notAllowedConditionsSet"
            ></tnk-custom-filters>
        </div>

        <!-- Empty state -->
        <div
            ng-if="
                !cfm.selectedFieldsMap[data.workflowVersionId] || !cfm.selectedFieldsMap[data.workflowVersionId].length
            "
        >
            <div class="margin-top common-size-xxs">
                No columns created. You can create a new column using the Actions menu.
            </div>
        </div>
    </div>

    <!-- Definition no longer exists state -->
    <div ng-if="data.aggregationType.id !== 'Count' && data.definitionDoesNotExist" class="margin-top-lg">
        <div>
            Aggregated column
            <strong>
                {{
                    data.existingFieldDefinition.definition.aggregatedDefinitionName
                        ? data.existingFieldDefinition.definition.aggregatedDefinitionName
                        : ''
                }}
            </strong>
            is deleted.
        </div>
        <a class="block margin-top-xs" ng-click="redefineKeyMetric()">Redefine the Key Metric</a>
    </div>
</div>
