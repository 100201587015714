/**
 * ETag types enum.
 */
export const ETAG_TYPES = {
    initiative: {
        data: {
            old: 'initiative_data_old_etag',
            new: 'initiative_data_new_etag',
        },
        children: {
            old: 'initiative_children_old_etag',
            new: 'initiative_children_new_etag',
        },
        group: {
            old: 'initiative_group_old_etag',
            new: 'initiative_group_new_etag',
        },
    },
};
