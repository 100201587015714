import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';

import type { LogicImpact } from '@tonkean/tonkean-entities';

const isOpenFormFromOtherGroup = (
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
) => {
    const workflowVersionId = configuredLogic.node.workflowVersion.id;
    const customTriggerActionDefinition =
        configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition;
    if (!customTriggerActionDefinition.formId) {
        return false;
    }
    const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
        workflowVersionId,
        customTriggerActionDefinition.formId,
    );

    // If form is undefined it means it from other group
    return !form;
};

export default isOpenFormFromOtherGroup;
