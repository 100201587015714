import React, { useMemo } from 'react';

import { IconSvg } from '@tonkean/infrastructure';
import { CursorClickIcon, FormIcon, InterfaceIcon, TrashIcon } from '@tonkean/svg';
import { FormQuestionType, FormType, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';
import {
    type Form,
    type InterfaceCTAActionOption,
    InterfaceCTAActionType,
    type ItemInterface,
    OptionsTypeTitles,
} from '@tonkean/tonkean-entities';
import { type CustomTrigger, AutonomousSecondaryType } from '@tonkean/tonkean-entities';

export type PartialForm = Pick<Form, 'id' | 'formType' | 'formQuestionType' | 'definition' | 'displayName'>;
export type PartialCustomTrigger = Pick<CustomTrigger, 'id' | 'displayName' | 'customTriggerSecondaryType'>;
export type PartialItemInterface = Pick<ItemInterface, 'id' | 'displayName'>;

const useWidgetInlineActionsOptions = (
    forms?: PartialForm[],
    interfaces?: PartialItemInterface[],
    customTriggers?: PartialCustomTrigger[],
) => {
    const buttonCustomTriggers: PartialCustomTrigger[] = useMemo(() => {
        return (customTriggers || [])?.filter(
            (trigger) => trigger.customTriggerSecondaryType === AutonomousSecondaryType.USER_INTERFACE_BUTTON_CLICK,
        );
    }, [customTriggers]);

    return useMemo(() => {
        const systemOptions: InterfaceCTAActionOption[] = [];

        systemOptions.push({
            savedEntityTemplate: {
                type: InterfaceCTAActionType.DELETE_ITEM,
                id: InterfaceCTAActionType.DELETE_ITEM,
                defaultLabel: 'Delete Item',
            },
            icon: <IconSvg as={TrashIcon} size={16} />,
            groupTitle: OptionsTypeTitles.SYSTEM_OPTIONS,
        });

        const filteredForms: PartialForm[] =
            forms?.filter(
                (form) =>
                    form.formType === FormType.UPDATE &&
                    (form.formQuestionType !== FormQuestionType.UPLOAD_FILES ||
                        (form.formQuestionType === FormQuestionType.UPLOAD_FILES &&
                            !!form.definition.dataStorageId &&
                            !!form.definition.destinationFolderId?.originalExpression)),
            ) || [];

        const updateForms: InterfaceCTAActionOption[] =
            filteredForms
                .filter((form): form is { id: TonkeanId<TonkeanType.FORM> } & Form => !!form.id)
                .map((form) => ({
                    savedEntityTemplate: {
                        type: InterfaceCTAActionType.OPEN_FORM,
                        id: form.id,
                        defaultLabel: form.displayName,
                    },
                    icon: <IconSvg as={FormIcon} size={16} />,
                    groupTitle: OptionsTypeTitles.UPDATE_FORMS,
                })) || [];

        const customTriggerButtonActions: InterfaceCTAActionOption[] = buttonCustomTriggers.map((customTrigger) => {
            return {
                savedEntityTemplate: {
                    type: InterfaceCTAActionType.TRIGGER_WORKFLOW,
                    id: customTrigger.id,
                    defaultLabel: customTrigger.displayName,
                },
                icon: <IconSvg as={CursorClickIcon} size={16} color="#FF7A00" />,
                groupTitle: OptionsTypeTitles.EVENT_TRIGGERS,
            };
        });

        const finalInterfaces: InterfaceCTAActionOption[] =
            interfaces?.map((interfaceInstance) => {
                return {
                    savedEntityTemplate: {
                        type: InterfaceCTAActionType.OPEN_INTERFACE,
                        id: interfaceInstance.id,
                        defaultLabel: interfaceInstance.displayName,
                    },
                    icon: <IconSvg as={InterfaceIcon} size={16} color="#FF7A00" />,
                    groupTitle: OptionsTypeTitles.INTERFACES,
                };
            }) || [];

        return [...systemOptions, ...updateForms, ...customTriggerButtonActions, ...finalInterfaces];
    }, [buttonCustomTriggers, forms, interfaces]);
};

export default useWidgetInlineActionsOptions;
