import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNlpBranchBlockCreateAfterOption from '../sharedConfigComponents/nlp/createAfterOptions/addNlpBranchBlockCreateAfterOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const NlpProcessorLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.NLP_PROCESSOR,
    title: 'Text Analysis (NLP)',
    iconClass: 'mod-nlp-processor',
    description: 'Branch out based on the content of the input text',
    allowedChildrenTypes: () => [
        LogicBlockConfigType.NLP_BRANCH,
        LogicBlockConfigType.NLP_BRANCH_DEFAULT,
        LogicBlockConfigType.NLP_BRANCH_ANY_OF_THE_REST,
    ],
    createAfterOptions: () => [addNlpBranchBlockCreateAfterOption],
    defaultActionDefinition: () => {
        return {
            inputExpression: '',
            nlpMatchType: 'ALL_MATCHING_BRANCHES',
        };
    },
    getLinkedFieldDefinitionsConfiguration: () => {
        return [
            {
                field: 'MATCHED_BRANCH',
                friendlyName: 'Selected Branch',
            },
            {
                field: 'BRANCHES_SCORES',
                friendlyName: 'Branches Scores',
            },
        ];
    },
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition.evaluatedInputExpression?.length) {
            validationObject.noInputExpression = 'Input field must be filled.';
        }

        if (definition.textAnalysisType === 'TRAINING_SET' && !definition.trainingSetId) {
            validationObject.noTrainingSetSelected = 'Must choose a training set';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    dataAutomation: 'text-analysis-nlp',
};

export default NlpProcessorLogicBlockConfig;
