import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';

import EnterpriseComponentAuditLogExport from './EnterpriseComponentAuditLogExport';
import EnterpriseComponentAuditLogFilterPopover, { EMPTY_OBJECT } from './EnterpriseComponentAuditLogFilterPopover';
import EnterpriseComponentPageTemplate from '../../../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import EnterpriseComponentAuditLogChangesList from '../../../../AuditLogPage/components/EnterpriseComponentPageAuditLog/components/EnterpriseComponentAuditLogChangesList';
import type AuditLogFilterSearchQuery from '../../../entities/AuditLogFilterSearchQuery';
import AuditLogType, { getAuditLogTypeTitle } from '../../../entities/AuditLogType';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { FiltersTrigger, Popover, Toggle, useDebouncedState, useToggle } from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { filtersHelper } from '@tonkean/utils';

export const auditLogFiltersHelper = filtersHelper(EMPTY_OBJECT);

interface Props {
    enterpriseComponent: EnterpriseComponent;
    enterpriseComponentType: EnterpriseComponentType;
    logType: AuditLogType;
    description: string;
}
const EnterpriseComponentAuditLogMainPage: React.FC<Props> = ({
    enterpriseComponent,
    enterpriseComponentType,
    logType,
    description,
}) => {
    const auditLogService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');
    const [isGrouped, setIsGrouped] = useToggle(false);

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [debouncedQuery, setDebouncedQuery] = useState<AuditLogFilterSearchQuery>({});
    const [query, setQuery] = useDebouncedState<AuditLogFilterSearchQuery>(debouncedQuery, setDebouncedQuery, 300);

    // Load how many groups we have in total
    const { data: auditLogsCount, loading: loadingAuditLogsCount } = useAsyncMethod(
        auditLogService,
        'getAuditLogCount',
        projectManager.project.id,
        enterpriseComponent.id,
        enterpriseComponentType,
        logType,
        debouncedQuery,
    );

    const filtersChangeCount = useMemo(() => {
        return auditLogFiltersHelper.changesCount(query.filters || EMPTY_OBJECT);
    }, [query.filters]);

    return (
        <EnterpriseComponentPageTemplate
            name={getAuditLogTypeTitle(logType)}
            description={description}
            searchTerm={query.search}
            onSearchTermChange={(search) => setQuery({ ...query, search })}
            actions={
                <>
                    <Popover
                        show={filtersOpen}
                        onClose={() => setFiltersOpen(false)}
                        placement="bottom-start"
                        content={
                            <EnterpriseComponentAuditLogFilterPopover
                                onChange={(filters) => {
                                    setQuery({ ...query, filters });
                                    setFiltersOpen(false);
                                }}
                                filters={query.filters || EMPTY_OBJECT}
                                enterpriseComponentType={enterpriseComponentType}
                                includeActors={logType !== AuditLogType.SYSTEM}
                                logType={logType}
                            />
                        }
                    >
                        <FiltersTrigger
                            activeFiltersCount={filtersChangeCount}
                            filtersOpen={filtersOpen}
                            onClick={() => setFiltersOpen(true)}
                        />
                    </Popover>

                    {logType === AuditLogType.USER_ACTION && (
                        <EnterpriseComponentAuditLogExport
                            enterpriseComponent={enterpriseComponent}
                            enterpriseComponentType={enterpriseComponentType}
                            filterSearch={query}
                            disabled={auditLogsCount === 0}
                        />
                    )}
                </>
            }
            stickyItems={
                logType === AuditLogType.USER_ACTION && (
                    <Toggle
                        size={InputSize.SMALL}
                        checked={isGrouped}
                        onChange={() => {
                            setIsGrouped();
                        }}
                        disabled={auditLogsCount === 0}
                        labelAfter="Group changes by categories"
                    />
                )
            }
        >
            <EnterpriseComponentAuditLogChangesList
                logsCount={auditLogsCount}
                loadingLogsCount={loadingAuditLogsCount}
                enterpriseComponentId={enterpriseComponent.id}
                filterSearch={debouncedQuery}
                enterpriseComponentType={enterpriseComponentType}
                isGrouped={isGrouped}
                logType={logType}
            />
        </EnterpriseComponentPageTemplate>
    );
};

export default EnterpriseComponentAuditLogMainPage;
