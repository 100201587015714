import { useAngularService } from 'angulareact';
import React, { useCallback, useId, useState } from 'react';
import type { GroupBase, MultiValue, SingleValue, InputActionMeta } from 'react-select';

import useDropdownOptionsFromMatchedEntity from './hooks/useDropdownOptionsFromMatchedEntity';

import { TnkSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

export type SearchDropdownSelectorOption = { label: string; value: string };

interface Props {
    fieldDefinitionId: FieldDefinition['id'];
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    value: MultiValue<SearchDropdownSelectorOption> | SingleValue<SearchDropdownSelectorOption> | undefined;
    onChange: (
        newValue: MultiValue<SearchDropdownSelectorOption> | SingleValue<SearchDropdownSelectorOption> | undefined,
    ) => void;
    isMultiValueList: boolean;
    multiValueList: string[] | undefined;
    readOnly: boolean | undefined;
    isInBuilder?: boolean;
    placeholderText?: string;
    isCreatable: boolean;
    defaultMenuIsOpen?: boolean;
    closeMenuOnSelect?: boolean;
    workflowVersionType?: WorkflowVersionType;
}

const UpdateFieldAsyncSearchDropdownSelector: React.FC<Props> = ({
    fieldDefinitionId,
    initiativeId,
    onChange,
    value,
    isMultiValueList,
    multiValueList,
    readOnly,
    isInBuilder,
    placeholderText,
    isCreatable,
    workflowVersionType,
    ...props
}) => {
    const requestThrottler = useAngularService('requestThrottler');
    const componentId = useId();

    const [menuLastOpenDate, setMenuLastOpenDate] = useState<number | undefined>();

    const [{ options, isLoading }, getDropDownOptionsFromMatchedEntity] = useDropdownOptionsFromMatchedEntity(
        workflowVersionType,
        initiativeId,
        fieldDefinitionId,
    );

    const onInputChange = useCallback(
        (query: string | undefined, actionMeta: InputActionMeta) => {
            if (actionMeta.action === 'input-change') {
                requestThrottler.do(`${componentId}`, 300, async () => {
                    await getDropDownOptionsFromMatchedEntity(query);
                });
            }
        },
        [componentId, getDropDownOptionsFromMatchedEntity, requestThrottler],
    );

    const onFocus = useCallback(() => {
        setMenuLastOpenDate(Date.now());

        if (menuLastOpenDate === undefined || Date.now() - menuLastOpenDate > 1000) {
            getDropDownOptionsFromMatchedEntity(undefined);
        }
    }, [getDropDownOptionsFromMatchedEntity, menuLastOpenDate]);

    return (
        <TnkSelect<SearchDropdownSelectorOption, GroupBase<SearchDropdownSelectorOption>, true, boolean, boolean>
            value={value}
            options={options}
            isLoading={isLoading}
            onChange={onChange}
            onInputChange={onInputChange}
            onFocus={onFocus}
            readOnly={readOnly}
            isDisabled={readOnly}
            isMulti={isMultiValueList}
            placeholder={placeholderText || 'Select an option'}
            isCreatable={isCreatable}
            filterOption={null} // Disables filtering on the dropdown by client side since we return filtered results from server
            isSearchable
            isClearable
            {...props}
        />
    );
};
export default UpdateFieldAsyncSearchDropdownSelector;
