import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { OperatorFamily } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';

const fieldMatchingType = Symbol('field A and field B value type');

export default abstract class NumericDateBinaryOperatorBase extends FormulaOperatorDefinitionBase {
    public readonly family = OperatorFamily.ARITHMETIC;
    public readonly dataType = ([fieldA]: formulaTreeNode[]) => fieldA?.dataType;

    public readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: [FieldType.Number, FieldType.Date],
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: [FieldType.Number, FieldType.Date],
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
