<tnk-react-root-fixed-portal></tnk-react-root-fixed-portal>
<tnk-loading-tonkean ng-hide="pageLoadState.done"></tnk-loading-tonkean>
<div
    ng-if="pageLoadState.done"
    class="project-main relative"
    ng-class="{ 'mod-full': showProjectFilter, 'mod-inner-project-filter-active': filterVisiblity.showInnerBar }"
>
    <!-- Filter button (mobile)-->
    <div
        class="visible-xs project-top-btn-mobile mod-filter-btn"
        ng-hide="shrinkHeader"
        ng-if="$root.isMobile && showProjectFilter"
        uib-dropdown
        on-toggle="toggled(open)"
    >
        <button uib-dropdown-toggle class="btn btn-no-border pointer" id="filterOpenBtn">
            <i class="fa fa-filter"></i>
        </button>

        <ul class="dropdown-menu-right" uib-dropdown-menu>
            <li>
                <a ng-click="navToFilter('my')">My tracks ({{ pm.projectData.onlyMineInitiativesCount || 0 }})</a>
            </li>
            <li ng-if="pm.projectData.stats && pm.projectData.stats.length">
                <a ng-click="toggleInnerBar(innerBarType.status)">Status</a>
            </li>
            <li ng-if="pm.functions && pm.functions.length">
                <a ng-click="toggleInnerBar(innerBarType.functions)">Function</a>
            </li>
            <li ng-if="pm.tags && pm.tags.length">
                <a ng-click="toggleInnerBar(innerBarType.tags)">Tag</a>
            </li>
            <li
                ng-if="
                    mobileFilters['func'] ||
                    mobileFilters['tags'] ||
                    mobileFilters['status'] ||
                    filterVisiblity.baseFilter !== 'all'
                "
            >
                <a ng-click="onClearFilters();">Clear filter</a>
            </li>
        </ul>
    </div>

    <!-- Search button (mobile) -->
    <!-- Removed for now. Probably not in use anymore -->
    <!-- <div
        class="project-top-btn-mobile mod-search-btn svg-icon-nav-sm visible-xs"
        ng-if="$root.isMobile && as.currentUser && pm.project && !pm.viewOnlyMode && (pm.isUserLicensed || pm.isCreator || (!pm.isLicensed && !pm.project.isLimitedLicense))"
    >
        <tnk-search-popup is-active="isActive"></tnk-search-popup>
    </div> -->

    <!--main sidebar-->
    <div
        class="project-filter flex flex-col common-bg mod-grey4"
        id="projectFilter"
        click-outside="onFilterClicked()"
        outside-if-not="filterOpenBtn project-filter"
        outside-activators="mod-visible"
        ng-class="{ 'mod-visible': data.showFilter, 'mod-collapsed': data.filterCollapsed }"
        ng-if="showProjectFilter"
    >
        <div class="visible-xs clearfix text-right padding-normal-sm margin-top">
            <div ng-click="data.showFilter = false" class="common-color-dark-grey pointer">Close Filter</div>
            <hr />
        </div>

        <!-- Lower part of sidebar -->
        <div class="common-bg mod-light-grey flex flex-col flex-grow overflow-y-auto relative">
            <!-- Search (desktop) + filter button -->
            <div class="flex-vmiddle padding-left padding-right flex-no-shrink common-size-xxxxs">
                <!-- Search (component doesn't exist) -->
                <!-- <tnk-search-popup is-active="isActive" class="margin-right" append-to-body="true"></tnk-search-popup> -->

                <span class="relative margin-right-xxs">
                    <!-- Filter button and popover -->
                    <span
                        class="pointer"
                        ng-class="{ 'common-color-link-blue': data.showGlobalFilters }"
                        data-automation="project-board-template-filter-cross-reports-items"
                        uib-popover-template="'project-board-filter-popover-template'"
                        popover-placement="auto"
                        popover-trigger="outsideClick"
                        popover-class="project-board-filter-popover"
                        popover-append-to-body="true"
                    >
                        <i class="svg-icon">
                            <tnk-icon src="../../../../../apps/tracks/images/icons/filter.svg"></tnk-icon>
                        </i>
                    </span>
                </span>
            </div>

            <!-- Live Reports -->
            <div
                ng-hide="data.collapseReports"
                class="padding-top margin-top flex-grow overflow-y-auto"
                style="direction: rtl"
            >
                <!-- // using direction hack to make the scroll be on the left side -->

                <div style="direction: ltr">
                    <!-- My tracks -->
                    <div
                        class="project-filter-item-container mod-group padding-left-xs padding-right"
                        ui-sref-active="active"
                    >
                        <a
                            class="project-filter-item tnk-tooltip"
                            ng-click="onFilterClicked()"
                            ui-sref="product.board({filter:'my', st: null, g:null, listFilter: null, isGroupListFilter: null, env: null})"
                        >
                            <div class="sidebar-item-icon">
                                <tnk-icon src="../../../../../apps/tracks/images/icons/user.svg"></tnk-icon>
                            </div>
                            <span class="flex-grow fs-censored common-ellipsis">Your Items</span>
                            <span class="flex-grow"></span>
                            <span
                                class="project-filter-item-side-number flex-no-shrink"
                                ng-if="pm.projectData && pm.projectData.onlyMineInitiativesCount"
                            >
                                {{ pm.projectData.onlyMineInitiativesCount }}
                            </span>
                        </a>
                    </div>

                    <!-- Timeline -->
                    <div
                        class="project-filter-item-container mod-group padding-left-xs pointer padding-right"
                        ui-sref="product.calendar({tab: 'timeline', g: null})"
                        ui-sref-active="active"
                        ng-if="pm.projectData.isTimelineAvailable && as.currentUser && pm.project && !pm.viewOnlyMode && (pm.isUserLicensed || !pm.isLicensed)"
                    >
                        <span class="project-filter-item" ui-sref-active="active">
                            <div class="sidebar-item-icon">
                                <tnk-icon src="../../../../../apps/tracks/images/icons/history/flow-run.svg"></tnk-icon>
                            </div>
                            Timeline
                        </span>
                    </div>

                    <!-- Calendar -->
                    <div
                        class="project-filter-item-container mod-group padding-left-xs pointer padding-right"
                        ui-sref="product.calendar({tab: null, g: null})"
                        ui-sref-active="active"
                        ng-if="as.currentUser && pm.project && !pm.viewOnlyMode && (pm.isUserLicensed || !pm.isLicensed)"
                    >
                        <span class="project-filter-item" ui-sref-active="active">
                            <div class="sidebar-item-icon">
                                <tnk-icon src="../../../../../apps/tracks/images/icons/calendar-o.svg"></tnk-icon>
                            </div>
                            Calendar
                        </span>
                    </div>

                    <!-- User's groups links -->
                    <div
                        class="
                            flex flex-col
                            mod-justify-space
                            flex-grow
                            common-height-full
                            overflow-hidden
                            padding-left-xs padding-right
                        "
                    >
                        <div class="overflow-hidden">
                            <div
                                class="padding-bottom-lg"
                                ng-repeat="definition in groupLinksSectionDefinitions"
                                ng-if="
                                    definition.isVisibleOnEmpty ||
                                    (pm.groups | filter: definition.filterGroups).length > 0
                                "
                            >
                                <div class="project-filter-subtitle flex-vmiddle margin-top" ng-if="definition.title">
                                    <a
                                        ng-click="data.sectionCollapse[definition.id] = !data.sectionCollapse[definition.id]"
                                        class="
                                            flex-grow
                                            common-bold common-color-grey common-link-no-color
                                            text-uppercase
                                            flex-vmiddle
                                        "
                                    >
                                        <tnk-text-ellipsis
                                            number-of-lines="1"
                                            tooltip="true"
                                            children="definition.title"
                                        ></tnk-text-ellipsis>
                                        <span ng-if="groupsInFilter && groupsInFilter.length > 0">
                                            ({{ groupsInFilter.length + solutionBusinessReportsInFilter.length }})
                                        </span>
                                        <span class="margin-left" style="margin-bottom: 2px">
                                            <i
                                                class="common-size-xxxxxs fa"
                                                ng-class="
                                                    data.sectionCollapse[definition.id]
                                                        ? 'fa-chevron-right'
                                                        : 'fa-chevron-down'
                                                "
                                            ></i>
                                        </span>
                                    </a>
                                    <!-- Plus button for "All Lists" section -->
                                    <div ng-if="data.loadingWorkflowFolders" class="loading-small flex-no-shrink"></div>
                                    <!-- <tnk-solution-business-report-popover
                                        ng-if="
                                            definition.workflowFolderId &&
                                            wfm.projectIdToFolderIdToFolderMap[pm.project.id][
                                                definition.workflowFolderId
                                            ].isUserOwner
                                        "
                                        class="margin-left flex-no-shrink"
                                        workflow-folder="wfm.projectIdToFolderIdToFolderMap[pm.project.id][definition.workflowFolderId]"
                                        on-created="(onSolutionBusinessReportCreated)"
                                    ></tnk-solution-business-report-popover> -->
                                    <!--<span-->
                                    <!--class="project-filter-add-group-btn flex-no-shrink"-->
                                    <!--ng-class="{'mod-disabled': !isMakerOfFolders()}"-->
                                    <!--uib-tooltip="{{isMakerOfFolders() ? 'Create Module' : 'You need to be a maker of at least one solution to add a live report'}}"-->
                                    <!--tooltip-append-to-body="true"-->
                                    <!--ng-if="!data.loadingWorkflowFolders && definition.type === groupSectionTypes.allLists"-->
                                    <!--ng-click="isMakerOfFolders() && modalUtils.openSetupGroupModal(null, true, null, null, true)"-->
                                    <!--&gt;-->
                                    <!--+-->
                                    <!--</span>-->
                                </div>

                                <div class="overflow-hidden" ng-hide="data.sectionCollapse[definition.id]">
                                    <!-- $location.search() - returns a maintained variable called $$search, which is why it is safe to make the call in the HTML -->
                                    <div
                                        class="project-filter-item-container mod-group"
                                        ng-repeat="group in groupsInFilter = (pm.groups | filter : definition.filterGroups | orderBy : 'created')"
                                        ng-if="!group.isDefaultGroup && !group.dashboardHidden"
                                        ng-class="{ active: $location.search().g === group.id }"
                                    >
                                        <a
                                            class="project-filter-item"
                                            ng-click="onFilterClicked()"
                                            ui-sref="product.board({filter:'all', g:group.id, st:null, listFilter: null, isGroupListFilter: null, o: null, env: null})"
                                        >
                                            <div class="sidebar-item-icon">
                                                <tnk-icon src="../../../../../apps/tracks/images/icons/history/flow-run.svg"></tnk-icon>
                                            </div>

                                            <tnk-text-ellipsis
                                                class="fs-censored"
                                                data-automation="project-board-template-group-name-{{group.name}}"
                                                number-of-lines="1"
                                                tooltip="true"
                                                children="group.name"
                                            ></tnk-text-ellipsis>
                                            <span class="flex-grow"></span>
                                            <i
                                                class="
                                                    flex-no-shrink
                                                    project-filter-item-lock
                                                    svg-icon-xs svg-icon-align-text-base
                                                    common-single-lineheight
                                                "
                                                ng-if="group.visibilityType && group.visibilityType === 'PRIVATE'"
                                            >
                                                <tnk-icon src="../../../../../apps/tracks/images/icons/lock.svg"></tnk-icon>
                                            </i>
                                        </a>
                                    </div>
                                    <!-- If no group at all show a fake one -->
                                    <div
                                        class="project-filter-item-container mod-group"
                                        ui-sref-active="active"
                                        ng-if="
                                            !pm.groups ||
                                            !pm.groups.length ||
                                            !(pm.groups | filter: { dashboardHidden: false }).length
                                        "
                                    >
                                        <a
                                            class="project-filter-item tnk-tooltip"
                                            ng-click="onFilterClicked()"
                                            ui-sref="product.board({filter:'all', g:null, st:null, listFilter: null, isGroupListFilter: null, env: null})"
                                        >
                                            <span class="flex-no-shrink common-ellipsis fs-censored">
                                                High level tracks
                                            </span>
                                        </a>
                                    </div>
                                    <!-- Solution Business Reports -->
                                    <div
                                        class="project-filter-item-container mod-group"
                                        ng-repeat="solutionBusinessReport in solutionBusinessReportsInFilter = (data.solutionBusinessReports | filter : definition.filterSolutionBusinessReports | orderBy : 'created')"
                                        ng-class="{
                                            active: $state.params.solutionBusinessReportId === solutionBusinessReport.id
                                        }"
                                    >
                                        <a
                                            class="project-filter-item"
                                            ui-sref="product.solution-business-report({solutionBusinessReportId:solutionBusinessReport.id})"
                                        >
                                            <div class="sidebar-item-icon">
                                                <tnk-icon src="../../../../../apps/tracks/images/icons/solution-business-report.svg"></tnk-icon>
                                            </div>
                                            <tnk-text-ellipsis
                                                class="fs-censored"
                                                number-of-lines="1"
                                                tooltip="true"
                                                children="solutionBusinessReport.displayName"
                                            ></tnk-text-ellipsis>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- To push the rest of the items down -->
            <!--<div class="flex-grow " ng-hide="data.collapseReports">&nbsp;</div>-->

            <!-- Archived -->
            <div class="project-filter-footer project-filter-subtitle-box padding-left flex-vmiddle flex-no-shrink">
                <div class="project-filter-item-container mod-group mod-grey" ui-sref-active="active-light">
                    <a class="project-filter-item mod-grey" ng-click="onFilterClicked()" ui-sref="product.archive">
                        Archived
                    </a>
                </div>
            </div>

            <!-- "More" groups (when there are more then X groups) -->
            <!--<div class="project-board-separator more-groups-gradient-white padding-bottom-xs padding-top absolute-bottom relative mod-skinny show-edit-when-hover-visibility pointer text-center"-->
            <!--ng-click="modalUtils.openManageGroupsModal(sectionsIds.all)"-->
            <!--ng-if="pm.groups.length > 6">-->
            <!--<span class="common-tag-full edit-button padding-normal-sm-h margin-bottom">-->
            <!--<span class="common-size-xxxxs common-color-white">More...</span>-->
            <!--</span>-->
            <!--</div>-->
        </div>
    </div>
    <!-- END: main sidebar -->

    <div class="" ng-if="filterVisiblity.showInnerBar && $root.showProjectInnerFilter">
        <div class="hidden-xs hidden-sm project-board-functions-placeholder"></div>
        <div class="hidden-xs hidden-sm project-board-functions" id="projectBoardInnerBar">
            <button
                class="btn btn-default btn-no-border project-board-functions-close-btn padding-normal-sm"
                ng-click="onFilterClicked()"
            >
                <i class="fa fa-long-arrow-left pointer"></i>
            </button>
            <div ng-if="filterVisiblity.showInnerBar === innerBarType.functions">
                <div class="margin-bottom relative">
                    <div class="flex-vmiddle common-bold common-size-xs">
                        <span class="common-title-md relative">
                            Functions
                        </span>
                        <span class="flex-grow"></span>
                        <i
                            class="fa fa-plus-circle pointer common-color-grey visible-xs"
                            ng-click="data.showAddFunc = !data.showAddFunc"
                        ></i>
                    </div>
                </div>
                <div ng-show="functions && functions.length">
                    <a
                        class="project-inner-board-item show-on-full-flex flex-vmiddle tnk-tooltip common-link-no-style"
                        ui-sref-active="active active-flex"
                        ui-sref="product.board({filter:func.name, g:null, st:null, listFilter: null, isGroupListFilter: null, env: null})"
                        ng-click="data.showFilter = false"
                        ng-repeat="func in functions = (pm.functions | hasFieldFilter:'starred':false | orderBy:'name')"
                    >
                        <!--<i class="fa fa-building-o common-color-blue-light"></i>-->
                        <!--<span class="initials common-circle flex-no-shrink">{{utils.getInitials(func.name)}}</span>-->
                        <span class="margin-left show-on-full-inline flex-grow" ng-bind="func.name"></span>
                        <span class="tnk-tooltip-text">{{ func.name }}</span>
                    </a>
                </div>
                <hr />
                <div class="hidden-xs">
                    <p ng-show="functions && functions.length" class="project-filter-item mod-seperator"></p>

                    <form
                        class="show-on-full"
                        ng-if="data.showAddFunc || !functions || !functions.length"
                        ng-submit="addNewFunc()"
                    >
                        <div class="flex-vmiddle">
                            <input
                                class="form-control margin-right-xs"
                                ng-model="data.newFuncName"
                                placeholder="Function/team name..."
                                autofocus
                            />
                            <button type="submit" class="btn btn-primary">+</button>
                        </div>
                    </form>

                    <a
                        ng-hide="data.showAddFunc || !functions || !functions.length"
                        class="common-color-grey"
                        ng-click="data.showAddFunc = !data.showAddFunc"
                    >
                        <i class="fa fa-plus-circle margin-right-xs"></i>
                        Add
                    </a>
                </div>
            </div>

            <div ng-if="filterVisiblity.showInnerBar === innerBarType.tags" class="hidden-xs">
                <div class="common-title-md margin-bottom">Tags</div>
                <div ng-show="tags && tags.length">
                    <a
                        class="project-inner-board-item show-on-full tnk-tooltip common-link-no-style"
                        ui-sref-active="active active-flex"
                        ui-sref="product.board({filter:tag.name, g:null, listFilter: null, isGroupListFilter: null, env: null})"
                        ng-click="data.showFilter = false"
                        ng-repeat="tag in tags = (pm.tags | hasFieldFilter:'starred':false | orderBy:'name' | limitTo:15 )"
                    >
                        <span class="tnk-tooltip-text">{{ tag.name }}</span>
                        <span class="margin-left show-on-full-inline" ng-bind="tag.name"></span>
                    </a>
                </div>
            </div>
        </div>
        <!-- Mobile filters - selection bars-->
        <div class="visible-xs visible-sm project-board-functions">
            <!-- Functions -->
            <div class="flex-vmiddle" ng-if="filterVisiblity.showInnerBar === innerBarType.functions">
                <span class="common-color-white margin-right flex-no-shrink">Go to function:</span>
                <select
                    class="form-control"
                    ng-model="mobileFilters[innerBarType.functions]"
                    ng-change="navToFilter(mobileFilters[innerBarType.functions], null, innerBarType.functions)"
                >
                    <option value="" disabled selected>Select a function</option>
                    <option ng-repeat="func in functions = (pm.functions | orderBy:'name')" value="{{ func.name }}">
                        {{ func.name }}
                    </option>
                </select>
                <span ng-click="closeFilterInnerBar()" class="margin-left">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
                </span>
            </div>
            <!-- Tags -->
            <div class="flex-vmiddle" ng-if="filterVisiblity.showInnerBar === innerBarType.tags">
                <span class="common-color-white margin-right flex-no-shrink">Go to tag:</span>
                <select
                    class="form-control"
                    ng-model="mobileFilters[innerBarType.tags]"
                    ng-change="navToFilter(mobileFilters[innerBarType.tags], null, innerBarType.tags)"
                >
                    <option value="" disabled selected>Select a tag</option>
                    <option ng-repeat="tag in tags = (pm.tags | orderBy:'name')" value="{{ tag.name }}">
                        {{ tag.name }}
                    </option>
                </select>
                <span ng-click="closeFilterInnerBar()" class="margin-left">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
                </span>
            </div>
            <!-- Status-->
            <div class="flex-vmiddle" ng-if="filterVisiblity.showInnerBar === innerBarType.status">
                <span class="common-color-white margin-right flex-no-shrink">Go to:</span>
                <select
                    class="form-control"
                    ng-model="mobileFilters[innerBarType.status]"
                    ng-change="navToFilter('all', mobileFilters[innerBarType.status], innerBarType.status)"
                >
                    <option value="" disabled selected>Select a status</option>
                    <option ng-repeat="stat in pm.projectData.stats" value="{{ stat.stateText }}">
                        {{ stat.stateText }} ({{ stat.count }})
                    </option>
                </select>
                <span ng-click="closeFilterInnerBar()" class="margin-left">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
                </span>
            </div>
        </div>
    </div>

    <div
        class="project-board-space flex-no-shrink hidden-xs"
        ng-if="showProjectFilter"
        ng-class="{ 'mod-small': filterVisiblity.showInnerBar }"
    ></div>
    <div
        class="project-board"
        id="project-board"
        ng-class="{
            'mod-full': data.filterCollapsed,
            'mod-no-nav-header': !showHeader,
            'padding-none mod-no-sidebar': !showProjectFilter,
            'common-width-auto': allowOverflowX,
            'mod-show-project-filter':
                showProjectFilter &&
                !(!pm.groups || !pm.groups.length || !(pm.groups | filter: { dashboardHidden: false }).length),
            'mod-show-inner-project-filter': showProjectInnerFilter,
            'mod-bot-sidepane-open': data.isBotSidePaneOpen,
            'mod-fullscreen': ns.isDrilldownMode
        }"
    >
        <ui-view></ui-view>
    </div>

    <!-- Bot side pane -->
    <!-- Removed for now. Probably not in use anymore -->
    <!-- <tnk-bot-side-pane
        class="hidden-xs"
        ng-if="showBotSidePane && $root.features.currentProject.tonkean_feature_show_old_v1_bot_side_pane"
        on-sidepane-open-changed="onBotSidePaneOpenChanged(isOpen)"
    ></tnk-bot-side-pane> -->

    <!-- Environment message -->
    <div
        ng-cloak
        class="environment-message mod-local"
        ng-if="environment === 'local' && !hideEnvMessage"
        ng-mouseover="envR=!envR"
        ng-class="{ 'mod-right': envR, 'mod-left': envR !== undefined && !envR }"
    >
        <div class="environment-message-text">Working with LOCAL API</div>
    </div>
    <div
        ng-cloak
        class="environment-message mod-test"
        ng-if="environment === 'test' && !hideEnvMessage"
        ng-mouseover="envR=!envR"
        ng-class="{ 'mod-right': envR, 'mod-left': envR !== undefined && !envR }"
    >
        <div class="environment-message-text">Working with TEST API</div>
    </div>
</div>
<!-- Filter popover template -->
<script type="text/ng-template" id="project-board-filter-popover-template">
    <!-- NOT FOR PC -->
    <tnk-project-stats class="flex-grow mod-no-top hidden-xs"
                       filter-visiblity="filterVisiblity"></tnk-project-stats>
</script>
