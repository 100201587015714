import { useAngularService } from 'angulareact';
import React, { useContext } from 'react';
import styled from 'styled-components';

import ProjectIntegrationPageContext from './ProjectIntegrationPageContext';
import ProjectIntegrationTogglesConfigurationComponent from './ProjectIntegrationTogglesConfigurationComponent';
import EnterpriseComponentPageTemplate from '../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';

import { angularToReact } from '@tonkean/angular-components';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { ProjectIntegrationIcon, TextEllipsis } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ConnectedInstancesTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
`;

const ConnectionBox = styled.div`
    display: flex;
    align-items: flex-start;
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    width: 220px;
    height: 100px;
`;

const EnvironmentLineIndicator = styled.div`
    margin-top: 3px;
    margin-left: 3px;
    width: 2px;
    height: 94px;
    background: ${Theme.current.palette.colorPicker.HEX_20C5F5};
    border-radius: 100px;
`;

const ConnectionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    width: 100%;
`;

const DisplayName = styled.div`
    max-width: 185px;
`;

const DisplayNameContainer = styled.div`
    display: flex;
    align-items: center;
`;

const EditIntegrationButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ProjectIntegrationIconView = styled(ProjectIntegrationIcon)`
    margin-right: 10px;
`;

const EditIntegrationButton = angularToReact<{
    projectIntegration: any;
    onProjectIntegrationUpdated: (updatedProjectIntegration: any) => void;
}>('tnk-edit-integration-button', { onProjectIntegrationUpdated: ['updatedProjectIntegration'] });

const AIAccessToggleIntegrationNames = new Set(['Azure OpenAI', 'OpenAI']);

interface Props {}

const ProjectIntegrationPageConnectionsPage: React.FC<Props> = () => {
    const { currentProjectIntegration, setCurrentProjectIntegration } = useContext(ProjectIntegrationPageContext);

    const $rootScope = useAngularService('$rootScope');
    const displayWebhooksControl =
        $rootScope.features.currentProject.tonkean_feature_display_control_of_webhooks_in_project_integration;
    const displayContinuousCollectControl =
        $rootScope.features.currentProject.tonkean_feature_display_control_of_continuous_collect_in_project_integration;
    const displaySystemAIAccessEnabled =
        useFeatureFlag('tonkean_feature_display_system_ai_access_toggle') &&
        AIAccessToggleIntegrationNames.has(currentProjectIntegration?.name || '');

    return (
        <EnterpriseComponentPageTemplate
            name="Connections"
            description="View and configure connected data source instances. For each instance, enable webhooks and continuous collect."
        >
            <ConnectedInstancesTitle>Connected Instances</ConnectedInstancesTitle>

            <ConnectionBox>
                <EnvironmentLineIndicator />

                <ConnectionContent>
                    <DisplayNameContainer>
                        <ProjectIntegrationIconView
                            iconUrl={currentProjectIntegration?.iconUrl}
                            integrationType={currentProjectIntegration?.integration.integrationType.toLowerCase()}
                            width={20}
                        />

                        <TextEllipsis numberOfLines={1} tooltip>
                            <DisplayName>{currentProjectIntegration?.displayName}</DisplayName>
                        </TextEllipsis>
                    </DisplayNameContainer>

                    <EditIntegrationButtonContainer>
                        <EditIntegrationButton
                            key={currentProjectIntegration?.id}
                            projectIntegration={currentProjectIntegration}
                            onProjectIntegrationUpdated={(updatedProjectIntegration: ProjectIntegration) => {
                                setCurrentProjectIntegration(updatedProjectIntegration);
                            }}
                        />
                    </EditIntegrationButtonContainer>
                </ConnectionContent>
            </ConnectionBox>

            {currentProjectIntegration &&
                (displayWebhooksControl || displayContinuousCollectControl || displaySystemAIAccessEnabled) && (
                    <ProjectIntegrationTogglesConfigurationComponent
                        projectId={currentProjectIntegration.projectId}
                        projectIntegrationId={currentProjectIntegration.id}
                        displayWebhooksControl={displayWebhooksControl}
                        displayContinuousCollectControl={displayContinuousCollectControl}
                        displaySystemAIAccessEnabledControl={displaySystemAIAccessEnabled}
                    />
                )}
        </EnterpriseComponentPageTemplate>
    );
};
export default ProjectIntegrationPageConnectionsPage;
