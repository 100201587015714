import lateConstructController from '../../utils/lateConstructController';
import { getDisplayFormats, TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { buildTnkSelectOption } from '@tonkean/infrastructure';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function UpdateFieldValueCtrl(
    $scope,
    $rootScope,
    $timeout,
    $location,
    $q,
    utils,
    tonkeanService,
    trackHelper,
    currentUserService,
    taTools,
    authenticationService,
) {
    // Disable the field selector for the html fields editor
    taTools.tnkInsertField.isEnabled = false;

    const ctrl = this;

    $scope.fieldDisplayFormats = getDisplayFormats();
    $scope.field = ctrl.field;
    $scope.fieldDefinition = ctrl.fieldDefinition;
    $scope.group = ctrl.group;
    $scope.initiative = ctrl.initiative;
    $scope.focus = ctrl.focus;
    $scope.isDisabled = ctrl.isDisabled;
    $scope.isCreatorOnlyValid = false;

    $scope.data = {
        posting: false,
        isEditing: false,
        appendDatePickerToBody: ctrl.appendDatePickerToBody,
        doNotUpdateInServer: ctrl.doNotUpdateInServer,
        hideSaveButton: ctrl.doNotUpdateInServer,
        newValue: '',
        newValueDate: '',
        newValueNumber: undefined,
        newValueTags: '',
        dropdownNewValues: [],
        dropdownPossibleValues: [],
        invalid: ctrl.invalid,
        selectStyles: {},
        isOnlyField: ctrl.isOnlyField,
        inlineUpdate: ctrl.inlineUpdate,
        buttonsColor: ctrl.buttonsColor,
        workflowVersionType: ctrl.workflowVersionType,
    };

    ctrl.$onInit = function () {
        const fieldType = $scope.fieldDefinition.fieldType.toLowerCase();
        $scope.data.displayFormat = $scope.fieldDefinition.displayFormat;
        // If $scope.data.newValue is not part of possibleValues, we add it to possible values so that the UI shows its value.
        switch (fieldType) {
            case 'list': {
                initDropdown();

                break;
            }
            case 'string': {
                $scope.data.newValue = $scope.field ? $scope.field.value : null;

                break;
            }
            case 'longstring': {
                $scope.data.newValue = $scope.field ? $scope.field.value : null;

                break;
            }
            case 'date': {
                $scope.data.newValueDate = $scope.field ? $scope.field.valueDate : null;

                break;
            }
            case 'number': {
                $scope.data.newValueNumber = $scope.field ? $scope.field.numberValue : null;

                break;
            }
        }

        const elementSuffixId = `manual-${$scope.fieldDefinition.id}`;

        if ($scope.fieldDefinition.isMultiValueField) {
            $scope.data.newValueTags = $scope.data.newValue?.length
                ? $scope.data.newValue.split($scope.fieldDefinition.outputMultiValueSeparator)
                : [];
        }

        if (ctrl.delegateSave && angular.isObject(ctrl.delegateSave)) {
            // execpeting an object
            ctrl.delegateSave.savePromise = innerSave;
            $scope.data.hideSaveButton = true;
        }

        // Get the active input element, and auto focus it if requested.
        if (ctrl.focus) {
            $timeout(function () {
                document.getElementById(`update-input-${elementSuffixId}`).focus();
            });
        }

        const trackCreatorAllowed = utils.findFirst(
            $scope.fieldDefinition.updateFieldPermissions,
            (element) => element === 'TRACK_CREATOR',
        );

        if (trackCreatorAllowed && $scope.initiative?.creator) {
            $scope.data.isCreatorOnlyValid = $scope.initiative.creator.id === authenticationService.currentUser.id;
        } else {
            $scope.data.isCreatorOnlyValid = true;
        }

        // If we dont have an initiative, it means we are having a create form.
        // The "Who can update" field configuration is only relevant for update form.
        if ($scope.initiative?.id) {
            const isUserAllowed = currentUserService.doesCurrentUserHavePermissionToEdit(
                $scope.fieldDefinition,
                $scope.initiative?.owner,
                $scope.group,
                $scope.initiative?.creator,
            );

            if (!$scope.isDisabled) {
                $scope.isDisabled = !isUserAllowed;
            }
        }
    };

    ctrl.$onChanges = function (changesObject) {
        if (changesObject.field) {
            $scope.field = changesObject.field.currentValue;
            ctrl.$onInit();
        }

        if (changesObject.fieldDefinition) {
            $scope.fieldDefinition = changesObject.fieldDefinition.currentValue;
            ctrl.$onInit();
        }

        if (changesObject.initiative) {
            $scope.initiative = changesObject.initiative.currentValue;
            ctrl.$onInit();
        }

        if (changesObject.invalid) {
            $scope.data.invalid = changesObject.invalid.currentValue;
            $scope.data.selectStyles = ctrl.invalid ? { control: (styles) => ({ ...styles, borderColor: 'red' }) } : {};
        }
    };

    $scope.dropdownUpdateOrCreateField = function (selectedOptions) {
        // tnk-select - the dropdown, is react. We need to sync the digest cycle.
        $timeout(() => {
            if (!$scope.data.hideSaveButton) {
                $scope.changeFieldValueEditMode(true);
            }
            $scope.$apply(() => {
                // This is done with apply so the react component will know to rerender
                $scope.data.dropdownNewValues = selectedOptions;
            });

            $scope.updateOrCreateField(
                $scope.fieldDefinition.id,
                extractValueFromDropdownOptions($scope.data.dropdownNewValues),
            );
        });
    };

    $scope.updateOrCreateField = function (fieldId, value) {
        $scope.data.newValue = value;

        $timeout(function () {
            if ($scope.fieldDefinition.fieldType === 'Date') {
                $scope.data.newValueDate = value;
            }
        });

        if ($scope.data.inlineUpdate && $scope.fieldDefinition.fieldType === 'Date') {
            $scope.updateOrCreateFieldSave();
        }

        if (ctrl.onFieldChanged) {
            ctrl.onFieldChanged({
                selectedValueToUpdate: value,
                fieldId,
            });
        }
    };

    $scope.updateOrCreateFieldSave = function () {
        if (!$scope.data.hideSaveButton && !$scope.data.doNotUpdateInServer) {
            // if allowing save - perform real save
            innerSave();
        }
    };

    $scope.onValueChanged = function (value) {
        if (!$scope.data.hideSaveButton) {
            $scope.changeFieldValueEditMode(true);
        } else {
            $scope.updateOrCreateField($scope.fieldDefinition.id, value);
        }
    };

    $scope.changeFieldValueEditMode = function (isEditMode) {
        $scope.data.isEditing = isEditMode;
    };

    $scope.onTagsChanged = (tags) => {
        $scope.data.newValueTags = tags;
        $scope.data.newValue = tags;

        if (!$scope.data.hideSaveButton) {
            $scope.changeFieldValueEditMode(true);
        } else {
            $scope.updateOrCreateField($scope.fieldDefinition.id, $scope.data.newValue);
        }
    };

    function getNewValue() {
        let newValue;
        const fieldType = $scope.fieldDefinition.fieldType.toLowerCase();

        // If $scope.data.newValue is not part of possibleValues, we add it to possible values so that the UI shows its newValue.
        switch (fieldType) {
            case 'list': {
                break;
            }
            case 'string': {
                newValue = $scope.data.newValue;

                break;
            }
            case 'date': {
                newValue = $scope.data.newValueDate;

                break;
            }
            case 'number': {
                newValue = $scope.data.newValueNumber;

                break;
            }
        }
        return newValue;
    }

    function innerSave() {
        const value = getNewValue();

        // Update the field's value.
        analyticsWrapper.track('Update field value', { category: 'Update' });
        $scope.data.posting = true;
        $scope.fieldPosting = true;

        let requestPromise;
        const isInitiativeField =
            ctrl.targetId && getTonkeanEntityType(ctrl.targetId) === TONKEAN_ENTITY_TYPE.INITIATIVE;
        let realValue = value;
        if (value instanceof Date) {
            realValue = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0);
        }

        const workerRunId = $location.search().workerRunId;
        const customTriggerId = $location.search().customTriggerId;
        const workflowVersionId = $location.search().workflowVersionId;

        if (isInitiativeField) {
            // If we received an initiative id, this is a column field (and not a global field). So we use the trackHelper to update it.
            if ($scope.field) {
                // If we have a field, we can update it.
                requestPromise = trackHelper.updateInitiativeDataTile(
                    ctrl.targetId,
                    $scope.field,
                    realValue,
                    undefined,
                    workerRunId,
                    customTriggerId,
                    workflowVersionId,
                );
            } else {
                // If we don't have a field, we need to create it.
                requestPromise = trackHelper.createInitiativeDataTile(
                    ctrl.targetId,
                    realValue,
                    ctrl.fieldDefinition,
                    workerRunId,
                    customTriggerId,
                    workflowVersionId,
                );
            }
        } else {
            // We did not receive an initiative id. Just update the field itself.
            // This field is a key metric / KPI, for which is not possible not to have a field instance.
            requestPromise = tonkeanService.updateFieldValue(
                $scope.field.id,
                realValue,
                undefined,
                workerRunId,
                customTriggerId,
                workflowVersionId,
            );
        }

        return requestPromise
            .then(function (updatedField) {
                // Update the given field with new values while preserving the reference (if we had a reference to begin with).
                if ($scope.field) {
                    angular.extend($scope.field, updatedField);
                } else {
                    $scope.field = updatedField;
                }

                // Reset the local editing flag.
                $scope.data.isEditing = false;

                if (!$scope.data.hideSaveButton) {
                    // Emit a success message.
                    $rootScope.$emit('alert', { type: 'success', msg: 'Saved. Thanks!' });
                }

                if (ctrl.onSaved) {
                    // Sending the same object back (but updated).
                    // This is important since callers depend on the reference to cancel editing mode.
                    ctrl.onSaved({ updatedField: $scope.field });
                }
            })
            .then(() => {
                if ($scope.data.isOnlyField) {
                    return tonkeanService.resumeModuleFlowAfterTrigger(
                        ctrl.targetId,
                        workerRunId,
                        customTriggerId,
                        workflowVersionId,
                    );
                }

                return $q.resolve();
            })
            .catch(function () {
                $rootScope.$emit('alert', { type: 'danger', msg: 'Value is invalid' });
            })
            .finally(function () {
                $scope.data.posting = false;
                $scope.fieldPosting = false;
            });
    }

    /**
     * This function build the possible values for a dropdown component.
     * The function looks for the dropdown configuration source and extract the necessary values for the options.
     */
    function initDropdown() {
        // Load the possible values
        $scope.data.dropdownPossibleValues = calculatePossibleValuesForDropdown($scope.fieldDefinition);

        // Build the possible values array of objects. We need to get the options to be from the type { value: 5, label: 'Some Label }
        // because the tnk-select component expects it to be from that type
        $scope.data.dropdownPossibleValues = buildDropdownOptionsFromValues($scope.data.dropdownPossibleValues);

        // current field value is undefined and there is a configured suggested value
        const suggestedValue =
            $scope.fieldDefinition.suggestedValue &&
            $scope.data.dropdownPossibleValues &&
            $scope.data.dropdownPossibleValues.find((value) => value.value === $scope.fieldDefinition.suggestedValue);
        if (suggestedValue && $scope.field?.value === undefined) {
            if ($scope.fieldDefinition.isMultiValueField) {
                $scope.data.dropdownNewValues = buildDropdownOptionsFromValues([suggestedValue.value]);
            } else {
                $scope.data.dropdownNewValues = buildDropdownOptionsFromValues(suggestedValue.value);
            }
            $scope.dropdownUpdateOrCreateField($scope.data.dropdownNewValues);
        } else {
            // We have a selected value and we want to show it in the dropdown
            if ($scope.fieldDefinition.isMultiValueField) {
                $scope.data.dropdownNewValues = buildDropdownOptionsFromValues(
                    $scope.field?.value.split($scope.fieldDefinition.outputMultiValueSeparator),
                );
            } else {
                $scope.data.dropdownNewValues = buildDropdownOptionsFromValues($scope.field?.value);
            }
        }
    }

    /**
     * Calculate the possible values from the field definition and the field value
     * @param fieldDef - The given field definition to extract the options from
     * @returns {[]} - An array of string which are the options you may choose in the dropdown
     */
    function calculatePossibleValuesForDropdown(fieldDef) {
        if (
            fieldDef.dropdownSource === 'FROM_FIELD' &&
            ($scope.initiative?.fields?.length ||
                $scope.group.globalFields?.length ||
                $scope.group.globalFieldsDraft?.length)
        ) {
            const relevantGlobalFields =
                $scope.initiative?.isDraftInitiative || $scope.data.workflowVersionType?.toUpperCase() === 'DRAFT'
                    ? $scope.group.globalFieldsDraft ?? []
                    : $scope.group.globalFields ?? [];

            const fromFieldInstances = [...($scope.initiative?.fields ?? []), ...relevantGlobalFields];

            const fromFieldInstance = fromFieldInstances.find(
                (field) => field.fieldDefinition.id === fieldDef.dropdownOptionsFromFieldDefinitionId,
            );

            return (
                fromFieldInstance?.multiValues ||
                fromFieldInstance?.values ||
                fromFieldInstance?.value?.split(fieldDef?.inputMultiValueSeparator || ',')
            );
        } else if (
            fieldDef.dropdownSource === 'MANUAL' ||
            fieldDef.dropdownSource === 'SYNC_FIELD' ||
            !fieldDef.dropdownSource
        ) {
            return fieldDef.possibleValues.map((value) => {
                // Non-graphql values are sent in plain text
                if (typeof value === 'string') {
                    return value;
                }

                // Graphql possible value are sent as complete objects
                return value.possibleValue;
            });
        } else {
            return [];
        }
    }

    /**
     * tnk-select component needs to get options that are structured by { value: '5', label: 'Some Label' }
     * This function iterates over an array values and builds an array that are from the type written above.
     * Labels are generated from the given value.
     * @param options - An array of values
     * @returns {label: *, value: *} from the type { value: '5', label: 'Some Label' }
     */
    function buildDropdownOptionsFromValues(values) {
        if (Array.isArray(values)) {
            return values.map(buildTnkSelectOption);
        } else if (values) {
            return buildTnkSelectOption(values);
        }
    }

    /**
     * tnk-select component needs to get options that are structured by { value: '5', label: 'Some Label' }
     * This function iterates over an array of such objects and extracts the values. Labels are ignored.
     * @param options - An array of options from type  { value: '5', label: 'Some Label' }
     * @returns Array with values extracted from the options array given
     */
    function extractValueFromDropdownOptions(options) {
        if (Array.isArray(options)) {
            return options.map((item) => item.value);
        } else if (options) {
            return options.value;
        }
    }
}

angular.module('tonkean.app').controller('UpdateFieldValueCtrl', lateConstructController(UpdateFieldValueCtrl));
