import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import type { BaseDataSourceConnectionConfigurationProps } from './baseDataSourceConnectionConfigurationProps';
import DataSourceConnectionBreadCrumbs from './DataSourceConnectionBreadCrumbs';
import { DataSourceConnectionTab } from './DataSourceConnectionTab';
import { ReactComponent as MoreActionIcon } from '../../../../../images/icons/more-actions.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-form.svg';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    ErrorMessage,
    LoadingCircle,
    Menu,
    MenuItem,
    Tooltip,
    useCreateDialog,
    useToggle,
} from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 112px;
    padding-top: 25px;
    padding-bottom: 14px;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const BreadCrumbsAndActionButton = styled.div`
    display: inline-flex;
`;

const HeaderTitle = styled.div`
    font-size: ${FontSize.XXXXLARGE_24};
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    height: 30px;
`;

const TitleAndButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: 5px;
`;

const MenuItemWrapper = styled(MenuItem)<{ disabled: boolean }>`
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;

const SavesChangesSection = styled.div`
    display: flex;
    margin-left: auto;
`;

const SaveChangesButton = styled(Button)`
    margin-left: 8px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 7px;
    border: 3px solid white;
    border-right-color: ${Theme.colors.primary};
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-left: 8px;
`;

const DataSourceConnectionPageHeader: React.FC<
    AdditionalSidePaneConfiguration<BaseDataSourceConnectionConfigurationProps>
> = ({
    currentDataSourceConnection,
    otherPossibleDataSourceConnections,
    sidePaneTabName,
    onClickSave,
    onClickCancel,
    sidePaneTabKey,
    hasChanges,
}) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const { confirm } = useCreateDialog();
    const [isSavingInProgress, setIsSavingInProgress] = useToggle(false);
    const [savingError, setSavingError] = useState('');

    const [{ loading }, deleteDataSourceConnection] = useLazyTonkeanService('deleteDataSourceConnection');
    const $state = useAngularService('$state');

    const onClickDeleteAction = async () => {
        const isConfirmed = await confirm('Are you sure you want to delete this data source connection?', {
            okLabel: 'Confirm',
            cancelLabel: 'Cancel',
        });

        if (isConfirmed) {
            await deleteDataSourceConnection(currentDataSourceConnection.projectId, currentDataSourceConnection.id);
            $state.go('product.settings.dataSources');
        }
    };

    const disableDeleteButton = useMemo(() => {
        return currentDataSourceConnection.sharedCredentials.length > 0;
    }, [currentDataSourceConnection.sharedCredentials]);

    const onSave = useCallback(async () => {
        setSavingError('');
        setIsSavingInProgress();
        try {
            await onClickSave();
        } catch {
            setSavingError('There was a problem saving the data source connection');
        } finally {
            setIsSavingInProgress();
        }
    }, [onClickSave, setIsSavingInProgress]);

    const onCancel = useCallback(() => {
        setSavingError('');
        onClickCancel();
    }, [onClickCancel, setSavingError]);

    return (
        <>
            <HeaderWrapper>
                <BreadCrumbsAndActionButton>
                    <DataSourceConnectionBreadCrumbs
                        currentDataSourceConnection={currentDataSourceConnection}
                        otherPossibleDataSourceConnections={otherPossibleDataSourceConnections}
                    />

                    <Menu
                        show={isMenuOpen}
                        onClose={toggleIsMenuOpen}
                        placement="right-start"
                        menuItems={
                            <>
                                <Tooltip
                                    disabled={!disableDeleteButton}
                                    content="Unable to delete data source connection with predefined connections."
                                >
                                    <MenuItemWrapper
                                        disabled={disableDeleteButton}
                                        onClick={onClickDeleteAction}
                                        icon={<TrashIcon />}
                                    >
                                        Delete
                                    </MenuItemWrapper>
                                </Tooltip>
                            </>
                        }
                        thin
                    >
                        <StyledIconButton onClick={() => toggleIsMenuOpen()} flat>
                            <MoreActionIcon />
                        </StyledIconButton>
                    </Menu>

                    {loading && <LoadingCircle className="margin-top margin-left" />}
                </BreadCrumbsAndActionButton>

                <TitleAndButtonsWrapper>
                    <HeaderTitle>{sidePaneTabName}</HeaderTitle>

                    {sidePaneTabKey === DataSourceConnectionTab.ACCESSIBILITY && (
                        <SavesChangesSection>
                            <Button disabled={!hasChanges} onClick={onCancel} size={ButtonSize.BIG} outlined flex>
                                Cancel
                            </Button>

                            <SaveChangesButton disabled={!hasChanges} onClick={onSave} size={ButtonSize.BIG} flex>
                                Save Changes
                                {isSavingInProgress && <StyledLoadingCircle />}
                            </SaveChangesButton>
                        </SavesChangesSection>
                    )}
                    {savingError && <StyledErrorMessage>{savingError}</StyledErrorMessage>}
                </TitleAndButtonsWrapper>
            </HeaderWrapper>
        </>
    );
};

export default DataSourceConnectionPageHeader;
