import { getFieldConditionToApiDefinitionMap } from '@tonkean/constants';

function TriggerGalleryConsts() {
    const _this = this;

    const conditions = getFieldConditionToApiDefinitionMap();

    const triggerTemplates = {
        CHURN_RISK: {
            id: 'CHURN_RISK',
            title: 'Churn Risk', // shows in list
            shortDescription: 'Alert account owners that criteria indicate client at-risk for churn.', // shows in list
            longDescription:
                'Automatically and proactively propel sales reps in to action when conditions indicate a client may be at-risk. Reinforce retention with active monitoring of key customer metrics and real-time actionability.',
            categories: ['Sales', 'Churn', 'Customer Success'],
            relatedLists: ['TOP_CUSTOMERS', 'CHURN_RISK'],
            botPreview: {
                entity: 'ACME',
                message: 'High churn risk identified. Reach out to client and provide an update please.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Revenue',
                        fieldLabel: 'Revenue',
                        integrationType: 'sales',
                        type: 'Number',
                        condition: 'GreaterThan',
                        value: 1000,
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Renewal Date',
                        fieldLabel: 'Renewal Date',
                        integrationType: 'sales',
                        type: 'Date',
                        condition: 'InThis',
                        value: 'Month',
                        isTriggerTemplateData: true,
                    },
                ],
                queries: [
                    {
                        type: 'Or',
                        filters: [
                            {
                                fieldName: 'Usage',
                                fieldLabel: 'Usage',
                                integrationType: 'analytics',
                                type: 'Number',
                                condition: conditions.DecreasedBy.apiName,
                                value: '50',
                                isTriggerTemplateData: true,
                            },
                            {
                                fieldName: 'NPS',
                                fieldLabel: 'NPS',
                                integrationType: 'support',
                                type: 'Number',
                                condition: 'LessThan',
                                value: 5,
                                isTriggerTemplateData: true,
                            },
                        ],
                    },
                ],
            },
            defaultDataSources: {
                analytics: { name: 'Analytics', iconUrl: '/images/integrations/googleanalytics-circle.png' },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
                UPDATE: [{ fieldName: 'Status', value: 'At Risk' }],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        UP_SALE: {
            id: 'UP_SALE',
            title: 'Upsell Ready', // shows in list
            shortDescription: 'Notify sales reps that criteria indicate an opportunity to upsell a client.', // shows in list
            longDescription:
                'Automatically and proactively propel reps in to action when conditions indicate an upsell opportunity. Support revenue growth with active monitoring of key customer metrics and real-time actionability.',
            categories: ['Sales'],
            relatedLists: ['UPSALES_OPPS', 'REVENUE_TRACKING', 'FORECAST', 'CUSTOMERS_RENEWAL'],
            botPreview: {
                entity: 'ACME',
                message: 'High potential for upsell identified! Reach out to client and provide an update please.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Renewal Date',
                        fieldLabel: 'Renewal Date',
                        integrationType: 'sales',
                        type: 'Date',
                        condition: 'InThis',
                        value: 'Month',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Usage',
                        fieldLabel: 'Usage',
                        integrationType: 'analytics',
                        type: 'Number',
                        condition: conditions.IncreasedBy.apiName,
                        value: '50',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'NPS',
                        fieldLabel: 'NPS',
                        integrationType: 'support',
                        type: 'Number',
                        condition: 'GreaterThan',
                        value: 6,
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                analytics: { name: 'Analytics', iconUrl: '/images/integrations/googleanalytics-circle.png' },
                sales: { name: 'CRM tool', integration: 'hubspot' },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
                UPDATE: [
                    { fieldName: 'Owner', value: 'Joe' },
                    { fieldName: 'Probability', value: 'Ask Owner', integrationType: 'sales' },
                ],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        AUTO_CLOSE: {
            id: 'AUTO_CLOSE',
            title: 'Auto Close Old Items', // shows in list
            shortDescription: 'Automatically close items that are not done and their due date has passed 30 days ago.', // shows in list
            longDescription:
                'Keep your list clean by automatically and proactively mark items as Done when their due date has past a while ago and no update was giving since.',
            categories: ['Project', 'Marketing', 'Sales'],
            relatedLists: ['MULTI_PROJECTS', 'REMOTE_WORKERS', 'MEETING_OPS'],
            botPreview: {
                entity: 'ACME',
                message: 'Automatically closing as due date past 30 days ago.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        id: 'TNK_STAGE',
                        fieldName: 'TNK_STAGE',
                        fieldLabel: 'Status',
                        type: 'String',
                        condition: 'NotEquals',
                        value: 'Done',
                    },
                    {
                        id: 'TNK_DUE_DATE',
                        fieldName: 'TNK_DUE_DATE',
                        fieldLabel: 'Due Date',
                        type: 'Date',
                        condition: conditions.OlderThan.apiName,
                        value: '30',
                    },
                    {
                        id: 'TNK_MODIFIED_DATE',
                        fieldName: 'TNK_MODIFIED_DATE',
                        fieldLabel: 'Modified',
                        type: 'Date',
                        condition: conditions.OlderThan.apiName,
                        value: '25',
                    },
                ],
            },
            actions: {
                ASK: true,
                UPDATE: [
                    {
                        fieldName: 'Status',
                        value: 'Done',
                        updateObjectMap: { id: 'TNK_STAGE', obj: { label: 'Done', color: '#396394', type: 'DONE' } },
                    },
                ],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        SLA_BREACH: {
            id: 'SLA_BREACH',
            title: 'SLA Breach Risk', // shows in list
            shortDescription: 'Alert account owners that an SLA is on the verge of breaching.', // shows in list
            longDescription:
                "Automatically and proactively propel account executives in to action when a client's SLA is about to breach. Support improved customer success with active monitoring of key metrics and real-time actionability.",
            categories: ['Support'],
            relatedLists: ['CUSTOMERS_RENEWAL', 'TOP_CUSTOMERS'],
            botPreview: {
                entity: 'ACME',
                message: 'SLA about to breach. Review account and provide an update please.',
            },
            query: {
                type: 'Or',
                filters: [
                    {
                        fieldName: 'SLA',
                        fieldLabel: 'SLA',
                        integrationType: 'support',
                        type: 'Number',
                        condition: 'LessThan',
                        value: '50',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'SLA',
                        fieldLabel: 'SLA',
                        integrationType: 'support',
                        type: 'Number',
                        condition: conditions.DecreasedBy.apiName,
                        value: '20',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {},
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
                UPDATE: [
                    { fieldName: 'Status', value: 'At Risk' },
                    { fieldName: 'Probability', value: 'Ask Owner', integrationType: 'sales' },
                ],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        HIGH_POTENTIAL_CUSTOMER: {
            id: 'HIGH_POTENTIAL_CUSTOMER',
            title: 'High Potential Customer', // shows in list
            shortDescription: 'Notify sales reps that a prospect has been identified as having high close probability.', // shows in list
            longDescription:
                'Automatically and proactively propel sales in to action when conditions indicate a high potential prospect. Support revenue growth with active monitoring of key customer metrics and real-time actionability.',
            categories: ['Support'],
            relatedLists: ['CUSTOMERS_RENEWAL', 'TOP_CUSTOMERS'],
            botPreview: {
                entity: 'ACME',
                message: 'High potential prospect identified. Engage and provide an update please.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Trial End Date',
                        fieldLabel: 'Trial End Date',
                        integrationType: 'sql',
                        type: 'Date',
                        condition: 'Next',
                        value: '7',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Plan',
                        fieldLabel: 'Plan',
                        integrationType: 'accounting',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'Trial',
                        isTriggerTemplateData: true,
                    },
                ],
                queries: [
                    {
                        type: 'Or',
                        filters: [
                            {
                                fieldName: 'Usage',
                                fieldLabel: 'Usage',
                                integrationType: 'analytics',
                                type: 'Number',
                                condition: conditions.IncreasedBy.apiName,
                                value: '50',
                                isTriggerTemplateData: true,
                            },
                            {
                                fieldName: 'Active Users',
                                fieldLabel: 'Active Users',
                                integrationType: 'analytics',
                                type: 'Number',
                                condition: conditions.GreaterThan.apiName,
                                value: '5',
                                isTriggerTemplateData: true,
                            },
                            {
                                fieldName: 'Unique Items',
                                fieldLabel: 'Unique Items',
                                integrationType: 'sql',
                                type: 'Number',
                                condition: conditions.GreaterThan.apiName,
                                value: '100',
                                isTriggerTemplateData: true,
                            },
                        ],
                    },
                ],
            },
            defaultDataSources: {
                analytics: { name: 'Analytics', iconUrl: '/images/integrations/googleanalytics-circle.png' },
                sql: { name: 'SQL', integration: 'sql' },
                accounting: {
                    name: 'Invoicing',
                    iconUrl: 'https://images.tonkean.com/webhooks_icons/27f5433f521806493e2c38a304e1786e.128x128.png',
                },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
                UPDATE: [{ fieldName: 'Status', value: 'High Potential' }],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        MISS_MILESTONE: {
            id: 'MISS_MILESTONE',
            title: 'Milestone At-Risk', // shows in list
            shortDescription: 'Inform and request status on project milestones in danger of being missed.', // shows in list
            longDescription:
                'Automatically ensure task owners are aware of critical deadlines approaching, and understand context for any that may be in danger of passing.',
            categories: ['Project'],
            relatedLists: ['PRODUCT_MILESTONES', 'MULTI_PROJECTS', 'PRODUCT_ROADMAP'],
            botPreview: {
                entity: 'Milesone X',
                message: 'At-risk for missing milestone. What is the latest status?',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'TNK_DUE_DATE',
                        fieldLabel: 'Due Date',
                        type: 'Date',
                        condition: 'Next',
                        value: '7',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Progress',
                        fieldLabel: 'Progress',
                        integrationType: 'issues',
                        type: 'Number',
                        condition: conditions.LessThan.apiName,
                        value: '80%',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                issues: { name: 'Project Management', integration: 'jira' },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
                UPDATE: [{ fieldName: 'Status', value: 'At Risk' }],
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        TOP_PRIORITY: {
            id: 'TOP_PRIORITY',
            title: 'Top Priority items due this week', // shows in list
            shortDescription:
                'Request update from task owners on high priority projects with due dates in the next week.', // shows in list
            longDescription:
                'Automatically ensure task owners are aware of critical deadlines approaching, and understand context for any that may be in danger of passing.',
            categories: ['Project'],
            relatedLists: ['TOP_INITIATIVES', 'PRODUCT_MILESTONES', 'MULTI_PROJECTS', 'PRODUCT_ROADMAP'],
            botPreview: {
                entity: 'Inititaive X',
                message: 'Top priority coming due. Please provide a status update.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'TNK_DUE_DATE',
                        fieldLabel: 'Due Date',
                        type: 'Date',
                        condition: 'Next',
                        value: '7',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Priority',
                        fieldLabel: 'Priority',
                        integrationType: 'spreadsheet',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'Top Priority',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                spreadsheet: { name: 'Spreadsheet', integration: 'googlesheets' },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        CHURN_RETROSPECTIVE: {
            id: 'CHURN_RETROSPECTIVE',
            title: 'Churn Retrospective', // shows in list
            shortDescription: 'Engage sales reps for insight on a customer recently lost.', // shows in list
            longDescription: 'Automatically get the insight you need from sales reps improve your customer success.',
            categories: ['Churn', 'Customer Success'],
            relatedLists: ['TOP_CUSTOMERS', 'CHURN_RISK'],
            botPreview: {
                entity: 'ACME',
                message: 'Recently lost. What happened?',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Renewal Date',
                        fieldLabel: 'Renewal Date',
                        integrationType: 'sales',
                        type: 'Date',
                        condition: 'Past',
                        value: '3',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Is Licensed',
                        fieldLabel: 'Is Licensed',
                        integrationType: 'sales',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'False',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {},
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        CONVERSION_ANOMALY: {
            id: 'CONVERSION_ANOMALY',
            title: 'Campaign Conversion Anomaly', // shows in list
            shortDescription: 'Alert campaign managers when changes in campaign conversion rates have occurred.', // shows in list
            longDescription:
                'Proactively get the insight you need to most effectively optimize your campaigns. Cancel low performing campaigns more quickly ensuring greater results.',
            categories: ['Marketing'],
            relatedLists: ['CONVERSIONS', 'MARKETING_CAMPAIGN'],
            botPreview: {
                entity: 'CampaignX',
                message: 'Significant change to conversion rate. Review campaign details and provide an update please.',
            },
            query: {
                type: 'Or',
                filters: [
                    {
                        fieldName: 'Conversions % (7d)',
                        fieldLabel: 'Conversions % (7d)',
                        integrationType: 'analytics',
                        type: 'Number',
                        condition: conditions.DecreasedBy.apiName,
                        value: '15',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Conversions % (7d)',
                        fieldLabel: 'Conversions % (7d)',
                        integrationType: 'analytics',
                        type: 'Number',
                        condition: conditions.IncreasedBy.apiName,
                        value: '15',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                analytics: { name: 'Analytics', iconUrl: '/images/integrations/googleanalytics-circle.png' },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        REACHING_BUDGET: {
            id: 'REACHING_BUDGET',
            title: 'Campaign Approaching Budget', // shows in list
            shortDescription: 'Inform marketing managers that the budget limit for a campaign is nearing its limit.', // shows in list
            longDescription:
                'Automatically ensure campaigns stay on budget. Support greater efficiency and proactive campaign optimization.',
            categories: ['Marketing'],
            relatedLists: ['CONVERSIONS', 'MARKETING_CAMPAIGN'],
            botPreview: {
                entity: 'CampaignX',
                message: 'Is approaching budget!',
            },
            query: {
                type: 'Or',
                filters: [
                    {
                        fieldName: 'Budget Spent % (Daily)',
                        fieldLabel: 'Budget Spent % (Daily)',
                        integrationType: 'analytics',
                        type: 'Number',
                        condition: conditions.GreaterThan.apiName,
                        value: '90',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Budget Spent % (Weekly)',
                        fieldLabel: 'Budget Spent % (Weekly)',
                        integrationType: 'analytics',
                        type: 'Number',
                        condition: conditions.GreaterThan.apiName,
                        value: '75%',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                analytics: {
                    name: 'Ads Analytics',
                    iconUrl: 'https://images.tonkean.com/webhooks_icons/aad802d3b949e2f74aa20916b31a5cfb.128x128.png',
                },
            },
            actions: {
                ASK: true,
                NOTIFY_CHANNEL: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        NO_CONTACT_3D: {
            id: 'NO_CONTACT_3D',
            title: 'No Contact (3 Days)',
            shortDescription: 'Activate account owners to engage a client/prospect following a three-day lapse.',
            longDescription:
                'Automatically and proactively ensure sales reps are following up with clients and prospects in a systematic way. Reinforce revenue growth success.',
            categories: ['Customer Success', 'Support'],
            relatedLists: ['CUSTOMERS_RENEWAL', 'TOP_CUSTOMERS'],
            botPreview: {
                entity: 'ACME',
                message: 'No contact in 3 days. Reach out to customer/prospect and provide an update please.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Last Contacted',
                        fieldLabel: 'Last Contacted',
                        integrationType: 'sales',
                        type: 'Date',
                        condition: conditions.OlderThan.apiName,
                        value: '3',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Plan',
                        fieldLabel: 'Plan',
                        integrationType: 'accounting',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'Trial',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                accounting: {
                    name: 'Invoicing',
                    iconUrl: 'https://images.tonkean.com/webhooks_icons/27f5433f521806493e2c38a304e1786e.128x128.png',
                },
            },
            actions: {
                ASK: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        NOT_SEEN_7D: {
            id: 'NOT_SEEN_7D',
            title: 'Not Seen (7 Days)',
            shortDescription: 'Notify account owners that a customer has not been seen over the past 7 days.',
            longDescription:
                'Automatically and proactively ensure sales reps are following up with customers who may be approaching churn risk. Enable rapid engagement and issue resolution to reinforce customer success.',
            categories: ['Customer Success', 'Support'],
            relatedLists: ['CUSTOMERS_RENEWAL', 'TOP_CUSTOMERS'],
            botPreview: {
                entity: 'ACME',
                message: 'Not seen in 7 days. Reach out to customer and provide an update please.',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'Last Seen',
                        fieldLabel: 'Last Seen',
                        integrationType: 'analytics',
                        type: 'Date',
                        condition: conditions.OlderThan.apiName,
                        value: '7',
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Plan',
                        fieldLabel: 'Plan',
                        integrationType: 'accounting',
                        type: 'String',
                        condition: conditions.NotEquals.apiName,
                        value: 'Trial',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                analytics: {
                    name: 'Analytics',
                    iconUrl: 'https://images.tonkean.com/webhooks_icons/c759dd77854e51d5383ac8166e774080.128x128.png',
                },
                accounting: {
                    name: 'Invoicing',
                    iconUrl: 'https://images.tonkean.com/webhooks_icons/27f5433f521806493e2c38a304e1786e.128x128.png',
                },
            },
            actions: {
                ASK: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        TASK_DUE_TODAY: {
            id: 'TASK_DUE_TODAY',
            title: 'Daily High Priority Tasks',
            shortDescription: 'Notify the team channel for high priority tasks for today.',
            longDescription:
                'Automatically ensure everyone in the team is aware of important items that are due today. Provide greater leadership and more effective resource allocation with increased visibility into team performance.',
            categories: ['Project', 'Marketing'],
            relatedLists: ['MEETING_PROJ', 'PROJECT_TRACKER', 'CROSS_DEPARTMENT'],
            botPreview: {
                entity: 'TaskX',
                message: 'Is high priority and is due today',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'TNK_DUE_DATE',
                        fieldLabel: 'Due Date',
                        type: 'Date',
                        condition: conditions.Next.apiName,
                        value: 1,
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Priority',
                        fieldLabel: 'Priority',
                        integrationType: 'issues',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'Top Priority',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                issues: { name: 'Task Management Tool', integration: 'jira' },
            },
            actions: {
                ASK: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },

        TASK_OVERDUE: {
            id: 'TASK_OVERDUE',
            title: 'Critical task is overdue',
            shortDescription:
                'Request an update from a task owner on a critical deliverable that has missed its due date.',
            longDescription:
                'Automatically get context from task owners on items that failed to meet their deadlines. Provide greater leadership and more effective resource allocation with increased visibility into team performance.',
            categories: ['Project', 'Marketing'],
            relatedLists: ['MEETING_PROJ', 'PROJECT_TRACKER', 'CROSS_DEPARTMENT'],
            botPreview: {
                entity: 'TaskX',
                message: 'Is CRITICAL and still open!',
            },
            query: {
                type: 'And',
                filters: [
                    {
                        fieldName: 'TNK_DUE_DATE',
                        fieldLabel: 'Due Date',
                        type: 'Date',
                        condition: conditions.Past.apiName,
                        value: 1,
                        isTriggerTemplateData: true,
                    },
                    {
                        fieldName: 'Priority',
                        fieldLabel: 'Priority',
                        integrationType: 'issues',
                        type: 'String',
                        condition: conditions.Equals.apiName,
                        value: 'Critical',
                        isTriggerTemplateData: true,
                    },
                ],
            },
            defaultDataSources: {
                issues: { name: 'Task Management Tool', integration: 'teamwork' },
            },
            actions: {
                ASK: true,
            },
            creator: {
                name: 'Sagi Eliyahu',
                company: 'Tonkean',
                avatarUri: 'https://images.tonkean.com/DEMO/f3oygm6jt1z6631d7y9cp37bn/image.png',
            },
        },
    };

    _this.getTriggerTemplates = function () {
        return angular.copy(triggerTemplates);
    };
}

export default angular.module('tonkean.shared').service('triggerGalleryConsts', TriggerGalleryConsts);
