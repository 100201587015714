<input
    type="text"
    autocomplete="off"
    class="{{ classes }}"
    ng-required="isRequired"
    ng-model="model"
    placeholder="{{ placeholder }}"
    uib-typeahead="user for user in searchPeople(model)"
    typeahead-loading="loadingPeople"
    typeahead-is-open="model && model.length > 0 && !data.userSelected"
    typeahead-no-results="data.noResults"
    typeahead-on-select="selectUser($item, $model, $label, $event)"
    typeahead-focus-first="false"
    typeahead-template-url="tnkPeopleNamesAutocompleteMatchTemplate.html"
/>

<script type="text/ng-template" id="tnkPeopleNamesAutocompleteMatchTemplate.html">
    <a class="tnk-people-names-autocomplete-result">
        <span ng-if="match.label.error">Failed finding people: {{match.label.error | error}}</span>
        <tnk-user ng-if="!match.label.error" class="mod-one-line" user="match.label" default-on-empty="true"></tnk-user>
    </a>
</script>
