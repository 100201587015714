import { angularToReact } from 'angulareact';

import type {
    Form,
    FullProject,
    Initiative,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

/**
 * Not the full list of props, if needed add the missing
 */
export type FormPageProps = {
    formId: Form['id'];
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    project: FullProject;
    formVersionType: WorkflowVersionType | undefined;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    onFormClosed?: () => void;
    onInitiativeCreated: (result: {
        createdInitiative: Initiative;
        relatedWorkerRunId: TonkeanId<TonkeanType.WORKER_RUN>;
        formDisplayName: string;
    }) => void;
    parentInitiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    isModal?: boolean;
    onSubmitResolved?: () => void | undefined;
    formModalRef?: HTMLDivElement | null;
    createUsingCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
};

const FormPage = angularToReact<FormPageProps>('tnk-form-page', angular.module('tonkean.app'));

export default FormPage;
