import { operatorKeyToOperatorMap } from '@tonkean/forumla-operators';
import type { CustomFieldsManager } from '@tonkean/shared-services';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FormulaField } from '@tonkean/tonkean-entities';
import { FormulaTreeConstNode } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FormulaTreeVariableNode } from '@tonkean/tonkean-entities';
import { FormulaTreeTreeNode } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';

// This regex is splitting the expression to parts of variables and const.
const splitExpressionRegex = /{{.+?}}|(?:^|(?!}})).+?(?:(?={{)|$)/gm;

/**
 * Converts a tonkean expression to a formula tree. **It ignores formulas in expression and should be used only if
 * you are sure that the expression contains only strings and variables**.
 *
 * @param expression - the expression to convert.
 * @param rootField - the field into which the tree node will be placed.
 * @param workflowVersionId - workflow version id.
 * @param customFieldsManager - the fields definition cache.
 * @returns formula tree node.
 */
function expressionToFormulaTree(
    expression: string,
    rootField: FormulaField,
    workflowVersionId: string,
    customFieldsManager: CustomFieldsManager,
): formulaTreeNode {
    const expressionParts = expression.match(splitExpressionRegex);

    if (!expressionParts) {
        return new FormulaTreeConstNode(FieldType.String, '', rootField);
    }

    const concatOperator = operatorKeyToOperatorMap[OperatorKey.STRING_CONCAT];
    // If formula has multiple parts, get the fields from the concat operator. Otherwise, the only field
    // is root field
    const fieldsList =
        expressionParts.length === 1 ? [rootField] : concatOperator.getFieldsList(expressionParts.length);

    const formulaParts: formulaTreeNode[] = expressionParts.map((part, index) => {
        const formulaField = fieldsList[index];
        if (!formulaField) {
            throw new Error(`no formula field found in index ${index}`);
        }

        if (part.startsWith('{{') && part.endsWith('}}')) {
            // A variable
            const variableId = part.slice(2, -2);

            const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(workflowVersionId, variableId);
            const variableName = fieldDefinition?.name;
            const variableType = fieldDefinition?.fieldType;

            // If fieldsList has multiple fields, it means that is wrapped with concat, and concat accepts only strings
            if (fieldsList.length === 1 || variableType === FieldType.String) {
                return new FormulaTreeVariableNode(
                    variableId,
                    variableName,
                    variableType as FieldType.String,
                    formulaField,
                );
            }

            const toStringOperator = operatorKeyToOperatorMap[OperatorKey.TO_STRING];
            const toStringOperandField = toStringOperator.getFieldsList()[0];
            const toStringOperand = new FormulaTreeVariableNode(
                variableId,
                variableName,
                variableType as FieldType.String,
                toStringOperandField,
            );
            return new FormulaTreeTreeNode(toStringOperator, [toStringOperand], formulaField);
        }

        // A string
        return new FormulaTreeConstNode(FieldType.String, part, formulaField);
    });

    if (fieldsList.length === 1) {
        return formulaParts[0]!;
    } else {
        return new FormulaTreeTreeNode(concatOperator, formulaParts, rootField);
    }
}

export default expressionToFormulaTree;
