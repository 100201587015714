import React, { useEffect, useState } from 'react';

import useSearchBackgroundProcesses from './hooks/useSearchBackgroundProcesses';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { SimpleSelect, useDebouncedState } from '@tonkean/infrastructure';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';

interface Props {
    groupId: string;
    workflowVersionType: WorkflowVersionType;
    preselectedItem?: string;
    onItemSelected: (selectedItemId: string) => void;
}

const BackgroundProcessSelector: React.FC<Props> = ({
    groupId,
    workflowVersionType,
    preselectedItem,
    onItemSelected,
}) => {
    const [selectedItem, setSelectedItem] = useState<string | undefined>(preselectedItem);
    const [filteredOptions, setFilteredOptions] = useState<{ value: string; label: string }[]>([]);

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);

    const searchBackgroundProcessResponse = useSearchBackgroundProcesses(
        groupId,
        workflowVersionType,
        debouncedSearchTerm,
        30,
    );

    const [preselectedResponse, getPreselectedBackgroundProcess] = useLazyTonkeanService('getBackgroundProcessById');

    useEffect(() => {
        if (
            preselectedItem &&
            !searchBackgroundProcessResponse.backgroundProcesses.some(
                (backgroundProcess) => backgroundProcess.id === preselectedItem,
            )
        ) {
            getPreselectedBackgroundProcess(preselectedItem);
        }
    }, [getPreselectedBackgroundProcess, preselectedItem, searchBackgroundProcessResponse.backgroundProcesses]);

    /**
     * Once we have fetched background processes, we set the filtered options in the API SimpleSelect expects to receive them.
     */
    useEffect(() => {
        let backgroundProcessOptions: { value: string; label: string }[] = [];

        if (
            searchBackgroundProcessResponse.backgroundProcesses?.length &&
            !searchBackgroundProcessResponse.loading &&
            !searchBackgroundProcessResponse.error
        ) {
            backgroundProcessOptions = [
                ...backgroundProcessOptions,
                ...searchBackgroundProcessResponse.backgroundProcesses.map((entity) => ({
                    value: entity.id,
                    label: entity.displayName,
                })),
            ];
        }

        if (
            preselectedResponse.data &&
            !searchBackgroundProcessResponse.backgroundProcesses.some(
                (backgroundProcess) => backgroundProcess.id === preselectedItem,
            )
        ) {
            backgroundProcessOptions.push({
                value: preselectedResponse.data.id,
                label: preselectedResponse.data.displayName,
            });
        }

        setFilteredOptions(backgroundProcessOptions);
    }, [
        preselectedResponse.data,
        searchBackgroundProcessResponse.backgroundProcesses,
        searchBackgroundProcessResponse.loading,
        searchBackgroundProcessResponse.error,
        preselectedItem,
    ]);

    const loading = searchBackgroundProcessResponse.loading || preselectedResponse.loading;

    return (
        <SimpleSelect
            placeholder="Select..."
            value={selectedItem}
            isLoading={loading}
            inputValue={notDebouncedSearchTerm}
            onInputChange={setNotDebouncedSearchTerm}
            options={filteredOptions}
            onChange={(selectedValue: string) => {
                setSelectedItem(selectedValue);
                onItemSelected(selectedValue);
            }}
            thin
            isClearable
        />
    );
};

export default BackgroundProcessSelector;
