import * as Yup from 'yup';
import type { RequiredObjectSchema, TypeOfShape } from 'yup/lib/object';

import type { AccessControlPageDefinition } from '@tonkean/tonkean-entities';
import { AccessControlType, IpRestrictionType } from '@tonkean/tonkean-entities';
import { yupEnum, yupExact } from '@tonkean/utils';

function getSchema(
    accessControlPageDefinition: AccessControlPageDefinition,
    schema: RequiredObjectSchema<{}, Record<string, any>, TypeOfShape<{}>>,
) {
    const IpV4Validation = /(^(\d{1,3}\.){3}(\d{1,3})$)/;
    const encryptionKeyValidation = /^[\w.!@?#$%&:"';()*+,/\-=[\\\]^{|}<>~` ]+$/;
    const accessControlDefinition = accessControlPageDefinition.accessControl;

    switch (accessControlDefinition.accessControlType) {
        case AccessControlType.EMAIL_INBOX: {
            return schema.concat(
                Yup.object({}).shape({
                    accessControl: Yup.object({
                        accessControlType: yupExact(AccessControlType.EMAIL_INBOX).required(),
                        fromEmailAddresses: Yup.object({
                            isEnabled: Yup.boolean().required(),
                            emailAddresses: Yup.array()
                                .of(
                                    Yup.string()
                                        .when(() =>
                                            accessControlDefinition.fromEmailAddresses?.isEnabled
                                                ? Yup.string().email((e) => `${e.value} Is not a valid mail address`)
                                                : Yup.string(),
                                        )
                                        .required(),
                                )
                                .when('isEnabled', (isEnabled, _schema) =>
                                    isEnabled ? _schema.min(1, 'Add at least 1 mail address') : _schema,
                                )
                                .required(),
                        }).required(),
                        fromEmailDomains: Yup.object({
                            isEnabled: Yup.boolean().required(),
                            emailDomains: Yup.array()
                                .of(
                                    Yup.string().when((_, _schema) =>
                                        accessControlDefinition.fromEmailDomains.isEnabled
                                            ? _schema.required('Must include al least 1 domain')
                                            : _schema,
                                    ),
                                )
                                .when('isEnabled', (isEnabled, _schema) =>
                                    isEnabled ? _schema.min(1, 'Add at least 1 domain address') : _schema,
                                )
                                .required(),
                        }).required(),
                    }).required(),
                    encryptionKey: Yup.string()
                        .length(32, 'Encryption key must be exactly 32 characters')
                        .matches(encryptionKeyValidation, {
                            message: 'Invalid encryption key',
                            excludeEmptyString: true,
                        })
                        .optional(),
                }),
            );
        }
        case AccessControlType.WEBHOOK: {
            return schema.concat(
                Yup.object({}).shape({
                    accessControl: Yup.object({
                        accessControlType: yupExact(AccessControlType.WEBHOOK).required(),
                        tokenRestriction: Yup.object({
                            headerName: Yup.string().when('isEnabled', {
                                is: true,
                                then: Yup.string().required("Token header name can't be empty"),
                                otherwise: Yup.string(),
                            }),
                            token: Yup.string().when('isEnabled', {
                                is: true,
                                then: Yup.string().required("Token restriction can't be empty"),
                                otherwise: Yup.string(),
                            }),
                            isEnabled: Yup.boolean().required(),
                        }).required(),

                        ipRestrictionGroups: Yup.object({
                            isEnabled: Yup.boolean().required(),
                            ipRestrictions: Yup.array()
                                .of(
                                    Yup.object({
                                        displayName: Yup.string().when(() =>
                                            accessControlDefinition.ipRestrictionGroups.isEnabled
                                                ? Yup.string().required("Method name can't be empty")
                                                : Yup.string(),
                                        ),
                                        ipRangeValues: Yup.array()
                                            .of(
                                                Yup.object({
                                                    fromIp: Yup.string().when((_, _schema) =>
                                                        accessControlDefinition.ipRestrictionGroups.isEnabled
                                                            ? _schema
                                                                  .matches(IpV4Validation, {
                                                                      message: 'Invalid IP address',
                                                                      excludeEmptyString: true,
                                                                  })
                                                                  .required("From IP can't be empty")
                                                            : _schema,
                                                    ),
                                                    toIp: Yup.string().when(
                                                        'ipRestrictionType',
                                                        (ipRestrictionType, _schema) => {
                                                            return accessControlDefinition.ipRestrictionGroups
                                                                .isEnabled &&
                                                                ipRestrictionType === IpRestrictionType.IP_RANGE
                                                                ? _schema
                                                                      .matches(IpV4Validation, {
                                                                          message: 'Invalid IP address',
                                                                          excludeEmptyString: true,
                                                                      })
                                                                      .required("To IP can't be empty")
                                                                : _schema;
                                                        },
                                                    ),
                                                    ipRestrictionType: yupEnum(IpRestrictionType).required(),
                                                }).required(),
                                            )
                                            .required(),
                                    }).required(),
                                )
                                .required(),
                        }).required(),
                    }).required(),
                    encryptionKey: Yup.string()
                        .length(32, 'Encryption key must be exactly 32 characters')
                        .matches(encryptionKeyValidation, {
                            message: 'Invalid encryption key',
                            excludeEmptyString: true,
                        })
                        .optional(),
                }),
            );
        }
        case AccessControlType.NATIVE_INTEGRATION:
            return schema.concat(
                Yup.object({}).shape({
                    accessControl: Yup.object({
                        accessControlType: yupExact(AccessControlType.NATIVE_INTEGRATION).required(),
                    }).required(),
                    encryptionKey: Yup.string()
                        .length(32, 'Encryption key must be exactly 32 characters')
                        .matches(encryptionKeyValidation, {
                            message: 'Invalid encryption key',
                            excludeEmptyString: true,
                        })
                        .optional(),
                }),
            );
    }
}

const ValidateControlAccess = Yup.object({}).required().when(getSchema) as ReturnType<typeof getSchema>;

export default ValidateControlAccess;
