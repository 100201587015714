import innerItemLogicBlockConfigs from '../innerItemLogicBlockConfigs';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addCreateItemInAnotherModuleOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addCreateItemInAnotherModuleOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const MonitorTracksLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.MONITOR_TRACKS,
    title: 'Match Conditions',
    iconClass: 'mod-monitor-tracks',
    secondaryIconClass: 'icon-match-condition',
    description: 'Created items will be monitored and acted upon by configuration.',
    secondaryType: 'MONITOR_TRACKS_MATCH_CONDITIONS',
    longDescription:
        'This trigger will run when a new inner item enters the flow, as long as it matches the condition below. Note: this will run on existing items as well.',
    showWhenDo: true,
    createAfterOptions: (features, workingOnContext, parentType) => {
        if (
            !features?.tonkean_allow_inner_items_old_flow &&
            workingOnContext === 'TRIGGERED_ACTION_ITEM' &&
            parentType &&
            innerItemLogicBlockConfigs.includes(parentType)
        ) {
            return [addCreateItemInAnotherModuleOption];
        } else {
            return [];
        }
    },
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    cantMove: true,
    cantDrag: true,
};

export default MonitorTracksLogicBlockConfig;
