<div class="create-inline-formula-modal" ng-if="!data.loading">
    <!-- Close button -->
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Title -->
    <h3 class="common-title-sm margin-top-none margin-bottom-lg">Create formula</h3>

    <!-- Content -->
    <div>
        <tnk-column-formula-field
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            target-type="'COLUMN'"
            create-mode="data.createMode"
            duplicate-mode="false"
            additional-tabs="data.additionalTabs"
            override-formula-operator="data.overrideFormulaOperator"
            existing-field-definition="data.existingFieldDefinitionMock"
            aggregation-on-column-feature-name="WORKFLOW_FORMULAS"
            column-formula-feature-name="WORKFLOW_FORMULAS"
            hide-inner-track-aggregation-formula="true"
            formula-expression-tree="data.formulaTree"
            custom-trigger="data.customTrigger"
            project-integration="data.projectIntegration"
            on-definition-change="
                onDefinitionChange(newDefinition, expressionNode, doNotReloadPreview, actualFieldDefinitionType)
            "
        ></tnk-column-formula-field>
    </div>

    <!-- Buttons -->
    <div class="text-right margin-top">
        <button type="button" class="btn btn-default margin-right-xs" ng-click="$dismiss()">Cancel</button>
        <button
            type="button"
            class="btn btn-primary"
            ng-click="onDoneClicked()"
            ng-disabled="data.updatedDefinition && (data.updatedDefinition.errorValidatingDefinition || !data.updatedDefinition.validDefinition)"
        >
            Done
        </button>
    </div>
</div>
