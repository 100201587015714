import React from 'react';

import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import WidgetConfigurationFieldSelector from '../../CommonWidgetConfiguration/WidgetConfigurationFieldSelector';
import useGetWorkflowVersionFieldsAndBasicFields from '../../hooks/useGetWorkflowVersionFieldsAndBasicFields';
import useWidgetConfigurationDeletedFields from '../../hooks/useWidgetConfigurationDeletedFields';
import type FieldsItemWidgetConfiguration from '../FieldsItemWidgetConfiguration';

import { LoadingCircle } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';

const ItemInterfaceFieldsWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<FieldsItemWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(workflowVersion.id, [
        'ITEM_INTERFACE_FIELDS_WIDGET',
    ]);

    const deletedFieldsIds = useWidgetConfigurationDeletedFields(
        allFieldDefinitions,
        Object.keys(configuration.fields || {}),
    );

    const { initiative } = useItemInterfaceContext();

    if (loading) {
        return <LoadingCircle large centered />;
    }

    return (
        <>
            <WidgetConfigurationFieldSelector
                allFieldDefinitions={allFieldDefinitions}
                groups={undefined}
                deletedFieldsIds={deletedFieldsIds}
                workflowVersion={workflowVersion}
                exampleItem={initiative}
                fixedOrderForSpecialFields={false}
            />
        </>
    );
};

export default ItemInterfaceFieldsWidgetConfigurationEditor;
