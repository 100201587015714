<div
    ng-show="!hideItem && !item.deleted && types[item.type] && (!item.actor || !types[item.type].hideIfMe || (item.actor.id !== as.currentUser.id))"
    class="activity-item mod-{{ item.type }} show-edit-when-hover-visibility padding-top-none padding-bottom-none {{
        customCss
    }}"
    ng-class="{
        'mod-new': isNew,
        'mod-notification': notification,
        'mod-mini': miniMode,
        'mod-clickable': notification && types[item.type].handleClick,
        'mod-light':
            !miniMode &&
            !notification &&
            (types[item.type].lightMode || (types[item.type].isLightMode && types[item.type].isLightMode(item)))
    }"
>
    <!--<div class="activity-item-line"></div>-->
    <div class="flex padding-top-md" ng-class="{ 'flex-vmiddle padding-top-none': !miniMode && !notification }">
        <div class="flex flex-col margin-right-xs">
            <div>
                <tnk-avatar ng-if="!item.metadata.updatedViaBot" user="item.actor"></tnk-avatar>
                <div
                    ng-if="!types[item.type].customIconTemplate"
                    class="activity-comment-avatar avatar-main img-circle"
                >   
                <img ng-if="item.metadata.updatedViaBot" src="/images/icons/tonkean-logo.svg"></img>
                    
                    <!--<img class="avatar-img img-circle" ng-src="{{item.actor && item.actor.avatarUri ? item.actor.avatarUri : 'images/avatar.png'}}">-->
                </div>
                <div ng-if="types[item.type].customIconTemplate">
                    <span
                        ng-if="item.type === 'BOT_GATHER'"
                        ng-include="'./botGather/botGatherIcon.template.html'"
                    ></span>
                    <span
                        ng-if="item.type === 'BOT_WORKER_RUN'"
                        ng-include="'./botGather/botGatherIcon.template.html'"
                    ></span>
                    <span
                        ng-if="item.type === 'EMPTY'"
                        ng-include="'./empty/emptyItemIconTemplate.template.html'"
                    ></span>
                </div>
            </div>
            <div class="flex-grow activity-item-left-line" ng-if="showLeftLine"></div>
        </div>
        <div class="flex-grow">
            <div class="activity-item-header margin-bottom-xxs" ng-click="handleClick(item)">
                <i ng-show="types[item.type].icon" class="activity-item-icon fa {{ types[item.type].icon }}"></i>

                <div class="activity-item-title mod-no-wrap">
                    <div
                        ng-if="item.type === 'question'"
                        ng-include="'./question/questionItemTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'PROBLEM_STATUS_CHANGED'"
                        ng-include="'./problemStatusChanged/problemStatusChangedTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_PROBLEM'"
                        ng-include="'./problem/problemItemTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_POSITIVE_PROBLEM'"
                        ng-include="'./problem/positiveProblemItemTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INSIGHT_PLUS_ONE'"
                        ng-include="'./answer/plusOneTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INSIGHT_INDICATION'"
                        ng-include="'./answer/insightIndicationTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'PROBLEM_INDICATION'"
                        ng-include="'./answer/problemIndicationTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_INSIGHT'"
                        ng-include="'./answer/answerItemTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_COMMENT'"
                        ng-include="'./comment/commentTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_ACTIVITY_COMMENT'"
                        ng-include="'./comment/commentTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_CREATED'"
                        ng-include="'./initiativeCreated/initiativeCreated.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED'"
                        ng-include="'./personStatusUpdate/personStatusUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_FUNCTION_ADDED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_FUNCTION_REMOVED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_DESCRIPTION_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_LEADER_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_TAGS_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'NEW_INITIATIVE'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_TITLE_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_DUE_DATE_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_STATUS_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_FUNCTION_OWNER_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdateTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_MANUAL_FIELD_VALUE_CHANGED'"
                        ng-include="'./fieldChanged/fieldChangedTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'INITIATIVE_FIELD_RANGE_CHANGED'"
                        ng-include="'./fieldChanged/fieldRangeChangedTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'BOT_GATHER'"
                        ng-include="'./botGather/botGather.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'BOT_WORKER_RUN'"
                        ng-include="'./botGather/botWorkerRun.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'COMMIT'"
                        ng-include="'./externalActivity/externalActivityTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'ISSUE'"
                        ng-include="'./externalActivity/externalItemTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div
                        ng-if="item.type === 'EMPTY'"
                        ng-include="'./empty/emptyTitle.template.html'"
                        onload="titleLoaded = true"
                        class="activity-item-title-text"
                    ></div>

                    <div ng-if="types[item.type].title" class="activity-item-title-text">
                        {{ types[item.type].title }}
                    </div>

                    <div
                        ng-show="item.created"
                        uib-tooltip="{{ item.created | date: 'MMM d, y h:mm a' }}"
                        tooltip-placement="{{ notification ? 'bottom-left' : 'left' }}"
                        class="activity-item-time flex-no-shrink clearfix margin-left-xs margin-top-xs"
                    >
                        {{ item.created | timeAgo }}
                        <span ng-if="item.metadata.updatedViaBot" class="common-size-xxxxxs">
                            <span class="tag-sample mod-spaced mod-small-font common-bold">VIA MODULE</span>
                        </span>
                    </div>
                </div>
            </div>
            <div
                ng-show="!types[item.type].shouldShowContent || types[item.type].shouldShowContent(item)"
                class="activity-item-content"
                onload="contentLoaded = true"
            >
                <div class="activity-item-content-item show-edit-when-hover relative">
                    <div ng-if="item.type === 'question'" ng-include="'./question/questionItem.template.html'"></div>
                    <div
                        ng-if="item.type === 'PROBLEM_STATUS_CHANGED'"
                        ng-include="'./problemStatusChanged/problemStatusChanged.template.html'"
                    ></div>
                    <div ng-if="item.type === 'NEW_PROBLEM'" ng-include="'./problem/problemItem.template.html'"></div>
                    <div
                        ng-if="item.type === 'NEW_POSITIVE_PROBLEM'"
                        ng-include="'./problem/problemItem.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INSIGHT_PLUS_ONE'"
                        ng-include="'./answer/answerItem.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INSIGHT_INDICATION'"
                        ng-include="'./answer/answerItem.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'PROBLEM_INDICATION'"
                        ng-include="'./answer/problemIndication.template.html'"
                    ></div>
                    <div ng-if="item.type === 'NEW_INSIGHT'" ng-include="'./answer/answerItem.template.html'"></div>
                    <div ng-if="item.type === 'NEW_COMMENT'" ng-include="'./comment/comment.template.html'"></div>
                    <div
                        ng-if="item.type === 'NEW_ACTIVITY_COMMENT'"
                        ng-include="'./comment/comment.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED'"
                        ng-include="'./personStatusUpdate/personStatusUpdate.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INITIATIVE_LEADER_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdate.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INITIATIVE_DUE_DATE_CHANGED'"
                        ng-include="'./initiativeUpdate/initiativeUpdate.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INITIATIVE_MANUAL_FIELD_VALUE_CHANGED'"
                        ng-include="'./fieldChanged/fieldChanged.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'INITIATIVE_FIELD_RANGE_CHANGED'"
                        ng-include="'./fieldChanged/fieldChanged.template.html'"
                    ></div>
                    <div
                        ng-if="item.type === 'ISSUE'"
                        ng-include="'./externalActivity/externalItem.template.html'"
                    ></div>
                    <div ng-if="item.type === 'EMPTY'" ng-include="'./empty/empty.template.html'"></div>

                    <a
                        class="common-link-no-style common-size-xxxxs"
                        ng-if="types[item.type].allowComments && !notification"
                        ng-click="data.showCommentBar = !data.showCommentBar"
                    >
                        <span class="common-color-light-grey common-bold">
                            <i class="svg-icon-xs">
                                <tnk-icon src="../../../../../apps/tracks/images/icons/comment.svg"></tnk-icon>
                            </i>
                            Comment
                        </span>
                    </a>
                </div>
                <div
                    ng-if="
                        types[item.type].allowComments &&
                        !notification &&
                        ((item.comments && item.comments.length) || data.showCommentBar)
                    "
                    class="activity-item-comments activity-item-content-item"
                >
                    <div class="activity-item-comments-list">
                        <div ng-if="item.comments.length > data.commentsLimit" class="common-size-xxxxs">
                            <a ng-click="data.commentsLimit = item.comments.length">
                                <i class="fa fa-comments-o margin-right"></i>
                                Show all {{ item.comments.length }} comments
                            </a>
                            <hr class="margin-normal-sm-v" />
                        </div>
                        <div
                            class="activity-item-inner-comment show-edit-when-hover relative"
                            ng-repeat="comment in filteredComments = (item.comments | orderBy:'!created' | limitTo:data.commentsLimit) | orderBy:'created'"
                            ng-if="!comment.deleted"
                        >
                            <div class="flex">
                                <!--<div class="relative flex-no-shrink margin-right">-->
                                <!--<tnk-avatar user="comment.commenter"></tnk-avatar>-->
                                <!--</div>-->
                                <div class="flex-grow">
                                    <div>
                                        <strong class="common-size-xxxxs">{{ comment.commenter.name }}</strong>
                                    </div>
                                    <div light-markdown="comment.text" class="common-break-long"></div>
                                    <div class="common-size-xxxxs common-color-grey">
                                        {{ comment.created | timeAgo }}
                                        <span ng-if="comment.commenter.id === as.currentUser.id">
                                            <!--, <a
                                                ng-click="deleteItem(comment.id, false, comment)">delete</a>-->
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button
                                class="btn btn-link activity-comment-delete btn-on-hover"
                                ng-if="comment.commenter.id === as.currentUser.id"
                                ng-disabled="deleting"
                                ng-click="deleteItem(comment.id, false, comment)"
                            >
                                <i ng-hide="deleting" class="fa fa-trash-o"></i>
                                <i ng-show="deleting" class="loading"></i>
                            </button>
                            <hr class="margin-normal-sm-v" ng-if="!$last" />
                        </div>
                    </div>
                    <div class="activity-item-comments-new clearfix" ng-if="data.showCommentBar">
                        <hr class="margin-normal-sm-v" ng-if="item.comments && item.comments.length" />
                        <tnk-comment-textbox
                            placeholder="Write a comment"
                            entity-id="{{ item.id }}"
                            required
                            rows="3"
                            on-post="addCommentToItem(comment)"
                            maxlength="1000"
                        ></tnk-comment-textbox>
                    </div>
                </div>
            </div>
            <br ng-if="showLeftLine" />
        </div>
    </div>
</div>
