import template from './customFiltersInnerQuery.template.html?angularjs';

/**
 * Component to display the templates gallery
 */
angular.module('tonkean.app').component('tnkCustomFiltersInnerQuery', {
    bindings: {
        query: '<',
        forceExpand: '<',
        errorObject: '<',
        groupNameBeingEditId: '<',
        onGroupNameEdit: '<',
        invokeOnFilterChange: '<',
        hideQueryOnSingleCondition: '<',
        queryTypes: '<',
        loadingFields: '<',
        filterInFocusId: '<',
        onFilterFocus: '<',
        filtersResultMap: '<',
        fieldDefinitionMap: '<',
        projectIntegrationDescriptionClass: '<',
        itemsSource: '<',
        smallMod: '<',
        groupId: '<',
        targetWorkflowVersionId: '<',
        workflowVersionId: '<',
        considerAllGroups: '<',
        projectIntegration: '<',
        displayIntegrationIndication: '<',
        selectedEntityType: '<',
        inspectEntityType: '<',
        onFieldOptionSelected: '<',
        addCustomFields: '<',
        reloadFields: '<',
        showFieldInspect: '<',
        showFieldInspectModal: '<',
        viewOnly: '<',
        appendToBody: '<',
        specialFieldsForFeatures: '<',
        reloadFieldsOnNewFieldDefinitionCreation: '<',
        onConditionSelection: '<',
        onValueChanged: '<',
        onSecondValueChanged: '<',
        onExpressionChanged: '<',
        useExpressionsForValue: '<',
        tabsFieldSelectorModNarrow: '<',
        tabsFieldSelectorModFixedWidth: '<',
        modFixedWidth: '<',
        verticalMod: '<',
        previewEvaluationSource: '<',
        allowedConditionsSet: '<',
        notAllowedConditionsSet: '<',
        excludedTabSelectorSpecialFields: '<',
        includeTabSelectorSpecialFieldsForFeatures: '<',
        uniqueIdentifier: '<',
        evaluatePreview: '<',
        selectedLogicTypeConfiguration: '<',
        removeFieldFilter: '<',
        addNewAggregateCondition: '<',
        addNewAggregateQuery: '<',
        logicId: '<',
        queryIndex: '<',
        onDeleteInnerQuery: '<',
        allowContains: '<', // allow using contains operator
        showPeopleDirectories: '<',
        includeViewerFields: '<',
    },
    template,
    controller: 'CustomerFiltersInnerQuery',
});
