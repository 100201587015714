import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addEndSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addEndSequenceBlockCreateAfterOption';
import addShowFormBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowFormBlockCreateAfterOption';
import addShowInterfaceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowInterfaceBlockCreateAfterOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousCreatedFromFormLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_CREATED_FROM_FORM,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-form-filled',
    title: 'Form Filled',
    description: 'When Form Filled',
    longDescription:
        'This trigger will run when a new item created by a Tonkean form, as long as it matches the condition below.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: true,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [
        addShowFormBlockCreateAfterOption,
        addShowInterfaceBlockCreateAfterOption,
        addEndSequenceBlockCreateAfterOption,
    ],
    createAfterOptionsTitle: 'Sequence Actions',
    definition: {},
    validator: ({ customTrigger, group, formInfoRetriever }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!customTrigger?.monitorForms.length) {
            validationObject.formNotSelectedError = 'At least one form need to be selected.';
        } else if (
            customTrigger.monitorForms
                .map((form) => formInfoRetriever.getWorkerFormFromCache(group.draftWorkflowVersionId, form.id))
                .some((form) => form?.formQuestionType === 'UPLOAD_FILES')
        ) {
            validationObject.formNotSelectedError = "Can't monitor an upload files form.";
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutonomousCreatedFromFormLogicBlockConfig;
