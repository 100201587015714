/**
 * A method decorator that binds the function to the class, so when you pass a referance of it, it will keep "this"
 * pointing to the class instance.
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function bindThis<T extends Function>(
    _target: object,
    _propertyKey: string,
    descriptor: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> | void {
    return {
        get(this: T): T {
            const value = descriptor.get?.apply(this) || descriptor.value;
            return value?.bind(this);
        },
    };
}

export default bindThis;
