import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery } from './getItemDetailsForItemDetailsModuleEditor.graphql';
import HexagonWrappedItemDetailsModuleEditorIcon from './HexagonWrappedItemDetailsModuleEditorIcon';
import ItemDetailsEntityIndicator from './ItemDetailsEntityIndicator';
import { ReactComponent as DataSourceIcon } from '../../../../../images/icons/data-source.svg';
import wrapperIconUrl from '../../../../../images/icons/module-editor-item-details-icon-wrapper.svg?url';
import { ReactComponent as MatchedEntitiesIcon } from '../../../../../images/icons/module-editor-item-details-matched-entities.svg';
import { ReactComponent as ViewItemsIcon } from '../../../../../images/icons/module-editor-item-details-view-items.svg';
import {
    INNER_ITEM_DETAILS_DESCRIPTION_DEFAULT,
    INNER_ITEM_DETAILS_NAME_DEFAULT,
    ITEM_DETAILS_DESCRIPTION_DEFAULT,
    ITEM_DETAILS_NAME_DEFAULT,
} from '../../itemDetailsSharedConsts';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { H3, Paragraph, TextEllipsis } from '@tonkean/infrastructure';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import type { TonkeanId } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const ICON_WRAPPER_WIDTH = 92;
const LINE_WIDTH = 2;
const WRAPPER_MARGIN_BOTTOM = 40;
const Wrapper = styled(Clickable)`
    position: relative;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-shrink: 0;
    gap: 20px;
    margin-bottom: ${WRAPPER_MARGIN_BOTTOM}px;

    ::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        bottom: -${WRAPPER_MARGIN_BOTTOM}px;
        width: 2px;
        left: ${(ICON_WRAPPER_WIDTH - LINE_WIDTH) / 2}px;
        background: #d8d8d8;
        // This comes outside of the wrapper, so we don't want this element to be clickable
        pointer-events: none;
    }
`;

const StyledItemDetailsEntityIndicator = styled(ItemDetailsEntityIndicator)`
    // Place the entity indicator right beneath the logo but dont take up space
    // because the margins need to look the same whether its there or not
    position: absolute;
    top: 100%;
    z-index: 2;
    margin-top: 8px;
`;

const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`;
const Title = styled(H3)``;
const Description = styled(Paragraph)`
    margin-top: 8px;
    color: ${Theme.colors.gray_700};
`;
const StyledTextEllipsis = styled(TextEllipsis)<{ target_is_group: boolean }>`
    ${({ target_is_group }) =>
        !target_is_group
            ? css`
                  width: 300px;
              `
            : ``}
`;
const Links = styled.div`
    margin-top: 11px;
    display: flex;
    gap: 8px;
    flex-shrink: 0;
`;
const Link = styled(Clickable)`
    height: 30px;
    padding: 0 16px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
`;
const LinkIcon = styled.svg`
    ${colorSvg('#838A92')};
`;
const LinkText = styled.div`
    font-weight: 700;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
`;
const IconWrapper = styled.div<{ size: number }>`
    ${({ size }) => css`
        height: ${size}px;
        width: ${size}px;
    `}
    padding: 18px;

    background-image: url(${wrapperIconUrl});
    background-size: 100% 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    onOpenTabClick(tabName: string, additionalParams: any): void;
    onViewItemsClicked(): void;
    hasValidationError?: boolean;
    overrideDetailsTitlePlaceholder?: string;
    overrideDetailsDescriptionPlaceholder?: string;
}

const ItemDetailsModuleEditor: React.FC<Props> = ({
    workflowVersionId,
    targetId,
    onOpenTabClick,
    onViewItemsClicked,
    hasValidationError,
    overrideDetailsTitlePlaceholder,
    overrideDetailsDescriptionPlaceholder,
}) => {
    const [{ data, fetching, error }] = useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery({
        variables: {
            targetId,
            workflowVersionId,
        },
        suspend: false,
    });

    const targetIsGroup = useMemo(() => {
        return targetId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.GROUP]);
    }, [targetId]);

    const { name, description } = useMemo(() => {
        const defaultItemDetails = targetIsGroup
            ? {
                  name: overrideDetailsTitlePlaceholder ?? ITEM_DETAILS_NAME_DEFAULT,
                  description: overrideDetailsDescriptionPlaceholder ?? ITEM_DETAILS_DESCRIPTION_DEFAULT,
              }
            : {
                  name: overrideDetailsTitlePlaceholder ?? INNER_ITEM_DETAILS_NAME_DEFAULT,
                  description: overrideDetailsDescriptionPlaceholder ?? INNER_ITEM_DETAILS_DESCRIPTION_DEFAULT,
              };

        const itemDetails = data?.itemDetailsByTargetIdAndWorkflowVersionId;

        if (!!itemDetails) {
            return {
                name: !!itemDetails.name && itemDetails.name.trim() !== '' ? itemDetails.name : defaultItemDetails.name,
                description:
                    !!itemDetails.description && itemDetails.description.trim() !== ''
                        ? itemDetails.description
                        : defaultItemDetails.description,
            };
        }

        return defaultItemDetails;
    }, [
        data?.itemDetailsByTargetIdAndWorkflowVersionId,
        overrideDetailsDescriptionPlaceholder,
        overrideDetailsTitlePlaceholder,
        targetIsGroup,
    ]);

    const [targetIdQuery] = useGetStateParams<[TonkeanId]>('targetId');
    const selected = useMemo(() => {
        return targetIdQuery === targetId;
    }, [targetId, targetIdQuery]);

    if (fetching || error) {
        return (
            <Wrapper>
                <IconWrapper size={ICON_WRAPPER_WIDTH} />
            </Wrapper>
        );
    } else {
        return (
            <Wrapper onClick={() => onOpenTabClick('item-details', { targetId })} buttonAsDiv>
                <HexagonWrappedItemDetailsModuleEditorIcon
                    workflowVersionId={workflowVersionId}
                    targetId={targetId}
                    iconWrapperSize={ICON_WRAPPER_WIDTH}
                    hasValidationError={hasValidationError}
                    selected={selected}
                />
                <RightWrapper>
                    <StyledTextEllipsis
                        target_is_group={targetIsGroup}
                        numberOfLines={1}
                        tooltipContentOverride={name}
                        tooltip
                    >
                        <Title data-automation={`item-details-module-editor-title-${name}`}>{name}</Title>
                    </StyledTextEllipsis>
                    <StyledTextEllipsis
                        target_is_group={targetIsGroup}
                        numberOfLines={3}
                        tooltipContentOverride={description}
                        tooltip
                    >
                        <Description>{description}</Description>
                    </StyledTextEllipsis>
                    {targetIsGroup && (
                        <Links>
                            <Link
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onViewItemsClicked();
                                }}
                            >
                                <LinkIcon as={ViewItemsIcon} />
                                <LinkText>View Items</LinkText>
                            </Link>
                            <Link
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onOpenTabClick('fields', null);
                                }}
                            >
                                <LinkIcon as={MatchedEntitiesIcon} />
                                <LinkText>Matched Entities</LinkText>
                            </Link>
                            <Link
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onOpenTabClick('item-details', null);
                                }}
                            >
                                <LinkIcon as={DataSourceIcon} />
                                <LinkText>Intake Source</LinkText>
                            </Link>
                        </Links>
                    )}
                </RightWrapper>
                {targetIsGroup && <StyledItemDetailsEntityIndicator workflowVersionId={workflowVersionId} />}
            </Wrapper>
        );
    }
};

export default ItemDetailsModuleEditor;
