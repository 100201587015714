import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onWorkflowFolderSelected: (workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>) => void;
}

const WorkflowFolderSimpleSelector: React.FC<Props> = ({ projectId, onWorkflowFolderSelected }) => {
    const [selectedWorkflowFolderId, setSelectedWorkflowFolderId] = useState<
        TonkeanId<TonkeanType.WORKFLOW_FOLDER> | undefined
    >();

    const workflowFolderManager = useAngularService('workflowFolderManager');
    const {
        data: workflowFolders,
        loading,
        error,
    } = useAsyncMethod(workflowFolderManager, 'getWorkflowFolders', projectId);

    const options: SimpleSelectSingleOption<TonkeanId<TonkeanType.WORKFLOW_FOLDER>>[] = useMemo(() => {
        if (!workflowFolders) {
            return [];
        }

        return (
            workflowFolders.map((folder) => ({
                value: folder.id,
                label: folder.displayName,
            })) || []
        );
    }, [workflowFolders]);

    const onChange = useCallback(
        (workflowFolderId) => {
            onWorkflowFolderSelected(workflowFolderId);
            setSelectedWorkflowFolderId(workflowFolderId);
        },
        [onWorkflowFolderSelected],
    );
    return (
        <SimpleSelect
            value={selectedWorkflowFolderId}
            options={options}
            onChange={onChange}
            isLoading={loading}
            isError={!!error}
            placeholder="Select Solution"
        />
    );
};

export default WorkflowFolderSimpleSelector;
