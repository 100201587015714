import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { GroupBase } from 'react-select';

import SimpleSelect from './SimpleSelect';
import type { SimpleSelectProps, SimpleSelectSingleOption } from './SimpleSelectTypes';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { useInnerField } from '../Field';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { toArray } from '@tonkean/utils';

type SimpleSelectOptionType<Option, LabelKey extends keyof Option, ValueKey extends keyof Option> = {
    label: Option[LabelKey];
    value: Option[ValueKey];
} & Omit<Option, LabelKey | ValueKey>;

type ExternalValueType<OptionType, IsMulti extends boolean> = IsMulti extends true ? OptionType[] : OptionType | null;

interface AutocompleteSpecificProps<
    Option,
    IsMulti extends boolean,
    LabelKey extends keyof Option,
    ValueKey extends keyof Option,
> {
    labelKey?: LabelKey;
    debouncedInterval?: number;
    valueKey?: ValueKey;
    value?: ExternalValueType<Option, IsMulti>;
    // This prop give you option to refresh the search result by his value
    refreshObject?: any;

    onDebouncedSearchGetResults(
        debouncedSearchTerm: string,
        ignoreValues: IsMulti extends true ? Option[ValueKey][] : Option[ValueKey] | undefined,
    ): Promise<Option[]>;

    onChange?(value: ExternalValueType<Option, IsMulti>): void;
}

export type AutocompleteSelectorProps<
    Option,
    IsMulti extends boolean,
    IsCreatable extends boolean,
    LabelKey extends keyof Option,
    ValueKey extends keyof Option,
    Group extends GroupBase<SimpleSelectSingleOption<Option[ValueKey]>>,
> = AutocompleteSpecificProps<Option, IsMulti, LabelKey, ValueKey> &
    Omit<
        Omit<SimpleSelectProps<SimpleSelectSingleOption<Option[ValueKey]>, IsMulti, IsCreatable, Group>, 'options'>,
        keyof AutocompleteSpecificProps<Option, IsMulti, LabelKey, ValueKey>
    >;

const AutocompleteSelector = <
    Option,
    LabelKey extends keyof Option,
    ValueKey extends keyof Option,
    IsMulti extends boolean = false,
    IsCreatable extends boolean = false,
    Group extends GroupBase<SimpleSelectSingleOption<Option[ValueKey]>> = GroupBase<
        SimpleSelectSingleOption<Option[ValueKey]>
    >,
>({
    onDebouncedSearchGetResults,
    debouncedInterval = 300,
    labelKey = 'label' as LabelKey,
    valueKey = 'value' as ValueKey,
    value: valueProp,
    onChange: onChangeProp,
    refreshObject,
    ...props
}: AutocompleteSelectorProps<Option, IsMulti, IsCreatable, LabelKey, ValueKey, Group>) => {
    const [options, setOptions] = useState<SimpleSelectOptionType<Option, LabelKey, ValueKey>[]>([]);
    const [loading, setLoading] = useState(true);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [fieldProps, , formikHelpers] = useInnerField({
        name: props.name,
        multiple: props.isMulti ?? false,
        type: 'select',
        value: valueProp,
        onBlur: props['onBlur'],
    });

    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState(
        debouncedSearchTerm,
        setDebouncedSearchTerm,
        debouncedInterval,
    );

    const simpleSelectValue: IsMulti extends true ? Option[ValueKey][] : Option[ValueKey] | undefined = useMemo(() => {
        return props.isMulti ? fieldProps.value?.map((item) => item[valueKey]) || [] : fieldProps.value?.[valueKey];
    }, [fieldProps.value, props.isMulti, valueKey]);

    const simpleSelectFilteredValue: IsMulti extends true ? Option[ValueKey][] : Option[ValueKey] | undefined =
        useMemo(() => {
            if (props.isMulti) {
                return fieldProps.value?.filter((item) => !item['__isNew__']).map((item) => item[valueKey]) || [];
            }
            if (fieldProps.value?.['__isNew__']) {
                return fieldProps.value[valueKey];
            }
        }, [fieldProps.value, props.isMulti, valueKey]);

    const onChange = useCallback(
        (
            _,
            selectedOptions: IsMulti extends true
                ? SimpleSelectOptionType<Option, LabelKey, ValueKey>[]
                : SimpleSelectOptionType<Option, LabelKey, ValueKey> | undefined,
        ) => {
            const convertSingleOption = ({
                value,
                label,
                ...rest
            }: SimpleSelectOptionType<Option, LabelKey, ValueKey>) => {
                return {
                    ...rest,
                    [valueKey]: value,
                    [labelKey]: label,
                } as any as Option;
            };

            const handleMultiSelect = (multiSelectedOptions: SimpleSelectOptionType<Option, LabelKey, ValueKey>[]) => {
                return multiSelectedOptions.map(convertSingleOption);
            };
            const handleSingleSelect = (selectedOption: SimpleSelectOptionType<Option, LabelKey, ValueKey> | null) => {
                return selectedOption ? convertSingleOption(selectedOption) : null;
            };

            const newValue = (
                props.isMulti ? handleMultiSelect(selectedOptions as any) : handleSingleSelect(selectedOptions as any)
            ) as ExternalValueType<Option, IsMulti>;
            onChangeProp?.(newValue);
            formikHelpers?.setValue(newValue);
        },
        [formikHelpers, labelKey, onChangeProp, props.isMulti, valueKey],
    );

    const addOptions = useCallback(
        (newOptions: Option[]) => {
            const newOptionsWithValueAndLabel = newOptions.map(({ [valueKey]: value, [labelKey]: label, ...rest }) => ({
                ...rest,
                value,
                label,
            }));

            const newOptionIds = new Set(newOptionsWithValueAndLabel.map((newOption) => newOption.value));

            setOptions((currentOptions) => [
                ...newOptionsWithValueAndLabel,
                ...currentOptions.filter((currentOption) => !newOptionIds.has(currentOption.value)),
            ]);
        },
        [labelKey, valueKey],
    );

    const onAutocompleteSearchConstantRef = useConstantRefCallback(onDebouncedSearchGetResults);

    useEffect(() => {
        setLoading(true);
        onAutocompleteSearchConstantRef(debouncedSearchTerm, simpleSelectFilteredValue).then((data) => {
            setLoading(false);
            addOptions(data);
        });
    }, [addOptions, debouncedSearchTerm, onAutocompleteSearchConstantRef, simpleSelectFilteredValue, refreshObject]);

    useEffect(() => {
        const valuesArray: Option[] = toArray(fieldProps.value || []);

        const filter = valuesArray
            // Filter out locally created items (when isCreatable is true)
            .filter((item) => !item['__isNew__']);

        addOptions(filter as any);
    }, [addOptions, fieldProps.value, labelKey, valueKey]);

    return (
        <SimpleSelect
            {...(props as any)}
            isLoading={loading || props['isLoading']}
            inputValue={notDebouncedSearchTerm}
            onInputChange={setNotDebouncedSearchTerm}
            options={options}
            value={simpleSelectValue}
            onChange={onChange}
            name={undefined}
            inputId={fieldProps.id}
            onBlur={fieldProps.onBlur}
        />
    );
};

export default AutocompleteSelector;

export type AutocompleteSelectorComponentType = <
    Option,
    LabelKey extends keyof Option,
    ValueKey extends keyof Option,
    IsMulti extends boolean = false,
    IsCreatable extends boolean = false,
    Group extends GroupBase<SimpleSelectSingleOption<Option[ValueKey]>> = GroupBase<
        SimpleSelectSingleOption<Option[ValueKey]>
    >,
>(
    props: AutocompleteSelectorProps<Option, IsMulti, IsCreatable, LabelKey, ValueKey, Group>,
) => React.ReactElement;
