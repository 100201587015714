import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import useGetLoadedFieldDefinitionById from '../../widgets/hooks/useGetLoadedFieldDefinitionById';

import { UpdateField } from '@tonkean/fields';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, FieldFilter, Group, Project, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

interface Props {
    fieldFilter: FieldFilter | undefined;
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersion: WorkflowVersion;
    fieldDefinition: FieldDefinition;
    onFilterChanged: (newVal: any) => void;
    onKeyDown: (event: React.KeyboardEvent) => void;
    onChangeFormattedLabel?: (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => void;
}

const WidgetItemsGeneralFilter: React.FC<Props> = ({
    fieldFilter,
    projectId,
    groupId,
    workflowVersion,
    fieldDefinition,
    onFilterChanged,
    onKeyDown,
    onChangeFormattedLabel,
}) => {
    const getLoadedFieldDefinitionById = useGetLoadedFieldDefinitionById(workflowVersion?.id);

    const onValueChanged = useCallback(
        (newVal) => {
            onFilterChanged(newVal);
            let fieldFilterValue = newVal;
            if (fieldDefinition.fieldType === FieldType.Date) {
                fieldFilterValue = dayjs(fieldFilterValue).format('MMM DD, YYYY');
            }
            onChangeFormattedLabel?.(fieldDefinition.id, fieldFilterValue);
        },
        [onFilterChanged, fieldDefinition.fieldType, fieldDefinition.id, onChangeFormattedLabel],
    );

    return (
        <UpdateField
            fieldDefinition={fieldDefinition}
            onValueSave={onValueChanged}
            projectId={projectId}
            groupId={groupId}
            initiative={undefined}
            workflowVersion={workflowVersion}
            viewOnly={false}
            fieldsAreBeingEdited={false}
            isSubmittingForm={false}
            overrideValue={fieldFilter?.value}
            onEnterDown={onKeyDown}
            getLoadedFieldDefinitionById={getLoadedFieldDefinitionById}
            showEmptyFieldWhenNoInitiative
            hideColorsAndTrends
            revealByDefault
            shouldOverrideValue
            isInPopover
            isFilterInput
        />
    );
};

export default WidgetItemsGeneralFilter;
