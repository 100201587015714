<div class="text-center" ng-if="loading"><i class="loading-large"></i></div>

<div ng-if="!loading">
    <div ng-if="data.preferenceOptionToIsCheckedMap['NO_ALL']" class="alert alert-danger">
        You have unsubscribed from all notifications and would not receive any notifications.
        <a ng-click="onOptionToggled(data.preferenceOptionConfigurations['NO_ALL'])">TURN ON</a>
    </div>

    <form name="formManageAccount" ng-hide="loading">
        <div class="form-group">
            <div
                class="checkbox"
                ng-repeat="preferenceOption in data.preferenceOptionConfigurationsArray | filter:{isHidden: '!true'}"
                ng-if="preferenceOption.id !== data.excludeOptionKey"
            >
                <div class="margin-left-md">
                    <tnk-checkbox
                        class="margin-right-xs"
                        label="preferenceOption.label"
                        value="data.preferenceOptionToIsCheckedMap[preferenceOption.id]"
                        on-toggle="onOptionToggled(preferenceOption)"
                    >
                        {{ op.label }}
                    </tnk-checkbox>
                    <div class="common-color-grey3 margin-top-xs margin-left-lg padding-left">{{ op.description }}</div>
                </div>
            </div>
        </div>
    </form>

    <div class="form-group" ng-if="pm.project">
        <label>Board specific notifications</label>
        <div class="margin-left">
            <a ng-click="modal.openActivityDigestEmailSettingsModal()">Activity Settings</a>
        </div>
    </div>
</div>
