import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const DelayLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.DELAY,
    title: 'Delay',
    iconClass: 'mod-delay',
    description: 'Set delay time before moving to the next block.',
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition.delayInMinutes) {
            validationObject.noDelayTime = 'Must fill a delay time';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default DelayLogicBlockConfig;
