import React from 'react';

import ContractFieldsMapping, { type ContractFieldsMappingProps } from './ContractFieldsMapping';
import ContractFieldsSelector from './ContractFieldsSelector';

import type { FieldDefinition } from '@tonkean/tonkean-entities';

const ContractFieldDefinitionsMapping: React.FC<
    Omit<ContractFieldsMappingProps<FieldDefinition>, 'selectorComponent'>
> = (props) => {
    return <ContractFieldsMapping selectorComponent={ContractFieldsSelector} {...props} />;
};

export default ContractFieldDefinitionsMapping;
