import type { Group, Person } from '@tonkean/tonkean-entities';
import type { AngularServices } from 'angulareact';

export class GroupPermissions {
    public static $inject: (string & keyof AngularServices)[] = ['authenticationService', 'utils'] as string[];

    currentUserIsOwnerMap = {};

    constructor(
        private authenticationService: AngularServices['authenticationService'],
        private utils: AngularServices['utils'],
    ) {}

    /**
     * Returns whether current user has permissions to edit worker configuration.
     */
    hasPermissionsToEditWorker(group?: Group) {
        if (!group) {
            return false;
        } else {
            return this.calculateOwnersForGroup(group);
        }
    }

    calculateOwnersForGroup(group: Group) {
        this.currentUserIsOwnerMap[group.id] = this.utils.anyMatch(
            group.owners,
            (owner: Person) => owner.id === this.authenticationService?.getCurrentUserSafe()?.id,
        );
        return this.currentUserIsOwnerMap[group.id];
    }

    isCurrentUserGroupCollaborator(group: Group) {
        const currentUserId = this.authenticationService.getCurrentUser().id;

        const isPublicAndEveryoneCanEdit =
            group.visibilityType === 'PUBLIC' && group.writePermissionType === 'EVERYONE';

        const isPrivateAndUserIsGroupMember =
            group.visibilityType === 'PRIVATE' &&
            this.utils.existsInArray(group.members, (member: Person) => member.id === currentUserId);

        const isPublicAndNotEveryOneCanEditAndUserHaveWritePermissions =
            group.visibilityType === 'PUBLIC' &&
            group.writePermissionType === 'SPECIFIC_PEOPLE' &&
            this.utils.existsInArray(group.writePermissionMembers, (member: Person) => member.id === currentUserId);

        return (
            isPublicAndEveryoneCanEdit ||
            isPrivateAndUserIsGroupMember ||
            isPublicAndNotEveryOneCanEditAndUserHaveWritePermissions
        );
    }
}

angular.module('tonkean.app').service('groupPermissions', GroupPermissions);
