<div class="enterprise">
    <!--header-->
    <div class="enterprise-main-header">
        <!-- Logo and board name -->
        <nav class="enterprise-main-nav flex">
            <span
                class="enterprise-main-nav-logo flex-no-shrink"
                ui-sref="product.board({filter:'all', g:null, st:null, })"
            >
                <div class="flex-vmiddle">
                    <div class="enterprise-main-nav-logo-img">
                        <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                    </div>
                </div>
            </span>
            <span
                class="padding-left common-color-black padding-top-md common-size-xxs common-bold pointer"
                ui-sref="product.board({filter:'all', g:null, st:null, })"
            >
                <i class="fa fa-chevron-left"></i>
                <span class="padding-left-xs">Back to Board</span>
            </span>
        </nav>
    </div>
    <div class="padding-normal flex-no-xs common-width-full">
        <div class="enterprise-left-bar">
            <div
                ng-repeat="barItem in data.barItems"
                class="enterprise-left-bar-item common-size-xxs"
                ng-click="selectBarItem(barItem)"
            >
                <span ng-class="{ 'common-color-black': data.selectedBarItem == barItem }">
                    {{ barItem.displayName }}
                </span>
            </div>
        </div>

        <div class="flex-grow enterprise-content">
            <!--Overview-->
            <div ng-if="data.selectedBarItem.name == 'overview'">
                <tnk-enterprise-overview></tnk-enterprise-overview>
            </div>

            <!--boards-->
            <div ng-if="data.selectedBarItem.name == 'boards'">
                <tnk-enterprise-manage-boards></tnk-enterprise-manage-boards>
            </div>

            <!--members-->
            <div ng-if="data.selectedBarItem.name == 'members'">
                <tnk-enterprise-manage-members></tnk-enterprise-manage-members>
            </div>

            <!--billing-->
            <div ng-if="data.selectedBarItem.name == 'billing'">
                <tnk-enterprise-billing></tnk-enterprise-billing>
            </div>
        </div>
    </div>
</div>
