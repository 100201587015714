<div class="tnk-solution-metrics">
    <!-- Metrics Grid -->
    <div ng-if="data.folders.length" class="solution-metrics-grids-wrapper">
        <div ng-repeat="folder in data.folders" class="solution-metrics-grid">
            <tnk-solution-metrics-grid
                folder-name="folder.displayName"
                filter-modules-term="data.moduleSearch"
                environment="data.selectedEnv"
                modules="folder.groups"
                collapsed="data.collapsedSolutions[folder.id]"
                on-toggle="(onSolutionToggle)"
                folder-id="folder.id"
            ></tnk-solution-metrics-grid>
        </div>
    </div>

    <div class="no-solutions-message" ng-if="!data.folders.length && !data.loadingSolutions">
        No Solutions Analytics to Show
        <div class="svg-icon-55">
            <tnk-icon class="margin-top-30" src="/images/icons/solutions-empty.svg"></tnk-icon>
        </div>
    </div>

    <div class="no-solutions-message" ng-if="data.loadingSolutions">
        Loading Solutions...
        <div class="svg-icon-55">
            <tnk-icon class="margin-top-30" src="/images/icons/solutions-empty.svg"></tnk-icon>
        </div>
    </div>
</div>
