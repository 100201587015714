import React, { useState } from 'react';
import type { FormEvent } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { Radio } from '@tonkean/infrastructure';
import { RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const SpringCMAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [accountType, setEnvironment] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');

    const authenticateWithClientIdAndSecret = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                accountType,
                clientId,
                clientSecret,
            };
            const environmentName = accountType === '' ? 'Production' : 'UAT';
            authenticate(config, {}, `${integrationConfiguration.displayName} (${environmentName})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithClientIdAndSecret}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-3 control-label">
                            Account Type
                        </label>
                        <div className="col-sm-8">
                            <RadioGroup
                                value={accountType}
                                direction="row"
                                onChange={setEnvironment}
                                size={InputSize.MEDIUM}
                            >
                                <Radio value="uat">UAT</Radio>
                                <Radio value="">Production</Radio>
                            </RadioGroup>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-3 control-label">
                            Client ID
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={clientId}
                                className="form-control"
                                id="api-client-id"
                                onChange={({ target }) => setClientId(target.value)}
                                autoComplete="off"
                                placeholder="Client ID"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Client Secret
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={clientSecret}
                                className="form-control"
                                onChange={({ target }) => setClientSecret(target.value)}
                                id="api-client-secret"
                                autoComplete="off"
                                placeholder="Client Secret"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SpringCMAuthenticationComponent;
