import dayjs from 'dayjs';
import { timezonesPreset } from '@tonkean/forumla-operators';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function RecurrenceTimeSelectionCtrl($scope, $q, consts, utils) {
    const ctrl = this;

    $scope.data = {
        existingRecurrenceTimeSelection: ctrl.existingRecurrenceTimeSelection,

        // Frequency
        frequenciesOptions: ['EveryXMinutes', 'EveryXHours', 'Daily', 'Weekly', 'Monthly', 'Quarterly'],
        frequencyOptionToDisplayNameMap: {
            EveryXMinutes: 'Every X Minutes',
            EveryXHours: 'Every X Hours',
            Daily: 'Daily',
            Weekly: 'Weekly',
            Monthly: 'Monthly',
            Quarterly: 'Quarterly',
        },
        selectedFrequency: 'Daily',
        // Every X minutes
        selectedEveryXMinutes: 30,
        // Every X hours
        selectedEveryXHours: 1,
        // Hour
        hoursInDay: [],
        selectedHourInDay: {},
        selectedMinuteOfHourOfDay: 0,
        // Minute
        minutesOfHourOfDay: [],
        // Week
        selectedDaysInWeek: getDaysInWeekSelectedMap({ Mon: true }),
        selectedMonthsInYear: getMonthsInYearSelectedMap({
            Jan: true,
            Feb: true,
            Mar: true,
            Apr: true,
            May: true,
            Jun: true,
            Jul: true,
            Aug: true,
            Sep: true,
            Oct: true,
            Nov: true,
            Dec: true,
        }),
        timezones: timezonesPreset,
        selectedTimezone: undefined,
        atLeastOneDaySelected: true,
        atLeastOneMonthSelected: true,
        // Month
        daysInMonth: [],
        selectedDayInMonth: 1,

        doNotRunOnWeekends: false,

        isXMinutesInvalid: false,
        isXHoursInvalid: false,
        hideTimezoneIfNotConfigured: ctrl.hideTimezoneIfNotConfigured,
        validationObject: ctrl.validationObject,
        minEveryXMinutesInterval: ctrl.minEveryXMinutesInterval,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        // Default initialization of recurrence configuration.
        $scope.data.hoursInDay = consts.getHoursOfTheDay(); // Dictionary of hour name to hour numeric - { '1 AM' : 1 }
        $scope.data.selectedHourInDay = $scope.data.hoursInDay[11].value;
        $scope.data.selectedMinuteOfHourOfDay = 0;

        // Generating minutes of hour of day.
        for (let i = 0; i <= 59; i++) {
            $scope.data.minutesOfHourOfDay.push(i);
        }

        // Generating the days of month
        for (let i = 1; i <= 31; i++) {
            $scope.data.daysInMonth.push(i);
        }

        if ($scope.data.existingRecurrenceTimeSelection) {
            initializeEditMode();
        } else {
            $scope.onRecurrenceTimeSelectionChanged();
        }
    };

    /**
     * Occurs on changes to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.existingRecurrenceTimeSelection && ctrl.existingRecurrenceTimeSelection) {
            $scope.data.existingRecurrenceTimeSelection = ctrl.existingRecurrenceTimeSelection;
            initializeEditMode();
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Selects a day in week, and sets a boolean indicating whether at least one day in week is selected.
     */
    $scope.selectDayInWeek = function (dayInWeekSelection) {
        dayInWeekSelection.selected = !dayInWeekSelection.selected;

        if (dayInWeekSelection.selected) {
            $scope.data.atLeastOneDaySelected = true;
        } else {
            const recurrenceDaysInWeek = extractRecurrenceDaysFromSelectedMap($scope.data.selectedDaysInWeek);

            if (!recurrenceDaysInWeek || !recurrenceDaysInWeek.length) {
                $scope.data.atLeastOneDaySelected = false;
            }
        }

        $scope.onRecurrenceTimeSelectionChanged();
    };

    /**
     * Selects a month in year, and sets a boolean indicating whether at least one month in year is selected.
     */
    $scope.selectMonthInYear = function (monthInYearSelection) {
        monthInYearSelection.selected = !monthInYearSelection.selected;

        if (monthInYearSelection.selected) {
            $scope.data.atLeastOneMonthSelected = true;
        } else {
            const recurrenceMonthsInYear = extractRecurrenceMonthsFromSelectedMap($scope.data.selectedMonthsInYear);

            if (!recurrenceMonthsInYear || !recurrenceMonthsInYear.length) {
                $scope.data.atLeastOneMonthSelected = false;
            }
        }

        $scope.onRecurrenceTimeSelectionChanged();
    };

    /**
     * Occurs on change of recurrence time selection object.
     */
    $scope.onRecurrenceTimeSelectionChanged = function (value) {
        $scope.data.doNotRunOnWeekends = value;
        $scope.data.isXMinutesInvalid =
            $scope.data.selectedFrequency === 'EveryXMinutes' && !$scope.data.selectedEveryXMinutes;
        $scope.data.isXHoursInvalid =
            $scope.data.selectedFrequency === 'EveryXHours' && !$scope.data.selectedEveryXHours;
        if ($scope.data.isXMinutesInvalid || $scope.data.isXHoursInvalid) {
            return;
        }

        const recurrenceDaysInWeek = extractRecurrenceDaysFromSelectedMap($scope.data.selectedDaysInWeek);
        const recurrenceMonthsInYear = extractRecurrenceMonthsFromSelectedMap($scope.data.selectedMonthsInYear);
        const recurrenceDaysInMonth = [$scope.data.selectedDayInMonth];
        const recurrenceMinute = $scope.data.selectedMinuteOfHourOfDay;

        const recurrenceTimeSelection = {
            recurrencePeriodType: $scope.data.selectedFrequency,
            doNotRunOnWeekends: $scope.data.doNotRunOnWeekends,
            timezone: $scope.data.selectedTimezone,
        };

        switch ($scope.data.selectedFrequency) {
            case 'EveryXMinutes':
                recurrenceTimeSelection.everyXMinutes = $scope.data.selectedEveryXMinutes;
                break;
            case 'EveryXHours':
                recurrenceTimeSelection.everyXHours = $scope.data.selectedEveryXHours;
                break;
            case 'Weekly':
                recurrenceTimeSelection.recurrenceDaysInWeek = recurrenceDaysInWeek;
                recurrenceTimeSelection.recurrenceHour = $scope.data.selectedHourInDay;
                recurrenceTimeSelection.recurrenceMinute = recurrenceMinute;
                break;
            case 'Monthly':
                recurrenceTimeSelection.recurrenceMonthsInYear = recurrenceMonthsInYear;
                recurrenceTimeSelection.recurrenceDaysInMonth = recurrenceDaysInMonth;
                recurrenceTimeSelection.recurrenceHour = $scope.data.selectedHourInDay;
                recurrenceTimeSelection.recurrenceMinute = recurrenceMinute;
                break;
            case 'Quarterly':
                recurrenceTimeSelection.recurrenceHour = $scope.data.selectedHourInDay;
                recurrenceTimeSelection.recurrenceMinute = recurrenceMinute;
                break;
            case 'Daily':
                recurrenceTimeSelection.recurrenceHour = $scope.data.selectedHourInDay;
                recurrenceTimeSelection.recurrenceMinute = recurrenceMinute;
                break;
        }

        if (ctrl.onRecurrenceTimeSelectionChanged) {
            ctrl.onRecurrenceTimeSelectionChanged({
                recurrenceTimeSelection,
            });
        }
        return $q.resolve();
    };

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        $scope.data.selectedFrequency = $scope.data.existingRecurrenceTimeSelection.recurrencePeriodType;

        // Setting the selected day
        switch ($scope.data.selectedFrequency) {
            case 'Weekly':
                const selectedDaysMap = {};
                for (let i = 0; i < $scope.data.existingRecurrenceTimeSelection.recurrenceDaysInWeek.length; i++) {
                    const selectedDay = $scope.data.existingRecurrenceTimeSelection.recurrenceDaysInWeek[i];
                    selectedDaysMap[selectedDay] = true;
                }

                $scope.data.selectedDaysInWeek = getDaysInWeekSelectedMap(selectedDaysMap);
                break;

            case 'Monthly':
                // In month frequency, you may only choose one recurrence day
                $scope.data.selectedDayInMonth = $scope.data.existingRecurrenceTimeSelection.recurrenceDaysInMonth[0];

                if ($scope.data.existingRecurrenceTimeSelection.recurrenceMonthsInYear) {
                    const selectedMonthsInYearMap = {};
                    for (
                        let i = 0;
                        i < $scope.data.existingRecurrenceTimeSelection.recurrenceMonthsInYear.length;
                        i += 1
                    ) {
                        const selectedMonth = $scope.data.existingRecurrenceTimeSelection.recurrenceMonthsInYear[i];
                        selectedMonthsInYearMap[selectedMonth] = true;
                    }

                    $scope.data.selectedMonthsInYear = getMonthsInYearSelectedMap(selectedMonthsInYearMap);
                }
                break;
        }

        // Setting the selected hour
        if (
            $scope.data.existingRecurrenceTimeSelection.recurrenceHour ||
            $scope.data.existingRecurrenceTimeSelection.recurrenceHour === 0
        ) {
            $scope.data.selectedHourInDay = $scope.data.existingRecurrenceTimeSelection.recurrenceHour;
        }

        // Setting the selected minute
        if ($scope.data.existingRecurrenceTimeSelection.recurrenceMinute) {
            $scope.data.selectedMinuteOfHourOfDay = $scope.data.existingRecurrenceTimeSelection.recurrenceMinute;
        }

        if (!utils.isNullOrUndefined($scope.data.existingRecurrenceTimeSelection.everyXMinutes)) {
            $scope.data.selectedEveryXMinutes = $scope.data.existingRecurrenceTimeSelection.everyXMinutes;
        }

        if (!utils.isNullOrUndefined($scope.data.existingRecurrenceTimeSelection.everyXHours)) {
            $scope.data.selectedEveryXHours = $scope.data.existingRecurrenceTimeSelection.everyXHours;
        }

        $scope.data.doNotRunOnWeekends = $scope.data.existingRecurrenceTimeSelection.doNotRunOnWeekends;

        // Setting the selected minute
        if ($scope.data.existingRecurrenceTimeSelection.timezone) {
            $scope.data.selectedTimezone = $scope.data.existingRecurrenceTimeSelection.timezone;
        } else {
            $scope.data.selectedTimezone = dayjs.tz.guess();
        }
    }

    /**
     * Constructs a selected map from the days of week settings.
     */
    function getDaysInWeekSelectedMap(selectedDaysMap) {
        const daysOfWeekSelectedMap = {};
        const daysOfWeekSettings = consts.getDaysOfWeekSettings();

        for (const singleDay in daysOfWeekSettings) {
            if (daysOfWeekSettings.hasOwnProperty(singleDay)) {
                const singleDayApiValue = daysOfWeekSettings[singleDay];

                daysOfWeekSelectedMap[singleDay] = {
                    apiValue: singleDayApiValue,
                    selected: selectedDaysMap[singleDayApiValue] || selectedDaysMap[singleDay],
                };
            }
        }

        return daysOfWeekSelectedMap;
    }

    /**
     * Constructs a selected map from the months of year settings.
     */
    function getMonthsInYearSelectedMap(selectedMonthsInYearMap) {
        const monthsOfYearSelectedMap = {};
        const monthsOfYearSettings = consts.getMonthsOfYearSettings();

        for (const singleMonth in monthsOfYearSettings) {
            if (monthsOfYearSettings.hasOwnProperty(singleMonth)) {
                const singleMonthApiValue = monthsOfYearSettings[singleMonth];

                monthsOfYearSelectedMap[singleMonth] = {
                    apiValue: singleMonthApiValue,
                    selected: selectedMonthsInYearMap[singleMonthApiValue] || selectedMonthsInYearMap[singleMonth],
                };
            }
        }

        return monthsOfYearSelectedMap;
    }

    /**
     * Gets the selected recurrence days in week.
     */
    function extractRecurrenceDaysFromSelectedMap(selectedDaysInWeekMap) {
        const recurrenceDays = [];

        for (const day in selectedDaysInWeekMap) {
            if (selectedDaysInWeekMap.hasOwnProperty(day) && selectedDaysInWeekMap[day].selected === true) {
                recurrenceDays.push(selectedDaysInWeekMap[day].apiValue);
            }
        }

        return recurrenceDays;
    }

    /**
     * Gets the selected recurrence months in year.
     */
    function extractRecurrenceMonthsFromSelectedMap(selectedMonthsInYearMap) {
        const recurrenceMonths = [];

        for (const month in selectedMonthsInYearMap) {
            if (selectedMonthsInYearMap.hasOwnProperty(month) && selectedMonthsInYearMap[month].selected === true) {
                recurrenceMonths.push(selectedMonthsInYearMap[month].apiValue);
            }
        }

        return recurrenceMonths;
    }
}

export default angular
    .module('tonkean.app')
    .controller('RecurrenceTimeSelectionCtrl', lateConstructController(RecurrenceTimeSelectionCtrl));
