import React from 'react';
import styled, { css } from 'styled-components';

import ProjectIntegrationBox from './ProjectIntegrationBox';
import ProjectIntegrationLoadingBoxesView from './ProjectIntegrationLoadingBoxesView';
import ThinProjectIntegrationBox from './ThinProjectIntegrationBox';
import { ReactComponent as SmallLockIcon } from '../../../../../images/icons/small-lock.svg';
import { ReactComponent as TooltipIcon } from '../../../../../images/icons/tooltip2.svg';

import { Tooltip } from '@tonkean/infrastructure';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SectionHeader = styled.div<{ isFirst: boolean }>`
    color: ${Theme.current.palette.mainColors.gray_700};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    display: inline-flex;

    ${({ isFirst }) =>
        !isFirst &&
        css`
            margin-top: 35px;
        `};
`;

const FlexedContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
`;

const LeftIconContainer = styled.div`
    margin-right: 5px;
`;

const RightIconContainer = styled.div`
    margin-left: 5px;
    height: 10px;
    margin-top: 1px;
`;

const EmptyStateText = styled.div`
    color: ${Theme.current.palette.mainColors.gray_500};
    word-break: break-word;
`;

interface Props {
    authorizedProjectIntegrations: ProjectIntegrationSummary[];
    unAuthorizedProjectIntegrations: ProjectIntegrationSummary[];
    disconnectedIntegrations: string[];
    filterByText?: string;
    isLoading: boolean;

    onClickConnectedDataSource: (projectIntegration: ProjectIntegrationSummary) => void;
    onClickDisconnectedDataSource: (integrationType: string) => void;
}

const ProjectIntegrationsBoxesView: React.FC<Props> = ({
    authorizedProjectIntegrations,
    unAuthorizedProjectIntegrations,
    filterByText,
    disconnectedIntegrations,
    onClickConnectedDataSource,
    onClickDisconnectedDataSource,
    isLoading,
}) => {
    const emptyFilteringStateText = `No data sources match the search keyword "${filterByText}"`;

    return (
        <>
            {/* Accessible data sources title */}
            <SectionHeader isFirst>
                Accessible data sources under solution
                <RightIconContainer>
                    <Tooltip content="Data sources that have been granted access to all the modules under this solution.">
                        <TooltipIcon />
                    </Tooltip>
                </RightIconContainer>
            </SectionHeader>

            {/* Accessible data sources boxes */}
            <FlexedContainer>
                {!isLoading &&
                    authorizedProjectIntegrations.map((authorizedProjectIntegration) => (
                        <ProjectIntegrationBox
                            key={authorizedProjectIntegration.id}
                            projectIntegration={authorizedProjectIntegration}
                            onClick={onClickConnectedDataSource}
                        />
                    ))}
                {/* Accessible data sources loading */}
                {isLoading && <ProjectIntegrationLoadingBoxesView />}
                {/* Accessible data sources - none at all */}
                {!isLoading && authorizedProjectIntegrations.length === 0 && !filterByText && (
                    <EmptyStateText>There are no available data sources</EmptyStateText>
                )}
                {/* Accessible data sources - none with filtering */}
                {!isLoading && authorizedProjectIntegrations.length === 0 && filterByText && (
                    <EmptyStateText>{emptyFilteringStateText}</EmptyStateText>
                )}
            </FlexedContainer>

            {/* Inaccessible data sources title */}
            <SectionHeader isFirst={false}>
                <LeftIconContainer>
                    <SmallLockIcon />
                </LeftIconContainer>
                Inaccessible data sources
                <RightIconContainer>
                    <Tooltip content="Data sources that are connected to the board but haven't been granted access to the modules under this solution.">
                        <TooltipIcon />
                    </Tooltip>
                </RightIconContainer>
            </SectionHeader>

            {/* Inaccessible data sources boxes */}
            <FlexedContainer>
                {!isLoading &&
                    unAuthorizedProjectIntegrations.map((unAuthorizedProjectIntegration) => (
                        <ProjectIntegrationBox
                            key={unAuthorizedProjectIntegration.id}
                            projectIntegration={unAuthorizedProjectIntegration}
                            onClick={onClickConnectedDataSource}
                            darkerBackground
                        />
                    ))}
                {/* Inaccessible data sources loading */}
                {isLoading && <ProjectIntegrationLoadingBoxesView />}
                {/* Accessible data sources - none at all */}
                {!isLoading && unAuthorizedProjectIntegrations.length === 0 && !filterByText && (
                    <EmptyStateText>There are no available data sources</EmptyStateText>
                )}
                {/* Accessible data sources - none with filtering */}
                {!isLoading && unAuthorizedProjectIntegrations.length === 0 && filterByText && (
                    <EmptyStateText>{emptyFilteringStateText}</EmptyStateText>
                )}
            </FlexedContainer>

            {/* Other data sources title */}
            <SectionHeader isFirst={false}>
                Other data sources to connect
                <RightIconContainer>
                    <Tooltip content="Other available data sources to connect to the board.">
                        <TooltipIcon />
                    </Tooltip>
                </RightIconContainer>
            </SectionHeader>

            {/* Other data sources boxes */}
            <FlexedContainer>
                {!isLoading &&
                    disconnectedIntegrations.map((disconnectedIntegration) => (
                        <ThinProjectIntegrationBox
                            key={disconnectedIntegration}
                            integrationType={disconnectedIntegration}
                            onClick={onClickDisconnectedDataSource}
                        />
                    ))}
                {/* other data sources loading */}
                {isLoading && <ProjectIntegrationLoadingBoxesView />}

                {/* Accessible data sources - none with filtering */}
                {!isLoading && disconnectedIntegrations.length === 0 && filterByText && (
                    <EmptyStateText>{emptyFilteringStateText}</EmptyStateText>
                )}
            </FlexedContainer>
        </>
    );
};

export default ProjectIntegrationsBoxesView;
