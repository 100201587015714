import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as InnerItemsCube } from '../../../../../../images/icons/inner-item-cube.svg';
import { ReactComponent as ItemDetailsCube } from '../../../../../../images/icons/item-details-cube.svg';
import { INNER_ITEM_DETAILS_NAME_DEFAULT, ITEM_DETAILS_NAME_DEFAULT } from '../../../itemDetailsSharedConsts';
import { useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery } from '../../ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';

import type { TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

const WhenLineWrapper = styled.div`
    text-align: left;
    padding: 1px;
    display: flex;
    gap: 5px;
`;

const Label = styled.div`
    font-weight: 500;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    overrideDetailsTitlePlaceholder?: string;
}
const ItemDetailsWhenLineTooltipRow: React.FC<Props> = ({
    workflowVersionId,
    targetId,
    overrideDetailsTitlePlaceholder,
}) => {
    const [{ data, fetching, error }] = useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery({
        variables: {
            targetId,
            workflowVersionId,
        },
        suspend: false,
    });

    const targetIsGroup = useMemo(() => {
        return targetId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.GROUP]);
    }, [targetId]);

    const label = useMemo(() => {
        const itemDetailsLabel = data?.itemDetailsByTargetIdAndWorkflowVersionId?.name;
        const hasLabel = !Utils.isNullOrEmpty(itemDetailsLabel);
        return hasLabel
            ? itemDetailsLabel
            : targetIsGroup
              ? ITEM_DETAILS_NAME_DEFAULT
              : overrideDetailsTitlePlaceholder ?? INNER_ITEM_DETAILS_NAME_DEFAULT;
    }, [data?.itemDetailsByTargetIdAndWorkflowVersionId?.name, overrideDetailsTitlePlaceholder, targetIsGroup]);

    return (
        <WhenLineWrapper>
            {targetIsGroup ? <ItemDetailsCube /> : <InnerItemsCube />}
            <Label>{label}</Label>
        </WhenLineWrapper>
    );
};

export default ItemDetailsWhenLineTooltipRow;
