import BoxAuthenticationComponent from './BoxAuthenticationComponent';
import BoxCustomizeSetupComponent from './BoxCustomizeSetupComponent';
import boxIcon from '../../../../apps/tracks/images/box.png';
import boxCircleIcon from '../../../../apps/tracks/images/integrations/box-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class BoxIntegration extends IntegrationDefinitionBase {
    override name = 'box';
    override displayName = 'Box';
    override description = 'We will collect Users, Folders & Files metadata.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, true);
    override entities = [
        new IntegrationEntity('File', 'Files'),
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Group', 'Groups'),
    ];
    override dataSourceSectionType = DataSourcesSectionType.DATABASE;
    override customizedAuthenticationComponent = BoxAuthenticationComponent;
    override customizedSetupComponent = BoxCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(boxCircleIcon, '130px'),
        new IntegrationIcon(boxIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { Folder: true, File: true, User: true, Group: true },
        isOnlyDataRetention: {},
    };
}

export default BoxIntegration;
