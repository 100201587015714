import { useAngularService } from 'angulareact';

import { usePreventConcurrentManualReloadFetchManager } from '@tonkean/infrastructure';
import type { IntakeRequestViewer } from '@tonkean/tonkean-entities';

const getItems = (response: { viewers: IntakeRequestViewer[] }) => response;

const useGetIntakeRequestViewers = () => {
    const tonkeanService = useAngularService('tonkeanService');
    const [[getIntakeRequestViewers], { data: viewersResponse }] = usePreventConcurrentManualReloadFetchManager(
        tonkeanService,
        'updateIntakeRequestViewersByInitiativeId',
        {
            autoReloadInterval: 10_000,
            isSingle: true,
            getItems,
        },
    );

    return { getIntakeRequestViewers, viewersResponse };
};

export default useGetIntakeRequestViewers;
