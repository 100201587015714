import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import FormulaAIConversationMessageView from './FormulaAIConversationMessageView';

import { H2 } from '@tonkean/infrastructure';
import { SmartConversationView, type SmartConversationManager } from '@tonkean/smart-conversation';
import { SmartConversationUserReplyView } from '@tonkean/smart-conversation';
import type { FormulaAIServerResponse } from '@tonkean/tonkean-entities';
import type { SmartConversationMessage } from '@tonkean/tonkean-entities';
import type { FormulaAIMessage } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const InitialDescription = styled(H2)`
    font-style: italic;
    line-height: 35px;
`;

interface Props {
    onUseFormulaClick: (formula: string) => void;
    smartConversationManager: SmartConversationManager<FormulaAIMessage>;
    called: boolean;
}

const FormulaAIConversationView: React.FC<Props> = ({ onUseFormulaClick, smartConversationManager, called }) => {
    const [showReplyBar, setShowReplyBar] = useState(false);

    const messageTransformer = useCallback(
        (message: SmartConversationMessage<FormulaAIMessage>, index: number) => {
            if (message.response.userResponse) {
                return <SmartConversationUserReplyView message={message} />;
            }

            return (
                <FormulaAIConversationMessageView
                    message={message as SmartConversationMessage<FormulaAIServerResponse>}
                    onAnimationEnd={() => {
                        message.showAnimation = false;
                        setShowReplyBar(true);
                    }}
                    onUseFormulaClick={onUseFormulaClick}
                />
            );
        },
        [onUseFormulaClick],
    );

    return (
        <>
            <SmartConversationView
                smartConversationManager={smartConversationManager}
                loading={smartConversationManager.replyMethodLoading}
                error={smartConversationManager.replyMethodError}
                showReplyBar={showReplyBar && !smartConversationManager.replyMethodLoading}
                messageTransformer={messageTransformer}
                takeFullHeight={false}
            />
            {!called && (
                <InitialDescription $color={Theme.colors.gray_600}>
                    Type what you are trying to achieve in your own words, for example:
                    <ul>
                        <li>“Take the domain out of the Email Address”</li>
                        <li>“Change the days in this formula from 7 to 10”</li>
                        <li>“Extract all phone numbers from Email Body”</li>
                        <li>“Explain how I can change a date format”</li>
                    </ul>
                </InitialDescription>
            )}
        </>
    );
};

export default FormulaAIConversationView;
