<!-- Example item evaluated expression -->
<span
    class="common-text-wrap"
    ng-if="
        !data.doNotEvaluatePreview &&
        (data.expressionToEvaluate || data.expressionToEvaluate === undefined || data.fieldIdToEvaluate)
    "
>
    <!-- Block mode -->
    <div ng-if="!data.isInline" class="margin-top-xs"></div>

    <!-- Error state -->
    <span class="common-color-danger common-size-xxxs margin-right" ng-if="data.evaluationError">
        {{ data.evaluationError }}
    </span>

    <!-- Content Container -->
    <div class="evaluated-expression-container relative" ng-click="onExampleAreaClicked()">
        <!-- The actual content of what we are previewing -->
        <div
            class="example-item-evaluated-expression-content"
            ng-class="{
                'mod-expanded':
                    data.isShowingMore || data.evaluatedExpressionValue.length <= data.maximumExpressionLength
            }"
        >
            <span
                class="common-size-xxxs common-color-grey common-text-wrap"
                ng-class="{
                    'common-color-grey': !data.color || data.color === 'grey',
                    'common-color-link-blue': data.color === 'blue',
                    'common-color-black': data.color === 'black'
                }"
            >
                <span class="example-item-evaluated-expression-text-icon-area">
                    <!-- Preview Icon -->
                    <a
                        ng-if="
                            !data.hideIcon &&
                            !data.evaluatedExpressionLoading &&
                            (data.expressionToEvaluate ||
                                data.expressionToEvaluate === undefined ||
                                data.fieldIdToEvaluate)
                        "
                        data-ng-click="togglePreview($event)"
                        class="common-color-grey common-size-xxxs common-no-text-decoration"
                        tooltip-placement="bottom-left"
                        uib-tooltip="Preview using the fields of the item {{
                            data.exampleInitiativeTitle
                        }} selected in the Workflow Outline on the left."
                        tooltip-append-to-body="true"
                    >
                        <i class="fa fa-flask"></i>
                    </a>

                    <!-- Loading state Evaluated text -->
                    <span
                        class="loading-small example-item-evaluated-expression-loading-circle"
                        ng-if="!data.hidePreview && data.evaluatedExpressionLoading"
                    ></span>
                </span>

                <!-- Actual content -->
                <span ng-if="!data.hidePreview">
                    <span
                        ng-if="(data.exampleInitiativeId || data.previewEvaluationSource) && data.htmlPreview"
                        ng-bind-html="data.evaluatedExpressionValue"
                    ></span>
                    <span
                        class="common-break-no-spaces"
                        ng-if="
                            (data.exampleInitiativeId || data.previewEvaluationSource) &&
                            !data.htmlPreview &&
                            !data.customPreview
                        "
                    >
                        {{ data.evaluatedExpressionValue }}
                    </span>
                    <span class="common-break-no-spaces" ng-if="data.customPreview">
                        {{ data.customPreview }}
                    </span>
                    <span
                        ng-if="
                            !(
                                data.exampleInitiativeId ||
                                data.previewEvaluationSource ||
                                (data.showPeopleDirectories && data.evaluatedExpressionValue)
                            ) && !data.customPreview
                        "
                    >
                        There are no monitored items to preview
                    </span>
                </span>

                <!-- Empty State -->
                <span
                    ng-if="
                        !data.hidePreview &&
                        (data.exampleInitiativeId || data.previewEvaluationSource) &&
                        !data.evaluatedExpressionValue &&
                        !data.evaluatedExpressionLoading &&
                        !(data.expressionToEvaluate && data.fieldIdToEvaluate)
                    "
                    class="common-size-xxxs common-color-grey"
                >
                    No value
                </span>
            </span>
        </div>

        <!-- Show more mechanic -->
        <div
            ng-if="
                !data.hidePreview &&
                data.evaluatedExpressionValue.length > data.maximumExpressionLength &&
                !data.isShowingMore
            "
        >
            <!-- Blurred area -->
            <div class="example-item-evaluated-expression-fade pointer"></div>

            <!-- Show more button -->
            <div
                class="
                    example-item-evaluated-expression-show-more
                    common-bold common-size-xxxxs
                    flex
                    mod-center
                    pointer
                "
            >
                Show more
            </div>
        </div>

        <!-- Show less mechanic -->
        <div
            ng-if="
                !data.hidePreview &&
                data.evaluatedExpressionValue.length > data.maximumExpressionLength &&
                data.isShowingMore
            "
        >
            <div ng-click="onShowLessClicked($event)" class="common-bold common-size-xxxxs flex mod-center pointer">
                Show less
            </div>
        </div>
    </div>
</span>
