import { useCallback, useEffect, useState } from 'react';

import useNextStepSummary from './useNextStepSummary';
import useUpdateNextSequenceStep from './useUpdateNextSequenceStep';
import type NextStepSummary from '../entities/NextStepSummary';
import type SequenceSubmissionResponse from '../entities/SequenceSubmissionResponse';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { usePollingWithConditions } from '@tonkean/infrastructure';
import type { SequenceState } from '@tonkean/tonkean-entities';
import type {
    EndSequence,
    FetchResults,
    Initiative,
    ItemInterface,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';

const useNextNavigation = (
    initiative: Initiative,
    intakeInitiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    intakeGroupId: TonkeanId<TonkeanType.GROUP>,
    scrollToTop: () => void,
    onSwitchedToNextInterfaceInternally: ((customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void) | undefined,
    onNextClicked: ((data: any) => void) | undefined,
    sequenceState: SequenceState,
    updateSequenceStep: (
        stepItemInterface: ItemInterface,
        stepCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        stepWidgets: WidgetBase<WidgetConfiguration, WidgetParentTypes>[] | undefined,
        isStepLastInSequence: boolean,
        nextInSequence: boolean,
        initative: Initiative | undefined,
        workflowVersion: WorkflowVersion | undefined,
    ) => void,
    handleNextInterface: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
    ) => Promise<any>,
    getStepInterfaceWidgets: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        workflowVersionIdOverride: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    ) => Promise<FetchResults<WidgetBase<WidgetConfiguration, WidgetParentTypes>>>,
    getStepItemInterfaceById: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        workflowVersionIdOverride: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    ) => Promise<ItemInterface>,
    setSubmitLoadingActive: (loading: boolean) => void,
    setShowThankYouPage: (show: boolean) => void,
    setShowWaitForConditionsToMatch: (matched: boolean) => void,
    setIsWaitingForNextInitiative: (waiting: boolean) => void,
) => {
    const [, runQueryOnInitiative] = useLazyTonkeanService('runQueryOnInitiative');
    const [, evaluateEndSequenceData] = useLazyTonkeanService('evaluateEndSequenceData');

    const [actionDefinition, setActionDefinition] = useState<any>({});
    const [sequenceSubmissionResponse, setSequenceSubmissionResponse] = useState<
        SequenceSubmissionResponse | undefined
    >();

    const [sequenceNextStepSummary, setSequenceNextStepSummary] = useState<NextStepSummary | undefined>();

    const [waitConditionsFinishedPolling, setWaitConditionsFinishedPolling] = useState<boolean>(false);

    const calcNextStepSummary = useNextStepSummary();
    const updateSummaryHandle = useUpdateNextSequenceStep(
        updateSequenceStep,
        getStepInterfaceWidgets,
        getStepItemInterfaceById,
    );

    const handleConditionalPolling = usePollingWithConditions();

    const handlePostNextNavigation = useCallback(
        async (
            nextStepSummary: NextStepSummary,
            itemInterfaceSubmissionResponse: any,
            continueAfterWaitConditions: boolean,
        ) => {
            if (continueAfterWaitConditions && nextStepSummary.isStepLastInSequence) {
                const endSequenceData: EndSequence = await evaluateEndSequenceData(
                    intakeGroupId,
                    nextStepSummary.answeredCustomTriggerId,
                    intakeInitiativeId,
                );
                itemInterfaceSubmissionResponse = { ...itemInterfaceSubmissionResponse, ...endSequenceData };
            }

            const { isNextStepThankYouPage, isWaitingForNextInitiative } = await updateSummaryHandle(nextStepSummary);

            onSwitchedToNextInterfaceInternally?.(
                nextStepSummary.goToSequenceTriggerId ?? nextStepSummary.answeredCustomTriggerId,
            );

            setActionDefinition(nextStepSummary.firstActionDefinition);
            setSequenceSubmissionResponse(itemInterfaceSubmissionResponse);
            setShowThankYouPage(isNextStepThankYouPage);
            setIsWaitingForNextInitiative(isWaitingForNextInitiative);
            setShowWaitForConditionsToMatch(false);
            if (!isWaitingForNextInitiative) {
                setSubmitLoadingActive(false);
            }
        },
        [
            onSwitchedToNextInterfaceInternally,
            setShowThankYouPage,
            setShowWaitForConditionsToMatch,
            setSubmitLoadingActive,
            setIsWaitingForNextInitiative,
            updateSummaryHandle,
            evaluateEndSequenceData,
            intakeGroupId,
            intakeInitiativeId,
        ],
    );

    useEffect(() => {
        if (waitConditionsFinishedPolling && sequenceNextStepSummary && sequenceSubmissionResponse) {
            setWaitConditionsFinishedPolling(false);
            handlePostNextNavigation(sequenceNextStepSummary, sequenceSubmissionResponse, true);
        }
    }, [handlePostNextNavigation, sequenceNextStepSummary, sequenceSubmissionResponse, waitConditionsFinishedPolling]);

    const onNextClickedHandle = useCallback(async () => {
        if (sequenceState.customTriggerId && initiative.id) {
            setSubmitLoadingActive(true);
            const itemInterfaceSubmissionResponse = await handleNextInterface(
                sequenceState.itemInterface.id,
                sequenceState.customTriggerId,
            );
            const nextStepSummary = calcNextStepSummary(itemInterfaceSubmissionResponse);
            scrollToTop();
            setSequenceSubmissionResponse(itemInterfaceSubmissionResponse);
            setSequenceNextStepSummary(nextStepSummary);

            if (!!onNextClicked && !!itemInterfaceSubmissionResponse && !!nextStepSummary.showFormInNextStep) {
                onNextClicked(itemInterfaceSubmissionResponse);
                return;
            }

            if (nextStepSummary.isWaitConditions) {
                setShowWaitForConditionsToMatch(true);
                handleConditionalPolling(
                    1000,
                    (data) => data['matched'],
                    () => runQueryOnInitiative(intakeInitiativeId, nextStepSummary.waitFormConditions),
                    () => setWaitConditionsFinishedPolling(true),
                );
            } else {
                await handlePostNextNavigation(nextStepSummary, itemInterfaceSubmissionResponse, false);
            }
        }
    }, [
        sequenceState.customTriggerId,
        sequenceState.itemInterface.id,
        initiative.id,
        setSubmitLoadingActive,
        handleNextInterface,
        calcNextStepSummary,
        scrollToTop,
        onNextClicked,
        setShowWaitForConditionsToMatch,
        handleConditionalPolling,
        runQueryOnInitiative,
        handlePostNextNavigation,
        intakeInitiativeId,
    ]);

    return {
        onNextClickedHandle,
        actionDefinition,
        sequenceSubmissionResponse,
    };
};

export default useNextNavigation;
