<div class="choose-worker-type-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn svg-icon" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title -->
    <div class="common-size-l common-bold margin-bottom">Create a New Module</div>

    <!-- Worker name input -->
    <div class="flex-vmiddle margin-bottom-xlg margin-top-lg">
        <div class="common-bold">Give it a name:</div>
        <input
            class="form-control common-input-only-line margin-left common-width-40"
            data-automation="choose-worker-type-modal-module-name-textbox"
            ng-model="data.workerName"
            placeholder="Module name..."
            autofocus
            required
        />
    </div>

    <!-- Type selection -->
    <div>
        <div class="worker-type-box margin-bottom pointer">
            <div>
                <strong>
                    * {{ workerTypes[data.selectedType].label }} ({{ workerTypes[data.selectedType].subtext }})
                </strong>
            </div>
            <div class="common-color-grey common-size-xxxs">{{ workerTypes[data.selectedType].description }}</div>
        </div>
    </div>

    <!-- Save button and error -->
    <div class="flex mod-justify-end">
        <div class="flex mod-justify-end">
            <button
                class="btn btn-primary"
                ng-click="save()"
                ng-disabled="!data.workerName || !data.workerName.length || data.loading"
                data-automation="choose-worker-type-modal-create-new-project-button"
            >
                Create
                <i ng-if="data.loading" class="loading-small"></i>
            </button>
        </div>

        <div ng-if="data.error">{{ data.error }}</div>
    </div>
</div>
