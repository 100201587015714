import type { EnterpriseComponentContentPageConfiguration } from '../entities/EnterpriseComponentContentPageConfiguration';
import type {
    EnterpriseComponentPageMenuConfiguration,
    EnterpriseComponentPageMenuItemConfiguration,
} from '../entities/EnterpriseComponentPageMenuConfiguration';

import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

function getComponentAndPermissionByIds<T extends EnterpriseComponentType, C extends EnterpriseComponent>(
    configuration: EnterpriseComponentPageMenuConfiguration<T, C>,
): Record<string, EnterpriseComponentContentPageConfiguration<T, C>> {
    const sections = configuration.sections;
    const menuItems: EnterpriseComponentPageMenuItemConfiguration<T, C>[] = sections.flatMap(
        ({ menuItems }) => menuItems,
    );

    const idConfigurationArray: Record<string, EnterpriseComponentContentPageConfiguration<T, C>>[] = menuItems.map(
        ({ id, component, permissionRoles, useOldLayout = false, showDefaultBreadCrumbs = useOldLayout }) => ({
            [id]: { component, permissionRoles, showDefaultBreadCrumbs, useOldLayout },
        }),
    );

    return idConfigurationArray.reduce(utils.mergeObjects);
}

export default getComponentAndPermissionByIds;
