import { IntegrationType, TonkeanType } from '@tonkean/tonkean-entities';
import lateConstructController from '../../utils/lateConstructController';
import { getRelevantProjectIntegrations } from '@tonkean/tonkean-utils';

/* @ngInject */
function ProjectIntegrationSelectorCtrl($scope, projectManager, utils, tonkeanService, workflowFolderManager) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wrokflowFolderManager = workflowFolderManager;
    $scope.tonkeanService = tonkeanService;

    $scope.data = {
        groupId: ctrl.groupId,
        selectedProjectIntegrationId: ctrl.selectedProjectIntegrationId,
        allowDefaultOption: ctrl.allowDefaultOption,
        defaultOptionLabel: ctrl.defaultOptionLabel,
        filterOnIntegrationType: ctrl.filterOnIntegrationType,
        selectorLabelPlaceholder: ctrl.selectorLabelPlaceholder,
        availableProjectIntegrationsOptions: [],
        selectedProjectIntegration: null,
        availableProjectIntegrations: [],
        disabled: ctrl.disabled,
        isLoading: false,
        includeAllNoCodeDatasources: ctrl.includeAllNoCodeDatasources,
        isForUpload: ctrl.isForUpload || false,
        isForDownload: ctrl.isForDownload || false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = async function () {
        $scope.data.isLoading = true;
        const workflowFolder = $scope.wrokflowFolderManager.getContainingWorkflowFolder(
            $scope.pm.project.id,
            $scope.pm.currentlyViewedGroupId,
        );

        const noCodeProjectIntegrationToKeep = [];

        // Add upload and download no code project integration
        if ($scope.data.isForUpload) {
            noCodeProjectIntegrationToKeep.push(
                ...(await tonkeanService.getNoCodeProjectIntegrationWithUploadAction($scope.pm.project.id))
                    .projectIntegrationIds,
            );
        }

        if ($scope.data.isForDownload) {
            noCodeProjectIntegrationToKeep.push(
                ...(await tonkeanService.getNoCodeProjectIntegrationWithDownloadAction($scope.pm.project.id))
                    .projectIntegrationIds,
            );
        }

        $scope.data.availableProjectIntegrations = getRelevantProjectIntegrations(
            projectManager.project,
            projectManager.groupsMap[$scope.data.groupId],
            $scope.data.filterOnIntegrationType,
            true,
        ).filter((projectIntegration) => {
            if (
                !$scope.data.includeAllNoCodeDatasources &&
                projectIntegration &&
                projectIntegration.integration &&
                projectIntegration.integration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE
            ) {
                return noCodeProjectIntegrationToKeep.includes(projectIntegration.id);
            } else {
                return true;
            }
        });

        if (workflowFolder) {
            // If we are in a solution we need to filter accessible integration.
            const { enterpriseComponentIds } = await tonkeanService.getWorkflowFolderAccessibleEnterpriseComponents(
                $scope.pm.project.id,
                workflowFolder.id,
                TonkeanType.PROJECT_INTEGRATION,
            );

            $scope.data.availableProjectIntegrations = $scope.data.availableProjectIntegrations.filter(({ id }) =>
                enterpriseComponentIds.includes(id),
            );
        }

        // Defining the default project integration option.
        const defaultProjectIntegrationOption = {
            displayName: $scope.data.defaultOptionLabel ? $scope.data.defaultOptionLabel : 'Tonkean Default',

            // Flagging this option so on the onProjectIntegrationSelected method we will know it's the default option that is selected.
            isDefaultOption: true,
        };

        // Adding default option.
        if ($scope.data.allowDefaultOption) {
            $scope.data.availableProjectIntegrations.push(defaultProjectIntegrationOption);
        }

        // Initializing the selected project integration.
        if ($scope.data.selectedProjectIntegrationId) {
            $scope.data.selectedProjectIntegration = getIntegrationById($scope.data.selectedProjectIntegrationId);
        } else {
            $scope.data.selectedProjectIntegration = $scope.data.allowDefaultOption
                ? defaultProjectIntegrationOption
                : null;
        }

        if ($scope.data.selectedProjectIntegration) {
            $scope.data.projectIntegrationIdSelectedOption = {
                value: $scope.data.selectedProjectIntegration.id,
                label: $scope.data.selectedProjectIntegration.displayName,
            };
            // Add from default prefix to show the user the default choice
            if (ctrl.isFormDefaultSelected && ctrl.displayFormDefault) {
                $scope.data.projectIntegrationIdSelectedOption.label = `Form default - ${$scope.data.projectIntegrationIdSelectedOption.label}`;
            }
        }

        $scope.data.availableProjectIntegrationsOptions = $scope.data.availableProjectIntegrations.map(
            (projectIntegration) => ({ value: projectIntegration.id, label: projectIntegration.displayName }),
        );
        if (ctrl.formDefaultSelectionDataStorageId && ctrl.displayFormDefault) {
            const defaultOptionIndex = $scope.data.availableProjectIntegrationsOptions.findIndex(
                (integration) => integration.value === ctrl.formDefaultSelectionDataStorageId,
            );
            // Add an option with the 'Form default' prefix to enable the user to see the form default
            if (defaultOptionIndex >= 0) {
                const newLabel = `Form default - ${$scope.data.availableProjectIntegrationsOptions[defaultOptionIndex].label}`;
                const newValue = $scope.data.availableProjectIntegrationsOptions[defaultOptionIndex].value;
                $scope.data.availableProjectIntegrationsOptions.push({
                    value: `${newValue}default`,
                    label: newLabel,
                    formDefault: true,
                });
            }
        }
        $scope.data.isLoading = false;
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.disabled) {
            $scope.data.disabled = changesObj.disabled.currentValue;
        }
    };

    /**
     * Occurs once the user selected a project integration.
     */
    $scope.onProjectIntegrationSelected = function (projectIntegrationIdSelectedOption) {
        $scope.data.projectIntegrationIdSelectedOption = projectIntegrationIdSelectedOption;
        let projectIntegrationIdSelectedOptionId = projectIntegrationIdSelectedOption.value;

        // If the default option was selected, retrieve the id from the value
        if (projectIntegrationIdSelectedOption.value.includes('default')) {
            projectIntegrationIdSelectedOptionId = projectIntegrationIdSelectedOption.value.split('default')?.[0];
        }

        // Get the project integration by the id
        $scope.data.selectedProjectIntegration = getIntegrationById(projectIntegrationIdSelectedOptionId);

        // If the default option was selected, add property to object that represents it
        if ($scope.data.selectedProjectIntegration) {
            if (projectIntegrationIdSelectedOption.value.includes('default')) {
                $scope.data.selectedProjectIntegration.formDefault = true;
            } else {
                $scope.data.selectedProjectIntegration.formDefault = false;
            }
        }

        if (ctrl.onProjectIntegrationSelected) {
            ctrl.onProjectIntegrationSelected({
                selectedProjectIntegration: $scope.data.selectedProjectIntegration,
            });
        }
    };

    /**
     * Returns the full project integration from a given id
     * @param projectIntegrationId - The id to fetch
     * @returns a project integration
     */
    function getIntegrationById(projectIntegrationId) {
        return utils.findFirst(
            $scope.data.availableProjectIntegrations,
            (projectIntegration) => projectIntegration && projectIntegration.id === projectIntegrationId,
        );
    }
}

angular
    .module('tonkean.app')
    .controller('ProjectIntegrationSelectorCtrl', lateConstructController(ProjectIntegrationSelectorCtrl));
