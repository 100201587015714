import { fieldsClassifier as FieldsClassifier } from '@tonkean/infrastructure';
import { analyticsWrapper } from '@tonkean/analytics';
import {
    convertMatchSelectionToServerObject,
    getColumnsAndStatesOptionsForDefinitions,
    getDisplayObjectForFieldDefinition,
    getMatchConfigurationAccordingToSourceIntegration,
    getSpecialFieldsForFeatures,
} from '@tonkean/tonkean-utils';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function FieldSelectorCtrl(
    $scope,
    $rootScope,
    $q,
    $timeout,
    tonkeanService,
    customFieldsManager,
    projectManager,
    utils,
    integrations,
    modal,
    requestThrottler,
    workflowVersionManager,
    syncConfigCacheManager,
    customTriggerManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        // Component Bindings.
        selectedField: ctrl.selectedField,
        projectIntegration: ctrl.projectIntegration,
        externalType: ctrl.externalType,
        inspectEntityType: ctrl.inspectEntityType,
        placeHolder: ctrl.placeHolder,
        isRequired: ctrl.isRequired,
        tooltipValue: ctrl.tooltipValue,
        itemsSource: ctrl.itemsSource,
        doNotIncludeExternalFields: ctrl.doNotIncludeExternalFields || false,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        considerAllGroups: ctrl.considerAllGroups,
        groupBy: ctrl.groupBy,
        fieldInspectPopover: ctrl.fieldInspectPopover,
        fieldInspectModal: ctrl.fieldInspectModal,
        fieldInspectAutoOpen: ctrl.fieldInspectAutoOpen,
        viewOnly: ctrl.viewOnly,
        onlyColumnFieldDefinitions: ctrl.onlyColumnFieldDefinitions,
        onlyUpdatableFieldDefinitions: ctrl.onlyUpdatableFieldDefinitions,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        excludedTabSelectorSpecialFields: ctrl.excludedTabSelectorSpecialFields,
        includeTabSelectorSpecialFieldsForFeatures: ctrl.includeTabSelectorSpecialFieldsForFeatures,
        doNotIncludeFieldIdsSet: ctrl.doNotIncludeFieldIdsSet,
        displayIntegrationIndication: ctrl.displayIntegrationIndication,
        additionalProjectIntegrationExternalTypesMap: ctrl.additionalProjectIntegrationExternalTypesMap || {},
        appendToBody: ctrl.appendToBody,
        reloadFieldsOnNewFieldDefinitionCreation: ctrl.reloadFieldsOnNewFieldDefinitionCreation,
        includeGlobalFields: ctrl.includeGlobalFields,
        doNotDisplaySelectedField: ctrl.doNotDisplaySelectedField,
        buttonMode: ctrl.buttonMode,
        listMode: ctrl.listMode,
        autoCreateColumnFields: ctrl.autoCreateColumnFields,
        addFieldsQuickCreateOptions: ctrl.addFieldsQuickCreateOptions,
        quickCreateMatchedEntity: ctrl.quickCreateMatchedEntity,
        doNotDisplaySpecialFields: ctrl.doNotDisplaySpecialFields,
        doNotDisplayExistingFields: ctrl.doNotDisplayExistingFields,
        fieldInspectFilter: ctrl.fieldInspectFilter,
        fieldsFilter: ctrl.fieldsFilter,
        sortBy: ctrl.sortBy,
        onlyIdRelationField: ctrl.onlyIdRelationField,
        dontClosePreviousTabOnFieldCreation: ctrl.dontClosePreviousTabOnFieldCreation,
        allowClearSelection: ctrl.allowClearSelection,
        includeViewerFields: ctrl.includeViewerFields,

        initiated: false,
        suggestionExists: false,
        // Inner attributes.
        fieldOptions: [],
        fieldOptionsMap: {},
        originalFieldOptions: [],
        loadingFieldOptions: false,
        searchQuery: null,

        // The possible sources of the items.
        itemsSourceTypes: {
            external: 'EXTERNAL',
            column: 'COLUMN',
            custom: 'CUSTOM',
        },

        fieldInspectPopoverOpen: false,

        autoFillFieldName: null,
        didAutoFillField: false,

        listModeOptions: [],
    };

    // Free text search item and clear selection item.
    // This is important. We must not instantiate a new object every time the user searches for a field, and use this reference and change its
    // content. If we were to initiate a new reference each time the user searches for a field, we would end up in an infinite digest loop.
    const searchedFieldItem = { name: null, label: null };
    const clearSelectionItem = { name: null, label: 'Clear selection', fieldSelectorClearItem: true };
    const suggestionSelectionItem = {
        name: null,
        initialValue: ctrl.selectedField,
        label: '',
        fieldSelectorSuggestionItem: true,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.initiated = true;

        if (!$scope.data.itemsSource) {
            $scope.data.itemsSource = $scope.data.itemsSourceTypes.external;
        }

        if (ctrl.selectedField) {
            $scope.data.suggestionExists = ctrl.selectedField.isTriggerTemplateData;

            // If we loaded a field from params, make sure it has a source so icons can show
            if (ctrl.selectedField.projectIntegration && ctrl.selectedField.projectIntegration.integrationType) {
                ctrl.selectedField.source = ctrl.selectedField.projectIntegration.integrationType.toLowerCase();
            }
        }

        const loadFieldOptionsPromise = loadFieldOptions();

        loadFieldOptionsPromise.then(() => {
            // If we're in list mode, initialize a list of options for the list.
            if (ctrl.listMode) {
                $scope.data.listModeOptions = $scope.getFieldOptions();
            }

            // Finding the selected field by field id.
            if (ctrl.selectedFieldIdentifier && !$scope.data.selectedField) {
                $scope.data.selectedField = utils.findFirstById($scope.data.fieldOptions, ctrl.selectedFieldIdentifier);
            }
        });

        enrichFieldWithSourceFromOptions();
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        let reloadFields = false;
        let reloadExternal = false;

        if (changes.fieldInspectPopover) {
            $scope.data.fieldInspectPopover = ctrl.fieldInspectPopover;
        }

        if (changes.onlyIdRelationField) {
            $scope.data.onlyIdRelationField = ctrl.onlyIdRelationField;
        }

        if (changes.fieldInspectModal) {
            $scope.data.fieldInspectModal = ctrl.fieldInspectModal;
        }

        if (changes.projectIntegration && ctrl.projectIntegration !== $scope.data.projectIntegration) {
            $scope.data.projectIntegration = ctrl.projectIntegration;
            reloadFields = true;
        }

        // If reloadFields was set to true, reload field options.
        if (changes.reloadFields && changes.reloadFields.currentValue) {
            reloadFields = true;
        }

        if (changes.fieldsFilter) {
            $scope.data.fieldsFilter = ctrl.fieldsFilter;
            reloadFields = true;
        }

        if (changes.externalType) {
            $scope.data.externalType = ctrl.externalType;
            reloadExternal = true;
        }

        if (changes.groupId && ctrl.groupId !== $scope.data.groupId) {
            $scope.data.groupId = ctrl.groupId;
            reloadFields = true;
        }

        if (changes.workflowVersionId && ctrl.workflowVersionId !== $scope.data.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            reloadFields = true;
        }

        if (
            changes.selectedField &&
            ctrl.selectedField !== $scope.data.selectedField &&
            ctrl.selectedField?.name !== $scope.data.selectedField?.name
        ) {
            $scope.data.selectedField = ctrl.selectedField;
            const isPartOfEnvironmentChange = changes.workflowVersionId && !changes.workflowVersionId.isFirstChange();
            $scope.fieldSelectionChange(isPartOfEnvironmentChange);
        }

        if (changes.doNotIncludeFieldIdsSet) {
            $scope.data.doNotIncludeFieldIdsSet = ctrl.doNotIncludeFieldIdsSet;
        }

        if (changes.allowClearSelection) {
            $scope.data.allowClearSelection = ctrl.allowClearSelection;
        }

        if (changes.viewOnly) {
            $scope.data.viewOnly = ctrl.viewOnly;
        }

        if (changes.additionalProjectIntegrationExternalTypesMap) {
            $scope.data.additionalProjectIntegrationExternalTypesMap =
                ctrl.additionalProjectIntegrationExternalTypesMap || {};
            reloadExternal = true;
        }

        if (changes.autoFillFieldName) {
            $scope.data.autoFillFieldName = changes.autoFillFieldName.currentValue;
            if ($scope.data.fieldOptions.length) {
                autoFillField();
            }
        }

        if ($scope.data.inspectEntityType) {
            loadExternalFieldForTyping();
        }

        if ($scope.data.initiated) {
            if (reloadFields) {
                loadFieldOptions();
            }

            if (reloadExternal) {
                loadExternalFieldOptions();
            }
        }
    };

    // Handling a new field definition that is created.
    $rootScope.$on('newFieldDefinitionCreated', () => {
        if (
            $scope.data.reloadFieldsOnNewFieldDefinitionCreation !== false &&
            $scope.data.itemsSource === $scope.data.itemsSourceTypes.column
        ) {
            loadFieldOptions();
        }
    });

    /**
     * Occurs once the Other option is selected.
     * @param field
     */
    $scope.onOtherFieldDefinitionCreated = function (field) {
        field.label = field.name;
        $scope.data.selectedField = field;
        $scope.fieldSelectionChange();
    };

    /**
     * Occurs once a field is clicked while in list mode.
     */
    $scope.selectFieldFromListMode = function (selectedField) {
        $scope.data.selectedField = selectedField;
        $scope.fieldSelectionChange();
    };

    /**
     * Fires whenever a field selection changes. Updates the component's user via the onFieldSelected function.
     */
    $scope.fieldSelectionChange = function (dontSaveChanges) {
        if ($scope.data.selectedField && $scope.data.selectedField.id === 'TNK_OTHER') {
            $rootScope.$broadcast('createNewField', [
                $scope.data.groupId,
                'COLUMN',
                null,
                null,
                true,
                false,
                null,
                $scope.onOtherFieldDefinitionCreated,
                null,
                null,
                false,
                'manual',
                null,
                true,
                null,
                false,
                null,
                false,
                $scope.data.workflowVersionId,
                ['manual'],
                $scope.data.dontClosePreviousTabOnFieldCreation,
            ]);
        } else if ($scope.data.selectedField && $scope.data.selectedField.id === 'TNK_OTHER_MATCHED_ENTITY') {
            $rootScope.$broadcast('createNewField', [
                $scope.data.groupId,
                'COLUMN',
                null,
                null,
                true,
                false,
                null,
                $scope.onOtherFieldDefinitionCreated,
                null,
                null,
                true,
                false,
                null,
                true,
                null,
                true,
                null,
                true,
                $scope.data.workflowVersionId,
                [],
                $scope.data.dontClosePreviousTabOnFieldCreation,
            ]);
        } else if (
            $scope.data.selectedField &&
            $scope.data.selectedField.virtualField &&
            !$scope.data.selectedField.id
        ) {
            $scope.onFieldSelectedInInspect($scope.data.selectedField, false);
        } else if ($scope.data.selectedField && $scope.data.selectedField.quickCreationField) {
            let projectIntegration = null;
            // Get project integration by field source.
            if ($scope.data.selectedField.source) {
                projectIntegration = utils.findFirst(
                    projectManager.project.integrations,
                    (projectIntegration) => projectIntegration.id === $scope.data.selectedField.projectIntegrationId,
                );
            }
            $scope.data.projectIntegration = projectIntegration;
            $scope.data.inspectEntityType = $scope.data.selectedField.entity;

            // The field is id related just if it hasn't linked custom trigger and perfromOnWorkerItem is fales
            // Otherwise we take the selectedField.id
            const idRelationFieldDefinitionId =
                $scope.data.selectedField.linkedCustomTrigger || $scope.data.selectedField.performOnWorkerItem
                    ? undefined
                    : $scope.data.selectedField.id;

            $scope.openFieldInspectModal(
                true,
                idRelationFieldDefinitionId,
                $scope.data.selectedField.linkedCustomTrigger?.id,
                $scope.data.selectedField.performOnWorkerItem,
            );
        } else {
            // If the clear item was selected, set the value of the selected field to null.
            if ($scope.data.selectedField && $scope.data.selectedField.fieldSelectorClearItem) {
                $scope.data.selectedField = { name: null, label: null };
            } else if ($scope.data.selectedField && $scope.data.selectedField.fieldSelectorSuggestionItem) {
                // If selected item is suggestion item set the selected field with the suggestion value.
                $scope.data.selectedField = $scope.data.selectedField.initialValue;
            }

            // Call the given update function.
            ctrl.onFieldSelected({
                selectedField: $scope.data.selectedField,
                selectedFieldIdentifier: ctrl.selectedFieldIdentifier,
                dontSaveChanges,
            });
        }
    };

    /**
     * Supplied to the tnkFieldInspect and fired when the user selects a field from the inspect table.
     */
    $scope.onFieldSelectedInInspect = function (
        field,
        dontNotifyChange,
        idRelationFieldDefinitionId,
        creatingCustomTriggerId,
        performOnWorkerItem,
    ) {
        if (!utils.isNullOrEmpty(field) && !utils.isNullOrEmpty(field.name)) {
            let getSelectedFieldPromise = $q.resolve();

            if (
                $scope.data.autoCreateColumnFields &&
                $scope.data.groupId &&
                (!$scope.data.itemsSource || $scope.data.itemsSource === 'COLUMN')
            ) {
                const matchConfiguration = getMatchConfigurationAccordingToSourceIntegration(
                    field,
                    $scope.data.inspectEntityType,
                    $scope.data.logicId || creatingCustomTriggerId,
                    performOnWorkerItem,
                    idRelationFieldDefinitionId,
                );

                const entity = $scope.data.inspectEntityType ? $scope.data.inspectEntityType : $scope.data.externalType;

                const externalFieldDefinition = {
                    ExternalType: entity,
                    FieldName: field.name,
                    FieldLabel: field.displayName,
                    matchConfiguration: matchConfiguration
                        ? convertMatchSelectionToServerObject(matchConfiguration)
                        : null,
                };

                // Only create field if not exist already.
                const existingFields = $scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId].filter(
                    (selectedField) =>
                        selectedField.definition &&
                        selectedField.definition.FieldName?.toUpperCase() ===
                            externalFieldDefinition.FieldName?.toUpperCase() &&
                        selectedField.definition.FieldLabel?.toUpperCase() ===
                            externalFieldDefinition.FieldLabel?.toUpperCase() &&
                        selectedField.definition.ExternalType?.toUpperCase() ===
                            externalFieldDefinition.ExternalType?.toUpperCase(),
                );
                if (existingFields && existingFields.length) {
                    const matchedFields = $scope.data.fieldOptions.filter(
                        (fieldOption) => fieldOption.id === existingFields[0].id,
                    );
                    if (matchedFields && matchedFields.length) {
                        getSelectedFieldPromise = $q.resolve(matchedFields[0]);
                    }
                } else {
                    let fieldName = field.displayName ?? field.label ?? field.name;
                    if (entity && !fieldName.toLowerCase().includes(entity.toLowerCase())) {
                        fieldName = `${entity} ${fieldName}`;
                    }

                    getSelectedFieldPromise = customFieldsManager
                        .createFieldDefinition(
                            'COLUMN',
                            fieldName,
                            field.description,
                            'EXTERNAL',
                            null,
                            externalFieldDefinition,
                            $scope.data.projectIntegration.id,
                            $scope.data.groupId,
                            field.type,
                            null,
                            field.updateable,
                            null,
                            null,
                            null,
                            false,
                            false,
                            true,
                            false,
                        )
                        .then((createdFieldDefinition) => {
                            customFieldsManager.addToCache($scope.data.workflowVersionId, [createdFieldDefinition]);
                            $rootScope.$broadcast('newFieldDefinitionCreated', createdFieldDefinition);

                            return $q.resolve(
                                getDisplayObjectForFieldDefinition(
                                    createdFieldDefinition,
                                    projectManager.groupsMap[$scope.data.groupId].name,
                                    false,
                                ),
                            );
                        });
                }
            } else {
                // Find the selected field in the fieldOptions array by the given name.
                const matchedFields = $scope.data.fieldOptions.filter(
                    (fieldOption) => !fieldOption.isSpecialField && fieldOption.name === field.name,
                );
                if (matchedFields && matchedFields.length) {
                    getSelectedFieldPromise = $q.resolve(matchedFields[0]);
                } else {
                    // In case field doesn't exist
                    const selectedField = {
                        swapWithCreatedFieldDefinition: true,
                        key: field.name,
                        name: field.name,
                        label: field.displayName,
                        fieldLabel: field.fieldLabel,
                        entity: $scope.data?.selectedField?.entity || $scope.data.externalType,
                        type: field.type,
                        values: field.possibleValues,
                        externalType: $scope.data.inspectEntityType
                            ? $scope.data.inspectEntityType
                            : $scope.data.externalType,
                        projectIntegrationId: $scope.data.projectIntegration.id,
                        source: $scope.data.projectIntegration.integration.integrationType.toLowerCase(),
                    };

                    getSelectedFieldPromise = $q.resolve(selectedField);
                }
            }

            getSelectedFieldPromise.then((selectedField) => {
                $scope.data.selectedField = selectedField;
                $scope.fieldSelectionChange(false);
                // Close the popover.
                $scope.data.fieldInspectPopoverOpen = false;
            });
        }
    };

    $scope.clearSelection = function () {
        $scope.data.selectedField = clearSelectionItem;
        $scope.fieldSelectionChange(false);
    };

    /**
     * If the given search query is not found in the data.fieldOptions array, the query itself becomes an item
     * in the array if allowCustomInput is enabled. Otherwise the supported entities array is returned.
     * Also adds a clear-selection item if ctrl.allowClearSelection is enabled.
     * @param searchQuery - the user's current search query.
     * @returns {*} - an array of supported entities.
     */
    $scope.getFieldOptions = function (searchQuery) {
        // Don't get and return any option if the inspect auto open is enabled.
        // We don't show the dropdown in that case, but only the field inspect modal/popover.
        if ($scope.data.fieldInspectAutoOpen) {
            return null;
        }

        // Update the searchQuery so we can pass it to the tnkFieldInspect component if needed.
        if (!utils.isNullOrEmpty(searchQuery)) {
            $scope.data.searchQuery = searchQuery;
        } else if (!$scope.data.selectedField) {
            // Only clear the searchQuery if it's empty and no field is selected.
            // The query can be cleared when the user selects something.
            $scope.data.searchQuery = null;
        }

        // To avoid infinite $digest loops, we always work with the same reference - the $scope.data.fieldOptions array.
        let fields = $scope.data.fieldOptions;

        utils.resetArrayValues(fields, $scope.data.originalFieldOptions);

        // If a filter function exists, filter fields with it
        if ($scope.data.fieldsFilter) {
            fields = $scope.data.fieldsFilter(fields);
        }

        if ($scope.data.sortBy) {
            fields = fields.sort((first, second) => $scope.data.sortBy({ first, second }));
        }

        if (ctrl.allowCustomInput && searchQuery) {
            const searchQueryLower = searchQuery.toLowerCase();
            const searchMatches = fields.filter(function (field) {
                return field.label && field.label.toLowerCase() === searchQueryLower;
            });

            // If the search query is not found in the given entities, add the search query as an option and return it.
            if (!searchMatches || !searchMatches.length) {
                // This is important that we change the existing object and not create a new object. Please see the documentation of searchedFieldItem for elaboration.
                searchedFieldItem.name = searchQuery;
                searchedFieldItem.label = searchQuery;
                searchedFieldItem.fieldType = ctrl.customInputType;

                fields.unshift(searchedFieldItem);
            }
        }

        // If we allow the user to clear the selection, we add an item to the beginning of the array that causes a clear (and has some different styling).
        if ($scope.data.allowClearSelection) {
            fields.unshift(clearSelectionItem);
        }

        // Filtering doNotIncludeFieldIdsSet.
        if ($scope.data.doNotIncludeFieldIdsSet) {
            fields = fields.filter((field) => !$scope.data.doNotIncludeFieldIdsSet[field.id]);
        }

        // If suggestion exist set suggestionSelectionItem label and push it as the last item.
        if ($scope.data.suggestionExists) {
            suggestionSelectionItem.label = `<div  class="field-selector-suggested-field"><span class="common-bold">SUGGESTED COLUMN</span><br><span class="common-color-black common-size-xxs common-bold">${suggestionSelectionItem.initialValue.label} - </span><a class="common-link-blue common-bold common-size-xxs"> Create new</a><br><span class="common-color-grey common-size-xxxxs">You can configure it later.</span></div></div>`;
            fields.push(suggestionSelectionItem);
        }

        if (!$scope.data.addFieldsQuickCreateOptions) {
            fields = fields.filter((field) => field.id !== 'TNK_OTHER');
        }

        if (!$scope.data.quickCreateMatchedEntity) {
            fields = fields.filter((field) => field.id !== 'TNK_OTHER_MATCHED_ENTITY');
        }

        // The search query is contained in our field options or the custom input feature is not enabled. Just return the field options.
        return fields;
    };

    $scope.toggleInspectPopover = function (skipAnalytics) {
        // Don't do anything if it's view only mode
        if ($scope.data.viewOnly) {
            return;
        }

        // If we're in mobile, we always open the modal and not the popover.
        if ($scope.$root.isMobile) {
            $scope.openFieldInspectModal(skipAnalytics);
            return;
        }

        if (!skipAnalytics) {
            analyticsWrapper.track('Toggle field inspect popover', {
                category: 'Field selector',
                label: $scope.data.fieldInspectPopoverOpen,
            });
        }
        $scope.data.fieldInspectPopoverOpen = !$scope.data.fieldInspectPopoverOpen;
    };

    $scope.openFieldInspectModal = function (
        skipAnalytics,
        idRelationFieldDefinitionId,
        creatingCustomTriggerId,
        performOnWorkerItem,
    ) {
        // Don't do anything if it's view only mode
        if ($scope.data.viewOnly) {
            return;
        }

        if (!skipAnalytics) {
            analyticsWrapper.track('Toggle field inspect modal', { category: 'Field selector', label: true });
        }
        const entityType = $scope.data.inspectEntityType ? $scope.data.inspectEntityType : $scope.data.externalType;

        modal
            .openFieldInspectModal(
                $scope.data.projectIntegration,
                entityType,
                $scope.data.searchQuery,
                $scope.onFieldSelectedInInspect,
                null,
                $scope.data.onlyUpdatableFieldDefinitions,
                null,
                null,
                $scope.data.fieldInspectFilter,
                $scope.data.workflowVersionId,
                null,
                null,
                null,
                null,
                idRelationFieldDefinitionId,
                creatingCustomTriggerId,
                performOnWorkerItem,
            )
            .catch(() => {
                $scope.data.selectedField = ctrl.selectedField;
            });
    };

    $scope.autoOpenFieldInspect = function (event) {
        if ($scope.data.fieldInspectPopover) {
            // Auto open the field inspect popover.
            $scope.toggleInspectPopover(true);
            // To keep the ui-select from "clicking" outside the popover, we stop event propagation.
            event.stopPropagation();
        } else if ($scope.data.fieldInspectModal) {
            // Auto open the field inspect modal.
            $scope.openFieldInspectModal(true);
        }
    };

    /**
     * Loads field options from the server.
     */
    function loadFieldOptions() {
        const loadingPromises = [];

        switch ($scope.data.itemsSource) {
            case $scope.data.itemsSourceTypes.external:
                loadingPromises.push(loadExternalFieldOptions());
                break;

            case $scope.data.itemsSourceTypes.column:
                loadingPromises.push(loadColumnFieldOptions());
                break;

            case $scope.data.itemsSourceTypes.custom:
                loadingPromises.push(
                    new Promise((resolve) => {
                        const customFieldsToAdd = ctrl.addCustomFields({
                            fieldOptions: $scope.data.fieldOptions,
                            projectIntegration: $scope.data.projectIntegration,
                            externalType: $scope.data.externalType,
                        });

                        $scope.data.originalFieldOptions = customFieldsToAdd;

                        return resolve(customFieldsToAdd);
                    }),
                );
                break;
        }

        if (!$scope.data.itemsSource && $scope.data.inspectEntityType && $scope.data.projectIntegration.id) {
            loadingPromises.push(loadExternalFieldForTyping());
        }

        if (loadingPromises.length) {
            $scope.data.loadingFieldOptions = true;

            return Promise.all(loadingPromises)
                .then((promisesAnswers) => {
                    $scope.data.fieldOptions = promisesAnswers.flat();

                    // Adding custom fields if needed.
                    const customFieldsToAdd = ctrl.addCustomFields({
                        fieldOptions: $scope.data.fieldOptions,
                        projectIntegration: $scope.data.projectIntegration,
                        externalType: $scope.data.externalType,
                    });

                    if (customFieldsToAdd && customFieldsToAdd.length) {
                        $scope.data.fieldOptions = $scope.data.fieldOptions.concat(customFieldsToAdd);
                    }

                    $scope.data.fieldOptionsMap = utils.createMapFromArray($scope.data.fieldOptions, 'id');

                    if (!$scope.data.selectedField && $scope.data.autoFillFieldName) {
                        autoFillField();
                    }

                    // Calling the onFieldOptionsLoaded as we are finished loading.
                    if (ctrl.onFieldOptionsLoaded) {
                        ctrl.onFieldOptionsLoaded({ fieldOptions: $scope.data.fieldOptions });
                    }
                })
                .finally(() => {
                    $timeout(function () {
                        $scope.data.loadingFieldOptions = false;
                    });
                });
        } else {
            return $q.resolve();
        }
    }

    /**
     * Loads field options for column items source.
     */
    function loadColumnFieldOptions() {
        const fieldsAndStates = getColumnsAndStatesOptionsForDefinitions(
            $scope.data.groupId,
            $scope.data.considerAllGroups,
            customFieldsManager.selectedColumnFieldsMap,
            customFieldsManager.selectedGlobalFieldsMap,
            projectManager.groupsMap,
            $scope.data.includeGlobalFields,
            $scope.data.workflowVersionId,
            workflowVersionManager.workflowVersionIdToWorkflowVersionMap,
            workflowVersionManager,
            false,
            $scope.data.onlyIdRelationField,
        );

        // If only column field definitions is requested, we do nothing more.
        if ($scope.data.onlyColumnFieldDefinitions) {
            return fieldsAndStates.fields;
        }

        const specialFields = getSpecialFieldsForFeatures(
            !$scope.data.specialFieldsForFeatures || !$scope.data.specialFieldsForFeatures.length,
            $scope.data.specialFieldsForFeatures,
        );
        // Setting the values to status field if there is such a field.
        const statusFields = specialFields.filter(
            (specialField) => specialField.id === 'TNK_STAGE' || specialField.id === 'TNK_STATUS_TEXT',
        );

        if (statusFields && statusFields.length) {
            for (const statusField of statusFields) {
                statusField.values = fieldsAndStates.states;
            }
        }

        const existingFieldsIds = new Set(fieldsAndStates.fields.map((field) => field.id));

        // We wants to take from the cache just the fields from fieldsAndStates.fields
        // The reason we take it from the cache is because we have the enriched fields there.
        const allExistingFields = utils
            .objToArray($scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId])
            ?.map((kvp) => kvp.value)
            .filter((f) => !f.systemUtilized && existingFieldsIds.has(f.id));

        // build fields view contains of custom, matched entities and special fields
        const fieldByEntityMapping = FieldsClassifier(
            $scope.data.workflowVersionId,
            customTriggerManager,
            allExistingFields,
            syncConfigCacheManager,
        );

        const fieldIdToDefinition =
            allExistingFields &&
            utils.createMapFromArray(allExistingFields, (field) => {
                if (field.idRelationField) {
                    return field.id;
                }
            });

        const customTriggerIdToCustomTrigger = customTriggerManager.getCachedCustomTriggerMapByWorkflowVersionId(
            $scope.data.workflowVersionId,
        );

        const allFields = $scope.data.onlyIdRelationField
            ? getMatchedEntitiesQuickCreateFields(false)
            : [
                  ...handleCustomFields(fieldByEntityMapping.customFields),
                  ...handleGlobalFields(fieldByEntityMapping.globalFields),
                  ...handleFormulaFields(fieldByEntityMapping.formulaFields),
                  ...handleRootItemsFields(fieldByEntityMapping.rootItemFields),
                  ...handleManualMatchedFields(
                      fieldByEntityMapping.idRelationFieldsDefinitionIdToFields,
                      fieldIdToDefinition,
                  ),
                  ...handleMatchedEntitiesFromCustomTrigger(
                      fieldByEntityMapping.fieldFromCustomTriggers,
                      customTriggerIdToCustomTrigger,
                  ),
                  ...(!$scope.data.doNotDisplaySpecialFields ? handleSpecialFields(specialFields) : []),
                  $scope.data.considerAllGroups
                      ? {
                            name: 'groupId',
                            label: 'List',
                            type: 'List',
                            fromOriginalEntity: false,
                            values: projectManager.groups.map((group) => group.id),
                            possibleValuesMap: utils.createMapFromArray(projectManager.groups, 'id', null, 'name'),
                        }
                      : [],
              ];

        if ($scope.data.onlyUpdatableFieldDefinitions) {
            const updatableFields = allFields.filter((fieldDef) => fieldDef.updateable);
            $scope.data.originalFieldOptions = updatableFields;

            return updatableFields;
        }

        $scope.data.originalFieldOptions = allFields;

        return $q.resolve(allFields);
    }

    function getMatchedEntitiesQuickCreateFields(shouldQuickCreateField = false) {
        const matchedEntitiesFields = customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId]
            .filter((field) => field.idRelationField)
            .map((field) => {
                const currentCustomTrigger = field.linkedCustomTrigger?.id
                    ? customTriggerManager.getCachedCustomTrigger(
                          $scope.data.workflowVersionId,
                          field.linkedCustomTrigger.id,
                      )
                    : undefined;

                return {
                    ...field,
                    quickCreationField: shouldQuickCreateField,
                    groupBy: 'Matched Entities',
                    label: field.name,
                    projectIntegrationId:
                        field?.projectIntegration?.id ||
                        currentCustomTrigger?.projectIntegrationIds?.[0] ||
                        field.projectIntegrationId,
                    entity:
                        field?.definition?.fieldMetadata?.entity ||
                        currentCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition?.performOnEntity
                            ?.entity,
                    name: `${field?.definition?.integrationType}_${field.entity}_EXTERNAL_INTEGRATION`,
                    definition: currentCustomTrigger
                        ? { ...field?.definition, integrationType: currentCustomTrigger.integrationType }
                        : field.definition,
                    source:
                        currentCustomTrigger?.integrationType?.toLowerCase() ||
                        field?.definition?.integrationType?.toLowerCase() ||
                        field.source,
                };
            });

        // Addition of option to create new matched entity field
        const newMatchedEntityField = getSpecialFieldsForFeatures(false, ['OTHER_MATCHED_ENTITY_ONLY']);

        const projectIntegration = syncConfigCacheManager.getSyncConfig(
            $scope.data.workflowVersionId,
        )?.projectIntegration;
        const rootField = projectIntegration ? getRootField(projectIntegration) : undefined;

        // Root field can be undefined so we use filter Boolean
        return [rootField, ...matchedEntitiesFields, ...newMatchedEntityField].filter(Boolean);
    }

    function getRootField(projectIntegration) {
        const rootEntityMetadata = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId)?.viewData
            ?.entityMetadata;
        const allRootEntitiesFromCustomField = integrations.generateIntegrationCustomFields(projectIntegration, []);
        const rootField = allRootEntitiesFromCustomField.find((field) =>
            rootEntityMetadata?.additionalIdentifyingNames?.includes(field.entity),
        );
        if (rootField) {
            rootField.performOnWorkerItem = true;
            rootField.label = `${rootField.entity} (Root)`;
            rootField.groupBy = 'Matched Entities';
        }
        return rootField;
    }

    /**
     * Load the external fields to show when typing
     */
    function loadExternalFieldForTyping() {
        if (!$scope.data.inspectEntityType && !$scope.data.projectIntegration) {
            return Promise.resolve();
        }

        return tonkeanService
            .getAvailableExternalFields(
                $scope.data.projectIntegration.id,
                $scope.data.inspectEntityType,
                $scope.data.includeViewerFields,
            )
            .then(
                ({ entitiesWithLabels }) =>
                    ($scope.data.fieldOptions = [
                        ...$scope.data.fieldOptions,
                        entitiesWithLabels.map((field) => {
                            // Let the code know that the field needs to be generated if selected
                            field.virtualField = true;
                            // Show {entity}: {name}
                            field.displayEntity = true;
                            // Show the integration icon
                            field.source = $scope.data.projectIntegration.integrationType.toLowerCase();
                            // Show title before the fields in the dropdown
                            field.groupBy = $scope.data.projectIntegration.name;
                            return field;
                        }),
                    ]),
            );
    }

    function handleSpecialFields(specialFields) {
        return specialFields.map((field) => ({ ...field, groupBy: 'Basic fields' }));
    }

    function handleFormulaFields(formulaFields) {
        return formulaFields.map((field) => ({
            ...field,
            groupBy: 'Formula',
            label: field.name,
            name: field.id,
            globalFieldDefinition: false,
        }));
    }

    function handleManualMatchedFields(matchedEntitiesFields, fieldIdToDefinition) {
        return Object.entries(matchedEntitiesFields).flatMap(([key, fields]) =>
            fields.map((field) => ({
                ...field,
                groupBy: fieldIdToDefinition[key].name,
                label: field.name,
                name: field.id,
                globalFieldDefinition: false,
                source: field?.definition?.integrationType?.toLowerCase(),
            })),
        );
    }

    function handleMatchedEntitiesFromCustomTrigger(matchedEntitiesFields, customTriggersMap) {
        return Object.entries(matchedEntitiesFields).flatMap(([key, fields]) =>
            fields
                .map((field) => {
                    if (customTriggersMap) {
                        const customTrigger = customTriggersMap[key];

                        if (customTrigger) {
                            return {
                                ...field,
                                groupBy: customTrigger.displayName,
                                label: field.name,
                                name: field.id,
                                globalFieldDefinition: false,
                                projectIntegrationId: customTrigger.projectIntegrationIds?.[0],
                                customTriggerName: customTrigger.displayName,
                                definition: {
                                    ...field.definition,
                                    integrationType: customTrigger.integrationType,
                                },
                                source: customTrigger?.integrationType?.toLowerCase() || field.source,
                                iconModClass: field.definition?.matchConfiguration?.isForMatchingItem
                                    ? 'mod-match'
                                    : undefined,
                            };
                        }
                    }

                    return null;
                })
                .filter((matchedEntity) => matchedEntity),
        );
    }

    function handleCustomFields(customFields) {
        return customFields.map((field) => ({
            ...field,
            groupBy: 'Custom',
            label: field.name,
            name: field.id,
            globalFieldDefinition: false,
            source: field?.definition?.integrationType?.toLowerCase(),
        }));
    }

    function handleGlobalFields(globalFields) {
        return globalFields.map((field) => ({
            ...field,
            groupBy: 'Global',
            label: field.name,
            name: field.id,
            globalFieldDefinition: false,
            source: field?.definition?.integrationType?.toLowerCase(),
        }));
    }

    function handleRootItemsFields(rootFields) {
        return rootFields.map((field) => ({
            ...field,
            groupBy: `${field?.definition?.entityMetadata?.pluralLabel || ''} (Root)`,
            label: field.name,
            name: field.id,
            globalFieldDefinition: false,
            source: field?.definition?.integrationType?.toLowerCase(),
        }));
    }

    /**
     * Loads the field options for external items source.
     */
    function loadExternalFieldOptions() {
        if (!$scope.data.externalType) {
            return;
        }

        const projectIntegration = $scope.data.projectIntegration;

        // Only do something if we have a project integration to get field options from.
        if (projectIntegration && projectIntegration.integration) {
            $scope.data.loadingFieldOptions = true;

            // Adding the default configured project integration and external type to the additional map,
            // so we will also request on it.
            if ($scope.data.additionalProjectIntegrationExternalTypesMap[projectIntegration.id]) {
                $scope.data.additionalProjectIntegrationExternalTypesMap[projectIntegration.id][
                    $scope.data.externalType
                ] = true;
            } else {
                $scope.data.additionalProjectIntegrationExternalTypesMap[projectIntegration.id] = {};
                $scope.data.additionalProjectIntegrationExternalTypesMap[projectIntegration.id][
                    $scope.data.externalType
                ] = true;
            }

            // Constructing the requests for fields for all given project integrations.
            const fieldsPromisesArray = [];
            for (const projectIntegrationId in $scope.data.additionalProjectIntegrationExternalTypesMap) {
                if ($scope.data.additionalProjectIntegrationExternalTypesMap.hasOwnProperty(projectIntegrationId)) {
                    const externalTypes = [];
                    for (const externalType in $scope.data.additionalProjectIntegrationExternalTypesMap[
                        projectIntegrationId
                    ]) {
                        if (
                            $scope.data.additionalProjectIntegrationExternalTypesMap[
                                projectIntegrationId
                            ].hasOwnProperty(externalType)
                        ) {
                            externalTypes.push(externalType);
                        }
                    }
                    fieldsPromisesArray.push(
                        requestThrottler.do(
                            `fieldSelectorCtrl-${projectIntegrationId}-${externalTypes}`,
                            300,
                            () =>
                                tonkeanService.getAvailableExternalFields(
                                    projectIntegrationId,
                                    externalTypes,
                                    $scope.data.includeViewerFields,
                                ),
                            null,
                            null,
                            null,
                            true,
                        ),
                    );
                }
            }

            // We want to get all field options, so we set a high limit of 100 items.
            return $q
                .all(fieldsPromisesArray)
                .then((dataArray) => {
                    let finalFields = [];

                    // Gathering all returned fields (entitiesWithLabels).
                    let entitiesWithLabels = [];
                    const relevantDataArray = dataArray.filter(
                        (data) =>
                            !utils.isNullOrUndefined(data) &&
                            !utils.isNullOrUndefined(data.entitiesWithLabels) &&
                            data.entitiesWithLabels.length > 0,
                    );
                    for (const data of relevantDataArray) {
                        entitiesWithLabels = entitiesWithLabels.concat(data.entitiesWithLabels);
                    }

                    finalFields = finalFields.concat(entitiesWithLabels);

                    // Create a backup of the field options, since they get changed if the user is allowed to add a custom value.
                    $scope.data.originalFieldOptions = [...entitiesWithLabels];

                    // Filtering only updateable if needed.
                    if ($scope.data.onlyUpdatableFieldDefinitions) {
                        finalFields = finalFields.filter((fieldDef) => fieldDef.updateable);
                        $scope.data.originalFieldOptions = $scope.data.originalFieldOptions.filter(
                            (fieldDef) => fieldDef.updateable,
                        );
                    }

                    // When set to true, whenever field options are loaded (now) and one option's label matches the current label - the option will be auto selected.
                    if (ctrl.autoMatchOnOptions) {
                        // We try to search a match by the current selected field name.
                        let matchedOption = utils.findFirstCompareProperties(
                            finalFields,
                            $scope.data.selectedField,
                            'name',
                        );
                        // If we failed to match by name, we try to match by label.
                        if (!matchedOption) {
                            matchedOption = utils.findFirstCompareProperties(
                                finalFields,
                                $scope.data.selectedField,
                                'label',
                            );
                        }

                        if (matchedOption) {
                            $scope.data.selectedField = matchedOption;
                            $scope.fieldSelectionChange();
                        } else if (ctrl.clearSelectedIfNoMatchOnOptions) {
                            // if no match, and we don't allow custom, clear it.
                            $scope.data.selectedField = null;
                        }
                    }

                    return $q.resolve(finalFields);
                })
                .finally(function () {
                    $timeout(function () {
                        $scope.data.loadingFieldOptions = false;
                    });
                });
        }
    }

    /**
     * enrich selected field with source from the field options.
     */
    function enrichFieldWithSourceFromOptions() {
        if ($scope.data.selectedField && $scope.data.selectedField.name) {
            const optionalFields = $scope.data.fieldOptions.filter(
                (field) => field.id === $scope.data.selectedField.name,
            );
            if (optionalFields.length && optionalFields[0].source) {
                $scope.data.selectedField.source = optionalFields[0].source;
            }
        }
    }

    function autoFillField() {
        if ($scope.data.autoFillFieldName) {
            $scope.onFieldSelectedInInspect($scope.data.autoFillFieldName, true);

            if ($scope.data.selectedField) {
                $scope.data.didAutoFillField = true;
            }
        }
    }
}

angular.module('tonkean.app').controller('FieldSelectorCtrl', lateConstructController(FieldSelectorCtrl));
