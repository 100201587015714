import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionTestPageResponseViewTabType from './ProjectIntegrationActionsTestPageRequestView/ActionTestPageResponseViewTabType';
import tryPrettifyJsonText from '../../utils/tryPrettifyJsonText';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    DisabledTextareaWithExtendModal,
    Tab,
    Tabs,
    TabsBorderType,
} from '@tonkean/infrastructure';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    margin-top: 25px;
`;

interface Props {
    response: string | undefined;
}

const ProjectIntegrationActionSqlResponseView: React.FC<Props> = ({ response }) => {
    // State for the viewing Tab
    const [selectedTab, setSelectedTab] = useState(ActionTestPageResponseViewTabType.RESPONSE);

    const prettifiedResponse = useMemo(() => {
        return tryPrettifyJsonText(response || '');
    }, [response]);

    return (
        <>
            <Tabs
                showBackground={false}
                value={selectedTab}
                onChange={setSelectedTab}
                borderType={TabsBorderType.RAILWAY}
                height="46px"
            >
                <Tab label={ActionTestPageResponseViewTabType.RESPONSE}>
                    {ActionTestPageResponseViewTabType.RESPONSE}
                </Tab>
            </Tabs>
            <AnimatedSwitch activeLabel={selectedTab}>
                <StyledAnimatedSwitchItem label={ActionTestPageResponseViewTabType.RESPONSE}>
                    <DisabledTextareaWithExtendModal text={prettifiedResponse} width="560px" height="160px" />
                </StyledAnimatedSwitchItem>
            </AnimatedSwitch>
        </>
    );
};

export default ProjectIntegrationActionSqlResponseView;
