import React from 'react';

import type { EntitySelectorProps } from './EntitySelectorProps';
import TnkEntitySelector from './TnkEntitySelector';

const EntitySelector: React.FC<EntitySelectorProps> = ({ ...props }) => {
    return <TnkEntitySelector {...props} />;
};

export default EntitySelector;
