import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ToPascalCaseFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.TO_PASCAL_CASE;
    override readonly sign = 'ToPascalCase';
    override readonly displayName = 'To Pascal Case';
    override readonly description = 'Will change string to be in pascal case format.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Value',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
