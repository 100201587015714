import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

export const Cell = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    height: 50px;
    display: flex;
    align-items: center;
`;
