import { useMemo } from 'react';

import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { ItemGridRow } from '@tonkean/items-grid';
import {
    type FieldDefinitionKey,
    type ItemInterfaceWidget,
    SpecialFieldsKey,
    TonkeanType,
    tonkeanTypeToIdentifyingPrefixMap,
} from '@tonkean/tonkean-entities';

const useItemsValuesForCalculations = (
    items: ItemGridRow[] | undefined,
    widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>,
) => {
    return useMemo(() => {
        return (items || []).map((item) => {
            const specialFieldsEntries: [SpecialFieldsKey, string | number | Date | undefined][] = [
                [SpecialFieldsKey.TITLE, item.title],
                [SpecialFieldsKey.DUE_DATE, item.dueDate],
                [SpecialFieldsKey.CREATED_DATE, item.created],
                [SpecialFieldsKey.STATUS, item.state?.label],
            ];

            const customFieldsEntries: [FieldDefinitionKey, string | number | Date | undefined][] = Object.entries(item)
                .filter((entry): entry is [FieldDefinitionKey, string | number | Date | undefined] =>
                    entry[0].startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION]),
                )
                .filter(([key]) => !!widget.configuration.fields[key]);

            return Object.fromEntries([...specialFieldsEntries, ...customFieldsEntries]) as Record<
                FieldDefinitionKey,
                string | number | Date | undefined
            >;
        });
    }, [items, widget.configuration.fields]);
};

export default useItemsValuesForCalculations;
