import template from './tnkUser.template.html?angularjs';
/**
 * Created by ShaLi on 5/13/15.
 */

function tnkUser() {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            defaultOnEmpty: '=',

            // If given, will override whatever display name we take from user entity and we will use this.
            overrideDisplayName: '<',
        },
        template,
    };
}
angular.module('tonkean.app').directive('tnkUser', tnkUser);
