import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CustomFilters } from '@tonkean/angular-to-react-components';
import type { BasicQueryDefinition } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const AdvancedWrapper = styled.div`
    width: 300px;
`;

const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 8px;
    margin-top: 16px;
`;

const Clear = styled(Clickable)`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Theme.colors.gray_600};

    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    filters: BasicQueryDefinition | undefined;
    onFiltersChange(filters: BasicQueryDefinition | undefined): void;
    onCancel(): void;
}

const SolutionBusinessReportCustomFilters: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    filters,
    onFiltersChange,
    onCancel,
}) => {
    const controlRef = useRef({});

    const [tempFilters, setTempFilters] = useState(filters);
    useEffect(() => {
        setTempFilters(filters);
    }, [filters]);

    const clear = () => {
        controlRef.current['clearFiltersAndQueries']?.();
        setTempFilters(undefined);
    };

    return (
        <AdvancedWrapper>
            <CustomFilters
                control={controlRef.current}
                itemsSource="COLUMN"
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                specialFieldsForFeatures={['INNER_LIST_FILTER']}
                existingDefinition={tempFilters}
                onFiltersChangeDefinition={(query) => {
                    const validQuery =
                        controlRef.current['anyNonEmptyConditions']?.() || query.query.filters?.length !== 0;

                    setTempFilters(validQuery ? query : undefined);
                }}
                hideTimeRangeSelection
                hideCloseButton
                editMode
            />

            <Actions>
                <div>
                    <Clear onClick={clear}>Clear</Clear>
                </div>
                <Button size={ButtonSize.SMALL} onClick={onCancel} cancel>
                    Cancel
                </Button>
                <Button size={ButtonSize.SMALL} onClick={() => onFiltersChange(tempFilters)}>
                    Save
                </Button>
            </Actions>
        </AdvancedWrapper>
    );
};

export default SolutionBusinessReportCustomFilters;
