import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class GetInterfaceLinkByNameFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.GET_INTERFACE_LINK_BY_NAME;
    override readonly sign = 'GetInterfaceLinkByName';
    override readonly displayName = 'Get interface link by name';
    override readonly description = "Will get link to the interface by it's name.";
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Interface Name',
            dataType: [FieldType.String],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Clean mode',
            dataType: [FieldType.Boolean],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
