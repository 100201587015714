import { BaseWidgetHandler } from './BaseWidgetHandler';
import { getFieldsSummaryFromConfiguration } from '../utils/ItemInterfaceWidgetFieldsUtils';
import type { FieldsItemWidgetConfiguration } from '../widgets/FieldsWidgetModule';

import type { FieldDefinitionSummary, WidgetBase } from '@tonkean/tonkean-entities';

class FieldsItemsWidgetHandler extends BaseWidgetHandler {
    override getFieldsSummaryFromConfiguration(widget: WidgetBase): FieldDefinitionSummary[] {
        const configuration = widget.configuration as FieldsItemWidgetConfiguration;
        return getFieldsSummaryFromConfiguration(configuration);
    }
}

export default FieldsItemsWidgetHandler;
