import { useMemo } from 'react';

import type HistoryField from '../entities/HistoryField';
import type HistoryTableGroup from '../utils/HistoryTableGroup';

/**
 * React hook to filter the history table groups.
 *
 * @param fieldGroups - the unfiltered list of table groups.
 * @param freeTextFilter - the search term.
 * @param filterOnlyEmptyValue - should show only empty values?
 * @param filterOnlyValueChanged - should show only fields that have been changed?
 */
function useFilterHistoryFieldsTable(
    fieldGroups: HistoryTableGroup[],
    freeTextFilter: string,
    filterOnlyEmptyValue: boolean,
    filterOnlyValueChanged?: boolean,
): HistoryTableGroup[];

/**
 * React hook to filter the history table groups.
 *
 * @param fieldGroups - the unfiltered list of table groups.
 * @param freeTextFilter - the search term.
 * @param filterOnlyEmptyValue - should show only empty values?
 * @param filterOnlyValueChanged - should show only fields that have been changed?
 * @param filterInvolvedInAction - should show only fields that has been involved in the action?
 * @param relatedFieldsIds - list of field ids that were involced in the action.
 */
function useFilterHistoryFieldsTable(
    fieldGroups: HistoryTableGroup[],
    freeTextFilter: string,
    filterOnlyEmptyValue: boolean,
    filterOnlyValueChanged: boolean,
    filterInvolvedInAction: boolean,
    relatedFieldsIds: string[],
): HistoryTableGroup[];

function useFilterHistoryFieldsTable(
    fieldGroups: HistoryTableGroup[],
    freeTextFilter: string,
    filterOnlyEmptyValue: boolean,
    filterOnlyValueChanged: boolean = false,
    filterInvolvedInAction: boolean = false,
    relatedFieldsIds: string[] = [],
): HistoryTableGroup[] {
    const filteredTableGroups = useMemo(() => {
        const textFilter = (field: HistoryField) => {
            if (!freeTextFilter) {
                return true;
            }

            const isMatchingFilter = (value: string | undefined) => {
                return value?.toString().toLowerCase().includes(freeTextFilter.toLowerCase());
            };

            return (
                isMatchingFilter(field.displayName) ||
                isMatchingFilter(field.value.initialValue) ||
                isMatchingFilter(field.value.finalValue)
            );
        };

        const onlyEmptyFilter = (field: HistoryField) => {
            if (!filterOnlyEmptyValue) {
                return true;
            }

            return field.value.isEmpty;
        };

        const onlyChangedFilter = (field: HistoryField) => {
            if (!filterOnlyValueChanged) {
                return true;
            }

            return field.value.hasChanged;
        };

        const onlyInvolvedInActionFilter = (field: HistoryField) => {
            if (!filterInvolvedInAction || !relatedFieldsIds) {
                return true;
            }

            return relatedFieldsIds.includes(field.id);
        };

        return fieldGroups
            .map((group) => {
                const fields = group.fields
                    .filter(textFilter)
                    .filter(onlyEmptyFilter)
                    .filter(onlyChangedFilter)
                    .filter(onlyInvolvedInActionFilter)
                    .filter((field) => !field.isSystemUtilized);

                return { ...group, fields };
            })
            .filter((group) => group.fields.length > 0);
    }, [
        filterInvolvedInAction,
        filterOnlyEmptyValue,
        filterOnlyValueChanged,
        freeTextFilter,
        relatedFieldsIds,
        fieldGroups,
    ]);

    return filteredTableGroups;
}

export default useFilterHistoryFieldsTable;
