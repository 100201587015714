import template from './tnkNextStepsLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkNextStepsLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'NextStepsLogicConfigurationCtrl',
});
