import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { BusinessReportProperties, SolutionReport } from '../entities/SolutionReportType';
import { SolutionReportTypes } from '../entities/SolutionReportType';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { workflowVersionTypeToEnvironment } from '@tonkean/tonkean-entities';
import type { Group, SolutionBusinessReport } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessibilitySummary } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import type { SolutionBusinessReportFormSummary } from '@tonkean/tonkean-entities';

interface SolutionReportListInSolutionContextType {
    /**
     * Loading any information that is needed for the solution report list
     * (business reports, solution business reports and forms - aka actions)
     */
    isLoading: boolean;

    /**
     * Object mapping report id to its report object, as it is needed in the solution report list
     */
    allReports: Record<string, SolutionReport>;

    /**
     * The solution we are working on
     */
    workflowFolder: WorkflowFolder;

    /**
     * Full object of the solutionBusinessReport for edit modal
     */
    fullSolutionBusinessReportsById: Record<string, SolutionBusinessReport>;

    /**
     * Set function for after updating business report access
     * @param solutionReportId - Id of the updated report
     * @param newBusinessReportAccessibilityType - New access type
     * @param specificPeopleWithAccess - list of new specific people with access
     */
    setBusinessReportAccessibility(
        solutionReportId: string,
        newBusinessReportAccessibilityType: BusinessReportAccessType,
        specificPeopleWithAccess: string[],
    ): void;

    /**
     * Set function for after updating a solution business report
     * @param solutionBusinessReport - The update solution business report modal
     */
    setFullSolutionReport(solutionBusinessReport: SolutionBusinessReport): void;
}

const SolutionReportListContext = React.createContext<SolutionReportListInSolutionContextType>(
    {} as SolutionReportListInSolutionContextType,
);

export default SolutionReportListContext;

/**
 * Helper function to create the value for solution report list context
 * @param workflowFolder - The workflow folder in which to show the reports list
 */
export const useCreateSolutionReportListContextValue = (
    workflowFolder: WorkflowFolder,
): SolutionReportListInSolutionContextType => {
    const [projectId] = useGetStateParams<[string]>('projectId');
    const groupInfoManager = useAngularService('groupInfoManager');
    const [solutionReportsById, setSolutionReportsById] = useState<Record<string, SolutionReport>>({});
    const [businessReportsById, setBusinessReportsById] = useState<Record<string, SolutionReport>>({});
    const [fullSolutionBusinessReportsById, setFullSolutionBusinessReportsById] = useState<
        Record<string, SolutionBusinessReport>
    >({});

    const { data: allGroupsFromServer, loading: loadingGroups } = useAsyncMethod(
        groupInfoManager,
        'getGroupsByIds',
        workflowFolder.groupIds ?? [],
        false,
        false,
    );

    const { data: allProjectBusinessReportsFromServer, loading: businessReportsLoading } = useTonkeanService(
        'getPersonBusinessReports',
        projectId,
    );
    const [{ data: allProjectSolutionReportsFromServer, loading: reportsLoading }, getSolutionBusinessReports] =
        useLazyTonkeanService('getSolutionBusinessReports');

    const { data: solutionBusinessReportFormSummaries, loading: formsLoading } = useTonkeanService(
        'getWorkflowFolderFormsSummary',
        workflowFolder.id,
    );

    useEffect(() => {
        getSolutionBusinessReports(projectId);
    }, [getSolutionBusinessReports, projectId]);

    // When business reports and forms were loaded
    useEffect(() => {
        if (!allProjectBusinessReportsFromServer) {
            return;
        }

        setBusinessReportsById(
            Object.fromEntries(
                allProjectBusinessReportsFromServer.entities
                    .filter((businessReport) => workflowFolder.groupIds.includes(businessReport.groupId))
                    .map((businessReport: BusinessReportAccessibilitySummary) => [
                        businessReport.groupId,
                        convertFullBusinessReportToSolutionReportListItem(businessReport),
                    ]),
            ),
        );
    }, [allProjectBusinessReportsFromServer, workflowFolder.groupIds]);

    // When solution reports and forms were loaded
    useEffect(() => {
        if (!allProjectSolutionReportsFromServer || !solutionBusinessReportFormSummaries || !allGroupsFromServer) {
            return;
        }

        setFullSolutionBusinessReportsById(
            Object.fromEntries(allProjectSolutionReportsFromServer.map((report) => [report.id, report])),
        );

        setSolutionReportsById(
            Object.fromEntries(
                allProjectSolutionReportsFromServer
                    .filter((report) => report.solutionId === workflowFolder.id)
                    .map((report) => [
                        report.id,
                        convertFullSolutionReportToSolutionReportListItem(
                            report,
                            solutionBusinessReportFormSummaries.entities.filter(
                                (summary) => summary.solutionBusinessReportId === report.id,
                            ),
                            allGroupsFromServer,
                        ),
                    ]),
            ),
        );
    }, [
        allGroupsFromServer,
        allProjectSolutionReportsFromServer,
        solutionBusinessReportFormSummaries,
        workflowFolder.id,
    ]);

    const isLoading = businessReportsLoading || reportsLoading || formsLoading || loadingGroups;

    const allReports = useMemo<Record<string, SolutionReport>>(
        () => ({ ...businessReportsById, ...solutionReportsById }),
        [businessReportsById, solutionReportsById],
    );

    const setBusinessReportAccessibility = useCallback(
        (
            businessReportId: string,
            newBusinessReportAccessibilityType: BusinessReportAccessType,
            specificPeopleWithAccess: string[],
        ) => {
            setBusinessReportsById((oldBusinessReportsById: Record<string, SolutionReport>) => {
                if (
                    oldBusinessReportsById?.[businessReportId] === undefined ||
                    oldBusinessReportsById[businessReportId].type != SolutionReportTypes.BUSINESS
                ) {
                    return oldBusinessReportsById;
                }

                const updatedPermissions: BusinessReportAccessibilitySummary = {
                    ...oldBusinessReportsById[businessReportId].permissions,
                    businessReportAccessibilityType: newBusinessReportAccessibilityType,
                    specificPeopleWithAccess,
                };

                const updatedBusinessReport: BusinessReportProperties = {
                    ...oldBusinessReportsById[businessReportId],
                    permissions: updatedPermissions,
                };

                return {
                    ...oldBusinessReportsById,
                    [businessReportId]: updatedBusinessReport,
                };
            });
        },
        [setBusinessReportsById],
    );

    const setFullSolutionReport = useCallback(
        (newSolutionReport: SolutionBusinessReport) => {
            setFullSolutionBusinessReportsById((oldSolutionReportsBys) => ({
                ...oldSolutionReportsBys,
                [newSolutionReport.id]: newSolutionReport,
            }));
            setSolutionReportsById((oldSolutionReportById) => ({
                ...oldSolutionReportById,
                [newSolutionReport.id]: convertFullSolutionReportToSolutionReportListItem(
                    newSolutionReport,
                    solutionBusinessReportFormSummaries?.entities ?? [],
                    allGroupsFromServer ?? [],
                ),
            }));
        },
        [allGroupsFromServer, solutionBusinessReportFormSummaries?.entities],
    );

    return {
        isLoading,
        allReports,
        setBusinessReportAccessibility,
        workflowFolder,
        fullSolutionBusinessReportsById,
        setFullSolutionReport,
    };
};

const convertFullSolutionReportToSolutionReportListItem = (
    report: SolutionBusinessReport,
    forms: SolutionBusinessReportFormSummary[],
    possibleGroups: Group[],
): SolutionReport => ({
    id: report.id,
    displayName: report.displayName,
    actions: forms.map((summary) => summary.clientDisplayName || summary.displayName) ?? [],
    groupNames: possibleGroups
        .filter((group) => report.groups?.map(({ groupId }) => groupId).includes(group.id))
        .map(({ name }) => name),
    environments: [workflowVersionTypeToEnvironment[report.workflowVersionType]!],
    type: SolutionReportTypes.SOLUTION,
});

const convertFullBusinessReportToSolutionReportListItem = (
    businessReport: BusinessReportAccessibilitySummary,
): SolutionReport => ({
    id: businessReport.groupId,
    displayName: businessReport.groupName,
    actions: [], // all actions on a business report shouldn't be available and show 0 results
    groupNames: [businessReport.groupName],
    environments: ['build', 'production'],
    type: SolutionReportTypes.BUSINESS,
    permissions: {
        ...businessReport,
    },
});
