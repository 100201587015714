import type { ReactElement } from 'react';
import React, { useCallback, useState } from 'react';

import { TooltipZone } from '@tonkean/infrastructure';

interface Props {
    children: (
        setTooltip: (id: string | number, label: { label: string; subLabel?: string } | false) => void,
    ) => ReactElement;
}

const RegexTooltipZone: React.FC<Props> = ({ children }) => {
    const [activeGroupId, setActiveGroupId] = useState<string | number>();
    const [activeGroupLabel, setActiveGroupLabel] = useState<{ label: string; subLabel?: string }>({ label: '' });

    // If label is false, remove the tooltip
    const setTooltip = useCallback((id: string | number, label: { label: string; subLabel?: string } | false) => {
        if (!label) {
            setActiveGroupId((activeGroupId) => {
                // If the id is not the same as the id of the active tooltip,
                // it should not make any changes and keep the active tooltip
                // open.
                if (activeGroupId === id) {
                    return undefined;
                } else {
                    return activeGroupId;
                }
            });
        } else {
            setActiveGroupId(() => id);
            setActiveGroupLabel(() => label);
        }
    }, []);

    return (
        <TooltipZone
            tooltipContent={
                <>
                    <strong>{activeGroupLabel.label}</strong>
                    {activeGroupLabel.subLabel && <small>{activeGroupLabel.subLabel}</small>}
                </>
            }
            show={!!activeGroupId}
            tooltipClassName="tooltip-zone-tooltip tnk-tooltip-text react-tooltip"
        >
            {children(setTooltip)}
        </TooltipZone>
    );
};

export { RegexTooltipZone };
