import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import WorkfrontAuthenticationType, { authTypeToDisplayName } from './WorkfrontAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const CenteredLinkButton = styled.div`
    text-align: center;
`;

const authConfig = {
    authType: '',
    subdomain: '',
    apiVersion: '',
    apiKey: '',
    clientId: '',
    clientSecret: '',
    redirectUri: '',
    code: '',
};

// Default API Version
const defaultApiVersion = 'v14.0';

const WorkfrontAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authenticationType, setAuthenticationType] = useState<WorkfrontAuthenticationType>(
        WorkfrontAuthenticationType.API_KEY,
    );
    const [subdomain, setSubdomain] = useState<string>('');
    const [apiKey, setApiKey] = useState<string>('');
    const [apiVersion, setApiVersion] = useState<string>(defaultApiVersion);
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');

    const authenticateWithWorkfront = async () => {
        try {
            authConfig.subdomain = subdomain;
            authConfig.authType = authenticationType;
            authConfig.apiVersion = apiVersion;

            if (authenticationType === WorkfrontAuthenticationType.API_KEY) {
                authConfig.apiKey = apiKey;
                authenticate(authConfig);
            } else {
                authConfig.clientId = clientId;
                authConfig.clientSecret = clientSecret;
                // @ts-ignore
                authConfig.redirectUri = environment.integrationKeysMap.workfrontUri;

                const url = `https://${subdomain}.workfront.com/integrations/oauth2/authorize?client_id=${clientId}&redirect_uri=${authConfig.redirectUri}&response_type=code`;
                const state = oauthHandler.publicGetState();

                // @ts-ignore
                authConfig.code = await oauthHandler.startOAuth(url, state);
                authenticate(authConfig);
            }
        } catch {
            reportError(`Error occurred while authenticating with ${authTypeToDisplayName[authenticationType]}.`);
        }
    };

    return (
        <div>
            <div className="api-token-authentication">
                <CenteredAuthTypeTitle>
                    <h5>{authTypeToDisplayName[authenticationType]} Authentication</h5>
                </CenteredAuthTypeTitle>
                <div>
                    <div className="flex flex-col">
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Account URL</label>
                            <div className="col-sm-9 flex-vmiddle">
                                <span>https://</span>
                                <input
                                    type="text"
                                    value={subdomain}
                                    className="form-control margin-bottom-xs"
                                    id="api_subdomain"
                                    onChange={({ target }) => setSubdomain(target.value)}
                                    autoComplete="off"
                                    placeholder="Subdomain"
                                    required
                                />
                                <span>.workfront.com/</span>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 common-valign-middle control-label">API Version</label>
                            <div className="col-sm-7">
                                <input
                                    value={apiVersion}
                                    className="form-control"
                                    onChange={({ target }) => setApiVersion(target.value)}
                                    id="api_version"
                                    autoComplete="off"
                                    placeholder={defaultApiVersion}
                                    required
                                />
                            </div>
                        </div>

                        {authenticationType === WorkfrontAuthenticationType.API_KEY && (
                            <div className="form-group">
                                <label className="col-sm-3 control-label">API Key</label>
                                <div className="col-sm-7">
                                    <input
                                        value={apiKey}
                                        className="form-control"
                                        onChange={({ target }) => setApiKey(target.value)}
                                        id="api_key"
                                        autoComplete="off"
                                        placeholder="API Key"
                                        required
                                    />
                                </div>
                            </div>
                        )}

                        {authenticationType === WorkfrontAuthenticationType.CUSTOM_APP && (
                            <div className="flex flex-col">
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Client ID</label>
                                    <div className="col-sm-7">
                                        <input
                                            value={clientId}
                                            className="form-control"
                                            onChange={({ target }) => setClientId(target.value)}
                                            id="client_id"
                                            autoComplete="off"
                                            placeholder="Client ID"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Client Secret</label>
                                    <div className="col-sm-7">
                                        <input
                                            type="password"
                                            value={clientSecret}
                                            className="form-control"
                                            onChange={({ target }) => setClientSecret(target.value)}
                                            id="client_secret"
                                            autoComplete="off"
                                            placeholder="Client Secret"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Connect button*/}
                        <div className="flex mod-center margin-top-20">
                            <button className="btn btn-secondary btn-lg" onClick={authenticateWithWorkfront}>
                                Authenticate with {integrationConfiguration.displayName}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <CenteredLinkButton className="form-group margin-top-20">
                        <a
                            onClick={() =>
                                setAuthenticationType(
                                    authenticationType === WorkfrontAuthenticationType.API_KEY
                                        ? WorkfrontAuthenticationType.CUSTOM_APP
                                        : WorkfrontAuthenticationType.API_KEY,
                                )
                            }
                        >
                            Use&nbsp;
                            {authenticationType === WorkfrontAuthenticationType.API_KEY
                                ? authTypeToDisplayName[WorkfrontAuthenticationType.CUSTOM_APP]
                                : authTypeToDisplayName[WorkfrontAuthenticationType.API_KEY]}
                            &nbsp;Authentication
                        </a>
                    </CenteredLinkButton>
                </div>
            </div>
        </div>
    );
};

export default WorkfrontAuthenticationComponent;
