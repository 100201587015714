import { useAngularService } from 'angulareact';
import React, { useState } from 'react';

import { FormPageModal } from '@tonkean/angular-to-react-components';
import { TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { Form, Initiative, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    parentInitiativeId?: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    buttonBackground: string | undefined;
    displayText: string;
    openFormsInModal: boolean | undefined;
    form: Form;
    disabled?: boolean;
    disabledTooltip?: string;
    params?: any;
    onInitiativeCreated?: (initiativeCreated: Initiative) => void;
    workflowVersionType: WorkflowVersionType | undefined;
    onSubmitResolved?: () => void | undefined;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const OpenFormActionButton: React.FC<Props> = ({
    initiativeId,
    className,
    projectId,
    buttonBackground,
    displayText,
    openFormsInModal,
    form,
    disabled,
    disabledTooltip,
    params,
    parentInitiativeId,
    onInitiativeCreated,
    workflowVersionType,
    onSubmitResolved,
    originatedCustomTriggerId,
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const workflowVersionTypeFromCache =
        form.workflowVersion &&
        workflowVersionManager.getCachedWorkflowVersion(form.workflowVersion.id)?.workflowVersionType;

    const defaultParams = {
        initiativeId,
        projectId,
        tid: initiativeId,
    };

    const [formModalOpen, setFormModalOpen] = useState(false);

    return (
        <>
            <Tooltip
                dataAutomation="action-button-tooltip"
                placement="bottom"
                content={disabledTooltip}
                disabled={!disabled}
            >
                <UserThemedClickableButton
                    data-automation="action-button"
                    state="product.form"
                    params={params || defaultParams}
                    className={className}
                    background={buttonBackground}
                    onClick={(event) => {
                        if (openFormsInModal && !event.ctrlKey && !event.metaKey) {
                            setFormModalOpen(true);

                            //  Preventing default so that the regular open in new tab will not execute.
                            event.preventDefault();
                            event.stopPropagation();
                            return false;
                        }
                    }}
                    disabled={disabled}
                    openInNewTab
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {displayText}
                    </TextEllipsis>
                </UserThemedClickableButton>
            </Tooltip>
            {openFormsInModal && form && (
                <FormPageModal
                    open={formModalOpen}
                    onClose={() => setFormModalOpen(false)}
                    formId={form.id}
                    workflowVersionId={form.workflowVersion?.id}
                    formVersionType={workflowVersionType ?? workflowVersionTypeFromCache}
                    initiativeId={initiativeId}
                    onInitiativeCreated={({ createdInitiative }) => {
                        onInitiativeCreated?.(createdInitiative);
                    }}
                    parentInitiativeId={parentInitiativeId}
                    onSubmitResolved={onSubmitResolved}
                    createUsingCustomTriggerId={originatedCustomTriggerId}
                />
            )}
        </>
    );
};

export default OpenFormActionButton;
