import { useEffect, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';

/**
 * Utility for searching Background Processes using the query API.
 * @param groupId The group in which we are fetching.
 * @param workflowVersionType The environment type (== workflow version) of the items we're querying.
 * @param searchTerm Filter by given search text.
 * @param limit The amount of results we'd like to return.
 */
export default function useSearchBackgroundProcesses(
    groupId: string,
    workflowVersionType: WorkflowVersionType,
    searchTerm: string,
    limit: number,
) {
    const [backgroundProcesses, setBackgroundProcesses] = useState<any[]>([]);

    const [{ data: searchOptionsData, error, loading }, searchBackgroundProcessUsingQuery] = useLazyTonkeanService(
        'getBackgroundProcessesByWorkflowVersionType',
    );

    useEffect(() => {
        searchBackgroundProcessUsingQuery(groupId, workflowVersionType, searchTerm, undefined, undefined, 0, limit);
    }, [searchBackgroundProcessUsingQuery, searchTerm, limit, workflowVersionType, groupId]);

    useEffect(() => {
        if (searchOptionsData?.entities?.length && !loading && !error) {
            setBackgroundProcesses(searchOptionsData?.entities);
        }
    }, [searchOptionsData, loading, error]);

    return {
        backgroundProcesses,
        loading,
        error,
    };
}
