import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const UiPathAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
}) => {
    const [baseUrl, setBaseUrl] = useState<string>('https://cloud.uipath.com');
    const [userKey, setUserKey] = useState<string>();
    const [orgName, setOrgName] = useState<string>();
    const [tenantName, setTenantName] = useState<string>();
    const [clientId, setClientId] = useState<string>();

    const authenticateWithClientIdAndSecret = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                baseUrl,
                userKey,
                orgName,
                tenantName,
                clientId,
            };
            authenticate(config);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithClientIdAndSecret}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="api-base-url" className="col-sm-5 control-label">
                            Base URL
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={baseUrl}
                                className="form-control"
                                id="api-base-url"
                                onChange={({ target }) => setBaseUrl(target.value)}
                                autoComplete="off"
                                placeholder="Base URL"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-org-name" className="col-sm-5 control-label">
                            Account Name (Organization)
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={orgName}
                                className="form-control"
                                id="api-org-name"
                                onChange={({ target }) => setOrgName(target.value)}
                                autoComplete="off"
                                placeholder="Account Logical Name"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-tenant-name" className="col-sm-5 control-label">
                            Tenant Name
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={tenantName}
                                className="form-control"
                                id="api-tenant-name"
                                onChange={({ target }) => setTenantName(target.value)}
                                autoComplete="off"
                                placeholder="Tenant Name"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-5 control-label">
                            Client ID
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="text"
                                value={clientId}
                                className="form-control"
                                id="api-client-id"
                                onChange={({ target }) => setClientId(target.value)}
                                autoComplete="off"
                                placeholder="Client ID"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-user-key" className="col-sm-5 control-label">
                            User Key
                        </label>
                        <div className="col-sm-6">
                            <input
                                type="password"
                                value={userKey}
                                className="form-control"
                                onChange={({ target }) => setUserKey(target.value)}
                                id="api-user-key"
                                autoComplete="off"
                                placeholder="User Key"
                                required
                            />
                        </div>
                    </div>

                    <div className="margin-bottom-lg info-box common-size-xxs">
                        <p className="common-color-dark-grey">API Access Information:</p>
                        <div>
                            If the authentication settings for your organization are set to use Azure Active Directory,
                            the API Access button is not available.
                            <br />
                            In this case, you must either log in with a UiPath account instead of an Azure AD account,
                            or use the OAuth flow instead.
                        </div>
                        <div className="common-color-dark-grey margin-top-lg">
                            <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                <li>
                                    <div className="padding-left-xxs">Navigate to Admin &gt; Tenants.</div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">
                                        Expand the desired tenant to display the available services.
                                    </div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">
                                        Click API Access for the corresponding Orchestrator service to open the API
                                        Access details.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UiPathAuthenticationComponent;
