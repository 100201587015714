import React, { useMemo, useState } from 'react';

import DocumentPreviewModal from './DocumentPreviewModule/DocumentPreviewModal';
import FindWordsEditor from './FindWordsEditorModule/FindWordsEditor';
import type SpecificEditorProps from '../../entities/SpecificEditorProps';
import formulaTreeToExpression from '../../utils/formulaTreeToExpression';

/**
 * Find words operands.
 * The enum value is the index in the operands list.
 */
enum FindWordsOperand {
    INPUT = 0,
}

const SpecificEditorFindWords: React.FC<SpecificEditorProps> = ({
    groupId,
    workflowVersionId,
    exampleInitiativeId,
    operands,
    indexInTree,
    onFormulaDataChange,
}) => {
    const [showPreviewDocumentTextModal, setShowPreviewDocumentTextModal] = useState<boolean>(false);
    const [initiativeIdForPreview, setInitiativeIdForPreview] = useState<string | undefined>(exampleInitiativeId);
    const [expectedValueForPreview, setExpectedValueForPreview] = useState<string | undefined>(undefined);

    const { fieldDefinitionId, textExtractionFieldInformation } = useMemo(() => {
        const { variableId } = formulaTreeToExpression(operands[FindWordsOperand.INPUT]!);

        const { evaluatedExpression: beforeAnchorStringOptional } = formulaTreeToExpression(operands[1]!);
        const { evaluatedExpression: afterAnchorStringOptional } = formulaTreeToExpression(operands[2]!);

        return {
            fieldDefinitionId: variableId,
            textExtractionFieldInformation: {
                beforeAnchorStringOptional,
                afterAnchorStringOptional,
            },
        };
    }, [operands]);

    return (
        <div>
            <FindWordsEditor
                initiativeId={exampleInitiativeId}
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                fieldDefinitionId={fieldDefinitionId}
                textExtractionFieldInformation={textExtractionFieldInformation}
                onPreviewFullTextClicked={(initiativeId, expectedValue) => {
                    setInitiativeIdForPreview(initiativeId);
                    setExpectedValueForPreview(expectedValue);
                    setShowPreviewDocumentTextModal(true);
                }}
                indexInTree={indexInTree}
                onFormulaDataChange={onFormulaDataChange}
            />

            <DocumentPreviewModal
                open={showPreviewDocumentTextModal}
                initiativeId={initiativeIdForPreview}
                fieldDefinitionId={fieldDefinitionId}
                textExtractionFieldInformation={textExtractionFieldInformation}
                expectedValue={expectedValueForPreview}
                onClose={() => setShowPreviewDocumentTextModal(false)}
            />
        </div>
    );
};

export default SpecificEditorFindWords;
