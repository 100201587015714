import React, { useContext, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import WorkerRunsTableItemDescription from './WorkerRunsTableItemDescription';
import WorkerRunsTableItemGrid from './WorkerRunsTableItemGrid';
import WorkerRunsTableItemStageIcon from './WorkerRunsTableItemStageIcon';
import WorkerRunsTableItemStatus from './WorkerRunsTableItemStatus';
import HistoryContext from '../../../entities/HistoryContext';
import workerRunReasonConfig from '../../../entities/workerRunReasonConfig';
import workerRunStageConfig from '../../../entities/workerRunStageConfig';
import useGetWorkerRunsItemFormattedDate from '../../../hooks/useGetWorkerRunsItemFormattedDate';
import initialWorkerRunForDrillState from '../../../states/initialWorkerRunForDrillState';
import getWorkerRunDisplayTitle from '../../../utils/getWorkerRunDisplayTitle';

import { TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import { createWorkerRunInfo, getWorkerRunInfo } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.current.palette.history.workerRunsList;

const LINE_HEIGHT = 18;
const LINE_COUNT = 2;
const VERTICAL_MARGIN = 12;

const Row = styled(StateLink)<{ selected: boolean }>`
    background: ${({ selected }) => (selected ? Theme.colors.brightestGray : theme.backgroundColor)};
    border: none;
    text-align: left;
    padding: 0;
    margin: 0;

    &:hover,
    &:focus {
        text-decoration: none;
        background: ${Theme.colors.brightestGray};
        /* to show the focus shadow */
        position: relative;
        z-index: 1;
    }

    ${styledFocus}
`;

const TableItemsGrid = styled(WorkerRunsTableItemGrid)`
    height: ${LINE_HEIGHT * LINE_COUNT + VERTICAL_MARGIN * 2}px;
`;

export const TableItem = styled.div`
    display: flex;
    align-items: center;
    margin: ${VERTICAL_MARGIN}px 0;
    font-size: ${FontSize.SMALL_12};
    line-height: ${LINE_HEIGHT}px;
    color: ${Theme.colors.gray_800};
    overflow-wrap: break-word;

    &:first-child {
        padding-left: 20px;
    }
`;

const DateItem = styled(TableItem)`
    flex-direction: column;
    align-items: flex-start;
`;

const Date = styled.div`
    color: ${Theme.colors.gray_500};
`;

const Time = styled.div`
    color: ${Theme.colors.gray_700};
`;

interface Props {
    workerRun: WorkerRun;
}

const WorkerRunsTableItem: React.FC<Props> = ({ workerRun }) => {
    const {
        id: workerRunId,
        workerRunStage,
        startTime,
        updated,
        hasError,
        hasWarning,
        completed,
        workerRunReason,
    } = workerRun;
    const [initialWorkerRunForDrill, setInitialWorkerRunForDrill] = useRecoilState(initialWorkerRunForDrillState);
    const { state: currentState, updateState, getState } = useContext(HistoryContext);
    const selectedWorkerRunId = getWorkerRunInfo(currentState.workerRunInfo)?.id;
    const state = useMemo(
        () => getState({ workerRunInfo: createWorkerRunInfo(workerRunId, startTime), workerRunStage }),
        [getState, startTime, workerRunId, workerRunStage],
    );
    const selectWorkerRun = () => {
        updateState({ workerRunInfo: createWorkerRunInfo(workerRunId, startTime), workerRunStage });
        setInitialWorkerRunForDrill(undefined);
    };

    const { formattedDate, formattedTime, fullDate } = useGetWorkerRunsItemFormattedDate(updated);

    const title = useMemo(() => getWorkerRunDisplayTitle(workerRun), [workerRun]);

    const workerRunFailedReason = workerRunReasonConfig[workerRunReason].success === false;
    // If the worker run reason is of a failed reason, the has error should be true. Otherwise, use the workerRun.hasError.
    const hasErrors = workerRunFailedReason || hasError;

    return (
        <Row
            data-automation="worker-runs-table-item-line-in-table"
            state={state.name}
            params={state.params}
            selected={
                !initialWorkerRunForDrill
                    ? workerRunId === selectedWorkerRunId
                    : workerRunId === initialWorkerRunForDrill?.id
            }
            onClick={selectWorkerRun}
            dontChangeState
        >
            <TableItemsGrid>
                <Tooltip content={fullDate}>
                    <DateItem>
                        {formattedDate && (
                            <TextEllipsis numberOfLines={1}>
                                <Date>{formattedDate}</Date>
                            </TextEllipsis>
                        )}
                        <TextEllipsis numberOfLines={1}>
                            <Time>{formattedTime}</Time>
                        </TextEllipsis>
                    </DateItem>
                </Tooltip>
                <TableItem>
                    <Tooltip content={workerRunStageConfig[workerRunStage].singleName}>
                        <WorkerRunsTableItemStageIcon workerRun={workerRun} />
                    </Tooltip>

                    <WorkerRunsTableItemDescription workerRun={workerRun} numberOfLines={LINE_COUNT} />
                </TableItem>
                <TableItem data-automation="worker-runs-table-item-involved-item">
                    <TextEllipsis numberOfLines={LINE_COUNT} fullWords={false} tooltip>
                        {title}
                    </TextEllipsis>
                </TableItem>
                <TableItem>
                    <WorkerRunsTableItemStatus hasErrors={hasErrors} hasWarning={hasWarning} completed={completed} />
                </TableItem>
            </TableItemsGrid>
        </Row>
    );
};

export default React.memo(WorkerRunsTableItem);
