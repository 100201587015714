import UploadFilesDataStorageSelection from './UploadFilesDataStorageSelection';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkUploadFilesDataStorageSelection',
        reactToAngular(UploadFilesDataStorageSelection, [
            'groupId',
            'workflowVersionId',
            'onDataStorageChanged',
            'currentSelectedDataStorageId',
            'previewEvaluationSource',
            'currentFolderExpression',
            'validationObject',
            'form',
            'disabled',
        ]),
    );
