<!--Please remove this file after refactoring -->

<div>
  <h4>Connect a new data source:</h4>
  <!-- Native integration -->
  <tnk-integration-group ng-if="data.integrationType !== 'NO_CODE_DATA_SOURCE'"
                         sources="data.sources"
                         ng-model="ignored"
                         state="data.integrationState"
                         display-mode="row2"
                         open-modal-for-configuration="true"
                         display-new-prefix="false"
                         current-integration="data.currentIntegration"
                         integration-changed="onIntegrationChanged(changedProjectIntegration)"
                         integration-configuration-canceled="onIntegrationCancel()"
                         icon-url-override="data.marketplaceItem.iconUrl"
  ></tnk-integration-group>

  <!-- Marketplace Data Source -->
  <tnk-marketplace-data-source-tile
    ng-if="data.integrationType === 'NO_CODE_DATA_SOURCE'"
    marketplace-item-summary="data.marketplaceItem"
    on-cancel="(onIntegrationCancel)"
    on-change="(onIntegrationUpdate)"

  ></tnk-marketplace-data-source-tile>

</div>
