import { useCallback } from 'react';

import utils from '@tonkean/utils';

function useCopyToClipboard() {
    return useCallback(async (textToCopy: string) => {
        let copySucceeded: boolean = false;
        if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(textToCopy);
                copySucceeded = true;
            } catch {
                copySucceeded = false;
            }
        }

        if (!copySucceeded) {
            return utils.copyToClipboardFromText(textToCopy);
        }

        return copySucceeded;
    }, []);
}

export default useCopyToClipboard;
