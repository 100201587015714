import { TrackActions } from '@tonkean/flux';
import moment from 'moment';
import { SCIMTonkeanRole } from '@tonkean/tonkean-entities';
import { getEntitiesFromRelatedEntitiesMap, isInitiativeEntity, isInitiativeLinkEntity } from '@tonkean/tonkean-utils';
import { DeprecatedDate } from '@tonkean/utils';
import {
    DUE_DATE_EVALUATION_OPTIONS,
    DUMMY_ENTITY_ID,
    ETAG_TYPES,
    INITIATIVE_CHANGE_TYPES,
    OWNER_EVALUATION_OPTIONS,
} from '@tonkean/constants';

function TrackHelper(
    $q,
    $rootScope,
    $localStorage,
    $log,
    tonkeanService,
    entityHelper,
    entityInitiativeHelper,
    projectManager,
    authenticationService,
    utils,
    onBoardingManager,
    activityManager,
    inviteManager,
    fieldDisplay,
    initiativeCache,
    initiativeManager,
    personCache,
) {
    const _this = this;

    _this.idsStock = [];

    /**
     * Map of initiative ids as key and whether a request to be moved has been sent, but not replied yet as value.
     *
     * @type {Record<string, boolean>}
     */
    _this.initiativesBeingMovedList = {};

    /**
     * Clears all the caches of the track helper.
     */
    _this.clearCaches = function () {
        initiativeCache.clearCaches();
    };

    /**
     * Gets the cache of tracks by date (for calendars usages).
     */
    _this.getTracksByDateFromCache = function (dateToken) {
        return initiativeCache.getTracksByDateFromCache(dateToken);
    };

    /**
     * Gets the cache of tracks by group.
     */
    _this.getTracksByGroupIdFromCache = function (groupId) {
        return initiativeCache.getTracksByGroupIdFromCache(groupId);
    };

    /**
     * Returns the initiative with id of initiativeId from the cache, if such exists.
     */
    _this.getInitiativeFromCache = function (initiativeId) {
        return initiativeCache.getInitiativeFromCache(initiativeId);
    };

    /**
     * Removes given initiative id from the cache.
     */
    _this.removeInitiativeFromCache = function (initiativeId) {
        return initiativeCache.removeInitiativeFromCache(initiativeId);
    };

    /**
     * Returns the initiatives with given ids of initiativeIds from the cache, if they exist.
     */
    _this.getInitiativesFromCache = function (initiativeIds) {
        return initiativeCache.getInitiativesFromCache(initiativeIds);
    };

    /**
     * Returns true if given initiativeId is in our cache. False otherwise.
     */
    _this.cacheHasInitiative = function (initiativeId) {
        return initiativeCache.cacheHasInitiative(initiativeId);
    };

    /**
     * Gets the cache of initiatives.
     */
    _this.getCachedInitiatives = function () {
        return initiativeCache.getInitiativesCache();
    };

    _this.forceReloadInitiativeFromServer = function (initiativeId) {
        _this.getInitiativeById(initiativeId, true);
    };

    /**
     * Gets initiative by id.
     * If it exists in cache, will return it from the cache. Otherwise, will fetch it from the server and return.
     */
    _this.getInitiativeById = function (initiativeId, forceServer) {
        const cached = initiativeCache.getInitiativeFromCache(initiativeId);

        if (
            !forceServer &&
            !initiativeCache.shouldInvalidate(initiativeId) &&
            cached &&
            cached.created &&
            cached.isFullyLoaded
        ) {
            if (cached.parentInitiatives && cached.parentInitiatives.length) {
                cached.parentInitiatives = initiativeCache.cacheItems(cached.parentInitiatives, true);
            }

            return $q.resolve(cached);
        } else {
            return tonkeanService.getInitiativeById(initiativeId).then(function (data) {
                data.isFullyLoaded = true;
                initiativeCache.removeInitiativeFromCache(initiativeId);
                const initiative = initiativeCache.cacheItem(data, false, true, false, true, true);

                // cache the initiatives int the related items
                initiativeCache.cacheItems(getEntitiesFromRelatedEntitiesMap(data.relatedEntities));

                if (initiative.parentInitiatives && initiative.parentInitiatives.length) {
                    initiative.parentInitiatives = initiativeCache.cacheItems(initiative.parentInitiatives);
                }

                return $q.resolve(initiative);
            });
        }
    };

    /**
     * For the given initiative ids, sends a request to the server with the initiatives and their
     * etags as pairs. In response, will receive all the initiatives that their etags have changed.
     * Also, if there are groups that need to be reloaded (due to creation of root initiatives,
     * or due to movement of root initiatives), they will also return.
     */
    _this.cacheUpdatedInitiatives = function (
        projectId,
        changedInitiativesAmountIsTooBig,
        changedEntities,
        relatedEntities,
        changedGroupIds,
        currentlyViewedGroupId,
    ) {
        // If changed initiatives amount is too big, we fire an update-all event for all lists we have in cache.
        if (changedInitiativesAmountIsTooBig) {
            $log.debug('Changed initiatives amount is too big. Firing list update for - ');
            $log.debug(projectManager.groups.map((group) => group.id));

            $rootScope.$broadcast('fullListReload', {
                groupIds: projectManager.groups.map((group) => group.id),
            });

            // If change was too big, no entities were returned and we have nothing else left to do.
            return;
        }

        // Caching the initiatives and initiative links
        const initiativesOrInitiativeLinks = changedEntities.filter(
            (entity) => isInitiativeEntity(entity) || isInitiativeLinkEntity(entity),
        );
        const filteredInitiativesOrInitiativeLinks = initiativesOrInitiativeLinks.filter(
            (entity) => !currentlyViewedGroupId || entity.groupId === currentlyViewedGroupId,
        );
        const initiativesOrInitiativeLinksIds = initiativesOrInitiativeLinks.map((entity) => entity.id);

        if (initiativesOrInitiativeLinks && initiativesOrInitiativeLinks.length) {
            $log.debug('Received items to cache from polling!');
            $log.debug(initiativesOrInitiativeLinks);
        }
        if (filteredInitiativesOrInitiativeLinks && filteredInitiativesOrInitiativeLinks.length) {
            $log.debug('Caching from polling!');
            $log.debug(filteredInitiativesOrInitiativeLinks);

            cacheInitiativesAndInitiativeLinks(filteredInitiativesOrInitiativeLinks);
        }

        // If groups were changed, that means root initiatives were created or moved,
        // so we fire the groupListUpdated event with the group ids.
        if (changedGroupIds && changedGroupIds.length) {
            $log.debug('Groups change from polling - ');
            $log.debug(changedGroupIds);

            // Marking changed group ids on the root scope.
            $rootScope.groupIdsWereChangedByOthers = changedGroupIds;

            $rootScope.$broadcast('groupListUpdated', {
                groupIds: $rootScope.groupIdsWereChangedByOthers,
                changedInitiativeIds: initiativesOrInitiativeLinksIds,
            });
        }

        _this.getRelatedInitiativesCount(projectId, initiativesOrInitiativeLinksIds);
    };

    _this.getWeekViewInitiatives = function (
        projectId,
        dateRange,
        onlyGroupId,
        excludeExampleGroups,
        skip,
        limit,
        onlyDraftInitiatives,
        returnFlat,
    ) {
        if (!$rootScope.features[projectId].tonkean_feature_show_week_view) {
            return { initiatives: [] };
        }

        const fetchInitiativesPromise = tonkeanService.getWeekViewInitiatives(
            projectId,
            dateRange,
            onlyGroupId,
            excludeExampleGroups,
            skip,
            limit,
            onlyDraftInitiatives,
        );

        return fetchInitiativesPromise.then((data) => initiativeManager.getInitiativesInner(data, returnFlat));
    };

    /**
     * Fetches initiatives from the server.
     */
    _this.getSolutionReportInitiatives = function (
        projectId,
        excludeStatuses,
        dateRange,
        returnFlat,
        onlyGroupId,
        query,
        searchString,
        commonFilters,
        skip,
        limit,
        orderByField,
        orderByFieldType,
        orderByType,
        filterOnlyRootItems,
        onlyDraftInitiatives,
        solutionReportId,
    ) {
        const filters = {
            isArchived: false,
            groupId: onlyGroupId,
            conditionsQuery: query,
            searchString,
            filterByTags: commonFilters?.tags,
            filterByStateTexts: commonFilters?.statuses,
            filterByFunctions: commonFilters?.functions,
            filterByOwnerIds: commonFilters?.owners,
            filterByCreatorIds: commonFilters?.creators,
            excludeByInitiativeStatuses: excludeStatuses,
            skip,
            limit,
            orderByFieldId: orderByField,
            orderByFieldType,
            orderByType,
            isRootInitiative: filterOnlyRootItems,
            isDraftInitiatives: onlyDraftInitiatives,
            from: dateRange?.from,
            to: dateRange?.to,
        };

        return tonkeanService
            .getSolutionReportInitiatives(solutionReportId, projectId, filters)
            .then((data) => initiativeManager.getInitiativesInner(data, returnFlat));
    };

    _this.getRelatedInitiativesCount = function (projectId, initiativeIds) {
        if (initiativeIds.length === 0) {
            return $q.resolve();
        }

        const realInitiativeIds = initiativeCache
            .getInitiativesFromCache(initiativeIds)
            .map((initiativeFromCache) => initiativeFromCache.id);

        // Couldn't find related initiative ids in cache
        if (realInitiativeIds.length === 0) {
            return $q.resolve();
        }

        return tonkeanService
            .getRelatedInitiativesCount(projectId, realInitiativeIds)
            .then(({ initiatives: initiativesWithInnerItemsCount }) => {
                const updatedInitiatives = [];
                Object.entries(initiativesWithInnerItemsCount).map(([id, innerItemsCounts]) => {
                    const entity = initiativeCache.getInitiativeFromCache(id);
                    entity.innerItemsCount = innerItemsCounts.allInnerItemsCount;
                    entity.doneInnerItemsCount = innerItemsCounts.doneInnerItemsCount;
                    updatedInitiatives.push(entity);
                });

                if (updatedInitiatives.length > 0) {
                    const enrichedUpdatedInitiatives = updatedInitiatives.map((item) =>
                        entityInitiativeHelper.enrichInitiative(item),
                    );
                    initiativeCache.cacheItems(enrichedUpdatedInitiatives);
                }
            });
    };

    _this.getDuplicatedFormInitiatives = function (
        workflowVersionId,
        formId,
        parentId,
        shouldIncludeEdit,
        shouldDuplicate,
        shouldIncludeQuestion,
        allInitiatives,
        skip,
        limit,
    ) {
        return tonkeanService
            .getDuplicatedFormInitiatives(
                workflowVersionId,
                formId,
                parentId,
                shouldIncludeEdit,
                shouldDuplicate,
                shouldIncludeQuestion,
                allInitiatives,
                skip,
                limit,
            )
            .then(initiativeManager.getInitiativesInner);
    };

    /**
     * Get initiatives for guest user.
     */
    _this.getInitiativesByGuestToken = function (guestToken, limit) {
        return tonkeanService
            .getInitiativesByGuestToken(guestToken, limit, $localStorage.anonymousUserUniqueId)
            .then(initiativeManager.getInitiativesInner);
    };

    /**
     * Loads initiative's related initiatives.
     */
    _this.loadRelatedInitiatives = function (initiative, async, skip, pageSize, excludeStatuses) {
        initiative.relatedInitiatives = initiativeCache.cacheItems(initiative.relatedInitiatives, true);
        const loadRelatedInitiativesInnerPromise = loadRelatedInitiativesInner(
            initiative,
            skip,
            pageSize,
            excludeStatuses,
        );

        if (!async) {
            return loadRelatedInitiativesInnerPromise;
        } else {
            return $q.resolve(initiative);
        }
    };

    /**
     * Inner logic of load related initiatives.
     */
    function loadRelatedInitiativesInner(initiative, skip, pageSize, excludeStatuses) {
        if (!initiative || !initiative.hasRelatedInitiatives) {
            return $q.resolve(initiative);
        }

        // Caching item if it doesn't exist
        if (!initiativeCache.cacheHasInitiative(initiative.id)) {
            initiativeCache.cacheItem(initiative);
        }
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);

        return tonkeanService
            .searchInitiatives(initiative.project.id, {
                isArchived: false,
                skip,
                limit: pageSize || initiative.innerItemsCount * 2,
                isDraftInitiatives: initiative.isDraftInitiative,
                parentId: initiative.id,
                excludeByInitiativeStatuses: excludeStatuses,
            })
            .then(function (related) {
                cachedInitiative.hasMoreInnerItemsToLoad = related.hasMoreEntities;
                const cachedInnerItems = initiativeCache.cacheItems(related.entities, true, true, false, true, true);
                const uniqRelatedInitiatives = utils.distinctArrayById(
                    cachedInitiative.relatedInitiatives.concat(cachedInnerItems),
                );
                cachedInitiative.relatedInitiatives = uniqRelatedInitiatives;

                // Cache the initiatives in the related items, if there are any (initiativeCache.cacheItems itself will check that entities are initiatives)
                initiativeCache.cacheItems(getEntitiesFromRelatedEntitiesMap(related.relatedEntities));

                // Enriching the initiative now that we cached its related items
                entityHelper.enrichEntity(cachedInitiative);

                // Notify given initiative that it needs to re render (initiative would be the parent of the loaded related initiatives)
                TrackActions.trackRelatedItemsUpdated(cachedInitiative.id);

                // Notify the related items they need to re render
                for (let i = 0; i < cachedInitiative.relatedInitiatives.length; i++) {
                    TrackActions.trackDataUpdated(cachedInitiative.relatedInitiatives[i].id);
                }

                return $q.resolve(cachedInitiative);
            });
    }

    /**
     * Updates the tags of an initiative.
     */
    _this.updateTags = function (initiativeId, tags, throughSolutionBusinessReport, solutionBusinessReportId) {
        if (!initiativeId) {
            return;
        }

        // Prepare the tags array
        const filteredTagsArray = [];
        for (const tag of tags) {
            let name = tag.name || tag;
            name = name.replace('#', '').trim();
            if (name) {
                filteredTagsArray.push(name);
            }
        }

        // Update the tags immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        const operationName = 'updateTags';
        const operationId = setOperationId(cachedInitiative, operationName);

        cachedInitiative.tags = filteredTagsArray;
        updateDataETag(initiativeId, operationId);

        TrackActions.trackTagsUpdated(initiativeId);

        return tonkeanService
            .updateInitiativeTags(
                initiativeId,
                filteredTagsArray,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                handleCacheInitiativeFromServer(serverInitiative, INITIATIVE_CHANGE_TYPES.data);
                return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
            });
    };

    /**
     * Updates the function of given initiative.
     */
    _this.setFunction = function (initiative, func) {
        if (!initiative) {
            return;
        }

        // If no func sent, resetting the function of the initiative.
        if (!func) {
            return _this.setFunctionInner(initiative, null);
        }

        if (func.id && !func.isNew) {
            // If func has an id and it's not a new function, we just update the initiative with the function.

            return _this.setFunctionInner(initiative, func);
        } else {
            // Otherwise, it's a new function. We create it first, and then set the initiative with the resulting created function.

            return tonkeanService
                .getOrCreateFunctionByName(initiative.project.id, func.name.replace(new RegExp(' ', 'g'), '-'))
                .then(function (resultFunc) {
                    return _this.setFunctionInner(initiative, resultFunc);
                });
        }
    };

    /**
     * Inner logic of setting function to an initiative.
     */
    _this.setFunctionInner = function (initiative, func) {
        if (!initiative) {
            return;
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);
        const operationName = 'setFunctionInner';
        const operationId = setOperationId(cachedInitiative, operationName);

        cachedInitiative.function = func;
        updateDataETag(initiative.id, operationId);

        TrackActions.trackFunctionUpdated(initiative.id);

        return tonkeanService
            .setInitiativeFunction(initiative.id, func ? func.id : null, operationId)
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                handleCacheInitiativeFromServer(serverInitiative, INITIATIVE_CHANGE_TYPES.data);
                return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
            });
    };

    /**
     * Sets the owner of the given initiative to the single person according to the given ownerId.
     */
    _this.setOwnerById = function (initiative, ownerId, customMessage) {
        // Get the owner person from the cache.
        return personCache.getEntityById(ownerId, false, true).then((person) => {
            // Now set the owner and return.
            // setOwner expect an array or people (to take the first one from) or null if there's no owner.
            return _this.setOwner(initiative, person ? [person] : null, customMessage);
        });
    };

    /**
     * Sets the owner of the given initiative to the single person inside the given selectedPeople.
     */
    _this.setOwner = function (
        initiative,
        selectedPeople,
        customMessage,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (!initiative) {
            return $q.resolve(initiative);
        }

        // If no selected people, we update the owner to null.
        if (!selectedPeople || !selectedPeople.length) {
            return _this.setOwnerInner(
                initiative,
                null,
                customMessage,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            );
        }

        if (selectedPeople.length > 1) {
            $log.error('Owner cannot be multiple people.');
        }

        const person = selectedPeople[0];

        if (person.id) {
            // If it's an existing user in Tonkean, we just set him as the owner.

            return _this.setOwnerInner(
                initiative,
                person,
                customMessage,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            );
        } else {
            // Otherwise, we invite him to Tonkean, and then set him as the owner (invite makes him a user).

            const tempPeopleArray = [{ email: person.email, name: person.email.split('@')[0] }];
            return inviteManager.sendInvites(initiative.project.id, tempPeopleArray).then(function (data) {
                return _this.setOwnerInner(
                    initiative,
                    data.invites[0].person,
                    customMessage,
                    throughSolutionBusinessReport,
                    solutionBusinessReportId,
                );
            });
        }
    };

    /**
     * Sets the owner of given initiative to given person - does the actual server call.
     */
    _this.setOwnerInner = function (
        initiative,
        person,
        customMessage,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (!initiative) {
            return;
        }

        // Update the owner immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);
        const operationName = 'setOwnerInner';
        const operationId = setOperationId(cachedInitiative, operationName);
        const previousOwnerId = initiative.owner ? initiative.owner.id : null;

        if (person && person.projectContext && $rootScope.lps.isV2AndUserNotLicensed(person)) {
            person.projectContext.pendingSeatGrab = true;
        }

        cachedInitiative.owner = person;
        updateDataETag(initiative.id, operationId, 'setOwner');

        TrackActions.trackOwnerUpdated(initiative.id);

        return tonkeanService
            .updateInitiativeOwner(
                initiative.id,
                person ? person.id : null,
                customMessage,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }
                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );

                if (!cachedServerInitiative) {
                    // We decided not to cache the initiatives received from server.
                    // But, isVisibleToOwner is a server-calculated property, so we would
                    // still like to set it in the cached initiative, regardless of if we cached it.
                    cachedInitiative.isVisibleToOwner = serverInitiative.isVisibleToOwner;
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    cachedInitiative.nextGatherUpdateReason = serverInitiative.nextGatherUpdateReason;
                    TrackActions.trackVisibleToOwnerUpdated(initiative.id);
                }

                // if the current user is the owner, update the My Tracks number indicator
                if (person && person.id === authenticationService.currentUser.id) {
                    projectManager.updateOnlyMineInitiativesCount(true);
                } else if (!person && previousOwnerId === authenticationService.currentUser.id) {
                    projectManager.updateOnlyMineInitiativesCount(false);
                }

                // Once an owner has been set, we'd like to update everyone.
                $rootScope.$broadcast('ownerSet', {
                    initiativeId: initiative.id,
                });

                // If we set a non-null owner, we'd like to do some extra actions.
                if (person) {
                    // Complete onboarding step if owner was not null
                    onBoardingManager.completeStep('assignOwner');

                    // Complete firstNoGatherUpdate onboarding step if owner was not null and nextGatherUpdate is null.
                    // If the step is already completed, nothing will happen.
                    if (!cachedInitiative.nextGatherUpdate) {
                        onBoardingManager.completeStep('firstNoGatherUpdate', cachedInitiative);
                    }

                    // check if project is licensed but the new owner is not, try to add them
                    if (person && $rootScope.lps.isV2AndUserNotLicensed(person)) {
                        _this.tryAddSeatAndUpdateTracks(person.id);
                    }
                }

                return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
            });
    };

    /**
     * Goes over all the cached initiatives and uses the update action to update all the initiatives with the same owner as the given personId
     * @param personId
     * @param updateInitiativeAction - (initiative) => action
     */
    _this.updateAllTracksWithOwner = function (personId, updateInitiativeAction) {
        // Go over all the initiatives in the cache and update if it's the same person that was updated.
        const initiativeIdToInitiativeMap = initiativeCache.getInitiativesCache();
        for (const initiativeId in initiativeIdToInitiativeMap) {
            if (initiativeIdToInitiativeMap.hasOwnProperty(initiativeId)) {
                const cachedInitiative = initiativeIdToInitiativeMap[initiativeId];
                if (cachedInitiative && cachedInitiative.owner && cachedInitiative.owner.id === personId) {
                    updateInitiativeAction(cachedInitiative);
                }
            }
        }
    };

    /**
     * Sets the advanced configuration of the next gather updates of an initiative.
     * @param gatherDays {Array} An array containing strings of day names in which the bot should gather update. Pass null or empty for auto/smart gather.
     */
    _this.setGatherOptions = function (initiative, gatherDays) {
        if (!initiative) {
            return $q.reject('no initiative');
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);
        const operationStr = 'setGatherOptions';
        const operationId = setOperationId(cachedInitiative, operationStr);

        updateDataETag(initiative.id, operationId);
        entityHelper.enrichEntity(cachedInitiative);

        return tonkeanService
            .updateInitiativeGatherUpdatesPreferences(cachedInitiative.id, gatherDays, operationId)
            .then((serverInitiative) => {
                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationStr, operationId)) {
                    return cachedInitiative;
                }

                updateDataETag(cachedInitiative.id, serverInitiative.headerTonkeanETag);

                if (!cachedServerInitiative) {
                    cachedInitiative.gatherUpdatesPreferences = serverInitiative.gatherUpdatesPreferences;
                }

                entityHelper.enrichEntity(cachedInitiative);
                return $q.resolve(cachedInitiative);
            });
    };

    /**
     * A public function for updating a track's due date.
     */
    _this.updateDueDate = function (
        initiativeOrLinkId,
        date,
        post,
        includeTime,
        createdByFormId,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (!initiativeOrLinkId) {
            return $q.resolve();
        }

        // Fetching the cached initiative.
        const cachedInitiativeOrLink = initiativeCache.getInitiativeFromCache(initiativeOrLinkId);
        if (!cachedInitiativeOrLink) {
            return $q.resolve();
        }
        const realInitiative = initiativeCache.getRealTrack(cachedInitiativeOrLink);
        if (!realInitiative) {
            return $q.resolve();
        }

        // Removing old due date from cache. The remove function will take care of checking for existence of date to delete, and remove the linked .
        initiativeCache.removeDueDateCache(cachedInitiativeOrLink);

        // If set without time, changing the due date hour to be end of day (17:00).
        if (!includeTime && date) {
            date = new Date(date);
            date.setHours(17, 0, 0, 0); // set hour to exactly 17:00
        }

        // Update the due date immediately so the UI will update.
        const operationName = 'updateDueDate';
        const operationId = setOperationId(realInitiative, operationName);

        realInitiative.dueDate = date;
        initiativeCache.cacheDueDate(cachedInitiativeOrLink);

        TrackActions.trackDueDateUpdated(initiativeOrLinkId);

        // If we're requested not to post server with updated due date.
        if (!post) {
            return $q.resolve(realInitiative);
        } else {
            updateDataETag(realInitiative.id, operationId);
        }

        // Otherwise, we update the server.
        return tonkeanService
            .updateInitiativeDueDate(
                realInitiative.id,
                date ? new Date(date).getTime() : null,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then((serverInitiative) => {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }
                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );

                if (!cachedServerInitiative) {
                    // We decided not to cache server response.

                    // These properties are 'server-calculated' properties, so we should take them regardless.
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    TrackActions.trackNextGatherUpdatesUpdated(initiativeOrLinkId);

                    // Going over the related initiatives, and updating their next gather updates manually, and it is a server calculated property.
                    for (let i = 0; i < serverInitiative.relatedInitiatives.length; i++) {
                        const serverRelatedInitiative = serverInitiative.relatedInitiatives[i];
                        const cachedRelatedInitiative = initiativeCache.getInitiativeFromCache(
                            serverRelatedInitiative.id,
                        );

                        if (cachedRelatedInitiative) {
                            // Updating cached related initiative
                            cachedRelatedInitiative.nextGatherUpdate = serverRelatedInitiative.nextGatherUpdate;
                            // Notifying react about the change
                            TrackActions.trackNextGatherUpdatesUpdated(initiativeOrLinkId);
                        }
                    }
                }

                // If no owner, and you set the due date - set you (the updater) as the owner.
                if (!cachedInitiative.owner && !cachedInitiative.function) {
                    _this.setOwner(cachedInitiative, [authenticationService.currentUser]);
                }

                return $q.resolve(initiativeCache.getInitiativeFromCache(cachedInitiative.id));
            });
    };

    _this.updateStartTime = function (initiativeOrLinkId, date, includeTime) {
        if (!initiativeOrLinkId) {
            return $q.resolve();
        }

        // Fetching the cached initiative.
        const cachedInitiativeOrLink = initiativeCache.getInitiativeFromCache(initiativeOrLinkId);
        if (!cachedInitiativeOrLink) {
            return $q.resolve();
        }
        const realInitiative = initiativeCache.getRealTrack(cachedInitiativeOrLink);
        if (!realInitiative) {
            return $q.resolve();
        }

        // If set without time, changing the due date hour to be end of day (17:00).
        if (!includeTime && date) {
            date = new Date(date);
            date.setHours(17, 0, 0, 0); // set hour to exactly 17:00
        }

        // Update the due date immediately so the UI will update.
        const operationName = 'updateStartTime';
        const operationId = setOperationId(realInitiative, operationName);

        realInitiative.startTime = date;
        // initiativeCache.cacheDueDate(cachedInitiativeOrLink);

        TrackActions.trackStartTimeUpdated(initiativeOrLinkId);

        updateDataETag(realInitiative.id, operationId);

        // Otherwise, we update the server.
        return tonkeanService
            .updateInitiativeStartTime(realInitiative.id, date ? new Date(date).getTime() : null, operationId)
            .then((serverInitiative) => {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }
                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );

                if (!cachedServerInitiative) {
                    // We decided not to cache server response.

                    // These properties are 'server-calculated' properties, so we should take them regardless.
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    TrackActions.trackNextGatherUpdatesUpdated(initiativeOrLinkId);

                    // Going over the related initiatives, and updating their next gather updates manually, and it is a server calculated property.
                    for (let i = 0; i < serverInitiative.relatedInitiatives.length; i++) {
                        const serverRelatedInitiative = serverInitiative.relatedInitiatives[i];
                        const cachedRelatedInitiative = initiativeCache.getInitiativeFromCache(
                            serverRelatedInitiative.id,
                        );

                        if (cachedRelatedInitiative) {
                            // Updating cached related initiative
                            cachedRelatedInitiative.nextGatherUpdate = serverRelatedInitiative.nextGatherUpdate;
                            // Notifying react about the change
                            TrackActions.trackNextGatherUpdatesUpdated(initiativeOrLinkId);
                        }
                    }
                }

                // If no owner, and you set the due date - set you (the updater) as the owner.
                if (!cachedInitiative.owner && !cachedInitiative.function) {
                    _this.setOwner(cachedInitiative, [authenticationService.currentUser]);
                }

                return $q.resolve(initiativeCache.getInitiativeFromCache(cachedInitiative.id));
            });
    };

    /**
     * Updates the given initiative state to the given state (mix of state, text and eta).
     */
    _this.updateInitiativeState = function (
        initiative,
        state,
        text,
        eta,
        atMentionIds,
        createdByFormId,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        const parent = initiative.parent;
        let now = DeprecatedDate.nowAsDate();
        // hack: subtracting 1000 from now because angulars timeago think its in the future and the latest update
        // label is 'from now' instead of 'ago'
        now = now - 1000;
        let status = state.type || initiative.status;
        const previousStatus = initiative.status;

        // If someone updated the status, this is not Future anymore...
        if (status === 'FUTURE') {
            status = 'ACTIVE';
        }

        // If changing ETA, need to remove old ETA.
        if (eta || initiative.eta) {
            initiativeCache.removeEtaCache(initiative);
        }

        // Update the data immediately in the UI.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);
        const operationName = 'updateInitiativeState';
        const operationId = setOperationId(cachedInitiative, operationName);

        const wasInitiativeStatusUpdated = cachedInitiative.status !== status;

        cachedInitiative.eta = eta;
        cachedInitiative.status = status;
        cachedInitiative.isDone = status === 'DONE';
        cachedInitiative.stateText = state.label;
        cachedInitiative.stateColor = state.color;
        cachedInitiative.updateText = text;
        cachedInitiative.updated = now;
        cachedInitiative.stateUpdated = now;
        cachedInitiative.updater = authenticationService.currentUser;

        // Update parent done count if needed
        if (parent && wasInitiativeStatusUpdated) {
            const cachedInitiativeParent = initiativeCache.getInitiativeFromCache(parent.id);
            if (cachedInitiative.isDone) {
                cachedInitiativeParent.doneInnerItemsCount = (cachedInitiativeParent.doneInnerItemsCount || 0) + 1;
                initiativeCache.cacheItem(cachedInitiativeParent);
            } else if (previousStatus === 'DONE') {
                cachedInitiativeParent.doneInnerItemsCount -= 1;
                initiativeCache.cacheItem(cachedInitiativeParent);
            }
        }

        // Caching the eta of the initiative.
        initiativeCache.cacheDueDate(cachedInitiative);
        updateDataETag(initiative.id, operationId, 'updateInitiativeState');

        TrackActions.trackStatusUpdated(initiative.id);

        return tonkeanService
            .updateInitiativeState(
                initiative.id,
                status,
                text,
                eta,
                state.label,
                state.color,
                atMentionIds,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                // Updating project stats
                projectManager.getProjectData(true);

                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );

                if (!cachedServerInitiative) {
                    // We decided not to cache server response.

                    // This properties are 'server-calculated' properties, so we should take them regardless.
                    cachedInitiative.inInbox = serverInitiative.inInbox;
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    TrackActions.trackNextGatherUpdatesUpdated(cachedInitiative.id);
                }

                // If no owner, and you gave an update - set you (the updater) as the owner.
                if (!cachedInitiative.owner && !cachedInitiative.function) {
                    _this.setOwner(cachedInitiative, [cachedInitiative.updater]);
                }

                // Enriching the parent of the initiative, if there is such, for calculations based on children states.
                if (cachedInitiative.parent) {
                    _this.getInitiativeById(cachedInitiative.parent.id).then(function (item) {
                        if (item) {
                            entityInitiativeHelper.enrichInitiative(item);

                            // If status changed from done or to done update the parent item so he can render the done items count
                            if (status !== previousStatus && (previousStatus === 'DONE' || status === 'DONE')) {
                                TrackActions.trackDoneRelatedItemsUpdated(item.id);
                            }
                        }
                    });
                }

                TrackActions.trackStatusUpdatedCompleted(cachedInitiative.id);

                return $q.resolve(initiativeCache.getInitiativeFromCache(cachedInitiative.id));
            });
    };

    /**
     * Updates the next reminder of an initiative.
     */
    _this.updateNextReminder = function (initiative, daysFromNow, hour) {
        const dateTime = utils.getTimeDiffFromNow(daysFromNow, hour);

        return _this.updateNextReminderTime(initiative, dateTime);
    };

    /**
     * Updates the next reminder time of an initiative.
     */
    _this.updateNextReminderTime = function (initiative, dateTime, shouldRecalculate) {
        // Update the nextGatherUpdate immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiative.id);
        const operationName = 'updateNextReminderTime';
        const operationId = setOperationId(cachedInitiative, operationName);

        cachedInitiative.nextGatherUpdate = dateTime;
        cachedInitiative.nextGatherUpdateUpdater = { id: authenticationService.currentUser.id };
        updateDataETag(initiative.id, operationId);

        TrackActions.trackNextGatherUpdatesUpdated(initiative.id);

        return tonkeanService
            .updateNextReminder(initiative.id, dateTime, shouldRecalculate, operationId)
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                );
                if (!cachedServerInitiative) {
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    cachedInitiative.nextGatherUpdateReason = serverInitiative.nextGatherUpdateReason;
                }

                TrackActions.trackNextGatherUpdateCompleted(cachedInitiative.id);

                return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
            });
    };

    /**
     * Updates the title of given initiative.
     */
    _this.updateInitiativeTitle = function (
        initiativeId,
        newTitle,
        createdByFormId,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        // Update the title immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        const operationName = 'updateInitiativeTitle';
        const operationId = setOperationId(cachedInitiative, operationName);

        if (cachedInitiative.title === newTitle) {
            // If the cached initiative title is the same as the new title, we do nothing.
            return $q.resolve(cachedInitiative);
        } else {
            // Otherwise, we update the cache and the server.
            cachedInitiative.title = newTitle;
            // Also make sure the initiative is not marked as an example anymore.
            cachedInitiative.isExample = false;
            updateDataETag(initiativeId, operationId, 'updateTitle');

            TrackActions.trackTitleUpdated(cachedInitiative.id);

            return tonkeanService
                .updateInitiativeTitle(
                    cachedInitiative.id,
                    newTitle,
                    operationId,
                    throughSolutionBusinessReport,
                    solutionBusinessReportId,
                )
                .then((serverInitiative) => {
                    const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                    if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                        return $q.resolve(cachedInitiative);
                    }

                    handleCacheInitiativeFromServer(serverInitiative, INITIATIVE_CHANGE_TYPES.data);
                    return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
                });
        }
    };

    /**
     * Updates the description of given initiative.
     */
    _this.updateInitiativeDescription = function (
        initiativeId,
        newDescription,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        // Update the description immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        const operationName = 'updateInitiativeDescription';
        const operationId = setOperationId(cachedInitiative, operationName);

        cachedInitiative.description = newDescription;
        updateDataETag(initiativeId, operationId);

        TrackActions.trackDescriptionUpdated(initiativeId);

        return tonkeanService
            .updateInitiativeDescription(
                initiativeId,
                newDescription,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then((initiative) => {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                handleCacheInitiativeFromServer(initiative, INITIATIVE_CHANGE_TYPES.data);
                return $q.resolve(initiativeCache.getInitiativeFromCache(initiative.id));
            });
    };

    /**
     * Asks for updates from owner right now.
     */
    _this.askForInitiativeUpdates = function (initiativeId, customText, isExample) {
        return tonkeanService
            .askForInitiativeUpdates(initiativeId, customText, isExample)
            .then(function () {
                return $q.resolve();
            })
            .catch((error) => {
                if (
                    error &&
                    error.data &&
                    error.data.error &&
                    error.data.error.message &&
                    error.data.error.message === "Can't ask for updates. owner has no access to this track."
                ) {
                    return $q.reject(
                        `${error.data.error.message} Make sure the "Allow setting owners that are not collaborators" checkbox is checked or add this person as a collaborator.`,
                    );
                } else {
                    // re throw
                    throw error;
                }
            });
    };

    /**
     * Checks if the initiative is being moved
     *
     * @param initiativeId {string} - the initiative to check
     * @returns {boolean}
     */
    _this.isTheInitiativeBeingMoved = (initiativeId) => {
        return _this.initiativesBeingMovedList[initiativeId];
    };

    _this.isGuestOnly = (projectId) => {
        const userProjectContext =
            authenticationService.currentUser.projectContext ||
            authenticationService.currentUser.projectContexts[projectId];
        const isGuestOnly =
            userProjectContext?.calculatedTonkeanRoles?.includes(SCIMTonkeanRole.GUEST) &&
            userProjectContext?.calculatedTonkeanRoles?.length === 1;
        return isGuestOnly;
    };

    /**
     * Moves an initiative.
     *
     * @param initiativeId {string} - the id of the initiative to be moved.
     * @param oldParentId {string} - the id of the parent the item is moved from (or null if no old parent).
     * @param underId {string} - the id of the initiative the moved track will be placed under (or null if none exists).
     * @param newParentId {string} - the id of the parent the item is moved into (or null if no new parent).
     * @param isDrop {boolean} - whether the move is a consequence of a drop or not. This changes the placement of the dropped item in the new parent.
     * @param editorId {string} - the editorId to emit a moving event
     */
    _this.moveInitiative = function (
        initiativeId,
        oldParentId,
        underId,
        newParentId,
        isDrop,
        moveToGroupId,
        editorId,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (_this.isTheInitiativeBeingMoved(initiativeId)) {
            const msg = 'Wait for the completion of current move before doing another move.';

            $rootScope.$emit('alert', { msg });
            return Promise.reject(msg);
        }

        _this.initiativesBeingMovedList[initiativeId] = true;
        if (editorId) {
            TrackActions.trackMoving(initiativeId, editorId, true);
        }

        const cachedMovedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        const cachedOldParent = initiativeCache.getInitiativeFromCache(oldParentId);
        const cachedNewParent = initiativeCache.getInitiativeFromCache(newParentId);
        let cachedUnderInitiative = initiativeCache.getInitiativeFromCache(underId);

        // Saving original index so we can revert the change if the server returns an error.
        const originalIndex = cachedMovedInitiative.index;

        const moveId = utils.guid();
        cachedMovedInitiative.moveId = moveId;

        if (cachedOldParent) {
            cachedOldParent.moveId = moveId;

            if (cachedOldParent.relatedInitiatives) {
                // Find the index of the initiativeId we're moving, and remove it from the old parent's related initiatives.
                const index = utils.indexOf(cachedOldParent.relatedInitiatives, (item) => item.id === initiativeId);
                if (index > -1) {
                    cachedOldParent.relatedInitiatives.splice(index, 1);
                    // Update inner items metadata
                    _this.updateInnerItemsMetadataOnItemRemoved(cachedOldParent, cachedMovedInitiative);
                }
            }

            // We're being removed from an old parent, his whole parents legacy should be destroyed.
            cachedMovedInitiative.parent = null;
            cachedMovedInitiative.parentInitiatives = [];
        }

        if (cachedNewParent) {
            cachedNewParent.moveId = moveId;

            // Make sure the new parent has a related initiatives array.
            if (!cachedNewParent.relatedInitiatives) {
                cachedNewParent.relatedInitiatives = [];
            }

            // If we've got a valid underId - the id of the item underneath we are supposed to be placed.
            // Or: this is a drop inside a parent, we will place the item at the beginning (instead of in the end when tabbing in).
            if (cachedUnderInitiative || isDrop) {
                const underIdIndex = utils.indexOf(cachedNewParent.relatedInitiatives, (item) => item.id === underId);

                // If we didn't find the item, and this is a drop, we set the index as 0 - a drop was made to the 0 place of the parent's related initiatives.
                let insertToBeginning = false;
                if (underIdIndex === -1 && isDrop) {
                    insertToBeginning = true;
                    // Fake a cacheUnderInitiative so we'll have an index to take.
                    cachedUnderInitiative = { index: -1 };
                }

                // If the underIdIndex is valid for the new parent's related initiatives.
                if (
                    insertToBeginning ||
                    (underIdIndex > -1 && underIdIndex < cachedNewParent.relatedInitiatives.length - 1)
                ) {
                    // Order the new parent's related initiatives.
                    cachedMovedInitiative.index = cachedUnderInitiative.index + 1;

                    for (let i = 0; i < cachedNewParent.relatedInitiatives.length; i++) {
                        const currentItem = initiativeCache.getInitiativeFromCache(
                            cachedNewParent.relatedInitiatives[i].id,
                        );
                        if (currentItem.index >= cachedMovedInitiative.index) {
                            currentItem.index = currentItem.index + 1;
                        }
                    }

                    cachedNewParent.relatedInitiatives.splice(underIdIndex + 1, 0, cachedMovedInitiative);
                } else {
                    // The underId wasn't valid. Take the last index and increment it and push the moved item to the related initiatives.
                    if (cachedNewParent.relatedInitiatives.length) {
                        cachedMovedInitiative.index =
                            cachedNewParent.relatedInitiatives[cachedNewParent.relatedInitiatives.length - 1].index + 1;
                    } else {
                        cachedMovedInitiative.index = 0;
                    }
                    cachedNewParent.relatedInitiatives.push(cachedMovedInitiative);
                }
            } else {
                if (cachedNewParent.relatedInitiatives.length) {
                    cachedMovedInitiative.index =
                        cachedNewParent.relatedInitiatives[cachedNewParent.relatedInitiatives.length - 1].index + 1;
                } else {
                    cachedMovedInitiative.index = 0;
                }
                cachedNewParent.relatedInitiatives.push(cachedMovedInitiative);
            }

            // Update the moved item's parent and parents legacy.
            cachedMovedInitiative.parent = cachedNewParent;
            // Create a new reference for the cachedMovedInitiative's parent initiatives array.
            cachedMovedInitiative.parentInitiatives = [];
            if (cachedNewParent.parentInitiatives && cachedNewParent.parentInitiatives.length) {
                for (let i = 0; i < cachedNewParent.parentInitiatives.length; i++) {
                    // Try to take the initiative from cache, and only if it's not there take the actual object we have here.
                    cachedMovedInitiative.parentInitiatives.push(
                        initiativeCache.getInitiativeFromCache(cachedNewParent.parentInitiatives[i].id) ||
                            cachedNewParent.parentInitiatives[i],
                    );
                }
            }
            // The immediate parent is the last one in the array.
            cachedMovedInitiative.parentInitiatives.push(cachedNewParent);

            // Update new parent inner items count
            _this.updateInnerItemsMetadataOnItemAdded(cachedNewParent, cachedMovedInitiative);
        } else {
            // No new parent? Clear all parent references.
            cachedMovedInitiative.parent = null;
            cachedMovedInitiative.parentInitiatives = [];
        }

        // Re-rendering old parent.
        if (cachedOldParent) {
            initiativeCache.cacheItem(cachedOldParent);
            TrackActions.trackDataUpdated(oldParentId);
        }
        // Re-rendering new parent.
        if (cachedNewParent) {
            initiativeCache.cacheItem(cachedOldParent);
            TrackActions.trackDataUpdated(newParentId);
        }

        // If initiative doesn't have an old parent or a new parent, that means she was either moved into a group, or from a group into an initiative.
        // Either way, we update its group etags.
        if (!cachedOldParent || !cachedNewParent) {
            updateGroupETag(initiativeId, moveId);
        }
        // If initiative has old parent, we update its children etags.
        if (cachedOldParent) {
            updateChildrenETag(cachedOldParent.id, moveId);
        }
        // If initiative has a new parent and it's different from its old parent, we update the new parent's children etags as well.
        if (cachedNewParent && (!cachedOldParent || cachedOldParent.id !== cachedNewParent.id)) {
            updateChildrenETag(cachedNewParent.id, moveId);
        }

        let movePromise;
        if (_this.isGuestOnly(projectManager.project.id)) {
            movePromise = tonkeanService.guestMoveInitiative(
                initiativeId,
                oldParentId,
                underId,
                newParentId,
                moveToGroupId,
                moveId,
            );
        } else {
            movePromise = tonkeanService.moveInitiative(
                initiativeId,
                oldParentId,
                underId,
                newParentId,
                moveToGroupId,
                moveId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            );
        }

        return movePromise
            .then((data) => {
                if (!data) {
                    return $q.resolve(data);
                }

                // Returned entities from server call, and same entities from cache.
                const serverMovedEntity = data.movedEntity;
                const serverOldParent = data.oldParent;
                const serverNewParent = data.newParent;
                const cachedMovedEntity = initiativeCache.getInitiativeFromCache(initiativeId);
                const cachedOldParent = initiativeCache.getInitiativeFromCache(oldParentId);
                const cachedNewParent = initiativeCache.getInitiativeFromCache(newParentId);

                // Validating we're here because of the same move that caused the call. If we aren't we ignore.
                if (
                    (cachedMovedEntity && cachedMovedEntity.moveId !== moveId) ||
                    (cachedOldParent && cachedOldParent.moveId !== moveId) ||
                    (cachedNewParent && cachedNewParent.moveId !== moveId)
                ) {
                    return $q.resolve(data);
                }

                if (!serverOldParent || !serverNewParent) {
                    handleCacheInitiativeFromServer(serverMovedEntity, INITIATIVE_CHANGE_TYPES.group, data);
                }
                if (serverOldParent && serverOldParent.id === oldParentId) {
                    handleCacheInitiativeFromServer(serverOldParent, INITIATIVE_CHANGE_TYPES.children, data);
                }
                if (serverNewParent && serverNewParent.id === newParentId) {
                    handleCacheInitiativeFromServer(serverNewParent, INITIATIVE_CHANGE_TYPES.children, data);
                }

                return $q.resolve(data);
            })
            .catch(function (error) {
                // Reverting properties that have changed because of the move.
                cachedMovedInitiative.index = originalIndex;
                cachedMovedInitiative.parent = cachedOldParent;

                // Putting the item back inside the current list
                if (cachedOldParent && cachedOldParent.relatedInitiatives) {
                    cachedOldParent.relatedInitiatives.push(cachedMovedInitiative);
                }
                // Removing the item from the new parent's related initiatives.
                if (cachedNewParent && cachedNewParent.relatedInitiatives) {
                    utils.removeFirst(cachedNewParent.relatedInitiatives, (item) => item.id === initiativeId);
                }

                // Alerting user with an error
                $rootScope.$emit('alert', {
                    msg: `There was a problem moving track "${cachedMovedInitiative.title}"`,
                });

                $rootScope.$broadcast('groupListUpdated', { groupIds: [cachedMovedInitiative.groupId] });

                return $q.reject(error);
            })
            .finally(() => {
                _this.initiativesBeingMovedList[initiativeId] = false;
                if (editorId) {
                    TrackActions.trackMoving(initiativeId, editorId, false);
                }
            });
    };

    /**
     * Update inner items metadata when adding an item
     */
    _this.updateInnerItemsMetadataOnItemAdded = function (parentInitiativeId, addedItem) {
        parentInitiativeId.innerItemsCount = (parentInitiativeId.innerItemsCount || 0) + 1;
        parentInitiativeId.hasRelatedInitiatives = true;

        if (addedItem && addedItem.status === 'DONE') {
            parentInitiativeId.doneInnerItemsCount = (parent.doneInnerItemsCount || 0) + 1;
        }
        _this.cacheItemList([parentInitiativeId]);
    };

    /**
     * Update inner items metadata when removing an item
     */
    _this.updateInnerItemsMetadataOnItemRemoved = function (parentInitiativeId, addedItem) {
        parentInitiativeId.innerItemsCount -= 1;

        if (addedItem.status === 'DONE') {
            parentInitiativeId.doneInnerItemsCount -= 1;
        }
        if (parentInitiativeId.innerItemsCount === 0) {
            parentInitiativeId.hasRelatedInitiatives = false;
        }
        _this.cacheItemList([parentInitiativeId]);
    };

    /**
     * Caches all given items (initiatives or initiatives links or a mix between the two).
     */
    _this.cacheItemList = function (initiativesOrInitiativeLinks) {
        return initiativeCache.cacheItems(initiativesOrInitiativeLinks, false, true, true, true, true);
    };

    /**
     * Caches given item (initiative or initiative link).
     * Helper function for when we do not know if item is a single item or an array.
     */
    _this.cacheTrack = function (item) {
        if (!item) {
            return item;
        }

        if (Array.isArray(item)) {
            return initiativeCache.cacheItems(item, false, true, true, true, true);
        } else {
            return initiativeCache.cacheItem(item, false, true, true, true, true);
        }
    };

    /**
     * Generates a server-id.
     */
    _this.generateId = function () {
        if (!_this.idsStock || !_this.idsStock.length) {
            return null;
        }

        // Gets the first id in the stock out, removes it and returns that id.
        const id = _this.idsStock[0];
        _this.idsStock.splice(0, 1);
        _this.fillIdsStock(1);

        return id;
    };

    /**
     * Fills the stock of server ids.
     */
    _this.fillIdsStock = function (count, onlyIfMissing) {
        if (count && (!onlyIfMissing || _this.idsStock.length < count)) {
            return tonkeanService.generateInitiativeId(count).then(function (data) {
                for (let i = 0; i < data.ids.length; i++) {
                    _this.idsStock.push(data.ids[i]);
                }

                return $q.resolve(_this.idsStock);
            });
        } else {
            return $q.resolve(_this.idsStock);
        }
    };

    _this.cacheIdsStock = function (ids) {
        _this.idsStock = [..._this.idsStock, ...ids];
    };

    /**
     * Creates an initiative.
     */
    _this.createInitiative = function (
        baseObj,
        projectId,
        parentId,
        text,
        tags,
        atMentions,
        ownerId,
        underItemId,
        due,
        groupId,
        callback,
        dataTiles,
        onErrorCallback,
        createdInWorkerRunInformation,
        functions,
        description,
        eta,
        updateText,
        initiativeState,
        createdByFormId,
        createdByFormName,
        customTriggerId,
        workerRunId,
        inEditMode,
        isDraftInitiative,
        isFormQuestion,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        let title = text;
        const evaluatedFunctions = functions || [];

        const owner = { id: ownerId };

        // Cleaning the title from at mentions.
        if (atMentions) {
            for (const atMention of atMentions) {
                let name;
                // Use the property initialized manually
                if (atMention.atMentionName) {
                    name = atMention.atMentionName;
                } else {
                    // Create fallback, in case something goes wrong
                    name = atMention.name;
                }

                title = title.replace(name, '');
                title = title.replace(name.toLowerCase(), '');
                title = title.replace(name.toLowerCase().replace(' ', '-'), '');

                if (!atMention.email) {
                    // It's a function

                    evaluatedFunctions.push(atMention);
                } else if (!owner.id) {
                    // It's an owner

                    owner.id = atMention.id;
                    owner.name = atMention.name;
                    owner.temp = atMention.customEmail;
                }
            }
        }

        // Cleaning the tags from hash tags.
        if (tags) {
            for (const tag of tags) {
                if (tag) {
                    title = title.replace(`#${tag}`, '');
                    title = title.replace(`#${tag.toLowerCase()}`, '');
                }
            }
        }

        // Trimming title
        title = title.trim();

        let id;
        if (baseObj && baseObj.id && baseObj.id.includes('INIT')) {
            // means the obj has a real server id
            id = baseObj.id;

            baseObj.title = title;
            initiativeCache.cacheItem(baseObj);

            // update the title with the replaced title (trimmed + removed @ and #)
            if (title !== text) {
                TrackActions.trackTitleUpdated(id);
            }
        }

        const afterCreate = function (initiative) {
            if (!initiative) {
                return $q.resolve(initiative);
            }

            if (baseObj) {
                // baseObj is used to async update fields
                utils.copyEntityFields(initiative, baseObj);
            }

            // Update the due date (this is not a call from outside so we should call our inner logic to avoid double action triggers).
            _this.updateDueDate(baseObj ? baseObj.id : initiative.id, initiative.dueDate, false);

            if (callback) {
                callback(baseObj || initiative);
            }

            // Let the store know a track creation is done.
            TrackActions.trackCreateDone(initiative.id);

            // let everyone know
            $rootScope.$broadcast('initiativeCreated', {
                initiativeId: initiative.id,
            });

            if (baseObj) {
                return $q.resolve(baseObj);
            } else {
                return $q.resolve(initiative);
            }
        };
        // simple create
        // , authenticationService.currentUser.id)

        if (callback) {
            // then just return and do the server call async
            createInitiativeInner(
                id,
                projectId,
                parentId,
                title,
                tags,
                evaluatedFunctions,
                owner,
                underItemId,
                due,
                groupId,
                dataTiles,
                createdInWorkerRunInformation,
                description,
                eta,
                updateText,
                initiativeState,
                createdByFormId,
                createdByFormName,
                customTriggerId,
                workerRunId,
                inEditMode,
                isDraftInitiative,
                isFormQuestion,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
                .then(afterCreate)
                .catch(function (error) {
                    if (onErrorCallback) {
                        onErrorCallback(error);
                    }
                });

            return $q.resolve(baseObj);
        } else {
            // return the server call
            return createInitiativeInner(
                id,
                projectId,
                parentId,
                title,
                tags,
                evaluatedFunctions,
                owner,
                underItemId,
                due,
                groupId,
                dataTiles,
                createdInWorkerRunInformation,
                description,
                eta,
                updateText,
                initiativeState,
                createdByFormId,
                createdByFormName,
                customTriggerId,
                workerRunId,
                inEditMode,
                isDraftInitiative,
                isFormQuestion,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
                .then(afterCreate)
                .catch(function (error) {
                    if (onErrorCallback) {
                        onErrorCallback(error);
                    }
                });
        }
    };

    /**
     * Create multiple initiatives in one request.
     * Doesn't prepare the given initiatives in any way before sending to the server.
     * Will always cache the returned newly created initiatives.
     */
    _this.createMultipleInitiatives = function (groupId, initiatives) {
        return tonkeanService.createMultipleInitiatives(groupId, initiatives).then((data) => {
            let initiatives = null;

            if (data && data.entities) {
                // Cache the new initiatives created.
                initiatives = initiativeCache.cacheItems(data.entities, false, true, true, true, true);

                for (const cachedInitiative of initiatives) {
                    // If this initiative was created with a parent, update the parent about it.
                    if (
                        cachedInitiative.parent &&
                        cachedInitiative.parent.relatedInitiatives &&
                        initiativeCache.cacheHasInitiative(cachedInitiative.parent.id)
                    ) {
                        const cachedParent = initiativeCache.getInitiativeFromCache(cachedInitiative.parent.id);
                        cachedParent.relatedInitiatives = cachedInitiative.parent.relatedInitiatives;
                    }
                }
            }

            return $q.resolve(initiatives);
        });
    };

    /**
     * Archives or un-archives an initiative, and updates the project data (mostly for getting the new project stats).
     */
    _this.updateInitiativeArchiveState = function (
        initiativeId,
        isArchive,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        // Update the isArchived property immediately so the UI will update.
        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        const operationName = 'updateInitiativeArchiveState';
        const operationId = setOperationId(cachedInitiative, operationName);

        cachedInitiative.isArchived = isArchive;
        updateDataETag(initiativeId, operationId);

        // Re-rendering archived item
        TrackActions.trackArchived(initiativeId);

        if (cachedInitiative.parent) {
            const cachedParent = initiativeCache.getInitiativeFromCache(cachedInitiative.parent.id);

            // Clear the archived initiative from his parent.
            if (cachedParent.relatedInitiatives) {
                const index = utils.indexOf(cachedParent.relatedInitiatives, (item) => item.id === initiativeId);
                if (index > -1) {
                    cachedParent.relatedInitiatives.splice(index, 1);
                }
            }

            // Re-rendering parent of archived item
            TrackActions.trackRelatedItemsUpdated(cachedInitiative.parent.id);
        }

        return tonkeanService
            .updateInitiativeArchiveState(
                initiativeId,
                isArchive,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then(function (serverInitiative) {
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                handleCacheInitiativeFromServer(serverInitiative, INITIATIVE_CHANGE_TYPES.data);

                projectManager.getProjectData(true);

                return $q.resolve(initiativeCache.getInitiativeFromCache(serverInitiative.id));
            });
    };

    /**
     * Bulk archives the given initiative ids.
     */
    _this.bulkArchive = function (initiativesIdsToArchive) {
        if (!initiativesIdsToArchive || !initiativesIdsToArchive.length) {
            return;
        }

        const initiativesCache = initiativeCache.getInitiativesCache();
        const initiativesToArchive = [];

        for (const initiativeId of initiativesIdsToArchive) {
            if (initiativesCache[initiativeId]) {
                // Collect the initiative ids to send to the server.
                initiativesToArchive.push(initiativeId);
                // Update the cache with the is archived state of this initiative.
                initiativesCache[initiativeId].isArchived = true;
            }
        }

        // Update the server with the initiatives to archive.
        tonkeanService.updateInitiativeArchiveStateBulk(projectManager.project.id, initiativesToArchive, true);
    };

    /**
     * Creates a field instance for an initiative.
     */
    _this.createInitiativeDataTile = function (
        initiativeOrLinkId,
        tileValue,
        fieldDefinition,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (!initiativeOrLinkId) {
            return $q.resolve();
        }

        const trimmedTileValue =
            tileValue && (typeof tileValue === 'string' || tileValue instanceof String) ? tileValue.trim() : tileValue;

        // Create a stub field until the server returns the new one.
        const cachedInitiativeOrLink = initiativeCache.getInitiativeFromCache(initiativeOrLinkId);
        if (!cachedInitiativeOrLink) {
            return $q.resolve();
        }
        const realInitiative = initiativeCache.getRealTrack(cachedInitiativeOrLink);
        if (!realInitiative) {
            return $q.resolve();
        }

        // Taking care of ETags.
        const operationName = 'createInitiativeDataTile';
        const operationId = setOperationId(realInitiative, operationName);
        updateDataETag(realInitiative.id, operationId);

        // Initialize all field containers.
        if (!realInitiative.fields) {
            realInitiative.fields = [];
            realInitiative.defIdToFieldsMap = {};
            realInitiative.defIdToValidFieldsMap = {};
        }
        if (!realInitiative.defIdToFieldsMap[fieldDefinition.id]) {
            realInitiative.defIdToFieldsMap[fieldDefinition.id] = [];
        }
        if (!realInitiative.defIdToValidFieldsMap[fieldDefinition.id]) {
            realInitiative.defIdToValidFieldsMap[fieldDefinition.id] = [];
        }

        // Create the dummy.
        const dummyField = {
            id: DUMMY_ENTITY_ID,
            value: trimmedTileValue,
            fieldDefinition,
            values: trimmedTileValue,
            formattedMultiValues: trimmedTileValue,
        };
        dummyField.displayValue = fieldDisplay.evaluateFieldDisplayValue(
            dummyField.fieldDefinition ? dummyField.fieldDefinition.evaluatedDisplayType : null,
            dummyField.fieldDefinition ? dummyField.fieldDefinition.displayFormat : null,
            dummyField.value,
            dummyField.dateValue,
            dummyField.formattedValue,
        );

        // Push it to all containers.
        realInitiative.fields.push(dummyField);
        realInitiative.defIdToFieldsMap[fieldDefinition.id].push(dummyField);
        realInitiative.defIdToValidFieldsMap[fieldDefinition.id].push(dummyField);

        // Update react.
        TrackActions.trackFieldUpdated(initiativeOrLinkId);

        return tonkeanService
            .createDataTile(
                realInitiative.id,
                fieldDefinition.isMultiValueField && typeof trimmedTileValue === 'string'
                    ? trimmedTileValue.split(fieldDefinition.inputMultiValueSeparator)
                    : trimmedTileValue,
                fieldDefinition.id,
                operationId,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            )
            .then(function (serverField) {
                const serverInitiative = serverField.initiative;
                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                    serverField,
                );
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);

                if (!cachedServerInitiative) {
                    // We decided not to cache server response.

                    // These properties are 'server-calculated' properties, so we should take them regardless.
                    // Search and destroy the dummy field!
                    utils.removeFirst(cachedInitiative.fields, (f) => f.id === DUMMY_ENTITY_ID);
                    utils.removeFirst(
                        cachedInitiative.defIdToFieldsMap[serverField.fieldDefinition.id],
                        (f) => f.id === DUMMY_ENTITY_ID,
                    );
                    utils.removeFirst(
                        cachedInitiative.defIdToValidFieldsMap[serverField.fieldDefinition.id],
                        (f) => f.id === DUMMY_ENTITY_ID,
                    );

                    // Update the fields array inside the initiative itself with the new field.
                    if (cachedInitiative.fields) {
                        const cachedFieldInstance = getFieldFromInitiativesFieldArray(cachedInitiative, serverField.id);

                        if (cachedFieldInstance) {
                            cachedFieldInstance.formattedValue = serverField.formattedValue;
                            cachedFieldInstance.formattedMultiValues = serverField.formattedMultiValues;
                            cachedFieldInstance.values = serverField.values;
                            cachedFieldInstance.dateValue = serverField.dateValue;
                            cachedFieldInstance.valueDate = serverField.dateValue;
                            cachedFieldInstance.numberValue = serverField.numberValue;
                            cachedFieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.evaluatedDisplayType
                                    : null,
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.displayFormat
                                    : null,
                                cachedFieldInstance.value,
                                cachedFieldInstance.dateValue,
                                cachedFieldInstance.formattedValue,
                            );
                        } else {
                            cachedInitiative.fields.push(serverField);
                        }
                    }

                    // Update the defIdToFieldsMap with the new field.
                    if (cachedInitiative.defIdToFieldsMap && cachedInitiative.defIdToFieldsMap[fieldDefinition.id]) {
                        const cachedFieldInstance = getFieldFromInitiativesDefIdToFieldsMap(
                            cachedInitiative,
                            serverField.fieldDefinition.id,
                            serverField.id,
                        );

                        if (cachedFieldInstance) {
                            cachedFieldInstance.formattedValue = serverField.formattedValue;
                            cachedFieldInstance.formattedMultiValues = serverField.formattedMultiValues;
                            cachedFieldInstance.values = serverField.values;
                            cachedFieldInstance.dateValue = serverField.dateValue;
                            cachedFieldInstance.valueDate = serverField.dateValue;
                            cachedFieldInstance.numberValue = serverField.numberValue;
                            cachedFieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.evaluatedDisplayType
                                    : null,
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.displayFormat
                                    : null,
                                cachedFieldInstance.value,
                                cachedFieldInstance.dateValue,
                                cachedFieldInstance.formattedValue,
                            );
                        } else {
                            cachedInitiative.defIdToFieldsMap[serverField.fieldDefinition.id].push(serverField);
                        }
                    }

                    // Update the defIdToValidFieldsMap with the new field.
                    if (
                        cachedInitiative.defIdToValidFieldsMap &&
                        cachedInitiative.defIdToValidFieldsMap[fieldDefinition.id]
                    ) {
                        const cachedFieldInstance = getFieldFromInitiativesDefIdToValidFieldsMap(
                            cachedInitiative,
                            serverField.fieldDefinition.id,
                            serverField.id,
                        );

                        if (cachedFieldInstance) {
                            cachedFieldInstance.formattedValue = serverField.formattedValue;
                            cachedFieldInstance.formattedMultiValues = serverField.formattedMultiValues;
                            cachedFieldInstance.values = serverField.values;
                            cachedFieldInstance.dateValue = serverField.dateValue;
                            cachedFieldInstance.valueDate = serverField.dateValue;
                            cachedFieldInstance.numberValue = serverField.numberValue;
                            cachedFieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.evaluatedDisplayType
                                    : null,
                                cachedFieldInstance.fieldDefinition
                                    ? cachedFieldInstance.fieldDefinition.displayFormat
                                    : null,
                                cachedFieldInstance.value,
                                cachedFieldInstance.dateValue,
                                cachedFieldInstance.formattedValue,
                            );
                        } else if (
                            !utils.isNullOrUndefined(serverField.value) &&
                            (serverField.isAssociated || serverField.fieldDefinition.type === 'MANUAL')
                        ) {
                            // If we didn't find the field, it means this might be a newly created field and we should keep the cache up-to-date.
                            // Therefore, we add the field to the cache if it's "valid", i.e manual or associated and has a value.
                            cachedInitiative.defIdToValidFieldsMap[serverField.fieldDefinition.id].push(serverField);
                        }
                    }

                    initiativeCache.cacheDueDate(cachedInitiativeOrLink);

                    TrackActions.trackFieldUpdated(initiativeOrLinkId);
                }

                // Update the ID of the field instead of dummy id
                dummyField.id = serverField.id;

                return $q.resolve(serverField);
            })
            .catch((error) => {
                if (error && error.data && error.data.error && error.data.error.message) {
                    $rootScope.$emit('alert', {
                        msg: error.data.error.message,
                        type: 'error',
                    });
                }
            });
    };

    // Tiles are actually fields instances. Thus tileId is fieldId (not fieldDefinitionId).
    _this.updateInitiativeDataTile = function (
        initiativeOrLinkId,
        field,
        newValue,
        externalId,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        if (!initiativeOrLinkId || !field || !field.fieldDefinition) {
            return;
        }

        // Parameters.
        const fieldDefinitionId = field.fieldDefinition.id;
        const fieldId = field.id;
        const trimmedNewValue =
            newValue && (typeof newValue === 'string' || newValue instanceof String) ? newValue.trim() : newValue;

        // Fetching from cache.
        const cachedInitiativeOrLink = initiativeCache.getInitiativeFromCache(initiativeOrLinkId);
        const realInitiative = initiativeCache.getRealTrack(cachedInitiativeOrLink);
        if (!cachedInitiativeOrLink || !realInitiative) {
            return $q.resolve();
        }

        // Taking care of ETags.
        const operationName = `updateInitiativeDataTile${fieldDefinitionId}${fieldId}`;
        const operationId = setOperationId(realInitiative, operationName);
        updateDataETag(realInitiative.id, operationId);

        // Create a backup of the current values (before updating them) so we can roll-back on errors.
        const oldFieldValues = {
            value: field.value,
            formattedValue: field.formattedValue,
            formattedMultiValues: field.formattedMultiValues,
            multiValues: field.multiValues,
            dateValue: field.dateValue,
            displayValue: field.displayValue,
            numberValue: field.numberValue,
            appliedRangeNumber: field.appliedRangeNumber,
        };

        // Update the field value immediately so the UI will update.
        // Reset the formatted value for now. The server will promptly return a new one (we don't know to calculate that ourselves).
        field.formattedValue = null;
        field.dateValue = null;
        field.value = newValue;
        field.multiValues = newValue;
        field.formattedMultiValues = newValue;
        field.displayValue = fieldDisplay.evaluateFieldDisplayValue(
            field.fieldDefinition ? field.fieldDefinition.evaluatedDisplayType : null,
            field.fieldDefinition ? field.fieldDefinition.displayFormat : null,
            field.value,
            field.dateValue,
            field.formattedValue,
        );
        TrackActions.trackFieldUpdated(initiativeOrLinkId);

        initiativeCache.removeFieldDateCache(cachedInitiativeOrLink, fieldId);
        updateFieldInCaches(
            cachedInitiativeOrLink,
            fieldId,
            fieldDefinitionId,
            trimmedNewValue,
            null,
            trimmedNewValue instanceof Date ? trimmedNewValue : null,
            null,
            null,
            false,
        );

        TrackActions.trackFieldUpdated(initiativeOrLinkId);

        let updatePromise;

        // There are two different APIs for updating a field. One for updating the external id,
        // and one for updating the value. The API for updating external id WILL NOT update the field value of a non-manual field,
        // and will leave you with the old value in the field.
        if (externalId) {
            updatePromise = tonkeanService.updateDataTile(fieldId, trimmedNewValue, externalId, operationId);
        } else {
            if (field.fieldDefinition.isMultiValueField) {
                updatePromise = tonkeanService.updateFieldValues(
                    fieldId,
                    fieldDefinitionId,
                    typeof newValue === 'string'
                        ? newValue.split(field.fieldDefinition.inputMultiValueSeparator)
                        : newValue,
                    initiativeOrLinkId,
                    operationId,
                    throughSolutionBusinessReport,
                    solutionBusinessReportId,
                );
            } else {
                updatePromise = tonkeanService.updateFieldValue(
                    fieldId,
                    trimmedNewValue,
                    operationId,
                    throughSolutionBusinessReport,
                    solutionBusinessReportId,
                );
            }
        }

        return updatePromise
            .then((serverField) => {
                const serverInitiative = serverField.initiative;
                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);
                if (!sameOperationId(cachedInitiative, operationName, operationId)) {
                    return $q.resolve(cachedInitiative);
                }

                const cachedServerInitiative = handleCacheInitiativeFromServer(
                    serverInitiative,
                    INITIATIVE_CHANGE_TYPES.data,
                    serverField,
                );

                // If we decided not to cache server response.
                if (!cachedServerInitiative) {
                    // We want to update 'server-calculated' properties only, so we take them regardless. We tell the function to skipSetValue.
                    updateFieldInCaches(
                        cachedInitiative,
                        fieldId,
                        fieldDefinitionId,
                        null,
                        serverField.formattedValue,
                        serverField.dateValue,
                        serverField.numberValue,
                        serverField.appliedRangeNumber,
                        true,
                    );

                    // Update the defIdToValidFieldsMap with the new field.
                    if (
                        cachedInitiative.defIdToValidFieldsMap &&
                        cachedInitiative.defIdToValidFieldsMap[fieldDefinitionId]
                    ) {
                        const cachedFieldInstance = getFieldFromInitiativesDefIdToValidFieldsMap(
                            cachedInitiative,
                            serverField.fieldDefinition.id,
                            serverField.id,
                        );

                        if (
                            !cachedFieldInstance &&
                            !utils.isNullOrUndefined(serverField.value) &&
                            (serverField.isAssociated || serverField.fieldDefinition.type === 'MANUAL')
                        ) {
                            // If we didn't find the field, it means this might be a newly created field and we should keep the cache up-to-date.
                            // Therefore, we add the field to the cache if it's "valid", i.e manual or associated and has a value.
                            cachedInitiative.defIdToValidFieldsMap[serverField.fieldDefinition.id].push(serverField);
                        }
                    }

                    // Field values may affect the next gather update.
                    cachedInitiative.nextGatherUpdate = serverInitiative.nextGatherUpdate;
                    cachedInitiative.nextGatherUpdateReason = serverInitiative.nextGatherUpdateReason;

                    TrackActions.trackFieldUpdated(initiativeOrLinkId);
                }

                return $q.resolve(serverField);
            })
            .catch(() => {
                // Rollback to field's former state.
                if (cachedInitiativeOrLink) {
                    updateFieldInCaches(
                        cachedInitiativeOrLink,
                        fieldId,
                        fieldDefinitionId,
                        oldFieldValues.value,
                        oldFieldValues.formattedValue,
                        oldFieldValues.dateValue,
                        oldFieldValues.numberValue,
                        oldFieldValues.appliedRangeNumber,
                    );
                    TrackActions.trackFieldUpdated(initiativeOrLinkId);
                }

                // Alert user that the field did not update
                $rootScope.$emit('alert', {
                    msg: `Could not update field ${
                        field && field.fieldDefinition ? `${field.fieldDefinition.name} ` : ''
                    }${realInitiative ? `in track ${realInitiative.title}` : ''}`,
                });
            });
    };

    /**
     * Updates the external status of given initiative.
     */
    _this.updateInitiativeExternalStatus = function (initiativeId, statusId) {
        return tonkeanService.updateInitiativeExternalStatus(initiativeId, statusId);
    };

    _this.autoGenerateTitle = function (creatorName, createdInFormName) {
        return `Created by ${creatorName} using ${createdInFormName} (${moment().format('MM/DD/YYYY hh:mm a')})`;
    };

    /**
     * Inner logic of create initiative.
     */
    function createInitiativeInner(
        initiativeId,
        projectId,
        parentId,
        title,
        tags,
        functions,
        owner,
        underItemId,
        due,
        groupId,
        dataTiles,
        createdInWorkerRunInformation,
        description,
        eta,
        updateText,
        initiativeState,
        createdByFormId,
        createdByFormName,
        customTriggerId,
        workerRunId,
        inEditMode,
        isDraftInitiative,
        isFormQuestion,
        throughSolutionBusinessReport,
        solutionBusinessReportId,
    ) {
        let functionToCreate;
        const metadata = {};

        if (functions && functions.length) {
            functionToCreate = [];

            for (const func of functions) {
                if (func) {
                    if (func.isNew) {
                        functionToCreate.push({ newFunction: func.name });
                    } else {
                        functionToCreate.push({ function: func.id });
                    }
                }
            }
        }

        const createOperationId = utils.guid();

        if (parentId && initiativeCache.cacheHasInitiative(parentId)) {
            const parentInitiative = initiativeCache.getInitiativeFromCache(parentId);
            parentInitiative.createOperationId = createOperationId;
            updateChildrenETag(parentId, createOperationId);

            // Re-rendering the parent, because its related initiatives have changed
            TrackActions.trackRelatedItemsUpdated(parentId);
        }

        // Setting the etags of the newly created initiative.
        if (initiativeCache.cacheHasInitiative(initiativeId)) {
            const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
            cachedInitiative.etags = {};
            cachedInitiative.etags[ETAG_TYPES.initiative.data.old] = createOperationId;
            cachedInitiative.etags[ETAG_TYPES.initiative.data.new] = createOperationId;
            cachedInitiative.etags[ETAG_TYPES.initiative.children.old] = createOperationId;
            cachedInitiative.etags[ETAG_TYPES.initiative.children.new] = createOperationId;
            cachedInitiative.etags[ETAG_TYPES.initiative.group.old] = createOperationId;
            cachedInitiative.etags[ETAG_TYPES.initiative.group.new] = createOperationId;
        }

        let createInitiativePromise;
        if (parentId) {
            createInitiativePromise = tonkeanService.createInnerInitiative(
                projectId,
                parentId,
                title,
                tags,
                metadata,
                functionToCreate,
                !owner.temp ? owner.id : null,
                owner.temp ? owner.name : null,
                due,
                groupId,
                initiativeId,
                dataTiles,
                underItemId,
                createOperationId,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.workerRunLogicActionId : null,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.createdByCustomTriggerId : null,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.workerRunLogicActionType : null,
                initiativeState,
                description,
                eta,
                updateText,
                createdByFormId,
                createdByFormName,
                customTriggerId,
                workerRunId,
                inEditMode,
                isDraftInitiative,
                isFormQuestion,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            );
        } else {
            createInitiativePromise = tonkeanService.createInitiative(
                projectId,
                parentId,
                title,
                tags,
                metadata,
                functionToCreate,
                !owner.temp ? owner.id : null,
                owner.temp ? owner.name : null,
                due,
                groupId,
                initiativeId,
                dataTiles,
                underItemId,
                createOperationId,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.workerRunLogicActionId : null,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.createdByCustomTriggerId : null,
                createdInWorkerRunInformation ? createdInWorkerRunInformation.workerRunLogicActionType : null,
                initiativeState,
                description,
                eta,
                updateText,
                createdByFormId,
                createdByFormName,
                customTriggerId,
                workerRunId,
                inEditMode,
                isDraftInitiative,
                isFormQuestion,
                throughSolutionBusinessReport,
                solutionBusinessReportId,
            );
        }

        return createInitiativePromise
            .then(function (serverInitiative) {
                // Caching the initiative itself, since it's a new initiative!
                initiativeCache.cacheItem(serverInitiative, false, true, true, true, true);

                // Caching the initiative's parent if there is such parent,
                // and validating we're here because of the same create that caused the call. If we aren't we ignore.
                if (serverInitiative.parent) {
                    if (!initiativeCache.cacheHasInitiative(serverInitiative.parent.id)) {
                        // If parent isn't cached yet, we just cache it.

                        initiativeCache.cacheItem(serverInitiative.parent.id, false, true, true, true, true);
                    } else {
                        // Otherwise, we get it from cache, and decide whether to cache it or not.
                        const cachedParent = initiativeCache.getInitiativeFromCache(serverInitiative.parent.id);

                        if (cachedParent.createOperationId === createOperationId) {
                            handleCacheInitiativeFromServer(
                                serverInitiative.parent,
                                INITIATIVE_CHANGE_TYPES.children,
                                serverInitiative,
                            );
                        }
                    }
                }

                const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);

                // Updating project's functions
                if (functionToCreate && functionToCreate.length) {
                    projectManager.getFunctions(true);
                }
                // Updating project's tags
                if (tags && tags.length) {
                    projectManager.getTags(true);
                }
                // Completing on-boarding steps
                onBoardingManager.completeStep('createTrack');
                if (cachedInitiative.owner && cachedInitiative.owner.id) {
                    onBoardingManager.completeStep('assignOwner');
                }

                // If the item is a root item, we check if its group has inner tracks template configuration,
                // and if so, we create those inner tracks using the multi initiatives creation API.
                if (!parentId && projectManager.groupsMap[groupId].innerTracksTemplate) {
                    const now = DeprecatedDate.nowAsDate();
                    const ownerEvaluationOptions = OWNER_EVALUATION_OPTIONS;
                    const dueDateEvaluationOptions = DUE_DATE_EVALUATION_OPTIONS;
                    const innerTemplateInitiatives = [];

                    for (let i = 0; i < projectManager.groupsMap[groupId].innerTracksTemplate.length; i++) {
                        const trackTemplate = projectManager.groupsMap[groupId].innerTracksTemplate[i];

                        const initiativeToCreate = {
                            initiativeId: _this.generateId(),
                            parentId: initiativeId,
                            parentAlreadyExists: true,
                            groupId,
                            title: trackTemplate.title,
                        };

                        // Evaluating the owner of the initiative
                        if (
                            trackTemplate.ownerEvaluationOption === ownerEvaluationOptions.takeFromParent &&
                            owner &&
                            owner.id
                        ) {
                            initiativeToCreate.owner = owner.id;
                        } else if (
                            trackTemplate.ownerEvaluationOption === ownerEvaluationOptions.manual &&
                            trackTemplate.manualOwnerInfo &&
                            trackTemplate.manualOwnerInfo.id
                        ) {
                            initiativeToCreate.owner = trackTemplate.manualOwnerInfo.id;
                        }

                        // Evaluating the due date of the initiative
                        if (
                            trackTemplate.dueDateEvaluationOption === dueDateEvaluationOptions.relativeToCreation &&
                            trackTemplate.relativeDueDateInDays
                        ) {
                            initiativeToCreate.dueDate =
                                now + trackTemplate.relativeDueDateInDays * 24 * 60 * 60 * 1000;
                        }

                        innerTemplateInitiatives.push(initiativeToCreate);
                    }

                    // Creating the inner initiatives using the create multiple API.
                    // Right after creation, we do force polling to get the initiatives that were just created.
                    tonkeanService.createMultipleInitiatives(groupId, innerTemplateInitiatives);
                }

                return $q.resolve(cachedInitiative);
            })
            .catch(function (error) {
                // If there's an error, we remove it from the cache.
                initiativeCache.removeInitiativeFromCache(initiativeId);

                // Alerting user with an error
                $rootScope.$emit('alert', {
                    msg: `There was a problem creating track "${title}": ${error.data.data.error.message}`,
                });

                return $q.reject(error);
            });
    }

    /**
     * Handles the logic of whether we should cache the initiative returned from a post request, or not,
     * based on the etags of the initiative and the etags we sent in the request.
     *
     * Returns true if we cached the given item. False otherwise.
     */
    function handleCacheInitiativeFromServer(serverInitiative, changeType, originalServerResponse) {
        if (!serverInitiative) {
            return false;
        }

        // If we don't have the server initiative in cache, we need to cache it.
        if (!initiativeCache.cacheHasInitiative(serverInitiative.id)) {
            initiativeCache.cacheItem(serverInitiative);
            return true;
        }

        // If we received an original server response, we take the header from there, as it will always be in the response from the server.
        // Otherwise, we take it from the initiatives object.
        let sentETag = null;
        if (originalServerResponse && originalServerResponse.headerTonkeanETag) {
            sentETag = originalServerResponse.headerTonkeanETag;
        } else {
            sentETag = serverInitiative.headerTonkeanETag;
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(serverInitiative.id);

        const oldDataETagName = ETAG_TYPES.initiative.data.old;
        const newDataETagName = ETAG_TYPES.initiative.data.new;
        const oldChildrenETagName = ETAG_TYPES.initiative.children.old;
        const newChildrenETagName = ETAG_TYPES.initiative.children.new;
        const oldGroupETagName = ETAG_TYPES.initiative.group.old;
        const newGroupETagName = ETAG_TYPES.initiative.group.new;

        const changeInData =
            (serverInitiative.etags &&
                cachedInitiative.etags &&
                serverInitiative.etags[oldDataETagName] !== cachedInitiative.etags[oldDataETagName] &&
                serverInitiative.etags[newDataETagName] !== cachedInitiative.etags[newDataETagName] &&
                serverInitiative.etags[oldDataETagName] !== cachedInitiative.etags[newDataETagName]) ||
            (serverInitiative.etags &&
                changeType === INITIATIVE_CHANGE_TYPES.data &&
                serverInitiative.etags[newDataETagName] !== sentETag);
        const changeInChildren =
            (serverInitiative.etags &&
                cachedInitiative.etags &&
                serverInitiative.etags[oldChildrenETagName] !== cachedInitiative.etags[oldChildrenETagName] &&
                serverInitiative.etags[newChildrenETagName] !== cachedInitiative.etags[newChildrenETagName] &&
                serverInitiative.etags[oldChildrenETagName] !== cachedInitiative.etags[newChildrenETagName]) ||
            (serverInitiative.etags &&
                changeType === INITIATIVE_CHANGE_TYPES.children &&
                serverInitiative.etags[newChildrenETagName] !== sentETag);
        const changeInGroup =
            (serverInitiative.etags &&
                cachedInitiative.etags &&
                serverInitiative.etags[oldGroupETagName] !== cachedInitiative.etags[oldGroupETagName] &&
                serverInitiative.etags[newGroupETagName] !== cachedInitiative.etags[newGroupETagName] &&
                serverInitiative.etags[oldGroupETagName] !== cachedInitiative.etags[newGroupETagName]) ||
            (serverInitiative.etags &&
                changeType === INITIATIVE_CHANGE_TYPES.group &&
                serverInitiative.etags[newGroupETagName] !== sentETag);

        let cachedGivenItem = false;

        // If we didn't receive etags on either sides (server or cache), or there was a change in data or change in children.
        if (!serverInitiative.etags || !cachedInitiative.etags || changeInData || changeInChildren || changeInGroup) {
            $log.debug(`yes caching [${serverInitiative.title}]!`);

            // If we have a change in children, we would like to cache the related initiatives as well.
            initiativeCache.cacheItem(serverInitiative, false, true, changeInChildren, false, false);
            cachedGivenItem = true;

            // Firing a track data updated event to let everybody know an updated item has been cached.
            TrackActions.trackDataUpdated(serverInitiative.id);
        } else {
            $log.debug(`not caching [${serverInitiative.title}]!`);
        }

        // If there's a change in group, we broadcast groupListUpdated over the group id, so everyone
        // would reload the group if needed.
        if (changeInGroup) {
            $log.debug('change in group!');
            $rootScope.$broadcast('groupListUpdated', {
                groupIds: [serverInitiative.group.id],
            });
        }

        return cachedGivenItem;
    }

    /**
     * Caches the given initiatives (or initiative links), and their related initiatives
     * out of the related entities.
     * @param initiativesOrInitiativeLinks Initiatives or initiative links we'd like to cache.
     * @param relatedEntitiesArray Related entities of these initiatives or initiative links. Out
     *        of these related entities, we would only cache the ones that are initiatives or
     *        initiative links.
     */
    function cacheInitiativesAndInitiativeLinks(initiativesOrInitiativeLinks) {
        if (!initiativesOrInitiativeLinks || !initiativesOrInitiativeLinks.length) {
            return;
        }

        // Cache the given entities.
        // Each entity has a changeTypes array, which holds all the different kinds of changes made to the entities.
        for (const itemToCache of initiativesOrInitiativeLinks) {
            let changeInData = false; // eslint-disable-line no-unused-vars
            let changeInChildren = false;
            let changeInGroup = false;

            if (itemToCache && itemToCache.changeTypes && itemToCache.changeTypes.length) {
                // Even though not all of this booleans are considered, we still keep them here to be aware of what we have available.
                changeInData = itemToCache.changeTypes.includes('DATA');
                changeInChildren = itemToCache.changeTypes.includes('CHILDREN');
                changeInGroup = itemToCache.changeTypes.includes('GROUP');
            }

            // Only caching the related initiatives of them item if there was a change in children.
            // There's no need to cache parent initiatives or the parent itself from the polling method.
            initiativeCache.cacheItem(itemToCache, false, true, changeInChildren, changeInGroup, changeInGroup);
        }

        // Re-rending
        for (const initiativesOrInitiativeLink of initiativesOrInitiativeLinks) {
            TrackActions.trackDataUpdated(initiativesOrInitiativeLink.id);
        }
    }

    function updateFieldInCaches(
        initiativeOrLink,
        fieldId,
        fieldDefinitionId,
        newValue,
        formattedValue,
        dateValue,
        numberValue,
        appliedRangeNumber,
        skipUpdateValue,
    ) {
        if (!initiativeOrLink || !fieldId) {
            return;
        }

        // Fetching the real initiative from the given initiativeOrLink.
        const realInitiative = initiativeCache.getRealTrack(initiativeOrLink);
        if (!realInitiative) {
            return;
        }

        // Update the fields array inside the initiative itself with the new field.
        if (realInitiative.fields) {
            const fieldInstance = getFieldFromInitiativesFieldArray(realInitiative, fieldId);

            if (fieldInstance) {
                if (!skipUpdateValue) {
                    fieldInstance.value = newValue;
                }
                fieldInstance.formattedValue = formattedValue;
                fieldInstance.dateValue = dateValue;
                fieldInstance.valueDate = dateValue;
                fieldInstance.numberValue = numberValue;
                fieldInstance.appliedRangeNumber = appliedRangeNumber;
                fieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.evaluatedDisplayType : null,
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.displayFormat : null,
                    fieldInstance.value,
                    fieldInstance.dateValue,
                    fieldInstance.formattedValue,
                );

                entityHelper.enrichField(fieldInstance);
            }
        }

        // Update the defIdToFieldsMap with the new field.
        if (realInitiative.defIdToFieldsMap && realInitiative.defIdToFieldsMap[fieldDefinitionId]) {
            const fieldInstance = getFieldFromInitiativesDefIdToFieldsMap(realInitiative, fieldDefinitionId, fieldId);

            if (fieldInstance) {
                if (!skipUpdateValue) {
                    fieldInstance.value = newValue;
                }
                fieldInstance.formattedValue = formattedValue;
                fieldInstance.dateValue = dateValue;
                fieldInstance.valueDate = dateValue;
                fieldInstance.numberValue = numberValue;
                fieldInstance.appliedRangeNumber = appliedRangeNumber;
                fieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.evaluatedDisplayType : null,
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.displayFormat : null,
                    fieldInstance.value,
                    fieldInstance.dateValue,
                    fieldInstance.formattedValue,
                );

                entityHelper.enrichField(fieldInstance);
            }
        }

        // Update the defIdToValidFieldsMap with the new field.
        if (realInitiative.defIdToValidFieldsMap && realInitiative.defIdToValidFieldsMap[fieldDefinitionId]) {
            const fieldInstance = getFieldFromInitiativesDefIdToValidFieldsMap(
                realInitiative,
                fieldDefinitionId,
                fieldId,
            );

            if (fieldInstance) {
                if (!skipUpdateValue) {
                    fieldInstance.value = newValue;
                }
                fieldInstance.formattedValue = formattedValue;
                fieldInstance.dateValue = dateValue;
                fieldInstance.valueDate = dateValue;
                fieldInstance.numberValue = numberValue;
                fieldInstance.appliedRangeNumber = appliedRangeNumber;
                fieldInstance.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.evaluatedDisplayType : null,
                    fieldInstance.fieldDefinition ? fieldInstance.fieldDefinition.displayFormat : null,
                    fieldInstance.value,
                    fieldInstance.dateValue,
                    fieldInstance.formattedValue,
                );

                entityHelper.enrichField(fieldInstance);
            }
        }

        initiativeCache.cacheDueDate(initiativeOrLink);
    }

    /**
     * Gets the field object with id of fieldInstanceId out of the fields array of the given initiative.
     */
    function getFieldFromInitiativesFieldArray(initiative, fieldInstanceId) {
        if (!initiative || !initiative.fields || !initiative.fields.length || !fieldInstanceId) {
            return null;
        }

        const fieldIndex = utils.indexOf(initiative.fields, function (item) {
            return item.id === fieldInstanceId;
        });

        if (fieldIndex > -1) {
            return initiative.fields[fieldIndex];
        } else {
            return null;
        }
    }

    /**
     * Gets the field object with id of fieldInstanceId out of the defIdToFieldsMap of the given initiative.
     */
    function getFieldFromInitiativesDefIdToFieldsMap(initiative, fieldDefinitionId, fieldInstanceId) {
        if (
            !initiative ||
            !fieldDefinitionId ||
            !fieldInstanceId ||
            !initiative.defIdToFieldsMap ||
            !initiative.defIdToFieldsMap[fieldDefinitionId]
        ) {
            return null;
        }

        const fieldMapIndex = utils.indexOf(initiative.defIdToFieldsMap[fieldDefinitionId], function (item) {
            return item.id === fieldInstanceId;
        });

        if (fieldMapIndex > -1) {
            return initiative.defIdToFieldsMap[fieldDefinitionId][fieldMapIndex];
        } else {
            return null;
        }
    }

    /**
     * Gets the field object with id of fieldInstanceId out of the defIdToValidFieldsMap of the given initiative.
     */
    function getFieldFromInitiativesDefIdToValidFieldsMap(initiative, fieldDefinitionId, fieldInstanceId) {
        if (
            !initiative ||
            !fieldDefinitionId ||
            !fieldInstanceId ||
            !initiative.defIdToValidFieldsMap ||
            !initiative.defIdToValidFieldsMap[fieldDefinitionId]
        ) {
            return null;
        }

        const fieldValidMapIndex = utils.indexOf(initiative.defIdToValidFieldsMap[fieldDefinitionId], function (item) {
            return item.id === fieldInstanceId;
        });

        // If we found the field, update it with the new one.
        if (fieldValidMapIndex > -1) {
            return initiative.defIdToValidFieldsMap[fieldDefinitionId][fieldValidMapIndex];
        } else {
            return null;
        }
    }

    /**
     * Updates the data etags of initiative to the given etag value.
     */
    function updateDataETag(initiativeId, etagValue, requester) {
        if (!initiativeId || !initiativeCache.cacheHasInitiative(initiativeId)) {
            $log.debug(
                `Update data ETag requested for requester [${requester}] and initiative id [${initiativeId}] but could not find initiative id or initiative itself.`,
            );
            return;
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        cachedInitiative.updated = utils.now();

        const oldDataETagName = ETAG_TYPES.initiative.data.old;
        const newDataETagName = ETAG_TYPES.initiative.data.new;

        if (!cachedInitiative.etags) {
            cachedInitiative.etags = {};

            cachedInitiative.etags[oldDataETagName] = etagValue;
            cachedInitiative.etags[newDataETagName] = etagValue;

            // $log.debug('Updating (No ETags) data ETag for requester [' + requester + '] and initiative id [' + initiativeId + ']. Old - [' + cachedInitiative.etags[oldDataETagName] + ']. New - [' + cachedInitiative.etags[newDataETagName] + '].');
        } else {
            cachedInitiative.etags[oldDataETagName] = cachedInitiative.etags[newDataETagName];
            cachedInitiative.etags[newDataETagName] = etagValue;

            // $log.debug('Updating data ETag for requester [' + requester + '] and initiative id [' + initiativeId + ']. Old - [' + cachedInitiative.etags[oldDataETagName] + ']. New - [' + cachedInitiative.etags[newDataETagName] + '].');
        }
    }

    /**
     * Updates the children etags of initiative to the given etag value.
     */
    function updateChildrenETag(initiativeId, etagValue) {
        if (!initiativeId || !initiativeCache.cacheHasInitiative(initiativeId)) {
            return;
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        cachedInitiative.updated = utils.now();

        const oldChildrenETagName = ETAG_TYPES.initiative.children.old;
        const newChildrenETagName = ETAG_TYPES.initiative.children.new;

        if (!cachedInitiative.etags) {
            cachedInitiative.etags = {};

            cachedInitiative.etags[oldChildrenETagName] = etagValue;
            cachedInitiative.etags[newChildrenETagName] = etagValue;
        }

        cachedInitiative.etags[oldChildrenETagName] = cachedInitiative.etags[newChildrenETagName];
        cachedInitiative.etags[newChildrenETagName] = etagValue;
    }

    /**
     * Updates the group etags of initiative to the given etag value.
     */
    function updateGroupETag(initiativeId, etagValue) {
        if (!initiativeId || !initiativeCache.cacheHasInitiative(initiativeId)) {
            return;
        }

        const cachedInitiative = initiativeCache.getInitiativeFromCache(initiativeId);
        cachedInitiative.updated = utils.now();

        const oldGroupETagName = ETAG_TYPES.initiative.group.old;
        const newGroupETagName = ETAG_TYPES.initiative.group.new;

        if (!cachedInitiative.etags) {
            cachedInitiative.etags = {};

            cachedInitiative.etags[oldGroupETagName] = etagValue;
            cachedInitiative.etags[newGroupETagName] = etagValue;
        }

        cachedInitiative.etags[oldGroupETagName] = cachedInitiative.etags[newGroupETagName];
        cachedInitiative.etags[newGroupETagName] = etagValue;
    }

    /**
     * Gets the operation id property name for the given operationStr.
     */
    function getOperationIdName(operationStr) {
        return `${operationStr}OperationId`;
    }

    /**
     * Sets the operation id of the operationStr into the cached initiative.
     */
    function setOperationId(cachedInitiative, operationStr) {
        if (!cachedInitiative || !operationStr) {
            return;
        }

        const operationIdName = getOperationIdName(operationStr);
        const operationId = utils.guid();
        cachedInitiative[operationIdName] = operationId;

        return operationId;
    }

    /**
     * Returns true if the cached initiative's operation id is the same as given operation id. False otherwise.
     */
    function sameOperationId(cachedInitiative, operationStr, operationId) {
        if (!cachedInitiative || !operationStr || !operationId) {
            return;
        }

        const operationIdName = getOperationIdName(operationStr);
        return cachedInitiative[operationIdName] === operationId;
    }
}

angular.module('tonkean.app').service('trackHelper', TrackHelper);
