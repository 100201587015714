<tnk-deprecated-people-selector
    class="flex-grow margin-none"
    no-validation="true"
    selected-people="data.selectedPeopleArray"
    no-custom="true"
    max-tags="1"
    on-tag-added="onPersonAdded()"
    on-tag-removed="onPersonRemoved()"
    placeholder="{{::data.placeholder || 'Search for a person'}}"
    disabled="data.readOnly"
    hide-placeholder-when-disabled="data.hidePlaceholderWhenDisabled"
    hide-tags-background-when-disabled="data.hideTagsBackgroundWhenDisabled"
    include-external= "data.includeExternal"
    dataAutomation="single-person-selector-template-people-selector"
 />