<div class="container mod-main manage-account-page">
    <!-- Tonkean logo (in case the user is not logged in - the menu won't be displayed) -->
    <div ng-if="!as.currentUser">
        <a
            href="/"
            analytics-on="click"
            analytics-category="Notifications"
            analytics-event="Go to main page"
            analytics-label="Logo"
        >
            <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
        </a>
    </div>

    <div class="common-title">Notifications preferences</div>
    <div class="common-subtitle">Manage your notification preferences.</div>

    <div class="clearfix manage-notifications-form">
        <tnk-message-preferences
            message-id="{{ data.messageId }}"
            on-selected-preferences-changed="onSelectedPreferencesChanged(selectedPreferences)"
        ></tnk-message-preferences>
    </div>

    <!-- Loading -->
    <div class="flex-vmiddle" ng-if="data.savingMessagePreference">
        <i class="loading-small margin-right-xs"></i>
        <span>Saving...</span>
    </div>

    <!-- Error -->
    <div class="common-color-danger common-size-xxxxs" ng-if="data.errorSavingMessagePreference">
        Error trying to save preferences.
    </div>
</div>
