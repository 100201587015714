import template from './rangesConfiguration.template.html?angularjs';

/**
 * Component to select ranges.
 */
export default angular.module('tonkean.app').component('tnkRangesConfiguration', {
    bindings: {
        targetCaption: '@', // Is it a column or a key metric?
        ranges: '<', // Ranges array.
        isImportant: '<', // Is important flag.
        fieldType: '<', // The field type.
        fieldTargetType: '<', // The field definition target type (global or column?).
        possibleValues: '<', // Possible values, if there are such.
        projectIntegration: '<', // The connected project integration

        onRangesChange: '&', // Callback to call once ranges are changed.
    },
    template,
    controller: 'RangesConfigurationCtrl',
});
