import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import AccessibilityFilters from './AccessibiltyFilters';
import PersonRoleFilters from './PersonRoleFilters';
import ProjectIntegrationPageExplorerSectionComponent from './ProjectIntegrationPageExplorerSectionComponent';
import type EnterpriseComponentPageExplorerItem from '../../../ProjectIntegrationPageModule/entities/EnterpriseComponentPageExplorerItem';
import type { ProjectIntegrationPageExplorerSectionWithLoading } from '../../../ProjectIntegrationPageModule/entities/ProjectIntegrationPageExplorerSection';

import { enterpriseComponentTypeDisplayName, PersonPermissionRoleType, TonkeanType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationWorkflowFolderAccess } from '@tonkean/tonkean-entities';
import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const SectionsContainer = styled.div`
    margin-bottom: 28px;
`;

const NoItemsFound = styled.div`
    margin-top: 15px;
    margin-left: 14px;
    font-size: 12px;
`;

const FilterHeader = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.mainColors.gray_600};
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const FiltersWrapper = styled.div`
    padding: 0 14px;
`;

interface Props {
    projectIntegrationSections: ProjectIntegrationPageExplorerSectionWithLoading[];
    searchText?: string;
    onItemSelected: (selectedItemId: string) => void;
    isFilterBySolutionAccess: boolean;
    projectIntegrationWorkflowFolderAccess?: ProjectIntegrationWorkflowFolderAccess;
    filterIntegrationsWithNoEntities?: boolean;
    enterpriseComponentsCurrentPersonPermissionRole?: EnterpriseComponentsPersonPermissionRole;
}

const ProjectIntegrationPageExplorerContent: React.FC<Props> = ({
    projectIntegrationSections,
    searchText,
    isFilterBySolutionAccess,
    projectIntegrationWorkflowFolderAccess,
    onItemSelected,
    filterIntegrationsWithNoEntities,
    enterpriseComponentsCurrentPersonPermissionRole,
}) => {
    const [filteredSections, setFilteredSections] =
        useState<ProjectIntegrationPageExplorerSectionWithLoading[]>(projectIntegrationSections);

    // Filter items by given search text
    const filterIntegrationBySearchText = useCallback(
        (item: EnterpriseComponentPageExplorerItem) => {
            if (!searchText) {
                // If search text is empty, we won't perform filter by text.
                return true;
            } else {
                return item.displayName?.toLowerCase().includes(searchText.toLowerCase());
            }
        },
        [searchText],
    );

    // Filter items with no entities (if requested)
    const IntegrationSectionWithEntities = useMemo(
        () =>
            projectIntegrationSections.map((section) => ({
                ...section,
                items: section.items?.filter(
                    (item) => item.loading || (filterIntegrationsWithNoEntities ? item.hasEntities : true),
                ),
            })),
        [projectIntegrationSections, filterIntegrationsWithNoEntities],
    );

    const onAccessibilityFilterChanged = useCallback(
        (isSolutionAccessible, isSolutionInaccessible) => {
            if (!projectIntegrationWorkflowFolderAccess) {
                setFilteredSections(IntegrationSectionWithEntities);
                return;
            }

            setFilteredSections(
                IntegrationSectionWithEntities.map((section) => ({
                    ...section,
                    items: section.items?.filter((item) => {
                        if (item.loading) {
                            return true;
                        }

                        const isAccessibleWhenShowingAccessible =
                            isSolutionAccessible &&
                            projectIntegrationWorkflowFolderAccess.accessibleProjectIntegrations.includes(item.id);
                        const isInaccessibleWhenShowingInaccessible =
                            isSolutionInaccessible &&
                            projectIntegrationWorkflowFolderAccess.inAccessibleProjectIntegrations.includes(item.id);
                        const isWithSearchTerm = filterIntegrationBySearchText(item);

                        return (
                            (isAccessibleWhenShowingAccessible || isInaccessibleWhenShowingInaccessible) &&
                            isWithSearchTerm
                        );
                    }),
                })),
            );
        },
        [IntegrationSectionWithEntities, filterIntegrationBySearchText, projectIntegrationWorkflowFolderAccess],
    );

    const onPersonRoleFilterChanged = useCallback(
        (isManageableShown, isViewShown, isInaccessibleShown) => {
            const personAccessTypeToFilter: Record<PersonPermissionRoleType, boolean> = {
                [PersonPermissionRoleType.ADMIN]: isManageableShown,
                [PersonPermissionRoleType.MAKER]: isViewShown,
                [PersonPermissionRoleType.NOT_AUTHORIZED]: isInaccessibleShown,
            };

            setFilteredSections(
                IntegrationSectionWithEntities.map((section) => ({
                    ...section,
                    items: section.items?.filter((item) => {
                        if (item.loading) {
                            return true;
                        }

                        const itemAccessType =
                            enterpriseComponentsCurrentPersonPermissionRole?.enterpriseComponentPersonAccessType[
                                item.id
                            ] || item.accessType;
                        return (
                            itemAccessType &&
                            personAccessTypeToFilter[itemAccessType] &&
                            filterIntegrationBySearchText(item)
                        );
                    }),
                })),
            );
        },
        [
            IntegrationSectionWithEntities,
            enterpriseComponentsCurrentPersonPermissionRole?.enterpriseComponentPersonAccessType,
            filterIntegrationBySearchText,
        ],
    );

    const sectionsWithItems = useMemo(() => {
        return filteredSections.filter((section) => section.items?.length);
    }, [filteredSections]);

    return (
        <div>
            <SectionsContainer>
                <FiltersWrapper>
                    {isFilterBySolutionAccess ? (
                        <AccessibilityFilters onFilterChanged={onAccessibilityFilterChanged} />
                    ) : (
                        <>
                            <FilterHeader>
                                Show {enterpriseComponentTypeDisplayName[TonkeanType.PROJECT_INTEGRATION].plural} that
                                you can:{' '}
                            </FilterHeader>
                            <PersonRoleFilters onFilterChanged={onPersonRoleFilterChanged} />
                        </>
                    )}
                </FiltersWrapper>

                {sectionsWithItems.length === 0 && (
                    <>
                        <NoItemsFound>
                            No data sources to show.
                            <br />
                            Consider changing the filters.
                        </NoItemsFound>
                    </>
                )}
                {sectionsWithItems.map((section) => {
                    return (
                        <ProjectIntegrationPageExplorerSectionComponent
                            key={section.id}
                            section={section}
                            onItemSelected={onItemSelected}
                        />
                    );
                })}
            </SectionsContainer>
        </div>
    );
};

export default ProjectIntegrationPageExplorerContent;
