import React from 'react';

import { range } from '@tonkean/utils';

interface Props {
    total: number;
    current: number;
    pageSize: number;
    component: React.ElementType;
}

const AuditLogPaginatedLoadingPlaceholder: React.FC<Props> = ({ total, current, pageSize, component: Component }) => {
    const loadingItemsCount = Math.min(total - current, pageSize);

    return (
        <>
            {range(loadingItemsCount).map((index) => (
                <Component key={index} />
            ))}
        </>
    );
};

export default AuditLogPaginatedLoadingPlaceholder;
