import { useAngularService, useAngularWatch } from 'angulareact';
import { useMemo } from 'react';

import {
    FieldInstance,
    SpecialFieldsKey,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

interface UseGroupGlobalFieldsExports {
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    workflowVersionType?: WorkflowVersionType | undefined;
}
function useGroupGlobalFields({
    groupId,
    workflowVersionType,
}: UseGroupGlobalFieldsExports): Record<
    TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}` | SpecialFieldsKey,
    FieldInstance
> {
    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    return useMemo(() => {
        if (!groupId || !workflowVersionType) {
            return {};
        }

        if (workflowVersionType === WorkflowVersionType.PUBLISHED) {
            return groupsMap[groupId]?.globalFieldsMap || {};
        } else {
            return groupsMap[groupId]?.globalFieldsDraftMap || {};
        }
    }, [groupsMap, groupId, workflowVersionType]);
}

export default useGroupGlobalFields;
