import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import MultiEntitySelectorEditEntityLabelModal from './components/MultiEntitySelectorEditEntityLabelModal';
import MultiEntitySelectorEntityLabel from './components/MultiEntitySelectorEntityLabel';
import MultiEntitySelectorItemBox from './components/MultiEntitySelectorItemBox';
import MultiEntitySelectorItemRow from './components/MultiEntitySelectorItemRow';
import type MultiEntitySelectorCustomComponents from './types/MultiEntitySelectorCustomComponents';

import { Checkbox, KebabMenuButton, Menu, MenuItem, TextEllipsis } from '@tonkean/infrastructure';
import { ArrowDownIcon, ArrowUpIcon } from '@tonkean/svg';
import type { MultiEntitySelectorEntityOption, MultiEntitySelectorSavedEntity } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const ItemLabelWrapper = styled.div`
    flex-grow: 1;
    padding: 5px 0;
`;

const ItemActionsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export type MultiEntitySelectorGetCustomMenuItems<T extends MultiEntitySelectorSavedEntity> = (
    item: T,
    setItem: (newValue: T) => void,
) => React.ReactNode[];

interface Props<T extends MultiEntitySelectorSavedEntity> {
    name: string;
    entityTypeLabel: string;
    savedEntity: T;
    entityOption: MultiEntitySelectorEntityOption<T>;
    customComponents: MultiEntitySelectorCustomComponents<T>;
    moveUp: () => void;
    moveUpDisabled: boolean;
    moveDown: () => void;
    moveDownDisabled: boolean;
    removeEntity: (entityIdToRemove: string) => void;
    hideEditLabel: boolean;
    hideEditIndex: boolean;
    hideEditShown: boolean;
    getCustomMenuItems?: MultiEntitySelectorGetCustomMenuItems<T>;
}

const MultiEntitySelectorItem = <T extends MultiEntitySelectorSavedEntity>({
    name,
    entityTypeLabel,
    savedEntity,
    entityOption,
    customComponents,
    moveUp,
    moveUpDisabled,
    moveDown,
    moveDownDisabled,
    removeEntity,
    hideEditLabel,
    hideEditIndex,
    hideEditShown,
    getCustomMenuItems = () => [],
}: Props<T>) => {
    const kebabMenuRef = useRef<HTMLButtonElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const EntityLabelComponent = customComponents.entityLabelComponent;
    const EntityDescriptionComponent = customComponents.entityDescriptionComponent;

    const [editEntityLabelModalOpen, setEditEntityLabelModalOpen] = useState(false);
    const { setValue: setLabel } = useField<string | undefined>(`${name}.label`)[2];

    const menuItems: JSX.Element[] = [];

    if (!hideEditLabel) {
        menuItems.push(
            <MenuItem
                data-automation="multi-entity-selector-item-edit-label"
                key="edit-label"
                onClick={() => setEditEntityLabelModalOpen(true)}
            >
                Edit {entityTypeLabel} label
            </MenuItem>,
            <MenuItem
                key="restore-default-label"
                data-automation="multi-entity-selector-item-restore-default-label"
                onClick={() => setLabel(undefined)}
            >
                Restore default label
            </MenuItem>,
        );
    }

    menuItems.push(
        <MenuItem
            key="remove"
            data-automation="multi-entity-selector-item-remove-action"
            onClick={() => removeEntity(savedEntity.id)}
        >
            Remove {entityTypeLabel}
        </MenuItem>,
    );

    if (!hideEditIndex) {
        menuItems.push(
            <MenuItem
                key="move-up"
                data-automation="multi-entity-selector-item-move-up"
                onClick={moveUp}
                disabled={moveUpDisabled}
            >
                Move Up
            </MenuItem>,
            <MenuItem
                key="move-down"
                data-automation="multi-entity-selector-item-move-down"
                onClick={moveDown}
                disabled={moveDownDisabled}
            >
                Move Down
            </MenuItem>,
        );
    }

    const [{ value: item }, , { setValue: setItem }] = useField<T>(name);

    return (
        <MultiEntitySelectorItemRow>
            {!hideEditShown && <Checkbox name={`${name}.shown`} boldLabel={false} highlighted labelFillSpace />}
            <MultiEntitySelectorItemBox>
                <ItemLabelWrapper>
                    {EntityLabelComponent ? (
                        <EntityLabelComponent entityOption={entityOption} savedEntity={savedEntity} />
                    ) : (
                        <MultiEntitySelectorEntityLabel>
                            <TextEllipsis numberOfLines={1} tooltip>
                                {savedEntity.label || entityOption.savedEntityTemplate.defaultLabel}
                            </TextEllipsis>
                        </MultiEntitySelectorEntityLabel>
                    )}
                    {EntityDescriptionComponent && (
                        <EntityDescriptionComponent entityOption={entityOption} savedEntity={savedEntity} />
                    )}
                </ItemLabelWrapper>

                <ItemActionsWrapper>
                    {!hideEditIndex && (
                        <>
                            <IconButton
                                disabled={moveDownDisabled}
                                onClick={moveDown}
                                aria-label="Move down"
                                className="move-button"
                                data-automation="multi-entity-selector-item-move-action-down-button"
                                flat
                            >
                                <ArrowDownIcon />
                            </IconButton>
                            <IconButton
                                disabled={moveUpDisabled}
                                onClick={moveUp}
                                aria-label="Move down"
                                className="move-button"
                                data-automation="multi-entity-selector-item-move-action-up-button"
                                flat
                            >
                                <ArrowUpIcon />
                            </IconButton>
                        </>
                    )}
                    <Menu
                        show={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        placement="bottom-end"
                        menuItems={[...menuItems, ...getCustomMenuItems(item, setItem)]}
                    >
                        <KebabMenuButton
                            data-automation="multi-entity-selector-item-kebab-menu-button"
                            onClick={() => setMenuOpen(true)}
                            aria-label="edit-label"
                            ref={kebabMenuRef}
                            flat
                        />
                    </Menu>

                    <MultiEntitySelectorEditEntityLabelModal
                        open={editEntityLabelModalOpen}
                        name={name}
                        onClose={() => setEditEntityLabelModalOpen(false)}
                        savedEntity={savedEntity}
                        entityOption={entityOption}
                        entityTypeLabel={entityTypeLabel}
                    />
                </ItemActionsWrapper>
            </MultiEntitySelectorItemBox>
        </MultiEntitySelectorItemRow>
    );
};

export default MultiEntitySelectorItem;
