import type { TElement } from '@udecode/plate';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as MapperIcon } from '../../../../images/icons/mapper.svg';
import SolutionPageSolutionMapperField from '../../SolutionsPageModule/components/SolutionPageSolutionMapperField';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { SavingIndicator, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { SolutionMapper, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const ProcessMapLabelTitle = styled.div`
    color: ${Theme.colors.gray_800};
`;

const ProcessMapLabelSubtitle = styled.div`
    color: ${Theme.colors.gray_700};
`;

const ProcessMapFooterLabel = styled.div`
    display: flex;
    align-items: center;
`;

const ProcessMapLabel = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
`;

const ProcessMapSection = styled.div`
    background: white;
    border: 1px solid ${Theme.colors.gray_300};
    margin-top: 20px;
    padding: 14px 24px 24px 24px;
    border-radius: 8px;
`;

const ProcessMapTitleSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
`;

const ProcessMapperGraphSection = styled.div`
    margin-top: 18px;
    min-height: 200px;
`;
const StyledMapperIcon = styled(MapperIcon)`
    width: 20px;
    height: 20px;
`;

const StyledClickableLink = styled(ClickableLink)`
    margin-left: 8px;
`;

const StyledSavingIndicator = styled(SavingIndicator)`
    margin-right: 8px;
`;

interface Props {
    processText: TElement[];
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
}
const ProcessBuilderPageProcessMapping: React.FC<Props> = ({ processText, workflowFolderId, projectId }) => {
    const [selectedSolutionMapper, setSelectedSolutionMapper] = useState<SolutionMapper | undefined>();

    const [
        { data: latestSolutionMapper, loading: solutionMapperLoading, error: solutionMapperError },
        getLatestSolutionMapper,
    ] = useLazyTonkeanService('getLatestSolutionMapper');

    const [
        { loading: generateProcessMapperLoading, error: generateProcessMapperError, data: createdSolutionMapper },
        generateProcessMapper,
    ] = useLazyTonkeanService('generateProcessMapper');

    useEffect(() => {
        getLatestSolutionMapper(workflowFolderId);
    }, [getLatestSolutionMapper, workflowFolderId]);

    useEffect(() => {
        setSelectedSolutionMapper(latestSolutionMapper?.solutionMapper);
    }, [latestSolutionMapper]);

    return (
        <ProcessMapSection>
            <ProcessMapTitleSection>
                <ProcessMapFooterLabel>
                    <StyledMapperIcon />
                    <ProcessMapLabel>
                        <ProcessMapLabelTitle>Process Mapping</ProcessMapLabelTitle>
                        <ProcessMapLabelSubtitle>
                            A visual representation of your business process
                        </ProcessMapLabelSubtitle>
                    </ProcessMapLabel>
                </ProcessMapFooterLabel>

                <div>
                    <StyledSavingIndicator error={generateProcessMapperError} loading={generateProcessMapperLoading} />
                    <Button
                        onClick={() => {
                            if (processText) {
                                generateProcessMapper(
                                    projectId,
                                    utils.richTextToPlainText(processText),
                                    workflowFolderId,
                                );
                            }
                        }}
                        disabled={generateProcessMapperLoading || !utils.richTextToPlainText(processText)}
                        shape={ButtonShape.ROUND}
                        backgroundColor={Theme.current.palette.colorPicker.HEX_605CE4}
                        size={ButtonSize.BIG}
                    >
                        Generate Process Mapping
                        {generateProcessMapperError && (
                            <SimpleErrorStateMessage error="Error generating the process map" />
                        )}
                    </Button>

                    <StyledClickableLink
                        state="product.solutionMapper"
                        options={{ location: 'replace' }}
                        params={{ workflowFolderId, solutionMapperId: selectedSolutionMapper?.id }}
                    >
                        Edit
                    </StyledClickableLink>
                </div>
            </ProcessMapTitleSection>

            <ProcessMapperGraphSection>
                <SolutionPageSolutionMapperField
                    workflowFolderId={workflowFolderId}
                    onCreateSolutionMapperClicked={() => {}}
                    isEditingEnabled={false}
                    selectedSolutionMapper={selectedSolutionMapper}
                    setSelectedSolutionMapper={setSelectedSolutionMapper}
                />
            </ProcessMapperGraphSection>
        </ProcessMapSection>
    );
};

export default ProcessBuilderPageProcessMapping;
