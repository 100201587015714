import { useCallback } from 'react';

import type { InitiativeRowData } from '@tonkean/items-grid';
import type { Initiative } from '@tonkean/tonkean-entities';

const useHandleCreateWrapper = (
    handleCreate: (updatedRow: InitiativeRowData) => Promise<Initiative>,
    fastInterval?: () => void,
) => {
    return useCallback(
        async (updatedRow: InitiativeRowData) => {
            const createResponse = await handleCreate(updatedRow);
            fastInterval?.();

            return createResponse;
        },
        [fastInterval, handleCreate],
    );
};

export default useHandleCreateWrapper;
