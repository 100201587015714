import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface TonkeanCreateParams<T extends object, U extends object> {
    name: string;
    state: T;
    actions: StateCreator<
        T,
        [['zustand/devtools', never], ['zustand/immer', never]],
        [['zustand/immer', never], ['zustand/devtools', never]],
        U
    >;
}
function tonkeanCreate<T extends object, U extends object>({ name, state, actions }: TonkeanCreateParams<T, U>) {
    return create<T & U>()(
        devtools(
            immer((...a) => Object.assign({}, state, (actions as any)(...a))),
            { name },
        ),
    );
}

export { tonkeanCreate };
