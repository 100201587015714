import { ReactComponent as TonkeanLogoIcon } from '../../../../images/icons/tonkean-logo.svg';
import { ReactComponent as LightningIcon } from '../../../../images/icons/lightning.svg';
import React from 'react';
import WorkerHistoryItemActions from '../../../actions/WorkerHistoryItemActions';
import { timeDiffShortDiffFilter } from '../../../services/filters/time-diff.filter';
import WorkerHistoryItemTrigger from './componnents/WorkerHistoryItemTrigger';
import WorkerHistoryItemAction from './componnents/WorkerHistoryItemAction';
import StateLink from '@tonkean/tui-basic/StateLink';

export default class WorkerHistoryItem extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.listener = null;

        this.workerHistoryItemStore = props.workerHistoryItemStore;
        this.trackHelper = props.$rootScope.trackHelper;
        this.logicBlockTypes = props.consts.getLogicBlockTypes();

        // Initialize the state of the track list item.
        this.state = this.workerHistoryItemStore.getNewState(this.props.id);
    }

    /**
     * Happens once, when the component is mounted to the page.
     * Registers our onStoreUpdated function as a listener on the store.
     * Also saves the returned remove listener, so we can remove the listener we've just added.
     */
    componentDidMount() {
        this.listener = this.workerHistoryItemStore.addListener(this.onStoreUpdated.bind(this));
    }

    /**
     * Happens once, when the component is unmounted from the page.
     * Removed the listener from the store, using our removeListener (if it was initialized).
     */
    componentWillUnmount() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    /**
     * Registered as a listener to the store. Triggered whenever the store emits an update.
     */
    onStoreUpdated(id) {
        // If we didn't get an id (means everyone should update) or if the id is ours - update the state (causes re-render).
        // Alternatively, if we are a linked item and the real track's id is the given id - we should also render.
        if (this.workerHistoryItemStore.shouldItemUpdate(this.props.id, id)) {
            this.setState(this.workerHistoryItemStore.getState(this.props.id));
        }
    }

    toggleActions() {
        WorkerHistoryItemActions.toggleActions(this.props.id, !this.state.togggleActions);
    }

    inspect(initiativeId) {}

    /**
     * The main render function
     */
    render() {
        // Give the person that was on the time of the event, not the current owner.
        const item = this.state.item;
        const id = `worker-history-item-${this.props.id}-tnk-worker-history`;

        // Test run shouldnt be in the feed.
        if (item.workerRunReason === 'TEST_RUN') {
            return null;
        }

        // AUTONOMOUS and disabled actions shouldnt be in the feed.
        const filteredActions = item.workerRunLogics.filter(
            (logic) => logic.customTriggerType !== 'AUTONOMOUS' && !logic.disabled,
        );

        const firstTrigger = {};
        if (
            item.workerRunReasonData &&
            item.workerRunReasonData.customTriggers &&
            item.workerRunReasonData.customTriggers.length
        ) {
            firstTrigger.id = item.workerRunReasonData.customTriggers[0].customTriggerId;
            firstTrigger.displayName = item.workerRunReasonData.customTriggers[0].customTriggerName;
        }

        const stateLinkTargetState = 'product.workers';
        const stateLinkParams = {
            tab: 'history',
            g: this.props.groupId,
            environment: item.testRun ? 'build' : 'production',
            wr: this.props.id,
            defaultFilterSearchTerm: item.initiative.title ?? item.initiativeId,
        };
        // Should notify unless it's only a param change (= state name remains the same)
        const alreadyInHistoryTab =
            this.props.$state.current.name === stateLinkTargetState && this.props.$state.params.tab === 'history';
        const stateLinkOptions = alreadyInHistoryTab ? { notify: false } : {};

        return (
            <div id={id} className="worker-history-item relative">
                <div className="flex">
                    {/* Module Icon*/}
                    <div className="svg-icon-lg mod-static">
                        <span className="tnk-icon">
                            <TonkeanLogoIcon />
                        </span>
                    </div>

                    <div className="flex-grow">
                        {/* First row */}
                        <div className="flex-vmiddle">
                            {/* Filter Trigger */}
                            <div className="flex-vmiddle flex-grow common-size-xxxxs common-color-grey worker-history-item-title">
                                <div className="svg-icon-xs mod-static">
                                    <span className="tnk-icon">
                                        <LightningIcon />
                                    </span>
                                </div>
                                <span
                                    className="tnk-tooltip mod-top pointer"
                                    onClick={this.props.uiActions.filterTrigger.bind(this, firstTrigger)}
                                >
                                    {firstTrigger.displayName}
                                    <span className="tnk-tooltip-text ">Filter by this trigger</span>
                                    <span className="edit-button filter-trigger-icon common-size-xxxxxs">
                                        <i className="fa fa-filter" />
                                    </span>
                                </span>
                            </div>

                            {/* Time Diff */}
                            <div className="flex-no-shrink">
                                <span className="tnk-tooltip padding-normal-xs mod-top common-color-grey common-size-xxxxxs">
                                    {timeDiffShortDiffFilter(item.created, true)}
                                    <span className="tnk-tooltip-text">{new Date(item.created).toLocaleString()}</span>
                                </span>
                            </div>

                            {/* Inspect - open worker run history modal */}
                            <div>
                                <StateLink
                                    className="btn btn-default btn-no-border btn-slim tnk-tooltip mod-top"
                                    state={stateLinkTargetState}
                                    params={stateLinkParams}
                                    options={stateLinkOptions}
                                    onClick={this.props.closeSidePane}
                                >
                                    <i className="fa fa-history" />
                                    <span className="tnk-tooltip-text">Inspect</span>
                                </StateLink>
                            </div>
                        </div>

                        {/* Second row */}
                        <div className="flex">
                            <div className="flex-grow">
                                {/* Worker Item Trigger */}
                                <WorkerHistoryItemTrigger
                                    goToTrack={this.props.uiActions.goToTrack}
                                    id={item.id}
                                    initiative={item.initiative}
                                    triggers={item.workerRunReasonData.customTriggers}
                                    filterInitiative={this.props.uiActions.filterInitiative}
                                />
                            </div>

                            {/* Wrapped in a div so the flex wont strech the div vertically, which will cause the dropdown to appear in a wrong place */}
                            <div className="flex-no-shrink padding-normal-xs">
                                <button
                                    className="btn btn-no-border btn-no-style flex-grow"
                                    onClick={this.toggleActions.bind(this)}
                                >
                                    <div>
                                        {/* Collapsed children  */}
                                        <div
                                            className={`worker-history-actions-collapsed-indicator common-width-auto common-size-xxxxxs pointer svg-icon svg-icon-xsm ${
                                                item.hasError ? 'common-color-danger' : 'common-color-success'
                                            }`}
                                        >
                                            {filteredActions.length} Actions
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* History Items Actions */}
                {this.state.togggleActions &&
                    filteredActions.map((action) => (
                        <div key={`action-${action.id}`} className="common-size-xxxxs padding-normal-xs">
                            <WorkerHistoryItemAction
                                logicBlockTypes={this.logicBlockTypes}
                                filterTrigger={this.props.uiActions.filterTrigger}
                                groupId={this.props.groupId}
                                customTriggerManager={this.props.customTriggerManager}
                                workflowVersionManager={this.props.workflowVersionManager}
                                action={action}
                            />
                        </div>
                    ))}
            </div>
        );
    }
}
