import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { VIEW_TYPES } from '@tonkean/constants';

function SyncTracksModalCtrl(
    $scope,
    $rootScope,
    authenticationService,
    integrations,
    modalUtils,
    projectManager,
    trackHelper,
    workflowVersionManager,
    groupId,
    workflowVersionId,
    targetEntityId,
    syncConfigCacheManager,
) {
    $scope.pm = $rootScope.pm;
    $scope.trackHelper = $rootScope.trackHelper;
    $scope.syncSettings = integrations.getSyncSettings();
    $scope.modalUtils = modalUtils;
    $scope.viewTypes = VIEW_TYPES;
    $scope.as = authenticationService;

    $scope.data = {
        groupId,
        workflowVersionId,
        targetEntityId,
        targetEntityType: getTonkeanEntityType(targetEntityId),
        isWorkflowVersionTargetEntity: false,
        isInitiativeTargetEntity: false,
        targetEntity: null,
        targetEntityName: null,
        editMode: false,
        syncConfig: null,
        stopPolling: false,
    };

    $scope.init = function () {
        if ($scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.WORKFLOW_VERSION) {
            $scope.data.targetEntity = workflowVersionManager.getCachedWorkflowVersion($scope.data.targetEntityId);
            $scope.data.syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.targetEntityId);
            $scope.data.targetEntityName = projectManager.groupsMap[$scope.data.groupId].name;
            $scope.data.isWorkflowVersionTargetEntity = true;
        } else if ($scope.data.targetEntityType === TONKEAN_ENTITY_TYPE.INITIATIVE) {
            const initiative = trackHelper.getInitiativeFromCache($scope.data.targetEntityId);
            $scope.data.targetEntity = initiative;
            $scope.data.syncConfig = initiative.syncConfig;
            $scope.data.targetEntityName = initiative.title;
            $scope.data.isInitiativeTargetEntity = true;
        }

        $scope.data.editMode = !$scope.data.syncConfig;
    };

    // Unsubscribe polling on destroy
    $scope.$on('$destroy', function () {
        $scope.data.stopPolling = true;
    });

    $scope.init();
}

export default angular.module('tonkean.app').controller('SyncTracksModalCtrl', SyncTracksModalCtrl);
