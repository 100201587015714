import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as CreateFormIcon } from '../../../../../images/icons/create-form.svg';
import { ReactComponent as UpdateFormIcon } from '../../../../../images/icons/update-form.svg';

import { HighlightableText, TextEllipsis } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FormType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
`;

const FormDescriptionIcon = styled.div`
    margin-right: 6px;
`;

const FormDescriptionText = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormName = styled.div`
    margin-bottom: 4px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;
const FormDate = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

interface Props {
    form: Form;
    searchTerm?: string;
    formSubmission?: number;
    showEmptyTimeAgoWhenNoSubmission?: boolean;
}

const FormDescription: React.FC<Props> = ({
    form,
    searchTerm,
    formSubmission,
    showEmptyTimeAgoWhenNoSubmission = false,
}) => {
    const timeAgo = useMemo(() => {
        const isUpdate = form.updated && form.updated > form.created;

        let description = isUpdate ? 'Updated' : 'Created';
        let date = dayjs(isUpdate ? form.updated : form.created);
        if (formSubmission) {
            description = 'Submitted';
            date = dayjs(formSubmission);
        } else if (showEmptyTimeAgoWhenNoSubmission) {
            return undefined;
        }

        const hour = date.format('hh:mm A');

        if (date.isToday()) {
            return `${description} on ${hour}`;
        } else if (date.isYesterday()) {
            return `${description} Yesterday on ${hour}`;
        }

        const day = date.format('MM.DD.YY');
        return `${description} on ${day} ${hour}`;
    }, [form.updated, form.created, formSubmission, showEmptyTimeAgoWhenNoSubmission]);

    return (
        <Wrapper>
            <FormDescriptionIcon>
                {form.formType === FormType.CREATE && (
                    <span className="tnk-icon">
                        <CreateFormIcon />
                    </span>
                )}
                {form.formType === FormType.UPDATE && (
                    <span className="tnk-icon">
                        <UpdateFormIcon />
                    </span>
                )}
            </FormDescriptionIcon>

            <FormDescriptionText>
                <FormName data-automation={`form-description-form-name-${form.displayName}`}>
                    <TextEllipsis numberOfLines={1} tooltip>
                        <HighlightableText text={form.displayName || 'New form'} highlightText={searchTerm} />
                    </TextEllipsis>
                </FormName>

                {timeAgo && (
                    <FormDate data-automation="form-description-form-date">
                        <TextEllipsis numberOfLines={1} tooltip>
                            {timeAgo}
                        </TextEllipsis>
                    </FormDate>
                )}
            </FormDescriptionText>
        </Wrapper>
    );
};

export default FormDescription;
