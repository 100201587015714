import { useMemo } from 'react';

import type HistoryField from '../entities/HistoryField';
import addHistoryFieldToItsGroup from '../utils/addHistoryFieldToItsGroup';
import constructHistoryTableGroup from '../utils/constructHistoryTableGroup';
import getMatchedEntityToFieldsGroupMap from '../utils/getMatchedEntityToFieldsGroupMap';
import type HistoryTableGroup from '../utils/HistoryTableGroup';

/**
 * React hook that groups list of history fields by matched entity or type.
 *
 * @param fields - list of history fields.
 * @param workflowVersionId - the workflow version id.
 * @param customTriggerManager - angular's custom trigger manager.
 * @return field groups.
 */
function useGroupHistoryTableFieldsByType(
    fields: HistoryField[],
    workflowVersionId: string | undefined,
    customTriggerManager,
): HistoryTableGroup[] {
    return useMemo(() => {
        const formulaFieldsGroup = constructHistoryTableGroup('formulaFields', 'Formula', 'FORMULA');
        const specialFieldsGroup = constructHistoryTableGroup('basicFields', 'Basic Fields', 'Manual');
        const customFieldsGroup = constructHistoryTableGroup('customFields', 'Custom Fields', 'Manual');

        const matchedEntityToFieldsGroupMap = getMatchedEntityToFieldsGroupMap(
            fields,
            workflowVersionId,
            customTriggerManager,
        );

        fields
            // History doesn't show global field changes.
            .filter((tableField) => !tableField.isGlobalField)
            .forEach(
                addHistoryFieldToItsGroup(
                    formulaFieldsGroup,
                    specialFieldsGroup,
                    customFieldsGroup,
                    matchedEntityToFieldsGroupMap,
                ),
            );

        const matchedEntityGroups = Object.values(matchedEntityToFieldsGroupMap);

        return [...matchedEntityGroups, formulaFieldsGroup, customFieldsGroup, specialFieldsGroup];
    }, [customTriggerManager, fields, workflowVersionId]);
}

export default useGroupHistoryTableFieldsByType;
