import type { OperationConfiguration, Person, TonkeanId } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';

function createOperationConfiguration(
    id: string,
    projectId: string,
    workflowFolderId: string,
    solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>,
    creator: Person,
    displayName: string,
): OperationConfiguration {
    return {
        id,
        projectId,
        workflowFolderId,
        solutionMapperId,
        displayName,
        created: Date.now(),
        creatorId: creator.id,
        apps: [],
        automatedParts: [],
        manualParts: [],
    };
}

export default createOperationConfiguration;
