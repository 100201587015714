import { getOperandTree } from '@tonkean/tonkean-entities';

function InlineFormulaEditorModalCtrl(
    $scope,
    utils,
    customFieldsManager,
    groupId,
    workflowVersionId,
    overrideFormulaOperator,
    existingFormula,
    additionalTabs,
    customTrigger,
    projectIntegration,
) {
    $scope.data = {
        groupId,
        workflowVersionId,
        overrideFormulaOperator,
        additionalTabs: additionalTabs || [],
        customTrigger,
        projectIntegration,
        updatedDefinition: null,
        createMode: true,
        existingFieldDefinitionMock: null,
        initialFormula: null,
        formulaTree: undefined,

        useAggregation: false,
        formulaExpressionTree: undefined,
    };

    $scope.init = function () {
        $scope.data.loading = true;

        Promise.all(
            $scope.data.additionalTabs.map((singleAdditionalTab) => {
                return singleAdditionalTab.getFields();
            }),
        ).then((additionalTabsFields) => {
            if (!utils.isNullOrEmpty(existingFormula)) {
                // If we got an existing formula, we're not in create mode.
                $scope.data.createMode = false;

                // Make sure the formula we've got uses single curly braces and not double (the server expects a single
                // curly brace when using the tnkColumnFormulaField). Also clear any match from piped parameters.
                existingFormula = existingFormula.replace(/{{(.*?)}}/g, (match, firstMatchGroup) => {
                    // match - the entire match (including the double curly brackets surrounding it).
                    // firstMatchGroup - the value within the match (without the curly brackets).
                    // Split the values we got by pipe. We expect to always have an id first.
                    // Then return the field definition id with single curly braces.
                    const fieldDefinitionId = firstMatchGroup.split('|')[0];
                    return `{${fieldDefinitionId}}`;
                });

                // The original formula expression should contain the field names instead of ids.
                // The regex matches any text with surrounded with single curly braces, but not two curly braces.
                const originalFormulaExpression = existingFormula.replace(
                    /{([^{}]*)}(?!})/g,
                    (match, firstMatchGroup) => {
                        // match - the entire match (including the single curly brackets surrounding it).
                        // firstMatchGroup - the value within the match (without the curly brackets) - the field definition id.
                        // Get the label for the found field definition id and return it with single curly brackets.
                        // Just take the first group (the value) and return it with single surrounding curly brackets.
                        return `{${getFieldLabel(firstMatchGroup, additionalTabsFields)}}`;
                    },
                );

                // Save the initial formula. If there's no change, clicking on "Done" will call $dismiss and not $close.
                $scope.data.initialFormula = angular.copy(existingFormula);

                // The tnkColumnFormulaField can only receive a field definition for edit mode.
                // We only have a formula expression, so we mock a definition for it.
                $scope.data.existingFieldDefinitionMock = {
                    definition: {
                        formulaExpression: existingFormula,
                        originalFormulaExpression,
                    },
                };
            } else {
                // No existing formula, we are in create mode.
                $scope.data.createMode = true;
            }

            if (overrideFormulaOperator) {
                $scope.data.formulaTree = getOperandTree(overrideFormulaOperator);
            }

            $scope.data.loading = false;
        });
    };

    $scope.onDefinitionChange = function (newDefinition, expressionNode) {
        // Saving the updated definition so we can pass it on when done is clicked.
        $scope.data.updatedDefinition = newDefinition;

        $scope.data.expressionNode = expressionNode;
    };

    $scope.onDoneClicked = function () {
        if (!$scope.data.updatedDefinition) {
            $scope.dismiss();
            return;
        }

        const parsedFormulaExpression = $scope.data.updatedDefinition.definition.formulaExpression;
        const parsedReadableFormulaExpression = $scope.data.updatedDefinition.definition.originalFormulaExpression;

        // If the formula didn't change, dismiss the modal instead of closing with a new formula.
        if (parsedFormulaExpression === $scope.data.initialFormula) {
            $scope.$dismiss();
            return;
        }

        // Formula fields use a single curly brace for fields, but inline formulas (expressions in general) use 2 curly braces...
        // The regex matches any text with surrounded with single curly braces, but not two curly braces.
        $scope.$close({
            // The formula with field ids.
            formulaExpression: parsedFormulaExpression.replace(/{([^{}]*)}(?!})/g, '{{$1}}'),
            // The formula with field names
            readableFormulaExpression: parsedReadableFormulaExpression.replace(/{([^{}]*)}(?!})/g, '{{$1}}'),
            expressionNode: $scope.data.expressionNode,
        });
    };

    function getFieldLabel(fieldId, additionalTabsFields) {
        if ($scope.data.workflowVersionId) {
            // Try to get the field and return its name.
            const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
                $scope.data.workflowVersionId,
                fieldId,
            );
            return fieldDefinition ? fieldDefinition.name : 'unknown field';
        } else {
            const additionalFields = additionalTabsFields.flat();
            const matchedAdditionalField = additionalFields.find((singleAdditionalField) => {
                return singleAdditionalField.id === fieldId;
            });

            return matchedAdditionalField?.label ? matchedAdditionalField.label : 'unknown field';
        }
    }

    $scope.init();
}

angular.module('tonkean.app').controller('InlineFormulaEditorModalCtrl', InlineFormulaEditorModalCtrl);
