<div class="html-editor-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn svg-icon" ng-click="$dismiss()">
        <tnk-icon src="../../../svg/src/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title -->
    <div class="flex-vmiddle margin-top-xs">
        <div class="common-size-l common-bold margin-bottom-xs flex-grow">Edit HTML Content</div>

        <!-- Preview -->
        <div class="common-size-s">
            <button
                class="btn btn-default flex-vmiddle common-no-outline margin-bottom-xxs"
                ng-class="{ 'btn-primary-black': data.showPreview }"
                ng-click="previewContent(); togglePreview()"
                uib-tooltip="Show how the HTML content will look like"
                tooltip-enable="!data.showPreview"
                tooltip-placement="auto"
            >
                <span class="common-color-grey" tooltip-placement="bottom-left">
                    <i class="fa fa-flask"></i>
                </span>
                <span class="margin-left margin-right-xs">
                    {{ data.showPreview ? 'Close Preview' : 'Preview Result' }}
                </span>
            </button>
        </div>
    </div>

    <div ng-if="data.validationError" class="alert alert-danger">
        Error occurred with parsing fields. "{{ data.validationError }}"
    </div>

    <!-- Editor -->
    <div ng-hide="data.showPreview">
        <div class="html-editor-container" text-angular ng-model="data.content"></div>
    </div>

    <!-- Example item evaluated expression -->
    <div
        class="html-preview-container rounded-box padding-normal-sm padding-left-xs"
        ng-if="data.showPreview && !data.doNotEvaluatePreview && data.evaluatedExpressionValue"
    >
        <!-- Empty Placeholder -->
        <div ng-if="!data.content">
            No content is available for preview.
            <span ng-click="togglePreview()">Go back</span>
            and add some content.
        </div>

        <!-- Content Preview -->
        <div ng-if="data.content">
            <!-- Loading State Evaluated text -->
            <span
                class="margin-top-xxxlg flex mod-align-center mod-justify-center"
                ng-if="!data.doNotEvaluatePreview && data.evaluatedExpressionLoading"
            >
                <span class="loading-small margin-right"></span>
                <span class="common-size-14 common-color-grey">Loading Preview...</span>
            </span>

            <!-- Error state -->
            <span
                class="common-color-danger common-size-xxxs margin-right"
                ng-if="data.evaluationError && !data.evaluatedExpressionLoading"
            >
                {{ data.evaluationError }}
            </span>

            <div ng-if="!data.evaluatedExpressionLoading" class="ta-preview">
                <span ta-bind ng-model="data.evaluatedExpressionValue" class="inline-block"></span>
            </div>
        </div>
    </div>

    <button class="btn btn-primary margin-top" ng-click="saveHtml()">Save</button>
</div>
