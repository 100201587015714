<div
    class="import-tracks-modal relative common-close-btn-container"
    ng-class="{ 'mod-no-padding': noContainerPadding }"
>
    <!-- X Button -->
    <span
        class="common-close-btn padding-normal-sm"
        ng-if="!showBack && !showInnerBack && dismiss"
        ng-click="dismiss()"
    >
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Back-->
    <a
        ng-if="showBack && dismiss && data.currentStep === 'selectIntegration' && !data.selectedProjectIntegration"
        ng-hide="posting"
        class="margin-bottom-xxlg block common-size-xxs"
        ng-click="dismiss()"
    >
        Back
    </a>

    <!-- Inner Back when no sync data yet-->
    <a
        ng-if="showInnerBack && data.currentStep !== 'selectIntegration' && !syncData"
        ng-hide="posting"
        class="margin-bottom-xlg block common-size-xxs"
        ng-click="back()"
    >
        <i class="fa fa-chevron-left margin-right"></i>
        Back
    </a>

    <!-- Title -->
    <div ng-if="!sectionVisibility || sectionVisibility.title">
        <div class="common-title mod-no-top">
            <span class="common-break-long" ng-if="!title">
                Import Items to
                <span bind-user-html="parentName" ng-if="parentName"></span>
            </span>
            <span ng-bind="title"></span>
        </div>
        <hr />
    </div>

    <!-- Loading -->
    <div class="text-center" ng-show="posting">
        <i class="loading-large"></i>

        <h3>Saving...</h3>
    </div>

    <div class="relative" ng-hide="posting">
        <!-- Integration selection -->
        <div ng-if="data.currentStep == 'selectIntegration' && !disableImportFromIntegrations">
            <div class="integration-group mod-inline modal-inline-container">
                <div
                    class="flex flex-vmiddle margin-bottom-lg"
                    ng-if="!onlySync"
                    ng-hide="data.selectedProjectIntegration"
                >
                    <div class="margin-right-lg">Upload a CSV file:</div>
                    <div
                        class="
                            btn btn-default
                            margin-right-xs
                            common-size-xxs
                            margin-bottom-xs
                            integration-selection-button
                        "
                        ng-click="importFromCSVClicked()"
                    >
                        <i class="fa fa-file-excel-o margin-right common-size-s padding-normal-xs"></i>
                        A CSV file
                    </div>
                </div>

                <!-- Systems to sync from -->
                <div>
                    <div class="margin-bottom-lg common-title-sm data-source-config-panel">Use data source:</div>
                    <input
                        type="text"
                        style="width: 95%"
                        class="form-control margin-bottom-lg ng-pristine ng-empty ng-touched"
                        ng-model="data.dataSourceFilter"
                        placeholder="Search for a data source.."
                    />
                    <tnk-project-integrations-view
                        on-click-connected-data-source="(selectProjectIntegration)"
                        view-type="'LIST'"
                        filter-by-text="data.dataSourceFilter"
                        filter-integrations-with-no-entities="true"
                        is-list-filter-by-solution-access="true"
                    ></tnk-project-integrations-view>
                </div>
            </div>
        </div>

        <!-- Selecting the entity to import and the items -->
        <div ng-if="data.currentStep == 'selectEntity'">
            <!--Select type header-->
            <div ng-if="!sectionVisibility || sectionVisibility.sync">
                <div class="import-tracks-integration-logo">
                    <div>
                        <!-- Icon when there's NO icon url. -->
                        <i
                            ng-if="!data.selectedProjectIntegration.iconUrl"
                            class="integration-group-header-connected-logo margin-none mod-{{
                                data.selectedProjectIntegration.integration.integrationType | lowercase
                            }}"
                        ></i>
                        <!-- Icon when there's an iconUrl -->
                        <div
                            ng-if="data.selectedProjectIntegration.iconUrl"
                            class="integration-group-header-connected-logo mod-manual-icon"
                        >
                            <i
                                class="initiative-integration-icon mod-normal margin-right-xs"
                                ng-style="{
                                    background: 'url(' + data.selectedProjectIntegration.iconUrl + ') no-repeat center'
                                }"
                            ></i>
                            <span>{{ data.selectedProjectIntegration.displayName }}</span>
                        </div>
                    </div>
                </div>
                <p>
                    <span
                        class="flex flex-wrap mod-align-baseline integration-group-header padding-bottom"
                        ng-if="
                            !integrations.integrationSupportsAllEntities(
                                data.selectedProjectIntegration.integration.integrationType
                            ) &&
                            data.selectedEntity &&
                            (onlySync
                                ? data.selectedSyncSettings.supportedEntities.length < 2
                                : data.selectedImportSettings.length < 2)
                        "
                    >
                        Import {{ data.selectedEntityDisplayName }} from
                        {{ data.selectedProjectIntegration.displayName }}
                    </span>
                    <span
                        class="block flex flex-wrap mod-align-baseline integration-group-header padding-bottom"
                        ng-if="
                            integrations.integrationSupportsAllEntities(
                                data.selectedProjectIntegration.integration.integrationType
                            ) ||
                            !data.selectedEntity ||
                            (onlySync
                                ? data.selectedSyncSettings.supportedEntities.length > 1
                                : data.selectedImportSettings.length > 1)
                        "
                    >
                        <span class="common-bold margin-bottom-xs">
                            {{ data.selectedProjectIntegration.displayName }} items:&nbsp;
                        </span>

                        <tnk-entity-selector
                            project-integration="data.selectedProjectIntegration"
                            selected-entity="{{ data.selectedEntity }}"
                            selected-entity-display-name="{{ data.selectedEntityDisplayName }}"
                            mode="{{ data.mode }}"
                            on-entity-selected="onEntityTypeChange(selectedEntity)"
                        ></tnk-entity-selector>
                    </span>
                </p>
                <hr ng-if="data.selectedEntity" />
            </div>

            <div ng-if="data.selectedEntity">
                <p ng-if="loading">
                    <i class="loading-small"></i>
                    Loading entities...
                    <span
                        class="block common-color-grey common-size-xxxxs margin-bottom-lg"
                        ng-if="data.waitingForIndex"
                    >
                        <span>
                            Still collecting and analyzing data from {{ data.selectedProjectIntegration.name }}... (this
                            can take few minutes)
                        </span>
                        <br />
                    </span>
                </p>

                <!--Must be ng-show and not ng-if for the tnk-sync-tracks to be loaded-->
                <div ng-show="!loading">
                    <!--Sync mode-->
                    <div ng-if="data.allowSync">
                        <div
                            ng-click="data.mode = 'sync'"
                            ng-class="{ 'opacity-50': data.mode !== 'sync', pointer: data.mode !== 'sync' }"
                        >
                            <tnk-sync-tracks
                                control="data.syncControl"
                                enabled="data.allowSync"
                                group-id="groupId"
                                target-entity-id="{{ parentInitiative ? parentInitiative.id : workflowVersionId }}"
                                collapsed="data.mode !== 'sync'"
                                on-completed="onClose()"
                                on-error="onError(error)"
                                disable-fields="disableFields"
                                view-type="viewType"
                                sync-data="syncData"
                                initiative="parentInitiative"
                                section-visibility="sectionVisibility"
                                hide-section-headers="hideSectionHeaders"
                                hide-time-range-selection="hideTimeRangeSelection"
                                selected-entity="data.selectedEntity"
                                selected-entity-metadata="data.selectedEntityMetadata"
                                dismiss="dismiss()"
                                project-integration="data.selectedProjectIntegration"
                                mod-small="modSmall"
                                update-group-name="updateGroupName"
                                workflow-version-id="{{ workflowVersionId }}"
                            ></tnk-sync-tracks>
                        </div>

                        <hr ng-if="!onlySync" />
                    </div>

                    <!--Import mode-->
                    <p
                        ng-click="data.mode = 'import'"
                        ng-if="!onlySync"
                        class="common-size-xxs"
                        ng-class="{ pointer: data.allowSync, 'opacity-50': data.mode !== 'import' }"
                    >
                        <span class="common-underline">One time import</span>
                        by selecting from {{ data.entities.length >= 1000 ? '1000+' : data.entities.length }}&nbsp;{{
                            data.selectedProjectIntegration.displayName
                        }}&nbsp;{{
                            data.selectedProjectIntegration.displayName !== data.selectedEntityDisplayName
                                ? data.selectedEntityDisplayName
                                : 'entities'
                        }}:
                    </p>

                    <div ng-if="!onlySync && (!data.allowSync || data.mode === 'import')">
                        <p class="common-color-danger common-size-xxs" ng-if="data.includeIdCount > 100">
                            <i class="fa fa-exclamation-triangle margin-right-xs"></i>
                            Note, the import limit is 100 items. Only the first selected 100 will be imported.
                        </p>
                        <div ng-if="data.entities.length < 1">
                            No {{ data.selectedEntityDisplayName }} entities found.
                        </div>
                        <div ng-if="data.entities.length > 0">
                            <div class="common-size-xxxxs flex-vmiddle margin-bottom">
                                <div class="flex-grow">
                                    <input
                                        class="form-control common-size-xxxxs"
                                        placeholder="Search..."
                                        ng-model="data.filterByText"
                                        ng-change="filterFromServer()"
                                    />
                                </div>
                                <div class="flex-basis-40 text-right">
                                    Select:
                                    <a ng-click="bulkSelect(true, filteredEntities)">All</a>
                                    /
                                    <a ng-click="bulkSelect(false, filteredEntities)">None</a>
                                </div>
                            </div>
                            <div class="import-review-box flex">
                                <div class="import-search-list">
                                    <div
                                        class="list-item flex-vmiddle pointer"
                                        ng-repeat="item in filteredEntities = (data.entities | filter:{name: data.filterByText})"
                                    >
                                        <span
                                            class="flex-grow common-size-xxs padding-normal-sm-v"
                                            ng-click="includeItem(item)"
                                        >
                                            {{ item.name }}
                                            <span class="common-size-xxxxs common-color-grey" ng-if="item.subTitle">
                                                ({{ item.subTitle }})
                                            </span>
                                        </span>
                                        <span
                                            class="common-size-xxxxxs flex-no-shrink"
                                            ng-class="{ 'margin-left': $first, 'margin-left-xs': !$first }"
                                            ng-repeat="field in item.customFields"
                                        >
                                            {{ field }}
                                        </span>
                                        <a
                                            class="common-size-xxxxxs margin-normal-sm-h flex-no-shrink"
                                            href="{{ item.url }}"
                                            target="_blank"
                                        >
                                            View
                                        </a>
                                        <i
                                            class="fa {{
                                                !data.includeIds[item.id]
                                                    ? 'fa-circle-o common-color-grey'
                                                    : 'fa-circle common-color-primary'
                                            }}"
                                            ng-click="includeItem(item)"
                                        ></i>
                                    </div>
                                </div>
                                <div class="import-selected-list">
                                    <div class="common-color-grey">
                                        SELECTED
                                        <span ng-if="data.includeIdCount" class="margin-left">
                                            ({{ data.includeIdCount }})
                                        </span>
                                    </div>
                                    <div
                                        class="list-item flex-vmiddle pointer"
                                        ng-repeat="(id, item) in data.includeIds"
                                    >
                                        <span class="flex-grow common-size-xxs padding-normal-sm-v">
                                            {{ item.name }}
                                            <span class="common-size-xxxxs common-color-grey" ng-if="item.subTitle">
                                                ({{ item.subTitle }})
                                            </span>
                                        </span>
                                        <a
                                            class="common-size-xxxxxs margin-normal-sm-h"
                                            href="{{ item.url }}"
                                            target="_blank"
                                        >
                                            View
                                        </a>
                                        <i class="fa fa-times common-color-grey" ng-click="includeItem(item)"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="common-color-grey common-size-xxs margin-top-lg">
                                * A Tonkean Track will be created for each of these entities.
                                <br />
                                For each item you will be able to see the relevant inner items and activity from
                                {{ data.selectedProjectIntegration.displayName }}.
                            </p>
                        </div>
                    </div>
                    <hr ng-if="!data.error" />
                </div>
                <div ng-if="data.error" class="alert alert-danger common-size-xxs">
                    <p>
                        There was a problem
                        <span>{{ data.mode === 'sync' ? 'syncing' : 'importing' }}</span>
                        at this time.
                    </p>
                    <hr ng-if="data.error.data" />
                    <p ng-if="data.error.data">
                        {{
                            data.error.data.error
                                ? data.error.data.error.message + '. Status: ' + data.error.data.error.status
                                : data.error.data
                        }}
                    </p>
                </div>
                <div class="flex flex-vmiddle">
                    <a
                        ng-if="syncData && !hideRemoveBtn"
                        class="common-color-danger common-size-xxs margin-left"
                        ng-click="removeSync(pm.project.id, groupId, parentInitiative)"
                    >
                        Remove
                    </a>
                    <div class="flex-grow"></div>

                    <button
                        class="btn btn-primary flex-no-shrink"
                        data-automation="import-tracks-preview-and-submit-button"
                        ng-disabled="loading || (data.mode === 'import' && data.includeIdCount < 1)
                                          || (data.mode === 'sync' && !data.syncControl.customDefinedView && !data.syncControl.selectedView)
                                          || (data.mode === 'sync' && data.syncControl.customDefinedView && !data.syncControl.customFiltersDefinition)"
                        ng-click="openSyncPreviewModal()"
                    >
                        {{ btnLabel || 'Import' }}
                        <i class="fa fa-arrow-right margin-left"></i>
                    </button>
                </div>
                    <span class="margin-right-xs common-size-xxxxs common-error" ng-if="data.removeSyncError">
                        {{ data.removeSyncError.data.data.error.message }}
                    </span>
            </div>
        </div>

        <!-- Import from CSV -->
        <div ng-if="data.currentStep == 'importCsv' || disableImportFromIntegrations">
            <div ng-show="!data.table || data.changeFile">
                <div>
                    <label class="custom-file-label" for="fileInput">Select a CSV file:</label>

                    <input
                        type="file"
                        id="fileInput"
                        class="import-csv-file-input"
                        custom-on-change="onFileChange"
                        accept=".csv, text/csv"
                    />
                </div>
                <hr />
                <button
                    class="btn btn-default"
                    ng-click="data.currentStep = (data.previousStep || 'selectIntegration')"
                >
                    Back
                </button>
            </div>
            <div ng-if="!loadingFile && data.table">
                <tnk-import-table
                    table="data.table"
                    group-id="groupId"
                    workflow-version-id="{{ workflowVersionId }}"
                    on-cancel="clearFile()"
                    parent-id="parentInitiative.id"
                    create-in-edit-mode="createInEditMode"
                    display-fields-list="displayFieldsList"
                    form-id="formId"
                    on-done="onClose(failedImportsCount)"
                ></tnk-import-table>
            </div>
        </div>
    </div>
</div>
