<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group margin-bottom-none">
                <label for="login-uri" class="col-sm-4 control-label">Agile CRM domain</label>
                <div class="col-sm-8">
                    <div class="flex-vmiddle">
                        <div>https://</div>
                        <div class="flex-grow zendesk-subdomain">
                            <input
                                type="text"
                                ng-model="data.subDomain"
                                class="form-control"
                                id="login-uri"
                                placeholder="Your sub-domain"
                                required
                            />
                        </div>
                        <div>.agilecrm.com</div>
                    </div>
                </div>
                <!--<div class="col-sm-9">-->
                <!--<input type="text" ng-model="data.uri" class="form-control" id="login-uri"-->
                <!--placeholder="Your company's agile CRM url."-->
                <!--autocomplete="off" required>-->
                <!--<div class="common-subtitle-inner"><i>i.e. https://<span class="common-underline">your-company</span>.agilecrm.com</i></div>-->
                <!--</div>-->
            </div>
            <div class="form-group padding-normal-md">
                <div class="row">
                    <label for="login-api-key" class="col-sm-4 control-label">API Token</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            ng-model="data.key"
                            class="form-control"
                            id="login-api-key"
                            autocomplete="off"
                            placeholder="API Token"
                            required
                        />
                    </div>
                </div>

                <div class="row margin-top-xs">
                    <label for="login-api-email" class="padding-top col-sm-4 control-label">Email</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            ng-model="data.email"
                            class="form-control"
                            id="login-api-email"
                            autocomplete="off"
                            placeholder="email"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="margin-bottom-lg info-box common-size-xxxs">
                <!--<i class="fa fa-info-circle margin-right"></i>-->
                <p class="common-color-dark-grey">
                    <span class="common-underline">Setup instructions</span>
                    :
                </p>
                <div class="common-color-dark-grey">
                    Go to
                    <a
                        target="_blank"
                        href="https://{{ data.subDomain || 'your-sub-domain' }}.agilecrm.com/#analytics-code"
                    >
                        https://{{ data.subDomain || 'your-sub-domain' }}.agilecrm.com/#analytics-code
                    </a>
                    and Copy the API token from the
                    <strong>REST API</strong>
                    section.
                    <br />
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
