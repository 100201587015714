<div>
    <div class="flex-grow">
        <strong>{{ item.actor.id === as.currentUser.id ? 'You' : item.actor.name }}</strong>
        commented
        <span
            ng-if="
                item.reference2 &&
                item.reference2.id &&
                (item.target.id !== item.reference2.id || targetId !== item.target.id)
            "
        >
            &nbsp;on
            <strong>
                {{
                    item.reference2.text
                        ? item.reference2.text
                        : item.reference2.title
                        ? item.reference2.title
                        : item.reference2.reference1.title
                }}
            </strong>
        </span>
    </div>
</div>
