import template from './tnkTitledInput.template.html?angularjs';

function tnkTitledInput() {
    return {
        restrict: 'E',
        scope: {
            value: '=',
            defaultValue: '@',
            title: '@',
            isRequired: '@',
            focus: '@',
            isPhone: '=',
            inputDisabled: '=',
        },
        template,
    };
}

export default angular.module('tonkean.app').directive('tnkTitledInput', tnkTitledInput);
