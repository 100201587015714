import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
const tnkManualAddTracksCtrl = function ($scope, $timeout, authenticationService, utils, modalUtils, trackHelper) {
    const ctrl = this;
    $scope.modalUtils = modalUtils;

    $scope.data = {
        initiativeLabel: ctrl.initiativeLabel,
        currentUserAsDefaultOwner: ctrl.currentUserAsDefaultOwner,
        onSave: ctrl.onSave,

        pasteInitiatives: false,
        datePickerOpen: false,
        handlingPasteText: false,
        pastedTextbox: null,
        initiativesResults: [],
        manualOwners: [],
        uninvitedOwners: [],
    };

    ctrl.$onInit = function () {
        $scope.addToInitiativesResults(1);
    };

    $scope.save = function () {
        $scope.data.onSave({ initiatives: $scope.data.initiativesResults });
    };

    $scope.handlePastedText = function () {
        $timeout(function () {
            $scope.data.handlingPasteText = true;

            const text = $scope.data.pastedTextbox;
            $scope.data.initiativesResults.length = 0;

            const splits = text.split('\n');
            if (splits && splits.length) {
                // $scope.treeLog = {};

                fillIds(0, splits.length, function () {
                    console.log(splits);
                    // handle lines recursively
                    handleLine(splits, 0, null, null, null, [], function () {
                        // done
                        $scope.data.pasteInitiatives = false;
                        $scope.data.handlingPasteText = false;
                    });
                });
            } else {
                $scope.data.handlingPasteText = false;
            }
        });
    };

    function fillIds(count, goal, doneCallback) {
        trackHelper.fillIdsStock(10).then(function () {
            count = count + 10;
            if (count < goal) {
                fillIds(count, goal, doneCallback);
            } else {
                doneCallback();
            }
        });
    }

    function handleLine(lines, index, newId, parentId, prefixScope, pathLog, doneCallback) {
        if (index > lines.length - 1) {
            doneCallback();
            return null;
        }

        const line = lines[index];
        if (line) {
            const rg = /^(\s*?o\s|\s*?\d\.\s|\s*?i+\.\s|\s*?[a-z]+\.\s|\W+?)\w/m; // get the prefix of the line
            const rgResult = rg.exec(line);
            let prefix = rgResult ? rgResult[1] : '';

            // if the prefix is numeric (1. |  a.  |  i. ) we need to tokenize it
            if (prefix && prefix.length) {
                const isNumericRg = /^\s*?\d\.\s|\s*?i+\.\s|\s*?[a-z]+\.\s/m;
                const isNumeric = isNumericRg.exec(prefix);
                if (isNumeric && isNumeric.length) {
                    let numType;
                    // find what exact type
                    if (/^\s*?\d\.\s/m.test(prefix)) {
                        // it's 1. format
                        numType = 'NUM';
                    } else if (/\s*?i+\.\s/m.test(prefix)) {
                        // it's i. format
                        numType = 'I';
                    } else if (/\s*?[a-z]+\.\s/m.test(prefix)) {
                        // it's a. format
                        numType = 'AZ';
                    }
                    // replace it with a token for the numeric + the level we are in
                    prefix = prefix.replace(isNumericRg, `#NU${numType} `);
                }
            }

            const initiativeId = newId || trackHelper.generateId();

            if (index === 0) {
                // first item
                prefixScope = prefix;
                pathLog.push(prefix);
            }

            // console.log(prefix);

            if (prefixScope === prefix) {
                // means we are on the same level
                createInitiativeFromLine(line, initiativeId, parentId, pathLog.length - 1, prefix);

                // check if the next one is the same
                const scopeEnd = handleLine(lines, index + 1, null, parentId, prefixScope, pathLog, doneCallback);
                if (scopeEnd) {
                    // check results recursively
                    return navigateLine(
                        scopeEnd,
                        prefix,
                        initiativeId,
                        lines,
                        index,
                        newId,
                        parentId,
                        prefixScope,
                        pathLog,
                        doneCallback,
                    );
                } else {
                    // move on
                    return null;
                }
            } else {
                // we got to a point where we no longer at the same level
                // return to caller
                return { index, prefix, initiativeId };
            }
        } else {
            // if no line, just call the next one in line
            return handleLine(lines, index + 1, null, parentId, prefixScope, pathLog, doneCallback);
        }
    }

    function navigateLine(
        scopeEnd,
        prefix,
        initiativeId,
        lines,
        index,
        newId,
        parentId,
        prefixScope,
        pathLog,
        doneCallback,
    ) {
        let result;
        // means this scope is done, let's find out if we need to go inside or not
        if (scopeEnd.prefix !== prefix && !pathLog.includes(scopeEnd.prefix)) {
            // means it's a new prefix so it's a new level down
            const newPathLog = angular.copy(pathLog);
            const innerPrefix = scopeEnd.prefix;
            newPathLog.push(innerPrefix);
            result = handleLine(
                lines,
                scopeEnd.index,
                scopeEnd.initiativeId,
                initiativeId,
                innerPrefix,
                newPathLog,
                doneCallback,
            );
        } else if (scopeEnd.prefix === prefix) {
            // this it's a brother, let's keep on
            result = handleLine(
                lines,
                scopeEnd.index,
                scopeEnd.initiativeId,
                parentId,
                prefixScope,
                pathLog,
                doneCallback,
            );
        } else {
            return scopeEnd;
        }

        if (result) {
            // check again
            return navigateLine(
                result,
                prefix,
                initiativeId,
                lines,
                index,
                newId,
                parentId,
                prefixScope,
                pathLog,
                doneCallback,
            );
        } else {
            // path check done
            return null;
        }
    }

    function createInitiativeFromLine(line, initiativeId, parentId, parentsCount) {
        // clean the title
        // .replace(/^\s*?[0-9]\.\s|\s*?i+\.\s/m,'')
        const title = line
            .replace(/[•♣]/, '')
            .replace(/^\s*[o*-]\s/m, '')
            .replace(/^\s*?\d\.\s|^\s*?i+\.\s|^\s*?[a-z]+\.\s/m, '')
            .trim();
        if (title.length) {
            // && !titleMap[title]) {
            // add an initiative
            const newItem = {
                initiativeId,
                parentId,
                title,
                fields: {},
            };

            // this is for the UI use only
            newItem.parentsCount = parentsCount;

            $scope.data.initiativesResults.push(newItem);

            return newItem;
        }

        return null;
    }

    /**
     * Assigns current user to the owner of the given initiative.
     * @param initiative
     */
    $scope.assignInitiativeToCurrentUser = function (initiative) {
        if (!initiative) {
            return;
        }

        // If we already have an owner set, we don't wanna mess with that.
        if (initiative.selectedPeople && initiative.selectedPeople.length > 0) {
            return;
        }

        // Initializing the selected people if it hasn't been initialized just yet.
        if (!initiative.selectedPeople) {
            initiative.selectedPeople = [];
        }

        // Adding current user to selected.
        initiative.selectedPeople.push(authenticationService.currentUser);
        // Changing given initiative owner id to current user id.
        initiative.ownerId = initiative.selectedPeople[0].id;
        // Updating the manual initiative owners for the initiative with the owner we assigned.
        $scope.updateManualInitiativesOwners(initiative, initiative.selectedPeople[0]);
    };

    /**
     * Updates the data.manualInitiativesOwners with the owners of the manual initiatives added by the user.
     * @param initiative - the initiative that was updated (owner was added or removed).
     * @param newOwner - a new owner in case he was added and null if removed.
     */
    $scope.updateManualInitiativesOwners = function (initiative, newOwner) {
        // Update the given initiative with the owner that was supplied (if one was supplied).
        // The prepareInitiatives function relies on ownerId and not the owner object - so this action is for our use only.
        // We have to add the new owner to the initiative itself because just an id is not enough for UI purposes.
        // Removing is handled in the html itself.
        if (initiative && newOwner) {
            initiative.owner = newOwner;
            // This display name is added so it's easier to display the user's name in the html.
            initiative.owner.customDisplayName = newOwner.firstName || newOwner.email || 'The owner';
        }

        // A map that will be later converted to an array.
        const ownersMap = {};

        if ($scope.data.initiativesResults) {
            // Go over the initiative results, and update the owners list accordingly.
            for (let i = 0; i < $scope.data.initiativesResults.length; i++) {
                const initiativeObj = $scope.data.initiativesResults[i];
                // If the initiative has an owner and the owner has a name or email, add him to the map.
                if (initiativeObj.owner) {
                    const key = initiativeObj.owner.name || initiativeObj.owner.email || initiativeObj.owner.firstName;
                    ownersMap[key] = initiativeObj.owner;
                }
            }
        }

        // Convert the built map to an array and save it.
        $scope.setManulInitiativesOwners(utils.objValues(ownersMap));
    };

    $scope.setManulInitiativesOwners = function (array) {
        $scope.data.manualOwners = array;
        $scope.calculateUninvitedManualOwners();
    };

    $scope.calculateUninvitedManualOwners = function () {
        // filter owners who are in the system
        $scope.data.uninvitedOwners = $scope.data.manualOwners.filter((owner) => !owner.id);
    };

    /**
     * Adds empty initiatives placeholders to the initiativesResults array.
     * @param numberOfEmptyInitiatives - the number of empty initiatives to create. Default is zero.
     * @param ownerId - an optional owner id to add to the new created initiatives.
     * @param reset - should the initiativesResults list be reset.
     */
    $scope.addToInitiativesResults = function (numberOfEmptyInitiatives, ownerId, reset) {
        // Reset the initiatives list.
        if (reset) {
            $scope.data.initiativesResults = [];
        }

        if (numberOfEmptyInitiatives) {
            // Add empty initiatives the number of times we were requested.
            for (let i = 0; i < numberOfEmptyInitiatives; i++) {
                $scope.data.initiativesResults.push({ fields: {}, ownerId });
            }
        }
    };

    /**
     * Removes an item from the initiativesResults array.
     * @param index - the index of the item to remove. If the index does not exist in the array, nothing is removed.
     */
    $scope.removeFromInitiativesResults = function (index) {
        // Only do something if the index is valid.
        if (index < $scope.data.initiativesResults.length) {
            $scope.data.initiativesResults.splice(index, 1);
            $scope.updateManualInitiativesOwners();
        }
    };
};
export default angular.module('tonkean.app').controller('tnkManualAddTracksCtrl', lateConstructController(tnkManualAddTracksCtrl));
