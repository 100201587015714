import { useField, useFormikContext } from 'formik';
import React, { useContext, useMemo, useRef } from 'react';
import styled from 'styled-components';

import ProjectIntegrationPageContext from '../../ProjectIntegrationPageContext';

import { Field, Input, Paragraph } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HeaderWrapper = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border: 1px solid ${Theme.colors.gray_300};
`;

const Header = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${Theme.colors.gray_800};
`;

const ContentWrapper = styled.div`
    min-height: 130px;
    padding: 18px 20px 32px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 0 0 3px 3px;
    border-top: none;
    background-color: ${Theme.colors.gray_100};
`;

const StyledParagraph = styled(Paragraph)`
    color: #979797;
    margin-bottom: 24px;
`;

const EncryptionKeyWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const EncryptionKeyField = styled(Field)`
    flex-grow: 1;
    max-width: 382px;
`;

const DataEncryptionKeyConfiguration: React.FC = () => {
    const [encryptionKey, , { setValue }] = useField<string>('encryptionKey');

    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const formik = useFormikContext();

    const generateEncryptionKey = () => {
        const secret = new Uint8Array(24);
        window.crypto.getRandomValues(secret);
        setValue(btoa(String.fromCharCode.apply(null, secret)));
    };

    const disabled = useMemo(() => {
        if (currentProjectIntegration?.integration.encryptionKeyDummy) {
            return true;
        }
        return !!encryptionKey.value && formik.isSubmitting;
    }, [currentProjectIntegration?.integration.encryptionKeyDummy, formik.isSubmitting, encryptionKey]);

    return (
        <>
            <HeaderWrapper>
                <Header>Encryption Key</Header>
            </HeaderWrapper>

            <ContentWrapper>
                <StyledParagraph>
                    An encryption key is typically a random string generated specifically to scramble and unscramble
                    data.
                    <br />
                    Note: This key needs to be 32 chars long.
                </StyledParagraph>

                <EncryptionKeyWrapper>
                    <Button onClick={generateEncryptionKey} disabled={disabled} outlined>
                        Generate
                    </Button>
                    <EncryptionKeyField>
                        <Input placeholder="Encryption key" name="encryptionKey" disabled={disabled} ref={inputRef} />
                    </EncryptionKeyField>
                </EncryptionKeyWrapper>
            </ContentWrapper>
        </>
    );
};

export default DataEncryptionKeyConfiguration;
