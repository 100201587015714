import React from 'react';
import styled from 'styled-components';

import type { State } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const StatusText = styled.span<{ $color: Color; $fontSize: FontSize }>`
    color: ${({ $color }) => $color}CC;
    font-weight: ${({ $fontSize }) => ($fontSize === FontSize.XXXXLARGE_24 ? 400 : 'bold')};
    font-size: ${({ $fontSize }) => $fontSize};
`;

export const StatusDot = styled.div<{ $color: Color }>`
    height: 8px;
    width: 8px;
    background-color: ${({ $color }) => $color}CC;
    border-radius: 50%;
    margin-right: 8px;
`;

const BackgroundStatus = styled.div<{ $color: Color; $height: number }>`
    border-radius: 35px;
    background-color: ${({ $color }) => $color}15;
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ $height }) => $height}px;
`;

const Status = styled.div`
    display: flex;
`;

interface Props {
    state: State | undefined;
    fontSize?: FontSize;
}
const StatusChip: React.FC<Props> = ({ state, fontSize = FontSize.SMALL_12 }) => {
    const calculateBackgroundStatusHeight = () => {
        const fontSizeNumber = Number.parseInt(fontSize.valueOf());
        return fontSizeNumber + 10;
    };

    return (
        <Status>
            {state && (
                <BackgroundStatus $color={state.color} $height={calculateBackgroundStatusHeight()}>
                    <StatusDot $color={state.color} />
                    <StatusText $color={state.color} $fontSize={fontSize}>
                        {state.label}
                    </StatusText>
                </BackgroundStatus>
            )}
        </Status>
    );
};

export default StatusChip;
