import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkflowMatchByConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        entityMetadata: ctrl.entityMetadata,
        projectIntegration: ctrl.projectIntegration,
        matchConfiguration: ctrl.matchConfiguration,
        entityMatchField: ctrl.matchConfiguration.entityMatchField,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        workerItemMatchFieldDefinition: ctrl.matchConfiguration.workerItemMatchFieldDefinition,
        workerItemMatchSpecialField: ctrl.matchConfiguration.workerItemMatchSpecialField,
        modFixedWidth: ctrl.modFixedWidth,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,

        workerItemMatchField: null,
        matchingConfiguration:
            ctrl.matchConfiguration.matchOption && ctrl.matchConfiguration.matchOption === 'MATCH_FIELD_CUSTOM_QUERY'
                ? 'ADVANCED'
                : 'SIMPLE',
        customQueryControl: {},
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {};

    /**
     * Occurs on changes to the component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.entityMetadata) {
            $scope.data.entityMetadata = ctrl.entityMetadata;
        }
    };

    /**
     * Occurs once a matched field is selected.
     */
    $scope.onEntityMatchFieldSelected = function (selectedField) {
        $scope.data.entityMatchField = selectedField;
        $scope.onWorkflowMatchByFieldChanged(true);
    };

    /**
     * Occurs once worker item match field is selected.
     */
    $scope.onWorkerItemMatchFieldSelected = function (selectedField) {
        $scope.data.workerItemMatchField = selectedField;
        $scope.onWorkflowMatchByFieldChanged(true);
    };

    $scope.onAdvancedMatchingChanged = function (shouldNotSaveLogic) {
        const selectionObject = {};

        if ($scope.data.customQueryControl && $scope.data.customQueryControl.createDefinitionFromCustomFilters) {
            selectionObject.customQuery = $scope.data.customQueryControl.createDefinitionFromCustomFilters();
            selectionObject.customQuery.entity =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata.entity
                    : $scope.data.entityMetadata;
            selectionObject.customQuery.entityMetadata =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata
                    : { entity: $scope.data.entityMetadata };
        }

        selectionObject.matchOption = 'MATCH_FIELD_CUSTOM_QUERY';
        selectionObject.validMatchSelection = true;

        if (ctrl.onWorkflowMatchQueryChanged) {
            ctrl.onWorkflowMatchQueryChanged({
                selectionObject,
                shouldSaveLogic: !shouldNotSaveLogic,
            });
        }
    };

    /**
     * Occurs on changes of the field match conditions.
     */
    $scope.onWorkflowMatchByFieldChanged = function (
        entityMatchField,
        workerItemMatchFieldDefinition,
        workerItemMatchSpecialField,
        shouldSaveLogic,
    ) {
        $scope.data.entityMatchField = entityMatchField;
        $scope.data.workerItemMatchFieldDefinition = workerItemMatchFieldDefinition;
        $scope.data.workerItemMatchSpecialField = workerItemMatchSpecialField;

        if (ctrl.onWorkflowMatchByFieldChanged) {
            ctrl.onWorkflowMatchByFieldChanged({
                entityMatchField: $scope.data.entityMatchField,
                workerItemMatchFieldDefinition: $scope.data.workerItemMatchFieldDefinition,
                workerItemMatchSpecialField: $scope.data.workerItemMatchSpecialField,
                shouldSaveLogic,
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('WorkflowMatchByConfigurationCtrl', lateConstructController(WorkflowMatchByConfigurationCtrl));
