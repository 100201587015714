import { useCallback, useEffect, useMemo, useState } from 'react';

const useFastReload = (initialInterval: number, fastInterval: number) => {
    const [interval, setInterval] = useState(initialInterval);
    const [activeTimeoutId, setActiveTimeoutId] = useState<NodeJS.Timeout | false>();

    // call this function will trigger
    const applyFastReloadInterval = useCallback(() => {
        const intervalMs = fastInterval;
        const intervalTTL = 10_000;
        const defaultInterval = initialInterval;

        setInterval(intervalMs);

        // create new timeout for intervalTTL ms
        const timeout = setTimeout(() => {
            setInterval(defaultInterval);
        }, intervalTTL);

        setActiveTimeoutId((lastTimeoutId) => {
            if (lastTimeoutId) {
                // Clear timeout is in the setActiveTimeout to make sure whenever we add new timeout we always clear the previous one
                clearTimeout(lastTimeoutId);
            }
            return timeout;
        });

        //
    }, [fastInterval, initialInterval]);

    // Clear out Timeouts
    useEffect(() => {
        return () => {
            if (activeTimeoutId) {
                clearTimeout(activeTimeoutId);
            }
        };
    }, [activeTimeoutId]);

    return useMemo(() => {
        return [interval, applyFastReloadInterval] as const;
    }, [applyFastReloadInterval, interval]);
};

export default useFastReload;
