import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function OnBoardingWidgetCtrl($scope, $timeout, onBoardingManager, modalUtils) {
    const ctrl = this;

    $scope.obm = onBoardingManager;

    $scope.finishing = false;

    ctrl.$onInit = function () {};

    $scope.openOnBoardingModal = function () {
        analyticsWrapper.track('On boarding modal opened', { category: 'OnBoardingWidget' });
        modalUtils.openContinuousOnBoardingModal(onDismissed);
    };

    function onDismissed() {
        $scope.finishing = true;
        $timeout(function () {
            onBoardingManager.finishOnBoarding();
        }, 2000);
    }
}
export default angular.module('tonkean.app').controller('OnBoardingWidgetCtrl', lateConstructController(OnBoardingWidgetCtrl));
