import template from './connectSpecificIntegration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkConnectSpecificIntegration', {
    bindings: {
        marketplaceItem: '<',
        integrationType: '<',
        onIntegrationCreated: '<',
    },
    template,
    controller: 'ConnectSpecificIntegrationCtrl',
});
