function numberEx() {
    return function (number, fractionSize) {
        if (fractionSize >= 0) {
            const pow = Math.pow(10, fractionSize);
            return Math.floor((number % 1) * pow) / pow + Math.floor(number);
        }
        return number;
    };
}
angular.module('tonkean.app').filter('numberEx', numberEx);
export { numberEx as NumberExFilter };

function numberAbs() {
    return function (number, fractionSize) {
        return Math.abs(number.toFixed(fractionSize || 0));
    };
}
angular.module('tonkean.app').filter('numberAbs', numberAbs);

function numberDigitPretty() {
    return function (number) {
        if (number) {
            number = number.toFixed(0);
            const lastDigit = Number.parseInt(number.split('').pop());

            if (number < 10 || number > 20) {
                switch (lastDigit) {
                    case 1: {
                        return `${number}st`;

                        break;
                    }
                    case 2: {
                        return `${number}nd`;

                        break;
                    }
                    case 3: {
                        return `${number}rd`;

                        break;
                    }
                    // No default
                }
            }

            return `${number}th`;
        } else {
            return '';
        }
    };
}

angular.module('tonkean.app').filter('numberDigitPretty', numberDigitPretty);
