import React, { useMemo } from 'react';
import styled from 'styled-components';

import EnterpriseComponentsExplorer from './EnterpriseComponentExplorer/EnterpriseComponentsExplorer';
import EnterpriseComponentMenuItemPageContent from './EnterpriseComponentMenuItemPageContent/EnterpriseComponentMenuItemPageContent';
import EnterpriseComponentNotAllowed from './EnterpriseComponentNotAllowed';
import EnterpriseComponentPageMenu from './EnterpriseComponentPageMenu/EnterpriseComponentPageMenu';
import type { EnterpriseComponentContentPageConfiguration } from '../../entities/EnterpriseComponentContentPageConfiguration';
import type { EnterpriseComponentPageMenuConfiguration } from '../../entities/EnterpriseComponentPageMenuConfiguration';
import useEnterpriseComponentContext from '../../hooks/useEnterpriseComponentContext';
import { EnterpriseComponentGeneralSectionIds } from '../../utils/EnterpriseComponentGeneralSection';
import type UpdateOverviewProp from '../../utils/UpdateOverviewProp';

import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { BreadcrumbsZone, LoadingCircle } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import type {
    EnterpriseComponent,
    EnterpriseComponentType,
    Person,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

export const Page = styled.div`
    display: grid;
    grid-template-columns: 280px 126px auto;
    height: 100%;
`;

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    enterpriseComponentType: T;
    allAccessData: EnterpriseComponentsPersonPermissionRole;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    projectOwners: Person[];
    menuConfiguration: EnterpriseComponentPageMenuConfiguration<T, C>;
    menuItemIdConfigurationMap: Record<string, EnterpriseComponentContentPageConfiguration<T, C>>;
    explorerTitle: string;

    setCurrentEnterpriseComponent(updatedEnterpriseComponent: EnterpriseComponent): void;

    enterpriseComponentOverviewResult: ConvertEnterpriseComponentTypeToOverviewResult<T> | undefined;
    updateOverview: UpdateOverviewProp<T>;
    pageStateName: string;
}

const EnterpriseComponentPageLayoutView = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponentType,
    allAccessData,
    projectId,
    projectOwners,
    menuConfiguration,
    menuItemIdConfigurationMap,
    explorerTitle,
    setCurrentEnterpriseComponent,
    enterpriseComponentOverviewResult,
    updateOverview,
    pageStateName,
}: Props<T, C>) => {
    const { currentEnterpriseComponentAccessType, enterpriseComponent } = useEnterpriseComponentContext();
    const notAuthorized = useMemo<boolean>(
        () => currentEnterpriseComponentAccessType === PersonPermissionRoleType.NOT_AUTHORIZED,
        [currentEnterpriseComponentAccessType],
    );

    // default breadcrumbs with icon.
    const pageContentBreadCrumbsOptions: BreadcrumbsSettings = useMemo(() => {
        return {
            displayName: enterpriseComponent.displayName,
            id: enterpriseComponent.id,
            prefixIcon: (
                <ProjectIntegrationIcon
                    width={14}
                    iconUrl={enterpriseComponent.iconUrl}
                    integrationType={enterpriseComponent.integrationType}
                />
            ),
            clickable: {
                params: {
                    page: EnterpriseComponentGeneralSectionIds.OVERVIEW,
                },
                options: { notify: false },
            },
        };
    }, [
        enterpriseComponent.displayName,
        enterpriseComponent.iconUrl,
        enterpriseComponent.id,
        enterpriseComponent.integrationType,
    ]);

    return (
        <Page>
            <EnterpriseComponentsExplorer
                explorerTitle={explorerTitle}
                allAccessData={allAccessData}
                enterpriseComponentType={enterpriseComponentType}
            />
            {!currentEnterpriseComponentAccessType ? (
                <LoadingCircle />
            ) : notAuthorized ? (
                <EnterpriseComponentNotAllowed
                    enterpriseComponent={enterpriseComponent}
                    enterpriseComponentType={enterpriseComponentType}
                    projectId={projectId}
                    projectOwners={projectOwners}
                />
            ) : (
                <>
                    <EnterpriseComponentPageMenu
                        sections={menuConfiguration.sections}
                        enterpriseComponentOverviewResult={enterpriseComponentOverviewResult}
                    />
                    <BreadcrumbsZone append={pageContentBreadCrumbsOptions}>
                        <EnterpriseComponentMenuItemPageContent
                            menuItemIdConfigurationMap={menuItemIdConfigurationMap}
                            enterpriseComponentType={enterpriseComponentType}
                            setCurrentEnterpriseComponent={setCurrentEnterpriseComponent}
                            projectId={projectId}
                            updateOverview={updateOverview}
                            pageStateName={pageStateName}
                        />
                    </BreadcrumbsZone>
                </>
            )}
        </Page>
    );
};

export default EnterpriseComponentPageLayoutView;
