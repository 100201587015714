import React from 'react';
import styled from 'styled-components';

import type { TextAnalysisMatchInformation } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Expendable = styled.div`
    background-color: ${Theme.colors.gray_100};
    padding: 15px 48px 3px;
`;

const ExpendableText = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    margin-bottom: 12px;
`;

const Bold = styled.span`
    font-weight: 500;
`;

const Highlighted = styled.span`
    color: ${Theme.current.palette.mainColors.gray_800};
    background: rgb(18, 212, 184, 0.3); //used rgb because don't want opacity on text, just on background
    font-size: ${FontSize.SMALL_12};
    margin-left: 2px;
    padding: 0 2px;
`;

interface Props {
    matchedResult?: TextAnalysisMatchInformation;
}

const NlpBranchExpendableContent: React.FC<Props> = ({ matchedResult }) => {
    return (
        <>
            {matchedResult && (
                <Expendable>
                    <ExpendableText>
                        <Bold>Positive example configured:</Bold>
                        <Highlighted>{matchedResult.example}</Highlighted>
                    </ExpendableText>
                    <ExpendableText>
                        <Bold>Matching example found in the input data:</Bold>{' '}
                        <span>
                            {matchedResult.beforeContext} <Highlighted>{matchedResult.matchFromInput}</Highlighted>{' '}
                            {matchedResult.afterContext}
                        </span>
                    </ExpendableText>
                </Expendable>
            )}
        </>
    );
};

export default NlpBranchExpendableContent;
