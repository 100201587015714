import { analyticsWrapper } from '@tonkean/analytics';

function ImportActionConfigCtrl($scope, $rootScope, $uibModalInstance, actionType, utils) {
    $scope.utils = utils;

    $scope.data = {
        actionName: actionType,
        actionToImportTypes: {
            OUTGOING_WEBHOOK: [
                {
                    type: 'curl',
                    displayName: 'cURL',
                },
            ],
        },
        selectedImportType: {},
        importObject: {},
    };

    $scope.init = function () {
        // Default type - first in the config object
        const availableImportTypes = $scope.data.actionToImportTypes[$scope.data.actionName];

        if (availableImportTypes) {
            $scope.data.selectedImportType = availableImportTypes[0];
        }
    };

    $scope.onSuccessfulParse = function (importActionConfig) {
        $scope.data.importObject = importActionConfig || {};
    };

    $scope.onError = function (errors) {
        $scope.data.errors = errors;
    };

    $scope.import = function () {
        $scope.data.errors = [];
        analyticsWrapper.track('Import form action config', { category: 'Worker logic component' });
        if (!$scope.data.importObject) {
            $scope.data.errors.push('Could not parse the given text, the format is unexpected');
            return;
        }

        $scope.promptConfirm().then(() => $uibModalInstance.close($scope.data.importObject));
    };

    $scope.promptConfirm = function () {
        const selectedImportTypeDisplayName = $scope.data.selectedImportType.displayName;
        $scope.mboxData = {
            title: `Import action configuration from ${selectedImportTypeDisplayName}`,
            body: `Are you sure you want to import action configuration from ${selectedImportTypeDisplayName}? Existing field values will be overwritten and lost.`,
            isWarn: true,
            okLabel: 'Import',
            cancelLabel: 'Cancel',
        };

        return $rootScope.modal.open({
            scope: $scope,
            size: 'md',
            windowClass: 'mod-danger',
        }).result;
    };

    $scope.cancel = () => {
        $uibModalInstance.close();
    };
}

export default angular.module('tonkean.app').controller('ImportActionConfigCtrl', ImportActionConfigCtrl);
