function ListGalleryCtrl($scope, authenticationService) {
    // Tell the setupGroupCtrl he is in the list gallery page.
    // This is used to show or hide certain things in the UI.
    $scope.isListGallery = true;
    $scope.workersTemplateMode = true;

    /**
     * Posts a message to the iFrame's window parent, using the cross-origin postMessage interface.
     */
    $scope.postMessageToContainer = function (listId) {
        const webAppUrl = 'https://tonkean.com';

        // Prepare the data string - add our own prefix, so the web app can recognize us.
        // If this prefix is not sent to the web app, it ignores the message.
        const dataString = `tnk-app-open-list ${listId}`;

        // If we are in an iFrame, window.parent is the one to post a message to.
        // If it's null we let it throw an exception so we can know about it (shouldn't cause the app to crash, just fail the message sending).
        window.parent.postMessage(dataString, webAppUrl);
    };
}

export default angular.module('tonkean.app').controller('ListGalleryCtrl', ListGalleryCtrl);
