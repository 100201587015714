<div class="tnk-outlook-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>
    <div ng-if="data.definition.customActionKey === 'SEND_EMAIL'" class="margin-bottom-xlg">
        <!-- Separator -->
        <hr />
        <tnk-send-email-recipients
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            identifier="outlook"
            configured-logic="data.configuredLogic"
            preview-evaluation-source="data.previewEvaluationSource"
            validation-object="data.validationObject"
            recipients-configuration="data.savedRecipientsConfiguration"
            cc-configuration="data.savedCCConfiguration"
            bcc-configuration="data.savedBCCConfiguration"
            to-recipients-array="data.definition.toRecipientsArray"
            cc-recipients-array="data.definition.ccRecipientsArray"
            bcc-recipients-array="data.definition.bccRecipientsArray"
            on-recipients-configuration-changed="
                onRecipientsConfigurationChanged(configuration, shouldSaveLogic, array)
            "
            on-c-c-configuration-changed="onCCConfigurationChanged(configuration, shouldSaveLogic, array)"
            on-b-c-c-configuration-changed="onBCCConfigurationChanged(configuration, shouldSaveLogic, array)"
        ></tnk-send-email-recipients>

        <!-- separator -->
        <hr />

        <!-- From -->
        <div class="margin-bottom-xs">
            User ID or Principal Name:
            <i class="common-color-grey common-size-xxxxs">(optional)</i>
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="From email to send as (optional)"
                saved-original-expression="data.definition.fromName"
                saved-evaluated-expression="data.definition.fromNameEvaluatedText"
                expression-unique-identifier="fromEmailAddress"
                on-tonkean-expression-changed="
                onFromEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <!-- separator -->
        <hr />

        <!-- Subject -->
        <div ng-show="!data.definition.isReplyToThread">
            <div class="margin-bottom-xs">Subject of the email:</div>

            <!-- Input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the subject to include in the email"
                saved-original-expression="data.definition.emailSubject"
                saved-evaluated-expression="data.definition.emailSubjectEvaluatedText"
                expression-unique-identifier="emailSubject"
                on-tonkean-expression-changed="
                    onSubjectTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <!-- separator -->
            <hr />
        </div>
        <div class="btn-group margin-bottom">
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': !data.definition.sendAsPlainText }"
                uib-btn-radio="false"
                ng-model="data.definition.sendAsPlainText"
                ng-click="onDefinitionChanged(true)"
            >
                HTML
            </label>
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': data.definition.sendAsPlainText }"
                uib-btn-radio="true"
                ng-click="onDefinitionChanged(true)"
                ng-model="data.definition.sendAsPlainText"
            >
                Plain-Text
            </label>
        </div>

        <!-- Title -->
        <div class="margin-bottom">Content of the email:</div>

        <!-- Plain test Input -->
        <div ng-if="data.definition.sendAsPlainText">
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the html to include in the email"
                saved-original-expression="data.definition.content"
                saved-evaluated-expression="data.definition.contentEvaluatedText"
                on-tonkean-expression-changed="
                    onContentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
                mod-text-area="true"
            ></tnk-tonkean-expression>
        </div>

        <!-- HTML Editor-->
        <div ng-if="!data.definition.sendAsPlainText">
            <button class="btn btn-primary btn-slim" ng-click="openHtmlEditor()">Open Editor</button>
        </div>

        <!-- separator -->
        <hr />

        <!-- Attachments -->
        <tnk-send-email-attachments
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            configured-logic-id="data.configuredLogic.node.id"
            preview-evaluation-source="data.previewEvaluationSource"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            existing-attachments-configuration="data.definition.attachments"
            on-attachments-changed="onAttachmentsChanged(attachments, shouldSaveLogic)"
        ></tnk-send-email-attachments>

        <!-- separator -->
        <hr />

        <!-- Monitor email replies -->
        <tnk-thread-reply
            on-toggled="onDefinitionChanged(shouldSaveLogic, childrenCustomTriggersToCreate)"
            workflow-version-id="{{ data.workflowVersionId }}"
            configured-logic="data.configuredLogic"
            definition="data.definition"
            monitor-replies-on-original-message="data.monitorRepliesOnOriginalMessage"
            group-id="data.groupId"
        ></tnk-thread-reply>
    </div>
    <div ng-if="data.definition.customActionKey === 'MOVE_MESSAGE_TO_FOLDER'" class="margin-bottom-xlg">

        <div class="margin-bottom">
            <!-- Message ID -->
            <div class="margin-right margin-top-xs">
                Message ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Message ID"
                    saved-expression="data.definition.messageId"
                    on-tonkean-expression-changed="onMessageIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <!-- Folder ID -->
            <div class="margin-right margin-top-xs">
                Destination Folder ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Folder ID"
                    saved-expression="data.definition.targetFolderId"
                    on-tonkean-expression-changed="onTargetFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
