import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';

const Divider = styled.hr`
    width: 100%;
`;

export interface BoxProjectData {
    parentFolderId: string | undefined;
    parentFolderName: string | undefined;
}

const BoxCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<BoxProjectData>> = ({
    integration,
    onChangeOrInitIntegration,
    projectIntegrationData,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [{ loading }, callExecuteIntegrationCommand] = useLazyTonkeanService('executeIntegrationCommand');
    const [selectedFolder, setSelectedFolder] = useState<string | undefined>(undefined);
    const [verifiedProjectData, setVerifiedProjectData] = useState<BoxProjectData | undefined>();
    const [loadingIntegrationError, setLoadingIntegrationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const init = () => {
            if (projectIntegrationData?.parentFolderId) {
                setSelectedFolder(projectIntegrationData.parentFolderId);
            }
        };

        init();
    }, [projectIntegrationData]);

    useEffect(() => {
        const disabled =
            !!loadingIntegrationError || !!!selectedFolder || selectedFolder !== verifiedProjectData?.parentFolderId;

        const projectData = {
            parentFolderId: selectedFolder || '0',
            parentFolderName: selectedFolder ? verifiedProjectData?.parentFolderName : '',
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
                projectIntegrationDisplayName: `Box (${verifiedProjectData?.parentFolderName})`,
            },
            disabled,
        );
    }, [
        loadingIntegrationError,
        onChangeOrInitIntegration,
        selectedFolder,
        verifiedProjectData?.parentFolderId,
        verifiedProjectData?.parentFolderName,
    ]);

    const verifyFolder = () => {
        if (!selectedFolder) {
            return;
        }

        if (selectedFolder === '0') {
            setSelectedFolder(undefined);
            setLoadingIntegrationError('The Root folder cannot be selected.');
            return;
        }

        setLoadingIntegrationError(undefined);

        callExecuteIntegrationCommand(
            projectManager.project.id,
            integration.id,
            'VERIFY_FOLDER',
            { parentFolderId: selectedFolder },
            projectIntegration?.id,
        )
            .then((data) => {
                setVerifiedProjectData(data.projectData);
            })
            .catch((error) => {
                setLoadingIntegrationError(
                    error?.data?.error?.cause?.message || 'An error occurred while verifying the folder.',
                );
            });
    };

    const onParentFolderIdChange = useCallback((parentFolderId) => {
        setVerifiedProjectData(undefined);
        setSelectedFolder(parentFolderId);
    }, []);

    return (
        <div className="flex flex-col">
            <div className="flex mod-center">
                <span className="control-label">
                    You can get the folder ID from the Box App URL.
                    <br />
                    e.g. 'https://app.box.com/folder/[Folder ID]'
                    <br />
                    <br />
                    You can't choose the root folder (ID of 0)
                </span>
            </div>

            <Divider />

            <div className="form-group flex-inline mod-vmiddle">
                <label className="control-label col-md-4 margin-right">Parent Folder ID</label>

                <div className="col-sm-7">
                    <input
                        type="text"
                        value={selectedFolder}
                        className="form-control"
                        onChange={({ target }) => onParentFolderIdChange(target.value)}
                        autoComplete="off"
                    />
                </div>

                <button
                    type="button"
                    disabled={loading || !selectedFolder || selectedFolder === verifiedProjectData?.parentFolderId}
                    className="btn btn-primary"
                    onClick={verifyFolder}
                >
                    {loading && <span className="loading" />}
                    {!loading &&
                        ((selectedFolder === verifiedProjectData?.parentFolderId && !!selectedFolder) ||
                        !!projectIntegrationData?.parentFolderId
                            ? 'Verified Folder'
                            : 'Verify Folder')}
                </button>
            </div>

            <div className="flex mod-center">
                {selectedFolder === verifiedProjectData?.parentFolderId && (
                    <span className="control-label">
                        Selected{' '}
                        {verifiedProjectData?.parentFolderName && selectedFolder
                            ? `'${verifiedProjectData?.parentFolderName}'`
                            : 'root'}{' '}
                        folder.
                    </span>
                )}
            </div>

            <div className="flex mod-center margin-top-xs margin-bottom-xs common-color-danger">
                {loadingIntegrationError && <span>{loadingIntegrationError}</span>}
            </div>
        </div>
    );
};

export default BoxCustomizeSetupComponent;
