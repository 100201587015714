import React from 'react';
import styled from 'styled-components';

import ActionButtonSharedSizingCss from './actionButtons/ActionButtonSharedSizingCss';
import type { ActionWidgetActionData } from './actionButtons/ActionWidgetActionData';
import WidgetActionButton from './actionButtons/WidgetActionButton';
import ActionsItemWidgetLoadingSkeleton from './ActionsItemWidgetLoadingSkeleton';
import type { ItemInterfacePermission } from '../../../entities';
import { ItemWidget } from '../../../WidgetModule';

import { Breakpoint } from '@tonkean/infrastructure';
import type {
    ActionsItemWidgetConfiguration,
    InterfaceCTASavedAction,
    TonkeanId,
    TonkeanType,
    Widget,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';

const StyledWidgetActionButton = styled(WidgetActionButton)`
    ${ActionButtonSharedSizingCss}
`;

const WidgetWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px 8px;
    align-items: center;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

interface Props {
    actionsData: ActionWidgetActionData[];
    loadingActions?: boolean;
    widget: Widget<ActionsItemWidgetConfiguration>;
    permission: ItemInterfacePermission;
    actionButtonColor: Color;
    workflowVersionType: WorkflowVersionType;
    reloadInitiativeFromServer?: () => void;
    actionsDisabledLiveDisplay?: Record<InterfaceCTASavedAction['id'], boolean>;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const ActionsItemWidgetDisplay: React.FC<Props> = ({
    actionsData,
    loadingActions,
    widget,
    permission,
    actionButtonColor,
    workflowVersionType,
    reloadInitiativeFromServer,
    actionsDisabledLiveDisplay,
    groupId,
    originatedCustomTriggerId,
}) => {
    return (
        <ItemWidget
            permission={permission}
            noBackgroundBody={widget.configuration.hideWidgetBackground}
            dashedBorderBody={widget.configuration.hideWidgetBackground}
            disableMinHeight
        >
            <WidgetWrapper>
                {loadingActions ? (
                    <ActionsItemWidgetLoadingSkeleton widget={widget} />
                ) : (
                    actionsData.map((actionData) => (
                        <StyledWidgetActionButton
                            key={actionData.id}
                            actionData={actionData}
                            widgetId={widget.id}
                            projectId={widget.projectId}
                            groupId={groupId}
                            workflowVersionType={workflowVersionType}
                            openFormsInModal={widget.configuration.openFormsInModal}
                            buttonBackground={actionButtonColor}
                            liveDisplayDisabled={actionsDisabledLiveDisplay?.[actionData.id]}
                            onActionCompleted={reloadInitiativeFromServer}
                            originatedCustomTriggerId={originatedCustomTriggerId}
                        />
                    ))
                )}
            </WidgetWrapper>
        </ItemWidget>
    );
};
export default React.memo(ActionsItemWidgetDisplay);
