import React, { useMemo } from 'react';

import ButtonRadioGroupContext from './ButtonRadioGroupContext';
import { RadioGroup } from '../RadioButton';
import type { RadioGroupProps } from '../RadioButton/RadioGroup';

interface Props extends Omit<RadioGroupProps, 'direction'> {
    /**
     * Whether all buttons should have equal width.
     * Due to how flexbox works, a width large enough must be set on the ButtonRadioGroup.
     */
    equalWidth?: boolean;
}

/**
 * A ButtonRadioGroup component.
 * Used to create a button-like RadioGroup.
 */
const ButtonRadioGroup: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { equalWidth = true, ...props },
    ref,
) => {
    const contextValue = useMemo(() => ({ equalWidth }), [equalWidth]);

    return (
        <ButtonRadioGroupContext.Provider value={contextValue}>
            <RadioGroup ref={ref} direction="row" {...props} />
        </ButtonRadioGroupContext.Provider>
    );
};

export default React.forwardRef(ButtonRadioGroup);
