import { useAngularService } from 'angulareact';
import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';

import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';
import BoardThemeOverrideWarning from '../../../BoardSettingsModule/components/ThemeSettings/BoardThemeOverrideWarning';

import { GridColorPicker, SavingIndicator, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const ThemeTitleWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;

    display: flex;
    align-items: center;
`;

const ThemeSectionWrapper = styled.div`
    margin-top: 17px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ThemeSectionRight = styled.span`
    display: flex;
    align-items: center;
    height: 100%;
`;

const ThemeSectionSubTitle = styled.span<{ middle?: boolean }>`
    white-space: pre;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};

    ${({ middle }) =>
        middle &&
        css`
            margin-left: 21px;
        `};
`;

const ColorPickerWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Spacer = styled.span`
    width: 6px;
`;

interface Props {
    form: Form;
    onFormDefinitionChanged: (
        primaryColor: string | undefined,
        secondaryColor: string | undefined,
        buttonsColor: string | undefined,
    ) => void;
}

const FormSettingsThemePicker: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { editedSections, isEditable } = useContext(FormBuilderContext);

    const onPrimaryChange = (color: string) => {
        onFormDefinitionChanged(color, form.definition.secondaryColor, form.definition.buttonsColor);
    };

    const onSecondaryChange = (color: string) => {
        onFormDefinitionChanged(form.definition.primaryColor, color, form.definition.buttonsColor);
    };

    const onButtonsColorChange = (color: string) => {
        onFormDefinitionChanged(form.definition.primaryColor, form.definition.secondaryColor, color);
    };

    const projectManager = useAngularService('projectManager');
    const forceBoardThemeOverride = projectManager.project.forceThemeConfiguration;
    const editingDisabled = !isEditable || forceBoardThemeOverride;
    const editingDisabledTooltip = forceBoardThemeOverride
        ? 'Board theme override has been enabled'
        : 'Form cannot be edited when creating';
    const formDefinition = useMemo(() => {
        if (forceBoardThemeOverride) {
            return {
                ...form.definition,
                primaryColor: projectManager.project.themeConfiguration.primaryColor,
                buttonsColor: projectManager.project.themeConfiguration.primaryColor,
                secondaryColor: projectManager.project.themeConfiguration.primaryColor,
            };
        }

        return form.definition;
    }, [forceBoardThemeOverride, form.definition, projectManager.project.themeConfiguration.primaryColor]);

    return (
        <>
            <ThemeTitleWrapper>
                <FormSettingsSectionHeaderTitle>Web Theme Design</FormSettingsSectionHeaderTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.WebThemeDesign]} />
            </ThemeTitleWrapper>
            <ThemeSectionWrapper>
                <ThemeSectionRight>
                    <ThemeSectionSubTitle>Background</ThemeSectionSubTitle>
                    <Spacer />
                    <Tooltip content={editingDisabledTooltip} disabled={!editingDisabled}>
                        <ColorPickerWrapper>
                            <GridColorPicker
                                value={formDefinition.secondaryColor}
                                onChange={onSecondaryChange}
                                disabled={editingDisabled}
                            />
                        </ColorPickerWrapper>
                    </Tooltip>
                    <Spacer />
                    <Spacer />
                    <ThemeSectionSubTitle>Buttons</ThemeSectionSubTitle>
                    <Spacer />
                    <Tooltip content={editingDisabledTooltip} disabled={!editingDisabled}>
                        <ColorPickerWrapper>
                            <GridColorPicker
                                value={formDefinition.buttonsColor}
                                onChange={onButtonsColorChange}
                                disabled={editingDisabled}
                            />
                        </ColorPickerWrapper>
                    </Tooltip>
                </ThemeSectionRight>
            </ThemeSectionWrapper>
            {forceBoardThemeOverride && <BoardThemeOverrideWarning />}
        </>
    );
};

export default FormSettingsThemePicker;
