import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const UnknownLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.UNKNOWN,
    title: '',
    iconClass: '',
    description: '',
};

export default UnknownLogicBlockConfig;
