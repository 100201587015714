function UpdateInitiativesCtrl(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $location,
    trackHelper,
    projectManager,
    licenseManager,
    groupInfoManager,
    workflowVersionManager,
    authenticationService,
    licensePermissionsService,
) {
    $scope.pm = projectManager;
    $scope.licenseManager = licenseManager;
    $scope.sp = $stateParams;

    $scope.initiativeId = $stateParams.id;
    $scope.fieldDefinitionId = $stateParams.fieldId;
    $scope.onlyInnerItems = $stateParams.inner;
    $scope.projectId = $stateParams.projectId;
    $scope.customTriggerId = $stateParams.customTriggerId;
    $scope.workerRunLogicActionId = $stateParams.workerRunLogicActionId;
    $scope.createdByCustomTriggerId = $stateParams.createdByCustomTriggerId;
    $scope.workerRunLogicActionType = $stateParams.workerRunLogicActionType;

    if ($stateParams.formId || $stateParams.itemInterfaceId) {
        // Hide menus until we know if create or update forms
        $rootScope.noMenus = true;
    }

    $scope.data = {
        noInitiatives: false, // Whether the user has any initiatives he own (including 'done' initiatives).
        noActiveInitiatives: false, // Whether the user has any ACTIVE initiative he own (NOT including 'done' initiatives).
        hideSidebar: !!$location.search().fieldId, // Whether we should hide the left side bar or not
        loading: true,
        initiatives: undefined,
    };

    $scope.init = function () {
        if ($stateParams.projectId) {
            $scope.pm.selectProject($scope.projectId).then((ignore) => {
                redirectIfLicenseIsInvalid();

                if ($stateParams.formId || $stateParams.itemInterfaceId) {
                    $state.go(
                        'form',
                        {
                            projectId: $stateParams.projectId,
                            formId: $stateParams.formId || $stateParams.itemInterfaceId,
                            formVersionType: $stateParams.formVersionType,
                            workerRunId: $stateParams.workerRunId,
                            sessionId: $stateParams.sessionId,
                            customTriggerId: $stateParams.customTriggerId,
                            initiativeId: $stateParams.id,
                            workflowVersionId: $stateParams.workflowVersionId,
                            initialValues: $stateParams.initialValues,
                        },
                        { location: 'replace' },
                    );
                } else {
                    $rootScope.noMenus = false;
                    $scope.data.loading = false;
                    groupInfoManager.getGroups();
                    trackHelper.fillIdsStock(20, true);
                }
            });
        } else {
            $state.go('product.board', null, { location: 'replace' });
        }
    };

    $scope.onInitiativesLoaded = async function (initiatives) {
        if (initiatives?.length) {
            $scope.data.noInitiatives = false;

            const activeInitiatives = initiatives.filter(function (item) {
                return item.status !== 'DONE';
            });

            $scope.data.noActiveInitiatives = !activeInitiatives?.length && !$location.search().id;
            $scope.data.initiatives = initiatives;
        } else {
            // If the user owns no initiatives, and no specific initiative was requested in the state params, set data.noInitiatives as true.
            $scope.data.noInitiatives = !$state.params.hasOwnProperty('id');
        }
    };

    $scope.onInitiativeSelected = function (initiativeId) {
        $state.transitionTo('updateState', { projectId: $scope.projectId, id: initiativeId }, { notify: false });
        $stateParams.id = initiativeId;
    };

    $scope.onLoadInitiativeDone = function (initiative) {
        $scope.data.noInitiatives = false;
        $scope.data.hideSidebar = !!($scope.fieldDefinitionId || $scope.onlyInnerItems);

        const answerIndex = Number.parseInt($stateParams.answer);

        // Try to get the group.
        let group = null;
        if (initiative && initiative.group) {
            group = projectManager.groupsMap[initiative.group.id];
        }

        let workflowVersion;
        if (initiative && group) {
            workflowVersion = initiative.isDraftInitiative
                ? workflowVersionManager.getDraftVersionFromCache(initiative.group.id)
                : workflowVersionManager.getPublishedVersionFromCache(initiative.group.id);
        }

        if (
            answerIndex &&
            workflowVersion &&
            workflowVersion.states?.length > answerIndex &&
            workflowVersion.states[answerIndex] &&
            workflowVersion.states[answerIndex].label &&
            workflowVersion.states[answerIndex].label.length
        ) {
            const state = workflowVersion.states[answerIndex];

            // Update status
            trackHelper
                .updateInitiativeState(initiative, state, initiative.updateText, initiative.eta)
                .then(function () {
                    $state.go(
                        'updateState',
                        {
                            projectId: $scope.projectId,
                            id: $scope.initiativeId,
                            answer: null,
                        },
                        { location: 'replace' },
                    );
                    $scope.$emit('alert', {
                        type: 'success',
                        msg: `"${initiative.title}" is now marked as ${state.label}`,
                    });
                });
        }
    };

    $scope.onItemClicked = function (item) {
        if (item && item.owner && item.owner.id === authenticationService.currentUser.id) {
            $state.go(
                'updateState',
                {
                    projectId: $scope.projectId,
                    id: item.trackId || item.id,
                    answer: null,
                },
                { location: 'replace' },
            );
        }
    };

    function redirectIfLicenseIsInvalid() {
        // If the project is expired (really - not free) or we're in the new pricing and the user is not licenced (whether in a licensed project or in a free (isLimitedLicense) project).
        if (licensePermissionsService.shouldGoToExpiredPage()) {
            $rootScope.showHeader = false;

            // Both of the following states use the same controller and template. The difference it the text in after the "/".
            if (!projectManager.project.isExpired && !projectManager.project.isUserLicensed) {
                $state.go(
                    'product.newUnlicensedMember',
                    { projectId: projectManager.project.id },
                    { location: 'replace' },
                );
            } else {
                $state.go('product.expired', { projectId: projectManager.project.id }, { location: 'replace' });
            }
        }
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('UpdateInitiativesCtrl', UpdateInitiativesCtrl);
