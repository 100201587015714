import React from 'react';
import styled from 'styled-components';

import NotAvailableDataCellPlaceholder from './NotAvailableDataCellPlaceholder';
import ViewDataBaseCellRenderer from './ViewDataBaseCellRenderer';
import useGetWorkerRunsItemFormattedDate from '../../../../../HistoryModule/hooks/useGetWorkerRunsItemFormattedDate';

import { Placeholder, Tooltip } from '@tonkean/infrastructure';
import { isNumeric } from '@tonkean/utils';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 47px;
`;

interface Props {
    value: string | undefined;
    loading: boolean;
}

const ViewDataDateCellRenderer: React.FC<Props> = ({ value = '', loading }) => {
    const longDate = value ? Number.parseInt(value, 10) : 0;

    const timestamp = new Date(isNumeric(value) ? longDate : value).getTime();
    const { formattedDate, formattedTime, fullDate } = useGetWorkerRunsItemFormattedDate(timestamp);

    if (loading) {
        return (
            <LoadingWrapper>
                <Placeholder $height="20px" $width="100px" />
            </LoadingWrapper>
        );
    }

    if (!value) {
        return <NotAvailableDataCellPlaceholder />;
    }

    // If we didn't manage to parse the date
    if (!formattedDate && !formattedTime) {
        return <>value</>;
    }

    return (
        <span>
            <Tooltip content={fullDate}>
                <span>
                    <ViewDataBaseCellRenderer value={`${formattedDate ? `${formattedDate}, ` : ''}${formattedTime}`} />
                </span>
            </Tooltip>
        </span>
    );
};

export default ViewDataDateCellRenderer;
