<div class="flex track-week-box">
    <div
        ng-repeat="day in ::days"
        class="flex-grow flex-basis-0 common-size-xxxxs track-week-day-col common-color-grey relative"
        ng-class="{ 'mod-last': $last, 'mod-alt': day.alt }"
    >
        <!--<a class="track-week-day-mobile-btn" ng-click="selectDay(day)">&nbsp;</a>-->
        <!--<div class="margin-bottom margin-left" ng-if="start === today">-->
        <!--<span ng-if="$first" class="margin-right-xs">Due:</span>-->
        <!--<span ng-if="day.diff < 2">{{day.date | daysDiff}}</span>-->
        <!--<span ng-if="day.diff >= 2 && (!day.later || fullMode)">{{day.date | date:'EEEE'}}</span>-->
        <!--</div>-->
        <div
            class="margin-left flex-vmiddle track-week-day-header cursor-default {{::(day.isToday || day.date.getDate() === 1 ? 'margin-bottom-xs' : 'margin-bottom')}}"
        >
            <span class="common-color-light-grey flex-grow common-bold">
                <span ng-class="::{'common-tag-full mod-blue-grey margin-none': day.isToday}">
                    {{::(day.isToday ? 'Today' : ( $root.isMobile ? '' : (day.date | date:'EEE')))}}
                </span>
            </span>
            <!--<div class="track-week-day-add common-size-xxxxs">-->
            <!--<a ng-click="modalUtils.openCreateInitiative(day.date, groupId)" class="margin-left common-color-grey">+ Add</a>-->
            <!--</div>-->
            <span class="common-size-xxs common-tag-full mod-primary margin-right" ng-if="::day.date.getDate() === 1">
                {{::day.date | date:'MMM'}}
            </span>
            <!--<span class="margin-right">{{day.date | date:'MMM yy'}}</span>-->
            <span class="common-size-xxs common-bold margin-right" uib-tooltip="{{::day.date | date}}">
                {{::day.date | date:'dd'}}
            </span>
            <!--ng-class="{'track-day-circle': hasItems(day.items)}"-->
        </div>
        <!--<hr class="margin-none"/>-->
        <div class="track-week-day-box">
            <div
                class="track-week-day-list"
                ng-if="!$root.isMobile"
                ng-include="'./tnkTrackWeekDayItemsList.template.html'"
            ></div>
            <div class="relative" ng-if="$root.isMobile">
                <div
                    class="track-week-day-items-count"
                    ng-class="{
                        'mod-visible': hasItems(day.items) && (objValues(day.items) | filter: filterItemsByGroup).length
                    }"
                >
                    <a class="common-link-no-style" ng-click="selectDay(day)">
                        {{ hasItems(day.items) && (objValues(day.items) | filter: filterItemsByGroup).length }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
