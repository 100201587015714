import React from 'react';

import { SelectorTitle } from './SelectorTitle';
import { InnerItemWidgetConfigurationItemCreationType } from '../../../entities';

import { FormikFormSelector } from '@tonkean/angular-to-react-components';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    itemCreationType: InnerItemWidgetConfigurationItemCreationType | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    groupId: TonkeanId<TonkeanType.GROUP>;
}
const SelectCreateForm: React.FC<Props> = ({ itemCreationType, workflowVersionId, groupId }) => {
    const isShown = !itemCreationType || itemCreationType === InnerItemWidgetConfigurationItemCreationType.MODAL;
    return isShown ? (
        <>
            <SelectorTitle>Select “Create” form</SelectorTitle>
            <FormikFormSelector
                name="configuration.createForm"
                workflowVersionId={workflowVersionId}
                groupId={groupId}
                clearable
                hideUpdateForms
                hideFileUploadForms
            />
        </>
    ) : (
        <></>
    );
};

export default SelectCreateForm;
