import React from 'react';
import styled from 'styled-components';

import FieldChartDataTypeEditorControllers from './FieldChartDataTypeEditorControllers';
import type FieldChartWidgetConfiguration from './FieldChartWidgetConfiguration';
import FieldChartWidgetWorkflowVersionFieldSelector from './fieldChartWidgetFieldSelectors/FieldChartWidgetWorkflowVersionFieldSelector';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../WidgetModule';
import { WidgetConfigurationToggle } from '../CommonWidgetConfiguration';

import { ChartDataType } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const ToggleWrapper = styled.div`
    font-weight: 400;
    padding: 0px 16px 16px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    margin-bottom: 16px;
`;

const FieldChartWidgetEditor: React.FC<ItemWidgetEditorProps<FieldChartWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    return (
        <>
            <FieldChartDataTypeEditorControllers configuration={configuration} />
            {configuration.chartDataType === ChartDataType.HISTORICAL && (
                <ToggleWrapper>
                    <ConfigurationSectionTitle>Overlay</ConfigurationSectionTitle>
                    <WidgetConfigurationToggle
                        dataAutomation="item-builder-widget-configuration-header-chart-show-status-overlay"
                        title="Show status overlay"
                        toolTip="When set to true, item's status changes appear on the graph"
                        name="configuration.showStatusOverlay"
                    />
                </ToggleWrapper>
            )}
            <FieldChartWidgetWorkflowVersionFieldSelector
                configuration={configuration}
                workflowVersion={workflowVersion}
            />
        </>
    );
};

export default FieldChartWidgetEditor;
