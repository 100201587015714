import { useAngularWatch } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { DUMMY_TRACK_TITLE } from '@tonkean/constants';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { Theme } from '@tonkean/tui-theme';

const Status = styled.div<{ isDuplicated: boolean; inEditMode: boolean; isDummy: boolean }>`
    width: 2px;
    z-index: 1;
    margin-right: 10px;
    height: 40%;
    background-color: ${({ isDuplicated, inEditMode, isDummy }) =>
        !inEditMode || isDummy ? 'transparent' : isDuplicated ? Theme.colors.warning : Theme.colors.success};
    position: absolute;
    top: 29%;
    border-radius: 1px;
`;

interface Props {
    realTrack: any;
}

const TrackEditStatus: React.FC<Props> = ({ realTrack }) => {
    const [inEditMode, isDuplicated, isDummy] = useAngularWatch(
        () => realTrack.inEditMode,
        () => !!realTrack.duplicationOfInitiativeId,
        () => realTrack.title === DUMMY_TRACK_TITLE,
    );

    const toolTipMessage = 'New item';

    // If we are not in edit mode or on dummy item then we do not need the edit status
    return inEditMode && !isDummy && !isDuplicated ? (
        <Tooltip content={toolTipMessage}>
            <Status isDuplicated={isDuplicated} inEditMode={inEditMode} isDummy={isDummy} />
        </Tooltip>
    ) : null;
};

export default TrackEditStatus;
