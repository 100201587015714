import template from './tnkWorkerOutlineItem.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerOutlineItem', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        nodeBlock: '<',
        nodeParent: '<',
        nodeGrandParent: '<',
        // The sync object holds properties that populate through the tree: selectedLogicId.
        // We use a single object so no change will trigger full tree on change cycle.
        // Angular will stop us after 10 levels are reached so this is our solution.
        syncObject: '<',
        hideNodeDisplayName: '<', // When you don't want to enable display name editing. Used for the root node which is not editable.
        selectNode: '&', // A callback for when a node is selected in the outline.
        createNewNode: '&', // A callback function to create a new logic node.
        updateCurrentNodeData: '&', // A callback function to update the parent and the grand parent of the current node (for the outline control panel).
        tabNodeIn: '&', // A callback function to tab a node IN if possible in the graph.
        tabNodeOut: '&', // A callback function to tab a node OUT if possible in the graph.
        moveInTree: '&', // A callback function to move in the tree (up or down).
        highlightText: '<', // Highlight triggers which their display name contains this string
        triggerIdsToHighlight: '<', // Border triggers with their id included in this id list
    },
    template,
    controller: 'WorkerOutlineItemCtrl',
});
