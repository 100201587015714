import React from 'react';
import styled from 'styled-components';

import ActionTestPageRequestView from './ProjectIntegrationActionsTestPageRequestView/ActionTestPageRequestView';

import type { PreviewEvaluatedResponse } from '@tonkean/tonkean-entities';
import type { HttpRequestDefinition } from '@tonkean/tonkean-entities';
import { HttpMethodType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const UrlInput = styled.input`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 0 3px 3px 0;
    height: 26px;
    line-height: 26px;
    background-color: ${Theme.colors.gray_100};
    color: ${Theme.colors.gray_600};
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 300;
`;

const UrlAndRequestTypeWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;
`;

const RequestType = styled.div`
    color: ${Theme.colors.gray_600};
    height: 26px;
    padding: 0 8px;
    line-height: 26px;
    font-weight: 700;
    font-size: ${FontSize.XSMALL_10};
    vertical-align: center;
    background-color: ${Theme.colors.brightestGray};
    border-radius: 3px 0 0 3px;

    border: 1px solid ${Theme.colors.gray_300};
    border-right: none;
    width: fit-content;
    text-align: center;
`;

interface Props {
    previewEvaluatedResponse: PreviewEvaluatedResponse | undefined;
    actionDefinition: HttpRequestDefinition;
    isLoading: boolean;
}

const ProjectIntegrationActionHttpRequestView: React.FC<Props> = ({
    previewEvaluatedResponse,
    actionDefinition,
    isLoading,
}) => {
    return (
        <>
            <UrlAndRequestTypeWrapper>
                <RequestType>{actionDefinition?.methodType}</RequestType>

                <UrlInput placeholder="Preview of the URL..." value={previewEvaluatedResponse?.url || ''} readOnly />
            </UrlAndRequestTypeWrapper>

            <ActionTestPageRequestView
                evaluatedUrl={previewEvaluatedResponse?.url}
                evaluatedBody={previewEvaluatedResponse?.body}
                evaluatedHeaders={previewEvaluatedResponse?.headers}
                isLoading={isLoading}
                shouldHideBodyTab={actionDefinition?.methodType === HttpMethodType.GET}
            />
        </>
    );
};

export default ProjectIntegrationActionHttpRequestView;
