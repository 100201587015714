function GoogleModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.views = state.views;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateViewsList();
        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        const redirectUri = environment.integrationKeysMap.googleUri;

        const promise = oauthHandler
            .google(
                environment.integrationKeysMap.google,
                'https://www.googleapis.com/auth/analytics.readonly',
                redirectUri,
                true,
            )
            .then(function (code) {
                return { code, redirectUri };
            });

        promise
            .then(function (config) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'GOOGLE',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (data) {
                $scope.integration = data;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'views',
                );
            })
            .then(function (data) {
                $scope.selections = data.options.map(function (view) {
                    return { view };
                });
                $scope.views = data.options;
                updateViewsList();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.views = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.$watch('temp.view', function () {
        $scope.onViewSelected($scope.temp.view);
    });

    $scope.onViewSelected = function (view) {
        if (view && view.displayName) {
            $scope.selections = [];
            const selection = { view, selected: true };
            $scope.selections.push(selection);
            updateViewsList();
            $scope.onSelectionChange(selection);
            $scope.temp.view = null;
        }
    };

    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateViewsList() {
        if ($scope.views) {
            const viewInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.view.value;
                }),
            );
            $scope.otherViews = $scope.views.filter(function (r) {
                return !viewInSelection.has(r.value);
            });
        }
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            views: $scope.views,
            selections: $scope.selections,
            integration: $scope.integration,
        };

        s.integrations = [];
        $scope.selections.forEach(function (selection) {
            const finalInteg = {
                integration: $scope.integration,
                projectData: {
                    view: selection.view.value,
                    displayName: selection.view.displayName,
                    description: selection.view.description,
                    accountId: selection.view.accountId,
                    webPropertyId: selection.view.webPropertyId,
                },
            };

            s.integrations.push(finalInteg);
        });

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'views')
                .then(function (data) {
                    $scope.views = data.options;
                    $scope.selections = data.options
                        .map(function (view) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (projectData.view === view.value) {
                                            return {
                                                view,
                                                selected: true,
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);
                    updateViewsList();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}

export default angular.module('tonkean.shared').controller('GoogleModalCtrl', GoogleModalCtrl);
