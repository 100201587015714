<div class="tnk-form-fields-select common-draggable-list margin-top">
    <!-- Title -->
    <div class="flex-no-shrink worker-form-editor-field-header margin-top margin-bottom-xs">{{ data.titleLabel }}</div>

    <!-- Field Select Titles -->
    <div class="worker-form-editor-field-options flex-vmiddle padding-left-lg" ng-if="data.isAliasEnabled">
        <!-- Display Name -->
        <div class="worker-form-editor-field-options-column">
            <div class="flex-no-shrink worker-form-editor-field-header margin-left-lg">Question Title</div>
        </div>

        <!-- Field Original Name -->
        <div class="worker-form-editor-field-options-column">
            <div class="flex-no-shrink worker-form-editor-field-header">Store value in field</div>
        </div>
    </div>

    <!-- Fields List -->
    <div tnk-dnd-list>
        <div
            ng-repeat="field in data.fields track by $index"
            class="common-draggable-list-item"
            ng-class="{ 'margin-left-lg': !data.isDraggable }"
            tnk-dnd-draggable="{{::field.fieldDefinition.id}}"
            tnk-dnd-disabled="{{ isDragDisabled() }}"
            tnk-dnd-moved="onFormFieldMoved($index, dndIndex)"
            tnk-dnd-relative-to="tnk-form-fields-select"
        >
            <div class="field-questions">
                <!-- Drag icon -->
                <div
                    class="svg-icon svg-icon-align-text-base common-color-border-light drag margin-right"
                    ng-if="data.isDraggable"
                    ng-style="{ cursor: 'move' }"
                    tooltip-enable="isDragDisabled()"
                    tooltip-placement="top-left"
                    uib-tooltip="Fill empty fields to enable reordering"
                >
                    <tnk-icon src="/images/icons/3lines-h.svg"></tnk-icon>
                </div>

                <div
                    class="flex-grow worker-form-editor-field-options mod-align-start flex-vmiddle"
                    data-automation="form-fields-select-template-field-row"
                    ng-class="{ 'mod-required': field.isRequired }"
                >
                    <!-- Display Name -->
                    <div class="field-with-display-name flex" ng-if="data.isAliasEnabled">
                        <input
                            class="form-control flex-grow characters-counter"
                            data-automation="form-field-select-question-title"
                            ng-model="field.displayName"
                            ng-change="onDisplayNameChanged(field)"
                            ng-model-options="{ debounce: 300 }"
                            placeholder="Optional"
                            maxlength="700"
                        />
                        <span
                            class="characters-counter"
                            ng-show="field.displayName.length / data.displayNameCharacterLimit >= 0.8"
                        >
                            {{ field.displayName.length }}/{{ data.displayNameCharacterLimit }}
                        </span>
                    </div>

                    <!-- Field selector -->
                    <div
                        ng-class="{
                            'fixed-field': data.fixedFields.includes(field.fieldDefinition.id),
                            'non-draggable-field': !data.isDraggable,
                            'field-with-display-name': data.isAliasEnabled,
                            'field-without-display-name': !data.isAliasEnabled
                        }"
                        tooltip-enable="data.fixedFields.includes(field.fieldDefinition.id)"
                        uib-tooltip="The field {{ field.fieldDefinition.name }} is mandatory and cannot be removed"
                    >
                        <!-- Field Select Box -->
                        <tnk-field-selector
                            items-source="COLUMN"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            selected-field="field.fieldDefinition"
                            group-by="groupBy"
                            on-field-selected="onFieldOptionSelected(field, selectedField)"
                            do-not-include-external-fields="true"
                            only-updatable-field-definitions="data.formType == CREATE"
                            fields-filter="data.filterFieldsOptions(fields)"
                            sort-by="sortSpecialFields(first, second)"
                            special-fields-for-features="['TONKEAN_FORMS']"
                            do-not-include-field-ids-set="data.existingFieldIdsSet"
                            dont-close-previous-tab-on-field-creation="true"
                            data-automation="form-field-select-store-in-field-drop-down-{{ field.fieldDefinition.name }}"
                            add-fields-quick-create-options="true"
                        ></tnk-field-selector>
                    </div>
                </div>

                <!-- Three Dots Menu -->
                <tnk-form-field-select-actions-menu
                    class="field-three-dots"
                    ng-class="{ 'three-dots-menu-hidden': data.fixedFields.includes(field.fieldDefinition.id) }"
                    remove-form-field="removeFormField($index)()"
                    toggle-is-required="toggleIsRequired(field)()"
                    toggle-field-inline="toggleFieldInline(field)()"
                    toggle-show-title="toggleShowTitle(field)"
                    toggle-view-only="toggleViewOnly(field)"
                    toggle-collapse-description-by-default="toggleCollapseDescriptionByDefault(field)"
                    form-question-type="data.formQuestionType"
                    on-validation-changed="onValidationChanged(field, fieldValidation)"
                    field="field"
                    enable-delete="true"
                    enable-inline="data.isInlineEnabled"
                    form-type="data.formType"
                    workflow-version-id="data.workflowVersionId"
                    group-id="data.groupId"
                ></tnk-form-field-select-actions-menu>

                <!-- Field Label Text -->
                <div
                    ng-if="field.fieldDefinition.fieldLabel"
                    class="common-size-xxs common-color-grey3 margin-top-6 field-label"
                >
                    <tnk-icon src="/images/icons/Label.svg" class="margin-right-xxs"></tnk-icon>
                    Labeled as: {{ field.fieldDefinition.fieldLabel }}
                </div>
            </div>

            <div
                ng-if="field.displayName.length > data.displayNameCharacterLimit"
                class="common-size-xxxxs margin-top-xxs margin-left-lg padding-left-xs common-color-warning"
            >
                Question title too long for Slack Dialog, the value will be trimmed to 48 characters.
            </div>

            <div
                ng-if="field.fieldDefinition.displayFormat === fieldDisplayFormats.html.apiName"
                class="common-color-grey common-size-xxxxs margin-top-xxs margin-left-lg padding-left-xs"
            >
                * This is an HTML field and is only supported in web forms
            </div>
        </div>
    </div>

    <!-- Add field button -->
    <a
        class="inline-block margin-top-md margin-bottom-xs"
        data-automation="form-fields-select-add-new-field"
        ng-click="addEmptyFormField()"
    >
        <i class="fa fa-plus margin-right-xs"></i>
        {{ data.addButtonLabel || 'Add Field' }}
    </a>
</div>
