import { useAngularService } from 'angulareact';
import { type FormEvent, useContext } from 'react';
import React, { useState } from 'react';

import IroncladAuthType, { authTypeToDisplayName } from './IroncladAuthType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const IroncladComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authType, setAuthType] = useState<IroncladAuthType>(IroncladAuthType.API_KEY);

    const [envSubdomain, setEnvSubdomain] = useState<string>();
    const [apiToken, setApiToken] = useState<string>();

    const authenticateWithAdobeSign = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            let config;

            config = {
                envSubdomain,
                authType,
            };

            if (authType === IroncladAuthType.OAUTH) {
                const clientId = environment.integrationKeysMap.ironcladClientId;
                const redirectUri = environment.integrationKeysMap.ironcladUri;
                const state = oauthHandler.publicGetState();

                const scopes = encodeURIComponent(
                    'public.records.createRecords public.records.readRecords public.records.updateRecords ' +
                        'public.records.readSchemas public.records.createAttachments public.records.readAttachments ' +
                        'public.webhooks.createWebhooks public.webhooks.readWebhooks public.webhooks.updateWebhooks ' +
                        'public.webhooks.deleteWebhooks public.workflows.readWorkflows public.workflows.createWorkflows ' +
                        'public.workflows.updateWorkflows public.workflows.readApprovals public.workflows.updateApprovals ' +
                        'public.workflows.readSignatures public.workflows.uploadSignedDocuments public.workflows.readParticipants ' +
                        'public.workflows.revertToReview public.workflows.cancel public.workflows.pauseAndResume ' +
                        'public.workflows.createComments public.workflows.readComments public.workflows.createDocuments ' +
                        'public.workflows.readDocuments public.workflows.readSchemas public.workflows.readTurnHistory ' +
                        'public.workflows.readEmailCommunications scim.users.readUsers',
                );

                const url = `https://ironcladapp.com/oauth/authorize?response_type=code&scope=${scopes}&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}`;

                const code = await oauthHandler.startOAuth(url, state);

                config = {
                    ...config,
                    code,
                    redirectUri,
                };
            } else {
                config = {
                    ...config,
                    api_token: apiToken,
                };
            }

            authenticate(config, { authType, envSubdomain });
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col form-horizontal">
            <div className="flex flex-col api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label">Authentication Type</label>
                    <div className="col-sm-7 padding-top-xs">
                        <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                            <Radio value={IroncladAuthType.API_KEY}>
                                {authTypeToDisplayName[IroncladAuthType.API_KEY]}
                            </Radio>
                            <Radio value={IroncladAuthType.OAUTH}>
                                {authTypeToDisplayName[IroncladAuthType.OAUTH]}
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div>
                    <label className="col-sm-4 control-label">Environment Subdomain</label>
                    <div className="col-sm-7 flex-vmiddle">
                        <span>https://&nbsp;</span>
                        <input
                            type="text"
                            value={envSubdomain}
                            className="form-control margin-bottom-xs"
                            onChange={({ target }) => setEnvSubdomain(target.value)}
                            autoComplete="off"
                            placeholder="Environment"
                            width="50"
                        />
                        <span>&nbsp;ironcladapp.com/</span>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-4 control-label margin-top" />
                    <div className="col-sm-7 flex-vmiddle">
                        <span className="common-size-xxxs common-color-grey">
                            Optional. Empty - Production (Default), 'eu1' - EU Environment, 'demo' - Demo Environment.
                        </span>
                    </div>
                </div>

                {authType === IroncladAuthType.API_KEY && (
                    <div className="form-group">
                        <label className="col-sm-4 control-label margin-top-xs">API Token</label>
                        <div className="col-sm-7">
                            <input
                                value={apiToken}
                                className="form-control"
                                onChange={({ target }) => setApiToken(target.value)}
                                autoComplete="off"
                                placeholder="API Token"
                            />
                        </div>
                    </div>
                )}

                <div className="flex mod-center margin-top-20">
                    <button
                        className="btn btn-secondary btn-lg"
                        onClick={authenticateWithAdobeSign}
                        disabled={!apiToken && authType === IroncladAuthType.API_KEY}
                    >
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IroncladComponent;
