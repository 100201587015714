<div class="product-expired-page">
    <div class="product-expired-options pull-right common-size-xxs padding-normal common-color-grey">
        <button class="logout-button" ng-click="as.logout()"> <tnk-icon class="log-out-icon" src="/images/icons/log-out.svg"></tnk-icon>Logout</button>
    </div>
    <div class="product-expired-content">
        <!-- Project is expired -->
        <tnk-product-expired-content ng-if="pm.project.isExpired"></tnk-product-expired-content>

        <!-- Project is not expired and user is not licensed-->
        <div class="text-center product-expired-center" ng-if="!pm.project.isExpired && !pm.project.isUserLicensed">
            <div class="common-size-l margin-normal-v common-title-font">Hi {{as.currentUser.firstName}} 👋</div>

            <p class="common-size-xs">
                The board
                <strong>{{ pm.project.name }}</strong>
                is managed by {{ pm.project.license.buyer.name || pm.project.creator.name }}.
            </p>

            <div class="margin-top-xlg">
                <button
                    type="button"
                    class="btn btn-primary btn-lg"
                    ng-click="licenseManager.goToBoard()"
                    ng-if="!licenseManager.states.requestForAccessSent"
                >
                    {{ pm.isEnterprise ? 'Continue to board' : 'Join board' }}
                </button>
                <div class="btn btn-success disabled" ng-if="licenseManager.states.requestForAccessSent">
                    Request for full access sent
                </div>
            </div>
        </div>
    </div>
</div>
