import React from 'react';

import Section from '../../../components/Section';
import type ItemWidgetEditorProps from '../../../WidgetModule/entities/ItemWidgetEditorProps';
import { WidgetInlineActionsSelector } from '../../CommonWidgetConfiguration';
import type InnerItemWidgetConfiguration from '../../entities/InnerItemWidgetConfiguration';
import useGetWorkflowVersionFieldsAndBasicFields from '../../hooks/useGetWorkflowVersionFieldsAndBasicFields';
import DetailedItemsWidgetConfigurationEditorDisplay from '../../InnerItemsWidgetModule/components/configurationEditorDisplays/DetailedItemsWidgetConfigurationEditorDisplay';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { EMPTY_ARRAY } from '@tonkean/utils';

const DetailedItemsWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<InnerItemWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { initiative, itemInterface } = useItemInterfaceContext();
    const { allFieldDefinitions: allWorkflowVersionFieldsDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(
        workflowVersion.id,
        EMPTY_ARRAY,
    );

    return (
        <>
            <DetailedItemsWidgetConfigurationEditorDisplay
                initiative={initiative}
                configuration={configuration}
                workflowVersionId={workflowVersion.id}
                fieldDefinitions={allWorkflowVersionFieldsDefinitions}
                groupId={workflowVersion.groupId}
            />

            <Section>
                <WidgetInlineActionsSelector workflowVersionId={itemInterface.workflowVersionId} />
            </Section>
        </>
    );
};

export default DetailedItemsWidgetConfigurationEditor;
