import { angularToReact } from '@tonkean/angular-components';
import type { Group } from '@tonkean/tonkean-entities';

export enum IntegrationsPreviewSize {
    MEDIUM = 'md',
    SMALL = 'sm',
}

const ModuleIntegrationsPreview = angularToReact<{ worker: Group; size?: IntegrationsPreviewSize }>(
    'tnk-module-integrations-preview',
);

export default ModuleIntegrationsPreview;
