import GanttTimeline from './GanttTimeline';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkGanttTimeline',
        reactToAngular(
            GanttTimeline,
            ['groups', 'initiatives', 'startWithGroupId', 'loadMoreInitiatives'],
            ['trackHelper', 'modalUtils', 'workflowVersionManager', 'customFieldsManager'],
        ),
    );
