import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousFieldChangedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_FIELD_CHANGED,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-field-changed',
    title: 'Field Changed',
    description: 'Field Changed',
    longDescription:
        'This trigger will run when one of the configured fields has changed, as long as it matches the condition below. Note: this will run on existing items.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [addNewLogicOption],
    definition: {},
    validator: ({ customTrigger }) => {
        const validationObject: LogicBlockValidationObject = {};
        if (!customTrigger?.monitorFieldDefinitions?.length) {
            validationObject.noFieldSelectedError = 'Fields changed trigger should have at least one field set.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutonomousFieldChangedLogicBlockConfig;
