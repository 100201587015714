import React, { useState } from 'react';

import WorkerRunsFiltersPopover from './WorkerRunsFiltersPopover/WorkerRunsFiltersPopover';
import type WorkerRunsFilter from '../../../entities/WorkerRunsFilter';

import { FiltersTrigger, Popover } from '@tonkean/infrastructure';

interface Props {
    filtersChangeCount: number;
    filters: WorkerRunsFilter;
    onChange(newFilter: WorkerRunsFilter): void;
}

const WorkerRunsFiltersTrigger: React.FC<Props> = ({ filters, onChange, filtersChangeCount }) => {
    const [filtersOpen, setFiltersOpen] = useState(false);

    const markAsPressed = () => {
        setFiltersOpen(!filtersOpen);
    };

    const onPopoverFiltersChange = (newFilters: WorkerRunsFilter) => {
        setFiltersOpen(false);
        onChange(newFilters);
    };

    return (
        <Popover
            show={filtersOpen}
            onClose={() => setFiltersOpen(false)}
            placement="bottom"
            content={<WorkerRunsFiltersPopover filters={filters} onChange={onPopoverFiltersChange} />}
        >
            <FiltersTrigger
                activeFiltersCount={filtersChangeCount}
                filtersOpen={filtersOpen}
                data-automation="filters-button-module-events-history"
                onClick={markAsPressed}
            />
        </Popover>
    );
};

export default WorkerRunsFiltersTrigger;
