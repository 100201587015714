import styled from 'styled-components';

import operationConfigurationSectionConfig from '../entities/operationConfigurationSectionConfig';
import type OperationConfigurationSections from '../entities/OperationConfigurationSections';

interface Props {
    $gray?: boolean;
    $section: OperationConfigurationSections;
}

export const SolutionMapperSectionIcon = styled.svg.attrs<Props>((props) => ({
    as: operationConfigurationSectionConfig[props.$section].icon,
}))<Props>`
    height: ${({ $section }) => operationConfigurationSectionConfig[$section].iconSize}px;
    width: ${({ $section }) => operationConfigurationSectionConfig[$section].iconSize}px;

    path {
        fill: ${({ $gray = false, $section }) =>
            $gray ? '#D8D8D8' : operationConfigurationSectionConfig[$section].iconColor};
    }
`;

export default SolutionMapperSectionIcon;
