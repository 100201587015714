import type { ReactElement } from 'react';
import React, { useMemo } from 'react';

import type { ExtendableRowProps } from './ExtendableRow';

interface Props {}

/**
 * Use this component when you want to wrap 2 or more ExtendableRow without spacing between them.
 * See examples in ExtendableRow.
 */
const GroupedExtendableRows: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    const updatedComponents: React.ReactNode[] = useMemo(() => {
        if (!Array.isArray(children)) {
            return [children];
        }
        const components: ReactElement[] = children as ReactElement<ExtendableRowProps, any>[];

        return components.map((node: React.ReactElement, index) => {
            return React.cloneElement(node, {
                isFirstNode: index === 0,
                hasLowerSibling: components.length - 1 !== index,
            });
        });
    }, [children]);

    return (
        <>
            {updatedComponents.map((component: React.ReactNode, index) => (
                <React.Fragment key={index}>{component}</React.Fragment>
            ))}
        </>
    );
};

export default GroupedExtendableRows;
