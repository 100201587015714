import type React from 'react';
import { useCallback } from 'react';

import { Validation, type TonkeanId, type TonkeanType, type ValidationState } from '@tonkean/tonkean-entities';

const useSetSelectedItemsIdCallback = (
    currentlySelectedItemId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    setSelectedItemId: React.Dispatch<React.SetStateAction<TonkeanId<TonkeanType.INITIATIVE> | undefined>>,
    visitedItemInterfacesValidation: Record<TonkeanId<TonkeanType.INITIATIVE>, ValidationState>,
    setDetailedItemsValidation: (key: TonkeanId<TonkeanType.INITIATIVE> | undefined, value: ValidationState) => void,
) => {
    return useCallback(
        (itemId) => {
            if (currentlySelectedItemId) {
                // Get the validation of the item we're leaving
                const trueValidation =
                    visitedItemInterfacesValidation[currentlySelectedItemId]?.trueValidation ||
                    Validation.NOTHING_TO_VALIDATE;

                // Update the visible validation to the true validation of the item we're leaving
                setDetailedItemsValidation(currentlySelectedItemId, {
                    trueValidation,
                    visibleValidation: trueValidation,
                    setForceShowValidation:
                        visitedItemInterfacesValidation[currentlySelectedItemId]?.setForceShowValidation,
                });
            }

            setSelectedItemId(itemId);
        },
        [currentlySelectedItemId, setDetailedItemsValidation, setSelectedItemId, visitedItemInterfacesValidation],
    );
};

export default useSetSelectedItemsIdCallback;
