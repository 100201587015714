import { useEffect } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useInitialModuleInputSourceContractConfiguration = (
    groupId: TonkeanId<TonkeanType.GROUP>,
    workflowVersionType: WorkflowVersionType | undefined,
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    onInitialContractLoaded: (selectedContractId: TonkeanId<TonkeanType.CONTRACT> | undefined) => void,
) => {
    const {
        data: initialContractIdResponse,
        loading,
        error,
    } = useTonkeanService('getInputSourceContract', groupId, workflowVersionType, workflowVersionId);

    useEffect(() => {
        onInitialContractLoaded(initialContractIdResponse?.contractId);
    }, [initialContractIdResponse?.contractId, onInitialContractLoaded]);

    return [error, loading] as const;
};

export default useInitialModuleInputSourceContractConfiguration;
