function AvatarCtrl($scope) {
    const ctrl = this;

    $scope.sizeClassMapping = {
        small: 'avatar-sm',
        medium: 'avatar-md',
        large: 'avatar',
        'extra-large': 'avatar-extra-large',
    };

    ctrl.$onInit = function () {
        $scope.extraClasses = [getOpacityClass(), getSizeClass()].join(' ');
        $scope.isDefaultImage = isDefaultAvatarImage();
    };

    function getOpacityClass() {
        if ($scope.opacity) {
            return 'mod-opacity';
        }
        return '';
    }

    function getSizeClass() {
        if (!$scope.size) {
            $scope.size = 'large';
        }
        return $scope.sizeClassMapping[$scope.size];
    }

    function isDefaultAvatarImage() {
        const defaultAvatarUrls = ['https://secure.gravatar.com/avatar', 'https://www.gravatar.com/avatar'];

        if (!$scope.hideDefaultAvatarPhoto) {
            return false;
        }

        if (!$scope.user.avatarUri) {
            return true;
        }

        return defaultAvatarUrls.some((url) => $scope.user.avatarUri.includes(url));
    }

    $scope.getUserOrGroupName = function () {
        // Name is for user, display name is for group
        return $scope.user.name || $scope.user.displayName;
    };

    $scope.isEmpty = function () {
        return $scope.user.members ? $scope.user.members.length === 0 : true;
    };
}

angular.module('tonkean.app').controller('AvatarCtrl', AvatarCtrl);
