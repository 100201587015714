<div>
    <!-- Loading -->
    <i ng-if="data.loading" class="loading"></i>

    <!-- Error loading forms -->
    <div ng-if="data.errorLoading" class="common-color-danger common-size-xxxxs">
        An error occurred while loading worker forms.
    </div>

    <!-- Displaying the selection of forms -->
    <div ng-if="!data.loading && !data.errorLoading">
        <ui-select
            class="ui-select-min-width"
            theme="bootstrap"
            ng-model="data.selectedForm"
            ng-change="onFormSelected(data.selectedForm)"
        >
            <ui-select-match data-automation="{{ data.automationIdentifier }}" placeholder="Select Form...">
                {{ data.selectedForm.displayName }}
                <!-- if we we are in the project settings -->
                <span
                    ng-if="data.clearable"
                    ng-click="clearSelection($select, $event)"
                    class="
                        svg-icon-vmiddle svg-icon-hover-dark-grey svg-icon-xxs
                        absolute-right
                        margin-right-lg
                        padding-right-xxs
                        common-color-grey
                        ui-select-custom-span-container
                    "
                >
                    <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
                </span>
            </ui-select-match>
            <ui-select-choices repeat="form in data.filteredForms | filter:$select.search" group-by="groupByGroupName">
                <!-- Regular form display -->
                <span ng-if="form.displayName && !form.isCreateNew">
                    <div
                        data-automation="form-selector-form-options"
                        data-automation-lable="{{ form.displayName }}"
                        ng-bind-html="form.displayName | highlight: $select.search"
                    ></div>
                </span>

                <!-- Open the create new update form modal -->
                <span ng-if="form.isCreateNew" ng-click="openEditWorkerFormModal(form.type)">
                    <div
                        data-automation="create-new-{{ form.displayName }}"
                        class="common-bold"
                        ng-bind-html="form.displayName | highlight: $select.search"
                    ></div>
                </span>
            </ui-select-choices>
        </ui-select>

        <div ng-if="data.dropdownFieldIsTooLongForDialogType" class="common-error margin-top-xxs common-size-xxs">
            Form in Slack only support dropdown fields up to 75 characters. Either update the form, or the fields will
            be truncate.
        </div>

        <div
            class="margin-top-xs"
            ng-if="
                data.selectedForm &&
                data.selectedForm.formType === 'UPDATE' &&
                data.selectedForm.group.id !== data.groupId
            "
        >
            <a ng-click="openMatchedFieldModal()">
                {{ data.existingFormMatchedEntityField ? 'Edit Matched Entity' : '+ Add Matched Entity' }}
            </a>

            <div class="alert alert-warning margin-top padding-normal-8">
                A Form from a different module was selected, no actions for When Form Answered are allowed after this
                block
            </div>
            <div
                class="margin-top-xs margin-bottom-xs common-color-danger"
                ng-if="data.validationObject.formNoMatchedEntity"
            >
                {{ data.validationObject.formNoMatchedEntity }}
            </div>
            <div
                class="margin-top-xs margin-bottom-xs common-color-danger"
                ng-if="data.validationObject.formMatchedEntityHasWhenAnswered"
            >
                {{ data.validationObject.formMatchedEntityHasWhenAnswered }}
            </div>
        </div>
    </div>
</div>
