import React from 'react';
import styled from 'styled-components';

import type { ExpressionEditorRef } from './ExpressionEditor';
import ExpressionEditor from './ExpressionEditor';
import { getVariables } from './utils/convertStringToNodes';

import { useDebouncedState } from '@tonkean/infrastructure';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const ExpressionEditorStyled = styled(ExpressionEditor)`
    border: 1px solid #d8d8d8;
    background: ${Theme.colors.basicBackground};
    border-radius: 5px;
    padding: 5px;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: ${FontSize.SMALL_12};
    min-height: 34px;
`;

interface Props extends StyledComponentsSupportProps {
    setControlObject(controlObject: ExpressionEditorRef): void;

    onExpressionUpdated(displayableExpression: string, evaluatedExpression: string): void;

    translateVariableLabel(variableId: string): string;

    getVariableIconClassName?(variableId: string, variableLabel: string): string | undefined;
    getProjectIntegrationTypeAndId?(variableId: string): { integrationType?: string; projectIntegrationId?: string };

    expression: string;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    customTriggerId?: string;
    workflowVersionId?: string;
    debounceOnChange?: boolean;
    placeholder?: string;
    prefix?: string;
    automationIdentifierExpressionValue?: string;
    additionalTabs?: TonkeanExpressionAdditionalTab[];
}

const TagsExpression: React.FC<Props> = ({
    setControlObject,
    onExpressionUpdated,
    translateVariableLabel,
    getVariableIconClassName,
    getProjectIntegrationTypeAndId,
    expression,
    groupId,
    customTriggerId,
    workflowVersionId,
    debounceOnChange = false,
    placeholder,
    prefix,
    className,
    automationIdentifierExpressionValue,
    additionalTabs,
}) => {
    const emitOnChange = (newValue: string) => {
        const displayableExpression = getVariables(newValue)
            .map(({ text, isVariable }) => {
                if (isVariable) {
                    const [variableId] = text.trim().split('|');
                    return `{{${translateVariableLabel(variableId)}}}`;
                }
                return text;
            })
            .join('');

        onExpressionUpdated(displayableExpression, newValue);
    };

    const onDebouncedChange = () => {
        if (debounceOnChange) {
            emitOnChange(value);
        }
    };

    const [value, setValue] = useDebouncedState(expression, onDebouncedChange);

    const onChange = (newValue: string) => {
        setValue(newValue);

        if (!debounceOnChange) {
            emitOnChange(newValue);
        }
    };

    return (
        <ExpressionEditorStyled
            value={value}
            onChange={onChange}
            groupId={groupId}
            workflowVersionId={workflowVersionId}
            customTriggerId={customTriggerId}
            placeholder={placeholder}
            translateVariableLabel={translateVariableLabel}
            getVariableIconClassName={getVariableIconClassName}
            getProjectIntegrationTypeAndId={getProjectIntegrationTypeAndId}
            ref={setControlObject}
            className={className}
            prefix={prefix}
            additionalTabs={additionalTabs}
            data-automation={
                !automationIdentifierExpressionValue
                    ? 'tags-expression-expression-editor'
                    : automationIdentifierExpressionValue
            }
        />
    );
};

export default TagsExpression;
