import React from 'react';

import type DefaultTonkeanNavigationUrlParams from './entities/DefaultTonkeanNavigationUrlParams';

const DefaultTonkeanNavigationParamsContext = React.createContext<DefaultTonkeanNavigationUrlParams>({
    projectId: undefined,
    projectUrlSlug: undefined,
});

export default DefaultTonkeanNavigationParamsContext;
