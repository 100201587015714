import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BusinessReportIcon } from '../../../../images/icons/navigation/business-reports.svg';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { Tooltip } from '@tonkean/infrastructure';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const BusinessReportButtonLabel = styled.span`
    color: ${Theme.current.palette.colorPicker.HEX_5B636C};
    margin-left: 6px;
    line-height: 14px;
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
`;

const NavigationButton = styled.span<{ $disabled: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 94px;
    height: 30px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    opacity: ${(props) => (props.$disabled ? '0.5' : 'unset')};
    cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};

    &:hover {
        border: 1px solid ${Theme.colors.gray_400};
        text-decoration: none;
        background-color: ${(props) => (props.$disabled ? Theme.colors.white : Theme.colors.HEX_F9F9F7)};
    }
`;

const BusinessReportButtonStateLink = styled(StateLink)<{ $disabled: boolean }>`
    display: flex;
    align-items: center;
    pointer-events: ${(props) => (props.$disabled ? 'none' : 'default')};
    opacity: ${(props) => (props.$disabled ? 0.7 : 1)};
    padding: 0px 16px;
    width: 94px;
    height: 30px;

    &:hover {
        text-decoration: none;
    }
`;

interface Props {
    isActive: boolean;
    groupId: string;
}

const BusinessReportButton: React.FC<Props> = ({ groupId, isActive }) => {
    const projectManager = useAngularService('projectManager');
    const [env] = useGetStateParams('env');
    const businessReportAccess = projectManager.allowedBusinessReportIdsSet[groupId];

    return (
        <Tooltip
            disabled={isActive && businessReportAccess}
            content={
                <>
                    You don’t have permissions to view this module’s business report,
                    <br /> ask your board admin for permissions.
                </>
            }
            textAlignLeft
        >
            <NavigationButton $disabled={!isActive || !businessReportAccess}>
                <BusinessReportButtonStateLink
                    data-automation="business-report-button"
                    $disabled={!isActive || !businessReportAccess}
                    state="product.board"
                    params={{ g: groupId, filter: 'all', env: env || 'DRAFT' }}
                >
                    <BusinessReportIcon />
                    <BusinessReportButtonLabel>Report</BusinessReportButtonLabel>
                </BusinessReportButtonStateLink>
            </NavigationButton>
        </Tooltip>
    );
};

export default BusinessReportButton;
