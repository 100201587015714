import React from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useCreateDialog } from '@tonkean/infrastructure';
import { TrashFormIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const DeletionIcon = styled(TrashFormIcon)`
    height: 16px;
    width: 16px;
    color: ${Theme.colors.gray_600};
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: flex-start;
`;

const TitleText = styled.div`
    flex-grow: 1;
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_700};
`;

const Content = styled.div`
    margin: 16px 0;
    overflow: auto;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_700};
`;

interface Props {
    onDelete: (commentId: string, targetId?: string) => void;
    commentId: string;
    content: string | JSX.Element;
    title: string | JSX.Element;
    targetId?: TonkeanId<TonkeanType.ACTIVITY>;
}

const CommentDeletionControl: React.FC<Props> = ({ onDelete, commentId, content, title, targetId }) => {
    const { confirm } = useCreateDialog();
    const [, deleteItem] = useLazyTonkeanService('deleteItem');

    const handleDelete = () => {
        confirm(
            <div>
                <Header>
                    <DeletionIcon />
                    <TitleText>{title}</TitleText>
                </Header>
                <Content>{content}</Content>
            </div>,
            {
                delete: true,
                okLabel: 'Delete',
                onOk: () => {
                    return deleteItem(commentId).then(() => {
                        onDelete(commentId, targetId);
                    });
                },
                onCreateCustomError: (error) => {
                    return 'There was a problem deleting this comment. Refresh the page and try again.';
                },
            },
        );
    };

    return (
        <IconButton data-automation="comment-view-delete-comment-button" onClick={handleDelete}>
            <TrashFormIcon />
        </IconButton>
    );
};

export default CommentDeletionControl;
