import { useCallback, useEffect, useState } from 'react';

import type UpdateOverviewProp from '../utils/UpdateOverviewProp';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

function useOverviewResult<T extends EnterpriseComponentType>(
    projectId: string,
    enterpriseComponentId: string,
    enterpriseComponentType: T,
) {
    const [overviewResult, setOverviewResult] = useState<ConvertEnterpriseComponentTypeToOverviewResult<T>>();

    const { data } = useTonkeanService(
        'getEnterpriseComponentOverview',
        projectId,
        enterpriseComponentId,
        enterpriseComponentType,
    );

    useEffect(() => {
        setOverviewResult(data as ConvertEnterpriseComponentTypeToOverviewResult<T> | undefined);
    }, [data]);

    const updateOverview = useCallback<UpdateOverviewProp<T>>((updateFunc) => {
        setOverviewResult((currentOverviewResult) => {
            if (!currentOverviewResult) {
                return;
            }

            return {
                ...currentOverviewResult,
                ...updateFunc(currentOverviewResult),
            };
        });
    }, []);

    return [overviewResult, updateOverview] as const;
}

export default useOverviewResult;
