export function MavenlinkModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        const promise = oauthHandler
            .mavenlink(environment.integrationKeysMap.mavenlink, environment.integrationKeysMap.mavenlinkUri)
            .then((code) => {
                return { code };
            });

        promise
            .then((config) => {
                config.redirectUri = environment.integrationKeysMap.mavenlinkUri;
                const integrationType = integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'MAVENLINK',
                    $scope.otherIntegrationOwner,
                );
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrationType,
                    undefined,
                );
            })
            .then((integObj) => {
                $scope.integration = integObj;
            })
            .catch((error) => {
                $scope.error = error;
            })
            .finally((invalid) => {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
    };

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }
        const settings = {
            integration: $scope.integration,
            integrations: [{ integration: $scope.integration, projectData: {} }],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(settings);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (projectIntegration) {
                return $rootScope.currentUser && projectIntegration.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}
export default angular.module('tonkean.shared').controller('MavenlinkModalCtrl', MavenlinkModalCtrl);
