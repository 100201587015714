import React, { useState } from 'react';
import styled from 'styled-components';

import ActionTestPageRequestViewTabType from './ProjectIntegrationActionsTestPageRequestView/ActionTestPageRequestViewTabType';
import SQLReservedKeyWords from './SQLReservedKeywords';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    HighlightableText,
    Tab,
    Tabs,
    TabsBorderType,
} from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    margin-top: 25px;
`;

const InputBox = styled.div`
    padding: 8px;
    background-color: ${Theme.colors.brightestGray} !important;
    min-height: 100px;
    border: 1px solid ${Theme.colors.gray_300} !important;
`;

const Highlighted = styled.span`
    color: ${Theme.colors.primaryHighlight};
`;

interface Props {
    evaluatedRequest: string | undefined;
}

const ProjectIntegrationActionSqlRequestView: React.FC<Props> = ({ evaluatedRequest }) => {
    // State for the viewing Tab
    const [selectedTab, setSelectedTab] = useState(ActionTestPageRequestViewTabType.QUERY);

    return (
        <>
            <Tabs
                showBackground={false}
                value={selectedTab}
                onChange={setSelectedTab}
                borderType={TabsBorderType.RAILWAY}
                height="46px"
            >
                <Tab label={ActionTestPageRequestViewTabType.QUERY}>{ActionTestPageRequestViewTabType.QUERY}</Tab>
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <StyledAnimatedSwitchItem label={ActionTestPageRequestViewTabType.QUERY}>
                    <InputBox>
                        <HighlightableText
                            highlightedTextWrapper={<Highlighted />}
                            highlightText={SQLReservedKeyWords}
                            text={evaluatedRequest}
                            fullWordsOnly
                        />
                    </InputBox>
                </StyledAnimatedSwitchItem>
            </AnimatedSwitch>
        </>
    );
};

export default ProjectIntegrationActionSqlRequestView;
