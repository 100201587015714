import template from './tnkMultiFormSelection.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkMultiFormSelection', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        existingForms: '<',
        onFormsChange: '&',
        hideFileUploadForms: '<',
        hideCreateForms: '<',
    },
    template,
    controller: 'multiFormSelectionCtrl',
});
