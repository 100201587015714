import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as TrashIcon } from '../../../../../../../../images/icons/trash-form.svg';

import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import type { CustomFilterFieldMetadata } from '@tonkean/angular-to-react-components';
import {
    Field,
    Input,
    MenuItem,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalHeaderActionButtons,
    ModalSize,
    SimpleSelect,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import type {
    BaseActionParameter,
    FieldListInputParameter,
    FieldListInputParameterConfiguration,
    FieldQuery,
} from '@tonkean/tonkean-entities';
import {
    ActionParameterType,
    fieldListValueOutputTypeToDisplayName,
    ProjectIntegrationFieldListActionParameterType,
    ProjectIntegrationFieldListValueOutputType,
} from '@tonkean/tonkean-entities';
import { getDefaultTonkeanQuery } from '@tonkean/tonkean-utils';
import { InputSize } from '@tonkean/tui-theme/sizes';
import utils, { yupEnum, yupTonkeanExpression } from '@tonkean/utils';

const fieldMetadataProperties: CustomFilterFieldMetadata[] = [
    {
        name: 'name',
        label: 'name',
        type: 'string',
    },
    {
        name: 'label',
        label: 'label',
        type: 'string',
    },
    {
        name: 'updateable',
        label: 'updateable',
        type: 'boolean',
    },
    {
        name: 'type',
        label: 'type',
        type: 'string',
    },
];

const StyledField = styled(Field)`
    margin-bottom: 18px;
`;

interface Props {
    existingFieldListParam?: FieldListInputParameter;
    onSubmit: (fieldListParameter: BaseActionParameter, isNew: boolean) => void;
    onDelete: (id: string) => void;
    entityToRunOn?: string;
    parameters: BaseActionParameter[];
}

const ProjectIntegrationActionFieldListModal: React.FC<Props> = ({
    existingFieldListParam,
    onSubmit,
    onDelete,
    parameters,
}) => {
    const onClose = useCloseCallback();

    const isNameUnique = useCallback(
        (newName: string) => {
            return !parameters
                .filter((parameter) => parameter.id !== existingFieldListParam?.id)
                .map((parameter) => parameter.displayName)
                .includes(newName);
        },
        [existingFieldListParam?.id, parameters],
    );

    const ProjectIntegrationActionFieldListCreation = useMemo(() => {
        return Yup.object({
            displayName: Yup.string()
                .required('Must fill a name')
                .max(150, 'Field List name must be at most 150 characters')
                .test('uniqueName', ' Entity name is not allowed, name already exists', isNameUnique),
            configuration: Yup.object({
                fieldListType: yupEnum(ProjectIntegrationFieldListActionParameterType).required(
                    'Must choose Field List type',
                ),
                queryDefinition: Yup.object().nullable(),
                keyExpression: yupTonkeanExpression().when(['configuration.fieldListType'], {
                    is: (fieldListType) => fieldListType === ProjectIntegrationFieldListActionParameterType.JSON,
                    then: yupTonkeanExpression('Must set a key expression').required('Must set a key expression'),
                    otherwise: yupTonkeanExpression(),
                }),
                valueOutputType: yupEnum(ProjectIntegrationFieldListValueOutputType).when(
                    ['configuration.fieldListType'],
                    {
                        is: (fieldListType) => fieldListType === ProjectIntegrationFieldListActionParameterType.JSON,
                        then: yupEnum(ProjectIntegrationFieldListValueOutputType).required('Must choose output type'),
                        otherwise: yupEnum(ProjectIntegrationFieldListValueOutputType).notRequired(),
                    },
                ),
                valueExpression:
                    yupTonkeanExpression('Must set a value expression').required('Must set a value expression'),
            }),
        });
    }, [isNameUnique]);

    const expressionProps = useMemo(
        () => ({
            additionalTabs: [
                {
                    id: 'FIELD_LIST_DEFINITION_FIELDS',
                    label: 'Field List',
                    iconClass: 'mod-enterprise-component-variable',
                    shouldShowInFormulasChoices: true,
                    searchPlaceholder: 'Search...',
                    getFields: () => {
                        return Promise.resolve([
                            {
                                name: 'Field_Name',
                                value: 'FIELD_LIST_KEY',
                                label: 'Field Name',
                                id: 'FIELD_LIST_KEY',
                            },
                            {
                                name: 'Value',
                                value: 'FIELD_LIST_VALUE',
                                label: 'Module provided value',
                                id: 'FIELD_LIST_VALUE',
                            },
                        ]);
                    },
                },
            ],
            hideEditorButton: true,
            globalExpressionOnly: true,
            doNotEvaluatePreview: true,
        }),
        [],
    );

    const fieldListTypeOptions = useMemo(() => {
        return Object.values(ProjectIntegrationFieldListActionParameterType).map((value) => ({
            label: value,
            value,
        }));
    }, []);

    const valueOutputTypeOptions = useMemo(() => {
        return Object.entries(fieldListValueOutputTypeToDisplayName).map(([type, name]) => ({
            label: name,
            value: type,
        }));
    }, []);

    const initialValues = useMemo(() => {
        const initialConfiguration = {
            fieldListType: ProjectIntegrationFieldListActionParameterType.JSON,
            queryDefinition: {
                query: getDefaultTonkeanQuery() as unknown as FieldQuery,
            },
            keyExpression: {
                evaluatedExpression: '{{FIELD_LIST_KEY}}',
                originalExpression: '{{ Field Name }}',
                isStripHtmlDisabled: true,
            },
            valueOutputType: ProjectIntegrationFieldListValueOutputType.OBJECT,
            valueExpression: {
                evaluatedExpression: '{{FIELD_LIST_VALUE}}',
                originalExpression: '{{ Module provided value }}',
                isStripHtmlDisabled: true,
            },
        } as FieldListInputParameterConfiguration;

        return {
            displayName: existingFieldListParam?.displayName || '',
            entityType: existingFieldListParam?.entityType,
            configuration: existingFieldListParam?.configuration || initialConfiguration,
        };
    }, [
        existingFieldListParam?.configuration,
        existingFieldListParam?.displayName,
        existingFieldListParam?.entityType,
    ]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ProjectIntegrationActionFieldListCreation}
            onSubmit={(values) => {
                const oldParamId = existingFieldListParam?.id;

                const fieldListParameter: FieldListInputParameter = {
                    id: oldParamId ?? utils.guid(),
                    displayName: values.displayName,
                    parameterType: ActionParameterType.FIELD_LIST,
                    configuration: values.configuration,
                };

                onSubmit(fieldListParameter, !existingFieldListParam);
                onClose();
            }}
        >
            {({ values }) => (
                <ModalForm>
                    <ModalHeader>
                        {existingFieldListParam ? 'Edit' : 'Create'} Field List
                        {existingFieldListParam && (
                            <ModalHeaderActionButtons
                                menuItems={
                                    <MenuItem
                                        icon={<TrashIcon />}
                                        onClick={() => {
                                            onDelete(existingFieldListParam.id);
                                            onClose();
                                        }}
                                    >
                                        Delete
                                    </MenuItem>
                                }
                            />
                        )}
                    </ModalHeader>
                    <ModalBody>
                        <StyledField label="Field List Name">
                            <Input size={InputSize.MEDIUM} name="displayName" placeholder="Type name" />
                        </StyledField>

                        <StyledField label="Set type">
                            <SimpleSelect options={fieldListTypeOptions} name="configuration.fieldListType" thin />
                        </StyledField>

                        <StyledField label="Fields">
                            <FormikTnkCustomFilters
                                name="configuration.queryDefinition"
                                itemsSource="CUSTOM"
                                specialFieldsForFeatures={EMPTY_ARRAY}
                                additionalFields={fieldMetadataProperties}
                                hideTimeRangeSelection
                                hideCloseButton
                                editMode
                            />
                        </StyledField>

                        {values.configuration.fieldListType === ProjectIntegrationFieldListActionParameterType.JSON && (
                            <>
                                <StyledField
                                    label="Key"
                                    description=" * use {{ Field Name }} to inject the key provided by the user"
                                >
                                    <FormikTonkeanExpression {...expressionProps} name="configuration.keyExpression" />
                                </StyledField>

                                <StyledField label="Set value type">
                                    <SimpleSelect
                                        options={valueOutputTypeOptions}
                                        name="configuration.valueOutputType"
                                        thin
                                    />
                                </StyledField>
                            </>
                        )}

                        <StyledField
                            label="Value"
                            description=" * use {{ Module provided value }} to inject the value provided by the user"
                        >
                            <FormikTonkeanExpression {...expressionProps} name="configuration.valueExpression" />
                        </StyledField>
                    </ModalBody>
                    <ModalFooterActions />
                </ModalForm>
            )}
        </Formik>
    );
};

export default withModal(ProjectIntegrationActionFieldListModal, {
    size: ModalSize.SMEDIUM,
    fixedWidth: true,
    onBackdropClicked: () => false,
});
