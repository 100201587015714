import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SidePaneCtrl($scope, $rootScope) {
    const ctrl = this;

    $scope.data = {
        customClass: ctrl.customClass,
        isOpen: ctrl.isOpen,
        doubleSizePane: ctrl.doubleSizePane,
        isLeft: ctrl.isLeft,
        dontPreventScrollBubbling: ctrl.dontPreventScrollBubbling,
        tabs: ctrl.tabs,
        tabsCustomClass: ctrl.tabsCustomClass,
        selectedTab: ctrl.selectedTab,
        marginTop: ctrl.marginTop,
        paneDisabled: ctrl.paneDisabled,
        onDisabledClick: ctrl.onDisabledClick,
    };
    ctrl.$onInit = function () {
        if ($scope.data.tabs?.length > 0 && !$scope.data.selectedTab) {
            $scope.onTabSelected($scope.data.tabs[0], false);
            $scope.setIsOpen(false, false);
        }
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedTab) {
            $scope.data.selectedTab = changesObj.selectedTab.currentValue;
        }

        if (changesObj.doubleSizePane) {
            $scope.data.doubleSizePane = changesObj.doubleSizePane.currentValue;
        }

        if (changesObj.marginTop) {
            $scope.data.marginTop = changesObj.marginTop.currentValue;
        }

        if (changesObj.paneDisabled) {
            $scope.data.paneDisabled = changesObj.paneDisabled.currentValue;
        }

        if (changesObj.onDisabledClick) {
            $scope.data.onDisabledClick = changesObj.onDisabledClick.currentValue;
        }

        if (changesObj.isOpen) {
            $scope.data.isOpen = changesObj.isOpen.currentValue;

            if ($scope.data.isOpen && $scope.data.tabs && $scope.data.tabs.length && !$scope.data.selectedTab) {
                $scope.onTabSelected($scope.data.tabs[0], false);
            }
        }
    };

    $scope.onTabSelected = function (tab, triggerCallbackParam = true) {
        if (!$scope.data.isOpen) {
            $scope.setIsOpen(true, triggerCallbackParam);
        }

        if (triggerCallbackParam) {
            ctrl.onTabSelected({ tab });
        }
    };

    $rootScope.$on('onSidePaneClose', function () {
        $scope.setIsOpen(false);
    });

    $scope.setIsOpen = function (isOpen, triggerCallbackParam = true) {
        $scope.data.isOpen = isOpen;

        if (!$scope.data.selectedTab && isOpen) {
            $scope.onTabSelected($scope.data.tabs[0], triggerCallbackParam);
        }

        if (triggerCallbackParam) {
            ctrl.onIsOpenChanged({ isOpen });
        }
    };
}

export default angular.module('tonkean.app').controller('SidePaneCtrl', lateConstructController(SidePaneCtrl));
