<div class="common-close-btn-container text-center padding-normal margin-auto" style="max-width: 900px">
    <!-- Close button -->
    <span class="common-close-btn svg-icon-lg padding-normal-sm" ng-if="$dismiss" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>
    <div class="">
        <i class="fa fa-gift common-size-50 common-color-primary"></i>
    </div>
    <!-- Title -->
    <div class="text-center margin-top-lg margin-bottom-lg padding-bottom">
        <div class="common-title-md">Tell other companies about Tonkean, you each get $50</div>
        <div class="common-color-dark-grey common-size-xs common-thin">
            Redeem as credit to this account or an Amazon gift card!
        </div>
    </div>
    <div class="margin-bottom-lg">
        <img alt="referral" style="max-width: 100%; width: 487px" src="/images/referral-diagram.png" />
    </div>
    <div class="padding-top-xlg" ng-if="!data.referralUrl">
        <i class="loading-medium"></i>
    </div>
    <!-- Body -->
    <div ng-if="data.referralUrl" class="padding-top-lg">
        <p>Copy and share this link:</p>

        <!-- Input (readonly in angular) with copy button -->
        <div class="input-group common-width-80-no-xs margin-auto">
            <input
                type="text"
                id="input-referral"
                class="form-control inline-block text-center common-width-full"
                ng-model="data.referralUrl"
            />
            <span class="input-group-btn">
                <button type="button" class="btn btn-default" ng-click="copyUrlToClipboard('input-referral')">
                    Copy
                </button>
            </span>
        </div>

        <div class="margin-normal-v flex-inline mod-vmiddle common-color-link-blue">
            <div class="margin-right common-color-grey">Or share on:</div>
            <div
                ng-click="socialShare('facebook')"
                uib-tooltip="Share on Facebook"
                class="pointer margin-right-xs common-size"
            >
                <i class="fa fa-facebook-square"></i>
            </div>
            <div
                ng-click="socialShare('twitter')"
                uib-tooltip="Share on Twitter"
                class="pointer margin-right-xs common-size"
            >
                <i class="fa fa-twitter-square"></i>
            </div>
            <div
                ng-click="socialShare('linkedin')"
                uib-tooltip="Share on LinkedIn"
                class="pointer margin-right-xs common-size"
            >
                <i class="fa fa-linkedin-square"></i>
            </div>
            <div
                ng-click="socialShare('email')"
                uib-tooltip="Share via email"
                class="pointer margin-right-xs common-size"
            >
                <i class="fa fa-envelope-square"></i>
            </div>
        </div>

        <div class="margin-top-xlg padding-top-lg common-color-grey common-size-xxs common-thin">
            <div class="margin-auto" style="max-width: 700px">
                To qualify, your friend must sign up and upgrade to the Standard plan. You will receive a $50 credit
                that you can redeem as credit to your Tonkean account or as an Amazon gift card within 30 days after
                their free trial period has expired and they have paid for the upgrade. They will also receive $50
                discount if they upgrade to 2 seats or more. Does not apply to administrators of multiple accounts or
                accounts that were closed before the bonus is issued.
            </div>
        </div>
    </div>
    <!-- Buttons -->
    <div class="text-right">
        <!--<button class="btn btn-default" ng-click="$dismiss()">{{questionConfirmModalData.cancelLabel}}</button>-->
        <!--<button class="btn btn-primary" ng-click="$close()">{{questionConfirmModalData.okLabel}}</button>-->
        <!--<div ng-if="questionConfirmModalData && questionConfirmModalData.okSubLabel"-->
        <!--class="common-color-dark-grey common-size-xxxxs margin-top-xxs">-->
        <!--{{questionConfirmModalData.okSubLabel}}-->
        <!--</div>-->
    </div>
</div>
