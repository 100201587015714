import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { SimpleSelect, useDebouncedState } from '@tonkean/infrastructure';
import type { TrainingSetModelIdentifiers } from '@tonkean/tonkean-entities';

interface Props {
    modelId?: string;
    trainingSetModelsToExclude?: string[];
    trainingSetId?: string;
    onChange?: (selectedItemId: string, selectedItemLabel: string | undefined) => void;
    /** * Will be used to pass formik field name */
    name?: string;
    isDisabled?: boolean;
}

const TrainingSetModelSelector: React.FC<Props> = ({
    modelId: modelIdProps,
    onChange: onChangeProps,
    trainingSetModelsToExclude,
    trainingSetId,
    name,
    isDisabled = false,
}) => {
    const formik = useContext(FormikContext) as FormikContextType<unknown> | undefined;
    const modelId = name ? formik?.getFieldMeta<string>(name).value : modelIdProps;

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);

    const [entities, setEntities] = useState<TrainingSetModelIdentifiers[]>([]);

    const [{ loading }, getTrainingSetModelIdentifiers] = useLazyTonkeanService('getTrainingSetModelIdentifiers');
    const [{ loading: loadingPreselectedItem }, getEntitySummaryById] = useLazyTonkeanService(
        'getTrainingSetModelWithDocumentCollection',
    );

    const addEntities = (entities: TrainingSetModelIdentifiers[]) => {
        setEntities((currentEntities) => {
            const filteredCurrentEntities = currentEntities.filter((entity) =>
                entities.every((newEntity) => newEntity.id !== entity.id),
            );
            return [...filteredCurrentEntities, ...entities];
        });
    };

    useEffect(() => {
        if (trainingSetId) {
            getTrainingSetModelIdentifiers(trainingSetId, 50, 0, debouncedSearchTerm).then((response) =>
                addEntities(response.entities),
            );
        }
    }, [debouncedSearchTerm, getTrainingSetModelIdentifiers, trainingSetId]);

    useEffect(() => {
        // we want to run this useEffect only if we done loading the entities
        if (!entities.length || !modelId) {
            return;
        }

        if (entities.every((entity) => entity.id !== modelId)) {
            getEntitySummaryById(modelId).then((response) => addEntities([response.trainingSetModel]));
        }
    }, [entities, getEntitySummaryById, modelId]);

    /**
     * Once we have fetched entities, we set the filtered options in the API SimpleSelect expects to receive them.
     */
    const filteredOptions = useMemo(() => {
        if (!entities) {
            return [];
        }

        const modelsToExclude = trainingSetModelsToExclude?.filter((field) => field !== modelId);

        return entities
            .filter((entity) => !modelsToExclude?.includes(entity.id))
            .filter((entity) => entity.isActive || entity.id === modelId)
            .map((entity) => {
                return {
                    value: entity.id,
                    label: entity.displayName,
                };
            });
    }, [entities, modelId, trainingSetModelsToExclude]);

    const onChange = (selectedValue: string) => {
        const foundTrainingSetModelLabel = filteredOptions.find((option) => option.value === selectedValue)?.label;
        onChangeProps?.(selectedValue, foundTrainingSetModelLabel);
    };

    return (
        <SimpleSelect
            placeholder="Search item..."
            value={name ? undefined : modelId}
            onChange={name ? undefined : onChange}
            isLoading={loading || loadingPreselectedItem}
            inputValue={notDebouncedSearchTerm}
            onInputChange={setNotDebouncedSearchTerm}
            options={filteredOptions}
            name={name}
            isDisabled={isDisabled}
            thin
        />
    );
};

export default TrainingSetModelSelector;
