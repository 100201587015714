import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { persistedFetchExchange } from '@urql/exchange-persisted-fetch';
import type { IntrospectionQuery } from 'graphql';
import { createClient, dedupExchange, fetchExchange } from 'urql';

import addMockMutationForCacheUpdateToSchema from './addMockMutationForCacheUpdateToSchema';
import getGraphqlCacheOptimisticConfigurationToUpdateEntityInCache from './getGraphqlCacheOptimisticConfigurationToUpdateEntityInCache';
import getTonkeanGraphqlCacheQueryResolvers from './getTonkeanGraphqlCacheQueryResolvers';
import getTonkeanGraphqlCacheQueryResolversConfig from './getTonkeanGraphqlCacheQueryResolversConfig';
import getTonkeanGraphqlCacheUniqueKeys from './getTonkeanGraphqlCacheUniqueKeys';

function createUrqlTonkeanClient(
    schema: IntrospectionQuery,
    queryResolversConfig = getTonkeanGraphqlCacheQueryResolversConfig(schema),
    apiUrl: string,
    getHeaders: (() => HeadersInit) | undefined,
) {
    return createClient({
        url: `${apiUrl}graphql`,
        exchanges: [
            devtoolsExchange,
            dedupExchange,
            // Too buggy
            // populateExchange({ schema }),
            cacheExchange({
                schema: addMockMutationForCacheUpdateToSchema(schema),
                keys: getTonkeanGraphqlCacheUniqueKeys(schema),
                resolvers: {
                    Query: getTonkeanGraphqlCacheQueryResolvers(queryResolversConfig),
                },
                optimistic: {
                    ...getGraphqlCacheOptimisticConfigurationToUpdateEntityInCache(schema),
                },
            }),
            fetchExchange,
            persistedFetchExchange(),
        ],
        fetchOptions: () => {
            return {
                headers: {
                    ...getHeaders?.(),
                },
            };
        },
        suspense: true,
    });
}

export default createUrqlTonkeanClient;
