import template from './tnkPersonSelectionConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkPersonSelectionConfiguration', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        radioIdPrefix: '<', // A prefix for the radio button (in cases where you use multiple tnkPersonSelectionConfigurations).
        savedPersonSelectionConfiguration: '<',
        savedServiceAccountSelectionConfiguration: '<',
        validationObject: '<',
        onPeopleSelectionChanged: '&',
        onPersonSelectionConfigurationChanged: '&',
        onCommunicationProjectIntegrationChanged: '&',

        hideFailIfNoValue: '<', // When true, Fail if no value checkbox will be hidden.
        showSendBy: '<', // When true, send by select will be shown.
        showSendTo: '<', // When true, send to select will be shown.
        showSendAsGroupMessage: '<',

        configuredLogic: '<', // The logic hosting the people configuration

        allowCustom: '<',
        allowNone: '<',
        noneOptionLabel: '<',
        maxPeople: '<', // Whats the maximum number of people that can be selected
        // An array. When supplied, only the items from the given array are considered as choices for the people selection.
        // Should contain objects in the form of (at least): {id: <string>, title: <string>}
        specificPeopleCustomOptions: '<',
        specificPeopleCustomOptionsRefresh: '&', // ({q: searchText}) to support async search, implement this function and update the specificPeopleCustomOptions

        noTracksOwnerOption: '<', // When true, the option to choose the current Track's owner will be hidden.
        noPreviousActorOption: '<', // When true, hide the option of Actor of previous action
        showTonkeanOption: '<', // When true, an option to select Tonkean will be added first, followed by a Person option that encapsulates all other options.
        showServiceAccountOption: '<', // When true, an option to select Service account will be added, will allow set a none person email address.

        doNotEvaluatePreview: '<', // When false, will evaluate the expression for preview.
        overrideExampleInitiative: '<', // An example initiative for the preview.
        hidePreview: '<', // Weather to show preview by default

        supportQuickCreate: '<',

        uniqueIdentifier: '@',
        enableClearSelection: '@', // Add reset button in order to reset any selection
        defaultSelectedOption: '@', // The default selected option.
        fromFieldTooltip: '@', // A text to be displayed in a tooltip near the from field.
        showFromFieldTooltip: '<', // Whether to display the tooltip near the from field.
        communicationProjectIntegrationSelector: '<', // Show communication project integration selector

        onlyTonkeanOption: '<',

        previewEvaluationSource: '<',

        automationIdentifier: '@',
        enableChoosingTonkean: '<',
        onBehalfOfCustomPerson: '<',
    },
    template,
    controller: 'PersonSelectionConfigurationCtrl',
});
