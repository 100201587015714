<div class="post-message-action-configuration">
    <div class="margin-top-lg">
        <p class="margin-right">Action Type:</p>
        <p class="common-bold">End Sequence</p>
        <div>
            <tnk-radio-button
                view-only="data.changedActionTypeIsLoading"
                radio-id="continue-flow-{{ data.workflowVersionId }}"
                radio-name="continue-flow-{{ data.workflowVersionId }}"
                radio-value="continue-flow"
                model="data.selectedActionType"
                on-click="selectActionType(onClickParam)"
                on-click-param="('continue-flow')"
                radio-label="Show thank you message"
                automation-identifier="action-type-continue-a-flow"
            ></tnk-radio-button>
            <tnk-radio-button
                view-only="data.changedActionTypeIsLoading"
                radio-id="open-custom-interface-{{ data.workflowVersionId }}"
                radio-name="open-custom-interface-{{ data.workflowVersionId }}"
                radio-value="open-custom-interface"
                model="data.selectedActionType"
                on-click="selectActionType(onClickParam)"
                on-click-param="('open-custom-interface')"
                radio-label="Redirect to interface"
                automation-identifier="action-type-open-custom-interface"
            ></tnk-radio-button>
            <tnk-radio-button
                view-only="data.changedActionTypeIsLoading"
                ng-if="data.customTriggerActionType !== 'ASK_FIELD_UPDATE_ANSWERED'"
                radio-id="open-link-{{ data.workflowVersionId }}"
                radio-name="open-link-{{ data.workflowVersionId }}"
                radio-value="open-link"
                model="data.selectedActionType"
                on-click="selectActionType(onClickParam)"
                on-click-param="('open-link')"
                radio-label="Redirect to URL"
                automation-identifier="action-type-open-a-url-to-user"
            ></tnk-radio-button>
        </div>
        <p class="common-bold margin-top">Continue Sequence</p>
        <div>
            <tnk-radio-button
                view-only="data.changedActionTypeIsLoading"
                ng-if="data.customTriggerActionType !== 'ASK_FIELD_UPDATE_ANSWERED'"
                radio-id="open-form-{{ data.workflowVersionId }}"
                radio-name="open-form-{{ data.workflowVersionId }}"
                radio-value="open-form"
                model="data.selectedActionType"
                on-click="selectActionType(onClickParam)"
                on-click-param="('open-form')"
                radio-label="Open form"
                automation-identifier="action-type-open-a-form"
            ></tnk-radio-button>

            <tnk-radio-button
                ng-if="data.enableChoosingItemInterfaceInSequence"
                view-only="data.changedActionTypeIsLoading"
                radio-id="open-custom-interface-{{ data.workflowVersionId }}"
                radio-name="open-custom-interface-{{ data.workflowVersionId }}"
                radio-value="open-custom-interface-in-sequence"
                model="data.selectedActionType"
                on-click="selectActionType(onClickParam)"
                on-click-param="('open-custom-interface-in-sequence')"
                radio-label="Open interface"
                automation-identifier="action-type-open-custom-interface"
            ></tnk-radio-button>
        </div>
    </div>

    <!-- Open custom interface -->
    <div
        ng-if="
            data.selectedActionType === 'open-custom-interface' ||
            data.selectedActionType === 'open-custom-interface-in-sequence'
        "
    >
        <hr />

        <!-- Item Interface Selector-->
        <tnk-item-interface-selector
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            selected-item-interface-id="data.itemInterfaceId"
            on-item-interface-selected="(onItemInterfaceSelected)"
            environment="data.environment"
            allow-clear="true"
        ></tnk-item-interface-selector>

        <!-- Item interface type error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noItemInterface"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noItemInterface }}
        </div>

        <div ng-if="data.selectedActionType === 'open-custom-interface-in-sequence'">
            <hr />

            <div class="margin-top-lg">
                <p class="margin-right">Continue sequence:</p>
                <div>
                    <tnk-radio-button
                        view-only="false"
                        radio-id="immediately-{{ data.workflowVersionId }}"
                        radio-name="immediately-{{ data.workflowVersionId }}"
                        radio-value="IMMEDIATELY"
                        model="data.waitFormType"
                        on-click="selectWaitFormType(onClickParam)"
                        on-click-param="('IMMEDIATELY')"
                        radio-label="Immediately"
                    ></tnk-radio-button>

                    <tnk-radio-button
                        view-only="false"
                        radio-id="wait-conditions-{{ data.workflowVersionId }}"
                        radio-name="wait-conditions-{{ data.workflowVersionId }}"
                        radio-value="WAIT_CONDITIONS"
                        model="data.waitFormType"
                        on-click="selectWaitFormType(onClickParam)"
                        on-click-param="('WAIT_CONDITIONS')"
                        radio-label="When conditions match"
                    ></tnk-radio-button>
                </div>
            </div>

            <!-- Custom filters -->
            <tnk-custom-filters
                ng-if="data.waitFormType === 'WAIT_CONDITIONS'"
                display-integration-indication="true"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                hide-close-button="true"
                control="data.control"
                items-source="COLUMN"
                existing-definition="data.waitFormConditions"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                logic-id="data.configuredLogic.id"
                preview-evaluation-source="data.previewEvaluationSource"
                evaluate-preview="true"
                edit-mode="data.editMode"
                small-mod="true"
                hide-time-range-selection="true"
                bold-title="true"
                special-fields-for-features="['CUSTOM_TRIGGER_CONDITIONS']"
                on-filters-change="onFilterChange(shouldNotSaveLogic)"
                filters-change-only-after-init="true"
                reload-fields-on-new-field-definition-creation="'true'"
                show-field-inspect="false"
                show-field-inspect-modal="!!scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                force-expand="true"
            ></tnk-custom-filters>

            <!-- Custom Response Message -->
            <div ng-if="data.waitFormType === 'WAIT_CONDITIONS'" class="margin-bottom margin-top">
                <div class="margin-bottom-xs">
                    <span>Wait message:</span>
                </div>
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    saved-original-expression="data.waitingConditionMessage"
                    saved-evaluated-expression="data.evaluatedWaitingConditionMessage"
                    on-tonkean-expression-changed="
                        onEvaluatedWaitingConditionMessageChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                    preview-evaluation-source="data.previewEvaluationSource"
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>

    <div ng-if="data.selectedActionType === 'open-form'">
        <hr />

        <!-- Choosing form title -->
        <div class="common-title-xxs margin-bottom-xs">Choose form</div>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noFormSelected"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noFormSelected }}
        </div>

        <!-- Form Selector-->
        <tnk-form-selector
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            selected-form-id="data.formId"
            existing-form-matched-entity-field-id="data.formMatchedEntityFieldId"
            on-matched-entity-created-or-updated="onFormMatchedEntityCreatedOrUpdated(field)"
            open-field-modal="data.openFieldModal"
            opened-from-custom-trigger-id="data.configuredLogic.node.id"
            show-workflow-folder-forms="true"
            on-form-selected-open-confirmation="onFormSelectedOpenConfirmationWrap(form)"
            on-form-selected="onFormSelected(form)"
            form-type="data.formType"
            automation-identifier="when-form-answered-choose-form-selector"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        ></tnk-form-selector>

        <!-- Form Type selection -->
        <div class="common-normal-weight margin-top-xs inline-block" ng-if="!data.isUnderIntercomFlow">
            <span class="margin-right-xxs">Form Type:</span>

            <!-- Web Form-->
            <div class="margin-top-xs margin-left-xs">
                <tnk-radio-button
                    radio-id="form-type-selection-webform-{{ data.workflowVersionId }}"
                    radio-name="formType-selection-{{ data.workflowVersionId }}"
                    uib-tooltip="The form will open in a Tonkean web form."
                    tooltip-placement="right"
                    radio-value="WEBFORM"
                    model="data.formType"
                    radio-label="Web Form"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('WEBFORM')"
                    is-required="true"
                    automation-identifier="when-form-answered-form-type-web-form"
                ></tnk-radio-button>
            </div>

            <!-- Dialog -->
            <div class="margin-top-xs margin-left-xs flex-no-shrink">
                <tnk-radio-button
                    radio-id="form-type-selection-dialog-{{ data.workflowVersionId }}"
                    radio-name="formType-selection-{{ data.workflowVersionId }}"
                    uib-tooltip="{{
                        'The form will open a dialog in Slack.' +
                            (data.parentForceEmail
                                ? 'This option is not available when Microsoft Teams/Email is selected as a communication source.'
                                : '')
                    }}"
                    tooltip-placement="right"
                    radio-value="DIALOG"
                    model="data.formType"
                    radio-label="Slack Dialog"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('DIALOG')"
                    is-required="false"
                    view-only="data.form.formQuestionType === 'UPLOAD_FILES' || data.parentForceEmail"
                    automation-identifier="when-form-answered-form-type-slack-form"
                ></tnk-radio-button>
            </div>

            <!-- Previous Platform -->
            <div ng-if="data.showPreviousFormPlatformRadioOption" class="margin-top-xs margin-left-xs">
                <tnk-radio-button
                    radio-id="form-type-selection-previous-platform-{{ data.workflowVersionId }}"
                    radio-name="formType-selection-{{ data.workflowVersionId }}"
                    uib-tooltip="The form will open in a previous form platform."
                    tooltip-placement="right"
                    radio-value="PREVIOUS_PLATFORM"
                    model="data.formType"
                    radio-label="Previous Platform"
                    on-click="selectFormType(onClickParam)"
                    view-only="data.form.formQuestionType === 'UPLOAD_FILES' || data.parentForceEmail"
                    on-click-param="('PREVIOUS_PLATFORM')"
                    is-required="true"
                    automation-identifier="when-form-answered-form-type-previous-platform"
                ></tnk-radio-button>
            </div>
        </div>

        <div class="flex common-normal-weight margin-top-xs flex-col" ng-if="data.formType === 'WEBFORM'">
            <span class="flex-no-shrink margin-right-xxs">Open consecutive forms on</span>
            <div class="flex">
                <!-- New Page -->
                <div class="margin-top-xs margin-left-xs">
                    <tnk-radio-button
                        radio-id="form-location-selection-new-{{ data.workflowVersionId }}"
                        radio-name="formLocation-selection-{{ data.workflowVersionId }}"
                        uib-tooltip="The form will be opened in a new page."
                        radio-value="NEW"
                        model="data.formLocation"
                        radio-label="New Page"
                        on-click="selectFormLocation(onClickParam)"
                        on-click-param="('NEW')"
                        is-required="true"
                        automation-identifier="when-form-answered-open-form-in-new-page"
                    ></tnk-radio-button>
                </div>

                <!-- Same Page -->
                <div class="margin-top-xs margin-left-xs flex-no-shrink">
                    <tnk-radio-button
                        radio-id="form-location-selection-same-{{ data.workflowVersionId }}"
                        radio-name="formLocation-selection-{{ data.workflowVersionId }}"
                        uib-tooltip="The form will be opened on the same page."
                        radio-value="SAME"
                        model="data.formLocation"
                        radio-label="Same Page"
                        on-click="selectFormLocation(onClickParam)"
                        on-click-param="('SAME')"
                        is-required="false"
                        automation-identifier="when-form-answered-open-form-in-same-page"
                    ></tnk-radio-button>
                </div>
            </div>
        </div>

        <!-- Form type error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].formTypeError"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].formTypeError }}

            <a ng-click="editForm(data.formId, data.form.formType)">Edit</a>
        </div>

        <div class="flex flex-vmiddle common-normal-weight margin-top-xs" ng-if="data.formLocation === 'SAME'">
            <span class="flex-no-shrink margin-right-xxs">View as</span>
            <!-- Multiple forms -->
            <div class="margin-top-xs margin-left-xs flex-no-shrink">
                <tnk-radio-button
                    radio-id="form-border-view-type-single-{{ data.workflowVersionId }}"
                    radio-name="form-border-view-type-{{ data.workflowVersionId }}"
                    uib-tooltip="The form will be opened as part of the previous form"
                    radio-value="MULTIPLE_FORMS"
                    model="data.borderViewType"
                    radio-label="Multiple forms"
                    on-click="selectBorderViewType(onClickParam)"
                    on-click-param="('MULTIPLE_FORMS')"
                    is-required="false"
                    automation-identifier="view-as-multiple-forms"
                ></tnk-radio-button>
            </div>

            <!-- Single forms -->
            <div class="margin-top-xs margin-left-xs">
                <tnk-radio-button
                    radio-id="form-border-view-type-multiple-{{ data.workflowVersionId }}"
                    radio-name="form-border-view-type-{{ data.workflowVersionId }}"
                    uib-tooltip="The form will be opened apart from previous forms."
                    radio-value="SINGLE_FORMS"
                    model="data.borderViewType"
                    radio-label="Single form"
                    on-click="selectBorderViewType(onClickParam)"
                    on-click-param="('SINGLE_FORMS')"
                    is-required="true"
                    automation-identifier="view-as-single-forms"
                ></tnk-radio-button>
            </div>
        </div>

        <div ng-if="data.selectedActionType === 'open-form' && data.formType === 'WEBFORM'">
            <hr />

            <div class="margin-top-lg">
                <p class="margin-right">Continue form sequence:</p>
                <div>
                    <tnk-radio-button
                        view-only="false"
                        radio-id="immediately-{{ data.workflowVersionId }}"
                        radio-name="immediately-{{ data.workflowVersionId }}"
                        radio-value="IMMEDIATELY"
                        model="data.waitFormType"
                        on-click="selectWaitFormType(onClickParam)"
                        on-click-param="('IMMEDIATELY')"
                        radio-label="Immediately"
                    ></tnk-radio-button>

                    <tnk-radio-button
                        view-only="false"
                        radio-id="wait-conditions-{{ data.workflowVersionId }}"
                        radio-name="wait-conditions-{{ data.workflowVersionId }}"
                        radio-value="WAIT_CONDITIONS"
                        model="data.waitFormType"
                        on-click="selectWaitFormType(onClickParam)"
                        on-click-param="('WAIT_CONDITIONS')"
                        radio-label="When conditions match"
                    ></tnk-radio-button>
                </div>
            </div>

            <!-- Custom filters -->
            <tnk-custom-filters
                ng-if="data.waitFormType === 'WAIT_CONDITIONS'"
                display-integration-indication="true"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                hide-close-button="true"
                control="data.control"
                items-source="COLUMN"
                existing-definition="data.waitFormConditions"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                logic-id="data.configuredLogic.id"
                preview-evaluation-source="data.previewEvaluationSource"
                evaluate-preview="true"
                edit-mode="data.editMode"
                small-mod="true"
                hide-time-range-selection="true"
                bold-title="true"
                special-fields-for-features="['CUSTOM_TRIGGER_CONDITIONS']"
                on-filters-change="onFilterChange(shouldNotSaveLogic)"
                filters-change-only-after-init="true"
                reload-fields-on-new-field-definition-creation="'true'"
                show-field-inspect="false"
                show-field-inspect-modal="!!scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                force-expand="true"
            ></tnk-custom-filters>

            <!-- Custom Response Message -->
            <div ng-if="data.waitFormType === 'WAIT_CONDITIONS'" class="margin-bottom margin-top">
                <div class="margin-bottom-xs">
                    <span>Wait message:</span>
                </div>
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    saved-original-expression="data.waitingConditionMessage"
                    saved-evaluated-expression="data.evaluatedWaitingConditionMessage"
                    on-tonkean-expression-changed="
                        onEvaluatedWaitingConditionMessageChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                    preview-evaluation-source="data.previewEvaluationSource"
                ></tnk-tonkean-expression>
            </div>
        </div>

        <div ng-if="data.form.formQuestionType === 'UPLOAD_FILES'">
            <tnk-upload-files-data-storage-selection
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                preview-evaluation-source="data.previewEvaluationSource"
                on-data-storage-changed="(onDataStorageChanged)"
                current-selected-data-storage-id="data.dataStorageId || data.form.definition.dataStorageId"
                current-folder-expression="data.folderIdExpression || data.form.definition.destinationFolderId"
                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                form="data.form"
                environment="data.environment"
                disabled="data.disableStorageSelection"
            ></tnk-upload-files-data-storage-selection>
        </div>

        <!-- Post Form Update Fields -->
        <div ng-if="data.form.formType === 'CREATE'">
            <hr />
            <div class="common-title-xxs margin-bottom-xs">Update Destination Fields</div>
            <div class="common-color-grey margin-bottom-xs">
                Select destination fields to update on the destination module, values are evaluated from the source
                module
            </div>

            <tnk-multiple-field-to-update-editors
                field-selector-group-id="{{ data.form.group.id }}"
                field-selector-workflow-version-id="{{ data.form.workflowVersion.id }}"
                expression-group-id="{{ data.groupId }}"
                expression-workflow-version-id="{{ data.workflowVersionId }}"
                configured-fields="data.postFormFieldsToUpdate"
                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                preview-evaluation-source="data.previewEvaluationSource"
                validation-key-prefix="'post-form-'"
                special-fields-for-features="['CREATE_TRACK_UPDATE_FIELDS']"
                on-configured-fields-changed="onConfiguredPostFormFieldsChanged(configuredFields, shouldSaveLogic)"
            ></tnk-multiple-field-to-update-editors>
        </div>

        <!--Display Form preview (including title)-->
        <span ng-if="data.formId && !data.isUnderIntercomFlow">
            <hr />
            <div class="margin-top-lg">
                <!-- Title -->
                <div class="flex-vmiddle">
                    <div class="flex-grow margin-bottom common-bold margin-right-xs common-title-xxs">Form Preview</div>
                    <div class="margin-bottom">
                        <button
                            class="btn btn-link"
                            ng-class="{ disabled: data.form.group.id !== data.groupId }"
                            ng-click="data.form.group.id === data.groupId && editForm(data.formId, data.form.formType)"
                            tooltip-placement="left"
                            uib-tooltip="{{
                                data.form.group.id !== data.groupId ? 'To edit the form move to it\'s Module' : ''
                            }}"
                        >
                            Edit
                        </button>
                    </div>
                </div>
                <!--Form preview-->
                <tnk-preview-form-ctrl
                    form-id="data.formId"
                    form="data.form"
                    form-type="data.formType"
                ></tnk-preview-form-ctrl>
            </div>
        </span>
    </div>

    <!-- Is Link -->
    <div ng-if="data.selectedActionType === 'open-link'">
        <hr />

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter Url..."
            saved-original-expression="data.urlLink"
            saved-evaluated-expression="data.evaluatedUrlLink"
            on-tonkean-expression-changed="
                onLinkTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            automation-identifier-expression-value="open-a-url-to-user-expression-value"
            automation-identifier-expression-add-field="open-a-url-to-user-expression-add-field"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <div>
            <label
                uib-tooltip="When turned on, will encode the values of each parameter in the URL query."
                tooltip-append-to-body="true"
                class="margin-top-xs"
            >
                <input type="checkbox" ng-model="data.shouldEncodeUrl" ng-change="onActionsChanged(true)" />
                <span>Auto-Encode</span>
            </label>
        </div>
    </div>

    <div
        ng-if="
            !data.isUnderIntercomFlow &&
            (data.selectedActionType === 'continue-flow' ||
            data.selectedActionType === 'open-link' ||
            data.selectedActionType === 'open-custom-interface')
        "
    >
        <hr />

        <div class="common-title-xxs">Response Message</div>

        <!-- Form submission response -->
        <div class="margin-bottom margin-top">
            <tnk-tonkean-expression
                ng-if="data.showWebFormResponse"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                saved-original-expression="data.webformResponseMessage"
                saved-evaluated-expression="data.evaluatedWebformResponseMessage"
                on-tonkean-expression-changed="
                    onWebformResponseMessageTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic
                    )
                "
                automation-identifier-expression-value="web-form-response-expression-value"
                automation-identifier-expression-add-field="web-form-response-expression-add-field"
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>

        <div
                ng-if="
            !data.isUnderIntercomFlow &&
            (data.selectedActionType === 'continue-flow' ||
                (data.customTriggerActionType === 'SEND_FORM_ANSWERED' && data.selectedActionType === 'open-form') ||
                (data.customTriggerActionType === 'ASK_FIELD_UPDATE_ANSWERED' &&
                    data.selectedActionType === 'open-form'))
        "
        >
            <!-- Custom Response Message -->
            <div class="margin-bottom">
                <div class="margin-bottom-xs">
                    <span ng-if="!data.parentForceEmail">Slack response message:</span>
                    <span ng-if="data.parentForceEmail">Email response message:</span>
                    <a
                        class="inline-block common-italic margin-left"
                        uib-tooltip="You can use markdown format to add styling to the message"
                        target="_blank"
                        ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
                    >
                        <i class="fa fa-info-circle"></i>
                    </a>
                </div>
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    saved-original-expression="data.customResponseMessage"
                    saved-evaluated-expression="data.evaluatedCustomResponseMessage"
                    on-tonkean-expression-changed="
                        onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                    automation-identifier-expression-value="slack-form-response-expression-value"
                    automation-identifier-expression-add-field="slack-form-response-expression-add-field"
                    preview-evaluation-source="data.previewEvaluationSource"
                ></tnk-tonkean-expression>
            </div>

            <label class="margin-bottom">
                <input type="checkbox" ng-model="data.isOneTime" ng-change="onActionsChanged(true)" />
                <span class="margin-left-xs common-normal-weight">Can only be clicked once?</span>
            </label>

            <!-- Send Response To -->
            <div>Send response to:</div>
            <div class="margin-top-xxs">
                <tnk-radio-button
                    radio-id="reply-to-person-{{ data.workflowVersionId }}"
                    radio-name="reply-to-person-{{ data.workflowVersionId }}"
                    radio-value="person"
                    model="data.selectedReplyTo"
                    on-click="selectReplyTo(onClickParam)"
                    on-click-param="('person')"
                    is-required="true"
                >
                    <!-- There's a slight assumption here that if it's not a bot button pressed post-action, then it's a form answered action. -->
                    <!-- In the future you might want to generalize it further to more type -->
                    <span>
                        Person who clicked the button
                    </span>
                </tnk-radio-button>
            </div>

            <div class="margin-top-xxs" ng-if="data.parentIsChannel">
                <tnk-radio-button
                    radio-id="reply-to-channel-{{ data.workflowVersionId }}"
                    radio-name="reply-to-channel-{{ data.workflowVersionId }}"
                    radio-value="channel"
                    model="data.selectedReplyTo"
                    on-click="selectReplyTo(onClickParam)"
                    on-click-param="('channel')"
                    is-required="true"
                >
                    <span>Channel the message was sent in</span>
                </tnk-radio-button>
            </div>

            <div class="margin-top-xxs">
                <tnk-radio-button
                    radio-id="reply-to-original-{{ data.workflowVersionId }}"
                    radio-name="reply-to-original-{{ data.workflowVersionId }}"
                    radio-value="original"
                    model="data.selectedReplyTo"
                    on-click="selectReplyTo(onClickParam)"
                    on-click-param="('original')"
                    is-required="true"
                    view-only="data.updateOriginalMessageDisabled"
                >
                    <span>Update original message</span>
                </tnk-radio-button>
            </div>

            <br />

            <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noReplyTarget" class="common-color-danger">
                {{ data.invalidLogics[data.configuredLogic.node.id].noReplyTarget }}
            </div>

            <!-- Preview -->
            <div class="margin-top-lg">
                <!-- Message Preview -->
                <div class="margin-bottom common-bold">Preview message:</div>

                <tnk-preview-bot-bubble-ctrl
                    text="data.previewMessageText"
                    text-evaluated-expression="data.previewMessageEvaluateText"
                    post-attachement-text="data.replyOnOriginalMessage ? data.previewReplyEvaluateText  : null"
                    preview-type="text"
                    user="as.currentUser"
                    color="data.color || '#e8e8e8'"
                    bot-buttons="data.previewMessageButtons"
                    preview-evaluation-source="data.previewEvaluationSource"
                    no-triangle="true"
                    logic-id="data.configuredLogic.node.id"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    unique-identifier="botButtonPreviewMessage"
                    html-preview="!data.parentSendAsPlainText && data.parentForceEmail"
                ></tnk-preview-bot-bubble-ctrl>

                <!-- Reply Preview -->
                <div ng-if="!data.replyOnOriginalMessage">
                    <div class="margin-bottom common-bold">Preview reply:</div>

                    <tnk-preview-bot-bubble-ctrl
                        text="data.previewReplyText"
                        text-evaluated-expression="data.previewReplyEvaluateText"
                        preview-type="text"
                        user="as.currentUser"
                        no-buttons="true"
                        no-triangle="true"
                        preview-evaluation-source="data.previewEvaluationSource"
                        logic-id="data.configuredLogic.node.id"
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        unique-identifier="botButtonReply"
                        html-preview="!data.parentSendAsPlainText && data.parentForceEmail"
                    ></tnk-preview-bot-bubble-ctrl>
                </div>
            </div>
        </div>
    </div>
</div>