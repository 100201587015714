import { WorkerRunReason } from '@tonkean/tonkean-entities';

const v1SupportedWorkerRunReasons = [
    WorkerRunReason.UNKNOWN,
    WorkerRunReason.RESUMED_WORKER,
    WorkerRunReason.PERSON_INQUIRY_RESUMED,
    WorkerRunReason.ASK_FIELD_UPDATE_RESUMED,
    WorkerRunReason.RUN_SCHEDULED_WORKER_NOW,
    WorkerRunReason.RUN_SCHEDULED_AUTONOMOUS_TRIGGER_NOW,
    WorkerRunReason.TEST_RUN,
    WorkerRunReason.NEW_CREATED_INITIATIVE,
    WorkerRunReason.AUTONOMOUS_TRIGGER_MATCH,
    WorkerRunReason.ACTION_ITEMS_RESUMED,
    WorkerRunReason.FIELD_CHANGE_AUTONOMOUS_TRIGGER_MATCH,
    WorkerRunReason.SCHEDULED_AUTONOMOUS_TRIGGER,
    WorkerRunReason.NEW_CREATED_INNER_INITIATIVE,
    WorkerRunReason.SCHEDULED_WORKER,
    WorkerRunReason.FORM_ANSWERED,
    WorkerRunReason.MONITOR_FORM_TRIGGER_MATCH,
    WorkerRunReason.INITIATIVE_CREATED_BY_FORM,
    WorkerRunReason.CHANGE_BUT_NO_NEW_AUTONOMOUS_TRIGGERS_MATCHED,
];

function WorkerRunsHistoryHelper(tonkeanService, projectManager) {
    const _this = this;

    _this.itemMap = {};

    _this.loadWorkerRunPage = function (
        groupId,
        initiativeId,
        triggerId,
        skip,
        doNotDisplayLoading,
        from,
        to,
        hideNoMatch,
        pageSize,
    ) {
        return tonkeanService
            .getWorkerRuns(
                projectManager.project.id,
                groupId,
                {
                    initiativeId,
                    triggerId,
                    onlyMatchedTriggers: hideNoMatch,
                    startedFrom: from,
                    startedTo: to,
                    onlyWorkerRunReasons: v1SupportedWorkerRunReasons,
                },
                skip,
                pageSize,
                undefined,
                false,
            )
            .then((data) => {
                // // Cache newly retrieved initiatives
                // let initiatives = utils.objToArray(data.relatedEntities)
                //     .filter(kvp => kvp.key.substring(0, 4) === 'INIT')
                //     .map(kvp => kvp.value);
                // trackHelper.cacheItemList(initiatives);

                for (let i = 0; i < data.entities.length; i++) {
                    const item = data.entities[i];
                    _this.itemMap[item.id] = item;
                }

                return data;
            });
    };

    _this.getItem = function (id) {
        return _this.itemMap[id];
    };

    _this.setItem = function (item) {
        _this.itemMap[item.id] = item;
    };
}

export default angular.module('tonkean.app').service('workerRunsHistoryHelper', WorkerRunsHistoryHelper);
