/* jshint ignore:start */

import React from 'react';

export default class DefaultInboxReason extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTitleLinkElement(initiative) {
        const context = initiative.parent ? initiative.parent.title : initiative.group.name;
        return (
            <span>
                <a onClick={this.props.openInitiativeModal.bind(this)}>{initiative.title}</a> ({context})
            </span>
        );
    }

    /**
     * The main render function
     */
    render() {
        return (
            <div>
                {this.renderTitleLinkElement(this.props.initiative)}
                <span className="common-bold"> needs your attention.</span>
            </div>
        );
    }
}
/* jshint ignore:end */
