import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import CreateOrEditProcessMapperModal from './CreateOrEditProcessMapperModal';
import { ReactComponent as EditIcon } from '../../../../images/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-new.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    KebabMenuButton as RawKebabMenuButton,
    Menu,
    MenuItem,
    Separator,
    TextEllipsis,
    useCreateDialog,
} from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { ProcessMapper, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';

const ProcessMapperBox = styled(Clickable)<{ $isActive: boolean }>`
    background: ${({ $isActive: isActive }) => (isActive ? Theme.colors.gray_300 : Theme.colors.basicBackground)};

    &:hover {
        background: ${Theme.colors.gray_300};
    }

    padding: 12px 8px;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        'title default spacer kebab'
        'subtitle subtitle subtitle kebab';
    gap: 8px;
`;

const ProcessMapperTitle = styled.span`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    grid-area: title;
`;
const ProcessMapperSubtitle = styled.span`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    grid-area: subtitle;
`;

const KebabMenuButton = styled(RawKebabMenuButton)`
    grid-area: kebab;
    place-self: center;
`;

interface Props {
    processMapper: ProcessMapper;
    workflowFolder: WorkflowFolder;
    activeProcessMapperId?: string;
    workflowVersionType: WorkflowVersionType;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    onProcessMapperUpdated(newProcessMapper: ProcessMapper): void;
    onProcessMapperDeleted(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
    ): void;
    groupId: TonkeanId<TonkeanType.GROUP>;
}

const ProcessMapperListItem: React.FC<Props> = ({
    processMapper,
    workflowFolder,
    activeProcessMapperId,
    workflowVersionType,
    workflowVersionId,
    onProcessMapperUpdated,
    onProcessMapperDeleted,
    groupId,
}) => {
    const utils = useAngularService('utils');
    const $rootScope = useAngularService('$rootScope');

    const isActive = activeProcessMapperId === processMapper?.id;

    const emitOnClick = useCallback(() => {
        $rootScope.$emit('onSidePaneClose');
    }, [$rootScope]);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showProcessMapperDetailsModal, setShowProcessMapperDetailsModal] = useState(false);

    const [, deleteProcessMapper] = useLazyTonkeanService('deleteProcessMapper');

    const { confirm: confirmDelete } = useCreateDialog();

    const onDeleteProcessMapper = useCallback(async () => {
        await confirmDelete(`${processMapper.displayName}`, `Are you sure you want to delete this process map?`, {
            okLabel: 'Delete',
            warning: true,
            onOk: () => {
                return deleteProcessMapper(processMapper.id).then(() => {
                    onProcessMapperDeleted(processMapper.workflowVersionId, processMapper.id);
                });
            },
        });
    }, [
        confirmDelete,
        deleteProcessMapper,
        onProcessMapperDeleted,
        processMapper.displayName,
        processMapper.id,
        processMapper.workflowVersionId,
    ]);

    return (
        <>
            <li>
                <ProcessMapperBox
                    key={processMapper.id}
                    state="."
                    options={{ location: 'replace' }}
                    params={{ processMapperId: processMapper.id, nodeId: undefined }}
                    onClick={() => emitOnClick()}
                    $isActive={isActive}
                    data-automation={`process-mapper-list-item-process-mapper-box-${processMapper.displayName}`}
                >
                    <ProcessMapperTitle>
                        <TextEllipsis numberOfLines={1}>{processMapper.displayName}</TextEllipsis>
                    </ProcessMapperTitle>
                    <ProcessMapperSubtitle>
                        Updated on {utils.formatDate(processMapper.updated, true, true)}
                    </ProcessMapperSubtitle>
                    {workflowVersionType !== WorkflowVersionType.PUBLISHED && (
                        <Menu
                            menuItems={
                                <>
                                    <MenuItem
                                        data-automation="process-mapper-list-item-edit-mapper"
                                        onClick={() => setShowProcessMapperDetailsModal(true)}
                                        icon={<EditIcon />}
                                    >
                                        Edit Details
                                    </MenuItem>
                                    <MenuItem
                                        data-automation="process-mapper-list-item-delete-mapper"
                                        onClick={onDeleteProcessMapper}
                                        icon={<TrashIcon />}
                                    >
                                        Delete
                                    </MenuItem>
                                </>
                            }
                            show={menuIsOpen}
                            onClose={() => setMenuIsOpen(false)}
                        >
                            <KebabMenuButton
                                data-automation={`process-mapper-list-item-kebab-menu-${processMapper.displayName}`}
                                aria-label="Open process map menu"
                                onClick={(e) => {
                                    setMenuIsOpen(true);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                flat
                            />
                        </Menu>
                    )}
                </ProcessMapperBox>
            </li>
            <Separator />
            {workflowFolder && (
                <CreateOrEditProcessMapperModal
                    open={showProcessMapperDetailsModal}
                    onClose={(createdProcessMapper) => {
                        if (createdProcessMapper) {
                            onProcessMapperUpdated(createdProcessMapper);
                        }
                        setShowProcessMapperDetailsModal(false);
                    }}
                    workflowFolder={workflowFolder}
                    existingProcessMapper={processMapper}
                    groupId={groupId}
                />
            )}
        </>
    );
};

export default ProcessMapperListItem;
