import React from 'react';
import styled from 'styled-components';

import { ClickableLink } from '@tonkean/tui-buttons/Link';

const StyledClickableLink = styled(ClickableLink)`
    display: inline;
`;

const AutoCloseLongDescription: React.FC = () => {
    return (
        <>
            To avoid the accumulation of unneeded items in the flow. Use this section to{` `}
            <StyledClickableLink
                state="product.workerEditor"
                params={{
                    lcm: 'when-finished',
                }}
            >
                define the relevant "Done" status.
            </StyledClickableLink>
        </>
    );
};

export default AutoCloseLongDescription;
