import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';

import History from '../../../modules/HistoryModule/History';

import { Modal } from '@tonkean/infrastructure';
import type { Environment, WorkerRunLogicInspectTabType, WorkerRunStage } from '@tonkean/tonkean-entities';
import type { RuntimePageTab } from '@tonkean/tonkean-entities';

interface Props {
    show: boolean;
    group: any;
    environment: Environment;
    workerRunInfo?: string;
    workerRunStage?: WorkerRunStage;
    inspect: boolean;
    workerRunLogicId?: string;
    tab?: WorkerRunLogicInspectTabType | RuntimePageTab;
    defaultFilterSearchTerm?: string;

    $state: any;
}

const HistoryModal: React.FC<Props> = ({
    show: showProp,
    group,
    environment,
    workerRunInfo,
    workerRunStage,
    inspect,
    workerRunLogicId,
    tab,
    defaultFilterSearchTerm,
}) => {
    const $state = useAngularService('$state');

    // We use a state and not showProp directly to be able to close the modal immediately without needing to wait
    // for the next digest cycle.
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(showProp);
    }, [showProp]);

    const closeModal = () => {
        setOpen(false);
        $state.go('product.workerEditor');
    };

    return (
        <>
            <Modal open={open} onClose={closeModal} hasBackdrop={false} fullScreen>
                <History
                    group={group}
                    environment={environment}
                    workerRunInfo={workerRunInfo}
                    workerRunStage={workerRunStage}
                    inspect={inspect}
                    workerRunLogicId={workerRunLogicId}
                    tab={tab}
                    defaultFilterSearchTerm={defaultFilterSearchTerm}
                    onCloseClick={closeModal}
                    isModal
                />
            </Modal>
        </>
    );
};

export default HistoryModal;
