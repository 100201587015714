<div>
    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noOwner" class="common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].noOwner }}
    </div>

    <tnk-update-fields-logic-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        invalid-logics="data.invalidLogics"
        reload-fields="data.reloadFields"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
        single-field-selector="true"
        single-field-selector-label="Owner:"
        preview-evaluation-source="data.previewEvaluationSource"
        single-field-selector-field-id="TNK_OWNER_ID"
        cant-control-notification="true"
    ></tnk-update-fields-logic-configuration>

    <i class="common-color-light-grey common-size-xxxxs">* If using field, that field must contain E-mail values.</i>
</div>
