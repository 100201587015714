import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addEndSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addEndSequenceBlockCreateAfterOption';
import addGoToSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addGoToSequenceBlockCreateAfterOption';
import addNewLogicOptionNotAfterOpenForm from '../sharedConfigComponents/form/createAfterOptions/addNewLogicOptionNotAfterOpenForm';
import addShowFormBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowFormBlockCreateAfterOption';
import addShowInterfaceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowInterfaceBlockCreateAfterOption';
import addInnerAutonomousAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addInnerAutonomousAfterOption';
import addMonitorBlockCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addMonitorBlockCreateAfterOption';
import customTriggerOfTypeIntakeMustBeSelectedValidator from '../sharedConfigComponents/validators/customTriggerOfTypeIntakeMustBeSelectedValidator';
import formMustBeSelectedValidator from '../sharedConfigComponents/validators/formMustBeSelectedValidator';
import formQuestionTypeMustMatchFormType from '../sharedConfigComponents/validators/formQuestionTypeMustMatchFormType';
import itemInterfaceMustBeSelectedValidator from '../sharedConfigComponents/validators/itemInterfaceMustBeSelectedValidator';
import storageProviderValidator from '../sharedConfigComponents/validators/storageProviderValidator';
import validateFormBeforePublish from '../sharedConfigComponents/validators/validateFormBeforePublish';
import validateFormFromDifferentModule from '../sharedConfigComponents/validators/validateFormFromDifferentModule';
import validateMultipleFieldEditors from '../sharedConfigComponents/validators/validateMultipleFieldEditors';
import validateParentTriggerChannelResponse from '../sharedConfigComponents/validators/validateParentTriggerChannelResponse';

import { AutonomousSecondaryType, LogicBlockConfigType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { CustomTrigger } from '@tonkean/tonkean-entities';

const SendFormAnsweredLogicBlock: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SEND_FORM_ANSWERED,
    title: 'Intake sequence step',
    iconClass: 'mod-send-form-answered',
    description:
        'This action will add another step to the intake sequence. Set the type of step you would like to display.',
    cantChangeType: () => true,
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    creatingActionItems: true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: false,
    cantDrag: false,
    specialChild: true,
    allowedChildrenTypes: (customTrigger?: CustomTrigger) => {
        if (
            customTrigger &&
            customTrigger.customTriggerActions[0].customTriggerActionDefinition.selectedActionType === 'open-form'
        ) {
            return [LogicBlockConfigType.MONITOR_TRACKS, LogicBlockConfigType.SEND_FORM_ANSWERED];
        } else return undefined;
    },
    allowedParentTypes: [
        LogicBlockConfigType.BOT_BUTTON_PRESSED,
        LogicBlockConfigType.SEND_FORM,
        LogicBlockConfigType.SEND_FORM_ANSWERED,
        LogicBlockConfigType.AUTONOMOUS,
        LogicBlockConfigType.ASK_FIELD_UPDATE,
        LogicBlockConfigType.AUTONOMOUS_INTERFACE_SUBMITTED,
    ],
    allowedDirectParentPredicate: (parent, customTriggerManager, currentTrigger) => {
        if (parent) {
            if (
                parent.customTriggerType === 'BOT_BUTTON_PRESSED' &&
                parent.customTriggerActions[0].customTriggerActionDefinition.selectedActionType !== 'open-form'
            ) {
                return {
                    type: 'alert',
                    message:
                        '"When Form Answered" is allowed to be dropped under  "Module Button" trigger only if the button configured to open a form',
                    messageType: 'error',
                };
            } else if (
                parent.customTriggerType === 'AUTONOMOUS' &&
                parent.customTriggerSecondaryType !== AutonomousSecondaryType.AUTONOMOUS_CREATED_FROM_FORM &&
                parent.customTriggerSecondaryType !== AutonomousSecondaryType.AUTONOMOUS_INTERFACE_SUBMITTED
            ) {
                return {
                    type: 'alert',
                    message:
                        '"When Form Answered" is allowed to be dropped under "autonomous" trigger only if it is of "Interface Submitted" or "Form Filled" type',
                    messageType: 'error',
                };
            } else if (
                currentTrigger.customTriggerActions[0].customTriggerActionDefinition.selectedActionType ===
                'go-to-sequence'
            ) {
                const allTriggerNewParents = customTriggerManager.getWorkflowVersionLogicParents(
                    parent.workflowVersion.id,
                    parent.id,
                );

                const newTriggersRootParent = allTriggerNewParents[0].node.customTriggerSecondaryType;

                if (newTriggersRootParent !== 'AUTONOMOUS_INTERFACE_SUBMITTED') {
                    return {
                        type: 'alert',
                        message:
                            '"When Form Answered" of type go to sequence is allowed to be dropped under "Interface Submitted" trigger only',
                        messageType: 'error',
                    };
                }
            }
        }
        return undefined;
    },
    createAfterOptions: () => [
        addShowFormBlockCreateAfterOption,
        addShowInterfaceBlockCreateAfterOption,
        addGoToSequenceBlockCreateAfterOption,
        addEndSequenceBlockCreateAfterOption,
        addNewLogicOptionNotAfterOpenForm,
        addMonitorBlockCreateAfterOption,
        addInnerAutonomousAfterOption,
    ],
    createAfterOptionsTitle: 'Sequence Actions',
    validatorAsync: async ({ definition, parentTrigger, group, formInfoRetriever, project, childImpacts }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (definition) {
            if (definition.selectedActionType === 'open-form') {
                formMustBeSelectedValidator(validationObject, definition);
            }

            if (
                definition.selectedButtonType === 'open-custom-interface' ||
                definition.selectedButtonType === 'open-custom-interface-in-sequence' ||
                definition.selectedButtonType === 'go-to-sequence' ||
                definition.selectedActionType === 'open-custom-interface-in-sequence'
            ) {
                itemInterfaceMustBeSelectedValidator(validationObject, definition);
            }

            if (definition.selectedActionType === 'go-to-sequence') {
                customTriggerOfTypeIntakeMustBeSelectedValidator(validationObject, definition);
            }

            if (definition.formId) {
                const form = await formInfoRetriever.getFormByWorkflowVersionType(
                    definition.formId,
                    WorkflowVersionType.DRAFT,
                );
                formQuestionTypeMustMatchFormType(validationObject, definition, form);
                validateFormFromDifferentModule(
                    validationObject,
                    form,
                    definition,
                    group.draftWorkflowVersionId,
                    childImpacts,
                    project.features,
                );
                validateFormBeforePublish(validationObject, form);
                validateMultipleFieldEditors(validationObject, definition.postFormFieldsToUpdate, false, 'post-form-');
                validateParentTriggerChannelResponse(validationObject, definition, parentTrigger);
                storageProviderValidator(validationObject, definition, form);
            }
        }
        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default SendFormAnsweredLogicBlock;
