import React from 'react';

import type FieldChartWidgetConfiguration from './FieldChartWidgetConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { CategoricalFieldInstanceChart } from '@tonkean/fields';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { ChartDataType, ChartDisplayType } from '@tonkean/infrastructure';
import type { FieldInstance, ItemInterfaceWidget } from '@tonkean/tonkean-entities';

interface props {
    widget: ItemInterfaceWidget<FieldChartWidgetConfiguration>;
    fieldInstance: FieldInstance | undefined;
}

const CategoricalFieldChartWidgetComponent: React.FC<props> = ({ widget, fieldInstance }) => {
    const { workflowVersion } = useItemInterfaceContext();

    const { data: categoricalFieldChartResults, loading } = useTonkeanService(
        'getCategoricalItemInterfaceFieldChartWidgetData',
        widget.workflowVersionId,
        widget.id,
    );

    return (
        <CategoricalFieldInstanceChart
            workflowVersion={workflowVersion}
            fieldType={widget.configuration.definition?.fieldType}
            fieldId={widget.configuration.definition?.aggregatedDefinitionId}
            fieldInstance={fieldInstance}
            chartDisplayType={widget.configuration.chartCategoricalDisplayType || ChartDisplayType.DOUGHNUT}
            optionallyEvaluatedResults={
                widget.configuration.chartDataType === ChartDataType.CATEGORICAL && widget.configuration.definition
                    ? categoricalFieldChartResults?.data
                    : undefined
            }
            loading={loading}
        />
    );
};

export default CategoricalFieldChartWidgetComponent;
