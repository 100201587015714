import { lateConstructController } from '@tonkean/angular-components';
import { getFieldRangeAppliedStyle } from '@tonkean/tonkean-utils';

/* @ngInject */
function PreviewListColumnsCtrl(
    $scope,
    $q,
    modalUtils,
    modal,
    tonkeanService,
    consts,
    requestThrottler,
    projectManager,
    utils,
    fieldDisplay,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.projectManager = projectManager;
    $scope.modalUtils = modalUtils;
    $scope.utils = utils;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        existingFieldDefinitionId: ctrl.existingFieldDefinitionId,
        definitionsToPreview: ctrl.definitionsToPreview,
        highlightedDefinitionId: ctrl.highlightedDefinitionId,
        globalFieldsToPreview: ctrl.globalFieldsToPreview,
        hidePreviewTitle: ctrl.hidePreviewTitle,
        softLoading: ctrl.softLoading,
        isBrokenIntegration: ctrl.isBrokenIntegration,
        filterInitiativesByTonkeanQuery: ctrl.filterInitiativesByTonkeanQuery,
        emptyStateType: ctrl.emptyStateType,
        highlightedFieldDefinitions: ctrl.highlightedFieldDefinitions,

        projectIntegration: null,
        reloadKeyMetricsPreview: false,

        definitionsToPreviewMap: {},
        highlightedDefinition: null,

        searchingInitiatives: false,
        errorSearchingInitiatives: null,

        loading: false,
        error: null,

        initiativeIdToFieldValuesMap: null,
        noInitiativesReturned: false,
        previewListTextSearch: null,
        maxFieldValueLength: {},
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        initializeDefinitionsToPreview();
        loadPreviewData();
    };

    /**
     * Occurs on changes to component bindings.
     */
    ctrl.$onChanges = function (changes) {
        if (changes.reloadPreview && changes.reloadPreview.currentValue) {
            $scope.reloadPreview(ctrl.softLoading);
        }

        if (changes.definitionsToPreview) {
            $scope.data.definitionsToPreview = ctrl.definitionsToPreview;
            initializeDefinitionsToPreview();
        }

        if (changes.globalFieldsToPreview) {
            $scope.data.globalFieldsToPreview = ctrl.globalFieldsToPreview;
        }

        if (changes.isBrokenIntegration) {
            $scope.data.isBrokenIntegration = ctrl.isBrokenIntegration;
        }

        if (changes.softLoading) {
            $scope.data.softLoading = ctrl.softLoading;
        }

        if (changes.filterInitiativesByTonkeanQuery) {
            $scope.data.filterInitiativesByTonkeanQuery = ctrl.filterInitiativesByTonkeanQuery;
        }

        if (changes.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.reloadPreview(true);
        }
    };

    /**
     * Reloads the preview data.
     */
    $scope.reloadPreview = function (softLoading) {
        loadPreviewData(softLoading);
    };

    /**
     * Searches initiatives in the list using the search text user inputted .
     */
    $scope.searchInitiatives = function () {
        $scope.data.searchingInitiatives = true;
        $scope.data.errorSearchingInitiatives = null;

        requestThrottler.do(
            'previewSearchInitiatives',
            200,
            () => getPreviewListPromise(),
            (data) => handlePreviewData(data),
            (error) => handlePreviewError(error),
            () => handlePreviewFinalCompletion(),
        );
    };

    /**
     * Opens the initiative view of the given initiative in a new tab.
     */
    $scope.openTrackViewInNewTab = function (initiativeId) {
        modal.openFieldInspectModal(
            $scope.data.projectIntegration,
            'TONKEAN',
            undefined,
            undefined,
            undefined,
            false,
            undefined,
            $scope.data.groupId,
            undefined,
            $scope.data.workflowVersionId,
            undefined,
            initiativeId,
            true,
        );
    };

    /**
     * Returns a mod class to make the column larger according to the maxFieldValueLength that was
     * calculated for the given field definition id.
     * This function is called from the $digest.
     */
    $scope.getColumnWidthClass = function (definitionId) {
        const definitionMaxLength = $scope.data.maxFieldValueLength[definitionId];
        const baseLength = 10;

        if (definitionMaxLength) {
            // Calculate the basic length.
            const length = definitionMaxLength - baseLength;

            if (length > 0) {
                // We only return an expansion class if the length is longer that the base length.
                // Calculate how many time we should expand the width.
                const growFactor = Math.ceil(length / (baseLength / 2));
                if (growFactor === 1) {
                    // Grow by 1 factor.
                    return 'mod-larger-1';
                } else {
                    // Grow by 2 factor (that's the maximum we allow).
                    return 'mod-larger-2';
                }
            }
        }

        return null;
    };

    /**
     * Initializes the definition to preview.
     */
    function initializeDefinitionsToPreview() {
        if (!$scope.data.definitionsToPreview || !$scope.data.definitionsToPreview.length) {
            $scope.data.definitionsToPreview = [];
        }

        $scope.data.definitionsToPreview = $scope.data.definitionsToPreview.filter((def) => def && def.definitionId);

        // Creating definitions map
        $scope.data.definitionsToPreviewMap = utils.createMapFromArray(
            $scope.data.definitionsToPreview,
            'definitionId',
        );

        if ($scope.data.definitionsToPreviewMap[$scope.data.highlightedDefinitionId]) {
            $scope.data.highlightedDefinition =
                $scope.data.definitionsToPreviewMap[$scope.data.highlightedDefinitionId];
        }

        $scope.data.highlightedFieldDefinitions = {};

        // Highlighting the existing field definition always.
        if ($scope.data.existingFieldDefinitionId) {
            $scope.data.highlightedFieldDefinitions[$scope.data.existingFieldDefinitionId] = true;
        }

        // Adding all the definitions to preview to the map of highlighted definitions.
        for (const definitionToPreview of $scope.data.definitionsToPreview) {
            $scope.data.highlightedFieldDefinitions[definitionToPreview.definitionId] = true;
        }

        $scope.data.projectIntegration = syncConfigCacheManager.getSyncConfig(ctrl.workflowVersionId)?.projectIntegration;
    }

    /**
     * Executes the preview request for the list.
     */
    function getPreviewListPromise() {
        // Going through the definitions we want to preview, and if their definition is not valid yet, we mark them as do not calculate preview.
        const definitionsToPreview = [];
        for (let i = 0; i < $scope.data.definitionsToPreview.length; i++) {
            const definition = $scope.data.definitionsToPreview[i];

            if (definition) {
                definition.doNotCalculatePreviewForDefinition = !definition.validDefinition;
                definitionsToPreview.push(definition);
            }
        }

        return tonkeanService.previewListFieldDefinitions(
            $scope.data.workflowVersionId,
            definitionsToPreview,
            $scope.data.previewListTextSearch,
            $scope.data.filterInitiativesByTonkeanQuery,
            0,
            consts.getScrollableInitiativesPageSize(),
        );
    }

    /**
     * Handles the data retrieved by the preview API request.
     */
    function handlePreviewData(data) {
        $scope.data.initiativeIdToFieldValuesMap = data.initiativeIdToFieldValuesMap;
        $scope.data.noInitiativesReturned = true;

        // Evaluating a boolean indicating if no initiatives returned
        for (const initiativeId in $scope.data.initiativeIdToFieldValuesMap) {
            if ($scope.data.initiativeIdToFieldValuesMap.hasOwnProperty(initiativeId)) {
                $scope.data.noInitiativesReturned = false;

                // Evaluating ranges and display values
                const definitionIdToValueObjectMap =
                    $scope.data.initiativeIdToFieldValuesMap[initiativeId].definitionIdToValueMap;
                /* jshint loopfunc:true */
                for (const definitionId in definitionIdToValueObjectMap) {
                    if (definitionIdToValueObjectMap.hasOwnProperty(definitionId)) {
                        $scope.data.maxFieldValueLength[definitionId] = 0;

                        const valuesArray = definitionIdToValueObjectMap[definitionId];
                        const definition = $scope.data.definitionsToPreviewMap[definitionId];
                        let displayValueLength = '';

                        if (valuesArray.length === 1) {
                            const valueObject = valuesArray[0];
                            valueObject.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                                definition.evaluatedDisplayType,
                                definition.displayFormat,
                                valueObject.value,
                                valueObject.dateValue,
                                valueObject.formattedValue,
                            );
                            displayValueLength = valueObject.displayValue ? valueObject.displayValue.length : 0;

                            if (valueObject.range) {
                                const appliedStyle = getFieldRangeAppliedStyle(
                                    definition.isImportant,
                                    valueObject.range.color,
                                );
                                valueObject.style = appliedStyle.style;
                            }
                        } else {
                            valuesArray[0].isMultiValuesField = true;
                            valuesArray[0].displayValue = valuesArray
                                .map((valueObject) =>
                                    fieldDisplay.evaluateFieldDisplayValue(
                                        definition.evaluatedDisplayType,
                                        definition.displayFormat,
                                        valueObject.value,
                                        valueObject.dateValue,
                                        valueObject.formattedValue,
                                    ),
                                )
                                .join(', ');

                            displayValueLength = valuesArray[0].displayValue ? valuesArray[0].displayValue.length : 0;
                        }

                        // Update the maximum display length if we've find a new winner.
                        if (displayValueLength > $scope.data.maxFieldValueLength[definitionId]) {
                            $scope.data.maxFieldValueLength[definitionId] = displayValueLength;
                        }
                    }
                }
            }
        }

        return $q.resolve(data);
    }

    /**
     * Handles the error block of the preview API request.
     */
    function handlePreviewError() {
        $scope.data.error = 'There was an error trying to preview list.';
    }

    /**
     * Handles the finally block of the preview API request.
     */
    function handlePreviewFinalCompletion() {
        $scope.data.loading = false;
        $scope.data.softLoading = false;
        $scope.data.searchingInitiatives = false;
    }

    /**
     * Loads the preview data from API.
     */
    function loadPreviewData(softLoading) {
        $scope.data.loading = true;
        $scope.data.softLoading = softLoading;
        $scope.data.error = null;

        requestThrottler.do(
            'previewListColumns',
            300,
            () => getPreviewListPromise(),
            (data) => handlePreviewData(data),
            (error) => handlePreviewError(error),
            () => handlePreviewFinalCompletion(),
        );
    }
}

export default angular.module('tonkean.app').controller('PreviewListColumnsCtrl', lateConstructController(PreviewListColumnsCtrl));
