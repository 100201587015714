import React from 'react';

import useCalculateDateTimeFromNow from './hooks/useCalculateRelativeDateTime';

import { Tooltip } from '@tonkean/infrastructure';

interface Props {
    dateTime: number | null;
    lastUpdatedTime: Date;
    label: string;
}

const ProjectIntegrationCollectGenericCollectTimeLabel: React.FC<Props> = ({ dateTime, lastUpdatedTime, label }) => {
    const { relativeDateTime, inThePast, fullDate } = useCalculateDateTimeFromNow(dateTime, [lastUpdatedTime]);

    return (
        <>
            {relativeDateTime ? (
                <div>
                    <span>
                        {label} {inThePast ? 'was ' : 'is '}
                    </span>
                    <Tooltip content={fullDate}>
                        <span>{relativeDateTime}</span>
                    </Tooltip>
                </div>
            ) : (
                `${label} is not available`
            )}
        </>
    );
};

export default ProjectIntegrationCollectGenericCollectTimeLabel;
