import { analyticsWrapper } from '@tonkean/analytics';
import { getCurrentUserProjectContext } from '@tonkean/tonkean-utils';

function LicenseManager(
    $rootScope,
    $state,
    $timeout,
    $q,
    projectManager,
    createProjectApis,
    authenticationService,
    tonkeanService,
    utils,
) {
    const _this = this;
    let watchTimeoutPromise = null;
    const watchInterval = 60_000;
    let userWasInPreviewMode = false;

    $rootScope.$on('projectSelected', watchFullUserPreview);

    _this.states = {
        requestForAccessSent: false,
        askingForAccess: false,
    };

    _this.goToBoard = function () {
        // If a request for full access was sent for this user, do nothing. The anchor/button element is disabled.
        if (!_this.states.requestForAccessSent) {
            _this.grabLicense().then(() => {
                if (projectManager.groupDefaultId) {
                    $state.go(
                        'product.board',
                        {
                            filter: 'all',
                            g: projectManager.groupDefaultId,
                            st: null,
                        },
                        { reload: true, location: 'replace' },
                    );
                } else {
                    location.href = '/';
                }
            });
        }
    };

    _this.grabLicense = function () {
        if (
            (projectManager.isLicensed || projectManager.project.isLimitedLicense || projectManager.isEnterprise) &&
            !projectManager.isUserLicensed
        ) {
            // If the user is not licensed to this licensed project, try to grab a seat.
            return _this.grabLicenseFromServer();
        }

        return $q.resolve();
    };

    _this.grabLicenseFromServer = function () {
        // Check if we can grab an available seat (a.k.a license) for this user.
        return createProjectApis.grabLicense(projectManager.project.id).then(function () {
            // Success! A seat was grabbed for this user. Update the user and send the user to the board.
            return tonkeanService.getCurrentUser().then(function (freshUser) {
                authenticationService.updateCurrentUser(freshUser);
            });
        });
    };

    /**
     * Tries to add a seat to a person. If fails, tries to ask the buyer for an additional seat.
     * @param personId - the person id to try to add seat for.
     */
    _this.tryToAddSeatToUser = function (personId) {
        analyticsWrapper.track('Add owner without seat', { category: 'License' });
        return tonkeanService
            .updatePersonLicense(projectManager.project.id, personId, true, true)
            .then((updatedPerson) => {
                // A seat was added.
                analyticsWrapper.track('Seat added', { category: 'License' });
                return $q.resolve(updatedPerson);
            })
            .catch(() => {
                // if update license failed, it might be because there is no open seat and you don't have access to add more.
                // in that case, we send a request for the buyer to do it
                analyticsWrapper.track('Seat requested', { category: 'License' });
                createProjectApis.requestFullAccess(projectManager.project.id, personId);

                // Reject to let the following promise know the original promise failed.
                return $q.reject();
            });
    };

    /**
     * Moves the project into a limited state (free license).
     */
    _this.setLimitedState = function () {
        const id = projectManager.project.id;
        return tonkeanService.setProjectLimitedLicense(id).then(function (data) {
            // update user metadata
            const user = authenticationService.currentUser;

            // If operation successful update our instance of the user project context to licensed
            if (data.success && user.projectContexts && user.projectContexts[id]) {
                user.projectContexts[id].isLicensed = true;
            }

            // Update the user's metadata.
            if (!user.metadata) {
                user.metadata = {};
            }
            if (!user.metadata.limitedMessageShown) {
                user.metadata.limitedMessageShown = {};
            }
            user.metadata.limitedMessageShown[id] = true;

            return tonkeanService.updateProfileMetadata(user.metadata).finally(() => {
                // Update the project in the pm.
                projectManager.project.isLimitedLicense = true;
                projectManager.project.isExpired = false;

                // Also update the project in the projects cache array.
                const projectFromCache = utils.findFirstById(projectManager.projects, id);
                if (projectFromCache) {
                    projectFromCache.isLimitedLicense = true;
                    projectFromCache.isExpired = false;
                }

                // Make sure all license properties are up to date.
                projectManager.recalculateProjectLicenseProperties(projectManager.project);
            });
        });
    };

    /**
     * "Watches" the user's full user preview mode (if the user is in preview mode to begin with)
     * in constant intervals, and updates the relevant flags according to the time left on preview.
     */
    function watchFullUserPreview() {
        // console.log('watchFullUserPreview + ' + new Date());

        if (projectManager) {
            // Ask the project manager for to refresh the full user preview state.
            projectManager.recalculateIsFullUserPreview();

            // If we are in full user preview mode, we should periodically check if this mode changes.
            // The interval checks stop once isFullUserPreview is no longer true.
            if (projectManager.isFullUserPreview) {
                // This flag helps us now later on if the user has finished the preview or was never in it.
                userWasInPreviewMode = true;

                // Change the the previewExpirationDate so the UI gets recalculated about the time left for the preview.
                const userProjectContext = getCurrentUserProjectContext(
                    authenticationService.currentUser,
                    projectManager.project,
                );
                if (userProjectContext && userProjectContext.previewExpirationDate) {
                    // To preserve the value, if the number is odd we decrease it by one and if even we add one.
                    userProjectContext.previewExpirationDate +=
                        Math.abs(userProjectContext.previewExpirationDate % 2) === 1 ? -1 : 1;
                }

                // Try to cancel the current timeout (if it exists) before setting a new timeout.
                if (watchTimeoutPromise) {
                    $timeout.cancel(watchTimeoutPromise);
                }

                watchTimeoutPromise = $timeout(watchFullUserPreview, watchInterval);
            } else {
                // Only do something if the user was in full user preview and finished it.
                if (userWasInPreviewMode && !projectManager.isUserLicensed) {
                    // Reset the flag.
                    userWasInPreviewMode = false;
                    // The user no longer has full user permissions. Sending him to the main page will cause a refresh to the whole system.
                    $state.go('product.board', { filter: 'all', g: projectManager.groupDefaultId }, { reload: true });
                }
            }
        }
    }

    function isFullUserPreviewAllowed() {
        // First, make sure the isFullUserPreview flag is updated.
        projectManager.recalculateIsFullUserPreview();

        // Get the user's project context safely.
        const userProjectContext = getCurrentUserProjectContext(
            authenticationService.currentUser,
            projectManager.project,
        );

        // A user is allowed to get into full user preview mode if he is not already a full user and not already in full user preview
        // and he doesn't have a preview expiration date set.
        return (
            !projectManager.isUserLicensed &&
            !projectManager.isFullUserPreview &&
            (!userProjectContext || !userProjectContext.previewExpirationDate)
        );
    }

    // Call this function at startup. Hopefully everything required will already be initialized.
    watchFullUserPreview();
}

angular.module('tonkean.app').service('licenseManager', LicenseManager);
