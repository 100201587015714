const getPersonInitials = (fullName: string): string => {
    const subNames = fullName.split(' ');
    if (subNames.length === 0) {
        return '';
    }
    let initials = subNames[0].charAt(0);
    if (subNames.length > 1) {
        initials += subNames[subNames.length - 1]?.charAt(0);
    }
    return initials.toUpperCase();
};
export default getPersonInitials;
