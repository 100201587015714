import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { LogicConfigurationTitle } from '@tonkean/logic-configuration';
import type { LogicConfigurationProps } from '@tonkean/logic-configuration';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const NoteText = styled.div`
    margin-top: 10px;
    font-size: ${FontSize.SMALL_12};
`;

const RequiredSign = styled.span`
    color: ${Theme.colors.error};
    margin-left: 3px;
`;

const RequiredAsterisk: React.FC = () => (
    <Tooltip content="Required Field">
        <RequiredSign>*</RequiredSign>
    </Tooltip>
);

const NetsuiteAddJournalEntryCustomActionConfiguration: React.FC<LogicConfigurationProps> = ({
    groupId,
    workflowVersionId,
    definition,
    previewEvaluationSource,
    configuredLogic,
    onDefinitionChanged,
}) => {
    const onJournalStatusChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.journalStatus = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    return (
        <>
            <LogicConfigurationTitle>
                Approve Journal
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.journalStatus?.originalExpression}
                savedEvaluatedExpression={definition.journalStatus?.evaluatedExpression}
                onTonkeanExpressionChanged={onJournalStatusChanged}
                placeholder="Journal Status"
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />
            <NoteText>Options: true, false</NoteText>
        </>
    );
};

export default NetsuiteAddJournalEntryCustomActionConfiguration;
