import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { RequiredIndication } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const PillRequiredIndication = styled.span<{ highlight: boolean }>`
    padding: 4px 10px;
    font-weight: 600;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${({ highlight }) => (highlight ? Theme.colors.error : Theme.colors.gray_500)};
    border-color: ${({ highlight }) => (highlight ? Theme.colors.error : Theme.colors.gray_500)};
    background-color: ${({ highlight }) => (highlight ? lighten(0.3, Theme.colors.error) : Theme.colors.gray_300)};
    border-width: 1px;
    border-style: solid;
    border-radius: 31px;

    transition-property: color, background-color, border-color;
    transition: 0.2s;
`;

const StarRequiredIndication = styled.div<{ starColor: string }>`
    color: ${({ starColor }) => (starColor === 'red' ? Theme.colors.error : Theme.colors.gray_500)};
`;

interface Props {
    type?: string;
}

const WidgetRequiredIndication: React.FC<Props> = ({ type = RequiredIndication.PILL.toString() }) => {
    return (
        <>
            {
                {
                    [RequiredIndication.RED_STAR]: <StarRequiredIndication starColor="red"> * </StarRequiredIndication>,
                    [RequiredIndication.GRAY_STAR]: (
                        <StarRequiredIndication starColor="gray"> * </StarRequiredIndication>
                    ),
                    [RequiredIndication.PILL]: <PillRequiredIndication highlight> Required </PillRequiredIndication>,
                    [RequiredIndication.NONE]: null,
                }[RequiredIndication[type] || RequiredIndication.PILL] // The PILL here is used for old widgets in order to keep what they had. New widgets that will be added the default will be RED_STAR on compo. predefined.
            }
        </>
    );
};
export default WidgetRequiredIndication;
