import React, { useCallback, useMemo, useState } from 'react';
import type { InputActionMeta } from 'react-select';

import TnkSelect from './TnkSelect';
import { useInnerField } from '../Field';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

interface Props {
    value?: string[];
    onChange?: (value: string[]) => void;
    name?: string;
}

const TagsInput: React.FC<Props & React.ComponentProps<typeof TnkSelect>> = ({
    value: valueProp,
    onChange: onChangeProp,
    readOnly = false,
    components: componentsProps,
    placeholder = '',
    name,
    ...props
}) => {
    const [inputValue, setInputValue] = useState('');

    const [fieldProps, hasError, formikHelpers] = useInnerField({
        name,
        type: 'select',
        multiple: true,
        value: valueProp,
    });

    const emitOnChange = useConstantRefCallback((newValue: string[]) => {
        onChangeProp?.(newValue);
        formikHelpers?.setValue(newValue);
    });

    /**
     * Adds the current inputValue to the current values and triggers onChange.
     *
     * @param alwaysClear - should clear the input value even if the inputValue was not added?
     */
    const addValue = useConstantRefCallback((alwaysClear: boolean = false) => {
        if (readOnly) {
            return;
        }

        const trimmedValue = inputValue.trim();
        const canAdd = trimmedValue.length && fieldProps.value?.every((item) => item !== trimmedValue);

        if (canAdd) {
            emitOnChange([...fieldProps.value, trimmedValue]);
        }
        if (alwaysClear || canAdd) {
            setInputValue('');
        }
    });

    // Because we set menuIsOpen={false}, the user has no way of adding items, so we will track enter or tab presses
    // and trigger onChange manually.
    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key !== 'Enter' && event.key !== 'Tab') {
                return;
            }

            event.preventDefault();
            addValue();
        },
        [addValue],
    );

    // If it's blur (unfocusing) we should add the item - the user might not know that it's a tags input and not
    // a regular input.
    const onInputChange = useCallback(
        (newInputValue: string, action: InputActionMeta) => {
            if (action.action === 'input-blur') {
                addValue(true);
            } else {
                setInputValue(newInputValue);
            }
        },
        [addValue],
    );

    const value = useMemo(() => {
        return fieldProps.value?.map((app) => ({ value: app, label: app }));
    }, [fieldProps.value]);

    const components = useMemo(
        () => ({ ClearIndicator: undefined, DropdownIndicator: undefined, ...componentsProps }),
        [componentsProps],
    );

    const onChange = useCallback(
        (values: { value: string }[]) => emitOnChange(values?.map((item) => item.value) || []),
        [emitOnChange],
    );

    return (
        <TnkSelect
            inputValue={inputValue}
            onInputChange={onInputChange}
            onKeyDown={onKeyDown}
            components={components}
            menuIsOpen={false}
            placeholder={placeholder}
            readOnly={readOnly}
            isCreatable
            isMulti
            {...props}
            inputId={fieldProps.id}
            onBlur={fieldProps.onBlur}
            value={value}
            onChange={onChange}
        />
    );
};

export default TagsInput;
