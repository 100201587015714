<div ng-controller="QuestionActivityController">
    <div ng-if="openQuestion && !questionIncomplete" class="question-box-content-question">
        <p class="question-box-content-title">
            <span ng-show="openQuestion.level === 0 && firstQuestionHeader" ng-bind="firstQuestionHeader.text"></span>
            <span ng-show="openQuestion.level === 0 && !firstQuestionHeader">
                Currently working on
                <span class="common-bold" ng-bind="pm.project.name"></span>
                , would you say:
            </span>
        </p>
        <p class="common-subtitle">Choose one of the following:</p>

        <p
            class="question-box-content-answer-row mod-arrow"
            ng-class="{ 'common-selectable': !postingAnswerId, 'common-selected': postingAnswerId === statement.id }"
            ng-repeat="statement in openQuestion.statements"
            ng-click="onAnswerClicked(statement)"
            analytics-on="click"
            analytics-category="Question"
            analytics-event="Open"
            analytics-label="Activity Answer Clicked"
            analytics-value="{{ $index }}"
        >
            <span class="question-box-content-answer-row-text" ng-bind="statement.text"></span>
            <i ng-if="postingAnswerId === statement.id" class="question-box-content-answer-row-loading"></i>
        </p>
    </div>
    <div ng-if="questionIncomplete" class="question-box-content-question">
        <div
            class="box-with-shadow mod-selectable-light padding-normal flex-vmiddle"
            ui-sref="question({projectId:pm.project.id, inquiry: openQuestion.inquiry.id})"
            analytics-on
            analytics-category="Question"
            analytics-event="Open"
            analytics-label="Incomplete question"
        >
            <span class="flex-grow">Continue answering and make a difference</span>
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>
</div>
