import React from 'react';
import styled from 'styled-components';

import FormulaNodePopoverTrigger from './FormulaNodePopoverTrigger';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';

import type { FormulaTreeConstNode } from '@tonkean/tonkean-entities';
import type { FormulaTreeEmptyNode } from '@tonkean/tonkean-entities';
import type { FormulaTreeVariableNode } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';

const EmptyOperand = styled(FormulaNodePopoverTrigger)`
    font-style: italic;
`;

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: FormulaTreeConstNode | FormulaTreeEmptyNode | FormulaTreeVariableNode;
}

const FormulaNodeOperand: React.FC<Props> = ({
    depth,
    canDelete,
    node,
    disabled,
    onNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
}) => {
    switch (node.type) {
        case FormulaTreeNodeType.CONST:
        case FormulaTreeNodeType.VARIABLE:
            return (
                <FormulaNodePopoverTrigger
                    content={node.toDisplayString()}
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );

        case FormulaTreeNodeType.EMPTY:
            return (
                <EmptyOperand
                    content={node.toDisplayString()}
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );
    }
};

export default FormulaNodeOperand;
