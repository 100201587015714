<div class="import-form-values-modal relative padding-normal" ng-init="init()">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title -->
    <div>
        <h1 class="common-size-l common-bold mod-no-top block">Import</h1>
    </div>

    <!-- Load form -->
    <div class="margin-normal-v ui-select-container flex flex-col">
        <!-- Import Type -->
        <label for="import-type-select-input">Import Type</label>
        <ui-select
            id="import-type-select-input"
            class="import-type-select-input margin-top-xs margin-bottom-xs"
            theme="bootstrap"
            ng-model="data.selectedImportType"
        >
            <ui-select-match placeholder="Choose an import type">{{ $select.selected.displayName }}</ui-select-match>
            <ui-select-choices
                repeat="selectedImportType.type as selectedImportType in data.actionToImportTypes[data.actionName] | filter:$select.search"
            >
                <div ng-bind="selectedImportType.displayName | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>

        <span ng-switch="data.selectedImportType.type" class="flex flex-col">
            <!-- cURL -->
            <span ng-switch-when="curl">
                <tnk-import-action-config-from-curl
                    on-successful-parse="onSuccessfulParse(parsedCurl)"
                    on-error="onError(errors)"
                ></tnk-import-action-config-from-curl>
            </span>

            <!-- Errors -->
            <div class="errors" ng-show="data.errors">
                <div class="error-text" ng-repeat="error in data.errors track by $index">{{ error }}</div>
            </div>

            <div class="flex-self-end inline-block">
                <!-- Import -->
                <button
                    type="button"
                    class="btn btn-primary margin-top-lg"
                    ng-disabled="utils.isEmpty(data.importObject) || data.errors.length > 0"
                    ng-click="import()"
                >
                    Import
                </button>
            </div>
        </span>
    </div>
</div>
