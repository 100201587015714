import template from './tnkWorkerTile.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerTile', {
    bindings: {
        worker: '<',
        indexInGallery: '<',
        viewOnly: '<',
        folderId: '<',
        highlightTerm: '<',
        onWorkerMoved: '<',
    },
    template,
    controller: 'WorkersTileCtrl',
});
