import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function HubspotCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        fieldDefinitionsToCreate: ctrl.fieldDefinitionsToCreate, // Definitions to create.
        validationObject: ctrl.validationObject,
        associatesOptionsMap: {
            CONTACT: { displayName: 'Contact', name: 'CONTACT' },
            COMPANY: { displayName: 'Company', name: 'COMPANY' },
        },
        associateOptions: [],
        associatesId: [],
        loading: false,
    };

    ctrl.$onInit = function () {
        $scope.data.associateOptions = [
            $scope.data.associatesOptionsMap.COMPANY,
            $scope.data.associatesOptionsMap.CONTACT,
        ];
        if (!$scope.data.definition.associatesId) {
            $scope.data.definition.associatesId = [];
        }

        if (!$scope.data.definition.selectedAssociateOption) {
            $scope.data.selectedAssociateOption = $scope.data.associateOptions[0];
            $scope.data.definition.selectedAssociateOption = $scope.data.selectedAssociateOption.name;
        } else {
            $scope.data.selectedAssociateOption =
                $scope.data.associatesOptionsMap[$scope.data.definition.selectedAssociateOption];
            $scope.data.associatesId = angular.copy($scope.data.definition.associatesId);
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic, index) {
        if ($scope.data.definition.associatesId) {
            $scope.data.definition.associatesId[index] = {
                evaluatedText: evaluatedExpression,
                text: originalExpression,
            };
        }

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.selectAssociateOption = function (associateOption) {
        $scope.data.selectedAssociateOption = associateOption;
        $scope.data.definition.selectedAssociateOption = $scope.data.selectedAssociateOption.name;
    };

    $scope.addAssociateId = function () {
        $scope.data.associatesId.push({ evaluatedText: '', text: '' });
    };

    $scope.removeAssociateId = function (index) {
        $scope.data.associatesId.splice(index, 1);
        $scope.data.definition.associatesId.splice(index, 1);
    };
}

export default angular
    .module('tonkean.app')
    .controller('HubspotCustomActionsDefinitionCtrl', lateConstructController(HubspotCustomActionsDefinitionCtrl));
