import { lateConstructController } from '@tonkean/angular-components';
import { FIELD_TYPES } from '@tonkean/constants';

/* @ngInject */
function ManualFieldCtrl($scope, customFieldsManager, utils, $rootScope) {
    const ctrl = this;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,

        existingFieldDefinition: ctrl.existingFieldDefinition,

        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        typeApiNameToDisplayNameMap: {},
        typeDisplayNameToApiName: {},

        selectedType: 'Text',
        isMultiValueField: false,
        inputMultiValueSeparator: ',',

        picklistValues: [],

        manualDatePickerIsOpen: false,

        manualValue: ctrl.manualValue,
        suggestedValue: ctrl.suggestedValue,

        fieldTypeToPreviousValue: {},
        featureDefaultFieldSource: $rootScope.features.currentProject?.tonkean_feature_default_field_source,
        featureUrlFieldEnabled: $rootScope.features.currentProject?.tonkean_feature_pretty_url_field_view,
        // Buttons config for the choosing options of dropdown configuration
        dropdownSourceCategoryButtons: [
            {
                value: 'MANUAL',
                label: 'Manual',
            },
            {
                value: 'FIELD',
                label: 'From a Field',
            },
            {
                value: 'SEARCH',
                label: 'From a Matched Entity',
                tooltip: 'Supported only in web',
            },
        ],
        fromFieldMetadataSyncDropdownSourceButtons: [
            {
                value: 'FROM_FIELD',
                label: 'Field Values',
            },
            {
                value: 'SYNC_FIELD',
                label: 'Field Options',
            },
        ],
        allowAddingOptionsButtons: [
            {
                value: false,
                label: 'No',
            },
            {
                value: true,
                label: 'Yes',
            },
        ],
        fieldValueTypeButtons: [
            {
                value: false,
                label: 'Single',
            },
            {
                value: true,
                label: 'Multiple',
            },
        ],
        showManualOptionsNoResultsButtons: [
            {
                value: false,
                label: 'No',
            },
            {
                value: true,
                label: 'Yes',
            },
        ],
        urlLabel: null,
    };

    initDropdownOptions();

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        // Initialize api name to display name map
        const allFieldTypes = FIELD_TYPES;

        for (const property in allFieldTypes) {
            if (allFieldTypes.hasOwnProperty(property)) {
                $scope.data.typeApiNameToDisplayNameMap[allFieldTypes[property].apiName] =
                    allFieldTypes[property].displayName;
                $scope.data.typeDisplayNameToApiName[allFieldTypes[property].displayName] =
                    allFieldTypes[property].apiName;
            }
        }

        if (!$scope.data.createMode) {
            initializeEditMode();
        }
        else{
            $scope.data.showManualOptionsNoResults = false;
        }

        $scope.definitionChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        const changedEnvironment = changesObj.workflowVersionId && !changesObj.workflowVersionId.isFirstChange();

        if (changedEnvironment) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.data.existingFieldDefinition = changesObj.existingFieldDefinition.currentValue;

            initDropdownOptions();
            initializeEditMode();
            $scope.definitionChanged();
        }
    };

    /**
     * Updates the setupFieldSelectedType with the selected type from the drop down.
     */
    $scope.selectType = function (fieldType) {
        $scope.data.selectedType = fieldType;
        ctrl.onChange();
        resetManualValue();
        $scope.definitionChanged();
    };

    /**
     * Occurs once the manual value is changed.
     */
    $scope.onManualValueChanged = function () {
        $scope.data.fieldTypeToPreviousValue[$scope.data.selectedType] = $scope.data.manualValue;
        $scope.definitionChanged();
    };

    /**
     * Occurs on every keydown in the value input text box.
     */
    $scope.onValueInputKeyDown = function (event) {
        if (event && (event.code === 'Enter' || event.keyCode === 13)) {
            event.preventDefault();
        }
    };

    /**
     * Resets the manualValue mapped by the selected type.
     * if it has previous value changes to that.
     */
    function resetManualValue() {
        $scope.data.manualValue = $scope.data.fieldTypeToPreviousValue[$scope.data.selectedType] || '';
    }

    /**
     * Occurs once the definition changes.
     */
    $scope.definitionChanged = function () {
        const fieldType = $scope.data.typeDisplayNameToApiName[$scope.data.selectedType];
        let displayAs = 'String';
        let displayAsDisplayName = 'String';
        let evaluatedDisplayType = 'String';

        switch (fieldType.toLowerCase()) {
            case 'longstring':
                displayAs = 'LongString';
                displayAsDisplayName = 'Long Text';
                evaluatedDisplayType = 'LongString';
                break;
            case 'number':
                displayAs = 'Number';
                evaluatedDisplayType = 'Number';
                break;
            case 'date':
                displayAs = 'Date';
                evaluatedDisplayType = 'Date';
                break;
            case 'dropdown':
            case 'list':
                displayAs = 'LongString';
                evaluatedDisplayType = 'List';
                break;
            case 'url':
                displayAs = 'URL';
                evaluatedDisplayType = 'URL';
                break;
        }

        let customInputMultiValueSeparator = null;
        if ($scope.data.selectedType === 'Dropdown' && $scope.data.dropdownSource === 'MANUAL') {
            customInputMultiValueSeparator = "[TNK_SPECIAL_CHAR]";
        } else if(['Text', 'Long Text', 'Dropdown'].includes($scope.data.selectedType)) {
            customInputMultiValueSeparator = $scope.data.inputMultiValueSeparator
        }

        const definitionObject = {
            fieldConfigurationSummaryTitle: `Manual ${displayAsDisplayName ? displayAsDisplayName : displayAs}`,
            fieldConfigurationSummarySubTitle: null,
            validDefinition: isDefinitionValid(),
            updateable: true,
            possibleValues: $scope.data.picklistValues,
            fieldType,
            isMultiValueField: ['Text', 'Long Text', 'Dropdown'].includes($scope.data.selectedType)
                ? $scope.data.isMultiValueField
                : false,
            inputMultiValueSeparator: customInputMultiValueSeparator,
            outputMultiValueSeparator: customInputMultiValueSeparator,
            displayAs,
            evaluatedDisplayType,
            manualValue: $scope.data.manualValue,
            dropdownSource: $scope.data.dropdownSource,
            suggestedValue: $scope.data.suggestedValue,
            allowAddDropdownOptions: $scope.data.allowAddDropdownOptions,
            dropdownOptionsFromFieldDefinitionId: $scope.data.dropdownOptionsFromFieldDefinitionId,
            defaultValueFieldDefinitionId: $scope.data.defaultValueFieldDefinitionId,
            dropdownOptionsFromFieldMetadataSyncFieldDefinition:
                $scope.data.dropdownOptionsFromFieldMetadataSyncFieldDefinition?.definition?.fieldMetadata,

            skipStepsMap: {
                integrationConfiguration: true,
                fieldDefinitionTypeConfiguration: true,
            },
            showManualOptionsNoResults: $scope.data.showManualOptionsNoResults,
            urlLabel: $scope.data.urlLabel
        };

        if (ctrl.onDefinitionChange) {
            // Dont reload preview if we are creating new field
            const doNotReloadPreview = !$scope.data.existingFieldDefinition;
            ctrl.onDefinitionChange({ newDefinition: definitionObject, doNotReloadPreview });
        }
    };

    $scope.onPicklistValuesChanged = function (values) {
        $scope.data.picklistValues = values;
        $scope.definitionChanged();
    };

    $scope.showManualOptionsNoResultsChanged = function (shouldShow) {
        if(shouldShow !== $scope.data.showManualOptionsNoResults){
            $scope.data.showManualOptionsNoResults = shouldShow;
            ctrl.onChange();
            $scope.definitionChanged();
        }
    }

    $scope.selectFieldAllowAddingOptions = function (isAllowed) {
        if (isAllowed !== $scope.data.allowAddDropdownOptions) {
            $scope.data.allowAddDropdownOptions = isAllowed;
            ctrl.onChange();
            $scope.definitionChanged();
        }
    };

    $scope.selectFieldDropdownSourceCategory = function (inputSource) {
        if ($scope.data.dropdownSourceCategory === inputSource) {
            return;
        }

        $scope.data.dropdownSourceCategory = inputSource;

        // If we choose a field options source, init it to default if a value is not present
        if ($scope.data.dropdownSourceCategory === 'FIELD' && $scope.data.dropdownSource) {
            $scope.data.dropdownSource = $scope.data.fromFieldMetadataSyncDropdownSourceButtons[0]?.value;
        }

        // If we choose manual category, we have only one options - manual
        if ($scope.data.dropdownSourceCategory === 'MANUAL') {
            $scope.data.dropdownSource = 'MANUAL';
        }

        if ($scope.data.dropdownSourceCategory === 'SEARCH') {
            $scope.data.dropdownSource = 'SEARCH';
        }

        ctrl.onChange();
        $scope.definitionChanged();
    };

    $scope.selectFieldDropdownSource = function (inputSource) {
        if ($scope.data.dropdownSource !== inputSource) {
            $scope.data.dropdownSource = inputSource;
            ctrl.onChange();
            $scope.definitionChanged();
        }
    };

    $scope.selectDropdownFieldSource = function (selectedFieldId, dontSaveChanges) {
        $scope.data.dropdownOptionsFromFieldDefinitionId = selectedFieldId;
        $scope.data.selectedDropdownOptionsFromFieldDefinitionId = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            selectedFieldId,
        );
        if (!dontSaveChanges) {
            ctrl.onChange();
        }
        $scope.definitionChanged();
    };

    $scope.selectDropdownFieldSyncSource = function (selectedField, dontSaveChanges) {
        $scope.data.dropdownOptionsFromFieldMetadataSyncFieldDefinition = selectedField;
        if (!dontSaveChanges) {
            ctrl.onChange();
        }
        $scope.definitionChanged();
    };



    $scope.selectDefaultValueFieldDefinitionId = function (selectedFieldId, dontSaveChanges) {
        $scope.data.defaultValueFieldDefinitionId = selectedFieldId;
        $scope.data.defaultValueFieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            selectedFieldId,
        );
        if (!dontSaveChanges) {
            ctrl.onChange();
        }
        $scope.definitionChanged();
    };

    $scope.filterCurrentFields = function (fields) {
        return fields.filter((field) => field.id !== $scope.data.existingFieldDefinition?.id && !field.isSpecialField);
    };

    $scope.filterMatchedEntitiesRelatedFields = function (fields) {
        return fields.filter(
            (field) =>
                field.id !== $scope.data.existingFieldDefinition?.id &&
                !field.isSpecialField &&
                !field.idRelationField &&
                field.type === 'EXTERNAL',
        );
    };

    $scope.selectFieldValueType = function (valueType) {
        const isMultiValueField = valueType === 'Multiple';

        if ($scope.data.isMultiValueField !== isMultiValueField) {
            ctrl.onChange();
            $scope.data.isMultiValueField = isMultiValueField;
            $scope.definitionChanged();
        }
    };

    $scope.separatorChange = function () {
        ctrl.onChange();
        $scope.definitionChanged();
    };

    $scope.urlLabelChange = function () {
        ctrl.onChange();
        $scope.definitionChanged();
    }

    $scope.suggestedValueChanged = function () {
        ctrl.onChange();
        $scope.definitionChanged();
    };

    $scope.getButtonByValue = function (buttonsArray, value) {
        return buttonsArray.find((button) => button.value === value);
    };

    $scope.onChange = function () {
        ctrl.onChange();
    };

    $scope.selectShowManualOptionsNoResults = function (value) {
            $scope.data.showManualOptionsNoResults = value;
            $scope.definitionChanged();
    };

    function initializeEditMode() {
        $scope.data.selectedType =
            $scope.data.typeApiNameToDisplayNameMap[$scope.data.existingFieldDefinition.fieldType];
        $scope.data.isMultiValueField =
            $scope.data.existingFieldDefinition.isMultiValueField ?? $scope.data.isMultiValueField; // In case it's a old field without this param
        $scope.data.inputMultiValueSeparator =
            $scope.data.existingFieldDefinition.inputMultiValueSeparator ?? $scope.data.inputMultiValueSeparator; // In case it's a old field without this param
        $scope.data.suggestedValue = $scope.data.existingFieldDefinition.suggestedValue;
        $scope.data.showManualOptionsNoResults = $scope.data.existingFieldDefinition.showManualOptionsNoResults;

        setDropdownOptionsFromFieldDefinitionId();
        setDefaultValueFieldDefinitionId();
        setDropdownOptionsFromFieldMetadataSyncFieldDefinition();

        $scope.data.urlLabel = $scope.data.existingFieldDefinition.urlLabel;
    }

    function isDefinitionValid() {
        // If you select a dropdown with a field source, you must select a field
        if (
            $scope.data.selectedType === 'Dropdown' &&
            ($scope.data.dropdownSource === 'FROM_FIELD' || $scope.data.dropdownSource === 'SEARCH') &&
            !$scope.data.dropdownOptionsFromFieldDefinitionId
        ) {
            return false;
        }

        // If you select a dropdown with a sync field source, you must select a field from a project integration
        if (
            $scope.data.selectedType === 'Dropdown' &&
            $scope.data.dropdownSource === 'SYNC_FIELD' &&
            !$scope.data.dropdownOptionsFromFieldMetadataSyncFieldDefinition?.definition?.fieldMetadata?.values?.length
        ) {
            return false;
        }

        // If you select a dropdown with a manual source, you must enter at least one option
        if (
            $scope.data.selectedType === 'Dropdown' &&
            $scope.data.dropdownSource === 'MANUAL' &&
            $scope.data.picklistValues.length === 0
        ) {
            return false;
        }

        return (
            (!$scope.data.createMode && !$scope.data.duplicateMode) ||
            $scope.data.targetType !== 'GLOBAL' ||
            $scope.data.manualValue
        );
    }

    function setDropdownOptionsFromFieldMetadataSyncFieldDefinition() {
        if (
            utils.isNotNullOrUndefined(
                ctrl.existingFieldDefinition?.dropdownOptionsFromFieldMetadataSyncFieldDefinition,
            )
        ) {
            $scope.data.dropdownOptionsFromFieldMetadataSyncFieldDefinition = JSON.parse(
                ctrl.existingFieldDefinition?.dropdownOptionsFromFieldMetadataSyncFieldDefinition,
            );
        }
    }

    function setDefaultValueFieldDefinitionId() {
        if (ctrl.existingFieldDefinition?.defaultValueFieldDefinitionId) {
            $scope.data.defaultValueFieldDefinitionId =
                ctrl.existingFieldDefinition.defaultValueFieldDefinitionId;

            $scope.data.defaultValueFieldDefinition =
                customFieldsManager.getFieldDefinitionFromCachesById(
                    $scope.data.workflowVersionId,
                    $scope.data.defaultValueFieldDefinitionId,
                );
        }
    }

    function setDropdownOptionsFromFieldDefinitionId() {
        if (ctrl.existingFieldDefinition?.dropdownOptionsFromFieldDefinitionId) {
            $scope.data.dropdownOptionsFromFieldDefinitionId =
                ctrl.existingFieldDefinition.dropdownOptionsFromFieldDefinitionId;

            $scope.data.selectedDropdownOptionsFromFieldDefinitionId =
                customFieldsManager.getFieldDefinitionFromCachesById(
                    $scope.data.workflowVersionId,
                    $scope.data.dropdownOptionsFromFieldDefinitionId,
                );
        }
    }

    function initDropdownOptions() {
        $scope.data.dropdownSource = ctrl.existingFieldDefinition?.dropdownSource || 'MANUAL';

        if (!ctrl.existingFieldDefinition?.dropdownSource) {
            $scope.data.dropdownSourceCategory = 'MANUAL';
        } else {
            if (ctrl.existingFieldDefinition?.dropdownSource === 'MANUAL') {
                $scope.data.dropdownSourceCategory = 'MANUAL';
            } else if (ctrl.existingFieldDefinition?.dropdownSource === 'SEARCH') {
                $scope.data.dropdownSourceCategory = 'SEARCH';
            } else {
                $scope.data.dropdownSourceCategory = 'FIELD';
            }
        }

        $scope.data.allowAddDropdownOptions = ctrl.existingFieldDefinition?.allowAddDropdownOptions || false;
        $scope.data.dropdownOptionsFromFieldDefinitionId =
            ctrl.existingFieldDefinition?.dropdownOptionsFromFieldDefinitionId;
        $scope.data.showManualOptionsNoResults = ctrl.existingFieldDefinition?.showManualOptionsNoResults
    }
}

export default angular.module('tonkean.app').controller('ManualFieldCtrl', lateConstructController(ManualFieldCtrl));
