import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';

enum AuditLogType {
    USER_ACTION = 'USER_ACTION',
    SYSTEM = 'SYSTEM',
}

const auditLogsTitles = {
    [AuditLogType.USER_ACTION]: {
        singular: 'Audit Log',
        plural: 'Audit Logs',
    },
    [AuditLogType.SYSTEM]: {
        singular: 'Collection Log',
        plural: 'Collection Logs',
    },
};

export const getAuditLogTypeTitle = (logType: AuditLogType) => {
    return auditLogsTitles[logType].plural;
};

export const getUserActionAuditLogDescription = (enterpriseComponentType: EnterpriseComponentType) => {
    return `View all changes made to the ${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}. Additionally, export the audit log as a CSV.`;
};

export default AuditLogType;
