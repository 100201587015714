import tnkAddFunctionPopoverTemplate from './tnkAddFunctionPopover.template.html?angularjs';

function tnkAddFunctionPopover() {
    return {
        restrict: 'E',
        scope: {
            item: '=initiative',
            onSave: '&',
            onClose: '&',
            isOpen: '=',
            placement: '@',
            displayInPlace: '=',
            disableInvite: '<',
            solutionBusinessReportId: '<',
            isInSharedMode: '<',
        },
        transclude: true,
        replace: true,
        template: tnkAddFunctionPopoverTemplate,
        controller: 'AddFunctionPopoverCtrl',
    };
}
angular.module('tonkean.app').directive('tnkAddFunctionPopover', tnkAddFunctionPopover);

function AddFunctionPopoverCtrl(
    $scope,
    $rootScope,
    authenticationService,
    trackHelper,
    entityInitiativeHelper,
    tonkeanService,
    viewingGroupUpdateService,
    projectManager,
    modal,
    currentUserService,
    groupPermissions,
    licensePermissionsService,
) {
    $scope.as = authenticationService;
    $scope.pm = $rootScope.pm;
    $scope.lps = licensePermissionsService;
    $scope.currentUserService = currentUserService;

    $scope.popover = {};
    $scope.data = {
        customMessage: null,
    };

    // need to set this to an object it needs to be the same reference for the things we pass it into
    $scope.manualNextUpdate = {};

    $scope.init = function () {
        $scope.popover.selectedPeople = [];
        $scope.popover.functionToAdd = [];
        $scope.popover.functionMap = {};
        $scope.disableInvite = $scope.disableInvite || projectManager.project.systemUsersSource !== 'MANUAL';

        $scope.estimated = undefined;
        $scope.ownerInfoPopoverState = {
            isOpen: false,
            appear: true,
            hasBeenShown: false,
        };
    };

    $scope.init();

    $scope.$watch('item.id', function () {
        $scope.init();
        setSelectedOption();
    });
    $scope.$watch('item.updated', setSelectedOption);
    $scope.$watch('isOpen', function (value) {
        if (value) {
            $scope.pm.getFunctions();

            // when this is open, open the ownerinfo popover with a delay and animation
            // if it is the first time and we are onboarding and didnt add owners
            // if (!$scope.ownerInfoPopoverState.hasBeenShown && $scope.as.currentUser.metadata && $scope.as.currentUser.metadata.continuousOnBoarding) {
            //     let step = $scope.as.currentUser.metadata.continuousOnBoarding.steps['assignOwner'];
            //     if (!step || !step.completed ) {
            //         $scope.ownerInfoPopoverState.hasBeenShown = true;
            //         $scope.ownerInfoPopoverState.isOpen = true;
            //         $timeout(function() {
            //             $scope.ownerInfoPopoverState.appear = true;
            //         }, 500);
            //     }
            // }
        }
        $scope.popover.isOpen = value;
        setSelectedOption();
    });
    $scope.$watchCollection('popover.selectedPeople', onSelectedPeopleChanged);

    /**
     * Sets the owner to current user.
     */
    $scope.assignToMe = function (event) {
        event.stopPropagation();

        if (!$scope.popover.selectedPeople) {
            $scope.popover.selectedPeople = [];
        }

        $scope.popover.selectedPeople.push(authenticationService.currentUser);
    };

    $scope.save = function () {
        $scope.initiative = $scope.item;
        $scope.popover.posting = true;

        if (!$scope.popover.functionToAdd.length && $scope.popover.latestFunc && $scope.popover.latestFunc.length) {
            $scope.popover.functionToAdd.push({ name: $scope.popover.latestFunc });
        }

        const func = $scope.popover.functionToAdd.length ? $scope.popover.functionToAdd[0] : null;
        const owner = $scope.popover.selectedPeople.length ? $scope.popover.selectedPeople[0] : null;

        $scope.isDifferentFunc =
            (func && !$scope.initiative.function) ||
            (!func && $scope.initiative.function) ||
            (func && $scope.initiative.function && func.id !== $scope.initiative.function.id);
        $scope.isDifferentOwner =
            (owner && !$scope.initiative.owner) ||
            (!owner && $scope.initiative.owner) ||
            (owner && $scope.initiative.owner && owner.id !== $scope.initiative.owner.id);

        const group =
            $scope.initiative && $scope.initiative.group ? projectManager.groupsMap[$scope.initiative.group.id] : null;
        // check if the list is private & if the new owner have access to it.
        if (
            group &&
            group.visibilityType === 'PRIVATE' &&
            !group.visibleToOwner &&
            $scope.popover.selectedPeople &&
            $scope.popover.selectedPeople.length
        ) {
            const person = $scope.popover.selectedPeople[0];
            let hasAccess;
            for (let i = 0; i < group.members.length; i++) {
                if (group.members[i].email === person.email) {
                    hasAccess = true;
                    break;
                }
            }
            if (!hasAccess) {
                askToOpenInvite(person);
                $scope.popover.posting = false;
                return;
            }
        }

        if ($scope.isDifferentFunc) {
            trackHelper.setFunction($scope.initiative, func).then(function (data) {
                if (!owner && func && func.defaultOwner) {
                    trackHelper.setOwner(
                        $scope.initiative,
                        [func.defaultOwner],
                        $scope.data.customMessage,
                        $scope.isInSharedMode,
                        $scope.solutionBusinessReportId,
                    );
                    // if no people selected, but there is a default to the function - don't override it
                    saveGatherUpdate(data);
                } else if ($scope.isDifferentOwner) {
                    trackHelper
                        .setOwner(
                            $scope.initiative,
                            $scope.popover.selectedPeople,
                            $scope.data.customMessage,
                            $scope.isInSharedMode,
                            $scope.solutionBusinessReportId,
                        )
                        .then(saveGatherUpdate);
                } else {
                    saveGatherUpdate(data);
                }
            });
        } else if ($scope.isDifferentOwner) {
            trackHelper
                .setOwner(
                    $scope.initiative,
                    $scope.popover.selectedPeople,
                    $scope.data.customMessage,
                    $scope.isInSharedMode,
                    $scope.solutionBusinessReportId,
                )
                .then(saveGatherUpdate);
        } else {
            saveGatherUpdate();
        }
    };

    $scope.close = function () {
        $scope.popover.isOpen = false;
        // clean the manual date so if the user opens the modal again he wont see old settings
        $scope.manualNextUpdate = {};

        if ($scope.onClose) {
            $scope.onClose();
        }
    };

    $scope.onTagRemoved = function () {
        // when removing the function, clearing the owner too
        if ($scope.popover.functionToAdd.length === 0) {
            $scope.popover.selectedPeople.length = 0;
        }
    };

    $scope.reminderOptionChanged = function () {
        $scope.showEstimated = false;
    };

    $scope.updateInitiativeData = function (data) {
        if (data) {
            $scope.initiative.owner = data.owner;
            $scope.initiative.function = data.function;
            $scope.initiative.metadata = data.metadata;
            $scope.initiative.nextGatherUpdate = data.nextGatherUpdate;
            entityInitiativeHelper.enrichInitiative($scope.initiative);
            setSelectedOption();

            if ($scope.isDifferentFunc && data.function) {
                $scope.$root.pm.getFunctions(true);
            }
            if ($scope.isDifferentOwner && data.owner) {
                $scope.$root.pm.getTempUsers(true);
            }
        }

        if ($scope.onSave) {
            $scope.onSave({ data: $scope.initiative });
        }

        // $scope.initiative.functions = data.functions;
        $scope.popover.isOpen = false;
        $scope.popover.posting = false;
        if ($scope.onClose) {
            $scope.onClose();
        }
    };

    $scope.openCustomReminderModal = function () {
        $scope.initiativeClicked = $scope.initiative;
        // make a new object so if we hit cancel it will not save changes
        if (!$scope.initiative.owner || $scope.manualNextUpdate.date) {
            $scope.customNextUpdate = {};

            if ($scope.manualNextUpdate.date) {
                $scope.customNextUpdate.date = $scope.manualNextUpdate.date;
            }
        }
        modal.openInitiativeSettingsModal($scope.initiative, true, $scope.customNextUpdate).result.finally(function () {
            if ($scope.customNextUpdate && $scope.customNextUpdate.date) {
                $scope.showEstimated = false;
                $scope.manualNextUpdate.date = $scope.customNextUpdate.date;
            }
        });
    };

    $scope.onOwnerInfoClick = function () {
        // if ($scope.ownerInfoPopoverState.isOpen === false) {
        //     $scope.ownerInfoPopoverState.hasBeenShown = false;
        // }

        $scope.ownerInfoPopoverState.isOpen = !$scope.ownerInfoPopoverState.isOpen;
    };

    $scope.provideOwnerName = function () {
        if ($scope.popover.selectedPeople[0]) {
            return $scope.popover.selectedPeople[0].name;
        }
    };

    $scope.openInviteModal = function () {
        // Only do something if people have been selected (should not happen, but making sure).
        if ($scope.popover.selectedPeople && $scope.popover.selectedPeople.length) {
            // Open invite modal with the currently selected person in "send intro" mode.
            modal
                .openInvite($scope.popover.selectedPeople[0], null, true)
                .then(function () {
                    // Invites sent.
                })
                .catch(function () {
                    // Modal dismissed.
                });
        }
    };

    function onSelectedPeopleChanged(newSelectedPeople) {
        if (newSelectedPeople.length > 0) {
            const newOwner = newSelectedPeople[0];
            if (!$scope.initiative || ($scope.initiative.owner && newOwner.id === $scope.initiative.owner.id)) {
                $scope.showEstimated = false;
                return;
            }

            const idForEstimation = newOwner.id || $scope.as.currentUser.id;
            $scope.showEstimated = true;
            $scope.loadingEstimation = true;
            tonkeanService
                .estimateNextReminder($scope.initiative.id, idForEstimation)
                .then(function (data) {
                    $scope.estimated = data.nextReminder;
                })
                .finally(function () {
                    $scope.loadingEstimation = false;
                });
        }
    }

    function saveGatherUpdate(data) {
        if ($scope.manualNextUpdate.date) {
            trackHelper
                .updateNextReminderTime($scope.initiative, new Date($scope.manualNextUpdate.date).getTime())
                .then(function () {
                    $scope.updateInitiativeData(data);
                });
        } else {
            $scope.updateInitiativeData(data);
        }
    }

    function setSelectedOption() {
        if ($scope.item) {
            $scope.initiative = $rootScope.trackHelper.getInitiativeFromCache($scope.item.id);
        }

        // Only set the group if we have a group and it's not set as visibleToOwner (which means the user can set owners from outside the group).
        // Not setting a group won't cause private group validations.
        $scope.group =
            $scope.initiative && $scope.initiative.group && !$scope.initiative.group.visibleToOwner
                ? $scope.initiative.group
                : null;

        if ($scope.initiative && $scope.initiative.function && !$scope.popover.functionToAdd.length) {
            $scope.popover.functionToAdd.push($scope.initiative.function);
        }

        if ($scope.initiative && $scope.initiative.owner && !$scope.popover.selectedPeople.length) {
            $scope.popover.selectedPeople.push($scope.initiative.owner);
        }

        onSelectedPeopleChanged($scope.popover.selectedPeople);
    }

    function askToOpenInvite(person) {
        modal
            .openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Invite to private list',
                            body: `${$scope.initiative.group.name} is a private list, and ${person.email} is not a member of this list. To set them as an owner you need to invite them to the list, or change the list settings to allow setting outside owners.`,
                            okLabel: 'Invite to list',
                            cancelLabel: 'Cancel',
                        };
                    },
                },
            })
            .result.then(function () {
                // okLabel clicked.
                let initialGroupId = null;
                const group = projectManager.groupsMap[$scope.initiative.group.id];
                // Only if private list the user is a project owner, or the creator of the list, we populate the invite modal with the list the user opened the invite from.
                if (
                    group.visibilityType === 'PRIVATE' &&
                    (projectManager.isOwner || groupPermissions.currentUserIsOwnerMap[group.id])
                ) {
                    initialGroupId = group.id;
                }

                modal.openInvite([person], null, null, initialGroupId ? [initialGroupId] : null, () =>
                    viewingGroupUpdateService.updateUserViewingGroupApiCall(group.id),
                );
            });
    }
}

angular.module('tonkean.app').controller('AddFunctionPopoverCtrl', AddFunctionPopoverCtrl);
