import template from './manualField.template.html?angularjs';

/**
 * Definition of a manual field.
 */
export default angular.module('tonkean.app').component('tnkManualField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        workflowVersionId: '@',
        targetType: '<',
        manualValue: '@',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',
        onChange: '&',
    },
    template,
    controller: 'ManualFieldCtrl',
});
