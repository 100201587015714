function ViewOnlyCtrl(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $rootScope,
    $localStorage,
    $http,
    safeDomEvents,
    entityHelper,
    projectManager,
    utils,
    consts,
    authenticationService,
    trackHelper,
    tonkeanService,
    viewingGroupUpdateService,
    customFieldsManager,
    groupInfoManager,
    workflowVersionManager,
) {
    $scope.wvm = workflowVersionManager;
    $scope.cfm = customFieldsManager;
    $scope.token = $stateParams.token;
    $scope.botStates = consts.getBotStates();

    $scope.cfm = customFieldsManager;

    $scope.data = {
        initiatives: [],
        loadingInitiatives: true,
        isManageTriggersOpen: false,
    };

    $scope.init = function () {
        // Setting an unique id for the anonymous user.
        if (!$localStorage.anonymousUserUniqueId) {
            $localStorage.anonymousUserUniqueId = utils.guid();
        }

        // Starting interval actions for updating user viewing group.
        viewingGroupUpdateService.registerIntervalViewingGroupUpdate(
            $scope.token,
            true,
            $localStorage.anonymousUserUniqueId,
            consts.getUpdateViewingGroupInterval(),
        );

        $scope.loading = true;
        tonkeanService
            .getGroupByGuestToken($scope.token, $localStorage.anonymousUserUniqueId)
            .then(function (data) {
                if (!authenticationService.isUserAuthenticated()) {
                    // set the guest user
                    authenticationService.setGuestUser($scope.token);
                }

                const originalGroupId = data.id;

                $scope.data.group = data;
                $scope.data.group.id = `guest_${$scope.token}`;
                $scope.data.group.viewOnlyMode = true;

                $scope.data.workflowVersionId = workflowVersionManager.getPublishedVersionFromCache(
                    $scope.data.group.id,
                ).id;

                // prepare fields
                const definitionMap = {};
                const workflowVersionId = workflowVersionManager.getPublishedVersionFromCache($scope.data.group.id).id;
                customFieldsManager.selectedGlobalFieldsMap[workflowVersionId].forEach(
                    (def) => (definitionMap[def.id] = def),
                );

                // replace definitions
                $scope.data.group.globalFields.forEach(
                    (field) =>
                        (field.fieldDefinition = definitionMap[field.fieldDefinition.id] || field.fieldDefinition),
                );

                $scope.data.group = entityHelper.enrichGroup($scope.data.group);

                // load sandbox icons
                if (
                    customFieldsManager.selectedColumnFieldsMap[workflowVersionId] &&
                    customFieldsManager.selectedColumnFieldsMap[workflowVersionId].length
                ) {
                    loadOriginalSourcesOverride(customFieldsManager.selectedColumnFieldsMap[workflowVersionId]);
                }

                // if no key metrics, auto open the week view
                $scope.data.showWeekView = !$scope.data.group.globalFields || !$scope.data.group.globalFields.length;

                if (!authenticationService.isUserAuthenticated()) {
                    projectManager.setViewOnlyMode(data.project);
                    finishInit();
                } else {
                    projectManager
                        .selectProject(data.project.id)
                        .then(function () {
                            // check if this is a full user
                            if (projectManager.isUserLicensed && originalGroupId) {
                                // it's a user, if it's full user and has access to the group, we just redirect them
                                if (data.visibilityType !== 'PRIVATE') {
                                    $state.go(
                                        'product.board',
                                        {
                                            filter: 'all',
                                            g: originalGroupId,
                                            st: null,
                                        },
                                        { location: 'replace' },
                                    );
                                } else {
                                    // if private list, we need to check if user have access. getting groups
                                    groupInfoManager.getGroups().then(function () {
                                        if (projectManager.groupsMap[originalGroupId]) {
                                            $state.go(
                                                'product.board',
                                                {
                                                    filter: 'all',
                                                    g: originalGroupId,
                                                    st: null,
                                                },
                                                { location: 'replace' },
                                            );
                                        } else {
                                            finishInit();
                                        }
                                    });
                                }
                            } else {
                                finishInit();
                            }
                        })
                        .catch(function () {
                            projectManager.setViewOnlyMode(data.project);
                            finishInit();
                        });
                }
            })
            .catch(function () {
                if (authenticationService.isUserAuthenticated()) {
                    // go to root
                    location.href = '/';
                } else {
                    // go to login
                    authenticationService.logout();
                }
            });
    };

    function finishInit() {
        initGroupMap();

        $scope.loadInitiatives();
        $scope.loading = false;
        $timeout(initScrollHandling);
    }

    $scope.loadInitiatives = function () {
        $scope.data.loadingInitiatives = true;
        trackHelper
            .getInitiativesByGuestToken($scope.token, 400, $localStorage.anonymousUserUniqueId)
            .then(function (data) {
                $scope.data.allInitiatives = trackHelper.cacheItemList(data.data.entities);
                $scope.data.initiatives = data.initiatives;
                $scope.data.loadingInitiatives = false;

                for (let i = 0; i < $scope.data.allInitiatives.length; i++) {
                    const item = $scope.data.allInitiatives[i];

                    // do the enrich to the fields if exists
                    if (item.fields && item.fields.length) {
                        for (let j = 0; j < item.fields.length; j++) {
                            item.fields[j] = entityHelper.enrichField(item.fields[j]);
                        }
                    }
                }

                $scope.data.startDate = utils.getLatestWeekDay();
                $scope.data.lastMonthDayLoaded = new Date($scope.data.startDate);

                // console.log($scope.data.initiatives[0]);

                $scope.loadThisWeek();
            });
    };

    $scope.loadThisWeek = function () {
        const monday = $scope.data.startDate;

        const from = new Date(monday);
        from.setDate(from.getDate() - 10);

        const to = new Date(monday);
        to.setDate(to.getDate() + 10);
    };

    $scope.loadOtherWeek = function (diff) {
        const date = new Date($scope.data.startDate);
        date.setDate(date.getDate() + diff);
        $scope.data.startDate = new Date(date);
        $scope.data.loadingWeek = true;
        $scope.loadThisWeek();
        $timeout(function () {
            $scope.data.loadingWeek = false;
        });
    };

    /**
     * This function is an overload of the same function in the initiativesCtrl.
     * This is needed since the bot live popover uses this function to communicate outside and tell us to close/open the bubble.
     * @param isOpen - if true, the popover will be opened. If false, it will be closed.
     */
    $scope.setBotPopoverIsOpenState = function (isOpen) {
        // This is a guest user, just toggle the bubble (no need to mess with onboarding as we do in initiativesCtrl).
        $scope.data.showBotState = isOpen;
    };

    $scope.init();

    function initGroupMap() {
        if (!projectManager.groups) {
            projectManager.groups = [];
        }
        projectManager.groups.push($scope.data.group);
        if (!projectManager.groupsMap) {
            projectManager.groupsMap = {};
        }
        projectManager.groupsMap[$scope.data.group.id] = $scope.data.group;
    }

    /** Scroll handling **/
    function initScrollHandling() {
        let scrolled = 0;
        let onscrollAction = null;
        let onscrollHandlerId = null;
        const projectBoardElement = document.querySelector('#project-board');

        if (!$rootScope.isMobile) {
            onscrollAction = function () {
                // current scroll size
                scrolled = projectBoardElement.scrollTop;

                // handle metrics top position
                const metricsBarElement = document.querySelector('#initiatives-list-metrics');
                if (metricsBarElement) {
                    metricsBarElement.style.top = `${-1 * scrolled}px`;
                }
            };

            onscrollHandlerId = safeDomEvents.registerEvent('project-board', 'onscroll', onscrollAction);

            $scope.$on('$destroy', function () {
                // console.log('destory initiativesPage');
                // cleaning the onscroll by setting the old ballback (which if null, means we are the root and can get rid of it)
                safeDomEvents.unregisterEvent('project-board', 'onscroll', onscrollHandlerId);
            });
        }
    }

    function loadOriginalSourcesOverride(fieldDefinitions) {
        return $http
            .get('https://s3-us-west-2.amazonaws.com/tonkean-files/sandbox_sources.json')
            .then(function (result) {
                $scope.sandboxSources = result.data;

                // Filter out existing sources from the webhooks ones.
                if ($scope.sandboxSources) {
                    // Faster using a dict of all integrations than searching on an array every time.
                    for (const fieldDef of fieldDefinitions) {
                        const key = fieldDef.id.slice(4);
                        if ($scope.sandboxSources[key]) {
                            fieldDef.originalSource = $scope.sandboxSources[key];
                        }
                    }
                }
            });
    }
}

export default angular.module('tonkean.app').controller('ViewOnlyCtrl', ViewOnlyCtrl);
