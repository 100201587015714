import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class StringLookUpTableFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_LOOK_UP_TABLE;
    override readonly dataType = FieldType.String;
    override readonly sign = 'StringLookUpTable';
    override readonly displayName = 'String Look Up Table';
    override readonly description = 'Will replace string with values from the table.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Source Text',
            dataType: FieldType.String,
        },
        {
            displayName: 'Lookup pair',
            pluralName: 'Lookup pairs',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 1,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Find ${repeatIndex}`,
                    explanation: 'A String that is to be replaced',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Replace ${repeatIndex}`,
                    explanation: `The String that replaces the substring specified by the specified in find ${repeatIndex}`,
                },
            ],
        },
    ];
}
