import { useMemo } from 'react';

import type InitiativeRowData from '../entities/InitiativeRowData';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FieldDefinitionTargetType } from '@tonkean/tonkean-entities';

const mapValues = (possibleValues: string[] | undefined) => {
    return (
        possibleValues?.map((possibleValue) => ({
            value: possibleValue,
            label: possibleValue,
        })) || []
    );
};

const useDropdownPossibleValues = (
    initiativeRowData: InitiativeRowData,
    dropdownOptionsFromFieldValue: string | number | undefined,
    inputMultiValueSeparator: string,
    fieldDefinitionTargetType?: FieldDefinitionTargetType,
    manualPossibleValues?: string[] | undefined,
    dropdownOptionsFromFieldDefinitionId?: TonkeanId<TonkeanType.FIELD_DEFINITION>,
) => {
    return useMemo(() => {
        let possibleValues: string[] | undefined = [];
        if (!dropdownOptionsFromFieldDefinitionId) {
            return mapValues(manualPossibleValues);
        }

        if (fieldDefinitionTargetType === FieldDefinitionTargetType.GLOBAL) {
            possibleValues = dropdownOptionsFromFieldValue?.toString().split(inputMultiValueSeparator) || [];
        } else {
            possibleValues =
                initiativeRowData[dropdownOptionsFromFieldDefinitionId]?.toString()?.split(inputMultiValueSeparator) ||
                [];
        }

        possibleValues = [...possibleValues, ...(manualPossibleValues || [])];

        return mapValues(possibleValues);
    }, [
        initiativeRowData,
        dropdownOptionsFromFieldValue,
        inputMultiValueSeparator,
        fieldDefinitionTargetType,
        manualPossibleValues,
        dropdownOptionsFromFieldDefinitionId,
    ]);
};

export default useDropdownPossibleValues;
