import { reactToAngular } from '@tonkean/angular-components';
import { UpdateFieldAsyncSearchDropdownSelector } from '@tonkean/fields';

angular
    .module('tonkean.app')
    .component(
        'tnkUpdateFieldAsyncSearchDropdownSelector',
        reactToAngular(UpdateFieldAsyncSearchDropdownSelector, [
            'fieldDefinitionId',
            'initiativeId',
            'onChange',
            'value',
            'isMultiValueList',
            'multiValueList',
            'readOnly',
            'placeholderText',
            'isCreatable',
            'defaultMenuIsOpen',
            'closeMenuOnSelect',
            'workflowVersionType',
        ]),
    );
