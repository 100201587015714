import { reactToAngular } from '@tonkean/angular-components';
import { FormulaTreeNodePopoverTabs } from '@tonkean/formula-builder';

angular
    .module('tonkean.app')
    .component(
        'tnkFormulaTreeNodePopoverTabs',
        reactToAngular(
            FormulaTreeNodePopoverTabs,
            [
                'workflowVersionId',
                'node',
                'depth',
                'isWrapMode',
                'deleteInPopover',
                'closePopover',
                'onNodeChanged',
                'onNodeDeleted',
                'onInnerTrackAggregationSelected',
            ],
            ['customFieldsManager'],
        ),
    );
