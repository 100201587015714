import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const PeopleDirectoryCommunicationPreference = styled.span`
    display: inline-flex;
    white-space: pre;
    align-items: center;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};

    svg {
        height: 14px;
        width: 14px;
    }
`;

export default PeopleDirectoryCommunicationPreference;
