import template from './tnkUpdateFieldsForm.template.html?angularjs';

/**
 * A create/update form which updates fields of a single initiative
 */
angular.module('tonkean.app').component('tnkUpdateFieldsForm', {
    bindings: {
        fields: '<',
        workflowVersionId: '<',
        groupId: '<',
        formConfig: '<',
        initiativeId: '<',
        workerRunId: '<',
        onSubmit: '&',
        onError: '&',
        customTriggerId: '<',
        sessionId: '<',
        formFinished: '<',
        primaryColor: '<',
        secondaryColor: '<',
        buttonsColor: '<',
        validationMap: '<',
        allErrorsNotRestrictingErrors: '<',
        workflowVersionType: '<',
        group: '<',
        isPrevious: '<',
        onBackClicked: '&',
        showBack: '<',
        prepopulatedValues: '<',
    },
    template,
    controller: 'UpdateFieldsFormCtrl',
});
