import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DetailedItemsWidgetBody from './DetailedItemsWidgetBody';
import WidgetRequiredIndication from '../../../components/WidgetRequiredIndication';
import type ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import useIsEnvironmentActive from '../../../hooks/useIsEnvironmentActive';
import ItemWidget from '../../../WidgetModule/components/ItemWidget';
import { ItemWidgetHeaderTitle } from '../../../WidgetModule/components/ItemWidgetHeader';
import type InnerItemWidgetConfiguration from '../../entities/InnerItemWidgetConfiguration';
import useItemsGridInlineMenuActions from '../../hooks/useItemsGridInlineMenuActions';
import InnerItemsItemWidgetHeaderActions from '../../InnerItemsWidgetModule/components/InnerItemsItemWidgetHeaderActions';
import LineItemSummaryFooter from '../../LineItemsWidgetModule/components/LineItemSummaryFooter';
import useUserFilters from '../../LineItemsWidgetModule/hooks/useUserFilters';
import type NewAddedInitiativeInfo from '../entities/NewAddedInitiativeInfo';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { DataNotUpToDateIndicator } from '@tonkean/infrastructure';
import { useFastReload, useIsMobile, useInitiatives } from '@tonkean/infrastructure';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey, type TonkeanId } from '@tonkean/tonkean-entities';
import {
    type Group,
    type Initiative,
    type ItemInterfaceHeaderThemeConfiguration,
    type ItemInterfaceWidget,
    type Project,
    type WorkflowVersion,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

interface Props {
    permission: ItemInterfacePermission;
    groupId: Group['id'] | undefined;
    initiative: Initiative | undefined;
    initiativeInitialLoading: boolean;
    projectId: Project['id'];
    widget: ItemInterfaceWidget<InnerItemWidgetConfiguration>;
    theme: ItemInterfaceHeaderThemeConfiguration;
    workflowVersion: WorkflowVersion | undefined;
}

const DetailedItemsWidgetDisplay: React.FC<Props> = ({
    permission,
    projectId,
    initiative,
    initiativeInitialLoading,
    widget,
    theme,
    groupId,
    workflowVersion,
}) => {
    const enableInterfaceWidgetNoItemEmptyState = useFeatureFlag(
        'tonkean_enable_interface_widgets_no_item_empty_state',
    );
    const isMobile = useIsMobile();
    const isEnvironmentActive = useIsEnvironmentActive(groupId, initiative);

    const [calculatedFiltersQueryDefinition] = useUserFilters(widget);

    const { itemInterfaceFieldDefinitions } = useItemInterfaceContext();

    const [interval, fastInterval] = useFastReload(10_000, 1000);

    const [searchInitiatives, { initiatives, hasMorePages, totalInitiatives, isFetched, loading }] = useInitiatives(
        50,
        interval,
        projectId,
    );

    const itemValuesForCalculations: Record<FieldDefinitionKey, string | number | Date | undefined>[] = useMemo(() => {
        return initiatives.map((innerInitiative) => {
            const specialFieldsEntries: [SpecialFieldsKey, string | number | Date | undefined][] = [
                [SpecialFieldsKey.TITLE, innerInitiative.title],
                [SpecialFieldsKey.DUE_DATE, innerInitiative.dueDate],
                [SpecialFieldsKey.CREATED_DATE, innerInitiative.created],
            ];

            const customFieldsEntries: [FieldDefinitionKey, string | number | Date | undefined][] = (
                innerInitiative.fields || []
            )
                .filter((fieldInstance) => !!widget.configuration.fields[fieldInstance.fieldDefinition.id])
                .map((fieldInstance) => [fieldInstance.fieldDefinition.id, fieldInstance.value]);

            return Object.fromEntries([...specialFieldsEntries, ...customFieldsEntries]) as Record<
                FieldDefinitionKey,
                string | number | Date | undefined
            >;
        });
    }, [initiatives, widget.configuration.fields]);

    const [newAddedInitiatives, setNewAddedInitiatives] = useState<NewAddedInitiativeInfo[]>([]);
    const [deletedInitiativeIds, setDeletedInitiativeIds] = useState<Set<TonkeanId<TonkeanType.INITIATIVE>>>(new Set());

    const reloadInitiatives = useCallback(() => {
        if (initiative?.id && workflowVersion?.workflowVersionType && groupId) {
            searchInitiatives(projectId, {
                conditionsQuery: calculatedFiltersQueryDefinition,
                isArchived: false,
                groupId,
                isDraftInitiatives: workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT,
                isEditModeInitiatives: true,
                parentId: initiative?.id,
                // TODO we should replace the hard coded skip & limit to - SKIP_PARAM, LIMIT_PARAM.
                //      For now its not supported because the skip & limit is inside object and the fetch manager (that used in useInitiative) is not supported skip/limit that inside object.
                //      its not urgent because the line items support max of 50 items but for future usage of this component need to fix that.
                skip: 0,
                limit: 50,
                orderByFieldId: widget.configuration.sortByField?.fieldDefinitionId,
                orderByFieldType: widget.configuration.sortByField?.orderByFieldType,
                orderByType: widget.configuration.sortByField?.orderType,
            });
        }
    }, [
        calculatedFiltersQueryDefinition,
        groupId,
        initiative?.id,
        projectId,
        searchInitiatives,
        widget.configuration.sortByField?.fieldDefinitionId,
        widget.configuration.sortByField?.orderByFieldType,
        widget.configuration.sortByField?.orderType,
        workflowVersion?.workflowVersionType,
    ]);

    useLayoutEffect(() => {
        reloadInitiatives();
    }, [
        calculatedFiltersQueryDefinition,
        groupId,
        initiative?.id,
        searchInitiatives,
        widget.configuration.filters,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        widget.projectId,
        reloadInitiatives,
    ]);

    // todo: In the local env this function is called twice due to onFormCreatedInitiative in formPageCtrl.controller.js, need to find out why
    const handleInitiativeCreated = useCallback(
        (initiativeCreated) => {
            if (initiativeCreated) {
                setNewAddedInitiatives((prevNewAddedInitiatives) => [
                    { id: initiativeCreated.id, title: initiativeCreated.title },
                    ...prevNewAddedInitiatives,
                ]);

                fastInterval();
            }
        },
        [fastInterval],
    );

    const handleInitiativeDeleted = useCallback((deletedInitiativeId: TonkeanId<TonkeanType.INITIATIVE>) => {
        setDeletedInitiativeIds(
            (prevDeletedInitiativeIds) => new Set([deletedInitiativeId, ...prevDeletedInitiativeIds]),
        );
    }, []);

    const fieldsToShowKeys = useMemo(() => {
        return Object.entries(widget.configuration.fields)
            .filter(([, field]) => field.isShown)
            .sort(([, fieldA], [, fieldB]) => {
                const itemAIndex = Number(fieldA?.index) ?? 0;
                const itemBIndex = Number(fieldB?.index) ?? 0;

                return itemAIndex - itemBIndex;
            })
            .map(([key]) => key as FieldDefinitionKey);
    }, [widget.configuration.fields]);

    const inlineMenuActions =
        useItemsGridInlineMenuActions(
            widget.configuration?.actions || EMPTY_ARRAY,
            workflowVersion?.workflowVersionType,
            groupId,
        ) || EMPTY_ARRAY;

    const minimumItemsRequired = widget.configuration.minMaxRequirementConfiguration?.minimum ?? 0;
    const maximumItemsAmount = widget.configuration.minMaxRequirementConfiguration?.maximum ?? 50;

    return (
        <ItemWidget
            permission={permission}
            itemWidgetBodyMinHeight={200}
            itemWidgetBodyMaxHeight={500}
            headerTitle={
                <TitleContainer>
                    <ItemWidgetHeaderTitle>{widget?.displayName ?? 'Detailed Items'}</ItemWidgetHeaderTitle>
                    <DataNotUpToDateIndicator show={isEnvironmentActive !== undefined && !isEnvironmentActive} />
                    {minimumItemsRequired > 0 && (
                        <WidgetRequiredIndication type={widget.configuration.requiredIndicatorType} />
                    )}
                </TitleContainer>
            }
            headerActions={
                <>
                    {!isMobile && (
                        <InnerItemsItemWidgetHeaderActions
                            widget={widget}
                            workflowVersionId={workflowVersion?.id}
                            headerBackgroundColor={theme.headerBackgroundColor}
                            initiative={initiative}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            onInitiativeCreated={handleInitiativeCreated}
                        />
                    )}
                </>
            }
            subHeader={
                <>
                    {isMobile && (
                        <InnerItemsItemWidgetHeaderActions
                            widget={widget}
                            workflowVersionId={workflowVersion?.id}
                            headerBackgroundColor={theme.headerBackgroundColor}
                            initiative={initiative}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            onInitiativeCreated={handleInitiativeCreated}
                        />
                    )}
                </>
            }
            footer={
                <LineItemSummaryFooter
                    widget={widget}
                    fieldDefinitions={itemInterfaceFieldDefinitions}
                    parentInitiativeId={initiative?.id}
                    itemsValues={itemValuesForCalculations}
                    hasMoreInitiatives={hasMorePages}
                    totalInitiatives={totalInitiatives}
                    isLoaded={isFetched}
                />
            }
            noPaddingBody
            disableMaxHeight
        >
            <DetailedItemsWidgetBody
                widgetId={widget.id}
                fieldsToShowKeys={fieldsToShowKeys}
                showNoItemAvailable={
                    !!(!initiativeInitialLoading && !initiative && enableInterfaceWidgetNoItemEmptyState)
                }
                loadingInitiatives={loading.initial}
                loadedInitiatives={initiatives}
                newAddedInitiatives={newAddedInitiatives}
                interfacePermission={permission}
                widgetUserAccessLevel={widget.configuration.userAccessLevel}
                selectionColor={theme.headerBackgroundColor}
                drillDownInterfaceId={widget.configuration.drillDownInterface}
                projectId={projectId}
                fastReloadRequired={fastInterval}
                inlineMenuActions={inlineMenuActions}
                onDeleteInitiative={handleInitiativeDeleted}
                deletedInitiativeIds={deletedInitiativeIds}
                minimumItemsRequired={minimumItemsRequired}
                maximumItemsAmount={maximumItemsAmount}
                isMinMaxValidationEnabled
            />
        </ItemWidget>
    );
};

export default DetailedItemsWidgetDisplay;
