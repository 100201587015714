<div class="worker-tile">
    <div class="workers-gallery-box-content" data-automation="worker-card-{{data.worker.name}}">
        <div
            class="
                flex
                padding-left-14 padding-top-14 padding-right-14
                mod-justify-space
                line-height-xs
                mod-align-center
            "
        >
            <!-- Module name -->
            <div
                id="bot-card-view-folder-{{ data.worker.id.toLowerCase() }}"
                class="common-title-xs mod-light margin-right-xs text-left padding-bottom-xs worker-tile-name"
                data-automation="worker-tile-bot-name-{{data.worker.name}}"
                tooltip-enable="{{ utils.hasEllipsis('#bot-card-view-folder-' + data.worker.id.toLowerCase()) }}"
                uib-tooltip="{{ data.worker.name }}"
            >
                <tnk-worker-tile-name name="data.worker.name" search-term="data.highlightTerm"></tnk-worker-tile-name>
            </div>

            <div ng-if="data.migrating" class="loading-small padding-bottom-xs" uib-tooltip="Migrating to makers"></div>

            <div
                ng-if="!data.migrating && data.worker.notVisibleToMakers"
                class="flex svg-fix-size padding-bottom-xs pointer"
                uib-tooltip="Click to migrate this private module to support solution makers"
                ng-click="migrateToMakers($event)"
            >
                <tnk-icon src="/images/icons/history/warning.svg"></tnk-icon>
            </div>

            <div class="flex-grow"></div>

            <!-- Status (on/off) -->
            <tnk-bot-status-view
                status="pm.groupsMap[data.worker.id].workerEnabled"
                class="margin-left-xs"
            ></tnk-bot-status-view>

            <!-- worker options -->
            <div class="flex-justify-end-no-xs margin-bottom-xs margin-left-xs" ng-if="!data.viewOnly">
                <div uib-dropdown is-open="data.openDropdown">
                    <!-- Config icon-->
                    <div class="three-dots-button common-color-grey pointer" ng-click="toggleDropdown($event)" data-automation="tnk-worker-tile-worker-3-dots-button">
                        <tnk-icon class="padding-7" src="/images/icons/ellipsis-h.svg"></tnk-icon>
                    </div>

                    <!-- the dropdown -->
                    <ul class="cursor" uib-dropdown-menu role="menu" aria-labelledby="split-button" data-automation="tnk-worker-tile-worker-move-button">
                        <li
                            ng-class="{
                                disabled: !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
                            }"
                        >
                            <a
                                ng-click="changeGroupsWorkflowFolder($event)"
                                uib-tooltip="Only makers can move Modules"
                                tooltip-enable="!wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner"
                            >
                                <div class="flex-vmiddle line-height-none">
                                    <span class="svg-icon-sm margin-right-xs">
                                        <tnk-icon src="/images/icons/folder.svg"></tnk-icon>
                                    </span>
                                    <div class="margin-top-xxs">Move</div>
                                </div>
                            </a>
                        </li>
                        <li
                            ng-class="{
                                disabled: !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
                            }"
                        >
                            <a
                                ng-click="onCreateTemplateClick($event)"
                                uib-tooltip="Only makers can create templates"
                                tooltip-enable="!wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner"
                            >
                                <div class="flex-vmiddle line-height-none">
                                    <span class="svg-icon-sm margin-right-xs">
                                        <tnk-icon src="/images/icons/copy-trigger.svg"></tnk-icon>
                                    </span>
                                    <div class="margin-top-xxs">Create Template</div>
                                    <tnk-module-marketplace-item-create-modal
                                        project-id="pm.project.id"
                                        group-id="data.worker.id"
                                        group-name="data.worker.name"
                                        workflow-folder-id="data.folderId"
                                        open="data.createModuleTemplateOpen"
                                        on-close="(moduleTemplateModalOnClose)"
                                    />
                                </div>
                            </a>
                        </li>
                        <li>
                            <a
                                ng-if="!data.migrating && data.worker.notVisibleToMakers"
                                ng-click="migrateToMakers($event)"
                            >
                                <div class="flex-vmiddle line-height-none">
                                    <span class="svg-icon-sm margin-right-xs">
                                        <tnk-icon src="/images/icons/update.svg"></tnk-icon>
                                    </span>
                                    <div class="margin-top-xxs">Migrate to makers</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Type name -->
        <div class="flex padding-left-14 mod-justify-space common-size-xxxxxs common-color-grey text-uppercase">
            <!-- Type label -->
            <span>{{ data.workerTypeInfo.typeLabel }}</span>
        </div>

        <div class="margin-bottom-lg">
            <!-- Icons and arrow -->
            <div
                class="padding-top-left-14 padding-right-xxs"
                ng-class="{
                    'padding-bottom-lg': data.viewOnly
                }"
            >
                <tnk-module-integrations-preview
                    size="'sm'"
                    hide-circle="true"
                    worker="data.worker"
                ></tnk-module-integrations-preview>
            </div>
        </div>

        <div class="flex-vmiddle flex-grow common-border-top common-size-xxxxs" ng-if="!data.viewOnly">
            <a
                ui-sref="product.workerEditor({ g: data.worker.id })"
                class="
                    flex-vmiddle
                    common-link-no-style
                    flex-grow
                    pointer
                    text-center
                    margin-none
                    mod-justify-center
                    common-color-purple
                    flex-grow
                    padding-14
                    common-hover common-border-right
                "
            >
                <span
                    class="svg-icon-sm common-color-purple margin-right-xs flex flex-vmiddle flex-justify-space-around"
                >
                    <tnk-icon class="common-color-purple" src="/images/icons/navigation/ops-modules.svg"></tnk-icon>
                </span>
                <span class="common-color-purple text-uppercase" data-automation="module-editor-button-{{data.worker.name}}">Editor</span>
            </a>
            <a
                ui-sref="product.board({filter:'all', g:data.worker.id, st:null, listFilter: null, isGroupListFilter: null})"
                class="
                    flex-vmiddle
                    common-link-no-style
                    flex-grow
                    pointer
                    text-center
                    margin-none
                    mod-justify-center
                    common-color-warning
                    flex-grow
                    padding-14
                    common-hover
                "
            >
                <span
                    class="svg-icon-sm common-color-warning margin-right-xs flex flex-vmiddle flex-justify-space-around"
                >
                    <tnk-icon class="common-color-warning" src="/images/icons/dashboard.svg"></tnk-icon>
                </span>
                <span class="common-color-warning text-uppercase" data-automation="tnk-worker-tile-template-report-button">Report</span>
            </a>
        </div>
    </div>
</div>
