<div class="custom-trigger-selector">
    <!-- Loading -->
    <div ng-if="data.loadingCustomTriggers" class="flex-vmiddle">
        <i class="loading-small margin-right-xs"></i>
        <div>Loading triggers...</div>
    </div>

    <!-- Error loading -->
    <div ng-if="data.errorLoadingCustomTriggers" class="common-size-xxxxs common-color-danger">
        There was an error loading triggers...
    </div>

    <!-- Selection of project integration -->
    <ui-select
        class="ui-select-min-width"
        ng-if="!data.loadingCustomTriggers && !data.errorLoadingCustomTriggers && data.customTriggers.length"
        theme="bootstrap"
        ng-model="data.selectedCustomTrigger"
        ng-change="onCustomTriggerSelected(data.selectedCustomTrigger)"
    >
        <ui-select-match allow-clear="true" placeholder="Select">
            {{ getCustomTriggerDisplayName(data.selectedCustomTrigger) }}
            <span
                ng-if="data.allowClear"
                ng-click="$select.clear($event) && clearSelectedCustomTrigger()"
                class="
                    svg-icon-vmiddle svg-icon-hover-dark-grey
                    absolute-right
                    margin-right-lg
                    padding-right-xxs
                    margin-top-xxs
                    common-color-grey
                    ui-select-custom-span-container
                "
            >
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>
        </ui-select-match>

        <ui-select-choices repeat="customTrigger in data.customTriggers | filter:$select.search">
            <div
                ng-bind-html="getCustomTriggerDisplayName(customTrigger) | escapeHtml | highlight: $select.search"
            ></div>
        </ui-select-choices>
    </ui-select>

    <!-- Empty state -->
    <div class="custom-trigger-selector-empty" ng-if="!data.customTriggers || !data.customTriggers.length">
        {{ data.emptyStateMessage || 'Could not find any empty triggers.' }}
    </div>
</div>
