export function NamelyModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    utils,
    integrations,
    projectManager,
) {
    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;
        tryLoadFromEdit();
    };

    $scope.connect = async function () {
        $scope.connecting = true;
        $scope.error = null;

        try {
            const token = $scope.data.apikey;
            const config = {
                api_token: token,
                namely_workspace: $scope.data.workspaceName,
                authentication_type: 'api_token',
            };
            $scope.integration = await createProjectApis.createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                config,
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'NAMELY',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            );
        } catch (error) {
            $scope.error = error;
        } finally {
            $scope.connecting = false;
        }
        // --------- OAuth option is disabled for now ------------
        // let code = await oauthHandler.namely(environment.integrationKeysMap.namely, environment.integrationKeysMap.namelyUril);
        // try {
        //     let config = {
        //         code,
        //         redirectUri: environment.integrationKeysMap.namelyUri
        //     };
        //     await createProjectApis.createIntegration($scope.currentIntegration.name, config, integrations.getIntegrationUniqueType(authenticationService.currentUser.id, 'NAMELY', $scope.otherIntegrationOwner));
        // } catch (error) {
        //     $scope.error = error;
        // } finally {
        //     $scope.connecting = false;
        // }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.data.apikey = '';
        $scope.data.workspaceName = '';
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }
        const settings = {
            integration: $scope.integration,
            integrations: [{ integration: $scope.integration, projectData: {} }],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(settings);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = false;
            $scope.error = null;
        }
    }
}

export default angular.module('tonkean.shared').controller('NamelyModalCtrl', NamelyModalCtrl);
