import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationsBoxesView from './components/ProjectIntegrationsBoxesView/ProjectIntegrationsBoxesView';
import ProjectIntegrationsListView from './components/ProjectIntegrationsListView/ProjectIntegrationsListView';
import ProjectIntegrationsViewType from './entities/ProjectIntegrationsViewType';
import type ProjectIntegrationPageExplorerSection from '../ProjectIntegrationPageModule/entities/ProjectIntegrationPageExplorerSection';
import getIntegrationsSectionsConfiguration from '../ProjectIntegrationPageModule/utils/getIntegrationsSectionsConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import type { GetProjectIntegrationsSummaries } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import type { IntegrationType } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';

const Wrapper = styled.div`
    ${childrenStyledFocus}
`;

interface Props {
    viewType: ProjectIntegrationsViewType;
    filterByText: string | undefined;
    filterByIntegrationType?: IntegrationType[];
    onClickConnectedDataSource: (projectIntegration: ProjectIntegrationSummary) => void;
    onClickDisconnectedDataSource?: (projectType: string) => void;
    selectedProjectIntegrationId?: string;
    customProjectIntegrationSummariesWithWorkflowFolderAccess?: GetProjectIntegrationsSummaries;
    isListFilterBySolutionAccess?: boolean;
    filterIntegrationsWithNoEntities?: boolean;
    shouldBlockOnClickOfInaccessible?: boolean;
}

const ProjectIntegrationsView: React.FC<Props> = ({
    viewType,
    filterByText,
    filterByIntegrationType,
    onClickConnectedDataSource,
    onClickDisconnectedDataSource,
    selectedProjectIntegrationId,
    isListFilterBySolutionAccess,
    customProjectIntegrationSummariesWithWorkflowFolderAccess,
    filterIntegrationsWithNoEntities,
    shouldBlockOnClickOfInaccessible = false,
}) => {
    const emitToast = useToastMessage();
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const consts = useAngularService('consts');
    const integrations = useAngularService('integrations');
    const [projectIntegrationPageExplorerSection, setProjectIntegrationPageExplorerSection] = useState<
        ProjectIntegrationPageExplorerSection[]
    >([]);

    const $rootScope = useAngularService('$rootScope');

    const showStorageUpload = $rootScope.features.currentProject.tonkean_feature_show_storage_upload_action;
    const showCustomHttpAction = $rootScope.features.currentProject.tonkean_feature_show_custom_http_action;

    // dictionary for quick access for projectIntegration by id.
    const [projectIntegrationsDict, setProjectIntegrationsDict] = useState<Record<string, ProjectIntegrationSummary>>();

    const [projectIntegrationsSummaries, setProjectIntegrationsSummaries] = useState<
        GetProjectIntegrationsSummaries | undefined
    >(customProjectIntegrationSummariesWithWorkflowFolderAccess);

    // lazy request to get project integrations summaries.
    const [{ data: projectIntegrations, loading }, getProjectIntegrationsSummaries] = useLazyTonkeanService(
        'getProjectIntegrationsSummaries',
    );

    // When the custom list customProjectIntegrationSummariesWithWorkflowFolderAccess changed from the outside we want to rerender
    useEffect(() => {
        if (customProjectIntegrationSummariesWithWorkflowFolderAccess) {
            setProjectIntegrationsSummaries(customProjectIntegrationSummariesWithWorkflowFolderAccess);
        }
    }, [customProjectIntegrationSummariesWithWorkflowFolderAccess]);

    // When the page loaded we're getting all the project summaries or on custom list is changed
    useEffect(() => {
        let workflowFolderId: string | undefined = undefined;

        if (
            projectManager.currentlyViewedGroupId &&
            (isListFilterBySolutionAccess || ProjectIntegrationsViewType.BOXES)
        ) {
            workflowFolderId = workflowFolderManager.getContainingWorkflowFolder(
                projectManager.project.id,
                projectManager.currentlyViewedGroupId,
            ).id;
        }
        // may be null when we giving custom list prop
        if (!projectIntegrationsSummaries) {
            getProjectIntegrationsSummaries(projectManager.project.id, true, workflowFolderId, filterByIntegrationType);
        }
    }, [
        projectIntegrationsSummaries,
        getProjectIntegrationsSummaries,
        isListFilterBySolutionAccess,
        projectManager,
        workflowFolderManager,
        filterByIntegrationType,
    ]);

    // When the page loaded we getting all the project summaries or on custom list is changed when no custom list given
    useEffect(() => {
        if (projectIntegrations) {
            setProjectIntegrationsSummaries(projectIntegrations);

            if (selectedProjectIntegrationId) {
                const foundSelectedProjectIntegration = projectIntegrations.entities.find(
                    (projectIntegration) => projectIntegration.id === selectedProjectIntegrationId,
                );
                if (foundSelectedProjectIntegration) {
                    onClickConnectedDataSource(foundSelectedProjectIntegration);
                }
            }
        }
    }, [onClickConnectedDataSource, projectIntegrations, selectedProjectIntegrationId]);

    // When project integration loaded in the first time we generate dictionary to better performance.
    useEffect(() => {
        if (projectIntegrationsSummaries?.entities?.length) {
            const projectIntegrationMap: Record<string, ProjectIntegrationSummary> = {};

            projectIntegrationsSummaries.entities.forEach(
                (projectIntegration) => (projectIntegrationMap[projectIntegration.id] = projectIntegration),
            );

            setProjectIntegrationsDict(projectIntegrationMap);
        }
    }, [projectIntegrationsSummaries]);

    const nonProjectIntegrationActions: string[] = useMemo(() => {
        const types: string[] = [consts.getLogicBlockTypes().HTTP_UPLOAD.type];

        if (showStorageUpload) {
            const storageUploadType = consts.getLogicBlockTypes().STORAGE_UPLOAD.type;
            types.push(storageUploadType);
        }

        if (showCustomHttpAction) {
            const customHttpActionType: string = consts.getLogicBlockTypes().OUTGOING_WEBHOOK.type;
            types.push(customHttpActionType);
        }

        return types;
    }, [consts, showCustomHttpAction, showStorageUpload]);

    // Get all the disconnected integration for use.
    const disconnectedIntegrations: string[] = useMemo(() => {
        if (projectIntegrationsSummaries?.entities?.length !== undefined && projectManager.projectData) {
            // Constructing a list of existing integration unique types without duplicates (only if we have a project - we might not be authenticated).
            const existingIntegrationsTypesToRemove = projectIntegrationsSummaries.entities
                .filter(
                    (projectIntegration: ProjectIntegrationSummary) =>
                        !projectIntegration.supportsMultipleIntegrationsPerUser,
                )
                .filter(
                    (projectIntegrationSummary: ProjectIntegrationSummary) => !projectIntegrationSummary.systemUtilized,
                )
                .map((integration) => integration.integration.integrationType.toLowerCase());

            // Remove integrations to hide and webhook integrations
            const integrationsGroups = integrations.getIntegrationGroups();
            const integrationsToFilter: string[] = integrationsGroups.hideIntegrations.integrations.concat(
                integrationsGroups.webhooks.integrations[1],
            );

            // Taking only the sources that do not already exist.
            const uniques: string[] = integrations.getUniqueIntegrationsDataSources(
                existingIntegrationsTypesToRemove.concat(integrationsToFilter),
                projectManager.projectData.canBeCreatedByUserIntegrationTypes,
                projectManager.project.id,
            );

            // We treat non project integration actions as disconnected integrations.
            // Thus, we add them manually.
            return [...uniques, ...nonProjectIntegrationActions];
        }
        return [];
    }, [
        integrations,
        nonProjectIntegrationActions,
        projectIntegrationsSummaries?.entities,
        projectManager.project.id,
        projectManager.projectData,
    ]);

    // The filtered disconnected integration by the given filterByText.
    const filteredDisconnectedActions: string[] = useMemo(() => {
        if (disconnectedIntegrations) {
            if (filterByText && filterByText.length > 0) {
                return disconnectedIntegrations.filter((integration) =>
                    integration.toLowerCase().includes(filterByText.toLowerCase()),
                );
            }
            return disconnectedIntegrations;
        }
        return [];
    }, [disconnectedIntegrations, filterByText]);

    const getFilteredProjectIntegrations = useCallback(
        (projectIntegrationsToFilter: (ProjectIntegrationSummary | undefined)[]) => {
            return projectIntegrationsToFilter
                .filter(Boolean)
                .filter(
                    (projectIntegrationSummary: ProjectIntegrationSummary) => !projectIntegrationSummary.systemUtilized,
                ) as ProjectIntegrationSummary[];
        },
        [],
    );

    // All allowed project integration.
    const allowedProjectIntegrations = useMemo(() => {
        if (projectIntegrationsSummaries?.workflowFolderAccess && projectIntegrationsDict) {
            return getFilteredProjectIntegrations(
                projectIntegrationsSummaries.workflowFolderAccess.accessibleProjectIntegrations.map(
                    (projectIntegrationId) => projectIntegrationsDict[projectIntegrationId],
                ),
            );
        }
        return [];
    }, [projectIntegrationsSummaries?.workflowFolderAccess, projectIntegrationsDict, getFilteredProjectIntegrations]);

    // Memo for All the not allowed projectIntegrations.
    const notAllowedProjectIntegrations = useMemo(() => {
        if (projectIntegrationsSummaries?.workflowFolderAccess && projectIntegrationsDict) {
            return getFilteredProjectIntegrations(
                projectIntegrationsSummaries.workflowFolderAccess.inAccessibleProjectIntegrations.map(
                    (projectIntegrationId) => projectIntegrationsDict[projectIntegrationId],
                ),
            );
        }
        return [];
    }, [projectIntegrationsSummaries?.workflowFolderAccess, projectIntegrationsDict, getFilteredProjectIntegrations]);

    // Memo for the result of the project integration list of filtered allowed ProjectIntegrations by filterByStringQuery.
    const projectIntegrationsFilteredByText = useMemo(() => {
        const filterByStringQuery = (projectIntegration: ProjectIntegrationSummary) => {
            if (filterByText) {
                const fieldsToFilter = [
                    projectIntegration.integration.integrationType,
                    projectIntegration.description,
                    projectIntegration.displayName,
                ];

                return fieldsToFilter.some((filterBy) => {
                    return filterBy?.toLowerCase().includes(filterByText.toLowerCase());
                });
            }
            return true;
        };

        return {
            allowed: allowedProjectIntegrations.filter(filterByStringQuery),
            notAllowed: notAllowedProjectIntegrations.filter(filterByStringQuery),
        };
    }, [allowedProjectIntegrations, filterByText, notAllowedProjectIntegrations]);

    useEffect(() => {
        if (projectIntegrationsSummaries?.entities) {
            const projectIntegrationsListConfiguration = getIntegrationsSectionsConfiguration(
                projectIntegrationsSummaries?.entities,
                integrations.getIntegrationsConfig(),
            );

            setProjectIntegrationPageExplorerSection(projectIntegrationsListConfiguration.sections);
        }
    }, [projectIntegrationsSummaries?.entities, integrations]);

    // Wrapping on data source click callback to validate if shouldBlockOnClickOfInaccessible
    const onClickDataSource = useCallback(
        (projectIntegrationSummary: ProjectIntegrationSummary) => {
            if (projectIntegrationsSummaries?.workflowFolderAccess) {
                if (
                    (shouldBlockOnClickOfInaccessible &&
                        projectIntegrationsSummaries.workflowFolderAccess.accessibleProjectIntegrations.includes(
                            projectIntegrationSummary.id,
                        )) ||
                    !shouldBlockOnClickOfInaccessible
                ) {
                    return onClickConnectedDataSource(projectIntegrationSummary);
                } else {
                    return emitToast(
                        'You can’t select inaccessible data source. You can manage the integration access permissions in the solution access tab in the data source settings.',
                    );
                }
            }
        },
        [
            emitToast,
            onClickConnectedDataSource,
            projectIntegrationsSummaries?.workflowFolderAccess,
            shouldBlockOnClickOfInaccessible,
        ],
    );

    return (
        <Wrapper>
            {viewType === ProjectIntegrationsViewType.BOXES && onClickDisconnectedDataSource && (
                <ProjectIntegrationsBoxesView
                    authorizedProjectIntegrations={projectIntegrationsFilteredByText.allowed}
                    unAuthorizedProjectIntegrations={projectIntegrationsFilteredByText.notAllowed}
                    filterByText={filterByText}
                    disconnectedIntegrations={filteredDisconnectedActions}
                    onClickConnectedDataSource={onClickDataSource}
                    onClickDisconnectedDataSource={onClickDisconnectedDataSource}
                    isLoading={loading}
                />
            )}
            {viewType === ProjectIntegrationsViewType.LIST && projectIntegrationsSummaries && (
                <ProjectIntegrationsListView
                    projectIntegrationSections={projectIntegrationPageExplorerSection}
                    onClickDataSource={onClickDataSource}
                    projectIntegrationsSummaries={projectIntegrationsSummaries.entities}
                    filterByText={filterByText}
                    isLoading={loading}
                    isFilterBySolutionAccess={isListFilterBySolutionAccess}
                    projectIntegrationWorkflowFolderAccess={projectIntegrationsSummaries.workflowFolderAccess}
                    filterIntegrationsWithNoEntities={filterIntegrationsWithNoEntities}
                />
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationsView;
