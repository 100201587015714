import { useAngularService } from 'angulareact';
import React, { useEffect, useRef } from 'react';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props {
    /**
     * Whether to disable the children or not.
     */
    disable: boolean;
    /**
     * Pass empty string to hide text
     */
    text?: string;
}

type DisableContentProps = Props & StyledComponentsSupportProps;

/**
 * Creates an overlay on the content based on a given prop.
 * Make sure to add in reactToAngular root - $state.
 * Doesn't trigger a render on url change for now - needs to be implemented.
 *
 * @example
 * <DisableContent disable={true}><input value="6" /></DisableContent>
 */
const DisableContent: React.FC<React.PropsWithChildren<DisableContentProps>> = ({
    children,
    className,
    disable,
    text,
}) => {
    const elementBlocker = useAngularService('elementBlocker');
    const elementToBlockRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!disable) {
            return;
        }

        const elementToBlock = elementToBlockRef.current;

        elementBlocker.blockElement(elementToBlock, text);
        return () => {
            elementBlocker.unblockElement(elementToBlock);
        };
    }, [elementBlocker, elementToBlockRef, disable, text]);

    return (
        <div className={className} ref={elementToBlockRef}>
            {children}
        </div>
    );
};

export default DisableContent;
