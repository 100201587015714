function EditCustomWebhookItemModal(
    $scope,
    $http,
    $q,
    tonkeanService,
    projectManager,
    projectIntegration,
    existingItem,
) {
    $scope.pm = projectManager;

    $scope.data = {
        item: existingItem || {},
        projectIntegration,
        isEdit: !!existingItem,
        incomingWebhook: null,
        idFieldPathName: null,
        titleFieldPathName: null,

        isLoading: false,
        isSubmitLoading: false,
        error: null,

        optionalFields: [],
        requiredFields: [],
        blackListedKeys: [
            'created',
            'updated',
            'secondaryId',
            'TNK_REAL_ENTITY_ID',
            'TNK_NEW_CREATED_DATE',
            'TNK_NEW_MODIFIED_DATE',
        ],
        newField: {},
    };

    $scope.init = function () {
        $scope.data.isLoading = true;

        const getFieldMetadataPromise = tonkeanService
            .getAvailableExternalFields($scope.data.projectIntegration.id, [$scope.data.projectIntegration.displayName])
            .then((data) => {
                return data.entitiesWithLabels
                    ? data.entitiesWithLabels.map((field) => {
                          return getNewFieldObject(field.name, $scope.data.item[field.name]);
                      })
                    : [];
            });

        const getIncomingWebhookPromise = tonkeanService.getIncomingWebhookByProjectIntegrationId(
            projectManager.project.id,
            $scope.data.projectIntegration.id,
        );

        $q.all([getFieldMetadataPromise, getIncomingWebhookPromise]).then((results) => {
            $scope.data.incomingWebhook = results[1].incomingWebhook;

            $scope.data.idFieldPathName = $scope.data.incomingWebhook.definition.idFieldPath || 'id';
            $scope.data.titleFieldPathName = $scope.data.incomingWebhook.definition.titleFieldPath || 'id';

            // Initialized required keys
            $scope.data.requiredFields = [];

            if ($scope.data.incomingWebhook.definition.idFieldPath) {
                $scope.data.requiredFields.push(
                    getNewFieldObject($scope.data.idFieldPathName, $scope.data.item[$scope.data.idFieldPathName], true),
                );
            }

            if (
                $scope.data.incomingWebhook.definition.titleFieldPath &&
                $scope.data.idFieldPathName !== $scope.data.titleFieldPathName
            ) {
                $scope.data.requiredFields.push(
                    getNewFieldObject(
                        $scope.data.titleFieldPathName,
                        $scope.data.item[$scope.data.titleFieldPathName],
                        true,
                    ),
                );
            }

            $scope.data.optionalFields = results[0].filter(
                (field) =>
                    !$scope.data.blackListedKeys.includes(field.key) &&
                    field.key !== $scope.data.idFieldPathName &&
                    field.key !== $scope.data.titleFieldPathName,
            );

            $scope.data.isLoading = false;
        });
    };

    $scope.import = function () {
        $scope.data.error = null;
        $scope.data.isSubmitLoading = true;

        const item = {};
        const fields = $scope.data.requiredFields.concat($scope.data.optionalFields);
        for (const field of fields) {
            if (field.key && field.value) {
                item[field.key] = field.value;
            }
        }

        $http
            .post($scope.data.incomingWebhook.url, [item])
            .catch((error) => {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.isSubmitLoading = false;
                if (!$scope.data.error) {
                    $scope.$close();
                }
            });
    };

    $scope.addNewField = function () {
        $scope.data.optionalFields.push(getNewFieldObject('', '', false, true));
    };

    function getNewFieldObject(key, value, nonEditable, keyEditable) {
        return {
            key,
            value,
            nonEditable,
            keyEditable,
        };
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('EditCustomWebhookItemModal', EditCustomWebhookItemModal);
