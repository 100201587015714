import ProgressBarEditor from './ProgressBarEditor';
import StatusProgressBarWidget from './StatusProgressBarWidget';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const StatusProgressBarWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: StatusProgressBarWidget,
    configurationComponent: ProgressBarEditor,
};

export default StatusProgressBarWidgetPackage;
