import template from './tnkMicrosoftTeamsAppTeamSelection.html?angularjs';

angular.module('tonkean.app').component('tnkMicrosoftTeamsAppTeamSelection', {
    bindings: {
        integration: '<',
        projectIntegration: '<', // The project integration object.
        onApplicationConnectedToTeam: '&',
    },
    template,
    controller: 'MicrosoftTeamsAppTeamSelectionCtrl',
});
