import React from 'react';
import styled from 'styled-components';

import { SolutionMapperAddOverlayTitle } from './SolutionMapperAddOverlay';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SolutionMapperAddOverlayLoading: React.FC = () => {
    return (
        <Wrapper>
            <SolutionMapperAddOverlayTitle>Adding block...</SolutionMapperAddOverlayTitle>
            <div className="loading-large mod-white" />
        </Wrapper>
    );
};

export default SolutionMapperAddOverlayLoading;
