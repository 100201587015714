import ZendeskAuthenticationComponent from './ZendeskAuthenticationComponent';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { DataSourcesSectionType } from '@tonkean/tonkean-entities';
import { IntegrationEntity } from '@tonkean/tonkean-entities';

class ZendeskIntegration extends IntegrationDefinitionBase {
    override name = 'zendesk';
    override displayName = 'Zendesk';
    override description = '';

    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Ticket', 'Tickets'),
        new IntegrationEntity('Attachment', 'Attachments'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Organization', 'Organizations'),
    ];

    override customizedAuthenticationComponent = ZendeskAuthenticationComponent;
    override supportsSharedCredentials = true;

    override dataSourceSectionType = DataSourcesSectionType.CLOUD;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon('', '130px'),
        new IntegrationIcon('', '130px'),
    );

    override dataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Ticket: true, User: true, Organization: true },
        isOnlyDataRetention: { User: true, Organization: true },
    };
}

export default ZendeskIntegration;
