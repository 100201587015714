import template from './tnkCreditCard.template.html?angularjs';

/**
 * A credit card component that uses Stripe to post the credit card data and passes on a a Stripe token.
 */
export default angular.module('tonkean.app').component('tnkCreditCard', {
    bindings: {
        // The component adds a function called submit() to the syncObject so the parent can tell this component
        // to run its submit logic and call onSubmit once done. This is not a valid component behaviour since the
        // component shouldn't tinker with the one-way binding inner properties. This was chosen as a better solution than
        // passing a "ready" function that will be called with a parameter the parent can use.
        syncObject: '<',
        cardExtraData: '<', // Maintained by the parent scope and should contain address_zip and name when submitting.
        loadingStripeComplete: '&', // Called once stripe is finished being loaded to the page.
    },
    template,
    controller: 'CreditCardCtrl',
});
