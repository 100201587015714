import type { PlateRenderElementProps, Value } from '@udecode/plate';
import { findNodePath, removeNodes, useEditorRef } from '@udecode/plate';
import React from 'react';
import { useFocused, useSelected } from 'slate-react';
import styled from 'styled-components';

import type { CoreEditorVariableElement } from './coreEditorVariableElement';
import TonkeanExpressionBlock from './TonkeanExpressionBlock';

import { XIcon } from '@tonkean/svg';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const RemoveIcon = styled(Clickable)`
    display: inline-flex;
    vertical-align: baseline;
    height: 12px;
    width: 12px;
    padding: 2px;
    margin-left: 3px;

    background: #ffffff;
    border-radius: 100%;

    svg {
        display: flex;
        width: 100%;
        height: 100%;
    }
`;

const CoreEditorTonkeanVariablePlateUi = <V extends Value>({
    children,
    element,
    attributes,
}: PlateRenderElementProps<V, CoreEditorVariableElement>) => {
    const selected = useSelected();
    const focused = useFocused();
    const showFocus = selected && focused;
    const editor = useEditorRef();

    return (
        // need an outer layer with {children, and a contentEditable=false wrapper so slate wont throw exception
        <span {...attributes}>
            <span contentEditable={false}>
                <TonkeanExpressionBlock expression={element.expression} showFocus={showFocus}>
                    <RemoveIcon
                        onClick={(event) => {
                            event.stopPropagation();
                            const path = findNodePath(editor, element);
                            removeNodes(editor, { at: path });
                        }}
                    >
                        <XIcon />
                    </RemoveIcon>
                </TonkeanExpressionBlock>
            </span>
            {children}
        </span>
    );
};

export default CoreEditorTonkeanVariablePlateUi;
