import template from './settings.template.html?angularjs';

export default angular.module('tonkean.app').directive('tnkSettingsState', () => ({
    template,
    controller: 'SettingsCtrl',
    transclude: true,
    scope: {
        setVisiblePages: '&',
        hideSideBar: '<',
    },
}));
