import React, { useCallback, useEffect, useMemo } from 'react';

import SupportUserSettingsToggle from './SupportUserSettingsToggle';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';

const description =
    'On public modules, support access permissions are inherited from the board settings. \n' +
    'By enabling remote support access you are allowing Tonkean support team an administrator access for ' +
    'this module. Access will only be used for support needs and all actions will be logged.';

interface Props {
    groupType: 'public' | 'private';

    onToggle(isSupportUserPermitted: boolean): void;
}

const GroupSupportUserPermissions: React.FC<Props> = ({ groupType, onToggle }) => {
    const [groupId, projectId] = useGetStateParams('g', 'projectId');
    const { data: supportUserInGroupResponse } = useTonkeanService('isSupportUserInGroup', groupId);
    const { data: supportUserInProjectResponse } = useTonkeanService('isSupportUserDisabled', projectId);

    useEffect(
        () => supportUserInGroupResponse && onToggle(supportUserInGroupResponse.isSupportUserInGroup),
        [onToggle, supportUserInGroupResponse],
    );

    const disabledToggle = useMemo(() => {
        return supportUserInProjectResponse?.isSupportUserDisabled || groupType === 'public';
    }, [groupType, supportUserInProjectResponse?.isSupportUserDisabled]);

    const disabledTooltipText = useMemo(() => {
        return supportUserInProjectResponse?.isSupportUserDisabled
            ? 'Enable support access for board before you can update here'
            : 'Changing support access is only possible in private modules';
    }, [supportUserInProjectResponse?.isSupportUserDisabled]);

    const isSupportUserPermitted = useMemo(() => {
        if (supportUserInProjectResponse?.isSupportUserDisabled) {
            return false;
        }

        return supportUserInGroupResponse?.isSupportUserInGroup || groupType === 'public';
    }, [
        groupType,
        supportUserInProjectResponse?.isSupportUserDisabled,
        supportUserInGroupResponse?.isSupportUserInGroup,
    ]);

    const asyncOnToggle = useCallback(
        (newIsSupportUserPermitted) => {
            onToggle(newIsSupportUserPermitted);
            return Promise.resolve();
        },
        [onToggle],
    );

    return supportUserInGroupResponse && supportUserInProjectResponse ? (
        <SupportUserSettingsToggle
            description={description}
            onToggle={asyncOnToggle}
            disabled={disabledToggle}
            isSupportUserPermitted={isSupportUserPermitted}
            disabledTooltip={disabledTooltipText}
        />
    ) : null;
};

export default GroupSupportUserPermissions;
