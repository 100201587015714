import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const AzureBlobStorageComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [accountName, setAccountName] = useState<string>('');
    const [accessKey, setAccessKey] = useState<string>('');

    const authenticateWithClientIdAndSecret = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                accountName,
                accessKey,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${accountName})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithClientIdAndSecret}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="login-api-account-name" className="col-sm-3 control-label">
                            Account Name
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={accountName}
                                className="form-control"
                                id="login-api-account-name"
                                onChange={({ target }) => setAccountName(target.value)}
                                autoComplete="off"
                                placeholder="Account Name"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-access-key" className="col-sm-3 control-label">
                            Access Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={accessKey}
                                className="form-control"
                                onChange={({ target }) => setAccessKey(target.value)}
                                id="login-api-access-key"
                                autoComplete="off"
                                placeholder="Access Key"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AzureBlobStorageComponent;
