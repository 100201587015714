import React from 'react';
import { components } from 'react-select';
import type { InputProps } from 'react-select';
import type { GroupBase } from 'react-select';

import type { PersonSummary } from '@tonkean/tonkean-entities';

const checkIfAnEmptyArray = (value: any) => {
    return Array.isArray(value) && value.length === 0;
};

const CustomInput: React.FC<InputProps<PersonSummary, boolean, GroupBase<PersonSummary>>> = ({
    getValue,
    isMulti,
    ...props
}) => {
    if (props.isDisabled) {
        return null;
    }
    return (
        <components.Input<PersonSummary, boolean, GroupBase<PersonSummary>>
            getValue={getValue}
            isMulti={isMulti}
            data-automation="people-selector-input"
            placeholder={isMulti && !checkIfAnEmptyArray(getValue()) ? 'Search User' : undefined}
            {...props}
        />
    );
};

export default CustomInput;
