import type { PlateEditor } from '@udecode/plate';
import { focusEditor, isEditorFocused, selectEditor } from '@udecode/plate';
import { useCallback, useRef } from 'react';
import type { BaseSelection } from 'slate';

function useCoreEditorCreateRestoreFocus(editor: PlateEditor | undefined) {
    const lastRangeRef = useRef<BaseSelection | undefined>();

    const onBlur = useCallback(() => {
        if (editor?.selection) {
            lastRangeRef.current = editor.selection;
        }
    }, [editor]);

    const restoreFocus = useCallback(() => {
        if (!editor || isEditorFocused(editor)) return;

        try {
            if (lastRangeRef.current) {
                focusEditor(editor, lastRangeRef.current ?? undefined);
            } else {
                selectEditor(editor, { focus: true, edge: 'end' });
            }
        } catch {}
    }, [editor]);

    return { onBlur, restoreFocus };
}

export default useCoreEditorCreateRestoreFocus;
