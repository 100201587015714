import React, { useMemo } from 'react';

import SmartSearchGetInformationSkillCategoryView from './categoriesViews/getInformationViews/SmartSearchGetInformationSkillCategoryView';
import SmartIntakeSearchCategoryView from './categoriesViews/SmartIntakeSearchCategoryView';
import SmartSearchAnalyzeEntityCategoryView from './categoriesViews/SmartSearchAnalyzeEntityCategoryView';
import SmartSearchAssistantCategoryView from './categoriesViews/SmartSearchAssistantResponseCategoryView';
import SmartSearchPerformActionCategoryView from './categoriesViews/SmartSearchPerformActionCategoryView';
import SmartSearchUnknownCategoryView from './categoriesViews/SmartSearchUnknownCategoryView';

import { SmartConversationUserReplyView } from '@tonkean/smart-conversation';
import type {
    SmartConversationMessage,
    SmartConversationUserMessageContent,
    SmartIntakeSearchCategoryResponse,
    SmartSearchAnalyzeEntityCategoryResponse,
    SmartSearchAssistantCategoryResponse,
    SmartSearchGetInformationCategoryResponse,
    SmartSearchPerformActionCategoryResponse,
    SmartSearchUnknownCategoryResponse,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { SmartSearchResponse } from '@tonkean/tonkean-entities';
import type { SmartSearchEntityAnalysisResponse } from '@tonkean/tonkean-entities';

export interface Props {
    message: SmartConversationMessage<SmartSearchResponse>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    previousMessage: SmartSearchResponse | undefined;
    onRequestAnalysis: (data: SmartSearchEntityAnalysisResponse) => void;
    setLoading: (value: boolean) => void;
    onAnimationEnd: () => void;
    allMessages: SmartConversationMessage<SmartSearchResponse>[];
}

const SmartSearchResponseView: React.FC<Props> = ({
    message,
    projectId,
    previousMessage,
    onRequestAnalysis,
    setLoading,
    onAnimationEnd,
    allMessages,
}) => {
    const responseViewComponent = useMemo(() => {
        if (message.response?.userResponse) {
            return (
                <SmartConversationUserReplyView
                    message={message as SmartConversationMessage<SmartConversationUserMessageContent>}
                />
            );
        }

        switch (message.response.skill) {
            case 'PERFORM_ACTION':
                return (
                    previousMessage?.userResponse && (
                        <SmartSearchPerformActionCategoryView
                            projectId={projectId}
                            message={message as SmartConversationMessage<SmartSearchPerformActionCategoryResponse>}
                            onAnimationEnd={onAnimationEnd}
                            userQuestion={previousMessage.text}
                            setLoading={setLoading}
                        />
                    )
                );

            case 'GET_INFORMATION':
                return (
                    previousMessage?.userResponse && (
                        <SmartSearchGetInformationSkillCategoryView
                            projectId={projectId}
                            message={message as SmartConversationMessage<SmartSearchGetInformationCategoryResponse>}
                            userQuestion={previousMessage.text}
                            onRequestAnalysis={onRequestAnalysis}
                            setLoading={setLoading}
                            onAnimationEnd={onAnimationEnd}
                        />
                    )
                );

            case 'ANALYZE_ENTITY':
                return (
                    <SmartSearchAnalyzeEntityCategoryView
                        message={message as SmartConversationMessage<SmartSearchAnalyzeEntityCategoryResponse>}
                        onAnimationEnd={onAnimationEnd}
                    />
                );

            case 'UNKNOWN':
                return (
                    <SmartSearchUnknownCategoryView
                        message={message as SmartConversationMessage<SmartSearchUnknownCategoryResponse>}
                        onAnimationEnd={onAnimationEnd}
                    />
                );

            case 'ASSISTANT':
                return (
                    <SmartSearchAssistantCategoryView
                        projectId={projectId}
                        message={message as SmartConversationMessage<SmartSearchAssistantCategoryResponse>}
                        onAnimationEnd={onAnimationEnd}
                        allMessages={allMessages}
                    />
                );
            case 'SMART_INTAKE_ASSISTANT':
                return (
                    <SmartIntakeSearchCategoryView
                        message={message as SmartConversationMessage<SmartIntakeSearchCategoryResponse>}
                        onAnimationEnd={onAnimationEnd}
                    />
                );
        }
    }, [
        message,
        previousMessage?.userResponse,
        previousMessage?.text,
        projectId,
        onAnimationEnd,
        setLoading,
        onRequestAnalysis,
        allMessages,
    ]);

    return <>{responseViewComponent}</>;
};

export default SmartSearchResponseView;
