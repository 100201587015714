import template from './tnkExampleItemEvaluatedExpression.template.html?angularjs';

angular.module('tonkean.app').component('tnkExampleItemEvaluatedExpression', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        logicId: '@',
        expressionUniqueIdentifier: '@',
        doNotEvaluatePreview: '<', // When false, will evaluate the expression for preview.
        overrideExampleInitiativeId: '<', // an example initiative for the preview.
        hidePreview: '<', // weather to show preview by default
        htmlPreview: '<',
        expressionToEvaluate: '<',
        fieldIdToEvaluate: '<',
        hideIcon: '<',
        isInline: '<',
        color: '@',
        customPreview: '<',
        showPeopleDirectories: '<',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'ExampleItemEvaluatedExpressionCtrl',
});
