import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';

import { useAsyncMethod, useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { FormPageState } from '@tonkean/angular-to-react-components';
import { useIsDataRestricted } from '@tonkean/infrastructure';
import { NotFoundPage } from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import type { Form, FormReferralData, Initiative, Project } from '@tonkean/tonkean-entities';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap, WorkflowVersionType } from '@tonkean/tonkean-entities';

const TonkeanForm: React.FC = () => {
    const projectManager = useAngularService('projectManager');
    const formManager = useAngularService('formManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const customFieldsManager = useAngularService('customFieldsManager');
    const trackHelper = useAngularService('trackHelper');

    const [projectId, formId, formVersionType, initiativeId] = useGetStateParams<
        [Project['id'], Form['id'], 'draft' | 'production', Initiative['id']]
    >('projectId', 'formId', 'formVersionType', 'initiativeId');

    const workflowVersionType =
        formVersionType?.toLocaleLowerCase() === 'draft' ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED;

    const projectResponse = useAsyncMethod(projectManager, 'selectProject', projectId);

    const [formResponse, getForm] = useLazyAsyncMethod(formManager, 'getFormByWorkflowVersionType');

    const [groupResponse, getGroup] = useLazyAsyncMethod(groupInfoManager, 'getGroup');

    useEffect(() => {
        if (projectResponse?.data?.id && formId?.startsWith('FORM') && workflowVersionType) {
            const referralData: FormReferralData = { referringInitiative: initiativeId };
            getForm(formId, workflowVersionType, referralData);
        }
    }, [formId, getForm, workflowVersionType, projectResponse?.data?.id, initiativeId]);

    useEffect(() => {
        if (formResponse?.data?.group) {
            getGroup(formResponse.data.group.id, undefined, false);
        }
    }, [formResponse?.data, getGroup]);

    const [workflowVersionResponse, getWorkflowVersions] = useLazyAsyncMethod(
        workflowVersionManager,
        'getFromServerAndCacheWorkflowVersions',
    );

    useEffect(() => {
        if (groupResponse.data) {
            getWorkflowVersions(projectId, [
                groupResponse.data.draftWorkflowVersionId,
                groupResponse.data.publishedWorkflowVersionId,
            ]);
        }
    }, [getWorkflowVersions, groupResponse.data, projectId]);

    const [fieldDefinitionsResponse, getFieldDefinitions] = useLazyAsyncMethod(
        customFieldsManager,
        'getFieldDefinitionsByIds',
    );

    useEffect(() => {
        if (formResponse?.data?.workflowVersion) {
            const formFieldIdentifiers = formResponse?.data.definition.fields
                .filter((field) =>
                    field.fieldDefinitionIdentifier.startsWith(
                        tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION],
                    ),
                )
                .map((field) => field.fieldDefinitionIdentifier);

            getFieldDefinitions(
                formResponse?.data.workflowVersion.id,
                workflowVersionType,
                formFieldIdentifiers,
                projectId,
            );
        }
    }, [
        formResponse?.data?.definition.fields,
        formResponse?.data?.workflowVersion,
        formVersionType,
        getFieldDefinitions,
        workflowVersionType,
        projectId,
    ]);

    const fillIdsStockResponse = useAsyncMethod(trackHelper, 'fillIdsStock', 50, true);

    const responses = [
        projectResponse,
        formResponse,
        groupResponse,
        workflowVersionResponse,
        fieldDefinitionsResponse,
        fillIdsStockResponse,
    ];

    const loading = responses.some((response) => response.loading) && projectResponse?.['called'];
    // If this is loading an interface and not a form, the rest of the data will be loaded in inner components

    if (useIsDataRestricted([formResponse.error?.status])) {
        return <NotFoundPage text="Form not found." dataAutomation="form-not-found" />;
    }

    if (loading) {
        return <LoadingTonkean />;
    }

    return <FormPageState />;
};

export default TonkeanForm;
