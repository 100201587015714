import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import WorkflowVersionInfo from '../../../../components/common/WorkflowVersionsHistoryTab/WorkflowVersionInfo';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Placeholder, Popover } from '@tonkean/infrastructure';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';

export const UnderlinedTextButton = styled.button`
    display: inline-flex;
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    text-align: initial;

    ${childrenStyledFocus}
`;

interface Props {
    workflowVersionId: string;
}

const SingleWorkerRunWorkflowVersionPopover: React.FC<Props> = ({ workflowVersionId }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const { data, error, loading } = useAsyncMethod(
        workflowVersionManager,
        'getCachedWorkflowVersionOrGetFromServer',
        workflowVersionId,
    );

    const [versionOpened, setVersionOpened] = useState(false);

    const toggleOpen = () => {
        setVersionOpened(!versionOpened);
    };

    if (loading) {
        return <Placeholder $height={FontSize.SMALL_12} $width="150px" />;
    }

    if (!error && data) {
        return (
            <Popover
                show={versionOpened}
                onClose={() => setVersionOpened(false)}
                placement="top"
                size={TooltipSize.MEDIUM}
                color={TooltipColor.WHITE}
                content={<WorkflowVersionInfo workflowVersion={data} />}
                noPadding
            >
                <UnderlinedTextButton data-automation="flow-run-work-flow-version-version-details" onClick={toggleOpen}>
                    {data.workflowVersionType === 'DRAFT' ? 'Build version' : `v${data.sequentialIdentifier}`}
                </UnderlinedTextButton>
            </Popover>
        );
    }

    return <>Error loading version</>;
};

export default SingleWorkerRunWorkflowVersionPopover;
