import Utils from '@tonkean/utils';
import { InboxItemActions, InboxItemActionTypes } from '../actions/InboxItemActions';
import InboxItemDispatcher from '../dispatchers/InboxItemDispatcher';
import { TrackActionTypes } from '@tonkean/flux';
import { TrackDispatcher } from '@tonkean/flux';
import { TonkeanStore } from '@tonkean/shared-services';

/**
 * The main track store.
 * Since we are still in an AngularJS app, the store doesn't actively manage it's data, but delegates everything to the outside component.
 */
class InboxItemStore extends TonkeanStore {
    /* @ngInject */

    constructor(botHistoryHelper, trackHelper, projectManager) {
        // Initialize our super with the dispatchers we need.
        super([InboxItemDispatcher, TrackDispatcher]);

        this.botHistoryHelper = botHistoryHelper;
        this.trackHelper = trackHelper;
        this.projectManager = projectManager;
    }

    getNewState(id) {
        // Reset the state of the given id to a new one: requesting a new state means the component was re-constructed.
        const item = this.botHistoryHelper.getItem(id);

        this.states[id] = {
            initiativeId: item.initiative.id,

            isLoading: false,
            askLaterDropdownOpen: false,
        };

        // Return the state to the component.
        return this.states[id];
    }

    getState(id) {
        return this.states[id];
    }

    /**
     * A helper function for our components.
     * @param itemId - the component's id.
     * @param requestedId - the idsObject the component has received via the store's emit function
     * @returns {boolean} - true if the component should re-render itself.
     */
    shouldItemUpdate(itemId, requestedId) {
        // If there's no requested id, all items should re-render.
        if (!requestedId) {
            return true;
        }

        // The specified id and editor id match this tracks ids - re-render.
        if (itemId === requestedId) {
            return true;
        }

        return false;
    }

    /**
     * The listener function that waits for our dispatcher to dispatch a new update.
     * @param action - the action the dispatcher dispatched.
     */
    onDispatch(action) {
        switch (action.type) {
            case InboxItemActionTypes.TOGGLE_LOADING:
                this.states[action.id].isLoading = action.isTrue;
                this.emit(action.id);
                break;

            case InboxItemActionTypes.TOGGLE_ASK_LATER_DROPDOWN:
                this.states[action.id].askLaterDropdownOpen = action.isTrue;
                this.emit(action.id);
                break;

            case TrackActionTypes.TRACK_NEXT_GATHER_UPDATES_UPDATED:
                const states = Utils.objToArray(this.states);

                for (const kvp of states) {
                    if (kvp.value.initiativeId === action.id) {
                        InboxItemActions.toggleLoading(kvp.key, true);
                    }
                }
                break;

            case TrackActionTypes.TRACK_STATUS_UPDATED_COMPLETED:
            case TrackActionTypes.TRACK_NEXT_GATHER_UPDATE_COMPLETED:
                const states2 = Utils.objToArray(this.states);

                // Reload items that have initiativeId the same as the on in action.
                for (const kvp of states2) {
                    if (kvp.value.initiativeId === action.id) {
                        this.botHistoryHelper.reloadItem(kvp.key);
                    }
                }

                break;
        }
    }
}

export default angular.module('tonkean.app').service('inboxItemStore', InboxItemStore);
