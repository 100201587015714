function FieldInspectModalCtrl(
    $scope,
    projectIntegration,
    externalType,
    searchQuery,
    onFieldSelectCallback,
    staticData,
    onlyUpdatable,
    exampleEntity,
    initiativeId,
    useTracksOfGroupId,
    fieldFilter,
    workflowVersionId,
    onlyItemsFromSelectedEnvironment,
    multiSelect,
    shouldHaveEntityPrefix,
    trackHelper,
    idRelationFieldDefinitionId,
    creatingCustomTriggerId,
    performOnWorkerItem,
    isForMatchedItem,
    externalTypeDisplayName,
) {
    $scope.data = {
        projectIntegration,
        externalType,
        searchQuery,
        staticData,
        onlyUpdatable,
        exampleEntity,
        useTracksOfGroupId,
        shouldHaveEntityPrefix,
        viewOnly: !onFieldSelectCallback,
        fieldFilter,
        workflowVersionId,
        onlyItemsFromSelectedEnvironment,
        multiSelect,
        initiativeId,
        loading: false,
        isError: false,
        idRelationFieldDefinitionId,
        creatingCustomTriggerId,
        performOnWorkerItem,
        isForMatchedItem,
        externalTypeDisplayName,
    };

    $scope.onFieldSelected = function (
        field,
        dontNotifyChange,
        idRelationFieldDefinitionId,
        creatingCustomTriggerId,
        performOnWorkerItem,
    ) {
        // Close the modal and call the onFieldSelect callback.
        $scope.$close();
        if (onFieldSelectCallback) {
            onFieldSelectCallback(
                field,
                dontNotifyChange,
                idRelationFieldDefinitionId,
                creatingCustomTriggerId,
                performOnWorkerItem,
            );
        }
    };

    $scope.init = function () {
        if (initiativeId) {
            $scope.data.loading = true;
            trackHelper
                .getInitiativeById(initiativeId)
                .then((initiative) => {
                    $scope.data.exampleEntity = initiative;
                })
                .catch(() => {
                    $scope.data.isError = true;
                })
                .finally(() => {
                    $scope.data.loading = false;
                });
        }
    };

    $scope.onModalEscClose = function () {
        $scope.$close();
    };

    $scope.init();
}

angular.module('tonkean.app').controller('FieldInspectModalCtrl', FieldInspectModalCtrl);
