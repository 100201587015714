<div>
    <!-- Working On -->
    <div
        ng-if="data.configuredLogic.node.customTriggerType !== 'MONITOR_TRACKS'"
        class="text-center logics-part-container-working-on common-size-xxxxs"
    >
        <span class="common-bold margin-right-xs">Working on</span>
        <span class="common-color-light-grey">{{ data.workingOn }}</span>
    </div>
    <div class="padding-normal-sm">
        <div>
            <div class="flex-vmiddle">
                <div class="logic-template-icon mod-sm margin-right mod-sm mod-update-field"></div>
                <div class="margin-right">Update Status</div>
            </div>
        </div>

        <!-- Logic type configuration -->
        <div>
            <hr class="common-separator mod-darker margin-top margin-bottom" />
            <div class="common-color-grey">Update the item status as part of the logic for this action block.</div>
        </div>

        <hr class="common-separator mod-darker margin-top margin-bottom" />

        <!-- View Only Mode -->
        <div class="margin-bottom" ng-if="data.viewOnlyMode">
            <!-- Update Status Summary -->
            <div ng-if="data.stateConfiguration.updateToStatus">
                <!-- Title -->
                <div class="margin-right">
                    When finished, update
                    <strong>Tonkean Item Status</strong>
                    to:
                </div>

                <div class="flex-vmiddle">
                    <i
                        class="fa fa-square margin-right"
                        ng-style="{ color: data.stateConfiguration.updateToStatus.color }"
                    ></i>
                    <div>{{ data.stateConfiguration.updateToStatus.label }}</div>
                </div>
            </div>

            <div ng-if="!data.stateConfiguration.updateToStatus">
                <!-- Title -->
                <div class="margin-right">You haven't configured update status for this logic yet.</div>
            </div>
        </div>

        <!-- Configuration -->
        <div ng-if="!data.viewOnlyMode">
            <!-- Status selection -->
            <div class="margin-bottom">
                <!-- Status selection title -->
                <div class="flex flex-vmiddle margin-bottom-xs">
                    <!-- Title -->
                    <div class="common-title-xxs">Update Item Status To</div>

                    <div class="flex-grow"></div>

                    <!-- Clear -->
                    <div>
                        <a ng-click="clearSelectedStatus()">Clear</a>
                    </div>
                    <!-- Loading -->
                    <div class="loading-small" ng-if="data.loadingSavingLogicState"></div>
                </div>

                <div class="margin-top">
                    <!-- Status selection dropdown -->
                    <ui-select
                        theme="bootstrap"
                        ng-model="data.stateConfiguration.updateToStatus"
                        ng-change="logicUpdateToStateUpdated(data.stateConfiguration)"
                        class="margin-bottom"
                        data-automation="tnk-post-status-update-logic-configuration-template-status-trigger-dropdown"
                    >
                        <ui-select-match placeholder="Search...">
                            <div class="flex-vmiddle" data-automation="tnk-post-status-update-logic-configuration-template-status-selected">
                                <i
                                    class="fa fa-square margin-right"
                                    ng-style="{ color: data.stateConfiguration.updateToStatus.color }"
                                ></i>
                                <div>{{ data.stateConfiguration.updateToStatus.label }}</div>
                            </div>
                        </ui-select-match>
                        <ui-select-choices
                            repeat="p in wvm.getCachedWorkflowVersion(data.workflowVersionId).states | filter:$select.search"
                            group-by="groupByType"
                        >
                            <div
                                class="flex-vmiddle"
                                data-automation="tnk-post-status-update-logic-configuration-template-select-status-{{ p.label }}"
                            >
                                <i
                                    class="logic-worker-status-color-box margin-right-xs"
                                    ng-style="{ 'background-color': p.color }"
                                ></i>
                                <div ng-bind-html="p.label | escapeHtml | highlight: $select.search"></div>
                            </div>
                        </ui-select-choices>
                    </ui-select>

                    <div
                        ng-if="data.invalidLogics[data.configuredLogic.node.id].noAutoCloseState"
                        class="margin-bottom-xs common-color-danger"
                    >
                        {{ data.invalidLogics[data.configuredLogic.node.id].noAutoCloseState }}
                    </div>

                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        saved-original-expression="data.stateConfiguration.whenFinishedUpdateText"
                        saved-evaluated-expression="data.stateConfiguration.evaluatedWhenFinishedUpdateText"
                        placeholder="Update Text..."
                        mod-text-area="true"
                        text-area-number-of-rows="6"
                        on-tonkean-expression-changed="
                            onTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                expressionIdentifier,
                                shouldSaveLogic
                            )
                        "
                    ></tnk-tonkean-expression>

                    <div class="margin-bottom-lg margin-top-lg">
                        <label>
                            <input
                              ng-disabled="!data.stateConfiguration.updateToStatus"
                              type="checkbox"
                              ng-model="data.stateConfiguration.applyStatusUpdateToInnerItems"
                              ng-change="logicUpdateToStateUpdated(data.stateConfiguration)"
                            />
                            <span class="common-normal-weight">Apply this status update to inner items</span>
                        </label>
                    </div>

                    <!-- Error state -->
                    <div class="margin-top-xs common-size-xxxs common-color-danger" ng-if="data.errorSavingLogicState">
                        {{ data.errorSavingLogicState }}
                    </div>

                    <!-- Separator -->
                    <hr />

                    <!-- Fields selection title -->
                    <div ng-if="data.showFieldsToUpdate">
                        <div>
                            <div class="common-title-xxs">Update Fields</div>
                            <span class="common-size-xxs common-color-grey3">
                                Select fields you would like to update at this point.
                            </span>
                        </div>

                        <tnk-multiple-field-to-update-editors
                          logic-id="{{ data.configuredLogic.node.id }}"
                          field-selector-group-id="{{ data.groupId }}"
                          field-selector-workflow-version-id="{{ data.workflowVersionId }}"
                          expression-group-id="{{ data.groupId }}"
                          expression-workflow-version-id="{{ data.workflowVersionId }}"
                          configured-fields="data.stateConfiguration.stateUpdaterFieldsToUpdate"
                          special-fields-for-features="['WORKER_UPDATE_FIELD']"
                          preview-evaluation-source="$data.previewEvaluationSource"
                          reload-fields="data.reloadFields"
                          validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                          on-configured-fields-changed="onConfiguredFieldsChanged(configuredFields, shouldSaveLogic)"
                          allow-delete-of-last-field="true"
                        ></tnk-multiple-field-to-update-editors>

                        <!-- Separator -->
                        <hr />
                    </div>

                    <!-- Fields selection title -->
                    <div>
                        <div class="common-title-xxs">Updater</div>
                        <span class="common-size-xxs common-color-grey3">
                            Choose who would be the updater of the item status
                        </span>
                    </div>

                    <!-- Selecting notification method -->
                    <tnk-person-selection-configuration
                        class="block"
                        support-quick-create="true"
                        radio-id-prefix="'state-updater-' + data.workflowVersionId"
                        allow-none="true"
                        none-option-label="'Default actor (' + pm.groupsMap[data.groupId].defaultActor.name + ')'"
                        max-people="1"
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        hide-fail-if-no-value="true"
                        configured-logic="data.configuredLogic"
                        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                        unique-identifier="creator"
                        saved-person-selection-configuration="data.stateConfiguration.existingStatusUpdaterPersonSelectionConfiguration"
                        on-person-selection-configuration-changed="
                            onStatusUpdaterPersonSelectionConfigurationChanged(
                                personSelectionConfiguration,
                                shouldSaveLogic
                            )
                        "
                        enable-choosing-tonkean="true"
                    ></tnk-person-selection-configuration>
                </div>
            </div>

            <!-- Separator -->
            <hr />

            <!-- Notification Settings -->
            <div>
                <div class="common-title-xxs">Notification Settings</div>
                <span class="common-size-xxs common-color-grey3">
                    Define whether track update notification should be sent (only in production environment)
                </span>
            </div>
            <div class="margin-bottom-lg margin-top-lg">
                <label>
                    <input
                        type="checkbox"
                        ng-model="data.stateConfiguration.shouldSendNotificationOnStatusUpdate"
                        ng-change="logicUpdateToStateUpdated(data.stateConfiguration)"
                    />
                    <span class="common-normal-weight">Send notifications</span>
                </label>
            </div>

            <!-- Separator -->
            <hr />

            <!-- Statuses Configuration -->
            <div class="margin-bottom-lg margin-top">
                <div class="margin-bottom-lg">
                    <div class="flex-vmiddle">
                        <span class="flex-grow">What statuses are possible?</span>
                        <a ng-click="$root.$broadcast('selectWorkflowOutlineTab', { tabKey: 'statuses' })">Edit</a>
                    </div>
                </div>
            </div>

            <!-- Separator -->
            <hr ng-if="scm.getSyncConfig(data.workflowVersionId).viewData" />

            <!-- Update status in external system -->
            <div ng-if="scm.getSyncConfig(data.workflowVersionId).viewData" class="margin-bottom">
                <!-- Title -->
                <div class="margin-bottom-xs">
                    Update
                    <strong>{{ scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.label }}</strong>
                    status in
                    <strong>{{ scm.getSyncConfig(data.workflowVersionId).projectIntegration.displayName }}</strong>
                    to:
                </div>

                <!-- Status input -->
                <input
                    type="text"
                    class="form-control"
                    ng-model="data.stateConfiguration.externalStatusValue"
                    ng-blur="logicUpdateToStateUpdated(data.stateConfiguration)"
                />
            </div>
        </div>
    </div>
</div>
