import { analyticsWrapper } from '@tonkean/analytics';
import messageBoxModalTemplate from './../../messageBoxModal/messageBoxModal.template.html?angularjs';

export function EmailWebhookModalCtrl(
    $scope,
    $uibModal,
    utils,
    entityHelper,
    projectManager,
    modalUtils,
    tonkeanService,
    requestThrottler,
    integrations,
) {
    $scope.modalUtils = modalUtils;
    $scope.infoStates = {}; // Will hold the show/hide state of the info buttons.

    $scope.data = {
        // Webhook definition details
        incomingWebhook: {
            displayName: null,
            definition: {
                pickOffChildKey: null,
                idFieldPath: null,
                titleFieldPath: null,
                ownerEmailFieldPath: null,
                ownerNameFieldPath: null,
                parentNameFieldPath: null,
                descriptionFieldPath: null,
                tagsFieldPath: null,
                urlFieldPath: null,
                additionalFieldMappings: {
                    ID: [],
                    TITLE: [],
                    PARENT_NAME: [],
                    OWNER_EMAIL: [],
                    OWNER_NAME: [],
                    DESCRIPTION: [],
                    TAGS: [],
                    URL: [],
                },
            },
        },
        projectIntegration: null,
        storageIntegration: null,
        reloadFieldSelectorOptions: false,

        // Form
        definitionForm: null,

        // API
        errorMessage: null,
        loadingWebhook: null,
        loadingEditMode: null,
        loadingDeleteWebhook: null,
        loadingPickOffChildKey: null,
        loadingWebhookRefresh: null,
        loadingStorageIntegration: null,

        // Polling
        webhookReceivedItems: false,
        webhookReceivedEntityJson: null,
        getIncomingWebhookStatusTimeout: false,
        getIncomingWebhookStatusTimeLimit: null,

        // Mode
        editMode: false,
        waitingForMetadataMode: false,
        postingFeedback: false,
        displayAdvancedConfiguration: false,
        displayEditModeSettings: false,
        displayReceivedEntityJson: false,
        showWebhookReceivedJSON: false,
        hideDeleteButton: false,
        hideWebhookFirstItemError: false,

        // State
        state: null,

        // type
        sourceType: 'csv',

        filterByIntegrationsNames: [],
        filterByIntegrationsDisplayName: [],
    };

    /**
     * Init function.
     * @param settings
     */
    $scope.init = function (settings) {
        const supportedUploadingStorageIntegrations = integrations.getSupportedUploadingStorageIntegrations();
        $scope.data.filterByIntegrationsNames = Object.keys(supportedUploadingStorageIntegrations);
        $scope.data.filterByIntegrationsDisplayName = Object.values(supportedUploadingStorageIntegrations).join('/');

        let state;
        if (settings.state) {
            state = angular.copy(settings.state);
        } else if (settings.originalState) {
            state = angular.copy(settings.originalState);
        } else {
            state = {};
        }

        if (state.integrations && state.integrations[0]) {
            // Edit mode.
            $scope.data.editMode = true;
            initializeEditMode(state);
        } else {
            // Create mode.
            // Add your code here...
        }

        if (settings.webhookSource) {
            $scope.data.webhookSource = settings.webhookSource;
            $scope.data.incomingWebhook.displayName = $scope.data.webhookSource.name;
        }

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.data.hideDeleteButton = settings.hideDeleteButton;
    };

    /**
     * Exists the modal.
     */
    $scope.cancel = function () {
        if (!$scope.data.projectIntegration || $scope.data.projectIntegration.disabled) {
            if ($scope.integrationConfigurationCanceled) {
                $scope.integrationConfigurationCanceled();
            }
            $scope.$dismiss();
        } else {
            notifyIntegrationCreatedOrUpdated();
        }
    };

    /**
     * Copies the url in the given element id to clipboard.
     * @param elementId - the element id in which the webhook url is.
     */
    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    /**
     * Creates the new webhook (which also creates an integration and a project integration.
     */
    $scope.createOrEditWebHook = function () {
        if (!$scope.data.definitionForm.$valid) {
            return;
        }

        if ($scope.data.editMode) {
            // updateWebhook();
        } else {
            createWebhook();
        }
    };

    $scope.onStorageProjectIntegrationSelected = function (selectedProjectIntegration) {
        let storageIntegrationId = null;
        if (selectedProjectIntegration) {
            $scope.data.storageIntegration = selectedProjectIntegration;
            storageIntegrationId = $scope.data.storageIntegration.id;
        } else {
            $scope.data.storageIntegration = null;
        }

        if ($scope.data.editMode) {
            $scope.data.loadingStorageIntegration = true;
            tonkeanService
                .updateProjectIntegrationStorageIntegration($scope.data.projectIntegration.id, storageIntegrationId)
                .finally(() => ($scope.data.loadingStorageIntegration = false));
        }
    };

    /**
     * Deletes the currently being configured webhook.
     */
    $scope.deleteIncomingWebhook = function () {
        analyticsWrapper.track('Deleting a webhook', { category: 'Webhook' });

        $scope.mboxData = {
            title: `Remove ${$scope.data.incomingWebhook.displayName} Data Source`,
            body: 'You’ll stop receiving live data updates from this data source to your modules once you remove it.',
            isWarn: true,
            okLabel: 'Remove',
            cancelLabel: 'Cancel',
        };

        $uibModal
            .open({
                template: messageBoxModalTemplate,
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(function () {
                $scope.data.errorMessage = null;
                $scope.data.loadingDeleteWebhook = true;

                return tonkeanService
                    .deleteIncomingWebhook($scope.data.incomingWebhook.id)
                    .then(function (data) {
                        projectManager.project.integrations = data.integrations;
                        entityHelper.enrichEntity(projectManager.project);
                        $scope.cancel();
                    })
                    .catch(function (error) {
                        handleApiError(error);
                    })
                    .finally(function () {
                        $scope.data.loadingDeleteWebhook = false;
                    });
            });
    };

    /**
     * Notifies about a new integration added or updated.
     */
    function notifyIntegrationCreatedOrUpdated() {
        const data = {
            createdProjectIntegration: $scope.data.projectIntegration,
            integration: $scope.data.projectIntegration,
            integrations: [
                {
                    integration: $scope.data.projectIntegration,
                },
            ],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(data);
        }
    }

    /**
     * Creates a new webhook.
     */
    function createWebhook() {
        analyticsWrapper.track('Creating a webhook', { category: 'Webhook' });
        window.Intercom('trackEvent', 'Create Webhook');

        $scope.data.errorMessage = null;
        $scope.data.loadingWebhook = true;

        // Get the webhook's icon url (if a source was selected).
        const webhookIconUrl =
            $scope.data.webhookSource && $scope.data.webhookSource.imgUrl ? $scope.data.webhookSource.imgUrl : null;

        const storageIntegrationId = $scope.data.storageIntegration ? $scope.data.storageIntegration.id : null;
        return tonkeanService
            .createIncomingEmailWebhook(
                projectManager.project.id,
                $scope.data.incomingWebhook.displayName,
                webhookIconUrl,
                storageIntegrationId,
                $scope.data.storageIntegrationConfiguration,
            )
            .then(function (data) {
                $scope.data.incomingWebhook = data.incomingWebhook;
                $scope.data.projectIntegration = data.projectIntegration;
                $scope.data.displayEditModeSettings = false;

                // Adding the newly created project integration to the project's integrations
                projectManager.project.integrations.push(data.projectIntegration);
                entityHelper.enrichEntity(projectManager.project);
            })
            .catch(function (error) {
                handleApiError(error);
            })
            .finally(function () {
                $scope.data.loadingWebhook = false;
            });
    }

    /**
     * Initializes the edit mode if needed.
     */
    function initializeEditMode(state) {
        $scope.data.loadingEditMode = true;
        const projectIntegration = state.integrations && state.integrations.length ? state.integrations[0] : state;

        tonkeanService
            .getIncomingWebhookByProjectIntegrationId(projectManager.project.id, projectIntegration.id)
            .then(function (data) {
                $scope.data.incomingWebhook = data.incomingWebhook;
                $scope.data.storageIntegrationConfiguration = projectIntegration.storageIntegrationConfiguration;
                $scope.data.projectIntegration = data.projectIntegration;

                if ($scope.data.incomingWebhook && $scope.data.incomingWebhook.metadataEvaluated) {
                    updateCachedProjectIntegration($scope.data.projectIntegration);
                } else {
                    $scope.data.waitingForMetadataMode = true;
                }

                if ($scope.data.projectIntegration.storageIntegrationId) {
                    $scope.data.storageIntegration = projectManager.getProjectIntegrationById(
                        $scope.data.projectIntegration.storageIntegrationId,
                    );
                }
            })
            .catch(function (error) {
                handleApiError(error);
            })
            .finally(function () {
                $scope.data.loadingEditMode = false;
            });
    }

    /**
     * Finds the given updated project integration in the pm.integrations and updates its display name and disabled mode.
     * @param updatedProjectIntegration - the updated project integration to take the updated data from.
     */
    function updateCachedProjectIntegration(updatedProjectIntegration) {
        // Updating the project integrations with new names and disabled status.
        for (let i = 0; i < projectManager.project.integrations.length; i++) {
            const projectIntegration = projectManager.project.integrations[i];

            if (projectIntegration.id === updatedProjectIntegration.id) {
                projectIntegration.displayName = updatedProjectIntegration.displayName;
                projectIntegration.disabled = updatedProjectIntegration.disabled;
                projectIntegration.storageIntegrationConfiguration =
                    updatedProjectIntegration.storageIntegrationConfiguration;
                break;
            }
        }
    }

    /**
     * Handles an error response from an API call and writes it to the UI.
     * @param error - the error response from the API call.
     */
    function handleApiError(error) {
        $scope.data.errorMessage = error;

        if (error && error.data && error.data.error && error.data.error.message) {
            $scope.data.errorMessage = error.data.error.message;
        }
    }

    $scope.onInnerPathEdited = function () {
        requestThrottler.do(
            'updateProjectIntegrationStorageIntegrationConfiguration',
            200,
            () =>
                tonkeanService.updateProjectIntegrationStorageIntegrationConfiguration(
                    $scope.data.projectIntegration.id,
                    $scope.data.storageIntegrationConfiguration,
                ),
            (updatedProjectIntegration) => updateCachedProjectIntegration(updatedProjectIntegration),
            () => {
                $scope.data.errorMessage = 'There was an error trying to update inner path.';
            },
        );
    };

    $scope.onStorageConfigurationDynamicParametersChanged = function (storageConfiguration) {
        $scope.data.storageIntegrationConfiguration = {
            ...$scope.data.storageIntegrationConfiguration,
            dynamicParameters: storageConfiguration,
        };
    };

    $scope.copyToClipboard = () => {
        const copyResult = utils.copyToClipboardFromText($scope.data.incomingWebhook.definition.titleFieldPath);
        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'Inbox address copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };
}

export default angular.module('tonkean.shared').controller('EmailWebhookModalCtrl', EmailWebhookModalCtrl);
