import type { IntegrationEntityInterface } from './PeopleDirectory';

/**
 * Represents an entity that is supported in an integration.
 */
class IntegrationEntity implements IntegrationEntityInterface {
    constructor(
        /**
         * The entity name. For example: Ticket.
         */
        public entityName: string,

        /**
         * The entity plural name. For example: Tickets.
         */
        public pluralLabel: string,

        /**
         * The entity name.
         */
        public entity?: string,
    ) {}
}

export default IntegrationEntity;
