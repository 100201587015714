import BitBucketSetupComponent from './BitBucketSetupComponent';
import bitbucketImage from '../../../../apps/tracks/images/bitbucket.png';
import bitbucketCircleImage from '../../../../apps/tracks/images/integrations/bitbucket-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class BitbucketIntegration extends IntegrationDefinitionBase {
    override name = 'bitbucket';
    override displayName = 'Bitbucket';
    override description = 'Connect your BitBucket source control.';
    override authenticationType = AuthenticationMethod.OAUTH;
    override entities = [new IntegrationEntity('PullRequest', 'Pull Requests')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(bitbucketCircleImage, '130px'),
        new IntegrationIcon(bitbucketImage, '130px'),
    );

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const clientId = environment.integrationKeysMap.bitbucket;
        const redirectUriState = oAuthHandler.publicGetState();
        const url = `https://bitbucket.org/site/oauth2/authorize?client_id=${clientId}&response_type=code&state=${redirectUriState}`;
        const code = await oAuthHandler.startOAuth(url, redirectUriState);
        return { code, undefined };
    }

    override customizedSetupComponent = BitBucketSetupComponent;
}

export default BitbucketIntegration;
