import isValidSyncConfigTypeLikeInput from './isValidSyncConfigTypeLikeInput';

/**
 * Whether the workflow data storage configuration is valid.
 * Example - The user selected thw workflow option and choose the root monitored item to perform the action on.
 * If the module sync config (= integration type) is different than the action required type we can't
 * perform this action. We can't use Google Drive File (entity) as Root Monitored Item
 * when the running item in the module is 'Row' entity of Google sheet.
 */
const isValidWorkflowDataStorageConfiguration = (
    fileSource,
    group,
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    projectManager,
): boolean => {
    if (
        fileSource?.fileSourceType === 'DATA_STORAGE' &&
        fileSource.extendedMatchConfiguration?.extendedMatchConfigurationType === 'WORKFLOW'
    ) {
        const matchConfiguration = fileSource?.extendedMatchConfiguration?.matchConfiguration;
        const storageIntegration = projectManager.getProjectIntegrationById(fileSource?.storageIntegrationId);

        if (matchConfiguration?.performOnWorkerItem) {
            return isValidSyncConfigTypeLikeInput(
                workflowVersionInfoRetriever,
                group,
                syncConfigCacheManager,
                storageIntegration,
            );
        }
    }

    return true;
};

export default isValidWorkflowDataStorageConfiguration;
