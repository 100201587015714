<div class="common-close-btn-container">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>
    <tnk-function-card
        class="mod-no-max"
        hide-deliverables="true"
        initial-state="funcSelectedState"
        edit-mode="true"
        hide-activity="funcHideActivity"
        initiative="initiativeClicked"
        skip-update-text-step="skipUpdateTextStep"
        update-initiative="updateInitiativeData(data,dontLoadActivity)"
        current-group-id="groupId"
        solution-business-report-id="data.solutionBusinessReportId"
        is-in-shared-mode="data.isInSharedMode"
    ></tnk-function-card>
</div>
