import { analyticsWrapper } from '@tonkean/analytics';

function AskForUpdateCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    trackHelper,
    initiative,
    userName,
    communicationIntegrationsService,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;
    $scope.cis = communicationIntegrationsService;
    $scope.trackHelper = $rootScope.trackHelper;
    $scope.initiative = trackHelper.getInitiativeFromCache(initiative.id) || initiative;
    $scope.data = {};

    $scope.askForUpdate = function () {
        analyticsWrapper.track('Ask for updates', { category: 'Initiative view' });

        $scope.posting = true;
        trackHelper
            .askForInitiativeUpdates($scope.initiative.id, $scope.data.tempText)
            .then(function () {
                $scope.data.error = null;

                $scope.$emit('alert', {
                    msg: `A message was successfully sent to ${userName || 'the owner'}!`,
                    type: 'success',
                });

                $uibModalInstance.close();
            })
            .catch(function (error) {
                if (typeof error === 'string' || error instanceof String) {
                    $scope.data.error = error;
                } else {
                    $scope.data.error = 'Unknown error occurred';
                }
                $scope.posting = false;
            });
    };

    $scope.onTextAreaKeyPress = function ($event) {
        if ($scope.as.currentUser && !$scope.as.currentUser.isGuest && $event.ctrlKey && $event.keyCode === 13) {
            $event.preventDefault();
            $scope.askForUpdate();
        }
    };
}
angular.module('tonkean.app').controller('AskForUpdateCtrl', AskForUpdateCtrl);
