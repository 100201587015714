<div
    class="common-size-xxs cursor-default common-close-btn-container"
    style="width: 300px; max-width: 300px"
    ng-click="onStatusPopoverClick($event)"
>
    <!-- Loading state. -->
    <div ng-show="data.loadingInitiativeFromServer" class="text-center common-width-full padding-top-lg padding-bottom">
        <i class="loading"></i>
    </div>

    <!-- Content -->
    <div ng-show="!data.loadingInitiativeFromServer">
        <span class="common-close-btn mod-xs bot-side-pane-block-click-outside" ng-click="close()" ng-keydown="close()">
            <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
        </span>
        <!-- Padded content -->
        <div class="status-popover-content">
            <!-- Title -->
            <div class="common-color-black common-bold common-ellipsis">
                <div>
                    {{ itemMap[item.trackId].title }}
                </div>
            </div>

            <!-- Parents -->
            <div
                class="flex-vmiddle hidden-sub common-size-xxxxs common-color-grey text-left"
                ng-if="itemMap[item.trackId].parentInitiatives && itemMap[item.trackId].parentInitiatives.length"
            >
                <!-- First parent -->
                <span
                    ng-if="itemMap[itemMap[item.trackId].parentInitiatives[0].id].title"
                    class="parent-max-width common-ellipsis"
                >
                    <a
                        ui-sref="product.interface.group({groupId: itemMap[item.trackId].group.id, initiativeId: itemMap[item.trackId].parentInitiatives[0].id})"
                        class="common-color-grey"
                    >
                        {{ itemMap[itemMap[item.trackId].parentInitiatives[0].id].title }}
                    </a>
                </span>
                <!-- Right arrow -->
                <i
                    ng-if="itemMap[item.trackId].parentInitiatives.length > 1"
                    class="fa fa-angle-right margin-left-xs margin-right-xs common-color-light-grey"
                ></i>
                <!-- Three dots (if more than 2 parents) -->
                <span class="margin-right-xs" ng-if="itemMap[item.trackId].parentInitiatives.length > 2">
                    <span class="margin-right-xs">...</span>
                    <i class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
                </span>
                <!-- Last parent -->
                <span
                    ng-if="
                        itemMap[item.trackId].parentInitiatives.length > 1 &&
                        itemMap[
                            itemMap[item.trackId].parentInitiatives[itemMap[item.trackId].parentInitiatives.length - 1]
                                .id
                        ].title
                    "
                    class="parent-max-width common-ellipsis"
                >
                    <a
                        ui-sref="product.interface.group({groupId: itemMap[item.trackId].group.id, initiativeId: itemMap[item.trackId].parentInitiatives[itemMap[item.trackId].parentInitiatives.length-1].id})"
                        class="margin-right-xs common-color-grey"
                    >
                        {{
                            itemMap[
                                itemMap[item.trackId].parentInitiatives[
                                    itemMap[item.trackId].parentInitiatives.length - 1
                                ].id
                            ].title
                        }}
                    </a>
                </span>
            </div>

            <!-- Status -->
            <div class="common-status-tag" ng-style="{ color: itemMap[item.trackId].stateColor || '' }">
                <span class="common-bold">
                    {{ itemMap[item.trackId].status !== 'FUTURE' ? itemMap[item.trackId].stateText : 'No status' }}
                </span>
                <span
                    class="common-color-grey margin-left-xs"
                    ng-if="itemMap[item.trackId].stateUpdated && !itemMap[item.trackId].updateText"
                >
                    {{ itemMap[item.trackId].stateUpdated | daysDiffShorter }}
                </span>
            </div>

            <!-- Due date and ETA container -->
            <div
                class="flex-vmiddle margin-top-xxs common-size-xxxxs"
                ng-if="itemMap[item.trackId].dueDate || itemMap[item.trackId].eta"
            >
                <!-- Due date -->
                <div ng-if="itemMap[item.trackId].dueDate" class="flex-vmiddle margin-right-md">
                    <span
                        class="svg-icon-xs svg-icon-vmiddle margin-right-xs"
                        ng-class="
                            itemMap[item.trackId].status !== 'DONE' && itemMap[item.trackId].dueDate < todayTime
                                ? 'common-color-danger'
                                : 'common-color-grey'
                        "
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/calendar-new.svg"></tnk-icon>
                    </span>
                    <span
                        ng-class="
                            itemMap[item.trackId].status !== 'DONE' && itemMap[item.trackId].dueDate < todayTime
                                ? 'common-color-danger'
                                : 'common-color-grey'
                        "
                    >
                        Due {{ itemMap[item.trackId].dueDate | daysDiffShort }}
                    </span>
                </div>
                <!-- ETA -->
                <div ng-if="itemMap[item.trackId].eta" class="flex-vmiddle">
                    <span
                        class="svg-icon-xs svg-icon-vmiddle margin-right-xs"
                        ng-class="
                            itemMap[item.trackId].status !== 'DONE' && itemMap[item.trackId].eta < todayTime
                                ? 'common-color-danger'
                                : 'common-color-grey'
                        "
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/clock.svg"></tnk-icon>
                    </span>
                    <span
                        ng-class="
                            itemMap[item.trackId].status !== 'DONE' && itemMap[item.trackId].eta < todayTime
                                ? 'common-color-danger'
                                : 'common-color-grey'
                        "
                    >
                        ETA {{ itemMap[item.trackId].eta | daysDiffShort }}
                    </span>
                </div>
            </div>

            <!-- Updater or owner = itemPerson, update text -->
            <div ng-if="itemPerson">
                <hr class="common-separator mod-darker margin-top margin-bottom" />
                <div class="flex mod-align-start">
                    <!-- Avatar -->
                    <tnk-avatar
                        user="itemPerson"
                        default-on-empty="true"
                        class="track-leader-avatar flex-no-shrink margin-right-xs"
                    ></tnk-avatar>
                    <!-- Content (updater, update text, buttons) -->
                    <div class="flex-grow">
                        <div class="flex-vmiddle">
                            <!-- Updater/owner name -->
                            <div class="common-color-black common-size-xxxxs common-bold margin-right-xs">
                                {{ itemPerson.name }}
                            </div>
                            <!-- update time -->
                            <div class="common-size-xxxxs common-color-grey" ng-if="itemMap[item.trackId].updateText">
                                {{ itemMap[item.trackId].stateUpdated | timeAgo }}
                                <!--<i class="fa fa-info-circle margin-left-xs" uib-tooltip="Pinged {{itemMap[item.trackId].lastGatherUpdate | daysDiff}}"></i>-->
                            </div>
                            <!-- Never pinged message -->
                            <div
                                ng-if="as.currentUser && as.currentUser.isUser && (!itemMap[item.trackId].nextGatherUpdate || itemMap[item.trackId].status === 'FUTURE') && itemMap[item.trackId].owner && gatherUpdatesActive && itemMap[item.trackId].status !== 'DONE'"
                                class="common-size-xxxxs common-color-grey"
                            >
                                <span ng-if="!itemMap[item.trackId].lastGatherUpdate">Never pinged</span>
                                <span ng-if="itemMap[item.trackId].lastGatherUpdate">
                                    Pinged {{ itemMap[item.trackId].lastGatherUpdate | daysDiff }}
                                </span>
                            </div>
                            <!-- Cool separation bullet -->
                            <div
                                ng-if="itemMap[item.trackId].updatedViaBot"
                                class="common-size-xxxxs common-color-grey margin-left-xxs margin-right-xxs"
                            >
                                •
                            </div>
                            <!-- Via the Module -->
                            <div
                                ng-if="itemMap[item.trackId].updatedViaBot"
                                class="common-size-xxxxs common-color-grey"
                            >
                                Via the BOT
                            </div>
                        </div>
                        <!-- Update text -->
                        <div
                            ng-if="itemMap[item.trackId].updateText && itemMap[item.trackId].updateText.length"
                            class="common-color-black margin-top margin-bottom common-text-wrap"
                        >
                            <div
                                class="fs-censored"
                                light-markdown="itemMap[item.trackId].updateText | limitLength : data.updateTextLengthLimit"
                            ></div>
                            <a
                                class="common-size-xxxxs"
                                ng-click="onSeeMoreUpdateTextClicked()"
                                ng-if="
                                    itemMap[item.trackId].updateText.length > data.updateTextLengthLimit &&
                                    !data.showingMore
                                "
                            >
                                See More
                            </a>
                        </div>
                        <!-- Buttons (when there's an owner) -->
                        <div class="flex-vmiddle margin-top-xxs" ng-if="itemMap[item.trackId].owner">
                            <!-- We want to show it to guests but we don't want to show it to users that are not full users-->
                            <button
                                type="button"
                                ng-click="pingNow()"
                                data-ng-disabled="as.currentUser && as.currentUser.isUser && isViewOnly"
                                class="
                                    btn btn-no-border
                                    mod-primary-with-bg
                                    status-popover-ping-button
                                    common-bold
                                    margin-right
                                "
                                ng-if="itemMap[item.trackId].owner.id !== as.currentUser.id && itemMap[item.trackId].status !== 'DONE' && lps.canPersonUpdate(item.owner)"
                                uib-tooltip="Ping {{ itemMap[item.trackId].owner.name }}"
                                tooltip-enable="{{ isUpdaterNotOwner }}"
                                tooltip-placement="auto bottom"
                            >
                                👋 Ping now
                            </button>
                            <a
                                class="pointer btn btn-no-border status-popover-ping-button flex-vmiddle"
                                data-ng-disabled="!as.currentUser || !as.currentUser.isUser || isViewOnly"
                                ng-if="itemMap[item.trackId].status !== 'FUTURE'"
                                ng-click="toggleCommentsBar()"
                                ng-class="data.isCommentsBarOpen ? 'common-color-primary' : 'common-color-grey'"
                            >
                                {{
                                    itemMap[item.trackId].lastStatusComment || data.latestComment
                                        ? 'View comments'
                                        : 'Comment'
                                }}
                                <span
                                    class="loading-small margin-left-xs"
                                    ng-if="data.loadingComments && itemMap[item.trackId].lastStatusComment"
                                ></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ng-if="as.currentUser && as.currentUser.isUser && !isViewOnly">
            <!-- Next gather update (only when there's an update text and the user has gather updates enabled)) -->
            <div
                ng-if="
                    itemMap[item.trackId].owner &&
                    itemMap[item.trackId].updateText &&
                    itemMap[item.trackId].updateText.length &&
                    gatherUpdatesActive &&
                    itemMap[item.trackId].status !== 'DONE' &&
                    !data.isCommentsBarOpen &&
                    (!itemMap[item.trackId].owner || !itemMap[item.trackId].owner.isGatherUpdateDisabled)
                "
                class="status-popover-next-gather-container common-size-xxxxs common-color-dark-grey"
            >
                <tnk-set-reminder-popover
                    initiative="itemMap[item.trackId]"
                    on-custom="openGatherSettings(itemMap[item.trackId])"
                    on-set-reminder="onSetGatherUpdate()"
                    no-tooltip="true"
                >
                    <span class="common-color-dark-grey">Next ping:</span>
                    <span class="common-color-danger" ng-if="!itemMap[item.trackId].nextGatherUpdate">None</span>
                    <span class="common-color-black" ng-if="itemMap[item.trackId].nextGatherUpdate">
                        {{ itemMap[item.trackId].nextGatherUpdate | daysDiff }}
                    </span>
                    <span class="dropdown-chevron mod-thin mod-static"></span>
                </tnk-set-reminder-popover>
            </div>

            <!-- Owner turned Module off -->
            <div
                ng-if="itemMap[item.trackId].owner && itemMap[item.trackId].owner.isGatherUpdateDisabled"
                class="status-popover-tip-container common-size-xxxxs common-color-dark-grey"
            >
                <div class="status-popover-tip">
                    <div class="common-bold">
                        {{itemMap[item.trackId].owner.id === as.currentUser.id ? 'You have turned off your Module' :
                        itemMap[item.trackId].owner.firstName + ' has turned off their Module'}}
                    </div>
                </div>
            </div>

            <!-- Tonkean tips (only active when there's no status and the user has gather updates enabled) -->
            <div
                ng-if="
                    (!itemMap[item.trackId].updateText || !itemMap[item.trackId].updateText.length) &&
                    itemMap[item.trackId].status !== 'DONE' &&
                    !data.isCommentsBarOpen &&
                    (!itemMap[item.trackId].owner || !itemMap[item.trackId].owner.isGatherUpdateDisabled)
                "
                class="status-popover-tip-container common-size-xxxxs common-color-dark-grey"
            >
                <!-- No gather update, no owner, general gather updates on -->
                <div
                    ng-if="
                        !itemMap[item.trackId].nextGatherUpdate && !itemMap[item.trackId].owner && gatherUpdatesActive
                    "
                    class="status-popover-tip"
                >
                    If you need an update from someone on this, set them as an
                    <strong>owner</strong>
                    .
                </div>
                <div ng-if="lps.canPersonUpdate(item.owner)">
                    <!-- Yes gather update, yes owner, general gather updates on -->
                    <div
                        ng-if="
                            itemMap[item.trackId].nextGatherUpdate && itemMap[item.trackId].owner && gatherUpdatesActive
                        "
                        class="status-popover-tip"
                    >
                        <span>
                            The Module will ping
                            {{itemMap[item.trackId].owner.id !== as.currentUser.id ?
                            itemMap[item.trackId].owner.firstName : 'you'}}
                        </span>
                        <tnk-set-reminder-popover
                            initiative="itemMap[item.trackId]"
                            on-custom="openGatherSettings(itemMap[item.trackId])"
                            on-set-reminder="onSetGatherUpdate()"
                            no-tooltip="true"
                        >
                            <strong class="margin-left-xxs margin-right-xxs">
                                {{ itemMap[item.trackId].nextGatherUpdate | daysDiffInSentence }}
                            </strong>
                            <span
                                class="dropdown-chevron mod-thin mod-static"
                                ng-class="{ 'margin-right-xs': gatherUpdateReason }"
                            ></span>
                        </tnk-set-reminder-popover>
                        <span>{{ !gatherUpdateReason ? '' : 'based on the ' + gatherUpdateReason + '.' }}</span>
                    </div>
                    <!-- No next gather update, yes last gather update, yes owner (not current user), general gather updates on -->
                    <div
                        ng-if="!itemMap[item.trackId].nextGatherUpdate && itemMap[item.trackId].lastGatherUpdate && itemMap[item.trackId].owner && itemMap[item.trackId].owner.id !== as.currentUser.id && gatherUpdatesActive"
                        class="status-popover-tip"
                    >
                        {{ itemMap[item.trackId].owner.firstName }} has never written an update for this track.
                        <br />
                        Need more info?
                        <tnk-set-reminder-popover
                            initiative="itemMap[item.trackId]"
                            on-custom="openGatherSettings(itemMap[item.trackId])"
                            on-set-reminder="onSetGatherUpdate()"
                            no-tooltip="true"
                        >
                            <a href="#" class="common-bold margin-left-xxs margin-right-xxs">Set next ping</a>
                            <span class="dropdown-chevron mod-thin mod-static"></span>
                        </tnk-set-reminder-popover>
                    </div>
                    <!-- No next gather update, no last gather update, yes owner, general gather updates on -->
                    <div
                        ng-if="
                            !itemMap[item.trackId].nextGatherUpdate &&
                            !itemMap[item.trackId].lastGatherUpdate &&
                            itemMap[item.trackId].owner &&
                            gatherUpdatesActive
                        "
                        class="status-popover-tip"
                    >
                        <div class="common-bold">The Module needs more info in order to follow up:</div>
                        <div class="flex-vmiddle margin-top-xs relative">
                            <button
                                type="button"
                                class="btn btn-sm btn-default common-color-grey"
                                ng-click="toggleDueDatePopover()"
                            >
                                Add due date
                            </button>
                            <div class="common-color-grey margin-left margin-right">or</div>
                            <div>
                                <tnk-set-reminder-popover
                                    initiative="itemMap[item.trackId]"
                                    on-custom="openGatherSettings(itemMap[item.trackId])"
                                >
                                    <span class="common-color-dark-grey margin-right-xxs">Set next follow up</span>
                                    <span
                                        class="dropdown-chevron mod-thin mod-static margin-left-xs flex-no-shrink"
                                    ></span>
                                </tnk-set-reminder-popover>
                            </div>
                            <input
                                class="track-due-date-input mod-no-adjustment"
                                uib-datepicker-popup="fullDate"
                                datepicker-options="{showWeeks: false}"
                                ng-model="data.tempDueDate"
                                ng-min="todayTime"
                                min-date="todayTime"
                                close-text="Close"
                                ng-change="onDueDateChange(itemMap[item.trackId], data.tempDueDate)"
                                is-open="data.dueDatePopoverOpen"
                            />
                        </div>
                    </div>
                    <!-- Module gather update disabled -->
                    <div ng-if="!gatherUpdatesActive" class="status-popover-tip">
                        <strong ng-if="itemMap[item.trackId].owner">
                            Activate the Module so it can ping
                            {{itemMap[item.trackId].owner.id !== as.currentUser.id ?
                            itemMap[item.trackId].owner.firstName : 'you'







                            }}.
                        </strong>
                        <strong ng-if="!itemMap[item.trackId].owner">Activate the Module so it can ping owners.</strong>
                    </div>
                </div>
            </div>

            <!-- Comments section -->
            <div class="status-popover-comments-container" ng-if="data.isCommentsBarOpen">
                <div ng-if="data.comments && data.comments.length" class="margin-bottom-xxs">
                    <!-- View all comments link -->
                    <div
                        ng-if="data.comments.length > 1"
                        class="status-popover-comments-view-more margin-bottom common-color-dark-grey"
                    >
                        <a
                            class="common-link-no-color"
                            ui-sref="product.interface.group({groupId: itemMap[item.id].group.id, initiativeId: item.id})"
                        >
                            Older comments
                        </a>
                    </div>
                    <!-- Last comment -->
                    <div class="flex mod-align-start">
                        <!-- Avatar -->
                        <tnk-avatar
                            user="data.latestComment.commenter"
                            default-on-empty="true"
                            class="track-leader-avatar flex-no-shrink margin-right-xs"
                        ></tnk-avatar>
                        <div class="flex-grow">
                            <div class="flex-vmiddle">
                                <!-- Commenter name -->
                                <div class="common-color-black common-size-xxs common-bold margin-right-xs">
                                    {{ data.latestComment.commenter.name }}
                                </div>
                                <!-- Comment update time -->
                                <div class="common-size-xxxxs common-color-grey">
                                    {{ data.latestComment.created | daysDiffShort }}
                                </div>
                            </div>
                            <!-- Comment text -->
                            <div class="common-color-black margin-top-xs common-text-wrap">
                                <div light-markdown="data.latestComment.text | limitLength : 120"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- New comment input -->
                <tnk-comment-textbox
                    placeholder="Add your comment"
                    entity-id="{{ data.statusActivityId }}"
                    maxlength="1000"
                    required
                    on-post="onNewCommentPosted(comment)"
                    post-on-enter="true"
                    single-line-mode="true"
                    update-initiative-id="initiative.trackId"
                    is-disabled="data.loadingComments"
                    on-text-changed="onStatusCommentTextChanged(text)"
                    initial-text="{{ initialStatusCommentText }}"
                ></tnk-comment-textbox>
            </div>
        </div>
    </div>
</div>
