<div class="entity-selector flex-vmiddle">
    <!-- Entity selector -->
    <ui-select
        ng-if="!data.useReactSelect"
        class="ui-select-min-width"
        data-automation="entity-selector-entity-name"
        ng-mouseover="updateTooltipVisibility(true)"
        ng-mouseleave="updateTooltipVisibility(false)"
        ng-attr-ui-select-required="data.isRequired === true"
        theme="bootstrap"
        append-to-body="data.appendToBody"
        ng-model="data.selectedEntity"
        ng-change="entitySelectionChange()"
        ng-disabled="data.forceEntitySelectionDisabled || data.loadingEntities || (data.supportedEntities && (data.supportedEntities.length < 1 || (data.selectedEntity && data.supportedEntities.length == 1)))"
    >
        <ui-select-match placeholder="Search entity">{{ data.selectedEntity.pluralLabel }}</ui-select-match>

        <ui-select-choices
            repeat="p in data.supportedEntities | filter:$select.search"
            ui-disable-choice="shouldDisableChoice(p)"
        >
            <div
                ng-bind-html="p.pluralLabel | escapeHtml | highlight: $select.search"
                data-automation="entity-selector-template-row-{{ p.pluralLabel }}"
            ></div>
        </ui-select-choices>
    </ui-select>

    <tnk-select
        name="data.name"
        class="entity-selector-react"
        data-automation="entity-selector-entity-name"
        ng-if="data.useReactSelect && !data.loadingEntities"
        value="data.selectedEntityOption"
        on-change="(onEntityChange)"
        is-searchable="true"
        is-clearable="data.allowClear"
        options="data.options"
        thin="true"
        read-only="data.disabled"
        is-error="data.showAsError"
    ></tnk-select>

    <i
        tooltip-is-open="data.showTooltip"
        ng-show="!data.isProjectIntegrationExists"
        class="fa fa-info-circle margin-left-xs"
        uib-tooltip="{{ data.tooltipMessage }}"
    ></i>

    <!-- Loading -->
    <i class="loading-small margin-left-xs" ng-if="data.loadingEntities"></i>

    <!-- Error - we offer user to refresh to try and get the entities successfully this time -->
    <a class="common-size-xxxxs margin-left" ng-if="data.errorFetchingEntities" ng-click="refreshEntities()">Refresh</a>
</div>
