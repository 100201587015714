<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'START_JOB'" class="margin-bottom">
        <!-- Folder ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Folder ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If left empty, the Integration's selected folder will be used."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Folder ID"
                saved-expression="data.definition.folderIdExpression"
                on-tonkean-expression-changed="onFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Release Key -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Release Key:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Release Key"
                saved-expression="data.definition.releaseKeyExpression"
                on-tonkean-expression-changed="onReleaseKeyTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.releaseKeyError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.releaseKeyError }}
        </div>

        <!-- Strategy -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Strategy:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If left empty, 'ModernJobsCount' will be used."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Strategy"
                saved-expression="data.definition.strategyExpression"
                on-tonkean-expression-changed="onStrategyTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- InputArguments -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Input Arguments (JSON):</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Input Arguments"
                saved-expression="data.definition.inputArgumentsExpression"
                on-tonkean-expression-changed="onInputArgumentsTonkeanExpressionChanged(expression, shouldSaveLogic)"
                mod-text-area="true"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
