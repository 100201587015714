<div>
    <!-- Subject Title -->
    <div class="margin-bottom-xs common-title-xxs">Email Subject</div>

    <!-- Subject Input -->
    <tnk-tonkean-expression
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        logic-id="{{ data.configuredLogic.node.id }}"
        saved-original-expression="data.savedOriginalSubjectExpression"
        saved-evaluated-expression="data.savedEvaluatedSubjectExpression"
        on-tonkean-expression-changed="
            onEmailSubjectTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
        "
        expression-unique-identifier="subject"
        preview-evaluation-source="data.previewEvaluationSource"
    ></tnk-tonkean-expression>

    <!-- Subject Error -->
    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].emailSubject" class="margin-top common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].emailSubject }}
    </div>

    <!-- separator -->
    <hr />

    <!-- Body Title -->
    <div class="margin-bottom-xs common-title-xxs">
        Email Body (Html):
        <a
            class="inline-block common-italic margin-left"
            uib-tooltip="You can use markdown format to add styling to the message"
            target="_blank"
            href="https://help.tonkean.com/article/47-messages-format"
        >
            <i class="fa fa-info-circle"></i>
        </a>
    </div>

    <!-- Plain Text / HTML option -->
    <div class="btn-group margin-bottom">
        <label
            class="btn btn-default"
            ng-class="{ 'btn-primary': !data.sendAsPlainText }"
            uib-btn-radio="false"
            ng-model="data.sendAsPlainText"
            ng-click="onSendAsPlainTextChanged(false)"
        >
            HTML
        </label>
        <label
            class="btn btn-default"
            ng-class="{ 'btn-primary': data.sendAsPlainText }"
            uib-btn-radio="true"
            ng-click="onSendAsPlainTextChanged(true)"
            ng-model="data.sendAsPlainText"
        >
            Plain-Text
        </label>
    </div>

    <!-- Content Of The Email Title -->
    <div class="margin-bottom">Content of the email:</div>

    <!-- Message content - HTML Editor-->
    <div ng-if="!data.sendAsPlainText">
        <button class="btn btn-primary btn-slim" ng-click="openHtmlEditor()">Open Editor</button>
    </div>

    <!-- Message content - Plain Text Expression -->
    <div ng-if="data.sendAsPlainText">
        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            saved-original-expression="data.savedOriginalBodyExpression"
            saved-evaluated-expression="data.savedEvaluatedBodyExpression"
            on-tonkean-expression-changed="
                onEmailBodyTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            expression-unique-identifier="body"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <!-- Email Body Error -->
    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].emailBody" class="margin-top common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].emailBody }}
    </div>
</div>
