import { reactToAngular } from 'angulareact';

import { InterfacesWorkerOutline } from '@tonkean/interface-module';

export default angular
    .module('tonkean.app')
    .component(
        'tnkInterfaceWorkerOutline',
        reactToAngular(InterfacesWorkerOutline, ['workflowVersionId', 'workflowVersionType']),
    );
