import type { Variants } from 'framer-motion';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import useInterfaceWidgetHoverContext from '../utils/InterfaceWidgetHoverContext';

import { LayoutGridElements, widthByLayoutGridColumns } from '@tonkean/infrastructure';
import { EmptyIIcon } from '@tonkean/svg';
import { EmptyListIcon } from '@tonkean/svg';
import { AddIconSvg } from '@tonkean/svg';
import { Button } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonStyle } from '@tonkean/tui-theme/styles';

const Wrapper = styled(Clickable)`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;

    ${widthByLayoutGridColumns};

    width: 100%;
    max-width: var(${LayoutGridElements.WIDTH});

    min-height: 320px;
    background-color: ${Theme.colors.basicBackground};
`;

const Content = styled(motion.div)`
    min-height: 320px;
    min-width: 100%;
    position: relative;

    border: 1px dashed ${Theme.colors.gray_400};
    border-radius: 4px;
`;

const AnimationWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    height: 145px;
    width: 236px;
`;

const iconCss = css`
    position: absolute;
    transform-origin: left top;
`;

const IIcon = styled(motion(EmptyIIcon))`
    ${iconCss}
`;

const ListIcon = styled(motion(EmptyListIcon))`
    ${iconCss}
`;

const AddWidgetButton = styled(motion(Button))`
    &:hover {
        svg path {
            fill: ${Theme.current.palette.TUI.button[ButtonStyle.HIGHLIGHTED].backgroundHoverColor} !important;
        }
    }
`;

const AddIcon = styled(AddIconSvg)`
    height: 14px;
    width: 14px;

    path {
        fill: ${Theme.colors.primaryHighlight} !important;
    }
`;

const AddWidget = styled(motion.div)`
    min-height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
`;
const AddHere = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;

    opacity: 1;
    width: 100%;
    height: 100%;
    gap: 36px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    color: ${Theme.colors.primaryHighlight};
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: ${FontSize.LARGE_16};
`;

const transition = { ease: 'easeOut', duration: 0.3 };

const contentVariants: Variants = {
    idle: {
        borderColor: Theme.colors.gray_500,
        backgroundColor: `${Theme.colors.basicBackground}01`,
        transition,
    },
    hover: {
        borderColor: Theme.colors.gray_500,
        backgroundColor: `${Theme.colors.basicBackground}01`,
        transition,
    },
    leftMenuHover: {
        borderColor: Theme.colors.primaryHighlight,
        backgroundColor: `${Theme.colors.primaryHighlight}1A`,
        transition,
    },
};

const addWidgetVariants: Variants = {
    idle: {
        opacity: 1,
        transition,
    },
    hover: {
        opacity: 1,
        transition,
    },
    leftMenuHover: {
        opacity: 0,
        transition,
    },
};

const addHereVariants: Variants = {
    idle: {
        opacity: 0,
        transition,
    },
    hover: {
        opacity: 0,
        transition,
    },
    leftMenuHover: {
        opacity: 1,
        transition,
    },
};

const ItemInterfaceBuilderEmptySpot: React.FC = () => {
    const { hoveredWidgetIcon } = useInterfaceWidgetHoverContext();

    const ref = useRef<HTMLDivElement>(null);

    const controls = useAnimation();

    const iVariants: Variants = {
        idle: {
            left: `56px`,
            top: '0px',
            transform: 'rotate(8deg)',
            transition,
        },
        hover: {
            left: `0px`,
            top: '10px',
            transform: 'rotate(-2deg)',
            transition,
        },
        leftMenuHover: {},
    };

    const listVariants: Variants = {
        idle: {
            left: `47px`,
            top: '9px',
            transform: 'rotate(0deg)',
            transition,
        },
        hover: {
            left: `86px`,
            top: '0px',
            transform: 'rotate(8.31deg)',
            transition,
        },
        leftMenuHover: {},
    };

    const reduceMotion = useReducedMotion();

    useEffect(() => {
        if (hoveredWidgetIcon) {
            controls.start('leftMenuHover');
            ref.current?.scrollIntoView({ behavior: reduceMotion ? 'auto' : 'smooth' });
        } else {
            controls.start('idle');
        }
    }, [controls, hoveredWidgetIcon, reduceMotion]);

    return (
        <Wrapper
            ref={ref}
            state="."
            params={{ currentTab: 'widgets', widgetId: undefined }}
            aria-label="Add new widget"
        >
            <Content
                whileHover="hover"
                initial="idle"
                transition={transition}
                variants={contentVariants}
                animate={controls}
            >
                <AddWidget variants={addWidgetVariants}>
                    <AnimationWrapper>
                        <IIcon variants={iVariants} />
                        <ListIcon variants={listVariants} />
                    </AnimationWrapper>
                    <AddWidgetButton
                        data-automation="item-interface-builder-empty-spot-add-widget-button"
                        flex
                        highlighted
                    >
                        <AddIcon />
                        Add Widget
                    </AddWidgetButton>
                </AddWidget>
                <AddHere variants={addHereVariants}>
                    {hoveredWidgetIcon}
                    Add here
                </AddHere>
            </Content>
        </Wrapper>
    );
};

export default ItemInterfaceBuilderEmptySpot;
