import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import PingLoginFetcher from './PingLoginFetcher';
import { ReactComponent as PingLogo } from '../assets/ping-logo.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { POPULATED_FAILED } from '@tonkean/constants';
import { Breakpoint, Field, H1, H3, Input, LoadingCircle, SimpleSelect } from '@tonkean/infrastructure';
import { TnkLogoFull as TnkLogo } from '@tonkean/svg';
import type { IDPLoginData } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const LoginTitle = styled(H1)`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 64px;
    text-align: center;
`;

const TnkLogoStyled = styled(TnkLogo)`
    margin-bottom: 64px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        margin: 32px;
    }
`;

const SubmitButton = styled(Button)`
    height: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
    padding-block: 13px;
    background: ${({ disabled }) => (disabled ? Theme.colors.gray_300 : Theme.colors.promotion)} !important;
    font-size: 18px;
    color: ${({ disabled }) => (disabled ? Theme.colors.gray_600 : Theme.colors.white)} !important;
    border-radius: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
    width: 100%;
    text-align: center;
    border-color: transparent;
    &:hover {
        color: ${Theme.colors.white};
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        background: ${Theme.colors.gray_blue};
    }
`;

const EmailInput = styled(Input)<{ error?: boolean }>`
    background: ${Theme.colors.gray_200} !important;
    height: 50px !important;
    border: ${({ error }) => (error ? 'solid 1px red' : 'none')} !important;
    padding: 12px !important;
    width: 100%;

    + div {
        position: relative !important;
    }
`;

const EnvIdInput = styled(Input)<{ error?: boolean }>`
    height: 50px !important;
    padding: 12px !important;
    width: 100%;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const DomainSelect = styled(SimpleSelect)<{ error?: boolean }>`
    width: 100%;
    background: ${Theme.colors.gray_200} !important;
    height: 50px !important;
    padding: 0 !important;
    border-radius: 4px;

    div:nth-child(3) {
        height: 100% !important;
        border: 1px solid ${Theme.colors.gray_300} !important;
        border-radius: 4px;
    }
`;

const InputLabel = styled(H3)`
    font-size: 16px;
    width: 100%;
    margin-bottom: 14px;
    font-weight: 400;
    color: ${Theme.colors.gray_700};
`;

const ChangeFormState = styled(H3)`
    font-size: 16px;
    line-height: 29px;
    color: ${Theme.colors.gray_700};
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledLink = styled(ClickableLink)`
    cursor: pointer;
    color: ${Theme.colors.focus};
`;

const LoginPrompt = styled.div`
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledForm = styled(Form)`
    width: inherit;
`;

styled(H3)`
    font-size: 16px;
    color: ${Theme.colors.gray_500};
    font-weight: 400;
`;
const PingEnvironmentFieldsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledField = styled(Field)`
    width: 80%;
`;

const ErrorMessage = styled.p`
    color: ${Theme.colors.error};
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
`;

interface Props {
    backToLogin: () => void;
    pingAuth: (pingClientId: string, pingUri: string, authSuffix: string) => Promise<void>;
    environmentPingDomain?: string;
}

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
});

const manualValidationSchema = yup.object().shape({
    pingEnvironmentId: yup.string().required('Required'),
    selectedPingDomain: yup.string().required('Required'),
});

const PingLoginPrompt: React.FC<Props> = ({ backToLogin, pingAuth, environmentPingDomain }) => {
    const [isUseManual, setIsUseManual] = useState(false);
    const [error, setError] = useState('');

    /**
     * This variable holds the PING login result fetched in PingLoginFetcher. The value is a promise so we must await it.
     */
    const [idpResult, setIDPResult] = useState<Promise<IDPLoginData>>();
    const [isFetchingCredentials, setIsFetchingCredentials] = useState<boolean>(true);
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [, getIDPLoginInfo] = useLazyTonkeanService('getIDPLoginInfo');

    useEffect(() => {
        if (environmentPingDomain) {
            const getLoginIDPLoginInfo = async () => {
                const loginIDPInfo = await getIDPLoginInfo('ping_identity', '', environmentPingDomain);
                const clientId = loginIDPInfo.additionalData.clientId;
                const loginUrl = loginIDPInfo.additionalData.loginUrl;
                const authSuffix = loginIDPInfo.additionalData.ssoUrls?.authorizationEndpointSuffix || 'as/authorize';

                pingAuth(clientId, loginUrl, authSuffix);
            };
            getLoginIDPLoginInfo();
        }
    }, [environmentPingDomain, getIDPLoginInfo, pingAuth]);

    const onSubmit = useCallback<React.ComponentProps<typeof Formik>['onSubmit']>(
        async (_, { setErrors }) => {
            try {
                setIsLoading(true);
                setError('');
                const result = await idpResult;
                const clientId = result?.additionalData.clientId;
                const loginUrl = result?.additionalData.loginUrl;
                const authSuffix = result?.additionalData.ssoUrls?.authorizationEndpointSuffix || 'as/authorize';
                if (clientId && loginUrl) {
                    setIsFetchingCredentials(false);
                    pingAuth(clientId, loginUrl, authSuffix);
                } else {
                    setError(`An error occurred please try again later.`);
                }
            } catch (submitError: any) {
                setIsLoading(false);
                const errorMessage = submitError?.data?.data?.error?.message;
                if (errorMessage === 'access_denied' || submitError === 'access_denied') {
                    setError(
                        `Your organization's authentication service failed to sign in. The following error was generated: ${submitError}`,
                    );
                } else if (submitError === POPULATED_FAILED) {
                    setError(
                        `Your organization's authentication service failed to sign in. The following error was generated: To prevent unexpected behavior, please make sure the browser popup blocker is disabled.`,
                    );
                } else if (
                    (errorMessage && errorMessage !== 'Authentication window closed') ||
                    (submitError && submitError !== 'Authentication window closed')
                ) {
                    if (isUseManual) {
                        setError(errorMessage);
                    } else {
                        setErrors({
                            email: errorMessage,
                        });
                    }
                }
            }
        },
        [isUseManual, pingAuth, idpResult],
    );

    const pingDomainSelectOptions: { value: string; label: string }[] = [
        {
            label: 'North America - US (pingone.com)',
            value: 'auth.pingone.com',
        },
        {
            label: 'North America - Canada (pingone.ca)',
            value: 'auth.pingone.ca',
        },
        {
            label: 'Europe (pingone.eu)',
            value: 'auth.pingone.eu',
        },
        {
            label: 'Asia Pacific - AU (pingone.com.au)',
            value: 'auth.pingone.com.au',
        },
        {
            label: 'Asia Pacific - AP (pingone.asia)',
            value: 'auth.pingone.asia',
        },
    ];

    const setPing = useCallback(async (values: Promise<IDPLoginData>) => {
        try {
            setIDPResult(values);

            const result = await values;
            const clientId = result.additionalData.clientId;
            const loginUrl = result.additionalData.loginUrl;
            if (clientId && loginUrl) {
                setIsFetchingCredentials(false);
                setError('');
            }
        } catch (error_: any) {
            const errorMessage = error_?.data?.data?.error?.message;
            setError(errorMessage);
            setEmailValid(false);
        }
    }, []);

    if (environmentPingDomain) {
        return <LoadingCircle large />;
    }

    return (
        <LoginPrompt>
            <TnkLogoStyled />
            <LoginTitle>
                Log in with <PingLogo />
            </LoginTitle>

            {isUseManual ? (
                <>
                    <Formik validationSchema={manualValidationSchema} onSubmit={onSubmit} initialValues={{}}>
                        {({ errors, values, isSubmitting }) => (
                            <StyledForm>
                                <PingLoginFetcher
                                    values={values}
                                    useManual={isUseManual}
                                    setPingResult={setPing}
                                    setEmailValid={setEmailValid}
                                    isEmailValid={emailValid}
                                />
                                <InputLabel>Enter your Ping Identity Environment Information</InputLabel>
                                <PingEnvironmentFieldsWrapper>
                                    Domain:
                                    <StyledField touchedOnlyErrors={false}>
                                        <DomainSelect
                                            defaultValue={pingDomainSelectOptions[0]}
                                            options={pingDomainSelectOptions}
                                            name="selectedPingDomain"
                                        />
                                    </StyledField>
                                </PingEnvironmentFieldsWrapper>
                                <PingEnvironmentFieldsWrapper>
                                    ID:
                                    <StyledField touchedOnlyErrors={false}>
                                        <EnvIdInput
                                            data-automation="ping-login-prompt-input-environment-id"
                                            error={!!errors['pingEnvironmentId']}
                                            name="pingEnvironmentId"
                                            placeholder="Your Ping Environment Id"
                                        />
                                    </StyledField>
                                </PingEnvironmentFieldsWrapper>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                <SubmitButton
                                    data-automation="ping-login-prompt-submit-button"
                                    type="submit"
                                    disabled={
                                        !!errors['pingEnvironmentId'] ||
                                        isSubmitting ||
                                        isFetchingCredentials ||
                                        isLoading
                                    }
                                >
                                    {isSubmitting || isLoading ? <LoadingCircle white /> : 'Login'}
                                </SubmitButton>
                            </StyledForm>
                        )}
                    </Formik>
                    <ChangeFormState>
                        <StyledLink onClick={() => setIsUseManual(false)}>Back</StyledLink>
                    </ChangeFormState>
                </>
            ) : (
                <>
                    <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{}}>
                        {({ errors, values, isSubmitting }) => (
                            <StyledForm>
                                <PingLoginFetcher
                                    values={values}
                                    useManual={isUseManual}
                                    setEmailValid={setEmailValid}
                                    setPingResult={setPing}
                                    isEmailValid={emailValid}
                                />
                                <InputLabel>Enter your email</InputLabel>
                                <Field touchedOnlyErrors={false}>
                                    <EmailInput
                                        data-automation="ping-login-prompt-email-input"
                                        error={!!errors['email']}
                                        name="email"
                                        placeholder="Enter your email"
                                    />
                                </Field>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                <SubmitButton
                                    data-automation="ping-login-prompt-submit-button"
                                    type="submit"
                                    disabled={!!errors['email'] || isSubmitting || isFetchingCredentials || isLoading}
                                >
                                    {isSubmitting || (isFetchingCredentials && emailValid) || isLoading ? (
                                        <LoadingCircle white />
                                    ) : (
                                        'Log in'
                                    )}
                                </SubmitButton>
                            </StyledForm>
                        )}
                    </Formik>
                    <ChangeFormState>
                        <StyledLink onClick={backToLogin}> Back </StyledLink>
                        <StyledLink
                            data-automation="ping-login-prompt-manually-authentication-domain-button"
                            onClick={() => setIsUseManual(true)}
                        >
                            Manually enter Ping Identity environment details
                        </StyledLink>
                    </ChangeFormState>
                </>
            )}
        </LoginPrompt>
    );
};

export default PingLoginPrompt;
