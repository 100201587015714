import { NavigationCategory } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const navigationCategoryToEnvironmentTypeMap: Partial<Record<NavigationCategory, WorkflowVersionType>> = {
    [NavigationCategory.CURRENT_MODULE_BUILD_ITEMS]: WorkflowVersionType.DRAFT,
    [NavigationCategory.CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS]: WorkflowVersionType.DRAFT,

    [NavigationCategory.CURRENT_MODULE_PRODUCTION_ITEMS]: WorkflowVersionType.PUBLISHED,
    [NavigationCategory.CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS]: WorkflowVersionType.PUBLISHED,
};

export default navigationCategoryToEnvironmentTypeMap;
