import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { publishSolutionSidePaneFiltersHelper } from './SolutionVersionsFiltersPopover';
import SolutionVersionsFiltersRow from './SolutionVersionsFiltersRow';
import SolutionVersionsSingleVersion from './SolutionVersionsSingleVersion';
import SolutionVersionsVersionContainer from './SolutionVersionsVersionContainer';
import { ReactComponent as EmptySolutionIcon } from '../../../../images/icons/empty-solution.svg';
import { ReactComponent as HistoryIcon } from '../../../../images/icons/history.svg';
import initialSolutionVersionsFilters from '../entities/initialSolutionVersionsFilters';

import { useTonkeanService } from '@tonkean/angular-hooks';
import {
    CloseIcon,
    EmptyStateFilters,
    LIMIT_PARAM,
    SimpleErrorStateMessage,
    SKIP_PARAM,
    StateMessage,
    useFetchManager,
} from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const Wrapper = styled.div`
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;
const Header = styled.header`
    margin: 0 0 15px;
    padding: 15px 15px 0;
`;
const Title = styled.h4`
    font-size: ${FontSize.SMALL_12};
    line-height: 10px;
    color: ${Theme.colors.gray_700};
    padding: 0;
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    .tnk-icon {
        margin-right: 6px;

        svg {
            height: 12px;
            width: 12px;
        }
    }
`;
const SolutionName = styled.h3`
    font-weight: 500;
    font-size: ${FontSize.XLARGE_18};
    line-height: 21px;
    color: ${Theme.colors.gray_800};
    margin: 0;
    padding: 0;
`;
const LoadMoreButton = styled(Button)`
    margin: 15px auto;
    display: block;
`;

interface Props {
    workflowFolder: WorkflowFolder;

    onClose(): void;
}

const SolutionVersionsList: React.FC<Props> = ({ workflowFolder }) => {
    const tonkeanService = useAngularService('tonkeanService');

    const [filters, setFilters] = useState(initialSolutionVersionsFilters);
    const [searchTerm, setSearchTerm] = useState('');

    const trimmedSearchTerm = useMemo(() => {
        return searchTerm.trim();
    }, [searchTerm]);

    // Get the versions count
    const { data: versionsCount } = useTonkeanService('getWorkflowFolderVersionCount', workflowFolder.id);

    const [
        [getWorkflowFolderVersionSummaries, cancelFetcher],
        { data: workflowFolderVersionSummaries, hasMorePages, loading, loadNextPage, error },
    ] = useFetchManager(tonkeanService, 'getWorkflowFolderVersionSummaries', {
        // Compare by the workflow folder version id.
        compareItems(item1, item2) {
            return item1.workflowFolderVersion.id === item2.workflowFolderVersion.id;
        },
        // Sort by sequential identifier, DESC.
        sort(item1, item2) {
            return item2.workflowFolderVersion.sequentialIdentifier - item1.workflowFolderVersion.sequentialIdentifier;
        },
        limit: 20,
    });

    useEffect(() => {
        getWorkflowFolderVersionSummaries(
            workflowFolder.id,
            trimmedSearchTerm || undefined,
            filters.fromDate?.getTime(),
            filters.toDate?.getTime(),
            filters.publisherId ? [filters.publisherId] : undefined,
            filters.sequentialIdentifier ? [filters.sequentialIdentifier] : undefined,
            SKIP_PARAM,
            LIMIT_PARAM,
        );

        return cancelFetcher;
    }, [cancelFetcher, filters, getWorkflowFolderVersionSummaries, trimmedSearchTerm, workflowFolder.id]);

    const hasFilters = useMemo(() => {
        return !!searchTerm || publishSolutionSidePaneFiltersHelper.hadChanged(filters);
    }, [filters, searchTerm]);
    const emptyState = !workflowFolderVersionSummaries.length && !error && !loading.any;

    return (
        <Wrapper>
            <Header>
                <Title>
                    <span className="tnk-icon">
                        <HistoryIcon />
                    </span>
                    <span>
                        Solution Published Versions History
                        {versionsCount && ` (${versionsCount.count})`}
                    </span>
                </Title>
                <SolutionName>{workflowFolder.displayName}</SolutionName>
            </Header>

            <SolutionVersionsFiltersRow
                workflowFolder={workflowFolder}
                searchTerm={searchTerm}
                onSearchTermChanged={setSearchTerm}
                filters={filters}
                onFiltersChanged={setFilters}
            />

            {error && (
                <SimpleErrorStateMessage
                    icon={
                        <span className="tnk-icon">
                            <EmptySolutionIcon />
                        </span>
                    }
                    error={error}
                    showSmallError={!!workflowFolderVersionSummaries.length}
                />
            )}

            {workflowFolderVersionSummaries.map((workflowFolderVersionSummary) => (
                <SolutionVersionsSingleVersion
                    key={workflowFolderVersionSummary.workflowFolderVersion.id}
                    workflowFolderVersionSummary={workflowFolderVersionSummary}
                    searchTerm={trimmedSearchTerm}
                />
            ))}

            {emptyState && (
                <>
                    {hasFilters ? (
                        <EmptyStateFilters
                            title="No solutions found"
                            icon={
                                <span className="tnk-icon">
                                    <EmptySolutionIcon />
                                </span>
                            }
                        />
                    ) : (
                        <StateMessage
                            icon={
                                <span className="tnk-icon">
                                    <EmptySolutionIcon />
                                </span>
                            }
                            title={
                                <>
                                    This Solution was never
                                    <br />
                                    published before
                                </>
                            }
                        >
                            Press the ‘Publish Version’ button
                        </StateMessage>
                    )}
                </>
            )}

            {loading.any && range(10).map((item) => <SolutionVersionsVersionContainer key={item} />)}

            {hasMorePages && (
                <LoadMoreButton disabled={loading.nextPageLoading} onClick={loadNextPage} outlined>
                    Load More
                </LoadMoreButton>
            )}

            <CloseIcon />
        </Wrapper>
    );
};

export default SolutionVersionsList;
