import SolutionSiteItemWidget from './components/SolutionSiteItemWidget';
import SolutionSiteItemWidgetConfigurationEditor from './components/SolutionSiteItemWidgetConfigurationEditor';
import type SolutionSiteItemWidgetConfiguration from './SolutionSiteItemWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSitePageItemsWidgetPackage: SolutionSitePageWidgetPackage<SolutionSiteItemWidgetConfiguration> = {
    displayComponent: SolutionSiteItemWidget,
    configurationComponent: SolutionSiteItemWidgetConfigurationEditor,
};

export default SolutionSitePageItemsWidgetPackage;
