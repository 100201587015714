function EducationPopoverCtrl($rootScope, $scope, $stateParams, projectManager, educationManager) {
    $scope.as = $rootScope.as;
    $scope.pm = projectManager;
    $scope.educationManager = educationManager;

    /**
     * Initializes the control.
     */
    $scope.init = function () {
        $scope.groupId = $stateParams.g
            ? $stateParams.g
            : projectManager.project.defaultGroup
            ? projectManager.project.defaultGroup.id
            : null;
    };

    $scope.minimizeClicked = function () {
        // Only finish the onboarding if this is a logged in user.
        if ($rootScope.as.currentUser.isUser) {
            $rootScope.onBoardingManager.finishOnBoarding();
        }
        // setBotPopoverIsOpenState knows to handle both users and guest users.
        $scope.setBotPopoverIsOpenState(false, false, true);
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('EducationPopoverCtrl', EducationPopoverCtrl);
