import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as DataSourceIcon } from '../../../../../../images/icons/history/data-source.svg';
import { ReactComponent as FlowRunIcon } from '../../../../../../images/icons/history/flow-run.svg';
import { ReactComponent as ModuleItemIcon } from '../../../../../../images/icons/history/module-item.svg';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';

const Wrapper = styled.div<{ svgWidth?: number }>`
    display: inline-flex;
    margin-right: 10px;

    ${({ svgWidth }) =>
        svgWidth &&
        css`
            svg {
                width: ${svgWidth}px;
            }
        `};
`;

interface Props {
    workerRun: WorkerRun;
    onBlur?(): void;
    onFocus?(): void;
    onMouseEnter?(): void;
    onMouseLeave?(): void;
}

const WorkerRunsTableItemStageIcon: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { workerRun, onBlur, onFocus, onMouseEnter, onMouseLeave },
    ref,
) => {
    // Allows wrapping this component with popover.
    const eventProps = { onBlur, onFocus, onMouseEnter, onMouseLeave };

    switch (workerRun.workerRunStage) {
        case WorkerRunStage.MODULE_ITEM:
            return (
                <Wrapper {...eventProps} ref={ref}>
                    <span className="tnk-icon">
                        <ModuleItemIcon />
                    </span>
                </Wrapper>
            );

        case WorkerRunStage.DATA_SOURCE: {
            return (
                <Wrapper {...eventProps} ref={ref}>
                    <ProjectIntegrationIcon
                        projectIntegrationId={workerRun.projectIntegrationId}
                        integrationType={workerRun.externalActivity?.integrationType}
                        width={13}
                        height={17}
                        placeholderWidth={16}
                    />
                </Wrapper>
            );

            return (
                <Wrapper svgWidth={13} {...eventProps} ref={ref}>
                    <span className="tnk-icon">
                        <DataSourceIcon />
                    </span>
                </Wrapper>
            );
        }

        case WorkerRunStage.FLOW_RUN:
        default:
            return (
                <Wrapper {...eventProps} ref={ref}>
                    <span className="tnk-icon">
                        <FlowRunIcon />
                    </span>
                </Wrapper>
            );
    }
};

export default React.forwardRef(WorkerRunsTableItemStageIcon);
