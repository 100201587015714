import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function ExcelSheetSelectorCtrl(
    $scope,
    $q,
    createProjectApis,
    oauthHandler,
    utils,
    projectManager,
    customFieldsManager,
    modal,
    environment,
) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        configuredInWorkflowVersionId: ctrl.configuredInWorkflowVersionId,
        displayColumnSelection: ctrl.displayColumnSelection,
        configSelections: {},

        connecting: false,
        error: null,
        selectedColumnsFlags: {},
        selectedColumns: {},
        filteredColumns: [],
        spreadsheets: null,
        selections: null,
        isEdit: false,

        // if this flag is true we show all columns, by default show only columns with example
        showAllColumns: false,

        showUniqueIdentifierAsExternalIdCheckbox:
            projectManager.project.features.tonkean_feature_excel_use_unique_identifier_as_external_id,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.projectIntegration) {
            initializeEditMode();
        }
    };

    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    $scope.selectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            // if we are not showing all the columns, dont mark as selected if the column isnt shown (has example)
            if (!$scope.data.showAllColumns && !column.example) {
                continue;
            }

            $scope.data.selectedColumnsFlags[column.value] = true;
        }
    };

    $scope.unselectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            $scope.data.selectedColumnsFlags[column.value] = false;
        }
    };

    $scope.columnSelected = function (event) {
        event.stopPropagation();
    };

    $scope.authorize = function () {
        $scope.data.connecting = true;
        $scope.data.error = null;
        const redirectUri = environment.integrationKeysMap.microsoftUri;

        oauthHandler
            .microsoft(
                'https%3A%2F%2Fgraph.microsoft.com%2FSites.ReadWrite.All https%3A%2F%2Fgraph.microsoft.com%2FFiles.ReadWrite.All offline_access',
                redirectUri,
                true
            )
            .then(function (code) {
                return $q.resolve({ code, redirectUri });
            })
            .then(function (config) {
                return createProjectApis.createIntegration(projectManager.project.id, 'excel365', config, undefined);
            })
            .then(function (integration) {
                $scope.data.integration = integration;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'spreadsheets',
                );
            })
            .then(function (data) {
                $scope.data.spreadsheets = data.options;
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.excelSheetsStep = 'selectSpreadSheet';
                $scope.data.connecting = false;
            });
    };

    $scope.onSearchChanged = function (queryString) {
        $scope.data.spreadsheets = [];

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'spreadsheets', {
                name: queryString,
            })
            .then(function (data) {
                $scope.data.spreadsheets = data.options;
            });
    };

    $scope.changeUser = function () {
        $scope.data.integration = null;
        $scope.data.spreadsheets = null;
        $scope.authorize();
    };

    $scope.onSpreadSheetSelected = function (spreadsheet) {
        if (!spreadsheet || !spreadsheet.displayName) {
            return;
        }
        $scope.data.connecting = true;
        $scope.data.spreadsheet = spreadsheet;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'sheets', {
                spreadsheetId: $scope.data.spreadsheet.value,
                driveId: $scope.data.spreadsheet.driveId,
            })
            .then(function (data) {
                $scope.data.sheets = data.options;
                if ($scope.data.sheets && $scope.data.sheets.length > 0) {
                    $scope.data.sheet = $scope.data.sheets[0];
                    $scope.onSheetChanged($scope.data.sheet);
                    $scope.data.excelSheetsStep = 'setupSpreadSheet';
                }
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    };

    $scope.onSheetChanged = function (sheet) {
        if (!sheet || !sheet.displayName) {
            return;
        }

        $scope.data.sheet = sheet;

        const autoCompleteObject = {
            spreadsheetId: $scope.data.spreadsheet.value,
            sheetId: $scope.data.sheet.value,
            sheetName: $scope.data.sheet.displayName,
            startFromRow: $scope.data.config && $scope.data.config.startFromRow ? $scope.data.config.startFromRow : 1,
            driveId: $scope.data.sheet.driveId,
        };

        return createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.integration.id,
                'columns',
                autoCompleteObject,
            )
            .then(function (data) {
                $scope.data.columns = data.options;

                return createProjectApis
                    .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'rows', {
                        spreadsheetId: $scope.data.spreadsheet.value,
                        sheetName: $scope.data.sheet.displayName,
                        sheetId: $scope.data.sheet.value,
                        driveId: $scope.data.sheet.driveId,
                    })
                    .then(function (data) {
                        $scope.data.rows = data.options;
                    });
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {});
    };

    $scope.onColumnNameInputClick = function (event) {
        // stop propagation so the surrounding div wont get the event and activates its click (selecting the column)
        event.stopPropagation();
    };

    $scope.toggleShowAllColumns = function () {
        $scope.data.showAllColumns = !$scope.data.showAllColumns;
    };

    function saveColumnConfiguration() {
        $scope.data.filteredColumns = $scope.data.columns;

        if ($scope.data.configSelections.titleFieldColumn) {
            $scope.data.config.titleFieldColumn = $scope.data.configSelections.titleFieldColumn.displayName;
            $scope.data.config.idFieldColumn = $scope.data.configSelections.titleFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.titleFieldColumn);
        }
        if ($scope.data.configSelections.idFieldColumn) {
            $scope.data.config.idFieldColumn = $scope.data.configSelections.idFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.idFieldColumn);
        }
        if ($scope.data.configSelections.descriptionFieldColumn) {
            $scope.data.config.descriptionFieldColumn = $scope.data.configSelections.descriptionFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.descriptionFieldColumn);
        }
        if ($scope.data.configSelections.ownerEmailFieldColumn) {
            $scope.data.config.ownerEmailFieldColumn = $scope.data.configSelections.ownerEmailFieldColumn.displayName;
            // $scope.data.config.ownerNameFieldColumn = $scope.data.configSelections.ownerEmailFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.ownerEmailFieldColumn);
        }
        if ($scope.data.configSelections.ownerNameFieldColumn) {
            $scope.data.config.ownerNameFieldColumn = $scope.data.configSelections.ownerNameFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.ownerNameFieldColumn);
        }

        if ($scope.data.configSelections.startingRow) {
            $scope.data.config.startFromRow = $scope.data.configSelections.startingRow.displayName;
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const filteredColumn = $scope.data.filteredColumns[i];

            if (filteredColumn && filteredColumn.example && filteredColumn.example !== '') {
                $scope.data.selectedColumnsFlags[filteredColumn.value] = true;
                $scope.data.selectedColumns[filteredColumn.value] = filteredColumn.example;
            }
        }
    }

    function removeFromFilteredColumns(columnToRemove) {
        const index = $scope.data.filteredColumns.indexOf(columnToRemove);

        if (index > -1) {
            $scope.data.filteredColumns.splice(index, 1);
        }
    }

    function getFieldDefinitionsToCreate() {
        if (!$scope.data.selectedColumnsFlags) {
            return [];
        }

        const editedColumns = Object.keys($scope.data.selectedColumnsFlags);
        const fieldDefinitionsToCreate = [];

        for (const col of editedColumns) {
            if ($scope.data.selectedColumnsFlags[col]) {
                const definition = {
                    name: $scope.data.selectedColumns[col],
                    type: 'EXTERNAL',
                    targetType: 'COLUMN',
                    projectIntegrationId: $scope.data.projectIntegration.id,
                    definition: {
                        ExternalType: 'Row',
                        FieldName: col,
                        FieldLabel: col,
                    },
                    ranges: [],
                    fieldType: 'String',
                    updateable: false,
                };
                fieldDefinitionsToCreate.push(definition);
            }
        }

        return fieldDefinitionsToCreate;
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        $scope.data.filteredColumns = [];
        $scope.data.config = {};
        saveColumnConfiguration();

        if (
            ($scope.data.configuredInWorkflowVersionId || $scope.data.displayColumnSelection) &&
            $scope.data.excelSheetsStep !== 'selectColumns'
        ) {
            $scope.data.excelSheetsStep = 'selectColumns';
            return;
        }
        $scope.data.saving = true;

        if (!$scope.data.config) {
            $scope.data.config = {};
            saveColumnConfiguration();
        }

        const projectIntegrationDisplayName = $scope.data.spreadsheet.displayName;
        const projectData = {
            spreadsheet: $scope.data.spreadsheet,
            sheet: $scope.data.sheet,
            config: $scope.data.config,
            uniqueIdentifierAsExternalID: $scope.data.uniqueIdentifierAsExternalID,
        };

        // NOTE: Since edit project integration is relying on the third parameter object to hold an array of projectDatas,
        // we inject it into the integration object, which does not have a project data property at all.
        $scope.data.integration.projectDatas = [projectData];

        let projectIntegrationPromise;
        const oldProject = projectManager.project;

        if ($scope.data.isEdit) {
            projectIntegrationPromise = createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.integration
            );
        } else {
            projectIntegrationPromise = createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.data.integration,
                projectIntegrationDisplayName,
            );
        }

        projectIntegrationPromise.then((updatedProject) => {
            $scope.data.projectIntegration = utils.findFirst(
                updatedProject.integrations,
                (prin) =>
                    prin.id === $scope?.data?.projectIntegration?.id ||
                    !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
            );
            const fieldDefinitionsToCreate = getFieldDefinitionsToCreate();

            let createFieldsPromise = $q.resolve();
            if (
                $scope.data.configuredInWorkflowVersionId &&
                fieldDefinitionsToCreate &&
                fieldDefinitionsToCreate.length
            ) {
                createFieldsPromise = customFieldsManager.createMultipleFieldDefinitions(
                    $scope.data.configuredInGroupId,
                    fieldDefinitionsToCreate,
                    null,
                );
            }

            createFieldsPromise.then(() => {
                projectManager.getProjectData(true);
                $scope.data.saving = false;
                if (ctrl.onProjectIntegrationCreated) {
                    ctrl.onProjectIntegrationCreated({
                        projectIntegration: $scope.data.projectIntegration,
                        fieldDefinitionsToCreate,
                    });
                }
            });
        });
    };

    $scope.onCancel = function () {
        if (ctrl.onClosed) {
            ctrl.onClosed();
        }
    };

    $scope.openFieldInspectModal = function (modelKeyToUpdate) {
        analyticsWrapper.track('Toggle field inspect modal', { category: 'Excel sheet selector', label: true });

        // Parse the columns to a dict so the inspector knows how to use it.
        // We pass that data as staticData - a different mode the inspector knows how to use.
        const columnsDict = parseColumnForFieldInspector($scope.data.columns);
        modal.openFieldInspectModal(
            null,
            null,
            null,
            (fieldName) =>
                ($scope.data.configSelections[modelKeyToUpdate] = utils.findFirst(
                    $scope.data.columns,
                    (column) => column.displayName === fieldName,
                )),
            columnsDict,
        );
    };

    /**
     * Parses the given columns array to a dictionary for the field inspector.
     * @returns {{}}
     */
    function parseColumnForFieldInspector(columns) {
        const columnsDict = {
            data: {},
            displayNameMap: {},
        };

        if (columns) {
            for (const column of columns) {
                if (!columnsDict.data[column.displayName]) {
                    columnsDict.data[column.displayName] = column.example;
                }
            }
        }

        return columnsDict;
    }

    /**
     * If component was given a project integration, will load the component's edit mode.
     */
    function initializeEditMode() {
        $scope.data.isEdit = true;

        $scope.data.integration = $scope.data.projectIntegration.integration;
        const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];
        $scope.data.connecting = true;

        $scope.data.error = null;

        $scope.data.spreadsheet = projectData.spreadsheet;
        $scope.data.sheet = projectData.sheet;
        $scope.data.uniqueIdentifierAsExternalID = projectData.uniqueIdentifierAsExternalID;
        $scope.data.excelSheetsStep = 'setupSpreadSheet';

        return createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.projectIntegration.integration.id,
                'sheets',
                {
                    spreadsheetId: $scope.data.spreadsheet.value,
                    driveId: $scope.data.spreadsheet.driveId,
                },
            )
            .then(function (data) {
                $scope.data.sheets = data.options;

                const autoCompleteObject = {
                    spreadsheetId: $scope.data.spreadsheet.value,
                    sheetId: $scope.data.sheet.value,
                    sheetName: $scope.data.sheet.displayName,
                    startFromRow: projectData.config.startFromRow ? projectData.config.startFromRow : 1,
                    driveId: $scope.data.sheet.driveId,
                };

                return createProjectApis
                    .getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.data.projectIntegration.integration.id,
                        'columns',
                        autoCompleteObject,
                    )
                    .then(function (data) {
                        $scope.data.columns = data.options;
                        $scope.data.config = projectData.config;
                        $scope.data.configSelections = {};

                        if ($scope.data.config.idFieldColumn) {
                            $scope.data.configSelections.idFieldColumn = {
                                displayName: $scope.data.config.idFieldColumn,
                                value: $scope.data.config.idFieldColumn,
                            };
                        }

                        if ($scope.data.config.titleFieldColumn) {
                            $scope.data.configSelections.titleFieldColumn = {
                                displayName: $scope.data.config.titleFieldColumn,
                                value: $scope.data.config.titleFieldColumn,
                            };
                        }

                        if ($scope.data.config.descriptionFieldColumn) {
                            $scope.data.configSelections.descriptionFieldColumn = {
                                displayName: $scope.data.config.descriptionFieldColumn,
                                value: $scope.data.config.descriptionFieldColumn,
                            };
                        }

                        if ($scope.data.config.ownerEmailFieldColumn) {
                            $scope.data.configSelections.ownerEmailFieldColumn = {
                                displayName: $scope.data.config.ownerEmailFieldColumn,
                                value: $scope.data.config.ownerEmailFieldColumn,
                            };
                        }

                        if ($scope.data.config.ownerNameFieldColumn) {
                            $scope.data.configSelections.ownerNameFieldColumn = {
                                displayName: $scope.data.config.ownerNameFieldColumn,
                                value: $scope.data.config.ownerNameFieldColumn,
                            };
                        }

                        const autoCompleteObject = {
                            spreadsheetId: $scope.data.spreadsheet.value,
                            sheetName: $scope.data.sheet.displayName,
                            sheetId: $scope.data.sheet.value,
                            driveId: $scope.data.sheet.driveId,
                        };

                        return createProjectApis
                            .getAutoCompleteOptions(
                                projectManager.project.id,
                                $scope.data.projectIntegration.integration.id,
                                'rows',
                                autoCompleteObject,
                            )
                            .then(function (data) {
                                $scope.data.rows = data.options;

                                if ($scope.data.config.startFromRow) {
                                    $scope.data.configSelections.startingRow = {
                                        displayName: $scope.data.config.startFromRow,
                                        value: $scope.data.config.startFromRow,
                                    };
                                }
                            });
                    });
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    }
}

export default angular.module('tonkean.app').controller('ExcelSheetSelectorCtrl', lateConstructController(ExcelSheetSelectorCtrl));
