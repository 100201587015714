import React from 'react';

import { HashtagIcon } from '@tonkean/svg';
import { CalendarIcon as CalendarNewIcon } from '@tonkean/svg';
import { ChevronCircleIcon } from '@tonkean/svg';
import { CalculatorIcon } from '@tonkean/svg';
import { TextInputIcon } from '@tonkean/svg';
import { TextIcon } from '@tonkean/svg';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    fieldType: string;
    size?: string;
}

const FieldTypeIcon: React.FC<Props> = ({ fieldType, size = 'sm', className = '' }) => {
    const sizeClassName = `svg-icon-${size}`;

    switch (fieldType.toUpperCase()) {
        case 'NUMBER':
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <HashtagIcon />
                    </span>
                </span>
            );
        case 'DATE':
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <CalendarNewIcon />
                    </span>
                </span>
            );
        case 'LIST':
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <ChevronCircleIcon />
                    </span>
                </span>
            );
        case 'FORMULA':
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <CalculatorIcon />
                    </span>
                </span>
            );
        case 'MANUAL':
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <TextInputIcon />
                    </span>
                </span>
            );
        default:
            return (
                <span className={`${sizeClassName} ${className}`}>
                    <span className="tnk-icon">
                        <TextIcon />
                    </span>
                </span>
            );
    }
};

export default FieldTypeIcon;
