import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import DuplicateProjectIntegrationAction from './DuplicateProjectIntegrationAction';
import ProjectIntegrationActionRemoveModal from './ProjectIntegrationActionRemoveModal';
import { ReactComponent as DuplicateIcon } from '../../../../../../../../images/icons/duplicate.svg';
import { ReactComponent as MoreActionIcon } from '../../../../../../../../images/icons/more-actions.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../../images/icons/trash-form.svg';
import type { BaseProjectIntegrationActionManagerProps } from '../../../../../../../components/state.product.projectIntegrationActionManager/baseProjectIntegrationActionManagerProps';
import useProjectIntegrationActionWarningModal from '../../../../../../../components/state.product.projectIntegrationActionManager/useProjectIntegrationActionWarningModal';
import type { AdditionalSidePaneConfiguration } from '../../../../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationActionManagerBreadCrumbs from '../../../../../../ProjectIntegrationActionLayoutModule/components/ProjectIntegrationActionManagerBreadCrumbs';
import useProjectIntegrationActionToSolutionAndGroupMapping from '../../../../../hooks/useProjectIntegrationActionToSolutionAndGroupMapping';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { LoadingCircle, Menu, MenuItem, TextEllipsis, Tooltip, useToggle } from '@tonkean/infrastructure';
import { ActionType, ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 112px;
    padding-top: 25px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const HeaderTitle = styled.div`
    font-size: ${FontSize.XXXXLARGE_24};
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    height: 31px;
`;

const SaveChangesButton = styled(Button)`
    margin-left: auto;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 7px;
    border: 3px solid white;
    border-right-color: ${Theme.colors.primary};
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
    margin: auto 10px auto auto;
    max-width: 400px;
`;

const TitleAndButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BreadCrumbsAndActionButton = styled.div`
    display: inline-flex;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: 5px;
`;

const SavesChangesSection = styled.div`
    display: flex;
`;

const ProjectIntegrationActionPageHeader: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationActionManagerProps>
> = ({
    sidePaneTabName,
    onClickSave,
    projectIntegrationActions,
    projectIntegrationAction,
    projectIntegration,
    isUpdateActionLoading,
    updateActionError,
    tabKeyToHasError,
    actionTabChanges,
}) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useToggle(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState<boolean>(false);

    const openWarning = useProjectIntegrationActionWarningModal();
    const emitToastMessage = useToastMessage();

    const $state = useAngularService('$state');

    const shouldShowDuplicateOption =
        projectIntegrationAction.actionType !== ActionType.UPLOAD &&
        projectIntegrationAction.actionType !== ActionType.DOWNLOAD &&
        projectIntegrationAction.actionType !== ActionType.STREAM_IMAGE;

    // validate if all of the tabs are valid
    // If exists the action can't be saved.
    const isAllTabsValid: boolean = useMemo(() => {
        return Object.values(tabKeyToHasError).every((hasError) => !hasError);
    }, [tabKeyToHasError]);

    // The text of error message when clicking Save action
    const errorMessage = useMemo(() => {
        if (updateActionError) {
            return getStateError(updateActionError);
        }
    }, [updateActionError]);

    // On Clicking save button we opens a modal to validate that he sure.
    const onClickSaveChangesButton = async () => {
        const confirmed = await openWarning(
            'Save Changes',
            'Notice - some changes you made in fields and entities will be applied in the modules that use this action, in all environments',
        );
        if (confirmed) {
            onClickSave();
        }
    };

    const [
        { loading: loadingDeleteProjectIntegrationAction, error: errorDeleteProjectIntegrationAction },
        deleteProjectIntegrationAction,
    ] = useLazyTonkeanService('deleteProjectIntegrationAction');

    const onDelete = useCallback(async () => {
        await deleteProjectIntegrationAction(projectIntegrationAction.id);
        toggleIsDeleteModalOpen();

        $state.go('product.projectIntegrationPage', {
            page: ProjectIntegrationPageMenuItemType.ACTIONS,
            enterpriseComponentId: projectIntegration.id,
        });

        emitToastMessage(`Deleted ${projectIntegrationAction.displayName} Custom Action successfully`, 'success');
    }, [
        projectIntegrationAction,
        projectIntegration,
        emitToastMessage,
        $state,
        deleteProjectIntegrationAction,
        toggleIsDeleteModalOpen,
    ]);

    const disabledSaveActionTooltip = useMemo(() => {
        if (isUpdateActionLoading) {
            return 'Save action already in process...';
        } else if (!isAllTabsValid) {
            return 'Some of the tabs are invalid';
        } else if (actionTabChanges.length === 0) {
            return 'No changes detected';
        }
    }, [isUpdateActionLoading, isAllTabsValid, actionTabChanges.length]);

    return (
        <>
            <HeaderWrapper>
                <BreadCrumbsAndActionButton>
                    <ProjectIntegrationActionManagerBreadCrumbs
                        projectIntegrationActions={projectIntegrationActions}
                        projectIntegrationAction={projectIntegrationAction}
                        projectIntegration={projectIntegration}
                        shouldOpenWarningBeforeNavigate={actionTabChanges.length !== 0}
                    />

                    <Menu
                        show={isMenuOpen}
                        onClose={toggleIsMenuOpen}
                        placement="right-start"
                        menuItems={
                            <>
                                <MenuItem onClick={() => toggleIsDeleteModalOpen()} icon={<TrashIcon />}>
                                    Delete Action
                                </MenuItem>

                                {shouldShowDuplicateOption && (
                                    <MenuItem onClick={() => setIsDuplicateModalOpen(true)} icon={<DuplicateIcon />}>
                                        Duplicate Action
                                    </MenuItem>
                                )}
                            </>
                        }
                        thin
                    >
                        <StyledIconButton
                            data-automation="duplicate-project-integration-action-button"
                            onClick={() => toggleIsMenuOpen()}
                            flat
                        >
                            <MoreActionIcon />
                        </StyledIconButton>
                    </Menu>

                    {isDuplicateModalOpen && (
                        <DuplicateProjectIntegrationAction
                            projectIntegrationAction={projectIntegrationAction}
                            projectIntegrationActions={projectIntegrationActions}
                            projectIntegration={projectIntegration}
                            isOpen={isDuplicateModalOpen}
                            onClose={() => {
                                setIsDuplicateModalOpen(false);
                            }}
                        />
                    )}

                    {loadingDeleteProjectIntegrationAction && <LoadingCircle className="margin-top margin-left" />}
                </BreadCrumbsAndActionButton>

                <TitleAndButtonsWrapper>
                    <HeaderTitle>{sidePaneTabName}</HeaderTitle>

                    <SavesChangesSection>
                        {errorMessage && (
                            <ErrorText>
                                <TextEllipsis>{errorMessage}</TextEllipsis>
                            </ErrorText>
                        )}

                        <Tooltip
                            disabled={!disabledSaveActionTooltip}
                            placement="bottom"
                            content={disabledSaveActionTooltip}
                        >
                            <SaveChangesButton
                                disabled={!!disabledSaveActionTooltip || !actionTabChanges.length}
                                size={ButtonSize.BIG}
                                onClick={onClickSaveChangesButton}
                                data-automation="project-integration-page-action-page-save-changes-button"
                                flex
                            >
                                Save Action Changes
                                {isUpdateActionLoading && <StyledLoadingCircle />}
                            </SaveChangesButton>
                        </Tooltip>
                    </SavesChangesSection>
                </TitleAndButtonsWrapper>
            </HeaderWrapper>

            {isDeleteModalOpen && (
                <ProjectIntegrationActionRemoveModal
                    projectIntegrationId={projectIntegration.id}
                    entityId={projectIntegrationAction.id}
                    entityDisplayName={projectIntegrationAction.displayName}
                    typeDisplayName="Custom Action"
                    open={isDeleteModalOpen}
                    onClose={toggleIsDeleteModalOpen}
                    onDelete={onDelete}
                    error={errorDeleteProjectIntegrationAction}
                    loading={loadingDeleteProjectIntegrationAction}
                    entityToSolutionAndGroupMappingProvider={useProjectIntegrationActionToSolutionAndGroupMapping}
                />
            )}
        </>
    );
};

export default ProjectIntegrationActionPageHeader;
