import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';

import type HistoryField from '../../../entities/HistoryField';
import getHistoryInitiativeFields from '../../../utils/getHistoryInitiativeFields';
import getInitiativeTableFieldsWithChangeValues from '../../../utils/getInitiativeTableFieldsWithChangeValues';
import HistoryFieldsTable from '../../HistoryFieldsTable/HistoryFieldsTable';
import HistoryFieldsTableFilters, {
    HistoryFieldsTableFiltersCheckbox,
    HistoryFieldsTableFiltersHeader,
    HistoryFieldsTableFiltersSearchBox,
} from '../../HistoryFieldsTable/HistoryFieldsTableFilters';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkerRun } from '@tonkean/tonkean-entities';

interface Props {
    /** If undefined, it means that it's the initial load of the worker run, and not just a reload. */
    workerRun?: WorkerRun;
}

const SingleWorkerRunModuleItemTable: React.FC<Props> = ({ workerRun }) => {
    const customFieldsManager = useAngularService('customFieldsManager');
    const customTriggerManager = useAngularService('customTriggerManager');
    const tonkeanUtils = useAngularService('tonkeanUtils');
    const [{ loading, error, data: customFieldDefinitions }, getFieldDefinitions] = useLazyAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
    );

    const workflowVersionId = workerRun?.workflowVersionId as TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    useEffect(() => {
        if (workflowVersionId) {
            getFieldDefinitions(workflowVersionId, false);
        }
    }, [getFieldDefinitions, workflowVersionId]);

    const [filterOnlyEmptyValue, setFilterOnlyEmptyValue] = useState(false);
    const [freeTextFilter, setFreeTextFilter] = useState('');

    const couldHaveChanged = workerRun?.initialInitiativeFieldsState && workerRun?.finalInitiativeFieldsStateOptional;

    const syncConfig = undefined;
    const tableFields: HistoryField[] = useMemo(() => {
        if (!customFieldDefinitions || !workerRun) {
            return [];
        }

        const fieldDefinitions = getHistoryInitiativeFields(customFieldDefinitions, syncConfig, tonkeanUtils);
        return getInitiativeTableFieldsWithChangeValues(
            fieldDefinitions,
            workerRun.initialInitiativeFieldsState,
            workerRun.finalInitiativeFieldsStateOptional,
            couldHaveChanged,
            workflowVersionId,
            customTriggerManager,
        ).filter((field) => !field.isGlobalField);
    }, [
        couldHaveChanged,
        customFieldDefinitions,
        customTriggerManager,
        syncConfig,
        tonkeanUtils,
        workerRun,
        workflowVersionId,
    ]);

    const emptyFieldsCount = useMemo(() => {
        return tableFields.filter((field) => !field.isMatchedEntity && field.value.isEmpty).length;
    }, [tableFields]);

    const noDataCollected =
        workerRun && !workerRun.initialInitiativeFieldsState && !workerRun.finalInitiativeFieldsStateOptional;
    const checkboxActive = !loading && !!workerRun && !noDataCollected;

    return (
        <>
            <HistoryFieldsTableFilters paddingTop>
                <HistoryFieldsTableFiltersHeader big>Module Item Fields</HistoryFieldsTableFiltersHeader>

                <HistoryFieldsTableFiltersCheckbox
                    checked={filterOnlyEmptyValue}
                    onChange={(e) => setFilterOnlyEmptyValue(e.target.checked)}
                    disabled={!checkboxActive}
                >
                    View empty fields {checkboxActive && `(${emptyFieldsCount})`}
                </HistoryFieldsTableFiltersCheckbox>

                <HistoryFieldsTableFiltersSearchBox
                    value={freeTextFilter}
                    onChange={(e) => setFreeTextFilter(e.target.value)}
                />
            </HistoryFieldsTableFilters>

            <HistoryFieldsTable
                couldHaveChanged={couldHaveChanged}
                tableInError={!!error}
                tableLoading={loading || !workerRun}
                noDataCollected={noDataCollected}
                fields={tableFields}
                workflowVersionId={workflowVersionId}
                syncConfig={syncConfig}
                freeTextFilter={freeTextFilter}
                filterOnlyEmptyValue={filterOnlyEmptyValue}
            />
        </>
    );
};

export default SingleWorkerRunModuleItemTable;
