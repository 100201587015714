/**
 * Helper for actions on integrations\project integrations
 */
import type CustomFieldsManager from './customFieldsManager.service';
import type WorkflowVersionManager from './workflowVersionManager.service';

function WorkflowVersionHelper(
    workflowVersionManager: WorkflowVersionManager,
    customFieldsManager: CustomFieldsManager,
) {
    const _this = this;

    _this.workflowVersionHasTriggers = (workflowVersionId) => {
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

        if (workflowVersion?.triggersCount && workflowVersion.triggersCount > 0) {
            return true;
        }

        const fieldDefinitions = customFieldsManager.getFieldDefinitionsFromCache(workflowVersionId);
        let hasColumnTriggers = false;
        if (fieldDefinitions && fieldDefinitions.length > 0) {
            // Enrich the group with "hasColumnTriggers" flag according to the field definitions ranges.
            // Go over the field definitions and check if one of its ranges is set as "isGather". If so, mark the group and stop iterations.
            for (const fieldDef of fieldDefinitions) {
                if (fieldDef.targetType === 'COLUMN' && fieldDef.ranges) {
                    for (let j = 0; j < fieldDef.ranges.length; j++) {
                        if (fieldDef?.ranges[j]?.isGather) {
                            hasColumnTriggers = true;
                            break;
                        }
                    }
                }
                if (hasColumnTriggers) {
                    break;
                }
            }
        }

        return hasColumnTriggers;
    };
}

angular.module('tonkean.app').service('workflowVersionHelper', WorkflowVersionHelper);
