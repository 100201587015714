import React from 'react';

export type InvalidLogics = {
    DATASOURCE?: {
        inAccessibleProjectIntegration?: boolean;
    };
};

export interface InvalidLogicsValidation {
    invalidLogics: InvalidLogics;
    requestLogicsValidation(): void;
}

const InvalidLogicsValidationContext = React.createContext<InvalidLogicsValidation>({
    invalidLogics: {},
    requestLogicsValidation: () => {},
});

export default InvalidLogicsValidationContext;
