import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import ProjectIntegrationActionHeadersLayout from './ProjectIntegrationActionHeadersLayout';
import projectIntegrationEntityCollectPageInputState from '../../states/projectIntegrationEntityCollectPageInputState';
import tryParseJson from '../../utils/tryParseJson';
import ProjectIntegrationJsonViewerLayout from '../common/ProjectIntegrationJsonViewerLayout';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    PageBrowser,
    Tab,
    Tabs,
    TabsBorderType,
    Tooltip,
} from '@tonkean/infrastructure';
import type { BasicExecutionResult, ProjectIntegrationActionTestRun } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const PagingWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    height: inherit;
    flex-direction: column;
`;

const UrlWrapper = styled.div`
    background-color: ${Theme.colors.gray_100};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
    padding: 8px;
    word-break: break-all;
`;

const StyledTab = styled(Tab)`
    margin-left: 0;
    padding-left: 0;

    &,
    &:hover,
    &:focus {
        background-color: unset;
    }
`;

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    height: 100%;
    overflow: hidden;
`;

enum TabName {
    BODY = 'BODY',
    URL = 'URL',
    HEADERS = 'HEADERS',
}

interface Props {
    testRunIds: string[];
    testRuns: ProjectIntegrationActionTestRun[];
    isResponse?: boolean;
}

const ProjectIntegrationEntityBodyAndHeadersLayout: React.FC<Props> = ({
    testRunIds,
    testRuns,
    isResponse = false,
}) => {
    // The selected tab.
    const [selectedTab, setSelectedTab] = useState<TabName>(isResponse ? TabName.BODY : TabName.URL);
    const [jsonPickerInput] = useRecoilState(projectIntegrationEntityCollectPageInputState);

    // The selected test run specific page number.
    const [testRunViewingSequence, setTestRunViewingSequence] = useState<number>(0);

    const viewingTestRun = useMemo(() => {
        const testRunId = testRunIds[testRunViewingSequence];
        return testRuns.find((testRun) => testRunId === testRun.id);
    }, [testRunIds, testRunViewingSequence, testRuns]);

    // The first retry request url.
    const resultOfFirstRetry: BasicExecutionResult | undefined = useMemo(() => {
        return viewingTestRun?.executionResultList.slice(-1)[0];
    }, [viewingTestRun?.executionResultList]);

    // The first retry request url.
    const url = useMemo(() => {
        return resultOfFirstRetry?.request?.url || '';
    }, [resultOfFirstRetry?.request?.url]);

    // is the request a get request
    const isGetRequest = useMemo(() => {
        return resultOfFirstRetry?.request?.method === 'GET';
    }, [resultOfFirstRetry?.request?.method]);

    const shouldDisableBodyTab = isGetRequest && !isResponse;

    const jsonBodyToDisplay = useMemo(() => {
        if (isResponse) {
            return tryParseJson(viewingTestRun?.executionResultList?.slice(-1)[0]?.response?.body || '');
        } else {
            return tryParseJson(viewingTestRun?.executionResultList?.slice(-1)[0]?.request?.body || '');
        }
    }, [isResponse, viewingTestRun?.executionResultList]);

    // When the test run id changed we reset the test run sequence to zero.
    useEffect(() => {
        if (testRunIds) {
            setTestRunViewingSequence(0);
        }
    }, [testRunIds]);

    return (
        <Wrapper>
            <Tabs
                borderType={TabsBorderType.NONE}
                onChange={setSelectedTab}
                value={selectedTab}
                showBackground={false}
                small
                hideIndicator
            >
                {!isResponse && <StyledTab label={TabName.URL}>Url</StyledTab>}
                <Tooltip disabled={!shouldDisableBodyTab} content="Get request does not contains body">
                    <StyledTab disabled={shouldDisableBodyTab} label={TabName.BODY}>
                        Body
                    </StyledTab>
                </Tooltip>
                <StyledTab label={TabName.HEADERS}>Headers</StyledTab>

                <PagingWrapper>
                    <PageBrowser
                        state={testRunViewingSequence + 1}
                        max={testRunIds.length}
                        setState={setTestRunViewingSequence}
                    />
                </PagingWrapper>
            </Tabs>

            {viewingTestRun && (
                <AnimatedSwitch activeLabel={selectedTab}>
                    <StyledAnimatedSwitchItem label={TabName.URL}>
                        <UrlWrapper>{url}</UrlWrapper>
                    </StyledAnimatedSwitchItem>

                    <StyledAnimatedSwitchItem label={TabName.BODY}>
                        <ProjectIntegrationJsonViewerLayout
                            showAsJsonPicker={isResponse}
                            jsonToDisplay={jsonBodyToDisplay}
                            jsonPickerOutputType={jsonPickerInput.jsonPickerOutputType}
                        />
                    </StyledAnimatedSwitchItem>

                    <StyledAnimatedSwitchItem label={TabName.HEADERS}>
                        <ProjectIntegrationActionHeadersLayout isResponse={isResponse} testRun={viewingTestRun} />
                    </StyledAnimatedSwitchItem>
                </AnimatedSwitch>
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationEntityBodyAndHeadersLayout;
