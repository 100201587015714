import React from 'react';

import ManualFieldWithTonkeanExpressionList from './ManualFieldWithTonkeanExpressionList';
import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type InitialFieldDefinitionValueExpression from '../entities/InitialFieldDefinitionValueExpression';
import useManualFieldDefinitions from '../hooks/useManualFieldDefinitions';

import { useFormikField } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { EMPTY_ARRAY } from '@tonkean/utils';

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    fieldDefinitions: FieldDefinition[] | undefined;
    isLoadingFieldDefinitions: boolean;
}
const LineItemsInitialValues: React.FC<Props> = ({
    workflowVersionId,
    groupId,
    fieldDefinitions,
    isLoadingFieldDefinitions,
}) => {
    const { setValue: setInitialExpressionValues, value: initialExpressionValues } = useFormikField<
        InitialFieldDefinitionValueExpression[] | undefined
    >('configuration.initialExpressionValues');

    const manualFieldDefinitions = useManualFieldDefinitions(fieldDefinitions);

    return (
        <>
            <ConfigurationSectionTitle>Set Initial Values</ConfigurationSectionTitle>

            <ManualFieldWithTonkeanExpressionList
                onChange={setInitialExpressionValues}
                value={initialExpressionValues || EMPTY_ARRAY}
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                isLoadingFieldDefinitions={isLoadingFieldDefinitions}
                fieldDefinitions={manualFieldDefinitions}
            />

            <ClickableLink
                onClick={() => {
                    setInitialExpressionValues([...(initialExpressionValues || []), { id: Math.random().toString() }]);
                }}
            >
                + Add new parameter
            </ClickableLink>
        </>
    );
};

export default LineItemsInitialValues;
