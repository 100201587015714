import type { TElement } from '@udecode/plate';
import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CoreEditorSerialize, HTMLEditor, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import { H3, Modal, ModalHeader, ModalSize, useCreateDialog, XCloseButton } from '@tonkean/infrastructure';
import type { Group } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const TemplateModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 12px;
    top: 12px;
`;

const EditClickable = styled(ClickableLink)`
    color: ${Theme.colors.primaryActive};
    font-size: ${FontSize.SMALL_12};
    position: absolute;
    right: 50px;
    top: 15px;
`;

const ApplyClickable = styled(Button)`
    font-size: ${FontSize.SMALL_12};
    position: absolute;
    right: 50px;
    top: 15px;
`;

const Editor = styled(HTMLEditor)`
    height: 100%;
    overflow: auto;
`;

const Serializer = styled(CoreEditorSerialize)``;

const StyledDiv = styled.div<{ isEditModeEnabled: boolean }>`
    width: 700px;
    height: 700px;
    padding: ${(props) => (props.isEditModeEnabled ? '0' : `20`)}px;
    overflow: auto !important;
`;

interface Props {
    onCloseModal(): void;

    show: boolean;
    group: Group;
    workflowVersionType: WorkflowVersionType;
}

const GroupDescriptionModal: React.FC<Props> = ({ onCloseModal, show, group, workflowVersionType }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const tonkeanService = useAngularService('tonkeanService');
    const { confirm } = useCreateDialog();

    const editable = workflowVersionType === WorkflowVersionType.DRAFT;

    const [htmlValue, setHtmlValue] = useState<TElement[]>([]);
    const [oldHtmlValue, setOldHtmlValue] = useState('');
    const [isEditModeEnabled, setEditModeEnabled] = useState(false);
    const [isValueChanged, setIsValueChanged] = useState(false);

    useEffect(() => {
        const htmlValueFromCache =
            workflowVersionType === WorkflowVersionType.DRAFT
                ? workflowVersionManager.getDraftVersionFromCache(group.id)?.htmlDescription
                : workflowVersionManager.getPublishedVersionFromCache(group.id)?.htmlDescription;

        setHtmlValue(htmlValueFromCache || []);
        setOldHtmlValue(JSON.stringify(htmlValueFromCache || []));
    }, [group.id, workflowVersionManager, workflowVersionType]);

    // Called when a user apply the changes he made
    const applyChanges = () => {
        onCloseModal();
        workflowVersionManager.incrementWorkflowVersionCounter(group.draftWorkflowVersionId);
        workflowVersionManager.updateWorkflowVersion({ id: group.draftWorkflowVersionId, htmlDescription: htmlValue });
        tonkeanService.updateWorkflowVersionDescription(group.id, htmlValue);
    };

    // Called when a user close the modal
    const close = async () => {
        if (isEditModeEnabled && isValueChanged) {
            const result = await confirm('Are you sure?', 'Readme changes have been made.', {
                okLabel: 'Save',
                cancelLabel: 'Discard',
            });
            if (result) {
                return applyChanges();
            }
        }

        onCloseModal();
    };

    const isDescriptionChanged = (val) => {
        return JSON.stringify(val) !== oldHtmlValue;
    };

    const onChangeHtml = (val: TElement[]) => {
        setHtmlValue(val);
        setIsValueChanged(isDescriptionChanged(val));
    };

    const buildSerializedPlaceholder = 'Describe your module - click edit to start';

    const publishedSerializedPlaceholder = 'Describe your module - go to build environment to edit';

    return (
        <>
            <Modal open={show} size={ModalSize.XLARGE} onClose={close} hasBackdrop>
                <ModalHeader>
                    <H3>{group.name} Readme</H3>
                    {editable && (
                        <>
                            {isEditModeEnabled ? (
                                <ApplyClickable
                                    disabled={!isValueChanged}
                                    size={ButtonSize.SMALL}
                                    data-automation="group-description-editable-readme"
                                    onClick={applyChanges}
                                >
                                    {' '}
                                    {isValueChanged ? 'Save' : 'Saved'}{' '}
                                </ApplyClickable>
                            ) : (
                                <EditClickable onClick={() => setEditModeEnabled(true)}> Edit </EditClickable>
                            )}
                        </>
                    )}

                    <TemplateModalCloseButton size={ButtonSize.BIG} onClick={close} />
                </ModalHeader>

                <StyledDiv isEditModeEnabled={isEditModeEnabled}>
                    {isEditModeEnabled ? (
                        <Editor
                            placeholder="Describe your module..."
                            initialValue={htmlValue}
                            onChange={onChangeHtml}
                        />
                    ) : (
                        <>
                            <Serializer plugins={HTMLEditorFullPlatePluginsList} value={htmlValue} />
                            {(!htmlValue || htmlValue.length === 0) && (
                                <p>
                                    {workflowVersionType === WorkflowVersionType.DRAFT
                                        ? buildSerializedPlaceholder
                                        : publishedSerializedPlaceholder}
                                </p>
                            )}
                        </>
                    )}
                </StyledDiv>
            </Modal>
        </>
    );
};

export default GroupDescriptionModal;
