import React from 'react';
import styled from 'styled-components';

import FieldMapperFieldRow, { type Field } from './FieldMapperFieldRow';
import { ReactComponent as FieldValueIcon } from '../../../../images/icons/field-value.svg';

import { H1, H4, Paragraph } from '@tonkean/infrastructure';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SectionHeader = styled(H4)`
    padding: 10px;
    background-color: ${Theme.colors.gray_100};
    border-bottom: 2px solid ${Theme.colors.gray_300};
`;

const TitleWrapper = styled.div`
    padding: 15px;
    background-color: ${Theme.colors.gray_100};
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
`;

const SectionWrapper = styled.div`
    border-top: 2px solid ${Theme.colors.gray_300};
`;

const FieldSubText = styled(Paragraph)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledFieldValueIcon = styled(FieldValueIcon)`
    margin-right: 5px;
`;

const EditButton = styled(IconButton)`
    margin-left: 5px;
`;

const DisplayNameWrapper = styled(H1)`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 0;
    margin-bottom: 5px;
`;

const Wrapper = styled.div`
    border: 2px solid ${Theme.colors.gray_300};
    border-radius: 6px;
`;

const EmptyDataRow = styled(Paragraph)`
    padding: 10px 20px;
`;

export interface Section {
    id: string;
    name: string;
    itemValue?: string;
    fields: Field[];
}

interface FieldMapperTableProps {
    titleDisplayName: string;
    sections: Section[];
    onClickEditField: (fieldName: string, sectionName: string) => void;
    icon: React.ReactNode;
}

const FieldMapperTable: React.FC<FieldMapperTableProps> = ({ titleDisplayName, sections, onClickEditField, icon }) => {
    return (
        <Wrapper>
            <TitleWrapper>
                <DisplayNameWrapper>
                    {icon}
                    {titleDisplayName}
                </DisplayNameWrapper>
                <FieldSubText $color={Theme.colors.gray_600}>
                    <StyledFieldValueIcon />
                    External Activity Name
                </FieldSubText>
            </TitleWrapper>

            {sections.map((section, index) => (
                <SectionWrapper key={section.name}>
                    <SectionHeader $bold>{section.name}</SectionHeader>
                    {section.fields.length > 0 ? (
                        section.fields.map((field) => (
                            <FieldMapperFieldRow
                                key={field.name}
                                field={field}
                                onClickEditField={() => onClickEditField(field.name, section.id)}
                            />
                        ))
                    ) : (
                        <EmptyDataRow $color={Theme.colors.gray_600}>No Items</EmptyDataRow>
                    )}
                </SectionWrapper>
            ))}
        </Wrapper>
    );
};
export default FieldMapperTable;
