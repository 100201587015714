import type FormulaField from './FormulaField';
import type { formulaTreeNode } from './formulaTreeNodes';
import { FormulaTreeEmptyNode } from './formulaTreeNodes';
import { FormulaTreeConstNode } from './formulaTreeNodes';
import { FieldType } from '../FieldDefinition';

/**
 * Create an empty node, or a const node if field has a default value.
 *
 * @param field - the formula field definition for the node.
 * @returns a new empty or const node based on the field definition.
 */
function createEmptyFormulaNode(field: FormulaField): formulaTreeNode {
    if (field.defaultValue === undefined || field.defaultValue === null) {
        return new FormulaTreeEmptyNode(field);
    }

    switch (typeof field.defaultValue) {
        case 'boolean':
            return new FormulaTreeConstNode(FieldType.Boolean, field.defaultValue.toString(), field);
        case 'number':
            return new FormulaTreeConstNode(FieldType.Number, field.defaultValue.toString(), field);
        case 'string':
            return new FormulaTreeConstNode(FieldType.String, field.defaultValue.toString(), field);
    }
}

export default createEmptyFormulaNode;
