import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function FrontDoorActionLogicConfigurationCtrl(
    $scope,
    projectManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        message: null,
        conversationId: null,
    };

    /**
     * Initialization function for the controller.
     */
    ctrl.$onInit = function () {
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();

            if ($scope.data.configuredLogic.node.customTriggerType !== 'UNKNOWN') {
                $scope.onActionsChanged(false);
            }
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
        }
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {

            const definition = {
                message: $scope.data.message,
                conversationId: $scope.data.conversationId,
            };


            const actionDefinition = {
                actions: [
                    {
                        type: 'FRONT_DOOR_ACTION',
                        definition,
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Initializes the edit mode of the logic configure component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'FRONT_DOOR_ACTION',
        );

        if (customTriggerAction) {
            const definition = customTriggerAction.customTriggerActionDefinition;
            $scope.data.message = definition.message;
            $scope.data.conversationId = definition.conversationId;
        }
    }

    $scope.onMessageExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.message = expression;

        $scope.onActionsChanged(shouldSaveLogic);
    };


    $scope.onConversationIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.conversationId = expression;

        $scope.onActionsChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('FrontDoorActionLogicConfigurationCtrl', lateConstructController(FrontDoorActionLogicConfigurationCtrl));
