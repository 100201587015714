import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import HandleResponseHandleActionResponse from './HandleResponseHandleActionResponse';
import ResponseDetailsView from './ResponseDetailsView';
import type { BaseProjectIntegrationActionManagerProps } from '../../../../../../../components/state.product.projectIntegrationActionManager/baseProjectIntegrationActionManagerProps';
import type { AdditionalSidePaneConfiguration } from '../../../../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationActionTestRunSelector from '../ProjectIntegrationActionTestRunSelector/ProjectIntegrationActionTestRunSelector';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import type { IntegrationSupportedEntity } from '@tonkean/tonkean-entities';
import { ActionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const ContentWrapper = styled.div`
    padding-top: 29px;
    padding-left: 24px;
    display: grid;
    grid-template-columns: 5fr 4fr;
`;

const ActionSummaryText = styled.span`
    color: ${Theme.colors.gray_600};
`;

const PreviewTestResponseTitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    margin-bottom: 16px;
`;

const ActionSummaryTextWrapper = styled.div`
    margin-bottom: 28px;
    font-size: ${FontSize.SMALL_12};
    font-weight: 400;
`;
const LeftSection = styled.div`
    padding-right: 80px;
`;

const RightSection = styled.div``;

const ProjectIntegrationActionTestRunSelectorWrapper = styled.div`
    margin-bottom: 30px;
`;

const ProjectIntegrationActionHandleResponseTab: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationActionManagerProps>
> = ({ projectIntegrationAction, projectIntegration, switchSelectedTab, setProjectIntegrationAction, setTabError }) => {
    // Validation for success and failure definition.

    const integrationMetadataManager = useAngularService('integrationMetadataManager');

    const { data: supportedEntitiesDict } = useAsyncMethod(
        integrationMetadataManager,
        'getIntegrationEntitiesAsDict',
        projectIntegrationAction.projectIntegrationId,
    );

    const entity: IntegrationSupportedEntity | undefined = useMemo(() => {
        const entityName = projectIntegrationAction.parametersDefinition?.entitiesToRunOn?.[0];

        if (supportedEntitiesDict && entityName) {
            return supportedEntitiesDict[entityName];
        }
    }, [supportedEntitiesDict, projectIntegrationAction.parametersDefinition?.entitiesToRunOn]);

    useEffect(() => {
        if (
            projectIntegrationAction.responseHandlingDefinition?.responseHandlingType ===
            EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS
        ) {
            const successDefinition = projectIntegrationAction.responseHandlingDefinition?.successDefinition;
            const failureDefinition = projectIntegrationAction.responseHandlingDefinition?.failureDefinition;

            // Success definition validation.
            if (successDefinition) {
                const namesList = successDefinition.outputLinkedFields.map(
                    (outputLinkedField) => outputLinkedField.name,
                );

                const hasEmptyName = !namesList.every((name) => !utils.isNullOrEmpty(name));
                const hasEmptyValue = !successDefinition.outputLinkedFields.every(
                    (outputLinkedField) => !utils.isNullOrEmpty(outputLinkedField.value.originalExpression),
                );
                const hasDuplicateNames = utils.hasDuplicatesValues(namesList);

                if (hasEmptyName || hasEmptyValue || hasDuplicateNames) {
                    return setTabError(true);
                }

                // Failure definition validation.
                if (failureDefinition) {
                    const hasInvalidFailCondition = !failureDefinition.bodyContainsConditions.every(
                        (bodyContainsCondition) =>
                            !utils.isNullOrEmpty(bodyContainsCondition.errorMessageToDisplay) &&
                            bodyContainsCondition.textToContain.length !== 0,
                    );
                    if (hasInvalidFailCondition) {
                        return setTabError(true);
                    }
                }
                return setTabError(false);
            }
        }
    }, [projectIntegrationAction, setTabError]);

    const actionSummaryText = useMemo(() => {
        switch (projectIntegrationAction.actionType) {
            case ActionType.UPDATE:
                return `update entity: ${entity?.pluralLabel}`;
            case ActionType.CREATE:
                return `create entity: ${entity?.pluralLabel}`;
            case ActionType.CREATE_OR_UPDATE:
                return `create or update entity: ${entity?.pluralLabel}`;
            case ActionType.OAUTH2_REFRESH_AUTHENTICATION:
                return `refresh variables values`;
            case ActionType.UPLOAD:
                return 'upload a file';
            case ActionType.DOWNLOAD:
                return 'download a file configured';
            case ActionType.STREAM_IMAGE:
                return 'stream image';
        }
    }, [entity?.pluralLabel, projectIntegrationAction]);

    const { data: testRunsResponse, loading } = useTonkeanService(
        'getProjectIntegrationActionTestRuns',
        projectIntegration.id,
        projectIntegrationAction.id,
    );

    const [selectedTestRunIds, setSelectedTestRunIds] = useState<string[]>();

    const selectedTestRun = useMemo(() => {
        // For now we support only first test run id because in action we dont support paginated requests
        const firstTestRunId = selectedTestRunIds?.[0];

        return testRunsResponse?.entities.find((testRun) => testRun.id === firstTestRunId);
    }, [selectedTestRunIds, testRunsResponse?.entities]);

    return (
        <ContentWrapper>
            <LeftSection>
                {projectIntegrationAction.actionType !== ActionType.CUSTOM && (
                    <ActionSummaryTextWrapper>
                        <ActionSummaryText>This action will </ActionSummaryText>
                        <span>{actionSummaryText}</span>
                    </ActionSummaryTextWrapper>
                )}

                <PreviewTestResponseTitle>
                    Preview test response ({(testRunsResponse?.entities || []).length})
                </PreviewTestResponseTitle>

                <ProjectIntegrationActionTestRunSelectorWrapper>
                    <ProjectIntegrationActionTestRunSelector
                        isLoading={loading}
                        testRuns={testRunsResponse?.entities || []}
                        onTestRunSelected={setSelectedTestRunIds}
                        selectedTestRunIds={selectedTestRunIds}
                        customTestName="Test Action -"
                        autoSelectFirstTestRun
                    />
                </ProjectIntegrationActionTestRunSelectorWrapper>

                <ResponseDetailsView
                    switchToRequestTab={switchSelectedTab}
                    projectIntegrationActionTestRun={selectedTestRun}
                />
            </LeftSection>

            <RightSection>
                <HandleResponseHandleActionResponse
                    projectIntegration={projectIntegration}
                    projectIntegrationAction={projectIntegrationAction}
                    setProjectIntegrationAction={setProjectIntegrationAction}
                    viewingTestResponseId={selectedTestRunIds?.[0]}
                    entity={entity}
                />
            </RightSection>
        </ContentWrapper>
    );
};

export default ProjectIntegrationActionHandleResponseTab;
