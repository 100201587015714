import SeparatorWidget from './Components/SeparatorWidget';
import SeparatorWidgetEditor from './Components/SeparatorWidgetEditor';
import type SeparatorWidgetConfiguration from './SeparatorWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const SeparatorWidgetPackage: ItemInterfaceWidgetPackage<SeparatorWidgetConfiguration> = {
    displayComponent: SeparatorWidget,
    configurationComponent: SeparatorWidgetEditor,
};

export default SeparatorWidgetPackage;
