/**
 * This directive is used when you want to have a callback for when an iframe is loaded.
 */
export default angular.module('tonkean.app').directive('iframeOnload', [
    () => {
        return {
            scope: {
                callBack: '&iframeOnload',
            },
            link(scope, element) {
                // Registering the callback to the load function of the iframe.
                element.on('load', () => {
                    return scope.callBack();
                });

                // Once destroyed, unregister the callback from the load function of the iframe.
                scope.$on('$destroy', () => {
                    element.off('load');
                });
            },
        };
    },
]);
