<div class="edit-integration-button">
    <tnk-integration-group
        sources="[data.projectIntegration.integration.integrationType.toLowerCase()]"
        ng-model="ignored"
        display-mode="edit-button"
        edit-mode="true"
        open-modal-for-configuration="true"
        state="projectIntegrationToStateMap[data.projectIntegration.id].state"
        current-integration="ignored"
        integration-changed="onIntegrationSaved(changedProjectIntegration)"
        hide-update-data-menu-option="true"
        hide-data-retention-menu-option="true"
        do-not-run-collect-after-saving-integration="true"
        icon-url-override="data.integrationOption.iconUrl"
    ></tnk-integration-group>
</div>
