import type { BasicQueryDefinition } from '@tonkean/tonkean-entities';
import { FieldQueryType } from '@tonkean/tonkean-entities';

const EMPTY_CONDITIONS_DEFINITION: BasicQueryDefinition = {
    query: {
        type: FieldQueryType.And,
        filters: [],
        queries: [],
    },
};

export default EMPTY_CONDITIONS_DEFINITION;
