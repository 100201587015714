import PreviewMatchedEntityItemsModalTrigger from './PreviewMatchedEntityItemsModalTrigger';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPreviewMatchedEntityItemsModalTrigger',
        reactToAngular(PreviewMatchedEntityItemsModalTrigger, ['workflowVersionId', 'matchedEntityId', 'initiativeId']),
    );
