import React from 'react';
import styled from 'styled-components';

import { IconSvg, Input } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { SearchIcon } from '@tonkean/svg';
import { RerunIcon } from '@tonkean/svg';
import { IconButton } from '@tonkean/tui-buttons/Button';

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
`;
const StyledInput = styled(Input)`
    position: relative;
    height: unset;
    min-height: 34px;
    padding: 14px 47px;

    border-radius: 50px;

    font-size: 15px;
    line-height: 16px;

    &:focus {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.14);
    }
`;

const StyledSearchIcon = styled(IconSvg)`
    position: absolute;
    z-index: 1;
    left: 30px;
`;

const ResubmitButton = styled(IconButton)`
    position: absolute;
    right: 20px;

    cursor: pointer;

    line-height: 0;
`;

interface Props {
    inputName: string;
    onResubmitButtonClick?: () => void;
    resubmitTooltip?: string;
}
const AIPromptInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    { inputName, onResubmitButtonClick, resubmitTooltip },
    ref,
) => {
    return (
        <InputWrapper>
            <StyledSearchIcon as={SearchIcon} size={14} />

            <StyledInput name={inputName} ref={ref} autoComplete="off" />

            <Tooltip content={resubmitTooltip || 'Try another search'}>
                <ResubmitButton onClick={onResubmitButtonClick} flat>
                    <IconSvg as={RerunIcon} size={16} />
                </ResubmitButton>
            </Tooltip>
        </InputWrapper>
    );
};

export default React.forwardRef(AIPromptInput);
