import React from 'react';

import type { SolutionSiteEditorProps } from '../../../../WidgetModule';
import ActionsItemWidgetConfigurationEditorDisplay from '../../components/ActionsItemWidgetConfigurationEditorDisplay';

import type { ActionsItemWidgetConfiguration } from '@tonkean/tonkean-entities';

const SolutionSitePageActionsItemWidgetConfigurationEditor: React.FC<
    SolutionSiteEditorProps<ActionsItemWidgetConfiguration>
> = () => {
    return <ActionsItemWidgetConfigurationEditorDisplay workflowVersion={undefined} />;
};

export default SolutionSitePageActionsItemWidgetConfigurationEditor;
