import React from 'react';
import styled from 'styled-components';

import TaggingOption from './TaggingOption';
import { ReactComponent as FieldValuePreviewIcon } from '../../../../../images/icons/field-value-preview.svg';
import { ReactComponent as RequestFieldItemIcon } from '../../../../../images/icons/request-field-item.svg';
import type { TaggableEntitiesItemsProps } from '../../../entities';

import { FontSize, Theme } from '@tonkean/tui-theme';

const IconWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 9px;
    align-items: center;
    gap: 10px;
`;

const FieldTitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
`;

const FieldValue = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    margin-left: 4px;
`;

const FieldDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FieldValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const RequestFieldsTagging: React.FC<TaggableEntitiesItemsProps> = ({
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
}) => {
    return (
        <TaggingOption $focused={focused}>
            <IconWrapper>
                <RequestFieldItemIcon />
            </IconWrapper>
            <FieldDataWrapper>
                <FieldTitle data-automation={`request-field-tagging-${entry.display}`}>{entry.display}</FieldTitle>
                <FieldValueWrapper>
                    <FieldValuePreviewIcon />
                    <FieldValue>{entry.previewValue}</FieldValue>
                </FieldValueWrapper>
            </FieldDataWrapper>
        </TaggingOption>
    );
};

export default RequestFieldsTagging;
