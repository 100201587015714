import React, { useState } from 'react';
import styled from 'styled-components';

import MarketplaceItemCreateModalDataSourceActionsTab from './MarketplaceItemDataSourceTabs/MarketplaceItemCreateModalDataSourceActionsTab';
import MarketplaceItemCreateModalDataSourceEntitiesTab from './MarketplaceItemDataSourceTabs/MarketplaceItemCreateModalDataSourceEntitiesTab';
import MarketplaceItemCreateModalDataSourceParametersTab from './MarketplaceItemDataSourceTabs/MarketplaceItemCreateModalDataSourceParametersTab';

import { AnimatedSwitch, AnimatedSwitchItem, Tab, Tabs } from '@tonkean/infrastructure';
import type {
    EnterpriseComponentAuthorizationType,
    ProjectIntegrationActionMarketplaceSummary,
    ProjectIntegrationEntityMarketplaceSummary,
    ProjectIntegrationParameterMarketplaceSummary,
} from '@tonkean/tonkean-entities';
import { AuthorizationTypeToDisplayName } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

enum DataSourceSettingsTabs {
    PARAMETERS = 'Parameters',
    ACTIONS = 'Actions',
    ENTITIES = 'Entities',
}

const AuthorizationTypeWrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-weight: 500;
`;

const AuthorizationTypeLabel = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: 12px;
`;

const AuthorizationTypeValue = styled.div`
    color: ${Theme.colors.gray_700};
    font-size: 13px;
`;

interface Props {
    authorizationType: EnterpriseComponentAuthorizationType;
    entities: ProjectIntegrationEntityMarketplaceSummary[];
    actions: ProjectIntegrationActionMarketplaceSummary[];
    parameters: ProjectIntegrationParameterMarketplaceSummary[];
    loading?: boolean;
}

const MarketplaceItemCreateModalDataSourceDataSourceSection: React.FC<Props> = ({
    authorizationType,
    entities,
    actions,
    parameters,
    loading,
}) => {
    const [selectedTab, setSelectedTab] = useState(DataSourceSettingsTabs.PARAMETERS);

    return (
        <>
            <>
                {authorizationType && (
                    <AuthorizationTypeWrapper>
                        <AuthorizationTypeLabel>Authorization Type:</AuthorizationTypeLabel>

                        <AuthorizationTypeValue>
                            {AuthorizationTypeToDisplayName[authorizationType]}
                        </AuthorizationTypeValue>
                    </AuthorizationTypeWrapper>
                )}

                <Tabs value={selectedTab} onChange={setSelectedTab} showBackground={false}>
                    <Tab label={DataSourceSettingsTabs.PARAMETERS}>{DataSourceSettingsTabs.PARAMETERS}</Tab>
                    <Tab label={DataSourceSettingsTabs.ACTIONS}>{DataSourceSettingsTabs.ACTIONS}</Tab>
                    <Tab label={DataSourceSettingsTabs.ENTITIES}>{DataSourceSettingsTabs.ENTITIES}</Tab>
                </Tabs>

                <AnimatedSwitch activeLabel={selectedTab}>
                    <AnimatedSwitchItem label={DataSourceSettingsTabs.PARAMETERS}>
                        <MarketplaceItemCreateModalDataSourceParametersTab loading={loading} />
                    </AnimatedSwitchItem>

                    <AnimatedSwitchItem label={DataSourceSettingsTabs.ACTIONS}>
                        <MarketplaceItemCreateModalDataSourceActionsTab actions={actions} loading={loading} />
                    </AnimatedSwitchItem>

                    <AnimatedSwitchItem label={DataSourceSettingsTabs.ENTITIES}>
                        <MarketplaceItemCreateModalDataSourceEntitiesTab entities={entities} loading={loading} />
                    </AnimatedSwitchItem>
                </AnimatedSwitch>
            </>
        </>
    );
};

export default MarketplaceItemCreateModalDataSourceDataSourceSection;
