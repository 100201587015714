import type { Initiative } from '@tonkean/tonkean-entities';

/**
 * Parses the given initiative's parents before returning them.
 * Parses out parents without titles (which we can get if the user is not allowed to see some parents).
 * This function is intended to be used in the UI via a one-time binding, in times where the user can NOT dynamically change the parents, so the data remains static.
 * Note: Do not call this function not in a one-time binding since it will always return a new array reference and you will run into an infinite digest loop.
 */
export function parseParents(initiative: Initiative, itemMap: Record<string, Initiative>) {
    const parents: Initiative[] = [];

    if (initiative?.parentInitiatives && itemMap) {
        for (let i = 0; i < initiative.parentInitiatives.length; i++) {
            const parent = initiative.parentInitiatives[i] as Initiative;

            // Only take the parent if we know its title (which is the most basic data we require and present).
            if (parent && (parent.title || itemMap[parent.id]!.title)) {
                parents.push(parent);
            }
        }
    }

    return parents;
}
