import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';

import type { IDPLoginData } from '@tonkean/tonkean-entities';
import utils, { debouncer } from '@tonkean/utils';

interface Props {
    values: any;
    useSubdomain: boolean;
    setOktaResult: (result: Promise<IDPLoginData>) => void;
    setEmailValid: (valid: boolean) => void;
    isEmailValid: boolean;
}

const oktaDebouncer = debouncer(1000);

/**
 * A component in charge of fetching the login info required to perform Okta SSO authorization.
 * This is required because of a bug where authorization popups don't open in Safari with popups disabled,
 * unless initiated from a user interaction. For some reason, sending a fetch request inside the `onSubmit` handler of
 * the login form makes Safari suspect the action was not initiated by the user.
 * To overcome this we must fetch the data before the user click 'Login'. We do this through the `useEffect` which fetches the data
 * and saves the request promise.
 * The request is debounced so we don't get 429 from the server. We only store the promise of the request and not the result,
 * so we always have the last request even if the user keeps typing.
 *
 * Originating bug: https://tonkean.atlassian.net/browse/TNKN-22105
 */
const OktaLoginFetcher: React.FC<Props> = ({ values, useSubdomain, setOktaResult, setEmailValid, isEmailValid }) => {
    const tonkeanService = useAngularService('tonkeanService');

    useEffect(() => {
        if (!useSubdomain && utils.isValidEmail(values['email'])) {
            setEmailValid(true);
        }
    }, [setEmailValid, useSubdomain, values]);

    useEffect(() => {
        const oktaUri = useSubdomain ? values['subDomain'] + values['selectedOktaUrlSuffix'] : '';
        const domain = !useSubdomain && isEmailValid ? utils.getCompanyEmailDomain(values['email']) : '';

        oktaDebouncer(
            () => (!!domain || !!oktaUri) && setOktaResult(tonkeanService.getIDPLoginInfo('okta', oktaUri, domain)),
        );
    }, [isEmailValid, setOktaResult, tonkeanService, useSubdomain, values]);

    return <></>;
};

export default OktaLoginFetcher;
