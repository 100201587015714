import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useSolutionSiteContext } from '../../hooks';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field } from '@tonkean/infrastructure';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const SectionItem = styled(Field)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    margin-top: 16px;

    tnk-tonkean-expression {
        width: 100%;
    }
`;

interface Props extends DataAutomationSupportProps {
    label?: string;
    placeholder: string;
    name: string;
}

const SolutionSitePageWidgetUrlExpression: React.FC<Props> = ({ label, placeholder, name, dataAutomation }) => {
    const { solutionSite } = useSolutionSiteContext();
    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');
    const customFieldsManager = useAngularService('customFieldsManager');

    useEffect(() => {
        customFieldsManager.getGlobalFieldDefinitionsByWorkflowFolderId(
            solutionSite.workflowFolderId,
            entityVersionType,
        );
    }, [customFieldsManager, entityVersionType, solutionSite.workflowFolderId]);

    return (
        <SectionItem label={label} light>
            <FormikTonkeanExpression
                placeholder={placeholder}
                name={name}
                workflowFolderId={solutionSite.workflowFolderId}
                entityVersionType={entityVersionType}
                dataAutomation={dataAutomation}
                hideEditorButton
                globalExpressionOnly
            />
        </SectionItem>
    );
};

export default SolutionSitePageWidgetUrlExpression;
