import template from './tnkSyncTracks.template.html?angularjs';

function tnkSyncTracks() {
    return {
        restrict: 'E',
        scope: {
            control: '=',
            enabled: '=',

            projectIntegration: '=',
            selectedEntity: '=',
            selectedEntityMetadata: '=',
            syncData: '=',
            viewType: '=',
            workflowVersionId: '@',

            targetEntityId: '@',
            sectionVisibility: '<',
            hideSectionHeaders: '<',
            hideTimeRangeSelection: '<',

            modSmall: '<',

            groupId: '=', // We support both group sync and initiative sync.
            initiative: '=', // We support both group sync and initiative sync.

            disableFields: '=',
            updateGroupName: '=',
            collapsed: '=',
            onCompleted: '&',
            onError: '&',
            dismiss: '&',
        },
        template,
        controller: 'SyncTracksCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkSyncTracks', tnkSyncTracks);
