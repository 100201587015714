import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class PreviousValueFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.PREVIOUS_VALUE;
    override readonly sign = 'PreviousValue';
    override readonly displayName = 'Previous Value';
    override readonly description = 'Returns the previous value this field had.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            allowedTabsOnly: [FormulaPopoverTab.VARIABLE],
        },
    ];
}
