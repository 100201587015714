import type { LogicImpact } from '@tonkean/tonkean-entities';

const formAnsweredCanCreateIsSequenceEnd = (configuredLogic: LogicImpact) => {
    const definition = configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition;
    if (!definition || (!definition?.selectedActionType && !definition?.selectedButtonType)) {
        return true;
    }

    const endSequenceActionTypes = new Set(['continue-flow', 'go-to-sequence', 'open-link', 'open-custom-interface']);
    return !(
        endSequenceActionTypes.has(definition?.selectedActionType) ||
        endSequenceActionTypes.has(definition?.selectedButtonType)
    );
};
export default formAnsweredCanCreateIsSequenceEnd;
