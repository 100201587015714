import template from './tnkHtmlEditorEditLink.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkHtmlEditorEditLink', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        logicId: '@',
        onSubmit: '&',
    },
    template,
    controller: 'HtmlEditorEditLinkCtrl',
});
