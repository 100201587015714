import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function AmazonTextractCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * File Source Selection
     */
    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Analyze Document
     */
    $scope.onS3ObjectBucketTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.s3ObjectBucketExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onS3ObjectNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.s3ObjectNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onS3ObjectVersionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.s3ObjectVersionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'AmazonTextractCustomActionsDefinitionCtrl',
        lateConstructController(AmazonTextractCustomActionsDefinitionCtrl),
    );
