import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import AutosaveToggle from '../../../../../../infrastructure/components/AutosaveToggle';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { Tooltip } from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface Props {
    module: Group;

    // Right now the component only supports production environment
    environment: Environment;

    folderId: string;
}

const GridToggle: React.FC<Props> = ({ module, environment, folderId }) => {
    const groupManager = useAngularService('groupManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const projectManager = useAngularService('projectManager');
    const authenticationService = useAngularService('authenticationService');

    const [, setWorkerEnabled] = useLazyAsyncMethod(groupManager, 'setWorkerEnabled');
    const toStateLabel = module.workerEnabled ? 'Off' : 'On';

    const currentUserId = authenticationService.getCurrentUser().id;
    const isCurrentUserMaker = useMemo(() => {
        const workflowFolderMakers =
            workflowFolderManager.projectIdToFolderIdToMakersMap[projectManager.project.id]?.[folderId];
        return workflowFolderMakers?.some((person) => person.id === currentUserId);
    }, [workflowFolderManager.projectIdToFolderIdToMakersMap, projectManager.project.id, folderId, currentUserId]);

    const toggleModule = () => {
        return setWorkerEnabled(module, !module.workerEnabled);
    };

    return (
        <Tooltip content="Only solution makers can turn on and off environments" disabled={isCurrentUserMaker}>
            <AutosaveToggle
                active={environment === 'production' ? module.workerEnabled : module.buildEnvironmentEnabled}
                disabled={!isCurrentUserMaker}
                onChange={toggleModule}
                size={InputSize.MEDIUM}
                errorMessage={`Error in turning ${toStateLabel} module ${module.name} in Production environment`}
                confirmDialog={{
                    title: `Turning the production environment ${toStateLabel}`,
                    content: `Are you sure you want to turn ${module.name} production environment ${toStateLabel}?`,
                }}
            />
        </Tooltip>
    );
};

export default GridToggle;
