import { useEffect, useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { InterfaceCTASavedAction, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { type ActionInfo, type TonkeanType, type TonkeanId, InterfaceCTAActionType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const useItemsGridInlineMenuActions = (
    widgetConfigurationAction: InterfaceCTASavedAction[],
    workflowVersionType: WorkflowVersionType | undefined,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
): ActionInfo[] | undefined => {
    const [{ data: inlineActionInfo }, getCallToActionInfo] = useLazyTonkeanService('getCallToActionInfo');

    useEffect(() => {
        const systemActionTypes = new Set([InterfaceCTAActionType.DELETE_ITEM, InterfaceCTAActionType.CLONE_ITEM]);
        const actionsToFetchDisplayName = widgetConfigurationAction
            .filter((action) => action.shown)
            .filter((action) => !systemActionTypes.has(action.type))
            .map((action) => action.id) as TonkeanId[];

        if (workflowVersionType && groupId) {
            getCallToActionInfo(groupId, workflowVersionType, actionsToFetchDisplayName);
        }
    }, [getCallToActionInfo, groupId, widgetConfigurationAction, workflowVersionType]);

    return useMemo((): ActionInfo[] | undefined => {
        const actionsWithDisplayName = [
            ...(inlineActionInfo?.formsData || EMPTY_ARRAY),
            ...(inlineActionInfo?.customTriggersData || EMPTY_ARRAY),
            ...(inlineActionInfo?.itemInterfacesData || EMPTY_ARRAY),
        ];
        const idToActionWithDisplayNameMap = new Map(actionsWithDisplayName.map((action) => [action.id, action]));
        // Need to manually add it into display name Map since it is not a real action
        idToActionWithDisplayNameMap.set(InterfaceCTAActionType.DELETE_ITEM, {
            id: InterfaceCTAActionType.DELETE_ITEM,
            type: InterfaceCTAActionType.DELETE_ITEM,
            displayName: 'Delete Item',
        });
        return widgetConfigurationAction
            .filter((action) => action.shown && idToActionWithDisplayNameMap.has(action.id))
            .map(
                (action): ActionInfo => ({
                    id: action.id,
                    displayName: action.label || idToActionWithDisplayNameMap.get(action.id)?.displayName || '',
                    type: action.type,
                }),
            );
    }, [
        inlineActionInfo?.customTriggersData,
        inlineActionInfo?.formsData,
        inlineActionInfo?.itemInterfacesData,
        widgetConfigurationAction,
    ]);
};

export default useItemsGridInlineMenuActions;
