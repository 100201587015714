<div class="channel-or-person-selector">
    <!-- Invalid logics -->
    <div ng-if="data.validationObject && data.validationObject.notifyType" class="margin-top-xs common-color-danger">
        {{ data.validationObject.notifyType }}
    </div>

    <!-- Person -->
    <tnk-radio-button
        radio-id="notification-person-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-name="notification-person-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-value="person"
        model="data.targetType"
        is-required="true"
        on-click="selectTargetType(onClickParam)"
        on-click-param="('person')"
        automation-identifier="who-should-tonkean-module-ask-person-radio-button"
    >
        Person
    </tnk-radio-button>

    <!-- form is here to solve the radio button -->
    <form>
        <tnk-person-selection-configuration
            ng-if="data.targetType === 'person'"
            class="block margin-left-lg margin-top-xs"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            support-quick-create="true"
            saved-person-selection-configuration="data.savedChannelOrPersonSelection"
            on-person-selection-configuration-changed="
                onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
            "
            communication-project-integration-selector="data.communicationProjectIntegrationSelector"
            on-communication-project-integration-changed="
                onCommunicationProjectIntegrationChanged(selectedCommunicationProjectIntegration)
            "
            preview-evaluation-source="data.previewEvaluationSource"
            hide-fail-if-no-value="data.hideFailIfNoValue"
            configured-logic="data.configuredLogic"
            do-not-evaluate-preview="data.doNotEvaluatePreview"
            hide-preview="data.hidePreview"
            show-send-by="true"
            show-send-to="data.showSendTo"
            show-send-as-group-message="true"
            unique-identifier="{{ data.uniqueIdentifier }}"
        ></tnk-person-selection-configuration>
    </form>

    <!-- Channel -->
    <tnk-radio-button
        class="margin-top-xs"
        radio-id="notification-channel-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-name="notification-channel-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-value="channel"
        model="data.targetType"
        on-click="selectTargetType(onClickParam)"
        on-click-param="('channel')"
        is-required="true"
        automation-identifier="who-should-tonkean-module-ask-channel-radio-button"
    >
        <div>Channel</div>
    </tnk-radio-button>

    <!-- Channel selection -->
    <div ng-if="data.targetType === 'channel'" class="margin-left-lg">
        <div class="margin-bottom" ng-if="data.communicationProjectIntegrationSelector">
            Communication Source:
            <tnk-communication-project-integration-selector
                selected-communication-project-integration="data.communicationProjectIntegration"
                on-communication-project-integration-changed="
                    onCommunicationProjectIntegrationChanged(selectedCommunicationProjectIntegration)
                "
            ></tnk-communication-project-integration-selector>
        </div>

        <!-- Public channel option -->
        <tnk-radio-button
            radio-id="public-channel-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-name="channel-visibility-type-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-value="publicChannel"
            model="data.channelVisibilityType"
            on-click="selectChannelVisibilityType(onClickParam)"
            on-click-param="('publicChannel')"
            is-required="true"
        >
            Public
        </tnk-radio-button>

        <!-- Public channel selector -->
        <tnk-channel-selector
            ng-if="data.channelVisibilityType === 'publicChannel'"
            class="block margin-left-lg margin-top-xs margin-bottom-xs"
            data-automation="tnk-channel-or-person-selector-template-public-channel-selector"
            selected-channel="data.selectedChannel"
            enable-clear-channel="true"
            on-channel-selected="onCommunicationChannelSelected(channel)"
            selected-integration="data.communicationProjectIntegration"
        ></tnk-channel-selector>

        <!-- Private channel option -->
        <tnk-radio-button
            radio-id="private-channel-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-name="channel-visibility-type-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-value="privateChannel"
            model="data.channelVisibilityType"
            on-click="selectChannelVisibilityType(onClickParam)"
            on-click-param="('privateChannel')"
            is-required="true"
            view-only="data.communicationProjectIntegration && data.communicationProjectIntegration.integrationType === 'MICROSOFT_TEAMS'"
            class="flex-inline"
            data-automation="tnk-channel-or-person-selector-template-private-channel-option"
        >
            Private
        </tnk-radio-button>

        <i
            ng-if="
                data.communicationProjectIntegration &&
                data.communicationProjectIntegration.integrationType === 'MICROSOFT_TEAMS'
            "
            class="fa fa-info-circle margin-left-xs flex-inline"
            uib-tooltip="Microsoft Teams currently doesn't support messaging to private channels"
        ></i>

        <!-- Private channel selector -->
        <tnk-channel-checker-selector
            ng-if="
                data.channelVisibilityType === 'privateChannel' &&
                data.communicationProjectIntegration &&
                data.communicationProjectIntegration.integrationType !== 'MICROSOFT_TEAMS'
            "
            selected-channel="data.selectedPrivateChannel"
            on-private-channel-selected="onPrivateChannelSelected(channelSelectionObject)"
            integration="data.communicationProjectIntegration.integration"
            channel-type="'PRIVATE_CHANNEL'"
        ></tnk-channel-checker-selector>

        <!-- Custom channel option -->
        <tnk-radio-button
            radio-id="custom-channel-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-name="channel-visibility-type-{{ data.workflowVersionId + data.uniqueIdentifier }}"
            radio-value="customChannel"
            model="data.channelVisibilityType"
            on-click="selectChannelVisibilityType(onClickParam)"
            on-click-param="('customChannel')"
            is-required="true"
        >
            Custom
        </tnk-radio-button>

        <!-- Custom channel selector -->
        <div ng-if="data.channelVisibilityType === 'customChannel'">
            <div class="flex mod-align-baseline">
                <i class="fa fa-hashtag margin-right-xs"></i>
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.logicId }}"
                    placeholder="Enter channel name"
                    class="flex-grow"
                    saved-expression="data.selectedCustomChannel"
                    preview-evaluation-source="data.previewEvaluationSource"
                    expression-unique-identifier="COP_CUSTOM_CHANNEL"
                    on-tonkean-expression-changed="onCustomChannelTonkeanExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
            <div class="common-color-grey common-size-xxxxs margin-top-xs">
                Enter Channel name or use a field. Note: @tonkean user must be part of that channel.
            </div>

            <!-- Invalid channel name-->
            <div
                ng-if="data.validationObject && data.validationObject.noChannelNameExpression"
                class="common-color-danger"
            >
                {{ data.validationObject.noChannelNameExpression }}
            </div>
        </div>
    </div>

    <!-- Reply in thread -->
    <tnk-radio-button
        ng-if="data.showReplyInFlowItemThread || data.showReplyInResumeThread"
        radio-id="notification-thread-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-name="notification-thread-{{ data.workflowVersionId + data.uniqueIdentifier }}"
        radio-value="thread"
        model="data.targetType"
        is-required="true"
        on-click="selectTargetType(onClickParam)"
        on-click-param="('thread')"
        data-automation="who-should-tonkean-module-ask-reply-radio-button"
    >
        Reply in Thread
    </tnk-radio-button>

    <!-- Thread selection -->
    <div
        ng-if="data.targetType === 'thread' && (data.showReplyInFlowItemThread || data.showReplyInResumeThread)"
        class="margin-left-lg"
        data-automation="reply-in-thread-search"
    >
        <ui-select
            theme="bootstrap"
            ng-model="data.selectedReplyInThreadOption"
            ng-change="onSelectedReplyInThreadOptionChanged()"
        >
            <ui-select-match placeholder="Search...">
                {{ data.selectedReplyInThreadOption.displayName }}
            </ui-select-match>
            <ui-select-choices
                repeat="option in data.replyInThreadOptions | filter:$select.search"
                ui-disable-choice="!option.shouldDisplay"
            >
                <div
                    class="common-ellipsis"
                    ng-bind-html="option.displayName | highlight: $select.search"
                    uib-tooltip="{{ option.disabledTooltip }}"
                    tooltip-enable="!option.shouldDisplay"
                    tooltip-placement="left"
                    tooltip-append-to-body="true"
                    data-automation="reply-in-thread-root-monitored-item"
                ></div>
            </ui-select-choices>
        </ui-select>
    </div>
</div>
