import { useMemo } from 'react';

import type { FieldType } from '@tonkean/tonkean-entities';
import type { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';

type OperatorsToDataType = [FieldType, FormulaOperatorDefinitionBase[]][];

function useSortedOperatorsByDataType(formulaOperatorsByDataType: OperatorsToDataType) {
    const sortedFormulaOperatorsByDataType: OperatorsToDataType = useMemo(() => {
        return formulaOperatorsByDataType.map(([fieldType, formulaOperatorDefinitionBase]) => {
            return [
                fieldType,
                formulaOperatorDefinitionBase.sort((firstOperator, secondOperator) =>
                    firstOperator.displayName.localeCompare(secondOperator.displayName),
                ),
            ];
        });
    }, [formulaOperatorsByDataType]);

    return sortedFormulaOperatorsByDataType;
}

export default useSortedOperatorsByDataType;
