import WorkerAuditLogItem from './WorkerAuditLogItem';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkerAuditLogItem',
        reactToAngular(WorkerAuditLogItem, ['activity', 'currentUser', 'hideButton'], ['tonkeanUtils', 'modalUtils']),
    );
