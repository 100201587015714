function tnkGitUrl() {
    const GIT_URL_REGEX = /^git@[\w.]+:[^ ]+$/i;
    const URL_REGEX = /^(https?|file):\/\/[^ ]+$/i;
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: {
            enabled: '=tnkGitUrl',
        },
        link(scope, elm, attrs, ctrl) {
            ctrl.$validators.gitUrl = function (val) {
                if (!scope.enabled) {
                    return true;
                }

                if (ctrl.$isEmpty(val) || val.includes(' ')) {
                    return false;
                }

                const valid = GIT_URL_REGEX.test(val) || URL_REGEX.test(val);
                console.log(`${val} ${valid}`);
                return valid;
            };
        },
    };
}
export default angular.module('tonkean.shared').directive('tnkGitUrl', tnkGitUrl);
