import dayjs from 'dayjs';
import { useMemo } from 'react';

import useGetWorkerRunsItemFormattedDate from '../../../../../HistoryModule/hooks/useGetWorkerRunsItemFormattedDate';

function useCalculateRelativeDateTime(dateTime: number | null, deps: any[] = []) {
    const relativeDateTime = useMemo(() => {
        if (!dateTime) {
            return null;
        }
        return dayjs(dateTime).fromNow();
    }, [dateTime]);

    const { fullDate } = useGetWorkerRunsItemFormattedDate(dateTime || 0);

    const inThePast = useMemo(() => {
        if (!dateTime) {
            return null;
        }

        return dayjs(dateTime).isBefore(dayjs());
    }, [dateTime]);

    return {
        relativeDateTime,
        inThePast,
        fullDate,
    };
}

export default useCalculateRelativeDateTime;
