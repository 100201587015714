import BuiltInListsUtils from '../builtInListsUtils';

export default {
    id: 'EMPTY',
    category: 'EMPTY',
    title: 'Empty list',
    shortTitle: 'Create a new module',
    subtitle: '',
    description: 'Start from scratch and build your own.',
    mainColor: '#F0A117',
    headerCss: 'mod-light-grey',
    workerType: BuiltInListsUtils.workerTypes.FULL_TIME.key,
    steps: [],
};
