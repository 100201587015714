import React, { useState } from 'react';

import EditBusinessReportAccessibilityModal from './EditBusinessReportAccessibilityModal';

import type { BusinessReportAccessibilitySummary } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

interface Props {
    businessReport: BusinessReportAccessibilitySummary;
    onEdit: (newBusinessReportAccessibilityType: BusinessReportAccessType, specificPeople: string[]) => void;
}

const BusinessReportAccessibilityEditButton: React.FC<Props> = ({ businessReport, onEdit }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    return (
        <>
            <ClickableLink onClick={() => setModalIsOpen(true)}>Edit</ClickableLink>

            <EditBusinessReportAccessibilityModal
                businessReport={businessReport}
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                onEdit={onEdit}
            />
        </>
    );
};

export default BusinessReportAccessibilityEditButton;
