import React from 'react';

import type { ActionWidgetOpenIntakeSequenceActionData } from './ActionWidgetActionData';

import { TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    buttonBackground: string | undefined;
    state: string;
    disabled?: boolean;
    disabledTooltip?: string;
    actionData: ActionWidgetOpenIntakeSequenceActionData;
    workflowVersionType: WorkflowVersionType;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
}

const OpenIntakeSequenceButton: React.FC<Props> = ({
    className,
    projectId,
    buttonBackground,
    state,
    disabled,
    disabledTooltip,
    actionData,
    workflowVersionType,
    groupId,
}) => {
    const defaultParams = {
        initiativeId: actionData.initiativeId,
        projectId,
        customTriggerId: actionData.id,
        workflowVersionType,
    };

    const noMatchedItemDisabled =
        groupId && // No group id means this is a workspace app
        actionData.groupId !== groupId && // if the action group id is not the same as the one we are on, this action is directed at a matched item
        !actionData.initiativeId; // no initiative id means no matched item

    const finalDisabled = noMatchedItemDisabled || disabled;
    return (
        <>
            <Tooltip
                dataAutomation="action-button-tooltip"
                placement="bottom"
                content={
                    noMatchedItemDisabled
                        ? 'This action is not available for the current item (no matching items were found)'
                        : disabledTooltip
                }
                disabled={!finalDisabled}
            >
                <UserThemedClickableButton
                    data-automation="action-button"
                    state={state}
                    params={defaultParams}
                    className={className}
                    background={buttonBackground}
                    disabled={finalDisabled}
                    openInNewTab
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {actionData.label || actionData.defaultLabel}
                    </TextEllipsis>
                </UserThemedClickableButton>
            </Tooltip>
        </>
    );
};

export default OpenIntakeSequenceButton;
