import CustomTriggerMonitorTypes from '../entities/CustomTriggerMonitorTypes';

import { type WorkerRunLogic, WorkerRunLogicStatus } from '@tonkean/tonkean-entities';
import { CustomTriggerType, WorkerRunReason } from '@tonkean/tonkean-entities';

function getWorkerRunLogicStatus(
    logic: any,
    logicInfo: WorkerRunLogic,
    isActivatedOrContainActivatedLogic: boolean,
    isParentActionCompleted: boolean,
    isInlineAction: boolean,
    workerRunReason: WorkerRunReason,
): WorkerRunLogicStatus {
    if (!logicInfo) {
        if (CustomTriggerMonitorTypes.includes(logic.node.customTriggerType) && isParentActionCompleted) {
            return WorkerRunLogicStatus.MONITORING;
        }

        if (isActivatedOrContainActivatedLogic && workerRunReason !== WorkerRunReason.RERUN_FAILED_CUSTOM_TRIGGERS) {
            return WorkerRunLogicStatus.INNER_TRIGGER_ACTIVATED;
        }

        if (
            logic.node.customTriggerType === CustomTriggerType.DELAY &&
            !logic.node.disabled &&
            isParentActionCompleted
        ) {
            return WorkerRunLogicStatus.SUCCESS;
        }

        return WorkerRunLogicStatus.WASNT_ACTIVATED;
    }
    if (logicInfo.workerDisabled) {
        return WorkerRunLogicStatus.WORKER_OFF;
    }
    if (logicInfo.disabled) {
        return WorkerRunLogicStatus.OFF;
    }
    if (logicInfo.skipped) {
        return WorkerRunLogicStatus.SKIPPED;
    }
    if (!logicInfo.conditionMet) {
        return WorkerRunLogicStatus.CONDITION_NOT_MET;
    }
    if (logicInfo.hasError) {
        return WorkerRunLogicStatus.ERROR;
    }
    if (!logicInfo.completed && CustomTriggerMonitorTypes.includes(logic.node.customTriggerType)) {
        return WorkerRunLogicStatus.MONITORING;
    }
    if (!logicInfo.completed) {
        return WorkerRunLogicStatus.PENDING;
    }
    if (
        logicInfo.completed &&
        CustomTriggerMonitorTypes.includes(logic.node.customTriggerType) &&
        logic.node.customTriggerType !== CustomTriggerType.SEND_FORM_ANSWERED &&
        !isInlineAction
    ) {
        return WorkerRunLogicStatus.MONITORING;
    }

    if (logicInfo.hasWarning) {
        return WorkerRunLogicStatus.PARTIAL;
    }

    return WorkerRunLogicStatus.SUCCESS;
}

export default getWorkerRunLogicStatus;
