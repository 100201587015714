import amazonTextractIcon from '../../../../apps/tracks/images/amazontextract.png';
import amazonTextractCircleIcon from '../../../../apps/tracks/images/integrations/amazontextract-circle.png';
import AmazonS3AuthenticationComponent from '../amazonS3/AmazonS3AuthenticationComponent';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

class AmazonTextractIntegration extends IntegrationDefinitionBase {
    override name = 'amazontextract';
    override displayName = 'Amazon Textract';
    override description = 'This integration is not collecting any data.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [];
    override customizedAuthenticationComponent = AmazonS3AuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(amazonTextractCircleIcon, '130px'),
        new IntegrationIcon(amazonTextractIcon, '130px'),
    );
}

export default AmazonTextractIntegration;
