import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class UniqueIdFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.UNIQUE_IDENTIFIER;
    override readonly sign = 'UniqueId';
    override readonly displayName = 'Generate Unique ID';
    override readonly description = 'Use the Unique Identifier formula to get a unique number or string.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Type',
            dataType: FieldType.String,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: ['Number', 'String'],
        },
    ];
}
