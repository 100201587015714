import React from 'react';
import styled from 'styled-components';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';

const HeaderLogo = styled.div`
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const ImageContainer = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    align-content: center;
`;

const StyledImg = styled.img`
    object-fit: contain;
    height: 40px;
`;

const ItemInterfaceHeaderLogo: React.FC = () => {
    const { itemInterface, project } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    if (themeConfiguration.logoUrl && themeConfiguration.showLogo)
        return (
            <HeaderLogo>
                <ImageContainer>
                    <StyledImg src={themeConfiguration.logoUrl} alt="Logo" />
                </ImageContainer>
            </HeaderLogo>
        );

    return <div />;
};

export default ItemInterfaceHeaderLogo;
