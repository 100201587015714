import styled, { css } from 'styled-components';

import ItemWidgetComponent from './ItemWidgetComponent';

import { Theme } from '@tonkean/tui-theme';

const ItemWidgetBody = styled(ItemWidgetComponent)<{
    $enableHorizontalScrolling: boolean;
    $noBorderBody?: boolean;
    $dashedBorderBody?: boolean;
    $noBackgroundBody?: boolean;
    $itemWidgetBodyHeight?: number;
    $itemWidgetBodyMinHeight?: number;
    $itemWidgetBodyMaxHeight?: number;
    $noBottomBorderRadius?: boolean;
    $noPadding?: boolean;
    $growBody?: boolean;
}>`
    overflow: auto;
    ${({ $itemWidgetBodyHeight }) =>
        !!$itemWidgetBodyHeight &&
        css`
            height: ${$itemWidgetBodyHeight}px;
        `};
    ${({ $itemWidgetBodyMinHeight }) =>
        !!$itemWidgetBodyMinHeight &&
        css`
            min-height: ${$itemWidgetBodyMinHeight}px;
        `};
    ${({ $itemWidgetBodyMaxHeight }) =>
        !!$itemWidgetBodyMaxHeight &&
        css`
            max-height: ${$itemWidgetBodyMaxHeight}px;
        `};
    ${({ $growBody }) =>
        $growBody &&
        css`
            display: flex;
            flex-direction: column;
        `};
    overflow-x: ${({ $enableHorizontalScrolling }) => ($enableHorizontalScrolling ? 'auto' : 'hidden')};
    flex-grow: 1;
    border-radius: ${({ $noBottomBorderRadius }) => ($noBottomBorderRadius ? '6px 6px 0 0 ' : '6px')};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
    background: ${({ $noBackgroundBody }) => ($noBackgroundBody ? 'transparent' : Theme.colors.basicBackground)};
    border: ${({ $noBorderBody, $dashedBorderBody }) =>
        $dashedBorderBody
            ? `1px dashed ${Theme.colors.gray_400}`
            : $noBorderBody
              ? 'none'
              : `1px solid ${Theme.colors.gray_300}`};
`;

export default ItemWidgetBody;
