import ProjectIntegrationEntityEntityFetcherAction from './ProjectIntegrationEntityEntityFetcherAction';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationEntityFetcherActionState',
        reactToAngular(ProjectIntegrationEntityEntityFetcherAction),
    );
