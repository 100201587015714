<div class="approval-cycle-logic-configuration">
    <!-- Person selection title -->
    <div class="common-title-xxs margin-bottom">Who should Tonkean module Ask for review?</div>

    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noReviewers" class="common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].noReviewers }}
    </div>

    <!-- Reviewers display -->
    <div class="flex-vmiddle margin-bottom" ng-repeat="reviewerPerson in data.reviewers">
        <!-- Prefix -->
        <div class="margin-right">Person {{ $index + 1 }}:</div>

        <!-- Reviewer selection -->
        <tnk-single-person-selector
            class="margin-right flex-grow"
            selected-person-id="reviewerPerson.id"
            on-person-selected="ownerSelected(person, paramsObject)"
            on-person-removed="ownerRemoved(paramsObject)"
            params-object-for-callbacks="$index"
            include-external="true"
        ></tnk-single-person-selector>

        <!-- Remove button -->
        <span
            class="pointer margin-top-none margin-right svg-icon flex-no-shrink common-color-grey"
            ng-click="removeReviewer($index)"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </span>
    </div>

    <!-- Add reviewer button -->
    <div class="margin-top">
        <a ng-click="addNewReviewer()">+ Add</a>
    </div>

    <i class="common-color-light-grey">Note: each approval will be added as an inner item to the original item</i>

    <!-- Separator -->
    <hr />

    <!-- Is Urgent? -->
    <tnk-checkbox
        label="'Notify Them Immediately'"
        value="data.isUrgent"
        uib-tooltip="Notify immediately after assignment and let them know the you need their input. Otherwise, the message might be delayed up to an hour, depending on how busy the person is."
        on-toggle="onIsUrgentChanged(value)"
    ></tnk-checkbox>

    <div ng-show="data.isUrgent">
        <!-- Separator -->
        <hr />

        <!-- Custom message -->
        <div class="margin-bottom-xs common-title-xxs">
            Message text:
            <a
                class="inline-block common-italic margin-left"
                uib-tooltip="You can use markdown format to add styling to the message"
                target="_blank"
                ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
            >
                <i class="fa fa-info-circle"></i>
            </a>
        </div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter custom message"
            saved-original-expression="data.customText"
            saved-evaluated-expression="data.evaluatedCustomText"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <!-- Separator -->
        <hr />

        <!-- Preview -->
        <div class="margin-top-lg">
            <!-- Title -->
            <div class="margin-bottom common-bold">Preview message:</div>

            <!-- Preview -->
            <tnk-preview-bot-bubble-ctrl
                text="data.text"
                text-evaluated-expression="data.evaluatedText"
                new-line-text="data.customText"
                new-line-evaluated-expression="data.evaluatedCustomText"
                preview-type="text"
                is-email="!cis.anyIntegrationsConnected()"
                user="as.currentUser"
                no-buttons="true"
                logic-id="data.configuredLogic.node.id"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                no-triangle="true"
            ></tnk-preview-bot-bubble-ctrl>
        </div>
    </div>
</div>
