import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousScheduleLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_SCHEDULE,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-scheduled',
    title: 'On Scheduled',
    description: 'On Scheduled',
    longDescription:
        'This trigger will run on schedule as configured below, as long as it matches the condition below. Note: this will run on existing items as well.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [addNewLogicOption],
    definition: {},
    validator: ({ customTrigger, projectManager }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!customTrigger.recurrencePeriodType) {
            validationObject.recurrencePeriodType = 'Must select a frequency.';
        }

        const minInterval =
            projectManager.project.features?.tonkean_feature_minimum_scheduled_trigger_interval_minutes || 30;
        if (
            customTrigger.recurrencePeriodType === 'EveryXMinutes' &&
            (customTrigger.everyXMinutes || 0) < minInterval
        ) {
            validationObject.invalidEveryXMinutes = `Minutes value must be greater than ${minInterval}.`;
        }

        if (customTrigger.recurrencePeriodType === 'EveryXHours' && (customTrigger.everyXHours || 0) < 1) {
            validationObject.invalidEveryXHours = 'Hours value must be greater than 0.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutonomousScheduleLogicBlockConfig;
