import StatePageType from '../entities/StatePageType';

const stateNameToStagePageType: Record<string, StatePageType> = {
    'product.workers': StatePageType.SOLUTIONS_GALLERY,
    'product.workerEditor': StatePageType.MODULE_EDITOR,
    'product.board': StatePageType.LIVE_REPORT,
    'product.enterpriseComponents': StatePageType.DATA_SOURCES,
    'product.runtime': StatePageType.RUNTIME,
    'product.projectIntegrationPage': StatePageType.PROJECT_INTEGRATION_PAGE,
    homepageView: StatePageType.HOME_PAGE,
    'solution-site': StatePageType.SOLUTION_SITE,
};

export default stateNameToStagePageType;
