/**
 * Created by ShaLi on 26/01/2016.
 */

function ProductSettingsCtrl($scope, integrations, projectManager, communicationIntegrationsService) {
    $scope.cis = communicationIntegrationsService;

    $scope.integrationGroups = integrations.getIntegrationGroups();
    $scope.projectName = projectManager.project.name;
    $scope.editProjectName = false;
    $scope.state = {};

    $scope.data = {
        communicationIntegrations: $scope.integrationGroups.communication.integrations(projectManager.project.id),
    };

    $scope.init = function () {
        $scope.state.integrationState = {};
        projectManager.project.integrations.forEach(function (projectIntegration) {
            if (
                $scope.state.integrationState[projectIntegration.integration.integrationType.toLowerCase()]
                    ?.integrations
            ) {
                $scope.state.integrationState[
                    projectIntegration.integration.integrationType.toLowerCase()
                ].integrations.push(projectIntegration);
            } else {
                $scope.state.integrationState[projectIntegration.integration.integrationType.toLowerCase()] = {
                    integrations: [projectIntegration],
                };
            }
        });
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('ProductSettingsCtrl', ProductSettingsCtrl);
