<div class="import-dropdown-options-popover">
    <!-- Title -->
    <div class="common-title-xs margin-bottom margin-top">Copy Field Options From Another Field</div>

    <!-- Note -->
    <div class="common-color-grey common-size-xxxs margin-bottom-lg">
        Select another field to copy the options from.
        <strong>Note:</strong>
        <span>
            Future changes to the selected field
            <strong>will not</strong>
            influence the imported options.
        </span>
    </div>

    <!-- Field Selector -->
    <div class="margin-bottom-xlg margin-top-md">
        <tnk-field-selector
            items-source="COLUMN"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            group-by="groupBy"
            is-required="true"
            display-integration-indication="true"
            on-field-selected="onFieldOptionSelected(selectedField)"
            do-not-display-special-fields="true"
            add-fields-quick-create-options="true"
        ></tnk-field-selector>

        <!-- Preview -->
        <div class="import-dropdown-options-list margin-top-lg" ng-if="data.selectedImportField.possibleValues.length">
            <div class="common-title-xxxs">Options to Import:</div>
            <ul>
                <li ng-repeat="option in data.selectedImportField.possibleValues">{{ option }}</li>
            </ul>
        </div>

        <!-- No Matches Warning -->
        <div
            class="import-dropdown-options-list margin-top-lg common-color-grey common-size-xxxs"
            ng-if="data.selectedImportField !== null && !data.selectedImportField.possibleValues.length"
        >
            No possible options available for the selected field.
        </div>
    </div>

    <!-- Action Buttons -->
    <div class="import-dropdown-options-action-buttons">
        <!-- Cancel Button -->
        <button class="btn btn-secondary" ng-click="onImportPopoverClose($event)">Cancel</button>

        <!-- Import Submit Button -->
        <button
            class="btn btn-primary"
            ng-click="onImportSubmit($event)"
            ng-disabled="data.selectedImportField === null || !data.selectedImportField.possibleValues.length"
        >
            Import
        </button>
    </div>
</div>
