<div class="org-overview org-content-page org-content-padded" ng-class="{ 'container mod-center': pm.isExpired }">
    <!-- Title -->
    <h2 class="common-title margin-top-none margin-bottom-lg flex-grow">Subscription & Billing</h2>
    <hr class="common-separator" />

    <!-- Trial / Free mode -->
    <div ng-if="!license">
        <!-- Trial title -->
        <div>
            <span class="common-title-lg mod-light margin-none">
                {{
                    pm.project.isInTrial
                        ? 'You are on the ' + consts.trialDaysLength + '-day free trial'
                        : pm.isExpired
                        ? 'Your trial has expired!'
                        : 'You are on the ' + (!pm.project.expirationDate ? 'Enterprise' : 'Free') + ' plan'
                }}
            </span>
            <span class="common-title-lg mod-light mod-primary margin-top" ng-if="pm.project.isInTrial">
                &nbsp;-&nbsp;{{ sharedData.trialDaysLeft }} days remaining!
            </span>
        </div>
        <button
            type="button"
            ui-sref="product.settings.license({tab: 'upgrade'})"
            ng-hide="pm.project.isInTrial || pm.project.isEnterprise"
            class="btn btn-primary-black margin-top-xs common-title-font common-size-xs"
        >
            {{ !pm.project.isEnterprise ? 'Upgrade your account' : 'Change subscription plan' }}
        </button>

        <!-- What you get from the plan -->
        <div class="margin-top-xlg">
            <h4 class="common-title-xxs" ng-if="pm.project.isInTrial">
                Purchase a plan to ensure continuous access to Tonkean.
            </h4>
            <!--<div ng-hide="pm.project.isEnterprise || data.showPlansOverview">-->
            <!--<p>Paid plans include:</p>-->
            <!--<div class="flex common-title-font">-->
            <!--<ul class="pretty-list margin-right-xlg-no-xs">-->
            <!--<li ng-if="lps.isPricingVersion(lps.pricingVersions.V1)">Unlimited team members</li>-->
            <!--<li>Unlimited Lists</li>-->
            <!--<li>ALL integrations (Including Webhooks)</li>-->
            <!--<li>Custom triggers</li>-->
            <!--<li>Custom workflows</li>-->
            <!--<li>And more...</li>-->
            <!--</ul>-->
            <!--&lt;!&ndash;<ul class="pretty-list margin-left">&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Auto sync</li>&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Private lists</li>&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Smart-Triggers</li>&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Executive summary</li>&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Custom fields</li>&ndash;&gt;-->
            <!--&lt;!&ndash;<li>Premium support<br/>+ 2 setup/coaching meetings</li>&ndash;&gt;-->
            <!--&lt;!&ndash;</ul>&ndash;&gt;-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="common-size-xs">-->
            <!--<a ng-click="data.showPlansOverview = !data.showPlansOverview">View all details and compare plans</a>-->
            <!--</div>-->
            <div ng-if="pm.project.isInTrial" ng-hide="pm.project.isEnterprise">
                <tnk-pricing-overview></tnk-pricing-overview>
            </div>
        </div>
    </div>

    <!-- Licensed mode -->
    <div ng-if="license">
        <!-- Plan and seats details -->
        <div class="flex flex-xs-wrap margin-top-lg">
            <!-- Plan name -->
            <div class="flex-no-shrink margin-right-lg margin-bottom">
                <div class="common-bold common-size-xxs">Your plan</div>
                <div class="common-title-md mod-light">
                    {{ plan.name }}
                    {{ (plan.interval !== sharedData.intervals.year ? plan.interval + 'ly' : '') | capitalize }}
                </div>
            </div>

            <!-- Seats -->
            <div class="flex-no-shrink margin-bottom">
                <div class="common-bold common-size-xxs">Number of seats</div>
                <div class="common-title-md mod-light">{{ plan.quantity }}</div>
            </div>
        </div>

        <!-- Change plan button -->
        <div class="flex-vmiddle margin-top-lg margin-bottom-xlg" ng-if="pm.isBuyer">
            <button
                type="button"
                class="btn btn-primary padding-right-lg padding-left-lg"
                ng-click="openChangePlanModal()"
            >
                Change
            </button>
            <span class="margin-left-xs margin-top-xs" ng-show="loadingChangePlansModal">
                <i class="loading"></i>
            </span>
        </div>

        <hr class="common-separator margin-top-lg margin-bottom-lg" />

        <!-- Company information -->
        <h3 class="common-title-lg mod-light margin-none padding-top">Company information</h3>
        <div class="margin-top padding-top-xs common-size-xxs">
            <!-- Company information view -->
            <div ng-if="!data.showChangeBillingInfo">
                <div class="common-bold">{{ license.customer.billingInformation.organizationName }}</div>
                <div>{{ license.customer.billingInformation.ccEmail }}</div>
                <div>{{ license.customer.billingInformation.phoneNumber }}</div>
                <div>{{ license.customer.billingInformation.address }}</div>
                <div>
                    {{ license.customer.billingInformation.city
                    }}{{
                        license.customer.billingInformation.state
                            ? ',
                    ' + data.usaStates[license.customer.billingInformation.state]
                            : ''
                    }}
                </div>
                <div>{{ license.customer.billingInformation.zipCode }}</div>
                <div>
                    {{
                        license.customer.billingInformation.country
                            ? data.countries[license.customer.billingInformation.country]
                            : ''
                    }}
                </div>
                <div class="margin-top">
                    <a href="#" ng-click="data.showChangeBillingInfo = true;" ng-if="pm.isBuyer">Edit</a>
                </div>
            </div>
            <!-- Update company information form -->
            <form
                name="forms.chaneBillingInfo"
                ng-if="data.showChangeBillingInfo"
                class="form-inline"
                ng-submit="updateLicenseBillingInfo()"
            >
                <div class="org-form-group">
                    <label for="frmOrgName" class="control-label org-form-label">Company name</label>
                    <input
                        id="frmOrgName"
                        placeholder="Company name"
                        class="form-control"
                        required
                        ng-model="license.customer.billingInformation.organizationName"
                    />
                </div>
                <div class="org-form-group">
                    <label for="ccEmail" class="control-label org-form-label">Contact Email</label>
                    <input
                        type="email"
                        id="ccEmail"
                        class="form-control"
                        ng-model="license.customer.billingInformation.ccEmail"
                        placeholder="Optional"
                    />
                </div>
                <div class="org-form-group">
                    <label for="frmPhoneNumA" class="control-label org-form-label">Phone</label>
                    <input
                        class="form-control"
                        type="tel"
                        name="phone"
                        id="frmPhoneNumA"
                        ng-model="license.customer.billingInformation.phoneNumber"
                        placeholder="+1-650-450-1212"
                        autocomplete="tel"
                    />
                </div>
                <div class="org-form-group">
                    <label for="frmCountryS" class="control-label org-form-label">Country</label>
                    <select
                        name="ship-country"
                        required
                        id="frmCountryS"
                        placeholder="USA"
                        class="form-control"
                        ng-model="license.customer.billingInformation.country"
                        autocomplete="billing country"
                        ng-options="countryCode as countryName for (countryCode, countryName) in data.countries"
                    ></select>
                </div>
                <div class="org-form-group" ng-if="license.customer.billingInformation.country === 'US'">
                    <label for="frmStateS" class="control-label org-form-label">State</label>
                    <select
                        name="ship-state"
                        required
                        id="frmStateS"
                        placeholder="NY"
                        class="form-control"
                        ng-model="license.customer.billingInformation.state"
                        autocomplete="billing state"
                        ng-options="stateCode as stateName for (stateCode, stateName) in data.usaStates"
                    ></select>
                </div>
                <div class="org-form-group">
                    <label for="frmCityS" class="control-label org-form-label">City</label>
                    <input
                        name="ship-city"
                        required
                        id="frmCityS"
                        placeholder="San Francisco"
                        class="form-control"
                        ng-model="license.customer.billingInformation.city"
                        autocomplete="billing address-level2"
                    />
                </div>
                <div class="org-form-group">
                    <label for="frmAddressS" class="control-label org-form-label">Address</label>
                    <input
                        name="ship-address"
                        required
                        id="frmAddressS"
                        class="form-control"
                        ng-model="license.customer.billingInformation.address"
                        placeholder="123 Any Street"
                        autocomplete="billing street-address"
                    />
                </div>
                <div class="org-form-group">
                    <label for="frmZipS" class="control-label org-form-label">Zip</label>
                    <input
                        name="ship-zip"
                        required
                        id="frmZipS"
                        placeholder="10011"
                        class="form-control"
                        ng-model="license.customer.billingInformation.zipCode"
                        autocomplete="billing postal-code"
                    />
                </div>
                <div class="org-form-group">
                    <div class="org-form-label"></div>
                    <div class="org-form-group-content text-right">
                        <span class="margin-top-xs margin-right-xs">
                            <i class="loading" ng-show="savingBillingInfo"></i>
                        </span>
                        <button
                            type="button"
                            class="btn btn-default flex-no-shrink margin-right-xs"
                            ng-click="closeBillingInfoForm()"
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary flex-no-shrink">Update</button>
                    </div>
                </div>
                <div class="common-color-danger" ng-if="data.billingFormError">{{ data.billingFormError }}</div>
            </form>
        </div>

        <!-- Payment method and info -->
        <div ng-if="pm.isBuyer">
            <hr class="common-separator margin-top-xlg margin-bottom-lg" />
            <h3 class="common-title-lg flex-vmiddle mod-light margin-none padding-top">
                <span>Payment method</span>
                <span
                    class="svg-icon-nav margin-left common-color-primary margin-bottom-xxs"
                    ng-hide="data.showChangePaymentInfo"
                >
                    <tnk-icon src="/images/icons/check-lg.svg"></tnk-icon>
                </span>
            </h3>
            <div class="margin-top padding-top-xs common-size-xxs">
                <!-- Payment method and info view -->
                <div ng-if="!data.showChangePaymentInfo">
                    <div>{{ license.customer.card.name }}</div>
                    <div class="flex-vmiddle common-color-light-grey2">
                        <i class="org-censor-bullets"></i>
                        <i class="org-censor-bullets"></i>
                        <i class="org-censor-bullets"></i>
                        <i class="org-censor-bullets"></i>
                        <span>{{ license.customer.card.last4 }}</span>
                    </div>
                    <div class="margin-top">
                        <a
                            href="#"
                            ng-click="data.showChangePaymentInfo = data.loadingStripe = true; "
                            ng-if="pm.isBuyer"
                        >
                            Edit
                        </a>
                    </div>
                </div>
                <!-- Update payment method and info form. -->
                <form
                    name="forms.changePaymentInfo"
                    ng-if="data.showChangePaymentInfo"
                    class="form-inline"
                    ng-submit="updateLicensePaymentInfo()"
                    novalidate
                >
                    <div ng-show="data.loadingStripe" class="margin-left-xlg">
                        <i class="loading-medium"></i>
                    </div>
                    <div ng-show="!data.loadingStripe">
                        <!-- Cardholder Name -->
                        <div class="org-form-group flex-vmiddle flex-xs-wrap">
                            <label for="frmCCName" class="control-label org-form-label">Name On Card</label>
                            <input
                                name="cardname"
                                id="frmCCName"
                                type="text"
                                class="form-control tnk-credit-card-sibling flex-grow-xs"
                                required
                                autocomplete="cc-name"
                                placeholder="Name On Card"
                                ng-model="cardExtraData.name"
                                ng-disabled="savingPaymentInfo"
                            />
                        </div>
                        <!-- Credit card (Stripe element component) -->
                        <div class="org-form-group flex-vmiddle flex-xs-wrap">
                            <label class="control-label org-form-label">Card details</label>
                            <tnk-credit-card
                                sync-object="creditCardSyncObject"
                                card-extra-data="cardExtraData"
                                loading-stripe-complete="loadingStripeComplete()"
                                class="flex-grow-xs tnk-credit-card-xs-wide"
                            ></tnk-credit-card>
                        </div>
                        <div class="org-form-group">
                            <div class="org-form-label hidden-xs"></div>
                            <div class="org-form-group-content text-right tnk-credit-card-sibling">
                                <span class="margin-top-xs margin-right-xs">
                                    <i class="loading" ng-show="savingPaymentInfo"></i>
                                </span>
                                <button
                                    type="button"
                                    class="btn btn-default flex-no-shrink margin-right-xs"
                                    ng-click="closePaymentInfoForm()"
                                >
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary flex-no-shrink">Update</button>
                            </div>
                        </div>
                        <div class="common-color-danger" ng-if="data.paymentFormError">{{ data.paymentFormError }}</div>
                    </div>
                </form>
            </div>
        </div>

        <!-- new pricing promo -->
        <div ng-if="lps.isV1Licensed()">
            <hr />
            <div class="alert alert-info padding-normal inline-block">
                <h4>We have a new pricing model!</h4>
                <p>
                    We are introducing a new pricing model and you can choose to stay on your current plan or migrate.
                </p>
                <a class="btn btn-primary-black margin-top-lg" ng-click="modalUtils.openMigratePricingModal()">
                    Learn More...
                </a>
            </div>
        </div>
    </div>

    <!-- Questions section -->
    <div class="margin-top-xlg padding-bottom-xlg margin-bottom-xlg">
        <hr class="common-separator" />
        <h4 class="common-title-xxs">Questions?</h4>
        <div>
            <span ng-hide="pm.project.isEnterprise">Need to upgrade to the Enterprise license?</span>
            Have any other question?
            <br />
            We're here to help with anything:
            <a href="#" ng-click="askForHelp()">Contact us</a>
            <div ng-if="license" class="margin-top-lg">
                <a
                    class="common-size-xxxxs common-color-grey"
                    analytics-on="click"
                    analytics-category="Cancel Subscription"
                    analytics-event="Cancel Subscription"
                    ng-click="askForHelp('Cancel my subscription')"
                >
                    Cancel my subscription
                </a>
            </div>
        </div>
    </div>
</div>
