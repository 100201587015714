import { DeprecatedDate } from '@tonkean/utils';

/**
 * A service to help with groups operations
 */
function GroupManager(
    $rootScope,
    $q,
    projectManager,
    activityManager,
    tonkeanService,
    authenticationService,
    utils,
    pollingManager,
    workflowVersionManager,
    workflowVersionDataSourceManager,
    workflowFolderManager,
    customTriggerManager,
    formManager,
    groupPermissions,
    groupInfoManager,
) {
    const _this = this;
    const pm = $rootScope.pm;

    const groupIdToBeingPolledMap = {};

    /**
     * Creates a group in the project.
     */
    _this.createGroup = function (
        projectId,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        shouldSendGatherUpdates,
        canAccessWithToken,
        isExample,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        dashboardHidden,
        blockWorkerErrorAlerts,
        workerType,
        recurrencePeriodType,
        recurrenceDaysInMonth,
        recurrenceDaysInWeek,
        recurrenceHour,
        recurrenceMinute,
        everyXMinutes,
        everyXHours,
        scheduledWorkerDefinition,
        doNotRunOnWeekends,
        defaultActorId,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        workflowFolderId,
        workflowFolderCategoryId,
    ) {
        return tonkeanService
            .createGroup(
                projectId,
                name,
                visibilityType,
                members,
                notificationType,
                notificationChannel,
                visibleToOwner,
                metadata,
                shouldSendGatherUpdates,
                canAccessWithToken,
                isExample,
                innerTracksTemplate,
                writePermissionType,
                writePermissionMembers,
                dashboardHidden,
                blockWorkerErrorAlerts,
                workerType,
                recurrencePeriodType,
                recurrenceDaysInMonth,
                recurrenceDaysInWeek,
                recurrenceHour,
                recurrenceMinute,
                everyXMinutes,
                everyXHours,
                doNotRunOnWeekends,
                scheduledWorkerDefinition,
                defaultActorId,
                archiveDoneAfterDays,
                deleteArchivedAfterDays,
                undefined,
                workflowFolderId,
                workflowFolderCategoryId,
                $rootScope.features[$rootScope.pm.project.id],
            )
            .then((createdGroup) => {
                // Getting the current user and updating the cached project contexts to be the most latest ones.
                tonkeanService.getCurrentUser().then((currentUser) => {
                    authenticationService.currentUser.projectContexts = currentUser.projectContexts;
                });

                // Add the group to the project managers caches (or make sure it is updated).
                projectManager.addGroup(createdGroup);

                workflowFolderManager.addGroupToWorkflowFolderCache(projectId, workflowFolderId, createdGroup.id);

                // Returning the createdGroup as an output of the promise.
                return $q.resolve(createdGroup);
            });
    };

    _this.duplicateGroup = function (
        sourceGroupId,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        shouldSendGatherUpdates,
        canAccessWithToken,
        isExample,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        dashboardHidden,
        blockWorkerErrorAlerts,
        workerType,
        defaultActorId,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        owners,
        workflowFolderId,
        workflowFolderCategoryId,
    ) {
        return tonkeanService
            .duplicateGroup(
                sourceGroupId,
                name,
                visibilityType,
                members,
                notificationType,
                notificationChannel,
                visibleToOwner,
                metadata,
                shouldSendGatherUpdates,
                canAccessWithToken,
                isExample,
                innerTracksTemplate,
                writePermissionType,
                writePermissionMembers,
                dashboardHidden,
                blockWorkerErrorAlerts,
                workerType,
                defaultActorId,
                archiveDoneAfterDays,
                deleteArchivedAfterDays,
                owners,
                workflowFolderId,
                workflowFolderCategoryId,
            )
            .then((groupData) => {
                workflowFolderManager.addGroupToWorkflowFolderCache(pm.project.id, workflowFolderId, groupData.id);

                // update groups list and maps
                groupInfoManager.getGroups(true);
                return $q.resolve(groupData);
            });
    };

    /**
     * Create an empty group with minimal settings
     */
    _this.quickCreateGroup = function (projectId, name, workerType, dashboardHidden, deleteArchivedAfterDays) {
        return _this
            .createGroup(
                projectId,
                name,
                'PUBLIC',
                null,
                'NONE',
                null,
                null,
                null,
                false,
                null,
                null,
                null,
                null,
                null,
                dashboardHidden,
                false,
                workerType,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                deleteArchivedAfterDays,
            )
            .then((group) => {
                return groupInfoManager.getGroups(true).then(() => {
                    return $q.resolve(group);
                });
            });
    };

    /**
     * Create an empty worker with minimal settings.
     */
    _this.quickCreateWorker = function (
        projectId,
        name,
        workerType,
        dashboardHidden,
        personId,
        deleteArchivedAfterDays,
        workflowFolderId,
        workflowFolderCategoryId,
    ) {
        return _this
            .createGroup(
                projectId,
                name,
                'PUBLIC',
                null,
                'NONE',
                null,
                true,
                null,
                false,
                null,
                null,
                null,
                'SPECIFIC_PEOPLE',
                [personId],
                dashboardHidden,
                false,
                workerType,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                deleteArchivedAfterDays,
                workflowFolderId,
                workflowFolderCategoryId,
            )
            .then((group) => {
                return groupInfoManager.getGroups(true).then(() => {
                    return $q.resolve(group);
                });
            });
    };

    /**
     * Updates the provided group properties
     */
    _this.updateGroupProperties = function (groupId, props) {
        // Set the new value in the cached group so it will update instantly in client.
        const cachedGroupObject = projectManager.groupsMap[groupId];
        const keys = utils.objKeys(props);
        for (const key of keys) {
            // Override given keys
            cachedGroupObject[key] = props[key];
        }

        // Update in server
        return tonkeanService.updateGroupProperties(cachedGroupObject, props);
    };

    /**
     * Updates the group name both in cache and in the server.
     */
    _this.updateGroupName = function (groupId, updatedGroupName) {
        const cachedGroupObject = projectManager.groupsMap[groupId];
        cachedGroupObject.name = updatedGroupName;

        return tonkeanService.updateGroupDisplayName(groupId, updatedGroupName);
    };

    /**
     * Updates the group draft version contractId.
     */
    _this.setInputSourceContract = function (groupId, contractId) {
        const cachedGroupObject = projectManager.groupsMap[groupId];
        cachedGroupObject.draftContractId = contractId;

        return tonkeanService.setInputSourceContract(groupId, contractId);
    };

    /**
     * Updates the owners of the given group.
     */
    _this.updateGroupOwners = function (groupId, ownersIds) {
        const cachedGroupObject = projectManager.groupsMap[groupId];
        const previousValue = cachedGroupObject.owners;
        cachedGroupObject.owners = ownersIds;
        groupPermissions.calculateOwnersForGroup(cachedGroupObject);

        return tonkeanService.updateGroupOwners(groupId, ownersIds).catch((error) => {
            cachedGroupObject.owners = previousValue;
            $rootScope.$emit('alert', {msg: 'Error in saving list owners', type: 'danger'});
            return $q.reject(error);
        });
    };

    /**
     * finds a group by id and toggles the current star state
     * @param groupId - the group id to work on.
     */
    _this.toggleStar = function (groupId, isStar) {
        const group = pm.groupsMap[groupId];
        // group.isStarred = !group.isStarred;
        // update server to match our new state
        if (isStar) {
            group.isStarred = true;
            tonkeanService
                .starGroup(group.id)
                .then(function () {
                    // groupInfoManager.getGroups(true);
                })
                .catch(_this.alertStarringFailed);
        } else {
            group.isStarred = false;
            tonkeanService
                .unstarGroup(group.id)
                .then(function () {
                    // groupInfoManager.getGroups(true);
                })
                .catch(_this.alertStarringFailed);
        }
    };

    _this.alertStarringFailed = function () {
        $rootScope.$emit('alert', "Couldn't star\\unstar list");
    };

    _this.updateGroup = function (
        updateGroupAsync,
        groupId,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        canAccessWithToken,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        blockWorkerErrorAlerts,
        defaultActorId,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        isSupportUserPermitted,
        communicationIntegrationId,
        smartSearchEnabled,
    ) {
        const group = projectManager.groupsMap[groupId];

        const updateGroupPromise = tonkeanService
            .updateGroup(
                groupId,
                name,
                visibilityType,
                members,
                notificationType,
                notificationChannel,
                visibleToOwner,
                metadata,
                canAccessWithToken,
                innerTracksTemplate,
                writePermissionType,
                writePermissionMembers,
                blockWorkerErrorAlerts,
                defaultActorId,
                archiveDoneAfterDays,
                deleteArchivedAfterDays,
                isSupportUserPermitted,
                communicationIntegrationId,
                smartSearchEnabled,
            )
            .then((groupData) => {

                // update groups list and maps
                groupInfoManager.getGroups(true);

                // Broadcast the update to the system.
                $rootScope.$broadcast('newActivityUpdate');

                return $q.resolve(groupData);
            });

        if (updateGroupAsync) {
            // Running async. We don't return anything.
            // We emit a "save started" message. If the promise succeeds, we emit a "done message".
            // If we fail and it's because of a timeout, we start polling to see if the save is done.
            $rootScope.$emit('alert', {msg: 'Saving list changes...', type: 'success'});

            // Set some time to the updateStartTime property, so our cache immediately knows a save is in progress.
            group.updateStartTime = DeprecatedDate.nowAsDate().getTime();
            projectManager.updateGroup(group);

            updateGroupPromise
                .then((groupData) => {
                    // Saving is done.
                    $rootScope.$emit('alert', {msg: `"${groupData.name}" saved successfully!`, type: 'success'});
                })
                .catch((error) => {
                    if (error && error.status === 503) {
                        // Handle timeout from the server. We poll until the group is not marked as updated_start_time.
                        _this.pollForGroupUpdateCompletion(group);
                    } else {
                        // The server returned an error which is not 503. This is a real failure. Let the user know.
                        $rootScope.$emit('alert', {
                            msg: `Failed saving "${group.name}". Please try again.`,
                            type: 'danger',
                        });
                    }
                });

            return $q.resolve(group);
        } else {
            // Running synchronously, just return the promise.
            return updateGroupPromise;
        }
    };

    _this.pollForGroupUpdateCompletion = function (group) {
        if (!group) {
            return;
        }

        if (groupIdToBeingPolledMap[group.id]) {
            // We are already polling. Return.
            return;
        } else {
            // We are not polling. Mark that we now are, and start.
            groupIdToBeingPolledMap[group.id] = true;
        }

        pollingManager.registerPollingPromise(
            () => groupInfoManager.getGroupById(group.id, true),
            (polledGroup, pollingIterationCount) => {
                if (polledGroup && utils.isNullOrUndefined(polledGroup.updateStartTime)) {
                    // The update start time was cleared. This means the group update was finished.
                    return true;
                }

                if (pollingIterationCount > 120) {
                    // If we are polling for more than 30 minutes - stop.
                    $rootScope.$emit('alert', {
                        msg: `Failed saving "${group.name}". Please try again.`,
                        type: 'danger',
                    });
                    return true;
                }

                return false;
            },
            (polledGroup) => {
                if (polledGroup) {
                    // If the polling was done and we've got a group (it was a success), update the group so the updateStartTime is cleared from cache.
                    projectManager.updateGroup(polledGroup, true);

                    // Clear the polling flag safely.
                    if (groupIdToBeingPolledMap[group.id]) {
                        delete groupIdToBeingPolledMap[group.id];
                    }

                    $rootScope.$emit('alert', {msg: `"${polledGroup.name}" saved successfully!`, type: 'success'});
                }
            },
            15 * 1000,
        );
    };

    _this.setWorkerEnabled = function (group, value, evaluateOnOldItems) {
        const previousValue = group.workerEnabled;
        group.workerEnabled = value;

        return tonkeanService
            .updateGroupWorkerEnabled(group.id, value, evaluateOnOldItems)
            .catch((error) => {
                // Reverting back to old state, since the server call failed.
                group.workerEnabled = previousValue;

                return $q.reject(error);
            })
            .then((result) => {
                pm.updateGroup(group);

                return $q.resolve(result);
            });
    };

    _this.setBuildEnvironmentEnabled = function (group, value) {
        const previousValue = group.buildEnvironmentEnabled;
        group.buildEnvironmentEnabled = value;

        return tonkeanService
            .updateBuildEnvironmentEnabled(group.id, value)
            .catch(() => {
                // Reverting back to old state, since the server call failed.
                group.buildEnvironmentEnabled = previousValue;

                return $q.reject();
            })
            .then((result) => {
                pm.updateGroup(group);

                return $q.resolve(result);
            });
    };

    _this.getGroupOutdated = function (groupId, workflowVersionType) {
        return tonkeanService.getGroupOutdated(groupId, workflowVersionType).catch(() => {
            return $q.reject();
        });
    };

    _this.getGroupCustomTriggerCreateTrackActions = function (groupId, workflowVersionType) {
        return tonkeanService.getGroupCustomTriggerCreateTrackActions(groupId, workflowVersionType).catch(() => {
            return $q.reject();
        });
    };

    /**
     * Reverts the current draft version to the specified version id
     */
    _this.revertDraftWorkflowVersion = function (groupId, toWorkflowVersionId) {
        return tonkeanService.revertDraftWorkflowVersion(groupId, toWorkflowVersionId).then(() => {
            return reCacheModuleEntitiesAfterRevert(groupId);
        });
    };

    function reCacheModuleEntitiesAfterRevert(groupId) {
        return groupInfoManager.getGroup(groupId, true).then(() => {
            const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

            workflowVersionManager.updateWorkflowVersion(
                {
                    id: draftWorkflowVersionId,
                    isDirty: false,
                    isPublishReady: false,
                    comment: null,
                    publishConfiguration: null,
                },
                false,
            );

            projectManager.updateGroup(
                {
                    id: groupId,
                    isDirty: false,
                },
                false,
            );

            return $q.all([
                workflowVersionManager.initializeChangesCounter(draftWorkflowVersionId, groupId),
                formManager.getAllWorkerForm(draftWorkflowVersionId, true),
                customTriggerManager.getWorkflowVersionCustomTriggersAndGraph(draftWorkflowVersionId, true),
                workflowVersionDataSourceManager.refetchWorkflowVersionItemDetails(draftWorkflowVersionId),
            ]);
        });
    }
}

export default angular.module('tonkean.app').service('groupManager', GroupManager);
