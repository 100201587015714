import { ConnectionPermission, ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { IntegrationManager } from '@tonkean/integrations';

function AddIntegrationCtrl(
    $scope,
    $rootScope,
    $state,
    integrations,
    apiConsts,
    utils,
    projectManager,
    $uibModalInstance,
    workflowVersionId,
    groupId,
    onCompleteCallback,
    mode,
    filter,
    authenticationService,
    tonkeanService,
) {
    $scope.pm = $rootScope.pm;
    $scope.importSettings = integrations.getImportSettings();
    $scope.groupId = groupId;
    $scope.workflowVersionId = workflowVersionId;

    $scope.onlyFilter = filter;

    $scope.filteredSources = [];
    $scope.filteredWebhookSources = [];
    $scope.filteredMarketplaceSources = [];
    $scope.sqlDatabases = ['mysql', 'mssql', 'postgres', 'redshift', 'athena', 'bigquery', 'oracledb'];

    $scope.data = {
        integrationState: {},
        searchIntegrationNameText: filter && filter.length ? filter : '',
        webhookSourcesLimit: 60,
        marketplaceSourcesLimit: 60,
        webhooksSources: [],
        marketplaceSources: [],
        loadingWebhooksSources: false,
        loadingMarketplaceSources: false,
        isNewCreateDataSourceModal: true,
        isWebhookCreationDisabled: true,
        webhookDataSourceConnection: null,
        userGroupsIds: [],
    };

    $scope.init = function () {
        $scope.data.loadingWebhooksSources = true;
        apiConsts.loadWebhookSources();

        loadMarketplaceSources();

        loadSources().then(() => {
            $scope.data.loadingWebhooksSources = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        });

        // Get the groups the user belongs to so we'll able to check permissions later on
        tonkeanService
            .getGroupsByUserId(projectManager.project.id, authenticationService.currentUser.id)
            .then(({ groupsIds }) => {
                $scope.data.userGroupsIds = groupsIds;
            });
    };

    // Load the webhook sources after the apiConsts is done loading them.
    $scope.$on('webhookSourcesLoaded', () => {
        $scope.data.webhooksSources = apiConsts.webhooksSources;
    });

    $scope.filterIntegrationsToHide = function (integration) {
        return !integrations.getIntegrationGroups().hideIntegrations.integrations.includes(integration.name);
    };

    $scope.onClose = function () {
        const selectedIntegration = utils.objToArray($scope.data.integrationState)[0];
        const newIntegrations = selectedIntegration.value.projectIntegrations;

        $scope.pm.getProjectData(true);

        $rootScope.$broadcast('newIntegrationAdded');

        $uibModalInstance.close();

        if (newIntegrations?.[0]?.id) {
            $state.go('product.projectIntegrationPage', {
                enterpriseComponentId: newIntegrations[0].id,
                page: ProjectIntegrationPageMenuItemType.OVERVIEW,
            });
        }

        if (onCompleteCallback) {
            onCompleteCallback(newIntegrations);
        }
    };

    $scope.closeModal = (oldIdsToCreatedIdsMap, createdProjectIntegrationId) => {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: createdProjectIntegrationId,
            page: ProjectIntegrationPageMenuItemType.OVERVIEW,
        });
        $uibModalInstance.close();
    };

    $scope.searchSourcesFilter = function (item) {
        if (!$scope.data.searchIntegrationNameText || !$scope.data.searchIntegrationNameText.length) {
            return true;
        }

        const terms = $scope.data.searchIntegrationNameText.split('||');
        for (const term_ of terms) {
            const term = term_.toLowerCase().trim();
            if (term && term.length) {
                const integrationDisplayName = integrations
                    .getIntegrationsConfig()
                    [item.name.toLowerCase()]?.displayName.toLowerCase();

                if (item.name.toLowerCase().includes(term) || integrationDisplayName?.includes(term)) {
                    return true;
                }
                if (item.name === 'sqldatabase') {
                    return $scope.sqlDatabases.some((sqlName) => sqlName.includes(term));
                }
            }
        }

        return false;
    };

    $scope.onIntegrationCanceled = function () {
        // Broadcasting newIntegrationAdded will cause the manage integrations (/integrations) page to get updated.
        // It won't cause any harm to broadcast this even if the integration is not really created in the end.
        // This should be fixed when tnk-integration-group / tnkIntegrationGroup is refactored.
        $rootScope.$broadcast('newIntegrationAdded');
    };

    $scope.ifCustomDataSourceDisplayed = function () {
        const customDataSourceLabel = 'new custom source';
        return (
            customDataSourceLabel.includes($scope.data.searchIntegrationNameText.toLowerCase()) || // If searching for custom
            ($scope.data.searchIntegrationNameText &&
                $scope.data.searchIntegrationNameText.length &&
                !$scope.filteredSources?.length &&
                !$scope.filteredWebhookSources.length)
        ); // No results
    };

    $scope.init();

    $scope.getNgDisabledTooltipTextOfDisabledWebhook = () => {
        const { disabledMessage } = getDataSourceAccessibilitySettings($scope.data.webhookDataSourceConnection);
        return disabledMessage;
    };

    /**
     * Initializes $scope.data.sources with all the non-connected sources.
     */
    async function loadSources() {
        // Constructing a list of existing integration unique types without duplicates.
        // We want to filter out the integration only if the current user created it.
        // Admins can view everyone's data sources in the current project and we don't want to prevent from them
        // to create existing data sources types.
        const existingIntegrations = new Set(
            $scope.pm.project.integrations
                .filter((projectIntegration) => projectIntegration.creator.id === authenticationService.currentUser.id)
                .map((projectIntegration) => projectIntegration.integration.integrationType.toLowerCase()),
        );

        // Get all integrations
        const allIntegrations = integrations.getUniqueIntegrationsDataSources(
            [],
            projectManager.projectData.canBeCreatedByUserIntegrationTypes,
            $scope.pm.project.id,
        );

        // An array of all of the integration that can be create multiple times by the user.
        const canBeCreatedMultipleByUserIntegrationTypes =
            projectManager.projectData?.canBeCreatedMultipleByUserIntegrationTypes || [];

        const { entities: dataSourceConnections } = await tonkeanService.getDataSourceConnections($scope.pm.project.id);

        $scope.data.webhookDataSourceConnection = dataSourceConnections.find(
            (singleDataSourceConnection) => singleDataSourceConnection.dataSourceType === 'WEBHOOK',
        );

        const { isDisabled } = getDataSourceAccessibilitySettings($scope.data.webhookDataSourceConnection);
        $scope.data.isWebhookCreationDisabled = isDisabled;

        // Map connected integrations.
        // If integration is already connected BUT it can also created multiple times by the user
        // we will consider the integration as not connected.This is done in order to allow the user
        // to connect more than 1 instance.
        $scope.data.sources = allIntegrations.map((source) => {
            const dataSourceConnection = dataSourceConnections.find(
                (dataSourceConnection) => dataSourceConnection.dataSourceType === source.toUpperCase(),
            );

            const { isDisabled, disabledMessage } = getDataSourceAccessibilitySettings(dataSourceConnection);

            const isAlreadyConnected =
                existingIntegrations.has(source) &&
                !canBeCreatedMultipleByUserIntegrationTypes.includes(source.toUpperCase());

            return {
                name: source,
                isConnected: isAlreadyConnected,
                isDisabled: isDisabled || isAlreadyConnected,
                disabledMessage,
            };
        });

        if (mode !== 'all') {
            $scope.data.sources = $scope.data.sources.filter((source) => !!$scope.importSettings[source]);
        }
    }

    $scope.loadMore = () => {
        if ($scope.data.webhookSourcesLimit < $scope.data.webhooksSources.length) {
            $scope.data.webhookSourcesLimit += 60;
        }

        if ($scope.data.marketplaceSourcesLimit < $scope.data.marketplaceSources.length) {
            $scope.data.marketplaceSourcesLimit += 60;
        }
    };

    /**
     * Checks if a given user is permitted given a permissions configuration
     * @param connectionPermission - The permissions of the data source connection
     * @param authorizedUsers - The authorized users, in case the permissions is specific users
     * @param userId - The user id to check the permissions of
     * @returns {boolean|boolean|*} True if the user is allowed to connect
     */
    function isUserPermitted(connectionPermission, authorizedUsers = [], userId) {
        if (projectManager.isOwner) {
            return true;
        }

        if (connectionPermission === ConnectionPermission.SPECIFIC_MEMBERS) {
            return (
                authorizedUsers?.includes(userId) ||
                $scope.data.userGroupsIds.some((userGroupId) => authorizedUsers.includes(userGroupId))
            );
        } else {
            return connectionPermission === ConnectionPermission.ALL_MEMBERS;
        }
    }

    /**
     * Gets a data source connection and returns a settings object that represent whether the user
     * is allowed to use that data source. If the user is not allowed, a message will be provided.
     */
    function getDataSourceAccessibilitySettings(dataSourceConnection) {
        let disabledMessage = null;

        if (dataSourceConnection?.enabled) {
            if (
                !isUserPermitted(
                    dataSourceConnection.connectionPermission,
                    dataSourceConnection.authorizedEntitiesIds,
                    authenticationService.currentUser.id,
                )
            ) {
                // First we check whether the user has permissions to use this data source at all.
                disabledMessage = "You're not allowed to create this type of data source";
            } else if (
                dataSourceConnection.allowOnlySharedCredentials &&
                !hasAtLeastOneActiveAuthorizedPredefinedConnection(
                    dataSourceConnection,
                    authenticationService.currentUser.id,
                ) &&
                $scope.data.isNewCreateDataSourceModal
            ) {
                // If the user has permissions to use the data source we check whether only predefined connections
                // are allowed and he has permission to one of them.
                disabledMessage =
                    'Only predefined connections are allowed, and there are no predefined connections configured';
            }
        } else {
            // If the data source connection is off, we default to project settings permissions
            const projectGlobalDataSourceConnectionsConfiguration =
                $scope.pm.project.projectIntegrationConnectionPermissionConfiguration;
            if (projectGlobalDataSourceConnectionsConfiguration) {
                const connectionPermission =
                    projectGlobalDataSourceConnectionsConfiguration.nonSharedCredentialsConnectionPermission;
                const authorizedPeopleIds = projectGlobalDataSourceConnectionsConfiguration.authorizedPeopleIds;

                if (!isUserPermitted(connectionPermission, authorizedPeopleIds, authenticationService.currentUser.id)) {
                    // Check whether the user has permissions to use this data source.
                    disabledMessage = "You're not allowed to create this type of data source";
                }
            }
        }

        return { isDisabled: !!disabledMessage, disabledMessage };
    }

    /**
     * Function that checks whether we have at least one active and authorized
     * predefined connection in the data source connection
     * @param dataSourceConnection - The data source connection
     * @returns {boolean} - True if there is at least one active predefined connection
     */
    function hasAtLeastOneActiveAuthorizedPredefinedConnection(dataSourceConnection, user) {
        return dataSourceConnection.sharedCredentials.some(
            (sharedCredential) =>
                sharedCredential.enabled &&
                ((sharedCredential.connectionPermission === ConnectionPermission.SPECIFIC_MEMBERS &&
                    (sharedCredential.authorizedUsers.includes(user) ||
                        $scope.data.userGroupsIds.some((userGroupId) =>
                            sharedCredential.authorizedUsers.includes(userGroupId),
                        ))) ||
                    sharedCredential.connectionPermission === ConnectionPermission.ALL_MEMBERS ||
                    projectManager.isOwner),
        );
    }

    async function loadMarketplaceSources() {
        $scope.data.loadingMarketplaceSources = true;
        $scope.data.marketplaceSources = await IntegrationManager.loadMarketplaceDataSources();
        $scope.data.loadingMarketplaceSources = false;
        
        if (!$scope.$$phase) {
            $scope.$apply();
        }
    }
}

export default angular.module('tonkean.app').controller('AddIntegrationCtrl', AddIntegrationCtrl);
