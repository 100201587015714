import template from './tnkPersonInquiryLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkPersonInquiryLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        includeFormAttachment: '<',
        includeItemInterfaceAttachment: '<',
        includeFieldAttachment: '<',
        createNewLogicCallback: '&',
        enableBotButtons: '<', // When true, a component for adding and removing buttons for the Module will be displayed.
        specialFieldsForFeatures: '<',
        previewEvaluationSource: '<',
        openFieldModal: '<',
    },
    template,
    controller: 'PersonInquiryLogicConfigurationCtrl',
});
