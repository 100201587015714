import type { DataSourcesSectionType } from '@tonkean/tonkean-entities';

/**
 * Gets a map between a section and its configuration.
 */
function getDataSourceSectionConfigurationMap(): Partial<Record<DataSourcesSectionType, { displayName: string }>> {
    return {
        CLOUD: {
            displayName: 'Cloud Application',
        },
        CUSTOM: {
            displayName: 'Custom / Internal API',
        },
        DATABASE: {
            displayName: 'Database / Storage / Bus',
        },
        EMAIL_INBOX: {
            displayName: 'Email Inbox',
        },
        SPREADSHEET: {
            displayName: 'Spreadsheet / Files',
        },
        COMMUNICATION: {
            displayName: 'Chat (Slack / MS Team)',
        },
    };
}

export default getDataSourceSectionConfigurationMap;
