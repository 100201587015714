import template from './tableauProjectIntegrationConfiguration.template.html?angularjs';

/**
 * Component for configuring a tableau project integration.
 */
export default angular.module('tonkean.app').component('tnkTableauProjectIntegrationConfiguration', {
    bindings: {
        projectIntegration: '<', // The project integration object.

        onProjectIntegrationCreated: '&', // Occurs once the creation of the project integration is finished.
        onCancel: '&', // Occurs when an integration is closed
    },
    template,
    controller: 'TableauProjectIntegrationConfigurationCtrl',
});
