import { angularToReact } from '@tonkean/angular-components';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { SearchInitiativesQuery } from '@tonkean/tonkean-entities';

const TnkTracksEditor = angularToReact<{
    scrollerElementId?: string;
    listParentElementId?: string;
    tracks?: any[];
    loading?: any;
    editorId?: string;
    parentItem?: any;
    defaultFunc?: any;
    defaultTag?: any;
    defaultMe?: any;
    defaultNewItemText?: any;
    hideHeader?: any;
    quickCreationMode?: any;
    lazyLoadTracks?: boolean;
    allowDndOnRoot?: boolean;
    viewOnlyMode?: boolean;
    anonymous?: any;
    collapseByDefault?: boolean;
    hideAddNewForce?: boolean;
    hideColumnQuickCreateForce?: boolean;
    showAddOnEmpty?: boolean;
    showArchived?: boolean;
    showRemindMe?: boolean;
    blockAddingLinks?: boolean;
    filter?: any;
    onlyGroup?: string;
    groupId?: string;
    workflowVersionId?: string;
    onSubmit?: any;
    overrideItemClick?: any;
    customFields?: any;
    isTagOrFunc?: any;
    searchInitiativesQuery?: SearchInitiativesQuery;
    loadNextInitiativesPageCallback?(): void;
    reloadAllInitiativesPages?(softUpdate: boolean): void;
    filterByField?: any;
    onOrderBy?: any;
    onSort?: (sortObject: { orderByFieldId: string; orderByFieldType: string; orderType: 'ASC' | 'DESC' }) => void; // This is for react components
    hasMoreInitiatives?: boolean;
    saveOrderPreference?: any;
    createdInWorkerRunInformation?: any;
    createTracksInEditMode?: any;
    displayFieldsList?: any;
    onInitiativeCreated?: any;
    onTrackRemoved?: any;
    onManualFieldUpdate?: (value: unknown) => void;
    ignoreColumnVisibility?: boolean;
    hideBulkSelection?: boolean;
    addItemLabel?: any;
    minimizeTitleHeader?: any;
    createdInFormId?: string;
    collectItemsMode?: boolean;
    createdInFormName?: string;
    createdInForm?: any;
    doNotAllowEditPublishedInitiatives?: boolean;
    hideContextMenu?: boolean;
    formViewInInitiative?: boolean;
    showHeader?: boolean;
    headerMaxLines?: number;
    disableNewItemTypeahead?: boolean;
    disableOwnerInvite?: boolean;
    noSubitems?: boolean;
    disableGoToTrack?: boolean;
    viewItemsMode?: any;
    stickyHeader?: boolean;
    fieldValidationMap?: any;
    hideHistory?: boolean;
    hideTitle?: boolean;
    showActionsColumn?: boolean;
    workerForms?: any;
    solutionBusinessReportId?: string;
    environment?: string;
    onlyUpdateExistingItems?: boolean;
    fieldsConfigurations?: any;
    defaultItemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    enableInnerItemsToggle?: boolean;
    showDone?: boolean;
    disableFieldHeaderDropdown?: boolean;
    enableAddingItems?: boolean;
    numerateItems?: boolean;
}>('tnk-tracks-editor', {
    loadNextInitiativesPageCallback: [],
    reloadAllInitiativesPages: ['softUpdate'],
});

export default TnkTracksEditor;
