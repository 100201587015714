import GenericIntegrationComponent from './GenericIntegrationComponent';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.shared')
    .component(
        'tnkGenericIntegrationComponent',
        reactToAngular(
            GenericIntegrationComponent,
            ['integrationType', 'projectIntegration', 'onIntegrationClosed', 'onCancel', 'integration'],
            ['utils', 'createProjectApis', 'projectManager'],
        ),
    );
