<div class="match-option-selection">
    <!-- Specific item -->
    <div class="flex" ng-hide="data.onlyShowDefaultSelectedOption">
        <!-- Specific item radio button -->
        <tnk-radio-button
            class="margin-right-xs"
            ng-show="data.showAdvanced"
            radio-id="specific-item-{{ data.workflowVersionId }}"
            radio-name="match-option-selection-{{ data.workflowVersionId }}"
            radio-value="specificItem"
            model="data.selectedMatchOption.id"
            is-required="true"
            ng-hide="data.onlyShowDefaultSelectedOption"
            on-click="onMatchOptionSelected(onClickParam)"
            on-click-param="('specificItem')"
            view-only="data.viewOnly"
        ></tnk-radio-button>

        <!-- Specific item content -->
        <div class="flex-grow">
            <span class="common-size-xxxs margin-right">
                Use the
                {{
                    data.entityMetadata.displayName ||
                        data.entityMetadata.label ||
                        data.entityMetadata.entity ||
                        data.entityMetadata
                }}
                in this workflow:
            </span>

            <!-- Workflow item selection -->
            <tnk-workflow-item-selection
                perform-on-worker-item="data.savedMatchOptionSelection.performOnWorkerItem"
                creating-custom-trigger-id="data.savedMatchOptionSelection.creatingCustomTriggerId"
                id-relation-field-definition-id="data.savedMatchOptionSelection.idRelationFieldDefinitionId"
                entity-metadata="data.entityMetadata"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                view-only="data.viewOnly"
                on-workflow-item-selection-changed="
                    onWorkflowItemSelectionChanged(
                        performOnWorkerItem,
                        creatingCustomTriggerId,
                        idRelationFieldDefinitionId,
                        shouldSaveLogic
                    )
                "
            ></tnk-workflow-item-selection>
        </div>
    </div>

    <!-- Match field rules -->
    <div class="margin-top" ng-show="data.showAdvanced">
        <!-- Match field -->
        <div
            class="flex"
            ng-hide="data.onlyShowDefaultSelectedOption && data.defaultSelectedOption !== 'matchFieldRules'"
        >
            <!-- Match field by rules radio button -->
            <tnk-radio-button
                radio-id="match-field-rules-{{ data.workflowVersionId }}"
                class="margin-right-xs"
                radio-name="match-option-selection-{{ data.workflowVersionId }}"
                radio-value="matchFieldRules"
                model="data.selectedMatchOption.id"
                is-required="true"
                ng-hide="data.onlyShowDefaultSelectedOption"
                on-click="onMatchOptionSelected(onClickParam)"
                on-click-param="('matchFieldRules')"
                view-only="data.viewOnly"
            ></tnk-radio-button>

            <div class="flex-grow">
                <!-- Radio button content - match by field -->
                <tnk-workflow-match-by-field
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    entity-metadata="data.entityMetadata"
                    project-integration="data.projectIntegration"
                    entity-match-field="data.savedMatchOptionSelection.entityMatchField"
                    worker-item-match-field-definition="data.savedMatchOptionSelection.workerItemMatchFieldDefinition"
                    worker-item-match-special-field="data.savedMatchOptionSelection.workerItemMatchSpecialField"
                    on-workflow-match-by-field-changed="
                        onWorkflowMatchByFieldChanged(
                            entityMatchField,
                            workerItemMatchFieldDefinition,
                            workerItemMatchSpecialField,
                            shouldSaveLogic
                        )
                    "
                    view-only="data.viewOnly"
                ></tnk-workflow-match-by-field>
            </div>
        </div>

        <!-- Match custom query -->
        <div
            class="flex margin-top-xs"
            ng-hide="data.onlyShowDefaultSelectedOption && data.defaultSelectedOption !== 'matchFieldCustomQuery'"
        >
            <!-- Match custom query by rules radio button -->
            <tnk-radio-button
                radio-id="match-field-custom-query-{{ data.workflowVersionId }}"
                class="margin-right-xs"
                radio-name="match-option-selection-{{ data.workflowVersionId }}"
                radio-value="matchFieldCustomQuery"
                model="data.selectedMatchOption.id"
                is-required="true"
                ng-hide="data.onlyShowDefaultSelectedOption"
                on-click="onMatchOptionSelected(onClickParam)"
                on-click-param="('matchFieldCustomQuery')"
                view-only="data.viewOnly"
            ></tnk-radio-button>

            <div class="flex-grow">
                <div class="margin-bottom" ng-hide="data.onlyShowDefaultSelectedOption">Custom query</div>
                <!-- Radio button content - match by custom query -->
                <tnk-custom-filters
                    display-integration-indication="true"
                    project-integration="data.projectIntegration"
                    hide-close-button="true"
                    control="data.customQueryControl"
                    items-source="{{ data.projectIntegration ? 'EXTERNAL' : 'COLUMN' }}"
                    selected-entity-type="data.entityMetadata && data.entityMetadata.entity ? data.entityMetadata.entity : data.entityMetadata"
                    existing-definition="data.customQueryDefinition"
                    group-id="data.groupId"
                    workflow-version-id="data.workflowVersionId"
                    target-workflow-version-id="data.targetWorkflowVersionId"
                    edit-mode="data.editMode"
                    small-mod="true"
                    vertical-mod="data.verticalMod"
                    hide-time-range-selection="true"
                    bold-title="true"
                    special-fields-for-features="['MATCH_FIELD_CUSTOM_QUERY']"
                    on-filters-change="onFilterChange(shouldNotSaveLogic)"
                    filters-change-only-after-init="true"
                    reload-fields-on-new-field-definition-creation="'true'"
                    show-field-inspect-modal="data.projectIntegration"
                    inspect-entity-type="data.entityMetadata && data.entityMetadata.entity ? data.entityMetadata.entity : data.entityMetadata"
                    use-expressions-for-value="true"
                    mod-fixed-width="data.modFixedWidth"
                    tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
                    tabs-field-selector-mod-fixed-width="data.tabsFieldSelectorModFixedWidth"
                    allowed-conditions-set="data.allowedConditionsSet"
                    force-expand="data.isWorkflowVersionPublished"
                    view-only="data.viewOnly"
                    preview-evaluation-source="data.previewEvaluationSource"
                    not-allowed-conditions-set="data.notAllowedConditionsSet"
                ></tnk-custom-filters>

                <!-- Order by -->
                <div class="flex-vmiddle margin-top" ng-show="data.selectedMatchOption.id === 'matchFieldCustomQuery'">
                    <!-- Order by title -->
                    <div class="margin-right">Order by:</div>

                    <!-- Order by field selection (Datasource) -->
                    <tnk-field-selector
                        ng-if="!data.orderByTonkeanField"
                        class="common-width-50"
                        selected-field="data.orderByField"
                        items-source="EXTERNAL"
                        project-integration="data.projectIntegration"
                        external-type="data.entityMetadata.pluralLabel || data.entityMetadata"
                        on-field-selected="onOrderByFieldSelected(selectedField, dontSaveChanges)"
                        place-holder="Select a data source field"
                        field-inspect-modal="true"
                        allow-clear-selection="true"
                        field-inspect-auto-open="true"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        view-only="!data.projectIntegration.valid"
                        tooltip-value="data.projectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
                    ></tnk-field-selector>

                    <!-- Order by field selection (Tonkean Field) -->
                    <tnk-field-selector
                        allow-clear-selection="true"
                        class="common-width-50"
                        items-source="COLUMN"
                        ng-if="data.orderByTonkeanField"
                        on-field-selected="onOrderByFieldSelected(selectedField, dontSaveChanges)"
                        place-holder="Select an order by field"
                        selected-field="data.orderByField"
                        workflow-version-id="{{ data.targetWorkflowVersionId }}"
                    ></tnk-field-selector>

                    <span>
                        <i
                            class="fa fa-info-circle margin-left-xs common-color-grey"
                            uib-tooltip="If multiple items matched the condition, order by provided field and take the first. If no field provided, will take one arbitrarily."
                        ></i>
                    </span>
                </div>

                <!-- Order by order -->
                <div class="flex-vmiddle margin-top" ng-show="data.selectedMatchOption.id === 'matchFieldCustomQuery'">
                    <!-- Order by order title -->
                    <div class="margin-right">Order:</div>

                    <!-- Order by order selection -->
                    <ui-select
                        class="margin-right"
                        ng-model="data.orderBySelectedOrder"
                        ng-change="onOrderBySelectedOrderSelected(data.orderBySelectedOrder)"
                        theme="bootstrap"
                    >
                        <ui-select-match placeholder="Order type...">
                            {{ data.orderBySelectedOrder.displayName }}
                        </ui-select-match>
                        <ui-select-choices repeat="orderType in data.orderTypes | filter:$select.search ">
                            <div ng-bind-html="orderType.displayName | escapeHtml | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>
                </div>

                <!-- Order by field type -->
                <div class="flex-vmiddle margin-top" ng-show="data.selectedMatchOption.id === 'matchFieldCustomQuery'">
                    <!-- Order by field type title -->
                    <div class="margin-right">Order field type:</div>

                    <!-- Order by field type selection -->
                    <ui-select
                        class="margin-right"
                        ng-model="data.selectedOrderByFieldType"
                        ng-change="onOrderByFieldTypeSelected(data.selectedOrderByFieldType)"
                        theme="bootstrap"
                    >
                        <ui-select-match placeholder="Order type...">
                            {{ data.selectedOrderByFieldType.displayName }}
                        </ui-select-match>
                        <ui-select-choices repeat="orderByFieldType in data.orderByFieldTypes | filter:$select.search ">
                            <div
                                ng-bind-html="orderByFieldType.displayName | escapeHtml | highlight: $select.search"
                            ></div>
                        </ui-select-choices>
                    </ui-select>
                </div>
            </div>
        </div>
    </div>

    <!-- Warning -->
    <div class="common-color-grey common-size-xxxxs margin-top" ng-show="data.showAdvanced">
        Use the order by to define what entity should be selected if more than one is matched.
    </div>

    <!-- Display custom match configuration -->
    <div ng-hide="data.showAdvanced" class="common-color-grey margin-top common-size-xxxs">
        Or use
        <a ng-click="data.showAdvanced = true" data-automation="tnk-match-option-selection-template-custom-match">Custom match</a>
    </div>

    <div ng-if="data.validationObject.cantBeRootMatch" class="common-error">
        {{ data.validationObject.cantBeRootMatch }}
    </div>
</div>
