import CoupaAuthenticationComponent from './CoupaAuthenticationComponent';
import CoupaCustomizeSetupComponent from './CoupaCustomizeSetupComponent';
import coupaIcon from '../../../../apps/tracks/images/coupa.png';
import coupaCircleIcon from '../../../../apps/tracks/images/integrations/coupa-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class CoupaIntegration extends IntegrationDefinitionBase {
    override name = 'coupa';
    override displayName = 'Coupa';
    override description = 'We will only collect Purchase Orders & Users items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Invoice', 'InvoiceS'),
        new IntegrationEntity('PurchaseOrder', 'Purchase Orders'),
        new IntegrationEntity('Requisition', 'Requisitions'),
        new IntegrationEntity('Supplier', 'Suppliers'),
        new IntegrationEntity('SupplierInformation', 'Suppliers Information'),
        new IntegrationEntity('User', 'Users'),
    ];
    override customizedAuthenticationComponent = CoupaAuthenticationComponent;
    override customizedSetupComponent = CoupaCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(coupaCircleIcon, '130px'),
        new IntegrationIcon(coupaIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            Invoice: true,
            PurchaseOrder: true,
            Requisition: true,
            Supplier: true,
            SupplierInformation: true,
            User: true,
        },
        isOnlyDataRetention: {},
    };
}

export default CoupaIntegration;
