/**
 * Created by ShaLi on 15/12/2015.
 */

function EditProjectCtrl($scope, $stateParams, $state, $log, projectManager) {
    $scope.pm = projectManager;
    if ($stateParams.projectId === 'board') {
        $scope.pm.getProjects().then(function () {
            if ($scope.pm.projects && $scope.pm.projects.length) {
                // $state.go('products');
                // select the first one
                $log.info('Redirecting to the first project');
                $stateParams.projectId = $scope.pm.projects[0].id;
                $state.go(
                    'product.settings.edit',
                    { projectId: $scope.pm.projects[0].id },
                    { location: 'replace', reload: true },
                );
            } else {
                $log.info('No projects found');
                $state.go('loginCreate');
            }
        });
    } else {
        $scope.pm.selectProject($stateParams.projectId);
    }

    $scope.$watch('newProject', function () {
        if ($scope.newProject) {
            $scope.pm.selectProject($scope.newProject);
            $scope.$emit('alert', { msg: 'Settings saved!', type: 'success' });
            // $state.go('product.enterpriseComponents', { tab: 'communication-sources', projectId: $scope.newProject.id });
        }
    });

    $scope.cancel = function () {
        $state.go('product.enterpriseComponents', { tab: 'communication-sources' });
    };
}
export default angular.module('tonkean.app').controller('EditProjectCtrl', EditProjectCtrl);
