import React, { useContext } from 'react';
import styled from 'styled-components';

import ItemWidgetContext from '../../entities/ItemWidgetContext';

import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const ItemClickArea = styled(Clickable)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 100;

    ${styledFocus}
`;

const ItemWidgetSidepaneTrigger: React.FC = () => {
    const widgetContext = useContext(ItemWidgetContext);

    return (
        <ItemClickArea
            data-automation="item-widget-side-pane-trigger-item-click-area"
            aria-label="open widget configuration menu"
            {...widgetContext!.sidepaneClickableProps}
        />
    );
};

export default ItemWidgetSidepaneTrigger;
