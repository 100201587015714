import mailchimpCircleImage from '../../../../apps/tracks/images/integrations/mailchimp-circle.png';
import mailchimpImage from '../../../../apps/tracks/images/mailchimp.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class MailchimpMarketingIntegration extends IntegrationDefinitionBase {
    override name = 'mailchimpmarketing';
    override displayName = 'Mailchimp Marketing';
    override description = '';
    override authenticationType = AuthenticationMethod.API_TOKEN;

    override entities = [new IntegrationEntity('Audience', 'Audiences')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(mailchimpCircleImage, '130px'),
        new IntegrationIcon(mailchimpImage, '130px'),
    );
}

export default MailchimpMarketingIntegration;
