import template from './displayConfiguration.template.html?angularjs';

/**
 * Component to select display configuraiton.
 */
export default angular.module('tonkean.app').component('tnkDisplayConfiguration', {
    bindings: {
        fieldDefinitionType: '<',
        targetType: '<',
        fieldType: '<',
        displayAs: '<',
        numberFieldDecimalPlaces: '<',
        evaluatedDisplayType: '<',
        displayFormat: '<',
        displayFormatPrefix: '<',
        displayFormatPostfix: '<',
        compareTimeframe: '<',
        isIncrementNegative: '<',
        decimalPlaces: '<',
        includeTrailingZeros: '<',
        disabled: '<',
        isMultiValueField: '<',
        outputMultiValueSeparator: '<',
        fieldLabel: '<',
        onDisplayConfigurationChange: '&', // Callback to call once the display configuration is changed
        projectIntegrationId: '<', // For the data source icon - if needed
        isFromMatchedItems: '<', // Is the items is matched items
    },
    template,
    controller: 'DisplayConfigurationCtrl',
});
