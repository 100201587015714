import type SolutionVersionsFilters from './SolutionVersionsFilters';

const initialSolutionVersionsFilters: SolutionVersionsFilters = {
    fromDate: undefined,
    toDate: undefined,
    publisherId: undefined,
    sequentialIdentifier: undefined,
};

export default initialSolutionVersionsFilters;
