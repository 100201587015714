import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import GroupSimpleSelector from './GroupSimpleSelector';
import WorkflowFolderSimpleSelector from './WorkflowFolderSimpleSelector';
import WorkflowFolderCategorySelector from '../../SolutionsPageModule/components/SolutionPageModules/WorkflowFolderCategorySelector';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import {
    Field,
    H4,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    SimpleSelect,
    Spacer,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import type { MarketplaceItem, ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { MarketplaceItemType, marketplaceItemTypeDisplayName } from '@tonkean/tonkean-entities';

const Description = styled(H4)`
    line-height: 18px;
`;

const StyledField = styled(Field)`
    margin-bottom: 20px;
`;
interface Props {
    marketplaceItem: MarketplaceItem;
    rootEntityName: string | undefined;
    onSubmit: (marketplaceItem: MarketplaceItem) => void;
}

const MarketplaceItemUpdateSourceModal: React.FC<Props> = ({ marketplaceItem, rootEntityName, onSubmit }) => {
    const onClose = useCloseCallback();

    const { id: marketplaceItemId, projectId, type, configuration } = marketplaceItem;

    const [{ loading, error }, updateMarketplaceItemCreationJson] = useLazyTonkeanService(
        'updateMarketplaceItemCreationJson',
    );

    const [entityId, setEntityId] = useState<TonkeanId | undefined>();

    const [version, setVersion] = useState<string | undefined>(marketplaceItem.version);

    const [wasSaved, setWasSaved] = useState<boolean>(false);

    const onSave = useCallback(() => {
        if (!version) {
            return;
        }

        return updateMarketplaceItemCreationJson(marketplaceItemId, projectId, type, version, entityId).then(
            (updatedMarketplaceItem) => {
                setWasSaved(true);
                onSubmit(updatedMarketplaceItem);
                onClose();
            },
        );
    }, [entityId, updateMarketplaceItemCreationJson, marketplaceItemId, projectId, type, version, onSubmit, onClose]);

    const [workflowFolderId, setWorkflowFolderId] = useState<TonkeanId<TonkeanType.WORKFLOW_FOLDER> | undefined>();
    const onWorkflowFolderSelected = useCallback(
        (selectedWorkflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>) => {
            setWorkflowFolderId(selectedWorkflowFolderId);
            setWorkflowFolderCategoryId(undefined);
            setGroupId(undefined);
            if (type === MarketplaceItemType.SOLUTION) {
                setEntityId(selectedWorkflowFolderId);
            }
        },
        [type],
    );

    const [workflowFolderCategoryId, setWorkflowFolderCategoryId] = useState<
        TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY> | undefined
    >();
    const onWorkflowFolderCategorySelected = useCallback(
        (selectedWorkflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>) => {
            setWorkflowFolderCategoryId(selectedWorkflowFolderCategoryId);
            setGroupId(undefined);
        },
        [],
    );

    const [groupId, setGroupId] = useState<TonkeanId<TonkeanType.GROUP> | undefined>();
    const onGroupSelected = useCallback((selectedGroupId: TonkeanId<TonkeanType.GROUP>) => {
        setGroupId(selectedGroupId);
        setEntityId(selectedGroupId);
    }, []);

    const onProjectIntegrationSelected = (projectIntegration: ProjectIntegration) => {
        setEntityId(projectIntegration.id);
    };

    const componentTypeName = marketplaceItemTypeDisplayName[type].toLowerCase();

    const validateVersion = () => {
        if (wasSaved) return undefined;

        if (!version) return 'Version field must not be empty';

        if (version === marketplaceItem.version) return 'Version must be different from current version';

        return undefined;
    };

    return (
        <>
            <ModalHeader>Update Source</ModalHeader>
            <ModalBody>
                <Description>
                    Update the {componentTypeName} component based on changes done in the {componentTypeName} which was
                    used to build this template{rootEntityName ? ` (${rootEntityName})` : ''}.
                    <br />
                    Important note: The <strong>production version</strong> of the component will be used.
                    {type === MarketplaceItemType.MODULE && configuration.sourceModule && (
                        <>
                            <br />
                            <Spacer height={8} />
                            Current source is of module "{configuration.sourceModule.name}" V
                            {configuration.sourceModule.version}
                        </>
                    )}
                </Description>
                <Spacer height={24} />
                {(type === MarketplaceItemType.SOLUTION || type === MarketplaceItemType.MODULE) &&
                    !marketplaceItem.rootEntityId && (
                        <>
                            <StyledField label={<H4 $bold>Solution: </H4>}>
                                <WorkflowFolderSimpleSelector
                                    projectId={projectId}
                                    onWorkflowFolderSelected={onWorkflowFolderSelected}
                                />
                            </StyledField>
                        </>
                    )}
                {type === MarketplaceItemType.MODULE && !marketplaceItem.rootEntityId && (
                    <>
                        <StyledField label={<H4 $bold>Section:</H4>}>
                            {workflowFolderId ? (
                                <WorkflowFolderCategorySelector
                                    workflowFolderId={workflowFolderId}
                                    workflowFolderCategoryId={workflowFolderCategoryId}
                                    onCategorySelected={onWorkflowFolderCategorySelected}
                                />
                            ) : (
                                <SimpleSelect options={[]} placeholder="Select Section" isDisabled />
                            )}
                        </StyledField>

                        <StyledField label={<H4 $bold>Module: </H4>}>
                            {workflowFolderId && workflowFolderCategoryId ? (
                                <GroupSimpleSelector
                                    projectId={projectId}
                                    workflowFolderId={workflowFolderId}
                                    workflowFolderCategoryId={workflowFolderCategoryId}
                                    groupId={groupId}
                                    onGroupSelected={onGroupSelected}
                                />
                            ) : (
                                <SimpleSelect options={[]} placeholder="Select Module" isDisabled />
                            )}
                        </StyledField>
                    </>
                )}
                {type === MarketplaceItemType.DATA_SOURCE && !marketplaceItem.rootEntityId && (
                    <>
                        <StyledField label={<H4 $bold>Data Source: </H4>}>
                            <TnkProjectIntegrationSelector
                                selectedProjectIntegrationId={undefined}
                                onProjectIntegrationSelected={onProjectIntegrationSelected}
                            />
                        </StyledField>
                    </>
                )}
                <StyledField label={<H4 $bold>Version:</H4>} error={validateVersion()}>
                    <Input value={version} onChange={({ target }) => setVersion(target.value)} placeholder="Version" />
                </StyledField>
            </ModalBody>
            <ModalFooterActions
                saveDisabled={(!entityId && !marketplaceItem.rootEntityId) || validateVersion() !== undefined}
                onSave={onSave}
                loading={loading}
                error={error}
                border
            />
        </>
    );
};

export default withModal(MarketplaceItemUpdateSourceModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
    escapeCloses: true,
});
