<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Add member to card -->
        <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'ADD_MEMBER_TO_CARD'">
            <!-- Member Email -->
            <div>
                <div class="margin-bottom-xs">Member Email:</div>

                <div ng-if="data.validationObject.noEmail" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noEmail }}
                </div>

                <!-- Input -->
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Member email..."
                    saved-original-expression="data.definition.email"
                    saved-evaluated-expression="data.definition.evaluatedEmail"
                    expression-unique-identifier="TRELLO_ADD_MEMBER_EMAIL"
                    on-tonkean-expression-changed="
                        onEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'COMMENT_ON_CARD'">
            <!-- Member Email -->
            <div>
                <div class="margin-bottom-xs">Comment Text:</div>

                <div
                    ng-if="data.validationObject.noCommentText"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noCommentText }}
                </div>

                <!-- Input -->
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Comment Text..."
                    saved-original-expression="data.definition.commentText"
                    saved-evaluated-expression="data.definition.evaluatedCommentText"
                    expression-unique-identifier="TRELLO_COMMENT_CARD_TEXT"
                    on-tonkean-expression-changed="
                        onCommentTextTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>

                <!-- Author note -->
                <div class="margin-top-xs common-color-light-grey common-size-xxxxs">
                    The author of the comment will be the person who connected the integration ({{
                        data.projectIntegration.creator.name
                    }}
                </div>
            </div>
        </div>

        <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'LABEL_CARD'">
            <!-- Label Color -->
            <div>
                <div class="flex-vmiddle margin-bottom-xs">
                    <div class="margin-right-xs">Label Color:</div>

                    <!-- Input-->
                    <tnk-color-options-picker
                        selected-color="data.definition.colorValue"
                        color-options="data.availableLabelColors"
                        on-color-selected="onMessageColorSelected(colorName, color)"
                    ></tnk-color-options-picker>
                </div>
            </div>

            <!-- Label Name -->
            <div>
                <div class="margin-bottom-xs">Label Name:</div>

                <!-- Input -->
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Label name..."
                    saved-original-expression="data.definition.name"
                    saved-evaluated-expression="data.definition.evaluatedName"
                    expression-unique-identifier="TRELLO_LABEL_CARD_NAME"
                    on-tonkean-expression-changed="
                        onNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'MOVE_CARD'">
            <!-- Select List -->
            <div ng-if="!data.trelloListsLoading">
                <div class="margin-bottom inline-block" uib-dropdown>
                    <button uib-dropdown-toggle class="btn btn-default">
                        {{ data.selectedList.displayName }}
                        <span class="common-size-xxxs common-color-light-grey">
                            ({{ data.selectedList.description }})
                        </span>
                        <span class="dropdown-chevron mod-static"></span>
                    </button>
                    <ul uib-dropdown-menu>
                        <li
                            ng-repeat="list in data.trelloLists | orderBy:'description'"
                            ng-click="onTrelloListSelected(list)"
                        >
                            <a>
                                {{ list.displayName }}
                                <span class="common-size-xxxs common-color-light-grey">({{ list.description }})</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="common-color-light-grey common-size-xxxs">
                    Note: Can't move Trello Cards between boards!
                </div>
            </div>

            <div ng-if="data.trelloListsLoading" class="loading"></div>
        </div>
    </div>
</div>
