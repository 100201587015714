import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { getContractsByProjectId } from '@tonkean/network-service';
import type { ContractSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useTDLContractsByProjectId = (id: TonkeanId<TonkeanType.PROJECT>) => {
    return useTonkeanQuery<{ projectId: TonkeanId<TonkeanType.PROJECT>; entities: ContractSummary[] } | undefined>({
        queryUniqueKey: 'getContractsByProjectId',
        propsUniqueKeysArray: [id],
        queryFn: async (networkService) => {
            return getContractsByProjectId(networkService, id);
        },
        enabledFn: () => !!id,
    });
};

export default useTDLContractsByProjectId;
