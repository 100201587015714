import { OperatorKey } from '@tonkean/tonkean-entities';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function ColumnFormulaFieldCtrl($scope, $timeout, customTriggerManager) {
    const ctrl = this;

    $scope.ctm = customTriggerManager;
    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetType: ctrl.targetType,
        additionalTabs: ctrl.additionalTabs,
        customTrigger: ctrl.customTrigger,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        aggregationOnColumnFeatureName: ctrl.aggregationOnColumnFeatureName,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        columnFormulaFeatureName: ctrl.columnFormulaFeatureName,

        treeViewMod: true,
        originalFormulaExpression: '',
        evaluatedFormulaExpression: '',
        formulaData: undefined,

        formulaType: 'EXPRESSION',
        innerTracksAggregationDefinition: {},

        variablesUsedInExpression: [],
        validationResult: undefined,

        selectedExampleInitiative: null,

        formulaExpressionTree: ctrl.formulaExpressionTree,
        hideInnerTrackAggregationFormula: ctrl.hideInnerTrackAggregationFormula,
        workflowVersionType: ctrl.workflowVersionType,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        if (ctrl.overrideFormulaOperator?.apiName === OperatorKey.INNER_TRACK_AGGREGATION) {
            $scope.onSelectInnerTrackAggregation(true);
            return;
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged(true);
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        const changedEnvironment = changesObj.workflowVersionId && !changesObj.workflowVersionId.isFirstChange();
        if (changedEnvironment) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.data.workflowVersionType = ctrl.workflowVersionType;
            $scope.data.existingFieldDefinition = changesObj.existingFieldDefinition.currentValue;
            initializeEditMode();
        }

        if (changesObj.formulaExpressionTree) {
            $scope.data.formulaExpressionTree = ctrl.formulaExpressionTree;
        }
        if (changesObj.onSelectInnerTrackAggregation) {
            $scope.data.onSelectInnerTrackAggregation = ctrl.onSelectInnerTrackAggregation;
        }
        if (changesObj.hideInnerTrackAggregationFormula) {
            $scope.data.hideInnerTrackAggregationFormula = ctrl.hideInnerTrackAggregationFormula;
        }
    };

    /**
     * Occurs once the formula expression is changed.
     */
    $scope.onFormulaExpressionChanged = function (
        evaluatedFormulaExpression,
        validationResult,
        originalFormulaExpression,
        variablesUsedInExpression = [],
        isInit = false,
        expressionNode,
    ) {
        if (
            $scope.data.evaluatedFormulaExpression !== evaluatedFormulaExpression ||
            $scope.data.originalFormulaExpression !== originalFormulaExpression
        ) {
            ctrl.onChange();
        }

        $scope.data.evaluatedFormulaExpression = evaluatedFormulaExpression;
        $scope.data.validationResult = validationResult;
        $scope.data.originalFormulaExpression = originalFormulaExpression;
        $scope.data.variablesUsedInExpression = variablesUsedInExpression.filter(Boolean);
        $scope.data.expressionNode = expressionNode;

        // When formula changes, it makes the tree outdated and should be removed so it will not
        // be passed to the builder.
        $scope.data.formulaExpressionTree = undefined;

        definitionChanged(isInit);
    };

    /**
     * Occurs once the formula data is changed
     */
    $scope.onFormulaDataChange = function (formulaData, isInit = false) {
        // We call to ctrl.onChange if was change in formulaData and it is not the first initialzie
        if ($scope.data.formulaData !== formulaData && !isInit) {
            ctrl.onChange();
        }

        $scope.data.formulaData = formulaData;
        definitionChanged(isInit, true);
    };

    /**
     * On Example initiative selected evaluate fields values.
     */
    $scope.onInitiativeSelected = function (selectedSimplifiedInitiative) {
        if (selectedSimplifiedInitiative.id) {
            $scope.data.selectedExampleInitiative = selectedSimplifiedInitiative;
            customTriggerManager.buildWorkflowVersionExampleItems(
                $scope.data.workflowVersionId,
                selectedSimplifiedInitiative.id,
            );
        }
    };

    /**
     * Triggered from the formula builder when inner track aggregation has been selected
     */
    $scope.onSelectInnerTrackAggregation = (isInit) => {
        $timeout(() => {
            $scope.data.formulaType = 'STRUCTURED';
            definitionChanged(isInit);

            if (!isInit) {
                $scope.data.onSelectInnerTrackAggregation();
            }
        });
    };

    /**
     * Triggered in the mock formula builder in the inner track aggregation to change it to
     * a formula.
     *
     * @param formulaTree {object} - the new evaluated formula
     */
    $scope.onFormulaSelected = (formulaTree) => {
        $timeout(() => {
            $scope.data.formulaType = 'EXPRESSION';
            $scope.data.formulaExpressionTree = formulaTree;
            definitionChanged();
        });
    };

    /**
     * Occurs once the inner tracks aggregation definition is changed.
     */
    $scope.onInnerTracksAggregationDefinitionChanged = function (innerTracksAggregationDefinition) {
        $scope.data.innerTracksAggregationDefinition = innerTracksAggregationDefinition;
        definitionChanged(false, false, innerTracksAggregationDefinition);
    };

    /**
     * Gets the definition for this field.
     */
    function getDefinitionObject() {
        const specificDefinition = !isAggregation()
            ? {
                  formulaExpression: $scope.data.evaluatedFormulaExpression,
                  originalFormulaExpression: $scope.data.originalFormulaExpression,
              }
            : {
                  formulaOperator: 'INNER_TRACK_AGGREGATION',
                  innerTracksAggregationDefinition: $scope.data.innerTracksAggregationDefinition.definition,
              };

        return {
            ...specificDefinition,
            groupId: $scope.data.groupId,
            formulaType: $scope.data.formulaType,
        };
    }

    /**
     * Loads all relevant properties from definition in edit mode.
     */
    function initializeEditMode() {
        $scope.data.originalFormulaExpression =
            $scope.data.existingFieldDefinition.definition.originalFormulaExpression || '';
        $scope.data.evaluatedFormulaExpression = $scope.data.existingFieldDefinition.definition.formulaExpression || '';
        $scope.data.variablesUsedInExpression = null;

        // All formulas should use formulaType of EXPRESSION. The only reason to use STRUCTURED is for INNER_TRACK_AGGREGATION.
        // Before the formula builder, additional formulaOperator were using STRUCTURED.
        if (
            $scope.data.existingFieldDefinition.definition.formulaType === 'STRUCTURED' &&
            $scope.data.existingFieldDefinition.definition.formulaOperator === 'INNER_TRACK_AGGREGATION'
        ) {
            $scope.data.formulaType = 'STRUCTURED';
            $scope.data.innerTracksAggregationDefinition = {
                definition: $scope.data.existingFieldDefinition.definition.innerTracksAggregationDefinition || {},
            };
        }
    }

    /**
     * @returns {boolean} true if it's an inner aggregation field
     */
    function isAggregation() {
        return $scope.data.formulaType === 'STRUCTURED';
    }

    /**
     * @returns {boolean} is the fields definition is valid
     */
    function isDefinitionValid() {
        if (isAggregation()) {
            return $scope.data.innerTracksAggregationDefinition?.validDefinition;
        }

        const validationResult =
            $scope.data.validationResult?.validFormulaExpression && !$scope.data.validationResult.errorMessage;
        const hasFormula = !!$scope.data.originalFormulaExpression;
        return hasFormula && validationResult;
    }

    /**
     * @returns list of fields definitions to show in the preview
     */
    function getAdditionalDefinitionsToPreview() {
        if (isAggregation()) {
            return $scope.data.innerTracksAggregationDefinition.initiativeDefinitionsToPreview || [];
        }

        if (!$scope.data?.variablesUsedInExpression?.length) {
            return [];
        }

        return $scope.data.variablesUsedInExpression
            .filter((variable) =>
                $scope.data.targetType === 'COLUMN'
                    ? variable.isColumnFieldDefinition
                    : !variable.isColumnFieldDefinition,
            )
            .map((variable) => ({
                name: variable.name,
                definitionId: variable.id,
                fieldDefinitionTargetType: $scope.data.targetType,
                validDefinition: true,
                previewDefinitionType:
                    variable.isColumnFieldDefinition && variable.isSpecialField
                        ? 'INITIATIVE_FIELD'
                        : 'EXISTING_FIELD_DEFINITION',
            }));
    }

    /**
     * @returns Get list of key metrics to show in the preview
     */
    function getAdditionalKeyMetricsToPreview() {
        if (isAggregation()) {
            return $scope.data.innerTracksAggregationDefinition.additionalDefinitionsToPreview || [];
        }

        if (!$scope.data?.variablesUsedInExpression?.length) {
            return [];
        }

        return $scope.data.variablesUsedInExpression
            .filter((variable) => !variable.isColumnFieldDefinition)
            .map((variable) => ({
                name: variable.name,
                definitionId: variable.id,
                fieldDefinitionTargetType: 'GLOBAL',
                validDefinition: true,
                previewDefinitionType: 'EXISTING_FIELD_DEFINITION',
            }));
    }

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged(isInit = false, doNotReloadPreview = false, innerTracksAggregationDefinition) {
        const validDefinition = isDefinitionValid();
        const fieldType =
            (isAggregation() && $scope.data.validationResult?.returnedFieldType) ||
            innerTracksAggregationDefinition?.fieldType ||
            'String';
        const definition = getDefinitionObject();

        // Previewing the field definitions used in the formula.
        const additionalDefinitionsToPreview = getAdditionalDefinitionsToPreview();
        const additionalKeyMetricsToPreview =
            $scope.data.targetType === 'COLUMN' ? getAdditionalKeyMetricsToPreview() : [];

        const newDefinition = {
            definition,
            fieldType,
            validDefinition,
            additionalKeyMetricsToPreview,
            additionalDefinitionsToPreview,
            formulaData: $scope.data.formulaData,
            fieldConfigurationSummaryTitle: 'Formula',
            fieldConfigurationSummarySubTitle: null,
            validatingDefinition: false,
            errorValidatingDefinition:
                $scope.data.validationResult && !$scope.data.validationResult.validFormulaExpression,
        };

        if ($scope.data.createMode || !$scope.data.existingFieldDefinition.isConfigured) {
            newDefinition.evaluatedDisplayType = fieldType;
            newDefinition.displayAs = fieldType;
        }

        ctrl.onDefinitionChange?.({
            isInit,
            newDefinition,
            expressionNode: $scope.data.expressionNode,
            doNotReloadPreview,
        });
    }

    $scope.onChange = function () {
        ctrl.onChange();
    };
}

angular.module('tonkean.app').controller('ColumnFormulaFieldCtrl', lateConstructController(ColumnFormulaFieldCtrl));
