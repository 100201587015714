import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import CreateOrEditItemInterfaceModal from './CreateOrEditItemInterfaceModal';

import { analyticsWrapper } from '@tonkean/analytics';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import {
    KebabMenuButton as RawKebabMenuButton,
    Menu,
    MenuItem,
    SavingIndicator,
    Separator,
    TextEllipsis,
    useCreateDialog,
} from '@tonkean/infrastructure';
import { EyeIcon } from '@tonkean/svg';
import { InterfaceIcon } from '@tonkean/svg';
import { EditIcon } from '@tonkean/svg';
import { DuplicateIcon } from '@tonkean/svg';
import { TrashIcon } from '@tonkean/svg';
import { BusinessAppsAnalyticsEvents, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import type { ItemInterface, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { getStateError } from '@tonkean/utils';

const ItemInterfaceBox = styled(Clickable)<{ $isActive: boolean }>`
    background: ${({ $isActive: isActive }) => (isActive ? Theme.colors.gray_300 : Theme.colors.basicBackground)};

    &:hover {
        background: ${Theme.colors.gray_300};
    }

    padding: 12px 8px;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        'title default spacer kebab'
        'subtitle subtitle subtitle kebab';
    gap: 8px;
`;

const DefaultChip = styled.span`
    background-color: ${Theme.colors.gray_300};
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    padding: 0 4px;
    border-radius: 4px;
    display: grid;
    place-content: center;
`;

const ItemInterfaceTitle = styled.span`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    grid-area: title;
`;
const ItemInterfaceSubtitle = styled.span`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    grid-area: subtitle;
`;

const MenuWrapper = styled.div`
    grid-area: kebab;
    place-self: center;
    display: flex;
    align-items: center;
    gap: 4px;
`;

interface Props {
    itemInterface: ItemInterface;
    onItemInterfaceCreated(newItemInterface: ItemInterface): void;
    onItemInterfaceUpdated(newItemInterface: ItemInterface): void;
    onItemInterfaceDeleted(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
    ): void;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowFolder: WorkflowFolder;
    workflowVersionType: WorkflowVersionType;
    exampleItemId: TonkeanId<TonkeanType.INITIATIVE>;
    setAsDefaultItemInterface(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>, isDefault: boolean): void;
    activeItemInterfaceId?: string;
}

const InterfaceListItem: React.FC<Props> = ({
    groupId,
    workflowFolder,
    itemInterface,
    onItemInterfaceCreated,
    onItemInterfaceUpdated,
    onItemInterfaceDeleted,
    workflowVersionType,
    exampleItemId,
    setAsDefaultItemInterface,
    activeItemInterfaceId,
}) => {
    const utils = useAngularService('utils');
    const $rootScope = useAngularService('$rootScope');
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showInterfaceDetailsModal, setShowInterfaceDetailsModal] = useState(false);

    const [{ loading: duplicateLoading, error: duplicateError }, duplicateInterface] =
        useLazyTonkeanService('duplicateInterface');
    const onDuplicateInterface = useCallback(() => {
        duplicateInterface(itemInterface.id).then((createdInterface) => {
            onItemInterfaceCreated(createdInterface);
        });
    }, [duplicateInterface, itemInterface.id, onItemInterfaceCreated]);

    const emit = useToastMessage();
    useEffect(() => {
        if (duplicateError) {
            const errorString = getStateError(duplicateError);
            emit(errorString);
        }
    }, [duplicateError, emit]);

    const [, deleteItemInterface] = useLazyTonkeanService('deleteItemInterface');

    const { confirm: confirmDelete } = useCreateDialog();
    const onDeleteInterface = useCallback(async () => {
        await confirmDelete(`${itemInterface.displayName}`, `Are you sure you want to delete this interface?`, {
            okLabel: 'Delete',
            warning: true,
            onOk: () => {
                return deleteItemInterface(itemInterface.id).then(() => {
                    analyticsWrapper.track(BusinessAppsAnalyticsEvents.DELETE_ITEM_INTERFACE, {
                        ItemInterfaceId: itemInterface.id,
                        ItemInterfaceGroup: itemInterface.group.id,
                    });
                    onItemInterfaceDeleted(itemInterface.workflowVersionId, itemInterface.id);
                });
            },
        });
    }, [
        confirmDelete,
        deleteItemInterface,
        itemInterface.displayName,
        itemInterface.group.id,
        itemInterface.id,
        itemInterface.workflowVersionId,
        onItemInterfaceDeleted,
    ]);

    const isActive = activeItemInterfaceId === itemInterface?.id;

    return (
        <>
            <li>
                <ItemInterfaceBox
                    data-automation={`interface-list-item-interface-box-${itemInterface.displayName}`}
                    key={itemInterface.id}
                    state="."
                    options={{ location: 'replace' }}
                    params={{ itemInterfaceId: itemInterface.id }}
                    onClick={() => {
                        $rootScope.$emit('onSidePaneClose');
                    }}
                    $isActive={isActive}
                >
                    <ItemInterfaceTitle data-automation="interface-list-item-interface-title">
                        <TextEllipsis numberOfLines={1}>{itemInterface.displayName}</TextEllipsis>
                    </ItemInterfaceTitle>
                    {itemInterface.isDefault && (
                        <DefaultChip data-automation="interface-list-item-default-indication">default</DefaultChip>
                    )}
                    <ItemInterfaceSubtitle>
                        Updated on {utils.formatDate(itemInterface.updated, true, true)}
                    </ItemInterfaceSubtitle>
                    <Menu
                        menuItems={
                            <>
                                <MenuItem
                                    disabled={workflowVersionType === WorkflowVersionType.PUBLISHED}
                                    onClick={() => setShowInterfaceDetailsModal(true)}
                                    icon={<EditIcon />}
                                    data-automation="interface-list-item-edit-button"
                                >
                                    Edit Details
                                </MenuItem>
                                <MenuItem
                                    state="product.itemInterfaceBuilderPreview"
                                    params={{
                                        initiativeId: exampleItemId,
                                        itemInterfaceId: itemInterface.id,
                                        solutionId: workflowFolder.id,
                                        workflowVersionId: itemInterface.workflowVersionId,
                                    }}
                                    icon={<EyeIcon />}
                                    data-automation="interface-list-item-preview-button"
                                    onClick={() => {
                                        analyticsWrapper.track(BusinessAppsAnalyticsEvents.PREVIEW_ITEM_INTERFACE, {
                                            initiativeId: exampleItemId,
                                            itemInterfaceId: itemInterface.id,
                                            solutionId: workflowFolder.id,
                                            workflowVersionId: itemInterface.workflowVersionId,
                                            environment: workflowVersionType,
                                        });
                                    }}
                                    openInNewTab
                                >
                                    Preview
                                </MenuItem>
                                <MenuItem
                                    disabled={workflowVersionType === WorkflowVersionType.PUBLISHED}
                                    onClick={() =>
                                        setAsDefaultItemInterface(itemInterface.id, !itemInterface.isDefault)
                                    }
                                    icon={<InterfaceIcon />}
                                    data-automation="interface-list-item-set-or-remove-default-button"
                                >
                                    {itemInterface.isDefault ? 'Remove as default' : 'Set as default'}
                                </MenuItem>
                                <MenuItem
                                    disabled={workflowVersionType === WorkflowVersionType.PUBLISHED}
                                    onClick={onDuplicateInterface}
                                    icon={<DuplicateIcon />}
                                    data-automation="interface-list-item-duplicate-button"
                                >
                                    Duplicate
                                </MenuItem>
                                <MenuItem
                                    disabled={workflowVersionType === WorkflowVersionType.PUBLISHED}
                                    onClick={onDeleteInterface}
                                    icon={<TrashIcon />}
                                    data-automation="interface-list-item-delete-button"
                                >
                                    Delete
                                </MenuItem>
                            </>
                        }
                        show={menuIsOpen}
                        onClose={() => setMenuIsOpen(false)}
                    >
                        <MenuWrapper>
                            <SavingIndicator loading={duplicateLoading} error={duplicateError} />
                            <RawKebabMenuButton
                                dataAutomation="interface-list-item-kebab-menu-button"
                                aria-label="Open interface menu"
                                onClick={(e) => {
                                    setMenuIsOpen(true);
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                flat
                            />
                        </MenuWrapper>
                    </Menu>
                </ItemInterfaceBox>
            </li>
            <Separator />

            {workflowFolder && (
                <CreateOrEditItemInterfaceModal
                    open={showInterfaceDetailsModal}
                    onClose={(createdItemInterface) => {
                        if (createdItemInterface) {
                            onItemInterfaceUpdated(createdItemInterface);
                        }
                        setShowInterfaceDetailsModal(false);
                    }}
                    workflowFolder={workflowFolder}
                    existingItemInterface={itemInterface}
                    groupId={groupId}
                />
            )}
        </>
    );
};

export default InterfaceListItem;
