import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const SolutionMapperPage = React.lazy(() => import('../../modules/SolutionMapperModule/SolutionMapperPage'));

interface Props {}

function fallbackRender({ error }) {
    return <SimpleErrorStateMessage error={error} />;
}

const SolutionMapperState: React.FC<Props> = () => {
    const [solutionMapperId] = useGetStateParams<[TonkeanId<TonkeanType.SOLUTION_MAPPER>]>('solutionMapperId');
    const { reader } = useTonkeanService('getOperationsGraph', solutionMapperId);

    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            <React.Suspense
                fallback={
                    <div className="common-height-full flex mod-align-center mod-center mod-justify-center">
                        <i className="loading-large" />
                    </div>
                }
            >
                <SolutionMapperPage reader={reader} />
            </React.Suspense>
        </ErrorBoundary>
    );
};

export default SolutionMapperState;
