import React from 'react';

import ItemInterfaceModal from '../../../components/ItemInterfaceModal';
import SystemInterfaceModal from '../../../SystemInterface/components/SystemInterface/SystemInterfaceModal';

import type { ModalProps } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props extends ModalProps {
    open: boolean;
    onClose: () => void;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    itemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined;
    projectId?: TonkeanId<TonkeanType.PROJECT>;
    originWidgetId?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
}

const MatchedItemInterfaceModal: React.FC<Props> = ({
    open,
    onClose,
    initiativeId,
    itemInterfaceId,
    projectId,
    originWidgetId,
}) => {
    return (
        <>
            {itemInterfaceId && projectId ? (
                <ItemInterfaceModal
                    initiativeId={initiativeId}
                    itemInterfaceId={itemInterfaceId}
                    projectId={projectId}
                    onClose={onClose}
                    open={open}
                    data-automation="specific_item_interface_modal"
                />
            ) : (
                <SystemInterfaceModal
                    initiativeId={initiativeId}
                    onClose={onClose}
                    open={open}
                    isBackEnabled={false}
                    originWidgetId={originWidgetId}
                    data-automation="default_item_interface_modal"
                    dontUseGroupIdStateParam
                />
            )}
        </>
    );
};
export default MatchedItemInterfaceModal;
