import type { CreateInterfaceRequest } from './entities/CreateInterfaceRequest';
import type { GetEmbedInterfaceByWidgetIdResponse } from './entities/GetEmbedInterfaceByWidgetIdResponse';
import type NetworkService from '../NetworkService';

import type { ItemInterface } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const URLS = {
    createInterface: (
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ) => `${projectId}/${workflowFolderId}/itemInterface`,
    getEmbedInterfaceByWidgetId: (
        projectId: TonkeanId<TonkeanType.PROJECT>,
        groupId: TonkeanId<TonkeanType.GROUP>,
        contractFieldId: TonkeanId<TonkeanType.CONTRACT_FIELD>,
        workflowVersionType: WorkflowVersionType,
    ) =>
        `${projectId}/interface?groupId=${groupId}&contractFieldId=${contractFieldId}&workflowVersionType=${workflowVersionType}`,
};

async function createInterface(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    groupId: TonkeanId<TonkeanType.GROUP>,
    displayName: string,
    businessGroupsIds: string[],
): Promise<ItemInterface> {
    const body = {
        displayName,
        groupId,
        businessGroupsIds,
    };

    return networkService.postRequest<ItemInterface, CreateInterfaceRequest>({
        url: URLS.createInterface(projectId, workflowFolderId),
        body,
    });
}

async function getEmbedInterfaceByWidgetId(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    groupId: TonkeanId<TonkeanType.GROUP>,
    contractFieldId: TonkeanId<TonkeanType.CONTRACT_FIELD>,
    workflowVersionType: WorkflowVersionType,
): Promise<GetEmbedInterfaceByWidgetIdResponse> {
    return networkService.getRequest<GetEmbedInterfaceByWidgetIdResponse>({
        url: URLS.getEmbedInterfaceByWidgetId(projectId, groupId, contractFieldId, workflowVersionType),
    });
}

export { createInterface, getEmbedInterfaceByWidgetId };
