import { analyticsWrapper } from '@tonkean/analytics';

function SetupGroupPreviewModalCtrl(
    $rootScope,
    $scope,
    $uibModalInstance,
    $timeout,
    utils,
    selectedList,
    requestedList,
    builtInLists,
) {
    // initialization
    $scope.data = {
        selectedList,
        builtInLists: utils.objToArray(builtInLists),
    };
    $scope.isMobile = $rootScope.isMobile;
    $scope.frameDuration = 5000;
    $scope.frames = null;

    // state describing on which list we are on
    $scope.listState = {
        currentList: null,
        currentListIndex: 0,
    };

    // state describing the current story
    $scope.state = {};

    // helper method to move to the next list
    $scope.moveToNextList = function () {
        moveCurrentListTo($scope.listState.currentListIndex + 1);
    };

    // helper method to move to the previous list
    $scope.moveToPreviousList = function () {
        moveCurrentListTo($scope.listState.currentListIndex - 1);
    };

    /**
     * Closes the modal and returns the list to be created
     */
    $scope.createCurrentList = function () {
        analyticsWrapper.track('Create group', {
            category: 'Built-in List Modal',
            label: $scope.listState.currentList.id,
        });
        window.Intercom('trackEvent', 'Chose Builtin List');

        $uibModalInstance.close($scope.listState.currentList);
    };

    // helper method to start story at the beginning
    $scope.startStory = function () {
        $scope.startStoryAt(0);
    };

    // helper method to start the next frame
    $scope.startNextStory = function () {
        $scope.startStoryAt($scope.state.currentFrameIndex + 1);
    };

    /**
     * main method to move story state
     * @param index
     */
    $scope.startStoryAt = function (index) {
        // in case we 'jumped' to the same frame while paused (can occur on click on the frame indicator)
        if ($scope.state.storyPaused) {
            $scope.resume();
        }

        // cleanup
        $scope.state.storyStarted = true;
        cleanPaused();
        cancelNextAction();

        // reset css state for all indicators
        $scope.state.resetting = true;
        $timeout(function () {
            // after reset
            $scope.state.resetting = false;
            moveToFrame(index);
            setNextAction($scope.frameDuration);
        }, 10);
    };

    $scope.pause = function () {
        $scope.state.storyPaused = true;
        $timeout.cancel($scope.state.nextActionPromise);

        // get the 'percentage' of progress through the story
        $scope.state.pausedRawElement = document.querySelectorAll('.active')[0];
        const element = angular.element($scope.state.pausedRawElement);
        const style = window.getComputedStyle($scope.state.pausedRawElement);
        const originalValue = style.backgroundPosition;

        // remove the active class so there is no transition anymore
        element.removeClass('active');
        // cache the original background position
        $scope.state.pausedRawElement.style.cssText = `background-position: ${originalValue};`;
    };

    $scope.resume = function () {
        // get remaining transition duration
        const oldBackgroundPositionValue = Math.abs(
            $scope.state.pausedRawElement.style.backgroundPositionX.replace('%', ''),
        );
        const goneThrough = (oldBackgroundPositionValue * $scope.frameDuration) / 100;
        const remaining = $scope.frameDuration - goneThrough;
        const duration = `${remaining}ms`;

        // resume transition from position
        $scope.state.pausedRawElement.style.cssText = '';
        $scope.state.pausedRawElement.style.transitionDuration = duration;
        angular.element($scope.state.pausedRawElement).addClass('active');

        $scope.state.storyPaused = false;
        setNextAction(remaining);
    };

    $scope.pauseResume = function () {
        if ($scope.state.storyPaused) {
            $scope.resume();
        } else {
            $scope.pause();
        }
    };

    $scope.frameOnClick = function (index) {
        analyticsWrapper.track('Jumped to frame', {
            category: 'Story controls',
            label: `${$scope.listState.currentList.id} frame index: ${index}`,
        });

        $scope.startStoryAt(index);
    };

    function moveToFrame(index) {
        $scope.state.currentFrameIndex = index;
        $scope.state.currentFrame = $scope.frames[$scope.state.currentFrameIndex];
    }

    /**
     * figure out what the next action that will run in 'timeout' ms
     * @param timeout
     */
    function setNextAction(timeout) {
        let nextAction;
        // the next action can be either stop or move to next story
        if ($scope.state.currentFrameIndex === $scope.frames.length - 1) {
            nextAction = function () {
                $scope.state.storyStarted = false;
            };
        } else {
            nextAction = $scope.startNextStory;
        }

        $scope.state.nextActionPromise = $timeout(nextAction, timeout);
    }

    function cleanPaused() {
        if ($scope.state.pausedRawElement) {
            // cant just set style to empty object becaues safari doesnt support it
            const style = $scope.state.pausedRawElement.style;
            style.transitionDuration = '';
            style.cssText = '';

            $scope.state.pausedRawElement = null;
        }
        $scope.state.storyPaused = false;
    }

    function cancelNextAction() {
        if ($scope.state && $scope.state.nextActionPromise) {
            $timeout.cancel($scope.state.nextActionPromise);
        }
    }

    function getListIndexById(id) {
        return utils.indexOf($scope.data.builtInLists, function (item) {
            return id === item.key;
        });
    }

    function moveCurrentListTo(index) {
        $scope.listState.currentListIndex = index;
        $scope.listState.currentList = $scope.data.builtInLists[index].value;
        $scope.frames = $scope.listState.currentList.frames;
        cancelNextAction();
        resetState();
        $scope.startStory();

        analyticsWrapper.track('Move to preview group', {
            category: 'Built-in List Modal',
            label: $scope.listState.currentList.id,
        });
    }

    function resetState() {
        $scope.state = {
            currentFrame: $scope.frames[0],
            currentFrameIndex: -1,
            nextActionPromise: null,
            pausedRawElement: null,
            storyStarted: false,
            storyPaused: false,
            resetting: false,
        };
    }

    moveCurrentListTo(getListIndexById(requestedList.id));
}

export default angular.module('tonkean.app').controller('SetupGroupPreviewModalCtrl', SetupGroupPreviewModalCtrl);
