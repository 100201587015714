import { useCallback, useEffect, useState } from 'react';

import type SearchWidgetConfiguration from '../SearchWidgetConfiguration';

import { useLazyTonkeanService, useToastMessage } from '@tonkean/angular-hooks';
import type {
    SearchWidgetResponseItem,
    SearchWidgetUpdateSelectedResponse,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

export type UseSearchWidgetSelectedItemsResult = {
    selectedItemsLoading: boolean;
    updatedSelectedItemError: ErrorResponseType | undefined;
    searchItemIdToInitiativeId: Record<string, TonkeanId<TonkeanType.INITIATIVE>> | undefined;
    selectedItemsError: ErrorResponseType | undefined;
    selectedItemsMap: Record<string, boolean>;
    selectedItems: SearchWidgetResponseItem[];
    changeItemSelectedState: (searchItem: SearchWidgetResponseItem) => Promise<void>;
};

const useSearchWidgetSelectedItems = (
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    configuration: SearchWidgetConfiguration,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    workflowVersionType: WorkflowVersionType,
): UseSearchWidgetSelectedItemsResult => {
    const [{ error: updatedSelectedItemError }, updateSearchWidgetSelectedItem] = useLazyTonkeanService(
        'updateSearchWidgetSelectedItem',
    );

    const [
        { data: selectedItemsData, loading: selectedItemsLoading, error: selectedItemsError },
        getSearchWidgetSelectedItems,
    ] = useLazyTonkeanService('getSearchWidgetSelectedItems');

    const updateSelectedItems = useCallback((selectedItemsResults: SearchWidgetUpdateSelectedResponse | undefined) => {
        const newSelectedItems = selectedItemsResults?.searchItems || [];
        setSelectedItems(newSelectedItems);

        const entries = newSelectedItems.map((item) => [item.id, true]);
        setSelectedItemsMap(Object.fromEntries(entries));

        setSearchItemIdToInitiativeId(selectedItemsResults?.searchItemIdToInitiativeId);
    }, []);

    useEffect(() => {
        updateSelectedItems(selectedItemsData);
    }, [selectedItemsData, updateSelectedItems]);

    const [validationError, setValidationError] = useState<string | undefined>();

    useEffect(() => {
        let newValidationError: string | undefined = undefined;
        if (!configuration.searchEntityProjectIntegrationId) {
            newValidationError = 'Results source not configured';
        } else if (!configuration.searchEntityApiName) {
            newValidationError = 'Search entity not configured';
        } else if (!configuration.resultFieldDefinitionId) {
            newValidationError = 'Field for storing selected results not configured';
        }
        setValidationError(newValidationError);
        if (newValidationError) {
            return;
        }

        if (initiativeId) {
            getSearchWidgetSelectedItems(workflowVersionType, initiativeId, widgetId);
        }
    }, [
        getSearchWidgetSelectedItems,
        initiativeId,
        workflowVersionType,
        configuration.resultFieldDefinitionId,
        configuration.searchEntityApiName,
        configuration.searchEntityProjectIntegrationId,
        widgetId,
    ]);

    const [selectedItems, setSelectedItems] = useState<SearchWidgetResponseItem[]>([]);
    const [selectedItemsMap, setSelectedItemsMap] = useState<Record<string, boolean>>({});
    const [searchItemIdToInitiativeId, setSearchItemIdToInitiativeId] = useState<
        Record<string, TonkeanId<TonkeanType.INITIATIVE>> | undefined
    >();

    const emit = useToastMessage();
    const changeItemSelectedState = useCallback(
        async (searchItem: SearchWidgetResponseItem) => {
            const fieldDefinitionId = configuration.resultFieldDefinitionId;
            if (!initiativeId || !fieldDefinitionId) {
                emit('No result field configured or item not initialized.', 'danger');
                return Promise.resolve();
            }

            const results = await updateSearchWidgetSelectedItem(
                workflowVersionType,
                initiativeId,
                widgetId,
                searchItem.id,
            );
            updateSelectedItems(results);
        },
        [
            emit,
            initiativeId,
            workflowVersionType,
            updateSearchWidgetSelectedItem,
            updateSelectedItems,
            configuration.resultFieldDefinitionId,
            widgetId,
        ],
    );

    return {
        selectedItems,
        selectedItemsMap,
        searchItemIdToInitiativeId,
        changeItemSelectedState,
        selectedItemsLoading,
        selectedItemsError: validationError || selectedItemsError,
        updatedSelectedItemError,
    };
};

export default useSearchWidgetSelectedItems;
