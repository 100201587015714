import template from './tnkWorkflowVersionsListFilter.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkflowVersionsListFilter', {
    bindings: {
        filterType: '<',
        from: '<',
        to: '<',
        publisher: '<',
        sequentialIdentifier: '<',
        onSaveFilter: '<',
        appendFilterDialogToBody: '<',
        tooltipDirection: '<',
        shouldLoadCommitActivities: '<',
        workflowVersionId: '<',
    },
    template,
    controller: 'workflowVersionsListFilterCtrl',
});
