import styled, { css } from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

export default styled.div<{ $focused: boolean }>`
    display: flex;
    align-items: center;
    color: ${Theme.colors.gray_700};
    width: 300px;
    height: 30px;
    padding: 20px 8px;
    font-size: ${FontSize.SMALL_12};

    .avatar-img {
        width: 32px;
        height: 32px;
    }

    ${({ $focused }) =>
        $focused &&
        css`
            background-color: ${Theme.colors.gray_200};
            border-radius: 8px;
            padding: 20px 8px;
        `};

    &:hover {
        background-color: ${Theme.colors.gray_200};
        border-radius: 8px;
        padding: 20px 8px;
    }
`;
