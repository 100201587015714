import utils from '@tonkean/utils';

/**
 * This validates the send item interfaced logic has selected an item interface and adds an appropriate message.
 */
const customTriggerOfTypeIntakeMustBeSelectedValidator = (validationObject, definition) => {
    if (utils.isNullOrUndefined(definition.goToSequenceCustomTriggerId)) {
        validationObject.noItemInterfaceSelected = 'You must select a custom trigger of type Interface submitted';
    }
};

export default customTriggerOfTypeIntakeMustBeSelectedValidator;
