import IroncladAuthenticationComponent from './IroncladAuthenticationComponent';
import IroncladSetupComponent from './IroncladSetupComponent';
import ironcladCircleIcon from '../../../../apps/tracks/images/integrations/ironclad-circle.png';
import ironcladIcon from '../../../../apps/tracks/images/ironclad.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class IroncladIntegration extends IntegrationDefinitionBase {
    override name = 'ironclad';
    override displayName = 'Ironclad';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Attachment', 'Attachments'),
        new IntegrationEntity('Comment', 'Comments'),
        new IntegrationEntity('Record', 'Records'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Workflow', 'Workflows'),
    ];
    override customizedAuthenticationComponent = IroncladAuthenticationComponent;
    override customizedSetupComponent = IroncladSetupComponent;
    override storageProviderSettings = new StorageProviderSettings(true, false);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(ironcladCircleIcon, '130px'),
        new IntegrationIcon(ironcladIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default IroncladIntegration;
