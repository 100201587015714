import { lateConstructController } from '@tonkean/angular-components';
import { WORKER_TYPES } from '@tonkean/constants';

/* @ngInject */
function WorkerTileCtrl(
    $scope,
    $state,
    workflowVersionManager,
    projectManager,
    modalUtils,
    modal,
    utils,
    workflowFolderManager,
    groupInfoManager,
    tonkeanService,
    $timeout,
) {
    const ctrl = this;
    $scope.wvm = workflowVersionManager;
    $scope.wfm = workflowFolderManager;
    $scope.utils = utils;
    $scope.pm = projectManager;
    $scope.data = {
        worker: ctrl.worker,
        workerTypeInfo: null,
        workerTypes: WORKER_TYPES,
        indexInGallery: ctrl.indexInGallery,
        openDropdown: false,
        viewOnly: ctrl.viewOnly,
        folderId: ctrl.folderId,
        highlightTerm: ctrl.highlightTerm,
        migrating: false,
        title: '',
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.workerTypeInfo = $scope.data.worker.isScheduled
            ? $scope.data.workerTypes.SCHEDULED
            : $scope.data.workerTypes[$scope.data.worker.workerType];
    };

    ctrl.$onChanges = function (changes) {
        if (changes.highlightTerm && changes.highlightTerm.currentValue !== $scope.data.highlightTerm) {
            $scope.data.highlightTerm = ctrl.highlightTerm;
        }
    };

    $scope.changeGroupsWorkflowFolder = function (event) {
        event.stopPropagation();
        if (!$scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id][$scope.data.folderId].isUserOwner) {
            return;
        }

        modalUtils.changeGroupsWorkflowFolderModal($scope.data.worker.id, $scope.data.folderId).then(() => {
            ctrl.onWorkerMoved?.();
        });
    };

    $scope.onCreateTemplateClick = (event) => {
        event.stopPropagation();
        if (!$scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id][$scope.data.folderId].isUserOwner) {
            return;
        }

        $scope.data.createModuleTemplateOpen = true;
    };

    $scope.moduleTemplateModalOnClose = () => {
        $timeout(() => {
            $scope.data.createModuleTemplateOpen = false;
        });
    };

    $scope.migrateToMakers = function (event) {
        event.stopPropagation();

        if (!$scope.data.worker || !$scope.data.worker.notVisibleToMakers || $scope.data.migrating) {
            return;
        }

        const makers =
            workflowFolderManager.projectIdToFolderIdToMakersMap[$scope.data.worker.project.id][$scope.data.folderId] ||
            [];
        const currentMembers = $scope.data.worker.members || [];

        const newMemberListNames = [...new Set([...makers, ...currentMembers].map((person) => person.name))];
        const newMemberListNamesString = utils.joinNames(newMemberListNames);

        $scope.mboxData = {
            title: 'convert private module admins to solution makers',
            html: `By converting the private module, the following will become members: <b>${newMemberListNamesString}</b>. Are you sure you want to continue?`,
            isWarn: true,
            okLabel: 'Yes',
            cancelLabel: 'Cancel',
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                $scope.data.migrating = true;
                tonkeanService
                    .migrateToMakers($scope.data.worker.id)
                    .then(() => {
                        return groupInfoManager.getGroupById($scope.data.worker.id, true);
                    })
                    .then(() => {
                        $scope.$emit('alert', {
                            msg: `${$scope.data.worker.name} module converted successfully!`,
                            type: 'success',
                        });
                    })
                    .catch(() => {
                        $scope.$emit('alert', {
                            msg: `An error occurred while trying to convert the ${$scope.data.worker.name} module.`,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        $scope.data.migrating = false;
                    });
            });
    };

    $scope.toggleDropdown = function (event) {
        if ($scope.data.viewOnly) {
            return;
        }
        event.stopPropagation();
        $scope.data.openDropdown = !$scope.data.openDropdown;
    };

    $scope.openWorker = function (id) {
        if ($scope.data.viewOnly) {
            return;
        }
        $state.go('product.workerEditor', { g: id });
    };

    $scope.getMoreTooltip = function (worker) {
        let tooltip = '';
        const limit = $scope.avatarsLimit(worker);
        for (let i = limit; i < worker.originalGroupProjectIntegrations.length; i++) {
            tooltip += worker.originalGroupProjectIntegrations[i].displayName;
            if (i < worker.originalGroupProjectIntegrations.length - 1) {
                tooltip += ', ';
            }
        }
        return tooltip;
    };
}

export default angular.module('tonkean.app').controller('WorkersTileCtrl', lateConstructController(WorkerTileCtrl));
