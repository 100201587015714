import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import type { StylesConfig } from 'react-select';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { SimpleSelect, useDebouncedState } from '@tonkean/infrastructure';
import type { TrainingSetIdentifiersSummary } from '@tonkean/tonkean-entities';
import type { TrainingSetType } from '@tonkean/tonkean-entities';

interface Props {
    preselectedItem?: string;
    itemIdsToExclude?: string[];
    overrideZIndex?: boolean;
    workflowFolderId: string | undefined;
    trainingSetType?: TrainingSetType;

    onItemSelected: (selectedItemId: string | undefined, selectedItemLabel: string | undefined) => void;
}

const TrainingSetSelector: React.FC<Props> = ({
    preselectedItem,
    onItemSelected,
    itemIdsToExclude,
    overrideZIndex,
    workflowFolderId,
    trainingSetType,
}) => {
    const [selectedItem, setSelectedItem] = useState<string | undefined>(preselectedItem);
    const [filteredOptions, setFilteredOptions] = useState<{ value: string; label: string }[]>([]);

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);

    const [entities, setEntities] = useState<TrainingSetIdentifiersSummary[]>([]);
    const projectManager = useAngularService('projectManager');

    const [{ data, error, loading }, searchTrainingSetsIdentifiers] = useLazyTonkeanService(
        'searchTrainingSetsIdentifiers',
    );

    useEffect(() => {
        searchTrainingSetsIdentifiers(
            projectManager.project.id,
            debouncedSearchTerm,
            workflowFolderId,
            trainingSetType,
            0,
            50,
        );
    }, [
        debouncedSearchTerm,
        projectManager.project.id,
        searchTrainingSetsIdentifiers,
        trainingSetType,
        workflowFolderId,
    ]);

    useEffect(() => {
        if (data?.entities?.length && !loading && !error) {
            setEntities(data?.entities || []);
        }
    }, [data, loading, error]);

    const [
        { data: fetchedPreselectedItem, error: errorFetchingPreselectedItem, loading: loadingPreselectedItem },
        getEntitySummaryById,
    ] = useLazyTonkeanService('getTrainingSetIdentifiersSummaryById');

    /**
     * Fetching pre selected item upon initialisation of component.
     */
    useEffect(() => {
        if (preselectedItem) {
            getEntitySummaryById(preselectedItem);
        }
    }, [preselectedItem, getEntitySummaryById]);

    /**
     * Once pre selected item has been fetched, we add it to the filtered options if they do not already contain it.
     * The reason we're using the setState(() => ...) lambda flavour is to not have to include the filteredOptions
     * in the dependency array, causing us to set selected item each time we're filtering the options.
     */
    useEffect(() => {
        if (fetchedPreselectedItem && !errorFetchingPreselectedItem && !loadingPreselectedItem) {
            setSelectedItem(fetchedPreselectedItem.id);

            setFilteredOptions((currentOptions) => [
                ...(!currentOptions.find((option) => option.value === fetchedPreselectedItem.id)
                    ? [
                          {
                              value: fetchedPreselectedItem.id,
                              label: fetchedPreselectedItem.displayName,
                          },
                      ]
                    : []),
                ...currentOptions,
            ]);
        }
    }, [fetchedPreselectedItem, errorFetchingPreselectedItem, loadingPreselectedItem]);

    /**
     * Once we have fetched entities, we set the filtered options in the API SimpleSelect expects to receive them.
     */
    useEffect(() => {
        if (entities?.length && !loading && !error) {
            setFilteredOptions(
                entities
                    .filter((entity) => !itemIdsToExclude?.includes(entity.id))
                    .map((entity) => {
                        return {
                            value: entity.id,
                            label: entity.displayName,
                        };
                    }),
            );
        }
    }, [entities, loading, error, itemIdsToExclude]);

    const styles: StylesConfig<{ value: string; label: string }> = overrideZIndex
        ? {
              // Fixes the overlapping problem of the component
              menuPortal: (provided) => ({ ...provided, zIndex: 1050 }),
          }
        : {};

    return (
        <SimpleSelect
            placeholder="Search item..."
            value={selectedItem}
            isLoading={loading || loadingPreselectedItem}
            inputValue={notDebouncedSearchTerm}
            onInputChange={setNotDebouncedSearchTerm}
            options={filteredOptions}
            onChange={(selectedValue) => {
                setSelectedItem(selectedValue);

                const foundTrainingSetLabel = filteredOptions.find((option) => option.value === selectedValue)?.label;
                onItemSelected(selectedValue, foundTrainingSetLabel);
            }}
            menuPosition="fixed"
            styles={styles}
            thin
        />
    );
};

export default TrainingSetSelector;
