<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Comment on file -->
        <div class="delay-configuration-logic" ng-if="data.definition.customActionKey === 'COMMENT_ON_FILE'">
            <!-- Title -->
            <div class="margin-bottom-xs">Text to comment:</div>

            <div ng-if="data.validationObject.noContent" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noContent }}
            </div>

            <!-- Input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Type a custom message to comment"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.content"
                saved-evaluated-expression="data.definition.evaluatedContent"
                expression-unique-identifier="GDText"
                on-tonkean-expression-changed="
                    onContentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Share File -->
        <div
            ng-if="
                data.definition.customActionKey === 'SHARE_FILE' || data.definition.customActionKey === 'SHARE_FOLDER'
            "
        >
            <!-- Email -->
            <div>
                <div class="margin-bottom-xs">Email Address to share with:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Email address to share with"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.email"
                    saved-evaluated-expression="data.definition.evaluatedEmail"
                    expression-unique-identifier="GDShareEmail"
                    on-tonkean-expression-changed="
                        onEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>

                <div ng-if="data.validationObject.noEmail" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noEmail }}
                </div>
            </div>

            <!-- Select Role -->
            <div class="flex-vmiddle margin-top-lg">
                <div>Role:</div>

                <div uib-dropdown class="margin-left">
                    <button class="btn btn-default specific-people-custom-options-btn" uib-dropdown-toggle>
                        <span class="">
                            <span class="margin-right-xxs">
                                {{ data.selectedRole ? data.selectedRole.label : 'Choose Role' }}
                            </span>
                            <span class="dropdown-chevron mod-static"></span>
                        </span>
                    </button>
                    <ul uib-dropdown-menu>
                        <li ng-repeat="role in data.roleOptions" ng-click="onRoleSelected(role)">
                            <a>{{ role.label }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div ng-if="data.validationObject.noRole" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noRole }}
            </div>

            <!-- Google Send Email? -->
            <label
                class="margin-top-lg margin-bottom"
                uib-tooltip="If checked, Google Drive would send a notification to the person that a folder\\file has been shared with them"
            >
                <input
                    type="checkbox"
                    ng-model="data.definition.sendNotificationEmail"
                    ng-change="onDefinitionChanged(true)"
                />
                <span class="common-normal-weight">Send E-Mail notification?</span>
            </label>

            <!-- Share message -->
            <div ng-if="data.definition.sendNotificationEmail">
                <div class="margin-bottom-xs flex-vmiddle">
                    <!-- Title -->
                    <span class="margin-right">Attached email message:</span>

                    <!-- Info -->
                    <div
                        uib-popover-template="'./attachedEmailMessageToShareExplanation.template.html'"
                        popover-placement="bottom-middle"
                        popover-class="common-width-60"
                        popover-trigger="mouseenter"
                    >
                        <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
                    </div>
                </div>

                <!-- Share message expression -->
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Email address to share with"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.attachedEmailMessage"
                    saved-evaluated-expression="data.definition.evaluatedAttachedEmailMessage"
                    on-tonkean-expression-changed="
                        onAttachedEmailMessageExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Copy File -->
        <div
            ng-if="
                data.definition.customActionKey === 'COPY_FILE' ||
                data.definition.customActionKey === 'CREATE_DOC_FROM_TEMPLATE'
            "
        >
            <!-- Destination Folder -->
            <div>
                <div class="margin-bottom-xs">Destination Folder (ID):</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The folder to copy into..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.destinationFolderId"
                    saved-evaluated-expression="data.definition.evaluatedDestinationFolderId"
                    expression-unique-identifier="GDFolderDestination"
                    on-tonkean-expression-changed="
                        onDestinationFolderIdTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- New Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">New Doc/Slide Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The new doc/slide name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.pastedFileName"
                    saved-evaluated-expression="data.definition.evaluatedPastedFileName"
                    expression-unique-identifier="GDFolderName"
                    on-tonkean-expression-changed="
                        onPastedFileNameTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Generate PDF File -->
        <div ng-if="data.definition.customActionKey === 'GENERATE_PDF'">
            <!-- Destination Folder -->
            <div>
                <div class="margin-bottom-xs">Destination Folder (ID):</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The PDF parent folder..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.destinationFolderId"
                    saved-evaluated-expression="data.definition.evaluatedDestinationFolderId"
                    expression-unique-identifier="GDFolderDestination"
                    on-tonkean-expression-changed="
                        onDestinationFolderIdTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
                <div
                    ng-if="data.validationObject.noDestinationFolderId"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noDestinationFolderId }}
                </div>
            </div>

            <!-- Generated PDF file name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Generate PDF file Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The new PDF name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.pdfFileName"
                    saved-evaluated-expression="data.definition.evaluatedPdfFileName"
                    expression-unique-identifier="GDFolderName"
                    on-tonkean-expression-changed="
                        onPdfFileNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Move File -->
        <div ng-if="data.definition.customActionKey === 'MOVE_FILE'">
            <div>
                <!-- Destination Folder -->
                <div>
                    <div class="margin-bottom-xs">
                        Destination Folder (ID):
                        <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="If the file has more than one parent folder, it will be removed from all of them, and then move to destination folder."
                        ></i>
                    </div>

                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="The folder to copy into..."
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-original-expression="data.definition.destinationFolderId"
                        saved-evaluated-expression="data.definition.evaluatedDestinationFolderId"
                        expression-unique-identifier="GDFolderDestination"
                        on-tonkean-expression-changed="
                            onDestinationFolderIdTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic
                            )
                        "
                    ></tnk-tonkean-expression>
                </div>

                <!-- New Name -->
                <div class="margin-top">
                    <div class="margin-bottom-xs">New Doc/Slide Name:</div>

                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="The new doc/slide name..."
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-original-expression="data.definition.pastedFileName"
                        saved-evaluated-expression="data.definition.evaluatedPastedFileName"
                        expression-unique-identifier="GDFolderName"
                        on-tonkean-expression-changed="
                            onPastedFileNameTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic
                            )
                        "
                    ></tnk-tonkean-expression>
                </div>
            </div>
        </div>

        <!-- Rename File -->
        <div ng-if="data.definition.customActionKey === 'RENAME_FILE' || data.definition.customActionKey === 'RENAME_FOLDER'">
            <!-- Rename File Title -->
            <div class="margin-right margin-bottom-xs">New Name:</div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="The new name..."
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.newFileName"
                saved-evaluated-expression="data.definition.evaluatedNewFileName"
                expression-unique-identifier="GDNewFileName"
                on-tonkean-expression-changed="
                    onNewFileNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Copy Folder -->
        <div ng-if="data.definition.customActionKey === 'COPY_FOLDER'">
            <!-- Source Folder -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Source Folder (ID):</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The folder to copy from..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.sourceFolderId"
                    saved-evaluated-expression="data.definition.evaluatedSourceFolderId"
                    on-tonkean-expression-changed="
                        onSourceFolderIdTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- Destination Folder -->
            <div>
                <div class="margin-bottom common-color-grey">This action can copy up to 50 files</div>
                <div class="margin-bottom-xs">Destination Folder (ID):</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The folder to copy into..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.destinationFolderId"
                    saved-evaluated-expression="data.definition.evaluatedDestinationFolderId"
                    on-tonkean-expression-changed="
                        onDestinationFolderIdTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Move Folder -->
        <div ng-if="data.definition.customActionKey === 'MOVE_FOLDER'">
            <!-- Destination Folder -->
            <div class="flex-vmiddle margin-bottom-xs">
                <!-- Title -->
                <span class="margin-right">Destination Folder (ID):</span>
            </div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="The folder to move into..."
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.destinationFolderId"
                saved-evaluated-expression="data.definition.evaluatedDestinationFolderId"
                expression-unique-identifier="GDFolderDestination"
                on-tonkean-expression-changed="
                    onDestinationFolderIdTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic
                    )
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Create Sheet -->
        <div ng-if="data.definition.customActionKey === 'CREATE_SHEET'">
            <!-- Spreadsheet Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Spreadsheet Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The Sheet Name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.spreadsheetName"
                    saved-evaluated-expression="data.definition.evaluatedSpreadsheetName"
                    expression-unique-identifier="SpradsheetName"
                    on-tonkean-expression-changed="
                        onSpreadsheetNameTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- Sheet Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Sheet Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The Sheet Name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.sheetName"
                    saved-evaluated-expression="data.definition.evaluatedSheetName"
                    expression-unique-identifier="SheetName"
                    on-tonkean-expression-changed="
                        onSheetNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- Parent Folder -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Parent Folder Id:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The Parent Folder Id..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.parentFolderId"
                    saved-evaluated-expression="data.definition.evaluatedParentFolderId"
                    expression-unique-identifier="ParentFolderId"
                    on-tonkean-expression-changed="
                        onParentFolderIdTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>

            <hr />

            <!-- Define rows title -->
            <div class="margin-bottom-xs">
                <div class="common-title-xxs">Rows</div>
                <span class="common-size-xxs common-color-grey3">Define the new spreadsheet Rows</span>
            </div>

            <!-- Rows -->
            <div class="margin-bottom">Inner Items</div>

            <hr />

            <!-- Define columns title -->
            <div class="margin-bottom-xs">
                <div class="common-title-xxs">Columns Template</div>
                <span class="common-size-xxs common-color-grey3">
                    Define the new spreadsheet columns template, the value will be evaluated for each row.
                </span>
            </div>

            <!-- Columns -->
            <div class="margin-bottom">
                <div class="margin-bottom" ng-repeat="columnObject in data.columnsObjects">
                    <div>
                        <div class="flex-vmiddle margin-bottom">
                            <div class="common-valign-middle">Column ({{ data.alphabet[$index] }})</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeColumnObject($index)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>

                        <div>Name:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            expression-unique-identifier="{{ $index }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-original-expression="columnObject.nameText"
                            saved-evaluated-expression="columnObject.evaluatedNameText"
                            on-tonkean-expression-changed="
                                onNameTonkeanExpressionChangedForColumns(
                                    originalExpression,
                                    evaluatedExpression,
                                    expressionIdentifier,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <div>
                        <div>Value:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            expression-unique-identifier="{{ $index }}"
                            saved-original-expression="columnObject.valueText"
                            saved-evaluated-expression="columnObject.evaluatedValueText"
                            on-tonkean-expression-changed="
                                onValueTonkeanExpressionChangedForColumns(
                                    originalExpression,
                                    evaluatedExpression,
                                    expressionIdentifier,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>

                <a ng-click="addColumnObject()">+ Add</a>

                <div ng-if="data.validationObject.noColumns" class="common-color-danger">
                    {{ data.validationObject.noColumns }}
                </div>
            </div>
        </div>

        <!-- Create Doc From Template -->
        <div ng-if="data.definition.customActionKey === 'CREATE_DOC_FROM_TEMPLATE'">
            <!-- New Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Template Doc/Slide ID:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The file to take as template"
                    saved-expression="data.definition.templateIdExpression"
                    expression-unique-identifier="GDTemplateID"
                    on-tonkean-expression-changed="onTemplateIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
                <div
                    ng-if="data.validationObject.noTemplateDocId"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noTemplateDocId }}
                </div>
            </div>

            <div class="margin-top common-title-xxs">Replace Placeholders:</div>

            <!-- Replacement Actions -->
            <div ng-repeat="action in data.docReplacementActions" class="padding-normal-xs common-border">
                <!-- Find Text -->
                <input
                    type="text"
                    class="form-control"
                    placeholder="Text to find..."
                    ng-model="action.findText"
                    ng-change="onDefinitionChanged(true)"
                />

                <!-- Replace Text -->
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Text to replace..."
                    saved-expression="action.replaceTextExpression"
                    preview-evaluation-source="data.previewEvaluationSource"
                    expression-unique-identifier="{{ 'GDTemplateTextReplace_' + $index }}"
                    on-tonkean-expression-changed="
                        onTemplateReplaceTextTonkeanExpressionChanged(expression, shouldSaveLogic, action)
                    "
                ></tnk-tonkean-expression>

                <tnk-checkbox
                    label="'Case-sensitive?'"
                    value="action.isMatchCase"
                    class="margin-top-xs"
                    on-toggle-async="onMatchCaseToggled(action, value)"
                ></tnk-checkbox>
            </div>

            <a ng-click="addReplacementAction()">+ Add</a>
        </div>

        <!-- Extract Text From Document -->
        <div ng-if="data.definition.customActionKey === 'EXTRACT_TEXT_FROM_DOCUMENT'">
            <!-- Selecting google cloud integration for when doing OCR using google cloud -->
            <div ng-if="pm.projectData.executeOcrUsingGoogleCloudForPdfFile">
                <!-- Title -->
                <div class="margin-bottom-xs">
                    Google Cloud Provider (
                    <a ui-sref="product.enterpriseComponents({tab: 'data-sources'})">Connect</a>
                    ):
                </div>

                <!-- Project integration selector -->
                <tnk-project-integration-selector
                    group-id="{{ data.groupId }}"
                    selected-project-integration-id="{{ data.definition.googleCloudProjectIntegrationId }}"
                    allow-default-option="true"
                    default-option-label="Tonkean Google Cloud Provider"
                    filter-on-integration-type="data.filterByIntegrations"
                    on-project-integration-selected="onProjectIntegrationSelected(selectedProjectIntegration)"
                ></tnk-project-integration-selector>
            </div>

            <!-- Mime type selection is only relevant for doing OCR using google drive -->
            <div ng-if="!pm.projectData.executeOcrUsingGoogleCloudForPdfFile">
                <!-- Title -->
                <div class="margin-bottom-xs">Mime Type:</div>

                <!-- Mime type selection -->
                <ui-select
                    theme="bootstrap"
                    ng-model="data.definition.mimeType"
                    required
                    ng-change="onMimeTypeChanged()"
                >
                    <ui-select-match placeholder="Choose a mime type">{{ data.definition.mimeType }}</ui-select-match>

                    <ui-select-choices repeat="possibleMimeType in data.possibleMimeTypes | filter:$select.search">
                        <div ng-bind-html="possibleMimeType | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>

                <hr />

                <div class="margin-bottom-xs">
                    <span class="common-bold">Note:</span>
                    The number of blank spaces between extracted words may differ from run to run.
                </div>
            </div>
        </div>

        <!-- Upload File From Url -->
        <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE_FROM_URL'">
            <!-- Url -->
            <div class="margin-top">
                <tnk-worker-file-selection
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    custom-trigger-id="{{ data.configuredLogic.node.id }}"
                    file-source-type="{{ data.definition.attachment.fileSourceType }}"
                    storage-integration-id="{{ data.definition.attachment.storageIntegrationId }}"
                    storage-integration-action-id="{{ data.definition.attachment.storageIntegrationActionId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    url-expression="data.definition.attachment.url"
                    parameters-values="data.definition.attachment.parametersValues"
                    should-display-delete-sign="false"
                    extended-match-configuration="data.definition.attachment.extendedMatchConfiguration"
                    worker-static-asset-id="{{ data.definition.attachment.workerStaticAssetId }}"
                    worker-static-asset-display-name="{{ data.definition.attachment.workerStaticAssetDisplayName }}"
                    on-worker-file-selection-changed="
                        onWorkerFileSelectionChanged(
                            fileSourceType,
                            storageIntegrationId,
                            extendedMatchConfiguration,
                            urlExpression,
                            workerStaticAssetId,
                            workerStaticAssetDisplayName,
                            parametersValues,
                            shouldSaveLogic,
                            storageIntegrationActionId
                        )
                    "
                ></tnk-worker-file-selection>

                <div
                    ng-if="data.validationObject.errorMessage"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.errorMessage }}
                </div>
            </div>

            <!-- Destination Folder -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Destination Folder (ID):</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The folder to create the file in..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.destinationFolderIdExpresssion"
                    expression-unique-identifier="GDFolderDestination"
                    on-tonkean-expression-changed="onDestinationFolderExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- New File Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">New File Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="The new file name..."
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.fileNameExpresssion"
                    expression-unique-identifier="GDFileName"
                    on-tonkean-expression-changed="onFileNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    ng-if="data.validationObject.noFileName"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noFileName }}
                </div>
            </div>

            <!-- Convert to Google Doc -->
            <div class="delay-configuration-logic">
                <tnk-checkbox
                    class="margin-top-xs"
                    label="'Save as Google Doc'"
                    value="data.definition.convertToGDoc"
                    on-toggle-async="onDefinitionChanged(true)"
                ></tnk-checkbox>

                <div class="alert alert-info margin-top">
                    Important: Use it in case you would like later to convert the file to PDF
                </div>
            </div>
        </div>

        <!-- Copy rows action -->
        <div class="margin-bottom" ng-if="data.definition.customActionKey === 'COPY_ROWS' && !data.loading">
            <!-- Source Spreadsheet ID -->
            <div>
                <div class="flex-vmiddle">
                    <div class="margin-bottom margin-top-xs">Source Spreadsheet ID:</div>
                </div>

                <!-- Tonkean custom expression input -->
                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Source Spreadsheet ID"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.sourceSpreadsheetIdExpression"
                        expression-unique-identifier="GoogleDriveSourceSpreadsheetId"
                        on-tonkean-expression-changed="onSourceSpreadsheetIdExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Source Sheet Name -->
            <div>
                <div class="flex-vmiddle">
                    <div class="margin-bottom margin-top-xs">Source Sheet Name:</div>
                    <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="If empty, the first sheet will be used."
                    ></i>
                </div>

                <!-- Tonkean custom expression input -->
                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Source Sheet Name"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.sourceSheetNameExpression"
                        expression-unique-identifier="GoogleDriveSourceSheetName"
                        on-tonkean-expression-changed="onSourceSheetNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Start Row NUmber -->
            <div>
                <div class="flex-vmiddle">
                    <div class="margin-bottom margin-top-xs">Start Row Number:</div>
                    <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="If empty, all rows will be copied."
                    ></i>
                </div>

                <!-- Tonkean custom expression input -->
                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Start Row Number"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.sourceStartRowNumberExpression"
                        expression-unique-identifier="GoogleDriveSourceStartRowNumber"
                        on-tonkean-expression-changed="onSourceStartRowNumberExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Destination Spreadsheet ID -->
            <div>
                <div class="flex-vmiddle">
                    <div class="margin-bottom margin-top-xs">Destination Spreadsheet ID:</div>
                </div>

                <!-- Tonkean custom expression input -->
                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Destination Spreadsheet ID"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.destinationSpreadsheetIdExpression"
                        expression-unique-identifier="GoogleDriveDestinationSpreadsheetId"
                        on-tonkean-expression-changed="onDestinationSpreadsheetIdExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Destination Sheet Name -->
            <div>
                <div class="flex-vmiddle">
                    <div class="margin-bottom margin-top-xs">Destination Sheet Name:</div>
                </div>

                <!-- Tonkean custom expression input -->
                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Destination Sheet Name"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.destinationSheetNameExpression"
                        expression-unique-identifier="GoogleDriveDestinationSheetName"
                        on-tonkean-expression-changed="onDestinationSheetNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Convert File Type -->
        <div ng-if="data.definition.customActionKey === 'CONVERT_FILE_TYPE'">
            <!-- Destination Folder -->
            <div>
                <div class="margin-bottom-xs">Destination Folder (ID):</div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Destination Folder"
                        expression-unique-identifier="GDConvertDestinationFolderId"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.convertDestinationIdExpression"
                        on-tonkean-expression-changed="onConvertDestinationIdExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Converted file name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Converted File Name:</div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="The Converted File New Name"
                        expression-unique-identifier="GDConvertNewFileName"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.convertNewFileNameExpression"
                        on-tonkean-expression-changed="onConvertNewFileNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Convert mimetype -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Convert to Mimetype:</div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Mimetype"
                        expression-unique-identifier="GDConvertMimetype"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.convertMimetypeExpression"
                        on-tonkean-expression-changed="onConvertMimetypeExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
