export function GenericIntegrationModalCtrl($scope, $rootScope) {
    $scope.data = {
        integrationType: null,
        projectIntegration: undefined,
        integration: null,
        onIntegrationClosed: null,
        onIntegrationConfigurationCanceled: null,

        otherIntegrationOwner: false,
    };

    /**
     * Initialization function.
     */
    $scope.init = function (settings) {
        $scope.data.integrationType = settings.integrationType;
        $scope.data.projectIntegration = settings.projectIntegration;
        $scope.data.integration = settings.integration;
        $scope.data.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.data.onIntegrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        $scope.data.otherIntegrationOwner =
            $scope.data.projectIntegration?.integration.user.id !== $rootScope.currentUser.id;
    };

    /**
     * Occurs on the close of the modal.
     */
    $scope.onCancel = function () {
        $scope.data.onIntegrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.onIntegrationSetupCompleted = (projectIntegration) => {
        const settings = {
            createdProjectIntegration: projectIntegration,
            integration: projectIntegration,
            integrations: [
                {
                    integration: projectIntegration,
                    projectData: projectIntegration.projectData,
                    projectIntegrationDisplayName: projectIntegration.displayName,
                },
            ],
        };

        $scope.data.onIntegrationClosed(settings);
    };
}

export default angular.module('tonkean.shared').controller('GenericIntegrationModalCtrl', GenericIntegrationModalCtrl);
