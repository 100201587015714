const toFixedWithoutRounding = (number: number, precision: number) => {
    const numberString = number.toString();

    const integerPart = numberString.split('.')[0];
    let decimalPart = numberString.split('.')?.[1];

    if (decimalPart === undefined) {
        return integerPart;
    }

    if (decimalPart?.length < precision) {
        decimalPart = decimalPart.padEnd(precision, '0');
    } else {
        decimalPart = decimalPart.slice(0, precision);
    }

    decimalPart = decimalPart.slice(0, precision);

    return `${integerPart}.${decimalPart}`;
};

export default toFixedWithoutRounding;
