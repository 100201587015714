import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addItemIsAddedCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addItemIsAddedCreateAfterOption';
import addMonitorBlockCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addMonitorBlockCreateAfterOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const ManualNextStepsLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.MANUAL_NEXT_STEPS,
    title: 'Create Inner Items',
    iconClass: 'mod-manual-next-steps',
    creatingActionItems: true,
    description: 'Pre-define what steps should be taken care of before moving on to the next block.',
    cantMoveInto: true,
    doNotDisplayStatusEdit: true,
    doNotDisplayNotificationsEdit: true,
    allowedChildrenTypes: () => [LogicBlockConfigType.MONITOR_TRACKS],
    parentValidationOnCreation: (parent, $emit) => {
        if (
            parent?.customTriggerType &&
            (parent.customTriggerType === 'AUTONOMOUS' || parent.customTriggerType === 'INNER_AUTONOMOUS') &&
            parent.monitorInnerItems
        ) {
            $emit('alert', {
                msg: 'You cannot create Create Inner Items as an inner child of a trigger that monitor inner items creation',
                type: 'error',
            });
            return false;
        } else {
            return true;
        }
    },
    createAfterOptionsTitle: 'Run on',
    innerItemDetailsTitlePlaceholder: 'Inner Item',
    innerItemDetailsDescriptionPlaceholder: 'An inner item related to the business request',
    createAfterOptions: (features) => {
        if (features?.tonkean_show_inner_item_idd) {
            return [addItemIsAddedCreateAfterOption, addMonitorBlockCreateAfterOption];
        } else {
            return [addMonitorBlockCreateAfterOption];
        }
    },
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (
            !(
                definition.innerTracksTemplate?.innerTrackTemplatesCollection?.length ||
                definition.dynamicInnerTracksTemplate?.innerTrackTemplatesCollection?.length
            )
        ) {
            validationObject.noTracks = 'Must have at least 1 item with a title.';
        }

        if (definition.innerTracksTemplate?.innerTrackTemplatesCollection?.length) {
            const tracks = definition.innerTracksTemplate.innerTrackTemplatesCollection;
            for (const track of tracks) {
                if (!track.expression?.evaluatedExpression?.length) {
                    validationObject.invalidTrack = 'All items must have a title.';
                    break;
                }
            }
        }

        if (definition.dynamicInnerTracksTemplate?.innerTrackTemplatesCollection?.length) {
            const dynamicTracks = definition.dynamicInnerTracksTemplate.innerTrackTemplatesCollection;
            for (const track of dynamicTracks) {
                if (!track.expression?.evaluatedExpression?.length) {
                    validationObject.invalidTrack = 'All items must have a title.';
                    break;
                }
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    dataAutomation: 'create-inner-items',
};

export default ManualNextStepsLogicBlockConfig;
