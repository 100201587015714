import React, { useRef } from 'react';
import styled from 'styled-components';

import ManagePublishersModalContent from './ManagePublishersModalContent';

import { Modal } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';

const ManagePublishersModal = styled(Modal)`
    width: 550px;
`;

interface Props {
    workflowFolder: WorkflowFolder;
    open: boolean;
    onClose(): void;
}

const OpenManagePublishersModal: React.FC<Props> = ({ workflowFolder, open, onClose }) => {
    const initiallyFocusedRef = useRef<HTMLButtonElement>(null);

    return (
        <ManagePublishersModal
            open={open}
            onClose={onClose}
            fullScreen={false}
            scrollable={false}
            initiallyFocusedElementRef={initiallyFocusedRef}
            hasBackdrop
        >
            <ManagePublishersModalContent workflowFolder={workflowFolder} initiallyFocusedRef={initiallyFocusedRef} />
        </ManagePublishersModal>
    );
};

export default OpenManagePublishersModal;
