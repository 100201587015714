import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MultiplePersonSelectorByEmailCtrl($scope, utils, personCache) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        paramsObjectForCallbacks: ctrl.paramsObjectForCallbacks,
        selectedPersonId: ctrl.selectedPersonId,
        placeholder: !utils.isNullOrEmpty(ctrl.placeholder) ? ctrl.placeholder : null,
        maxTags: ctrl.maxTags ?? 'Infinity',

        peopleMap: {},
        selectedPeopleArray: [],
        disabled: ctrl.disabled,
        hidePlaceholderWhenDisabled: ctrl.hidePlaceholderWhenDisabled,
        hideTagsBackgroundWhenDisabled: ctrl.hideTagsBackgroundWhenDisabled,
        includeExternal: ctrl.includeExternal,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (ctrl.selectedPersonId) {
            $scope.data.selectedPeopleArray = [];
            getPeople(ctrl.selectedEmails);
        }
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedEmails) {
            $scope.data.selectedEmails = ctrl.selectedEmails;

            if (ctrl.selectedEmails) {
                $scope.data.selectedPeopleArray = [];
                getPeople(ctrl.selectedEmails);
            }
        }
    };

    $scope.onUpdate = function () {
        $scope.data.selectedPeopleArray
            .filter((user) => user.customEmail)
            .forEach((user) => {
                user.customEmail = false;
                user.name = user.email;
                user.initials = user.email[0];
                user.valid = true;
            });

        $scope.data.peopleMap = utils.createMapFromArray($scope.data.selectedPeopleArray, 'email');
        if (ctrl.onUpdate) {
            const newValue = $scope.data.selectedPeopleArray
                .map((user) => user.email)
                .filter((email) => email.length)
                .join(',');

            ctrl.onUpdate({
                emails: newValue,
                paramsObject: $scope.data.paramsObjectForCallbacks,
            });
            ctrl.onUpdateReact(newValue);
        }
    };

    /**
     * Gets the person by emails string and sets it as selected person.
     */
    function getPeople(emails) {
        const emailsArray = emails
            .split(',')
            .map((email) => email.trim().toLowerCase())
            .filter((email) => email.length);

        emailsArray.map(async (email) => {
            const person =
                $scope.data.peopleMap[email] ||
                (await personCache
                    .getEntitiesByEmailDataLoader([email])
                    .then(
                        (personArr) =>
                            personArr[0] || { email, customEmail: false, name: email, initials: email[0], valid: true },
                    ));

            $scope.data.peopleMap[email] = person;
            $scope.data.selectedPeopleArray.push(person);
        });
    }
}

export default angular
    .module('tonkean.app')
    .controller('MultiplePersonSelectorByEmailCtrl', lateConstructController(MultiplePersonSelectorByEmailCtrl));
