import SolutionSitePageActionsItemWidget from './components/SolutionSitePageActionsItemWidget';
import SolutionSitePageActionsItemWidgetConfigurationEditor from './components/SolutionSitePageActionsItemWidgetConfigurationEditor';
import type { SolutionSitePageWidgetPackage } from '../../../WidgetModule';

const SolutionSitePageActionsItemWidgetPackage: SolutionSitePageWidgetPackage = {
    displayComponent: SolutionSitePageActionsItemWidget,
    configurationComponent: SolutionSitePageActionsItemWidgetConfigurationEditor,
};

export default SolutionSitePageActionsItemWidgetPackage;
