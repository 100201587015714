import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SendEmailAttachmentsCtrl($scope, integrations, tonkeanUtils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogicId: ctrl.configuredLogicId,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        existingAttachmentsConfiguration: ctrl.existingAttachmentsConfiguration,

        attachments: [],

        storageIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: tonkeanUtils.getFileEntityMetadata(),
    };

    ctrl.$onInit = function () {
        initializeEditMode();
    };

    $scope.onAttachmentsChanged = function (shouldSaveLogic) {
        // We have to use a proxy array for the attachments so we won't save the project integration on the definition
        // (just the id)
        const attachments = $scope.data.attachments.map((attachment) => {
            return {
                fileSourceConfiguration: {
                    fileSourceType: attachment.fileSourceType,
                    extendedMatchConfiguration:
                        attachment.fileSourceType === 'DATA_STORAGE'
                            ? attachment.extendedMatchConfiguration
                            : undefined,
                    storageIntegrationId:
                        attachment.fileSourceType === 'DATA_STORAGE' ? attachment.storageIntegrationId : undefined,
                    storageIntegrationActionId:
                        attachment.fileSourceType === 'DATA_STORAGE'
                            ? attachment.storageIntegrationActionId
                            : undefined,
                    url: attachment.fileSourceType === 'URL' ? attachment.url : undefined,
                    workerStaticAssetId:
                        attachment.fileSourceType === 'UPLOAD_FILE' ? attachment.workerStaticAssetId : undefined,
                    workerStaticAssetDisplayName:
                        attachment.fileSourceType === 'UPLOAD_FILE'
                            ? attachment.workerStaticAssetDisplayName
                            : undefined,
                },
            };
        });

        ctrl.onAttachmentsChanged({
            attachments,
            shouldSaveLogic,
        });
    };

    $scope.onAttachmentStorageProjectIntegrationSelected = function (attachment, selectedProjectIntegration) {
        attachment.storageProjectIntegration = selectedProjectIntegration;
        attachment.storageProjectIntegrationId = selectedProjectIntegration.id;
        attachment.extendedMatchConfiguration = $scope.onAttachmentsChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = function (
        attachment,
        extendedMatchConfiguration,
        shouldSaveLogic,
    ) {
        attachment.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onAttachmentsChanged(shouldSaveLogic);
    };

    $scope.addAttachment = function () {
        $scope.data.attachments.push({});
    };

    $scope.onDeleteSignClicked = function (index) {
        $scope.data.attachments.splice(index, 1);
        $scope.onAttachmentsChanged(true);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        attachment,
        storageIntegrationActionId,
    ) {
        attachment.fileSourceType = fileSourceType;
        attachment.storageIntegrationId = storageIntegrationId;
        attachment.storageIntegrationActionId = storageIntegrationActionId;
        attachment.extendedMatchConfiguration = extendedMatchConfiguration;
        attachment.url = urlExpression;
        attachment.workerStaticAssetId = workerStaticAssetId;
        attachment.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        attachment.parametersValues = parametersValues;
        $scope.onAttachmentsChanged(shouldSaveLogic);
    };

    function initializeEditMode() {
        if ($scope.data.existingAttachmentsConfiguration) {
            $scope.data.attachments = $scope.data.existingAttachmentsConfiguration.map((existingAttachment) => {
                if (existingAttachment.fileSourceConfiguration) {
                    // Are we using the new configuration?

                    return {
                        fileSourceType: existingAttachment.fileSourceConfiguration.fileSourceType,
                        extendedMatchConfiguration:
                            existingAttachment.fileSourceConfiguration.extendedMatchConfiguration,
                        storageIntegrationId: existingAttachment.fileSourceConfiguration.storageIntegrationId,
                        storageIntegrationActionId:
                            existingAttachment.fileSourceConfiguration.storageIntegrationActionId,
                        url: existingAttachment.fileSourceConfiguration.url,
                        parametersValues: existingAttachment.fileSourceConfiguration.parametersValues,
                        workerStaticAssetId: existingAttachment.fileSourceConfiguration.workerStaticAssetId,
                        workerStaticAssetDisplayName:
                            existingAttachment.fileSourceConfiguration.workerStaticAssetDisplayName,
                    };
                } else {
                    // Otherwise, we're using the old configuration.

                    return {
                        fileSourceType: 'DATA_STORAGE',
                        extendedMatchConfiguration: existingAttachment.extendedMatchConfiguration,
                        storageIntegrationId: existingAttachment.storageProjectIntegrationId,
                        storageIntegrationActionId: existingAttachment.storageIntegrationActionId,
                        url: {
                            originalExpression: null,
                            evaluatedExpression: null,
                        },
                    };
                }
            });
        }
    }
}

export default angular.module('tonkean.app').controller('SendEmailAttachmentsCtrl', lateConstructController(SendEmailAttachmentsCtrl));
