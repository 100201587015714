import React from 'react';
import styled from 'styled-components';

import LoaderImage from '../../images/tonkean-loader-2.gif?url';

interface Props {
    margin?: string;
}

const TonkeanAnimation = styled.div`
    display: block;
    height: 72px;
    width: 72px;
    background-size: cover;
    background-image: url(${LoaderImage});
`;

interface LoaderContainerProps {
    $margin: string;
}

const LoaderContainer = styled.div<LoaderContainerProps>`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.$margin};
`;

interface LoadingTonkeanProps {
    margin?: string;
}

const LoadingTonkean: React.FC<LoadingTonkeanProps> = ({ margin = '0' }) => {
    return (
        <LoaderContainer $margin={margin} data-automation="loading-tonkean">
            <TonkeanAnimation />
        </LoaderContainer>
    );
};

/**
 * We use memo here to allow rendering this component from different places but it will appear as a continuous load, and thats because its all the same component
 * Example:
 * <Parent>
 *    <Child/>
 * </Parent>
 *
 * If both Parent and Child returns <TonkeanLoading /> while they load their inner data, it will appear as one continuous load.
 */
export default React.memo(LoadingTonkean);
