import { useCallback, useMemo, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { UpdateFieldsValuesManager } from '@tonkean/infrastructure';
import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useFieldsValuesUpdater = (
    initiativeUpdatedCallback?: (initiative: Initiative) => void,
): UpdateFieldsValuesManager => {
    const [, updateGlobalFieldValue] = useLazyTonkeanService('updateFieldValue');
    const [, updateMultipleFieldsValues] = useLazyTonkeanService('updateMultipleFieldsValues');

    const [fieldsInActiveUpdate, setFieldsInActiveUpdate] = useState<
        Record<TonkeanId<TonkeanType.FIELD_DEFINITION> | TonkeanId<TonkeanType.FIELD> | `TNK_${string}`, boolean>
    >({});

    const startedUpdatingFields = useCallback(
        (fieldsIds: (TonkeanId<TonkeanType.FIELD_DEFINITION> | TonkeanId<TonkeanType.FIELD> | `TNK_${string}`)[]) => {
            setFieldsInActiveUpdate((prev) => {
                const newFieldsInActiveUpdate = { ...prev };
                fieldsIds.forEach((fieldId) => {
                    newFieldsInActiveUpdate[fieldId] = true;
                });
                return newFieldsInActiveUpdate;
            });
        },
        [],
    );

    const finishedUpdatingFields = useCallback(
        (fieldsIds: (TonkeanId<TonkeanType.FIELD_DEFINITION> | TonkeanId<TonkeanType.FIELD> | `TNK_${string}`)[]) => {
            setFieldsInActiveUpdate((prev) => {
                const newFieldsInActiveUpdate = { ...prev };
                fieldsIds.forEach((fieldId) => {
                    newFieldsInActiveUpdate[fieldId] = false;
                });
                return newFieldsInActiveUpdate;
            });
        },
        [],
    );

    const updateGlobalField = useCallback(
        async (id: TonkeanId<TonkeanType.FIELD>, newValue: unknown) => {
            startedUpdatingFields([id]);
            try {
                await updateGlobalFieldValue(id, newValue, undefined);
            } finally {
                finishedUpdatingFields([id]);
            }
        },
        [updateGlobalFieldValue, startedUpdatingFields, finishedUpdatingFields],
    );

    const updateInitiativeFields = useCallback(
        async (
            workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
            initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
            fields: Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, string>,
        ) => {
            const fieldsIds: TonkeanId<TonkeanType.FIELD_DEFINITION>[] = Object.keys(
                fields,
            ) as TonkeanId<TonkeanType.FIELD_DEFINITION>[];

            startedUpdatingFields(fieldsIds);
            try {
                const updatedInitiative = await updateMultipleFieldsValues(workflowVersionId, initiativeId, fields);
                initiativeUpdatedCallback?.(updatedInitiative);
                return updatedInitiative;
            } finally {
                finishedUpdatingFields(fieldsIds);
            }
        },
        [updateMultipleFieldsValues, startedUpdatingFields, finishedUpdatingFields, initiativeUpdatedCallback],
    );

    return useMemo(() => {
        return {
            updateGlobalField,
            updateInitiativeFields,
            inActiveFieldsValuesUpdate: Object.keys(fieldsInActiveUpdate).some(
                (fieldId) => fieldsInActiveUpdate[fieldId],
            ),
        };
    }, [updateGlobalField, updateInitiativeFields, fieldsInActiveUpdate]);
};

export default useFieldsValuesUpdater;
