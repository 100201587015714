import { reactToAngular } from 'angulareact';

import NoCodeProjectIntegrationDownloadPredefined from './NoCodeProjectIntegrationDownloadPredefinedParameters';

export default angular
    .module('tonkean.app')
    .component(
        'tnkNoCodeProjectIntegrationDownloadPredefinedParameters',
        reactToAngular(NoCodeProjectIntegrationDownloadPredefined, [
            'storageProjectIntegration',
            'onStorageIntegrationDynamicParametersChanged',
            'groupId',
            'workflowVersionId',
            'customTriggerId',
            'parametersValues',
            'storageProjectIntegrationActionId',
        ]),
    );
