import React from 'react';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field } from '@tonkean/infrastructure';
import { ActionDefinitionType } from '@tonkean/tonkean-entities';
import type {
    TonkeanExpressionAdditionalTab,
    SQLRequestDefinition,
    ProjectIntegrationAction,
} from '@tonkean/tonkean-entities';

interface Props {
    actionDefinition: SQLRequestDefinition;
    setProjectIntegrationAction: React.Dispatch<React.SetStateAction<Partial<ProjectIntegrationAction>>>;
    additionalTabsForTonkeanExpression: TonkeanExpressionAdditionalTab[];
}

const ProjectIntegrationActionSqlConfiguration: React.FC<Props> = ({
    actionDefinition,
    setProjectIntegrationAction,
    additionalTabsForTonkeanExpression,
}) => {
    return (
        <>
            <Field label="SQL Query">
                <TonkeanExpression
                    groupId=""
                    workflowVersionId=""
                    placeholder="Insert SQL query"
                    automationIdentifierExpressionValue="project-integration-action-sql-configuration-insert-query"
                    automationIdentifierExpressionAddField="project-integration-action-sql-configuration-add-field-button"
                    savedOriginalExpression={actionDefinition.query.originalExpression || ''}
                    savedEvaluatedExpression={actionDefinition.query.evaluatedExpression || ''}
                    onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                        setProjectIntegrationAction((action) => {
                            if (action.actionDefinition?.definitionType === ActionDefinitionType.SQL) {
                                return {
                                    ...action,
                                    actionDefinition: {
                                        ...action.actionDefinition,
                                        query: {
                                            ...action.actionDefinition.query,
                                            evaluatedExpression,
                                            originalExpression,
                                            isStripHtmlDisabled: false,
                                        },
                                    },
                                };
                            } else {
                                return { ...action };
                            }
                        });
                    }}
                    additionalTabs={additionalTabsForTonkeanExpression}
                    defaultTabId="CUSTOM_ACTION_PARAMS"
                    showFormulasTab={false}
                    doNotEvaluatePreview
                    modTextArea
                    globalExpressionOnly
                />
            </Field>
        </>
    );
};

export default ProjectIntegrationActionSqlConfiguration;
