import styled from 'styled-components';

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 16px 0;
    justify-content: space-between;
`;
