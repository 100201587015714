import type { RegexBaseItem } from './RegexBaseItem';

/**
 * Representing a part of a regex expression.
 *
 * @example
 * const regex = /([A-z])/g
 * // There are the parts that would
 * // be constructed for this regex
 * const RegexExpressionParts = ["(", "([A-z])", "[A-z]", ")"];
 */
export class RegexExpressionPart implements RegexBaseItem {
    /**
     * Counter to generate a fake index.
     */
    private static counter = 0;

    public index: string | undefined;
    public color: string;
    public label?: string;
    public groupIndex?: number;

    constructor(
        public value: string,
        public startIndex: number,
        public endIndex: number,
        public hasError: boolean = false,
    ) {
        if (!hasError) {
            this.index = RegexExpressionPart.counter.toString();
            RegexExpressionPart.counter++;
        }
    }

    getTooltipLabel() {
        return this.label ? { label: this.label } : false;
    }
}
