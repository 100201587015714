import React, { useState } from 'react';

import ItemInterfaceInSequence from './ItemInterfaceInSequence';
import { useItemInterfaceContextDataBuilder } from '../../../hooks';
import usePollInitiativeData from '../../../SystemInterface/hooks/usePollInitiativeData';
import useGetWidgetsToDisplay from '../../../widgets/hooks/useGetWidgetsToDisplay';

import { ItemInterfaceContext, ItemInterfaceSection } from '@tonkean/infrastructure';
import type {
    FetchResults,
    FullProject,
    Initiative,
    ItemInterface,
    ItemInterfaceWidget,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { SequenceState } from '@tonkean/tonkean-entities';

interface Props {
    onNextClicked?: (data: any) => void;
    onBackClicked?: () => void;
    onSwitchedToNextInterfaceInternally?: (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void;
    onSwitchedToPrevInterfaceInternally?: (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void;
    itemInterface: ItemInterface;
    workflowVersion: WorkflowVersion;
    initiative: Initiative;
    project: FullProject;
    widgets: FetchResults<WidgetBase<WidgetConfiguration, WidgetParentTypes>>;
    customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    showBackButton?: boolean;
    nextWorkerRunId?: TonkeanId<TonkeanType.WORKER_RUN>;
    isLastInSequence?: boolean;
    formModalRef?: HTMLDivElement | null;
    buttonColor?: string;
}

const ItemInterfaceInSequenceWithContext: React.FC<Props> = ({
    initiative,
    workflowVersion,
    itemInterface,
    project,
    customTriggerId,
    widgets,
    onNextClicked,
    onBackClicked,
    onSwitchedToNextInterfaceInternally,
    onSwitchedToPrevInterfaceInternally,
    showBackButton,
    nextWorkerRunId,
    isLastInSequence = false,
    formModalRef,
    buttonColor,
}) => {
    const [currentSequenceState, setCurrentSequenceState] = useState<SequenceState>({
        itemInterface,
        customTriggerId,
        widgets: widgets.entities,
        isLastInSequence,
        workflowVersion,
    });

    const {
        polledInitiative,
        error: pollInitiativeError,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        setPollInitiativeEnabled,
    } = usePollInitiativeData(project.id, initiative.id, currentSequenceState.itemInterface.id);

    const widgetsToDisplay: ItemInterfaceWidget[] = useGetWidgetsToDisplay(
        currentSequenceState.widgets as ItemInterfaceWidget[],
        polledInitiative,
    );

    const currentItemInterface = currentSequenceState?.itemInterface || itemInterface;
    const currentWorkflowVersion = currentSequenceState?.workflowVersion || workflowVersion;

    // Initiative = initial initiative loading in the interface; ItemInterfaceData = the initiative from polling (most updated initiative data).
    const currentInitiative = polledInitiative || initiative;

    const itemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative: currentInitiative,
        initiativeInitialLoading: false,
        intakeInitiative: initiative,
        itemInterface: currentItemInterface,
        section: ItemInterfaceSection.MAIN,
        workflowVersion: currentWorkflowVersion,
        intakeWorkflowVersion: workflowVersion,
        includedWidgetsSummaryByInterfaceId: undefined, // sequence shouldnt have tabs
        originatedCustomTriggerId: customTriggerId,
        currentCustomTriggerId: currentSequenceState.customTriggerId,
        widgetsToDisplay,
        allInterfaceWidgets: (currentSequenceState.widgets || []) as ItemInterfaceWidget[],
        itemInterfaceContextDataError: pollInitiativeError,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        currentSequenceState: {
            ...currentSequenceState,
            itemInterface: currentItemInterface,
            workflowVersion: currentWorkflowVersion,
            initiative: currentInitiative,
            widgets: currentSequenceState?.widgets || [],
            isLastInSequence,
            evaluatedIntakeSequenceButtonLabel: currentSequenceState?.evaluatedIntakeSequenceButtonLabel,
        },
        setCurrentSequenceState,
        setPollInitiativeEnabled,
    });

    return (
        <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
            <ItemInterfaceInSequence
                itemInterface={currentSequenceState.itemInterface}
                workflowVersion={workflowVersion}
                widgets={widgets}
                project={project}
                buttonColor={buttonColor}
                onSwitchedToNextInterfaceInternally={onSwitchedToNextInterfaceInternally}
                onSwitchedToPrevInterfaceInternally={onSwitchedToPrevInterfaceInternally}
                onBackClicked={onBackClicked}
                onNextClicked={onNextClicked}
                showBackButton={showBackButton}
                nextWorkerRunId={nextWorkerRunId}
                formModalRef={formModalRef}
                setCurrentSequenceState={setCurrentSequenceState}
            />
        </ItemInterfaceContext.Provider>
    );
};

export default ItemInterfaceInSequenceWithContext;
