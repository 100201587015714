import { useEffect } from 'react';

import useDropdownPossibleValues from './useDropdownPossibleValues';
import useGetGlobalFieldInstance from './useGetGlobalFieldInstance';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useDropdownOptions = (
    workflowVersionType: WorkflowVersionType | undefined,
    initiativeRowData: InitiativeRowData,
    groupId: TonkeanId<TonkeanType.GROUP>,
    inputMultiValueSeparator: string,
    manualPossibleValues?: string[] | undefined,
    dropdownOptionsFromFieldDefinitionId?: TonkeanId<TonkeanType.FIELD_DEFINITION>,
) => {
    const [{ data: fieldDefinitionForDropdownOptions }, getFieldDefinitionByIdAndWorkflowVersionType] =
        useLazyTonkeanService('getFieldDefinitionByIdAndWorkflowVersionType');

    useEffect(() => {
        const fieldConfiguredToTakeOptionFromAnotherField = dropdownOptionsFromFieldDefinitionId && workflowVersionType;
        if (fieldConfiguredToTakeOptionFromAnotherField) {
            getFieldDefinitionByIdAndWorkflowVersionType(dropdownOptionsFromFieldDefinitionId, workflowVersionType);
        }
    }, [workflowVersionType, dropdownOptionsFromFieldDefinitionId, getFieldDefinitionByIdAndWorkflowVersionType]);

    const dropdownOptionsFromFieldValue = useGetGlobalFieldInstance(
        fieldDefinitionForDropdownOptions,
        groupId,
        workflowVersionType,
    )?.value;

    return useDropdownPossibleValues(
        initiativeRowData,
        dropdownOptionsFromFieldValue,
        inputMultiValueSeparator,
        fieldDefinitionForDropdownOptions?.targetType,
        manualPossibleValues,
        dropdownOptionsFromFieldDefinitionId,
    );
};

export default useDropdownOptions;
