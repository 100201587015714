import React from 'react';

import LineItemWidgetDisplay from './LineItemWidgetDisplay';
import type { ItemWidgetProps } from '../../../WidgetModule';
import useItemsGridInlineMenuActions from '../../hooks/useItemsGridInlineMenuActions';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const LineItemsWidget: React.FC<ItemWidgetProps<LineItemsWidgetConfiguration>> = ({
    initiative: parentInitiative,
    widget,
    permission,
}) => {
    const { project, itemInterface, workflowVersion, originatedCustomTriggerId, initiativeInitialLoading } =
        useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    const inlineActions = useItemsGridInlineMenuActions(
        widget.configuration?.actions || EMPTY_ARRAY,
        workflowVersion?.workflowVersionType || WorkflowVersionType.PUBLISHED,
        itemInterface.group.id,
    );

    return (
        <LineItemWidgetDisplay
            permission={permission}
            theme={themeConfiguration}
            workflowVersion={workflowVersion}
            groupId={parentInitiative?.group?.id}
            initiative={parentInitiative}
            initiativeInitialLoading={initiativeInitialLoading}
            projectId={project.id}
            widget={widget}
            inlineMenuActions={inlineActions}
            originatedCustomTriggerId={originatedCustomTriggerId}
        />
    );
};

export default LineItemsWidget;
