import AutonomousCreatedFromFormLogicBlockConfig from './AutonomousCreatedFromFormLogicBlockConfig';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const InnerAutonomousCreatedFromFormLogicBlockConfig: LogicBlockConfigDefinition = {
    ...AutonomousCreatedFromFormLogicBlockConfig,
    type: LogicBlockConfigType.INNER_AUTONOMOUS,
};

export default InnerAutonomousCreatedFromFormLogicBlockConfig;
