import GroupDescriptionModal from './GroupDescriptionModal';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'groupDescriptionModal',
        reactToAngular(GroupDescriptionModal, ['onCloseModal', 'show', 'group', 'workflowVersionType']),
    );
