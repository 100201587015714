import Onedrive365SetupComponent from './Onedrive365SetupComponent';
import onedrive365CircleIcon from '../../../../apps/tracks/images/integrations/onedrive-circle.svg';
import onedrive365Icon from '../../../../apps/tracks/images/onedrive.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class Onedrive365Integration extends IntegrationDefinitionBase {
    override name = 'onedrive365';
    override displayName = 'One Drive 365';
    override description = 'One Drive for enterprise users';
    override authenticationType = AuthenticationMethod.OAUTH;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [new IntegrationEntity('File', 'Files'), new IntegrationEntity('Folder', 'Folders')];
    override customizedSetupComponent = Onedrive365SetupComponent;

    override storageProviderSettings = new StorageProviderSettings(true, true);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(onedrive365CircleIcon, '130px'),
        new IntegrationIcon(onedrive365Icon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { File: true, Folder: true },
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const scopePermissions = `https://graph.microsoft.com/Sites.ReadWrite.All https://graph.microsoft.com/Files.ReadWrite.All https://graph.microsoft.com/User.Read https://graph.microsoft.com/Sites.ReadWrite.All https://graph.microsoft.com/Sites.Read.All offline_access`;

        const code = await oAuthHandler.microsoft(scopePermissions, environment.integrationKeysMap.microsoftUri, true);

        return { code, redirectUri: environment.integrationKeysMap.microsoftUri };
    }
}

export default Onedrive365Integration;
