import React, { useState } from 'react';
import styled from 'styled-components';

import ItemsGridInlineMenuItem from './components/ItemsGridInlineMenuItem';
import { isRowLoading, isTempRowForAddition } from '../../entities';
import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { LoadingCircle, Menu } from '@tonkean/infrastructure';
import { AddSvg, MoreActionsSvg } from '@tonkean/svg';
import { InterfaceCTAActionType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import utils, { colorSvg } from '@tonkean/utils';

const StyledAddSvg = styled(AddSvg)<{ $color: string }>`
    ${({ $color }) => colorSvg($color)};
`;

const Wrapper = styled.div<{ $borderColor: string; $rowMarker: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: default;

    border-top: 0;
    border-bottom: 0;
    color: ${Theme.colors.gray_600};

    border-left: ${({ $rowMarker, $borderColor }) => ($rowMarker ? `3px solid ${$borderColor}` : 'none')};
`;

interface Props extends ItemsGridCellRendererParams {
    hasRowMarker: boolean;
}

const OptionsCellRenderer: React.FC<Props> = ({
    node,
    context: { inlineMenuActions, workflowVersion, onDelete, projectId, onChange, backgroundColor },
    hasRowMarker,
}) => {
    const [isHovering, setIsHover] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const remove = () => {
        if (!node.data) {
            return;
        }

        onDelete?.(node.data.tableRowId);
    };

    if (node.data && isTempRowForAddition(node.data)) {
        return (
            <Wrapper $borderColor={backgroundColor} $rowMarker={hasRowMarker}>
                <IconButton
                    onClick={() => {
                        if (node.data && onChange) {
                            onChange(
                                node.data,
                                'title',
                                `New Line Item (${utils.formatDate(new Date(), true, false, false)})`,
                            );
                        }
                    }}
                    iconColor={backgroundColor}
                    noBackgroundColor
                >
                    <StyledAddSvg $color={backgroundColor} />
                </IconButton>
            </Wrapper>
        );
    }

    if (node.data && isRowLoading(node.data)) {
        return (
            <Wrapper $borderColor={backgroundColor} $rowMarker={hasRowMarker}>
                <LoadingCircle />
            </Wrapper>
        );
    }

    return (
        <Wrapper
            role="button"
            onMouseEnter={() => !!node.data?.initiativeId && setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            $borderColor={backgroundColor}
            $rowMarker={hasRowMarker}
        >
            {(isHovering || menuIsOpen) && inlineMenuActions?.length ? (
                <Menu
                    show={menuIsOpen && !!workflowVersion}
                    onClose={() => setMenuIsOpen(false)}
                    placement="bottom-end"
                    menuItems={
                        <>
                            {workflowVersion &&
                                inlineMenuActions?.map((action) => (
                                    <ItemsGridInlineMenuItem
                                        key={action.id}
                                        actionId={action.id}
                                        displayName={action.displayName}
                                        initiativeId={node.data!.initiativeId}
                                        actionType={InterfaceCTAActionType[action.type]}
                                        projectId={projectId}
                                        workflowVersionType={
                                            workflowVersion.workflowVersionType || WorkflowVersionType.PUBLISHED
                                        }
                                        workflowVersionId={workflowVersion.id}
                                        deleteCallback={remove}
                                    />
                                ))}
                        </>
                    }
                >
                    <IconButton onClick={() => setMenuIsOpen(true)} flat>
                        <MoreActionsSvg />
                    </IconButton>
                </Menu>
            ) : (
                <>{(node.data?._index || node.rowIndex || 0) + 1}</>
            )}
        </Wrapper>
    );
};

export default OptionsCellRenderer;
