import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CreateVersionIcon } from '../../../images/icons/navigation/create-a-version.svg';
import { ReactComponent as DiscardIcon } from '../../../images/icons/navigation/discard.svg';
import { ReactComponent as OpenMenuIcon } from '../../../images/icons/navigation/open-menu.svg';
import { ReactComponent as ViewChangesIcon } from '../../../images/icons/navigation/view-changes.svg';

import { Menu, MenuItem, Tooltip, useToggle } from '@tonkean/infrastructure';
import type { Group, WorkflowVersion } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StyledMenuItem = styled(MenuItem)<{ disabled: boolean }>`
    font-size: ${FontSize.SMALL_12};
    width: 235px;
    height: 30px;
    padding: 7px 8px;
    opacity: ${(props) => (props.disabled ? '0.5' : 'unset')};
    cursor: ${(props) => (props.disabled ? 'not-allowed !important' : 'pointer')};

    &:focus {
        background-color: ${Theme.colors.white};
    }

    &:hover {
        background-color: ${Theme.colors.gray_900};
    }
`;

const StyledCreateVersionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 26px;
`;

const StyledCreateVersionCommand = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 4px;
    gap: 10px;
    width: 36px;
    height: 16px;
    background: ${Theme.colors.gray_200};
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-weight: 400;
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    align-items: center;
    color: ${Theme.colors.gray_500};
`;

const StyledOpeningMenuItem = styled(MenuItem)<{ disabled: boolean; $workflowVersionChanges: number }>`
    width: ${(props) => (props.$workflowVersionChanges === 0 ? '140' : '180')}px;
    height: 30px;
    left: 0px;
    background: ${(props) => workflowVersionChangesColor(props)};
    border-radius: 4px;
    font-size: ${FontSize.SMALL_12};
    padding: 0px;

    opacity: ${(props) => (props.disabled ? '0.5' : 'unset')};
    cursor: ${(props) => (props.disabled ? 'not-allowed !important' : 'pointer')};

    &:focus,
    &:hover {
        background-color: ${(props) => workflowVersionChangesColor(props)};
        text-decoration: none;
        color: ${Theme.colors.white};
    }
`;

const Chip = styled.div<{ $workflowVersionChanges: number; hasPermissions: boolean }>`
    position: absolute;
    border-radius: 100px;
    background: ${(props) => workflowVersionChangesColor(props)};
    opacity: ${(props) => (!props.hasPermissions ? '0.5' : 'none')};
    font-size: ${FontSize.XSMALL_10};
    width: 21px;
    height: 21px;
    text-align: center;
    margin-left: 169px;
    margin-top: -10px;
    padding-top: 2px;
    color: white;
    border: 1px solid ${Theme.colors.white};
`;

const StyledOpenMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 0;
`;

const StyledOpenMenuTextContainer = styled.div`
    width: 147px;
    height: 30px;
`;

const StyledOpenMenuText = styled.span`
    height: 16px;
    font-weight: 700;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Theme.colors.white};
    order: 0;
    margin: 7px 27px 7px 27px;
`;

const Spacer = styled.span`
    width: 0px;
    height: 30px;
    border: 1px solid ${Theme.colors.white};
    transform: rotate(180deg);
    flex: none;
    order: 1;
    flex-grow: 0;
    opacity: 0.5;
`;

const StyledOpenMenuIconContainer = styled.div`
    width: 32px;
    height: 30px;
    order: 2;
`;

const StyledOpenMenuIcon = styled(OpenMenuIcon)`
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
`;

const workflowVersionChangesColor = (props) => {
    return !props.$workflowVersionChanges || props.$workflowVersionChanges < 20
        ? Theme.colors.warning
        : Theme.colors.error;
};

const StyledButton = styled(Button)<{ $isPublishReady: boolean }>`
    width: ${(props) => (props.$isPublishReady ? '170' : '150')}px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 22px;
    gap: 10px;
    background: ${Theme.colors.successHover};
    border-radius: 4px;
    color: ${Theme.colors.basicBackground};
    font-weight: 700;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    opacity: ${(props) => (props.$isPublishReady ? '0.5' : 'unset')};
    cursor: ${(props) => (props.$isPublishReady ? 'not-allowed !important' : 'pointer')};

    &:focus,
    &:hover {
        background-color: ${Theme.colors.successHover};
        color: ${Theme.colors.basicBackground};
    }
`;

interface Props {
    group: Group;
    draftWorkflowVersion: WorkflowVersion;
    workflowVersionChanges: number;
    draftWorkflowVersionChanges: number;
    validateLogics: () => Promise<any>;
    hasPermissions: boolean;
    onRevertWorkflowVersionClicked: () => void;
    showButtonBackToMarkForPublish: () => void;
    onSuccessfulPublish: () => void;
    validateLogicsTooltipError: string | undefined;
    isPublishReady: boolean;
    onReadyToPublishWorkflowClicked: (componentsAreValidated) => void;
    onDiscardPendingChangesModalOpened: () => void;
}

const ModuleActions: React.FC<Props> = ({
    group,
    draftWorkflowVersion,
    workflowVersionChanges,
    draftWorkflowVersionChanges,
    validateLogics,
    hasPermissions,
    onRevertWorkflowVersionClicked,
    showButtonBackToMarkForPublish,
    onSuccessfulPublish,
    validateLogicsTooltipError,
    isPublishReady,
    onReadyToPublishWorkflowClicked,
    onDiscardPendingChangesModalOpened,
}) => {
    const modalUtils = useAngularService('modalUtils');
    const projectManager = useAngularService('projectManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const [open, toggleOpen, setOpen] = useToggle(false);
    const [markForPublishTooltipText, setMarkForPublishTooltipText] = useState<string>();
    const [useOldPublish, setUseOldPublish] = useState<boolean>(false);

    useEffect(() => {
        const groupsMap = projectManager.groupsMap[group.id];
        setUseOldPublish(!!groupsMap.notVisibleToMakers);
    }, [group, projectManager.groupsMap]);

    useEffect(() => {
        if (isPublishReady) {
            setMarkForPublishTooltipText(
                'This module is ready for publishing. navigate to the solution page to finish publishing the solution.',
            );
        } else {
            setMarkForPublishTooltipText(undefined);
        }
    }, [isPublishReady, draftWorkflowVersionChanges, useOldPublish, workflowVersionChanges]);

    const discardPendingChanges = () => {
        if (workflowVersionChanges === 0) {
            return;
        }

        onDiscardPendingChangesModalOpened();

        modalUtils.openRevertDraftVersionModal(group.id)?.then((successfulRevert) => {
            if (successfulRevert && onRevertWorkflowVersionClicked) {
                onRevertWorkflowVersionClicked();
            }
        });
    };

    const createVersionClicked = () => {
        if (validateLogicsTooltipError) {
            setOpen(true);
            return;
        }

        validateLogics()
            .then(() => {
                modalUtils.openWorkflowVersionActionModal(group.id, undefined, 'isCreateVersion', undefined);
            })
            .catch(() => {
                setOpen(true);
            });
    };

    const viewPendingChangesClicked = () => {
        modalUtils.openWorkflowVersionChangesModal(
            group.id,
            draftWorkflowVersion.id,
            'Pending Changes',
            'Below are the changes that will be included on the next published version of the module',
            null,
            true,
            false,
            null,
            undefined,
        );
    };

    const [subWorkflowVersionChanges] = useAngularWatch(() => {
        return workflowVersionManager.subWorkflowVersionIdToChangesCounterMap[draftWorkflowVersion.id] ?? 0;
    });

    const [workflowVersionChangesCounterError] = useAngularWatch(() => {
        return workflowVersionManager.workflowVersionIdToChangesCounterError[draftWorkflowVersion.id];
    });

    const isReadyToPublishDisabled = useMemo(() => {
        const hasChanges =
            workflowVersionChanges !== 0 || subWorkflowVersionChanges !== 0 || workflowVersionChangesCounterError;

        const disabled =
            !hasPermissions || !(draftWorkflowVersion.workflowVersionType === 'DRAFT') || !hasChanges || isPublishReady;

        return disabled;
    }, [
        workflowVersionChanges,
        subWorkflowVersionChanges,
        workflowVersionChangesCounterError,
        hasPermissions,
        draftWorkflowVersion.workflowVersionType,
        isPublishReady,
    ]);

    const markForPublishClicked = () => {
        if (isReadyToPublishDisabled) {
            return;
        }

        validateLogics()
            .then(() => {
                const action = useOldPublish ? 'useOldPublish' : 'markReadyToPublish';

                return modalUtils.openWorkflowVersionActionModal(
                    group.id,
                    useOldPublish ? onSuccessfulPublish : undefined,
                    action,
                    showButtonBackToMarkForPublish,
                )?.result;
            })
            .then(() => {
                // If we're here, that means we successfully validated our logics
                // and returned from the modal, so we can notify on it.
                if (onReadyToPublishWorkflowClicked) {
                    onReadyToPublishWorkflowClicked(true);
                }
            })
            .catch(() => {
                // Otherwise, if we got here that means the components aren't validated.
                if (onReadyToPublishWorkflowClicked) {
                    onReadyToPublishWorkflowClicked(false);
                }
                setOpen(true);
            });
    };

    if (workflowVersionChanges === 0) {
        return (
            <>
                {subWorkflowVersionChanges > 0 && (
                    <Tooltip content={markForPublishTooltipText} disabled={!markForPublishTooltipText} placement="top">
                        <StyledButton
                            $isPublishReady={isPublishReady}
                            onClick={markForPublishClicked}
                            disabled={!!markForPublishTooltipText}
                            data-automation="module-actions-mark-for-publish-button"
                        >
                            {useOldPublish ? 'Publish' : isPublishReady ? 'Marked For Publish' : 'Mark for Publish'}
                        </StyledButton>
                    </Tooltip>
                )}
            </>
        );
    } else {
        return (
            <Menu
                show={open}
                onClose={() => toggleOpen()}
                placement="bottom-start"
                menuItems={
                    <>
                        <Tooltip
                            content={
                                validateLogicsTooltipError ??
                                'A new version can only be created after changes have been made to the module'
                            }
                            disabled={!validateLogicsTooltipError && workflowVersionChanges > 0}
                            placement="right"
                        >
                            <StyledMenuItem
                                icon={<CreateVersionIcon />}
                                onClick={createVersionClicked}
                                disabled={!!validateLogicsTooltipError || workflowVersionChanges === 0}
                            >
                                <StyledCreateVersionContainer>
                                    <span>Create Version</span>
                                    <StyledCreateVersionCommand>Ctrl+D</StyledCreateVersionCommand>
                                </StyledCreateVersionContainer>
                            </StyledMenuItem>
                        </Tooltip>
                        <Tooltip
                            content="No pending changes available"
                            disabled={workflowVersionChanges > 0}
                            placement="right"
                        >
                            <StyledMenuItem
                                data-automation="module-action-view-pending-changes"
                                icon={<ViewChangesIcon />}
                                onClick={viewPendingChangesClicked}
                                disabled={workflowVersionChanges === 0}
                            >
                                View Pending Changes
                            </StyledMenuItem>
                        </Tooltip>
                        <Tooltip
                            content="No pending changes available"
                            disabled={workflowVersionChanges > 0}
                            placement="right"
                        >
                            <StyledMenuItem
                                data-automation="module-action-discard-pending-changes"
                                icon={<DiscardIcon />}
                                onClick={discardPendingChanges}
                                disabled={workflowVersionChanges === 0}
                            >
                                Discard Pending Changes
                            </StyledMenuItem>
                        </Tooltip>
                    </>
                }
            >
                <StyledOpeningMenuItem disabled={!hasPermissions} $workflowVersionChanges={workflowVersionChanges}>
                    {workflowVersionChanges > 0 && (
                        <Chip
                            $workflowVersionChanges={workflowVersionChanges}
                            hasPermissions={hasPermissions}
                            data-automation="publish-version-pending-changes-indication-chip"
                        >
                            {workflowVersionChanges}
                        </Chip>
                    )}
                    <Tooltip content="You need to be a maker to be able to make changes" disabled={!!hasPermissions}>
                        <StyledOpenMenuContainer>
                            <Tooltip
                                content={validateLogicsTooltipError}
                                disabled={!validateLogicsTooltipError}
                                placement="top"
                            >
                                <StyledOpenMenuTextContainer
                                    onClick={createVersionClicked}
                                    data-automation="module-actions-create-version"
                                >
                                    <StyledOpenMenuText>
                                        {!validateLogicsTooltipError ? 'Create Version' : 'View Changes'}
                                    </StyledOpenMenuText>
                                </StyledOpenMenuTextContainer>
                            </Tooltip>
                            <Spacer />
                            <StyledOpenMenuIconContainer
                                data-automation="module-action-open-version-option"
                                onClick={() => toggleOpen()}
                            >
                                <StyledOpenMenuIcon />
                            </StyledOpenMenuIconContainer>
                        </StyledOpenMenuContainer>
                    </Tooltip>
                </StyledOpeningMenuItem>
            </Menu>
        );
    }
};

export default ModuleActions;
