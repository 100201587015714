import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SlackAppCustomActionsDefinitionCtrl($scope, utils, tonkeanService) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        validationObject: ctrl.validationObject,
        onDefinitionChanged: ctrl.onDefinitionChanged,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        userSelectionConfiguration: null,
        existingUserSelectionConfiguration: null,
        disableOverrideUserGroupUsers: false,
        disableUserGroupUsersFields: false,
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        if ($scope.data.definition && utils.isNullOrUndefined($scope.data.definition.isPrivate)) {
            $scope.data.definition.isPrivate = false;
        }

        if ($scope.data.configuredLogic && $scope.data.definition) {
            $scope.data.loading = true;
            $scope.refreshUsers().then(() => {
                initializeEditMode();
                if ($scope.data.definition.selectedUser && $scope.data.definition.selectedUser.personIds) {
                    // Since the component use a single select we handle only the 1st in personIds array
                    tonkeanService
                        .getExternalActivityById($scope.data.projectIntegration.projectId, $scope.data.projectIntegration.id, $scope.data.definition.selectedUser.personIds[0], true)
                        .then((personData) => {
                            if (personData) {
                                $scope.data.users = [...$scope.data.users, personData]
                            }
                            $scope.data.loading = false;
                        });
                } else {
                    $scope.data.loading = false;
                }
            });
        }
    };

    function initializeEditMode() {
        if (
            ctrl.definition &&
            (ctrl.definition.customActionKey === 'INVITE_USERS_TO_CHANNEL' ||
                ctrl.definition.customActionKey === 'REMOVE_USER_FROM_CONVERSATION') &&
            ctrl.definition.selectedUser
        ) {
            $scope.data.existingUserSelectionConfiguration = {
                personEmailFieldDefinitionId: ctrl.definition.selectedUser.personEmailFieldDefinitionId,
                personEmailExpressionDefinition: ctrl.definition.selectedUser.personEmailExpressionDefinition,
                specificPeopleIds: ctrl.definition.selectedUser.personIds,
                initiativeOwner: ctrl.definition.selectedUser.initiativeOwner,
                previousActor: ctrl.definition.selectedUser.previousActor,
                useNone: ctrl.definition.selectedUser.useNone,
                failIfNoValue: ctrl.definition.selectedUser.failIfNoValue,
            };
        }
    }

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = false;
            }
        }
        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.channelName = originalExpression;
        $scope.data.definition.evaluatedChannelNameExpression = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onChannelTopicExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.channelTopicExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMessageReactionExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.messageReactionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of is private toggled.
     */
    $scope.onIsPrivateToggled = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.onPerformingUserSelected = function (userSelectionConfiguration, shouldSaveLogic) {
        $scope.data.userSelectionConfiguration = userSelectionConfiguration;

        const data = {
            personEmailExpressionDefinition: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
            personIds: [],
        };

        if (userSelectionConfiguration) {
            if (userSelectionConfiguration.personSelectionType === 'fromField') {
                data.personEmailExpressionDefinition = userSelectionConfiguration.fromFieldExpressionDefinition;
            }

            if (
                userSelectionConfiguration.personSelectionType === 'specificPeople' &&
                userSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < userSelectionConfiguration.selectedPeopleIds.length; i++) {
                    data.personIds.push(userSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = userSelectionConfiguration.personSelectionType === 'initiativeOwner';
            data.previousActor = userSelectionConfiguration.personSelectionType === 'previousActor';
            data.useNone = userSelectionConfiguration.personSelectionType === 'none';
            data.failIfNoValue = userSelectionConfiguration.failIfNoValue;
        }

        $scope.data.definition.selectedUser = data;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.refreshUsers = function (q) {
        return tonkeanService
            .getIntegrationExampleEntities($scope.data.projectIntegration.id, 'User', q && q.length ? q : null)
            .then((data) => {
                if (data.exampleEntities && data.exampleEntities.length) {
                    $scope.data.users = data.exampleEntities;
                }
            });
    };

    /**
     * Occurs on update of the actions definition.
     */
    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        if (ctrl.onDefinitionChanged) {
            ctrl.onDefinitionChanged({ shouldSaveLogic });
        }
        $scope.data.disableUserGroupUsersFields =
            $scope.data.definition.userGroupUsersExpression &&
            $scope.data.definition.userGroupUsersExpression.originalExpression !== '';

        $scope.data.disableOverrideUserGroupUsers =
            ($scope.data.definition.userGroupUsersToAddExpression &&
                $scope.data.definition.userGroupUsersToAddExpression.originalExpression !== '') ||
            ($scope.data.definition.userGroupUsersToRemoveExpression &&
                $scope.data.definition.userGroupUsersToRemoveExpression.originalExpression !== '');
    };

    /**
     * User Group
     */
    $scope.onUserGroupNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupDescriptionExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupDescriptionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupHandleExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupHandleExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupChannelsExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupChannelsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupTeamIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupTeamIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupUsersExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupUsersExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupUsersToAddExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupUsersToAddExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onUserGroupUsersToRemoveExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userGroupUsersToRemoveExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onChannelIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.channelIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMessageIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.messageIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('SlackAppCustomActionsDefinitionCtrl', lateConstructController(SlackAppCustomActionsDefinitionCtrl));
