import type { IHttpResponse } from 'angular';
import React from 'react';
import styled from 'styled-components';

import ChainOfEventsSingleEvent from './ChainOfEventsSingleEvent';
import { ChainOfEventsTitle, SubTitle } from './ChainOfEventsTitle';
import { ReactComponent as FlowRunIcon } from '../../../../../images/icons/history/flow-run.svg';

import { Placeholder, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import { range } from '@tonkean/utils';

const FlowRunPlaceHolderIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FlowRunPlaceHolderIcon = styled(FlowRunIcon)`
    margin-right: 5px;
`;

interface Props {
    error: IHttpResponse<any> | undefined;
    isEmpty: boolean;
    isLoading: boolean;
}

const ChainOfEventsStates: React.FC<Props> = ({ error, isEmpty, isLoading }) => {
    return (
        <>
            <ChainOfEventsTitle>
                Chain of Events
                {error && ' - Error'}
                {isEmpty && (
                    <>
                        {' - No Events'} <SubTitle>In current time range</SubTitle>
                    </>
                )}
            </ChainOfEventsTitle>

            <ChainOfEventsSingleEvent workerRunStage={WorkerRunStage.DATA_SOURCE} showTitle={!error} isDisabled>
                {error && <SimpleErrorStateMessage error={error} showSmallError />}
                {isLoading && <Placeholder $width="200px" />}
            </ChainOfEventsSingleEvent>

            <ChainOfEventsSingleEvent workerRunStage={WorkerRunStage.MODULE_ITEM} showTitle={!error} isDisabled>
                {isLoading && <Placeholder $width="200px" />}
            </ChainOfEventsSingleEvent>

            <ChainOfEventsSingleEvent
                workerRunStage={WorkerRunStage.FLOW_RUN}
                showTitle={!error}
                isMultiple={isLoading}
                isLast
                isDisabled
            >
                {isLoading &&
                    range(3).map((i) => (
                        <FlowRunPlaceHolderIconContainer key={i}>
                            <FlowRunPlaceHolderIcon />
                            <Placeholder $width="150px" />
                        </FlowRunPlaceHolderIconContainer>
                    ))}
            </ChainOfEventsSingleEvent>
        </>
    );
};

export default ChainOfEventsStates;
