import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CachedPeopleSelectorCtrl($scope, personCache) {
    const ctrl = this;

    $scope.data = {
        selectedPeopleIds: ctrl.selectedPeopleIds,
        paramsObjectForCallbacks: ctrl.paramsObjectForCallbacks,
        maxPeople: ctrl.maxPeople,
        placeholder: ctrl.placeholder,

        selectedPeople: [],
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (ctrl.selectedPeopleIds && ctrl.selectedPeopleIds.length) {
            personCache.getEntitiesByIds(ctrl.selectedPeopleIds).then((people) => {
                $scope.data.selectedPeople = people;
            });
        }
    };

    /**
     * Occurs once a person is selected.
     */
    $scope.onPersonAdded = function () {
        if (ctrl.onPersonSelected) {
            ctrl.onPersonSelected({
                people: $scope.data.selectedPeople,
                paramsObject: $scope.data.paramsObjectForCallbacks,
            });
        }
    };

    /**
     * Occurs once the selected person is removed.
     */
    $scope.onPersonRemoved = function () {
        if (ctrl.onPersonRemoved) {
            ctrl.onPersonRemoved({
                people: $scope.data.selectedPeople,
                paramsObject: $scope.data.paramsObjectForCallbacks,
            });
        }
    };
}

export default angular.module('tonkean.app').controller('CachedPeopleSelectorCtrl', lateConstructController(CachedPeopleSelectorCtrl));
