import AmazonKinesisComponent from './AmazonKinesisComponent';
import amazonKinesisImage from '../../../../apps/tracks/images/amazonKinesis.png';
import amazonKinesisCircleImage from '../../../../apps/tracks/images/integrations/amazon-kinesis-circle.svg';
import AmazonS3AuthenticationComponent from '../amazonS3/AmazonS3AuthenticationComponent';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class AmazonKinesisIntegration extends IntegrationDefinitionBase {
    override name = 'amazonkinesis';

    override displayName = 'Amazon Kinesis';
    override description = 'We will collect the records of your chosen streams.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override customizedSetupComponent = AmazonKinesisComponent;

    override entities = [new IntegrationEntity('Record', 'Records')];
    override dataSourceSectionType = DataSourcesSectionType.DATABASE;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(amazonKinesisCircleImage, '130px'),
        new IntegrationIcon(amazonKinesisImage, '130px'),
    );

    override customizedAuthenticationComponent = AmazonS3AuthenticationComponent;
}

export default AmazonKinesisIntegration;
