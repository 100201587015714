<div class="field-to-update-editor">
    <!-- Field name title -->
    <div
            class="margin-bottom-xs flex-vmiddle mod-justify-space pointer mod-align-start"
            ng-if="!data.isPreselectedField"
    >
        <!-- Title -->
        <div>Field Name:</div>

        <!-- Delete icon -->
        <i ng-if="!data.shouldHideDeleteButton" ng-click="onFieldRemoved()" class="fa fa-trash-o"></i>
    </div>

    <!-- Field selection -->
    <div class="margin-bottom" ng-if="!data.isPreselectedField">
        <tnk-field-selector
                class="filter-min-width"
                selected-field="data.fullFieldObject"
                items-source="COLUMN"
                group-id="{{ data.fieldSelectorGroupId }}"
                workflow-version-id="{{ data.fieldSelectorWorkflowVersionId }}"
                project-integration="scm.getSyncConfig(data.fieldSelectorWorkflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.fieldSelectorWorkflowVersionId).viewData.entityMetadata.entity"
                reload-fields="data.reloadFields"
                display-integration-indication="true"
                only-updatable-field-definitions="true"
                add-fields-quick-create-options="true"
                group-by="groupBy"
                fields-filter="noMatchedEntityFields"
                auto-create-column-fields="true"
                special-fields-for-features="data.specialFieldsForFeatures"
                place-holder="Search data field"
                on-field-selected="onFieldOptionSelected(selectedField)"
                do-not-include-field-ids-set="data.doNotIncludeFieldIdsSet"
                data-automation="field-to-update-editor-open-field-drop-down"
        ></tnk-field-selector>
    </div>

    <!-- Value title -->
    <div class="margin-bottom-xs">{{ data.isPreselectedField ? data.preselectedFieldLabel : 'Value:' }}</div>

    <div class="flex flex-vmiddle common-normal-weight margin-top-xs">
        <!-- Manual -->
        <div class="margin-top-xs" ng-if="data.configuredField.speciallyHandledField">
            <tnk-radio-button
                    radio-id="manual-input-{{ data.fieldSelectorWorkflowVersionId }}"
                    radio-name="input-type-selection-{{ data.configuredField.fieldId }}-{{
                    data.fieldSelectorWorkflowVersionId
                }}"
                    radio-value="MANUAL"
                    data-automation="field-to-update-editor-manual-option"
                    model="data.configuredField.inputTypeSelection"
                    on-click="selectInputType(onClickParam)"
                    on-click-param="('MANUAL')"
                    is-required="true"
            >
                <div>Manual</div>
            </tnk-radio-button>
        </div>

        <!-- Expression -->
        <div class="margin-top-xs margin-left-xs flex-no-shrink">
            <tnk-radio-button
                    radio-id="expression-input-{{ data.fieldSelectorWorkflowVersionId }}"
                    radio-name="input-type-selection-{{ data.configuredField.fieldId }}-{{
                    data.fieldSelectorWorkflowVersionId
                }}"
                    radio-value="EXPRESSION"
                    data-automation="field-to-update-editor-expression-or-value-option"
                    model="data.configuredField.inputTypeSelection"
                    on-click="selectInputType(onClickParam)"
                    on-click-param="('EXPRESSION')"
                    is-required="true"
            >
                <div>{{ data.configuredField.speciallyHandledField ? 'Expression' : 'Value' }}</div>
            </tnk-radio-button>
        </div>

        <!-- Empty Value -->
        <div class="margin-top-xs margin-left-xs flex-no-shrink" ng-if="data.configuredField.fieldId !== 'TNK_STAGE'">
            <tnk-radio-button
                    radio-id="empty-value-input-{{ data.fieldSelectorWorkflowVersionId }}"
                    radio-name="input-type-selection-{{ data.configuredField.fieldId }}-{{
                    data.fieldSelectorWorkflowVersionId
                }}"
                    radio-value="EMPTY_VALUE"
                    model="data.configuredField.inputTypeSelection"
                    on-click="selectInputType(onClickParam)"
                    on-click-param="('EMPTY_VALUE')"
                    is-required="true"
            >
                <div>Empty Value</div>
            </tnk-radio-button>
        </div>

        <!-- Multiple Values -->
        <div
                class="margin-top-xs margin-left-xs flex-no-shrink inline-block"
                ng-if="
                !formulaSpecialFieldIdToDefinitionMap[data.configuredField.fieldId] &&
                !data.configuredField.speciallyHandledField
            "
        >
            <tnk-radio-button
                    radio-id="empty-value-input-{{ data.fieldSelectorWorkflowVersionId }}"
                    radio-name="input-type-selection-{{ data.configuredField.fieldId }}-{{
                    data.fieldSelectorWorkflowVersionId
                }}"
                    radio-value="MULTIPLE_VALUES"
                    model="data.configuredField.inputTypeSelection"
                    on-click="selectInputType(onClickParam)"
                    on-click-param="('MULTIPLE_VALUES')"
                    is-required="true"
            >
                <div>Multiple Values</div>
            </tnk-radio-button>
        </div>
    </div>

    <!-- Owner input -->
    <tnk-single-person-selector
            ng-if="data.configuredField.fieldId === 'TNK_OWNER_ID' && data.configuredField.inputTypeSelection === 'MANUAL'"
            selected-person-id="data.configuredField.ownerValue.ownerId"
            on-person-selected="ownerSelected(person)"
            on-person-removed="ownerRemoved()"
            include-external="true"
    ></tnk-single-person-selector>

    <!-- User input -->
    <tnk-multiple-person-selector-by-email
            ng-if="data.fullFieldObject.displayFormat === 'USER' && data.configuredField.inputTypeSelection === 'MANUAL'"
            selected-emails="data.configuredField.userValue"
            on-update="userUpdated(emails)"
    ></tnk-multiple-person-selector-by-email>

    <!-- Date input -->
    <div
            class="flex-vmiddle"
            ng-if="
            (data.fullFieldObject.type === 'Date' || data.fullFieldObject.fieldType === 'Date') &&
            data.configuredField.inputTypeSelection === 'MANUAL'
        "
    >
        <input
                class="form-control"
                type="number"
                ng-model="data.configuredField.dateValue.daysFromExecutionCurrentTime"
                ng-change="onDaysFromExecutionCurrentTimeChanged()"
        />
        <div class="common-size-xxxs margin-left-xs flex-no-shrink">Days from Trigger match</div>
    </div>

    <!-- Status input -->
    <ui-select
            theme="bootstrap"
            ng-if="data.configuredField.fieldId === 'TNK_STAGE' && data.configuredField.inputTypeSelection === 'MANUAL'"
            ng-model="data.configuredField.statusValue"
            ng-change="onStateChanged()"
    >
        <ui-select-match data-automation="field-to-update-editor-open-status-drop-down" placeholder="Search...">
            <div class="flex mod-align-baseline">
                <i
                        class="fa fa-square margin-right-xs"
                        ng-style="{
                        color: wvm.getCachedWorkflowVersion(data.fieldSelectorWorkflowVersionId).stateIdToStateMap[
                            data.configuredField.statusValue.id
                        ].color
                    }"
                ></i>
                <span>
                    {{
                    wvm.getCachedWorkflowVersion(data.fieldSelectorWorkflowVersionId).stateIdToStateMap[
                            data.configuredField.statusValue.id
                            ].label
                    }}
                </span>
            </div>
        </ui-select-match>
        <ui-select-choices
                repeat="p in wvm.getCachedWorkflowVersion(data.fieldSelectorWorkflowVersionId).states | filter:$select.search"
                group-by="statusesGroupByType"
        >
            <div class="flex-vmiddle">
                <i class="fa fa-square margin-right" ng-style="{ color: p.color }"></i>
                <div
                        data-automation="field-to-update-editor-status-dropdown-option"
                        ng-bind-html="p.label | highlight: $select.search"
                ></div>
            </div>
        </ui-select-choices>
    </ui-select>

    <!-- Expression input -->
    <tnk-tonkean-expression
            ng-if="data.configuredField.inputTypeSelection === 'EXPRESSION' && data.expressionGroupId"
            class="flex-grow"
            group-id="{{ data.expressionGroupId }}"
            workflow-version-id="{{ data.expressionWorkflowVersionId }}"
            logic-id="{{ data.logicId }}"
            saved-original-expression="data.configuredField.expressionValue.originalExpression"
            saved-evaluated-expression="data.configuredField.expressionValue.evaluatedExpression"
            on-tonkean-expression-changed="onFieldValueTonkeanExpressionChanged(expression, shouldSaveLogic)"
            automation-identifier-expression-value="field-to-update-editor-expression-value"
            automation-identifier-expression-add-field="field-to-update-editor-expression-add-field"
            preview-evaluation-source="data.previewEvaluationSource"
            global-expression-only="data.globalExpressionOnly"
    ></tnk-tonkean-expression>

    <!-- Multiple Values -->
    <div
            class="flex mod-align-start"
            ng-if="
            data.configuredField.inputTypeSelection === 'MULTIPLE_VALUES' &&
            !utils.isEmpty(data.multipleInputExpressionsValues)
        "
            ng-repeat="inputExpression in data.multipleInputExpressionsValues track by $index"
    >
        <tnk-tonkean-expression
                class="block margin-bottom-xs flex-grow"
                group-id="{{ data.expressionGroupId }}"
                workflow-version-id="{{ data.expressionWorkflowVersionId }}"
                logic-id="{{ data.logicId }}"
                saved-original-expression="inputExpression.originalExpression"
                saved-evaluated-expression="inputExpression.evaluatedExpression"
                automation-identifier-expression-value="field-to-update-editor-multiple-value-expression-value"
                automation-identifier-expression-add-field="field-to-update-editor-multiple-value-expression-add-field"
                on-tonkean-expression-changed="
                onFieldMultipleValueTonkeanExpressionChanged(expression, shouldSaveLogic, $index)
            "
                preview-evaluation-source="data.previewEvaluationSource"
                global-expression-only="data.globalExpressionOnly"
        ></tnk-tonkean-expression>

        <!-- Display remove only when at least two values are saved -->
        <div
                class="margin-left-xs pointer margin-top-xs"
                ng-if="
                data.configuredField.expressionMultipleValue.originalExpression.includes(data.multipleValuesSeparator)
            "
                ng-click="deleteValueFromField($index)"
        >
            <i class="fa fa-trash-o"></i>
        </div>
    </div>

    <a
            class="inline-block margin-top-xs"
            ng-click="addValueToField()"
            ng-if="data.configuredField.inputTypeSelection === 'MULTIPLE_VALUES'"
    >
        + Add Another Value
    </a>

    <!-- Empty value explanation -->
    <div ng-if="data.configuredField.inputTypeSelection === 'EMPTY_VALUE'" class="margin-top">
        The value of the field will be cleared.
    </div>
</div>
