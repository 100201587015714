import { useCallback, useEffect, useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useStreamServiceImage = (
    privateUrl: string | undefined,
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION> | undefined,
    projectId: TonkeanId<TonkeanType.PROJECT> | undefined,
    imageExternalId: string | undefined,
    actionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined,
    retries: number = 8,
) => {
    const [{ data: imageStream }, getImageStreamResponseRequest] = useLazyTonkeanService('getImageStream');
    const [{ data: signedUrlResponse }, getImagePreSignedUrlResponseRequest] =
        useLazyTonkeanService('createImageStreamUrl');

    useEffect(() => {
        if (!!privateUrl && !!imageExternalId && !!projectIntegrationId && !!projectId && !!actionId) {
            getImagePreSignedUrlResponseRequest(projectIntegrationId, projectId, privateUrl, imageExternalId, actionId);
        }
    }, [actionId, getImagePreSignedUrlResponseRequest, imageExternalId, privateUrl, projectId, projectIntegrationId]);

    const fetchDataWithRetries = useCallback(
        async (retriesLeft: number, signedUrl: string) => {
            try {
                await getImageStreamResponseRequest(signedUrl);
            } catch {
                if (retriesLeft > 0) {
                    // Retry the request
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    fetchDataWithRetries(retriesLeft - 1, signedUrl);
                }
            }
        },
        [getImageStreamResponseRequest],
    );

    const objectUrl = useMemo(() => {
        if (imageStream) {
            return URL.createObjectURL(new Blob([imageStream.data]));
        }

        return undefined;
    }, [imageStream]);

    useEffect(() => {
        if (!!signedUrlResponse?.streamImageUrl) {
            fetchDataWithRetries(retries, signedUrlResponse.streamImageUrl);
        }
    }, [fetchDataWithRetries, retries, signedUrlResponse?.streamImageUrl]);

    return objectUrl;
};
export default useStreamServiceImage;
