<div>
    <div><strong class="common-bold common-size-xxxs">Custom Attributes Requirements</strong></div>

    <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs common-size-xxxxs">
        <li>
            <div class="padding-left-xxs">If any attribute does not already exist, it will be created.</div>
        </li>
        <li>
            <div class="padding-left-xxs">
                Attributes keys must not contain Periods ('.') or Dollar ('$') characters
            </div>
        </li>
    </ul>
</div>
