import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

const getCellEditor = (fieldDefinition: FieldDefinition) => {
    let cellEditorComponent = 'agTextCellEditor';
    switch (fieldDefinition.fieldType) {
        case FieldType.List:
            cellEditorComponent = 'editableCustomFieldCellRenderer';
            break;
        case FieldType.Number:
            cellEditorComponent = 'agNumberCellEditor';
            break;
        case FieldType.Date:
            cellEditorComponent = 'agDateCellEditor';
            break;
        case FieldType.LongString:
            cellEditorComponent = 'agLargeTextCellEditor';
            break;
    }
    return cellEditorComponent;
};

export default getCellEditor;
