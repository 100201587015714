import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class RegexFindFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.REGEX_FIND;
    override readonly sign = 'RegexFind';
    override readonly displayName = 'Regex Find';
    override readonly description =
        'Use regex find in order to create patterns that extracts text. The pattern will run on the input and will be stored in this field.';
    override readonly dataType = FieldType.String;

    override readonly specificEditor = true;

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input String',
            explanation: 'The string against which to match the regular expression',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Regular expression',
            explanation: 'Pattern to match by',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Requested Groups',
            explanation: 'For multiple groups, separate by commas. 0 means full match',
            dataType: [FieldType.String, FieldType.Number],
            defaultValue: 0,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Requested Matches',
            explanation: 'For multiple matches, separate by commas. 0 means all matches',
            dataType: [FieldType.String, FieldType.Number],
            defaultValue: 1,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Groups separator',
            explanation: 'Will separate groups in a match if more than one requested.',
            dataType: FieldType.String,
            defaultValue: '-',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
    ];
}
