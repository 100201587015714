<div class="search-initiatives-configuration flex-vmiddle">
    <ui-select
        class="flex-grow ui-select-min-width"
        ng-class="{ 'margin-right-xs': !data.canClear }"
        theme="bootstrap"
        ng-change="onInitiativeSelected(data.selectedSimplifiedInitiative)"
        ng-model="data.selectedSimplifiedInitiative"
    >
        <ui-select-match
            class="ui-select-match-ellipsis-inside"
            placeholder="Search item..."
            tooltip-enable="utils.hasEllipsis('.search-initiatives-configuration .ui-select-match-ellipsis-inside .ui-select-match-text > span')"
            uib-tooltip="{{ data.selectedSimplifiedInitiative.displayTitle }}"
        >
            {{ data.selectedSimplifiedInitiative.displayTitle }}
        </ui-select-match>
        <!-- refresh is the function to refresh options while the user is typing. -->
        <!-- refresh-delay is 0 because our refresh function does the throttling itself (ui-select doesn't do a good job). -->
        <ui-select-choices
            repeat="initiative in data.simplifiedInitiatives | filter:$select.search"
            refresh="searchInitiativesThrottled($select.search)"
            refresh-delay="0"
            ng-click="stopPropagation($event)"
        >
            <div class="flex-vmiddle">
                <span>{{ initiative.displayTitle }}</span>
            </div>
        </ui-select-choices>
    </ui-select>

    <button
        ng-if="data.canClear"
        ng-click="clearInitiative()"
        class="btn btn-no-border svg-icon svg-icon-vmiddle common-color-light-grey margin-right-xs"
        uib-tooltip="Clear"
        ng-class="{ 'search-initiatives-cancel--form-builder': data.appendToBody }"
    >
        <tnk-icon src="../../../../svg/src/icons/x.svg"></tnk-icon>
    </button>

    <!-- Error-->
    <div class="common-color-danger common-size-xxxs" ng-if="!data.loadingInitiatives && data.errorLoadingInitiatives">
        {{ data.errorLoadingInitiatives }}
    </div>
</div>
