import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useMemo } from 'react';

import usePollGroupsByIds from './usePollGroupsByIds';

import { analyticsWrapper } from '@tonkean/analytics';
import { useAsyncMethod, useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { SolutionSite, TonkeanId, TonkeanType, UpdateSolutionSiteParams } from '@tonkean/tonkean-entities';
import { BusinessAppsAnalyticsEvents, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';
import type { Either } from '@tonkean/utils';

type SolutionSiteContextLoadedType = {
    solutionSite: SolutionSite;

    // using these props to unify the type access but declare that the site must exist
    loading: false;
    error: undefined;
};

type SolutionSiteContextNotLoadedType = { solutionSite: undefined } & (
    | { loading: true; error: undefined }
    | { loading: false; error: IHttpResponse<any> }
);

export type SolutionSiteLoadingType = SolutionSiteContextLoadedType | SolutionSiteContextNotLoadedType;

export type SolutionSiteContextType = {
    solutionSiteReader: () => SolutionSite;

    updateSolutionSite: (params: UpdateSolutionSiteParams) => Promise<SolutionSite>;
    updateSolutionSiteResponse: {
        loading: boolean;
        error?: ErrorResponseType;
    };
    addGroupsToPoll: {
        draft: (groupIds: TonkeanId<TonkeanType.GROUP>[]) => void;
        published: (groupIds: TonkeanId<TonkeanType.GROUP>[]) => void;
    };

    workflowVersionType: WorkflowVersionType;
};
export const SolutionSiteContext = React.createContext<SolutionSiteContextType | undefined>(undefined);

type Params = Either<
    { workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER> },
    { solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE> }
>;

export const useBuildSolutionSiteContext = ({ workflowFolderId, solutionSiteId }: Params) => {
    const tonkeanService = useAngularService('tonkeanService');

    const fetcher = useMemo(
        () => ({
            getSolutionSiteByIdOrWorkflowFolderId(
                innerWorkflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER> | undefined,
                innerSolutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE> | undefined,
            ) {
                if (innerWorkflowFolderId) {
                    return tonkeanService.getSolutionSiteByWorkflowFolderId(innerWorkflowFolderId);
                }

                if (innerSolutionSiteId) {
                    return tonkeanService.getSolutionSiteById(innerSolutionSiteId);
                }

                throw new Error("no id provided, can't fetch solution site");
            },
        }),
        [tonkeanService],
    );
    const {
        data: solutionSite,
        loading,
        error,
        reader,
        rerun,
    } = useAsyncMethod(fetcher, 'getSolutionSiteByIdOrWorkflowFolderId', workflowFolderId, solutionSiteId);

    const [{ loading: loadingUpdateSolutionSite, error: errorUpdateSolutionSite }, updateSolutionSiteInner] =
        useLazyTonkeanService('updateSolutionSite');

    const updateSolutionSite = useCallback(
        (solutionSiteParams: UpdateSolutionSiteParams) => {
            analyticsWrapper.track(BusinessAppsAnalyticsEvents.UPDATE_APP, {
                solutionSiteId: solutionSiteParams.id,
                solutionSiteDisplayName: solutionSiteParams.displayName,
                solutionSiteDescription: solutionSiteParams.description,
                solutionSiteUrlSlug: solutionSiteParams.urlSlug,
                solutionSiteThemeConfiguration: solutionSiteParams.themeConfiguration,
            });

            return updateSolutionSiteInner(
                solutionSiteParams.id,
                solutionSiteParams.displayName,
                solutionSiteParams.description ?? '',
                solutionSiteParams.urlSlug,
                solutionSiteParams.themeConfiguration.headerBackgroundColor,
                solutionSiteParams.themeConfiguration.icon,
                solutionSiteParams.themeConfiguration.showLogo,
                solutionSiteParams.mainActionFormId,
                solutionSiteParams.mainActionLabel,
                solutionSiteParams.homepageEnabled,
                solutionSiteParams.configuration,
            ).then(() => {
                rerun();
                return fetcher.getSolutionSiteByIdOrWorkflowFolderId(workflowFolderId, solutionSiteId);
            });
        },
        [fetcher, rerun, solutionSiteId, updateSolutionSiteInner, workflowFolderId],
    );

    const { addGroupIdsToPoll: addDraftGroupIdsToPoll } = usePollGroupsByIds(WorkflowVersionType.DRAFT);
    const { addGroupIdsToPoll: addPublishedGroupIdsToPoll } = usePollGroupsByIds(WorkflowVersionType.PUBLISHED);
    const context = useMemo(() => {
        const solutionSiteResponse: SolutionSiteLoadingType = getTypedSolutionSiteResponse(
            solutionSite,
            loading,
            error,
        );

        return {
            solutionSiteResponse,
            solutionSiteReader: reader,
            updateSolutionSite,
            addGroupsToPoll: {
                draft: addDraftGroupIdsToPoll,
                published: addPublishedGroupIdsToPoll,
            },
            updateSolutionSiteResponse: {
                loading: loadingUpdateSolutionSite,
                error: errorUpdateSolutionSite,
            },
            workflowVersionType: WorkflowVersionType.DRAFT, // Mock, where are we supposed to get this>
        } as const;
    }, [
        error,
        errorUpdateSolutionSite,
        loading,
        loadingUpdateSolutionSite,
        reader,
        solutionSite,
        updateSolutionSite,
        addDraftGroupIdsToPoll,
        addPublishedGroupIdsToPoll,
    ]);

    return context;
};

function getTypedSolutionSiteResponse(
    solutionSite: SolutionSite | undefined,
    loadingSolutionSite: boolean,
    errorSolutionSite: angular.IHttpResponse<any> | undefined,
): SolutionSiteLoadingType {
    // Making sure that the loading, error and data types align logically
    if (solutionSite) {
        return {
            solutionSite,
            loading: false,
            error: undefined,
        };
    } else if (loadingSolutionSite) {
        return {
            solutionSite,
            loading: loadingSolutionSite,
            error: undefined,
        };
    } else if (errorSolutionSite) {
        return {
            solutionSite: undefined,
            loading: false,
            error: errorSolutionSite,
        };
    } else {
        return {
            solutionSite: undefined,
            loading: true,
            error: undefined,
        };
    }
}

const useSolutionSiteContext = () => {
    const context = useContext(SolutionSiteContext);
    if (!context) {
        throw new Error('useSolutionSiteContext used but no context provided!');
    }

    return { solutionSite: context.solutionSiteReader(), ...context };
};

export default useSolutionSiteContext;
