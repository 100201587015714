import { useAngularService } from 'angulareact';
import { motion } from 'framer-motion';
import type { Variants } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as NotificationIcon } from '../../../../../../images/icons/red-bell.svg';
import { ReactComponent as TonkeanIcon } from '../../../../../../images/icons/tonkean-centered.svg';
import ExampleItemEvaluatedExpression from '../../../../../infrastructure/components/ExampleItemEvaluatedExpression';
import type NotificationPreviewButton from '../../../entities/NotificationPreviewButton';
import type NotificationPreviewField from '../../../entities/NotificationPreviewField';
import useExampleItemId from '../../../hooks/useExampleItemId';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Chevron, ChevronDirection, Portal, Separator, Spacer, useLastDefinedValue } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';

const HEADER_HEIGHT = 40;

const Curtain = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
`;
const Wrapper = styled(motion.article)`
    position: absolute;
    bottom: 0;
    right: 364px; // Side pane width + 4 px margin
    width: 250px;
    background-color: ${Theme.colors.basicBackground};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    pointer-events: auto;
`;
const Header = styled(Clickable)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${HEADER_HEIGHT}px;
    width: 100%;
    padding: 14px;
`;
const HeaderText = styled.h5`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    margin: 0;
    padding: 0;
`;
const HeaderLeft = styled.div`
    margin-left: auto;
`;
const StyledNotificationIcon = styled(NotificationIcon)`
    height: 12px;
    width: 12px;
`;
const Body = styled.main`
    padding: 14px;
    display: flex;
    gap: 8px;
    max-height: 50vh;
    overflow: auto;
`;
const TonkeanLogo = styled.div`
    height: 30px;
    flex: 0 0 30px;
    border-radius: 4px;
    background-color: ${Theme.colors.gray_200};
    display: grid;
    place-items: center;
`;
const Message = styled.div`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const MessageSender = styled.h6`
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    color: ${Theme.colors.gray_800};
    font-weight: 500;
`;
const OnBehalfOfImage = styled.img`
    width: 30px;
    height: 30px;
`;
const MessageBodyWrapper = styled.div`
    display: flex;
    gap: 8px;
`;
const MessageColor = styled.div<{ $color?: string }>`
    width: 2px;
    border-radius: 2px;
    background-color: ${({ $color }) => $color};
`;
const MessageBody = styled.div`
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-line;
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    padding-bottom: 14px;
`;
const MessageButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;
const MessageButton = styled.div`
    padding: 5px;
    border: 1px solid #23930d; // using specific color to mimic old look
    border-radius: 4px;
    color: #23930d; // using specific color to mimic old look
`;

interface Props {
    messageContent: string;
    workflowVersionId: string;
    logicId: string;
    previewEvaluationSource?: any;
    groupId: string;
    color?: string;
    addLinkText?: string;
    fields: NotificationPreviewField[];
    buttons: NotificationPreviewButton[];
    sendOnBehalf: boolean;
}

const EXPRESSION_KEY = 'notification';

const PreviewNotificationPane: React.FC<Props> = ({
    messageContent,
    workflowVersionId,
    logicId,
    previewEvaluationSource,
    groupId,
    color,
    addLinkText,
    fields,
    buttons,
    sendOnBehalf,
}) => {
    const exampleInitiativeId = useExampleItemId(workflowVersionId, logicId);
    const expressions = useMemo(() => [{ expression: messageContent, key: EXPRESSION_KEY }], [messageContent]);
    const projectManager = useAngularService('projectManager');
    const [{ data }, getEvaluatedExpressionsForInitiative] = useLazyTonkeanService(
        'getEvaluatedExpressionsForInitiative',
    );
    const [open, setOpen] = useState(false);

    const projectBoardElement = useMemo(() => document.querySelector('#project-board') as HTMLDivElement, []);

    useEffect(() => {
        getEvaluatedExpressionsForInitiative(projectManager.project.id, exampleInitiativeId, expressions);
    }, [projectManager.project.id, exampleInitiativeId, expressions, getEvaluatedExpressionsForInitiative]);

    // Using last defined value because `useTonkeanService` returns undefined when refetching
    const expression = useLastDefinedValue(data?.evaluatedExpressions[EXPRESSION_KEY]);

    const variants: Variants = {
        up: { height: 'auto' },
        down: { height: HEADER_HEIGHT },
    };

    return (
        <Portal container={projectBoardElement}>
            <Curtain>
                <Wrapper
                    initial={false}
                    variants={variants}
                    animate={open ? 'up' : 'down'}
                    transition={
                        open
                            ? {
                                  type: 'spring',
                                  duration: 0.5,
                                  bounce: 0.2,
                              }
                            : {
                                  ease: 'easeOut',
                              }
                    }
                    layout
                >
                    <Header onClick={() => setOpen((prevOpen) => !prevOpen)}>
                        <StyledNotificationIcon />
                        <Spacer width={8} />
                        <HeaderText>Preview Notification</HeaderText>
                        <HeaderLeft>
                            <Chevron direction={open ? ChevronDirection.DOWN : ChevronDirection.UP} />
                        </HeaderLeft>
                    </Header>
                    <Separator />
                    <Body>
                        {sendOnBehalf ? (
                            <OnBehalfOfImage src="/images/avatars/avatar-1.png" alt="profile" />
                        ) : (
                            <TonkeanLogo>
                                <TonkeanIcon />
                            </TonkeanLogo>
                        )}
                        <Message>
                            <MessageSender>{sendOnBehalf ? 'Person Name' : 'Tonkean Module'}</MessageSender>
                            <MessageBodyWrapper>
                                {color && <MessageColor $color={color} />}
                                <MessageBody>
                                    {expression}
                                    {addLinkText && (
                                        <>
                                            {' | '}
                                            <ExampleItemEvaluatedExpression
                                                expressionToEvaluate={addLinkText}
                                                groupId={groupId}
                                                workflowVersionId={workflowVersionId}
                                                logicId={logicId}
                                                previewEvaluationSource={previewEvaluationSource}
                                                expressionUniqueIdentifier="postText"
                                                color="blue"
                                                hideIcon
                                                isInline
                                            />
                                        </>
                                    )}
                                    {fields.map((field) => (
                                        <div key={field.id}>
                                            <strong>{field.fieldLabel || field.label || field.name}</strong>
                                            <ExampleItemEvaluatedExpression
                                                fieldIdToEvaluate={field.id}
                                                groupId={groupId}
                                                workflowVersionId={workflowVersionId}
                                                logicId={logicId}
                                                previewEvaluationSource={previewEvaluationSource}
                                                expressionUniqueIdentifier={`field${field.id}`}
                                                hideIcon
                                                isInline
                                            />
                                        </div>
                                    ))}
                                    {buttons.length > 0 && (
                                        <MessageButtonsWrapper>
                                            {buttons.map((button) => (
                                                <MessageButton key={button.customTriggerId}>
                                                    {button.text}
                                                </MessageButton>
                                            ))}
                                        </MessageButtonsWrapper>
                                    )}
                                </MessageBody>
                            </MessageBodyWrapper>
                        </Message>
                    </Body>
                </Wrapper>
            </Curtain>
        </Portal>
    );
};

export default PreviewNotificationPane;
