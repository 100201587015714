import { useEffect, useState } from 'react';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';

const useFileUrl = (file: TonkeanUploadedFile | undefined) => {
    const [fileUrl, setFileUrl] = useState<string>();
    useEffect(() => {
        try {
            const url = file?.blob ? URL.createObjectURL(file.blob) : file?.url;
            setFileUrl(url);

            // If we created a objectURL, we clean it up
            if (file?.blob && url) {
                return () => {
                    URL.revokeObjectURL(url);
                };
            }
        } catch (error) {
            console.error(error);
        }
    }, [file, file?.blob, file?.url]);

    return fileUrl;
};

export default useFileUrl;
