import { WorkflowVersionType } from '@tonkean/tonkean-entities';

function FillFormModalCtrl(
    $scope,
    $uibModalInstance,
    $rootScope,
    $stateParams,
    groupId,
    formId,
    workflowVersionId,
    onInitiativeCreated,
    initiativeId,
    solutionBusinessReportId,
    projectManager,
    workflowVersionType,
    parentInitiativeId,
) {
    const ctrl = this;

    $scope.data = {
        groupId,
        formId,
        workflowVersionId,
        workflowVersionType:
            $stateParams.formVersionType || $stateParams.env || workflowVersionType || WorkflowVersionType.PUBLISHED,
        createdInitiative: undefined,
        createdInitiativeForm: undefined,
        initiativeId,
        solutionBusinessReportId,
        parentInitiativeId,
        isOpen: true,
        project: projectManager.project,
    };

    ctrl.$onInit = function () {
        $scope.data.solutionBusinessReportId = solutionBusinessReportId;
    };

    /**
     * Closes the modal.
     */
    $scope.close = function () {
        $uibModalInstance.close();
        $scope.data.isOpen = false;
    };

    $scope.$on('modal.closing', function (event, reason, closed) {
        alertOnNewInitiativeIfCreated();
    });

    /**
     * Occurs once the initiative of the filled form is created.
     */
    $scope.onInitiativeCreatedByForm = function (createtionResult) {
        const { createdInitiative, formDisplayName } = createtionResult;
        $scope.data.createdInitiative = createdInitiative;
        $scope.data.createdInitiativeForm = formDisplayName;

        if (onInitiativeCreated) {
            onInitiativeCreated(createdInitiative);
        }
    };

    function alertOnNewInitiativeIfCreated() {
        if ($scope.data.createdInitiative && $scope.data.createdInitiativeForm) {
            $rootScope.$emit('alert', {
                msg: `Item ${$scope.data.createdInitiative.title} created by form ${$scope.data.createdInitiativeForm}`,
                type: 'success',
            });
        }
    }
}

angular.module('tonkean.app').controller('FillFormModalCtrl', FillFormModalCtrl);
