import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function AvatarsBarCtrl($rootScope, $scope, projectManager, modalUtils, utils) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.isMobile = $rootScope.isMobile;
    $scope.modalUtils = modalUtils;

    $scope.data = {
        avatars: ctrl.avatars,
        avatarsDesktopLimit: ctrl.avatarsDesktopLimit || 3,
        displayMoreLimit: ctrl.displayMoreLimit || 10,
        avatarHoverTemplatePath: ctrl.avatarHoverTemplatePath,
        showMoreTemplatePath: ctrl.showMoreTemplatePath,
        showAllInDisplayMore: ctrl.showAllInDisplayMore || false,
        showMoreAvatarsLink: ctrl.showMoreAvatarsLink || true,
        shouldDisplayViewTime: ctrl.shouldDisplayViewTime,
        size: ctrl.size || 'large',
        separatedAvatars: ctrl.separatedAvatars,

        anonymousUsersPatterns: [
            '/images/icons/pattern-triangles.png',
            '/images/icons/pattern-clouds.png',
            '/images/icons/pattern-wave.png',
            '/images/icons/pattern-zigzag.png',
            '/images/icons/pattern-church.png',
            '/images/icons/pattern-temple.png',
            '/images/icons/pattern-stripes.png',
            '/images/icons/pattern-deathstar.png',
        ],
        anonymousUserName: 'Guest',
        modPalette: ['primary', 'red', 'green', 'blue', 'dark-green'],
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.avatars) {
            $scope.data.avatars = ctrl.avatars.filter((person) => !person.systemUtilized);
        }
    };

    $scope.generateRandomPaletteColor = function (index) {
        return $scope.data.modPalette[index % $scope.data.modPalette.length];
    };

    $scope.generateRandomAnonymousPattern = function (index) {
        return $scope.data.anonymousUsersPatters[index % $scope.data.anonymousUsersPatters.length];
    };

    $scope.onMoreClick = function ($event) {
        utils.eventStopAllPropagations($event);
        modalUtils.openMoreUsersModal(
            $scope.data.avatars,
            $scope.data.anonymousUsersPatters,
            $scope.data.modPalette,
            $scope.data.anonymousUserName,
            $scope.data.shouldDisplayViewTime,
        );
    };

    $scope.getAvatarName = function (avatar) {
        return avatar.isAnonymousUser ? $scope.data.anonymousUserName : avatar.name || avatar.displayName;
    };
}

export default angular.module('tonkean.app').controller('AvatarsBarCtrl', lateConstructController(AvatarsBarCtrl));
