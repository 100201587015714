import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import NavigationMenuContent from './NavigationMenuContent';
import type ExternalNavigablePage from '../../entities/ExternalNavigablePage';
import type InternalNavigablePage from '../../entities/InternalNavigablePage';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Menu } from '@tonkean/infrastructure';
import { DotsGridIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

const NavigationMenuWrapper = styled(Menu)`
    width: 268px;
    padding: 12px 16px;
    gap: 8px;
`;

const NavigationMenuButton = styled.button<{
    $isActive: boolean;
    transparentMode?: boolean;
}>`
    display: flex;
    margin: 0 16px 0 0;
    padding: 8px;
    border-radius: 8px;
    background: ${({ $isActive, transparentMode }) => {
        if (transparentMode) {
            return 'transparent';
        }
        if ($isActive) {
            return Theme.colors.gray_300;
        }
        return 'white';
    }};
    transition: background-color 0.2s linear;
    border: none;

    &:hover {
        ${({ transparentMode }) =>
            !transparentMode &&
            css`
                background: ${Theme.colors.gray_300};
            `};
    }
`;

interface Props {
    currentPage: InternalNavigablePage | ExternalNavigablePage | undefined;
    transparentMode: boolean | undefined;
    alignToNodeRef: React.RefObject<HTMLElement>;
    isSystemUser: boolean;
}

const NavigationMenuSwitch: React.FC<Props> = ({ currentPage, transparentMode, alignToNodeRef, isSystemUser }) => {
    const [navigationMenuIsOpen, setNavigationMenuIsOpen] = useState(false);

    const projectManager = useAngularService('projectManager');
    const { data: homepageSolutionSites } = useTonkeanService('getHomepageSolutionSites', projectManager.project.id);

    return (
        <NavigationMenuWrapper
            show={navigationMenuIsOpen}
            onClose={() => setNavigationMenuIsOpen(false)}
            menuItems={
                <NavigationMenuContent
                    currentPage={currentPage?.id}
                    projectId={projectManager.project.id}
                    projectUrlSlug={projectManager.project.urlSlug}
                    solutionSites={homepageSolutionSites?.entities}
                    themeColor={projectManager.project?.themeConfiguration?.primaryColor || Theme.colors.gray_300}
                    isSystemUser={isSystemUser}
                />
            }
            nodeRef={alignToNodeRef}
            initiallyFocusOnWrapper
        >
            <NavigationMenuButton
                aria-label="Open Navigation Menu"
                $isActive={navigationMenuIsOpen}
                onClick={() => setNavigationMenuIsOpen(!navigationMenuIsOpen)}
                data-automation="navigation-menu-switch-open-navigation-menu"
                transparentMode={transparentMode}
            >
                <DotsGridIcon />
            </NavigationMenuButton>
        </NavigationMenuWrapper>
    );
};

export default NavigationMenuSwitch;
