import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import AddCommentInput from './AddCommentInput';
import CommentList from './CommentList';
import EventsLoadingState from './EventsLoadingState';
import NoCommentsView from './NoCommentsView';
import { useGetTaggableIntakeCustomTriggers } from '../../taggableEntities/hooks';
import { CommentsContext } from '../context';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    ItemInterfaceFieldDefinitionSummaryContext,
    LIST_CHANGE_TYPE,
    NoItemEmptyState,
    useInitiativeActivityFetchManager,
    useModifiableComments,
} from '@tonkean/infrastructure';
import { ActivityType, type Initiative, type NewActivityData, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const AddCommentInputWrapper = styled.div`
    border-top: 1px solid ${Theme.colors.gray_300};
    padding-bottom: 7px;
    padding-top: 35px;
    height: 100px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const LoadMoreButton = styled(Button)`
    margin-top: 12px;
`;

const activityTypes: ActivityType[] = [ActivityType.NEW_COMMENT];

const CommentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    grid-column: span 12;
    background-color: ${Theme.colors.white};
    padding: 5px 16px 0 16px;
    overflow-y: auto;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-column: span 1;
        max-width: 95vw;
    }
`;

const EmptyStateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export interface CommentsProps {
    initiative: Initiative | undefined;
    initiativeInitialLoading: boolean;
    canEditComments: boolean;
    includeIntake: boolean;
    excludePrivateComments?: boolean;
    filterOnOtherTargets: boolean;
    defaultCommentText?: string;
    orderByAscending?: boolean;
    disableReplyToComment?: boolean;
}

const Comments: React.FC<CommentsProps> = ({
    initiative,
    initiativeInitialLoading,
    canEditComments,
    includeIntake,
    excludePrivateComments,
    filterOnOtherTargets,
    defaultCommentText,
    disableReplyToComment,
    orderByAscending = false,
}) => {
    const authenticationService = useAngularService('authenticationService');
    const enableInterfaceWidgetNoItemEmptyState = useFeatureFlag(
        'tonkean_enable_interface_widgets_no_item_empty_state',
    );

    const { activities, loading, hasMorePages, loadNextPage } = useInitiativeActivityFetchManager(
        initiative?.id,
        activityTypes,
        includeIntake,
        excludePrivateComments,
        true,
    );

    const [modifiableComments, { onCommentAdded, onCommentDelete, reset }] = useModifiableComments(
        activities,
        initiative?.group,
        initiative?.project,
    );

    const onCommentAddedToList = useCallback(
        (newActivity: NewActivityData) => {
            onCommentAdded(newActivity, filterOnOtherTargets);
        },
        [onCommentAdded, filterOnOtherTargets],
    );

    useEffect(() => {
        reset([LIST_CHANGE_TYPE.CREATED]);
    }, [activities, reset]);

    const handleCommentAdded = useCallback(
        (newActivity: NewActivityData) => {
            onCommentAdded(newActivity, filterOnOtherTargets);
        },
        [onCommentAdded, filterOnOtherTargets],
    );

    const showEmptyState = useMemo(() => {
        return !loading.initial && modifiableComments?.length === 0;
    }, [loading.initial, modifiableComments]);

    const showLoadingState = useMemo(() => {
        return loading.initial;
    }, [loading.initial]);

    const showLoadedCommentsIfExist = useMemo(() => {
        return modifiableComments && modifiableComments.length > 0;
    }, [modifiableComments]);

    const workflowVersionType = initiative?.isDraftInitiative
        ? WorkflowVersionType.DRAFT
        : WorkflowVersionType.PUBLISHED;

    const { taggableCustomTriggerInterfaces, loadingIntakeCustomTrigger } = useGetTaggableIntakeCustomTriggers(
        workflowVersionType,
        initiative?.id,
    );

    const fieldDefinitionsSummaryContext = useContext(ItemInterfaceFieldDefinitionSummaryContext);
    const fieldDefinitionsSummary = fieldDefinitionsSummaryContext?.summaries;
    const commentsContextData = useMemo<CommentsContext>(() => {
        return {
            initiativeId: initiative?.id,
            fields: fieldDefinitionsSummary,
            projectId: initiative?.project?.id,
            workflowVersionType,
            taggableCustomTriggerInterfaces,
        };
    }, [initiative, fieldDefinitionsSummary, taggableCustomTriggerInterfaces, workflowVersionType]);

    if (!initiative && !initiativeInitialLoading) {
        return enableInterfaceWidgetNoItemEmptyState ? (
            <EmptyStateContainer>
                <NoItemEmptyState />
            </EmptyStateContainer>
        ) : (
            <NoCommentsView />
        );
    }

    return (
        <CommentsContext.Provider value={commentsContextData}>
            <CommentsContainer>
                {initiative ? (
                    <>
                        {(showLoadingState || loadingIntakeCustomTrigger) && <EventsLoadingState rowCount={1} />}
                        {showLoadedCommentsIfExist && (
                            <CommentList
                                comments={modifiableComments}
                                currentUser={authenticationService.getCurrentUser()}
                                onCommentDelete={onCommentDelete}
                                onCommentAdded={onCommentAddedToList}
                                canUserEditItem={canEditComments}
                                key={modifiableComments.length}
                                initiativeId={initiative.id}
                                workflowVersionType={
                                    initiative.isDraftInitiative
                                        ? WorkflowVersionType.DRAFT
                                        : WorkflowVersionType.PUBLISHED
                                }
                                defaultCommentText={defaultCommentText}
                                dataAutomation="initiative-comments-list"
                                orderByAscending={orderByAscending}
                                disableReplyToComment={disableReplyToComment}
                            />
                        )}
                        {showEmptyState && <NoCommentsView />}
                    </>
                ) : (
                    <EventsLoadingState rowCount={1} />
                )}

                {hasMorePages && (
                    <ButtonWrapper>
                        <LoadMoreButton size={ButtonSize.SMALL} onClick={loadNextPage} outlined>
                            Load More
                        </LoadMoreButton>
                    </ButtonWrapper>
                )}
            </CommentsContainer>
            {initiative && (
                <AddCommentInputWrapper>
                    <AddCommentInput
                        defaultCommentText={defaultCommentText}
                        targetId={initiative.id}
                        onSubmit={handleCommentAdded}
                        canUserEditItem={canEditComments}
                        initiativeId={initiative.id}
                        workflowVersionType={
                            initiative.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED
                        }
                    />
                </AddCommentInputWrapper>
            )}
        </CommentsContext.Provider>
    );
};

export default Comments;
