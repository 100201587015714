import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import FieldSelectorValueTypeIcons from './FieldSelectorValueTypeIcons';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, FieldType } from '@tonkean/tonkean-entities';
import { FormDefinitionType } from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const IconWrapper = styled.div`
    margin-right: 4px;
`;

const typeToTitleName: Record<FormDefinitionType, string> = {
    // TODO - Implement if needed
    AGGREGATE_QUERY: '',
    EXTERNAL: '',
    GOOGLE: '',
    JIRA_COUNT_FILTER: '',
    MATCH_TONKEAN_ITEM: '',
    ROOT: '',
    SMARTSHEET: '',
    SQL: '',
    TNK_COLUMN_AGGREGATE: '',
    TNK_COLUMN_FORMULA: 'Formula Fields',
    MANUAL: 'Custom Fields',
};

type FieldDefinitionSummary = {
    title: string;
    id: FieldDefinition['id'];
    type: FormDefinitionType;
    valueType: FieldType;
};

const ALL_TYPES = Object.keys(FormDefinitionType);

interface Props {
    fieldsDefinitions: FieldDefinition[];
    loading: boolean;
    typesToInclude?: FormDefinitionType[];
    placeholder?: string;
    isClearable?: boolean;
    name?: string;
    includeSpecialFields?: boolean;
    onChange?: (selectedValue: FieldDefinition['id'] | undefined, fieldType: FieldType | undefined) => void;
    fieldDefinitionId?: string;
    thin?: boolean;
    size?: InputComponentSizes;
    onlyMultiValueFields?: boolean;
    onlyForMatchedItems?: boolean;
}

const FieldSelector: React.FC<Props> = ({
    placeholder = 'Search Field',
    fieldsDefinitions,
    typesToInclude = ALL_TYPES,
    isClearable = false,
    includeSpecialFields = false,
    onlyMultiValueFields,
    onlyForMatchedItems,
    onChange: onChangeProps,
    name,
    fieldDefinitionId: fieldDefinitionIdProps,
    loading,
    ...props
}) => {
    const formik = useContext(FormikContext) as FormikContextType<unknown> | undefined;
    const fieldDefinitionId = name ? formik?.getFieldMeta<string>(name).value : fieldDefinitionIdProps;

    const typeToShouldShow = useMemo(() => {
        return Object.fromEntries(Object.keys(FormDefinitionType).map((type) => [type, typesToInclude.includes(type)]));
    }, [typesToInclude]);

    const fieldDefinitionSummaries: FieldDefinitionSummary[] = useMemo(() => {
        const summaries: FieldDefinitionSummary[] = fieldsDefinitions
            .filter((fieldDefinition) => typeToShouldShow[fieldDefinition?.type])
            .filter((fieldDefinition) => !onlyMultiValueFields || fieldDefinition.isMultiValueField)
            .filter((fieldDefinition) => {
                if (!onlyForMatchedItems) {
                    return true;
                }
                const definition = fieldDefinition.definition as any;
                return (
                    definition?.matchConfiguration?.isForMatchingItem &&
                    (fieldDefinition.type === FormDefinitionType.MANUAL ||
                        (fieldDefinition.type === FormDefinitionType.EXTERNAL &&
                            !definition.matchConfiguration.creatingCustomTriggerId &&
                            !definition?.matchConfiguration?.idRelationFieldDefinitionId)) &&
                    (fieldDefinition.idRelationField || fieldDefinition.linkedCustomTrigger)
                );
            })
            .map((fieldDefinition) => ({
                title: fieldDefinition.name,
                id: fieldDefinition.id,
                type: fieldDefinition.type,
                valueType: fieldDefinition.fieldType,
            }));

        const specialFieldsToExclude = new Set(['TNK_STATUS_TEXT']);

        const specialFieldsSummaries = includeSpecialFields
            ? getSpecialFieldsForFeatures(false, ['BASIC_FIELDS', 'MATCH_TRACK_FIELD'])
                  ?.filter((fieldDefinition: FieldDefinition) => !specialFieldsToExclude.has(fieldDefinition.id))
                  ?.map((fieldDefinition: FieldDefinition) => ({
                      title: fieldDefinition.name,
                      id: fieldDefinition.id,
                      type: fieldDefinition.type,
                      valueType: fieldDefinition.fieldType,
                  })) || []
            : [];

        return [...summaries, ...specialFieldsSummaries];
    }, [fieldsDefinitions, includeSpecialFields, onlyForMatchedItems, onlyMultiValueFields, typeToShouldShow]);

    const options = useMemo(() => {
        const grouped = utils.groupBy(fieldDefinitionSummaries, (summary) => summary.type);

        return Object.entries(grouped).map(([type, summaries]) => ({
            label: typeToTitleName[type] || type,
            options: (summaries || []).map((summary) => ({
                label: summary.title,
                value: summary.id,
                icon: (
                    <IconWrapper>
                        <FieldSelectorValueTypeIcons type={summary.valueType} />
                    </IconWrapper>
                ),
            })),
        }));
    }, [fieldDefinitionSummaries]);

    const onChange = (selectedValue: FieldDefinition['id'] | undefined) => {
        const fieldType = fieldDefinitionSummaries.find(
            (fieldDefinitionSummary) => fieldDefinitionSummary.id === selectedValue,
        )?.valueType;

        onChangeProps?.(selectedValue, fieldType);
    };

    return (
        <SimpleSelect
            isLoading={loading}
            options={options}
            placeholder={placeholder}
            isClearable={isClearable}
            value={name ? undefined : fieldDefinitionId}
            onChange={name ? undefined : onChange}
            name={name}
            {...props}
        />
    );
};

export default FieldSelector;
