/* jshint ignore:start */

import React from 'react';

export default class DefaultProjectedReason extends React.Component {
    constructor(props) {
        super(props);
    }

    renderBody() {
        return <div>{this.renderTitleLink(this.props.initiative)}</div>;
    }

    renderTitleLink() {
        const context = this.props.initiative.parent
            ? this.props.initiative.parent.title
            : this.props.initiative.group.name;
        return (
            <span>
                <a onClick={this.props.goToTrack.bind(this, this.props.initiative.id)}>{this.props.initiative.title}</a>{' '}
                ({context})
            </span>
        );
    }

    /**
     * The main render function
     */
    render() {
        return <div>{this.renderBody()}</div>;
    }
}
/* jshint ignore:end */
