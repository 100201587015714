import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import type {
    SingleFieldChanged,
    SingleFieldConfigurationWithDefinition,
    WidgetFieldPackage,
} from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';
import { FieldsWidgetDisplayType } from '../../FieldsWidgetModule';
import { SingleField } from '../../FieldsWidgetModule';

import { Breakpoint, TextEllipsis, useBreakpoint } from '@tonkean/infrastructure';
import type { Initiative, TonkeanId, TonkeanType, WidgetBase } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme, FontSize } from '@tonkean/tui-theme';

const CardDetailsWrapper = styled.div<{ shouldDisplayCardsOnMobile: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    --grid-item-min-width: 250px;
    margin-top: 16px;
    width: 100%;
    ${({ shouldDisplayCardsOnMobile }) =>
        shouldDisplayCardsOnMobile &&
        css`
            @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
                grid-template-columns: 1fr;
            }
        `}
`;

const CardContainer = styled(ClickableLink)`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px;
    background: ${Theme.colors.basicBackground};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    color: initial;
    &:hover {
        color: initial;
        text-decoration: none;
        border-color: rgba(47, 132, 220, 0.4);
        box-shadow: 0px 2px 2px rgb(0 0 0 / 3%);
        transition: box-shadow 200ms ease;
    }
    &:focus {
        color: initial;
        text-decoration: none;
    }
`;

const CardTitle = styled(ClickableLink)`
    font-weight: 600;
    font-size: ${FontSize.LARGE_16};
    padding: 8px;
    color: initial;
    text-decoration: none;
    &:hover {
        color: initial;
        text-decoration: underline;
    }
    &:focus {
        color: initial;
        text-decoration: none;
    }
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    initiative: Initiative;
    showEmptyFieldWhenNoInitiative: boolean;
    showModuleOffIndication?: boolean;
    onChange(change: SingleFieldChanged): Promise<void>;
    fields: SingleFieldConfigurationWithDefinition[];
    fieldDisplay?: FieldsWidgetDisplayType;
    viewOnly: boolean;
    displayNotUpToDateWarning: boolean;
    shouldDisplayCardsOnMobile: boolean;
    widget: WidgetBase<InnerItemWidgetConfiguration>;
}

const CardItemDisplay: React.FC<Props> = ({
    projectId,
    initiative,
    showEmptyFieldWhenNoInitiative,
    fields,
    onChange,
    viewOnly,
    fieldDisplay,
    showModuleOffIndication,
    shouldDisplayCardsOnMobile,
    widget,
}) => {
    const $location = useAngularService('$location');
    const $state = useAngularService('$state');
    const $stateParams = useAngularService('$stateParams');

    const initiativeFields: SingleFieldConfigurationWithDefinition[] = fields.map((field) => {
        if (field.package) {
            return {
                ...field,
                package: {
                    ...field.package,
                    initiative,
                } as WidgetFieldPackage,
            };
        } else {
            return field;
        }
    });

    const sortedFieldDefinitions = useMemo(() => {
        return initiativeFields.sort((fieldA, fieldB) => Number(fieldA.index) - Number(fieldB.index));
    }, [initiativeFields]);

    const openItemInterface = useCallback(() => {
        if ($stateParams.initiativeId) {
            $state.go('.', { initiativeId: initiative.id, originWidget: widget.id }, { notify: false });
        }
        if (widget.id) {
            $location.search('originWidget', widget.id);
        } else {
            $location.search('originWidget', '');
        }
        $location.search('tid', initiative.id);
    }, [$location, $state, $stateParams.initiativeId, initiative.id, widget.id]);

    const effectiveBreakpoint = useBreakpoint();
    const isMobile = effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;

    return (
        <CardContainer onClick={isMobile ? () => {} : openItemInterface}>
            <CardTitle onClick={isMobile ? openItemInterface : () => {}}>
                <TextEllipsis numberOfLines={1} tooltip>
                    {initiative.title}
                </TextEllipsis>
            </CardTitle>
            <CardDetailsWrapper shouldDisplayCardsOnMobile={shouldDisplayCardsOnMobile}>
                {sortedFieldDefinitions.map((field) => {
                    return (
                        <Clickable
                            key={field.key}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <SingleField
                                key={field.key}
                                projectId={projectId}
                                onChange={(newValue) => {
                                    if (field.package) {
                                        return onChange({ package: field.package, newValue });
                                    }
                                    return Promise.reject('Unable to update field without filed definition');
                                }}
                                field={field.package}
                                fieldDefinitionId={field.key}
                                showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                                labelOverride={field.label}
                                hideColorsAndTrends={field.hideColorsAndTrends}
                                submitAsForm={false}
                                fullWidth={field?.fullWidth}
                                fieldDisplay={fieldDisplay}
                                showModuleOffIndication={showModuleOffIndication}
                                editingEnabledInWidget={!viewOnly}
                                canUserEditItem={!viewOnly}
                                fieldDisplayInMobile={FieldsWidgetDisplayType.LIST}
                            />
                        </Clickable>
                    );
                })}
            </CardDetailsWrapper>
        </CardContainer>
    );
};

export default CardItemDisplay;
