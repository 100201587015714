import React from 'react';
import styled from 'styled-components';

import TimelineEventContent from './TimelineEventContent';
import TimelineEventMessage from './TimelineEventMessage';
import TimelineEventTitleText from './TimelineEventTitleText';

import { EventTitle, CommentDeletionControl } from '@tonkean/collaboration';
import type { NewActivityData, Person, Activity } from '@tonkean/tonkean-entities';
import { ActivityType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ShowOnHover = styled.span``;

const Container = styled.div`
    padding: 4px 0;

    ${ShowOnHover} {
        visibility: hidden;
    }

    &:hover ${ShowOnHover} {
        visibility: visible;
    }
`;

const CardWrapper = styled.div<{ borderLeft: boolean }>`
    margin-top: 8px;
    margin-left: 13px;
    padding-bottom: 16px;
    padding-left: 22px;
    border-left: ${({ borderLeft }) => borderLeft && `1px solid ${Theme.colors.gray_400}`};
`;

interface TimelineEventProps {
    event: Activity;
    currentUser: Person;
    onCommentAdded: (newActivity: NewActivityData) => void;
    defaultShowAddCommentForm?: boolean;
    threadMaxHeight?: number;
    onCommentDelete: (commentId: string, targetId?: string) => void;
    isLastEvent: boolean;
    canUserEditItem: boolean;
}

const TimelineEvent: React.VFC<TimelineEventProps> = ({
    event,
    currentUser,
    onCommentDelete,
    isLastEvent,
    ...otherEventContentProps
}) => {
    return (
        <Container data-automation="timeline-event-container">
            <EventTitle
                actor={event.actor}
                date={event.created}
                eventText={<TimelineEventTitleText event={event} currentUser={currentUser} />}
                actions={
                    event.type === ActivityType.NEW_COMMENT && event.actor.id === currentUser.id ? (
                        <ShowOnHover>
                            <CommentDeletionControl
                                commentId={event.id}
                                onDelete={onCommentDelete}
                                title="Delete Event"
                                content={
                                    <>
                                        Are you sure you want to delete “<TimelineEventMessage event={event} />” event?
                                    </>
                                }
                            />
                        </ShowOnHover>
                    ) : null
                }
                activatedByTonkean={event.type === ActivityType.BOT_GATHER || event.metadata?.updatedViaBot}
            />
            <CardWrapper borderLeft={!isLastEvent}>
                <TimelineEventContent
                    event={event}
                    currentUser={currentUser}
                    onCommentDelete={onCommentDelete}
                    {...otherEventContentProps}
                />
            </CardWrapper>
        </Container>
    );
};

export default TimelineEvent;
