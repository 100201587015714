import { useContext } from 'react';

import type { EnterpriseComponentPageLayoutContextProps } from '../utils/EnterpriseComponentPageLayoutContext';
import EnterpriseComponentPageLayoutContext from '../utils/EnterpriseComponentPageLayoutContext';

import type { EnterpriseComponent } from '@tonkean/tonkean-entities';

function useEnterpriseComponentContext<C extends EnterpriseComponent>(): EnterpriseComponentPageLayoutContextProps<C> {
    return useContext(EnterpriseComponentPageLayoutContext) as any;
}

export default useEnterpriseComponentContext;
