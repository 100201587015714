import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import PeopleDirectoryRecipientsPreview from './PeopleDirectoryRecipientsPreview';
import { ReactComponent as AskForInfoIcon } from '../../../../../../images/icons/ask-information.svg';
import { ReactComponent as AskAQuestionIcon } from '../../../../../../images/icons/ask.svg';
import { ReactComponent as SendFormIcon } from '../../../../../../images/icons/form.svg';
import { ReactComponent as SendNotificationIcon } from '../../../../../../images/icons/red-bell.svg';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import useExampleItemId from '../../../hooks/useExampleItemId';
import type { PeopleDirectoryViewContextData } from '../../../utils/PeopleDirectoryViewContext';
import PeopleDirectoryViewContext from '../../../utils/PeopleDirectoryViewContext';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService, useAsyncMethod } from '@tonkean/angular-hooks';
import { Modal, ModalBody, ModalHeader, XCloseButton } from '@tonkean/infrastructure';
import type { CustomTrigger, Environment, Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { CustomTriggerType, SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const IconWrapper = styled.div`
    margin-right: 8px;
    display: grid;
    place-items: center;

    svg {
        width: 20px;
        height: 20px;
    }
`;
const CustomTriggerName = styled.div`
    color: ${Theme.colors.gray_500};
    margin-right: 1ch;
`;
const CloseModalButton = styled(XCloseButton)`
    justify-self: flex-end;
`;
const StyledModalHeader = styled(ModalHeader)`
    justify-content: space-between;
`;
const HeaderRight = styled.div`
    display: flex;
    align-items: center;
`;
const StyledModalBody = styled(ModalBody)`
    display: flex;
    flex-direction: column;
`;

const peopleActionToIcon: Partial<Record<CustomTriggerType, JSX.Element>> = {
    [CustomTriggerType.ASK_FIELD_UPDATE]: <AskForInfoIcon />,
    [CustomTriggerType.SEND_FORM]: <SendFormIcon />,
    [CustomTriggerType.PERSON_INQUIRY]: <AskAQuestionIcon />,
    [CustomTriggerType.SEND_NOTIFICATION]: <SendNotificationIcon />,
};

interface Props {
    evaluatedExpression: string;

    onClose(): void;

    customTrigger: CustomTrigger;
    sendToGroupOfPeopleAs: SendToGroupOfPeopleAs;
}

const PeopleDirectoryRecipientsPreviewModal: React.FC<Props> = ({
    onClose,
    customTrigger,
    evaluatedExpression,
    sendToGroupOfPeopleAs,
}) => {
    const customTriggerIcon = peopleActionToIcon[customTrigger.customTriggerType];

    const [groupId] = useGetStateParams<[TonkeanId<TonkeanType.GROUP>]>('g');
    const groupInfoManager = useAngularService('groupInfoManager');

    const [currentEnvironment, setCurrentEnvironment] = useState<Environment>('build');

    const { data: group } = useAsyncMethod(groupInfoManager, 'getGroupById', groupId, false);

    const exampleInitiativeId = useExampleItemId(customTrigger.workflowVersion.id, customTrigger.id);

    const [{ data: recipientsPreviewResponse, loading }, getRecipientsPreviewFromTonkeanExpression] =
        useLazyTonkeanService('getRecipientsPreviewFromTonkeanExpression');

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (!group) {
            return;
        }

        const workflowVersionId =
            currentEnvironment === 'build'
                ? (group as Group).draftWorkflowVersionId
                : (group as Group).publishedWorkflowVersionId;

        getRecipientsPreviewFromTonkeanExpression(workflowVersionId, exampleInitiativeId, evaluatedExpression);
    }, [
        currentEnvironment,
        evaluatedExpression,
        exampleInitiativeId,
        getRecipientsPreviewFromTonkeanExpression,
        group,
    ]);

    const peopleDirectoryInstances = useMemo(
        () => recipientsPreviewResponse?.peopleDirectoryInstances ?? {},
        [recipientsPreviewResponse?.peopleDirectoryInstances],
    );
    const missingInstancesPeopleDirectoryIds: string[] = useMemo(() => {
        return recipientsPreviewResponse?.missingInstancesPeopleDirectoryIds ?? [];
    }, [recipientsPreviewResponse?.missingInstancesPeopleDirectoryIds]);

    const groupMessages = useMemo(
        () => recipientsPreviewResponse?.groupMessages ?? {},
        [recipientsPreviewResponse?.groupMessages],
    );
    const peopleDirectories = useMemo(
        () =>
            Object.fromEntries(
                recipientsPreviewResponse?.peopleDirectories
                    .filter((peopleDirectory) => {
                        if (sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL) {
                            return true;
                        }

                        const instance = peopleDirectoryInstances?.[peopleDirectory?.id]?.id;

                        if (!instance) {
                            return true;
                        }

                        return (
                            peopleDirectory.displayName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                            ((groupMessages[instance]?.slackChannel?.displayName as string) ?? '')
                                .toLocaleLowerCase()
                                .includes(searchTerm.toLocaleLowerCase())
                        );
                    })
                    .map((peopleDirectory) => [peopleDirectory.id, peopleDirectory]) ?? [],
            ),
        [
            groupMessages,
            peopleDirectoryInstances,
            recipientsPreviewResponse?.peopleDirectories,
            searchTerm,
            sendToGroupOfPeopleAs,
        ],
    );
    const individuals = useMemo(() => {
        if (searchTerm === '' || !recipientsPreviewResponse?.individuals) {
            return recipientsPreviewResponse?.individuals ?? {};
        }

        return Object.fromEntries(
            Object.entries(recipientsPreviewResponse.individuals).map(
                ([peopleDirectoryId, peopleDirectoryIndividuals]) => {
                    const filteredIndividuals = peopleDirectoryIndividuals.filter((person) =>
                        person.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
                    );
                    return [peopleDirectoryId, filteredIndividuals];
                },
            ),
        );
    }, [searchTerm, recipientsPreviewResponse?.individuals]);

    const totalIndividuals = useMemo(() => {
        return new Set(
            Object.values(individuals)
                .flat()
                .map((person) => person.id),
        ).size;
    }, [individuals]);

    const contextValue = useMemo<PeopleDirectoryViewContextData>(() => {
        return {
            peopleDirectories,
            peopleDirectoryInstances,
            missingInstancesPeopleDirectoryIds,
            groupMessages,
            groupMessagesResults: {},
            individuals,
            individualsSendResults: {},
            totalIndividuals,
            sendToGroupOfPeopleAs,
            mode: PeopleDirectoryViewMode.PREVIEW,
        };
    }, [
        groupMessages,
        individuals,
        peopleDirectories,
        peopleDirectoryInstances,
        missingInstancesPeopleDirectoryIds,
        sendToGroupOfPeopleAs,
        totalIndividuals,
    ]);

    return (
        <PeopleDirectoryViewContext.Provider value={contextValue}>
            <Modal onClose={onClose} size={1150} height="80vh" open fixedWidth>
                <StyledModalHeader $flex>
                    <HeaderRight>
                        <IconWrapper>{customTriggerIcon}</IconWrapper>
                        <CustomTriggerName>{customTrigger.displayName}:</CustomTriggerName> Preview Recipients
                    </HeaderRight>
                    <CloseModalButton />
                </StyledModalHeader>
                <StyledModalBody>
                    <PeopleDirectoryRecipientsPreview
                        currentEnvironment={currentEnvironment}
                        setCurrentEnvironment={setCurrentEnvironment}
                        loading={loading}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                    />
                </StyledModalBody>
            </Modal>
        </PeopleDirectoryViewContext.Provider>
    );
};

export default PeopleDirectoryRecipientsPreviewModal;
