import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ToggleButtonsGroupCtrl($scope) {
    const ctrl = this;
    
    $scope.data = {
        buttons: ctrl.buttons,
        onButtonSelected: ctrl.onButtonSelected,
        selectedButton: ctrl.selectedButton,
        titleLabel: ctrl.titleLabel,
        automationIdentifier: ctrl.automationIdentifier,
        wrapperClasses: ctrl.wrapperClasses,
        toggleButtonsGroupClasses: ctrl.toggleButtonsGroupClasses,
        titleClasses: ctrl.titleClasses,
    };

    ctrl.$onChanges = (changeObject) => {
        if (changeObject.selectedButton) {
            $scope.data.selectedButton = changeObject.selectedButton.currentValue;
        }
        if (changeObject.titleLabel) {
            $scope.data.titleLabel = changeObject.titleLabel.currentValue;
        }
        if (changeObject.buttons) {
            $scope.data.buttons = changeObject.buttons.currentValue;
        }
        if (changeObject.automationIdentifier) {
            $scope.data.automationIdentifier = ctrl.automationIdentifier;
        }
    };
}

export default angular.module('tonkean.app').controller('ToggleButtonsGroupCtrl', lateConstructController(ToggleButtonsGroupCtrl));
