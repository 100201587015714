<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group">
                <label for="okta-url" class="col-sm-3 control-label">URL</label>

                <div class="col-sm-9">
                    <div class="flex-vmiddle">
                        <div>https://</div>
                        <div class="flex-grow zendesk-subdomain">
                            <input
                                type="text"
                                ng-model="data.subDomain"
                                class="form-control"
                                id="okta-url"
                                placeholder="Your sub-domain"
                                required
                            />
                        </div>

                        <!-- Okta url suffix dropdown -->
                        <div class="margin-top-minus-xxxs">
                            <ui-select ng-model="data.selectedOktaUrlSuffix" theme="bootstrap">
                                <ui-select-match placeholder="Select url">
                                    {{ data.selectedOktaUrlSuffix }}
                                </ui-select-match>
                                <ui-select-choices repeat="displayName in data.oktaUrlSuffixOptions">
                                    <div ng-bind-html="displayName | escapeHtml | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Api Token Connect-->
            <div class="form-group">
                <label for="okta-key" class="col-sm-3 control-label">API Key</label>

                <div class="col-sm-9">
                    <input
                        type="text"
                        ng-model="data.apiKey"
                        class="form-control"
                        id="okta-key"
                        autocomplete="off"
                        placeholder="API Token"
                    />
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        ng-if="!data.isCreate"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        ng-if="data.isCreate"
        type="button"
        ng-click="connectWithToken()"
    >
        OK
    </button>
</div>
