import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Accordion } from '@tonkean/infrastructure';
import { AccordionSize } from '@tonkean/tui-theme/sizes';

const StyledAccordion = styled(Accordion)`
    margin-bottom: 8px;
`;

const AccordionSection = styled.div<{ noPadding?: boolean }>`
    ${({ noPadding }) =>
        noPadding
            ? css`
                  padding: 0;
              `
            : css`
                  padding: 24px;
              `};
`;

export type MarketplaceItemCreateSection = {
    title: React.ReactNode;
    contentComponent: JSX.Element;
    validationSchema?: any;
    configurationInitialValues?: any;
    configurationValidationSchema?: any;
    noPadding?: boolean;
};

interface Props {
    section: MarketplaceItemCreateSection;
}

const MarketplaceItemCreateModalSection: React.FC<Props> = ({ section }) => {
    const [open, setOpen] = useState(true);
    return (
        <StyledAccordion
            open={open}
            onChange={(value) => setOpen(value)}
            size={AccordionSize.SMALL}
            title={section.title}
            whiteContentBackground
            shadow
            sideBorders
            borderTop
        >
            <AccordionSection noPadding={section.noPadding}>{section.contentComponent}</AccordionSection>
        </StyledAccordion>
    );
};

export default MarketplaceItemCreateModalSection;
