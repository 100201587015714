import React from 'react';

import StateMessage from './StateMessage';

import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

interface Props {
    title: React.ReactNode;
    message?: React.ReactNode;
    icon: React.ReactNode;
    clearFilters?(): void;
    messageDataAutomation?: string;
    clearFiltersDataAutomation?: string;
}

const EmptyStateFilters: React.FC<Props> = ({
    title,
    message = 'Try changing or clearing the filters.',
    icon,
    messageDataAutomation,
    clearFiltersDataAutomation,
    clearFilters,
}) => {
    return (
        <StateMessage icon={icon} title={title}>
            <div data-automation={messageDataAutomation}>{message}</div>

            {clearFilters && (
                <Button
                    className="margin-top"
                    onClick={clearFilters}
                    size={ButtonSize.SMALL}
                    data-automation={clearFiltersDataAutomation}
                    outlined
                >
                    Clear Filters
                </Button>
            )}
        </StateMessage>
    );
};

export default EmptyStateFilters;
