import { useAngularService, useAngularWatch } from 'angulareact';

type ToStringArray<T> = T extends [infer Z, ...infer Rest]
    ? [Z extends string ? Z : string, ...ToStringArray<Rest>]
    : [];

// Convert a tuple of specific string (like enums) to regular string tuple. For example: ['john' | 'doe', TonkeanId]
// to [string, string].
type InferResultTuple<T extends any[]> = T extends [any, ...infer Rest] ? [string, ...InferResultTuple<Rest>] : [];

/**
 * Hook to bring the state params values by the requested state params names.
 *
 * @example
 * const [projectId] = useGetStateParams('projectId');
 * // will return the tonkean project id string
 *
 * @param paramNames
 * @returns the string value of the requested param.
 */
function useGetStateParams<T extends any[]>(...paramNames: InferResultTuple<ToStringArray<T>>): T;
function useGetStateParams<T extends string[]>(...paramNames: ToStringArray<T>): InferResultTuple<T>;
function useGetStateParams<T extends string[]>(...paramNames: T): InferResultTuple<T>;
function useGetStateParams(...paramNames: string[]): string[] {
    const $state = useAngularService('$state');

    const watchFunctions = paramNames.map((paramName) => {
        return () => $state.params[paramName] as string;
    });

    return useAngularWatch(...watchFunctions) as any;
}

export default useGetStateParams;
