import template from './tnkInitiativesSidebar.template.html?angularjs';

/**
 * A component used to supply initiative state updates.
 */
angular.module('tonkean.app').component('tnkInitiativesSidebar', {
    bindings: {
        isForms: '<',
        onInitiativesLoaded: '&',
        onInitiativeSelected: '&',
        formItemsHierarchyFilter: '<?',
        groupId: '<?',
    },
    template,
    controller: 'InitiativesSidebarCtrl',
});
