import React from 'react';
import styled, { css } from 'styled-components';

import type { SearchItemImageBaseProps } from './SearchItemImageBase';
import SearchItemImageBase, { StyledImg } from './SearchItemImageBase';
import { SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE } from '../SearchWidgetConfiguration';

const ImageContainer = styled.div<{ $width: number }>`
    display: flex;
    justify-content: center;
    ${({ $width }) => css`
        width: ${`${$width}px`};
        max-width: ${`${$width}px`};
        min-width: ${`${$width}px`};
    `}
`;

interface SearchItemImageProps extends SearchItemImageBaseProps {}

const SearchItemImage: React.FC<SearchItemImageProps> = ({
    entity,
    cardsDensityConfiguration,
    imagePlaceholderMode = SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE,
}) => {
    const imageWidth = cardsDensityConfiguration.image.width;
    const imageHeight = cardsDensityConfiguration.image.height;

    if (entity.imageUrl) {
        return (
            <ImageContainer $width={imageWidth}>
                <StyledImg src={entity.imageUrl} alt={`item-${entity.id}`} $width={imageWidth} $height={imageHeight} />
            </ImageContainer>
        );
    }

    return (
        <SearchItemImageBase
            cardsDensityConfiguration={cardsDensityConfiguration}
            entity={entity}
            imagePlaceholderMode={imagePlaceholderMode}
        />
    );
};

export default SearchItemImage;
