import { useAngularService, useAngularWatch } from 'angulareact';

function useIsInEmptyTrial() {
    const $state = useAngularService('$state');
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const [isInEmptyTrial] = useAngularWatch(() => {
        return (
            $state.current.name === 'product.workers' &&
            (projectManager.isSelectedProjectInTrial() as boolean) &&
            projectManager.project?.id &&
            (!workflowFolderManager.projectIdToFoldersMap.get(projectManager.project.id) ||
                workflowFolderManager.projectIdToFoldersMap.get(projectManager.project.id)?.length === 0)
        );
    });

    return isInEmptyTrial;
}

export default useIsInEmptyTrial;
