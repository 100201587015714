<span>
    {{ item.reference1.id === item.target.id ? 'This has been identified as a problem' : 'A new problem found ' }}
    <span ng-if="item.reference1.inquiry && pm.inquiriesMap && pm.inquiriesMap[item.reference1.inquiry.id]">
        in
        <span>
            {{
                pm.inquiriesMap[item.reference1.inquiry.id].name
                    ? pm.inquiriesMap[item.reference1.inquiry.id].name
                    : 'the
            ' + pm.inquiriesMap[item.reference1.inquiry.id].typeData.preTitle
            }}
        </span>
        {{ item.reference1.id !== item.target.id ? ':' : '' }}
    </span>
    <strong ng-if="item.reference1.id !== item.target.id" class="inline-block">{{ item.reference1.text }}</strong>
</span>
