/**
 * Calculates what should be the next reminder hour according to the given reminder object.
 * @param nextReminder - A next reminder object from the consts (should contain days [required] and hours [optional]).
 */
export function calculateNextReminderHour(nextReminder: { days?: number; hours?: number }) {
    let hours = nextReminder.hours;

    // In case we are dealing with "today" reminder.
    if (!hours && nextReminder.days === 0) {
        // calculate now + 4 hours with max 20:00
        const now = new Date();
        hours = now.getHours() + 4;
        if (hours > 20) {
            hours = 20;
        }
    }

    return hours;
}
