import styled, { css } from 'styled-components';

import ItemInterfaceHeaderTitles from '../../../SystemInterface/components/ItemInterface/ItemInterfaceHeaderTitles';

import { Theme } from '@tonkean/tui-theme';

export const ItemInterfaceSequenceHeaderWrapper = styled.div<{ $tabsView?: boolean }>`
    background-color: ${Theme.colors.gray};
    padding-top: 14px;
    border-radius: 16px 16px 0 0;

    ${({ $tabsView }) =>
        $tabsView &&
        css`
            background-color: ${Theme.colors.basicBackground};
        `};
`;

export const ItemInterfaceSequenceHeaderTitles = styled(ItemInterfaceHeaderTitles)`
    padding: 0 16px;
    margin-top: 16px;
    ${({ tabsView }) =>
        tabsView &&
        css`
            padding: 0;
        `};
`;
