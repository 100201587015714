<div class="padding-normal">
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-smartsheet"></div>
            <h4 class="flex-grow">Setup Smartsheet</h4>
        </div>
    </div>
    <tnk-smartsheet-sheet-selector
        project-integration="projectIntegration"
        configured-in-group-id="{{ configuredInGroupId }}"
        configured-in-workflow-version-id="{{ configuredInWorkflowVersionId }}"
        on-project-integration-created="onSmartsheetSelected(projectIntegration, fieldDefinitionsToCreate)"
        on-cancel="onCancel()"
    ></tnk-smartsheet-sheet-selector>
</div>
