import React from 'react';
import styled from 'styled-components';

import type { TaggableEntitiesItemsProps } from '../../entities';

import { FontSize, Theme } from '@tonkean/tui-theme';

const EmptyStateTitle = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    letter-spacing: 0.3px;
    padding: 4px 0;
    display: flex;
    align-items: center;
    cursor: auto;
`;

const EmptyStateText = styled.div<{ $isTitle?: boolean }>`
    margin-left: ${({ $isTitle }) => ($isTitle ? 'auto' : '8px')};
`;

const EntityTitle = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
`;

const TaggableEntitiesItems: React.FC<TaggableEntitiesItemsProps> = ({
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
}) => {
    if (entry.isEmptyState) {
        const EntryIcon = entry.icon || null;
        return (
            <EmptyStateTitle key={entry.id}>
                {EntryIcon && <EntryIcon />}
                <EmptyStateText $isTitle={entry.isTitle}>{entry.display}</EmptyStateText>
            </EmptyStateTitle>
        );
    } else if (entry.isTitle) {
        return <EntityTitle key={entry.id}>{entry.display}</EntityTitle>;
    } else {
        const EntryComponent = entry.component || null;
        return (
            EntryComponent && (
                <EntryComponent
                    entry={entry}
                    search={search}
                    highlightedDisplay={highlightedDisplay}
                    index={index}
                    focused={focused}
                />
            )
        );
    }
};

export default TaggableEntitiesItems;
