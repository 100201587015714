import type { IHttpResponse } from 'angular';
import React from 'react';
import styled from 'styled-components';

import CustomFieldRow, { CustomFieldRowDataTypes, type CustomFieldRowProps } from './CustomFieldRow';
import CustomFieldRowMultiInput from './CustomFieldRowMultiInput';

import { ErrorMessage, LoadingCircle, ModalBody } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntityFieldMetadataSubField } from '@tonkean/tonkean-entities';

const StyledModalBody = styled(ModalBody)`
    gap: 30px;
    display: flex;
    flex-direction: column;
`;

export const customFieldRowsData: CustomFieldRowProps[] = [
    {
        fieldNameId: 'isCustomFieldToggle',
        fieldLabel: 'Is this a custom field?',
        dataAutomation: 'modal-is-custom-field-toggle',
        dataType: CustomFieldRowDataTypes.TOGGLE,
    },
    {
        fieldNameId: 'collectedFieldName',
        fieldLabel: 'Collected field name',
        dataAutomation: 'modal-collected-field-name',
        dataType: CustomFieldRowDataTypes.INPUT,
        disabled: true,
    },
    {
        fieldNameId: 'fieldName',
        fieldLabel: 'Field name',
        subTitle: 'Name that the target application API is expecting',
        dataAutomation: 'modal-field-name',
        dataType: CustomFieldRowDataTypes.INPUT,
    },
    {
        fieldNameId: 'displayName',
        fieldLabel: 'Display name',
        subTitle: 'Parameter name to be shown in the action block',
        dataAutomation: 'modal-display-name',
        dataType: CustomFieldRowDataTypes.INPUT,
    },
    {
        fieldNameId: 'fieldType',
        fieldLabel: 'Field type',
        subTitle: 'Select the parameters data type (i.e. Text, Number, ...)',
        dataAutomation: 'modal-field-type',
        dataType: CustomFieldRowDataTypes.INPUT,
    },
    {
        fieldNameId: 'shouldIncludeInCreateActionToggle',
        fieldLabel: 'Include in "Create Actions"',
        subTitle: 'Display the parameter in Create actions',
        dataAutomation: 'modal-should-include-in-create-action',
        dataType: CustomFieldRowDataTypes.TOGGLE,
    },
    {
        fieldNameId: 'isRequiredInCreateActionToggle',
        fieldLabel: 'Required in Create',
        subTitle: 'Is this parameter mandatory in the module action block',
        dataAutomation: 'modal-is-required-in-create-action',
        dataType: CustomFieldRowDataTypes.TOGGLE,
    },
    {
        fieldNameId: 'shouldIncludeInUpdateAndCustomActionToggle',
        fieldLabel: 'Include in "Update & Custom Actions"',
        subTitle: 'Display the parameter in Update actions',
        dataAutomation: 'modal-should-include-in-update-and-custom-action',
        dataType: CustomFieldRowDataTypes.TOGGLE,
    },
];

interface Props {
    subFieldsError: IHttpResponse<any> | undefined;
    subFieldsLoading: boolean;
    subFields: ProjectIntegrationEntityFieldMetadataSubField[];
    subFieldsValidationError: string | undefined;
    addSubField: () => void;
    removeSubField: (index: number) => void;
    editSubField: (index: number, id: string, newName: string) => void;
}
const ProjectIntegrationEntityFieldMetadataModalBodyForm: React.FC<Props> = ({
    subFieldsError,
    subFieldsLoading,
    subFields,
    subFieldsValidationError,
    addSubField,
    removeSubField,
    editSubField,
}) => {
    return (
        <StyledModalBody>
            {customFieldRowsData.map((customFieldRowData: CustomFieldRowProps) => (
                <CustomFieldRow key={customFieldRowData.fieldNameId} {...customFieldRowData} />
            ))}
            {subFieldsError ? (
                <ErrorMessage>There was an error loading sub fields</ErrorMessage>
            ) : subFieldsLoading ? (
                <LoadingCircle />
            ) : (
                <CustomFieldRowMultiInput
                    initialSubFields={subFields}
                    fieldLabel="Sub Fields"
                    dataAutomation="modal-sub-fields"
                    subFieldsValidationError={subFieldsValidationError}
                    addSubField={addSubField}
                    removeSubField={removeSubField}
                    editSubField={editSubField}
                />
            )}
        </StyledModalBody>
    );
};

export default ProjectIntegrationEntityFieldMetadataModalBodyForm;
