import FormBuilder from './FormBuilder';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkFormBuilder',
        reactToAngular(FormBuilder, [
            'formId',
            'groupId',
            'workflowVersionId',
            'formType',
            'onFormUnselected',
            'onFormSelected',
        ]),
    );
