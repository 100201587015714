import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentGroupsDependencyReviewModal from './EnterpriseComponentGroupsDependencyReviewModal';
import TonkeanAvatarsBar from './TonkeanAvatarsBar';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle, Toggle } from '@tonkean/infrastructure';
import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const TableColumn = styled.div`
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid ${Theme.current.palette.mainColors.gray_300};
    display: table-cell;
    vertical-align: middle;
    width: 100%;
`;

const LoadingContainer = styled(LoadingCircle)`
    margin-top: 5px;
    margin-left: 5px;
`;

const DisplayName = styled(StateLink)`
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid ${Theme.current.palette.mainColors.gray_300};
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MakersColumn = styled(TableColumn)`
    padding-top: 10px !important;
`;

interface Props {
    enterpriseComponentWorkflowFolderAccess: {
        accessAllowed: boolean;
        id: string;
        displayName: string;
        projectId: string;
    };
    enterpriseComponentId: string;
    enterpriseComponentType: EnterpriseComponentType;
    onError: (errText: string) => void;
    onAllowAccessChange: (
        id: string,
        partial: { accessAllowed: boolean } | ((current: { accessAllowed: boolean }) => { accessAllowed: boolean }),
    ) => void;
}

const SolutionsRowView: React.FC<Props> = ({
    enterpriseComponentWorkflowFolderAccess,
    enterpriseComponentId,
    enterpriseComponentType,
    onError,
    onAllowAccessChange,
}) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const [showTurnOffAccessReviewModal, setShowTurnOffAccessReviewModal] = useState<boolean>(false);
    const [moduleNamesDependentOnDataSource, setModuleNamesDependentOnDataSource] = useState<string[]>([]);

    const [
        { error: errorTogglingAccess, loading: loadingTogglingAccess },
        updateEnterpriseComponentWorkflowFolderAccess,
    ] = useLazyTonkeanService('updateEnterpriseComponentWorkflowFolderAccess');

    const [, getEnterpriseComponentWorkflowFoldersRelatedGroups] = useLazyTonkeanService(
        'getEnterpriseComponentWorkflowFoldersRelatedGroups',
    );

    const updateAllowedSolutions = useCallback(
        (updatedAccessAllowed: boolean | ((current: boolean) => boolean)) => {
            onAllowAccessChange(enterpriseComponentWorkflowFolderAccess.id, (current) => {
                const accessAllowed =
                    typeof updatedAccessAllowed === 'function'
                        ? updatedAccessAllowed(current.accessAllowed)
                        : updatedAccessAllowed;

                return { accessAllowed };
            });
        },
        [enterpriseComponentWorkflowFolderAccess.id, onAllowAccessChange],
    );

    /**
     * Acting after the update of the toggle in the server is over.
     */
    useEffect(() => {
        if (errorTogglingAccess) {
            // Revert the toggle back to what it was since we had an error.
            // Reason we're using the current value lambda flavour of the get state is to not have
            // a dependency on accessIsAllowed, which will cause us an infinite loop.
            updateAllowedSolutions((current) => !current);

            // Update parent with the fact that error happened.
            onError(getStateError(errorTogglingAccess));
        }

        setShowTurnOffAccessReviewModal(false);
    }, [onError, loadingTogglingAccess, errorTogglingAccess, updateAllowedSolutions]);

    /**
     * Opens the review modal before turning off access.
     */
    const openEnterpriseComponentGroupsDependencyReviewModal = useCallback(async () => {
        // We will open the modal only if the toggle is currently on (if it's on, means the user is
        // trying to turn it of).
        // Otherwise, we will perform the update with no validation.
        if (enterpriseComponentWorkflowFolderAccess.accessAllowed) {
            // We use await here as we need the results in order to decide how to act - open the modal or not.
            const groups: string[] = await getEnterpriseComponentWorkflowFoldersRelatedGroups(
                enterpriseComponentId,
                enterpriseComponentType,
                enterpriseComponentWorkflowFolderAccess.projectId,
                enterpriseComponentWorkflowFolderAccess.id,
            );

            if (groups.length) {
                setModuleNamesDependentOnDataSource(groups);
                setShowTurnOffAccessReviewModal(true);
            } else {
                updateAllowedSolutions(false);
                updateEnterpriseComponentWorkflowFolderAccess(
                    enterpriseComponentId,
                    enterpriseComponentType,
                    enterpriseComponentWorkflowFolderAccess.projectId,
                    enterpriseComponentWorkflowFolderAccess.id,
                    false,
                );
            }
        } else {
            updateAllowedSolutions(true);
            updateEnterpriseComponentWorkflowFolderAccess(
                enterpriseComponentId,
                enterpriseComponentType,
                enterpriseComponentWorkflowFolderAccess.projectId,
                enterpriseComponentWorkflowFolderAccess.id,
                true,
            );
        }
    }, [
        enterpriseComponentWorkflowFolderAccess.accessAllowed,
        enterpriseComponentWorkflowFolderAccess.projectId,
        enterpriseComponentWorkflowFolderAccess.id,
        getEnterpriseComponentWorkflowFoldersRelatedGroups,
        enterpriseComponentId,
        enterpriseComponentType,
        updateAllowedSolutions,
        updateEnterpriseComponentWorkflowFolderAccess,
    ]);

    return (
        <>
            <DisplayName
                data-automation="solution-row-solution-name"
                state="product.workers"
                params={{ folderId: enterpriseComponentWorkflowFolderAccess.id }}
            >
                {enterpriseComponentWorkflowFolderAccess.displayName}
            </DisplayName>

            <MakersColumn>
                <TonkeanAvatarsBar
                    avatars={
                        workflowFolderManager.projectIdToFolderIdToMakersMap[
                            enterpriseComponentWorkflowFolderAccess.projectId
                        ]?.[enterpriseComponentWorkflowFolderAccess.id] || []
                    }
                    avatarsDesktopLimit={5}
                    displayMoreLimit={10}
                    shouldDisplayViewTime={false}
                    showAllInDisplayMore={false}
                    showMoreAvatarsLink
                    separatedAvatars
                />
            </MakersColumn>

            <TableColumn>
                <Toggle
                    dataAutomation="allow-access"
                    disabled={loadingTogglingAccess}
                    size={InputSize.SMALL}
                    checked={enterpriseComponentWorkflowFolderAccess.accessAllowed}
                    onChange={() => openEnterpriseComponentGroupsDependencyReviewModal()}
                />
                {loadingTogglingAccess && <LoadingContainer className="loading-small" />}
            </TableColumn>

            <EnterpriseComponentGroupsDependencyReviewModal
                open={showTurnOffAccessReviewModal}
                onClose={() => setShowTurnOffAccessReviewModal(false)}
                confirm={() => {
                    // Update toggle.
                    updateAllowedSolutions(!enterpriseComponentWorkflowFolderAccess.accessAllowed);

                    // Update server.
                    updateEnterpriseComponentWorkflowFolderAccess(
                        enterpriseComponentId,
                        enterpriseComponentType,
                        enterpriseComponentWorkflowFolderAccess.projectId,
                        enterpriseComponentWorkflowFolderAccess.id,
                        !enterpriseComponentWorkflowFolderAccess.accessAllowed,
                    );
                }}
                typeDisplayName={enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}
                groupsName={moduleNamesDependentOnDataSource}
                workflowFolderName={enterpriseComponentWorkflowFolderAccess.displayName}
            />
        </>
    );
};

export default SolutionsRowView;
