import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import type { ModuleOption } from './ModulePickerMenuSection';
import ModulePickerMenuSection from './ModulePickerMenuSection';

import { Chevron, ChevronDirection, Menu, TextEllipsis } from '@tonkean/infrastructure';
import type { Group, WorkflowFolderCategory } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ModuleMenuOpenLoading = styled.span`
    padding-top: 1px;
    margin-left: 3px;
`;

const ModuleMenuOpenChevron = styled(Chevron)`
    margin-left: 8px;
`;

const ModuleMenu = styled(Menu)`
    max-width: 300px;
`;

const ModulePickerSelectedOption = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    background: white;
    border: none;
    cursor: pointer;
    max-width: 300px;
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_800};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};

    ${styledFocus};
`;

interface Props {
    groups: Group[] | undefined;
    workflowFolderCategories: WorkflowFolderCategory[] | undefined;
    selectedGroup: Group;
    isLoading: boolean;
    onMenuItemClicked: (item: ModuleOption) => void;
}

const ModulePicker: React.FC<Props> = ({
    groups,
    workflowFolderCategories,
    selectedGroup,
    isLoading,
    onMenuItemClicked,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Canonize the options to { value: X, label : Y }
    const groupsOptionsByWorkflowFolderCategoryName: Record<string, ModuleOption[]> = useMemo(() => {
        if (!workflowFolderCategories || !groups) {
            return {};
        }

        const entries = workflowFolderCategories.map((workflowFolderCategory) => {
            const groupOptions = groups
                .filter((group) => group.workflowFolderCategoryId === workflowFolderCategory.id)
                .sort((groupA, groupB) => (groupA.name > groupB.name ? 1 : -1))
                .map((group) => ({
                    label: group.name,
                    value: group.id,
                }));
            return [workflowFolderCategory.displayName, groupOptions];
        });
        // handle possible no workflowfoldercategory groups?

        return Object.fromEntries(entries);
    }, [workflowFolderCategories, groups]);

    return (
        <ModuleMenu
            show={isOpen}
            onClose={() => setIsOpen(false)}
            menuItems={
                <>
                    {Object.entries(groupsOptionsByWorkflowFolderCategoryName).map(
                        ([workflowFolderCategoryName, groupOptions]) => (
                            <ModulePickerMenuSection
                                key={workflowFolderCategoryName}
                                sectionName={workflowFolderCategoryName}
                                menuOptions={groupOptions}
                                selectedGroupId={selectedGroup.id}
                                onMenuItemClicked={onMenuItemClicked}
                            />
                        ),
                    )}
                </>
            }
        >
            <ModulePickerSelectedOption
                disabled={isLoading}
                onClick={() => setIsOpen(true)}
                data-automation="module-picker-selected-module"
            >
                <TextEllipsis numberOfLines={1} tooltip>
                    {selectedGroup.name}
                </TextEllipsis>
                {isLoading ? (
                    <ModuleMenuOpenLoading>
                        <i className="loading-small" />
                    </ModuleMenuOpenLoading>
                ) : (
                    <ModuleMenuOpenChevron
                        direction={isOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
                        className="pointer"
                        data-automation="module-picker-module-menu"
                    />
                )}
            </ModulePickerSelectedOption>
        </ModuleMenu>
    );
};

export default ModulePicker;
