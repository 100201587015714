<div ng-controller="SetupIntegrationTypeCtrl">
    <div class="common-size-s padding-top-xs">
        Where do you currently track your
        <strong>{{ data.selectedList.shortTitle }}</strong>
        ?
    </div>

    <div class="common-width-50-no-xs margin-top-lg">
        <button
            class="btn btn-secondary btn-lg common-width-100 block margin-bottom btn-text-left"
            ng-click="setManualStepsSetup()"
        >
            In my head / my notes
        </button>
        <button
            class="btn btn-secondary btn-lg common-width-100 block margin-bottom btn-text-left"
            ng-click="setManualStepsSetup('SPREADSHEET', 'SPREADSHEET')"
        >
            CSV / Spreadsheet file
        </button>
        <button
            class="btn btn-secondary btn-lg common-width-100 block margin-bottom btn-text-left"
            ng-click="setInitialListSteps()"
        >
            In a dedicated system
        </button>
        <div class="margin-top-lg common-size-xs common-color-grey">
            Not sure?
            <a
                ng-click="modalUtils.openChat('Hi, Would love help setting up a \'' + data.selectedList.title + '\' list.', true)"
            >
                Let us help.
            </a>
        </div>
    </div>
</div>
