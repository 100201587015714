<tnk-deprecated-people-selector
    class="flex-grow margin-none"
    no-validation="true"
    selected-people="data.selectedPeople"
    no-custom="true"
    max-tags="{{ data.maxPeople }}"
    on-tag-added="onPersonAdded()"
    on-tag-removed="onPersonRemoved()"
    placeholder="{{::data.placeholder || 'Search for a person'}}"
    include-external="true"
></tnk-deprecated-people-selector>
