import React, { useEffect } from 'react';

import SingleWorkerRunDataSourceDetails from './SingleWorkerRunDataSourceDetails';
import SingleWorkerRunDataSourceTable from './SingleWorkerRunDataSourceTable';
import getWorkerRunFullDescription from '../../../utils/getWorkerRunFullDescription';
import SingleWorkerRunContent from '../SingleWorkerRunContent';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import type { WorkerRun } from '@tonkean/tonkean-entities';

interface Props {
    /** If undefined, it means that it's the initial load of the worker run, and not just a reload. */
    workerRun?: WorkerRun;
}

const SingleWorkerRunDataSource: React.FC<Props> = ({ workerRun }) => {
    const { projectIntegration, error: projectIntegrationError } = useGetProjectIntegrationById(
        workerRun?.projectIntegrationId,
    );

    const [{ data: integrationExample, error: integrationExampleError }, getIntegrationExampleEntities] =
        useLazyTonkeanService('getIntegrationExampleEntities');

    useEffect(() => {
        const workerRunExternalType = workerRun?.externalType || workerRun?.externalActivity?.externalType;
        if (!workerRun?.projectIntegrationId || !workerRunExternalType) {
            return;
        }

        getIntegrationExampleEntities(workerRun.projectIntegrationId, workerRunExternalType, undefined, 0, false);
    }, [getIntegrationExampleEntities, workerRun]);

    const workerRunFullDescription = getWorkerRunFullDescription(workerRun, projectIntegration);
    const isError = !!integrationExampleError || !!projectIntegrationError;
    const isLoading = !workerRun || !projectIntegration || !integrationExample;

    return (
        <SingleWorkerRunContent
            startTime={workerRun?.startTime}
            icon={
                <ProjectIntegrationIcon
                    projectIntegrationId={workerRun?.projectIntegrationId}
                    width={20}
                    integrationType={workerRun?.externalActivity?.integrationType}
                />
            }
            title={workerRun && projectIntegration && workerRunFullDescription}
            workerRunStage={WorkerRunStage.DATA_SOURCE}
        >
            <SingleWorkerRunDataSourceDetails workerRun={workerRun} />
            <SingleWorkerRunDataSourceTable
                workerRun={workerRun}
                isError={isError}
                isLoading={isLoading}
                fieldNameToDisplayNameMap={integrationExample?.fieldNameToDisplayNameMap}
                fieldNameToFieldTypeMap={integrationExample?.fieldNameToFieldTypeMap}
                projectIntegration={projectIntegration}
            />
        </SingleWorkerRunContent>
    );
};

export default SingleWorkerRunDataSource;
