<div
    class="
        simple-name-editor
        flex-grow
        common-title-xs
        mod-light
        margin-right-lg
        relative
        common-title-md
        flex-grow-xs flex-vmiddle
        common-color-black common-hover-visible-container
    "
>
    <!-- display name display -->
    <button
        ng-if="!data.editMode"
        class="show-edit-when-hover-visibility common-title-sm btn btn-no-style common-text-wrap flex-vmiddle"
        ng-class="{ pointer: !data.isWorkflowFolderHidden }"
        ng-click="toggleEditMode($event)"
        data-test="display-name"
    >
        <tnk-text-ellipsis number-of-lines="1" tooltip="true" children="data.displayName"></tnk-text-ellipsis>
        <!-- Edit pencil-->
        <span
            ng-if="!data.isWorkflowFolderHidden"
            uib-tooltip="{{ data.disabledTooltip }}"
            tooltip-enable="data.isDisabled && data.disabledTooltip && data.disabledTooltip.length > 0"
        >
            <span class="fa fa-pencil common-color-link-blue common-size-xxxs edit-button mod-title"></span>
        </span>
        <span ng-if="data.isWorkflowFolderHidden" class="margin-left-6">
            <tnk-icon src="/images/icons/lock12.svg"></tnk-icon>
        </span>
    </button>

    <!-- Edit display name -->
    <form ng-if="data.editMode" class="flex-vmiddle name-editor" ng-submit="updateDisplayName()">
        <!-- display name input -->
        <input
            class="
                form-control
                common-input-only-line common-size-s
                padding-bottom-none
                margin-top-minus-xxs margin-right-xs
                common-title-sm
            "
            ng-model="data.editingDisplayName"
            required
        />

        <!-- Save display name button -->
        <button type="submit" class="btn btn-secondary btn-sm" data-automation="worker-edition-submit-name">
            Save
        </button>

        <!-- Loading state -->
        <span class="loading-small margin-left" ng-if="data.loadingDisplayName"></span>
        <!-- Error state -->
        <span class="common-color-danger common-size-xxxs margin-left" ng-if="data.errorSavingDisplayName">
            {{ data.errorSavingDisplayName }}
        </span>
    </form>
</div>
