import type { IRootScopeService } from 'angular';
import { useMemo } from 'react';

import EnterpriseComponentAuthorizationPage from '../../EnterpriseComponentsModule/components/EnterpriseComponentAuthorizationPage/EnterpriseComponentAuthorizationPage';
import EnterpriseComponentPageContentNotFound from '../../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/EnterpriseComponentPageContentNotFound';
import EnterpriseComponentVariablesPage from '../../EnterpriseComponentsModule/components/EnterpriseComponentVariablesPage/EnterpriseComponentVariablesPage';
import EnterpriseComponentPageSystemLog from '../../EnterpriseComponentsModule/components/SystemLogPage/components/EnterpriseComponentPageSystemLog';
import type { EnterpriseComponentPageMenuConfiguration } from '../../EnterpriseComponentsModule/entities/EnterpriseComponentPageMenuConfiguration';
import getEnterpriseComponentGeneralSection from '../../EnterpriseComponentsModule/utils/EnterpriseComponentGeneralSection';
import FieldMapperIntegrationPage from '../components/FieldMapperIntegrationData/FieldMapperIntegrationPage';
import ProjectIntegrationEntitiesPage from '../components/ProjectIntegrationEntitiesPage/ProjectIntegrationEntitiesPage';
import ProjectIntegrationFrontDoorPage from '../components/ProjectIntegrationFrontDoorPage/ProjectIntegrationFrontDoorPage';
import ProjectIntegrationPageAccessControlPage from '../components/ProjectIntegrationPageAccessControlPage/ProjectIntegrationPageAccessControlPage';
import ProjectIntegrationPageActionsPage from '../components/ProjectIntegrationPageActionsPage';
import ProjectIntegrationPageConnectionsPage from '../components/ProjectIntegrationPageConnectionsPage';
import ProjectIntegrationPageDataRetentionPage from '../components/ProjectIntegrationPageDataRetentionPage';
import ViewProjectIntegrationData from '../components/ViewProjectIntegrationData';
import type EnterpriseComponentPageExplorerItem from '../entities/EnterpriseComponentPageExplorerItem';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import type { ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import {
    IntegrationType,
    PersonPermissionRoleType,
    ProjectIntegrationPageMenuItemType,
} from '@tonkean/tonkean-entities';

/**
 * Gets the menu items and sections relevant for given project integration.
 * @param projectId The identifier of the project we work on.
 * @param $rootScope The $rootScope used for feature flagging sections and menu items.
 * @param projectIntegration the current project integration
 */
export default function useIntegrationPageMenuConfiguration(
    projectId: TonkeanId<TonkeanType.PROJECT>,
    $rootScope: IRootScopeService,
    projectIntegration?: ProjectIntegration,
): EnterpriseComponentPageMenuConfiguration<TonkeanType.PROJECT_INTEGRATION, EnterpriseComponentPageExplorerItem> {
    // Indicated whether we should show the control access tab.

    const featureShowEntities = useFeatureFlag('tonkean_feature_enable_project_integration_entities');

    const shouldShowAccessControlTab = useMemo(() => {
        switch (projectIntegration?.integration.integrationType) {
            case IntegrationType.EMAILWEBHOOK:
            case IntegrationType.WEBHOOK:
            case IntegrationType.NO_CODE_DATA_SOURCE:
                return true;
            default:
                return featureShowEntities;
        }
    }, [projectIntegration?.integration.integrationType, featureShowEntities]);

    const shouldShowVariablesTab = useMemo(
        () =>
            projectIntegration?.integration.integrationType === IntegrationType.WEBHOOK ||
            projectIntegration?.integration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE,
        [projectIntegration],
    );

    const shouldShowAuthorizationTab = useMemo(
        () =>
            projectIntegration?.integration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE &&
            !projectIntegration?.publicMarketplaceTemplateName,
        [projectIntegration],
    );

    const generalSection = useMemo(() => {
        return getEnterpriseComponentGeneralSection($rootScope.features.currentProject);
    }, [$rootScope.features.currentProject]);

    return useMemo(() => {
        return {
            sections: [
                generalSection,
                {
                    id: 'Settings',
                    title: 'Settings',
                    menuItems: [
                        {
                            id: ProjectIntegrationPageMenuItemType.VARIABLES,
                            title: 'Parameters',
                            hideItem: !shouldShowVariablesTab,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: EnterpriseComponentVariablesPage,
                        },

                        {
                            id: ProjectIntegrationPageMenuItemType.AUTHORIZATION,
                            title: 'Authentication',
                            hideItem: !shouldShowAuthorizationTab,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: EnterpriseComponentAuthorizationPage,
                            showDefaultBreadCrumbs: true,
                        },
                    ],
                },
                {
                    id: 'Connections',
                    title: 'Connections',
                    menuItems: [
                        {
                            id: ProjectIntegrationPageMenuItemType.CONNECTIONS,
                            title: 'Connections',
                            hideItem: false,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            showRedDot: (projectIntegration) => !projectIntegration.valid,
                            component: ProjectIntegrationPageConnectionsPage,
                        },
                        {
                            id: ProjectIntegrationPageMenuItemType.FIELD_MAPPER,
                            title: 'Field Mapper',
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: FieldMapperIntegrationPage,
                        },
                    ],
                },
                {
                    id: 'Input',
                    title: 'Input',
                    menuItems: [
                        {
                            id: 'encryption',
                            title: 'Encryption',
                            hideItem: true,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: EnterpriseComponentPageContentNotFound,
                        },
                        {
                            id: 'access-control',
                            title: 'Access Control',
                            hideItem: !shouldShowAccessControlTab,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: ProjectIntegrationPageAccessControlPage,
                        },
                        {
                            id: ProjectIntegrationPageMenuItemType.ENTITIES,
                            title: 'Entities',
                            hideItem: !featureShowEntities,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: ProjectIntegrationEntitiesPage,
                            showDefaultBreadCrumbs: true,
                            useOldLayout: true,
                        },
                    ],
                },
                {
                    id: 'Output',
                    title: 'Output',
                    menuItems: [
                        {
                            id: ProjectIntegrationPageMenuItemType.ACTIONS,
                            title: 'Actions',
                            hideItem: false,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: ProjectIntegrationPageActionsPage,
                        },
                    ],
                },
                {
                    id: 'Data',
                    title: 'Data',
                    menuItems: [
                        {
                            id: ProjectIntegrationPageMenuItemType.DATA_RETENTION,
                            title: 'Retention',
                            hideItem: false,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: ProjectIntegrationPageDataRetentionPage,
                        },
                        {
                            id: ProjectIntegrationPageMenuItemType.VIEW_DATA,
                            title: 'View Data',
                            hideItem: false,
                            permissionRoles: [PersonPermissionRoleType.ADMIN, PersonPermissionRoleType.MAKER],
                            component: ViewProjectIntegrationData,
                            showDefaultBreadCrumbs: true,
                            useOldLayout: true,
                        },
                        {
                            id: ProjectIntegrationPageMenuItemType.FRONT_DOOR,
                            title: 'AI Front Door',
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: ProjectIntegrationFrontDoorPage,
                        },
                        {
                            id: 'data-log',
                            title: 'Data Log',
                            hideItem: true,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: EnterpriseComponentPageContentNotFound,
                        },
                        {
                            id: ProjectIntegrationPageMenuItemType.SYSTEM_LOG,
                            title: 'Collection Log',
                            hideItem: false,
                            permissionRoles: [PersonPermissionRoleType.ADMIN],
                            component: EnterpriseComponentPageSystemLog,
                        },
                    ],
                },
            ],
        } as EnterpriseComponentPageMenuConfiguration<
            TonkeanType.PROJECT_INTEGRATION,
            EnterpriseComponentPageExplorerItem
        >;
    }, [
        generalSection,
        shouldShowVariablesTab,
        shouldShowAuthorizationTab,
        shouldShowAccessControlTab,
        featureShowEntities,
    ]);
}
