import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class LastIndexOfFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_LAST_INDEX_OF;
    override readonly sign = 'LastIndexOf';
    override readonly displayName = 'Last Index Of';
    override readonly description = 'Returns the last index of a substring inside a string.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input String',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Input Substring',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'From Index',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 0,
            defaultTab: FormulaPopoverTab.CONST,
        },
    ];
}
