import React, { useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

export interface OrigamiProjectData {
    collectionInterval: string | undefined;
}

const OrigamiRiskCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<OrigamiProjectData>> = ({
    onChangeOrInitIntegration,
    projectIntegrationData,
}) => {
    const [collectionInterval, setCollectionInterval] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();

    // Loading values in Edit mode
    useEffect(() => {
        const init = () => {
            if (projectIntegrationData?.collectionInterval) {
                setCollectionInterval(projectIntegrationData.collectionInterval);
            }
        };

        init();
    }, [projectIntegrationData]);

    // Saving the integration data
    useEffect(() => {
        if (collectionInterval && Number(collectionInterval) < 10) {
            setError('Error: The minimum collection time interval allowed is 10 minutes.');
            return;
        }
        setError('');
        const projectData = {
            collectionInterval,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
            },
            false,
        );
    }, [collectionInterval, onChangeOrInitIntegration]);

    return (
        <div className="flex flex-col">
            <label className="control-label text-center common-size-s">Integration Settings</label>

            <div className="form-group padding-top-md">
                <label className="col-sm-7 control-label padding-top-xs">
                    Data Collection Time Intervals (in minutes)
                </label>
                <div className="col-sm-5">
                    <input
                        type="number"
                        min="10"
                        value={collectionInterval}
                        className="form-control"
                        onChange={({ target }) => setCollectionInterval(target.value)}
                        autoComplete="off"
                        placeholder="Collection Time Intervals"
                    />
                    <div className="flex common-subtitle-inner common-size-xxs margin-bottom-md">
                        <span>Minimum of 10 minutes</span>
                    </div>
                </div>
            </div>

            {error && <div className="flex mod-center alert alert-danger margin-bottom">{error}</div>}
        </div>
    );
};

export default OrigamiRiskCustomizeSetupComponent;
