import { FormType, tonkeanTypeToIdentifyingPrefixMap, WorkflowVersionType } from '@tonkean/tonkean-entities';
import lateConstructController from '../../utils/lateConstructController';
import { TrackActions } from '@tonkean/flux';
import Utils, { getFullLinkUrl } from '@tonkean/utils';
import { analyticsWrapper } from '@tonkean/analytics';
import { getFormPrimaryColorOptions, getFormSecondaryColorOptions, STATUS_CODE_NOT_FOUND } from '@tonkean/constants';

/* @ngInject */
function fillFormCtrl(
    $scope,
    $rootScope,
    $timeout,
    $q,
    $state,
    $interval,
    utils,
    tonkeanService,
    projectManager,
    formManager,
    trackHelper,
    modal,
    workflowVersionManager,
    groupPermissions,
    pollingManager,
    customFieldsManager,
    environment,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.utils = utils;
    $scope.intervalId = null;

    $scope.data = {
        // The common data.
        workflowVersionId: ctrl.workflowVersionId,
        groupId: ctrl.groupId,
        sessionId: ctrl.sessionId,
        workerRunLogicId: ctrl.workerRunLogicId,

        // The specific data for each form.
        initialFormId: ctrl.formId,
        initialCustomTriggerId: ctrl.customTriggerId,
        initialInitiativeId: ctrl.initiativeId,
        initialWorkerRunId: ctrl.workerRunId,

        // The array of the form configurations.
        formsConfigurations: [],
        previousFormsConfigurations: [],

        displayCloseButtonWhenFormAnswered: ctrl.displayCloseButtonWhenFormAnswered,
        shouldPopAlert: ctrl.shouldPopAlert,
        formVersionType: ctrl.formVersionType,
        viewMode: ctrl.viewMode,

        selectedPrimaryColor: null,
        selectedSecondaryColor: ctrl.backgroundColor,
        selectedButtonsColor: null,
        expandHovered: ctrl.expandHovered,
        globalFormsIndex: 0,

        allErrorsNotRestrictingErrors: {},
        itemsValidationMap: {},
        fieldsValidationMap: {},

        noFormMatchEntityDetails: {},
        solutionBusinessReportId: ctrl.solutionBusinessReportId,

        loadingWaitForm: false,
        formAnsweredDefinition: null,
        group: ctrl.group,
        parentInitiativeId: ctrl.parentInitiativeId,
        displayInterface: false,
        itemInterfaceId: ctrl.itemInterfaceId,
        currentCustomTriggerId: ctrl.customTriggerId,
        showBackButton: false,
        initiativeData: {},
        interfaceData: {},
        widgetsData: {},
        workflowVersionData: {},
        projectData: {},
        finishedSequence: false,
        onSubmitResolved: ctrl.onSubmitResolved,
        evaluatedWaitConditionsMessage: '',
        submittedFilesForForms: {},
        itemInterface: ctrl.itemInterface,
        intakeLoader: false,
        formModalRef: ctrl.formModalRef,
        createUsingCustomTriggerId: ctrl.createUsingCustomTriggerId,
    };

    ctrl.$onDestroy = function () {
        analyticsWrapper.setGlobalContext({
            isInFormContext: false,
        });
    };

    /**
     * Initialization of the component.
     */
    ctrl.$onInit = function () {
        let intakePromise = $q.resolve();
        $scope.data.intakeLoader = true;

        if ($scope.data.formsConfigurations.length === 0 && ctrl.form) {
            const formConfiguration = {
                loading: false,
                form: ctrl.form,
                customTriggerId: $scope.data.initialCustomTriggerId,
                initiativeId: $scope.data.initialInitiativeId,
                selectedAddUnderInitiative: ctrl.addUnderInitiative,
                errorLoadingFormConfig: null,
                formFinished: false,
                workerRunId: $scope.data.initialWorkerRunId,
                formAnsweredDefinition: {},
                evaluatedWebformResponseMessage: null,
                evaluatedWebformResponseMessageSubtitle: null,
                evaluatedCustomResponseMessage: null,
                showDefaultResponseMessage: false,
                collectionForm: ctrl.collectionForm,
            };
            if (!ctrl.form.descriptionExpression?.evaluatedExpression && ctrl.form.description?.length) {
                formConfiguration.evaluatedDescription = ctrl.form.description;
            }

            $scope.data.formsConfigurations.push(formConfiguration);
        } else if (ctrl.formId) {
            intakePromise = loadFormIntoFormsConfigurations(
                $scope.data.initialFormId,
                $scope.data.initialCustomTriggerId,
                $scope.data.initialInitiativeId,
                $scope.data.initialWorkerRunId,
                -1,
            );
        } else if (ctrl.itemInterfaceId && $scope.data.itemInterface) {
            if (projectManager.project.forceThemeConfiguration) {
                $scope.data.selectedPrimaryColor = projectManager?.project?.themeConfiguration?.primaryColor;
            } else {
                $scope.data.selectedPrimaryColor = $scope.data.itemInterface?.themeConfiguration?.headerBackgroundColor;
            }
            $scope.data.displayInterface = true;
            intakePromise = openNextInterface(
                ctrl.itemInterfaceId,
                $scope.data.initialCustomTriggerId,
                $scope.data.initialWorkerRunId,
                false,
                null,
                $scope.data.initialInitiativeId,
                $scope.data.itemInterface,
            );
        }

        intakePromise.then(() => {
            $timeout(() => {
                $scope.data.intakeLoader = false;
            }, 0);
            updateCurrentCustomTriggerId($scope.data.initialCustomTriggerId);

            if (ctrl.formId) {
                analyticsWrapper.setGlobalContext({
                    isInFormContext: true,
                    formContextDraftMode: ctrl.formVersionType?.toUpperCase() === WorkflowVersionType.DRAFT,
                });

                const form = $scope.data.formsConfigurations[0].form;
                $scope.data.selectedPrimaryColor = form.definition.primaryColor || getFormPrimaryColorOptions().default;

                $scope.data.selectedSecondaryColor =
                    form.definition.secondaryColor || getFormSecondaryColorOptions().default;

                $scope.data.selectedButtonsColor =
                    form.definition.buttonsColor || getFormSecondaryColorOptions().default;

                const formQuestionType = form.formQuestionType;

                if (formQuestionType === 'COLLECT_INNER_ITEMS') {
                    $scope.data.isGroupCollaborator = groupPermissions.isCurrentUserGroupCollaborator(
                        $scope.pm.groupsMap[$scope.data.groupId],
                    );
                    if (!$scope.data.isGroupCollaborator) {
                        // Alerting user with an error
                        $rootScope.$emit('alert', {
                            msg: `Additional permissions required to fill out this form`,
                            type: 'warning',
                        });
                    }
                }
                if ($state.params.initialValues) {
                    try {
                        const decodedString = Utils.fromBase64($state.params.initialValues);
                        const initialValues = JSON.parse(decodedString);

                        $scope.data.formsConfigurations[0].prepopulatedValues = initialValues;
                    } catch (error) {
                        // If this piece of code fails we just want to skip this logic
                        console.error(`Error trying to convert to base 64. [${$state.params.initialValues}]`, error);
                    }
                }
            }
        });
    };

    $scope.openTrackModalView = function (index) {
        modal.openViewInitiative($scope.data.formsConfigurations[index].selectedAddUnderInitiative.id);
    };

    function popInitiativeCreatedAlertIfNeeded(index, initiativeId) {
        const submittedFormConfiguration = $scope.data.formsConfigurations[index];
        trackHelper
            .getInitiativeById(initiativeId, true)
            .then((reloadedCreatedInitiative) => {
                // If its a create form we broadcast that a new initiative was created.
                if (submittedFormConfiguration.form.formType === FormType.CREATE) {
                    broadcastCreatedInitiative(
                        reloadedCreatedInitiative,
                        submittedFormConfiguration.form.displayName,
                        submittedFormConfiguration.workerRunId,
                    );
                }
            })
            .catch((error) => {
                if (STATUS_CODE_NOT_FOUND.includes(error?.status)) {
                    $state.go('noaccesspage');
                }
            });
    }

    /**
     * Occurs once the form is closed.
     */
    $scope.closeForm = function (index) {
        if (ctrl.onFormClosed) {
            ctrl.onFormClosed();
        }
    };

    $scope.linkClicked = function () {
        $interval.cancel($scope.intervalId);
    };

    function checkIfAllErrorsNotRestrictingErrors(itemsErrorMap, fieldsErrorMap) {
        const allItemsErrorsNotRestrictingErrors = itemsErrorMap
            ? Object.values(itemsErrorMap)
                  .flatMap((fieldsMap) => Object.values(fieldsMap))
                  .flat()
                  .every((error) => !error.restrictSubmission)
            : true;

        const allFieldsErrorsNotRestrictingErrors = fieldsErrorMap
            ? Object.values(fieldsErrorMap)
                  .flat()
                  .every((error) => !error.restrictSubmission)
            : true;

        return allItemsErrorsNotRestrictingErrors && allFieldsErrorsNotRestrictingErrors;
    }

    function pollConditions(
        formAnsweredDefinition,
        data,
        reloadedCreatedInitiative,
        index,
        submittedFiles = [],
        isLastInSequence,
        evaluatedCustomResponseMessage,
        evaluatedWebformResponseMessage,
        evaluatedWebformResponseMessageSubtitle,
        evaluatedCustomInterfaceLabel,
    ) {
        const waitFormConditions = formAnsweredDefinition?.waitFormConditions;

        $scope.data.loadingWaitForm = true;
        // Next form is of wait conditions type, start polling conditions.
        pollingManager.registerPollingPromise(
            () => {
                return tonkeanService.runQueryOnInitiative(data.initiative.id, waitFormConditions);
            },
            (serverResponse) => serverResponse['matched'],
            () => {
                if (!formAnsweredDefinition.isCustomInterfaceInSequence) {
                    $scope.data.loadingWaitForm = false;
                }

                handleNextStep(
                    data.alreadyHandledFormAnsweredTrigger,
                    data.formAnsweredCustomTrigger,
                    data.nextWorkerRunId,
                    reloadedCreatedInitiative,
                    data.evaluatedUrlLink,
                    data.redirectDelay,
                    data.redirectToNewTab,
                    index,
                    submittedFiles,
                    isLastInSequence,
                    evaluatedCustomResponseMessage,
                    evaluatedWebformResponseMessage,
                    evaluatedWebformResponseMessageSubtitle,
                    evaluatedCustomInterfaceLabel,
                );
            },
            5000,
            1,
            true,
            1000,
        );
    }

    /**
     * Function which handles the submitted form, after it already updated/created the items

     * @param index The index of the specific form configuration we want
     * @param fields list of answered fields
     * @param ignoreNonRestrictingErrors Whether it should submit the form even if there are errors which are not restricting the
     * submission.
     */
    $scope.onSubmit = function (index, fields = [], ignoreNonRestrictingErrors = true, submittedFiles = []) {
        const submittedFormConfiguration = $scope.data.formsConfigurations[index];
        let confirmationPromise = $q.resolve();

        const shouldAutoSubmitForm =
            submittedFormConfiguration.form.definition.autoSubmitForm &&
            $rootScope.features[$scope.pm.project.id].tonkean_feature_auto_submit_form;

        if (submittedFormConfiguration.formFinished && !shouldAutoSubmitForm) {
            confirmationPromise = modal.openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Re-Submit Form',
                            body: 'Re-submitting the form might reload the following forms, are you sure you want to re-submit?',
                            okLabel: 'Re-Submit',
                            cancelLabel: 'Cancel',
                        };
                    },
                },
            }).result;
        }

        return confirmationPromise.then(() => {
            $scope.data.itemsValidationMap[index] = {};
            $scope.data.fieldsValidationMap[index] = {};
            $scope.data.allErrorsNotRestrictingErrors[index] = false;
            $scope.data.noFormMatchEntityDetails[submittedFormConfiguration.form.id] = undefined;

            let submittedFormData;
            if (submittedFormConfiguration.form.formType === FormType.UPDATE) {
                submittedFormData = tonkeanService.answerWorkerUpdateForm(
                    submittedFormConfiguration.form.workflowVersion.id,
                    $scope.data.formVersionType,
                    submittedFormConfiguration.form.id,
                    submittedFormConfiguration.initiativeId,
                    submittedFormConfiguration.workerRunId,
                    submittedFormConfiguration.customTriggerId,
                    $scope.data.sessionId,
                    fields || [],
                    $scope.data.workerRunLogicId,
                    $scope.data.globalFormsIndex > 0,
                    ignoreNonRestrictingErrors,
                    $state.current.name === 'product.processContributorSolutionBusinessReport',
                    $scope.data.solutionBusinessReportId,
                    $scope.data.parentInitiativeId,
                );
            } else {
                if ($scope.data.parentInitiativeId) {
                    submittedFormData = tonkeanService.answerWorkerCreateInnerItemsForm(
                        submittedFormConfiguration.form.workflowVersion.id,
                        $scope.data.formVersionType,
                        submittedFormConfiguration.form.id,
                        submittedFormConfiguration.initiativeId,
                        submittedFormConfiguration.workerRunId,
                        submittedFormConfiguration.customTriggerId,
                        $scope.data.sessionId,
                        fields || [],
                        $scope.data.workerRunLogicId,
                        $scope.data.globalFormsIndex > 0,
                        ignoreNonRestrictingErrors,
                        $scope.data.parentInitiativeId,
                        $state.current.name === 'product.processContributorSolutionBusinessReport',
                        $scope.data.solutionBusinessReportId,
                        $scope.data.createUsingCustomTriggerId,
                    );
                } else {
                    submittedFormData = tonkeanService.answerWorkerCreateForm(
                        submittedFormConfiguration.form.workflowVersion.id,
                        $scope.data.formVersionType,
                        submittedFormConfiguration.form.id,
                        submittedFormConfiguration.initiativeId,
                        submittedFormConfiguration.workerRunId,
                        submittedFormConfiguration.customTriggerId,
                        $scope.data.sessionId,
                        fields || [],
                        $scope.data.workerRunLogicId,
                        $scope.data.globalFormsIndex > 0,
                        ignoreNonRestrictingErrors,
                        $state.current.name === 'product.processContributorSolutionBusinessReport',
                        $scope.data.solutionBusinessReportId,
                        $scope.data.parentInitiativeId,
                    );
                }
            }
            // Answering the form for the specific index.
            return submittedFormData
                .then((data) =>
                    $scope.handlePostFormSubmit(data, index, submittedFiles).then(() => {
                        if ($scope.data.onSubmitResolved) {
                            $scope.data.onSubmitResolved();
                        }
                    }),
                )
                .catch((error) => {
                    console.error(error);
                    const errorObj = error?.data?.data?.error?.data;

                    if (errorObj?.formValidationError) {
                        $scope.data.itemsValidationMap[index] = errorObj.innerItemIdToFieldToErrorMessage || {};
                        $scope.data.fieldsValidationMap[index] = errorObj.fieldToErrorMessage || {};

                        $scope.data.allErrorsNotRestrictingErrors[index] = checkIfAllErrorsNotRestrictingErrors(
                            errorObj.innerItemIdToFieldToErrorMessage,
                            errorObj.fieldToErrorMessage,
                        );
                    }

                    return Promise.reject(error);
                });
        });
    };

    $scope.handlePostFormSubmit = (data, index = 0, submittedFiles = []) => {
        const submittedFormConfiguration = $scope.data.formsConfigurations[index];

        if (submittedFiles?.length > 0 && submittedFormConfiguration?.form?.id) {
            const submittedFormId = submittedFormConfiguration.form.id;
            $scope.data.submittedFilesForForms = {
                ...$scope.data.submittedFilesForForms,
                [submittedFormId]: submittedFiles,
            };
        }

        // No next initiative
        if (!data.nextInitiative) {
            // If we have form entity match details
            if (data.matchFieldEntityDetails && submittedFormConfiguration) {
                // Mark which form needs a match
                $scope.data.noFormMatchEntityDetails = {
                    ...$scope.data.noFormMatchEntityDetails,
                    [submittedFormConfiguration.form.id]: data.matchFieldEntityDetails,
                };

                // No match so cant show the next forms
                $scope.data.formsConfigurations.length = index + 1;
                // We have to set the form to not finished because this is a match entity form
                // If theres no match, we cant continue to next form until there is
                $scope.data.formsConfigurations[index].formFinished = false;
                return $q.resolve();
            } else {
                // No next initiative and no match entity details, Shouldn't happen, default to regular initiative
                data.nextInitiative = data.initiative;
            }
        }
        let loadGroupData = $q.resolve();
        // If the next initiative is from a different module, it means all the data (fields, triggers, workflowversion) is different
        // So to be safe we load all the group's data
        let isDifferentModule = false;
        if ($scope.data.previousFormsConfigurations?.length > 0) {
            const previousForm =
                $scope.data.previousFormsConfigurations[$scope.data.previousFormsConfigurations.length - 1];
            isDifferentModule = previousForm.initiative.id !== data.nextInitiative.group.id;
        }

        if (isDifferentModule) {
            loadGroupData = projectManager.getGroup(data.nextInitiative.group.id, false, false);
        }

        // Fetching the initiative next in line for form filling.
        let getNextInitiativePromise = $q.resolve();
        if (data.nextInitiative) {
            getNextInitiativePromise = trackHelper.getInitiativeById(data.nextInitiative.id, true);
        }

        // Fetching the initiative we just submitted the form on, to make sure cache is updated.
        let getSubmittedInitiativePromise = $q.resolve();
        if (submittedFormConfiguration?.initiativeId || $scope.data.initialInitiativeId) {
            getSubmittedInitiativePromise = trackHelper.getInitiativeById(
                submittedFormConfiguration?.initiativeId || $scope.data.initialInitiativeId,
                true,
            );
        }

        return Promise.all([getNextInitiativePromise, loadGroupData, getSubmittedInitiativePromise]).then(
            ([reloadedCreatedInitiative, _]) => {
                if (submittedFormConfiguration) {
                    // Let track views know they should re render because of an update.
                    if (submittedFormConfiguration.initiativeId) {
                        TrackActions.trackDataUpdated(submittedFormConfiguration.initiativeId);
                    }

                    // We update that the specific form was submitted.
                    submittedFormConfiguration.shouldPopAlert = true;
                    submittedFormConfiguration.formFinished = true;
                    submittedFormConfiguration.initiativeId = data.nextInitiative.id;
                    submittedFormConfiguration.workerRunId = data.workerRunId;
                    submittedFormConfiguration.evaluatedCustomResponseMessage = data.evaluatedCustomResponseMessage;
                    submittedFormConfiguration.evaluatedWebformResponseMessage = data.evaluatedWebformResponseMessage;
                    submittedFormConfiguration.evaluatedWebformResponseMessageSubtitle =
                        data.evaluatedWebformResponseMessageSubtitle;
                    submittedFormConfiguration.evaluatedWaitConditionsMessage = data.evaluatedWaitConditionsMessage;
                    submittedFormConfiguration.evaluatedCustomInterfaceLabel = data.evaluatedCustomInterfaceLabel;
                    submittedFormConfiguration.evaluatedUrlLink = data.evaluatedUrlLink;
                    submittedFormConfiguration.redirectDelay = data.redirectDelay;
                    submittedFormConfiguration.redirectToNewTab = data.redirectToNewTab;
                }

                // Taking the workflow version of the new group
                if (isDifferentModule) {
                    const nextWorkflowVersion = data.nextInitiative.isDraftInitiative
                        ? workflowVersionManager.getDraftVersionFromCache(data.nextInitiative.group.id)
                        : workflowVersionManager.getPublishedVersionFromCache(data.nextInitiative.group.id);
                    $scope.data.workflowVersionId = nextWorkflowVersion.id;
                }

                // When handling next steps we might delete the configuration needed for this
                // popup so we need to first try and popup before handling next step
                popInitiativeCreatedAlertIfNeeded(index, data.initiative.id);

                $scope.data.formAnsweredDefinition =
                    data.formAnsweredCustomTrigger?.customTriggerActions[0]?.customTriggerActionDefinition;
                // check if wait form, and continue to next step only if conditions are met
                if ($scope.data.formAnsweredDefinition?.waitFormType === 'WAIT_CONDITIONS') {
                    $scope.data.displayInterface = false;
                    $scope.data.evaluatedWaitConditionsMessage = data.evaluatedWaitConditionsMessage;
                    pollConditions(
                        $scope.data.formAnsweredDefinition,
                        data,
                        reloadedCreatedInitiative,
                        index,
                        submittedFiles,
                        data.isLastInSequence,
                        data.evaluatedCustomResponseMessage,
                        data.evaluatedWebformResponseMessage,
                        data.evaluatedWebformResponseMessageSubtitle,
                        data.evaluatedCustomInterfaceLabel,
                    );
                } else {
                    // We handle the next step we want to do.
                    handleNextStep(
                        data.alreadyHandledFormAnsweredTrigger,
                        data.formAnsweredCustomTrigger,
                        data.nextWorkerRunId,
                        reloadedCreatedInitiative,
                        data.evaluatedUrlLink,
                        data.redirectDelay,
                        data.redirectToNewTab,
                        index,
                        submittedFiles,
                        data.isLastInSequence,
                        data.evaluatedCustomResponseMessage,
                        data.evaluatedWebformResponseMessage,
                        data.evaluatedWebformResponseMessageSubtitle,
                        data.evaluatedCustomInterfaceLabel,
                    );
                }
            },
        );
    };

    $scope.onSwitchedFromOneInterfaceToAnother = (customTriggerId) => {
        updateCurrentCustomTriggerId(customTriggerId);
    };

    function setCurrentPageState(initiative) {
        const currentState = { initiative };
        let shouldSaveCurrentState = false;
        if ($scope.data.itemInterfaceId || $scope.data.formsConfigurations?.length > 0) {
            if ($scope.data.displayInterface) {
                shouldSaveCurrentState = setCurrentStateForItemInterface(currentState);
            } else {
                shouldSaveCurrentState = setCurrentStateForForm(currentState);
            }
            if (shouldSaveCurrentState) {
                $scope.data.previousFormsConfigurations.push(currentState);
                $scope.data.showBackButton = $scope.data.previousFormsConfigurations.length > 0;
            }
        }
    }

    function setCurrentStateForItemInterface(currentState) {
        currentState.itemInterfaceId = $scope.data.itemInterfaceId;
        currentState.interfaceData = $scope.data.interfaceData;
        currentState.widgetsData = $scope.data.widgetsData;
        currentState.workflowVersionData = $scope.data.workflowVersionData;
        currentState.projectData = $scope.data.projectData;
        currentState.customTriggerId = $scope.data.currentCustomTriggerId;
        currentState.workflowVersionId = $scope.data.workflowVersionId;
        return true;
    }

    function setCurrentStateForForm(currentState) {
        currentState.customTriggerId = $scope.data.currentCustomTriggerId;

        // We don't want to enable go back to create forms, only to update forms
        if ($scope.data.formsConfigurations?.some((form) => form.form.formType === FormType.CREATE)) {
            const formsConfigurations = [];
            $scope.data.formsConfigurations.forEach((formData) => {
                if (formData.form.formType !== FormType.CREATE) {
                    formsConfigurations.push(formData);
                }
            });
            if (formsConfigurations.length === 0) {
                return false;
            }
            currentState.formsConfigurations = formsConfigurations;
        } else {
            currentState.formsConfigurations = $scope.data.formsConfigurations;
        }

        return true;
    }

    $scope.getPreviousState = () => {
        if ($scope.data.previousFormsConfigurations.length > 0) {
            const previousStep = $scope.data.previousFormsConfigurations.pop();

            if (previousStep.itemInterfaceId) {
                const promises = [];
                promises.push(
                    tonkeanService.getInterfaceWidgets(
                        previousStep.itemInterfaceId,
                        previousStep.workflowVersionId,
                        $scope.data.initiativeData.isDraftInitiative
                            ? WorkflowVersionType.DRAFT
                            : WorkflowVersionType.PUBLISHED,
                        previousStep.initiative.id,
                    ),
                    trackHelper.getInitiativeById(previousStep.initiative.id, true),
                );
                return Promise.all(promises).then((results) => {
                    $scope.data.itemInterfaceId = previousStep.itemInterfaceId;
                    $scope.data.displayInterface = !!previousStep.itemInterfaceId;
                    $scope.data.workflowVersionId = previousStep.workflowVersionId;
                    $scope.data.interfaceData = previousStep.interfaceData;
                    $scope.data.workflowVersionData = previousStep.workflowVersionData;
                    $scope.data.projectData = previousStep.projectData;
                    $scope.data.widgetsData = results[0];
                    $scope.data.initiativeData = results[1];
                    updateCurrentCustomTriggerId(previousStep.customTriggerId);
                    calculateShowBackButton();
                });
            }

            if (previousStep.formsConfigurations) {
                previousStep.formsConfigurations.forEach((formData) => {
                    formData.formFinished = false;
                });
                $scope.data.formsConfigurations = previousStep.formsConfigurations;
                $scope.data.displayInterface = false;
                updateCurrentCustomTriggerId(previousStep.customTriggerId);
            }
            $scope.data.allErrorsNotRestrictingErrors = {};
            $scope.data.isLastInSequence = false;
        }
        calculateShowBackButton();
    };

    function calculateShowBackButton() {
        if ($scope.data.previousFormsConfigurations.length < 1) {
            $scope.data.showBackButton = false;
        }
    }

    /**
     * A function which handles what happens on an error
     * @param errorMessage - the error message to display
     */
    $scope.onError = function (errorMessage) {
        $rootScope.$emit('alert', { msg: `Saving item failed. ${errorMessage || ''}`, type: 'warning' });
    };

    /**
     * Handle what to do next when a form is submitted
     * @param alreadyHandledFormAnsweredTrigger
     * @param formAnsweredCustomTrigger
     * @param workerRunId
     * @param reloadedCreatedInitiative
     * @param evaluatedUrlLink
     */
    function handleNextStep(
        alreadyHandledFormAnsweredTrigger,
        formAnsweredCustomTrigger,
        workerRunId,
        reloadedCreatedInitiative,
        evaluatedUrlLink,
        redirectDelay,
        redirectToNewTab,
        index,
        submittedFiles = [],
        isLastInSequence,
        evaluatedCustomResponseMessage,
        evaluatedWebformResponseMessage,
        evaluatedWebformResponseMessageSubtitle,
        evaluatedCustomInterfaceLabel,
    ) {
        let nextFormPromise = $q.resolve();
        // If we already submitted the form we want to make sure we clear the array
        if ($scope.data.formsConfigurations?.length > 0 && !!$scope.data.formsConfigurations[index].formFinished) {
            if (submittedFiles?.length > 0) {
                $scope.data.formsConfigurations[index].submittedFiles = submittedFiles;
            }
            // We are changing the length to the index + 1
            // that way we cut all the forms after this form in the array.
            $scope.data.formsConfigurations.length = index + 1;
        }

        // If we have a form answered custom trigger, we might want to act on the form answer.
        if (
            !alreadyHandledFormAnsweredTrigger &&
            formAnsweredCustomTrigger &&
            formAnsweredCustomTrigger.customTriggerActions &&
            formAnsweredCustomTrigger.customTriggerActions.length
        ) {
            const firstAction = formAnsweredCustomTrigger.customTriggerActions[0];

            if (firstAction.customTriggerActionDefinition) {
                if ($scope.data.formsConfigurations?.length > 0) {
                    $scope.data.formsConfigurations[index].formAnsweredDefinition =
                        firstAction.customTriggerActionDefinition;
                    // Backwards compatibility
                    if (!$scope.data.formsConfigurations[index].formAnsweredDefinition.borderViewType) {
                        $scope.data.formsConfigurations[index].formAnsweredDefinition.borderViewType = 'MULTIPLE_FORMS';
                    }
                }
                if (firstAction.customTriggerActionDefinition.formId) {
                    if (
                        firstAction.customTriggerActionDefinition.formLocation === 'NEW' ||
                        $scope.data.itemInterfaceId
                    ) {
                        setCurrentPageState(reloadedCreatedInitiative);
                    }
                    nextFormPromise = openNextForm(
                        firstAction.customTriggerActionDefinition.formId,
                        formAnsweredCustomTrigger.id,
                        workerRunId,
                        reloadedCreatedInitiative.id,
                        firstAction.customTriggerActionDefinition.formLocation,
                        index,
                        isLastInSequence,
                    );
                } else if (
                    firstAction.customTriggerActionDefinition.evaluatedUrlLink ||
                    firstAction.customTriggerActionDefinition.isCustomInterfaceLink
                ) {
                    // Though the definition usually holds an expression as a url link, this time the backend was responsible
                    // to return us the url link expression already evaluated so we can actually use it.
                    evaluatedUrlLink = getFullLinkUrl(evaluatedUrlLink, environment.appUrl);

                    setSummaryTrigger(
                        firstAction.customTriggerActionDefinition,
                        evaluatedUrlLink,
                        true,
                        redirectDelay,
                        redirectToNewTab,
                        evaluatedCustomResponseMessage,
                        evaluatedWebformResponseMessage,
                        evaluatedWebformResponseMessageSubtitle,
                        evaluatedCustomInterfaceLabel,
                        formAnsweredCustomTrigger.id,
                    );

                    $scope.data.redirectUrlCounter = redirectDelay !== undefined ? redirectDelay : 5;
                    if ($scope.data.redirectUrlCounter > 0) {
                        $scope.intervalId = $interval(function () {
                            $scope.data.redirectUrlCounter--;
                            if ($scope.data.redirectUrlCounter <= 0) {
                                $interval.cancel($scope.intervalId);
                                if (redirectToNewTab === undefined || redirectToNewTab) {
                                    window.open(evaluatedUrlLink, '_blank');
                                } else {
                                    window.open(evaluatedUrlLink, '_self');
                                }
                            }
                        }, 1000);
                    }
                } else if (firstAction.customTriggerActionDefinition.isCustomInterfaceInSequence) {
                    nextFormPromise = openNextInterface(
                        firstAction.customTriggerActionDefinition.itemInterfaceId,
                        formAnsweredCustomTrigger.id,
                        workerRunId,
                        isLastInSequence,
                        reloadedCreatedInitiative,
                        reloadedCreatedInitiative.id,
                        null,
                    );
                } else {
                    if (!doAnyConfigsHaveResponse(index) && $scope.data.formsConfigurations?.length > 0) {
                        $scope.data.formsConfigurations[index].showDefaultResponseMessage = true;
                    }
                    setSummaryTrigger(
                        firstAction.customTriggerActionDefinition,
                        evaluatedUrlLink,
                        false,
                        redirectDelay,
                        redirectToNewTab,
                        evaluatedCustomResponseMessage,
                        evaluatedWebformResponseMessage,
                        evaluatedWebformResponseMessageSubtitle,
                        evaluatedCustomInterfaceLabel,
                        formAnsweredCustomTrigger.id,
                    );
                }
            }
        } else {
            if (
                $scope.data.formsConfigurations?.length > 0 &&
                !doAnyConfigsHaveResponse(index) &&
                (formAnsweredCustomTrigger?.showWebFormResponse || !formAnsweredCustomTrigger)
            ) {
                $scope.data.formsConfigurations[index].showDefaultResponseMessage = true;
            }
            $scope.summaryTrigger = { showDefaultResponseMessage: true };
            $scope.data.displayInterface = false;
            $scope.data.finishedSequence = true;
        }

        // Scroll to next form step after the form has been loaded
        nextFormPromise.finally(() => {
            // The next digest cycle should contain the loaded element
            $timeout(() => {
                const someElement = angular.element(document.querySelectorAll(`.single-form`)[index + 1]);
                const scrollContainer = angular.element(document.querySelector('#project-board'));

                if (someElement?.getBoundingClientRect) {
                    scrollContainer.scrollTo(someElement, 0, 500);
                }
            });
        });
    }

    function setSummaryTrigger(
        triggerActionDefinition,
        evaluatedUrlLink,
        redirectToUrl,
        redirectDelay,
        redirectToNewTab,
        evaluatedCustomResponseMessage,
        evaluatedWebformResponseMessage,
        evaluatedWebformResponseMessageSubtitle,
        evaluatedCustomInterfaceLabel,
        customTriggerId,
    ) {
        $scope.summaryTrigger = triggerActionDefinition;

        if (
            !triggerActionDefinition.showDefaultResponseMessage &&
            !triggerActionDefinition.evaluatedWebformResponseMessage &&
            !evaluatedWebformResponseMessage &&
            !evaluatedCustomResponseMessage
        ) {
            $scope.summaryTrigger.showDefaultResponseMessage = true;
        }
        if (evaluatedUrlLink) {
            $scope.summaryTrigger.evaluatedUrlLink = evaluatedUrlLink;
            if (triggerActionDefinition.isCustomInterfaceLink) {
                tonkeanService
                    .getItemInterfaceById(triggerActionDefinition.itemInterfaceId, $scope.data.workflowVersionId, null)
                    .then((interfaceDetails) => {
                        $scope.summaryTrigger.evaluatedUrlLabel = interfaceDetails.displayName;
                    });
            } else if (!!triggerActionDefinition.urlLink) {
                $scope.summaryTrigger.evaluatedUrlLabel = triggerActionDefinition?.evaluatedUrlLink;
            } else {
                $scope.summaryTrigger.evaluatedUrlLabel = triggerActionDefinition?.evaluatedCustomInterfaceLabel;
            }
        }
        $scope.summaryTrigger.redirectToUrl = redirectToUrl;
        $scope.summaryTrigger.redirectDelay = redirectDelay;
        $scope.summaryTrigger.redirectToNewTab = redirectToNewTab;
        $scope.summaryTrigger.evaluatedCustomResponseMessage = evaluatedCustomResponseMessage;
        $scope.summaryTrigger.evaluatedWebformResponseMessage = evaluatedWebformResponseMessage;
        $scope.summaryTrigger.evaluatedWebformResponseMessageSubtitle = evaluatedWebformResponseMessageSubtitle;
        $scope.summaryTrigger.evaluatedCustomInterfaceLabel = evaluatedCustomInterfaceLabel;
        $scope.data.displayInterface = false;
        $scope.data.finishedSequence = true;

        updateCurrentCustomTriggerId(customTriggerId);
    }

    function openNextInterface(
        itemInterfaceId,
        customTriggerId,
        workerRunId,
        isLastInSequence,
        initiativeData,
        initiativeId,
        itemInterface,
    ) {
        if (initiativeData) {
            setCurrentPageState(initiativeData);
        }
        const promises = [];

        return workflowVersionManager
            .getCachedWorkflowVersionOrGetFromServer($scope.data.workflowVersionId)
            .then((workflowVersionData) => {
                promises.push(
                    tonkeanService.getInterfaceWidgets(
                        itemInterfaceId,
                        $scope.data.workflowVersionId,
                        workflowVersionData.workflowVersionType,
                        initiativeId,
                    ),
                );
                if (!itemInterface) {
                    promises.push(
                        tonkeanService.getItemInterfaceById(itemInterfaceId, $scope.data.workflowVersionId, null),
                    );
                } else {
                    promises.push(Promise.resolve(itemInterface));
                }
                if (!initiativeData) {
                    promises.push(trackHelper.getInitiativeById(initiativeId, false));
                } else {
                    promises.push(Promise.resolve(initiativeData));
                }

                return Promise.all(promises).then((results) => {
                    $scope.data.workflowVersionData = workflowVersionData;
                    $scope.data.initiativeData = results[2];
                    $scope.data.interfaceData = results[1];
                    $scope.data.widgetsData = results[0];
                    $scope.data.projectData = projectManager.project;
                    $scope.data.itemInterfaceId = itemInterfaceId;
                    $scope.data.displayInterface = true;
                    $scope.data.nextWorkerRunId = workerRunId;
                    $scope.data.isLastInSequence = isLastInSequence;
                    $scope.data.loadingWaitForm = false;
                    projectManager.addGroup($scope.data.interfaceData?.group);
                    updateCurrentCustomTriggerId(customTriggerId);
                });
            });
    }

    /**
     * Broadcast the initiative to the relevant places - emits a message in the rootScope and calls the parent's callback
     * with the created initiative.
     *
     * @param initiative - the created initiative.
     * @param formDisplayName - the form display name.
     */
    function broadcastCreatedInitiative(initiative, formDisplayName, relatedWorkerRunId) {
        // Call given parent callback
        if (ctrl.onInitiativeCreated) {
            ctrl.onInitiativeCreated({
                createdInitiative: initiative,
                relatedWorkerRunId,
                formDisplayName,
            });
        }
    }

    /**
     * Opens the next form in line. Initializes all values and regenerates the configuration.
     * @param formId
     * @param customerTriggerId
     * @param workerRunId
     * @param initiativeId
     */
    function openNextForm(
        formId,
        customerTriggerId,
        workerRunId,
        initiativeId,
        formLocation,
        previousFormIndex,
        isLastInSequence,
    ) {
        $scope.data.globalFormsIndex += 1;

        let finalPreviousFormIndex = previousFormIndex;
        if (!formLocation || formLocation === 'NEW' || $scope.data.itemInterfaceId) {
            $scope.data.formsConfigurations = [];
            finalPreviousFormIndex = -1;
        }

        $scope.data.formsConfigurations[finalPreviousFormIndex + 1] = {
            loading: true,
        };

        updateCurrentCustomTriggerId(customerTriggerId);

        return loadFormIntoFormsConfigurations(
            formId,
            customerTriggerId,
            initiativeId,
            workerRunId,
            finalPreviousFormIndex,
            isLastInSequence,
        );
    }

    function loadFormIntoFormsConfigurations(
        formId,
        customTriggerId,
        initiativeId,
        workerRunId,
        previousFormIndex,
        isLastInSequence,
    ) {
        const formIndex = previousFormIndex + 1;
        $scope.data.itemsValidationMap[formIndex] = {};
        $scope.data.fieldsValidationMap[formIndex] = {};
        $scope.data.allErrorsNotRestrictingErrors[formIndex] = false;

        const formConfiguration = {
            loading: false,
            form: {},
            customTriggerId,
            initiativeId,
            selectedAddUnderInitiative: null,
            errorLoadingFormConfig: null,
            formFinished: false,
            workerRunId,
            formAnsweredDefinition: {},
            evaluatedWebformResponseMessage: null,
            evaluatedWebformResponseMessageSubtitle: null,
            evaluatedCustomResponseMessage: null,
            showDefaultResponseMessage: false,
        };

        return loadForm(formId, customTriggerId, initiativeId)
            .then((formConfig) => {
                formConfiguration.form = formConfig;
                if ($scope.data.submittedFilesForForms?.[formId]?.length > 0) {
                    formConfiguration.submittedFiles = $scope.data.submittedFilesForForms?.[formId];
                }
                const promises = [];
                // Show the initiative picker if it's configured to be under an inner item
                if (formConfig.definition.addUnderInitiativeId) {
                    promises.push([
                        trackHelper
                            .getInitiativeById(formConfig.definition.addUnderInitiativeId, false)
                            .then((initiative) => {
                                formConfiguration.selectedAddUnderInitiative = initiative;
                            })
                            .catch((error) => {
                                if (STATUS_CODE_NOT_FOUND.includes(error?.status)) {
                                    $state.go('noaccesspage');
                                }
                            }),
                    ]);
                }

                let collectionFormPromise = $q.resolve(formConfig);
                if (
                    formConfig.definition.collectionFormId &&
                    formConfig.definition.collectionFormId !== formConfig.id
                ) {
                    collectionFormPromise = loadForm(
                        formConfig.definition.collectionFormId,
                        customTriggerId,
                        customTriggerId?.initiativeId,
                    );
                }
                promises.push(
                    collectionFormPromise.then((collectionFormConfig) => {
                        formConfiguration.collectionForm = collectionFormConfig;
                    }),
                );

                // Backwards compatibility for non-expression descriptions
                if (!formConfig.descriptionExpression?.evaluatedExpression && formConfig.description?.length) {
                    formConfiguration.evaluatedDescription = formConfig.description;
                }

                if (
                    formConfig.descriptionExpression?.evaluatedExpression ||
                    formConfig?.expandableDescriptionExpression?.evaluatedExpression
                ) {
                    let descriptionEvaluationPromise;
                    const expressions = [
                        formConfig.descriptionExpression?.evaluatedExpression && {
                            key: 'description',
                            expression: formConfig.descriptionExpression.evaluatedExpression,
                        },
                        formConfig?.expandableDescriptionExpression?.evaluatedExpression && {
                            key: 'expandableDescriptionExpression',
                            expression: formConfig.expandableDescriptionExpression.evaluatedExpression,
                        },
                    ].filter(Boolean);

                    if (initiativeId) {
                        descriptionEvaluationPromise = tonkeanService.getEvaluatedExpressionsForInitiative(
                            projectManager.project.id,
                            initiativeId,
                            expressions,
                            true,
                        );
                    } else {
                        descriptionEvaluationPromise = tonkeanService.getEvaluatedExpressions(
                            $scope.data.groupId,
                            expressions,
                        );
                    }

                    descriptionEvaluationPromise.then((data) => {
                        formConfiguration.evaluatedDescription = data.evaluatedExpressions.description;
                        formConfiguration.evaluatedExpandableDescription =
                            data.evaluatedExpressions.expandableDescriptionExpression;
                    });

                    promises.push(descriptionEvaluationPromise);
                }

                const formFieldIdentifiers = formConfig.definition.fields
                    .filter((field) =>
                        // We only need to fetch fields that have ids that start with `FIDE`, other fields are special fields.
                        field.fieldDefinitionIdentifier.startsWith(tonkeanTypeToIdentifyingPrefixMap.FIELD_DEFINITION),
                    )
                    .map((field) => field.fieldDefinitionIdentifier);

                promises.push(
                    customFieldsManager.getFieldDefinitionsByIds(
                        formConfig.workflowVersion.id,
                        $scope.data.formVersionType?.toUpperCase() ?? WorkflowVersionType.PUBLISHED,
                        formFieldIdentifiers,
                        projectManager.project.id,
                    ),
                );

                return $q.all(promises);
            })
            .catch((error) => {
                formConfiguration.errorLoadingFormConfig = error;
            })
            .finally(() => {
                $scope.data.formsConfigurations[formIndex] = formConfiguration;
                if (isLastInSequence && $scope.data.formsConfigurations[formIndex]?.form?.definition) {
                    $scope.data.formsConfigurations[formIndex].form.definition.overrideSubmitFormButtonLabel = 'Finish';
                } else {
                    $scope.data.formsConfigurations[formIndex].form.definition.overrideSubmitFormButtonLabel = '';
                }
                $scope.data.displayInterface = false;
                $scope.data.itemInterfaceId = null;
            });
    }

    function loadForm(formId, referringCustomTrigger, referringInitiative) {
        const referralData = {
            referringCustomTrigger,
            referringInitiative,
        };
        let promise;
        if ($scope.data.formVersionType) {
            // Are we given workflow version type?
            const workflowVersionType = $scope.data.formVersionType.toUpperCase();

            promise = formManager.getFormByWorkflowVersionType(formId, workflowVersionType, referralData);
        } else {
            // If we weren't given a workflow version type, that means we were given a workflow version id we can use.
            promise = tonkeanService.getWorkerForm($scope.data.workflowVersionId, formId, referralData);
        }

        return promise.then((form) => {
            if (projectManager.project.forceThemeConfiguration) {
                form.definition = {
                    ...form.definition,
                    primaryColor: projectManager.project.themeConfiguration.primaryColor,
                    buttonsColor: projectManager.project.themeConfiguration.primaryColor,
                    secondaryColor: projectManager.project.themeConfiguration.primaryColor,
                    logoUrl: projectManager.project.themeConfiguration.logoUrl,
                };
            }
            return form;
        });
    }

    $scope.areAllFormFinished = function () {
        const result =
            $scope.data.formsConfigurations.filter((config) => config.formFinished).length ===
            $scope.data.formsConfigurations.length;
        return result;
    };

    function updateCurrentCustomTriggerId(customTriggerId) {
        $scope.data.currentCustomTriggerId = customTriggerId;

        if (ctrl.onCustomTriggerIdChanged) {
            ctrl.onCustomTriggerIdChanged({ customTriggerId });
        }
    }

    function doAnyConfigsHaveResponse() {
        return $scope.data.formsConfigurations.some(
            (singleConfig) =>
                singleConfig.formAnsweredDefinition.showWebFormResponse &&
                (!utils.isNullOrEmpty(singleConfig.evaluatedCustomResponseMessage) ||
                    !utils.isNullOrEmpty(singleConfig.evaluatedWebformResponseMessage)),
        );
    }

    ctrl.$onChanges = (changesObj) => {
        if (changesObj.expandHovered) {
            $scope.data.expandHovered = changesObj.expandHovered.currentValue;
        }
    };
}

angular.module('tonkean.app').controller('FillFormCtrl', lateConstructController(fillFormCtrl));
