import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_700};
`;
const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectIntegrationCollectStatusCollectInvalidConfiguration: React.FC = () => {
    return (
        <LabelWrapper>
            <Label>The current selected entity collect has invalid configuration for it to successfully execute.</Label>
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusCollectInvalidConfiguration;
