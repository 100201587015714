import PardotAuthenticationComponent from './PardotAuthenticationComponent';
import pardotCircleIcon from '../../../../apps/tracks/images/integrations/pardot-circle.png';
import pardotIcon from '../../../../apps/tracks/images/pardot.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

class PardotIntegration extends IntegrationDefinitionBase {
    override name = 'pardot';
    override displayName = 'Pardot';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [];
    override customizedAuthenticationComponent = PardotAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(pardotCircleIcon, '130px'),
        new IntegrationIcon(pardotIcon, '130px'),
    );
}

export default PardotIntegration;
