import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function FieldsConfigurationInterfaceCtrl($scope, integrationsConsts) {
    const ctrl = this;

    $scope.integrationsConsts = integrationsConsts;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        logicId: ctrl.logicId,
        fieldsConfiguration: ctrl.fieldsConfiguration,
        existingFieldsValuesDefinition: ctrl.existingFieldsValuesDefinition,

        tonkeanExpressionsValuesMap: {},
        validationObject: ctrl.validationObject,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.existingFieldsValuesDefinition) {
            $scope.data.tonkeanExpressionsValuesMap =
                $scope.data.existingFieldsValuesDefinition.tonkeanExpressionsValuesMap;
        }
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs on a change of an expression of a field in configuration.
     */
    $scope.onTonkeanExpressionChanged = function (expression, expressionIdentifier, shouldSaveLogic) {
        $scope.data.tonkeanExpressionsValuesMap[expressionIdentifier] = expression;
        onFieldsValuesDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Notifies the users of the component on change of values.
     */
    function onFieldsValuesDefinitionChanged(shouldSaveLogic) {
        if (ctrl.onFieldsValuesDefinitionChanged) {
            ctrl.onFieldsValuesDefinitionChanged({
                // The definition object for the values.
                fieldsValuesDefinition: {
                    tonkeanExpressionsValuesMap: $scope.data.tonkeanExpressionsValuesMap,
                },

                shouldSaveLogic,
            });
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('FieldsConfigurationInterfaceCtrl', lateConstructController(FieldsConfigurationInterfaceCtrl));
