import React from 'react';
import styled from 'styled-components';

import { ItemWidgetHeaderTitle } from '../../WidgetModule';

import { DataNotUpToDateIndicator } from '@tonkean/infrastructure';
import { Spacer } from '@tonkean/infrastructure';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleContainer = styled.div`
    margin-top: 5px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    title?: string;
    environmentIsActive?: boolean;
}

const SolutionSiteFieldChartHeader: React.FC<Props> = ({ environmentIsActive, title }) => {
    return (
        <Container>
            <TitleContainer>
                <ItemWidgetHeaderTitle>
                    <HeaderWrapper>
                        {title ?? 'Chart'} <Spacer width={8} />
                        <DataNotUpToDateIndicator show={!environmentIsActive} />
                    </HeaderWrapper>
                </ItemWidgetHeaderTitle>
            </TitleContainer>
        </Container>
    );
};

export default SolutionSiteFieldChartHeader;
