import styled from 'styled-components';

import type { Color } from '@tonkean/tui-theme';

export const SOLUTION_SITE_INTERFACE_HEADER_COLOR_BANNER_HEIGHT = 6;

const SolutionSiteInterfaceHeaderColorBanner = styled.div<{ $color: Color }>`
    grid-area: solution-site-banner;
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
    height: ${SOLUTION_SITE_INTERFACE_HEADER_COLOR_BANNER_HEIGHT}px;
    background-color: ${({ $color }) => $color};
`;

export default SolutionSiteInterfaceHeaderColorBanner;
