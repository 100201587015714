import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

export default styled.p`
    color: ${Theme.colors.error};
    font-size: 12px;
    line-height: 14px;
    margin-top: -10px;
`;
