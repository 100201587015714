import type { ColDef } from '@ag-grid-community/core';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import equals from './equals';
import getCellRenderer from './getCellRenderer';
import getFilterTypeOfCustomField from './getFilterTypeOfCustomField';
import valueGetter from './valueGetter';
import type { FieldValidationParams } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { type FieldDefinition, FieldType } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const getFieldDefinitionDefinition = (
    fieldDefinition: FieldDefinition,
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    isEditable: boolean,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
    isManualField: boolean,
    isSearchField: boolean,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    min: string | undefined,
    max: string | undefined,
    validations: FieldValidation<FieldValidationParams>[] | undefined,
    previewValue: (value, fieldDefinition: FieldDefinition) => string,
    cellEditor,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[fieldDefinition.id] || fieldDefinition.name,
        headerTooltip: fieldDefinitionKeyToName[fieldDefinition.id] || fieldDefinition.name,
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(fieldDefinition.id)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
        field: fieldDefinition.id,
        editable: (params) => {
            if (!params?.data?.tableRowId) {
                return false;
            }

            const isCreatedAndStillLoading = !params.data.initiativeId && !!params.data._internalOriginalInitiativeId;

            return !!isEditable && isManualField && !(isCreatedAndStillLoading && isSearchField);
        },
        filter: enableFilter && getFilterTypeOfCustomField(fieldDefinition),
        comparator: (valueA: any, valueB: any) => {
            if (valueA === valueB) {
                return 0;
            }

            switch (fieldDefinition.fieldType) {
                case FieldType.Number:
                    return Number(valueA) - Number(valueB);
                case FieldType.Date:
                    return new Date(valueA).getTime() - new Date(valueB).getTime();
                case FieldType.String:
                    return valueA?.localeCompare(valueB);
                default:
                    return valueA - valueB;
            }
        },
        cellRenderer: getCellRenderer(fieldDefinition),
        cellRendererParams: {
            urlLabel: fieldDefinition.urlLabel,
        },
        cellEditor,

        cellEditorParams: {
            fieldDefinition,
            showStepperButtons: true,
            groupId,
            min,
            max,
        },
        cellClassRules: {
            [EMPTY_AND_REQUIRED_CLASS_NAME]: (params) => {
                const currentFieldValidationConfig = validations?.find(
                    (columnValidation) => columnValidation.fieldDefinitionKey === params.colDef.field,
                );

                if (currentFieldValidationConfig && params.data) {
                    return !currentFieldValidationConfig.isValid(params.data);
                }

                return false;
            },
        },
        tooltipValueGetter: (params) => {
            return previewValue(params.data?.[fieldDefinition.id], fieldDefinition);
        },
        menuTabs: ['filterMenuTab'],
        cellEditorPopup: [FieldType.LongString, FieldType.List].includes(fieldDefinition.fieldType),
        valueFormatter: (params) => previewValue(params.data?.[fieldDefinition.id], fieldDefinition),
        valueGetter: (params) => valueGetter(params.data?.[fieldDefinition.id], fieldDefinition),
        equals: (value1, value2) => equals(value1, value2, fieldDefinition),
    } as ColDef<InitiativeRowData>;
};

export default getFieldDefinitionDefinition;
