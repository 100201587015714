<div class="nlp-processor-logic-configuration" data-automation="nlp-processor-logic-configuration">
    <!-- Input text to work on -->
    <div class="margin-bottom-xs common-title-xxs">Input text to process</div>

    <!-- Input -->
    <tnk-tonkean-expression
        group-id="{{ data.groupId }}"
        logic-id="{{ data.configuredLogic.node.id }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        preview-evaluation-source="data.previewEvaluationSource"
        saved-original-expression="data.inputExpression"
        saved-evaluated-expression="data.evaluatedInputExpression"
        on-tonkean-expression-changed="
            onInputExpressionExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
        "
        expression-unique-identifier="NLP_PROCESSOR_INPUT"
    ></tnk-tonkean-expression>

    <div
        ng-if="data.invalidLogics[data.configuredLogic.node.id].noInputExpression"
        class="margin-top common-color-danger"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].noInputExpression }}
    </div>

    <div>
        <div class="margin-bottom-xs common-title-xxs margin-top-md">Text analysis type</div>
        <div class="flex-vmiddle margin-bottom-md margin-top-md">
            <tnk-radio-button
                class="margin-right-md common-size-xxxxs"
                radio-id="nlp-analysis-type"
                radio-name="nlp-analysis-type"
                radio-value="MANUAL"
                model="data.textAnalysisType"
                on-click="selectTextAnalysisType(onClickParam)"
                on-click-param="('MANUAL')"
                is-required="true"
            >
                Manual
            </tnk-radio-button>

            <tnk-radio-button
                class="margin-right-md"
                radio-id="nlp-analysis-type"
                radio-name="nlp-analysis-type"
                radio-value="TRAINING_SET"
                model="data.textAnalysisType"
                on-click="selectTextAnalysisType(onClickParam)"
                on-click-param="('TRAINING_SET')"
                is-required="true"
            >
                Training Set
            </tnk-radio-button>
        </div>
    </div>

    <tnk-training-set-search-matcher-selector
        ng-if="data.textAnalysisType === 'TRAINING_SET'"
        group-id="data.groupId"
        selected-training-set-id="data.selectedTrainingSetId"
        on-item-selected="(onTrainingSetSelected)"
    ></tnk-training-set-search-matcher-selector>

    <div
        ng-if="
            data.textAnalysisType === 'TRAINING_SET' &&
            data.invalidLogics[data.configuredLogic.node.id].noTrainingSetSelected
        "
        class="margin-top common-color-danger"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].noTrainingSetSelected }}
    </div>

    <!-- Branches -->
    <div class="margin-top-26">
        <div>
            <div class="common-title-xxs mod-light margin-bottom-8" ng-if="data.textAnalysisType !== 'TRAINING_SET'">
                Define keywords by analyze the input text
            </div>

            <span class="common-size-xxs common-color-grey" ng-if="data.textAnalysisType !== 'TRAINING_SET'">
                Each definition is composite of keywords that might trigger the branch.
            </span>

            <div class="common-title-xxs mod-light margin-bottom-8" ng-if="data.textAnalysisType === 'TRAINING_SET'">
                Define NLP branches
            </div>

            <span class="common-size-xxs common-color-grey" ng-if="data.textAnalysisType === 'TRAINING_SET'">
                For each branch correlate the relevant nlp model.
            </span>
        </div>

        <!-- Buttons-->
        <div class="margin-top-md">
            <div class="buttons-draggable-list flex flex-col common-draggable-list no-border">
                <div
                    ng-repeat="branch in data.branches"
                    class="
                        common-draggable-list-item
                        flex-vmiddle
                        show-edit-when-hover-visibility
                        branch-configuration-display
                    "
                >
                    <div class="flex-grow">
                        <div class="flex-vmiddle margin-bottom-sm margin-top-xs">
                            <div class="logic-template-icon mod-nlp-branch mod-small margin-right-10"></div>
                            <div class="common-size-xxxs common-color-black branch-name">Branch name</div>
                        </div>

                        <input
                            class="form-control"
                            ng-model="branch.displayName"
                            ng-change="onBranchNameChanged(branch.customTriggerId,branch.displayName, true)"
                            placeholder="Branch Name"
                            ng-blur="onActionsChanged(true, true)"
                        />

                        <div
                            ng-if="data.textAnalysisType === 'TRAINING_SET'"
                            class="common-size-xxxs common-color-black margin-bottom-sm margin-top-12"
                        >
                            NLP model
                        </div>
                        <!-- Branch configuration -->
                        <div class="margin-top-xs margin-bottom-16">
                            <tnk-nlp-branch-configuration
                                branch-id="branch.customTriggerId"
                                definition="branch.definition"
                                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                text-analysis-type="data.textAnalysisType"
                                selected-training-set-id="data.selectedTrainingSetId"
                                on-branch-definition-changed="onBranchDefinitionChanged(branch, definition)"
                                on-branch-name-changed="(onBranchNameChanged)"
                                training-set-models-to-exclude="data.alreadyUsedModelIds"
                                group-id="data.groupId"
                            ></tnk-nlp-branch-configuration>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add branch -->
            <div class="padding-normal-xs">
                <a ng-click="addBranch()" data-automation="tnk-nlp-processor-logic-configuration-template-add-branch">+ Add Branch</a>
            </div>

            <div>
                <i class="loading" ng-if="data.branchesLoading"></i>
            </div>
        </div>

        <!-- Separator -->
        <hr />

        <div ng-if="data.textAnalysisType !== 'TRAINING_SET'">
            <div class="common-title-xxs margin-bottom-xs">Which Branches should run:</div>

            <!-- Match Type -->
            <div class="margin-bottom inline-block" uib-dropdown>
                <button uib-dropdown-toggle class="btn btn-default">
                    {{ data.selectedMatchType.label }}
                    <span class="dropdown-chevron mod-static"></span>
                </button>
                <ul uib-dropdown-menu>
                    <li ng-repeat="type in data.nlpMatchTypes" ng-click="onMatchTypeChanged(type.key)">
                        <a>{{ type.label }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="margin-top-lg flex">
            <div class="flex-grow margin-right">
                <span class="common-title-xxs margin-right">No match was found Branch</span>
                <i ng-if="data.useDefaultBranchIsLoading" class="loading"></i>
                <p ng-if="data.textAnalysisType !== 'TRAINING_SET'" class="common-size-xxs common-color-grey">
                    Activated when the input text doesn't match any other branch
                </p>
                <p ng-if="data.textAnalysisType === 'TRAINING_SET'" class="common-size-xxs common-color-grey">
                    Activated when the system could not find a model to handle the received text
                </p>
            </div>
            <tnk-toggle
                is-active="data.useDefaultBranch"
                size="sm"
                on-toggle-click="onUseDefaultBranchChanged(isActive, ['NLP_BRANCH_DEFAULT'])"
            ></tnk-toggle>
        </div>

        <div ng-if="data.textAnalysisType === 'TRAINING_SET'">
            <!-- Separator -->
            <hr />

            <div class="margin-top-lg flex">
                <div class="flex-grow margin-right">
                    <span class="common-title-xxs margin-right">Other active models Branch</span>
                    <i ng-if="data.useAnyOfTheRestBranchIsLoading" class="loading"></i>
                    <p class="common-size-xxs common-color-grey">
                        Activated when a model without a specific branch was matched with the received text.
                    </p>
                </div>
                <tnk-toggle
                    is-active="data.useAnyOfTheRestBranch"
                    size="sm"
                    on-toggle-click="onUseDefaultBranchChanged(isActive, ['NLP_BRANCH_ANY_OF_THE_REST'])"
                ></tnk-toggle>
            </div>
        </div>
    </div>
</div>
