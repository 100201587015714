enum IntakeProgressStatusCompletionStatus {
    COMPLETE = 'complete',
    SKIPPED = 'skipped',
    CURRENT = 'current',
    FUTURE = 'future',
}

type IntakeProgressStatusInfo = {
    title: string;
    completionStatus: IntakeProgressStatusCompletionStatus;
};

export { IntakeProgressStatusCompletionStatus };
export type { IntakeProgressStatusInfo };
