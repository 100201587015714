import { operatorKeyToOperatorMap } from '@tonkean/forumla-operators';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FormulaField } from '@tonkean/tonkean-entities';
import { FormulaTreeTreeNode } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { FormulaTreeVariableNode } from '@tonkean/tonkean-entities';
import { FormulaTreeConstNode } from '@tonkean/tonkean-entities';
import { FormulaTreeEmptyNode } from '@tonkean/tonkean-entities';
import { createEmptyFormulaNode } from '@tonkean/tonkean-entities';

/**
 * Get a tree representation with the correct classes given a json tree (recursive)
 *
 * @param model - an object representing the tree
 * @param field - the formula field definition of the node in the model param
 * @param variablesToEmpty - should convert variables to empty
 * @param ignoreDefaultValues - should ignore default values in the fields
 * @return a formula tree class
 */
function formulaTreeNodeFactory(
    model: formulaTreeNode,
    field: FormulaField,
    variablesToEmpty: boolean = false,
    ignoreDefaultValues: boolean = false,
): formulaTreeNode {
    const createEmptyField = () => {
        if (ignoreDefaultValues) {
            return new FormulaTreeEmptyNode(field);
        }
        return createEmptyFormulaNode(field);
    };

    switch (model.type) {
        case FormulaTreeNodeType.TREE: {
            const operator = operatorKeyToOperatorMap[model.operator.key];
            const fields = operator.getFieldsList(model.operands.length);
            const operands = fields.map((field, index) =>
                model.operands[index]
                    ? formulaTreeNodeFactory(model.operands[index], field, variablesToEmpty)
                    : createEmptyFormulaNode(field),
            );
            return new FormulaTreeTreeNode(operator, operands, field);
        }

        case FormulaTreeNodeType.CONST: {
            return new FormulaTreeConstNode(model.dataType, model.value ?? '', field);
        }

        case FormulaTreeNodeType.VARIABLE: {
            if (variablesToEmpty) {
                return createEmptyField();
            }
            return new FormulaTreeVariableNode(model.variableId, model.name, model.dataType, field);
        }

        case FormulaTreeNodeType.EMPTY:
        default: {
            return createEmptyField();
        }
    }
}

export default formulaTreeNodeFactory;
