import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { H2, Paragraph, SimpleErrorStateMessage, SimpleSelect } from '@tonkean/infrastructure';
import { IntegrationManager } from '@tonkean/integrations';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 900px;
    margin: 40px auto;
`;
const FormWrapper = styled(Form)`
    display: grid;
    grid-template-columns: 300px auto;
    align-self: center;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
`;
const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

const CreateMarketplaceItemForNativeIntegration: React.FC = () => {
    const integrationsConsts = useAngularService('integrationsConsts');
    const integrations = useAngularService('integrations');

    const nativeIntegrations = useMemo(() => {
        return Object.entries(integrations.getIntegrationsConfig()).map(([integrationKey, integration]) => ({
            value: integrationKey,
            label: (integration as any).displayName,
        }));
    }, [integrations]);

    const [{ loading, error }, createMarketplaceItemForNativeIntegration] = useLazyTonkeanService(
        'createMarketplaceItemForNativeIntegration',
    );
    const [projectId] = useGetStateParams<[TonkeanId<TonkeanType.PROJECT>]>('projectId');

    const [results, setResults] = useState<React.ReactNode[]>([]);

    const createItem = async (integrationType: string) => {
        if (!integrationType) {
            return;
        }

        const actions = integrationsConsts.getActionsByIntegrationsMap()[integrationType.toUpperCase()];
        const extractEntities = (object: Record<string, { displayName?: string }> | undefined, prefix?: string) => {
            if (!object) {
                return [];
            }

            return Object.values(object)
                .map((action) => {
                    if (!action.displayName) {
                        return undefined;
                    }
                    return { displayName: `${prefix ?? ''} ${action.displayName}`.trim() };
                })
                .filter(Boolean) as { displayName: string }[];
        };
        const actionNames = [
            ...extractEntities(actions?.['createNewItem'], 'Create'),
            ...extractEntities(actions?.['createOrUpdateItem'], 'Create Or Update'),
            ...extractEntities(actions?.['updateField'], 'Update'),
            ...extractEntities(actions?.['customActions']),
        ];

        const getEntityNames = () => {
            const newIntegration = IntegrationManager.getIntegrationByName(integrationType);
            if (newIntegration) {
                return newIntegration.entities.map((entity) => entity.pluralLabel);
            }

            const entitiesMetadataFromIntegrationConsts =
                integrationsConsts.getEntitiesMetadata()[integrationType.toUpperCase()];
            if (entitiesMetadataFromIntegrationConsts) {
                return Object.values(entitiesMetadataFromIntegrationConsts).map(
                    (entity: any) => entity.pluralLabel as string,
                );
            }

            return (integrations.getIntegrationsConfig()[integrationType]?.fieldOptions as string[]) || [];
        };
        const entityNames = getEntityNames().map((displayName) => ({ displayName }));

        async function checkFileExists(filePath: string): Promise<boolean> {
            try {
                const response = await fetch(filePath);
                return response.ok;
            } catch {
                return false;
            }
        }

        const getIconPath = () => {
            const newIntegration = IntegrationManager.getIntegrationByName(integrationType);
            if (newIntegration) {
                return `/images/integrations/${integrationType.toLowerCase()}-circle.png`;
            }

            return [...document.styleSheets]
                .flatMap((it) => {
                    try {
                        return [...it.rules];
                    } catch {
                        return [];
                    }
                })
                .find((it) => it['selectorText'] == `.initiative-integration-icon.mod-${integrationType}`)
                ?.['style'].backgroundImage.replace(/^url\(['"]?/, '')
                .replace(/['"]?\)$/, '') as string | undefined;
        };

        const iconPath: string = getIconPath() || '';

        return checkFileExists(iconPath).then((exists) => {
            let iconUrl: URL;

            if (exists) {
                iconUrl = new URL(iconPath, 'https://tracks.tonkean.com');
            } else {
                iconUrl = new URL(
                    `/images/integrations/${integrationType.toLowerCase()}-circle.svg`,
                    'https://tracks.tonkean.com',
                );
            }

            return createMarketplaceItemForNativeIntegration(
                projectId,
                integrationType,
                entityNames,
                actionNames,
                iconUrl.toString(),
            ).then((result) => {
                setResults((currentResults) => [
                    <>
                        {result.isCreated ? 'Created' : 'Updated'} <strong>{result.displayName}</strong> marketplace
                        item
                    </>,
                    ...currentResults,
                ]);
            });
        });
    };

    return (
        <Wrapper>
            <H2>Create Marketplace Item For Native Integration</H2>
            <Paragraph>
                This will create a new marketplace item, with the icon, actions and entities pre filled, in this
                project. If you already have a marketplace item for the given native integration, it will modify this
                information in the marketplace item, but won't touch anything else (for example, the description).
            </Paragraph>
            <Paragraph>
                This will not publish it to the marketplace, you must download it and upload it to the repository, just
                like any other marketplace item.
            </Paragraph>

            <Formik
                initialValues={{ nativeIntegration: '' }}
                onSubmit={({ nativeIntegration }) => createItem(nativeIntegration)}
            >
                <FormWrapper>
                    <SimpleSelect name="nativeIntegration" options={nativeIntegrations} />
                    <Button type="submit" disabled={loading}>
                        Goo
                    </Button>
                </FormWrapper>
            </Formik>

            <ResultsWrapper>
                {error && <SimpleErrorStateMessage error={error} showSmallError />}
                {results.map((result, index) => (
                    <div key={index}>{result}</div>
                ))}
            </ResultsWrapper>
        </Wrapper>
    );
};

export default CreateMarketplaceItemForNativeIntegration;
