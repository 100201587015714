import { analyticsWrapper } from '@tonkean/analytics';

function LinkCtrl($scope, $state, $stateParams, $timeout, tonkeanService, authenticationService, modal, $log) {
    const replaceOptions = { location: 'replace' };

    $scope.page = $stateParams.page;
    $scope.secret = $stateParams.secret;
    $scope.answerIndex = $stateParams.a;
    $scope.entityId = $stateParams.e;
    $scope.workerRunStartTime = $stateParams.wrst;
    $scope.workerRunLogicActionId = $stateParams.wrla;
    $scope.createdByCustomTriggerId = $stateParams.cutr;
    $scope.workerRunLogicActionType = $stateParams.action;
    $scope.fieldDefinitionId = $stateParams.d;
    $scope.functionId = $stateParams.f;
    $scope.groupId = $stateParams.g;
    $scope.inquiryId = $stateParams.i;
    $scope.projectId = $stateParams.p;
    $scope.formId = $stateParams.wf;
    $scope.formVersionType = $stateParams.fvt;
    $scope.workerRunLogicId = $stateParams.wrl;
    $scope.hr = $stateParams.hr;
    $scope.workerRunId = $stateParams.wr;
    $scope.sId = $stateParams.sId;
    $scope.callbackId = $stateParams.s;
    $scope.messageTypeId = $stateParams.t;
    $scope.value = $stateParams.v;
    $scope.workflowVersionId = $stateParams.wid;
    $scope.workflowVersionType = $stateParams.workflowVersionType;
    $scope.itemInterfaceId = $stateParams.int;
    $scope.initialValues = $stateParams.initialValues;
    $scope.loading = false;
    $scope.authenticating = false;
    $scope.postingAnswer = false;
    $scope.approvingLicenseRequest = false;

    const noAuthPages = new Set(['pref']);

    $scope.messageTypeMap = {
        0: 'SIGNUP',
        1: 'SIGNIN',
        2: 'VERIFY_TOKEN',
        3: 'PROJECT_READY',
        4: 'QUESTION',
        5: 'INVITE',
        6: 'ACTIVITY_DIGEST',
        7: 'ANALYZE_DEEPER',
        8: 'ANALYZE_DEEPER_STAKEHOLDER',
        9: 'BIG_COMMIT_DISCOVERY',
        10: 'SHARE_PROBLEM',
        11: 'SHARE_DISCOVERY',
        12: 'INVOLVE_REMINDER',
        13: 'OPEN_PROBLEMS',
        14: 'UNANSWERED_QUESTIONS',
        15: 'ACTIVITY_NOTIFICATION',
        16: 'ASK_FOR_RISKS',
        19: 'GATHER_UPDATES',
        21: 'MULTI_GATHER_UPDATE',
    };

    $scope.init = function () {
        if ($scope.secret) {
            tonkeanService.sendMessageClick($scope.secret, $scope.messageTypeId, $scope.projectId);
        }

        analyticsWrapper.track(`Click ${$scope.page}`, {
            category: 'Link Message',
            label: $scope.messageTypeMap[$scope.messageTypeId],
        });

        const isNoAuthPage = noAuthPages.has($scope.page);

        if (authenticationService.isUserAuthenticated() || isNoAuthPage) {
            $scope.handleLinkPage(isNoAuthPage);
        } else {
            $scope.login();
        }
    };

    $scope.login = function () {
        $scope.authenticating = true;
        if (!$scope.secret || !$scope.secret.length) {
            // if no secret and not authenticated - just go to the login page
            authenticationService.setAuthenticationCompleteRedirectUrl();
            $state.go('login', null, replaceOptions);
        } else {
            tonkeanService
                .loginPartial($scope.secret)
                .then((data) => {
                    // authentication successful, now go to the required page
                    authenticationService.finishAuthentication(data.user, data.accessToken, data.isNewUser, true);
                    $scope.handleLinkPage();
                })
                .catch(() => {
                    // authentication failed, need to login first but keep the original page for after login to complete the operation
                    authenticationService.setAuthenticationCompleteRedirectUrl();
                    $scope.$emit('alert', { type: 'warning', msg: 'Could not authenticate. Please sign in.' });
                    $state.go('login', null, replaceOptions);
                })
                .finally(() => {
                    $scope.authenticating = false;
                });
        }
    };

    $scope.handleLinkPage = function (isNoAuthPage) {
        let selectProject = true;
        let trackCategory;
        let trackLabel = 'Link';

        // Set the from and to time for the history filter for a specific worker run
        const filterTimeFrom = Math.floor($stateParams.wrst / 1000);
        const filterTimeUntil = Math.round(filterTimeFrom + 60 * 60);

        switch ($scope.page) {
            case 'answer':
                trackCategory = 'Question';
                trackLabel = 'Link (Answer)';
                selectProject = false;
                $scope.handleAnswerLink();
                break;
            case 'update':
                trackCategory = 'Update State';
                trackLabel = 'Link (State)';
                $state.go(
                    'updateState',
                    {
                        projectId: $scope.projectId,
                        id: $scope.entityId,
                        answer: $scope.answerIndex,
                        fieldId: $scope.fieldDefinitionId,
                        formId: $scope.formId,
                        formVersionType: $scope.formVersionType,
                        workerRunLogicId: $scope.workerRunLogicId,
                        hr: $scope.hr,
                        workerRunId: $scope.workerRunId,
                        sessionId: $scope.sId,
                        customTriggerId: $scope.createdByCustomTriggerId,
                        workflowVersionId: $scope.workflowVersionId,
                        itemInterfaceId: $scope.itemInterfaceId,
                        initialValues: $scope.initialValues,
                    },
                    replaceOptions,
                );
                break;
            case 'update_inner':
                trackCategory = 'Update State';
                trackLabel = 'Link (State) - Inner Items';
                $state.go(
                    'updateState',
                    {
                        projectId: $scope.projectId,
                        id: $scope.entityId,
                        answer: $scope.answerIndex,
                        inner: true,
                        workerRunLogicActionId: $scope.workerRunLogicActionId,
                        createdByCustomTriggerId: $scope.createdByCustomTriggerId,
                        workerRunLogicActionType: $scope.workerRunLogicActionType,
                    },
                    replaceOptions,
                );
                break;
            case 'initiative':
                trackCategory = 'Initiative';
                $state.go(
                    'product.interface.group',
                    { initiativeId: $scope.entityId, groupId: $scope.groupId },
                    replaceOptions,
                );
                break;
            case 'item_interface':
                if ($stateParams.int) {
                    trackCategory = 'ItemInterface';
                    $state.go(
                        'product.customInterfaceView',
                        {
                            initiativeId: $scope.entityId,
                            itemInterfaceId: $stateParams.int,
                            groupId: $stateParams.g,
                        },
                        replaceOptions,
                    );
                }
                break;
            case 'worker_run':
                trackCategory = 'Worker run';
                $state.go(
                    'product.workerEditor.history',
                    {
                        projectId: $scope.projectId,
                        g: $scope.groupId,
                        wr: `${$scope.entityId}-${$scope.workerRunStartTime}`,
                        environment: $scope.workflowVersionType === 'DRAFT' ? 'build' : 'production',
                        // We need to filter the history according to the time so the worker run will show on the left side
                        from: filterTimeFrom,
                        to: filterTimeUntil,
                    },
                    replaceOptions,
                );
                break;
            case 'approve':
                // analyticsWrapper.track('Involve Team', {category: 'OnBoarding', label: 'Notification'});
                trackCategory = 'OnBoarding';
                $state.go('product', { projectId: $scope.projectId }, replaceOptions);
                $timeout(modal.openInvite, 1000);
                break;
            case 'project':
                trackCategory = 'Problems';
                $state.go('product', { projectId: $scope.projectId }, replaceOptions);
                break;
            case 'inbox':
                trackCategory = 'Inbox';
                $state.go(
                    'product.board',
                    {
                        projectId: $scope.projectId,
                        filter: 'my',
                        g: null,
                        st: null,
                    },
                    replaceOptions,
                );
                break;
            case 'problem':
                trackCategory = 'Problem Page';
                $state.go(
                    'product.analyze.problems.details',
                    {
                        projectId: $scope.projectId,
                        problemId: $scope.entityId,
                    },
                    replaceOptions,
                );
                break;
            case 'analyze':
                trackCategory = 'Analyze deeper';
                $state.go('analyze', { projectId: $scope.projectId, problemId: $scope.entityId }, replaceOptions);
                break;
            case 'discoveries':
                trackCategory = 'Discovery';
                $state.go('discover', { projectId: $scope.projectId, discoveryId: $scope.entityId }, replaceOptions);
                break;
            case 'question':
                trackCategory = 'Question';
                goToQuestionPage();
                break;
            case 'ApproveLicenseRequest':
                trackCategory = 'ApproveLicenseRequest';
                $scope.handleApproveLicenseRequestLink($scope.projectId, $scope.entityId);
                break;
            case 'digest':
                trackCategory = 'Digest';
                $state.go('product.summary', { projectId: $scope.projectId }, replaceOptions);
                break;
            case 'license':
                trackCategory = 'License';
                $state.go('product.settings.license', { tab: 'overview' }, { reload: true });
                break;
            case 'message_action':
                trackCategory = 'Worker message - answer action';
                $state.go(
                    'botButtonPressed',
                    {
                        filter: 'all',
                        g: null,
                        st: null,
                    },
                    replaceOptions,
                );
                $timeout(() => {
                    modal.openWorkerEmailButtonAnswerModal($scope.callbackId, $scope.value, true);
                }, 500);
                break;
            default:
                trackCategory = 'Board';
                $log.error(`Unknown link navigation page: ${$scope.page}`);
                $state.go('product', null, replaceOptions);
                break;
        }

        if (selectProject && $scope.projectId && !isNoAuthPage) {
            // Selects the project async.
            $scope.pm.selectProject($scope.projectId);
        }
        analyticsWrapper.track('Open', { category: trackCategory, label: trackLabel });
    };

    $scope.handleAnswerLink = function () {
        $scope.postingAnswer = true;
        $scope.pm
            .answerQuestionBySecret($scope.secret, $scope.answerIndex)
            .then(function (data) {
                goToQuestionPage(data);
            })
            .catch(function () {
                $scope.$emit('alert', 'Failed to post answer.');
            })
            .finally(function () {
                $scope.postingAnswer = false;
            });
    };

    $scope.handleApproveLicenseRequestLink = function () {
        $scope.approvingLicenseRequest = true;
        $scope.pm
            .approveLicenseRequest($scope.projectId, $scope.entityId)
            .then(function () {
                $state.go('product.settings.license', { tab: 'members', projectId: $scope.projectId }, replaceOptions);
            })
            .catch(function () {
                $scope.$emit('alert', 'Failed to approve license request.');
            })
            .finally(function () {
                $scope.approvingLicenseRequest = false;
            });
    };

    function goToQuestionPage(inquiryData) {
        const stateData = { projectId: $scope.projectId };
        if (inquiryData) {
            stateData.inquiryData = inquiryData;
        }
        if ($scope.inquiryId) {
            stateData.inquiry = $scope.inquiryId;
        }
        $state.go('question', stateData, replaceOptions);
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('LinkCtrl', LinkCtrl);
