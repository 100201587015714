import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type { ResponseOfIsValueValid } from '../../../../../../../../components/common/EditableTitle/EditableText';
import EditableTitle from '../../../../../../../../components/common/EditableTitle/EditableTitle';
import getResponseOfIsNameValid from '../../ProjectIntegrationActionCRUDPage/getResponseOfIsNameValid';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const ActionTitle = styled(EditableTitle)`
    flex-grow: 1;
    margin-right: 5px;
`;

const ProjectIntegrationIconView = styled(ProjectIntegrationIcon)`
    margin-right: 10px;
`;

const ActionNameChange = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    onChangeNameError: () => void;
    projectIntegration: ProjectIntegration;
    onChange: (name: string) => void;
    projectIntegrationActionNames: string[];
    defaultName: string;
}

const ProjectIntegrationActionHeader: React.FC<Props> = ({
    onChangeNameError,
    onChange,
    defaultName,
    projectIntegration,
    projectIntegrationActionNames,
}) => {
    const [isNameValid, setIsNameValid] = useState(true);
    const [name, setName] = useState<string>(defaultName);

    const isNewNameValid: (name: string) => ResponseOfIsValueValid = useCallback(
        (newDisplayNameValue: string) => {
            const isNameValueValid = getResponseOfIsNameValid(newDisplayNameValue, projectIntegrationActionNames, name);
            setIsNameValid(isNameValueValid.isValid);

            return isNameValueValid;
        },
        [name, projectIntegrationActionNames],
    );

    // Notify onError callback that the name is invalid
    useEffect(() => {
        if (!isNameValid) {
            onChangeNameError();
        }
    }, [isNameValid, onChangeNameError]);

    return (
        <ActionNameChange>
            <ProjectIntegrationIconView
                iconUrl={projectIntegration.iconUrl}
                integrationType={projectIntegration.integration.integrationType.toLowerCase()}
                width={20}
            />
            <ActionTitle
                onChange={(changedName) => {
                    setName(changedName);
                    onChange(changedName);
                }}
                value={name}
                isValueValid={isNewNameValid}
                isEditable
            />
        </ActionNameChange>
    );
};
export default ProjectIntegrationActionHeader;
