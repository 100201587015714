<div class="text-center common-size-xxxs">
    <!-- Person name -->
    <div>
        <strong>{{ getAvatarName(avatar) }}</strong>
    </div>

    <!-- Last view time -->
    <div ng-if="data.shouldDisplayViewTime">
        {{ avatar.lastViewTime ? (avatar.lastViewTime | timeAgo) : 'Never Viewed' }}
    </div>
</div>
