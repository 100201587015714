import React from 'react';

import IntakeProgressTracker from '../IntakeProgressTracker/components/IntakeProgressTracker';

import {
    useIsSmallScreen,
    useIsXSmallScreen,
    HamburgerMenu,
    HamburgerMenuPresentationStyle,
} from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { Color } from '@tonkean/tui-theme';

export interface VerticalIntakeProgressTrackerPresenterProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    themeColor?: Color;
    intakeProgressTrackerTitle?: string;
    isOpen: boolean;
    closeButtonClicked: () => void;
}

const VerticalIntakeProgressTrackerPresenter: React.FC<VerticalIntakeProgressTrackerPresenterProps> = ({
    workflowVersionId,
    currentCustomTriggerId,
    currentFormId,
    themeColor,
    intakeProgressTrackerTitle,
    isOpen,
    closeButtonClicked,
}) => {
    const isXSmallScreen = useIsXSmallScreen();
    const isSmallScreen = useIsSmallScreen();

    const presentationStyle = isXSmallScreen
        ? HamburgerMenuPresentationStyle.FULL_SCREEN
        : isSmallScreen
          ? HamburgerMenuPresentationStyle.FULL_HEIGHT
          : HamburgerMenuPresentationStyle.HORIZONTAL_CENTERED;
    return (
        <HamburgerMenu
            menuOpen={isOpen}
            onMenuClose={closeButtonClicked}
            showMenuBackdrop={isSmallScreen}
            closeOnClickOutside={isSmallScreen}
            backgroundColor={Theme.colors.white}
            presentationStyle={presentationStyle}
        >
            <IntakeProgressTracker
                workflowVersionId={workflowVersionId}
                currentCustomTriggerId={currentCustomTriggerId}
                currentFormId={currentFormId}
                title={intakeProgressTrackerTitle}
                themeColor={themeColor}
                showCloseButton={isSmallScreen}
                closeButtonClicked={closeButtonClicked}
                isVertical
            />
        </HamburgerMenu>
    );
};

export default VerticalIntakeProgressTrackerPresenter;
