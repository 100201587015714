import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import AddCommentInput from './AddCommentInput';
import CommentThread from './CommentThread';
import { CommentInThread } from '../icons';

import { Breakpoint } from '@tonkean/infrastructure';
import { TonkeanType } from '@tonkean/tonkean-entities';
import type { Person, TonkeanId, NewActivityData, Comment, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const Container = styled.div<{ $isHighlight: boolean }>`
    border: ${({ $isHighlight }) =>
        `1px solid ${$isHighlight ? Theme.colors.primaryHighlight : Theme.colors.gray_200}`};
    padding: 15px 0 0;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    border-radius: 8px;
    margin: 10px 0;
    ${({ $isHighlight }) => $isHighlight && `background-color: #F5F9FD`};
`;

const ContentWrapper = styled.div`
    padding: 0 22px;
    position: relative;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 0 16px;
    }
`;

const Content = styled.div`
    margin-bottom: 18px;
`;

const ToggleText = styled(ClickableLink)<{ threadlevel: number }>`
    margin: ${({ threadlevel }) => `8px 0px 18px calc(${34 * threadlevel}px + 22px)`};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${Theme.colors.primaryHighlight};
`;

const CommentInputWrapper = styled.div<{ $threadlevel: number }>`
    margin: ${({ $threadlevel }) => `3px 0px 18px calc(${27 * $threadlevel}px + 22px)`};
    height: 50px;
    display: flex;
    flex-direction: column;
`;

const StyledCommentsIcon = styled(CommentInThread)`
    margin-right: 5px;
`;

const StyledCommentThread = styled(CommentThread)`
    padding-left: 55px;
    width: 100%;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding-left: 47px;
    }
`;

const StyledDivVector = styled.div<{ $hideLine: boolean | undefined }>`
    position: absolute;
    left: 36px;
    top: 3px;
    height: calc(100% + 32px);
    width: 12px;
    border-left: ${({ $hideLine }) => ($hideLine ? undefined : `1px solid ${Theme.colors.gray_400}`)};
    border-bottom: ${({ $hideLine }) => ($hideLine ? undefined : `1px solid ${Theme.colors.gray_400}`)};
    border-bottom-left-radius: 6px 6px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        left: 28px;
    }
`;

export interface CommentableCardProps extends StyledComponentsSupportProps, DataAutomationSupportProps {
    comments?: Comment[] | undefined;
    currentUser: Person;
    defaultShowAddCommentForm?: boolean | undefined;
    threadMaxHeight?: number | undefined;
    targetId: TonkeanId<TonkeanType.ACTIVITY | TonkeanType.INITIATIVE>;
    onCommentAdded: (newActivity: NewActivityData) => void;
    onCommentDelete?: (commentId: string) => void;
    isCommentsAllowed?: boolean;
    canUserEditItem: boolean;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType?: WorkflowVersionType;
    defaultCommentText?: string;
    isFromTimeline?: boolean;
    commentTimestamp?: number;
    disableReplyToComment?: boolean;
}

const LAST_MESSAGES_DELATA_TIME_SEC = 30;

const CommentableCard: React.FC<React.PropsWithChildren<CommentableCardProps>> = ({
    children,
    className,
    comments = [],
    currentUser,
    defaultShowAddCommentForm = false,
    threadMaxHeight,
    targetId,
    onCommentAdded,
    onCommentDelete,
    canUserEditItem,
    isCommentsAllowed = canUserEditItem,
    initiativeId,
    dataAutomation,
    workflowVersionType,
    defaultCommentText,
    isFromTimeline,
    commentTimestamp,
    disableReplyToComment,
}) => {
    const [showAddCommentForm, setShowAddCommentForm] = useState(defaultShowAddCommentForm);

    let isHighlightOn = false;
    if (commentTimestamp) {
        const diff = (Date.now() - commentTimestamp) / 1000;
        if (diff <= LAST_MESSAGES_DELATA_TIME_SEC) {
            isHighlightOn = true;
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowAddCommentForm(!showAddCommentForm);
    };

    const threadLevelCalc = useMemo(() => {
        if (comments.length > 0) {
            return 2;
        } else if (isFromTimeline) {
            return 0;
        }
        return 1;
    }, [comments.length, isFromTimeline]);

    return (
        <Container className={className} $isHighlight={isHighlightOn} data-automation="commentable-card">
            <ContentWrapper data-automation={dataAutomation}>
                {!isFromTimeline && <StyledDivVector $hideLine={disableReplyToComment && comments.length === 0} />}
                <Content>{children}</Content>
            </ContentWrapper>
            {isCommentsAllowed && getTonkeanEntityType(targetId) === TonkeanType.ACTIVITY && (
                <StyledCommentThread
                    comments={comments}
                    currentUser={currentUser}
                    maxHeight={threadMaxHeight}
                    onCommentDelete={onCommentDelete}
                    targetId={
                        getTonkeanEntityType(targetId) === TonkeanType.ACTIVITY
                            ? (targetId as TonkeanId<TonkeanType.ACTIVITY>)
                            : undefined
                    }
                />
            )}
            {isCommentsAllowed && canUserEditItem && !showAddCommentForm && !disableReplyToComment && (
                <ToggleText
                    data-automation="commentable-card-comment-on-thread-button"
                    onClick={handleClick}
                    threadlevel={threadLevelCalc}
                >
                    <StyledCommentsIcon /> Reply
                </ToggleText>
            )}
            {isCommentsAllowed && showAddCommentForm && initiativeId && workflowVersionType && (
                <CommentInputWrapper $threadlevel={threadLevelCalc}>
                    <AddCommentInput
                        defaultCommentText={defaultCommentText}
                        targetId={targetId}
                        onSubmit={onCommentAdded}
                        showOnReply={setShowAddCommentForm}
                        canUserEditItem={canUserEditItem}
                        initiativeId={initiativeId}
                        workflowVersionType={workflowVersionType}
                        isInThread
                    />
                </CommentInputWrapper>
            )}
        </Container>
    );
};

export default CommentableCard;
