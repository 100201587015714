import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

const LoadingContainer = styled.div`
    margin-top: 30px;
`;

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    gap: 10px;
`;

const PlaceHoldersRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ProcessBuilderInitialInputStateLoading: React.FC = () => {
    return (
        <LoadingContainer>
            {range(4).map((index) => (
                <LoadingSection key={index}>
                    <Placeholder $height="28px" $width="175px" $borderRadius="0" />
                    <PlaceHoldersRow>
                        <Placeholder $height="28px" $width="270px" $borderRadius="0" />
                        <Placeholder $height="28px" $width="270px" $borderRadius="0" />
                        <Placeholder $height="28px" $width="270px" $borderRadius="0" />
                    </PlaceHoldersRow>
                    <PlaceHoldersRow>
                        <Placeholder $height="28px" $width="270px" $borderRadius="0" />
                        <Placeholder $height="28px" $width="406px" $borderRadius="0" />
                        <Placeholder $height="28px" $width="270px" $borderRadius="0" />
                    </PlaceHoldersRow>
                </LoadingSection>
            ))}
        </LoadingContainer>
    );
};

export default ProcessBuilderInitialInputStateLoading;
