import { useAngularService } from 'angulareact';
import isEqual from 'lodash.isequal';
import { useEffect, useMemo, useState } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { CustomFieldsManager } from '@tonkean/shared-services';
import {
    type CustomTrigger,
    type FieldDefinition,
    type FieldInstance,
    FormDefinitionType,
    type Initiative,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY, Truthy } from '@tonkean/utils';

export const getGroupIdToMatchedItemFromMatchedData = (
    matchedItemsData: MatchedItemData[],
): Record<TonkeanId<TonkeanType.GROUP>, TonkeanId<TonkeanType.INITIATIVE>> => {
    const entries = matchedItemsData
        .map((matched) =>
            matched.matchedInitiative ? [matched.matchedInitiative.groupId, matched.matchedInitiative.id] : undefined,
        )
        .filter(Truthy);

    return Object.fromEntries(entries);
};

export interface MatchedItemData {
    fieldDefinition: FieldDefinition;
    matchedInitiative: Initiative | undefined;
    customTrigger: CustomTrigger;
}

const useMatchedItems = (
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    initiative?: Pick<Initiative, 'fields'>,
) => {
    const customFieldsManager: CustomFieldsManager = useAngularService('customFieldsManager');
    const customTriggerManager = useAngularService('customTriggerManager');
    const trackHelper = useAngularService('trackHelper');

    const [matchedItemsData, setMatchedItemsData] = useState<MatchedItemData[]>(EMPTY_ARRAY);

    const [{ data: fields }, getFields] = useLazyAsyncMethod(customFieldsManager, 'getFieldDefinitions');

    useEffect(() => {
        if (workflowVersionId) {
            getFields(workflowVersionId, true);
        }
    }, [getFields, workflowVersionId]);

    const matchingItemFields = useMemo(() => {
        return fields?.filter((field: FieldDefinition<any>) => {
            const forMatchingItem = field.definition?.matchConfiguration?.isForMatchingItem;

            return (
                forMatchingItem &&
                (field.type === FormDefinitionType.MANUAL ||
                    (field.type === FormDefinitionType.EXTERNAL &&
                        !field.definition.matchConfiguration.creatingCustomTriggerId &&
                        !field.definition?.matchConfiguration?.idRelationFieldDefinitionId)) &&
                (field.idRelationField || field.linkedCustomTrigger)
            );
        });
    }, [fields]);

    useEffect(() => {
        if (!matchingItemFields) {
            return;
        }

        const getMatchingFields = () => {
            const matchedItemsDataPromises: Promise<MatchedItemData>[] = matchingItemFields.map(async (field) => {
                let customTrigger = {} as CustomTrigger;
                if (field.linkedCustomTrigger?.id) {
                    customTrigger = await customTriggerManager.getCustomTriggerFromCacheOrFallbackServer(
                        workflowVersionId,
                        field.linkedCustomTrigger?.id,
                    );
                }

                const matchedFieldValue = initiative
                    ? (initiative.fields || []).find(
                          (initiativeField: FieldInstance) => initiativeField.fieldDefinition.id === field.id,
                      )
                    : undefined;

                let matchedInitiative: Initiative | undefined = undefined;
                if (matchedFieldValue?.formattedValue) {
                    matchedInitiative = await trackHelper.getInitiativeById(matchedFieldValue.formattedValue);
                }

                return {
                    fieldDefinition: field,
                    matchedInitiative,
                    customTrigger,
                };
            });

            return matchedItemsDataPromises;
        };

        const matchedItemsDataPromises = getMatchingFields();

        Promise.all(matchedItemsDataPromises).then((foundMatchedItemsData) => {
            // To reduce unnecessary renders, only if the matched data is different from the already set matched data do we need to calculate the data returned from this hook
            setMatchedItemsData((prevState) => {
                if (isEqual(prevState, foundMatchedItemsData)) {
                    return prevState;
                }
                return foundMatchedItemsData;
            });
        });
    }, [customTriggerManager, initiative, matchingItemFields, trackHelper, workflowVersionId]);

    return matchedItemsData;
};

export default useMatchedItems;
