function validateSQLDatabasePaginationQuery(
    paginationEnabled: boolean,
    query: string | undefined,
    pageSize: number | undefined,
): string | undefined {
    if (!paginationEnabled) {
        return;
    } else if (!query?.includes('{{Limit}}')) {
        return 'Paginated query must use {{Limit}}';
    } else if (pageSize && pageSize > 1000) {
        return 'SQL SELECT commands are limited to 1000 records per page';
    } else {
        return;
    }
}

export default validateSQLDatabasePaginationQuery;
