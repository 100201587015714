import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import FormSettingsInput from './FormSettingsInput';
import FormSettingsLabel from './FormSettingsLabel';
import FormSettingsUploadLogoIcon from './FormSettingsUploadLogoIcon';
import { ReactComponent as GenericLogoIcon } from '../../../../../images/icons/generic-logo.svg';
import BoardThemeOverrideWarning from '../../../BoardSettingsModule/components/ThemeSettings/BoardThemeOverrideWarning';

import { FormBuilderContext } from '@tonkean/infrastructure';
import { Tooltip, useUUID } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { isUrlValid } from '@tonkean/utils';

const LogoSectionWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

const UploadLogoInput = styled(FormSettingsInput)`
    margin-top: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin-right: 8px;
`;

const UploadLogoWrapper = styled.div`
    display: flex;
    margin-right: -30px;
`;

const CheckWrapper = styled.span`
    width: 30px;
    display: grid;
    place-items: center;

    .tnk-icon {
        display: inline-flex;
    }

    & polygon {
        fill: ${Theme.colors.success};
    }

    .red path {
        fill: #f73838;
    }
`;

const UploadLogoTitle = styled.div`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};

    .tnk-icon {
        margin-right: 2px;
    }
`;

const Error = styled.div`
    color: ${Theme.colors.error};
    margin-top: 5px;
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    form: Form;
    onFormDefinitionChanged: (logoUrl: string) => void;
}

const FormSettingsUploadLogo: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    const [url, setUrl] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>();
    const [isDirty, setIsDirty] = useState<boolean>();
    const [isUnsupportedType, setIsUnsupportedType] = useState<boolean>();
    const id = useUUID();

    const onUrlChanged = ({ target: { value: newUrl } }: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(newUrl);
        setIsValid(Boolean(isUrlValid(newUrl)));
        setIsUnsupportedType(newUrl.endsWith('.svg'));
        setIsDirty(newUrl !== form.definition.logoUrl);
    };

    const updateUrl = () => {
        onFormDefinitionChanged(url ?? '');
        setIsDirty(false);
    };

    /** This effect clears the input when a new form is selected before uploading */
    useEffect(() => {
        setUrl(undefined);
        setIsDirty(false);
        setIsValid(undefined);
    }, [form.id]);

    const projectManager = useAngularService('projectManager');
    const forceBoardThemeOverride = projectManager.project.forceThemeConfiguration;
    const editingDisabled = !isEditable || forceBoardThemeOverride;
    const editingDisabledTooltip = forceBoardThemeOverride
        ? 'Board theme override has been enabled'
        : 'Form cannot be edited when creating';

    return (
        <LogoSectionWrapper>
            <FormSettingsLabel htmlFor={id}>
                <UploadLogoTitle>
                    <span className="tnk-icon">
                        <GenericLogoIcon />
                    </span>
                    Upload Logo From Web
                </UploadLogoTitle>
            </FormSettingsLabel>
            <UploadLogoWrapper>
                <Tooltip content={editingDisabledTooltip} disabled={!editingDisabled}>
                    <UploadLogoInput
                        value={url ?? form.definition.logoUrl ?? ''}
                        onChange={onUrlChanged}
                        id={id}
                        valid={!!isValid || !isDirty || !isUnsupportedType}
                        disabled={editingDisabled}
                    />
                </Tooltip>
                <Button
                    shape={ButtonShape.RECTANGULAR}
                    disabled={forceBoardThemeOverride || !isDirty || !isValid || isUnsupportedType}
                    onClick={updateUrl}
                    size={ButtonSize.MEDIUM}
                >
                    Upload
                </Button>
                <CheckWrapper>
                    <FormSettingsUploadLogoIcon isDirty={!!isDirty} isValid={!!isValid && !isUnsupportedType} />
                </CheckWrapper>
            </UploadLogoWrapper>
            {forceBoardThemeOverride && <BoardThemeOverrideWarning />}
            {isValid === false && <Error>Please enter a valid URL. (e.g. https://tonkean.com/logo.png)</Error>}
            {isUnsupportedType && <Error>The provided type is not supported</Error>}
        </LogoSectionWrapper>
    );
};

export default FormSettingsUploadLogo;
