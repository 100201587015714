import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import SingleWorkerRunFlowRunTableRowGrid from './SingleWorkerRunFlowRunTableRowGrid';
import { ReactComponent as RefreshIcon } from '../../../../../../../images/icons/history/refresh.svg';
import { ReactComponent as ReRunIcon } from '../../../../../../../images/icons/re-run.svg';
import HistoryContext from '../../../../entities/HistoryContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Menu, MenuItem, SearchBox, Tooltip, useCreateDialog } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Sticky = styled.div`
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 20px 0;
`;

const Title = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    line-height: normal;
    color: ${Theme.colors.gray_700};
    flex: 1 0 auto;
    margin-right: 40px;
`;

const Search = styled(SearchBox)`
    max-width: 350px;
`;

const HeaderRow = styled(SingleWorkerRunFlowRunTableRowGrid)`
    border: 1px solid ${Theme.current.palette.general.border};
    border-radius: 3px 3px 0 0;
    height: 30px;
    min-height: 30px;
    align-items: center;
    padding: 0;
    font-size: ${FontSize.XSMALL_10};
`;

const RowItem = styled.div`
    position: relative;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 10px;
    color: ${Theme.current.palette.TUI.notSelected};
`;

const ButtonsItem = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    justify-self: end;
    align-items: center;
    padding-right: 25px;
`;

const ButtonStyled = styled(Button)`
    margin-left: 10px;
    flex-shrink: 0;
`;

const RerunText = styled.div`
    margin-top: 5px;
`;

interface Props {
    searchTerm: string;
    workerRun?: WorkerRun;

    reload(): void;

    onChange(searchTerm: string): void;
}

const SingleWorkerRunFlowRunTableHeader: React.FC<Props> = ({ searchTerm, onChange, reload, workerRun }) => {
    const allowRerunFromPending = useFeatureFlag('tonkean_feature_allow_rerun_from_pending_state');
    const runAgainButtonRef = useRef<HTMLButtonElement>(null);

    const { environmentIsActive, updateState } = useContext(HistoryContext);
    const { confirm } = useCreateDialog();

    const [showRunAgainMenu, setShowRunAgainMenu] = useState(false);

    const [, rerunFailedCustomTriggers] = useLazyTonkeanService('rerunFailedCustomTriggers');
    const [, triggerRunOnExistingItemAgain] = useLazyTonkeanService('runOnExistingItemAgain');

    const onRunAgainTypeSelected = (isFromFailed: boolean) => {
        if (!workerRun?.initiative) {
            return;
        }

        const initiative = workerRun.initiative;
        confirm(
            `Run ${initiative.title} through the module flow`,
            <>
                {isFromFailed ? (
                    'Are you sure you want to re-run from failed action?'
                ) : (
                    <>
                        Would you like to run <strong>{initiative.title}</strong> through the module flow again?
                    </>
                )}
                <div className="margin-top">
                    {isFromFailed ? (
                        'Running from failed action will re-run the failed action, and then continue to run the rest of the flow actions, based on their order'
                    ) : (
                        <div>
                            Running again will invoke all the triggers in the flow, including triggers that are defined
                            to run only once.
                            <RerunText>
                                Please note that rerunning will not create a new module item and create actions in the
                                external systems will not invoke again.
                            </RerunText>
                        </div>
                    )}
                </div>
            </>,
            {
                okLabel: 'Run Again',
                onOk() {
                    if (isFromFailed) {
                        return rerunFailedCustomTriggers(workerRun.projectId, workerRun.id, workerRun.startTime);
                    } else {
                        return triggerRunOnExistingItemAgain(workerRun.groupId, [initiative.id], true);
                    }
                },
            },
        );
    };

    const onRefreshClick = () => {
        if (workerRun) {
            reload();
        }
    };

    const hasFailedActions = workerRun?.workerRunLogics?.some((workerRunLogic) => workerRunLogic.hasError);
    const rerunPending =
        allowRerunFromPending && workerRun?.workerRunLogics?.some((workerRunLogic) => !workerRunLogic.completed);
    const rerunFailedActionsEnabled = hasFailedActions || rerunPending;

    return (
        <Sticky>
            <Flex>
                <Title>Flow Investigation</Title>
                <Search value={searchTerm} onChange={(event) => onChange(event.target.value)} />
            </Flex>
            <HeaderRow>
                <RowItem>Action/Trigger</RowItem>
                <RowItem>Status</RowItem>
                <RowItem>
                    <ButtonsItem>
                        <ButtonStyled
                            size={ButtonSize.SMALL}
                            onClick={onRefreshClick}
                            disabled={!workerRun}
                            outlined
                            flex
                        >
                            <RefreshIcon />
                            Refresh
                        </ButtonStyled>

                        <Tooltip
                            content="Environment is off"
                            disabled={environmentIsActive}
                            nodeRef={runAgainButtonRef}
                        />
                        <Menu
                            show={showRunAgainMenu}
                            menuItems={
                                <>
                                    <MenuItem onClick={() => onRunAgainTypeSelected(false)}>Entire event</MenuItem>
                                    <Tooltip
                                        disabled={rerunFailedActionsEnabled}
                                        content="None of the actions has failed"
                                    >
                                        <MenuItem
                                            onClick={() => onRunAgainTypeSelected(true)}
                                            disabled={!rerunFailedActionsEnabled}
                                        >
                                            From failed actions
                                        </MenuItem>
                                    </Tooltip>
                                </>
                            }
                            onClose={() => setShowRunAgainMenu(false)}
                            nodeRef={runAgainButtonRef}
                        />
                        <ButtonStyled
                            size={ButtonSize.SMALL}
                            onClick={() => setShowRunAgainMenu(true)}
                            disabled={!workerRun?.initiative || !environmentIsActive}
                            ref={runAgainButtonRef}
                            outlined
                            flex
                        >
                            <ReRunIcon />
                            Run Again
                        </ButtonStyled>
                    </ButtonsItem>
                </RowItem>
            </HeaderRow>
        </Sticky>
    );
};

export default SingleWorkerRunFlowRunTableHeader;
