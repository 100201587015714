<div class="example-highlights-modal text-center common-close-btn-container flex flex-col common-height-full relative">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="common-title-md mod-light line-height-normal margin-bottom margin-top">Digest highlights</div>

    <div class="margin-auto common-size-xs modal-description">
        Bite sized, actionable insights, that give the manager a concise view of what they need to know, and act on.
    </div>

    <tnk-gallery images="images" class="flex-grow"></tnk-gallery>
</div>
