import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import TimelineEventMessage from './TimelineEventMessage';

import { Breakpoint } from '@tonkean/infrastructure';
import { ActivityType } from '@tonkean/tonkean-entities';
import type { Person } from '@tonkean/tonkean-entities';
import type { Activity } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';

const EventText = styled.div`
    margin-bottom: 6px;
    font-size: ${FontSize.SMALL_12};
    line-height: 10px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        font-Size: ${FontSize.SMALL_12}
        line-height: 10px;
    }
`;

interface TimelineEventTitleTextProps {
    event: Activity;
    currentUser: Person;
}

const TimelineEventTitleText: React.FC<TimelineEventTitleTextProps> = ({ event, currentUser }) => {
    const customFieldsManager = useAngularService('customFieldsManager');

    let actorName: string;
    if (event.metadata?.updatedViaBot) actorName = 'Tonkean';
    else if (event.actor.id === currentUser.id) actorName = 'You';
    else actorName = event.actor.name;

    return (
        <EventText data-automation="timeline-event-title">
            {event.type === ActivityType.BOT_GATHER ? (
                <>
                    <TimelineEventMessage event={event} fieldsMap={customFieldsManager.fieldsMap} /> {actorName}
                </>
            ) : (
                <>
                    {actorName} <TimelineEventMessage event={event} fieldsMap={customFieldsManager.fieldsMap} />
                </>
            )}
        </EventText>
    );
};

export default TimelineEventTitleText;
