import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as TooltipIcon } from '../../../../../images/icons/tooltip2.svg';
import {
    INNER_ITEM_DETAILS_DESCRIPTION_DEFAULT,
    INNER_ITEM_DETAILS_NAME_DEFAULT,
    ITEM_DETAILS_DESCRIPTION_DEFAULT,
    ITEM_DETAILS_NAME_DEFAULT,
} from '../../itemDetailsSharedConsts';
import { SelectInputSourceTitleContainer } from '../ItemDetailsInputSourceSelection/contentComponents/sharedComponents';
import { useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery } from '../ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';
import HexagonWrappedItemDetailsModuleEditorIcon from '../ItemDetailsModuleEditor/HexagonWrappedItemDetailsModuleEditorIcon';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import {
    Field,
    FormikAutosave,
    H4,
    Input,
    InputReveal,
    Paragraph,
    SimpleErrorStateMessage,
    Tooltip,
} from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { CustomTrigger, TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { yupTonkeanExpression } from '@tonkean/utils';

const TopFormWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
`;

const NameDescriptionFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    width: 100%;
`;

const BottomFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-top: 1px solid #e9ecf1;
    border-bottom: 1px solid #e9ecf1;
    margin-top: 26px;
    padding-bottom: 26px;
`;

const StyledSelectInputSourceTitleContainer = styled(SelectInputSourceTitleContainer)`
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: start;
`;

const NameInputReveal = styled(InputReveal)`
    padding: 0 8px;
`;

const RequesterDescription = styled(Paragraph)`
    margin-bottom: 18px;
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const ItemDetailsEditForm: React.FC<Props> = ({ groupId, workflowVersionId, targetId }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const $timeout = useAngularService('$timeout');
    const { id: projectId } = useProject();

    const customTriggerManager = useAngularService('customTriggerManager');
    const consts = useAngularService('consts');
    const logicBlockTypes = consts.getLogicBlockTypes();

    const [{ data }, reexecuteQueryItemDetails] = useGetItemDetailsForItemDetailsModuleEditorByTargetIdQuery({
        variables: { targetId, workflowVersionId },
    });

    const [{ loading: updateItemLoading, error: updateItemDetailsError }, updateItemDetails] =
        useLazyTonkeanService('updateItemDetails');

    const [{ loading: createItemLoading, error: creteItemDetailsError }, createItemDetails] =
        useLazyTonkeanService('createItemDetails');

    const itemDetails = useMemo(() => {
        return data?.itemDetailsByTargetIdAndWorkflowVersionId;
    }, [data?.itemDetailsByTargetIdAndWorkflowVersionId]);

    const targetIsGroup = useMemo<boolean>(() => {
        return targetId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.GROUP]);
    }, [targetId]);

    const [namePlaceholder, setNamePlaceholder] = useState<string | undefined>();
    const [descriptionPlaceholder, setDescriptionPlaceholder] = useState<string | undefined>();

    useEffect(() => {
        if (targetIsGroup) {
            setNamePlaceholder(ITEM_DETAILS_NAME_DEFAULT);
            setDescriptionPlaceholder(ITEM_DETAILS_DESCRIPTION_DEFAULT);
            return;
        }

        const getCustomTrigger = async () => {
            return await customTriggerManager.getCustomTriggerFromCacheOrFallbackServer(workflowVersionId, targetId);
        };

        getCustomTrigger().then((customTrigger: CustomTrigger | undefined) => {
            let overrideDetailsTitlePlaceholder;
            let overrideDetailsDescriptionPlaceholder;

            if (customTrigger) {
                overrideDetailsTitlePlaceholder =
                    logicBlockTypes[customTrigger.customTriggerType].innerItemDetailsTitlePlaceholder;
                overrideDetailsDescriptionPlaceholder =
                    logicBlockTypes[customTrigger.customTriggerType].innerItemDetailsDescriptionPlaceholder;
            }

            setNamePlaceholder(overrideDetailsTitlePlaceholder ?? INNER_ITEM_DETAILS_NAME_DEFAULT);
            setDescriptionPlaceholder(overrideDetailsDescriptionPlaceholder ?? INNER_ITEM_DETAILS_DESCRIPTION_DEFAULT);
        });
    }, [customTriggerManager, logicBlockTypes, targetId, targetIsGroup, workflowVersionId]);

    const schema = Yup.object({
        name: Yup.string().nullable(),
        description: Yup.string().nullable(),
        requesterExpression: yupTonkeanExpression().nullable(),
    });

    return (
        <>
            <Formik
                initialValues={{
                    name: itemDetails?.name,
                    description: itemDetails?.description,
                    requesterExpression: itemDetails?.requesterExpression,
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    const name = values.name?.trim();
                    const description = values.description?.trim();
                    if (itemDetails) {
                        await updateItemDetails(
                            groupId,
                            itemDetails.id as TonkeanId<TonkeanType.ITEM_DETAILS>,
                            workflowVersionId,
                            name || '',
                            description || '',
                            values.requesterExpression,
                        );
                    } else {
                        await createItemDetails(
                            projectId,
                            groupId,
                            targetId,
                            workflowVersionId,
                            name || '',
                            description || '',
                        );
                    }

                    $timeout(() => workflowVersionManager.incrementWorkflowVersionCounter(workflowVersionId));

                    return reexecuteQueryItemDetails({ requestPolicy: 'network-only' });
                }}
            >
                <Form>
                    <Field>
                        <TopFormWrapper>
                            <HexagonWrappedItemDetailsModuleEditorIcon
                                targetId={targetId}
                                workflowVersionId={workflowVersionId}
                                iconWrapperSize={62}
                            />
                            <NameDescriptionFieldWrapper>
                                <NameInputReveal
                                    as={Input}
                                    name="name"
                                    placeholder={namePlaceholder}
                                    size={InputSize.XLARGE}
                                />
                                <InputReveal as={Input} name="description" placeholder={descriptionPlaceholder} />
                            </NameDescriptionFieldWrapper>
                        </TopFormWrapper>
                        {targetIsGroup && (
                            <BottomFormWrapper>
                                <StyledSelectInputSourceTitleContainer>
                                    <H4 $bold>Requester</H4>
                                    <Tooltip
                                        placement="right"
                                        limitWidth={19}
                                        content="Define the persona that initiated the request."
                                    >
                                        <TooltipIcon />
                                    </Tooltip>
                                </StyledSelectInputSourceTitleContainer>
                                <RequesterDescription>
                                    Define an expression that represents this item's Requester. Depending on your
                                    pricing plan, this may be used to calculate usage.
                                </RequesterDescription>
                                <FormikTonkeanExpression
                                    groupId={targetId}
                                    workflowVersionId={workflowVersionId}
                                    name="requesterExpression"
                                    fieldsToFilter={['TNK_OTHER', 'TNK_OTHER_MATCHED_ENTITY']}
                                    hideEditorButton
                                />
                            </BottomFormWrapper>
                        )}
                        <FormikAutosave />
                        {updateItemDetailsError && (
                            <SimpleErrorStateMessage error={updateItemDetailsError} showSmallError />
                        )}
                    </Field>
                </Form>
            </Formik>
        </>
    );
};

export default ItemDetailsEditForm;
