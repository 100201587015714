<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
            <div ng-hide="connecting || integration" class="text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="{{ currentIntegration.name }}"
                    ng-click="authorize()"
                    ng-if="!data.isUsingGCPServiceAccount"
                >
                    Authenticate with {{ currentIntegration.displayName }}
                </button>

                <div ng-if="data.isUsingGCPServiceAccount">
                    <tnk-google-internal-gcp-service-account
                        authenticate="authorize"
                    ></tnk-google-internal-gcp-service-account>
                </div>
                <div class="margin-normal-sm-v common-subtitle-inner"></div>
            </div>
            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>
            <div ng-show="integration && !connecting">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change User
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>

                <div class="common-bold">Do you want to send emails?</div>
                <div class="btn-group margin-top">
                    <label
                        class="btn btn-default"
                        ng-class="{ 'btn-primary': !data.sendEmails }"
                        uib-btn-radio="false"
                        ng-model="data.sendEmails"
                    >
                        No
                    </label>
                    <label
                        class="btn btn-default"
                        ng-class="{ 'btn-primary': data.sendEmails }"
                        uib-btn-radio="true"
                        ng-model="data.sendEmails"
                    >
                        Yes
                    </label>
                </div>

                <div class="margin-bottom margin-top-lg info-box common-size-xxs">
                    <span>We take the security of your data seriously, Tonkean will not read any of your emails.</span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div
                class="common-color-danger pull-left"
                ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid"
            >
                Invalid
            </div>
            <button
                class="btn btn-default"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>

            <button
                class="btn btn-primary"
                ng-disabled="!data.integrationForm.$valid"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                type="button"
                ng-click="ok()"
            >
                OK
            </button>
        </div>
    </div>
</form>
