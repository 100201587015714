<div class="email-endpoint-modal flex flex-col mod-align-center common-close-btn-container">
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title-->
    <div class="margin-bottom-lg text-center">
        <div class="common-size-l common-title-font">Add items by email</div>
        <div class="common-size-xxs">to: {{ pm.groupsMap[groupId].name }}</div>
    </div>

    <!-- Enable email endpoint -->
    <div class="common-size-xs margin-top-xlg margin-bottom-xlg text-center" ng-hide="data.emailAddress">
        <a class="btn btn-secondary" ng-click="enableEmailEndpoint()">
            <span class="flex-vmiddle">
                <span class="common-color-primary margin-right-xs">
                    {{ data.loadingEnableEmailEndpoint ? 'Creating...' : 'Generate Email Address' }}
                </span>
                <span class="svg-icon-sm">
                    <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                </span>
            </span>
        </a>
    </div>

    <!-- Copy share link-->
    <div ng-if="data.emailAddress" class="margin-top-lg common-width-full">
        <!-- Input (readonly in angular) with copy button -->
        <div class="input-group">
            <div class="relative flex">
                <input
                    type="text"
                    id="input-emailAddress"
                    class="form-control inline-block text-center common-width-full email-endpoint-modal-link-input"
                    ng-model="data.emailAddress"
                />
            </div>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-default email-endpoint-modal-copy-btn"
                    ng-click="copyUrlToClipboard('input-emailAddress')"
                >
                    Copy
                </button>
            </span>
        </div>
        <div class="margin-top-xs common-size-xxxs">
            <a
                ng-if="pm.isOwner || gm.currentUserIsOwnerMap[groupId]"
                ng-click="disableEmailEndpoint()"
                class="common-color-danger margin-right"
            >
                Revoke
            </a>
            <a
                ng-if="pm.isOwner || gm.currentUserIsOwnerMap[groupId]"
                ng-click="enableEmailEndpoint()"
                class="common-color-danger"
            >
                Regenerate
            </a>

            <!-- Loading -->
            <span
                class="loading-small margin-left"
                ng-if="data.loadingEnableEmailEndpoint || data.loadingDisableEmailEndpoint"
            ></span>
            <!-- Error -->
            <div
                class="common-size-xxxs common-color-danger margin-left"
                ng-if="
                    !(data.loadingEnableEmailEndpoint || data.loadingDisableEmailEndpoint) &&
                    (data.errorLoadingEnableEmailEndpoint || data.errorLoadingDisableEmailEndpoint)
                "
            >
                {{ data.errorLoadingEnableEmailEndpoint || data.errorLoadingDisableEmailEndpoint }}
            </div>
        </div>
        <div class="margin-top-md">
            <div class="common-size-xxxs mod-blue-light padding-normal-sm rounded-box">
                <span class="svg-icon-nav-sm margin-right-xs">
                    <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                </span>
                <strong>Important Notes:</strong>
                <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                    <li>
                        <div class="padding-left-xxs">The subject will be the item name</div>
                    </li>
                    <li>
                        <div class="padding-left-xxs">The body will be the item description</div>
                    </li>
                    <li>
                        <div class="padding-left-xxs">Only group members can use this email address</div>
                    </li>
                    <li>
                        <div class="padding-left-xxs">Emails with the same subject will be updated</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="flex-grow"></div>

    <button
        ng-if="data.emailAddress"
        ng-click="$dismiss()"
        class="btn btn-lg btn-primary padding-normal-lg-h margin-top-md"
    >
        Close
    </button>
</div>
