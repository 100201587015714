import React, { useEffect, useState } from 'react';

import useProjectIntegrationEntityConfiguration from './useProjectIntegrationEntityConfiguration';
import GenericContentBySidePaneLayout from '../../modules/GenericContentBySidePaneLayoutModule/GenericContentBySidePaneLayout';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';

const ProjectIntegrationEntityState: React.FC = () => {
    // Current ProjectIntegration Id.
    const [projectIntegrationId, entityId] = useGetStateParams<[string, string]>('enterpriseComponentId', 'entityId');

    // Current ProjectIntegration entity.
    const [entity, setEntity] = useState<ProjectIntegrationEntity>();

    // Getting the projectIntegration by id.
    const { projectIntegration } = useGetProjectIntegrationById(projectIntegrationId);

    // Getting the projectIntegration entity by entity id.
    const { data: entityResponse, error: errorGetEntityById } = useTonkeanService(
        'getProjectIntegrationEntityById',
        projectIntegrationId,
        entityId,
    );

    // Updating the current entity state
    useEffect(() => {
        if (entityResponse) {
            setEntity(entityResponse);
        }
    }, [entityResponse]);

    // Getting the projectIntegration entity summaries for breadcrumbs.
    const { data: entitiesSummary } = useTonkeanService('getProjectIntegrationEntitySummaries', projectIntegrationId);

    // Getting the configuration for the layout
    const configuration = useProjectIntegrationEntityConfiguration();

    const isComponentReady = projectIntegration && entity && !errorGetEntityById;

    return (
        <>
            {isComponentReady && (
                <GenericContentBySidePaneLayout
                    sidePaneConfiguration={configuration}
                    clickableBack={{
                        state: 'product.projectIntegrationPage',
                        params: {
                            page: ProjectIntegrationPageMenuItemType.ENTITIES,
                            enterpriseComponentId: projectIntegrationId,
                        },
                    }}
                    projectIntegration={projectIntegration}
                    entity={entity}
                    entitiesSummary={entitiesSummary?.entities || []}
                    setEntity={setEntity}
                    layoutMarginBottom={0}
                    showBackButton
                />
            )}
        </>
    );
};

export default ProjectIntegrationEntityState;
