import type InitiativeRowData from '../entities/InitiativeRowData';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

export enum ValidationType {
    DATE_RANGE = 'DATE_RANGE',
    REQUIRED = 'REQUIRED',
}

interface FieldValidation<T> {
    fieldDefinitionKey: FieldDefinitionKey;
    type: ValidationType;
    params: T;

    isValid(data: InitiativeRowData | undefined): boolean;
}

export default FieldValidation;
