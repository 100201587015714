import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import type UserMenuSectionConfig from '../../../entities/UserMenuSectionConfig';
import UserMenuItem from '../UserMenuItem';

import { useCreateDialog } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const AddBoardToAccountButton = styled(Button)`
    width: 150px;
    margin: 4px 8px 8px 30px;
`;

interface Props {
    setting: UserMenuSectionConfig;
}

const EnterpriseUserMenuItem: React.FC<Props> = ({ setting }) => {
    const projectManager = useAngularService('projectManager');
    const authenticationService = useAngularService('authenticationService');
    const enterpriseHelper = useAngularService('enterpriseHelper');
    const $rootScope = useAngularService('$rootScope');

    const { confirm } = useCreateDialog();

    const openAddBoardToAccountModal = async () => {
        const shouldAdd = await confirm(
            'Are you sure?',
            `You are about to add the board "${projectManager.project.name}" to your account.`,
        );
        if (!shouldAdd) {
            return;
        }

        enterpriseHelper
            .addProjectToEnterpriseById(
                authenticationService.getCurrentUser().enterprise!.id,
                projectManager.project.id,
            )
            .then(() => {
                // Update the project data now that the project was added to the enterprise.
                projectManager.getProjectData(true).then(() => {
                    $rootScope.$emit('alert', {
                        msg: `Successfully added board "${projectManager.project.name}" to the enterprise account.`,
                        type: 'success',
                    });
                });
            })
            .catch((error) => {
                $rootScope.$emit('alert', {
                    msg: `There was an error adding board "${projectManager.project.name}" to the enterprise account${
                        error?.data?.error?.message ? `: ${error.data.error.message}` : ''
                    }`,
                    type: 'danger',
                });
            });
    };

    return (
        <>
            <UserMenuItem setting={setting} />
            {!projectManager.isEnterprise && (
                <AddBoardToAccountButton size={ButtonSize.SMALL} onClick={openAddBoardToAccountModal} outlined>
                    Add Board To Account
                </AddBoardToAccountButton>
            )}
        </>
    );
};

export default EnterpriseUserMenuItem;
