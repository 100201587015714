import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
    ContractFieldDefinitionsMapping,
    ContractCustomTriggerMapping,
    ContractItemInterfacesMapping,
} from '@tonkean/contracts';
import { H3, H4, Paragraph } from '@tonkean/infrastructure';
import { InterfaceIcon } from '@tonkean/svg';
import {
    type ContractFieldMapping,
    ContractFieldType,
    type CustomTrigger,
    type FieldDefinition,
    FormDefinitionType,
    type ItemInterface,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledFieldsHeader = styled(H3)`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    padding-bottom: 8px;
`;

interface Props {
    onChangeEach: (fieldMapping: ContractFieldMapping) => void;
    fieldDefinitions: FieldDefinition[];
    itemInterfaces: ItemInterface[];
    intakeSequences: CustomTrigger[];
    fieldsMapping: ContractFieldMapping[];
    isLoadingFieldDefinitions: boolean;
    isLoadingItemInterfaces: boolean;
    isLoadingIntakeSequences: boolean;
    isLoadingMapping: boolean;
}

const ContractFieldDefinitionsMappingInputCustomProps = {
    includeSpecialFields: false,
};

const ContractFieldDefinitionsMappingOutputCustomProps = {
    includeSpecialFields: true,
};

const ContractFieldDefinitionsMappingIntakeSequencesProps = {
    placeholder: 'Select an intake sequence',
    icon: <InterfaceIcon />,
};

const ContractFieldsMappingView: React.FC<Props> = ({
    onChangeEach,
    fieldDefinitions,
    itemInterfaces,
    intakeSequences,
    fieldsMapping,
    isLoadingFieldDefinitions,
    isLoadingItemInterfaces,
    isLoadingIntakeSequences,
    isLoadingMapping,
}) => {
    const inputMappingFields = fieldsMapping.filter((field) => field.contractFieldType === ContractFieldType.INPUT);
    const outputMappingFields = fieldsMapping.filter((field) => field.contractFieldType === ContractFieldType.OUTPUT);
    const itemInterfacesMappingFields = fieldsMapping.filter(
        (field) => field.contractFieldType === ContractFieldType.ITEM_INTERFACE,
    );
    const intakeSequencesMappingFields = fieldsMapping.filter(
        (field) => field.contractFieldType === ContractFieldType.INTAKE_SEQUENCE,
    );

    const manualFieldDefinitions = useMemo(() => {
        return fieldDefinitions?.filter((fieldDefinition) => {
            return fieldDefinition.type === FormDefinitionType.MANUAL;
        });
    }, [fieldDefinitions]);

    return (
        <>
            <H4 data-automation="contract-field-mapping-title" $bold>
                Map module fields to contract fields
            </H4>

            <Paragraph data-automation="contract-field-mapping-description" $color={Theme.colors.gray_700}>
                The fields from your module will receive the contract inputs and outputs
            </Paragraph>

            <StyledFieldsHeader>Input Fields</StyledFieldsHeader>

            <ContractFieldDefinitionsMapping
                isLoadingOptions={isLoadingFieldDefinitions}
                isLoadingFieldsMapping={isLoadingMapping}
                fieldMappings={inputMappingFields}
                options={manualFieldDefinitions}
                selectorCustomProps={ContractFieldDefinitionsMappingInputCustomProps}
                onChangeEach={onChangeEach}
                allowRepeatedFields={false}
            />

            <StyledFieldsHeader>Output Fields</StyledFieldsHeader>

            <ContractFieldDefinitionsMapping
                isLoadingOptions={isLoadingFieldDefinitions}
                isLoadingFieldsMapping={isLoadingMapping}
                fieldMappings={outputMappingFields}
                options={fieldDefinitions}
                selectorCustomProps={ContractFieldDefinitionsMappingOutputCustomProps}
                onChangeEach={onChangeEach}
                allowRepeatedFields
            />

            <StyledFieldsHeader>Interfaces</StyledFieldsHeader>

            <ContractItemInterfacesMapping
                isLoadingOptions={isLoadingItemInterfaces}
                isLoadingFieldsMapping={isLoadingMapping}
                fieldMappings={itemInterfacesMappingFields}
                options={itemInterfaces}
                onChangeEach={onChangeEach}
            />

            <StyledFieldsHeader>Intake Sequences</StyledFieldsHeader>

            <ContractCustomTriggerMapping
                isLoadingOptions={isLoadingIntakeSequences}
                isLoadingFieldsMapping={isLoadingMapping}
                fieldMappings={intakeSequencesMappingFields}
                options={intakeSequences}
                onChangeEach={onChangeEach}
                selectorCustomProps={ContractFieldDefinitionsMappingIntakeSequencesProps}
            />
        </>
    );
};

export default ContractFieldsMappingView;
