/**
 * Created by ShaLi on 14/10/2015.
 */

function typeaheadMultiHighlight($sce, $injector, $log, utils) {
    let isSanitizePresent;
    isSanitizePresent = $injector.has('$sanitize');

    function containsHtml(matchItem) {
        return /<.*>/g.test(matchItem);
    }

    return function (matchItem, query) {
        if (!isSanitizePresent && containsHtml(matchItem)) {
            $log.warn('Unsafe use of typeahead please use ngSanitize'); // Warn the user about the danger
        }
        matchItem = query ? `${matchItem}`.replace(utils.getMultiWordRegex(query), '<strong>$&</strong>') : matchItem; // Replaces the capture string with a the same string inside of a "strong" tag
        if (!isSanitizePresent) {
            matchItem = $sce.trustAsHtml(matchItem); // If $sanitize is not present we pack the string in a $sce object for the ng-bind-html directive
        }
        return matchItem;
    };
}

export default angular.module('tonkean.app').filter('typeaheadMultiHighlight', typeaheadMultiHighlight);
