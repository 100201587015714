import { useAngularService } from 'angulareact';
import type { FormEvent } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import AirtableAuthenticationType, { authTypeToDisplayName } from './AirtableAuthenticationType';
import type { AirtableProjectIntegrationData } from './AirtableProjectIntegrationData';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Radio, RadioGroup, TnkSelect } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
`;

const CenteredAuthTypeRadioGroup = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

interface Table {
    label: string;
    value: string;
}

const AirtableAuthenticationComponent: React.FC<
    CustomizedAuthenticationComponentProps<AirtableProjectIntegrationData>
> = ({ authenticate, reportError, integrationConfiguration, projectIntegrationData }) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [apiToken, setApiToken] = useState<string>('');
    const [baseId, setBaseId] = useState<string>('');
    const [authType, setAuthType] = useState<AirtableAuthenticationType>(AirtableAuthenticationType.API_KEY);
    const [tablesList, setTablesList] = useState<Table[]>([]);
    const [internalProjectIntegrationData, setInternalProjectIntegrationData] =
        useState<AirtableProjectIntegrationData>(projectIntegrationData || { tableToSelectedColumn: {} });

    useEffect(() => {
        const tables = Object.keys(projectIntegrationData?.tableToSelectedColumn || {});
        setTablesList(tables.map((table): Table => ({ label: table, value: table })));
    }, [projectIntegrationData]);

    const authenticateWithAirtable = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            if (tablesList.length === 0) {
                reportError('Tables list is empty, please name/id at least one.');
                return;
            }

            let config;

            config = {
                authType,
                baseId,
                tables: tablesList.map((table) => table.value),
            };

            if (authType === AirtableAuthenticationType.OAUTH) {
                const clientId = environment.integrationKeysMap.airtable;
                const redirectUri = environment.integrationKeysMap.airtableUri;
                const state = oauthHandler.publicGetState();

                const pkcePair = await oauthHandler.generatePKCEPair();
                const scopes =
                    'data.records:read data.records:write data.recordComments:read data.recordComments:write schema.bases:read schema.bases:write webhook:manage';

                const url = `https://airtable.com/oauth2/v1/authorize?response_type=code&scope=${scopes}&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}&code_challenge=${pkcePair.challenge}&code_challenge_method=S256`;

                const code = await oauthHandler.startOAuth(url, state);

                config = {
                    ...config,
                    redirectUri,
                    codeVerifier: pkcePair.verifier,
                    code,
                };
            } else {
                config = {
                    ...config,
                    apiToken,
                };
            }

            authenticate(config, internalProjectIntegrationData, integrationConfiguration.displayName);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    const onTablesInputChanged = useCallback((tables) => {
        setTablesList(tables);
    }, []);

    useEffect(() => {
        setInternalProjectIntegrationData((currentInternalProjectIntegrationData) => ({
            ...currentInternalProjectIntegrationData,
            tableToSelectedColumn: tablesList?.reduce((acc, table) => {
                acc[table.value] = undefined;
                return acc;
            }, {}),
        }));
    }, [tablesList]);

    return (
        <div className="flex flex-col">
            <CenteredAuthTypeTitle>
                <h5>Authentication Type</h5>
            </CenteredAuthTypeTitle>
            <CenteredAuthTypeRadioGroup>
                <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                    <Radio value={AirtableAuthenticationType.API_KEY}>
                        {authTypeToDisplayName[AirtableAuthenticationType.API_KEY]}
                    </Radio>
                    <Radio value={AirtableAuthenticationType.TOKEN}>
                        {authTypeToDisplayName[AirtableAuthenticationType.TOKEN]}
                    </Radio>
                    <Radio value={AirtableAuthenticationType.OAUTH}>
                        {authTypeToDisplayName[AirtableAuthenticationType.OAUTH]}
                    </Radio>
                </RadioGroup>
            </CenteredAuthTypeRadioGroup>

            {(authType === AirtableAuthenticationType.API_KEY || authType === AirtableAuthenticationType.TOKEN) && (
                <div className="form-group">
                    <label htmlFor="login-api-token" className="col-sm-3 control-label margin-top-xs">
                        {authTypeToDisplayName[authType]}
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="password"
                            value={apiToken}
                            className="form-control"
                            id="login-api-token"
                            onChange={({ target }) => setApiToken(target.value)}
                            autoComplete="off"
                            placeholder={authTypeToDisplayName[authType]}
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Get your {authTypeToDisplayName[authType]}{' '}
                            <a
                                href={
                                    authType === AirtableAuthenticationType.API_KEY
                                        ? 'https://airtable.com/create/apikey'
                                        : 'https://airtable.com/create/tokens'
                                }
                                target="_blank"
                            >
                                here
                            </a>
                        </span>
                    </div>
                </div>
            )}

            <div className="form-group">
                <label htmlFor="airtable-base-id" className="col-sm-3 control-label margin-top-xs">
                    Base Id
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={baseId}
                        className="form-control"
                        id="airtable-base-id"
                        onChange={({ target }) => setBaseId(target.value)}
                        autoComplete="off"
                        placeholder="Base ID"
                        required
                    />
                    <span className="common-size-xxxs common-color-grey">
                        Get your base id{' '}
                        <a href="https://airtable.com/api" target="_blank">
                            here
                        </a>
                    </span>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="airtable-table-name" className="col-sm-3 control-label margin-top-xs">
                    Table Name/ID
                </label>
                <div className="col-sm-8">
                    <TnkSelect
                        value={tablesList}
                        className="margin-bottom"
                        placeholder="Type in your table name or id..."
                        onChange={onTablesInputChanged}
                        isClearable={false}
                        isMulti
                        isCreatable
                        closeMenuOnSelect
                        isSearchable
                    />
                </div>
            </div>

            {/* Connect button*/}
            <div className="flex flex-col api-token-authentication">
                <div className="flex mod-center margin-top-20">
                    <button
                        className=" btn btn-secondary btn-lg"
                        onClick={authenticateWithAirtable}
                        disabled={
                            (authType === AirtableAuthenticationType.API_KEY ||
                                authType === AirtableAuthenticationType.TOKEN) &&
                            utils.isNullOrEmpty(apiToken)
                        }
                    >
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AirtableAuthenticationComponent;
