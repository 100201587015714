import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { CustomTriggerType, MonitorTracksSecondaryType } from '@tonkean/tonkean-entities';

const innerAutonomousCustomTriggerParamsEnricher: TriggerParamsEnricher = (params, customTriggerManager) => {
    params.customTriggerType = CustomTriggerType.MONITOR_TRACKS;
    params.displayName = addInnerAutonomousAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
    const directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
        params.parentNode.node.workflowVersion.id,
        params.parentNode.node.id,
    );

    params.customTriggerActions = [
        {
            type: CustomTriggerType.MONITOR_TRACKS,
            secondaryType: MonitorTracksSecondaryType.MONITOR_TRACKS_MATCH_CONDITIONS,
            customTriggerActionDefinition: {
                monitorActionItemQuantifierType: 'ANY_ACTION_ITEMS',
                monitorContextType: 'ACTION_ITEM',
                monitorItemsConditionType: 'IMMEDIATELY',
                workerItemContextType: 'TRIGGERED_ACTION_ITEM',
                parentCustomTriggerId: directParentInWorkflowVersion.node.id,
            },
        },
    ];
};

const addInnerAutonomousAfterOptionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
    customTriggerManager,
) => {
    const directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
        configuredLogic.node.workflowVersion.id,
        configuredLogic.node.id,
    );

    const formIds: TonkeanId<TonkeanType.FORM>[] = [];

    // If the parent trigger is 'send form update' or 'when form answered', the form id will be part of the customTriggerActionDefinition
    if (directParentInWorkflowVersion.node?.customTriggerActions?.[0]?.customTriggerActionDefinition.formId) {
        formIds.push(
            directParentInWorkflowVersion.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                .formId as TonkeanId<TonkeanType.FORM>,
        );
    }

    // If the parent trigger is 'form filled', the trigger my be monitoring multiple forms.
    // If one of these forms is of type upload file, then thsi method should return true - to enable continue the flow with monitor tracks
    if (directParentInWorkflowVersion.node?.monitorForms?.length > 0) {
        formIds.push(directParentInWorkflowVersion.node?.monitorForms?.map((form) => form.id));
    }

    if (formIds.length > 0) {
        return formIds.some((formId) => {
            const formData = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
                configuredLogic.node.workflowVersion.id,
                formId,
            );
            return formData?.formQuestionType === 'UPLOAD_FILES';
        });
    }

    return false;
};

const addInnerAutonomousAfterOption: CreateAfterOption = {
    label: 'Monitor File Uploaded',
    blockDisplayName: 'Monitor File Uploaded',
    triggerParamsEnricher: innerAutonomousCustomTriggerParamsEnricher,
    canCreate: addInnerAutonomousAfterOptionCanCreate,
};

export default addInnerAutonomousAfterOption;
