import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SharePointCustomActionsDefinitionCtrl($scope, integrations, integrationsConsts) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['SHAREPOINT']['FILE'],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'CREATE_DOC_FROM_TEMPLATE':
                if (!$scope.data.definition.replacements) {
                    $scope.data.definition.replacements = [];
                }
        }
    };

    $scope.onItemNameChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.itemNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFolderIdChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.folderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewDocumentNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.newDocumentNameExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSourceFirstPdfExpressionTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.sourceFirstPdfExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSourceSecondPdfExpressionTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.sourceSecondPdfExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewDocumentContentTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.newDocumentContentExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    /**
     * Shared link handling
     * Add Permissions
     * Preview Link
     */
    $scope.onDriveItemIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.driveItemIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onShareTypeExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.shareTypeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onShareScopeExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.shareScopeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSharedUrlExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sharedUrlExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAliasRecipientsExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.aliasRecipientsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onEmailRecipientsExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailRecipientsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onIdRecipientsExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.idRecipientsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRolesExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.rolesExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onExpirationDateTimeExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.expirationDateTimeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onInviteMessageExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.inviteMessageExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Create Doc From Template
     */
    $scope.onTemplateIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTargetIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.targetIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.newNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addReplacement = function () {
        $scope.data.definition.replacements.push({});
    };

    $scope.removeReplacement = function (index) {
        $scope.data.definition.replacements.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onReplacementPlaceholderTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.replacements[index].placeholderExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onReplacementTextTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.replacements[index].textExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Preview Link
     */
    $scope.onPreviewPageExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.previewPageExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPreviewZoomExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.previewZoomExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'SharePointCustomActionsDefinitionCtrl',
        lateConstructController(SharePointCustomActionsDefinitionCtrl),
    );
