import { analyticsWrapper } from '@tonkean/analytics';

function OnBoardingModalCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $uibModalInstance,
    tonkeanService,
    selectedUsecase,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;
    $scope.groupId = $stateParams.g || $scope.pm.groupDefaultId;

    $scope.data = {
        usecases: {
            CUSTOMER_SUCCESS: { btn: 'Customers / Accounts', caption: 'customers' },
            PRODUCT_ECOSYSTEM: { btn: 'Product Milestones', caption: 'milestones' },
        },
        selectedUsecase: selectedUsecase || null,
    };

    $scope.close = function (finish) {
        analyticsWrapper.track(finish ? 'onboarding completed' : 'onboarding dismissed', {
            category: 'Onboarding',
            label: `Welcome modal ${$scope.data.tab}`,
        });

        if (!$rootScope.as.currentUser.metadata || !$rootScope.as.currentUser.metadata.onboardingWatched) {
            if (!$rootScope.as.currentUser.metadata) {
                $rootScope.as.currentUser.metadata = {};
            }
            $rootScope.as.currentUser.metadata.onboardingWatched = true;
            tonkeanService.updateProfileMetadata($rootScope.as.currentUser.metadata);
        }
        $uibModalInstance.close();
    };

    $scope.import = function () {
        $uibModalInstance.close();
    };

    $scope.createSampleTracks = function () {
        if (!$scope.creating) {
            $scope.creating = true;
            $rootScope.hideInitialQuestion = true;
            tonkeanService
                .createSampleData($scope.pm.project.id, [$scope.data.selectedUsecase])
                .then(function () {
                    $rootScope.$broadcast('newActivityUpdate');
                })
                .finally(function () {
                    $scope.close(true);
                    $scope.creating = false;
                });
        }
    };
}

export default angular.module('tonkean.app').controller('OnBoardingModalCtrl', OnBoardingModalCtrl);
