import { TrackActions } from '@tonkean/flux';
import { getOperandTree } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';
import { getDisplayFormats, getFieldDefinitionConfigurationModalSteps } from '@tonkean/constants';
import { getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function FieldDefinitionConfigurationCtrl(
    $scope,
    $state,
    $q,
    $timeout,
    $anchorScroll,
    $rootScope,
    integrations,
    modal,
    projectManager,
    customFieldsManager,
    groupPermissions,
    utils,
    groupInfoManager,
    workflowVersionManager,
    customTriggerManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.integrations = integrations;

    let confirmModalOpen = false;
    let group = null;

    $scope.showField = true;
    $scope.fieldNotPublished = false;
    $scope.afterCreateOrUpdateCallback = ctrl.afterCreateOrUpdateCallback;

    $scope.populateData = () => {
        $scope.fieldDefinitionConfigurationModalSteps = getFieldDefinitionConfigurationModalSteps();

        let workflowVersion;
        if (ctrl.workflowVersionId) {
            workflowVersion = workflowVersionManager.getCachedWorkflowVersion(ctrl.workflowVersionId);
        } else {
            workflowVersion =
                ctrl.environment && ctrl.environment === 'build'
                    ? workflowVersionManager.getDraftVersionFromCache(ctrl.groupId)
                    : workflowVersionManager.getPublishedVersionFromCache(ctrl.groupId);
        }

        group = groupInfoManager.getGroupByIdFromCache(ctrl.groupId);

        $scope.data = {
            // Modal parameters
            isForMatchedItem: ctrl.isForMatchedItem,
            workflowVersion,
            groupId: ctrl.groupId,
            projectIntegration: ctrl.projectIntegration,
            openedFromCustomTriggerId: ctrl.openedFromCustomTriggerId,
            secondaryId: ctrl.secondaryId,
            createMode: ctrl.createMode,
            duplicateMode: ctrl.duplicateMode,
            existingFieldDefinition: ctrl.existingFieldDefinition,
            selectedEntity: ctrl.selectedEntity,
            openInStep: ctrl.openInStep,
            quickCreateForExternal: ctrl.quickCreateForExternal,
            isWorkerMode: ctrl.isWorkerMode,
            manualValue: ctrl.manualValue,
            idOnlyMode: ctrl.idOnlyMode,
            allowOnlyFieldDefinitionDataTypes: ctrl.allowOnlyFieldDefinitionDataTypes,
            workflowVersionId: workflowVersion.id,
            matchedEntityFromWorkflowVersionId: ctrl.matchedEntityFromWorkflowVersionId,
            duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
            isSystemUtilized: ctrl.isSystemUtilized,
            startWithDataSource: ctrl.startWithDataSource,
            // All steps related variables.
            stepsState: {},
            visitedStepsState: {},
            skipStepsMap: {},
            allFieldGroupsNames: [],

            // All possible data types configuraiton
            fieldDefinitionDataTypes: {
                dataSource: {
                    id: 'dataSource',
                    name: 'Data Source',
                    displayName: {
                        column: 'Pull from an external data source',
                        global: 'Pull from an external data source',
                    },
                    requiresIntegrationSelectionStep: true,
                    relevantFieldDefinitionTypes: {
                        SQL: true,
                        EXTERNAL: true,
                        GOOGLE: true,
                        JIRA_COUNT_FILTER: true,
                        AGGREGATE_QUERY: true,
                        SMARTSHEET: true,
                    },
                    targetTypes: {
                        COLUMN: true,
                        GLOBAL: false,
                    },
                },
                aggregate: {
                    id: 'aggregate',
                    name: 'Aggregate',
                    displayName: {
                        column: 'Create aggregation query',
                        global: 'Create aggregation query',
                    },
                    requiresIntegrationSelectionStep: false,
                    relevantFieldDefinitionTypes: {
                        SQL: false,
                        EXTERNAL: false,
                        GOOGLE: false,
                        JIRA_COUNT_FILTER: false,
                        AGGREGATE_QUERY: true,
                        SMARTSHEET: false,
                    },
                    targetTypes: {
                        COLUMN: true,
                        GLOBAL: true,
                    },
                },
                manual: {
                    id: 'manual',
                    name: 'Manual',
                    displayName: {
                        column: 'Set the value manually',
                        global: 'Set the value manually',
                    },
                    requiresIntegrationSelectionStep: false,
                    relevantFieldDefinitionTypes: {
                        MANUAL: true,
                    },
                    targetTypes: {
                        COLUMN: true,
                        GLOBAL: true,
                    },
                },
                formula: {
                    id: 'formula',
                    name: 'Formula',
                    displayName: {
                        column: 'Formula using existing Tonkean fields',
                        global: 'Formula using existing Tonkean global fields',
                    },
                    requiresIntegrationSelectionStep: false,
                    relevantFieldDefinitionTypes: {
                        TNK_COLUMN_FORMULA: true,
                        TNK_COLUMN_AGGREGATE: true,
                    },
                    targetTypes: {
                        COLUMN: true,
                        GLOBAL: true,
                    },
                },
                matchTonkeanItem: {
                    id: 'matchTonkeanItem',
                    name: 'matchTonkeanItem',
                    displayName: {
                        column: 'Match entity for Tonkean Modules',
                        global: 'Formula using existing Tonkean global fields',
                    },
                    requiresIntegrationSelectionStep: false,
                    relevantFieldDefinitionTypes: {
                        MATCH_TONKEAN_ITEM: true,
                    },
                    targetTypes: {
                        COLUMN: true,
                        GLOBAL: false,
                    },
                },
            },

            updateFieldPermissionsType: 'ANY_COLLABORATOR',

            // The data source we're defining for the field definition.
            selectedDataTypeObject: null,
            projectDataFieldIntegrations: null,

            // Field definition type selection.
            fieldSettings: ctrl.targetType === 'GLOBAL' ? integrations.getGlobalMetricSettings() : integrations.getFieldSettings(),

            targetType: ctrl.targetType || 'MANUAL',

            // Used to reload the preview component
            reloadPreview: false,
            softLoading: false,

            // Loading for the entities related to the matchedEntity (different workflowVersion, fields, etc...)
            matchedEntityRelationsLoading: false,

            // Field definition name edition
            fieldDefinitionNameEdited: !ctrl.createMode && !ctrl.duplicateMode,

            // Field definition configuration titles
            fieldConfigurationSummaryTitle: null,
            fieldConfigurationSummarySubTitle: null,

            // Display summary
            displaySummary: null,

            // Used for fields that would like to override the project integration of the field definition.
            overrideProjectIntegrationId: null,

            // All names that cannot be used as field definition names
            forbiddenNames: utils
                .objValues(getFormulaSpecialFieldIdToDefinitionMap())
                .filter(
                    (specialFieldDefinitionObject) => specialFieldDefinitionObject.considerInTypes['COLUMN_FORMULA'],
                )
                .map((specialFieldDefinitionObject) => specialFieldDefinitionObject.id),

            // Holds all the definitions we need to preview in the preview component.
            additionalDefinitionsToPreview: [],
            initiativeDefinitionsToPreview: [],
            allDefinitionsToPreview: [],

            /**
             * A formula tree node, used to pass the new tree from the mock formula editor in the aggregate inner track
             * to the read formula builder. It will not be kept up-to-date by the formula builder, so you should not
             * trust this value.
             */
            formulaExpressionTree: undefined,

            // A map of all field definition types that only support manual preview (and not automatic).
            manualPreviewFieldDefinitionTypes: {
                SQL: true,
            },

            // Display configuration variables
            displayFormats: getDisplayFormats(),
            displayFormatApiNameToDefinitionMap: {},

            // Creating or updating field
            creatingOrUpdatingFieldDefinition: false,
            errorCreatingOrUpdatingFieldDefinition: null,

            // Deleting field
            deletingFieldDefinition: false,
            errorDeletingFieldDefinition: null,

            // Holds the field definition type the modal was opened with.
            originalFieldDefinitionType: ctrl.fieldDefinitionType,

            // Indicates whether the definition is ready to be previewed
            validDefinition: false,
            // If true, configuration is still in progress, even if definition is currently valid.
            integrationConfigurationStillInProgress: false,
            // The preview request parameters
            previewRequestParameters: {
                definitionId: 'previewedFieldDefinition',
                name: null,
                fieldDefinitionType: ctrl.fieldDefinitionType,
                fieldDefinitionTargetType: ctrl.targetType,
                projectIntegrationId: ctrl.projectIntegration ? ctrl.projectIntegration.id : null,
                fieldType: null,
                possibleValues: [],
                showManualOptionsNoResults: false,
                updateable: false,
                manualValue: null,
                fieldGroupName: null,

                // Definition configuration
                definition: null,

                // Ranges configuration
                ranges: [],
                isImportant: false,

                // Display configuration,
                displayAs: null,
                numberFieldDecimalPlaces: null,
                displayFormat: null,
                displayFormatPrefix: null,
                displayFormatPostfix: null,
                compareTimeframe: null,
                isIncrementNegative: null,
            },
            headerTitle: group.name,
            subHeaderTitle: null,
            disabledEnvironments: {},
            isActiveEnvironment: null,
            didChange: false,
            prodEnvironmentToggleDisabledMessage: 'Field was not published to Production.',
            publishedWorkflowVersion: null,
            draftWorkflowVersion: null,
            dataSourceFilter: null,
            suggestedValue: null,
        };

        if (ctrl.matchedEntityFromWorkflowVersionId && ctrl.matchedEntityFromWorkflowVersionId !== workflowVersion.id) {
            const promises = [];
            promises.push(
                workflowVersionManager.getCachedWorkflowVersionOrGetFromServer(ctrl.matchedEntityFromWorkflowVersionId),
                customFieldsManager.getFieldDefinitions(ctrl.matchedEntityFromWorkflowVersionId),
            );
            $scope.data.matchedEntityRelationsLoading = true;
            Promise.all(promises).finally(() => ($scope.data.matchedEntityRelationsLoading = false));
        }
    };

    function setFieldGroupsNames() {
        customFieldsManager.getFieldGroupNames($scope.data.workflowVersionId).then((allFieldGroupsNames) => {
            $scope.data.allFieldGroupsNames = allFieldGroupsNames;
        });
    }

    /**
     * Initialization function for the modal.
     */
    $scope.init = function () {
        $scope.populateData();

        // Steps initialization.
        initializeSteps();

        // Initialization of the display formats map.
        for (const key in $scope.data.displayFormats) {
            if ($scope.data.displayFormats.hasOwnProperty(key)) {
                $scope.data.displayFormatApiNameToDefinitionMap[
                    $scope.data.displayFormats[key].apiName +
                        $scope.data.displayFormats[key].prefix +
                        $scope.data.displayFormats[key].postfix
                ] = $scope.data.displayFormats[key].label;
            }
        }

        $scope.data.draftWorkflowVersion = workflowVersionManager.getDraftVersionFromCache($scope.data.groupId);
        setFieldGroupsNames();

        if (!$scope.data.createMode && $scope.data.existingFieldDefinition) {
            $scope.data.publishedWorkflowVersion = workflowVersionManager.getPublishedVersionFromCache(
                $scope.data.groupId,
            );

            if ($scope.data.workflowVersion.workflowVersionType === 'DRAFT') {
                const shouldDisableProd = !customFieldsManager.getFieldDefinitionFromCachesById(
                    $scope.data.publishedWorkflowVersion.id,
                    $scope.data.existingFieldDefinition.id,
                );

                if (shouldDisableProd) {
                    $scope.data.disabledEnvironments.production = $scope.data.prodEnvironmentToggleDisabledMessage;
                }
            } else {
                const fieldDoesntExists = !customFieldsManager.getFieldDefinitionFromCachesById(
                    $scope.data.draftWorkflowVersion.id,
                    $scope.data.existingFieldDefinition.id,
                );
                const doesntHavePermissionsToEditWorker = !groupPermissions.hasPermissionsToEditWorker(group);
                const shouldDisableBuild = fieldDoesntExists || doesntHavePermissionsToEditWorker;

                if (shouldDisableBuild) {
                    $scope.data.disabledEnvironments.build = fieldDoesntExists
                        ? 'Field does not exist in Build environment'
                        : 'Only Makers can edit Fields';
                }
            }

            initEditMode();
        }

        if ($scope.data.createMode || !$scope.data.existingFieldDefinition) {
            $scope.data.disabledEnvironments['production'] = $scope.data.prodEnvironmentToggleDisabledMessage;
        }

        $scope.data.subHeaderTitle = $scope.subHeaderContentDecider();

        if ($scope.data.workflowVersion.workflowVersionType === 'DRAFT') {
            $scope.data.isActiveEnvironment = group.buildEnvironmentEnabled;
        } else {
            $scope.data.isActiveEnvironment = group.workerEnabled;
        }

        // If we're required to jump to a certain step.
        if ($scope.data.openInStep) {
            switch ($scope.data.openInStep) {
                // Name configuration
                case $scope.fieldDefinitionConfigurationModalSteps.nameConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.nameConfiguration.id);
                    break;
                // Data type configuration
                case $scope.fieldDefinitionConfigurationModalSteps.dataTypeConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.dataTypeConfiguration.id);
                    break;
                // Integration configuration
                case $scope.fieldDefinitionConfigurationModalSteps.integrationConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.integrationConfiguration.id);
                    break;
                // Field definition type configuration
                case $scope.fieldDefinitionConfigurationModalSteps.fieldDefinitionTypeConfiguration.id:
                    $scope.toggleStep(
                        $scope.fieldDefinitionConfigurationModalSteps.fieldDefinitionTypeConfiguration.id,
                    );
                    break;
                // Field definition configuration
                case $scope.fieldDefinitionConfigurationModalSteps.fieldDefinitionConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.fieldDefinitionConfiguration.id);
                    break;
                // Display configuration configuration
                case $scope.fieldDefinitionConfigurationModalSteps.displayConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.displayConfiguration.id);
                    break;
                // Ranges configuration
                case $scope.fieldDefinitionConfigurationModalSteps.rangesConfiguration.id:
                    $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.rangesConfiguration.id);
                    break;
            }
        } else if ($scope.data.createMode) {
            $scope.toggleStep('dataTypeConfiguration', true);
        } else {
            $scope.toggleStep($scope.fieldDefinitionConfigurationModalSteps.fieldDefinitionConfiguration.id);
        }

        evaluateDisplaySummary();

        $scope.data.projectDataFieldIntegrations = getProjectDataFieldIntegrations();

        if (ctrl.startWithDataSource && $scope.data.fieldDefinitionDataTypes[ctrl.startWithDataSource]) {
            $scope.selectDataSource(
                $scope.data.fieldDefinitionDataTypes[ctrl.startWithDataSource],
                false,
                ctrl.projectIntegration,
            );
        }

        if (ctrl.quickCreateForExternal) {
            $scope.selectDataSource($scope.data.fieldDefinitionDataTypes.dataSource, false, ctrl.projectIntegration);
        }

        if (ctrl.matchedEntityFromWorkflowVersionId && ctrl.openedFromCustomTriggerId) {
            const customTrigger = customTriggerManager.getCachedCustomTrigger(
                $scope.data.workflowVersionId,
                ctrl.openedFromCustomTriggerId,
            );
            $scope.data.previewRequestParameters.name = `Match entity for Block ${customTrigger.displayName}`;
        }

        if (ctrl.overrideFormulaOperator) {
            const formulaTree = getOperandTree(ctrl.overrideFormulaOperator);

            $scope.data.previewRequestParameters.fieldDefinitionType = 'TNK_COLUMN_FORMULA';
            $scope.data.formulaExpressionTree = formulaTree;
        }
    };

    $scope.onModalEscClose = (force) => {
        if (force) {
            ctrl.onClose();
        } else {
            if ($scope.data.didChange) {
                if (confirmModalOpen) {
                    return;
                }

                $scope.mboxData = {
                    title: 'You have unsaved changes in your Field',
                    body: 'Are you sure you want to discard those changes?',
                    isWarn: true,
                    okLabel: 'Discard',
                    cancelLabel: 'Cancel',
                };

                confirmModalOpen = true;
                modal
                    .openMessageBox({
                        scope: $scope,
                        size: 'md',
                        windowClass: 'mod-danger',
                    })
                    .result.then(
                        function onConfirm() {
                            confirmModalOpen = false;
                            ctrl.onClose();
                        },
                        function onDismiss() {
                            confirmModalOpen = false;
                        },
                    );
            } else {
                ctrl.onClose();
            }
        }
    };

    $scope.searchGroupName = function (searchTerm) {
        return $scope.data.allFieldGroupsNames.filter((groupName) =>
            groupName.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    };

    /**
     * Occurs once an integration is selected.
     */
    $scope.selectProvider = function (projectIntegration, fieldDefinitionType) {
        if (projectIntegration?.id !== $scope.data.previewRequestParameters.projectIntegrationId) {
            $scope.data.projectIntegration = projectIntegration;
            $scope.data.previewRequestParameters.projectIntegrationId = projectIntegration.id;
            $scope.data.integrationConfigurationStillInProgress = true;
            $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = false;
            $scope.markAsChanged();
        }

        const availableFieldDefinitionTypes =
            $scope.data.fieldSettings[$scope.data.projectIntegration.integration.integrationType.toLowerCase()];
        let definitionSettings = availableFieldDefinitionTypes[0];
        if (fieldDefinitionType) {
            definitionSettings = availableFieldDefinitionTypes.find((setting) => setting.type === fieldDefinitionType);
        }

        // Send the definition settings based on the selected field definition
        if ($scope.data.idOnlyMode) {
            $scope.selectFieldDefinitionType(definitionSettings, 'integrationConfiguration');
        } else if ($scope.data.selectedDataTypeObject.id === $scope.data.fieldDefinitionDataTypes.aggregate.id) {
            const availableAggregationDefinition = availableFieldDefinitionTypes.find(
                (fieldDefinitionType) => fieldDefinitionType.type !== 'EXTERNAL',
            );
            $scope.selectFieldDefinitionType(
                availableAggregationDefinition || definitionSettings,
                'integrationConfiguration',
            );
        }
    };

    /**
     * Occurs once definition type is selected.
     */
    $scope.selectFieldDefinitionType = function (selectedDefinition, fromStep) {
        if (!selectedDefinition) {
            return;
        }

        if ($scope.data.previewRequestParameters.fieldDefinitionType !== selectedDefinition.type) {
            $scope.markAsChanged();
        }

        $scope.data.previewRequestParameters.fieldDefinitionType = selectedDefinition.type;
        $scope.data.previewRequestParameters.fieldDefinitionTypeLabel =
            getFieldDefinitionInstanceLabel(selectedDefinition);

        $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = false;
        $scope.data.integrationConfigurationStillInProgress = false;

        $scope.moveFromToStep(fromStep || 'fieldDefinitionTypeConfiguration', 'fieldDefinitionConfiguration');
    };

    /**
     * Opens the copy to another list modal.
     */
    $scope.openDuplicateMetricModal = function () {
        ctrl.onClose();
        modal.openDuplicateFieldDefinitionModal(
            $scope.data.existingFieldDefinition,
            projectManager.groupsMap[$scope.data.groupId],
            true,
        );
    };

    /**
     * Whether if the field definition data type is allowed.
     */
    $scope.isAllowedFieldDefinitionDataType = function (dataSourceType) {
        if ($scope.data.allowOnlyFieldDefinitionDataTypes && $scope.data.allowOnlyFieldDefinitionDataTypes.length) {
            return $scope.data.allowOnlyFieldDefinitionDataTypes.includes(dataSourceType);
        }
        if (dataSourceType === $scope.data.fieldDefinitionDataTypes.matchTonkeanItem.id) {
            return false;
        }
        return true;
    };

    /**
     * Selects a data source for the field definition.
     */
    $scope.selectDataSource = function (dataSourceObject, didChange, projectIntegration, fieldDefinitionType) {
        if (didChange) {
            $scope.markAsChanged();
        }
        $scope.data.selectedDataTypeObject = dataSourceObject;

        // Resetting the project integration every time we choose a different data source.
        $scope.data.projectIntegration = null;
        $scope.data.projectIntegrationId = null;
        $scope.data.previewRequestParameters.projectIntegrationId = null;

        initializeSteps(true);

        // Set the default, it may be changed by the selected data source type
        $scope.data.previewRequestParameters.definitionId = 'previewedFieldDefinition';
        $scope.data.previewRequestParameters.previewDefinitionType = 'NEW_DEFINITION';

        // Proceeding to next step immediately.
        switch ($scope.data.selectedDataTypeObject.id) {
            case $scope.data.fieldDefinitionDataTypes.dataSource.id:
                // integrationConfigurationStillInProgress will change to false once we select an integration and field type.
                $scope.data.integrationConfigurationStillInProgress = true;

                // Displaying integration selection and field definition type configuration steps.
                $scope.data.skipStepsMap['integrationConfiguration'] = false;
                $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = false;
                $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = true;

                $scope.moveFromToStep('dataTypeConfiguration', 'integrationConfiguration');

                // in case project integration is set select the provider
                if (projectIntegration) {
                    $scope.selectProvider(projectIntegration, fieldDefinitionType);
                }

                break;

            case $scope.data.fieldDefinitionDataTypes.aggregate.id:
                // integrationConfigurationStillInProgress will change to false once we select an integration and field type.
                $scope.data.integrationConfigurationStillInProgress = true;

                // Displaying integration selection and field definition type configuration steps.
                $scope.data.skipStepsMap['integrationConfiguration'] = false;
                $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = false;
                $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = true;

                $scope.moveFromToStep('dataTypeConfiguration', 'integrationConfiguration');

                // in case project integration is set select the provider
                if (projectIntegration) {
                    $scope.selectProvider(projectIntegration, fieldDefinitionType);
                }

                break;

            case $scope.data.fieldDefinitionDataTypes.matchTonkeanItem.id:
                // Displaying integration selection and field definition type configuration steps.
                $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = false;

                $scope.data.skipStepsMap['dataTypeConfiguration'] = true;
                $scope.data.skipStepsMap['integrationConfiguration'] = true;
                $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = true;
                $scope.data.skipStepsMap['displayConfiguration'] = true;
                $scope.data.skipStepsMap['rangesConfiguration'] = true;
                $scope.data.skipStepsMap['additionalInformationConfiguration'] = true;

                $scope.moveFromToStep('fieldDefinitionTypeConfiguration', 'fieldDefinitionConfiguration');

                break;

            case $scope.data.fieldDefinitionDataTypes.manual.id:
                $scope.data.integrationConfigurationStillInProgress = false;

                // Skipping integration related steps.
                $scope.data.skipStepsMap['integrationConfiguration'] = true;
                $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = true;
                $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = false;
                $scope.data.skipStepsMap['displayConfiguration'] = false;

                // Setting to MANUAL field definition type.
                $scope.data.previewRequestParameters.fieldDefinitionType = 'MANUAL';

                // If its a manual and we are editing a field, we want to enable the preview
                if ($scope.data.existingFieldDefinition) {
                    $scope.data.previewRequestParameters.definitionId = $scope.data.existingFieldDefinition.id;
                    $scope.data.previewRequestParameters.previewDefinitionType = 'EXISTING_FIELD_DEFINITION';
                }

                $scope.moveFromToStep('dataTypeConfiguration', 'fieldDefinitionConfiguration');
                break;

            case $scope.data.fieldDefinitionDataTypes.formula.id:
                $scope.data.integrationConfigurationStillInProgress = false;

                // Skipping integration related steps.
                $scope.data.skipStepsMap['integrationConfiguration'] = true;
                $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = true;
                $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = false;

                // Setting the correct field definition type
                if ($scope.data.previewRequestParameters.fieldDefinitionTargetType === 'GLOBAL') {
                    $scope.data.previewRequestParameters.fieldDefinitionType = 'TNK_COLUMN_AGGREGATE';
                } else {
                    $scope.data.previewRequestParameters.fieldDefinitionType = 'TNK_COLUMN_FORMULA';
                }

                $scope.moveFromToStep('dataTypeConfiguration', 'fieldDefinitionConfiguration');
                break;
        }
    };

    /**
     * Deletes field definition.
     */
    $scope.deleteFieldDefinition = function () {
        $scope.mboxData = {
            title: 'Delete column',
            body: `Are you sure you want to delete "${$scope.data.existingFieldDefinition.name}"?`,
            isWarn: true,
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                // okLabel clicked.
                $scope.data.deletingFieldDefinition = true;
                $scope.data.errorDeletingFieldDefinition = null;

                return customFieldsManager
                    .deleteFieldDefinition($scope.data.groupId, $scope.data.existingFieldDefinition.id)
                    .then(() => {
                        groupInfoManager.getGroup($scope.data.groupId, true);

                        if (ctrl.deleteCallback) {
                            ctrl.deleteCallback($scope.data.existingFieldDefinition);
                        }

                        TrackActions.fieldDefinitionsUpdated();
                        $rootScope.$broadcast('fieldDefinitionDeleted', {
                            field: $scope.data.existingFieldDefinition,
                        });
                        ctrl.onClose();
                    })
                    .catch((error) => {
                        if (error && error.data && error.data.error && error.data.error.message) {
                            $scope.data.errorDeletingFieldDefinition = error.data.error.message;
                        } else if (error?.data?.data?.error?.message) {
                            $scope.data.errorDeletingFieldDefinition = error.data.data.error.message;
                        }

                        $rootScope.$emit('alert', {
                            msg: $scope.data.errorDeletingFieldDefinition,
                            type: 'error',
                        });
                    })
                    .finally(() => {
                        $scope.data.deletingFieldDefinition = false;
                    });
            });
    };

    /**
     * Occurs once permissions type is selected.
     */
    $scope.onUpdateFieldPermissionsTypeSelected = function (onClickParam) {
        if ($scope.data.updateFieldPermissionsType !== onClickParam) {
            $scope.markAsChanged();
        }

        $scope.data.updateFieldPermissionsType = onClickParam;
    };

    /**
     * Occurs once the definition changed.
     */
    $scope.onDefinitionChange = function (newDefinition, doNotReloadPreview, actualFieldDefinitionType) {
        $scope.data.previewRequestParameters.actualFieldDefinitionType = actualFieldDefinitionType;

        $scope.data.previewRequestParameters.definition = newDefinition.definition;
        $scope.data.previewRequestParameters.formulaData = newDefinition.formulaData;
        $scope.data.previewRequestParameters.validDefinition = newDefinition.validDefinition;
        $scope.data.previewRequestParameters.validatingDefinition = newDefinition.validatingDefinition;
        $scope.data.previewRequestParameters.errorValidatingDefinition = newDefinition.errorValidatingDefinition;
        $scope.data.previewRequestParameters.isMultiValueField = newDefinition.isMultiValueField;
        $scope.data.previewRequestParameters.inputMultiValueSeparator = utils.isNullOrEmpty(
            newDefinition.inputMultiValueSeparator,
        )
            ? ','
            : newDefinition.inputMultiValueSeparator;
        $scope.data.previewRequestParameters.outputMultiValueSeparator = utils.isNullOrEmpty(
          newDefinition.outputMultiValueSeparator,
        )
          ? ','
          : newDefinition.outputMultiValueSeparator;
        $scope.data.fieldConfigurationSummaryTitle = newDefinition.fieldConfigurationSummaryTitle;
        $scope.data.fieldConfigurationSummarySubTitle = newDefinition.fieldConfigurationSummarySubTitle;

        $scope.data.additionalDefinitionsToPreview = newDefinition.additionalDefinitionsToPreview;
        $scope.data.initiativeDefinitionsToPreview = newDefinition.initiativeDefinitionsToPreview;
        $scope.data.globalFieldsToPreview = newDefinition.additionalKeyMetricsToPreview;

        if (!utils.isNullOrUndefined(newDefinition.updateable)) {
            $scope.data.previewRequestParameters.updateable = newDefinition.updateable;
        }
        if (!utils.isNullOrUndefined(newDefinition.fieldType)) {
            $scope.data.previewRequestParameters.fieldType = newDefinition.fieldType;
        }
        if (!utils.isNullOrUndefined(newDefinition.displayAs)) {
            $scope.data.previewRequestParameters.displayAs = newDefinition.displayAs;
        }
        if (!utils.isNullOrUndefined(newDefinition.numberFieldDecimalPlaces)) {
            $scope.data.previewRequestParameters.numberFieldDecimalPlaces = newDefinition.numberFieldDecimalPlaces;
        }
        if (!utils.isNullOrUndefined(newDefinition.fieldLabel)) {
            $scope.data.previewRequestParameters.fieldLabel = newDefinition.fieldLabel;
        }
        if (!utils.isNullOrUndefined(newDefinition.suggestedValue)) {
            $scope.data.previewRequestParameters.suggestedValue = newDefinition.suggestedValue;
        }
        if (!utils.isNullOrUndefined(newDefinition.evaluatedDisplayType)) {
            $scope.data.previewRequestParameters.evaluatedDisplayType = newDefinition.evaluatedDisplayType;
        }
        if (!utils.isNullOrUndefined(newDefinition.possibleValues)) {
            // Change the dropdown source to manual if an external field have possible values
            if (utils.isNullOrUndefined($scope.data.previewRequestParameters.dropdownSource)) {
                $scope.data.previewRequestParameters.dropdownSource = 'MANUAL';
            }
            $scope.data.previewRequestParameters.possibleValues = newDefinition.possibleValues;
            $scope.data.previewRequestParameters.showManualOptionsNoResults = newDefinition.showManualOptionsNoResults;
        }
        if (!utils.isNullOrUndefined(newDefinition.forceUseDefinitionMetadata)) {
            $scope.data.previewRequestParameters.forceUseDefinitionMetadata = newDefinition.forceUseDefinitionMetadata;
        }
        if (!utils.isNullOrUndefined(newDefinition.dropdownSource)) {
            $scope.data.previewRequestParameters.dropdownSource = newDefinition.dropdownSource;
        }
        if (!utils.isNullOrUndefined(newDefinition.dropdownOptionsFromFieldDefinitionId)) {
            $scope.data.previewRequestParameters.dropdownOptionsFromFieldDefinitionId =
                newDefinition.dropdownOptionsFromFieldDefinitionId;
        }
        if (!utils.isNullOrUndefined(newDefinition.allowAddDropdownOptions)) {
            $scope.data.previewRequestParameters.allowAddDropdownOptions = newDefinition.allowAddDropdownOptions;
        }
        if (!utils.isNullOrUndefined(newDefinition.dropdownOptionsFromFieldMetadataSyncFieldDefinition)) {
            $scope.data.previewRequestParameters.dropdownOptionsFromFieldMetadataSyncFieldDefinition =
                newDefinition.dropdownOptionsFromFieldMetadataSyncFieldDefinition;
        }
        if (!utils.isNullOrUndefined(newDefinition.defaultValueFieldDefinitionId)) {
            $scope.data.previewRequestParameters.defaultValueFieldDefinitionId =
                newDefinition.defaultValueFieldDefinitionId;
        }
        if (!utils.isNullOrUndefined(newDefinition.skipStepsMap)) {
            // If display configuration step was previously skipped and now it isn't, we set the ranges step back to not visited.
            if (
                $scope.data.skipStepsMap['displayConfiguration'] &&
                !newDefinition.skipStepsMap['displayConfiguration']
            ) {
                $scope.data.visitedStepsState['rangesConfiguration'] = false;
            }

            // Copying the skipStepsMap of newDefinition into the data.skipStepsMap, without chaging the reference of data.skipStepsMap.
            for (const key in newDefinition.skipStepsMap) {
                if (newDefinition.skipStepsMap.hasOwnProperty(key)) {
                    $scope.data.skipStepsMap[key] = newDefinition.skipStepsMap[key];
                }
            }
        }

        if (!utils.isNullOrUndefined(newDefinition.projectIntegrationId)) {
            $scope.data.overrideProjectIntegrationId = newDefinition.projectIntegrationId;
        }

        if (!utils.isNullOrUndefined(newDefinition.urlLabel)) {
            $scope.data.previewRequestParameters.urlLabel = newDefinition.urlLabel;
        }

        $scope.data.previewRequestParameters.manualValue = newDefinition.manualValue;

        evaluateDisplaySummary();

        $scope.data.allDefinitionsToPreview = [$scope.data.previewRequestParameters];
        if ($scope.data.additionalDefinitionsToPreview && $scope.data.additionalDefinitionsToPreview.length) {
            $scope.data.allDefinitionsToPreview = $scope.data.allDefinitionsToPreview.concat(
                $scope.data.additionalDefinitionsToPreview,
            );
        }

        if (
            !doNotReloadPreview &&
            !$scope.data.manualPreviewFieldDefinitionTypes[$scope.data.previewRequestParameters.fieldDefinitionType]
        ) {
            $scope.reloadPreview();
        }
    };

    /**
     * Occurs once the field definition name is changed.
     */
    $scope.onFieldDefinitionNameChange = function (fieldDefinitionName) {
        $scope.data.previewRequestParameters.name = fieldDefinitionName;
    };

    /**
     * Occurs once the field definition description is changed.
     */
    $scope.onFieldDefinitionDescriptionChange = function (fieldDefinitionDescription) {
        $scope.data.previewRequestParameters.description = fieldDefinitionDescription;
    };

    /**
     * Occurs once the field definition name is edited.
     */
    $scope.onFieldDefinitionNameEdited = function () {
        checkFieldNameIsAllowed();
        $scope.data.fieldDefinitionNameEdited = true;
        $scope.markAsChanged();
    };

    /**
     * If the user selected a broken integration in the integration selector, we want to close the modal
     * as the selector will move the user to the integrations page
     */
    $scope.onSelectedBrokenIntegration = function () {
        ctrl.onClose();
    };

    /**
     * Occurs on key down in the field name input.
     */
    $scope.onFieldNameInputKeyDown = function (event) {
        if (event && (event.code === 'Enter' || event.keyCode === 13)) {
            // If it's an enter key press, and we have a name defined and ready to use, we proceed.
            // It is important that we do event.preventDefault() outside of the valid name condition, to make sure
            // we prevent the regular enter handling which will result in submit of the field definition form.
            event.preventDefault();
        }
    };

    /**
     * Reloads the preview component.
     */
    $scope.reloadPreview = function (softLoading) {
        $scope.data.softLoading = softLoading;
        $scope.data.reloadPreview = true;

        $timeout(() => {
            $scope.data.softLoading = false;
            $scope.data.reloadPreview = false;
        });
    };

    /**
     * Occurs onces ranges are changed.
     */
    $scope.onRangesChange = function (ranges, isImportant, didChange) {
        if (didChange) {
            $scope.markAsChanged();
        }

        $scope.data.previewRequestParameters.ranges = ranges;
        $scope.data.previewRequestParameters.isImportant = isImportant;

        if (!$scope.data.manualPreviewFieldDefinitionTypes[$scope.data.previewRequestParameters.fieldDefinitionType]) {
            $scope.reloadPreview(true);
        }
    };

    /**
     * Occurs once display configuration is changed.
     */
    $scope.onDisplayConfigurationChange = function (displayConfigurationObject, didDataChange) {
        if (didDataChange) {
            $scope.markAsChanged();
        }

        $scope.data.previewRequestParameters.displayAs = displayConfigurationObject.displayAs;
        $scope.data.previewRequestParameters.numberFieldDecimalPlaces = displayConfigurationObject.numberFieldDecimalPlaces;
        $scope.data.previewRequestParameters.fieldLabel = displayConfigurationObject.fieldLabel;
        $scope.data.previewRequestParameters.evaluatedDisplayType = displayConfigurationObject.evaluatedDisplayType;
        $scope.data.previewRequestParameters.displayFormat = displayConfigurationObject.displayFormat;
        $scope.data.previewRequestParameters.displayFormatPrefix = displayConfigurationObject.displayFormatPrefix;
        $scope.data.previewRequestParameters.displayFormatPostfix = displayConfigurationObject.displayFormatPostfix;
        $scope.data.previewRequestParameters.compareTimeframe = displayConfigurationObject.compareTimeframe;
        $scope.data.previewRequestParameters.isIncrementNegative = displayConfigurationObject.isIncrementNegative;
        $scope.data.previewRequestParameters.outputMultiValueSeparator =
            displayConfigurationObject.outputMultiValueSeparator;

        evaluateDisplaySummary();

        if (!$scope.data.manualPreviewFieldDefinitionTypes[$scope.data.previewRequestParameters.fieldDefinitionType]) {
            $scope.reloadPreview(true);
        }
    };

    /**
     * Creating field definition.
     */
    $scope.createOrUpdateFieldDefinition = function () {
        logAnalytics();
        const evaluatedFieldDefinitionType = $scope.data.previewRequestParameters.actualFieldDefinitionType
            ? $scope.data.previewRequestParameters.actualFieldDefinitionType
            : $scope.data.previewRequestParameters.fieldDefinitionType;

        let areYouSurePromise = $q.resolve();
        if (
            $scope.data.originalFieldDefinitionType &&
            evaluatedFieldDefinitionType !== $scope.data.originalFieldDefinitionType &&
            $scope.data.existingFieldDefinition &&
            $scope.data.existingFieldDefinition.isConfigured &&
            $scope.data.originalFieldDefinitionType === 'MANUAL'
        ) {
            $scope.mboxData = {
                title: 'Changing a Manual column',
                body: 'By changing the data type of this column, some of the existing values that were manually entered, will be deleted or replaced. Are you sure you want to continue?',
                isWarn: true,
                okLabel: 'Yes',
                cancelLabel: 'Cancel',
            };

            areYouSurePromise = modal.openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            }).result;
        }

        return areYouSurePromise.then(() => {
            let createOrUpdatePromise = null;

            $scope.data.creatingOrUpdatingFieldDefinition = true;
            $scope.data.errorCreatingOrUpdatingFieldDefinition = null;

            if ($scope.data.duplicateOrCreateMode) {
                createOrUpdatePromise = customFieldsManager.createFieldDefinition(
                    $scope.data.previewRequestParameters.fieldDefinitionTargetType,
                    $scope.data.previewRequestParameters.name,
                    $scope.data.previewRequestParameters.description,
                    evaluatedFieldDefinitionType,
                    getServerDefinedFieldRanges($scope.data.previewRequestParameters.ranges),
                    $scope.data.previewRequestParameters.definition,
                    $scope.data.overrideProjectIntegrationId
                        ? $scope.data.overrideProjectIntegrationId
                        : $scope.data.projectIntegration
                        ? $scope.data.projectIntegration.id
                        : null,
                    $scope.data.groupId,
                    $scope.data.previewRequestParameters.fieldType || 'String',
                    $scope.data.previewRequestParameters.possibleValues,
                    $scope.data.previewRequestParameters.updateable,
                    $scope.data.previewRequestParameters.isImportant,
                    getDisplayConfiguration(),
                    $scope.data.previewRequestParameters.manualValue,
                    false,
                    false,
                    utils.isNullOrUndefined(ctrl.overrideFieldIsHidden) || ctrl.overrideFieldIsHidden,
                    $scope.data.isSystemUtilized,
                    $scope.data.previewRequestParameters.forceUseDefinitionMetadata,
                    extractSelectedPermissionsValues(),
                    ctrl.idOnlyMode,
                    $scope.data.previewRequestParameters.isMultiValueField,
                    $scope.data.previewRequestParameters.inputMultiValueSeparator,
                    $scope.data.previewRequestParameters.outputMultiValueSeparator,
                    $scope.data.previewRequestParameters.dropdownSource,
                    $scope.data.previewRequestParameters.allowAddDropdownOptions,
                    $scope.data.previewRequestParameters.dropdownOptionsFromFieldDefinitionId,
                    $scope.data.previewRequestParameters.dropdownOptionsFromFieldMetadataSyncFieldDefinition,
                    $scope.data.previewRequestParameters.formulaData,
                    $scope.data.previewRequestParameters.fieldGroupName,
                    $scope.data.openedFromCustomTriggerId,
                    $scope.data.secondaryId,
                    $scope.data.previewRequestParameters.suggestedValue,
                    $scope.data.previewRequestParameters.showManualOptionsNoResults,
                    $scope.data.previewRequestParameters.defaultValueFieldDefinitionId,
                    $scope.data.previewRequestParameters.urlLabel,
                );
            } else {
                createOrUpdatePromise = customFieldsManager.updateFieldDefinition(
                    $scope.data.existingFieldDefinition.id,
                    $scope.data.previewRequestParameters.fieldDefinitionTargetType,
                    $scope.data.previewRequestParameters.name,
                    $scope.data.previewRequestParameters.description,
                    evaluatedFieldDefinitionType,
                    getServerDefinedFieldRanges($scope.data.previewRequestParameters.ranges),
                    $scope.data.previewRequestParameters.definition,
                    $scope.data.overrideProjectIntegrationId
                        ? $scope.data.overrideProjectIntegrationId
                        : $scope.data.projectIntegration
                        ? $scope.data.projectIntegration.id
                        : null,
                    $scope.data.groupId,
                    $scope.data.previewRequestParameters.fieldType,
                    $scope.data.previewRequestParameters.possibleValues,
                    $scope.data.previewRequestParameters.updateable,
                    $scope.data.previewRequestParameters.isImportant,
                    getDisplayConfiguration(),
                    extractSelectedPermissionsValues(),
                    $scope.data.previewRequestParameters.isMultiValueField,
                    $scope.data.previewRequestParameters.inputMultiValueSeparator,
                    $scope.data.previewRequestParameters.outputMultiValueSeparator,
                    $scope.data.previewRequestParameters.dropdownSource,
                    $scope.data.previewRequestParameters.allowAddDropdownOptions,
                    $scope.data.previewRequestParameters.dropdownOptionsFromFieldDefinitionId,
                    $scope.data.previewRequestParameters.dropdownOptionsFromFieldMetadataSyncFieldDefinition,
                    $scope.data.previewRequestParameters.formulaData,
                    $scope.data.previewRequestParameters.fieldGroupName,
                    $scope.data.previewRequestParameters.suggestedValue,
                    $scope.data.previewRequestParameters.showManualOptionsNoResults,
                    null,
                    $scope.data.previewRequestParameters.defaultValueFieldDefinitionId,
                    $scope.data.previewRequestParameters.urlLabel,
                );
            }

            return createOrUpdatePromise
                .then((field) => {
                    // Mark the addField step in the on boarding as completed.
                    $rootScope.onBoardingManager.completeStep('addField');

                    // Call get getFieldDefinitions to update the cache.
                    $scope.data.creatingOrUpdatingFieldDefinition = false;
                    $scope.$emit('alert', {
                        msg: `Field "${$scope.data.previewRequestParameters.name}" was ${
                            $scope.data.duplicateOrCreateMode ? ' created' : 'updated'
                        } successfully! ${
                            $scope.data.previewRequestParameters.fieldDefinitionType !== 'MANUAL'
                                ? 'Loading data, this may take a few seconds...'
                                : ''
                        }`,
                        type: 'success',
                    });

                    // Second parameter to callback is fromUpdate
                    if (ctrl.afterCreateOrUpdateCallback) {
                        ctrl.afterCreateOrUpdateCallback(field, true);
                    }

                    // Fire newActivityUpdate event - update ui to add/update the field
                    $rootScope.$broadcast('newActivityUpdate');

                    // Fire newFieldDefinitionCreated event on create/update.
                    $rootScope.$broadcast('newFieldDefinitionCreated', field);

                    ctrl.onClose();
                })
                .catch((error) => {
                    handleServerErrorMessageInCreateOrUpdate(error);
                })
                .finally(() => {
                    $scope.data.creatingOrUpdatingFieldDefinition = false;
                });
        });
    };

    /**
     * Cancels the modal.
     */
    $scope.cancel = function () {
        ctrl.onClose();
    };

    /**
     * Toggles the current step.
     */
    $scope.toggleStep = function (step, noScroll) {
        $scope.data.stepsState[step] = !$scope.data.stepsState[step];

        // Turning other steps off.
        for (const key in $scope.data.stepsState) {
            if ($scope.data.stepsState.hasOwnProperty(key) && key !== step) {
                $scope.data.stepsState[key] = false;
            }
        }

        if (!noScroll) {
            $timeout(function () {
                $anchorScroll(`section-${step}`);
            });
        }
    };

    /**
     * Sets given step to be visited.
     */
    $scope.setStepVisited = function (step) {
        $scope.data.visitedStepsState[step] = true;
    };

    /**
     * Moves from from step to to step.
     */
    $scope.moveFromToStep = function (from, to) {
        $scope.toggleStep(to);
        $scope.data.visitedStepsState[from] = true;
    };

    /**
     * Proceeds from the field definition configuration step.
     */
    $scope.proceedFromFieldDefinitionConfiguration = function () {
        let nextStep = 'displayConfiguration';

        // If we're required to skip the display configuration step, we proceed to ranges configuration.
        if ($scope.data.skipStepsMap['displayConfiguration']) {
            $scope.setStepVisited('rangesConfiguration');
            nextStep = 'rangesConfiguration';
        }

        $scope.moveFromToStep('fieldDefinitionConfiguration', nextStep);

        // If it's a manual previewed field definition type, we reload the preview once clicking next.
        if ($scope.data.manualPreviewFieldDefinitionTypes[$scope.data.previewRequestParameters.fieldDefinitionType]) {
            $scope.reloadPreview(false);
        }

        if (ctrl.quickCreateForExternal) {
            $scope.data.visitedStepsState['rangesConfiguration'] = true;
        }
    };

    /**
     * Proceeds from the data type selection step.
     */
    $scope.proceedFromDataTypeSelectionStep = function () {
        if ($scope.data.selectedDataTypeObject.requiresIntegrationSelectionStep) {
            $scope.data.skipStepsMap.integrationConfiguration = false;
            $scope.moveFromToStep('dataTypeConfiguration', 'integrationConfiguration');
        } else {
            $scope.moveFromToStep('dataTypeConfiguration', 'fieldDefinitionConfiguration');
        }
    };

    /**
     * Proceeds from the range configuration step.
     */
    $scope.proceedFromRangeConfigurationStep = function () {
        $scope.moveFromToStep('rangesConfiguration', 'additionalInformationConfiguration');
    };

    $scope.onRangesConfigurationStepHeaderClick = function () {
        if (!$scope.data.createMode || $scope.data.visitedStepsState['displayConfiguration']) {
            $scope.toggleStep('rangesConfiguration');
        } else if ($scope.data.previewRequestParameters.validDefinition) {
            $scope.proceedFromFieldDefinitionConfiguration();
        }
    };

    $scope.onAdditionalInformationConfigurationStepHeaderClick = function () {
        if (!$scope.data.createMode || $scope.data.visitedStepsState['rangesConfiguration']) {
            $scope.toggleStep('additionalInformationConfiguration');
        }
    };

    /**
     * @param environment {'build' | 'production'}
     */
    function changeEnvironment(environment) {
        // If environment is disabled, don't allow changing.
        if ($scope.data.disabledEnvironments[environment]) {
            $scope.fieldNotPublished = true;
            return;
        }

        const newWorkflowVersion =
            environment === 'build' ? $scope.data.draftWorkflowVersion : $scope.data.publishedWorkflowVersion;
        if ($scope.data.workflowVersionId === newWorkflowVersion.id) {
            return;
        }

        $scope.data.workflowVersion = newWorkflowVersion;
        $scope.data.workflowVersionId = newWorkflowVersion.id;

        $scope.data.existingFieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            $scope.data.existingFieldDefinition.id,
        );

        if ($scope.data.existingFieldDefinition.projectIntegration) {
            $scope.selectProvider(
                $scope.data.existingFieldDefinition.projectIntegration,
                $scope.data.existingFieldDefinition.type,
            );
        }

        initEditMode();
        // Must be after init edit mode because it sets `$scope.data.selectedDataTypeObject`
        $scope.selectDataSource(
            $scope.data.selectedDataTypeObject,
            false,
            $scope.data.existingFieldDefinition.projectIntegration,
            $scope.data.existingFieldDefinition.type,
        );

        // Must be last
        $scope.data.didChange = false;
        ctrl.onHasPendingChangesChanged(false);
        ctrl.onEnvironmentChanged(environment);
    }

    function openChangeEnvironmentWithPendingChangesModal() {
        $scope.mboxData = {
            title: 'Moving from Build Environment to Production',
            html: `You have some unsaved changes.<br />Are you sure you want to discard and move to Production Environment? `,
            isWarn: false,
            cancelLabel: 'Stay in Build',
            closeIcon: false,
            okLabel: 'Discard and move to Production',
            isReversedButtons: true,
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                $scope.data.didChange = false;
                ctrl.onHasPendingChangesChanged(false);
                changeEnvironment('production');
            });
    }

    $scope.changeEnvironmentToBuildFromDisabledElement = function () {
        if ($scope.data.workflowVersion.workflowVersionType !== 'DRAFT' && !$scope.data.disabledEnvironments.build) {
            changeEnvironment('build');
        }
    };

    $scope.onEnvironmentToggleClick = function (environment) {
        $timeout(() => {
            if (environment === 'production' && $scope.data.didChange) {
                openChangeEnvironmentWithPendingChangesModal();
            } else {
                changeEnvironment(environment);
            }
        });
    };

    /**
     * Initializes the steps states of the controller.
     */
    function initializeSteps(doNotInitializeNameConfiguration) {
        if (!doNotInitializeNameConfiguration) {
            $scope.data.stepsState['nameConfiguration'] = false;
        }
        $scope.data.stepsState['dataTypeConfiguration'] = false;
        $scope.data.stepsState['integrationConfiguration'] = false;
        $scope.data.stepsState['fieldDefinitionTypeConfiguration'] = false;
        $scope.data.stepsState['fieldDefinitionConfiguration'] = false;
        $scope.data.stepsState['displayConfiguration'] = false;
        $scope.data.stepsState['rangesConfiguration'] = false;

        if (!doNotInitializeNameConfiguration) {
            $scope.data.visitedStepsState['nameConfiguration'] = false;
        }
        $scope.data.visitedStepsState['dataTypeConfiguration'] = false;
        $scope.data.visitedStepsState['integrationConfiguration'] = false;
        $scope.data.visitedStepsState['fieldDefinitionTypeConfiguration'] = false;
        $scope.data.visitedStepsState['fieldDefinitionConfiguration'] = false;
        $scope.data.visitedStepsState['displayConfiguration'] = false;
        $scope.data.visitedStepsState['rangesConfiguration'] = false;

        if (!doNotInitializeNameConfiguration) {
            $scope.data.skipStepsMap['nameConfiguration'] = false;
        }
        $scope.data.skipStepsMap['dataTypeConfiguration'] = false;
        $scope.data.skipStepsMap['integrationConfiguration'] = true;
        $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = true;
        $scope.data.skipStepsMap['fieldDefinitionConfiguration'] = false;
        $scope.data.skipStepsMap['displayConfiguration'] = false;
        $scope.data.skipStepsMap['rangesConfiguration'] = false;
    }

    /**
     * Checks the name defined for the column is not a forbidden name to be used as a column name.
     */
    function checkFieldNameIsAllowed() {
        const isForbiddenName =
            $scope.data.previewRequestParameters.name &&
            !!utils.findFirst(
                $scope.data.forbiddenNames,
                (forbiddenName) => forbiddenName === $scope.data.previewRequestParameters.name,
            );
        $scope.data.errorDefiningFieldDefinitionName = isForbiddenName
            ? 'Field cannot be defined with this name, since it is used for system purposes.'
            : null;
    }

    /**
     * Returns the selected permissions out of update field permissions.
     */
    function extractSelectedPermissionsValues() {
        if ($scope.data.updateFieldPermissionsType === 'ANY_COLLABORATOR') {
            return ['LIST_OWNER', 'COLLABORATORS', 'TRACK_OWNERS', 'TRACK_CREATOR'];
        } else {
            return [$scope.data.updateFieldPermissionsType];
        }
    }

    /**
     * Evaluates the summary for the display configuration.
     */
    function evaluateDisplaySummary() {
        // Figuring out the display summary title.
        let displaySummary = null;
        if (
            $scope.data.previewRequestParameters.evaluatedDisplayType ||
            $scope.data.previewRequestParameters.fieldType
        ) {
            displaySummary =
                $scope.data.previewRequestParameters.evaluatedDisplayType ||
                $scope.data.previewRequestParameters.fieldType;

            if ($scope.data.previewRequestParameters.displayAs) {
                displaySummary += `, Format: ${
                    $scope.data.displayFormatApiNameToDefinitionMap[
                        $scope.data.previewRequestParameters.displayFormat +
                            $scope.data.previewRequestParameters.displayFormatPrefix +
                            $scope.data.previewRequestParameters.displayFormatPostfix
                    ]
                }`;
            }
            if ($scope.data.previewRequestParameters.isIncrementNegative) {
                displaySummary += ', Increment: Negative';
            } else {
                displaySummary += ', Increment: Positive';
            }
            if ($scope.data.previewRequestParameters.fieldLabel) {
                displaySummary += `, labeled as: ${$scope.data.previewRequestParameters.fieldLabel}`;
            }
        }

        $scope.data.displaySummary = displaySummary;
    }

    /**
     * Gets the given field's ranges for submission.
     */
    function getServerDefinedFieldRanges(definedRanges) {
        // Can't filter undefined, so check before doing anything.
        if (!definedRanges) {
            return [];
        }

        // If we have nothing relevant, we return empty array
        if (!definedRanges || !definedRanges.length) {
            return [];
        }

        const serverDefinedRanges = [];

        // Prepare the field ranges for the server (delete our extra fields, clear empty rules).
        for (const range of definedRanges) {
            serverDefinedRanges.push({
                condition: range.condition,
                color: range.color,

                // Trimming the data
                value: range.value
                    ? range.value instanceof Date
                        ? range.value.getTime().toString()
                        : range.value.toString().trim()
                    : null,
                secondValue: range.secondValue
                    ? range.secondValue instanceof Date
                        ? range.secondValue.getTime().toString()
                        : range.secondValue.toString().trim()
                    : null,

                // If the user hasn't marked it, we need to verify the value is false. If it's true nothing will happen.
                isWarn: !!range.isWarn,
                isAlert: !!range.isAlert,
                isGather: !!range.isGather,
            });
        }

        return serverDefinedRanges;
    }

    /**
     * Gets the given field's display configuration for submission.
     */
    function getDisplayConfiguration() {
        const configurationData = {};
        configurationData.displayAs = $scope.data.previewRequestParameters.displayAs;
        configurationData.numberFieldDecimalPlaces = $scope.data.previewRequestParameters.numberFieldDecimalPlaces;
        configurationData.fieldLabel = $scope.data.previewRequestParameters.fieldLabel;
        configurationData.compareTimeframe = $scope.data.previewRequestParameters.compareTimeframe || 1;
        configurationData.isIncrementNegative = $scope.data.previewRequestParameters.isIncrementNegative || false;
        configurationData.displayFormat =
            $scope.data.previewRequestParameters.displayFormat ||
            ($scope.data.previewRequestParameters.displayAs === 'Number'
                ? $scope.data.displayFormats.number.apiName
                : null);
        configurationData.displayFormatPrefix = $scope.data.previewRequestParameters.displayFormatPrefix;
        configurationData.displayFormatPostfix = $scope.data.previewRequestParameters.displayFormatPostfix;
        configurationData.outputMultiValueSeparator = $scope.data.previewRequestParameters.outputMultiValueSeparator;
        configurationData.dropdownSource = $scope.data.previewRequestParameters.dropdownSource;
        configurationData.allowAddDropdownOptions = $scope.data.previewRequestParameters.allowAddDropdownOptions;
        configurationData.dropdownOptionsFromFieldDefinitionId =
            $scope.data.previewRequestParameters.dropdownOptionsFromFieldDefinitionId;
        configurationData.dropdownOptionsFromFieldMetadataSyncFieldDefinition =
            $scope.data.dropdownOptionsFromFieldMetadataSyncFieldDefinition;
        configurationData.defaultValueFieldDefinitionId =
            $scope.data.defaultValueFieldDefinitionId;
        configurationData.urlLabel = $scope.data.previewRequestParameters.urlLabel;

        return configurationData;
    }

    /**
     * Logs analytics.
     */
    function logAnalytics() {
        if ($scope.data.createMode) {
            analyticsWrapper.track('Create field definition', { category: 'Edit Field Modal' });
            window.Intercom('trackEvent', `Create Field ${$scope.targetCaption}`);
        } else if ($scope.data.duplicateMode) {
            analyticsWrapper.track('Duplicate field definition', { category: 'Duplicate Field Modal' });
        } else {
            analyticsWrapper.track('Edit field definition', { category: 'Edit Field Modal' });
        }
    }

    /**
     * Saves a custom message for different types of errors.
     */
    function handleServerErrorMessageInCreateOrUpdate(error) {
        let errorCreatingOrUpdatingFieldDefinition = ''
        if (error && error.data && error.data.error && error.data.error.message) {
            if (
                error.data.error.message.includes('Could not run compiled query') ||
                error.data.error.message.includes('Failed to execute elastic search search')
            ) {
                errorCreatingOrUpdatingFieldDefinition =
                    'I’m sorry! Could you re-check the parameters entered? I don’t know how to create the column based on them.';
            } else {
                errorCreatingOrUpdatingFieldDefinition = error.data.error.message;
            }
        } else {
            errorCreatingOrUpdatingFieldDefinition = error;
        }
        $timeout(() => {
            $scope.data.errorCreatingOrUpdatingFieldDefinition = errorCreatingOrUpdatingFieldDefinition;
        });
    }

    /**
     * Gets the integrations that support data fields.
     */
    function getProjectDataFieldIntegrations() {
        if (!projectManager.project.integrations || !projectManager.project.integrations.length) {
            return [];
        }

        return projectManager.project.integrations.filter(
            (projectIntegration) =>
                !!integrations.getFieldSettingsByIntegration(projectIntegration.integration.integrationType.toLowerCase()),
        );
    }

    /**
     * Gets field instance label.
     */
    function getFieldDefinitionInstanceLabel(instance) {
        return instance.buttonText
            ? instance.buttonText.replace('Webhook', $scope.data.projectIntegration.displayName)
            : instance.buttonText;
    }

    $scope.onSelectInnerTrackAggregation = () => {
        $scope.markAsChanged();
    };

    /**
     * Occurs once the definition as changed
     */
    $scope.markAsChanged = function (fieldName) {
        if (!fieldName || fieldName === $scope.data.existingFieldDefinition?.name) {
            $scope.data.didChange = true;
            ctrl.onHasPendingChangesChanged(true);
        }
    };

    /**
     * Initializes modal edit mode.
     */
    function initEditMode() {
        // Initializing the data source type relevant to the field definition type.
        for (const dataTypeKey in $scope.data.fieldDefinitionDataTypes) {
            if ($scope.data.fieldDefinitionDataTypes.hasOwnProperty(dataTypeKey)) {
                const dataTypeObject = $scope.data.fieldDefinitionDataTypes[dataTypeKey];

                if (
                    dataTypeObject.relevantFieldDefinitionTypes[$scope.data.existingFieldDefinition.type] &&
                    dataTypeObject.targetTypes[$scope.data.existingFieldDefinition.targetType]
                ) {
                    $scope.data.selectedDataTypeObject = dataTypeObject;
                    if ($scope.data.selectedDataTypeObject.requiresIntegrationSelectionStep) {
                        $scope.data.skipStepsMap['integrationConfiguration'] = false;
                        $scope.data.skipStepsMap['fieldDefinitionTypeConfiguration'] = false;
                    }
                    break;
                }
            }
        }

        // Initializing the field definition instance label.
        if ($scope.data.projectIntegration) {
            const selectedInstance = utils.findFirst(
                $scope.data.fieldSettings[$scope.data.projectIntegration.integration.integrationType.toLowerCase()],
                (instance) => instance.type === $scope.data.previewRequestParameters.fieldDefinitionType,
            );
            if (selectedInstance) {
                $scope.data.previewRequestParameters.fieldDefinitionTypeLabel =
                    getFieldDefinitionInstanceLabel(selectedInstance);
            }
        }

        if (
            $scope.data.existingFieldDefinition.updateFieldPermissions &&
            $scope.data.existingFieldDefinition.updateFieldPermissions.length
        ) {
            if ($scope.data.existingFieldDefinition.updateFieldPermissions.length === 1) {
                $scope.data.updateFieldPermissionsType = $scope.data.existingFieldDefinition.updateFieldPermissions[0];
            } else {
                $scope.data.updateFieldPermissionsType = 'ANY_COLLABORATOR';
            }
        }

        // Name
        $scope.data.previewRequestParameters.name = $scope.data.existingFieldDefinition.name;

        // Description
        $scope.data.previewRequestParameters.description = $scope.data.existingFieldDefinition.description;

        $scope.data.previewRequestParameters.fieldGroupName = $scope.data.existingFieldDefinition.fieldGroupName;

        // Definition
        $scope.data.previewRequestParameters.definition = $scope.data.existingFieldDefinition.definition;
        $scope.data.previewRequestParameters.validDefinition = true;

        // General properties
        $scope.data.previewRequestParameters.updateable = $scope.data.existingFieldDefinition.updateable;
        $scope.data.previewRequestParameters.fieldType = $scope.data.existingFieldDefinition.fieldType;
        $scope.data.previewRequestParameters.possibleValues = $scope.data.existingFieldDefinition.possibleValues;
        $scope.data.previewRequestParameters.showManualOptionsNoResults = $scope.data.existingFieldDefinition.showManualOptionsNoResults;
        $scope.data.previewRequestParameters.manualValue = $scope.data.existingFieldDefinition.manualValue;
        $scope.data.previewRequestParameters.isMultiValueField = $scope.data.existingFieldDefinition.isMultiValueField;
        $scope.data.previewRequestParameters.inputMultiValueSeparator = utils.isNullOrEmpty(
            $scope.data.existingFieldDefinition.inputMultiValueSeparator,
        )
            ? ','
            : $scope.data.existingFieldDefinition.inputMultiValueSeparator;
        $scope.data.previewRequestParameters.outputMultiValueSeparator = utils.isNullOrEmpty(
            $scope.data.existingFieldDefinition.outputMultiValueSeparator,
        )
            ? ','
            : $scope.data.existingFieldDefinition.outputMultiValueSeparator;

        // Ranges
        // Copy so we won't change the original values if the user decides to cancel.
        $scope.data.previewRequestParameters.ranges = angular.copy($scope.data.existingFieldDefinition.ranges);
        $scope.data.previewRequestParameters.isImportant = $scope.data.existingFieldDefinition.isImportant;

        // Display
        $scope.data.previewRequestParameters.displayAs = $scope.data.existingFieldDefinition.displayAs;
        $scope.data.previewRequestParameters.numberFieldDecimalPlaces = $scope.data.existingFieldDefinition.numberFieldDecimalPlaces;
        $scope.data.previewRequestParameters.fieldLabel = $scope.data.existingFieldDefinition.fieldLabel;
        $scope.data.previewRequestParameters.evaluatedDisplayType =
            $scope.data.existingFieldDefinition.evaluatedDisplayType;
        $scope.data.previewRequestParameters.displayFormat = $scope.data.existingFieldDefinition.displayFormat;
        $scope.data.previewRequestParameters.displayFormatPrefix =
            $scope.data.existingFieldDefinition.displayFormatPrefix;
        $scope.data.previewRequestParameters.displayFormatPostfix =
            $scope.data.existingFieldDefinition.displayFormatPostfix;
        $scope.data.previewRequestParameters.compareTimeframe = $scope.data.existingFieldDefinition.compareTimeframe;
        $scope.data.previewRequestParameters.isIncrementNegative =
            $scope.data.existingFieldDefinition.isIncrementNegative;
        $scope.data.previewRequestParameters.dropdownSource = $scope.data.existingFieldDefinition.dropdownSource;
        $scope.data.previewRequestParameters.allowAddDropdownOptions =
            $scope.data.existingFieldDefinition.allowAddDropdownOptions;
        $scope.data.previewRequestParameters.dropdownOptionsFromFieldDefinitionId =
            $scope.data.existingFieldDefinition.dropdownOptionsFromFieldDefinitionId;
        $scope.data.previewRequestParameters.dropdownOptionsFromFieldMetadataSyncFieldDefinition =
            $scope.data.existingFieldDefinition.dropdownOptionsFromFieldMetadataSyncFieldDefinition;
        $scope.data.previewRequestParameters.defaultValueFieldDefinitionId =
            $scope.data.existingFieldDefinition.defaultValueFieldDefinitionId;

        $scope.data.previewRequestParameters.suggestedValue = $scope.data.existingFieldDefinition.suggestedValue;
        $scope.data.previewRequestParameters.showManualOptionsNoResults = $scope.data.existingFieldDefinition.showManualOptionsNoResults;
        $scope.data.previewRequestParameters.urlLabel = $scope.data.existingFieldDefinition.urlLabel;
        // Initializing temporary summary title
        switch ($scope.data.previewRequestParameters.fieldDefinitionType) {
            case 'AGGREGATE_QUERY':
                $scope.data.fieldConfigurationSummaryTitle = 'Aggregation';
                $scope.data.fieldConfigurationSummarySubTitle = null;
                break;
            case 'MANUAL':
                $scope.data.previewRequestParameters.definitionId = $scope.data.existingFieldDefinition.id;
                $scope.data.previewRequestParameters.previewDefinitionType = 'EXISTING_FIELD_DEFINITION';
                break;
        }
    }

    $scope.shouldDisableEditor = function () {
        return $scope.data.workflowVersion.workflowVersionType !== 'DRAFT';
    };

    $scope.subHeaderContentDecider = function () {
        let headerContent = '';
        if (ctrl.targetType === 'COLUMN') {
            if (ctrl.idOnlyMode) {
                headerContent = 'Matching Field';
            } else {
                headerContent = 'Field';
            }
        } else {
            headerContent = 'Global Field';
        }
        if (ctrl.duplicateMode) {
            headerContent.concat(` (${projectManager.groupsMap[ctrl.groupId].name})`);
        }
        return headerContent;
    };

    ctrl.$onChanges = function (changesObj) {
        $scope.showField = false;
        $scope.fieldNotPublished = false;

        if (changesObj.groupId) {
            $scope.data.groupId = ctrl.groupId;
        }
        if (changesObj.targetType) {
            $scope.data.targetType = ctrl.targetType;
        }
        if (changesObj.fieldDefinitionType) {
            $scope.data.fieldDefinitionType = ctrl.fieldDefinitionType;
        }
        if (changesObj.projectIntegration) {
            $scope.data.projectIntegration = ctrl.projectIntegration;
        }
        if (changesObj.createMode) {
            $scope.data.createMode = ctrl.createMode;
        }
        if (changesObj.duplicateMode) {
            $scope.data.duplicateMode = ctrl.duplicateMode;
        }
        if (changesObj.existingFieldDefinition) {
            $scope.data.existingFieldDefinition = ctrl.existingFieldDefinition;
        }
        if (changesObj.afterCreateOrUpdateCallback) {
            $scope.data.afterCreateOrUpdateCallback = ctrl.afterCreateOrUpdateCallback;
        }
        if (changesObj.deleteCallback) {
            $scope.data.deleteCallback = ctrl.deleteCallback;
        }
        if (changesObj.openInStep) {
            $scope.data.openInStep = ctrl.openInStep;
        }
        if (changesObj.quickCreateForExternal) {
            $scope.data.quickCreateForExternal = ctrl.quickCreateForExternal;
        }
        if (changesObj.startWithDataSource) {
            $scope.data.startWithDataSource = ctrl.startWithDataSource;
        }
        if (changesObj.selectedEntity) {
            $scope.data.selectedEntity = ctrl.selectedEntity;
        }
        if (changesObj.isWorkerMode) {
            $scope.data.isWorkerMode = ctrl.isWorkerMode;
        }
        if (changesObj.overrideFormulaOperator) {
            $scope.data.overrideFormulaOperator = ctrl.overrideFormulaOperator;
        }
        if (changesObj.overrideFieldIsHidden) {
            $scope.data.overrideFieldIsHidden = ctrl.overrideFieldIsHidden;
        }
        if (changesObj.manualValue) {
            $scope.data.manualValue = ctrl.manualValue;
        }
        if (changesObj.idOnlyMode) {
            $scope.data.idOnlyMode = ctrl.idOnlyMode;
        }
        if (changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
        }
        if (changesObj.allowOnlyFieldDefinitionDataTypes) {
            $scope.data.allowOnlyFieldDefinitionDataTypes = ctrl.allowOnlyFieldDefinitionDataTypes;
        }
        if (changesObj.onClose) {
            $scope.data.onClose = ctrl.onClose;
        }
        if (changesObj.environment) {
            $scope.data.environment = ctrl.environment;
            $scope.onEnvironmentToggleClick($scope.data.environment);
        }
        if (changesObj.isSystemUtilized) {
            $scope.data.isSystemUtilized = ctrl.isSystemUtilized;
        }

        $scope.init();

        $timeout(() => {
            $scope.showField = true;
        });
    };

    $scope.goToProjectIntegrationPageWithReturnTo = function () {
        $state.go('product.projectIntegrationPage', {
            fromName: projectManager.groupsMap[$scope.data.groupId].name,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            enterpriseComponentId: $scope.data.projectIntegration.id,
            page: ProjectIntegrationPageMenuItemType.CONNECTIONS,
        });
    };

    $scope.init();
}

export default angular
    .module('tonkean.app')
    .controller('FieldDefinitionConfigurationCtrl', lateConstructController(FieldDefinitionConfigurationCtrl));
