import type { IRootScopeService } from 'angular';

import type DataRetentionSettings from './DataRetentionSettings';
import type IntegrationDefinitionBase from './IntegrationDefinitionBase';

import type { IntegrationEntity } from '@tonkean/tonkean-entities';
import type { DataSourcesSectionType } from '@tonkean/tonkean-entities';

/**
 * Represents the old integration configuration model.
 */
class OldIntegrationConfiguration {
    /**
     * Import settings is used by the import once section in the live report.
     */
    public importSettings: string[];

    /**
     * Sync settings is used by the module (bot) sync settings.
     */
    public syncSettings: any;

    /**
     * Field Settings is used by the create fields section.
     * Whether aggregation / external fields is supported.
     */
    public fieldSettings: any;

    /**
     * Global Metric is used by the create global fields section.
     * This object is identical to the Aggregate fields object under the fieldSettings above.
     */
    public globalMetricSettings: any;

    /**
     * Field Options is used by the create fields from data source section.
     * This array holds the Entities names the user can create fields from.
     */
    public fieldOptions: string[];

    /**
     * Filter options is used by the sync settings criteria section.
     */
    public filterOptions: string[];

    /**
     * An optional flag which indicates the integration support custom owner fields.
     * When the user configure the sync settings (Which integration and entity to import)
     * he can also choose which field from the external activity will used as the initiative owner.
     * If this flag is true, this section will appear just below the sync settings in the bot section.
     * This feature is rarely used and this is why it's an optional.
     */
    public integrationSupportsCustomOwnerFields: boolean;

    /**
     * An optional field which represents the field name in the external activity
     * which we can use to find external activity by time range.
     * This field indicates when the external activity created and used by the custom filters in
     * the integration sync settings;
     */
    public createDateSettings?: string;

    /**
     * Defines the settings for the data retention of the integration.
     */
    public dataRetentionSettings?: DataRetentionSettings;

    /**
     * Indicates whether the integration supports dynamic entities.
     */
    public allEntitiesSupportedIntegrations?: boolean;

    /**
     * The data source section of the integration.
     */
    public dataSourceSectionType?: DataSourcesSectionType;

    /**
     * The display name.
     */
    public displayName: string;

    /**
     * Whether the sync integration add a default X DateTime filter
     */
    public shouldRemoveTheDefaultSyncDateTimeFilter: boolean;

    /**
     * Constructor.
     */
    constructor(integrationDefinitionBase: IntegrationDefinitionBase, $rootScope: IRootScopeService) {
        this.displayName = integrationDefinitionBase.displayName;
        this.allEntitiesSupportedIntegrations = !!$rootScope?.features?.currentProject?.[
            'tonkean_feature_enable_project_integration_entities'
        ]
            ? true
            : integrationDefinitionBase.hasDynamicEntities;

        // Only if the integration does not support dynamic entities, we set the entities hard coded.
        if (!integrationDefinitionBase.hasDynamicEntities) {
            const entitiesNames = integrationDefinitionBase.entities.map((entity) => entity.entityName);
            this.importSettings = entitiesNames;
            this.syncSettings = this.buildSyncSettings(integrationDefinitionBase.entities);
            this.fieldOptions = entitiesNames;
            this.filterOptions = entitiesNames;
        }

        this.fieldSettings = this.buildFieldSettings(integrationDefinitionBase);
        this.globalMetricSettings = this.buildGlobalMetricSettings();

        this.integrationSupportsCustomOwnerFields = true;
        this.createDateSettings = integrationDefinitionBase.externalActivityCreatedDateFieldName;
        this.dataRetentionSettings = integrationDefinitionBase.dataRetentionSettings;
        this.dataSourceSectionType = integrationDefinitionBase.dataSourceSectionType;
        this.shouldRemoveTheDefaultSyncDateTimeFilter =
            integrationDefinitionBase.shouldRemoveTheDefaultSyncDateTimeFilter || false;
    }

    buildFieldSettings(integrationDefinitionBase: IntegrationDefinitionBase) {
        const externalField = {
            type: 'EXTERNAL',
            buttonText: `${integrationDefinitionBase.displayName} field`,
        };

        const aggregateField = {
            type: 'AGGREGATE_QUERY',
            buttonText: 'Aggregate items',
        };

        return [externalField, aggregateField];
    }

    buildGlobalMetricSettings() {
        return [
            {
                type: 'AGGREGATE_QUERY',
                buttonText: 'Aggregate items',
            },
        ];
    }

    buildSyncSettings(entities: IntegrationEntity[]) {
        const syncSettings = {
            supportedEntities: entities.map((entity) => entity.pluralLabel),
        };

        entities.forEach(
            (entity) =>
                (syncSettings[entity.pluralLabel] = {
                    entityType: entity.entityName,
                    pluralLabel: entity.pluralLabel,
                }),
        );

        return syncSettings;
    }
}

export default OldIntegrationConfiguration;
