import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as FormulaBuilderLeftIcon } from '../../images/formula-builder-left.svg';
import { ReactComponent as FormulaBuilderRightIcon } from '../../images/formula-builder-right.svg';

import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const buttonsTheme = Theme.current.palette.formula.buttons;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    left?: boolean;
    right?: boolean;
    depth: number;
    hasError?: boolean;
    faded?: boolean;
    compact?: boolean;
    pointer?: boolean;
    nodeType: FormulaTreeNodeType;
}

export const InnerRoundedSide = styled.div`
    background: #fff;
`;

const BoxWrapper = styled.div<Pick<Props, 'nodeType' | 'faded' | 'hasError' | 'pointer'>>`
    display: flex;

    ${({ pointer }) =>
        pointer &&
        `
        cursor: pointer;
    `}

    ${InnerRoundedSide} svg {
        display: block;

        .formula-builder-right_svg__background,
        .formula-builder-left_svg__background {
            transition: 0.2s all;
            fill: ${({ nodeType, faded }) => buttonsTheme.types[nodeType][faded ? 'fadedBackground' : 'background']};
        }
        .formula-builder-right_svg__error-border,
        .formula-builder-left_svg__error-border {
            transition: 0.2s all;
            fill: ${({ nodeType, hasError, faded }) =>
                hasError ? buttonsTheme.errorBorder : buttonsTheme.types[nodeType][faded ? 'fadedBorder' : 'border']};
        }
    }
`;

const RoundedBox = styled.div<Props>`
    background: ${({ nodeType, faded }) => buttonsTheme.types[nodeType][faded ? 'fadedBackground' : 'background']};
    color: ${({ nodeType, faded }) => buttonsTheme.types[nodeType][faded ? 'fadedText' : 'text']};
    border: 2px
        ${({ nodeType, hasError }) => (nodeType === FormulaTreeNodeType.EMPTY && !hasError ? 'dashed' : 'solid')}
        ${({ nodeType, hasError, faded }) =>
            hasError ? buttonsTheme.errorBorder : buttonsTheme.types[nodeType][faded ? 'fadedBorder' : 'border']};

    padding: 0 ${({ compact }) => (compact ? 12 : 16)}px;
    border-radius: 14px;
    text-align: left;
    overflow-wrap: anywhere;
    line-height: 24px;

    position: relative;
    z-index: ${({ depth }) => depth};
    transition: 0.2s all;

    ${({ left }) =>
        left &&
        css<Props>`
            padding-left: ${({ compact }) => (compact ? 4 : 8)}px;
            margin-left: -2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none !important;
        `}
    ${({ right }) =>
        right &&
        css<Props>`
            padding-right: ${({ compact }) => (compact ? 4 : 8)}px;
            margin-right: -2px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none !important;
        `}
`;

const FormulaInnerRoundedBox: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { left, right, depth, hasError, faded, nodeType, children, ...props },
    ref,
) => {
    return (
        <BoxWrapper hasError={!!hasError} faded={!!faded} nodeType={nodeType} {...props} ref={ref}>
            {left && (
                <InnerRoundedSide>
                    <FormulaBuilderLeftIcon />
                </InnerRoundedSide>
            )}

            <RoundedBox
                right={!!right}
                left={!!left}
                depth={depth}
                hasError={!!hasError}
                faded={!!faded}
                nodeType={nodeType}
            >
                {children}
            </RoundedBox>

            {right && (
                <InnerRoundedSide>
                    <FormulaBuilderRightIcon />
                </InnerRoundedSide>
            )}
        </BoxWrapper>
    );
};

export default React.forwardRef(FormulaInnerRoundedBox);
