function EducationModalCtrl($scope, $sce, onBoardingManager, educationItem, group, workflowVersionId) {
    $scope.educationItem = educationItem;
    $scope.group = group;
    $scope.workflowVersionId = workflowVersionId;

    if ($scope.educationItem && $scope.educationItem.videoId) {
        $scope.videoUrl = $sce.trustAsResourceUrl(
            `https://www.youtube.com/embed/${$scope.educationItem.videoId}?rel=0&amp;showinfo=0`,
        );
    }

    /**
     * Do the action for the item of the education.
     */
    $scope.doItemAction = function (cta) {
        cta.action(group.id, $scope.workflowVersionId);
        $scope.$dismiss();
    };

    // mark the view education onboarding step
    onBoardingManager.completeStep($scope.educationItem.stepName);
}
export default angular.module('tonkean.app').controller('EducationModalCtrl', EducationModalCtrl);
