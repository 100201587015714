import React from 'react';
import styled from 'styled-components';

import NotAvailableDataCellPlaceholder from './NotAvailableDataCellPlaceholder';
import ViewDataBaseCellRenderer from './ViewDataBaseCellRenderer';

import { Placeholder } from '@tonkean/infrastructure';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 47px;
`;

interface Props {
    value: string | undefined;
    loading: boolean;
}

const ViewDataTitleCellRenderer: React.FC<Props> = ({ value, loading }) => {
    if (loading) {
        return (
            <LoadingWrapper>
                <Placeholder $height="20px" $width="100px" />
            </LoadingWrapper>
        );
    }

    if (!value) {
        return <NotAvailableDataCellPlaceholder />;
    }

    return <ViewDataBaseCellRenderer value={<span data-automation="sync-preview-id-item">{value}</span>} />;
};

export default ViewDataTitleCellRenderer;
