import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationEntityHeaderBreadCrumbs from './ProjectIntegrationEntityHeaderBreadCrumbs';
import { ReactComponent as MoreActionIcon } from '../../../../images/icons/more-actions.svg';
import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { LoadingCircle, Menu, MenuItem, useCreateDialog, useToggle } from '@tonkean/infrastructure';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const Wrapper = styled.div<{ defaultMargin: boolean }>`
    margin: ${({ defaultMargin }) => (defaultMargin ? 30 : 0)}px 0;
`;

const BreadCrumbsAndActionButton = styled.div`
    display: inline-flex;
    align-items: center;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: 5px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 4px;
`;

interface Props extends AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps> {
    defaultMargin?: boolean;
}

const ProjectIntegrationEntityHeaderComponent: React.FC<Props> = ({
    entity,
    projectIntegration,
    entitiesSummary,
    defaultMargin = true,
    tabKeyToOnExit = {},
}) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const $state = useAngularService('$state');
    const $rootScope = useAngularService('$rootScope');
    const { confirm } = useCreateDialog();

    const emitToast = useToastMessage();

    const [currentTabKey] = useGetStateParams('tab');

    const [{ loading: loadingDeleteEntity }, deleteProjectIntegrationEntity] = useLazyTonkeanService(
        'deleteProjectIntegrationEntity',
    );

    const [, getProjectIntegrationEntityDependencies] = useLazyTonkeanService(
        'getProjectIntegrationEntityDisplayNamesDependencies',
    );

    const onClickDeleteEntity = async () => {
        try {
            const dependentEntities = await getProjectIntegrationEntityDependencies(projectIntegration.id, entity.id);

            // If we have dependent entities we not allow deleting
            if (dependentEntities.entitiesDependentDisplayNames.length) {
                emitToast(
                    `Could not delete entity. This entity depend on those entities : ${dependentEntities.entitiesDependentDisplayNames.join(
                        ', ',
                    )}. Remove the dependencies before deleting`,
                    'danger',
                );
            } else {
                const confirmed = await confirm(
                    'Are you sure?',
                    `You are about to delete custom entity "${entity.displayName}" This action cannot be undone`,
                    {
                        okLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        warning: true,
                    },
                );

                if (!confirmed) {
                    return;
                }

                await deleteProjectIntegrationEntity(projectIntegration.id, entity.id);

                $state.go('product.projectIntegrationPage', {
                    page: ProjectIntegrationPageMenuItemType.ENTITIES,
                    enterpriseComponentId: projectIntegration.id,
                });
            }
        } catch {
            emitToast('Failed to delete entity. Please try again.', 'danger');
        }
    };

    return (
        <Wrapper defaultMargin={defaultMargin}>
            <BreadCrumbsAndActionButton>
                <ProjectIntegrationEntityHeaderBreadCrumbs
                    entity={entity}
                    projectIntegration={projectIntegration}
                    entitiesSummary={entitiesSummary}
                    onExit={tabKeyToOnExit[currentTabKey]}
                />

                <Menu
                    show={isMenuOpen}
                    onClose={toggleIsMenuOpen}
                    placement="right-start"
                    menuItems={
                        <MenuItem onClick={onClickDeleteEntity} icon={<TrashIcon />}>
                            Delete Entity
                        </MenuItem>
                    }
                    thin
                >
                    <StyledIconButton onClick={() => toggleIsMenuOpen()} flat>
                        <MoreActionIcon />
                    </StyledIconButton>
                </Menu>

                {loadingDeleteEntity && <StyledLoadingCircle />}
            </BreadCrumbsAndActionButton>
        </Wrapper>
    );
};

export default ProjectIntegrationEntityHeaderComponent;
