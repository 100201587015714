import FunctionOperatorBase from './FunctionOperatorBase';

import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class TextAnalysisFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.TEXT_ANALYSIS;
    override readonly dataType = (operands: formulaTreeNode[]): FieldType | undefined => {
        // Both the default value and all the results must have the same data type. The last operand is always
        // the default value, and we can be sure that it's exists (because this function is being called only if
        // the validation passed), so we'll use it to infer the data type of the function.
        const lastOperand = operands[operands.length - 1];
        return lastOperand?.dataType;
    };
    override readonly sign = 'TextAnalysis';
    override readonly displayName = 'Text Analysis';
    override readonly description = 'Analyses the input text and returns value according to matches.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Expression',
            explanation: 'An expression whose result is matched against each case clause.',
            dataType: FieldType.String,
        },
        {
            displayName: 'Case clause',
            pluralName: 'Case clauses',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Condition ${repeatIndex}`,
                    explanation: 'A string to be searched for within the expression',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Result ${repeatIndex}`,
                    explanation: `If condition ${repeatIndex} is found within the expression, this statement will be returned`,
                },
            ],
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Default Value',
            explanation: "This value is returned if the value of expression doesn't match any of the case clauses.",
            dataType: undefined,
        },
    ];
}
