import React, { useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { FieldTypeIcon } from '@tonkean/fields';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

interface Props {
    additionalTab: TonkeanExpressionAdditionalTab;
    filter: string;
    onSelected(fields: any): void;
}

const FormulaPopoverAdditionalTab: React.FC<Props> = ({ additionalTab, onSelected, filter }) => {
    const { data: allFields } = useAsyncMethod(additionalTab, 'getFields');
    const filteredFields = useMemo(() => {
        if (filter?.length > 0) {
            return allFields?.filter(
                (singleField) =>
                    singleField?.name?.toLowerCase()?.includes(filter) ||
                    singleField?.label?.toLowerCase()?.includes(filter),
            );
        }

        return allFields;
    }, [allFields, filter]);

    return (
        <div className="inline-select-list">
            {filteredFields?.map((singleField) => (
                <div key={singleField.id} className="inline-select-list-data-type">
                    <button type="button" className="inline-select-item" onClick={() => onSelected(singleField)}>
                        <span className="tabs-field-selector-field-icon-container">
                            <FieldTypeIcon fieldType={singleField.fieldType || ''} size="xs" />
                        </span>
                        {singleField.label}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default FormulaPopoverAdditionalTab;
