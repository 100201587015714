import React, { useState } from 'react';

import SystemInterfaceModal from './SystemInterfaceModal';
import useInitiativeNavigation from '../../hooks/useInitiativeNavigation';
import InitiativeNavigationContext from '../../hooks/useIntitiativeNavigationContext';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    open?: boolean;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    onClose?: () => void;
}

const SystemInterfaceNavigationContextWrapper: React.FC<Props> = ({
    open = true,
    initiativeId: paramInitiativeId,
    onClose,
}) => {
    const [initiativeId, setInitiativeId] = useState<TonkeanId<TonkeanType.INITIATIVE> | undefined>(paramInitiativeId);
    const itemInterfaceNavigation = useInitiativeNavigation(initiativeId, setInitiativeId);

    return (
        <InitiativeNavigationContext.Provider value={itemInterfaceNavigation}>
            <SystemInterfaceModal initiativeId={initiativeId} open={open} onClose={onClose} />
        </InitiativeNavigationContext.Provider>
    );
};

export default SystemInterfaceNavigationContextWrapper;
