import { useAngularService } from 'angulareact';

import type { FullProject } from '@tonkean/tonkean-entities';

const useProject = () => {
    const projectManager = useAngularService('projectManager');
    return projectManager.project as FullProject;
};

export default useProject;
