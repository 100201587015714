import type { ProjectIntegrationPageExplorerSectionWithLoading } from '../entities/ProjectIntegrationPageExplorerSection';

/**
 * Gets the loading state of data source navigation menu, with items as the given amount.
 */
function getDataSourceLoadingSections(
    sectionsAmount: number,
    itemsAmount: number[],
): ProjectIntegrationPageExplorerSectionWithLoading[] {
    let uniqueIdentifier: number = 0;

    return [...new Array(sectionsAmount).keys()].map((sectionId) => {
        uniqueIdentifier = uniqueIdentifier + 1;
        return {
            id: `${uniqueIdentifier}`,
            loading: true,
            items: [...new Array(itemsAmount[sectionId]).keys()].map(() => {
                uniqueIdentifier = uniqueIdentifier + 1;

                return {
                    id: `${uniqueIdentifier}`,
                    loading: true,
                };
            }),
        };
    });
}

export default getDataSourceLoadingSections;
