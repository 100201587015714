export enum SharePointAuthenticationType {
    AUTHORIZATION_CODE = 'authorization_code',
    CLIENT_CREDENTIALS = 'client_credentials',
}

export const sharePointAuthenticationTypeToDisplayName: Record<SharePointAuthenticationType, string> = {
    [SharePointAuthenticationType.AUTHORIZATION_CODE]: 'User',
    [SharePointAuthenticationType.CLIENT_CREDENTIALS]: 'Application',
};

export default SharePointAuthenticationType;
