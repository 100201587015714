import usePrevious from './usePrevious';

/**
 * This function return the last non-nullish value it was provided.
 * If the current value is null/undefined, it returns the previous value.
 */
function useLastDefinedValue<T>(value: T | undefined | null) {
    const prev = usePrevious(value, true);

    if (!value) {
        return prev;
    }

    return value;
}

export default useLastDefinedValue;
