import BuiltInListsUtils from '../../../../../shared/services/builtInLists/builtInListsUtils';
import { analyticsWrapper } from '@tonkean/analytics';

function SetupIntegrationTypeCtrl($scope, $q, $log, builtInListsConsts, csvHelper) {
    $scope.setManualStepsSetup = function (stepsId, syncId) {
        // if user choose manual flow (from manual or csv)
        // we replace the steps with a default manual steps flow and go to the next step
        const newSteps = [];
        // add this step
        newSteps.push($scope.data.currentStep);

        // add the relevant config steps
        const defaultSteps = angular.copy(
            BuiltInListsUtils.defaultSteps[
                stepsId || $scope.data.currentStep.data.defaultStepsId || 'MANUAL_INITIATIVES'
            ],
        );
        for (const defaultStep of defaultSteps) {
            newSteps.push(defaultStep);
        }

        $scope.data.isStepsModified = true;
        $scope.data.steps = newSteps;

        $scope.addSetupGroupStep();

        if (syncId) {
            // we need to override the sync as well
            $scope.data.originalSync = $scope.data.selectedList.sync;
            $scope.data.selectedList.sync = { integrationType: stepsId, viewData: {} };
        }

        $scope.clearCurrentSetupValues();

        $scope.nextStep();
    };

    $scope.setInitialListSteps = function () {
        if ($scope.data.isStepsModified) {
            // reset the current list if has changed (in case of clicking back)
            $scope.data.steps = angular.copy($scope.data.selectedList.steps);
            $scope.addSetupGroupStep();

            if ($scope.data.originalSync) {
                $scope.data.selectedList.sync = $scope.data.originalSync;
                $scope.data.originalSync = null;
            }
            $scope.data.isStepsModified = false;
        }

        $scope.clearCurrentSetupValues();

        $scope.nextStep();
    };

    /** ****** region: Import CSV ********/

    $scope.csvData = {};

    $scope.handleCsvResult = function (result) {
        console.log(result);

        // add new fields as columns
        const columnsMap = {};
        const existingColumnNames = {};
        if ($scope.data.columns && $scope.data.columns.length) {
            $scope.data.columns.forEach((col) => (existingColumnNames[col.fieldDefinition.name] = col));
        }

        const csvColumns = angular.copy($scope.csvData.table[0]);
        for (const [i, csvCol] of csvColumns.entries()) {
            if (
                result.include[csvCol] &&
                (result.trackNameIndex < 0 || $scope.csvData.table[0][result.trackNameIndex] !== csvCol) &&
                (result.ownerIndex < 0 || $scope.csvData.table[0][result.ownerIndex] !== csvCol) &&
                (result.dueDateIndex < 0 || $scope.csvData.table[0][result.dueDateIndex] !== csvCol)
            ) {
                if (!$scope.data.columns) {
                    $scope.data.columns = [];
                }

                let key = `CSV_COL_${i}`;
                if (existingColumnNames[csvCol]) {
                    key = existingColumnNames[csvCol].fieldDefinition.key;
                } else {
                    $scope.data.columns.push({
                        integrationType: 'MANUAL',
                        fieldDefinition: {
                            key, // saving this to be used as reference in the initiatives we create
                            name: csvCol,
                            type: 'MANUAL',
                            updateable: true,
                            ranges: [],
                        },
                    });
                }

                columnsMap[i] = key;
            }
        }

        // Add rows as initiatives.
        const existingTitles = {};

        for (let j = 1; j < $scope.csvData.table.length; j++) {
            const row = angular.copy($scope.csvData.table[j]);
            const trackName = row[result.trackNameIndex];

            if (
                trackName &&
                trackName.length &&
                (result.avoidDups !== 'ignore' || !existingTitles[trackName.toLowerCase().trim()])
            ) {
                const newInitiative = { title: trackName };
                if (result.dueDateIndex > -1) {
                    // try to get the due date
                    try {
                        const date = Date.parse(row[result.dueDateIndex]);
                        if (date) {
                            newInitiative.dueDate = date.getTime();
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                if (result.ownerIndex > -1) {
                    // try to get owner
                    try {
                        const ownerObj = result.ownersMap[row[result.ownerIndex]];
                        if (ownerObj && ownerObj.people && ownerObj.people.length && ownerObj.people[0]) {
                            if (ownerObj.people[0].id) {
                                newInitiative.ownerId = ownerObj.people[0].id;
                            } else {
                                newInitiative.owner = ownerObj.people[0];
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                const dataFields = {};
                for (const [k, csvColumn] of csvColumns.entries()) {
                    if (
                        result.include[csvColumn] &&
                        (result.trackNameIndex < 0 || $scope.csvData.table[0][result.trackNameIndex] !== csvColumn) &&
                        (result.ownerIndex < 0 || $scope.csvData.table[0][result.ownerIndex] !== csvColumn) &&
                        (result.dueDateIndex < 0 || $scope.csvData.table[0][result.dueDateIndex] !== csvColumn)
                    ) {
                        // get the value
                        dataFields[columnsMap[k]] = row[k];
                    }
                }

                newInitiative.fields = dataFields;

                $scope.initiativesResults.push(newInitiative);

                // Also add this track name to the existingTitles map since it doesn't exist there.
                existingTitles[trackName.toLowerCase().trim()] = true;
            }
        }

        // jump two steps cause next step will expect an integration or otherwise will try to ask us to create manual initiatives so we want to skip that
        $scope.nextStep($scope.data.currentStepIndex + 2);
    };

    $scope.handleFileSelect = function (el) {
        console.log('file changed');
        $scope.error = null;

        // reset the initiatives that were imported from the previous csv (we use this method to centeralize the handling of this array to avoid reference problems)
        $scope.addToInitiativesResults(null, null, true);

        const file = el.files[0];
        $scope.csvData.fileName = file.name;
        $scope.loadingFile = true;

        csvHelper.parseFile(
            file,
            (table, csvFile) => {
                $scope.$apply(function ($scope) {
                    analyticsWrapper.track('CSV file uploaded', { category: 'Import' });
                    $scope.csvData.file = csvFile;
                    $scope.csvData.table = table;
                    $scope.loadingFile = false;
                });
            },
            (error) => {
                $scope.$apply(function ($scope) {
                    $scope.loadingFile = false;
                    $scope.error = error;
                });
            },
        );
    };

    $scope.onFileChange = function (e) {
        console.log('calling handleFileSelect in the current scope');
        $scope.handleFileSelect(e.target);
    };

    $scope.clearFile = function () {
        $scope.csvData.file = null;
        $scope.csvData.table = null;
    };

    $scope.getDataForPreviewPromise = function () {
        const promise = $q(function (resolve) {
            const entities = $scope.initiativesResults.map(function (initiative) {
                const entity = {
                    name: initiative.title,
                };
                if (initiative.owner) {
                    entity.ownerName = initiative.owner.firstName;
                    entity.ownerEmail = initiative.owner.email;
                }
                return entity;
            });
            resolve({
                finishedCollecting: true,
                entities,
            });
        });
        return promise;
    };

    $scope.previewPromise = $scope.getDataForPreviewPromise();

    /** endregion: Import CSV */
}

export default angular.module('tonkean.app').controller('SetupIntegrationTypeCtrl', SetupIntegrationTypeCtrl);
