<div class="padding-normal common-close-btn-container">
    <tnk-use-escape callback="onModalEscClose(event)"></tnk-use-escape>
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../svg/src/icons/x.svg"></tnk-icon>
    </span>

    <div ng-if="data.loading" class="text-center">
        <i class="loading-large"></i>
    </div>

    <div ng-if="data.isError" class="common-error">Failed to load item</div>

    <tnk-field-inspect
        ng-if="!data.loading"
        project-integration="data.projectIntegration"
        external-type="data.externalType"
        field-query="data.searchQuery"
        on-field-select="
            onFieldSelected(
                field,
                undefined,
                data.idRelationFieldDefinitionId,
                data.creatingCustomTriggerId,
                data.performOnWorkerItem
            )
        "
        static-data="data.staticData"
        example-entity="data.exampleEntity"
        view-only="data.viewOnly"
        use-tracks-of-group-id="data.useTracksOfGroupId"
        field-filter="data.fieldFilter"
        only-updatable="data.onlyUpdatable"
        workflow-version-id="data.workflowVersionId"
        only-items-from-selected-environment="data.onlyItemsFromSelectedEnvironment"
        multi-select="data.multiSelect"
        should-have-entity-prefix="data.shouldHaveEntityPrefix"
        creating-custom-trigger-id="data.creatingCustomTriggerId"
        is-for-matched-items="data.isForMatchedItem"
        external-type-display-name="data.externalTypeDisplayName"
    ></tnk-field-inspect>

    <div ng-if="data.initiativeId" class="common-size-xxxxs common-bold common-color-dark-grey margin-top-xs">
        * Empty custom fields won't show
    </div>
</div>
