import React from 'react';

import ProjectIntegrationEntityGenericSplitPageWithRequest from './ProjectIntegrationEntityGenericSplitPageWithRequest';
import HandleResponseActionType from './ProjectIntegrationEntitySchema/HandleResponseActionType';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

const ProjectIntegrationEntityContinuousCollectPage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ entity, ...props }) => {
    return (
        <ProjectIntegrationEntityGenericSplitPageWithRequest
            projectIntegrationActionId={entity.collectDefinition.collectActionId}
            httpRequestConfigurationHeaderText="Collect"
            requestUrlHeader="API Path To Collect From"
            entity={entity}
            description="Configure the API request Tonkean sends to collect the entity."
            handleResponseActionType={HandleResponseActionType.COLLECT}
            showFetchEntity
            enableSubEntitiesCollectOption
            {...props}
        />
    );
};

export default ProjectIntegrationEntityContinuousCollectPage;
