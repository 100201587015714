import template from './tnkTracksEditor.template.html?angularjs';

function tnkTracksEditor() {
    return {
        restrict: 'E',
        scope: {
            scrollerElementId: '@',
            listParentElementId: '@',
            tracks: '=',
            loading: '=',
            editorId: '@',
            parentItem: '=',
            defaultFunc: '=',
            defaultTag: '=',
            defaultMe: '=',
            defaultNewItemText: '@',
            hideHeader: '=',
            quickCreationMode: '<',
            lazyLoadTracks: '=',
            allowDndOnRoot: '=',
            viewOnlyMode: '=',
            anonymous: '=',
            collapseByDefault: '=',
            hideAddNewForce: '=',
            hideColumnQuickCreateForce: '=',
            showAddOnEmpty: '=',
            showArchived: '=',
            showRemindMe: '=',
            blockAddingLinks: '=',
            filter: '=',
            onlyGroup: '=',
            groupId: '=',
            workflowVersionId: '=',
            onSubmit: '&',
            overrideItemClick: '=',
            customFields: '=',
            isTagOrFunc: '=',
            searchInitiativesQuery: '<',
            loadNextInitiativesPageCallback: '&',
            reloadAllInitiativesPages: '&',
            filterByField: '&',
            onOrderBy: '&',
            onSort: '<', // This is for react components
            hasMoreInitiatives: '=',
            saveOrderPreference: '<',
            createdInWorkerRunInformation: '<',
            createTracksInEditMode: '<', // Whether to create a new track in edit mode
            displayFieldsList: '<', // Array of field definitions to display. If none provided, it will be ignored
            onInitiativeCreated: '&', // A callback function to execute when a track is added
            onTrackRemoved: '<', // A callback function to execute when a track is removed
            ignoreColumnVisibility: '<', // Whether take in mind the field manager column visibility
            hideBulkSelection: '<', // Whether to show the bulk selection checkbox
            addItemLabel: '@', // A customization to the add item label. Default is "Add Item"
            minimizeTitleHeader: '<', // Whether to disable to growing of the title to the available space left. Useful when using the component is small sections
            collectItemsMode: '<',
            createdInFormId: '<', // The form id which the items will be related to
            createdInFormName: '@', // The form name which the items will be related to
            createdInForm: '<', // The form which the items will be related to
            doNotAllowEditPublishedInitiatives: '<', // Whether to allow editing already published tracks
            hideContextMenu: '<', // Whether to hide the right-click menu
            formViewInInitiative: '<',
            showHeader: '<', // Whether to always show the header of the tracks
            headerMaxLines: '<', // The number of lines the header can take, defaults to 1
            disableNewItemTypeahead: '<', // Whether to enable a typeahead when adding new track
            disableOwnerInvite: '<', // Disable inviting an owner in the owner component
            noSubitems: '<', // Disable inner items creation
            disableGoToTrack: '<', // Whether it's possible to click on initiative and go to single initiative view
            viewItemsMode: '<', // Whether its view items mode
            stickyHeader: '<', // Whether the header row should be sticky
            fieldValidationMap: '<', // Validation map for fields, data should be initiativeId to fieldId to error message
            hideHistory: '<', // Whether to show a button for viewing the track history
            hideTitle: '=',
            showActionsColumn: '=',
            workerForms: '=',
            solutionBusinessReportId: '<', // Whether to show the fields by their solution report state
            environment: '<', // The environment the fields are taken from
            onlyUpdateExistingItems: '<', // Whether to only enable updating existing items, and not creating new ones
            fieldsConfigurations: '<',
            defaultItemInterfaceId: '<',
            originWidget: '<',
            enableInnerItemsToggle: '<',
            showDone: '<', // whether to show done items
            disableFieldHeaderDropdown: '<',
            enableAddingItems: '<', // whether to allow adding items
            onManualFieldUpdate: '<',
            numerateItems: '<',
            loadingRelatedInitiatives: '<',
        },
        template,
        controller: 'TracksEditorCtrl',
    };
}

angular.module('tonkean.app').directive('tnkTracksEditor', tnkTracksEditor);
