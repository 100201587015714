import React, { useState } from 'react';
import styled from 'styled-components';

import SingleWorkerRunFlowRunTableHeader from './SingleWorkerRunFlowRunTableHeader';
import SingleWorkerRunFlowRunTableList from './SingleWorkerRunFlowRunTableList';

import type { WorkerRun } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const TableContainer = styled.div`
    border: solid ${Theme.current.palette.general.border};
    border-radius: 0 0 3px 3px;
    border-width: 0 1px 1px 1px;
`;

interface Props {
    /** if undefined it means that it's the initial load or a manual reload. */
    workerRun?: WorkerRun;

    reload(): void;
}

const SingleWorkerRunFlowRunTable: React.FC<Props> = ({ workerRun, reload }) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <>
            <SingleWorkerRunFlowRunTableHeader
                searchTerm={searchTerm}
                onChange={setSearchTerm}
                reload={reload}
                workerRun={workerRun}
            />

            <TableContainer>
                <SingleWorkerRunFlowRunTableList workerRun={workerRun} searchTerm={searchTerm} />
            </TableContainer>
        </>
    );
};

export default SingleWorkerRunFlowRunTable;
