import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationCollectStatusLastCollectErrorTooltip from './ProjectIntegrationCollectStatusLastCollectErrorTooltip';

import { Theme } from '@tonkean/tui-theme';

const StyledError = styled.div`
    display: flex;
    align-items: center;
    color: ${Theme.colors.gray_700};
`;

const StyledErrorLabel = styled.div`
    color: ${Theme.colors.warning};
    margin-right: 6px;
`;

interface Props {
    error: string;
}

const ProjectIntegrationCollectStatusLastCollectFailedUpdate: React.FC<Props> = ({ error }) => {
    return (
        <StyledError>
            <StyledErrorLabel data-automation="project-integration-collect-status-last-collect-failed-update">
                Collect encountered an error
            </StyledErrorLabel>

            <ProjectIntegrationCollectStatusLastCollectErrorTooltip error={error} />
        </StyledError>
    );
};

export default ProjectIntegrationCollectStatusLastCollectFailedUpdate;
