import React from 'react';

import { SimpleSelect } from '@tonkean/infrastructure';
import { EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';

const fetchEntityOptions: { value: EntityResponseHandlingDefinitionType; label: string }[] = [
    {
        value: EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES_IDS,
        label: 'Use existing entity fetcher',
    },
    {
        value: EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES,
        label: 'Build new entity using the received payload',
    },
];

interface Props {
    namePrefix: string;
}

const ProjectIntegrationEntityLiveStreamWebhookFilter: React.FC<Props> = ({ namePrefix }) => {
    return <SimpleSelect name={`${namePrefix}.responseHandlingType`} options={fetchEntityOptions} />;
};

export default ProjectIntegrationEntityLiveStreamWebhookFilter;
