import { reactToAngular } from 'angulareact';

import MarketplaceDataSourceTile from './MarketplaceDataSourceTile';

export default angular
    .module('tonkean.app')
    .component(
        'tnkMarketplaceDataSourceTile',
        reactToAngular(MarketplaceDataSourceTile, ['marketplaceItemSummary', 'onChange', 'onCancel']),
    );
