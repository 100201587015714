import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import type { Group } from '@tonkean/tonkean-entities';

type UserPermissionsForInitiativeInGroupResult = {
    canUserEditItem: boolean;
    canUserViewItem: boolean;
};

const useUserPermissionsForInitiativeInGroup = (
    isArchivedInitative?: boolean,
    isDraftInitiative?: boolean,
    group?: Group,
): UserPermissionsForInitiativeInGroupResult => {
    const groupPermissions = useAngularService('groupPermissions');

    const canUserEditItem = useMemo(() => {
        const canUserEditItemsInGroup = group ? groupPermissions.isCurrentUserGroupCollaborator(group) : false;

        return canUserEditItemsInGroup && !isArchivedInitative;
    }, [group, groupPermissions, isArchivedInitative]);

    const canUserViewItem = useMemo(() => {
        if (isDraftInitiative && group) {
            return groupPermissions.hasPermissionsToEditWorker(group);
        }

        return true;
    }, [group, groupPermissions, isDraftInitiative]);

    return { canUserEditItem, canUserViewItem };
};

export default useUserPermissionsForInitiativeInGroup;
