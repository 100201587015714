<div class="single-integration-view relative">
    <div
        ng-click="cleanProjectIntegrationId()"
        class="padding-normal-sm pointer common-color-grey common-size-xxs margin-top-xs"
    >
        <i class="fa fa-chevron-left margin-right"></i>
        <span>Back</span>
    </div>
    <div class="margin-top-lg">
        <div class="common-title flex-vmiddle">
            <!-- Integration logo when icon url is NOT available -->
            <div ng-if="!data.projectIntegration || !data.projectIntegration.iconUrl" class="flex-no-shrink">
                <!-- Integration logo (inline) -->
                <i
                    class="initiative-integration-icon mod-normal block hidden-xs margin-none mod-{{
                        data.projectIntegration.integration.integrationType.toLowerCase()
                    }}"
                ></i>
            </div>
            <!-- Integration logo when icon url IS available -->
            <div ng-if="data.projectIntegration && data.projectIntegration.iconUrl" class="flex-no-shrink">
                <i
                    class="initiative-integration-icon mod-normal block margin-right-xs"
                    ng-style="{ background: 'url(' + data.projectIntegration.iconUrl + ') no-repeat center' }"
                ></i>
            </div>

            <div class="margin-left flex-grow flex-vmiddle show-edit-when-hover">
                <div ng-hide="data.editIntegrationDisplayName">
                    <span>{{ data.projectIntegration.displayName }}</span>
                    <i
                        class="edit-button"
                        ng-click="data.tempNewIntegrationDisplayName = data.projectIntegration.displayName; data.editIntegrationDisplayName = true"
                    >
                        <i class="fa fa-pencil margin-left"></i>
                    </i>
                </div>

                <!-- Edit display name-->
                <div ng-if="data.editIntegrationDisplayName" class="flex-vmiddle">
                    <input
                        ng-model="data.tempNewIntegrationDisplayName"
                        class="common-input-only-line form-control"
                        autofocus
                    />
                    <button
                        class="btn btn-primary btn-sm"
                        ng-click="updateProjectIntegrationDisplayName(data.projectIntegration, data.tempNewIntegrationDisplayName)"
                    >
                        Save
                    </button>
                    <button class="btn btn-cancel btn-sm" ng-click="data.editIntegrationDisplayName = false">
                        Cancel
                    </button>

                    <i class="loading-small" ng-if="data.editIntegrationDisplayNameLoading"></i>
                </div>
            </div>

            <button
                ng-if="data.projectIntegration.integration.integrationType === 'WEBHOOK'"
                data-automation="single-integration-view-add-item-button"
                class="btn btn-primary margin-right margin-top-xs"
                ng-click="openEditCustomWebhookItemModal()"
            >
                + Add Item
            </button>

            <div>
                <tnk-integration-group
                    sources="[data.projectIntegration.integration.integrationType.toLowerCase()]"
                    ng-model="ignored"
                    display-mode="edit-button"
                    edit-mode="true"
                    open-modal-for-configuration="true"
                    state="projectIntegrationToStateMap[data.projectIntegration.id].state"
                    current-integration="ignored"
                    integration-changed="onIntegrationSaved()"
                    on-collect="onCollectComplete(projectIntegration)"
                ></tnk-integration-group>
            </div>
        </div>

        <div class="margin-top common-size-xxxs common-color-grey flex-vmiddle">
            <!-- Last Update -->
            <span class="margin-right-xs">Last Update:</span>

            <span
                uib-tooltip="{{ data.projectIntegration.lastCollect | date: 'medium' }}"
                ng-if="data.projectIntegration.lastCollect"
            >
                {{ data.projectIntegration.lastCollect | timeAgo }}
            </span>

            <span ng-if="!data.projectIntegration.lastCollect">N/A</span>

            <!-- Update Now -->
            <a
                class="margin-left common-size-xxxxxs"
                ng-if="!data.projectIntegration.disabled && data.runningCollect !== data.projectIntegration.id"
                ng-click="runCollect(data.projectIntegration)"
            >
                <i class="fa fa-refresh margin-right-xs"></i>
                Update now
            </a>

            <!-- Updating -->
            <span
                ng-if="data.runningCollect === data.projectIntegration.id"
                class="margin-left common-size-xxxxxs flex-vmiddle common-color-primary"
            >
                <i class="loading-xsmall margin-right-xs"></i>
                <span>Updating...</span>
            </span>
        </div>
        <hr />

        <!-- view data -->
        <div class="margin-bottom-lg padding-bottom-lg">
            <div class="flex-vmiddle">
                <span class="margin-right">Explore:</span>
                <tnk-entity-selector
                    project-integration="data.projectIntegration"
                    force-fetch-supported-entities-from-server="true"
                    mode="sync"
                    on-entity-selected="loadData(selectedEntity, projectIntegration)"
                ></tnk-entity-selector>
            </div>
            <div class="padding-bottom-lg margin-top" ng-if="data.previewItems.selectedEntity">
                <div class="preview-data-table padding-normal rounded-box">
                    <div class="flex-vmiddle margin-bottom-lg">
                        <!-- All -->
                        <button
                            class="
                                text-left
                                btn btn-default
                                common-no-outline
                                margin-right-xs
                                padding-right-xs padding-left-xs
                            "
                            ng-click="toggleFilter(false)"
                            ng-class="!data.isFiltered ? 'selected-option' : 'not-selected-option'"
                        >
                            All {{ data.previewItems.entityType }}
                        </button>

                        <!-- Custom filter -->
                        <button
                            class="
                                text-left
                                btn btn-default
                                common-no-outline
                                margin-right-xs
                                padding-right-xs padding-left-xs
                            "
                            ng-click="toggleFilter(true)"
                            ng-class="data.isFiltered ? 'selected-option' : 'not-selected-option'"
                        >
                            Custom Filter
                        </button>
                    </div>

                    <!-- Sync preview component -->
                    <tnk-live-sync
                        control="data.previewItems.control"
                        project-integration="data.projectIntegration"
                        copy-properties-from-definition="true"
                        sync-validity-state="data.previewItems.validityState"
                        selected-entity-plural-name="data.previewItems.entityType"
                        custom-defined-view="true"
                        existing-definition="data.previewItems.definition"
                        selected-entity-type="data.previewItems.entityType"
                        view-type="viewTypes.custom"
                        hide-manage-triggers-modal="true"
                        hide-information="true"
                        override-activate-bot-object="data.overrideActivateBotObject"
                        hide-filters="!data.isFiltered"
                    ></tnk-live-sync>
                </div>
            </div>
        </div>
    </div>
</div>
