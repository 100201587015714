<div class="inline-block">
    <div class="tnk-checkbox-container flex-vmiddle" ng-class="{ 'flex-row-reverse': !isReversed }" ng-click="toggle()">
        <span>{{ label }}</span>

        <!-- Spacer -->
        <span class="margin-left-xs"></span>

        <div class="tnk-checkbox-mark-zone">
            <div ng-if="data.isSaving"><i class="loading-small"></i></div>

            <div ng-if="!data.isSaving" class="common-height-full">
                <input type="checkbox" ng-checked="value" data-automation="checkbox-mark-indication-{{ label }}" />
                <span class="tnk-checkbox-mark" data-automation="checkbox-mark-button-{{ label }}"></span>
            </div>
        </div>
    </div>
</div>
