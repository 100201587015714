import { useMemo } from 'react';

import type { ActionWidgetOpenIntakeSequenceActionData } from '../../components/actionButtons/ActionWidgetActionData';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import {
    InterfaceCTAActionType,
    type InterfaceCTASavedAction,
    type InterfaceCTASavedIntakeSequenceAction,
} from '@tonkean/tonkean-entities';

export const useIntakeSequenceActions = (
    configurationActions: InterfaceCTASavedAction[],
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    groupIdToMatchedItemId: Record<TonkeanId<TonkeanType.GROUP>, TonkeanId<TonkeanType.INITIATIVE>>,
) => {
    const actions: ActionWidgetOpenIntakeSequenceActionData[] = useMemo(() => {
        return configurationActions
            .filter(
                (action): action is InterfaceCTASavedIntakeSequenceAction =>
                    action.type === InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE,
            )
            .map((action) => {
                // Different group from current one means its for matched item
                if (action.groupId !== groupId) {
                    const matchedInitiativeId = groupIdToMatchedItemId[action.groupId];
                    return {
                        ...action,
                        initiativeId: matchedInitiativeId,
                    };
                }

                return {
                    ...action,
                    initiativeId,
                };
            });
    }, [configurationActions, groupId, groupIdToMatchedItemId, initiativeId]);

    return actions;
};
