import type React from 'react';

import OperationConfigurationSections from './OperationConfigurationSections';
import { ReactComponent as DataSourceIcon } from '../../images/data-source.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as ReplaceIcon } from '../../images/replace.svg';
import { ReactComponent as CostsIcon } from '../../images/solution-mapper-costs.svg';

const operationConfigurationSectionConfig: Record<
    OperationConfigurationSections,
    { color: string; iconColor: string; iconSize: number; icon: React.ComponentType }
> = {
    [OperationConfigurationSections.APPS]: {
        color: '#00AE11',
        iconColor: '#00ae11',
        iconSize: 16,
        icon: DataSourceIcon,
    },
    [OperationConfigurationSections.AUTOMATED]: {
        color: '#005BAE',
        iconColor: '#437cbf',
        iconSize: 14,
        icon: ReplaceIcon,
    },
    [OperationConfigurationSections.MANUAL]: {
        color: '#FF8A00',
        iconColor: '#ffb700',
        iconSize: 14,
        icon: EditIcon,
    },
    [OperationConfigurationSections.COSTS]: {
        color: '#E10000',
        iconColor: '#D72700',
        iconSize: 14,
        icon: CostsIcon,
    },
};

export default operationConfigurationSectionConfig;
