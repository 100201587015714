import { right } from '@popperjs/core/lib/enums';
import { useAngularService } from 'angulareact';
import React from 'react';
import styled, { css } from 'styled-components';

import ItemInterfaceWidgetIcon from './ItemInterfaceWidgetIcon';
import ItemWidgetComponent from './ItemWidgetComponent';
import ItemWidgetSidepaneTrigger from './ItemWidgetSidepaneTrigger';
import { getIsInWidgetBuilder } from '../utils';

import { Breakpoint, H4, TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { TooltipIcon } from '@tonkean/svg';
import type { Icon, ItemInterfaceWidget, ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export const ItemWidgetHeaderTitle = styled(H4)`
    color: ${Theme.colors.gray_700};
`;

const Wrapper = styled(ItemWidgetComponent)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TitleWrapper = styled.div<{ $shouldRemoveTopPadding: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 8px 0px;

    ${({ $shouldRemoveTopPadding }) =>
        $shouldRemoveTopPadding &&
        css`
            padding-top: 0px;
        `};

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 4px 0;
        margin-left: 0;

        ${({ $shouldRemoveTopPadding }) =>
            $shouldRemoveTopPadding &&
            css`
                padding-top: 0px;
            `};
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TooltipWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-left: 4px;
    margin-top: 2px;
`;

const StyledTooltipIcon = styled.div`
    width: 14px;
    height: 14px;

    svg {
        height: 14px;
        width: 14px;
    }
`;

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
    title?: JSX.Element | string;
    wrapTitle?: boolean;
    actions?: JSX.Element;
    savingIndicator?: JSX.Element;
    isClickable: boolean;
    widget: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration> | undefined;
    showIcon?: boolean;
    shouldRemoveTopPadding?: boolean;
    descriptionTooltip?: string | undefined;
    widgetIcon?: Icon;
    pluggableActions?: React.ReactNode;
    isWidgetActiveOrInHover?: boolean;
}

const ItemWidgetHeader: React.FC<Props> = ({
    title,
    wrapTitle,
    actions,
    savingIndicator,
    isClickable,
    inert,
    widget,
    showIcon,
    shouldRemoveTopPadding = false,
    descriptionTooltip,
    widgetIcon,
    pluggableActions,
    isWidgetActiveOrInHover,
    ...props
}) => {
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    return (
        <Wrapper data-automation="widget-header" $noPadding {...props}>
            {isClickable && <ItemWidgetSidepaneTrigger />}
            {widget && showIcon && widgetIcon && (
                <ItemInterfaceWidgetIcon size={20} icon={widgetIcon} widget={widget} isWidgetHeader />
            )}
            <div inert={inert} style={{ width: '100%' }}>
                <HeaderWrapper data-automation="item-widget-header-widget-title">
                    {title && (
                        <TitleWrapper $shouldRemoveTopPadding={shouldRemoveTopPadding}>
                            {typeof title === 'string' ? (
                                <ItemWidgetHeaderTitle>
                                    {wrapTitle ? title : <TextEllipsis numberOfLines={1}>{title}</TextEllipsis>}
                                </ItemWidgetHeaderTitle>
                            ) : (
                                title
                            )}
                            {descriptionTooltip && descriptionTooltip.trim().length > 0 && (
                                <TooltipWrapper>
                                    <Tooltip content={descriptionTooltip} placement={right}>
                                        <StyledTooltipIcon>
                                            <TooltipIcon />
                                        </StyledTooltipIcon>
                                    </Tooltip>
                                </TooltipWrapper>
                            )}
                            {savingIndicator}
                        </TitleWrapper>
                    )}
                    {actions}
                    {!isInBuilder && isWidgetActiveOrInHover && pluggableActions}
                </HeaderWrapper>
            </div>
        </Wrapper>
    );
};

export default ItemWidgetHeader;
