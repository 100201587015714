import template from './tnkWorkflowVersionSelector.template.html?angularjs';

/**
 * Selector of workflow versions.
 */
export default angular.module('tonkean.app').component('tnkWorkflowVersionSelector', {
    bindings: {
        groupId: '@',

        onWorkflowVersionSelected: '&',
    },
    template,
    controller: 'WorkflowVersionSelectorCtrl',
});
