import { getDisplayObjectForFieldDefinition } from './getDisplayObjectForFieldDefinition';

import type { Group, State, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

/**
 * Gets the options for columns and states for definition of fields.
 */
export function getColumnsAndStatesOptionsForDefinitions(
    onlyGroupId,
    considerAllGroupsInProject,
    selectedColumnFieldsMap,
    selectedGlobalFieldsMap,
    groupIdToGroupMap: Record<TonkeanId<TonkeanType.GROUP>, Group>,
    includeGlobalFields,
    onlyWorkflowVersionId,
    workflowVersionIdToWorkflowVersionMap: Record<TonkeanId<TonkeanType.WORKFLOW_VERSION>, WorkflowVersion>,
    workflowVersionManager,
    filterExternalFields,
    onlyIdRelationField,
) {
    let allFieldsToAdd = [];
    let allStatesToAdd: State[] = [];

    // Gathering the groups and workflow versions we need to extract fields and states from.
    let groupIds: TonkeanId<TonkeanType.GROUP>[] = [];
    let workflowVersionIds: TonkeanId<TonkeanType.WORKFLOW_VERSION>[] = [];

    // The boolean considerAllGroupsInProject is used to indicate whether we should take definitions
    // from all groups. In the meantime of having a semi-shared entities between a group and a workflow
    // version, we will use this boolean also as "considerAllWorkflowVersionsInProject".
    if (considerAllGroupsInProject) {
        // Gathering all the groups.
        for (const groupId in groupIdToGroupMap) {
            if (groupIdToGroupMap.hasOwnProperty(groupId)) {
                groupIds.push(groupId as TonkeanId<TonkeanType.GROUP>);
            }
        }

        // Gathering all the workflow versions.
        for (const workflowVersionId in workflowVersionIdToWorkflowVersionMap) {
            if (workflowVersionIdToWorkflowVersionMap.hasOwnProperty(workflowVersionId)) {
                workflowVersionIds.push(workflowVersionId as TonkeanId<TonkeanType.WORKFLOW_VERSION>);
            }
        }
    } else if (onlyGroupId || onlyWorkflowVersionId) {
        groupIds = onlyGroupId ? [onlyGroupId] : [];
        workflowVersionIds = onlyWorkflowVersionId ? [onlyWorkflowVersionId] : [];
    }

    // For each group, extracting the states from.
    /* jshint loopfunc:true */
    for (const groupId of groupIds) {
        const group = groupIdToGroupMap[groupId];

        if (group) {
            let workflowVersion: WorkflowVersion | null = null;
            if (onlyWorkflowVersionId) {
                // If we're given an only workflow version id, we take the workflow version by given id.
                workflowVersion = workflowVersionManager.getCachedWorkflowVersion(onlyWorkflowVersionId);

                if (workflowVersion?.groupId !== group.id) {
                    // We must make sure the given id is of the same group.
                    continue;
                }
            } else {
                // Otherwise, we just take the published version of the group.
                workflowVersion = workflowVersionManager.getPublishedVersionFromCache(group.id);
            }

            if (workflowVersion?.states?.length) {
                allStatesToAdd = allStatesToAdd.concat(workflowVersion.states.filter((state) => state?.label.length));
            }
        }
    }

    // For each workflow version, extracting the fields from it.
    /* jshint loopfunc:true */
    for (let i = 0; i < workflowVersionIds.length; i++) {
        const workflowVersionId = workflowVersionIds[i];
        const groupName = groupIdToGroupMap[workflowVersionIdToWorkflowVersionMap[workflowVersionId!]?.groupId!]?.name;

        if (workflowVersionId) {
            // Column field definitions
            const workflowFields = selectedColumnFieldsMap[workflowVersionId];
            if (workflowFields?.length) {
                const workflowFieldsToAdd = workflowFields
                    .filter((fieldDef) => fieldDef && !fieldDef.systemUtilized)
                    .filter((fieldDef) => {
                        if (filterExternalFields) {
                            return fieldDef.type !== 'EXTERNAL';
                        }

                        if (onlyIdRelationField) {
                            return fieldDef.idRelationField;
                        }

                        return true;
                    })
                    .map((fieldDef) =>
                        getDisplayObjectForFieldDefinition(
                            fieldDef,
                            workflowVersionIds.length > 1 ? groupName : null,
                            false,
                        ),
                    );
                allFieldsToAdd = allFieldsToAdd.concat(workflowFieldsToAdd);
            }

            // Global field definitions
            if (includeGlobalFields && selectedGlobalFieldsMap[workflowVersionId]?.length) {
                allFieldsToAdd = allFieldsToAdd.concat(
                    selectedGlobalFieldsMap[workflowVersionId].map((fieldDefinition) =>
                        getDisplayObjectForFieldDefinition(
                            fieldDefinition,
                            workflowVersionIds.length > 1 ? groupName : null,
                            true,
                        ),
                    ),
                );
            }
        }
    }

    return {
        fields: allFieldsToAdd,
        states: Utils.getUniqueValues(allStatesToAdd.map((state) => state.label)),
        statesFull: allStatesToAdd,
    };
}
