import { Formik } from 'formik';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import EditableTitle from '../../../../../../../components/common/EditableTitle/EditableTitle';

import {
    Field,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    Textarea,
    Toggle,
    withModal,
} from '@tonkean/infrastructure';
import type { ActionParameterExpression, BaseActionParameter } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledModalHeader = styled(ModalHeader)`
    display: flex;
    align-items: center;
    line-height: 28px;
`;

const OneLineField = styled(Field)`
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 30px;
`;

const PredefinedActionParameterSchema = Yup.object({
    displayName: Yup.string()
        .required('Action parameter must have a name')
        .max(350, "Action parameter name can't be longer than 350 characters"),
    description: Yup.string().notRequired(),
    isRequired: Yup.boolean().default(false),
});

type PredefinedActionParameterSchemaType = Yup.InferType<typeof PredefinedActionParameterSchema>;

interface Props {
    parameter: ActionParameterExpression;
    onUpdate: (updatedParameter: BaseActionParameter) => void;
    onClose: () => void;
}

const ProjectIntegrationActionEditPredefinedParameterModal: React.FC<Props> = ({ parameter, onUpdate, onClose }) => {
    const initialValues = useMemo(() => {
        return {
            isRequired: parameter.isRequired || false,
            description: parameter.description || '',
            displayName: parameter.displayName,
        };
    }, [parameter.description, parameter.displayName, parameter.isRequired]);

    const onSubmit = useCallback(
        (values: PredefinedActionParameterSchemaType) => {
            const updatedParam: BaseActionParameter = {
                ...parameter,
                displayName: values.displayName,
                description: values.description,
                isRequired: values.isRequired,
            } as ActionParameterExpression;

            onUpdate(updatedParam);
            onClose();
        },
        [onClose, onUpdate, parameter],
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={PredefinedActionParameterSchema}
            enableReinitialize
        >
            <ModalForm>
                <StyledModalHeader $padding={14} $border>
                    <EditableTitle name="displayName" maxLength={500} fontSize={FontSize.LARGE_16} />
                </StyledModalHeader>

                <ModalBody>
                    <OneLineField label="is required" showLabelInline>
                        <Toggle name="isRequired" onClick={(e) => e.stopPropagation()} size={InputSize.SMALL} />
                    </OneLineField>

                    <Field label="Description" showOptional>
                        <Textarea
                            minRows={3}
                            placeholder="Insert Description"
                            name="description"
                            data-automation="project-integration-action-edit-predefined-parameter-modal-write-description"
                        />
                    </Field>
                </ModalBody>

                <ModalFooterActions saveLabel="Update" saveButtonProps={{ highlighted: true }} onCancel={onClose} />
            </ModalForm>
        </Formik>
    );
};

export default withModal(ProjectIntegrationActionEditPredefinedParameterModal, {
    size: ModalSize.SMEDIUM,
    fixedWidth: true,
});
