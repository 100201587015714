import type { FormikContextType } from 'formik';
import type { FormikErrors, FormikTouched } from 'formik/dist/types';
import React from 'react';

const FormikHelpersContext = React.createContext<
    | {
          getFieldValue<FieldValueType>(field: string): FieldValueType;
          getFieldError<FieldValueType>(field: string): FormikErrors<{ field: FieldValueType }>['field'];
          getFieldTouched<FieldValueType>(field: string): FormikTouched<{ field: FieldValueType }>['field'];
          getFormik<FormikValuesType>(): FormikContextType<FormikValuesType>;
          addValueWatcher(field: string, watcher: () => void): () => void;
          addErrorWatcher(field: string, watcher: () => void): () => void;
          addTouchedWatcher(field: string, watcher: () => void): () => void;
      }
    | undefined
>(undefined);

export default FormikHelpersContext;
