import Utils from '@tonkean/utils';

/**
 * Returns the style of the field based on the range that applied on it.
 */
export function getFieldRangeAppliedStyle(isImportant: boolean, appliedRangeColor: string) {
    // Use property-specific transitions so other properties wouldn't transition
    const transition = 'background-color 1s ease, color 1s ease';

    const style = {
        color: appliedRangeColor,
        transition,
        '-webkit-transition': transition,
        '-moz-transition': transition,
        '-ms-transition': transition,
        '-o-transition': transition,
    };

    // add background color if important
    let emphasizedColor = 'transparent'; // Default background color

    if (isImportant) {
        const rgb = Utils.hexToRgb(appliedRangeColor);
        emphasizedColor = `rgba(${rgb!.join(',')},0.06)`;
    }

    style['background-color'] = emphasizedColor;
    style['border-radius'] = '4px';

    // React treats the style json differently so we build a different json for it.
    const reactStyle = {
        color: appliedRangeColor,
        transition,
        WebkitTransition: transition,
        MozTransition: transition,
        MsTransition: transition,
        OTransition: transition,
        backgroundColor: emphasizedColor,
        borderRadius: '4px',
    };

    return {
        style,
        reactStyle,
    };
}
