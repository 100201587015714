import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import { useCallback, useMemo } from 'react';

import type { UniqueQueryKeyType } from './useTonkeanQuery';

interface CachedDataKeys {
    queryUniqueKey: string;
    propsUniqueKeysArray: UniqueQueryKeyType[];
}

type SetCachedDataProps<DataType> = CachedDataKeys & {
    updatedData: DataType;
    ignoreKey?: CachedDataKeys;
};

type FilteredSetCachedDataProps<DataType> = SetCachedDataProps<DataType> & {
    filterFn: (data: DataType) => boolean;
};

const useQueryClientAdapter = () => {
    const queryClient = useQueryClient();

    const getCachedData = useCallback(
        <DataType>({ queryUniqueKey, propsUniqueKeysArray }: CachedDataKeys) => {
            return queryClient.getQueryData<DataType>([queryUniqueKey, ...propsUniqueKeysArray]);
        },
        [queryClient],
    );

    const setCachedData = useCallback(
        <DataType>({ queryUniqueKey, propsUniqueKeysArray, updatedData, ignoreKey }: SetCachedDataProps<DataType>) => {
            if (
                ignoreKey &&
                queryUniqueKey === ignoreKey.queryUniqueKey &&
                isEqual(propsUniqueKeysArray, ignoreKey.propsUniqueKeysArray)
            ) {
                return;
            }

            queryClient.setQueryData([queryUniqueKey, ...propsUniqueKeysArray], updatedData);
        },
        [queryClient],
    );

    /**
     * Search for all cached data that matches keys partially and a filter on the data.
     * For example: given an updated workflowVersion, update cache for all getWorkflowVersionByInititariveId
     * queries for which ther result data has the same workflowVersion id.
     *
     */
    const setCachedDataByPartialPropsKeys = useCallback(
        <DataType>({
            queryUniqueKey,
            propsUniqueKeysArray,
            filterFn,
            updatedData,
            ignoreKey,
        }: FilteredSetCachedDataProps<DataType>) => {
            const allCachedQueries: [queryKey: QueryKey, data: DataType | undefined][] = queryClient.getQueriesData({
                queryKey: [queryUniqueKey, ...propsUniqueKeysArray],
            });

            for (const [queryKey, cachedData] of allCachedQueries) {
                if (
                    ignoreKey &&
                    queryUniqueKey === ignoreKey.queryUniqueKey &&
                    isEqual(propsUniqueKeysArray, ignoreKey.propsUniqueKeysArray)
                ) {
                    continue;
                }

                if (cachedData && filterFn(cachedData)) {
                    queryClient.setQueryData(queryKey, updatedData);
                }
            }
        },
        [queryClient],
    );

    return useMemo(
        () => ({ getCachedData, setCachedData, setCachedDataByPartialPropsKeys }),
        [getCachedData, setCachedData, setCachedDataByPartialPropsKeys],
    );
};

export default useQueryClientAdapter;
