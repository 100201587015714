import React, { useMemo, useState } from 'react';

import type HistoryField from '../../../entities/HistoryField';
import HistoryFieldsTable from '../../HistoryFieldsTable/HistoryFieldsTable';
import HistoryFieldsTableFilters, {
    HistoryFieldsTableFiltersCheckbox,
    HistoryFieldsTableFiltersHeader,
    HistoryFieldsTableFiltersSearchBox,
} from '../../HistoryFieldsTable/HistoryFieldsTableFilters';

import type { WorkerRun } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

interface Props {
    workerRun?: WorkerRun;
    isError: boolean;
    isLoading: boolean;
    fieldNameToDisplayNameMap?: Record<string, string>;
    fieldNameToFieldTypeMap?: Record<string, FieldType>;
    projectIntegration?: any;
}

const SingleWorkerRunDataSourceTable: React.FC<Props> = ({
    workerRun,
    isError,
    isLoading,
    fieldNameToDisplayNameMap,
    fieldNameToFieldTypeMap,
    projectIntegration,
}) => {
    const [filterOnlyEmptyValue, setFilterOnlyEmptyValue] = useState(false);
    const [freeTextFilter, setFreeTextFilter] = useState('');

    const initialFieldIdentifierToValueInformationMap =
        workerRun?.initialExternalActivityFieldsState?.fieldIdentifierToValueInformationMap;
    const finalFieldIdentifierToValueInformationMap =
        workerRun?.finalExternalActivityFieldsStateOptional?.fieldIdentifierToValueInformationMap;
    const couldHaveChanged = initialFieldIdentifierToValueInformationMap && finalFieldIdentifierToValueInformationMap;

    const tableFields: HistoryField[] = useMemo(() => {
        // If isLoading and isError are false, we should be sure that worker run, project integration, etc are not undefined,
        // but for the type inference, I added them to the if.
        if (isError || isLoading || !workerRun || !projectIntegration || !fieldNameToDisplayNameMap) {
            return [];
        }

        const externalType = workerRun?.externalType || workerRun?.externalActivity?.externalType;

        const matchedEntityField: HistoryField = {
            id: 'matchedEntity',
            type: FieldType.String,
            displayName: `${projectIntegration.displayName} ${externalType}`,
            integrationType: workerRun.externalActivity?.integrationType,
            isMatchedEntity: true,
            isSpecialField: false,
            isFormulaField: false,
            isGlobalField: false,
            isSystemUtilized: false,
            relationFieldId: externalType,
            projectIntegrationId: workerRun.projectIntegrationId,
            value: {
                hasChanged: false,
                isEmpty: false,
            },
        };

        const fields = Object.entries(fieldNameToDisplayNameMap).map(([name, displayName]) => {
            const initialValue = initialFieldIdentifierToValueInformationMap?.[name]?.fieldValues
                .map((fieldValue) => fieldValue.value)
                .join(', ');
            const finalValue = finalFieldIdentifierToValueInformationMap?.[name]?.fieldValues
                .map((fieldValue) => fieldValue.value)
                .join(', ');

            return {
                displayName,
                id: name,
                type: fieldNameToFieldTypeMap?.[name] ?? FieldType.String,
                integrationType: workerRun.externalActivity?.integrationType,
                isMatchedEntity: false,
                isSpecialField: false,
                isFormulaField: false,
                isGlobalField: false,
                isSystemUtilized: false,
                relationFieldId: externalType,
                projectIntegrationId: workerRun.projectIntegrationId,
                value: {
                    initialValue,
                    finalValue,
                    hasChanged: couldHaveChanged ? initialValue !== finalValue : false,
                    isEmpty: couldHaveChanged ? !initialValue || !finalValue : !initialValue && !finalValue,
                },
            };
        });
        return [matchedEntityField, ...fields];
    }, [
        couldHaveChanged,
        fieldNameToDisplayNameMap,
        fieldNameToFieldTypeMap,
        finalFieldIdentifierToValueInformationMap,
        initialFieldIdentifierToValueInformationMap,
        isError,
        isLoading,
        projectIntegration,
        workerRun,
    ]);

    const emptyFieldsCount = useMemo(() => {
        return tableFields
            .filter((field) => !field.isMatchedEntity)
            .reduce((count, field) => count + Number(field.value.isEmpty), 0);
    }, [tableFields]);

    const loading = !workerRun || (!fieldNameToDisplayNameMap && !isError);

    return (
        <>
            <HistoryFieldsTableFilters paddingTop>
                <HistoryFieldsTableFiltersHeader big>Module Item Fields</HistoryFieldsTableFiltersHeader>

                <HistoryFieldsTableFiltersCheckbox
                    checked={filterOnlyEmptyValue}
                    onChange={(e) => setFilterOnlyEmptyValue(e.target.checked)}
                    disabled={loading}
                >
                    View empty fields {!loading && `(${emptyFieldsCount})`}
                </HistoryFieldsTableFiltersCheckbox>

                <HistoryFieldsTableFiltersSearchBox
                    value={freeTextFilter}
                    onChange={(e) => setFreeTextFilter(e.target.value)}
                />
            </HistoryFieldsTableFilters>

            <HistoryFieldsTable
                couldHaveChanged={couldHaveChanged}
                tableInError={isError}
                tableLoading={loading}
                fields={tableFields}
                workflowVersionId={workerRun?.workflowVersionId}
                freeTextFilter={freeTextFilter}
                filterOnlyEmptyValue={filterOnlyEmptyValue}
            />
        </>
    );
};

export default SingleWorkerRunDataSourceTable;
