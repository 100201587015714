<div>
    <!-- Saving to... -->
    <div class="text-center" ng-if="data.saving">
        <h4>
            <span class="loading"></span>
            Saving...
        </h4>
    </div>

    <form name="data.integrationForm" ng-hide="data.saving" novalidate ng-submit="ok()">
        <div class="modal-body">
            <button ng-if="data.integration.isMock" class="btn btn-default" ng-click="changeUser()">Set up</button>

            <!-- Error -->
            <div class="alert alert-danger" ng-show="data.error">{{ data.error | error }}</div>

            <!-- Authenticate -->
            <div ng-if="!data.integration && !data.connecting">
                <div class="text-center">
                    <tnk-google-authentication
                        data-automation="google-sheet-selector-template-authenticate"
                        google-integration-type="data.integrationType"
                        authenticate="authorize"
                        initial-google-authentication-type="data.authenticationType"
                        initial-oauth-permission-type="data.integrationPermissions"
                        disable-other-google-authentication-types="data.disableOtherGoogleAuthenticationTypes"
                    ></tnk-google-authentication>
                </div>
            </div>

            <!-- Connecting to... -->
            <h4 class="flex-vmiddle mod-justify-center" ng-if="data.connecting">
                <span class="loading"></span>
                <span class="margin-left-xs">Connecting to Google Sheets</span>
            </h4>

            <div ng-if="data.integration && !data.connecting">
                <div ng-switch="data.googleSheetsStep">
                    <!-- Select Spreadsheet -->
                    <div ng-switch-when="selectSpreadSheet">
                        <div class="alert alert-info flex-vmiddle">
                            <div ng-if="!data.isInitFromSharedCredentials">
                                <a
                                    href="#"
                                    analytics-on="click"
                                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                    analytics-event="Change Auth"
                                    analytics-label="{{ currentIntegration.name }}"
                                    ng-click="changeUser()"
                                >
                                    Switch account
                                </a>

                                <div
                                    class="integration-group-header-connected"
                                    ng-if="integration && !data.isInitFromSharedCredentials"
                                >
                                    Authenticated
                                    <span
                                        ng-show="integration.integrationUserDisplayName || integration.integrationUser"
                                    >
                                        as
                                        <span class="integration-group-header-connected-username">
                                            {{
                                                data.integration.integrationUserDisplayName ||
                                                    data.integration.integrationUser
                                            }}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div ng-if="data.isInitFromSharedCredentials">
                                You're connected with a predefined connection.
                            </div>
                        </div>

                        <div class="flex-vmiddle">
                            <b class="flex-grow">Select a Spreadsheet:</b>

                            <div class="flex-no-shrink">
                                <label class="sr-only" for="spreadsheetSearch">Search</label>
                                <input
                                    type="search"
                                    class="form-control"
                                    id="spreadsheetSearch"
                                    placeholder="Search"
                                    ng-change="onSearchChanged(spreadsheetSearch)"
                                    ng-model="spreadsheetSearch"
                                />
                            </div>
                        </div>
                        <div class="integration-instance-selection-box margin-top">
                            <div ng-if="loadingExternal" class="text-center margin-top-lg">
                                <i class="loading-medium"></i>
                            </div>
                            <div
                                ng-click="onSpreadSheetSelected(selection)"
                                ng-repeat="selection in data.spreadsheets"
                                class="list-item btn-default pointer common-size-xxs"
                                data-automation="google-sheet-selector-template-spreadsheet"
                            >
                                <strong>{{ selection.displayName }}</strong>
                                <div class="common-size-xxxxs common-color-grey">
                                    <span>{{ selection.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Setup Spreadsheet -->
                    <div ng-switch-when="setupSpreadSheet">
                        <!-- Change spreadsheet-->
                        <div>
                            Selected Spreadsheet:
                            <span class="google-sheets-info-title">{{ data.spreadsheet.displayName }}</span>
                            <span ng-if="data.loadingSpreadsheetOptions" class="loading-small"></span>
                        </div>

                        <div>
                            <span>Current Permissions:</span>
                            <span class="google-sheets-info-title">
                                {{ data.integrationPermissions === 'readonly' ? 'Read only' : 'Read & Write' }}
                            </span>
                        </div>

                        <div class="google-sheets-selector-title">Set Data Structure</div>

                        <div class="google-sheets-fields-wrapper">
                            <!--Select sheet option-->
                            <div class="flex-vmiddle margin-normal-sm-v">
                                <div
                                    class="
                                        col-sm-5
                                        common-size-xxs common-text-right-no-xs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="google-sheets-inner-block-name-titles">Select worksheet</div>
                                </div>
                                <div class="col-sm-7">
                                    <ui-select
                                        theme="bootstrap"
                                        ng-model="data.sheet"
                                        class="sheet-name-select"
                                        ng-if="!data.projectIntegration"
                                        ng-disabled="!data.sheets"
                                    >
                                        <ui-select-match
                                            class="ui-select-match-ellipsis-inside"
                                            tooltip-enable="{{
                                                utils.hasEllipsis(
                                                    '.sheet-name-select .ui-select-match-ellipsis-inside .ui-select-match-text > span'
                                                )
                                            }}"
                                            uib-tooltip="{{ $select.selected.displayName }}"
                                            placeholder="Choose a Sheet"
                                        >
                                            {{ $select.selected.displayName }}
                                        </ui-select-match>
                                        <ui-select-choices
                                            ng-click="onSheetChanged(data.sheet)"
                                            repeat="sheet in data.sheets | filter:$select.search"
                                        >
                                            <div ng-bind-html="sheet.displayName | escapeHtml | highlight: $select.search"></div>
                                        </ui-select-choices>
                                    </ui-select>

                                    <div ng-if="data.projectIntegration">{{ data.sheet.displayName }}</div>
                                </div>
                            </div>

                            <!-- Header row selection -->
                            <div class="flex-vmiddle margin-top-lg flex-xs-wrap">
                                <div
                                    class="
                                        col-sm-5
                                        common-size-xxs common-text-right-no-xs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="google-sheets-inner-block-name-titles">Header row</div>
                                    <div class="common-size-xxxxs common-color-grey margin-left-xs hidden">
                                        Optional
                                    </div>
                                </div>

                                <!-- Input and explanation -->
                                <div class="col-sm-7">
                                    <input
                                        type="number"
                                        string-to-number
                                        ng-model="data.configSelections.headerRow"
                                        class="
                                            form-control
                                            ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched
                                        "
                                        autocomplete="off"
                                        min="1"
                                    />
                                </div>
                            </div>

                            <!-- Starting row selection -->
                            <div class="flex-vmiddle margin-top-lg flex-xs-wrap margin-bottom">
                                <div
                                    class="
                                        col-sm-5
                                        common-size-xxs common-text-right-no-xs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="google-sheets-inner-block-name-titles">Start from row</div>
                                    <div class="common-size-xxxxs common-color-grey margin-left-xs hidden">
                                        Optional
                                    </div>
                                </div>

                                <!-- Input and explanation -->
                                <div class="col-sm-7">
                                    <input
                                        type="number"
                                        string-to-number
                                        ng-model="data.configSelections.startFromRow"
                                        ng-change="checkRowsCountValid()"
                                        class="
                                            form-control
                                            ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched
                                        "
                                        autocomplete="off"
                                        min="1"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="google-sheets-selector-title">Map Columns to Item Fields</div>

                        <div class="google-sheets-fields-wrapper">
                            <!-- Id Field path -->
                            <div
                                class="flex mod-align-start margin-top flex-xs-wrap"
                                ng-if="shouldShowUniqueIdentifier()"
                            >
                                <div
                                    class="
                                        col-sm-5
                                        common-size-xxs common-text-right-no-xs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="google-sheets-inner-block-name-titles">Unique Identifier*</div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-7">
                                    <div class="relative">
                                        <ui-select
                                            theme="bootstrap"
                                            ng-model="data.configSelections.idFieldColumn"
                                            ng-disabled="shouldDisableUniqueIdentifier()"
                                            required
                                        >
                                            <ui-select-match
                                                uib-tooltip="Unique identifier can not be changed"
                                                tooltip-enable="shouldDisableUniqueIdentifier()"
                                                class="common-height-full {{
                                                    data.configSelections.idFieldColumn.example
                                                        ? 'ui-select-with-example'
                                                        : ''
                                                }}"
                                                placeholder="Choose a column"
                                                data-automation="google-sheet-selector-template-choose-unique-identifier-columns"
                                            >
                                                <div>{{ $select.selected.displayName }}</div>
                                                <div
                                                    ng-if="data.configSelections.idFieldColumn.example"
                                                    class="common-size-xxxxxs common-color-grey common-ellipsis"
                                                >
                                                    <i>EX: {{ data.configSelections.idFieldColumn.example }}</i>
                                                </div>
                                            </ui-select-match>
                                            <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                                <div
                                                   data-automation="google-sheet-selector-template-choose-unique-identifier-column" ng-bind-html="column.displayName | highlight: $select.search"
                                                ></div>
                                                <div
                                                    class="common-size-xxxxxs common-color-grey"
                                                    ng-if="column.example && column.example !== ''"
                                                >
                                                    <i>EX: {{ column.example }}</i>
                                                </div>
                                            </ui-select-choices>
                                        </ui-select>
                                        <!-- Field inspector button. -->
                                        <span
                                            class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                            ng-class="{ 'cursor-disable': shouldDisableUniqueIdentifier() }"
                                            ng-click="openFieldInspectModal('idFieldColumn',shouldDisableUniqueIdentifier() )"
                                        >
                                            <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Title Field path -->
                            <div class="flex-vmiddle margin-top flex-xs-wrap margin-bottom">
                                <!-- Title -->
                                <div
                                    class="
                                        col-sm-5
                                        common-size-xxs common-text-right-no-xs
                                        padding-right-none
                                        margin-top-xs margin-bottom-xs
                                        flex-xs
                                        mod-align-center
                                    "
                                >
                                    <div class="google-sheets-inner-block-name-titles">Item display name*</div>
                                </div>
                                <!-- Input and explanation -->
                                <div class="col-sm-7">
                                    <div class="relative">
                                        <ui-select
                                            theme="bootstrap"
                                            ng-model="data.configSelections.titleFieldColumn"
                                            required
                                            ng-disabled="!data.columns"
                                        >
                                            <ui-select-match
                                                class="common-height-full {{
                                                    data.configSelections.titleFieldColumn.example
                                                        ? 'ui-select-with-example'
                                                        : ''
                                                }}"
                                                placeholder="Choose a column"
                                                data-automation="google-sheet-selector-template-choose-item-display-name-columns"
                                            >
                                                <div>{{ $select.selected.displayName }}</div>
                                                <div
                                                    ng-if="data.configSelections.titleFieldColumn.example"
                                                    class="common-size-xxxxxs common-color-grey common-ellipsis"
                                                >
                                                    <i>EX: {{ data.configSelections.titleFieldColumn.example }}</i>
                                                </div>
                                            </ui-select-match>
                                            <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                                <div
                                                   data-automation="google-sheet-selector-template-item-display-column" ng-bind-html="column.displayName | highlight: $select.search"
                                                ></div>
                                                <div
                                                    class="common-size-xxxxxs common-color-grey"
                                                    ng-if="column.example && column.example !== ''"
                                                >
                                                    <i>EX: {{ column.example }}</i>
                                                </div>
                                            </ui-select-choices>
                                        </ui-select>
                                        <!-- Field inspector button. -->
                                        <span
                                            class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                            ng-click="openFieldInspectModal('titleFieldColumn')"
                                        >
                                            <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Explainer text -->
                            <hr class="margin-normal-sm-v" ng-if="shouldShowUniqueIdentifier()" />
                            <div
                                class="common-size-xxxxs margin-top-xxs common-color-grey google-sheets-comment"
                                ng-if="shouldShowUniqueIdentifier()"
                            >   <br/>
                                <b>Important:</b> A column with a unique identifier (ID) must be selected from your spreadsheet. The value in the column is used by Tonkean to recognize if a record has changed and must satisfy two conditions:
                                <br/>  
                                <p>
                                    <ul>
                                        <li><b>Uniqueness:</b> The values in the selected ID column should identify each row uniquely and should not contain any duplicate values. Data collection from the spreadsheet will be aborted if multiple rows with the same ID are discovered. The Unique ID field is limited to 256 characters</li>
                                        <br/>
                                        <li><b>Constant:</b> Once an ID is assigned to a given record, it must not be modified. If the ID for a given record is modified it will be considered by Tonkean as a new record and will retrigger automation flows for the record</li>
                                    </ul>
                                </p>
                                <br />
                                <p style="font-weight: bold">The column you select as a unique identifier is final and cannot be modified later.
                                </p>
                            </div>
                        </div>

                        <div
                            class="google-sheets-error"
                            ng-show="data.rowCountError"
                            ng-if="shouldShowUniqueIdentifier()"
                        >
                            {{ data.rowCountError }}
                        </div>
                        <div class="google-sheets-selector-title" ng-if="shouldShowUniqueIdentifier()">
                            Spreadsheet Usage Method
                        </div>
                        <!-- Usage Method path -->
                        <div class="flex mod-align-start margin-top flex-xs-wrap" ng-if="shouldShowUniqueIdentifier()">
                            <tnk-google-sheet-usage-method
                                on-select="(onUsageMethodSelected)"
                                selected-usage-method="data.configSelections.spreadsheetUsageMethodType"
                                is-edit="data.isEdit"
                                is-crud-disabled="data.isCrudDisabled"
                                is-append-disabled="data.isAppendDisabled"
                            ></tnk-google-sheet-usage-method>
                        </div>
                    </div>

                    <!-- Select Columns -->
                    <div ng-switch-when="selectColumns">
                        <div>
                            <b>What columns do you want to see in Tonkean?</b>
                        </div>
                        <div class="flex-vmiddle margin-top flex-xs-wrap">
                            <div
                                class="
                                    col-sm-1
                                    common-size-xxs common-text-right-no-xs
                                    padding-right
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            ></div>
                            <!-- Title and subtitle -->
                            <div
                                class="
                                    col-sm-4
                                    common-size-xxs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Spreadsheet Column</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="common-bold">Tonkean Field name</div>
                            </div>
                        </div>
                        <div class="integration-instance-selection-box margin-top">
                            <div ng-if="loadingExternal" class="text-center margin-top-lg">
                                <i class="loading-medium"></i>
                            </div>
                            <div
                                ng-repeat="column in data.filteredColumns"
                                ng-if="column.example || data.showAllColumns"
                                ng-click="data.selectedColumnsFlags[column.value] = !data.selectedColumnsFlags[column.value]"
                                class="list-item btn-default pointer common-size-xxs"
                            >
                                <div class="flex-vmiddle margin-top flex-xs-wrap">
                                    <div
                                        class="
                                            col-sm-1
                                            common-size-xxs common-text-right-no-xs
                                            padding-right-none
                                            margin-top-xs margin-bottom-xs
                                            mod-align-center
                                        "
                                    >
                                        <input
                                            type="checkbox"
                                            ng-model="data.selectedColumnsFlags[column.value]"
                                            ng-click="columnSelected($event)"
                                        />
                                    </div>
                                    <!-- Title and subtitle -->
                                    <div
                                        class="
                                            col-sm-4
                                            common-size-xxs
                                            padding-right
                                            margin-top-xs margin-bottom-xs
                                            mod-align-center
                                            common-border-right common-border-left
                                        "
                                    >
                                        <div class="common-bold">Column {{ column.displayName }}</div>
                                        <div ng-if="column.example" class="common-size-xxxxs common-color-grey">
                                            Ex: {{ column.example }}
                                        </div>
                                    </div>
                                    <!-- Input and explanation -->
                                    <div class="">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter column name..."
                                            ng-click="onColumnNameInputClick($event)"
                                            ng-model="data.selectedColumns[column.value]"
                                            ng-required="data.selectedColumnsFlags[column.value]"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-if="!data.showAllColumns"
                                ng-click="toggleShowAllColumns()"
                                class="list-item btn-default pointer common-size-xxs flex-vmiddle mod-center"
                            >
                                <a class="margin-top-xs margin-bottom-xs">Show all columns</a>
                            </div>
                        </div>
                        <div class="margin-top">
                            Select
                            <a ng-click="selectAllColumns()">All</a>
                            &nbsp;\&nbsp;
                            <a ng-click="unselectAllColumns()">None</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-vmiddle">
            <!-- Error -->
            <div class="common-color-danger" ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid">
                You must choose a title and unique identifier
            </div>

            <!-- Placeholder -->
            <div class="flex-grow"></div>

            <!-- Cancel button -->
            <button
                class="google-sheets-close-button margin-right-xs flex-no-shrink"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- OK button -->
            <button
                class="btn btn-round-blue flex-no-shrink"
                data-automation="google-sheet-selector-template-ok-button"
                ng-if="data.sheet"
                ng-disabled="!data.integrationForm.$valid || (data.selections|filter:{loadingBranches:true}:true).length || (!data.configSelections.spreadsheetUsageMethodType)"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="submit"
            >
                OK
            </button>
        </div>
    </form>
</div>
