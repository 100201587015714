import { useAngularService, useAngularWatch } from 'angulareact';

import type { FullProject } from '@tonkean/tonkean-entities';

/**
 * Hook for getting the value of a feature flag by name
 * @param featureName
 * @param features the features object to use, if not provided will take the default one
 */
function useFeatureFlag<T = boolean>(featureName: string, features?: Pick<FullProject, 'features'>): T | undefined {
    const $rootScope = useAngularService('$rootScope');
    const [defaultProjectFeatureValue] = useAngularWatch(
        () => ($rootScope.features as any).currentProject?.[featureName],
    );
    return features?.[featureName] || defaultProjectFeatureValue;
}

export default useFeatureFlag;
