import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function DropdownSearchCtrl($scope, utils) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        items: ctrl.items,
        closedPlaceholder: ctrl.closedPlaceholder,
        selectedLabel: ctrl.selectedLabel,
        placeholder: ctrl.placeholder,
        labelClasses: ctrl.labelClasses,
        selectedItem: ctrl.selectedItem,
        enableClear: ctrl.enableClear,
        itemsDisplayNameProperty: ctrl.itemsDisplayNameProperty,
        hideDropdownChevron: ctrl.hideDropdownChevron,
        doNotSaveSelectedItem: ctrl.doNotSaveSelectedItem,
        viewOnly: ctrl.viewOnly,

        searchText: '',
        currentlySelectedItem: null,
    };

    // Free text search item
    // This is important. We must not instantiate a new object every time the user searches for a field, and use this reference and change its
    // content. If we were to initiate a new reference each time the user searches for a field, we would end up in an infinite digest loop.
    const searchedItem = { name: null };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        searchedItem[$scope.data.itemsDisplayNameProperty] = null;

        // Create a backup of the field options, since they get changed if the user is allowed to add a custom value.
        $scope.data.originalItems = [...$scope.data.items];
        $scope.getOptions($scope.data.searchText);
        if (ctrl.selectedItem) {
            $scope.onItemSelected(ctrl.selectedItem);
        }
    };

    /**
     * If the given search query is not found in the data.fieldOptions array, the query itself becomes an item
     * in the array if allowCustomInput is enabled. Otherwise the supported entities array is returned.
     * @param searchQuery - the user's current search query.
     * @returns {*} - an array of supported entities.
     */
    $scope.getOptions = function (searchQuery) {
        // To avoid infinite $digest loops, we always work with the same reference - the $scope.data.fieldOptions array.
        const items = $scope.data.items;

        utils.resetArrayValues(items, $scope.data.originalItems);

        if (ctrl.allowCustomInput && searchQuery) {
            const searchQueryLower = searchQuery.toLowerCase();
            const searchMatches = items.filter(function (item) {
                return (
                    item[$scope.data.itemsDisplayNameProperty] &&
                    item[$scope.data.itemsDisplayNameProperty].toLowerCase() === searchQueryLower
                );
            });

            // If the search query is not found in the given entities, add the search query as an option and return it.
            if (!searchMatches || !searchMatches.length) {
                // This is important that we change the existing object and not create a new object. Please see the documentation of searchedFieldItem for elaboration.
                searchedItem.name = searchQuery;
                searchedItem[$scope.data.itemsDisplayNameProperty] = searchQuery;

                items.unshift(searchedItem);
            }
        }

        // The search query is contained in our field options or the custom input feature is not enabled. Just return the field options.
        return items;
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        if (changes.items) {
            $scope.data.items = ctrl.items;
            $scope.data.originalItems = [...$scope.data.items];
        }

        if (changes.selectedLabel) {
            $scope.data.selectedLabel = ctrl.selectedLabel;
        }

        if (changes.selectedItem && changes.selectedItem.currentValue) {
            $scope.data.selectedItem = ctrl.selectedItem;
            $scope.onItemSelected(ctrl.selectedItem);
        }
    };

    /**
     * Occurs once an item from the dropdown menu is selected.
     */
    $scope.onItemSelected = function (selectedItem) {
        // Setting the selected item in our scope.
        if (!$scope.data.doNotSaveSelectedItem) {
            $scope.data.currentlySelectedItem = selectedItem;
        }

        // Firing the onItemSelected to update users of the component an item has been selected.
        if (ctrl.onItemSelected) {
            ctrl.onItemSelected({ selectedItem });
        }
    };

    /**
     * Occurs once the search text box is clicked. It is used to prevent the dropdown from closing due to the user's click once you try to search..
     */
    $scope.searchBoxClicked = function (event) {
        event.stopPropagation();
    };

    $scope.searchChanged = function () {
        $scope.getOptions($scope.data.searchText);
    };
}

angular.module('tonkean.app').controller('DropdownSearchCtrl', lateConstructController(DropdownSearchCtrl));
