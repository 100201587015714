import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from '../../../../images/icons/plus-o.svg';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { XCloseButton } from '@tonkean/infrastructure';
import { ConnectionPermission } from '@tonkean/tonkean-entities';
import type { SharedCredential } from '@tonkean/tonkean-entities';
import type { Integration, IntegrationType, ProjectIntegration } from '@tonkean/tonkean-entities';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Title = styled.div`
    font-weight: 500;
    font-size: ${FontSize.XXLARGE_20};
    margin-bottom: 8px;
    color: ${Theme.colors.gray_700};
    display: flex;
`;

const Description = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 15px;
`;

const SubTitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: ${FontSize.LARGE_16};
    margin-bottom: 10px;
`;

const SharedCredentialName = styled.div`
    font-weight: 400;
    font-size: ${FontSize.MEDIUM_14};
    margin-left: 8px;
`;

const StyledClickable = styled(Clickable)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    border-bottom: 1px solid ${Theme.colors.gray_300};

    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

const BodyWrapper = styled.div`
    max-height: 92%;
    overflow: auto;
`;

const Wrapper = styled.div`
    height: 460px;
    padding: 25px;
`;

const SharedCredentialsWrapper = styled.div`
    height: 80%;
    overflow: auto;
`;

const ProjectIntegrationIconWrapper = styled.div`
    margin-left: 5px;
`;

const StyledXCloseButton = styled(XCloseButton)`
    margin-left: auto;
`;

const StyledPlusIcon = styled(PlusIcon)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 5px;
`;

interface Props {
    integration: Partial<Integration>;
    integrationType: IntegrationType;
    closeModal: () => void;
    setUp: (
        integration: Partial<Integration>,
        projectIntegration?: ProjectIntegration,
        ignoreState?: boolean,
    ) => { result: Promise<any> };
}

const CreateNewProjectIntegrationModal: React.FC<Props> = ({ integrationType, integration, closeModal, setUp }) => {
    const project = useProject();

    const integrations = useAngularService('integrations');
    const authenticationService = useAngularService('authenticationService');

    const [currentDataSourceConnection, setCurrentDataSourceConnection] = useState<DataSourceConnection>();

    const { data: dataSourceConnectionResponse } = useTonkeanService('getDataSourceConnections', project.id);

    const { data: userGroupsIdsResponse } = useTonkeanService(
        'getGroupsByUserId',
        project.id,
        authenticationService.getCurrentUser().id,
    );

    useEffect(() => {
        if (dataSourceConnectionResponse) {
            const relevantDataSourceConnection = dataSourceConnectionResponse?.entities.find(
                (entity) => entity.dataSourceType === integrationType,
            );
            if (relevantDataSourceConnection) {
                setCurrentDataSourceConnection(relevantDataSourceConnection);
            }
        }
    }, [dataSourceConnectionResponse, integrationType]);

    const isAllowedToCreateNewConnection = useMemo(() => {
        if (currentDataSourceConnection) {
            return !currentDataSourceConnection.enabled || !currentDataSourceConnection.allowOnlySharedCredentials;
        }
        return true;
    }, [currentDataSourceConnection]);

    const isAllowedToUseSharedCredentials =
        currentDataSourceConnection &&
        currentDataSourceConnection?.sharedCredentials?.length > 0 &&
        currentDataSourceConnection?.enabled;

    // Flat the SharedCredential from the dataSourceConnectionResponse and filter all the allowed sharedCredential.
    const currentUserId = authenticationService.getCurrentUser().id;
    const sharedCredentials: SharedCredential[] = useMemo(() => {
        return (
            dataSourceConnectionResponse?.entities
                .filter((entity) => entity.dataSourceType === integrationType)
                .flatMap((entity) => entity.sharedCredentials)
                .filter(
                    (sharedCredential) =>
                        sharedCredential.connectionPermission === ConnectionPermission.ALL_MEMBERS ||
                        (sharedCredential.connectionPermission === ConnectionPermission.SPECIFIC_MEMBERS &&
                            (sharedCredential.authorizedUsers.includes(currentUserId) ||
                                userGroupsIdsResponse?.groupsIds.some((userGroupId) =>
                                    sharedCredential.authorizedUsers.includes(userGroupId),
                                ))),
                )
                .filter((sharedCredential) => sharedCredential.enabled) || []
        );
    }, [currentUserId, dataSourceConnectionResponse?.entities, integrationType, userGroupsIdsResponse]);

    const emitToastMessage = useToastMessage();

    const onCreatingProjectIntegrationFromPredefinedCredential = async (sharedCredential: SharedCredential) => {
        try {
            sharedCredential.integration.modal = integration.modal;
            sharedCredential.integration.name = integration.name;

            await setUp(sharedCredential.integration).result;
            closeModal();
        } catch (error) {
            console.error(error);
            emitToastMessage('Error while trying to connect data source', 'danger');
        }
    };

    const integrationName = integrations.getIntegrationsConfig()[integrationType.toLowerCase()].displayName;

    return (
        <Wrapper>
            <BodyWrapper>
                <Title>
                    New {integrationName} Connection
                    <StyledXCloseButton size={ButtonSize.MEDIUM} onClick={closeModal} />
                </Title>
                <Description>
                    Select one of the predefined connections
                    {isAllowedToCreateNewConnection && ' or create a new custom one'}
                </Description>

                {isAllowedToUseSharedCredentials && (
                    <>
                        <SubTitle>Available Connections:</SubTitle>
                        <SharedCredentialsWrapper>
                            {sharedCredentials.map((sharedCredential) => (
                                <StyledClickable
                                    data-automation="create-new-project-integration-modal-create-shared-credential-integration"
                                    onClick={() =>
                                        onCreatingProjectIntegrationFromPredefinedCredential(sharedCredential)
                                    }
                                    key={sharedCredential.id}
                                >
                                    <ProjectIntegrationIconWrapper>
                                        <ProjectIntegrationIcon integrationType={integrationType} width={24} />
                                    </ProjectIntegrationIconWrapper>
                                    <SharedCredentialName>{sharedCredential.displayName}</SharedCredentialName>
                                </StyledClickable>
                            ))}
                        </SharedCredentialsWrapper>
                    </>
                )}
            </BodyWrapper>

            {isAllowedToCreateNewConnection && (
                <StyledClickable
                    data-automation="create-new-project-integration-modal-create-new-connection"
                    onClick={() => {
                        const modalState = setUp(integration);
                        modalState.result.then(() => {
                            closeModal();
                        });
                    }}
                >
                    <StyledPlusIcon />
                    Create a new connection
                </StyledClickable>
            )}
        </Wrapper>
    );
};

export default CreateNewProjectIntegrationModal;
