<ui-select
    ng-if="!$ctrl.error || $ctrl.items.length"
    ng-class="{ 'mod-loading': $ctrl.loadingExampleEntities }"
    theme="bootstrap"
    ng-model="$ctrl.selectedEntity"
    on-select="$ctrl.onItemSelected($item)"
>
    <ui-select-match placeholder="Search {{ $ctrl.externalTypePluralName }}">
        {{ $ctrl.selectedEntity.title }}
    </ui-select-match>
    <ui-select-choices
        repeat="item in $ctrl.items | filter:{title: $select.search}"
        refresh="$ctrl.onQueryChanged($select.search)"
        refresh-delay="0"
    >
        <div ng-bind-html="item.title | escapeHtml | escapeHtml | highlight: $select.search"></div>
    </ui-select-choices>
</ui-select>

<div class="common-color-danger common-size-xxxs" ng-if="$ctrl.error">Error loading external items.</div>
