import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SearchWidgetCardsDensityConfigurations from './SearchWidgetCardsDensityConfigurations';
import SearchWidgetHeader from './SearchWidgetHeader';
import SearchWidgetItemsList from './SearchWidgetItemsList';
import SearchWidgetLoadingSkeletonState from './SearchWidgetLoadingSkeletonState';
import SearchWidgetNoResultsState from './SearchWidgetNoResultsState';
import SearchWidgetNoSearchState from './SearchWidgetNoSearchState';
import SearchWidgetResultItem from './SearchWidgetResultItem';
import SearchWidgetSelectedOnlyState from './SearchWidgetSelectedOnlyState';
import type { ItemWidgetProps } from '../../../WidgetModule';
import { ItemWidget } from '../../../WidgetModule';
import useSearchWidgetSelectedItems from '../hooks/useSearchWidgetSelectedItems';
import type SearchWidgetConfiguration from '../SearchWidgetConfiguration';
import { SEARCH_WIDGET_DEFAULT_CARDS_DENSITY } from '../SearchWidgetConfiguration';

import {
    Breakpoint,
    FormikAutosave,
    Input,
    LIMIT_PARAM,
    Placeholder,
    SimpleErrorStateMessage,
    SKIP_PARAM,
    useBreakpoint,
    useFetchManager,
    useItemInterfaceContext,
} from '@tonkean/infrastructure';
import { useInitiativeExpressionServerEvaluation } from '@tonkean/infrastructure';
import type { SearchWidgetSearchResponse } from '@tonkean/tonkean-entities';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const LayoutWrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    gap: 16px;
`;

const SearchWidget: React.FC<ItemWidgetProps<SearchWidgetConfiguration>> = ({ widget, permission }) => {
    const { itemInterface, initiative, project } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    const tonkeanService = useAngularService('tonkeanService');
    const [[getSearchWidgetSearch], { data: results, loading: searchLoading, isEmpty }] = useFetchManager(
        tonkeanService,
        'getSearchWidgetSearch',
        {
            limit: 10,
            getItems: (response: SearchWidgetSearchResponse) => response.results,
        },
    );

    const [searchText, setSearchText] = useState('');

    const {
        selectedItems,
        selectedItemsMap,
        searchItemIdToInitiativeId,
        changeItemSelectedState,
        selectedItemsLoading,
        selectedItemsError,
        updatedSelectedItemError,
    } = useSearchWidgetSelectedItems(
        widget.id,
        widget.configuration,
        initiative?.id,
        itemInterface.workflowVersionType,
    );

    const selectedItemsCount = selectedItems.length;
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    useEffect(() => {
        if (selectedItemsCount === 0) {
            setShowOnlySelected(false);
        }
    }, [selectedItemsCount, setShowOnlySelected]);

    const {
        values: [searchInputPlaceholderExpression],
        loading: searchInputPlaceholderExpressionLoading,
    } = useInitiativeExpressionServerEvaluation(
        widget.configuration.searchInputPlaceholder ? [widget.configuration.searchInputPlaceholder] : [],
        initiative,
    );

    const breakpoint = useBreakpoint();
    const isMobile = breakpoint < Breakpoint.SMALL_1366;
    const cardsDensity = widget.configuration.cardsDensity || SEARCH_WIDGET_DEFAULT_CARDS_DENSITY;
    const cardsDensityConfiguration = SearchWidgetCardsDensityConfigurations[cardsDensity];

    return (
        <ItemWidget
            headerActions={
                <SearchWidgetHeader
                    selectedItemsCount={selectedItemsCount}
                    showOnlySelected={showOnlySelected}
                    setShowOnlySelected={setShowOnlySelected}
                    selectedItemsLoading={selectedItemsLoading}
                    selectedItemsError={selectedItemsError}
                    themeColor={themeConfiguration.headerBackgroundColor}
                />
            }
            permission={permission}
            itemWidgetBodyMaxHeight={750}
            disableMaxHeight
            noBackgroundBody
            noBorderBody
            noPaddingBody
        >
            {!showOnlySelected && (
                <LayoutWrapper data-automation="search-widget-all-items-state">
                    <Formik
                        initialValues={{ searchText: '' }}
                        onSubmit={({ searchText: newSearchText }) => {
                            setSearchText(newSearchText);
                            return getSearchWidgetSearch(
                                itemInterface.workflowVersionType,
                                widget.id,
                                newSearchText,
                                SKIP_PARAM,
                                LIMIT_PARAM,
                            );
                        }}
                    >
                        <>
                            <FormikAutosave />
                            {!searchInputPlaceholderExpressionLoading ? (
                                <Input
                                    data-automation="search-widget-search"
                                    name="searchText"
                                    size={InputSize.LARGE}
                                    placeholder={searchInputPlaceholderExpression || 'Search'}
                                />
                            ) : (
                                <Placeholder $width="100%" $height={`${Theme.sizes.input[InputSize.LARGE].height}px`} />
                            )}
                        </>
                    </Formik>

                    {results.length > 0 && (
                        <SearchWidgetItemsList
                            $itemsGap={cardsDensityConfiguration.itemsGap}
                            $cardsDensity={cardsDensity}
                        >
                            {results.map((item) => {
                                return (
                                    <SearchWidgetResultItem
                                        key={item.id}
                                        item={item}
                                        editableResultFieldConfiguration={widget.configuration.editableResultField}
                                        customColor={themeConfiguration.headerBackgroundColor}
                                        selected={!!selectedItemsMap[item.id]}
                                        associatedInitiativeId={searchItemIdToInitiativeId?.[item.id]}
                                        isMobile={isMobile}
                                        cardsDensity={cardsDensity}
                                        imagePlaceholderMode={widget.configuration.imagePlaceholderMode}
                                        imageInputType={widget.configuration.imageInputType}
                                        searchEntityProjectIntegrationId={
                                            widget.configuration.searchEntityProjectIntegrationId
                                        }
                                        imageStreamSelectedActionId={widget.configuration.imageStreamSelectedActionId}
                                        projectId={project.id}
                                        changeItemSelectedState={changeItemSelectedState}
                                    />
                                );
                            })}
                        </SearchWidgetItemsList>
                    )}

                    {!searchLoading.initial && searchText === '' && (
                        <SearchWidgetNoSearchState
                            initiative={initiative}
                            configuration={widget.configuration}
                            themeConfiguration={themeConfiguration}
                        />
                    )}

                    {searchLoading.initial && <SearchWidgetLoadingSkeletonState cardsDensity={cardsDensity} />}

                    {isEmpty && searchText !== '' && <SearchWidgetNoResultsState searchText={searchText} />}

                    {updatedSelectedItemError && (
                        <SimpleErrorStateMessage
                            dataAutomation="search-widget-select-item-error"
                            error={updatedSelectedItemError}
                            showSmallError
                        />
                    )}
                </LayoutWrapper>
            )}

            {showOnlySelected && (
                <SearchWidgetSelectedOnlyState
                    widget={widget}
                    themeConfiguration={themeConfiguration}
                    selectedItems={selectedItems}
                    searchItemIdToInitiativeId={searchItemIdToInitiativeId}
                    isMobile={isMobile}
                    cardsDensity={cardsDensity}
                    changeItemSelectedState={changeItemSelectedState}
                />
            )}
        </ItemWidget>
    );
};

export default SearchWidget;
