import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';
import type { FormEvent } from 'react';

import PardotAccountType, { accountTypeToDisplayName } from './PardotAccountType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Radio } from '@tonkean/infrastructure';
import { RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const PardotAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [accountType, setAccountType] = useState<PardotAccountType>(PardotAccountType.PRODUCTION);
    const [unitId, setUnitId] = useState<string | undefined>();

    const authenticateWithOAuth = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            const code = await oauthHandler.salesforce(
                environment.integrationKeysMap.salesforce,
                environment.integrationKeysMap.salesforceUri,
                accountType,
            );

            const config = {
                code,
                redirectUri: environment.integrationKeysMap.salesforceUri,
                authSubdomain: accountType,
                unitId,
            };

            authenticate(
                config,
                {},
                `${integrationConfiguration.displayName} (${accountTypeToDisplayName[accountType]})`,
            );
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label">Account Type</label>
                    <div className="col-sm-8">
                        <RadioGroup
                            value={accountType}
                            direction="row"
                            onChange={setAccountType}
                            size={InputSize.MEDIUM}
                        >
                            <Radio value={PardotAccountType.SANDBOX}>
                                {accountTypeToDisplayName[PardotAccountType.SANDBOX]} ({PardotAccountType.SANDBOX})
                            </Radio>
                            <Radio value={PardotAccountType.PRODUCTION}>
                                {accountTypeToDisplayName[PardotAccountType.PRODUCTION]} ({PardotAccountType.PRODUCTION}
                                )
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-4 control-label">Pardot Business Unit ID</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            value={unitId}
                            className="form-control"
                            onChange={({ target }) => setUnitId(target.value)}
                            autoComplete="off"
                            placeholder="Unit ID"
                            required
                        />
                    </div>
                </div>

                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={authenticateWithOAuth} disabled={!unitId}>
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PardotAuthenticationComponent;
