import { useMemo } from 'react';

import useCalculatedFiltersQueryDefinition from './useCalculatedFiltersQueryDefinition';
import useEndUserFiltersQueryDefinition from './useEndUserFiltersQueryDefinition';
import type { InnerItemWidgetConfiguration } from '../../entities';

import {
    type BasicQueryDefinition,
    type FieldFilter,
    type Initiative,
    type SearchInitiativesQuery,
    type TonkeanId,
    type WidgetBase,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const useSearchInitiativesQuery = (
    widget: WidgetBase<InnerItemWidgetConfiguration>,
    endUserFilters: FieldFilter[],
    endUserAdvancedQuery: BasicQueryDefinition,
    groupId: TonkeanId | undefined,
    debouncedSearchTerm: string,
    parentInitiative: Initiative | undefined,
    workflowVersionType: WorkflowVersionType | undefined,
): SearchInitiativesQuery => {
    const endUserFiltersQueryDefinition = useEndUserFiltersQueryDefinition(endUserFilters, endUserAdvancedQuery);

    const calculatedFiltersQueryDefinition = useCalculatedFiltersQueryDefinition(endUserFiltersQueryDefinition, widget);

    return useMemo(() => {
        const searchInitiativesQueryObject = {
            conditionsQuery: calculatedFiltersQueryDefinition,
            isArchived: false,
            dateRange: undefined,
            returnFlat: undefined,
            groupId,
            orderByFieldId: widget.configuration.sortByField?.fieldDefinitionId,
            orderByFieldType: widget.configuration.sortByField?.orderByFieldType,
            orderByType: widget.configuration.sortByField?.orderType,
            isDraftInitiatives: workflowVersionType === WorkflowVersionType.DRAFT,
            parentId: widget.configuration.includeInnerItemsFromAllHierarchies ? undefined : parentInitiative?.id,
            anyParentId: parentInitiative?.id,
        };

        if (!debouncedSearchTerm) {
            return { ...searchInitiativesQueryObject, isRootInitiative: !parentInitiative?.id };
        }

        return searchInitiativesQueryObject;
    }, [
        calculatedFiltersQueryDefinition,
        debouncedSearchTerm,
        groupId,
        parentInitiative?.id,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        widget.configuration.sortByField?.fieldDefinitionId,
        widget.configuration.sortByField?.orderByFieldType,
        widget.configuration.sortByField?.orderType,
        workflowVersionType,
    ]);
};

export default useSearchInitiativesQuery;
