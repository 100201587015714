import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SinglePersonSelectorCtrl($scope, utils, personCache) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        paramsObjectForCallbacks: ctrl.paramsObjectForCallbacks,
        selectedPersonId: ctrl.selectedPersonId,
        selectedPersonEmail: ctrl.selectedPersonEmail,
        selectedPeopleArray: [],
        placeholder: !utils.isNullOrEmpty(ctrl.placeholder) ? ctrl.placeholder : null,

        readOnly: ctrl.readOnly,
        hidePlaceholderWhenDisabled: ctrl.hidePlaceholderWhenDisabled,
        hideTagsBackgroundWhenDisabled: ctrl.hideTagsBackgroundWhenDisabled,
        includeExternal: ctrl.includeExternal,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (ctrl.selectedPersonId) {
            getPerson(ctrl.selectedPersonId);
        }
        if (ctrl.selectedPersonEmail) {
            getPersonByEmail(ctrl.selectedPersonEmail);
        }
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedPersonId) {
            $scope.data.selectedPersonId = ctrl.selectedPersonId;

            if (ctrl.selectedPersonId) {
                getPerson(ctrl.selectedPersonId);
            }
        }
    };

    /**
     * Occurs once a person is selected.
     */
    $scope.onPersonAdded = function () {
        if (ctrl.onPersonSelected) {
            ctrl.onPersonSelected({
                person: $scope.data.selectedPeopleArray[0],
                paramsObject: $scope.data.paramsObjectForCallbacks,
            });
        }
        if (ctrl.onPersonSelectedReact) {
            ctrl.onPersonSelectedReact($scope.data.selectedPeopleArray[0]);
        }
    };

    /**
     * Occurs once the selected person is removed.
     */
    $scope.onPersonRemoved = function () {
        if (ctrl.onPersonRemoved) {
            ctrl.onPersonRemoved({
                paramsObject: $scope.data.paramsObjectForCallbacks,
            });
        }
    };

    /**
     * Gets the person by personId and sets it as selected person.
     */
    function getPerson(personId) {
        return personCache.getEntityById(personId, false, true).then((person) => {
            if ($scope.data.selectedPeopleArray.length === 0) {
                $scope.data.selectedPeopleArray.push(person);
            }
        });
    }

    /**
     * Gets the person by email and sets it as selected person.
     */
    function getPersonByEmail(personEmail) {
        return personCache.getEntityByEmail(personEmail, false, true).then((person) => {
            if ($scope.data.selectedPeopleArray.length === 0) {
                $scope.data.selectedPeopleArray.push(person);
            }
        });
    }
}
export default angular.module('tonkean.app').controller('SinglePersonSelectorCtrl', lateConstructController(SinglePersonSelectorCtrl));
