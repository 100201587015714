function generateHeader(headers) {
    if (!headers) {
        return '';
    }
    const headerParam = [];
    Object.keys(headers).map((key) => {
        const headerKey = headers[key].name.evaluatedExpressionValue || headers[key].name.evaluatedExpression;
        const headerVal = headers[key].value.evaluatedExpressionValue || headers[key].value.evaluatedExpression;
        headerParam.push(`-H '${headerKey}: ${headerVal}' \\`);
    });
    return headerParam;
}

function generateBody(body) {
    if (!body) {
        return '';
    }
    return `-d '${body}'`;
}

function generateMethod(method) {
    if (!method) {
        return '';
    }
    const type = {
        GET: '-X GET \\',
        POST: '-X POST \\',
        PUT: '-X PUT \\',
        PATCH: '-X PATCH \\',
        DELETE: '-X DELETE \\',
    };
    const methodParam = type[method.toUpperCase()];
    return methodParam ? methodParam : '';
}

export function curlGenerator(options) {
    let result = 'curl ';
    const headers = generateHeader(options.headers);
    const url = options.url;
    result += `${generateMethod(options.method)}\n`;
    result += `${url}\n`;
    result += `${headers.join('\n')}\n`;
    result += `${options.isHttpUploadAction ? '-d FILE STREAM' : generateBody(options.body)} `;
    return result;
}

export default { curlGenerator };
