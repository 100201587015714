export enum WorkfrontAuthenticationType {
    API_KEY = 'API_KEY',
    CUSTOM_APP = 'CUSTOM_APP',
}

export const authTypeToDisplayName: Record<WorkfrontAuthenticationType, string> = {
    [WorkfrontAuthenticationType.API_KEY]: 'API Key',
    [WorkfrontAuthenticationType.CUSTOM_APP]: 'Custom App',
};
export default WorkfrontAuthenticationType;
