import type { Features } from 'angular';
import type { AngularServices } from 'angulareact';

import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';
import formAnsweredCanCreateInSameModule from '../canCreate/formAnsweredCanCreateInSameModule';
import formAnsweredCanCreateIsSequenceEnd from '../canCreate/formAnsweredCanCreateIsSequenceEnd';
import formAnsweredShowInterfaceTriggerParamsEnricher from '../enrichers/formAnsweredShowInterfaceTriggerParamsEnricher';

import { ItemDetailsIconType } from '@tonkean/tonkean-entities';
import type { LogicImpact } from '@tonkean/tonkean-entities';

const showInterfaceOptionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts: LogicImpact[],
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
    customTriggerManager: AngularServices['customTriggerManager'],
    features: Features,
) => {
    return (
        !configuredLogic.node.disabled &&
        formAnsweredCanCreateInSameModule(configuredLogic, logicBlockConfigsFormInfoRetriever) &&
        formAnsweredCanCreateIsSequenceEnd(configuredLogic)
    );
};

const addShowInterfaceBlockCreateAfterOption: CreateAfterOption = {
    label: 'Show an Interface',
    blockDisplayName: 'Show an Interface',
    triggerParamsEnricher: formAnsweredShowInterfaceTriggerParamsEnricher,
    canCreate: showInterfaceOptionCanCreate,
    iconType: ItemDetailsIconType.INTERFACE,
};
export default addShowInterfaceBlockCreateAfterOption;
