/* eslint-disable */

function IntegrationsConsts($q, tonkeanService, utils, projectManager) {
    let entitiesMetadata = {
        OKTA: {
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
            GROUP: {
                displayName: 'Group',
                entity: 'Group',
                pluralLabel: 'Groups',
            },
        },
        INTERCOM: {
            CONVERSATION: {
                displayName: 'Conversation',
                entity: 'Conversation',
                pluralLabel: 'Conversations',
            },
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
            COMPANY: {
                displayName: 'Company',
                entity: 'Company',
                pluralLabel: 'Companies',
            },
            LEAD: {
                displayName: 'Lead',
                entity: 'Lead',
                pluralLabel: 'Leads',
            },
            DATA_ATTRIBUTE: {
                displayName: 'Data Attribute',
                entity: 'DataAttribute',
                pluralLabel: 'Data Attributes',
            },
        },
        SALESFORCE: {
            CASE: {
                displayName: 'Case',
                entity: 'Case',
                pluralLabel: 'Cases',
            },
            ACCOUNT: {
                displayName: 'Account',
                entity: 'Account',
                pluralLabel: 'Accounts',
            },
            OPPORTUNITY: {
                displayName: 'Opportunity',
                entity: 'Opportunity',
                pluralLabel: 'Opportunities',
            },
            TASK: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
            CAMPAIGN: {
                displayName: 'Campaign',
                entity: 'Campaign',
                pluralLabel: 'Campaigns',
            },
            CONTACT: {
                displayName: 'Contact',
                entity: 'Contact',
                pluralLabel: 'Contacts',
            },
            LEAD: {
                displayName: 'Lead',
                entity: 'Lead',
                pluralLabel: 'Leads',
            },
            CONTENTDOCUMENT: {
                displayName: 'Content Document',
                entity: 'ContentDocument',
                pluralLabel: 'ContentDocuments',
            },
        },
        TWILIOCHAT: {
            CHANNEL: {
                displayName: 'Channel',
                entity: 'Channel',
                pluralLabel: 'Channels',
            },
            TASK: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
        },
        ZENDESK: {
            TICKET: {
                displayName: 'Ticket',
                entity: 'Ticket',
                pluralLabel: 'Tickets',
            },
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
            ATTACHMENT: {
                displayName: 'Attachment',
                entity: 'Attachment',
                pluralLabel: 'Attachments',
            },
        },
        TRELLO: {
            CARD: {
                displayName: 'Card',
                entity: 'Card',
                pluralLabel: 'Cards',
            },
        },
        HUBSPOT: {
            Deal: {
                displayName: 'Deal',
                entity: 'Deal',
                pluralLabel: 'Deals',
            },
        },
        GOOGLEGROUPS: {
            GROUP: {
                displayName: 'Group',
                entity: 'Group',
                pluralLabel: 'Groups',
            },
            MEMBER: {
                displayName: 'Group Member',
                entity: 'GroupMember',
                pluralLabel: 'Group Members',
            },
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
        },
        GOOGLEDRIVE: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
            SHEET: {
                displayName: 'Sheet',
                entity: 'Sheet',
                pluralLabel: 'Sheets',
            },
        },
        GOOGLESHEETS: {
            ROW: {
                displayName: 'Row',
                entity: 'Row',
                pluralLabel: 'Rows',
            },
        },
        FRONTAPP: {
            CONVERSATION: {
                displayName: 'Conversation',
                entity: 'Conversation',
                pluralLabel: 'Conversations',
            },
        },
        GOOGLE_CALENDAR: {
            EVENT: {
                displayName: 'Event',
                entity: 'Event',
                pluralLabel: 'Events',
            },
        },
        GREENHOUSE: {
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
        },
        DROPBOX: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        FRESHDESK: {
            TICKET: {
                displayName: 'Ticket',
                entity: 'Ticket',
                pluralLabel: 'Tickets',
            },
        },
        JIRA: {
            ISSUE: {
                displayName: 'Issue',
                entity: 'Issue',
                pluralLabel: 'Issues',
            },
        },
        NETSUITE: {
            TRANSACTION: {
                displayName: 'Transaction',
                entity: 'Transaction',
                pluralLabel: 'Transactions',
            },
            TRANSACTION_LINE: {
                displayName: 'Transaction Line',
                entity: 'TransactionLine',
                pluralLabel: 'Transaction Lines',
            },
            VENDOR: {
                displayName: 'Vendor',
                entity: 'Vendor',
                pluralLabel: 'Vendors',
            },
        },
        GMAIL: {
            MESSAGE: {
                displayName: 'Message',
                entity: 'Message',
                pluralLabel: 'Messages',
            },
        },
        SLACKAPP: {
            CHANNEL: {
                displayName: 'Channel',
                entity: 'Channel',
                pluralLabel: 'Channels',
            },
            MESSAGE: {
                displayName: 'Message',
                entity: 'Message',
                pluralLabel: 'Messages',
            },
            USER_GROUP: {
                displayName: 'User Group',
                entity: 'UserGroup',
                pluralLabel: 'User Groups',
            },
        },
        ASANA: {
            TASK: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
            PROJECT: {
                displayName: 'Project',
                entity: 'Project',
                pluralLabel: 'Projects',
            },
        },
        DOCUSIGN: {
            ENVELOPE: {
                displayName: 'Envelope',
                entity: 'Envelope',
                pluralLabel: 'Envelopes',
            },
            TEMPLATE: {
                displayName: 'Template',
                entity: 'Template',
                pluralLabel: 'Templates',
            },
        },
        PROSPERWORKS: {
            LEAD: {
                displayName: 'Lead',
                entity: 'Lead',
                pluralLabel: 'Leads',
            },
        },
        TWILIOSMS: {
            SMS: {
                displayName: 'SMS',
                entity: 'SMS',
                pluralLabel: 'SMS',
            },
        },
        MONDAY: {
            ITEM: {
                displayName: 'Item',
                entity: 'Item',
                pluralLabel: 'Items',
            },
            BOARD: {
                displayName: 'Board',
                entity: 'Board',
                pluralLabel: 'Boards',
            },
            Group: {
                displayName: 'Group',
                entity: 'Group',
                pluralLabel: 'Groups',
            },
            Update: {
                displayName: 'Update',
                entity: 'Update',
                pluralLabel: 'Updates',
            },
        },
        OUTLOOK: {
            EVENT: {
                displayName: 'Event',
                entity: 'Event',
                pluralLabel: 'Events',
            },
            MESSAGE: {
                displayName: 'Message',
                entity: 'Message',
                pluralLabel: 'Messages',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        MAVENLINK: {
            PROJECT: {
                displayName: 'Project',
                entity: 'Project',
                pluralLabel: 'Projects',
            },
            TASK: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
            CUSTOM_FIELD: {
                displayName: 'CustomField',
                entity: 'CustomField',
                pluralLabel: 'CustomFields',
            },
        },
        SENDGRID: {
            CONTACT: {
                displayName: 'Contact',
                entity: 'Contact',
                pluralLabel: 'Contacts',
            },
        },
        SMARTSHEET: {
            COLUMN: {
                displayName: 'Column',
                entity: 'Column',
                pluralLabel: 'Columns',
            },
            ROW: {
                displayName: 'Row',
                entity: 'Row',
                pluralLabel: 'Rows',
            },
            ATTACHEMNT: {
                displayName: 'Attachment',
                entity: 'Attachment',
                pluralLabel: 'Attachments',
            },
        },
        SMARTSHEETWORKSPACE: {
            SHEET: {
                displayName: 'Sheet',
                entity: 'Sheet',
                pluralLabel: 'Sheets',
            },
        },
        TEAMCONNECT: {
            MATTER: {
                displayName: 'Matter',
                entity: 'Matter',
                pluralLabel: 'Matters',
            },
        },
        IRONCLAD: {
            WORKFLOW: {
                displayName: 'Workflow',
                entity: 'Workflow',
                pluralLabel: 'Workflows',
            },
            RECORD: {
                displayName: 'Record',
                entity: 'Record',
                pluralLabel: 'Records',
            },
            ATTACHMENT: {
                displayName: 'Attachment',
                entity: 'Attachment',
                pluralLabel: 'Attachments',
            },
        },
        SERVICENOW: {
            INCIDENT: {
                displayName: 'Incident',
                entity: 'Incident',
                pluralLabel: 'Incidents',
            },
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
            ATTACHMENT: {
                displayName: 'Attachment',
                entity: 'Attachment',
                pluralLabel: 'Attachments',
            },
            CATALOG_TASK: {
                displayName: 'Catalog Task',
                entity: 'CatalogTask',
                pluralLabel: 'Catalog Tasks',
            },
            PROJECT: {
                displayName: 'Project',
                entity: 'Project',
                pluralLabel: 'Projects',
            },
            PROJECT_TASK: {
                displayName: 'Project Task',
                entity: 'ProjectTask',
                pluralLabel: 'Project Tasks',
            },
        },
        AMAZONS3: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
        },
        MAILCHIMPTRANSACTIONAL: {
            TEMPLATE: {
                displayName: 'Template',
                entity: 'Template',
                pluralLabel: 'Templates',
            },
        },
        MAILCHIMPMARKETING: {
            AUDIENCE: {
                displayName: 'Audience',
                entity: 'Audience',
                pluralLabel: 'Audiences',
            },
        },
        MARKETO: {
            LEAD: {
                displayName: 'Lead',
                entity: 'Lead',
                pluralLabel: 'Leads',
            },
            EXPORT_JOB: {
                displayName: 'Export Job',
                entity: 'ExportJob',
                pluralLabel: 'Export Jobs',
            },
        },
        GONG: {
            CALL: {
                displayName: 'Call',
                entity: 'Call',
                pluralLabel: 'Calls',
            },
        },
        EXCEL365: {
            SPREADSHEET: {
                displayName: 'Spreadsheet',
                entity: 'Spreadsheet',
                pluralLabel: 'Spreadsheets',
            },
            ROW: {
                displayName: 'Row',
                entity: 'Row',
                pluralLabel: 'Rows',
            },
        },
        SHAREPOINT: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        ONEDRIVE365: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        SPRINGCM: {
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
            DOCUMENT: {
                displayName: 'Document',
                entity: 'Document',
                pluralLabel: 'Documents',
            },
            DOCGENERATIONCONFIG: {
                displayName: 'Document Generation Configuration',
                entity: 'DocumentGenerationConfiguration',
                pluralLabel: 'Document Generation Configurations',
            },
        },
        DATADOG: {
            INCIDENT: {
                displayName: 'Incident',
                entity: 'Incident',
                pluralLabel: 'Incidents',
            },
        },
        CONCUR: {
            REPORT: {
                displayName: 'Report',
                entity: 'Report',
                pluralLabel: 'Reports',
            },
        },
        GOODTIME: {
            CONTACT: {
                displayName: 'Contact',
                entity: 'Contact',
                pluralLabel: 'Contacts',
            },
            TAG: {
                displayName: 'Tag',
                entity: 'Tag',
                pluralLabel: 'Tags',
            },
        },
        BOX: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        MICROSOFTADMIN: {
            USER: {
                displayName: 'User',
                entity: 'User',
                pluralLabel: 'Users',
            },
            GROUP: {
                displayName: 'Group',
                entity: 'Group',
                pluralLabel: 'Groups',
            },
        },
        SHOPIFY: {
            CUSTOMER: {
                displayName: 'Customer',
                entity: 'Customer',
                pluralLabel: 'Customers',
            },
            ORDER: {
                displayName: 'Order',
                entity: 'Order',
                pluralLabel: 'Orders',
            },
            PRODUCT: {
                displayName: 'Product',
                entity: 'Product',
                pluralLabel: 'Products',
            },
            PRODUCTVARIANT: {
                displayName: 'Product Variant',
                entity: 'ProductVariant',
                pluralLabel: 'Product Variants',
            },
        },
        KUSTOMER: {
            CUSTOMER: {
                displayName: 'Customer',
                entity: 'Customer',
                pluralLabel: 'Customers',
            },
            CONVERSATION: {
                displayName: 'Conversation',
                entity: 'Conversation',
                pluralLabel: 'Conversations',
            },
            MESSAGE: {
                displayName: 'Message',
                entity: 'Message',
                pluralLabel: 'Messages',
            },
        },
        LEVER: {
            FEEDBACK: {
                displayName: 'Feedback',
                entity: 'Feedback',
                pluralLabel: 'Feedbacks',
            },
        },
        GOOGLECLOUDSTORAGE: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
        },
        ONEDRIVE: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'File',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folder',
            },
        },
        UIPATH: {
            JOB: {
                displayName: 'Job',
                entity: 'Job',
                pluralLabel: 'Jobs',
            },
        },
        NETDOCUMENTS: {
            MATTER: {
                displayName: 'Matter',
                entity: 'Matter',
                pluralLabel: 'Matters',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
            DOCUMENT: {
                displayName: 'Document',
                entity: 'Document',
                pluralLabel: 'Documents',
            },
        },
        AZUREBLOBSTORAGE: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
        },
        EVISORT: {
            DOCUMENT: {
                displayName: 'Document',
                entity: 'Document',
                pluralLabel: 'Documents',
            },
        },
        SIMPLELEGAL: {
            MATTER: {
                displayName: 'Matter',
                entity: 'Matter',
                pluralLabel: 'Matters',
            },
        },
        GOOGLESLIDES: {
            PRESENTATION: {
                displayName: 'Presentation',
                entity: 'Presentation',
                pluralLabel: 'Presentations',
            },
        },
        WORKFRONT: {
            PROJECT: {
                displayName: 'Project',
                entity: 'Project',
                pluralLabel: 'Projects',
            },
            TASK: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
        },
        DYNAMICS365FO: {
            PROJECT: {
                displayName: 'Project',
                entity: 'Project',
                pluralLabel: 'Projects',
            },
        },
        PAGERDUTY: {
            INCIDENT: {
                displayName: 'Incident',
                entity: 'Incident',
                pluralLabel: 'Incidents',
            },
        },
        CLOUDCONVERT: {
            Job: {
                displayName: 'Job',
                entity: 'Job',
                pluralLabel: 'Jobs',
            },
            Task: {
                displayName: 'Task',
                entity: 'Task',
                pluralLabel: 'Tasks',
            },
        },
        ADOBESIGN: {
            Agreement: {
                displayName: 'Agreement',
                entity: 'Agreement',
                pluralLabel: 'Agreements',
            },
        },
        COUPA: {
            Invoice: {
                displayName: 'Invoice',
                entity: 'Invoice',
                pluralLabel: 'Invoices',
            },
            PurchaseOrder: {
                displayName: 'Purchase Order',
                entity: 'PurchaseOrder',
                pluralLabel: 'Purchase Orders',
            },
            Requisition: {
                displayName: 'Requisition',
                entity: 'Requisition',
                pluralLabel: 'Requisitions',
            },
            Supplier: {
                displayName: 'Supplier',
                entity: 'Supplier',
                pluralLabel: 'Suppliers',
            },
            SupplierInformation: {
                displayName: 'SupplierInformation',
                entity: 'SupplierInformation',
                pluralLabel: 'Suppliers Information',
            },
        },
        UKG: {
            Employee: {
                displayName: 'Employee',
                entity: 'Employee',
                pluralLabel: 'Employees',
            },
            Job: {
                displayName: 'Job',
                entity: 'Job',
                pluralLabel: 'Jobs',
            },
        },
        NEXTCLOUD: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            },
        },
        EMAILWEBHOOK: {
            THREAD: {
                displayName: 'Thread',
                entity: 'Thread',
                pluralLabel: 'Threads',
            },
        },
        SFTP: {
            FILE: {
                displayName: 'File',
                entity: 'File',
                pluralLabel: 'Files',
            },
            FOLDER: {
                displayName: 'Folder',
                entity: 'Folder',
                pluralLabel: 'Folders',
            }
        }
    };

    this.presentationalComponentType = {
        tonkeanExpressionInput: {
            id: 'tonkeanExpressionInput',
        },
    };

    let trelloListAutoCompleteTab = {
        id: 'TRELLO_LISTS',
        label: 'Trello Lists',
        iconClass: 'mod-trello',
        searchPlaceholder: 'Search for a list by name...',
        getFields: (projectIntegration) => {
            let getAutoCompletesPromises = [];
            for (let i = 0; i < projectIntegration.projectData.projectDatas.length; i++) {
                let projectData = projectIntegration.projectData.projectDatas[i];
                let promise = tonkeanService.getAutoCompleteOptions(
                    projectManager.project.id,
                    projectIntegration.integration.id,
                    'lists',
                    {
                        orgId: projectData.organization,
                    },
                );
                getAutoCompletesPromises.push(
                    promise.then((result) => {
                        return result.options;
                    }),
                );
            }

            return $q.all(getAutoCompletesPromises).then((results) => {
                results = results.filter((options) => options.length);
                let allOptions = [];
                for (let i = 0; i < results.length; i++) {
                    let result = results[i];
                    for (let j = 0; j < result.length; j++) {
                        let option = result[j];
                        allOptions.push({
                            name: `${option.displayName} (${option.description})`, // What the user will see in the selector
                            value: option.value, // What will be the actual value
                            source: 'TRELLO', // From which integration this is (for icons)
                            isAutoComplete: true, // Flag this from auto complete so the tonkean expression will know how to handle this "field"
                        });
                    }
                }

                return allOptions;
            });
        },
    };

    let getGoogleSheetFields = function (entity, projectIntegration, tonkeanService) {
        if (entity === 'Row') {
            const spreadsheetConfiguration = projectIntegration?.projectData?.projectDatas?.[0]?.config;
            if (spreadsheetConfiguration?.titleFieldColumn || spreadsheetConfiguration?.idFieldColumn) {
                // We get the external fields so we can put the correct label on the field (otherwise,
                // it's just an arbitrary integer).
                return tonkeanService
                    .getAvailableExternalFields(projectIntegration.id, ['Row'])
                    .then((availableExternalFieldsData) => {
                        let requiredFields = {};
                        if (spreadsheetConfiguration?.titleFieldColumn) {
                            requiredFields[spreadsheetConfiguration.titleFieldColumn] = {
                                id: spreadsheetConfiguration.titleFieldColumn,
                                label: utils.findFirst(
                                    availableExternalFieldsData.entitiesWithLabels,
                                    (entityWithLabel) =>
                                        entityWithLabel.name === spreadsheetConfiguration.titleFieldColumn,
                                )?.label,
                                description:
                                    "The field that's configured as Title when importing into Tonkean (Data source configuration)",
                                defaultValueType: 'MANUAL',
                            };
                        }

                        if (spreadsheetConfiguration?.idFieldColumn) {
                            let idFieldColumnMetadata = utils.findFirst(
                                availableExternalFieldsData.entitiesWithLabels,
                                (entityWithLabel) => entityWithLabel.name === spreadsheetConfiguration?.idFieldColumn,
                            );
                            // Double check that the field column metadata is exists.
                            if (idFieldColumnMetadata) {
                                requiredFields[spreadsheetConfiguration?.idFieldColumn] = {
                                    id: spreadsheetConfiguration?.idFieldColumn,
                                    label: idFieldColumnMetadata?.label,
                                    description:
                                        "The field that's configured as unique identifier when importing into Tonkean (Data source configuration)",
                                    defaultValueType: 'MANUAL',
                                };
                            }
                        }

                        return $q.resolve(requiredFields);
                    });
            }
        }

        return $q.resolve();
    };

    let actionsByIntegrationsMap = {
        HUBSPOT: {
            createNewItem: {
                Deal: {
                    displayName: 'Deal',
                    entity: 'Deal',
                    pluralLabel: 'Deals',
                    requiredFields: {
                        dealname: {
                            id: 'dealname',
                            label: 'Deal Name',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Deal ID',
                        },
                    ],
                },
                Contact: {
                    displayName: 'Contact',
                    entity: 'Contact',
                    pluralLabel: 'Contacts',
                    requiredFields: {
                        email: {
                            id: 'email',
                            label: 'Contact Email',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        firstname: {
                            id: 'firstname',
                            label: 'First Name',
                            defaultValueType: 'MANUAL',
                        },
                        website: {
                            id: 'website',
                            label: 'Website',
                            defaultValueType: 'MANUAL',
                        },
                        company: {
                            id: 'company',
                            label: 'Company',
                            defaultValueType: 'MANUAL',
                        },
                        phone: {
                            id: 'phone',
                            label: 'Phone',
                            defaultValueType: 'MANUAL',
                        },
                        address: {
                            id: 'address',
                            label: 'Address',
                            defaultValueType: 'MANUAL',
                        },
                        city: {
                            id: 'city',
                            label: 'City',
                            defaultValueType: 'MANUAL',
                        },
                        state: {
                            id: 'state',
                            label: 'State',
                            defaultValueType: 'MANUAL',
                        },
                        zip: {
                            id: 'zip',
                            label: 'Zip',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Contact ID',
                        },
                    ],
                },
                Company: {
                    displayName: 'Company',
                    entity: 'Company',
                    pluralLabel: 'Companies',
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                            defaultValueType: 'TNK_TITLE',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Company ID',
                        },
                    ],
                },
            },
            createOrUpdateItem: {
                Contact: {
                    displayName: 'Contact',
                    entity: 'Contact',
                    pluralLabel: 'Contacts',
                    requiredFields: {
                        email: {
                            id: 'email',
                            label: 'Contact Email',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        firstname: {
                            id: 'firstname',
                            label: 'First Name',
                            defaultValueType: 'MANUAL',
                        },
                        website: {
                            id: 'website',
                            label: 'Website',
                            defaultValueType: 'MANUAL',
                        },
                        company: {
                            id: 'company',
                            label: 'Company',
                            defaultValueType: 'MANUAL',
                        },
                        phone: {
                            id: 'phone',
                            label: 'Phone',
                            defaultValueType: 'MANUAL',
                        },
                        address: {
                            id: 'address',
                            label: 'Address',
                            defaultValueType: 'MANUAL',
                        },
                        city: {
                            id: 'city',
                            label: 'City',
                            defaultValueType: 'MANUAL',
                        },
                        state: {
                            id: 'state',
                            label: 'State',
                            defaultValueType: 'MANUAL',
                        },
                        zip: {
                            id: 'zip',
                            label: 'Zip',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created/Updated Contact ID',
                        },
                    ],
                },
            },
            updateField: {
                Deal: {
                    displayName: 'Deal',
                    entity: 'Deal',
                    pluralLabel: 'Deals',
                },
                Company: {
                    displayName: 'Company',
                    entity: 'Company',
                    pluralLabel: 'Companies',
                },
            },
            customActions: {
                ASSOCIATE_DEAL: {
                    displayName: 'Associate Deal',
                    entity: entitiesMetadata.HUBSPOT.Deal,
                },
            },
        },
        AIRTABLE: {
            createNewItem: {
                useEntitySelector: true,
            },
            updateField: {
                useEntitySelector: true,
            },
        },
        GOOGLEGROUPS: {
            createNewItem: {
                Member: {
                    displayName: 'Group Member',
                    entity: 'GroupMember',
                    pluralLabel: 'Group Members',
                    requiredFields: {
                        groupAddress: {
                            id: 'groupAddress',
                            label: 'Group Email',
                            defaultValueType: 'MANUAL',
                        },
                        email: {
                            id: 'email',
                            label: 'Group Member Email',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        role: {
                            id: 'role',
                            label: 'Group Member Role',
                            description: 'Member, Manager and Owner accepted',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Google Group Member',
                        },
                    ],
                },
            },
            updateField: {
                User: {
                    displayName: entitiesMetadata.GOOGLEGROUPS.USER.displayName,
                    entity: entitiesMetadata.GOOGLEGROUPS.USER.entity,
                    pluralLabel: entitiesMetadata.GOOGLEGROUPS.USER.pluralLabel,
                },
            },
            customActions: {
                FORCE_PASSWORD_CHANGE: {
                    displayName: 'Force User to Change Password',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} should change his password?`,
                },
                REMOVE_USER_FROM_GROUP: {
                    displayName: 'Remove User From Group',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} to remove from group?`,
                },
                INVALIDATE_USER_VERIFICATION_CODES: {
                    displayName: 'Invalidate Backup Verification Codes',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} to invalidate?`,
                },
                DELETE_ALL_ACCESS_TOKENS_FOR_USER: {
                    displayName: 'Deletes All Access Tokens Issued by a User',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} to delete all tokens for?`,
                },
                SIGN_OUT_USER: {
                    displayName: 'Sign Out User',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} to sign out?`,
                },
                DELETE_USER: {
                    displayName: 'Delete User',
                    entity: entitiesMetadata.GOOGLEGROUPS.USER,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.GOOGLEGROUPS.USER.displayName} to delete?`,
                },
            },
        },
        JIRA: {
            createNewItem: {
                Issue: {
                    displayName: 'Issue',
                    entity: 'Issue',
                    pluralLabel: 'Issues',
                    requiredFields: {
                        project: {
                            id: 'project',
                            label: ' Project Key',
                            description: '',
                            defaultValueType: 'MANUAL',
                        },
                        issuetype: {
                            id: 'issuetype',
                            label: ' Issue Type Name',
                            description: 'Story, Bug, Epic, etc..',
                            defaultValueType: 'MANUAL',
                        },
                        summary: {
                            id: 'summary',
                            label: 'Summary',
                            description: '',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Issue ID',
                        },
                    ],
                },
            },
            updateField: {
                Issue: entitiesMetadata.JIRA.ISSUE,
            },
            customActions: {
                ADD_ISSUE_COMMENT: {
                    displayName: 'Add Comment to Issue',
                    entity: entitiesMetadata.JIRA.ISSUE,
                },
                ASSIGN_ISSUE: {
                    displayName: 'Assign Issue',
                    entity: entitiesMetadata.JIRA.ISSUE,
                },
                UPDATE_ISSUE_STATUS: {
                    displayName: 'Update Issue Status',
                    entity: entitiesMetadata.JIRA.ISSUE,
                },
                ADD_SERVICE_DESK_CUSTOMER: {
                    displayName: 'Add Service Desk Customer',
                    entity: entitiesMetadata.JIRA.ISSUE,
                    doesNotRequireEntity: true,
                },
                REMOVE_SERVICE_DESK_CUSTOMER: {
                    displayName: 'Remove Service Desk Customer',
                    entity: entitiesMetadata.JIRA.ISSUE,
                    doesNotRequireEntity: true,
                },
                CREATE_CUSTOMER: {
                    displayName: 'Create Customer',
                    entity: entitiesMetadata.JIRA.ISSUE,
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'email',
                                displayName: 'Customer Email',
                                placeholder: 'Enter email here...',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'display_name',
                                displayName: 'Customer Full Name',
                                placeholder: 'Enter full name here...',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const displayName = fieldsValuesDefinition.tonkeanExpressionsValuesMap['display_name'];
                        const email = fieldsValuesDefinition.tonkeanExpressionsValuesMap['email'];

                        if (displayName && email) {
                            return {
                                url: {
                                    originalExpression: `/rest/servicedeskapi/customer`,
                                    evaluatedExpression: `/rest/servicedeskapi/customer`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{"displayName": "${displayName.originalExpression}", "email":"${email.originalExpression}"}`,
                                    evaluatedExpression: `{"displayName": "${displayName.evaluatedExpression}", "email":"${email.evaluatedExpression}"}`,
                                },
                                contentType: 'application/json',
                                headers: [
                                    {
                                        name: {
                                            originalExpression: `X-ExperimentalApi`,
                                            evaluatedExpression: `X-ExperimentalApi`,
                                        },
                                        value: {
                                            originalExpression: `opt-in`,
                                            evaluatedExpression: `opt-in`,
                                        },
                                    },
                                ],
                            };
                        }
                    },
                },
                ADD_ATTACHMENT_TO_ISSUE: {
                    useExtendedMatchConfiguration: true,
                    displayName: `Add Attachment to ${entitiesMetadata.JIRA.ISSUE.displayName}`,
                    entity: entitiesMetadata.JIRA.ISSUE,
                },
            },
        },
        NETSUITE: {
            createNewItem: {
                Vendor: {
                    displayName: entitiesMetadata.NETSUITE.VENDOR.displayName,
                    entity: entitiesMetadata.NETSUITE.VENDOR.displayName,
                    pluralLabel: entitiesMetadata.NETSUITE.VENDOR.displayName,
                    requiredFields: {
                        companyname: {
                            id: 'companyname',
                            label: 'Company Name',
                            defaultValueType: 'MANUAL',
                        },
                        subsidiary: {
                            id: 'subsidiary',
                            label: 'Subsidiary ID',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Vendor ID',
                        },
                    ],
                },
            },
            updateField: {
                JOURNAL: {
                    displayName: entitiesMetadata.NETSUITE.TRANSACTION.displayName,
                    entity: entitiesMetadata.NETSUITE.TRANSACTION.entity,
                    pluralLabel: entitiesMetadata.NETSUITE.TRANSACTION.pluralLabel,
                },
            },
            customActions: {
                ADD_JOURNAL_ENTRY: {
                    displayName: 'Add Journal Entry',
                    entity: entitiesMetadata.NETSUITE.TRANSACTION,
                    doesNotRequireEntity: true,
                },
                UPDATE_JOURNAL_STATUS: {
                    displayName: `Update Journal's Status`,
                    entity: entitiesMetadata.NETSUITE.TRANSACTION,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.NETSUITE.TRANSACTION.displayName} to update?`,
                },
                UPLOAD_FILE: {
                    displayName: `Upload File to File Cabinet`,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                ATTACH_FILE: {
                    displayName: `Attach File from File Cabinet`,
                    entity: entitiesMetadata.NETSUITE.TRANSACTION,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.NETSUITE.TRANSACTION.displayName} to update?`,
                },
                ADD_PURCHASE_ORDER: {
                    displayName: 'Add Purchase Order',
                    entity: entitiesMetadata.NETSUITE.TRANSACTION,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Purchase Order ID',
                        },
                    ],
                },
                ADD_UPDATE_PURCHASE_ORDER_LINE_ITEMS: {
                    displayName: 'Add/Update Purchase Order Line Items',
                    entity: entitiesMetadata.NETSUITE.TRANSACTION,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which Purchase Order to update?`,
                },
            },
        },
        ZENDESK: {
            createNewItem: {
                Ticket: {
                    displayName: 'Ticket',
                    entity: 'Ticket',
                    pluralLabel: 'Tickets',
                    requiredFields: {
                        comment: {
                            id: 'comment',
                            label: 'Comment',
                        },
                    },
                    optionalFields: {
                        comment_format: {
                            id: 'comment_format',
                            label: 'Comment Format',
                            description: `Options: 'body' (default), 'html_body', 'plain_body'`,
                        },
                        subject: {
                            id: 'subject',
                            label: 'Subject',
                        },
                        assignee_id: {
                            id: 'assignee_id',
                            label: 'Assignee (Email)',
                        },
                        requester: {
                            id: 'requester',
                            label: 'Requester (Email)',
                        },
                        followers: {
                            id: 'followers',
                            label: 'Followers',
                            description: `Comma-separated list of User ID or User Email`,
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Ticket ID',
                        },
                    ],
                },
                User: {
                    displayName: entitiesMetadata.ZENDESK.USER.displayName,
                    entity: entitiesMetadata.ZENDESK.USER.entity,
                    pluralLabel: entitiesMetadata.ZENDESK.USER.pluralLabel,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    optionalFields: {
                        email: {
                            id: 'email',
                            label: 'Email',
                        },
                        role: {
                            id: 'role',
                            label: 'Role',
                            description: `Possible values: 'end-user' (default), 'agent' & 'admin'.`,
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created User ID',
                        },
                    ],
                },
            },
            updateField: {
                Ticket: {
                    displayName: 'Ticket',
                    entity: 'Ticket',
                    pluralLabel: 'Tickets',
                    uniqueFieldIdentifier: 'name',
                },
                User: {
                    displayName: entitiesMetadata.ZENDESK.USER.displayName,
                    entity: entitiesMetadata.ZENDESK.USER.entity,
                    pluralLabel: entitiesMetadata.ZENDESK.USER.pluralLabel,
                },
            },
            customActions: {
                CREATE_COMMENT_ON_TICKET: {
                    displayName: 'Reply on Ticket',
                    entity: entitiesMetadata.ZENDESK.TICKET,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to add reply to?`,
                },
                ADD_TAG_ON_TICKET: {
                    displayName: 'Add Tag to Ticket',
                    entity: entitiesMetadata.ZENDESK.TICKET,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to add tag to?`,
                },
                REMOVE_TAG_FROM_TICKET: {
                    displayName: 'Remove Tag From Ticket',
                    entity: entitiesMetadata.ZENDESK.TICKET,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to remove tag from?`,
                },
                REDACT_ALL_ATTACHMENTS: {
                    displayName: 'Redact All Attachments From Ticket',
                    entity: entitiesMetadata.ZENDESK.TICKET,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to redact attachments from?`,
                    useExtendedMatchConfiguration: true,
                },
                UPLOAD_ATTACHMENT_TO_TICKET: {
                    useExtendedMatchConfiguration: true,
                    displayName: `Upload ${entitiesMetadata.ZENDESK.ATTACHMENT.displayName} to ${entitiesMetadata.ZENDESK.TICKET.displayName}`,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to upload the ${entitiesMetadata.ZENDESK.ATTACHMENT.displayName} to?`,
                    entity: entitiesMetadata.ZENDESK.TICKET,
                },
                ADD_FOLLOWERS_TO_TICKET: {
                    displayName: 'Add Followers to Ticket',
                    entity: entitiesMetadata.ZENDESK.TICKET,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.ZENDESK.TICKET.displayName} to add the followers to?`,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        DYNAMICS365: {
            updateField: {
                Opportunity: {
                    displayName: 'Opportunity',
                    entity: 'Opportunity',
                    pluralLabel: 'Opportunities',
                },
            },
        },
        TRELLO: {
            createNewItem: {
                Card: {
                    displayName: 'Card',
                    entity: 'Card',
                    pluralLabel: 'Cards',
                    requiredFields: {
                        idList: {
                            id: 'idList',
                            label: ' ID of the list the card should be created in',
                            description: 'The ID of the list the card should be created in',
                            valuesAdditionalTabs: [trelloListAutoCompleteTab],
                        },
                    },
                    optionalFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                            description: 'The name for the card',
                        },
                        desc: {
                            id: 'desc',
                            label: 'Description',
                            description: 'The description for the card',
                        },
                        pos: {
                            id: 'pos',
                            label: 'Position',
                            description: 'The position of the new card. top, bottom, or a positive float',
                        },
                        due: {
                            id: 'due',
                            label: 'Due date',
                            description: '',
                        },
                        dueComplete: {
                            id: 'dueComplete',
                            label: 'dueComplete',
                            description: 'Whether the due date has been marked complete',
                        },
                        membersEmails: {
                            id: 'membersEmails',
                            label: 'Members Emails',
                            description:
                                'Comma-separated list of member E-mails to add to the card as members. Make sure they are correctly mapped in the integration configuration.',
                        },
                        idLabels: {
                            id: 'idLabels',
                            label: 'list of label IDs',
                            description: 'Comma-separated list of label IDs to add to the card',
                        },
                        urlSource: {
                            id: 'urlSource',
                            label: 'URL',
                            description: 'A URL starting with http:// or https://',
                        },
                        idCardSource: {
                            id: 'idCardSource',
                            label: 'Card source',
                            description: 'The ID of a card to copy into the new card',
                        },
                        keepFromSource: {
                            id: 'keepFromSource',
                            label: 'properties to copy over',
                            description:
                                'If using idCardSource you can specify which properties to copy over. all or comma-separated list of: attachments,checklists,comments,due,labels,members,stickers',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Card ID',
                        },
                    ],
                },
            },
            updateField: {
                Card: {
                    displayName: 'Card',
                    entity: 'Card',
                    pluralLabel: 'Cards',
                    requiredFields: {},
                    optionalFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                            description: 'The name for the card',
                        },
                        desc: {
                            id: 'desc',
                            label: 'Description',
                            description: 'The description for the card',
                        },
                        closed: {
                            id: 'name',
                            label: 'Name',
                            description: 'The name for the card',
                        },
                        membersEmails: {
                            id: 'membersEmails',
                            label: 'Members Emails',
                            description:
                                'Comma-separated list of member E-mails to add to the card as members. Make sure they are correctly mapped in the integration configuration.',
                        },
                        idList: {
                            id: 'idList',
                            label: ' ID of the list the card should be created in',
                            description: 'The ID of the list the card should be created in',
                            valuesAdditionalTabs: [trelloListAutoCompleteTab],
                        },
                        idBoard: {
                            id: 'idBoard',
                            label: ' ID of the list the card should be created in',
                            description: 'The ID of the list the card should be created in',
                        },
                        pos: {
                            id: 'pos',
                            label: 'Position',
                            description: 'The position of the new card. top, bottom, or a positive float',
                        },
                        due: {
                            id: 'due',
                            label: 'Due date',
                            description: '',
                        },
                        dueComplete: {
                            id: 'dueComplete',
                            label: 'dueComplete',
                            description: 'Whether the due date has been marked complete',
                        },
                    },
                },
            },
            customActions: {
                ADD_MEMBER_TO_CARD: {
                    displayName: 'Add a Member to a Card',
                    entity: entitiesMetadata.TRELLO.CARD,
                },
                COMMENT_ON_CARD: {
                    displayName: 'Add a Comment to a Card',
                    entity: entitiesMetadata.TRELLO.CARD,
                },
                LABEL_CARD: {
                    displayName: 'Add a Label to a Card',
                    entity: entitiesMetadata.TRELLO.CARD,
                },
                MOVE_CARD: {
                    displayName: 'Move a Card to the Specified List',
                    entity: entitiesMetadata.TRELLO.CARD,
                },
            },
        },
        GOOGLESHEETS: {
            createNewItem: {
                Row: {
                    displayName: 'Row',
                    entity: 'Row',
                    pluralLabel: 'Rows',
                    requiredFields: {},
                    optionalFields: {},
                    getDynamicRequiredFields: getGoogleSheetFields,
                    getDynamicOptionalFields: (entity, projectIntegration, tonkeanService) => {
                        return $q.resolve();
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Row ID',
                        },
                    ],
                },
            },
            updateField: {
                Row: {
                    displayName: 'Row',
                    entity: 'Row',
                    pluralLabel: 'Rows',
                },
            },
            createOrUpdateItem: {
                Row: {
                    displayName: entitiesMetadata.GOOGLESHEETS.ROW.displayName,
                    entity: entitiesMetadata.GOOGLESHEETS.ROW.entity,
                    pluralLabel: entitiesMetadata.GOOGLESHEETS.ROW.pluralLabel,
                    requiredFields: {},
                    optionalFields: {},
                    getDynamicRequiredFields: getGoogleSheetFields,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Updated/Created Row ID',
                        },
                    ],
                },
            },
            customActions: {
                DELETE_ROW: {
                    displayName: 'Delete Row',
                    entity: entitiesMetadata.GOOGLESHEETS.ROW,
                    itemMatchQuestion: 'Which row to delete?',
                },
            },
        },
        GOOGLEDRIVE: {
            createNewItem: {
                Folder: {
                    displayName: 'Folder',
                    entity: 'Folder',
                    pluralLabel: 'Folder',
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                            description: 'The name for the folder',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        parents: {
                            id: 'parents',
                            label: 'Parent ID',
                            description: 'The existing folder to put the new folder under',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
                FILE: {
                    displayName: entitiesMetadata.GOOGLEDRIVE.FILE.displayName,
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE.entity,
                    pluralLabel: entitiesMetadata.GOOGLEDRIVE.FILE.pluralLabel,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                            description: 'The name for the file (with extension)',
                            defaultValueType: 'MANUAL',
                        },
                        mimeType: {
                            id: 'mimeType',
                            label: 'Mime Type',
                            description: 'The mime type for the file',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        parent: {
                            id: 'parent',
                            label: 'Parent ID',
                            description: 'The existing folder to put the new file under',
                            defaultValueType: 'MANUAL',
                        },
                        description: {
                            id: 'description',
                            label: 'File Description',
                            defaultValueType: 'MANUAL',
                        },
                        fileContent: {
                            id: 'fileContent',
                            label: 'File Content',
                            description: 'If empty, an empty file will be created',
                            defaultValueType: 'MANUAL',
                        }
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created File ID',
                        },
                    ],
                },
            },
            createOrUpdateItem: {
                Folder: {
                    displayName: 'Folder',
                    entity: 'Folder',
                    pluralLabel: 'Folder',
                    requiredFields: {
                        folderName: {
                            id: 'folderName',
                            label: 'Folder to Update (Name)',
                            description: 'The name for the folder',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        parents: {
                            id: 'parents',
                            label: 'Parent ID',
                            description: 'The existing folder to put the new folder under',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created/Updated Folder ID',
                        },
                    ],
                },
            },
            customActions: {
                DELETE_FILE: {
                    displayName: 'Delete File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    useExtendedMatchConfiguration: true,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        return {
                            url: {
                                originalExpression: '/drive/v3/files/{{TNK_ACTION_ENTITY_ID}}',
                                evaluatedExpression: '/drive/v3/files/{{TNK_ACTION_ENTITY_ID}}',
                            },
                            methodType: 'DELETE',
                            contentType: 'application/json',
                        };
                    },
                    itemMatchQuestion: 'Which file to delete?',
                },
                COMMENT_ON_FILE: {
                    displayName: 'Comment on File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    itemMatchQuestion: 'Which file to comment on?',
                },
                SHARE_FILE: {
                    displayName: 'Share File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    itemMatchQuestion: 'Which file to share?',
                },
                SHARE_FOLDER: {
                    displayName: 'Share Folder',
                    entity: entitiesMetadata.GOOGLEDRIVE.FOLDER,
                    itemMatchQuestion: 'Which folder to share?',
                },
                COPY_FILE: {
                    displayName: 'Copy File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Copied File ID',
                        },
                    ],
                    itemMatchQuestion: 'Which file to copy?',
                    useExtendedMatchConfiguration: true,
                },
                GENERATE_PDF: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Generate PDF File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    createsEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Generated PDF ID',
                        },
                    ],
                },
                MOVE_FILE: {
                    displayName: 'Move File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    itemMatchQuestion: 'Which file to move?',
                    useExtendedMatchConfiguration: true,
                },
                RENAME_FILE: {
                    displayName: 'Rename File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    itemMatchQuestion: 'Which file to rename?',
                },
                RENAME_FOLDER: {
                    displayName: 'Rename Folder',
                    entity: entitiesMetadata.GOOGLEDRIVE.FOLDER,
                    itemMatchQuestion: 'Which folder to rename?',
                },
                COPY_FOLDER: {
                    doesNotRequireEntity: true,
                    displayName: 'Copy Folder',
                    entity: entitiesMetadata.GOOGLEDRIVE.FOLDER,
                    itemMatchQuestion: 'Which folder to copy?',
                    useExtendedMatchConfiguration: true,
                },
                MOVE_FOLDER: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Move Folder',
                    entity: entitiesMetadata.GOOGLEDRIVE.FOLDER,
                    itemMatchQuestion: 'Which folder to move?',
                },
                CREATE_SHEET: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Sheet',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Sheet ID',
                        },
                    ],
                },
                CREATE_DOC_FROM_TEMPLATE: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Doc/Slide From Template',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Doc/Slide ID',
                        },
                    ],
                },
                EXTRACT_TEXT_FROM_DOCUMENT: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Extract Text From File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Extracted Text',
                        },
                    ],
                    itemMatchQuestion: 'Which file to extract the text from?',
                },
                UPLOAD_FILE_FROM_URL: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created File ID',
                        },
                    ],
                },
                COPY_ROWS: {
                    doesNotRequireEntity: true,
                    displayName: 'Append Rows from Another Sheet',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    useExtendedMatchConfiguration: true,
                },
                CONVERT_FILE_TYPE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Convert File Type',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    createsEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Converted New File ID',
                        },
                    ],
                },
                GET_FILE_CONTENT: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Get File Content',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Google DriveFile Content',
                        },
                    ],
                    itemMatchQuestion: 'Which file to get the content from?',
                },
                GET_DOCUMENT_SUGGESTED_CHANGES: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Get Document Suggested Changes',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Document Suggested changes',
                        },
                    ],
                    itemMatchQuestion: 'Which file to get the suggested changes from?',
                },
            },
        },
        GOOGLE_CALENDAR: {
            createNewItem: {
                Event: {
                    displayName: 'Event',
                    entity: 'Event',
                    pluralLabel: 'Events',
                    requiredFields: {
                        start: {
                            id: 'start',
                            label: 'Start',
                        },
                        end: {
                            id: 'end',
                            label: 'End',
                        },
                    },
                    optionalFields: {
                        summary: {
                            id: 'summary',
                            label: 'summary',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Event ID',
                        },
                    ],
                },
            },
            updateField: {
                Event: {
                    displayName: 'Event',
                    entity: 'Event',
                    pluralLabel: 'Events',
                    actionValidator: (fields, validationObject) => {
                        let hasStartDateField = fields.hasOwnProperty('start');
                        let hasEndDateField = fields.hasOwnProperty('end');

                        if ((hasStartDateField && !hasEndDateField) || (hasEndDateField && !hasStartDateField)) {
                            let missingField = hasStartDateField ? 'End' : 'Start';
                            validationObject.dateFieldError = `The fields "Start" and "End" are required to update at the same time. Please add the "${missingField}" field.`;
                        }
                    },
                },
            },
            customActions: {
                RSVP_EVENT: {
                    displayName: 'RSVP to Event',
                    entity: entitiesMetadata.GOOGLE_CALENDAR.EVENT,
                },
                INVITE_ATTENDEES: {
                    displayName: 'Invite Attendees',
                    entity: entitiesMetadata.GOOGLE_CALENDAR.EVENT,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        GREENHOUSE: {
            customActions: {
                CREATE_USER: {
                    displayName: 'Create User',
                    entity: entitiesMetadata.GREENHOUSE.USER,
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'first_name',
                                displayName: 'First Name',
                                placeholder: 'The user first name.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'last_name',
                                displayName: 'Last Name',
                                placeholder: 'The user last name.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'email',
                                displayName: 'Email',
                                placeholder: 'The user email.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'creator_id',
                                displayName: 'Create on Behalf Of',
                                placeholder: 'ID of the user issuing this request.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const firstName = fieldsValuesDefinition.tonkeanExpressionsValuesMap['first_name'];
                        const lastName = fieldsValuesDefinition.tonkeanExpressionsValuesMap['last_name'];
                        const email = fieldsValuesDefinition.tonkeanExpressionsValuesMap['email'];
                        const creatorId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['creator_id'];

                        if (firstName && lastName && email && creatorId) {
                            return {
                                url: {
                                    originalExpression: '/users',
                                    evaluatedExpression: '/users',
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{"first_name": "${firstName.evaluatedExpression}","last_name": "${lastName.evaluatedExpression}","email": "${email.evaluatedExpression}"}`,
                                    evaluatedExpression: `{"first_name": "${firstName.evaluatedExpression}","last_name": "${lastName.evaluatedExpression}","email": "${email.evaluatedExpression}"}`,
                                },
                                contentType: 'application/json',
                                headers: [
                                    {
                                        name: {
                                            originalExpression: `On-Behalf-Of`,
                                            evaluatedExpression: `On-Behalf-Of`,
                                        },
                                        value: {
                                            originalExpression: `${creatorId.evaluatedExpression}`,
                                            evaluatedExpression: `${creatorId.evaluatedExpression}`,
                                        },
                                    },
                                ],
                            };
                        }
                    },
                },
                DISABLE_USER: {
                    displayName: 'Disable User',
                    entity: entitiesMetadata.GREENHOUSE.USER,
                    doesNotRequireEntity: false,
                    isCustomHttpAction: true,
                    useExtendedMatchConfiguration: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'creator_id',
                                displayName: 'Create on Behalf Of',
                                placeholder: 'ID of the user issuing this request.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const creatorId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['creator_id'];

                        if (creatorId) {
                            return {
                                url: {
                                    originalExpression: `/users/{{TNK_ACTION_ENTITY_ID}}/disable`,
                                    evaluatedExpression: `/users/{{TNK_ACTION_ENTITY_ID}}/disable`,
                                },
                                methodType: 'PATCH',
                                body: {
                                    originalExpression: '',
                                    evaluatedExpression: '',
                                },
                                contentType: 'application/json',
                                headers: [
                                    {
                                        name: {
                                            originalExpression: `On-Behalf-Of`,
                                            evaluatedExpression: `On-Behalf-Of`,
                                        },
                                        value: {
                                            originalExpression: `${creatorId.evaluatedExpression}`,
                                            evaluatedExpression: `${creatorId.evaluatedExpression}`,
                                        },
                                    },
                                ],
                            };
                        }
                    },
                },
            },
        },
        OUTLOOK: {
            doesNotRequireEntity: true,
            createNewItem: {
                Event: {
                    displayName: entitiesMetadata.OUTLOOK.EVENT.displayName,
                    pluralLabel: entitiesMetadata.OUTLOOK.EVENT.pluralLabel,
                    entity: entitiesMetadata.OUTLOOK.EVENT.entity,
                    requiredFields: {
                        subject: {
                            id: 'subject',
                            label: 'Subject',
                        },
                        start: {
                            id: 'start',
                            label: 'Start',
                        },
                        end: {
                            id: 'end',
                            label: 'End',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                        },
                        attendees: {
                            id: 'attendees',
                            label: 'Attendees',
                            description: 'All the attendees invited to the event. (Separated by commas)',
                        },
                        location: {
                            id: 'location',
                            label: 'Location',
                        },
                        userId: {
                            id: 'userId',
                            label: 'User ID or Principal Name',
                            description:
                                'Required when using Application Authentication and not provided during the integration setup.',
                        },
                        isOnlineMeeting: {
                            id: 'isOnlineMeeting',
                            label: 'Is Online Meeting.',
                            description: 'Options: true, false. Default: false.',
                        },
                        onlineMeetingProvider: {
                            id: 'onlineMeetingProvider',
                            label: 'Online Meeting Provider',
                            description: "For example 'teamsForBusiness'",
                        },
                    },
                },
                Folder: {
                    displayName: entitiesMetadata.OUTLOOK.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.OUTLOOK.FOLDER.pluralLabel,
                    entity: entitiesMetadata.OUTLOOK.FOLDER.entity,
                    requiredFields: {
                        displayName: {
                            id: 'displayName',
                            label: 'Display Name',
                        },
                    },
                    optionalFields: {
                        parentFolderId: {
                            id: 'parentFolderId',
                            label: 'Parent Folder Id',
                            description: 'If empty, the folder will be created in the root.',
                        },
                    },
                },
            },
            customActions: {
                SEND_EMAIL: {
                    displayName: 'Send Email',
                    entity: entitiesMetadata.OUTLOOK.MESSAGE,
                },
                MOVE_MESSAGE_TO_FOLDER: {
                    doesNotRequireEntity: true,
                    displayName: 'Move Email Message',
                    entity: entitiesMetadata.OUTLOOK.MESSAGE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Moved Message ID',
                        },
                    ],
                },
            },
        },
        DROPBOX: {
            createOrUpdateItem: {
                Folder: {
                    displayName: 'Folder',
                    entity: 'Folder',
                    pluralLabel: 'Folder',
                    requiredFields: {
                        folderName: {
                            id: 'folderName',
                            label: 'Name',
                            description: 'The name for the folder',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        folderPath: {
                            id: 'folderPath',
                            label: 'Folder Parent Path',
                            description:
                                'The full path of the parent folder. For example: "/some root folder/parent"\n' +
                                '        If one or more of the parents does not exist it will be automatically created.\n' +
                                '        If missing, will use the folder connected in this integration.',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created/Updated Folder ID',
                        },
                    ],
                },
            },
            customActions: {
                EXTRACT_TEXT_FROM_DOCUMENT: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Extract Text From File',
                    entity: entitiesMetadata.DROPBOX.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Extracted Text',
                        },
                    ],
                    itemMatchQuestion: 'Which file to extract the text from?',
                },
                CREATE_FOLDER: {
                    displayName: 'Create Folder',
                    entity: entitiesMetadata.DROPBOX.FOLDER,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
                CREATE_SHARED_FOLDER: {
                    displayName: 'Create Shared Folder',
                    entity: entitiesMetadata.DROPBOX.FOLDER,
                    createsEntity: true,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
                UPLOAD_FILE_FROM_URL: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload File From Url',
                    entity: entitiesMetadata.DROPBOX.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created File ID',
                        },
                    ],
                },
                COPY_FOLDER: {
                    displayName: 'Copy Folder',
                    entity: entitiesMetadata.DROPBOX.FOLDER,
                    createsEntity: true,
                    doesNotRequireEntity: true,
                    itemMatchQuestion: 'Which folder to copy?',
                },
                MOVE_FILE: {
                    displayName: 'Move File/Folder',
                    entity: entitiesMetadata.DROPBOX.FILE,
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'from_url',
                                displayName: 'File/Folder URL',
                                placeholder: 'The source file/folder url.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'to_url',
                                displayName: 'File/Folder Destination URL',
                                placeholder: 'The destination file/folder url.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const fromUrl = fieldsValuesDefinition.tonkeanExpressionsValuesMap['from_url'];
                        const toUrl = fieldsValuesDefinition.tonkeanExpressionsValuesMap['to_url'];

                        if (fromUrl && toUrl) {
                            return {
                                url: {
                                    originalExpression: '/2/files/move_v2',
                                    evaluatedExpression: '/2/files/move_v2',
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{"from_path": "${fromUrl.evaluatedExpression}","to_path": "${toUrl.originalExpression}","allow_shared_folder": false,"autorename": false,"allow_ownership_transfer": false}`,
                                    evaluatedExpression: `{"from_path": "${fromUrl.evaluatedExpression}","to_path": "${toUrl.evaluatedExpression}","allow_shared_folder": false,"autorename": false,"allow_ownership_transfer": false}`,
                                },
                                contentType: 'application/json',
                                retryOnStatusCodes: [429],
                            };
                        }
                    },
                    itemMatchQuestion: 'Which file to move?',
                },
            },
        },
        TWILIOSMS: {
            customActions: {
                SEND_SMS: {
                    displayName: 'Send SMS',
                    doesNotRequireEntity: true,
                    entity: entitiesMetadata.TWILIOSMS.SMS,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'SMS ID',
                        },
                    ],
                },
            },
        },
        MONDAY: {
            updateField: {
                Item: {
                    displayName: entitiesMetadata.MONDAY.ITEM.displayName,
                    entity: entitiesMetadata.MONDAY.ITEM.entity,
                    pluralLabel: entitiesMetadata.MONDAY.ITEM.pluralLabel,
                    useExtendedMatchConfiguration: true,
                },
            },
            createNewItem: {
                Board: {
                    displayName: entitiesMetadata.MONDAY.BOARD.displayName,
                    entity: entitiesMetadata.MONDAY.BOARD.entity,
                    pluralLabel: entitiesMetadata.MONDAY.BOARD.pluralLabel,
                    isDynamicFieldsAllowed: false,
                    requiredFields: {
                        BoardName: {
                            id: 'BoardName',
                            label: 'Board Name',
                            defaultValueType: 'MANUAL',
                        },
                        BoardKind: {
                            id: 'BoardKind',
                            label: 'Board Kind (Public/Private/Share)',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Board ID',
                        },
                    ],
                },
                Group: {
                    displayName: entitiesMetadata.MONDAY.Group.displayName,
                    entity: entitiesMetadata.MONDAY.Group.entity,
                    pluralLabel: entitiesMetadata.MONDAY.Group.pluralLabel,
                    isDynamicFieldsAllowed: false,
                    requiredFields: {
                        BoardId: {
                            id: 'BoardId',
                            label: 'Board Id',
                            defaultValueType: 'MANUAL',
                        },
                        GroupName: {
                            id: 'GroupName',
                            label: 'Group Name',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Group ID',
                        },
                    ],
                },
                Item: {
                    displayName: entitiesMetadata.MONDAY.ITEM.displayName,
                    entity: entitiesMetadata.MONDAY.ITEM.entity,
                    pluralLabel: entitiesMetadata.MONDAY.ITEM.pluralLabel,
                    isDynamicFieldsAllowed: true,
                    description:
                        'Custom Fields Formats: Phone: 123 | Country Name. Date: YYYY-MM-DD. Timeline: YYYY-MM-DD | YYYY-MM-DD',
                    requiredFields: {
                        BoardId: {
                            id: 'BoardId',
                            label: 'Board Id',
                            defaultValueType: 'MANUAL',
                        },
                        GroupId: {
                            id: 'GroupId',
                            label: 'Group Id',
                            defaultValueType: 'MANUAL',
                        },
                        ItemTitle: {
                            id: 'ItemTitle',
                            label: 'Title',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {},
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Item ID',
                        },
                    ],
                },
            },
            customActions: {
                ARCHIVE_ITEM: {
                    displayName: 'Archive Item',
                    entity: entitiesMetadata.MONDAY.ITEM,
                    isCustomHttpAction: true,
                    useExtendedMatchConfiguration: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        return {
                            url: {
                                originalExpression: '/v2',
                                evaluatedExpression: '/v2',
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression:
                                    '{ "query" : "mutation { archive_item (item_id: {{TNK_ACTION_ENTITY_ID}}) { id } }" } ',
                                evaluatedExpression:
                                    '{ "query" : "mutation { archive_item (item_id: {{TNK_ACTION_ENTITY_ID}}) { id } }" } ',
                            },
                            contentType: 'application/json',
                        };
                    },
                },
                CREATE_UPDATE: {
                    displayName: 'Comment on Item',
                    entity: entitiesMetadata.MONDAY.ITEM,
                    isCustomHttpAction: true,
                    useExtendedMatchConfiguration: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'comment_text',
                                displayName: 'Comment Text',
                                placeholder: 'Please fill out the text to put in the update',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const commentText = fieldsValuesDefinition.tonkeanExpressionsValuesMap['comment_text'];

                        if (commentText) {
                            return {
                                url: {
                                    originalExpression: '/v2',
                                    evaluatedExpression: '/v2',
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{ "query" : "mutation { create_update (item_id: {{TNK_ACTION_ENTITY_ID}} body: \\"${commentText.originalExpression}\\") { id } }" } `,
                                    evaluatedExpression: `{ "query" : "mutation { create_update (item_id: {{TNK_ACTION_ENTITY_ID}} body: \\"${commentText.evaluatedExpression}\\") { id } }" } `,
                                },
                                contentType: 'application/json',
                            };
                        }
                    },
                },
                DELETE_ITEM: {
                    displayName: 'Delete Item',
                    entity: entitiesMetadata.MONDAY.ITEM,
                    isCustomHttpAction: true,
                    useExtendedMatchConfiguration: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        return {
                            url: {
                                originalExpression: '/v2',
                                evaluatedExpression: '/v2',
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression:
                                    '{ "query" : "mutation { delete_item (item_id: {{TNK_ACTION_ENTITY_ID}}) { id } }" } ',
                                evaluatedExpression:
                                    '{ "query" : "mutation { delete_item (item_id: {{TNK_ACTION_ENTITY_ID}}) { id } }" } ',
                            },
                            contentType: 'application/json',
                        };
                    },
                },
            },
        },
        SALESFORCE: {
            createNewItem: {
                useEntitySelector: true,
                Lead: {
                    displayName: 'Lead',
                    entity: 'Lead',
                    pluralLabel: 'Leads',
                    requiredFields: {
                        LastName: {
                            id: 'LastName',
                            label: 'Last Name',
                            defaultValueType: 'TNK_TITLE',
                        },
                        Company: {
                            id: 'Company',
                            label: 'Company',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    optionalFields: {
                        AnnualRevenue: {
                            id: 'AnnualRevenue',
                            label: 'Annual Revenue',
                            defaultValueType: 'MANUAL',
                        },
                        City: {
                            id: 'City',
                            label: 'City',
                            defaultValueType: 'MANUAL',
                        },
                        CompanyDunsNumber: {
                            id: 'CompanyDunsNumber',
                            label: 'Company D-U-N-S Number',
                            defaultValueType: 'MANUAL',
                        },
                        Country: {
                            id: 'Country',
                            label: 'Country',
                            defaultValueType: 'MANUAL',
                        },
                        CurrentGenerators__c: {
                            id: 'CurrentGenerators__c',
                            label: 'Current Generator(s)',
                            defaultValueType: 'MANUAL',
                        },
                        DandbCompanyId: {
                            id: 'DandbCompanyId',
                            label: 'D&B Company',
                            defaultValueType: 'MANUAL',
                        },
                        Description: {
                            id: 'Description',
                            label: 'Description',
                            defaultValueType: 'MANUAL',
                        },
                        Email: {
                            id: 'Email',
                            label: 'Email',
                            defaultValueType: 'MANUAL',
                        },
                        Fax: {
                            id: 'Fax',
                            label: 'Fax',
                            defaultValueType: 'MANUAL',
                        },
                        FirstName: {
                            id: 'FirstName',
                            label: 'First Name',
                            defaultValueType: 'MANUAL',
                        },
                        Industry: {
                            id: 'Industry',
                            label: 'Industry',
                            defaultValueType: 'MANUAL',
                        },
                        IsConverted: {
                            id: 'IsConverted',
                            label: 'Converted',
                            defaultValueType: 'MANUAL',
                        },
                        IsUnreadByOwner: {
                            id: 'IsUnreadByOwner',
                            label: 'Unread By Owner',
                            defaultValueType: 'MANUAL',
                        },
                        Jigsaw: {
                            id: 'Jigsaw',
                            label: 'Data.com Key',
                            defaultValueType: 'MANUAL',
                        },
                        LeadSource: {
                            id: 'LeadSource',
                            label: 'Lead Source',
                            defaultValueType: 'MANUAL',
                        },
                        MobilePhone: {
                            id: 'MobilePhone',
                            label: 'Mobile Phone',
                            defaultValueType: 'MANUAL',
                        },
                        NumberOfEmployees: {
                            id: 'NumberOfEmployees',
                            label: 'Employees',
                            defaultValueType: 'MANUAL',
                        },
                        NumberofLocations__c: {
                            id: 'NumberofLocations__c',
                            label: 'Number of Locations',
                            defaultValueType: 'MANUAL',
                        },
                        OwnerId: {
                            id: 'OwnerId',
                            label: 'Owner',
                            defaultValueType: 'MANUAL',
                        },
                        Phone: {
                            id: 'Phone',
                            label: 'Phone',
                            defaultValueType: 'MANUAL',
                        },
                        PostalCode: {
                            id: 'PostalCode',
                            label: 'Zip/Postal Code',
                            defaultValueType: 'MANUAL',
                        },
                        Primary__c: {
                            id: 'Primary__c',
                            label: 'Primary',
                            defaultValueType: 'MANUAL',
                        },
                        ProductInterest__c: {
                            id: 'ProductInterest__c',
                            label: 'Product Interest',
                            defaultValueType: 'MANUAL',
                        },
                        Rating: {
                            id: 'Rating',
                            label: 'Rating',
                            defaultValueType: 'MANUAL',
                        },
                        UseAssignmentRules: {
                            id: 'UseAssignmentRules',
                            label: 'Use Assignment Rules',
                            defaultValueType: 'MANUAL',
                        },
                        SICCode__c: {
                            id: 'SICCode__c',
                            label: 'SIC Code',
                            defaultValueType: 'MANUAL',
                        },
                        Salutation: {
                            id: 'Salutation',
                            label: 'Salutation',
                            defaultValueType: 'MANUAL',
                        },
                        State: {
                            id: 'State',
                            label: 'State/Province',
                            defaultValueType: 'MANUAL',
                        },
                        Status: {
                            id: 'Status',
                            label: 'Status',
                            defaultValueType: 'TNK_STAGE',
                        },
                        Street: {
                            id: 'Street',
                            label: 'Street',
                            defaultValueType: 'MANUAL',
                        },
                        Title: {
                            id: 'Title',
                            label: 'Title',
                            defaultValueType: 'MANUAL',
                        },
                        Website: {
                            id: 'Website',
                            label: 'Website',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Lead ID',
                        },
                    ],
                },
                Opportunity: {
                    displayName: 'Opportunity',
                    entity: 'Opportunity',
                    pluralLabel: 'Opportunities',
                    requiredFields: {
                        Name: {
                            id: 'Name',
                            label: 'Name',
                            defaultValueType: 'TNK_TITLE',
                        },
                        StageName: {
                            id: 'StageName',
                            label: 'Stage',
                            defaultValueType: 'TNK_STAGE',
                        },
                        CloseDate: {
                            id: 'CloseDate',
                            label: 'Close Date',
                            defaultValueType: 'TNK_DUE_DATE',
                        },
                    },
                    optionalFields: {
                        AccountId: {
                            id: 'AccountId',
                            label: 'Account',
                            defaultValueType: 'MANUAL',
                        },
                        Amount: {
                            id: 'Amount',
                            label: 'Amount',
                            defaultValueType: 'MANUAL',
                        },
                        CampaignId: {
                            id: 'CampaignId',
                            label: 'Campaign',
                            defaultValueType: 'MANUAL',
                        },
                        CurrentGenerators__c: {
                            id: 'CurrentGenerators__c',
                            label: 'Current Generator(s)',
                            defaultValueType: 'MANUAL',
                        },
                        DeliveryInstallationStatus__c: {
                            id: 'DeliveryInstallationStatus__c',
                            label: 'Delivery/Installation Status',
                            defaultValueType: 'MANUAL',
                        },
                        Description: {
                            id: 'Description',
                            label: 'Description',
                            defaultValueType: 'MANUAL',
                        },
                        ForecastCategoryName: {
                            id: 'ForecastCategoryName',
                            label: 'Forecast Category',
                            defaultValueType: 'MANUAL',
                        },
                        IsPrivate: {
                            id: 'IsPrivate',
                            label: 'Private',
                            defaultValueType: 'MANUAL',
                        },
                        LeadSource: {
                            id: 'LeadSource',
                            label: 'Lead Source',
                            defaultValueType: 'MANUAL',
                        },
                        MainCompetitors__c: {
                            id: 'MainCompetitors__c',
                            label: 'Main Competitor(s)',
                            defaultValueType: 'MANUAL',
                        },
                        NextStep: {
                            id: 'NextStep',
                            label: 'Next Step',
                            defaultValueType: 'MANUAL',
                        },
                        OrderNumber__c: {
                            id: 'OrderNumber__c',
                            label: 'Order Number',
                            defaultValueType: 'MANUAL',
                        },
                        OwnerId: {
                            id: 'OwnerId',
                            label: 'Owner',
                            defaultValueType: 'MANUAL',
                        },
                        Pricebook2Id: {
                            id: 'Pricebook2Id',
                            label: 'Price Book',
                            defaultValueType: 'MANUAL',
                        },
                        Probability: {
                            id: 'Probability',
                            label: 'Probability (%)',
                            defaultValueType: 'MANUAL',
                        },
                        TotalOpportunityQuantity: {
                            id: 'TotalOpportunityQuantity',
                            label: 'Quantity',
                            defaultValueType: 'MANUAL',
                        },
                        TrackingNumber__c: {
                            id: 'TrackingNumber__c',
                            label: 'Tracking Number',
                            defaultValueType: 'MANUAL',
                        },
                        Type: {
                            id: 'Type',
                            label: 'Opportunity Type',
                            defaultValueType: 'MANUAL',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Opportunity ID',
                        },
                    ],
                },
                Campaign: {
                    displayName: entitiesMetadata.SALESFORCE.CAMPAIGN.displayName,
                    pluralLabel: entitiesMetadata.SALESFORCE.CAMPAIGN.pluralLabel,
                    entity: entitiesMetadata.SALESFORCE.CAMPAIGN.entity,
                    requiredFields: {
                        Name: {
                            id: 'Name',
                            label: 'Campaign Name',
                        },
                    },
                },
                Contact: {
                    displayName: entitiesMetadata.SALESFORCE.CONTACT.displayName,
                    pluralLabel: entitiesMetadata.SALESFORCE.CONTACT.pluralLabel,
                    entity: entitiesMetadata.SALESFORCE.CONTACT.entity,
                    requiredFields: {
                        LastName: {
                            id: 'LastName',
                            label: 'Last Name',
                        },
                    },
                },
                Task: {
                    displayName: entitiesMetadata.SALESFORCE.TASK.displayName,
                    pluralLabel: entitiesMetadata.SALESFORCE.TASK.pluralLabel,
                    entity: entitiesMetadata.SALESFORCE.TASK.entity,
                    requiredFields: {
                        WhoId: {
                            id: 'WhoId',
                            label: 'Name ID',
                        },
                        Status: {
                            id: 'Status',
                            label: 'Status',
                        },
                        Subject: {
                            id: 'Subject',
                            label: 'Subject',
                        },
                    },
                },
            },
            updateField: {
                useEntitySelector: true,
            },
            customActions: {
                COMMENT_ON_CASE: {
                    displayName: 'Comment on a Case',
                    entity: entitiesMetadata.SALESFORCE.CASE,
                    useExtendedMatchConfiguration: true,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: (fields) => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'comment_text',
                                displayName: 'Comment Text',
                                placeholder: 'Please fill out the text to put in the comment',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const commentText = fieldsValuesDefinition.tonkeanExpressionsValuesMap['comment_text'];

                        if (commentText) {
                            return {
                                url: {
                                    originalExpression: '/services/data/v39.0/sobjects/CaseComment',
                                    evaluatedExpression: '/services/data/v39.0/sobjects/CaseComment',
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{ "ParentId" : "{{TNK_ACTION_ENTITY_ID}}", "CommentBody" : "${commentText.evaluatedExpression}" } `,
                                    evaluatedExpression: `{ "ParentId" : "{{TNK_ACTION_ENTITY_ID}}", "CommentBody" : "${commentText.evaluatedExpression}" } `,
                                },
                                contentType: 'application/json',
                            };
                        }
                    },
                },
                POST_A_CHATTER: {
                    displayName: 'Post a Feed Item to Chatter',
                    useExtendedMatchConfiguration: true,
                    isCustomHttpAction: true,
                    canPerformOnVariousEntities: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'post_message',
                                displayName: 'Post Text',
                                tooltipDescription: 'Use \\n for a new line',
                                placeholder: 'Please fill out the text to put in the post',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const postMessage = fieldsValuesDefinition.tonkeanExpressionsValuesMap['post_message'];

                        if (postMessage) {
                            return {
                                url: {
                                    originalExpression:
                                        '/services/data/v47.0/chatter/feed-elements?feedElementType=FeedItem&subjectId={{TNK_ACTION_ENTITY_ID}}&text=New+post',
                                    evaluatedExpression:
                                        '/services/data/v47.0/chatter/feed-elements?feedElementType=FeedItem&subjectId={{TNK_ACTION_ENTITY_ID}}&text=New+post',
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{"body":{"messageSegments":[{"type" : "Text", "text" : "${postMessage.evaluatedExpression}" }]}, "feedElementType" : "FeedItem", "subjectId" : "{{TNK_ACTION_ENTITY_ID}}"}`,
                                    evaluatedExpression: `{"body":{"messageSegments":[{"type" : "Text", "text" : "${postMessage.evaluatedExpression}" }]}, "feedElementType" : "FeedItem", "subjectId" : "{{TNK_ACTION_ENTITY_ID}}"}`,
                                },
                                contentType: 'application/json',
                            };
                        }
                    },
                },
                UPDATE_CASE_STATUS: {
                    displayName: 'Update Case Status',
                    entity: entitiesMetadata.SALESFORCE.CASE,
                    useExtendedMatchConfiguration: true,
                    isCustomHttpAction: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'case_status',
                                displayName: 'Status',
                                placeholder: 'Please fill out the status to update',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const caseStatus = fieldsValuesDefinition.tonkeanExpressionsValuesMap['case_status'];

                        if (caseStatus) {
                            return {
                                url: {
                                    originalExpression: '/services/data/v20.0/sobjects/Case/{{TNK_ACTION_ENTITY_ID}}',
                                    evaluatedExpression: '/services/data/v20.0/sobjects/Case/{{TNK_ACTION_ENTITY_ID}}',
                                },
                                methodType: 'PATCH',
                                body: {
                                    originalExpression: `{ "Status" : "${
                                        fieldsValuesDefinition &&
                                        fieldsValuesDefinition.tonkeanExpressionsValuesMap &&
                                        caseStatus
                                            ? caseStatus.evaluatedExpression
                                            : ''
                                    }" } `,
                                    evaluatedExpression: `{ "Status" : "${
                                        fieldsValuesDefinition &&
                                        fieldsValuesDefinition.tonkeanExpressionsValuesMap &&
                                        caseStatus
                                            ? caseStatus.evaluatedExpression
                                            : ''
                                    }" } `,
                                },
                                contentType: 'application/json',
                            };
                        }
                    },
                },
                ADD_CAMPAIGN_MEMBER: {
                    displayName: 'Add Campaign Member',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'campaign_id',
                                displayName: 'Campaign ID',
                                tooltipDescription: 'Use the Campaign ID you would like to update',
                                placeholder: 'Use the Campaign ID you would like to update',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'lead_id',
                                displayName: 'Lead ID',
                                tooltipDescription: 'Use the Lead ID you would like to add',
                                placeholder: 'Lead ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                            },
                            {
                                id: 'contact_id',
                                displayName: 'Contact ID',
                                tooltipDescription: 'Use the Contact ID you would like to add',
                                placeholder: 'Contact ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                            },
                            {
                                id: 'status',
                                displayName: 'Status',
                                placeholder: 'Campaign member status',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const leadId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['lead_id'];
                        const contactId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['contact_id'];
                        const campaignId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['campaign_id'];
                        const status = fieldsValuesDefinition.tonkeanExpressionsValuesMap['status'];
                        let body = {};

                        if (campaignId && status) {
                            body = {
                                CampaignId: campaignId.evaluatedExpression,
                                Status: status.evaluatedExpression,
                            };
                        }
                        if (leadId && leadId.evaluatedExpression !== '') {
                            body.LeadId = leadId.evaluatedExpression;
                        }
                        if (contactId && contactId.evaluatedExpression !== '') {
                            body.ContactId = contactId.evaluatedExpression;
                        }

                        return {
                            url: {
                                originalExpression: '/services/data/v48.0/sobjects/CampaignMember/',
                                evaluatedExpression: '/services/data/v48.0/sobjects/CampaignMember/',
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression: JSON.stringify(body),
                                evaluatedExpression: JSON.stringify(body),
                            },
                            contentType: 'application/json',
                        };
                    },
                },
                REPLY_ON_CHATTER: {
                    displayName: 'Reply on Chatter',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'chatterId',
                                displayName: 'Chatter ID',
                                tooltipDescription: 'The Chatter ID you would like to update',
                                placeholder: 'The Chatter ID you would like to update',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'text',
                                displayName: 'Text',
                                tooltipDescription: 'Message Content',
                                placeholder: 'Message Content',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'mention',
                                displayName: 'Mention',
                                tooltipDescription: 'Mention ID',
                                placeholder: 'Mention ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const { chatterId, text, mention } = fieldsValuesDefinition.tonkeanExpressionsValuesMap;

                        const body = {
                            body: {
                                messageSegments: [
                                    {
                                        type: 'Text',
                                        text: text?.evaluatedExpression || '',
                                    },
                                ],
                            },
                        };

                        if (mention?.evaluatedExpression && mention.evaluatedExpression !== '') {
                            body.body.messageSegments.push({
                                type: 'Mention',
                                id: mention.evaluatedExpression,
                            });
                        }

                        return {
                            url: {
                                originalExpression: `/services/data/v48.0/chatter/feed-elements/${chatterId.evaluatedExpression}/capabilities/comments/items`,
                                evaluatedExpression: `/services/data/v48.0/chatter/feed-elements/${chatterId.evaluatedExpression}/capabilities/comments/items`,
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression: JSON.stringify(body),
                                evaluatedExpression: JSON.stringify(body),
                            },
                            contentType: 'application/json',
                        };
                    },
                },
                POST_FEED_ITEM: {
                    displayName: 'Post a Feed Item',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'subjectId',
                                displayName: 'Subject ID',
                                tooltipDescription:
                                    'Specify the user, group, or record ID that will parent the feed item',
                                placeholder: 'User, Group, or Record ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'text',
                                displayName: 'Text',
                                tooltipDescription: 'Message Content',
                                placeholder: 'Message Content',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'mention',
                                displayName: 'Mention',
                                tooltipDescription: 'Mention ID',
                                placeholder: 'Mention ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const { subjectId, text, mention } = fieldsValuesDefinition.tonkeanExpressionsValuesMap;

                        const body = {
                            body: {
                                messageSegments: [
                                    {
                                        type: 'Text',
                                        text: text?.evaluatedExpression || '',
                                    },
                                ],
                            },
                            feedElementType: 'FeedItem',
                            subjectId: subjectId.evaluatedExpression,
                        };

                        if (mention?.evaluatedExpression && mention.evaluatedExpression !== '') {
                            body.body.messageSegments.push({
                                type: 'Mention',
                                id: mention.evaluatedExpression,
                            });
                        }

                        return {
                            url: {
                                originalExpression: `/services/data/v48.0/chatter/feed-elements`,
                                evaluatedExpression: `/services/data/v48.0/chatter/feed-elements`,
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression: JSON.stringify(body),
                                evaluatedExpression: JSON.stringify(body),
                            },
                            contentType: 'application/json',
                        };
                    },
                },
                CONVERT_LEAD: {
                    displayName: 'Convert Lead',
                    entity: entitiesMetadata.SALESFORCE.LEAD,
                    useExtendedMatchConfiguration: true,
                },
                MERGE_RECORDS: {
                    displayName: 'Merge Records',
                    useExtendedMatchConfiguration: true,
                },
                UPLOAD_DOCUMENT: {
                    displayName: 'Upload Document',
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: false,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded ContentDocument ID',
                        },
                    ],
                },
                UPDATE_DOCUMENT: {
                    displayName: 'Update Document Version',
                    entity: entitiesMetadata.SALESFORCE.CONTENTDOCUMENT,
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: false,
                },
            },
        },
        GITLAB: {
            updateField: {
                Issue: {
                    displayName: 'Issue',
                    entity: 'Issue',
                    pluralLabel: 'Issues',
                },
            },
        },
        SALESFORCEIQ: {
            updateField: {
                ListItem: {
                    displayName: 'List Item',
                    pluralLabel: 'ListItems',
                },
            },
        },
        INTERCOM: {
            createNewItem: {
                PurchaseOrder: {
                    displayName: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.displayName,
                    pluralLabel: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.pluralLabel,
                    entity: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                        model: {
                            id: 'model',
                            label: 'Model',
                            description: 'The model that the data attribute belongs to. Options: contact & company',
                        },
                        "data_type": {
                            id: 'data_type',
                            label: 'Data Type',
                            description: 'The type of data stored for this attribute.. Options: string, integer, float, boolean, datetime & date',
                        },
                    },
                },
            },
            updateField: {
                SupplierInformation: {
                    displayName: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.displayName,
                    pluralLabel: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.pluralLabel,
                    entity: entitiesMetadata.INTERCOM.DATA_ATTRIBUTE.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                CLOSE_CONVERSATION: {
                    displayName: 'Close Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                ASSIGN_CONVERSATION: {
                    displayName: 'Assign a Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                SNOOZE_CONVERSATION: {
                    displayName: 'Snooze Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                UNSNOOZE_CONVERSATION: {
                    displayName: 'Unsnooze Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                REPLY_CONVERSATION: {
                    displayName: 'Reply/Add Note to Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                START_CONVERSATION: {
                    displayName: 'Start Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Conversation ID',
                        },
                    ],
                },
                CREATE_NOTE: {
                    displayName: 'Add Note to User',
                    entity: entitiesMetadata.INTERCOM.USER,
                },
                UPDATE_CUSTOM_ATTRIBUTES: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    displayName: 'Update Custom Attributes of a User',
                    entity: entitiesMetadata.INTERCOM.USER,
                },
                TAG_USER: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.USER.displayName} to tag?`,
                    displayName: 'Tag User',
                    entity: entitiesMetadata.INTERCOM.USER,
                },
                TAG_COMPANY: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.COMPANY.displayName} to tag?`,
                    displayName: 'Tag Company',
                    entity: entitiesMetadata.INTERCOM.COMPANY,
                },
                TAG_LEAD: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.LEAD.displayName} to tag?`,
                    displayName: 'Tag Lead',
                    entity: entitiesMetadata.INTERCOM.LEAD,
                },
                TAG_CONVERSATION: {
                    doesNotRequireActionPerformer: false,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.CONVERSATION.displayName} to tag?`,
                    displayName: 'Tag Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                UNTAG_CONVERSATION: {
                    doesNotRequireActionPerformer: false,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.CONVERSATION.displayName} to untag?`,
                    displayName: 'Untag Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                UNTAG_USER: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.USER.displayName} to untag?`,
                    displayName: 'Untag User',
                    entity: entitiesMetadata.INTERCOM.USER,
                },
                UNTAG_COMPANY: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.COMPANY.displayName} to untag?`,
                    displayName: 'Untag Company',
                    entity: entitiesMetadata.INTERCOM.COMPANY,
                },
                UNTAG_LEAD: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.LEAD.displayName} to untag?`,
                    displayName: 'Untag Lead',
                    entity: entitiesMetadata.INTERCOM.LEAD,
                },
                ADD_FORM_TO_CONVERSATION: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.CONVERSATION.displayName} to add from to?`,
                    displayName: 'Add Form to Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
                CREATE_USER: {
                    doesNotRequireActionPerformer: true,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create/Update User',
                    entity: entitiesMetadata.INTERCOM.USER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created User ID',
                        },
                    ],
                },
                ARCHIVE_USER: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.USER.displayName} to archive?`,
                    displayName: 'Archive User',
                    entity: entitiesMetadata.INTERCOM.USER,
                },
                REDACT_MESSAGE_FROM_CONVERSATION: {
                    doesNotRequireActionPerformer: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.INTERCOM.CONVERSATION.displayName} to redact from?`,
                    displayName: 'Redact Message From Conversation',
                    entity: entitiesMetadata.INTERCOM.CONVERSATION,
                },
            },
        },
        SENDGRID: {
            createOrUpdateItem: {
                Contact: {
                    displayName: entitiesMetadata.SENDGRID.CONTACT.displayName,
                    pluralLabel: entitiesMetadata.SENDGRID.CONTACT.pluralLabel,
                    entity: entitiesMetadata.SENDGRID.CONTACT.entity,
                    requiredFields: {
                        email: {
                            id: 'email',
                            label: 'Email Address',
                            description: "Contact's main Email address",
                        },
                    },
                    optionalFields: {
                        list_ids: {
                            id: 'list_ids',
                            label: 'List IDs',
                            description: 'Comma-separated IDs',
                        },
                        first_name: {
                            id: 'first_name',
                            label: 'First Name',
                        },
                        last_name: {
                            id: 'last_name',
                            label: 'Last Name',
                        },
                        address_line_1: {
                            id: 'address_line_1',
                            label: 'Address Line 1',
                        },
                        address_line_2: {
                            id: 'address_line_2',
                            label: 'Address Line 2',
                        },
                        city: {
                            id: 'city',
                            label: 'City',
                        },
                        state_province_region: {
                            id: 'state_province_region',
                            label: 'State/Province/Region',
                        },
                        country: {
                            id: 'country',
                            label: 'Country',
                        },
                        postal_code: {
                            id: 'postal_code',
                            label: 'Postal Code',
                        },
                        alternate_emails: {
                            id: 'alternate_emails',
                            label: 'Alternate Emails',
                            description: 'Comma-separated Emails (Max 5 items)',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created/Updated Contact ID',
                        },
                    ],
                },
            },
            customActions: {
                SEND_EMAIL: {
                    doesNotRequireEntity: true,
                    displayName: 'Send Email',
                },
                CREATE_CONTACT_LEGACY: {
                    displayName: 'Create Contact (Legacy Marketing)',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'email',
                                displayName: 'Email Address',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'first_name',
                                displayName: 'First Name',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'last_name',
                                displayName: 'Last Name',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const email = fieldsValuesDefinition.tonkeanExpressionsValuesMap['email'];
                        const firstName = fieldsValuesDefinition.tonkeanExpressionsValuesMap['first_name'];
                        const lastName = fieldsValuesDefinition.tonkeanExpressionsValuesMap['last_name'];

                        if (email && firstName && lastName) {
                            return {
                                url: {
                                    originalExpression: `/contactdb/recipients`,
                                    evaluatedExpression: `/contactdb/recipients`,
                                },
                                body: {
                                    originalExpression: `[{
                                        "email": "${email.evaluatedExpression}",
                                        "first_name": "${firstName.evaluatedExpression}",
                                        "last_name": "${lastName.evaluatedExpression}"
                                    }]`,
                                    evaluatedExpression: `[{
                                        "email": "${email.evaluatedExpression}",
                                        "first_name": "${firstName.evaluatedExpression}",
                                        "last_name": "${lastName.evaluatedExpression}"
                                    }]`,
                                },
                                methodType: 'POST',
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                ADD_CONTACT_TO_LIST_LEGACY: {
                    displayName: 'Add Contact to List (Legacy Marketing)',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'listId',
                                displayName: 'List ID',
                                placeholder: 'Enter your List ID here',
                                tooltipDescription: 'You can find your List ID in the URL of that list',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'contactId',
                                displayName: 'Contact ID',
                                placeholder: 'Enter your Contact IDs here',
                                tooltipDescription: 'Comma-separated list of contact IDs ',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const contactIds =
                            fieldsValuesDefinition.tonkeanExpressionsValuesMap['contactId']?.evaluatedExpression;
                        const listId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['listId'];
                        const contactsList = contactIds?.split(',').map((user) => `"${user.trim()}"`);

                        if (listId?.evaluatedExpression && contactsList) {
                            return {
                                url: {
                                    originalExpression: `/contactdb/lists/${listId.evaluatedExpression}/recipients`,
                                    evaluatedExpression: `/contactdb/lists/${listId.evaluatedExpression}/recipients`,
                                },
                                body: {
                                    originalExpression: `[${contactsList}]`,
                                    evaluatedExpression: `[${contactsList}]`,
                                },
                                methodType: 'POST',
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
            },
        },
        GMAIL: {
            doesNotRequireEntity: true,
            customActions: {
                SEND_EMAIL: {
                    displayName: 'Send Email',
                    entity: entitiesMetadata.GMAIL.MESSAGE,
                },
            },
        },
        FRESHDESK: {
            createNewItem: {
                Ticket: {
                    displayName: 'Ticket',
                    entity: 'Ticket',
                    pluralLabel: 'Tickets',
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                        email: {
                            id: 'email',
                            label: 'Requester (Email)',
                        },
                        priority: {
                            id: 'priority',
                            label: 'Priority',
                        },
                        status: {
                            id: 'status',
                            label: 'Status',
                        },
                        description: {
                            id: 'description',
                            label: 'Description',
                        },
                        subject: {
                            id: 'subject',
                            label: 'Subject',
                        },
                    },
                    optionalFields: {
                        agent: {
                            id: 'agent',
                            label: 'Agent (Email)',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Ticket ID',
                        },
                    ],
                },
            },
            updateField: {
                Ticket: {
                    displayName: 'Ticket',
                    entity: 'Ticket',
                    pluralLabel: 'Tickets',
                },
            },
        },
        TWILIOCHAT: {
            createNewItem: {
                Channel: {
                    displayName: 'Channel',
                    entity: 'Channel',
                    pluralLabel: 'Channels',
                    requiredFields: {
                        service_id: {
                            id: 'service_id',
                            label: 'Service Id',
                        },
                    },
                    optionalFields: {
                        UniqueName: {
                            id: 'UniqueName',
                            label: 'Unique Name',
                        },
                        FriendlyName: {
                            id: 'FriendlyName',
                            label: 'Friendly Name',
                        },
                        Attributes: {
                            id: 'Attributes',
                            label: 'Attributes',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Channel ID',
                        },
                    ],
                },
            },
            updateField: {
                Channel: {
                    displayName: 'Channel',
                    entity: 'Channel',
                    pluralLabel: 'Channels',
                },
            },
            customActions: {
                REPLY_TO_CHANNEL: {
                    displayName: 'Reply to Channel',
                    entity: entitiesMetadata.TWILIOCHAT.CHANNEL,
                },
                ADD_OR_UPDATE_CHANNEL_ATTRIBUTES: {
                    displayName: 'Add\\Update Channel Attributes',
                    entity: entitiesMetadata.TWILIOCHAT.CHANNEL,
                },
                ADD_OR_UPDATE_TASK_ATTRIBUTES: {
                    displayName: 'Add\\Update Task Attributes',
                    entity: entitiesMetadata.TWILIOCHAT.TASK,
                },
            },
        },
        FACEBOOKADS: {
            doesNotRequireEntity: true,
            customActions: {
                UPDATE_USER_IN_AUDIENCE: {
                    displayName: 'Add\\Remove User in Audience',
                },
            },
        },
        SLACK_APP: {
            customActions: {
                CREATE_CHANNEL: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Channel',
                    entity: entitiesMetadata.SLACKAPP.CHANNEL,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Channel ID',
                        },
                    ],
                },
                INVITE_USERS_TO_CHANNEL: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Invite Users to Channel',
                    entity: entitiesMetadata.SLACKAPP.CHANNEL,
                },
                REMOVE_USER_FROM_CONVERSATION: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Remove a user from a conversation',
                    entity: entitiesMetadata.SLACKAPP.CHANNEL,
                },
                SET_CHANNEL_TOPIC: {
                    displayName: 'Set Channel Topic',
                    entity: entitiesMetadata.SLACKAPP.CHANNEL,
                    useExtendedMatchConfiguration: true,
                },
                ARCHIVE_CHANNEL: {
                    displayName: 'Archive Channel',
                    entity: entitiesMetadata.SLACKAPP.CHANNEL,
                    useExtendedMatchConfiguration: true,
                },
                // Uncomment this when production got the reactions:write scope
                // REACT_ON_MESSAGE: {
                //     displayName: 'React On Slack Message',
                //     entity: entitiesMetadata.SLACKAPP.MESSAGE,
                //     useExtendedMatchConfiguration: true,
                // },
                CREATE_USER_GROUP: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: `Create ${entitiesMetadata.SLACKAPP.USER_GROUP.displayName}`,
                    entity: entitiesMetadata.SLACKAPP.USER_GROUP,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SLACKAPP.USER_GROUP.displayName} ID`,
                        },
                    ],
                },
                UPDATE_USER_GROUP_USERS: {
                    useExtendedMatchConfiguration: true,
                    displayName: `Update ${entitiesMetadata.SLACKAPP.USER_GROUP.displayName} Users`,
                    entity: entitiesMetadata.SLACKAPP.USER_GROUP,
                },
                GET_MESSAGE_PERMALINK: {
                    doesNotRequireEntity: true,
                    displayName: `Get Message Permalink`,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Message Permalink`,
                        },
                    ],
                },
            },
        },
        FRONTAPP: {
            customActions: {
                SET_ASSIGNEE: {
                    displayName: 'Assign a Conversation',
                    entity: entitiesMetadata.FRONTAPP.CONVERSATION,
                },
                REPLY_CONVERSATION: {
                    displayName: 'Reply/Add Note to Conversation',
                    entity: entitiesMetadata.FRONTAPP.CONVERSATION,
                },
                ARCHIVE_CONVERSATION: {
                    displayName: 'Archive a Conversation',
                    entity: entitiesMetadata.FRONTAPP.CONVERSATION,
                },
            },
        },
        DOCUSIGN: {
            createNewItem: {
                Envelope: {
                    displayName: entitiesMetadata.DOCUSIGN.ENVELOPE.displayName,
                    pluralLabel: entitiesMetadata.DOCUSIGN.ENVELOPE.pluralLabel,
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE.entity,
                    requiredFields: {
                        emailSubject: {
                            id: 'emailSubject',
                            label: 'Envelope Subject',
                        },
                        status: {
                            id: 'status',
                            label: 'Status',
                            description:
                                'Enter "sent" for sending the envelope immediately, for creating it as a draft enter "created".',
                        },
                    },
                    optionalFields: {
                        templateId: {
                            id: 'templateId',
                            label: 'Template ID',
                            description: 'You can get the Template ID from the url when you view the template.',
                        },
                        recipients: {
                            id: 'recipients',
                            label: 'Recipients',
                            description: 'Comma-separated list of recipients when using a template.',
                        },
                        emailBlurb: {
                            id: 'emailBlurb',
                            label: 'Email Message',
                            description: 'The email message that is sent to all envelope recipients.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Envelope ID',
                        },
                    ],
                },
            },
            customActions: {
                UPLOAD_ATTACHMENT: {
                    displayName: 'Add Document',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                },
                ADD_RECIPIENTS: {
                    displayName: 'Add Recipients to Envelope',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    isCustomHttpAction: true,
                    hide: true,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'accountId',
                                displayName: 'Account ID',
                                placeholder: 'Enter Your Account ID Here',
                                tooltipDescription: 'You can find your Account ID by clicking on your profile picture.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'recipients',
                                displayName: 'Recipients',
                                placeholder: 'Enter Your Recipients Here',
                                tooltipDescription: 'All the recipients to send the envelope to. (Separated by commas)',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const recipients =
                            fieldsValuesDefinition.tonkeanExpressionsValuesMap['recipients']?.evaluatedExpression;
                        const accountId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['accountId'];

                        const recipientsArray = recipients?.split(',').map(
                            (recipient, recipientId) => `{
                            "name": "${recipient.trim()}",
                            "email": "${recipient.trim()}",
                            "recipientId": ${recipientId + 1}
                        }`,
                        );

                        if (recipientsArray && accountId?.evaluatedExpression) {
                            return {
                                url: {
                                    originalExpression: `accounts/${accountId.evaluatedExpression}/envelopes/{{TNK_ACTION_ENTITY_ID}}`,
                                    evaluatedExpression: `accounts/${accountId.evaluatedExpression}/envelopes/{{TNK_ACTION_ENTITY_ID}}`,
                                },
                                methodType: 'PUT',
                                body: {
                                    originalExpression: `{
                                        "recipients": {
                                            "signers": [${recipientsArray}]
                                        }
                                    }`,
                                    evaluatedExpression: `{
                                        "recipients": {
                                            "signers": [${recipientsArray}]
                                        }
                                    }`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                ADD_RECIPIENTS_WITH_NAME: {
                    displayName: 'Add Recipients to Envelope',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    useExtendedMatchConfiguration: true,
                },
                SEND_ENVELOPE_BACKEND: {
                    displayName: 'Send an Envelope',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    useExtendedMatchConfiguration: true,
                },
                SEND_ENVELOPE: {
                    displayName: 'Send an Envelope Old',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    doesNotRequireEntity: true,
                    hide: true,
                    isCustomHttpAction: true,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'accountId',
                                displayName: 'Account ID',
                                placeholder: 'Enter your Account ID here',
                                tooltipDescription: 'You can find your Account ID by clicking on your profile picture.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                            {
                                id: 'envelopeId',
                                displayName: 'Envelope ID',
                                placeholder: 'Enter your Envelope ID here',
                                tooltipDescription:
                                    'You can get the Envelope ID from the url when you view the template.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const accountId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['accountId'];
                        const envelopeId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['envelopeId'];

                        if (accountId?.evaluatedExpression && envelopeId?.evaluatedExpression) {
                            return {
                                url: {
                                    originalExpression: `accounts/${accountId.originalExpression}/envelopes/${envelopeId.originalExpression}`,
                                    evaluatedExpression: `accounts/${accountId.evaluatedExpression}/envelopes/${envelopeId.evaluatedExpression}`,
                                },
                                methodType: 'PUT',
                                body: {
                                    originalExpression: `{
                                  "status": "sent"
                                }`,
                                    evaluatedExpression: `{
                                  "status": "sent"
                                }`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                TRANSFER_DOCUMENT: {
                    displayName: 'Transfer Document',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                CREATE_ENVELOPE_WITH_TABS: {
                    displayName: 'Create Envelope with Tabs',
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Envelope ID',
                        },
                    ],
                },
                LOCK_ENVELOPE: {
                    displayName: `Lock ${entitiesMetadata.DOCUSIGN.ENVELOPE.displayName}`,
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `${entitiesMetadata.DOCUSIGN.ENVELOPE.displayName} Lock Token`,
                        },
                    ],
                },
                REMOVE_ENVELOPE_LOCK: {
                    displayName: `Unlock ${entitiesMetadata.DOCUSIGN.ENVELOPE.displayName}`,
                    entity: entitiesMetadata.DOCUSIGN.ENVELOPE,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        ASANA: {
            createNewItem: {
                Task: {
                    displayName: entitiesMetadata.ASANA.TASK.displayName,
                    pluralLabel: entitiesMetadata.ASANA.TASK.pluralLabel,
                    entity: entitiesMetadata.ASANA.TASK.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                        workspace: {
                            id: 'workspace',
                            label: 'Workspace Name',
                            description:
                                'You can find your desired workspace when you click your profile picture (Case sensitive).',
                        },
                    },
                    optionalFields: {
                        projects: {
                            id: 'projects',
                            label: 'Project IDs',
                            description: 'Can be more than one (Separated by commas)',
                            defaultValueType: null,
                        },
                        notes: {
                            id: 'notes',
                            label: 'Description',
                        },
                        due_on: {
                            id: 'due_on',
                            label: 'Due on',
                        },
                        assignee: {
                            id: 'assignee',
                            label: 'Assignee',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Task ID',
                        },
                    ],
                },
            },
            updateField: {
                Task: {
                    displayName: entitiesMetadata.ASANA.TASK.displayName,
                    pluralLabel: entitiesMetadata.ASANA.TASK.pluralLabel,
                    entity: entitiesMetadata.ASANA.TASK.entity,
                },
            },
            customActions: {
                ADD_COMMENT: {
                    displayName: 'Add Comment',
                    entity: entitiesMetadata.ASANA.TASK,
                    doesNotRequireEntity: false,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'comment',
                                displayName: 'Comment',
                                placeholder: 'Enter your comment here',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const comment = fieldsValuesDefinition.tonkeanExpressionsValuesMap['comment'];

                        if (comment) {
                            return {
                                url: {
                                    originalExpression: `/tasks/{{TNK_ACTION_ENTITY_ID}}/stories`,
                                    evaluatedExpression: `/tasks/{{TNK_ACTION_ENTITY_ID}}/stories`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{
                                  "data": {
                                    "text": "${comment.evaluatedExpression}",
                                    "is_pinned": false
                                  }
                                }`,
                                    evaluatedExpression: `{
                                  "data": {
                                    "text": "${comment.evaluatedExpression}",
                                    "is_pinned": false
                                  }
                                }`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                ADD_TASK_TO_SECTION: {
                    displayName: 'Add Task to Section',
                    entity: entitiesMetadata.ASANA.TASK,
                    doesNotRequireEntity: false,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'section_gid',
                                displayName: 'Section ID',
                                placeholder: 'Enter section ID here',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const sectionId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['section_gid'];

                        if (sectionId?.evaluatedExpression) {
                            return {
                                url: {
                                    originalExpression: `/sections/${sectionId.evaluatedExpression}/addTask`,
                                    evaluatedExpression: `/sections/${sectionId.evaluatedExpression}/addTask`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{
                                  "data": {
                                    "task": "{{TNK_ACTION_ENTITY_ID}}"
                                  }
                                }`,
                                    evaluatedExpression: `{
                                  "data": {
                                    "task": "{{TNK_ACTION_ENTITY_ID}}"
                                  }
                                }`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                ADD_FOLLOWERS_TO_TASK: {
                    displayName: 'Add Followers to a Task',
                    entity: entitiesMetadata.ASANA.TASK,
                    doesNotRequireEntity: false,
                    isCustomHttpAction: true,
                    // If the fields are not valid then return the error message, otherwise, return nothing.
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'followers',
                                displayName: 'Followers',
                                placeholder: 'Comma-separated list of user IDs or Emails',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                // If the field is not valid then return the error message, otherwise, return nothing.
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const usersInput =
                            fieldsValuesDefinition.tonkeanExpressionsValuesMap['followers']?.evaluatedExpression;
                        const usersArray = usersInput?.split(',').map((user) => `"${user.trim()}"`);

                        if (usersArray && usersArray.length) {
                            return {
                                url: {
                                    originalExpression: `/tasks/{{TNK_ACTION_ENTITY_ID}}/addFollowers`,
                                    evaluatedExpression: `/tasks/{{TNK_ACTION_ENTITY_ID}}/addFollowers`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{
                                  "data": {
                                    "followers": [${usersArray}]
                                  }
                                }`,
                                    evaluatedExpression: `{
                                  "data": {
                                    "followers": [${usersArray}]
                                  }
                                }`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
                UPLOAD_ATTACHMENT: {
                    displayName: 'Upload Attachment',
                    entity: entitiesMetadata.ASANA.TASK,
                },
                DUPLICATE_TASK: {
                    doesNotRequireEntity: false,
                    createsEntity: true,
                    useExtendedMatchConfiguration: true,
                    displayName: 'Duplicate Task',
                    entity: entitiesMetadata.ASANA.TASK,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Duplicated Asana Task ID',
                        },
                    ],
                },
                DUPLICATE_PROJECT: {
                    doesNotRequireEntity: false,
                    createsEntity: true,
                    useExtendedMatchConfiguration: true,
                    displayName: 'Duplicate Project',
                    entity: entitiesMetadata.ASANA.PROJECT,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Duplicated Asana Project ID',
                        },
                    ],
                },
            },
        },
        ZUORA: {
            updateField: {
                Subscription: {
                    displayName: 'Subscription',
                    entity: 'Subscription',
                    pluralLabel: 'Subscriptions',
                },
                Account: {
                    displayName: 'Account',
                    entity: 'Account',
                    pluralLabel: 'Accounts',
                },
            },
        },
        PROSPERWORKS: {
            createNewItem: {
                Lead: {
                    displayName: entitiesMetadata.PROSPERWORKS.LEAD.displayName,
                    pluralLabel: entitiesMetadata.PROSPERWORKS.LEAD.pluralLabel,
                    entity: entitiesMetadata.PROSPERWORKS.LEAD.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Lead ID',
                        },
                    ],
                },
            },
            updateField: {
                Lead: {
                    displayName: entitiesMetadata.PROSPERWORKS.LEAD.displayName,
                    entity: entitiesMetadata.PROSPERWORKS.LEAD.entity,
                    pluralLabel: entitiesMetadata.PROSPERWORKS.LEAD.pluralLabel,
                },
            },
        },
        OKTA: {
            createNewItem: {
                User: {
                    displayName: entitiesMetadata.OKTA.USER.displayName,
                    pluralLabel: entitiesMetadata.OKTA.USER.pluralLabel,
                    entity: entitiesMetadata.OKTA.USER.entity,
                    requiredFields: {
                        'profile-login': {
                            id: 'profile-login',
                            label: 'Username',
                            description: 'Username of the Okta user, in email address format.',
                        },
                        'profile-firstName': {
                            id: 'profile-firstName',
                            label: 'First Name',
                            description: 'First name of the Okta user.',
                        },
                        'profile-lastName': {
                            id: 'profile-lastName',
                            label: 'Last Name',
                            description: 'Last name of the Okta user.',
                        },
                        'profile-email': {
                            id: 'profile-email',
                            label: 'Primary email',
                            description: 'Primary email address of the Okta user.',
                        },
                    },
                    optionalFields: {
                        'credentials-password': {
                            id: 'credentials-password',
                            label: 'Password',
                            description: 'Password that meets minimum criteria.',
                        },
                        activate: {
                            id: 'activate',
                            label: 'Activate',
                            description: 'User is activated upon creation. Options: true, false. Default: false.',
                        },
                        groupIds: {
                            id: 'groupIds',
                            label: 'Group IDs',
                            description: 'Comma-separated list of Group IDs',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created User ID',
                        },
                    ],
                },
            },
            updateField: {
                User: {
                    displayName: entitiesMetadata.OKTA.USER.displayName,
                    entity: entitiesMetadata.OKTA.USER.entity,
                    pluralLabel: entitiesMetadata.OKTA.USER.pluralLabel,
                    fieldFilter: (field) => field.name.indexOf('profile-') === 0,
                },
            },
            customActions: {
                ADD_USER_TO_GROUP: {
                    displayName: 'Add User to Group',
                    entity: entitiesMetadata.OKTA.USER,
                    useExtendedMatchConfiguration: true,
                },
                DEACTIVATE_USER: {
                    displayName: 'Deactivate User',
                    entity: entitiesMetadata.OKTA.USER,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        MAVENLINK: {
            createNewItem: {
                Task: {
                    displayName: entitiesMetadata.MAVENLINK.TASK.displayName,
                    pluralLabel: entitiesMetadata.MAVENLINK.TASK.pluralLabel,
                    entity: entitiesMetadata.MAVENLINK.TASK.entity,
                    requiredFields: {
                        title: {
                            id: 'title',
                            label: 'Title',
                        },
                        workspace_id: {
                            id: 'workspace_id',
                            label: 'Project ID',
                            description: 'The project ID where this story should be created',
                        },
                        story_type: {
                            id: 'story_type',
                            label: 'Type',
                            description: "Must be 'task', 'deliverable', 'milestone', or 'issue'",
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                            description: 'Must be less than 1000 characters',
                        },
                        start_date: {
                            id: 'start_date',
                            label: 'Start Date',
                            description: 'Projected start date of the project. Format should look like YYYY-MM-DD',
                        },
                        due_date: {
                            id: 'due_date',
                            label: 'Due Date',
                            description: 'Projected end date of the project. Format should look like YYYY-MM-DD',
                        },
                        assignee_ids: {
                            id: 'assignee_ids',
                            label: 'Assignee IDs',
                            description: 'Comma-separated list of Assignee IDs',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Project ID',
                        },
                    ],
                },
                CustomField: {
                    displayName: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.displayName,
                    pluralLabel: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.pluralLabel,
                    entity: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.entity,
                    requiredFields: {
                        subject_type: {
                            id: 'subject_type',
                            label: 'Subject Type',
                            description:
                                'The type of entity with which the custom field value is associated. Currently, Workspace is the only valid value.',
                        },
                        subject_id: {
                            id: 'subject_id',
                            label: 'Subject ID',
                            description: 'The ID of the entity type defined by the subject_type (eg. Project ID)',
                        },
                        custom_field_id: {
                            id: 'custom_field_id',
                            label: 'CustomField ID',
                            description: 'The ID of the associated custom field',
                        },
                        value: {
                            id: 'value',
                            label: 'Value',
                            description:
                                "string, date (eg. '1900-01-01'), number, currency (eg. [100, USD]) & single and multi (eg. [1, 2, 4])",
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created CustomField ID',
                        },
                    ],
                },
            },
            updateField: {
                Project: {
                    displayName: entitiesMetadata.MAVENLINK.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.MAVENLINK.PROJECT.pluralLabel,
                    entity: entitiesMetadata.MAVENLINK.PROJECT.entity,
                },
                Task: {
                    displayName: entitiesMetadata.MAVENLINK.TASK.displayName,
                    pluralLabel: entitiesMetadata.MAVENLINK.TASK.pluralLabel,
                    entity: entitiesMetadata.MAVENLINK.TASK.entity,
                },
                CustomField: {
                    displayName: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.displayName,
                    pluralLabel: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.pluralLabel,
                    entity: entitiesMetadata.MAVENLINK.CUSTOM_FIELD.entity,
                },
            },
        },
        SMARTSHEET: {
            createNewItem: {
                ROW: {
                    displayName: entitiesMetadata.SMARTSHEET.ROW.displayName,
                    pluralLabel: entitiesMetadata.SMARTSHEET.ROW.pluralLabel,
                    entity: entitiesMetadata.SMARTSHEET.ROW.entity,
                    requiredFields: {},
                    optionalFields: {
                        toTop: {
                            id: 'toTop',
                            label: 'Add row to top',
                            description: "Value can only be 'true'. If not, remove row.",
                        },
                    },
                    getDynamicRequiredFields: (entity, projectIntegration, tonkeanService) => {
                        if (entity === 'Row') {
                            if (projectIntegration?.projectData?.projectDatas[0]?.config?.titleFieldColumn) {
                                // We get the external fields so we can put the correct label on the field (otherwise,
                                // it's just an arbitrary integer).
                                return tonkeanService
                                    .getAvailableExternalFields(projectIntegration.id, ['Row'])
                                    .then((availableExternalFieldsData) => {
                                        const spreadsheetConfiguration =
                                            projectIntegration.projectData.projectDatas[0].config;

                                        return $q.resolve({
                                            [spreadsheetConfiguration.titleFieldColumn]: {
                                                id: spreadsheetConfiguration.titleFieldColumn,
                                                label: utils.findFirst(
                                                    availableExternalFieldsData.entitiesWithLabels,
                                                    (entityWithLabel) =>
                                                        entityWithLabel.name ===
                                                        spreadsheetConfiguration.titleFieldColumn,
                                                )?.label,
                                                description:
                                                    "The field that's configured as Title when importing into Tonkean (Datasource configuration)",
                                                defaultValueType: 'MANUAL',
                                            },
                                        });
                                    });
                            }
                        }

                        return $q.resolve();
                    },
                    getDynamicOptionalFields: (entity, projectIntegration, tonkeanService) => {
                        return $q.resolve();
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Row ID',
                        },
                    ],
                },
            },
            updateField: {
                Column: {
                    displayName: entitiesMetadata.SMARTSHEET.COLUMN.displayName,
                    pluralLabel: entitiesMetadata.SMARTSHEET.COLUMN.pluralLabel,
                    entity: entitiesMetadata.SMARTSHEET.COLUMN.entity,
                    useExtendedMatchConfiguration: true,
                },
                Row: {
                    displayName: entitiesMetadata.SMARTSHEET.ROW.displayName,
                    pluralLabel: entitiesMetadata.SMARTSHEET.ROW.pluralLabel,
                    entity: entitiesMetadata.SMARTSHEET.ROW.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                GET_ATTACHMENT_URL: {
                    displayName: 'Get Attachment URL',
                    entity: entitiesMetadata.SMARTSHEET.ATTACHEMNT,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Extracted Attachment URL',
                        },
                    ],
                    useExtendedMatchConfiguration: true,
                    actionValidator: () => {
                        return;
                    },
                },
            },
        },
        EXCEL365: {
            createNewItem: {
                Row: {
                    displayName: entitiesMetadata.EXCEL365.ROW.displayName,
                    entity: entitiesMetadata.EXCEL365.ROW.entity,
                    pluralLabel: entitiesMetadata.EXCEL365.ROW.pluralLabel,
                    requiredFields: {},
                    optionalFields: {
                        tableId: {
                            id: 'tableId',
                            label: 'Table ID (Optional)',
                            description: 'Enter the Table ID to append the new row within that table, or leave blank to append at the end of the spreadsheet.',
                        },
                    },
                    getDynamicRequiredFields: (entity, projectIntegration, tonkeanService) => {
                        if (entity === 'Row') {
                            if (projectIntegration?.projectData?.projectDatas[0]?.config?.titleFieldColumn) {
                                // We get the external fields so we can put the correct label on the field (otherwise,
                                // it's just an arbitrary integer).
                                return tonkeanService
                                    .getAvailableExternalFields(projectIntegration.id, ['Row'])
                                    .then((availableExternalFieldsData) => {
                                        const spreadsheetConfiguration =
                                            projectIntegration.projectData.projectDatas[0].config;

                                        return $q.resolve({
                                            [spreadsheetConfiguration.titleFieldColumn]: {
                                                id: spreadsheetConfiguration.titleFieldColumn,
                                                label: utils.findFirst(
                                                    availableExternalFieldsData.entitiesWithLabels,
                                                    (entityWithLabel) =>
                                                        entityWithLabel.name ===
                                                        spreadsheetConfiguration.titleFieldColumn,
                                                )?.label,
                                                description: "The field that's configured as Title",
                                                defaultValueType: 'MANUAL',
                                            },
                                        });
                                    });
                            }
                        }
                        return $q.resolve();
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Row ID',
                        },
                    ],
                },
            },
            updateField: {
                Row: {
                    displayName: entitiesMetadata.EXCEL365.ROW.displayName,
                    entity: entitiesMetadata.EXCEL365.ROW.entity,
                    pluralLabel: entitiesMetadata.EXCEL365.ROW.pluralLabel,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                APPEND_TABLE: {
                    displayName: 'Append Table',
                    entity: entitiesMetadata.EXCEL365.SPREADSHEET,
                    linkedFieldDefinitionsConfiguration: [],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.EXCEL365.SPREADSHEET.displayName}'s table you want to append?`,
                },
            },
        },
        SHAREPOINT: {
            createNewItem: {
                FOLDER: {
                    displayName: entitiesMetadata.SHAREPOINT.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.SHAREPOINT.FOLDER.pluralLabel,
                    entity: entitiesMetadata.SHAREPOINT.FOLDER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Folder Name',
                        },
                    },
                    optionalFields: {
                        parentId: {
                            id: 'parentId',
                            label: 'Parent ID',
                            description: 'If empty, the folder will be created on the root level.',
                        },
                        conflictBehavior: {
                            id: 'conflictBehavior',
                            label: 'Conflict Behavior',
                            description:
                                'The conflict resolution behavior for creating a folder.\n' +
                                'You can use "replace" (Default), "rename" or "fail".\n',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
            },
            updateField: {
                Folder: {
                    displayName: entitiesMetadata.SHAREPOINT.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.SHAREPOINT.FOLDER.pluralLabel,
                    entity: entitiesMetadata.SHAREPOINT.FOLDER.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                ADD_PERMISSIONS_TO_ITEM: {
                    displayName: 'Add Permission to File/Folder',
                    doesNotRequireEntity: true,
                },
                CREATE_SHARED_LINK: {
                    displayName: 'Create a Sharing Link',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Shared URL',
                        },
                    ],
                },
                CREATE_DOC_FROM_TEMPLATE: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Doc From Template',
                    entity: entitiesMetadata.SHAREPOINT.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Doc ID',
                        },
                    ],
                },
                CREATE_FILE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Create Text/PDF File',
                    createsEntity: true,
                    entity: entitiesMetadata.SHAREPOINT.FOLDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created File ID',
                        },
                    ],
                },
                DELETE_FILE: {
                    displayName: `Delete ${entitiesMetadata.SHAREPOINT.FILE.displayName}`,
                    entity: entitiesMetadata.SHAREPOINT.FILE,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SHAREPOINT.FILE.displayName} to delete?`,
                    useExtendedMatchConfiguration: true,
                },
                GRANT_ACCESS_TO_SHARED_LINK: {
                    displayName: 'Grant Access to Sharing Link',
                    doesNotRequireEntity: true,
                },
                MERGE_PDFS: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Merge PDF Files',
                    entity: entitiesMetadata.SHAREPOINT.FOLDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Merged PDF ID',
                        },
                    ],
                },
                MOVE_FILE: {
                    displayName: 'Move File/Folder',
                    entities: entitiesMetadata.SHAREPOINT,
                    linkedFieldDefinitionsConfiguration: [],
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SHAREPOINT.FILE.displayName}/${entitiesMetadata.SHAREPOINT.FOLDER.displayName} you want to move?`,
                },
                RENAME_FILE: {
                    displayName: 'Rename File/Folder',
                    entities: entitiesMetadata.SHAREPOINT,
                    linkedFieldDefinitionsConfiguration: [],
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SHAREPOINT.FILE.displayName}/${entitiesMetadata.SHAREPOINT.FOLDER.displayName} you want to rename?`,
                },
                UPLOAD_FILE: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: false,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.SHAREPOINT.FOLDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                CREATE_PREVIEW_LINK: {
                    displayName: 'Create a Preview Link',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Preview URL',
                        },
                    ],
                },
                GET_FILE_CONTENT: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Get File Content',
                    entity: entitiesMetadata.SHAREPOINT.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'SharePoint File Content',
                        },
                    ],
                    itemMatchQuestion: 'Which file to get the content from?',
                },
            },
        },
        ONEDRIVE365: {},
        SMARTSHEETWORKSPACE: {
            customActions: {
                COPY_ROWS: {
                    displayName: 'Copy Rows to Another Sheet',
                    entity: entitiesMetadata.SMARTSHEETWORKSPACE.SHEET,
                    linkedFieldDefinitionsConfiguration: [],
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SMARTSHEETWORKSPACE.SHEET.displayName} to copy rows from?`,
                    actionValidator: () => {
                        return;
                    },
                },
                MOVE_SHEET: {
                    displayName: 'Move Sheet to Folder',
                    entity: entitiesMetadata.SMARTSHEETWORKSPACE.SHEET,
                    isCustomHttpAction: true,
                    useExtendedMatchConfiguration: true,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SMARTSHEETWORKSPACE.SHEET.displayName} to move?`,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'destinationFolderId',
                                displayName: 'Destination Folder ID',
                                placeholder: 'Enter destination folder ID here',
                                tooltipDescription:
                                    'You can find the folder ID by right clicking on the folder and then click on properties.',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                                fieldValidator: (field) => {
                                    return;
                                },
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        let destinationFolderId =
                            fieldsValuesDefinition.tonkeanExpressionsValuesMap['destinationFolderId'];

                        if (destinationFolderId) {
                            return {
                                url: {
                                    originalExpression: `/sheets/{{TNK_ACTION_ENTITY_ID}}/move/`,
                                    evaluatedExpression: `/sheets/{{TNK_ACTION_ENTITY_ID}}/move/`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{"destinationType": "folder","destinationId": "${destinationFolderId.evaluatedExpression}"}`,
                                    evaluatedExpression: `{"destinationType": "folder","destinationId": "${destinationFolderId.evaluatedExpression}"}`,
                                },
                                contentType: 'application/json',
                                accept: 'application/json',
                            };
                        }
                    },
                },
            },
        },
        TEAMCONNECT: {
            updateField: {
                Matter: {
                    displayName: entitiesMetadata.TEAMCONNECT.MATTER.displayName,
                    entity: entitiesMetadata.TEAMCONNECT.MATTER.entity,
                    pluralLabel: entitiesMetadata.TEAMCONNECT.MATTER.pluralLabel,
                },
            },
        },
        SERVICENOW: {
            createNewItem: {
                Incident: {
                    displayName: entitiesMetadata.SERVICENOW.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.INCIDENT.entity,
                    requiredFields: {
                        short_description: {
                            id: 'short_description',
                            label: 'Short Description',
                        },
                        caller_id: {
                            id: 'caller_id',
                            label: 'Caller',
                            description: 'The User ID of the creator',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                            description: 'Full incident description',
                        },
                        urgency: {
                            id: 'urgency',
                            label: 'Urgency',
                            description: '1 - High, 2 - Medium, 3 - Low',
                        },
                        impact: {
                            id: 'impact',
                            label: 'Impact',
                            description: '1 - High, 2 - Medium, 3 - Low',
                        },
                        work_notes: {
                            id: 'work_notes',
                            label: 'Work Note',
                            description: '',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Incident ID',
                        },
                    ],
                },
                CatalogTask: {
                    displayName: entitiesMetadata.SERVICENOW.CATALOG_TASK.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.CATALOG_TASK.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.CATALOG_TASK.entity,
                    requiredFields: {
                        short_description: {
                            id: 'short_description',
                            label: 'Short Description',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                            description: 'Full incident description',
                        },
                        priority: {
                            id: 'priority',
                            label: 'Priority',
                            description: '1 - Critical, 2 - High, 3 - Moderate, 4 - Low, 5 - Planing',
                        },
                        work_notes: {
                            id: 'work_notes',
                            label: 'Work Note',
                            description: '',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SERVICENOW.CATALOG_TASK.displayName} ID`,
                        },
                    ],
                },
                Project: {
                    displayName: entitiesMetadata.SERVICENOW.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.PROJECT.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.PROJECT.entity,
                    requiredFields: {
                        short_description: {
                            id: 'short_description',
                            label: 'Short Description',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                            description: 'Full incident description',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SERVICENOW.PROJECT.displayName} ID`,
                        },
                    ],
                },
                ProjectTask: {
                    displayName: entitiesMetadata.SERVICENOW.PROJECT_TASK.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.PROJECT_TASK.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.PROJECT_TASK.entity,
                    requiredFields: {
                        short_description: {
                            id: 'short_description',
                            label: 'Short Description',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                            description: 'Full incident description',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SERVICENOW.PROJECT_TASK.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                Incident: {
                    displayName: entitiesMetadata.SERVICENOW.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.INCIDENT.entity,
                },
                CatalogTask: {
                    displayName: entitiesMetadata.SERVICENOW.CATALOG_TASK.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.CATALOG_TASK.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.CATALOG_TASK.entity,
                },
                Project: {
                    displayName: entitiesMetadata.SERVICENOW.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.PROJECT.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.PROJECT.entity,
                },
                ProjectTask: {
                    displayName: entitiesMetadata.SERVICENOW.PROJECT_TASK.displayName,
                    pluralLabel: entitiesMetadata.SERVICENOW.PROJECT_TASK.pluralLabel,
                    entity: entitiesMetadata.SERVICENOW.PROJECT_TASK.entity,
                },
            },
            customActions: {
                UPLOAD_ATTACHMENT: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Add Attachment To Incident',
                    entity: entitiesMetadata.SERVICENOW.INCIDENT,
                },
                UPLOAD_ATTACHMENT_TO_TABLE: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: true,
                    displayName: 'Add Attachment To Table',
                },
            },
        },
        AMAZONS3: {
            customActions: {
                UPLOAD_FILE: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.AMAZONS3.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                UPLOAD_FILE_FROM_URL: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    hide: true,
                    displayName: 'Upload File From Url',
                    entity: entitiesMetadata.AMAZONS3.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created File ID',
                        },
                    ],
                },
                GENERATE_SIGNED_URL: {
                    createsEntity: false,
                    doesNotRequireEntity: true,
                    displayName: 'Generate a Signed URL',
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Generated Signed URL',
                        },
                    ],
                },
            },
        },
        MAILCHIMPTRANSACTIONAL: {
            customActions: {
                SEND_MESSAGE_USING_TEMPLATE: {
                    doesNotRequireEntity: true,
                    displayName: 'Send Message Using Template',
                    entity: entitiesMetadata.MAILCHIMPTRANSACTIONAL.TEMPLATE,
                    linkedFieldDefinitionsConfiguration: [],
                    useExtendedMatchConfiguration: false,
                },
                SEND_MESSAGE: {
                    doesNotRequireEntity: true,
                    displayName: 'Send Message',
                    linkedFieldDefinitionsConfiguration: [],
                    useExtendedMatchConfiguration: false,
                },
            },
        },
        MAILCHIMPMARKETING: {
            customActions: {
                ADD_MEMBER_TO_AUDIENCE: {
                    doesNotRequireEntity: false,
                    useExtendedMatchConfiguration: true,
                    createsEntity: true,
                    displayName: 'Add Member To Audience List',
                    entity: entitiesMetadata.MAILCHIMPMARKETING.AUDIENCE,
                },
            },
        },
        MARKETO: {
            createOrUpdateItem: {
                Lead: {
                    displayName: entitiesMetadata.MARKETO.LEAD.displayName,
                    pluralLabel: entitiesMetadata.MARKETO.LEAD.pluralLabel,
                    entity: entitiesMetadata.MARKETO.LEAD.entity,
                    requiredFields: {
                        email: {
                            id: 'email',
                            label: 'Email',
                            description: '',
                        },
                    },
                    optionalFields: {
                        firstName: {
                            id: 'firstName',
                            label: 'First Name',
                            description: `Contact's first name.`,
                        },
                        lastName: {
                            id: 'lastName',
                            label: 'Last Name',
                            description: `Contact's last name.`,
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Lead ID',
                        },
                    ],
                },
            },
            customActions: {
                ADD_LEAD_TO_LIST: {
                    displayName: 'Add Lead to List',
                    doesNotRequireEntity: true,
                    isCustomHttpAction: true,
                    fieldsConfiguration: {
                        fields: [
                            {
                                id: 'list_id',
                                displayName: 'List ID',
                                tooltipDescription: 'Use the List ID you would like to update',
                                placeholder: 'Use the List ID you would like to update',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                            {
                                id: 'lead_id',
                                displayName: 'Lead ID',
                                tooltipDescription: 'Use the Lead ID you would like to add',
                                placeholder: 'Lead ID',
                                type: this.presentationalComponentType.tonkeanExpressionInput.id,
                                isRequired: true,
                            },
                        ],
                    },
                    convertToHttpDefinition: (fieldsValuesDefinition) => {
                        const leadId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['lead_id'];
                        const listId = fieldsValuesDefinition.tonkeanExpressionsValuesMap['list_id'];
                        if (leadId && listId) {
                            return {
                                url: {
                                    originalExpression: `/rest/v1/lists/${listId.evaluatedExpression}/leads.json?id=${leadId.evaluatedExpression}`,
                                    evaluatedExpression: `/rest/v1/lists/${listId.evaluatedExpression}/leads.json?id=${leadId.evaluatedExpression}`,
                                },
                                methodType: 'POST',
                                body: {
                                    originalExpression: `{}`,
                                    evaluatedExpression: `{}`,
                                },
                                contentType: 'application/json',
                            };
                        }
                    },
                },
                CREATE_EXPORT_JOB: {
                    doesNotRequireEntity: true,
                    displayName: `Create and Start ${entitiesMetadata.MARKETO.EXPORT_JOB.displayName}`,
                    entity: entitiesMetadata.MARKETO.EXPORT_JOB,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.MARKETO.EXPORT_JOB.displayName} ID`,
                        },
                    ],
                },
            },
        },
        GONG: {
            customActions: {
                GET_TRANSCRIPT: {
                    displayName: `Get Call Transcript`,
                    entity: entitiesMetadata.GONG.CALL,
                    useExtendedMatchConfiguration: true,
                    isCustomHttpAction: true,
                    actionValidator: () => {
                        return;
                    },
                    fieldsConfiguration: {
                        fields: [],
                    },
                    convertToHttpDefinition: () => {
                        return {
                            url: {
                                originalExpression: `/v2/calls/transcript`,
                                evaluatedExpression: `/v2/calls/transcript`,
                            },
                            methodType: 'POST',
                            body: {
                                originalExpression: `{"filter": {"callIds": ["{{TNK_ACTION_ENTITY_ID}}"]}}`,
                                evaluatedExpression: `{"filter": {"callIds": ["{{TNK_ACTION_ENTITY_ID}}"]}}`,
                            },
                            contentType: 'application/json',
                            accept: 'application/json',
                        };
                    },
                },
            },
        },
        SFTP: {
            customActions: {
                UPLOAD_FILE: {
                    displayName: 'Upload File',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [],
                },
                MOVE_FILE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Move File',
                    entity: entitiesMetadata.SFTP.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Moved File ID',
                        },
                    ],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.SFTP.FILE} to Move?`,
                }
            },
        },
        SPRINGCM: {
            createNewItem: {
                Folder: {
                    displayName: entitiesMetadata.SPRINGCM.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.SPRINGCM.FOLDER.pluralLabel,
                    entity: entitiesMetadata.SPRINGCM.FOLDER.entity,
                    requiredFields: {
                        Name: {
                            id: 'Name',
                            label: 'Name',
                        },
                        ParentFolder: {
                            id: 'ParentFolder',
                            label: 'Parent Folder ID',
                            description: 'The destination folder',
                        },
                    },
                    optionalFields: {
                        Description: {
                            id: 'Description',
                            label: 'Description',
                            description: 'Folder description',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
            },
            updateField: {
                Document: {
                    displayName: entitiesMetadata.SPRINGCM.DOCUMENT.displayName,
                    pluralLabel: entitiesMetadata.SPRINGCM.DOCUMENT.pluralLabel,
                    entity: entitiesMetadata.SPRINGCM.DOCUMENT.entity,
                },
            },
            customActions: {
                GENERATE_DOCUMENT_FROM_XML: {
                    displayName: 'Generate Document From XML',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Generated Document ID',
                        },
                    ],
                },
                UPLOAD_DOCUMENT: {
                    displayName: 'Upload Document',
                    entity: entitiesMetadata.SPRINGCM.DOCUMENT,
                    doesNotRequireEntity: true,
                    customExtendedMatchConfigurationTitle: `To Which ${entitiesMetadata.SPRINGCM.FOLDER.displayName} To Upload The ${entitiesMetadata.SPRINGCM.DOCUMENT.displayName}?`,
                    useExtendedMatchConfiguration: false,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded Document ID',
                        },
                    ],
                },
                MOVE_DOCUMENT: {
                    displayName: 'Move Document',
                    entity: entitiesMetadata.SPRINGCM.DOCUMENT,
                    useExtendedMatchConfiguration: true,
                    createsEntity: true,
                },
            },
        },
        DATADOG: {
            createNewItem: {
                Incident: {
                    displayName: entitiesMetadata.DATADOG.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.DATADOG.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.DATADOG.INCIDENT.entity,
                    requiredFields: {
                        title: {
                            id: 'title',
                            label: 'Title',
                            description: 'The title of the incident, which summarizes what happened.',
                        },
                    },
                    optionalFields: {
                        customer_impact_scope: {
                            id: 'customer_impact_scope',
                            label: 'Customer Impact Scope',
                            description: `If empty, it means it didn't impacted any customer`,
                        },
                        severity: {
                            id: 'severity',
                            label: 'Severity',
                            description:
                                'SEV-1 (Critical), SEV-2 (High), SEV-3 (Moderate), SEV-4 (Low), SEV-5 (Minor), Unknown',
                        },
                        notification_handles: {
                            id: 'notification_handles',
                            label: 'Notification Handles',
                            description:
                                'Notification handles that will be notified of the incident at creation (Emails separated by comma).',
                        },
                        root_cause: {
                            id: 'root_cause',
                            label: 'Root Cause',
                            description: 'What is the root cause of the issue?',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Incident ID',
                        },
                    ],
                },
            },
            updateField: {
                Incident: {
                    displayName: entitiesMetadata.DATADOG.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.DATADOG.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.DATADOG.INCIDENT.entity,
                },
            },
        },
        CONCUR: {
            createNewItem: {
                Incident: {
                    displayName: entitiesMetadata.CONCUR.REPORT.displayName,
                    pluralLabel: entitiesMetadata.CONCUR.REPORT.pluralLabel,
                    entity: entitiesMetadata.CONCUR.REPORT.entity,
                    requiredFields: {
                        name: {
                            id: 'Name',
                            label: 'Name',
                            description: 'The name of the report.',
                        },
                    },
                    optionalFields: {
                        purpose: {
                            id: 'Purpose',
                            label: 'Purpose',
                            description: `The business purpose of the report.`,
                        },
                        comment: {
                            id: 'Comment',
                            label: 'Comment',
                            description: `The report header comment.`,
                        },
                        policyID: {
                            id: 'PolicyID',
                            label: 'Policy ID',
                            description: `The unique identifier for the policy.`,
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Expense Report ID',
                        },
                    ],
                },
            },
            updateField: {
                Incident: {
                    displayName: entitiesMetadata.CONCUR.REPORT.displayName,
                    pluralLabel: entitiesMetadata.CONCUR.REPORT.pluralLabel,
                    entity: entitiesMetadata.CONCUR.REPORT.entity,
                },
            },
        },
        GOODTIME: {
            customActions: {
                ADD_TAGS: {
                    displayName: 'Add tags to a contact',
                    entity: entitiesMetadata.GOODTIME.CONTACT,
                    customExtendedMatchConfigurationTitle: `To which ${entitiesMetadata.GOODTIME.CONTACT.displayName} add the ${entitiesMetadata.GOODTIME.TAG.pluralLabel}?`,
                    useExtendedMatchConfiguration: true,
                },
                DELETE_TAG: {
                    displayName: 'Delete a tag from a contact',
                    entity: entitiesMetadata.GOODTIME.CONTACT,
                    customExtendedMatchConfigurationTitle: `From which ${entitiesMetadata.GOODTIME.CONTACT.displayName} to delete the ${entitiesMetadata.GOODTIME.TAG.displayName}?`,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        BOX: {
            createNewItem: {
                FOLDER: {
                    displayName: entitiesMetadata.BOX.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.BOX.FOLDER.pluralLabel,
                    entity: entitiesMetadata.BOX.FOLDER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Folder Name',
                        },
                    },
                    optionalFields: {
                        parentId: {
                            id: 'parentId',
                            label: 'Parent ID',
                            description: 'If empty, the folder will be created in the integration selected folder.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Folder ID',
                        },
                    ],
                },
            },
            createOrUpdateItem: {
                Folder: {
                    displayName: entitiesMetadata.BOX.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.BOX.FOLDER.pluralLabel,
                    entity: entitiesMetadata.BOX.FOLDER.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Folder Name',
                        },
                    },
                    optionalFields: {
                        parentId: {
                            id: 'parentId',
                            label: 'Parent ID',
                            description: 'If empty, the folder will be created in the integration selected folder.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created/Updated ${entitiesMetadata.BOX.FOLDER.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                FOLDER: {
                    displayName: entitiesMetadata.BOX.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.BOX.FOLDER.pluralLabel,
                    entity: entitiesMetadata.BOX.FOLDER.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                COPY_FOLDER: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Copy Folder',
                    entity: entitiesMetadata.BOX.FOLDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Copied Folder ID',
                        },
                    ],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FOLDER.displayName} to copy?`,
                },
                COPY_FILE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Copy File',
                    entity: entitiesMetadata.BOX.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Copied File ID',
                        },
                    ],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FILE.displayName} to copy?`,
                },
                MOVE_FOLDER: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Move Folder',
                    entity: entitiesMetadata.BOX.FOLDER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FOLDER.displayName} to move?`,
                },
                MOVE_FILE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Move File',
                    entity: entitiesMetadata.BOX.FILE,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FILE.displayName} to move?`,
                },
                SHARE_FOLDER: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Add Shared Link to Folder',
                    entity: entitiesMetadata.BOX.FOLDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Folder Shared Link URL',
                        },
                    ],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FOLDER.displayName} to share?`,
                },
                SHARE_FILE: {
                    useExtendedMatchConfiguration: true,
                    displayName: 'Add Shared Link to File',
                    entity: entitiesMetadata.BOX.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'File Shared Link URL',
                        },
                    ],
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.BOX.FILE.displayName} to share?`,
                },
                CREATE_COLLABORATION: {
                    doesNotRequireEntity: true,
                    displayName: 'Add Collaboration',
                },
                UPLOAD_FILE: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: false,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.BOX.FOLDER,
                    customExtendedMatchConfigurationTitle: `To which ${entitiesMetadata.BOX.FOLDER.displayName} upload the ${entitiesMetadata.BOX.FILE.displayName}?`,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
            },
        },
        MICROSOFTADMIN: {
            createNewItem: {
                User: {
                    displayName: entitiesMetadata.MICROSOFTADMIN.USER.displayName,
                    pluralLabel: entitiesMetadata.MICROSOFTADMIN.USER.pluralLabel,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER.entity,
                    requiredFields: {},
                    optionalFields: {
                        accountEnabled: {
                            id: 'accountEnabled',
                            label: 'Account Enabled',
                            description: `true if the account is enabled; otherwise, false.`,
                        },
                        displayName: {
                            id: 'displayName',
                            label: 'Display Name',
                            description: `The name to display in the address book for the user.`,
                        },
                        mailNickname: {
                            id: 'mailNickname',
                            label: 'Mail Nickname',
                            description: `The mail alias for the user.`,
                        },
                        userPrincipalName: {
                            id: 'userPrincipalName',
                            label: 'User Principal Name',
                            description: `The user principal name (someuser@company.com)`,
                        },
                        forceChangePasswordNextSignIn: {
                            id: 'forceChangePasswordNextSignIn',
                            label: 'Force Change Password',
                            description: `true to force change password on next sign in; otherwise, false.`,
                        },
                        password: {
                            id: 'password',
                            label: 'Password',
                            description: `The password for the user.`,
                        },
                        passwordPolicies: {
                            id: 'passwordPolicies',
                            label: 'Password Policy',
                            description: `Options: DisablePasswordExpiration, DisableStrongPassword`,
                        },
                        onPremisesImmutableId: {
                            id: 'onPremisesImmutableId',
                            label: 'Premises Immutable ID',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created User ID',
                        },
                    ],
                },
            },
            updateField: {
                User: {
                    displayName: entitiesMetadata.MICROSOFTADMIN.USER.displayName,
                    pluralLabel: entitiesMetadata.MICROSOFTADMIN.USER.pluralLabel,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                RESET_USER_PASSWORD: {
                    displayName: `Reset ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}'s Password`,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Reset ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}'s Password`,
                        },
                    ],
                },
                DELETE_USER: {
                    displayName: `Delete ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to delete?`,
                    useExtendedMatchConfiguration: true,
                },
                RESTORE_USER: {
                    displayName: `Restore ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                },
                ASSIGN_USER_TO_MANAGER: {
                    displayName: `Assign ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to Manager`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to assign?`,
                    useExtendedMatchConfiguration: true,
                    createsEntity: true,
                },
                REMOVE_USER_MANAGER: {
                    displayName: `Remove ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}'s Manager`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
                CHANGE_MFA_STATE: {
                    displayName: `Change MFA State for ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to change the state for?`,
                    useExtendedMatchConfiguration: true,
                    createsEntity: true,
                },
                ADD_USER_TO_GROUP: {
                    displayName: `Add ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to Group`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to add?`,
                    useExtendedMatchConfiguration: true,
                    createsEntity: true,
                },
                REMOVE_USER_FROM_GROUP: {
                    displayName: `Remove ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} from Group`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to remove?`,
                    useExtendedMatchConfiguration: true,
                },
                REMOVE_USER_FROM_ALL_GROUPS: {
                    displayName: `Remove ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} from all Groups`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to remove?`,
                    useExtendedMatchConfiguration: true,
                },
                INVALIDATE_USER_REFRESH_TOKENS: {
                    displayName: `Invalidate ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} Refresh Tokens`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to invalidate?`,
                    useExtendedMatchConfiguration: true,
                },
                ADD_REMOVE_USER_LICENSES: {
                    displayName: `Add/Remove ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} Licenses`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
                ADD_USER_DESCRIPTION: {
                    displayName: `Add ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} Description`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
                UPDATE_USER_USAGE_LOCATION: {
                    displayName: `Update ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}'s Usage Location`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
                ADD_USER_TO_POLICIES: {
                    displayName: `Add Policies to ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
                REMOVE_USER_FROM_POLICIES: {
                    displayName: `Remove Policies from ${entitiesMetadata.MICROSOFTADMIN.USER.displayName}`,
                    entity: entitiesMetadata.MICROSOFTADMIN.USER,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.MICROSOFTADMIN.USER.displayName} to update?`,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        ORIGAMIRISK: {
            createNewItem: {
                useEntitySelector: true,
            },
            updateField: {
                useEntitySelector: true,
            },
            customActions: {
                UPLOAD_FILE: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: true,
                    createsEntity: false,
                    displayName: 'Upload File',
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
            }
        },
        SHOPIFY: {
            createNewItem: {
                CUSTOMER: {
                    displayName: entitiesMetadata.SHOPIFY.CUSTOMER.displayName,
                    pluralLabel: entitiesMetadata.SHOPIFY.CUSTOMER.pluralLabel,
                    entity: entitiesMetadata.SHOPIFY.CUSTOMER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        first_name: {
                            id: 'first_name',
                            label: 'First Name',
                        },
                        last_name: {
                            id: 'last_name',
                            label: 'Last Name',
                        },
                        email: {
                            id: 'email',
                            label: 'Email',
                        },
                    },
                    optionalFields: {
                        phone: {
                            id: 'phone',
                            label: 'Phone',
                        },
                        note: {
                            id: 'note',
                            label: 'Note',
                        },
                        defaultAddressCompany: {
                            id: 'default_address-company',
                            label: 'Default Address / Company Name',
                        },
                        defaultAddressAddress1: {
                            id: 'default_address-address1',
                            label: 'Default Address / Street Address',
                        },
                        defaultAddressAddress2: {
                            id: 'default_address-address2',
                            label: 'Default Address / Street Address Line 2',
                        },
                        defaultAddressCity: {
                            id: 'default_address-city',
                            label: 'Default Address / City',
                        },
                        defaultAddressProvince: {
                            id: 'default_address-province',
                            label: 'Default Address / State/Province',
                        },
                        defaultAddressProvinceCode: {
                            id: 'default_address-province_code',
                            label: 'Default Address / State/Province Code',
                            description: 'E.g. "CA" or "TX"',
                        },
                        defaultAddressCountry: {
                            id: 'default_address-country',
                            label: 'Default Address / Country',
                        },
                        defaultAddressCountryCode: {
                            id: 'default_address-country_code',
                            label: 'Default Address / Country Code',
                        },
                        defaultAddressZip: {
                            id: 'default_address-zip',
                            label: 'Default Address / Zip Code',
                        },
                        tags: {
                            id: 'tags',
                            label: 'Tags',
                        },
                        accepts_marketing: {
                            id: 'accepts_marketing',
                            label: 'Accept Marketing',
                            description: 'Options: true, false. Default: false;',
                        },
                        tax_exempt: {
                            id: 'tax_exempt',
                            label: 'Tax Exempt',
                            description: 'Options: true, false. Default: false;',
                        },
                        send_email_invite: {
                            id: 'send_email_invite',
                            label: 'Send Email Invite',
                            description: 'Options: true, false. Default: false;',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SHOPIFY.CUSTOMER.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                CUSTOMER: {
                    displayName: entitiesMetadata.SHOPIFY.CUSTOMER.displayName,
                    pluralLabel: entitiesMetadata.SHOPIFY.CUSTOMER.pluralLabel,
                    entity: entitiesMetadata.SHOPIFY.CUSTOMER.entity,
                    useExtendedMatchConfiguration: true,
                },
                ORDER: {
                    displayName: entitiesMetadata.SHOPIFY.ORDER.displayName,
                    pluralLabel: entitiesMetadata.SHOPIFY.ORDER.pluralLabel,
                    entity: entitiesMetadata.SHOPIFY.ORDER.entity,
                    useExtendedMatchConfiguration: true,
                },
                PRODUCT: {
                    displayName: entitiesMetadata.SHOPIFY.PRODUCT.displayName,
                    pluralLabel: entitiesMetadata.SHOPIFY.PRODUCT.pluralLabel,
                    entity: entitiesMetadata.SHOPIFY.PRODUCT.entity,
                    useExtendedMatchConfiguration: true,
                },
                PRODUCTVARIANT: {
                    displayName: entitiesMetadata.SHOPIFY.PRODUCTVARIANT.displayName,
                    pluralLabel: entitiesMetadata.SHOPIFY.PRODUCTVARIANT.pluralLabel,
                    entity: entitiesMetadata.SHOPIFY.PRODUCTVARIANT.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                CREATE_ORDER: {
                    doesNotRequireEntity: true,
                    displayName: `Create ${entitiesMetadata.SHOPIFY.ORDER.displayName}`,
                    entity: entitiesMetadata.SHOPIFY.ORDER,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SHOPIFY.ORDER.displayName} ID`,
                        },
                    ],
                },
            },
        },
        KUSTOMER: {
            createNewItem: {
                CUSTOMER: {
                    displayName: entitiesMetadata.KUSTOMER.CUSTOMER.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.CUSTOMER.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.CUSTOMER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Customer Name',
                        },
                    },
                    optionalFields: {
                        company: {
                            id: 'company',
                            label: 'Company',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Customer ID',
                        },
                    ],
                },
                CONVERSATION: {
                    displayName: entitiesMetadata.KUSTOMER.CONVERSATION.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.CONVERSATION.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.CONVERSATION.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Conversation Title',
                        },
                        customer: {
                            id: 'customer',
                            label: 'Customer ID',
                        },
                    },
                    optionalFields: {
                        status: {
                            id: 'status',
                            label: 'Status',
                            description: `Conversation Status ('open' or 'done')`,
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Conversation ID',
                        },
                    ],
                },
                MESSAGE: {
                    displayName: entitiesMetadata.KUSTOMER.MESSAGE.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.MESSAGE.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.MESSAGE.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        subject: {
                            id: 'subject',
                            label: 'Subject',
                        },
                        preview: {
                            id: 'preview',
                            label: 'Content',
                        },
                        conversation: {
                            id: 'conversation',
                            label: 'Conversation ID',
                        },
                        channel: {
                            id: 'channel',
                            label: 'Channel',
                            description:
                                'Options: email, sms, chat, facebook, twitter-dm, twitter-tweet, voice, instagram, whatsapp.',
                        },
                        app: {
                            id: 'app',
                            label: 'App',
                            description:
                                'Options: email, sms, chat, facebook, twitter-dm, twitter-tweet, voice, instagram, whatsapp.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Message ID',
                        },
                    ],
                },
            },
            updateField: {
                CUSTOMER: {
                    displayName: entitiesMetadata.KUSTOMER.CUSTOMER.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.CUSTOMER.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.CUSTOMER.entity,
                    useExtendedMatchConfiguration: true,
                },
                CONVERSATION: {
                    displayName: entitiesMetadata.KUSTOMER.CONVERSATION.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.CONVERSATION.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.CONVERSATION.entity,
                    useExtendedMatchConfiguration: true,
                },
                MESSAGE: {
                    displayName: entitiesMetadata.KUSTOMER.MESSAGE.displayName,
                    pluralLabel: entitiesMetadata.KUSTOMER.MESSAGE.pluralLabel,
                    entity: entitiesMetadata.KUSTOMER.MESSAGE.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        LEVER: {
            customActions: {
                CREATE_FEEDBACK: {
                    doesNotRequireEntity: true,
                    displayName: `Create ${entitiesMetadata.LEVER.FEEDBACK.displayName}`,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.LEVER.FEEDBACK.displayName} ID`,
                        },
                    ],
                },
                UPDATE_FEEDBACK: {
                    doesNotRequireEntity: true,
                    displayName: `Update ${entitiesMetadata.LEVER.FEEDBACK.displayName}`,
                },
            },
        },
        GOOGLECLOUDSTORAGE: {
            customActions: {
                UPLOAD_FILE: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.GOOGLEDRIVE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                GENERATE_SIGNED_URL: {
                    createsEntity: false,
                    doesNotRequireEntity: true,
                    displayName: 'Generate a Signed URL',
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Generated Signed URL',
                        },
                    ],
                },
            },
        },
        ONEDRIVE: {},
        UIPATH: {
            customActions: {
                START_JOB: {
                    doesNotRequireEntity: true,
                    displayName: `Start ${entitiesMetadata.UIPATH.JOB.displayName}`,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Started ${entitiesMetadata.UIPATH.JOB.displayName} ID`,
                        },
                    ],
                },
                GET_FINISHED_JOB_OUTPUT: {
                    displayName: `Get Finished ${entitiesMetadata.UIPATH.JOB.displayName}'s Output`,
                    hide: true,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Finished ${entitiesMetadata.UIPATH.JOB.displayName}'s Output`,
                        },
                    ],
                },
            },
        },
        NETDOCUMENTS: {
            createNewItem: {
                CUSTOMER: {
                    displayName: entitiesMetadata.NETDOCUMENTS.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.NETDOCUMENTS.FOLDER.pluralLabel,
                    entity: entitiesMetadata.NETDOCUMENTS.FOLDER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    optionalFields: {
                        parent: {
                            id: 'parent',
                            label: 'Destination ID',
                            description:
                                'ID of the new folder’s parent folder or workspace. Can be omitted if the new folder will be a top-level folder in a cabinet.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.NETDOCUMENTS.FOLDER.displayName} ID`,
                        },
                    ],
                },
            },
            createOrUpdateItem: {
                Matter: {
                    displayName: entitiesMetadata.NETDOCUMENTS.MATTER.displayName,
                    pluralLabel: entitiesMetadata.NETDOCUMENTS.MATTER.pluralLabel,
                    entity: entitiesMetadata.NETDOCUMENTS.MATTER.entity,
                    requiredFields: {
                        key: {
                            id: 'key',
                            label: 'Matter Key',
                        },
                        parent: {
                            id: 'parent',
                            label: 'Parent Key',
                        },
                    },
                    optionalFields: {
                        name: {
                            id: 'description',
                            label: 'Matter Name',
                        },
                        defaulting: {
                            id: 'defaulting',
                            label: 'Defaulting',
                        },
                        hold: {
                            id: 'hold',
                            label: 'Hold',
                            description: 'Options: true, false.',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Matter ID',
                        },
                    ],
                },
            },
            customActions: {
                UPLOAD_DOCUMENT: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload Document',
                    entity: entitiesMetadata.NETDOCUMENTS.DOCUMENT,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded Document ID',
                        },
                    ],
                },
            },
        },
        AZUREBLOBSTORAGE: {
            customActions: {
                UPLOAD_FILE: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload File',
                    entity: entitiesMetadata.AZUREBLOBSTORAGE.FILE,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                DELETE_FILE: {
                    displayName: `Delete ${entitiesMetadata.AZUREBLOBSTORAGE.FILE.displayName}`,
                    entity: entitiesMetadata.AZUREBLOBSTORAGE.FILE,
                    customExtendedMatchConfigurationTitle: `Which ${entitiesMetadata.AZUREBLOBSTORAGE.FILE.displayName} to delete?`,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        EVISORT: {
            customActions: {
                UPLOAD_DOCUMENT: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Upload Document',
                    entity: entitiesMetadata.EVISORT.DOCUMENT,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded Document ID',
                        },
                    ],
                },
                TRANSFER_DOCUMENT: {
                    useExtendedMatchConfiguration: true,
                    doesNotRequireEntity: false,
                    displayName: 'Transfer Document',
                    entity: entitiesMetadata.EVISORT.DOCUMENT,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Transferred Document ID',
                        },
                    ],
                },
            },
        },
        SIMPLELEGAL: {
            createNewItem: {
                Matter: {
                    displayName: entitiesMetadata.SIMPLELEGAL.MATTER.displayName,
                    pluralLabel: entitiesMetadata.SIMPLELEGAL.MATTER.pluralLabel,
                    entity: entitiesMetadata.SIMPLELEGAL.MATTER.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    optionalFields: {
                        description: {
                            id: 'description',
                            label: 'Description',
                        },
                        status: {
                            id: 'status',
                            label: 'Status',
                            description:
                                'Must be one of the following valid values open, closed, inactive, voided or draft. Default: draft',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.SIMPLELEGAL.MATTER.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                Matter: {
                    displayName: entitiesMetadata.SIMPLELEGAL.MATTER.displayName,
                    pluralLabel: entitiesMetadata.SIMPLELEGAL.MATTER.pluralLabel,
                    entity: entitiesMetadata.SIMPLELEGAL.MATTER.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        GOOGLESLIDES: {
            customActions: {
                CREATE_PRESENTATION_FROM_TEMPLATE: {
                    doesNotRequireEntity: true,
                    createsEntity: false,
                    displayName: 'Create Presentation From Template',
                    entity: entitiesMetadata.GOOGLESLIDES.PRESENTATION,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Presentation ID',
                        },
                    ],
                },
            },
        },
        WORKFRONT: {
            createNewItem: {
                Project: {
                    displayName: entitiesMetadata.WORKFRONT.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.WORKFRONT.PROJECT.pluralLabel,
                    entity: entitiesMetadata.WORKFRONT.PROJECT.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.WORKFRONT.PROJECT.displayName} ID`,
                        },
                    ],
                },
                Task: {
                    displayName: entitiesMetadata.WORKFRONT.TASK.displayName,
                    pluralLabel: entitiesMetadata.WORKFRONT.TASK.pluralLabel,
                    entity: entitiesMetadata.WORKFRONT.TASK.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.WORKFRONT.TASK.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                Project: {
                    displayName: entitiesMetadata.WORKFRONT.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.WORKFRONT.PROJECT.pluralLabel,
                    entity: entitiesMetadata.WORKFRONT.PROJECT.entity,
                    useExtendedMatchConfiguration: true,
                },
                Task: {
                    displayName: entitiesMetadata.WORKFRONT.TASK.displayName,
                    pluralLabel: entitiesMetadata.WORKFRONT.TASK.pluralLabel,
                    entity: entitiesMetadata.WORKFRONT.TASK.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        DYNAMICS365FO: {
            createNewItem: {
                Project: {
                    displayName: entitiesMetadata.DYNAMICS365FO.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.DYNAMICS365FO.PROJECT.pluralLabel,
                    entity: entitiesMetadata.DYNAMICS365FO.PROJECT.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        dataAreaId: {
                            id: 'dataAreaId',
                            label: 'Company',
                        },
                        ProjectType: {
                            id: 'ProjectType',
                            label: 'Project Type',
                        },
                        ProjectName: {
                            id: 'ProjectName',
                            label: 'Project Name',
                        },
                        ProjectGroup: {
                            id: 'ProjectGroup',
                            label: 'Project Group',
                        },
                        ProjectedStartDate: {
                            id: 'ProjectedStartDate',
                            label: 'Start Date',
                            description: `Format: yyyy-MM-dd'T'HH:mm:ss'z'`,
                        },
                    },
                    optionalFields: {
                        ProjectID: {
                            id: 'ProjectID',
                            label: 'Project ID',
                            description: 'If Empty, will auto generated.',
                        },
                        ProjectContractID: {
                            id: 'ProjectContractID',
                            label: 'Project Contract ID',
                        },
                        CustomerAccount: {
                            id: 'CustomerAccount',
                            label: 'Customer',
                        },
                        WorkerResponsiblePersonnelNumber: {
                            id: 'WorkerResponsiblePersonnelNumber',
                            label: 'Project Manager',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.DYNAMICS365FO.PROJECT.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                Project: {
                    displayName: entitiesMetadata.DYNAMICS365FO.PROJECT.displayName,
                    pluralLabel: entitiesMetadata.DYNAMICS365FO.PROJECT.pluralLabel,
                    entity: entitiesMetadata.DYNAMICS365FO.PROJECT.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        PAGERDUTY: {
            createNewItem: {
                Project: {
                    displayName: entitiesMetadata.PAGERDUTY.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.PAGERDUTY.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.PAGERDUTY.INCIDENT.entity,
                    useExtendedMatchConfiguration: true,
                    requiredFields: {
                        title: {
                            id: 'title',
                            label: 'Title',
                        },
                        service: {
                            id: 'service',
                            label: 'Service ID',
                        },
                    },
                    optionalFields: {
                        priority: {
                            id: 'priority-id',
                            label: 'Priority ID',
                        },
                        status: {
                            id: 'status',
                            label: 'Status',
                            description: 'Options: resolved, acknowledged.',
                        },
                        urgency: {
                            id: 'urgency',
                            label: 'Urgency',
                            description: 'Options: high, low',
                        },
                        body: {
                            id: 'body-details',
                            label: 'Details',
                        },
                        assignments: {
                            id: 'assignments-assignee-id',
                            label: 'Assignment IDs',
                            description: 'Comma-separated list of assignee IDs',
                        },
                        policy: {
                            id: 'escalation_policy-id',
                            label: 'Escalation Policy ID',
                        },
                    },
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: `Created ${entitiesMetadata.PAGERDUTY.INCIDENT.displayName} ID`,
                        },
                    ],
                },
            },
            updateField: {
                Project: {
                    displayName: entitiesMetadata.PAGERDUTY.INCIDENT.displayName,
                    pluralLabel: entitiesMetadata.PAGERDUTY.INCIDENT.pluralLabel,
                    entity: entitiesMetadata.PAGERDUTY.INCIDENT.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        CLOUDCONVERT: {
            customActions: {
                CREATE_IMPORT_FILE_TASK: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Import/Upload Task',
                    entity: entitiesMetadata.CLOUDCONVERT.TASK,
                },
                CREATE_MERGE_TASK: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Merge Task',
                    entity: entitiesMetadata.CLOUDCONVERT.TASK,
                },
                CREATE_EXPORT_URL_TASK: {
                    doesNotRequireEntity: true,
                    createsEntity: true,
                    displayName: 'Create Export URL Task',
                    entity: entitiesMetadata.CLOUDCONVERT.TASK,
                },
            },
        },
        ADOBESIGN: {
            customActions: {
                UPLOAD_TRANSIENT_DOCUMENT: {
                    doesNotRequireEntity: true,
                    displayName: 'Upload Transient Document',
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded Transient Document ID',
                        },
                    ],
                },
                CREATE_AGREEMENT: {
                    displayName: 'Create Agreement',
                    entity: entitiesMetadata.ADOBESIGN.Agreement,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Created Agreement ID',
                        },
                    ],
                },
                SEND_AGREEMENT: {
                    displayName: 'Send an Agreement',
                    entity: entitiesMetadata.ADOBESIGN.Agreement,
                    useExtendedMatchConfiguration: true,
                },
                TRANSFER_AGREEMENT_DOCUMENT: {
                    displayName: 'Transfer Agreement Document',
                    entity: entitiesMetadata.ADOBESIGN.Agreement,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Transferred Document ID',
                        },
                    ],
                },
            },
        },
        COUPA: {
            createNewItem: {
                PurchaseOrder: {
                    displayName: entitiesMetadata.COUPA.PurchaseOrder.displayName,
                    pluralLabel: entitiesMetadata.COUPA.PurchaseOrder.pluralLabel,
                    entity: entitiesMetadata.COUPA.PurchaseOrder.entity,
                    requiredFields: {
                        requester: {
                            id: 'requester',
                            label: 'requester',
                        },
                    },
                },
                Requisition: {
                    displayName: entitiesMetadata.COUPA.Requisition.displayName,
                    pluralLabel: entitiesMetadata.COUPA.Requisition.pluralLabel,
                    entity: entitiesMetadata.COUPA.Requisition.entity,
                    requiredFields: {
                        requester: {
                            id: 'requester',
                            label: 'requester',
                        },
                    },
                },
                Supplier: {
                    displayName: entitiesMetadata.COUPA.Supplier.displayName,
                    pluralLabel: entitiesMetadata.COUPA.Supplier.pluralLabel,
                    entity: entitiesMetadata.COUPA.Supplier.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                        enterprise: {
                            id: 'enterprise',
                            label: 'Enterprise',
                        },
                        'primary-address': {
                            id: 'primary-address',
                            label: 'Address',
                        },
                        'primary-contact': {
                            id: 'primary-contact',
                            label: 'Contact Email',
                        },
                    },
                },
                SupplierInformation: {
                    displayName: entitiesMetadata.COUPA.SupplierInformation.displayName,
                    pluralLabel: entitiesMetadata.COUPA.SupplierInformation.pluralLabel,
                    entity: entitiesMetadata.COUPA.SupplierInformation.entity,
                    requiredFields: {
                        name: {
                            id: 'name',
                            label: 'Name',
                        },
                        enterprise: {
                            id: 'enterprise',
                            label: 'Enterprise',
                        },
                        'primary-address': {
                            id: 'primary-address',
                            label: 'Address',
                        },
                        'primary-contact': {
                            id: 'primary-contact',
                            label: 'Contact Email',
                        },
                    },
                    optionalFields: {
                        'supplier-id': {
                            id: 'supplier-id',
                            label: 'Supplier ID',
                            description: 'If empty, an associated Supplier record is automatically created.'
                        },
                    }
                },
            },
            updateField: {
                Invoice: {
                    displayName: entitiesMetadata.COUPA.Invoice.displayName,
                    pluralLabel: entitiesMetadata.COUPA.Invoice.pluralLabel,
                    entity: entitiesMetadata.COUPA.Invoice.entity,
                    useExtendedMatchConfiguration: true,
                },
                PurchaseOrder: {
                    displayName: entitiesMetadata.COUPA.PurchaseOrder.displayName,
                    pluralLabel: entitiesMetadata.COUPA.PurchaseOrder.pluralLabel,
                    entity: entitiesMetadata.COUPA.PurchaseOrder.entity,
                    useExtendedMatchConfiguration: true,
                },
                Requisition: {
                    displayName: entitiesMetadata.COUPA.Requisition.displayName,
                    pluralLabel: entitiesMetadata.COUPA.Requisition.pluralLabel,
                    entity: entitiesMetadata.COUPA.Requisition.entity,
                    useExtendedMatchConfiguration: true,
                },
                Supplier: {
                    displayName: entitiesMetadata.COUPA.Supplier.displayName,
                    pluralLabel: entitiesMetadata.COUPA.Supplier.pluralLabel,
                    entity: entitiesMetadata.COUPA.Supplier.entity,
                    useExtendedMatchConfiguration: true,
                },
                SupplierInformation: {
                    displayName: entitiesMetadata.COUPA.SupplierInformation.displayName,
                    pluralLabel: entitiesMetadata.COUPA.SupplierInformation.pluralLabel,
                    entity: entitiesMetadata.COUPA.SupplierInformation.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
            customActions: {
                ADD_PURCHASE_ORDER_LINE: {
                    displayName: 'Add/Update Purchase Order Lines',
                    entity: entitiesMetadata.COUPA.PurchaseOrder,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Updated Purchase Order ID',
                        },
                    ],
                },
                ADD_REQUISITION_LINE: {
                    displayName: 'Add/Update Requisition Lines',
                    entity: entitiesMetadata.COUPA.Requisition,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Updated Requisition ID',
                        },
                    ],
                },
                SUBMIT_REQUISITION_FOR_APPROVAL: {
                    displayName: 'Submit Draft Requisition for Approval',
                    entity: entitiesMetadata.COUPA.Requisition,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Submitted Requisition ID',
                        },
                    ],
                },
            },
        },
        UKG: {
            updateField: {
                Employee: {
                    displayName: entitiesMetadata.UKG.Employee.displayName,
                    pluralLabel: entitiesMetadata.UKG.Employee.pluralLabel,
                    entity: entitiesMetadata.UKG.Employee.entity,
                    useExtendedMatchConfiguration: true,
                },
                Job: {
                    displayName: entitiesMetadata.UKG.Job.displayName,
                    pluralLabel: entitiesMetadata.UKG.Job.pluralLabel,
                    entity: entitiesMetadata.UKG.Job.entity,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        NEXTCLOUD: {
            createNewItem: {
                Folder: {
                    displayName: entitiesMetadata.NEXTCLOUD.FOLDER.displayName,
                    pluralLabel: entitiesMetadata.NEXTCLOUD.FOLDER.pluralLabel,
                    entity: entitiesMetadata.NEXTCLOUD.FOLDER.entity,
                    requiredFields: {
                        folderName: {
                            id: 'folderName',
                            label: 'Folder Name',
                        },
                    },
                    optionalFields: {
                        folderPath: {
                            id: 'folderPath',
                            label: 'Folder Path (Optional)',
                            description: `Relative to the selected folder in the integration's setup.`,
                        },
                    },
                },
            },
            customActions: {
                MOVE_FOLDER: {
                    displayName: 'Move Folder',
                    entity: entitiesMetadata.NEXTCLOUD.FOLDER,
                    useExtendedMatchConfiguration: true,
                },
                MOVE_FILE: {
                    displayName: 'Move File',
                    entity: entitiesMetadata.NEXTCLOUD.FILE,
                    useExtendedMatchConfiguration: true,
                },
                COPY_FOLDER: {
                    displayName: 'Copy Folder',
                    entity: entitiesMetadata.NEXTCLOUD.FOLDER,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Copied Folder ID',
                        },
                    ],
                },
                COPY_FILE: {
                    displayName: 'Copy File',
                    entity: entitiesMetadata.NEXTCLOUD.FILE,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Copied File ID',
                        },
                    ],
                },
                DELETE_FOLDER: {
                    displayName: 'Delete Folder',
                    entity: entitiesMetadata.NEXTCLOUD.FOLDER,
                    useExtendedMatchConfiguration: true,
                },
                DELETE_FILE: {
                    displayName: 'Delete File',
                    entity: entitiesMetadata.NEXTCLOUD.FILE,
                    useExtendedMatchConfiguration: true,
                },
                UPLOAD_FILE: {
                    displayName: 'Upload File',
                    entity: entitiesMetadata.NEXTCLOUD.FILE,
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Uploaded File ID',
                        },
                    ],
                },
                SHARE_FILE: {
                    displayName: 'Share File',
                    entity: entitiesMetadata.NEXTCLOUD.FILE,
                    useExtendedMatchConfiguration: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Share File URL',
                        },
                    ],
                },
            },
        },
        AMAZONTEXTRACT: {
            customActions: {
                ANALYZE_DOCUMENT: {
                    displayName: 'Analyze Document',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Raw Text',
                        },
                    ],
                },
                ANALYZE_INVOICE: {
                    displayName: 'Analyze Invoice',
                    doesNotRequireEntity: true,
                    linkedFieldDefinitionsConfiguration: [
                        {
                            field: 'DEFAULT',
                            friendlyName: 'Raw Text',
                        },
                    ],
                },
            },
        },
        EMAILWEBHOOK: {
            customActions: {
                UPDATE_EMAIL_THREAD: {
                    displayName: 'Update Email Thread',
                    entity: entitiesMetadata.EMAILWEBHOOK.THREAD,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
        IRONCLAD: {
            customActions: {
                ADD_COMMENT_TO_WORKFLOW: {
                    displayName: 'Add Comment (Activity Feed)',
                    entity: entitiesMetadata.IRONCLAD.WORKFLOW,
                    useExtendedMatchConfiguration: true,
                },
            },
        },
    };

    this.getActionsByIntegrationsMap = function () {
        return actionsByIntegrationsMap;
    };

    /**
     * Get the fields defined for an integration action
     * @param integrationType
     * @param actionKey
     * @param projectIntegration
     * @return Object containing required and optional fields of the action provided
     */
    this.getFieldsForAction = function (integrationType, actionKey, projectIntegration, tonkeanService) {
        const actionDefinition = actionsByIntegrationsMap[integrationType][actionKey];
        const objKeys = utils.objKeys(actionDefinition);

        let entityRequiredFieldsPromises = [];
        let entityOptionalFieldsPromises = [];

        for (let i = 0; i < objKeys.length; i++) {
            const entityDefinition = actionDefinition[objKeys[i]];

            const requiredFields = entityDefinition.requiredFields || [];
            let requiredFieldsPromise = $q.resolve([entityDefinition, requiredFields]);
            if (projectIntegration && entityDefinition.getDynamicRequiredFields) {
                requiredFieldsPromise = entityDefinition
                    .getDynamicRequiredFields(entityDefinition.entity, projectIntegration, tonkeanService)
                    .then((dynamicRequiredFields) => {
                        const fields = utils.mergeObjects(requiredFields, dynamicRequiredFields);
                        return $q.resolve([entityDefinition, fields]);
                    });
            }
            entityRequiredFieldsPromises.push(requiredFieldsPromise);

            const optionalFields = entityDefinition.optionalFields || [];
            let optionalFieldsPromise = $q.resolve([entityDefinition, optionalFields]);
            if (entityDefinition.getDynamicOptionalFields) {
                optionalFieldsPromise = entityDefinition
                    .getDynamicOptionalFields(entityDefinition.entity, projectIntegration, tonkeanService)
                    .then((dynamicOptionalFields) => {
                        const fields = utils.mergeObjects(optionalFields, dynamicOptionalFields);
                        return $q.resolve([entityDefinition, fields]);
                    });
            }
            entityOptionalFieldsPromises.push(optionalFieldsPromise);
        }

        return $q
            .all([$q.all(entityRequiredFieldsPromises), $q.all(entityOptionalFieldsPromises)])
            .then(([entityRequiredFieldsPairs, entityOptionalFieldsPairs]) => {
                const fieldsForAction = {};

                if (entityRequiredFieldsPairs && entityRequiredFieldsPairs.length) {
                    entityRequiredFieldsPairs.forEach((entityRequiredFieldsPair) => {
                        const entityDefinition = entityRequiredFieldsPair[0];
                        const requiredFields = entityRequiredFieldsPair[1];

                        if (!fieldsForAction[entityDefinition.entity]) {
                            fieldsForAction[entityDefinition.entity] = {};
                        }

                        fieldsForAction[entityDefinition.entity].requiredFields = requiredFields;
                    });
                }

                if (entityOptionalFieldsPairs && entityOptionalFieldsPairs.length) {
                    entityOptionalFieldsPairs.forEach((entityOptionalFieldsPair) => {
                        const entityDefinition = entityOptionalFieldsPair[0];
                        const optionalFields = entityOptionalFieldsPair[1];

                        if (!fieldsForAction[entityDefinition.entity]) {
                            fieldsForAction[entityDefinition.entity] = {};
                        }

                        fieldsForAction[entityDefinition.entity].optionalFields = optionalFields;
                    });
                }

                return $q.resolve(fieldsForAction);
            });
    };

    this.getEntitiesMetadata = function () {
        return entitiesMetadata;
    };
}

export default angular.module('tonkean.shared').service('integrationsConsts', IntegrationsConsts);
export const integrationsConsts = new IntegrationsConsts();
