<section class="login-page mod-no-bg">
    <div class="flex-vmiddle-xs-block flex-grow common-height-full padding-top-xxlg">
        <div ng-if="loading" class="flex mod-justify-center common-width-full">
            <div>
                <div class="common-size-xl login-page-title">Signing in...</div>
                <i class="loading-large" data-automation="loading-circle"></i>
            </div>
        </div>
        <div ng-if="!loading" class="login-page-content">
            <div class="login-page-title common-size-xl">
                Hey,
                <p>The sign-in link expires after 24 hours and can only be used once.</p>

                <p></p>
                Sorry 😬
            </div>
            <div ng-if="!error" class="margin-top-xlg flex-vmiddle">
                <button ng-click="resendEmail()" type="button" class="btn btn-lg btn-primary">Send a new link</button>
                <div ng-if="loadingResend" class="margin-left-xs margin-top-xxs">
                    <i class="loading-medium"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="login-page-image-box common-bg mod-gradient-blue hidden-sm hidden-xs">
        <div class="login-page-image-inner-box mod-left">
            <div class="login-page-image mod-rpa"></div>
        </div>
    </div>
</section>
