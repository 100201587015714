import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as EyeIcon } from '../../../../../../../../images/icons/eye-thin.svg';
import { ReactComponent as TestIcon } from '../../../../../../../../images/icons/test.svg';
import sqlIntegrations from '../../utils/SqlIntegrations';
import ProjectIntegrationActionTestPage from '../ProjectIntegrationActionTestPage/ProjectIntegrationActionTestPage';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Modal, ModalSize } from '@tonkean/infrastructure';
import type { BaseActionParameter, ProjectIntegration, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { ActionDefinitionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StyledModal = styled(Modal)`
    height: 600px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    constantParameters: BaseActionParameter[];
}

const ProjectIntegrationActionRequestButtons: React.FC<Props> = ({
    projectIntegration,
    constantParameters,
    projectIntegrationAction,
}) => {
    const [paramIdToParamInputValueRecord, setParamIdToParamInputValueRecord] = useState<Record<string, string>>({});
    const [isRunTestModalOpen, setIsRunTestModalOpen] = useState(false);
    const shouldShowCurlPreview = !sqlIntegrations.includes(projectIntegration.integrationType.toLowerCase());

    const modalUtils = useAngularService('modalUtils');

    const [, getApiBaseUrl] = useLazyTonkeanService('getIntegrationApiBaseUrl');

    const fetchApiBaseUrl = async () => {
        const { apiBaseUrl } = await getApiBaseUrl(projectIntegration.id);
        if (projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP) {
            // Build query params string
            const params = new URLSearchParams({});
            projectIntegrationAction.actionDefinition?.queryParams?.forEach((queryParam) => {
                params.append(queryParam.name.evaluatedExpression, queryParam.value.evaluatedExpression);
            });
            const queryParamsString = params.toString().length ? `?${params.toString()}` : '';

            const options = {
                url: `${apiBaseUrl}/${projectIntegrationAction.actionDefinition?.url.evaluatedExpression}${queryParamsString}`,
                method: projectIntegrationAction.actionDefinition?.methodType,
                contentType: projectIntegrationAction.actionDefinition?.contentType,
                headers: projectIntegrationAction.actionDefinition?.headers,
                body: projectIntegrationAction.actionDefinition?.body?.evaluatedExpression,
                isHttpUploadAction: false,
            };

            modalUtils.openHttpRequestPreviewModal(null, options, null);
        }
    };

    return (
        <>
            <ButtonsWrapper>
                <Button
                    className="margin-right"
                    size={ButtonSize.MEDIUM}
                    onClick={() => setIsRunTestModalOpen(true)}
                    data-automation="project-integration-action-request-buttons-test-request-button"
                    flex
                >
                    <TestIcon />
                    Test Request
                </Button>

                {shouldShowCurlPreview && (
                    <Button size={ButtonSize.MEDIUM} onClick={fetchApiBaseUrl} flex outlined>
                        <EyeIcon />
                        cURL Preview
                    </Button>
                )}
            </ButtonsWrapper>

            <StyledModal
                open={isRunTestModalOpen}
                onClose={() => setIsRunTestModalOpen(false)}
                size={ModalSize.XLARGE}
                fixedWidth
            >
                <ProjectIntegrationActionTestPage
                    projectIntegrationAction={projectIntegrationAction}
                    onCancel={() => setIsRunTestModalOpen(false)}
                    projectIntegration={projectIntegration}
                    paramIdToParamInputValueRecord={paramIdToParamInputValueRecord}
                    setParamIdToParamInputValueRecord={setParamIdToParamInputValueRecord}
                    constantParameters={constantParameters}
                />
            </StyledModal>
        </>
    );
};

export default ProjectIntegrationActionRequestButtons;
