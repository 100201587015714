import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const AllocadiaComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [instance, setInstance] = useState<string | undefined>('secure.allocadia.com');
    const [username, setUsername] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [budgetId, setBudgetId] = useState<string | undefined>();

    const authenticateWithCredentials = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                instance,
                username,
                password,
                budgetId,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${username})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithCredentials}>
                <div className="flex api-token-authentication">
                    <div className="margin-bottom-lg info-box common-size-xxs">
                        <p className="common-color-dark-grey">Setup Information:</p>
                        <div className="common-color-dark-grey margin-top-lg">
                            <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                <li>
                                    The user account that's being authenticated with must have API Access enabled.
                                    <br />
                                    Contact Allocadia to have this enabled.
                                </li>
                                <li>
                                    The user must have at least VIEWER access to the budget/activity plan/folder
                                    specified by the ID in the URL and also all child budgets/activity plans and
                                    folders.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-instance" className="col-sm-3 control-label">
                            Instance
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={instance}
                                className="form-control"
                                id="api-instance"
                                onChange={({ target }) => setInstance(target.value)}
                                autoComplete="off"
                                placeholder="Instance"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                The instance name, normally 'secure.allocadia.com' but could be an alternate name if
                                you're working off of a test instance for example.
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-username" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="api-username"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="api-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-budget" className="col-sm-3 control-label">
                            Budget ID
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={budgetId}
                                className="form-control"
                                id="api-budget"
                                onChange={({ target }) => setBudgetId(target.value)}
                                autoComplete="off"
                                placeholder="Budget ID"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                The unique ID of the budget/activity plan or folder that you wish to export.
                                <br />
                                Contact Allocadia for assistance in determining the ID of the budget/activity plan or
                                folder.
                            </span>
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AllocadiaComponent;
