import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import useProjectIntegrationActionBreadCrumbsSettings from './useProjectIntegrationActionBreadCrumbsSettings';

import type { BreadCrumbsOption } from '@tonkean/infrastructure';
import { Breadcrumbs } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionWithIsImported,
} from '@tonkean/tonkean-entities';

interface Props {
    projectIntegrationActions: ProjectIntegrationActionWithIsImported[];
    projectIntegrationAction: ProjectIntegrationAction;
    projectIntegration: ProjectIntegration;
    shouldOpenWarningBeforeNavigate: boolean;
}

const ProjectIntegrationActionManagerBreadCrumbs: React.FC<Props> = ({
    projectIntegration,
    projectIntegrationAction,
    projectIntegrationActions,
    shouldOpenWarningBeforeNavigate,
}) => {
    const $state = useAngularService('$state');

    // Constructing the other possible project integration actions the user can switch to.
    const otherPossibleActions = useMemo((): BreadCrumbsOption[] => {
        return projectIntegrationActions
            .filter((action) => action.id !== projectIntegrationAction.id)
            .filter((action) => !action.isImported)
            .map((action) => ({ id: action.id, displayName: action.displayName }));
    }, [projectIntegrationAction.id, projectIntegrationActions]);

    const breadCrumbsSettings = useProjectIntegrationActionBreadCrumbsSettings(
        projectIntegration,
        projectIntegrationAction,
        otherPossibleActions,
        $state,
        shouldOpenWarningBeforeNavigate,
    );

    return <Breadcrumbs settings={breadCrumbsSettings} />;
};

export default ProjectIntegrationActionManagerBreadCrumbs;
