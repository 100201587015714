/**
 * Checks if the logic block type is of a trigger type
 *
 * @param logicBlockType - the logic block type.
 * @returns If the block type is a trigger.
 */

function isLogicBlockTypeTrigger(logicBlockType: any): boolean {
    return ['AUTONOMOUS', 'INNER_AUTONOMOUS', 'MONITOR_TRACKS'].includes(logicBlockType?.type);
}

export default isLogicBlockTypeTrigger;
