import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TwilioChatCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        configuredLogic: ctrl.configuredLogic,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        loading: false,

        attributesToUpdate: [],
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onServiceIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.serviceIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onChannelIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.channelIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkspaceIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.workspaceIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTaskIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.taskIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMessageTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.messageExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFromTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fromExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttributesTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.attributesExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttributeNameTonkeanExpressionChanged = function (expression, shouldSaveLogic, attribute) {
        attribute.nameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttributeValueTonkeanExpressionChanged = function (expression, shouldSaveLogic, attribute) {
        attribute.valueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addEmptyAttributeToUpdate = function () {
        $scope.data.attributesToUpdate.push({
            nameExpression: {},
            valueExpression: {},
        });
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        if ($scope.data.attributesToUpdate) {
            $scope.data.definition.attributesToUpdate = $scope.data.attributesToUpdate.filter(
                (attribute) =>
                    (attribute.nameExpression && attribute.nameExpression.evaluatedExpression) ||
                    (attribute.valueExpression && attribute.valueExpression.evaluatedExpression),
            );
        }

        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        if ($scope.data.definition) {
            if (
                $scope.data.definition.customActionKey === 'REPLY_TO_CHANNEL' ||
                $scope.data.definition.customActionKey === 'ADD_OR_UPDATE_CHANNEL_ATTRIBUTES'
            ) {
                $scope.data.isActionOnChannel = true;
                $scope.data.isActionOnTask = false;
            } else {
                $scope.data.isActionOnChannel = false;
                $scope.data.isActionOnTask = true;
            }

            if (
                $scope.data.definition.customActionKey === 'ADD_OR_UPDATE_CHANNEL_ATTRIBUTES' ||
                $scope.data.definition.customActionKey === 'ADD_OR_UPDATE_TASK_ATTRIBUTES'
            ) {
                if (!$scope.data.definition.attributesToUpdate) {
                    $scope.data.definition.attributesToUpdate = [];
                }

                $scope.data.attributesToUpdate = $scope.data.definition.attributesToUpdate;

                if (!$scope.data.attributesToUpdate.length) {
                    $scope.addEmptyAttributeToUpdate();
                }
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'TwilioChatCustomActionsDefinitionCtrl',
        lateConstructController(TwilioChatCustomActionsDefinitionCtrl),
    );
