import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import getExtractionTriggersToCreate from './utils/getExtractionTriggersToCreate';
import ExploreTrainingSet from '../../../../../modules/TrainingSetsModule/modules/TrainingSetActionsUtils/ExploreTrainingSet';
import TrainingSetSelectorContainer from '../../../../../modules/TrainingSetsModule/modules/TrainingSetActionsUtils/TrainingSetSelectorContainer';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field } from '@tonkean/infrastructure';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { TrainingSetType, trainingSetTypeToLabel } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ExtractorInput = styled.div`
    margin-top: 24px;
`;

const MatchedEntityReferenceContent = styled.p`
    margin-top: 16px;
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
`;

const ErrorText = styled.div`
    margin-top: 16px;
    color: ${Theme.colors.error};
`;

const ValidationErrorText = styled.div`
    margin-top: 5px;
    color: ${Theme.colors.error};
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    configuredLogic: any;
    previewEvaluationSource: any;
    validationObject: any;

    onChange(definition: any, shouldSaveLogic: any): any;
}

const TextExtractorLogicConfiguration: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    configuredLogic,
    previewEvaluationSource,
    validationObject,
    onChange,
}) => {
    const tonkeanService = useAngularService('tonkeanService');
    const customTriggerManager = useAngularService('customTriggerManager');

    const [errorUpdatingConfiguration] = useState<boolean>(false);
    const [updatedByUser, setUpdatedByUser] = useState<boolean>(false);

    // Initialize the input text of the extractor.
    const [inputText, setInputText] = useState<TonkeanExpressionDefinition | undefined>(() => {
        if (configuredLogic.node?.customTriggerActions?.length > 0) {
            return configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.inputText || '';
        }

        return '';
    });

    // Initialize the training set we're working with.
    const [selectedTrainingSetId, setSelectedTrainingSetId] = useState<string | undefined>(() => {
        return (
            configuredLogic.node?.customTriggerActions?.length &&
            configuredLogic.node?.customTriggerActions[0].customTriggerActionDefinition.trainingSetId
        );
    });

    // Once training set or input text are changed, we save the definition.
    useEffect(() => {
        if (updatedByUser && (inputText || selectedTrainingSetId)) {
            onChange(
                {
                    actions: [
                        {
                            type: 'TEXT_EXTRACTOR',
                            definition: {
                                inputText,
                                trainingSetId: selectedTrainingSetId,
                            },
                        },
                    ],
                    // We create the children custom triggers only after the update of the trigger has happened,
                    // since we rely on linked field definitions to be created and those are created
                    // only after you selected a training set.
                    // Therefore, we must first update the trigger and only then create the children.
                    postCreationChildrenDefinitionsCreator: () =>
                        selectedTrainingSetId
                            ? getExtractionTriggersToCreate(
                                  workflowVersionId,
                                  tonkeanService,
                                  customTriggerManager,
                                  configuredLogic,
                                  selectedTrainingSetId,
                              )
                            : Promise.resolve([]),
                },
                true,
            );
        }
    }, [
        inputText,
        selectedTrainingSetId,
        onChange,
        updatedByUser,
        tonkeanService,
        customTriggerManager,
        configuredLogic,
        workflowVersionId,
    ]);

    const onTextInputChange = (
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean | undefined,
    ) => {
        setUpdatedByUser(shouldSaveLogic || false);
        setInputText({
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled: true,
        });
    };

    return (
        <>
            <ExtractorInput>
                <Field label="Input (any text or OCR Output)">
                    <TonkeanExpression
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        placeholder=""
                        savedOriginalExpression={inputText?.originalExpression || ''}
                        savedEvaluatedExpression={inputText?.evaluatedExpression || ''}
                        previewEvaluationSource={previewEvaluationSource}
                        onTonkeanExpressionChanged={(
                            originalExpression,
                            evaluatedExpression,
                            expression,
                            shouldSaveLogic,
                        ) => onTextInputChange(originalExpression, evaluatedExpression, shouldSaveLogic)}
                    />
                </Field>
                {validationObject?.noInputText && (
                    <ValidationErrorText>{validationObject.noInputText}</ValidationErrorText>
                )}
            </ExtractorInput>

            <TrainingSetSelectorContainer
                groupId={groupId}
                selectedTrainingSetId={selectedTrainingSetId}
                trainingSetTypeAsText={trainingSetTypeToLabel[TrainingSetType.TEXT_EXTRACTOR]}
                trainingSetType={TrainingSetType.TEXT_EXTRACTOR}
                onItemSelected={(selectedItemId) => {
                    setUpdatedByUser(true);
                    setSelectedTrainingSetId(selectedItemId);
                }}
            />

            {validationObject?.noTextExtractor && (
                <ValidationErrorText>{validationObject.noTextExtractor}</ValidationErrorText>
            )}

            <ExploreTrainingSet trainingSetId={selectedTrainingSetId} groupId={groupId} />

            {selectedTrainingSetId && (
                <MatchedEntityReferenceContent>
                    This training set is configured to extract fields under a matched entity you can view in Fields tab.
                </MatchedEntityReferenceContent>
            )}

            {errorUpdatingConfiguration && <ErrorText>There was an error trying to save configuration.</ErrorText>}
        </>
    );
};

export default TextExtractorLogicConfiguration;
