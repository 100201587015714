import React, { useEffect, useMemo } from 'react';

import EmbedWidgetDisplay from './EmbedWidgetDisplay';
import type { ItemWidgetProps } from '../../../WidgetModule';
import EmbedUrlInputType from '../../entities/EmbedUrlInputType';
import type EmbedWidgetConfiguration from '../EmbedWidgetConfiguration';
import { EmbedWidgetType } from '../EmbedWidgetConfiguration';
import { useEmbedWidgetContentExpression } from '../useEmbedWidgetContentExpression';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useInitiativeExpressionServerEvaluation } from '@tonkean/infrastructure';

const EmbedWidget: React.FC<ItemWidgetProps<EmbedWidgetConfiguration>> = ({ widget, permission }) => {
    const { initiative } = useItemInterfaceContext();

    const expressionToLoad = useEmbedWidgetContentExpression(widget.configuration.embed);
    const {
        values: [embedExpression],
        loading: loadingExpressions,
    } = useInitiativeExpressionServerEvaluation(expressionToLoad, initiative);

    const [{ data: embedUrlResponse, loading: loadingEmbedUrl }, getUrlFromAction] =
        useLazyTonkeanService('getEmbedWidgetUrlFromAction');

    const embedContent = useMemo(() => {
        if (
            !!initiative?.id &&
            widget.configuration.embed.type === EmbedWidgetType.URL &&
            widget.configuration.embed.urlInputType === EmbedUrlInputType.FROM_ACTION
        ) {
            return embedUrlResponse?.embedUrl;
        } else {
            return embedExpression;
        }
    }, [embedExpression, embedUrlResponse?.embedUrl, initiative?.id, widget.configuration.embed]);

    const contentConfigured = useMemo(() => {
        if (
            !!initiative?.id &&
            widget.configuration.embed.type === EmbedWidgetType.URL &&
            widget.configuration.embed.urlInputType === EmbedUrlInputType.FROM_ACTION
        ) {
            return (
                !!widget.configuration.embed.selectedProjectIntegrationId &&
                !!widget.configuration.embed.integrationEntityApiName &&
                !!widget.configuration.embed.selectedActionId &&
                !!widget.configuration.embed.externalIdForAction
            );
        } else {
            return !!expressionToLoad[0]?.originalExpression;
        }
    }, [expressionToLoad, initiative?.id, widget.configuration.embed]);

    useEffect(() => {
        if (
            !!initiative?.id &&
            widget.configuration.embed.type === EmbedWidgetType.URL &&
            widget.configuration.embed.urlInputType === EmbedUrlInputType.FROM_ACTION
        ) {
            getUrlFromAction(widget.id, initiative.id);
        }
    }, [getUrlFromAction, initiative?.id, widget.configuration.embed, widget.id]);

    const loading = loadingExpressions || loadingEmbedUrl;

    return (
        <EmbedWidgetDisplay
            permission={permission}
            embedType={widget.configuration.embed.type}
            embedContent={embedContent}
            contentConfigured={contentConfigured}
            loadingExpressions={loading}
            hideWidgetBackground={widget.configuration.hideWidgetBackground}
            height={widget.configuration?.embed?.height}
        />
    );
};

export default EmbedWidget;
