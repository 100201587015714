<div
    ng-controller="AdvancedSyncCtrl"
    ng-init="valueResults['SYNC_ENTITY'] = 'TNK_OPP'; valueResults['SYNC_ENTITY_TYPE'] = 'TNK_OPP'"
>
    <div class="common-size-xs padding-top-xs">
        <strong>Choose the specifics:</strong>
    </div>
    <hr class="margin-top" />

    <div class="row common-size-xs flex-vmiddle">
        <strong class="col-sm-5 text-right">Sales rep:</strong>
        <div class="col-sm-7" ng-init="data.selectedPeople = []">
            <tnk-deprecated-people-selector
                class="flex-no-shrink"
                no-validation="false"
                selected-people="data.selectedPeople"
                on-tag-added="onPeopleTagAdded(true)"
                on-tag-removed="onPeopleTagRemoved(true)"
                max-tags="1"
                min-tags="1"
            ></tnk-deprecated-people-selector>
        </div>
    </div>

    <div class="row common-size-xs flex-vmiddle margin-top" ng-init="valueResults['DATE_RANGE'] = 'Quarter'">
        <strong class="col-sm-5 text-right">Track forecast by:</strong>
        <div class="col-sm-7">
            <select
                class="form-control inline-block common-width-auto"
                ng-change="updateSyncData()"
                ng-model="valueResults['DATE_RANGE']"
                required
            >
                <option value="Quarter">Quarter</option>
                <option value="Month">Month</option>
            </select>
        </div>
    </div>

    <div class="row common-size-xs flex-vmiddle flex-xs-wrap common-btn-input-height margin-top">
        <strong class="col-sm-5 text-right flex-no-shrink margin-bottom-xs">
            Your quota for current
            <span class="text-lowercase" ng-bind="valueResults['DATE_RANGE']"></span>
            :
        </strong>
        <div class="flex-vmiddle flex-grow col-sm-7 margin-bottom-xs">
            <!-- Quota input -->
            <input
                class="form-control inline-block common-width-auto margin-right-xs"
                ng-model="valueResults['QUOTA']"
                pattern="^[0-9]+([,\.][0-9]+)*$"
                title="This field should contain a valid number."
                placeholder="Enter number..."
                required
            />
            <!-- Display format - dropdown -->
            <tnk-display-format-selector
                default-display-format="dollar"
                field-type-filter="'Number'"
                selected-format-api-name="valueResults['DISPLAY_FORMAT_API_NAME']"
                selected-format-prefix="valueResults['DISPLAY_FORMAT_PREFIX']"
                selected-format-postfix="valueResults['DISPLAY_FORMAT_POSTFIX']"
                on-display-format-selected="onDisplayFormatSelected(selectedDisplayFormat, skipAnalytics)"
                field-type-filter="CURRENCY"
                disable-selection="posting"
            ></tnk-display-format-selector>
        </div>
    </div>

    <!-- hiding the advanced option from single rep and forecast - removing this will probably hurt functionality -->
    <div class="margin-top-lg common-size-xxs" ng-hide="true">
        <a ng-click="data.advancedSyncDisplayed = !data.advancedSyncDisplayed">Advanced</a>
        <div class="margin-top-lg" ng-init="initAdvancedSync()" ng-show="data.advancedSyncDisplayed">
            <tnk-custom-filters
                control="valueResults['SYNC_CONTROL']"
                items-source="EXTERNAL"
                existing-definition="valueResults['SYNC_DEFINITION']"
                copy-properties-from-definition="true"
                project-integration="integrationsResults['CRM']"
                from-sync="true"
                edit-mode="false"
                selected-entity-type="valueResults['SYNC_ENTITY']"
                hide-time-range-selection="true"
                show-field-inspect="true"
            ></tnk-custom-filters>
        </div>
    </div>
</div>
