import template from './integrationSmartsheetField.template.html?angularjs';

/**
 * Definition of a smartsheet field.
 */
export default angular.module('tonkean.app').component('tnkIntegrationSmartsheetField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        targetType: '<',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',
    },
    template,
    controller: 'IntegrationSmartsheetFieldCtrl',
});
