import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import MainCallToActionButton from './MainCallToActionButton';

import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { Breadcrumbs, Breakpoint, IconSvg, useBreakpoint } from '@tonkean/infrastructure';
import {
    InterfaceHeaderCallToActionMenu,
    InterfaceQuickNav,
    ItemInterfacePermission,
    useIsTabsViewWorkspaceApp,
} from '@tonkean/interface-module';
import { HamburgerIcon } from '@tonkean/svg';
import { HomeIcon } from '@tonkean/svg';
import {
    type CustomSolutionSitePage,
    ItemInterfaceThemeTextAlignment,
    type SolutionSite,
    type SolutionSitePageWidget,
    type SolutionSiteThemeConfiguration,
    type WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { BreadcrumbsSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY, Truthy } from '@tonkean/utils';

export const SolutionSiteBuilderHeaderContainer = styled.div<{ $isMobile: boolean; $tabsView: boolean }>`
    grid-area: solution-site-header;
    ${({ $isMobile }) =>
        $isMobile &&
        css`
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 8px;
            align-items: baseline;
        `}

    position: sticky;
    top: 0;
    z-index: 3;

    width: 100%;
    padding: 16px 57px;
    background-color: ${Theme.colors.basicBackground};
    border-left: 1px solid ${Theme.colors.gray_200};
    border-bottom: 2px solid ${Theme.colors.gray_200};

    ${({ $tabsView }) =>
        $tabsView &&
        css`
            padding: 32px 45px 0;
        `}

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 9px 15px 18px;
    }
`;

const MainHeader = styled.div`
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;

    width: 100%;
`;

const TitleBar = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    padding: 0 10px;
`;

const StyledMainCallToActionButton = styled(MainCallToActionButton)`
    flex-shrink: 1;

    font-size: ${FontSize.LARGE_16};
`;

interface Props {
    solutionSite: Pick<SolutionSite, 'displayName' | 'urlSlug' | 'projectId'>;
    selectedPage: Pick<CustomSolutionSitePage, 'displayName' | 'urlSlug' | 'configuration'> | undefined;
    projectUrlSlug: string;
    widgets: SolutionSitePageWidget[];
    workflowVersionType: WorkflowVersionType;
    permission: ItemInterfacePermission;
    themeConfiguration: Pick<SolutionSiteThemeConfiguration, 'headerBackgroundColor'>;
    setSidepaneOpen?: Dispatch<SetStateAction<boolean>>;
}

const SolutionSiteBuilderHeader: React.FC<Props> = ({
    solutionSite,
    selectedPage,
    projectUrlSlug,
    widgets,
    workflowVersionType,
    permission,
    themeConfiguration,
    setSidepaneOpen,
}) => {
    const effectiveBreakpoint = useBreakpoint();
    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    const breadcrumbs: BreadcrumbsSettings[] = useMemo(() => {
        return [
            {
                id: 'HOMEPAGE',
                prefixIcon: <IconSvg as={HomeIcon} size={18} color={Theme.colors.gray_600} />,
                clickable: {
                    state: 'homepageView',
                    params: {
                        projectUrlSlug,
                    },
                },
            },
            solutionSite && {
                id: 'SOLUTION_SITE',
                displayName: solutionSite.displayName,
                clickable: {
                    state: 'solution-site',
                    params: {
                        projectUrlSlug,
                        solutionSiteUrlSlug: solutionSite.urlSlug,
                    },
                },
            },
            selectedPage && {
                id: 'SOLUTION_SITE_PAGE',
                displayName: selectedPage.displayName,
                clickable: {
                    state: 'solution-site',
                    params: {
                        projectUrlSlug,
                        solutionSiteUrlSlug: solutionSite.urlSlug,
                        solutionSitePageUrlSlug: selectedPage.urlSlug,
                    },
                },
            },
        ].filter(Truthy);
    }, [projectUrlSlug, solutionSite, selectedPage]);

    const tabsView = useIsTabsViewWorkspaceApp(selectedPage?.configuration);

    return (
        <SolutionSiteBuilderHeaderContainer $isMobile={isMobile} $tabsView={tabsView}>
            {isMobile && (
                <IconButton
                    data-automation="solution-site-builder-header-side-pane-button"
                    onClick={() => {
                        setSidepaneOpen?.((sidepaneOpen) => !sidepaneOpen);
                    }}
                    flat
                >
                    <HamburgerIcon />
                </IconButton>
            )}
            <MainHeader>
                {selectedPage && (
                    <>
                        <TitleBar data-automation="solution-site-selected-page-display-name">
                            <Breadcrumbs
                                data-automation="solution-site-selected-page-breadcrumbs"
                                settings={breadcrumbs}
                                size={BreadcrumbsSize.MEDIUM}
                                disabled={permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE}
                                lastIsBold
                                chevronSeparator
                            />
                            <InterfaceHeaderCallToActionMenu
                                actions={selectedPage.configuration.actions || EMPTY_ARRAY}
                                projectId={solutionSite.projectId}
                                primaryColor={themeConfiguration.headerBackgroundColor}
                                workflowVersionType={workflowVersionType}
                                workflowVersion={undefined}
                                permission={permission}
                                showActionAsButton={!selectedPage.configuration.showActionsAsMenu}
                                savingIndicatorPosition="left"
                            />
                        </TitleBar>
                        <InterfaceQuickNav
                            widgets={widgets}
                            quickNavigationMode={selectedPage.configuration.quickNavigationMode}
                            themeColor={themeConfiguration.headerBackgroundColor}
                            alignment={ItemInterfaceThemeTextAlignment.LEFT}
                            tabsViewEnabled
                        />
                    </>
                )}
            </MainHeader>
        </SolutionSiteBuilderHeaderContainer>
    );
};

export default SolutionSiteBuilderHeader;
