<div class="outgoing-webhook-configuration-logic">
    <div
        ng-if="!data.isBaseUrlHidden && data.isIntegrationGenericAction && !data.isHttpUploadAction"
        class="margin-bottom"
    >
        <div class="margin-bottom-xs common-title-xxs">API Base Url:</div>

        <input
            type="text"
            class="form-control flex-grow expression-input"
            value="{{ data.apiBaseUrl }}"
            disabled="disabled"
        />
    </div>

    <!-- Preview -->
    <div class="margin-bottom-xs margin-top-md flex mod-justify-end" ng-hide="data.isPreviewHidden">
        <button class="btn btn-primary btn-slim" ng-click="openHttpRequestPreviewModal()">Show Preview</button>
    </div>

    <!-- Title -->
    <div class="margin-top-md">
        <div class="flex-vmiddle">
            <div class="margin-bottom-xs common-title-xxs">
                {{ data.isHttpUploadAction ? 'Upload' : '' }} {{ data.isIntegrationGenericAction ? 'Path' : 'URL' }}
            </div>
        </div>
        <!-- Input -->
        <tnk-tonkean-expression
            class="path-request-input"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter the {{ data.isIntegrationGenericAction ? 'path' : 'url' }} of the request"
            saved-original-expression="data.url"
            saved-evaluated-expression="data.urlEvaluatedText"
            expression-unique-identifier="OWUrl"
            on-tonkean-expression-changed="
                onURLTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            prefix="{{ data.prefix }}"
            global-expression-only="data.isIntegrationGenericActionModal"
            additional-tabs="data.additionalTabsForTonkeanExpression"
            do-not-evaluate-preview="data.isIntegrationGenericActionModal"
            show-formulas-tab="data.isIntegrationGenericActionModal"
            should-delete-variable="data.shouldDeleteVariable"
            hide-editor-button="data.isIntegrationGenericActionModal"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <!-- Request Type -->
        <div class="margin-bottom">
            <div class="common-title-xxs margin-bottom-xs">Type</div>
        </div>
        <div>
            <ui-select ng-model="data.method" theme="bootstrap" class="select-type-wrapper">
                <ui-select-match placeholder="Type">
                    <span class="action-type-text">{{ data.method }}</span>
                </ui-select-match>
                <ui-select-choices
                    repeat="type in data.httpMethodTypes | filter:$select.search"
                    ng-click="onDefinitionChanged(true)"
                >
                    {{ type }}
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Validation error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noURL"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noURL }}
        </div>
    </div>

    <div
        ng-if="data.invalidLogics[data.configuredLogic.node.id].badRequest"
        class="margin-top-xs margin-bottom-xs common-color-danger"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].badRequest }}
    </div>

    <!-- HTTP Upload action -->
    <div ng-if="data.isHttpUploadAction" class="margin-top">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.fileSourceType }}"
            storage-integration-id="{{ data.storageIntegrationId }}"
            storage-integration-action-id="{{ data.storageIntegrationActionId }}"
            url-expression="data.urlExpression"
            should-display-delete-sign="false"
            extended-match-configuration="data.extendedMatchConfiguration"
            preview-evaluation-source="data.previewEvaluationSource"
            worker-static-asset-id="{{ data.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.workerStaticAssetDisplayName }}"
            parameters-values="data.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- New file name -->
        <div class="margin-top-md" ng-if="data.isHttpUploadAction">
            <!-- Title -->
            <div class="margin-bottom-xs common-title-xxs">
                New File Name (Optional)
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="When using Content-type header multipart/form-data"
                ></i>
            </div>

            <!-- Input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="New File Name"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.newFileNameExpression"
                expression-unique-identifier="UploadFileNewName"
                on-tonkean-expression-changed="onNewFileNameExpressionChanged(expression, shouldSaveLogic)"
                prefix="{{ data.prefix }}"
                global-expression-only="data.isIntegrationGenericActionModal"
                additional-tabs="data.additionalTabsForTonkeanExpression"
                do-not-evaluate-preview="data.isIntegrationGenericActionModal"
                show-formulas-tab="data.isIntegrationGenericActionModal"
                should-delete-variable="data.shouldDeleteVariable"
                hide-editor-button="data.isIntegrationGenericActionModal"
            ></tnk-tonkean-expression>
        </div>

        <!-- File Upload MimeType -->
        <div class="margin-top-md">
            <div class="margin-bottom-xs common-title-xxs">
                File Mime Type
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Optional. For example: text/csv. Default: application/octet-stream"
                ></i>
            </div>

            <!-- Input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Enter the file's mime type"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.fileMimeTypeExpression"
                expression-unique-identifier="UploadFileMimeType"
                on-tonkean-expression-changed="onFileMimeTypeExpressionChanged(expression, shouldSaveLogic)"
                prefix="{{ data.prefix }}"
                global-expression-only="data.isIntegrationGenericActionModal"
                additional-tabs="data.additionalTabsForTonkeanExpression"
                do-not-evaluate-preview="data.isIntegrationGenericActionModal"
                show-formulas-tab="data.isIntegrationGenericActionModal"
                should-delete-variable="data.shouldDeleteVariable"
                hide-editor-button="data.isIntegrationGenericActionModal"
            ></tnk-tonkean-expression>
        </div>

        <!-- Form Fields -->
        <div class="margin-top-md">
            <div class="margin-bottom-xs common-title-xxs">
                Form Fields
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Optional. Relevant when using Content-Type: multipart/form-data."
                ></i>
            </div>

            <!-- Headers -->
            <div
                class="
                    common-bg
                    mod-light-grey
                    margin-top-md
                    flex
                    row
                    common-row-no-gutters
                    padding-normal-sm
                    common-border-dark-softer
                "
                ng-repeat="(formFieldsIndex, formField) in data.formFields"
            >
                <!-- Field Name  -->
                <div class="flex flex-col" style="width: 100%">
                    <span class="padding-none">
                        <span class="common-color-dark-grey">Field Name</span>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.formFields[formFieldsIndex].formFieldNameExpression"
                            placeholder="Name"
                            on-tonkean-expression-changed="
                                onFormFieldNameTonkeanExpressionChanged(expression, formFieldsIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </span>

                    <!-- Field value -->
                    <span
                        class="padding-left-none padding-right-none margin-top-md padding-bottom-xs"
                        ng-hide="data.formFields[formFieldsIndex].formFieldType === 'File'"
                    >
                        <span class="common-color-dark-grey">Field Value</span>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.formFields[formFieldsIndex].formFieldValueExpression"
                            placeholder="Value"
                            on-tonkean-expression-changed="
                                onFormFieldValueTonkeanExpressionChanged(expression, formFieldsIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </span>

                    <!-- Field Type -->
                    <span class="padding-left-none padding-right-none margin-top-md padding-bottom-xs">
                        <span class="common-color-dark-grey">Field Type</span>
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.formFields[formFieldsIndex].formFieldType"
                            ng-change="onDefinitionChanged(true)"
                        >
                            <ui-select-match placeholder="Field Type">
                                {{ data.formFields[formFieldsIndex].formFieldType }}
                            </ui-select-match>

                            <ui-select-choices repeat="formFieldType in data.formFieldTypes | filter:$select.search">
                                <div ng-bind-html="formFieldType | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </span>

                    <span
                        class="padding-left-none padding-right-none padding-bottom-xs"
                        ng-show="data.formFields[formFieldsIndex].formFieldType === 'File'"
                    >
                        <span class="common-size-xxxs common-color-grey common-text-wrap">
                            * We support only 1 field of type "File"
                        </span>
                    </span>
                </div>

                <!-- Remove button -->
                <div class="col-sm-1 padding-left-none padding-right-none" ng-if="data.headersArray.length > 0">
                    <button
                        type="button"
                        class="btn btn-sm btn-no-border padding-normal-xxs margin-top-xxs margin-left-xs"
                        ng-click="removeFormField(formFieldsIndex)"
                    >
                        <i class="svg-icon-xxs common-color-grey">
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </i>
                    </button>
                </div>

                <!-- Separator -->
                <hr />
            </div>

            <!-- Add button -->
            <div class="margin-top-xs">
                <a ng-click="addFormField()">+ Add Field</a>
            </div>
        </div>
    </div>

    <!-- Not-GET HTTP method body & preview -->
    <div class="margin-top-md" ng-if="data.method !== 'GET' && !data.isHttpUploadAction">
        <!-- Title -->
        <div class="margin-bottom-xs flex-vmiddle mod-justify-space">
            <span class="common-title-xxs">
                Raw Body
                <span ng-if="data.contentType === 'application/json'">(JSON)</span>
            </span>
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter the body of the request"
            saved-original-expression="data.body"
            saved-evaluated-expression="data.bodyEvaluatedText"
            on-tonkean-expression-changed="
                onBodyTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            mod-text-area="true"
            global-expression-only="data.isIntegrationGenericActionModal"
            additional-tabs="data.additionalTabsForTonkeanExpression"
            do-not-evaluate-preview="data.isIntegrationGenericActionModal"
            show-formulas-tab="data.isIntegrationGenericActionModal"
            should-delete-variable="data.shouldDeleteVariable"
            hide-editor-button="data.isIntegrationGenericActionModal"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <!-- Headers -->
    <div class="margin-top-md">
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noContentType"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noContentType }}
        </div>

        <div class="common-title-xxs margin-bottom-xs">Headers</div>

        <!-- Content Type Header -->
        <div
            ng-if="!data.isHttpUploadAction && data.method !== 'GET'"
            class="
                flex flex-col
                row
                common-row-no-gutters
                padding-bottom-xs
                common-row-no-gutters common-bg
                mod-light-grey
                padding-normal-sm
                common-border-dark-softer
            "
        >
            <!-- Header name -->
            <span class="padding-none common-color-dark-grey">
                Header Name
                <input
                    type="text"
                    class="form-control flex-grow expression-input"
                    value="Content-Type"
                    disabled="disabled"
                />
            </span>

            <!-- Content type -->
            <span class="padding-right-none margin-top-md common-color-dark-grey padding-bottom-xs">
                Header Value
                <ui-select
                    class="relative"
                    theme="bootstrap"
                    ng-model="data.contentType"
                    on-select="contentTypeSelectionChange($item)"
                    ng-attr-ui-select-required="true"
                >
                    <ui-select-match placeholder="Enter content type..">
                        <span class="common-size-xxxs">{{ data.contentType }}</span>
                    </ui-select-match>
                    <ui-select-choices
                        group-by="data.groupBy"
                        repeat="contentTypeOption in getContentTypeOptions($select.search) | filter:$select.search | orderBy : 'orderBy': false"
                    >
                        <span class="common-size-xxxs">{{ contentTypeOption }}</span>
                    </ui-select-choices>
                </ui-select>
            </span>
        </div>

        <!-- Headers -->
        <div
            class="
                common-bg
                mod-light-grey
                margin-top-md
                flex
                row
                common-row-no-gutters
                padding-normal-sm
                common-border-dark-softer
            "
            ng-repeat="headerRow in data.headersArray"
        >
            <!-- Header name input -->
            <div class="flex flex-col" style="width: 100%">
                <span class="padding-none">
                    <span class="common-color-dark-grey">Header Name</span>
                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Header Name"
                        saved-original-expression="headerRow.name.originalExpression"
                        saved-evaluated-expression="headerRow.name.evaluatedExpression"
                        expression-unique-identifier="OWHeaderName{{ $index }}"
                        on-tonkean-expression-changed="
                            onHeaderNameTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic,
                                data.headersArray,
                                $index
                            )
                        "
                        global-expression-only="data.isIntegrationGenericActionModal"
                        additional-tabs="data.additionalTabsForTonkeanExpression"
                        do-not-evaluate-preview="data.isIntegrationGenericActionModal"
                        show-formulas-tab="data.isIntegrationGenericActionModal"
                        should-delete-variable="data.shouldDeleteVariable"
                        hide-editor-button="data.isIntegrationGenericActionModal"
                        preview-evaluation-source="data.previewEvaluationSource"
                    ></tnk-tonkean-expression>
                </span>
                <!-- Header value input -->
                <span class="padding-left-none padding-right-none margin-top-md padding-bottom-xs">
                    <span class="common-color-dark-grey">Header Value</span>
                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Header Value"
                        saved-original-expression="headerRow.value.originalExpression"
                        saved-evaluated-expression="headerRow.value.evaluatedExpression"
                        expression-unique-identifier="OWHeaderValue{{ $index }}"
                        mod-text-area="true"
                        on-tonkean-expression-changed="
                            onHeaderValueTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic,
                                data.headersArray,
                                $index
                            )
                        "
                        global-expression-only="data.isIntegrationGenericActionModal"
                        additional-tabs="data.additionalTabsForTonkeanExpression"
                        do-not-evaluate-preview="data.isIntegrationGenericActionModal"
                        show-formulas-tab="data.isIntegrationGenericActionModal"
                        should-delete-variable="data.shouldDeleteVariable"
                        hide-editor-button="data.isIntegrationGenericActionModal"
                        preview-evaluation-source="data.previewEvaluationSource"
                    ></tnk-tonkean-expression>
                </span>
            </div>

            <!-- Remove button -->
            <div class="col-sm-1 padding-left-none padding-right-none" ng-if="data.headersArray.length > 0">
                <button
                    type="button"
                    class="btn btn-sm btn-no-border padding-normal-xxs margin-top-xxs margin-left-xs"
                    ng-click="removeHeader(data.headersArray, $index)"
                >
                    <i class="svg-icon-xxs common-color-grey">
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </i>
                </button>
            </div>

            <!-- Separator -->
            <hr />
        </div>

        <!-- Add button -->
        <div class="margin-top-xs">
            <a ng-click="addHeader(data.headersArray)">+ Add header</a>
        </div>
    </div>

    <!-- Disable follow redirect -->
    <hr />
    <div>
        <tnk-checkbox
            class="margin-normal-sm-v"
            label="'Disable follow redirects'"
            value="data.disableFollowRedirects"
            on-toggle="onDisableFollowRedirectsToggled(value)"
        ></tnk-checkbox>
    </div>

    <!-- Ignore Untrusted certificates -->
    <div>
        <tnk-checkbox
            class="margin-normal-sm-v"
            label="'Ignore untrusted certificates'"
            value="data.ignoreUntrustedCertificates"
            on-toggle="onIgnoreUntrustedCertificatesToggled(value)"
        ></tnk-checkbox>
    </div>

    <!-- Disable auto charset -->
    <div>
        <tnk-checkbox
            class="margin-normal-sm-v"
            uib-tooltip="If off we automatically set the charset to utf-8"
            label="'Disable Auto Charset'"
            value="data.disableAutoCharset"
            on-toggle="onDisableAutoCharsetToggled(value)"
        ></tnk-checkbox>
    </div>

    <!-- Keep Trailing Forward Slash -->
    <div>
        <tnk-checkbox
            class="margin-normal-sm-v"
            uib-tooltip="Tonkean will automatically remove a '/' character if it's the last one in the URL, check this box to disable this behaviour"
            label="'Keep Trailing Forward Slash'"
            value="data.keepTrailingForwardSlash"
            on-toggle="onKeepTrailingForwardSlashToggled(value)"
        ></tnk-checkbox>
    </div>

    <!-- Retry on response status codes -->
    <div ng-if="!data.isIntegrationGenericActionModal">
        <hr />
        <div class="flex-vmiddle margin-bottom">
            <!-- Title -->
            <div class="margin-right-xs">Retry on these response status codes:</div>

            <!-- Info -->
            <div
                uib-popover-template="'./retryExplanation.template.html'"
                popover-placement="top-middle"
                popover-class="common-width-60"
                popover-trigger="mouseenter"
            >
                <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
            </div>
        </div>

        <tags-input
            ng-model="data.retryOnStatusCodesAsObjectArray"
            class="flex-grow"
            add-from-autocomplete-only="false"
            add-on-enter="true"
            add-on-blur="true"
            placeholder="Add Status Codes..."
            replace-spaces-with-dashes="false"
            on-tag-added="onStatusCodesTagsChanged()"
            on-tag-removed="onStatusCodesTagsChanged()"
        ></tags-input>
    </div>
</div>
