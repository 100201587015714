<div
    class="color-options-picker"
    popover-placement="bottom-left"
    popover-trigger="outsideClick"
    popover-is-open="data.isOpen"
    uib-popover-template="'./colorOptionsPickerTemplate.template.html'"
    popover-class="state-color-picker-modal"
    popover-animation="false"
    popover-enable="!data.isDisabled"
>
    <div
        class="state-option-color-circle margin-right-xxs flex-no-shrink"
        ng-style="{ background: data.selectedColor }"
        ng-class="{ 'mod-editable pointer': !data.isDisabled }"
        dnd-nodrag
    ></div>
</div>
