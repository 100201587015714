<div class="common-size-xxs show-edit-when-hover-visibility">
    <!--<hr class="margin-normal-sm-v hidden-xs"/>-->
    <button
        class="btn btn-link activity-comment-delete btn-on-hover"
        ng-if="pm.isOwner ||item.actor.id === as.currentUser.id"
        ng-disabled="deleting"
        ng-click="deleteItem(item.reference1.id,true)"
    >
        <i ng-hide="deleting" class="fa fa-trash-o"></i>
        <i ng-show="deleting" class="loading"></i>
    </button>
    <div class="common-break-long" light-markdown="item.reference1.text"></div>
</div>
