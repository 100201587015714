import React, { useCallback, useContext, useMemo } from 'react';

import FormulaContext from '../../entities/FormulaContext';
import usePairValuesFilter from '../../hooks/usePairValuesFilter';

import { FieldTypeIcon } from '@tonkean/fields';
import type { FieldType } from '@tonkean/tonkean-entities';
import { fieldTypeToFieldDisplayName } from '@tonkean/tonkean-entities';
import { getParticipatingVariablesForFormula } from '@tonkean/tonkean-utils';
import utils, { classNames } from '@tonkean/utils';

interface Props {
    customFieldsManager: any;
    workflowVersionId: string;
    onSelected: (fieldDefinition: any) => void;
    filter: string;
}

const FormulaPopoverTabVariable: React.FC<Props> = ({ customFieldsManager, workflowVersionId, onSelected, filter }) => {
    const { fieldDefinition: existingFieldDefinition } = useContext(FormulaContext);

    // Create data type to field definitions list pairs list
    const dataTypeToFieldDefinitionsPairsList = useMemo(() => {
        // Get list of variables
        const { variables } = getParticipatingVariablesForFormula(
            customFieldsManager.selectedGlobalFieldsMap[workflowVersionId],
            customFieldsManager.selectedColumnFieldsMap[workflowVersionId],
            'COLUMN_FORMULA',
        );

        // Create the pairs list
        return Object.entries(utils.groupBy(variables, ({ displayAs }) => fieldTypeToFieldDisplayName[displayAs])) as [
            FieldType,
            any[],
        ][];
    }, [customFieldsManager, workflowVersionId]);

    const filterFunction = useCallback(
        (fieldDefinition: { label?: string; name?: string }, searchString: string): boolean =>
            (fieldDefinition.label?.toLowerCase().includes(searchString) ||
                fieldDefinition.name?.toLowerCase().includes(searchString) ||
                false) &&
            existingFieldDefinition?.name !== fieldDefinition.name,
        [existingFieldDefinition?.name],
    );
    const filteredFieldDefinitionByDataType = usePairValuesFilter(
        dataTypeToFieldDefinitionsPairsList,
        filterFunction,
        filter,
    );

    return (
        <div className="inline-select-list">
            {filteredFieldDefinitionByDataType.map(
                ([dataType, fields]) =>
                    fields.length && (
                        <div key={dataType} className="inline-select-list-data-type">
                            <h5 className="inline-select-title">{dataType}</h5>
                            {fields.map((field, index) => (
                                <button
                                    key={field.key || `f${index}`}
                                    type="button"
                                    className="inline-select-item"
                                    onClick={() => onSelected(field)}
                                    data-automation={`formula-popover-tab-variable-item-${field.name}`}
                                >
                                    <span className="tabs-field-selector-field-icon-container">
                                        {(field.definition?.matchConfiguration?.isForMatchingItem && (
                                            <span className="inline-block mod-sm mod-circle initiative-integration-icon mod-match" />
                                        )) ||
                                            ((field.definition?.integrationType || field.source) && (
                                                <span
                                                    className={`inline-block mod-sm mod-circle initiative-integration-icon mod-${(
                                                        field.definition.integrationType || field.source
                                                    ).toLowerCase()}`}
                                                />
                                            )) ||
                                            (field.iconModClass && (
                                                <span
                                                    className={classNames(
                                                        'inline-block mod-sm mod-circle initiative-integration-icon',
                                                        field.iconModClass,
                                                    )}
                                                />
                                            )) || (
                                                <span className="common-color-grey-soft">
                                                    <FieldTypeIcon fieldType={field.fieldType} size="xs" />
                                                </span>
                                            )}
                                    </span>
                                    {field.label || field.name}
                                </button>
                            ))}
                        </div>
                    ),
            )}
        </div>
    );
};

export default FormulaPopoverTabVariable;
