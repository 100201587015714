import React from 'react';

import { StateMessage } from '@tonkean/infrastructure';
import { NoCommentsEmptyIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

const NoCommentsView: React.FC = () => (
    <StateMessage
        data-automation="no-comment-view-empty-state"
        titleColor={Theme.colors.gray_700}
        icon={<NoCommentsEmptyIcon />}
        title="No comments yet"
        secondaryColor={Theme.colors.gray_600}
    >
        Comments will be displayed here when someone posts them
    </StateMessage>
);

export default NoCommentsView;
