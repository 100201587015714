import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addFormAnsweredBlockAfterButtonCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addFormAnsweredBlockAfterButtonCreateAfterOption';
import addNewLogicOptionNotAfterOpenForm from '../sharedConfigComponents/form/createAfterOptions/addNewLogicOptionNotAfterOpenForm';
import storageProviderValidator from '../sharedConfigComponents/validators/storageProviderValidator';
import validateParentTriggerChannelResponse from '../sharedConfigComponents/validators/validateParentTriggerChannelResponse';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const BotButtonPressedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.BOT_BUTTON_PRESSED,
    title: 'When Module Button Pressed',
    iconClass: 'mod-bot-button-pressed',
    description:
        "This button will be displayed to the person receiving the Module's message. Once clicked, the following blocks would be triggered.",
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: true,
    cantDrag: false,
    allowedParentTypes: [LogicBlockConfigType.PERSON_INQUIRY, LogicBlockConfigType.SEND_FORM],
    createAfterOptions: () => [addFormAnsweredBlockAfterButtonCreateAfterOption, addNewLogicOptionNotAfterOpenForm],
    validator: ({ definition, parentTrigger, group, formInfoRetriever }) => {
        const validationObject: LogicBlockValidationObject = {};
        if (definition) {
            const form = formInfoRetriever.getWorkerFormFromCache(group.draftWorkflowVersionId, definition.formId);

            validateParentTriggerChannelResponse(validationObject, definition, parentTrigger);
            storageProviderValidator(validationObject, definition, form);
        }
        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    enricher: (customTrigger, definition) => {
        if (
            definition.selectedActionType === 'open-link' ||
            definition.selectedActionType === 'open-custom-interface'
        ) {
            customTrigger.cantAddChildren = true;
        } else {
            customTrigger.cantAddChildren = false;
        }
    },
};

export default BotButtonPressedLogicBlockConfig;
