import React, { useMemo } from 'react';

import { ReactComponent as ContractSVG } from '../icons/contract.svg';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { ContractSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

export interface ContractSelectorProps {
    contracts: ContractSummary[] | undefined;
    loading: boolean;
    selectedContract?: TonkeanId<TonkeanType.CONTRACT>;
    onChange?: (contractId: TonkeanId<TonkeanType.CONTRACT> | undefined) => void | undefined;
    name?: string;
    size?: InputComponentSizes;
    disabled?: boolean;
}

const ContractSelector: React.FC<ContractSelectorProps> = ({
    contracts,
    loading,
    selectedContract,
    onChange,
    name,
    disabled,
    size,
}) => {
    const options = useMemo(() => {
        return (
            contracts?.map((contract) => ({
                label: contract.displayName,
                value: contract.id,
                icon: <ContractSVG />,
            })) || EMPTY_ARRAY
        );
    }, [contracts]);

    return (
        <SimpleSelect
            isDisabled={loading || disabled}
            placeholder="No Contract"
            isLoading={loading}
            options={options}
            size={size}
            value={selectedContract}
            onChange={onChange}
            name={name}
            isClearable
        />
    );
};

export default ContractSelector;
