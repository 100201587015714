import template from './previewListColumns.template.html?angularjs';

/**
 * Component to preview list columns.
 */
export default angular.module('tonkean.app').component('tnkPreviewListColumns', {
    bindings: {
        groupId: '@', // The group id we're previewing
        workflowVersionId: '@', // The group id we're previewing
        definitionsToPreview: '<', // The definitions we'd like to preview
        existingFieldDefinitionId: '<', // The field definition id we are currently previewing (editing)
        highlightedDefinitionId: '@', // The definition we'd like to highlight
        highlightedFieldDefinitions: '<',
        globalFieldsToPreview: '<', // Any global fields we'd like to preview, in addition to the list preview.
        hidePreviewTitle: '@', // If true, will hide the Preview upper title.
        reloadPreview: '<', // If toggled to true, will reload the preview.
        softLoading: '<', // If true, won't show loading state, but rather a small loading circle
        isBrokenIntegration: '<', // A boolean indicating whether the integration is broken or not.
        filterInitiativesByTonkeanQuery: '<', // A tonkean query to filter initiatives by.
        emptyStateType: '@', // The type of empty state to show when there are no results.
    },
    template,
    controller: 'PreviewListColumnsCtrl',
});
