import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { SMART_SEARCH_MOBILE_BREAKPOINT } from './SmartSearchConsts';
import SmartSearchResponseView from './SmartSearchResponseView';

import { ADD_DIRECTION, useBreakpoint } from '@tonkean/infrastructure';
import { SmartConversationView, type SmartConversationManager } from '@tonkean/smart-conversation';
import type {
    SmartConversationMessage,
    SmartSearchEntityAnalysisResponse,
    SmartSearchResponse,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const StyledSmartConversationView = styled(SmartConversationView)`
    // Big screen
    @media screen and (min-width: ${SMART_SEARCH_MOBILE_BREAKPOINT}px) {
        // Making this element a scroll container is tricky because the entire modal doesnt have a height, so css cant determine when to make this a scroller.
        // Because of that we have a "fixed" max height for this element, allowing it to grow until this height and then scroll
        max-height: calc(100vh - 300px);
    }
    // Small screen
    @media screen and (max-width: ${SMART_SEARCH_MOBILE_BREAKPOINT}px) {
        height: 100%;
    }
`;

export interface SmartSearchConversationViewProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    showTalkToExpert?: boolean;
    needHelpClicked?: () => void;
    smartConversationManager: SmartConversationManager<SmartSearchResponse>;
}

const SmartSearchConversationView: React.FC<SmartSearchConversationViewProps> = ({
    projectId,
    showTalkToExpert,
    needHelpClicked,
    smartConversationManager,
}) => {
    const breakPoint = useBreakpoint();
    const isMobile = breakPoint <= SMART_SEARCH_MOBILE_BREAKPOINT;

    const onRequestAnalysis = useCallback(
        (data: SmartSearchEntityAnalysisResponse, messageId: string) => {
            const lookupMessageId = smartConversationManager.messages.findIndex((item) => item.messageId === messageId);
            const messagesAfterLookupMessage = smartConversationManager.messages.slice(lookupMessageId + 1);
            messagesAfterLookupMessage.forEach((item) =>
                smartConversationManager.messagesHelpers.onDelete(item.messageId),
            );

            smartConversationManager.messagesHelpers.onCreate(
                { showAnimation: true, messageId: utils.guid(), ...data },
                ADD_DIRECTION.END,
            );
        },
        [smartConversationManager.messages, smartConversationManager.messagesHelpers],
    );

    const [showReplyBar, setShowReplyBar] = useState(false);
    const onMessageAnimationEnd = useCallback(() => {
        setShowReplyBar(true);
    }, []);
    const [isInnerLoading, setIsInnerLoading] = useState(false);

    const loading = smartConversationManager.replyMethodLoading || isInnerLoading;
    const error = smartConversationManager.replyMethodError;

    const messageTransformer = useCallback(
        (message: SmartConversationMessage<SmartSearchResponse>, index: number) => {
            return (
                <SmartSearchResponseView
                    key={message.messageId}
                    message={message}
                    projectId={projectId}
                    previousMessage={smartConversationManager.messages[index - 1]?.response}
                    allMessages={smartConversationManager.messages}
                    onRequestAnalysis={(data: SmartSearchEntityAnalysisResponse) =>
                        onRequestAnalysis(data, message.messageId)
                    }
                    setLoading={setIsInnerLoading}
                    onAnimationEnd={() => {
                        message.showAnimation = false;
                        onMessageAnimationEnd();
                    }}
                />
            );
        },
        [projectId, smartConversationManager.messages, onMessageAnimationEnd, onRequestAnalysis],
    );

    return (
        <StyledSmartConversationView
            smartConversationManager={smartConversationManager}
            messageTransformer={messageTransformer}
            loading={loading}
            error={error}
            showReplyBar={showReplyBar}
            takeFullHeight={isMobile}
            showTalkToExpert={showTalkToExpert}
            needHelpClicked={needHelpClicked}
        />
    );
};

export default SmartSearchConversationView;
