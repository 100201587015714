import React from 'react';
import styled from 'styled-components';

import ItemInterfaceBody from './ItemInterfaceBody';
import type { ItemInterfaceHeaderProps } from './ItemInterfaceHeader';
import ItemInterfaceHeader from './ItemInterfaceHeader';
import ItemInterfaceScrollContextProvider from './ItemInterfaceScrollContextProvider';
import { useIsTabsViewItemInterface } from './useIsTabsViewItemInterface';
import GeneralInterfaceWrapper from '../../../components/GeneralInterfaceWrapper';
import type ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import InterfaceSubmitValidationContext from '../../../utils/InterfaceSubmitValidationContext';
import useUpdateWidgetsToValidate from '../../../widgets/hooks/useUpdateWidgetsToValidate';
import useScrollHandler from '../../hooks/useScrollHandler';
import getSubmitValidationContext from '../../utils/getSubmitValidationContext';

import { Breakpoint, useItemInterfaceContext } from '@tonkean/infrastructure';
import type {
    Initiative,
    ItemInterfaceLoadingState,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    ValidationState,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        border-radius: 8px 8px 0 0;
    }
    min-height: 100%;
`;

const HeaderWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
`;

export interface ItemInterfaceViewProps {
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    permission: ItemInterfacePermission;
    showPoweredByTonkean: boolean;
    CustomHeaderComponent?: React.FC<ItemInterfaceHeaderProps>;
    hideLogoInHeader?: boolean;
    showReturnToHomepageButton?: boolean;
    cleanMode?: boolean;
    showTitle?: boolean;
    onValidationChangedCallback?: (
        validation: ValidationState,
        setForceShownValidation?: (value: boolean) => void,
    ) => void;
    inheritValidationContext?: boolean;
    inheritQuickNavigationContext?: boolean;
    isNested?: boolean;
}

const ItemInterfaceView: React.FC<ItemInterfaceViewProps> = ({
    initiative,
    workflowVersion,
    itemInterfaceWidgetsState,
    widgets,
    showPoweredByTonkean,
    permission,
    CustomHeaderComponent,
    hideLogoInHeader,
    showReturnToHomepageButton,
    cleanMode,
    showTitle = true,
    onValidationChangedCallback,
    inheritValidationContext,
    inheritQuickNavigationContext,
    isNested = false,
}) => {
    const interfaceSubmitValidationContext = getSubmitValidationContext(
        inheritValidationContext,
        onValidationChangedCallback,
    );

    const setWidgetsIdsToValidate = interfaceSubmitValidationContext.setWidgetsIdsToValidate;

    useUpdateWidgetsToValidate(setWidgetsIdsToValidate, widgets);

    const scrollingObjectStarted = useScrollHandler();

    const HeaderCompononent = CustomHeaderComponent ?? ItemInterfaceHeader;

    const { itemInterface, tabs } = useItemInterfaceContext();
    const tabsView = useIsTabsViewItemInterface(itemInterface);

    return (
        <Wrapper>
            <ItemInterfaceScrollContextProvider scrollingEventObject={scrollingObjectStarted}>
                <InterfaceSubmitValidationContext.Provider value={interfaceSubmitValidationContext}>
                    <GeneralInterfaceWrapper
                        quickNavigationMode={itemInterface.configuration?.quickNavigationMode}
                        inheritQuickNavigationContext={inheritQuickNavigationContext}
                        tabs={tabs}
                    >
                        {showTitle && (
                            <HeaderWrapper>
                                <HeaderCompononent
                                    initiative={initiative}
                                    permission={permission}
                                    widgets={widgets}
                                    tabsView={tabsView}
                                />
                            </HeaderWrapper>
                        )}
                        <ItemInterfaceBody
                            itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                            widgets={widgets}
                            initiative={initiative}
                            workflowVersion={workflowVersion}
                            permission={permission}
                            showPoweredByTonkean={showPoweredByTonkean}
                            cleanMode={cleanMode}
                            tabsView={tabsView}
                            isNested={isNested}
                        />
                    </GeneralInterfaceWrapper>
                </InterfaceSubmitValidationContext.Provider>
            </ItemInterfaceScrollContextProvider>
        </Wrapper>
    );
};

export default ItemInterfaceView;
