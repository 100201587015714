import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function OrderByFieldCtrl(
    $scope,
    utils,
    projectManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetWorkflowVersionId: ctrl.targetWorkflowVersionId || ctrl.workflowVersionId,
        entityMetadata: ctrl.entityMetadata,
        projectIntegration: ctrl.projectIntegration,
        savedMatchOptionSelection: ctrl.savedMatchOptionSelection,
        orderByTonkeanField: ctrl.orderByTonkeanField,
        orderByField: ctrl.savedMatchOptionSelection?.orderByField || null,
        orderByFieldTypes: [
            {
                apiName: 'String',
                displayName: 'Textual',
            },
            {
                apiName: 'Number',
                displayName: 'Number',
            },
            {
                apiName: 'Date',
                displayName: 'Date',
            },
        ],
        // Order types
        orderTypes: [
            {
                apiName: 'ASC',
                displayName: 'Ascending',
            },
            {
                apiName: 'DESC',
                displayName: 'Descending',
            },
        ],
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.data.orderBySelectedOrder = $scope.data.orderTypes[0];
        $scope.data.selectedOrderByFieldType = $scope.data.orderByFieldTypes[0];

        if ($scope.data.savedMatchOptionSelection) {
            const apiNameToOrderObjectMap = utils.createMapFromArray($scope.data.orderTypes, 'apiName');
            $scope.data.orderBySelectedOrder =
                apiNameToOrderObjectMap[$scope.data.savedMatchOptionSelection.orderByOrder];

            $scope.data.orderByField = $scope.data.savedMatchOptionSelection.orderByField;
            const apiNameToOrderByFieldTypeMap = utils.createMapFromArray($scope.data.orderByFieldTypes, 'apiName');
            $scope.data.selectedOrderByFieldType =
                apiNameToOrderByFieldTypeMap[$scope.data.savedMatchOptionSelection.orderByFieldType];
        }

        definitionChanged(false);
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changes) {};

    /**
     * Occurs on selection of field to order by.
     */
    $scope.onOrderByFieldSelected = function (selectedField) {
        $scope.data.orderByField = selectedField?.name ? selectedField : null;
        definitionChanged();
    };

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderByFieldTypeSelectedOrderSelected = function (selectedOrderType) {
        $scope.data.orderByFieldType = selectedOrderType;
        definitionChanged();
    };

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderBySelectedOrderSelected = function (selectedOrder) {
        $scope.data.orderBySelectedOrder = selectedOrder;
        definitionChanged();
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged(shouldSave = true) {
        const sortByFieldObject = {
            orderByField: $scope.data.orderByField,
            orderByFieldType: $scope.data.selectedOrderByFieldType,
            orderBySelectedOrder: $scope.data.orderBySelectedOrder,
        };

        if (ctrl.onSortByFieldChanged) {
            ctrl.onSortByFieldChanged({
                sortByFieldObject,
                shouldSave,
            });
        }
    }
}

export default angular.module('tonkean.app').controller('OrderByFieldCtrl', lateConstructController(OrderByFieldCtrl));
