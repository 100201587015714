import React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
    line-height: 25px;

    > .loading {
        vertical-align: middle;
        margin-right: 5px;
    }
`;

const RegexLoading: React.FC = () => {
    return (
        <LoadingWrapper>
            <span className="loading" />
            loading...
        </LoadingWrapper>
    );
};

export default RegexLoading;
