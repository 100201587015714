import React from 'react';

import SingleWorkerRunModuleItemDetails from './SingleWorkerRunModuleItemDetails';
import SingleWorkerRunModuleItemTable from './SingleWorkerRunModuleItemTable';
import { ReactComponent as ModuleItemIcon } from '../../../../../../images/icons/history/module-item.svg';
import getWorkerRunFullDescription from '../../../utils/getWorkerRunFullDescription';
import SingleWorkerRunContent from '../SingleWorkerRunContent';

import { WorkerRunStage } from '@tonkean/tonkean-entities';
import type { WorkerRun } from '@tonkean/tonkean-entities';

interface Props {
    /** If undefined, it means that it's the initial load of the worker run, and not just a reload. */
    workerRun?: WorkerRun;
}

const SingleWorkerRunModuleItem: React.FC<Props> = ({ workerRun }) => {
    const workerRunFullDescription = getWorkerRunFullDescription(workerRun);

    return (
        <SingleWorkerRunContent
            startTime={workerRun?.startTime}
            icon={
                <span className="tnk-icon">
                    <ModuleItemIcon />
                </span>
            }
            title={workerRun && workerRunFullDescription}
            workerRunStage={WorkerRunStage.MODULE_ITEM}
        >
            <SingleWorkerRunModuleItemDetails workerRun={workerRun} />
            <SingleWorkerRunModuleItemTable workerRun={workerRun} />
        </SingleWorkerRunContent>
    );
};

export default SingleWorkerRunModuleItem;
