import React, { useMemo } from 'react';
import styled from 'styled-components';

import type ProcessMapperWidgetConfiguration from './ProcessMapperWidgetConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../WidgetModule';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { Field, SimpleSelect, Spacer } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    padding: 0 20px;
    padding-bottom: 20px;
`;
const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    margin-bottom: 8px;
`;
const FieldsSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const heightOptions: { value: number; label: string }[] = [
    { label: 'Small', value: 45 },
    { label: 'Medium', value: 60 },
    { label: 'Large', value: 75 },
];

const ProcessMapperWidgetEditor: React.FC<ItemWidgetEditorProps<ProcessMapperWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { data, loading, error } = useTonkeanService('getProcessMappersByWorkflowVersionId', workflowVersion.id);

    const options = useMemo(() => {
        if (data?.entities) {
            return data?.entities.map((mapper) => {
                return {
                    value: mapper.id,
                    label: mapper.displayName,
                } as SimpleSelectSingleOption<any>;
            });
        }
        return [];
    }, [data?.entities]);

    return (
        <>
            <Wrapper>
                <FieldsSectionTitle>Process Map</FieldsSectionTitle>
                <SimpleSelect
                    name="configuration.selectedProcessMapper"
                    options={options}
                    isLoading={loading}
                    isClearable
                />
                <Field inlineError>
                    <Spacer height={15} />
                    <Label>Height</Label>
                    <SimpleSelect name="configuration.height" options={heightOptions} />
                </Field>
            </Wrapper>
        </>
    );
};

export default ProcessMapperWidgetEditor;
