<div class="email-webhook-modal">
    <!-- Modal title -->
    <div class="common-title modal-header flex-vmiddle flex-wrap">
        <i class="initiative-integration-icon flex-no-shrink mod-normal margin-right mod-emailwebhook"></i>
        <span class="flex-grow margin-right-xs">
            {{ data.incomingWebhook.displayName ? data.incomingWebhook.displayName : 'New Email Data Source' }}
            <span ng-if="data.incomingWebhook.displayName" class="common-color-grey common-size-xxs">
                (Custom Source)
            </span>
        </span>
        <span class="flex-no-shrink common-size-xxs">
            <a ng-href="{{::$root.knowledgeBase.pages.webhooks}}" target="_blank">Need help?</a>
        </span>
    </div>

    <!-- Modal body -->
    <div class="modal-body">
        <!-- Webhook definition form -->
        <form
            name="data.definitionForm"
            ng-submit="createOrEditWebHook()"
            ng-if="!data.loadingEditMode && !data.loadingDeleteWebhook"
            novalidate
        >
            <!-- Settings Tab -->
            <div ng-if="!data.incomingWebhook.definition.titleFieldPath">
                <div class="padding-normal-h">
                    <!-- Integration display name field -->
                    <div
                        class="margin-bottom-lg margin-top-lg"
                        ng-hide="data.waitingForMetadataMode || data.webhookSource"
                    >
                        <!-- Title -->
                        <div class="common-size-xxs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                            Choose a name for this Email inbox data source:
                        </div>
                        <!-- Input and explanation -->
                        <div>
                            <input
                                type="text"
                                class="form-control"
                                data-automation="modal-template-enter-name-new-email-intake-data-source"
                                ng-model="data.incomingWebhook.displayName"
                                placeholder="Enter name"
                                required
                            />
                        </div>
                    </div>

                    <!-- Title -->
                    <div
                        class="
                            common-size-xxs common-bold
                            margin-right
                            padding-right-none
                            margin-top-xs margin-bottom-xs
                        "
                    >
                        Attachment Storage:
                    </div>

                    <div class="common-color-grey margin-bottom">
                        To use attachment storage, connecting {{ data.filterByIntegrationsDisplayName }} as a data
                        source is needed.
                    </div>

                    <!-- Input and explanation -->
                    <div class="flex-grow">
                        <tnk-project-integration-selector
                            selected-project-integration-id="{{
                                data.storageIntegration ? data.storageIntegration.id : null
                            }}"
                            allow-default-option="true"
                            default-option-label="None"
                            filter-on-integration-type="data.filterByIntegrationsNames"
                            on-project-integration-selected="
                                onStorageProjectIntegrationSelected(selectedProjectIntegration)
                            "
                            is-for-upload="true"
                        ></tnk-project-integration-selector>
                    </div>

                    <div ng-if="data.storageIntegration && data.storageIntegration.integration.integrationType !== 'NO_CODE_DATA_SOURCE'" class="margin-top-lg">
                        <!-- Title -->
                        <div
                            class="
                                common-size-xxs common-bold
                                margin-right
                                padding-right-none
                                margin-top-xs margin-bottom-xs
                            "
                        >
                            Attachment
                            {{
                                data.storageIntegration.integration.integrationType === 'GOOGLEDRIVE' ||
                                data.storageIntegration.integration.integrationType === 'SMARTSHEETWORKSPACE'
                                    ? 'Folder Id'
                                    : 'Inner Path'
                            }}:
                        </div>
                        <!-- Input and explanation -->
                        <div class="flex-grow">
                            <div ng-if="data.storageIntegration" class="margin-top">
                                <input
                                    type="text"
                                    class="form-control"
                                    ng-model="data.storageIntegrationConfiguration.innerPath"
                                    placeholder="Enter {{
                                        data.storageIntegration.integration.integrationType === 'GOOGLEDRIVE'
                                            ? 'folder id'
                                            : 'inner path'
                                    }} to put the attachments in (Optional)"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        ng-if="
                                data.storageIntegration &&
                                data.storageIntegration.integration.integrationType === 'NO_CODE_DATA_SOURCE'
                            ">
                        <tnk-no-code-project-integration-upload-dynamic-parameters
                            configuration-project-integration-id="data.projectIntegration.id"
                            storage-project-integration="data.storageIntegration"
                            on-storage-integration-dynamic-parameters-changed="(onStorageConfigurationDynamicParametersChanged)"
                        ></div>
                </div>
            </div>

            <div ng-if="data.incomingWebhook.definition.titleFieldPath">
                <div class="padding-normal-h">
                    <!-- Integration display name field -->
                    <div class="margin-bottom-lg margin-top-lg">
                        <div class="flex-vmiddle">
                            <!-- Title -->
                            <div
                                class="
                                    common-size-xxs common-bold
                                    margin-right
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                "
                            >
                                Inbox Address:
                            </div>
                            <!-- Input and explanation -->
                            <div class="flex-grow">
                                <div class="input-group">
                                    <div class="relative flex">
                                        <input
                                            type="text"
                                            id="input-emailAddress"
                                            class="
                                                form-control
                                                inline-block
                                                text-center
                                                common-width-full
                                                email-endpoint-modal-link-input
                                            "
                                            ng-model="data.incomingWebhook.definition.titleFieldPath"
                                        />
                                    </div>
                                    <span class="input-group-btn">
                                        <button
                                            type="button"
                                            class="btn btn-default email-endpoint-modal-copy-btn"
                                            data-automation="modal-template-copy-input-emil-address-to-clipboard"
                                            ng-click="copyUrlToClipboard('input-emailAddress')"
                                        >
                                            Copy
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex-vmiddle margin-top">
                            <!-- Title -->
                            <div
                                class="
                                    common-size-xxs common-bold
                                    margin-right
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                "
                            >
                                Attachment Storage:
                            </div>

                            <!-- Input and explanation -->
                            <div class="flex-grow">
                                <tnk-project-integration-selector
                                    selected-project-integration-id="{{
                                        data.storageIntegration ? data.storageIntegration.id : null
                                    }}"
                                    allow-default-option="true"
                                    default-option-label="None"
                                    filter-on-integration-type="data.filterByIntegrationsNames"
                                    on-project-integration-selected="
                                        onStorageProjectIntegrationSelected(selectedProjectIntegration)
                                    "
                                    is-for-upload="true"
                                ></tnk-project-integration-selector>
                            </div>

                            <!-- Loading -->
                            <i
                                class="loading-small margin-right flex-no-shrink"
                                ng-if="data.loadingStorageIntegration"
                            ></i>
                        </div>

                        <div ng-if="data.storageIntegration && data.storageIntegration.integration.integrationType !== 'NO_CODE_DATA_SOURCE'" class="flex-vmiddle">
                            <!-- Title -->
                            <div
                                class="
                                    common-size-xxs common-bold
                                    margin-right
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                "
                            >
                                Attachment
                                {{
                                    data.storageIntegration.integration.integrationType === 'GOOGLEDRIVE' ||
                                    data.storageIntegration.integration.integrationType === 'SMARTSHEETWORKSPACE'
                                        ? 'Folder
                                Id'
                                        : 'Inner Path'
                                }}:
                            </div>

                            <!-- Input and explanation -->
                            <div class="flex-grow">
                                <div class="margin-top">
                                    <input
                                        type="text"
                                        class="form-control"
                                        ng-change="onInnerPathEdited()"
                                        ng-model="data.storageIntegrationConfiguration.innerPath"
                                        placeholder="Enter {{
                                            data.storageIntegration.integration.integrationType === 'GOOGLEDRIVE'
                                                ? 'folder id'
                                                : 'inner path'
                                        }} to put the attachments in (Optional)"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            ng-if="
                                data.storageIntegration &&
                                data.storageIntegration.integration.integrationType === 'NO_CODE_DATA_SOURCE'
                            ">
                            <tnk-no-code-project-integration-upload-dynamic-parameters
                            configuration-project-integration-id="data.projectIntegration.id"
                            storage-project-integration="data.storageIntegration"
                        ></div>

                        <div class="common-size common-color-danger">{{ data.errorMessage }}</div>

                        <div class="margin-top-xxlg">
                            <div class="common-size-xxxs mod-blue-light padding-normal-sm rounded-box">
                                <span class="svg-icon-nav-sm margin-right-xs">
                                    <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                                </span>

                                <strong>Use this email address to send emails to this Data Source:</strong>

                                <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                    <li>
                                        <div class="padding-left-xxs">
                                            Add it in CC to forward any items you’d like Tonkean to handle.
                                        </div>
                                    </li>
                                    <li>
                                        <div class="padding-left-xxs">Add it to a distribution list.</div>
                                    </li>
                                    <li>
                                        <div class="padding-left-xxs">
                                            Create rules in your inbox provider in order to automatically forward items
                                            to this address.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Buttons -->
            <div class="margin-bottom flex-vmiddle mod-justify-center margin-top-xlg">
                <!-- Loading -->
                <i class="loading-small margin-right flex-no-shrink" ng-if="data.loadingWebhook"></i>

                <!-- Cancel button (when no in edit mode) -->
                <button
                    type="button"
                    class="btn btn-default margin-right-xs flex-no-shrink"
                    data-automation="modal-template--exit-incoming-webhook-create-mode-close-button"
                    analytics-on
                    analytics-category="Webhooks"
                    analytics-event="Exit Window Incoming Webhook Create Mode"
                    analytics-label="{{ data.incomingWebhook.displayName }}"
                    ng-click="cancel()"
                >
                    Close
                </button>

                <!-- Create button (appears if there's no integration yet) -->
                <button
                    type="submit"
                    ng-disabled="data.loadingWebhook"
                    ng-if="!data.projectIntegration"
                    class="btn btn-primary flex-no-shrink"
                    data-automation="modal-template-create-email-intake-data-source"
                    analytics-on
                    analytics-category="Webhooks"
                    analytics-event="Create Incoming Webhook"
                    analytics-label="{{ data.incomingWebhook.displayName }}"
                >
                    Create
                </button>
            </div>

            <!-- Error -->
            <div class="common-color-danger" ng-if="data.errorMessage">
                Error trying to create a new integration - {{ data.errorMessage }}.
            </div>
        </form>

        <!-- Loading edit mode -->
        <div class="flex-vmiddle mod-center margin-top-lg margin-bottom-lg" ng-if="data.loadingEditMode">
            <i class="loading-medium"></i>
            <span class="common-bold common-size-xs margin-left">Loading configuration...</span>
        </div>

        <!-- Deleting webhook -->
        <div class="flex-vmiddle mod-center margin-top-lg margin-bottom-lg" ng-if="data.loadingDeleteWebhook">
            <i class="loading-medium"></i>
            <span class="common-bold common-size-xs margin-left">Deleting configuration...</span>
        </div>
    </div>
</div>
