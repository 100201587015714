import { IntegrationType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { getStateError } from '@tonkean/utils';

/* @ngInject */
function GoogleDriveSelectorCtrl(
    $scope,
    createProjectApis,
    utils,
    projectManager,
) {
    const ctrl = this;

    $scope.utils = utils;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,

        configSelections: {},

        connecting: false,
        error: null,
        isEdit: false,
        isEditingPermissions: false,
        integrationPermissions: 'readwrite',

        configStep: 'selectDomain',

        currentFolder: null,
        selectedFolder: null,
        folders: [],
        folderNavigationHistory: [], // Used as a stack to keep the history of navigation (in order to go back)
        foldersLoading: false,

        folderSearch: '',
        integrationType: IntegrationType.GOOGLEDRIVE,
        authenticationType: null,
        shouldIndexContent: null,
        integration: ctrl.integration || ctrl.projectIntegration?.integration,

        isSharedCredential: false,
        isMock: false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.isMock = $scope.data.integration.isMock;

        if ($scope.data.projectIntegration || $scope.data.isMock) {
            initializeEditMode();
        } else if ($scope.data.integration) {
            // If project integration is not present, but integration is - it means we have a shared credential state.
            $scope.data.isSharedCredential = true;
        }
    };

    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    $scope.authorize = async function (config, displayName, additionalData) {
        $scope.data.connecting = true;
        $scope.data.error = null;

        if (additionalData) {
            // The oauth permission type (if present). readwrite / read only.
            if (additionalData.oauthPermissionType) {
                $scope.data.integrationPermissions = additionalData.oauthPermissionType;
            }

            // The authentication type (oauth, internal gcp, service account)
            if (additionalData.environment) {
                $scope.data.authenticationType = additionalData.environment;
            }
        }

        if (config) {
            $scope.data.shouldIndexContent = config.shouldIndexContent;
        }

        try {
            $scope.data.integration = await createProjectApis.createIntegration(
                projectManager.project.id,
                'googledrive',
                config,
                undefined,
                undefined,
            );
        } catch (error) {
            $scope.data.error = error;
        } finally {
            $scope.data.connecting = false;
            $scope.data.isMock = $scope.data.integration.isMock;

            if ($scope.data.isEditingPermissions) {
                if ($scope.data.projectIntegration) {
                    // changing the valid of the project integration to true so the "Fix" button would disappear.
                    const integration = utils.findFirstById(
                        projectManager.project.integrations,
                        $scope.data.projectIntegration.id,
                    );

                    // Changing the updateable property in cache, so when we open modal again the permissions are correct
                    integration.updateable = $scope.data.integrationPermissions === 'readwrite';
                }

                $scope.data.isEditingPermissions = false;
            }

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.setEditingPermissions = function (value) {
        $scope.data.isEditingPermissions = value;
    };

    $scope.moveToSelectFolder = function (isShared) {
        $scope.data.configStep = 'selectFolder';
        const folder = {
            displayName: isShared ? 'Shared With Me' : 'My Drive',
            value: isShared ? null : 'root',
            isShared, // hack: when getting autoComplete options can query on this field to know if this is MyDrive or Shared
        };

        // Get folders under root
        $scope.getChildFolders(folder, isShared);
    };

    $scope.onOpenClicked = function (folder) {
        // Save previous folder in history (unless its root)
        if ($scope.data.currentFolder) {
            $scope.data.folderNavigationHistory.push($scope.data.currentFolder);
        }

        return $scope.getChildFolders(folder);
    };

    $scope.getChildFolders = function (folder, queryOnRootShared) {
        $scope.data.folders = [];
        $scope.data.foldersLoading = true;

        // Set Current folder
        $scope.data.currentFolder = folder;

        const params = {};
        if (queryOnRootShared || ($scope.data.currentFolder && $scope.data.currentFolder.isShared)) {
            params.isShared = true;
        }

        params.parent = folder.value;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'googledrive', params)
            .then(function (data) {
                $scope.data.folders = data.options;
            })
            .finally(() => {
                $scope.data.foldersLoading = false;
            });
    };

    $scope.goToPreviousFolder = function () {
        if ($scope.data.folderNavigationHistory.length) {
            const targetFolder = $scope.data.folderNavigationHistory.pop();
            return $scope.getChildFolders(targetFolder);
        } else {
            $scope.data.configStep = 'selectDomain';
        }
    };

    $scope.onSearchChanged = function (queryString) {
        $scope.data.folderes = [];
        $scope.data.foldersLoading = true;
        const query = {};
        if (queryString && queryString.length) {
            query.name = queryString;
        } else {
            query.parent = 'root';
        }

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'googledrive', query)
            .then(function (data) {
                $scope.data.folders = data.options;
            })
            .finally(() => {
                $scope.data.foldersLoading = false;
            });
    };

    $scope.changeUser = function () {
        $scope.data.integration = null;
        $scope.data.folders = [];
        $scope.data.folderNavigationHistory = [];
        $scope.data.currentFolder = null;
        $scope.data.selectedFolder = null;
        $scope.data.configStep = 'selectDomain';
    };

    $scope.onFolderSelected = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        $scope.data.saving = true;

        const folderName = $scope.data.currentFolder ? $scope.data.currentFolder.displayName : 'My Drive';
        const projectIntegrationDisplayName = `Google Drive (${folderName} Folder)`;
        const twoWaySync = $scope.data.integrationPermissions === 'readwrite';

        const projectData = {
            folder: $scope.data.currentFolder,
            isUpdateable: twoWaySync,
            authenticationType: $scope.data.authenticationType,
            shouldIndexContent: $scope.data.shouldIndexContent,
        };

        $scope.data.integration.isUpdateable = twoWaySync;

        // NOTE: Since edit project integration is relying on the third parameter object to hold an array of projectDatas,
        // we inject it into the integration object, which does not have a project data property at all.
        $scope.data.integration.projectDatas = [projectData];

        let projectIntegrationPromise;
        $scope.data.integration.projectDatas = [projectData];
        $scope.data.integration.isUpdateable = twoWaySync;
        const oldProject = projectManager.project;
        if ($scope.data.isEdit) {
            projectIntegrationPromise = createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.integration);
        } else {
            projectIntegrationPromise = createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.data.integration,
                projectIntegrationDisplayName,
            );
        }

        projectIntegrationPromise
            .then((updatedProject) => {
                $scope.data.projectIntegration = utils.findFirst(
                    updatedProject.integrations,
                    (prin) =>
                        prin.id === $scope?.data?.projectIntegration?.id ||
                        !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
                );

                projectManager.getProjectData(true).then(() => {
                    $scope.data.saving = false;
                    if (ctrl.onProjectIntegrationCreated) {
                        ctrl.onProjectIntegrationCreated({
                            projectIntegration: $scope.data.projectIntegration,
                        });
                    }
                });
            })
            .catch((error) => {
                $scope.data.error = getStateError(error, {
                    fallbackErrorMessage: 'There was an error saving the data source',
                });
                $scope.data.saving = false;
            });
    };

    $scope.onCancel = function () {
        if (ctrl.onClosed) {
            ctrl.onClosed();
        }
    };

    /**
     * If component was given a project integration, will load the component's edit mode.
     */
    function initializeEditMode() {
        $scope.data.isEdit = true;

        if ($scope.data.projectIntegration.sharedCredentialId) {
            $scope.data.isSharedCredential = true;
        }

        const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];
        // Checks that the integration is well configured. It might be broken and need to be reconfigured (Example - when you create worker from template).
        if (!projectData || !projectData.folder) {
            return;
        }

        // Taking the authentication type from the project data (if present) or setting to PUBLIC by default.
        // PUBLIC == OAUTH
        $scope.data.authenticationType = projectData.authenticationType || 'PUBLIC';
        $scope.data.shouldIndexContent = projectData.shouldIndexContent || false;

        $scope.data.selectedFolder = projectData.folder;
        $scope.data.currentFolder = projectData.folder;
        $scope.data.integration = $scope.data.projectIntegration.integration;

        if ($scope.data.currentFolder) {
            $scope.data.configStep = 'selectFolder';
        }

        $scope.data.connecting = true;

        $scope.data.error = null;

        createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.projectIntegration.integration.id,
                'googledrive',
                {
                    parent: $scope.data.currentFolder ? $scope.data.currentFolder.value : 'root',
                },
            )
            .then(function (data) {
                $scope.data.folders = data.options;

                if (projectData.isUpdateable) {
                    $scope.data.integrationPermissions = 'readwrite';
                } else {
                    $scope.data.integrationPermissions = 'readonly';
                }
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    }
}

export default angular.module('tonkean.app').controller('GoogleDriveSelectorCtrl', lateConstructController(GoogleDriveSelectorCtrl));
