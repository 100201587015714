import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import SmartConversationResponseText from './SmartConversationResponseText';

import { AppearFromBottomAnimation } from '@tonkean/infrastructure';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import type {
    FormulaAIMessage,
    SmartConversationMessage,
    SmartConversationUserMessageContent,
} from '@tonkean/tonkean-entities';

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const UserAvatar = styled.div`
    position: relative;
    // In order to prevent long text overflowing the avatar icon
    min-width: 20px;
    height: 20px;
    margin-right: 9px;
`;

export interface Props {
    message: SmartConversationMessage<SmartConversationUserMessageContent | FormulaAIMessage>;
}

const SmartConversationUserReplyView: React.FC<Props> = ({ message }) => {
    const authenticationService = useAngularService('authenticationService');

    const currentUser = authenticationService.currentUser;
    return (
        <AppearFromBottomAnimation
            skipAnimation={!message.showAnimation}
            delay={0}
            onAnimationEnd={() => {
                message.showAnimation = false;
            }}
        >
            <Container>
                <UserAvatar>
                    <TonkeanAvatar owner={currentUser} />
                </UserAvatar>
                <SmartConversationResponseText>{message.response.text}</SmartConversationResponseText>
            </Container>
        </AppearFromBottomAnimation>
    );
};

export default SmartConversationUserReplyView;
