export function getMatchConfigurationAccordingToSourceIntegration(
    sourceIntegration,
    selectedEntity,
    customTriggerId,
    performOnWorkerItem,
    idRelationFieldDefinitionId = null,
) {
    const lowerCaseIntegrationEntity = sourceIntegration?.entity ? sourceIntegration.entity : '';
    const lowerCaseSelectedEntity = selectedEntity?.entity ? selectedEntity.entity : selectedEntity || '';

    if (
        !!lowerCaseIntegrationEntity &&
        lowerCaseSelectedEntity.toLowerCase() === lowerCaseIntegrationEntity.toLowerCase()
    ) {
        return {
            matchOptionApiName: 'SPECIFIC_ITEM',
            performOnWorkerItem,
            creatingCustomTriggerId: customTriggerId || null,
            idRelationFieldDefinitionId,
            validMatchSelection: true,
        };
    }

    return {
        matchOptionApiName: 'TONKEAN_SMART_MATCHING',
        validMatchSelection: true,
    };
}
