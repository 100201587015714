<div class="workflow-match-by-field">
    <!-- Entity match field selection -->
    <div class="margin-bottom-xs flex-grow">
        <!-- Title -->
        <span class="margin-right-xs">Match {{ data.entityMetadata.displayName }} field:</span>

        <!-- Selecting a field to match on the entity side -->
        <tnk-field-selector
            selected-field="data.entityMatchField"
            items-source="EXTERNAL"
            project-integration="data.projectIntegration"
            external-type="data.entityMetadata.entity || data.entityMetadata"
            on-field-selected="onEntityMatchFieldSelected(selectedField, dontSaveChanges)"
            is-required="true"
            place-holder="Select a data source field"
            field-inspect-modal="true"
            field-inspect-auto-open="true"
            workflow-version-id="{{ data.workflowVersionId }}"
            view-only="!data.projectIntegration.valid || data.viewOnly"
            tooltip-value="data.projectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
        ></tnk-field-selector>
    </div>

    <!-- Worker item field selection -->
    <div class="flex-grow">
        <!-- Title -->
        <span>With the field:</span>

        <!-- Worker item match field selector -->
        <tnk-field-selector
            class="filter-min-width margin-bottom"
            selected-field="data.workerItemMatchField"
            items-source="COLUMN"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            on-field-selected="onWorkerItemMatchFieldSelected(selectedField, selectedFieldIdentifier)"
            place-holder="Search..."
            view-only="data.viewOnly"
        ></tnk-field-selector>
    </div>
</div>
