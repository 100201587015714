import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { AutocompleteSelectorComponentType } from '@tonkean/infrastructure';
import {
    AutocompleteSelector,
    ErrorMessage,
    H3,
    H4,
    Paragraph,
    Placeholder,
    SearchBox,
    Table,
    useDebouncedState,
} from '@tonkean/infrastructure';
import type { ExternalActivity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const ExampleText = styled(H4)`
    margin-bottom: 8px;
    display: flex;
    padding-top: 16px;
`;

const TableWrapper = styled.div`
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 0;
    height: 0;
`;

const TableRow = styled.tr`
    word-break: break-all;
`;

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
`;

const StyledAutocompleteSelector = styled(AutocompleteSelector)`
    margin-bottom: 12px;
` as AutocompleteSelectorComponentType;

const StyledSearchBox = styled(SearchBox)`
    margin-bottom: 16px;
`;

const EachPageSummaryText = styled(Paragraph)`
    font-weight: 400;
    margin-bottom: 4px;
    color: ${Theme.colors.gray_700};
`;

const AllPagesSummaryText = styled(H4)`
    margin-bottom: 4px;
    padding-top: 8px;
`;

interface Props {
    entity: ProjectIntegrationEntity;
    getExternalActivitiesAutoComplete: (debounced: string) => Promise<ExternalActivity[]>;
    error: unknown;
    isLoadingSummaries: boolean | undefined;
    entitiesPerPage?: number[];
    totalEntities: number | undefined;
    numOfPages?: number;
    isUpdatedEverySave?: boolean;
}

const ProjectIntegrationEntityHandleResponseView: React.FC<Props> = ({
    entity,
    error,
    isLoadingSummaries,
    entitiesPerPage,
    totalEntities,
    numOfPages,
    getExternalActivitiesAutoComplete,
    isUpdatedEverySave,
}) => {
    // The debounced search term.
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

    // Debounced text for searching in current external activity fields.
    const [searchTerm, setSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 200);

    // Selected External Activity
    const [selectedExternalActivity, setSelectedExternalActivity] = useState<ExternalActivity>();

    useEffect(() => {
        setSelectedExternalActivity(undefined);
    }, [entity]);

    // Parsed external Activity for table view
    const values: { name: string; value: string }[] = useMemo(() => {
        if (!selectedExternalActivity) {
            return [];
        }

        const originalFieldsValues = selectedExternalActivity.originalFieldsValues.map((originalFieldValue) => ({
            name: originalFieldValue.fieldName,
            value: originalFieldValue.strValue.join(','),
        }));
        const realEntityId = { name: 'Real Entity Id', value: selectedExternalActivity.realEntityId };

        return [realEntityId, ...originalFieldsValues];
    }, [selectedExternalActivity]);

    // Filtered values by the search term.
    const filteredValues = useMemo(() => {
        return values.filter(
            (row) =>
                row.name.toLowerCase().trim().includes(debouncedSearchTerm.toLowerCase().trim()) ||
                row.value.toString().toLowerCase().trim().includes(debouncedSearchTerm.toLowerCase().trim()),
        );
    }, [debouncedSearchTerm, values]);

    // Should hide table.
    const shouldHideTable = !selectedExternalActivity || !values || values.length === 0;

    const emptyStates = useMemo(() => {
        if (!selectedExternalActivity) {
            return <H3>Please Select External Activity</H3>;
        } else if (values.length === 0) {
            return <H3>No Fields Found</H3>;
        } else {
            return <></>;
        }
    }, [selectedExternalActivity, values.length]);

    // Table columns configurations.
    const columns = useMemo(
        () => [
            {
                Header: 'Field Name',
                accessor: 'name' as const,
                width: '50%',
            },
            {
                Header: 'Value',
                accessor: 'value' as const,
                width: '50%',
            },
        ],
        [],
    );

    return (
        <Wrapper>
            <AllPagesSummaryText $bold>
                {!!totalEntities && (
                    <>
                        Total External Activities: {totalEntities} {numOfPages && `In ${numOfPages} Pages`}
                        {isUpdatedEverySave && (
                            <Paragraph $inline $light>
                                {' '}
                                (updates every save)
                            </Paragraph>
                        )}
                    </>
                )}
                {isLoadingSummaries && <Placeholder $height="16px" $width="400px" />}
            </AllPagesSummaryText>

            {entitiesPerPage?.map((count, index) => (
                <EachPageSummaryText key={index}>
                    Page {index + 1} - {count} items
                </EachPageSummaryText>
            ))}

            {isLoadingSummaries &&
                range(3).map((i) => (
                    <Placeholder style={{ marginBottom: '4px' }} key={i} $height="14px" $width="150px" />
                ))}

            {!!error && <ErrorMessage>Error Loading Summaries</ErrorMessage>}

            <ExampleText $light $bold>
                Example {entity.displayName}
            </ExampleText>

            <StyledAutocompleteSelector
                onDebouncedSearchGetResults={getExternalActivitiesAutoComplete}
                refreshObject={entity}
                valueKey="realEntityId"
                labelKey="title"
                onChange={(externalActivity) => {
                    setSelectedExternalActivity(externalActivity ?? undefined);
                }}
                thin
                isCreatable
            />

            {!shouldHideTable && (
                <>
                    <StyledSearchBox
                        onChange={({ target: { value } }) => setSearchTerm(value)}
                        value={searchTerm}
                        placeholder="Search field names or values"
                    />

                    <TableWrapper>
                        <Table
                            tableRowComponent={TableRow}
                            columns={columns}
                            data={filteredValues}
                            stickyHeader
                            border
                        />
                    </TableWrapper>
                </>
            )}

            {shouldHideTable && <>{emptyStates}</>}
        </Wrapper>
    );
};
export default ProjectIntegrationEntityHandleResponseView;
