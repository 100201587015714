import React from 'react';
import styled, { css } from 'styled-components';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

const StyledPlaceholder = styled(Placeholder)<{ $largeRow?: boolean }>`
    ${({ $largeRow = false }) =>
        $largeRow &&
        css`
            margin-bottom: 16px;
        `};
`;

const Wrapper = styled(PlaceholderGrid)`
    position: absolute;
    width: 100%;
    padding: 14px 6px;
    left: 0;
    top: 0;
`;

const OPTIONS_TAB_WIDTH = '20px';

class Props {
    columnsAmount?: number;
    rowsAmount?: number;
    shouldShowOptionsTab?: boolean;
}

const CustomLoadingOverlay: React.FC<Props> = ({ columnsAmount = 6, rowsAmount = 8, shouldShowOptionsTab = false }) => {
    return (
        <Wrapper
            gap="20px"
            direction={PlaceholderGridDirection.COLUMNS}
            template={
                shouldShowOptionsTab
                    ? `${OPTIONS_TAB_WIDTH} repeat(${columnsAmount}, 1fr)`
                    : `repeat(${columnsAmount}, 1fr)`
            }
        >
            <>
                <LoadingRow columnsAmount={columnsAmount} shouldShowOptionsTab={shouldShowOptionsTab} largeRow />

                {range(rowsAmount).map((rowNum) => (
                    <LoadingRow
                        key={rowNum}
                        columnsAmount={columnsAmount}
                        shouldShowOptionsTab={shouldShowOptionsTab}
                    />
                ))}
            </>
        </Wrapper>
    );
};

interface InnerLoadingProps {
    columnsAmount: number;
    shouldShowOptionsTab: boolean;
    largeRow?: boolean;
}

const LoadingRow: React.FC<InnerLoadingProps> = ({ columnsAmount, shouldShowOptionsTab, largeRow = false }) => {
    const PLACE_HOLDER_HEIGHT = '20px';

    return (
        <>
            {shouldShowOptionsTab && (
                <StyledPlaceholder $height={PLACE_HOLDER_HEIGHT} $width={OPTIONS_TAB_WIDTH} $largeRow={largeRow} />
            )}

            {range(columnsAmount).map((colNum) => (
                <StyledPlaceholder
                    key={colNum}
                    $borderRadius="4px"
                    $height={PLACE_HOLDER_HEIGHT}
                    $largeRow={largeRow}
                    $width="auto"
                />
            ))}
        </>
    );
};

export default CustomLoadingOverlay;
