import type { Features } from 'angular';

import EnterpriseComponentPageAuditLog from '../components/AuditLogPage/components/EnterpriseComponentPageAuditLog/components/EnterpriseComponentPageAuditLog';
import EnterpriseComponentOverview from '../components/EnterpriseComponentOverview/EnterpriseComponentOverview';
import EnterpriseComponentPageAdmins from '../components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/EnterpriseComponentPageAdmins';
import EnterpriseComponentPageInformationPage from '../components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/EnterpriseComponentPageInformationPage';
import EnterpriseComponentSolutionsAccessContent from '../components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/EnterpriseComponentSolutionsAccessContent';
import type { EnterpriseComponentPageMenuSectionConfiguration } from '../entities/EnterpriseComponentPageMenuConfiguration';

import { PersonPermissionRoleType } from '@tonkean/tonkean-entities';

// The ids are reflected to the url, and that is why they are using dashes and lowercase
export enum EnterpriseComponentGeneralSectionIds {
    INFORMATION = 'information',
    SOLUTIONS_ACCESS = 'solution-access',
    ADMINS = 'admins',
    AUDIT_LOG = 'audit-log',
    OVERVIEW = 'overview',
}

const getEnterpriseComponentGeneralSection = (
    currentProjectFeatures: Features['currentProject'],
): EnterpriseComponentPageMenuSectionConfiguration<any, any> => ({
    id: 'general',
    title: 'General',
    menuItems: [
        {
            id: EnterpriseComponentGeneralSectionIds.OVERVIEW,
            title: 'Overview',
            hideItem: false,
            permissionRoles: [PersonPermissionRoleType.ADMIN],
            component: EnterpriseComponentOverview,
        },
        {
            id: EnterpriseComponentGeneralSectionIds.INFORMATION,
            title: 'Information',
            hideItem: false,
            permissionRoles: [PersonPermissionRoleType.ADMIN, PersonPermissionRoleType.MAKER],
            component: EnterpriseComponentPageInformationPage,
        },
        {
            id: EnterpriseComponentGeneralSectionIds.SOLUTIONS_ACCESS,
            title: 'Solution Access',
            hideItem: false,
            permissionRoles: [PersonPermissionRoleType.ADMIN],
            component: EnterpriseComponentSolutionsAccessContent,
            stepStatusKey: 'allowedSolutionsStepStatus',
        },
        {
            id: EnterpriseComponentGeneralSectionIds.ADMINS,
            title: 'Admins',
            hideItem: false,
            permissionRoles: [PersonPermissionRoleType.ADMIN],
            component: EnterpriseComponentPageAdmins,
            stepStatusKey: 'adminsStepStatus',
        },
        {
            id: EnterpriseComponentGeneralSectionIds.AUDIT_LOG,
            title: 'Audit Log',
            hideItem: false,
            permissionRoles: [PersonPermissionRoleType.ADMIN],
            component: EnterpriseComponentPageAuditLog,
        },
    ],
});

export default getEnterpriseComponentGeneralSection;
