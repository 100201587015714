import React from 'react';

import SingleWorkerRunInformationItem from './SingleWorkerRunInformationItem';
import SingleWorkerRunWorkflowVersionPopover from './SingleWorkerRunWorkflowVersionPopover';

import type { WorkerRun } from '@tonkean/tonkean-entities';

const SingleWorkerRunWorkflowVersionInformationItem: React.FC<{ workerRun?: WorkerRun }> = ({ workerRun }) => {
    // If it's loaded and doesn't have a workflow version id, we should hide the information item.
    const workerRunLoadingOrHaveWorkflowVersionId = !workerRun || workerRun.workflowVersionId;

    return (
        <>
            {workerRunLoadingOrHaveWorkflowVersionId && (
                <SingleWorkerRunInformationItem
                    title="On Version"
                    value={
                        workerRun?.workflowVersionId && (
                            <SingleWorkerRunWorkflowVersionPopover workflowVersionId={workerRun.workflowVersionId} />
                        )
                    }
                />
            )}
        </>
    );
};

export default SingleWorkerRunWorkflowVersionInformationItem;
