import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../EnterpriseComponentsModule/entities/EnterpriseComponentExplorerListConfiguration';
import type ProjectIntegrationPageExplorerSection from '../entities/ProjectIntegrationPageExplorerSection';

export function getEnterpriseComponentExplorerSectionsFromProjectIntegrationExplorerSections(
    obj: ProjectIntegrationPageExplorerSection[],
): EnterpriseComponentExplorerListSectionConfiguration[] {
    return obj.map((section) => ({
        id: section.id,
        title: section.title || '',
        loading: section.loading,
        itemIds: section.items.map(({ id }) => id),
    }));
}
