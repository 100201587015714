import uniq from 'lodash.uniq';
import { useMemo } from 'react';

import type { LineItemAggregation } from '../../entities';
import { LineItemWidgetAggregationType } from '../../entities';
import type CalculationResult from '../entities/CalculationResult';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

export type AggregationThatRequireCalculation = LineItemAggregation & {
    aggregation: Exclude<LineItemWidgetAggregationType, LineItemWidgetAggregationType.NONE>;
};

const useCalculateAggregations = (
    itemsValues: Record<FieldDefinitionKey, string | number | Date | undefined>[],
    aggregations: LineItemAggregation[],
) => {
    return useMemo(() => {
        const calculationResults: CalculationResult[] = aggregations
            .filter(
                (agg): agg is AggregationThatRequireCalculation =>
                    agg.aggregation !== LineItemWidgetAggregationType.NONE,
            )
            // adding this eslint disable inorder to make sure all types of LineItemWidgetAggregationType is supported - so when adding new line type, the map will return compile time error
            // eslint-disable-next-line array-callback-return
            .map((aggregation) => {
                const _values = itemsValues.map((fieldIdToValue) => fieldIdToValue[aggregation.fieldDefinitionKey]);

                const numericValues = _values
                    .map((value) => Number(value))
                    // For now, we filter out NaN values
                    .filter((value) => !Number.isNaN(value));

                const stringValues = _values
                    .map((value) => value?.toString())
                    // For now, we filter out empty values
                    .filter((value) => !!value && value.trim() !== '');

                const temporalValues = _values
                    .map((value) => (!!value ? new Date(value).getTime() : null))
                    .filter((value): value is number => !Number.isNaN(value) && value !== null);

                switch (aggregation.aggregation) {
                    case LineItemWidgetAggregationType.AVERAGE: {
                        return [
                            aggregation,
                            numericValues.length === 0
                                ? 0
                                : numericValues.reduce(
                                      (previousValue, currentValue) => previousValue + currentValue,
                                      0,
                                  ) / numericValues.length,
                        ] as const;
                    }
                    case LineItemWidgetAggregationType.MAX:
                        return [aggregation, Math.max(...numericValues)] as const;

                    case LineItemWidgetAggregationType.MIN:
                        return [aggregation, Math.min(...numericValues)] as const;

                    case LineItemWidgetAggregationType.MAX_DATE:
                        const maxDate = new Date(Math.max(...temporalValues));
                        const originalMaxDate =
                            _values.find((value) => !!value && new Date(value).getTime() === maxDate.getTime()) ||
                            Math.max();
                        return [aggregation, originalMaxDate] as const;

                    case LineItemWidgetAggregationType.MIN_DATE:
                        const minDate = new Date(Math.min(...temporalValues));
                        const originalMinDate =
                            _values.find((value) => !!value && new Date(value).getTime() === minDate.getTime()) ||
                            Math.min();
                        return [aggregation, originalMinDate] as const;

                    case LineItemWidgetAggregationType.COUNT: {
                        return [aggregation, numericValues.length] as const;
                    }

                    case LineItemWidgetAggregationType.SUM: {
                        return [
                            aggregation,
                            numericValues.reduce((previousValue, currentValue) => {
                                return previousValue + currentValue;
                            }, 0),
                        ] as const;
                    }

                    case LineItemWidgetAggregationType.CONCAT: {
                        return [aggregation, stringValues.join(',')] as const;
                    }

                    case LineItemWidgetAggregationType.UNIQUE_CONCAT: {
                        return [aggregation, uniq(stringValues).join(',')] as const;
                    }
                }
            });

        return calculationResults;
    }, [aggregations, itemsValues]);
};

export default useCalculateAggregations;
