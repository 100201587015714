import { reactToAngular } from 'angulareact';

import CustomItemMatchFieldsMetadataTable from './CustomItemMatchFieldsMetadataTable';

export default angular
    .module('tonkean.app')
    .component(
        'tnkCustomItemMatchFieldsMetadataTable',
        reactToAngular(CustomItemMatchFieldsMetadataTable, [
            'fieldDefinition',
            'onFieldSelected',
            'groupId',
            'workflowVersionId',
            'reloadFields',
            'addFieldByRelatedEntity',
            'allFieldDefinitions',
            'applyFieldDependenciesFilter',
            'applyTriggerDependenciesFilter',
            'deleteFieldDefinition',
            'fieldFilterByName',
        ]),
    );
