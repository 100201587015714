import SolutionSiteFieldChartWidget from './SolutionSiteFieldChartWidget';
import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';
import SolutionSiteFieldChartWidgetEditor from './SolutionSiteFieldChartWidgetEditor';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSiteFieldChartWidgetPackage: SolutionSitePageWidgetPackage<SolutionSiteFieldChartWidgetConfiguration> = {
    displayComponent: SolutionSiteFieldChartWidget,
    configurationComponent: SolutionSiteFieldChartWidgetEditor,
};

export default SolutionSiteFieldChartWidgetPackage;
