import React from 'react';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

export interface ExpressionEditorContextValue {
    groupId?: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId?: string;
    customTriggerId?: string;
    additionalTabs?: TonkeanExpressionAdditionalTab[];

    translateVariableLabel(variableId: string): string;

    getVariableIconClassName?(variableId: string, variableLabel: string): string | undefined;
    getProjectIntegrationTypeAndId?(variableId: string): { integrationType?: string; projectIntegrationId?: string };
}

const ExpressionEditorContext = React.createContext<ExpressionEditorContextValue>({} as any);

export default ExpressionEditorContext;
