/**
 * Created by ShaLi on 20/01/2016.
 */

export function ZendeskModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.data = {
        isCreate: true,
        isSharableIntegration: false,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration || settings.integration;
        $scope.selected = state.selected || {};

        if (settings.projectIntegration) {
            if (settings.projectIntegration.sharedCredentialId) {
                $scope.data.isSharableIntegration = true;
            }

            if (!$scope.integration && settings.projectIntegration.integration) {
                $scope.integration = settings.projectIntegration.integration;
            }
        }

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function (config) {
        createProjectApis
            .createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                config,
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'ZENDESK',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            )
            .then(function (integObj) {
                $scope.integration = integObj;
                $scope.data.isCreate = false;
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    };

    $scope.getTags = function (query) {
        $scope.loadingTags = true;
        const params = query ? { q: query } : null;
        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'tags', params)
            .then(function (tags) {
                return tags.options;
            })
            .finally(function () {
                $scope.loadingTags = false;
            });
    };

    $scope.changeUser = function () {
        $scope.data.isCreate = true;
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        s.integrations = [
            {
                integration: $scope.integration,
                projectData: { tags: [] },
            },
        ];
        if ($scope.selected.tags && $scope.selected.tags.length) {
            s.integrations[0].projectData.tags = $scope.selected.tags.map(function (c) {
                return c.value;
            });
        }
        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && state.integrations[0] && state.integrations[0].integration) {
            $scope.data.isCreate = false;
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                const projectIntegration = state.integrations[0];
                // If the project integration is using a shared credential we must hide the 'switch account' button.
                if (projectIntegration.sharedCredentialId) {
                    $scope.data.isSharableIntegration = true;
                }
                $scope.integration = state.integrations[0].integration;

                if (
                    state.integrations[0].projectData &&
                    state.integrations[0].projectData.projectDatas &&
                    state.integrations[0].projectData.projectDatas.length === 1 &&
                    state.integrations[0].projectData.projectDatas[0] &&
                    state.integrations[0].projectData.projectDatas[0].tags
                ) {
                    $scope.selected.tags = state.integrations[0].projectData.projectDatas[0].tags.map(function (tag) {
                        return {
                            value: tag,
                            displayName: tag,
                        };
                    });
                }
            }
        } else {
            $scope.data.isCreate = true;
            if ($scope.integration) {
                // If there is no project integration it is means that we are in creation mode / using shared credential.
                $scope.data.isSharableIntegration = true;
            }
        }
    }
}
export default angular.module('tonkean.shared').controller('ZendeskModalCtrl', ZendeskModalCtrl);
