import React from 'react';
import styled from 'styled-components';

import WorkerRunsTableItemGrid from './WorkerRunsTableItemGrid';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';

export const TableItem = styled.div`
    padding: 12px 0;

    &:first-child {
        padding-left: 20px;
    }
    &:last-child {
        padding-right: 20px;
    }
`;

const TableItemsGrid = styled(WorkerRunsTableItemGrid)`
    height: 60px;
`;

const WorkerRunsTableLoadingItem: React.FC = ({}) => {
    return (
        <TableItemsGrid>
            <TableItem>
                <PlaceholderGrid direction={PlaceholderGridDirection.ROWS}>
                    <Placeholder $width="70%" />
                    <Placeholder $width="90%" />
                </PlaceholderGrid>
            </TableItem>
            <TableItem>
                <PlaceholderGrid direction={PlaceholderGridDirection.COLUMNS} template="16px 1fr">
                    <Placeholder $width="16px" $circle />
                    <Placeholder $width="90%" />
                </PlaceholderGrid>
            </TableItem>
            <TableItem>
                <PlaceholderGrid direction={PlaceholderGridDirection.ROWS}>
                    <Placeholder $width="90%" />
                    <Placeholder $width="50%" />
                </PlaceholderGrid>
            </TableItem>
            <TableItem>
                <Placeholder $width="50%" />
            </TableItem>
        </TableItemsGrid>
    );
};

export default WorkerRunsTableLoadingItem;
