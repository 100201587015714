<div class="margin-left margin-right margin-top">
    <div ng-if="data.definition.customActionKey === 'RENAME_FILE'">
        <div class="margin-bottom-xs">New File/Folder Name:</div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The new file/folder name..."
            saved-expression="data.definition.itemNameExpression"
            expression-unique-identifier="SharePointItemName"
            on-tonkean-expression-changed="onItemNameChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
    </div>

    <div ng-if="data.definition.customActionKey === 'MOVE_FILE'">
        <div class="margin-bottom-xs">Destination Folder (ID):</div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The folder id to move to..."
            saved-expression="data.definition.folderIdExpression"
            expression-unique-identifier="SharePointFolderId"
            on-tonkean-expression-changed="onFolderIdChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
    </div>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            preview-evaluation-source="data.previewEvaluationSource"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- New Document Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New Document Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Document name"
                saved-original-expression="data.definition.newDocumentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CREATE_FILE'" class="margin-bottom-xlg">
        <!-- New Document Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New File Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="File name must have the extension .txt"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File name"
                saved-original-expression="data.definition.newDocumentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.newDocumentNameError" class="common-color-danger">
            {{ data.validationObject.newDocumentNameError }}
        </div>

        <!-- New Document Content -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                File Content:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Text content of the file."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File Content"
                mod-text-area="true"
                saved-original-expression="data.definition.newDocumentContentExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentContentExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentContentTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic
                    )
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.newDocumentContentError" class="common-color-danger">
            {{ data.validationObject.newDocumentContentError }}
        </div>

        <div>
            <tnk-checkbox
                class="margin-top-xs"
                label="'Save as PDF'"
                value="data.definition.saveAsPdf"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>

        <div ng-hide="!!!data.definition.saveAsPdf">
            <tnk-checkbox
                class="margin-top-xs"
                label="'Convert from HTML'"
                value="data.definition.convertHtmlToPdf"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'MERGE_PDFS'" class="margin-bottom-xlg">
        <!-- 1st Source PDF -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Source PDF:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Source file ID"
                saved-original-expression="data.definition.sourceFirstPdfExpression.originalExpression"
                saved-evaluated-expression="data.definition.sourceFirstPdfExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onSourceFirstPdfExpressionTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic
                    )
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- 2nd Source PDF -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                List of PDFs:
                <i class="fa fa-info-circle common-color-primary margin-left-xs" uib-tooltip="Separated by commas."></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="List of PDF IDs"
                saved-original-expression="data.definition.sourceSecondPdfExpression.originalExpression"
                saved-evaluated-expression="data.definition.sourceSecondPdfExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onSourceSecondPdfExpressionTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic
                    )
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.sourcePdfExpression" class="common-color-danger">
            {{ data.validationObject.sourcePdfExpression }}
        </div>

        <!-- New Document Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">New File Name:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File name"
                saved-original-expression="data.definition.newDocumentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.newDocumentNameError" class="common-color-danger">
            {{ data.validationObject.newDocumentNameError }}
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'CREATE_SHARED_LINK' ||
            data.definition.customActionKey === 'ADD_PERMISSIONS_TO_ITEM' ||
            data.definition.customActionKey === 'CREATE_PREVIEW_LINK'
        "
        class="margin-bottom-xlg"
    >
        <!-- File/Folder ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">File {{data.definition.customActionKey === 'CREATE_PREVIEW_LINK' ? '' : '/Folder'}} ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Drive Item ID"
                saved-expression="data.definition.driveItemIdExpression"
                on-tonkean-expression-changed="onDriveItemIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CREATE_SHARED_LINK'" class="margin-bottom-xlg">
        <!-- Share Type -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Share Type:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The type of sharing link to create. Either 'view', 'edit', or 'embed'."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type"
                saved-expression="data.definition.shareTypeExpression"
                on-tonkean-expression-changed="onShareTypeExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Scope -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Share Scope:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Optional. The scope of link to create. Either 'anonymous', 'organization', or 'users'."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Scope"
                saved-expression="data.definition.shareScopeExpression"
                on-tonkean-expression-changed="onShareScopeExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'GRANT_ACCESS_TO_SHARED_LINK'" class="margin-bottom-xlg">
        <!-- Shared URL -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Sharing URL:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Sharing URL"
                saved-expression="data.definition.sharedUrlExpression"
                on-tonkean-expression-changed="onSharedUrlExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'GRANT_ACCESS_TO_SHARED_LINK' ||
            data.definition.customActionKey === 'ADD_PERMISSIONS_TO_ITEM'
        "
        class="margin-bottom-xlg"
    >
        <!-- Alias Recipients -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Alias Recipients:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Comma-separated aliases."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Alias Recipients"
                saved-expression="data.definition.aliasRecipientsExpression"
                on-tonkean-expression-changed="onAliasRecipientsExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Email Recipients -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Email Recipients:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Comma-separated emails."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Email Recipients"
                saved-expression="data.definition.emailRecipientsExpression"
                on-tonkean-expression-changed="onEmailRecipientsExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- ID Recipients -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                ID Recipients:
                <i class="fa fa-info-circle common-color-primary margin-left-xs" uib-tooltip="Comma-separated IDs."></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="ID Recipients"
                saved-expression="data.definition.idRecipientsExpression"
                on-tonkean-expression-changed="onIdRecipientsExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Roles -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Roles:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Comma-separated roles. Options: 'read', 'write'."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Roles"
                saved-expression="data.definition.rolesExpression"
                on-tonkean-expression-changed="onRolesExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_PERMISSIONS_TO_ITEM'" class="margin-bottom-xlg">
        <!-- Expiration Date Time -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Expiration Date:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Format: yyyy-MM-ddTHH:mm:ssZ"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Expiration Date"
                saved-expression="data.definition.expirationDateTimeExpression"
                on-tonkean-expression-changed="onExpirationDateTimeExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Require SignIn -->
        <div>
            <tnk-checkbox
                class="margin-top-xs"
                label="'Require Sign-In'"
                value="data.definition.requireSignIn"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>

        <!-- Send Invitation -->
        <div>
            <tnk-checkbox
                class="margin-top-xs"
                label="'Send Invitation'"
                value="data.definition.sendInvitation"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>

        <!-- Message -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Message:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Message"
                saved-expression="data.definition.inviteMessageExpression"
                on-tonkean-expression-changed="onInviteMessageExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Create Doc From Template -->
    <div ng-if="data.definition.customActionKey === 'CREATE_DOC_FROM_TEMPLATE'">
        <!-- Template -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Template Doc ID:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The file to take as template"
                    saved-expression="data.definition.templateIdExpression"
                    expression-unique-identifier="SharePointTemplateID"
                    on-tonkean-expression-changed="onTemplateIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                    ng-if="data.validationObject.docFromTemplateIdDataError"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.docFromTemplateIdDataError }}
            </div>
        </div>

        <!-- Target -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Target Folder ID:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The new file target folder"
                    saved-expression="data.definition.targetIdExpression"
                    expression-unique-identifier="SharePointTargetID"
                    on-tonkean-expression-changed="onTargetIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                    ng-if="data.validationObject.docFromTemplateTargetDataError"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.docFromTemplateTargetDataError }}
            </div>
        </div>

        <!-- Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">New File Name:</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="The name of the new file"
                    saved-expression="data.definition.newNameExpression"
                    expression-unique-identifier="SharePointNewName"
                    on-tonkean-expression-changed="onNewNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                    ng-if="data.validationObject.docFromTemplateNewNameDataError"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.docFromTemplateNewNameDataError }}
            </div>
        </div>

        <div class="margin-bottom">
            <div ng-if="data.definition.replacements.length > 0" class="margin-right margin-top-xs margin-bottom">
                Replace Placeholders ({{ data.definition.replacements.length }}):
            </div>

            <div
                    ng-repeat="(replacementIndex, replacement) in data.definition.replacements"
                    class="flex"
            >
                <div class="margin-left-xs margin-right-xs margin-top">{{ replacementIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Placeholder -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Placeholder:</div>

                            <div class="flex-grow"></div>

                            <button
                                    class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                    ng-click="removeReplacement(replacementIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.replacements[replacementIndex].placeholderExpression"
                                on-tonkean-expression-changed="
                                onReplacementPlaceholderTonkeanExpressionChanged(
                                    expression,
                                    replacementIndex,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Text -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Replace With:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.replacements[replacementIndex].textExpression"
                                on-tonkean-expression-changed="
                                onReplacementTextTonkeanExpressionChanged(
                                    expression,
                                    replacementIndex,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addReplacement()">+ Add Replacement</a>
            <div ng-if="data.validationObject.replacementsDataError" class="common-color-danger">
                {{ data.validationObject.replacementsDataError }}
            </div>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CREATE_PREVIEW_LINK'" class="margin-bottom-xlg">
        <!-- Preview Page -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Page Number:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Optional. Page number of document to start at"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Preview Page"
                    saved-expression="data.definition.previewPageExpression"
                    on-tonkean-expression-changed="onPreviewPageExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Preview Zoom -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Preview Zoom:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Optional. Zoom level to start at. Default: 1.0"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Preview Zoom"
                    saved-expression="data.definition.previewZoomExpression"
                    on-tonkean-expression-changed="onPreviewZoomExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
