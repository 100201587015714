import TrainTrainingSetTriggerLogicConfiguration from './TrainTrainingSetTriggerLogicConfiguration';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTrainTrainingSetTriggerLogicConfiguration',
        reactToAngular(TrainTrainingSetTriggerLogicConfiguration, [
            'groupId',
            'workflowVersionId',
            'configuredLogic',
            'previewEvaluationSource',
            'onTrainingSetTrainTriggerConfiguration',
            'validationObject',
        ]),
    );
