import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class MonthFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.MONTH;
    override readonly sign = 'Month';
    override readonly displayName = 'Month';
    override readonly description = 'Will get the month number of the given date in a specific timezone.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Date Field',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        timeZoneField(),
    ];
}
