import type { IRootScopeService } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import EnvironmentActivateConfirmationModal from './EnvironmentConfirmationModal/EnvironmentActivateConfirmationModal';
import EnvironmentDeactivateConfirmationModal from './EnvironmentConfirmationModal/EnvironmentDeactivateConfirmationModal';
import useEnvironmentFlags from '../../../infrastructure/hooks/useEnvironmentFlags';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { EnvironmentIndicator, Toggle, Tooltip } from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { BackgroundProcessStatus, BackgroundProcessType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const EnvironmentSection = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 6px;
    align-items: center;
`;

const BubbleSection = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: absolute;
    color: #ffffff;

    padding: 4px 10px;

    background: #34393e;
    border-radius: 3px;
`;

const ToggleWrapper = styled.div`
    display: flex;
`;

const defaultDisabledEnvironments: { production: string | boolean; build: string | boolean } = {
    production: false,
    build: false,
};

interface Props {
    onEnvironmentChange: (newEnvironment: Environment) => void;
    onActiveChange?: (newActiveStatus: boolean) => void;
    environmentIsActive: boolean;
    currentEnvironment: Environment;
    group: Group;
}

const EnvironmentExplorer: React.FC<Props> = ({
    currentEnvironment,
    onEnvironmentChange,
    environmentIsActive,
    group,
}) => {
    const [
        { loading: getBackgroundProcessesByWorkflowVersionTypeLoading },
        getBackgroundProcessesByWorkflowVersionType,
    ] = useLazyTonkeanService('getBackgroundProcessesByWorkflowVersionType');

    const projectManager = useAngularService('projectManager');
    const groupManager = useAngularService('groupManager');
    const groupPermissions = useAngularService('groupPermissions');
    const { isDraft, isPublished } = useEnvironmentFlags();

    const [isActive, setIsActive] = useState<boolean>(false);
    const [isArchiving, setArchiving] = useState<boolean>(false);
    const [isActivationModalOpen, setIsActivationModalOpen] = useState<boolean>(false);
    const [isDeactivateOpen, setIsDeactivateOpen] = useState<boolean>(false);
    const $rootScope = useAngularService('$rootScope') as IRootScopeService & Record<any, any>;

    const hasPermissions = groupPermissions.hasPermissionsToEditWorker(projectManager.groupsMap[group.id]);
    const disabledEnvironments = {
        ...defaultDisabledEnvironments,
        build: !hasPermissions ? 'Build environment is only available to module makers' : false,
    };

    useEffect(() => {
        getBackgroundProcessesByWorkflowVersionType(
            group.id,
            currentEnvironment === 'production' ? WorkflowVersionType.PUBLISHED : WorkflowVersionType.DRAFT,
            undefined,
            BackgroundProcessType.ARCHIVE_INITIATIVES_AND_TURN_ON_MODULE_VERSION,
            [
                BackgroundProcessStatus.EXECUTING,
                BackgroundProcessStatus.INITIALIZING,
                BackgroundProcessStatus.INITIALIZED,
                BackgroundProcessStatus.CREATED,
            ],
            0,
            1,
        ).then((response) => {
            setArchiving(response.entities.length > 0);
        });
    }, [group, group.id, currentEnvironment, getBackgroundProcessesByWorkflowVersionType]);

    const [{ data: groups }, getGroupCustomTriggerCreateTrackActions] = useLazyAsyncMethod(
        groupManager,
        'getGroupCustomTriggerCreateTrackActions',
    );

    useEffect(() => {
        setIsActive(environmentIsActive);
    }, [environmentIsActive]);

    const onActiveChange = useCallback(
        (newActiveState) => {
            if (newActiveState) {
                setIsActivationModalOpen(true);
            } else {
                getGroupCustomTriggerCreateTrackActions(
                    group.id,
                    currentEnvironment === 'production' ? WorkflowVersionType.PUBLISHED : WorkflowVersionType.DRAFT,
                ).then(() => {
                    setIsDeactivateOpen(true);
                });
            }
        },
        [currentEnvironment, getGroupCustomTriggerCreateTrackActions, group.id],
    );

    const toggleTooltipMessage = useMemo(() => {
        return hasPermissions
            ? `Click to turn the module ${isActive ? 'off' : 'on'}`
            : `Environment is ${isActive ? 'on' : 'off'}. Only makers can change this.`;
    }, [hasPermissions, isActive]);

    function onActivate() {
        const future =
            currentEnvironment === 'build'
                ? groupManager.setBuildEnvironmentEnabled(group, true)
                : groupManager.setWorkerEnabled(group, true);

        future.then((response) => {
            setArchiving(!!response.backgroundProcessId);
        });
    }

    function onDeactivate() {
        let future;
        if (currentEnvironment === 'build') {
            future = groupManager.setBuildEnvironmentEnabled(group, false);
        } else {
            future = groupManager.setWorkerEnabled(group, false);
        }

        future.then(() => {
            setIsActive(false);
        });
    }

    return (
        <>
            {isActivationModalOpen && (
                <EnvironmentActivateConfirmationModal
                    environment={currentEnvironment}
                    group={group}
                    onCancel={() => {
                        setIsActivationModalOpen(false);
                    }}
                    onConfirm={() => {
                        setIsActivationModalOpen(false);
                        onActivate();
                    }}
                />
            )}
            <EnvironmentDeactivateConfirmationModal
                environment={currentEnvironment}
                linkedGroups={groups}
                group={group}
                open={isDeactivateOpen}
                onCancel={() => {
                    setIsDeactivateOpen(false);
                }}
                onConfirm={() => {
                    setIsDeactivateOpen(false);
                    onDeactivate();
                }}
            />
            <EnvironmentSection>
                <EnvironmentIndicator
                    currentEnvironment={currentEnvironment}
                    onChanges={onEnvironmentChange}
                    disabledEnvironments={disabledEnvironments}
                    environmentIsActive={isActive}
                    offModuleIndication
                />

                {(isDraft || isPublished) && (
                    <Tooltip
                        content={
                            isArchiving ? (
                                <span>
                                    The module is in the process of turning on <br />
                                    and will be active once all items are up to date.
                                    <br />
                                    Open history and filter the background process to view the progress
                                </span>
                            ) : (
                                toggleTooltipMessage
                            )
                        }
                    >
                        <ToggleWrapper>
                            <Toggle
                                size={InputSize.SMALL}
                                checked={isActive}
                                disabled={!hasPermissions || isArchiving}
                                onChange={() => onActiveChange(!isActive)}
                                dataAutomation="environment-toggle"
                            />
                        </ToggleWrapper>
                    </Tooltip>
                )}
            </EnvironmentSection>
        </>
    );
};

export default EnvironmentExplorer;
