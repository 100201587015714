import template from './fieldsConfigurationInterface.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkFieldsConfigurationInterface', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        logicId: '@',
        previewEvaluationSource: '<',
        fieldsConfiguration: '<',
        existingFieldsValuesDefinition: '<',
        onFieldsValuesDefinitionChanged: '&',
        validationObject: '<',
    },
    template,
    controller: 'FieldsConfigurationInterfaceCtrl',
});
