import React from 'react';
import styled from 'styled-components';

import { useItemDetailsEntityIndicator_Query_EntityDataQuery } from './itemDetailsEntityIndicator_query_entityData.graphql';
import { recurrencePeriodTypeToLabel } from '../ItemDetailsInputSourceSelection/contentComponents/scheduled/recurrenceSelectionsOptions';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { DataSourceType, RecurrencePeriodType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 40px;
    padding: 6px 12px;
    background: ${Theme.colors.basicBackground};
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

interface Props extends StyledComponentsSupportProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}

const ItemDetailsEntityIndicator: React.FC<Props> = ({ workflowVersionId, className }) => {
    const [
        {
            data: {
                workflowVersion: {
                    dataSourceType,
                    isScheduled,
                    recurrencePeriodType,
                    everyXMinutes,
                    everyXHours,
                    syncConfig,
                },
            },
        },
    ] = useItemDetailsEntityIndicator_Query_EntityDataQuery({ variables: { workflowVersionId } });

    if (dataSourceType === DataSourceType.MANUAL && isScheduled && recurrencePeriodType) {
        let periodTypeDescription;
        if (recurrencePeriodType === RecurrencePeriodType.EveryXMinutes) {
            periodTypeDescription = `Every ${everyXMinutes} Minutes`;
        } else if (recurrencePeriodType === RecurrencePeriodType.EveryXHours) {
            periodTypeDescription = `Every ${everyXHours} Hours`;
        } else {
            periodTypeDescription = recurrencePeriodTypeToLabel[recurrencePeriodType];
        }
        return <Wrapper className={className}>Runs {periodTypeDescription}</Wrapper>;
    }

    if (syncConfig) {
        const viewData = syncConfig.viewData;
        return (
            <Wrapper className={className}>
                <ProjectIntegrationIcon
                    iconUrl={syncConfig.projectIntegration?.iconUrl}
                    integrationType={viewData.integrationType}
                    width={14}
                />
                {`${viewData.query.filters?.length ? 'Filtered' : 'All'} ${
                    viewData.entityMetadata?.pluralLabel || viewData.entity
                }`}
            </Wrapper>
        );
    }

    return <></>;
};

export default ItemDetailsEntityIndicator;
