<div ng-hide="inlineSetupScreen" ng-init="data.today = utils.getToday();">
    <div
        class="box-with-shadow padding-normal"
        ng-repeat="integration in integrations | filter:{name:''}"
        ng-click="!viewOnlyMode && !editMode && onClick(integration)"
        ng-class="{ 'mod-clickable': !editMode && !fixMode && !saving }"
        ng-init="projectIntegration = state[integration.name].integrations[0]"
    >
        <div class="flex-vmiddle" data-automation="integration-group-box-mode-template-data-source-name">
            <!-- Integration logo when icon url is NOT available -->
            <div ng-if="!projectIntegration || !projectIntegration.iconUrl">
                <!-- Integration logo (inline) -->
                <i class="integration-group-header-connected-logo hidden-xs margin-none mod-{{ integration.name }}"></i>
            </div>
            <!-- Integration logo when icon url IS available -->
            <div
                ng-if="projectIntegration && projectIntegration.iconUrl"
                class="integration-group-header-connected-logo mod-confirm hidden-xs mod-manual-icon margin-none"
                uib-tooltip="{{ state[integration.name].integrations[0].displayName || integration.displayName }}"
            >
                <i
                    class="initiative-integration-icon mod-normal margin-right-xs"
                    ng-style="{ background: 'url(' + projectIntegration.iconUrl + ') no-repeat center' }"
                ></i>
                <span>{{ state[integration.name].integrations[0].displayName || integration.displayName }}</span>
            </div>

            <!-- Integration name (inline) -->
            <span
                uib-tooltip="{{ state[integration.name].integrations[0].displayName || integration.displayName }}"
                class="margin-left margin-right-xs common-ellipsis"
            >
                {{ state[integration.name].integrations[0].displayName || integration.displayName }}
            </span>

            <!-- Creator Name-->
            <span ng-class="{ 'common-color-grey': viewOnlyMode }">
                {{
                    state[integration.name].integrations[0].integration.isNativeIntegration &&
                    !state[integration.name].integrations[0].integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + state[integration.name].integrations[0].creator.firstName + ')'
                        : ''
                }}
            </span>
        </div>
        <hr ng-if="!viewOnlyMode" class="margin-normal-sm-v" />

        <div
            ng-if="!viewOnlyMode"
            class="common-size-xxxxs common-color-dark-grey"
            ng-hide="data.removingOrCreatingCommunicationIntegration"
        >
            <p>
                Last update:
                <span
                    uib-tooltip="{{ projectIntegration.lastCollect | date: 'medium' }}"
                    ng-if="projectIntegration.lastCollect"
                >
                    {{ projectIntegration.lastCollect | timeAgo }}
                </span>

                <span ng-if="!projectIntegration.lastCollect">N/A</span>
            </p>
        </div>

        <div ng-if="data.removingOrCreatingCommunicationIntegration" class="flex-vmiddle">
            <i class="loading-small margin-right"></i>
            Removing..
        </div>

        <div
            ng-if="!viewOnlyMode"
            class="flex-vmiddle flex-grow common-width-auto"
            ng-hide="data.removingOrCreatingCommunicationIntegration"
        >
            <div class="flex-grow">
                <!-- Integration is authenticating (inline) -->
                <span
                    ng-if="state[integration.name].connecting"
                    uib-tooltip="Authentication is in progress, popup window is open."
                    class="margin-left common-color-primary"
                >
                    Authenticating...
                </span>
            </div>

            <!-- Loading icon -->
            <i class="loading-medium margin-right-xs" ng-show="updatingIntegrations[integration.name]"></i>

            <div
                ng-if="
                    !viewOnlyMode &&
                    state[integration.name].integrations &&
                    state[integration.name].integrations[0].valid &&
                    !state[integration.name].integrations[0].disabled &&
                    (integrationSyncSettings[integration.name] || integrationImportSettings[integration.name])
                "
            >
                <a
                    class="btn btn-default margin-right"
                    data-automation="integration-group-box-mode-template-view-data"
                    ui-sref="product.enterpriseComponents({prin: projectIntegration.id, tab: 'data-sources'})"
                >
                    View Data
                </a>
            </div>

            <div ng-if="!viewOnlyMode" ng-include="'./integrationGroupButtonTemplate.template.html'"></div>
        </div>
        <!-- If we're in field fix mode, we do not want the plus sign! -->
        <i
            ng-if="!fixMode && !state[integration.name] && !hidePlusIcon"
            class="fa fa-plus margin-left"
            ng-hide="data.removingOrCreatingCommunicationIntegration"
        ></i>

        <div ng-if="connectedByName && !state" ng-hide="data.removingOrCreatingCommunicationIntegration">
            <hr class="margin-normal-sm-v" />
            <span class="common-size-xxxxs">Connected by "{{ connectedByName }}"</span>
        </div>
    </div>
</div>
