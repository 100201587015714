import React from 'react';
import styled from 'styled-components';

import { useGetContractFieldIdToOptions } from '../hooks/useGetContractFieldIdToOptions';

import { H4, Paragraph, Placeholder } from '@tonkean/infrastructure';
import type { ContractFieldMapping } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const RequiredIndication = styled.span`
    color: ${Theme.colors.error};
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const FieldMappingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const FieldsMappingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export interface CustomSelectorProps<T> {
    options: T[];
    selectedField: string | undefined;
    onChange: (fieldId: string | undefined) => void;
    loading: boolean;
}

export interface ContractFieldsMappingProps<T = unknown> {
    fieldMappings: ContractFieldMapping[];
    onChangeEach: (fieldMapping: ContractFieldMapping) => void;
    options: T[];
    isLoadingOptions: boolean;
    isLoadingFieldsMapping: boolean;
    selectorComponent: React.FC<CustomSelectorProps<T>>;
    selectorCustomProps?: Record<string | number | symbol, unknown>;
    allowRepeatedFields?: boolean;
}

const ContractFieldsMapping: React.FC<ContractFieldsMappingProps> = <T,>({
    fieldMappings,
    isLoadingOptions,
    isLoadingFieldsMapping,
    options,
    onChangeEach,
    selectorComponent: CustomSelector,
    selectorCustomProps,
    allowRepeatedFields = true,
}) => {
    const contractFieldIdToOptionsList: Record<
        TonkeanId<TonkeanType.CONTRACT_FIELD>,
        T[]
    > = useGetContractFieldIdToOptions(
        allowRepeatedFields,
        options,
        fieldMappings,
        isLoadingOptions || isLoadingFieldsMapping,
    );

    if (isLoadingFieldsMapping) {
        return (
            <FieldsMappingWrapper data-automation="contract-field-mapping-loading-indication">
                <FieldMappingWrapper>
                    <Title>
                        <Placeholder $height="16px" $width="40%" />
                        <Placeholder $height="14px" $width="90%" />
                    </Title>
                    <Placeholder $height="38px" $width="100%" />
                </FieldMappingWrapper>
                <FieldMappingWrapper>
                    <Title>
                        <Placeholder $height="16px" $width="60%" />
                        <Placeholder $height="14px" $width="40%" />
                    </Title>
                    <Placeholder $height="38px" $width="100%" />
                </FieldMappingWrapper>
                <FieldMappingWrapper>
                    <Title>
                        <Placeholder $height="16px" $width="50%" />
                        <Placeholder $height="14px" $width="70%" />
                    </Title>
                    <Placeholder $height="38px" $width="100%" />
                </FieldMappingWrapper>
            </FieldsMappingWrapper>
        );
    }

    const onChangeSingleFieldMapping = (
        fieldId: TonkeanId<TonkeanType.CONTRACT_FIELD>,
        targetId: string | undefined,
    ) => {
        const originalMap = fieldMappings.find((fieldMap) => fieldMap.contractFieldId === fieldId);

        if (originalMap) onChangeEach?.({ ...originalMap, targetId });
    };

    return (
        <FieldsMappingWrapper data-automation="contract-field-mapping-wrapper">
            {fieldMappings.map((fieldMap) => (
                <FieldMappingWrapper
                    data-automation="contract-field-mapping"
                    data-automation-label={fieldMap.displayName}
                    key={fieldMap.contractFieldId}
                >
                    <Title data-automation="contract-field-mapping-title">
                        <H4 $bold>
                            {fieldMap.displayName}
                            {fieldMap.required ? (
                                <RequiredIndication data-automation="contract-field-mapping-required-indication">
                                    {' '}
                                    (*)
                                </RequiredIndication>
                            ) : (
                                ''
                            )}
                        </H4>

                        <Paragraph $color={Theme.colors.gray_700}>{fieldMap.description}</Paragraph>
                    </Title>

                    <CustomSelector
                        options={contractFieldIdToOptionsList[fieldMap.contractFieldId] || EMPTY_ARRAY}
                        loading={isLoadingOptions}
                        selectedField={fieldMap.targetId}
                        onChange={(newTargetId) => onChangeSingleFieldMapping(fieldMap.contractFieldId, newTargetId)}
                        {...selectorCustomProps}
                    />
                </FieldMappingWrapper>
            ))}
        </FieldsMappingWrapper>
    );
};

export default ContractFieldsMapping;
