import React from 'react';

import QuestionWidget from './QuestionWidget';
import type QuestionWidgetConfiguration from './QuestionWidgetConfiguration';
import type { ItemWidgetProps } from '../../WidgetModule';
import { useItemFieldWidget } from '../hooks';

import { useItemInterfaceContext } from '@tonkean/infrastructure';

const ItemInterfaceQuestionWidget: React.FC<ItemWidgetProps<QuestionWidgetConfiguration>> = ({
    initiative,
    workflowVersionId,
    widget,
    permission,
}) => {
    const {
        reloadInitiativeFromServer,
        updateFieldsValuesManager,
        workflowVersion,
        itemInterfaceFieldDefinitions,
        loadingItemInterfaceFieldDefinitions,
    } = useItemInterfaceContext();

    const [onChange, configuredFields] = useItemFieldWidget(
        workflowVersionId,
        'ITEM_INTERFACE_FIELDS_WIDGET',
        updateFieldsValuesManager,
        workflowVersion,
        initiative,
        itemInterfaceFieldDefinitions,
        undefined,
        widget.configuration.selectedField,
    );

    return (
        <QuestionWidget
            onChange={onChange}
            field={configuredFields?.[0]}
            showEmptyFieldWhenNoInitiative={!initiative}
            loading={loadingItemInterfaceFieldDefinitions}
            widget={widget}
            permission={permission}
        />
    );
};

export default ItemInterfaceQuestionWidget;
