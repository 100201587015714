/**
 * Created by ShaLi on 26/01/2016.
 */

function BoardSettingsCtrl($scope, $state, tonkeanService, projectManager) {
    $scope.pm = projectManager;
    if (!projectManager.isOwner) {
        $state.go('product.settings.profile');
    }

    $scope.projectName = $scope.pm.project.name;
    $scope.editProjectName = false;
    $scope.state = {};

    $scope.data = {
        urlSlug: $scope.pm.project.urlSlug,
        editProjectUrlSlug: false,
        savingUrlSlug: false,
    };

    $scope.cancelEdit = function () {
        $scope.projectName = $scope.pm.project.name;
        $scope.editProjectName = false;
    };

    $scope.cancelUrlSlugEdit = function () {
        $scope.data.urlSlug = $scope.pm.project.urlSlug;
        $scope.data.editProjectUrlSlug = false;
    };

    $scope.submitName = function (projectName) {
        if (projectName) {
            $scope.savingName = true;
            const project = $scope.pm.project;
            tonkeanService
                .updateProjectMetadata(project.id, projectName, project.emailDomain)
                .then(function (data) {
                    // Update PM object
                    angular.extend(project, data);
                    $scope.editProjectName = false;
                })
                .catch(function (error) {
                    $scope.$emit('alert', error);
                })
                .finally(function () {
                    $scope.savingName = false;
                });
        }
    };

    $scope.submitUrlSlug = function () {
        if (!$scope.data.urlSlug?.length) {
            $scope.data.projectUrlSlugValidationError = 'Must have URL ID';
            return;
        }
        if ($scope.data.urlSlug?.length >= 30) {
            $scope.data.projectUrlSlugValidationError = "URL ID can't be more than 30 characters";
            return;
        }
        if (!$scope.data.urlSlug?.match('^[\\w-]*$')) {
            $scope.data.projectUrlSlugValidationError =
                'URL ID must only contain word characters (letters, numbers, hyphen and underscore)';
            return;
        }

        $scope.data.savingUrlSlug = true;
        tonkeanService
            .updateProjectUrlSlug($scope.pm.project.id, $scope.data.urlSlug)
            .then(({ urlSlugEncoded }) => {
                $scope.data.urlSlug = urlSlugEncoded;
                $scope.pm.project.urlSlug = urlSlugEncoded;
                $scope.data.editProjectUrlSlug = false;
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            })
            .finally(function () {
                $scope.data.savingUrlSlug = false;
            });
    };
}
export default angular.module('tonkean.app').controller('BoardSettingsCtrl', BoardSettingsCtrl);
