<div class="tnk-goodtime-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'ADD_TAGS'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Tag IDs:
                <i class="fa fa-info-circle common-color-primary margin-left-xs" uib-tooltip="Separated by commas."></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Tags"
                saved-original-expression="data.definition.tagIdsExpression.originalExpression"
                saved-evaluated-expression="data.definition.tagIdsExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onTagIdsExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.tagsInputError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.tagsInputError }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'DELETE_TAG'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">Tag ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Tag"
                saved-original-expression="data.definition.tagIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.tagIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onTagIdExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.tagInputError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.tagInputError }}
        </div>
    </div>
</div>
