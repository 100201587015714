import React from 'react';
import DefaultHistoryReason from './DefaultHistoryReason';

export default class ExternalChangedHistoryReason extends DefaultHistoryReason {
    constructor(props) {
        super(props);
    }

    getTitle() {
        const reasonMetadata = this.props.item.reasonMetadata;
        if (reasonMetadata) {
            return (
                <div>
                    {this.props.person.name} was asked notified that the status of {this.renderTitleLink()} was changed
                    in {reasonMetadata.externalSourceName} to {reasonMetadata.externalStatus}
                </div>
            );
        } else {
            return super.getTitle();
        }
    }
}
