import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import CollaborationActionsPopover from './CollaborationActionsPopover';
import NavigationButton from './NavigationButton';
import { Comments } from '../../comments';
import type { CollaborationAction } from '../entities';
import { CollaborationIcon, ConciergeIcon } from '../icons';
import {
    CollaborationActionsContext,
    type CollaborationActionsContextData,
} from '../utils/CollaborationActionsContext';

import type { ReactUseState } from '@tonkean/infrastructure';
import { Breakpoint } from '@tonkean/infrastructure';
import { useSmartConversation } from '@tonkean/smart-conversation';
import { SmartSearch, type SmartSearchInstructionHelper, useFetchSmartSearch } from '@tonkean/smart-search';
import { PluggableActionType } from '@tonkean/tonkean-entities';
import type {
    Initiative,
    SmartConversationReplyMethodParams,
    SmartSearchResponse,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { SelectedPluggableAction } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const CollaborationActionsNav = styled.div`
    display: flex;
    gap: 16px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const IconWrapper = styled.div`
    height: 24px;
`;

const StyledCollaborationIcon = styled(CollaborationIcon)`
    margin-top: 4px;
`;

const SmartSearchWrapper = styled.div`
    padding: 24px 16px 16px 16px;
`;

export interface CollaborationActionsPluginProps {
    initiative: Initiative;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    itemInterfaceWidgetIds: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[];
    selectedPluggableActionState?: ReactUseState<SelectedPluggableAction | undefined>;
    pluggableActionHoverState?: ReactUseState<boolean>;
    pluggableActionsToShowSettings?: PluggableActionType[];
    disabled?: boolean;
}

const CollaborationActionsPlugin: React.FC<CollaborationActionsPluginProps> = ({
    initiative,
    projectId,
    itemInterfaceId,
    itemInterfaceWidgetIds,
    selectedPluggableActionState,
    pluggableActionHoverState,
    pluggableActionsToShowSettings,
    disabled,
}) => {
    const [activeActionType, setActiveActionType] = useState<PluggableActionType | undefined>(undefined);

    const { smartIntakeAssistant, loading, error } = useFetchSmartSearch();

    const [selectedPluggableAction, setSelectedPluggableAction] = selectedPluggableActionState ?? [];
    const [pluggableActionHover, setPluggableActionHover] = pluggableActionHoverState ?? [];

    useEffect(() => {
        if (selectedPluggableAction) {
            switch (selectedPluggableAction.actionType) {
                case PluggableActionType.CONCIERGE:
                    setActiveActionType(PluggableActionType.CONCIERGE);
                    break;
                case PluggableActionType.COLLABORATION:
                    setActiveActionType(PluggableActionType.COLLABORATION);
                    break;
            }
        }
    }, [selectedPluggableAction]);

    const replyMethod = useCallback(
        ({ projectId: innerProjectId, userReply, conversationId }: SmartConversationReplyMethodParams) => {
            return smartIntakeAssistant(
                innerProjectId,
                userReply,
                initiative.id,
                itemInterfaceId,
                itemInterfaceWidgetIds,
                conversationId,
            );
        },
        [initiative.id, itemInterfaceId, itemInterfaceWidgetIds, smartIntakeAssistant],
    );

    const smartConversationManager = useSmartConversation<SmartSearchResponse>(
        projectId,
        replyMethod,
        loading.any,
        error,
    );

    const needHelpClickedHandler = useCallback(
        (originalMessage: string) => {
            setSelectedPluggableAction?.({
                actionType: PluggableActionType.COLLABORATION,
                initialActionString: originalMessage,
            });
        },
        [setSelectedPluggableAction],
    );

    const getSmartSearchInstructionHelpers: SmartSearchInstructionHelper[] = useMemo(() => {
        return [
            {
                id: 1,
                title: 'Get help responding',
                options: [
                    '"What purchase category I should choose?"',
                    '"Can you write the business justification for me?"',
                    '"I’m buying office supplies, which payment option should I use?"',
                ],
            },
            {
                id: 2,
                title: 'Ask about policy & guidance',
                options: [
                    '"Why should I use the suggested supplier?”',
                    '"What purchase size requires CFO approval?"',
                    '"How do I know what legal entity to select"',
                ],
            },
        ];
    }, []);

    const getInitialActionString = useCallback(
        (pluggableActionType: PluggableActionType) => {
            if (selectedPluggableAction && selectedPluggableAction.actionType === pluggableActionType) {
                return selectedPluggableAction.initialActionString;
            }

            return undefined;
        },
        [selectedPluggableAction],
    );

    const collaborationActions: CollaborationAction[] = useMemo(() => {
        return [
            {
                actionType: PluggableActionType.CONCIERGE,
                title: 'Concierge',
                icon: <ConciergeIcon />,
                iconFill: Theme.current.palette.colorPicker.HEX_605CE4,
                popupTitle: 'AI Concierge Service',
                popupContent: (
                    <SmartSearchWrapper>
                        <SmartSearch
                            projectId={projectId}
                            searchRequired={false}
                            needHelpClicked={needHelpClickedHandler}
                            smartConversationManager={smartConversationManager}
                            initialSearchString={getInitialActionString(PluggableActionType.CONCIERGE)}
                            instructionHelpers={getSmartSearchInstructionHelpers}
                            showTalkToExpert
                        />
                    </SmartSearchWrapper>
                ),
                shouldShow: pluggableActionsToShowSettings?.includes(PluggableActionType.CONCIERGE) || false,
                showGradientBackground: true,
            },
            {
                actionType: PluggableActionType.COLLABORATION,
                title: 'Collaboration',
                icon: <StyledCollaborationIcon />,
                popupContent: (
                    <Comments
                        initiative={initiative}
                        initiativeInitialLoading={false}
                        defaultCommentText={getInitialActionString(PluggableActionType.COLLABORATION)}
                        filterOnOtherTargets={false}
                        excludePrivateComments
                        canEditComments
                        includeIntake
                    />
                ),
                shouldShow: pluggableActionsToShowSettings?.includes(PluggableActionType.COLLABORATION) || false,
                showGradientBackground: false,
            },
        ];
    }, [
        projectId,
        needHelpClickedHandler,
        smartConversationManager,
        getInitialActionString,
        getSmartSearchInstructionHelpers,
        pluggableActionsToShowSettings,
        initiative,
    ]);

    const contextValue: CollaborationActionsContextData = useMemo(() => {
        return {
            isCommentsOpen: activeActionType === PluggableActionType.COLLABORATION,
        };
    }, [activeActionType]);

    const onClosePopover = useCallback(() => {
        if (pluggableActionHover) {
            return;
        }

        setActiveActionType(undefined);
        setSelectedPluggableAction?.(undefined);
    }, [pluggableActionHover, setSelectedPluggableAction]);

    const navigationButtonClicked = useCallback(
        (collaborationAction: CollaborationAction) => {
            const selectedActionType =
                activeActionType === collaborationAction.actionType ? undefined : collaborationAction.actionType;
            setActiveActionType(selectedActionType);
        },
        [activeActionType, setActiveActionType],
    );

    return (
        <CollaborationActionsContext.Provider value={contextValue}>
            <CollaborationActionsNav>
                {collaborationActions
                    .filter((collaborationAction) => {
                        return collaborationAction.shouldShow;
                    })
                    .map((collaborationAction) => (
                        <CollaborationActionsPopover
                            key={collaborationAction.title}
                            showPopover={collaborationAction.actionType === activeActionType}
                            collaborationAction={collaborationAction}
                            closePopover={onClosePopover}
                        >
                            <NavigationButton
                                data-automation={`collaboration-actions-plugin-navigation-button${collaborationAction.actionType}`}
                                onClick={() => navigationButtonClicked(collaborationAction)}
                                onMouseEnter={() => {
                                    setPluggableActionHover?.(true);
                                }}
                                onMouseLeave={() => {
                                    setPluggableActionHover?.(false);
                                }}
                                isSelected={collaborationAction.actionType === activeActionType}
                                disabled={disabled}
                            >
                                <IconWrapper>{collaborationAction.icon}</IconWrapper>
                                {collaborationAction.title}
                            </NavigationButton>
                        </CollaborationActionsPopover>
                    ))}
            </CollaborationActionsNav>
        </CollaborationActionsContext.Provider>
    );
};

export default CollaborationActionsPlugin;
