export function MondayModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.data = {
        subDomain: null,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        if ($scope.data.loginModal.$valid) {
            const token = $scope.data.key;
            $scope.error = null;
            $scope.loading = true;
            return createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        api_token: token,
                    },
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'MONDAY',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                    return createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.integration.id,
                        'boards',
                    );
                })
                .catch(function (error) {
                    $scope.error = error;
                    $scope.loading = false;
                })
                .then(function (data) {
                    $scope.selections = data.options.slice(0, 100).map(function (board) {
                        return { board };
                    });
                    $scope.loading = false;

                    $scope.boards = data.options;
                    updateBoardsLists();
                });
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }
        const s = {
            boards: $scope.boards,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            board: selection.board.value,
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.onBoardSelected = function (board) {
        if (board && board.displayName) {
            const selection = { board, selected: true };
            $scope.selections.push(selection);
            updateBoardsLists();
            $scope.onSelectionChange(selection);
            $scope.temp.board = null;
        }
    };

    /* Refresh the repositories selection for each combo-box.
     */
    function updateBoardsLists() {
        if ($scope.boards) {
            const boardInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.board.displayName;
                }),
            );
            $scope.otherBoards = $scope.boards.filter(function (r) {
                return !boardInSelection.has(r.displayName);
            });
        }
    }

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (
            state &&
            state.integrations &&
            !$scope.integration &&
            state.integrations[0] &&
            state.integrations[0].integration
        ) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.projectIntegration = state.integrations[0];
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'boards')
                .then(function (data) {
                    $scope.boards = data.options;
                    $scope.selections = data.options
                        .map(function (board) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (projectData.board === board.value) {
                                            return {
                                                board,
                                                selected: true,
                                            };
                                        }
                                    }
                                    // in case bo selected board found
                                    return {
                                        board,
                                        selected: false,
                                    };
                                }
                            }
                        })
                        .filter(angular.identity);
                    $scope.data.loginModal.$valid = true;
                    updateBoardsLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                    $scope.integration = null;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}

export default angular.module('tonkean.shared').controller('MondayModalCtrl', MondayModalCtrl);
