import React from 'react';

const WorkflowVersionLoadingInfo: React.FC = () => {
    return (
        <section className="workflow-version-info workflow-version-loading">
            <header>
                <div className="loading-rect loading-date" />
                <div className="flex-grow" />
                <div className="loading-rect loading-changes-text" />
                <div className="loading-circle loading-changes-circle" />
            </header>
            <main>
                <div className="workflow-versions-owner">
                    <div className="loading-circle loading-avatar" />
                </div>
                <p className="workflow-versions-comment">
                    <span className="loading-rect loading-comment" />
                </p>
            </main>
        </section>
    );
};

export default WorkflowVersionLoadingInfo;
