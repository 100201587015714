import { reactToAngular } from 'angulareact';

import FieldDefinitionMatchItemSettingsModal from './FieldDefinitionMatchItemSettingsModal';

export default angular
    .module('tonkean.app')
    .component(
        'tnkFieldDefinitionMatchItemSettingsModal',
        reactToAngular(FieldDefinitionMatchItemSettingsModal, [
            'groupId',
            'fieldDefinition',
            'workflowVersion',
            'reloadFields',
            'onModalEscClose',
            'onCancel',
            'onDelete',
            'onDuplicate',
        ]),
    );
