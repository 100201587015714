import template from './tnkSidePane.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSidePane', {
    bindings: {
        customClass: '@',
        isOpen: '<',
        doubleSizePane: '<',
        isLeft: '<',
        dontPreventScrollBubbling: '<',
        tabs: '<',
        tabsCustomClass: '<',
        selectedTab: '<',
        marginTop: '<',
        paneDisabled: '<',
        // The event to trigger if clicking on the blocked element
        onDisabledClick: '<',

        onTabSelected: '&',
        onIsOpenChanged: '&',
    },
    transclude: true,
    template,
    controller: 'SidePaneCtrl',
});
