import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { formatDecimalPlaces } from '@tonkean/utils';

const formatNumber = (originalNumber: unknown, fieldDefinition: FieldDefinition) => {
    if (originalNumber?.toString()?.trim() === '' || originalNumber === undefined) {
        return '';
    }

    if (!fieldDefinition.numberFieldDecimalPlaces) {
        return originalNumber;
    }

    return formatDecimalPlaces(
        fieldDefinition.numberFieldDecimalPlaces.decimalPlaces,
        fieldDefinition.numberFieldDecimalPlaces.includeTrailingZeros,
        Number(originalNumber),
    );
};

export default formatNumber;
