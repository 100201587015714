import lateConstructController from '../../utils/lateConstructController';

import type { TonkeanService } from '@tonkean/shared-services';
import type { PeopleDirectory } from '@tonkean/tonkean-entities';
import { getParticipatingVariablesForFormula, replaceExpressionVariableNamesToIds } from '@tonkean/tonkean-utils';

/* @ngInject */
function TonkeanExpressionCtrl(
    $scope,
    $rootScope,
    projectManager,
    customFieldsManager,
    tonkeanService: TonkeanService,
    utils,
    customTriggerManager,
    modal,
    groupInfoManager,
    workflowFolderManager,
    $timeout,
    $q,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        workflowFolderId: ctrl.workflowFolderId,
        entityVersionType: ctrl.entityVersionType,
        logicId: ctrl.logicId,
        placeholder: ctrl.placeholder,
        expressionUniqueIdentifier: ctrl.expressionUniqueIdentifier,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        shouldDeleteVariable: ctrl.shouldDeleteVariable,
        hideEditorButton: ctrl.hideEditorButton || false,
        savedOriginalExpression: ctrl.savedExpression?.originalExpression || ctrl.savedOriginalExpression || '',
        savedEvaluatedExpression: ctrl.savedExpression?.evaluatedExpression || ctrl.savedEvaluatedExpression || '',
        savedIsStripHtmlDisabled: ctrl.savedExpression?.isStripHtmlDisabled || ctrl.savedIsStripHtmlDisabled,
        showToggleStripHtml: ctrl.showToggleStripHtml,
        saveOnKeyUp: ctrl.saveOnKeyUp,
        // modNormalInput: !ctrl.modTextArea, - MAKE THIS THE DEFAULT until we fix up the tagsExpression.
        modNormalInput: ctrl.modNormalInput,
        modTextArea: ctrl.modTextArea,
        textAreaNumberOfRows: ctrl.textAreaNumberOfRows || 8,
        additionalTabs: ctrl.additionalTabs,
        paramForTabs: ctrl.paramForTabs,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        tabsFieldSelectorModFixedWidth: ctrl.tabsFieldSelectorModFixedWidth,
        showFormulasTab: ctrl.showFormulasTab,
        elementId: ctrl.elementId || utils.guid(),
        element: null,
        isInitiated: false,

        disabled: ctrl.disabled === true,
        disabledReason: ctrl.disabledReason,
        prefix: ctrl.prefix,

        // Evaluated example
        doNotEvaluatePreview: ctrl.doNotEvaluatePreview,
        hidePreview: ctrl.hidePreview,

        exampleInitiative: null,

        availableParametersForExpression: null,
        modFixedWidth: ctrl.modFixedWidth,

        expression: null,
        variableNameToVariableMap: {},
        variableLabelToVariableMap: {},
        variableIdToVariableMap: {},

        variableOpeningSign: '{{',
        variableClosingSign: '}}',

        expressionControlObject: {}, // Passed to the tnkTagsExpression so it can enrich it with the addTag function for us to call.

        // Evaluated example
        evaluatedExpression: ctrl.savedEvaluatedExpression,
        evaluatedExpressionValue: null,
        evaluatedExpressionLoading: false,
        evaluationError: '',

        globalExpressionOnly: ctrl.globalExpressionOnly,

        cursorPositionStart: 0,
        cursorPositionEnd: 0,

        waitingForNewField: false,

        automationIdentifierExpressionValue: ctrl.automationIdentifierExpressionValue,
        automationIdentifierExpressionAddField: ctrl.automationIdentifierExpressionAddField,

        customPreview: ctrl.customPreview,

        peopleDirectories: [], // Can either be group specific or project specific, depending on context and props provided
        showPeopleDirectories: ctrl.showPeopleDirectories,
        defaultTabId: ctrl.defaultTabId,
        excludedTabSelectorSpecialFields: ctrl.excludedTabSelectorSpecialFields,
        includeTabSelectorSpecialFieldsForFeatures: ctrl.includeTabSelectorSpecialFieldsForFeatures,
        fieldsToFilter: ctrl.fieldsToFilter || [],
    };

    /**
     * Occurs on initialization of the component.
     */
    ctrl.$onInit = function () {
        $q.all([getPeopleDirectories(), initVariables()]).then(() => {
            // If we are given a logic id, we add parent parameters to the expression.
            if ($scope.data.logicId) {
                const customTrigger = customTriggerManager.getCachedCustomTrigger(
                    $scope.data.workflowVersionId,
                    $scope.data.logicId,
                );
                // Initializing the parameters holder.
                $scope.data.availableParametersForExpression = [];

                const parentNodesInfo = customTriggerManager.getRelevantActionItemsCreatingParentLogicsWorkflowVersion(
                    $scope.data.workflowVersionId,
                    customTrigger,
                    true,
                    false,
                );

                // Initializing the parameter for parent context.
                const possibleValues = parentNodesInfo.map((parentInfo, index) => {
                    return { value: index, label: parentInfo.label };
                });
                possibleValues.push({
                    value: '',
                    label: 'Parent of Root #',
                    isDefaultOption: true,
                    blockClick: true,
                    numOfParentsTillRoot: parentNodesInfo.length,
                    isParentOfRoot: true,
                });

                const parentParameter = {
                    parameterName: 'P',
                    displaySentence: 'Select a flow item',
                    infoTooltip: 'Select the context item in which this field will be treated',
                    freeText: false,
                    possibleValues,
                    defaultValue: 0,
                    checkIsValueSelected: (selectedValue, possibleValue, possibleValueOption) => {
                        // There are cases where the selected value can be really big. In such cases, the selected option
                        // should be the furthermost parent from current item. So, the purpose of this function is to handle
                        // all cases where the selected value does not equal to any possible value, but by the logic of big
                        // parents, it should still be selected.
                        return (
                            (!utils.isNullOrUndefined(selectedValue) && selectedValue === possibleValue) ||
                            (possibleValueOption.isDefaultOption && selectedValue >= parentNodesInfo.length)
                        );
                    },
                };
                // Insert the parent parameter into the available parameters holder.
                $scope.data.availableParametersForExpression.push(parentParameter);

                // Add the default value parameter.
                const defaultValueParameter = {
                    parameterName: 'D',
                    displaySentence: 'Enter a default value',
                    infoTooltip: 'Define a default value to use when the field is empty or not found',
                    freeText: true,
                };
                $scope.data.availableParametersForExpression.push(defaultValueParameter);

                $scope.data.availableAddonsForExpression = [
                    {
                        name: 'editField',
                        condition: (tagValue) => tagValue?.startsWith('FIDE'),
                        getScope: (tagValue) => {
                            return {
                                definition: customFieldsManager.getFieldDefinitionFromCachesById(
                                    $scope.data.workflowVersionId,
                                    tagValue,
                                ),
                            };
                        },
                        reloadFields: () => groupInfoManager.getGroup($scope.data.groupId, true),
                    },
                ];
            }

            $scope.data.expression = $scope.data.savedOriginalExpression || '';
            notifyTonkeanExpressionChanged(
                $scope.data.savedOriginalExpression,
                $scope.data.savedEvaluatedExpression,
                false,
            );

            if (ctrl.overrideExampleInitiative) {
                $scope.data.exampleInitiative = ctrl.overrideExampleInitiative;
            }
            $scope.data.isInitiated = true;
        });
    };

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.disabled) {
            $scope.data.disabled = changesObj.disabled.currentValue === true;
        }

        if (changesObj.disabledReason) {
            $scope.data.disabledReason = ctrl.disabledReason;
        }

        if (changesObj.shouldDeleteVariable) {
            $scope.data.shouldDeleteVariable = changesObj.shouldDeleteVariable.currentValue;
        }

        if (
            changesObj.overrideExampleInitiative &&
            !utils.isNullOrUndefined(changesObj.overrideExampleInitiative.currentValue)
        ) {
            $scope.data.exampleInitiative = changesObj.overrideExampleInitiative.currentValue;
        }
        if (
            changesObj.savedOriginalExpression &&
            changesObj.savedOriginalExpression.currentValue !== $scope.data.expression
        ) {
            const originalExpression =
                changesObj.savedOriginalExpression.currentValue ?? $scope.data.savedOriginalExpression;
            $scope.data.expression = originalExpression;
            $scope.data.savedOriginalExpression = originalExpression;
        }
        if (
            changesObj.savedEvaluatedExpression &&
            changesObj.savedEvaluatedExpression.currentValue !== $scope.data.savedEvaluatedExpression
        ) {
            $scope.data.savedEvaluatedExpression =
                changesObj.savedEvaluatedExpression?.currentValue ?? $scope.data.savedEvaluatedExpression;
            $scope.data.evaluatedExpression = $scope.data.savedEvaluatedExpression;
        }

        if (changesObj.paramForTabs) {
            $scope.data.paramForTabs = changesObj.paramForTabs.currentValue;
            functionCache.clear();
        }

        if (changesObj.additionalTabs) {
            $scope.data.additionalTabs = changesObj.additionalTabs.currentValue;
            if (!changesObj.additionalTabs.isFirstChange()) {
                initVariables();
            }
        }

        if (changesObj.automationIdentifierExpressionValue) {
            $scope.data.automationIdentifierExpressionValue =
                changesObj.automationIdentifierExpressionValue.currentValue;
        }

        if (changesObj.automationIdentifierExpressionAddField) {
            $scope.data.automationIdentifierExpressionAddField =
                changesObj.automationIdentifierExpressionAddField.currentValue;
        }

        if (changesObj.customPreview) {
            $scope.data.customPreview = changesObj.customPreview.currentValue;
        }

        if (changesObj.fieldsToFilter) {
            $scope.data.fieldsToFilter = changesObj.fieldsToFilter.currentValue;
        }
    };

    // Handling a new field definition that is created.
    $scope.$on('newFieldDefinitionCreated', (_, field) => {
        initVariables(true);

        if ($scope.data.waitingForNewField) {
            $scope.data.waitingForNewField = false;
            $scope.expandEditorView(field);
        }
    });

    /**
     * Occurs on change of the expression.
     */
    $scope.onExpressionChanged = function (displayableExpression, evaluatedExpression) {
        if (!$scope.data.modNormalInput && !$scope.data.modTextArea) {
            $timeout(() => {
                const shouldSave =
                    displayableExpression !== $scope.data.savedOriginalExpression ||
                    evaluatedExpression !== $scope.data.savedEvaluatedExpression;
                notifyTonkeanExpressionChanged(displayableExpression, evaluatedExpression, shouldSave);
            });
        } else {
            // Converting direct-variables from name to id.
            const replaceExpressionVariableNamesToIdsFunc = (displayedExpression, isGettingInvalidData) =>
                replaceExpressionVariableNamesToIds(
                    displayedExpression,
                    $scope.data.variableOpeningSign,
                    $scope.data.variableClosingSign,
                    $scope.data.variableNameToVariableMap,
                    $scope.data.variableLabelToVariableMap,
                    {},
                    isGettingInvalidData,
                    $scope.data.shouldDeleteVariable
                        ? (fieldName) => !$scope.data.shouldDeleteVariable(fieldName)
                        : undefined,
                );

            let variableNamesEvaluationInfo = replaceExpressionVariableNamesToIdsFunc(
                displayableExpression,
                $scope.data.modTextArea,
            );

            const fixedExpression = variableNamesEvaluationInfo.invalidIds.reduce((prev, curr) => {
                return prev.replaceAll(curr, '*deleted*');
            }, displayableExpression);

            if (variableNamesEvaluationInfo.invalidIds.length > 0) {
                variableNamesEvaluationInfo = replaceExpressionVariableNamesToIdsFunc(fixedExpression, false);
            }

            // Only if the expression is valid, we update the user of expression change.
            if (variableNamesEvaluationInfo.valid) {
                notifyTonkeanExpressionChanged(fixedExpression, variableNamesEvaluationInfo.evaluatedExpression, true);
            }
        }
    };

    /**
     * Occurs when a variable is selected to be added to the expression.
     */
    $scope.onVariableSelected = function (selectedField) {
        if (!$scope.data.modNormalInput && !$scope.data.modTextArea) {
            if (selectedField.isFormula) {
                // Formula field.
                $scope.data.expressionControlObject.addFormulaTag(selectedField.formulaExpression);
            } else if (selectedField.isAutoComplete) {
                $scope.data.expressionControlObject.addText(selectedField.value);
            } else {
                // Normal field.
                $scope.data.expressionControlObject.addVariable(selectedField.id);
            }
        } else {
            let textToAdd;
            if (selectedField.isFormula) {
                textToAdd = `<#${selectedField.readableFormulaExpression}#>`;
            } else {
                textToAdd =
                    $scope.data.variableOpeningSign +
                    (selectedField.label || selectedField.name) +
                    $scope.data.variableClosingSign;
            }
            $scope.data.expression =
                $scope.data.expression.slice(0, $scope.data.cursorPositionStart) +
                textToAdd +
                $scope.data.expression.slice($scope.data.cursorPositionEnd, $scope.data.expression.length);

            $scope.onExpressionChanged($scope.data.expression);
        }
    };

    $scope.onFieldPopoverOpen = function () {
        if (!$scope.data.modNormalInput && !$scope.data.modTextArea) {
            return;
        }

        if (!$scope.data.element) {
            $scope.data.element = document.getElementById($scope.data.elementId);
        }
        $scope.data.cursorPositionStart = $scope.data.element.selectionStart;
        $scope.data.cursorPositionEnd = $scope.data.element.selectionEnd;
    };

    /**
     * A function passed to the tnkTagsExpression.
     * @param fieldId - a field id for which we want to find a label for.
     * @returns {*} - the label of the given field id, or '*deleted*' if the field was not found.
     */
    $scope.getFieldLabel = function (fieldId) {
        const selectedPeopleDirectory: PeopleDirectory | undefined = $scope.data.peopleDirectories.find(
            (peopleDirectory) => peopleDirectory.id === fieldId,
        );
        if (selectedPeopleDirectory) {
            return selectedPeopleDirectory.displayName;
        }

        if (!$scope.data.workflowVersionId) {
            if ($scope.data.shouldDeleteVariable && !$scope.data.shouldDeleteVariable(fieldId)) {
                return fieldId;
            }

            if ($scope.data.workflowFolderId && $scope.data.entityVersionType) {
                const globalField = customFieldsManager.getGlobalFieldDefinitionByWorkflowFolderFromCachesById(
                    $scope.data.workflowFolderId,
                    $scope.data.entityVersionType,
                    fieldId,
                );
                if (globalField) {
                    return globalField.name;
                }
            }
            return (
                customFieldsManager.getNonInitiativeRelatedSpecialFieldDefinition(fieldId)?.name ||
                $scope.data.variableIdToVariableMap[fieldId]?.label ||
                '*deleted*'
            );
        }
        // Try to get the field.
        const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            fieldId,
        );

        if (fieldDefinition) {
            // If the field definition was found, return its name.
            return fieldDefinition.name;
        } else {
            // No field found for given id.
            return '*deleted*';
        }
    };

    /**
     * A function passed to the tnkTagsExpression. The function creates an html prefix with a small integration logo.
     * @param fieldId - a field id for which we want to create a prefix for.
     */
    $scope.getFieldIconClassName = function (fieldId) {
        const selectedPeopleDirectory: PeopleDirectory | undefined = $scope.data.peopleDirectories.find(
            (peopleDirectory) => peopleDirectory.id === fieldId,
        );
        if (selectedPeopleDirectory) {
            return `initiative-integration-icon mod-people-directories`;
        }

        if (!$scope.data.workflowVersionId) {
            return;
        }
        // Try to get the field.
        const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            fieldId,
        );

        // If the field definition was found, return its a span with it's integration icon (if not special field).
        if (fieldDefinition?.projectIntegration?.integrationType) {
            return `initiative-integration-icon mod-${fieldDefinition.projectIntegration.integrationType.toLowerCase()}`;
        }
    };

    /**
     * A function passed to the tnkTagsExpression. The function extracts the project integration type and id.
     * @param fieldId - a field id for which we want to create a prefix for.
     */
    $scope.getProjectIntegrationTypeAndId = function (fieldId) {
        if (!$scope.data.workflowVersionId) {
            return;
        }
        // Try to get the field.
        const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            fieldId,
        );

        return {
            integrationType: fieldDefinition?.projectIntegration?.integrationType,
            projectIntegrationId: fieldDefinition?.projectIntegration?.id,
        };
    };

    /**
     *
     * @param expressionControlObject {import('../../../modules/EditorModule/modules/ExpressionEditorModule/ExpressionEditor').ExpressionEditorRef}
     */
    $scope.setExpressionControlObject = (expressionControlObject) => {
        $scope.data.expressionControlObject = expressionControlObject;
    };

    /**
     * Toggles the $scope.data.savedIsStripHtmlDisabled boolean.
     */
    $scope.toggleStripHtml = function () {
        $scope.data.savedIsStripHtmlDisabled = !$scope.data.savedIsStripHtmlDisabled;
        notifyTonkeanExpressionChanged($scope.data.savedOriginalExpression, $scope.data.savedEvaluatedExpression, true);
    };

    $scope.expandEditorView = function (fieldToInsert) {
        modal
            .openHtmlEditor(
                $scope.data.groupId,
                $scope.data.expression,
                $scope.data.exampleInitiative,
                false,
                $scope.data.logicId,
                $scope.data.workflowVersionId,
                $scope.data.globalExpressionOnly,
                fieldToInsert,
            )
            .then((result) => {
                if (result.waitingForNewField) {
                    $scope.data.waitingForNewField = result.waitingForNewField;
                    $rootScope.$broadcast('selectWorkflowOutlineTab', { tabKey: 'fields' });
                    $rootScope.$broadcast('createNewField', result.newFieldArgs);
                } else {
                    $scope.data.expression = result.html;
                    $scope.data.evaluatedExpression = result.evaluatedHtml;
                    $scope.data.expressionControlObject?.replaceText?.(result.html);
                    $scope.onExpressionChanged(result.html, result.evaluatedHtml);
                }
            });
    };

    const functionCache = new Map();

    /**
     * Initializes the variables that can be used by the expression.
     */
    async function initVariables(ignoreCache = false) {
        let additionalTabsFieldsMap = {};
        let additionalTabsVariableLabelMap = {};
        let additionalTabsVariableIdMap = {};

        if ($scope.data.additionalTabs) {
            const additionalTabsFields = await Promise.all(
                $scope.data.additionalTabs.map((tab) => {
                    if (ignoreCache || !functionCache.has(tab.getFields)) {
                        functionCache.set(tab.getFields, tab.getFields($scope.data.paramForTabs));
                    }

                    return functionCache.get(tab.getFields);
                }),
            );

            const allAdditionalTabs = additionalTabsFields.flat();
            additionalTabsVariableLabelMap = utils.createMapFromArray(allAdditionalTabs, 'label');
            additionalTabsFieldsMap = utils.createMapFromArray(allAdditionalTabs, 'name');
            additionalTabsVariableIdMap = utils.createMapFromArray(allAdditionalTabs, 'id');
        }

        const { globalFieldDefinitionsMap, columnFieldDefinitionsMap } = await getParticipatingVariables();
        const participatingVariablesInfo = getParticipatingVariablesForFormula(
            globalFieldDefinitionsMap,
            columnFieldDefinitionsMap,
            'TONKEAN_EXPRESSION',
        );

        $scope.data.variableLabelToVariableMap = {
            ...participatingVariablesInfo.variableLabelToVariableMap,
            ...additionalTabsVariableLabelMap,
        };

        $scope.data.variableIdToVariableMap = {
            ...participatingVariablesInfo.variableIdToVariableMap,
            ...additionalTabsVariableIdMap,
        };

        $scope.data.variableNameToVariableMap = {
            ...participatingVariablesInfo.variableNameToVariableMap,
            ...additionalTabsFieldsMap,
        };

        // when field changed we want execute onChanges in the tnkTagsExpression to render the ui
        $scope.getFieldLabel = $scope.getFieldLabel.bind(ctrl);
    }

    async function getParticipatingVariables() {
        if (ctrl.workflowVersionId) {
            return {
                globalFieldDefinitionsMap: customFieldsManager.selectedGlobalFieldsMap[ctrl.workflowVersionId],
                columnFieldDefinitionsMap: customFieldsManager.selectedColumnFieldsMap[ctrl.workflowVersionId],
            };
        }

        if (ctrl.workflowFolderId && ctrl.entityVersionType) {
            const globalFieldDefinitionsMap = await customFieldsManager.getGlobalFieldDefinitionsByWorkflowFolderId(
                ctrl.workflowFolderId,
                ctrl.entityVersionType,
            );

            return { globalFieldDefinitionsMap };
        }

        return {};
    }

    async function getPeopleDirectories() {
        if (!$scope.data.showPeopleDirectories) {
            return;
        }

        const workflowFolderId = workflowFolderManager.getContainingWorkflowFolder(
            $scope.pm.project.id,
            $scope.data.groupId,
        )?.id;

        if (!$scope.data.groupId) {
            // If theres no group id we are not in a context that can have solution access restrictions (like the front door access settings)
            const peopleDirectoryResults = await tonkeanService.getPeopleDirectoriesByProjectId(
                $scope.pm.project.id,
                '',
                0,
                100,
            );
            $scope.data.peopleDirectories = peopleDirectoryResults.entities;
        } else if (workflowFolderId) {
            const peopleDirectoryResults = await tonkeanService.getPeopleDirectoriesByWorkflowFolderId(
                workflowFolderId,
                '',
                0,
                100,
            );

            $scope.data.peopleDirectories = peopleDirectoryResults.entities;
        } else {
            $scope.data.peopleDirectories = [];
        }
    }

    /**
     * Notifies user of component on expression change.
     */
    function notifyTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.evaluatedExpression = evaluatedExpression;
        if (ctrl.onTonkeanExpressionChanged) {
            ctrl.onTonkeanExpressionChanged({
                originalExpression,
                evaluatedExpression,
                expression: {
                    originalExpression,
                    evaluatedExpression,
                    isStripHtmlDisabled: $scope.data.savedIsStripHtmlDisabled,
                },
                expressionIdentifier: $scope.data.expressionUniqueIdentifier,
                shouldSaveLogic,
                isStripHtmlDisabled: $scope.data.savedIsStripHtmlDisabled,
            });

            if (shouldSaveLogic) {
                $scope.data.expression = originalExpression;
                $scope.data.savedOriginalExpression = originalExpression;
                $scope.data.savedEvaluatedExpression = evaluatedExpression;
            }
        }
    }
}

angular.module('tonkean.app').controller('TonkeanExpressionCtrl', lateConstructController(TonkeanExpressionCtrl));
