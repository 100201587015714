<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Integration</h4>
    </div>
</div>
<div class="modal-body">
    <form novalidate>
        <div ng-form="data.integrationForm">
            <div class="modal-body">
                <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
                <div ng-hide="connecting || integration" class="text-center">
                    <button
                        type="button"
                        class="btn btn-secondary btn-lg"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="authorize()"
                    >
                        Authenticate with {{ currentIntegration.displayName }}
                    </button>
                </div>
                <div class="text-center" ng-show="connecting">
                    <h4>
                        <span class="loading"></span>
                        Connecting to {{ currentIntegration.displayName }}
                    </h4>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                analytics-event="Change Auth"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        ng-disabled="!data.integrationForm.$valid"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
