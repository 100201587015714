import React, { useCallback, useImperativeHandle, useState } from 'react';

import { angularToReact } from '@tonkean/angular-components';
import { useIsInSharedReport } from '@tonkean/angular-hooks';
import { Popover } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { classNames } from '@tonkean/utils';

const EditTagsPopover = angularToReact('tnk-edit-tags-popover');

interface Props {
    item: any;
    collectItemsMode?: boolean;
    viewOnlyMode: boolean;
    solutionBusinessReportId?: string;

    onTrackEdit(): void;
}

interface Ref {
    openPopover(): void;
}

/**
 * A components that renders the track list item tags.
 */
const TrackTags: React.ForwardRefRenderFunction<Ref, Props> = (
    { item, onTrackEdit, collectItemsMode, viewOnlyMode, solutionBusinessReportId },
    ref,
) => {
    const tags = item.tags;

    const [popoverOpen, setPopoverOpen] = useState(false);
    const isInShareMode = useIsInSharedReport();

    const onClick = useCallback(() => {
        if (!viewOnlyMode) {
            setPopoverOpen(true);
        }
    }, [viewOnlyMode]);
    const onClose = () => setPopoverOpen(false);

    // This will allow to get openPopover function from the ref.
    useImperativeHandle(ref, () => ({ openPopover: onClick }), [onClick]);

    // Inner element.
    let tagsInner: React.ReactNode;
    if (!tags?.length) {
        // No tags.
        tagsInner = (
            <Tooltip content={viewOnlyMode ? 'No tags' : 'Add tags'}>
                <i
                    onClick={viewOnlyMode ? undefined : onClick}
                    className={`margin-normal-h fa fa-tags common-color-light-grey2 opacity-50 ${
                        !viewOnlyMode && 'pointer'
                    }`}
                />
            </Tooltip>
        );
    } else if (tags.length === 1) {
        // One tag.
        const tagName = tags[0].length > 10 ? `${tags[0].slice(0, 8)}..` : tags[0];
        tagsInner = (
            <span
                onClick={viewOnlyMode ? undefined : onClick}
                className={`initiative-tag pointer common-size-xxxxxs ${!viewOnlyMode && 'pointer'}`}
            >
                #{tagName}
            </span>
        );
    } else {
        // More than one tag.
        const allTags = tags.join(', ');
        tagsInner = (
            <Tooltip content={allTags}>
                <i
                    onClick={viewOnlyMode ? undefined : onClick}
                    className={`margin-normal-h fa fa-tags pointer common-color-light-grey2 ${
                        !viewOnlyMode && 'pointer'
                    }`}
                />
            </Tooltip>
        );
    }

    return (
        <Popover
            content={
                <EditTagsPopover
                    initiative={item}
                    onSave={onTrackEdit}
                    onClose={onClose}
                    solutionBusinessReportId={solutionBusinessReportId}
                    isInSharedMode={isInShareMode}
                    isOpen
                    inline
                />
            }
            show={popoverOpen}
            onClose={onClose}
            overflowVisible
        >
            <div
                className={classNames(
                    'text-center tracks-col-info2 hidden-xs hidden-sm',
                    !tags?.length && !popoverOpen && 'visible-on-hover',
                    collectItemsMode && 'mod-force-visible',
                )}
                tabIndex={collectItemsMode && tags.length === 1 ? 0 : -1}
                onKeyPress={(e) => {
                    if (collectItemsMode && !viewOnlyMode) {
                        onClick();
                    }
                }}
            >
                {tagsInner}
            </div>
        </Popover>
    );
};

export default React.forwardRef(TrackTags);
