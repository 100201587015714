import template from './tnkZendeskCustomActionPerformerSelector.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkZendeskCustomActionPerformerSelector', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        definition: '<',
        validationObject: '<',
        onActionPerformerChanged: '&',
    },
    template,
    controller: 'ZendeskCustomActionPerformerSelectorCtrl',
});
