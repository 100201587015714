<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Comment on file -->
        <div
            class="delay-configuration-logic"
            ng-if="
                data.definition.customActionKey === 'CREATE_FOLDER' ||
                data.definition.customActionKey === 'CREATE_SHARED_FOLDER'
            "
        >
            <div class="margin-bottom-lg">
                <div class="margin-bottom-xs">Name:</div>

                <tnk-tonkean-expression
                    class="margin-bottom-xs"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Folder Name"
                    saved-expression="data.definition.folderNameExpression"
                    expression-unique-identifier="DRPBXFolderName"
                    on-tonkean-expression-changed="onFolderNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    ng-if="data.validationObject.noFolderName"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noFolderName }}
                </div>
            </div>

            <!-- Folder Path -->
            <div class="margin-bottom-lg">
                <div class="margin-bottom-xs flex flex-inline">
                    Folder Parent Path: (Optional)
                    <div
                        class="margin-left"
                        uib-popover-template="'../../../../../tnkPopovers/tnkDropboxPopover/folderParentPathMessageTemplate.template.html'"
                        popover-placement="{{ placement || 'bottom-middle' }}"
                        popover-class="common-width-60"
                        popover-trigger="mouseenter"
                    >
                        <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
                    </div>
                </div>

                <tnk-tonkean-expression
                    class="margin-bottom-xs"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="/some root folder/parent"
                    saved-expression="data.definition.pathExpression"
                    expression-unique-identifier="DRPBXPath"
                    on-tonkean-expression-changed="onPathExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    class="common-color-warning margin-left-xs common-size-xxxs margin-top-xs"
                    ng-if="!data.isWorkingUnderRootFolder"
                >
                    <b>Note:</b>
                    This folder isn't a subfolder of the root monitored folder configured in data sources.
                </div>
            </div>

            <!-- Auto Rename -->
            <div class="flex flex-inline">
                <tnk-checkbox
                    class="margin-right-xs"
                    label="'Auto Rename'"
                    value="data.definition.isAutoRename"
                    on-toggle="onAutoRenameToggled(value)"
                ></tnk-checkbox>

                <div
                    class="margin-left"
                    uib-popover-template="'../../../../../tnkPopovers/tnkDropboxPopover/autoRenamePopoverTemplate.template.html'"
                    popover-placement="{{ placement || 'bottom-middle' }}"
                    popover-class="common-width-60"
                    popover-trigger="mouseenter"
                >
                    <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
                </div>
            </div>
        </div>

        <!-- Share Folder -->
        <div class="margin-top" ng-if="data.definition.customActionKey === 'CREATE_SHARED_FOLDER'">
            <!-- Email -->
            <div>
                <div class="margin-bottom-xl">People to share with:</div>

                <div ng-if="data.validationObject.noMembers" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noMembers }}
                </div>

                <div ng-repeat="member in data.members" class="margin-bottom-xs">
                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        placeholder="Email address to share with"
                        saved-expression="member.emailExpression"
                        expression-unique-identifier="DRPX-MEMBEREMAIL-{{ $index }}"
                        on-tonkean-expression-changed="
                            onMemberEmailExpressionChanged(expression, shouldSaveLogic, member)
                        "
                    ></tnk-tonkean-expression>
                </div>

                <a ng-click="addEmptyMember()">+ Add</a>
            </div>
        </div>

        <!-- Copy Folder -->
        <div ng-if="data.definition.customActionKey === 'COPY_FOLDER'">
            <!-- From Folder Path -->
            <div class="margin-bottom-xs margin-top">Source folder path to copy:</div>

            <tnk-tonkean-expression
                class="margin-bottom-xs"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="/some root folder/parent"
                saved-expression="data.definition.pathFromExpression"
                expression-unique-identifier="DRPBXFromPath"
                on-tonkean-expression-changed="onPathFromExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
            <div
                class="common-color-warning margin-left-xs common-size-xxxs margin-top-xs"
                ng-if="!data.isSourceFolderPathToCopyUnderRootFolder"
            >
                <b>Note:</b>
                This folder isn't a subfolder of the root monitored folder configured in data sources.
            </div>

            <div ng-if="data.validationObject.noFromPath" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noFromPath }}
            </div>

            <!-- To folder-->
            <div class="margin-bottom margin-top">
                <div class="margin-bottom-xs">New folder name:</div>

                <tnk-tonkean-expression
                    class="margin-bottom-xs"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Folder Name"
                    saved-expression="data.definition.folderNameExpression"
                    expression-unique-identifier="DRPBXFolderName"
                    on-tonkean-expression-changed="onFolderNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    ng-if="data.validationObject.noFolderName"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noFolderName }}
                </div>
            </div>

            <!-- Folder Path -->
            <div class="margin-bottom-lg">
                <div class="margin-bottom-xs flex flex-inline">
                    New folder parent path: (Optional)

                    <div
                        class="margin-left"
                        uib-popover-template="'../../../../../tnkPopovers/tnkDropboxPopover/folderParentPathMessageTemplate.template.html'"
                        popover-placement="{{ placement || 'bottom-middle' }}"
                        popover-class="common-width-60"
                        popover-trigger="mouseenter"
                    >
                        <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
                    </div>
                </div>

                <div ng-if="data.validationObject.noPath" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noPath }}
                </div>

                <tnk-tonkean-expression
                    class="margin-bottom-lg"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="/some root folder/parent"
                    saved-expression="data.definition.pathExpression"
                    expression-unique-identifier="DRPBXPath"
                    on-tonkean-expression-changed="onPathExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>

                <div
                    class="common-color-warning margin-left-xs common-size-xxxs margin-top-xs"
                    ng-if="!data.isWorkingUnderRootFolder"
                >
                    <b>Note:</b>
                    This folder isn't a subfolder of the root monitored folder configured in data sources.
                </div>
            </div>

            <!-- Auto Rename -->
            <div class="flex flex-inline">
                <tnk-checkbox
                    class="margin-right-xs"
                    label="'Auto Rename'"
                    value="data.definition.isAutoRename"
                    on-toggle="onAutoRenameToggled(value)"
                ></tnk-checkbox>
                <div
                    class="margin-left"
                    uib-popover-template="'../../../../../tnkPopovers/tnkDropboxPopover/autoRenamePopoverTemplate.template.html'"
                    popover-placement="{{ placement || 'bottom-middle' }}"
                    popover-class="common-width-60"
                    popover-trigger="mouseenter"
                >
                    <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
                </div>
            </div>

            <!-- Allow shared folder -->
            <tnk-checkbox
                class="margin-right-xs margin-top"
                label="'Allow copying shared folder'"
                value="data.definition.isAllowSharedFolder"
                on-toggle="onIsAllowSharedFolderToggled(value)"
            ></tnk-checkbox>

            <!-- Allow transfer ownership-->
            <tnk-checkbox
                class="margin-right-xs margin-top"
                label="'Allow transfering ownership if needed'"
                value="data.definition.isAllowOwnershipTransfer"
                on-toggle="onIsAllowOwnershipTransferToggled(value)"
            ></tnk-checkbox>
        </div>
    </div>
    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE_FROM_URL'">
        <div class="margin-bottom-xs">URL:</div>
        <tnk-tonkean-expression
            class="margin-bottom-lg"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The url to download the file from."
            saved-expression="data.definition.urlExpression"
            expression-unique-identifier="DRPBXUrl"
            on-tonkean-expression-changed="onURLExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
        <div class="margin-bottom-xs margin-top">New File Path:</div>
        <tnk-tonkean-expression
            class="margin-bottom-lg"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="/Some Root Folder/Parent Folder"
            saved-expression="data.definition.pathExpression"
            expression-unique-identifier="DRPBXPath"
            on-tonkean-expression-changed="onPathExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
        <div class="margin-bottom-xs margin-top">New File Name:</div>
        <tnk-tonkean-expression
            class="margin-bottom-lg"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="The new name for the file."
            saved-expression="data.definition.nameExpression"
            expression-unique-identifier="DRPBXName"
            on-tonkean-expression-changed="onNameExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
    </div>

    <!-- Extract Text From Document -->
    <div ng-if="data.definition.customActionKey === 'EXTRACT_TEXT_FROM_DOCUMENT'">
        <!-- Selecting google cloud integration for when doing OCR using google cloud -->
        <div ng-if="pm.projectData.executeOcrUsingGoogleCloudForPdfFile">
            <!-- Title -->
            <div class="margin-bottom-xs">
                Google Cloud Provider (
                <a ui-sref="product.enterpriseComponents({tab: 'data-sources'})">Connect</a>
                ):
            </div>

            <!-- Project integration selector -->
            <tnk-project-integration-selector
                group-id="{{ data.groupId }}"
                selected-project-integration-id="{{ data.definition.googleCloudProjectIntegrationId }}"
                allow-default-option="true"
                default-option-label="Tonkean Google Cloud Provider"
                filter-on-integration-type="data.filterByIntegrations"
                on-project-integration-selected="onProjectIntegrationSelected(selectedProjectIntegration)"
            ></tnk-project-integration-selector>
        </div>
    </div>
</div>
