import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addItemIsAddedCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addItemIsAddedCreateAfterOption';
import addMonitorBlockCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addMonitorBlockCreateAfterOption';
import personSelectorValidator from '../sharedConfigComponents/validators/personSelectorValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const NextStepsLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.NEXT_STEPS,
    title: 'Request Action Items',
    iconClass: 'mod-next-steps',
    creatingActionItems: true,
    description: 'The Module will reach out and ask the configured person for manual input on the next action items.',
    hasPersonConfig: true,
    cantMoveInto: true,
    doNotDisplayStatusEdit: true,
    doNotDisplayNotificationsEdit: true,
    allowedChildrenTypes: () => [LogicBlockConfigType.MONITOR_TRACKS],
    innerItemDetailsTitlePlaceholder: 'Action Item',
    innerItemDetailsDescriptionPlaceholder: 'An action item related to the business request',
    createAfterOptionsTitle: 'Run on',
    createAfterOptions: (features) => {
        if (features?.tonkean_show_inner_item_idd) {
            return [addItemIsAddedCreateAfterOption, addMonitorBlockCreateAfterOption];
        } else {
            return [addMonitorBlockCreateAfterOption];
        }
    },
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};
        personSelectorValidator(validationObject, definition);
        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    parentValidationOnCreation: (parent, $emit) => {
        if (
            parent?.customTriggerType &&
            (parent.customTriggerType === 'AUTONOMOUS' || parent.customTriggerType === 'INNER_AUTONOMOUS') &&
            parent.monitorInnerItems
        ) {
            $emit('alert', {
                msg: 'You cannot create Request Action Items as an inner child of a trigger that monitor inner items creation',
                type: 'error',
            });
            return false;
        } else {
            return true;
        }
    },
};

export default NextStepsLogicBlockConfig;
