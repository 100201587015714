import template from './tnkEditTagsPopover.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

function tnkEditTagsPopover() {
    return {
        restrict: 'E',
        scope: {
            item: '=initiative',
            onSave: '&',
            onClose: '&',
            inline: '=',
            isOpen: '=',
            placement: '@',
            solutionBusinessReportId: '=',
            isInSharedMode: '=',
        },
        transclude: true,
        template,
        controller: 'EditTagsPopoverCtrl',
    };
}
angular.module('tonkean.app').directive('tnkEditTagsPopover', tnkEditTagsPopover);

function EditTagsPopoverCtrl($scope, $rootScope, trackHelper) {
    $scope.pm = $rootScope.pm;
    $scope.popover = { initiativeTags: [] };

    $scope.init = function () {
        $scope.popover.initiativeTags = [];
    };

    $scope.init();

    $scope.$watch('item.id', function () {
        $scope.init();
        setSelectedOption();
    });
    $scope.$watch('item.updated', setSelectedOption);
    $scope.$watch('isOpen', function (value) {
        setSelectedOption();
        $scope.popover.isOpen = value;
    });

    $scope.updateTags = function (tags) {
        $scope.popover.posting = true;
        analyticsWrapper.track('Update Tags', { category: 'Initiative view' });

        trackHelper
            .updateTags($scope.item.id, tags, $scope.isInSharedMode, $scope.solutionBusinessReportId)
            .then(function (data) {
                $scope.item.tags = data.tags;

                const item = trackHelper.getInitiativeFromCache();
                if (item) {
                    item.tags = data.tags;
                }

                if ($scope.onSave) {
                    $scope.onSave();
                }

                $scope.pm.getTags(true);

                $scope.close();
            });
    };

    $scope.close = function () {
        $scope.popover.isOpen = false;
        if ($scope.onClose) {
            $scope.onClose();
        }
    };

    function setSelectedOption() {
        if ($scope.item && $scope.item.tags && !$scope.popover.initiativeTags.length) {
            $scope.popover.initiativeTags = angular.copy($scope.item.tags);
        }
    }
}

angular.module('tonkean.app').controller('EditTagsPopoverCtrl', EditTagsPopoverCtrl);
