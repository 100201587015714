import type { ItemInterface, TonkeanId } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import { useAngularService, useAngularWatch } from 'angulareact';
import { useCallback } from 'react';
import { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';
import type WorkflowVersionManager from './workflowVersionManager.service';

/**
 * The ItemInterfacesManager is a slim cache for the item interfaces in the module editor.
 * It supports caching interfaces of 1 module at a time.
 * To make sure no stale data is returned, we use the WorkflowVersionId.
 *
 * This service should not be consumed directly, only via the hook below.
 */
class ItemInterfacesCacheImpl {
    itemInterfaces: Record<
        TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        Record<TonkeanId<TonkeanType.ITEM_INTERFACE>, ItemInterface>
    > = {};
    loading: Record<TonkeanId<TonkeanType.WORKFLOW_VERSION>, ItemInterfaceLoadingState> = {};

    constructor(private workflowVersionManager: WorkflowVersionManager) {}

    getItemInterfaces(workflowVersionIdToGet: TonkeanId<TonkeanType.WORKFLOW_VERSION>): ItemInterface[] {
        return Object.values(this.itemInterfaces[workflowVersionIdToGet] ?? {});
    }

    getItemInterfaceById(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
    ): ItemInterface | undefined {
        return this.itemInterfaces[workflowVersionId]?.[itemInterfaceId];
    }

    onItemInterfaceCreated(newItemInterface: ItemInterface | undefined) {
        if (newItemInterface) {
            const workflowVersionId = newItemInterface.workflowVersionId;
            const newItemInterfacesMap = {
                ...this.itemInterfaces[workflowVersionId],
                [newItemInterface.id]: newItemInterface,
            };
            this.setItemInterfaces(workflowVersionId, Object.values(newItemInterfacesMap));
            this.workflowVersionManager.incrementWorkflowVersionCounter(newItemInterface.workflowVersionId);
        }
    }

    onItemInterfaceDeleted(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined,
    ) {
        if (!itemInterfaceId) {
            return;
        }

        const itemInterface = this.itemInterfaces[workflowVersionId]?.[itemInterfaceId];

        if (!itemInterface) {
            return;
        }
        const itemInterfaces = Object.values(this.itemInterfaces[workflowVersionId] ?? []).filter(
            (_itemInterface) => _itemInterface.id !== itemInterfaceId,
        );

        this.setItemInterfaces(workflowVersionId, itemInterfaces);
        this.workflowVersionManager.incrementWorkflowVersionCounter(itemInterface.workflowVersionId);
    }

    onItemInterfaceUpdated(newItemInterface: ItemInterface | undefined) {
        if (!newItemInterface) {
            return;
        }

        const itemInterfaces = Object.values(this.itemInterfaces[newItemInterface.workflowVersionId] ?? []).map(
            (_itemInterface) => (_itemInterface.id === newItemInterface.id ? newItemInterface : _itemInterface),
        );

        this.setItemInterfaces(newItemInterface.workflowVersionId, itemInterfaces);
        this.workflowVersionManager.incrementWorkflowVersionCounter(newItemInterface.workflowVersionId);
    }

    setItemInterfaces(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>, itemInterfaces: ItemInterface[]) {
        const itemInterfacesMap: Record<TonkeanId<TonkeanType.ITEM_INTERFACE>, ItemInterface> = Object.fromEntries(
            itemInterfaces.map((itemInterface) => [itemInterface.id, itemInterface]),
        );

        this.itemInterfaces = { ...this.itemInterfaces, [workflowVersionId]: itemInterfacesMap };
    }

    setItemInterfaceDefault(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        isDefault: boolean,
    ) {
        const itemInterfaces = Object.values(this.itemInterfaces[workflowVersionId] ?? []).map((itemInterface) => {
            return { ...itemInterface, isDefault: itemInterface.id === itemInterfaceId ? isDefault : false };
        });

        this.setItemInterfaces(workflowVersionId, itemInterfaces);
        this.workflowVersionManager.incrementWorkflowVersionCounter(workflowVersionId);
    }

    setLoading(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>, loadingState: ItemInterfaceLoadingState) {
        const loading = { ...this.loading };
        loading[workflowVersionId] = loadingState;

        this.loading = loading;
    }

    getLoading(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        // We default to ItemInterfaceLoadingState.LOADING to avoid a flash of empty state.
        return this.loading[workflowVersionId] ?? ItemInterfaceLoadingState.LOADING;
    }
}

export type ItemInterfacesCache = typeof ItemInterfacesCacheImpl;

/**
 * A thin wrapper around ItemInterfacesManagerImpl that exposes a react-y API
 */
export default function useItemInterfaceCache() {
    const itemInterfaceCache: ItemInterfacesCacheImpl = useAngularService('itemInterfacesManager');
    const $timeout = useAngularService('$timeout');

    // Using useAngularWatch to make sure React re-renders our components when we edit the item interfaces
    // inside the itemInterfaceManager
    const [rawItemInterfaces, loading] = useAngularWatch(
        () => itemInterfaceCache.itemInterfaces,
        () => itemInterfaceCache.loading,
    );

    const getItemInterfaces = useCallback<ItemInterfacesCacheImpl['getItemInterfaces']>(
        (...args) => {
            if (rawItemInterfaces) {
                return itemInterfaceCache.getItemInterfaces(...args);
            }
            return [];
        },
        [itemInterfaceCache, rawItemInterfaces],
    );

    const onItemInterfaceCreated = useCallback<ItemInterfacesCacheImpl['onItemInterfaceCreated']>(
        (...args) => {
            $timeout(() => itemInterfaceCache.onItemInterfaceCreated(...args));
        },
        [$timeout, itemInterfaceCache],
    );

    const onItemInterfaceDeleted = useCallback<ItemInterfacesCacheImpl['onItemInterfaceDeleted']>(
        (...args) => {
            $timeout(() => itemInterfaceCache.onItemInterfaceDeleted(...args));
        },
        [$timeout, itemInterfaceCache],
    );

    const onItemInterfaceUpdated = useCallback<ItemInterfacesCacheImpl['onItemInterfaceUpdated']>(
        (...args) => {
            $timeout(() => itemInterfaceCache.onItemInterfaceUpdated(...args));
        },
        [$timeout, itemInterfaceCache],
    );

    const setItemInterfaces = useCallback<ItemInterfacesCacheImpl['setItemInterfaces']>(
        (...args) => {
            $timeout(() => itemInterfaceCache.setItemInterfaces(...args));
        },
        [$timeout, itemInterfaceCache],
    );

    const getItemInterfaceById = useCallback<ItemInterfacesCacheImpl['getItemInterfaceById']>(
        (...args) => {
            if (rawItemInterfaces) {
                return itemInterfaceCache.getItemInterfaceById(...args);
            }
        },
        [itemInterfaceCache, rawItemInterfaces],
    );

    const setItemInterfaceDefault = useCallback<ItemInterfacesCacheImpl['setItemInterfaceDefault']>(
        (...args) => {
            $timeout(() => itemInterfaceCache.setItemInterfaceDefault(...args));
        },
        [$timeout, itemInterfaceCache],
    );

    const setLoading = useCallback<ItemInterfacesCacheImpl['setLoading']>(
        (...args) => {
            itemInterfaceCache.setLoading(...args);
        },
        [itemInterfaceCache],
    );

    const getLoading = useCallback<ItemInterfacesCacheImpl['getLoading']>(
        (...args) => {
            return itemInterfaceCache.getLoading(...args);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [itemInterfaceCache, loading],
    );

    return {
        getItemInterfaces,
        setItemInterfaces,
        onItemInterfaceCreated,
        onItemInterfaceDeleted,
        onItemInterfaceUpdated,
        getItemInterfaceById,
        setItemInterfaceDefault,
        setLoading,
        getLoading,
    };
}

angular.module('tonkean.app').service('itemInterfacesManager', ItemInterfacesCacheImpl);
