import { lateConstructController } from '@tonkean/angular-components';
import { getDisplayFormats } from '@tonkean/constants';

/* @ngInject */
function DisplayFormatSelectorCtrl($scope, utils) {
    $scope.utils = utils;
    const ctrl = this;

    $scope.data = {
        // Component parameters
        defaultDisplayFormat: ctrl.defaultDisplayFormat,
        selectedFormatApiName: ctrl.selectedFormatApiName,
        selectedFormatPrefix: ctrl.selectedFormatPrefix,
        selectedFormatPostfix: ctrl.selectedFormatPostfix,
        apiNameFilter: ctrl.apiNameFilter,
        fieldTypeFilter: ctrl.fieldTypeFilter,
        disableSelection: ctrl.disableSelection,

        // Format
        selectedDisplayFormat: null,
        allDisplayFormats: utils.objValues(getDisplayFormats()),
        displayFormats: utils.objValues(getDisplayFormats()),
        displayFormatsMap: getDisplayFormats(),
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        // Apply the requested api name filter if supplied.
        if ($scope.data.apiNameFilter) {
            $scope.data.displayFormats = $scope.data.allDisplayFormats.filter(function (format) {
                return format.apiName === $scope.data.apiNameFilter;
            });
        }

        // Apply the requested field type filter if supplied.
        if ($scope.data.fieldTypeFilter) {
            $scope.data.displayFormats = $scope.data.allDisplayFormats.filter(function (format) {
                return format.fieldType.apiName === $scope.data.fieldTypeFilter;
            });
        }

        chooseSelectedDisplayFormat();
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        if (changes.apiNameFilter) {
            $scope.data.apiNameFilter = ctrl.apiNameFilter;

            // Apply the requested api name filter if supplied.
            if ($scope.data.apiNameFilter) {
                $scope.data.displayFormats = $scope.data.allDisplayFormats.filter(function (format) {
                    return format.apiName === $scope.data.apiNameFilter;
                });
            }
        }

        let formatChanged = false;

        if (changes.fieldTypeFilter) {
            $scope.data.fieldTypeFilter = ctrl.fieldTypeFilter;

            // Apply the requested field type filter if supplied.
            if ($scope.data.fieldTypeFilter) {
                $scope.data.displayFormats = $scope.data.allDisplayFormats.filter(function (format) {
                    return format.fieldType.apiName === $scope.data.fieldTypeFilter;
                });
            }

            formatChanged = true;
        }

        if (changes.selectedFormatPrefix) {
            $scope.data.selectedFormatPrefix = ctrl.selectedFormatPrefix;
            formatChanged = true;
        }

        if (changes.selectedFormatPostfix) {
            $scope.data.selectedFormatPostfix = ctrl.selectedFormatPostfix;
            formatChanged = true;
        }

        if (changes.disableSelection) {
            $scope.data.disableSelection = ctrl.disableSelection;
            formatChanged = true;
        }

        if (changes.selectedFormatApiName) {
            $scope.data.selectedFormatApiName = ctrl.selectedFormatApiName;
            formatChanged = true;
        }

        if (formatChanged) {
            chooseSelectedDisplayFormat();
        }
    };

    /**
     * Occurs when a display format has been selected.
     */
    $scope.onDisplayFormatSelected = function (selectedDisplayFormat, skipAnalytics, didDataChange) {
        $scope.data.selectedDisplayFormat = selectedDisplayFormat;

        // Call the outer selection function.
        if (ctrl.onDisplayFormatSelected) {
            ctrl.onDisplayFormatSelected({
                selectedDisplayFormat,
                skipAnalytics,
                didDataChange,
            });
        }
    };

    /**
     * Choose the requested default display format, or the first one, if not already initialized.
     */
    function chooseSelectedDisplayFormat() {
        let selectedFormat = utils.findFirst($scope.data.displayFormats, function (format) {
            return (
                format.apiName === $scope.data.selectedFormatApiName &&
                format.prefix === $scope.data.selectedFormatPrefix &&
                format.postfix === $scope.data.selectedFormatPostfix
            );
        });

        if (!selectedFormat) {
            selectedFormat = $scope.data.defaultDisplayFormat
                ? $scope.data.displayFormatsMap[$scope.data.defaultDisplayFormat]
                : $scope.data.displayFormats[0];
        }

        if (selectedFormat) {
            $scope.onDisplayFormatSelected(selectedFormat, true, false);
        }
    }
}
export default angular
    .module('tonkean.app')
    .controller('DisplayFormatSelectorCtrl', lateConstructController(DisplayFormatSelectorCtrl));
