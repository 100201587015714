import React from 'react';

import { Placeholder } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

interface Props {
    entitiesAmount: number | undefined;
}
const MultiEntitySelectorLoading: React.FC<Props> = ({ entitiesAmount = 0 }) => {
    return (
        <>
            {range(entitiesAmount).map((number) => (
                <Placeholder key={number} $width="100%" $height="47px" />
            ))}
            <Placeholder $width="100%" $height="47px" />
        </>
    );
};

export default React.memo(MultiEntitySelectorLoading);
