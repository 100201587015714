export function KanbanizeModalCtrl(
    $scope,
    $q,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {
        appUri: null,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.projects = [];
        $scope.filteredProjects = [];
        $scope.selectedProjects = state.selectedProjects || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
        $scope.updateProjectsList();
    };

    $scope.$watch('temp.project', function () {
        $scope.addProject($scope.temp.project);
    });

    $scope.connect = function () {
        if ($scope.data.loginModal.$valid) {
            const appUri = $scope.data.appUri;
            const key = $scope.data.key;
            $scope.error = null;
            $scope.loading = true;
            return createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        appUri,
                        apiKey: key,
                    },
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'KANBANIZE',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                    return createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.integration.id,
                        'projects',
                    );
                })
                .then(function (data) {
                    $scope.projects = data.options;
                    $scope.updateProjectsList();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loading = false;
                });
        }
    };

    $scope.updateProjectsList = function () {
        if ($scope.projects.length > 0) {
            const projectsInSelection = new Set(
                $scope.selectedProjects.map(function (s) {
                    return s.project.value;
                }),
            );
            $scope.filteredProjects = $scope.projects.filter(function (r) {
                return !projectsInSelection.has(r.value);
            });
        }
    };

    $scope.addProject = function (project) {
        if (project) {
            const selected = { project };
            $scope.selectedProjects.push(selected);
            $scope.updateProjectsList();
        }
    };

    $scope.removeProject = function ($index) {
        $scope.selectedProjects.splice($index, 1);
        $scope.updateProjectsList();
    };

    $scope.onBoardSelected = function (boardId, value) {
        const option = utils.findFirst($scope.boards, function (item) {
            return item.boardId === boardId;
        });

        if (option) {
            option.selected = value;
        }

        return $q.resolve();
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        $scope.okClicked = true;

        if ($scope.selectedProjects.length === 0) {
            return;
        }

        const s = {
            projects: $scope.projects,
            selectedProjects: $scope.selectedProjects,
            integration: $scope.integration,
        };

        s.integrations = [];
        $scope.selectedProjects.forEach(function (selected) {
            const finalInteg = {
                integration: $scope.integration,
                projectData: { project: selected.project.value },
            };

            s.integrations.push(finalInteg);
        });

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }

        // ////////////////
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;

            $scope.projectIntegration = state.integrations[0];
            $scope.loading = true;
            $scope.error = null;
            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'projects')
                .then(function (data) {
                    $scope.projects = data.options;
                    // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                    if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                        const originalIntegration = state.integrations[0];

                        if (
                            originalIntegration.projectData &&
                            originalIntegration.projectData.projectDatas &&
                            originalIntegration.projectData.projectDatas.length > 0
                        ) {
                            originalIntegration.projectData.projectDatas.forEach(function (projectData) {
                                const project = utils.findFirst($scope.projects, function (p) {
                                    return p.value.projectId === projectData.project.projectId;
                                });
                                const selected = { project };
                                $scope.selectedProjects.push(selected);
                            });
                        }
                        $scope.updateProjectsList();
                    }
                })
                .catch(function (error) {
                    if (error.status === 500) {
                        $scope.changeUser();
                        $scope.error = 'Failed to edit integration. Please re-configure your connection to Kanbanize';
                    } else {
                        $scope.error = error;
                    }
                })
                .finally(function () {
                    $scope.loading = false;
                });

            $scope.connecting = true;
            $scope.error = null;
        }
    }
}
export default angular.module('tonkean.shared').controller('KanbanizeModalCtrl', KanbanizeModalCtrl);
