import React from 'react';

import { RegexViewerBlock } from './RegexViewerBlock';
import type { RegexMatchedGroupPart } from '../entities/RegexMatchedGroupPart';

import { classNames } from '@tonkean/utils';

interface Props {
    showError: boolean;
    matches: RegexMatchedGroupPart[][];
    loading: boolean;
    selectedGroup: { match: number; group: number | undefined } | undefined;
    onGroupSelect: (match: number, group: number | undefined, selected: boolean) => void;
}

/**
 * A react component for showing the groups in a table
 */
const RegexGroupsResult: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    showError,
    loading,
    matches,
    selectedGroup,
    onGroupSelect,
}) => {
    const toggleGroupSelect = (matchIndex: number, groupIndex: number | undefined = undefined) => {
        if (matchIndex === selectedGroup?.match && groupIndex === selectedGroup?.group) {
            onGroupSelect(matchIndex, groupIndex, false);
        } else {
            onGroupSelect(matchIndex, groupIndex, true);
        }
    };

    // I'm using the index as key. It's an anti pattern, but specifiably here it's OK as the index
    // is part of the information - the index is the match name.
    return (
        <RegexViewerBlock
            input={children}
            title="Output Settings"
            loading={loading}
            className="regex-groups-result"
            evaluated
        >
            {!matches.length && showError && (
                <span className="block common-bold common-color-warning margin-top">
                    Your regular expression does not match the subject string.
                </span>
            )}
            {matches.map((match, matchIndex) => (
                <div key={matchIndex} className="regex-match margin-bottom-md">
                    <h5>Match {matchIndex + 1}</h5>
                    <div className="regex-groups">
                        {match.map((group) => (
                            <React.Fragment key={group.index}>
                                <strong
                                    className={classNames(
                                        'regex-name padding-right padding-left margin-top-xs margin-bottom-md margin-right pointer',
                                        selectedGroup?.match === group.matchIndex &&
                                            selectedGroup?.group === group.groupIndex &&
                                            'mod-outline',
                                    )}
                                    style={{ backgroundColor: group.color }}
                                    onClick={() => toggleGroupSelect(group.matchIndex, group.groupIndex)}
                                >
                                    {group.name}
                                </strong>
                                <small className="regex-range margin-top-xs margin-bottom-md margin-right common-color-grey">
                                    {group.startIndex} – {group.endIndex}
                                </small>
                                <p className="regex-value padding-xs margin-top-none margin-bottom">{group.value}</p>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </RegexViewerBlock>
    );
};

export { RegexGroupsResult };
