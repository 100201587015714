import { lateConstructController } from '@tonkean/angular-components';
import { WORKER_TYPES } from '@tonkean/constants';

/* @ngInject */
const EditIntegrationButtonCtrl = function ($scope, projectManager, projectIntegrationManager) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
    };

    /**
     * Initializes the state object for tnkIntegrationsGroup directive to use.
     */
    function initializeStateObjectForAllProjectIntegrations() {
        $scope.projectIntegrationToStateMap = {};
        $scope.data.projectIntegrationMap = {};

        const projectIntegration = $scope.data.projectIntegration;

        if (!$scope.projectIntegrationToStateMap[projectIntegration.id]) {
            $scope.projectIntegrationToStateMap[projectIntegration.id] = {
                state: {},
            };

            $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                projectIntegration.integration.integrationType.toLowerCase()
            ] = {
                integrations: [projectIntegration],
            };
        } else {
            $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                projectIntegration.integration.integrationType.toLowerCase()
            ].integrations[0].displayName = projectIntegration.displayName;
            $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                projectIntegration.integration.integrationType.toLowerCase()
            ].integrations[0].disabled = projectIntegration.disabled;
        }

        $scope.data.projectIntegrationMap[projectIntegration.id] = projectIntegration;
    }

    /**
     * Initializes the state object for groups project integrations.
     */
    function initializeStateObjectForAllGroupsProjectIntegrations() {
        $scope.groupsProjectIntegrationToStateMap = {};
        $scope.data.groupsProjectIntegrationMap = {};
        $scope.data.hideGroupProjectIntegrations = true;

        // Filter modules which were never published
        const groups = projectManager.groups.filter(
            (group) => group.workerType !== WORKER_TYPES.UNINITIALIZED.key,
        );

        for (const group of groups) {
            $scope.groupsProjectIntegrationToStateMap[group.id] = {};
            $scope.data.groupsProjectIntegrationMap[group.id] = {};

            if (group.groupProjectIntegrations) {
                for (
                    let groupProjectIntegratrionIndex = 0;
                    groupProjectIntegratrionIndex < group.groupProjectIntegrations.length;
                    groupProjectIntegratrionIndex++
                ) {
                    const projectIntegration = group.groupProjectIntegrations[groupProjectIntegratrionIndex];
                    $scope.data.hideGroupProjectIntegrations = false;
                    if (!$scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id]) {
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id] = {
                            state: {},
                        };

                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ] = {
                            integrations: [projectIntegration],
                        };
                    } else {
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ].integrations[0].displayName = projectIntegration.displayName;
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ].integrations[0].disabled = projectIntegration.disabled;
                    }

                    $scope.data.groupsProjectIntegrationMap[group.id][projectIntegration.id] = projectIntegration;
                }
            }
        }
    }

    ctrl.$onInit = function () {
        initializeStateObjectForAllProjectIntegrations();
        initializeStateObjectForAllGroupsProjectIntegrations();
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.projectIntegration) {
            $scope.data.projectIntegration = changesObj.projectIntegration.currentValue;
            ctrl.$onInit();
        }
    };

    $scope.onIntegrationSaved = function (changedProjectIntegration) {
        if (changedProjectIntegration?.projectIntegration) {
            $scope.data.projectIntegration = changedProjectIntegration.projectIntegration;
            // Update the project cached integration.
            projectIntegrationManager.updateCachedProjectIntegration(
                projectManager.project,
                changedProjectIntegration.projectIntegration,
            );

            initializeStateObjectForAllProjectIntegrations();
            initializeStateObjectForAllGroupsProjectIntegrations();

            if (ctrl.onProjectIntegrationUpdated) {
                ctrl.onProjectIntegrationUpdated({
                    updatedProjectIntegration: changedProjectIntegration.projectIntegration,
                });
            }
        }
    };
};

export default angular
    .module('tonkean.app')
    .controller('EditIntegrationButtonCtrl', lateConstructController(EditIntegrationButtonCtrl));
