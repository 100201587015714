<div class="common-size-xxs show-edit-when-hover-visibility">
    <p class="common-break-long">
        <span ng-switch="item.type">
            <span ng-switch-when="INITIATIVE_DUE_DATE_CHANGED">
                {{ item.metadata.newDueDate ? (item.metadata.newDueDate | date) : 'None' }}
            </span>
            <span ng-switch-when="INITIATIVE_LEADER_CHANGED">
                Owner: {{ item.reference3 ? item.reference3.name : 'None' }}
            </span>
            <span ng-switch-default></span>
        </span>
    </p>

    <!--<hr ng-if="item.metadata.newTicketUrl" class="margin-normal-sm-v"/>-->

    <span ng-switch="item.type" class="common-size-xxxxs common-color-grey">
        <span ng-switch-when="INITIATIVE_DUE_DATE_CHANGED">
            Was: {{ item.metadata.oldDueDate ? (item.metadata.oldDueDate | date) : 'None' }}
        </span>
        <span ng-switch-default></span>
    </span>
</div>
