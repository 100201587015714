import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class HourFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.HOUR;
    override readonly sign = 'Hour';
    override readonly displayName = 'Hour';
    override readonly description = 'Will get the hour of a date in a specific timezone.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        timeZoneField(),
    ];
}
