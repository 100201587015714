export enum OutlookAuthenticationType {
    USER = 'authorization_code',
    APPLICATION = 'client_credentials',
}

export const authTypeToDisplayName: Record<OutlookAuthenticationType, string> = {
    [OutlookAuthenticationType.USER]: 'User Authentication',
    [OutlookAuthenticationType.APPLICATION]: 'Application Authentication',
};

export default OutlookAuthenticationType;
