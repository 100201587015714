import type { KeyGenerator, KeyingConfig } from '@urql/exchange-graphcache';
import type { IntrospectionQuery } from 'graphql';

import { memoize } from '@tonkean/utils';

/**
 * This tells urql that entities that extends the workflow version entity base interface, their unique id is not the
 * id field, but a combination between id and workflow version id.
 */
function getTonkeanGraphqlCacheUniqueKeys(schema: IntrospectionQuery): KeyingConfig {
    const cacheUniqueKeysEntries: [string, KeyGenerator][] = schema.__schema.types
        .filter(
            (type) =>
                type.kind === 'OBJECT' &&
                type.interfaces.some(
                    (singleInterface) =>
                        singleInterface.name.toUpperCase() === 'GraphqlWorkflowVersionEntityBase'.toUpperCase(),
                ),
        )
        .map((object) => [
            object.name,
            (workflowVersionEntity) => {
                if (!workflowVersionEntity.id) {
                    return null;
                }

                const workflowVersionId =
                    workflowVersionEntity.workflowVersionId ?? workflowVersionEntity.workflowVersion?.['id'];
                if (!workflowVersionId) {
                    const error = new Error(
                        `You requested an ${object.name} with the id ${workflowVersionEntity.id} and it was returned without it's workflow version id, which is required to create a unique id for caching.`,
                    );
                    console.error(error);
                    throw error;
                }

                return `${workflowVersionId}_${workflowVersionEntity.id}`;
            },
        ]);

    // keyless types are types of objects that cannot be normalized in the cache because they have no unique identifier.
    // Such objects are any object that does not implement the 'GraphqlEntityBase' interface such as 'TonkeanExpressionDefinition'.
    const keylessTypes: [string, KeyGenerator][] = schema.__schema.types
        .filter(
            (type) =>
                type.kind === 'OBJECT' &&
                type.interfaces.every(
                    (singleInterface) => singleInterface.name.toUpperCase() !== 'GraphqlEntityBase'.toUpperCase(),
                ),
        )
        .map((type) => [type.name, () => null]);

    return Object.fromEntries([...cacheUniqueKeysEntries, ...keylessTypes]);
}

export default memoize(getTonkeanGraphqlCacheUniqueKeys);
