import { angularToReact } from '@tonkean/angular-components';
import type { Integration, ProjectIntegration } from '@tonkean/tonkean-entities';

const MicrosoftTeamsAppSelection = angularToReact<{
    integration: Integration;
    projectIntegration: ProjectIntegration;
    onApplicationConnectedToTeam: (projectData: any, isDisabled: boolean) => void;
}>('tnk-microsoft-teams-app-team-selection', { onApplicationConnectedToTeam: ['projectData', 'isDisabled'] });

export default MicrosoftTeamsAppSelection;
