import TextExtractorLogicConfiguration from './TextExtratorLogicConfiguration';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTextExtractorLogicConfiguration',
        reactToAngular(TextExtractorLogicConfiguration, [
            'groupId',
            'workflowVersionId',
            'configuredLogic',
            'previewEvaluationSource',
            'validationObject',
            'onChange',
        ]),
    );
