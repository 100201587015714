import React, { useMemo } from 'react';
import styled from 'styled-components';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { WebhookPayload } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const OptionContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`;

interface Props {
    webhookPayloads: WebhookPayload[] | undefined;
    isLoading: boolean;
    selectedWebhookPayloadId: string | undefined;
    onWebhookPayloadSelected: (payloadId: string) => void;
}

const ProjectIntegrationEntityLiveStreamWebhookPayloadSelector: React.FC<Props> = ({
    webhookPayloads,
    isLoading,
    selectedWebhookPayloadId,
    onWebhookPayloadSelected,
}) => {
    const webhookPayloadOptions = useMemo(() => {
        if (!webhookPayloads) {
            return [];
        }

        return webhookPayloads.map((webhookPayload) => {
            const formattedDate = utils.formatDate(webhookPayload.receiveTime, true, true, true, false);
            return {
                label: <OptionContainer>{`${formattedDate} [ ${webhookPayload.id} ] `}</OptionContainer>,
                value: webhookPayload.id,
            };
        });
    }, [webhookPayloads]);

    return (
        <SimpleSelect
            isDisabled={!webhookPayloads || webhookPayloads.length === 0}
            placeholder={isLoading ? 'Loading webhook payloads...' : 'Pick webhook payload...'}
            value={selectedWebhookPayloadId}
            isLoading={isLoading}
            options={webhookPayloadOptions}
            onChange={onWebhookPayloadSelected}
            menuPosition="fixed"
            thin
        />
    );
};

export default ProjectIntegrationEntityLiveStreamWebhookPayloadSelector;
