import { useAngularService } from 'angulareact';
import { useEffect, useState } from 'react';

import { filterServerErrors } from '../utils/formServerErrors';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { usePrevious } from '@tonkean/infrastructure';

function useUpdateWorkerForm(
    setLoadingSectionsToSaved: () => void,
    setLoadingSectionsToError: () => void,
    onFormQuestionTypeChanged?: () => void,
) {
    const formManager = useAngularService('formManager');
    const $rootScope = useAngularService('$rootScope');

    const [{ data, error }, updateWorkerForm] = useLazyAsyncMethod(formManager, 'updateWorkerForm');

    const prevQuestionType = usePrevious(data?.formQuestionType, true);
    const [currentError, setCurrentError] = useState<string | undefined>();

    /* Store form from updateWorkerForm in state */
    useEffect(() => {
        if (data) {
            setCurrentError(undefined);

            setLoadingSectionsToSaved();
        }
    }, [$rootScope, data, setLoadingSectionsToSaved]);

    /**
     * Call onFormQuestionTypeChanged when form question changes.
     *
     * @see {@link https://tonkean.atlassian.net/browse/TNKN-6824}
     * */
    useEffect(() => {
        if (data && prevQuestionType && prevQuestionType !== data?.formQuestionType) {
            onFormQuestionTypeChanged?.();
        }
    }, [data, onFormQuestionTypeChanged, prevQuestionType]);

    /* Show alert when updateWorkerForm fails */
    useEffect(() => {
        if (error) {
            setCurrentError(error.data.error.message);
            setLoadingSectionsToError();

            if (filterServerErrors.includes(error.data.error.message)) {
                return;
            }

            const errorMessage =
                error.status === 409
                    ? `Failed saving form configuration. ${error.data.error.message}`
                    : 'Failed to update form';

            $rootScope.$emit('alert', {
                msg: errorMessage,
                type: 'danger',
            });
        }
    }, [$rootScope, error, setLoadingSectionsToError]);

    return {
        updateWorkerForm,
        currentError,
    };
}

export default useUpdateWorkerForm;
