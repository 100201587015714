import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import FormBuilderConfiguration from './FormBuilderConfiguration/FormBuilderConfiguration';
import FormBuilderDisplayNameBar from './FormBuilderDisplayNameBar';
import FormBuilderDuplicateFormModal from './FormBuilderDuplicateFormModal';
import FormBuilderPreview from './FormBuilderPreview';
import FormBuilderPreviewOn from './FormBuilderPreviewOn';
import { ReactComponent as DuplicateFormIcon } from '../../../../images/icons/duplicate-new.svg';
import { ReactComponent as TrashFormIcon } from '../../../../images/icons/trash-form.svg';
import useEnvironmentFlags from '../../../infrastructure/hooks/useEnvironmentFlags';
import FormBuilderPreviewType from '../entities/FormBuilderPreviewType';
import FormBuilderTabLabel from '../entities/FormBuilderTabLabel';
import useCloseForm from '../hooks/useCloseForm';
import useCreateWorkerForm from '../hooks/useCreateWorkerForm';
import useDeleteWorkerFormCallback from '../hooks/useDeleteWorkerFormCallback';
import useDuplicateWorkerFormCallback from '../hooks/useDuplicateWorkerFormCallback';
import useEditedSections from '../hooks/useEditedSections';
import useGetWorkerForm from '../hooks/useGetWorkerForm';
import useSaveForm from '../hooks/useSaveForm';
import useUpdateWorkerForm from '../hooks/useUpdateWorkerForm';
import getInitialForm from '../utils/getInitialForm';

import { FormBuilderContext } from '@tonkean/infrastructure';
import { KebabMenuButton, Menu, MenuItem, Tab, Tabs, TabsBorderType } from '@tonkean/infrastructure';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import type { FormType } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;

    display: grid;
    grid-template-columns: 502px 1fr auto;
    grid-template-rows: 60px 46px 1fr;
    grid-template-areas:
        'header header actions'
        'subheader subheader subheader'
        'configuration preview preview';

    ${childrenStyledFocus}
`;

const FormSubHeader = styled.div`
    box-shadow: 0 3px 3px rgb(0, 0, 0, 0.1);

    grid-area: subheader;

    display: flex;
`;

const TabsWrapper = styled.div`
    @media screen and (max-width: 1366px) {
        max-width: 502px;
        width: 502px;
    }

    max-width: 512px;
    width: 512px;

    padding: 0 30px;
`;

const FormActionsWrapper = styled.div`
    grid-area: actions;

    border-bottom: 1px solid ${Theme.colors.gray_300};

    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Cancel = styled(Button)`
    margin-right: 8px;
`;

const UnpublishedFormNoteText = styled(Wrapper)`
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    color: #8a6d3b;
    text-align: center;
    padding: 15px;
    margin: 54px 15px 18px;
    border-radius: 4px;
    width: 500px;
    height: 50px;
    align-self: center;
`;

interface Props {
    formId?: string;
    groupId: string;
    workflowVersionId: string;
    formType: FormType;
    onFormUnselected: () => void;
    onFormSelected: (formId: string, formType: FormType) => void;
}

const FormBuilder: React.FC<Props> = ({
    workflowVersionId,
    formId,
    groupId,
    formType,
    onFormUnselected,
    onFormSelected,
}) => {
    const formManager = useAngularService('formManager');
    const projectManager = useAngularService('projectManager');
    const [form, setForm] = useState(() =>
        getInitialForm(formId, formType, formManager, workflowVersionId, projectManager.project.themeConfiguration),
    );

    const { editedSections, setLoadingToSaved, setLoadingToError, setSectionLoading } = useEditedSections(form?.id);

    const [selectedTab, setSelectedTab] = useState(FormBuilderTabLabel.SETTINGS);
    const [formPreviewType, setFormPreviewType] = useState(FormBuilderPreviewType.Web);
    const [moreActionsMenuOpen, setMoreActionsMenuOpen] = useState<boolean>(false);

    /**
     * key is used to un-mount and remount form editor.
     * The angularKey changes every time the form is saved,
     * and is used to make sure the preview and the form editor stay in sync.
     * The error occurs when changing a form's question type from `UPDATE_FIELDS` to `COLLECT_INNER_ITEMS` or vice versa.
     *
     * @see {@link https://tonkean.atlassian.net/browse/TNKN-6824}
     * */
    const [angularKey, setAngularKey] = useState(utils.guid());

    const onFormQuestionTypeChanged = useCallback(() => {
        setAngularKey(utils.guid());
    }, []);

    useEffect(() => {
        setAngularKey(utils.guid());
    }, [form?.id]);

    /**
     * TODO: Fetch form once and drill it down as prop.
     * The existing angular form editor (tnkWorkerFormEditor) fetches the form from provided bindings.
     * In order to maintain backwards compatibility until we refactor it,
     * we must alert it to re-fetch the form if changes were made in the React part.
     */
    const [angularEditorFetchFormTrigger, setAngularEditorFetchFormTrigger] = useState<() => void>();

    const { getWorkerForm, getWorkerFormLoading } = useGetWorkerForm({
        formId,
        workflowVersionId,
        formType,
        setForm,
        angularEditorFetchFormTrigger,
        setSelectedTab,
    });
    const { createWorkerForm, createWorkerFormLoading } = useCreateWorkerForm();
    const { updateWorkerForm, currentError } = useUpdateWorkerForm(
        setLoadingToSaved,
        setLoadingToError,
        onFormQuestionTypeChanged,
    );

    useSaveForm({
        formId,
        form,
        updateWorkerForm,
        groupId,
        createWorkerForm,
    });

    const { isDraft } = useEnvironmentFlags();

    const deleteForm = useDeleteWorkerFormCallback(form, groupId, onFormUnselected);
    const [duplicateForm, duplicateFormLoading] = useDuplicateWorkerFormCallback({
        form,
        workflowVersionId,
        groupId,
        onFormSelected,
    });
    const onCloseForm = useCloseForm(form, onFormUnselected);

    const [showDuplicateFormModal, setShowDuplicateFormModal] = useState<boolean>(false);

    const openDuplicateFormModal = useCallback(() => {
        if (form?.slackCommand) {
            setShowDuplicateFormModal(true);
        } else {
            duplicateForm();
        }
    }, [duplicateForm, form?.slackCommand]);

    /**
     * The form is editable as long as it is not being fetched, created or has no id.
     */
    const isEditable = !getWorkerFormLoading && !createWorkerFormLoading && !duplicateFormLoading && !!form?.id;

    const contextValue = useMemo(() => {
        return { editedSections, setSectionLoading, isEditable, currentError };
    }, [editedSections, isEditable, setSectionLoading, currentError]);

    return (
        <FormBuilderContext.Provider value={contextValue}>
            <Wrapper data-automation="form-builder-new-form-page">
                <FormBuilderDisplayNameBar
                    form={form}
                    onFormChanged={setForm}
                    formType={formType ?? form?.formType}
                    workflowVersionId={workflowVersionId}
                />
                <FormActionsWrapper>
                    <Cancel data-automation="form-builder-close-button" onClick={onCloseForm} cancel>
                        Close
                    </Cancel>
                    {form && !!form.id && isDraft && (
                        <Menu
                            show={moreActionsMenuOpen}
                            onClose={() => setMoreActionsMenuOpen(false)}
                            menuItems={
                                <>
                                    <MenuItem
                                        data-automation="form-builder-duplicate-form-button"
                                        key="Duplicate"
                                        onClick={openDuplicateFormModal}
                                        icon={
                                            <span className="tnk-icon">
                                                <DuplicateFormIcon />
                                            </span>
                                        }
                                    >
                                        Duplicate
                                    </MenuItem>
                                    <MenuItem
                                        data-automation="form-builder-delete-form-button"
                                        key="Delete"
                                        onClick={deleteForm}
                                        icon={
                                            <span className="tnk-icon">
                                                <TrashFormIcon />
                                            </span>
                                        }
                                    >
                                        Delete
                                    </MenuItem>
                                </>
                            }
                        >
                            <KebabMenuButton
                                data-automation="form-builder-3-dots-settings-button"
                                onClick={() => setMoreActionsMenuOpen(true)}
                                aria-label="More actions menu trigger"
                                flat
                            />
                        </Menu>
                    )}
                </FormActionsWrapper>
                {form ? (
                    <>
                        <FormSubHeader>
                            <TabsWrapper>
                                <Tabs
                                    value={selectedTab}
                                    onChange={setSelectedTab}
                                    borderType={TabsBorderType.NONE}
                                    height="46px"
                                >
                                    <Tab
                                        dataAutomation="form-builder-settings-tab"
                                        label={FormBuilderTabLabel.SETTINGS}
                                        width="20%"
                                    >
                                        Settings
                                    </Tab>
                                    <Tab
                                        dataAutomation="form-builder-builder-tab"
                                        label={FormBuilderTabLabel.BUILDER}
                                        disabled={form.formQuestionType === FormQuestionType.UPLOAD_FILES}
                                        width="20%"
                                    >
                                        Builder
                                    </Tab>
                                </Tabs>
                            </TabsWrapper>
                            <FormBuilderPreviewOn
                                selectedPreviewType={formPreviewType}
                                onPreviewTypeSelected={setFormPreviewType}
                                form={form}
                                workflowVersionId={workflowVersionId}
                                groupId={groupId}
                            />
                        </FormSubHeader>
                        <FormBuilderConfiguration
                            form={form}
                            setForm={setForm}
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            formId={formId}
                            selectedTab={selectedTab}
                            getWorkerForm={getWorkerForm}
                            setAngularEditorFetchFormTrigger={setAngularEditorFetchFormTrigger}
                            angularKey={angularKey}
                        />
                        <FormBuilderPreview
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            formPreviewType={formPreviewType}
                            form={form}
                        />
                    </>
                ) : (
                    <UnpublishedFormNoteText>Form was not published, and cannot be shown.</UnpublishedFormNoteText>
                )}
                {showDuplicateFormModal && (
                    <FormBuilderDuplicateFormModal
                        groupId={groupId}
                        onClose={() => setShowDuplicateFormModal(false)}
                        onDuplicate={duplicateForm}
                        open
                    />
                )}
            </Wrapper>
        </FormBuilderContext.Provider>
    );
};

export default FormBuilder;
