<div>
    <div ng-if="!user.styledGroupTags" class="avatar-main" ng-class="extraClasses">
        <!-- Avatar Image -->
        <img
            ng-if="user && !isDefaultImage"
            ng-src="{{ user.avatarUri }}"
            tnk-img-events
            hide-on-error
            class="avatar-img {{ square ? 'img-rounded' : 'img-circle' }}"
            data-automation="avatar-icon"
        />
        <!-- Initials Avatar -->
        <!-- Keeping the modSecondary in condition for backwards compatibility -->
        <div
            ng-if="user && getUserOrGroupName() && isDefaultImage"
            ng-class="defaultUserExtraClass"
            class="avatar-initials mod-{{ modSecondary ? 'secondary' : mod ? mod : 'primary' }}"
        >
            <span class="avatar-initials-text" data-automation="avatar-icon" ng-bind="user.initials"></span>
        </div>

        <!-- Generic Avatar Image -->
        <span
            ng-if="!user || (!getUserOrGroupName() && isDefaultImage)"
            class="avatar-default"
            ng-class="defaultUserExtraClass"
        >
            <tnk-icon data-automation="avatar-icon" src="../../../../../apps/tracks/images/icons/avatar.svg"></tnk-icon>
        </span>
    </div>
    <!-- user is actually a group in an only groups context -->
    <div ng-if="user.styledGroupTags" class="avatar-main" ng-class="extraClasses" ng-style="{ marginRight: '6px' }">
        <!-- Group tag with group icon -->
        <span class="avatar-default" ng-style="{ marginLeft: '6px' }">
            <img
                ng-if="!isEmpty()"
                data-automation="avatar-icon"
                ng-style="{ width: '14px' }"
                src="/images/icons/user-group.svg"
            />
            <img
                ng-if="isEmpty()"
                data-automation="avatar-icon"
                ng-style="{ width: '12px', paddingLeft: '1px', paddingRight: '1px' }"
                src="/images/icons/error-warning.svg"
            />
        </span>
    </div>
</div>
