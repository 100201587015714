import template from './tnkManualNextStepsLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkManualNextStepsLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'ManualNextStepsLogicConfigurationCtrl',
});
