import { atom } from 'recoil';

import type { WorkerRun } from '@tonkean/tonkean-entities';

const initialWorkerRunForDrillState = atom<WorkerRun | undefined>({
    key: 'initialWorkerRunForDrillState',
    default: undefined,
});

export default initialWorkerRunForDrillState;
