import { getStateError } from '@tonkean/utils';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import lateConstructController from '../../utils/lateConstructController';
import { analyticsWrapper } from '@tonkean/analytics';
import { SPECIAL_FIELD_KEYS, DUMMY_TRACK_TITLE } from '@tonkean/constants';

/* @ngInject */
function CollectInnerItemsFormCtrl(
    $scope,
    $stateParams,
    $rootScope,
    $state,
    trackHelper,
    projectManager,
    utils,
    customFieldsManager,
    workflowVersionManager,
    authenticationService,
    tonkeanService,
    groupPermissions,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        initiativesPerPage: 100,
        currentPage: 1,
        hasMorePages: true,
        initiatives: [],
        formConfig: ctrl.formConfig,
        collectionFormConfig: ctrl.collectionFormConfig,
        formQuestion: getFormQuestion(ctrl.collectionFormConfig || ctrl.formConfig),
        formQuestionToDisplay: ctrl.formConfig?.definition?.formQuestion,
        minimumItemsAmount: ctrl.formConfig?.definition?.minimumItemsAmount || 0,
        workflowVersionType: ctrl.workflowVersionType,
        workflowVersionId: ctrl.workflowVersionId,
        formFinished: ctrl.formFinished,
        groupId: ctrl.groupId,
        sessionId: ctrl.sessionId,
        fieldsIds: ctrl.formConfig?.definition?.fields?.map((item) => item.fieldDefinitionIdentifier),
        fieldsNames: [], // Is used for the import modal to filter only fields that in the form
        customTriggerId: ctrl.customTriggerId,
        initiativeId: ctrl.initiativeId,
        workerRunId: ctrl.workerRunId,
        primaryColor: ctrl.primaryColor,
        secondaryColor: ctrl.secondaryColor,
        buttonsColor: ctrl.buttonsColor,
        versionType: $stateParams.formVersionType,
        loading: false,
        onSubmit: ctrl.onSubmit,
        onError: ctrl.onError,
        loadingForm: false,
        questionInitiative: undefined,
        itemsValidationMap: ctrl.itemsValidationMap || {},
        allErrorsNotRestrictingErrors: false,
        showItemsAmountError: false,
        showEmptyTitleError: false,
        fieldIdsToFieldNames: {},
        error: '',
        solutionBusinessReportId: ctrl.solutionBusinessReportId,
        isGroupCollaborator: false,
        showBack: ctrl.showBack,
        backLoading: false,
    };

    ctrl.$onInit = function () {
        $scope.data.error = '';
        $scope.data.currentPage = 1;
        $scope.data.hasMorePages = true;
        $scope.data.isGroupCollaborator = groupPermissions.isCurrentUserGroupCollaborator(
            $scope.pm.groupsMap[$scope.data.groupId],
        );

        loadFormInitiatives().then(() => {
            const pageLoadTimestamp = $rootScope.pageLoadTime;
            const now = Date.now();

            // We want to make sure we only log this event once
            if ($rootScope.formLoadEventSubmitted) return;

            analyticsWrapper.track('form-loaded', {
                formId: $scope.data.formConfig.id,
                groupId: $scope.data.formConfig.group.id,
                formLoadTime: now - pageLoadTimestamp,
                formType: $scope.data.formConfig.formType,
                formQuestionType: $scope.data.formConfig.formQuestionType,
            });

            $rootScope.formLoadEventSubmitted = true;
        });

        // Extract field names and save them
        ctrl.formConfig?.definition?.fields.forEach((field) => {
            const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
                ctrl.collectionFormConfig?.workflowVersion.id,
                field.fieldDefinitionIdentifier,
            );

            if (fieldDefinition === null) {
                return;
            }

            $scope.data.fieldsNames.push(fieldDefinition.name);
            // Can't import status or tags from csv
            if (![SPECIAL_FIELD_KEYS.status, SPECIAL_FIELD_KEYS.tags].includes(field.fieldDefinitionIdentifier)) {
                $scope.data.fieldIdsToFieldNames[field.fieldDefinitionIdentifier] =
                    field.displayName || fieldDefinition.fieldLabel || fieldDefinition.name;
            }
        });
    };

    ctrl.$onChanges = function (changesObj) {
        [
            'initiatives',
            'formConfig',
            'collectionFormConfig',
            'formQuestion',
            'workflowVersionType',
            'workflowVersionId',
            'groupId',
            'customTriggerId',
            'initiativeId',
            'workerRunId',
            'allErrorsNotRestrictingErrors',
        ].forEach((variable) => {
            if (!!changesObj[variable]) {
                $scope.data[variable] = changesObj[variable].currentValue;
            }
        });

        // Extract field IDs and names
        if (changesObj.fields) {
            $scope.data.fieldsIds = changesObj.fields.currentValue?.map((item) => item.fieldDefinitionIdentifier);
            $scope.data.fieldsNames = changesObj.fields.currentValue?.map((item) => item.name);
        }

        if (changesObj.itemsValidationMap) {
            $scope.data.itemsValidationMap = ctrl.itemsValidationMap || {};
        }
    };

    $scope.handleBackClicked = function () {
        $scope.data.allErrorsNotRestrictingErrors = false;
        $scope.data.backLoading = true;
        ctrl.onBackClicked();
    };

    function getFormQuestion(formConfig) {
        return formConfig?.definition?.useAutoGeneratedFormQuestion || formConfig?.definition?.formQuestion === ''
            ? `${formConfig?.displayName} - Collect Inner Items Question`
            : formConfig?.definition?.formQuestion;
    }

    $scope.onCsvMappingCreated = async (initiativesData) => {
        const initiatives = await Promise.all(
            initiativesData.map(async (initiativeToImport) => {
                const initiative = {};

                // Special fields handling
                initiative.title =
                    initiativeToImport[SPECIAL_FIELD_KEYS.title] ||
                    trackHelper.autoGenerateTitle(
                        authenticationService.currentUser.name,
                        $scope.data.formQuestionToDisplay,
                    );

                try {
                    const date = Date.parse(initiativeToImport[SPECIAL_FIELD_KEYS.dueDate]);
                    if (date) {
                        initiative.dueDate = date.getTime();
                    }
                } catch (error) {
                    console.log(error);
                    console.log("can't parse given due date to date - skipping this column");
                }

                if (initiativeToImport[SPECIAL_FIELD_KEYS.ownerId]) {
                    try {
                        const { people } = await tonkeanService.getTeamMembers(
                            projectManager.project.id,
                            { group: $scope.data.groupId },
                            initiativeToImport[SPECIAL_FIELD_KEYS.ownerId],
                            1,
                        );
                        if (people && people[0]) {
                            initiative.owner = people[0].id;
                        }
                    } catch (error) {
                        console.log(error);
                        console.log("can't get person to use as owner - skipping this column");
                    }
                }

                // Created in collect inner items form fields
                initiative.inEditMode = true;
                initiative.createdByFormId = $scope.data.collectionFormConfig.id;
                initiative.parentAlreadyExists = true;
                initiative.parentId = $scope.data.questionInitiative.id;
                initiative.groupId = $scope.data.groupId;
                // When getting here through business report the state param is env (or not existing) and not formVersionType
                const workflowVersionType =
                    $stateParams.formVersionType || $stateParams.env || WorkflowVersionType.PUBLISHED;
                if (workflowVersionType.toLowerCase() === 'DRAFT'.toLowerCase()) {
                    initiative.isDraftInitiative = true;
                }

                // Custom fields
                initiative.fields = Object.entries(initiativeToImport)
                    .filter(([fieldId]) => !Object.values(SPECIAL_FIELD_KEYS).includes(fieldId))
                    .map(([fieldId, fieldValue]) => ({
                        fieldDefinitionId: fieldId,
                        value: fieldValue,
                    }));

                return initiative;
            }),
        );

        return tonkeanService.createMultipleInitiatives($scope.data.groupId, initiatives).then((data) => {
            if (data && data.entities) {
                // return count of successful
                return data.entities.length;
            }
        });
    };

    function loadPage(page) {
        const baseObj = {};
        const { initiativeId, workflowVersionId, collectionFormConfig, hasMorePages } = $scope.data;
        if (
            initiativeId &&
            workflowVersionId &&
            collectionFormConfig.id &&
            hasMorePages &&
            $scope.data.isGroupCollaborator
        ) {
            return trackHelper
                .getDuplicatedFormInitiatives(
                    $scope.data.collectionFormConfig.workflowVersion.id,
                    $scope.data.collectionFormConfig.id,
                    $scope.data.initiativeId,
                    true,
                    true,
                    true,
                    false,
                    (page - 1) * $scope.data.initiativesPerPage,
                    $scope.data.initiativesPerPage,
                )
                .then((result) => {
                    // If we dont have any existing initiatives it means we need to create the question initiative.
                    if (!result.data.entities.length) {
                        const newItemFormQuestion = getFormQuestion($scope.data.collectionFormConfig);

                        return trackHelper.createInitiative(
                            baseObj,
                            projectManager.project.id,
                            $scope.data.initiativeId,
                            newItemFormQuestion,
                            null,
                            null,
                            null,
                            null,
                            null,
                            $scope.data.groupId,
                            onCreateInitiativeSuccess,
                            null,
                            onCreateInitiativeError,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            $scope.data.collectionFormConfig.id,
                            $scope.data.collectionFormConfig.displayName,
                            $scope.data.customTriggerId,
                            $scope.data.workerRunId,
                            true,
                            null,
                            true,
                            $state.current.name === 'product.processContributorSolutionBusinessReport',
                            $scope.data.solutionBusinessReportId,
                        );
                    }

                    // Finding the initiatives that need to be added to the list
                    // (the ones that are not already in the list)
                    const initiativeIds = new Set($scope.data.initiatives.map(({ id }) => id));
                    const initiativesToAdd = result.data.entities.filter((initiative) => {
                        const isQuestionInitiative = initiative.title === $scope.data.formQuestion;
                        const alreadyDisplayed = initiativeIds.has(initiative.id);

                        return !isQuestionInitiative && !alreadyDisplayed;
                    });

                    validateItemsAmountError(result.initiatives);

                    // finding dummy item
                    const dummyItemIndex = $scope.data.initiatives.findIndex(
                        (initiative) => initiative.title === DUMMY_TRACK_TITLE,
                    );

                    if (dummyItemIndex !== -1) {
                        // adding dummy item to the end of the list
                        initiativesToAdd.push($scope.data.initiatives[dummyItemIndex]);
                        // slicing out original dummy item
                        $scope.data.initiatives.splice(dummyItemIndex, 1);
                    }

                    initiativesToAdd.forEach((initiative) => $scope.data.initiatives.push(initiative));
                    $scope.data.hasMorePages = result.data.hasMorePages;

                    $scope.data.questionInitiative = utils.findFirstCompareProperties(
                        result.initiatives,
                        { title: $scope.data.formQuestion },
                        'title',
                    );
                })
                .then(reIndexDummyItemInServer)
                .catch((error) => {
                    $scope.data.error = 'something went wrong';
                    console.log(error);
                })
                .finally(finishSubmitLoading);
        } else {
            return Promise.resolve();
        }
    }

    function loadFormInitiatives(page = 1) {
        startSubmitLoading();
        return loadPage(page).finally(finishSubmitLoading);
    }

    function validateItemsAmountError(initiatives) {
        const initiativesToCheck = $scope.data.initiatives?.length > 0 ? $scope.data.initiatives : initiatives;
        const realInitiatives = initiativesToCheck?.filter((initiative) => initiative.title !== DUMMY_TRACK_TITLE);

        $scope.data.showItemsAmountError = realInitiatives?.length < $scope.data.minimumItemsAmount;
    }

    $scope.onInitiativeCreated = function (initiative, addUnderItemId) {
        validateItemsAmountError();
        $scope.data.showEmptyTitleError = false;

        if (!utils.findFirstCompareProperties($scope.data.initiatives, initiative, 'id')) {
            const index = !addUnderItemId
                ? $scope.data.initiatives.length
                : utils.findIndexCompareProperties($scope.data.initiatives, { id: addUnderItemId }, 'id') + 1;
            $scope.data.initiatives.splice(index, 0, initiative);
        }
    };

    $scope.onTrackRemoved = function (removedTrack) {
        $scope.data.initiatives = $scope.data.initiatives.filter((initiative) => initiative.id !== removedTrack.id);
        validateItemsAmountError();
    };

    $scope.submitForm = function (ignoreNonRestrictingErrors) {
        /**
         * Before submitting the form we must archive the dummy item.
         * We can find it based on it's unique title.
         *
         * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
         */
        const dummyItem = $scope.data.initiatives.find((initiative) => initiative.title === DUMMY_TRACK_TITLE);

        const validateFormAppSidePromise = workflowVersionManager
            .getCachedWorkflowVersionOrGetFromServer($scope.data.workflowVersionId)
            .then((workflowVersion) => {
                const anyFilledFieldsOnDummy = $scope.data.fieldsIds
                    // If the title is "filled" it becomes not a dummy so we have to filter this out
                    .filter((fieldKey) => fieldKey !== 'TNK_TITLE')
                    .some((fieldId) => {
                        const fieldValue = customFieldsManager.getFieldValue(dummyItem, fieldId, workflowVersion);
                        // Make sure the field has no value or its empty, .length covers both strings and arrays
                        return fieldValue !== null && fieldValue !== undefined && fieldValue.length !== 0;
                    });

                if (anyFilledFieldsOnDummy) {
                    // Return rejected promise for method consistency
                    $scope.data.showEmptyTitleError = true;
                    return Promise.reject({ expectedError: true, msg: 'There are filled fields on the dummy item' });
                }
            });

        // Call parent callback
        return validateFormAppSidePromise
            .then(() =>
                trackHelper.updateInitiativeArchiveState(
                    dummyItem.id,
                    true,
                    $state.current.name === 'product.processContributorSolutionBusinessReport',
                    $scope.data.solutionBusinessReportId,
                ),
            )
            .then(() =>
                $scope.data.onSubmit({
                    fields: [],
                    ignoreNonRestrictingErrors,
                }),
            )
            .then(() => {
                $scope.data.formFinished = true;
            })
            .catch((error) => {
                if (error.expectedError) {
                    // if this was app side validation error keep bubbling this error
                    return Promise.reject(error);
                }

                if (error !== 'cancelButton' && !error?.data?.error?.data?.formValidationError) {
                    // Call parent error callback
                    $scope.data.onError({ errorMessage: getStateError(error) });
                }
            })
            .finally(() => {
                return trackHelper.updateInitiativeArchiveState(
                    dummyItem.id,
                    false,
                    $state.current.name === 'product.processContributorSolutionBusinessReport',
                    $scope.data.solutionBusinessReportId,
                );
            });
    };

    $scope.omImportFinished = function () {
        $scope.data.currentPage = 1;
        $scope.data.hasMorePages = true;
        loadFormInitiatives();
    };

    $scope.loadingPage = false;
    $scope.loadNextPage = function () {
        if ($scope.loadingPage) {
            return Promise.resolve();
        }

        $scope.loadingPage = true;
        $scope.data.currentPage += 1;
        return loadPage($scope.data.currentPage).finally(() => ($scope.loadingPage = false));
    };

    function onCreateInitiativeError(error) {
        $scope.data.errorMessage = error;
        finishSubmitLoading();
        $scope.data.onError(error);
    }

    function onCreateInitiativeSuccess(data) {
        $scope.data.questionInitiative = data;
        $scope.data.hasMorePages = false;
        finishSubmitLoading();
    }

    function startSubmitLoading() {
        $scope.data.loadingForm = true;
    }

    function finishSubmitLoading() {
        $scope.data.loadingForm = false;
    }

    function reIndexDummyItemInServer() {
        const dummyItem = $scope.data.initiatives.find((initiative) => initiative.title === DUMMY_TRACK_TITLE);
        const otherInitiatives = $scope.data.initiatives.filter((initiative) => initiative !== dummyItem);

        if (!dummyItem || otherInitiatives.length === 0) {
            return Promise.resolve();
        }

        return trackHelper.moveInitiative(
            dummyItem.id,
            null,
            otherInitiatives[otherInitiatives.length - 1].id,
            otherInitiatives[otherInitiatives.length - 1].parent.id,
            undefined,
            undefined,
            undefined,
            $state.current.name === 'product.processContributorSolutionBusinessReport',
            $scope.data.solutionBusinessReportId,
        );
    }
}

angular
    .module('tonkean.app')
    .controller('CollectInnerItemsFormCtrl', lateConstructController(CollectInnerItemsFormCtrl));
