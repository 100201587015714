import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import CommunicationIntegrationByMessageSendBy from './CommunicationIntegrationByMessageSendBy';
import PeopleDirectoryCommunicationPreference from './PeopleDirectoryCommunicationPreference';
import PeopleDirectoryPreviewTableEntranceHeader from './PeopleDirectoryPreviewTableEntranceHeader';
import PeopleDirectorySelectedCommunicationIntegration from './PeopleDirectorySelectedCommunicationIntegration';
import { ReactComponent as ArrowIcon } from '../../../../../../images/icons/arrow-3.svg';
import { ReactComponent as InformationIcon } from '../../../../../../images/icons/history/search-tooltip.svg';
import { ReactComponent as MailIcon } from '../../../../../../images/integrations/mail.svg';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoriesView, usePeopleDirectoryView } from '../../../utils/PeopleDirectoryViewContext';

import { TonkeanAvatar } from '@tonkean/infrastructure';
import { Placeholder, TextEllipsis } from '@tonkean/infrastructure';
import type { PeopleDirectory, PeopleDirectoryPersonSummary } from '@tonkean/tonkean-entities';
import {
    MessageSendBy,
    PeopleDirectorySendStatus,
    PeopleDirectorySendStatusLabel,
    SendToGroupOfPeopleAs,
} from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { Either, Maybe } from '@tonkean/utils';

const RecipientBar = styled.div<{ gridColumns: number }>`
    display: grid;
    grid-template-columns: repeat(${({ gridColumns }) => gridColumns}, minmax(20%, 35%));
    min-height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
`;
const Recipient = styled.span`
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'avatar name' 'avatar email';
    column-gap: 8px;
    padding: 5px 0 5px 9px;
`;
const Avatar = styled.span`
    height: 22px;
    width: 22px;
    grid-area: avatar;
    align-self: center;
    position: relative;

    svg {
        height: 10px;
        width: 10px;
        position: absolute;
        z-index: 7;
        top: -2px;
        right: -4px;
        background-color: ${Theme.colors.basicBackground};
        border-radius: 50%;

        path {
            fill: ${Theme.colors.warning};
        }
    }
`;
const RecipientName = styled.span`
    grid-area: name;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;
const RecipientEmail = styled.span<{ $center: boolean }>`
    grid-area: email;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    ${({ $center }) =>
        $center &&
        css`
            grid-row-start: name;
            grid-column-start: name;
            grid-row-end: email;
            grid-column-end: email;
            align-self: center;
        `}
`;
const MessageStatus = styled.span`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};
    display: flex;
    align-items: center;
    white-space: pre;
`;
const MessageError = styled.span`
    color: ${Theme.colors.warning};
`;

interface LoadingProps {
    loading: true;
}
type GeneralProps = {
    peopleDirectoryId: string;
};
type Props = Either<LoadingProps, GeneralProps>;

const Loading: React.FC = () => {
    return (
        <PeopleDirectoryPreviewTableEntranceHeader loading>
            {Array.from({ length: 3 })
                .fill(undefined)
                .map((__, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <RecipientBar key={idx} gridColumns={2}>
                        <Recipient>
                            <Avatar>
                                <Placeholder $width="22px" $circle />
                            </Avatar>
                            <RecipientEmail $center>
                                <Placeholder $width="200px" />
                            </RecipientEmail>
                        </Recipient>
                        <PeopleDirectoryCommunicationPreference>
                            <Placeholder $width="14px" $circle /> <Placeholder $width="100px" />
                        </PeopleDirectoryCommunicationPreference>
                    </RecipientBar>
                ))}
        </PeopleDirectoryPreviewTableEntranceHeader>
    );
};

const RecipientView: React.FC<
    {
        person: PeopleDirectoryPersonSummary;
        sendByEmail: boolean;
        peopleDirectory?: PeopleDirectory;
        showIndividualsPreferences: boolean;
        communicationPreferences: JSX.Element;
    } & Maybe<{ status: JSX.Element; errorMessage: JSX.Element; showErrorIcon: boolean }>
> = (props) => {
    const { mode } = usePeopleDirectoriesView();

    if (!props.peopleDirectory) {
        return <></>;
    }

    return (
        <RecipientBar key={props.person.id} gridColumns={mode === PeopleDirectoryViewMode.PREVIEW ? 2 : 4}>
            <Recipient>
                <Avatar>
                    <TonkeanAvatar owner={props.person} />
                    {props.showErrorIcon && <InformationIcon />}
                </Avatar>
                <TextEllipsis numberOfLines={1}>
                    {props.person.name !== props.person.email && <RecipientName>{props.person.name}</RecipientName>}
                </TextEllipsis>
                <TextEllipsis numberOfLines={1}>
                    <RecipientEmail $center={props.person.name === props.person.email}>
                        {props.person.email}
                    </RecipientEmail>
                </TextEllipsis>
            </Recipient>
            {props.showIndividualsPreferences && (
                <>
                    {props.communicationPreferences}
                    {props.status}
                    {props.errorMessage}
                </>
            )}
        </RecipientBar>
    );
};

const HistoryContent: React.FC<GeneralProps & { showIndividualsPreferences: boolean }> = (props) => {
    const { peopleDirectory, individualsSendResults } = usePeopleDirectoryView(props.peopleDirectoryId);

    return (
        <PeopleDirectoryPreviewTableEntranceHeader {...props}>
            {individualsSendResults?.map((sendResult) => {
                const status = PeopleDirectorySendStatusLabel[sendResult.status];

                return (
                    <RecipientView
                        key={sendResult.person.id}
                        person={sendResult.person}
                        peopleDirectory={peopleDirectory}
                        sendByEmail={false}
                        showIndividualsPreferences={props.showIndividualsPreferences}
                        communicationPreferences={
                            <PeopleDirectoryCommunicationPreference>
                                {sendResult.results.map((result, index, arr) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={index}>
                                        <CommunicationIntegrationByMessageSendBy messageSendBy={result.sendBy} />{' '}
                                        {index !== arr.length - 1 && (
                                            <>
                                                <ArrowIcon />
                                                &nbsp;
                                            </>
                                        )}
                                    </Fragment>
                                ))}
                            </PeopleDirectoryCommunicationPreference>
                        }
                        errorMessage={
                            <MessageStatus>
                                {sendResult.results.map((result, index, arr) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={index}>
                                        {result.friendlyErrorMessage && (
                                            <MessageError>
                                                {result.friendlyErrorMessage}
                                                {index !== arr.length - 1 && ', '}
                                            </MessageError>
                                        )}
                                        {index === arr.length - 1 &&
                                            index !== 0 &&
                                            result.sendBy === MessageSendBy.EMAIL &&
                                            result.status === PeopleDirectorySendStatus.SUCCESS &&
                                            'fallback to email'}
                                        {result.status === PeopleDirectorySendStatus.SUCCESS && index === 0 && '-'}
                                    </Fragment>
                                ))}
                            </MessageStatus>
                        }
                        status={
                            <MessageStatus>
                                {sendResult.status === PeopleDirectorySendStatus.FAIL ? (
                                    <MessageError>{status}</MessageError>
                                ) : (
                                    status
                                )}
                            </MessageStatus>
                        }
                        showErrorIcon={sendResult.status === PeopleDirectorySendStatus.FAIL}
                    />
                );
            })}
        </PeopleDirectoryPreviewTableEntranceHeader>
    );
};

const PreviewContent: React.FC<GeneralProps & { showIndividualsPreferences: boolean }> = (props) => {
    const { peopleDirectory, individuals, sendToGroupOfPeopleAs } = usePeopleDirectoryView(props.peopleDirectoryId);

    return (
        <PeopleDirectoryPreviewTableEntranceHeader {...props}>
            {sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL &&
                individuals?.map((person) => (
                    <RecipientView
                        key={person.id}
                        person={person}
                        peopleDirectory={peopleDirectory}
                        sendByEmail={person.preferEmail}
                        showIndividualsPreferences={props.showIndividualsPreferences}
                        communicationPreferences={
                            <PeopleDirectoryCommunicationPreference>
                                {person.preferEmail ? (
                                    <>
                                        <MailIcon /> Email
                                    </>
                                ) : (
                                    <PeopleDirectorySelectedCommunicationIntegration
                                        peopleDirectory={peopleDirectory}
                                    />
                                )}
                            </PeopleDirectoryCommunicationPreference>
                        }
                    />
                ))}
        </PeopleDirectoryPreviewTableEntranceHeader>
    );
};

const Content: React.FC<GeneralProps> = (props) => {
    const { sendToGroupOfPeopleAs, mode } = usePeopleDirectoryView(props.peopleDirectoryId);
    const showIndividualsPreferences = sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL;

    if (mode === PeopleDirectoryViewMode.PREVIEW) {
        return <PreviewContent {...props} showIndividualsPreferences={showIndividualsPreferences} />;
    }

    return <HistoryContent {...props} showIndividualsPreferences={showIndividualsPreferences} />;
};

const PeopleDirectoryPreviewTableEntrance: React.FC<Props> = (props) => {
    return props.loading ? <Loading /> : <Content {...props} />;
};

export default PeopleDirectoryPreviewTableEntrance;
