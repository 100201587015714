<div class="state-color-picker flex flex-wrap">
    <span
        ng-repeat="(colorName, color) in data.colorOptions"
        ng-style="{ 'background-color': color, 'border-color': color }"
        ng-class="{ 'mod-selected': data.selectedColor === color }"
        class="state-color-picker-option flex-vmiddle mod-center margin-right-xs margin-bottom-xs"
        ng-click="onColorSelected(colorName, color)"
    >
        <span class="svg-icon-xxs common-color-white" ng-if="data.selectedColor === color">
            <tnk-icon src="/images/icons/check.svg"></tnk-icon>
        </span>
    </span>
</div>
