import SolutionSiteAIChatWidget from './SolutionSiteAIChatWidget';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

import type { WidgetConfiguration } from '@tonkean/tonkean-entities';

const SolutionSiteAIChatWidgetPackage: SolutionSitePageWidgetPackage<WidgetConfiguration> = {
    displayComponent: SolutionSiteAIChatWidget,
};

export default SolutionSiteAIChatWidgetPackage;
