import FieldDefinitionConfigurationModalSubHeader from './FieldDefinitionConfigurationModalSubHeader';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkFieldDefinitionConfigurationModalSubHeader',
        reactToAngular(FieldDefinitionConfigurationModalSubHeader, [
            'headerTitle',
            'hasPendingChanges',
            'onDelete',
            'onCancel',
            'onDuplicate',
            'workflowVersionType',
            'saveDisabled',
            'onModalEscClose',
        ]),
    );
