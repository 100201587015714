import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import TnkIllustrationUrl from './assets/tnkillustration.svg?url';

import { AuthPageLayoutWrapper } from '@tonkean/auth-page';
import { Field, H1, H3, Input, LoadingCircle } from '@tonkean/infrastructure';
import { TonkeanLogo as TnkLogo } from '@tonkean/svg';
import { Button } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const LoginTitle = styled(H1)`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
`;

const TnkLogoStyled = styled(TnkLogo)`
    position: absolute;
    top: 60px;
`;

const Hr = styled.hr`
    width: 44%;
    border-color: #cdd2d8;
    z-index: 1;
`;

const EmailLoginSeperator = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const OrSeperatorSpan = styled.span`
    color: #5b636c;
    font-size: 14px;
    display: flex;
    align-items: center;
    z-index: 2;
`;

const SubmitButton = styled(Button)`
    height: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-block: 10px;
    background: #605ce4;
    font-size: 16px;
    color: #34393e;
    border-radius: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
    width: 100%;
    color: white;
    text-align: center;

    &:hover {
        color: white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        background: #322f89;
    }
`;

const EmailInput = styled(Input)<{ error?: boolean }>`
    background: #f2f4f7 !important;
    height: 50px !important;
    border: ${({ error }) => (error ? 'solid 1px red' : 'none')} !important;
    padding: 12px !important;
`;

const InputLabel = styled(H3)`
    font-size: 16px;
    width: 100%;
    margin-bottom: 14px;
    font-weight: 400;
    text-align: center;
    color: #5b636c;
`;

const EmailLabel = styled.span`
    font-weight: 700;
`;

const ChangeFormState = styled(H3)`
    font-size: 16px;
    line-height: 29px;
    color: #5b636c;
    margin-top: 6px;
`;

const StyledLink = styled(ClickableLink)`
    cursor: pointer;
    color: #3799ff;
`;

const PolicyBottomText = styled(H3)`
    font-size: 16px;
    font-size: 13px;
    color: #5b636c;
    position: absolute;
    bottom: 40px;
    padding-inline: 20px;
    text-align: center;
`;

const LoginPrompt = styled.div`
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledForm = styled(Form)`
    width: inherit;
`;

const TnkIllustrationStyled = styled.div`
    margin-top: -50px;
    margin-bottom: 50px;
    background-image: url(${TnkIllustrationUrl});
    height: 247px;
    width: 377px;
`;

const validationSchema = yup.object().shape({
    code: yup.string().required('Required').min(14, 'Tonken is too short'),
});

const LoginSentPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isResetLoading, setIsResetLoading] = useState(false);
    const $localStorage = useAngularService('$localStorage');
    const $rootScope = useAngularService('$rootScope');
    const $state = useAngularService('$state');
    const tonkeanService = useAngularService('tonkeanService');
    const workEmail = $localStorage.LastLoginWithEmailAddress;

    const onSubmit = (values) => {
        setIsLoading(true);
        const code = values['code'];
        if (code) {
            $state.go('loginToken', {
                token: code,
            });
        }
    };

    const resend = () => {
        if (workEmail && !isResetLoading && !isLoading) {
            setIsResetLoading(true);
            tonkeanService
                .sendLoginEmail(workEmail, null, null)
                .then(function () {
                    $rootScope.$emit('alert', { type: 'success', msg: 'Email confirmation re-sent successfully' });
                })
                .finally(function () {
                    setIsResetLoading(false);
                });
        }
    };

    return (
        <AuthPageLayoutWrapper>
            <LoginPrompt>
                <TnkLogoStyled />
                <TnkIllustrationStyled />
                <LoginTitle>Check your inbox for our email to get started</LoginTitle>

                <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{}}>
                    {({ errors, touched }) => (
                        <StyledForm>
                            <InputLabel>
                                We sent an email to <EmailLabel>{workEmail}</EmailLabel>
                            </InputLabel>
                            <Field touchedOnlyErrors={false}>
                                <EmailInput
                                    data-automation="tkn-input-code"
                                    error={!!errors['code']}
                                    name="code"
                                    placeholder="Enter token code from email"
                                />
                            </Field>
                            <SubmitButton
                                data-automation="tkn-btn-login-using-code"
                                type="submit"
                                disabled={!!errors['code'] || !touched['code'] || isLoading}
                            >
                                {isLoading ? <LoadingCircle white /> : 'Continue'}
                            </SubmitButton>
                        </StyledForm>
                    )}
                </Formik>

                <ChangeFormState>
                    Didn’t get the email?{' '}
                    {isResetLoading ? <LoadingCircle /> : <StyledLink onClick={resend}>Send again</StyledLink>}
                </ChangeFormState>

                <ChangeFormState>
                    Not your email? <StyledLink state="login">Change Email</StyledLink>
                </ChangeFormState>
            </LoginPrompt>
        </AuthPageLayoutWrapper>
    );
};

export default LoginSentPage;
