export function SalesforceModalCtrl(
    $scope,
    createProjectApis,
    oauthHandler,
    utils,
    projectManager,
    tonkeanService,
    environment,
) {
    let state;
    $scope.projectIntegration = null;
    $scope.integration = null;
    $scope.data = {
        incomingWebhookUrl: null,
        loadingExistingIncomingWebhooks: false,
        accountTypes: {
            sandbox: {
                authSubdomain: 'test',
                label: 'Sandbox',
            },
            production: {
                authSubdomain: 'login',
                label: 'Production',
            },
        },
        selectedAccountType: 'production',
        collectConvertedLeads: false,
    };

    $scope.init = async function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }
        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;
        $scope.projectIntegration = settings.projectIntegration;
        $scope.data.selectedAccountType = $scope.projectIntegration.projectData.projectDatas[0].selectedAccountType;
        $scope.data.collectConvertedLeads = $scope.projectIntegration.projectData.projectDatas[0].collectConvertedLeads;
        await tryLoadFromEdit();
    };

    $scope.authorize = async function () {
        $scope.connecting = true;
        $scope.error = null;
        try {
            const authSubdomain = $scope.data.accountTypes[$scope.data.selectedAccountType].authSubdomain;
            const code = await oauthHandler.salesforce(
                environment.integrationKeysMap.salesforce,
                environment.integrationKeysMap.salesforceUri,
                authSubdomain,
            );
            $scope.integration = await createProjectApis.createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                {
                    code,
                    redirectUri: environment.integrationKeysMap.salesforceUri,
                    authSubdomain,
                },
                undefined,
                undefined,
            );

            const oldProject = projectManager.project;
            let updatedProject;

            const projectData = {
                selectedAccountType: $scope.data.selectedAccountType,
                collectConvertedLeads: $scope.data.collectConvertedLeads,
            };
            $scope.integration.projectDatas = [projectData];

            // If project integration is exists it means that the user
            // is in Edit or Fix mode.
            if ($scope.projectIntegration) {
                updatedProject = await createProjectApis.editProjectIntegration(
                    projectManager.project.id,
                    $scope.projectIntegration.id,
                    $scope.integration,
                    $scope.projectIntegration.displayName,
                );
                // Add the created project integration to the scope
                $scope.projectIntegration = utils.findFirst(
                    updatedProject.integrations,
                    (prin) =>
                        prin.id === $scope?.projectIntegration?.id ||
                        !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
                );
                loadProjectIntegrationWebhook();
            } else {
                // Create project integration
                updatedProject = await createProjectApis.createProjectIntegration(
                    projectManager.project,
                    $scope.integration,
                );
                // Add the created project integration to the scope
                $scope.projectIntegration = utils.findFirst(
                    updatedProject.integrations,
                    (prin) =>
                        prin.id === $scope?.projectIntegration?.id ||
                        !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
                );
                await $scope.createSalesforceIncomingWebhook();
            }

            // Add the newly created integration to the project manager
            projectManager.project.integrations = updatedProject.integrations;
        } catch (error) {
            $scope.error = error;
        } finally {
            $scope.connecting = false;

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.authorize();
    };

    $scope.createSalesforceIncomingWebhook = async function () {
        const { incomingWebhook } = await tonkeanService.createIntegrationIncomingWebhook(
            projectManager.project.id,
            $scope.projectIntegration.id,
            'salesforce',
        );
        $scope.data.incomingWebhookUrl = incomingWebhook.url;
    };

    $scope.ok = function () {
        const settings = {
            integration: $scope.integration,
            integrations: [
                {
                    integration: $scope.integration,
                    projectData: {
                        selectedAccountType: $scope.data.selectedAccountType,
                        collectConvertedLeads: $scope.data.collectConvertedLeads,
                    },
                },
            ],
            createdProjectIntegration: $scope.projectIntegration,
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(settings);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    async function loadProjectIntegrationWebhook() {
        $scope.data.loadingExistingIncomingWebhooks = true;
        $scope.connecting = true;
        try {
            const incomingWebhookUrl = await tonkeanService.getIncomingWebhooksByProjectIntegrationId(
                $scope.projectIntegration.id,
            );
            if (incomingWebhookUrl.entities && incomingWebhookUrl.entities.length) {
                $scope.data.incomingWebhookUrl = incomingWebhookUrl.entities[0].url;
            }
        } catch {
            $scope.error = 'Unable to create incoming webhook.';
        } finally {
            $scope.data.loadingExistingIncomingWebhooks = false;
            $scope.connecting = false;
        }
    }

    $scope.setAccountType = function (accountType) {
        $scope.data.selectedAccountType = accountType;
    };

    $scope.resetIntegration = function () {
        $scope.integration = null;
        $scope.connecting = false;
    };

    async function tryLoadFromEdit() {
        // Only when the user opens the modal after the integration created (cause we have project integratoin also)
        if (state && state.integrations) {
            $scope.integration = state.integrations[0].integration;
            $scope.projectIntegration = state.integrations[0];
            // Fetching the webhook url.
            $scope.connecting = true;
            loadProjectIntegrationWebhook();
            $scope.connecting = false;
            $scope.data.selectedAccountType =
                state.integrations[0].projectData.projectDatas[0].selectedAccountType || 'production';
            $scope.data.collectConvertedLeads =
                state.integrations[0].projectData.projectDatas[0].collectConvertedLeads || false;
        }
    }
}
export default angular.module('tonkean.shared').controller('SalesforceModalCtrl', SalesforceModalCtrl);
