import { SlackAuthenticationType } from '@tonkean/tonkean-entities';

export function SlackAppModalCtrl(
    $scope,
    $q,
    $timeout,
    oauthHandler,
    createProjectApis,
    $rootScope,
    consts,
    utils,
    integrations,
    authenticationService,
    communicationIntegrationsService,
    projectManager,
    environment,
) {
    $scope.pm = projectManager;

    $scope.data = {
        isCreate: true,
        hasInvalidChannels: false,
        isSlackCommunicationConnected: null,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.data.isSlackCommunicationConnected = communicationIntegrationsService.isIntegrationConnected('slack');

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        $scope.error = null;
        $scope.loading = true;
        const useOldScopes = $rootScope?.features?.[projectManager.project.id]?.tonkean_feature_slack_oauth2;

        return oauthHandler
            .slack(
                environment.integrationKeysMap.slackApp,
                useOldScopes
                    ? consts.getSlackAppIntegrationScopesV2()
                    : consts.getSlackAppIntegrationScopesV2New(),
                useOldScopes
                    ? consts.getSlackAppIntegrationUserScopes()
                    : consts.slackAppIntegrationUserScopesNew(),
                true,
            )
            .then(function (code) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        code,
                        redirectUri: environment.integrationKeysMap.slackApp,
                        authType: SlackAuthenticationType.OAUTH2,
                    },
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'SLACK_APP',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (integObj) {
                $scope.integration = integObj;
                $scope.data.isCreate = false;
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    };

    $scope.changeUser = function () {
        $scope.data.isCreate = true;
        $scope.integration = null;
        $scope.selected = {};
        $scope.connect();
    };

    $scope.verifyChannelsAreValid = function () {
        if ($scope.selected.channelsToCollect?.length) {
            const invalidChannels = $scope.selected.channelsToCollect.filter((channel) => channel.isInvalid);
            if (invalidChannels.length) {
                $scope.data.hasInvalidChannels = true;
                return false;
            }
        }

        $scope.data.hasInvalidChannels = false;
        return true;
    };

    $scope.ok = function () {
        if ($scope.data.integrationForm && !$scope.data.integrationForm.$valid) {
            return;
        }

        if (!$scope.verifyChannelsAreValid()) {
            return;
        }

        const s = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        s.integrations = [
            {
                integration: $scope.integration,
                projectData: {
                    channelsToCollect: [],
                },
            },
        ];

        if ($scope.selected.channelsToCollect && $scope.selected.channelsToCollect.length) {
            s.integrations[0].projectData.channelsToCollect = $scope.selected.channelsToCollect
                .filter((channel) => channel && channel.value && channel.displayName)
                .map((channel) => {
                    return {
                        channelId: channel.value,
                        displayName: channel.displayName,
                        visibilityType: channel.visibilityType,
                        selectionType: channel.selectionType,
                    };
                });
        }
        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onPublicChannelSelected = function (channel, index) {
        channel.visibilityType = 'public';
        channel.selectionType = 'public';
        $scope.updateChannelAt(index, channel);
    };

    $scope.onPublicChannelByNameSelected = function (channelSelectionObject, index) {
        if (channelSelectionObject.validatedPrivateChannel) {
            const updatedChannel = {
                displayName: channelSelectionObject.selectedChannel,
                value: channelSelectionObject.selectedChannelId,
                visibilityType: 'public',
                selectionType: 'public-by-name',
            };
            $scope.updateChannelAt(index, updatedChannel);
        }
    };

    $scope.onPrivateChannelSelected = function (channelSelectionObject, index) {
        if (channelSelectionObject.validatedPrivateChannel) {
            const updatedChannel = {
                displayName: channelSelectionObject.selectedChannel,
                value: channelSelectionObject.selectedChannelId,
                visibilityType: 'private',
                selectionType: 'private',
            };
            $scope.updateChannelAt(index, updatedChannel);
        } else {
            $scope.selected.channelsToCollect[index].isInvalid = true;
        }

        if ($scope.data.hasInvalidChannels) {
            $scope.verifyChannelsAreValid();
        }
    };

    $scope.updateChannelAt = function (index, updatedChannel) {
        $scope.selected.channelsToCollect[index] = updatedChannel;
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.addChannelToCollect = function () {
        if (!$scope.selected.channelsToCollect) {
            $scope.selected.channelsToCollect = [];
        }

        $scope.selected.channelsToCollect.push({
            visibilityType: 'public',
            selectionType: 'public',
        });

        // Scroll to the new added channel to monitor, we use timeout because we want to scroll after the ui
        // was rendered with the new added channel
        $timeout(() => {
            // The added monitored channel
            const someElement = angular.element(
                document.querySelectorAll(`.monitored-channel`)[$scope.selected.channelsToCollect.length - 1],
            );

            // The monitored channels container
            const scrollContainer = angular.element(document.querySelector('.monitored-channels-container'));

            scrollContainer.scrollTo(someElement, 0, 500);
        }, 100);
    };

    $scope.removeChannelToCollect = function (index) {
        $scope.selected.channelsToCollect.splice(index, 1);
    };

    $scope.selectChannelVisibilityType = function (channel, selectionType) {
        channel.selectionType = selectionType;
    };

    function tryLoadFromEdit() {
        let loadingPromise = $q.resolve();
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.data.isCreate = false;
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;

                if (state.integrations[0]?.projectData?.projectDatas?.[0]?.channelsToCollect) {
                    $scope.selected.channelsToCollect =
                        state.integrations[0].projectData?.projectDatas[0].channelsToCollect.map((channel) => {
                            return {
                                value: channel.channelId,
                                displayName: channel.displayName,
                                visibilityType: channel.visibilityType,
                                selectionType: channel.selectionType || channel.visibilityType,
                            };
                        });
                }
            }
        } else {
            $scope.data.isCreate = true;
            loadingPromise = $scope.connect();
        }

        loadingPromise.then(() => {
            if (!$scope.selected.channelsToCollect || $scope.selected.channelsToCollect.length === 0) {
                $scope.addChannelToCollect();
            }
        });
    }
}
export default angular.module('tonkean.shared').controller('SlackAppModalCtrl', SlackAppModalCtrl);
