import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import { useLazyAsyncMethod } from './asyncMethod';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useFetchProject = (projectId: string) => {
    const projectManager = useAngularService('projectManager');

    const [{ data: project, loading: loadingProject, error: projectError }, getProjectById] = useLazyAsyncMethod(
        projectManager,
        'selectProject',
    );

    if (projectId && !project && !loadingProject) {
        getProjectById(projectId);
    }

    const getGroupMap = useCallback(
        (groupId: TonkeanId<TonkeanType.GROUP>) => {
            return projectManager.groupsMap[groupId];
        },
        [projectManager.groupsMap],
    );

    return { project, loadingProject, projectError, getGroupMap };
};

export default useFetchProject;
