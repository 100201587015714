<div class="margin-top-xxs">
    <span>
        <strong ng-if="item.metadata.updatedViaBot">Tonkean</strong>
        <strong ng-if="!item.metadata.updatedViaBot">{{ item.actor.id === as.currentUser.id ? 'You' : item.actor.name }}</strong>
        updated
        <span ng-if="!item.metadata.nothingChanged">
            the status to&nbsp;
            <strong ng-if="item.metadata.newState" ng-style="{ color: item.metadata.newState.stateColor }">
                {{ item.metadata.newState.stateText }}
            </strong>
        </span>
        <span ng-if="item.metadata.nothingChanged">that the status is still the same</span>
        <span ng-if="item.reference1.id !== item.target.id || targetId !== item.target.id">
            for
            <a ng-click="modal.openViewInitiative(item.reference1.id)" class="common-color-dark-grey">
                <strong>{{ item.reference1.title }}</strong>
            </a>
        </span>
    </span>
</div>
<div
    class="common-break-long margin-top-xs activity-notification-innertext"
    ng-if="notification && item.metadata.newUpdateText"
    ng-click="modal.openViewInitiative(item.reference1.id)"
    light-markdown="item.metadata.newUpdateText"
></div>
