import React from 'react';
import styled from 'styled-components';

import { NAV_BAR_HEIGHT } from '../../NavigationTopbar';

import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    height: ${NAV_BAR_HEIGHT}px;
    background: ${Theme.colors.white};
    border-bottom: 1px solid ${Theme.colors.gray_200};
    display: flex;
    align-items: center;
    padding: 0 24px 0 24px;
`;

interface Props {}

const NavigationTopBarLoading: React.FC<Props> = ({}) => {
    return (
        <Container>
            <Placeholder $width="24px" $height="24px" $borderRadius="5px" />
            <div className="flex-grow" />
            <Placeholder $width="24px" $height="24px" $circle />
        </Container>
    );
};

export default NavigationTopBarLoading;
