import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MoreActionIcon } from '../../../../../images/icons/more-actions.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-form.svg';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { Menu, MenuItem, useToggle } from '@tonkean/infrastructure';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    integrationType: string;
    integrationDisplayName: string;
    onEditConnectionClicked(): void;
}

const Box = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 220px;
    height: 100px;
    display: flex;
`;

const ColoredLine = styled.div`
    height: 94px;
    width: 2px;
    background: ${Theme.current.palette.colorPicker.HEX_20C5F5};
    border-radius: 100px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin: auto 0px auto 2px;
`;

const TitleWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
`;

const DisplayName = styled.div`
    margin-left: 10px;
`;

const MenuWrapper = styled.div`
    margin-top: auto;
    margin-left: auto;
`;

const PredefinedConnectionBox: React.FC<Props> = ({
    integrationType,
    integrationDisplayName,
    onEditConnectionClicked,
}) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);

    return (
        <Box>
            <ColoredLine />

            <TitleWrapper>
                <ProjectIntegrationIcon width={14} integrationType={integrationType} />
                <DisplayName>{integrationDisplayName}</DisplayName>
            </TitleWrapper>

            <MenuWrapper>
                <Menu
                    show={isMenuOpen}
                    onClose={toggleIsMenuOpen}
                    placement="bottom-start"
                    menuItems={
                        <MenuItem onClick={onEditConnectionClicked} icon={<TrashIcon />}>
                            Edit Connection
                        </MenuItem>
                    }
                    thin
                >
                    <IconButton onClick={() => toggleIsMenuOpen()} flat>
                        <MoreActionIcon />
                    </IconButton>
                </Menu>
            </MenuWrapper>
        </Box>
    );
};

export default PredefinedConnectionBox;
