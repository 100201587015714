import lateConstructController from '../../utils/lateConstructController';
import { DeprecatedDate } from '@tonkean/utils';
import { getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function SpecialFieldManualInputCtrl($scope, $timeout, projectManager, workflowVersionManager) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        fieldId: ctrl.fieldId,
        selectedValueToUpdate: ctrl.selectedValueToUpdate ? ctrl.selectedValueToUpdate : { value: null },
        relativeMode: ctrl.relativeMode,
        invalid: ctrl.invalid,

        specialFieldMap: getFormulaSpecialFieldIdToDefinitionMap(),
        specialField: null,
        minDate: DeprecatedDate.nowAsDate(),
    };

    /**
     * Initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.fieldId) {
            $scope.data.specialField = $scope.data.specialFieldMap[$scope.data.fieldId];
        }
    };

    /**
     * Occurs when bindings are changed.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj && changesObj.selectedValueToUpdate) {
            $scope.data.selectedValueToUpdate = ctrl.selectedValueToUpdate;
        }
        if (changesObj && changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
        }
        if (changesObj.invalid) {
            $scope.data.invalid = changesObj.invalid.currentValue;
        }
    };

    /**
     * Occurs once an owner is selected.
     */
    $scope.ownerSelected = function (person) {
        $scope.data.selectedValueToUpdate = {
            value: person?.id || person,
            id: person?.id || person,
        };
        $scope.onManualSpecialFieldChanged();
    };

    /**
     * Occurs whenever a state (TNK_STAGE) is changed.
     */
    $scope.onStateChanged = function () {
        // The model for the ui-select is selectedValueToUpdate.value, but we want the value to be the label after selection.
        $scope.data.selectedValueToUpdate = $scope.data.selectedValueToUpdate.value;
        if ($scope.data.selectedValueToUpdate) {
            $scope.data.selectedValueToUpdate.value = $scope.data.selectedValueToUpdate.label;
        } else {
            $scope.data.selectedValueToUpdate.value = null;
        }

        $scope.onManualSpecialFieldChanged();
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onManualSpecialFieldChanged = function (fieldId, value, isDate) {
        if (isDate) {
            $timeout(function () {
                $scope.data.selectedValueToUpdate = { value: value.getTime() };
            });
        }
        if (ctrl.onManualSpecialFieldChanged) {
            ctrl.onManualSpecialFieldChanged({
                // If value doesnt exist we send an empty object, signaling that the value is cleared, not that theres no change
                selectedValueToUpdate: (isDate ? { value: value.getTime() } : $scope.data.selectedValueToUpdate) || {},
                fieldId: $scope.data.fieldId,
            });
        }
    };
}

angular
    .module('tonkean.app')
    .controller('SpecialFieldManualInputCtrl', lateConstructController(SpecialFieldManualInputCtrl));
