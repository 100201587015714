<div class="common-size-xs clear fix">
    <div class="pull-right btn btn-secondary btn-sm btn-on-hover" ng-if="onClick" ng-click="onClick(item)">
        <i class="fa fa-plus margin-right-xs"></i>
        Create Item
    </div>
    <div class="common-tag-full mod-grey3 common-size-xxxxxs margin-right" ng-bind="item.externalType"></div>
    <span ng-if="item.status" class="common-size-xxxxs common-tag">
        {{ item.status }}
        <span
            class="common-tag-line"
            ng-style="{
                'background-color': item.status.trim().toUpperCase().indexOf('DONE') > -1 ? '#17C578' : item.statusColor
            }"
        ></span>
    </span>
    <span class="common-size-xxxxs common-color-light-grey" ng-if="item.tags && item.tags.length">
        <span class="margin-right-xs">/</span>
        <span ng-repeat="tag in item.tags">{{ tag }}{{ !$last ? ', ' : '.' }}</span>
    </span>
</div>
