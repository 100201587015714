import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const MultiEntitySelectorEntityLabel = styled.div`
    color: ${Theme.colors.gray_700};
    font-size: 12px;
`;

export default MultiEntitySelectorEntityLabel;
