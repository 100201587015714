import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import EnterpriseComponentVariableValuesFromMarketplaceItem from './EnterpriseComponentVariableValuesFromMarketplaceItem';
import EnterpriseComponentIcon from '../../../modules/EnterpriseComponentsModule/components/EnterpriseComponentIcon';

import { useLazyTonkeanService, useToastMessage, useTonkeanService } from '@tonkean/angular-hooks';
import { CoreEditorSerialize, HTMLEditorFullPlatePluginsList } from '@tonkean/editor';
import {
    H3,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    SimpleErrorStateMessage,
    useProject,
} from '@tonkean/infrastructure';
import { useAuthentication } from '@tonkean/integrations';
import type { TonkeanId } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledH3 = styled(H3)<{ paddingTop?: boolean }>`
    margin-bottom: 4px;

    ${({ paddingTop = false }) =>
        paddingTop &&
        css`
            padding-top: 16px;
        `}
`;

interface Props {
    isOpen: boolean;
    templateName: string;
    iconUrl: string;
    onSave: (oldIdsToCreatedIdsMap: Record<string, string>, createdProjectIntegrationId?: TonkeanId) => void;
    onCancel: () => void;
}

const EnterpriseComponentVariablesValuesFromMarketplaceItemModal: React.FC<Props> = ({
    isOpen,
    templateName,
    iconUrl,
    onSave,
    onCancel,
}) => {
    const { id: projectId } = useProject();
    const emitToast = useToastMessage();
    const authenticate = useAuthentication();

    // current state of all the updated parameters.
    const [updatedValuesMap, setUpdatedValuesMap] = useState<Record<string, string>>({});

    const {
        loading: loadingInfo,
        data: info,
        error,
    } = useTonkeanService('getEnterpriseComponentInfoFromMarketplaceItem', projectId, templateName);

    // Initiate values after request server for parameters
    useEffect(() => {
        info?.variables.forEach((variable) => {
            if (variable.initialValue) {
                setUpdatedValuesMap((prevUpdatedValuesMap) => {
                    return {
                        ...prevUpdatedValuesMap,
                        [variable.displayName]: variable.initialValue || '',
                    };
                });
            }
        });
    }, [info?.variables]);

    const [isCreateLoading, setIsCreateLoading] = useState(false);

    const [
        { error: installEntityFromMarketplaceItemByTemplateNameError },
        installEntityFromMarketplaceItemByTemplateName,
    ] = useLazyTonkeanService('installEntityFromMarketplaceItemByTemplateName');

    const [, getProjectIntegrationById] = useLazyTonkeanService('getProjectIntegrationById');

    // On Click save button
    const onSaveInner = async () => {
        setIsCreateLoading(true);
        try {
            const { createdItemId, oldToNewMap } = await installEntityFromMarketplaceItemByTemplateName({
                projectId,
                templateName,
                marketplaceItemType: MarketplaceItemType.DATA_SOURCE,
                variableValues: updatedValuesMap,
            });

            const projectIntegration = await getProjectIntegrationById(createdItemId);
            await authenticate(projectIntegration);

            onSave(oldToNewMap, createdItemId);

            return;
        } catch {
            emitToast('Unable to create data source', 'danger');
        } finally {
            setIsCreateLoading(false);
        }
        onCancel?.();
    };

    // All the variables that the user can fill
    const editableVariables = useMemo(() => {
        return info?.variables?.filter((variable) => variable.requestOnConnect)?.filter((variable) => !variable.hide);
    }, [info?.variables]);

    const requiredVariablesNames = useMemo(() => {
        return (
            editableVariables?.filter((variable) => variable.isRequired).map((variable) => variable.displayName) || []
        );
    }, [editableVariables]);

    const isSaveDisabled: boolean = useMemo(() => {
        return (
            loadingInfo ||
            requiredVariablesNames.some((requiredVariablesName) => {
                return !updatedValuesMap[requiredVariablesName]?.trim();
            })
        );
    }, [loadingInfo, requiredVariablesNames, updatedValuesMap]);

    return (
        <Modal open={isOpen} onClose={() => onCancel?.()} fixedWidth>
            <ModalHeader>
                <HeaderWrapper>
                    <EnterpriseComponentIcon iconUrl={iconUrl} />
                    Set Up Integration
                </HeaderWrapper>
            </ModalHeader>

            <ModalBody>
                {!loadingInfo && info?.description?.length !== 0 && (
                    <>
                        <StyledH3 $bold>About</StyledH3>

                        <CoreEditorSerialize
                            value={info?.description || EMPTY_ARRAY}
                            plugins={HTMLEditorFullPlatePluginsList}
                        />
                    </>
                )}

                {!loadingInfo && info?.instructions?.length !== 0 && (
                    <>
                        <StyledH3 paddingTop $bold>
                            Setup Instructions
                        </StyledH3>

                        <CoreEditorSerialize
                            value={info?.instructions || EMPTY_ARRAY}
                            plugins={HTMLEditorFullPlatePluginsList}
                        />
                    </>
                )}

                {!loadingInfo && info?.variables?.length !== 0 && (
                    <StyledH3 paddingTop $bold>
                        Connection Parameters
                    </StyledH3>
                )}

                <EnterpriseComponentVariableValuesFromMarketplaceItem
                    variables={editableVariables}
                    onChange={setUpdatedValuesMap}
                    updatedValuesMap={updatedValuesMap}
                    loading={loadingInfo}
                />

                {error && (
                    <SimpleErrorStateMessage
                        error={error}
                        errorMessage="Error fetching data source from library"
                        showSmallError
                    />
                )}
            </ModalBody>

            <ModalFooterActions
                error={installEntityFromMarketplaceItemByTemplateNameError}
                saveDisabled={isSaveDisabled}
                onCancel={onCancel}
                onSave={onSaveInner}
                loading={isCreateLoading}
                saveLabel="Create"
                border
            />
        </Modal>
    );
};

export default EnterpriseComponentVariablesValuesFromMarketplaceItemModal;
