import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import botQuestionAndSendNotificationValidator from '../sharedConfigComponents/validators/botQuestionAndSendNotificatinValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const SendNotificationLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SEND_NOTIFICATION,
    title: 'Send Notification',
    iconClass: 'mod-notification',
    description: 'Send a custom notification to a channel or a person.',
    doNotDisplayNotificationsEdit: true,
    hasPersonConfig: true,
    defaultActionDefinition: (project) => {
        return {
            initiativeOwner: true,
            hideInitiativePageLink: true,
            text: '',
            communicationProjectIntegrationId: project?.defaultCommunicationProjectIntegration?.id,
        };
    },
    validator: ({ definition, group, syncConfigCacheManager, workflowVersionInfoRetriever, projectManager }) => {
        const validationObject: LogicBlockValidationObject = {};
        botQuestionAndSendNotificationValidator(
            validationObject,
            definition,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            group,
            projectManager,
        );
        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default SendNotificationLogicBlockConfig;
