import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { Checkbox } from '@tonkean/infrastructure';
import utils from '@tonkean/utils';

const CenteredDiv = styled.div`
    text-align: center;
    margin-bottom: 10px;
`;

const SFTPCustomSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    onChangeOrInitIntegration,
    projectIntegration,
}) => {
    const [folderPath, setFolderPath] = useState<string>('/');
    const [excludeSubfolders, setExcludeSubfolders] = useState<boolean>(false);

    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setFolderPath(projectData.path);
            setExcludeSubfolders(!!projectData.excludeSubfolders);
        }
    }, [projectIntegration]);

    useEffect(() => {
        const path = utils.isNullOrEmpty(folderPath) ? '/' : folderPath;
        const projectIntegrationData = {
            path,
            excludeSubfolders,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData,
            },
            false,
        );
    }, [excludeSubfolders, folderPath, onChangeOrInitIntegration]);

    return (
        <div className="flex flex-col">
            <div className="form-group flex-inline mod-vmiddle">
                <label className="control-label col-md-4 margin-right">Folder Path</label>

                <div className="col-sm-7">
                    <input
                        type="text"
                        value={folderPath}
                        className="form-control"
                        onChange={({ target }) => setFolderPath(target.value)}
                        autoComplete="off"
                    />
                    <span className="common-size-xxxs common-color-grey">
                        If empty, root folder ('/') will be used.
                    </span>
                </div>
            </div>

            <CenteredDiv>
                <Checkbox checked={excludeSubfolders} onChange={() => setExcludeSubfolders(!excludeSubfolders)}>
                    Exclude Subfolders
                </Checkbox>
            </CenteredDiv>
        </div>
    );
};

export default SFTPCustomSetupComponent;
