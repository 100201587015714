import { FieldArray, useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import SingleNextPageParameterEditor from './SingleNextPageParameterEditor';
import defaultTonkeanExpression from '../../../FormBuilder/entities/DefaultTonkeanExpression';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { LinkLikeButton } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const AddPageParameter = styled(LinkLikeButton)`
    color: ${Theme.colors.primaryHighlight};
`;

interface Props {
    name: string;
    expressionProps?: FormikExpressionProps;
}

const NextPageParameters: React.FC<Props> = ({ name, expressionProps }) => {
    const [{ value: nextPageParameters }, , { setValue: setNextPageParameters }] = useField(name);

    return (
        <>
            <FieldArray name={name}>
                {({ remove }) => {
                    return nextPageParameters?.map((singleNextPageParameter, index) => (
                        <SingleNextPageParameterEditor
                            expressionProps={expressionProps}
                            onRemove={() => remove(index)}
                            name={`${name}[${index}]`}
                            key={`${name}[${index}]`}
                        />
                    ));
                }}
            </FieldArray>

            <AddPageParameter
                onClick={() =>
                    setNextPageParameters([
                        ...nextPageParameters,
                        {
                            parameterName: '',
                            parameterValue: { ...defaultTonkeanExpression },
                        },
                    ])
                }
            >
                + Add Next Page Parameter
            </AddPageParameter>
        </>
    );
};

export default NextPageParameters;
