import { REGULAR_INITIATIVES_PAGE_SIZE } from '@tonkean/constants';
import { parseParents } from '@tonkean/tonkean-utils';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function InitiativesSidebarCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $location,
    initiativeManager,
    projectManager,
    initiativeCache,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.parseParents = parseParents;
    $scope.sp = $stateParams;
    $scope.as = $rootScope.as;

    $scope.projectId = $stateParams.projectId;
    $scope.itemMap = initiativeCache.getInitiativesCache();

    $scope.onInitiativeSelected = ctrl.onInitiativeSelected;

    $scope.dueFilterOptions = [
        { label: 'Today', days: 1 },
        { label: 'Next 7 days', days: 7 },
        {
            label: 'Next 30 days',
            days: 30,
        },
    ];

    $scope.hierarchyFilterOptionsMap = {
        group: { label: 'All', key: 'group' },
        onlyItem: { label: 'Root', key: 'onlyItem' },
        onlyInner: { label: 'Inner', key: 'onlyInner' },
    };

    $scope.itemsFilterOptions = [
        { label: 'Owned by me', value: 'OWNED_BY_ME' },
        { label: 'Created by me', value: 'CREATED_BY_ME' },
    ];

    $scope.hierarchyFilterOptions = [
        $scope.hierarchyFilterOptionsMap.group,
        $scope.hierarchyFilterOptionsMap.onlyItem,
        $scope.hierarchyFilterOptionsMap.onlyInner,
    ];

    $scope.data = {
        showSidebar: !$rootScope.isMobile && !ctrl.isForms, // The side panel should always be open if not in mobile. The user can close it if he wants to.
        isForms: ctrl.isForms,
        formItemsHierarchyFilter: ctrl.formItemsHierarchyFilter,
        groupId: ctrl.groupId,
        initiatives: [],
        initiativesByGroup: {},
        invisibleGroupsInitiatives: [],
        toggleDone: false,
        selectedItemFilter: $scope.itemsFilterOptions[0].value,
        dateRange: null,
        hierarchy: $scope.hierarchyFilterOptionsMap[$stateParams.hr],
        hideHierarchyFilter:
            ctrl.formItemsHierarchyFilter === 'ROOT_ITEMS' || ctrl.formItemsHierarchyFilter === 'INNER_ITEMS',
    };

    $scope.loading = true;

    $scope.$on('newActivityUpdate', () => {
        $scope.loadMyInitiatives();
    });

    $rootScope.$on('initiativeCreated', () => {
        $scope.loadMyInitiatives();
    });

    $rootScope.$on('ownerSet', () => {
        $scope.loadMyInitiatives();
    });

    $scope.toggleSidebar = function () {
        $scope.data.showSidebar = !$scope.data.showSidebar;
    };

    $scope.loadMyInitiatives = function (isInit) {
        $scope.loading = true;

        let filters = {};
        if ($scope.data.selectedItemFilter === $scope.itemsFilterOptions[0].value) {
            filters = { owners: [$scope.as.currentUser.id] };
        } else {
            filters = { creators: [$scope.as.currentUser.id] };
        }

        let isRoot = null;
        if ($scope.data.hierarchy?.key === 'onlyItem' || $scope.data.formItemsHierarchyFilter === 'ROOT_ITEMS') {
            isRoot = true;
        } else if ($scope.data.hierarchy?.key === 'onlyInner') {
            isRoot = false;
        }

        initiativeManager
            .searchInitiatives(
                $scope.pm.project.id,
                null,
                $scope.data.dateRange,
                null,
                null,
                null,
                null,
                filters,
                0,
                REGULAR_INITIATIVES_PAGE_SIZE,
                null,
                null,
                null,
                isRoot,
                $stateParams.formVersionType === 'draft',
            )
            .then(function (initiatives) {
                if (ctrl.onInitiativesLoaded) {
                    ctrl.onInitiativesLoaded(initiatives);
                }

                $scope.data.initiatives = initiatives.initiatives;
                $scope.data.initiativesByGroup = {};

                if ($scope.data.initiatives?.length) {
                    // Clear these containers before re-filling them in the for loop.
                    $scope.data.invisibleGroupsInitiatives = [];

                    for (let i = 0; i < $scope.data.initiatives.length; i++) {
                        const item = $scope.data.initiatives[i];
                        const group = item.group;

                        // Only add items visible to the user to the data.initiatives array and data.initiativesByGroup dict.
                        if (group.isVisibleToUser) {
                            if (!$scope.data.initiativesByGroup[group.id]) {
                                $scope.data.initiativesByGroup[group.id] = [];
                            }

                            // Push item only if the forms dont present only inner items or if it present only inner items and it is an inner item (it has a parent).
                            if (
                                $scope.data.formItemsHierarchyFilter !== 'INNER_ITEMS' ||
                                ($scope.data.formItemsHierarchyFilter === 'INNER_ITEMS' &&
                                    item.parentInitiatives &&
                                    item.parentInitiatives.length)
                            ) {
                                $scope.data.initiativesByGroup[group.id].push(item);
                            }
                        } else {
                            // If this item's group is not visible to the user, we should add it to the "invisibleGroupsInitiatives" bucket.
                            $scope.data.invisibleGroupsInitiatives.push(item);
                        }
                    }

                    // If no specific id was given, auto select the first (not done) one for the user.
                    if (
                        !($location.search().id || $location.search().initiativeId) &&
                        isInit &&
                        $location.search().viewMode !== 'preview'
                    ) {
                        // Filter out done initiatives so we only take the first one that's not done.
                        const activeInitiatives = $scope.data.initiatives.filter(function (item) {
                            return item.status !== 'DONE';
                        });

                        // Only auto navigate to the first active initiative.
                        if (activeInitiatives?.length) {
                            $scope.setCurrentIndex(activeInitiatives[0].id);
                        }
                    }
                }
            })
            .finally(() => {
                $scope.loading = false;
            });
    };

    $scope.setCurrentIndex = function (initiativeId) {
        if ($scope.onInitiativeSelected) {
            $scope.onInitiativeSelected({ initiativeId });
        }
    };

    $scope.setDueFilter = function (f) {
        if (f) {
            const from = new Date();
            from.setDate(from.getDate() - 1);
            const to = new Date(from);
            to.setDate(to.getDate() + f.days + 1);
            $scope.data.dateRange = { from: from.getTime(), to: to.getTime(), label: f.label };
        } else {
            $scope.data.dateRange = null;
        }
        $scope.loadingInner = true;
        $scope.loadMyInitiatives();
    };

    $scope.selectItemFilter = function (selectedOption) {
        $scope.data.selectedItemFilter = selectedOption;
        $scope.loadMyInitiatives();
    };

    $scope.setHierarchyFilter = function (filter) {
        if (filter) {
            $scope.data.hierarchy = $scope.hierarchyFilterOptionsMap[filter.key];
        } else {
            $scope.data.hierarchy = $scope.hierarchyFilterOptions[0];
        }
        $scope.loadingInner = true;
        $scope.loadMyInitiatives();
    };

    $scope.init = function () {
        $scope.loadMyInitiatives(true);
    };

    $scope.init();
}

angular.module('tonkean.app').controller('InitiativesSidebarCtrl', lateConstructController(InitiativesSidebarCtrl));
