export function GoogleCloudModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.as = authenticationService;
    let state;
    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.connect = async function () {
        if ($scope.data.loginModal.$valid) {
            $scope.error = null;
            $scope.loading = true;

            try {
                $scope.integration = await createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        credentialsJson: $scope.data.credentialsJson,
                        workingBucketName: $scope.data.workingBucketName,
                    },
                    integrations.getNewIntegrationUniqueType(authenticationService.currentUser.id, 'GOOGLECLOUD'),
                    undefined,
                );
            } catch (error) {
                $scope.error = error;
            } finally {
                $scope.loading = false;
                const s = {
                    selected: $scope.selected,
                    integration: $scope.integration,
                };

                s.integrations = [
                    {
                        integration: $scope.integration,
                        projectData: {},
                    },
                ];

                if ($scope.onIntegrationClosed) {
                    $scope.onIntegrationClosed(s);
                }
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}

export default angular.module('tonkean.shared').controller('GoogleCloudModalCtrl', GoogleCloudModalCtrl);
