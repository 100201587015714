import template from './tnkMoveToTrackPopover.template.html?angularjs';

function tnkMoveToTrackPopover() {
    return {
        restrict: 'E',
        scope: {
            item: '=initiative',
            onSave: '&',
            onClose: '&',
        },
        transclude: true,
        template,
        controller: 'MoveToTrackPopover',
    };
}
export const tnkMoveToTrackPopoverDirective = angular.module('tonkean.app').directive('tnkMoveToTrackPopover', tnkMoveToTrackPopover);

function MoveToTrackPopover($scope, $timeout, $document) {
    $scope.popover = {};

    $scope.init = function () {
        $scope.popover.search = {
            isOpen: false,
            isFocused: false,
            searchText: '',
            results: [],
        };
    };

    $scope.$watch('item.id', function () {
        $scope.init();
    });

    $scope.$watch('isOpen', function (value) {
        $scope.popover.isOpen = value;
    });

    $scope.onSelect = function ($item) {
        $scope.popover.search.isOpen = false;
        $scope.popover.isActive = false;
        $scope.popover.search.searchText = $item.title;
        $scope.selectedItem = $item;
        $scope.save();
    };

    $scope.onKeyDown = function ($event) {
        if ($scope.popover.search.isOpen) {
            return;
        }

        if ($event.code === 'Escape' || $event.keyCode === 27) {
            // If we are in the track view we don't want to close the track view
            $event.stopPropagation();
            $event.stopImmediatePropagation();

            $scope.close();
        }
    };

    $scope.save = function () {
        $scope.onClose({ item: $scope.item, target: $scope.selectedItem });
    };

    $scope.close = function () {
        $scope.popover.isOpen = false;
        $scope.popover.search.isOpen = false;
        if ($scope.onClose) {
            $scope.onClose();
        }
    };

    $scope.init();
}

export const tnkMoveToTrackPopoverController = angular.module('tonkean.app').controller('MoveToTrackPopover', MoveToTrackPopover);
