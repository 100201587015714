import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { CommonActionButton, CommonActionButtonTypes } from '../CommonWidgetComponents';
import type { UploadWidgetConfiguration } from '../entities';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Breakpoint, DragAndDropFiles } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { ImportFileIcon } from '@tonkean/svg';
import type {
    Initiative,
    TonkeanId,
    TonkeanType,
    TonkeanUploadedFile,
    WidgetBase,
    WidgetParentTypes,
} from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const StyledActionButton = styled(CommonActionButton)`
    max-width: 350px;
    margin-right: 8px;
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 8px;
        margin-right: 0;
    }
`;

const DragAndDropFilesWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px;
`;

const maxFilesSizeMB = 15;

interface Props {
    initiative?: Initiative;
    widget: WidgetBase<UploadWidgetConfiguration, WidgetParentTypes>;
    workflowVersionType?: WorkflowVersionType;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    onFilesAdded: (newInitiatives: Initiative[]) => Promise<void> | void;
    headerBackgroundColor: string;
}

const UploadModal: React.FC<Props> = ({
    initiative,
    widget,
    workflowVersionType,
    groupId,
    onFilesAdded,
    headerBackgroundColor,
}) => {
    const { originatedCustomTriggerId, intakeWorkflowVersion } = useItemInterfaceContext();
    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const [files, setFiles] = useState<TonkeanUploadedFile[]>([]);
    const [errorText, setErrorText] = useState<string | undefined>();
    const [, uploadFiles] = useLazyTonkeanService('uploadFilesFromWidget');

    const onSubmit = useCallback(async () => {
        if (!groupId) {
            setErrorText('Interface must have a group in order to use upload files widget');
            return;
        }

        if (!initiative) {
            setErrorText('Interface must have an Initiative in order to use upload files widget');
            return;
        }

        if (!workflowVersionType) {
            setErrorText('workflowVersionType must be set in order to use upload files widget');
            return;
        }

        const uploadPromise = uploadFiles(
            initiative.id,
            '',
            '',
            workflowVersionType,
            widget.projectId,
            groupId,
            widget.id,
            files,
            originatedCustomTriggerId,
            intakeWorkflowVersion?.groupId,
        );

        setErrorText('');

        return uploadPromise;
    }, [
        originatedCustomTriggerId,
        files,
        groupId,
        initiative,
        uploadFiles,
        widget.id,
        widget.projectId,
        workflowVersionType,
        intakeWorkflowVersion,
    ]);

    const onModalSubmitSuccess = useCallback(
        async (responseValue: { entities: Initiative[] }) => {
            setFiles([]);
            await onFilesAdded(responseValue.entities);
        },
        [onFilesAdded],
    );

    const onExit = useCallback(() => {
        setErrorText('');
        setFiles([]);
    }, []);

    const onModalSubmitError = useCallback((error) => {
        setErrorText(error?.data?.data?.error?.message || 'Something went wrong. Please try again later.');
    }, []);

    return (
        <StyledActionButton
            commonActionButtonType={CommonActionButtonTypes.MODAL}
            buttonBackground={headerBackgroundColor}
            buttonLabel={widget.configuration.uploadFileButtonLabel}
            disabled={
                groupId &&
                (workflowVersionType === WorkflowVersionType.DRAFT
                    ? !groupsMap[groupId].buildEnvironmentEnabled
                    : !groupsMap[groupId].workerEnabled)
            }
            disabledTooltip="Module is disabled"
            submitButtonText="Submit"
            onModalSubmit={onSubmit}
            onModalSubmitError={onModalSubmitError}
            onModalSubmitSuccess={onModalSubmitSuccess}
            submitButtonDisabled={files.length === 0}
            onModalExited={onExit}
            errorMessage={errorText}
            modalContent={
                <DragAndDropFilesWrapper>
                    <DragAndDropFiles
                        mainText="Drag files here to upload"
                        dragAndDropLogo={<ImportFileIcon />}
                        isImageUpload={false}
                        existingFiles={files}
                        setExistingFiles={setFiles}
                        setErrorText={setErrorText}
                        maxFileSizeMB={maxFilesSizeMB}
                        isInPreview={false}
                        isError={!!errorText}
                    />
                </DragAndDropFilesWrapper>
            }
        />
    );
};

export default UploadModal;
