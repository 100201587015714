/**
 * Created by sagieliyahu on 07/10/15.
 */

function ChangeAvatarModalCtrl($scope, $uibModalInstance, tonkeanService, authenticationService) {
    $scope.inProgress = false;
    $scope.myImage = '';
    $scope.myCroppedImage = '';

    $scope.handleFileSelect = function (el) {
        console.log('file changed');
        $scope.error = null;
        const file = el.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', function (evt) {
            $scope.$apply(function ($scope) {
                $scope.myImage = evt.target.result;
            });
        });
        reader.readAsDataURL(file);
    };

    $scope.save = function () {
        if ($scope.myCroppedImage) {
            $scope.error = null;
            $scope.inProgress = true;

            const base64 = $scope.myCroppedImage.slice(22);
            const arrayBuffer = base64ToArrayBuffer(base64);

            tonkeanService
                .setAvatar(arrayBuffer)
                .then(function (user) {
                    if (authenticationService.currentUser) {
                        authenticationService.currentUser.avatarUri = user.avatarUri;
                    }

                    $uibModalInstance.close();
                })
                .catch(function () {
                    $scope.error = 'Could not upload your avatar';
                    $scope.inProgress = false;
                });
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    $scope.onFileChange = function (e) {
        console.log('calling handleFileSelect in the current scope');
        $scope.handleFileSelect(e.target);
    };
}

export const changeAvatarModalCtrl = angular.module('tonkean.app').controller('ChangeAvatarModalCtrl', ChangeAvatarModalCtrl);

export const customOnChange = angular.module('tonkean.app').directive('customOnChange', function () {
    return {
        restrict: 'A',
        link(scope, element, attrs) {
            const onChangeHandler = scope.$eval(attrs.customOnChange);
            element.bind('change', onChangeHandler);
        },
    };
});
