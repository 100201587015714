import template from './tnkFilteredWorkerHistory.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkFilteredWorkerHistory', {
    bindings: {
        hideWorkerSettings: '<',
        groupId: '@',
        closeSidePane: '<',
    },
    template,
    controller: 'filteredWorkerHistoryCtrl',
});
