<div class="margin-left margin-right">
    <div class="margin-bottom" data-automation="tnk-slack-app-custom-actions-definition-template-loading" ng-if="data.loading">
        <i class="loading-small"></i>
    </div>

    <!-- Create channel -->
    <div ng-if="data.definition.customActionKey === 'CREATE_CHANNEL' && !data.loading">
        <!-- Channel -->
        <div class="margin-bottom">
            <!-- Channel name -->
            <div class="margin-right margin-bottom margin-top-xs common-title-xxs">Channel name:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter channel name..."
                saved-original-expression="data.definition.channelName"
                saved-evaluated-expression="data.definition.evaluatedChannelNameExpression"
                on-tonkean-expression-changed="
                    onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
            <!-- Error Section -->
            <div ng-if="data.validationObject.error" class="common-color-danger">{{ data.validationObject.error }}</div>

            <div class="margin-top">
                <tnk-checkbox
                    label="'Make it private?'"
                    value="data.definition.isPrivate"
                    on-toggle-async="onIsPrivateToggled(value)"
                ></tnk-checkbox>
            </div>
        </div>
    </div>
    <!-- Remove user from a conversation -->
    <div ng-if="data.definition.customActionKey === 'REMOVE_USER_FROM_CONVERSATION' && !data.loading">
        <!-- Tonkean users to invite expression input -->
        <div class="margin-right margin-bottom margin-top-xs common-title-xxs">Who should be removed?</div>
    </div>

    <!-- Invite users to channel -->
    <div ng-if="data.definition.customActionKey === 'INVITE_USERS_TO_CHANNEL' && !data.loading">
        <!-- Tonkean users to invite expression input -->
        <div class="margin-right margin-bottom margin-top-xs common-title-xxs">
            Who should be invited to the channel?
        </div>
    </div>

    <!-- Choose users for Invite users to channel or Remove user from a conversation -->
    <div
        ng-if="
            (data.definition.customActionKey === 'INVITE_USERS_TO_CHANNEL' ||
                data.definition.customActionKey === 'REMOVE_USER_FROM_CONVERSATION') &&
            !data.loading
        "
    >
        <tnk-person-selection-configuration
            ng-if="!data.loading"
            class="block margin-bottom-lg"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            radio-id-prefix="'performer-' + data.workflowVersionId"
            validation-object="data.validationObject"
            preview-evaluation-source="data.previewEvaluationSource"
            saved-person-selection-configuration="data.existingUserSelectionConfiguration"
            on-person-selection-configuration-changed="
                onPerformingUserSelected(personSelectionConfiguration, shouldSaveLogic)
            "
            configured-logic="data.configuredLogic"
            support-quick-create="false"
            specific-people-custom-options-refresh="refreshUsers(q)"
            specific-people-custom-options="data.users"
            unique-identifier="performer"
        ></tnk-person-selection-configuration>
    </div>

    <!-- Set channel topic -->
    <div ng-if="data.definition.customActionKey === 'SET_CHANNEL_TOPIC' && !data.loading">
        <!-- Topic -->
        <div class="margin-bottom">
            <!-- Topic field -->
            <div class="margin-right margin-bottom margin-top-xs common-title-xxs">Topic:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Enter channel's topic"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.channelTopicExpression.originalExpression"
                saved-evaluated-expression="data.definition.channelTopicExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onChannelTopicExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
            <!-- Error Section -->
            <div ng-if="data.validationObject.error" class="common-color-danger">{{ data.validationObject.error }}</div>
        </div>
    </div>

    <!-- Set message reaction -->
    <div ng-if="data.definition.customActionKey === 'REACT_ON_MESSAGE' && !data.loading">
        <!-- Reaction -->
        <div class="flex-vmiddle margin-bottom-xs">
            <span class="margin-right">Reaction:</span>

            <!-- Explanation -->
            <div uib-tooltip="Use the reaction name without the ':' prefix and suffix">
                <i class="fa fa-info-circle"></i>
            </div>
        </div>

        <!-- Tonkean custom expression input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Enter reaction"
            saved-expression="data.definition.messageReactionExpression"
            on-tonkean-expression-changed="onMessageReactionExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
        <!-- Error Section -->
        <div ng-if="data.validationObject.error" class="common-color-danger">{{ data.validationObject.error }}</div>
    </div>

    <!-- Create User Group -->
    <div ng-if="data.definition.customActionKey === 'CREATE_USER_GROUP' && !data.loading">
        <!-- User Group Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">User Group Name:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="User Group Name"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupNameExpression"
                expression-unique-identifier="SlackAppUserGroupName"
                on-tonkean-expression-changed="onUserGroupNameExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- User Group Description -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Description:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Description"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupDescriptionExpression"
                expression-unique-identifier="SlackAppUserGroupDescription"
                on-tonkean-expression-changed="onUserGroupDescriptionExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Handle -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Handle:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="A mention handle. Must be unique among channels, users and User Groups. Example 'marketing'"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Handle"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupHandleExpression"
                expression-unique-identifier="SlackAppUserGroupHandle"
                on-tonkean-expression-changed="onUserGroupHandleExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Channels -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Channels:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="A comma separated string of encoded channel IDs for which the User Group uses as a default."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Channels"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupChannelsExpression"
                expression-unique-identifier="SlackAppUserGroupChannels"
                on-tonkean-expression-changed="onUserGroupChannelsExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Team ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Team ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Encoded team id where the user group has to be created, required if org token is used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Team ID"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupTeamIdExpression"
                expression-unique-identifier="SlackAppUserGroupTeamId"
                on-tonkean-expression-changed="onUserGroupTeamIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.error" class="common-color-danger">{{ data.validationObject.error }}</div>
    </div>

    <!-- Update User Group's Users -->
    <div ng-if="data.definition.customActionKey === 'UPDATE_USER_GROUP_USERS' && !data.loading">
        <!-- Users list to add -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                User IDs to Add:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="A comma separated user IDs."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Comma separated user IDs"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupUsersToAddExpression"
                expression-unique-identifier="SlackAppUserToAddGroupUsers"
                on-tonkean-expression-changed="onUserGroupUsersToAddExpressionChanged(expression, shouldSaveLogic)"
                disabled="data.disableUserGroupUsersFields"
            ></tnk-tonkean-expression>
        </div>

        <!-- Users list to remove -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                User IDs to Remove:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="A comma separated user IDs."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Comma separated user IDs"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupUsersToRemoveExpression"
                expression-unique-identifier="SlackAppUserToRemoveGroupUsers"
                on-tonkean-expression-changed="onUserGroupUsersToRemoveExpressionChanged(expression, shouldSaveLogic)"
                disabled="data.disableUserGroupUsersFields"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Or</div>
        </div>

        <!-- Users list override -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Replace All Users With:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="A comma separated user IDs. It will replace the existing list."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Comma separated user IDs"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.userGroupUsersExpression"
                expression-unique-identifier="SlackAppUserGroupUsers"
                on-tonkean-expression-changed="onUserGroupUsersExpressionChanged(expression, shouldSaveLogic)"
                disabled="data.disableOverrideUserGroupUsers"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Update User Group's Users -->
    <div ng-if="data.definition.customActionKey === 'GET_MESSAGE_PERMALINK' && !data.loading">

        <!-- Channel ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Channel ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Channel ID"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.channelIdExpression"
                    expression-unique-identifier="SlackPermalinkChannelId"
                    on-tonkean-expression-changed="onChannelIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Message ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Message ID (Timestamp):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Message ID"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.messageIdExpression"
                    expression-unique-identifier="SlackPermalinkMessageId"
                    on-tonkean-expression-changed="onMessageIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
