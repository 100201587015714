<tags-input
    ng-model="tags"
    display-property="name"
    key-property="{{ isFunc ? 'id' : 'name' }}"
    class="tnk-selector"
    text="latestTagSearch"
    placeholder="{{ placeholder }}"
    add-on-comma="true"
    add-from-autocomplete-only="false"
    maxTags="{{ maxTags }}"
    on-tag-added="onTagAddedFunc($tag)"
    on-tag-removed="onTagRemovedFunc($tag)"
    ng-class="{ 'mod-max': maxTags > 0 && tags.length >= maxTags }"
    template="{{ selectedItemTemplate }}"
    allow-leftover-text="{{ allowLeftoverText }}"
>
    <auto-complete
        source="onRefreshTags($query)"
        min-length="0"
        load-on-focus="true"
        load-on-empty="{{ loadOnEmpty }}"
        max-results-to-show="32"
        template="{{ autoCompleteTemplate }}"
    ></auto-complete>
    <i class="loading-small mod-tags-input" ng-show="loadingTags"></i>
</tags-input>
<script type="text/ng-template" id="function-selected-item">
    <i class="fa fa-building-o margin-left-xs"></i>
    <span>{{$getDisplayText()}}</span>
    <a class="common-link-no-style" ng-click="$removeTag()">×</a>
</script>
<script type="text/ng-template" id="function-autocomplete-item">
    <i class="fa fa-building-o margin-right common-color-blue-dark"></i>
    <span ng-hide="data.isNew" ng-bind-html="$highlight($getDisplayText())"></span>
    <span ng-show="data.isNew" ng-bind="$getDisplayText()"></span>
    <span ng-show="data.isNew" class="elaborate-tag-item-new">[new function]</span>
</script>

<script type="text/ng-template" id="initiative-tag-selected-item">
    <span>{{$getDisplayText()}}</span>
    <a class="" ng-click="$removeTag()">×</a>
</script>
<script type="text/ng-template" id="initiative-tag-autocomplete-item">
    <span ng-hide="data.isNew" ng-bind-html="$highlight($getDisplayText())"></span>
    <span ng-show="data.isNew" ng-bind="$getDisplayText()"></span>
    <span ng-show="data.isNew" class="elaborate-tag-item-new">[new topic]</span>
</script>
