import type React from 'react';
import { useContext, useMemo, useSyncExternalStore } from 'react';

import FormikHelpersContext from './FormikHelpersContext';

const useFormikField = <T>(field: string) => {
    const formikHelpers = useContext(FormikHelpersContext);

    if (!formikHelpers) {
        throw new Error(`useFormikField('${field}') must be a descendant of FormikHelpers`);
    }

    const value = useSyncExternalStore(
        (watcher) => formikHelpers.addValueWatcher(field, watcher),
        () => formikHelpers.getFieldValue<T>(field),
    );
    const error = useSyncExternalStore(
        (watcher) => formikHelpers.addErrorWatcher(field, watcher),
        () => formikHelpers.getFieldError<T>(field),
    );
    const touched = useSyncExternalStore(
        (watcher) => formikHelpers.addTouchedWatcher(field, watcher),
        () => formikHelpers.getFieldTouched<T>(field),
    );

    const helpers = useMemo(
        () => ({
            setValue(newValue: React.SetStateAction<T>) {
                const newValueParsed =
                    typeof newValue === 'function'
                        ? (newValue as (old: T) => T)(formikHelpers.getFieldValue<T>(field))
                        : newValue;
                formikHelpers.getFormik().setFieldValue(field, newValueParsed);
            },
            setError: (newError: string | undefined) => formikHelpers.getFormik().setFieldError(field, newError),
            setTouched: (newTouched: boolean) => formikHelpers.getFormik().setFieldTouched(field, newTouched),
        }),
        [field, formikHelpers],
    );

    return { value, error, touched, ...helpers };
};

export default useFormikField;
