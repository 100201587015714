import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import googleSheetsCRUDGif from '../../../../apps/tracks/images/highlightsExamples/add-update-delete-animate.gif';
import googleSheetsStaticCRUD from '../../../../apps/tracks/images/highlightsExamples/add-update-delete.png';
import googleSheetsAppendGif from '../../../../apps/tracks/images/highlightsExamples/append-animate.gif';
import googleSheetsStaticAppend from '../../../../apps/tracks/images/highlightsExamples/append.png';

import { Radio } from '@tonkean/infrastructure';
import { RadioGroup } from '@tonkean/infrastructure';
import { CheckIconFilledSVG as CheckFilledIcon } from '@tonkean/svg';
import { GoogleSheetsUsageMethodType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const UsageTypeBox = styled.div`
    background: ${Theme.colors.HEX_F7F8FA};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
    height: 325px;
    width: 48%;
    &:hover {
        cursor: pointer;
    }
`;

const UsageTypeBoxShifted = styled.div`
    padding-top: 10px;
    padding-left: 10px;
`;

const UsageTypeBoxRight = styled(UsageTypeBox)`
    margin-left: 20px;
    display: flex;
    flex-direction: column;
`;

const StaticImg = styled.img`
    position: absolute;
    height: 120px;
    width: 255px;

    &:hover {
        opacity: 0;
    }
`;

const CheckFilledIconRight = styled(CheckFilledIcon)`
    margin-right: 6px;
`;

const BulletWrapper = styled.div`
    display: grid;
    grid-template-columns: 20px auto;
`;

const StyledRadio = styled(Radio)`
    margin-bottom: 12px;
    margin-top: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
`;

const BulletText = styled.span<{ errored?: boolean }>`
    ${({ errored = false }) =>
        errored &&
        css`
            color: ${Theme.colors.error};
        `};
`;

interface Props {
    onSelect: (type: string) => void;
    selectedUsageMethod: GoogleSheetsUsageMethodType;
    isEdit: boolean;
    isCrudDisabled: boolean;
    isAppendDisabled: boolean;
}

const GoogleSheetUsageMethod: React.FC<Props> = ({
    onSelect,
    selectedUsageMethod,
    isEdit,
    isCrudDisabled,
    isAppendDisabled,
}) => {
    const [usageMethod, setUsageMethod] = useState<GoogleSheetsUsageMethodType>(selectedUsageMethod);

    return (
        <div>
            <RadioGroup
                radioGroupDisabled={isEdit}
                value={usageMethod}
                onChange={(value) => {
                    setUsageMethod(value);
                    onSelect(value);
                }}
                direction="row"
            >
                <UsageTypeBox
                    onClick={() => {
                        if (!isEdit && !isCrudDisabled) {
                            setUsageMethod(GoogleSheetsUsageMethodType.CRUD);
                            onSelect(GoogleSheetsUsageMethodType.CRUD);
                        }
                    }}
                >
                    <StaticImg src={googleSheetsStaticCRUD as any} alt="Google Sheets static usage method" />
                    <img src={googleSheetsCRUDGif as any} alt="Google Sheets CRUD usage method" />
                    <UsageTypeBoxShifted>
                        <StyledRadio
                            value={GoogleSheetsUsageMethodType.CRUD}
                            disabled={isCrudDisabled}
                            data-automation="icon-input-with-label-undefined"
                        >
                            Add / Update / Delete
                        </StyledRadio>

                        <BulletWrapper>
                            <CheckFilledIconRight />
                            <BulletText>Add, delete and update any row.</BulletText>

                            <CheckFilledIconRight />
                            <BulletText errored={isCrudDisabled}>Limited to 100K rows.</BulletText>

                            <CheckFilledIconRight />
                            <BulletText>
                                Recommended for spreadsheets that contain data that is updated regularly.
                            </BulletText>
                        </BulletWrapper>
                    </UsageTypeBoxShifted>
                </UsageTypeBox>

                <UsageTypeBoxRight
                    onClick={() => {
                        if (!isEdit && !isAppendDisabled) {
                            setUsageMethod(GoogleSheetsUsageMethodType.APPEND);
                            onSelect(GoogleSheetsUsageMethodType.APPEND);
                        }
                    }}
                >
                    <StaticImg src={googleSheetsStaticAppend as any} alt="Google Sheets static usage method" />
                    <img src={googleSheetsAppendGif as any} alt="Google Sheets Append usage method" />
                    <UsageTypeBoxShifted>
                        <StyledRadio value={GoogleSheetsUsageMethodType.APPEND} disabled={isAppendDisabled}>
                            Append
                        </StyledRadio>

                        <BulletWrapper>
                            <CheckFilledIconRight />
                            <BulletText>Append new rows after the last inserted row.</BulletText>

                            <CheckFilledIconRight />
                            <BulletText>Does NOT support update or delete actions.</BulletText>

                            <CheckFilledIconRight />
                            <BulletText errored={isAppendDisabled}>Limited to 500K rows.</BulletText>

                            <CheckFilledIconRight />
                            <BulletText>
                                Recommended for spreadsheets with large data sets that do not change.
                            </BulletText>
                        </BulletWrapper>
                    </UsageTypeBoxShifted>
                </UsageTypeBoxRight>
            </RadioGroup>
        </div>
    );
};

export default GoogleSheetUsageMethod;
