import SAPOpenConnectorAuthenticationComponent from './SAPOpenConnectorAuthenticationComponent';
import sapCloudPlatformCircleImage from '../../../../apps/tracks/images/integrations/sap_cloud_platform-circle.png';
import sapCloudPlatformImage from '../../../../apps/tracks/images/sap_cloud_platform.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

class SAPOpenConnectorIntegration extends IntegrationDefinitionBase {
    override name = 'sapopenconnector';
    override displayName = 'SAP Open Connector';
    override description = 'We will not collect any of your data.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override showEntitiesInboundConfiguration = false;

    override entities = [];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(sapCloudPlatformCircleImage, '130px'),
        new IntegrationIcon(sapCloudPlatformImage, '130px'),
    );

    override customizedAuthenticationComponent = SAPOpenConnectorAuthenticationComponent;
}

export default SAPOpenConnectorIntegration;
