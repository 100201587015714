import { useAngularService, useAngularWatch } from 'angulareact';
import { useMemo } from 'react';

import type { FieldDefinition, FieldInstance, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FieldDefinitionTargetType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { InitiativeFieldValue } from '@tonkean/tonkean-utils';
import type { Either } from '@tonkean/utils';

const getGlobalFieldValueFromCachedMap = (
    requestedVersionMap: string,
    fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}`,
    groupsMap,
): InitiativeFieldValue | undefined => {
    return Object.assign({}, ...Object.values(groupsMap).map((group: any) => group[requestedVersionMap]))[
        fieldDefinitionId
    ];
};

export function getGlobalFieldInstance(
    fieldDefinition: FieldDefinition,
    workflowVersionType: WorkflowVersionType | undefined,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    groupsMap,
): InitiativeFieldValue | undefined {
    if (workflowVersionType && fieldDefinition?.targetType === FieldDefinitionTargetType.GLOBAL) {
        if (groupId) {
            if (workflowVersionType === WorkflowVersionType.DRAFT) {
                return groupsMap[groupId]?.globalFieldsDraftMap[fieldDefinition.id];
            } else {
                return groupsMap[groupId]?.globalFieldsMap[fieldDefinition.id];
            }
        }
        if (workflowVersionType === WorkflowVersionType.DRAFT) {
            return getGlobalFieldValueFromCachedMap('globalFieldsDraftMap', fieldDefinition.id, groupsMap);
        } else {
            return getGlobalFieldValueFromCachedMap('globalFieldsMap', fieldDefinition.id, groupsMap);
        }
    }
}

const useGetGlobalFieldInstance = (
    fieldDefinition: FieldDefinition,
    groupId: TonkeanId<TonkeanType.GROUP>,
    workflowVersionType: WorkflowVersionType | undefined,
) => {
    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    return useMemo<Either<FieldInstance, InitiativeFieldValue> | undefined>(() => {
        return getGlobalFieldInstance(fieldDefinition, workflowVersionType, groupId, groupsMap);
    }, [fieldDefinition, workflowVersionType, groupId, groupsMap]);
};

export default useGetGlobalFieldInstance;
