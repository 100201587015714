import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SearchTooltipIcon } from '../../../../../../images/icons/history/search-tooltip.svg';

import { Popover, useToggle } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { TooltipColor } from '@tonkean/tui-theme/colors';

const ErrorPopoverButton = styled(Clickable)`
    display: flex;
    align-items: center;
`;

interface Props {
    error: string;
}

const ProjectIntegrationCollectStatusLastCollectErrorTooltip: React.FC<Props> = ({ error }) => {
    const [popoverOpen, togglePopoverOpen, setPopoverOpen] = useToggle(false);

    return (
        <Popover
            show={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            content={<>{error}</>}
            color={TooltipColor.DARK}
        >
            <ErrorPopoverButton onClick={togglePopoverOpen}>
                <SearchTooltipIcon />
            </ErrorPopoverButton>
        </Popover>
    );
};

export default ProjectIntegrationCollectStatusLastCollectErrorTooltip;
