import { fieldTypeToPresetItemInterfaceWidgetTypes } from './FieldTypeToPresetItemInterfaceWidgetTypes';
import type QuestionWidgetConfiguration from './QuestionWidgetConfiguration';
import { FieldsSelectorByFieldTypeGroupSummary } from '../CommonWidgetConfiguration';
import type RichTextEditorInputWidgetConfiguration from '../RichTextEditorInputWidget/RichTextEditorInputWidgetConfiguration';

import { FieldType, fieldTypeToFieldDisplayName } from '@tonkean/tonkean-entities';

const widgetGroupedFieldsUtils = (
    configuration: QuestionWidgetConfiguration | RichTextEditorInputWidgetConfiguration,
): FieldsSelectorByFieldTypeGroupSummary[] => {
    return Object.values(FieldType).map((value) => {
        const fieldsSelectorByFieldTypeGroupSummary = new FieldsSelectorByFieldTypeGroupSummary();

        fieldsSelectorByFieldTypeGroupSummary.fieldType = value;
        fieldsSelectorByFieldTypeGroupSummary.name = fieldTypeToFieldDisplayName[value];
        fieldsSelectorByFieldTypeGroupSummary.orderNumber = fieldTypeToPresetItemInterfaceWidgetTypes[value].includes(
            configuration.widgetSubType,
        )
            ? 100
            : 0;

        return fieldsSelectorByFieldTypeGroupSummary;
    });
};

export default widgetGroupedFieldsUtils;
