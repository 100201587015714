import React from 'react';
import styled from 'styled-components';

import { ModalBody, ModalHeader, XCloseButton } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StyledModalHeader = styled(ModalHeader)`
    justify-content: space-between;
`;

const Title = styled.span`
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_700};
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    // Overriding user agents ul style
    padding: 0;
`;

const ListItem = styled.li`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    display: flex;
    align-items: center;
    padding-left: 22px;
`;

interface Props {
    onClose(): void;
    header: string;
    data: string[];
}

const SolutionReportListItemMetricModal: React.FC<Props> = ({ onClose, header, data }) => {
    return (
        <>
            <StyledModalHeader $border $flex>
                <Title>{header}</Title>
                <XCloseButton size={ButtonSize.MEDIUM} onClick={onClose} />
            </StyledModalHeader>
            <ModalBody removePadding>
                <List>
                    {/* List of non unique strings, we don't have a better key */}
                    {data.map((item, index) => (
                        <ListItem key={index}>
                            <span>{item}</span>
                        </ListItem>
                    ))}
                </List>
            </ModalBody>
        </>
    );
};

export default SolutionReportListItemMetricModal;
