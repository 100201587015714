import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { ProjectIntegrationActionManagerTabKey } from '../../../../../../../components/state.product.projectIntegrationActionManager/ProjectIntegrationActionManagerTabKey';

import {
    HighlightableText,
    Placeholder,
    PlaceholderGrid,
    PlaceholderGridDirection,
    TextEllipsis,
} from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 750px;
    max-height: 80vh;

    overflow: auto;
    ${childrenStyledFocus}
`;

const SingleActionRow = styled(Clickable)<{ $isFirst: boolean }>`
    background-color: transparent;
    line-height: 43px;
    height: 43px;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    border-bottom: 1px solid ${Theme.colors.gray_300};

    display: flex;
    align-items: center;

    ${({ $isFirst }) =>
        $isFirst &&
        css`
            border-top: 1px solid ${Theme.colors.gray_300};
        `};

    &:hover {
        background-color: #f9f9f7;
    }
`;

const EditChevron = styled.i`
    margin-left: auto;
    margin-right: 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
`;

const ProjectIntegrationIconView = styled(ProjectIntegrationIcon)`
    margin-right: 10px;
`;

const ActionNameLabel = styled(TextEllipsis)`
    font-style: normal;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    flex-grow: 1;
`;

const ActionPlaceholderWrapper = styled(PlaceholderGrid)`
    margin-top: 26px;
`;

const ActionsListHeader = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    color: ${Theme.current.palette.mainColors.gray_600};
`;

const ActionsList = styled.div`
    overflow-y: scroll;
`;

export interface ProjectIntegrationActionForView {
    id: string;
    displayName: string;
    description?: string;
    isCustom: boolean;
}

interface Props {
    projectIntegrationActions: ProjectIntegrationActionForView[];
    loading: boolean;
    filterValue: string;
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationActionView: React.FC<Props> = ({
    projectIntegrationActions,
    filterValue,
    projectIntegration,
    loading,
}) => {
    const filterCustomActions = useMemo(() => {
        return projectIntegrationActions?.filter((action) =>
            action.displayName.toLowerCase().includes(filterValue.toLowerCase()),
        );
    }, [projectIntegrationActions, filterValue]);

    const [customActions, nativeAction] = useMemo(() => {
        return [
            filterCustomActions.filter((action) => action.isCustom),
            filterCustomActions.filter((action) => !action.isCustom),
        ] as const;
    }, [filterCustomActions]);

    return (
        <ActionsWrapper>
            {customActions && customActions.length > 0 && (
                <ActionsListHeader>Custom Actions ({customActions?.length || 0})</ActionsListHeader>
            )}

            <ActionsList>
                {customActions?.map((action: ProjectIntegrationActionForView, index: number) => (
                    <SingleActionRow
                        key={action.id}
                        $isFirst={index === 0}
                        state="product.projectIntegrationActionManager"
                        params={{
                            tab: ProjectIntegrationActionManagerTabKey.GENERAL,
                            projectIntegrationActionId: action.id,
                            projectIntegrationId: projectIntegration.id,
                        }}
                    >
                        <ProjectIntegrationIconView
                            iconUrl={projectIntegration.iconUrl}
                            integrationType={projectIntegration.integration.integrationType.toLowerCase()}
                            width={20}
                        />

                        <ActionNameLabel>
                            <HighlightableText text={action.displayName} highlightText={filterValue} />
                        </ActionNameLabel>

                        <EditChevron className="fa fa-chevron-right" />
                    </SingleActionRow>
                ))}
            </ActionsList>

            {nativeAction?.length > 0 && (
                <ActionsListHeader>Native Actions ({nativeAction?.length || 0})</ActionsListHeader>
            )}

            <ActionsList>
                {nativeAction?.map((action, index: number) => (
                    <SingleActionRow key={action.id} $isFirst={index === 0}>
                        <ProjectIntegrationIconView
                            iconUrl={projectIntegration.iconUrl}
                            integrationType={projectIntegration.integration.integrationType.toLowerCase()}
                            width={20}
                        />

                        <ActionNameLabel>
                            <HighlightableText text={action.displayName} highlightText={filterValue} />
                        </ActionNameLabel>
                    </SingleActionRow>
                ))}
            </ActionsList>

            {loading && (
                <ActionPlaceholderWrapper direction={PlaceholderGridDirection.ROWS}>
                    {range(5).map((i) => (
                        <Placeholder key={i} $height="41px" $width="750px" />
                    ))}
                </ActionPlaceholderWrapper>
            )}
        </ActionsWrapper>
    );
};

export default ProjectIntegrationActionView;
