/**
 * Factory method
 * @return {PageLoadState}
 */
function PageLoadStateFactory($log, $timeout) {
    /**
     * A class that holds the loading state of multiple fragments.
     * Once all fragments are done, the done field is true.
     * @constructor Creates a new instance.
     */
    function PageLoadState(page) {
        const _this = this;
        _this._fragmentsState = [];
        _this.done = false;
        _this.timer = $timeout(onTimeout, 15_000);
        _this.pageName = page;

        // console.time('PageLoad-' + _this.pageName);
        /**
         * Checks if all fragment state are true
         */
        function updateDone() {
            _this.done = _this._fragmentsState.every(angular.identity);
            if (_this.done) {
                $timeout.cancel(_this.timer);
                $log.debug('Page load done!');
                // console.timeEnd('PageLoad-' + _this.pageName);
                // Feature detects Navigation Timing API support.
                if (window.performance) {
                    // Gets the number of milliseconds since page load
                    // (and rounds the result since the value must be an integer).
                    const timeSincePageLoad = Math.round(performance.now());
                    // $log.debug('Page load ' + timeSincePageLoad + 'ms');
                    // Sends the timing hit to Google Analytics.
                    ga('send', 'timing', _this.pageName, 'firstLoad', timeSincePageLoad);
                }
            }
        }

        /**
         * Prints warning log after timeout.
         */
        function onTimeout() {
            if (!_this.done) {
                $log.warn('Page load did not finished.', _this._fragmentsState);
            }
        }

        /**
         * Inner class to handle the state of a single fragment.
         */
        function FragmentState(name) {
            const partialIndex = _this._fragmentsState.push(false) - 1;
            $log.debug('Creating fragment', partialIndex, name);

            updateDone();

            /**
             * Call this when the fragment is loaded.
             */
            this.complete = function () {
                if (!_this._fragmentsState[partialIndex]) {
                    $log.debug('Completed fragment', partialIndex, name);
                    _this._fragmentsState[partialIndex] = true;
                    updateDone();
                }
            };
        }

        /**
         * Creates a new instance of a fragment.
         * One this fragment is done loading you should call it's complete() method.
         * @param [name] {String} The name of the fragment.
         * @return {FragmentState}
         */
        _this.createFragment = function (name) {
            return new FragmentState(name);
        };
    }

    return PageLoadState;
}
export default angular.module('tonkean.app').factory('PageLoadState', PageLoadStateFactory);
