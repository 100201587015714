import { lateConstructController } from '@tonkean/angular-components';
import { TONKEAN_ENTITY_TYPE, getFieldDefinitionConfigurationModalSteps } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';

/* @ngInject */
function ManageTriggersCtrl(
    $scope,
    $state,
    $rootScope,
    projectManager,
    trackHelper,
    customFieldsManager,
    tonkeanService,
    modalUtils,
    groupInfoManager,
    utils,
    educationManager,
    communicationIntegrationsService,
    workflowVersionManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.educationManager = educationManager;
    $scope.trackHelper = trackHelper;
    $scope.modalUtils = modalUtils;
    $scope.cis = communicationIntegrationsService;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        onClose: ctrl.onClose,
        groupId: ctrl.groupId,
        hideBotToggle: ctrl.hideBotToggle,
        hideCloseBtn: ctrl.hideCloseBtn,
        modSlim: ctrl.modSlim,
        viewOnly: ctrl.viewOnly,

        showListSelection: !ctrl.groupId,

        customTriggers: [],
        rangesConfigurationStepId: getFieldDefinitionConfigurationModalSteps().rangesConfiguration.id,
        workflowVersionId: workflowVersionManager.getPublishedVersionFromCache(ctrl.groupId).id,

        loadingCustomTriggers: false,
        customTriggersError: null,

        loadingToggleBotLive: false,
        errorToggleBotLive: false,
    };

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        if (!$scope.data.groupId) {
            $scope.data.groupId = projectManager.groupDefaultId;
            $scope.data.selectedGroup = projectManager.groupsMap[$scope.data.groupId];
            $scope.data.loadingCustomTriggers = true;
        }

        customFieldsManager.getFieldDefinitions($scope.data.workflowVersionId).then(function () {
            $scope.loadCustomTriggers();
        });

        window.Intercom('trackEvent', 'View ManageTriggers');
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        if ($scope.data.onClose) {
            $scope.data.onClose();
        }
    };

    /**
     * Toggles the should send gather updates property of the group.
     */
    $scope.toggleBotLive = function () {
        $scope.data.loadingToggleBotLive = true;
        $scope.data.errorToggleBotLive = null;

        workflowVersionManager
            .toggleShouldSendGatherUpdates(
                $scope.data.groupId,
                !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).shouldSendGatherUpdates,
            )
            .catch(function () {
                $scope.data.errorToggleBotLive = 'There was an error trying to update the module.';
            })
            .finally(function () {
                $scope.data.loadingToggleBotLive = false;
            });
    };

    /**
     * Opens the custom trigger modal.
     */
    $scope.openCustomTriggerModal = function (customTrigger) {};

    /**
     * Opens the trigger gallery modal.
     */
    $scope.openTriggersGalleryModal = function () {
        if ($scope.data.viewOnly) {
            $state.go('triggerGallery');
        } else {
            $state.go('product.triggers', { tab: 'gallery' });
        }

        $scope.cancel();
        // modalUtils.openTriggersGalleryModal(true);
    };

    /**
     * Loads the custom triggers of a group.
     */
    $scope.loadCustomTriggers = function () {
        $scope.data.customTriggersError = null;
        $scope.data.loadingToggleBotLive = false;
        $scope.data.errorToggleBotLive = null;
        $scope.data.loadingCustomTriggers = true;

        let getTriggersPromise;
        if ($scope.data.viewOnly && $scope.data.viewOnly.token) {
            getTriggersPromise = tonkeanService.getCustomTriggersByGuestToken($scope.data.viewOnly.token, true);
        } else {
            getTriggersPromise = tonkeanService.getCustomTriggersOfWorkflowVersion(
                $scope.data.workflowVersionId,
                true,
                true,
            );
        }

        getTriggersPromise
            .then(function (data) {
                $scope.data.customTriggers = data.entities;

                // Evaluating the project integrations inside the custom triggers
                for (let i = 0; i < $scope.data.customTriggers.length; i++) {
                    const customTrigger = $scope.data.customTriggers[i];

                    if (
                        $scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId] &&
                        $scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId].length
                    ) {
                        const fieldDefinitionsInTriggerConditions = traverseQueryForFieldDefinitions(
                            customTrigger.queryDefinition.query,
                        );

                        if (fieldDefinitionsInTriggerConditions) {
                            const fieldDefIdToFieldDefMap = utils.createMapFromArray(
                                $scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId],
                                'id',
                            );
                            const projectIntegrationsInTriggerConditions = [];
                            const projectIntegrationIdToProjectIntegrationMap = {};

                            for (const fieldDefinitionsInTriggerCondition of fieldDefinitionsInTriggerConditions) {
                                const fieldDefinition = fieldDefIdToFieldDefMap[fieldDefinitionsInTriggerCondition];

                                if (
                                    fieldDefinition &&
                                    fieldDefinition.projectIntegration &&
                                    !projectIntegrationIdToProjectIntegrationMap[fieldDefinition.projectIntegration.id]
                                ) {
                                    projectIntegrationsInTriggerConditions.push(fieldDefinition.projectIntegration);
                                    projectIntegrationIdToProjectIntegrationMap[
                                        fieldDefinition.projectIntegration.id
                                    ] = true;
                                }
                            }

                            customTrigger.projectIntegrationsInTriggerConditions =
                                projectIntegrationsInTriggerConditions;
                        }
                    }
                }
            })
            .catch(function () {
                $scope.data.customTriggersError = 'There was an error trying to get custom triggers for this list.';
            })
            .finally(function () {
                $scope.data.loadingCustomTriggers = false;
            });
    };

    /**
     * Toggles the disabled property of the given custom trigger.
     */
    $scope.toggleCustomTriggerDisabled = function ($event, customTrigger) {
        // Stop bubbling of click event.
        $event.stopPropagation();

        customTrigger.loadingToggleDisabled = true;
        customTrigger.errorToggleDisabled = null;

        tonkeanService
            .updateCustomTriggerDisableMode(customTrigger.id, !customTrigger.disabled)
            .then(function (data) {
                customTrigger.disabled = data.disabled;

                // Getting groups as they are enriched using custom triggers data, and otherwise wouldn't be updated.
                groupInfoManager.getGroup($scope.data.groupId, true);
            })
            .catch(function () {
                customTrigger.errorToggleDisabled = 'There was an error trying to update custom trigger.';
            })
            .finally(function () {
                customTrigger.loadingToggleDisabled = false;
            });
    };

    $scope.openFieldDefinitionModal = function (field) {
        $rootScope.$broadcast('createNewField', [
            $scope.data.groupId,
            field.targetType,
            field.type,
            field.projectIntegration,
            false,
            false,
            field,
            null,
            null,
            $scope.data.rangesConfigurationStepId,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.data.workflowVersionId,
        ]);
    };

    /**
     * A filter to use to filter out empty ranges
     * @param field
     * @returns {boolean|*}
     */
    $scope.filterEmptyColumnTriggers = function (field) {
        return field.ranges && field.ranges.length;
    };

    /**
     * Selects the given group.
     */
    $scope.selectGroup = function (group) {
        $scope.data.groupId = group ? group.id : null;

        if ($scope.data.groupId) {
            $scope.data.viewOnly = !$scope.pm.groupsMap[$scope.data.groupId].canUserWrite;
        }

        $scope.loadCustomTriggers();
    };

    /**
     * Getting the field definitions participating in the trigger conditions.
     * @param query
     * @returns {Array}
     */
    function traverseQueryForFieldDefinitions(query) {
        let fieldDefinitionsInFilters = [];

        for (let i = 0; i < query.filters.length; i++) {
            const currentFilter = query.filters[i];

            if (
                currentFilter &&
                getTonkeanEntityType(currentFilter.fieldName) ===
                TONKEAN_ENTITY_TYPE.FIELD_DEFINITION
            ) {
                fieldDefinitionsInFilters.push(currentFilter.fieldName);
            }
        }

        if (query.queries && query.queries.length) {
            for (let i = 0; i < query.queries.length; i++) {
                const innerQuery = query.queries[i];
                const fieldDefinitionsInInnerQuery = traverseQueryForFieldDefinitions(innerQuery);

                fieldDefinitionsInFilters = fieldDefinitionsInFilters.concat(fieldDefinitionsInInnerQuery);
            }
        }

        return fieldDefinitionsInFilters;
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageTriggersCtrl', lateConstructController(ManageTriggersCtrl));
