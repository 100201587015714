import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const AmazonKinesisComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    projectIntegration,
    createProjectApis,
    onChangeOrInitIntegration,
}) => {
    const [streamList, setStreamList] = useState([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [currentStream, setCurrentStream] = useState<{ value: string; label: string }>();
    const [isLoadingStreams, setIsLoadingStreams] = useState(false);
    const projectManager = useAngularService('projectManager');

    // On every change of the project integration, we initialize current stream from project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setCurrentStream(projectData.stream);
        }
    }, [projectIntegration]);

    // On every change of the integration, we reset the state if the integration id was changed.
    useEffect(() => {
        // If the user changed the integration credentials (logged in to a different user) -
        // it means that the current project data is irrelevant and we should reset the component state.
        if (integration?.id !== projectIntegration?.integration?.id) {
            setCurrentStream(undefined);
        }
    }, [integration, projectIntegration]);

    // Load stream options.
    useEffect(() => {
        const fetchStreams = async () => {
            setIsLoadingStreams(true);
            try {
                const innerStreams = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'streams',
                );
                const autoCompleteOptions = constructAutoCompleteOptions(innerStreams.options);
                setStreamList(autoCompleteOptions);
            } catch {
                setError('Error occurred while fetching streams.');
            } finally {
                setIsLoadingStreams(false);
            }
        };
        fetchStreams();
    }, [createProjectApis, integration, projectManager.project.id]);

    const onStreamChanged = useCallback((stream) => {
        setCurrentStream(stream);
    }, []);

    useEffect(() => {
        if (!currentStream) {
            return;
        }

        const projectIntegrationDisplayName = `Amazon Kinesis (${currentStream?.label})`;
        const projectData = {
            stream: currentStream?.value,
        };

        createProjectApis.getAutoCompleteOptions(projectManager.project.id, integration.id, 'streams').then(() => {
            onChangeOrInitIntegration(
                { projectIntegrationData: projectData, projectIntegrationDisplayName },
                !!!currentStream,
            );
        });
    }, [createProjectApis, currentStream, integration.id, onChangeOrInitIntegration, projectManager.project.id]);

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
        }));
    };

    return (
        <div className="flex flex-col">
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}

            <div className="flex flex-col">
                {isLoadingStreams && (
                    <div className="text-center margin-top-lg">
                        <i className="loading-medium" />
                    </div>
                )}

                <div className="flex-vmiddle margin-bottom">
                    <b>Select a Stream:</b>
                </div>

                <TnkSelect
                    value={currentStream}
                    className="margin-bottom"
                    isMulti={false}
                    isCreatable={false}
                    onChange={onStreamChanged}
                    isClearable={false}
                    options={streamList}
                    closeMenuOnSelect
                    isSearchable
                />
            </div>
        </div>
    );
};

export default AmazonKinesisComponent;
