import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionFetcherMenu from './ProjectIntegrationActionFetcherMenu';
import { ReactComponent as Settings } from '../../../../images/icons/settings.svg';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import type { ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Title = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div`
    margin-right: 6px;
`;

const FetcherDefaultMessage = styled.span`
    margin-left: 19px;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
    font-style: italic;
`;

const StyledStateLink = styled(StateLink)`
    color: ${Theme.colors.primary};
`;

function getProjectIntegrationActionFetcherAsTableRow(
    fetcherAction: ProjectIntegrationAction,
    entity: ProjectIntegrationEntity,
    props: AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>,
    defaultFetcherActonId: string,
    onDelete: (itemId: string) => void,
) {
    return {
        name: (
            <Title>
                <IconWrapper>
                    <Settings />
                </IconWrapper>
                <StyledStateLink
                    state="product.projectIntegrationPageEntityFetcher"
                    params={{
                        enterpriseComponentId: fetcherAction.projectIntegrationId,
                        entityId: entity.id,
                        fetcherActionId: fetcherAction.id,
                        entity,
                        props,
                    }}
                >
                    {fetcherAction.displayName}{' '}
                </StyledStateLink>

                {defaultFetcherActonId === fetcherAction.id ? (
                    <FetcherDefaultMessage>(Use for single collect)</FetcherDefaultMessage>
                ) : (
                    ''
                )}
            </Title>
        ),
        actions: (
            <>
                {defaultFetcherActonId !== fetcherAction.id && (
                    <ProjectIntegrationActionFetcherMenu
                        fetcherAction={fetcherAction}
                        entity={entity}
                        setEntity={props.setEntity}
                        onDelete={onDelete}
                    />
                )}
            </>
        ),
    };
}

export default getProjectIntegrationActionFetcherAsTableRow;
