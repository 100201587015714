import React from 'react';
import styled from 'styled-components';

import SearchWidgetCardsDensityConfigurations from './SearchWidgetCardsDensityConfigurations';
import SearchWidgetItemsList from './SearchWidgetItemsList';
import SearchWidgetLoadingImage from './SearchWidgetLoadingImage';
import { SearchWidgetResultItemBox } from './SearchWidgetResultItem';
import { SearchWidgetCardsDensity } from '../SearchWidgetConfiguration';

import { Breakpoint, Placeholder, useBreakpoint } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

const TextContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
    padding: 10px 0 0;
`;

interface Props {
    count?: number;
    cardsDensity: SearchWidgetCardsDensity;
}

const SearchWidgetLoadingSkeletonState: React.FC<Props> = ({ count = 2, cardsDensity }) => {
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint < Breakpoint.SMALL_1366;
    const cardsDensityConfiguration = SearchWidgetCardsDensityConfigurations[cardsDensity];

    return (
        <SearchWidgetItemsList $cardsDensity={cardsDensity} $itemsGap={cardsDensityConfiguration.itemsGap}>
            {range(count).map((number) => {
                return (
                    <SearchWidgetResultItemBox
                        key={number}
                        $selected={false}
                        $selectedColor=""
                        $gap={cardsDensityConfiguration.gap}
                        $cardsDensity={cardsDensity}
                    >
                        {!isMobile && <Placeholder $width="18px" $height="18px" $borderRadius="50%" $noAnimation />}
                        <SearchWidgetLoadingImage cardsDensityConfiguration={cardsDensityConfiguration} />
                        <TextContainer>
                            <Placeholder $width="100%" $height="16px" $noAnimation />
                            <Placeholder $width="80%" $height="16px" $noAnimation />
                            {cardsDensity === SearchWidgetCardsDensity.SPACED && (
                                <Placeholder $width="90%" $height="16px" $noAnimation />
                            )}
                        </TextContainer>
                    </SearchWidgetResultItemBox>
                );
            })}
        </SearchWidgetItemsList>
    );
};

export default SearchWidgetLoadingSkeletonState;
