import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import isValidWorkflowDataStorageConfiguration from '../sharedConfigComponents/validators/isValidWorkflowDataStorageConfiguration';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const OcrConversaionLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.OCR_CONVERSION,
    title: 'OCR Conversion',
    iconClass: 'mod-ocr-trigger',
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    description: 'Converts input file into textual output. File can be of PDF/TIFF/JPEG/PNG formats.',
    getLinkedFieldDefinitionsConfiguration: () => {
        return [
            {
                field: 'OCR_EXTRACTED_FULL_TEXT',
                friendlyName: 'Extracted text',
            },
            {
                field: 'OCR_DETAILED_OUTPUT',
                friendlyName: 'Detailed output',
            },
        ];
    },
    validator({ definition, group, syncConfigCacheManager, workflowVersionInfoRetriever, projectManager }) {
        const validationObject: LogicBlockValidationObject = {};

        const validUrlSource = definition.source?.type === 'URL' && definition.source?.url?.evaluatedExpression;
        const validUrlSourceUsingNewConfiguration =
            definition.source?.fileSourceType === 'URL' && definition.source?.url?.evaluatedExpression;
        const validDataStorageInput =
            definition.source?.fileSourceType === 'DATA_STORAGE' && definition.source?.extendedMatchConfiguration;
        const validStaticAssetSource =
            definition.source?.fileSourceType === 'UPLOAD_FILE' &&
            definition.source?.workerStaticAssetId &&
            definition.source?.workerStaticAssetDisplayName;
        const validIntegrationSource =
            definition.source?.type === 'Integration' &&
            definition.source?.storageIntegrationId &&
            definition.source?.fileId?.evaluatedExpression;

        if (
            !validUrlSource &&
            !validUrlSourceUsingNewConfiguration &&
            !validIntegrationSource &&
            !validStaticAssetSource &&
            !validDataStorageInput
        ) {
            validationObject.noSource = 'Must fill file source.';
        }

        if (
            !isValidWorkflowDataStorageConfiguration(
                definition,
                group,
                workflowVersionInfoRetriever,
                syncConfigCacheManager,
                projectManager,
            )
        ) {
            validationObject.invalidFileChoice =
                'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default OcrConversaionLogicBlockConfig;
