import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useSequentialIdentifier } from '@tonkean/infrastructure';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { EntityDataRetentionSettings } from '@tonkean/tonkean-entities';

export type EntityDataRetentionCollectStatus =
    | { isLoading: false; isEnabled: boolean; isEditable: boolean; tooltip?: string }
    | { isLoading: true; isEnabled?: boolean; isEditable?: boolean; tooltip?: string };

export interface EntityRetentionSummary {
    reactIdForRender: string;
    id: string;
    activeRecords?: number;
    collectStatus: EntityDataRetentionCollectStatus;
    retention: {
        isIndefinite: { isEditable: boolean; value: boolean | null };
        expirationDays: { isEditable: boolean; value: number | null };
        tooltip?: string;
    };
    isDeletable: boolean;
}

const useDataRetentionConfiguration = (
    projectIntegration: ProjectIntegration,
    shouldCalculateActiveRecords: boolean,
) => {
    const shouldShowInboundConfiguration = useFeatureFlag('tonkean_feature_entities_inbound_configuration');
    const allowToggleIsCollectEnabledOfNativeEntities = shouldShowInboundConfiguration || false;
    const integrations = useAngularService('integrations');
    const { incrementValue } = useSequentialIdentifier(0);

    // list of all the configured retention for the entities.
    const [updatedEntitiesConfiguration, setUpdatedEntitiesConfiguration] = useState<EntityRetentionSummary[]>([]);

    // Gets all the related custom entities of this project integration.
    const { data: projectIntegrationSummaries } = useTonkeanService(
        'getProjectIntegrationEntitySummaries',
        projectIntegration.id,
    );

    // Gets the consts configuration for native entities.
    const constConfiguration = useMemo(() => {
        const constConfig = integrations.getDataRetentionSettingsByIntegration(projectIntegration.integrationType);

        return {
            removableEntities: constConfig?.removableEntities || {},
            ignoreEntities: constConfig?.ignoreEntities || [],
            canAddOrRemoveAll: constConfig?.canAddOrRemoveAll || false,
            isSupportsDataRetention: constConfig?.isSupportsDataRetention || false,
            editableEntities: constConfig?.editableEntities || [],
            canEditAll: constConfig?.canEditAll || false,
        };
    }, [integrations, projectIntegration.integrationType]);

    // Get entity id to document count.
    const [{ data: entityIdToDocumentsCount, error: getDocumentsCountError }, getDocumentsCount] =
        useLazyTonkeanService('getProjectDocumentsCountPerIntegrationPerEntity');

    // get data retention configuration
    const {
        data: retentionConfiguration,
        loading,
        error,
    } = useTonkeanService('getProjectIntegrationDataRetentionConfiguration', projectIntegration.id);

    // Calculate active records only when shouldCalculateActiveRecords is true.
    useEffect(() => {
        if (shouldCalculateActiveRecords) {
            getDocumentsCount(projectIntegration.projectId, [projectIntegration.id]);
        }
    }, [getDocumentsCount, projectIntegration.projectId, projectIntegration.id, shouldCalculateActiveRecords]);

    // get is deletable for specific entity
    const getIsDeletable = useCallback(
        (entityId: string) => {
            // Custom entities can't be removed from the data retention.
            if (entityId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY])) {
                return false;
            }

            if (constConfiguration.removableEntities[entityId]) {
                return true;
            }

            if (constConfiguration.canAddOrRemoveAll) {
                return true;
            }

            return false;
        },
        [constConfiguration.canAddOrRemoveAll, constConfiguration.removableEntities],
    );

    // get is editable for specific native entity
    const isNativeEntityDataRetentionEditable = useCallback(
        (entityId: string): boolean => {
            return constConfiguration.canEditAll || constConfiguration.editableEntities[entityId] || false;
        },
        [constConfiguration.canEditAll, constConfiguration.editableEntities],
    );

    // get is indefinite for specific entity
    const getIsIndefinite = useCallback(
        (entityRetentionSetting: EntityDataRetentionSettings) => {
            const isIndefinite = entityRetentionSetting.isIndefinite !== null;

            if (
                entityRetentionSetting.id.startsWith(
                    tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY],
                )
            ) {
                return { isEditable: true, value: isIndefinite };
            } else {
                return {
                    isEditable: isNativeEntityDataRetentionEditable(entityRetentionSetting.id) || false,
                    value: isIndefinite,
                };
            }
        },
        [isNativeEntityDataRetentionEditable],
    );

    // get the amount  indefinite for specific entity
    const getExpirationDays = useCallback(
        (entityRetentionSetting: EntityDataRetentionSettings) => {
            if (
                entityRetentionSetting.id.startsWith(
                    tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY],
                )
            ) {
                return { isEditable: true, value: entityRetentionSetting.expirationDays };
            } else {
                return {
                    isEditable: isNativeEntityDataRetentionEditable(entityRetentionSetting.id),
                    value: entityRetentionSetting.expirationDays,
                };
            }
        },
        [isNativeEntityDataRetentionEditable],
    );

    // Map of entity id to is collect enable summary.
    const entityIdToIsCollectEnabled: Record<string, EntityDataRetentionCollectStatus> = useMemo(() => {
        const entityIdToCollectStatusEntries = Object.keys(
            retentionConfiguration?.dataRetentionConfiguration?.entitiesConfiguration || {},
        ).map((entityId) => {
            if (entityId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY])) {
                const entity = projectIntegrationSummaries?.entities?.find((entity) => entity.id === entityId);

                if (entity === undefined) {
                    return [entityId, { isLoading: true }];
                } else {
                    return [entityId, { isLoading: false, isEnabled: entity.isCollectEnabled, isEditable: true }];
                }
            } else {
                // This is copied from the angular view. do we want to split it into 2 toggles?
                const inboundConfiguration = projectIntegration.nativeEntitiesInboundConfiguration[entityId];

                const isEditable =
                    allowToggleIsCollectEnabledOfNativeEntities &&
                    (inboundConfiguration ? isNativeEntityDataRetentionEditable(entityId) : false);

                const entityIdToCollectStatus: [string, EntityDataRetentionCollectStatus] = [
                    entityId,
                    {
                        isEnabled:
                            !!inboundConfiguration?.collectConfiguration.isCollectEnabled ||
                            !!inboundConfiguration?.livestreamConfiguration.isLivestreamEnabled ||
                            true,
                        isLoading: false,
                        isEditable,
                        tooltip: !isEditable
                            ? `is collect option is not supported for this entity and it cannot be modified`
                            : undefined,
                    },
                ];

                return entityIdToCollectStatus;
            }
        });

        return Object.fromEntries(entityIdToCollectStatusEntries);
    }, [
        allowToggleIsCollectEnabledOfNativeEntities,
        isNativeEntityDataRetentionEditable,
        projectIntegration.nativeEntitiesInboundConfiguration,
        projectIntegrationSummaries?.entities,
        retentionConfiguration?.dataRetentionConfiguration?.entitiesConfiguration,
    ]);

    // On change entity summery update configuration locally
    const onChange = (reactId: string, entity: EntityRetentionSummary) => {
        setUpdatedEntitiesConfiguration((prevState) =>
            prevState.map((prevEntity) => (prevEntity.reactIdForRender === reactId ? { ...entity } : prevEntity)),
        );
    };

    // delete specific entity configuration.
    const deleteEntity = (entityReactIdToRemove: string) => {
        setUpdatedEntitiesConfiguration((prevState) => {
            return prevState.filter((entity) => entityReactIdToRemove !== entity.reactIdForRender);
        });
    };

    // if there is more entities left that we support
    const canAddEntity = updatedEntitiesConfiguration.at(-1)?.id === '';

    // Adding new data retention row with empty values (for native entities like salesforce)
    const addNewEntityDataRetentionConfiguration = useCallback(() => {
        setUpdatedEntitiesConfiguration((prevState) => {
            const retentionSummary: EntityRetentionSummary = {
                id: '',
                reactIdForRender: incrementValue().toString(),
                collectStatus: {
                    isLoading: false,
                    isEnabled: true,
                    isEditable: allowToggleIsCollectEnabledOfNativeEntities,
                    tooltip: !allowToggleIsCollectEnabledOfNativeEntities
                        ? `is collect option is not supported for this entity and it cannot be modified`
                        : undefined,
                },
                retention: {
                    isIndefinite: { value: null, isEditable: true },
                    expirationDays: { value: 365, isEditable: true },
                },
                isDeletable: true,
                activeRecords: 0,
            };

            return [...prevState, retentionSummary];
        });
    }, [allowToggleIsCollectEnabledOfNativeEntities, incrementValue]);

    const getRetentionTooltip = useCallback(
        (entityRetentionSetting: EntityDataRetentionSettings): string | undefined => {
            if (
                entityRetentionSetting.id.startsWith(
                    tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY],
                )
            ) {
                // on custom entities we dnt need tooltip
                return undefined;
            } else {
                if (!isNativeEntityDataRetentionEditable(entityRetentionSetting.id)) {
                    return 'Update lifespan of this entity is not supported.';
                } else {
                    return undefined;
                }
            }
        },
        [isNativeEntityDataRetentionEditable],
    );

    // Initial configuration
    const configuration = useMemo(() => {
        return Object.entries(retentionConfiguration?.dataRetentionConfiguration?.entitiesConfiguration || {}).map(
            ([entityId, entityRetentionSetting]) => {
                const summary: EntityRetentionSummary = {
                    id: entityId,
                    reactIdForRender: incrementValue().toString(),
                    activeRecords:
                        entityIdToDocumentsCount?.result?.[projectIntegration.id]?.[entityRetentionSetting.id],
                    collectStatus: entityIdToIsCollectEnabled[entityId] || { isLoading: true },
                    retention: {
                        isIndefinite: getIsIndefinite(entityRetentionSetting),
                        expirationDays: getExpirationDays(entityRetentionSetting),
                        tooltip: getRetentionTooltip(entityRetentionSetting),
                    },
                    isDeletable: getIsDeletable(entityId),
                };

                return summary;
            },
        );
    }, [
        entityIdToDocumentsCount?.result,
        entityIdToIsCollectEnabled,
        getExpirationDays,
        getIsDeletable,
        getIsIndefinite,
        getRetentionTooltip,
        incrementValue,
        projectIntegration.id,
        retentionConfiguration?.dataRetentionConfiguration?.entitiesConfiguration,
    ]);

    const discardChanges = useCallback(() => {
        setUpdatedEntitiesConfiguration(configuration);
    }, [configuration]);

    // Init the updatedEntitiesConfiguration on page loaded - we are updating updatedEntitiesConfiguration and on save we used this fied.
    useEffect(() => {
        setUpdatedEntitiesConfiguration(configuration);
    }, [configuration]);

    return useMemo(() => {
        return {
            updatedConfiguration: updatedEntitiesConfiguration,
            initialConfiguration: configuration,
            onChange,
            addEntity: addNewEntityDataRetentionConfiguration,
            deleteEntity,
            canAddEntity,
            error: getDocumentsCountError || error,
            ignoredEntities: constConfiguration.ignoreEntities,
            loading,
            discardChanges,
            canAddEntities: constConfiguration.canAddOrRemoveAll && constConfiguration.isSupportsDataRetention,
        };
    }, [
        addNewEntityDataRetentionConfiguration,
        canAddEntity,
        configuration,
        constConfiguration.canAddOrRemoveAll,
        constConfiguration.ignoreEntities,
        constConfiguration.isSupportsDataRetention,
        discardChanges,
        error,
        getDocumentsCountError,
        loading,
        updatedEntitiesConfiguration,
    ]);
};

export default useDataRetentionConfiguration;
