import React from 'react';

import ApiKeyAuthenticationComponent from './AuthenticationComponents/ApiKeyAuthenticationComponent';
import OAuthAuthenticationComponent from './AuthenticationComponents/OAuthAuthenticationComponent';

import { AuthenticationMethod } from '@tonkean/integrations';
import type { CustomizedAuthenticationComponentProps } from '@tonkean/integrations';

const GenericIntegrationAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps<unknown>> = ({
    integrationConfiguration,
    authenticate,
    reportError,
    projectIntegrationData,
}) => {
    switch (integrationConfiguration.authenticationType) {
        case AuthenticationMethod.API_TOKEN:
            return (
                <ApiKeyAuthenticationComponent
                    authenticate={authenticate}
                    reportError={reportError}
                    integrationConfiguration={integrationConfiguration}
                />
            );
        case AuthenticationMethod.NONE:
            return <></>;

        case AuthenticationMethod.OAUTH:
            return (
                <OAuthAuthenticationComponent
                    authenticate={authenticate}
                    integrationConfiguration={integrationConfiguration}
                    reportError={reportError}
                />
            );

        case AuthenticationMethod.CUSTOM: {
            const CustomizedAuthenticationComponent = integrationConfiguration.customizedAuthenticationComponent;

            if (!CustomizedAuthenticationComponent) {
                throw new Error(
                    'You must provide a customizedAuthenticationComponent when using AuthenticationMethod of CUSTOM',
                );
            }

            return (
                <CustomizedAuthenticationComponent
                    authenticate={authenticate}
                    integrationConfiguration={integrationConfiguration}
                    reportError={reportError}
                    projectIntegrationData={projectIntegrationData}
                />
            );
        }
    }
};

export default GenericIntegrationAuthenticationComponent;
