<tnk-select-integration-instance
        integration-type="data.integrationType"
        project-integration-options="data.projectIntegrationOptions"
        on-integration-selected="onIntegrationSelected(selectedIntegration)"
        selected-project-integration="data.selectedProjectIntegration"
        data-automation="select-integration"
>
    <!-- Show the selected integration as a trigger to open the popover -->
    <div class="pointer"
         uib-tooltip="{{ data.selectedProjectIntegration.displayName }}"
         tooltip-enable="data.selectedProjectIntegration.displayName.length > 10"
         tooltip-append-to-body="true"
    >
        <tnk-integration-group
                ng-if="data.selectedProjectIntegration"
                class="pointer-events-none"
                sources="[data.integrationType.toLowerCase()]"
                ng-model="ignored"
                button-text="{{ data.selectedProjectIntegration.displayName }}"
                button-max-width="150px"
                display-mode="button"
                current-integration="data.integrationState.currentIntegration"
                state="data.integrationState"
                open-modal-for-configuration="true"
                display-new-prefix="false"
                wide-mode="false"
                icon-url-override="data.selectedProjectIntegration.iconUrl"
        ></tnk-integration-group>


        <div ng-if="!data.selectedProjectIntegration" class="btn btn-default pointer-events-none">
            Select Connector
        </div>
    </div>
</tnk-select-integration-instance>