import { useCallback } from 'react';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

const useShouldShowRequiredIndication = (isEditable: boolean, requiredFields: FieldDefinitionKey[]) => {
    return useCallback(
        (fieldDefinitionKey: FieldDefinitionKey) => {
            return isEditable && requiredFields.includes(fieldDefinitionKey);
        },
        [isEditable, requiredFields],
    );
};

export default useShouldShowRequiredIndication;
