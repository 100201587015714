import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as TriggerIcon } from '../../../../../images/icons/lightning.svg';

import { CloseButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { getIndexedCollection } from '@tonkean/utils';

interface Props {
    open: boolean;

    onClose(): void;

    confirm(): void;

    typeDisplayName: string;
    workflowFolderName: string;
    groupsName: string[];
}

const ConfirmButton = styled(Button)`
    background-color: #f0771e;

    &:hover {
        background-color: #ff964a;
        border-color: #ff964a;
    }
`;

const ModalWrapper = styled(Modal)`
    width: 468px;
    height: 365px;
`;

const GreyContent = styled.div`
    color: #838a92;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const GroupName = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: end;
`;

const NoticeContent = styled.div`
    margin-top: 20px;
`;

const ModalHeaderWrapper = styled(ModalHeader)`
    height: 51px;
    font-size: 20px;
    display: flex;
    align-items: center;
`;

const TriggerIconWrapper = styled(TriggerIcon)`
    margin-right: 10px;
`;

const WarningTriangleWrapper = styled.div`
    margin-right: 7.5px;
`;
const AboutToRemoveWrapper = styled.div`
    font-size: 14px;
`;

const WorkflowFolderName = styled.strong`
    font-weight: 700;
`;

const AccessRemovedMessage = styled.span`
    color: ${Theme.colors.warning};
`;

const CapitalLettersTitle = styled.div`
    text-transform: capitalize;
`;

const EnterpriseComponentGroupsDependencyReviewModal: React.FC<Props> = ({
    open,
    onClose,
    confirm,
    typeDisplayName,
    workflowFolderName,
    groupsName,
}) => {
    return (
        <ModalWrapper open={open} onClose={onClose} size={ModalSize.MEDIUM} fixedWidth>
            <ModalHeaderWrapper>
                <WarningTriangleWrapper>
                    <WarningTriangleIcon />
                </WarningTriangleWrapper>
                <CapitalLettersTitle>Remove {typeDisplayName}</CapitalLettersTitle>
            </ModalHeaderWrapper>
            <ModalBody>
                <AboutToRemoveWrapper>
                    <div>You are about to remove access to this {typeDisplayName} from Solution:</div>
                    <div>
                        <WorkflowFolderName>{workflowFolderName}.</WorkflowFolderName>
                    </div>
                </AboutToRemoveWrapper>

                <GreyContent>Modules that are using the {typeDisplayName}:</GreyContent>
                {getIndexedCollection(groupsName).map((indexedGroup) => (
                    <GroupName key={indexedGroup.index}>
                        <TriggerIconWrapper />
                        {indexedGroup.original}
                    </GroupName>
                ))}

                <NoticeContent>
                    <AccessRemovedMessage>Notice:</AccessRemovedMessage> Access will be removed initially from the{' '}
                    <strong>Build Environment</strong>. Access changes will be applied in Production on the solution
                    next publish.
                </NoticeContent>
            </ModalBody>
            <ModalFooter align="right" gap={10}>
                <CloseButton cancel outlined>
                    Cancel
                </CloseButton>
                <ConfirmButton onClick={() => confirm()}>Got It</ConfirmButton>
            </ModalFooter>
        </ModalWrapper>
    );
};

export default EnterpriseComponentGroupsDependencyReviewModal;
