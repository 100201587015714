import formulaOperatorsList from './operators';

import { OperatorKey } from '@tonkean/tonkean-entities';
import type { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { memoize } from '@tonkean/utils';

/**
 * Filters operators based on conditions.
 *
 * @param withAggregationOperands - should include operands that work only in aggregation?
 * @param withInnerTrackAggregationOperand - should include the inner track aggregation operator?
 * @param withDeprecatedOperands - should include deprecated operands?
 * @param customTrigger - will return only operators that are allowed in the specified custom trigger
 * @returns filtered list of operands.
 */
const memoizedGetOperators = memoize(
    (
        withAggregationOperands: boolean = false,
        withInnerTrackAggregationOperand: boolean = false,
        withDeprecatedOperands: boolean = false,
        customTrigger: any = undefined,
        projectIntegration: ProjectIntegration | undefined = undefined,
        _force: boolean | symbol,
    ): FormulaOperatorDefinitionBase[] => {
        return formulaOperatorsList.filter((operator) => {
            if (!withAggregationOperands && operator.aggregationOnly) {
                return false;
            }
            if (!withInnerTrackAggregationOperand && operator.key === OperatorKey.INNER_TRACK_AGGREGATION) {
                return false;
            }
            if (!withDeprecatedOperands && operator.deprecated) {
                return false;
            }
            if (!operator.isAllowed({ customTrigger, projectIntegration })) {
                return false;
            }
            return true;
        });
    },
);

interface Params {
    /** should include operands that work only in aggregation? */
    withAggregationOperands?: boolean;
    /** should include the inner track aggregation operator? */
    withInnerTrackAggregationOperand?: boolean;
    /** should include deprecated operands? */
    withDeprecatedOperands?: boolean;
    /** will return only operators that are allowed in the specified custom trigger */
    customTrigger?: any;
    /** will return only operators that are allowed in the specified project integration */
    projectIntegration?: ProjectIntegration;
}

/**
 * Filters operators based on conditions.
 *
 * @returns filtered list of operands.
 */
function getOperators(
    {
        withAggregationOperands,
        withInnerTrackAggregationOperand,
        withDeprecatedOperands,
        customTrigger,
        projectIntegration,
    }: Params,
    force: boolean = false,
): FormulaOperatorDefinitionBase[] {
    return memoizedGetOperators(
        withAggregationOperands,
        withInnerTrackAggregationOperand,
        withDeprecatedOperands,
        customTrigger,
        projectIntegration,
        force && Symbol(),
    );
}

export default getOperators;
