import type { AngularServices } from 'angulareact';

import type { WorkflowFolder } from '@tonkean/tonkean-entities';

const checkSolutionCanPublish = (
    projectManager: AngularServices['projectManager'],
    workflowFolder: WorkflowFolder,
    anySolutionSitePagesDirty?: boolean,
): true | string => {
    const canOnlyPublishersPublish = projectManager.project.metadata.allowPublishersManagement;

    if (canOnlyPublishersPublish && !workflowFolder.isUserPublisher) {
        return 'You must be a publisher to publish the solution.';
    } else if (!canOnlyPublishersPublish && !workflowFolder.isUserOwner) {
        return 'You must be a maker to publish the solution.';
    }

    if (workflowFolder.isSandbox) {
        return "Sandbox solution can't be published";
    }

    const anyModulesAreDirty: boolean = workflowFolder.groupIds
        .map((groupId) => (groupId ? projectManager.groupsMap[groupId] : undefined))
        .some((group) => group?.isDirty);

    const canPublish = anySolutionSitePagesDirty?.['anyDirtyVersions'] || anyModulesAreDirty;

    return canPublish || 'No modules were changed in this solution since the last publish';
};
export default checkSolutionCanPublish;
