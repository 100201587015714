import { useCallback, useEffect, useRef, useState } from 'react';

// Important - this class list is from the tnkPeopleSelector.template.html component <tag-input/> angular component
// It is enabling scroll inside this component
const CLASS_NAMES_TO_ENABLE_SCROLLING = ['dropdown', 'suggestion-list'];

const useScrollHandler = () => {
    const lastKnownScrollPosition = useRef<number>(0);
    const scheduledAnimationFrame = useRef<boolean>(false);
    const [scrollingObjectStarted, setScrollingObjectStarted] = useState<Event | undefined>();

    const scrollHandler = useCallback(
        (e: Event) => {
            const isScrollInDropdown: number = CLASS_NAMES_TO_ENABLE_SCROLLING.findIndex((className) =>
                e?.target?.['className']?.includes(className),
            );
            if (isScrollInDropdown > -1) {
                return;
            }
            lastKnownScrollPosition.current = window.scrollY;
            if (!scheduledAnimationFrame.current) {
                window.requestAnimationFrame(function () {
                    setScrollingObjectStarted(e);
                    scheduledAnimationFrame.current = false;
                });

                scheduledAnimationFrame.current = true;
            }
        },
        [setScrollingObjectStarted, lastKnownScrollPosition],
    );

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler, true);

        return () => {
            window.removeEventListener('scroll', scrollHandler, true);
        };
    }, [scrollHandler]);

    return scrollingObjectStarted;
};

export default useScrollHandler;
