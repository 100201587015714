import { FieldArray } from 'formik';
import * as React from 'react';
import styled, { css } from 'styled-components';

import IpAddressRestrictionRangeValueView from './IpAddressRestrictionRangeValueView';
import { ReactComponent as ErrorIcon } from '../../../../../../images/icons/error-or-fail.svg';

import { ExtendableRow, Field, Input, Toggle } from '@tonkean/infrastructure';
import { IpRestrictionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Header = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${Theme.colors.gray_800};
`;

const StyledErrorIconButton = styled(IconButton)`
    margin-left: auto;
    height: 18px;
    width: 18px;
    transform: translate(5px, 8px);
`;

const AddMethodWrapper = styled.div`
    margin-top: 14px;
`;

const HeaderWrapper = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 12px;
`;

const GroupOfIpRestrictionsGroup = styled.div<{ isFirst: boolean }>`
    max-width: 524px;
    border: 1px solid ${Theme.colors.gray_300};
    background: ${Theme.current.palette.colorPicker.HEX_FFFFFF};
    border-radius: 3px;

    ${({ isFirst }) =>
        !isFirst &&
        css`
            margin-top: 20px;
            padding-top: 5px;
        `}
`;

const NameWrapper = styled.div`
    padding: 16px 16px 0 16px;
`;

const Wrapper = styled.div`
    padding: 20px;
`;

const IpAddressRestrictionExtendableRow: React.FC = () => {
    return (
        <ExtendableRow
            header={
                <HeaderWrapper>
                    <Header>IP Based control method</Header>
                    <Toggle
                        size={InputSize.MEDIUM}
                        onClick={(e) => e.stopPropagation()}
                        name="accessControl.ipRestrictionGroups.isEnabled"
                        showInnerLabel
                    />
                </HeaderWrapper>
            }
            isOpenByDefault
        >
            <FieldArray name="accessControl.ipRestrictionGroups.ipRestrictions">
                {({ push: pushMethod, remove: removeMethod, form }) => (
                    <Wrapper>
                        {form.values.accessControl.ipRestrictionGroups.ipRestrictions.map(
                            (ipRangeGroup, ipRestrictionsIndex) => (
                                <GroupOfIpRestrictionsGroup
                                    isFirst={ipRestrictionsIndex === 0}
                                    key={ipRestrictionsIndex}
                                >
                                    <NameWrapper>
                                        {ipRestrictionsIndex > 0 && (
                                            <StyledErrorIconButton
                                                onClick={() => removeMethod(ipRestrictionsIndex)}
                                                flat
                                            >
                                                <ErrorIcon />
                                            </StyledErrorIconButton>
                                        )}
                                        <Field label="Method Name">
                                            <Input
                                                placeholder="New Method"
                                                name={`accessControl.ipRestrictionGroups.ipRestrictions.${ipRestrictionsIndex}.displayName`}
                                            />
                                        </Field>
                                    </NameWrapper>

                                    <FieldArray
                                        name={`accessControl.ipRestrictionGroups.ipRestrictions.${ipRestrictionsIndex}.ipRangeValues`}
                                    >
                                        {({ push: pushNewIp, remove: removeIpGroup }) => (
                                            <IpAddressRestrictionRangeValueView
                                                form={form}
                                                ipRestrictionsIndex={ipRestrictionsIndex}
                                                removeIpGroup={removeIpGroup}
                                                pushNewIp={pushNewIp}
                                            />
                                        )}
                                    </FieldArray>
                                </GroupOfIpRestrictionsGroup>
                            ),
                        )}

                        <AddMethodWrapper>
                            <ClickableLink
                                onClick={() =>
                                    pushMethod({
                                        displayName: '',
                                        ipRangeValues: [
                                            {
                                                ipRestrictionType: IpRestrictionType.SINGLE_IP,
                                                fromIp: '',
                                                toIp: '',
                                            },
                                        ],
                                    })
                                }
                            >
                                + Add Method
                            </ClickableLink>
                        </AddMethodWrapper>
                    </Wrapper>
                )}
            </FieldArray>
        </ExtendableRow>
    );
};

export default IpAddressRestrictionExtendableRow;
