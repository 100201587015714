export function getWorkerRunInfo(workerRunInfo?: string): { id: string; startTime: number } | undefined {
    if (!workerRunInfo) {
        return;
    }

    const splitInfo = workerRunInfo.split('-');

    if (splitInfo[0] && splitInfo[1]) {
        return { id: splitInfo[0], startTime: +splitInfo[1] };
    }
}

export function createWorkerRunInfo(id: string, startTime: number) {
    return `${id}-${startTime}`;
}
