import React from 'react';

export enum AuthTypes {
    GOOGLE,
    MICROSOFT,
    SLACK,
    OKTA,
    PING,
}

export interface Auth {
    name: string;
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    type: AuthTypes;
}
