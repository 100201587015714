import template from './tnkZendeskCustomActionsDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkZendeskCustomActionsDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        definition: '<',
        validationObject: '<',
        onDefinitionChanged: '&',
    },
    template,
    controller: 'ZendeskCustomActionsDefinitionCtrl',
});
