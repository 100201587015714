import template from './tnkKbPopover.template.html?angularjs';

function tnkKbPopover() {
    return {
        restrict: 'E',
        controller: 'KbPopoverCtrl',
        scope: {
            startInCat: '@',
            showIcon: '=',
        },
        template,
    };
}
export default angular.module('tonkean.app').directive('tnkKbPopover', tnkKbPopover);
