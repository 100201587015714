import React from 'react';
import * as Yup from 'yup';

import type { MarketplaceItemCreateSection } from './components/MarketplaceItemCreateModalSection';
import MarketPlaceItemCreateModalUploadImagesSection from './components/sections/MarketPlaceItemCreateModalUploadImagesSection';
import MarketplaceItemCreateModalPageContentSection from './components/sections/page/MarketplaceItemCreateModalPageContentSection';

const PageMarketplaceItemCreateModalAdditionalSections: MarketplaceItemCreateSection[] = [
    {
        title: 'Content',
        contentComponent: <MarketplaceItemCreateModalPageContentSection />,
    },
    {
        title: 'Upload images',
        contentComponent: <MarketPlaceItemCreateModalUploadImagesSection maximumFiles={1} />,
        validationSchema: {
            images: Yup.array().length(1, 'Must upload 1 image').required('Must upload 1 image'),
        },
    },
];

export default PageMarketplaceItemCreateModalAdditionalSections;
