<mentio-menu
    mentio-for="textId"
    mentio-trigger-char="'#'"
    mentio-items="selectedItems"
    mentio-template-url="tags-mentions.tpl"
    mentio-visible="data.visible"
    mentio-search="searchTags(term)"
    mentio-select="getTextRaw(item)"
></mentio-menu>

<script type="text/ng-template" id="tags-mentions.tpl">
    <ul class="list-group product-search margin-top">
        <li mentio-menu-item="tag" ng-repeat="tag in items | limitTo:5" class="list-group-item">
            <div class="flex flex-vmiddle">
                <div class="flex-no-shrink common-size-xxxxs common-color-grey margin-right">
                    <i class="fa fa-hashtag"></i>
                </div>
                <div class="flex-grow margin-left">
                    {{tag.name}}
                </div>
            </div>
        </li>
    </ul>
</script>
