import DrilldownHeaderNavigation from './DrilldownHeaderNavigation';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkDrilldownHeaderNavigation',
        reactToAngular(DrilldownHeaderNavigation, [
            'currentEnvironment',
            'onEnvironmentChange',
            'environmentIsActive',
            'group',
        ]),
    );
