import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import DataEncryptionKeyConfiguration from './DataEncryptionKeyConfiguration';
import IpAddressRestrictionExtendableRow from './IpAddressRestrictionExtendableRow';
import TokenRestrictionExtendableRow from './TokenRestrictionExtendableRow';
import ProjectIntegrationPageContext from '../../ProjectIntegrationPageContext';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { AnimatedSwitch, AnimatedSwitchItem, Tab, Tabs } from '@tonkean/infrastructure';
import { IntegrationType } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    margin-top: 40px;
`;

enum AccessControlTabType {
    IP_ACCESS = 'IP Access',
    TOKEN_KEY_ACCESS = 'Token Key Access',
    DATA_ENCRYPTION = 'Data Encryption',
}

interface Props {}

const ProjectIntegrationPageWebhookAccessControlPage: React.FC<Props> = () => {
    const [selectedTab, setSelectedTab] = useState(AccessControlTabType.IP_ACCESS);
    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);
    const featureShowEntities = useFeatureFlag('tonkean_feature_enable_project_integration_entities');

    const isCustomDataSource =
        currentProjectIntegration?.integration.integrationType === IntegrationType.WEBHOOK ||
        currentProjectIntegration?.integration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE;

    // set default tab according to available tabs
    useEffect(() => {
        if (selectedTab === AccessControlTabType.IP_ACCESS && !isCustomDataSource) {
            setSelectedTab(AccessControlTabType.DATA_ENCRYPTION);
        }
    }, [selectedTab, isCustomDataSource, setSelectedTab]);

    return (
        <>
            <Tabs value={selectedTab} onChange={setSelectedTab} hideIndicator>
                {isCustomDataSource && (
                    <>
                        <Tab label={AccessControlTabType.IP_ACCESS}>{AccessControlTabType.IP_ACCESS}</Tab>
                        <Tab label={AccessControlTabType.TOKEN_KEY_ACCESS}>{AccessControlTabType.TOKEN_KEY_ACCESS}</Tab>
                    </>
                )}

                {featureShowEntities && (
                    <Tab label={AccessControlTabType.DATA_ENCRYPTION}>{AccessControlTabType.DATA_ENCRYPTION}</Tab>
                )}
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <Wrapper>
                    {isCustomDataSource && (
                        <>
                            <AnimatedSwitchItem label={AccessControlTabType.IP_ACCESS}>
                                <IpAddressRestrictionExtendableRow />
                            </AnimatedSwitchItem>

                            <AnimatedSwitchItem label={AccessControlTabType.TOKEN_KEY_ACCESS}>
                                <TokenRestrictionExtendableRow />
                            </AnimatedSwitchItem>
                        </>
                    )}

                    {featureShowEntities && (
                        <AnimatedSwitchItem label={AccessControlTabType.DATA_ENCRYPTION}>
                            <DataEncryptionKeyConfiguration />
                        </AnimatedSwitchItem>
                    )}
                </Wrapper>
            </AnimatedSwitch>
        </>
    );
};

export default ProjectIntegrationPageWebhookAccessControlPage;
