<div class="flex-vmiddle flex-row-reverse-xs">
    <tnk-user
        ng-if="!data.customEmail"
        class="mod-one-line mod-no-title"
        user="data"
        default-on-empty="true"
        override-display-name="data.selectedModeDisplayName"
        ng-class="{ 'group-padding-right': data.styledGroupTags }"
    ></tnk-user>
    <div
        ng-if="data.customEmail"
        class="share-modal-custom-item"
        uib-tooltip="{{
            data.isValid
                ? data.notAuthorized
                    ? 'You are not authorized to share with this person'
                    : ''
                : 'This email is not valid and will be ignored'
        }}"
        ng-class="{ 'common-color-warning': !data.isValid || data.notAuthorized }"
    >
        <i class="fa share-modal-custom-item-icon"></i>
        <span>{{ data.selectedModeDisplayName || data.displayName || data.email }}</span>
    </div>
    <a
        class="remove-person-link common-link-no-style margin-xs-right-xxs hide-remove-person-link"
        data-automation="people-selected-item-remove-item"
        ng-click="$removeTag()"
    >
        <tnk-icon class="flex" src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </a>
</div>
