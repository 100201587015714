import React, { useMemo } from 'react';

import InnerItemsItemWidgetDisplay from './InnerItemsItemWidgetDisplay';
import { useIsEnvironmentActive } from '../../../hooks';
import type { ItemWidgetProps } from '../../../WidgetModule';
import type { InnerItemWidgetConfiguration } from '../../entities';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration, WorkflowVersionType } from '@tonkean/tonkean-entities';

const InnerItemsItemWidget: React.FC<ItemWidgetProps<InnerItemWidgetConfiguration>> = ({
    initiative,
    workflowVersionId,
    widget,
    permission,
}) => {
    const { itemInterface, project, originatedCustomTriggerId, reloadInitiativeFromServer, updateFieldsValuesManager } =
        useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);
    const isEnvironmentActive = useIsEnvironmentActive(initiative?.group?.id, initiative);

    const workflowVersionType = useMemo(() => {
        return initiative && (initiative.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED);
    }, [initiative]);

    return (
        <InnerItemsItemWidgetDisplay
            workflowVersionType={workflowVersionType}
            parentInitiative={initiative}
            workflowVersionId={workflowVersionId}
            groupId={initiative?.group?.id}
            widget={widget}
            permission={permission}
            headerBackgroundColor={themeConfiguration.headerBackgroundColor}
            environmentIsActive={isEnvironmentActive}
            reloadInitiativeFromServer={reloadInitiativeFromServer}
            updateFieldsValuesManager={updateFieldsValuesManager}
            originatedCustomTriggerId={originatedCustomTriggerId}
        />
    );
};

export default InnerItemsItemWidget;
