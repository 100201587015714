import { angularToReact } from 'angulareact';

import type { Form, Group, Initiative, Project, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Maybe } from '@tonkean/utils';

const FormPageState = angularToReact<
    Maybe<{
        form: Form;
        project: Project;
        loadForm(): void;
        addUnderInitiative: Initiative | undefined | null;
        collectionForm: Form | undefined | null;
        group: Group;
        itemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    }>
>('tnk-form-page-state', angular.module('tonkean.app'));

export default FormPageState;
