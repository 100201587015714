/**
 * Created by sagieliyahu on 9/25/15.
 */

export const hasFieldFilter = angular.module('tonkean.app').filter('hasFieldFilter', function () {
    return function (input, field, hasIt) {
        if (!field) {
            return input;
        }
        const result = [];

        angular.forEach(input, function (item) {
            if ((item[field] && hasIt) || (!item[field] && !hasIt)) {
                result.push(item);
            }
        });
        return result;
    };
});
export const hasSubFieldFilter = angular.module('tonkean.app').filter('hasSubFieldFilter', function () {
    return function (input, field, subField, hasIt) {
        if (!field) {
            return input;
        }
        const result = [];

        angular.forEach(input, function (item) {
            if (
                (item[field] && item[field][subField] && hasIt) ||
                ((!item[field] || !item[field][subField]) && !hasIt)
            ) {
                result.push(item);
            }
        });
        return result;
    };
});
