import BotHistoryItemDispatcher from '../dispatchers/BotHistoryItemDispatcher';

/**
 * A map for action type names.
 */
export const BotHistoryItemActionTypes = {
    TOGGLE_ACTIONS_DROPDOWN: 'TOGGLE_ACTIONS_DROPDOWN',
};

/**
 * Our flux actions.
 */
const BotHistoryItemActions = {
    toggleActionsDropdown(id, isTrue) {
        BotHistoryItemDispatcher.dispatch({
            type: BotHistoryItemActionTypes.TOGGLE_ACTIONS_DROPDOWN,
            id,
            isTrue,
        });
    },
};

export default BotHistoryItemActions;
