import { useAngularService } from 'angulareact';

/**
 * A simple wrapper around useAngularService('authenticationService') to get the currently authenticated user.
 * The main motivation is to encapsulate the angular hook
 */
function useCurrentlyAuthenticatedUser() {
    const authenticationService = useAngularService('authenticationService');
    const user = authenticationService.getCurrentUser();

    return user;
}

export default useCurrentlyAuthenticatedUser;
