import type { TypedDocumentNode } from '@urql/core';
import type { OperationContext } from '@urql/core';
import type { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import type { AnyVariables } from 'urql';

import type QueryParams from './QueryParams';
import type QueryResponse from './QueryResponse';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import type { NoInfer } from '@tonkean/utils';

type IsQuerySuspended<Pause extends boolean, Suspend extends boolean> = Pause extends true
    ? // Pause is true
      false
    : // Pause is false
      Suspend extends false
      ? // Suspend is false and pause is false
        false
      : // Suspend is true and pause is false
        true;

export type CreateUseSuspenseQuery<Data, Variables extends AnyVariables> = <
    Pause extends boolean = false,
    Suspend extends boolean = true,
>(
    ...params: QueryParams<Data, Variables, Pause, Suspend>
) => QueryResponse<Data, Variables, IsQuerySuspended<NoInfer<Pause>, NoInfer<Suspend>>>;

const createUseSuspenseQuery = <Data, Variables extends AnyVariables>(
    query: string | DocumentNode | TypedDocumentNode<Data, Variables>,
) => {
    const useSuspenseQuery = ((args) => {
        const pause = args?.pause ?? false;
        const suspense = !pause && (args?.suspend ?? true);

        const fetchMethod = useConstantRefCallback((...fetchArgs: Parameters<typeof fetch>) => {
            const fetchToUse: typeof fetch = args?.context?.fetch ?? fetch;
            args?.onBeforeFetch?.();
            return fetchToUse(...fetchArgs).then((response) => {
                args?.onAfterFetch?.();
                return response;
            });
        });

        const context = useMemo<Partial<OperationContext>>(
            () => ({
                ...args?.context,
                fetch: fetchMethod,
                suspense,
            }),
            [args?.context, fetchMethod, suspense],
        );

        const [state, reexecuteQuery] = useQuery<Data, Variables>({
            ...(args as any),
            context,
            query,
        });

        if (suspense && state.error) {
            throw state.error;
        }

        return [state, reexecuteQuery];
    }) as CreateUseSuspenseQuery<Data, Variables>;

    return useSuspenseQuery;
};

export default createUseSuspenseQuery;
