import type { Person, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { AngularServices } from 'angulareact';
import { bindThis } from '@tonkean/utils';
import type { TElement } from '@udecode/plate';
import { WorkflowFolder } from '@tonkean/tonkean-entities';

class WorkflowFolderManager {
    public static $inject: (string & keyof AngularServices)[] = [
        '$q',
        'authenticationService',
        'tonkeanService',
        'utils',
        'projectManager',
        '$timeout',
    ] as string[];

    public projectIdToFoldersMap = new Map<string, WorkflowFolder[]>();
    public projectIdToFolderIdToFolderMap = {} as Record<string, Record<string, WorkflowFolder>>;
    public projectIdToFolderIdToMakersMap = {} as Record<string, Record<string, Person[]>>;

    private projectIdToLoadingFoldersPromiseMap: Record<string, Promise<unknown> | undefined> = {};

    protected constructor(
        private $q: AngularServices['$q'],
        private authenticationService: AngularServices['authenticationService'],
        private tonkeanService: AngularServices['tonkeanService'],
        private utils: AngularServices['utils'],
        private projectManager: AngularServices['projectManager'],
        private $timeout: AngularServices['$timeout'],
    ) {}

    public deleteWorkflowFolder = function (folderId, projectId) {
        return this.tonkeanService.deleteWorkflowFolder(folderId, projectId).then(() => {
            // Cache update
            const folders = this.projectIdToFoldersMap.get(projectId);
            this.utils.removeFirst(folders, (folder) => folder.id === folderId);

            // Second cache update
            delete this.projectIdToFolderIdToFolderMap[projectId][folderId];
        });
    };

    public createWorkflowFolder = function (folderName, isSandbox, projectId) {
        return this.tonkeanService.createWorkflowFolder(folderName, isSandbox, projectId).then((folder) => {
            folder.groupIds = folder.groupIds || [];

            // Cache update
            this.addOrUpdateFolderInCache(projectId, folder);

            return folder;
        });
    };

    public addOrUpdateFolderInCache(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        folder: Omit<WorkflowFolder, 'isUserOwner' | 'isUserPublisher'>,
        groupIds: TonkeanId<TonkeanType.GROUP>[] = folder.groupIds || [],
        owners: Person[] = folder.owners,
        publishers: Person[] = folder.publishers,
        processOwners: Person[] = folder.processOwners,
    ) {
        const enrichedFolder = new WorkflowFolder(
            folder.id,
            folder.displayName,
            groupIds,
            projectId,
            folder.creator,
            owners,
            publishers,
            processOwners,
            folder.owners?.filter((owner) => owner.id === this.authenticationService.getCurrentUser().id).length !== 0,
            folder.publishers?.filter((publisher) => publisher.id === this.authenticationService.getCurrentUser().id)
                .length !== 0,
            folder.isSandbox,
            folder.isHiddenFromNonSolutionCollaborator,
            folder.description,
            folder.htmlDescription,
            folder.businessOutcome,
            folder.htmlBusinessOutcome,
        );

        const cachedWorkflowFolder = this.getCachedWorkflowFolder(projectId, folder.id);
        if (cachedWorkflowFolder) {
            cachedWorkflowFolder.id = enrichedFolder.id;
            cachedWorkflowFolder.displayName = enrichedFolder.displayName;
            cachedWorkflowFolder.groupIds = enrichedFolder.groupIds;
            cachedWorkflowFolder.project = enrichedFolder.project;
            cachedWorkflowFolder.creator = enrichedFolder.creator;
            cachedWorkflowFolder.owners = enrichedFolder.owners;
            cachedWorkflowFolder.publishers = enrichedFolder?.publishers;
            cachedWorkflowFolder.processOwners = enrichedFolder.processOwners;
            cachedWorkflowFolder.isUserOwner = enrichedFolder.isUserOwner;
            cachedWorkflowFolder.isUserPublisher = enrichedFolder.isUserPublisher;
            cachedWorkflowFolder.isSandbox = enrichedFolder.isSandbox;
            cachedWorkflowFolder.isHiddenFromNonSolutionCollaborator =
                enrichedFolder.isHiddenFromNonSolutionCollaborator;
            cachedWorkflowFolder.description = enrichedFolder.description;
            cachedWorkflowFolder.businessOutcome = enrichedFolder.businessOutcome;
        } else {
            if (!this.projectIdToFoldersMap.get(projectId)) {
                this.projectIdToFoldersMap.set(projectId, []);
            }

            const folders = this.projectIdToFoldersMap.get(projectId)!;
            folders.push(enrichedFolder);

            // Second cache update
            if (!this.projectIdToFolderIdToFolderMap[projectId]) {
                this.projectIdToFolderIdToFolderMap[projectId] = {};
            }
            this.projectIdToFolderIdToFolderMap[projectId]![enrichedFolder.id] = enrichedFolder;
        }

        if (!this.projectIdToFolderIdToMakersMap[projectId]) {
            this.projectIdToFolderIdToMakersMap[projectId] = {};
        }
        this.projectIdToFolderIdToMakersMap[projectId]![folder.id] = owners;

        return enrichedFolder;
    }

    public addGroupToWorkflowFolder = function (projectId, folderId, groupId) {
        return this.tonkeanService.addGroupToWorkflowFolder(projectId, folderId, groupId).then(() => {
            this.addGroupToWorkflowFolderCache(projectId, folderId, groupId);
        });
    };

    public ownersWithoutSupport(projectId, folderId) {
        return (
            this.projectIdToFolderIdToMakersMap?.[projectId]?.[folderId]?.filter((person) => !person.systemUtilized) ??
            []
        );
    }

    public deleteGroupFromWorkflowFolder = function (projectId, folderId, groupIdToDelete) {
        return this.tonkeanService.deleteGroupFromWorkflowFolder(projectId, folderId, groupIdToDelete).then(() => {
            this.removeGroupFromWorkflowFolderCache(projectId, folderId, groupIdToDelete);
        });
    };

    public moveGroupToWorkflowFolder = function (
        projectId,
        groupId,
        fromFolderId,
        toFolderId,
        workflowFolderCategoryId,
    ) {
        return this.tonkeanService
            .moveEntityToWorkflowFolder(toFolderId, groupId, workflowFolderCategoryId)
            .then(() => {
                this.removeGroupFromWorkflowFolderCache(projectId, fromFolderId, groupId);
                this.addGroupToWorkflowFolderCache(projectId, toFolderId, groupId);
                const cachedGroup = this.projectManager.groupsMap[groupId];
                if (cachedGroup) {
                    cachedGroup.workflowFolderCategoryId = workflowFolderCategoryId;

                    if (!cachedGroup.notVisibleToMakers) {
                        cachedGroup.owners = this.projectIdToFolderIdToFolderMap[projectId][toFolderId].owners;
                    }
                }
            });
    };

    public updateWorkflowFolderDisplayName(projectId, folderId, displayName) {
        this.$timeout(() => {
            // Cache update
            const folders = this.projectIdToFoldersMap.get(projectId);
            const targetFolder = this.utils.findFirstById(folders!, folderId)!;
            targetFolder.displayName = displayName;

            // Second cache update
            this.projectIdToFolderIdToFolderMap[projectId]![folderId]!.displayName = displayName;
        });

        return this.tonkeanService.updateWorkflowFolderDisplayName(folderId, displayName);
    }

    public updateWorkflowFolderBusinessOutcome(
        projectId: string,
        folderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        businessOutcome: {
            markdownBusinessOutcome?: string;
            htmlBusinessOutcome?: TElement[];
        },
    ) {
        this.$timeout(() => {
            // Cache update
            const folders = this.projectIdToFoldersMap.get(projectId);
            const targetFolder = this.utils.findFirstById(folders!, folderId)!;
            if (businessOutcome.htmlBusinessOutcome) {
                targetFolder.htmlBusinessOutcome = businessOutcome.htmlBusinessOutcome;
                this.projectIdToFolderIdToFolderMap[projectId]![folderId]!.htmlBusinessOutcome =
                    businessOutcome.htmlBusinessOutcome;
            }
            if (businessOutcome.markdownBusinessOutcome) {
                targetFolder.businessOutcome = businessOutcome.markdownBusinessOutcome;
                this.projectIdToFolderIdToFolderMap[projectId]![folderId]!.businessOutcome =
                    businessOutcome.markdownBusinessOutcome;
            }
        });

        return this.tonkeanService.updateWorkflowFolderBusinessOutcome(folderId, businessOutcome);
    }

    public updateWorkflowFolderDescription(
        projectId: string,
        folderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        description: {
            markdownDescription?: string;
            htmlDescription?: TElement[];
        },
    ) {
        this.$timeout(() => {
            // Cache update
            const folders = this.projectIdToFoldersMap.get(projectId);
            const targetFolder = this.utils.findFirstById(folders!, folderId)!;
            if (description.htmlDescription) {
                targetFolder.htmlDescription = description.htmlDescription;
                this.projectIdToFolderIdToFolderMap[projectId]![folderId]!.htmlDescription =
                    description.htmlDescription;
            }
            if (description.markdownDescription) {
                targetFolder.description = description.markdownDescription;
                this.projectIdToFolderIdToFolderMap[projectId]![folderId]!.description =
                    description.markdownDescription;
            }
        });

        return this.tonkeanService.updateWorkflowFolderDescription(folderId, description);
    }

    public addGroupToWorkflowFolderCache = function (projectId, folderId, groupId) {
        const folders = this.projectIdToFoldersMap.get(projectId);
        const targetFolder: WorkflowFolder = this.utils.findFirstById(folders, folderId);

        if (targetFolder) {
            targetFolder.groupIds.push(groupId);
        }
    };

    /**
     * Get the workflow folder containing a specific group
     */
    public getContainingWorkflowFolder = function (
        projectId: TonkeanId<TonkeanType.PROJECT>,
        groupId: TonkeanId<TonkeanType.GROUP> | string | null,
    ): WorkflowFolder {
        if (this.projectIdToFolderIdToFolderMap[projectId]) {
            const folderIds = Object.keys(this.projectIdToFolderIdToFolderMap[projectId]);
            for (const folderId_ of folderIds) {
                const folderId = folderId_!;
                const folder = this.projectIdToFolderIdToFolderMap[projectId][folderId]!;
                if (folder.groupIds.includes(groupId)) {
                    return folder;
                }
            }
        }

        // Hack because a lot of places don't account for the possible null /:
        return null as unknown as WorkflowFolder;
    };

    public removeGroupFromWorkflowFolderCache = function (projectId, folderId, groupIdToDelete) {
        // Cache update
        const folders = this.projectIdToFoldersMap.get(projectId);
        const targetFolder: WorkflowFolder = this.utils.findFirstById(folders, folderId);
        this.utils.removeFirst(targetFolder.groupIds, (groupId) => groupId === groupIdToDelete);

        // Second cache update
        const groupIds = this.projectIdToFolderIdToFolderMap[projectId][folderId].groupIds;
        this.utils.removeFirst(groupIds, (groupId) => groupId === groupIdToDelete);
    };

    public setWorkflowFolderMakers(projectId: TonkeanId<TonkeanType.PROJECT>, folderId: string, makers: Person[]) {
        const makersIds = makers.map((maker) => maker.id);
        return this.tonkeanService.updateWorkflowFolderOwners(folderId, makersIds).then((workflowFolder) => {
            const groupIds = this.projectIdToFolderIdToFolderMap[projectId]?.[folderId]?.groupIds;
            this.addOrUpdateFolderInCache(projectId, workflowFolder, groupIds, makers, workflowFolder.publishers);
        });
    }

    public setWorkflowFolderPublishers = function (projectId, folderId, publishers) {
        const publishersIds = publishers.map((publisher) => publisher.id);
        return this.tonkeanService.updateWorkflowFolderPublishers(folderId, publishersIds).then((workflowFolder) => {
            const groupIds = this.projectIdToFolderIdToFolderMap[projectId][folderId].groupIds;
            this.addOrUpdateFolderInCache(
                projectId,
                workflowFolder,
                groupIds,
                workflowFolder.owners,
                workflowFolder.publishers,
            );
        });
    };

    public setWorkflowFolderProcessOwners = function (projectId, folderId, processOwners) {
        const processOwnersIds = processOwners.map((person) => person.id);
        return this.tonkeanService
            .updateWorkflowFolderProcessOwners(folderId, processOwnersIds)
            .then((workflowFolder) => {
                const groupIds = this.projectIdToFolderIdToFolderMap[projectId][folderId].groupIds;
                this.addOrUpdateFolderInCache(projectId, workflowFolder, groupIds);
            });
    };

    @bindThis
    public updateWorkflowFolderAccess(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        folderId: string,
        solutionAccess: { isHiddenFromNonSolutionCollaborator: boolean },
    ) {
        return this.tonkeanService
            .updateWorkflowFolderAccess(folderId, solutionAccess.isHiddenFromNonSolutionCollaborator)
            .then(() => {
                const cachedWorkflowFolder = this.projectIdToFolderIdToFolderMap[projectId]?.[folderId];
                if (!cachedWorkflowFolder) {
                    return;
                }

                this.addOrUpdateFolderInCache(projectId, {
                    ...cachedWorkflowFolder,
                    isHiddenFromNonSolutionCollaborator: solutionAccess.isHiddenFromNonSolutionCollaborator,
                });
            });
    }

    public getFoldersOfMaker = function (projectId: string, makerId: string) {
        const folders: WorkflowFolder[] = this.projectIdToFoldersMap.get(projectId) || [];
        return folders.filter((folder) => folder.owners.some((owner) => owner.id === makerId));
    };

    public isMakerOfFolder(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ) {
        return this.projectIdToFolderIdToFolderMap[projectId]?.[workflowFolderId]?.isUserOwner ?? false;
    }

    public async getIsMakerOfFolder(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        forceServer?: boolean,
    ) {
        if (forceServer || !this.projectIdToFolderIdToFolderMap[projectId]?.[workflowFolderId]) {
            await this.getWorkflowFolder(projectId, workflowFolderId, forceServer);
        }

        return this.isMakerOfFolder(projectId, workflowFolderId);
    }

    public getWorkflowFolder(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: string,
        forceServer: boolean = false,
    ): Promise<WorkflowFolder> {
        if (!forceServer && this.projectIdToFoldersMap.has(projectId)) {
            const cachedFolder = this.getCachedWorkflowFolder(projectId, workflowFolderId);
            if (cachedFolder) {
                return Promise.resolve(cachedFolder);
            }
        }

        return this.tonkeanService.getWorkflowFolder(workflowFolderId).then((workflowFolder) => {
            const enrichedWorkflowFolder = this.addOrUpdateFolderInCache(projectId, workflowFolder);

            return this.innerSpecificFolderGetAllRelations(projectId, workflowFolderId, 1000, 0).then(() => {
                return enrichedWorkflowFolder;
            });
        });
    }

    private getCachedWorkflowFolder(projectId: string, workflowFolderId: string) {
        return this.projectIdToFolderIdToFolderMap[projectId]?.[workflowFolderId];
    }

    public getWorkflowFolders(
        projectId: string,
        forceServer: boolean = false,
        workflowFolderLimit: number = 100,
        relationsLimit: number = 100,
    ): Promise<WorkflowFolder[]> {
        if (forceServer) {
            this.projectIdToLoadingFoldersPromiseMap[projectId] = undefined;
        }

        if (!this.projectIdToLoadingFoldersPromiseMap[projectId]) {
            // Fetch all folders
            this.projectIdToLoadingFoldersPromiseMap[projectId] = this.innerGetAllWorkflowFolders(
                projectId,
                workflowFolderLimit,
                0,
                relationsLimit,
            ).then(() => {
                // After all the folder are fetched, we need to fetch all of its relations (the groups of the folders)
                return this.innerGetAllFoldersRelations(projectId, relationsLimit);
            });
        }

        return this.projectIdToLoadingFoldersPromiseMap[projectId]!.then(() => {
            return this.$q.resolve(this.projectIdToFoldersMap.get(projectId) as WorkflowFolder[]);
        });
    }

    private innerGetAllWorkflowFolders(projectId, limit, skip, relationsLimit) {
        // Fetch folders page
        return this.tonkeanService.getWorkflowFolders(projectId, skip, limit, relationsLimit).then((data) => {
            const folders = data.entities;
            const relations = data.relations;
            // If no folders returned, return.
            if (!folders || !folders.length) {
                return this.$q.resolve();
            }

            // Getting the folder's groups ids from the relations.
            const folderIdToGroupIds = {};
            for (const relation of relations) {
                const relationFolderId = relation.folderId.id;
                const relationId = relation.relatedEntityId;
                folderIdToGroupIds[relationFolderId] = folderIdToGroupIds[relationFolderId] || [];
                if (relation.relatedEntityId.startsWith('GRUP')) {
                    folderIdToGroupIds[relationFolderId].push(relationId);
                }
            }

            // Add to cache
            folders.forEach((folder) =>
                this.addOrUpdateFolderInCache(projectId, folder, folderIdToGroupIds[folder.id]),
            );

            let nextFoldersPagePromise = this.$q.resolve();
            // Fetch next folder page if needed
            if (folders.length === limit) {
                nextFoldersPagePromise = this.innerGetAllWorkflowFolders(
                    projectId,
                    limit,
                    skip + limit,
                    relationsLimit,
                );
            }

            return nextFoldersPagePromise;
        });
    }

    private innerGetAllFoldersRelations(projectId, limit) {
        const projectFolders = this.projectIdToFoldersMap.get(projectId) ?? [];
        const promiseList: Promise<any>[] = [];
        // Fetch relations for each folder in the project
        for (const projectFolder of projectFolders) {
            if (projectFolder!.groupIds.length === limit) {
                // At the first time we are doing limit+skip because the first relations page was already fetched
                promiseList.push(this.innerSpecificFolderGetAllRelations(projectId, projectFolder!.id, limit, limit));
            }
        }

        return this.$q.all(promiseList);
    }

    private innerSpecificFolderGetAllRelations(projectId, folderId, limit, skip) {
        return this.tonkeanService.getWorkflowFoldersRelations(folderId, projectId, skip, limit).then((data) => {
            if (!data.entities || !data.entities.length) {
                return this.$q.resolve();
            }

            // Getting the folder from cache
            const relevantFolder = this.utils.findFirst(
                this.projectIdToFoldersMap.get(projectId) ?? [],
                (folder) => folder.id === folderId,
            );

            if (!relevantFolder) {
                return;
            }

            // Add the fetched groups to the folder
            const groupIds = data.entities
                .filter((entity) => entity.relatedEntityId.startsWith('GRUP'))
                .map((entity) => entity.relatedEntityId);
            relevantFolder.groupIds.push(...groupIds);

            let nextPageRelationPromise = this.$q.resolve();
            // Fetch next related entities page if needed
            if (data.entities.length === limit) {
                nextPageRelationPromise = this.innerSpecificFolderGetAllRelations(
                    projectId,
                    folderId,
                    limit,
                    skip + limit,
                );
            }
            return nextPageRelationPromise;
        });
    }
}

export default WorkflowFolderManager;

angular.module('tonkean.app').service('workflowFolderManager', WorkflowFolderManager);
