import ItemInterfaceFieldsWidget from './components/ItemInterfaceFieldsWidget';
import ItemInterfaceFieldsWidgetConfigurationEditor from './components/ItemInterfaceFieldsWidgetConfigurationEditor';
import type FieldsItemWidgetConfiguration from './FieldsItemWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const ItemInterfaceFieldsWidgetPackage: ItemInterfaceWidgetPackage<FieldsItemWidgetConfiguration> = {
    displayComponent: ItemInterfaceFieldsWidget,
    configurationComponent: ItemInterfaceFieldsWidgetConfigurationEditor,
};

export default ItemInterfaceFieldsWidgetPackage;
