import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import SearchBox from './SearchBox';
import useEnvironmentFlags from '../../../../infrastructure/hooks/useEnvironmentFlags';

import { Menu, MenuItem, useDebouncer } from '@tonkean/infrastructure';
import { FormType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid ${Theme.colors.gray_400};
    margin-bottom: 16px;
    background: ${Theme.colors.basicBackground};
`;

const NewButton = styled(Button)`
    position: relative;
    width: 60px;
`;

const ButtonHeightContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 12px;

    // Hack for menu popup position
    padding-right: 2px;
    margin-right: 10px; // With no arrow - popover as a default distance
`;

interface Props {
    searchTerm: string;
    onChange(term: string): void;
    createNewForm: (formType: FormType) => void;
}

const FormsOutlineHeader: React.FC<Props> = ({ searchTerm, onChange, createNewForm }) => {
    const [value, setValue] = useState(searchTerm);
    const [open, setOpen] = useState(false);

    const { isDraft } = useEnvironmentFlags();

    useEffect(() => {
        setValue(searchTerm);
    }, [searchTerm]);

    const emitValue = useCallback(() => {
        if (searchTerm === value) {
            return;
        }

        onChange(value);
    }, [value, searchTerm, onChange]);

    useDebouncer(emitValue, 200);

    return (
        <Wrapper>
            <SearchBox
                value={value}
                onChange={(event) => setValue(event.target.value)}
                data-automation="forms-outline-header-search-box-forms-outline"
                autoFocus
            />

            {isDraft && (
                <Menu
                    show={open}
                    placement="right-start"
                    onClose={() => setOpen(false)}
                    menuItems={
                        <>
                            <MenuItem
                                data-automation="forms-outline-header-crete-form-button"
                                onClick={() => createNewForm(FormType.CREATE)}
                            >
                                Create Form
                            </MenuItem>
                            <MenuItem
                                data-automation="forms-outline-header-update-form-button"
                                onClick={() => createNewForm(FormType.UPDATE)}
                            >
                                Update Form
                            </MenuItem>
                        </>
                    }
                >
                    <ButtonHeightContainer>
                        <NewButton
                            data-automation="forms-outline-header-new-button"
                            size={ButtonSize.SMALL}
                            onClick={() => setOpen((isOpen) => !isOpen)}
                        >
                            + New
                        </NewButton>
                    </ButtonHeightContainer>
                </Menu>
            )}
        </Wrapper>
    );
};

export default FormsOutlineHeader;
