import { timezonesPreset } from './commonPresets';

import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';
import type { FormulaSingleField } from '@tonkean/tonkean-entities';

export function timeZoneField(): FormulaSingleField {
    return {
        displayName: 'Timezone',
        explanation: '"America/Los_Angeles", "Asia/Tokyo", "Europe/Paris", "Asia/Jerusalem" etc...',
        dataType: FieldType.String,
        fieldDefinitionType: FieldDefinitionType.SINGLE,
        defaultValue: 'UTC',
        defaultTab: FormulaPopoverTab.PRESET,
        presetValues: timezonesPreset,
    };
}
