import React, { useMemo } from 'react';

import ItemDetailsEditForm from '../ItemDetailsEditForm/ItemDetailsEditForm';
import ItemDetailsInputSourceSelection from '../ItemDetailsInputSourceSelection/ItemDetailsInputSourceSelection';

import type { TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const ItemDetailsModuleEditorSidePaneContent: React.FC<Props> = ({ groupId, workflowVersionId, targetId }) => {
    const targetIsGroup = useMemo(() => {
        return targetId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.GROUP]);
    }, [targetId]);

    return (
        <>
            <ItemDetailsEditForm
                key={targetId}
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                targetId={targetId}
            />
            {targetIsGroup && <ItemDetailsInputSourceSelection workflowVersionId={workflowVersionId} />}
        </>
    );
};

export default ItemDetailsModuleEditorSidePaneContent;
