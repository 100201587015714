import { useField } from 'formik';
import type { FormikErrors } from 'formik/dist/types';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import AdvancedOptions from './components/AdvancedOptions';
import BodyComponent from './components/BodyComponent';
import HeaderComponent from './components/HeaderComponent';
import Pagination from './components/Pagination/Pagination';
import QueryParamsComponent from './components/QueryParamsComponent';
import RequestTypeComponent from './components/RequestTypeComponent';
import UrlComponent from './components/UrlComponent';
import useCustomRequestAdditionalTab from './utils/useCustomRequestAdditionalTab';
import type { ConstantActionConfig } from '../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { Field, FormikHelpers, H4 } from '@tonkean/infrastructure';
import { ActionDefinitionType, ActionParameterType, ActionType, HttpMethodType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import type { BaseActionParameter, HttpRequestDefinition } from '@tonkean/tonkean-entities';

const InlineRequestMethodAndUrl = styled(Field)`
    display: grid;
    grid-template-columns: 100px auto;
    width: 100%;
`;

const StyledField = styled(Field)`
    margin-bottom: 8px;
`;

const HeaderText = styled(H4)`
    padding-top: 36px;

    &:first-child {
        padding-top: 0;
    }

    margin-bottom: 16px;
`;

interface Props {
    namePrefix: string;
    errors: FormikErrors<any>;
    hideEnvUrlPrefix: boolean;
    urlHeader?: string;
    includePaging?: boolean;
    expressionProps?: FormikExpressionProps;
    isLoading?: boolean;
    additionalActionParameters?: BaseActionParameter[];
    actionParametersToFilter?: string[];
    fieldListParameters?: BaseActionParameter[];
    actionType?: ActionType;
    constantParameters: ConstantActionConfig[];
    shouldIncludeFileDownloading: boolean;
}

const HttpRequestConfiguration: React.FC<Props> = ({
    namePrefix,
    errors,
    hideEnvUrlPrefix,
    urlHeader = 'URL',
    includePaging = false,
    expressionProps,
    isLoading = false,
    additionalActionParameters = EMPTY_ARRAY,
    actionParametersToFilter,
    fieldListParameters,
    actionType,
    constantParameters,
    shouldIncludeFileDownloading,
}) => {
    const [contentType] = useField<string>(`${namePrefix}.contentType`);
    const [methodType] = useField<HttpMethodType>(`${namePrefix}.methodType`);
    // List of all the constant ActionParameter
    const defaultConstantParameters: BaseActionParameter[] = useMemo(() => {
        return constantParameters
            .filter((param) => {
                if (!includePaging) {
                    const paramIds = constantParameters.map((constantParam) => constantParam.id);
                    return paramIds.includes(param.id);
                }

                return true;
            })
            .map((param) => {
                if (param.parameterType === ActionParameterType.CLOSE_LIST) {
                    return {
                        id: param.id,
                        displayName: param.displayName,
                        parameterType: param.parameterType,
                        options: param.defaultOptions,
                    };
                } else {
                    return {
                        id: param.id,
                        displayName: param.displayName,
                        parameterType: param.parameterType,
                    };
                }
            });
    }, [constantParameters, includePaging]);

    // The Default params of the http response.
    const defaultHttpRequestParamsTab = useCustomRequestAdditionalTab(ActionDefinitionType.HTTP, []);

    // The definition of the custom action
    const [definitionWrapper] = useField<HttpRequestDefinition>('definition');

    // the default parameters for paginated param with the default parameters
    const allParameters: BaseActionParameter[] = useMemo(() => {
        return [
            ...defaultConstantParameters,
            ...additionalActionParameters,
            ...(definitionWrapper.value.pagination?.nextPageParameters?.map((param) => ({
                id: param.parameterName.replaceAll(' ', '_').replaceAll(',', '_').toLowerCase(),
                displayName: param.parameterName,
                parameterType: ActionParameterType.TONKEAN_EXPRESSION,
            })) || []),
        ];
    }, [defaultConstantParameters, additionalActionParameters, definitionWrapper.value.pagination?.nextPageParameters]);

    const getPaginatedFields = useCallback(() => {
        return Promise.resolve(
            allParameters.map((param) => ({
                name: param.id,
                value: param.id,
                label: param.displayName,
                id: param.id,
            })),
        );
    }, [allParameters]);

    const defaultParamsWithPaginatedParamsTab: TonkeanExpressionAdditionalTab = useMemo(() => {
        return {
            id: 'PAGINATED_PARAMS',
            label: 'Custom Action Params',
            iconClass: 'mod-actions',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: getPaginatedFields,
        };
    }, [getPaginatedFields]);

    const shouldShowBodyInput = methodType.value !== HttpMethodType.GET;
    const shouldShowContentType = methodType.value !== HttpMethodType.GET;
    const shouldShowKeyValueOption =
        shouldShowBodyInput && actionType
            ? ![ActionType.UPLOAD, ActionType.DOWNLOAD, ActionType.STREAM_IMAGE].includes(actionType)
            : false;

    const updatedRequestExpressionProps = useMemo(() => {
        return {
            ...expressionProps,
            additionalTabs: expressionProps?.additionalTabs
                ? [defaultParamsWithPaginatedParamsTab, ...expressionProps?.additionalTabs]
                : [defaultParamsWithPaginatedParamsTab],
            fieldsToFilter: actionParametersToFilter,
        };
    }, [actionParametersToFilter, defaultParamsWithPaginatedParamsTab, expressionProps]);

    const nextPageExpressionProps = useMemo(() => {
        return {
            ...expressionProps,
            additionalTabs: [
                defaultHttpRequestParamsTab,
                ...(expressionProps?.additionalTabs || []),
                defaultParamsWithPaginatedParamsTab,
            ],
        };
    }, [defaultHttpRequestParamsTab, defaultParamsWithPaginatedParamsTab, expressionProps]);

    const bodyExpressionProps = useMemo(() => {
        return { ...updatedRequestExpressionProps, fieldsToFilter: [] };
    }, [updatedRequestExpressionProps]);

    // Todo: Add Two way binding between the url and the query params

    return (
        <FormikHelpers>
            <HeaderText $bold>{urlHeader}</HeaderText>

            <Field>
                <InlineRequestMethodAndUrl>
                    <RequestTypeComponent isLoading={isLoading} name={`${namePrefix}.methodType`} />
                    <UrlComponent
                        isLoading={isLoading}
                        expressionProps={updatedRequestExpressionProps}
                        name={`${namePrefix}.url`}
                        hideEnvUrlPrefix={hideEnvUrlPrefix}
                    />
                </InlineRequestMethodAndUrl>
            </Field>

            <HeaderText $bold>Query Params</HeaderText>

            <StyledField error={errors[namePrefix]?.['queryParams'] ? 'Invalid query params' : undefined}>
                <QueryParamsComponent
                    isLoading={isLoading}
                    expressionProps={updatedRequestExpressionProps}
                    showQueryParamType={includePaging}
                    name={`${namePrefix}.queryParams`}
                />
            </StyledField>

            {shouldShowBodyInput && (
                <>
                    <HeaderText $bold>Body</HeaderText>

                    <Field>
                        <BodyComponent
                            isLoading={isLoading}
                            expressionProps={bodyExpressionProps}
                            namePrefix={namePrefix}
                            isFormBody={contentType.value === 'application/x-www-form-urlencoded'}
                        />
                    </Field>
                </>
            )}

            <HeaderText $bold>Headers</HeaderText>

            <StyledField error={errors[namePrefix]?.['headers'] ? 'Invalid Headers' : undefined}>
                <HeaderComponent
                    isLoading={isLoading}
                    expressionProps={updatedRequestExpressionProps}
                    shouldShowContentType={shouldShowContentType}
                    name={namePrefix}
                    actionType={actionType}
                    shouldIncludeFileDownloading={shouldIncludeFileDownloading}
                />
            </StyledField>

            <HeaderText $bold>Advanced</HeaderText>

            <AdvancedOptions
                name={namePrefix}
                isLoading={isLoading}
                fieldListParameters={fieldListParameters || []}
                shouldShowKeyValueOption={shouldShowKeyValueOption}
            />

            {includePaging && (
                <Pagination
                    isLoading={isLoading}
                    expressionProps={nextPageExpressionProps}
                    name={`${namePrefix}.pagination`}
                />
            )}
        </FormikHelpers>
    );
};

export default HttpRequestConfiguration;
