import * as React from 'react';
import styled from 'styled-components';

import { OcrOutputType } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const OutputTypeSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const OutputTypeButton = styled(Button)`
    margin-right: 8px;
`;

interface Props {
    ocrOutputType: OcrOutputType;
    onOcrOutputTypeSelected: (ocrOutputType: OcrOutputType) => void;
}

const DocumentPreviewOutputTypeSelector: React.FC<Props> = ({ ocrOutputType, onOcrOutputTypeSelected }) => {
    return (
        <OutputTypeSelectorWrapper>
            <OutputTypeButton
                size={ButtonSize.SMALL}
                shape={ButtonShape.ROUND}
                outlined={ocrOutputType !== OcrOutputType.TRANSCRIPT}
                onClick={() => onOcrOutputTypeSelected(OcrOutputType.TRANSCRIPT)}
            >
                Transcript
            </OutputTypeButton>

            <OutputTypeButton
                size={ButtonSize.SMALL}
                shape={ButtonShape.ROUND}
                outlined={ocrOutputType !== OcrOutputType.WORDS}
                onClick={() => onOcrOutputTypeSelected(OcrOutputType.WORDS)}
            >
                Words
            </OutputTypeButton>

            <OutputTypeButton
                size={ButtonSize.SMALL}
                shape={ButtonShape.ROUND}
                outlined={ocrOutputType !== OcrOutputType.PARAGRAPHS}
                onClick={() => onOcrOutputTypeSelected(OcrOutputType.PARAGRAPHS)}
            >
                Paragraphs
            </OutputTypeButton>

            <OutputTypeButton
                size={ButtonSize.SMALL}
                shape={ButtonShape.ROUND}
                outlined={ocrOutputType !== OcrOutputType.BLOCKS}
                onClick={() => onOcrOutputTypeSelected(OcrOutputType.BLOCKS)}
            >
                Blocks
            </OutputTypeButton>
        </OutputTypeSelectorWrapper>
    );
};

export default DocumentPreviewOutputTypeSelector;
