import React from 'react';
import styled from 'styled-components';

import FieldSelector from '../../CommonWidgetConfiguration/FieldSelector';
import { FieldsSelectorByWorkflowVersionIdGroupSummary } from '../../CommonWidgetConfiguration/FieldsSelectorByWorkflowVersionIdGroupSummary';

import { Checkbox } from '@tonkean/infrastructure';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const FieldRow = styled.div`
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 5fr auto;
    align-items: center;
    margin-left: 2px;
`;

const FieldDetailsWrapper = styled.div`
    padding: 2px 0;
    margin-left: 15px;
    border: 1px solid ${Theme.colors.gray_300};
    box-sizing: border-box;
    border-radius: 4px;
    gap: 6px;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
`;

const StyledTnkFieldSelector = styled.div`
    width: 100%;
    padding: 8px 0;
    padding-right: 5px;
`;

const ExtraBorder = styled.div`
    width: 2px;
    height: 60px;
    margin-left: 3px;
    border-radius: 4px;
    background: ${Theme.colors.primaryHighlight};
`;

interface Label {
    fieldDefinition: FieldDefinition;
    label: string;
    icon: React.ReactElement;
}

interface Props {
    fieldDefinitions: FieldDefinition[];
    addFieldToConfiguration(fieldId: FieldDefinition): void;
    groups: FieldsSelectorByWorkflowVersionIdGroupSummary[] | undefined;
    isChecked?: boolean;
}

const FieldsWidgetAddField: React.FC<Props> = ({
    addFieldToConfiguration,
    fieldDefinitions,
    groups,
    isChecked = true,
}) => {
    return (
        <FieldRow className="FieldsWidgetAddField">
            <Checkbox boldLabel={false} checked={isChecked} disabled labelFillSpace>
                <FieldDetailsWrapper>
                    <ExtraBorder />
                    <StyledTnkFieldSelector>
                        <FieldSelector
                            addFieldToConfiguration={addFieldToConfiguration}
                            groups={groups}
                            groupByPredicate={(value) => value.fieldDefinition.workflowVersionId}
                            fieldDefinitions={fieldDefinitions}
                        />
                    </StyledTnkFieldSelector>
                </FieldDetailsWrapper>
            </Checkbox>
        </FieldRow>
    );
};
export default React.memo(FieldsWidgetAddField);
