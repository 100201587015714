import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef, FirstDataRenderedEvent, GridApi } from '@ag-grid-community/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ViewDataBaseCellRenderer from './CellRenderers/ViewDataBaseCellRenderer';
import ViewDataDateCellRenderer from './CellRenderers/ViewDataDateCellRenderer';
import ViewDataEditCellRenderer from './CellRenderers/ViewDataEditCellRenderer';
import ViewDataInspectCellRenderer from './CellRenderers/ViewDataInspectCellRenderer';
import ViewDataTitleCellRenderer from './CellRenderers/ViewDataTitleCellRenderer';
import ViewDataUrlCellRenderer from './CellRenderers/ViewDataUrlCellRenderer';
import columnsDef from './config/columnsDef';
import columnTypes from './config/columnTypes';
import {
    TnkProjectIntegrationViewDataPreviewListLoading,
    TnkProjectIntegrationViewDataPreviewListNoRows,
} from './tnkProjectIntegrationViewDataPreviewListOverlays';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import { Input, Placeholder } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { classNames } from '@tonkean/utils';

const PreviewLabelContainer = styled.div`
    display: grid;
    grid-template-columns: auto minmax(200px, 40%);
    gap: 10px;

    margin-bottom: 16px;
`;

const PreviewLabel = styled.div`
    font-weight: bold;
`;

const PreviewCountPlaceholder = styled(Placeholder)`
    display: inline-block;
    margin: 0 4px 0 4px;
`;

const PreviewPlaceholder = styled.div`
    display: flex;
    align-items: center;
`;

const AgGridWrapper = styled.div<{ $height?: string }>`
    height: ${(props) => props.$height};
    width: 100%;
`;

interface Props {
    entityPluralLabel: string;
    projectIntegration: ProjectIntegration;
    viewData: any;
    viewType: any;
    selectedEntity: string;
    onDataChanged?: () => void;
    fullScreen?: boolean;
    height?: string; // Height that will override the other settings.
    gridClassName?: string;
    editAllowed: boolean;
}

const fieldTypeToCellRenderer = { date: 'viewDataDateCellRenderer' };

const fieldNameToCellRenderer = {
    url: 'viewDataUrlCellRenderer',
    TNK_NEW_CREATED_DATE: 'viewDataDateCellRenderer',
    TNK_NEW_MODIFIED_DATE: 'viewDataDateCellRenderer',
};

const TnkProjectIntegrationViewDataPreviewList: React.FC<Props> = ({
    entityPluralLabel,
    projectIntegration,
    viewData,
    viewType,
    selectedEntity,
    onDataChanged,
    fullScreen = false,
    height = fullScreen ? 'calc(100vh - 190px)' : '500px',
    gridClassName,
    editAllowed,
}) => {
    const [gridApi, setGridApi] = useState<GridApi>();

    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');
    const $rootScope = useAngularService('$rootScope');

    const { data: projectIntegrationSummaries, loading: projectIntegrationSummariesLoading } = useTonkeanService(
        'getProjectIntegrationEntitySummaries',
        projectIntegration.id,
    );

    const isEditRecordsEnabled = useMemo<boolean>(
        () =>
            !!projectIntegrationSummaries?.entities?.find(
                (projectIntegrationEntity) => projectIntegrationEntity.id === selectedEntity,
            )?.isAddRecordsEnabled,
        [projectIntegrationSummaries?.entities, selectedEntity],
    );

    useEffect(() => {
        gridApi?.setColumnDefs(
            columnsDef(
                projectIntegration,
                viewData.entity,
                $rootScope,
                selectedEntity,
                onDataChanged,
                editAllowed || isEditRecordsEnabled,
            ),
        );
    }, [
        $rootScope,
        editAllowed,
        gridApi,
        isEditRecordsEnabled,
        onDataChanged,
        projectIntegration,
        selectedEntity,
        viewData.entity,
    ]);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const [searchText, setSearchText] = useState('');
    const { data: previewData, loading: previewLoading } = useAsyncMethod(
        tonkeanService,
        'previewSyncView',
        projectManager.project.id,
        projectIntegration.id,
        viewData,
        viewType,
        undefined,
        searchText,
    );

    const { data: availableFields } = useAsyncMethod(
        tonkeanService,
        'getAvailableExternalFields',
        projectIntegration.id,
        viewData.entity,
    );

    const fieldNameToDisplayNameMap = useMemo(() => {
        return availableFields?.entitiesWithLabels.reduce((nameToDisplayNameMap, field) => {
            nameToDisplayNameMap[field.name] = {
                label: field.label,
                type: field.type,
            };
            return nameToDisplayNameMap;
        }, {});
    }, [availableFields?.entitiesWithLabels]);

    // Refresh so the date format will apply
    const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
        params.columnApi.autoSizeAllColumns();
    };

    const getRelevantCellRenderer = useCallback(
        (fieldName: string) => {
            return (
                fieldNameToCellRenderer[fieldNameToDisplayNameMap[fieldName]?.label.toLowerCase()] ||
                fieldTypeToCellRenderer[fieldNameToDisplayNameMap[fieldName]?.type.toLowerCase()] ||
                'viewDataBaseCellRenderer'
            );
        },
        [fieldNameToDisplayNameMap],
    );

    // Extract original entities (have all the fields) from the returned entities
    const entities = useMemo(() => {
        return (
            previewData?.entities.map((entity) => ({
                ...entity.originalEntity,
                tnk_title: entity.name,
                id: entity.id,
            })) || []
        );
    }, [previewData?.entities]);

    // We want to canonize the columns as definitions from the fetches original entities
    useEffect(() => {
        const columnStaticDefs = columnsDef(
            projectIntegration,
            viewData.entity,
            $rootScope,
            selectedEntity,
            onDataChanged,
            editAllowed || isEditRecordsEnabled,
        );

        // Add the relevant fields for the entity as columns
        if (entities[0] && fieldNameToDisplayNameMap) {
            const fieldNames = [...new Set(entities.flatMap((e) => Object.keys(e)))]
                .map((fieldName) => ({
                    headerName: fieldNameToDisplayNameMap[fieldName]?.label || fieldName,
                    field: fieldName,
                    type: 'resizableColumn',
                    headerClass: 'text-capitalize',
                    cellRenderer: getRelevantCellRenderer(fieldName),
                    flex: 1,
                    minWidth: 180,
                }))
                .filter((columnDef) => !columnStaticDefs.find((def) => def.field === columnDef.field));

            gridApi?.setColumnDefs([...columnStaticDefs, ...fieldNames] as ColDef[]);
        }
    }, [
        entities,
        gridApi,
        projectIntegration,
        viewData.entity,
        fieldNameToDisplayNameMap,
        getRelevantCellRenderer,
        $rootScope,
        selectedEntity,
        onDataChanged,
        isEditRecordsEnabled,
        editAllowed,
    ]);

    // Show loading/no items in the ag-grid overlay
    useEffect(() => {
        if (previewLoading || projectIntegrationSummariesLoading) {
            gridApi?.showLoadingOverlay();
        } else if (previewData?.entities.length) {
            gridApi?.hideOverlay();
        } else {
            gridApi?.showNoRowsOverlay();
        }
    }, [gridApi, previewLoading, previewData?.entities.length, projectIntegrationSummariesLoading]);

    ModuleRegistry.registerModules([ClientSideRowModelModule]);

    return (
        <>
            <PreviewLabelContainer>
                <PreviewLabel data-automation="tnk-project-integration-view-data-preview-list-items-preview">
                    {previewLoading || projectIntegrationSummariesLoading ? (
                        <PreviewPlaceholder>
                            Showing Preview for <PreviewCountPlaceholder $width="24px" $height="16px" /> of{' '}
                            <PreviewCountPlaceholder $width="24px" $height="16px" /> {entityPluralLabel}
                        </PreviewPlaceholder>
                    ) : (
                        <div>
                            Showing Preview for {previewData?.entities.length} of {previewData?.totalCount}{' '}
                            {entityPluralLabel}
                        </div>
                    )}
                </PreviewLabel>
                <Input
                    value={searchText}
                    onChange={({ target }) => setSearchText(target.value)}
                    placeholder="Search..."
                />
            </PreviewLabelContainer>

            <AgGridWrapper $height={height} className={classNames('ag-theme-material', gridClassName)}>
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={entities}
                    components={{
                        viewDataUrlCellRenderer: ViewDataUrlCellRenderer,
                        viewDataDateCellRenderer: ViewDataDateCellRenderer,
                        viewDataInspectCellRenderer: ViewDataInspectCellRenderer,
                        viewDataEditCellRenderer: ViewDataEditCellRenderer,
                        viewDataBaseCellRenderer: ViewDataBaseCellRenderer,
                        viewDataTitleCellRenderer: ViewDataTitleCellRenderer,
                    }}
                    rowModelType="clientSide"
                    columnTypes={columnTypes}
                    onFirstDataRendered={onFirstDataRendered}
                    loadingOverlayComponent={TnkProjectIntegrationViewDataPreviewListLoading}
                    loadingOverlayComponentParams={{ entityPluralLabel }}
                    noRowsOverlayComponent={TnkProjectIntegrationViewDataPreviewListNoRows}
                    noRowsOverlayComponentParams={{ entityPluralLabel }}
                    animateRows
                    enableCellTextSelection
                />
            </AgGridWrapper>
        </>
    );
};

export default TnkProjectIntegrationViewDataPreviewList;
