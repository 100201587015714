import SplitAndTakeIndexFunction from '../functions/SplitAndTakeIndexFunction';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class EmailDomainFunction extends SplitAndTakeIndexFunction {
    override readonly displayName = 'Email Domain';
    override readonly description = 'Will split the given email by "@" and return the domain.';
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Email',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Split By',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: '@',
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Take Index',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 1,
            defaultTab: FormulaPopoverTab.CONST,
        },
    ];
}
