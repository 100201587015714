import React from 'react';

import { TextEllipsis, TonkeanAvatar as TrackAvatar } from '@tonkean/infrastructure';
import { WorkflowActivityEvents } from '@tonkean/shared-services';
import { DataSourceType } from '@tonkean/tonkean-entities';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import utils, { classNames } from '@tonkean/utils';
import Utils from '@tonkean/utils';

export default class WorkerAuditLogItem extends React.Component<any, any> {
    messageRef: any;
    tooltipRef: any;

    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.state = {
            showActionDetails: false,
            activityChangedProperties: this.showActivityChangesKeys(),
            activityDate: utils.formatDate(this.props.activity.created, true, true, true, true),
        };

        this.messageRef = React.createRef();
        this.tooltipRef = React.createRef();
    }

    /**
     * The main render function
     */
    override render() {
        const { jsx: message, showDetailsButton } = this.activityMessageDisplayComponent();

        return (
            <div
                className={classNames('worker-audit-log-item', this.props.oneLineMessage && ['tnk-tooltip', 'mod-top'])}
            >
                <figure>
                    <TrackAvatar owner={this.props.activity.actor} />
                </figure>
                <main className={classNames(this.props.oneLineMessage && 'worker-audit-log-item-one-line')}>
                    <div className="worker-audit-log-item-icon">{this.props.icon}</div>
                    <div className="mod-line-height-12">
                        <TextEllipsis tooltipLimitWidth={15} numberOfLines={1} tooltip>
                            {message} {this.state.activityChangedProperties}
                        </TextEllipsis>
                    </div>
                    <div className="worker-audit-log-item-footer">
                        <small>
                            {this.props.activity.actor.firstName} - {this.state.activityDate}
                        </small>
                        {showDetailsButton && !this.props.hideButton && (
                            <button
                                className="inline-button show-details"
                                type="button"
                                onClick={() => this.showActivityInformation()}
                            >
                                View details
                            </button>
                        )}
                    </div>
                </main>
                {this.props.oneLineMessage && (
                    <span className="tnk-tooltip-text hidden" ref={this.tooltipRef}>
                        {message}
                    </span>
                )}
            </div>
        );
    }

    showTooltip() {
        const showTooltip = this.messageRef.current.offsetWidth < this.messageRef.current.scrollWidth;

        if (showTooltip) {
            this.tooltipRef.current.classList.remove('hidden');
        } else {
            this.tooltipRef.current.classList.add('hidden');
        }
    }

    hideTooltip() {
        this.tooltipRef.current.classList.add('hidden');
    }

    filterChangeRows(diffObj) {
        return Utils.objValues(diffObj).filter(
            (row) =>
                row.key.indexOf('evaluated') !== 0 &&
                row.path !== 'compiledQueryTimestamp' &&
                row.key !== 'id' &&
                row.key !== 'compiledQuery' &&
                !this.isRowHaveIds(row) &&
                row.value1 !== row.value2,
        );
    }

    /**
     * If the value of one of the values is tonkean id, filter it out.
     */
    isRowHaveIds(row) {
        return (
            (row.value1 && getTonkeanEntityType(row.value1) !== null) ||
            (row.value2 && getTonkeanEntityType(row.value2) !== null)
        );
    }

    /**
     * Gets the properties names that was changed for that activity's component
     */
    showActivityChangesKeys() {
        const diffObj = this.getDiffObjByType();
        const changesRows = this.filterChangeRows(diffObj);

        return changesRows
            .map((row) => this.getChangeKeyReadableSummery(row.parent ? `${row.parent}.${row.key}` : row.key))
            .toString()
            .replaceAll(',', ', ');
    }

    getChangeKeyReadableSummery(key) {
        return key
            .replaceAll(/\.?originalExpression\.?/g, '')
            .replaceAll(/\.?customTriggerActionDefinition\.?/g, '')
            .replaceAll(/(\d)\W([a-zA-Z]*)/g, '$2')
            .replaceAll(/([a-zA-Z]*)\W(\d)/g, '$1')
            .replaceAll(/([a-zA-Z]*)\.([a-zA-Z]*)/g, '$1 - $2')
            .replaceAll(/([A-Z][a-z])/g, ' $1')
            .trim()
            .toLowerCase();
    }

    /**
     * Gets the internal information component for the activity;
     */
    showActivityInformation() {
        const diffObj = this.getDiffObjByType();
        const changesRows = this.filterChangeRows(diffObj);

        const changes = changesRows.map((row) => ({
            key: this.makeChangeKeyHumanReadable(row.parent ? `${row.parent}.${row.key}` : row.key),
            old: row.value1,
            new: row.value2,
        }));

        this.props.modalUtils.openWorkflowVersionChangeDetailsModal(changes, this.props.activity);
    }

    /**
     * Will add spaces between uppercase letters, and remove dots and common words
     *
     * @param key {string} - the unreadable change key
     */
    makeChangeKeyHumanReadable(key) {
        return key
            .replaceAll(/\.?originalExpression\.?/g, '')
            .replaceAll(/\.?customTriggerActionDefinition\.?/g, '')
            .replaceAll(/(.*)\.(.*)/g, '$1 - $2')
            .replaceAll(/([A-Z][a-z])/g, ' $1')
            .trim()
            .toLowerCase();
    }

    getDiffObjByType() {
        const diffObj = {};
        switch (this.props.activity.type) {
            case 'CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED':
                // delete the timestamp property because it updated on every change and i dont want the generic compare to recognize it as a change.
                delete this.props.activity.metadata.oldTrigger.queryDefinition.query.compiledQueryTimestamp;
                delete this.props.activity.metadata.updatedTrigger.queryDefinition.query.compiledQueryTimestamp;

                // Since the entity enrichment mechanism also works on our activity entity,
                // we must clear the enriched (by mistake) entities, so we won't be stuck
                // in an infinite loop.
                this.clearEnrichedEntities(this.props.activity.metadata.oldTrigger.queryDefinition);
                this.clearEnrichedEntities(this.props.activity.metadata.updatedTrigger.queryDefinition);

                this.clearEnrichedEntities(this.props.activity.metadata.oldTrigger.customTriggerActions);
                this.clearEnrichedEntities(this.props.activity.metadata.updatedTrigger.customTriggerActions);

                this.diffJson(
                    diffObj,
                    this.props.activity.metadata.oldTrigger.queryDefinition,
                    this.props.activity.metadata.updatedTrigger.queryDefinition,
                );
                this.diffJson(
                    diffObj,
                    this.props.activity.metadata.oldTrigger.customTriggerActions,
                    this.props.activity.metadata.updatedTrigger.customTriggerActions,
                );

                break;

            case 'INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED': {
                this.diffJson(
                    diffObj,
                    this.props.activity.metadata.oldSyncConfig.viewData,
                    this.props.activity.metadata.newSyncConfig.viewData,
                );
                break;
            }

            case 'SOLUTION_SITE_PAGE_UPDATED':
                this.diffJson(
                    diffObj,
                    this.props.activity.metadata.oldProperties,
                    this.props.activity.metadata.newProperties,
                );

                break;

            case 'ITEM_DETAILS_UPDATED':
                diffObj['Name'] = {
                    key: 'Name',
                    value1: this.props.activity.metadata.oldName
                        ? this.props.activity.metadata.oldName.toString()
                        : null,
                    value2: this.props.activity.metadata.newName
                        ? this.props.activity.metadata.newName.toString()
                        : null,
                };

                diffObj['Description'] = {
                    key: 'Description',
                    value1: this.props.activity.metadata.oldDescription
                        ? this.props.activity.metadata.oldDescription.toString()
                        : null,
                    value2: this.props.activity.metadata.newDescription
                        ? this.props.activity.metadata.newDescription.toString()
                        : null,
                };

                diffObj['RequesterExpression'] = {
                    key: 'RequesterExpression',
                    value1: this.props.activity.metadata.oldRequesterExpression
                        ? this.props.activity.metadata.oldRequesterExpression.toString()
                        : null,
                    value2: this.props.activity.metadata.newRequesterExpression
                        ? this.props.activity.metadata.newRequesterExpression.toString()
                        : null,
                };

                break;

            case 'ITEM_DETAILS_CREATED':
                diffObj['Name'] = {
                    key: 'Name',
                    value1: this.props.activity.metadata.name.toString(),
                    value2: '',
                };

                diffObj['Description'] = {
                    key: 'Description',
                    value1: this.props.activity.metadata.description.toString(),
                    value2: '',
                };

                break;
        }

        return diffObj;
    }

    /**
     * Clears any enriched tonkean entities from the json.
     */
    clearEnrichedEntities(json) {
        if (!json || !(json instanceof Object)) {
            return;
        }

        const allJsonProperties = Object.keys(json);

        allJsonProperties.forEach((key) => {
            if (json[key] && json[key] instanceof Object && json[key].id && getTonkeanEntityType(json[key].id)) {
                json[key] = json[key].id;
            }

            if (Array.isArray(json[key]) && json[key].length) {
                for (let i = 0; i < json[key].length; i++) {
                    this.clearEnrichedEntities(json[key][i]);
                }
            } else if (json[key] instanceof Object) {
                this.clearEnrichedEntities(json[key]);
            }
        });
    }

    getFieldType() {
        let fieldType;
        if (this.props.activity.reference1?.idRelationField) {
            fieldType = 'matching entity';
        } else if (this.props.activity.reference1 && this.props.activity.reference1.targetType === 'GLOBAL') {
            fieldType = 'global field';
        } else {
            fieldType = 'field';
        }
        return fieldType;
    }

    /**
     * Gets the display component for the activity;
     */
    activityMessageDisplayComponent() {
        switch (this.props.activity.type) {
            case 'WORKER_ENABLED_STATE_CHANGED':
                return { jsx: this.getWorkerEnabledStateChangedDisplayComponent(), showDetailsButton: false };

            case 'BUILD_ENVIRONMENT_ENABLED_STATE_CHANGED':
                return { jsx: this.getBuildEnvironmentEnabledStateChangedDisplayComponent(), showDetailsButton: false };

            case 'TRIGGER_ENABLED_STATE_CHANGED':
                return { jsx: this.getTriggerEnabledStateChangedDisplayComponent(), showDetailsButton: false };

            // This one returns jsx and showDetailsButton object by itself
            case 'CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED':
                return this.getCustomTriggerDefinitionChangedDisplayComponent();

            case 'CUSTOM_TRIGGER_DISPLAY_NAME_CHANGED':
                return { jsx: this.getCustomTriggerDisplayNameChangedDisplayComponent(), showDetailsButton: false };

            case 'CUSTOM_TRIGGER_POST_STATE_CHANGED':
                return { jsx: this.getCustomTriggerPostStateChangedDisplayComponent(), showDetailsButton: false };

            case 'CUSTOM_TRIGGER_CREATED':
                return { jsx: this.getCustomTriggerCreatedDisplayComponent(), showDetailsButton: false };

            case 'CUSTOM_TRIGGER_DELETED':
                return { jsx: this.getCustomTriggerDeletedDisplayComponent(), showDetailsButton: false };

            case 'CUSTOM_TRIGGER_DUPLICATED':
                return { jsx: this.getCustomTriggerDuplicatedDisplayComponent(), showDetailsButton: false };

            case WorkflowActivityEvents.CREATED_BOT_FROM_TEMPLATE:
                return { jsx: this.getCreatedBotFromTemplateDisplayComponent(), showDetailsButton: false };

            case WorkflowActivityEvents.MODULE_CREATED_FROM_MARKETPLACE:
                return { jsx: this.getCreatedModuleFromMarketplaceDisplayComponent(), showDetailsButton: false };

            case 'GROUP_SHOULD_GATHER_UPDATES':
                return { jsx: this.getGroupShouldGatherUpdatesDisplayComponent(), showDetailsButton: false };

            case 'GROUP_IS_DASHBOARD_HIDDEN_TOGGLED':
                return { jsx: this.getGroupIdDashboardHiddenToggledDisplayComponent(), showDetailsButton: false };

            case 'CUSTOM_TRIGGER_HIDDEN_STATE_CHANGE':
                return { jsx: this.getCustomTriggerHiddenStateChangeDisplayComponent(), showDetailsButton: false };

            case 'GROUP_DATA_SOURCE_CHANGED_TO_MANUAL':
                return { jsx: this.getDataSourceChangedToManualDisplayComponent(), showDetailsButton: false };

            case 'GROUP_DATA_SOURCE_CHANGED_TO_FORMS':
                return { jsx: this.getDataSourceChangedToFormsDisplayComponent(), showDetailsButton: false };

            case 'GROUP_DATA_SOURCE_REMOVED':
                return { jsx: this.getDataSourceRemovedDisplayComponent(), showDetailsButton: false };

            case WorkflowActivityEvents.GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_MAPPING_CONFIGURATION_CHANGED:
                return {
                    jsx: this.getGroupInputSourceFromAnotherModuleMappingConfigurationChanged(),
                    showDetailsButton: false,
                };

            case WorkflowActivityEvents.GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_CONTRACT_CHANGED:
                return { jsx: this.getGroupInputSourceFromAnotherModuleContractChanged(), showDetailsButton: false };

            case WorkflowActivityEvents.GROUP_INPUT_SOURCE_CHANGED_TO_FROM_ANOTHER_MODULE:
                return { jsx: this.getGroupInputSourceChangeToFromAnotherModule(), showDetailsButton: false };

            case 'DATA_SOURCE_CHANGED_TO_SCHEDULED':
                return { jsx: this.getDataSourceChangedToScheduledDisplayComponent(), showDetailsButton: false };

            case 'DATA_SOURCE_CHANGED_TO_INTEGRATION':
                return { jsx: this.getDataSourceChangedToIntegrationDisplayComponent(), showDetailsButton: false };

            case 'INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED':
                return {
                    jsx: this.getIntegrationDataSourceConfigurationChangedDisplayComponent(),
                    showDetailsButton: false,
                };

            case 'FIELD_DEFINITION_CREATED':
                return {
                    jsx: this.getFieldDefinitionCreatedDisplayComponent(this.getFieldType()),
                    showDetailsButton: false,
                };

            case 'FIELD_DEFINITION_UPDATED':
                return {
                    jsx: this.getFieldDefinitionUpdatedDisplayComponent(this.getFieldType()),
                    showDetailsButton: false,
                };

            case 'FIELD_DEFINITION_DELETED':
                return {
                    jsx: this.getFieldDefinitionDeletedDisplayComponent(this.getFieldType()),
                    showDetailsButton: false,
                };

            case 'LIVE_REPORT_FIELD_IS_HIDDEN_UPDATED':
                return {
                    jsx: this.getLiveReportFieldIsHiddenUpdatedDisplayComponent(this.getFieldType()),
                    showDetailsButton: false,
                };

            case 'LIVE_REPORT_FIELD_REORDER':
                return {
                    jsx: this.getLiveReportFieldReorderDisplayComponent(this.getFieldType()),
                    showDetailsButton: false,
                };

            case 'FORM_CREATED':
                return { jsx: this.getFormCreatedDisplayComponent(), showDetailsButton: false };

            case 'FORM_UPDATED':
                return { jsx: this.getFormUpdatedDisplayComponent(), showDetailsButton: false };

            case 'FORM_DELETED':
                return { jsx: this.getFormDeletedDisplayComponent(), showDetailsButton: false };

            case 'STATES_CHANGED':
                return { jsx: this.getStatesChangedDisplayComponent(), showDetailsButton: false };

            case 'WORKER_CUSTOM_TRIGGER_GRAPH_CHANGED':
                return { jsx: this.getWorkerCustomTriggerGraphChangedDisplayComponent(), showDetailsButton: false };

            case 'TRAINED_KEYWORD_ADDED':
                return { jsx: this.getTrainedKeywordAddedDisplayComponent(), showDetailsButton: false };

            case 'TRAINED_KEYWORD_DELETED':
                return { jsx: this.getTrainedKeywordDeletedDisplayComponent(), showDetailsButton: false };

            case 'SCHEDULED_WORKER_CONFIGURATION_CHANGED':
                return { jsx: 'Scheduled worker configuration changed', showDetailsButton: false };

            case 'FIELD_GRAPH_UPDATE':
                return { jsx: 'Field Configuration Change', showDetailsButton: false };

            case 'NEW_INITIATIVE':
                return { jsx: this.getNewInitiativeDisplayComponent(), showDetailsButton: false };

            case 'NEW_INITIATIVE_SYNCED':
                return { jsx: 'New track was imported from Data Source', showDetailsButton: false };

            case 'IMPORTED_INITIATIVE':
                return { jsx: 'A track was imported manually from Data Source', showDetailsButton: false };

            case 'MODULE_REVERT':
                return { jsx: this.getRevertToVersionComponent(), showDetailsButton: false };

            case 'ITEM_INTERFACE_CREATED': {
                return {
                    jsx: (
                        <span>
                            Created item interface{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }

            case 'ITEM_INTERFACE_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated item interface{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }

            case 'ITEM_INTERFACE_DELETED': {
                return {
                    jsx: (
                        <span>
                            Deleted item interface{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }

            case 'ITEM_INTERFACE_WIDGET_CREATED': {
                return {
                    jsx: (
                        <span>
                            Created item interface widget{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceWidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_WIDGET_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated item interface widget{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceWidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_WIDGET_DELETED': {
                return {
                    jsx: (
                        <span>
                            Deleted item interface widget{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceWidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_SET_AS_DEFAULT': {
                return {
                    jsx: (
                        <span>
                            Set item interface as default{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_REMOVED_AS_DEFAULT': {
                return {
                    jsx: (
                        <span>
                            Removed item interface as default{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_LOGO_ADDED': {
                return {
                    jsx: (
                        <span>
                            Added logo to item interface{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_INTERFACE_LOGO_REMOVED': {
                return {
                    jsx: (
                        <span>
                            Removed logo from item interface{' '}
                            <strong>{this.props.activity.metadata.itemInterfaceDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'SOLUTION_SITE_PAGE_WIDGETS_SWAPPED':
            case 'ITEM_INTERFACE_WIDGETS_SWAPPED': {
                return {
                    jsx: (
                        <span>
                            Swapped positions of <strong>{this.props.activity.metadata.firstWidgetDisplayName}</strong>{' '}
                            and <strong>{this.props.activity.metadata.secondWidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }

            case 'GROUP_DESCRIPTION_UPDATED': {
                return {
                    jsx: <span>Module description has been changed</span>,
                    showDetailsButton: false,
                };
            }

            case 'SOLUTION_SITE_PAGE_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated workspace app page{' '}
                            <strong>{this.props.activity.metadata.solutionSitePageDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: true,
                };
            }
            case 'SOLUTION_SITE_PAGE_WIDGET_CREATED': {
                return {
                    jsx: (
                        <span>
                            Created workspace app page widget{' '}
                            <strong>{this.props.activity.metadata.WidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'SOLUTION_SITE_PAGE_WIDGET_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated workspace app page widget{' '}
                            <strong>{this.props.activity.metadata.WidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'SOLUTION_SITE_PAGE_WIDGET_DELETED': {
                return {
                    jsx: (
                        <span>
                            Deleted workspace app page widget{' '}
                            <strong>{this.props.activity.metadata.WidgetDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'ITEM_DETAILS_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated <strong>{this.props.activity.metadata.newName || 'item'}</strong> details
                        </span>
                    ),
                    showDetailsButton: true,
                };
            }
            case 'ITEM_DETAILS_CREATED': {
                return {
                    jsx: (
                        <span>
                            Created <strong>{this.props.activity.metadata.name || 'item'}</strong> details
                        </span>
                    ),
                    showDetailsButton: true,
                };
            }
            case 'MODULE_CREATED_FROM_MODULE_DUPLICATED': {
                return {
                    jsx: (
                        <span>
                            Duplicated module <strong>{this.props.activity.metadata.oldModuleName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_CREATED': {
                return {
                    jsx: (
                        <span>
                            Created process map <strong>{this.props.activity.metadata.processMapperDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_UPDATED': {
                return {
                    jsx: (
                        <span>
                            Updated process map <strong>{this.props.activity.metadata.processMapperDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_DELETED': {
                return {
                    jsx: (
                        <span>
                            Deleted process map <strong>{this.props.activity.metadata.processMapperDisplayName}</strong>
                        </span>
                    ),
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_NODE_CREATED': {
                return {
                    jsx: <span>Created process map Node</span>,
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_NODE_UPDATED': {
                return {
                    jsx: <span>Updated process map Node</span>,
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_NODE_DELETED': {
                return {
                    jsx: <span>Deleted process map Node</span>,
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_EDGE_CREATED': {
                return {
                    jsx: <span>Created process map Edge</span>,
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_EDGE_UPDATED': {
                return {
                    jsx: <span>Updated process map Edge</span>,
                    showDetailsButton: false,
                };
            }
            case 'PROCESS_MAPPER_EDGE_DELETED': {
                return {
                    jsx: <span>Deleted process map Edge</span>,
                    showDetailsButton: false,
                };
            }
            default:
                return { jsx: '', showDetailsButton: false };
        }
    }

    getCustomTriggerHiddenStateChangeDisplayComponent() {
        if (this.props.activity.metadata.triggerDisplayName) {
            if (this.props.activity.metadata.isHidden) {
                return (
                    <span>
                        Enabled{' '}
                        {this.props.isNotInner &&
                            this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName)}
                    </span>
                );
            }
            return (
                <span>
                    Disabled{' '}
                    {this.props.isNotInner &&
                        this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName)}
                </span>
            );
        }
        return <div>Error trying to display audit log.</div>;
    }

    getDataSourceChangedToManualDisplayComponent() {
        return (
            <span>
                Changed Intake Source to <strong>Manual</strong>
            </span>
        );
    }

    getDataSourceChangedToFormsDisplayComponent() {
        return (
            <span>
                Changed Intake Source to <strong>Forms</strong>
            </span>
        );
    }

    getDataSourceChangedToIntegrationDisplayComponent() {
        return (
            <span>
                Changed Intake Source to <strong>Data Source</strong>
            </span>
        );
    }

    getFieldDefinitionCreatedDisplayComponent(fieldType) {
        return (
            <span>
                Created {fieldType}{' '}
                <strong>{this.props.isNotInner && this.props.activity.metadata.fieldDefinitionName}</strong>
            </span>
        );
    }

    getFieldDefinitionUpdatedDisplayComponent(fieldType) {
        return (
            <span>
                Updated {fieldType}{' '}
                <strong>{this.props.isNotInner && this.props.activity.metadata.fieldDefinitionName}</strong>
            </span>
        );
    }

    getFieldDefinitionDeletedDisplayComponent(fieldType) {
        return (
            <span>
                Deleted {fieldType} <strong> {this.props.activity.metadata.fieldDefinitionName} </strong>
            </span>
        );
    }

    getLiveReportFieldIsHiddenUpdatedDisplayComponent(fieldType) {
        return (
            <span>
                Changed {fieldType} <strong>{this.props.activity.metadata.fieldDefinitionName}</strong> to{' '}
                <strong>{this.props.activity.metadata.isHidden ? 'Hidden' : 'Shown'}</strong>
            </span>
        );
    }

    getLiveReportFieldReorderDisplayComponent(fieldType) {
        return (
            <span>
                Reordered {fieldType}
                <strong>{this.props.activity.metadata.fieldDefinitionName}</strong> to be right of{' '}
                <strong>{this.props.activity.metadata?.rightOfFieldDefinitionName || 'Owner'}</strong>
            </span>
        );
    }

    getFormCreatedDisplayComponent() {
        return (
            <span>
                Created form <strong>{this.props.isNotInner && this.props.activity.metadata.formName}</strong>
            </span>
        );
    }

    getFormUpdatedDisplayComponent() {
        return (
            <span>
                Updated form <strong>{this.props.isNotInner && this.props.activity.metadata.formName}</strong>
            </span>
        );
    }

    getFormDeletedDisplayComponent() {
        return (
            <span>
                Deleted form <strong>{this.props.activity.metadata.formName}</strong>
            </span>
        );
    }

    getStatesChangedDisplayComponent() {
        return <span>Updated the statuses configuration of the worker</span>;
    }

    getWorkerCustomTriggerGraphChangedDisplayComponent() {
        return <span>Updated the structure of the workflow</span>;
    }

    getTrainedKeywordAddedDisplayComponent() {
        return (
            <span>
                Added a trained keyword - <strong>{this.props.activity.metadata.keyword}</strong> (
                {this.props.activity.metadata.sentiment === 'POSITIVE' ? 'Positive' : 'Negative'})
            </span>
        );
    }

    getTrainedKeywordDeletedDisplayComponent() {
        return (
            <span>
                Deleted a trained keyword - <strong>{this.props.activity.metadata.keyword}</strong> (
                {this.props.activity.metadata.sentiment === 'POSITIVE' ? 'Positive' : 'Negative'})
            </span>
        );
    }

    getNewInitiativeDisplayComponent() {
        return (
            <span>
                New track created - <strong>{this.props.activity.metadata.initiativeTitle}</strong>
            </span>
        );
    }

    getRevertToVersionComponent() {
        return (
            <span>
                Module was restored back to{' '}
                <strong>
                    Version {this.props.activity.reference1.sequentialIdentifier}
                    {this.props.activity.reference1.subSequentialIdentifier > 0 &&
                        `.${this.props.activity.reference1.subSequentialIdentifier}`}
                </strong>
            </span>
        );
    }

    getDataSourceChangedToScheduledDisplayComponent() {
        return (
            <span>
                Changed Intake Source to <strong>Scheduled Worker</strong>
            </span>
        );
    }

    getDataSourceRemovedDisplayComponent() {
        return (
            <span>
                Removed the{' '}
                <strong>{this.getDataSourceTypeDisplayName(this.props.activity.metadata.oldDataSourceType)}</strong>{' '}
                Intake Source
            </span>
        );
    }

    getGroupInputSourceChangeToFromAnotherModule() {
        return (
            <span>
                Changed Intake Source to <strong>From Another Module</strong>
            </span>
        );
    }

    getGroupInputSourceFromAnotherModuleContractChanged() {
        return <span>Changed contract</span>;
    }

    getGroupInputSourceFromAnotherModuleMappingConfigurationChanged() {
        return <span>Changed contract mapping</span>;
    }

    getDataSourceTypeDisplayName(dataSourceType: DataSourceType) {
        switch (dataSourceType) {
            case DataSourceType.MANUAL:
                return 'Manual List';
            case DataSourceType.INTEGRATION:
                return 'Data Source';
        }
    }

    getGroupIdDashboardHiddenToggledDisplayComponent() {
        if (!this.props.activity.metadata.dashboardHidden) {
            return <span>Enabled Business Report</span>;
        }
        return <span>Disabled Business Report</span>;
    }

    getGroupShouldGatherUpdatesDisplayComponent() {
        if (this.props.activity.metadata.shouldSendGatherUpdates) {
            return <span>enabled Auto Check-Ins</span>;
        }
        return <span>Disabled Auto Check-Ins</span>;
    }

    getWorkflowVersionShouldGatherUpdatesDisplayComponent() {
        if (this.props.activity.metadata.shouldSendGatherUpdates) {
            return <span>enabled Auto Check-Ins</span>;
        }
        return <span>Disabled Auto Check-Ins</span>;
    }

    getCustomTriggerDuplicatedDisplayComponent() {
        return (
            <span>
                Duplicated{' '}
                {this.props.isNotInner &&
                    this.renderActivityParameter(this.props.activity.metadata.sourceCustomDisplayName)}
            </span>
        );
    }

    getCreatedBotFromTemplateDisplayComponent() {
        return <span>Created module from template</span>;
    }

    getCreatedModuleFromMarketplaceDisplayComponent() {
        const metadata = this.props.activity.metadata;
        const marketplaceUrl = this.props.consts.getMarketplaceUrl();
        return (
            <span>
                Created module from library template "
                <a target="_blank" href={`${marketplaceUrl}template/${metadata.templateName}`}>
                    {metadata.templateTitle}
                </a>
                " Version ({metadata.templateVersion})
            </span>
        );
    }

    getCustomTriggerDeletedDisplayComponent() {
        return (
            <span>
                Deleted {this.renderActivityParameter(this.props.activity.metadata.deletedCustomTriggerDisplayText)}
            </span>
        );
    }

    getCustomTriggerCreatedDisplayComponent() {
        return (
            <span>
                Created{' '}
                {this.props.isNotInner &&
                    this.renderActivityParameter(this.props.activity.metadata.createdCustomDisplayName)}
            </span>
        );
    }

    getCustomTriggerPostStateChangedDisplayComponent() {
        if (
            this.props.activity.metadata.updatedState &&
            (!this.props.activity.metadata.oldState ||
                this.props.activity.metadata.oldState?.stateText !==
                    this.props.activity.metadata.updatedState.stateText)
        ) {
            return (
                <span>
                    Updated {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName || 'Trigger')}
                    &nbsp;state to
                    <strong style={{ color: this.props.activity.metadata.updatedState.stateColor }}>
                        {' '}
                        {this.props.activity.metadata.updatedState.stateText}
                    </strong>
                </span>
            );
        } else if (!Utils.isNullOrEmpty(this.props.activity.metadata.updatedUpdateText)) {
            // replace curly braces with semicolon because it looks weird ux wise
            return (
                <span>
                    Updated {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName || 'Trigger')}
                    &nbsp;state update text to{' '}
                    {this.renderActivityParameter(
                        this.renderUpdateText(this.props.activity.metadata.updatedUpdateText),
                    )}
                </span>
            );
        } else if (
            !Utils.isNullOrEmpty(this.props.activity.metadata.updatedFieldsToUpdate) &&
            this.props.activity.metadata.updatedFieldsToUpdate
        ) {
            return (
                <span>
                    Updated {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName || 'Trigger')}
                    &nbsp;fields updated.
                </span>
            );
        }
        return (
            <span>
                Updated {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName || 'Trigger')}
                state update text to an empty string
            </span>
        );
    }

    getCustomTriggerDisplayNameChangedDisplayComponent() {
        return (
            <span>
                Updated {this.renderActivityParameter(this.props.activity.metadata.oldTriggerDisplayName)}'s name to{' '}
                {this.renderActivityParameter(this.props.activity.metadata.updatedTriggerDisplayName)}
            </span>
        );
    }

    getCustomTriggerDefinitionChangedDisplayComponent() {
        // delete the timestamp property because it updated on every change and i dont want the generic compare to recognize it as a change.
        delete this.props.activity.metadata.oldTrigger.queryDefinition.query.compiledQueryTimestamp;
        delete this.props.activity.metadata.updatedTrigger.queryDefinition.query.compiledQueryTimestamp;
        const changesRows = this.filterChangeRows(this.getDiffObjByType());

        if (
            this.props.activity.metadata.oldTrigger.isScheduled !==
                this.props.activity.metadata.updatedTrigger.isScheduled &&
            this.props.activity.metadata.updatedTrigger.isScheduled === true
        ) {
            return {
                jsx: (
                    <span>
                        Changed {this.renderActivityParameter(this.props.activity.metadata.updatedTrigger.displayName)}
                        to be{' '}
                        {this.props.activity.metadata.updatedTrigger.recurrencePeriodType &&
                            this.renderActivityParameter(
                                this.props.activity.metadata.updatedTrigger.recurrencePeriodType,
                            )}
                        {this.renderActivityParameter('scheduled')}
                    </span>
                ),
                showDetailsButton: false,
            };
        } else if (
            this.props.activity.metadata.updatedTrigger.hasOwnProperty('monitorFieldDefinitions') &&
            !this.props.activity.metadata.oldTrigger.hasOwnProperty('monitorFieldDefinitions')
        ) {
            return {
                jsx: (
                    <span>
                        Updated {this.renderActivityParameter(this.props.activity.metadata.updatedTrigger.displayName)}{' '}
                        to monitor when field value changes.
                    </span>
                ),
                showDetailsButton: false,
            };
        } else if (
            this.props.activity.metadata.oldTrigger.monitorInnerItems !==
            this.props.activity.metadata.updatedTrigger.monitorInnerItems
        ) {
            const submessage = this.props.activity.metadata.updatedTrigger.monitorInnerItems ? 'run' : 'not run';
            return {
                jsx: (
                    <span>
                        Set {this.renderActivityParameter(this.props.activity.metadata.updatedTrigger.displayName)} to{' '}
                        <b>{submessage} </b> on action items
                    </span>
                ),
                showDetailsButton: false,
            };
        } else if (
            !this.compare(
                this.props.activity.metadata.updatedTrigger.queryDefinition,
                this.props.activity.metadata.oldTrigger.queryDefinition,
            ) &&
            changesRows.length
        ) {
            return {
                jsx: (
                    <span>
                        <span>
                            Updated{' '}
                            {this.props.isNotInner &&
                                this.renderActivityParameter(this.props.activity.metadata.updatedTrigger.displayName)}
                        </span>
                    </span>
                ),
                showDetailsButton: true,
            };
        } else if (
            !this.compare(
                this.props.activity.metadata.updatedTrigger.queryDefinition,
                this.props.activity.metadata.oldTrigger.queryDefinition,
            ) &&
            !changesRows.length
        ) {
            return {
                jsx: (
                    <span>
                        <span>
                            Updated{' '}
                            {this.props.isNotInner &&
                                this.renderActivityParameter(this.props.activity.metadata.updatedTrigger.displayName)}
                        </span>
                    </span>
                ),
                showDetailsButton: false,
            };
        }
        return {
            jsx: '',
            showDetailsButton: false,
        };
    }

    getIntegrationDataSourceConfigurationChangedDisplayComponent() {
        if (!this.compare(this.props.activity.metadata.oldSyncConfig, this.props.activity.metadata.newSyncConfig)) {
            return <span>Updated Data Source Intake Source configuration</span>;
        }
        return '';
    }

    getTriggerEnabledStateChangedDisplayComponent() {
        if (!this.props.activity.metadata.triggerDisabled) {
            return (
                <span>Turned {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName)} on</span>
            );
        }
        return <span>Turned {this.renderActivityParameter(this.props.activity.metadata.triggerDisplayName)} off</span>;
    }

    getWorkerEnabledStateChangedDisplayComponent() {
        if (this.props.activity.metadata.workerEnabled) {
            return <span>Turned the production environment on</span>;
        }
        return <span>Turned the production environment off</span>;
    }

    getBuildEnvironmentEnabledStateChangedDisplayComponent() {
        if (this.props.activity.metadata.buildEnvironmentEnabled) {
            return <span>Turned the build environment on</span>;
        }
        return <span>Turned the build environment off</span>;
    }

    /**
     * Compares given two jsons and returns whether they are equal (deep comparison).
     */
    compare(obj1, obj2) {
        // check for obj2 overlapping props
        if (
            !obj1 ||
            !obj2 ||
            Object.keys(obj1).length !== Object.keys(obj2).length ||
            !Utils.allMatch(Object.keys(obj2), (key) => obj1.hasOwnProperty(key))
        ) {
            return false;
        }

        // check every key for being same
        return Utils.allMatch(Object.keys(obj2), (key) => {
            // if object
            if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                // recursively check
                return this.compare(obj1[key], obj2[key]);
            }
            // do the normal compare
            return obj1[key] === obj2[key];
        });
    }

    /**
     * Compares given two jsons and returns map of the new value and old value
     */
    diffJson(result, obj1, obj2, key?) {
        for (const i in obj2) {
            let token = i;
            if (key) {
                token = `${key}.${i}`;
            }

            if (typeof obj2[i] === 'object' && obj1?.[i]) {
                this.diffJson(result, obj1[i], obj2[i], token);
            } else if (obj2?.[i] && Array.isArray(obj2[i]) && obj1?.[i] && Array.isArray(obj1[i])) {
                // run through it
                for (let j = 0; j < obj2[i].length; j++) {
                    if (j < obj1[i].length) {
                        this.diffJson(result, obj1[i][j], obj2[i][j], `${token}.${j}`);
                    } else {
                        // means it's a new item in array
                        this.diffJson(result, null, '*NEW*', `${token}.${j}`);
                    }
                }
            } else {
                if (!obj1?.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
                    // in case one of them is null, it might be that we didn't get to a value, cause the diff is higher up
                    // so we ignore the value
                    const value1 = !obj1?.[i] || Array.isArray(obj1[i]) || typeof obj1[i] === 'object' ? null : obj1[i];
                    const value2 = !obj2?.[i] || Array.isArray(obj2[i]) || typeof obj2[i] === 'object' ? null : obj2[i];

                    if (value1 || value2) {
                        // calc parent
                        const pathParts = token.split('.');

                        // save the result
                        result[token] = {
                            path: token,
                            key: i,
                            parent: pathParts.length > 1 ? pathParts[pathParts.length - 2] : null,
                            value1: value1 ? value1.toString() : null,
                            value2: value2 ? value2.toString() : null,
                        };
                    }
                }
            }
        }
    }

    /**
     * Renders the parameter display for an activity.
     */
    renderActivityParameter(activityParameterString) {
        return <strong>{activityParameterString}</strong>;
    }

    /**
     * Renders the update text display.
     */
    renderUpdateText(updateText) {
        // Split the string, return either {{x}} or any thing else, if we recognize a {{x}}. (So we can know which split needs to be a tag and which doesnt.)
        const splits = updateText.split(/({{.*?}})|.*?/g);

        // Filter out the undefineds
        const parts = splits
            .filter((s) => s)
            .map((str, index) => {
                const indexOfEndTag = str.length - 2;
                // If this should be a tag (starts with {{ and ends with }} )
                if (str.indexOf('{{') === 0 && str.indexOf('}}') === indexOfEndTag) {
                    // Replace with span
                    const text = str.slice(2, indexOfEndTag);
                    return (
                        <span key={index} className="common-tag-full margin-left-xs common-size-xxxxs">
                            {text}
                        </span>
                    );
                }
                // otherwise just return a normal string
                return str;
            });

        return parts;
    }
}
