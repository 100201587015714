<div ng-controller="QuestionActivityController">
    <div ng-show="inquiry && inquiry.typeData && inquiry.title">
        You have a pending question
        <span ng-if="inquiry.type !== 'CHECK_PROBLEM'" class="common-bold">on {{ inquiry.title }}</span>
        <span ng-if="inquiry.type === 'CHECK_PROBLEM'">
            about the
            <a
                ng-if="inquiry.type === 'CHECK_PROBLEM'"
                ui-sref="product.analyze.problems.details({projectId: pm.project.id, problemId: inquiry.reference.id})"
                target="_blank"
            >
                {{ inquiry.reference.text }}
            </a>
        </span>
    </div>
    <div ng-hide="inquiry && inquiry.typeData && inquiry.title">{{ title }}</div>
</div>
