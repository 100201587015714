import React from 'react';
import styled, { css } from 'styled-components';

import ItemDetailsIcon from './ItemDetailsIcon';
import errorWrapperIconUrl from '../../../../../images/icons/module-editor-item-details-icon-wrapper-error.svg?url';
import wrapperSelectedIconUrl from '../../../../../images/icons/module-editor-item-details-icon-wrapper-selected.svg?url';
import wrapperIconUrl from '../../../../../images/icons/module-editor-item-details-icon-wrapper.svg?url';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const IconWrapper = styled.div<{ size: number; hasValidationError?: boolean; selected?: boolean }>`
    ${({ size }) => css`
        height: ${size}px;
        width: ${size}px;
    `}
    padding: 18px;

    background-image: url(${({ hasValidationError, selected }) =>
        hasValidationError ? errorWrapperIconUrl : selected ? wrapperSelectedIconUrl : wrapperIconUrl});
    background-size: 100% 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
`;

const DEFAULT_WRAPPER_SIZE = 92;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    iconWrapperSize?: number;
    hasValidationError?: boolean;
    selected?: boolean;
}

const HexagonWrappedItemDetailsModuleEditorIcon: React.FC<Props> = ({
    iconWrapperSize = DEFAULT_WRAPPER_SIZE,
    workflowVersionId,
    targetId,
    hasValidationError,
    selected,
}) => {
    // Modifier to adjust the inner icon sizes, was based on an original size of 92 so 92/92 = 1
    // For example, 46/92 would be 0.5, so the inner icons would be half their original size
    const iconSizeModifier = iconWrapperSize / DEFAULT_WRAPPER_SIZE;

    return (
        <IconWrapper
            size={iconWrapperSize}
            hasValidationError={hasValidationError}
            selected={selected}
            data-automation="hexagon-wrapped-item-details-module-editor-icon"
        >
            <ItemDetailsIcon
                workflowVersionId={workflowVersionId}
                sizeModifier={iconSizeModifier}
                targetId={targetId}
            />
        </IconWrapper>
    );
};

export default HexagonWrappedItemDetailsModuleEditorIcon;
