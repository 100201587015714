import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class RandomFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.RANDOM;
    override readonly sign = 'Random';
    override readonly displayName = 'Random';
    override readonly description = 'Use the Random formula to get a random number between a range.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Minimum number',
            explanation: 'inclusive',
            dataType: FieldType.Number,
            defaultValue: 1,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Maximum number',
            explanation: 'exclusive',
            dataType: FieldType.Number,
        },
    ];
}
