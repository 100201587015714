import template from './workflowFolderSelector.template.html?angularjs';

/**
 * Component to select workflow folder from project.
 */
export default angular.module('tonkean.app').component('tnkWorkflowFolderSelector', {
    bindings: {
        groupId: '<', // If set will try to select the workflow folder of this group on load
        isRequired: '<',
        hideOptionsIds: '<', // An array of workflow folder ids to hide in the selector
        onFolderSelected: '&',
        selectedWorkflowFolder: '<',
        onlyOfMaker: '<',
    },
    template,
    controller: 'WorkflowFolderSelectorCtrl',
});
