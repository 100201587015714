import React, { useEffect, useState } from 'react';
import Utils from '@tonkean/utils';
import TrackFieldUserIcon from './TrackFieldUserIcon';
import { Popover } from '@tonkean/infrastructure';
import { angularToReact } from '@tonkean/angular-components';
import { useAngularService } from 'angulareact';

const AddUserPopover = angularToReact('tnk-add-user-popover', { onSave: ['data'] });

/**
 * A components that renders the track list user field.
 */
const TrackFieldUser = ({
    field,
    track,
    editorId,
    fieldDef,
    updateField,
    personCache,
    viewOnlyMode,
    anonymous,
    isCreatorOnlyValid,
    setParentClickEvent = () => {},
}) => {
    const [showPopover, setShowPopover] = useState(false);
    const [users, setUsers] = useState([]);

    const $timeout = useAngularService('$timeout');

    setParentClickEvent(() => setShowPopover(true));

    /**
     * Will fetch users from person cache when field.value changes
     */
    useEffect(() => {
        if (!field.value) {
            setUsers([]);
            return;
        }

        let counter = 0;
        const generateCustomEmail = (email) => ({
            customEmail: false,
            isValid: true,
            id: ++counter + email,
            initials: email[0],
            name: email,
            email,
        });

        const emails = field.value.split(',').map((v) => v.trim().toLowerCase());

        if (anonymous) {
            setUsers(() => emails.map(generateCustomEmail));
        } else {
            const usersReturned = [];

            // getEntitiesByEmailDataLoader might return null if the email is not a registered person,
            // so I'm using a separate getEntitiesByEmailDataLoader on each email address. It uses
            // dataloader so it will send a single request.
            Promise.all(
                emails.map((email) =>
                    personCache
                        .getEntitiesByEmailDataLoader([email])
                        .then((u) => usersReturned.push(u[0] || generateCustomEmail(email))),
                ),
            ).then(() => setUsers(() => usersReturned));
        }
    }, [anonymous, field.value, personCache]);

    const className = 'user-field relative common-size-xxxxs flex';
    const setUserContainerId = `${Utils.getReactAngularContainerId('set-user', (track || { id: 123 }).id, editorId)}-${
        (fieldDef || { id: 123 }).id
    }`;

    /**
     * If there are more than 4 users, it will show in the last avatar the amount of users past 3,
     * so if there are 8 users, it will show +5.
     */
    const remainingUsersAmount = users.length - 3;
    const lastUser = {
        name: `+${remainingUsersAmount}`,
        initials: `+${remainingUsersAmount}`,
        email: `+${remainingUsersAmount}`,
    };

    return (
        <Popover
            content={
                <AddUserPopover
                    selectedPeople={users}
                    maxTags={fieldDef.isMultiValueField ? undefined : 1}
                    onSave={(newUsers) => $timeout(() => updateField(newUsers))}
                    onClose={() => setShowPopover(false)}
                />
            }
            show={showPopover && !viewOnlyMode}
            onClose={() => setShowPopover(false)}
            placement="left"
            overflowVisible
        >
            <div className={className}>
                <span id={setUserContainerId} className="absolute" />
                {users.length ? (
                    <React.Fragment>
                        {users
                            .slice(0, users.length > 4 ? 3 : 4)
                            .map(
                                (user) =>
                                    user && (
                                        <TrackFieldUserIcon
                                            key={user.id}
                                            user={user}
                                            toggleSetUserPopover={() => setShowPopover(true)}
                                            isCreatorOnlyValid={isCreatorOnlyValid}
                                        />
                                    ),
                            )}
                        {remainingUsersAmount > 1 && (
                            <TrackFieldUserIcon
                                user={lastUser}
                                toggleSetUserPopover={() => setShowPopover(true)}
                                isCreatorOnlyValid={isCreatorOnlyValid}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <TrackFieldUserIcon
                        user={null}
                        toggleSetUserPopover={() => setShowPopover(true)}
                        isCreatorOnlyValid={isCreatorOnlyValid}
                    />
                )}
            </div>
        </Popover>
    );
};

export default TrackFieldUser;
