import { ReactComponent as EllipsisVIcon } from '../../../../images/icons/ellipsis-v.svg';
import React from 'react';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import { ReasonComponentFactory } from './reasonComponents/ReasonComponentFactory';
import BotHistoryItemActionsDropdown from './components/BotHistoryItemDropdown';
import BotHistoryItemActions from '../../../actions/BotHistoryItemActions';
import BotHistoryItemAnswer from './components/BotHistoryItemAnswer';
import { timeDiffShortDiffFilter } from '../../../services/filters/time-diff.filter';

export default class BotHistoryItem extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.listener = null;

        this.botHistoryItemStore = props.botHistoryItemStore;
        this.trackHelper = props.trackHelper;

        // Initialize the state of the track list item.
        this.state = this.botHistoryItemStore.getNewState(this.props.id);
    }

    /**
     * Happens once, when the component is mounted to the page.
     * Registers our onStoreUpdated function as a listener on the store.
     * Also saves the returned remove listener, so we can remove the listener we've just added.
     */
    componentDidMount() {
        this.listener = this.botHistoryItemStore.addListener(this.onStoreUpdated.bind(this));
    }

    /**
     * Happens once, when the component is unmounted from the page.
     * Removed the listener from the store, using our removeListener (if it was initialized).
     */
    componentWillUnmount() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    /**
     * Registered as a listener to the store. Triggered whenever the store emits an update.
     */
    onStoreUpdated(id) {
        // If we didn't get an id (means everyone should update) or if the id is ours - update the state (causes re-render).
        // Alternatively, if we are a linked item and the real track's id is the given id - we should also render.
        if (this.botHistoryItemStore.shouldItemUpdate(this.props.id, id)) {
            this.setState(this.botHistoryItemStore.getState(this.props.id));
        }
    }

    toggleActionsDropdown() {
        BotHistoryItemActions.toggleActionsDropdown(this.props.id, !this.state.isActionsDropdownOpen);
    }

    toggleTrackStatusSummaryPopover(isOpen, timeoutObj) {
        if (isOpen) {
            // Toggle the status popover in a delay, so we're sure the user wants to open it and doesn't simple moves his mouse.
            timeoutObj.id = setTimeout(() => {
                this.props.uiActions.toggleTrackStatusSummaryPopover(this.props.id);
                timeoutObj.popoverToggled = true;
            }, 200);
        } else {
            // If we have a timeout id, we can try and clear the timeout. We won't always get a timeoutObj with an id.
            if (timeoutObj && timeoutObj.id) {
                clearTimeout(timeoutObj.id);
            }

            if (!timeoutObj || !timeoutObj.id || timeoutObj.popoverToggled) {
                // If we were asked to close the popover and we either don't have a timeoutObj or the popover is already displayed - toggle it off.
                this.props.uiActions.toggleTrackStatusSummaryPopover(null);

                // Clear the flag if the object exists.
                if (timeoutObj && timeoutObj.id) {
                    timeoutObj.popoverToggled = false;
                }
            }
        }
    }

    /**
     * The main render function
     */
    render() {
        // Get fresh initiative from cache.
        const initiative = this.trackHelper.getInitiativeFromCache(this.state.initiativeId);
        // Give the person that was on the time of the event, not the current owner.
        const item = this.state.item;
        const person = item.person;
        const id = `bot-history-item-${this.props.id}-tnk-bot-history`;
        const statusPopoverTimeout = {};

        return (
            <div
                id={id}
                className="bot-history-item relative"
                onMouseLeave={this.toggleTrackStatusSummaryPopover.bind(this, false, statusPopoverTimeout)}
            >
                <div className="flex">
                    {/* Body */}
                    {ReasonComponentFactory.get(item.reason, {
                        item,
                        person,
                        initiative,
                        statusPopoverTimeout,
                        as: this.props.authenticationService,
                        goToTrack: this.props.uiActions.goToTrack,
                        toggleTrackStatusSummaryPopover: this.toggleTrackStatusSummaryPopover.bind(this),
                    })}

                    {/* Menu */}
                    <div className="flex flex-no-shrink">
                        <div className="margin-right">
                            <span className="tnk-tooltip mod-top common-color-grey common-size-xxxxs">
                                {timeDiffShortDiffFilter(item.created, true)}
                                <span className="tnk-tooltip-text">{new Date(item.created).toLocaleString()}</span>
                            </span>
                        </div>

                        {/* Avatar */}
                        <div className="tnk-tooltip mod-top avatar-main avatar-sm margin-right flex-no-shrink">
                            {person && person.name && <span className="tnk-tooltip-text">{person.name}</span>}
                            <TonkeanAvatar owner={person} />
                        </div>

                        {/* Wrapped in a div so the flex wont strech the div vertically, which will cause the dropdown to appear in a wrong place */}
                        <div>
                            <div className="dropdown open">
                                <div
                                    className="pointer dropdown-toggle svg-icon-smd"
                                    onClick={this.toggleActionsDropdown.bind(this)}
                                >
                                    <span className="tnk-icon">
                                        <EllipsisVIcon />
                                    </span>
                                </div>
                                <BotHistoryItemActionsDropdown
                                    isOpen={this.state.isActionsDropdownOpen}
                                    initiativeId={initiative.id}
                                    toggle={this.toggleActionsDropdown.bind(this)}
                                    goToTrack={this.props.uiActions.goToTrack}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <BotHistoryItemAnswer answers={item.answers} />
            </div>
        );
    }
}
