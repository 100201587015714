import { reactToAngular } from '@tonkean/angular-components';
import { FormulaBuilder } from '@tonkean/formula-builder';

angular
    .module('tonkean.app')
    .component(
        'tnkFormulaBuilder',
        reactToAngular(
            FormulaBuilder,
            [
                'projectId',
                'groupId',
                'exampleInitiativeId',
                'workflowVersionId',
                'evaluatedFormula',
                'formulaExpressionTree',
                'onFormulaChange',
                'emitValidationResult',
                'onInnerTrackAggregationSelected',
                'fieldDefinition',
                'onFormulaDataChange',
                'additionalTabs',
                'customTrigger',
                'projectIntegration',
            ],
            ['tonkeanService', 'customFieldsManager'],
        ),
    );
