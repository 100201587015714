import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SpringcmCustomActionsDefinitionCtrl(
    $scope,
    integrations,
    tonkeanService,
    customTriggerManager,
    utils,
    projectManager,
) {
    const ctrl = this;

    $scope.data = {
        previewEvaluationSource: ctrl.previewEvaluationSource,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
        fileEntityMetadata: {
            displayName: 'File',
            entity: 'File',
            pluralLabel: 'Files',
        },

        // This cache purpose is to save the xml fields values while the user is working on the XML.
        // If the user choose an xml and fill its values and right after he decide to
        // the change XML - we want to keep its values.
        // For example - the user filled <FirstName></FirstName> in the XML data field
        // and he set the value of FirstName to 'Joe'.
        // Then, the user changed the XML data to <Note></Note> and as a result all of the
        // previous fields are gone. If the user will decide to go back to <FirstName></FirstName>
        // we will automatically populate the FirstName field with the old value 'Joe'.
        documentFieldsCache: {},
        xmlParsingError: null,
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onNewDocumentNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.newDocumentNameExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFolderIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.folderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.documentNameExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.templateIdExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.destinationFolderIdExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderIdExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.dataXMLExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.documentFieldsCache = Object.assign(
            $scope.data.documentFieldsCache,
            $scope.data.definition.documentFields,
        );
        $scope.data.definition.dataXMLExpression = { originalExpression, evaluatedExpression };

        populateDocumentFieldsFromXML(evaluatedExpression);
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentFieldValueChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        fieldName,
    ) {
        $scope.data.definition.documentFields[fieldName] = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * This function responsibility is to check whether the document fields are empty.
     * Object.keys is not accessible from the angular.js context.
     */
    $scope.areDocumentFieldsEmpty = function () {
        return Object.keys($scope.data.definition?.documentFields || {}).length === 0;
    };

    /**
     * This function responsibility is to populate the document fields by the given XML data.
     */
    async function populateDocumentFieldsFromXML(xmlData) {
        try {
            $scope.data.xmlParsingError = null;

            // Evaluate the given XML.
            const evaluatedXmlString = await evaluateXmlData(xmlData);

            // If the evaluated value is empty, we reset the fields.
            if (utils.isNullOrEmpty(evaluatedXmlString)) {
                $scope.data.definition.documentFields = {};
                return;
            }

            // Trying to parse the given string to XML.
            const domParser = new DOMParser();
            const xmlObject = domParser.parseFromString(evaluatedXmlString, 'text/xml');

            // parseFromString function doesn't throw an error, if the XML parsing didn't worked it will
            // contain parsererror element. Thus, we want to display an error that the xml is not valid and exist.
            if (xmlObject.querySelectorAll('parsererror').length !== 0) {
                // Display an error to the user.
                $scope.data.xmlParsingError = "Couldn't populate fields. XML is not valid.";
                return;
            } else {
                // Every time we populate the fields we have to clean the existing fields. Otherwise, when the
                // user change the XML value - the old fields will be populated in addition to the new ones.
                $scope.data.definition.documentFields = {};
            }

            // Returns all of the XML elements.
            const xmlElements = xmlObject.querySelectorAll('*');

            for (const element of xmlElements) {
                // We don't want to display the root element of the XML.
                if (element.nodeName === 'TemplateFieldData') {
                    continue;
                }
                // Take the value from the cache if exists, otherwise initial it with an empty expression.
                $scope.data.definition.documentFields[element.nodeName] = $scope.data.documentFieldsCache[
                    element.nodeName
                ] || { originalExpression: null, evaluatedExpression: null };
            }
        } catch {
            $scope.data.xmlParsingError = "Couldn't populate fields.";
        }
    }

    /**
     * Will evaluate the given example data.
     */
    async function evaluateXmlData(xmlData) {
        const exampleInitiative =
            customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]?.[
                $scope.data.configuredLogic.node.id
            ];

        const expressions = [
            {
                key: 'description',
                expression: xmlData,
            },
        ];

        let evaluationPromise;
        if (exampleInitiative) {
            evaluationPromise = tonkeanService.getEvaluatedExpressionsForInitiative(
                projectManager.project.id,
                exampleInitiative,
                expressions,
                true,
            );
        } else {
            evaluationPromise = tonkeanService.getEvaluatedExpressions($scope.data.groupId, expressions);
        }

        const { evaluatedExpressions } = await evaluationPromise;
        return evaluatedExpressions.description;
    }
}

export default angular
    .module('tonkean.app')
    .controller('SpringcmCustomActionsDefinitionCtrl', lateConstructController(SpringcmCustomActionsDefinitionCtrl));
