import React from 'react';

import FieldsWidget from './FieldsWidget';
import type { ItemWidgetProps } from '../../../WidgetModule';
import useItemFieldWidget from '../../hooks/useItemFieldWidget';
import type FieldsItemWidgetConfiguration from '../FieldsItemWidgetConfiguration';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { EMPTY_ARRAY } from '@tonkean/utils';

const ItemInterfaceFieldsWidget: React.FC<ItemWidgetProps<FieldsItemWidgetConfiguration>> = ({
    initiative,
    workflowVersionId,
    widget,
    permission,
}) => {
    const {
        reloadInitiativeFromServer,
        updateFieldsValuesManager,
        workflowVersion,
        itemInterfaceFieldDefinitions,
        loadingItemInterfaceFieldDefinitions,
    } = useItemInterfaceContext();

    const [onChange, configuredFields] = useItemFieldWidget(
        workflowVersionId,
        'ITEM_INTERFACE_FIELDS_WIDGET',
        updateFieldsValuesManager,
        workflowVersion,
        initiative,
        itemInterfaceFieldDefinitions,
        widget?.configuration?.fields || EMPTY_ARRAY,
        undefined,
    );

    return (
        <FieldsWidget
            onChange={onChange}
            fields={configuredFields}
            showEmptyFieldWhenNoInitiative={!initiative}
            loading={loadingItemInterfaceFieldDefinitions}
            widget={widget}
            permission={permission}
            displayNotUpToDateWarning={false}
        />
    );
};

export default ItemInterfaceFieldsWidget;
