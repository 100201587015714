import { useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useEntityAvailabeExternalFieldTonkeanExpressionTab = (
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION> | undefined,
    entityApiName: string | undefined,
) => {
    const [, getAvailableExternalFields] = useLazyTonkeanService('getAvailableExternalFields');

    const tab = useMemo(
        () => ({
            id: 'ORIGINAL_ENTITY_FIELDS',
            label: 'Original Entity Fields',
            iconClass: 'mod-handle-response',
            searchPlaceholder: 'Search...',
            getFields: async () => {
                if (!entityApiName || !projectIntegrationId) {
                    return [];
                }
                const { entitiesWithLabels: externalFields } = await getAvailableExternalFields(projectIntegrationId, [
                    entityApiName,
                ]);
                return externalFields.map((field) => ({
                    name: field.name,
                    value: field.name,
                    label: field.label,
                    id: field.name,
                }));
            },
        }),
        [entityApiName, getAvailableExternalFields, projectIntegrationId],
    );
    return tab;
};

export default useEntityAvailabeExternalFieldTonkeanExpressionTab;
