import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const theme = Theme.current.palette.history.workerRunsList;

const WorkerRunsTableItemGrid = styled.div`
    display: grid;
    grid-template-columns: 3fr 6fr 8fr 3fr;
    grid-gap: 20px;
    align-items: center;
    border-bottom: 1px solid ${theme.borderColor};
`;

export default WorkerRunsTableItemGrid;
