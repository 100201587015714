import type InitiativeRowData from '../entities/InitiativeRowData';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey } from '@tonkean/tonkean-entities';

const isFieldEmpty = (rowData: InitiativeRowData, fieldId: FieldDefinitionKey): boolean => {
    const isSpecialField = fieldId.startsWith('TNK');

    if (isSpecialField) {
        switch (fieldId) {
            case SpecialFieldsKey.TITLE: {
                return !rowData.title;
            }
            case SpecialFieldsKey.STATUS: {
                return !rowData.state;
            }
            case SpecialFieldsKey.OWNER: {
                return !rowData.owner?.id;
            }
            case SpecialFieldsKey.DUE_DATE: {
                return !rowData.dueDate;
            }
            default: {
                console.error(`fieldId - ${fieldId} is special field without specific handling!`);

                return !rowData[fieldId] || true;
            }
        }
    } else {
        const value = rowData[fieldId];

        return (
            // Note, 0 shouldn't be considered as empty.
            value === undefined || value === null || value === '' || (typeof value === 'string' && value.trim() === '')
        );
    }
};

export default isFieldEmpty;
