import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SyncPreviewCtrl(
    $scope,
    $q,
    consts,
    projectManager,
    tonkeanService,
    modalUtils,
    modal,
    integrationMetadataManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.modalUtils = modalUtils;

    $scope.syncPreviewViewMods = {
        LIST: 'list',
        TABLE: 'table',
    };

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        viewData: ctrl.viewData,
        viewType: ctrl.viewType,
        pluralEntityType: ctrl.pluralEntityType,
        selectedEntity: ctrl.selectedEntity,
        titleExpression: ctrl.titleExpression,
        defaultTitleIfExpressionEmpty: ctrl.defaultTitleIfExpressionEmpty,
        hideManageTriggersModal: ctrl.hideManageTriggersModal,
        hideInformation: ctrl.hideInformation,
        overrideActivateBotObject: ctrl.overrideActivateBotObject,
        isBotActive: null,
        hideBot: ctrl.hideBot,
        syncItemsLimitation: consts.getSyncItemsLimitation(),
        limitTo: consts.getSyncItemsLimitation(),
        loadOnInit: ctrl.loadOnInit,
        viewMod: ctrl.viewMod || $scope.syncPreviewViewMods.LIST,
        isEntityConfiguredInDataCollection: false,
        fullScreen: ctrl.fullScreen,

        tableFieldsMetadata: [],
        showNewViewDataTable: true,
    };

    $scope.isBotActive = $scope.data.overrideActivateBotObject?.getInitialValue();

    ctrl.$onInit = function () {
        if (ctrl.loadOnInit) {
            $scope.loadPreview();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.viewData) {
            $scope.data.viewData = changes.viewData.currentValue;

            if ($scope.loadingItems) {
                $scope.loadAgain = true;
                return;
            }
            $scope.loadPreview($scope.data.viewData);
        }

        if (changes.pluralEntityType) {
            $scope.data.pluralEntityType = ctrl.pluralEntityType;
        }

        if (changes.selectedEntity) {
            $scope.data.selectedEntity = ctrl.selectedEntity;
        }

        if (changes.projectIntegration) {
            $scope.data.projectIntegration = ctrl.projectIntegration;
        }

        if (changes.fullScreen) {
            $scope.data.fullScreen = ctrl.fullScreen;
        }
    };

    $scope.onDataChanged = function () {
        ctrl.onDataChanged();
    };

    /**
     * Refreshes the preview with latest items.
     */
    $scope.loadPreview = async () => {
        if (!$scope.data.viewData && !ctrl.loadPreviewItemsPromise) {
            return;
        }

        // Data collection feature affects which entities are being collected. if the user clicked on an entity
        // which is not being collected we want to display a relevant message.
        // In this component we hold only the entity plural name and in order to extract the project integration
        // Data Collection configuration we need its entity id. Thus, we will fetch for the integration entities.
        const integrationEntities = await integrationMetadataManager.getIntegrationEntities(ctrl.projectIntegration.id);
        // Searching for the entity object by its plural name.
        const entity = integrationEntities.supportedEntities.find(
            (item) => item.pluralLabel === $scope.data.pluralEntityType,
        );
        if (!entity) {
            return;
        }

        const { entity: entityId } = entity;
        // Searching for the data collection configuration for the given entity id.
        const projectIntegrationCollectedEntities =
            Object.values(ctrl.projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration || {}).map(
                (entity) => entity.id,
            ) || [];
        // Whether or not the entity is being configured.
        $scope.data.isEntityConfiguredInDataCollection = projectIntegrationCollectedEntities.includes(entityId);

        setIsLoading(true);
        if (ctrl.syncValidityState) {
            ctrl.syncValidityState.hasError = false;
            $scope.error = false;
        }

        if ($scope.data.titleExpression) {
            ctrl.viewData.titleExpression = $scope.data.titleExpression;
        }

        if ($scope.data.defaultTitleIfExpressionEmpty) {
            ctrl.viewData.defaultTitleIfExpressionEmpty = $scope.data.defaultTitleIfExpressionEmpty;
        }

        let loadPromise;
        if (ctrl.loadPreviewItemsPromise) {
            loadPromise = ctrl.loadPreviewItemsPromise;
        } else {
            const previewSyncPromise = tonkeanService.previewSyncView(
                projectManager.project.id,
                ctrl.projectIntegration.id,
                $scope.data.viewData,
                $scope.data.viewType,
            );
            let fieldMetadataPromise = $q.resolve();

            if ($scope.data.viewMod === $scope.syncPreviewViewMods.TABLE) {
                fieldMetadataPromise = tonkeanService
                    .getAvailableExternalFields(ctrl.projectIntegration.id, [ctrl.projectIntegration.displayName])
                    .then((data) => {
                        $scope.data.tableFieldsMetadata = [];
                        if (data.entitiesWithLabels) {
                            $scope.data.tableFieldsMetadata = data.entitiesWithLabels.filter(
                                (field) => field.name !== 'id',
                            );
                        }
                    });
            }

            loadPromise = $q.all([previewSyncPromise, fieldMetadataPromise]).then((results) => {
                return results[0];
            });
        }

        loadPromise
            .then(function (data) {
                if ($scope.loadAgain) {
                    $scope.loadAgain = false;
                    return $scope.loadPreview();
                }
                $scope.data.syncedItems = data.entities.map((entity) => entity.originalEntity);
                $scope.data.totalSyncedItemsCount = data.totalCount;
                $scope.data.finishedCollecting = data.finishedCollecting;

                if (ctrl.syncValidityState) {
                    ctrl.syncValidityState.isOverItemsLimitation =
                        $scope.data.syncedItems.length > $scope.data.syncItemsLimitation;
                }
                $scope.error = null;
            })
            .catch(function (error) {
                $scope.error = error.data ? error.data.error.message : error;
                if (ctrl.syncValidityState) {
                    ctrl.syncValidityState.hasError = true;
                }
            })
            .finally(function () {
                setIsLoading(false);
            });
    };

    $scope.toggleIsBotActive = function () {
        $scope.isBotActive = !$scope.isBotActive;
        if ($scope.data.overrideActivateBotObject) {
            $scope.data.overrideActivateBotObject.shouldOverride = true;
            $scope.data.overrideActivateBotObject.overrideValue = $scope.isBotActive;
        }
    };

    $scope.inspectItem = function (item) {
        tonkeanService
            .getExternalActivityById($scope.pm.project.id, $scope.data.projectIntegration.id, item.id, true)
            .then(function (data) {
                modal.openFieldInspectModal(
                    $scope.data.projectIntegration,
                    $scope.data.viewData.entity,
                    null,
                    null,
                    null,
                    null,
                    data,
                );
            });
    };

    $scope.editWebhookItem = function (item) {
        tonkeanService
            .getExternalActivityById($scope.pm.project.id, $scope.data.projectIntegration.id, item.id, true)
            .then(function (data) {
                modalUtils.openEditCustomWebhookItemModal($scope.data.projectIntegration, data.originalEntity);
            });
    };

    function setIsLoading(isLoading) {
        $scope.loadingItems = isLoading;
        if (ctrl.syncValidityState) {
            ctrl.syncValidityState.isLoading = isLoading;
        }
    }

    $scope.openDataCollectionConfigurationModal = function () {
        modalUtils.openEntitiesConfigurationExpirationModal(
            $scope.data.projectIntegration.id,
            $scope.data.projectIntegration.integration.integrationType,
        );
    };
}

export default angular.module('tonkean.app').controller('SyncPreviewCtrl', lateConstructController(SyncPreviewCtrl));
