import React from 'react';
import styled from 'styled-components';

import IntakeStatusProgressBar from './IntakeStatusProgressBar';
import useIntakeStatusesSummary from '../hooks/useIntakeStatusesSummary';

import { H2, H4 } from '@tonkean/infrastructure';
import { CloseIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div<{ $isVertical?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${({ $isVertical }) => ($isVertical ? 'start' : 'center')};
    justify-content: center;
    gap: ${({ $isVertical }) => ($isVertical ? '25px' : '10px')};
`;

const TopBar = styled.div<{ $isVertical?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $isVertical }) => ($isVertical ? 'start' : 'center')};
    width: 100%;
`;

const StyledClosedIconButton = styled(IconButton)`
    position: absolute;
    right: 0;
    margin: 16px;
`;

export interface IntakeProgressTrackerProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    title?: string;
    isVertical?: boolean;
    themeColor?: Color;
    showCloseButton?: boolean;
    closeButtonClicked?: () => void;
}

const IntakeProgressTracker: React.FC<IntakeProgressTrackerProps> = ({
    workflowVersionId,
    currentCustomTriggerId,
    currentFormId,
    title,
    isVertical,
    themeColor,
    showCloseButton,
    closeButtonClicked,
}) => {
    const intakeStatusesInfo = useIntakeStatusesSummary({
        workflowVersionId,
        currentCustomTriggerId,
        currentFormId,
    });

    if (!intakeStatusesInfo) {
        return null;
    }

    const Title = isVertical ? H2 : H4;
    return (
        <Container $isVertical={isVertical} data-automation="intake-progress-tracker">
            <TopBar $isVertical={isVertical}>
                {title && (
                    <Title $color={isVertical ? Theme.colors.gray_800 : Theme.colors.gray_700} $extraBold>
                        {title}
                    </Title>
                )}
                {showCloseButton && closeButtonClicked && (
                    <StyledClosedIconButton
                        onClick={closeButtonClicked}
                        dataAutomation="intake-progress-tracker-close-button"
                        flat
                    >
                        <CloseIcon />
                    </StyledClosedIconButton>
                )}
            </TopBar>
            <IntakeStatusProgressBar
                intakeStatusesInfo={intakeStatusesInfo}
                isVertical={isVertical ?? false}
                statusBarColor={themeColor}
            />
        </Container>
    );
};

export default IntakeProgressTracker;
