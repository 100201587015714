import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import EnterpriseComponentNotAuthorizedForPageContent from './EnterpriseComponentNotAutorizedForPageContent';
import EnterpriseComponentPageContentNotFound from './EnterpriseComponentPageContentNotFound';
import { EnterpriseComponentPageTemplateContext } from '../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import type { EnterpriseComponentContentPageConfiguration } from '../../../entities/EnterpriseComponentContentPageConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import type UpdateOverviewProp from '../../../utils/UpdateOverviewProp';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { Breadcrumbs } from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const BreadCrumbsWrapper = styled.div`
    margin-bottom: 20px;
`;

export const PageContent = styled.div<{ useOldLayout?: boolean }>`
    overflow-y: auto;

    ${({ useOldLayout = true }) =>
        useOldLayout
            ? css`
                  // Old layout
                  padding-top: 24px;
                  padding-left: 60px;
                  padding-right: 60px;
              `
            : css`
                  // New layout
                  display: flex;
                  flex-direction: column;
              `}
`;

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    menuItemIdConfigurationMap: Record<string, EnterpriseComponentContentPageConfiguration<T, C>>;
    enterpriseComponentType: T;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    setCurrentEnterpriseComponent(updatedEnterpriseComponent: EnterpriseComponent): void;
    updateOverview: UpdateOverviewProp<T>;
    pageStateName: string;
}

const EnterpriseComponentMenuItemPageContent = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    menuItemIdConfigurationMap,
    enterpriseComponentType,
    projectId,
    setCurrentEnterpriseComponent,
    updateOverview,
    pageStateName,
}: Props<T, C>) => {
    const { currentEnterpriseComponentAccessType, enterpriseComponent } = useEnterpriseComponentContext<C>();
    const [pageId] = useGetStateParams('page');
    const configuration = menuItemIdConfigurationMap[pageId];

    const hasPermissions = useMemo<boolean>(
        () => configuration?.permissionRoles.includes(currentEnterpriseComponentAccessType) || false,
        [configuration, currentEnterpriseComponentAccessType],
    );

    // Breadcrumb without display name, to allow EnterpriseComponentPageTemplate to only add the page name and not need to know the params.
    const pageTemplateContextValue = useMemo(
        () => ({
            currentPageState: pageStateName,
            currentPageParams: { page: pageId, enterpriseComponentId: enterpriseComponent.id },
        }),
        [enterpriseComponent.id, pageId, pageStateName],
    );

    if (!hasPermissions) {
        return (
            <PageContent>
                <EnterpriseComponentNotAuthorizedForPageContent />
            </PageContent>
        );
    }

    if (!configuration?.component || !enterpriseComponent) {
        return (
            <PageContent>
                <EnterpriseComponentPageContentNotFound />
            </PageContent>
        );
    }

    return (
        <PageContent useOldLayout={!!configuration?.useOldLayout}>
            {configuration.useOldLayout && configuration.showDefaultBreadCrumbs && (
                <BreadCrumbsWrapper>
                    <Breadcrumbs />
                </BreadCrumbsWrapper>
            )}
            <EnterpriseComponentPageTemplateContext.Provider value={pageTemplateContextValue}>
                <configuration.component
                    key={enterpriseComponent.id}
                    enterpriseComponent={enterpriseComponent}
                    enterpriseComponentType={enterpriseComponentType}
                    projectId={projectId}
                    setCurrentEnterpriseComponent={setCurrentEnterpriseComponent}
                    updateOverview={updateOverview}
                />
            </EnterpriseComponentPageTemplateContext.Provider>
        </PageContent>
    );
};

export default EnterpriseComponentMenuItemPageContent;
