import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class DayOfWeekFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.DAY_OF_WEEK;
    override readonly sign = 'DayOfWeek';
    override readonly displayName = 'Day of Week';
    override readonly description = 'Will get the day of week of a date in a specific timezone.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        timeZoneField(),
    ];
}
