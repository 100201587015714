import template from './syncPreview.template.html?angularjs';

/**
 * This is a component used to display a preview of a sync about to be configured.
 */
export default angular.module('tonkean.app').component('tnkSyncPreview', {
    bindings: {
        /**
         * The project integration of the integration we're syncing from.
         */
        projectIntegration: '<',

        /**
         * The field representing the title expression.
         */
        titleExpression: '<',

        /**
         * The field representing the default value for title if expression evaluates to an empty or null value.
         */
        defaultTitleIfExpressionEmpty: '<',

        /**
         * View data used to sync the view.
         */
        viewData: '<',

        /**
         * The plural form of the entity type.
         */
        pluralEntityType: '<',

        /**
         * The type of the view.
         */
        viewType: '<',

        /**
         * the validity state of the control
         * will hold the state of validity: isloading, haserror, invalid...
         */
        syncValidityState: '<',

        /**
         * True if the manage triggers modal button should be hidden
         */
        hideManageTriggersModal: '<',

        /**
         * True if the manage triggers modal button should be hidden
         */
        hideInformation: '<',

        /**
         * True if the Module on/off button should be hidden
         */
        hideBot: '<',

        /**
         * An object used for overriding the default shouldGatherUpdates
         */
        overrideActivateBotObject: '<',

        /**
         * If exists, runs this promise instead of the default tonkeanService promise for load preview
         */
        loadPreviewItemsPromise: '<',

        /**
         * If true runs the loadPreview method on controller init
         */
        loadOnInit: '<',

        /**
         * Determines how the data would be displayed. Can be 'list' or 'table'. defaults to 'list'.
         */
        viewMod: '<',

        /**
         * If true sets the grid to be full screen resolution
         */
        fullScreen: '<',

        /**
         * The name/id of the entity. Should be unique and singular.
         */
        selectedEntity: '<',

        /**
         * A function to call when the viewed data as been changed
         */
        onDataChanged: '<',
    },
    template,
    controller: 'SyncPreviewCtrl',
});
