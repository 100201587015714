<div class="margin-left margin-right">
    <!-- Deactivate User -->
    <div
        class="delay-configuration-logic"
        ng-if="!data.loading && data.definition.customActionKey === 'DEACTIVATE_USER'"
    >
        <tnk-checkbox
            class="margin-top-xs"
            label="'Send email to admin?'"
            value="data.definition.sendEmail"
            on-toggle-async="onDefinitionChanged(true)"
        ></tnk-checkbox>

        <div class="alert alert-info margin-top">
            Important: Deactivating a user is a destructive operation. The user is deprovisioned from all assigned
            applications which may destroy their data such as email or files. This action cannot be recovered!
        </div>
    </div>

    <!-- Add user to group-->
    <div
        class="delay-configuration-logic"
        ng-if="!data.loading && data.definition.customActionKey === 'ADD_USER_TO_GROUP'"
    >
        <!-- Group ID -->
        <div class="">Group ID:</div>
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Group ID to add the user to..."
            saved-expression="data.definition.groupIdExpression"
            on-tonkean-expression-changed="onGroupIdExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
    </div>
</div>
