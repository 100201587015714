import ItemInterfaceActionsItemWidget from './components/ItemInterfaceActionsItemWidget';
import ItemInterfaceActionsItemWidgetConfigurationEditor from './components/ItemInterfaceActionsItemWidgetConfigurationEditor';
import type { ItemInterfaceWidgetPackage } from '../../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const ItemInterfaceActionsItemWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: ItemInterfaceActionsItemWidget,
    configurationComponent: ItemInterfaceActionsItemWidgetConfigurationEditor,
};

export default ItemInterfaceActionsItemWidgetPackage;
