import React, { useContext } from 'react';

import FormulaContext from '../entities/FormulaContext';
import operandKeyToSpecificEditor from '../entities/operandKeyToSpecificEditor';
import type SpecificEditorProps from '../entities/SpecificEditorProps';

type PropsToPick =
    | 'groupId'
    | 'exampleInitiativeId'
    | 'tonkeanService'
    | 'customFieldsManager'
    | 'setFormulaChangedOutsideSpecificEditorCallback'
    | 'onFormulaDataChange';
type Props = Pick<SpecificEditorProps, PropsToPick>;

const SpecificEditor: React.FC<Props> = ({
    groupId,
    exampleInitiativeId,
    setFormulaChangedOutsideSpecificEditorCallback,
    customFieldsManager,
    tonkeanService,
    onFormulaDataChange,
}) => {
    const { workflowVersionId, specificEditor } = useContext(FormulaContext);

    const SpecificEditorComponent: React.FC<SpecificEditorProps> | undefined =
        specificEditor?.treeNode.operator.key && operandKeyToSpecificEditor[specificEditor.treeNode.operator.key];

    return (
        <>
            {specificEditor && SpecificEditorComponent && (
                <SpecificEditorComponent
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    exampleInitiativeId={exampleInitiativeId}
                    tonkeanService={tonkeanService}
                    customFieldsManager={customFieldsManager}
                    setFormulaChangedOutsideSpecificEditorCallback={setFormulaChangedOutsideSpecificEditorCallback}
                    fields={specificEditor.treeNode.operator.getFieldsList}
                    operands={specificEditor.treeNode.operands}
                    onChanges={specificEditor.onChanges}
                    onFormulaDataChange={onFormulaDataChange}
                    indexInTree={specificEditor.treeNode.id.toString()}
                />
            )}
        </>
    );
};

export default React.memo(SpecificEditor);
