import { Form, Formik } from 'formik';
import type { FormikHelpers as FormikHelpersType } from 'formik';
import React from 'react';
import styled from 'styled-components';

import FieldsWidgetDisplay from './FieldsWidgetDisplay';
import FieldsWidgetLoadingSkeleton from './FieldsWidgetLoadingSkeleton';
import { ItemInterfacePermission } from '../../../entities';
import { ItemWidgetHeaderTitle } from '../../../WidgetModule';
import { ItemWidgetSubmitFooter } from '../../../WidgetModule';
import { ItemWidget } from '../../../WidgetModule';
import type { SingleFieldChanged, WidgetFieldPackage } from '../../CommonWidgetConfiguration';
import useFieldsWidgeEffectiveDisplayType from '../../hooks/useFieldsWidgeEffectiveDisplayType';
import useFilterFieldsInWidget from '../../hooks/useFilterFieldsInWidget';
import useWidgetConfigurationFieldsSoftMigration from '../../hooks/useWidgetConfigurationFieldsSoftMigration';
import { FieldsWidgetDisplayType } from '../FieldsItemWidgetConfiguration';
import type FieldsItemWidgetConfiguration from '../FieldsItemWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Breakpoint, FormikHelpers, H3, SearchBox, useBreakpoint, useSearch } from '@tonkean/infrastructure';
import { FieldManagementWidgetIcon as FieldsWidgetIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType, Widget } from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { Theme } from '@tonkean/tui-theme';

const EmptyState = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;

    svg > path {
        fill: #c4c4c4;
    }

    ${H3} {
        color: ${Theme.colors.gray_700};
    }
`;

const WidgetHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WidgetIconAndTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WidgetDisplayNameContainer = styled(ItemWidgetHeaderTitle)`
    margin-left: 5px;
`;

const SearchBoxContainer = styled.span`
    min-width: 100px;
    margin-left: 16px;
`;

const FormWrapper = styled(Form)`
    display: contents;
`;

type FormikValueType = Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, SingleFieldChanged>;

interface Props {
    onChange(changes: SingleFieldChanged[]): Promise<void>;
    fields: WidgetFieldPackage[];
    showEmptyFieldWhenNoInitiative: boolean;
    loading: boolean;
    widget: Widget<FieldsItemWidgetConfiguration>;
    permission: ItemInterfacePermission;
    displayNotUpToDateWarning: boolean;
    showModuleOffIndication?: boolean;
}

const FieldsWidget: React.FC<Props> = ({
    fields,
    loading,
    showEmptyFieldWhenNoInitiative,
    widget,
    onChange,
    permission,
    displayNotUpToDateWarning,
    showModuleOffIndication,
}) => {
    const onSubmit = useConstantRefCallback((values: FormikValueType, helpers: FormikHelpersType<FormikValueType>) => {
        return onChange(Object.values(values)).then(() => {
            // Reset the form so next submit will only submit the newly touched fields
            helpers.resetForm({});
        });
    });

    const configurationFields = useWidgetConfigurationFieldsSoftMigration(widget.configuration.fields);

    const submitAsForm = !!widget.configuration.submitAsForm;

    const [shownFieldKeys, filteredShownFields, fieldsSearchComparer] = useFilterFieldsInWidget(
        showEmptyFieldWhenNoInitiative,
        fields,
        configurationFields,
    );

    const widgetEffectiveDisplayType = useFieldsWidgeEffectiveDisplayType(widget.configuration.fieldDisplay);
    const isInGalleryView = widgetEffectiveDisplayType == FieldsWidgetDisplayType.GALLERY;

    const { searchTerm, setSearchTerm, debouncedFilteredItems } = useSearch(filteredShownFields, fieldsSearchComparer);

    const canUserEditItem = [
        ItemInterfacePermission.USER_CAN_EDIT_ITEM,
        ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM,
    ].includes(permission);

    const disableWidgetMaxHeight =
        useBreakpoint() < Breakpoint.XSMALL_640 || widgetEffectiveDisplayType === FieldsWidgetDisplayType.TABLE;

    const showFieldsWidgetsReadOnlyTable = useFeatureFlag('tonkean_feature_show_fields_widgets_read_only_table');

    return (
        <Formik initialValues={{} as FormikValueType} onSubmit={onSubmit}>
            {(formik) => (
                <FormikHelpers>
                    <FormWrapper>
                        <ItemWidget
                            headerActions={
                                <>
                                    {widget.configuration.showSearch && (
                                        <SearchBoxContainer>
                                            <SearchBox
                                                data-automation="fields-widget-search-box"
                                                value={searchTerm}
                                                onChange={({ target: { value } }) => setSearchTerm(value)}
                                                placeholder="Search"
                                                $maxWidth="200px"
                                                $height="30px"
                                                $width="210px"
                                                $borderRadius="18px"
                                            />
                                        </SearchBoxContainer>
                                    )}
                                </>
                            }
                            footer={submitAsForm && <ItemWidgetSubmitFooter canUserEditItem={canUserEditItem} />}
                            noPaddingBody={widgetEffectiveDisplayType !== FieldsWidgetDisplayType.LIST}
                            permission={permission}
                            disableMaxHeight={disableWidgetMaxHeight}
                            noBackgroundBody={
                                showFieldsWidgetsReadOnlyTable &&
                                isInGalleryView &&
                                !widget.configuration.editingEnabled
                            }
                            noBorderBody={
                                showFieldsWidgetsReadOnlyTable &&
                                isInGalleryView &&
                                !widget.configuration.editingEnabled
                            }
                            disableMinHeight
                        >
                            {loading ? (
                                <FieldsWidgetLoadingSkeleton
                                    fields={widget.configuration.fields}
                                    fieldDisplay={widgetEffectiveDisplayType}
                                />
                            ) : (
                                <>
                                    {shownFieldKeys?.length > 0 ? (
                                        <FieldsWidgetDisplay
                                            showModuleOffIndication={showModuleOffIndication}
                                            projectId={widget.projectId}
                                            onChange={(change) => {
                                                if (submitAsForm) {
                                                    formik.setFieldValue(change.package.fieldDefinition.id, change);
                                                    return Promise.resolve();
                                                } else {
                                                    return onChange([change]);
                                                }
                                            }}
                                            showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                                            fields={debouncedFilteredItems}
                                            submitAsForm={submitAsForm}
                                            editingEnabledInWidget={widget.configuration.editingEnabled}
                                            fieldDisplay={widgetEffectiveDisplayType}
                                            canUserEditItem={canUserEditItem}
                                            displayNotUpToDateWarning={displayNotUpToDateWarning}
                                            emphasizeText={widget.configuration.emphasizeText}
                                        />
                                    ) : (
                                        <EmptyState data-automation="fields-widget-empty-state">
                                            <FieldsWidgetIcon />
                                            <H3 $bold>No fields selected</H3>
                                        </EmptyState>
                                    )}
                                    {!configurationFields && <div>No Fields configured</div>}
                                </>
                            )}
                        </ItemWidget>
                    </FormWrapper>
                </FormikHelpers>
            )}
        </Formik>
    );
};

export default React.memo(FieldsWidget);
