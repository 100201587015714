import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import styled from 'styled-components';

import SolutionSiteSidePaneContent from './SolutionSiteSidePaneContent';

import { SidePane } from '@tonkean/infrastructure';
import { TOPBAR_REDESIGN_HEIGHT } from '@tonkean/navigation';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export const SOLUTION_SITE_INTERFACE_MENU_WIDTH = 300;

const StyledSidePane = styled(SidePane)`
    grid-area: solution-site-sidepane;
    border-right: 1px solid ${Theme.colors.gray_200};
    box-shadow: none !important;
`;

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    isMobile: boolean;
    workflowVersionType: WorkflowVersionType;
}

const SolutionSiteSidePane: React.FC<Props> = ({ open, setOpen, isMobile, workflowVersionType }) => {
    return (
        <StyledSidePane
            open={open}
            width={SOLUTION_SITE_INTERFACE_MENU_WIDTH}
            onClose={() => {
                isMobile && setOpen(false);
            }}
            side="left"
            top={`${TOPBAR_REDESIGN_HEIGHT}px`}
            skipInitialAnimation={!isMobile}
            backdrop={isMobile}
            focusTrap={isMobile}
        >
            <SolutionSiteSidePaneContent isMobile={isMobile} open={open} setOpen={setOpen} />
        </StyledSidePane>
    );
};

export default SolutionSiteSidePane;
