/**
 * Created by ShaLi on 23/11/2015.
 */

function NewProductPageCtrl($scope, $state, $stateParams) {
    // var modalPromise = modalUtils.openWorkEmailIfNoOrg('create');
    // if (modalPromise) {
    //    modalPromise.catch(function () {
    //        $state.go('products');
    //    });
    // }
    $scope.isPrivate = $stateParams.type !== 'open';
    $scope.state = {};

    $scope.$watch('newProject', function () {
        if ($scope.newProject) {
            $scope.pm.selectProject($scope.newProject);
            $state.go('product', { projectId: $scope.newProject.id });
        }
    });
}
export default angular.module('tonkean.app').controller('NewProductPageCtrl', NewProductPageCtrl);
