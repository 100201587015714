import template from './tnkPreviewBotBubble.template.html?angularjs';
import { lateConstructController } from '@tonkean/angular-components';

export const tnkPreviewBotBubbleCtrlComponent = angular.module('tonkean.app').component('tnkPreviewBotBubbleCtrl', {
    bindings: {
        entityName: '<',
        text: '<',
        textEvaluatedExpression: '<',
        postText: '<',
        postTextEvaluatedExpression: '<',
        newLineText: '<',
        newLineEvaluatedExpression: '<',
        postAttachementText: '<',
        placeholder: '@',
        showQuestion: '<',
        isEmail: '<',
        user: '<',
        noTriangle: '<',
        color: '<',
        autoWidth: '<',
        botButtons: '<',
        botFields: '<',
        sendOnBehalf: '<',
        groupId: '<',
        workflowVersionId: '@',
        uniqueIdentifier: '@',
        logicId: '<',

        doNotEvaluatePreview: '<', // When false, will evaluate the expression for preview.
        overrideExampleInitiativeId: '<', // an example initiative for the preview.
        hidePreview: '<', // weather to show preview by default
        htmlPreview: '<',
        previewEvaluationSource: '<', // Whether show preview by example item or

        noButtons: '@',
        previewType: '@',
    },
    template,
    controller: 'tnkPreviewBotBubbleCtrl',
});

/* @ngInject */
const tnkPreviewBotBubbleCtrl = function ($scope, trackHelper, customTriggerManager) {
    const ctrl = this;

    $scope.data = {
        finishedOnInit: false,
        entityName: ctrl.entityName,
        text: ctrl.text,
        textEvaluatedExpression: ctrl.textEvaluatedExpression,
        textEvaluatedExpressionValue: '',
        textEvaluatedExpressionLoading: false,
        postText: ctrl.postText,
        postTextEvaluatedExpression: ctrl.postTextEvaluatedExpression,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        newLineText: ctrl.newLineText,
        postAttachementText: ctrl.postAttachementText,
        placeHolder: ctrl.placeholder,
        showQuestion: ctrl.showQuestion,
        isEmail: ctrl.isEmail,
        user: ctrl.user,
        noTriangle: ctrl.noTriangle,
        autoWidth: ctrl.autoWidth,
        color: ctrl.color,
        botButtons: ctrl.botButtons,
        botFields: ctrl.botFields,
        sendOnBehalf: ctrl.sendOnBehalf,
        noButtons: ctrl.noButtons,
        previewType: ctrl.previewType,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        error: '',
        showExampleItem: !ctrl.hidePreview,
        uniqueIdentifier: ctrl.uniqueIdentifier,
        logicId: ctrl.logicId,
        htmlPreview: ctrl.htmlPreview,

        selectedExampleItem: { title: 'no' },
        exampleInitiativeId: null,
        exampleTracksMap: [],
        exampleItemError: '',

        previewTypesEnum: {
            text: 'text',
            question: 'question',
            trigger: 'trigger',
        },
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        $scope.data.finishedOnInit = true;

        if (ctrl.overrideExampleInitiativeId) {
            $scope.data.exampleInitiativeId = ctrl.overrideExampleInitiativeId;
        } else if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            $scope.data.exampleInitiativeId =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.logicId
                ];
        }

        // Todo: for now not calling the get title, till we will have throttler that return promise for all the requests
        // getExampleInitiativeTitle();
    };

    /**
     * OnChanges function of the controller.
     */
    ctrl.$onChanges = function (changes) {
        // Keep the data.initiativeId up to date.
        if (changes.text) {
            $scope.data.text = ctrl.text;
        }

        if (changes.entityName) {
            $scope.data.entityName = ctrl.entityName;
        }

        if (changes.htmlPreview) {
            $scope.data.htmlPreview = ctrl.htmlPreview;
        }

        if (changes.newLineText) {
            $scope.data.newLineText = ctrl.newLineText;
        }

        if (changes.postText) {
            $scope.data.postText = ctrl.postText;
        }

        if (changes.postAttachementText) {
            $scope.data.postAttachementText = ctrl.postAttachementText;
        }

        if (changes.color) {
            $scope.data.color = ctrl.color;
        }

        if (changes.textEvaluatedExpression) {
            $scope.data.textEvaluatedExpression = changes.textEvaluatedExpression.currentValue;
        }

        if (changes.postTextEvaluatedExpression) {
            $scope.data.postTextEvaluatedExpression = changes.postTextEvaluatedExpression.currentValue;
        }

        if (changes.newLineEvaluatedExpression) {
            $scope.data.newLineEvaluatedExpression = changes.newLineEvaluatedExpression.currentValue;
        }

        if (changes.overrideExampleInitiativeId) {
            $scope.data.exampleInitiativeId = changes.overrideExampleInitiativeId.currentValue;

            // Todo: for now not calling the get title, till we will have throttler that return promise for all the requests
            // getExampleInitiativeTitle();
        }

        if (changes.sendOnBehalf) {
            $scope.data.sendOnBehalf = ctrl.sendOnBehalf;
        }

        if (changes.isEmail) {
            $scope.data.isEmail = ctrl.isEmail;
        }
    };

    // function getExampleInitiativeTitle() {
    //     if ($scope.data.exampleInitiativeId) {
    //         trackHelper.getInitiativeById($scope.data.exampleInitiativeId)
    //             .then(function (data) {
    //                 $scope.data.exampleInitiativeTitle = data.title;
    //             });
    //     }
    // }

    /**
     * Toggle the example item selection.
     */
    $scope.toggleShowExampleItem = function () {
        $scope.data.showExampleItem = !$scope.data.showExampleItem;
    };
};

export const tnkPreviewBotBubbleCtrlController = angular
    .module('tonkean.app')
    .controller('tnkPreviewBotBubbleCtrl', lateConstructController(tnkPreviewBotBubbleCtrl));
