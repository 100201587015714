import React, { useContext } from 'react';

import ProjectIntegrationDataRetentionTable from './ProjectIntegrationDataRetentionPage/ProjectIntegrationDataRetentionTable';
import ProjectIntegrationPageContext from './ProjectIntegrationPageContext';
import EnterpriseComponentPageTemplate from '../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';

import { angularToReact } from '@tonkean/angular-components';
import type { EntityInboundConfiguration } from '@tonkean/tonkean-entities';

const DataRetentionConfiguration = angularToReact<{
    projectIntegration: any;
    setNativeEntitiesInboundConfiguration: (
        nativeEntitiesInboundConfiguration: Record<string, EntityInboundConfiguration>,
    ) => void;
}>('tnk-data-retention-configuration', {
    setNativeEntitiesInboundConfiguration: ['nativeEntitiesInboundConfiguration'],
});

interface Props {}

const ProjectIntegrationPageDataRetentionPage: React.FC<Props> = () => {
    const { currentProjectIntegration, setCurrentProjectIntegration } = useContext(ProjectIntegrationPageContext);

    return (
        <EnterpriseComponentPageTemplate
            name="Data Retention"
            description="Set the retention time limit of entity data that is no longer active. Once this limit is reached, relevant data source records are archived."
        >
            {currentProjectIntegration && (
                <ProjectIntegrationDataRetentionTable
                    key={currentProjectIntegration?.id}
                    projectIntegration={currentProjectIntegration}
                />
            )}

            {/* TODO REMOVE THIS AND ALL THE DATA RETENTION FILES AFTER TESTS*/}
            {/* <DataRetentionConfiguration*/}
            {/*    projectIntegration={currentProjectIntegration}*/}
            {/*    key={currentProjectIntegration?.id}*/}
            {/*    setNativeEntitiesInboundConfiguration={(*/}
            {/*        nativeEntitiesInboundConfiguration: Record<string, EntityInboundConfiguration>,*/}
            {/*    ) => {*/}
            {/*        setCurrentProjectIntegration((projectIntegration) => ({*/}
            {/*            ...projectIntegration,*/}
            {/*            nativeEntitiesInboundConfiguration,*/}
            {/*        }));*/}
            {/*    }}*/}
            {/* />*/}
        </EnterpriseComponentPageTemplate>
    );
};

export default ProjectIntegrationPageDataRetentionPage;
