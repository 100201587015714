import LogicalOperatorBase from './LogicalOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const fieldMatchingType = Symbol('field A and field B value type');

export default class NotEqualsOperator extends LogicalOperatorBase {
    override readonly key = OperatorKey.NOT_EQUALS;
    override readonly sign = '!=';
    override readonly displayName = 'Not Equal To';
    override readonly description = 'Will return true if the left hand field is not equal to the right hand field.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: undefined,
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: undefined,
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
