import styled, { css } from 'styled-components';

const FormSettingsSelectWrapper = styled.div<{ fixedHeight?: boolean }>`
    margin-top: 8px;
    ${({ fixedHeight }) =>
        fixedHeight &&
        css`
            height: 34px;
        `};
`;

export default FormSettingsSelectWrapper;
