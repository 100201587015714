import { useAngularService } from 'angulareact';
import type React from 'react';
import { useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useSequentialIdentifier } from '@tonkean/infrastructure';
import { createOperationConfiguration } from '@tonkean/solution-mapper-graph';
import type { OperationEdge, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

function useCreateEdge(
    projectId: TonkeanId<TonkeanType.PROJECT>,
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>,
    setEdges?: React.Dispatch<React.SetStateAction<OperationEdge[]>>,
) {
    const { incrementValue } = useSequentialIdentifier();
    const authenticationService = useAngularService('authenticationService');
    const $rootScope = useAngularService('$rootScope');

    const [loadingEdges, setLoadingEdges] = useState<{ id: string; source: string; target: string }[]>([]);
    const [, createOperationEdge] = useLazyTonkeanService('createOperationEdge');

    const createEdgeFunc = (source: string, target: string) => {
        const tempId = `temp-edge-${incrementValue()}`;
        if (!setEdges) {
            return;
        }

        setLoadingEdges((currentEdges) => [...currentEdges, { id: tempId, source, target }]);

        createOperationEdge(workflowFolderId, solutionMapperId, source, target, 'Handoff')
            .then(({ operationEdgeId, operationConfigurationId }) => {
                setLoadingEdges((currentEdges) => currentEdges.filter((loadingEdge) => loadingEdge.id !== tempId));

                setEdges((currentEdges) => [
                    ...currentEdges,
                    {
                        id: operationEdgeId,
                        source,
                        target,
                        projectId,
                        workflowFolderId,
                        solutionMapperId,
                        operationConfiguration: createOperationConfiguration(
                            operationConfigurationId,
                            projectId,
                            workflowFolderId,
                            solutionMapperId,
                            authenticationService.getCurrentUser(),
                            'Handoff',
                        ),
                    },
                ]);
            })
            .catch(() => {
                $rootScope.$emit('alert', {
                    msg: `An error occurred while trying to create a new handoff.`,
                    type: 'danger',
                });
            });
    };

    return [loadingEdges, createEdgeFunc] as const;
}

export default useCreateEdge;
