import type React from 'react';

import type NavigablePageKey from './NavigablePageKey';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

export enum NavigablePageType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

interface BaseNavigablePage {
    id:
        | NavigablePageKey
        | TonkeanId<TonkeanType.SOLUTION_SITE>
        | TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>
        | TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    type: NavigablePageType;
    name: string;
    icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | undefined;
    menuIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>> | undefined;
    isShownInNavigationMenu: (features: Record<string, boolean>) => boolean | undefined;
    accessibleToAllUsers: boolean;
    openInNewPage: boolean;
    state?: string;
    params?: Record<string, unknown>;
    path?: string;
    secondaryStates?: string[];
}

export default BaseNavigablePage;
