import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import personSelectorValidator from '../sharedConfigComponents/validators/personSelectorValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const GatherUpdateLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.GATHER_UPDATE,
    title: 'Ask for Status',
    iconClass: 'mod-gather-update',
    description: 'Ask someone what is the latest status on an item. The selected user will be assigned as owner.',
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    hasPersonConfig: true,
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        personSelectorValidator(validationObject, definition);

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default GatherUpdateLogicBlockConfig;
