import template from './tnkToggleButtonsGroup.template.html?angularjs';

/**
 * This component accepts an array of buttons definition and creates a toggle between them
 */
export default angular.module('tonkean.app').component('tnkToggleButtonsGroup', {
    bindings: {
        buttons: '<', // An array of type { value: 5, label: 'Some label' }
        titleLabel: '<', // A title which will describe the buttons group
        onButtonSelected: '&', // A callback for clicking on a single button
        selectedButton: '<', // Pass the already selected button

        automationIdentifier: '@',
        wrapperClasses: '@',
        toggleButtonsGroupClasses: '@',
        titleClasses: '@',
    },
    template,
    controller: 'ToggleButtonsGroupCtrl',
});
