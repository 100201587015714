import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as LockSVG } from '../../../../../../images/icons/lock12.svg';
import { ReactComponent as TrashIcon } from '../../../../../../images/icons/trash-form.svg';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import EnterpriseComponentIcon from '../../EnterpriseComponentIcon';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { KebabMenuButton, Menu, MenuItem, TextEllipsis, Tooltip, useCreateDialog } from '@tonkean/infrastructure';
import type { EnterpriseComponent, TonkeanType } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName, PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';

const ItemContainer = styled(Clickable)<{ selected?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    text-align: left;
    padding: 12px 10px 10px 15px;

    height: 50px;
    width: 100%;

    &:hover {
        background-color: ${Theme.colors.gray_300};
    }

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${Theme.colors.gray_300};
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed !important;
        `};

    ${styledFocus}
`;

const ProjectIntegrationIconView = styled(EnterpriseComponentIcon)`
    margin-right: 10px;
`;

const ItemTitleContainer = styled.div`
    flex-grow: 1;
`;

const DisplayName = styled.div`
    font-size: ${FontSize.SMALL_12};
    max-width: 160px;
    color: ${Theme.colors.gray_700};
`;

const LastUpdated = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

const LockSvgWrapper = styled(LockSVG)`
    margin-left: auto;
    margin-right: 12px;
`;

const StyledKebabMenuButton = styled(KebabMenuButton)`
    flex-shrink: 0;
`;

interface Props {
    enterpriseComponentAccessType: PersonPermissionRoleType;
    enterpriseComponentId: string;
    enterpriseComponentType: TonkeanType;
    enterpriseComponentsByIds: Record<string, EnterpriseComponent>;
}

const EnterpriseComponentsExplorerListItem: React.FC<Props> = ({
    enterpriseComponentAccessType,
    enterpriseComponentId,
    enterpriseComponentType,
    enterpriseComponentsByIds,
}) => {
    const { onDelete, enterpriseComponentIdsUserCanDelete, loadingEnterpriseComponentId } =
        useEnterpriseComponentContext();
    const canUserUpdateEnterpriseComponent = enterpriseComponentAccessType === PersonPermissionRoleType.ADMIN;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedItemId] = useGetStateParams('enterpriseComponentId');
    const myRef = useRef<HTMLButtonElement>(null);
    const enterpriseComponent = useMemo(
        () => enterpriseComponentsByIds[enterpriseComponentId],
        [enterpriseComponentsByIds, enterpriseComponentId],
    );

    // Scroll to this item if it's selected.
    // *NOTE* We need to keep the deps empty so this will happen only on the first load, and not on each click
    useEffect(() => {
        if (myRef.current && selectedItemId === enterpriseComponent?.id) {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const { confirm } = useCreateDialog();
    const handleDelete = (enterpriseComponentIdToDelete: string) => {
        if (!onDelete) {
            return;
        }

        confirm(
            `Are you sure you want to delete the ${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}?`,
            {
                warning: true,
                okLabel: 'Delete',
            },
        ).then((shouldDelete) => {
            if (shouldDelete) {
                onDelete(enterpriseComponentIdToDelete);
            }
        });
    };

    const canDelete = useMemo(() => {
        if (!canUserUpdateEnterpriseComponent) {
            return false;
        }
        // onDelete was provided but enterpriseComponentIdsUserCanDelete is undefined, so ALL enterprise components should be deletable.
        if (onDelete && !enterpriseComponentIdsUserCanDelete) {
            return true;
        }

        if (onDelete && enterpriseComponentIdsUserCanDelete?.includes(enterpriseComponentId)) {
            return true;
        }

        return false;
    }, [canUserUpdateEnterpriseComponent, enterpriseComponentId, enterpriseComponentIdsUserCanDelete, onDelete]);

    return (
        <>
            {enterpriseComponent ? (
                <ItemContainer
                    disabled={enterpriseComponent.disabled}
                    ref={myRef}
                    selected={selectedItemId === enterpriseComponent.id}
                    state="."
                    params={{
                        enterpriseComponentId: enterpriseComponent.id,
                    }}
                    options={{ notify: false }}
                >
                    <ProjectIntegrationIconView
                        integrationType={enterpriseComponent.integrationType}
                        iconUrl={enterpriseComponent.iconUrl}
                        isValid={enterpriseComponent.valid}
                    />

                    <ItemTitleContainer data-automation="enterprise-components-explorer-list-item-data-source-name">
                        <DisplayName>
                            <TextEllipsis numberOfLines={1} tooltip>
                                {enterpriseComponent.displayName}
                            </TextEllipsis>
                        </DisplayName>

                        <LastUpdated>{enterpriseComponent.subtitle}</LastUpdated>
                    </ItemTitleContainer>

                    {selectedItemId === enterpriseComponent.id && canDelete && !loadingEnterpriseComponentId && (
                        <Menu
                            show={isMenuOpen}
                            menuItems={
                                <MenuItem icon={<TrashIcon />} onClick={() => handleDelete(enterpriseComponent.id)}>
                                    Delete
                                </MenuItem>
                            }
                            onClose={() => setIsMenuOpen(false)}
                        >
                            <StyledKebabMenuButton
                                onClick={() => setIsMenuOpen(true)}
                                pressed={isMenuOpen}
                                horizontal
                                flat
                            />
                        </Menu>
                    )}

                    {loadingEnterpriseComponentId && loadingEnterpriseComponentId === enterpriseComponent.id && (
                        <div className="loading" />
                    )}

                    {(enterpriseComponent.disabled ||
                        enterpriseComponent.accessType === PersonPermissionRoleType.NOT_AUTHORIZED) && (
                        <Tooltip
                            disabled={!enterpriseComponent.disabled}
                            content={`${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular} is inaccessible from this module.`}
                        >
                            <LockSvgWrapper />
                        </Tooltip>
                    )}
                </ItemContainer>
            ) : (
                <>No enterprise component was found</>
            )}
        </>
    );
};

export default EnterpriseComponentsExplorerListItem;
