import { reactToAngular } from '@tonkean/angular-components';
import { PickerList } from '@tonkean/infrastructure';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPickerList',
        reactToAngular(PickerList, [
            'itemsById',
            'onCheckChanged',
            'onSelectChanged',
            'loading',
            'title',
            'customTitle',
            'customEmptyMessage',
            'titleCounter',
            'showCheckboxes',
            'highlightText',
            'customPadding',
            'titleShadow',
            'onClickSelect',
            'selectedId',
        ]),
    );
