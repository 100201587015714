<div class="relative field-selector-container" ng-if="::!data.listMode">
    <ui-select
        class="relative field-selector-ui-select"
        ng-class="::{'mod-enable-inspect': data.fieldInspectPopover || data.fieldInspectModal, 'mod-add-new': data.suggestionExists, 'mod-button': data.buttonMode}"
        ng-click="data.fieldInspectAutoOpen && autoOpenFieldInspect($event)"
        theme="bootstrap"
        ng-model="data.selectedField"
        on-select="fieldSelectionChange(false)"
        ng-disabled="data.loadingFieldOptions || data.viewOnly"
        append-to-body="data.appendToBody"
        ng-attr-ui-select-required="data.isRequired === true"
        uib-tooltip="{{ data.tooltipValue }}"
        data-automation="{{(data.loadingFieldOptions || data.viewOnly) ? 'field-selector-template-select-loading' : 'field-selector-template-select-filed'}}"
    >
        <ui-select-match
            placeholder="{{
                data.loadingFieldOptions
                    ? 'Loading fields...'
                    : data.placeHolder || (data.buttonMode ? '' : 'Search for a field')
            }}"
        >
            <!-- If should display selected field -->
            <div class="flex" ng-if="!data.doNotDisplaySelectedField">
                <span
                    data-automation="field-selector-select-field-row"
                    ng-class="{ 'common-color-grey': !data.selectedField.label && !data.selectedField.name }"
                >
                    <div data-automation="field-selector-selected-field" class="flex">
                        <!-- Integration Icon -->
                        <div
                            ng-if="
                                data.displayIntegrationIndication &&
                                (data.selectedField.source || data.fieldOptionsMap[data.selectedField.name].source) &&
                                !data.doNotDisplaySelectedField
                            "
                            class="svg-icon-nav-sm margin-right-xs"
                        >
                            <div
                                class="inline-block mod-width-14 margin-top-xxs mod-sm mod-circle initiative-integration-icon mod-{{
                                    data.selectedField.source || data.fieldOptionsMap[data.selectedField.name].source
                                }}"
                            ></div>
                        </div>
                        {{
                            data.selectedField.label ||
                                data.selectedField.name ||
                                data.placeHolder ||
                                'Search for a field'
                        }}
                    </div>
                </span>
                <i ng-if="data.loadingFieldOptions" class="loading absolute-right margin-right-lg margin-top-xxs"></i>
            </div>

            <!-- If we are required to not display selected field -->
            <div ng-if="data.doNotDisplaySelectedField">
                {{ data.placeHolder || (data.buttonMode ? '' : 'Search for a field') }}
            </div>
        </ui-select-match>

        <!-- Field Choices -->
        <ui-select-choices
            group-by="data.groupBy"
            repeat="p in getFieldOptions($select.search) | filter: {label: $select.search} | orderBy : 'orderBy': false"
        >
            <div class="flex-vmiddle" data-automation="field-selector-field-option-name-{{p.label || ''}}">
                <!-- Integration icon -->
                <span
                    ng-if="data.displayIntegrationIndication && p.source"
                    class="inline-block flex-no-shrink margin-right-xs mod-sm mod-circle initiative-integration-icon mod-{{
                        p.source
                    }}"
                ></span>
                <!-- Choice icon when available. -->
                <span
                    ng-if="p.iconModClass"
                    class="inline-block margin-right-xs mod-sm mod-circle  flex-no-shrink initiative-integration-icon {{
                        p.iconModClass
                    }}"
                ></span>

                <span
                    ng-if="!p.source && !p.definition.integrationType && !p.iconModClass"
                    class="inline-block flex-no-shrink margin-right-xs mod-sm mod-circle"
                >
                    <tnk-field-type-icon field-type="p.fieldType" size="xs"></tnk-field-type-icon>
                </span>

                <!-- Label -->
                <div
                    class="padding-right-md margin-right-xs"
                    data-automation="field-selector-field-option-name"
                    ng-bind-html="(p.entity && p.displayEntity ? p.entity + ': ' : '') + (p.label | highlight: $select.search)"
                    ng-class="{ 'common-color-grey common-italic': p.fieldSelectorClearItem }"
                ></div>
                <!-- Group name -->
                <div ng-if="p.groupName" class="common-size-xxxxxs">({{ p.groupName }})</div>
            </div>
        </ui-select-choices>

        <!-- No Fields Found Note -->
        <ui-select-no-choice>
            <div class="padding-xs common-disabled margin-left-xs">No matching fields</div>
        </ui-select-no-choice>
    </ui-select>

    <!-- Span floating over the input showing that the field is new field -->
    <span ng-if="data.selectedField.isTriggerTemplateData" class="field-select-new-field-badge">NEW</span>

    <!-- Field inspector button for POPOVER (hidden in mobile) -->
    <span
        class="svg-icon-nav-sm field-selector-inspect-icon"
        ng-if="data.fieldInspectPopover"
        ng-click="toggleInspectPopover()"
        ng-class="{ 'hidden-xs': !data.fieldInspectAutoOpen, pointer: !data.viewOnly }"
        uib-popover-template="'field-selector-inspect-template'"
        popover-placement="auto right"
        popover-is-open="data.fieldInspectPopoverOpen"
        popover-trigger="outsideClick"
        popover-class="popover-no-max-width field-selector-inspect-popover"
        popover-append-to-body="true"
    >
        <tnk-icon src="../../../../../apps/tracks/images/icons/search-book.svg"></tnk-icon>
    </span>

    <!-- Field inspector button for MODAL (replaces popover mode in mobile) -->
    <span
        class="svg-icon-nav-sm field-selector-inspect-icon"
        ng-class="{ pointer: !data.viewOnly }"
        ng-if="
            (data.itemsSource === 'EXTERNAL' && data.fieldInspectModal) || ($root.isMobile && data.fieldInspectPopover)
        "
        data-automation="field-selector-open-field-list"
        ng-click="openFieldInspectModal()"
    >
        <tnk-icon src="../../../../../apps/tracks/images/icons/search-book.svg"></tnk-icon>
    </span>

    <!-- Field selector clear button -->
    <span
        class="svg-icon-xxs common-color-grey field-selector-clear-icon pointer"
        ng-if="data.allowClearSelection && data.selectedField && data.selectedField.name"
        uib-tooltip="Clear"
        ng-click="clearSelection()"
    >
        <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>

    <div class="flex mod-justify-end margin-top" ng-if="data.didAutoFillField">
        <button ng-click="fieldSelectionChange()" class="btn btn-primary">Save</button>
    </div>
</div>

<!-- Simple list mode UI -->
<div ng-if="::data.listMode">
    <ul class="dropdown-menu mod-static-open">
        <li ng-repeat="option in data.listModeOptions">
            <a ng-click="selectFieldFromListMode(option)">
                <span class="flex-vmiddle">
                    <!-- Integration icon -->
                    <span
                        ng-if="data.displayIntegrationIndication && option.source"
                        class="inline-block  flex-no-shrink margin-right-xs mod-sm mod-circle initiative-integration-icon mod-{{
                            option.source
                        }}"
                    ></span>
                    <!-- Choice icon when available. -->
                    <span
                        ng-if="option.iconModClass"
                        class="inline-block margin-right-xs mod-sm mod-circle  flex-no-shrink initiative-integration-icon {{
                            option.iconModClass
                        }}"
                    ></span>
                    <!-- Label -->
                    <span
                        ng-bind-html="option.label"
                        class="margin-right-xs"
                        ng-class="{ 'common-color-grey common-italic': option.fieldSelectorClearItem }"
                    ></span>
                    <!-- Group name -->
                    <span ng-if="option.groupName" class="common-size-xxxxxs">({{ option.groupName }})</span>
                </span>
            </a>
        </li>
    </ul>
</div>

<!-- Field inspect popover template -->
<script type="text/ng-template" id="field-selector-inspect-template">
    <div class="padding-normal-xs common-close-btn-container" data-dont-trigger-click-outside>
        <span class="common-close-btn margin-none" ng-click="toggleInspectPopover()"
              tnk-esc-close="toggleInspectPopover()">
            <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
        </span>
        <tnk-field-inspect project-integration="data.projectIntegration"
                           external-type="data.inspectEntityType ? data.inspectEntityType : data.externalType"
                           field-query="data.searchQuery"
                           on-field-select="onFieldSelectedInInspect(field)"
                           only-updatable="data.onlyUpdatableFieldDefinitions">
        </tnk-field-inspect>
    </div>
</script>
