import {lateConstructController} from '@tonkean/angular-components';
import messageBoxModalTemplate
    from './../../../../shared/components/messageBoxModal/messageBoxModal.template.html?angularjs';

/* @ngInject */
function AutonomousTriggerConfigurationCtrl(
    $scope,
    projectManager,
    consts,
    customTriggerManager,
    customFieldsManager,
    utils,
    tonkeanService,
    workflowVersionManager,
    syncConfigCacheManager,
    modal,
    $q,
    $uibModal,
    $state,
    $window,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.ctm = customTriggerManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        customTriggerId: ctrl.customTriggerId,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        control: ctrl.control,
        viewOnly: ctrl.viewOnly,
        isInnerAutonomous: ctrl.isInnerAutonomous,
        selectedLogicTypeConfiguration: consts.getLogicBlockTypes()[ctrl.customTriggerType],
        configuredLogic: null,
        // Scheduled configuration
        recurrenceTimeSelection: null,
        existingRecurrenceTimeSelection: null,
        existingMonitorFieldDefinitions: null,
        monitorInnerItemsCreatedByCustomTriggerId: null,

        monitorInnerItems: null,
        runOneTimeOnly: true,

        // Monitor field definitions
        savingMonitorFieldDefinitions: false,
        errorSavingMonitorFieldDefinitions: null,

        // Monitor forms
        savingMonitorForms: false,
        errorSavingMonitorForms: null,

        // Run trigger now
        sendingRunAutonomousTriggerNow: false,
        errorSendingRunAutonomousTriggerNow: null,
        invalidLogics: ctrl.invalidLogics,
        creatingInnerItemsLogicTypes: utils
            .objToArray(consts.getLogicBlockTypes())
            .filter((item) => item.value.creatingActionItems)
            .map((item) => item.key),
        notAllowedConditionsSet: null,
        environment: $scope.wvm.isDraftVersion ? 'DRAFT' : 'PUBLISH',
        itemInterfaceId: null,
        
        intakeProgressTrackerEnabled: false,
        intakeProgressTrackerTitle: null,
        submitButtonCustomLabel: null,
        showVerticalIntakeProgressTracker: false,

        intakeProgressTrackerOrientationButtons: [
            {
                value: false,
                label: 'Horizontal',
            },
            {
                value: true,
                label: 'Vertical',
            },
        ],
        fieldsToUpdate: [{}],
        showCollaborationPopoverComments: true,
        showCollaborationPopoverConcierge: false,
        setInitialValues: false,
        showInFrontDoor: false,
        showInHomePage: false,
    };

    $scope.onConfiguredFieldsChanged = function (configuredFields, shouldSaveLogic) {
        $scope.data.fieldsToUpdate = configuredFields;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onSetCollaborationPopoverShowCommentsToggle = function () {
        $scope.data.showCollaborationPopoverComments = !$scope.data.showCollaborationPopoverComments;
        $scope.onActionsChanged(true);
    };

    $scope.onSetCollaborationPopoverShowConciergeToggle = function () {
        $scope.data.showCollaborationPopoverConcierge = !$scope.data.showCollaborationPopoverConcierge;
        $scope.onActionsChanged(true);
    };

    $scope.onSetInitialValuesToggle = function () {
        $scope.data.setInitialValues = !$scope.data.setInitialValues;
        $scope.onActionsChanged(true);
    };

    $scope.onShowInFrontDoorToggle = function () {
        $scope.data.showInFrontDoor = !$scope.data.showInFrontDoor;
        $scope.onActionsChanged(true);
    };

    $scope.onShowInHomePageToggle = function () {
        $scope.data.showInHomePage= !$scope.data.showInHomePage;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on changes to the component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.customTriggerId) {
            // Loading custom trigger from cache.
            $scope.data.customTriggerId = ctrl.customTriggerId;
            $scope.data.configuredLogic = $scope.ctm.getCachedCustomTrigger(
                $scope.data.workflowVersionId,
                $scope.data.customTriggerId,
            );

            if ($scope.data.configuredLogic) {
                // Trigger type.
                initializeTriggerType($scope.data.configuredLogic.customTriggerSecondaryType);

                // Monitor items.
                $scope.data.monitorInnerItems = $scope.data.configuredLogic.monitorInnerItems;

                $scope.data.monitorInnerItemsCreatedByCustomTriggerId =
                    $scope.data.configuredLogic.monitorInnerItemsCreatedByCustomTriggerId;

                // Run one time only.
                if (!utils.isNullOrUndefined($scope.data.configuredLogic.runOneTimeOnly)) {
                    $scope.data.runOneTimeOnly = $scope.data.configuredLogic.runOneTimeOnly;
                }

                $scope.data.dontRunOnDone = $scope.data.configuredLogic.dontRunOnDone;
                $scope.data.runAlsoOnNewItems = $scope.data.configuredLogic.runAlsoOnNewItems;

                $scope.data.runAlsoOnIntakeItems = $scope.data.configuredLogic.runAlsoOnIntakeItems;

                // Scheduled trigger.
                $scope.data.existingRecurrenceTimeSelection = $scope.data.configuredLogic;

                // Existing monitor field definitions.
                if (
                    $scope.data.configuredLogic.monitorFieldDefinitions &&
                    $scope.data.configuredLogic.monitorFieldDefinitions.length
                ) {
                    const fieldDefinitionMap = utils.createMapFromArray(
                        customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                        'id',
                    );
                    $scope.data.existingMonitorFieldDefinitions =
                        $scope.data.configuredLogic.monitorFieldDefinitions.map(
                            (fieldDefinition) => fieldDefinitionMap[fieldDefinition.id],
                        );
                }

                if ($scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED') {
                    $scope.data.fieldsToUpdate = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.fieldsToUpdate ?? $scope.data.fieldsToUpdate;
                    $scope.data.setInitialValues = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.setInitialValues ?? $scope.data.setInitialValues;
                    $scope.data.showInFrontDoor = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.showInFrontDoor ?? $scope.data.showInFrontDoor;
                    $scope.data.showInHomePage = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.showInHomePage ?? $scope.data.showInHomePage;
                    $scope.data.showCollaborationPopoverComments = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.showCollaborationPopoverComments ?? $scope.data.showCollaborationPopoverComments;
                    $scope.data.showCollaborationPopoverConcierge = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.showCollaborationPopoverConcierge ?? $scope.data.showCollaborationPopoverConcierge;
                    $scope.data.itemInterfaceId = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.itemInterfaceId ?? $scope.data.item;
                }


                if ($scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM' || $scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED') {
                    if ($scope.data.configuredLogic?.customTriggerActions?.[0]?.customTriggerActionDefinition?.intakeProgressTrackerEnabled) {
                        $scope.data.intakeProgressTrackerEnabled = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.intakeProgressTrackerEnabled;
                    }

                    if ($scope.data.configuredLogic?.customTriggerActions?.[0]?.customTriggerActionDefinition?.intakeProgressTrackerTitle) {
                        $scope.data.intakeProgressTrackerTitle = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.intakeProgressTrackerTitle;
                    }

                    if ($scope.data.configuredLogic?.customTriggerActions?.[0]?.customTriggerActionDefinition?.submitButtonCustomLabel) {
                        $scope.data.submitButtonCustomLabel = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.submitButtonCustomLabel;
                    }

                    if ($scope.data.configuredLogic?.customTriggerActions?.[0]?.customTriggerActionDefinition?.showVerticalIntakeProgressTracker) {
                        $scope.data.showVerticalIntakeProgressTracker = $scope.data.configuredLogic.customTriggerActions?.[0]?.customTriggerActionDefinition?.showVerticalIntakeProgressTracker;
                    }
                }
            }
        }

        if (changesObj.isInnerAutonomous) {
            $scope.data.isInnerAutonomous = ctrl.isInnerAutonomous;
        }
    };

    $scope.changeAutonomousType = function (selectedType) {
        const logicBlockTypes = consts.getLogicBlockTypes();
        const logic = $scope.ctm.findLogicDataInGraphById(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.id,
        )?.logic;

        const isFormAutonomousTrigger =
            $scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM';
        const changingToFormAutonomousTrigger = selectedType.secondaryType === 'AUTONOMOUS_CREATED_FROM_FORM';
        const childFormAnswered = logic?.impacts.find(
            ({node}) => (node.customTriggerType ?? node.type) === logicBlockTypes.SEND_FORM_ANSWERED.type,
        );
        const isInterfaceSubmittedTrigger =
            $scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED';

        if (isInterfaceSubmittedTrigger) {
            let sendFormAnsweredTriggers = $scope.ctm.getCustomTriggersOfTypeInWorkflowVersionFromCache($scope.data.workflowVersionId, 'SEND_FORM_ANSWERED');
            sendFormAnsweredTriggers = sendFormAnsweredTriggers.filter(trigger => trigger.customTriggerActions[0].customTriggerActionDefinition.selectedActionType === 'go-to-sequence'
                && trigger.customTriggerActions[0].customTriggerActionDefinition.goToSequenceCustomTriggerId === $scope.data.customTriggerId);
            if (sendFormAnsweredTriggers.length > 0) {
                $scope.mboxData = {
                    title: 'You cannot change the type of this trigger',
                    body: `Changing the type of this trigger is not allowed because the following actions are dependent on it: ${sendFormAnsweredTriggers.map(trigger => `\"${trigger.displayName}\"`).join(', ')}`,
                    isWarn: true,
                    cancelLabel: 'Close',
                };
                $uibModal.open({
                    template: messageBoxModalTemplate,
                    scope: $scope,
                    size: 'md',
                    windowClass: 'mod-danger',
                });
                return false;
            }
        }

        if (!changingToFormAutonomousTrigger && (isFormAutonomousTrigger || isInterfaceSubmittedTrigger) && childFormAnswered) {
            $scope.mboxData = {
                title: 'You cannot change the type of this trigger',
                body: `Changing the type of a form filled trigger is not allowed because you have ${
                    childFormAnswered?.name ?? 'when form answered'
                } as a child`,
                isWarn: true,
                cancelLabel: 'Close',
            };

            $uibModal.open({
                template: messageBoxModalTemplate,
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            });

            return false;
        }

        resetAutonomousTriggerConfiguration();
        initializeTriggerType(selectedType.secondaryType);
        return $scope.onAutonomousTriggerConfigurationChanged();
    };

    function resetAutonomousTriggerConfiguration() {
        $scope.data.configuredLogic.isScheduled = false;
        $scope.data.configuredLogic.monitorFieldDefinitions = [];
        $scope.data.existingMonitorFieldDefinitions = [];
        $scope.data.configuredLogic.monitorForms = [];
        $scope.data.runOneTimeOnly = false;
    }

    /**
     * Occurs once a trigger type is selected.
     */
    $scope.onTriggerTypeSelected = function (triggerType) {
        $scope.data.selectedTriggerType = triggerType;
        return $scope.onAutonomousTriggerConfigurationChanged();
    };

    /**
     * Occurs on recurrence time selection object change.
     */
    $scope.onRecurrenceTimeSelectionChanged = function (recurrenceTimeSelection) {
        $scope.data.existingRecurrenceTimeSelection = recurrenceTimeSelection;
        $scope.onAutonomousTriggerConfigurationChanged();
    };

    /**
     * Occurs once monitor fields are changed.
     */
    $scope.onMonitorFieldsChanged = function (monitorFieldDefinitions) {
        const nonNullMonitorFieldDefinitions = [];

        const uniqueMap = {};
        for (const fieldDef of monitorFieldDefinitions) {
            if (fieldDef && fieldDef.id && !uniqueMap[fieldDef.id]) {
                nonNullMonitorFieldDefinitions.push(fieldDef);
                uniqueMap[fieldDef.id] = true;
            }
        }

        $scope.data.existingMonitorFieldDefinitions = nonNullMonitorFieldDefinitions;
        $scope.onAutonomousTriggerConfigurationChanged();
    };

    /**
     * Occurs once a custom interface is selected from the dropdown.
     */
    $scope.onItemInterfaceSelected = function (itemInterfaceId) {
        $scope.data.itemInterfaceId = itemInterfaceId;

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once intake status tracker enabled is toggled
     */
    $scope.onIntakeProgressTrackerEnabledChanged = function (value) {
        $scope.data.intakeProgressTrackerEnabled = value;

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once intake status tracker title is changed
     */
    $scope.onIntakeStatusTrackerTitleChanged = function (intakeProgressTrackerTitle) {
        $scope.data.intakeProgressTrackerTitle = intakeProgressTrackerTitle;

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once submit button custom label changes
     */
    $scope.onSubmitButtonLabelChanged = function (submitButtonCustomLabel) {
        $scope.data.submitButtonCustomLabel = submitButtonCustomLabel;

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once show vertical/horizontal intake status tracker is toggled
     */
    $scope.onShowVerticalIntakeProgressTracker = function (showVerticalIntakeProgressTracker) {
        $scope.data.showVerticalIntakeProgressTracker = showVerticalIntakeProgressTracker;

        return $scope.onActionsChanged(true);
    };

    /**
     * Returns the selected intake progress tracker orientation button info
     */

    $scope.getSelectedIntakeProgressTrackerOrientationButton = function () {
        return $scope.data.intakeProgressTrackerOrientationButtons.find(
            (button) => button.value === $scope.data.showVerticalIntakeProgressTracker,
        );
    }

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const definition = {}
            if ($scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED') {
                definition.itemInterfaceId = $scope.data.itemInterfaceId;
                definition.fieldsToUpdate = $scope.data.fieldsToUpdate;
                definition.setInitialValues = $scope.data.setInitialValues;
                definition.showInFrontDoor = $scope.data.showInFrontDoor;
                definition.showInHomePage = $scope.data.showInHomePage;
                definition.showCollaborationPopoverComments = $scope.data.showCollaborationPopoverComments;
                definition.showCollaborationPopoverConcierge = $scope.data.showCollaborationPopoverConcierge;
            }

            if ($scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM' ||
                $scope.data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED') {
                definition.intakeProgressTrackerEnabled = $scope.data.intakeProgressTrackerEnabled;
                definition.intakeProgressTrackerTitle = $scope.data.intakeProgressTrackerTitle;
                definition.submitButtonCustomLabel = $scope.data.submitButtonCustomLabel;
                definition.showVerticalIntakeProgressTracker = $scope.data.showVerticalIntakeProgressTracker;
            }

            const actionDefinition = {
                actions: [
                    {
                        definition,
                        type: 'AUTONOMOUS',
                    },
                ],
            };

            return ctrl.onActionsChanged({
                definition: actionDefinition,
                shouldSaveLogic,
            })
        }
    };

    /**
     * Occurs once monitor forms are changed.
     */
    $scope.onMonitorFormsChanged = function (monitorForms) {
        const nonNullMonitorForms = [];

        const uniqueMap = {};
        for (const monitorForm of monitorForms) {
            if (monitorForm && monitorForm.id && !uniqueMap[monitorForm.id]) {
                nonNullMonitorForms.push(monitorForm);
                uniqueMap[monitorForm.id] = true;
            }
        }

        $scope.data.configuredLogic.monitorForms = nonNullMonitorForms;
        $scope.onAutonomousTriggerConfigurationChanged();
    };

    /**
     * Occurs once monitor inner items flag is changed.
     */
    $scope.onMonitorInnerItemsChanged = function (value) {
        $scope.data.monitorInnerItems = value;

        // We are blocking monitor inner item creation if this autonomous trigger has assign action items as an inner child in order to prevent endless loop
        if ($scope.data.monitorInnerItems && hasInnerItemsCreatorChild()) {
            $scope.$emit('alert', {
                msg: 'In order to prevent circular bot running, you cannot configure a trigger to run on action items, if the actions proceeding it create action items as well.',
                type: 'error',
            });
            $scope.data.monitorInnerItems = false;
            return $q.reject();
        } else {
            $scope.onAutonomousTriggerConfigurationChanged();
            return $q.resolve();
        }
    };

    /**
     * @returns {boolean} - Indicating whether this autonomous trigger has "Assign action items" as an inner child
     */
    function hasInnerItemsCreatorChild() {
        // Using logicInTree and not configureLogic because configureLogic object dont have the impacts property.
        const logicInTree = $scope.ctm.findLogicDataInGraphById(
            $scope.data.workflowVersionId,
            $scope.data.customTriggerId,
        ).logic;

        return $scope.ctm.anyLogicChildren(
            logicInTree,
            (logic) =>
                logic &&
                logic.node &&
                logic.node.customTriggerType &&
                (logic.node.customTriggerType === consts.getLogicBlockTypes()['MANUAL_NEXT_STEPS'].type ||
                    logic.node.customTriggerType === consts.getLogicBlockTypes()['SYNC_INNER_MATCHED_ENTITY'].type),
        );
    }

    /**
     * Occurs once the run one time only is changed.
     */
    $scope.onRunOneTimeOnlyChanged = function (value) {
        $scope.data.runOneTimeOnly = value;
        $scope.onAutonomousTriggerConfigurationChanged();
        return $q.resolve();
    };

    /**
     * Occurs once the filters for the autonomous trigger are changed.
     */
    $scope.onFilterChange = function (shouldNotSaveLogic) {
        $scope.onAutonomousTriggerConfigurationChanged(shouldNotSaveLogic);
    };

    /**
     * Occurs on change of the trigger configuration.
     */
    $scope.onAutonomousTriggerConfigurationChanged = function (shouldNotSaveLogic) {
        if (ctrl.onAutonomousTriggerConfigurationChanged) {
            return ctrl.onAutonomousTriggerConfigurationChanged({
                autonomousTriggerConfiguration: {
                    selectedTriggerType: $scope.data.selectedTriggerType,
                    recurrenceTimeSelection: $scope.data.existingRecurrenceTimeSelection,
                    monitorFieldDefinitions:
                        $scope.data.selectedTriggerType === 'ON_FIELD_CHANGE'
                            ? $scope.data.existingMonitorFieldDefinitions
                            : [],
                    monitorForms:
                        $scope.data.selectedTriggerType === 'ON_INITIATIVE_CREATED_BY_FORM'
                            ? $scope.data.configuredLogic.monitorForms
                            : [],
                    monitorInnerItems: $scope.data.monitorInnerItems,
                    runOneTimeOnly: $scope.data.runOneTimeOnly,
                    customTriggerSecondaryType: $scope.data.customTriggerSecondaryType,
                    dontRunOnDone: $scope.data.dontRunOnDone,
                    shouldNotSaveLogic,
                    monitorInnerItemsCreatedByCustomTriggerId: $scope.data.monitorInnerItemsCreatedByCustomTriggerId,
                    runAlsoOnNewItems: $scope.data.runAlsoOnNewItems,
                    runAlsoOnIntakeItems: $scope.data.runAlsoOnIntakeItems,
                },
            });
        }
    };

    $scope.onDontRunOnDoneChange = function (value) {
        $scope.data.dontRunOnDone = value;
        return $scope.onAutonomousTriggerConfigurationChanged();
    };

    $scope.onRunAlsoOnNewItemsChange = function (value) {
        $scope.data.runAlsoOnNewItems = value;
        return $scope.onAutonomousTriggerConfigurationChanged();
    };

    $scope.onRunAlsoOnIntakeItemsChange = function (value) {
        $scope.data.runAlsoOnIntakeItems = value;
        return $scope.onAutonomousTriggerConfigurationChanged();
    };

    $scope.onCustomTriggerSelected = function (selectedCustomTrigger, $index) {
        $scope.data.monitorInnerItemsCreatedByCustomTriggerId = selectedCustomTrigger?.id;
        $scope.onAutonomousTriggerConfigurationChanged(false);
    };

    /**
     * Runs an autonomous trigger immediately.
     */
    $scope.runAutonomousTriggerNow = function () {
        $scope.questionConfirmModalData = {
            title: 'Run scheduled trigger now',
            body: 'This action will cause all the current items in the list to run now. Are you sure you want to proceed?',
            okLabel: 'Run',
            cancelLabel: 'Cancel',
        };

        return modal
            .openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning',
            })
            .result.then(() => {
                $scope.data.sendingRunAutonomousTriggerNow = true;
                $scope.data.errorSendingRunAutonomousTriggerNow = null;

                return tonkeanService
                    .runScheduledAutonomousCustomTriggerNow(
                        $scope.data.workflowVersionId,
                        $scope.data.configuredLogic.id,
                    )
                    .then(() => {
                        $scope.$emit('alert', {msg: 'Trigger Executed.', type: 'success'});
                    })
                    .catch(() => {
                        $scope.data.errorSendingRunAutonomousTriggerNow = 'Error trying to run scheduled trigger.';
                    })
                    .finally(() => {
                        $scope.data.sendingRunAutonomousTriggerNow = false;
                    });
            });
    };

    /**
     * It will add 0 to the number in necessary, to make it 2 digit number
     *
     * @param num {number|string} - the number to pad
     * @returns {string} - the padded number
     */
    $scope.padZeros = (num = '') => {
        return `00${num}`.slice(-2);
    };

    function createIntakeLink() {
        return new URL(
            $state.href('product.intakeInterface', {
                projectId: $scope.pm.project.id,
                customTriggerId: $scope.data.customTriggerId,
                workflowVersionType: $scope.wvm.isDraftVersion($scope.data.workflowVersionId) ? 'DRAFT' : 'PUBLISHED',
            }),
            window.location.origin,
        ).href
    }

    $scope.goToIntakePage = function () {
        var url = createIntakeLink();

        $window.open(url, '_blank');
    }

    $scope.copyIntakeLinkToClipboard = function () {
        var url = createIntakeLink();

        navigator.clipboard.writeText(url);

        $scope.$emit('alert', {
            type: 'success',
            msg: `The link has been successfully copied`,
        });
    };

    /**
     * Initialize trigger type (scheduled, on field change, etc). The type can be inferred by the secondary type (Optional)
     */
    function initializeTriggerType(secondaryType) {
        if ($scope.data.configuredLogic.isScheduled || secondaryType === 'AUTONOMOUS_SCHEDULE') {
            $scope.data.selectedTriggerType = 'ON_SCHEDULE';
            $scope.data.notAllowedConditionsSet = {
                Contains: true,
                NotContains: true,
            };
        } else if (
            ($scope.data.configuredLogic.monitorFieldDefinitions &&
                $scope.data.configuredLogic.monitorFieldDefinitions.length) ||
            secondaryType === 'AUTONOMOUS_FIELD_CHANGED'
        ) {
            $scope.data.selectedTriggerType = 'ON_FIELD_CHANGE';
        } else
            switch (secondaryType) {
                case 'AUTONOMOUS_ITEM_CREATED': {
                    $scope.data.selectedTriggerType = 'AUTONOMOUS_ITEM_CREATED';

                    break;
                }
                case 'USER_INTERFACE_BUTTON_CLICK': {
                    $scope.data.selectedTriggerType = 'USER_INTERFACE_BUTTON_CLICK';

                    break;
                }
                case 'AUTO_CLOSE': {
                    $scope.data.selectedTriggerType = 'AUTO_CLOSE';

                    break;
                }
                default:
                    if (
                        ($scope.data.configuredLogic.monitorForms && $scope.data.configuredLogic.monitorForms.length) ||
                        secondaryType === 'AUTONOMOUS_CREATED_FROM_FORM'
                    ) {
                        $scope.data.selectedTriggerType = 'ON_INITIATIVE_CREATED_BY_FORM';
                    } else {
                        $scope.data.selectedTriggerType = 'AUTONOMOUS_MATCH_CONDITIONS';
                    }
            }

        $scope.data.customTriggerSecondaryType = secondaryType;
    }
}

export default angular
    .module('tonkean.app')
    .controller('AutonomousTriggerConfigurationCtrl', lateConstructController(AutonomousTriggerConfigurationCtrl));
