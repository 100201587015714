import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

const getFilterTypeOfCustomField = (fieldDefinition: FieldDefinition) => {
    switch (fieldDefinition.fieldType) {
        case FieldType.Number:
            return 'agNumberColumnFilter';
        case FieldType.Date:
            return 'agDateColumnFilter';
        default:
            return 'agTextColumnFilter';
    }
};

export default getFilterTypeOfCustomField;
