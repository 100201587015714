import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkflowFolderSelectorCtrl($scope, $rootScope, workflowFolderManager, projectManager, utils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        isRequired: ctrl.isRequired,
        hideOptionsIds: ctrl.hideOptionsIds || [],
        selectedWorkflowFolder: ctrl.selectedWorkflowFolder,
        onlyOfMaker: ctrl.onlyOfMaker,
        workflowFolderOptions: [],
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        workflowFolderManager.getWorkflowFolders(projectManager.project.id, false).then((folders) => {
            $scope.data.workflowFolderOptions = folders.filter((folder) => {
                const isMaker =
                    !$scope.data.onlyOfMaker || folder.owners.some((owner) => owner.id === $scope.data.onlyOfMaker);
                return !$scope.data.hideOptionsIds.includes(folder.id) && isMaker;
            });

            if ($scope.data.groupId) {
                const workflowFolderId = workflowFolderManager.getContainingWorkflowFolder(
                    projectManager.project.id,
                    $scope.data.groupId,
                )?.id;
                if (workflowFolderId) {
                    $scope.data.selectedWorkflowFolder = utils.findFirstById(
                        $scope.data.workflowFolderOptions,
                        workflowFolderId,
                    );
                    $scope.folderSelectionChange();
                }
            }

            if ($scope.data.selectedWorkflowFolder) {
                $scope.folderSelectionChange();
            }
        });
    };

    $scope.folderSelectionChange = function () {
        ctrl.onFolderSelected({ folder: $scope.data.selectedWorkflowFolder });
    };
}

export default angular
    .module('tonkean.app')
    .controller('WorkflowFolderSelectorCtrl', lateConstructController(WorkflowFolderSelectorCtrl));
