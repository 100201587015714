<div class="tnk-video">
    <div class="rounded-box padding-normal-sm">
        <div>
            <div>
                <div style="position: relative; padding-bottom: 62.5%; height: 0">
                    <iframe
                        ng-src="{{ data.currentTipVideo }}"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                    ></iframe>
                </div>
            </div>
            <p class="margin-top text-center">{{ data.videos[data.tipIndex].title }}</p>
            <div class="flex-vmiddle common-color-light-grey">
                <div class="pointer" ng-click="selectNextTip(-1)">
                    <i class="fa fa-chevron-left"></i>
                </div>
                <div class="flex-grow text-center">
                    <i
                        class="pointer fa fa-circle margin-right-xs"
                        ng-repeat="tip in data.videos"
                        ng-click="selectTip($index)"
                        ng-class="{ 'common-color-primary': $index === data.tipIndex }"
                    ></i>
                </div>
                <div class="pointer" ng-click="selectNextTip(1)">
                    <i class="fa fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</div>
