import defaultTonkeanExpression from './DefaultTonkeanExpression';

import { defaultFormColors } from '@tonkean/infrastructure';
import type { CreateForm, FormBase, UpdateForm } from '@tonkean/tonkean-entities';
import { BorderViewType, FormQuestionType, FormType, ItemsHierarchyFilter } from '@tonkean/tonkean-entities';

const initialValueBase: FormBase = {
    displayName: 'New Form',
    id: '',
    formQuestionType: FormQuestionType.UPDATE_FIELDS,
    slackCommand: undefined,
    descriptionExpression: defaultTonkeanExpression,
    expandableDescriptionExpression: defaultTonkeanExpression,
    collapseDescriptionByDefault: false,
    disabled: false,
    definition: {
        fillFormButtonLabel: '',
        submitFormButtonLabel: '',
        formQuestion: '',
        useAutoGeneratedFormQuestion: true,
        addItemButtonLabel: '',
        fields: [],
        addUnderInitiativeId: null,
        autoSubmitForm: false,
        expandByDefault: false,
        emphasisRequiredFields: false,
        canSkipUpload: false,
        dataStorageId: '',
        destinationFolderId: defaultTonkeanExpression,
        itemsHierarchyFilter: ItemsHierarchyFilter.SINGLE_ITEM,
        logoUrl: '',
        ...defaultFormColors,
        borderViewType: BorderViewType.SINGLE_VIEW,
    },
    formType: FormType.CREATE_OR_UPDATE,
    created: 0,
    updated: 0,
};

export const formBuilderConfigurationCreateFormInitialValue: CreateForm = {
    ...initialValueBase,
    formType: FormType.CREATE,
    formQuestionType: FormQuestionType.UPDATE_FIELDS,
    homepageEnabled: true,
};

export const formBuilderConfigurationUpdateFormInitialValue: UpdateForm = {
    ...initialValueBase,
    formType: FormType.UPDATE,
    slackCommand: '',
};
