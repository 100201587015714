<div class="ask-person-field-update-configuration">
    <tnk-person-inquiry-logic-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        preview-evaluation-source="data.previewEvaluationSource"
        configured-logic="data.configuredLogic"
        invalid-logics="data.invalidLogics"
        include-form-attachment="false"
        include-field-attachment="true"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
        special-fields-for-features="['ASK_PERSON_FIELD_UPDATE']"
    ></tnk-person-inquiry-logic-configuration>
</div>
