import { useMemo } from 'react';

import { type BasicQueryDefinition, type FieldFilter, FieldQueryType } from '@tonkean/tonkean-entities';

const useEndUserFiltersQueryDefinition = (
    endUserFilters: FieldFilter[],
    endUserAdvancedQuery: BasicQueryDefinition,
): BasicQueryDefinition => {
    return useMemo(() => {
        return {
            query: {
                type: FieldQueryType.And,
                filters: [...endUserFilters],
                queries: [{ ...endUserAdvancedQuery.query }],
            },
        };
    }, [endUserAdvancedQuery, endUserFilters]);
};

export default useEndUserFiltersQueryDefinition;
