import template from './tnkCreateTrackActionDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkCreateTrackActionDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        workflowVersionType: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        definition: '<', // The selected action definition.
        validationObject: '<',
        onDefinitionChanged: '&',
        groupOptionIds: '<',
    },
    template,
    controller: 'CreateTrackActionDefinitionCtrl',
});
