import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import fetchAndImportRequestsHelper from './FetchAndImportRequestHelper';
import WsdlImportedOperationsView from './WsdlImportedOperationsView';
import type WsdlWithOperations from './WsdlTypes';
import type { FileWsdl, UrlWsdl } from './WsdlTypes';
import { WsdlOption } from './WsdlTypes';
import { ReactComponent as UploadFileIcon } from '../../../../../../images/icons/upload-12px.svg';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import {
    DragAndDropModal,
    Input,
    LoadingCircle,
    ModalBody,
    ModalFooter,
    ModalHeader,
    XCloseButton,
} from '@tonkean/infrastructure';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const InlineRow = styled.div`
    display: inline-flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 20px;
`;

const StyledXCloseButton = styled(XCloseButton)`
    margin-left: auto;
`;

const StyledModalHeader = styled(ModalHeader)`
    display: inline-flex;
`;

const StyledModalBody = styled(ModalBody)`
    flex-direction: column;
    display: flex;
    gap: 25px;
    padding: 5px 0 0 0;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
    font-size: ${FontSize.SMALL_12};
    margin-right: auto !important;
`;
const ImportButton = styled(Button)`
    padding-left: 16px;
    padding-right: 16px;
    min-width: 156px;
`;

const InputWithButton = styled.div`
    display: inline-flex;
    flex-grow: 1;
`;

const WsdlUrlInput = styled(Input)`
    border-right: none;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const WsdlUrlButton = styled(Button)`
    border: 1px solid ${Theme.colors.gray_400};
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const GreyedText = styled.div`
    color: ${Theme.colors.gray_500};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 26px;
`;

interface Props {
    projectIntegrationId: string;
    onImportCompleted: () => void;
}

const ImportCustomActionFromWsdlModal: React.FC<Props> = ({ projectIntegrationId, onImportCompleted }) => {
    // Indicated the type of the wsdl with the with a list of selected operations.
    const [wsdlWithOperations, setWsdlWithOperations] = useState<WsdlWithOperations>();
    // Indicates the wsdl for the input element.
    const [wsdlUrlToImport, setWsdlUrlToImport] = useState<string>('');
    // Indicates whether the drag and drop modal open.
    const [isDragAndDropWsdlFileOpen, setIsDragAndDropWsdlFileOpen] = useState<boolean>(false);

    const tonkeanService = useAngularService('tonkeanService');

    // Helper for fetch and import server commands.
    const fetchAndImportRequests = useMemo(() => {
        return fetchAndImportRequestsHelper(tonkeanService);
    }, [tonkeanService]);

    const [
        { loading: isLoadingGetOperationsNames, error: errorGetOperationNames, data: operationNamesResponse },
        getOperationNames,
    ] = useLazyAsyncMethod(fetchAndImportRequests, 'requestOperations');

    const [
        {
            loading: isLoadingImportProjectIntegrationCustomActionFromWsdl,
            error: errorImportProjectIntegrationCustomActionsFromWsdl,
        },
        importProjectIntegrationCustomActionsFromWsdl,
    ] = useLazyAsyncMethod(fetchAndImportRequests, 'importOperations');

    // On clicking the import button.
    const onClickImport = useCallback(async () => {
        if (wsdlWithOperations?.wsdl) {
            await importProjectIntegrationCustomActionsFromWsdl(projectIntegrationId, wsdlWithOperations);
            onImportCompleted();
        }
    }, [importProjectIntegrationCustomActionsFromWsdl, onImportCompleted, projectIntegrationId, wsdlWithOperations]);

    // When executing fetch From Url,
    // We Executing Fetch + We Save the fetched URL
    const onClickFetchFromUrl = () => {
        const newWsdlWithEmptyOperations: UrlWsdl = { type: WsdlOption.URL, wsdl: wsdlUrlToImport, operations: [] };
        getOperationNames(projectIntegrationId, newWsdlWithEmptyOperations);
        setWsdlWithOperations(newWsdlWithEmptyOperations);
    };

    // When executing fetch from file,
    // We Executing Fetch + We Save the fetched File + closing modal
    const onClickFetchFromFile = (blob: Blob) => {
        const newWsdlWithEmptyOperations: FileWsdl = { type: WsdlOption.FILE, wsdl: blob, operations: [] };
        setWsdlWithOperations(newWsdlWithEmptyOperations);
        getOperationNames(projectIntegrationId, newWsdlWithEmptyOperations);

        setIsDragAndDropWsdlFileOpen(false);
    };

    // Indicates whether error occurred or not.
    const errorMessage = useMemo(() => {
        const error = errorGetOperationNames || errorImportProjectIntegrationCustomActionsFromWsdl;
        if (error) {
            return getStateError(error, {
                // When we fetch or import from url the server wont throw indicative error.
                overrideErrorMessage:
                    wsdlWithOperations?.type === WsdlOption.URL
                        ? 'Unable to download file. Make sure it is valid and the WSDL file is not larger than 5MB.'
                        : undefined,
            });
        }
    }, [errorGetOperationNames, errorImportProjectIntegrationCustomActionsFromWsdl, wsdlWithOperations?.type]);

    return (
        <>
            <StyledModalHeader $border={false}>
                Import actions From WSDL
                <StyledXCloseButton size={ButtonSize.MEDIUM} />
            </StyledModalHeader>

            <StyledModalBody>
                <InlineRow>
                    <Button
                        size={ButtonSize.MEDIUM}
                        onClick={() => setIsDragAndDropWsdlFileOpen(true)}
                        flex
                        highlighted
                    >
                        <UploadFileIcon />
                        Upload File
                    </Button>

                    <GreyedText>Or</GreyedText>

                    <InputWithButton>
                        <WsdlUrlInput
                            size={InputSize.MEDIUM}
                            onChange={({ target }) => setWsdlUrlToImport(target.value)}
                            value={wsdlUrlToImport}
                            placeholder="Enter URL of WSDL file"
                        />
                        <WsdlUrlButton
                            size={ButtonSize.MEDIUM}
                            shape={ButtonShape.RECTANGULAR}
                            disabled={!wsdlUrlToImport}
                            onClick={onClickFetchFromUrl}
                            highlighted
                        >
                            Fetch
                        </WsdlUrlButton>
                    </InputWithButton>
                </InlineRow>

                <WsdlImportedOperationsView
                    operationsList={operationNamesResponse?.operations}
                    setWsdlWithOperations={setWsdlWithOperations}
                    isLoading={isLoadingGetOperationsNames}
                />
            </StyledModalBody>

            <ModalFooter gap={10} align="right" shadow>
                {isLoadingImportProjectIntegrationCustomActionFromWsdl && <LoadingCircle />}

                {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

                <ImportButton
                    disabled={
                        !wsdlWithOperations?.wsdl ||
                        wsdlWithOperations.operations.length === 0 ||
                        isLoadingImportProjectIntegrationCustomActionFromWsdl
                    }
                    onClick={onClickImport}
                >
                    Import Selected ({wsdlWithOperations?.operations.length || 0})
                </ImportButton>
            </ModalFooter>

            <DragAndDropModal
                modalHeader="Upload WSDL file"
                dragAndDropMainText="Drag WSDL file here to upload"
                acceptedFileTypes=".wsdl"
                isImageUpload={false}
                maximumFiles={1}
                isOpen={isDragAndDropWsdlFileOpen}
                onCancel={() => setIsDragAndDropWsdlFileOpen(false)}
                onSave={(files) => files[0]?.blob && onClickFetchFromFile(files[0].blob)}
            />
        </>
    );
};

export default ImportCustomActionFromWsdlModal;
