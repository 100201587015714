import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const EditButton = styled(Button)`
    cursor: pointer !important;
`;

interface Props {
    value: string;
    projectIntegration: ProjectIntegration;
}

const ViewDataEditCellRenderer: React.FC<Props> = ({ value = '', projectIntegration }) => {
    const modalUtils = useAngularService('modalUtils');
    const tonkeanService = useAngularService('tonkeanService');
    const project = useProject();

    const { data: externalActivity } = useAsyncMethod(
        tonkeanService,
        'getExternalActivityById',
        project.id,
        projectIntegration.id,
        value,
        true,
    );

    if (!value) {
        return (
            <Button disabled outlined>
                Edit
            </Button>
        );
    }

    return (
        <>
            <EditButton
                onClick={() =>
                    modalUtils.openEditCustomWebhookItemModal(projectIntegration, externalActivity?.originalEntity)
                }
                size={ButtonSize.SMALL}
                outlined
            >
                Edit
            </EditButton>
        </>
    );
};

export default ViewDataEditCellRenderer;
