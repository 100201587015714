import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';

import type { AsyncMethodRequestState } from './asyncMethod';
import useAsyncMethod from './asyncMethod/useAsyncMethod';

import type { TonkeanService } from '@tonkean/shared-services';
import type { KeysThatExtend } from '@tonkean/utils';

/**
 * React hook for using the Tonkean service in a react way. It will trigger the method immediately on component load,
 * and will change automatically when one of the params changes.
 *
 * @example
 * const CustomTrigger = ({customTriggerId}) => {
 *     const {data, error, loading} = useTonkeanService('getCustomTrigger', customTriggerId);
 *
 *     if (loading) {
 *         return <span className="loading" />;
 *     }
 *     if (error) {
 *         return <strong className="error">{ error.message }</strong>;
 *     }
 *     return <strong>{data.name}</strong>;
 * }
 *
 * @param methodName - the tonkean service method name to use
 * @param args - the arguments to pass to the service
 * @returns an object with the value if the method resolves as data, the reason if the method rejects as error
 * and a boolean indicating if the method is currently loading as loading.
 */
function useTonkeanService<E = any, T extends KeysThatExtend<TonkeanService> = any>(
    methodName: T,
    ...args: Parameters<TonkeanService[T]>
): AsyncMethodRequestState<IHttpResponse<E>, TonkeanService, T> {
    const tonkeanService = useAngularService('tonkeanService');

    return useAsyncMethod(tonkeanService, methodName, ...args);
}

export default useTonkeanService;
