import React, { useContext } from 'react';
import styled from 'styled-components';

import SQLDatabaseIntegrationConfigurationContext from './SQLDatabaseIntegrationConfigurationContext';
import SQLDatabaseQueryConfigurationEntityIdSection from './SQLDatabaseQueryConfigurationEntityIdSection';
import SQLDatabaseQueryConfigurationPaginationSection from './SQLDatabaseQueryConfigurationPaginationSection';
import SQLDatabaseQueryConfigurationSqlQuerySection from './SQLDatabaseQueryConfigurationSqlQuerySection';
import SQLDatabaseQueryConfigurationTimestampsSection from './SQLDatabaseQueryConfigurationTimestampsSection';
import SQLDatabaseQueryConfigurationTitleSection from './SQLDatabaseQueryConfigurationTitleSection';
import SQLDatabaseQueryContainer from './SQLDatabaseQueryContainer';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

import { TrashFormIcon } from '@tonkean/svg';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const Separator = styled.hr`
    margin-top: 8px;
    margin-bottom: 10px;
`;

const TrashIconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const TrashIcon = styled.button`
    border: none;
    background-color: transparent;
    outline: none;
`;

const ConfigurationSectionTitle = styled.div`
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
`;

const ConfigurationSectionContainer = styled.div`
    margin-top: 10px;
`;
const SQLDatabaseQueryConfigurationForm: React.FC<{
    queryConfiguration: SQLDatabaseQueryConfiguration;
    index: number;
    integrationId: string;
    projectIntegration: ProjectIntegration | undefined;
}> = ({ queryConfiguration, index, integrationId, projectIntegration }) => {
    const { changeExpanded, removeQuery } = useContext(SQLDatabaseIntegrationConfigurationContext);

    return (
        <SQLDatabaseQueryContainer
            data-automation="sql-database-query-configuration-form"
            expanded={queryConfiguration.expanded}
            onClick={() => !queryConfiguration.expanded && changeExpanded(true, index)}
            key={queryConfiguration.queryId}
        >
            <SQLDatabaseQueryConfigurationTitleSection index={index} queryConfiguration={queryConfiguration} />

            {queryConfiguration.expanded && (
                <>
                    <Separator />

                    <SQLDatabaseQueryConfigurationSqlQuerySection
                        integrationId={integrationId}
                        queryConfiguration={queryConfiguration}
                        index={index}
                        projectIntegration={projectIntegration}
                    />

                    <ConfigurationSectionTitle>Map Fields</ConfigurationSectionTitle>
                    <ConfigurationSectionContainer>
                        <SQLDatabaseQueryConfigurationEntityIdSection
                            queryConfiguration={queryConfiguration}
                            index={index}
                        />

                        <SQLDatabaseQueryConfigurationTimestampsSection
                            queryConfiguration={queryConfiguration}
                            index={index}
                        />
                    </ConfigurationSectionContainer>

                    <ConfigurationSectionTitle>Settings</ConfigurationSectionTitle>
                    <ConfigurationSectionContainer>
                        <SQLDatabaseQueryConfigurationPaginationSection
                            queryConfiguration={queryConfiguration}
                            index={index}
                        />
                    </ConfigurationSectionContainer>

                    <TrashIconWrapper>
                        <TrashIcon className="svg-icon-smd" onClick={() => removeQuery(index)}>
                            <span className="tnk-icon">
                                <TrashFormIcon />
                            </span>
                        </TrashIcon>
                    </TrashIconWrapper>
                </>
            )}
        </SQLDatabaseQueryContainer>
    );
};

export default SQLDatabaseQueryConfigurationForm;
