import { SlackAuthenticationType } from '@tonkean/tonkean-entities';
import { analyticsWrapper } from '@tonkean/analytics';

/**
 * Helps querying the communication integrations we handle in the system for features, etc.
 */
function CommunicationIntegrationsConnectService(
    $rootScope,
    $state,
    consts,
    createProjectApis,
    oauthHandler,
    projectManager,
    environment,
    communicationIntegrationsService,
) {
    /**
     * PLEASE NOTE: All of the functions here should be treated as if they will be called from within a digest loop,
     * so they should be super lightweight and not run any complicated calculations.
     */

    const _this = this;

    _this.features = {
        channels: 'channels',
        notifications: 'notifications',
        multiDirectMessage: 'multiDirectMessage',
        privateChannels: 'privateChannels',
    };

    _this.integrations = {
        SLACK: {
            ...communicationIntegrationsService.integrations.SLACK,
            connect(analyticsLabel, loadingFlag) {
                // eslint-disable-line  no-unused-vars
                analyticsWrapper.track('Add Bot', { category: 'Edit Project', label: analyticsLabel });

                loadingFlag = true;
                const slackOauth2Enabled = true;

                oauthHandler
                    .slack(
                        environment.integrationKeysMap.slack,
                        slackOauth2Enabled
                            ? consts.getSlackIntegrationScopesV2()
                            : consts.getSlackIntegrationScopes(),
                        consts.getSlackIntegrationUserScopes(),
                        slackOauth2Enabled,
                    )
                    .then(function (code) {
                        return createProjectApis.createIntegration(
                            projectManager.project.id,
                            'slack',
                            {
                                code,
                                redirectUri: environment.integrationKeysMap.slackApp,
                                authType: slackOauth2Enabled
                                    ? SlackAuthenticationType.OAUTH2
                                    : SlackAuthenticationType.OAUTH,
                            },
                            undefined,
                            undefined,
                        );
                    })
                    .then(function (integrationObj) {
                        analyticsWrapper.track('Add Slack', { category: 'Edit Project', label: analyticsLabel });
                        window.Intercom('trackEvent', 'AddBot'); // eslint-disable-line new-cap

                        return createProjectApis
                            .createProjectIntegration(projectManager.project, integrationObj)
                            .then(function (data) {
                                return projectManager.selectProject(data).then(function () {
                                    $rootScope.$emit('alert', {
                                        type: 'success',
                                        msg: 'Slack connected successfully!',
                                    });
                                });
                            });
                    })
                    .catch(function (error) {
                        $rootScope.$emit('alert', { type: 'warning', msg: `Failed to connect Slack bot: ${error}` });
                    })
                    .finally(function () {
                        loadingFlag = false;
                    });
            },
        },
        MICROSOFT_TEAMS: {
            ...communicationIntegrationsService.integrations.MICROSOFT_TEAMS,
            connect() {
                $state.go('product.enterpriseComponents', { tab: 'communication-sources' });
            },
        },
        GOOGLECHAT: {
            ...communicationIntegrationsService.integrations.GOOGLECHAT,
            connect() {
                $state.go('product.enterpriseComponents', { tab: 'communication-sources' });
            },
        },
    };

    /**
     * Runs the connect logic fot the given communication integration name (case insensitive), or does nothing if no such integration exists.
     */
    _this.connect = function (integrationName, loadingFlag, analyticsLabel) {
        if (integrationName) {
            const integrationNameUpper = integrationName.toUpperCase();

            if (_this.integrations[integrationNameUpper] && _this.integrations[integrationNameUpper].connect) {
                _this.integrations[integrationNameUpper].connect(analyticsLabel, loadingFlag);
            }
        }
    };
}

export default angular.module('tonkean.app').service('communicationIntegrationsConnectService', CommunicationIntegrationsConnectService);
