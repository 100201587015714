import template from './tnkRadioButton.template.html?angularjs';
import lateConstructController from '../../utils/lateConstructController';

angular.module('tonkean.app').component('tnkRadioButton', {
    bindings: {
        radioId: '@',
        radioLabel: '@',
        radioValue: '@',
        radioName: '@',
        model: '<',
        onClick: '&',
        onClickParam: '<',
        secondOnClickParam: '<',
        isRequired: '<',
        classes: '@',
        viewOnly: '<',
        automationIdentifier: '@',
    },
    transclude: true,
    template,
    controller: 'RadioButtonCtrl',
});

/* @ngInject */
function RadioButtonCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        id: ctrl.radioId,
        label: ctrl.radioLabel,
        value: ctrl.radioValue,
        name: ctrl.radioName,
        model: ctrl.model,
        onClickParam: ctrl.onClickParam,
        secondOnClickParam: ctrl.secondOnClickParam,
        isRequired: ctrl.isRequired,
        classes: ctrl.classes,
        viewOnly: ctrl.viewOnly,
        automationIdentifier: ctrl.automationIdentifier,
    };

    $scope.onClick = function (event, onClickParam) {
        // Only trigger the on click once. We cancel all propagations so we are not fired twice.
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if (!$scope.data.viewOnly) {
            ctrl.onClick({ onClickParam, secondOnClickParam: ctrl.secondOnClickParam });
        }

        // Returning false to make sure we stop propagations.
        return false;
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object.
     */
    ctrl.$onChanges = function (changes) {
        // Keep onClickParam reference up tp date.
        if (changes.onClickParam) {
            $scope.data.onClickParam = ctrl.onClickParam;
        }
        // Keep model reference up tp date.
        if (changes.model) {
            $scope.data.model = ctrl.model;
        }
        // Keep the label's reference up to date.
        if (changes.radioLabel) {
            $scope.data.label = ctrl.radioLabel;
        }
        // Keep the view only reference up to date
        if (changes.viewOnly) {
            $scope.data.viewOnly = ctrl.viewOnly;
        }

        if (changes.classes) {
            $scope.data.classes = ctrl.classes;
        }
    };
}

angular.module('tonkean.app').controller('RadioButtonCtrl', lateConstructController(RadioButtonCtrl));
