import { useCallback } from 'react';

import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { Initiative } from '@tonkean/tonkean-entities';
import type { TonkeanType, SearchInitiativesQuery } from '@tonkean/tonkean-entities';
import type { ItemInterfaceWidget, TonkeanId } from '@tonkean/tonkean-entities';

const useReloadInitiatives = (
    widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    initiative: Initiative | undefined,
    searchInitiatives: (projectId: TonkeanId<TonkeanType.PROJECT>, options: SearchInitiativesQuery) => void,
) => {
    return useCallback(() => {
        if (initiative?.id && groupId) {
            searchInitiatives(projectId, {
                conditionsQuery: widget.configuration.filters,
                isArchived: false,
                groupId,
                isDraftInitiatives: initiative?.isDraftInitiative,
                isEditModeInitiatives: true,
                parentId: initiative?.id,
                skip: 0,
                limit: 50,
                orderByFieldId: widget.configuration.sortByField?.fieldDefinitionId,
                orderByFieldType: widget.configuration.sortByField?.orderByFieldType,
                orderByType: widget.configuration.sortByField?.orderType,
            });
        }
    }, [
        groupId,
        initiative?.id,
        initiative?.isDraftInitiative,
        projectId,
        searchInitiatives,
        widget.configuration.filters,
        widget.configuration.sortByField?.fieldDefinitionId,
        widget.configuration.sortByField?.orderByFieldType,
        widget.configuration.sortByField?.orderType,
    ]);
};

export default useReloadInitiatives;
