import template from './picklistValueEditor.template.html?angularjs';

/**
 * Definition of a manual field.
 */
export default angular.module('tonkean.app').component('tnkPicklistValueEditor', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        existingValues: '<',
        onPicklistChanged: '&',
        groupId: '<',
        workflowVersionId: '<',
        sourceOptions: '<',
        disableEdit: '<',
        enableImport: '<',
        onChange: '&',
        required: '<',
    },
    template,
    controller: 'PicklistValueEditorCtrl',
});
