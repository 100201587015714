import React from 'react';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import { TrackActions } from '@tonkean/flux';
import DefaultProjectedReason from './reasonComponents/DefaultProjectedReason';
import DateProjectedReason from './reasonComponents/DateProjectedReason';
import BotProjectedItemActions from '../../../actions/BotProjectedItemActions';
import { TrackSetReminderDropdown } from '@tonkean/infrastructure';

export default class BotProjectedItem extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.listener = null;

        this.botProjectedItemStore = props.botProjectedItemStore;
        this.trackHelper = props.trackHelper;
        this.as = this.props.authenticationService;

        // Initialize the state of the track list item.
        this.state = this.botProjectedItemStore.getNewState(this.props.sectionId, this.props.ownerId);
    }

    /**
     * Happens once, when the component is mounted to the page.
     * Registers our onStoreUpdated function as a listener on the store.
     * Also saves the returned remove listener, so we can remove the listener we've just added.
     */
    componentDidMount() {
        this.listener = this.botProjectedItemStore.addListener(this.onStoreUpdated.bind(this));
    }

    /**
     * Happens once, when the component is unmounted from the page.
     * Removed the listener from the store, using our removeListener (if it was initialized).
     */
    componentWillUnmount() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    /**
     * Registered as a listener to the store. Triggered whenever the store emits an update.
     */
    onStoreUpdated(sectionId, ownerId) {
        // If we didn't get an id (means everyone should update) or if the id is ours - update the state (causes re-render).
        if (
            this.botProjectedItemStore.shouldItemUpdate(this.props.ownerId, this.props.sectionId, {
                sectionId,
                ownerId,
            })
        ) {
            this.setState(this.botProjectedItemStore.getState(this.props.sectionId, this.props.ownerId));
        }
    }

    toggleSetReminderDropdown(initiativeId) {
        TrackActions.toggleTrackSetReminderDropdown(
            this.props.ownerId,
            this.props.sectionId,
            !this.state.setReminderDropdownOpen,
        );
        BotProjectedItemActions.setReminderDropdownTarget(this.props.sectionId, this.props.ownerId, initiativeId);
    }

    toggleShowMoreInitiatives() {
        BotProjectedItemActions.toggleShowMoreInitiatives(
            this.props.sectionId,
            this.props.ownerId,
            !this.state.showMoreInitiatives,
        );
    }

    renderReason(person, initiative) {
        const reason = initiative.nextGatherUpdateReason;
        switch (reason) {
            case 'DUE_DATE':
            case 'DUE_DATE_FUTURE':
            case 'DUE_DATE_EXACT':
                return (
                    <DateProjectedReason
                        label=" is due "
                        fieldValue={initiative.dueDate}
                        initiative={initiative}
                        owner={person}
                        goToTrack={this.props.uiActions.goToTrack}
                    />
                );
            case 'ETA':
            case 'ETA_EXACT':
            case 'ETA_FUTURE':
                return (
                    <DateProjectedReason
                        label={"'s ETA is "}
                        fieldValue={initiative.eta}
                        initiative={initiative}
                        owner={person}
                        goToTrack={this.props.uiActions.goToTrack}
                    />
                );

            // Don't do range value change or custom trigger because it will only show for like 15 mins

            default:
                return (
                    <DefaultProjectedReason
                        initiative={initiative}
                        owner={person}
                        goToTrack={this.props.uiActions.goToTrack}
                    />
                );
        }
    }

    /**
     * The main render function
     */
    render() {
        const initiatives = this.props.initiatives.map((i) => this.trackHelper.getInitiativeFromCache(i.id));
        // All owners should be the same so just pick the first one.
        const person = initiatives[0].owner;

        const initiativesElement = initiatives.map((initiative, index) => {
            if (index < 3 || this.state.showMoreInitiatives) {
                const isMenuOpen =
                    this.state.setReminderDropdownTarget === initiative.id && this.state.setReminderDropdownOpen;
                let menuClasses = 'flex-vmiddle open relative pointer';
                if (!isMenuOpen) {
                    menuClasses += ' common-hover-visible';
                }

                return (
                    <div
                        key={initiative.id}
                        className="flex mod-align-baseline margin-bottom-xs common-hover-visible-container"
                    >
                        {initiatives.length > 1 ? <div className="bot-projected-item-dot flex-no-shrink" /> : null}
                        <div className="flex-grow">{this.renderReason(person, initiative)}</div>

                        <div className={menuClasses} onClick={this.toggleSetReminderDropdown.bind(this, initiative.id)}>
                            <div className="common-size-xxxs common-color-grey">Change</div>
                            <span className="dropdown-chevron mod-small mod-static" />
                            {isMenuOpen && (
                                <TrackSetReminderDropdown
                                    id={this.props.ownerId}
                                    isOpen={this.state.setReminderDropdownOpen}
                                    onReminderSet={this.props.uiActions.loadItems.bind(this, false)}
                                    realTrack={initiative}
                                    editorId={this.props.sectionId}
                                    currentUserId={this.as.currentUser ? this.as.currentUser.id : null}
                                    modalUtils={this.props.modalUtils}
                                    trackHelper={this.props.trackHelper}
                                    tonkeanUtils={this.props.tonkeanUtils}
                                    callerForAnalyticsForAskForUpdates="Bot Projections"
                                    callerForAnalytics="Bot Projections"
                                />
                            )}
                        </div>
                    </div>
                );
            }
        });

        let initiativesContainerClasses = '';
        if (initiatives.length > 1) {
            initiativesContainerClasses += ' margin-left-xs';
        }

        return (
            <div id={`bot-projected-item-${this.props.sectionId}-${this.props.ownerId}`} className="bot-projected-item">
                <div className="flex">
                    {/* Avatar */}
                    <div className="avatar-main avatar-md margin-right flex-no-shrink">
                        <TonkeanAvatar owner={person} />
                    </div>

                    {/* Body */}
                    <i className="flex-grow">
                        <div>
                            <span className="common-bold">{person.firstName}</span>
                            <span> Will get a check-in message:</span>
                        </div>
                        <div className={initiativesContainerClasses}>{initiativesElement}</div>
                        {/* { initiatives.length > 3 && <a onClick={this.toggleShowMoreInitiatives.bind(this)}>{this.state.showMoreInitiatives ? 'Show less' : 'Show more'}</a> }*/}
                    </i>
                </div>
            </div>
        );
    }
}
