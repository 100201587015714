import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import SystemInterface from './SystemInterface';
import { SEARCH_INITIATIVES_QUERY_PARAM } from '../../utils/navigationQueryParams';

import { useGetStateParams } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    LoadingCircle,
    Modal,
    ModalAnimationType,
    useBreakpoint,
    useSetQueryParamOnLocation,
} from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const BACKDROP_BACKGROUND = 'rgba(0, 0, 0, 0.5)';

const DrawerWhenMobileModal = styled(Modal)`
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 8px 8px 0px 0px;
    }
`;

interface Props {
    open?: boolean;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    originWidgetId?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    onClose?: () => void;
    isBackEnabled?: boolean;
    dontUseGroupIdStateParam?: boolean;
}

const SystemInterfaceModal: React.FC<Props> = ({
    open = true,
    isBackEnabled = true,
    initiativeId: paramInitiativeId,
    originWidgetId,
    onClose,
    dontUseGroupIdStateParam,
}) => {
    const $location = useAngularService('$location');
    const $timeout = useAngularService('$timeout');
    const [urlInitiativeId] = useAngularWatch(() => $location.search().tid);
    const [originWidgetIdUrl] = useAngularWatch(() => $location.search().originWidget);

    const initiativeId = paramInitiativeId || urlInitiativeId;
    const isOpen = !!initiativeId && open;
    const [solutionReportId, groupIdStateParam] = useGetStateParams<
        [TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>, TonkeanId<TonkeanType.GROUP> | undefined]
    >('solutionBusinessReportId', 'g');

    const groupId = dontUseGroupIdStateParam ? undefined : groupIdStateParam;

    const projectManager = useAngularService('projectManager');
    const [project] = useAngularWatch(() => projectManager.project);
    const projectLoaded = project && !project.isDummy;
    const setQueryParamOnLocation = useSetQueryParamOnLocation();

    const onCloseCallback = useCallback(() => {
        // Using timeout for immediate digest cycle
        $timeout(() => {
            setQueryParamOnLocation('tid', null);
            setQueryParamOnLocation('originWidget', null);
            setQueryParamOnLocation(SEARCH_INITIATIVES_QUERY_PARAM, null);
        });
        onClose?.();
    }, [setQueryParamOnLocation, $timeout, onClose]);

    const effectiveBreakpoint = useBreakpoint();

    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    const onBack = useCallback(() => {
        history.back();
    }, []);

    return (
        <DrawerWhenMobileModal
            open={isOpen}
            onClose={onCloseCallback}
            modalAnimationType={
                isMobile ? ModalAnimationType.ANIMATE_BOTTOM_TO_TOP : ModalAnimationType.ANIMATE_OPACITY
            }
            backdropBackground={BACKDROP_BACKGROUND}
            animationDuration={350}
            escapeCloses={false}
            windowedFullScreen
        >
            {isOpen && projectLoaded && (
                <SystemInterface
                    solutionReportId={solutionReportId}
                    groupId={groupId}
                    initiativeId={initiativeId}
                    originWidgetId={originWidgetId || originWidgetIdUrl}
                    onBack={isBackEnabled ? onBack : undefined}
                    showPoweredByTonkean={false}
                    showPanel
                    showBreadcrumbs
                    showClosingButton
                />
            )}
            {isOpen && !projectLoaded && <LoadingCircle large centered />}
        </DrawerWhenMobileModal>
    );
};

export default SystemInterfaceModal;
