import { useCallback, useEffect, useMemo, useState } from 'react';

import useItemsValidationsNotifier from './useItemsValidationsNotifier';
import type { FieldValidationParams, RowId } from '../entities';
import { extractInitiativeId, generateItemsGridId, isPlaceholderRow, isTempRowForAddition } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';
import type ItemsGridServerRow from '../entities/ItemsGridServerRow';
import type RowToChangeDate from '../entities/RowToChangeDate';
import useConstructInitiativeToInitiativeRowData from '../utils/useConstructInitiativeToInitiativeRowData';

import type { FieldDefinition, Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { range } from '@tonkean/utils';

const useItemsGridRowsManager = (
    initiatives: Initiative[] | undefined,
    fieldDefinitions: FieldDefinition[],
    isReady: boolean,
    initiativesFetchTime: number,
    allowInsert: boolean,
    minimumItems: number = 0,
    maximumItems: number = 50,
    validations?: FieldValidation<FieldValidationParams>[],
    onValidationResultChange?: (errors: string[]) => void,
    onUpdateCell?: (
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        updatedRow: InitiativeRowData,
        updatedCell: string,
        updatedValue: unknown,
    ) => Promise<{
        id: TonkeanId<TonkeanType.INITIATIVE>;
        initiative: Initiative | undefined;
    }>,
    onCreateRow?: (updatedRow: InitiativeRowData) => Promise<Initiative>,
    onDeleteRow?: (id: TonkeanId<TonkeanType.INITIATIVE>) => Promise<void>,
    addIconsToTitle: boolean = false,
) => {
    const [serverRows, setServerRows] = useState<ItemsGridServerRow[]>([]);
    const [clientRows, setClientRows] = useState<InitiativeRowData[]>([]);
    const [deletedInitiativeIds, setDeletedInitiativeIds] = useState<Set<TonkeanId<TonkeanType.INITIATIVE>>>(new Set());
    const [itemsLastUpdate, setItemsLastUpdate] = useState<RowToChangeDate>({});

    const constructInitiativeToInitiativeRowData = useConstructInitiativeToInitiativeRowData(fieldDefinitions);

    const [isInitialized, setIsInitialized] = useState(false);

    const mergeExistingItemWithServerUpdatedData = useCallback(
        (clientItem: InitiativeRowData, serverInitiative: Omit<InitiativeRowData, 'tableRowId'>): InitiativeRowData => {
            const userChanges = itemsLastUpdate[clientItem.tableRowId] || {};

            const fieldsToForceClientLastValue = Object.entries(userChanges)
                .filter(([, { isRequestStillPending, updated }]) => {
                    return isRequestStillPending || updated > initiativesFetchTime;
                })
                .map(([fieldId]) => fieldId);

            const cleanedServerInitiative = { ...serverInitiative };

            // strip the fields that the client did update.
            fieldsToForceClientLastValue.forEach((fieldId) => {
                delete cleanedServerInitiative[fieldId];
            });

            return { ...clientItem, ...cleanedServerInitiative };
        },
        [initiativesFetchTime, itemsLastUpdate],
    );

    useEffect(() => {
        if (initiatives === undefined || !isReady) {
            return;
        }
        if (!isInitialized) {
            // The initial import of dummy and placeholders
            setIsInitialized(true);
        }

        const updatedServerRows = initiatives.map((initiative) => {
            return constructInitiativeToInitiativeRowData(initiative);
        });

        // Updating only the server rows that did not changed manually after the polling starts the fetch.
        setServerRows((prevServerRows) => {
            const prevServerRowsMap: Record<TonkeanId<TonkeanType.INITIATIVE>, ItemsGridServerRow> = Object.fromEntries(
                prevServerRows.map((serverRow) => [serverRow.initiativeId, serverRow] as const),
            );

            // in case we're creating a row, and the server did not return it yet we keep them in the list to avoid flickering in the data we already have (aggregations/ titles).
            const prevServerRowsThatStillNotExistInServerResponse = prevServerRows.filter(
                (prevRow) =>
                    prevRow.initiativeId && !initiatives.some((initiative) => initiative.id === prevRow.initiativeId),
            );

            const updatedRows = updatedServerRows.map((serverRow) => {
                const oldServerRow = serverRow.initiativeId ? prevServerRowsMap[serverRow.initiativeId] : undefined;
                if (
                    oldServerRow &&
                    oldServerRow._manuallyUpdated &&
                    oldServerRow._manuallyUpdated > initiativesFetchTime
                ) {
                    return oldServerRow;
                } else {
                    return serverRow;
                }
            });
            return [...updatedRows, ...prevServerRowsThatStillNotExistInServerResponse];
        });

        setDeletedInitiativeIds((deletedItems) => {
            // Updating the client rows with the new server rows, adding placeholders and dummy one for insert.
            setClientRows((prevState) => {
                // Server rows that are not in the client.
                const serverRowsThatNotInClient = updatedServerRows
                    .filter((serverRow) => {
                        return (
                            !prevState.some((clientRow) => clientRow.initiativeId === serverRow.initiativeId) &&
                            !deletedItems.has(serverRow.initiativeId)
                        );
                    })
                    .map((serverRow) => {
                        return {
                            ...serverRow,
                            tableRowId: generateItemsGridId(),
                        };
                    });

                const syncedItems = [...prevState, ...serverRowsThatNotInClient];

                const amountOfPlaceHoldersToAdd =
                    allowInsert && !isInitialized ? Math.max(minimumItems - syncedItems.length, 0) : 0;

                const placeHoldersToReachMinimumItems = range(amountOfPlaceHoldersToAdd).map(() => ({
                    tableRowId: generateItemsGridId(),
                    _createdAsPlaceholder: true,
                    initiativeId: undefined,
                }));

                const updatedItems = [...syncedItems, ...placeHoldersToReachMinimumItems];

                // Whether we have to add an empty row to insert
                const showAddEmptyRowToCreateNewItem =
                    !isInitialized && allowInsert && updatedItems.length < maximumItems;

                return [
                    ...updatedItems,
                    ...(showAddEmptyRowToCreateNewItem
                        ? [
                              {
                                  tableRowId: generateItemsGridId(),
                                  initiativeId: undefined,
                              },
                          ]
                        : []),
                ];
            });

            return deletedItems;
        });
    }, [
        allowInsert,
        constructInitiativeToInitiativeRowData,
        initiatives,
        initiativesFetchTime,
        isInitialized,
        isReady,
        maximumItems,
        minimumItems,
    ]);

    const appendUpdatedInitiative = useCallback(
        (initiative: Initiative, updateStartDate: number) => {
            const updatedRow = constructInitiativeToInitiativeRowData(initiative);

            setServerRows((prevServerRows) => {
                const prevServerRow = prevServerRows.find((serverRow) => serverRow.initiativeId === initiative.id);
                if (!prevServerRow) {
                    // if the server row is not in the server rows, we should add it.
                    return [...prevServerRows, { ...updatedRow, _manuallyUpdated: updateStartDate }];
                } else if (prevServerRow._manuallyUpdated && prevServerRow._manuallyUpdated > updateStartDate) {
                    // if the client row is manually updated, we should not update it.
                    return prevServerRows;
                } else {
                    // if the server row is not manually updated, we should update it.
                    return prevServerRows.map((serverRow) => {
                        if (serverRow.initiativeId === initiative.id) {
                            return { ...updatedRow, _manuallyUpdated: updateStartDate };
                        }
                        return serverRow;
                    });
                }
            });
        },
        [constructInitiativeToInitiativeRowData],
    );

    const onUpdate = useCallback(
        async (updatedRow: InitiativeRowData, updatedCell: string, updatedValue: unknown) => {
            const updateStartDate = Date.now();

            try {
                setClientRows((prevItems) => {
                    const updatedRows = prevItems.map((item) => {
                        if (item.tableRowId === updatedRow.tableRowId) {
                            return { ...item, [updatedCell]: updatedValue };
                        }
                        return item;
                    });

                    return [...updatedRows];
                });

                setItemsLastUpdate((prevItemsLastUpdate) => {
                    return {
                        ...prevItemsLastUpdate,
                        [updatedRow.tableRowId]: {
                            ...prevItemsLastUpdate[updatedRow.tableRowId],
                            [updatedCell]: {
                                updated: updateStartDate,
                                isRequestStillPending: true,
                            },
                        },
                    };
                });

                if (updatedRow.initiativeId && onUpdateCell) {
                    const { initiative: updatedInitiative } = await onUpdateCell(
                        updatedRow.initiativeId,
                        updatedRow,
                        updatedCell,
                        updatedValue,
                    );

                    if (updatedInitiative) {
                        appendUpdatedInitiative(updatedInitiative, updateStartDate);
                    }
                } else if (updatedRow._internalOriginalInitiativeId && onUpdateCell) {
                    const initiativeId = await updatedRow._internalOriginalInitiativeId;
                    const { initiative: updatedInitiative } = await onUpdateCell(
                        initiativeId,
                        updatedRow,
                        updatedCell,
                        updatedValue,
                    );

                    if (updatedInitiative) {
                        appendUpdatedInitiative(updatedInitiative, updateStartDate);
                    }
                } else if (!!onCreateRow) {
                    const createRowPromise = onCreateRow(updatedRow);

                    setClientRows((prevState) => {
                        const updatedState = prevState.map((prevItem) => {
                            if (prevItem.tableRowId === updatedRow.tableRowId) {
                                return {
                                    ...prevItem,
                                    _internalOriginalInitiativeId: createRowPromise.then(({ id }) => id),
                                };
                            }
                            return prevItem;
                        });

                        return [
                            ...updatedState,
                            ...(allowInsert && !isPlaceholderRow(updatedRow) && updatedState.length < maximumItems
                                ? [
                                      {
                                          tableRowId: generateItemsGridId(),
                                          initiativeId: undefined,
                                      },
                                  ]
                                : []),
                        ];
                    });
                    const createInitiative = await createRowPromise;

                    setClientRows((prevState) => {
                        const updatedState = prevState.map((prevItem) => {
                            if (prevItem.tableRowId === updatedRow.tableRowId) {
                                return {
                                    ...prevItem,
                                    _internalOriginalInitiativeId: createRowPromise.then(({ id }) => id),
                                    initiativeId: createInitiative.id,
                                };
                            }
                            return prevItem;
                        });

                        return [...updatedState];
                    });

                    appendUpdatedInitiative(createInitiative, updateStartDate);
                }
            } finally {
                setItemsLastUpdate((prevItemsLastUpdate) => {
                    return {
                        ...prevItemsLastUpdate,
                        [updatedRow.tableRowId]: {
                            ...prevItemsLastUpdate[updatedRow.tableRowId],
                            [updatedCell]: {
                                ...prevItemsLastUpdate[updatedRow.tableRowId]?.[updatedCell],
                                isRequestStillPending: false,
                            },
                        },
                    };
                });
            }
        },
        [allowInsert, appendUpdatedInitiative, maximumItems, onCreateRow, onUpdateCell],
    );

    const items = useMemo(() => {
        if (!isReady) {
            return undefined;
        }

        const serverRowIdToId = Object.fromEntries(
            serverRows.map((serverRow) => [serverRow.initiativeId, serverRow] as const),
        );

        const syncedClientRowsWithServerData = clientRows.map((clientRow) => {
            const serverRow = clientRow.initiativeId ? serverRowIdToId[clientRow.initiativeId] : undefined;

            if (serverRow) {
                return mergeExistingItemWithServerUpdatedData(clientRow, serverRow);
            }

            return clientRow;
        });

        return (
            syncedClientRowsWithServerData
                // Make sure we don't show deleted initiatives.
                .filter((item) => (item.initiativeId ? !deletedInitiativeIds.has(item.initiativeId) : true))
                // Add the index to the items.
                .map((item, index) => {
                    return {
                        ...item,
                        titleIcon: addIconsToTitle
                            ? {
                                  name: item.title || '',
                                  icon: undefined,
                              }
                            : undefined,
                        _index: index,
                    };
                })
        );
    }, [
        addIconsToTitle,
        clientRows,
        deletedInitiativeIds,
        isReady,
        mergeExistingItemWithServerUpdatedData,
        serverRows,
    ]);

    useItemsValidationsNotifier(items, minimumItems, validations, onValidationResultChange, isReady);

    const onDelete = useCallback(
        async (rowId: RowId) => {
            const item = items?.find((tableItem) => tableItem.tableRowId === rowId);

            if (!!item?.initiativeId) {
                setDeletedInitiativeIds(
                    (prevDeletedInitiativeIds) => new Set([item.initiativeId!, ...prevDeletedInitiativeIds]),
                );
            }

            setClientRows((prevItems) => {
                const newState = prevItems.filter((prevItem) => prevItem.tableRowId !== rowId);

                const shouldAddEmptyRow =
                    allowInsert && newState.length < maximumItems && !prevItems.some(isTempRowForAddition);

                return [
                    ...newState,
                    ...(shouldAddEmptyRow
                        ? [
                              {
                                  tableRowId: generateItemsGridId(),
                                  initiativeId: undefined,
                              },
                          ]
                        : []),
                ];
            });

            if (!item || !onDeleteRow) return;
            const initiativeIdToDelete = await extractInitiativeId(item);

            if (initiativeIdToDelete) {
                setServerRows((prevItems) => {
                    return prevItems.filter((prevItem) => prevItem.initiativeId !== initiativeIdToDelete);
                });

                setDeletedInitiativeIds(
                    (prevDeletedInitiativeIds) => new Set([initiativeIdToDelete, ...prevDeletedInitiativeIds]),
                );

                await onDeleteRow(initiativeIdToDelete);
            }
        },
        [allowInsert, items, maximumItems, onDeleteRow],
    );

    const appendServerRow = useCallback(
        (initiative: Initiative) => {
            const updatedServerRow: ItemsGridServerRow = constructInitiativeToInitiativeRowData(initiative);

            setClientRows((prevClientRows) => {
                const initiativeAlreadyExists = prevClientRows.some(
                    (serverRow) => serverRow.initiativeId === initiative.id,
                );

                if (initiativeAlreadyExists) {
                    return prevClientRows.map((clientRow) => {
                        if (clientRow.initiativeId === initiative.id) {
                            return {
                                tableRowId: clientRow.tableRowId,
                                ...updatedServerRow,
                            };
                        }
                        return clientRow;
                    });
                } else {
                    const updatedRow = {
                        tableRowId: generateItemsGridId(),
                        ...updatedServerRow,
                    };

                    return [...prevClientRows, updatedRow];
                }
            });

            setServerRows((prevServerRows) => {
                const initiativeAlreadyExists = prevServerRows.some(
                    (serverRow) => serverRow.initiativeId === initiative.id,
                );

                if (initiativeAlreadyExists) {
                    return prevServerRows.map((serverRow) => {
                        if (serverRow.initiativeId === initiative.id) {
                            return updatedServerRow;
                        }
                        return serverRow;
                    });
                } else {
                    return [...prevServerRows, updatedServerRow];
                }
            });
        },
        [constructInitiativeToInitiativeRowData],
    );

    return {
        items,
        onUpdate,
        onDelete,
        isInitialized,
        appendServerRow,
    };
};
export default useItemsGridRowsManager;
