import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import WidgetItemsGeneralFilter from './WidgetItemsSingleFilters/WidgetItemsGeneralFilter';
import WidgetItemsPersonFilter from './WidgetItemsSingleFilters/WidgetItemsPersonFilter';
import WidgetItemsStatusFilter from './WidgetItemsSingleFilters/WidgetItemsStatusFilter';

import { FieldDefinitionDisplayFormat, SpecialFieldsKey } from '@tonkean/tonkean-entities';
import type { FieldDefinition, FieldFilter, Group, Project, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const FieldFilterContainer = styled(Clickable)`
    padding: 5px;
    width: 250px;
`;

interface Props {
    fieldFilter: FieldFilter | undefined;
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersion: WorkflowVersion;
    fieldDefinition: FieldDefinition;
    onFilterChanged: (fieldId: FieldDefinition['id'], value: any) => void;
    onFilterRemoved: (fieldId?: FieldDefinition['id']) => void;
    onKeyDown: (event: React.KeyboardEvent) => void;
    label: string;
    onChangeFormattedLabel?: (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => void;
}

const WidgetItemsSingleFilter: React.FC<Props> = ({
    fieldFilter,
    projectId,
    groupId,
    workflowVersion,
    fieldDefinition,
    onFilterChanged,
    onFilterRemoved,
    onKeyDown,
    label,
    onChangeFormattedLabel,
}) => {
    const [openFilterSelection, setOpenFilterSelection] = useState<boolean>(false);

    const toggleOpenFilterSelection = useCallback(() => {
        setOpenFilterSelection((prevOpenFilterSelection) => !prevOpenFilterSelection);
    }, []);

    const isPersonFilter =
        fieldDefinition.displayFormat === FieldDefinitionDisplayFormat.USER ||
        fieldDefinition.id === SpecialFieldsKey.OWNER;

    const isStatusFilter = fieldDefinition.id === SpecialFieldsKey.STATUS;

    const onValueChanged = useCallback(
        (newVal) => {
            if (newVal) {
                onFilterChanged(fieldDefinition.id, newVal);
            } else {
                onFilterRemoved(fieldDefinition.id);
            }
        },
        [fieldDefinition.id, onFilterChanged, onFilterRemoved],
    );

    return (
        <FieldFilterContainer onClick={toggleOpenFilterSelection}>
            {isPersonFilter ? (
                <WidgetItemsPersonFilter
                    fieldFilter={fieldFilter}
                    projectId={projectId}
                    workflowVersion={workflowVersion}
                    groupId={groupId}
                    fieldDefinition={fieldDefinition}
                    onFilterChanged={onValueChanged}
                    label={label}
                    onChangeFormattedLabel={onChangeFormattedLabel}
                />
            ) : isStatusFilter ? (
                <WidgetItemsStatusFilter
                    fieldFilter={fieldFilter}
                    projectId={projectId}
                    workflowVersion={workflowVersion}
                    groupId={groupId}
                    fieldDefinition={fieldDefinition}
                    onFilterChanged={onValueChanged}
                    label={label}
                    onChangeFormattedLabel={onChangeFormattedLabel}
                />
            ) : (
                <WidgetItemsGeneralFilter
                    fieldFilter={fieldFilter}
                    projectId={projectId}
                    workflowVersion={workflowVersion}
                    groupId={groupId}
                    fieldDefinition={fieldDefinition}
                    onFilterChanged={onValueChanged}
                    onChangeFormattedLabel={onChangeFormattedLabel}
                    onKeyDown={onKeyDown}
                />
            )}
        </FieldFilterContainer>
    );
};

export default WidgetItemsSingleFilter;
