<div class="common-size-xs padding-top-xs">
    <strong>Module details:</strong>
</div>
<hr class="margin-top" />

<tnk-edit-group
    group="data.groupSettings"
    control-object="data.groupControlObject"
    is-valid="data.isGroupValid"
    error="error"
    loading="posting"
></tnk-edit-group>
