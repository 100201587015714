import { useAngularWatch } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import DoneTracksCounter from './DoneTracksCounter';
import SubtrackNumber from './SubtrackNumber';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;

interface Props {
    realTrack: any;
    hideCount?: boolean;
    onClick?(): void;
    loadingRelatedInitiatives?: boolean;
}

const TrackInnerItemsCounts: React.FC<Props> = ({
    realTrack,
    hideCount = false,
    onClick = () => {},
    loadingRelatedInitiatives,
}) => {
    const [hasRelatedInitiatives, subtrackNumber, doneInnerItemsCount] = useAngularWatch(
        () => realTrack.hasRelatedInitiatives,
        () => realTrack.innerItemsCount,
        () => realTrack.doneInnerItemsCount,
    );

    const showDoneItemsCounter = useMemo(() => doneInnerItemsCount > 0, [doneInnerItemsCount]);
    return hasRelatedInitiatives ? (
        <StyledDiv className="track-inner-items-count">
            {showDoneItemsCounter && (
                <>
                    <DoneTracksCounter doneInnerItemsCount={doneInnerItemsCount} onClick={onClick} />
                    <span className="common-color-grey">/</span>
                </>
            )}
            {!hideCount && (
                <SubtrackNumber
                    subtrackNumber={subtrackNumber}
                    onClick={onClick}
                    loadingRelatedInitiatives={!!loadingRelatedInitiatives}
                />
            )}
        </StyledDiv>
    ) : (
        <div className="track-inner-items-count" />
    );
};

export default TrackInnerItemsCounts;
