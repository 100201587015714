import InboxItem from './InboxItem';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkInboxItem',
        reactToAngular(
            InboxItem,
            ['id'],
            [
                'inboxItemStore',
                'trackHelper',
                'botHistoryHelper',
                'projectManager',
                'modalUtils',
                'tonkeanService',
                'tonkeanUtils',
                'authenticationService',
                'inboxItemStore',
            ],
        ),
    );
