import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type FieldToTag from './FieldToTag';
import type TrainingSetTrainTriggerConfiguration from './TrainingSetTrainTriggerConfiguration';
import { ReactComponent as EyeIcon } from '../../../../../../../images/icons/Invisble-Eye.svg';
import TrainingSetModelSelector from '../../../../../../infrastructure/components/TrainingSetModelSelector';
import TrainingSetSelector from '../../../../../../infrastructure/components/TrainingSetSelector';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Checkbox, Field, InformationTooltip, LoadingCircle, Radio, RadioGroup, Toggle } from '@tonkean/infrastructure';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType, TrainingSetFieldType, TrainingSetType } from '@tonkean/tonkean-entities';
import { TrainingSetTrainType } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize, InformationIconSize } from '@tonkean/tui-theme/sizes';

const StyledField = styled(Field)`
    margin-top: 24px;

    ${childrenStyledFocus}
`;

const ExploreTrainingSet = styled.div`
    margin-top: 12px;
    font-size: ${Theme.colors.gray_300};
    font-size: ${FontSize.SMALL_12};
    display: inline-flex;
    align-items: center;
`;

const StyledEyeIcon = styled(EyeIcon)`
    margin-right: 5px;
`;

const TrainTypeRadioGroup = styled(RadioGroup)`
    margin-top: 24px;
`;

const TagFieldsTitleContainer = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TaggingExplanation = styled.div`
    margin-top: 8px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    font-style: italic;
    line-height: 1.6;
`;

const TagFieldsTitle = styled(Field)`
    label:first-of-type {
        margin-bottom: 0;
    }
`;

const LoadingIndicationContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LoadingFieldsText = styled.div`
    font-size: ${FontSize.SMALL_12};
`;

const FieldCheckbox = styled(Checkbox)`
    margin-bottom: 8px;
    margin-top: 16px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-right: 5px;
`;

const ErrorLoadingFieldsText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
`;

const NlpSTrainingSetFieldWrapper = styled.div`
    margin-top: 10px;
`;

const AddToTestItemsContent = styled.div`
    display: flex;
    align-items: center;
`;

const AddToTestItemsText = styled.div`
    margin-right: 4px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
`;

const ErrorText = styled.div`
    margin-top: 5px;
    color: ${Theme.colors.error};
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    previewEvaluationSource: any;
    configuredLogic: any;
    validationObject: any;

    onTrainingSetTrainTriggerConfiguration(newConfiguration: TrainingSetTrainTriggerConfiguration): any;
}

const TrainTrainingSetTriggerLogicConfiguration: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    configuredLogic,
    previewEvaluationSource,
    onTrainingSetTrainTriggerConfiguration,
    validationObject,
}) => {
    const $state = useAngularService('$state');
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const utils = useAngularService('utils');

    const [
        { data: trainingSetFieldsResponse, error: errorLoadingFields, loading: loadingFields },
        getTrainingSetFields,
    ] = useLazyTonkeanService('getTrainingSetFields');

    const [{ data: trainingSetResponse, loading: trainingSetLoading, error: trainingSetError }, getTrainingSet] =
        useLazyTonkeanService('getTrainingSetById');

    const currentWorkflowFolder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectManager.project.id, groupId);
    }, [workflowFolderManager, projectManager, groupId]);

    const [updatedByUser, setUpdatedByUser] = useState<boolean>(false);
    const [taggedFieldsMap, setTaggedFieldsMap] = useState<Record<string, FieldToTag>>(() => {
        const fieldsToTag: FieldToTag[] =
            (configuredLogic.node?.customTriggerActions?.length &&
                configuredLogic.node?.customTriggerActions[0].customTriggerActionDefinition
                    ?.trainingSetTrainTriggerConfiguration?.fieldsToTag) ||
            [];

        return utils.createMapFromArray(fieldsToTag, 'trainingSetFieldId');
    });

    // Initialise the training set we're working with.
    const [selectedTrainingSetId, setSelectedTrainingSetId] = useState<string | undefined>(() => {
        return (
            configuredLogic.node?.customTriggerActions?.length &&
            configuredLogic.node?.customTriggerActions[0].customTriggerActionDefinition
                ?.trainingSetTrainTriggerConfiguration?.trainingSetId
        );
    });

    // Initialise the input text to train the item with.
    const [inputText, setInputText] = useState<TonkeanExpressionDefinition | undefined>(() => {
        return (
            (configuredLogic.node?.customTriggerActions?.length &&
                configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    .trainingSetTrainTriggerConfiguration?.inputTextExpression) ||
            ''
        );
    });

    // Initialise the item name for creation.
    const [itemName, setItemName] = useState<TonkeanExpressionDefinition | undefined>(() => {
        return (
            (configuredLogic.node?.customTriggerActions?.length &&
                configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    ?.trainingSetTrainTriggerConfiguration?.itemNameExpression) ||
            ''
        );
    });

    // Initialise the training set train type for creation.
    const [trainingSetTrainType, setTrainingSetTrainType] = useState<TrainingSetTrainType>(() => {
        return (
            (configuredLogic.node?.customTriggerActions?.length &&
                configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    ?.trainingSetTrainTriggerConfiguration?.trainingSetTrainType) ||
            TrainingSetTrainType.NEW_TRAINED_ITEM
        );
    });

    // Initialise the shouldTagFields type for creation.
    const [shouldTagFields, setShouldTagFields] = useState<boolean>(() => {
        return (
            (configuredLogic.node?.customTriggerActions?.length &&
                configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    ?.trainingSetTrainTriggerConfiguration?.shouldTagFields) ||
            false
        );
    });

    // Initialise the the option to add item to the model test items.
    const [addAsTestItem, setAddAsTestItem] = useState<boolean>(() => {
        return (
            configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.trainingSetTrainTriggerConfiguration?.addAsTestItem || false
        );
    });

    const onInputTextChange = (
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean | undefined,
    ) => {
        setUpdatedByUser(shouldSaveLogic || false);
        setInputText({
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled: true,
        });
    };

    const onTaggedFieldTagValueChange = (
        trainingSetFieldId: string,
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean = false,
        shouldTag: boolean = taggedFieldsMap[trainingSetFieldId]?.shouldTag || false,
    ) => {
        setUpdatedByUser(shouldSaveLogic);
        setTaggedFieldsMap({
            ...taggedFieldsMap,
            [trainingSetFieldId]: {
                trainingSetFieldId,
                shouldTag,
                tagValue: {
                    originalExpression,
                    evaluatedExpression,
                    isStripHtmlDisabled: true,
                },
            },
        });
    };

    const onItemNameChange = (
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean | undefined,
    ) => {
        setUpdatedByUser(shouldSaveLogic || false);
        setItemName({
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled: true,
        });
    };

    useEffect(() => {
        if (selectedTrainingSetId) {
            getTrainingSetFields(selectedTrainingSetId);
            getTrainingSet(selectedTrainingSetId);
        }
    }, [getTrainingSet, getTrainingSetFields, selectedTrainingSetId]);

    useEffect(() => {
        if (updatedByUser) {
            onTrainingSetTrainTriggerConfiguration({
                trainingSetId: selectedTrainingSetId,
                inputTextExpression: inputText,
                itemNameExpression: itemName,
                trainingSetTrainType,
                shouldTagFields,
                fieldsToTag: Object.values(taggedFieldsMap),
                addAsTestItem,
            });
        }
    }, [
        selectedTrainingSetId,
        inputText,
        itemName,
        onTrainingSetTrainTriggerConfiguration,
        updatedByUser,
        trainingSetTrainType,
        shouldTagFields,
        taggedFieldsMap,
        addAsTestItem,
    ]);

    return (
        <>
            <StyledField label="Choose Training Set">
                <TrainingSetSelector
                    workflowFolderId={currentWorkflowFolder.id}
                    preselectedItem={selectedTrainingSetId}
                    onItemSelected={(selectedItem) => {
                        setUpdatedByUser(true);
                        setSelectedTrainingSetId(selectedItem);
                    }}
                />
            </StyledField>

            {validationObject?.noTrainingSet && <ErrorText>{validationObject.noTrainingSet} </ErrorText>}

            {selectedTrainingSetId && (
                <ExploreTrainingSet>
                    <StyledEyeIcon />
                    <ClickableLink
                        state="product.trainingSetPage"
                        params={{
                            page: ProjectIntegrationPageMenuItemType.EXTRACTED_FIELDS,
                            enterpriseComponentId: selectedTrainingSetId,
                            fromState: $state.current.name,
                            fromStateParams: $state.params,
                            fromName: projectManager.groupsMap[groupId]?.name || 'Module Editor',
                        }}
                        underline={LinkUnderline.ALWAYS}
                    >
                        Explore this Training Set
                    </ClickableLink>
                </ExploreTrainingSet>
            )}

            <StyledField label="Item name">
                <TonkeanExpression
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    placeholder="Item name"
                    savedOriginalExpression={itemName?.originalExpression || ''}
                    savedEvaluatedExpression={itemName?.evaluatedExpression || ''}
                    previewEvaluationSource={previewEvaluationSource}
                    onTonkeanExpressionChanged={(
                        originalExpression,
                        evaluatedExpression,
                        expression,
                        shouldSaveLogic,
                    ) => onItemNameChange(originalExpression, evaluatedExpression, shouldSaveLogic)}
                />
            </StyledField>

            {validationObject?.noItemName && <ErrorText>{validationObject.noItemName} </ErrorText>}

            <TrainTypeRadioGroup
                value={trainingSetTrainType}
                onChange={(newValue) => {
                    setTrainingSetTrainType(newValue);
                    setUpdatedByUser(true);
                }}
                direction="row"
                size={InputSize.SMALL}
            >
                <Radio value={TrainingSetTrainType.NEW_TRAINED_ITEM}>Create new train item</Radio>
                <Radio value={TrainingSetTrainType.EXISTING_TRAINED_ITEM}>Use existing train item</Radio>
            </TrainTypeRadioGroup>

            {trainingSetTrainType === TrainingSetTrainType.NEW_TRAINED_ITEM && (
                <StyledField label="Input (any text or OCR Output)">
                    <TonkeanExpression
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        placeholder="Add field"
                        savedOriginalExpression={inputText?.originalExpression || ''}
                        savedEvaluatedExpression={inputText?.evaluatedExpression || ''}
                        previewEvaluationSource={previewEvaluationSource}
                        onTonkeanExpressionChanged={(
                            originalExpression,
                            evaluatedExpression,
                            expression,
                            shouldSaveLogic,
                        ) => onInputTextChange(originalExpression, evaluatedExpression, shouldSaveLogic)}
                    />
                </StyledField>
            )}

            {validationObject?.noInputText && <ErrorText>{validationObject.noInputText} </ErrorText>}

            {selectedTrainingSetId && (
                <>
                    <TagFieldsTitleContainer>
                        <TagFieldsTitle label="Tag Fields" />
                        <Toggle
                            size={InputSize.SMALL}
                            checked={shouldTagFields}
                            onChange={() => {
                                setShouldTagFields(!shouldTagFields);
                                setUpdatedByUser(true);
                            }}
                        />
                    </TagFieldsTitleContainer>

                    <TaggingExplanation>
                        Choose the tagged values to set for the trained item. If the trained item already exists, they
                        will override any existing tagged values.
                    </TaggingExplanation>

                    {shouldTagFields && (
                        <>
                            {(loadingFields || trainingSetLoading) && (
                                <LoadingIndicationContainer>
                                    <StyledLoadingCircle />
                                    <LoadingFieldsText>Loading fields to tag...</LoadingFieldsText>
                                </LoadingIndicationContainer>
                            )}

                            {(errorLoadingFields || trainingSetError) && (
                                <ErrorLoadingFieldsText>Error loading fields to tag...</ErrorLoadingFieldsText>
                            )}

                            {trainingSetResponse &&
                                trainingSetFieldsResponse?.trainingSetFields
                                    .filter(
                                        (trainingSetField) =>
                                            trainingSetField.trainingSetFieldType === TrainingSetFieldType.EXTRACT,
                                    )
                                    .map((trainingSetField) => {
                                        return (
                                            <React.Fragment key={trainingSetField.id}>
                                                {trainingSetResponse.trainingSetType ===
                                                TrainingSetType.TEXT_EXTRACTOR ? (
                                                    <>
                                                        <FieldCheckbox
                                                            checked={taggedFieldsMap[trainingSetField.id]?.shouldTag}
                                                            onChange={() => {
                                                                setUpdatedByUser(true);
                                                                setTaggedFieldsMap({
                                                                    ...taggedFieldsMap,
                                                                    [trainingSetField.id]: {
                                                                        trainingSetFieldId: trainingSetField.id,
                                                                        shouldTag:
                                                                            !taggedFieldsMap[trainingSetField.id]
                                                                                ?.shouldTag,
                                                                        tagValue:
                                                                            taggedFieldsMap[trainingSetField.id]
                                                                                ?.tagValue,
                                                                    },
                                                                });
                                                            }}
                                                            name={trainingSetField.id}
                                                        >
                                                            {trainingSetField.fieldName}
                                                        </FieldCheckbox>
                                                        <TonkeanExpression
                                                            groupId={groupId}
                                                            workflowVersionId={workflowVersionId}
                                                            placeholder={trainingSetField.fieldName}
                                                            previewEvaluationSource={previewEvaluationSource}
                                                            savedOriginalExpression={
                                                                taggedFieldsMap[trainingSetField.id]?.tagValue
                                                                    ?.originalExpression || ''
                                                            }
                                                            savedEvaluatedExpression={
                                                                taggedFieldsMap[trainingSetField.id]?.tagValue
                                                                    ?.evaluatedExpression || ''
                                                            }
                                                            onTonkeanExpressionChanged={(
                                                                originalExpression,
                                                                evaluatedExpression,
                                                                expression,
                                                                shouldSaveLogic,
                                                            ) =>
                                                                onTaggedFieldTagValueChange(
                                                                    trainingSetField.id,
                                                                    originalExpression,
                                                                    evaluatedExpression,
                                                                    shouldSaveLogic,
                                                                )
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <NlpSTrainingSetFieldWrapper>
                                                        <Field label={trainingSetField.fieldName}>
                                                            <TrainingSetModelSelector
                                                                trainingSetId={selectedTrainingSetId}
                                                                onChange={(selectedItemId) =>
                                                                    onTaggedFieldTagValueChange(
                                                                        trainingSetField.id,
                                                                        selectedItemId,
                                                                        selectedItemId,
                                                                        true,
                                                                        true,
                                                                    )
                                                                }
                                                                modelId={
                                                                    taggedFieldsMap[trainingSetField.id]?.tagValue
                                                                        ?.originalExpression
                                                                }
                                                            />
                                                        </Field>
                                                        <FieldCheckbox
                                                            checked={addAsTestItem}
                                                            onChange={(event) => {
                                                                setUpdatedByUser(true);
                                                                setAddAsTestItem(event.target.checked);
                                                            }}
                                                            size={InputSize.SMALL}
                                                        >
                                                            <AddToTestItemsContent>
                                                                <AddToTestItemsText>
                                                                    Add to model's test items
                                                                </AddToTestItemsText>
                                                                <InformationTooltip
                                                                    iconSize={InformationIconSize.SMALL}
                                                                    tooltipLimitWidth={15}
                                                                    placement="bottom"
                                                                >
                                                                    If checked, will add the item to the list of test
                                                                    items that are shown in the model configuration.
                                                                </InformationTooltip>
                                                            </AddToTestItemsContent>
                                                        </FieldCheckbox>
                                                    </NlpSTrainingSetFieldWrapper>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default TrainTrainingSetTriggerLogicConfiguration;
