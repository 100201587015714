<!-- Cover with absolute because not directly in modal and can be in it's own page-->
<div class="setup-group-modal absolute-fill-view">
    <div class="common-close-btn-container common-height-full clearfix">
        <!-- Logo to go back to dashboard if not a modal -->
        <div
            class="hidden-xs main-logo-sm pointer fixed-top-left"
            ng-if="::(!isModal && !isListGallery && !isInline)"
            ng-hide="posting || firstLoading || data.selectedList"
            ui-sref="product"
        >
            <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
        </div>

        <!-- Close button -->
        <div
            class="common-close-btn svg-icon-lg margin-normal-sm"
            ng-if="isModal"
            ng-click="$dismiss()"
            ng-hide="(!posting || firstLoading) && data.selectedList"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <!-- Close button that sends the user back to live reports when not in modal but in inline mod (selecting bot) -->
        <div
            class="common-close-btn svg-icon-lg margin-normal-sm"
            ng-if="!isModal && isInline"
            ui-sref="product.board({filter: 'all'})"
            ng-hide="posting || firstLoading || data.selectedList"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <!-- Back -->
        <div
            class="absolute-left pointer padding-normal setup-group-modal-back-button"
            ng-if="data.selectedList && !emptyOnly"
            ng-hide="
                posting ||
                firstLoading ||
                ((data.selectedList.id === 'DUPLICATE' || data.selectedList.isExampleList) &&
                    data.currentStepIndex === 0)
            "
            ng-click="previousStep()"
        >
            <i class="fa fa-chevron-left margin-right"></i>
            <span>Back</span>
        </div>

        <!-- Posting loading circle -->
        <div class="text-center setup-group-modal-loading" ng-show="posting || firstLoading">
            <div>
                <i class="loading-large"></i>
            </div>
            <p class="common-size-s" ng-hide="firstLoading">
                <strong>Creating your {{ workersTemplateMode ? 'Module' : 'List' }}...</strong>
            </p>
            <p class="common-size-xxxs common-color-grey">Please don't refresh or navigate away while creating.</p>
            <div class="margin-normal-v setup-complete-progress-bar-container" ng-hide="firstLoading">
                <div class="common-loading-bar margin-top">
                    <div
                        class="common-loading-bar-progress"
                        ng-style="{ width: (data.postingProgress / data.postingProgressCount) * 100 + '%' }"
                    ></div>
                </div>
            </div>
        </div>

        <div
            class="setup-group-built-in-left-bar flex-no-shrink"
            ng-if="(!data.selectedList || data.selectedList.isExampleList) && !data.autoCreateList"
            ng-hide="posting"
            ng-class="{ 'hidden-xs': isListGallery || isInline }"
        >
            <!--tabs-->
            <div class="setup-group-built-in-tabs-bar flex common-color-dark-grey mod-inline flex-xs-wrap">
                <a
                    ng-if="isListGallery || isInline"
                    class="setup-group-tab-btn text-left btn btn-default margin-right margin-bottom common-bold"
                    ng-class="!data.selectedTab ? 'groups-selected-option' : ''"
                    ng-click="selectTab(null)"
                >
                    All
                </a>
                <a
                    ng-repeat="cat in categoriesList"
                    class="setup-group-tab-btn text-left btn btn-default margin-right margin-bottom common-bold"
                    ng-class="data.selectedTab && data.selectedTab.id === cat.id ? 'groups-selected-option' : ''"
                    ng-click="selectTab(cat)"
                >
                    {{ cat.title }}
                </a>
            </div>
        </div>

        <!-- Select list -->
        <div
            class="setup-group-modal-select-list text-left"
            ng-if="!data.selectedList || data.selectedList.isExampleList"
            ng-hide="posting || firstLoading"
        >
            <p
                class="text-uppercase common-size-xxs common-color-dark-grey margin-left hidden-xs"
                ng-hide="isListGallery || data.selectedList"
            >
                Create A {{ workersTemplateMode ? 'Module' : 'List' }}
            </p>
            <div
                ng-hide="data.selectedList"
                class="
                    padding-bottom-lg
                    flex-vmiddle flex-wrap-no-xs flex-xs-col
                    margin-top-lg margin-bottom-lg margin-left
                "
            >
                <h1 class="mod-no-top flex-grow" ng-if="!isListGallery && !isInline">What do you want to track?</h1>
                <div ng-if="(isListGallery || isInline) && !workersTemplateMode" class="flex-grow">
                    <h1 class="mod-no-top">Dashboard Templates</h1>
                    <p>
                        Tonkean’s flexible, all-in-one dashboards keep everything together for you. All of the data you
                        care about and team updates are organized into one clean bulleted list so you can see the big
                        picture.
                    </p>
                </div>
                <div ng-if="(isListGallery || isInline) && workersTemplateMode" class="flex-grow margin-right-lg">
                    <h1 class="mod-no-top">Module Templates</h1>
                    <p>
                        Add a ready to go Module from our built-in templates. Just connect the relevant data sources and
                        turn it on, or customize it to your needs.
                    </p>
                </div>
                <div class="rounded-box flex-vmiddle setup-group-built-in-search">
                    <span class="svg-icon-sm margin-left">
                        <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                    </span>
                    <input
                        class="form-control common-width-auto common-no-border common-no-outline"
                        placeholder="Search..."
                        ng-model="data.searchTerm"
                        ng-change="data.selectedList = null"
                    />
                </div>
            </div>

            <hr class="visible-xs" ng-if="!data.selectedList" />

            <div
                ng-if="data.searchTerm && data.searchTerm.length"
                class="
                    alert alert-info
                    common-size-xxxxs common-width-50
                    padding-normal-sm
                    margin-bottom-lg
                    flex-vmiddle
                "
            >
                <span class="flex-grow">Showing lists related to "{{ data.searchTerm }}".</span>
                <a ng-click="data.searchTerm = null">
                    <i class="fa fa-times margin-right-xs inline-block"></i>
                    Clear search
                </a>
            </div>

            <!-- List gallery -->
            <div ng-if="!data.selectedList && !data.enterCustomJson">
                <div class="flex-grow setup-group-built-in-gallery-container text-left margin-top-lg">
                    <p class="margin-left" ng-hide="data.searchTerm && data.searchTerm.length">
                        <strong>{{ data.selectedTab.title }}</strong>
                    </p>
                    <!--options-->
                    <div class="setup-group-built-in-gallery flex flex-wrap">
                        <div
                            class="setup-group-built-in-item flex flex-col"
                            ng-repeat="list in builtInLists"
                            ng-if="
                                (data.selectedTab &&
                                    (list.category === data.selectedTab.id ||
                                        (list.additionalCategoriesSet &&
                                            list.additionalCategoriesSet[data.selectedTab.id])) &&
                                    (!data.searchTerm || !data.searchTerm.length)) ||
                                (data.searchTerm &&
                                    data.searchTerm.length &&
                                    filterListBySearchTerm(list, data.searchTerm)) ||
                                ((isListGallery || isInline) &&
                                    !data.selectedTab &&
                                    (!data.searchTerm || !data.searchTerm.length))
                            "
                            ng-click="selectList(list)"
                        >
                            <!--ng-click="openPreview($event, list)">-->
                            <div class="setup-group-built-in-header common-bg flex-vmiddle {{ list.headerCss }}">
                                <div
                                    class="common-circle mod-full common-color-white opacity-90"
                                    ng-style="{ background: list.mainColor }"
                                >
                                    <i class="fa {{ categories[list.category].symbol }}"></i>
                                </div>
                            </div>
                            <div class="flex-grow padding-normal">
                                <div class="common-size-xs flex-no-shrink">
                                    <strong>{{ list.title }}</strong>
                                </div>
                                <p
                                    class="common-size-xxxxs common-ellipsis common-color-grey"
                                    ng-if="list.subtitle && workersTemplateMode"
                                >
                                    {{ list.subtitle }}
                                </p>
                                <p class="common-size-xxxxs common-ellipsis">
                                    {{ list.longDescription || list.description }}
                                </p>
                                <!--<p ng-if="!list.isExampleList" class="common-color-grey common-size-xxxxs flex-grow common-ellipsis">-->
                                <!--Guided setup: {{list.longDescription || list.description}}</p>-->
                                <div
                                    ng-if="list.isExampleList && !workersTemplateMode"
                                    class="common-size-xxxxs common-color-primary"
                                >
                                    <i class="fa fa-bolt margin-right"></i>
                                    Live Example & Quick Setup
                                </div>
                                <div
                                    ng-if="!list.isExampleList && !workersTemplateMode"
                                    class="common-size-xxxxs common-color-grey"
                                >
                                    <i class="fa fa-cogs margin-right"></i>
                                    Guided Setup
                                </div>

                                <div ng-if="workersTemplateMode">
                                    <!-- Integrations Icons and arrow -->
                                    <div class="flex-vmiddle flex-xs-wrap">
                                        <!-- Tonkean logo (when there are no integrations) -->
                                        <span class="workers-gallery-icon-container flex-vmiddle mod-justify-center">
                                            <span class="svg-icon svg-icon-lg mod-static padding-left-xxs">
                                                <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                                            </span>
                                        </span>

                                        <!-- Arrow -->
                                        <span class="svg-icon-xxxlg">
                                            <tnk-icon src="/images/icons/arrow-right-lg.svg"></tnk-icon>
                                        </span>

                                        <!-- Integrations icons -->
                                        <span
                                            ng-repeat="source in list.requiredIntegrations | limitTo:3"
                                            class="workers-gallery-icon-container mod-big-icon mod-margin-right-minus"
                                            uib-tooltip="{{ source.toLowerCase() }}"
                                            tooltip-placement="bottom"
                                            ng-style="{ zIndex: 100 - $index }"
                                        >
                                            <span
                                                class="inline-block initiative-integration-icon common-radius-50 mod-{{
                                                    source.toLowerCase()
                                                }}"
                                            ></span>
                                        </span>

                                        <!-- Bullet List -->
                                        <span
                                            class="workers-gallery-icon-container flex-vmiddle mod-justify-center"
                                            ng-if="!list.requiredIntegrations || list.requiredIntegrations.length === 0"
                                            ng-style="{ zIndex: 2 }"
                                        >
                                            <span
                                                class="
                                                    svg-icon-nav-sm svg-icon-hover-black
                                                    pointer
                                                    inline-block
                                                    common-color-grey3
                                                "
                                            >
                                                <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                                            </span>
                                        </span>

                                        <!-- Avatars -->
                                        <span
                                            class="workers-gallery-icon-container mod-big-icon mod-margin-left-minus"
                                            uib-tooltip="Auto Check-ins"
                                            tooltip-placement="bottom"
                                            ng-style="{ zIndex: 1 }"
                                        >
                                            <img
                                                class="inline-block avatar {{ source.iconClass }}"
                                                ng-src="/images/avatars/avatar-{{ ($index % 7) + 1 }}.png"
                                            />
                                        </span>

                                        <!-- "More" label (when there are integrations) -->
                                        <span
                                            class="
                                                workers-gallery-icon-container
                                                mod-wide
                                                common-color-grey3
                                                flex-vmiddle
                                            "
                                            ng-if="list.requiredIntegrations.length > 3"
                                            ng-style="{ zIndex: 2 }"
                                        >
                                            <span class="text-center padding-left">
                                                + {{ list.requiredIntegrations.length - 3 }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="setup-group-built-in-item-line"-->
                            <!--ng-style="{'background-color': list.mainColor }"></div>-->
                        </div>
                    </div>
                </div>

                <p
                    class="common-size-xxxxs margin-top-xlg margin-left-lg"
                    id="EMPTY"
                    ng-if="data.showGroupSettings && !isListGallery && !isInline"
                >
                    Choose a built-in list above or
                    <a href="#" ng-click="selectList(builtInLists.EMPTY)">start with an empty list</a>
                    .
                </p>
            </div>

            <!-- NEW built in lists - with example data -->
            <div ng-if="data.selectedList.isExampleList" class="margin-left example-list-container">
                <!-- Back sign -->
                <div class="flex-vmiddle margin-bottom">
                    <i class="pointer dropdown-chevron mod-static back-sign-chevron" ng-click="previousStep()"></i>
                    <div class="pointer" ng-click="previousStep()">Back</div>
                </div>

                <!-- Top Bar - Title & Create List button -->
                <div class="flex-vmiddle-no-xs margin-bottom common-title-sm margin-xs-right-lg">
                    <!-- Title -->
                    <h3 class="margin-none flex-grow">
                        <span class="margin-right">{{ data.selectedList.title }}</span>

                        <!-- Tags -->
                        <span
                            ng-repeat="tag in data.selectedList.tags"
                            class="
                                common-pill
                                inline-block
                                mod-thin
                                margin-right-xs
                                common-size-xxxxxs
                                margin-bottom-xs
                            "
                        >
                            {{ tag }}
                        </span>
                    </h3>

                    <!-- Create Button-->
                    <div class="hidden-xs margin-left" ng-include="'setup-group-create-button'"></div>
                </div>

                <!-- Description -->
                <div
                    class="common-color-dark-grey common-size-xxs description-container"
                    ng-bind-html="data.selectedList.longDescription"
                ></div>

                <div
                    class="margin-top margin-bottom flex-vmiddle-no-xs"
                    ng-if="data.selectedList.popularIntegrations && data.selectedList.popularIntegrations.length"
                >
                    <div class="common-color-grey common-size-xxxxs margin-right margin-xs-bottom-xs">
                        Popular with:
                    </div>
                    <div class="flex-vmiddle flex-xs-wrap">
                        <span
                            ng-repeat="source in data.selectedList.popularIntegrations"
                            uib-tooltip="{{ source.toLowerCase() }}"
                            class="
                                initiative-integration-icon
                                margin-bottom-xs margin-right-xs
                                mod-normal mod-circle
                                flex-no-shrink
                            "
                            ng-class="'mod-' + source.toLowerCase()"
                        ></span>
                        <i
                            class="margin-left margin-bottom-xs common-color-grey fa fa-ellipsis-h"
                            uib-tooltip="And many others..."
                        ></i>
                    </div>
                </div>

                <!--Triggers examples-->
                <div
                    class="margin-top margin-bottom flex-vmiddle-no-xs"
                    ng-if="data.selectedList.popularIntegrations && data.selectedList.popularIntegrations.length"
                >
                    <div class="common-color-grey common-size-xxxxs margin-right margin-xs-bottom-xs">
                        Example Bot Trigger:
                    </div>
                    <div class="flex-vmiddle flex-xs-wrap">
                        <span
                            ng-repeat="trigger in data.selectedList.triggersExample"
                            class="margin-right-xs flex-no-shrink"
                        >
                            <span class="trigger-example-tag">{{ trigger }}</span>
                            {{ !$last ? ' + ' : '' }}
                        </span>
                    </div>
                </div>

                <!-- Required Integrations -->
                <div ng-if="data.requiredIntegrationsStates">
                    <!-- Title -->
                    <h4>Required Data Sources</h4>

                    <!-- Required integrations message -->
                    <!--<div ng-if="data.startValidatingRequiredIntegrations &&-->
                    <!--(data.requiredIntegrationsStates | filter: {currentIntegration: { disabled: false} }).length !== data.requiredIntegrationsStates.length"-->
                    <!--class="common-color-danger">-->
                    <!--Must configure all required integrations:-->
                    <!--</div>-->

                    <div
                        ng-repeat="integrationState in data.requiredIntegrationsStates"
                        class="integration-group-exists common-width-100"
                    >
                        <!-- Success indicator -->
                        <div
                            ng-if="
                                integrationState.currentIntegration.valid &&
                                !integrationState.currentIntegration.disabled
                            "
                            class="integration-group-exists-indicator common-color-success"
                        >
                            <i class="fa fa-check-circle-o"></i>
                        </div>

                        <!-- Integration connection -->
                        <div>
                            <!-- Integration with no multiple instances-->
                            <div ng-if="!integrationState.supportsMultipleIntegrationsPerUser">
                                <!-- Not allowing clicking on connected valid integrations, since clicking caused the integrationState to be cleared and thus marked as not connected -->
                                <tnk-integration-group
                                    ng-class="{
                                        'pointer-events-none':
                                            integrationState.currentIntegration.valid &&
                                            !integrationState.currentIntegration.disabled
                                    }"
                                    sources="[integrationState.type.toLowerCase()]"
                                    ng-model="ignored"
                                    display-mode="button"
                                    current-integration="integrationState.currentIntegration"
                                    state="integrationState.state"
                                    integration-changed="integrationState.integrationChanged(changedProjectIntegration)"
                                    open-modal-for-configuration="true"
                                    display-new-prefix="false"
                                    wide-mode="true"
                                ></tnk-integration-group>
                            </div>

                            <!-- Possible multiple instances of integration -->
                            <div ng-if="integrationState.supportsMultipleIntegrationsPerUser">
                                <tnk-select-integration-instance
                                    integration-type="integrationState.type"
                                    project-integration-options="integrationState.existingProjectIntegrations"
                                    on-integration-selected="
                                        onExistingIntegrationSelected(integrationState, selectedIntegration)
                                    "
                                >
                                    <!-- Show the selected integration as a trigger to open the popover -->
                                    <div class="pointer">
                                        <tnk-integration-group
                                            class="pointer-events-none"
                                            sources="[integrationState.type.toLowerCase()]"
                                            ng-model="ignored"
                                            display-mode="button"
                                            button-postfix="{{
                                                ' (' +
                                                    integrationState.currentIntegration.displayName +
                                                    ') ' +
                                                    (integrationState.pastIntegrationName
                                                        ? integrationState.pastIntegrationName
                                                        : '')
                                            }}"
                                            current-integration="integrationState.currentIntegration"
                                            state="integrationState.state"
                                            integration-changed="integrationState.integrationChanged(changedProjectIntegration)"
                                            open-modal-for-configuration="true"
                                            display-new-prefix="false"
                                            wide-mode="true"
                                        ></tnk-integration-group>
                                    </div>
                                </tnk-select-integration-instance>
                            </div>
                        </div>

                        <!-- field match selector -->
                        <div
                            ng-if="data.isMakeBotFromJson"
                            class="margin-bottom-md common-border mod-radius field-matcher-container"
                        >
                            <button
                                class="
                                    pointer
                                    btn btn-default
                                    button-background-grey
                                    common-width-100
                                    flex-vmiddle
                                    mod-justify-space
                                "
                                type="button"
                                ng-click="loadFields(integrationState)"
                            >
                                <span class="common-size-15 button-text-font">Fields Match Configuration</span>
                                <span class="fa fa-chevron-down"></span>
                            </button>

                            <div uib-collapse="integrationState.isCollapsed" class="margin-top-md padding-normal-h">
                                <div ng-if="!integrationState.isLoadingExternalFields">
                                    <div
                                        ng-repeat="column in data.columns | filter:filterNonDefinitionFields(integrationState) | filter:{integrationUniqueType: integrationState.originalProjectIntegrationId}"
                                    >
                                        <!-- Original field -->
                                        <div class="flex-grow flex-vmiddle margin-bottom-md">
                                            <div class="common-width-33">
                                                {{ column.definition.FieldLabel }} ({{
                                                    column.definition.ExternalType
                                                }}):
                                            </div>

                                            <!-- New integration fields selector -->
                                            <ui-select
                                                class="flex-grow uib-select-fields-match-height margin-left-md"
                                                ng-change="fieldSelected(column.matchedEntity, column)"
                                                theme="bootstrap"
                                                ng-model="column.matchedEntity"
                                            >
                                                <ui-select-match placeholder="">
                                                    {{ column.matchedEntity.label }}
                                                    ({{ column.matchedEntity.entityDisplayName }})
                                                </ui-select-match>
                                                <ui-select-choices
                                                    repeat="field in integrationState.availableExternalFields | filter:{label:$select.search}"
                                                >
                                                    <div>{{ field.label }} ({{ field.entityDisplayName }})</div>
                                                </ui-select-choices>
                                            </ui-select>
                                        </div>
                                    </div>
                                </div>

                                <!-- Loading External Fields -->
                                <i ng-if="integrationState.isLoadingExternalFields" class="loading-medium"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create List button (Mobile) -->
                <div class="visible-xs margin-top-lg" ng-include="'setup-group-create-button'"></div>

                <span
                    class="flex margin-bottom-xs margin-xs-top margin-xs-bottom-xlg mod-justify-end-no-xs"
                    ng-if="data.selectedList.sandboxUrl"
                >
                    <span class="common-color-grey visible-xs-inline margin-right">Or</span>

                    <!-- Explore list link -->
                    <a class="common-bold flex-vmiddle" href="{{ data.selectedList.sandboxUrl }}" target="_blank">
                        <!-- Explore list link text -->
                        <span class="margin-right-xs common-color-black">Explore Live List</span>

                        <!-- Open in new tab icon -->
                        <span class="svg-icon-sm common-color-grey">
                            <tnk-icon src="/images/icons/open-in-new-tab.svg"></tnk-icon>
                        </span>
                    </a>
                </span>

                <!-- Sandbox -->
                <div class="sandbox-iframe-container" ng-if="data.selectedList.sandboxUrl">
                    <iframe
                        width="100%"
                        height="100%"
                        class="sandbox-iframe"
                        ng-src="{{ trustSrc(data.selectedList.sandboxUrl + '/embed') }}"
                        frameborder="0"
                        iframe-onload="onSandboxIFrameLoaded()"
                    ></iframe>
                </div>

                <!-- Screenshots -->
                <div
                    class="screenshot-container"
                    ng-if="data.selectedList.screenshotUrl && data.selectedList.screenshotUrl.length"
                >
                    <p>Preview:</p>
                    <div class="screenshot-img">
                        <img
                            ng-src="{{ data.selectedList.screenshotUrl }}"
                            width="{{
                                data.selectedList.screenshotWidth ? data.selectedList.screenshotWidth * 0.4 : null
                            }}"
                        />
                    </div>
                </div>
            </div>

            <!-- Custom Json-->
            <div ng-if="!data.selectedList && data.enterCustomJson">
                <p>Enter custom template (json):</p>

                <div>
                    <textarea ng-model="data.customJson" class="form-control" rows="10"></textarea>
                </div>

                <button class="btn btn-primary" ng-click="loadCustomJson(data.customJson)">Load</button>
            </div>
        </div>

        <!-- Modal content - setup steps (list selected) -->
        <div
            class="setup-group-modal-list-setup flex"
            ng-hide="posting || firstLoading"
            ng-if="data.selectedList && !data.selectedList.isExampleList"
        >
            <!-- Old built in lists - not with example data -->
            <div class="flex flex-grow text-center">
                <!-- Top line -->
                <div
                    class="setup-group-step-top-line"
                    ng-if="data.selectedList.id !== 'EMPTY'"
                    ng-style="{ 'background-color': data.selectedList.mainColor }"
                ></div>

                <!-- Step content -->
                <div class="flex-grow padding-normal common-close-btn-container">
                    <!-- Close button when in this mode -->
                    <div class="common-close-btn svg-icon-lg margin-normal-sm" ng-if="isModal" ng-click="close()">
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </div>

                    <div class="setup-group-step-container text-left">
                        <!-- Header -->
                        <div class="flex">
                            <h2 class="margin-none flex-grow">
                                {{
                                    data.selectedList.category
                                        ? categories[data.selectedList.category].title + ':'
                                        : ''
                                }}&nbsp;{{ data.selectedList.shortTitle }}
                            </h2>
                            <!-- Hide the preview button if we're creating an empty list (a list with just the list setup step). -->
                            <span
                                ng-if="data.showGroupSettings ? data.selectedList.steps.length > 1 : true"
                                ng-click="openPreview($event, data.selectedList)"
                                class="common-size-xxxxs common-color-dark-grey text-uppercase margin-top pointer"
                            >
                                Preview
                            </span>
                        </div>

                        <!-- Progress bar -->
                        <!--<div class="common-loading-bar margin-top">-->
                        <!--<div class="common-loading-bar-progress"-->
                        <!--ng-style="{ 'width' : (((data.currentStepIndex + 1) / (data.steps.length)) * 100) + '%' }"></div>-->
                        <!--</div>-->

                        <div class="flex margin-top-xlg">
                            <div class="flex-no-shrink margin-right">
                                <div class="common-circle mod-primary mod-bold">
                                    {{
                                        data.steps[data.currentStepIndex].actualIndex
                                            ? data.steps[data.currentStepIndex].actualIndex + 1
                                            : data.currentStepIndex + 1
                                    }}
                                </div>
                            </div>

                            <!-- Step content -->
                            <div
                                class="flex-grow padding-normal-sm-h"
                                ng-if="!data.loadingStep"
                                ng-switch="data.currentStep.type"
                            >
                                <!-- Integration -->
                                <div ng-switch-when="integration">
                                    <div class="common-size-xs padding-top-xs">
                                        <strong>Connect your {{ data.currentStep.data.title }}:</strong>
                                    </div>
                                    <p class="common-size-xxs">{{ data.currentStep.data.subtitle }}</p>

                                    <!-- Integrations button selection -->
                                    <tnk-integration-selection
                                        current-integration="data.currentIntegration"
                                        integration-state="data.integrationState"
                                        integrations-settings="integrationsSettings"
                                        not-connected-native-sources="data.sources"
                                        reset-other-search-box="data.resetIntegrationSelectionSearch"
                                        select-provider="selectProvider(projectIntegration)"
                                        on-integration-saved="onIntegrationSaved(overrideState)"
                                        open-modal-for-configuration="true"
                                        on-current-integration-changed="onCurrentIntegrationChanged(currentIntegration)"
                                        display-only-not-connected-native-sources-in-search="data.selectedList.displayOnlyAllowedNativeIntegrationsInSearch"
                                        ng-class="{ 'inline-block': !data.currentIntegration }"
                                    ></tnk-integration-selection>

                                    <!-- Upgrade required message-->
                                    <!--<div ng-if="!pm.isUserLicensed || pm.project.isInTrial"-->
                                    <!--class="margin-top-lg common-bg mod-blue-light box-with-shadow padding-normal">-->
                                    <!--<p>Using integrations requires <a ng-click="modalUtils.openUpgradeModal()">upgrading-->
                                    <!--your account.</a></p>-->
                                    <!--<p><strong>To experience the core value of the Tonkean Bot</strong> you don't have-->
                                    <!--to connect to your systems.<br/>You can simply continue without it and connect-->
                                    <!--your integrations later.</p>-->
                                    <!--<div class="margin-top">-->
                                    <!--<a class="btn btn-primary"-->
                                    <!--ng-click="data.currentStep.optional ? nextStep() : previousStep()"> Continue-->
                                    <!--without integrations</a>-->
                                    <!--</div>-->
                                    <!--</div>-->
                                </div>

                                <!-- Config -->
                                <div ng-switch-when="config">
                                    <form
                                        name="forms.setupList"
                                        ng-submit="submitList(data.currentStepIndex >= (data.steps.length - 1))"
                                    >
                                        <div
                                            ng-if="data.currentStep.data.controller"
                                            ng-include="'./setupGroupTemplateSwitch.template.html'"
                                            ng-controller="data.currentStep.data.controller"
                                        ></div>
                                        <!-- Need to do this twice, cause ng-controller don't know how to handle null -->
                                        <div
                                            ng-if="!data.currentStep.data.controller"
                                            ng-include="'./setupGroupTemplateSwitch.template.html'"
                                        ></div>

                                        <div
                                            ng-if="!valueResults['SYNC_VALIDITY'].hasError"
                                            class="text-right margin-top-lg padding-top-lg"
                                        >
                                            <button
                                                class="btn btn-primary"
                                                ng-if="data.steps.length - 1 > data.currentStepIndex"
                                                type="submit"
                                                ng-disabled="!data.isGroupValid"
                                            >
                                                Continue
                                            </button>
                                            <button
                                                class="btn btn-primary"
                                                ng-if="data.steps.length - 1 === data.currentStepIndex"
                                                type="submit"
                                                ng-disabled="!data.isGroupValid"
                                            >
                                                Complete
                                            </button>
                                            <!-- Loading-->
                                            <span class="margin-left-md" ng-if="data.loadingGroupCreation">
                                                <i class="loading-medium"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <!-- Config -->
                                <div ng-switch-when="plainConfig">
                                    <div
                                        ng-if="data.currentStep.data.controller"
                                        ng-include="'./setupGroupTemplateSwitch.template.html'"
                                        ng-controller="data.currentStep.data.controller"
                                    ></div>
                                    <!-- Need to do this twice, cause ng-controller don't know how to handle null -->
                                    <div
                                        ng-if="!data.currentStep.data.controller"
                                        ng-include="'./setupGroupTemplateSwitch.template.html'"
                                    ></div>
                                </div>

                                <!-- Preview -->
                                <div ng-switch-when="preview" ng-controller="AdvancedSyncCtrl">
                                    <!-- Live sync control when we don't have a group to duplicate (regular built in list) -->
                                    <div
                                        ng-init="calculateDefinition(valueResults['SYNC_CONTROL'].createDefinitionFromCustomFilters())"
                                        ng-if="!data.selectedList.duplicateSync"
                                    >
                                        <!-- Live sync control -->
                                        <tnk-live-sync
                                            control="valueResults['PREVIEW_SYNC_CONTROL']"
                                            custom-defined-view="valueResults['SYNC_CONTROL'].customDefinedView"
                                            project-integration="integrationsResults[data.selectedList.sync.integrationType]"
                                            copy-properties-from-definition="true"
                                            calculate-plural-entity-name="calculatePluralEntityName"
                                            sync-validity-state="valueResults['SYNC_VALIDITY']"
                                            existing-definition="valueResults['SYNC_DEFINITION']"
                                            selected-entity-type="valueResults['SYNC_ENTITY']"
                                            is-special-entity="data.filtersIsSpecialEntity || false"
                                            fallback-entity-type="data.filtersFallbackEntityType || null"
                                            view-type="viewTypes.custom"
                                            hide-manage-triggers-modal="true"
                                            override-activate-bot-object="isBotActiveData"
                                        ></tnk-live-sync>
                                    </div>

                                    <!-- Live sync control for when we have a group to duplicate -->
                                    <div ng-if="data.selectedList.duplicateSync">
                                        <tnk-live-sync
                                            control="data.duplicateSyncControl"
                                            custom-defined-view="data.selectedList.duplicateSync.viewType === 'Custom'"
                                            existing-definition="data.selectedList.duplicateSync.viewData"
                                            project-integration="data.selectedList.duplicateSync.projectIntegration"
                                            sync-validity-state="valueResults['SYNC_VALIDITY']"
                                            selected-entity-type="data.selectedList.duplicateSync.viewData.entity"
                                            hide-filters="data.selectedList.duplicateSync.viewType !== 'Custom'"
                                            view-type="data.selectedList.duplicateSync.viewType"
                                            integration-views="data.duplicateSyncControl.getAllIntegrationViews"
                                            override-activate-bot-object="isBotActiveData"
                                        ></tnk-live-sync>
                                    </div>

                                    <!-- Continue (or complete) button -->
                                    <div class="margin-top-lg" ng-if="!valueResults['SYNC_VALIDITY'].isLoading">
                                        <button
                                            class="btn btn-primary"
                                            type="button"
                                            ng-disabled="valueResults['SYNC_VALIDITY'].isOverItemsLimitation"
                                            ng-click="submitList(data.currentStepIndex >= (data.steps.length - 1), true)"
                                        >
                                            {{
                                                data.steps.length - 1 > data.currentStepIndex
                                                    ? 'Save & continue'
                                                    : 'Complete'
                                            }}
                                        </button>
                                    </div>
                                </div>
                                <div ng-switch-when="previewManual" ng-controller="SetupIntegrationTypeCtrl">
                                    <tnk-sync-preview
                                        load-preview-items-promise="previewPromise"
                                        hide-manage-triggers-modal="true"
                                        load-on-init="true"
                                        override-activate-bot-object="isBotActiveData"
                                    ></tnk-sync-preview>
                                    <div class="margin-top">
                                        <button
                                            class="btn btn-primary"
                                            type="button"
                                            ng-click="submitList(data.currentStepIndex >= (data.steps.length - 1), true)"
                                        >
                                            {{
                                                data.steps.length - 1 > data.currentStepIndex
                                                    ? 'Save & continue'
                                                    : 'Complete'
                                            }}
                                        </button>
                                    </div>
                                </div>

                                <!-- Skip button -->
                                <div
                                    class="margin-top-xlg text-right"
                                    ng-if="
                                        data.currentStep.optional &&
                                        (data.currentStepIndex > 0 || data.currentStep.type !== 'integration')
                                    "
                                >
                                    <hr />
                                    <span>
                                        <span class="common-color-grey">or</span>
                                        <a class="btn btn-default margin-left" ng-click="nextStep()">
                                            Skip Step
                                            <i class="fa fa-arrow-right margin-left-xs common-color-dark-grey"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Error message -->
                        <div
                            class="margin-top common-size-xxs padding-left margin-left-xlg text-danger"
                            ng-if="!posting && error"
                        >
                            <strong>An error has occurred:&nbsp;</strong>
                            <span>{{ error | error }}</span>
                        </div>
                    </div>
                </div>

                <!-- Side bar-->
                <div
                    class="setup-group-step-sidebar"
                    ng-if="
                        data.selectedList.id !== 'EMPTY' &&
                        data.selectedList.id !== 'DUPLICATE' &&
                        !data.selectedList.isExampleList
                    "
                    ng-init="data.sidebarColor = utils.hexToRgbaWithOpacity(data.selectedList.mainColor, 0.1)"
                    ng-class="{ 'mod-shrinked': data.currentStepIndex === data.steps.length - 1 }"
                    ng-style="{ 'background-color': data.sidebarColor }"
                >
                    <div class="side-bar-content" id="setup-group-side-bar">
                        <!-- List overview -->
                        <div ng-if="data.currentStepIndex === 0">
                            <!--list details-->
                            <div class="box-with-shadow padding-normal text-left">
                                <h4 class="margin-top-none margin-bottom header-font">{{ data.selectedList.title }}</h4>
                                <div class="common-size-xxs common-color-grey hidden-xs">
                                    {{ data.selectedList.subtitle }}
                                </div>
                                <div class="margin-top-lg margin-bottom">{{ data.selectedList.description }}</div>
                            </div>

                            <!--preview-->
                            <div
                                class="box-with-shadow padding-normal text-left margin-top"
                                ng-if="data.selectedList.frames && data.selectedList.frames.length"
                            >
                                <h4 class="margin-top-none margin-bottom header-font">Preview an example</h4>
                                <div
                                    class="relative pointer margin-normal-sm-v"
                                    ng-click="openPreview($event, data.selectedList)"
                                >
                                    <div class="absolute-fill-view flex-vmiddle flex-justify-space-around">
                                        <i class="fa fa-play-circle common-color-black common-size-50 opacity-40"></i>
                                    </div>
                                    <img
                                        ng-src="{{ data.selectedList.frames[0].thumbnail }}"
                                        width="100%"
                                        class="img-rounded"
                                    />
                                </div>
                                <div>
                                    or check out a
                                    <a href="https://sandbox.tonkean.com/group/link/5yrym9T1uriqQSMt" target="_blank">
                                        Sandbox list
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- Specific step info -->
                        <div ng-if="data.currentStepIndex > 0">
                            <div ng-include="'./setupGroupInfoTemplateSwitch.template.html'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script type="text/ng-template" id="setup-group-create-button">
    <!-- Create List button (Authenticated) -->
    <!-- Authenticated users will see a button with tnk-require on it. -->
    <button type="button" class="btn btn-primary btn-lg" ng-disabled="data.createExampleListButtonClicked"
            ng-if="data.isUserAuthenticated" ng-click="createExampleList()">
        Create {{workersTemplateMode ? 'Module' : 'List'}}
    </button>
    <!-- Create List button (Anonymous) -->
    <button type="button" class="btn btn-primary btn-lg"
            ng-if="!data.isUserAuthenticated" ng-click="createExampleList()">
        Create {{workersTemplateMode ? 'Module' : 'List'}}
    </button>
</script>
