import React from 'react';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import { daysDiffInSentenceFilter } from '@tonkean/shared-services';

const answerTypes = [
    {
        priority: 1,
        shortTitle: 'Updated status',
        predicate: (answer) => answer.metadata.newState || answer.metadata.newUpdateText,
        getLongTitle: (answer) => {
            if (answer.metadata.newUpdateText) {
                return <div>Gave an update: {answer.metadata.newUpdateText}</div>;
            } else {
                return (
                    <div>
                        Changed the status: {answer.metadata.oldState ? `from ${answer.metadata.oldState}` : null}
                        {answer.metadata.newState}
                    </div>
                );
            }
        },
    },
    {
        priority: 2,
        shortTitle: 'ETA',
        predicate: (answer) => answer.metadata.newEta,
        getLongTitle: (answer) => <div>Changed the ETA to {new Date(answer.metadata.newEta).toLocaleDateString()}</div>,
    },
    {
        priority: 3,
        shortTitle: 'Postponed follow-up',
        predicate: (answer) => answer.metadata.manualNextGather,
        getLongTitle: (answer) => (
            <div>Asked to be reached {daysDiffInSentenceFilter(answer.metadata.manualNextGather)}</div>
        ),
    },
    {
        priority: 4,
        shortTitle: 'New owner',
        predicate: (answer) => answer.metadata.newOwner,
        getLongTitle: (answer) => <div>Set {answer.metadata.newOwner.name} as owner</div>,
    },
    {
        priority: 5,
        shortTitle: 'Marked read',
        predicate: (answer) => answer.metadata.markedRead,
        getLongTitle: () => <div>Marked as read</div>,
    },
];

export default class BotHistoryItemAnswer extends React.Component {
    render() {
        if (!this.props.answers || !this.props.answers.length) {
            return null;
        }

        // Map the answer type to a corresponding answer
        const answerToTypePairs = answerTypes
            .map((type) => {
                for (let i = 0; i < this.props.answers.length; i++) {
                    const answer = this.props.answers[i];
                    if (type.predicate(answer)) {
                        return { answer, type };
                    }
                }

                return null;
            })
            // Filter nulls
            .filter((answer) => answer)
            // Sort by priority
            .sort((a, b) => {
                return a.type.priority - b.type.priority;
            });

        if (!answerToTypePairs || !answerToTypePairs.length) {
            return null;
        }

        let answer = null;
        if (answerToTypePairs.length === 1) {
            const pair = answerToTypePairs[0];
            answer = pair.type.getLongTitle(pair.answer);
        } else {
            answer = `${answerToTypePairs[0].type.shortTitle}, ${answerToTypePairs[1].type.shortTitle}`;
            if (answerToTypePairs.length > 2) {
                answer += ` + ${answerToTypePairs.length - 2} fields`;
            }
        }

        // Take the actor of the most important answer
        const actor = answerToTypePairs[0].answer.actor;

        return (
            <div className="bot-history-answer-container margin-top common-size-xxxxs padding-normal-sm flex-vmiddle">
                <div className="tnk-tooltip mod-top avatar-main avatar-sm margin-right flex-no-shrink">
                    {actor && actor.name && <span className="tnk-tooltip-text">{actor.name}</span>}
                    <TonkeanAvatar owner={actor} />
                </div>

                <div>{answer}</div>
            </div>
        );
    }
}
