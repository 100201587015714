import SeparatorWidgetEditor from './Components/SeparatorWidgetEditor';
import SolutionSiteSeparatorWidget from './Components/SolutionSiteSeparatorWidget';
import type SeparatorWidgetConfiguration from './SeparatorWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSiteSeparatorWidgetPackage: SolutionSitePageWidgetPackage<SeparatorWidgetConfiguration> = {
    displayComponent: SolutionSiteSeparatorWidget,
    configurationComponent: SeparatorWidgetEditor,
};

export default SolutionSiteSeparatorWidgetPackage;
