import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionTestPageResponseViewTabType from './ActionTestPageResponseViewTabType';
import tryPrettifyJsonText from '../../../utils/tryPrettifyJsonText';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    BasicKeyValueTable,
    DisabledTextareaWithExtendModal,
    Tab,
    Tabs,
    TabsBorderType,
} from '@tonkean/infrastructure';
import type { ProjectIntegrationActionTestRunHTTPResponse } from '@tonkean/tonkean-entities';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    margin-top: 25px;
`;

interface Props {
    response?: ProjectIntegrationActionTestRunHTTPResponse;
    isLoading: boolean;
}

const ActionTestPageRequestView: React.FC<Props> = ({ response, isLoading }) => {
    const [selectedTab, setSelectedTab] = useState<ActionTestPageResponseViewTabType>();

    // On response return we navigate to RESPONSE_BODY tab.
    useEffect(() => {
        if (response) {
            setSelectedTab(ActionTestPageResponseViewTabType.RESPONSE_BODY);
        }
    }, [response]);

    const prettifyJsonBody = useMemo(() => {
        return tryPrettifyJsonText(response?.body || '');
    }, [response?.body]);

    // Memo for headers.
    const responseHeaders = useMemo(() => {
        try {
            return JSON.parse(response?.responseHeadersString || '{}');
        } catch {
            return {};
        }
    }, [response?.responseHeadersString]);

    return (
        <>
            <Tabs
                showBackground={false}
                value={selectedTab}
                onChange={setSelectedTab}
                borderType={TabsBorderType.RAILWAY}
                height="46px"
                hideIndicator={!response}
            >
                <Tab label={ActionTestPageResponseViewTabType.RESPONSE_BODY} disabled={!response}>
                    {ActionTestPageResponseViewTabType.RESPONSE_BODY}
                </Tab>

                <Tab label={ActionTestPageResponseViewTabType.HEADERS} disabled={!response}>
                    {ActionTestPageResponseViewTabType.HEADERS}
                </Tab>
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <StyledAnimatedSwitchItem label={ActionTestPageResponseViewTabType.RESPONSE_BODY}>
                    <DisabledTextareaWithExtendModal text={prettifyJsonBody} width="560px" height="160px" />
                </StyledAnimatedSwitchItem>

                <StyledAnimatedSwitchItem label={ActionTestPageResponseViewTabType.HEADERS}>
                    <BasicKeyValueTable isLoading={isLoading} keyValueRecord={responseHeaders} keyColumnWidth={200} />
                </StyledAnimatedSwitchItem>
            </AnimatedSwitch>
        </>
    );
};

export default ActionTestPageRequestView;
