import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ArraySumFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.ARRAY_SUM;
    override readonly sign = 'ArraySum';
    override readonly displayName = 'Array Sum';
    override readonly description =
        'Will sum elements there are in a concatenated string, after splitting by the given separator.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input Array',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Separator',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: ',',
        },
    ];
}
