import UploadFilesForm from './UploadFilesForm';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkUploadFilesForm',
        reactToAngular(UploadFilesForm, [
            'form',
            'primaryColor',
            'secondaryColor',
            'buttonsColor',
            'formFinished',
            'isInPreview',
            'canSkip',
            'initiativeId',
            'customTriggerId',
            'workflowVersionType',
            'onSubmit',
            'workerRunId',
            'formIndex',
            'onBackClicked',
            'showBack',
            'submittedFiles',
        ]),
    );
