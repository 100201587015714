import { getStateError } from '@tonkean/utils';
import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';
import { WORKER_TYPES } from '@tonkean/constants';
import { countQueryFilters, getDefaultTonkeanQuery, getTonkeanQueryParticipatingFilters } from '@tonkean/tonkean-utils';

/* @ngInject */
function LogicWorkerComponentCtrl(
    $scope,
    $rootScope,
    $q,
    $timeout,
    tonkeanUtils,
    utils,
    modalUtils,
    modal,
    projectManager,
    tonkeanService,
    customTriggerManager,
    consts,
    integrations,
    integrationsConsts,
    customFieldsManager,
    requestThrottler,
    groupInfoManager,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
) {
    const ctrl = this;
    const logicBlockTypes = consts.getLogicBlockTypes();
    const conditionBlockTypes = consts.getConditionBlockTypes();
    const logicConfigModes = consts.getLogicConfigModes();

    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.ctm = customTriggerManager;
    $scope.scm = syncConfigCacheManager;

    const peopleCoordinationActions = [
        logicBlockTypes.PERSON_INQUIRY,
        logicBlockTypes.GATHER_UPDATE,
        logicBlockTypes.ASK_FIELD_UPDATE,
        logicBlockTypes.SEND_FORM,
        logicBlockTypes.SEND_ITEM_INTERFACE,
        logicBlockTypes.APPROVAL_CYCLE,
        logicBlockTypes.NEXT_STEPS,
        logicBlockTypes.MANUAL_OWNER_UPDATE,
        logicBlockTypes.SEND_NOTIFICATION,
    ];

    const workflowActions = [
        logicBlockTypes.MANUAL_FIELD_UPDATE,
        logicBlockTypes.TONKEAN_ACTION,
        logicBlockTypes.NLP_PROCESSOR,
        logicBlockTypes.TRAIN_TRIGGER,
        logicBlockTypes.MANUAL_NEXT_STEPS,
        logicBlockTypes.DELAY,
        logicBlockTypes.INNER_AUTONOMOUS,
    ];

    if ($rootScope.features[projectManager.project.id].tonkean_feature_show_sync_inner_matched_entity) {
        workflowActions.push(logicBlockTypes.SYNC_INNER_MATCHED_ENTITY);
    }

    workflowActions.push(logicBlockTypes.TEXT_EXTRACTOR);

    if (projectManager.projectData?.executeOcrUsingGoogleCloudForPdfFile === true) {
        workflowActions.push(logicBlockTypes.OCR_CONVERSION);
    }

    if ($rootScope.features[projectManager.project.id].tonkean_feature_front_door_action) {
        workflowActions.push(logicBlockTypes.FRONT_DOOR_ACTION);
    }

    const dataActions = [logicBlockTypes.PERFORM_INTEGRATION_ACTION, logicBlockTypes.OUTGOING_WEBHOOK];

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        workflowVersionType: ctrl.workflowVersionType,
        isPublishedWorkflowVersion: null,
        configuredLogic: ctrl.configuredLogic,
        displaysHistoryVersion: ctrl.displaysHistoryVersion,
        includeSpaceFillerForWarningMessage: ctrl.includeSpaceFillerForWarningMessage,
        syncObject: ctrl.syncObject || {},
        invalidLogics: ctrl.invalidLogics,
        isEditAllowed: ctrl.isEditAllowed,
        createMultipleLogicComponents: ctrl.createMultipleLogicComponents,
        getSpecialChildren: ctrl.getSpecialChildren,
        removeSpecialChildren: ctrl.removeSpecialChildren,
        openFieldModal: ctrl.openFieldModal,
        viewOnly: ctrl.viewOnly,
        noHeader: ctrl.noHeader,
        filtersResultMap: ctrl.filtersResultMap,
        logicBlockTypes,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        integrationGroups: integrations.getIntegrationGroups(),

        configuredLogicInitialized: false,

        workerTypes: WORKER_TYPES,
        alreadyCreatedFilterKeys: {},

        selectedLogicTypeConfiguration: null,
        actionDefinitionObject: null,

        showWorkerItemContextTypeOptions: false,

        existingMonitorFieldDefinitions: null,

        recurrenceTimeSelection: null,
        existingRecurrenceTimeSelection: null,

        customTriggerIdToLinkedFieldDefinitionsMap: {},
        customTriggerIdTolinkedFieldDefinitionsConfiguration: [],
        hideDetailedLInkedFieldDefinitionsSections: true,

        // Monitor inner items
        loadingSavingMonitorInnerItems: false,
        errorSavingMonitorInnerItems: null,
        monitorInnerItems: null,

        selectedWorkerItemContextType: null,
        workerItemContextTypes: ['ORIGINAL_ITEM', 'TRIGGERED_ACTION_ITEM'],
        workerItemContextTypeApiNameToDisplayName: {
            ORIGINAL_ITEM: 'root monitored item',
            TRIGGERED_ACTION_ITEM: 'created action item',
        },

        selectedTriggerType: null,
        reloadFields: true,
        control: {},

        // Display name edit mode
        editedLogicName: ctrl.configuredLogic.node.displayName,
        displayEditLogicName: false,

        // Save display name to server
        loadingSavingLogicDisplayName: false,
        errorSavingLogicDisplayName: null,

        // Save custom trigger to server
        isSaving: false, // A save is queued in the throttler
        loadingSavingLogic: false, // A save method is in progress
        errorSavingLogic: null,
        pendingSave: false,
        shouldEmitActionTypeChanged: false,

        loadingSavingLogicState: false,
        showBigLoading: false,
        errorSavingLogicState: null,

        doNotRunOnWeekends: false,

        monitorForms: [],

        // Logic types configuration
        logicConfigurationTypesMap: {
            categories: {
                PEOPLECOORDINATION: {
                    title: 'PEOPLE COORDINATION',
                    logicTypeConfigurations: peopleCoordinationActions,
                },
                WORKFLOW: {
                    title: 'WORKFLOW',
                    logicTypeConfigurations: workflowActions,
                },
                DATA: {
                    title: 'DATA ACTIONS',
                    logicTypeConfigurations: dataActions,
                },
            },
        },
        logicConfigurationTypes: {
            categories: [],
        },
        logicTypeToConfigurationMap: {},

        // Condition types configuration
        conditionTypeConfigurations: [conditionBlockTypes.ALWAYS, conditionBlockTypes.FILTER],

        logicConfigModes,
        logicConfigMode: 'selectType',

        showConditionsConfiguration: true,
        selectedProjectIntegration: '',

        selectedOnType: null,
        onTypes: {
            all: 'ALL',
            filter: 'FILTER',
        },
        stateConfigurationToSave: {},

        customTriggerSecondaryType: null,

        workflowFolderProjectIntegrationsAccess: ctrl.workflowFolderProjectIntegrationsAccess,
        searchAction: null,
        skipWhenConditionNotMet: ctrl.configuredLogic.node.skipWhenConditionNotMet,
        dontRunOnDone: ctrl.configuredLogic.node.dontRunOnDone,
        runAlsoOnNewItems: ctrl.configuredLogic.node.runAlsoOnNewItems,
        runAlsoOnIntakeItems: ctrl.configuredLogic.node.runAlsoOnIntakeItems,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        if (!$scope.data.invalidLogics) {
            $scope.data.invalidLogics = {};
        }

        $scope.data.logicConfigurationTypes.categories = [
            $scope.data.logicConfigurationTypesMap.categories.PEOPLECOORDINATION,
            $scope.data.logicConfigurationTypesMap.categories.DATA,
            $scope.data.logicConfigurationTypesMap.categories.WORKFLOW,
        ];

        evaluateSelectedConditionTypeConfiguration();

        $scope.data.logicTypeToConfigurationMap = consts.getLogicBlockTypesMap();

        initializeConfigurationObject();

        // Load Selected Integration
        if (
            $scope.data.configuredLogic.node.projectIntegrationIds &&
            $scope.data.configuredLogic.node.projectIntegrationIds.length
        ) {
            if (
                projectManager.project.projectIntegrationIdDict[
                    $scope.data.configuredLogic.node.projectIntegrationIds[0]
                ]
            ) {
                $scope.data.selectedProjectIntegration =
                    projectManager.project.projectIntegrationIdDict[
                        $scope.data.configuredLogic.node.projectIntegrationIds[0]
                    ];
            } else if (
                projectManager.groupsMap[$scope.data.groupId].projectIntegrationIdDict &&
                projectManager.groupsMap[$scope.data.groupId].projectIntegrationIdDict[
                    $scope.data.configuredLogic.node.projectIntegrationIds[0]
                ]
            ) {
                $scope.data.selectedProjectIntegration =
                    projectManager.groupsMap[$scope.data.groupId].projectIntegrationIdDict[
                        $scope.data.configuredLogic.node.projectIntegrationIds[0]
                    ];
            }
        }

        $scope.data.syncObject.reloadLogic = $scope.reloadLogic;
        $scope.data.syncObject.setBigLoadingCircle = (value) => ($scope.data.showBigLoading = value);
        $scope.data.syncObject.getExistingSavePromise = () => $scope.data.saveOperationPromise;

        getLinkedFieldDefinitions();
    };

    /**
     * Gets the linked field definitions for the logic.
     */
    function getLinkedFieldDefinitions() {
        if (!$scope.data.customTriggerIdToLinkedFieldDefinitionsMap) {
            $scope.data.customTriggerIdToLinkedFieldDefinitionsMap = {};
        }

        if (!$scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id]) {
            $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id] = {};
        }

        return tonkeanService
            .getCustomTriggerLinkedFieldDefinitions($scope.data.workflowVersionId, $scope.data.configuredLogic.node.id)
            .then((data) => {
                if (data.linkedFieldTypeToFieldDefinitionIdMap) {
                    for (const linkedFieldType in data.linkedFieldTypeToFieldDefinitionIdMap) {
                        if (data.linkedFieldTypeToFieldDefinitionIdMap.hasOwnProperty(linkedFieldType)) {
                            const fieldDefinitionId = data.linkedFieldTypeToFieldDefinitionIdMap[linkedFieldType];
                            $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id][
                                linkedFieldType
                            ] = utils.findFirst(
                                customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                                (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
                            );
                        }
                    }
                }

                return $q.resolve();
            });
    }

    /**
     * If no filters in the query set condition to be always.
     */
    function evaluateSelectedConditionTypeConfiguration() {
        const filters = getTonkeanQueryParticipatingFilters(
            $scope.data.configuredLogic.node.queryDefinition.query,
        );

        if (filters.length > 0) {
            $scope.data.selectedConditionTypeConfiguration = conditionBlockTypes.FILTER;
        } else {
            $scope.data.selectedConditionTypeConfiguration = conditionBlockTypes.ALWAYS;
        }
    }

    function onConfigredLogicChanged() {
        if (!$scope.data.isSaving) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.data.configuredLogic = ctrl.configuredLogic;
            $scope.data.monitorInnerItems = $scope.data.configuredLogic.node.monitorInnerItems;
            $scope.data.runOneTimeOnly = $scope.data.configuredLogic.node.runOneTimeOnly;
            $scope.data.editedLogicName = ctrl.configuredLogic.node.displayName;
            $scope.data.skipWhenConditionNotMet = ctrl.configuredLogic.node.skipWhenConditionNotMet;
            $scope.data.dontRunOnDone = ctrl.configuredLogic.node.dontRunOnDone;
            $scope.data.runAlsoOnNewItems = ctrl.configuredLogic.node.runAlsoOnNewItems;
            $scope.data.runAlsoOnIntakeItems = ctrl.configuredLogic.node.runAlsoOnIntakeItems;
            // Re-setting the control object so that the state doesnt stick
            $scope.data.control = {};
            $scope.data.displayEditLogicName = false;
            $scope.data.customTriggerSecondaryType = null;

            evaluateSelectedConditionTypeConfiguration();

            if ($scope.data.configuredLogic.node.isScheduled) {
                $scope.data.selectedTriggerType = 'ON_SCHEDULE';
            } else if (
                $scope.data.configuredLogic.node.monitorFieldDefinitions &&
                $scope.data.configuredLogic.node.monitorFieldDefinitions.length
            ) {
                $scope.data.selectedTriggerType = 'ON_FIELD_CHANGE';
            } else if (
                $scope.data.configuredLogic.node.monitorForms &&
                $scope.data.configuredLogic.node.monitorForms.length
            ) {
                $scope.data.selectedTriggerType = 'ON_INITIATIVE_CREATED_BY_FORM';
            } else {
                $scope.data.selectedTriggerType = 'IMMEDIATELY_ON_MATCH';
            }

            // Existing monitor field definitions
            if (
                $scope.data.configuredLogic.node.monitorFieldDefinitions &&
                $scope.data.configuredLogic.node.monitorFieldDefinitions.length
            ) {
                const fieldDefinitionMap = utils.createMapFromArray(
                    customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                    'id',
                );
                $scope.data.existingMonitorFieldDefinitions =
                    $scope.data.configuredLogic.node.monitorFieldDefinitions.map(
                        (fieldDefinition) => fieldDefinitionMap[fieldDefinition.id],
                    );
            } else {
                $scope.data.monitorFieldDefinitions = [];
            }

            $scope.data.existingRecurrenceTimeSelection = $scope.data.configuredLogic.node;
            $scope.data.selectedWorkerItemContextType =
                $scope.data.configuredLogic.node.workerItemContextType || 'ORIGINAL_ITEM';

            if (
                $scope.data.configuredLogic.node.queryDefinition &&
                countQueryFilters($scope.data.configuredLogic.node.queryDefinition.query) > 0
            ) {
                $scope.data.selectedOnType = $scope.data.onTypes.filter;
            } else {
                $scope.data.selectedOnType = $scope.data.onTypes.all;
            }

            // If logic has no type defined go to type selection
            if ($scope.data.configuredLogic.node && $scope.data.configuredLogic.node.customTriggerType === 'UNKNOWN') {
                $scope.data.logicConfigMode = 'selectType';
            }

            // Close the filter configuration on changing logic
            $scope.data.showConditionsConfiguration = true;

            $scope.data.workingOn = customTriggerManager.getWorkingOnLabelInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node,
            );
            if ($scope.data.configuredLogic.node.monitorForms) {
                $scope.data.monitorForms = $scope.data.configuredLogic.node.monitorForms;
            } else {
                $scope.data.monitorForms = [];
            }

            initializeConfigurationObject();

            $scope.data.configuredLogicInitialized = true;
        }
    }

    $scope.$watch('data.configuredLogic.node.customTriggerType', function () {
        $scope.data.isTonkeanAction = $scope.data.configuredLogic.node.customTriggerType === 'TONKEAN_ACTION';
        $scope.data.tonkeanActionsActionType =
            $scope.data.configuredLogic.node.customTriggerActions?.[0]?.customTriggerActionDefinition?.actionType;
        $scope.data.isCreateTrackAction =
            $scope.data.isTonkeanAction && $scope.data.tonkeanActionsActionType === 'CREATE_TRACK';
    });

    /**
     * Occurs on changes to component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic || changesObj.workflowVersionId) {
            $scope.data.configuredLogicInitialized = false;
            $scope.data.searchAction = '';
            onConfigredLogicChanged();
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = changesObj.previewEvaluationSource.currentValue;
        }

        if (changesObj.includeSpaceFillerForWarningMessage) {
            $scope.data.includeSpaceFillerForWarningMessage =
                changesObj.includeSpaceFillerForWarningMessage.currentValue;
        }

        if (changesObj.logicConfigMode) {
            $scope.data.logicConfigMode = changesObj.logicConfigMode.currentValue;
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }

        if (changesObj.workflowVersionId) {
            $scope.data.isPublishedWorkflowVersion = $scope.wvm.isPublishedVersion(ctrl.workflowVersionId);
        }

        if (changesObj.filtersResultMap) {
            $scope.data.filtersResultMap = ctrl.filtersResultMap;
        }
    };

    /**
     * Occurs once the autonomous trigger is changed.
     */
    $scope.onAutonomousTriggerConfigurationChanged = function (autonomousTriggerConfiguration) {
        $scope.data.selectedTriggerType = autonomousTriggerConfiguration.selectedTriggerType;
        $scope.data.recurrenceTimeSelection = autonomousTriggerConfiguration.recurrenceTimeSelection;
        $scope.data.monitorFieldDefinitions = autonomousTriggerConfiguration.monitorFieldDefinitions;
        $scope.data.monitorForms = autonomousTriggerConfiguration.monitorForms;
        $scope.data.monitorInnerItems = autonomousTriggerConfiguration.monitorInnerItems;
        $scope.data.runOneTimeOnly = autonomousTriggerConfiguration.runOneTimeOnly;
        $scope.data.doNotRunOnWeekends = autonomousTriggerConfiguration.doNotRunOnWeekends;
        $scope.data.dontRunOnDone = autonomousTriggerConfiguration.dontRunOnDone;
        $scope.data.runAlsoOnNewItems = autonomousTriggerConfiguration.runAlsoOnNewItems;
        $scope.data.runAlsoOnIntakeItems = autonomousTriggerConfiguration.runAlsoOnIntakeItems;
        $scope.data.monitorInnerItemsCreatedByCustomTriggerId =
            autonomousTriggerConfiguration.monitorInnerItemsCreatedByCustomTriggerId;

        if (!$scope.data.configuredLogic.node.manuallyChangedName) {
            if (isAutonomousDefaultTitles(autonomousTriggerConfiguration.recurrenceTimeSelection.displayName)) {
                if (autonomousTriggerConfiguration.customTriggerSecondaryType === 'AUTONOMOUS_MATCH_CONDITIONS') {
                    $scope.data.configuredLogic.node.displayName = logicBlockTypes['AUTONOMOUS'].title;
                } else if (logicBlockTypes[autonomousTriggerConfiguration.customTriggerSecondaryType]?.title) {
                    $scope.data.configuredLogic.node.displayName =
                        logicBlockTypes[autonomousTriggerConfiguration.customTriggerSecondaryType].title;
                }
            } else {
                $scope.data.configuredLogic.node.displayName =
                    autonomousTriggerConfiguration.recurrenceTimeSelection.displayName;
            }

            $scope.data.editedLogicName = ctrl.configuredLogic.node.displayName;
        }

        if (autonomousTriggerConfiguration.customTriggerSecondaryType) {
            $scope.data.customTriggerSecondaryType = autonomousTriggerConfiguration.customTriggerSecondaryType;
            if (
                $scope.data.customTriggerSecondaryType === 'AUTONOMOUS_ITEM_CREATED' &&
                $scope.data.control &&
                $scope.data.control.clearFiltersAndQueries
            ) {
                $scope.data.control.clearFiltersAndQueries();
            }
        }

        const isFormAutonomousTrigger = $scope.data.selectedTriggerType === 'ON_INITIATIVE_CREATED_BY_FORM';
        const hasFormAnsweredAsChild = $scope.data.configuredLogic.impacts.some(
            ({ node }) => (node.customTriggerType ?? node.type) === logicBlockTypes.SEND_FORM_ANSWERED.type,
        );
        const shouldCreateFormAnswered = isFormAutonomousTrigger && !hasFormAnsweredAsChild;
        if (shouldCreateFormAnswered) {
            if (!$scope.data.actionDefinitionObject) {
                $scope.data.actionDefinitionObject = {};
            }

            const params = {};
            consts.getFormAnsweredTriggerParamsEnricher(params);
            $scope.data.actionDefinitionObject.childrenCustomTriggersToCreate = [params];
        }

        let saveLogicPromise = $q.resolve();
        if (!autonomousTriggerConfiguration.shouldNotSaveLogic) {
            saveLogicPromise = $scope.saveLogic(false, true);
        }

        return saveLogicPromise.then(() => {
            if (shouldCreateFormAnswered) {
                // Resetting the array.
                $scope.data.actionDefinitionObject.childrenCustomTriggersToCreate = [];
                const whenFormAnsweredLogic = $scope.data.configuredLogic.impacts.find(
                    ({ node }) => node.customTriggerType === logicBlockTypes.SEND_FORM_ANSWERED.type,
                );
                const otherChildLogic = $scope.data.configuredLogic.impacts.filter(
                    ({ node }) => node.customTriggerType !== logicBlockTypes.SEND_FORM_ANSWERED.type,
                );

                $scope.data.configuredLogic.impacts = [whenFormAnsweredLogic];
                whenFormAnsweredLogic.impacts = otherChildLogic;

                return customTriggerManager
                    .moveCustomTrigger(
                        $scope.data.groupId,
                        $scope.data.configuredLogic.node.id,
                        whenFormAnsweredLogic.node.id,
                        null,
                    )
                    .catch((error) => {
                        if (error.status === 409) {
                            modalUtils.openRefreshModuleModal();
                        }
                    });
            }
        });
    };

    function isAutonomousDefaultTitles(title) {
        const autonomousDefaultTitles = [
            logicBlockTypes['AUTONOMOUS'].title,
            logicBlockTypes['AUTONOMOUS_CREATED_FROM_FORM'].title,
            logicBlockTypes['AUTONOMOUS_INTERFACE_SUBMITTED'].title,
            logicBlockTypes['AUTONOMOUS_FIELD_CHANGED'].title,
            logicBlockTypes['AUTONOMOUS_ITEM_CREATED'].title,
            logicBlockTypes['AUTONOMOUS_SCHEDULE'].title,
            logicBlockTypes['USER_INTERFACE_BUTTON_CLICK'].title,
        ];

        return autonomousDefaultTitles.includes(title);
    }

    $scope.createNewLogic = function (params) {
        if (ctrl.createNewLogicCallback) {
            ctrl.createNewLogicCallback({
                params,
            });
        }
    };

    /**
     * Toggles the edit flag of the display name of the custom trigger.
     */
    $scope.toggleEditLogicDisplayName = function () {
        $scope.data.displayEditLogicName = true;
    };

    /**
     * Updates the display name of the custom trigger.
     */
    $scope.updateLogicDisplayName = function (logicName) {
        // If the name didn't change, we don't call the server with an update.
        if (!logicName || !logicName.length || $scope.data.configuredLogic.node.displayName === logicName) {
            return;
        }

        $scope.data.loadingSavingLogicDisplayName = true;
        $scope.data.errorSavingLogicDisplayName = null;

        requestThrottler.do(
            'updateCustomTriggerDisplayName',
            200,
            () => {
                $scope.data.configuredLogic.node.manuallyChangedName = true;
                return customTriggerManager.updateCustomTriggerDisplayName(
                    $scope.data.groupId,
                    $scope.data.configuredLogic.node.id,
                    logicName,
                );
            },
            null,
            () => {
                $scope.data.errorSavingLogicDisplayName = 'There was an error trying to save logic.';
            },
            () => {
                $scope.data.loadingSavingLogicDisplayName = false;
            },
        );
    };

    /**
     * Select Logic Type Configuration
     */
    $scope.selectLogicType = function (logicTypeConfiguration) {
        // Validate parents (If validation function exists) before creating the logic.
        if (logicTypeConfiguration.parentValidationOnCreation) {
            let directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node.id,
            );

            // Solving a bug where calling the $emit from the validation function threws exception because angular uses
            // "this" and the context changes when we go into the validation function
            const $emit = (name, args) => $rootScope.$emit(name, args);
            while (directParentInWorkflowVersion) {
                if (!logicTypeConfiguration.parentValidationOnCreation(directParentInWorkflowVersion.node, $emit)) {
                    return;
                }
                directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
                    $scope.data.workflowVersionId,
                    directParentInWorkflowVersion.node.id,
                );
            }
        }

        if (logicTypeConfiguration && logicTypeConfiguration.type !== logicBlockTypes.PERFORM_INTEGRATION_ACTION.type) {
            // Integrations log their own analytics.
            analyticsWrapper.track('Select logic type', {
                category: 'Worker logic component',
                label: logicTypeConfiguration.type,
            });
        }

        // Check the validity of the logic type in this specific position in the worker graph
        if (!isTypeAllowedInSpecificPosition(logicTypeConfiguration)) {
            return $q.resolve();
        }

        let confirmPromise = $q.resolve();

        // We should filter for the canCreate() property which holds the function to filter by.
        let filteredCreateAfterOptions = [];
        if (
            logicTypeConfiguration.createAfterOptions?.(projectManager.project.features) &&
            logicTypeConfiguration.createAfterOptions?.(projectManager.project.features).length
        ) {
            filteredCreateAfterOptions = logicTypeConfiguration
                .createAfterOptions?.(projectManager.project.features)
                .filter(
                    (option) =>
                        option.canCreate &&
                        option.canCreate(
                            $scope.data.configuredLogic.impacts,
                            $scope.data.configuredLogic,
                            formManager,
                            customTriggerManager,
                            projectManager.project.features,
                        ),
                );
        }

        // If the TARGET type has restrictions on what we can create after
        if (
            filteredCreateAfterOptions &&
            filteredCreateAfterOptions.length &&
            $scope.data.configuredLogic?.node?.customTriggerType !== 'UNKNOWN'
        ) {
            // Ask for user for approval to INSERT before children
            const createAfterOption = logicTypeConfiguration.createAfterOptions?.(projectManager.project.features)[0];
            $scope.questionConfirmModalData = {
                title: `Changing the type of this block to "${logicTypeConfiguration.title}" Block`,
                body: `Doing this will add "${createAfterOption.blockDisplayName}" after each child block. Are you sure?`,
                okLabel: 'Change',
                cancelLabel: 'Cancel',
            };

            confirmPromise = modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(() => {
                    const definitionsArray = $scope.data.configuredLogic.impacts.map(() => {
                        const definition = {};
                        createAfterOption.triggerParamsEnricher(definition);
                        definition.queryDefinition = {
                            query: getDefaultTonkeanQuery(),
                        };
                        definition.description = 'Description';

                        return definition;
                    });

                    // Ask to create special block before each child.
                    return $scope.data.createMultipleLogicComponents({
                        parentNode: $scope.data.configuredLogic,
                        definitionsArray,
                        replaceInPlace: true,
                    });
                });
        }

        confirmPromise.then(() => {
            innerSelectLogicType(logicTypeConfiguration);
        });
    };

    /**
     * Get the logicType we want to create,
     * and return false if its type is not allowed in current position with respect to the logic paren allowed children types
     */
    function isTypeAllowedInSpecificPosition(logicTypeConfiguration) {
        const directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.node.id,
        );

        // If there is a parent, check if the logicType we are trying to create is allowed by checking the parent's allowedChildrenTypes
        if (directParentInWorkflowVersion) {
            const parentLogicType = logicBlockTypes[directParentInWorkflowVersion.node.customTriggerType];
            if (
                parentLogicType.allowedChildrenTypes?.() &&
                !utils.findFirst(
                    parentLogicType.allowedChildrenTypes(directParentInWorkflowVersion.node),
                    (type) => logicTypeConfiguration.type === type,
                )
            ) {
                $rootScope.$emit('alert', {
                    msg: `${logicTypeConfiguration.title} is not allowed to be created under ${parentLogicType.title}`,
                    type: 'error',
                });
                return false;
            }
        }

        // If there are impacts (children) check if the logicType we are trying to create is allowed by the impacts
        if (
            $scope.data.configuredLogic &&
            $scope.data.configuredLogic.impacts &&
            $scope.data.configuredLogic.impacts.length &&
            logicTypeConfiguration.allowedChildrenTypes?.()
        ) {
            // For each impact (child)
            for (let i = 0; i < $scope.data.configuredLogic.impacts.length; i++) {
                const childTypeConfiguration =
                    logicBlockTypes[$scope.data.configuredLogic.impacts[i].node.customTriggerType];
                if (
                    childTypeConfiguration.type !== 'UNKNOWN' &&
                    !utils.findFirst(
                        logicTypeConfiguration.allowedChildrenTypes(directParentInWorkflowVersion.node),
                        (type) => childTypeConfiguration.type === type,
                    )
                ) {
                    $rootScope.$emit('alert', {
                        msg: `${logicTypeConfiguration.title} is not allowed to be created as parent of ${childTypeConfiguration.title}`,
                        type: 'error',
                    });
                    return false;
                }
            }
        }

        return true;
    }

    $scope.sideBySideDiff = function () {
        if (ctrl.onSideBySideDiffSelected) {
            ctrl.onSideBySideDiffSelected({});
        }
    };

    function innerSelectLogicType(logicTypeConfiguration) {
        $scope.data.selectedLogicTypeConfiguration = logicTypeConfiguration;
        if (logicTypeConfiguration && logicTypeConfiguration.getLinkedFieldDefinitionsConfiguration) {
            if (!$scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration) {
                $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration = {};
            }

            $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration[$scope.data.configuredLogic.node.id] =
                logicTypeConfiguration.getLinkedFieldDefinitionsConfiguration(
                    integrationsConsts,
                    $scope.data.configuredLogic.node,
                );
        }

        $scope.data.actionDefinitionObject = {};
        // Integration action and tonkean action require additional step before you can auto save it
        if (!$scope.data.selectedLogicTypeConfiguration.dontSaveOnSelect) {
            // if no name and choose type, set the name
            const logicNewDisplayName = $scope.data.selectedLogicTypeConfiguration.title;
            const noActionSpecificName =
                !$scope.data.configuredLogic.node.displayName ||
                !$scope.data.configuredLogic.node.displayName.length ||
                $scope.data.configuredLogic.node.displayName === 'Action Block';

            if (noActionSpecificName && !$scope.data.configuredLogic.node.manuallyChangedName) {
                $scope.data.configuredLogic.node.displayName = logicNewDisplayName;
            }

            $scope.saveLogic(false, true);
        }
        // } else {
        $scope.data.logicConfigMode = $scope.data.logicConfigModes.configuration;
        // }
    }

    /**
     * Select Condition Type Configuration
     */
    $scope.selectConditionType = function (conditionTypeConfiguration) {
        if (conditionTypeConfiguration.type === 'ALWAYS') {
            showAreYouSure().then(() => {
                $scope.data.showConditionsConfiguration = true;
                $scope.data.selectedConditionTypeConfiguration = conditionTypeConfiguration;

                if ($scope.data.control && $scope.data.control.clearFiltersAndQueries) {
                    $scope.data.control.clearFiltersAndQueries(true);
                }

                notifyOnActionsChanged(true, () => saveLogicConditions());
            });
        } else {
            $scope.data.showConditionsConfiguration = true;
            $scope.data.selectedConditionTypeConfiguration = conditionTypeConfiguration;
        }
    };

    $scope.onTriggerConditionFiltersChange = function (shouldNotSaveLogic) {
        notifyOnActionsChanged(!shouldNotSaveLogic, () => saveLogicConditions());
    };

    $scope.getWorkingOnContext = function () {
        return (
            customTriggerManager.getWorkflowVersionFirstMonitorParentData(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic?.node,
            )?.monitorActionDefinition?.workerItemContextType ??
            $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.workerItemContextType
        );
    };

    $scope.cantChangeType = function () {
        const cantChangeTypeFun = $scope.data.selectedLogicTypeConfiguration?.cantChangeType;
        if (cantChangeTypeFun) {
            const workingOn = $scope.getWorkingOnContext();
            return cantChangeTypeFun(projectManager.project.features, workingOn);
        }
        return false;
    };

    /**
     * Set logicConfigMode to be selectType, so you can choose logic type
     */
    $scope.setLogicSelectTypeMode = function () {
        analyticsWrapper.track('Change logic type', { category: 'Worker logic component' });
        $scope.resetSelectedLogicType().then(() => {
            $scope.data.logicConfigMode = $scope.data.logicConfigModes.selectType;
        });
    };

    $scope.onUpdateCustomTriggerType = function (type) {
        innerSelectLogicType(logicBlockTypes[type]);
    };

    $scope.onCopyToListButtonClick = function () {
        modalUtils.openCopyToListModal($scope.data.configuredLogic, $scope.data.groupId, false);
    };

    /**
     * Occurs once the actions object is changed.
     */
    $scope.onActionsChanged = function (definition, shouldSaveLogic, reloadOnFieldsError) {
        if (definition.actions?.[0]?.type !== $scope.data.actionDefinitionObject?.actions?.[0]?.type) {
            // We use scope and not just storing in a variable because notifyOnActionsChanged debounce the changes,
            // so we might "loose" the change otherwise.
            $scope.data.shouldEmitActionTypeChanged = true;
        }

        $scope.data.actionDefinitionObject = definition;

        return notifyOnActionsChanged(shouldSaveLogic, () =>
            saveLogicConfiguration(reloadOnFieldsError).then(() => {
                if ($scope.data.shouldEmitActionTypeChanged) {
                    $scope.data.shouldEmitActionTypeChanged = false;
                }
            }),
        );
    };

    $scope.onSkipCustomTriggerChange = function (event) {
        $scope.data.skipWhenConditionNotMet = event.target.checked;
        $scope.saveLogic(false);
    };

    function saveLogicConfiguration(reloadOnFieldsError) {
        const definition = $scope.data.actionDefinitionObject;
        if (definition && definition.actions && definition.actions.length) {
            // if no name and choose type, set the name
            const logicNewDisplayName =
                definition.actions[0].displayName || $scope.data.selectedLogicTypeConfiguration.title;
            if (
                !$scope.data.configuredLogic.node.displayName ||
                !$scope.data.configuredLogic.node.displayName.length ||
                $scope.data.configuredLogic.node.displayName === 'Action Block'
            ) {
                $scope.updateLogicDisplayName(logicNewDisplayName);
            }
        }

        return $scope.saveLogic(false, false, reloadOnFieldsError);
    }

    function saveLogicConditions() {
        return $scope.saveLogic();
    }

    function saveLogicNotifications(customNotificationSettings) {
        return customTriggerManager.updateCustomTriggerCustomNotificationSettings(
            $scope.data.groupId,
            $scope.data.configuredLogic.node.id,
            tonkeanUtils.getCustomNotificationSettingsServerObject(
                customNotificationSettings.botMessageText,
                customNotificationSettings.evaluatedBotMessageText,
                customNotificationSettings.channelOrPersonSelectionConfiguration,
                customNotificationSettings.notificationAdditionalFields,
                customNotificationSettings.hideInitiativePageLink,
                customNotificationSettings.selectedCommunicationProjectIntegrationId,
            ),
        );
    }

    function saveLogicWhenFinished() {
        $scope.data.loadingSavingLogicState = true;
        $scope.data.errorSavingLogicState = null;
        const stateConfiguration = $scope.data.stateConfigurationToSave;

        const updaterConfig = tonkeanUtils.buildPersonSelectionConfiguration(
            stateConfiguration.statusUpdaterPersonSelectionConfiguration,
        );
        const stateUpdaterPersonId =
            updaterConfig.specificPeopleIds && updaterConfig.specificPeopleIds.length
                ? updaterConfig.specificPeopleIds[0]
                : null;

        return customTriggerManager
            .updateCustomTriggerStateId(
                $scope.data.groupId,
                $scope.data.configuredLogic.node.id,
                stateConfiguration.updateToStatus ? stateConfiguration.updateToStatus.id : null,
                stateConfiguration.whenFinishedUpdateText,
                stateConfiguration.evaluatedWhenFinishedUpdateText,
                stateConfiguration.externalStatusValue,
                updaterConfig.initiativeOwner,
                updaterConfig.previousActor,
                stateUpdaterPersonId,
                updaterConfig.personEmailExpressionDefinition,
                !stateConfiguration.shouldSendNotificationOnStatusUpdate,
                stateConfiguration.statusUpdaterPersonSelectionConfiguration.personSelectionType ===
                    'isTonkeanRootUser',
                stateConfiguration.applyStatusUpdateToInnerItems ? stateConfiguration.updateToStatus.id : null,
                stateConfiguration.stateUpdaterFieldsToUpdate,
            )
            .then(() => {
                $scope.data.updateToStatus = workflowVersionManager.getCachedWorkflowVersion(
                    $scope.data.workflowVersionId,
                ).stateIdToStateMap[ctrl.configuredLogic.node.stateId];
            })
            .catch(() => {
                $scope.data.errorSavingLogicState = 'Error trying to update status of logic...';
            })
            .finally(() => {
                $scope.data.loadingSavingLogicState = false;
            });
    }

    $scope.onPostStatusChanged = function (shouldSaveLogic, stateConfiguration) {
        $scope.data.stateConfigurationToSave = stateConfiguration;
        notifyOnActionsChanged(shouldSaveLogic, saveLogicWhenFinished);
    };

    function notifyOnActionsChanged(shouldSaveLogic, saveFunction) {
        const promise = $q.resolve();

        // If this change is not saveable dont do anything
        if (!shouldSaveLogic) {
            return promise;
        }

        // Auto-Save if worker is disabled

        return saveFunction().then((data) => {
            ctrl.onActionsChanged({
                saveFunction: () => saveFunction()
            });

            return data;
        });
    }

    /**
     * Resets the selected logic type.
     */
    $scope.resetSelectedLogicType = function () {
        const hasSpecialChildren = $scope.data.getSpecialChildren($scope.data.configuredLogic).length;

        let body = 'Are you sure you want to change the type?';
        if (hasSpecialChildren) {
            const createAfterOptionDisplayName =
                $scope.data.selectedLogicTypeConfiguration?.createAfterOptions?.(projectManager.project.features)?.[0]
                    ?.blockDisplayName || 'New message on Thread';
            body = `Doing this will remove all "${createAfterOptionDisplayName}" blocks and connect their inner blocks to this one. Are you sure?`;
        }

        $scope.questionConfirmModalData = {
            title: `Changing the type of ${$scope.data.selectedLogicTypeConfiguration.title}`,
            okLabel: 'Change',
            cancelLabel: 'Cancel',
            body,
        };

        const newConfiguration = {
            displayName: 'Action Block',
            customTriggerType: 'UNKNOWN',
        };

        return modal
            .openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning',
            })
            .result.then(() => {
                $scope.data.loadingSavingLogic = true;
                const customTrigger = $scope.data.configuredLogic.node;

                return customTriggerManager.updateCustomTriggerLogic(
                    $scope.data.groupId,
                    customTrigger.id,
                    customTrigger.disabled,
                    newConfiguration.customTriggerType,
                    [],
                    customTrigger.queryDefinition,
                    customTrigger.pingOwner,
                    customTrigger.isScheduled,
                    $scope.data.recurrenceTimeSelection
                        ? $scope.data.recurrenceTimeSelection.recurrencePeriodType
                        : null,
                    $scope.data.recurrenceTimeSelection
                        ? $scope.data.recurrenceTimeSelection.recurrenceDaysInWeek
                        : null,
                    $scope.data.recurrenceTimeSelection
                        ? $scope.data.recurrenceTimeSelection.recurrenceDaysInMonth
                        : null,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.recurrenceHour : null,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.everyXMinutes : null,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.everyXHours : null,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.doNotRunOnWeekends : null,
                    customTrigger.workerItemContextType,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.recurrenceMinute : null,
                    customTrigger.monitorInnerItems,
                    customTrigger.runOneTimeOnly,
                    customTrigger.monitorFieldDefinitions,
                    $scope.data.recurrenceTimeSelection
                        ? $scope.data.recurrenceTimeSelection.recurrenceMonthsInYear
                        : null,
                    $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.timezone : null,
                    convertLinkedFieldDefinitionsMapToIdMap(
                        $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id],
                    ),
                    newConfiguration.displayName,
                    customTrigger.monitorForms,
                    undefined,
                    undefined,
                    true,
                    customTrigger.skipWhenConditionNotMet,
                    customTrigger.dontRunOnDone,
                    true,
                    customTrigger.runAlsoOnNewItems,
                    customTrigger.runAlsoOnIntakeItems,
                );
            })
            .then(() => {
                if (hasSpecialChildren) {
                    return $scope.data.removeSpecialChildren($scope.data.configuredLogic);
                }
            })
            .then(() => {
                $scope.data.selectedLogicTypeConfiguration = { type: 'UNKNOWN' };
                $scope.data.selectedProjectIntegration = null;
                $scope.data.actionDefinitionObject = {};
                $scope.data.configuredLogic.node.displayName = newConfiguration.displayName;
                $scope.data.editedLogicName = newConfiguration.displayName;
                $scope.data.configuredLogic.node.manuallyChangedName = false;
                $scope.data.configuredLogic.node.customTriggerActions = [];
                $scope.data.configuredLogic.node.customTriggerType = newConfiguration.customTriggerType;
                $scope.data.configuredLogic.node.integrationType = null;
            })
            .catch((error) => {
                if (error !== 'cancelButton' && error !== 'backdrop click' && error !== 'escape key press') {
                    $rootScope.$emit('alert', { type: 'danger', msg: getStateError(error) });
                }
                return $q.reject(error);
            })
            .finally(() => {
                $scope.data.loadingSavingLogic = false;
            });
    };

    /**
     * Saves custom trigger to server.
     */
    $scope.saveLogic = function (closeSidePane, dontThrottle, reloadOnFieldsError = true) {
        // Must return a promise, so create an unresolved one and resolve it in the throttler callbacks
        const deferred = $q.defer();

        // We want to immediately update the cached trigger with the up to date actions.
        // Due to different server models in update/create trigger and get trigger, we need to enrich the model we send to the server.
        if (
            $scope.data.actionDefinitionObject &&
            $scope.data.actionDefinitionObject.actions &&
            $scope.data.actionDefinitionObject.actions.length
        ) {
            // Go over the updated actions and enrich them with customTriggerActionType and customTriggerActionDefinition which are needed.
            // They are missing because the model the server expects for updates/creation is different then the model the server outputs for triggers.
            for (let i = 0; i < $scope.data.actionDefinitionObject.actions.length; i++) {
                const action = $scope.data.actionDefinitionObject.actions[i];
                action.customTriggerActionType = action.type;
                action.customTriggerActionDefinition = action.definition;
            }

            // Update the cached logic with the updated (enriched) actions.
            $scope.data.configuredLogic.node.customTriggerActions = $scope.data.actionDefinitionObject.actions;
        }

        if (!$scope.data.displaysHistoryVersion) {
            $scope.data.isSaving = true;
            // Request Id must be unique per logic block
            const requestId = `saveLogic-${$scope.data.configuredLogic.node.id}`;
            const delayDuration = 500;
            const saveLogic = () =>  {
                //We want to avoid multiple concurrent saves in progress - returning request to the throttler
                if ($scope.data.loadingSavingLogic) {
                    requestThrottler.do(requestId, delayDuration, saveLogic);
                    return;
                }

                saveLogicInner()
                    .then(() =>
                        customTriggerManager.loadCustomTriggerById(
                            $scope.data.workflowVersionId,
                            $scope.data.configuredLogic.node.id,
                        ),
                    )
                    .then(() => {
                        // Telling the action component to reload its cached fields.
                        $scope.data.reloadFields = false;
                        $timeout(() => {
                            $scope.data.reloadFields = true;
                        });

                        deferred.resolve();
                    })
                    .catch((error) => {
                        $scope.data.errorSavingLogic = 'There was an error trying to save logic.';

                        if (
                            typeof error === 'object' &&
                            error?.data?.error?.data?.isFieldDefinitionRelated &&
                            reloadOnFieldsError
                        ) {
                            modalUtils.openRefreshModuleModal(getStateError(error));
                        }

                        deferred.reject(error);
                    })
                    .finally(() => {
                        $scope.data.loadingSavingLogic = false;
                        $scope.data.isSaving = false;
                        $scope.data.editedLogicName = $scope.data.configuredLogic.node.displayName;

                        if (!$scope.data.configuredLogicInitialized) {
                            onConfigredLogicChanged();
                        }
                    });
                };

            if (dontThrottle && !$scope.data.loadingSavingLogic) {
                saveLogic();
            } else {
                requestThrottler.do(requestId, delayDuration, saveLogic);
            }
        }

        // if closing, dont wait for server to close
        if (closeSidePane && ctrl.onComponentClosed) {
            ctrl.onComponentClosed();
        }

        return deferred.promise;
    };

    /**
     * Duplicates a logic in the graph.
     */
    $scope.duplicateLogic = function () {
        if (ctrl.duplicateLogic) {
            ctrl.duplicateLogic({
                logicToDuplicate: $scope.data.configuredLogic,
            });
        }
    };

    /**
     * Converts given action definition of a custom trigger to create into a json definition that the backend knows how to handle.
     */
    function convertCustomTriggerToCreateToDefinition(customTriggerToCreate) {
        return {
            customTriggerType: customTriggerToCreate.type ?? customTriggerToCreate.customTriggerType,
            customTriggerSecondaryType: customTriggerToCreate.customTriggerSecondaryType,
            displayName: customTriggerToCreate.displayName,
            description: customTriggerToCreate.description || 'Description',
            customTriggerActions: customTriggerToCreate.customTriggerActions,
            manuallyChangedName: true,
            queryDefinition:
                customTriggerToCreate.queryDefinition && customTriggerToCreate.queryDefinition.query
                    ? customTriggerToCreate.queryDefinition
                    : {
                          query: getDefaultTonkeanQuery(),
                      },
        };
    }

    /**
     * Converts a given list of custom triggers to create in actions language to a definitions the backend
     * knows to work with.
     */
    function convertCustomTriggersToCreateToDefinition(customTriggersToCreate) {
        return customTriggersToCreate
            .filter((customTriggerToCreate) => customTriggerToCreate.displayName)
            .map((customTriggerToCreate) => convertCustomTriggerToCreateToDefinition(customTriggerToCreate));
    }

    function resetActionDefintionIfNeeded(secondaryType){
        if(secondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED' && secondaryType !== 'AUTONOMOUS_CREATED_FROM_FORM'){
            $scope.data.actionDefinitionObject = {};
        }
    }

    function saveLogicInner() {

        $scope.data.loadingSavingLogic = true;
        $scope.data.errorSavingLogic = null;

        // Since we may be choosing fields on the fly, field definition creation is needed before creating the actions.

        let queryDefinition = {
            query: getDefaultTonkeanQuery(),
        };
        if ($scope.data.control && $scope.data.control.createDefinitionFromCustomFilters) {
            queryDefinition = $scope.data.control.createDefinitionFromCustomFilters();
        }

        let fieldDefinitionsToCreate = [];

        if (
            $scope.data.actionDefinitionObject &&
            $scope.data.actionDefinitionObject.fieldDefinitionsToCreate &&
            $scope.data.actionDefinitionObject.fieldDefinitionsToCreate.length
        ) {
            fieldDefinitionsToCreate = fieldDefinitionsToCreate.concat(
                $scope.data.actionDefinitionObject.fieldDefinitionsToCreate,
            );
        }

        let fieldDefinitionCreationPromise = $q.resolve(queryDefinition);
        if (fieldDefinitionsToCreate && fieldDefinitionsToCreate.length) {
            fieldDefinitionCreationPromise = customFieldsManager
                .createMultipleFieldDefinitions($scope.data.groupId, fieldDefinitionsToCreate, true)
                .then((data) => {
                    tonkeanUtils.replaceFiltersAndActionsWithCreatedFieldDefinitions(
                        data,
                        $scope.data.alreadyCreatedFilterKeys,
                        queryDefinition,
                        $scope.data.actionDefinitionObject,
                    );
                    return $q.resolve(data);
                });
        }

        // Here we create any children custom triggers prior to the update of the custom trigger itself.
        // Custom triggers to create prior the save of a change can appear in two forms - a list
        // of children custom triggers to create in $scope.data.actionDefinitionObject.childrenCustomTriggersToCreate
        // or in a generating function in $scope.data.actionDefinitionObject.childrenCustomTriggersDefinitionsCreator.
        // A generating function can be used if you need to generate the custom triggers on demand instead
        // of having a "static" list of custom triggers to create as the definition is changed.
        let customTriggerCreationPromise = $q.resolve();
        if (
            $scope.data.actionDefinitionObject &&
            ($scope.data.actionDefinitionObject.childrenCustomTriggersToCreate ||
                $scope.data.actionDefinitionObject.childrenCustomTriggersDefinitionsCreator)
        ) {
            // We start off with the list of custom triggers to create.
            let customTriggersToCreatePromise = $q.resolve(
                $scope.data.actionDefinitionObject.childrenCustomTriggersToCreate || [],
            );

            // If we have a creator, we call the creator and append the custom triggers it adds
            // to the creation.
            if ($scope.data.actionDefinitionObject.childrenCustomTriggersDefinitionsCreator) {
                customTriggersToCreatePromise = $scope.data.actionDefinitionObject
                    .childrenCustomTriggersDefinitionsCreator()
                    .then((customTriggersToCreate) => {
                        let mergedCustomTriggersToCreate =
                            $scope.data.actionDefinitionObject.childrenCustomTriggersToCreate || [];

                        mergedCustomTriggersToCreate = mergedCustomTriggersToCreate.concat(customTriggersToCreate);

                        return $q.resolve(mergedCustomTriggersToCreate);
                    })
                    .catch(() => {
                        // We swallow the error as we don't care for anything to fail here.
                    });
            }

            customTriggerCreationPromise = customTriggersToCreatePromise.then((customTriggersToCreate) => {
                const definitionsArray = convertCustomTriggersToCreateToDefinition(customTriggersToCreate);

                if (definitionsArray && definitionsArray.length) {
                    return $scope.data.createMultipleLogicComponents({
                        parentNode: $scope.data.configuredLogic,
                        definitionsArray,
                    });
                } else {
                    return $q.resolve();
                }
            });
        }

        let customTriggerUpdatePromise = $q.resolve();
        if ($scope.data.actionDefinitionObject && $scope.data.actionDefinitionObject.childrenCustomTriggersToUpdate) {
            const definitionsArray = $scope.data.actionDefinitionObject.childrenCustomTriggersToUpdate.map(
                (triggerUpdate) => {
                    triggerUpdate.customTriggerId = triggerUpdate.id;
                    triggerUpdate.groupId = $scope.data.groupId;
                    return triggerUpdate;
                },
            );
            if (definitionsArray && definitionsArray.length) {
                customTriggerUpdatePromise = tonkeanService.multiUpdateCustomTrigger(
                    $scope.data.groupId,
                    definitionsArray,
                );
            }
        }

        if (!$scope.data.actionDefinitionObject) {
            $scope.data.actionDefinitionObject = {};
        }

        if (
            $scope.data.selectedLogicTypeConfiguration &&
            (!$scope.data.actionDefinitionObject.actions || !$scope.data.actionDefinitionObject.actions.length)
        ) {
            const defaultActionDefinition =
                logicBlockTypes[$scope.data.selectedLogicTypeConfiguration.type].defaultActionDefinition;

            if (defaultActionDefinition) {
                $scope.data.actionDefinitionObject.actions = [
                    {
                        type: $scope.data.selectedLogicTypeConfiguration.type,
                        customTriggerActionDefinition: defaultActionDefinition($scope.pm.project),
                    },
                ];
            }
        }

        let secondaryType;
        if (
            $scope.data.selectedLogicTypeConfiguration?.type === 'AUTONOMOUS' ||
            $scope.data.selectedLogicTypeConfiguration?.type === 'INNER_AUTONOMOUS'
        ) {
            secondaryType =
                $scope.data.customTriggerSecondaryType || $scope.data.selectedLogicTypeConfiguration.secondaryType;
            resetActionDefintionIfNeeded(secondaryType);
        } else if ($scope.data.selectedLogicTypeConfiguration?.type === 'MONITOR_TRACKS') {
            secondaryType = $scope.data.actionDefinitionObject.customTriggerSecondaryType;
        }

        return $q
            .all([fieldDefinitionCreationPromise, customTriggerCreationPromise, customTriggerUpdatePromise])
            .then(([, , customTriggerUpdateResult]) => {
                if (customTriggerUpdateResult?.fieldDefinitionsChanged) {
                    $rootScope.$broadcast('linkedFieldDefinitionsChanged');
                }

                if (!$scope.data.selectedLogicTypeConfiguration) {
                    $scope.data.selectedLogicTypeConfiguration =
                        $scope.data.logicTypeToConfigurationMap['UNKNOWN']?.['UNKNOWN'];
                }

                const pingOwner =
                    $scope.data.selectedLogicTypeConfiguration &&
                    ($scope.data.selectedLogicTypeConfiguration.type === 'AUTONOMOUS' ||
                        $scope.data.selectedLogicTypeConfiguration.type === 'GATHER_UPDATE' ||
                        $scope.data.selectedLogicTypeConfiguration.type === 'PERSON_INQUIRY');

                return customTriggerManager
                    .updateCustomTriggerLogic(
                        $scope.data.groupId,
                        $scope.data.configuredLogic.node.id,
                        $scope.data.configuredLogic.node.disabled,
                        $scope.data.selectedLogicTypeConfiguration.type,
                        $scope.data.actionDefinitionObject.actions,
                        queryDefinition,
                        pingOwner,
                        $scope.data.customTriggerSecondaryType === 'AUTONOMOUS_SCHEDULE',
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.recurrencePeriodType
                            : null,
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.recurrenceDaysInWeek
                            : null,
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.recurrenceDaysInMonth
                            : null,
                        $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.recurrenceHour : null,
                        $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.everyXMinutes : null,
                        $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.everyXHours : null,
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.doNotRunOnWeekends
                            : null,
                        $scope.data.selectedWorkerItemContextType,
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.recurrenceMinute
                            : null,
                        $scope.data.monitorInnerItems,
                        $scope.data.runOneTimeOnly,
                        $scope.data.monitorFieldDefinitions,
                        $scope.data.recurrenceTimeSelection
                            ? $scope.data.recurrenceTimeSelection.recurrenceMonthsInYear
                            : null,
                        $scope.data.recurrenceTimeSelection ? $scope.data.recurrenceTimeSelection.timezone : null,
                        convertLinkedFieldDefinitionsMapToIdMap(
                            $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id],
                        ),
                        $scope.data.configuredLogic.node.displayName,
                        // Filtering out angular.js auto generated objects.
                        $scope.data.monitorForms.filter((item) => item.id),
                        secondaryType,
                        $scope.data.monitorInnerItemsCreatedByCustomTriggerId,
                        null,
                        $scope.data.skipWhenConditionNotMet,
                        $scope.data.dontRunOnDone,
                        false,
                        $scope.data.runAlsoOnNewItems,
                        $scope.data.runAlsoOnIntakeItems,
                    )
                    .then((updateResult) => {
                        if (
                            $scope.data.selectedLogicTypeConfiguration &&
                            $scope.data.selectedLogicTypeConfiguration.getLinkedFieldDefinitionsConfiguration
                        ) {
                            if (!$scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration) {
                                $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration = {};
                            }

                            $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration[
                                $scope.data.configuredLogic.node.id
                            ] = $scope.data.selectedLogicTypeConfiguration.getLinkedFieldDefinitionsConfiguration(
                                integrationsConsts,
                                $scope.data.configuredLogic.node,
                            );
                        }

                        // If you'd like to create children custom triggers post an update (or creation)
                        // of the custom trigger we're working on, you can use the postCreationChildrenDefinitionsCreator
                        // property on the definition - $scope.data.actionDefinitionObject.postCreationChildrenDefinitionsCreator.
                        // This should be a function that returns an array of custom triggers to create definitions.
                        // An example of usage would be for when you need the linked field definitions of a trigger
                        // to be created before you can create the children.
                        // Since linked field definitions may sometimes need the definition of the trigger itself,
                        // then we must first wait for the update of the trigger itself to complete, and then the
                        // update of the linked field definitions to complete before we can create any children based
                        // on that information.
                        let postCreationChildrenCreatorPromise = $q.resolve();
                        if (
                            $scope.data.actionDefinitionObject &&
                            $scope.data.actionDefinitionObject.postCreationChildrenDefinitionsCreator
                        ) {
                            postCreationChildrenCreatorPromise = $scope.data.actionDefinitionObject
                                .postCreationChildrenDefinitionsCreator()
                                .then((customTriggersToCreate) => {
                                    const definitions =
                                        convertCustomTriggersToCreateToDefinition(customTriggersToCreate);

                                    if (definitions && definitions.length) {
                                        return $scope.data.createMultipleLogicComponents({
                                            parentNode: $scope.data.configuredLogic,
                                            definitionsArray: definitions,
                                        });
                                    } else {
                                        return $q.resolve();
                                    }
                                });
                        }

                        return postCreationChildrenCreatorPromise.then(() => {
                            return getLinkedFieldDefinitions().then(() => {
                                return $q.resolve(updateResult);
                            });
                        });
                    });
            });
    }

    /**
     * Converts the map between a linked field type to a field map to a map between linked field type to an id map.
     */
    function convertLinkedFieldDefinitionsMapToIdMap(linkedFieldDefinitionsMap) {
        const linkedFieldTypeToIdMap = {};

        for (const linkedFieldType in linkedFieldDefinitionsMap) {
            if (linkedFieldDefinitionsMap.hasOwnProperty(linkedFieldType)) {
                const fieldDefinition = linkedFieldDefinitionsMap[linkedFieldType];
                if (fieldDefinition) {
                    linkedFieldTypeToIdMap[linkedFieldType] = fieldDefinition.id;
                }
            }
        }

        return linkedFieldTypeToIdMap;
    }

    /**
     * Clears the custom notification settings.
     */
    $scope.clearCustomNotificationSettings = function () {
        $scope.data.savingCustomNotificationSettings = true;
        $scope.data.errorSavingCustomNotificationSettings = false;

        return customTriggerManager
            .clearCustomTriggerCustomNotificationSettings($scope.data.groupId, $scope.data.configuredLogic.node.id)
            .catch(() => {
                $scope.data.errorSavingCustomNotificationSettings = 'Error saving logic.';
            })
            .finally(() => {
                $scope.data.savingCustomNotificationSettings = false;
            });
    };

    /**
     * Occurs once the custom notification settings are changed.
     */
    $scope.onCustomNotificationSettingsChanged = function (customNotificationSettings, shouldSaveLogic) {
        // If we're not requested to save, we do nothing.
        if (!shouldSaveLogic) {
            return;
        }

        $scope.data.configuredLogic.node.customNotificationSettings = customNotificationSettings;

        // If definition is invalid, we do nothing.
        const isValid = tonkeanUtils.isChannelOrPersonSelectionConfigurationValid(
            customNotificationSettings.channelOrPersonSelectionConfiguration,
        );
        if (!isValid) {
            return;
        }

        // Otherwise, we update the settings using the throttler (since you can type text that can cause too many api calls).
        $scope.data.savingCustomNotificationSettings = true;
        $scope.data.errorSavingCustomNotificationSettings = false;

        // Updating in server.
        requestThrottler.do(
            'updatingCustomNotificationSettings',
            200,
            () => updateCustomNotificationSettingsInner(customNotificationSettings),
            () => {},
            () => {
                $scope.data.errorSavingCustomNotificationSettings = 'Error saving logic.';
            },
            () => {
                $scope.data.savingCustomNotificationSettings = false;
            },
        );
    };

    /**
     * Inner logic of updating custom notification settings.
     */
    function updateCustomNotificationSettingsInner(customNotificationSettings) {
        return notifyOnActionsChanged(true, () => {
            return saveLogicNotifications(customNotificationSettings);
        });
    }

    /**
     * Runs an autonomous trigger immediately.
     */
    $scope.runAutonomousTriggerNow = function () {
        $scope.data.sendingRunAutonomousTriggerNow = true;
        $scope.data.errorSendingRunAutonomousTriggerNow = null;

        $scope.saveLogic(false).then(() => {
            tonkeanService
                .runScheduledAutonomousCustomTriggerNow(
                    $scope.data.workflowVersionId,
                    $scope.data.configuredLogic.node.id,
                )
                .catch(() => {
                    $scope.data.errorSendingRunAutonomousTriggerNow = 'Error trying to run scheduled trigger.';
                })
                .finally(() => {
                    $scope.data.sendingRunAutonomousTriggerNow = false;
                });
        });
    };

    /**
     * Toggles the disabled property of the given custom trigger.
     */
    $scope.toggleCustomTriggerDisabled = function ($event, customTrigger) {
        // Stop bubbling of click event.
        $event.stopPropagation();

        const toggleResult = !customTrigger.disabled;

        customTrigger.loadingToggleDisabled = true;
        customTrigger.errorToggleDisabled = null;

        customTriggerManager
            .updateCustomTriggerDisableMode($scope.data.groupId, customTrigger.id, toggleResult)
            .then((data) => {
                customTrigger.disabled = data.disabled;

                // Getting groups as they are enriched using custom triggers data, and otherwise wouldn't be updated.
                groupInfoManager.getGroup($scope.data.groupId, true);
            })
            .catch(function () {
                customTrigger.errorToggleDisabled = 'There was an error trying to update custom trigger.';
            })
            .finally(function () {
                customTrigger.loadingToggleDisabled = false;
            });
    };

    $scope.deleteLogic = function () {
        $scope.data.loadingSavingLogic = true;
        $scope.data.isSaving = true;
        const deletePromise = customTriggerManager
            .deleteCustomTriggerInWorkflowVersion($scope.data.groupId, $scope, $scope.data.configuredLogic)
            .then((wasLogicDeleted) => {
                $scope.data.errorSavingLogic = null;
                $rootScope.$broadcast('linkedFieldDefinitionsChanged');

                // If the logic was deleted try to close the component.
                if (wasLogicDeleted && $scope.data.configuredLogicInitialized && ctrl.onComponentClosed) {
                    ctrl.onComponentClosed();
                }
            })
            .catch((error) => {
                $scope.data.errorSavingLogic = getStateError(error, {
                    fallbackErrorMessage: `There was an error trying to delete the logic ${$scope.data.configuredLogic.node.displayName}. please try again.`,
                });

                $rootScope.modal
                    .alert('Whoops!', { body: $scope.data.errorSavingLogic, isWarn: true, okLabel: 'Refresh Module' })
                    .finally(() => {
                        $rootScope.$broadcast('reloadModuleEditorRequest');
                    });
            })
            .finally(() => {
                $scope.data.loadingSavingLogic = false;
                $scope.data.isSaving = false;

                // If the the configured logic was changed mid delete, just initialized the configured logic
                if (!$scope.data.configuredLogicInitialized) {
                    onConfigredLogicChanged();
                }
            });

        $scope.data.saveOperationPromise = deletePromise;
    };

    $scope.openImportActionConfigModal = function (actionType) {
        modalUtils.openImportActionConfig(actionType).result.then(function (resolvedObject) {
            $scope.data.importActionConfig = resolvedObject;
        });
    };

    /**
     * Selects a project integration to perform action in.
     */
    $scope.selectProjectIntegration = function (projectIntegration) {
        analyticsWrapper.track('Select logic type', {
            category: 'Worker logic component',
            label: projectIntegration.name,
        });

        $scope.data.selectedProjectIntegration = projectIntegration;
        $scope.selectLogicType($scope.data.logicConfigurationTypesMap.categories.DATA.logicTypeConfigurations[0]);
    };

    /**
     * Selects a integration by name for case the user didn't connect the integration yet to perform action in.
     */
    $scope.selectIntegrationByName = function (integrationName) {
        const nonProjectIntegrationActions = ['OUTGOING_WEBHOOK', 'HTTP_UPLOAD', 'STORAGE_UPLOAD'];

        analyticsWrapper.track('Select logic type', { category: 'Worker logic component', label: integrationName });

        if (nonProjectIntegrationActions.includes(integrationName)) {
            $scope.selectLogicType($scope.data.logicBlockTypes[integrationName]);
        } else {
            $scope.data.selectedProjectIntegration = {
                name: integrationName,
                integrationType: integrationName.toUpperCase(),
            };
            $scope.selectLogicType($scope.data.logicConfigurationTypesMap.categories.DATA.logicTypeConfigurations[0]);
        }
    };

    /**
     * Selects Tonkean as a project integration to perform action in.
     */
    $scope.selectTonkeanAsIntegration = function () {
        $scope.selectLogicType($scope.data.logicTypeToConfigurationMap['TONKEAN_ACTION']?.['UNKNOWN']);
    };

    /**
     * Occurs on selection of worker item context type.
     */
    $scope.onWorkerItemContextTypeSelection = function (selectedWorkerItemContextType) {
        $scope.data.selectedWorkerItemContextType = selectedWorkerItemContextType;
        $scope.data.showWorkerItemContextTypeOptions = false;

        $scope.saveLogic(false);
    };

    /**
     * Occurs on custom filter change.
     */
    $scope.onFilterChange = function () {
        $scope.saveLogic(false);
    };

    /**
     * Opens the add new integration modal.
     */
    $scope.openAddNewIntegrationModal = function () {
        if (ctrl.onComponentClosed) {
            ctrl.onComponentClosed();
        }
        modalUtils.openAddIntegration(null, null, null, 'all');
    };

    $scope.selectOnType = function (onType) {
        $scope.data.selectedOnType = onType;
    };

    $scope.toggleShowAdvanceRunOptions = function () {
        if (!$scope.pm.groupsMap[$scope.data.groupId].workerEnabled || $scope.data.configuredLogic.node.disabled) {
            $scope.data.showAdvanceRunOptions = !$scope.data.showAdvanceRunOptions;
        }
    };

    $scope.reloadLogic = function () {
        $scope.data.pendingSave = false;
        $scope.data.showBigLoading = true;
        return customTriggerManager
            .loadCustomTriggerById($scope.data.workflowVersionId, $scope.data.configuredLogic.node.id)
            .finally(() => ($scope.data.showBigLoading = false));
    };

    /**
     * Checks if the three dot menu should be disabled,
     * checks for each option if its available, if one is available the 3 dot should not be disabled
     */
    $scope.getThreeDotMenuIsDisabled = function () {
        // if no type selected yet
        if (!$scope.data.selectedLogicTypeConfiguration) {
            return false;
        }

        if (
            $scope.data.configuredLogic.node.customTriggerType === 'AUTONOMOUS' &&
            !$scope.data.selectedLogicTypeConfiguration.cantCopyToOtherModule
        ) {
            return false;
        }

        if (!$scope.data.selectedLogicTypeConfiguration.cantDuplicate) {
            return false;
        }

        if ($scope.data.selectedLogicTypeConfiguration.type === 'OUTGOING_WEBHOOK') {
            return false;
        }

        if (!$scope.data.selectedLogicTypeConfiguration.cantDelete) {
            return false;
        }

        const publishedVersionId = $scope.wvm.getPublishedVersionFromCache($scope.data.groupId).id;
        const cachedPublishedCustomTrigger = $scope.ctm.getCachedCustomTrigger(
            publishedVersionId,
            $scope.data.configuredLogic.node.id,
        );
        if (cachedPublishedCustomTrigger) {
            return false;
        }

        // All checks failed, no available actions therefore it is disabled
        return true;
    };

    /**
     * Initializes the relevant configuration for configured logic.
     */
    function initializeConfigurationObject() {
        $scope.data.initializing = true;
        const customTrigger = $scope.data.configuredLogic.node;

        if (customTrigger.customTriggerType !== 'UNKNOWN') {
            $scope.data.selectedLogicTypeConfiguration =
                $scope.data.logicTypeToConfigurationMap[customTrigger.customTriggerType]?.[
                    customTrigger.customTriggerSecondaryType
                ];

            if (
                $scope.data.selectedLogicTypeConfiguration &&
                $scope.data.selectedLogicTypeConfiguration.getLinkedFieldDefinitionsConfiguration
            ) {
                if (!$scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration) {
                    $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration = {};
                }

                $scope.data.customTriggerIdTolinkedFieldDefinitionsConfiguration[customTrigger.id] =
                    $scope.data.selectedLogicTypeConfiguration.getLinkedFieldDefinitionsConfiguration(
                        integrationsConsts,
                        customTrigger,
                    );
            }
        } else {
            $scope.data.selectedLogicTypeConfiguration = null;
        }
        $timeout(function () {
            $scope.data.initializing = false;
        });
    }

    function showAreYouSure() {
        $scope.questionConfirmModalData = {
            title: 'Changing condition to always',
            body: 'Are you sure you want to change condition to always? all your existing filters will be removed',
            okLabel: 'Change',
            cancelLabel: 'Cancel',
        };

        return modal.openQuestionConfirmModal({
            scope: $scope,
            windowClass: 'mod-warning',
        }).result;
    }

    /**
     * Occurs when Linked Field Definition is changed
     */
    $scope.onLinkedFieldDefinitionOptionSelected = function (selectedField, selectedFieldIdentifier) {
        if (selectedField && selectedFieldIdentifier && selectedField.id) {
            if (!$scope.data.customTriggerIdToLinkedFieldDefinitionsMap) {
                $scope.data.customTriggerIdToLinkedFieldDefinitionsMap = {};
            }

            if (!$scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id]) {
                $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id] = {};
            }

            if (
                !$scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id][
                    selectedFieldIdentifier
                ] ||
                $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id][
                    selectedFieldIdentifier
                ].id !== selectedField.id
            ) {
                $scope.data.customTriggerIdToLinkedFieldDefinitionsMap[$scope.data.configuredLogic.node.id][
                    selectedFieldIdentifier
                ] = selectedField;
                $scope.saveLogic(false);
            }
        }
    };

    $scope.onSelectedLogicTypeConfigurationChanged = function (canSkipCustomTriggerWhenConditionWasNotMet) {
        $scope.data.selectedLogicTypeConfiguration.canSkipCustomTriggerWhenConditionWasNotMet =
            canSkipCustomTriggerWhenConditionWasNotMet;
    };
}

export default angular.module('tonkean.app').controller('LogicWorkerComponentCtrl', lateConstructController(LogicWorkerComponentCtrl));
