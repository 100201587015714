import React, { useCallback, useState } from 'react';

import ProcessBuilderQuestionMessage from './ProcessBuilderQuestionMessage';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useSmartConversation } from '@tonkean/smart-conversation';
import { SmartConversationView } from '@tonkean/smart-conversation';
import { SmartConversationUserReplyView } from '@tonkean/smart-conversation';
import type { SmartConversationReplyMethodParams, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { ProcessBuilderAnsweredMissingQuestionAIMessage } from '@tonkean/tonkean-entities';
import type { SmartConversationMessage } from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    questionId: string;
    onAnswerOnMissingQuestionFeedbackReceived: (continueToNextQuestion: boolean, userReply: string) => void;
}

const ProcessBuilderAnswerQuestionsAIConversationView: React.FC<Props> = ({
    projectId,
    questionId,
    onAnswerOnMissingQuestionFeedbackReceived,
}) => {
    const [showReplyBar, setShowReplyBar] = useState(true);

    const [
        { loading: loadingAnswerProcessBuildMissingQuestion, error: errorAnswerProcessBuildMissingQuestion },
        answerProcessBuildMissingQuestion,
    ] = useLazyTonkeanService('answerProcessBuildQuestion');

    const replyMethod = useCallback(
        async ({ projectId: internalProjectId, userReply, conversationId }: SmartConversationReplyMethodParams) => {
            const conversationResult = await answerProcessBuildMissingQuestion(
                internalProjectId,
                userReply,
                questionId,
                conversationId,
            );

            const continueToNextQuestion = conversationResult.response.question_result.continueToNextQuestion;
            onAnswerOnMissingQuestionFeedbackReceived(continueToNextQuestion, userReply);

            if (continueToNextQuestion) {
                setShowReplyBar(false);
            }

            return conversationResult;
        },
        // onAnswerOnMissingQuestionFeedbackReceived must not be part of deps list, otherwise it will cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [answerProcessBuildMissingQuestion, questionId],
    );

    const messageTransformer = useCallback(
        (message: SmartConversationMessage<ProcessBuilderAnsweredMissingQuestionAIMessage>, index: number) => {
            if (message.response.userResponse) {
                return <SmartConversationUserReplyView key={index} message={message} />;
            }

            if (
                !message.response.question_result?.continueToNextQuestion &&
                message.response.question_result?.explanation
            ) {
                return (
                    <ProcessBuilderQuestionMessage key={index} text={message.response.question_result.explanation} />
                );
            }

            return <div key={index} />;
        },
        [],
    );

    const smartConversationManager = useSmartConversation<ProcessBuilderAnsweredMissingQuestionAIMessage>(
        projectId,
        replyMethod,
        loadingAnswerProcessBuildMissingQuestion,
        errorAnswerProcessBuildMissingQuestion,
    );

    return (
        <SmartConversationView
            loading={smartConversationManager.replyMethodLoading}
            error={smartConversationManager.replyMethodError}
            showReplyBar={showReplyBar && !smartConversationManager.replyMethodLoading}
            messageTransformer={messageTransformer}
            takeFullHeight={false}
            smartConversationManager={smartConversationManager}
        />
    );
};

export default ProcessBuilderAnswerQuestionsAIConversationView;
