import React from 'react';
import styled, { css } from 'styled-components';

import CommentDeletionControl from './CommentDeletionControl';
import CommentMarkdown from './CommentMarkdown';
import EventTitle from './EventTitle';

import { Breakpoint, PointerType, TextEllipsis, usePointerType } from '@tonkean/infrastructure';
import type { Person, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ShowOnHover = styled.span``;

const Container = styled.div<{ pointer: PointerType }>`
    ${({ pointer }) =>
        pointer === PointerType.FINE &&
        css`
            ${ShowOnHover} {
                opacity: 0;
            }

            &:hover ${ShowOnHover}, ${ShowOnHover}:focus-within {
                opacity: 1;
            }

            @media (max-width: ${Breakpoint.XSMALL_640}px) {
                ${ShowOnHover} {
                    opacity: 1;
                }
            }
        `};
`;

const CommentTitle = styled.span`
    font-weight: 500;
`;

const CommentText = styled.div`
    margin-top: 16px;
    margin-left: 35px;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
`;

interface Props {
    commentId: string;
    commentText: string;
    actor: Person;
    created: number;
    eventText: string | JSX.Element;
    onCommentDelete?: (commentId: string, targetId?: string) => void;
    targetId?: TonkeanId<TonkeanType.ACTIVITY>;
    onBehalfOfFullName?: string;
}

const CommentView: React.FC<Props> = ({
    commentId,
    commentText,
    actor,
    created,
    eventText,
    onCommentDelete,
    targetId,
    onBehalfOfFullName,
}) => {
    const pointer = usePointerType();
    return (
        <Container pointer={pointer}>
            <EventTitle
                actor={actor}
                onBehalfOfFullName={onBehalfOfFullName}
                eventText={<CommentTitle>{eventText}</CommentTitle>}
                date={created}
                actions={
                    onCommentDelete && (
                        <ShowOnHover>
                            <CommentDeletionControl
                                commentId={commentId}
                                onDelete={onCommentDelete}
                                title="Delete Comment"
                                content="Are you sure you want to delete this comment?"
                                targetId={targetId}
                            />
                        </ShowOnHover>
                    )
                }
            />

            <CommentText data-automation="comment-view-comment-text">
                <TextEllipsis numberOfLines={50}>
                    <CommentMarkdown commentText={commentText} />
                </TextEllipsis>
            </CommentText>
        </Container>
    );
};

export default CommentView;
