import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { analyticsWrapper } from '@tonkean/analytics';
import { useLazyTonkeanService, useTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    FormikHelpers,
    H3,
    InformationTooltip,
    Input,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    Textarea,
    Toggle,
} from '@tonkean/infrastructure';
import { BusinessAppsAnalyticsEvents } from '@tonkean/tonkean-entities';
import type { ItemInterface, ItemInterfaceConfiguration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize, InformationIconSize } from '@tonkean/tui-theme/sizes';

const Subtitle = styled.div`
    margin-top: 8px;
    font-size: ${FontSize.SMALL_12};
    line-height: 16px;
    color: ${Theme.colors.gray_700};
`;

const StyledField = styled(Field)`
    margin-top: 33px;
`;

const FieldLabel = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-left: 4px;
`;

const OptionalTag = styled.i`
    color: ${Theme.colors.gray_500};
    font-weight: 400;
    margin-left: 4px;
`;

const StyledModalFooter = styled(ModalFooter)`
    padding: 2px 6px;
`;

const StyledToggle = styled(Toggle)`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const ToggleWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ToggleSubtext = styled.div`
    margin-top: 5px;
    font-weight: 400;
    color: ${Theme.colors.gray_500};
    font-size: 12px;
`;

const DISPLAY_NAME_MAX_LENGTH = 255;
const DESCRIPTION_MAX_LENGTH = 2000;
const SMART_ASSISTANT_POLICY_MAX_LENGTH = 10_000;
const ValidationSchema = Yup.object({
    displayName: Yup.string()
        .required('A name is required')
        .max(
            DISPLAY_NAME_MAX_LENGTH,
            `Interface name is too long. Reduce the name to ${DISPLAY_NAME_MAX_LENGTH} characters or less`,
        ),
    groupId: Yup.string().required('A module is required'),
    description: Yup.string().max(
        DESCRIPTION_MAX_LENGTH,
        `Description text is too long. Reduce the description to ${DESCRIPTION_MAX_LENGTH} characters or less`,
    ),
    smartAssistantPolicy: Yup.string().max(
        SMART_ASSISTANT_POLICY_MAX_LENGTH,
        `Submission guidance and policies text is too long. Reduce the description to ${SMART_ASSISTANT_POLICY_MAX_LENGTH} characters or less`,
    ),
});
type FormElements = Yup.InferType<typeof ValidationSchema>;

interface Props {
    onClose(createdItemInterface?: ItemInterface, itemInterfaceWasCreated?: boolean): void;
    workflowFolder: WorkflowFolder;
    existingItemInterface?: ItemInterface;
    groupId: TonkeanId<TonkeanType.GROUP>;
}

const CreateOrEditItemInterfaceModalContent: React.FC<Props> = ({
    onClose,
    workflowFolder,
    existingItemInterface,
    groupId,
}) => {
    const [{ loading: createLoading, error: createError }, createItemInterface] =
        useLazyTonkeanService('createItemInterface');

    const [{ loading: updateLoading, error: updateError }, updateItemInterfaceById] =
        useLazyTonkeanService('updateItemInterfaceById');

    const {
        data: smartAssistantPolicyData,
        loading: smartAssistantPolicyLoading,
        error: smartAssistantPolicyError,
    } = useTonkeanService(
        'getItemInterfaceSmartAssistantPolicyById',
        existingItemInterface?.id,
        existingItemInterface?.group.id,
        existingItemInterface?.workflowVersionType,
    );

    const loading = createLoading || updateLoading || smartAssistantPolicyLoading;
    const error = createError || updateError || smartAssistantPolicyError;
    const [showCollaborationPopoverComments, setShowCollaborationPopoverComments] = useState<boolean>(
        existingItemInterface?.configuration?.showCollaborationPopoverComments ?? true,
    );
    const [showCollaborationPopoverConcierge, setShowCollaborationPopoverConcierge] = useState<boolean>(
        existingItemInterface?.configuration?.showCollaborationPopoverConcierge ?? false,
    );

    const onSubmit = useCallback(
        (values: FormElements) => {
            const promise = existingItemInterface
                ? updateItemInterfaceById(
                      existingItemInterface.id,
                      values.displayName,
                      values.description ?? '',
                      existingItemInterface.themeConfiguration.headerBackgroundColor,
                      existingItemInterface.themeConfiguration.textColor,
                      existingItemInterface.themeConfiguration.textAlignment,
                      existingItemInterface.themeConfiguration.title,
                      existingItemInterface.themeConfiguration.subtitle,
                      existingItemInterface.group.id,
                      existingItemInterface.businessGroupsIds,
                      existingItemInterface.themeConfiguration.showLogo,
                      existingItemInterface.themeConfiguration.mainActionEntityId,
                      existingItemInterface.themeConfiguration.mainActionLabel,
                      existingItemInterface.themeConfiguration.mainActionEntityType,
                      {
                          ...existingItemInterface.configuration,
                          showCollaborationPopoverComments,
                          showCollaborationPopoverConcierge,
                      } as ItemInterfaceConfiguration,
                      values.smartAssistantPolicy,
                  )
                : createItemInterface(
                      workflowFolder.project.id,
                      workflowFolder.id,
                      values.groupId,
                      values.displayName,
                      {
                          showCollaborationPopoverComments,
                          showCollaborationPopoverConcierge,
                      } as ItemInterfaceConfiguration,
                      values.description,
                      [],
                      values.smartAssistantPolicy,
                  );
            return promise.then((itemInterface) => {
                onClose(itemInterface, Boolean(!existingItemInterface));
                analyticsWrapper.track(
                    existingItemInterface
                        ? BusinessAppsAnalyticsEvents.UPDATE_ITEM_INTERFACE
                        : BusinessAppsAnalyticsEvents.CREATE_ITEM_INTERFACE,
                    {
                        ItemInterfaceId: itemInterface.id,
                        ItemInterfaceDisplayName: itemInterface.displayName,
                        ItemInterfaceDescription: itemInterface.description,
                        ItemInterfaceThemeConfiguration: itemInterface.themeConfiguration,
                        ItemInterfaceGroup: itemInterface.group,
                        ItemInterfaceBusinessGroupsIds: itemInterface.businessGroupsIds,
                    },
                );
            });
        },
        [
            existingItemInterface,
            updateItemInterfaceById,
            createItemInterface,
            workflowFolder.project.id,
            workflowFolder.id,
            onClose,
            showCollaborationPopoverComments,
            showCollaborationPopoverConcierge,
        ],
    );

    return (
        <Formik
            initialValues={{
                displayName: existingItemInterface?.displayName || '',
                groupId: existingItemInterface?.group?.id || groupId,
                description: existingItemInterface?.description || '',
                businessGroupsIds: existingItemInterface?.businessGroupsIds || [],
                smartAssistantPolicy: smartAssistantPolicyData?.smartAssistantPolicy || undefined,
            }}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <FormikHelpers>
                <ModalForm>
                    <ModalHeader>
                        <H3>
                            {existingItemInterface ? `Edit '${existingItemInterface.displayName}'` : 'Create New'}{' '}
                            Interface
                        </H3>
                        <Subtitle>
                            Interfaces are customizable views that enable the sharing of relevant information about
                            specific module items with people that are involved in and contribute to your business
                            process
                        </Subtitle>
                    </ModalHeader>
                    <ModalBody>
                        <Field
                            label={
                                <FieldLabel>
                                    Name
                                    <StyledInformationTooltip
                                        iconSize={InformationIconSize.SMALL}
                                        placement="top-start"
                                        tooltipLimitWidth={25}
                                        textAlignLeft
                                    >
                                        The name is used as the default title for the interface and may be visible to
                                        users
                                    </StyledInformationTooltip>
                                </FieldLabel>
                            }
                        >
                            <Input
                                type="text"
                                placeholder="Type interface name"
                                autoComplete="off"
                                name="displayName"
                                data-automation="create-or-edit-item-interface-modal-content-name-input"
                            />
                        </Field>
                        <StyledField
                            label={
                                <FieldLabel>
                                    Description <OptionalTag>(Optional)</OptionalTag>{' '}
                                    <StyledInformationTooltip
                                        iconSize={InformationIconSize.SMALL}
                                        placement="top-start"
                                        tooltipLimitWidth={25}
                                        textAlignLeft
                                    >
                                        The description can be used to understand the purpose of the interface.
                                    </StyledInformationTooltip>
                                </FieldLabel>
                            }
                        >
                            <Textarea
                                placeholder="Describe your interface"
                                autoComplete="off"
                                name="description"
                                minRows={3}
                                data-automation="create-or-edit-item-interface-modal-content-description-input"
                            />
                        </StyledField>

                        <StyledField
                            label={
                                <FieldLabel>
                                    Submission guidance and policies
                                    <OptionalTag>(Optional)</OptionalTag>
                                    <StyledInformationTooltip
                                        iconSize={InformationIconSize.SMALL}
                                        placement="top-start"
                                        tooltipLimitWidth={25}
                                        textAlignLeft
                                    >
                                        This information will be provided to the AI intake concierge for context when
                                        answering users queries. Up to 10,000 characters.
                                    </StyledInformationTooltip>
                                </FieldLabel>
                            }
                        >
                            <Textarea
                                placeholder="Describe your guidance and policies"
                                autoComplete="off"
                                name="smartAssistantPolicy"
                                minRows={3}
                                data-automation="create-or-edit-item-interface-modal-content-smart-assistant-policy-input"
                                disabled={smartAssistantPolicyLoading}
                            />
                        </StyledField>

                        <ToggleWrapper>
                            <StyledToggle
                                checked={showCollaborationPopoverConcierge}
                                onChange={() =>
                                    setShowCollaborationPopoverConcierge(!showCollaborationPopoverConcierge)
                                }
                                size={InputSize.SMALL}
                                labelBefore="Show AI Concierge"
                            />
                            <ToggleSubtext>Provide requests with AI assistance</ToggleSubtext>
                        </ToggleWrapper>

                        <ToggleWrapper>
                            <StyledToggle
                                dataAutomation="create-or-edit-item-interface-modal-show-comments-toggle"
                                checked={showCollaborationPopoverComments}
                                onChange={() => setShowCollaborationPopoverComments(!showCollaborationPopoverComments)}
                                size={InputSize.SMALL}
                                labelBefore="Show Comments"
                            />
                            <ToggleSubtext>Collaborate on requests with rich comments and context</ToggleSubtext>
                        </ToggleWrapper>
                    </ModalBody>

                    <StyledModalFooter align="right" border>
                        <ModalFooterActions
                            saveDisabled={loading}
                            saveLabel={existingItemInterface ? 'Update' : 'Create'}
                            error={error}
                        />
                    </StyledModalFooter>
                </ModalForm>
            </FormikHelpers>
        </Formik>
    );
};

export default CreateOrEditItemInterfaceModalContent;
