import React from 'react';

import { SelectorTitle } from './configurationEditorSettingsComponents/SelectorTitle';
import ItemInterfaceSelector from '../../../components/ItemInterfaceSelector';

import { useFormikField } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    disabled?: boolean;
}

const ItemsWidgetConfigurationEditorInterfaceSelector: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    disabled = false,
}) => {
    const { value: itemInterfaceId, setValue: setItemInterfaceId } = useFormikField<
        TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined
    >('configuration.drillDownInterface');

    return (
        <>
            <SelectorTitle>Drill down interface</SelectorTitle>
            <ItemInterfaceSelector
                workflowVersionId={workflowVersionId}
                selectedItemInterfaceId={itemInterfaceId}
                onItemInterfaceSelected={(selectedItemInterface) => setItemInterfaceId(selectedItemInterface)}
                groupId={groupId}
                disabled={disabled}
                allowClear
            />
        </>
    );
};

export default ItemsWidgetConfigurationEditorInterfaceSelector;
