<div class="initiative-function-box" ng-class="{ 'padding-normal': data.mode === 'update' }">
    <div class="text-center" ng-if="!initiative || loading">
        <div class="loading"></div>
    </div>
    <div ng-if="initiative">
        <div ng-switch="data.mode">
            <!-- Update Status Mode -->
            <div ng-switch-when="update">
                <div class="initiative-function-box-header">
                    <div class="flex-vmiddle relative flex-no-shrink">
                        <div class="flex-grow">
                            <strong class="track-title">{{ titleText || initiative.title }}</strong>
                            <div
                                ng-if="initiative.stateText && initiative.status !== 'FUTURE'"
                                class="hidden-xs common-size-xxxxs"
                            >
                                (Currently {{ initiative.stateText ? initiative.stateText : 'Not started' }})
                            </div>
                        </div>
                        <div
                            class="margin-normal-sm-h flex-no-shrink common-bold common-color-blue-dark hidden-xs"
                            ng-if="initiative.eta"
                        >
                            ETA: {{ initiative.eta | daysDiff }}
                        </div>
                        <div class="flex-no-shrink flex-vmiddle" ng-if="showOwner">
                            <tnk-avatar
                                user="initiative.owner"
                                class="flex-no-shrink"
                                default-on-empty="true"
                            ></tnk-avatar>
                            <span class="flex-grow margin-left">
                                {{ initiative.owner && initiative.owner.name ? initiative.owner.name : '' }}
                            </span>
                        </div>
                    </div>
                    <div class="visible-xs">
                        <hr class="margin-normal-sm-v" />
                        <div class="flex flex-vmiddle margin-normal-sm-v">
                            <div
                                ng-if="initiative.stateText"
                                class="margin-right flex-grow"
                                ng-style="{ color: initiative.stateColor }"
                            >
                                <i class="fa fa-circle margin-right"></i>
                                {{ initiative.stateText ? initiative.stateText : 'Not started' }}
                            </div>
                            <div
                                class="common-bold common-color-blue-dark relative"
                                ng-if="initiative.eta"
                                uib-tooltip="{{ initiative.eta | date }}"
                            >
                                ETA: {{ initiative.eta | daysDiff }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-grow" ng-if="!hideActivity">
                    <!--<div class="flex-grow" ng-if="!hideActivity">-->
                    <hr class="margin-normal-sm-v" />
                    <div
                        class="initiative-updatetext-box"
                        ng-if="initiative.updateText && initiative.updateText.length"
                    >
                        <!--ng-if="initiative.updateText && initiative.updateText.length && (!initiative.updater || initiative.updater.id !== as.currentUser.id || (initialState && initialState.label !== initiative.stateText))">-->
                        <div class="padding-normal-sm-v flex">
                            <i class="fa fa-quote-left common-color-blue-light margin-right"></i>

                            <div class="flex-grow">
                                <div light-markdown="initiative.updateText"></div>
                                <div class="common-color-grey common-size-xxxxs" ng-show="initiative.updated">
                                    <strong
                                        ng-if="
                                            initiative.stateText &&
                                            initiative.status !== 'FUTURE' &&
                                            initiative.stateText
                                        "
                                    >
                                        {{ initiative.stateText }}
                                    </strong>
                                    -
                                    <span ng-if="initiative.updater">
                                        by
                                        <span>{{ initiative.updater.name }}</span>
                                        ,
                                    </span>
                                    {{ initiative.updated | timeAgo }}
                                </div>
                            </div>
                        </div>
                        <hr class="margin-normal-sm-v" />
                    </div>
                    <div
                        ng-if="initiative.updateText && initiative.updateText.length && initiative.updater && initiative.updater.id === as.currentUser.id && (!initialState || initialState.label === initiative.stateText)"
                        class="flex-vmiddle"
                    >
                        <span class="flex-grow">{{as.currentUser.firstName}}, What's the latest status?</span>
                        <div class="common-color-grey common-size-xxxxs">
                            Last updated by you, {{ initiative.updated | timeAgo }}
                        </div>
                    </div>
                </div>
                <div class="margin-top flex-grow" ng-if="!hideActivity">
                    <form name="status-{{ initiative.id }}">
                        <label for="message-{{ initiative.id }}" class="sr-only">Message</label>
                        <textarea
                            class="form-control common-resize-y-only"
                            rows="{{
                                editMode || toggles.updateFocus || (data.tempText && data.tempText.length) ? 2 : 1
                            }}"
                            id="message-{{ initiative.id }}"
                            placeholder="{{as.currentUser.firstName}}, What's the latest status?"
                            maxlength="4000"
                            ng-focus="toggles.updateFocus = true"
                            autofocus="{{ editMode }}"
                            ng-change="onTextChange()"
                            ng-keydown="onTextKeyDown($event)"
                            ng-required="required"
                            ng-model="data.tempText"
                            mentio
                        ></textarea>
                        <tnk-people-at-mention
                            text-id="{{ 'message-' + initiative.id }}"
                            result-list="data.atMentioned"
                            allowed-people="initiative.group.visibilityType === 'PRIVATE' ? initiative.group.members : null"
                        ></tnk-people-at-mention>

                        <div
                            ng-show="editMode || toggles.updateFocus || (data.tempText && data.tempText.length)"
                            class="flex-no-sm flex-xs-col"
                        >
                            <div class="flex-vmiddle margin-top flex-grow">
                                <div class="flex-grow flex-vmiddle">
                                    <div
                                        class="
                                            flex-no-shrink
                                            margin-right
                                            initiative-status-button-group
                                            flex-vmiddle
                                            common-bg
                                            mod-grey
                                            btn-group
                                        "
                                        ng-style="{ 'background-color': funcState.color }"
                                        uib-dropdown
                                        on-toggle="toggled(open)"
                                        uib-dropdown-toggle
                                    >
                                        <!-- status button -->
                                        <button
                                            type="button"
                                            class="
                                                initiative-status-button
                                                btn btn-slim btn-text-left btn-primary
                                                text-left
                                                flex-grow flex-self-start
                                                common-no-outline common-background-transparent
                                                btn-no-border
                                            "
                                            id="simple-dropdown-{{ initiative.id }}"
                                        >
                                            {{ funcState.label ? funcState.label : 'Not started' }}
                                        </button>
                                        <!-- dropdown carret -->
                                        <button
                                            type="button"
                                            class="
                                                initiative-status-button
                                                btn btn-slim btn-primary
                                                common-no-outline
                                                dropdown-toggle
                                                common-background-transparent
                                                btn-no-border
                                            "
                                        >
                                            <span class="caret"></span>
                                            <span class="sr-only">Toggle Dropdown</span>
                                        </button>

                                        <ul
                                            class="dropdown-menu"
                                            uib-dropdown-menu
                                            aria-labelledby="simple-dropdown-{{ initiative.id }}"
                                        >
                                            <li
                                                ng-repeat="state in wvm.getCachedWorkflowVersion(data.workflowVersion.id).states"
                                                ng-if="state.label && state.label.length"
                                            >
                                                <a ng-click="setFuncState(state)">
                                                    <i
                                                        class="fa fa-square margin-right"
                                                        ng-style="{ color: state.color }"
                                                    ></i>
                                                    {{ state.label }}
                                                </a>
                                            </li>
                                            <!-- Extra states from original group (if current group is different from original one) -->
                                            <li
                                                class="
                                                    common-color-grey common-size-xxxxs
                                                    padding-top padding-bottom-xxs padding-left padding-right
                                                "
                                                ng-if="data.originalGroupStates && data.originalGroupStates.length"
                                            >
                                                From original list:
                                            </li>
                                            <li
                                                ng-repeat="extraState in data.originalGroupStates"
                                                ng-if="
                                                    data.originalGroupStates &&
                                                    data.originalGroupStates.length &&
                                                    extraState.label &&
                                                    extraState.label.length
                                                "
                                            >
                                                <a ng-click="setFuncState(extraState)">
                                                    <i
                                                        class="fa fa-square margin-right"
                                                        ng-style="{ color: extraState.color }"
                                                    ></i>
                                                    {{ extraState.label }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="flex-grow relative track-due-date-input-container"
                                        ng-hide="funcState.type === 'DONE'"
                                    >
                                        ETA (days):
                                        <input
                                            type="number"
                                            class="form-control inline-block"
                                            data-automation="function-card-template-update-eta-days"
                                            style="max-width: 80px"
                                            ng-model="initiative.etaDays"
                                        />
                                        <i
                                            ng-click="toggles.dateOpened = !toggles.dateOpened"
                                            class="fa fa-calendar margin-left pointer common-color-dark-grey"
                                        ></i>
                                        <input
                                            class="margin-top track-due-date-input relative"
                                            uib-datepicker-popup="fullDate"
                                            datepicker-options="{showWeeks: false}"
                                            ng-model="etaDays.date"
                                            ng-min="minDate"
                                            min-date="minDate"
                                            ng-change="onDateChange()"
                                            is-open="toggles.dateOpened"
                                            close-text="Close"
                                        />
                                    </div>
                                </div>
                                <div class="flex-vmiddle">
                                    <div ng-hide="editMode">
                                        <i
                                            class="fa fa-close common-color-grey pointer margin-right"
                                            ng-click="toggles.updateFocus = null; toggles.updateStatus = null"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-no-shrink margin-top text-right">
                                <button
                                    class="btn btn-primary mod-100-xs"
                                    data-automation="function-card-template-update-item-status"
                                    type="submit"
                                    ng-class="{ 'btn-lg margin-top-lg': $root.isMobile }"
                                    ng-click="updateItemStatus(data.tempText, initiative.etaDays, funcState, 'updateFocus')"
                                    ng-disabled="postingUpdate"
                                >
                                    <span ng-hide="postingUpdate">
                                        Post
                                        <i class="margin-left fa fa-arrow-right"></i>
                                    </span>
                                    <span ng-show="postingUpdate">Posting...</span>
                                </button>
                            </div>
                        </div>

                        <!-- Remember my choice hint and option to dismiss -->
                        <div
                            ng-if="as.currentUser.metadata.externalStatusUpdateSettings[initiative.externalSource + funcState.label]"
                            class="common-size-xxxxs flex-vmiddle margin-top"
                        >
                            <!-- Checkbox to choose if to update external status automatically -->
                            <input
                                type="checkbox"
                                class="remember-my-choice-hint-checkbox"
                                ng-model="as.currentUser.metadata.externalStatusUpdateSettings.automaticallyUpdateExternalStatus"
                                ng-click="updateAutomaticallyUpdateExternalStatus()"
                            />

                            <!-- Description of what we remembered -->
                            <div class="margin-right">
                                Mark as
                                <strong>
                                    {{as.currentUser.metadata.externalStatusUpdateSettings[initiative.externalSource +
                                    funcState.label].displayName}}
                                </strong>
                                on
                                <strong>{{ initiative.externalSourceFriendly }}</strong>
                            </div>

                            <!-- Remove link -->
                            <a ng-click="openChangeExternalStatusSettingsModal(false)">Change</a>
                        </div>
                    </form>
                </div>
                <div
                    class="initiative-function-box-footer common-size-xxs"
                    ng-if="initiative.owner && initiative.owner.id === as.currentUser.id && initiative.status !== 'DONE' && funcState.type !== 'DONE'"
                >
                    <span class="margin-right">Next Module follow-up:</span>
                    <tnk-set-reminder-popover
                        initiative="initiative"
                        on-custom="openSettingsModal(item, true)"
                        on-set-reminder="onSetReminderManually()"
                        dont-update-server="!fullPageMode"
                        manual-gather-update="data.tempNextReminder"
                        on-save="onSaveManualReminder()"
                    >
                        <a href="#">
                            {{ (data.tempNextReminder.date | daysDiff) || 'None' }}
                            <i class="fa fa-caret-down margin-left-xs"></i>
                        </a>
                    </tnk-set-reminder-popover>
                    <span
                        ng-if="fullPageMode"
                        class="common-size-xxxs common-color-success save-alert margin-left-lg"
                        ng-class="{ 'mod-visible': data.nextReminderSaved }"
                    >
                        Saved!
                    </span>
                    <i
                        ng-if="!fullPageMode && data.didUserSetReminderManually"
                        class="fa fa-info-circle margin-left common-color-grey common-size-xxxs"
                        uib-tooltip="This change will be saved when you click Post."
                    ></i>
                </div>
            </div>

            <!-- Follow-ups Mode-->
            <div ng-switch-when="follow-up">
                <div class="common-width-100">
                    <div class="common-bg mod-grey3 padding-normal">
                        <div class="common-bold common-color-black">{{ initiative.title }}</div>

                        <div class="flex-vmiddle common-color-success">
                            <i class="svg-icon margin-right-xs">
                                <tnk-icon src="../../../../../../apps/tracks/images/icons/check.svg"></tnk-icon>
                            </i>
                            <div>
                                Marked as
                                <span>{{ initiative.stateText }}</span>
                            </div>
                        </div>

                        <!-- Update Text -->
                        <div ng-if="data.showUpdateText" class="flex relative margin-top-md padding-left-md">
                            <div
                                class="initiative-update-color-bar"
                                ng-style="{ 'border-color': initiative.stateColor }"
                            ></div>
                            <div>
                                <div>
                                    Updated status is
                                    <span ng-style="{ color: initiative.stateColor }">{{ initiative.stateText }}</span>
                                </div>
                                <div class="common-color-dark-grey margin-top-xxs">{{ initiative.updateText }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Question Container -->
                    <div class="flex mod-align-baseline padding-normal margin-top-lg">
                        <i class="svg-icon-xlg mod-static margin-right-xs">
                            <tnk-icon src="../../../../../../apps/tracks/images/icons/tonkean-logo.svg"></tnk-icon>
                        </i>
                        <div>
                            <div
                                class="alert-grey-inverted mod-no-shadow margin-bottom-xs"
                                ng-repeat="question in data.followUpSteps[data.selectedFollowUpStepIndex].questionHtml"
                                ng-bind-html="question"
                            ></div>
                            <div
                                ng-if="data.followUpSteps.length > 1"
                                class="common-size-xxxxs common-color-light-grey margin-top-xs"
                            >
                                <span class="common-bold">{{ data.selectedFollowUpStepIndex + 1 }}</span>
                                / {{ data.followUpSteps.length }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Answer Container -->
                <div ng-if="data.followUpLoading" class="flex-vmiddle mod-justify-center">
                    <i class="loading-medium"></i>
                </div>
                <div ng-if="!data.followUpLoading">
                    <div ng-switch="data.followUpSteps[data.selectedFollowUpStepIndex].answerTemplate">
                        <div ng-switch-when="createInnerTracks">
                            <div ng-include="'./followUpAnswerTemplates/createInnerTracks.template.html'"></div>
                        </div>
                        <div ng-switch-when="nextReminder">
                            <div ng-include="'./followUpAnswerTemplates/nextReminder.template.html'"></div>
                        </div>
                        <div ng-switch-when="externalStatus">
                            <div ng-include="'./followUpAnswerTemplates/externalStatus.template.html'"></div>
                        </div>
                        <div ng-switch-when="stillRelevant">
                            <div ng-include="'./followUpAnswerTemplates/stillRelevant.template.html'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
