import React from 'react';
import styled, { css } from 'styled-components';

import { useProject } from '@tonkean/infrastructure';
import type { ProjectThemeConfiguration } from '@tonkean/tonkean-entities';
import { type ClickableProps, Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const StyledButton = styled(Clickable)<{
    $mainColor: string;
    $secondaryColor: string;
    $isSelected: boolean;
    disabled?: boolean;
}>`
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 13px 8px 8px;
    gap: 4px;
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;

    ${({ disabled, $mainColor }) =>
        !disabled &&
        css`
            &:hover {
                color: ${$mainColor};
                background: ${`linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%),${$mainColor}`};

                svg {
                    ${colorSvg($mainColor)};
                }
            }
        `};

    ${({ $isSelected, $secondaryColor, $mainColor }) =>
        $isSelected &&
        css`
            &:hover {
                background: ${`none,${$mainColor}`};
                color: ${$secondaryColor};

                svg {
                    ${colorSvg($secondaryColor)};
                }
            }

            color: ${$secondaryColor};
            background-color: ${$mainColor};

            svg {
                ${colorSvg($secondaryColor)};
            }
        `};
`;

interface Props extends ClickableProps {
    isSelected: boolean;
    dataAutomation?: string;
    buttonColor?: string;
    children: React.ReactNode;
}

const NavigationButton: React.FC<Props> = ({ isSelected, dataAutomation, buttonColor, children, ...props }) => {
    const project = useProject();
    const projectThemeConfiguration: ProjectThemeConfiguration = project.themeConfiguration;
    const themeColorToDisplay = buttonColor ? buttonColor : projectThemeConfiguration.primaryColor;

    return (
        <StyledButton
            $mainColor={themeColorToDisplay}
            $secondaryColor={Theme.colors.basicBackground}
            $isSelected={isSelected}
            data-automation={dataAutomation}
            {...props}
        >
            {children}
        </StyledButton>
    );
};

export default NavigationButton;
