import Markdown from 'markdown-to-jsx';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type { TaggableEntityItem } from '../../taggableEntities/entities';
import { useCommentContext } from '../context';

import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';

function findBeginingOfTagging(str, index) {
    for (let i = index; i >= 0; i--) {
        if (str[i] === '@') {
            return i;
        }
    }
    return -1;
}

function findEndOfTagging(str, index) {
    for (let i = index; i <= str.length - 1; i++) {
        if (str[i] === ')') {
            return i;
        }
    }
    return -1;
}

const FieldName = styled.span`
    font-weight: 500;
    color: ${Theme.colors.primaryHighlight};
`;

const InterfaceName = styled.span`
    font-weight: 500;
    text-decoration: none;
    color: ${Theme.colors.primaryHighlight};
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 20px;
    align-items: center;
`;

const TooltipFieldTitle = styled.div`
    color: var(--Greys-700, #5b636c);
    font-size: ${FontSize.SMALL_12};
`;

const TooltipFieldValue = styled.div`
    color: ${Theme.colors.primaryHighlight};
    font-size: ${FontSize.LARGE_16};
    font-weight: 500;
`;

const RequestFieldTooltip = ({ fieldName, fieldValue }) => (
    <TooltipContainer>
        <TooltipFieldTitle data-automation="comment-markdown-field-name">{fieldName}</TooltipFieldTitle>
        <TooltipFieldValue data-automation="comment-markdown-field-value">{fieldValue}</TooltipFieldValue>
    </TooltipContainer>
);

const RequestField = ({ children, ...props }) => (
    <Tooltip
        content={<RequestFieldTooltip fieldValue={props.previewValue} fieldName={props.display} />}
        showArrow={false}
        key={props.id}
        color={TooltipColor.WHITE}
    >
        <FieldName data-automation="comment-markdown-field">{props?.display}</FieldName>
    </Tooltip>
);

const IntakeSequence = ({ children, ...props }) => (
    <StateLink
        state="product.intakeInterface"
        params={{
            initiativeId: props.initiativeId,
            customTriggerId: props.id,
            workflowVersionType: 'DRAFT',
        }}
        key={props.id}
        openInNewTab
    >
        <InterfaceName>{props.display}</InterfaceName>
    </StateLink>
);

const getCustomEntityMarkdown = (entity: TaggableEntityItem, markDown: string): string => {
    if (entity.id.startsWith('FIDE') || entity.id.startsWith('TNK_')) {
        return `<Field id=\"${entity.id}\" display=\"${entity.display}\" previewValue=\"${entity.previewValue || '-'}\"/>`;
    } else if (entity.id.startsWith('CUTR')) {
        return `<Intake id=\"${entity.customTriggerId}\" display=\"${entity.display}\" initiativeId=\"${entity.initiativeId}\"/>`;
    }
    return markDown;
};

interface CommentMarkDownProps {
    commentText?: string;
}

const CommentMarkdown: React.FC<CommentMarkDownProps> = ({ commentText }) => {
    const { getTaggabaleEntityById } = useCommentContext();

    const getCustomMarkdown = useCallback(
        (markDown: string): string => {
            const regex = /(CUTR\w*-INIT\w*|FIDE\w*.*?(?=\))|TNK_\w*.*?(?=\)))/g;
            const entityIds = markDown.match(regex);
            const distinctEntityIds = [...new Set(entityIds)];

            distinctEntityIds?.forEach((entityId) => {
                const entity = getTaggabaleEntityById(entityId);
                if (entity) {
                    const beginingOfTagging = findBeginingOfTagging(markDown, markDown.indexOf(entityId));
                    const endOfTagging = findEndOfTagging(markDown, markDown.indexOf(entityId));
                    const fullTagingEntity = markDown.slice(beginingOfTagging - 1, endOfTagging + 1);
                    markDown = markDown.replaceAll(fullTagingEntity, getCustomEntityMarkdown(entity, markDown));
                }
            });

            return markDown;
        },
        [getTaggabaleEntityById],
    );

    const markdownValue = useMemo(() => {
        const updatedCommentText = commentText ? getCustomMarkdown(commentText) : '';
        // replace one * with two ** for backward compatibility with our old markdown
        return (updatedCommentText ?? '')
            .replaceAll(/(~{1,2})([^ ].*?[^ ])\1/gm, '$1$1$2$1$1')
            .replaceAll(/(\*{1,2})([^ ].*?[^ ])\1/gm, '$1$1$2$1$1')
            .replaceAll(/([*~])\1{3}/gm, '$1$1');
    }, [commentText, getCustomMarkdown]);

    return (
        <Markdown
            options={{
                forceInline: true,
                overrides: {
                    Field: {
                        component: RequestField,
                    },
                    Intake: {
                        component: IntakeSequence,
                    },
                },
            }}
        >
            {markdownValue}
        </Markdown>
    );
};

export default CommentMarkdown;
