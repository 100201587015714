import AllocadiaAuthenticationComponent from './AllocadiaAuthenticationComponent';
import allocadiaIcon from '../../../../apps/tracks/images/allocadia.png';
import allocadiaCircleIcon from '../../../../apps/tracks/images/integrations/allocadia-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

class AllocadiaIntegration extends IntegrationDefinitionBase {
    override name = 'allocadia';
    override displayName = 'Allocadia BI Export';
    override description = 'This integration will not collect any data';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [];
    override customizedAuthenticationComponent = AllocadiaAuthenticationComponent;
    override storageProviderSettings = new StorageProviderSettings(true, false);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(allocadiaCircleIcon, '130px'),
        new IntegrationIcon(allocadiaIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default AllocadiaIntegration;
