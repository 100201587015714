import React from 'react';

import type OAuthHandler from './oauth-handler.service';

/**
 * This interface represents the context of the generic integration.
 * Using this context all of the Generic Integration Component will be able to access the services.
 */
interface Props {
    createProjectApis: any;
    oauthHandler: OAuthHandler;
}

const GenericIntegrationContext = React.createContext<Props>({} as any);

export default GenericIntegrationContext;
