import LongDescriptionSelector from './LongDescriptionSelector';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkLongDescriptionSelector',
        reactToAngular(LongDescriptionSelector, ['customTriggerType', 'customTriggerSecondaryType']),
    );
