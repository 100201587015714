import type { LogicBlockValidator } from './LogicBlockValidator';

const outgoingWebhookValidator: LogicBlockValidator = ({ definition }) => {
    const validationObject: Record<string, string> = {};

    if (!definition.url?.evaluatedExpression) {
        validationObject.noURL = 'Must enter a url.';
    }

    if (!definition.contentType && definition.methodType !== 'GET') {
        validationObject.noContentType = 'Must enter a Content-Type.';
    }

    if (definition.methodType === 'GET' && definition.body?.evaluatedExpression) {
        validationObject.badRequest = "Can't have a body when doing a GET request.";
    }

    return Object.keys(validationObject).length > 0 ? validationObject : null;
};

export default outgoingWebhookValidator;
