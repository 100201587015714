import template from './tnkSpecialFieldManualInput.template.html?angularjs';

angular.module('tonkean.app').component('tnkSpecialFieldManualInput', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        fieldId: '<',
        selectedValueToUpdate: '<', // Optional. An initial value to set to the field (in case edit mode is wanted).
        relativeMode: '<', // When true, due date and eta are relative number of days inputs (and not date pickers).
        onManualSpecialFieldChanged: '&',
        invalid: '<',
    },
    template,
    controller: 'SpecialFieldManualInputCtrl',
});
