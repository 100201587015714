import React, { useContext } from 'react';

import TriggerSelector from '../../../../../../infrastructure/components/TriggerSelector';
import HistoryContext from '../../../../entities/HistoryContext';

interface Props {
    sequentialIdentifier?: string;
    value?: string;
    onChange(value?: string): void;
}

const WorkerRunsFiltersPopoverSearchTriggers: React.FC<Props> = ({ sequentialIdentifier, value, onChange }) => {
    const { state } = useContext(HistoryContext);

    return (
        <TriggerSelector
            groupId={state.groupId}
            environment={state.environment || ''}
            sequentialIdentifier={sequentialIdentifier}
            value={value}
            onChange={onChange}
        />
    );
};

export default WorkerRunsFiltersPopoverSearchTriggers;
