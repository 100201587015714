function CreateProjectApis($http, integrations, environment, projectIntegrationManager, tonkeanService) {
    /**
     * Creates a new project
     * @param {String} projectName The name of the project.
     * @param {String} organizationId The organization Id.
     * @param {[]} integrations Array of all integrations to the project.
     * @param addDomains if true, will add the creators domains into the allowed domains list, and will automatically add users from these domains to this project
     * @return {*}
     */
    this.createProject = function (projectName, organizationId, integrations, usecases, addDomains) {
        const data = {
            name: projectName,
            integrations,
            organizationId,
            metadata: {},
            usecases,
            addDomains,
        };
        return $http.post(buildUrl('me/projects'), data);
    };

    /**
     * Creates a new project
     * @param {String} projectId The project id.
     * @param {String} emailDomain The company domain of the project.
     * @param {String} projectName The name of the project.
     * @param {[]} integrations Array of all integrations to the project.
     * @return {*}
     */
    this.editProject = function (projectId, projectName, emailDomain, integrations) {
        const data = {
            name: projectName,
            integrations,
        };
        if (emailDomain) {
            data.emailDomain = emailDomain;
        }

        return $http.post(buildUrl(projectId), data);
    };

    /**
     * Gets a project from the local API
     * @param projectId
     * @return {*}
     */
    this.getLocalProject = function (projectId) {
        return $http.get(buildUrl(`projects/${projectId}`));
    };

    /**
     * Creates an integration for projects.
     * @param type things like 'github' or 'jira'.
     * @param config The config accoring to the type (ex. github expects a 'code' property).
     * @return {*}
     */
    this.createIntegration = function (projectId, type, config, integrationUniqueType, isMock) {
        const params = {
            params: {},
        };

        if (integrationUniqueType) {
            params.params.integrationUniqueType = integrationUniqueType;
        }

        if (isMock) {
            params.params.isMock = true;
        }

        return $http.post(buildUrl(`${projectId}/integrations/${type}`), config, params);
    };

    /**
     * Removes a project integration.
     * @param projectId project to create the integration in
     * @param projectIntegrationId The projet integration to be edited
     * @param integration integration details. A json looking like this -
     *  {
     *      id: "INTG...",
     *      projectDatas: [
     *          {},
     *          {},
     *          ...,
     *          {}¬
     *      ]
     *  }
     * @param displayName
     * @return {*}
     */
    this.editProjectIntegration = function (projectId, projectIntegrationId, integration, displayName) {
        const data = {
            integrationId: integration.id,
        };

        if (integration.projectDatas && integration.projectDatas.length > 0) {
            data.projectDatas = integration.projectDatas;
        } else {
            data.projectDatas = [];
        }

        if (displayName) {
            data.displayName = displayName;
        }

        data.isUpdateable = integration.isUpdateable;

        return $http.post(buildUrl(`${projectId}/${projectIntegrationId}`), data);
    };

    /**
     * Creates a project integration.
     * @param project project to create the integration in
     * @param integration integration details. A json looking like this -
     *  {
     *      id: "INTG...",
     *      projectDatas: [
     *          {},
     *          {},
     *          ...,
     *          {}¬
     *      ]
     *  }
     *  @param nativeEntitiesInboundConfiguration a map that holds data about which entity types should be synced into tonkean
     */
    this.createProjectIntegration = function (
        project,
        integration,
        displayName,
        isMock,
        sharedCredentialId,
        nativeEntitiesInboundConfiguration,
    ) {
        const data = {
            integrationId: integration.id,
            displayName,
        };

        if (integration.projectDatas && integration.projectDatas.length > 0) {
            data.projectDatas = integration.projectDatas;
        } else {
            data.projectDatas = [];
        }

        if (integration.isUpdateable) {
            data.isUpdateable = integration.isUpdateable;
        }

        if (isMock) {
            data.isMock = true;
        }

        if (sharedCredentialId) {
            data.sharedCredentialId = sharedCredentialId;
        }

        let dataRetentionDays;
        // If the project is not under enterprise plan.
        if (!project.isEnterprise) {
            // if not enterprise check the default retention settings
            const retentionConfig = integrations.getDataRetentionSettingsByIntegration(
                integration.integrationType.toLowerCase(),
            );
            if (retentionConfig && retentionConfig.daysDefault) {
                if (project.licensed && project.license?.tier) {
                    dataRetentionDays = retentionConfig.daysDefault[project.license.tier];
                } else {
                    // means it's trial or free
                    dataRetentionDays = retentionConfig.daysDefault?.free;
                }
                if (dataRetentionDays > 0) {
                    data.defaultExpirationDays = dataRetentionDays;
                }
            }
        }

        data.nativeEntitiesInboundConfiguration = nativeEntitiesInboundConfiguration ?? {};

        return $http.post(buildUrl(`${project.id}/integrations`), data);
    };

    /**
     * Creates an integration for projects.
     * @param type things like 'github' or 'jira'.
     * @param config The config accoring to the type (ex. github expects a 'code' property).
     * @return {*}
     */
    this.removeProjectIntegration = function (projectId, projectIntegrationId) {
        return tonkeanService.deleteProjectIntegration(projectId, projectIntegrationId);
    };

    /**
     * Gets the auto complete options for a given field
     * @param integrationId The integration / project integration id
     * @param fieldName The name of the field to be completed
     * @param params Query params
     * @return {*}
     */
    this.getAutoCompleteOptions = function (projectId, integrationId, fieldName, params) {
        return tonkeanService.getAutoCompleteOptions(projectId, integrationId, fieldName, params);
    };

    /**
     * Gets the project auto complete options for a given field
     * @param integrationId The project ID
     * @return {*}
     */
    this.getProjectIntegrationUnMappedUsers = function (projectIntegrationId, projectIntegrationType) {
        let config = {};

        if (projectIntegrationType !== '') {
            config = {
                params: projectIntegrationManager.getUnmappedUserFilterParamsByIntegrationType(
                    projectIntegrationType.toLowerCase(),
                ),
            };
        }

        return $http.get(buildUrl(`${projectIntegrationId}/userNamesWithNoEmails`), config);
    };

    /**
     * Get user mapping for integration
     */
    this.getProjectIntegrationUserMapping = function (projectId, projectIntegrationId) {
        const config = {
            params: {
                projectId,
            },
        };
        return $http.get(buildUrl(`${projectIntegrationId}/externalUsers`), config);
    };

    /**
     * Updates user mapping for integration
     * @param projectId
     */
    this.updateProjectIntegrationUserMapping = function (
        projectIntegrationId,
        usersMap,
        shouldOverrideUserMapping = false,
    ) {
        const data = {
            shouldOverrideUserMapping,
            json: usersMap,
        };

        return $http.post(buildUrl(`${projectIntegrationId}/externalUsers`), data);
    };

    /**
     * Updates user calendar integration.
     * @param projectId project to create the integration in
     * @param integration integration details. A json looking like this -
     *  {
     *      integrationId: "INTG..."
     *  }
     */
    this.updatePersonCalendarIntegration = function (projectId, integration) {
        let data = {};
        if (integration) {
            data = {
                value: integration.id,
            };
        }

        return $http.post(buildUrl(`${projectId}/calendar`), data);
    };

    /**
     * Gets the health status of the APIs
     * @return {*}
     */
    this.getHealth = function () {
        return $http.get(buildUrl('health'));
    };

    /**
     * Gets the current user.
     * @return {HttpPromise}
     */
    this.getMe = function () {
        return $http.get(buildUrl('me'));
    };

    /**
     * Sends a request access request message.
     * @param projectId - the project id to ask for access to.
     * @param personId - optional. which person id to ask in behalf of
     * @return {HttpPromise}
     */
    this.requestFullAccess = function (projectId, personId) {
        const data = {};
        if (personId) {
            data.personId = personId;
        }
        return $http.post(buildUrl(`${projectId}/requestLicense`), data);
    };

    /**
     * Tries grabbing an available license (seat) for the current user.
     * If there's no available seat, an error is returned.
     * @param projectId - the current project id.
     * @param shouldStartPreview - when true, the server will be requested to start a full user preview for the current user. Ignored if false or undefined.
     * @return {HttpPromise}
     */
    this.grabLicense = function (projectId, shouldStartPreview) {
        const data = {};

        // Only add shouldStartPreview to the body params if it was supplied and is true.
        if (shouldStartPreview) {
            data.shouldStartPreview = true;
        }

        return $http.post(buildUrl(`${projectId}/grabLicense`), data);
    };

    // region Private Methods

    /**
     * Builds a url for the API
     * @param {string} path The API path
     * @return {string} a URL.
     */
    function buildUrl(path) {
        return environment.apiUrl + path;
    }

    // endregion
}

angular.module('tonkean.shared').service('createProjectApis', CreateProjectApis);
