import { integrationsConsts } from '@tonkean/logic-block-configs';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkflowItemSelectionCtrl($scope, customTriggerManager, utils, customFieldsManager) {
    const ctrl = this;

    $scope.data = {
        performOnWorkerItem: ctrl.performOnWorkerItem,
        creatingCustomTriggerId: ctrl.creatingCustomTriggerId,
        idRelationFieldDefinitionId: ctrl.idRelationFieldDefinitionId,
        entityMetadata: ctrl.entityMetadata,
        viewOnly: ctrl.viewOnly,
        entitiesMetadata: ctrl.entitiesMetadata,
        entities: ctrl.entities,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        currentCustomTrigger: ctrl.currentCustomTrigger,

        specificItemsSelectionOptions: null,
        selectedSpecificItem: null,

        specificItemType: {
            creatingCustomTrigger: 'creatingCustomTrigger',
            idRelationFieldDefinition: 'idRelationFieldDefinition',
            workerOriginalItem: 'workerOriginalItem',
        },
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        initializeSpecificItemsOptions();

        if ($scope.data.performOnWorkerItem) {
            $scope.data.selectedSpecificItem = utils.findFirst(
                $scope.data.specificItemsSelectionOptions,
                (option) => option.type === $scope.data.specificItemType.workerOriginalItem,
            );
        }

        if ($scope.data.creatingCustomTriggerId) {
            $scope.data.selectedSpecificItem = utils.findFirst(
                $scope.data.specificItemsSelectionOptions,
                (option) =>
                    option.type === $scope.data.specificItemType.creatingCustomTrigger &&
                    option.customTrigger.id === $scope.data.creatingCustomTriggerId,
            );
        }

        if ($scope.data.idRelationFieldDefinitionId) {
            $scope.data.selectedSpecificItem = utils.findFirst(
                $scope.data.specificItemsSelectionOptions,
                (option) =>
                    option.type === $scope.data.specificItemType.idRelationFieldDefinition &&
                    option.fieldDefinition.id === $scope.data.idRelationFieldDefinitionId,
            );
        }

        $scope.onWorkflowItemSelectionChanged(false);
    };

    /**
     * Occurs on changes to the component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.entityMetadata) {
            $scope.data.entityMetadata = ctrl.entityMetadata;
            $scope.onWorkflowItemSelectionChanged(false);
        }

        if (changesObj.entitiesMetadata) {
            $scope.data.entitiesMetadata = ctrl.entitiesMetadata;
            $scope.onWorkflowItemSelectionChanged(false);
        }

        if (changesObj.entities) {
            $scope.data.entities = ctrl.entities;
            $scope.onWorkflowItemSelectionChanged(false);
        }
    };

    /**
     * Occurs on selection of a specific item.
     */
    $scope.onSpecificItemSelected = function (selectedSpecificItem) {
        $scope.data.selectedSpecificItem = selectedSpecificItem;
        $scope.onWorkflowItemSelectionChanged(true);
    };

    /**
     * Occurs once the workflow item selection is changed.
     */
    $scope.onWorkflowItemSelectionChanged = function (shouldSaveLogic) {
        if (ctrl.onWorkflowItemSelectionChanged) {
            ctrl.onWorkflowItemSelectionChanged({
                performOnWorkerItem:
                    $scope.data.selectedSpecificItem?.type === $scope.data.specificItemType?.workerOriginalItem,
                creatingCustomTriggerId:
                    $scope.data.selectedSpecificItem?.type === $scope.data.specificItemType?.creatingCustomTrigger
                        ? $scope.data.selectedSpecificItem?.customTrigger?.id
                        : null,
                idRelationFieldDefinitionId:
                    $scope.data.selectedSpecificItem?.type === $scope.data.specificItemType?.idRelationFieldDefinition
                        ? $scope.data.selectedSpecificItem?.fieldDefinition?.id
                        : null,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Initializes the options for specific item.
     */
    function initializeSpecificItemsOptions() {
        // Initializing empty array.
        $scope.data.specificItemsSelectionOptions = [];

        // Adding the option of the original worker item.
        const rootItemSpecificItemOption = {
            type: $scope.data.specificItemType.workerOriginalItem,
            displayName: 'Root Monitored Item',
        };

        $scope.data.specificItemsSelectionOptions.push(rootItemSpecificItemOption);
        $scope.data.selectedSpecificItem = rootItemSpecificItemOption;

        if (
            $scope.data.groupId &&
            customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId] &&
            customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId].length
        ) {
            const idRelationFieldDefinitions = customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId]
                .filter((fieldDefinition) => fieldDefinition.idRelationField)
                .filter(
                    (fieldDefinition) =>
                        // Filter out the current trigger, if provided
                        !$scope.data.currentCustomTrigger ||
                        $scope.data.currentCustomTrigger !== fieldDefinition.linkedCustomTrigger?.id,
                );

            if (idRelationFieldDefinitions && idRelationFieldDefinitions.length) {
                $scope.data.specificItemsSelectionOptions = $scope.data.specificItemsSelectionOptions.concat(
                    idRelationFieldDefinitions
                        .map((idRelationFieldDefinition) => {
                            // Figuring out the entity we're dealing with in the component by either taking it from the inner entity property of the entity metadata, or just taking the entityMetadata object.
                            const actualEntity =
                                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                                    ? $scope.data.entityMetadata.entity
                                    : $scope.data.entityMetadata;
                            const actualEntities = $scope.data.entitiesMetadata
                                ? Object.entries($scope.data.entitiesMetadata).map(
                                      ([entityKey, entityMetadata]) => entityMetadata.entity,
                                  )
                                : $scope.data.entities || [];

                            // If we are a field definition that is linked to a custom trigger, we take it if the custom trigger entity is matched with the configured entity.
                            if (
                                idRelationFieldDefinition.linkedCustomTrigger &&
                                idRelationFieldDefinition.linkedCustomTrigger.id &&
                                customTriggerManager.getCachedCustomTrigger(
                                    $scope.data.workflowVersionId,
                                    idRelationFieldDefinition.linkedCustomTrigger.id,
                                )
                            ) {
                                const actionsByIntegrationsMap = integrationsConsts.getActionsByIntegrationsMap();
                                const definition = customTriggerManager.getCachedCustomTrigger(
                                    $scope.data.workflowVersionId,
                                    idRelationFieldDefinition.linkedCustomTrigger.id,
                                ).customTriggerActions?.[0]?.customTriggerActionDefinition;

                                if (
                                    definition &&
                                    definition.integrationType &&
                                    definition.performedActionDefinition &&
                                    definition.performedActionDefinition.customActionKey &&
                                    actionsByIntegrationsMap[definition.integrationType] &&
                                    actionsByIntegrationsMap[definition.integrationType].customActions &&
                                    actionsByIntegrationsMap[definition.integrationType].customActions[
                                        definition.performedActionDefinition.customActionKey
                                    ] &&
                                    actionsByIntegrationsMap[definition.integrationType].customActions[
                                        definition.performedActionDefinition.customActionKey
                                    ].entity &&
                                    actionsByIntegrationsMap[definition.integrationType].customActions[
                                        definition.performedActionDefinition.customActionKey
                                    ].entity.entity &&
                                    ((actualEntity &&
                                        actionsByIntegrationsMap[definition.integrationType].customActions[
                                            definition.performedActionDefinition.customActionKey
                                        ].entity.entity.toLowerCase() === actualEntity.toLowerCase()) ||
                                        (actualEntities &&
                                            actualEntities.some(
                                                (singleEntity) =>
                                                    singleEntity.toLowerCase() ===
                                                    actionsByIntegrationsMap[definition.integrationType].customActions[
                                                        definition.performedActionDefinition.customActionKey
                                                    ].entity.entity.toLowerCase(),
                                            )))
                                ) {
                                    return {
                                        fieldDefinition: idRelationFieldDefinition,
                                        matchDueToCustomTrigger: true,
                                        matchedDueToFieldDefinition: false,
                                    };
                                } else if (
                                    definition &&
                                    definition.performOnEntity &&
                                    definition.performOnEntity.entity &&
                                    ((actualEntity &&
                                        definition.performOnEntity.entity.toLowerCase() ===
                                            actualEntity.toLowerCase()) ||
                                        (actualEntities &&
                                            actualEntities.some(
                                                (singleEntity) =>
                                                    definition.performOnEntity.entity.toLowerCase() ===
                                                    singleEntity.toLowerCase(),
                                            )))
                                ) {
                                    return {
                                        fieldDefinition: idRelationFieldDefinition,
                                        matchDueToCustomTrigger: true,
                                        matchedDueToFieldDefinition: false,
                                    };
                                } else if (
                                    definition &&
                                    definition.performedActionType === 'PROJECT_INTEGRATION_ACTION' &&
                                    definition.performedActionDefinition.projectIntegrationActionId
                                ) {
                                    return {
                                        fieldDefinition: idRelationFieldDefinition,
                                        matchDueToCustomTrigger: true,
                                        matchedDueToFieldDefinition: false,
                                    };
                                }
                            }

                            // Otherwise, we try to check if we're an external field, and we are - does our definition support the same entity we're dealing with (note the use of lower case).
                            if (
                                idRelationFieldDefinition.type === 'EXTERNAL' &&
                                idRelationFieldDefinition.definition &&
                                idRelationFieldDefinition.definition['ExternalType'] &&
                                ((actualEntity &&
                                    idRelationFieldDefinition.definition['ExternalType'].toLowerCase() ===
                                        actualEntity.toLowerCase()) ||
                                    (actualEntities &&
                                        actualEntities.some(
                                            (singleEntity) =>
                                                idRelationFieldDefinition.definition['ExternalType'].toLowerCase() ===
                                                singleEntity.toLowerCase(),
                                        )))
                            ) {
                                return {
                                    fieldDefinition: idRelationFieldDefinition,
                                    matchDueToCustomTrigger: false,
                                    matchedDueToFieldDefinition: true,
                                };
                            }

                            // At last, if we didn't match so far, we have nothing to do with this field definition, we return false.
                            return {
                                fieldDefinition: idRelationFieldDefinition,
                                matchDueToCustomTrigger: false,
                                matchedDueToFieldDefinition: false,
                            };
                        })
                        .filter(
                            (matchingObject) =>
                                matchingObject &&
                                (matchingObject.matchDueToCustomTrigger || matchingObject.matchedDueToFieldDefinition),
                        )
                        .map((matchingObject) => {
                            if (matchingObject.matchDueToCustomTrigger) {
                                const customTrigger = customTriggerManager.getCachedCustomTrigger(
                                    $scope.data.workflowVersionId,
                                    matchingObject.fieldDefinition.linkedCustomTrigger.id,
                                );

                                return {
                                    customTrigger,
                                    fieldDefinition: matchingObject.fieldDefinition,
                                    type: $scope.data.specificItemType.creatingCustomTrigger,
                                    displayName: `Created in - ${customTrigger.displayName}`,
                                    fullName: matchingObject.fieldDefinition.name,
                                };
                            } else if (matchingObject.matchedDueToFieldDefinition) {
                                return {
                                    customTrigger: null,
                                    fieldDefinition: matchingObject.fieldDefinition,
                                    type: $scope.data.specificItemType.idRelationFieldDefinition,
                                    displayName: `Id Relation - ${matchingObject.fieldDefinition.name}`,
                                    fullName: matchingObject.fieldDefinition.name,
                                };
                            }
                            return null;
                        })
                        .filter((item) => !!item),
                );
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('WorkflowItemSelectionCtrl', lateConstructController(WorkflowItemSelectionCtrl));
