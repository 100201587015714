import template from './formPage.template.html?angularjs';

angular.module('tonkean.app').component('tnkFormPage', {
    bindings: {
        form: '<',
        project: '<',
        loadForm: '<',
        addUnderInitiative: '<',
        collectionForm: '<',
        group: '<',
        fieldDefinitionId: '<',
        onlyInnerItems: '<',
        hierarchy: '<',
        formVersionType: '<',
        viewMode: '<',
        workerRunId: '<',
        workerRunLogicActionType: '<',
        formId: '<',
        sessionId: '<',
        customTriggerId: '<',
        initiativeId: '<',
        onFormClosed: '<',
        onInitiativeCreated: '<',
        isModal: '<',
        onSubmitResolved: '<',
        itemInterfaceId: '<',
        workflowVersionId: '<',
        parentInitiativeId: '<',
        formModalRef: '<',
        createUsingCustomTriggerId: '<',
    },
    template,
    controller: 'FormPageCtrl',
});
