import NetsuiteCustomActions from './NetsuiteCustomActions';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkNetsuiteCustomActions',
        reactToAngular(NetsuiteCustomActions, [
            'groupId',
            'workflowVersionId',
            'configuredLogic',
            'previewEvaluationSource',
            'definition',
            'projectIntegration',
            'validationObject',
            'onDefinitionChanged',
        ]),
    );
