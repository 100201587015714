import template from './tnkNotificationSettings.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

function tnkNotificationSettings() {
    return {
        restrict: 'E',
        scope: {
            overwriteSettings: '=',
            overwriteId: '=',
            disableEdit: '=',
            controlledSave: '<',
            explanationInTooltip: '<',
            onChannelChanged: '<',
            viewOnly: '<',
            hideTagsBackgroundWhenDisabled: '<',
        },
        template,
        controller: [
            '$scope',
            '$rootScope',
            'tonkeanService',
            'communicationIntegrationsService',
            function ($scope, $rootScope, tonkeanService, communicationIntegrationsService) {
                $scope.pm = $rootScope.pm;
                $scope.cis = communicationIntegrationsService;

                $scope.data = {
                    channel: $scope.overwriteSettings
                        ? $scope.overwriteSettings
                        : $scope.pm.project.notificationSettings,
                    reloadChannels: false, // Used for the tnkChannelSelector (when set to true, tells him to reload the channels).
                    communicationIntegration: $scope.cis.getFirstThatSupports($scope.cis.features.channels),
                    hideTagsBackgroundWhenDisabled: $scope.hideTagsBackgroundWhenDisabled,
                };

                if ($scope.data.channel) {
                    $scope.data.newChannel = $scope.data.channel;
                    $scope.data.newChannel.displayName = $scope.data.channel.channelName;
                }

                $scope.onChannelSelected = function (channel) {
                    $scope.data.newChannel = channel;
                    $scope.data.newChannel.channelId = channel.value;
                    $scope.onChannelChanged(channel);
                };

                $scope.save = function (channel) {
                    analyticsWrapper.track('Set channel', {
                        category: $scope.overwriteId ? 'Track view' : 'Default',
                        label: channel ? channel.displayName : 'Empty',
                    });
                    $scope.savingChannel = true;

                    tonkeanService
                        .setNotificationChannel(
                            $scope.overwriteId ? $scope.overwriteId : $scope.pm.project.id,
                            channel ? channel.value : null,
                            !!$scope.overwriteId,
                        )
                        .then(function () {
                            $scope.data.editChannel = false;
                            $scope.pm.getProjectData(true);
                            $scope.data.channel = channel
                                ? { channelId: channel.value, channelName: channel.displayName }
                                : null;
                        })
                        .finally(() => {
                            $scope.savingChannel = false;
                        });
                };
            },
        ],
    };
}
angular.module('tonkean.app').directive('tnkNotificationSettings', tnkNotificationSettings);
