import template from './tnkUpdateFieldsLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkUpdateFieldsLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        reloadFields: '<',
        singleFieldSelector: '@',
        singleFieldSelectorLabel: '@',
        singleFieldSelectorFieldId: '@',
        cantControlNotification: '<',
        onActionsChanged: '&',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'UpdateFieldsLogicConfigurationCtrl',
});
