<tags-input
    ng-model="tags"
    class="tnk-selector"
    text="latestTagSearch"
    placeholder="{{ placeholder }}"
    add-from-autocomplete-only="false"
    add-on-comma="true"
    add-on-enter="true"
    add-on-blur="true"
    on-tag-adding="isValidTag($tag)"
    on-tag-added="updateTags()"
    on-tag-removed="updateTags()"
>
    <auto-complete source="isValidOnKeyStroke($query)"></auto-complete>
</tags-input>
<div class="common-color-warning margin-top-xs common-size-xxxxs inline-block height-like-font">
    {{ invalidTagMessage }}
</div>
