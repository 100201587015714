import InitiativeViewFormTabContent from './InitiativeViewFormTabContent';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkInitiativeViewFormTabContent',
        reactToAngular(InitiativeViewFormTabContent, ['initiative', 'forms', 'formSubmissions', 'viewOnlyMode']),
    );
