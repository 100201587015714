import React from 'react';

import type SQLDatabaseEntityIdType from '../entities/SQLDatabaseEntityIdType';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

interface SqlDatabaseIntegrationConfigurationContextDefinition {
    queryConfigurations: SQLDatabaseQueryConfiguration[];
    toggleEditMode: (newEditModeValue: boolean, index: number) => void;
    changeQueryName: (newQueryName: string, index: number) => void;
    changeExpanded: (newExpandedValue: boolean, index: number) => void;
    changeQuery: (newQuery: string, index: number) => void;
    changeEntityIdType: (newEntityIdType: SQLDatabaseEntityIdType, index: number) => void;
    changeIdentifierColumn: (newIdentifierColumn: string, index: number) => void;
    changePaginationEnabled: (newPaginationEnabled: boolean, index: number) => void;
    changePagesAmount: (newPagesAmount: string, index: number) => void;
    changePageSize: (newPageSize: number, index: number) => void;
    changeCustomTimestampsEnabled: (newCustomTimestampsEnabled: boolean, index: number) => void;
    changeCreatedColumn: (newCreatedColumn: string, index: number) => void;
    changeUpdatedColumn: (newUpdatedColumn: string, index: number) => void;
    changeQueryTested: (newQueryTestedValue: boolean, index: number) => void;
    removeQuery: (index: number) => void;
}

const SQLDatabaseIntegrationConfigurationContext =
    React.createContext<SqlDatabaseIntegrationConfigurationContextDefinition>({
        queryConfigurations: [],
        toggleEditMode: () => {},
        changeQueryName: () => {},
        changeExpanded: () => {},
        changeQuery: () => {},
        changeEntityIdType: () => {},
        changeIdentifierColumn: () => {},
        changePaginationEnabled: () => {},
        changePagesAmount: () => {},
        changePageSize: () => {},
        changeCustomTimestampsEnabled: () => {},
        changeCreatedColumn: () => {},
        changeUpdatedColumn: () => {},
        changeQueryTested: () => {},
        removeQuery: () => {},
    });

export default SQLDatabaseIntegrationConfigurationContext;
