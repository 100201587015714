import { FormQuestionType, FormType } from '@tonkean/tonkean-entities';

const validateFormBeforePublish = (validationObject, form) => {
    if (
        form &&
        form.formType === FormType.UPDATE &&
        form.formQuestionType === FormQuestionType.UPDATE_FIELDS &&
        form.definition.fields.length === 0
    ) {
        validationObject.formTypeError = 'Update form of type Update fields must have at least one field configured';
    }
};

export default validateFormBeforePublish;
