/**
 * Returns the server object representing the match configuration selection.
 */
export function convertMatchSelectionToServerObject(matchConfigurationSelection) {
    return {
        matchOption: matchConfigurationSelection.matchOptionApiName,
        performOnWorkerItem: matchConfigurationSelection.performOnWorkerItem,
        creatingCustomTriggerId: matchConfigurationSelection.creatingCustomTriggerId,
        idRelationFieldDefinitionId: matchConfigurationSelection.idRelationFieldDefinitionId,
        entityMatchField: matchConfigurationSelection.entityMatchField,
        workerItemMatchFieldDefinition: matchConfigurationSelection.workerItemMatchFieldDefinition,
        workerItemMatchSpecialField: matchConfigurationSelection.workerItemMatchSpecialField,
        customQuery: matchConfigurationSelection.customQuery,
        orderByField: matchConfigurationSelection.orderByField,
        orderByOrder: matchConfigurationSelection.orderByOrder,
        orderByFieldType: matchConfigurationSelection.orderByFieldType,
        isForMatchingItem: matchConfigurationSelection.isForMatchingItem,
        matchedItemSourceGroupId: matchConfigurationSelection.matchedItemSourceGroupId,
        matchedItemSourceWorkflowVersionId: matchConfigurationSelection.matchedItemSourceWorkflowVersionId,
        matchingRecurrence: matchConfigurationSelection.matchingRecurrence,
    };
}
