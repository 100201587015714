import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useToastMessage } from '@tonkean/angular-hooks';
import { SavingIndicator, Toggle, useCreateDialog } from '@tonkean/infrastructure';
import { SavingState } from '@tonkean/tonkean-entities';
import type { ToggleComponentSizes } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledToggle = styled(Toggle)<{ $externallyDisabled?: boolean }>`
    ${({ $externallyDisabled = false }) =>
        !$externallyDisabled &&
        css`
            margin-right: 10px;
        `}
`;

interface Props {
    active: boolean;
    onChange: (active: boolean) => Promise<unknown>;
    errorMessage?: string;
    emitErrorMessage?: boolean;
    disabled?: boolean;
    size?: ToggleComponentSizes;
    confirmDialog: { title?: React.ReactNode; content: React.ReactNode };
}

const AutosaveToggle: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { active, onChange: onChangeProp, errorMessage, emitErrorMessage = !!errorMessage, disabled, size, confirmDialog },
    ref,
) => {
    const { confirm } = useCreateDialog();
    const emitToast = useToastMessage();

    const [savingIndicator, setSavingIndicator] = useState<SavingState>(SavingState.NONE);

    const onChange = (newActive: boolean) => {
        confirm(confirmDialog.title || 'Are you sure?', confirmDialog.content, {
            okLabel: 'Confirm',
            cancelLabel: 'Cancel',
        }).then((confirmResponse) => {
            if (confirmResponse) {
                setSavingIndicator(SavingState.LOADING);
                onChangeProp(newActive)
                    .then(() => {
                        setSavingIndicator(SavingState.SAVED);
                    })
                    .catch((error) => {
                        if (emitErrorMessage) {
                            emitToast(errorMessage ?? getStateError(error));
                        }

                        setSavingIndicator(SavingState.ERROR);
                    });
            }
        });
    };

    return (
        <ToggleWrapper ref={ref}>
            <StyledToggle
                checked={active}
                disabled={disabled || savingIndicator === SavingState.LOADING}
                $externallyDisabled={disabled}
                size={size}
                onChange={(event) => onChange(event.target.checked)}
                showInnerLabel
            />
            {!disabled && <SavingIndicator state={savingIndicator} />}
        </ToggleWrapper>
    );
};

export default React.forwardRef(AutosaveToggle);
