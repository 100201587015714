import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BuildIcon } from '../../../../images/icons/build-icon.svg';
import { ReactComponent as ProductionIcon } from '../../../../images/icons/production-icon.svg';

import type { Environment } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    span {
        font-size: ${FontSize.SMALL_12};
    }
`;

interface Props {
    environment: Environment;
}

const SolutionReportListItemMetricEnvironment: React.FC<Props> = ({ environment }) => {
    return (
        <Wrapper>
            {environment === 'build' ? <BuildIcon /> : <ProductionIcon />}
            <span>{environment}</span>
        </Wrapper>
    );
};

export default SolutionReportListItemMetricEnvironment;
