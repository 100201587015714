import React, { useCallback } from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { LogicConfigurationTitle } from '@tonkean/logic-configuration';
import { LogicConfigurationSubTitle } from '@tonkean/logic-configuration';
import type { LogicConfigurationProps } from '@tonkean/logic-configuration';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const LineTemplateTitle = styled(LogicConfigurationTitle)`
    font-size: ${FontSize.MEDIUM_14};
`;

const NoteText = styled.div`
    margin-top: 10px;
    font-size: ${FontSize.SMALL_12};
`;

const LineTemplate = styled.div`
    background: ${Theme.colors.HEX_F2F2F2};
    padding: 5px;
    border-radius: 5px;
    border: 1px solid ${Theme.current.palette.general.border};
`;

const RequiredSign = styled.span`
    color: ${Theme.colors.error};
    margin-left: 3px;
`;

const RequiredAsterisk: React.FC = () => (
    <Tooltip content="Required Field">
        <RequiredSign>*</RequiredSign>
    </Tooltip>
);

const NetsuiteAddJournalEntryCustomActionConfiguration: React.FC<LogicConfigurationProps> = ({
    groupId,
    workflowVersionId,
    definition,
    previewEvaluationSource,
    configuredLogic,
    // projectIntegration,
    // validationObject,
    onDefinitionChanged,
}) => {
    // Initiate the line template to an empty object if it doesnt exist
    if (!definition.lineTemplate) {
        definition.lineTemplate = {};
    }

    const onSubsidiaryChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.subsidiaryId = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onLineTemplateAccountIdChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.lineTemplate.accountId = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.lineTemplate, onDefinitionChanged],
    );

    const onLineTemplateDebitChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.lineTemplate.debit = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.lineTemplate, onDefinitionChanged],
    );

    const onLineTemplateMemoChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.lineTemplate.memo = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.lineTemplate, onDefinitionChanged],
    );

    const onLineTemplateCreditChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.lineTemplate.credit = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.lineTemplate, onDefinitionChanged],
    );

    const onDepartmentChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.departmentId = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onMemoChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.memo = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    const onTransactionDateChanged = useCallback(
        (_originalExpression, _evaluatedExpression, expression, shouldSaveLogic) => {
            definition.transactionDate = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition, onDefinitionChanged],
    );

    return (
        <>
            <LogicConfigurationTitle>
                Subsidiary ID
                <RequiredAsterisk />
            </LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                savedOriginalExpression={definition.subsidiaryId?.originalExpression}
                savedEvaluatedExpression={definition.subsidiaryId?.evaluatedExpression}
                onTonkeanExpressionChanged={onSubsidiaryChanged}
                placeholder="Entry Subsidiary Internal ID..."
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>Department ID</LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                previewEvaluationSource={previewEvaluationSource}
                workflowVersionId={workflowVersionId}
                savedOriginalExpression={definition.departmentId?.originalExpression}
                savedEvaluatedExpression={definition.departmentId?.evaluatedExpression}
                onTonkeanExpressionChanged={onDepartmentChanged}
                placeholder="Entry Department Internal ID..."
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>Memo</LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                previewEvaluationSource={previewEvaluationSource}
                workflowVersionId={workflowVersionId}
                savedOriginalExpression={definition.memo?.originalExpression}
                savedEvaluatedExpression={definition.memo?.evaluatedExpression}
                onTonkeanExpressionChanged={onMemoChanged}
                placeholder="Entry Memo..."
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />

            <LogicConfigurationTitle>Transaction Date</LogicConfigurationTitle>
            <TonkeanExpression
                groupId={groupId}
                logicId={configuredLogic.node.id}
                previewEvaluationSource={previewEvaluationSource}
                workflowVersionId={workflowVersionId}
                savedOriginalExpression={definition.transactionDate?.originalExpression}
                savedEvaluatedExpression={definition.transactionDate?.evaluatedExpression}
                onTonkeanExpressionChanged={onTransactionDateChanged}
                placeholder="Entry Transaction Date..."
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />
            <NoteText>Note: format should be YYYY-MM-DD</NoteText>

            <hr />

            <LogicConfigurationTitle size="large">Entry Lines</LogicConfigurationTitle>
            <LogicConfigurationSubTitle>
                This <b>template</b> will be used <b>for each inner item</b>, in order to <b>create lines</b> in the
                Entry.
                <br />
                <br />
                You <b>must have at least 2 lines</b>, and the total <b>credit</b> across all lines <b>must balance</b>{' '}
                out the total <b>debit</b> across all lines.
            </LogicConfigurationSubTitle>

            <LineTemplate>
                <LineTemplateTitle>
                    Account ID
                    <RequiredAsterisk />
                </LineTemplateTitle>
                <TonkeanExpression
                    groupId={groupId}
                    logicId={configuredLogic.node.id}
                    previewEvaluationSource={previewEvaluationSource}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={definition.lineTemplate.accountId?.originalExpression}
                    savedEvaluatedExpression={definition.lineTemplate.accountId?.evaluatedExpression}
                    onTonkeanExpressionChanged={onLineTemplateAccountIdChanged}
                    placeholder="Entry Account Internal ID..."
                    showFormulasTab
                    doNotEvaluatePreview
                    saveOnKeyUp
                />

                <LineTemplateTitle>
                    Credit Amount
                    <RequiredAsterisk />
                </LineTemplateTitle>
                <TonkeanExpression
                    groupId={groupId}
                    logicId={configuredLogic.node.id}
                    previewEvaluationSource={previewEvaluationSource}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={definition.lineTemplate.credit?.originalExpression}
                    savedEvaluatedExpression={definition.lineTemplate.credit?.evaluatedExpression}
                    onTonkeanExpressionChanged={onLineTemplateCreditChanged}
                    placeholder="Credit amount..."
                    showFormulasTab
                    doNotEvaluatePreview
                    saveOnKeyUp
                />

                <LineTemplateTitle>
                    Debit Amount
                    <RequiredAsterisk />
                </LineTemplateTitle>
                <TonkeanExpression
                    groupId={groupId}
                    logicId={configuredLogic.node.id}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={definition.lineTemplate.debit?.originalExpression}
                    savedEvaluatedExpression={definition.lineTemplate.debit?.evaluatedExpression}
                    onTonkeanExpressionChanged={onLineTemplateDebitChanged}
                    placeholder="Debit amount..."
                    previewEvaluationSource={previewEvaluationSource}
                    showFormulasTab
                    doNotEvaluatePreview
                    saveOnKeyUp
                />

                <NoteText>
                    Note: each line can have either credit or debit, and the amounts must balance across the lines
                </NoteText>

                <LineTemplateTitle>Line Memo</LineTemplateTitle>
                <TonkeanExpression
                    groupId={groupId}
                    logicId={configuredLogic.node.id}
                    previewEvaluationSource={previewEvaluationSource}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={definition.lineTemplate.memo?.originalExpression}
                    savedEvaluatedExpression={definition.lineTemplate.memo?.evaluatedExpression}
                    onTonkeanExpressionChanged={onLineTemplateMemoChanged}
                    placeholder="Line Memo..."
                    showFormulasTab
                    doNotEvaluatePreview
                    saveOnKeyUp
                />
            </LineTemplate>
        </>
    );
};

export default NetsuiteAddJournalEntryCustomActionConfiguration;
