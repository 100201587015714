<div
    class="worker-tile-new-worker outer"
    ng-mouseenter="mouseEnterNewModuleTile()"
    ng-mouseleave="mouseLeaveNewModuleTile()"
>
    <div
        class="new-module-box below relative"
        ng-class="{
            'box-hover-opacity': data.isHovered
        }"
    >
        <span class="flex padding-left-14 padding-top-14 padding-right-14 mod-justify-space line-height-xs">
            <!-- Module name -->
            <div
                class="
                    worker-name-card-title
                    common-title-xs
                    mod-light
                    padding-bottom-xs
                    margin-right-xs
                    common-ellipsis
                "
            >
                New Module
            </div>
        </span>

        <!-- Plus Button -->
        <span class="new-module-box-plus-container flex-vmiddle mod-justify-center">
            <span class="new-module-box-plus-outer-circle flex-vmiddle mod-justify-center">
                <span class="new-module-box-plus-inner-circle flex-vmiddle mod-justify-center">
                    <span class="new-module-box-plus-h-line"></span>
                    <span class="new-module-box-plus-v-line"></span>
                </span>
            </span>
        </span>
    </div>

    <div
        class="new-module-box top flex flex-col mod-justify-center mod-align-center"
        ng-class="{
            'box-opacity-1': data.isHovered,
            'box-opacity-0': !data.isHovered
        }"
    >
        <button
            ng-class="{
                'common-disabled':
                    !pm.isOwner && !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
            }"
            ng-click="createNewWorkerInWorkflowFolder(data.folderId, $event)"
            uib-tooltip="Only Makers\Board Owners can add Modules to a Solution"
            tooltip-enable="{{
                !pm.isOwner && !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
            }}"
            tooltip-append-to-body="true"
            data-automation="worker-browser-create-new-bot"
            class="tnk-hollow-button common-size-xs margin-bottom-xs"
        >
            Create New
        </button>

        <div class="common-size-xxxs common-color-border-light-5">OR</div>

        <button
            ng-class="{
                'common-disabled':
                    !pm.isOwner && !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
            }"
            ng-click="createNewWorkerFromTemplate(data.folderId, $event)"
            uib-tooltip="Only Makers\Board Owners can add Modules to a Solution"
            tooltip-enable="{{
                !pm.isOwner && !wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.folderId].isUserOwner
            }}"
            tooltip-append-to-body="true"
            class="tnk-hollow-button common-size-xs margin-top-xs"
        >
            Use Template
        </button>
    </div>
</div>
