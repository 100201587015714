import { useAngularService } from 'angulareact';
import React from 'react';
import type { GroupBase } from 'react-select';

import type { AutocompleteSelectorProps } from './AutocompleteSelector';
import AutocompleteSelector from './AutocompleteSelector';
import { Option, SingleValue } from './components/PersonSelectComponents';
import type { SimpleSelectSingleOption } from './SimpleSelectTypes';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { GetTeamMemberOptions, Person } from '@tonkean/tonkean-entities';

interface PeopleAutocompleteSpecificProps<IsMulti extends boolean> {
    onlyCreatorsInGroupId?: string;
    onlyOwnersInGroupId?: string;
    isMulti?: IsMulti;
    showAvatar?: boolean;
    includeExternal?: boolean;
}

type Props<IsMulti extends boolean = false> = Omit<
    Omit<
        AutocompleteSelectorProps<
            Person,
            IsMulti,
            false,
            'name',
            'id',
            GroupBase<SimpleSelectSingleOption<Person['name']>>
        >,
        'labelKey' | 'valueKey' | 'onDebouncedSearchGetResults'
    >,
    keyof PeopleAutocompleteSpecificProps<IsMulti>
> &
    PeopleAutocompleteSpecificProps<IsMulti>;

const PeopleAutocompleteSelect = <IsMulti extends boolean = false>({
    onlyCreatorsInGroupId,
    onlyOwnersInGroupId,
    showAvatar,
    includeExternal,
    ...props
}: Props<IsMulti>): React.ReactElement => {
    const projectManager = useAngularService('projectManager');
    const [, getTeamMembers] = useLazyTonkeanService('getTeamMembers');

    const getPeopleAutocomplete = (debounced: string, excludePersonIds: string[]) => {
        const options: GetTeamMemberOptions = {
            excludePersonIds,
            onlyCreatorsInGroupId,
            onlyOwnersInGroupId,
            includeExternal,
        };
        return getTeamMembers(projectManager.project.id, options, debounced, 20).then((data) => data.people);
    };

    const components = showAvatar
        ? {
              Option,
              SingleValue,
          }
        : {};

    return (
        <AutocompleteSelector
            onDebouncedSearchGetResults={getPeopleAutocomplete}
            valueKey="id"
            labelKey="name"
            components={components}
            {...(props as any)}
        />
    );
};

export type PeopleAutocompleteSelectComponentType = <IsMulti extends boolean = false>(
    props: Props<IsMulti>,
) => React.ReactElement;

export default PeopleAutocompleteSelect;
