import template from './tnkBotWorkerComponent.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotWorkerComponent', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        onComponentClosed: '&',
    },
    template,
    controller: 'BotWorkerComponentCtrl',
});
