import template from './enterpriseManageBoards.template.html?angularjs';

/**
 * Component to enterprise Manage Members.
 */
export default angular.module('tonkean.app').component('tnkEnterpriseManageBoards', {
    bindings: {},
    template,
    controller: 'EnterpriseManageBoardsCtrl',
});
