import template from './fieldSelector.template.html?angularjs';

/**
 * Component to select fields of integrations.
 */
angular.module('tonkean.app').component('tnkFieldSelector', {
    bindings: {
        itemsSource: '@', // What items we should load (For example, EXTERNAL or COLUMN).
        addCustomFields: '&', // Function that returns any custom fields we should add to the field options.
        groupId: '@', // What group are we in? This is only relevant for COLUMN items source.
        workflowVersionId: '@', // What workflow version are we in? This is only relevant for COLUMN items source.
        considerAllGroups: '@', // If true, will consider all groups of the project as data source for fields and not just the given group at groupId parameter.
        groupBy: '@', // A string used to group by the fields in the ui-selector.

        selectedField: '<', // The selected field reference.
        selectedFieldIdentifier: '<', // The selected field identifying object (i.e the parameter name in the caller controller). It can also be used as passing an object param to the directive.

        projectIntegration: '<', // The project integration to load external fields from.
        externalType: '<', // The entity we're fetching fields for.

        additionalProjectIntegrationExternalTypesMap: '<', // Map between a project integration id to external types to also include on suggested fields.

        onFieldSelected: '&', // Fires whenever the current field selection changes.
        onFieldOptionsLoaded: '&', // Function to be called once loading field options is done.
        reloadFields: '<', // A flag passed from parents that tells the component it should update its field options.

        allowCustomInput: '<', // If true, allows the user to type in custom values as fields.
        customInputType: '<', // Can provide a field type (Number, etc..) to determine the type of custom inputs
        allowClearSelection: '<', // If true, allows the user to clear the current selection by adding a "clear selection" item to the options list.
        autoMatchOnOptions: '<', // When set to true, whenever field options are loaded and one option's label matches the current label - the option will be auto selected.
        clearSelectedIfNoMatchOnOptions: '<', // When set to true, whenever field options are loaded and we have current label, but no option's label matches the current label - the current will be cleared.
        placeHolder: '@', // A placeholder string for the ui-select.
        isRequired: '<', // Whether the field selector is required in the form it is in. Default is false.
        tooltipValue: '<', // Optional : If exists tooltip will appear

        fieldInspectPopover: '<', // If true, a field inspect search icon will appear allowing the user to open and use that feature via POPOVER.
        fieldInspectModal: '<', // If true, a field inspect search icon will appear allowing the user to open and use that feature via MODAL.
        fieldInspectAutoOpen: '<', // If true and either fieldInspectPopover or fieldInspectModal are true, a click on the selector will auto open the field inspect.

        inspectEntityType: '<', // optional : if exist set the inspect to use this entity.

        onlyIdRelationField: '<', // optional: If set to true, will only display matched entities.
        viewOnly: '<', // Indicates whether we are in a view only mode and we cannot edit a field.

        onlyColumnFieldDefinitions: '<', // If true, and COLUMNS item source is chosen, will only return field definitions and not special fields as options.
        onlyUpdatableFieldDefinitions: '<', // If true, and COLUMNS item source is chosen, will only return field definitions that are updatable.
        doNotIncludeFieldIdsSet: '<', // If given, will not include fields whose ids are in the doNotIncludeFieldIdsSet collection.

        specialFieldsForFeatures: '<', // Only relevant to COLUMN items source. Only special fields that are in this feature list will be included.
        excludedTabSelectorSpecialFields: '<', // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
        includeTabSelectorSpecialFieldsForFeatures: '<', // Use 'considerInTypes' to add fields under this category to the special fields tab selector
        displayIntegrationIndication: '<', // Indicates whether to display integration indication in the selection options.

        autoFillFieldName: '<',
        reloadFieldsOnNewFieldDefinitionCreation: '<', // If true, will reload field options if a new field definition creation event is raised.

        includeGlobalFields: '<', // Should we include global fields when we are in the COLUMNS source.
        doNotIncludeExternalFields: '<', // Whether to include external fields

        doNotDisplaySelectedField: '@', // If true, will not display the selected field.
        buttonMode: '<', // When true, the field selector will appear as a button with a dropdown and not as a text input.
        listMode: '<', // When true, the field selector displays its options as a list (no ui-select or buttons, just a simple list to select from).

        autoCreateColumnFields: '@', // If true, will auto create selected fields from integration.
        addFieldsQuickCreateOptions: '<', // If true, will add options for quick creation of fields.
        quickCreateMatchedEntity: '<', // If true, the auto create field option will prompt you to auto create matched entity.
        doNotDisplaySpecialFields: '<', // If true, special fields will not be an option.
        doNotDisplayExistingFields: '<', // If true, fields will not shown and only the fields quick creation will be available.
        fieldInspectFilter: '<', // If exists, has to be a filter method to filter out fields from being displayed in the field inspect

        appendToBody: '<',
        fieldsFilter: '<', // If exists, has to be a filter method to filter out fields in the component
        sortBy: '&', // If exists, sorts the fields by the given function

        dontClosePreviousTabOnFieldCreation: '<', // If true, will get back to previous tab after field created

        /**
         * If true will allow user to select viewer fields like Viewer Email. Note that this does not promise server support when doing a query.
         */
        includeViewerFields: '<',
    },
    template,
    controller: 'FieldSelectorCtrl',
});
