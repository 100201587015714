<div class="ranges-configuration">
    <!-- Ranges -->
    <div ng-repeat="range in data.ranges" class="flex-vmiddle margin-top flex-xs-wrap field-definition-range">
        <!-- Condition -->
        <tnk-condition-selector
            class="margin-right-xs"
            project-integration="data.projectIntegration"
            field-type="data.fieldType"
            value="range.value"
            second-value="range.secondValue"
            possible-values="data.possibleValues"
            condition-api-name="range.condition"
            on-condition-selected="onConditionSelection(selectedCondition, param)"
            on-condition-selected-param="(range)"
            on-value-changed="onValueChanged(valueObject, param)"
            on-value-changed-param="(range)"
            on-second-value-changed="secondValueChanged(valueObject, param)"
        ></tnk-condition-selector>

        <div class="field-definition-modal-dropbox margin-right-xs" uib-dropdown>
            <button
                type="button"
                class="btn btn-default common-no-outline dropdown-toggle field-definition-range-color"
                uib-dropdown-toggle
                ng-disabled="posting"
            >
                <span class="flex-vmiddle">
                    <span class="flex-grow text-left common-ellipsis margin-right" ng-style="{ color: range.color }">
                        {{ range.selectedColor.label }}
                    </span>
                    <span class="dropdown-chevron flex-no-shrink"></span>
                </span>
            </button>
            <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
                <li ng-repeat="color in data.colors" ng-click="selectRangeColor(range, color, $parent.$index)">
                    <a ng-style="{ color: color.color }">{{ color.label }}</a>
                </li>
            </ul>
        </div>
        <button
            type="button"
            class="btn btn-xs btn-no-border common-no-outline flex-no-shrink"
            ng-click="deleteRange($index)"
            ng-disabled="posting"
            uib-tooltip="Delete rule"
            tooltip-placement="top"
        >
            <span class="svg-icon-xs common-color-grey">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>
        </button>
    </div>

    <hr ng-if="data.ranges && data.ranges.length" class="margin-top margin-bottom-none" />

    <div class="flex margin-top">
        <!-- Add alert/range button -->
        <div class="flex-grow margin-right">
            <button
                type="button"
                class="btn btn-default btn-sm common-color-grey"
                ng-click="addNewRange()"
                ng-disabled="posting"
            >
                <i class="fa fa-plus-circle margin-right-xs common-no-outline"></i>
                <strong>Add rule</strong>
            </button>
        </div>
        <!-- Emphasize colors checkbox -->
        <div
            class="pointer common-size-xxs margin-right-xs"
            ng-if="data.ranges && data.ranges.length"
            ng-click="onEmphasizeRange();"
        >
            <i
                class="margin-right-xs fa"
                ng-class="data.isImportant ? 'fa-check-square-o common-color-primary' : 'fa-square-o'"
            ></i>
            Emphasize colors
        </div>
    </div>
</div>
