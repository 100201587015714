/**
 * A function which checks whether a given string is a valid number.
 * @param str - The string to check
 */
function isNumeric(str: string): boolean {
    if (typeof str != 'string') return false;

    return (
        !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(Number.parseFloat(str))
    );
}

export default isNumeric;
