function DuplicateFieldDefinitionCtrl(
    $rootScope,
    $scope,
    $state,
    $uibModalInstance,
    projectManager,
    tonkeanService,
    fieldDefinition,
    group,
    includeCurrentGroup,
    manualValue,
) {
    $scope.data = {
        fieldDefinition, // The copied field definition.
        group, // The group the duplicate field definition was opened from.
        allAvailableGroups: [], // All the possible groups you can copy the field into.
        duplicateIntoGroup: null, // The group you would like the field to be copied into.
        manualValue,
    };

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        // data.allAvailableGroups will hold all groups apart from the group you opened the modal from.
        $scope.data.allAvailableGroups = projectManager.groups.filter(
            (filteredGroup) => includeCurrentGroup || filteredGroup.id !== group.id,
        );

        if ($scope.data.allAvailableGroups) {
            $scope.data.duplicateIntoGroup = includeCurrentGroup
                ? projectManager.groups.find((filteredGroup) => filteredGroup.id === group.id)
                : $scope.data.allAvailableGroups[0];
        }
    };

    /**
     * Closes the modal.
     */
    $scope.close = function () {
        $uibModalInstance.close();
    };

    $scope.copyFieldDefinitionToGroup = function () {
        tonkeanService
            .copyFieldDefinitionToGroup($scope.data.fieldDefinition.id, $scope.data.duplicateIntoGroup.id)
            .then((result) => {
                if (result.hasErrors) {
                    $rootScope.$emit('alert', {
                        msg: `An error occurred while trying to copy "${$scope.data.fieldDefinition.name}" to module "${$scope.data.duplicateIntoGroup.name}"`,
                        type: 'danger',
                    });
                } else {
                    // Refresh fields
                    if ($scope.data.group.id === $scope.data.duplicateIntoGroup.id) {
                        $rootScope.$broadcast('newFieldDefinitionCreated', { field: null, shouldReload: true });
                    }

                    $rootScope.$emit('alert', {
                        msg: `Successfully copied "${$scope.data.fieldDefinition.name}" to module`,
                        type: 'success',
                        linkText: $scope.data.duplicateIntoGroup.name,
                        linkTarget: '_blank',
                        linkHref: $state.href(
                            'product.workerEditor',
                            { g: $scope.data.duplicateIntoGroup.id },
                            { absolute: true },
                        ),
                    });
                }
            })
            .then(() => $scope.close());
    };

    $scope.init();
}

angular.module('tonkean.app').controller('DuplicateFieldDefinitionCtrl', DuplicateFieldDefinitionCtrl);
