import { useCallback, useEffect, useState } from 'react';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

/**
 * Flags allows to run code on trigger after render. It's similar to useCallback, but instead of running immediately,
 * it re-renders and then runs, and it has no return value.
 *
 * The component will re-render when the flag activates and when it's switches off, so it will re-render twice -
 * one before the callback triggers and one after it.
 *
 * **This hook should be used carefully. It's not intended to act as a workaround for the deps array in useEffect.**
 *
 * @param callback - the function to trigger when the flag is activated.
 * @return a function to activate the flag.
 */
function useFlag(callback: () => void) {
    const callbackConstantRef = useConstantRefCallback(callback);

    const [flagActive, setFlagActive] = useState(false);
    useEffect(() => {
        if (!flagActive) {
            return;
        }

        callbackConstantRef();

        setFlagActive(false);
    }, [callbackConstantRef, flagActive]);

    const setFlag = useCallback(() => {
        setFlagActive(true);
    }, []);

    return setFlag;
}

export default useFlag;
