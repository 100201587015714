import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsInput from './FormSettingsInput';
import FormSettingsLabel from './FormSettingsLabel';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';

import { Tooltip, useUUID } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HeaderMaxLinesTitle = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    form: Form;
    onFormChanged: (headerMaxLines: number | undefined) => void;
}

const FormSettingsHeaderMaxLines: React.FC<Props> = ({ form, onFormChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    const id = useUUID();

    return (
        <>
            <FormSettingsLabel htmlFor={id}>
                <HeaderMaxLinesTitle>Table Header Max Lines</HeaderMaxLinesTitle>
                <FormSettingsSectionHeaderSubtitle>
                    How many lines can the header take? (If the value doesn't fit, it will be shown in a tooltip)
                </FormSettingsSectionHeaderSubtitle>
            </FormSettingsLabel>
            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <FormSettingsInput
                    data-automation="form-settings-header-max-lines"
                    id={id}
                    type="number"
                    placeholder="Header Max Lines"
                    value={form.definition.headerMaxLines || 1}
                    onChange={({ target: { valueAsNumber } }) => onFormChanged(valueAsNumber)}
                    disabled={!isEditable}
                    min={1}
                    halfWidth
                />
            </Tooltip>
        </>
    );
};

export default FormSettingsHeaderMaxLines;
