import type { FieldOption } from '../entities';

export const getAllCheckerData = <T extends string>(fieldsState: FieldOption<T>[]) => {
    const hasUnCheckedField = fieldsState.some((field) => !field.isSelected && !field.disabled);

    if (hasUnCheckedField) {
        return { label: 'check all', action: 'check-all' } as const;
    }

    const hasCheckedField = fieldsState.some((field) => field.isSelected && !field.disabled);

    if (hasCheckedField) {
        return { label: 'uncheck all', action: 'uncheck-all' } as const;
    }

    return undefined;
};
