import styled from 'styled-components';

import type { ItemInterfacePermission } from '../../../entities';

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const StyledImage = styled.img`
    width: 100%;
    object-fit: contain;
`;

export interface ImageWidgetDisplayBaseProps {
    isUrlExpressionValid: boolean;
    loadingExpressions: boolean;
    permission: ItemInterfacePermission;
    altText?: string;
    url: string | null | undefined;
    originalExpression?: string;
}
