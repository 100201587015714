import { useAngularService } from 'angulareact';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import DefaultTonkeanNavigationParamsContext from './DefaultTonkeanNavigationParamsContext';
import { NavigablePageType } from '../../entities/BaseNavigablePage';
import { isExternalNavigablePage } from '../../entities/ExternalNavigablePage';
import type NavigablePage from '../../entities/NavigablePage';

import {
    Menu,
    MenuItem,
    useCloseCallback,
    useFullDebouncedState,
    IconSvg,
    TextEllipsis,
} from '@tonkean/infrastructure';
import { Chevron2Icon } from '@tonkean/svg';
import { getTextColorByBackgroundColor } from '@tonkean/tui-basic/utils';
import { Theme, FontSize, type Color } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const NavigationMenuItemLabel = styled.span`
    flex-grow: 1;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
`;

const NavigationMenuItemIcon = styled.div<{ $hoverColor: string }>`
    margin-right: 12px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        ${({ $hoverColor }) => css`
            color: ${$hoverColor};
            ${colorSvg($hoverColor)}
        `}
    }
`;

const ItemExpandChevron = styled(IconSvg)`
    flex-shrink: 0;
`;

const NavigationMenuItemWrapper = styled(MenuItem)<{
    $isExternalLink: boolean;
    $hoverBackground: Color;
    $hoverColor: Color;
}>`
    padding: 12px;
    border-radius: 6px;

    ${NavigationMenuItemIcon} {
        color: ${Theme.colors.gray_800};
        ${colorSvg(Theme.colors.gray_800)}
    }

    &:hover,
    &:focus {
        background-color: ${({ $hoverBackground }) => $hoverBackground};

        ${({ $hoverColor }) => css`
            ${NavigationMenuItemLabel}, ${NavigationMenuItemIcon}, ${ItemExpandChevron} {
                color: ${$hoverColor};
                ${colorSvg($hoverColor)}
            }
        `}
    }
`;

const NavigationMenuItemContent = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const StyledMenu = styled(Menu)`
    width: 268px;
    padding: 12px 16px;
    gap: 8px;
`;

const MenuItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const MenuTitle = styled.div`
    margin-left: 10px;
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`;

interface Props {
    page: NavigablePage;
    currentPageId: NavigablePage['id'] | undefined;
    themeColor: Color;
}

const NavigationMenuItem: React.FC<Props> = ({ page, currentPageId, themeColor }) => {
    const isExternalLink = isExternalNavigablePage(page);

    const defaultUrlParams = useContext(DefaultTonkeanNavigationParamsContext);

    const params = {
        ...defaultUrlParams,
        ...page.params,
    };

    const $rootScope = useAngularService('$rootScope');
    const features = $rootScope.features.currentProject;

    const [innerMenuOpen, setInnerMenuOpen] = useFullDebouncedState<boolean>(false, 50);
    const innerPages =
        page.type === NavigablePageType.INTERNAL
            ? page.innerPages
                  ?.filter((page) => page.isShownInNavigationMenu(features))
                  ?.map((innerPage) => {
                      return (
                          <NavigationMenuItem
                              key={innerPage.id}
                              page={innerPage}
                              currentPageId={currentPageId}
                              themeColor={themeColor}
                          />
                      );
                  })
            : undefined;

    const onClose = useCloseCallback();

    const hoverTextColor = getTextColorByBackgroundColor(themeColor);

    return (
        <StyledMenu
            menuItems={
                <MenuItemsWrapper
                    onMouseEnter={() => {
                        setInnerMenuOpen(true);
                    }}
                    onMouseLeave={() => {
                        setInnerMenuOpen(false);
                    }}
                >
                    <MenuTitle>
                        <TextEllipsis numberOfLines={1} tooltip>
                            {page.name}
                        </TextEllipsis>
                    </MenuTitle>
                    {innerPages}
                </MenuItemsWrapper>
            }
            onClose={() => {
                setInnerMenuOpen(false);
                // Close outer menu when theres a click inside the inner menu
                onClose();
            }}
            show={innerMenuOpen}
            placement="right-start"
            offset={25}
            initiallyFocusOnWrapper
        >
            <NavigationMenuItemWrapper
                $isExternalLink={isExternalLink}
                $hoverBackground={themeColor}
                $hoverColor={hoverTextColor}
                href={page.path}
                openInNewTab={page.openInNewPage}
                state={!page.path && page.state}
                params={!page.path && params}
                selected={currentPageId === page.id}
                onMouseEnter={() => {
                    if (innerPages?.length) {
                        setInnerMenuOpen(true);
                    }
                }}
                onMouseLeave={() => {
                    setInnerMenuOpen(false);
                }}
            >
                <NavigationMenuItemContent>
                    {page.menuIcon && (
                        <NavigationMenuItemIcon $hoverColor={hoverTextColor}>
                            <page.menuIcon />
                        </NavigationMenuItemIcon>
                    )}
                    <NavigationMenuItemLabel data-automation={`navigation-menu-${page.name}`}>
                        <TextEllipsis numberOfLines={1} tooltipPlacement="bottom" tooltip>
                            {page.name}
                        </TextEllipsis>
                    </NavigationMenuItemLabel>
                    {innerPages?.length ? <ItemExpandChevron as={Chevron2Icon} size={20} /> : undefined}
                </NavigationMenuItemContent>
            </NavigationMenuItemWrapper>
        </StyledMenu>
    );
};

export default NavigationMenuItem;
