function ImportTracksModalCtrl($scope, $uibModalInstance, importTracksSettings) {
    $scope.importTracksSettings = importTracksSettings;

    $scope.done = function (failedImportsCount) {
        $uibModalInstance.close();
        if ($scope.importTracksSettings && $scope.importTracksSettings.onCompleteCallback) {
            $scope.importTracksSettings.onCompleteCallback(failedImportsCount);
        }
    };
}

export default angular.module('tonkean.app').controller('ImportTracksModalCtrl', ImportTracksModalCtrl);
