import React from 'react';
import styled, { css } from 'styled-components';

import ActionsTestPageResponseView from './ProjectIntegrationActionsTestPageRequestView/ActionsTestPageResponseView';
import ProjectIntegrationActionTestResponseStatusCode from '../ProjectIntegrationActionTestResponseStatusCode';

import type { ProjectIntegrationActionTestRunHTTPResponse } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

export const SubHeader = styled.div<{ greyed?: boolean }>`
    font-weight: 700;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_800};

    margin-bottom: 24px;
    margin-top: 30px;

    ${({ greyed }) =>
        greyed &&
        css`
            color: ${Theme.colors.gray_500} !important;
        `}
`;

const InlineSubHeader = styled(SubHeader)`
    display: flex;
    gap: 10px;
    align-items: baseline;
`;

interface Props {
    isTestRunning: boolean;
    response: ProjectIntegrationActionTestRunHTTPResponse | undefined;
}

const TestPageHttpResponseView: React.FC<Props> = ({ isTestRunning, response }) => {
    return (
        <>
            <InlineSubHeader greyed={!response}>
                Response
                {response?.responseCode && (
                    <ProjectIntegrationActionTestResponseStatusCode statusCode={response.responseCode} />
                )}
            </InlineSubHeader>

            <ActionsTestPageResponseView isLoading={isTestRunning} response={response} />
        </>
    );
};

export default TestPageHttpResponseView;
