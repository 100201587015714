import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function GatherUpdateLogicConfigurationCtrl(
    $scope,
    authenticationService,
    tonkeanUtils,
    communicationIntegrationsService,
    projectManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.tonkeanUtils = tonkeanUtils;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,

        personSelectionConfiguration: null,
        existingPersonSelectionConfiguration: null,

        botMessageText: null,
        evaluatedBotMessageText: null,
        previewButtons: [{ text: 'Post an update' }, { text: 'Ask me later' }, { text: 'More info' }],
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    /**
     * Occurs once the person selection configuration is changed.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.personSelectionConfiguration = personSelectionConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.botMessageText = originalExpression;
        $scope.data.evaluatedBotMessageText = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the email subject expression has changed.
     */
    $scope.onEmailSubjectTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.emailSubject = originalExpression;
        $scope.data.evaluatedEmailSubject = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the definition of the action is changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                fieldDefinitionsToCreate: [],
                actions: [],
            };

            tonkeanUtils.enrichPersonActionDefinition(
                'GATHER_UPDATE',
                actionDefinition,
                $scope.data.personSelectionConfiguration,
                $scope.data.botMessageText,
                $scope.data.evaluatedBotMessageText,
            );

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'GATHER_UPDATE',
        );

        if (customTriggerAction) {
            $scope.data.botMessageText = customTriggerAction.customTriggerActionDefinition.text;
            $scope.data.evaluatedBotMessageText = customTriggerAction.customTriggerActionDefinition.evaluatedText;
            $scope.data.existingPersonSelectionConfiguration = customTriggerAction.customTriggerActionDefinition;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('GatherUpdateLogicConfigurationCtrl', lateConstructController(GatherUpdateLogicConfigurationCtrl));
