import { reactToAngular } from '@tonkean/angular-components';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';

angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationIcon',
        reactToAngular(ProjectIntegrationIcon, [
            'projectIntegrationId',
            'iconUrl',
            'integrationType',
            'className',
            'tooltipContent',
        ]),
    );
