import { isOpenFormAction } from './addFormAnsweredBlockAfterButtonCreateAfterOption';
import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import isOpenFormFromOtherGroup from '../canCreate/isOpenFormFromOtherGroup';

const afterOpenFormCantCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
    customTriggerManager,
    features,
) => {
    return (
        !isOpenFormAction(configuredLogic, features) &&
        !isOpenFormFromOtherGroup(configuredLogic, logicBlockConfigsFormInfoRetriever)
    );
};
const addNewLogicOptionNotAfterOpenForm: CreateAfterOption = {
    label: 'Add New Logic',
    canCreate: afterOpenFormCantCreate,
};
export default addNewLogicOptionNotAfterOpenForm;
