import React, { useContext } from 'react';

import type { SolutionBusinessReport, SolutionBusinessReportPermissionType } from '@tonkean/tonkean-entities';

export interface SolutionBusinessReportContextType {
    solutionBusinessReport: SolutionBusinessReport | undefined;
    currentUserAccess: Record<SolutionBusinessReportPermissionType, boolean>;
}

const SolutionBusinessReportContext = React.createContext<SolutionBusinessReportContextType>({
    solutionBusinessReport: undefined,
    currentUserAccess: {
        EDIT: false,
        VIEW: false,
    },
});

export function useSolutionBusinessReportContext() {
    return useContext(SolutionBusinessReportContext);
}

export default SolutionBusinessReportContext;
