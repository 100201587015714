import useBreakpoint from './useBreakpoint';
import { Breakpoint } from '../components/BreakpointManager';

/**
 * A hook that returns whether the current breakpoint is small screen width
 *
 * @returns the is small screen width flag
 */
export default function useIsSmallScreen(): boolean {
    const effectiveBreakpoint = useBreakpoint();
    return effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;
}
