import includes from 'lodash.includes';
import uniq from 'lodash.uniq';

import lateConstructController from '../../utils/lateConstructController';

import { getOperators } from '@tonkean/forumla-operators';
import { fieldsClassifier } from '@tonkean/infrastructure';
import { logicBlockTypes } from '@tonkean/logic-block-configs';
import type { FieldDefinition, ProjectIntegration } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { AutonomousSecondaryType } from '@tonkean/tonkean-entities';
import {
    convertMatchSelectionToServerObject,
    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP,
    getDisplayObjectForFieldDefinition,
    getMatchConfigurationAccordingToSourceIntegration,
    getSpecialFieldsForFeatures,
} from '@tonkean/tonkean-utils';
import { mergeObjectsById } from '@tonkean/utils';

/* @ngInject */
function TabsFieldSelectorCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $q,
    projectManager,
    tonkeanService,
    customFieldsManager,
    utils,
    modal,
    customTriggerManager,
    workflowVersionManager,
    syncConfigCacheManager,
    groupInfoManager,
    trackHelper,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.wvm = workflowVersionManager;

    function wrapWithTitle(fields: any[], title: string) {
        if (!fields.length) {
            return [];
        }

        return [{ isGroupTitle: true, title }, ...fields];
    }

    function handleCustomTriggerFields(customFields: any[]) {
        return wrapWithTitle(customFields, 'Custom Fields');
    }

    function handleFormulaFields(formulaFields: any[]) {
        return wrapWithTitle(formulaFields, 'Formula Fields');
    }

    function handleRootMatchedEntitiesFields(rootFields: any[]) {
        return wrapWithTitle(rootFields, `${rootFields?.[0]?.definition?.entityMetadata?.pluralLabel} (Root)`);
    }

    // Wrap fields with group title for each manual matched entity
    function handleManualMatchedEntities(existingFields, fieldIdToDefinition) {
        return Object.entries(existingFields).flatMap(
            ([idRelationFieldDefinitionId, fieldsByIdRelation]: [string, any]) => {
                return wrapWithTitle(fieldsByIdRelation, fieldIdToDefinition[idRelationFieldDefinitionId]?.name);
            },
        );
    }

    function handleMatchedEntitiesFromCustomTrigger(matchedEntitiesFromCustomTrigger, customTriggersMap) {
        return Object.entries(matchedEntitiesFromCustomTrigger).flatMap(
            ([idRelationFieldDefinitionId, fieldsByIdRelation]: [string, any[]]) => {
                const customTrigger = customTriggersMap[idRelationFieldDefinitionId];
                if (customTrigger) {
                    fieldsByIdRelation = fieldsByIdRelation.map((field) => ({
                        ...field,
                        source: customTrigger.integrationType,
                        projectIntegrationId: field.projectIntegration?.id,
                        iconModClass: field.definition?.matchConfiguration?.isForMatchingItem ? 'mod-match' : undefined,
                    }));
                    return wrapWithTitle(fieldsByIdRelation, customTrigger.displayName);
                }

                return [];
            },
        );
    }

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        logicId: ctrl.logicId,
        currentProjectIntegrationId: $stateParams.projectIntegrationId,
        tooltip: ctrl.tooltip,
        popoverPlacement: ctrl.popoverPlacement,
        showPeopleDirectories: ctrl.showPeopleDirectories,
        peopleDirectories: ctrl.peopleDirectories,
        buttonClassOverride: ctrl.buttonClassOverride,
        shouldHaveEntityPrefix: false,

        projectIntegration: syncConfigCacheManager.getSyncConfig(ctrl.workflowVersionId)
            ? syncConfigCacheManager.getSyncConfig(ctrl.workflowVersionId).projectIntegration
            : null,
        inspectEntityType: null,
        inspectFieldPrefix: null,
        isPopoverOpen: false,

        displayedFieldList: [], // Holds all of the displayed fields.
        tabs: [], // Holds all of the displayed tabs.
        tabFieldsCache: {}, // Cache the fields per tab

        globalTabsOnly: ctrl.globalTabsOnly,

        selectedTab: null,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        tabsFieldSelectorModFixedWidth: ctrl.tabsFieldSelectorModFixedWidth,

        automationIdentifierExpressionAddField: ctrl.automationIdentifierExpressionAddField,
        defaultTabId: ctrl.defaultTabId,
        activeTabIndex: null,
        specialFieldsValuesForFeatures: ctrl.specialFieldsValuesForFeatures,
        excludedSpecialFields: ctrl.excludedSpecialFields,
        fieldsToFilter: ctrl.fieldsToFilter || [],

        // Configuration for each default tab
        defaultTabs: [
            {
                id: 'CURRENT_FIELDS',
                label: 'Current Fields',
                iconClass: 'mod-columns',
                searchPlaceholder: 'Search for a field...',
                getFields: async () => {
                    let specialFields = getSpecialFieldsForFeatures(false, ['FIELDS_TAB_SELECTOR']);
                    if (!$scope.data.workflowVersionId) {
                        specialFields = specialFields.filter((field) => field.notInitiativeRelated);
                    }

                    const basicFieldsTitle = { isGroupTitle: true, title: 'Basic fields' };

                    if (ctrl.includeTabSelectorSpecialFieldsForFeatures) {
                        const specialFieldsForFeatures = getSpecialFieldsForFeatures(
                            false,
                            ctrl.includeTabSelectorSpecialFieldsForFeatures,
                        );
                        specialFields = uniq([...specialFields, ...specialFieldsForFeatures]);
                    }

                    if (ctrl.excludedTabSelectorSpecialFields) {
                        specialFields = specialFields.filter(
                            (field) => !ctrl.excludedTabSelectorSpecialFields.includes(field.id),
                        );
                    }

                    if (ctrl.globalTabsOnly) {
                        const notInitiativeRelatedFields = specialFields.filter((field) => field.notInitiativeRelated);
                        notInitiativeRelatedFields.unshift(basicFieldsTitle);

                        return $q.resolve(notInitiativeRelatedFields);
                    }

                    const result: any[] = [];
                    // Make sure the field definitions are loaded
                    if ($scope.data.workflowVersionId) {
                        await customFieldsManager.getFieldDefinitions($scope.data.workflowVersionId);

                        const allExistingFields = utils
                            .objToArray($scope.cfm.selectedColumnFieldsMap[$scope.data.workflowVersionId])
                            ?.map((kvp) => kvp.value)
                            .filter((f) => !f.systemUtilized);

                        // build fields view contains of custom, matched entities and special fields
                        const fieldByEntityMapping = fieldsClassifier(
                            $scope.data.workflowVersionId,
                            customTriggerManager,
                            allExistingFields,
                            syncConfigCacheManager,
                        );

                        const fieldIdToDefinition = utils.createMapFromArray(allExistingFields, (field) => {
                            if (field.idRelationField) {
                                return field.id;
                            }
                        });

                        // We first make sure we have our cache loaded, then take the cached custom triggers map.
                        await customTriggerManager.getWorkflowVersionCustomTriggersAndGraph(
                            $scope.data.workflowVersionId,
                        );

                        const customTriggerIdToCustomTrigger =
                            await customTriggerManager.getCachedCustomTriggerMapByWorkflowVersionId(
                                $scope.data.workflowVersionId,
                            );
                        result.push(
                            ...handleCustomTriggerFields(fieldByEntityMapping.customFields),
                            ...handleFormulaFields(fieldByEntityMapping.formulaFields),
                            ...handleRootMatchedEntitiesFields(fieldByEntityMapping.rootItemFields),
                            ...handleMatchedEntitiesFromCustomTrigger(
                                fieldByEntityMapping.fieldFromCustomTriggers,
                                customTriggerIdToCustomTrigger,
                            ),
                            ...handleManualMatchedEntities(
                                fieldByEntityMapping.idRelationFieldsDefinitionIdToFields,
                                fieldIdToDefinition,
                            ),
                        );
                    }

                    result.push(basicFieldsTitle, ...specialFields);
                    return $q.resolve(result);
                },
            },
            {
                id: 'GLOBAL_FIELDS',
                label: 'Global Fields',
                iconClass: 'mod-bars',
                searchPlaceholder: 'Search for a global field...',
                getFields: () => {
                    return $scope.getGlobalFields();
                },
            },
            {
                id: 'FROM_INTEGRATION_FIELDS',
                label: 'Matched Entities',
                iconClass: 'mod-plug',
                searchPlaceholder: 'Search for a matched entity field...',
                quickCreationField: true,
                getFields: () => {
                    if (!$scope.data.workflowVersionId) {
                        return $q.resolve([]);
                    }
                    return customTriggerManager
                        .getWorkflowVersionCustomTriggersAndGraph($scope.data.workflowVersionId)
                        .then(() => {
                            const matchedEntitiesFields = customFieldsManager.selectedColumnFieldsMap[
                                $scope.data.workflowVersionId
                            ]
                                .filter((field) => field.idRelationField)
                                .filter((field) => !field.definition?.matchConfiguration?.isForMatchingItem)
                                // We do the .map to not enrich the cache but a new object
                                .map((v) => ({ ...v }));

                            const projectIntegration = syncConfigCacheManager.getSyncConfig(
                                $scope.data.workflowVersionId,
                            )?.projectIntegration;

                            const rootField = projectIntegration ? getRootField(projectIntegration) : undefined;

                            // Fill the fields definition with integration type (to show the icon on the ui)
                            matchedEntitiesFields.forEach((field) => {
                                field.quickCreationField = true;

                                if (field.projectIntegration?.id) {
                                    field.projectIntegrationId = field.projectIntegration.id;
                                }

                                if (field.definition?.fieldMetadata) {
                                    field.entity = field.definition.fieldMetadata.entity;
                                }

                                if (field.definition?.integrationType) {
                                    field.source = field.definition.integrationType.toLowerCase();
                                }

                                if (field.linkedCustomTrigger?.id) {
                                    const currentCustomTrigger = customTriggerManager.getCachedCustomTrigger(
                                        $scope.data.workflowVersionId,
                                        field.linkedCustomTrigger.id,
                                    );

                                    if (!field.definition) {
                                        field.definition = {};
                                    }

                                    field.entity =
                                        currentCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition
                                            ?.performOnEntity?.entity ||
                                        currentCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition
                                            ?.performOnEntity?.displayName;
                                    field.definition.integrationType = currentCustomTrigger?.integrationType;
                                    field.projectIntegrationId = currentCustomTrigger?.projectIntegrationIds?.[0];
                                }
                            });

                            // Addition of option to create new matched entity field
                            const newMatchedEntityField = getSpecialFieldsForFeatures(false, [
                                'OTHER_MATCHED_ENTITY_ONLY',
                            ]);

                            const fields = rootField
                                ? [...newMatchedEntityField, rootField, ...matchedEntitiesFields]
                                : [...newMatchedEntityField, ...matchedEntitiesFields];

                            return $q.resolve(fields);
                        });
                },
            },
            {
                id: 'MATCHED_ITEM_FIELDS',
                label: 'Matched Items',
                iconClass: 'mod-union-gray',
                searchPlaceholder: 'Search for a matched item field...',
                quickCreationField: true,
                getFields: () => {
                    if (!$scope.data.workflowVersionId) {
                        return $q.resolve([]);
                    }
                    const matchedItemsFields = customFieldsManager.selectedColumnFieldsMap[
                        $scope.data.workflowVersionId
                    ]
                        .filter((field) => field.definition?.matchConfiguration?.isForMatchingItem)
                        // We do the .map to not enrich the cache but a new object
                        .map((v) => ({ ...v }));

                    // Fill the fields definition with integration type (to show the icon on the ui)
                    matchedItemsFields.map((field) => {
                        field.quickCreationField = true;
                        // Set the icon for matched item fields
                        field.iconModClass = 'mod-match';

                        if (field.projectIntegration?.id) {
                            field.projectIntegrationId = field.projectIntegration.id;
                        }

                        if (field.definition?.fieldMetadata) {
                            field.entity = field.definition.fieldMetadata.entity;
                        }

                        if (field.definition?.integrationType) {
                            field.source = field.definition.integrationType.toLowerCase();
                        }

                        if (field.linkedCustomTrigger?.id) {
                            const currentCustomTrigger = customTriggerManager.getCachedCustomTrigger(
                                $scope.data.workflowVersionId,
                                field.linkedCustomTrigger.id,
                            );

                            if (!field.definition) {
                                field.definition = {};
                            }

                            if (currentCustomTrigger.customTriggerType === 'TONKEAN_ACTION') {
                                return groupInfoManager
                                    .getGroupNameAndProjectIntegrationId(
                                        currentCustomTrigger.customTriggerActions[0].customTriggerActionDefinition
                                            .actionDefinition.groupId,
                                        true,
                                    )
                                    .then((group) => {
                                        field.projectIntegrationId = group.projectIntegrationIdForGroupItems;
                                        field.name = `Module ${group.name}`;
                                        return tonkeanService
                                            .getProjectIntegrationEntitySummaries(
                                                group.projectIntegrationIdForGroupItems,
                                            )
                                            .then((entitiesResponse) => {
                                                // There is only one project integration entity for the group project integration - the default one
                                                field.entity = entitiesResponse.entities[0].displayName;
                                                field.definition.integrationType =
                                                    currentCustomTrigger?.integrationType;
                                                field.definition.matchConfiguration.isForMatchingItem = true;

                                                return field;
                                            });
                                    });
                            }

                            field.entity =
                                currentCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition
                                    ?.performOnEntity?.entity ||
                                currentCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition
                                    ?.performOnEntity?.displayName;
                            field.definition.integrationType = currentCustomTrigger?.integrationType;
                            field.projectIntegrationId = currentCustomTrigger?.projectIntegrationIds?.[0];

                            return field;
                        }
                    });

                    return $q.resolve(matchedItemsFields);
                },
            },
            {
                id: 'FLOW_ITEMS_FIELDS',
                label: 'Flow Items Fields',
                iconClass: 'mod-flow',
                searchPlaceholder: 'Search for a flow item field...',
                shouldShowInFormulasChoices: true,
                getFields: () => {
                    const relevantSpecialFields: FieldDefinition[] = [];

                    if ($scope.data.logicId) {
                        const currentLogic = customTriggerManager.getCachedCustomTrigger(
                            $scope.data.workflowVersionId,
                            $scope.data.logicId,
                        );
                        const isButtonLogic =
                            currentLogic?.customTriggerType && currentLogic.customTriggerType === 'BOT_BUTTON_PRESSED';
                        const isFormAnsweredLogic =
                            currentLogic?.customTriggerType && currentLogic.customTriggerType === 'SEND_FORM_ANSWERED';

                        const anyButtonParent = customTriggerManager.anyParentOfTypesWorkflowVersion(
                            $scope.data.workflowVersionId,
                            $scope.data.logicId,
                            [logicBlockTypes.BOT_BUTTON_PRESSED.type],
                        );
                        const anyFormAnsweredParent = customTriggerManager.anyParentOfTypesWorkflowVersion(
                            $scope.data.workflowVersionId,
                            $scope.data.logicId,
                            [logicBlockTypes.SEND_FORM_ANSWERED.type],
                        );
                        const anyThreadReplyParent = customTriggerManager.anyParentOfTypesWorkflowVersion(
                            $scope.data.workflowVersionId,
                            $scope.data.logicId,
                            [logicBlockTypes.BOT_THREAD_REPLY.type],
                        );
                        const parents = customTriggerManager.getWorkflowVersionLogicParents(
                            $scope.data.workflowVersionId,
                            $scope.data.logicId,
                        );
                        const anyMonitorFormsAutonomousParent = !!parents.filter(
                            (parent) =>
                                parent.node.customTriggerType === 'AUTONOMOUS' && parent.node.monitorForms?.length,
                        ).length;
                        const anyButtonClickedAutonomousParent = !!parents.filter(
                            (parent) =>
                                parent.node.customTriggerType === 'AUTONOMOUS' &&
                                parent.node.customTriggerSecondaryType ===
                                    AutonomousSecondaryType.USER_INTERFACE_BUTTON_CLICK,
                        ).length;

                        if (
                            anyMonitorFormsAutonomousParent ||
                            anyButtonParent ||
                            anyFormAnsweredParent ||
                            anyThreadReplyParent ||
                            anyButtonClickedAutonomousParent ||
                            isButtonLogic ||
                            isFormAnsweredLogic
                        ) {
                            relevantSpecialFields.push(
                                FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP['TNK_PREVIOUS_ACTOR_NAME'] as any,
                                FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP['TNK_PREVIOUS_ACTOR_EMAIL'] as any,
                            );
                        }
                        if (anyThreadReplyParent) {
                            relevantSpecialFields.push(
                                FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP['TNK_PREVIOUS_REPLY_TEXT'] as any,
                                FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP['TNK_PREVIOUS_EMAIL_ATTACHMENT_IDS'] as any,
                            );
                        }
                    }

                    return $q.resolve(relevantSpecialFields);
                },
            },
            // If showFormulasTab is not false (undefined considered true), append to the array the formulas
            // tab. Otherwise, append an empty array (meaning, leave the array as it is - without the formulas)
            ctrl.showFormulasTab !== false && {
                id: 'FORMULA_FIELDS',
                label: 'Formula Fields',
                iconClass: 'mod-calculator',
                searchPlaceholder: 'Search for a formula...',
                getFields: () => {
                    let customTrigger = undefined;
                    let projectIntegration: ProjectIntegration | undefined;

                    if (ctrl.logicId) {
                        customTrigger = customTriggerManager.getCachedCustomTrigger(
                            ctrl.workflowVersionId,
                            ctrl.logicId,
                        );
                    }

                    if ($scope.data.currentProjectIntegrationId) {
                        projectIntegration = projectManager.getProjectIntegrationById(
                            $scope.data.currentProjectIntegrationId,
                        );
                    }
                    return $q.resolve(
                        getOperators(
                            { withInnerTrackAggregationOperand: true, customTrigger, projectIntegration },
                            !!customTrigger,
                        ).map((operator) => {
                            const isInlineFormula = operator.key !== OperatorKey.INNER_TRACK_AGGREGATION;
                            return {
                                label: operator.displayName,
                                fieldType: 'FORMULA',
                                operator,
                                // Can only be created in a variable, not in an expression
                                isFormula: !isInlineFormula,
                                // Can be converted to a string formula
                                isInlineFormula,
                            };
                        }),
                    );
                },
            },
            ctrl.showPeopleDirectories === true && {
                id: 'PEOPLE_DIRECTORIES',
                label: 'Business Groups',
                iconClass: 'mod-people-directory',
                searchPlaceholder: 'Search business group',
                shouldShowInFormulasChoices: true,
                emptyStateMessage:
                    'No results. Please make sure you have given solution access to the business groups you wish to use.',
                getFields: () => {
                    return $q.resolve(
                        ctrl.peopleDirectories?.map((peopleDirectory) => {
                            return {
                                id: peopleDirectory.id,
                                label: peopleDirectory.displayName,
                                name: peopleDirectory.displayName,
                                fieldType: 'PEOPLE_DIRECTORY',
                                displayAs: 'String',
                                evaluatedDisplayType: 'String',
                            };
                        }),
                    );
                },
            },
        ].filter(Boolean),
        showNewField: true,
    };

    const functionCache = new Map();

    /**
     * Occurs on initialization of the component.
     */
    ctrl.$onInit = function () {
        $scope.data.defaultTabsMap = utils.createMapFromArray($scope.data.defaultTabs, 'id');

        addTabs(ctrl.additionalTabs);

        $scope.updateFieldsCache();

        $scope.selectTabByIndex(getDefaultTabIndex());
    };

    /**
     * Occurs on initialization of the component.
     */
    ctrl.$onChanges = function (changeObject) {
        if (changeObject.paramForTabs) {
            functionCache.clear();
        }

        if (changeObject.additionalTabs) {
            addTabs(changeObject.additionalTabs.currentValue);
            if (!changeObject.additionalTabs.isFirstChange()) {
                $scope.updateFieldsCache();
            }
        }

        if (changeObject.fieldsToFilter) {
            $scope.data.fieldsToFilter = changeObject.fieldsToFilter.currentValue;
        }

        if (changeObject.automationIdentifierExpressionAddField) {
            $scope.data.automationIdentifierExpressionAddField =
                changeObject.automationIdentifierExpressionAddField.currentValue;
        }
    };

    $scope.getGlobalFields = () => {
        if ($scope.data.workflowVersionId) {
            return $q.resolve(customFieldsManager.selectedGlobalFieldsMap[$scope.data.workflowVersionId]);
        }
        if ($scope.data.globalTabsOnly && ctrl.workflowFolderId && ctrl.entityVersionType) {
            return customFieldsManager.getGlobalFieldDefinitionsByWorkflowFolderId(
                ctrl.workflowFolderId,
                ctrl.entityVersionType,
            );
        } else {
            return $q.resolve([]);
        }
    };

    $scope.$on('newFieldDefinitionCreated', () => $scope.updateFieldsCache(true));

    $scope.updateFieldsCache = function (ignoreCache = false) {
        for (let i = 0; i < $scope.data.tabs.length; i++) {
            const tab = $scope.data.tabs[i];
            tab.isLoading = true;
            // Get fields

            if (ignoreCache || !functionCache.has(tab.getFields)) {
                functionCache.set(tab.getFields, tab.getFields(ctrl.paramForTabs));
            }

            functionCache
                .get(tab.getFields)
                // Fill cache (so we wouldn't load each change)
                .then((fields) => ($scope.data.tabFieldsCache[tab.label] = fields))
                .finally(() => {
                    // If after loading the user is selected on this tab, reload the displayed tabs because the cache has changed
                    if ($scope.data.selectedTab && $scope.data.selectedTab.id === tab.id) {
                        $scope.selectTabByIndex(i);
                    }
                    tab.isLoading = false;
                });
        }
    };

    $scope.selectTabByIndex = function (index) {
        const tab = $scope.data.tabs[index];
        $scope.data.selectedTab = tab;
        $scope.data.displayedFieldList = $scope.data.tabFieldsCache[tab.label];
        $scope.data.activeTabIndex = index;
    };

    function getDefaultTabIndex() {
        const defaultTabIndex =
            $scope.data.defaultTabId && $scope.data.tabs.findIndex((tab) => tab.id === $scope.data.defaultTabId);
        if (defaultTabIndex > -1) {
            return defaultTabIndex;
        }

        const firstAvailableTab = $scope.data.tabs.findIndex(
            (tab) => $scope.data.tabFieldsCache[tab.label]?.length || tab.emptyStateMessage || tab.isLoading,
        );
        if (firstAvailableTab > -1) {
            return firstAvailableTab;
        }

        return 0;
    }

    $scope.selectField = function (field) {
        $scope.data.isPopoverOpen = false;

        if (field?.id === 'TNK_OTHER_MATCHED_ENTITY') {
            $rootScope.$broadcast('createNewField', [
                $scope.data.groupId,
                'COLUMN',
                null,
                null,
                true,
                false,
                null,
                $scope.onOtherFieldDefinitionCreated,
                null,
                null,
                true,
                false,
                null,
                true,
                null,
                true,
                null,
                true,
                $scope.data.workflowVersionId,
                [],
                false,
            ]);
        } else if (field?.id === 'TNK_OTHER') {
            if (ctrl.isFromHtmlEditor) {
                $scope.data.showNewField = false;
            }

            $rootScope.$emit('createNewField', [
                $scope.data.groupId,
                'COLUMN',
                null,
                null,
                true,
                false,
                null,
                $scope.onOtherFieldDefinitionCreated,
                null,
                null,
                false,
                'manual',
                null,
                true,
                null,
                null,
                null,
                null,
                $scope.data.workflowVersionId,
            ]);
        } else if (field?.quickCreationField && $scope.data.selectedTab.quickCreationField) {
            let projectIntegration = null;
            // Get project integration by projectIntegrationId.
            if (field.projectIntegrationId) {
                projectIntegration = utils.findFirst(
                    projectManager.project.integrations,
                    (projectIntegration) => projectIntegration.id === field.projectIntegrationId,
                );
            }

            $scope.data.inspectEntityType = field.entity;
            $scope.data.inspectFieldPrefix = field.performOnWorkerItem ? field.label : field.name;
            $scope.data.projectIntegration = projectIntegration;
            $scope.data.shouldHaveEntityPrefix = !!field.shouldHaveEntityPrefix;

            // If there is linkedCustomTrigger or it is root field the idRelation is undefined
            const idRelationFieldDefinitionId =
                field.linkedCustomTrigger || field.performOnWorkerItem ? undefined : field.id;

            $scope.openFieldInspectModal(
                idRelationFieldDefinitionId,
                field.linkedCustomTrigger?.id,
                field.performOnWorkerItem,
                field.definition?.matchConfiguration?.isForMatchingItem,
                field.entityDisplayName,
            );
        } else if (field?.isFormula) {
            // Leaving this option (although it's not currently used) in case we want to have formula fields in the future.
            $rootScope.$broadcast('createNewField', [
                $scope.data.groupId,
                'COLUMN',
                null,
                null,
                true,
                false,
                null,
                $scope.selectField,
                null,
                null,
                false,
                'formula',
                null,
                false,
                field.operator,
                null,
                null,
                null,
                $scope.data.workflowVersionId,
            ]);
        } else if (field?.isInlineFormula) {
            // Inline formulas are formulas generated as text, in the form of "<# formula #>" and used in expressions.
            modal
                .openInlineFormulaEditorModal(
                    $scope.data.groupId,
                    $scope.data.workflowVersionId,
                    field.operator,
                    null,
                    $scope.data.tabs.filter(
                        (singleTab) =>
                            singleTab.shouldShowInFormulasChoices &&
                            $scope.data.tabFieldsCache[singleTab.label]?.length > 0,
                    ),
                    $scope.data.logicId
                        ? customTriggerManager.getCachedCustomTrigger(
                              $scope.data.workflowVersionId,
                              $scope.data.logicId,
                          )
                        : undefined,
                    $scope.data.currentProjectIntegrationId
                        ? projectManager.getProjectIntegrationById($scope.data.currentProjectIntegrationId)
                        : undefined,
                )
                ?.result.then((inlineFormulaData) => {
                    const selectedField = {
                        isFormula: true,
                        formulaExpression: inlineFormulaData.formulaExpression,
                        readableFormulaExpression: inlineFormulaData.readableFormulaExpression,
                    };
                    ctrl.onFieldSelected({
                        selectedField,
                    });
                    ctrl.onFieldSelectedReact?.(selectedField, inlineFormulaData.expressionNode);
                });
        } else {
            ctrl.onFieldSelected({
                selectedField: field,
            });
            ctrl.onFieldSelectedReact?.(field);
        }
    };

    $scope.onOtherFieldDefinitionCreated = function (field) {
        if ($scope.data.showNewField) {
            field.label = field.name;
            $scope.selectField(field);
        }
    };

    /**
     * Gets the example entity to display in inspect modal.
     */
    async function getExampleEntity() {
        const exampleEntityId = customTriggerManager.getWorkflowExampleItemIdFromCache($scope.data.workflowVersionId);
        if (!exampleEntityId) {
            return null;
        }

        const initiative = await trackHelper.getInitiativeById(exampleEntityId);
        if (!initiative) {
            return null;
        }

        // Fetch the external activity of the initiative if exist and if the entity type is equal to the inspect field modal entity type.
        if (
            $scope.data.projectIntegration &&
            initiative.externalId &&
            initiative.linkedProjectIntegrationId &&
            initiative.linkedProjectIntegrationId.id === $scope.data.projectIntegration.id &&
            initiative.externalType === $scope.data.inspectEntityType
        ) {
            return tonkeanService.getExternalActivityById(
                $scope.pm.project.id,
                $scope.data.projectIntegration.id,
                initiative.externalId,
                true,
            );
        } else {
            return null;
        }
    }

    $scope.openFieldInspectModal = async function (
        idRelationFieldDefinitionId: number | undefined,
        creatingCustomTriggerId: number | undefined,
        performOnWorkerItem: boolean | undefined,
        isForMatchedItem: boolean | undefined,
        entityDisplayName?: string,
    ) {
        // Get the example entity to use in the inspect.
        const exampleEntityOptional = await getExampleEntity();

        // Open the modal
        modal.openFieldInspectModal(
            $scope.data.projectIntegration,
            $scope.data.inspectEntityType,
            null,
            $scope.onFieldSelectedInInspect,
            null,
            null,
            exampleEntityOptional,
            null,
            null,
            $scope.data.workflowVersionId,
            null,
            null,
            null,
            $scope.data.shouldHaveEntityPrefix,
            idRelationFieldDefinitionId,
            creatingCustomTriggerId,
            performOnWorkerItem,
            isForMatchedItem,
            entityDisplayName,
        );
    };

    $scope.onFieldSelectedInInspect = function (
        field,
        dontNotifyChange,
        idRelationFieldDefinitionId,
        creatingCustomTriggerId,
        performOnWorkerItem,
    ) {
        if (!utils.isNullOrEmpty(field) && !utils.isNullOrEmpty(field.name)) {
            let getSelectedFieldPromise = $q.resolve(field);
            if ($scope.data.workflowVersionId.length) {
                let sourceIntegration = { entity: undefined };
                if ($scope.data.inspectEntityType) {
                    sourceIntegration.entity = $scope.data.inspectEntityType;
                } else {
                    sourceIntegration =
                        $scope.data.groupId &&
                        projectManager.groupsMap[$scope.data.groupId] &&
                        syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId)
                            ? syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId).viewData
                            : null;
                }

                const matchConfiguration = getMatchConfigurationAccordingToSourceIntegration(
                    sourceIntegration,
                    $scope.data.inspectEntityType,
                    creatingCustomTriggerId,
                    performOnWorkerItem,
                    idRelationFieldDefinitionId,
                );

                const externalFieldDefinition = {
                    ExternalType: $scope.data.inspectEntityType,
                    FieldName: field.name,
                    FieldLabel: field.displayName,
                    matchConfiguration: matchConfiguration
                        ? convertMatchSelectionToServerObject(matchConfiguration)
                        : null,
                };

                // Only create field if not exist already.
                const existingFields = $scope.cfm.selectedFieldsMap[$scope.data.workflowVersionId].filter(
                    (selectedField) =>
                        selectedField.definition &&
                        selectedField.definition.FieldName === externalFieldDefinition.FieldName &&
                        selectedField.definition.FieldLabel === externalFieldDefinition.FieldLabel &&
                        selectedField.definition.ExternalType === externalFieldDefinition.ExternalType,
                );
                if (existingFields?.length) {
                    getSelectedFieldPromise = $q.resolve(existingFields[0]);
                } else {
                    let fieldName = field.displayName;
                    if (
                        $scope.data.inspectFieldPrefix &&
                        $scope.data.inspectEntityType &&
                        !fieldName.toLowerCase().includes($scope.data.inspectEntityType.toLowerCase())
                    ) {
                        fieldName = `${$scope.data.inspectFieldPrefix} - ${field.displayName}`;
                    }

                    getSelectedFieldPromise = customFieldsManager
                        .createFieldDefinition(
                            'COLUMN',
                            fieldName,
                            field.description,
                            'EXTERNAL',
                            null,
                            externalFieldDefinition,
                            $scope.data.projectIntegration.id,
                            $scope.data.groupId,
                            field.type,
                            null,
                            field.updateable,
                            null,
                            null,
                            null,
                            false,
                            false,
                            true,
                            false,
                        )
                        .then((createdFieldDefinition) => {
                            $scope.cfm.addToCache($scope.data.workflowVersionId, [createdFieldDefinition]);
                            $rootScope.$broadcast('newFieldDefinitionCreated', createdFieldDefinition);

                            return $q.resolve(
                                getDisplayObjectForFieldDefinition(
                                    createdFieldDefinition,
                                    projectManager.groupsMap[$scope.data.groupId].name,
                                    false,
                                ),
                            );
                        });
                }
            }

            getSelectedFieldPromise.then((selectedField) => {
                // Notify that a field was selected.
                if (!dontNotifyChange) {
                    $scope.selectField(selectedField);
                }
            });
        }
    };

    function getRootField(projectIntegration) {
        const syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);

        const entity =
            syncConfig.viewData?.integrationType === 'WEBHOOK'
                ? syncConfig.viewData.entityMetadata?.label ||
                  syncConfig.viewData.entityMetadata?.entity ||
                  syncConfig.viewData.entity
                : syncConfig.viewData?.entityMetadata?.pluralLabel ||
                  syncConfig.viewData?.['entity'] ||
                  syncConfig.viewData?.['Entity'];

        // The entity id represent the "entity" field in the entity metadata.
        // In native entities its will be normal text and in custom entities its will be tonkean id
        const entityId =
            syncConfig.viewData?.integrationType === 'WEBHOOK'
                ? syncConfig.viewData.entityMetadata?.label ||
                  syncConfig.viewData.entityMetadata?.entity ||
                  syncConfig.viewData.entity
                : syncConfig.viewData?.entityMetadata?.entity ||
                  syncConfig.viewData?.['entity'] ||
                  syncConfig.viewData?.['Entity'];

        if (!entity) {
            console.error('Sync config object does not contain entity');
            return undefined;
        }

        return {
            id: `${projectIntegration.integration.integrationType}_${entity}_EXTERNAL_INTEGRATION`,
            label: `${entity} (Root)`,
            name: `${projectIntegration.integration.integrationType}_${entity}_EXTERNAL_INTEGRATION`,
            entity: entityId,
            shouldHaveEntityPrefix: false,
            quickCreationField: true,
            source: projectIntegration.integration.integrationType.toLowerCase(),
            performOnWorkerItem: true,
            updateable: true, // Adding flag so this fields will not be filtered out when filtering on only updateable field definitions.
            groupBy: 'Matched Entities',
            projectIntegrationId: projectIntegration.id,
        };
    }

    /**
     * Force focus on the input on click so the external htmlEditor wouldn't steal focus
     */
    $scope.forceFocus = function ($event) {
        $event.target.focus();
        $event.stopPropagation();
    };

    $scope.onPopoverOpen = () => {
        if (!$scope.data.isPopoverOpen) {
            $scope.data.activeTabIndex = getDefaultTabIndex();
            ctrl.onPopoverOpen?.();
        }
    };

    /**
     * Filters a field, by matching `data.search` to it's name, label or operator sign.
     *
     * @param field {object} - field to filter
     * @returns {boolean}
     */
    $scope.filterFieldList = (field) => {
        if (includes($scope.data.fieldsToFilter, field.name)) {
            return false;
        }

        if (!$scope.data.search?.trim()) {
            return true;
        }

        return $scope.filterFieldListBySearchTerm($scope.data.search, field);
    };

    /**
     * Memoized filter function that filters a field by a search term, by matching to it's name, label or operator sign.
     *
     * @param searchTerm {string} - the search term.
     * @param field {object} - the field to filter.
     * @returns {boolean}
     */
    $scope.filterFieldListBySearchTerm = (searchTerm, field) => {
        if (!searchTerm?.trim()) {
            return true;
        }

        const searchString = searchTerm.toLowerCase();
        let nameContainsSearch: boolean;
        let labelContainsSearch: boolean;
        let signContainsSearch: boolean;

        if (
            $rootScope?.features?.[projectManager.project.id]
                ?.tonkean_feature_remove_redundant_call_to_group_related_info_in_tonkean_expression
        ) {
            nameContainsSearch = field?.name !== undefined && includes(field.name.toLowerCase(), searchString);
            labelContainsSearch = field?.label !== undefined && includes(field.label.toLowerCase(), searchString);
            signContainsSearch =
                field?.operator?.sign !== undefined && includes(field.operator.sign.toLowerCase(), searchString);
        } else {
            nameContainsSearch = field?.name?.toLowerCase().includes(searchString);
            labelContainsSearch = field?.label?.toLowerCase().includes(searchString);
            signContainsSearch = field?.operator?.sign.toLowerCase().includes(searchString);
        }

        return nameContainsSearch || labelContainsSearch || signContainsSearch;
    };

    function addTabs(tabs: any[] = []) {
        let tabsToInit: any[] = [];

        if ($scope.data.globalTabsOnly) {
            if ($scope.data.defaultTabsMap?.['FORMULA_FIELDS']) {
                tabsToInit = [$scope.data.defaultTabsMap['FORMULA_FIELDS']];
            }
            if ($scope.data.defaultTabsMap?.['GLOBAL_FIELDS']) {
                tabsToInit = [...tabsToInit, $scope.data.defaultTabsMap['GLOBAL_FIELDS']];
            }
            if ($scope.data.defaultTabsMap?.['CURRENT_FIELDS']) {
                tabsToInit = [...tabsToInit, $scope.data.defaultTabsMap?.['CURRENT_FIELDS']];
            }
        } else {
            tabsToInit = $scope.data.defaultTabs;
        }

        $scope.data.tabs = mergeObjectsById(tabsToInit, tabs);
    }
}

angular.module('tonkean.app').controller('TabsFieldSelectorCtrl', lateConstructController(TabsFieldSelectorCtrl));
