<div class="create-initiative-modal">
    <div class="text-center" ng-show="loading">
        <i class="loading-large"></i>
        <h3>Moving...</h3>
    </div>
    <form name="moveGroupForm" ng-hide="loading" ng-submit="submit()" novalidate>
        <div class="common-title">
            <i class="fa fa-files-o"></i>
            Move this item to...
        </div>
        <hr />

        <div class="margin-normal-v">
            <p class="common-color-dark-grey common-size-xxs">Choose a List to move "{{ data.item.title }}" to:</p>
            <div class="flex-vmiddle margin-top relative">
                <ui-select
                    class="flex-grow"
                    theme="bootstrap"
                    ng-model="data.selectedGroup"
                    ng-disabled="loadingGroups"
                    required
                >
                    <ui-select-match placeholder="Choose a list">{{ $select.selected.name }}</ui-select-match>
                    <ui-select-choices repeat="group in (data.groups | filter: {name: $select.search})">
                        <i
                            class="fa margin-right-xs"
                            ng-class="group.visibilityType === groupType.public ? 'fa-globe' : 'fa-lock'"
                        ></i>
                        <span ng-bind-html="group.name | highlight: $select.search"></span>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <div class="clearfix flex-vmiddle">
            <div class="flex-grow">
                <div
                    class="common-tag-full common-color-danger flex-vmiddle common-size-xxxxs mod-light-grey"
                    ng-if="data.selectedGroup"
                >
                    <i
                        class="common-color-warning fa fa-{{
                            data.selectedGroup.members && data.selectedGroup.members.length ? 'lock' : 'eye'
                        }} margin-right-xs"
                    ></i>
                    <span class="common-color-warning">
                        This item will be visible to&nbsp;
                        <strong ng-if="!data.selectedGroup.members || !data.selectedGroup.members.length">
                            Everyone in this board
                        </strong>
                        <span ng-if="data.selectedGroup.members">
                            <strong
                                ng-if="data.selectedGroup.members.length < 2"
                                ng-repeat="m in data.selectedGroup.members"
                            >
                                {{ m.name }}{{ !$last ? ', ' : '' }}
                            </strong>
                            <strong
                                ng-if="data.selectedGroup.members.length > 1"
                                uib-tooltip="{{ utils.joinObjArray(data.selectedGroup.members, 'name', ', ') }}"
                                tooltip-placement="bottom"
                            >
                                {{ data.selectedGroup.members.length }} people
                            </strong>
                        </span>
                    </span>
                </div>
            </div>
            <div class="margin-top-lg">
                <button type="button" class="btn btn-lg btn-default mark-modal-btn" ng-click="$dismiss()">
                    Cancel
                </button>
                <button type="submit" class="btn btn-lg btn-primary mark-modal-btn" ng-disabled="!moveGroupForm.$valid">
                    Move
                </button>
            </div>
        </div>
        <div>
            <span class="text-danger" ng-if="error">{{ error | error }}</span>
        </div>
    </form>
</div>
