import type { IRootScopeService } from 'angular';
import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import BusinessReportButton from './components/BusinessReportButton';
import EnvironmentExplorer from './components/EnvironmentExplorer';
import ModuleInfoModal from './components/ModuleInfoModal';
import SecondaryNavbar from './components/SecondaryNavbar';
import SolutionOpsModulesDropdown from './components/SolutionOpsModulesDropdown';
import ModuleActions from './ModuleActions';
import { ReactComponent as CogIcon } from '../../../images/icons/cog-o.svg';
import { ReactComponent as InformationIcon } from '../../../images/icons/info20px.svg';
import { ReactComponent as ActivityIcon } from '../../../images/icons/navigation/activity.svg';
import { ReactComponent as BusinessReportIcon } from '../../../images/icons/navigation/business-reports.svg';
import { ReactComponent as ItemDefaultsIcon } from '../../../images/icons/navigation/item-defaults.svg';
import { ReactComponent as SettingsIcon } from '../../../images/icons/navigation/settings.svg';
import { ReactComponent as TestErrorIcon } from '../../../images/icons/navigation/test-error.svg';
import { ReactComponent as TestIcon } from '../../../images/icons/navigation/test.svg';
import { ReactComponent as HistoryIcon } from '../../../images/icons/version-control-history.svg';
import useEnvironmentFlags from '../../infrastructure/hooks/useEnvironmentFlags';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import {
    CommandKeys,
    LoadingCircle,
    Menu,
    MenuItem,
    Toggle,
    Tooltip,
    useKeyShortcutCallback,
    useToggle,
} from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import { StateLinkButton } from '@tonkean/tui-buttons/Button';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const EditModuleNameModalWrapper = styled.div`
    opacity: 0;
    margin-left: 4px;
    transition: opacity 0.1s linear;
`;

const ModuleNavigator = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:hover ${EditModuleNameModalWrapper} {
        opacity: 1;
        transition: opacity 0.1s linear;
    }
`;

const LeftSideNavbar = styled.span`
    display: flex;
    align-items: center;
`;

const RightSideNavbar = styled.span`
    align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
`;

const SettingsButton = styled(CogIcon)`
    cursor: pointer;
    order: 6;
`;

const Spacer = styled.span`
    width: 0px;
    height: 30px;
    border: 1px solid ${Theme.colors.gray_300};
    transform: rotate(180deg);
    flex: none;
    order: 1;
    flex-grow: 0;
`;

const Spacer2 = styled(Spacer)`
    order: 4;
`;

const StyledStateLinkButton = styled(StateLinkButton)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 4px;
    color: ${Theme.current.palette.colorPicker.HEX_5B636C};
    font-size: ${FontSize.SMALL_12};
    height: 30px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
    flex: none;
    order: 3;
    flex-grow: 0;
    cursor: pointer;

    &:hover {
        border: 1px solid ${Theme.colors.gray_400};
        text-decoration: none;
        background-color: ${Theme.colors.HEX_F9F9F7};
    }
`;

const TestButton = styled(Button)<{ disabled: boolean; $error: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 2px;
    color: ${(props) => testButtonObjectColor(props)};
    font-size: ${FontSize.SMALL_12};
    height: 30px;
    border: 1px solid ${(props) => testButtonObjectColor(props)};
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
    opacity: ${(props) => (props.disabled ? '0.5' : 'unset')};
    cursor: ${(props) => (props.disabled ? 'not-allowed !important' : 'pointer')};

    &:focus {
        color: ${(props) => testButtonObjectColor(props)};
        border-color: ${(props) => testButtonObjectColor(props)};
        svg {
            [fill] {
                fill: ${(props) => testButtonObjectColor(props)};
            }
        }
    }

    svg {
        [fill] {
            fill: ${(props) => testButtonObjectColor(props)};
        }
    }
    &:hover {
        background-color: ${(props) => testButtonColor(props, true)};
        border-color: ${(props) => testButtonObjectColor(props)};
        text-decoration: none;
        color: ${(props) => testButtonColor(props, false)};
        svg {
            [fill] {
                fill: ${(props) => testButtonColor(props, false)};
            }
        }
    }
`;

const testButtonColor = (props, withError) => {
    if (!withError) {
        return props.disabled ? Theme.colors.primaryHighlight : Theme.colors.white;
    } else {
        return props.disabled ? Theme.colors.white : props.$error ? Theme.colors.error : Theme.colors.primaryHighlight;
    }
};

const testButtonObjectColor = (props) => {
    return props.$error ? Theme.colors.error : Theme.colors.primaryHighlight;
};

const StyledMenuItem = styled(MenuItem)`
    font-size: 12px;
    width: 235px;
    height: 30px;
    padding: 7px 8px;

    &:focus {
        background-color: ${Theme.colors.white};
    }

    &:hover {
        background-color: ${Theme.colors.gray_900};
    }
`;

const StyledBusinessReportContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 26px;
`;

const StyledBusinessReportToggle = styled.span`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    flex: none;
    order: 1;
    flex-grow: 0;

    align-items: center;
`;

const StyledEnvironmentExplorerContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    order: 5;
`;

interface Props {
    currentEnvironment: Environment;
    onEnvironmentChange: () => void;
    environmentIsActive: boolean;
    workflowVersionId: string;
    onLiveReportClick: (groupId: string, isOpen: boolean) => void;
    isLiveReportOn: boolean;
    group: Group;
    onTestWorkflowClicked: (error, invalid: string) => void;
    successfulValidation: boolean;
    onSuccessfulPublish: () => void;
    onRevertWorkflowVersionClicked: () => void;
    showButtonBackToMarkForPublish: () => void;
    onReadyToPublishWorkflowClicked: (componentsAreValidated) => void;
    onDiscardPendingChangesModalOpened: () => void;
}

const ModuleNavigation: React.FC<Props> = ({
    environmentIsActive,
    currentEnvironment,
    onEnvironmentChange,
    onLiveReportClick,
    isLiveReportOn,
    group,
    onTestWorkflowClicked,
    successfulValidation,
    showButtonBackToMarkForPublish,
    onSuccessfulPublish,
    onRevertWorkflowVersionClicked,
    onReadyToPublishWorkflowClicked,
    workflowVersionId,
    onDiscardPendingChangesModalOpened,
}) => {
    const $state = useAngularService('$state');
    const { isDraft, isPublished, sequentialIdentifier, subSequentialIdentifier } = useEnvironmentFlags();
    const navigationService = useAngularService('navigationService');
    const modalUtils = useAngularService('modalUtils');
    const projectManager = useAngularService('projectManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const customTriggerManager = useAngularService('customTriggerManager');
    const tonkeanService = useAngularService('tonkeanService');
    const $rootScope = useAngularService('$rootScope') as IRootScopeService & Record<any, any>;

    const [isDrilldownMode] = useAngularWatch(() => navigationService.isDrilldownMode);

    const [currentGroup, setCurrentGroup] = useState(group);
    const isViewingHistoricVersion = !isDraft && !isPublished;

    const [isValidationLoading, setIsValidationLoading] = useState(false);

    const groupManager = useAngularService('groupManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const groupPermissions = useAngularService('groupPermissions');
    const hasEditModulePermissions = groupPermissions.hasPermissionsToEditWorker(group);

    const [, updateGroupName] = useLazyAsyncMethod(groupManager, 'updateGroupName');
    const hasPermissions = groupPermissions.hasPermissionsToEditWorker(projectManager.groupsMap[group.id]);

    const draftWorkflowVersionId = useMemo(() => {
        const draftWorkflowVersionId = workflowVersionManager.groupIdToDraftWorkflowVersionIdMap[group.id];

        if (!draftWorkflowVersionId) {
            throw new Error(`No draft version id of group id ${group.id} in cache`);
        }

        return draftWorkflowVersionId;
    }, [group.id, workflowVersionManager.groupIdToDraftWorkflowVersionIdMap]);

    const [draftWorkflowVersion] = useAngularWatch(() => {
        const draftVersionFromCache =
            workflowVersionManager.workflowVersionIdToWorkflowVersionMap[draftWorkflowVersionId];

        if (!draftVersionFromCache) {
            throw new Error(`No draft version of group id ${group.id} in cache`);
        }

        return draftVersionFromCache;
    });

    const workflowVersion = workflowVersionManager.workflowVersionIdToWorkflowVersionMap[workflowVersionId]!;

    const [isPublishReady] = useAngularWatch(() => {
        return draftWorkflowVersion.isPublishReady;
    });

    const [draftWorkflowVersionChanges] = useAngularWatch(() => {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(group.id)?.id;

        if (!draftWorkflowVersionId) {
            console.error(`Could not load draftWorkflowVersionId for groupId : ${group.id}`);
            return 0;
        }

        return workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersionId] ?? 0;
    });

    const [allDraftAndSubWorkflowVersionChanges] = useAngularWatch(() => {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(group.id)?.id;

        if (!draftWorkflowVersionId) {
            console.error(`Could not load draftWorkflowVersionId for groupId : ${group.id}`);
            return 0;
        }

        return (
            draftWorkflowVersionChanges +
            (workflowVersionManager.subWorkflowVersionIdToChangesCounterMap[draftWorkflowVersionId] ?? 0)
        );
    });

    const [settingsMenuView, toggleSettingsMenuView, setSettingsMenuView] = useToggle(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [testButtonText, setTestButtonText] = useState<string>('Test');
    const [testTooltipContent, setTestTooltipContent] = useState<string>();
    const [testButtonActive, setTestButtonActive] = useState<boolean>(true);
    const [isModuleInfoModalOpen, setIsModuleModalInfoOpen] = useState(false);
    const [validateLogicsTooltipError, setValidateLogicsTooltipError] = useState<string>();

    useEffect(() => {
        setIsActive(environmentIsActive);

        if (!projectManager.groupsMap[group.id].buildEnvironmentEnabled) {
            setTestTooltipContent('Build environment must be on for testing the flow');
            setTestButtonActive(false);
        } else {
            setTestTooltipContent(undefined);
            setTestButtonActive(true);
        }
    }, [environmentIsActive, group.id, projectManager.groupsMap]);

    useEffect(() => {
        if (successfulValidation) {
            setTestButtonText('Test');
            setTestTooltipContent(undefined);
            setTestButtonActive(true);
            setValidateLogicsTooltipError(undefined);
        }
    }, [successfulValidation]);

    const validateLogics = useCallback(
        (isTestRun: boolean = false) => {
            if (!draftWorkflowVersion) {
                return Promise.reject();
            }

            return customTriggerManager
                .validateCustomTriggers(draftWorkflowVersion.id, group, projectManager.project)
                .then(() => {
                    if (isTestRun) {
                        setIsValidationLoading(false);
                    }
                    onTestWorkflowClicked({}, 'VALID');

                    setTestButtonText(`Test`);
                    setValidateLogicsTooltipError(undefined);
                    setTestButtonActive(true);

                    return Promise.resolve();
                })
                .catch((error) => {
                    onTestWorkflowClicked(error, 'INVALID');

                    setIsValidationLoading(false);
                    setTestButtonText(`Errors found (${Object.keys(error).length})`);
                    setValidateLogicsTooltipError('There are errors in the module editor below');
                    setTestButtonActive(true);

                    return Promise.reject(error);
                });
        },
        [customTriggerManager, draftWorkflowVersion, group, onTestWorkflowClicked, projectManager.project],
    );

    const createVersionClicked = (event) => {
        event.preventDefault();

        $rootScope.$emit('alert', {
            type: 'success',
            msg: keyShortcutEventReceivedMessage,
        });

        if (!draftWorkflowVersion) {
            return;
        }

        if (isViewingHistoricVersion || currentEnvironment != 'build') {
            $rootScope.$emit('alert', {
                type: 'warning',
                msg: 'To create a version you need to be in the Build environment.',
            });

            return;
        }

        if (!hasPermissions) {
            $rootScope.$emit('alert', {
                type: 'danger',
                msg: 'You need to be a maker to create a version',
            });

            return;
        }

        if (workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersion.id] === 0) {
            $rootScope.$emit('alert', {
                type: 'danger',
                msg: 'You should make changes before creating versions',
            });

            return;
        }

        validateLogics()
            .then(() => {
                tonkeanService
                    .commitWorkflowVersion(group.id, '')
                    .then((workflowVersionId) => {
                        workflowVersionManager.lastWorkflowVersionIdMap[group.id] = workflowVersionId;

                        return groupInfoManager.getGroup(group.id, true).then(() =>
                            // Initialize the changes counter for the group.
                            workflowVersionManager
                                .initializeChangesCounter(draftWorkflowVersion.id, group.id)
                                .then(() => {
                                    $rootScope.$emit('alert', {
                                        type: 'success',
                                        msg: `${group.name} version created`,
                                    });
                                }),
                        );
                    })
                    .catch((error) => {
                        $rootScope.$emit('alert', {
                            type: 'danger',
                            msg: `Error on version created ${error?.data?.error?.message}`,
                        });
                    });
            })
            .catch(() => {
                $rootScope.$emit('alert', {
                    type: 'danger',
                    msg: 'There are errors in the module editor below',
                });
            });
    };

    const onTestClicked = useCallback(() => {
        setIsValidationLoading(true);
        validateLogics(true).then(() => {
            setTestButtonText('Test');
            setTestButtonActive(true);
            setValidateLogicsTooltipError(undefined);
            modalUtils.openTestWorkflowModal(group.id, null, null, null, null);
        });
    }, [validateLogics, modalUtils, group.id]);

    const onSettingMenuClicked = (showSettingMenuTab) => {
        modalUtils.openPrivateGroupModal(group, showSettingMenuTab, true, true);
    };

    const toggleBusinessReportActive = () => {
        setSettingsMenuView(true);

        onLiveReportClick(group.id, isLiveReportOn);
    };

    const [keyShortcutEventReceivedMessage] = useAngularWatch(() => {
        return draftWorkflowVersion &&
            workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersion.id]
            ? `Creating version with ${
                  workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersion.id]
              } changes.`
            : 'Creating a version for your changes';
    });

    const onRevertWorkflowVersionInnerClicked = () => {
        onRevertWorkflowVersionClicked();
        validateLogics();
    };

    useKeyShortcutCallback('d', createVersionClicked, false, true, false, undefined, CommandKeys.COMMAND);

    if (!isDrilldownMode && group) {
        return (
            <SecondaryNavbar isUppermost={isDrilldownMode}>
                <ModuleInfoModal
                    open={isModuleInfoModalOpen}
                    onClose={() => setIsModuleModalInfoOpen(false)}
                    group={group}
                    workflowVersion={workflowVersion}
                />
                <LeftSideNavbar>
                    <ModuleNavigator data-automation="module-navigation-left-side">
                        <SolutionOpsModulesDropdown
                            group={currentGroup}
                            onMenuItemClicked={(groupOption) =>
                                $state.go('.', {
                                    g: groupOption.value,
                                    env: undefined,
                                    historyVersion: undefined,
                                    t: undefined,
                                    targetId: undefined,
                                })
                            }
                        />
                    </ModuleNavigator>
                    {!isViewingHistoricVersion && currentEnvironment === 'build' && draftWorkflowVersion && (
                        <ModuleActions
                            group={group}
                            draftWorkflowVersion={draftWorkflowVersion}
                            workflowVersionChanges={draftWorkflowVersionChanges}
                            draftWorkflowVersionChanges={allDraftAndSubWorkflowVersionChanges}
                            validateLogics={validateLogics}
                            hasPermissions={hasPermissions}
                            onRevertWorkflowVersionClicked={onRevertWorkflowVersionInnerClicked}
                            showButtonBackToMarkForPublish={showButtonBackToMarkForPublish}
                            onSuccessfulPublish={onSuccessfulPublish}
                            validateLogicsTooltipError={validateLogicsTooltipError}
                            isPublishReady={isPublishReady}
                            onReadyToPublishWorkflowClicked={onReadyToPublishWorkflowClicked}
                            onDiscardPendingChangesModalOpened={onDiscardPendingChangesModalOpened}
                        />
                    )}
                </LeftSideNavbar>

                <RightSideNavbar>
                    {!isViewingHistoricVersion && (
                        <BusinessReportButton
                            groupId={group.id}
                            isActive={isLiveReportOn && !isViewingHistoricVersion}
                        />
                    )}
                    <Spacer />
                    {currentEnvironment === 'build' && (
                        <Tooltip
                            content={testTooltipContent ?? validateLogicsTooltipError}
                            disabled={!testTooltipContent && !validateLogicsTooltipError}
                            placement="top"
                        >
                            <TestButton
                                data-automation="version-control-toolbar-content-click-on-test-button"
                                size={ButtonSize.MEDIUM}
                                onClick={onTestClicked}
                                disabled={!isActive || !testButtonActive || isValidationLoading}
                                $error={!!validateLogicsTooltipError}
                                outlined
                                cancel
                            >
                                {isValidationLoading ? (
                                    <LoadingCircle />
                                ) : (
                                    <span className="tnk-icon">
                                        {!validateLogicsTooltipError ? <TestIcon /> : <TestErrorIcon />}
                                    </span>
                                )}
                                {testButtonText}
                            </TestButton>
                        </Tooltip>
                    )}
                    {!isViewingHistoricVersion && (
                        <>
                            <StyledStateLinkButton
                                data-automation="environment-explorer-history-button"
                                size={ButtonSize.MEDIUM}
                                state="product.workerEditor.history"
                                params={{ g: group.id, environment: currentEnvironment }}
                                outlined
                                cancel
                            >
                                <span className="tnk-icon">
                                    <HistoryIcon />
                                </span>
                                History
                            </StyledStateLinkButton>
                            <Spacer2 />
                            <StyledEnvironmentExplorerContainer>
                                <EnvironmentExplorer
                                    environmentIsActive={environmentIsActive}
                                    onEnvironmentChange={onEnvironmentChange}
                                    currentEnvironment={currentEnvironment}
                                    group={group}
                                />
                            </StyledEnvironmentExplorerContainer>
                            <Menu
                                show={settingsMenuView}
                                onClose={() => toggleSettingsMenuView()}
                                placement="bottom-end"
                                menuItems={
                                    <>
                                        <StyledMenuItem
                                            data-automation="module-navigation-setting-option"
                                            icon={<InformationIcon />}
                                            onClick={() => setIsModuleModalInfoOpen(true)}
                                        >
                                            Module Info
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                            data-automation="module-navigation-setting-option"
                                            icon={<SettingsIcon />}
                                            onClick={() => onSettingMenuClicked('settings')}
                                        >
                                            Settings
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                            data-automation="module-navigation-item-default-option"
                                            icon={<ItemDefaultsIcon />}
                                            onClick={() => onSettingMenuClicked('innerTracksTemplate')}
                                        >
                                            Item defaults
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                            data-automation="module-navigation-activity-option"
                                            icon={<ActivityIcon />}
                                            onClick={() => onSettingMenuClicked('activity')}
                                        >
                                            Activity
                                        </StyledMenuItem>
                                        <StyledMenuItem icon={<BusinessReportIcon />}>
                                            <Tooltip
                                                disabled={currentEnvironment === 'build'}
                                                content="In order to hide the Business Report, please move to build environment"
                                            >
                                                <StyledBusinessReportContainer>
                                                    <span>Business report</span>
                                                    <StyledBusinessReportToggle>
                                                        <Toggle
                                                            size={InputSize.SMALL}
                                                            checked={isLiveReportOn}
                                                            disabled={!hasPermissions || currentEnvironment !== 'build'}
                                                            onChange={() => toggleBusinessReportActive()}
                                                        />
                                                    </StyledBusinessReportToggle>
                                                </StyledBusinessReportContainer>
                                            </Tooltip>
                                        </StyledMenuItem>
                                    </>
                                }
                            >
                                <SettingsButton
                                    data-automation="module-navigation-setting-button"
                                    onClick={() => toggleSettingsMenuView()}
                                />
                            </Menu>
                        </>
                    )}
                    {isViewingHistoricVersion && (
                        <>
                            <span className="margin-right-xs common-color-light-grey2">
                                You are viewing a historic draft version V{sequentialIdentifier}
                                {subSequentialIdentifier &&
                                    subSequentialIdentifier > 0 &&
                                    `.${subSequentialIdentifier}`}
                            </span>
                            <StateLinkButton
                                state="product.workerEditor"
                                params={{ env: 'DRAFT', historyVersion: '' }}
                                size={ButtonSize.SMALL}
                                highlighted
                            >
                                Return to {hasEditModulePermissions ? 'build' : 'production'} environment
                            </StateLinkButton>
                        </>
                    )}
                </RightSideNavbar>
            </SecondaryNavbar>
        );
    } else {
        return <></>;
    }
};

export default ModuleNavigation;
