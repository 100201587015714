export function FrontappModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    utils,
    integrations,
    authenticationService,
    tonkeanService,
    projectManager,
) {
    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.data.projectIntegration = settings.projectIntegration;
        $scope.integration = state.integration;
        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;
        tryLoadFromEdit();

        if ($scope.data.projectIntegration) {
            $scope.data.loadingExistingIncomingWebhooks = true;
            $scope.data.errorLoadingExistingIncomingWebhooks = false;

            tonkeanService
                .getIncomingWebhooksByProjectIntegrationId($scope.data.projectIntegration.id)
                .then((data) => {
                    if (data.entities && data.entities.length) {
                        $scope.data.incomingWebhookUrl = data.entities[0].url;
                    }
                })
                .catch(() => {
                    $scope.data.errorLoadingExistingIncomingWebhooks = true;
                })
                .finally(() => {
                    $scope.data.loadingExistingIncomingWebhooks = false;
                });
        }
    };

    $scope.createFrontappIncomingWebhook = function () {
        $scope.data.creatingFrontappIncomingWebhook = true;
        $scope.data.errorCreatingFrontappIncomingWebhook = false;

        tonkeanService
            .createIntegrationIncomingWebhook(projectManager.project.id, $scope.data.projectIntegration.id, 'frontapp')
            .then((data) => {
                if (data.incomingWebhook) {
                    $scope.data.incomingWebhookUrl = data.incomingWebhook.url;
                }
            })
            .catch(() => {
                $scope.data.errorCreatingFrontappIncomingWebhook = true;
            })
            .finally(() => {
                $scope.data.creatingFrontappIncomingWebhook = false;
            });
    };

    $scope.connect = async function () {
        $scope.connecting = true;
        $scope.error = null;

        try {
            const token = $scope.data.apikey;
            const config = {
                api_token: token,
                authentication_type: 'api_token',
            };
            $scope.integration = await createProjectApis.createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                config,
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'FRONTAPP',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            );

            // If the integration connected successfully
            const settings = {
                integration: $scope.integration,
                integrations: [{ integration: $scope.integration, projectData: {} }],
            };

            if ($scope.onIntegrationClosed) {
                $scope.onIntegrationClosed(settings);
            }
        } catch (error) {
            $scope.error = error.data.error.message;
        } finally {
            $scope.connecting = false;
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.data.apikey = '';
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }
        const settings = {
            integration: $scope.integration,
            integrations: [{ integration: $scope.integration, projectData: {} }],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(settings);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = false;
            $scope.error = null;
        }
    }
}

export default angular.module('tonkean.shared').controller('FrontappModalCtrl', FrontappModalCtrl);
