import type { AngularServices } from 'angulareact';
import groupBy from 'lodash.groupby';
import React from 'react';
import styled from 'styled-components';

import { H4, MenuItem, Separator } from '@tonkean/infrastructure';
import { InitiativeStatus } from '@tonkean/tonkean-entities';
import type { Person, State } from '@tonkean/tonkean-entities';

const StatusSectionTitle = styled(H4)`
    margin-left: 7px;
    margin-top: 4px;
`;

const Divider = styled.hr`
    margin: 4px 0;
`;

interface Props {
    track: any;
    realTrack: any;
    groupId: string;
    workflowVersionId: string;
    services: {
        as: AngularServices['authenticationService'];
        wvm: AngularServices['workflowVersionManager'];
        pm: AngularServices['projectManager'];
        currentUserService: AngularServices['currentUserService'];
    };

    askForUpdates(owner: Person, realTrack: unknown): void;

    openEditStatus(track: unknown, hideActivity: unknown, state: unknown): void;
}

/**
 * A components that renders the track's status dropdown menu.
 */
const TrackStatusDropdown: React.FC<Props> = ({
    realTrack,
    services,
    askForUpdates,
    track,
    groupId,
    workflowVersionId,
    openEditStatus,
}) => {
    // Ask owner elements (only exist if there's an owner and he's not the current user).
    let askOwnerElement: React.ReactNode = null;
    let askOwnerDivider: React.ReactNode = null;
    if (realTrack.owner && realTrack.owner.id !== services.as.getCurrentUser().id) {
        askOwnerElement = (
            <MenuItem icon={<div>👋</div>} onClick={() => askForUpdates(realTrack.owner, realTrack)}>
                Ping {realTrack.owner.name}
            </MenuItem>
        );
        askOwnerDivider = <Separator />;
    }

    // Set the relevant states for this item.
    const states: State[] =
        services.wvm.getCachedWorkflowVersion(workflowVersionId)?.states?.filter((state) => state?.label.length) || [];
    let moreStates: State[] | undefined;

    const statesElements = Object.entries(
        groupBy(states, ({ type }) => (type === InitiativeStatus.INTAKE ? 'Intake' : 'Triage and Coordination')),
    ).map(([label, statesList]) => (
        <div key={label}>
            <>
                <StatusSectionTitle $light>{label}</StatusSectionTitle>
                <Divider />

                {statesList.map((state) => (
                    <MenuItem
                        key={state.label}
                        onClick={() => openEditStatus(track, false, state)}
                        data-automation="track-status-dropdown-options"
                        icon={<i className="fa fa-square" style={{ color: state.color }} />}
                    >
                        {state.label}
                    </MenuItem>
                ))}
            </>
        </div>
    ));

    // More states elements - for when we have that linked item on our hands.
    let moreStatesElements: React.ReactNode | undefined;
    if (moreStates?.length) {
        moreStatesElements = moreStates.map((state, index) => (
            <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => openEditStatus(track, false, state)}
                icon={<i className="fa fa-square" style={{ color: state.color }} />}
            >
                {state.label}
            </MenuItem>
        ));
    }

    return (
        <>
            {askOwnerElement}
            {askOwnerDivider}
            <MenuItem onClick={() => openEditStatus(realTrack, false, null)}>Give an update</MenuItem>
            <Separator />
            {statesElements.map((statesElement) => (
                <React.Fragment key={statesElement.key}>{statesElement}</React.Fragment>
            ))}
            {moreStatesElements && (
                <>
                    <div className="common-color-grey common-size-xxxxs padding-top padding-bottom-xxs padding-left padding-right">
                        From original list:
                    </div>
                    {moreStatesElements}
                </>
            )}
        </>
    );
};

export default TrackStatusDropdown;
