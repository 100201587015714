import { useAngularService } from 'angulareact';
import { useEffect } from 'react';

import { useAsyncMethod, useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

function useCacheFillForTonkeanExpression(
    groupId: TonkeanId<TonkeanType.GROUP>,
    workflowVersionType: WorkflowVersionType,
): { loading: boolean; successful: boolean } {
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const customFieldManager = useAngularService('customFieldsManager');
    const customTriggerManager = useAngularService('customTriggerManager');

    const { data: group, loading: loadingGroup } = useAsyncMethod(groupInfoManager, 'getGroupById', groupId);
    const { data: version, loading: loadingVersion } = useAsyncMethod(
        workflowVersionManager,
        'getGroupWorkflowVersion',
        groupId,
        workflowVersionType,
    );
    const [{ data: fields, loading: loadingFields, called: fieldsCalled }, getFields] = useLazyAsyncMethod(
        customFieldManager,
        'getFieldDefinitions',
    );
    const [
        { data: customTriggersResponse, loading: loadingTriggers, called: triggersCalled },
        getWorkflowVersionCustomTriggersAndGraph,
    ] = useLazyAsyncMethod(customTriggerManager, 'getWorkflowVersionCustomTriggersAndGraph');

    useEffect(() => {
        if (group && version) {
            getFields(version.id, true);
            getWorkflowVersionCustomTriggersAndGraph(version.id);
        }
    }, [getFields, getWorkflowVersionCustomTriggersAndGraph, group, version]);

    const loading =
        loadingGroup || loadingVersion || loadingFields || loadingTriggers || !fieldsCalled || !triggersCalled;
    return {
        loading,
        successful: !!(group && version && fields && customTriggersResponse),
    };
}

export default useCacheFillForTonkeanExpression;
