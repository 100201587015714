import type { FormulaTreeConstNode } from './FormulaTreeConstNode';
import type { FormulaTreeEmptyNode } from './FormulaTreeEmptyNode';
import FormulaTreeNodeBase from './FormulaTreeNodeBase';
import type { FormulaTreeVariableNode } from './FormulaTreeVariableNode';
import { FieldDefinitionType } from '../FieldDefinitionType';
import type FormulaField from '../FormulaField';
import { FormulaTreeNodeType } from '../FormulaTreeNodeType';
import { OperatorFamily } from '../OperatorFamily';
import type FormulaOperatorDefinitionBase from '../operators/FormulaOperatorDefinitionBase';
import type { ServerFormulaExpressionNode, ServerFormulaExpressionTreeNode } from '../ServerFormulaExpressionNode';
import EmptyFieldValidationError from '../validationErrors/EmptyFieldValidationError';

import utils from '@tonkean/utils';

type formulaTreeNode = FormulaTreeTreeNode | FormulaTreeConstNode | FormulaTreeVariableNode | FormulaTreeEmptyNode;

export class FormulaTreeTreeNode extends FormulaTreeNodeBase {
    public declare type: FormulaTreeNodeType.TREE;

    constructor(
        public operator: FormulaOperatorDefinitionBase,
        public operands: formulaTreeNode[],
        public override field: FormulaField,
        id?: number,
    ) {
        super(FormulaTreeNodeType.TREE, field, id);

        if (typeof operator.dataType !== 'function') {
            this.dataType = operator.dataType;
        }
    }

    public override toString(evaluated: boolean = false) {
        switch (this.operator.family) {
            case OperatorFamily.FUNCTION: {
                const operands = this.operands
                    .map((operand) => operand.toString(evaluated))
                    .filter(Boolean)
                    .join(', ');
                return `${this.operator.sign}(${operands})`;
            }

            case OperatorFamily.LOGICAL:
            case OperatorFamily.ARITHMETIC:
                return `(${this.operands[0]!.toString(evaluated)} ${this.operator.sign} ${this.operands[1]!.toString(
                    evaluated,
                )})`;
        }
    }

    public override preformValidationAndDataTypeCalculation() {
        // This process can be run only once
        if (this.valid !== undefined) {
            return;
        }

        // Make it recursive
        this.operands.forEach((operand) => operand.preformValidationAndDataTypeCalculation());

        super.preformValidationAndDataTypeCalculation();
    }

    public override getServerFormulaNode(): ServerFormulaExpressionNode {
        return {
            '@type': 'tree',
            operator: this.operator.key,
            operands: this.operands.map((operand) => operand.getServerFormulaNode()),
        } as ServerFormulaExpressionTreeNode;
    }

    /**
     * This method should be called only if operand validation (`this.operator.validate`) has succeeded
     */
    protected override getType() {
        const dataTypeGetter = this.operator.dataType;

        if (typeof dataTypeGetter === 'function') {
            return dataTypeGetter(this.operands);
        }
        return dataTypeGetter;
    }

    public override clone(field: FormulaField) {
        return new FormulaTreeTreeNode(this.operator, this.operands, field, this.id);
    }

    protected override validate(): boolean {
        // Validate operands types
        const operandsValid = this.validateOperands();
        // Collect operands errors to validationErrors list
        const operandsHasNoErrors = this.getErrorsFromOperands();

        return operandsValid && operandsHasNoErrors;
    }

    private validateOperands(): boolean {
        // Check if fields are valid
        const { singleFields = [], ...tupleIdToFieldsMap } = utils.groupBy(this.operands, (operand: formulaTreeNode) =>
            operand.field.metadata.fieldDefinitionType === FieldDefinitionType.ARRAY
                ? operand.field.metadata.inArrayIndex
                : ('singleFields' as const),
        );

        const tuplesList = Object.values(tupleIdToFieldsMap) as formulaTreeNode[][];

        // Validate only valid operands that are not part of an empty tuple
        const nonEmptyTuples = tuplesList.filter((tuple) =>
            tuple.some((operand) => operand.type !== FormulaTreeNodeType.EMPTY),
        );
        // All operands that are not part of an empty tuple
        const operands: formulaTreeNode[] = [...nonEmptyTuples.flat(), ...singleFields];
        // All operands that passed they upper-level validation - valid is false or dataType is undefined (unless it's
        // an empty field - we need empty fields to validate emptyOperands)
        const operandsToValidate = operands.filter(
            (operand) => (operand.valid && operand.dataType) || operand.type === FormulaTreeNodeType.EMPTY,
        );

        // Validate missing fields
        const emptyOperands = operandsToValidate.filter((operand) => operand.type === FormulaTreeNodeType.EMPTY);
        if (emptyOperands.length) {
            emptyOperands.forEach((operand) => operand.setError(new EmptyFieldValidationError()));
        }

        const minTupleRepeats = this.operator.fieldsMetadata.arrayField?.minRepeats ?? 0;
        const missingTupleCount = minTupleRepeats - nonEmptyTuples.length;
        if (missingTupleCount > 0) {
            tuplesList
                .filter((tuple) => !nonEmptyTuples.includes(tuple))
                .slice(0, missingTupleCount)
                .flat()
                .forEach((field) => field.setError(new EmptyFieldValidationError()));

            return false;
        }

        // If has errors the operands, or if has empty operands not part of an empty tuple
        if (operands.length !== operandsToValidate.length || emptyOperands.length) {
            return false;
        }

        // Run custom operator validation
        const validationResult = this.operator.validate(this.operands);
        if (validationResult !== true) {
            validationResult.forEach((error) => this.setError(error));
            return false;
        }

        // Validate type of self
        return super.validate();
    }

    /**
     * Will get errors from the operands and add them to the validationErrors list.
     *
     * @returns true if no errors in operands
     */
    private getErrorsFromOperands() {
        this.validationErrors.push(...this.operands.flatMap((operand) => operand.validationErrors).filter(Boolean));

        return !this.validationErrors.length;
    }
}
