import React from 'react';

import NavigationMenuItem from './NavigationMenuItem';
import type BaseNavigablePage from '../../entities/BaseNavigablePage';
import type NavigablePage from '../../entities/NavigablePage';

import { Separator } from '@tonkean/infrastructure';
import { Theme, type Color } from '@tonkean/tui-theme';

interface Props {
    pages: NavigablePage[];
    isLastSection: boolean;
    currentPageId?: BaseNavigablePage['id'];
    themeColor: Color;
}

const NavigationMenuSection: React.FC<Props> = ({ pages, isLastSection, currentPageId, themeColor }) => {
    return (
        <>
            {pages.map((page) => (
                <NavigationMenuItem key={page.id} page={page} currentPageId={currentPageId} themeColor={themeColor} />
            ))}
            {isLastSection && <Separator color={Theme.colors.gray_300} />}
        </>
    );
};

export default NavigationMenuSection;
