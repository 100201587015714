<div class="tnk-microsoft-admin-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'RESET_USER_PASSWORD'" class="margin-bottom-xlg">

        <!-- User ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                User ID or Principal Name:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="User ID"
                    saved-expression="data.definition.userIdExpression"
                    on-tonkean-expression-changed="onUserIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Password Method -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Password Method (Optional):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Password Method"
                    saved-expression="data.definition.passwordMethodExpression"
                    on-tonkean-expression-changed="onPasswordMethodTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- New Password -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New Password:
                <i class="fa fa-info-circle common-color-primary margin-left-xs"
                   uib-tooltip="Required for tenants with hybrid password scenarios. If omitted for a cloud-only password, the system returns a system-generated password."></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="New Password"
                    saved-expression="data.definition.newPasswordExpression"
                    on-tonkean-expression-changed="onNewPasswordTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'RESTORE_USER'" class="margin-bottom-xlg">

        <!-- User (Object) ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                User ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="User ID"
                    saved-expression="data.definition.userIdExpression"
                    on-tonkean-expression-changed="onUserIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ASSIGN_USER_TO_MANAGER'" class="margin-bottom-xlg">

        <!-- Manager ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Manager ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Manager ID"
                    saved-expression="data.definition.managerIdExpression"
                    on-tonkean-expression-changed="onManagerIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_USER_DESCRIPTION'" class="margin-bottom-xlg">

        <!-- Description -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Description:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Description"
                    saved-expression="data.definition.descriptionExpression"
                    on-tonkean-expression-changed="onDescriptionTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CHANGE_MFA_STATE'" class="margin-bottom-xlg">

        <!-- MFA State -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                MFA State:
                <i class="fa fa-info-circle common-color-primary margin-left-xs"
                   uib-tooltip="Options: disabled, enabled, enforced"></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="MFA State"
                    saved-expression="data.definition.mfaStateExpression"
                    on-tonkean-expression-changed="onMfaStateTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_USER_TO_GROUP' || data.definition.customActionKey === 'REMOVE_USER_FROM_GROUP'"
         class="margin-bottom-xlg">

        <!-- Group ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Group ID:
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Group ID"
                    saved-expression="data.definition.groupIdExpression"
                    on-tonkean-expression-changed="onGroupIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_REMOVE_USER_LICENSES'" class="margin-bottom-xlg">

        <!-- Add -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Add Licenses:
                <i class="fa fa-info-circle common-color-primary margin-left-xs"
                   uib-tooltip="Comma-separated list of the SKU IDs"></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Add Licenses"
                    saved-expression="data.definition.licensesAddedExpression"
                    on-tonkean-expression-changed="onLicensesAddedTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Remove -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Remove Licenses:
                <i class="fa fa-info-circle common-color-primary margin-left-xs"
                   uib-tooltip="Comma-separated list of the SKU IDs"></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Removed Licenses"
                    saved-expression="data.definition.licensesRemovedExpression"
                    on-tonkean-expression-changed="onLicensesRemovedTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'UPDATE_USER_USAGE_LOCATION'" class="margin-bottom-xlg">

        <!-- Location -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Location (Country Code):
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Location"
                    saved-expression="data.definition.usageLocationExpression"
                    on-tonkean-expression-changed="onUsageLocationTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_USER_TO_POLICIES' || data.definition.customActionKey === 'REMOVE_USER_FROM_POLICIES'" class="margin-bottom-xlg">

        <!-- Policy IDs -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Policy IDs:
                <i class="fa fa-info-circle common-color-primary margin-left-xs"
                   uib-tooltip="Comma-separated list of policies IDs"></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Policy IDs"
                    saved-expression="data.definition.policiesIdsExpression"
                    on-tonkean-expression-changed="onPoliciesIdsTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
