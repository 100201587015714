import React, { useMemo } from 'react';
import type { MultiValue, SingleValue, StylesConfig } from 'react-select';

import type { SearchDropdownSelectorOption } from './UpdateFieldAsyncSearchDropdownSelector';
import UpdateFieldAsyncSearchDropdownSelector from './UpdateFieldAsyncSearchDropdownSelector';

import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

interface Props {
    fieldDefinitionId: FieldDefinition['id'];
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    onChange: (newValue: string | string[] | undefined) => void;
    value: string | undefined;
    isMultiValueList: boolean;
    multiValueList: string[] | undefined;
    readOnly: boolean | undefined;
    isInBuilder?: boolean;
    placeholderText?: string;
    isCreatable: boolean;
    styles?: StylesConfig<SimpleSelectSingleOption<string>>;
    autoFocus?: boolean;
    defaultMenuIsOpen?: boolean;
    workflowVersionType?: WorkflowVersionType;
}

const UpdateFieldAsyncSearchDropdownSelectorWrapper: React.FC<Props> = ({
    fieldDefinitionId,
    initiativeId,
    onChange,
    value,
    isMultiValueList,
    multiValueList,
    readOnly,
    isInBuilder = false,
    placeholderText = 'Search an option',
    isCreatable,
    workflowVersionType,
    ...props
}) => {
    const asyncDropdownValue:
        | MultiValue<SearchDropdownSelectorOption>
        | SingleValue<SearchDropdownSelectorOption>
        | undefined = useMemo(() => {
        if (isMultiValueList) {
            return multiValueList?.map((item) => ({ label: item, value: item })) || [];
        } else if (!!value) {
            return { label: value.toString(), value: value.toString() };
        } else {
            // React-select only clears the value if it is exactly null
            return null;
        }
    }, [isMultiValueList, multiValueList, value]);

    return (
        <UpdateFieldAsyncSearchDropdownSelector
            fieldDefinitionId={fieldDefinitionId}
            initiativeId={initiativeId}
            value={asyncDropdownValue}
            onChange={(newValue) =>
                isMultiValueList
                    ? onChange((newValue as MultiValue<SearchDropdownSelectorOption>).map((item) => item.value))
                    : onChange((newValue as SingleValue<SearchDropdownSelectorOption>)?.value)
            }
            isMultiValueList={isMultiValueList}
            multiValueList={multiValueList}
            readOnly={readOnly}
            isInBuilder={isInBuilder}
            placeholderText={placeholderText}
            isCreatable={isCreatable}
            workflowVersionType={workflowVersionType}
            {...props}
        />
    );
};

export default UpdateFieldAsyncSearchDropdownSelectorWrapper;
