import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function SyncInnerMatchedEntityLogicConfigurationCtrl(
    $scope,
    $q,
    customTriggerManager,
    projectManager,
) {
    const ctrl = this;

    $scope.ctm = customTriggerManager;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        limitExpression: {
            originalExpression: '1000',
            evaluatedExpression: '1000',
        },
        matchedEntityId: null,
        // Can be 'sync' or 'importOnce'
        importMethod: 'sync',
        previewMatchedEntityItemsModalOpen: false,
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'SYNC_INNER_MATCHED_ENTITY',
        );

        if (customTriggerAction) {
            const definition = customTriggerAction.customTriggerActionDefinition;

            $scope.data.matchedEntityId = definition.matchedEntityId;
            $scope.data.importMethod = definition.importOnce ? 'importOnce' : 'sync';
            $scope.data.limitExpression = definition.limitExpression || {
                originalExpression: '1000',
                evaluatedExpression: '1000',
            };
        }
    }

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {};

    /**
     * Opens the preview modal.
     */
    $scope.openMatchedEntityItemsPreviewModal = function () {
        $scope.data.previewMatchedEntityItemsModalOpen = true;
    };

    /**
     * Closes the preview modal.
     */
    $scope.closePreviewMatchedEntityItemsModal = function () {
        $scope.data.previewMatchedEntityItemsModalOpen = false;
    };

    $scope.onLimitChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.limitExpression = { originalExpression, evaluatedExpression };
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once you select a matched entity.
     */
    $scope.onMatchedEntitySelected = function (selectedMatchedEntity) {
        $scope.data.matchedEntityId = selectedMatchedEntity.id;
        $scope.onActionsChanged(true);
    };

    $scope.onImportOnceCheckboxChanged = function (shouldImportOnce) {
        $scope.data.importMethod = shouldImportOnce;
        $scope.onActionsChanged(true);
        return $q.resolve();
    };

    $scope.filterRootFields = function (fields) {
        return fields.filter(
            (field) => field.performOnWorkerItem !== true && !field.definition?.matchConfiguration?.isForMatchingItem,
        );
    };

    /**
     * Occurs on update of the actions definition.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const definition = {
                matchedEntityId: $scope.data.matchedEntityId,
                importOnce: $scope.data.importMethod === 'importOnce',
                limitExpression: $scope.data.limitExpression,
            };

            const actionDefinition = {
                actions: [
                    {
                        type: 'SYNC_INNER_MATCHED_ENTITY',
                        definition,
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'SyncInnerMatchedEntityLogicConfigurationCtrl',
        lateConstructController(SyncInnerMatchedEntityLogicConfigurationCtrl),
    );
