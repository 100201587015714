/* eslint-disable no-nested-ternary */
import { useAngularService } from 'angulareact';
import type { FormikHelpers as FormikHelpersType } from 'formik';
import { Formik } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

import CardItemDisplay from './CardItemDisplay';
import type { SingleFieldChanged } from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';
import { FieldsWidgetDisplayType } from '../../FieldsWidgetModule';
import { useWidgetConfigurationFieldsSoftMigration } from '../../hooks';
import { useItemFieldWidget } from '../../hooks';
import { useFilterFieldsInWidget } from '../../hooks';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Breakpoint, FormikHelpers, LoadingCircle, type UpdateFieldsValuesManager } from '@tonkean/infrastructure';
import type { Initiative, TonkeanId, TonkeanType, WidgetBase, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

const CardsListWrapper = styled.div<{ shouldDisplayCardsOnMobile: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    overflow: hidden;
    ${({ shouldDisplayCardsOnMobile }) =>
        shouldDisplayCardsOnMobile &&
        css`
            @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
                grid-template-columns: 1fr;
            }
        `}
`;

const LoadMoreButton = styled(UserThemedClickableButton)`
    margin: 16px 0;
`;

const LoadMoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

type FormikValueType = Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, SingleFieldChanged>;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    initiatives: Initiative[];
    widget: WidgetBase<InnerItemWidgetConfiguration>;
    viewOnly: boolean;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    workflowVersionType: WorkflowVersionType | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    environmentIsActive?: boolean;
    showModuleOffIndication?: boolean;
    showLoadMore: boolean;
    loadMore: () => void;
    headerBackgroundColor: string;
    shouldShowGalleryView: boolean;
    updateFieldsValuesManager: UpdateFieldsValuesManager;
}

const CardsItemsWidgetDisplay: React.FC<Props> = ({
    projectId,
    initiatives,
    workflowVersionId,
    widget,
    viewOnly,
    showLoadMore,
    loadMore,
    headerBackgroundColor,
    shouldShowGalleryView,
    updateFieldsValuesManager,
}) => {
    const customFieldsManager = useAngularService('customFieldsManager');
    const customTriggerManager = useAngularService('customTriggerManager');

    const { data: allFieldDefinitions, loading: loadingFields } = useAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
        workflowVersionId,
    );

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const { data: workflowVersion, loading: loadingWorkflowVersion } = useAsyncMethod(
        workflowVersionManager,
        'getCachedWorkflowVersionOrGetFromServer',
        workflowVersionId,
    );

    const { loading: loadingVersionCustomTriggers } = useAsyncMethod(
        customTriggerManager,
        'getWorkflowVersionCustomTriggersAndGraph',
        workflowVersionId,
    );

    const [onChange, configuredFields] = useItemFieldWidget(
        workflowVersionId,
        'ITEM_INTERFACE_INNER_ITEMS_WIDGET',
        updateFieldsValuesManager,
        workflowVersion,
        undefined,
        allFieldDefinitions,
        widget?.configuration?.cardFields || [],
        undefined,
    );

    const configurationFields = useWidgetConfigurationFieldsSoftMigration(widget.configuration.cardFields);

    const [shownFieldKeys, filteredShownFields] = useFilterFieldsInWidget(true, configuredFields, configurationFields);

    const onSubmit = useConstantRefCallback((values: FormikValueType, helpers: FormikHelpersType<FormikValueType>) => {
        return onChange(Object.values(values)).then(() => {
            // Reset the form so next submit will only submit the newly touched fields
            helpers.resetForm({});
        });
    });

    return (
        <Formik initialValues={{} as FormikValueType} onSubmit={onSubmit}>
            <FormikHelpers>
                {loadingFields || loadingVersionCustomTriggers || loadingWorkflowVersion ? (
                    <LoaderWrapper>
                        <LoadingCircle large />
                    </LoaderWrapper>
                ) : (
                    <>
                        <CardsListWrapper shouldDisplayCardsOnMobile={shouldShowGalleryView}>
                            {initiatives.map((initiative) => (
                                <CardItemDisplay
                                    key={initiative.id}
                                    projectId={projectId}
                                    fields={filteredShownFields}
                                    onChange={(change) => onChange([change])}
                                    viewOnly={viewOnly}
                                    fieldDisplay={FieldsWidgetDisplayType.LIST}
                                    initiative={initiative}
                                    displayNotUpToDateWarning={false}
                                    shouldDisplayCardsOnMobile={shouldShowGalleryView}
                                    widget={widget}
                                    showEmptyFieldWhenNoInitiative
                                    showModuleOffIndication
                                />
                            ))}
                        </CardsListWrapper>
                        {showLoadMore && (
                            <LoadMoreWrapper>
                                <LoadMoreButton background={headerBackgroundColor} onClick={loadMore}>
                                    Load more
                                </LoadMoreButton>
                            </LoadMoreWrapper>
                        )}
                    </>
                )}
            </FormikHelpers>
        </Formik>
    );
};

export default CardsItemsWidgetDisplay;
