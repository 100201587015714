import type { ColDef } from '@ag-grid-community/core';
import { useMemo } from 'react';

import useMinMaxDateRangeValidation from './useMinMaxDateRangeValidation';
import usePreviewValue from './usePreviewValue';
import type { FieldValidationParams } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';
import getCellEditor from '../utils/getCellEditor';
import getCommonDef from '../utils/getCommonDef';
import getFieldDefinitionDefinition from '../utils/getFieldDefinitionDefinition';
import getOptionsDefinitions from '../utils/getOptionsDefinitions';

import type { SpecialFieldsKey, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import {
    type FieldDefinition,
    type FieldDefinitionKey,
    FieldType,
    FormDefinitionType,
} from '@tonkean/tonkean-entities';

const useFieldDefinitionDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    isEditable: boolean,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
    fieldDefinitions: FieldDefinition[] | undefined,
    fieldsWidth: Record<FieldDefinitionKey, number | undefined> = {},
    validations: FieldValidation<FieldValidationParams>[] | undefined,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    getSpecialFieldsMapping: () => { fieldDefinitionKey: SpecialFieldsKey; fieldDef: ColDef<InitiativeRowData> }[],
    usedFieldDefinitionKeys: FieldDefinitionKey[],
    showOptionsMenu: boolean | undefined,
    hasRowMarker: boolean,
) => {
    const getMinMaxDates = useMinMaxDateRangeValidation();

    const previewValue = usePreviewValue();

    return useMemo(() => {
        const fieldDefinitionsColDef =
            fieldDefinitions?.map((fieldDefinition) => {
                const cellEditor = getCellEditor(fieldDefinition);

                const fieldDefinitionValidation = validations?.find(
                    (validation) => validation.fieldDefinitionKey === fieldDefinition.id,
                );

                const { min, max } = getMinMaxDates(fieldDefinitionValidation);

                const isSearchField =
                    fieldDefinition.fieldType === FieldType.List && fieldDefinition.dropdownSource === 'SEARCH';

                const isManualField = fieldDefinition.type === FormDefinitionType.MANUAL;

                const def: ColDef<InitiativeRowData> = getFieldDefinitionDefinition(
                    fieldDefinition,
                    getCommonDef(commonDef, fieldsWidth, fieldDefinition.id, fieldDefinition),
                    fieldDefinitionKeyToName,
                    enableFilter,
                    isEditable,
                    shouldShowRequiredIndication,
                    isEmphasizeText,
                    filteredColumnIds,
                    isManualField,
                    isSearchField,
                    groupId,
                    min,
                    max,
                    validations,
                    previewValue,
                    cellEditor,
                );
                return { fieldDefinitionKey: fieldDefinition.id, fieldDef: def };
            }) || [];

        const optionsDef: ColDef<InitiativeRowData> = getOptionsDefinitions(isEditable, validations, hasRowMarker);

        const allDefsFields = [...getSpecialFieldsMapping(), ...fieldDefinitionsColDef];

        const filteredAndSortedDefsFields = allDefsFields
            .filter((defsField) => usedFieldDefinitionKeys.includes(defsField.fieldDefinitionKey))
            .sort((defsField1, defsField2) => {
                return (
                    usedFieldDefinitionKeys.indexOf(defsField1.fieldDefinitionKey) -
                    usedFieldDefinitionKeys.indexOf(defsField2.fieldDefinitionKey)
                );
            })
            .map((sortedDefsField) => sortedDefsField.fieldDef);

        return showOptionsMenu ? [optionsDef, ...filteredAndSortedDefsFields] : filteredAndSortedDefsFields;
    }, [
        fieldDefinitions,
        isEditable,
        validations,
        hasRowMarker,
        getSpecialFieldsMapping,
        showOptionsMenu,
        getMinMaxDates,
        commonDef,
        fieldsWidth,
        fieldDefinitionKeyToName,
        enableFilter,
        shouldShowRequiredIndication,
        isEmphasizeText,
        filteredColumnIds,
        groupId,
        previewValue,
        usedFieldDefinitionKeys,
    ]);
};

export default useFieldDefinitionDefinition;
