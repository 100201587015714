import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkerOutlineItemCtrl($scope, $timeout, utils, consts, customTriggerManager, workflowVersionManager) {
    const ctrl = this;

    $scope.data = {
        // Component data.
        groupId: ctrl.groupId,
        workflowVersion: null,
        workflowVersionId: ctrl.workflowVersionId,
        nodeBlock: null, // The nodeBlock is loaded with a $timeout delay. See $onInit.
        nodeParent: ctrl.nodeParent,
        nodeGrandParent: ctrl.nodeGrandParent,
        syncObject: ctrl.syncObject,
        hideNodeDisplayName: ctrl.hideNodeDisplayName,
        selectNode: ctrl.selectNode,

        // Internal data.
        defaultActionName: consts.getDefaultLogicName(),
        isFocused: false,
        previousDisplayName: null,

        // Loading states.
        savingDisplayName: false,

        highlightText: ctrl.highlightText,
        readOnlyMode: true,
        editMode: false,
        triggerIdsToHighlight: ctrl.triggerIdsToHighlight,
        isDependent: false,
    };

    ctrl.$onInit = function () {
        updateWorkflowVersion();

        // We only load the nodeBlock in a $timeout and inside the init, so he will postpone loading its recursive
        // impacts to the next digest loop. This way we do not overload the digest loop with to many iteration
        // (until it ultimately gives up after 10 loops).
        $timeout(() => {
            $scope.data.nodeBlock = ctrl.nodeBlock;

            // Now that we are loaded, we should check if we we're selected.
            // This can happen if we were just created after the user pressed enter.
            // We run in a timeout so that angular has time to create the html for our element (so it can be focused).
            $timeout(() => {
                checkIfSelected();
            });
        });
    };

    ctrl.$onChanges = function (changes) {
        if (
            changes.nodeBlock &&
            changes.nodeBlock.currentValue &&
            $scope.data.nodeBlock !== changes.nodeBlock.currentValue
        ) {
            // We use a time to avoid a bug where angular will run infinite digest cycles.
            $timeout(() => {
                $scope.data.nodeBlock = ctrl.nodeBlock;
                createHtmlDisplayName();
            });
        }
        if (
            changes.nodeParent &&
            changes.nodeParent.currentValue &&
            $scope.data.nodeParent !== changes.nodeParent.currentValue
        ) {
            $scope.data.nodeParent = changes.nodeParent.currentValue;
        }
        if (
            changes.nodeGrandParent &&
            changes.nodeGrandParent.currentValue &&
            $scope.data.nodeGrandParent !== changes.nodeGrandParent.currentValue
        ) {
            $scope.data.nodeGrandParent = changes.nodeGrandParent.currentValue;
        }
        if (changes.workflowVersionId) {
            $scope.workflowVersionId = changes.workflowVersionId.currentValue;
            updateWorkflowVersion();
        }
        if (changes.highlightText) {
            $scope.data.highlightText = ctrl.highlightText;
            createHtmlDisplayName();
        }
        if (changes.triggerIdsToHighlight) {
            $scope.data.triggerIdsToHighlight = ctrl.triggerIdsToHighlight;
            createHtmlDisplayName();
        }
    };

    /**
     * Watch the selectedLogicId on the sync object. If it changes and we we're selected - we should react.
     */
    $scope.$watch('data.syncObject.selectedLogicId', function () {
        checkIfSelected();
    });

    /**
     * Focuses the input.
     */
    $scope.focusInput = function () {
        // Clear node display name on focus if it is default name
        if ($scope.data.nodeBlock.node.displayName === $scope.data.defaultActionName) {
            $scope.data.nodeBlock.node.displayName = '';
        }

        utils.focus(`worker-outline-input-${$scope.data.nodeBlock.node.id}`);
    };

    $scope.onNodeFocused = function () {
        $scope.data.isFocused = true;

        // Save the current display name of the node so we can tell if it has changed (on blur).
        $scope.data.previousDisplayName = $scope.data.nodeBlock.node.displayName;

        // Call the callback for node selection.
        $scope.selectNode($scope.data.nodeBlock);
    };

    $scope.switchToEditMode = function () {
        $scope.data.editMode = true;
        $scope.onNodeFocused();
    };

    $scope.onNodeBlur = function () {
        if (utils.isNullOrEmpty($scope.data.nodeBlock.node.displayName)) {
            $scope.data.nodeBlock.node.displayName = $scope.data.previousDisplayName
                ? $scope.data.previousDisplayName
                : $scope.data.defaultActionName;
            return;
        }

        // Only send the new name to the server if the name has changed.
        if ($scope.data.nodeBlock.node.displayName !== $scope.data.previousDisplayName) {
            // Update the old display name to be the current one.
            $scope.data.previousDisplayName = $scope.data.nodeBlock.node.displayName;

            // Update the server.
            updateDisplayName();
        }

        createHtmlDisplayName();
        $scope.data.editMode = false;
    };

    function createHtmlDisplayName() {
        // If nodeBlock exists
        if ($scope.data.nodeBlock && $scope.data.nodeBlock.node) {
            $scope.data.nodeBlock.node.displayNameHtml = $scope.data.nodeBlock.node.displayName;

            // If this trigger dependent on a filter dependency field
            if (
                $scope.data.triggerIdsToHighlight &&
                $scope.data.triggerIdsToHighlight.length &&
                $scope.data.triggerIdsToHighlight.includes($scope.data.nodeBlock.node.id)
            ) {
                $scope.data.isDependent = true;
            } else {
                $scope.data.isDependent = false;
            }
        }
    }

    $scope.onOutlineItemInputKeyDown = function (event) {
        if (event && (event.code === 'Enter' || event.keyCode === 13)) {
            // If it's an enter key press.
            event.preventDefault();
            // Create a new line.
            $scope.createNewNode($scope.data.nodeParent);
        } else if ((event.key === 'Tab' || event.keyCode === 9) && !event.shiftKey) {
            // Handle tab (without shift).
            event.preventDefault();
            $scope.tabNodeIn($scope.data.nodeBlock, $scope.data.nodeParent);
        } else if ((event.key === 'Tab' || event.keyCode === 9) && event.shiftKey) {
            // Handle tab (with shift).
            event.preventDefault();
            $scope.tabNodeOut($scope.data.nodeBlock, $scope.data.nodeParent, $scope.data.nodeGrandParent);
        } else if (event.code === 'ArrowUp' || event.keyCode === 38) {
            // Handle arrow up.
            event.preventDefault();
            $scope.moveInTree(true, $scope.data.nodeBlock.node.id);
        } else if (event.code === 'ArrowDown' || event.keyCode === 40) {
            // Handle arrow down.
            event.preventDefault();
            $scope.moveInTree(false, $scope.data.nodeBlock.node.id);
        }
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.selectNode from an inner node.
     */
    $scope.selectNode = function (node) {
        ctrl.selectNode({ node });
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.createNewNode from an inner node.
     */
    $scope.createNewNode = function (parentNode) {
        ctrl.createNewNode({ parentNode });
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.updateCurrentNodeData from an inner node.
     */
    $scope.updateCurrentNodeData = function (currentNode, nodeParent, nodeGrandParent) {
        ctrl.updateCurrentNodeData({
            currentNode,
            nodeParent,
            nodeGrandParent,
        });
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.tabNodeIn from an inner node.
     */
    $scope.tabNodeIn = function (nodeBlock, nodeParent) {
        ctrl.tabNodeIn({ nodeBlock, nodeParent });
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.tabNodeOut from an inner node.
     */
    $scope.tabNodeOut = function (nodeBlock, nodeParent, nodeGrandParent) {
        ctrl.tabNodeOut({ nodeBlock, nodeParent, nodeGrandParent });
    };

    /**
     * A callback passed recursively down the tree. This enables us to call ctrl.moveInTree from an inner node.
     */
    $scope.moveInTree = function (isUp, currentNodeId) {
        ctrl.moveInTree({ isUp, currentNodeId });
    };

    function updateWorkflowVersion() {
        $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
    }

    /**
     * Checks if the current node block is the selected one, so it can focus itself.
     */
    function checkIfSelected() {
        // If the current selected id equals to our id (we are selected!).
        if (
            $scope.data.nodeBlock &&
            $scope.data.nodeBlock.node &&
            $scope.data.syncObject.selectedLogicId &&
            $scope.data.syncObject.selectedLogicId === $scope.data.nodeBlock.node.id
        ) {
            // Update about current node data.
            ctrl.updateCurrentNodeData({
                currentNode: $scope.data.nodeBlock,
                nodeParent: $scope.data.nodeParent,
                nodeGrandParent: $scope.data.nodeGrandParent,
            });

            // Focus ourselves.
            $scope.focusInput();
        } else {
            // If we're not selected, we shouldn't be focused.
            $scope.data.isFocused = false;
        }
    }

    /**
     * Sends the node's display name to the server.
     */
    function updateDisplayName() {
        $scope.data.savingDisplayName = true;

        customTriggerManager
            .updateCustomTriggerDisplayName(
                $scope.data.groupId,
                $scope.data.nodeBlock.node.id,
                $scope.data.nodeBlock.node.displayName,
            )
            .catch(() => {
                $scope.$emit('alert', {
                    type: 'error',
                    msg: `There was an error updating the following name: "${$scope.data.nodeBlock.node.displayName}"`,
                });
            })
            .finally(() => {
                $scope.data.savingDisplayName = false;
            });
    }
}

export default angular.module('tonkean.app').controller('WorkerOutlineItemCtrl', lateConstructController(WorkerOutlineItemCtrl));
