import React from 'react';

import GenericDatasourceInputSourceSelection from './dataSource/GenericDatasourceInputSourceSelection';
import type { InputSourceContentComponentProps } from '../InputSourceContentComponentProps';

import { IntegrationType } from '@tonkean/tonkean-entities';

const EMAIL_INTEGRATION_TYPES = [IntegrationType.EMAILWEBHOOK];

const EmailInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = (props) => {
    return <GenericDatasourceInputSourceSelection filterByIntegrationType={EMAIL_INTEGRATION_TYPES} {...props} />;
};

export default EmailInputSourceSelectionContent;
