<div class="initiatives-metrics-list" ng-class="{ 'mod-list': listMode }">
    <div
        class="flex relative initiatives-metrics-list-content"
        ng-class="{
            'show-add-on-hover': cfm.selectedTargetFieldsMap[data.workflowVersionId][fieldDefinitionTargetType].length,
            'no-group-mode': !groupId,
            'mod-open flex-wrap': !collapsed
        }"
    >
        <!--sample fields-->
        <div
            class="flex show-edit-when-hover relative"
            ng-if="
                !groupId ||
                cfm.selectedVisibleTargetFieldsMap[data.workflowVersionId][fieldDefinitionTargetType].length === 0
            "
            ng-init="sampleMetrics = pm.groupsMap[groupId].metadata && pm.groupsMap[groupId].metadata.usecase && data.sampleMetrics[pm.groupsMap[groupId].metadata.usecase] ? data.sampleMetrics[pm.groupsMap[groupId].metadata.usecase] : data.sampleMetrics['OTHER']"
            ng-hide="data.loadAllFields"
        >
            <div
                class="initiatives-metrics-box-item clearfix cursor-default"
                ng-repeat="metric in (sampleMetrics | limitTo: ($root.isMobile ? 1 : 3))"
            >
                <div class="common-color-grey opacity-50 common-size-xxxxs">{{ metric.fieldName }}</div>
                <!--<div class="flex-inline mod-vmiddle flex-wrap margin-top">-->
                <div class="initiatives-metrics-content relative flex">
                    <div class="initiatives-metrics-value margin-right-xs common-color-grey text-center opacity-50">
                        {{ metric.value }}
                    </div>
                    <div class="common-size-xxxxxs margin-left-xs margin-top" ng-if="metric.change">
                        <div
                            ng-class="{
                                'common-color-danger': metric.negative,
                                'common-color-success': metric.positive
                            }"
                        >
                            <i class="fa fa-long-arrow-{{ metric.trend }}"></i>
                            {{ metric.change }}%
                        </div>
                        <div class="common-size-xxxxxs common-color-grey">Vs. last 7 days</div>
                    </div>
                </div>
            </div>
            <div class="initiatives-metrics-box-item clearfix cursor-default">
                <span class="tag-sample">SAMPLE</span>
            </div>
        </div>

        <!--actual fields-->
        <div
            class="initiatives-metrics-box-item mod-hover clearfix show-edit-when-hover-visibility relative"
            data-automation="metrics-bar-global-field"
            ng-repeat="definition in (!data.loadAllFields ? cfm.selectedVisibleTargetFieldsMap[data.workflowVersionId][fieldDefinitionTargetType] : fieldDefinitionTargetType === 'COLUMN' ? cfm.selectedColumnFieldsMap[data.workflowVersionId] : cfm.selectedGlobalFieldsMap[data.workflowVersionId])"
            ng-if="!definition.systemUtilized && !reloadDefinition[definition.id]"
        >
            <div ng-init="metric = getFieldByDefinition(definition.id, isDraft)" class="common-size-xxxxs flex-vmiddle">
                <div data-automation="metrics-bar-field-name" class="common-color-grey flex-grow fs-censored">
                    {{ definition.name }}
                    <i
                        class="fa fa-info-circle"
                        ng-if="definition.description && definition.description.length"
                        ng-class="{
                            'metric-tooltip-outside-active': data.currentMetricTooltipIsOpen === definition.id
                        }"
                        uib-tooltip-html="definition.description"
                        tooltip-append-to-body="true"
                        tooltip-placement="top auto"
                        tooltip-class="tooltip-cursor-enable"
                        tooltip-trigger="none"
                        tooltip-is-open="data.currentMetricTooltipIsOpen === definition.id"
                        ng-click="setMetricTooltipIsOpen(definition.id, data.currentMetricTooltipIsOpen  === definition.id, true)"
                        click-outside="setMetricTooltipIsOpen(definition.id, false, true)"
                        outside-activators="metric-tooltip-outside-active"
                        outside-if-not="metric-tooltip-outside-active,tooltip-cursor-enable"
                        ng-mouseenter="setMetricTooltipIsOpen(definition.id, true)"
                        ng-mouseleave="setMetricTooltipIsOpen(definition.id, false)"
                    ></i>
                </div>
                <div class="edit-button" ng-if="!viewOnly" uib-dropdown>
                    <span
                        class="svg-icon-smd svg-icon-hover-dark-grey pointer"
                        uib-dropdown-toggle
                        data-automation="metrics-bar-kebab-menu"
                    >
                        <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                    </span>
                    <ul uib-dropdown-menu class="metrics-bar-menu" ng-class="{ 'dropdown-menu-right': listMode }">
                        <li
                            ng-if="
                                data.targetType === TONKEAN_ENTITY_TYPE.GROUP &&
                                definition.type === 'TNK_COLUMN_AGGREGATE'
                            "
                        >
                            <a
                                ng-click="onUserApplyTracksFilter({queryDefinition: definition.definition.aggregateQueryDefinition})"
                            >
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">View relevant Items</span>
                            </a>
                        </li>
                        <li
                            ng-if="
                                data.targetType === TONKEAN_ENTITY_TYPE.GROUP &&
                                definition.type === 'TNK_COLUMN_AGGREGATE'
                            "
                            class="divider"
                        ></li>
                        <li ng-if="!$first && data.targetType === TONKEAN_ENTITY_TYPE.GROUP">
                            <a ng-click="moveMetricLeft(definition.id)">
                                <i class="svg-icon-sm margin-right common-color-grey">
                                    <tnk-icon src="/images/icons/arrow-left.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Move left</span>
                            </a>
                        </li>
                        <li ng-if="!$last && data.targetType === TONKEAN_ENTITY_TYPE.GROUP">
                            <a ng-click="moveMetricRight(definition.id)">
                                <i class="svg-icon-sm margin-right common-color-grey">
                                    <tnk-icon src="/images/icons/arrow-right.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Move right</span>
                            </a>
                        </li>
                        <li>
                            <a ng-click="hideMetric(definition.id)">
                                <i class="svg-icon-sm svg-icon-align-text-top margin-right">
                                    <tnk-icon src="/images/icons/eye-crossed-o.svg"></tnk-icon>
                                </i>
                                <span class="common-color-light-grey2">Hide</span>
                            </a>
                        </li>
                        <li>
                            <a ng-click="openFieldViewModal(metric, target, 'SHOW_FULL_VIEW')">
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="/images/icons/text.svg"></tnk-icon>
                                </i>
                                <span ng-class="metric == null ? 'common-disabled' : 'common-color-light-grey2'">
                                    Show more..
                                </span>
                            </a>
                        </li>
                        <li ng-if="metric.fieldDefinition.definition.aggregationType !== 'Terms'">
                            <a ng-click="openFieldChartModal(metric, target)">
                                <i class="svg-icon-sm margin-right">
                                    <tnk-icon src="/images/icons/small-graph.svg"></tnk-icon>
                                </i>
                                <span>View history</span>
                            </a>
                        </li>
                        <li
                            ng-if="
                                metric.fieldDefinition.targetType === 'GLOBAL' &&
                                metric.fieldDefinition.type !== 'MANUAL'
                            "
                        >
                            <a
                                data-automation="metrics-bar-refresh-field-button"
                                ng-click="refreshField(metric, definition.id)"
                            >
                                <span class="svg-icon-sm margin-right"><i class="refresh-icon fa fa-refresh"></i></span>
                                <span>Refresh field</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="initiatives-metrics-content flex">
                <div
                    ng-if="!editingField[definition.id]"
                    class="flex flex-vmiddle initiatives-metrics-value margin-right-xs text-center flex-no-shrink"
                >
                    <!-- External link icon -->
                    <a
                        ng-if="metric.value && metric.value.length && metric.hasUrlLink"
                        ng-href="{{ metric.value }}"
                        target="_blank"
                        class="common-size-xxs"
                    >
                        <i class="fa fa-external-link-square margin-right-xs"></i>
                    </a>

                    <!-- Show avatars if field of type users -->
                    <tnk-track-field-user
                        ng-if="definition.displayFormat === 'USER' && metric.value"
                        field="{ value: metric.displayValue || metric.value }"
                        data-automation="metrics-bar-field-content"
                        view-only-mode="true"
                        anonymous="false"
                    ></tnk-track-field-user>

                    <!-- Show the string value if not of type users -->
                    <div
                        ng-if="definition.displayFormat !== 'USER' && metric.value"
                        class="fs-censored metric-value common-ellipsis line-height-normal"
                        data-automation="metrics-bar-field-content"
                        ng-style="metric.style"
                    >
                        {{ metric.displayValue || metric.value }}
                    </div>

                    <i class="common-size margin-left margin-right-xs text-center" ng-if="!metric.value">-</i>
                    <!-- Edit pencil ONLY IF manual FIELD-->
                    <i
                        data-automation="metrics-bar-edit-field-instance"
                        class="svg-icon margin-right-xs common-color-grey edit-button"
                        ng-if="
                            ((!metric && definition.type === 'MANUAL') || metric.evaluatedUpdateable) &&
                            !viewOnly &&
                            !definition.idRelationField
                        "
                        ng-click="metric ? editingField[definition.id] = true : createFieldInstance(definition)"
                    >
                        <tnk-icon src="/images/icons/pencil-o.svg"></tnk-icon>

                        <span class="loading-small" ng-if="loadingDefinition[definition.id]"></span>
                    </i>
                </div>

                <!-- Inline edit ONLY IF manual field -->
                <div
                    class="flex flex-col flex-no-shrink margin-top initiative-metrics-inline-edit"
                    ng-if="metric && editingField[definition.id] && !viewOnly && !definition.idRelationField"
                    ng-keydown="onMetricEditKeyDown($event, metric)"
                >
                    <tnk-update-field-value
                        field="metric"
                        field-definition="metric.fieldDefinition"
                        focus="true"
                        append-date-picker-to-body="true"
                        target-id="target.id"
                        on-saved="updateMetricValueSaved(updatedField)"
                        inline-update="true"
                        group="pm.groupsMap[groupId]"
                    ></tnk-update-field-value>
                    <a
                        class="common-size-xxxxs margin-left-xxs margin-top-xs pull-left"
                        ng-click="editingField[definition.id] = false"
                    >
                        Cancel
                    </a>
                </div>

                <div
                    class="common-size-xxxxxs margin-left-xs margin-top pointer flex-no-shrink"
                    ng-if="metric.change > 0 || metric.change < 0"
                    ng-click="modal.openFieldViewModal(metric, target)"
                >
                    <div ng-class="{ 'common-color-danger': metric.negative, 'common-color-success': metric.positive }">
                        <i class="fa fa-long-arrow-{{ metric.trend }}"></i>
                        {{ metric.change * 100 | numberAbs: 2 }}%
                    </div>
                    <div class="common-size-xxxxxs common-color-grey">
                        Vs. {{ fieldCompareTimeframeLabelsMap[metric.fieldDefinition.compareTimeframe] || 'yesterday' }}
                    </div>
                </div>
                <!-- push the fix integration text to the right -->
                <div class="flex flex-grow mod-align-center">
                    <div class="loading" data-automation="tnk-metrics-bar-template-loading" ng-if="refreshingFields[definition.id]"></div>
                </div>
            </div>

            <!-- Configure button -->
            <div class="absolute-below flex metrics-configure-container" ng-if="!definition.isConfigured && !viewOnly">
                <button
                    class="btn btn-primary tracks-header-configure-btn flex-grow flex-vmiddle mod-justify-center"
                    type="button"
                    ng-click="openConfigureManualMetricModal(definition)"
                >
                    <i class="svg-icon-xs svg-icon-vmiddle common-color-white margin-right-xxs">
                        <tnk-icon src="/images/icons/settings.svg"></tnk-icon>
                    </i>
                    <div class="common-size-xxxxs line-height-normal">Configure</div>
                </button>
            </div>
        </div>

        <div ng-if="listMode && !data.loadAllFields" class="text-center padding-normal-sm common-width-full">
            <span ng-click="data.loadAllFields = true" class="btn btn-secondary btn-sm">Show All</span>
        </div>

        <div
            ng-if="!listMode && !collapsed"
            class="initiatives-metrics-box-item flex mod-align-center"
            ng-click="collapseMetrics()"
        >
            <span class="common-tag-full padding-normal-xs pointer margin-top-lg">Less</span>
        </div>

        <div class="initiatives-metrics-box-more pointer" ng-click="expandMetrics()" ng-if="!listMode && collapsed">
            <span class="common-tag-full padding-normal-xs margin-top-lg">More</span>
        </div>

        <div ng-if="!listMode && collapsed" class="flex-grow initiatives-metrics-box-space" style="z-index: 2"></div>
    </div>
    <tnk-field-chart-modal
        ng-if="data.fieldChartModalOpen"
        open="data.fieldChartModalOpen"
        on-close="(closeFieldChartModal)"
        field-instance="data.currentFieldInstance"
        target="data.target"
    ></tnk-field-chart-modal>
</div>
