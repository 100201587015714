import PlaceholderReplacer from './PlaceholderReplacer';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPlaceholderReplacer',
        reactToAngular(PlaceholderReplacer, ['groupId', 'workflowVersionId', 'initialValue', 'onChange']),
    );
