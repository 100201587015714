import type { CSSObject } from 'styled-components';
import styled, { css } from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const UpdateFieldWrapper = styled.div<{
    rangeStyle?: CSSObject;
    hasError?: boolean;
    editingEnabledInWidget?: boolean;
    isInGalleryView?: boolean;
    isInTableView?: boolean;
}>`
    width: 100%;

    input,
    textarea {
        text-overflow: ellipsis;

        ${({ editingEnabledInWidget, isInGalleryView, isInTableView }) =>
            !editingEnabledInWidget &&
            css`
                background-color: transparent;
                border: none;
                font-size: ${isInTableView ? FontSize.LARGE_16 : FontSize.XXXLARGE_22} !important;
                font-weight: 400 !important;

                ${(isInGalleryView || isInTableView) &&
                css`
                    padding: 0 !important;
                `}
            `}
    }

    ${({ editingEnabledInWidget, isInGalleryView, isInTableView }) =>
        !editingEnabledInWidget &&
        css`
            // This selector selects all the dropdown of type select without the status picker

            [data-automation='tnk-select-control'] {
                background-color: transparent;
                border: none;

                ${(isInGalleryView || isInTableView) &&
                css`
                    padding: 0;
                `}
            }

            // This selector selects all the dropdown of type select without the status picker
            [class$='-singleValue'] {
                color: rgb(52, 57, 62);
                overflow: visible;

                ${(isInGalleryView || isInTableView) &&
                css`
                    margin-left: 0px;
                `}
            }

            [class$='-control'] {
                background-color: transparent;
                border: none;
                font-size: ${isInTableView ? FontSize.LARGE_16 : FontSize.XXXLARGE_22};
                font-weight: 400;
                overflow: hidden;

                ${(isInGalleryView || isInTableView) &&
                css`
                    padding: 0;
                    margin-left: 0;
                `}
            }

            [data-automation='people-selector-custom-value-main-container'] {
                background-color: transparent;

                ${(isInGalleryView || isInTableView) &&
                css`
                    padding: 0;
                `}
            }

            [data-automation='people-selector-custom-value-title-container'] {
                overflow: visible;
            }

            [data-automation='people-selector-custom-value-title'] {
                font-size: ${isInTableView ? FontSize.LARGE_16 : FontSize.XXXLARGE_22};
                font-weight: 400;
                overflow: visible;
            }

            [data-automation='update-field-long-string'] {
                font-size: ${isInTableView ? FontSize.LARGE_16 : FontSize.XXXLARGE_22};
                line-height: 28px;
            }
        `}

    ${({ rangeStyle }) =>
        rangeStyle &&
        css`
            input,
            textarea,
              // For list fields
            [data-automation='tnk-select-control'] {
                ${{ ...rangeStyle }};
            }

            // For list fields selected value text
            [class$='-singleValue'] {
                color: ${rangeStyle.color};
            }
        `}

  ${({ hasError }) =>
        hasError &&
        css`
            input,
            textarea,
            [data-automation='tnk-select-control'],
            [data-automation='tnk-simple-select-list'],
            [class='react-datepicker__input-container'] > div {
                border: 1px solid ${Theme.colors.error};
            }
        `}
`;

export default UpdateFieldWrapper;
