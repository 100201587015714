import React from 'react';
import { DaysHoursDiffFilter } from '@tonkean/shared-services';
import InboxItemActions from '../../../actions/InboxItemActions';
import { TrackSetReminderDropdown } from '@tonkean/infrastructure';
import DefaultInboxReason from './reasonComponents/DefaultInboxReason';
import DateInboxReason from './reasonComponents/DateInboxReason';
import CustomTriggerInboxReason from './reasonComponents/CustomTriggerInboxReason';
import FieldRangeChangedInboxReason from './reasonComponents/FieldRangeChangedInboxReason';
import ExternalChangedInboxReason from './reasonComponents/ExternalChangedInboxReason';
import PingNowInboxReason from './reasonComponents/PingNowInboxReason';
import ManualInboxReason from './reasonComponents/ManualInboxReason';

export default class InboxItem extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.listener = null;

        this.store = props.inboxItemStore;
        this.trackHelper = props.trackHelper;
        this.projectManager = props.projectManager;
        this.tonkeanService = props.tonkeanService;

        // Initialize the state of the track list item.
        this.state = this.store.getNewState(this.props.id);
    }

    /**
     * Happens once, when the component is mounted to the page.
     * Registers our onStoreUpdated function as a listener on the store.
     * Also saves the returned remove listener, so we can remove the listener we've just added.
     */
    componentDidMount() {
        this.listener = this.store.addListener(this.onStoreUpdated.bind(this));
    }

    /**
     * Happens once, when the component is unmounted from the page.
     * Removed the listener from the store, using our removeListener (if it was initialized).
     */
    componentWillUnmount() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    /**
     * Registered as a listener to the store. Triggered whenever the store emits an update.
     */
    onStoreUpdated(id) {
        // If we didn't get an id (means everyone should update) or if the id is ours - update the state (causes re-render).
        // Alternatively, if we are a linked item and the real track's id is the given id - we should also render.
        if (this.store.shouldItemUpdate(this.props.id, id)) {
            this.setState(this.store.getState(this.props.id));
        }
    }

    openUpdateStatusModal() {
        this.props.modalUtils.openUpdateInitiativeStateModal(this.state.initiativeId, true);
    }

    openInitiativeModal() {
        this.props.modalUtils.openViewInitiative(this.state.initiativeId);
    }

    markAsRead() {
        InboxItemActions.toggleLoading(this.props.id, true);

        this.tonkeanService
            .markGatherHistoryRead(this.state.initiativeId, true)
            .then(() => this.tonkeanService.getGatherHistoryById(this.props.id))
            .then((gatherHistory) => {
                this.props.botHistoryHelper.setItem(gatherHistory);
            })
            .finally(() => {
                InboxItemActions.toggleLoading(this.props.id, false);
            });
    }

    toggleAskLaterDropdown() {
        InboxItemActions.toggleAskLaterDropdown(this.props.id, !this.state.askLaterDropdownOpen);
    }

    renderReason(item, initiative) {
        let reasonElement;

        if (item.isManually) {
            reasonElement = (
                <PingNowInboxReason
                    item={item}
                    initiative={initiative}
                    openInitiativeModal={this.openInitiativeModal.bind(this)}
                />
            );
        }

        switch (item.reason) {
            case 'DUE_DATE':
            case 'DUE_DATE_EXACT':
            case 'DUE_DATE_FUTURE':
            case 'DUE_DATE_PAST':
                reasonElement = (
                    <DateInboxReason
                        item={item}
                        initiative={initiative}
                        fieldName="dueDate"
                        fieldLabel="Due Date"
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
                break;
            case 'ETA':
            case 'ETA_EXACT':
            case 'ETA_FUTURE':
            case 'ETA_PAST':
                reasonElement = (
                    <DateInboxReason
                        item={item}
                        initiative={initiative}
                        fieldName="eta"
                        fieldLabel="ETA"
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
                break;

            case 'CUSTOM_TRIGGER':
                reasonElement = (
                    <CustomTriggerInboxReason
                        item={item}
                        initiative={initiative}
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
                break;

            case 'FIELD_VALUE_RANGE_CHANGED':
                reasonElement = (
                    <FieldRangeChangedInboxReason
                        item={item}
                        initiative={initiative}
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
                break;
            case 'EXTERNAL_CHANGED':
                reasonElement = (
                    <ExternalChangedInboxReason
                        item={item}
                        initiative={initiative}
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
                break;

            case 'MANUAL':
                reasonElement = (
                    <ManualInboxReason
                        item={item}
                        initiative={initiative}
                        as={this.props.authenticationService}
                        openInitiativeModal={this.openInitiativeModal.bind(this)}
                    />
                );
        }

        // In case we for some reason failed to provide a reason specific template, provide a default one.
        return (
            reasonElement || (
                <DefaultInboxReason initiative={initiative} openInitiativeModal={this.openInitiativeModal.bind(this)} />
            )
        );
    }

    /**
     * The main render function
     */
    render() {
        // Get fresh initiative from cache.
        const item = this.props.botHistoryHelper.getItem(this.props.id);
        const initiative = this.trackHelper.getInitiativeFromCache(this.state.initiativeId);
        if (!initiative) {
            return <></>;
        }

        let classes = 'inbox-item relative';

        const answered = item.answers && item.answers.length;
        if (answered || this.state.isLoading) {
            classes += ' mod-disabled';
        }

        return (
            <div id={`inbox-item-${this.props.id}`} className={classes}>
                <div className="inbox-item-content">
                    <div className="common-size-xxxxs">{DaysHoursDiffFilter(item.created)}</div>
                    {this.renderReason(item, initiative)}
                </div>

                <div className="digest-card-buttons mod-tall flex common-color-light-grey2">
                    <button className="btn btn-no-border flex-grow" onClick={this.openUpdateStatusModal.bind(this)}>
                        Update
                    </button>
                    <button
                        className="btn btn-no-border flex-grow open relative"
                        onClick={this.toggleAskLaterDropdown.bind(this)}
                    >
                        <span>Ask me later</span>
                        <TrackSetReminderDropdown
                            isOpen={this.state.askLaterDropdownOpen}
                            id={this.props.id}
                            realTrack={initiative}
                            editorId="inbox"
                            currentUserId={this.props.authenticationService.currentUser?.id}
                            modalUtils={this.props.modalUtils}
                            trackHelper={this.trackHelper}
                            tonkeanUtils={this.props.tonkeanUtils}
                            callerForAnalyticsForAskForUpdates="Bot pane next gather dropdown"
                            callerForAnalytics="Bot pane next gather dropdown"
                        />
                    </button>
                    <button className="btn btn-no-border flex-grow relative" onClick={this.markAsRead.bind(this)}>
                        <span>👍 Got it</span>
                    </button>
                </div>

                {this.state.isLoading && (
                    <i className="loading-medium absolute-top margin-auto-right-left margin-top-lg opacity-40" />
                )}
            </div>
        );
    }
}
