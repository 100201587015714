<div>
    <!-- Title -->
    <div class="common-title-xxs">Attachments</div>

    <!-- Subtitle -->
    <div class="common-subtitle">Add file attachments from the connected data sources to the sent e-mail</div>

    <!-- Attachments -->
    <div ng-repeat="attachment in data.attachments" class="margin-top-xs">
        <!-- Worker file selection -->
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogicId }}"
            file-source-type="{{ attachment.fileSourceType }}"
            storage-integration-id="{{ attachment.storageIntegrationId }}"
            storage-integration-action-id="{{ attachment.storageIntegrationActionId }}"
            url-expression="attachment.url"
            should-display-delete-sign="true"
            extended-match-configuration="attachment.extendedMatchConfiguration"
            worker-static-asset-id="{{ attachment.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ attachment.workerStaticAssetDisplayName }}"
            parameters-values="attachment.parametersValues"
            preview-evaluation-source="data.previewEvaluationSource"
            on-delete-sign-clicked="onDeleteSignClicked($index)"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    attachment,
                    storageIntegrationActionId
                )
            "
            attachment-index="$index"
        ></tnk-worker-file-selection>

        <div
            ng-if="
                data.validationObject.invalidAttachmentIndex === $index &&
                data.validationObject.invalidAttachmentMessage
            "
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.invalidAttachmentMessage }}
        </div>
    </div>

    <!-- Add another attachment -->
    <div>
        <a ng-click="addAttachment()" class="margin-top-xs">+ Add</a>
    </div>
</div>
