import deleteGroupModalTemplate from './deleteGroupModal.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

function PrivateGroupModalCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    $state,
    $document,
    $timeout,
    modalUtils,
    projectManager,
    groupManager,
    groupInfoManager,
    groupPermissions,
    group,
    tab,
    dontRedirect,
    workflowVersionManager,
    syncConfigCacheManager,
    hideListName,
    tonkeanService
) {
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    // The different tabs ids.
    $scope.tabs = {
        settings: 'settings',
        states: 'states',
        innerTracksTemplate: 'innerTracksTemplate',
        activity: 'activity,',
    };

    $scope.groupType = {public: 'public', private: 'private'};
    $scope.viewType = {noView: 'NoView', integration: 'Integration', custom: 'Custom'};
    $scope.selectedTab = $scope.tabs[tab] || $scope.tabs.settings; // Set the settings tabs as selected by default.

    $scope.data = {
        group,
        groupId: group ? group.id : null,
        isReadonly: calculateIsReadOnly(group),
        isValid: true,
        editGroupControlObject: {},
        defaultManualSyncOwners: [],
        innerTracksTemplate: null,
        activityActorSelectionPopoverOpen: false,

        selectedActorPeopleToFilterOn: [],
        workerAuditLogsControlObject: {},
        hideListName,
        enableDuplicationLoading: false,
        showMatchedItemsExistInModuleError: false
    };

    /**
     * Occurs once the person added.
     */
    $scope.onActorPersonAdded = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    /**
     * Occurs once the person removed.
     */
    $scope.onActorPersonRemoved = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    /**
     * Opens the activity actor person selection popover.
     */
    $scope.openActivityActorPersonSelection = function () {
        $scope.data.activityActorSelectionPopoverOpen = true;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-container'));

        ngContainer.append(ngPopoverElement);

        // This is important to make the popover show right after the first click, and not have to double click the link for it to show.
        // Our theory here is that appending the element of the popover requires a digest loop to be rendered, and by calling timeout
        // we trigger such digest loop and the popover gets displayed.
        $timeout(() => {
        });
    };

    /**
     * Closes the popover for the actor selection.
     */
    $scope.resetActivityActorSelectionPopover = function () {
        $scope.data.activityActorSelectionPopoverOpen = false;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-parent'));

        ngContainer.append(ngPopoverElement);
    };

    $scope.init = function () {
        // Get the group, so we're sure the settings are up to date.
        groupInfoManager.getGroupById($scope.data.groupId, true).then((updatedGroup) => {
            $scope.data.group = updatedGroup;

            // If the group is being saved, start polling until the save is done.
            if ($scope.data.group.updateStartTime) {
                groupManager.pollForGroupUpdateCompletion($scope.data.group);
            }
        });

        if (group && group.metadata && group.metadata.defaultManualOwner) {
            $scope.data.defaultManualSyncOwners = [group.metadata.defaultManualOwner];
        }

        // If this is readonly mode prevent all clicks on the content element and its children.
        if ($scope.data.isReadonly) {
            const blockAction = function (event) {
                if (!event.target.classList.contains('dont-block') && $scope.data.isReadonly) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    event.preventDefault();
                }
            };
            let element;
            $document.ready(() => {
                element = document.querySelector('#private-group-modal-content');
                // Block both mousedown and click events to capture drag events as well.
                // Third parameter means we add the listener to the capture phase which happens before the bubbling phase.
                // This means that this listener would be activated before any other/child listeners, so we can intercept them all.
                element.addEventListener('mousedown', blockAction, true);
                element.addEventListener('touchstart', blockAction, true);
                element.addEventListener('click', blockAction, true);
            });
            $scope.$on('$destroy', () => {
                if (element) {
                    element.removeEventListener('mousedown', blockAction, true);
                    element.removeEventListener('touchstart', blockAction, true);
                    element.removeEventListener('click', blockAction, true);
                }
            });
        }
    };

    // Watch the updateStartTime of the group. If it is changed we should recalculate the readonly property.
    $scope.$watch('pm.groupsMap[data.groupId].updateStartTime', () => {
        $scope.data.group = $scope.pm.groupsMap[$scope.data.groupId];
        $scope.data.isReadonly = calculateIsReadOnly($scope.pm.groupsMap[$scope.data.groupId]);
    });

    $scope.selectTab = function (tab) {
        $scope.selectedTab = tab;
    };

    /**
     * Occurs once there is a change in the inner tracks template object.
     */
    $scope.onInnerTracksTemplateConfigChanged = function (innerTracksTemplate) {
        $scope.data.innerTracksTemplate = innerTracksTemplate;
    };

    $scope.submit = function () {
        // Only do something if the form is valid.
        if ($scope.privateGroupForm.$valid) {
            const isCreate = !$scope.data.group;

            // Call the analytics.
            const eventAction = isCreate ? 'Create' : 'Edit';
            analyticsWrapper.track(`${eventAction} ${$scope.data.type} list`, {
                category: 'Create List Modal',
                label: $scope.data.notificationType,
            });

            // Saving the default owner
            if (!$scope.data.group.metadata) {
                $scope.data.group.metadata = {};
            }
            if ($scope.data.defaultManualSyncOwners.length) {
                $scope.data.group.metadata.defaultManualOwner = {
                    id: $scope.data.defaultManualSyncOwners[0].id,
                    name: $scope.data.defaultManualSyncOwners[0].name,
                    email: $scope.data.defaultManualSyncOwners[0].email,
                    avatarUri: $scope.data.defaultManualSyncOwners[0].avatarUri,
                };
            } else {
                $scope.data.group.metadata.defaultManualOwner = null;
            }

            // Save the group's settings.
            // The third parameter asks to do the submit async.
            $scope.data.editGroupControlObject
                .submit(null, null, true)
                .then((group) => {
                    // Close the modal.
                    $uibModalInstance.close();

                    // Go to group's page.
                    if (!dontRedirect) {
                        $state.go(
                            'product.board',
                            {
                                filter: 'all',
                                g: group.dashboardHidden ? null : group.id,
                            },
                            {location: 'replace'},
                        );
                    }
                })
                .catch((error) => {
                    if (error !== 'dismissed') {
                        $scope.$emit('alert', {type: 'danger', msg: `Failed saving list ${group.name}`});
                    }

                    $scope.loading = false;
                });
        }
    };

    $scope.removeGroupModal = function () {
        analyticsWrapper.track('Delete list clicked', {category: 'Create List Modal'});

        modalUtils
            .open(
                {
                    backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                    template: deleteGroupModalTemplate,
                    controller: 'DeleteGroupModalCtrl',
                    windowClass: 'mod-warning',
                    resolve: {
                        group() {
                            return $scope.data.group;
                        },
                    },
                },
                'removeGroupModal',
            )
            .result.then(function () {
            $scope.$dismiss();
        });
    };

    $scope.openDuplicateGroup = async function () {
        try {
            $scope.data.enableDuplicationLoading = true;
            const isDuplicateGroupAllowed = (await tonkeanService.getIsDuplicateGroupAllowed($scope.data.groupId)).isDuplicateGroupAllowed;
            $scope.data.enableDuplicationLoading = false;
            if (!isDuplicateGroupAllowed) {
                $scope.data.showMatchedItemsExistInModuleError = true;
                return;
            }

            analyticsWrapper.track('Duplicate list clicked', {category: 'Create List Modal'});
            modalUtils.openSetupGroupModal($scope.data.group, true);
            $scope.$dismiss();
        } catch (error) {
            $scope.$emit('alert', {type: 'danger', msg: 'Failed to get duplicate group allowed status. Please try again.'});
            $scope.data.enableDuplicationLoading = false;
        }
    };

    $scope.closeTemplateModal = () => {
        $scope.data.isTemplateModalOpen = false;
    };

    $scope.onTemplateModalError = (error) => {
        $rootScope.$emit('alert', {type: 'danger', msg: error});
        $scope.closeTemplateModal();
    };

    $scope.onOwnersUpdated = function () {
        $scope.data.isReadonly = calculateIsReadOnly($scope.pm.groupsMap[$scope.data.groupId]);
    };

    function calculateIsReadOnly(group) {
        const isReadonly = !(groupPermissions.currentUserIsOwnerMap[group.id] || projectManager.isOwner);
        return isReadonly || (group && group.updateStartTime);
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('PrivateGroupModalCtrl', PrivateGroupModalCtrl);
