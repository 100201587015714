import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function UipathCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        definition: ctrl.definition,
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onFolderIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.folderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onReleaseKeyTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.releaseKeyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStrategyTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.strategyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onInputArgumentsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.inputArgumentsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('UipathCustomActionsDefinitionCtrl', lateConstructController(UipathCustomActionsDefinitionCtrl));
