export const FORM_PRIMARY_COLORS = {
    blue: '#2F84DC',
    yellow: '#F0CA27',
    purple: '#6C58A7',
    default: '#292929',
    grey: '#6A6C6D',
    green: '#23930D',
    orange: '#FF611C',
    red: '#D10027',
    darkBlue: '#396394',
    // New colors.
    hotPink: '#FF5770',
    brown: '#9B6F4B',
    royalBlue: '#27709C',
    parakeetGreen: '#36B67A',
    lilachPurple: '#C869AA',
    turquoise: '#00ADC9',
};

export function getFormPrimaryColorOptions() {
    return structuredClone(FORM_PRIMARY_COLORS);
}
