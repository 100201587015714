import { reactToAngular } from 'angulareact';

import ExpandableFormDescription from './ExpandableFormDescription';

angular
    .module('tonkean.app')
    .component(
        'tnkExpandableFormDescription',
        reactToAngular(ExpandableFormDescription, ['form', 'expandableDescription']),
    );
