import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab, OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class StringConvertArrayFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_CONVERT_ARRAY;
    override readonly sign = 'ConvertArrayToString';
    override readonly displayName = 'Convert JSON array to string';
    override readonly description = 'Will concatenate a JSON array to a string with spaces between each element.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Value',
            dataType: FieldType.String,
            defaultTab: FormulaPopoverTab.VARIABLE,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Separator',
            dataType: FieldType.String,
            defaultTab: FormulaPopoverTab.VARIABLE,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: ' ',
        },
    ];
}
