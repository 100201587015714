<div class="margin-left margin-top padding-right">
    <i ng-if="data.loading" class="loading"></i>

    <!-- Associate Deal -->
    <div ng-if="!data.loading && data.definition.customActionKey === 'ASSOCIATE_DEAL'">
        <div class="flex-vmiddle">
            <!-- Title -->
            <div>Associate With</div>

            <div class="flex-grow margin-left" uib-dropdown>
                <div class="flex-vmiddle form-control pointer" uib-dropdown-toggle>
                    <span class="flex-grow margin-right-xs">{{ data.selectedAssociateOption.displayName }}</span>
                    <span class="flex-no-shrink dropdown-chevron mod-static"></span>
                </div>

                <ul uib-dropdown-menu class="" role="menu">
                    <li ng-repeat="associateOption in data.associateOptions">
                        <a ng-click="selectAssociateOption(associateOption)">{{ associateOption.displayName }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- validation -->
        <div ng-if="data.validationObject.noText" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noText }}
        </div>

        <!-- ids -->
        <div class="margin-top flex-vmiddle" ng-repeat="associateId in  data.associatesId">
            <!-- Input -->
            <tnk-tonkean-expression
                class="flex-grow"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="{{ data.selectedAssociateOption.displayName }} associate id"
                saved-original-expression="associateId.text"
                saved-evaluated-expression="associateId.evaluatedText"
                on-tonkean-expression-changed="
                    onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic, $index)
                "
            ></tnk-tonkean-expression>

            <!-- Remove-->
            <button class="btn btn-no-style svg-icon-xs common-color-grey" ng-click="removeAssociateId($index)">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </button>
        </div>

        <!-- Add -->
        <a ng-click="addAssociateId()" class="margin-top-xs">
            Add {{ data.selectedAssociateOption.displayName }} associated id
        </a>
    </div>
</div>
