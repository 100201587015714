import { IntegrationType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { GoogleSheetsUsageMethodType } from '@tonkean/tonkean-entities';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function GoogleSheetSelectorCtrl(
    $scope,
    createProjectApis,
    utils,
    projectManager,
    tonkeanService,
    customFieldsManager,
    modal,
) {
    const ctrl = this;

    const appendMaxRowCount = projectManager.projectData.googleSheetsIntegrationAppendMaxRowCount;
    const crudMaxRowCount = projectManager.projectData.googleSheetsIntegrationCrudMaxRowCount;

    $scope.utils = utils;
    $scope.projectManager = projectManager;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        configuredInGroupId: ctrl.configuredInGroupId,
        configuredInWorkflowVersionId: ctrl.configuredInWorkflowVersionId,
        displayColumnSelection: ctrl.displayColumnSelection,
        configSelections: {
            headerRow: 1,
            startFromRow: 2,
            spreadsheetUsageMethod: GoogleSheetsUsageMethodType.CRUD,
        },

        connecting: false,
        error: null,
        selectedColumnsFlags: {},
        selectedColumns: {},
        filteredColumns: [],
        spreadsheets: null,
        selections: null,
        isEdit: false,
        integrationPermissions: 'readwrite',
        // Whether the integration is supports developer metadata (this feature available only for new creation integration).
        supportDeveloperMetadata: false,
        // if this flag is true we show all columns, by default show only columns with example
        showAllColumns: false,
        isValidIntegration: true,
        isUsingGCPServiceAccount: projectManager.project.features.tonkean_feature_gcp_service_account_enabled,
        integrationType: IntegrationType.GOOGLESHEETS,
        authenticationType: null,
        disableOtherGoogleAuthenticationTypes: true,
        integration: ctrl.integration || ctrl.projectIntegration?.integration,
        shouldIgnoreGoogleSheetRowsMetadata: undefined,
        isAppendDisabled: false,
        isCrudDisabled: false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = async function () {
        // If projectData is not defined we initialize it in order to use its value later.
        if (!projectManager.projectData) {
            await projectManager.getProjectData(true);
        }

        // Whether the project integration has the supportDeveloperMetadata flag.
        initializeSupportDeveloperMetadata(!utils.isNullOrUndefined($scope.data.projectIntegration));

        if ($scope.data.projectIntegration) {
            if (
                $scope.data.projectIntegration.integration.isMock ||
                !$scope.data.projectIntegration.projectData.projectDatas.length
            ) {
                return;
            }

            // Extracting the project data.
            const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];
            // If the authentication type is exists on the project data, we take it.
            $scope.data.authenticationType = projectData.authenticationType;

            $scope.data.connecting = true;
            // Im doing getAutoCompleteOptions just to get the indication that the credentials are valid or not.
            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.data.projectIntegration.id, 'spreadsheets')
                .then(() => initializeEditMode())
                .catch((error) => {
                    // We are fixing the integration so its just edit and not creating new one.
                    $scope.data.isEdit = true;
                    $scope.data.error = `${
                        error?.data?.error?.message || 'Internal Error.'
                    }. Re-authenticate in order to edit integration.`;
                    $scope.data.integration = null;
                })
                .finally(() => ($scope.data.connecting = false));
        } else if ($scope.data.integration) {
            // On create project integration from shared credentials.
            $scope.data.isInitFromSharedCredentials = true;
            initializeEditFromSharedCredentialsMode();
        }
    };

    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    $scope.selectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            // if we are not showing all the columns, dont mark as selected if the column isnt shown (has example)
            if (!$scope.data.showAllColumns && !column.example) {
                continue;
            }

            $scope.data.selectedColumnsFlags[column.value] = true;
        }
    };

    $scope.shouldDisableUniqueIdentifier = function () {
        return $scope.data.isEdit || !$scope.data.columns;
    };

    $scope.unselectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            $scope.data.selectedColumnsFlags[column.value] = false;
        }
    };

    $scope.columnSelected = function (event) {
        event.stopPropagation();
    };

    /**
     * Responsible to authorize user against Google's API.
     * If serviceAccountEmail parameter is present, Google Service Account method will be used.
     * Otherwise,  OAUTH popup will be displayed.
     */

    $scope.authorize = async function (config, displayName, additionalData) {
        $scope.data.connecting = true;
        $scope.data.error = null;
        const currentAuthenticationType = $scope.data.authenticationType;
        if (additionalData) {
            // The oauth permission type (if present). readwrite / read only.
            if (additionalData.oauthPermissionType) {
                $scope.data.integrationPermissions = additionalData.oauthPermissionType;
            }
            if (additionalData.environment) {
                $scope.data.authenticationType = additionalData.environment;
            }
        }

        initializeSupportDeveloperMetadata(!utils.isNullOrUndefined($scope.data.projectIntegration));

        try {
            $scope.data.integration = await createProjectApis.createIntegration(
                projectManager.project.id,
                'googlesheets',
                config,
                undefined,
                undefined,
            );

            // If project integration is not exists we need to start the integration setup process.
            if (
                !$scope.data.projectIntegration ||
                (!$scope.data.isInitFromSharedCredentials && $scope.data.projectIntegration.integration.isMock)
            ) {
                const spreadsheetsResponse = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.data.integration.id,
                    'spreadsheets',
                );
                $scope.data.spreadsheets = spreadsheetsResponse.options;
                $scope.data.googleSheetsStep = 'selectSpreadSheet';
                $scope.data.connecting = false;
            } else {
                // Project integration is already exists and we should update its Integration objects.
                if ($scope.data.projectIntegration.projectData) {
                    // Taking the previous project data configuration.
                    $scope.data.integration.projectDatas = $scope.data.projectIntegration.projectData.projectDatas;
                    $scope.data.integration.projectDatas[0].isUpdateable =
                        $scope.data.integrationPermissions === 'readwrite';
                    $scope.data.integration.projectDatas[0].supportDeveloperMetadata =
                        $scope.data.supportDeveloperMetadata;
                }

                const oldProject = projectManager.project;
                const updatedProject = await createProjectApis.editProjectIntegration(
                    projectManager.project.id,
                    $scope.data.projectIntegration.id,
                    $scope.data.integration,
                    $scope.data.projectIntegration.displayName,
                );
                $scope.data.projectIntegration = utils.findFirst(
                    updatedProject.integrations,
                    (prin) =>
                        prin.id === $scope?.data?.projectIntegration?.id ||
                        !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
                );
                $scope.data.connecting = false;
                initializeEditMode();
            }
        } catch (error) {
            $scope.data.connecting = false;
            if (currentAuthenticationType && currentAuthenticationType !== $scope.data.authenticationType) {
                $scope.data.error =
                    'Seems like you changed the authentication method and the selected sheet is not available from the new account.';
            } else {
                $scope.data.error = error;
            }
        } finally {
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.onSearchChanged = function (queryString) {
        $scope.data.spreadsheets = [];

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'spreadsheets', {
                name: queryString,
            })
            .then(function (data) {
                $scope.data.spreadsheets = data.options;
            });
    };

    $scope.changeUser = function () {
        $scope.data.integration = null;
        $scope.data.spreadsheets = null;
    };

    $scope.onUsageMethodSelected = function (type) {
        $scope.data.configSelections.spreadsheetUsageMethodType = type;
    };

    $scope.checkRowsCountValid = function () {
        const rowsCount = Number.parseInt($scope.data.sheet.rowCount) - $scope.data.configSelections.startFromRow;
        $scope.data.isAppendDisabled = false;
        $scope.data.isCrudDisabled = false;
        $scope.data.rowCountError = null;

        // checking if the row count exceeds the max row count
        if (rowsCount > appendMaxRowCount) {
            $scope.data.isAppendDisabled = true;
            $scope.data.isCrudDisabled = true;
            $scope.data.rowCountError =
                'The selected file exceeded the max number of rows. Please choose another file or remove rows.';
        } else if (
            $scope.data.configSelections.spreadsheetUsageMethod === GoogleSheetsUsageMethodType.CRUD &&
            rowsCount > crudMaxRowCount
        ) {
            // in case there is a violation we disable the method and throw an error
            $scope.data.isCrudDisabled = true;
            $scope.data.rowCountError =
                'The selected file exceeded the max number of rows. Please choose another file or remove rows.';
        }
    };

    $scope.onSpreadSheetSelected = function (spreadsheet) {
        if (!spreadsheet || !spreadsheet.displayName) {
            return;
        }
        $scope.data.connecting = true;
        $scope.data.spreadsheet = spreadsheet;
        $scope.data.rowCountError = null;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'sheets', {
                spreadsheetId: $scope.data.spreadsheet.value,
            })
            .then(function (data) {
                $scope.data.sheets = data.options;
                if ($scope.data.sheets && $scope.data.sheets.length > 0) {
                    $scope.data.sheet = $scope.data.sheets[0];
                    $scope.onSheetChanged($scope.data.sheet);
                    $scope.data.googleSheetsStep = 'setupSpreadSheet';

                    // check that user didn't violate rowCount restriction
                    $scope.checkRowsCountValid();
                }
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    };

    $scope.onSheetChanged = function (sheet) {
        if (!sheet || !sheet.displayName) {
            return;
        }

        $scope.data.configSelections.titleFieldColumn = null;

        $scope.data.sheet = sheet;

        const autoCompleteObject = {
            spreadsheetId: $scope.data.spreadsheet.value,
            sheetId: $scope.data.sheet.value,
            sheetName: $scope.data.sheet.displayName,
            startFromRow: $scope.data.config && $scope.data.config.startFromRow ? $scope.data.config.startFromRow : 1,
            supportDeveloperMetadata: $scope.data.supportDeveloperMetadata,
        };

        return createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.integration.id,
                'columns',
                autoCompleteObject,
            )
            .then(function (data) {
                $scope.data.columns = data.options;

                return createProjectApis
                    .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'rows', {
                        spreadsheetId: $scope.data.spreadsheet.value,
                        sheetId: $scope.data.sheet.value,
                    })
                    .then(function (data) {
                        $scope.data.rows = data.options;
                    });
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {});
    };

    $scope.onColumnNameInputClick = function (event) {
        // stop propagation so the surrounding div wont get the event and activates its click (selecting the column)
        event.stopPropagation();
    };

    $scope.toggleShowAllColumns = function () {
        $scope.data.showAllColumns = !$scope.data.showAllColumns;
    };

    function saveColumnConfiguration() {
        $scope.data.filteredColumns = $scope.data.columns;

        $scope.data.config.startFromRow = $scope.data.configSelections.startFromRow || 1;
        $scope.data.config.headerRow = $scope.data.configSelections.headerRow || 1;
        $scope.data.config.spreadsheetUsageMethodType = $scope.data.configSelections.spreadsheetUsageMethodType;

        if ($scope.data.configSelections.idFieldColumn) {
            $scope.data.config.idFieldColumn = $scope.data.supportDeveloperMetadata
                ? $scope.data.configSelections.idFieldColumn.value
                : $scope.data.configSelections.idFieldColumn.displayName;

            removeFromFilteredColumns($scope.data.configSelections.idFieldColumn);
        }

        if ($scope.data.configSelections.titleFieldColumn) {
            $scope.data.config.titleFieldColumn = $scope.data.configSelections.titleFieldColumn.value;

            removeFromFilteredColumns($scope.data.configSelections.titleFieldColumn);
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const filteredColumn = $scope.data.filteredColumns[i];

            if (filteredColumn && filteredColumn.example && filteredColumn.example !== '') {
                $scope.data.selectedColumnsFlags[filteredColumn.value] = true;
                $scope.data.selectedColumns[filteredColumn.value] = filteredColumn.example;
            }
        }
    }

    function removeFromFilteredColumns(columnToRemove) {
        const index = $scope.data.filteredColumns.indexOf(columnToRemove);

        if (index > -1) {
            $scope.data.filteredColumns.splice(index, 1);
        }
    }

    function getFieldDefinitionsToCreate() {
        if (!$scope.data.selectedColumnsFlags) {
            return [];
        }

        const editedColumns = Object.keys($scope.data.selectedColumnsFlags);
        const fieldDefinitionsToCreate = [];

        for (const col of editedColumns) {
            if ($scope.data.selectedColumnsFlags[col]) {
                const definition = {
                    name: $scope.data.selectedColumns[col],
                    type: 'EXTERNAL',
                    targetType: 'COLUMN',
                    projectIntegrationId: $scope.data.projectIntegration.id,
                    definition: {
                        ExternalType: 'Row',
                        FieldName: col,
                        FieldLabel: col,
                    },
                    ranges: [],
                    fieldType: 'String',
                    updateable: false,
                };
                fieldDefinitionsToCreate.push(definition);
            }
        }

        return fieldDefinitionsToCreate;
    }

    $scope.ok = async function () {
        if (
            !$scope.data.integrationForm.$valid ||
            !$scope.data.integration ||
            !$scope.data.configSelections.spreadsheetUsageMethodType
        ) {
            return;
        }

        if (
            ($scope.data.configuredInWorkflowVersionId || $scope.data.displayColumnSelection) &&
            $scope.data.googleSheetsStep !== 'selectColumns'
        ) {
            $scope.data.filteredColumns = [];
            $scope.data.config = {};
            saveColumnConfiguration();
            $scope.data.googleSheetsStep = 'selectColumns';

            return;
        }

        $scope.data.saving = true;

        if (!$scope.data.config) {
            $scope.data.config = {};
            saveColumnConfiguration();
        } else if ($scope.data.isEdit) {
            saveColumnConfiguration();
        }

        const twoWaySync = $scope.data.integrationPermissions === 'readwrite';

        // Preparing the integration object.
        $scope.data.integration.projectDatas = [
            {
                spreadsheet: $scope.data.spreadsheet,
                sheet: $scope.data.sheet,
                config: $scope.data.config,
                isUpdateable: twoWaySync,
                supportDeveloperMetadata: $scope.data.supportDeveloperMetadata,
                authenticationType: $scope.data.authenticationType,
                shouldIgnoreGoogleSheetRowsMetadata: !!$scope.data.config.idFieldColumn,
            },
        ];
        $scope.data.integration.isUpdateable = twoWaySync;

        // Updating / Creating the project integration
        const projectIntegrationDisplayName = `${$scope.data.spreadsheet.displayName} (${$scope.data.sheet.displayName})`;
        const oldProject = projectManager.project;
        let updatedProject;
        if (
            $scope.data.isEdit ||
            (!$scope.data.isInitFromSharedCredentials && $scope.data.projectIntegration?.integration?.isMock)
        ) {
            updatedProject = await createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.integration
            );
        } else {
            updatedProject = await createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.data.integration,
                projectIntegrationDisplayName,
            );
        }

        $scope.data.projectIntegration = utils.findFirst(
            updatedProject.integrations,
            (prin) =>
                prin.id === $scope?.data?.projectIntegration?.id ||
                !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
        );

        const fieldDefinitionsToCreate = getFieldDefinitionsToCreate();

        if ($scope.data.configuredInWorkflowVersionId && fieldDefinitionsToCreate && fieldDefinitionsToCreate.length) {
            await customFieldsManager.createMultipleFieldDefinitions(
                $scope.data.configuredInGroupId,
                fieldDefinitionsToCreate,
                null,
            );
        }

        await projectManager.getProjectData(true);
        $scope.data.saving = false;
        if (ctrl.onProjectIntegrationCreated) {
            ctrl.onProjectIntegrationCreated({
                projectIntegration: $scope.data.projectIntegration,
                fieldDefinitionsToCreate,
            });
        }
    };

    $scope.onCancel = function () {
        if (ctrl.onClosed) {
            ctrl.onClosed();
        }
    };

    $scope.openFieldInspectModal = function (modelKeyToUpdate, ignoreClick) {
        if (ignoreClick) {
            return;
        }
        analyticsWrapper.track('Toggle field inspect modal', { category: 'Google sheet selector', label: true });

        // Parse the columns to a dict so the inspector knows how to use it.
        // We pass that data as staticData - a different mode the inspector knows how to use.
        const columnsDict = parseColumnForFieldInspector($scope.data.columns);
        modal.openFieldInspectModal(
            null,
            null,
            null,
            (field) =>
                ($scope.data.configSelections[modelKeyToUpdate] = utils.findFirst(
                    $scope.data.columns,
                    (column) => column.displayName === field.name,
                )),
            columnsDict,
        );
    };

    /**
     * Parses the given columns array to a dictionary for the field inspector.
     * @returns {{}}
     */
    function parseColumnForFieldInspector(columns) {
        const columnsDict = {
            data: {},
            displayNameMap: {},
        };

        if (columns) {
            for (const column of columns) {
                if (!columnsDict.data[column.displayName]) {
                    columnsDict.data[column.displayName] = column.example;
                }
            }
        }

        return columnsDict;
    }

    /**
     * If component was given a project integration, will load the component's edit mode.
     */
    function initializeEditMode() {
        $scope.data.isEdit = true;

        return tonkeanService
            .getProjectIntegrationById($scope.data.projectIntegration.id)
            .then((projectIntegration) => {
                $scope.data.projectIntegration = projectIntegration;
                $scope.data.integration = $scope.data.projectIntegration.integration;
                const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];

                $scope.data.shouldIgnoreGoogleSheetRowsMetadata = projectData.shouldIgnoreGoogleSheetRowsMetadata;
                $scope.data.error = null;

                $scope.data.config = projectData.config;
                $scope.data.configSelections = {};

                // Set the selected values
                if ($scope.data.config.startFromRow) {
                    $scope.data.configSelections.startFromRow = Number.parseInt($scope.data.config.startFromRow);
                }
                if ($scope.data.config.headerRow) {
                    $scope.data.configSelections.headerRow = Number.parseInt($scope.data.config.headerRow);
                }

                $scope.data.configSelections.spreadsheetUsageMethodType =
                    $scope.data.config.spreadsheetUsageMethodType || GoogleSheetsUsageMethodType.CRUD;

                $scope.data.loadingSpreadsheetOptions = true;
                const loadSpreadSheetOptionsPromise = createProjectApis
                    .getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.data.projectIntegration.id,
                        'spreadsheets',
                    )
                    .then(function (data) {
                        $scope.data.spreadsheets = data.options;
                    })
                    .catch(function (error) {
                        $scope.data.error = error;
                    })
                    .finally(() => ($scope.data.loadingSpreadsheetOptions = false));

                $scope.data.connecting = true;

                return loadSpreadSheetOptionsPromise.then((ignored) => {
                    return createProjectApis
                        .getAutoCompleteOptions(
                            projectManager.project.id,
                            $scope.data.projectIntegration.id,
                            'spreadsheets',
                            {
                                spreadsheetId: projectData.spreadsheet.value,
                            },
                        )
                        .then(function (data) {
                            if (projectData.isUpdateable) {
                                $scope.data.integrationPermissions = 'readwrite';
                            } else {
                                $scope.data.integrationPermissions = 'readonly';
                            }

                            $scope.data.sheet = projectData.sheet;
                            $scope.data.googleSheetsStep = 'setupSpreadSheet';

                            $scope.data.spreadsheet = data.options[0];
                            // If data.options in not undefined, it means that there was no server or network error,
                            // so if spreadsheet is undefined it means that the spreadsheet really does not existing.
                            if (!$scope.data.spreadsheet) {
                                return Promise.reject(
                                    'The selected spreadsheet no longer exists, try to restore it or connect another spreadsheet.',
                                );
                            }

                            return createProjectApis
                                .getAutoCompleteOptions(
                                    projectManager.project.id,
                                    $scope.data.projectIntegration.id,
                                    'sheets',
                                    {
                                        spreadsheetId: $scope.data.spreadsheet.value,
                                    },
                                )
                                .then(function (data) {
                                    $scope.data.sheets = data.options;

                                    const autoCompleteObject = {
                                        spreadsheetId: $scope.data.spreadsheet.value,
                                        sheetId: $scope.data.sheet.value,
                                        sheetName: $scope.data.sheet.displayName,
                                        startFromRow: projectData.config.startFromRow
                                            ? projectData.config.startFromRow
                                            : 1,
                                        supportDeveloperMetadata: $scope.data.supportDeveloperMetadata,
                                    };

                                    return createProjectApis
                                        .getAutoCompleteOptions(
                                            projectManager.project.id,
                                            $scope.data.projectIntegration.id,
                                            'columns',
                                            autoCompleteObject,
                                        )
                                        .then(function (data) {
                                            $scope.data.columns = data.options;
                                            ['idFieldColumn', 'titleFieldColumn'].forEach(function (columnName) {
                                                if ($scope.data.config[columnName]) {
                                                    $scope.data.configSelections[columnName] = {
                                                        displayName: $scope.data.config[columnName],
                                                        value: $scope.data.config[columnName],
                                                    };
                                                }
                                            });

                                            if ($scope.data.config.titleFieldColumn) {
                                                // Iterating the available columns options and pick the selected title display name by the title field column id.
                                                const relevantColumnOption = data.options.find(
                                                    (option) => option.value === $scope.data.config.titleFieldColumn,
                                                );
                                                const titleFieldDisplayName = relevantColumnOption
                                                    ? relevantColumnOption.displayName
                                                    : '';

                                                $scope.data.configSelections.titleFieldColumn = {
                                                    displayName: titleFieldDisplayName,
                                                    value: $scope.data.config.titleFieldColumn,
                                                    example: relevantColumnOption.example || '',
                                                };
                                            }

                                            if ($scope.data.config.idFieldColumn) {
                                                // Iterating the available columns options and pick the selected unique column display name by the field column id.
                                                const relevantColumnOption = data?.options.find(
                                                    (option) => option.value === $scope.data.config.idFieldColumn,
                                                );

                                                if (relevantColumnOption) {
                                                    $scope.data.configSelections.idFieldColumn = {
                                                        displayName: relevantColumnOption?.displayName || '',
                                                        value: $scope.data.config.idFieldColumn,
                                                        example: relevantColumnOption?.example || '',
                                                    };
                                                }
                                            }
                                        });
                                });
                        })
                        .catch(function (error) {
                            $scope.data.error = error;
                        })
                        .finally(function () {
                            $scope.data.connecting = false;
                        });
                });
            });
    }

    /**
     * If component was given a  integration, will load the component's edit mode.
     */
    function initializeEditFromSharedCredentialsMode() {
        $scope.data.error = null;

        $scope.data.googleSheetsStep = 'selectSpreadSheet';
        $scope.data.loadingSpreadsheetOptions = true;

        createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'spreadsheets')
            .then(function (data) {
                $scope.data.spreadsheets = data.options;
                $scope.data.connecting = false;
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(() => ($scope.data.loadingSpreadsheetOptions = false));
    }

    /**
     * Whether to display the unique identifier column.
     * If its a new integration, the field will be displayed only if the user selected 'readonly' permissions.
     * If the integration is already exists and user click on Edit, the field will be displayed only if
     * supportDeveloperMetadata value is false / undefined.
     */
    $scope.shouldShowUniqueIdentifier = function () {

        // If the feature flag which represents whether we should display the unique column is on, we display it.
        if (
            !$scope.data.projectIntegration ||
            (!$scope.data.isInitFromSharedCredentials && $scope.data.projectIntegration.integration.isMock) ||
            $scope.data.shouldIgnoreGoogleSheetRowsMetadata
        ) {
            // If the feature flag is on and (there is not project integration which means this is a creation process / shouldIgnoreGoogleSheetRowsMetadata = true
            // which means this integration was initially created with the shouldIgnoreGoogleSheetRowsMetadata flag.
            return true;
        }

        if ($scope.data.projectIntegration) {
            // If this is an existing project, use the value from the server
            return !$scope.data.supportDeveloperMetadata;
        } else {
            // If it's a new project, show according to permissions
            return $scope.data.integrationPermissions === 'readonly';
        }
    };

    /**
     * Initializes the supportDeveloperMetadata flag.
     */
    function initializeSupportDeveloperMetadata(isEditMode) {
        const projectDataSupportsDeveloperMetadata = !!(
            $scope.data.projectIntegration &&
            $scope.data.projectIntegration.projectData.projectDatas?.[0]?.supportDeveloperMetadata
        );
        const readWritePermissions = $scope.data.integrationPermissions === 'readwrite';
        const noProjectIntegration = !$scope.data.projectIntegration;

        $scope.data.supportDeveloperMetadata =
            projectDataSupportsDeveloperMetadata ||
            ((isEditMode || noProjectIntegration) && readWritePermissions);
    }
}

export default angular.module('tonkean.app').controller('GoogleSheetSelectorCtrl', lateConstructController(GoogleSheetSelectorCtrl));
