import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const NlpBranchDefaultLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.NLP_BRANCH_DEFAULT,
    displayName: 'No match was found',
    title: 'No match was found',
    iconClass: 'mod-nlp-branch-default',
    description: `Will activate this flow only when no other branches match their conditions.`,
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: true,
    cantDrag: false,
    allowedParentTypes: [LogicBlockConfigType.NLP_PROCESSOR],
};

export default NlpBranchDefaultLogicBlockConfig;
