import { reactToAngular } from 'angulareact';

import { ItemInterfaceInSequenceWithContext } from '@tonkean/interface-module';

angular
    .module('tonkean.app')
    .component(
        'tnkItemInterfaceInSequenceWithContext',
        reactToAngular(ItemInterfaceInSequenceWithContext, [
            'itemInterface',
            'onNextClicked',
            'onBackClicked',
            'onSwitchedToNextInterfaceInternally',
            'onSwitchedToPrevInterfaceInternally',
            'workflowVersion',
            'widgets',
            'initiative',
            'project',
            'customTriggerId',
            'showBackButton',
            'nextWorkerRunId',
            'isLastInSequence',
            'formModalRef',
            'buttonColor',
        ]),
    );
