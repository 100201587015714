<div class="manage-columns">
    <div class="select-fields-box flex flex-xs-col" ng-hide="data.loading">
        <div class="flex-grow">
            <!-- If user cleared out all selected fields -->
            <div
                class="padding-normal-sm common-size-xxs flex-vmiddle mod-center common-height-full"
                ng-if="!data.copiedFieldDefinitions || !data.copiedFieldDefinitions.length"
            >
                <div class="text-center">
                    <div class="common-subtitle-dark">
                        No fields are defined.
                        <br />
                        You can create, edit and delete fields from the
                        <a ng-click="goToBotEditor()">Module Editor</a>
                        .
                    </div>
                </div>
            </div>

            <!-- All selected fields in a draggable list -->
            <div
                class="fields-selected-draggable-list flex flex-col common-draggable-list"
                ng-if="data.copiedFieldDefinitions && data.copiedFieldDefinitions.length"
                tnk-dnd-list
            >
                <div
                    class="common-draggable-list-item flex-vmiddle pointer show-edit-when-hover-visibility"
                    ng-repeat="definition in data.copiedFieldDefinitions"
                    tnk-dnd-draggable="{{::definition.id}}"
                    tnk-dnd-no-dragover="false"
                    tnk-dnd-moved="onColumnMoved($index, dndIndex)"
                    ng-init="isFieldDisabled =
                    data.dndDisabled || (data.copiedFieldDefinitions && data.copiedFieldDefinitions.length === 1)"
                    tnk-dnd-disabled="{{ isFieldDisabled }}"
                    data-automation="manage-fields-option-{{ definition.name }}"
                >
                    <div
                        class="svg-icon svg-icon-align-text-base common-color-border-light drag margin-right"
                        ng-style="{ cursor: isFieldDisabled ? 'default' : 'move' }"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/3lines-h.svg"></tnk-icon>
                    </div>

                    <!-- Field Title -->
                    <span
                        class="flex-vmiddle flex-grow common-size-xxs padding-normal-sm-v common-bold"
                        data-automation="mange-field-row-element"
                        ng-class="{
                            'opacity-40': data.fieldDefinitionToIsHiddenMap[definition.id] || data.fieldDefinitionToIsHiddenMap[definition.id] == null
                        }"
                    >
                        <div data-automation="manage-fields-{{ definition.name }}">{{ definition.name }}</div>
                        <div class="common-color-grey common-thin common-size-xxxxs margin-left-xxs">
                            ({{ definition.displayType | lowercase }})
                        </div>
                    </span>

                    <i ng-if="data.fieldIsLoadingMap[definition.id]" class="loading-small margin-right"></i>

                    <div
                        class="flex-vmiddle flex-no-shrink btn-on-hover"
                        ng-class="{ 'draggable-list-item-floating-container': data.modeSmall }"
                    >
                        <!-- Repopulate field -->
                        <div
                            ng-if="definition.targetType === 'GLOBAL' && definition.type !== 'MANUAL'"
                            ng-click="repopulateField(definition)"
                            class="margin-right"
                            uib-tooltip="Refresh field"
                            tooltip-append-to-body="true"
                        >
                            <i class="refresh-icon fa fa-refresh"></i>
                        </div>

                        <!-- Visibility Button -->
                        <div
                            ng-if="!data.hideVisibilityButton"
                            ng-class="
                                data.fieldDefinitionToIsHiddenMap[definition.id] || data.fieldDefinitionToIsHiddenMap[definition.id] == null
                                    ? 'common-color-grey'
                                    : 'common-color-primary'
                            "
                            ng-click="toggleShow(definition)"
                            class="margin-right"
                            data-automation="tnk-manage-fields-template-visible"
                            uib-tooltip="{{ data.fieldDefinitionToIsHiddenMap[definition.id] || data.fieldDefinitionToIsHiddenMap[definition.id] == null ? 'Hidden' : 'Visible' }}"
                            tooltip-append-to-body="true"
                            data-automation="manage-fields-make-option-visible-{{ definition.name }}"
                        >
                            <tnk-icon src="../../../../../apps/tracks/images/icons/eye.svg"></tnk-icon>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
