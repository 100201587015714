import NumericDateBinaryOperatorBase from './NumericDateBinaryOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';

export default class SumOperator extends NumericDateBinaryOperatorBase {
    override readonly key = OperatorKey.SUM;
    override readonly sign = '+';
    override readonly displayName = 'Sum';
    override readonly description = 'Will sum the right hand field and the left hand field.';
}
