import { useAngularService } from 'angulareact';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useFetchManager } from '@tonkean/infrastructure';
import type { Environment, WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';

const AUTO_RELOAD_SINGLE_WORKER_RUN_INTERVAL_MS = 2000;

/**
 * React hook to be used in the SingleWorkerRun component. It handles loading the worker run, emitting updates to the
 * setWorkerRunState from the History component, auto and manual reloading, and worker run cache.
 *
 * @param workerRunId - the current worker run id to show.
 * @param projectId - the project id of the worker run whose id was passed in the workerRunId prop.
 * @param groupId - the group id of the history modal/tab.
 * @param environment - the current environment
 * @param givenWorkerRunStage - the worker run stage. Used to show correct component while the worker run is still
 * loading and the correct worker run type cannot be known yet.
 * @param setWorkerRunState - update worker run state (of the history angular state manager) if the giver worker run
 * type doesn't match the worker run type of the loaded worker run.
 * @param currentlyInspecting - does an inspect modal currently open? If it does, the auto-reload will stop.
 */
function useSingleWorkerRunFetchManager(
    projectId: string,
    groupId: string,
    environment: Environment | undefined,
    workerRunId: string | undefined,
    workerRunStartTime: number | undefined,
    givenWorkerRunStage: WorkerRunStage | undefined,
    setWorkerRunState: (id?: string, startTime?: number, type?: WorkerRunStage) => void,
    currentlyInspecting: boolean,
) {
    // We are using ref and not state because the cache doesn't need a render.
    const workerRunsCacheRef = useRef<Record<string, WorkerRun>>({});

    const [workerRunShouldAutoReload, setWorkerRunShouldAutoReload] = useState(false);
    // If the inspect modal is open, it should not auto reload.
    const shouldAutoReload = !currentlyInspecting && workerRunShouldAutoReload;

    const tonkeanService = useAngularService('tonkeanService');
    const [[getWorkerRunById, stopFetcher], { data, loading, error, manuallyReload }] = useFetchManager(
        tonkeanService,
        'getWorkerRunById',
        {
            autoReloadInterval: shouldAutoReload ? AUTO_RELOAD_SINGLE_WORKER_RUN_INTERVAL_MS : undefined,
            isSingle: true,
        },
    );

    /**
     * Fetches a new worker run and stores the response in the cache.
     */
    useEffect(() => {
        if (workerRunId && workerRunStartTime) {
            getWorkerRunById(projectId, workerRunId, workerRunStartTime, true).then((response) => {
                // We use the promise and not useEffect with workerRunResponse to update the cache to add worker
                // runs even if they were canceled because a new worker run was requested before this was resolved.
                // In those cases, the data (=workerRunResponse) will wait for the next request, but the returned
                // promise will resolve anyway.
                const fetchedWorkerRun = response.entities[0];
                if (fetchedWorkerRun) {
                    workerRunsCacheRef.current[fetchedWorkerRun.id] = fetchedWorkerRun;
                }
            });

            return () => {
                stopFetcher();
            };
        }
    }, [getWorkerRunById, projectId, stopFetcher, workerRunId, workerRunStartTime]);

    const workerRun = useMemo(() => {
        if (data) {
            return data;
        }

        if (workerRunId) {
            return workerRunsCacheRef.current[workerRunId];
        }

        return undefined;
    }, [workerRunId, data]);

    /**
     * Update the auto reloadable worker run state. When the stage is of flow run, the worker run should auto reload.
     * Otherwise, it should not.
     */
    useEffect(() => {
        setWorkerRunShouldAutoReload(workerRun?.workerRunStage === WorkerRunStage.FLOW_RUN);
    }, [workerRun?.workerRunStage]);

    /**
     * If the url has no worker run type, set it. It's used for showing the correct loading state and not just
     * a generic one when entering to the history modal using a URL. If the worker run is a test item but the
     * env is not build, it should remove the worker run.
     */
    useEffect(() => {
        if (!workerRun) {
            return;
        }

        const workerRunEnvironment: Environment = workerRun.testRun ? 'build' : 'production';
        if (environment !== workerRunEnvironment) {
            setWorkerRunState(undefined);
            return;
        }

        if (
            workerRun?.workerRunStage &&
            givenWorkerRunStage !== workerRun?.workerRunStage &&
            workerRun.groupId === groupId &&
            workerRun.id === workerRunId
        ) {
            setWorkerRunState(workerRunId, workerRunStartTime, workerRun?.workerRunStage);
        }
    }, [environment, givenWorkerRunStage, groupId, setWorkerRunState, workerRun, workerRunId, workerRunStartTime]);

    return {
        workerRun,
        error,
        manuallyReload,
        manuallyReloading: loading.manualReloading,
        autoReloading: loading.autoReloading,
    };
}

export default useSingleWorkerRunFetchManager;
