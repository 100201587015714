import { useEscapeCallback } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';

function useCloseForm(form: Form | undefined, onFormUnselected: () => void) {
    const onCloseForm = () => {
        onFormUnselected();
    };

    useEscapeCallback(onCloseForm);

    return onCloseForm;
}

export default useCloseForm;
