import { angularToReact } from '@tonkean/angular-components';

const LogicWorkerComponent = angularToReact<{
    groupId: string;
    workflowVersionId: string;
    includeSpaceFillerForWarningMessage: boolean;
    displaysHistoryVersion?: boolean;
    disabled?: boolean;
    configuredLogic: any;
    syncObject?: any;
    invalidLogics?: any;
    getSpecialChildren?: (parentNode) => void;
    createMultipleLogicComponents?: (parentNode, definitionsArray, replaceInPlace) => void;
    duplicateLogic?: (logicToDuplicate) => void;
    removeSpecialChildren?: (parentNode) => void;
    onComponentClosed?: () => void;
    onActionsChanged?: (saveFunction) => void;
    onRequestValidation?: () => void;
    logicConfigMode: string;
    createNewLogicCallback?: (params, customTriggerSecondaryType) => void;
    onSideBySideDiffSelected?: (openCompare) => void;
    workflowFolderProjectIntegrationsAccess?: any;
    previewEvaluationSource: any;
    openFieldModal?: () => void;
    viewOnly?: boolean;
    noHeader?: boolean;
    filtersResultMap?: Record<string, boolean>;
}>('tnk-logic-worker-component');

export default LogicWorkerComponent;
