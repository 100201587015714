<div
    ng-if="!inline"
    uib-popover-template="'./editTagsPopoverTemplate.template.html'"
    popover-placement="{{ placement || 'bottom' }}"
    popover-is-open="popover.isOpen"
    popover-trigger="none"
>
    <ng-transclude></ng-transclude>
</div>

<div ng-if="inline" ng-include="'./editTagsPopoverTemplate.template.html'"></div>
