import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function frontappCustomActionsDefinitionCtrl($scope, $q, projectManager, tonkeanService, utils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        validationObject: ctrl.validationObject,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        onDefinitionChanged: ctrl.onDefinitionChanged,
        configuredLogic: ctrl.configuredLogic,

        teammates: [],
        teammateSelectionConfiguration: null,
        existingTeammateSelectionConfiguration: null,

        tagsObjects: [],
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = true;

                $scope
                    .refreshTeammates()
                    .then(initializeEditMode)
                    .then($scope.onActionsChanged)
                    .finally(() => {
                        $scope.data.loading = false;
                    });
            }
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.refreshTeammates = function (q) {
        return tonkeanService
            .getIntegrationExampleEntities($scope.data.projectIntegration.id, 'Teammate', q && q.length ? q : null)
            .then((data) => {
                if (data.exampleEntities && data.exampleEntities.length) {
                    $scope.data.teammate = data.exampleEntities;
                }
            });
    };

    $scope.onPerformingTeammateSelected = function (assigneeSelection, shouldSaveLogic) {
        $scope.data.teammateSelectionConfiguration = assigneeSelection;

        const data = {
            personEmailExpressionDefinition: null,
            personIds: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
        };

        if (assigneeSelection) {
            if (assigneeSelection.personSelectionType === 'fromField') {
                data.personEmailExpressionDefinition = assigneeSelection.fromFieldExpressionDefinition;
            }

            data.personIds = [];
            if (assigneeSelection.personSelectionType === 'specificPeople' && assigneeSelection.selectedPeopleIds) {
                for (let i = 0; i < assigneeSelection.selectedPeopleIds.length; i++) {
                    data.personIds.push(assigneeSelection.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = assigneeSelection.personSelectionType === 'initiativeOwner';
            data.previousActor = assigneeSelection.personSelectionType === 'previousActor';
            data.useNone = assigneeSelection.personSelectionType === 'none';
            data.failIfNoValue = assigneeSelection.failIfNoValue;
        }

        $scope.data.definition.teammate = data;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onActionsChanged = function (shouldSave) {
        if (ctrl.onDefinitionChanged) {
            ctrl.onDefinitionChanged({ shouldSaveLogic: shouldSave });
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.text = originalExpression;
        $scope.data.definition.evaluatedText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );
        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate
        ) {
            $scope.data.existingTeammateSelectionConfiguration = {
                personEmailFieldDefinitionId:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate
                        .personEmailFieldDefinitionId,
                personEmailExpressionDefinition:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate
                        .personEmailExpressionDefinition,
                specificPeopleIds:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.personIds,
                initiativeOwner:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate
                        .initiativeOwner,
                previousActor:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.previousActor,
                useNone: false,
                failIfNoValue:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.failIfNoValue,
            };

            if (
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.personIds &&
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.personIds.length >
                    0
            ) {
                const itemId =
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.teammate.personIds[0];
                $scope.data.selectedTeammate = utils.findFirstById($scope.data.teammates, itemId);
                if (!$scope.data.selectedTeammate) {
                    return tonkeanService
                        .getExternalActivityById(projectManager.project.id, $scope.data.projectIntegration.id, itemId)
                        .then((data) => {
                            $scope.data.teammates.push(data);
                            $scope.data.selectedTeammate = utils.findFirstById($scope.data.teammates, itemId);
                        });
                }
            }
        }
        return $q.resolve();
    }
}

export default angular
    .module('tonkean.app')
    .controller('frontappCustomActionsDefinitionCtrl', lateConstructController(frontappCustomActionsDefinitionCtrl));
