import { AgileCrmModalCtrl } from './agileCrm/agileCrmModalCtrl.controller';
import { AsanaModalCtrl } from './asana/asanaModalCtrl.controller';
import { BasecampModalCtrl } from './basecamp/basecampModalCtrl.controller';
import { DynamicsModalCtrl } from './dynamics365/dynamicsModalCtrl.controller';
import { ExcelSheetsModalCtrl } from './excel365/excelModalCtrl.controller';
import { FacebookadsModalCtrl } from './facebookads/facebookadsModalCtrl.controller';
import { GitLabModalCtrl } from './gitlab/gitlabModalCtrl.controller';
import { GitLoginCtrl } from './gitLocal/gitLoginCtrl.controller';
import { GitServiceConfigCtrl } from './gitService/gitServiceConfigCtrl.controller';
import { GmailModalCtrl } from './gmail/gmailModalCtrl.controller';
import { GoogleCalendarModalCtrl } from './googlecalendar/googleCalendarModalCtrl.controller';
import { GoogleSheetsModalCtrl } from './googlesheets/googleModalCtrl.controller';
import { JiraModalCtrl } from './jira/jiraModalCtrl.controller';
import { KanbanizeModalCtrl } from './kanbanize/kanbanizeModalCtrl.controller';
import { MicrosoftTeamsModalCtrl } from './microsoftTeams/microsoftTeamsModalCtrl.controller';
import { GenericIntegrationModalCtrl } from './genericIntegration/genericIntegrationModalCtrl.controller';
import { NutshellModalCtrl } from './nutshell/nutshellModalCtrl.controller';
import { PipedriveModalCtrl } from './pipedrive/pipedriveModalCtrl.controller';
import { MondayModalCtrl } from './monday/mondayModalCtrl.controller';
import { TwilioChatModalCtrl } from './twiliochat/twilioChatModalCtrl.controller';
import { ProsperworksModalCtrl } from './prosperworks/prosperworksModalCtrl.controller';
import { RssModalCtrl } from './rss/rssModalCtrl.controller';
import { SalesforceIQModalCtrl } from './salesforceiq/salesforceIQModalCtrl.controller';
import { SmartsheetModalCtrl } from './smartsheet/smartsheetModalCtrl.controller';
import { SqlModalCtrl } from './mysql/sqlModalCtrl.controller';
import { TableauModalCtrl } from './tableau/tableauModalCtrl.controller';
import { TeamworkModalCtrl } from './teamwork/teamworkModalCtrl.controller';
import { TrelloModalCtrl } from './trello/trelloModalCtrl.controller';
import { WebhookModalCtrl } from './webhook/webhookModalCtrl.controller';
import { WunderlistModalCtrl } from './wunderlist/wunderlistModalCtrl.controller';
import { ZendeskModalCtrl } from './zendesk/zendeskModalCtrl.controller';
import { GoogleDriveModalCtrl } from './googledrive/googleDriveModalCtrl.controller';
import { SendgridModalCtrl } from './sendgrid/sendgridModalCtrl.controller';
import { FreshdeskModalCtrl } from './freshdesk/freshdeskModalCtrl.controller';
import { DropboxModalCtrl } from './dropbox/dropboxModalCtrl.controller';
import { EmailWebhookModalCtrl } from './emailwebhook/emailWebhookModalCtrl.controller';
import { ZuoraModalCtrl } from './zuora/zuoraModalCtrl.controller';
import { GoogleCloudModalCtrl } from './googleCloud/googleCloudModalCtrl.controller';
import { NamelyModalCtrl } from './namely/namelyModalCtrl.controller';
import { FrontappModalCtrl } from './frontapp/frontappModalCtrl.controller';
import { GreenhouseModalCtrl } from './greenhouse/greenhouseModalCtrl.controller';
import { OktaModalCtrl } from './okta/oktaModalCtrl.controller';
import { SalesforceModalCtrl } from './salesforce/salesforceModalCtrl.controller';
import { MavenlinkModalCtrl } from './mavenlink/mavenlinkModalCtrl.controller';
import { SlackAppModalCtrl } from './slackApp/slackAppModalCtrl.controller';
import { TeamconnectModalCtrl } from './teamconnect/teamconnectModalCtrl.controller';
import { IntegrationManager } from '@tonkean/integrations';
import { GoogleChatModalCtrl } from './googlechat/googleChatModalCtrl.controller';
import TnkIntegrationGroupSetupModalTemplate from '../../../shared/components/tnkIntegrationGroup/tnkIntegrationGroupSetupModal.template.html?angularjs';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { SlackAuthenticationType } from '@tonkean/tonkean-entities';

export function IntegrationGroupCtrl(
    $scope,
    $q,
    authenticationService,
    modalUtils,
    oauthHandler,
    createProjectApis,
    projectManager,
    $rootScope,
    consts,
    utils,
    entityHelper,
    onBoardingManager,
    integrations,
    integrationHelper,
    environment,
) {
    $scope.updatingIntegrations = {};

    $scope.integrationRequireMap = integrations.getIntegrationRequireMap();
    $scope.integrationSyncSettings = integrations.getSyncSettings();
    $scope.integrationImportSettings = integrations.getImportSettings();

    $scope.editProject = $scope.$parent.editProject;
    $scope.pm = projectManager;
    $scope.utils = utils;
    $scope.modalUtils = modalUtils;

    // $scope.defaultLicenseTierToAllow = 'standardLicenseNoTrial';
    $scope.defaultLicenseTierToAllow = 'standardLicense';

    // map of all the integrations that are currently in the process of fixing (for loading circles)
    $scope.isFixingMap = {};

    $scope.data = {
        runningCollect: false,
        uniqueTypeToProjectIntegration: {},
        isCommunication: $scope.isCommunication,
        removingOrCreatingCommunicationIntegration: false,
        isNewCreateDataSourceModal: true,

        ngDisabled: $scope.ngDisabled,
        ngDisabledTooltip: $scope.ngDisabledTooltip,
    };

    $scope.integrationsConfig = {
        github: {
            name: 'github',
            displayName: 'GitHub',
            description: 'Will automatically match developers activity (commits and issues)',
            modal: {
                controller: 'GitServiceConfigCtrl',
                controllerClass: GitServiceConfigCtrl,
            },
        },
        git: {
            name: 'git',
            displayName: 'Local Git',
            description: 'Used to analyze the metadata of commits',
            vpnOnly: true,
            modal: {
                controller: 'GitLoginCtrl',
                controllerClass: GitLoginCtrl,
            },
        },
        jira: {
            name: 'jira',
            displayName: 'Jira',
            description: 'Will automatically match relevant Jira items',
            modal: {
                controller: 'JiraModalCtrl',
                controllerClass: JiraModalCtrl,
            },
        },
        sql: {
            name: 'sql',
            displayName: 'SQL',
            description: 'Will automatically match relevant SQL fields',
            modal: {
                controller: 'SqlModalCtrl',
                controllerClass: SqlModalCtrl,
            },
        },
        trello: {
            name: 'trello',
            displayName: 'Trello',
            description: 'Will automatically match relevant Trello items',
            modal: {
                controller: 'TrelloModalCtrl',
                controllerClass: TrelloModalCtrl,
            },
        },
        asana: {
            name: 'asana',
            displayName: 'Asana',
            description: 'Will automatically match relevant Asana items',
            modal: {
                controller: 'AsanaModalCtrl',
                controllerClass: AsanaModalCtrl,
            },
        },
        wrike: {
            name: 'wrike',
            displayName: 'Wrike',
            description: 'Will automatically match relevant Wrike items',
            login: loginToWrike,
        },
        harvest: {
            name: 'harvest',
            displayName: 'Harvest',
            description: 'Will automatically match relevant Harvest items',
            login: loginToHarvest,
        },
        google: {
            name: 'google',
            displayName: 'Google Analytics',
            description: 'Will automatically match metrics and dimensions',
            login: loginToGoogle,
            // modal: {
            //     template: modalTemplate,
            //     controller: 'GoogleModalCtrl',
            //     controllerClass: GoogleModalCtrl
            // }
        },
        wunderlist: {
            name: 'wunderlist',
            displayName: 'Wunderlist',
            description: 'Will automatically match relevant Wunderlist items',
            modal: {
                controller: 'WunderlistModalCtrl',
                controllerClass: WunderlistModalCtrl,
            },
        },
        salesforce: {
            name: 'salesforce',
            displayName: 'Salesforce',
            description: 'Will automatically match relevant Salesforce items',
            modal: {
                controller: 'SalesforceModalCtrl',
                controllerClass: SalesforceModalCtrl,
            },
        },
        zendesk: {
            name: 'zendesk',
            displayName: 'Zendesk',
            description: 'Will automatically match relevant Zendesk cases',
            modal: {
                controller: 'ZendeskModalCtrl',
                controllerClass: ZendeskModalCtrl,
            },
        },
        smartsheet: {
            name: 'smartsheet',
            displayName: 'Smartsheet',
            description: 'Will automatically match relevant Smartsheet',
            modal: {
                controller: 'SmartsheetModalCtrl',
                controllerClass: SmartsheetModalCtrl,
            },
        },
        nutshell: {
            name: 'nutshell',
            displayName: 'Nutshell',
            description: 'Will automatically match relevant Nutshell items',
            modal: {
                controller: 'NutshellModalCtrl',
                controllerClass: NutshellModalCtrl,
            },
        },
        pipedrive: {
            name: 'pipedrive',
            displayName: 'Pipedrive',
            description: 'Will automatically match relevant Pipedrive items',
            modal: {
                controller: 'PipedriveModalCtrl',
                controllerClass: PipedriveModalCtrl,
            },
        },
        monday: {
            name: 'monday',
            displayName: 'Monday',
            description: 'Will automatically match relevant Monday items',
            modal: {
                controller: 'MondayModalCtrl',
                controllerClass: MondayModalCtrl,
            },
        },
        intercom: {
            name: 'intercom',
            displayName: 'Intercom',
            description: 'Will automatically match relevant Intercom items',
            login: loginToIntercom,
        },
        twiliochat: {
            name: 'twiliochat',
            displayName: 'Twilio Chat',
            description: 'Will automatically match relevant Twilio Chat items',
            modal: {
                controller: 'TwilioChatModalCtrl',
                controllerClass: TwilioChatModalCtrl,
            },
        },
        agilecrm: {
            name: 'agilecrm',
            displayName: 'Agile CRM',
            description: 'Will automatically match relevant Agile CRM items',
            modal: {
                controller: 'AgileCrmModalCtrl',
                controllerClass: AgileCrmModalCtrl,
            },
        },
        facebookads: {
            name: 'facebookads',
            displayName: 'Facebook Ads',
            description: 'Will automatically match relevant Facebook Ads items',
            modal: {
                controller: 'FacebookadsModalCtrl',
                controllerClass: FacebookadsModalCtrl,
            },
        },
        salesforceiq: {
            name: 'salesforceiq',
            displayName: 'SalesforceIQ',
            description: 'Will automatically match relevant SalesforceIQ items',
            modal: {
                controller: 'SalesforceIQModalCtrl',
                controllerClass: SalesforceIQModalCtrl,
            },
        },
        googlesheets: {
            name: 'googlesheets',
            displayName: 'Google Sheets',
            description: 'Will automatically match relevant Google Sheets items',
            modal: {
                controller: 'GoogleSheetsModalCtrl',
                controllerClass: GoogleSheetsModalCtrl,
            },
        },
        googledrive: {
            name: 'googledrive',
            displayName: 'Google Drive',
            description: 'Will automatically match relevant Google Drive items',
            modal: {
                controller: 'GoogleDriveModalCtrl',
                controllerClass: GoogleDriveModalCtrl,
            },
        },
        dropbox: {
            name: 'dropbox',
            displayName: 'Dropbox',
            description: 'Will automatically match relevant Dropbox items',
            modal: {
                controller: 'DropboxModalCtrl',
                controllerClass: DropboxModalCtrl,
            },
        },
        tableau: {
            name: 'tableau',
            displayName: 'Tableau Views',
            description: 'Will automatically match relevant Tableau Views items',
            modal: {
                controller: 'TableauModalCtrl',
                controllerClass: TableauModalCtrl,
            },
        },
        teamwork: {
            name: 'teamwork',
            displayName: 'Teamwork',
            description: 'Will automatically match relevant Teamwork items',
            modal: {
                controller: 'TeamworkModalCtrl',
                controllerClass: TeamworkModalCtrl,
            },
        },
        prosperworks: {
            name: 'prosperworks',
            displayName: 'Prosperworks',
            description: 'Will automatically match relevant Prosperworks items',
            modal: {
                controller: 'ProsperworksModalCtrl',
                controllerClass: ProsperworksModalCtrl,
            },
        },
        // eslint-disable-next-line camelcase
        google_calendar: {
            name: 'google_calendar',
            displayName: 'Google Calendar',
            description: 'Will automatically match relevant Google Calendar events',
            modal: {
                controller: 'GoogleCalendarModalCtrl',
                controllerClass: GoogleCalendarModalCtrl,
            },
        },
        gmail: {
            name: 'gmail',
            displayName: 'Gmail',
            description: 'Will automatically match relevant Gmail messages',
            modal: {
                controller: 'GmailModalCtrl',
                controllerClass: GmailModalCtrl,
            },
        },
        gitlab: {
            name: 'gitlab',
            displayName: 'GitLab',
            description: 'Will automatically match relevant GitLab items',
            modal: {
                size: 'md2',
                controller: 'GitLabModalCtrl',
                controllerClass: GitLabModalCtrl,
            },
        },
        stripeapp: {
            name: 'stripeapp',
            displayName: 'Stripe',
            description: 'Will automatically match relevant Stripe items',
            login: loginToStripeApp,
        },
        excel365: {
            name: 'excel365',
            displayName: 'Excel Online',
            description: 'Will automatically match relevant Excel Online Spreadsheets items',
            modal: {
                controller: 'ExcelSheetsModalCtrl',
                controllerClass: ExcelSheetsModalCtrl,
            },
        },
        dynamics365: {
            name: 'dynamics365',
            displayName: 'Dynamics 365',
            description: 'Will automatically match relevant Dynamics 365 items',
            modal: {
                controller: 'DynamicsModalCtrl',
                controllerClass: DynamicsModalCtrl,
            },
        },
        zohocrm: {
            name: 'zohocrm',
            displayName: 'Zoho CRM',
            description: 'Will automatically match relevant Zoho CRM items',
            login: loginToZohoCRM,
        },
        basecamp: {
            name: 'basecamp',
            displayName: 'Basecamp',
            description: 'Will automatically match relevant Basecamp items',
            modal: {
                controller: 'BasecampModalCtrl',
                controllerClass: BasecampModalCtrl,
            },
        },
        kanbanize: {
            name: 'kanbanize',
            displayName: 'Kanbanize',
            description: 'Will automatically match relevant Kanbanize items',
            modal: {
                controller: 'KanbanizeModalCtrl',
                controllerClass: KanbanizeModalCtrl,
            },
        },
        rss: {
            name: 'rss',
            displayName: 'RSS',
            description: 'Will automatically read RSS items',
            modal: {
                controller: 'RssModalCtrl',
                controllerClass: RssModalCtrl,
            },
        },
        sendgrid: {
            name: 'sendgrid',
            displayName: 'SendGrid',
            description: 'Will automatically match relevant SendGrid items',
            modal: {
                controller: 'SendgridModalCtrl',
                controllerClass: SendgridModalCtrl,
            },
        },
        freshdesk: {
            name: 'freshdesk',
            displayName: 'Freshdesk',
            description: 'Will automatically match relevant Freshdesk items',
            modal: {
                controller: 'FreshdeskModalCtrl',
                controllerClass: FreshdeskModalCtrl,
            },
        },
        slack_app: {
            name: 'slack_app',
            displayName: 'Slack App',
            description: 'Will automatically match relevant Slack items',
            modal: {
                controller: 'SlackAppModalCtrl',
                controllerClass: SlackAppModalCtrl,
            },
        },
        zuora: {
            name: 'zuora',
            displayName: 'Zuora',
            description: 'Will automatically match relevant Zuora items',
            modal: {
                controller: 'ZuoraModalCtrl',
                controllerClass: ZuoraModalCtrl,
            },
        },
        googlecloud: {
            name: 'googlecloud',
            displayName: 'Google Cloud',
            description: 'Will automatically match relevant Google Cloud items',
            modal: {
                controller: 'GoogleCloudModalCtrl',
                controllerClass: GoogleCloudModalCtrl,
            },
        },
        greenhouse: {
            name: 'greenhouse',
            displayName: 'Greenhouse',
            description: 'Will automatically match relevant Greenhouse items',
            modal: {
                controller: 'GreenhouseModalCtrl',
                controllerClass: GreenhouseModalCtrl,
            },
        },
        namely: {
            name: 'namely',
            displayName: 'Namely',
            description: 'Will automatically match relevant Namely items',
            modal: {
                controller: 'NamelyModalCtrl',
                controllerClass: NamelyModalCtrl,
            },
        },
        okta: {
            name: 'okta',
            displayName: 'Okta',
            description: 'Will automatically match relevant Okta items',
            modal: {
                controller: 'OktaModalCtrl',
                controllerClass: OktaModalCtrl,
            },
        },
        frontapp: {
            name: 'frontapp',
            displayName: 'Front',
            description: 'Will automatically match relevant Front items',
            modal: {
                controller: 'FrontappModalCtrl',
                controllerClass: FrontappModalCtrl,
            },
        },
        emailwebhook: {
            name: 'emailwebhook',
            displayName: 'Email Inbox Intake',
            description: 'Send emails to an inbox at Tonkean to analyze',
            modal: {
                size: 'md2',
                controller: 'EmailWebhookModalCtrl',
                controllerClass: EmailWebhookModalCtrl,
            },
        },
        webhook: {
            name: 'webhook',
            displayName: 'New Custom Source',
            description: 'Will listen to incoming events into Tonkean',
            modal: {
                size: 'md2',
                controller: 'WebhookModalCtrl',
                controllerClass: WebhookModalCtrl,
            },
        },
        slack: {
            name: 'slack',
            displayName: 'Slack',
            description: 'Connect the Tonkean to your Slack team',
            login: loginToSlack,
            workspaceName: 'Workspace',
        },
        microsoft_teams: {
            name: 'microsoft_teams',
            displayName: 'Microsoft Teams',
            description: 'Connect the Tonkean to your Microsoft Teams',
            modal: {
                controller: 'MicrosoftTeamsModalCtrl',
                controllerClass: MicrosoftTeamsModalCtrl,
            },
            workspaceName: 'Team',
        },
        googlechat: {
            name: 'googlechat',
            displayName: 'Google Chat',
            description: 'Connect the Tonkean to your Google Chat',
            modal: {
                controller: 'GoogleChatModalCtrl',
                controllerClass: GoogleChatModalCtrl,
            },
            workspaceName: 'Room',
        },
        mavenlink: {
            name: 'mavenlink',
            displayName: 'Mavenlink',
            description: 'Will automatically match relevant Mavenlink items',
            modal: {
                controller: 'MavenlinkModalCtrl',
                controllerClass: MavenlinkModalCtrl,
            },
        },
        teamconnect: {
            name: 'teamconnect',
            displayName: 'TeamConnect',
            description: 'Will automatically match relevant TeamConnect items',
            modal: {
                controller: 'TeamconnectModalCtrl',
                controllerClass: TeamconnectModalCtrl,
            },
        },
    };

    this.$onInit = function () {
        if ($scope.data.isCommunication) {
            $scope.data.uniqueTypeToProjectIntegration = getUniqueTypeToProjectIntegration();
        }
    };

    this.$onChanges = function (changesObj) {
        if (changesObj && changesObj.ngDisabled) {
            $scope.data.ngDisabled = changesObj.ngDisabled.currentValue;
        }

        if (changesObj && changesObj.ngDisabledTooltip) {
            $scope.data.ngDisabledTooltip = changesObj.ngDisabledTooltip.currentValue;
        }
    };

    function getUniqueTypeToProjectIntegration() {
        return utils.createMapFromArray(
            utils.objToArray($scope.state || {}).flatMap((state) => state.value.integrations),
            'integrationUniqueType',
        );
    }

    $scope.$watch('state', function () {
        if ($scope.state) {
            gatherIntegrationObjects();
        }

        $scope.setIntegrations();

        $scope.data.uniqueTypeToProjectIntegration = getUniqueTypeToProjectIntegration();
    });

    $scope.deleteTitle = function (projectIntegration) {
        return `${projectIntegration?.displayName} (${projectIntegration?.integration?.integrationUserDisplayName}) Communication Source`;
    };

    $scope.deleteWarningMessage = function (projectIntegration) {
        let integrationFullName = projectIntegration?.displayName;

        if (projectIntegration?.integration?.integrationUserDisplayName) {
            integrationFullName += ` (${projectIntegration?.integration?.integrationUserDisplayName})`;
        }
        return `Your modules won’t be able to send people actions by ${integrationFullName} if you remove this instance.`;
    };

    $scope.$watch('sources', function (sources) {
        if ($scope.data.isCommunication) {
            $scope.integrations = [];
            for (const source of sources) {
                const integrationDefinition = $scope.integrationsConfig[source];
                if ($scope.state[source]?.integrations) {
                    for (let i = 0; i < $scope.state[source].integrations.length; i++) {
                        const projectIntegrationUniqueType = $scope.state[source].integrations[i].integrationUniqueType;
                        $scope.integrations.push({
                            integrationDefinition,
                            projectIntegrationUniqueType,
                        });
                    }
                }
            }
        } else {
            if (sources && sources.length) {
                $scope.integrations = sources.map(function (name) {
                    const integrationsConfigElement = $scope.integrationsConfig[name];

                    if (integrationsConfigElement) {
                        integrationsConfigElement.id = utils.generateTempID();
                        return integrationsConfigElement;
                    }

                    const integration = IntegrationManager.getIntegrationByName(name);

                    if (integration) {
                        return {
                            name: integration.name,
                            displayName: integration.displayName,
                            description: integration.description,
                            modal: {
                                controller: 'GenericIntegrationModalCtrl',
                                controllerClass: GenericIntegrationModalCtrl,
                            },
                            id: utils.generateTempID(),
                            iconUrl: $scope.iconUrlOverride,
                        };
                    }
                });
            }
        }
    });

    function loginToSlack(integration, isFix, projectIntegration) {
        const slackOauth2Enabled = true;

        const loginPromise = oauthHandler.slack(
            environment.integrationKeysMap.slack,
            slackOauth2Enabled
                ? consts.getSlackIntegrationScopesV2()
                : consts.getSlackIntegrationScopes(),
            consts.getSlackIntegrationUserScopes(),
            slackOauth2Enabled,
        );

        return oauthLogin(integration, loginPromise, null, isFix, true, projectIntegration).then((data) => {
            if ($scope.data.isCommunication) {
                $scope.data.uniqueTypeToProjectIntegration[data[0].integrationUniqueType] = data[0];
                $scope.integrations.push({
                    integrationDefinition: integration,
                    projectIntegrationUniqueType: data[0].integrationUniqueType,
                });
            }
        });
    }

    function loginToWrike(integration, isFix) {
        const redirectUriState = oauthHandler.publicGetState();
        const redirectUri = `${environment.integrationKeysMap.wrikeUri}?state=${redirectUriState}`;
        const loginPromise = oauthHandler.wrike(environment.integrationKeysMap.wrike, redirectUri, redirectUriState);
        return oauthLogin(integration, loginPromise, redirectUri, isFix);
    }

    function loginToZohoCRM(integration, isFix) {
        const redirectUri = environment.integrationKeysMap.zohocrmUri;
        const scopes =
            'ZohoCRM.users.READ,ZohoCRM.settings.fields.read,ZohoCRM.modules.deals.ALL,ZohoCRM.modules.accounts.ALL,ZohoCRM.modules.leads.ALL';
        const loginPromise = oauthHandler.zohoCRM(environment.integrationKeysMap.zohocrm, scopes, redirectUri);
        return oauthLogin(integration, loginPromise, redirectUri, isFix);
    }

    function loginToHarvest(integration, isFix) {
        const redirectUri = environment.integrationKeysMap.harvestUri;
        const loginPromise = oauthHandler.harvest(environment.integrationKeysMap.harvest, redirectUri);
        return oauthLogin(integration, loginPromise, redirectUri, isFix);
    }

    function loginToGoogle(integration, isFix) {
        const redirectUri = environment.integrationKeysMap.googleUri;
        const loginPromise = oauthHandler.google(
            environment.integrationKeysMap.google,
            'https://www.googleapis.com/auth/analytics.readonly',
            redirectUri,
            true,
        );
        return oauthLogin(integration, loginPromise, redirectUri, isFix);
    }

    function loginToIntercom(integration, isFix, projectIntegration) {
        const redirectUri = environment.integrationKeysMap.intercomUri;
        const loginPromise = oauthHandler.intercom(environment.integrationKeysMap.intercom, redirectUri);

        let optionalProjectIntegration;
        if (
            $scope.state[integration.name] &&
            $scope.state[integration.name].integrations.length &&
            $scope.state[integration.name].integrations[0]
        ) {
            optionalProjectIntegration = $scope.state[integration.name].integrations[0];
        }

        return oauthLogin(integration, loginPromise, redirectUri, isFix, true, optionalProjectIntegration);
    }

    function loginToStripeApp(integration, isFix) {
        const clientId = environment.integrationKeysMap.stripeappKey;
        const redirectUri = 'https://tracks.tonkean.com/oauth.html';
        const loginPromise = oauthHandler.stripeapp(clientId, redirectUri);
        return oauthLogin(integration, loginPromise, redirectUri, isFix);
    }

    // base function to user when login with oAuth
    function oauthLogin(
        integration,
        loginPromise,
        redirectUri,
        isFix,
        dontSetIntegrationUniqueType,
        projectIntegration,
    ) {
        let integrationUniqueType = null;
        if (!dontSetIntegrationUniqueType) {
            const oldProjectIntegration =
                $scope.state[integration.name] &&
                $scope.state[integration.name].integrations.length &&
                $scope.state[integration.name].integrations[0];
            integrationUniqueType = integrations.getIntegrationUniqueType(
                authenticationService.currentUser.id,
                integration.name,
                oldProjectIntegration,
            );
        }

        if (projectIntegration) {
            integrationUniqueType = integrations.getIntegrationUniqueType(
                authenticationService.currentUser.id,
                integration.name,
                projectIntegration,
            );

            $scope.currentProjectIntegration = projectIntegration;
        }

        if (isFix) {
            $scope.isFixingMap[integration.name] = true;
        }

        const integrationKey = integrationUniqueType ? integrationUniqueType : integration.name;
        const state = ($scope.state[integrationKey] = $scope.state[integrationKey] || {});
        state.connecting = true;
        $scope.currentIntegration = integration;
        $scope.saving = true;

        let authType;
        if (integration.name === 'slack') {
            authType = SlackAuthenticationType.OAUTH2;
        }

        return loginPromise
            .then(function (code) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    integration.name,
                    {
                        code,
                        redirectUri,
                        authType,
                    },
                    integrationUniqueType,
                    undefined,
                );
            })
            .then(function (integrationObj) {
                state.integrations = [{ integration: integrationObj }];
                state.integration = integrationObj;

                gatherIntegrationObjects();
                return $scope.saveIntegration(state);
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
                $scope.currentIntegration = null;
                state.connecting = false;
                $scope.saving = false;
            });
    }

    $scope.loginCommunicationIntegration = function (integration, projectIntegration, createNew) {
        $scope.data.removingOrCreatingCommunicationIntegration = true;
        let loginPromise = $q.resolve();
        if (integration.login) {
            loginPromise = integration.login(integration, false, projectIntegration);
        } else if (integration.modal) {
            loginPromise = $scope.setUp(integration, projectIntegration, createNew).result;
        }

        loginPromise.finally(() => {
            $scope.data.removingOrCreatingCommunicationIntegration = false;
        });
    };

    function shouldOpenPredefinedModal(integration) {
        const isFfOn = $scope.data.isNewCreateDataSourceModal;
        const isNativeIntegration = integration.name === IntegrationType.WEBHOOK.toLowerCase();
        return isFfOn && !isNativeIntegration;
    }

    $scope.onClickIntegration = function (integration) {
        if (integration.login) {
            integration.login(integration);
        } else if (shouldOpenPredefinedModal(integration)) {
            modalUtils.openCreateNewProjectIntegrationModal($scope.setUp, integration, integration.name.toUpperCase());
        } else {
            $scope.setUp(integration);
        }
    };

    $scope.setUp = function (integration, projectIntegration, ignoreState) {
        if (integration) {
            $scope.currentIntegration = integration;
            $scope.currentProjectIntegration = projectIntegration;
            const modal = angular.copy(integration.modal);
            modal.settings = {};
            modal.settings.state = ignoreState ? null : $scope.state[integration.name];

            if ($scope.data.isCommunication && !ignoreState) {
                modal.settings.state = [projectIntegration];
            }
            // When in fix mode, ignoreState = true, which makes all modals make you define a new integration basically.
            // In webhooks, we don't want you to define a new integration, we want you to fix the current one.
            // I didn't want to change the true to false right now since we will have to change it everywhere.
            // Instead, I save the original state as well, regardless of the ignoreState flag.
            modal.settings.originalState = $scope.state[integration.name];
            modal.settings.onIntegrationClosed = $scope.onIntegrationWindowClosed;
            modal.settings.integrationConfigurationCanceled = $scope.integrationConfigurationCanceled;
            modal.settings.onCancel = $scope.onIntegrationCancel;
            modal.settings.webhookSource = $scope.webhookSource;
            modal.settings.hideDeleteButton = $scope.hideDeleteButton;
            modal.settings.configuredInGroupId = $scope.configuredInGroupId;
            modal.settings.configuredInWorkflowVersionId = $scope.configuredInWorkflowVersionId;
            modal.settings.projectIntegration = $scope.currentProjectIntegration;
            modal.settings.integrationType = integration.name;

            if (!integration?.id?.startsWith('TEMP')) {
                modal.settings.integration = integration;
            }

            $scope.currentModal = modal;

            // If we're in fix mode, we don't want the setup screen to be inline,
            // because it can be messy - we prefer it to be a new modal.
            if ($scope.data.inlineMode && !$scope.fixMode && !$scope.openModalForConfiguration) {
                $scope.inlineSetupScreen = modal;
            } else {
                modal.scope = $scope;
                modal.size = $scope.currentModal.size;
                modal.backdrop = 'static'; // backdrop is present but modal window is not closed when clicking outside of the modal window
                modal.keyboard = false;
                modal.template = TnkIntegrationGroupSetupModalTemplate;

                $scope.currentModalInstance = modalUtils.open(modal);
                return $scope.currentModalInstance;
            }
        } else {
            throw new Error('No such integration');
        }
    };

    $scope.onIntegrationWindowClosed = function (state) {
        $scope.data.removingOrCreatingCommunicationIntegration = true;

        if (state) {
            return $scope
                .saveIntegration(state)
                .then((data) => {
                    if (data && $scope.data.isCommunication) {
                        $scope.data.uniqueTypeToProjectIntegration[data[0].integrationUniqueType] = data[0];
                        $scope.integrations.push({
                            integrationDefinition: $scope.integrationsConfig[data[0].integrationType.toLowerCase()],
                            projectIntegrationUniqueType: data[0].integrationUniqueType,
                        });
                    }
                })
                .finally(() => {
                    $scope.data.removingOrCreatingCommunicationIntegration = false;
                });
        }
    };

    $scope.openEntitiesConfigurationModal = function (projectIntegration, integration) {
        modalUtils.openEntitiesConfigurationExpirationModal(projectIntegration.id, integration.name);
    };

    $scope.saveIntegration = function (state) {
        if ($scope.currentModalInstance) {
            $scope.currentModalInstance.close();
            $scope.currentModalInstance = null;
        }

        // If integration creation already created a project integration, we don't need to go through everything else
        if (state.createdProjectIntegration) {
            $scope.state[state.createdProjectIntegration.integration.integrationUniqueType] = state;
            onComplete(state.createdProjectIntegration.integration.integrationUniqueType);
            $rootScope.$broadcast('newIntegrationAdded');
            return $q.resolve();
        }

        if (!state.integration) {
            $scope.onIntegrationCancel();
            return $q.resolve();
        }

        $scope.saving = true;

        // These objects will hold an array of maps of IntegrationId -> Array of ProjectData.
        const integrationsToAdd = {};
        const integrationsToEdit = {};

        const integrationName = state.integration.integrationUniqueType;
        const existingProjectIntegration = $scope.currentProjectIntegration;

        $scope.updatingIntegrations[integrationName] = true;
        $scope.state[integrationName] = state;

        // Collect all project datas together for each integration to be added or modified.
        for (let i = 0; i < state.integrations.length; i++) {
            const selectedIntegration = state.integrations[i];

            if (existingProjectIntegration) {
                if (!integrationsToEdit[selectedIntegration.integration.id]) {
                    integrationsToEdit[selectedIntegration.integration.id] = [];
                }

                integrationsToEdit[selectedIntegration.integration.id].push(selectedIntegration.projectData || {});
            } else {
                if (!integrationsToAdd[selectedIntegration.integration.id]) {
                    integrationsToAdd[selectedIntegration.integration.id] = [];
                }

                integrationsToAdd[selectedIntegration.integration.id].push(selectedIntegration.projectData);
            }
        }

        const promises = [];
        // For each integration to add, call addProjectIntegration
        for (const integrationId in integrationsToAdd) {
            if (integrationsToAdd.hasOwnProperty(integrationId)) {
                promises.push(
                    addProjectIntegrations(
                        integrationId,
                        integrationsToAdd[integrationId],
                        state.integration,
                        function () {
                            onComplete(integrationName);
                            $rootScope.$broadcast('newIntegrationAdded');
                        },
                    ),
                );
            }
        }

        // For each integration to edit, call editProjectIntegration
        for (const integrationId in integrationsToEdit) {
            if (integrationsToEdit.hasOwnProperty(integrationId)) {
                promises.push(
                    editProjectIntegrations(
                        integrationId,
                        existingProjectIntegration.id,
                        integrationsToEdit[integrationId],
                    ).then(() => {
                        onComplete(integrationName);
                        $rootScope.$broadcast('newIntegrationAdded');
                    }),
                );
            }
        }

        return $q.all(promises);
    };

    $scope.onIntegrationCancel = function () {
        $scope.inlineSetupScreen = null;
        $scope.currentIntegration = null;
        $scope.currentModal = null;
    };

    $scope.setIntegrations = () => {
        if (!$scope.state) {
            return;
        }

        if ($scope.displayMode === 'edit-button') {
            $scope.integration = $scope.integrations?.[0];
            $scope.projectIntegration = $scope.state[$scope.integration?.name]?.integrations?.[0];
        }
    };

    $scope.onClick = function (integration) {
        // Only do something on the entire row if inline mode, and not in field fix mode (in fix case, we'd like him to click the button).
        if ($scope.data.inlineMode && !$scope.fixMode && !$scope.saving) {
            if (integration.login) {
                integration.login(integration);
            } else {
                $scope.setUp(integration, true);
            }
        }
    };

    $scope.setDefaultCommunicationIntegration = function (projectIntegration) {
        integrationHelper
            .setDefaultCommunicationIntegration($scope.pm.project.id, projectIntegration.id)
            .then((data) => {
                $scope.$emit('alert', {
                    msg: 'Communication integration was set as default successfully.',
                    type: 'success',
                });
            });
    };

    $scope.runCollect = function (integration) {
        if ($scope.state[integration.name] && $scope.state[integration.name].integrations.length) {
            $scope.data.runningCollect = true;
            integrationHelper
                .runCollectOnProjectIntegration($scope.state[integration.name].integrations[0])
                .finally(() => {
                    $scope.data.runningCollect = false;

                    if ($scope.onCollect) {
                        $scope.onCollect();
                    }
                });
        }
    };

    function onComplete(integrationName) {
        let changedProjectIntegration = null;
        if ($scope.state[integrationName]) {
            // save the current project integrations so can be used by inline usage
            if ($scope.state[integrationName].createdProjectIntegration) {
                $scope.state[integrationName].projectIntegrations = [
                    $scope.state[integrationName].createdProjectIntegration,
                ];
            } else {
                $scope.state[integrationName].projectIntegrations = getProjectIntegrationsByUniqueType(integrationName);
            }

            for (let i = 0; i < $scope.state[integrationName].projectIntegrations.length; i++) {
                const projectIntegration = $scope.state[integrationName].projectIntegrations[i];
                const integrationType = projectIntegration.integrationType;
                const stateIntegration = $scope.state[integrationName] || $scope.state[integrationType.toLowerCase()];

                if (stateIntegration) {
                    const existingProjectIntegration = utils.findFirst(stateIntegration.integrations, (prjIntg) => {
                        return prjIntg.integration.integrationUniqueType === integrationName;
                    });
                    existingProjectIntegration.valid = projectIntegration.valid;
                    if (
                        $scope.isFixingMap &&
                        existingProjectIntegration.name &&
                        $scope.isFixingMap[existingProjectIntegration.name.toLowerCase()]
                    ) {
                        $scope.isFixingMap[existingProjectIntegration.name.toLowerCase()] = false;
                    }

                    changedProjectIntegration = existingProjectIntegration;

                    // Make sure that the integration id is actually an integration id and not a projectIntegrationID
                    const actualIntegrationId =
                        existingProjectIntegration.integration.id.indexOf('PRIN') === 0
                            ? existingProjectIntegration.integration.integration.id
                            : existingProjectIntegration.integration.id;
                    // Put the actual matching project integration inside the container object
                    changedProjectIntegration.projectIntegration = utils.findFirst(
                        stateIntegration.projectIntegrations,
                        (prjIntg) => {
                            return prjIntg.integration.id === actualIntegrationId;
                        },
                    );
                }
            }

            // Reset state.connecting.
            $scope.state[integrationName].connecting = false;
        }

        const currentIntegration = $scope.currentIntegration;

        // Hide loading.
        $scope.updatingIntegrations[integrationName] = false;
        $scope.currentIntegration = null;
        $scope.saving = false;

        // updating the controls that uses this
        if ($scope.integrationChanged) {
            $scope.integrationChanged({ changedProjectIntegration });

            if (currentIntegration && !$scope.doNotRunCollectAfterSavingIntegration) {
                $scope.runCollect(currentIntegration);
            }
        }

        // alert the onboarding manager that an integration has been added
        onBoardingManager.completeStep('addIntegration');
    }

    function gatherIntegrationObjects() {
        const integrations = $scope.sources.flatMap(function (k) {
            return $scope.state[k] && $scope.state[k].integrations
                ? mapIntegrationObjects($scope.state[k].integrations)
                : [];
        });
        $scope.$modelCtrl.$setViewValue(integrations.length ? integrations : null);
        return integrations;
    }

    function addProjectIntegrations(integrationId, projectDatas, integrationObj, callback) {
        if (integrationId && projectDatas) {
            const integ = {
                id: integrationId,
                integrationType: integrationObj.integrationType,
                projectDatas,
            };

            return createProjectApis
                .createProjectIntegration(projectManager.project, integ, integrationObj.displayName, false)
                .then(function (data) {
                    projectManager.project.integrations = data.integrations;
                    entityHelper.enrichEntity(projectManager.project);

                    if (callback) {
                        callback();
                    }

                    return utils
                        .objToArray(data.projectIntegrationIdDict)
                        .find(
                            (projectIntegrationEntry) =>
                                projectIntegrationEntry.value?.integration?.id === integrationId,
                        ).value;
                });
        }
    }

    function editProjectIntegrations(integrationId, existingProjectIntegrationId, projectDatas) {
        if (integrationId && existingProjectIntegrationId && projectDatas) {
            const integ = {
                id: integrationId,
                projectDatas,
            };

            return createProjectApis
                .editProjectIntegration(projectManager.project.id, existingProjectIntegrationId, integ)
                .then(function (data) {
                    projectManager.project.integrations = data.integrations;
                    entityHelper.enrichEntity(projectManager.project);
                });
        }
    }

    /**
     * Maps the integration group output to the server input.
     * @param {{integration:{},projectData:{}}} integrations List of integrations
     * @return {Array|*|{prev, inline, annotation, sourcesContent}}
     */
    function mapIntegrationObjects(integrations) {
        return integrations.map(function (tuple) {
            const newVar = { id: tuple.integration.id };
            if (tuple.projectData) {
                newVar.projectData = tuple.projectData;
            }
            return newVar;
        });
    }

    function getProjectIntegrationsByUniqueType(integrationUniqueType) {
        if (!projectManager.project.integrations || !projectManager.project.integrations.length) {
            return [];
        }
        const arr = [];
        for (let i = 0; i < projectManager.project.integrations.length; i++) {
            const item = projectManager.project.integrations[i];
            if (item.integration.integrationUniqueType === integrationUniqueType) {
                arr.push(item);
            }
        }
        return arr;
    }
}

export default angular.module('tonkean.shared').controller('IntegrationGroupCtrl', IntegrationGroupCtrl);
/* jshint ignore:start */
