import React from 'react';

import { classNames } from '@tonkean/utils';

/** @deprecated Please use {@link ClickableLink} instead */
const LinkLikeButton: React.ForwardRefRenderFunction<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...props }, ref) => {
    return (
        <button type="button" className={classNames([className, 'btn-link padding-none'])} {...props} ref={ref}>
            {children}
        </button>
    );
};

export default React.forwardRef(LinkLikeButton);
