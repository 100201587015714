import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const ProcessBuilderAIChat = styled.div`
    padding: 16px 16px 16px 16px;
    margin-left: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    height: fit-content;
    max-height: 100%;
    overflow: scroll;
    border: 1px solid #babce9;
    border-radius: 16px;
    font-weight: 300;
    background-color: ${Theme.colors.promotion}1A;
`;

export default ProcessBuilderAIChat;
