import React from 'react';

import type { MultiEntitySelectorCustomComponentProps } from '../../MultiEntitySelector';
import { MultiEntitySelectorEntityDescription } from '../../MultiEntitySelector';

import { TextEllipsis } from '@tonkean/infrastructure';
import type { InterfaceCTASavedAction } from '@tonkean/tonkean-entities';
import { InterfaceCTAActionType } from '@tonkean/tonkean-entities';

const getEntityDescription = (type: InterfaceCTAActionType, defaultLabel: string) => {
    switch (type) {
        case InterfaceCTAActionType.OPEN_FORM:
            return `Open form "${defaultLabel}"`;
        case InterfaceCTAActionType.TRIGGER_WORKFLOW:
            return `Trigger workflow "${defaultLabel}"`;
        case InterfaceCTAActionType.OPEN_INTERFACE:
            return `Open interface "${defaultLabel}"`;
        case InterfaceCTAActionType.OPEN_MATCHED_ITEM:
            return `Open matched item created by "${defaultLabel}"`;
        default:
            return defaultLabel;
    }
};

const InterfaceCTASelectorEntityDescription: React.FC<
    MultiEntitySelectorCustomComponentProps<InterfaceCTASavedAction>
> = ({ savedEntity, entityOption }) => {
    const innerText = getEntityDescription(savedEntity.type, entityOption.savedEntityTemplate.defaultLabel);

    return (
        <MultiEntitySelectorEntityDescription>
            <TextEllipsis numberOfLines={1} tooltipPlacement="bottom" tooltip>
                {innerText}
            </TextEllipsis>
        </MultiEntitySelectorEntityDescription>
    );
};

export default InterfaceCTASelectorEntityDescription;
