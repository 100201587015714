import { useAngularService } from 'angulareact';
import { useEffect, useMemo, useState } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

/**
 * Fetches and returns all the information needed to run the project integration page.
 * It will fetch the project integration and integrations and return functions to update needed information.
 */
function useProjectIntegrationsBrowser(projectIntegrationId: string) {
    const [selectedProjectIntegrationId, setSelectedProjectIntegrationId] = useState<string>(projectIntegrationId);
    const [currentProjectIntegration, setCurrentProjectIntegration] = useState<ProjectIntegration | undefined>();

    const workflowFolderManager = useAngularService('workflowFolderManager');
    const projectManager = useAngularService('projectManager');

    const projectId = useMemo(() => projectManager.project.id, [projectManager]);
    const workflowFolderId = useMemo(
        () =>
            workflowFolderManager.getContainingWorkflowFolder(
                projectManager.project.id,
                projectManager.currentlyViewedGroupId,
            )?.id,
        [workflowFolderManager, projectManager.project.id, projectManager.currentlyViewedGroupId],
    );

    const [
        {
            error: errorLoadingProjectIntegrations,
            loading: loadingProjectIntegrations,
            data: projectIntegrationSummaries,
        },
        getProjectIntegrationsSummaries,
    ] = useLazyTonkeanService('getProjectIntegrationsSummaries');

    // Loading project integration.
    const {
        data: projectIntegration,
        error: errorLoadingProjectIntegration,
        loading: loadingProjectIntegration,
    } = useTonkeanService('getProjectIntegrationById', selectedProjectIntegrationId);

    // Once project integration is loaded.
    useEffect(() => {
        if (projectIntegration) {
            setCurrentProjectIntegration(projectIntegration);
        }
    }, [projectIntegration, setCurrentProjectIntegration]);

    // Getting the summaries on load and when current project integration changes, this is mainly for 'Fix' of
    // project integrations. The client doesn't know that the integration is fixed until it re-queries the server
    useEffect(() => {
        getProjectIntegrationsSummaries(projectId, true, workflowFolderId);
        // currentProjectIntegration must be in deps so we can update the summaries when it changes
    }, [currentProjectIntegration, getProjectIntegrationsSummaries, projectId, workflowFolderId]);

    return {
        projectId,

        setCurrentProjectIntegration,
        currentProjectIntegration,

        selectedProjectIntegrationId,
        setSelectedProjectIntegrationId,

        projectIntegrationSummaries,
        loadingProjectIntegrations,
        errorLoadingProjectIntegrations,
    };
}

export default useProjectIntegrationsBrowser;
