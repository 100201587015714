import type VariableElement from '../entities/VariableElement';

function createVariableElement(variableContent: string): VariableElement {
    return {
        variableContent,
        type: 'variable',
        children: [{ text: '' }],
    };
}

export default createVariableElement;
