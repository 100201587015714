import { saveAs } from 'file-saver';
import { Formik } from 'formik';
import JSZip from 'jszip';
import React from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Input,
    ModalBody,
    ModalFooter,
    ModalForm,
    ModalHeader,
    ModalSize,
    useCloseCallback,
    withModal,
    XCloseButton,
} from '@tonkean/infrastructure';
import type {
    GroupVersionsExportData,
    TonkeanId,
    TonkeanType,
    WorkflowFolderVersionsExportData,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const ModalSubtitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: 12px;
    margin-bottom: 12px;
`;

const ModalHeaderWrapper = styled(ModalHeader)`
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 0 14px;
`;

const ModalBodyWrapper = styled(ModalBody)`
    padding: 14px 20px 0 20px;
`;

const Header = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    margin-top: 8px;
    margin-left: 6px;
`;

interface Props {
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    defaultFileName: string;
}

const getFormattedDate = (date: number | undefined) => {
    return date ? utils.formatDate(date, true, false, false) : null;
};

const getEnrichedSolutionData = (solution: WorkflowFolderVersionsExportData) => {
    const enrichedWorkflowFolderVersions = solution.workflowVersions.map((workflowVersion) => ({
        ...workflowVersion,
        created: getFormattedDate(workflowVersion.created),
        updated: getFormattedDate(workflowVersion.updated),
    }));

    return { ...solution, workflowVersions: enrichedWorkflowFolderVersions };
};

const getEnrichedGroups = (groups: GroupVersionsExportData[]) => {
    return groups.map((group) => {
        const enrichedGroupVersions = group.workflowVersions.map((workflowVersion) => ({
            ...workflowVersion,
            created: getFormattedDate(workflowVersion.created),
            updated: getFormattedDate(workflowVersion.updated),
        }));

        const enrichedVersionToActivitiesMap = Object.entries(group.workflowVersionIdToActivities).map(
            ([workflowVersionId, activities]) => {
                const enrichedActivities = activities.map((activity) => ({
                    ...activity,
                    created: getFormattedDate(activity.created),
                    updated: getFormattedDate(activity.updated),
                }));

                return [workflowVersionId, enrichedActivities];
            },
        );

        return {
            ...group,
            workflowVersions: enrichedGroupVersions,
            workflowVersionIdToActivities: enrichedVersionToActivitiesMap,
        };
    });
};

const ExportWorkflowFolderVersionHistory: React.FC<Props> = ({ workflowFolderId, defaultFileName }) => {
    const onClose = useCloseCallback();

    const [{ loading, error }, getWorkflowFolderVersionsHistoryForDownload] = useLazyTonkeanService(
        'getWorkflowFolderVersionsHistoryForDownload',
    );

    const onSubmit = async ({ fileName }) => {
        const result = await getWorkflowFolderVersionsHistoryForDownload(workflowFolderId);

        const enrichedSolution = getEnrichedSolutionData(result.solution);
        const enrichedGroups = getEnrichedGroups(result.groups);

        const zip = new JSZip();
        zip.file(`solution.json`, JSON.stringify(enrichedSolution));

        const groupsFolder = zip.folder('modules');
        enrichedGroups.forEach((group) => groupsFolder?.file(`${group.groupName}.json`, JSON.stringify(group)));

        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${fileName}.zip`);
        });

        onClose();
    };

    return (
        <Formik initialValues={{ fileName: `${defaultFileName} version history` }} onSubmit={onSubmit}>
            <ModalForm>
                <ModalHeaderWrapper $border={false}>
                    <Header>Export version history ZIP</Header>
                    <XCloseButton size={ButtonSize.MEDIUM} onClick={() => onClose()} />
                </ModalHeaderWrapper>

                <ModalBodyWrapper>
                    <ModalSubtitle>
                        <div>Save version history as a zip folder.</div>
                    </ModalSubtitle>

                    <Input
                        data-automation="export-workflow-folder-version-history-file-name"
                        name="fileName"
                        placeholder="Type file name"
                        required
                    />
                    {error && (
                        <div className="common-size-xxxxs common-color-danger flex-grow margin-top">
                            There was an error trying to export version history
                        </div>
                    )}
                </ModalBodyWrapper>
                <ModalFooter gap={8} align="right">
                    <Button onClick={() => onClose()} type="button" cancel>
                        Cancel
                    </Button>
                    <Button
                        data-automation="export-workflow-folder-version-history-download"
                        disabled={loading}
                        type="submit"
                    >
                        Download
                    </Button>
                </ModalFooter>
            </ModalForm>
        </Formik>
    );
};

export default withModal(ExportWorkflowFolderVersionHistory, {
    fixedWidth: true,
    size: ModalSize.SMALL,
    backdropCloses: false,
});
