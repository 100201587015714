enum LineItemWidgetAggregationType {
    NONE = 'NONE',
    COUNT = 'COUNT',
    SUM = 'SUM',
    AVERAGE = 'AVERAGE',
    MAX = 'MAX',
    MIN = 'MIN',
    MAX_DATE = 'MAX_DATE',
    MIN_DATE = 'MIN_DATE',
    CONCAT = 'CONCAT',
    UNIQUE_CONCAT = 'UNIQUE_CONCAT',
}

export const numericLineItemWidgetAggregationTypes = [
    LineItemWidgetAggregationType.NONE,
    LineItemWidgetAggregationType.COUNT,
    LineItemWidgetAggregationType.SUM,
    LineItemWidgetAggregationType.AVERAGE,
    LineItemWidgetAggregationType.MAX,
    LineItemWidgetAggregationType.MIN,
];

export const textualLineItemWidgetAggregationTypes = [
    LineItemWidgetAggregationType.NONE,
    LineItemWidgetAggregationType.CONCAT,
    LineItemWidgetAggregationType.UNIQUE_CONCAT,
];

export const temporalLineItemWidgetAggregationTypes = [
    LineItemWidgetAggregationType.NONE,
    LineItemWidgetAggregationType.MIN_DATE,
    LineItemWidgetAggregationType.MAX_DATE,
];

export const aggregationTypeToDisplayName: Record<LineItemWidgetAggregationType, string> = {
    [LineItemWidgetAggregationType.NONE]: 'None',
    [LineItemWidgetAggregationType.SUM]: 'Sum',
    [LineItemWidgetAggregationType.MIN]: 'Min',
    [LineItemWidgetAggregationType.MAX]: 'Max',
    [LineItemWidgetAggregationType.MIN_DATE]: 'Min',
    [LineItemWidgetAggregationType.MAX_DATE]: 'Max',
    [LineItemWidgetAggregationType.AVERAGE]: 'Average',
    [LineItemWidgetAggregationType.COUNT]: 'Count',
    [LineItemWidgetAggregationType.CONCAT]: 'Concatenate',
    [LineItemWidgetAggregationType.UNIQUE_CONCAT]: 'Concatenate Unique',
};

export default LineItemWidgetAggregationType;
