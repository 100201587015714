import WorkerHistoryItemDispatcher from '../dispatchers/WorkerHistoryItemDispatcher';
import { WorkerHistoryItemActionTypes } from '../actions/WorkerHistoryItemActions';
import { TonkeanStore } from '@tonkean/shared-services';

/**
 * The main track store.
 * Since we are still in an AngularJS app, the store doesn't actively manage it's data, but delegates everything to the outside component.
 */
class WorkerHistoryItemStore extends TonkeanStore {
    /* @ngInject */

    constructor(workerRunsHistoryHelper, trackHelper) {
        // Initialize our super with the dispatchers we need.
        super([WorkerHistoryItemDispatcher]);

        this.workerRunsHistoryHelper = workerRunsHistoryHelper;
        this.trackHelper = trackHelper;
    }

    getNewState(id) {
        // Reset the state of the given id to a new one: requesting a new state means the component was re-constructed.
        const item = this.workerRunsHistoryHelper.getItem(id);

        this.states[id] = {
            item,
            initiativeId: item.initiativeId,

            toggleActions: false,
        };

        // Return the state to the component.
        return this.states[id];
    }

    getState(id) {
        return this.states[id];
    }

    /**
     * A helper function for our components.
     * @param itemId - the component's id.
     * @param requestedId - the idsObject the component has received via the store's emit function
     * @returns {boolean} - true if the component should re-render itself.
     */
    shouldItemUpdate(itemId, requestedId) {
        // If there's no requested id, all items should re-render.
        if (!requestedId) {
            return true;
        }

        // The specified id and editor id match this tracks ids - re-render.
        if (itemId === requestedId) {
            return true;
        }

        return false;
    }

    /**
     * The listener function that waits for our dispatcher to dispatch a new update.
     * @param action - the action the dispatcher dispatched.
     */
    onDispatch(action) {
        switch (action.type) {
            case WorkerHistoryItemActionTypes.TOGGLE_ACTIONS:
                this.states[action.id].togggleActions = action.isTrue;
                this.emit(action.id);
                break;
        }
    }
}

export default angular.module('tonkean.app').service('workerHistoryItemStore', WorkerHistoryItemStore);
