import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DetailedItemsWidgetDrillDown from './DetailedItemsWidgetDrillDown';
import DetailedItemsWidgetDrillDownLoading from './DetailedItemsWidgetDrillDownLoading';
import DetailedItemsWidgetDrillDownNotSelected from './DetailedItemsWidgetDrillDownNotSelected';
import DetailedItemsWidgetItemsList from './DetailedItemsWidgetItemsList';
import DetailsItemsWidgetItemsListLoading from './DetailedItemsWidgetItemsListLoading';
import DetailedItemsWidgetNoItems from './DetailedItemsWidgetNoItems';
import { ItemInterfacePermission } from '../../../entities';
import InterfaceSubmitValidationContext from '../../../utils/InterfaceSubmitValidationContext';
import type { DetailedItemsWidgetItemInfo } from '../entities/DetailedItemsWidgetItemInfo';
import type NewAddedInitiativeInfo from '../entities/NewAddedInitiativeInfo';
import useDetailedItemsInfo from '../hooks/useDetailedItemsInfo';
import useSetDetailedItemsValidation from '../hooks/useSetDetailedItemsValidation';
import useSetItemValidation from '../hooks/useSetItemValidation';
import useSetSelectedItemsIdCallback from '../hooks/useSetSelectedItemsIdCallback';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    FieldError,
    NoItemEmptyState,
    useItemInterfaceContext,
    useOnClickOutside,
    useResizeObserver,
} from '@tonkean/infrastructure';
import { ItemInterfaceWidgetConfigurationUserAccessLevel } from '@tonkean/tonkean-entities';
import type {
    ActionInfo,
    FieldDefinitionKey,
    Initiative,
    TonkeanId,
    TonkeanType,
    ValidationState,
} from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    display: flex;
    height: 100%;
`;

const ItemsContainer = styled.div`
    flex: 0 0 30%;
    max-width: 300px;
    overflow: auto;
`;

const ItemDetailsContainer = styled.div<{ $minHeight: number }>`
    flex: 1;
    overflow: auto;
    min-height: ${({ $minHeight }) => $minHeight}px;
    background: ${Theme.colors.gray_300};
`;

export interface DetailedItemsWidgetBodyProps {
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    showNoItemAvailable: boolean;
    fieldsToShowKeys: FieldDefinitionKey[];
    loadingInitiatives: boolean;
    loadedInitiatives: Initiative[];
    newAddedInitiatives: NewAddedInitiativeInfo[];
    drillDownInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    interfacePermission: ItemInterfacePermission;
    widgetUserAccessLevel?: ItemInterfaceWidgetConfigurationUserAccessLevel;
    selectionColor: Color;
    fastReloadRequired?: () => void;
    inlineMenuActions: ActionInfo[];
    onDeleteInitiative: (initiativeId: TonkeanId<TonkeanType.INITIATIVE>) => void;
    deletedInitiativeIds: Set<TonkeanId<TonkeanType.INITIATIVE>>;
    minimumItemsRequired: number;
    isMinMaxValidationEnabled: boolean;
    maximumItemsAmount: number;
}

const DetailedItemsWidgetBody: React.FC<DetailedItemsWidgetBodyProps> = ({
    widgetId,
    showNoItemAvailable,
    fieldsToShowKeys,
    loadingInitiatives,
    loadedInitiatives,
    newAddedInitiatives,
    drillDownInterfaceId,
    projectId,
    interfacePermission,
    widgetUserAccessLevel,
    selectionColor,
    fastReloadRequired,
    inlineMenuActions,
    onDeleteInitiative,
    deletedInitiativeIds,
    minimumItemsRequired,
    isMinMaxValidationEnabled,
    maximumItemsAmount,
}) => {
    const [visitedItemInterfacesValidation, setVisitedItemInterfacesValidation] = useState<
        Record<TonkeanId<TonkeanType.INITIATIVE>, ValidationState>
    >({});
    const [, updateInitiativeArchiveState] = useLazyTonkeanService('updateInitiativeArchiveState');

    const { itemInterface, section } = useItemInterfaceContext();

    const itemsInfo: DetailedItemsWidgetItemInfo[] = useDetailedItemsInfo({
        loadedInitiatives,
        newAddedInitiatives,
        fieldsToShowKeys,
        deletedInitiativeIds,
    });

    const [selectedItemId, setSelectedItemId] = useState<TonkeanId<TonkeanType.INITIATIVE> | undefined>(undefined);
    const [prevNewAddedInitiatives, setPrevNewAddedInitiatives] = useState(newAddedInitiatives);

    const { forceShowValidation, setValidationKey, onValidationChanged } = useContext(InterfaceSubmitValidationContext);

    const [focused, setFocused] = useState<boolean>(false);
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

    const canUserAddOrDeleteItems = Boolean(
        widgetUserAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT_AND_CREATE,
    );

    const minMaxValidationErrors: string[] = useMemo(() => {
        if (!canUserAddOrDeleteItems) {
            return [];
        }

        const errors: string[] = [];
        const notValidMinRows = minimumItemsRequired ? itemsInfo.length < minimumItemsRequired : false;
        if (notValidMinRows) {
            errors.push(`You have not reached the required minimum of ${minimumItemsRequired} items`);
        }

        if (itemsInfo.length > maximumItemsAmount) {
            errors.push(`You have exceeded the maximum of ${maximumItemsAmount} items`);
        }

        return errors;
    }, [canUserAddOrDeleteItems, itemsInfo.length, maximumItemsAmount, minimumItemsRequired]);

    useEffect(() => {
        onValidationChanged(minMaxValidationErrors, widgetId);
    }, [onValidationChanged, minMaxValidationErrors, widgetId]);

    const ref = useOnClickOutside(
        () => {
            if (focused) {
                setShowValidationErrors(true);
            }
        },
        true,
        false,
    );

    const setDetailedItemsValidation = useSetDetailedItemsValidation(
        minMaxValidationErrors,
        visitedItemInterfacesValidation,
        setVisitedItemInterfacesValidation,
        setValidationKey,
        widgetId,
    );

    if (newAddedInitiatives.length > prevNewAddedInitiatives.length) {
        setSelectedItemId(newAddedInitiatives[0]?.id);
        setPrevNewAddedInitiatives(newAddedInitiatives);
    }

    // This prevents the item details container from changing its height when moving between items and improves the UX
    const [itemDetailsContainerMinHeight, setItemDetailsContainerMinHeight] = useState(0);
    const { setNode } = useResizeObserver((entry) => {
        setItemDetailsContainerMinHeight(Math.max(entry.contentRect.height, itemDetailsContainerMinHeight));
    });

    const currentlySelectedItemId = selectedItemId || itemsInfo[0]?.id;

    const handleItemDeletion = useCallback(
        (deletedItemId: TonkeanId<TonkeanType.INITIATIVE>) => {
            const deletedItemIndex = itemsInfo.findIndex((item) => item.id === deletedItemId);
            if (deletedItemIndex === -1) {
                return;
            }

            if (deletedItemId === currentlySelectedItemId) {
                if (deletedItemIndex < itemsInfo.length - 1) {
                    setSelectedItemId(itemsInfo[deletedItemIndex + 1]?.id); // If not last take next item
                } else if (deletedItemIndex === itemsInfo.length - 1 && deletedItemIndex !== 0) {
                    setSelectedItemId(itemsInfo[deletedItemIndex - 1]?.id); // If last index take previous item
                } else {
                    setSelectedItemId(undefined); // No items left
                }
            }

            onDeleteInitiative(deletedItemId);
            updateInitiativeArchiveState(deletedItemId, true, '');
            const { [deletedItemId]: _, ...newVisitedItemInterfacesValidation } = visitedItemInterfacesValidation;
            setVisitedItemInterfacesValidation(newVisitedItemInterfacesValidation);
        },
        [
            visitedItemInterfacesValidation,
            itemsInfo,
            currentlySelectedItemId,
            onDeleteInitiative,
            updateInitiativeArchiveState,
        ],
    );

    const inInterfaceEditMode = interfacePermission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;
    const viewOnly =
        !Boolean(
            [ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM, ItemInterfacePermission.USER_CAN_EDIT_ITEM].includes(
                interfacePermission,
            ),
        ) || !canUserAddOrDeleteItems;

    const setItemValidation = useSetItemValidation(
        viewOnly,
        currentlySelectedItemId,
        visitedItemInterfacesValidation,
        setDetailedItemsValidation,
    );

    useEffect(() => {
        if (forceShowValidation && currentlySelectedItemId) {
            visitedItemInterfacesValidation[currentlySelectedItemId]?.setForceShowValidation?.(true);
        }
    }, [forceShowValidation, currentlySelectedItemId, visitedItemInterfacesValidation]);

    const setSelectedItemIdCallback = useSetSelectedItemsIdCallback(
        currentlySelectedItemId,
        setSelectedItemId,
        visitedItemInterfacesValidation,
        setDetailedItemsValidation,
    );

    if (showNoItemAvailable) {
        return <NoItemEmptyState />;
    }

    return (
        <>
            <Container ref={ref} onClick={() => setFocused(true)} data-automation="item-details-widget-container">
                {!loadingInitiatives && itemsInfo.length === 0 ? (
                    <DetailedItemsWidgetNoItems />
                ) : (
                    <>
                        <ItemsContainer>
                            {loadingInitiatives ? (
                                <DetailsItemsWidgetItemsListLoading />
                            ) : (
                                <DetailedItemsWidgetItemsList
                                    itemsInfo={itemsInfo}
                                    selectedItemId={currentlySelectedItemId}
                                    setSelectedItemId={setSelectedItemIdCallback}
                                    selectionColor={selectionColor}
                                    inlineMenuActions={inlineMenuActions}
                                    onDelete={handleItemDeletion}
                                    projectId={projectId}
                                    workflowVersionType={itemInterface.workflowVersionType}
                                    viewOnly={viewOnly}
                                    visitedItemInterfacesValidation={visitedItemInterfacesValidation}
                                    forceShowValidation={forceShowValidation}
                                />
                            )}
                        </ItemsContainer>
                        <ItemDetailsContainer ref={setNode} $minHeight={itemDetailsContainerMinHeight}>
                            {loadingInitiatives ? (
                                <DetailedItemsWidgetDrillDownLoading />
                            ) : !drillDownInterfaceId ? (
                                <DetailedItemsWidgetDrillDownNotSelected inInterfaceEditMode={inInterfaceEditMode} />
                            ) : (
                                currentlySelectedItemId &&
                                drillDownInterfaceId && (
                                    <DetailedItemsWidgetDrillDown
                                        drillDownInterfaceId={drillDownInterfaceId}
                                        selectedItemId={currentlySelectedItemId}
                                        widgetId={widgetId}
                                        projectId={projectId}
                                        viewOnly={viewOnly}
                                        fastReloadRequired={fastReloadRequired}
                                        onValidationChangedCallback={setItemValidation}
                                        section={section}
                                    />
                                )
                            )}
                        </ItemDetailsContainer>
                    </>
                )}
            </Container>
            {isMinMaxValidationEnabled &&
                (forceShowValidation || showValidationErrors) &&
                minMaxValidationErrors.length > 0 && (
                    <FieldError
                        $inlineError={false}
                        $textAlign="right"
                        data-automation="item-details-widget-minimum-items-error-message"
                    >
                        {minMaxValidationErrors.map((errorMessage, index) => (
                            <div key={index}>{errorMessage}</div>
                        ))}
                    </FieldError>
                )}
        </>
    );
};

export default DetailedItemsWidgetBody;
