import NumericBinaryOperatorBase from './NumbericBinaryOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';

export default class PowOperator extends NumericBinaryOperatorBase {
    override readonly key = OperatorKey.POW;
    override readonly sign = '^';
    override readonly displayName = 'Pow';
    override readonly description = 'Will raise the left hand field to the power of the right hand field.';
}
