import React, { useState } from 'react';

import DuplicateItemModal from '../../../../SystemInterface/components/ItemInterface/DuplicateItemModal';

import { TextEllipsis, useProject } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { InterfaceCTASavedCloneItemAction } from '@tonkean/tonkean-entities';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    action: InterfaceCTASavedCloneItemAction;
    buttonBackground: Color;
    disabled?: boolean;
    disabledTooltip?: string;
}

const CloneItemActionButton: React.FC<Props> = ({ className, action, buttonBackground, disabled, disabledTooltip }) => {
    const { initiative, itemInterface } = useItemInterfaceContext();
    const project = useProject();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);
    const [duplicateInitiativeModalOpen, setDuplicateInitiativeModalOpen] = useState(false);

    return (
        <>
            <Tooltip
                dataAutomation="action-button-tooltip"
                placement="bottom"
                content={disabledTooltip}
                disabled={!disabled}
            >
                <UserThemedClickableButton
                    data-automation="action-button"
                    className={className}
                    background={buttonBackground}
                    onClick={(event) => {
                        setDuplicateInitiativeModalOpen(true);
                    }}
                    disabled={disabled || !initiative}
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {action.label || 'Clone Item'}
                    </TextEllipsis>
                </UserThemedClickableButton>
            </Tooltip>
            {initiative && (
                <DuplicateItemModal
                    open={duplicateInitiativeModalOpen}
                    onClose={() => setDuplicateInitiativeModalOpen(false)}
                    itemInterface={itemInterface}
                    initiative={initiative}
                    backgroundColor={themeConfiguration.headerBackgroundColor}
                />
            )}
        </>
    );
};

export default CloneItemActionButton;
