import React, { useMemo } from 'react';

import type { SmartSearchSuggestAction, TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { StateLinkButton } from '@tonkean/tui-buttons/Button';
import Utils from '@tonkean/utils';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    action: SmartSearchSuggestAction;
    conversationId?: string;
}

const SmartSearchActionButton: React.FC<Props> = ({ projectId, action, conversationId }) => {
    const initialValues = useMemo(() => {
        const valuesJson = action.actionFields
            .filter((field) => field.id && field.value)
            .reduce((accumulator, current) => {
                return { ...accumulator, [current.id]: current.value };
            }, {});
        try {
            const result = Utils.toBase64(JSON.stringify(valuesJson));
            return result;
        } catch (error) {
            console.error(`Error converting value from base 64. [${JSON.stringify(valuesJson)}]`, error);
        }
    }, [action.actionFields]);

    const actionState = action.tonkeanType === TonkeanType.FORM ? 'form' : 'product.intakeInterface';

    const actionParams = action.actionId.startsWith('FORM')
        ? {
              projectId,
              formId: action.actionId,
              formVersionType: WorkflowVersionType.PUBLISHED,
              initialValues,
          }
        : {
              projectId,
              customTriggerId: action.actionId,
              workflowVersionType: WorkflowVersionType.PUBLISHED,
              initialValues,
              conversationId,
          };

    return (
        <StateLinkButton key={action.actionName} state={actionState} params={actionParams} openInNewTab promotion>
            {action.actionName}
        </StateLinkButton>
    );
};

export default SmartSearchActionButton;
