import React from 'react';

import { ReactComponent as CheckLgIcon } from '../../../../../images/icons/check-lg.svg';

interface Props {
    isDirty: boolean;
    isValid: boolean;
}

const FormSettingsUploadLogoIcon: React.FC<Props> = ({ isDirty, isValid }) => {
    if (isDirty && isValid) {
        return (
            <span className="svg-icon-sm">
                <span className="tnk-icon">
                    <CheckLgIcon />
                </span>
            </span>
        );
    }

    return <></>;
};

export default FormSettingsUploadLogoIcon;
