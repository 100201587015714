import React from 'react';

import WorkerRunsFiltersLabel from '../../HistoryModule/components/WorkerRuns/WorkerRunsForm/WorkerRunsFiltersPopover/WorkerRunsFiltersLabel';
import initialSolutionVersionsFilters from '../entities/initialSolutionVersionsFilters';
import type SolutionVersionsFilters from '../entities/SolutionVersionsFilters';

import { FilterDateRange, FilterRow, FiltersGroup, Input, useSubmittableFilters } from '@tonkean/infrastructure';
import { PersonSelect } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { filtersHelper } from '@tonkean/utils';

export const publishSolutionSidePaneFiltersHelper = filtersHelper(initialSolutionVersionsFilters);

interface Props {
    workflowFolder: WorkflowFolder;
    filters: SolutionVersionsFilters;
    onChange(filters: SolutionVersionsFilters);
}

const SolutionVersionsFiltersPopover: React.FC<Props> = ({ workflowFolder, filters: previousFilters, onChange }) => {
    const [{ onSubmit, onClear, canSubmit, canClear }, filters, setFilters] = useSubmittableFilters(
        previousFilters,
        initialSolutionVersionsFilters,
        onChange,
    );

    return (
        <FiltersGroup submitDisabled={!canSubmit} onSubmit={onSubmit} clearDisabled={!canClear} onClear={onClear}>
            <FilterRow label="Version ID">
                <Input
                    type="number"
                    min={1}
                    value={filters.sequentialIdentifier || ''}
                    onChange={(event) => {
                        setFilters({ ...filters, sequentialIdentifier: event.target.value });
                    }}
                />
            </FilterRow>
            <FilterRow label="Publisher">
                <PersonSelect
                    value={filters.publisherId}
                    onChange={(publisherId) => setFilters({ ...filters, publisherId })}
                    options={workflowFolder.owners}
                    isClearable
                    thin
                />
            </FilterRow>
            <WorkerRunsFiltersLabel label="Date">
                <FilterDateRange
                    fromDate={filters.fromDate}
                    toDate={filters.toDate}
                    onFromDateChange={(fromDate) => setFilters({ ...filters, fromDate })}
                    onToDateChange={(toDate) => setFilters({ ...filters, toDate })}
                    showTimeSelect
                />
            </WorkerRunsFiltersLabel>
        </FiltersGroup>
    );
};

export default SolutionVersionsFiltersPopover;
