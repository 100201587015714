import { analyticsWrapper } from '@tonkean/analytics';

function WorkerEmailButtonAnswerModalCtrl(
    $scope,
    $uibModalInstance,
    utils,
    tonkeanService,
    callbackId,
    value,
    hideModalButtons,
) {
    $scope.data = {
        posting: true,
        isSuccess: false,
        isError: false,
        hideModalButtons,
    };

    $scope.init = function () {
        $scope.data.isError = false;
        $scope.data.isSuccess = false;

        if (!utils.isNullOrEmpty(callbackId) && !utils.isNullOrEmpty(value)) {
            // We have the data we need. Post the answer to the server.
            tonkeanService
                .postEmailWorkerAnswer(callbackId, value)
                .then(() => {
                    $scope.data.isSuccess = true;
                })
                .catch(() => {
                    $scope.data.isError = true;
                })
                .finally(() => {
                    $scope.data.posting = false;
                });
        } else {
            // If we didn't have a callback id nor a value, close the modal and emit an error.
            $scope.$emit('alert', 'Error posting your answer.');
            $uibModalInstance.dismiss('cancel');
        }
    };

    $scope.cancel = function () {
        analyticsWrapper.track('Cancel', { category: 'workerEmailButtonAnswerModal' });
        $uibModalInstance.dismiss('cancel');
    };

    $scope.init();
}
export default angular
    .module('tonkean.app')
    .controller('WorkerEmailButtonAnswerModalCtrl', WorkerEmailButtonAnswerModalCtrl);
