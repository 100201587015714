import defaultCustomGetHttpActionDefinition from './defaultCustomGetHttpActionDefinition';

import type { HttpRequestDefinition } from '@tonkean/tonkean-entities';
import { ContainsCriteriaType } from '@tonkean/tonkean-entities';

const defaultCustomPaginatedHttpActionDefinition: HttpRequestDefinition = {
    ...defaultCustomGetHttpActionDefinition,
    pagination: {
        enabled: false,
        nextPageParameters: [],
        stopCriteria: { type: ContainsCriteriaType.NOT_CONTAINS, keywords: [] },
        stopCriteriaExpression: {
            originalExpression: 'false',
            evaluatedExpression: 'false',
            isStripHtmlDisabled: false,
        },
    },
};

export default defaultCustomPaginatedHttpActionDefinition;
