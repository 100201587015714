import React from 'react';

import type MarketplaceItemKeyMetricTrendType from './MarketplaceItemKeyMetricTrendType';
import { ReactComponent as ArrowDownGreenIcon } from '../../images/arrow-down-green.svg';
import { ReactComponent as ArrowDownRedIcon } from '../../images/arrow-down-red.svg';
import { ReactComponent as ArrowUpGreenIcon } from '../../images/arrow-up-green.svg';
import { ReactComponent as ArrowUpRedIcon } from '../../images/arrow-up-red.svg';

const marketplaceItemKeyMetricTrendTypeToIcon: Record<MarketplaceItemKeyMetricTrendType, JSX.Element> = {
    GREEN_UP: <ArrowUpGreenIcon />,
    GREEN_DOWN: <ArrowDownGreenIcon />,
    RED_UP: <ArrowUpRedIcon />,
    RED_DOWN: <ArrowDownRedIcon />,
} as const;

export default marketplaceItemKeyMetricTrendTypeToIcon;
