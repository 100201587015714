<div class="tonkean-action-logic-configuration">
    <!-- Header -->
    <div ng-if="data.selectedAction.type !== 'CREATE_TRACK'" class="flex-vmiddle padding-left margin-bottom">
        <!-- Action -->
        <div class="flex-grow">{{ data.selectedAction.title }}</div>
        <!-- Back button -->
        <div
            class="common-size-xxxs margin-left margin-right"
            ng-if="data.selectedAction"
            uib-tooltip="Remove current configuration and choose a different action"
            tooltip-placement="left-bottom"
        ></div>
    </div>

    <!-- No action selected -->
    <div ng-if="!data.selectedAction">
        <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noAction" class="common-color-danger margin-left">
            {{ data.invalidLogics[data.configuredLogic.node.id].noAction }}
        </div>

        <div ng-repeat="action in data.actions" ng-click="selectAction(action)">
            <!-- Title -->
            <div
                class="common-size-xxxs padding-left text-uppercase margin-bottom margin-top-lg pointer"
                ng-class="{
                    'common-disabled':
                        (data.configuredLogic.impacts.length > 0 && action.type === 'ARCHIVE_TRACK') ||
                        (data.groupIdsInSolution.length === 1 && action.type === 'CREATE_TRACK')
                }"
            >
                {{ action.title }}
                <i
                    ng-if="data.configuredLogic.impacts.length > 0 && action.type === 'ARCHIVE_TRACK'"
                    uib-tooltip='You can&apos;t archive the item here, since there are subsequent actions that depend on the item. To use "Archive item" it must be the last action in the flow.'
                    class="fa fa-info-circle margin-left'"
                ></i>
                <i
                    ng-if="data.groupIdsInSolution.length === 1 && action.type === 'CREATE_TRACK'"
                    uib-tooltip="There are no other modules in this solution. Create another module to use this action"
                    class="fa fa-info-circle margin-left'"
                ></i>
            </div>
            <hr class="margin-none" />
        </div>
    </div>

    <!-- Action selected -->
    <div ng-if="data.selectedAction">
        <!-- Separator -->
        <hr ng-if="data.selectedAction.type !== 'CREATE_TRACK'" class="margin-bottom-xs margin-top-xs" />

        <div ng-switch="data.selectedAction.type">
            <div
                class="common-color-danger margin-left"
                ng-if="data.invalidLogics[data.configuredLogic.node.id].hasChildren"
            >
                {{ data.invalidLogics[data.configuredLogic.node.id].hasChildren }}
            </div>
            <!-- CREATE_TRACK -->
            <div ng-switch-when="CREATE_TRACK">
                <tnk-create-track-action-definition
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    workflow-version-type="{{ data.workflowVersionType }}"
                    configured-logic="data.configuredLogic"
                    definition="data.innerActionDefinition"
                    preview-evaluation-source="data.previewEvaluationSource"
                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                    on-definition-changed="onActionsChanged(shouldSaveLogic)"
                    group-option-ids="data.groupIdsInSolution"
                ></tnk-create-track-action-definition>
            </div>

            <!-- ARCHIVE TRACK -->
            <div ng-switch-when="ARCHIVE_TRACK"></div>

            <!-- ADD COMMENT -->
            <div ng-switch-when="ADD_COMMENT">
                <tnk-add-comment-action-definition
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    configured-logic="data.configuredLogic"
                    definition="data.innerActionDefinition"
                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                    preview-evaluation-source="data.previewEvaluationSource"
                    on-definition-changed="onActionsChanged(shouldSaveLogic)"
                ></tnk-add-comment-action-definition>
            </div>
        </div>
    </div>
</div>
