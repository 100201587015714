import React, { useCallback } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import { WidgetConfigurationFirstFieldSelector } from '../../CommonWidgetConfiguration';
import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration';
import { useWidgetConfigurationDeletedFields } from '../../hooks';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../../hooks';
import type FieldChartWidgetConfiguration from '../FieldChartWidgetConfiguration';
import type FieldChartWidgetConfigurationDefinition from '../FieldChartWidgetConfigurationDefinition';

import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import { FieldChartWidgetConstants } from '@tonkean/fields';
import { ChartDataType, LoadingCircle, useFormikField } from '@tonkean/infrastructure';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const FiltersContainer = styled.div`
    padding: 0 20px;
    padding-bottom: 20px;
`;

export const SelectorTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    margin-top: 16px;
    padding-bottom: 8px;
`;

interface Props {
    configuration: FieldChartWidgetConfiguration;
    workflowVersion: WorkflowVersion;
    isSolutionSite?: boolean;
}

const FieldChartWidgetWorkflowVersionFieldSelector: React.FC<Props> = ({
    configuration,
    workflowVersion,
    isSolutionSite,
}) => {
    const { setValue: setDefinition } = useFormikField<FieldChartWidgetConfigurationDefinition | undefined>(
        'configuration.definition',
    );

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(
        workflowVersion.id,
        ['ITEM_INTERFACE_CHART_WIDGET'],
        undefined,
        configuration.chartDataType === ChartDataType.HISTORICAL ? FieldType.Number : undefined,
        configuration.chartDataType === ChartDataType.CATEGORICAL,
        isSolutionSite,
    );

    const deletedFieldsIds = useWidgetConfigurationDeletedFields(
        allFieldDefinitions,
        Object.keys(
            configuration.chartDataType === ChartDataType.HISTORICAL
                ? configuration.fields
                : configuration.categoricalFields || {},
        ),
    );

    const updateConfigurationDefinition = useCallback(
        (fieldDefinition: Record<FieldDefinitionKey, SingleFieldConfiguration>) => {
            if (
                Object.entries(fieldDefinition).length > 0 &&
                configuration.chartDataType === ChartDataType.CATEGORICAL
            ) {
                const field: FieldDefinition | undefined = allFieldDefinitions.find(
                    (field) => field.id === Object.keys(fieldDefinition)[0],
                );
                if (field) {
                    const fieldDefinition = field.definition as any;
                    const fieldIsGroupBy =
                        (fieldDefinition?.aggregationType === FieldChartWidgetConstants.TERMS &&
                            (fieldDefinition?.formulaType === FieldChartWidgetConstants.STRUCTURED ||
                                field.type === FieldChartWidgetConstants.AGGREGATE_QUERY)) ||
                        (fieldDefinition?.innerTracksAggregationDefinition?.aggregationType ===
                            FieldChartWidgetConstants.TERMS &&
                            fieldDefinition?.innerTracksAggregationDefinition.formulaType ===
                                FieldChartWidgetConstants.STRUCTURED);
                    if (fieldIsGroupBy) {
                        setDefinition(undefined);
                        return;
                    }
                    const widgetDefinition: FieldChartWidgetConfigurationDefinition = {
                        formulaType: FieldChartWidgetConstants.STRUCTURED,
                        aggregationType: FieldChartWidgetConstants.TERMS,
                        aggregatedDefinitionId: field.id.toString(),
                        aggregatedDefinitionName: field.name,
                        orderByOrder: 'ASC',
                        shouldRunRecursivelyOnParents: false,
                        groupId: workflowVersion.groupId,
                        fieldType: field.fieldType,
                    };
                    setDefinition(widgetDefinition);
                    return;
                }
            } else if (
                Object.entries(fieldDefinition).length === 0 &&
                configuration.chartDataType === ChartDataType.CATEGORICAL
            ) {
                setDefinition(undefined);
            }
        },
        [allFieldDefinitions, configuration.chartDataType, setDefinition, workflowVersion],
    );

    if (loading || !allFieldDefinitions) {
        return <LoadingCircle large centered />;
    }

    return (
        <>
            {configuration.chartDataType === ChartDataType.CATEGORICAL && (
                <FiltersContainer>
                    <ConfigurationSectionTitle>Items</ConfigurationSectionTitle>
                    <SelectorTitle>Filter Items</SelectorTitle>
                    <FormikTnkCustomFilters
                        name="configuration.filters"
                        itemsSource="COLUMN"
                        groupId={workflowVersion.groupId}
                        workflowVersionId={workflowVersion.id}
                        specialFieldsForFeatures={['ITEM_INTERFACE_CHART_WIDGET']}
                        useExpressionsForValue={false}
                        hideTimeRangeSelection
                        hideCloseButton
                        editMode
                        verticalMod
                        smallMod
                    />
                </FiltersContainer>
            )}
            <WidgetConfigurationFirstFieldSelector
                allFieldDefinitions={allFieldDefinitions}
                groups={undefined}
                deletedFieldsIds={deletedFieldsIds}
                workflowVersion={workflowVersion}
                workflowVersionType={workflowVersion.workflowVersionType}
                enableSearch={false}
                fieldConfigurationPath={
                    configuration.chartDataType === ChartDataType.HISTORICAL
                        ? 'configuration.fields'
                        : 'configuration.categoricalFields'
                }
                onFieldChanged={updateConfigurationDefinition}
            />
        </>
    );
};
export default FieldChartWidgetWorkflowVersionFieldSelector;
