import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ExtendedMatchConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        logicId: ctrl.logicId,
        entityMetadata: ctrl.entityMetadata,
        entitiesMetadata: ctrl.entitiesMetadata,
        entities: ctrl.entities,
        validationObject: ctrl.validationObject,
        existingExtendedMatchConfiguration: ctrl.existingExtendedMatchConfiguration,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        projectIntegration: ctrl.projectIntegration,
        modFixedWidth: ctrl.modFixedWidth,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        currentCustomTrigger: ctrl.currentCustomTrigger,
        uniqueId: Math.random(),

        extendedMatchConfiguration: ctrl.existingExtendedMatchConfiguration || {
            extendedMatchConfigurationType: 'WORKFLOW',
        },
    };

    /**
     * Initialization function for the controller.
     */
    ctrl.$onInit = function () {};

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }

        if (changes.projectIntegration) {
            // if the project integration has been changed since init we reset the match config
            if ($scope.data.projectIntegration !== ctrl.projectIntegration) {
                $scope.selectExtendedMatchConfigurationType('WORKFLOW');
            }

            $scope.data.projectIntegration = ctrl.projectIntegration;
        }
    };

    /**
     * Occurs once the file id input selection type is changed.
     */
    $scope.selectExtendedMatchConfigurationType = function (onClickParam) {
        $scope.data.extendedMatchConfiguration.extendedMatchConfigurationType = onClickParam;
        $scope.onExtendedMatchConfigurationChanged(true);
    };

    /**
     * Occurs once the field to extract from id expression is changed.
     */
    $scope.onExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.extendedMatchConfiguration.uiExpression = expression;
        $scope.onExtendedMatchConfigurationChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the workflow item selection is changed.
     */
    $scope.onWorkflowItemSelectionChanged = function (
        performOnWorkerItem,
        creatingCustomTriggerId,
        idRelationFieldDefinitionId,
        shouldSaveLogic,
    ) {
        $scope.data.extendedMatchConfiguration.matchConfiguration = {
            matchOption: 'SPECIFIC_ITEM',
            performOnWorkerItem,
            creatingCustomTriggerId,
            idRelationFieldDefinitionId,
        };
        $scope.onExtendedMatchConfigurationChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of match conditions.
     */
    $scope.onWorkflowMatchByFieldChanged = function (
        entityMatchField,
        workerItemMatchFieldDefinition,
        workerItemMatchSpecialField,
        shouldSaveLogic,
    ) {
        $scope.data.extendedMatchConfiguration.matchConfiguration = {
            matchOption: 'MATCH_FIELD_RULES',
            entityMatchField,
            workerItemMatchFieldDefinition,
            workerItemMatchSpecialField,
        };
        $scope.onExtendedMatchConfigurationChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of match query.
     */
    $scope.onWorkflowMatchQueryChanged = function (selectionObject, shouldSaveLogic) {
        $scope.data.extendedMatchConfiguration.matchConfiguration = selectionObject;
        $scope.onExtendedMatchConfigurationChanged(shouldSaveLogic);
    };

    /**
     * Occurs on configuration changed.
     */
    $scope.onExtendedMatchConfigurationChanged = function (shouldSaveLogic) {
        if (ctrl.onExtendedMatchConfigurationChanged) {
            ctrl.onExtendedMatchConfigurationChanged({
                extendedMatchConfiguration: $scope.data.extendedMatchConfiguration,
                shouldSaveLogic,
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('ExtendedMatchConfigurationCtrl', lateConstructController(ExtendedMatchConfigurationCtrl));
