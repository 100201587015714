import type { Dispatch, SetStateAction } from 'react';
import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { Radio, RadioGroup, SavingIndicator, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { UpdateForm } from '@tonkean/tonkean-entities';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const FormQuestionTypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FormQuestionTypeRadioGroup = styled.div`
    margin-top: 16px;
`;

interface Props {
    form: UpdateForm;
    onFormChanged: Dispatch<SetStateAction<UpdateForm>>;
}

const FormSettingsQuestionType: React.FC<Props> = ({ form, onFormChanged }) => {
    const { editedSections, setSectionLoading, isEditable } = useContext(FormBuilderContext);

    const onChange = (value: FormQuestionType) => {
        onFormChanged((prevForm) => ({ ...prevForm, formQuestionType: value }));
        setSectionLoading(FormBuilderSections.FormType);
    };

    return (
        <FormQuestionTypeWrapper>
            <HeaderWrapper>
                <FormSettingsSectionHeaderTitle>Form Type:</FormSettingsSectionHeaderTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.FormType]} />
            </HeaderWrapper>
            <FormQuestionTypeRadioGroup>
                <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                    <span>
                        <RadioGroup value={form.formQuestionType} onChange={onChange} direction="row">
                            <Radio
                                dataAutomation="update-fields"
                                value={FormQuestionType.UPDATE_FIELDS}
                                disabled={!isEditable}
                            >
                                Update Fields
                            </Radio>
                            <Radio
                                dataAutomation="collect-inner-items"
                                value={FormQuestionType.COLLECT_INNER_ITEMS}
                                disabled={!isEditable}
                            >
                                Collect Inner Items
                            </Radio>

                            <Radio
                                dataAutomation="upload-files"
                                value={FormQuestionType.UPLOAD_FILES}
                                disabled={!isEditable}
                            >
                                Upload Files
                            </Radio>
                        </RadioGroup>
                    </span>
                </Tooltip>
            </FormQuestionTypeRadioGroup>
        </FormQuestionTypeWrapper>
    );
};

export default FormSettingsQuestionType;
