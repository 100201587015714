<div class="preview-list-columns" ng-class="{ 'mod-no-header': data.hidePreviewTitle }">
    <!-- Title -->
    <div class="flex-vmiddle common-bold common-size-xxs margin-right-xs" ng-if="!data.hidePreviewTitle">
        <!-- Subtitle -->
        <div ng-if="data.isBrokenIntegration" class="margin-top-xs common-color-danger common-size-xxs">
            <i class="fa fa-exclamation common-color-danger"></i>
            <span>The preview data might not be updated, since its connection with Tonkean is broken.</span>
        </div>
    </div>

    <div class="relative margin-bottom-lg">
        <!-- Loading Circle-->
        <i ng-if="data.softLoading" class="loading-small absolute-left-overflow margin-top-xs margin-right"></i>

        <!-- List Inspect -->
        <div class="items-preview-title">
            Preview Items
            <i
                class="info-icon fa fa-info-circle"
                uib-tooltip="Preview of the items with the involving fields"
                tooltip-append-to-body="true"
            ></i>
        </div>
        <!-- Preview title -->
        <div ng-if="!data.hidePreviewTitle">
            <!-- Filter bar -->
            <div class="margin-bottom flex-vmiddle">
                <div class="search-items">
                    <!-- Search icon -->
                    <span
                        class="svg-icon-sm flex-no-shrink common-color-grey margin-right-xs search-icon"
                        ng-if="!data.searchingInitiatives"
                    >
                        <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                    </span>

                    <!-- Loading icon -->
                    <i class="loading-small margin-right-xs" ng-if="data.searchingInitiatives"></i>

                    <!-- Search box -->
                    <input
                        type="text"
                        class="form-control common-no-border common-no-outline search-text-box padding-left-none"
                        ng-class="{ 'common-color-grey': utils.isNullOrUndefined(data.previewListTextSearch) }"
                        placeholder="Search Items"
                        ng-model="data.previewListTextSearch"
                        ng-change="searchInitiatives()"
                    />
                </div>
            </div>
        </div>
        <div>
            <!-- Preview key metrics if requested -->
            <tnk-preview-key-metrics
                ng-if="data.globalFieldsToPreview"
                hide-preview-title="true"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                definitions-to-preview="data.globalFieldsToPreview"
                highlighted-definition-id="{{ data.globalFieldsToPreview[0].definitionId }}"
                reload-preview="data.reloadKeyMetricsPreview"
                soft-loading="data.softLoading"
                is-broken-integration="data.isBrokenIntegration"
            ></tnk-preview-key-metrics>

            <!-- Loading state -->
            <div ng-if="data.loading && !data.softLoading">
                <hr class="margin-none" />
                <div class="placeholder-container" ng-repeat="item in [1,2,3,4,5,6,7,8,9]">
                    <div class="flex-vmiddle padding-normal">
                        <div class="big-loading-placeholder"></div>
                        <div class="small-loading-placeholder"></div>
                    </div>
                    <hr class="margin-none" />
                </div>
            </div>

            <!-- Error state -->
            <div ng-if="!data.loading && data.error" class="common-size-xxs common-color-danger">
                <span class="common-bold">{{ data.error }}</span>
                <a ng-click="reloadPreview()">Refresh</a>
            </div>

            <!-- Empty state - SYNC_OR_IMPORT (also the default empty state if no type was given) -->
            <div
                ng-if="
                    !data.loading &&
                    !data.error &&
                    utils.isNullOrUndefined(data.previewListTextSearch) &&
                    data.noInitiativesReturned &&
                    (!data.emptyStateType || data.emptyStateType === 'SYNC_OR_IMPORT')
                "
                class="padding-normal-sm alert-warning inline-block common-color-black empty-state-box"
            >
                To preview your {{ data.targetType === 'COLUMN' ? 'column' : 'key metric' }},
                <a
                    ng-click="modalUtils.openSyncTracksModal(data.groupId, data.workflowVersionId, data.workflowVersionId)"
                >
                    Sync
                </a>
                or
                <a ng-click="modalUtils.openImportTracks(null, null, data.groupId, data.workflowVersionId)">Import</a>
                items to your module.
                <br />
                It's currently empty.
            </div>
            <!-- Empty state - NO_RESULTS -->
            <div
                ng-if="
                    !data.loading &&
                    !data.error &&
                    utils.isNullOrUndefined(data.previewListTextSearch) &&
                    data.noInitiativesReturned &&
                    data.emptyStateType === 'NO_RESULTS'
                "
                class="padding-normal-sm alert-warning inline-block common-color-black empty-state-box"
            >
                No results returned for the filters.
            </div>

            <!-- Items state -->
            <div
                ng-if="
                    (!data.loading || data.softLoading) &&
                    !data.error &&
                    (!data.noInitiativesReturned || !utils.isNullOrUndefined(data.previewListTextSearch))
                "
                class="relative initiative-preview-data-container"
            >
                <!-- Header -->
                <div class="flex-vmiddle">
                    <!-- Title header -->
                    <div class="flex-vmiddle title-column-header common-color-grey">Item title</div>

                    <!-- Definitions -->
                    <div
                        ng-repeat="definitionToPreview in data.definitionsToPreview"
                        class="
                            initiative-field-value-header
                            padding-right-xs padding-bottom-xs padding-top
                            common-ellipsis
                        "
                        ng-class="::getColumnWidthClass(definitionToPreview.definitionId)"
                        uib-tooltip="{{ definitionToPreview.name }}"
                    >
                        <!-- Highlight column border -->
                        <div
                            ng-if="data.highlightedDefinitionId === definitionToPreview.definitionId"
                            ng-class="::getColumnWidthClass(definitionToPreview.definitionId)"
                            class="initiative-field-value-highlighted"
                        ></div>
                        <!-- Field Name -->
                        <span class="common-color-grey">
                            {{ definitionToPreview.name ? definitionToPreview.name : 'Field name' }}
                        </span>
                    </div>
                </div>

                <!-- Header separator -->
                <hr class="margin-top-none margin-bottom-none" />

                <!-- Empty search results for text search state -->
                <div
                    class="margin-top"
                    ng-if="data.noInitiativesReturned && !utils.isNullOrUndefined(data.previewListTextSearch)"
                >
                    <!-- Text for searching state -->
                    <span ng-if="data.searchingInitiatives">Searching items...</span>
                    <!-- Text for not searching state -->
                    <span ng-if="!data.searchingInitiatives">
                        No results returned for
                        <strong>{{ data.previewListTextSearch }}</strong>
                        .
                    </span>
                </div>

                <!-- All initiatives results -->
                <div ng-repeat="(initiativeId, fieldValues) in data.initiativeIdToFieldValuesMap">
                    <div class="flex-vmiddle">
                        <!-- Initiative title -->
                        <div class="title-column-value common-size-xxs padding-right-xlg flex-vmiddle">
                            <!-- Title -->
                            <div>
                                <button
                                    type="button"
                                    ng-click="openTrackViewInNewTab(initiativeId)"
                                    class="item-inspect-link inline-button"
                                >
                                    <tnk-text-ellipsis
                                        number-of-lines="1"
                                        tooltip="true"
                                        children="fieldValues.title"
                                    ></tnk-text-ellipsis>
                                </button>
                            </div>
                        </div>

                        <!-- Field value -->
                        <div
                            ng-repeat="definitionToPreview in data.definitionsToPreview"
                            class="
                                initiative-field-value-container
                                padding-top-lg
                                common-bold common-ellipsis
                                padding-right-xs
                                margin-right
                            "
                            ng-class="::getColumnWidthClass(definitionToPreview.definitionId)"
                        >
                            <span
                                ng-style="fieldValues.definitionIdToValueMap[definitionToPreview.definitionId][0].style"
                                class="padding-normal-xs"
                                uib-tooltip="{{
                                    fieldValues.definitionIdToValueMap[definitionToPreview.definitionId][0].displayValue
                                }}"
                                tooltip-placement="auto right"
                                tooltip-enable="{{
                                    fieldValues.definitionIdToValueMap[definitionToPreview.definitionId][0]
                                        .isMultiValuesField
                                }}"
                            >
                                {{
                                    fieldValues.definitionIdToValueMap[definitionToPreview.definitionId][0]
                                        .isMultiValuesField
                                        ? 'Multiple'
                                        : fieldValues.definitionIdToValueMap[definitionToPreview.definitionId][0]
                                              .displayValue
                                }}
                            </span>
                        </div>
                    </div>

                    <!-- Separator -->
                    <hr ng-if="!$last" class="margin-none" />
                </div>
            </div>
        </div>
    </div>
</div>
