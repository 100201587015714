import React, { useState } from 'react';
import styled from 'styled-components';

import ViewDataAddRecordModal from '../../../../../../components/common/tnkViewProjectIntegrationData/ViewDataAddRecordModal';
import SingleExternalActivityInspect from '../ExternalActivityInspect';

import { Placeholder } from '@tonkean/infrastructure';
import type { ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 47px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledModelOpenButton = styled(Button)`
    cursor: pointer !important;
`;

interface Props {
    value: string | undefined;
    projectIntegration: ProjectIntegration;
    viewDataEntity: string;
    loading: boolean;
    enableEdit: boolean;
    projectIntegrationEntityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>;
    onDataChanged: () => void;
}

const ViewDataInspectCellRenderer: React.FC<Props> = ({
    value,
    projectIntegration,
    viewDataEntity,
    loading: loadingProp,
    enableEdit,
    projectIntegrationEntityId,
    onDataChanged,
}) => {
    const [isModalShown, setModalShown] = useState(false);
    const [isEditRecordModalOpen, setIsEditRecordModalOpen] = useState(false);

    if (loadingProp) {
        return (
            <LoadingWrapper>
                <Placeholder $height="20px" $width="50px" />
            </LoadingWrapper>
        );
    }

    if (!value) {
        return (
            <Wrapper>
                <Button size={ButtonSize.SMALL} disabled outlined>
                    Inspect
                </Button>
                {enableEdit && (
                    <Button disabled outlined>
                        Edit
                    </Button>
                )}
            </Wrapper>
        );
    }

    return (
        <>
            <Wrapper>
                <StyledModelOpenButton
                    onClick={() => setModalShown(true)}
                    data-automation={`view-data-inspect-cell-renderer-inspect-button-${value}`}
                    size={ButtonSize.SMALL}
                    outlined
                >
                    Inspect
                </StyledModelOpenButton>

                {enableEdit && (
                    <StyledModelOpenButton
                        size={ButtonSize.SMALL}
                        data-automation={`view-data-inspect-cell-renderer-edit-button-${value}`}
                        onClick={() => setIsEditRecordModalOpen(true)}
                        outlined
                    >
                        Edit
                    </StyledModelOpenButton>
                )}
            </Wrapper>

            <SingleExternalActivityInspect
                onClose={() => setModalShown(false)}
                shown={isModalShown}
                externalActivityId={value}
                entityType={viewDataEntity}
                projectIntegration={projectIntegration}
            />

            <ViewDataAddRecordModal
                entityName={viewDataEntity}
                open={isEditRecordModalOpen}
                onClose={() => setIsEditRecordModalOpen(false)}
                projectIntegrationEntityId={projectIntegrationEntityId}
                projectIntegrationId={projectIntegration.id}
                projectIntegrationUniqueType={projectIntegration.integrationUniqueType}
                onSubmit={onDataChanged}
                externalActivityId={value}
            />
        </>
    );
};

export default ViewDataInspectCellRenderer;
