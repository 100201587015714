import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';

import FormulaAIBuilderModal from './components/FormulaAIBuilder/FormulaAIBuilderModal';
import FormulaNode from './components/FormulaNodes/FormulaNode';
import FormulaValidationErrors from './components/FormulaValidationErrors';
import SpecificEditor from './components/SpecificEditor';
import FormulaContext from './entities/FormulaContext';
import type HighlightedNodes from './entities/HighlightedNodes';
import type SpecificEditorMetadata from './entities/SpecificEditorMetadata';
import formulaTreeNodeFactory from './utils/formulaTreeNodeFactory';
import type { JoinedValidationError } from './utils/triageValidations';
import triageValidations from './utils/triageValidations';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { IconSvg, useFlag } from '@tonkean/infrastructure';
import type { TonkeanService } from '@tonkean/shared-services';
import type { CustomFieldsManager } from '@tonkean/shared-services';
import { SmartSearchIcon as AtomIcon } from '@tonkean/svg';
import { createEmptyFormulaNode } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { rootFormulaField } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FieldDefinition, FieldType, ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

export const FormulaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${Theme.current.palette.formula.wrapperBorder};
    border-radius: 4px;
    margin: 8px 0;
    padding: 10px;

    > * {
        margin-bottom: 8px;
    }
`;

interface FormulaState {
    rootNode?: formulaTreeNode;
    history: formulaTreeNode[];
    future: formulaTreeNode[];
}

type ReducerAction =
    | { type: 'setRoot'; node: formulaTreeNode }
    | { type: 'undo'; lastChange: formulaTreeNode; history: formulaTreeNode[] }
    | { type: 'redo'; previousChange: formulaTreeNode; future: formulaTreeNode[] };
const formulaReducer: React.Reducer<FormulaState, ReducerAction> = (state, action) => {
    const { history, future, rootNode } = state;
    const rootNodeArray = rootNode ? [rootNode] : [];

    switch (action.type) {
        case 'undo': {
            return {
                history: action.history,
                rootNode: action.lastChange,
                future: [...future, ...rootNodeArray],
            };
        }

        case 'redo': {
            return {
                future: action.future,
                rootNode: action.previousChange,
                history: [...history, ...rootNodeArray],
            };
        }

        case 'setRoot': {
            const history = [...state.history, ...rootNodeArray];

            return {
                history,
                rootNode: action.node,
                future: [],
            };
        }
    }
};

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    exampleInitiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    /** The formula */
    evaluatedFormula: string;
    /** The formula tree. If supplied, will ignore the evaluatedFormula and use it as the tree. */
    formulaExpressionTree?: formulaTreeNode;
    /** Will be triggered on formula change, if the change is valid */
    onFormulaChange: (
        originalFormula: string,
        evaluatedFormula: string,
        valid: boolean,
        dataType: FieldType | undefined,
        rootNode: formulaTreeNode,
    ) => void;
    onFormulaDataChange: (formulaData: any, isInit: boolean) => void;
    /** Emits validation result without updating the formula  */
    emitValidationResult: (valid: boolean, dataType: FieldType | undefined) => void;
    /** Callback to let the parent know that the inner track aggregation option was selected */
    onInnerTrackAggregationSelected?: () => void;
    /** The field definition for which the formula is configured */
    fieldDefinition: FieldDefinition | undefined;

    tonkeanService: TonkeanService;
    customFieldsManager: CustomFieldsManager;
    additionalTabs?: TonkeanExpressionAdditionalTab[];
    customTrigger?: any;
    projectIntegration?: ProjectIntegration;
}

const FormulaBuilder: React.FC<Props> = ({
    projectId,
    groupId,
    exampleInitiativeId,
    workflowVersionId,
    evaluatedFormula = '',
    formulaExpressionTree,
    onFormulaChange: onFormulaChangeCallback,
    emitValidationResult,
    onInnerTrackAggregationSelected,
    fieldDefinition,
    tonkeanService,
    customFieldsManager,
    onFormulaDataChange,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    // Storing the formula string in a ref and not a state so we don't need to add it as a dependency
    // to to the useEffect
    const currentEvaluatedFormulaStringRef = useRef<string | undefined>(undefined);

    const [{ rootNode, history, future }, formulaDispatch] = useReducer(formulaReducer, undefined, () => ({
        history: [],
        future: [],
    }));

    const [formulaChangedOutsideSpecificEditorCallback, setFormulaChangedOutsideSpecificEditorCallback] =
        useState<() => void>();
    const [validations, setValidations] = useState<Record<'errors' | 'warnings', JoinedValidationError[]>>({
        errors: [],
        warnings: [],
    });
    const [highlightedNodes, setHighlightedNodes] = useState<HighlightedNodes>();
    const [specificEditor, setSpecificEditor] = useState<SpecificEditorMetadata>();
    const [formulaDataMap, setFormulaDataMap] = useState<Map<string, any>>(new Map());

    const [{ data: formulaDataFromServer, loading: formulaDataFromServerLoading }, getFieldDefinitionFormulaData] =
        useLazyTonkeanService('getFieldDefinitionFormulaData');

    useEffect(() => {
        if (fieldDefinition?.id) {
            getFieldDefinitionFormulaData(workflowVersionId, fieldDefinition.id);
        }
    }, [fieldDefinition, getFieldDefinitionFormulaData, workflowVersionId]);

    useEffect(() => {
        if (!formulaDataFromServer) {
            return;
        }

        const mapWithDataFromServer = new Map(Object.entries(formulaDataFromServer));
        setFormulaDataMap(mapWithDataFromServer);
        onFormulaDataChange(mapWithDataFromServer, true);
    }, [formulaDataFromServer, onFormulaDataChange]);

    const onFormulaDataMapChange = useCallback(
        (dataToInsert: any) => {
            if (!specificEditor || !formulaDataMap) {
                return;
            }
            const newFormulaDataMap = new Map(formulaDataMap).set('0', dataToInsert);
            setFormulaDataMap(newFormulaDataMap);
            onFormulaDataChange(newFormulaDataMap, false);
        },
        [formulaDataMap, onFormulaDataChange, specificEditor],
    );

    /**
     * Sets the new node as root, triggers recursive validation, and sets the errors.
     *
     * @param node - the new root node.
     * @param emitValidation - should it emit the validation result.
     */
    const setRootFormula = useCallback(
        (node: formulaTreeNode, emitValidation: boolean = false) => {
            // Validate
            node.preformValidationAndDataTypeCalculation();

            formulaDispatch({ type: 'setRoot', node });

            if (emitValidation) {
                emitValidationResult(node.valid, node.dataType);
            }
        },
        [emitValidationResult],
    );

    // We store the formula tree count in a ref to make sure we not updating the root node if other http
    // request has been sent already. Otherwise, it can cause an infinity loop of emitting an outdated
    // formula string.
    const getFormulaTreeCountRef = useRef(0);

    // Update errors list when the root node changes
    useEffect(() => {
        setValidations(triageValidations(rootNode?.validationErrors ?? []));
    }, [rootNode]);

    // When the formula tree changes, emit a change event and store the formula string in the current formula string ref.
    const emitNewFormulaString = useCallback(
        (rootNode: formulaTreeNode) => {
            if (!rootNode) {
                return;
            }

            const evaluatedFormulaString = rootNode.toString(true);
            const originalFormulaString = rootNode.toString(false);
            currentEvaluatedFormulaStringRef.current = evaluatedFormulaString;
            onFormulaChangeCallback(
                originalFormulaString,
                evaluatedFormulaString,
                rootNode.valid,
                rootNode.dataType,
                rootNode,
            );
        },
        [onFormulaChangeCallback],
    );

    const onNodeChanged = useCallback(
        (newNode: formulaTreeNode) => {
            setRootFormula(newNode);
            emitNewFormulaString(newNode);
        },
        [setRootFormula, emitNewFormulaString],
    );
    const onNodeDeleted = useCallback(() => {
        const rootNode = createEmptyFormulaNode(rootFormulaField);
        setRootFormula(rootNode);
        emitNewFormulaString(rootNode);
    }, [setRootFormula, emitNewFormulaString]);

    const translateStringIntoFormulaNode = useCallback(
        (formulaString: string) => {
            const trimmedFormula = formulaString.trim();
            if (currentEvaluatedFormulaStringRef.current !== trimmedFormula) {
                currentEvaluatedFormulaStringRef.current = trimmedFormula;

                if (!trimmedFormula.length) {
                    setRootFormula(createEmptyFormulaNode(rootFormulaField));
                } else {
                    const currentGetFormulaTreeCount = getFormulaTreeCountRef.current + 1;
                    getFormulaTreeCountRef.current = currentGetFormulaTreeCount;
                    tonkeanService.getFormulaTree(projectId, workflowVersionId, trimmedFormula).then((formulaNode) => {
                        if (getFormulaTreeCountRef.current !== currentGetFormulaTreeCount) {
                            return;
                        }

                        setRootFormula(formulaTreeNodeFactory(formulaNode, rootFormulaField), true);
                    });
                }
            }
        },
        [projectId, setRootFormula, tonkeanService, workflowVersionId],
    );

    const [aiGeneratedFormulaString, setAIGeneratedFormulaString] = useState('');
    useEffect(() => {
        if (!aiGeneratedFormulaString) {
            return;
        }

        translateStringIntoFormulaNode(aiGeneratedFormulaString);
    }, [aiGeneratedFormulaString, translateStringIntoFormulaNode]);

    // If a formulaExpressionTree is supplied, use it as the formula. Otherwise, check if evaluatedFormula
    // has the same formula as the one showed in the component, and if not, use it to build the tree.
    useEffect(() => {
        if (formulaExpressionTree) {
            const isInnerAggregation =
                formulaExpressionTree.type === FormulaTreeNodeType.TREE &&
                formulaExpressionTree.operator.key === OperatorKey.INNER_TRACK_AGGREGATION;
            if (isInnerAggregation) {
                onInnerTrackAggregationSelected?.();
                return;
            }

            onNodeChanged(formulaExpressionTree);
            return;
        }

        translateStringIntoFormulaNode(evaluatedFormula);
    }, [
        evaluatedFormula,
        formulaExpressionTree,
        onInnerTrackAggregationSelected,
        onNodeChanged,
        translateStringIntoFormulaNode,
    ]);

    const undo = () => {
        const newHistory = [...history];
        const lastChange = newHistory.pop();
        if (!lastChange) {
            return;
        }

        formulaDispatch({ type: 'undo', history: newHistory, lastChange });
        emitNewFormulaString(lastChange);
    };
    const redo = () => {
        const newFuture = [...future];
        const previousChange = newFuture.pop();
        if (!previousChange) {
            return;
        }

        formulaDispatch({ type: 'redo', future: newFuture, previousChange });
        emitNewFormulaString(previousChange);
    };

    const emitFormulaChangedOutsideSpecificEditor = useFlag(() => {
        formulaChangedOutsideSpecificEditorCallback?.();
    });

    const contextValue = useMemo(
        () => ({
            highlightedNodes,
            setHighlightedNodes,
            specificEditor,
            setSpecificEditor,
            formulaDataMap,
            onInnerTrackAggregationSelected,
            workflowVersionId,
            formulaChangedOutsideSpecificEditorCallback: emitFormulaChangedOutsideSpecificEditor,
            fieldDefinition,
        }),
        [
            highlightedNodes,
            specificEditor,
            formulaDataMap,
            onInnerTrackAggregationSelected,
            workflowVersionId,
            emitFormulaChangedOutsideSpecificEditor,
            fieldDefinition,
        ],
    );

    const [formulaAIBuilderOpen, setFormulaAIBuilderOpen] = useState(false);

    const showAIFormulaGenerator = useFeatureFlag('tonkean_feature_smart_formula_generator');

    return (
        <FormulaContext.Provider value={contextValue}>
            <header className="flex flex-vmiddle margin-bottom-xs margin-top">
                <div className="flex-grow common-bold">Formula Editor:</div>
                {showAIFormulaGenerator && (
                    <>
                        <IconButton
                            leftIcon={<IconSvg as={AtomIcon} color={Theme.colors.white} />}
                            onClick={() => setFormulaAIBuilderOpen(true)}
                        >
                            AI Builder
                        </IconButton>
                        <FormulaAIBuilderModal
                            open={formulaAIBuilderOpen}
                            onClose={() => setFormulaAIBuilderOpen(false)}
                            projectId={projectId}
                            onUseFormulaClick={setAIGeneratedFormulaString}
                        />
                    </>
                )}
                <button
                    type="button"
                    onClick={undo}
                    className="btn btn-secondary btn-sm margin-left-xs tnk-tooltip mod-top"
                    disabled={!history.length}
                >
                    <span className="tnk-tooltip-text"> Undo </span>
                    <span className="fa fa-arrow-left margin-right-xxs margin-top-xxs" />
                </button>
                <button
                    type="button"
                    onClick={redo}
                    className="btn btn-secondary btn-sm margin-left-xs tnk-tooltip mod-top"
                    disabled={!future.length}
                >
                    <span className="tnk-tooltip-text"> Redo </span>
                    <span className="fa fa-arrow-right margin-left-xxs margin-top-xxs" />
                </button>
            </header>
            <FormulaWrapper>
                {rootNode && (
                    <FormulaNode
                        depth={0}
                        additionalTabs={additionalTabs}
                        customTrigger={customTrigger}
                        projectIntegration={projectIntegration}
                        canDelete={false}
                        disabled={false}
                        node={rootNode}
                        onNodeChanged={onNodeChanged}
                        onNodeDeleted={onNodeDeleted}
                    />
                )}
            </FormulaWrapper>
            <FormulaValidationErrors errors={validations.errors} warnings={validations.warnings} />
            {formulaDataFromServerLoading && <div className="loading-large" />}
            {!formulaDataFromServerLoading && specificEditor && (
                <SpecificEditor
                    groupId={groupId}
                    tonkeanService={tonkeanService}
                    exampleInitiativeId={exampleInitiativeId}
                    customFieldsManager={customFieldsManager}
                    setFormulaChangedOutsideSpecificEditorCallback={setFormulaChangedOutsideSpecificEditorCallback}
                    onFormulaDataChange={onFormulaDataMapChange}
                />
            )}
        </FormulaContext.Provider>
    );
};

export default FormulaBuilder;
