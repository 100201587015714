import type { Placement } from '@popperjs/core';
import React from 'react';

import AutonomousTypesDropdown from './AutonomousTypesDropdown';

import type { LogicBlockConfigDefinition } from '@tonkean/logic-block-configs';

interface Props {
    onAutonomousTypeSelected: (typeConfig: LogicBlockConfigDefinition) => void;
    placement?: Placement;
}
const PlusButtonAutonomousTypesDropdown: React.FC<Props> = ({ onAutonomousTypeSelected, placement }) => {
    return (
        <AutonomousTypesDropdown
            className="worker-editor-add-logic mod-color"
            dropdownTitle="Add Trigger When:"
            onAutonomousTypeSelected={onAutonomousTypeSelected}
            placement={placement}
        >
            +
        </AutonomousTypesDropdown>
    );
};

export default PlusButtonAutonomousTypesDropdown;
