import personSelectorValidator from './personSelectorValidator';

const channelOrPersonSelectorValidator = (validationObject, definition, validateThread) => {
    const personSelectorIsValid = personSelectorValidator(validationObject, definition, true, validateThread);
    if (!personSelectorIsValid) {
        if (!definition.notificationSettings) {
            validationObject.notifyType = 'Must select a notification method';
        } else if (
            definition.notificationSettings.channelType === 'CUSTOM_CHANNEL' &&
            !definition.notificationSettings.channelNameExpression?.evaluatedExpression
        ) {
            validationObject.noChannelNameExpression = 'Must configure the Channel Name';
        }
    }
};

export default channelOrPersonSelectorValidator;
