function TestBotCtrl(
    $scope,
    $rootScope,
    $q,
    $timeout,
    tonkeanService,
    trackHelper,
    groupManager,
    communicationIntegrationsService,
    workflowFolderManager,
) {
    $scope.as = $rootScope.as;
    $scope.pm = $rootScope.pm;
    $scope.cis = communicationIntegrationsService;
    $scope.data = {
        testItemName: null,
        preparingTest: false,
        donePreparingTest: false,
        communicationIntegration: $scope.cis.getFirstThatSupports($scope.cis.features.notifications),
    };

    // the steps to be completed in the creation process
    const creationSteps = [
        {
            // get groups step
            action: () => {
                // use get groups of tonkean service because we dont want to update the cache. because we are also getting the hidden groups
                return (
                    tonkeanService
                        .getGroups($scope.pm.project.id, true)
                        // extract groups from server data
                        .then((data) => data.entities)
                );
            },
        },
        {
            // find or create test group step
            action: (groups) => {
                const testGroup = groups.find((group) => {
                    return group.isExample;
                });

                if (!testGroup) {
                    return groupManager.createGroup(
                        $scope.pm.project.id,
                        'Test List',
                        'private',
                        [$scope.as.currentUser.id],
                        tonkeanService.groupNotifications.direct,
                        null,
                        false,
                        null,
                        false,
                        false,
                        true,
                    );
                }

                const deferred = $q.defer();
                deferred.resolve(testGroup);
                return deferred.promise;
            },
        },
        {
            // create test inititative in test group step
            action: (testGroup) =>
                trackHelper.createInitiative(
                    null,
                    $scope.pm.project.id,
                    null,
                    $scope.data.testItemName || 'Test item',
                    [],
                    [],
                    $scope.as.currentUser.id,
                    null,
                    null,
                    testGroup.id,
                ),
        },
        {
            // send test gather update step
            action: (testInitiative) => trackHelper.askForInitiativeUpdates(testInitiative.id, null, true),
        },
    ];

    $scope.sendTest = function () {
        $scope.data.preparingTest = true;

        // create promise object
        const creationDeferredObject = $q.defer();
        // start completing steps
        nextStep(0, creationDeferredObject);
        // when final step resolves the promise
        creationDeferredObject.promise.then(function () {
            $scope.data.preparingTest = false;
            $scope.data.donePreparingTest = true;
            $scope.data.testItemName = null;
            $timeout(resetSendButton, 3000);
        });
    };

    $scope.sendButtonClicked = function () {
        if ($scope.data.donePreparingTest) {
            resetSendButton();
        } else {
            $scope.sendTest();
        }
    };

    function resetSendButton() {
        $scope.data.preparingTest = false;
        $scope.data.donePreparingTest = false;
    }

    /**
     * Starts completing steps recursively, at the final step resolves the given promise
     * @param stepIndex
     * @param deferred a angular deferred object, which contains a promise and is the api of resolving that promise
     */
    function nextStep(stepIndex, deferred, previousStepParam) {
        // if no more steps
        if (stepIndex >= creationSteps.length) {
            // no more steps
            deferred.resolve();
            return;
        }

        const step = creationSteps[stepIndex];
        step.action(previousStepParam).then(function (previousStepParam) {
            // move to next step
            nextStep(stepIndex + 1, deferred, previousStepParam);
        });
    }
}

export default angular.module('tonkean.app').controller('TestBotCtrl', TestBotCtrl);
