import React, { useCallback, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import DisableContent from '../../../../infrastructure/components/DisableContent';
import DisableOnProduction from '../../../../infrastructure/components/DisableOnProduction';
import FormBuilderTabLabel from '../../entities/FormBuilderTabLabel';
import FormSettings from '../FormSettings/FormSettings';

import { angularToReact } from '@tonkean/angular-components';
import { AnimatedSwitch, AnimatedSwitchItem, SavingIndicator } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form, FormField, FormType } from '@tonkean/tonkean-entities';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: configuration;
    border-right: 1px solid ${Theme.colors.gray_300};
    overflow-y: auto;
`;

const DisableOnProductionStyled = styled(DisableOnProduction)`
    flex-grow: 1;
`;

const DisableContentStyled = styled(DisableContent)`
    flex-grow: 1;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    margin-bottom: 5px;

    display: flex;
    align-items: center;
`;

const FormQuestionHeader = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: bold;
`;

const TnkWorkerFormEditor = angularToReact<{
    groupId: string;
    workflowVersionId: string;
    formId: string;
    onFormCreated?: () => void;
    formTypeToCreate: FormType;
    advancedSettingsOpen: boolean;
    isInFormBuilder: boolean;
    setFormFetchTrigger: (trigger: () => void) => void;
    onFieldsChange: (fields: FormField[]) => void;
    onFormQuestionChange: (question: string) => void;
    form: Form;
}>('tnk-worker-form-editor');

const FormBuilderConfigurationQuestionsSwitchItem = styled(AnimatedSwitchItem)`
    .worker-form-editor {
        overflow-y: visible;
    }

    & > .self-unfocusable.self-unfocusable-position {
        height: 100%;
    }

    flex-grow: 1;
`;

/**
 * This wrapper is used to allow DisableContent cover the whole tab content area, rather than the actual square the FormEditor is taking.
 */
const FormEditorWrapper = styled.div`
    padding: 14px 30px ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

interface Props {
    form: Form;
    setForm: Dispatch<SetStateAction<Form>>;
    groupId: string;
    workflowVersionId: string;
    formId?: string;
    selectedTab: FormBuilderTabLabel;
    getWorkerForm: (workflowVersionId: string, formId: string) => void;
    setAngularEditorFetchFormTrigger: (trigger: () => void) => void;
    angularKey: string;
}

const FormBuilderConfiguration: React.FC<Props> = ({
    form,
    setForm,
    formId,
    groupId,
    workflowVersionId,
    selectedTab,
    getWorkerForm,
    setAngularEditorFetchFormTrigger,
    angularKey,
}) => {
    const { editedSections, setSectionLoading, isEditable } = useContext(FormBuilderContext);

    const onFieldsChange = useCallback(
        (fields: FormField[]) => {
            setForm((prevForm) => ({ ...prevForm, definition: { ...prevForm.definition, fields } }));
            setSectionLoading(FormBuilderSections.Builder);
        },
        [setForm, setSectionLoading],
    );

    const onFormQuestionChange = (question: string) => {
        setForm((prevForm) => ({
            ...prevForm,
            definition: {
                ...prevForm.definition,
                formQuestion: question,
                useAutoGeneratedFormQuestion: question === '',
            },
        }));

        setSectionLoading(FormBuilderSections.Builder);
    };

    return (
        <Wrapper>
            <DisableOnProductionStyled>
                <ContentWrapper>
                    <AnimatedSwitch activeLabel={selectedTab}>
                        <AnimatedSwitchItem label={FormBuilderTabLabel.SETTINGS}>
                            <FormSettings
                                groupId={groupId}
                                workflowVersionId={workflowVersionId}
                                form={form}
                                onFormChanged={setForm}
                                formId={formId}
                            />
                        </AnimatedSwitchItem>
                        <FormBuilderConfigurationQuestionsSwitchItem label={FormBuilderTabLabel.BUILDER}>
                            <DisableContentStyled disable={!isEditable}>
                                <FormEditorWrapper>
                                    <HeaderWrapper>
                                        {form.formQuestionType === FormQuestionType.COLLECT_INNER_ITEMS ? (
                                            <FormQuestionHeader>Form Question</FormQuestionHeader>
                                        ) : (
                                            <FormQuestionHeader>Form Questions</FormQuestionHeader>
                                        )}
                                        <SavingIndicator state={editedSections[FormBuilderSections.Builder]} />
                                    </HeaderWrapper>
                                    <TnkWorkerFormEditor
                                        key={angularKey} // key is used to un-mount previous form editor and replace with new one
                                        groupId={groupId}
                                        workflowVersionId={workflowVersionId}
                                        formId={form.id}
                                        onFormCreated={() => getWorkerForm(workflowVersionId, form.id)}
                                        formTypeToCreate={form.formType}
                                        advancedSettingsOpen={false}
                                        setFormFetchTrigger={setAngularEditorFetchFormTrigger}
                                        onFieldsChange={onFieldsChange}
                                        onFormQuestionChange={onFormQuestionChange}
                                        form={form}
                                        isInFormBuilder
                                    />
                                </FormEditorWrapper>
                            </DisableContentStyled>
                        </FormBuilderConfigurationQuestionsSwitchItem>
                    </AnimatedSwitch>
                </ContentWrapper>
            </DisableOnProductionStyled>
        </Wrapper>
    );
};

export default FormBuilderConfiguration;
