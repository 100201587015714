import { useContext } from 'react';

import { useItemInterfaceContext, ItemInterfaceContext } from '@tonkean/infrastructure';

function useItemInterfaceContextIfValid() {
    /* eslint-disable react-hooks/rules-of-hooks */
    const itemInterfaceContext = useContext(ItemInterfaceContext);

    return itemInterfaceContext?.itemInterface ? useItemInterfaceContext() : null;
}

export default useItemInterfaceContextIfValid;
