import React, { useContext } from 'react';
import styled from 'styled-components';

import SQLDatabaseExplanationSubtitle from './SQLDatabaseExplanationSubtitle';
import SQLDatabaseIntegrationConfigurationContext from './SQLDatabaseIntegrationConfigurationContext';
import SQLDatabaseItalicText from './SQLDatabaseItalicText';
import SQLDatabaseRequiredAsterisk from './SQLDatabaseRequiredAsterisk';
import SQLDatabaseSectionTitle from './SQLDatabaseSectionTitle';
import SQLDatabaseTestQuery from './SQLDatabaseTestQuery';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const TestSqlQueryWrapper = styled.div`
    margin-top: 6px;
`;

const SQLDatabaseQueryConfigurationSqlQuerySection: React.FC<{
    queryConfiguration: SQLDatabaseQueryConfiguration;
    index: number;
    integrationId: string;
    projectIntegration: ProjectIntegration | undefined;
}> = ({ queryConfiguration, index, integrationId, projectIntegration }) => {
    const { changeQuery, changeQueryTested } = useContext(SQLDatabaseIntegrationConfigurationContext);

    return (
        <>
            <SQLDatabaseSectionTitle>
                SQL Query <SQLDatabaseRequiredAsterisk />
            </SQLDatabaseSectionTitle>

            <SQLDatabaseExplanationSubtitle>
                In order to use the last time we fetched, you can use{' '}
                <SQLDatabaseItalicText>&#123;&#123;Last Collect&#125;&#125;</SQLDatabaseItalicText>&nbsp;placeholder in
                your query. <br />
                It will be set in the format YYYY-MM-DD HH:MI:SS and UTC timezone.
            </SQLDatabaseExplanationSubtitle>

            <SQLDatabaseExplanationSubtitle>
                Please note: SQL SELECT commands are limited to 1000 records per page (Limit 1000).
            </SQLDatabaseExplanationSubtitle>

            <textarea
                value={queryConfiguration.query}
                onChange={({ target }) => changeQuery(target.value, index)}
                className="form-control common-resize-y-only"
                data-automation="sql-database-query-configuration-sql-query-section"
                autoComplete="off"
                placeholder="SQL Query"
                required
            />

            <TestSqlQueryWrapper>
                <SQLDatabaseTestQuery
                    query={queryConfiguration.query}
                    integrationId={integrationId}
                    pageSize={queryConfiguration.pageSize}
                    onTestSuccessful={() => changeQueryTested(true, index)}
                    onTestFailed={() => changeQueryTested(false, index)}
                    projectIntegration={projectIntegration}
                />
            </TestSqlQueryWrapper>
        </>
    );
};

export default SQLDatabaseQueryConfigurationSqlQuerySection;
