import { useAngularService } from 'angulareact';
import type { FormEvent } from 'react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import OutlookAuthenticationType, { authTypeToDisplayName } from './OutlookAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Checkbox, Radio, RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
`;

const CenteredAuthTypeRadioGroup = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const CenteredDiv = styled.div`
    text-align: center;
    margin-bottom: 10px;
`;

const OutlookComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authType, setAuthType] = useState<OutlookAuthenticationType>(OutlookAuthenticationType.USER);
    const projectManager = useAngularService('projectManager');
    const predefinedTenantId = projectManager.projectData.sharepointPredefinedTenantId;
    const [tenantId, setTenantId] = useState<string | undefined>(predefinedTenantId);
    const [shouldCollectMessages, setShouldCollectMessages] = useState<boolean>(false);

    const authenticateWithMicrosoft = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            let config;
            const redirectUri = environment.integrationKeysMap.microsoftUri;
            let scope =
                'https%3A%2F%2Fgraph.microsoft.com%2FCalendars.ReadWrite ' +
                'https%3A%2F%2Fgraph.microsoft.com%2FSites.Read.All ' +
                'https%3A%2F%2Fgraph.microsoft.com%2FMail.Send ' +
                'https%3A%2F%2Fgraph.microsoft.com%2FMail.Send.Shared ' +
                'offline_access';

            if (shouldCollectMessages) {
                scope +=
                    ' https%3A%2F%2Fgraph.microsoft.com%2FMail.ReadBasic ' +
                    'https%3A%2F%2Fgraph.microsoft.com%2FMail.ReadWrite ' +
                    'https%3A%2F%2Fgraph.microsoft.com%2FMail.Read.Shared';
            }

            config = {
                redirectUri,
                authType,
                shouldCollectMessages,
            };

            if (authType === OutlookAuthenticationType.USER) {
                const code = await oauthHandler.microsoft(scope, redirectUri, true);

                config = {
                    ...config,
                    code,
                };
            } else {
                config = {
                    ...config,
                    tenantId,
                };
            }

            authenticate(
                config,
                { authType, tenantId, shouldCollectMessages },
                `${integrationConfiguration.displayName}`,
            );
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <CenteredAuthTypeTitle>
                <h5>Authentication Type</h5>
            </CenteredAuthTypeTitle>
            <CenteredAuthTypeRadioGroup>
                <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                    <Radio value={OutlookAuthenticationType.USER}>
                        {authTypeToDisplayName[OutlookAuthenticationType.USER]}
                    </Radio>
                    <Radio value={OutlookAuthenticationType.APPLICATION}>
                        {authTypeToDisplayName[OutlookAuthenticationType.APPLICATION]}
                    </Radio>
                </RadioGroup>
            </CenteredAuthTypeRadioGroup>

            {authType === OutlookAuthenticationType.APPLICATION && (
                <div className="form-group flex-inline">
                    <label htmlFor="tenant_id" className="control-label col-md-3 text-right margin-top-xs">
                        Tenant ID
                    </label>

                    <div className="col-sm-8">
                        <input
                            value={tenantId}
                            className="form-control"
                            onChange={({ target }) => setTenantId(target.value)}
                            id="tenant_id"
                            autoComplete="off"
                            placeholder="Tenant ID"
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Get your Tenant ID{' '}
                            <a
                                href="https://aad.portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview"
                                target="_blank"
                            >
                                here
                            </a>
                            .
                        </span>
                    </div>
                </div>
            )}

            <CenteredDiv>
                <Checkbox
                    checked={shouldCollectMessages}
                    onChange={() => setShouldCollectMessages(!shouldCollectMessages)}
                >
                    Collect Messages, Attachments &amp; Folders
                </Checkbox>
            </CenteredDiv>

            <div className="flex flex-col api-token-authentication">
                <div className="flex mod-center margin-top-20">
                    <button
                        className=" btn btn-secondary btn-lg"
                        onClick={authenticateWithMicrosoft}
                        disabled={authType === OutlookAuthenticationType.APPLICATION && !!!tenantId}
                    >
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OutlookComponent;
