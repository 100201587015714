/**
 * A service to help with custom trigger operations.
 */
function TrainedKeywordManager($q, tonkeanService, workflowVersionManager) {
    const _this = this;

    /**
     * Deletes a given trained keyword.
     */
    _this.deleteTrainedKeyword = function (workflowVersionId, trainedKeywordId) {
        return tonkeanService.deleteTrainedKeyword(trainedKeywordId).then(() => {
            workflowVersionManager.incrementWorkflowVersionCounter(workflowVersionId);
            return $q.resolve();
        });
    };

    /**
     * Gets the trained keywords of a custom trigger.
     */
    _this.getTrainedKeywords = function (workflowVersionId, customTriggerId) {
        return tonkeanService.getTrainedKeywords(workflowVersionId, customTriggerId);
    };

    /**
     * Adds a trained keyword for a trigger.
     */
    _this.addTrainedKeyword = function (workflowVersionId, customTriggerId, trainedKeyword, sentiment) {
        return tonkeanService.addTrainedKeyword(customTriggerId, trainedKeyword, sentiment).then((createdKeyword) => {
            workflowVersionManager.incrementWorkflowVersionCounter(workflowVersionId);
            return $q.resolve(createdKeyword);
        });
    };
}

export default angular.module('tonkean.app').service('trainedKeywordManager', TrainedKeywordManager);
