import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { SelectInputSourceTitleContainer } from './sharedComponents';
import WorkflowVersionInputSourceType from '../../../entities/WorkflowVersionInputSourceType';
import type { InputSourceContentComponentProps } from '../InputSourceContentComponentProps';

import { H4, Paragraph } from '@tonkean/infrastructure';
import { logicBlockConfigs } from '@tonkean/logic-block-configs';
import { LogicBlockConfigType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const FormInstructionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
    background: ${Theme.colors.gray_100};
    margin-bottom: 18px;
`;

const Instructions = styled.ol`
    padding-inline-start: 16px;
    color: ${Theme.colors.gray_600};
`;

const FormsControlsBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FormsInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = ({
    workflowVersion,
    setSelectedInputSourceType,
}) => {
    const $rootScope = useAngularService('$rootScope');
    const addFormTrigger = () => {
        $rootScope.$broadcast(
            'addNewAutonomousTriggerToWorkerEditor',
            logicBlockConfigs[LogicBlockConfigType.AUTONOMOUS_CREATED_FROM_FORM],
        );
    };

    return (
        <>
            <SelectInputSourceTitleContainer>
                <H4 $bold>Intake Sequences</H4>

                <ClickableLink onClick={() => setSelectedInputSourceType(WorkflowVersionInputSourceType.MANUAL)}>
                    Change Intake Method
                </ClickableLink>
            </SelectInputSourceTitleContainer>

            <FormInstructionsContainer>
                <Paragraph $bold>Intake Sequence Instructions</Paragraph>
                <Instructions>
                    <li>Create a new interface in the interface builder.</li>
                    <li>
                        Add an “Interface Submitted” trigger to the canvas to handle submission of the intake sequence.
                    </li>
                </Instructions>
            </FormInstructionsContainer>

            <FormsControlsBar>
                <ClickableLink onClick={addFormTrigger}>Add Interface Submitted Trigger</ClickableLink>
                <ClickableLink state="." params={{ t: 'forms' }}>
                    Manage Interfaces
                </ClickableLink>
            </FormsControlsBar>
        </>
    );
};

export default FormsInputSourceSelectionContent;
