<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div>
        <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>

        <form
            name="data.loginModal"
            class="form-horizontal"
            ng-submit="connect()"
            ng-show="!integration && !connecting"
        >
            <div class="form-group">
                <label for="login-api-url" class="col-sm-4 control-label">Workspace URL</label>

                <div class="col-sm-8 flex-vmiddle">
                    <span>https://</span>
                    <input
                        type="text"
                        ng-model="data.workspaceName"
                        class="form-control flex-grow"
                        id="login-api-url"
                        autocomplete="off"
                        placeholder="Company"
                        required
                    />
                    <span>.namely.com</span>
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <div class="form-group">
                <label for="login-api-key" class="col-sm-4 control-label">API Token</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.apikey"
                        class="form-control"
                        id="login-api-key"
                        autocomplete="off"
                        placeholder="API Token"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <!--   Setup Instructions    -->
            <div class="margin-bottom-lg info-box common-size-xxs">
                <p class="common-color-dark-grey">Setup API key instructions:</p>
                <div class="common-color-dark-grey">
                    <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                        <li>
                            <div class="padding-left-xxs">Click on the API option in your profile menu</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">
                                Go to Personal Access Tokens tab and type in your new API key name
                            </div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Click on the create button and copy the generated code</div>
                        </li>
                    </ul>
                </div>
            </div>

            <!--     Connect Button       -->
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>

            <!--  OAuth Authentication          -->
            <!--            <div ng-hide="connecting || integration" class="text-center">-->
            <!--                <button type="button" class=" btn btn-secondary btn-lg"-->
            <!--                        analytics-on analytics-category="Config {{editProject ? 'Edit' : 'New'}}"-->
            <!--                        analytics-event="Authenticate"-->
            <!--                        analytics-label="{{currentIntegration.name}}" ng-click="authorize()">-->
            <!--                    Authenticate with {{currentIntegration.displayName}}-->
            <!--                </button>-->
            <!--                <div class="margin-normal-sm-v common-subtitle-inner">We only need read permissions!</div>-->
            <!--            </div>-->
        </form>

        <div class="text-center" ng-show="connecting">
            <h4>
                <span class="loading"></span>
                Connecting to {{ currentIntegration.displayName }}
            </h4>
        </div>

        <div ng-show="integration && !connecting">
            <div class="alert alert-info">
                <a
                    class="pull-right"
                    href="#"
                    analytics-on="click"
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Change Auth"
                    analytics-label="{{ currentIntegration.name }}"
                    ng-click="changeUser()"
                >
                    Change User
                </a>

                <div class="integration-group-header-connected" ng-if="integration">
                    Authenticated
                    <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                        as
                        <span class="integration-group-header-connected-username">
                            {{ integration.integrationUserDisplayName || integration.integrationUser }}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            class="btn btn-default"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Cancel Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="onCancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary"
            ng-disabled="(selections|filter:{loadingBranches:true}:true).length"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Save Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="ok()"
        >
            OK
        </button>
    </div>
</div>
