<div
    class="edit-worker-form-modal common-close-btn-container"
    blocked-element="{{ data.viewMode === 'preview' }}"
    data-blocked-message="This is a display only view of the form configuration"
>
    <!-- Close button -->
    <div class="common-close-btn ignore-blocked-element" ng-hide="inviting" ng-click="cancel()">
        <tnk-icon blocked-element="false" src="/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Worker form editor -->
    <tnk-worker-form-editor
        group-id="data.groupId"
        form-id="data.formId"
        workflow-version-id="{{ data.workflowVersionId }}"
        on-form-created="onFormCreated(form)"
        form-type-to-create="data.formTypeToCreate"
        advanced-settings-open="data.viewMode === 'preview'"
    ></tnk-worker-form-editor>
</div>
