import React from 'react';

import useCloseCallback from './useCloseCallback';

import type { ButtonProps } from '@tonkean/tui-buttons/Button';
import { Button } from '@tonkean/tui-buttons/Button';
import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';

/**
 * Button component that will close the modal when clicked. It's a wrapper around the Button component, and
 * all the props that Button accepts can be passed to this component.
 *
 * If added an onClick event listener, it will be triggered before the modal closes. To prevent the modal from
 * being closed, use `event.preventDefault()`.
 */
const CloseButton: React.ForwardRefRenderFunction<
    HTMLButtonElement,
    ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }, ref) => {
    const onClose = useCloseCallback();
    const sharedOnClick = useCompositeEventCallback(props.onClick, () => onClose());

    return (
        <Button type="button" {...props} onClick={sharedOnClick} ref={ref}>
            {children}
        </Button>
    );
};

export default React.forwardRef(CloseButton);
