import Utils from '@tonkean/utils';

/**
 * Our abstract store implementation. Extend this store when starting a new one.
 * What you need to implement yourself once extending this class (what you don't get from this abstract class):
 * - onDispatch(action)
 * - getNewState(id) - optional. For your components. A function to initialize a state.
 * - getState(id) - optional. For you components. A function to retrieve a state by id.
 * - shouldItemUpdate(ids...) - optional. For your components. A function to tell them if their item should re-render.
 */
class TonkeanStore {
    constructor(dispatchers) {
        if (dispatchers) {
            for (const dispatcher of dispatchers) {
                // Register the store to each dispatcher we got. The onDispatch function will handle all dispatches triggered.
                dispatcher.register(this.onDispatch.bind(this));
            }
        }

        // Initialize our listeners array. Components that call addListener add items to this array.
        this.listeners = [];

        // Initialize the states map.
        this.states = {};
    }

    /**
     * Exposed to components and allows them to register for updates from this store.
     * @param callback - a callback to trigger upon emission.
     * @returns {{callback: *, id: string, remove: any}} - a new listener that enables the caller to remove its registered listener.
     */
    addListener(callback) {
        // Initialize an id for the new listener.
        const id = Utils.guid();

        // Create the new listener.
        const newListener = {
            callback, // The callback provided by the caller that will be invoked upon emission.
            id, // The unique id of this listener.
            remove: this.removeListener.bind(this, id), // A wrapper for the removeListener function. The caller can call this function to remove itself from listening.
        };

        // Add it to our listeners array so it will get called in the next emit action.
        this.listeners.push(newListener);

        return newListener;
    }

    /**
     * Exposed to components via the returned listener returned from addListener, wrapped in a remove function.
     * @param id - the id of the listener to remove.
     */
    removeListener(id) {
        let listenerIndex = -1;

        // Go over our listeners and try to find the requested one for deletion.
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];

            if (listener.id === id) {
                listenerIndex = i;
                break;
            }
        }

        // If the desired listener was found, remove it from the listeners array.
        if (listenerIndex > -1) {
            this.listeners.splice(listenerIndex, 1);
        }
    }

    /**
     * Emits a new change to our listeners.
     * Emitting with an id will cause the component in charge of that id to re-render.
     * Emitting without an id (passing null or nothing) will cause all the components to re-render.
     */
    emit(id) {
        // Go over all of our listeners and call their callbacks with the given parameter (which might be null or undefined).
        for (let i = 0; i < this.listeners.length; i++) {
            const listener = this.listeners[i];
            listener.callback(id);
        }
    }
}

export default TonkeanStore;
