<div class="tnk-update-fields-form">
    <div class="margin-top-xs" ng-if="data.loadingForm">
        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-8 common-width-12"></div>
        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-13"></div>
        <div
            class="
                loading-placeholder
                margin-normal-sm-h margin-normal-sm-v
                padding-normal-8
                margin-top-xs
                common-width-12
            "
        ></div>
        <div
            class="
                loading-placeholder
                margin-normal-sm-h margin-normal-sm-v
                padding-normal-13
                margin-top-xs margin-bottom-xs
            "
        ></div>
    </div>

    <!-- Fields List -->
    <div class="update-fields-form-not-available-layout" ng-if="!data.environmentIsActive && !data.loadingForm">
        The form submission process cannot be completed at this point
        <div class="update-fields-form-not-available-sub-title">
            The details submitted up to this point were received and saved
        </div>
        <div class="update-fields-form-not-available-description">
            Please contact the form owner in order to complete the process
        </div>
    </div>

    <div
        ng-if="data.environmentIsActive && !data.loadingForm"
        ng-repeat="inlineGroup in data.inlineGroups"
        ng-class="{ 'flex mod-align-start': inlineGroup.length > 1 }"
    >
        <div
            ng-repeat="field in inlineGroup"
            class="update-fields-form-full-field"
            ng-class="{
                'margin-right': inlineGroup.length > 1,
                'not-valid': data.validationMap[field.fieldDefinitionIdentifier].length
            }"
            data-automation="update-fields-form-full-field"
        >
            <!-- Title -->
            <div
                data-automation="update-fields-form-full-field-title"
                ng-if="field.showTitle"
                class="update-fields-form-field-label"
                ng-class="{
                    'emphasis-required-field': data.formConfig.definition.emphasisRequiredFields && field.isRequired
                }"
            >
                <span ng-if="field.displayName && field.displayName.length">{{ field.displayName }}</span>
                <span
                    ng-if="!field.displayName || !field.displayName.length"
                    data-automation="update-field-form-field-name"
                >
                    {{
                        data.specialFieldsMap[field.fieldDefinitionIdentifier].fieldLabel ||
                            data.specialFieldsMap[field.fieldDefinitionIdentifier].name ||
                            data.fieldDefinitionsMap[field.fieldDefinitionIdentifier].fieldLabel ||
                            data.fieldDefinitionsMap[field.fieldDefinitionIdentifier].name
                    }}
                </span>
                <span
                    data-automation="update-field-form-required-field-text"
                    ng-if="field.isRequired && data.formConfig.definition.emphasisRequiredFields"
                    class="emphasis-required-field"
                >
                    (required)
                </span>
                <span data-automation="update-field-form-required-field-icon" ng-if="field.isRequired" class="form-red">
                    *
                </span>
            </div>

            <!-- Field Description -->
            <tnk-expandable-field-description
                ng-if="
                    data.fieldDefinitionsMap[field.fieldDefinitionIdentifier] &&
                    data.fieldDefinitionsMap[field.fieldDefinitionIdentifier].description
                "
                field-definition="data.fieldDefinitionsMap[field.fieldDefinitionIdentifier]"
                form-field="field"
            ></tnk-expandable-field-description>

            <div
                ng-if="field.viewOnly"
                ng-bind-html="data.specialFieldIdToExistingValueMap[field.fieldDefinitionIdentifier].value
                 || data.fieldDefinitionIdToExistingFieldInstanceMap[field.fieldDefinitionIdentifier].value"
            ></div>

            <div ng-if="!field.viewOnly">
                <!-- Special field input -->
                <tnk-special-field-manual-input
                    ng-if="!!data.specialFieldsMap[field.fieldDefinitionIdentifier]"
                    field-id="field.fieldDefinitionIdentifier"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    selected-value-to-update="data.specialFieldIdToExistingValueMap[field.fieldDefinitionIdentifier]"
                    on-manual-special-field-changed="onSpecialFieldValueChanged(selectedValueToUpdate, fieldId)"
                    invalid="!!data.validationMap[field.fieldDefinitionIdentifier].length"
                ></tnk-special-field-manual-input>

                <!-- Field definition input -->
                <tnk-update-field-value
                    ng-if="!!data.fieldDefinitionsMap[field.fieldDefinitionIdentifier]"
                    group="data.group"
                    initiative="data.initiative"
                    field="data.fieldDefinitionIdToExistingFieldInstanceMap[field.fieldDefinitionIdentifier]"
                    delegate-save="true"
                    field-definition="data.fieldDefinitionsMap[field.fieldDefinitionIdentifier]"
                    do-not-update-in-server="true"
                    buttons-color="data.buttonsColor"
                    invalid="!!data.validationMap[field.fieldDefinitionIdentifier].length"
                    on-field-changed="onCustomFieldValueChanged(selectedValueToUpdate, fieldId)"
                    workflow-version-type="data.workflowVersionType"
                ></tnk-update-field-value>
            </div>

            <span data-automation="update-fields-form-required-field-message" class="form-red">
                <span
                    ng-repeat="errorObj in data.validationMap[field.fieldDefinitionIdentifier]"
                    class="block"
                    ng-class="{ 'common-color-warning': !errorObj.restrictSubmission }"
                >
                    {{ errorObj.errorMessage }}
                </span>
            </span>
        </div>
    </div>

    <!-- Submit form button -->
    <div
        ng-if="
            !(
                data.formConfig.definition.autoSubmitForm &&
                $root.features[pm.project.id].tonkean_feature_auto_submit_form
            ) && data.environmentIsActive
        "
        class="flex-vmiddle margin-top-lg"
        ng-class="{ 'mod-justify-space': data.showBack, 'mod-justify-end': !data.showBack }"
    >
        <button
            ng-if="data.showBack"
            ng-click="handleBackClicked()"
            class="btn btn-secondary margin-right back-button"
            ng-style="{
            'color': data.buttonsColor ,
            'border-color': data.buttonsColor,
        }"
            ng-disabled="data.backLoading"
            data-automation="sequence-back-button"
        >
            Back
            <i class="loading-small margin-left-xs" ng-if="data.backLoading"></i>
        </button>
        <tnk-submit-form-button
            label="data.formConfig.definition.overrideSubmitFormButtonLabel || data.formConfig.definition.submitFormButtonLabel"
            primary-color="data.primaryColor"
            secondary-color="data.secondaryColor"
            buttons-color="data.buttonsColor"
            form-finished="data.formFinished"
            on-submit="submitForm(ignoreNonRestrictingErrors)"
            all-errors-not-restricting-errors="data.allErrorsNotRestrictingErrors"
            disabled="data.loadingForm"
        ></tnk-submit-form-button>
    </div>
</div>
