import MicrosoftAdminAuthenticationComponent from './MicrosoftAdminAuthenticationComponent';
import microsoftAdminCircleIcon from '../../../../apps/tracks/images/integrations/microsoft_admin-circle.png';
import microsoftAdminIcon from '../../../../apps/tracks/images/microsoft_admin.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class MicrosoftAdminIntegration extends IntegrationDefinitionBase {
    override name = 'microsoftadmin';
    override displayName = 'Microsoft Admin';
    override description = 'We will only collect users and groups data.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'createdate';
    override customizedAuthenticationComponent = MicrosoftAdminAuthenticationComponent;

    override entities = [
        new IntegrationEntity('Group', 'Groups'),
        new IntegrationEntity('Member', 'Members'),
        new IntegrationEntity('User', 'Users'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(microsoftAdminCircleIcon, '130px'),
        new IntegrationIcon(microsoftAdminIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default MicrosoftAdminIntegration;
