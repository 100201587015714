import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import INTAKE_THANK_YOU_PAGE_CONSTS from './IntakeThankYouPageConsts';
import type ThankYouPageData from '../../../entities/ThankYouPageData';

import { Breakpoint, Checkmark, SafeHtml } from '@tonkean/infrastructure';
import type { Color } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    text-align: center;
    margin: 56px 0;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
`;

const ThankYouMessage = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.5px;
    color: variables.$tonkean-black-text;
    padding: 34px 34px 10px 34px;
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        font-size: 20px;
    }
`;

const ThankYouMessageSubtitle = styled.div`
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.5px;
    color: variables.$tonkean-dark-grey-text;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        font-size: 20px;
    }
`;

const RedirectLinkWrapper = styled.div<{ $isButton: boolean }>`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    flex-direction: ${(props) => (props.$isButton ? 'column' : 'row')};
`;

const RedirectingMessage = styled.div`
    margin-right: 6px;
`;

const RedirectLink = styled.a<{ $color: string; $isButton: boolean }>`
    border-radius: 100px;
    background-color: transparent;
    text-decoration: none;
    color: ${(props) => props.$color};

    ${(props) =>
        props.$isButton &&
        `
        padding: 6px 12px;
        border: 1px solid ${props.$color};
        border-radius: 100px;
        margin-top: 16px;
    `}
`;

interface ThankYouPageProps {
    thankYouPageData: ThankYouPageData;
    color: Color;
}

const IntakeThankYouPage: React.FC<ThankYouPageProps> = ({ thankYouPageData, color }) => {
    const [redirectUrlCounter, setRedirectUrlCounter] = useState<number>(
        thankYouPageData.redirectToUrl && thankYouPageData.redirectDelay !== undefined
            ? thankYouPageData.redirectDelay
            : 5,
    );

    const redirectToUrl = thankYouPageData.redirectToUrl;
    const redirectToNewTab = thankYouPageData.redirectToNewTab;
    const evaluatedUrlLink = thankYouPageData.evaluatedUrlLink;

    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();

    const handleRedirect = useCallback(() => {
        setRedirectUrlCounter((prevCounter) => {
            const newCounter = prevCounter - 1;
            if (redirectToUrl && evaluatedUrlLink && newCounter <= 0) {
                const target = redirectToNewTab ? '_blank' : '_self';
                window.open(evaluatedUrlLink, target);
            }
            return newCounter;
        });
    }, [setRedirectUrlCounter, evaluatedUrlLink, redirectToNewTab, redirectToUrl]);

    const clearIntervalId = useCallback(() => {
        clearInterval(intervalId);
    }, [intervalId]);

    useEffect(() => {
        if (thankYouPageData.redirectToUrl) {
            const currentIntervalId = setInterval(handleRedirect, 1000);
            setIntervalId(currentIntervalId);
            return () => clearInterval(currentIntervalId);
        }
    }, [thankYouPageData.redirectToUrl, setIntervalId, handleRedirect]);

    useEffect(() => {
        if (thankYouPageData.redirectToUrl && redirectUrlCounter <= 0 && intervalId !== undefined) {
            clearInterval(intervalId);
        }
    }, [thankYouPageData.redirectToUrl, redirectUrlCounter, intervalId]);

    const showDefaultResponseMessage =
        thankYouPageData?.showDefaultResponseMessage ||
        (!thankYouPageData?.evaluatedWebformResponseMessage && !thankYouPageData?.evaluatedCustomResponseMessage);

    const subtitle = thankYouPageData.isDisabledMode
        ? INTAKE_THANK_YOU_PAGE_CONSTS.DISABLED_THANK_YOU_SUB_TITLE
        : thankYouPageData.evaluatedWebformResponseMessageSubtitle;

    const thankYouMessge = showDefaultResponseMessage
        ? INTAKE_THANK_YOU_PAGE_CONSTS.DEFAULT_THANK_YOU_TITLE
        : thankYouPageData?.evaluatedWebformResponseMessage || thankYouPageData?.evaluatedCustomResponseMessage || '';

    const redirectLinkDataAutomation = thankYouPageData.redirectToUrl
        ? INTAKE_THANK_YOU_PAGE_CONSTS.THANK_YOU_REDIRECT_URL_DATA_AUTOMATION
        : INTAKE_THANK_YOU_PAGE_CONSTS.THANK_YOU_NO_REDIRECT_URL_DATA_AUTOMATION;

    return (
        <Wrapper>
            <Checkmark color={color} isBig />
            <ThankYouMessage data-automation={INTAKE_THANK_YOU_PAGE_CONSTS.THANK_YOU_TITLE_DATA_AUTOMATION}>
                <SafeHtml
                    unsafeHtml={
                        thankYouPageData.isDisabledMode
                            ? INTAKE_THANK_YOU_PAGE_CONSTS.DISABLED_THANK_YOU_TITLE
                            : thankYouMessge
                    }
                />
            </ThankYouMessage>
            {!!subtitle && (
                <ThankYouMessageSubtitle
                    data-automation={INTAKE_THANK_YOU_PAGE_CONSTS.THANK_YOU_SUB_TITLE_DATA_AUTOMATION}
                >
                    <SafeHtml unsafeHtml={subtitle || ''} />
                </ThankYouMessageSubtitle>
            )}

            {thankYouPageData.evaluatedUrlLink && (
                <RedirectLinkWrapper
                    data-automation={INTAKE_THANK_YOU_PAGE_CONSTS.THANK_YOU_REDIRECT_LINK_WRAPPER_DATA_AUTOMATION}
                    $isButton={!!thankYouPageData.isCustomInterfaceLink}
                >
                    {thankYouPageData.redirectToUrl && redirectUrlCounter > 0 && (
                        <RedirectingMessage>
                            <span>Redirecting in {redirectUrlCounter} seconds to</span>
                        </RedirectingMessage>
                    )}

                    <RedirectLink
                        $isButton={!!thankYouPageData.isCustomInterfaceLink}
                        data-automation={redirectLinkDataAutomation}
                        href={thankYouPageData.evaluatedUrlLink}
                        target={thankYouPageData.redirectToNewTab ? '_blank' : '_self'}
                        type="button"
                        onClick={clearIntervalId}
                        $color={color}
                    >
                        {thankYouPageData.evaluatedCustomInterfaceLabel || thankYouPageData.evaluatedUrlLink}
                    </RedirectLink>
                </RedirectLinkWrapper>
            )}
        </Wrapper>
    );
};

export default IntakeThankYouPage;
