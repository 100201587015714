import PencilButtonAutonomousTypesDropdown from './PencilButtonAutonomousTypesDropdown';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPencilButtonAutonomousTypesDropdown',
        reactToAngular(PencilButtonAutonomousTypesDropdown, [
            'onAutonomousTypeSelected',
            'isMonitorTrack',
            'isInnerAutonomous',
        ]),
    );
