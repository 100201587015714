import React from 'react';
import type { Client } from 'urql';
import { useClient } from 'urql';

import { angularToReact } from '@tonkean/angular-components';

const TnkWorkerEditorState = angularToReact<{ urql: Client }>('tnk-worker-editor-state');

interface Props {}

const WorkerEditorStateWrapper: React.FC<Props> = () => {
    const urql = useClient();
    return <TnkWorkerEditorState urql={urql} />;
};

export default WorkerEditorStateWrapper;
