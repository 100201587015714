import { useAngularService } from 'angulareact';
import React, { useLayoutEffect, useMemo } from 'react';

import SingleWorkerRunFlowRunTableLogic from './SingleWorkerRunFlowRunTableLogic';
import { ReactComponent as EmptyFieldsIcon } from '../../../../../../../images/icons/history/empty-fields.svg';
import { ReactComponent as NetworkErrorIcon } from '../../../../../../../images/icons/network-error.svg';
import WorkerRunTableListLoading from '../../WorkerRunTableListLoading';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { ErrorStateMessage, StateMessage } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

interface Logic {
    node: { displayName: string; id: string };
    impacts: Logic[];
}

const filterLogicImpact = (impact: Logic, searchTerm: string) => {
    return String(impact.node.displayName).toLowerCase().includes(searchTerm.toLowerCase());
};

const filterLogic = (logic: Logic, searchTerm: string): Logic => {
    const filteredImpacts: Logic[] = [];
    for (const impact of logic.impacts) {
        const filtered = filterLogic(impact, searchTerm);
        const filterImpact = filterLogicImpact(impact, searchTerm);

        if (filtered.impacts.length > 0 || filterImpact) {
            filteredImpacts.push(filtered);
        }
    }

    return { node: logic.node, impacts: filteredImpacts };
};

interface Props {
    /** If undefined it means that it's the initial load or a manual reload */
    workerRun?: WorkerRun;
    /** Search term to filter the table by */
    searchTerm: string;
}

const SingleWorkerRunFlowRunTableList: React.FC<Props> = ({ workerRun, searchTerm }) => {
    const customTriggerManager = useAngularService('customTriggerManager');
    const [{ loading, error, data }, getLogicGraph] = useLazyAsyncMethod(
        customTriggerManager,
        'getWorkflowVersionCustomTriggersAndGraph',
    );

    const workflowVersionId = workerRun?.workflowVersionId;
    useLayoutEffect(() => {
        if (workflowVersionId) {
            getLogicGraph(workflowVersionId);
        }
    }, [getLogicGraph, workflowVersionId]);

    const logicComponentsGraphRoot = data?.logicComponentsGraphRoot;
    const logicRootFiltered = useMemo(() => {
        if (!logicComponentsGraphRoot) {
            return undefined;
        }

        return filterLogic(logicComponentsGraphRoot, searchTerm);
    }, [logicComponentsGraphRoot, searchTerm]);

    const infoToCustomTriggersMap = useMemo(() => {
        if (!workerRun) {
            return;
        }

        return utils.groupBy(workerRun.workerRunLogics as { customTriggerId: string }[], 'customTriggerId');
    }, [workerRun]);

    if (error) {
        return (
            <ErrorStateMessage
                icon={
                    <span className="tnk-icon">
                        <NetworkErrorIcon />
                    </span>
                }
            >
                Error Loading The Flow Investigation
            </ErrorStateMessage>
        );
    }

    if (!workerRun || loading || !infoToCustomTriggersMap || !logicRootFiltered) {
        return <WorkerRunTableListLoading />;
    }

    if (logicRootFiltered.impacts?.length > 0) {
        return (
            <>
                {logicRootFiltered.impacts?.map((logic, index) => (
                    <SingleWorkerRunFlowRunTableLogic
                        key={`${logic.node.id}-${workerRun.id}`}
                        logic={logic}
                        workerRun={workerRun}
                        infoToCustomTriggersMap={infoToCustomTriggersMap}
                        isLastChild={index === logicRootFiltered.impacts.length - 1}
                        searchTerm={searchTerm}
                    />
                ))}
            </>
        );
    }

    return (
        <StateMessage
            icon={
                <span className="tnk-icon">
                    <EmptyFieldsIcon />
                </span>
            }
        >
            No Actions/Triggers To Show
        </StateMessage>
    );
};

export default SingleWorkerRunFlowRunTableList;
