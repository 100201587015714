/**
 * Created by ShaLi on 06/10/2015.
 */

function FeedbackModalCtrl($scope, $uibModalInstance, tonkeanService, subject, hideInfo) {
    $scope.hideInfo = hideInfo;

    $scope.data = {
        subject,
    };

    $scope.init = function () {};

    $scope.submit = function () {
        if (!$scope.loading) {
            $scope.error = null;
            if ($scope.data.description && $scope.data.description.length) {
                $scope.loading = true;

                tonkeanService
                    .postFeedback($scope.data.subject, $scope.data.description)
                    .then(function () {
                        $scope.$emit('alert', { msg: 'Thanks for sharing!', type: 'success' });
                        $uibModalInstance.close();
                    })
                    .catch(function (error) {
                        $scope.error = error;
                        $scope.loading = false;
                    });
            } else {
                $scope.error = 'Please fill the description of your request...';
            }
        }
    };

    $scope.init();
}
angular.module('tonkean.app').controller('FeedbackModalCtrl', FeedbackModalCtrl);
