import type { TElement, TText, UnknownObject } from '@udecode/plate';
import React from 'react';
import styled from 'styled-components';

import { CoreEditorSerializeLeaf, type CoreEditorSerializeConfiguration } from '@tonkean/editor';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useInitiativeJsonExpressionServerEvaluation } from '@tonkean/infrastructure';
import type { ServerFormulaExpressionNode } from '@tonkean/tonkean-entities';

export const ELEMENT_TNK_VARIABLE = 'tonkean-variable';

export interface CoreEditorVariableElement extends TElement {
    type: typeof ELEMENT_TNK_VARIABLE;
    expression: ServerFormulaExpressionNode;
}

const PreserveWhitespaces = styled.span`
    white-space: pre-wrap;
`;

interface Props {
    expression: ServerFormulaExpressionNode;
    elementStyleParams: UnknownObject;
    serializeConfiguration: CoreEditorSerializeConfiguration;
}

const EvaluatedTonkeanVariable: React.FC<Props> = ({ expression, elementStyleParams, serializeConfiguration }) => {
    const { initiative } = useItemInterfaceContext();
    const evaluatedExpression = useInitiativeJsonExpressionServerEvaluation(initiative, expression);

    const leafElement: TText = { ...elementStyleParams, text: evaluatedExpression };

    return (
        <PreserveWhitespaces>
            <CoreEditorSerializeLeaf leaf={leafElement} serializeConfiguration={serializeConfiguration} />
        </PreserveWhitespaces>
    );
};

export default React.memo(EvaluatedTonkeanVariable);
